import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Chrome from '../chrome/Chrome';
import LoadingPanel from '../components/LoadingPanel';
import DnfBuilder from '../dnfBuilder/DnfBuilder';
import DnfLoader from '../dnfBuilder/DnfLoader';
import DnfPairEstimatesLoader from '../dnfBuilder/DnfPairEstimatesLoader';
import DnfSidebar from '../dnfBuilder/DnfSidebar';
import { isCuratorByProjectId } from '../utils/Authorization';
import { getAuthorizedUser, selectActiveProjectInfo } from '../utils/Selectors';
import style from './DnfBuilderPage.module.scss';
import Unauthorized from './Unauthorized';

const DnfBuilderPage = _.compose(
  connect(state => {
    const { location: { recipeId }, dnfBuilder: { sidebarExpanded }, projects: { initialFetch } } = state;
    return {
      recipeId,
      sidebarExpanded,
      initialProjectsFetch: initialFetch,
      isUserACurator: isCuratorByProjectId(getAuthorizedUser(state), selectActiveProjectInfo(state)?.projectDoc.id.id),
    };
  }),
)(class DnfBuilderPage extends React.Component {
  static propTypes = {
    initialProjectsFetch: PropTypes.bool.isRequired,
    isUserACurator: PropTypes.bool.isRequired,
    sidebarExpanded: PropTypes.bool.isRequired,
  };

  getSidebar = () => {
    const { sidebarExpanded } = this.props;
    return (
      <DnfSidebar
        isExpanded={sidebarExpanded}
        sidebarKey="sources"
        recipeId={this.props.recipeId}
      />
    );
  };

  render() {
    const { initialProjectsFetch, sidebarExpanded, isUserACurator } = this.props;

    return (
      <Chrome
        className={style.container}
        rightSidebar={this.getSidebar()}
        isExpanded={sidebarExpanded}
      >
        <DnfLoader />
        <DnfPairEstimatesLoader />
        {!initialProjectsFetch ? (
          <LoadingPanel />
        ) : !isUserACurator ? (
          <Unauthorized />
        ) : (
          <DnfBuilder />
        )}
      </Chrome>
    );
  }
});

export default DnfBuilderPage;
