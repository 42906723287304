import PropTypes from 'prop-types';
import React from 'react';
import { pure } from 'recompose';
import _ from 'underscore';

import Button from '../components/Button';
import Input from '../components/Input/Input';

/**
 * A Snazzy Input that dynamically changes from text to input, based on clicking an edit button.
 */
const GroupByInput = _.compose(
  pure,
)(/**
 * A Snazzy Input that dynamically changes from text to input, based on clicking an edit button.
 */
  class GroupByInput extends React.Component {
    static propTypes = {
      defaultText: PropTypes.string.isRequired,
      onExit: PropTypes.func.isRequired,
      text: PropTypes.string,
    };

    state = {
      editing: false,
    };

    edit = () => {
      this.setState({
        editing: true,
      });
    };

    update = (event) => {
      if (event.key === 'Enter' || event.key === 'Tab') {
      // write content of Input back to parent
        this.props.onExit(event.target.value);
      }
      if (event.key === 'Enter' || event.key === 'Tab' || event.key === 'Escape') {
        this.setState({
          editing: false,
        });
      }
    };

    onChange = () => {
    // TODO<Cheeze>: Change Input to not require onChange, potentially add onEnter.
    // we are using the update() function above to update the parent
    };

    render() {
      if (this.state.editing) {
      // uncontrolled Input
        return (
          <Input
            key="group-by-input"
            title="" // Required.
            onKeyDown={_.partial(this.update)}
            onChange={_.partial(this.onChange)}
            defaultValue={this.props.text || this.props.defaultText}
        />
        );
      }
      const current = this.props.text || this.props.defaultText;
      return (
        <div className="editable-input" {...this.props}>
          <span key="group-by-value" onClick={_.partial(this.edit)}>{current}</span>
          <Button
            key="edit-value"
            buttonType="Link"
            icon="edit"
            iconSize={16}
            onClick={_.partial(this.edit)}
            type="button"
        />
        </div>
      );
    }
  });

export default GroupByInput;
