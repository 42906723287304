import Model from '../models/Model';
import { ArgTypes } from '../utils/ArgValidation';

export const initialState = new (Model({
  lastSequenceNum: { type: ArgTypes.oneOf(ArgTypes.wholeNumber, ArgTypes.eq(-1)), defaultValue: -1 },
  lastResponseWasError: { type: ArgTypes.bool, defaultValue: false },
}))();

export const reducers = {
  'Messaging.error': (state) => {
    return state.merge({ lastResponseWasError: true });
  },

  'Messaging.messageReceived': (state, { lastSequenceNum }) => {
    return state.merge({ lastSequenceNum, lastResponseWasError: false });
  },
};
