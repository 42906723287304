import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import LoadingPanel from '../components/LoadingPanel';
import KeyMods from '../models/KeyMods';
import AllUnifiedAttributesLoader from '../schema-mapping/AllUnifiedAttributesLoader';
import { activeUserIsCuratorForActiveProject } from '../utils/Selectors';
import BulkTransformList from './BulkTransformList';
import ClearEditsButton from './ClearEditsButton';
import CodemirrorTooltipPositioner from './CodemirrorTooltipPositioner';
import FunctionNamesLoader from './FunctionNamesLoader';
import HintNamesLoader from './HintNamesLoader';
import SaveTransformsButton from './SaveTransformsButton';
import ShowAllButton from './ShowAllButton';
import StaticAnalysisLoader from './StaticAnalysisLoader';
import { saveTransforms } from './TransformsApi';
import TransformsBrowserStorage from './TransformsBrowserStorage';
import style from './TransformSidebarTab.module.scss';
import TransformsLoader from './TransformsLoader';
import { selectOrdering, selectSavePreconditions } from './TransformsStore';

const TransformsSidebarTab = _.compose(
  connect((state) => {
    const { transforms, transforms: { loading } } = state;
    const noTransforms = selectOrdering(transforms).size === 0;
    const userIsCurator = activeUserIsCuratorForActiveProject(state);
    const canSaveTransforms = selectSavePreconditions(state).every(v => v);
    return { noTransforms, loading, userIsCurator, canSaveTransforms };
  }, {
    onSaveTransforms: saveTransforms,
  }),
)(({ loading, userIsCurator, onSaveTransforms, canSaveTransforms, ...rest }) => {
  const maybeSaveTransforms = (event) => {
    if (KeyMods.fromJSON(event).toggleKey && event.key === 's') {
      event.preventDefault();
      if (canSaveTransforms) {
        onSaveTransforms();
      }
    }
  };
  return (
    <div className={style.container} {...rest} onKeyDown={maybeSaveTransforms}>
      {loading ? <LoadingPanel /> : null}
      <TransformsLoader />
      <TransformsBrowserStorage />
      <FunctionNamesLoader />
      <HintNamesLoader />
      <AllUnifiedAttributesLoader />
      <StaticAnalysisLoader />
      <CodemirrorTooltipPositioner />
      <div className={style.doclink}>
        <a target="_" href="/function-docs">view function docs</a>
      </div>
      {userIsCurator && (
        <div className={style.toolbar}>
          <ShowAllButton />
          <ClearEditsButton />
          <SaveTransformsButton />
        </div>
      )}
      <div className={style.content}>
        <BulkTransformList sourceLevel />
        <BulkTransformList sourceLevel={false} />
      </div>
    </div>
  );
});

export default TransformsSidebarTab;
