import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import { auth } from './SSOAsync';

const SSOWrapper = _.compose(
  connect(state => {
    const { sso: { loaded } } = state;
    return { loaded };
  }, {
    onAuth: auth,
  }),
)(class SSOWrapper extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    loaded: PropTypes.bool.isRequired,
  };

  UNSAFE_componentWillMount() {
    const { onAuth } = this.props;
    onAuth();
  }

  render() {
    const { loaded, children } = this.props;
    return loaded ? children : null;
  }
});

export default SSOWrapper;
