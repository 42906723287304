import { List } from 'immutable';

import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import { ArgTypes, Checker } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import MimeType, { MimeTypeE } from './MimeType';


export default class StorageNode extends getModelHelpers({
  name: { type: ArgTypes.string },
  parent: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
  mimeType: { type: ArgTypes.orNull(MimeType.argType as Checker<MimeTypeE>), defaultValue: null },
  selectable: { type: ArgTypes.bool },
  childNames: { type: ArgTypes.Immutable.list.of(ArgTypes.string), defaultValue: null },
}, 'StorageNode')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class StorageNodeRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: $TSFixMe) {
    return new StorageNode({
      name: obj.name,
      parent: List(obj.parent),
      mimeType: obj.mimeType,
      selectable: obj.selectable,
      childNames: List(obj.childNames),
    });
  }

  isDirectory() {
    return this.mimeType === MimeType.DIRECTORY;
  }
}
