import classNames from 'classnames';
import { is } from 'immutable';
import PropTypes from 'prop-types';
import { Range } from 'rc-slider';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import Checkbox from '../components/Input/Checkbox';
import PlainInput from '../components/Input/PlainInput';
import ScoreIcon from '../components/ScoreIcon';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import TooltipTrigger from '../components/TooltipTrigger';
import FeedbackAssignDialog from '../feedback/FeedbackAssignDialog';
import ConfidenceFilter from '../models/ConfidenceFilter';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import ProcurementTaxonomy from '../models/ProcurementTaxonomy';
import Recipe from '../models/Recipe';
import ScoreThresholds, { Symbol } from '../models/ScoreThresholds';
import VerifiedIcon from '../pairs/VerifiedIcon';
import { getRecipeDocById } from '../projects/ProjectsStore';
import CategoryTree from '../taxonomy/CategoryTree';
import { shortPerc } from '../utils/Numbers';
import PRODUCT_NAME from '../utils/ProductName';
import { getActiveRecipeWithStatus, getAuthorizedUser } from '../utils/Selectors';
import { getPath } from '../utils/Values';
import CategorizationDatasetFilter from './CategorizationDatasetFilter';
import { filterMyAssignments, filterNeedsVerification, isAssignmentStatusSemiChecked, isExpertsSemiChecked, isLabeledByUserSemiChecked } from './TransactionStore';

const BoundInput = (props) => {
  const { key, onChange, value, title } = props;
  return (
    <PlainInput
      key={key}
      type="number"
      className="input-box"
      defaultValue={parseFloat((value * 100).toFixed(1), 10)}
      title={title}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          onChange(e);
        }
      }}
      onBlur={onChange}
      max={100}
      min={0}
      step={1}
      size={3}
    />
  );
};

/**
 * Filter dialog on the categorization transactions page
 */
const TransactionFilter = _.compose(
  connect(state => {
    const {
      transactions,
      transactions: { selectedDatasetIds, assignedToMeComplete, assignedToMeToDo, expertResponsesAgree, expertResponsesDisagree, expertsPending, expertsUnsure, expertsNone, expertsSome, hasComments, categorizedByMe, labeledByUser, labeledByTamr, unlabeled, unlabeledByTamr, labelAgreesWithTamr, labelDisagreesWithTamr, showExternalData, categoryIds, assignmentStatusNone, assignmentStatusSome, assignmentStatusAll, assignmentStatusUnassigned, assignmentStatusAssigned, assignedToUsers, categorizedByUsers, showAssignedToUserFilter, showCategorizedByUsersFilter, highImpact, confidenceLow, confidenceMedium, confidenceHigh, hasConfidence, confidence },
      projects,
      location: { recipeId },
      allSourceDatasets: { datasets: sourceDatasetDocs },
      taxonomy: { taxonomy },
      config: { categorizationStrengthThresholds },
    } = state;
    const recipe = getRecipeDocById(projects.projectsWithStatus, recipeId);
    const loggedInUsername = getAuthorizedUser(state).username;
    const hasSuggestions = !!(
      getPath(getActiveRecipeWithStatus(state), 'materializations', 'categorizations', 'lastRun') ||
      getPath(getActiveRecipeWithStatus(state), 'materializations', 'predictCategorizations', 'lastRun')
    );
    return {
      recipe,
      recipeId,
      filterAssignedToMeComplete: assignedToMeComplete,
      filterAssignedToMeToDo: assignedToMeToDo,
      filterAssignmentStatusNone: assignmentStatusNone,
      filterAssignmentStatusSome: assignmentStatusSome,
      filterAssignmentStatusAll: assignmentStatusAll,
      filterAssignmentStatusUnassigned: assignmentStatusUnassigned,
      filterAssignmentStatusAssigned: assignmentStatusAssigned,
      assignmentStatusAssignedSemiChecked: isAssignmentStatusSemiChecked(state),
      filterCategorizedByMe: categorizedByMe,
      filterHighImpact: highImpact,
      filterConfidence: confidence,
      filterConfidenceLow: confidenceLow,
      filterConfidenceMedium: confidenceMedium,
      filterConfidenceHigh: confidenceHigh,
      filterExpertsResponsesAgree: expertResponsesAgree,
      filterExpertsResponsesDisagree: expertResponsesDisagree,
      filterExpertsPending: expertsPending,
      filterExpertsUnsure: expertsUnsure,
      filterExpertsNone: expertsNone,
      filterExpertsSome: expertsSome,
      expertsSemiChecked: isExpertsSemiChecked(state),
      filterHasComments: hasComments,
      filterHasConfidence: hasConfidence,
      filterLabeledByUser: labeledByUser,
      userLabeledSemiChecked: isLabeledByUserSemiChecked(state),
      filterLabeledByTamr: labeledByTamr,
      filterUnlabeled: unlabeled,
      filterUnlabeledByTamr: unlabeledByTamr,
      filterLabelAgreesWithTamr: labelAgreesWithTamr,
      filterLabelDisagreesWithTamr: labelDisagreesWithTamr,
      filterShowExternalData: showExternalData,
      selectedDatasetIds,
      sourceDatasetDocs,
      categoryIds,
      taxonomy,
      assignedToUsers,
      categorizedByUsers,
      showAssignedToUserFilter,
      showCategorizedByUsersFilter,
      loggedInUsername,
      filteredToMyAssignments: is(filterMyAssignments(transactions), transactions),
      filteredToNeedsVerification: is(filterNeedsVerification(transactions), transactions),
      strengthThresholds: categorizationStrengthThresholds,
      hasSuggestions,
    };
  }, {
    onAssignedToMeCompleteFilterChange: assignedToMeComplete => ({ type: 'Transactions.setFilterAssignedToMeComplete', assignedToMeComplete }),
    onAssignedToMeToDoFilterChange: assignedToMeToDo => ({ type: 'Transactions.setFilterAssignedToMeToDo', assignedToMeToDo }),
    onAssignedToUsersFilterChange: assignedToUsers => ({ type: 'Transactions.setFilterAssignedToUsers', assignedToUsers }),
    onCategorizedByUsersFilterChange: categorizedByUsers => ({ type: 'Transactions.setFilterCategorizedByUsers', categorizedByUsers }),
    onAssignmentStatusAllFilterChange: assignmentStatusAll => ({ type: 'Transactions.setAssignmentStatusAll', assignmentStatusAll }),
    onAssignmentStatusNoneFilterChange: assignmentStatusNone => ({ type: 'Transactions.setAssignmentStatusNone', assignmentStatusNone }),
    onAssignmentStatusSomeFilterChange: assignmentStatusSome => ({ type: 'Transactions.setAssignmentStatusSome', assignmentStatusSome }),
    onAssignmentStatusUnassignedFilterChange: assignmentStatusUnassigned => ({ type: 'Transactions.setAssignmentStatusUnassigned', assignmentStatusUnassigned }),
    onAssignmentStatusAssignedFilterChange: assignmentStatusAssigned => ({ type: 'Transactions.setAssignmentStatusAssigned', assignmentStatusAssigned }),
    resetDatasetFilter: () => ({ type: 'Transactions.resetDatasetFilter' }),
    onExpertResponsesAgreeFilterChange: expertResponsesAgree => ({ type: 'Transactions.setFilterExpertResponsesAgree', expertResponsesAgree }),
    onExpertResponsesDisagreeFilterChange: expertResponsesDisagree => ({ type: 'Transactions.setFilterExpertResponsesDisagree', expertResponsesDisagree }),
    onExpertsPendingFilterChange: expertsPending => ({ type: 'Transactions.setFilterExpertsPending', expertsPending }),
    onExpertsUnsureFilterChange: expertsUnsure => ({ type: 'Transactions.setFilterExpertsUnsure', expertsUnsure }),
    onExpertsNoneFilterChange: expertsNone => ({ type: 'Transactions.setFilterExpertsNone', expertsNone }),
    onExpertsSomeFilterChange: expertsSome => ({ type: 'Transactions.setFilterExpertsSome', expertsSome }),
    onExternalDataFilterChange: showExternalData => ({ type: 'Transactions.setFilterExternalData', showExternalData }),
    onHasCommentsFilterChange: hasComments => ({ type: 'Transactions.setFilterHasComments', hasComments }),
    onLabeledByTamrFilterChange: labeledByTamr => ({ type: 'Transactions.setFilterTamrLabeled', labeledByTamr }),
    onLabeledByUserFilterChange: labeledByUser => ({ type: 'Transactions.setFilterUserLabeled', labeledByUser }),
    onCategorizedByMeFilterChange: categorizedByMe => ({ type: 'Transactions.setFilterCategorizedByMe', categorizedByMe }),
    onUnlabeledFilterChange: unlabeled => ({ type: 'Transactions.setFilterUnlabeled', unlabeled }),
    onUnlabeledByTamrFilterChange: unlabeledByTamr => ({ type: 'Transactions.setFilterUnlabeledByTamr', unlabeledByTamr }),
    onLabelAgreesWithTamrFilterChange: labelAgreesWithTamr => ({ type: 'Transactions.setFilterLabelAgreesWithTamr', labelAgreesWithTamr }),
    onLabelDisagreesWithTamrFilterChange: labelDisagreesWithTamr => ({ type: 'Transactions.setFilterLabelDisagreesWithTamr', labelDisagreesWithTamr }),
    onHighImpactFilterChange: highImpact => ({ type: 'Transactions.setFilterHighImpact', highImpact }),
    onHasConfidenceFilterChange: hasConfidence => ({ type: 'Transactions.setHasConfidence', hasConfidence }),
    onConfidenceChange: confidence => ({ type: 'Transactions.setConfidence', confidence }),
    onConfidenceLowChange: confidenceLow => ({ type: 'Transactions.setConfidenceLow', confidenceLow }),
    onConfidenceMediumChange: confidenceMedium => ({ type: 'Transactions.setConfidenceMedium', confidenceMedium }),
    onConfidenceHighChange: confidenceHigh => ({ type: 'Transactions.setConfidenceHigh', confidenceHigh }),
    onOpenAssignedToUsersFilter: () => ({ type: 'Transactions.openAssignedToUsersFilter' }),
    onOpenCategorizedByUsersFilter: () => ({ type: 'Transactions.openCategorizedByUsersFilter' }),
    onCancelAssignedToUsersFilter: assignedToUsers => ({ type: 'Transactions.cancelAssignedToUsersFilter', assignedToUsers }),
    onCancelCategorizedByUsersFilter: categorizedByUsers => ({ type: 'Transactions.cancelCategorizedByUsersFilter', categorizedByUsers }),
    onOpenCategoryFilter: categoryId => ({ type: 'Transactions.openCategoryFilter', categoryId }),
    onClearCategoryFilter: categoryId => ({ type: 'Transactions.removeCategoryFilter', categoryId }),
    onClearReviewerFilters: () => ({ type: 'Transactions.clearReviewerFilters' }),
    onMyAssignments: () => ({ type: 'Transactions.setReviewerFilters' }),
    onClearCuratorFilters: () => ({ type: 'Transactions.clearCuratorFilters' }),
    onNeedsVerification: () => ({ type: 'Transactions.setFilterNeedsVerification' }),
    onAssignedToUsersFilterChangeFromDialog: (usersToAssign, usersToUnassign) => (dispatch, getState) => {
      const assignedToUsers = getState().transactions.assignedToUsers.concat(usersToAssign).subtract(usersToUnassign);
      dispatch({ type: 'Transactions.setFilterAssignedToUsers', assignedToUsers });
    },
    onCategorizedByUsersFilterChangeFromDialog: (usersToAssign, usersToUnassign) => (dispatch, getState) => {
      const categorizedByUsers = getState().transactions.categorizedByUsers.concat(usersToAssign).subtract(usersToUnassign);
      dispatch({ type: 'Transactions.setFilterCategorizedByUsers', categorizedByUsers });
    },
  }),
)(/**
 * Filter dialog on the categorization transactions page
 */
  class TransactionFilter extends React.Component {
    static propTypes = {
      assignedToUsers: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
      categorizedByUsers: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
      categoryIds: ImmutablePropTypes.listOf(PropTypes.number).isRequired,
      filterAssignedToMeComplete: PropTypes.bool,
      filterAssignedToMeToDo: PropTypes.bool,
      filterAssignmentStatusAll: PropTypes.bool,
      filterAssignmentStatusNone: PropTypes.bool,
      filterAssignmentStatusSome: PropTypes.bool,
      filterAssignmentStatusUnassigned: PropTypes.bool,
      filterCategorizedByMe: PropTypes.bool,
      filterConfidence: PropTypes.instanceOf(ConfidenceFilter),

      filterExpertsNone: PropTypes.bool,
      filterExpertsPending: PropTypes.bool,
      filterExpertsResponsesAgree: PropTypes.bool,
      filterExpertsResponsesDisagree: PropTypes.bool,
      filterExpertsSome: PropTypes.bool,
      filterExpertsUnsure: PropTypes.bool,

      filterHasComments: PropTypes.bool,
      filterHasConfidence: PropTypes.bool,
      filterHighImpact: PropTypes.bool,

      filterLabelAgreesWithTamr: PropTypes.bool,
      filterLabelDisagreesWithTamr: PropTypes.bool,
      filterLabeledByTamr: PropTypes.bool,
      filterLabeledByUser: PropTypes.bool,
      filterShowExternalData: PropTypes.bool,
      filterUnlabeled: PropTypes.bool,
      filterUnlabeledByTamr: PropTypes.bool,

      hasSuggestions: PropTypes.bool.isRequired,
      isUserACurator: PropTypes.bool.isRequired,

      loggedInUsername: PropTypes.string.isRequired,

      /**
     * Callback that is invoked when changing the selection of datasets.  Note: returns a js array
     */
      onAssignedToMeCompleteFilterChange: PropTypes.func.isRequired,
      onAssignedToMeToDoFilterChange: PropTypes.func.isRequired,
      onAssignedToUsersFilterChange: PropTypes.func.isRequired,
      onAssignedToUsersFilterChangeFromDialog: PropTypes.func.isRequired,
      onAssignmentStatusAllFilterChange: PropTypes.func.isRequired,
      onAssignmentStatusNoneFilterChange: PropTypes.func.isRequired,
      onAssignmentStatusSomeFilterChange: PropTypes.func.isRequired,
      onAssignmentStatusUnassignedFilterChange: PropTypes.func.isRequired,
      onCancelAssignedToUsersFilter: PropTypes.func.isRequired,
      onCancelCategorizedByUsersFilter: PropTypes.func.isRequired,
      onCategorizedByMeFilterChange: PropTypes.func.isRequired,
      onCategorizedByUsersFilterChange: PropTypes.func.isRequired,
      onCatgorizedByUsersFilterChangeFromDialog: PropTypes.func.isRequired,

      onClearCategoryFilter: PropTypes.func.isRequired,
      onClearCuratorFilters: PropTypes.func.isRequired,
      onClearReviewerFilters: PropTypes.func.isRequired,
      onConfidenceChange: PropTypes.func.isRequired,
      onConfidenceHighChange: PropTypes.func.isRequired,
      onConfidenceLowChange: PropTypes.func.isRequired,
      onConfidenceMediumChange: PropTypes.func.isRequired,

      onExpertResponsesAgreeFilterChange: PropTypes.func.isRequired,
      onExpertResponsesDisagreeFilterChange: PropTypes.func.isRequired,
      onExpertsNoneFilterChange: PropTypes.func.isRequired,
      onExpertsPendingFilterChange: PropTypes.func.isRequired,
      onExpertsSomeFilterChange: PropTypes.func.isRequired,
      onExpertsUnsureFilterChange: PropTypes.func.isRequired,

      onExternalDataFilterChange: PropTypes.func.isRequired,

      onHasCommentsFilterChange: PropTypes.func.isRequired,
      onHasConfidenceFilterChange: PropTypes.func.isRequired,
      onHighImpactFilterChange: PropTypes.func.isRequired,

      onLabelAgreesWithTamrFilterChange: PropTypes.func.isRequired,
      onLabelDisagreesWithTamrFilterChange: PropTypes.func.isRequired,
      onLabeledByTamrFilterChange: PropTypes.func.isRequired,
      onLabeledByUserFilterChange: PropTypes.func.isRequired,
      onMyAssignments: PropTypes.func.isRequired,
      onNeedsVerification: PropTypes.func.isRequired,

      onOpenAssignedToUsersFilter: PropTypes.func.isRequired,
      onOpenCategorizedByUsersFilter: PropTypes.func.isRequired,
      onOpenCategoryFilter: PropTypes.func.isRequired,

      onUnlabeledByTamrFilterChange: PropTypes.func.isRequired,
      onUnlabeledFilterChange: PropTypes.func.isRequired,
      recipe: Document.propType.withDataType(Recipe).isRequired,
      recipeId: PropTypes.number.isRequired,

      resetDatasetFilter: PropTypes.func.isRequired,

      selectedDatasetIds: ImmutablePropTypes.setOf(PropTypes.number).isRequired,
      showAssignedToUserFilter: PropTypes.bool.isRequired,
      showCategorizedByUsersFilter: PropTypes.bool.isRequired,
      sourceDatasetDocs: ImmutablePropTypes.listOf(Document.propType.withDataType(Dataset)).isRequired,
      strengthThresholds: PropTypes.instanceOf(ScoreThresholds),
      taxonomy: PropTypes.instanceOf(ProcurementTaxonomy),
    };

    state = {
      sliderKey: 1, // to force reconsideration of default handle positions after input change
      inputKey: 1, // to force reconsideration of default input values after slider change
      lowerBound: 0,
      upperBound: 1,
    };

    UNSAFE_componentWillMount() {
      const { filterConfidence } = this.props;
      if (filterConfidence) {
        this.setState({ lowerBound: filterConfidence.lowerBound, upperBound: filterConfidence.upperBound });
      }
    }

    getSourceFilterHeight = (sourceDatasets) => {
      const numDatasets = sourceDatasets ? sourceDatasets.size : 0;
      const maxDatasetInScroll = 8;
      const datasetHeightElement = 35;
      const searchHeight = 35;
      const padding = numDatasets ? 7 : 0;
      return padding + searchHeight + Math.min(maxDatasetInScroll, numDatasets) * datasetHeightElement;
    };

    renderSectionTitle = (title, clearAllAction, first) => {
      return (
        <div className={classNames('section-title-container', { first })}>
          <div className="section-title">
            {title}
          </div>
          <div className="section-clear-button">
            <Button buttonType="Link" onClick={clearAllAction}>clear</Button>
          </div>
        </div>
      );
    };

    renderHelpBubble = (text) => {
      return (
        <TooltipTrigger placement="right" content={text}>
          <TamrIcon
            className="help-bubble"
            size={12}
            iconName="info-outline"
        />
        </TooltipTrigger>
      );
    };

    renderAssignedToUsersDialog = () => {
      const { assignedToUsers, showAssignedToUserFilter, onAssignedToUsersFilterChangeFromDialog, onCancelAssignedToUsersFilter } = this.props;
      const selectionActivator = 1;
      const userAssignmentsSelected = (user) => {
        return assignedToUsers.contains(user) ? selectionActivator : 0;
      };
      return (
        <FeedbackAssignDialog
          actionName="Update Filter"
          titleMessage="Filter to assigned users"
          userAssignmentCount={userAssignmentsSelected}
          showDialog={showAssignedToUserFilter}
          onAssign={onAssignedToUsersFilterChangeFromDialog}
          onHide={onCancelAssignedToUsersFilter}
          recordCount={selectionActivator}
      />
      );
    };
    renderCategorizedByUsersDialog = () => {
      const { categorizedByUsers, showCategorizedByUsersFilter, onCategorizedByUsersFilterChangeFromDialog, onCancelCategorizedByUsersFilter } = this.props;
      const selectionActivator = 1;
      const userAssignmentsSelected = (user) => {
        return categorizedByUsers.contains(user) ? selectionActivator : 0;
      };
      return (
        <FeedbackAssignDialog
          actionName="Update Filter"
          titleMessage="Filter to users"
          userAssignmentCount={userAssignmentsSelected}
          showDialog={showCategorizedByUsersFilter}
          onAssign={onCategorizedByUsersFilterChangeFromDialog}
          onHide={onCancelCategorizedByUsersFilter}
          recordCount={selectionActivator}
        />
      );
    };

    renderCategory = (category) => {
      const { taxonomy } = this.props;
      return (
        <TooltipTrigger
          className="category-tooltip"
          placement="top"
          content={(
            <div className="category-tree-container">
              <CategoryTree {...{ taxonomy, category }} />
            </div>
        )}
      >
          <span className="category-filter-item">&gt; &gt; {category.name}</span>
        </TooltipTrigger>
      );
    };

    onConfidenceChangeUncommitted = ([lowerBound, upperBound]) => {
      this.setState({ lowerBound, upperBound, inputKey: this.state.inputKey + 1 });
    };

    onConfidenceChangeSlider = ([lowerBound, upperBound]) => {
      this.setState({ inputKey: this.state.inputKey + 1 });
      return this.onConfidenceChange([lowerBound, upperBound]);
    };

    onConfidenceChangeInput = ([lowerBound, upperBound]) => {
      this.setState({ sliderKey: this.state.sliderKey + 1 });
      return this.onConfidenceChange([lowerBound, upperBound]);
    };

    onConfidenceChange = ([lowerBound, upperBound]) => {
      const { onConfidenceChange } = this.props;
      this.setState({ lowerBound, upperBound });
      onConfidenceChange(new ConfidenceFilter({ lowerBound, upperBound }));
    };

    renderConfidenceSlider = () => {
      const { filterHasConfidence, filterConfidence, strengthThresholds } = this.props;
      const { sliderKey, inputKey, lowerBound, upperBound } = this.state;

      if (!filterHasConfidence || !filterConfidence) {
        return undefined;
      }
      const { medium, high } = strengthThresholds;
      return (
        <div className="confidence-value">
          <BoundInput
            key={`lower-${inputKey}`}
            value={lowerBound}
            title="Lower Bound"
            onChange={e => this.onConfidenceChangeInput([parseFloat(e.target.value, 10) / 100, filterConfidence.upperBound])}
        />
          <Range
            key={sliderKey}
            range
            allowCross={false}
            className="range-slider"
            min={0}
            max={1}
            step={0.01}
            defaultValue={[filterConfidence.lowerBound, filterConfidence.upperBound]}
            onChange={this.onConfidenceChangeUncommitted}
            onAfterChange={this.onConfidenceChangeSlider}
            marks={{
              0: { style: { textAlign: 'center', marginLeft: 0, left: 0, width: shortPerc(medium, 1) }, label: <ScoreIcon className="score-icon-legend" forceSymbol={Symbol.L} size={10} /> },
              [medium]: { style: { textAlign: 'center', marginLeft: 0, left: shortPerc(medium, 1), width: shortPerc(high - medium, 1) }, label: <ScoreIcon className="score-icon-legend" forceSymbol={Symbol.M} size={10} /> },
              [high]: { style: { textAlign: 'center', marginLeft: 0 }, left: shortPerc(high, 1), width: shortPerc(1 - high, 1), label: <ScoreIcon className="score-icon-legend" forceSymbol={Symbol.H} size={10} /> },
              1: { label: '' },
            }}
        />
          <BoundInput
            key={`upper-${inputKey}`}
            value={upperBound}
            title="Upper Bound"
            onChange={e => this.onConfidenceChangeInput([filterConfidence.lowerBound, parseFloat(e.target.value, 10) / 100])}
        />
        </div>
      );
    };

    renderConfidenceSection = () => {
      const { hasSuggestions, onConfidenceHighChange, filterConfidenceHigh, onConfidenceMediumChange, filterConfidenceMedium, onConfidenceLowChange, filterConfidenceLow, onHasConfidenceFilterChange, filterHasConfidence } = this.props;
      if (!hasSuggestions) {
        return;
      }
      return (
        <div>
          <div className="filter-panel-header">
            Confidence
          </div>
          <div className="confidence-high confidence-checkbox">
            <Checkbox
              onChange={onConfidenceHighChange}
              title="High"
              value={filterConfidenceHigh}
              size={15}
              titlePosition="right"
          />
            <ScoreIcon
              className="score-icon"
              forceSymbol={Symbol.H}
              size={10}
          />
          </div>
          <div className="confidence-medium confidence-checkbox">
            <Checkbox
              onChange={onConfidenceMediumChange}
              title="Medium"
              value={filterConfidenceMedium}
              size={15}
              titlePosition="right"
          />
            <ScoreIcon
              className="score-icon"
              forceSymbol={Symbol.M}
              size={10}
          />
          </div>
          <div className="confidence-low confidence-checkbox">
            <Checkbox
              onChange={onConfidenceLowChange}
              title="Low"
              value={filterConfidenceLow}
              size={15}
              titlePosition="right"
          />
            <ScoreIcon
              className="score-icon"
              forceSymbol={Symbol.L}
              size={10}
          />
          </div>
          <div className="has-confidence-checkbox">
            <Checkbox
              onChange={onHasConfidenceFilterChange}
              title="Custom Range"
              value={filterHasConfidence}
              size={15}
              titlePosition="right"
          />
            {this.renderConfidenceSlider()}
          </div>
        </div>
      );
    };

    render() {
      const { taxonomy, loggedInUsername, categoryIds, sourceDatasetDocs, resetDatasetFilter, onAssignedToMeCompleteFilterChange, onAssignedToMeToDoFilterChange, filterAssignedToMeComplete, filterAssignedToMeToDo, onOpenCategoryFilter, onClearCategoryFilter, onAssignmentStatusNoneFilterChange, onAssignmentStatusSomeFilterChange, onAssignmentStatusAllFilterChange, onAssignmentStatusUnassignedFilterChange, filterAssignmentStatusNone, filterAssignmentStatusSome, filterAssignmentStatusAll, filterAssignmentStatusUnassigned, onLabeledByUserFilterChange, filterLabeledByUser, onUnlabeledFilterChange, filterUnlabeled, onLabelAgreesWithTamrFilterChange, filterLabelAgreesWithTamr, onLabelDisagreesWithTamrFilterChange, filterLabelDisagreesWithTamr, onOpenAssignedToUsersFilter, onOpenCategorizedByUsersFilter, onCategorizedByUsersFilterChange, onAssignedToUsersFilterChange, categorizedByUsers, assignedToUsers, selectedDatasetIds, onNeedsVerification, onClearCuratorFilters, onClearReviewerFilters, onMyAssignments, filteredToMyAssignments, filteredToNeedsVerification } = this.props;
      if (!taxonomy) {
        return <div />;
      }
      const selectUsersButton = (
        <Button buttonType="Link" onClick={onOpenAssignedToUsersFilter} className="select-trigger-button">
          Select users
        </Button>
      );

      const selectCategoryUsersButton = (
        <Button buttonType="Link" onClick={onOpenCategorizedByUsersFilter} className="select-trigger-button">
          Select users
        </Button>
      );
      const selectDatasetsButton = <CategorizationDatasetFilter />;

      const addCategoryButton = (
        <Button buttonType="Link" onClick={() => onOpenCategoryFilter(undefined)} className="select-trigger-button">
          Add category
        </Button>
      );

      return (
        <div className="transaction-filter-component">
          <div className="transaction-filter">
            {this.renderSectionTitle('Reviewer Filters', onClearReviewerFilters, true)}
            <div className="filter-panel-button">
              <Button
                buttonType={filteredToMyAssignments ? 'Shaded' : 'Secondary'}
                onClick={onMyAssignments}
            >
                My assignments
              </Button>
            </div>
            <div className="filter-panel-header first">
              My assignment status
            </div>
            <div className="tamr-assigned-to-me-complete">
              <Checkbox
                onChange={onAssignedToMeCompleteFilterChange}
                title="Completed"
                value={filterAssignedToMeComplete}
                size={15}
                titlePosition="right"
            />
            </div>
            <div className="tamr-assigned-to-me-todo">
              <Checkbox
                onChange={onAssignedToMeToDoFilterChange}
                title="To-do"
                value={filterAssignedToMeToDo}
                size={15}
                titlePosition="right"
            />
            </div>
            {this.renderSectionTitle('Curator Filters', onClearCuratorFilters, false)}
            <div className="filter-panel-button">
              <Button
                buttonType={filteredToNeedsVerification ? 'Shaded' : 'Secondary'}
                onClick={onNeedsVerification}
            >
                Needs Verification
              </Button>
            </div>
            <div className="filter-panel-header first">
              High Impact Records
            </div>
            <div className="tamr-high-impact-records">
              <Checkbox
                onChange={this.props.onHighImpactFilterChange}
                title={
                  <span title="High Impact" className="high-impact-wrapper">
                    High impact
                    <TamrIcon iconName="tamr-icon-lightning-bolt" className="high-impact-icon" size={16} />
                  </span>
              }
                value={this.props.filterHighImpact}
                size={15}
                titlePosition="right"
            />
            </div>
            <div className="filter-panel-header first">
              Assignments
            </div>
            <div className="tamr-assignments-unassigned">
              <Checkbox
                onChange={onAssignmentStatusUnassignedFilterChange}
                title="Not assigned"
                value={filterAssignmentStatusUnassigned}
                size={15}
                titlePosition="right"
            />
            </div>
            <div className="tamr-assignments-assigned">
              <Checkbox
                onChange={this.props.onAssignmentStatusAssignedFilterChange}
                title="Assigned"
                value={this.props.filterAssignmentStatusAssigned}
                size={15}
                titlePosition="right"
                semiChecked={this.props.assignmentStatusAssignedSemiChecked}
            />
            </div>
            <div className="tamr-assignments-all indented">
              <Checkbox
                onChange={onAssignmentStatusAllFilterChange}
                title="Everyone responded"
                value={filterAssignmentStatusAll}
                size={15}
                titlePosition="right"
            />
            </div>
            <div className="tamr-assignments-some indented">
              <Checkbox
                onChange={onAssignmentStatusSomeFilterChange}
                title="Some responded"
                value={filterAssignmentStatusSome}
                size={15}
                titlePosition="right"
            />
            </div>
            <div className="tamr-assignments-none indented">
              <Checkbox
                onChange={onAssignmentStatusNoneFilterChange}
                title="No one responded"
                value={filterAssignmentStatusNone}
                size={15}
                titlePosition="right"
            />
            </div>
            <div className="filter-panel-header">
              Categorizations
            </div>
            <div className="experts-none-checkbox">
              <Checkbox
                onChange={this.props.onExpertsNoneFilterChange}
                title="No user feedback"
                value={this.props.filterExpertsNone}
                size={15}
                titlePosition="right"
            />
            </div>
            <div className="experts-some-checkbox">
              <Checkbox
                onChange={this.props.onExpertsSomeFilterChange}
                title="User feedback"
                value={this.props.filterExpertsSome}
                size={15}
                titlePosition="right"
                semiChecked={this.props.expertsSemiChecked}
            />
            </div>
            <div className="experts-unsure-checkbox indented">
              <Checkbox
                onChange={this.props.onExpertsUnsureFilterChange}
                title="Skipped"
                value={this.props.filterExpertsUnsure}
                size={15}
                titlePosition="right"
            />
            </div>
            <div className="experts-agree-checkbox indented">
              <Checkbox
                onChange={this.props.onExpertResponsesAgreeFilterChange}
                title="Users suggested single category"
                value={this.props.filterExpertsResponsesAgree}
                size={15}
                titlePosition="right"
            />
              <div className="filter-panel-info">
                {this.renderHelpBubble(<span>Filter to <Term>records</Term> where user(s) have suggested only one category for this <Term>part</Term> with no disagreement.</span>)}
              </div>
            </div>
            <div className="experts-disagree-checkbox indented">
              <Checkbox
                onChange={this.props.onExpertResponsesDisagreeFilterChange}
                title="Users disagreed on categories"
                value={this.props.filterExpertsResponsesDisagree}
                size={15}
                titlePosition="right"
            />
              <div className="filter-panel-info">
                {this.renderHelpBubble(<span>Filter to <Term>records</Term> where users disagree with each other's categorizations for this <Term>part</Term>.</span>)}
              </div>
            </div>
            <div className="filter-panel-header">
              Categorized By {!categorizedByUsers.isEmpty() ? selectCategoryUsersButton : undefined}
            </div>
            <div className="selected-list-container">
              {categorizedByUsers.isEmpty() ? selectCategoryUsersButton : undefined}
              {
              categorizedByUsers.sort().map((user, i) => (
                <div key={`user-${i}`} className="list-item">
                  <span className="item-name" title={user}>
                    {`${user}${loggedInUsername === user ? ' (You)' : ''}`}
                  </span>
                  <Button
                    className="remove-icon"
                    buttonType="Link"
                    icon="tamr-icon-close"
                    onClick={() => onCategorizedByUsersFilterChange(categorizedByUsers.delete(user))}
                  />
                </div>
              ))
            }
            </div>
            <div className="filter-panel-header">
              Verification {this.renderHelpBubble(<span>{PRODUCT_NAME} only learns from responses that have been verified by curators or admins. These help {PRODUCT_NAME} suggest better categorizations for <Term>parts</Term>.</span>)}
            </div>
            <div className="unlabeled-checkbox">
              <Checkbox
                onChange={onUnlabeledFilterChange}
                title="Not verified"
                value={filterUnlabeled}
                size={15}
                titlePosition="right"
            />
            </div>
            <div className="user-labeled-checkbox">
              <Checkbox
                onChange={onLabeledByUserFilterChange}
                title="Verified"
                value={filterLabeledByUser}
                size={15}
                titlePosition="right"
                semiChecked={this.props.userLabeledSemiChecked}
            />
              <TooltipTrigger placement="right" content={<span>{PRODUCT_NAME} only learns from responses that have been verified by curators or admins. These help {PRODUCT_NAME} suggest better categorizations for <Term>records</Term>.</span>}>
                <VerifiedIcon
                  className="verified-icon"
                  size={14}
              />
              </TooltipTrigger>
            </div>
            <div className="user-labeled-agree-checkbox indented">
              <Checkbox
                onChange={onLabelAgreesWithTamrFilterChange}
                title={`Agrees with ${PRODUCT_NAME}`}
                value={filterLabelAgreesWithTamr}
                size={15}
                titlePosition="right"
            />
            </div>
            <div className="user-labeled-disagree-checkbox indented">
              <Checkbox
                onChange={onLabelDisagreesWithTamrFilterChange}
                title={`Disagrees with ${PRODUCT_NAME}`}
                value={filterLabelDisagreesWithTamr}
                size={15}
                titlePosition="right"
            />
            </div>
            <div className="user-labeled-no-tamr-suggestion indented">
              <Checkbox
                onChange={this.props.onUnlabeledByTamrFilterChange}
                title={`No ${PRODUCT_NAME} suggestion`}
                value={this.props.filterUnlabeledByTamr}
                size={15}
                titlePosition="right"
            />
            </div>
            {this.renderConfidenceSection()}
            <div className="filter-panel-header">
              Comments
            </div>
            <div className="has-comments-checkbox">
              <Checkbox
                onChange={this.props.onHasCommentsFilterChange}
                title="Has comments"
                value={this.props.filterHasComments}
                size={15}
                titlePosition="right"
            />
            </div>
            <div className="filter-panel-header">
              Assigned to {!assignedToUsers.isEmpty() ? selectUsersButton : undefined}
            </div>
            <div className="selected-list-container">
              {assignedToUsers.isEmpty() ? selectUsersButton : undefined}
              {
              assignedToUsers.sort().map((user, i) => (
                <div key={`user-${i}`} className="list-item">
                  <span className="item-name" title={user}>
                    {`${user}${loggedInUsername === user ? ' (You)' : ''}`}
                  </span>
                  <Button
                    className="remove-icon"
                    buttonType="Link"
                    icon="tamr-icon-close"
                    onClick={() => onAssignedToUsersFilterChange(assignedToUsers.delete(user))}
                  />
                </div>
              ))
            }
            </div>
            <div className="filter-panel-header dataset-header">
              Categories {!categoryIds.isEmpty() ? addCategoryButton : undefined}
            </div>
            <div className="selected-list-container">
              {categoryIds.isEmpty() ? addCategoryButton : undefined}
              {
              categoryIds.sort().map((catId, i) => {
                const category = taxonomy && taxonomy.findCategoryById(catId);
                return (
                  <div key={`category-${i}`} className="list-item">
                    <span className="item-name">
                      {this.renderCategory(category)}
                    </span>
                    <Button
                      className="remove-icon"
                      buttonType="Link"
                      icon="tamr-icon-close"
                      onClick={() => onClearCategoryFilter(catId)}
                    />
                    <Button
                      className="edit-icon"
                      buttonType="Link"
                      icon="edit"
                      onClick={() => onOpenCategoryFilter(catId)}
                    />
                  </div>
                );
              })
            }
            </div>
            <div className="filter-panel-header dataset-header">
              Sources {!selectedDatasetIds.isEmpty() ? selectDatasetsButton : undefined}
            </div>
            <div className="selected-list-container">
              {selectedDatasetIds.isEmpty() ? selectDatasetsButton : undefined}
              {
              selectedDatasetIds.sort().map((selId, i) => {
                const ds = sourceDatasetDocs.find(doc => doc.id.id === selId);
                // Note: fall back to include id of dataset if it isn't found in the project datasets
                const dsName = getPath(ds, 'data', 'name') || `Dataset id ${i}`;
                return (
                  <div key={`dataset-${i}`} className="list-item">
                    <span className="item-name" title={dsName}>
                      {dsName}
                    </span>
                    <Button
                      className="remove-icon"
                      buttonType="Link"
                      icon="tamr-icon-close"
                      onClick={() => resetDatasetFilter()}
                    />
                  </div>
                );
              })
            }
            </div>
            <div className="external-data-checkbox">
              <Checkbox
                onChange={this.props.onExternalDataFilterChange}
                title="Show external data"
                value={this.props.filterShowExternalData}
                size={15}
                titlePosition="right"
            />
              {
              this.renderHelpBubble(
                <span>
                  This will display any external data you are using in the <Term>records</Term> table.
                  This data will not impact dashboards or metrics.
                  External data is marked with a yellow background.
                </span>,
              )
            }
              <span className="yellow-box" />
            </div>
          </div>
          {this.renderAssignedToUsersDialog()}
          {this.renderCategorizedByUsersDialog()}
        </div>
      );
    }
  });

export default TransactionFilter;
