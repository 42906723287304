import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { pure } from 'recompose';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import LoadingPanel from '../components/LoadingPanel';

const FeedbackAssignDialogFooter = _.compose(
  pure,
)(class FeedbackAssignDialogFooter extends React.Component {
  static propTypes = {
    actionName: PropTypes.string, // If specified overrides the default CTA button text
    busy: PropTypes.bool,
    onAssign: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    onSelectAll: PropTypes.func.isRequired,
    recordCount: PropTypes.number.isRequired,
    resetState: PropTypes.func.isRequired,
    usersToAssign: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
    usersToUnassign: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
  };

  onAssign = () => {
    const { onAssign, usersToAssign, usersToUnassign } = this.props;
    onAssign(usersToAssign, usersToUnassign);
  };

  onHide = () => {
    this.props.onHide.call(this);
    this.props.resetState();
  };

  render() {
    const { actionName, busy, usersToAssign, usersToUnassign, onSelectAll } = this.props;
    const primaryButtonValue = actionName || 'Update Assignments';
    return (
      <div className="footer-buttons">
        {busy ? <LoadingPanel /> : null}
        <ButtonToolbar>
          <Button buttonType="Link" onClick={() => onSelectAll(true)}>Select All</Button>
          <Button buttonType="Link" onClick={() => onSelectAll(false)}>Unselect All</Button>
          <Button style={{ marginLeft: 'auto' }} buttonType="Secondary" onClick={this.onHide}>Cancel</Button>
          <Button
            buttonType="Primary"
            onClick={this.onAssign}
            disabled={usersToAssign.isEmpty() && usersToUnassign.isEmpty()}
          >
            {primaryButtonValue}
          </Button>
        </ButtonToolbar>
      </div>
    );
  }
});

export default FeedbackAssignDialogFooter;
