import { is } from 'immutable';

import createLoader from '../utils/createLoader';
import { fetchClusterProfileJob } from './GoldenRecordsAsync';
import { selectClusterProfileJobFilterInfo } from './GoldenRecordsStore';


// determines whether the cluster profile job is out of date
const ClusterProfileJobLoader = createLoader((state) => {
  const { goldenRecords, goldenRecords: { loadedClusterProfileJobFilterInfo, loadingClusterProfileJob } } = state;
  const filterInfo = selectClusterProfileJobFilterInfo(goldenRecords);
  return {
    canFetch: !!filterInfo.publishedDatasetName,
    shouldFetch: !is(filterInfo, loadedClusterProfileJobFilterInfo),
    loading: loadingClusterProfileJob,
  };
}, {
  onFetch: fetchClusterProfileJob,
}, 'ClusterProfileJobLoader');

export default ClusterProfileJobLoader;
