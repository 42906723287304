import ClusterAssignment from '../models/ClusterAssignment';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import { StoreReducers } from '../stores/AppAction';
import { ArgTypes } from '../utils/ArgValidation';


/**
 * A store which the total cluster feedback assignments that a user has
 */
class ClusterFeedbackStore extends getModelHelpers({
  clusterAssignments: { type: ArgTypes.array.of(ArgTypes.instanceOf(ClusterAssignment)), defaultValue: [] },
  loading: { type: ArgTypes.bool, defaultValue: false },
  loaded: { type: ArgTypes.bool, defaultValue: false },
}, 'ClusterFeedbackStore')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ClusterFeedbackStoreRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

export const initialState = new ClusterFeedbackStore({});

export const reducers: StoreReducers<ClusterFeedbackStore> = {
  'ClusterFeedback.fetchFeedback': (state) => {
    return state.merge({
      loading: true,
      loaded: false,
    });
  },

  'ClusterFeedback.fetchFeedbackCompleted': (state, { clusterAssignments }) => {
    return state.merge({
      clusterAssignments,
      loading: false,
      loaded: true,
    });
  },

  'ClusterFeedback.fetchFeedbackFailed': (state) => {
    return state.merge({
      loading: false,
      loaded: true,
    });
  },
};
