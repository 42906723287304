import { Record } from 'immutable';

import Model from '../models/Model';
import { ArgTypes } from '../utils/ArgValidation';
import { getActiveNumSources, getActiveRecipeWithStatus, selectActiveProjectInfo } from '../utils/Selectors';

const CategorizationStatus = Model({
  datasets: { type: ArgTypes.bool, defaultValue: false },
  taxonomy: { type: ArgTypes.bool, defaultValue: false },
  unifiedDataset: { type: ArgTypes.bool, defaultValue: false },
  categorize: { type: ArgTypes.bool, defaultValue: false },
});

export const getCategorizationStatus = state => {
  return new CategorizationStatus({
    datasets: getActiveNumSources(state) > 0,
    taxonomy: !!(state.categorizationDashboard.taxonomy && state.categorizationDashboard.taxonomy.categories.size > 0),
    unifiedDataset: selectActiveProjectInfo(state).isUnifiedDatasetIndexed,
    categorize: !!(
      getActiveRecipeWithStatus(state).materializations.get('categorizations').lastRun ||
      getActiveRecipeWithStatus(state).materializations.get('predictCategorizations').lastRun
    ),
  });
};

const SchemaMappingStatus = Record({
  hasAddedDatasets: null,
  hasMappedAttributes: null,
});

export const getSchemaMappingStatus = state => {
  return new SchemaMappingStatus({
    hasAddedDatasets: getActiveNumSources(state) > 0,
    hasMappedAttributes: state?.schemaMapping?.allUnifiedAttributes.some(({ mappedAttributes }) => mappedAttributes.size),
  });
};
