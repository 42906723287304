import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import style from './JobCancelFailedDialog.module.scss';

const JobCancelFailedDialog = _.compose(
  connect(({ jobs: { showCancelError } }) => {
    return { showCancelError };
  }, {
    onClose: () => ({ type: 'Jobs.closeCancelAndRefresh' }),
  }),
)(class JobCancelFailedDialog extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    showCancelError: PropTypes.bool.isRequired,
  };

  render() {
    const { showCancelError, onClose } = this.props;
    return (
      <Dialog
        closeButton
        dialogStyle={DialogStyle.PRIMARY}
        title="Job cannot be canceled"
        body={
          <div>
            <div className={style.explanation}>This job cannot be canceled because it already finished.</div>
            Refresh for an updated view of your jobs.
          </div>
        }
        footer={
          <ButtonToolbar>
            <Button buttonType="Primary" onClick={onClose}>Refresh</Button>
          </ButtonToolbar>
        }
        show={showCancelError}
        onHide={onClose}
      />
    );
  }
});

export default JobCancelFailedDialog;
