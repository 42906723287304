import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { pure } from 'recompose';
import _ from 'underscore';

const DashboardCard = _.compose(
  pure,
)(class DashboardCard extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
  };

  render() {
    const className = classNames('dashboard-card', this.props.className, {
      clickable: !!this.props.onClick,
    });
    return (
      <div {...this.props} className={className}>
        {this.props.children}
      </div>
    );
  }
});

export default DashboardCard;
