import { isEqual } from 'lodash';

import createLoader from '../utils/createLoader';
import { queryDraft } from './GoldenRecordsAsync';
import { selectFilterInfo } from './GoldenRecordsSelectors';
import { lastRunIndexDraft } from './ModuleStatus';

const DraftRecordsLoader = createLoader(state => {
  const { moduleStatus, loading, loadedFilterInfo } = state.goldenRecords;
  const lastRun = moduleStatus && lastRunIndexDraft(moduleStatus);
  const filterInfo = selectFilterInfo(state);
  return {
    canFetch: !!lastRun, // indexDraft must have been run successfully before
    shouldFetch: !isEqual(filterInfo, loadedFilterInfo),
    loading,
  };
}, {
  onFetch: queryDraft,
}, 'DraftRecordsLoader');

export default DraftRecordsLoader;
