import '../errorDialog/ErrorDialog.scss';

import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import WarningDialog from '../components/WarningDialog';
import AppState from '../stores/AppState';
import style from './ForceSaveModuleDialog.module.scss';
import { CANCEL_FORCE_SAVE_MODULE, SAVE_MODULE_INVALID } from './GoldenRecordsActionTypes';
import { forceSaveModule } from './GoldenRecordsAsync';

const mapStateToProps = (state: AppState) => {
  const { goldenRecords: { saveModuleError } } = state;
  return { saveModuleError };
};

const mapDispatchToProps = {
  onAccept: forceSaveModule,
  onCancel: () => ({ type: CANCEL_FORCE_SAVE_MODULE }),
  onHide: () => ({ type: SAVE_MODULE_INVALID, saveModuleError: null }),
};

const ForceSaveModuleDialog = connect(
  mapStateToProps,
  mapDispatchToProps,
)(({ saveModuleError, onHide, onAccept, onCancel }) => {
  return saveModuleError && (<WarningDialog
    title={'Force Update'}
    actionName={'Force Update'}
    onAccept={onAccept}
    onHide={onHide}
    onCancel={onCancel}
    show={!!saveModuleError}
    message={(
      <div>
        <p>Attempting to update your golden record rules returns the following error:</p>
        <textarea
          className={classNames('advanced-detail-box', style.errorMessage)}
          value={saveModuleError.apiException.message}
          readOnly
          />
        <br />
        <p>
          Forcing the update will put your project into an invalid state.
        </p>
      </div>
      )}
    />);
},
);

export default ForceSaveModuleDialog;
