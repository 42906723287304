import { List } from 'immutable';
import React from 'react';

import Button from '../components/Button';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { pluralize } from '../utils/Strings';
import styles from './GeospatialDetailsDialog.module.scss';
import * as geotamr from './GeoTamr';
import LongLatTable from './LongLatTable';

/**
 * Dialog for showing a table of long/lat values for each GeoTamr in a specific
 * attribute.
 */
function GeospatialDetailsDialog({
  attributeName,
  geoTamrs,
  isOpen,
  close,
}) {
  checkArg({ attributeName }, ArgTypes.string);
  checkArg({ geoTamrs }, ArgTypes.array.of(geotamr.GeoTamr.argType));
  checkArg({ isOpen }, ArgTypes.bool);
  checkArg({ close }, ArgTypes.func);
  const geoList = List(geoTamrs);
  return (
    <Dialog
      dialogStyle={DialogStyle.PRIMARY}
      header={<div className={styles.dialogTitle}>{attributeName}</div>}
      body={
        <div className={styles.dialogBody}>
          {geoList.filter(g => g).map((gt, i) => (
            <React.Fragment key={i}>
              {i === 0
                ? null
                : <div className={styles.dialogBodySeparator} />
              }
              <div className={styles.dialogBodyElement}>
                <div className={styles.dialogBodyHeading}>
                  {geotamr.size(gt)} {pluralize(geotamr.size(gt), geotamr.displayName(gt), geotamr.displayName(gt) + 's')}
                </div>
                {geoTamrs.length === 1
                  ? null
                  : <div className={styles.dialogBodyTopOrBottom}>
                    {i === 0
                      ? 'Top record'
                      : 'Bottom record'
                    }
                  </div>
                }
                {geotamr.longLatLists(gt).map((longLats, idx) => (
                  <div key={'longLat' + idx} className={styles.longLatTable}>
                    {LongLatTable({ longLats })}
                  </div>
                ))}
              </div>
            </React.Fragment>
          ))}
        </div>
      }
      footer={<Button buttonType="Primary" onClick={close}>Done</Button>}
      show={isOpen}
      onHide={close}
    />
  );
}

export default GeospatialDetailsDialog;
