import { Map } from 'immutable';

import { TRANSFER_CLOUD_TO_TAMR_COMPLETED, TRANSFER_TAMR_TO_CLOUD_COMPLETED } from '../coreConnectService/CoreConnectServiceActionTypes';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import { AppAction, Reducer, StoreReducers } from '../stores/AppAction';
import Pane from '../suppliers/Pane';
import { MOVE_RECORDS_COMPLETED } from '../suppliers/SuppliersActionTypes';
import VerificationType from '../suppliers/VerificationType';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { routes } from '../utils/Routing';
import NotificationType, { NotificationTypeE } from './NotificationType';

const { CLUSTER_CHANGED, CLUSTER_MERGE, CLUSTER_MOVE_RECORDS, CLUSTER_MOVE_RECORDS_TO_NEW, DISCONNECTED, TRANSFORMATION_LIST_UPDATE, CoreConnect_JOB_SUBMITTED } = NotificationTypeE;

class NotificationStore extends getModelHelpers({
  notificationType: { type: ArgTypes.orUndefined(NotificationType.argType) },
  parameters: { type: ArgTypes.Immutable.map.of(ArgTypes.any, ArgTypes.string), defaultValue: Map<string, any>() },
  permanent: { type: ArgTypes.bool, defaultValue: false },
}, 'NotificationStore')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class NotificationStoreRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

export const initialState = new NotificationStore({});

type NotificationReducer<T extends AppAction> = Reducer<NotificationStore, T>;
const ifChangeable = <T extends AppAction>(reducer: NotificationReducer<T>) => (store: NotificationStore, params: T) => (store.permanent ? store : reducer(store, params));

export const reducers: StoreReducers<NotificationStore> = {
  'Location.change': (state, { location, oldPath }) => {
    if (oldPath !== location.pathname && routes.suppliers.match(oldPath)) {
      return initialState;
    }
    return state;
  },

  'Messaging.messageReceived': (state) => {
    return state.notificationType === DISCONNECTED ? state.clear() : state;
  },

  'Messaging.error': (state) => {
    return state.merge({
      notificationType: DISCONNECTED,
      parameters: Map(),
      permanent: true,
    });
  },

  'Notifications.hide': (state) => {
    return state.clear();
  },

  'Suppliers.mergeClustersCompleted': ifChangeable((state, { mergeClusterId, numMerged, pane, verificationType }) => {
    checkArg({ mergeClusterId }, ArgTypes.string);
    checkArg({ numMerged }, ArgTypes.number);
    checkArg({ verificationType }, VerificationType.argType);
    return state.merge({
      notificationType: CLUSTER_MERGE,
      parameters: Map({
        numMerged,
        targetClusterId: mergeClusterId,
        pane,
        verificationType,
      }),
    });
  }),

  [MOVE_RECORDS_COMPLETED]: ifChangeable((state, { numRecords, targetClusterId, targetClusterName, pane, verificationType }) => {
    checkArg({ numRecords }, ArgTypes.number);
    checkArg({ targetClusterId }, ArgTypes.string);
    checkArg({ targetClusterName }, ArgTypes.string);
    checkArg({ pane }, ArgTypes.valueIn(Pane));
    checkArg({ verificationType }, VerificationType.argType);
    return state.merge({
      notificationType: CLUSTER_MOVE_RECORDS,
      parameters: Map({
        numRecords,
        targetClusterName,
        targetClusterId,
        pane,
        verificationType,
      }),
    });
  }),

  'Suppliers.moveRecordsToNewCompleted': ifChangeable((state, { numRecords, targetClusterId, pane, verificationType }) => {
    checkArg({ numRecords }, ArgTypes.number);
    checkArg({ targetClusterId }, ArgTypes.string);
    checkArg({ pane }, ArgTypes.valueIn(Pane));
    checkArg({ verificationType }, VerificationType.argType);
    return state.merge({
      notificationType: CLUSTER_MOVE_RECORDS_TO_NEW,
      parameters: Map({ numRecords, targetClusterId, pane, verificationType }),
    });
  }),

  'Suppliers.clustersHaveChanged': (state) => {
    return state.merge({
      notificationType: CLUSTER_CHANGED,
      parameters: Map(),
      permanent: true,
    });
  },

  'Transforms.transformationListHasChanged': ifChangeable((state) => {
    return state.merge({
      notificationType: TRANSFORMATION_LIST_UPDATE,
      parameters: Map(),
      permanent: true,
    });
  }),

  [TRANSFER_CLOUD_TO_TAMR_COMPLETED]: (state) => {
    return state.merge({
      notificationType: CoreConnect_JOB_SUBMITTED,
      parameters: Map(),
      permanent: false,
    });
  },

  [TRANSFER_TAMR_TO_CLOUD_COMPLETED]: (state) => {
    return state.merge({
      notificationType: CoreConnect_JOB_SUBMITTED,
      parameters: Map(),
      permanent: false,
    });
  },
};
