import { connect } from 'react-redux';

import MouseTrackingDragLayer from '../components/MouseTrackingDragLayer';
import { AppState } from '../stores/MainStore';
import { pluralize } from '../utils/Strings';


const EditDatasetFilterDialogDragLayer = connect((state: AppState) => {
  const { goldenRecords: { selectedDatasetFilterSources } } = state;
  const getText = () => {
    const numDatasets = selectedDatasetFilterSources.size;
    return `${numDatasets} ${pluralize(numDatasets, 'dataset', 'datasets')}`;
  };
  return { getText };
})(MouseTrackingDragLayer);

export default EditDatasetFilterDialogDragLayer;
