import { List, Map } from 'immutable';

import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import Document from './doc/Document';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';
import UnifiedAttribute from './UnifiedAttribute';

// com.tamr.transform.models.BootstrapResponse
class BootstrapResponse extends getModelHelpers({
  updatedUnifiedAttributes: { type: ArgTypes.Immutable.list.of(Document.argTypeWithNestedClass(UnifiedAttribute)) },
  nameValidationErrors: { type: ArgTypes.Immutable.map.of(ArgTypes.Immutable.list.of(ArgTypes.string), ArgTypes.string) },
}, 'BootstrapResponse')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class BootstrapResponseRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static fromJSON(obj: $TSFixMe) {
    return new BootstrapResponse({
      updatedUnifiedAttributes: List(obj.updatedUnifiedAttributes).map(doc => Document.fromJSON(doc, data => UnifiedAttribute.fromJSON(data))),
      nameValidationErrors: Map<string, List<string>>(obj.nameValidationErrors).map(errs => List(errs)),
    });
  }
}

export default BootstrapResponse;
