import classNames from 'classnames';
import React, { RefObject } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { throttle } from 'underscore';

import { AppThunkAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { updateGlobalPreferences } from '../users/UsersAsync';
import style from './ClusterSampleDivider.module.scss';
import { CLUSTER_SAMPLE_TABLE_VERTICAL_PROPORTION_PREF_KEY, selectClusterSampleTableVerticalProportion } from './GoldenRecordsSelectors';


const updateClusterSampleTableVerticalProportion = (newProportion: number, skipSavingToServer: boolean): AppThunkAction<void> => (dispatch) => {
  dispatch(updateGlobalPreferences({ [CLUSTER_SAMPLE_TABLE_VERTICAL_PROPORTION_PREF_KEY]: newProportion }, skipSavingToServer));
};

const mapStateToProps = (state: AppState) => {
  const clusterSampleTableVerticalProportion = selectClusterSampleTableVerticalProportion(state);
  return { clusterSampleTableVerticalProportion };
};
const mapDispatchToProps = {
  onUpdateProportionPreference: updateClusterSampleTableVerticalProportion,
};

type ClusterSampleDividerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

type ClusterSampleDividerState = {
  dragging: boolean
}

const PREF_UPDATE_WAIT_MS = 1;
const SAMPLE_TABLE_MIN_PROPORTION = 0.15;
const SAMPLE_TABLE_MAX_PROPORTION = 0.85;

/**
 * Allows resizing the vertical page space occupied by the cluster sample table
 */
// TODO implement minimum-height guarantees for both the cluster table and the GR table
const ClusterSampleDivider = connect(
  mapStateToProps,
  mapDispatchToProps,
)(class UnconnectedClusterSampleDivider extends React.Component<ClusterSampleDividerProps, ClusterSampleDividerState> {
  divider: RefObject<HTMLDivElement>;

  state: ClusterSampleDividerState = {
    dragging: false,
  };

  constructor(props: ClusterSampleDividerProps) {
    super(props);
    this.divider = React.createRef<HTMLDivElement>();

    this.beginDragging = this.beginDragging.bind(this);
    this.stopDragging = this.stopDragging.bind(this);
    this.updateProportion = this.updateProportion.bind(this);
    this.debouncedProportionUpdate = this.debouncedProportionUpdate.bind(this);
  }

  updateProportion(event: MouseEvent): void {
    const dividerElement = ReactDOM.findDOMNode(this.divider?.current);
    if (!dividerElement) {
      console.error('Cannot update cluster table height - no handle to divider dom node');
      return;
    }
    const parentElement = dividerElement.parentElement;
    if (!parentElement) {
      console.error('Cannot update cluster table height - no handle to divider dom node parent');
      return;
    }
    const parentBoundingRect = parentElement.getBoundingClientRect();
    const parentHeight = parentBoundingRect.height;
    const parentTop = parentBoundingRect.top;
    const mouseY = event.clientY;
    const mouseYRelativeToParent = mouseY - parentTop;
    const mainTableProportion = mouseYRelativeToParent / parentHeight;
    const unconstrainedClusterSampleProportion = 1 - mainTableProportion;
    const clusterSampleProportion = Math.min(
      Math.max(unconstrainedClusterSampleProportion, SAMPLE_TABLE_MIN_PROPORTION),
      SAMPLE_TABLE_MAX_PROPORTION,
    );
    this.props.onUpdateProportionPreference(clusterSampleProportion, true);
  }

  debouncedProportionUpdate = throttle(this.updateProportion, PREF_UPDATE_WAIT_MS);

  beginDragging(): void {
    this.setState({ dragging: true });
    window.addEventListener('mousemove', this.debouncedProportionUpdate);
    window.addEventListener('mouseup', this.stopDragging);
  }

  stopDragging(): void {
    this.setState({ dragging: false });
    window.removeEventListener('mousemove', this.debouncedProportionUpdate);
    window.removeEventListener('mouseup', this.stopDragging);
    // save preference
    this.props.onUpdateProportionPreference(this.props.clusterSampleTableVerticalProportion, false);
  }

  render() {
    const { clusterSampleTableVerticalProportion } = this.props;
    return (
      <div
        ref={this.divider}
        style={{ top: `${(1 - clusterSampleTableVerticalProportion) * 100}%` }}
        className={classNames(style.divider, { [style.dragging]: this.state.dragging })}
        onMouseDown={this.beginDragging}
      />
    );
  }
});

export default ClusterSampleDivider;
