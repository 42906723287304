import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Button from '../components/Button';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import filterStyle from './FilterSection.module.scss';
import SelectSourcesButton from './SelectSourcesButton';

export default class SourceDatasetFilter extends React.Component {
  static propTypes = {
    dialogVisible: PropTypes.bool.isRequired,
    infoTooltipMessage: PropTypes.string.isRequired,
    modifyQuery: PropTypes.func.isRequired,
    onHideDialog: PropTypes.func.isRequired,
    onOpenDialog: PropTypes.func.isRequired,
    onRemoveSelectedSource: PropTypes.func.isRequired,
    onSetDialogVisible: PropTypes.func.isRequired,
    onSubmitDialog: PropTypes.func.isRequired,
    selectedSourceDatasets: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
  };

  render() {
    const { dialogVisible, infoTooltipMessage, modifyQuery, onHideDialog, onOpenDialog, onRemoveSelectedSource, onSetDialogVisible, onSubmitDialog, selectedSourceDatasets } = this.props;
    return (
      <div className={filterStyle.filterSection}>
        <div className={filterStyle.filterPanelHeader}>
          Sources
          <TooltipTrigger placement="right" content={<span>{infoTooltipMessage}</span>}>
            <TamrIcon className={filterStyle.help} iconName="info-outline" size={14} />
          </TooltipTrigger>
          {selectedSourceDatasets.isEmpty() ? null : (
            <SelectSourcesButton
              dialogVisible={dialogVisible}
              onOpenDialog={onOpenDialog}
              onHideDialog={onHideDialog}
              modifyQuery={modifyQuery}
              onSetDialogVisible={onSetDialogVisible}
              onSubmitDialog={onSubmitDialog}
              selectedDatasets={selectedSourceDatasets}
            />
          )}
        </div>
        {selectedSourceDatasets.isEmpty()
          ? (
            <SelectSourcesButton
              dialogVisible={dialogVisible}
              onOpenDialog={onOpenDialog}
              onHideDialog={onHideDialog}
              modifyQuery={modifyQuery}
              onSetDialogVisible={onSetDialogVisible}
              onSubmitDialog={onSubmitDialog}
              selectedDatasets={selectedSourceDatasets}
            />
          )
          : (
            <div>
              {selectedSourceDatasets.sort().map(name => (
                <div className={filterStyle.sourceEntry} key={name}>
                  {name}
                  <Button
                    className={filterStyle.closeIcon}
                    buttonType="Link"
                    onClick={() => onRemoveSelectedSource(name)}
                    icon="tamr-icon-close"
                  />
                </div>
              ))}
            </div>
          )}
      </div>
    );
  }
}
