import { isEqual } from 'underscore';

import createLoader from '../utils/createLoader';
import { fetch } from './ProjectDatasetCatalogAsync';
import { getFilterInfo } from './ProjectDatasetCatalogStore';

const ProjectDatasetCatalogLoader = createLoader(({ projectDatasetCatalog, location: { recipeId } }) => {
  const { loadedFilterInfo, loading } = projectDatasetCatalog;
  return {
    canFetch: true,
    shouldFetch: !isEqual(getFilterInfo(projectDatasetCatalog, recipeId), loadedFilterInfo),
    loading,
  };
}, {
  onFetch: fetch,
}, 'ProjectDatasetCatalogLoader');

export default ProjectDatasetCatalogLoader;
