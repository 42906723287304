import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import FileInput from '../components/Input/FileInput';
import LoadingPanel from '../components/LoadingPanel';

/**
 * Content for the Dialog used to add new Cateories to the Procurement app
 */
const TaxonomyUploadDialogContent = _.compose(
  connect(
    ({
      taxonomyUpload: {
        isLoading,
        selectedFile,
        errorMessage,
        showDialog,
      },
    }) => {
      return { isLoading, selectedFile, errorMessage, showDialog };
    },
    {
      onSetFile: event => ({
        type: 'TaxonomyUpload.fileUpdated',
        selectedFile: event.target.files[0],
      }),
    },
  ),
)(
  /**
   * Content for the Dialog used to add new Cateories to the Procurement app
   */
  class TaxonomyUploadDialogContent extends React.Component {
    static propTypes = {
      errorMessage: PropTypes.string,
      isLoading: PropTypes.bool.isRequired,
      onSetFile: PropTypes.func.isRequired,
      selectedFile: PropTypes.object,
    };

    getError = () => {
      if (this.props.errorMessage) {
        return <div className="error-panel">{this.props.errorMessage}</div>;
      }
      return <div />;
    };

    render() {
      const { showDialog, selectedFile, onSetFile } = this.props;

      return (
        <div className="taxonomy-upload-dialog-content">
          {this.props.isLoading ? (
            <LoadingPanel semiTransparent={false} message="Loading Taxonomy" />
          ) : (
            <div style={{ alignItems: 'center', justifyContent: 'center' }}>
              <FileInput
                openOnMount={showDialog}
                onSetFile={onSetFile}
                selectedFile={selectedFile}
                />
              {this.getError()}
            </div>
          )}
        </div>
      );
    }
  },
);

export default TaxonomyUploadDialogContent;
