import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import uri from 'urijs';

import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import ProcurementCategory from '../models/ProcurementCategory';
import { history } from '../utils/History';
import { longMoney } from '../utils/Numbers';
import PRODUCT_NAME from '../utils/ProductName';

const CategorySidebarStats = _.compose(
  connect(state => {
    const { location: { recipeId } } = state;
    return { recipeId };
  }, {
    onResetTransactionsFilterState: () => ({ type: 'Transactions.resetFilters' }),
  }),
)(class CategorySidebarStats extends React.Component {
  static propTypes = {
    activeCategory: PropTypes.instanceOf(ProcurementCategory),
    onResetTransactionsFilterState: PropTypes.func.isRequired,
    recipeId: PropTypes.number.isRequired,
  };

  onTotalClick = () => {
    this.props.onResetTransactionsFilterState();
    history.push(uri(`/spend/recipe/${this.props.recipeId}`).query({ categoryIds: [this.props.activeCategory.categoryId] }).toString());
  };

  onSuggestedClick = () => {
    this.props.onResetTransactionsFilterState();
    history.push(uri(`/spend/recipe/${this.props.recipeId}`).query({ categoryIds: [this.props.activeCategory.categoryId], unlabeled: true }).toString());
  };

  onLabeledClick = () => {
    this.props.onResetTransactionsFilterState();
    history.push(uri(`/spend/recipe/${this.props.recipeId}`).query({
      categoryIds: [this.props.activeCategory.categoryId],
      labeledByUser: true,
      // Also need to explicitly select all three sub-options of the labeledByUser/Verified filter
      labelAgreesWithTamr: true,
      labelDisagreesWithTamr: true,
      unlabeledByTamr: true,
    }).toString());
  };

  render() {
    if (!this.props.activeCategory) {
      return <div />;
    }
    const cat = this.props.activeCategory;
    const totalTransactions = cat.manualCategorizations.count + cat.totalAggForSuggestedCategorizations.count;
    const totalSpend = cat.manualCategorizations.spend + cat.totalAggForSuggestedCategorizations.spend;
    return (
      <div className="category-sidebar-stats">
        <div className="stat-line">
          <span
            className="link-to-spend"
            onClick={this.onTotalClick}
          >
            {numeral(totalTransactions).format('0,0')}
          </span>
          <span> Total <Term>Records</Term></span>
        </div>

        <div className="stat-line">
          <div className="label-stat">
            <span>- </span>
            <span
              className="link-to-spend"
              onClick={this.onSuggestedClick}
            >
              {numeral(cat.totalAggForSuggestedCategorizations.count).format('0,0')}
            </span>
            <span> Suggested by {PRODUCT_NAME}</span>
            <TamrIcon
              className="suggested"
              iconName="tamr-icon-check"
              size={22}
            />
          </div>
        </div>

        <div className="stat-line">
          <div className="label-stat">
            <span>- </span>
            <span
              className="link-to-spend"
              onClick={this.onLabeledClick}
            >
              {numeral(cat.manualCategorizations.count).format('0,0')}
            </span>
            <span> Verified</span>
            <TamrIcon
              className="manual"
              iconName="tamr-icon-check-inverted"
              size={22}
            />
          </div>
        </div>

        <div className="stat-line">
          <Term>currencyChar</Term>{longMoney(totalSpend)}
          <span> Total <Term>Spend</Term></span>
        </div>
      </div>
    );
  }
});

export default CategorySidebarStats;
