import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Chrome from '../chrome/Chrome';
import Button from '../components/Button';
import CenterContent from '../components/CenterContent';
import Link from '../components/Link';
import LoadingPanel from '../components/LoadingPanel';
import { SCHEMA_MAPPING } from '../constants/RecipeType';
import ProjectInfo from '../models/ProjectInfo';
import SchemaMapping from '../schema-mapping/SchemaMapping';
import { RESET_ATTRIBUTES } from '../schema-mapping/SchemaMappingActionTypes';
import { isCuratorByProjectId } from '../utils/Authorization';
import { getAuthorizedUser, selectActiveProjectInfo } from '../utils/Selectors';

/**
 * Main entrance page for schema mapping in the context of a specific recipe. For
 * now this class contains the simple flow necessary to get the user to schema mapping
 * i.e. 'are there input datasets defined?' etc.
 */
const SchemaMappingPage = _.compose(
  connect(state => {
    const { location: { recipeId } } = state;
    const projectInfo = selectActiveProjectInfo(state);
    return { recipe: (projectInfo || {}).smRecipe, recipeId, projectInfo, isUserACurator: isCuratorByProjectId(getAuthorizedUser(state), projectInfo?.projectDoc.id.id) };
  }, {
    onReset: () => ({ type: RESET_ATTRIBUTES }),
  }),
)(/**
 * Main entrance page for schema mapping in the context of a specific recipe. For
 * now this class contains the simple flow necessary to get the user to schema mapping
 * i.e. 'are there input datasets defined?' etc.
 */
  class SchemaMappingPage extends React.Component {
    static propTypes = {
      isUserACurator: PropTypes.bool.isRequired,
      onReset: PropTypes.func.isRequired,
      projectInfo: PropTypes.instanceOf(ProjectInfo),
    };

    /**
   * Figures out which step is needed and renders it
   */
    renderPage = () => {
      const { isUserACurator } = this.props;
      const recipe = this.props.recipe;
      if (!recipe) {
        return this.renderLoading();
      } if (recipe.type !== SCHEMA_MAPPING) {
        return this.renderWrongType(recipe);
      }
      return <SchemaMapping canUserEdit={isUserACurator} />;
    };

    renderLoading = () => {
    // FYI: if the user seeing this for any real amount of time it's a bug
      return <LoadingPanel message="Loading schema mapping" />;
    };

    renderWrongType = (recipe) => {
      return (
        <CenterContent>
          <div>Can't configure schema mapping on a {recipe.type} recipe</div>
        </CenterContent>
      );
    };

    renderNoInputDatasets = () => {
      const recipeDatasetsPage = `/datasets/recipe/${this.props.recipeId}`;
      return (
        <CenterContent className="textCenter">
          <div>This recipe has no input datasets</div>
          <div>
            <Link key="sm-no-data" to={recipeDatasetsPage}>
              <Button>Add datasets</Button>
            </Link>
          </div>
        </CenterContent>
      );
    };

    UNSAFE_componentWillMount() {
      const { onReset } = this.props;
      onReset();
    }

    render() {
    // TODO: fix the chrome when we re-do the nav
      return (
        <Chrome className="schema-mapping-page">
          {this.renderPage()}
        </Chrome>
      );
    }
  });

export default SchemaMappingPage;
