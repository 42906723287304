import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import Button from '../components/Button';
import GroupByEntry from './GroupByEntry';
import GroupByField from './GroupByField';
import GroupByFieldDialog from './GroupByFieldDialog';

const GroupByFields = connect((state) => {
  const { groupBy: { fields } } = state;
  return { fields: fields.toList() };
}, {
  onShowAddFieldDialog: () => ({ type: 'GroupBy.showAddFieldDialog' }),
})(class GroupByFields extends React.Component {
  static propTypes = {
    fields: ImmutablePropTypes.listOf(PropTypes.instanceOf(GroupByEntry)).isRequired,
    onShowAddFieldDialog: PropTypes.func.isRequired,
  };

  render() {
    const { fields, onShowAddFieldDialog } = this.props;
    const sortedFields = fields.toList()
      .sort((a, b) => a.aggregationName.localeCompare(b.aggregationName))
      .sortBy(field => !field.groupBy);
    return (
      <div className="group-by-fields">
        <div className="field-header">
          <div className="column attribute">Attribute Name</div>
          <div className="column field">Source Field Name</div>
          <div className="column group-by">Group Values</div>
          <div className="column show">Show</div>
        </div>
        {sortedFields.map(f => <GroupByField key={f.aggregationName} field={f} />)}
        <Button buttonType="Link" className="add-field-button" onClick={onShowAddFieldDialog}>
          Add attribute...
        </Button>
        <GroupByFieldDialog />
      </div>
    );
  }
});

export default GroupByFields;
