import React from 'react';

import { ArgTypes, checkArg } from '../utils/ArgValidation';
import * as geotamr from './GeoTamr';
import styles from './LongLatCard.module.scss';
import LongLatTable from './LongLatTable';

const PREVIEW_SIZE = 4;
/**
 * Render a long/lat table with a header indicating which GeoTamr is being shown.
 * Shows only the first `PREVIEW_SIZE` long/lat entries and includes a 'View more'
 * button for seeing all entries.
 */
function LongLatCard({ geoTamr, openDetailsDialog }) {
  checkArg({ geoTamr }, geotamr.GeoTamr.argType);
  checkArg({ openDetailsDialog }, ArgTypes.func);
  if (!geoTamr) {
    return (<div />);
  }
  const latLongsList = geotamr.longLatLists(geoTamr);
  return latLongsList.map((latLongs, i) => {
    const preview = latLongs.slice(0, PREVIEW_SIZE);
    return (
      <div key={i} className={styles.card}>
        <div className={styles.longLatTableTitle}>{`${geotamr.displayName(geoTamr)} ${i + 1}`}</div>
        {LongLatTable({ longLats: preview })}
        <div className={styles.viewRow}>
          <div onClick={openDetailsDialog} className={styles.viewButton}>
            {latLongs.size > PREVIEW_SIZE ? 'View more' : 'View details'}
          </div>
        </div>
      </div>
    );
  });
}

export default LongLatCard;
