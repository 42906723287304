import LicenseActions from '../constants/LicenseActions';
import License from '../models/License';
import Model from '../models/Model';
import { ArgTypes } from '../utils/ArgValidation';

/**
 * Store that contains the license information
 */

export const initialState = new (Model({
  license: { type: ArgTypes.nullable(ArgTypes.instanceOf(License)) },
}))();


export const reducers = {
  [LicenseActions.LOAD_LICENSE_COMPLETED]: (state, { data }) => {
    return state.set('license', new License(data));
  },
};
