// For specifying that a type is temporarily set to `any` during TS migration
export type $TSFixMe = any;

// See https://www.typescriptlang.org/docs/handbook/advanced-types.html#exhaustiveness-checking
export function assertNever(x: never): never {
  throw new Error('Unexpected object: ' + x);
}

/**
 * Helper types for expressing JSON, like that sent over the wire from the server
 */
export type JsonObject = { [key: string]: JsonContent };
export type JsonArray = JsonContent[];
export type JsonContent
  = string
  | number
  | boolean
  | null
  | undefined
  | JsonObject
  | JsonArray
export type Json
  = JsonObject
  | JsonArray

/**
 * Various helper types
 */

export type Maybe<T> = T | undefined;
export type Nullable<T> = T | null | undefined;

export type Dictionary<T> = { [key: string]: Nullable<T> };
