// synced with AssignmentInfo.Status java enum

const FeedbackStatus = {
  SKIP: 'SKIP',
  PENDING: 'PENDING',
  ANSWERED: 'ANSWERED',
} as const;
type FeedbackStatus = typeof FeedbackStatus[keyof typeof FeedbackStatus]

export default FeedbackStatus;
