import classnames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';

import style from './Toolbar.module.scss';

const propTypes = {
  className: PropTypes.string,
  left: PropTypes.element,
  right: PropTypes.element,
};
type ToolbarProps = InferProps<typeof propTypes>;

/**
 * A toolbar has a left side and a right side and a growing space in between. It is a flex container that vertically centers the children as well. You can override the styles of the flexContainer with className. Best used with React.Fragment when you have multiple items.
 */
export default class Toolbar extends React.Component<ToolbarProps> {
  static propTypes = propTypes;

  render() {
    const { className, left, right } = this.props;
    return (
      <div className={classnames(style.flexContainer, className)}>
        {left}
        <span className={style.spacer} />
        {right}
      </div>
    );
  }
}
