import { List } from 'immutable';
import React from 'react';

import TileServer from '../models/TileServer';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { isNotEmpty } from '../utils/Values';
import GeospatialMap from './GeospatialMap';
import * as geotamr from './GeoTamr';
import styles from './SidebarMap.module.scss';
import { TamrSidebarGeoJSON } from './TamrSidebarGeoJSON';

const BLACK = '#000000';
const BLUE = '#0A608E';
/**
 * Simplified shared configuration and styling for sidebar geospatial maps.
 */
function SidebarMap({ geoTamrs, tileServers }: {
  geoTamrs: geotamr.GeoTamrType[],
  tileServers: List<TileServer>,
}) {
  checkArg({ geoTamrs }, ArgTypes.array.of(geotamr.GeoTamr.argType));
  checkArg({ tileServers }, ArgTypes.Immutable.list.of(TileServer.argType));
  const geoList = List(geoTamrs).filter(isNotEmpty);
  if (geoList.isEmpty()) {
    return (<div />);
  }

  // If the first feature is null or undefined but the second feature is not, the bottom record in
  // a pair of features is being displayed, and should then be black.
  const firstColor = geoTamrs[0] ? BLUE : BLACK;

  // Leaflet wants Latitude, Longitude, so we reverse.
  const bounds = geotamr.calculateBounds(geoList.toArray()).map(arr => arr.reverse());
  const center = geotamr.calculateCentroid(bounds);

  return (
    <div className={styles.sidebarMap}>
      <GeospatialMap
        tileServers={tileServers}
        bounds={bounds}
        center={center}
      >
        <>
          <TamrSidebarGeoJSON color={firstColor} geoRows={[geoList.get(0) as geotamr.GeoTamrType]} />
          { geoList.size === 2 ? <TamrSidebarGeoJSON color={BLACK} geoRows={[geoList.get(1) as geotamr.GeoTamrType]} /> : null }
        </>
      </GeospatialMap>
    </div>
  );
}

export default SidebarMap;
