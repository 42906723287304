import { List, Map, Set } from 'immutable';
import $ from 'jquery';
import uri from 'urijs';

import { TAMR_ID } from '../constants/ElasticConstants';
import { SHOW } from '../errorDialog/ErrorDialogActionTypes';
import Dataref from '../models/Dataref';
import EsRecord from '../models/EsRecord';
import { TxnUrlBuilder } from '../transactions/TransactionUtils';
import { AnalysisInput } from '../transforms/models/StaticAnalysisModels';
import TransformChangesPreview from '../transforms/models/TransformChangesPreview';
import TransformListPreview from '../transforms/models/TransformListPreview';
import {
  doAnalyzeTypes,
  doGetDatasetTypes,
  doGetDatasetTypesAndKeys,
} from '../transforms/TransformsApi';
import {
  getSourceScopedGuids,
  getTransformList,
  inferUnifiedDatasetSchema,
  selectUnifiedOperationsBeforePreviewCutoff,
  selectUnifiedOperationsReferencedDatasets,
} from '../transforms/TransformsStore';
import { getUnifiedDatasetName, selectActiveProjectInfo } from '../utils/Selectors';
import SortUtils from '../utils/SortUtils';
import PreviewRecord from './PreviewRecord';
import { getFilterInfo } from './RecordsStore';
import { ES, PREVIEW } from './RowProviders';

const SAMPLE_DATASETS_FIELD = 'sampleDatasetsForPreview';

export const fetchTransactions = () => (dispatch, getState) => {
  const state = getState();
  const { records, transforms, schemaMapping: { allUnifiedAttributes } } = state;
  const { previewCutoff, deltas } = transforms;
  const { pageNum, pageSize, hasComments, queryString, selectedDatasetNames, columnSortStates, provider, previewResultSize } = records;
  const unifiedDatasetName = getUnifiedDatasetName(state);
  const { unifiedDatasetDoc, unifiedDatasetId, smRecipe, projectType } = selectActiveProjectInfo(state);
  const sampleDatasetsForPreview = smRecipe?.metadata.get(SAMPLE_DATASETS_FIELD);
  const sampleOverrides = sampleDatasetsForPreview || Map();
  const filterInfo = getFilterInfo(state);
  dispatch({ type: 'Records.fetchTransactions' });

  const previewCutoffIsInSourceScopedTransforms = getSourceScopedGuids(transforms).includes(previewCutoff);

  const getRecordsFetch = provider === ES
    ? $.ajax({
      url: new TxnUrlBuilder(projectType)
        .unifiedDatasetName(unifiedDatasetName)
        .datasetNames(selectedDatasetNames.toJS())
        .pageNum(pageNum)
        .pageSize(pageSize)
        .hasComments(hasComments)
        .queryString(queryString)
        .sort(SortUtils.getUrlSortStates(columnSortStates))
        .fetchRecordsWithoutClusters(true)
        .build(),
      method: 'GET',
      cache: false,
    }).then(({ items, total }) => {
      const rows = List(items).map(hit => {
        return new EsRecord(hit, unifiedDatasetDoc);
      });
      return { rows, total };
    })
    : $.ajax({
      url: uri(SERVICES.transform(`/preview/${unifiedDatasetName}` + (previewCutoff ? '/with-transforms' : '')))
        .query({ resultsSize: previewResultSize, sourceDatasetNames: selectedDatasetNames.toJS() }),
      method: 'POST',
      contentType: 'application/json',
      data: JSON.stringify(
        unifiedDatasetId === transforms.loadedDatasetId
          ? (previewCutoff ? new TransformListPreview({ transformList: getTransformList(transforms), sampleDatasets: sampleOverrides })
            : new TransformChangesPreview({ transformChanges: deltas, sampleDatasets: sampleOverrides })
          )
          : new TransformListPreview({ transformList: [], sampleDatasets: sampleOverrides }),
      ),
      cache: false,
    }).then(({ recordPage, columns, profiledSchema, datasetsToProfile, filteredDatasets }) => {
      const rows = List(recordPage).map(hit => new PreviewRecord(hit));
      return {
        rows,
        total: rows.size,
        columns: new List(columns),
        previewProfiledSchema: new List(profiledSchema),
        datasetsToProfile: new List(datasetsToProfile),
        filteredDatasets: new Set(filteredDatasets),
      };
    });

  const unifiedDatasetRef = Dataref.id(unifiedDatasetName);
  const getDatasetTypeFetch = provider === ES
    ? doGetDatasetTypes(Set.of(unifiedDatasetRef))
      .then(datasetTypes => datasetTypes.get(unifiedDatasetRef))
    : previewCutoffIsInSourceScopedTransforms
      ? $.Deferred().resolve(undefined)
      : doGetDatasetTypesAndKeys(selectUnifiedOperationsReferencedDatasets(transforms))
        .then(([referenceTypes, referenceKeys]) => {
          const activeType = inferUnifiedDatasetSchema(allUnifiedAttributes);
          const operationList = selectUnifiedOperationsBeforePreviewCutoff(transforms);
          const activeKey = List.of(TAMR_ID);
          return doAnalyzeTypes(new AnalysisInput({ activeType, referenceTypes, operationList, activeKey, referenceKeys }));
        }).then(operationListResult => {
          return operationListResult.outputType;
        });
  $.when(
    getRecordsFetch,
    getDatasetTypeFetch,
  ).then(({ rows, total, columns, previewProfiledSchema, datasetsToProfile, filteredDatasets }, datasetType) => {
    dispatch({ type: 'Records.fetchTransactionsCompleted', rows, total, datasetType, columns, filterInfo, previewProfiledSchema, datasetsToProfile, filteredDatasets });
  }, response => {
    dispatch({ type: 'Records.fetchTransactionsFailed', filterInfo });
    // Include short error messages in SHOW header
    let errorMsg = response.responseJSON.message;
    if (errorMsg === null || errorMsg.length > 100) {
      errorMsg = '';
    } else {
      errorMsg = ': ' + errorMsg;
    }
    dispatch({
      type: SHOW,
      detail: provider === PREVIEW
        ? 'Preview could not be updated' + errorMsg
        : 'Error loading data' + errorMsg,
      response,
    });
  });
};
