import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import { ElasticConfig } from '../api/ProcurifyClient';
import Chrome from '../chrome/Chrome';
import PageHeader from '../chrome/PageHeader';
import Link from '../components/Link';
import SearchBox from '../components/SearchBox';
import Term from '../components/Term';
import WarningDialog from '../components/WarningDialog';
import { getAvailableFields } from '../dnfBuilder/DnfBuilderStore';
import ScoreThresholds from '../models/ScoreThresholds';
import ConfirmRemoveResponseDialog from '../pairs/ConfirmRemoveResponseDialog';
import ConfusionMatrixLoader from '../pairs/ConfusionMatrixLoader';
import RecordPairFeedbackAssignDialog from '../pairs/RecordPairFeedbackAssignDialog';
import { launchPredictCluster, launchTrainPredictCluster } from '../pairs/RecordPairsAsync';
import RecordPairsCompareDetailsDialog from '../pairs/RecordPairsCompareDetailsDialog';
import RecordPairsFilterPanel from '../pairs/RecordPairsFilterPanel';
import RecordPairsFilterViolationChecker from '../pairs/RecordPairsFilterViolationChecker';
import RecordPairsLoader from '../pairs/RecordPairsLoader';
import RecordPairsSidebar from '../pairs/RecordPairsSidebar';
import PairsTable from '../pairs/RecordPairsTable';
import RecordPairsTaskbar from '../pairs/RecordPairsTaskbar';
import RecordPairsUpdateResultsButton from '../pairs/RecordPairsUpdateResultsButton';
import UserDefinedSignalsLoader from '../pairs/UserDefinedSignalsLoader';
import { isCuratorByProjectId } from '../utils/Authorization';
import { commafy } from '../utils/Numbers';
import { getAuthorizedUser, selectActiveProjectInfo } from '../utils/Selectors';

const RecordPairs = _.compose(
  connect(state => {
    const {
      config: { elasticConfig, pairConfidenceThresholds },
      recordPairs: { isSidebarExpanded, queryString, unfilteredTotal, total, showFilterPanel, showingPredictWarning, showingTrainPredictWarning },
      location: { recipeId },
    } = state;
    const fieldsAreAvailable = !getAvailableFields(state).isEmpty();
    return { recipeId, elasticConfig, pairConfidenceThresholds, fieldsAreAvailable, isSidebarExpanded, queryString, unfilteredTotal, total, showingPredictWarning, showingTrainPredictWarning, showFilterPanel, isUserACurator: isCuratorByProjectId(getAuthorizedUser(state), selectActiveProjectInfo(state)?.projectDoc.id.id) };
  }, {
    onHidePredictWarning: () => ({ type: 'RecordPairs.hidePredictWarning' }),
    onHideTrainPredictWarning: () => ({ type: 'RecordPairs.hideTrainPredictWarning' }),
    onResetDnfState: () => ({ type: 'DnfBuilder.reset' }),
    onSetQueryString: (queryString) => ({ type: 'RecordPairs.setQueryString', queryString }),
    onLaunchTrainPredictCluster: launchTrainPredictCluster,
    onLaunchPredictCluster: launchPredictCluster,
  }),
)(class RecordPairs extends React.Component {
  static propTypes = {
    elasticConfig: PropTypes.instanceOf(ElasticConfig),
    fieldsAreAvailable: PropTypes.bool.isRequired,
    isSidebarExpanded: PropTypes.bool.isRequired,
    isUserACurator: PropTypes.bool.isRequired,
    onHidePredictWarning: PropTypes.func.isRequired,
    onHideTrainPredictWarning: PropTypes.func.isRequired,
    onLaunchPredictCluster: PropTypes.func.isRequired,
    onLaunchTrainPredictCluster: PropTypes.func.isRequired,
    onResetDnfState: PropTypes.func.isRequired,
    onSetQueryString: PropTypes.func.isRequired,
    pairConfidenceThresholds: PropTypes.instanceOf(ScoreThresholds),
    queryString: PropTypes.string.isRequired,
    showFilterPanel: PropTypes.bool.isRequired,
    showingPredictWarning: PropTypes.bool.isRequired,
    showingTrainPredictWarning: PropTypes.bool.isRequired,
    total: PropTypes.number.isRequired,
    unfilteredTotal: PropTypes.number.isRequired,
  };

  UNSAFE_componentWillMount() {
    // Reload the pairs and clusters
    // so that search state from the clusters/pairs pages does not bleed in to the dashboard.
    // TODO: persist stats on project
    this.props.onResetDnfState();
  }

  renderHeader = () => {
    const { isUserACurator, fieldsAreAvailable, queryString, total, showingPredictWarning, showingTrainPredictWarning, unfilteredTotal, onHidePredictWarning, onHideTrainPredictWarning, onLaunchTrainPredictCluster, onLaunchPredictCluster } = this.props;

    const metrics = (
      <span className="page-header-metrics">
        {total !== unfilteredTotal ? `${commafy(total)} of ` : ''}{commafy(unfilteredTotal)}
      </span>
    );
    const linkToDnfBuilder = (fieldsAreAvailable && isUserACurator)
      ? <Link to={`/dnf-builder/recipe/${this.props.recipeId}`} className="dnf-builder-link">Manage <Term>pair</Term> generation</Link>
      : <div />;

    const subtitle = (
      <span className="record-pairs-subtitle">
        {linkToDnfBuilder}
      </span>
    );
    return (
      <PageHeader
        title={
          <span>
            <Term>Pairs</Term> {metrics}
          </span>
        }
        subtitle={subtitle}
        searchbox={(
          <div className="record-pairs-action-section">
            <SearchBox value={queryString} onSearch={this.props.onSetQueryString} />
            <RecordPairsUpdateResultsButton iconify={false} />
            <WarningDialog
              actionName="Apply feedback and update results"
              message="Applying feedback and updating results can take a while. Are you sure you want to continue?"
              onAccept={onLaunchTrainPredictCluster}
              onHide={onHideTrainPredictWarning}
              show={showingTrainPredictWarning}
            />
            <WarningDialog
              actionName="Update results"
              message="Updating results can take a while. Are you sure you want to continue?"
              onAccept={onLaunchPredictCluster}
              onHide={onHidePredictWarning}
              show={showingPredictWarning}
            />
          </div>
        )}
      />
    );
  };

  render() {
    const { isSidebarExpanded, showFilterPanel } = this.props;

    const tablePanelCssClasses = classNames('table-panel-container', {
      'filter-panel-present': showFilterPanel,
    });
    return (
      <Chrome
        rightSidebar={<RecordPairsSidebar />}
        isExpanded={isSidebarExpanded}
        className="record-pairs-container"
      >
        {this.renderHeader()}
        <div className="inner-content-container">
          {showFilterPanel ?
            <div className="filter-panel-container">
              <RecordPairsFilterPanel />
            </div>
            : null}
          <div className={tablePanelCssClasses}>
            <RecordPairsTaskbar />
            <PairsTable />
          </div>
        </div>
        <RecordPairsCompareDetailsDialog />
        <RecordPairFeedbackAssignDialog />
        <ConfusionMatrixLoader />
        <RecordPairsLoader />
        <RecordPairsFilterViolationChecker />
        <UserDefinedSignalsLoader />
        <ConfirmRemoveResponseDialog />
      </Chrome>
    );
  }
});

export default RecordPairs;
