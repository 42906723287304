import 'react-keyed-file-browser/dist/react-keyed-file-browser.css';

import { List, Map } from 'immutable';
import React, { useEffect, useRef } from 'react';
// @ts-expect-error
import FileBrowser from 'react-keyed-file-browser';
import { connect } from 'react-redux';

import { BrowserFile } from '../api/CoreConnectService';
import Button from '../components/Button';
import ConditionalButton from '../components/ConditionalButton';
import DocLink from '../components/DocLink';
import Checkbox from '../components/Input/Checkbox';
import Input from '../components/Input/Input';
import Selector from '../components/Input/Selector';
import LoadingPanel from '../components/LoadingPanel';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import CoreConnectFileType, { CoreConnectFileTypeE } from '../coreConnectService/CoreConnectFileType';
import CoreConnectProvider from '../coreConnectService/CoreConnectProvider';
import {
  SET_ADVANCED_OPTIONS_OPEN,
  SET_APPEND_TO_DATASET,
  SET_PRIMARY_KEYS,
  SET_PROFILE_DATASET,
  SET_SINK_PATH_PREFIX,
  SET_SOURCE_BUCKET_NAME,
  SET_SOURCE_FILE_TYPE,
  SET_SOURCE_PATH_PREFIX,
  SET_SOURCE_PROJECT_NAME,
} from '../coreConnectService/CoreConnectServiceActionTypes';
import { fetchFilesWithCoreConnect } from '../coreConnectService/CoreConnectServiceAsync';
import DatasetPoliciesSelector, {
  FUTURE_DATASET_ID,
  FUTURE_DATASET_NAME,
} from '../datasets/DatasetPoliciesSelector';
import { AppAction, AppThunkAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { canUpdateDatasetAtLeastOnePolicy } from '../utils/Authorization';
import { isBlank } from '../utils/Strings';
import style from './CloudStorageContent.module.scss';
import { $TSFixMe } from '../utils/typescript';

const PRIMARY_KEY_WARNING_MESSAGE = 'Datasets must have a primary key for Tamr'
  + ' to reference individual records. Tamr will add a Primary Key column (with'
  + ' random guids as values) to be used as a reference if accessing this dataset’s'
  + ' records via Tamr’s API.';
const APPEND_DATA_WARNING_MESSAGE = 'If Tamr already has a dataset with the'
+ ' name you specified here, Tamr will attempt to append data from the file(s) you'
+ ' selected to your existing dataset. If the file(s) selected do not share a'
+ ' schema, they will not be appended.';

export default connect((state: AppState) => {
  const {
    coreConnectService: {
      coreconnectDefaultProvider,
      sourceProjectName,
      sourceBucketName,
      sourcePathPrefix,
      sourceFileType,
      retrievingSourceFiles,
      fetchSchemaCallCompleted,
      sourceFiles,
      sinkPathPrefix,
      sourceSchema,
      primaryKeys,
      advancedOptionsOpen,
      numThreads,
      profileDataset,
      appendToDataset,
      submittingJob,
    },
    auth: {
      authorizedUser,
    },
  } = state;
  return {
    coreconnectDefaultProvider: coreconnectDefaultProvider || '',
    sourceProjectName,
    sourceBucketName,
    sourcePathPrefix,
    sourceFileType,
    retrievingSourceFiles,
    fetchSchemaCallCompleted,
    sourceFiles,
    sinkPathPrefix,
    sourceSchema,
    primaryKeys,
    advancedOptionsOpen,
    numThreads,
    profileDataset,
    appendToDataset,
    submittingJob,
    readyToFetchFiles: !isBlank(sourceProjectName)
      && !isBlank(sourceBucketName)
      && sourceFileType !== CoreConnectFileType.NONE,
    authorizedUser,
  };
}, {
  onChangeSourceProjectName: (sourceProjectName: string): AppAction => ({ type: SET_SOURCE_PROJECT_NAME, sourceProjectName }),
  onChangeSourceBucketName: (sourceBucketName: string): AppAction => ({ type: SET_SOURCE_BUCKET_NAME, sourceBucketName }),
  onChangeSourcePathPrefix: (sourcePathPrefix: string): AppAction => ({ type: SET_SOURCE_PATH_PREFIX, sourcePathPrefix }),
  onChangeSourceFileType: (sourceFileType: CoreConnectFileTypeE): AppAction => ({ type: SET_SOURCE_FILE_TYPE, sourceFileType }),
  onFetchFilesWithCoreConnect: (): AppThunkAction<void> => fetchFilesWithCoreConnect(),
  onChangeSinkPathPrefix: (sinkPathPrefix: string): AppAction => ({ type: SET_SINK_PATH_PREFIX, sinkPathPrefix }),
  onSetPrimaryKeys: (primaryKeys: string): AppAction => (
    { type: SET_PRIMARY_KEYS, primaryKeys: primaryKeys === '' ? List() : List.of(primaryKeys) }
  ),
  onSetAdvancedOptionsOpen: (): AppAction => ({ type: SET_ADVANCED_OPTIONS_OPEN }),
  onSetProfileDataset: (profileDataset: boolean): AppAction => ({ type: SET_PROFILE_DATASET, profileDataset }),
  onSetAppendToDataset: (appendToDataset: boolean): AppAction => ({ type: SET_APPEND_TO_DATASET, appendToDataset }),
})(({
  coreconnectDefaultProvider,
  sourceProjectName,
  sourceBucketName,
  sourcePathPrefix,
  sourceFileType,
  retrievingSourceFiles,
  fetchSchemaCallCompleted,
  sourceFiles,
  onChangeSourceProjectName,
  onChangeSourceBucketName,
  onChangeSourcePathPrefix,
  onChangeSourceFileType,
  onFetchFilesWithCoreConnect,
  onChangeSinkPathPrefix,
  sinkPathPrefix,
  sourceSchema,
  primaryKeys,
  onSetPrimaryKeys,
  onSetAdvancedOptionsOpen,
  onSetProfileDataset,
  onSetAppendToDataset,
  advancedOptionsOpen,
  profileDataset,
  appendToDataset,
  submittingJob,
  readyToFetchFiles,
  authorizedUser,
}) => {
  const fileBrowserRef = useRef<$TSFixMe>(null);
  useEffect(() => {
    if (fileBrowserRef.current) {
      let pos = 0;
      const path = sourcePathPrefix || '';
      while (path.indexOf('/', pos) > -1) {
        pos = path.indexOf('/', pos) + 1;
        fileBrowserRef.current?.openFolder(path.substring(0, pos));
      }
      fileBrowserRef.current?.openFolder(path);
    }
  }, [fileBrowserRef.current, sourcePathPrefix]);

  const coreconnectString = coreconnectDefaultProvider !== undefined ? CoreConnectProvider.get(coreconnectDefaultProvider) : null;
  // @ts-ignore
  const topLevelProject:string = { S3: 'Region', ADLS2: 'Account Name', GCS: 'Project' }[coreconnectDefaultProvider] || '';
  // @ts-ignore
  const secondLevelBucket:string = { S3: 'Bucket', ADLS2: 'Container', GCS: 'Bucket' }[coreconnectDefaultProvider] || '';
  return (
    <>
      { submittingJob ? <LoadingPanel /> : null }
      <div className={style.container}>
        <p>Connected to {coreconnectString}.&nbsp;
          <DocLink path="uploading-a-dataset">
            Learn more
          </DocLink>.
        </p>
      </div>
      <div className={style.container}>
        <div className={style.sourceConfig}>
          <div className={style.subContainer}>
            <div className={style.selectorLabel}>
              File type:
            </div>
            <Selector
              className={style.fileTypeSelector}
              onChange={onChangeSourceFileType}
              placeholder="Select file type"
              values={[
                { value: CoreConnectFileType.AVRO, display: CoreConnectFileType.AVRO },
                { value: CoreConnectFileType.CSV, display: CoreConnectFileType.CSV },
              ]}
              value={sourceFileType}
            />
          </div>
          <div className={style.subContainer}>
            <Input
              componentClassName={style.project}
              value={sourceProjectName}
              title={topLevelProject}
              onChange={onChangeSourceProjectName}
            />
          </div>
          <div className={style.subContainer}>
            <Input
              componentClassName={style.bucket}
              value={sourceBucketName}
              title={secondLevelBucket}
              onChange={onChangeSourceBucketName}
            />
          </div>
          <div className={style.subContainer}>
            <Input
              componentClassName={style.path}
              value={sourcePathPrefix}
              title="Path"
              onChange={onChangeSourcePathPrefix}
            />
          </div>
          <div className={style.subContainer}>
            <div className={style.applyButton}>
              <ConditionalButton
                tooltipPlacement="top"
                preconditions={Map({
                  'Please fill out the fields above to browse': readyToFetchFiles,
                })}
                onClick={onFetchFilesWithCoreConnect}
              >
                Apply
              </ConditionalButton>
            </div>
          </div>
          { sourceFiles.isEmpty() ? null : fetchSchemaCallCompleted && sourceSchema.isEmpty() ? (
            <div className={style.fileRetrieveError}>
              Unable to retrieve files from the specified path.
              <br />
              Check that you have entered the correct location and file type for the dataset and try again.
            </div>
          ) : null
          }
        </div>
        <div
          className={style.fileBrowserContainer}
          style={{ backgroundColor: sourceFiles.size === 0 ? '#E7E7E7' : 'white' }}
        >
          { sourceFiles.size === 0 ? (
            <div className={style.fileBrowserLabel}>
              Specify at least a {topLevelProject} and {secondLevelBucket} to browse files.
            </div>
          ) : (
            <FileBrowser
              ref={(el: $TSFixMe) => { fileBrowserRef.current = el; }}
              files={sourceFiles}
              icons={{
                Folder: <TamrIcon className={style.fileBrowserIcon} iconName="folder" size={16} />,
                FolderOpen: <TamrIcon className={style.fileBrowserIcon} iconName="folder-open" size={16} />,
              }}
              detailRenderer={() => null}
              onSelect={(browserFile: BrowserFile) => {
                browserFile && onChangeSourcePathPrefix(browserFile.key);
                onFetchFilesWithCoreConnect();
              }}
              />
          )}
          { retrievingSourceFiles ? <LoadingPanel /> : null }
        </div>
      </div>
      { (!readyToFetchFiles
        || sourcePathPrefix === undefined) ? null : (
          <>
            <div className={style.container}>
              <Input
                componentClassName={style.name}
                value={sinkPathPrefix}
                title="Tamr Dataset Name"
                onChange={onChangeSinkPathPrefix}
                invalid={sinkPathPrefix?.includes('/')}
              />
            </div>
            {sinkPathPrefix?.includes('/') && <p className={style.fileRetrieveError}>Dataset name should not contain '/'</p>}
            <div className={style.container}>
              <div className={style.selectorLabel}>
                Primary key:
              </div>
              <Selector
                className={style.primaryKeySelector}
                disabled={sourceSchema.isEmpty()}
                onChange={onSetPrimaryKeys}
                placeholder={(
                  <div className={style.noPrimaryKeyOption}>
                    No Primary Key
                    <TooltipTrigger
                      placement="top"
                      content={<span>{PRIMARY_KEY_WARNING_MESSAGE}</span>}
                    >
                      <TamrIcon className={style.pkWarningIcon} iconName="tamr-icon-warning" size={14} />
                    </TooltipTrigger>
                  </div>
                )}
                values={[{
                  value: '',
                  display: (
                    <div className={style.noPrimaryKeyOption}>
                      No Primary Key
                      <TooltipTrigger
                        placement="top"
                        content={<span>{PRIMARY_KEY_WARNING_MESSAGE}</span>}
                      >
                        <TamrIcon className={style.pkWarningIcon} iconName="tamr-icon-warning" size={14} />
                      </TooltipTrigger>
                    </div>
                  ),
                }].concat(
                  sourceSchema.filter(value => value !== 'Ingest PK').map(atr => ({ value: atr, display: <span>{atr}</span> })).toArray(),
                )}
                value={primaryKeys.first()}
              />
              {primaryKeys.size !== 0 ? null : (
                <div className={style.primaryKeyWarning}>
                  <span className={style.warningTitle}>
                    <TamrIcon className={style.warningIcon} iconName="tamr-icon-warning" size={14} />
                    <span>Autogenerate Primary Key Column</span>
                  </span>
                  <div>
                    {PRIMARY_KEY_WARNING_MESSAGE}
                  </div>
                </div>
              )}
            </div>
            {!canUpdateDatasetAtLeastOnePolicy({ user: authorizedUser }) ? null : (
              <div className="form-component-spacer">
                <div className="section-title">SELECT POLICIES</div>
                <div className="policies-section">
                  <DatasetPoliciesSelector datasetId={FUTURE_DATASET_ID} datasetName={FUTURE_DATASET_NAME} /></div>
              </div>
            )}
            <div className={style.container}>
              <Button
                className={style.advancedOptionsLink}
                buttonType="Link"
                onClick={onSetAdvancedOptionsOpen}
              >
                {advancedOptionsOpen ? 'Hide' : 'Show'} advanced options
              </Button>
            </div>
            {!advancedOptionsOpen ? null : (
              <div className={style.container}>
                <div className={style.additionalOption}>
                  <Checkbox
                    className={style.checkbox}
                    title="Profile dataset"
                    titlePosition="right"
                    onChange={onSetProfileDataset}
                    value={profileDataset}
                    />
                </div>
                <div className={style.additionalOption}>
                  <Checkbox
                    className={style.checkbox}
                    title="Append data to tamr dataset"
                    titlePosition="right"
                    onChange={onSetAppendToDataset}
                    value={appendToDataset}
                    />
                </div>
              </div>
            )}
            {appendToDataset !== true ? null : (
              <div className={style.container}>
                <div className={style.appendDataWarning}>
                  <span className={style.warningTitle}>
                    <TamrIcon className={style.warningIcon} iconName="tamr-icon-warning" size={14} />
                    <span>Appending Data</span>
                  </span>
                  <div>
                    {APPEND_DATA_WARNING_MESSAGE}
                  </div>
                </div>
              </div>
            )}
          </>
        )
      }
    </>
  );
});
