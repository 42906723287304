import { List, Map, Set } from 'immutable';
import { isString } from 'underscore';

import PolicyMemberType from '../constants/PolicyMemberType';
import ResourceSpecCollections from '../constants/ResourceSpecCollections';
import { ArgTypes, checkArg, checkReturn } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { isDefined } from '../utils/Values';
import { Roles } from './AuthUser';
import Member from './Member';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes, toJSON } from './Model';
import ResourceSpec from './ResourceSpec';


export default class AuthorizationPolicy extends getModelHelpers({
  name: { type: ArgTypes.string },
  description: { type: ArgTypes.nullable(ArgTypes.string) },
  rolesToMembers: { type: ArgTypes.Immutable.map.of(ArgTypes.Immutable.list.of(Member.argType), ArgTypes.string) },
  resourceSpecs: { type: ArgTypes.Immutable.list.of(ResourceSpec.argType) },
}, 'AuthorizationPolicy')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class AuthorizationPolicyRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  /**
   * Get a set of all of the usernames of the users in this policy
   *
   * IMPORTANT: This list does not exclude admin users, which effectively do not belong to this
   * policy. This can happen when a user is made admin after being added to this policy. The list
   * is also incorrect when the policy applies to all users.
   */
  users(): Set<string> {
    return this.rolesToMembers.valueSeq()
      .map(memberList => memberList
        .filter(member =>
          member.memberType === PolicyMemberType.USER &&
          member.identifier.parts.size === 2 &&
          member.identifier.parts.first() === ResourceSpecCollections.USERS)
        .map(member => member.identifier.parts.get(1)),
      )
      .flatMap(l => l)
      .toSet()
      .filter(isDefined);
  }

  /**
   * @return the role if the policy grants all user groups access to its resources, otherwise null
   */
  appliesToAllUsers(): string | null {
    const allUserRole = new Member({ memberType: PolicyMemberType.USER, identifier: ResourceSpec.fromJSON(ResourceSpecCollections.USERS) });
    if (this.rolesToMembers.get(Roles.CURATOR)?.includes(allUserRole)) {
      return Roles.CURATOR;
    }

    if (this.rolesToMembers.get(Roles.VERIFIER)?.includes(allUserRole)) {
      return Roles.VERIFIER;
    }

    return this.rolesToMembers.get(Roles.REVIEWER)?.includes(allUserRole) ? Roles.REVIEWER : null;
  }

  /**
   * Get a set of all of the names of the user groups in this policy
   *
   * IMPORTANT: This list does not exclude admin groups, which effectively do not belong to this
   * policy. This can happen when a group is made admin after being added to this policy. The list
   * is also incorrect when the policy applies to all user groups.
   */
  userGroups(): Set<string> {
    return this.rolesToMembers.valueSeq()
      .map(memberList => memberList
        .filter(member =>
          member.memberType === PolicyMemberType.USER_GROUP &&
          member.identifier.parts.size === 2 &&
          member.identifier.parts.first() === ResourceSpecCollections.USERGROUPS)
        .map(member => member.identifier.parts.get(1)),
      )
      .flatMap(l => l)
      .toSet()
      .filter(isDefined);
  }

  /**
   * @return the role if the policy grants all user groups access to its resources, otherwise null
   */
  appliesToAllUserGroups(): string | null {
    const allUserGroupsRole = new Member({ memberType: PolicyMemberType.USER_GROUP, identifier: ResourceSpec.fromJSON(ResourceSpecCollections.USERGROUPS) });
    if (this.rolesToMembers.get(Roles.CURATOR)?.includes(allUserGroupsRole)) {
      return Roles.CURATOR;
    }

    if (this.rolesToMembers.get(Roles.VERIFIER)?.includes(allUserGroupsRole)) {
      return Roles.VERIFIER;
    }

    return this.rolesToMembers.get(Roles.REVIEWER)?.includes(allUserGroupsRole) ? Roles.REVIEWER : null;
  }

  /**
   * Get a set of all of the ids of the projects (as member) in this policy
   */
  projectsAsMemberIds(): Set<number> {
    return this.rolesToMembers.valueSeq()
      .map(memberList => memberList
        .filter(member =>
          member.memberType === PolicyMemberType.RESOURCE &&
          member.identifier.parts.size === 2 &&
          member.identifier.parts.first() === ResourceSpecCollections.PROJECTS)
        .map(member => Number(member.identifier.parts.get(1))),
      )
      .flatMap(l => l)
      .toSet();
  }

  /**
   * Whether this policy grants all projects access to its resources
   */
  appliesToAllMemberProjects(): boolean {
    return this.rolesToMembers.some(memberList => memberList.includes(
      new Member({ memberType: PolicyMemberType.RESOURCE, identifier: ResourceSpec.fromJSON(ResourceSpecCollections.PROJECTS) }),
    ));
  }

  /**
   * Get a set of all of the ids of the projects in this policy
   */
  projectIds(): Set<number> {
    return this.resourceSpecs
      .filter(spec => spec.parts.size === 2 && spec.parts.first() === ResourceSpecCollections.PROJECTS)
      .map(spec => Number(spec.parts.get(1)))
      .toSet();
  }

  /**
   * Whether this policy grants its members access to all projects
   */
  appliesToAllResourceProjects(): boolean {
    return this.resourceSpecs.some(spec => spec.parts.size === 1 && spec.parts.first() === ResourceSpecCollections.PROJECTS);
  }

  /**
   * Get a set of all of the ids of the datasets in this policy
   */
  datasetIds(): Set<string> {
    return this.resourceSpecs
      .filter(spec => spec.parts.size === 2 && spec.parts.first() === ResourceSpecCollections.DATASETS)
      .map(spec => spec.parts.get(1))
      .toSet()
      .filter(isDefined);
  }

  /**
   * Whether this policy grants its members access to all datasets
   */
  appliesToAllDatasets(): boolean {
    return this.resourceSpecs.some(spec => spec.parts.size === 1 && spec.parts.first() === ResourceSpecCollections.DATASETS);
  }

  /**
   * Get a set of the ids of the policies that are resources within in this policy
   */
  resourcePolicyIds(): Set<string> {
    return this.resourceSpecs
      .filter(spec => spec.parts.size === 2 && spec.parts.first() === ResourceSpecCollections.POLICIES)
      .map(spec => spec.parts.get(1))
      .toSet()
      .filter(isDefined);
  }

  /**
   * Get the highest-level role for the member
   *
   * e.g. if a user group is both reviewer and curator, returns unify_curator
   */
  roleForMember({ memberType, identifier }: { memberType: string, identifier: ResourceSpec }): string | null {
    checkArg({ memberType }, ArgTypes.string);
    checkArg({ identifier }, ResourceSpec.argType);
    // get all roles for the given member
    const allRoles = this.rolesToMembers
      // filter to roles where at least one of the members is the specified memberType and identifier
      .filter(memberList => memberList.some(member => member.memberType === memberType && member.identifier.matches(identifier)))
      .keySeq()
      .toList();
    if (allRoles.includes(Roles.CURATOR)) {
      return Roles.CURATOR;
    }

    if (allRoles.includes(Roles.VERIFIER)) {
      return Roles.VERIFIER;
    }

    if (allRoles.includes(Roles.REVIEWER)) {
      return Roles.REVIEWER;
    }
    return allRoles.first();
  }

  /**
   * Get the role for a given user by their username
   */
  roleForUsername(username: string): string | null {
    checkArg({ username }, ArgTypes.string);
    return this.appliesToAllUsers() || this.roleForMember({
      memberType: PolicyMemberType.USER,
      identifier: ResourceSpec.fromUsername(username),
    });
  }

  /**
   * Get the role for a given group by its groupname
   */
  roleForGroupname(groupname: string): string | null {
    checkArg({ groupname }, ArgTypes.string);
    return this.appliesToAllUserGroups() || this.roleForMember({
      memberType: PolicyMemberType.USER_GROUP,
      identifier: ResourceSpec.fromUsergroup(groupname),
    });
  }

  /**
   * Get the role for a given project by its ID
   */
  roleForProjectId(projectId: number): string | null {
    checkArg({ projectId }, ArgTypes.number);
    return this.roleForMember({
      memberType: PolicyMemberType.RESOURCE,
      identifier: ResourceSpec.fromProjectId(projectId),
    });
  }

  /**
   * Get a map of a username to a role
   */
  roleByUsername(): Map<string, string> {
    return this.users().reduce((accumulator, username) => {
      const role = this.roleForUsername(username);
      return isString(role) ? accumulator.set(username, role) : accumulator;
    }, Map<string, string>());
  }

  /**
   * Get a map of a groupname to a role
   */
  roleByGroupname(): Map<string, string> {
    return this.userGroups().reduce((accumulator, groupname) => {
      const role = this.roleForGroupname(groupname);
      return isString(role) ? accumulator.set(groupname, role) : accumulator;
    }, Map<string, string>());
  }

  /**
   * Get a map of a projectId to a role
   */
  roleByProjectId() {
    return checkReturn(ArgTypes.Immutable.map.of(ArgTypes.string, ArgTypes.number), () => {
      return this.projectsAsMemberIds().reduce((accumulator, projectId) => accumulator.set(projectId, this.roleForProjectId(projectId)), Map());
    })();
  }

  addUserToPolicy({ username, role }: { username: string, role: string }): AuthorizationPolicy {
    const userMember = new Member({ memberType: PolicyMemberType.USER, identifier: ResourceSpec.fromUsername(username) });
    return this.update('rolesToMembers', current => {
      // if there aren't any roles, or the role to add doesn't yet exist, create from scratch
      if (current.isEmpty() || !current.has(role)) {
        return current.set(role, List.of(userMember));
      }
      // otherwise update the existing list
      return current.update(role, members => {
        return !members.includes(userMember) ? members.push(userMember) : members;
      });
    });
  }

  removeUserFromPolicy({ username }: { username: string }) {
    const userMember = new Member({ memberType: PolicyMemberType.USER, identifier: ResourceSpec.fromUsername(username) });
    return this.update('rolesToMembers', current => {
      return current.map(members => (members.includes(userMember)
        ? members.remove(members.indexOf(userMember))
        : members
      ));
    });
  }

  updateUserInPolicy({ username, role }: { username: string, role: string }) {
    return checkReturn(AuthorizationPolicy.argType, () => {
      return this.removeUserFromPolicy({ username }).addUserToPolicy({ username, role });
    })();
  }

  addUserGroupToPolicy({ groupname, role }: { groupname: string, role: string }): AuthorizationPolicy {
    const userGroupMember = new Member({ memberType: PolicyMemberType.USER_GROUP, identifier: ResourceSpec.fromUsergroup(groupname) });
    return this.update('rolesToMembers', current => {
      // if there aren't any roles or the role to add doesn't exist yet, create it from scratch
      if (current.isEmpty() || !current.has(role)) {
        return current.set(role, List.of(userGroupMember));
      }
      // otherwise update the existing list
      return current.update(role, members => {
        return !members.includes(userGroupMember) ? members.push(userGroupMember) : members;
      });
    });
  }

  removeUserGroupFromPolicy({ groupname }: { groupname: string }): AuthorizationPolicy {
    checkArg({ groupname }, ArgTypes.string);
    const userGroupMember = new Member({ memberType: PolicyMemberType.USER_GROUP, identifier: ResourceSpec.fromUsergroup(groupname) });
    return this.update('rolesToMembers', current => {
      return current.map(members => (members.includes(userGroupMember)
        ? members.remove(members.indexOf(userGroupMember))
        : members
      ));
    });
  }

  /**
   * Returns whether the dataset is a resource of this policy or not
   */
  hasDataset({ datasetId }: { datasetId: string }): boolean {
    return this.appliesToAllDatasets() || this.datasetIds().includes(datasetId);
  }

  /**
   * Add a dataset as a resource to this policy
   */
  addDatasetToPolicy({ datasetId }: { datasetId: string }): AuthorizationPolicy {
    const datasetSpec = new ResourceSpec(`datasets/${datasetId}`);
    return this.update('resourceSpecs', currentSpecs => {
      return currentSpecs.includes(datasetSpec) ? currentSpecs : currentSpecs.push(datasetSpec);
    });
  }

  /**
   * Remove a dataset as a resource from this policy
   */
  removeDatasetFromPolicy({ datasetId }: { datasetId: string }): AuthorizationPolicy {
    const datasetSpec = new ResourceSpec(`datasets/${datasetId}`);
    return this.update('resourceSpecs', currentSpecs => {
      return currentSpecs.includes(datasetSpec) ? currentSpecs.remove(currentSpecs.indexOf(datasetSpec)) : currentSpecs;
    });
  }

  addProjectToPolicyAsMember({ projectId }: { projectId: number }): AuthorizationPolicy {
    const projectMember = new Member({ memberType: PolicyMemberType.RESOURCE, identifier: ResourceSpec.fromProjectId(projectId) });
    return this.update('rolesToMembers', current => {
      if (!current.has(Roles.PROJECT)) {
        return current.set(Roles.PROJECT, List.of(projectMember));
      }
      // otherwise update the existing list
      return current.update(Roles.PROJECT, members => {
        return !members.includes(projectMember) ? members.push(projectMember) : members;
      });
    });
  }

  removeProjectFromPolicyAsMember({ projectId }: { projectId: number }): AuthorizationPolicy {
    const projectMember = new Member({ memberType: PolicyMemberType.RESOURCE, identifier: ResourceSpec.fromProjectId(projectId) });
    return this.update('rolesToMembers', current => {
      return current.map(members => (members.includes(projectMember)
        ? members.remove(members.indexOf(projectMember))
        : members
      ));
    });
  }

  addProjectToPolicyAsResource({ projectId }: { projectId: number }): AuthorizationPolicy {
    const projectResource = ResourceSpec.fromProjectId(projectId);
    return this.update('resourceSpecs', current => {
      return current.push(projectResource);
    });
  }

  removeProjectFromPolicyAsResource({ projectId }: { projectId: number }): AuthorizationPolicy {
    const projectResource = ResourceSpec.fromProjectId(projectId);
    return this.update('resourceSpecs', current => {
      return current.filter(resource => resource.toJSON() !== projectResource.toJSON());
    });
  }

  hasProjectAsMember({ projectId }: { projectId: number }): boolean {
    return this.projectsAsMemberIds().includes(projectId) || this.appliesToAllMemberProjects();
  }

  hasProjectAsResource({ projectId }: { projectId: number }): boolean {
    return this.projectIds().includes(projectId) || this.appliesToAllResourceProjects();
  }

  toJSON() {
    const { rolesToMembers, resourceSpecs } = this;
    return {
      ...super.toJSON(),
      resourceSpecs: toJSON(resourceSpecs),
      rolesToMembers: toJSON(rolesToMembers.map(members => members.map(member => member.toString()))),
    };
  }

  static fromJSON(json: $TSFixMe): AuthorizationPolicy {
    checkArg({ json }, ArgTypes.object);
    const { name, description, rolesToMembers, resourceSpecs } = json;
    return new AuthorizationPolicy({
      name,
      description,
      rolesToMembers: Map<string, $TSFixMe>(rolesToMembers).map(members => List<string>(members).map(member => Member.fromString(member))),
      resourceSpecs: List<$TSFixMe>(resourceSpecs).map(spec => ResourceSpec.fromJSON(spec)),
    });
  }
}
