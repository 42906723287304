import $ from 'jquery';
import { isNumber } from 'underscore';
import uri from 'urijs';

import * as RecipeClient from '../api/RecipeClient';
import ServiceProxy from '../api/ServiceProxy';
import {
  CLASSIFICATION,
  GOLDEN_RECORDS,
  SCHEMA_MAPPING_RECOMMENDATIONS, SUPPLIER_MASTERING,
} from '../constants/ProjectTypes';
import { CATEGORIZATION } from '../constants/RecipeType';
import { AppThunkAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { TxnUrlBuilder } from '../transactions/TransactionUtils';
import * as Result from '../utils/Result';
import { selectProjectInfoByProjectId } from '../utils/Selectors';

const getProjectRecipesWithStatus = (state: AppState, projectId: number) => {
  const { projects: { projectsWithStatus } } = state;
  const pws = projectsWithStatus.find(p => p.project.id.id === projectId);
  return pws?.recipes;
};

const getProjectType = (state: AppState, projectId: number) => {
  const terminalRecipe = getProjectRecipesWithStatus(state, projectId)?.last(undefined)?.recipe;
  return terminalRecipe?.data.type;
};

const getSMRecipeWithStatus = (state: AppState, projectId: number) => {
  return getProjectRecipesWithStatus(state, projectId)?.first(undefined);
};

const getUnifiedDatasetName = (state: AppState, projectId: number) => {
  return getSMRecipeWithStatus(state, projectId)?.recipe.data.outputDatasets.keySeq().first(undefined);
};

const getRecordsLastRun = (state: AppState, projectId: number) => {
  return getSMRecipeWithStatus(state, projectId)?.materializations.get('records')?.lastRun;
};

export const fetchStatsForProject = (projectId: number, moduleId: number | undefined): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const projectType = getProjectType(state, projectId);
  if (projectType === GOLDEN_RECORDS && isNumber(moduleId)) {
    return RecipeClient.queryGoldenRecordsDraft(moduleId, { offset: 0, limit: 0, sortOptions: [], searchString: undefined, hasOverrides: undefined, includedClusters: [], excludedClusters: [] })
      .then(Result.handler(
        page => dispatch({ type: 'ProjectStats.statsFetchCompleted', projectId, value: page.total }),
        () => dispatch({ type: 'ProjectStats.statsFetchCompleted', projectId, value: 0 }),
      ));
  }
  const unifiedDatasetName = getUnifiedDatasetName(state, projectId);
  const recordsLastRun = getRecordsLastRun(state, projectId);
  if (!unifiedDatasetName || (recordsLastRun === null && projectType !== SCHEMA_MAPPING_RECOMMENDATIONS)) {
    return;
  }
  dispatch({ type: 'ProjectStats.statsFetchStarting', projectId, moduleId });
  let url;
  if (projectType === SCHEMA_MAPPING_RECOMMENDATIONS) {
    const smRecsRecipeId = selectProjectInfoByProjectId(state, projectId)?.smRecsRecipeId;
    url = uri(ServiceProxy.transform(`/schema-mapping/unified-attributes/${unifiedDatasetName}/display`))
      .query({
        tag: `recipe_${smRecsRecipeId}`,
        mapped: true,
      });
    $.ajax({
      url: url.toString(),
      method: 'POST',
      cache: false,
      context: this,
      success(data) {
        dispatch({ type: 'ProjectStats.statsFetchCompleted', projectId, value: data.length });
      },
    });
  } else {
    if (projectType === CLASSIFICATION) {
      // fetch transactions filtered to NOT unlabeled, set ProjectStats if different
      url = new TxnUrlBuilder(CATEGORIZATION)
        .unifiedDatasetName(unifiedDatasetName)
        .pageNum(0)
        .pageSize(0)
        .labeledByUser(true)
        .labeledByTamr(true)
        .build();
    } else if (projectType === SUPPLIER_MASTERING) {
      // fetch num suppliers
      url = uri(ServiceProxy.dedup(`/clusters/${unifiedDatasetName}`)).query({
        offset: 0,
        limit: 0,
      });
    }
    url && $.ajax({
      url: url.toString(),
      method: 'GET',
      cache: false,
      context: this,
      success(data) {
        const value = data.total;
        dispatch({ type: 'ProjectStats.statsFetchCompleted', projectId, value });
      },
    });
  }
};
