import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';

import PageHeader from '../chrome/PageHeader';
import Button from '../components/Button';
import ColumnWidthProvider from '../components/ColumnWidthProvider';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Table from '../components/Table/Table';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import PolicyMemberType from '../constants/PolicyMemberType';
import PolicyResourceType from '../constants/PolicyResourceType';
import AuthorizationPolicy from '../models/AuthorizationPolicy';
import { Roles } from '../models/AuthUser';
import Document from '../models/doc/Document';
import { AppAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { pluralize } from '../utils/Strings';
import { startManageMembers } from './AccessControlAsync';
import { UserGroupsCount, UsersCount } from './Count';
import style from './PoliciesList.module.scss';
import PoliciesLoader from './PoliciesLoader';
import PolicyConfigDialog from './PolicyConfigDialog';


export const policyString = (numPolicies: number) => {
  checkArg({ numPolicies }, ArgTypes.number);
  return `${numPolicies || 'No'} ${pluralize(numPolicies, 'Policy', 'Policies')}`;
};

export const memberProjectString = (policy: AuthorizationPolicy) => {
  checkArg({ policy }, AuthorizationPolicy.argType);
  const numProjects = policy.projectsAsMemberIds().size;
  return policy.appliesToAllMemberProjects() ? 'All projects' : `${numProjects} ${pluralize(numProjects, 'project', 'projects')}`;
};

export const resourceProjectString = (policy: AuthorizationPolicy) => {
  checkArg({ policy }, AuthorizationPolicy.argType);
  const numProjects = policy.projectIds().size;
  return policy.appliesToAllResourceProjects() ? 'All projects' : `${numProjects} ${pluralize(numProjects, 'project', 'projects')}`;
};

export const resourcePolicyString = (policy: AuthorizationPolicy) => {
  checkArg({ policy }, AuthorizationPolicy.argType);
  const numResourcePolicies = policy.resourcePolicyIds().size;
  return `${numResourcePolicies} ${pluralize(numResourcePolicies, 'policy', 'policies')}`;
};

export const datasetString = (policy: AuthorizationPolicy) => {
  checkArg({ policy }, AuthorizationPolicy.argType);
  const numDatasets = policy.datasetIds().size;
  return policy.appliesToAllDatasets() ? 'All datasets' : `${numDatasets} ${pluralize(numDatasets, 'dataset', 'datasets')}`;
};

export const containsAnyResources = (policy: AuthorizationPolicy) => {
  if (policy.appliesToAllDatasets() || policy.appliesToAllResourceProjects()) {
    return true;
  }
  return (policy.datasetIds().size + policy.projectIds().size + policy.resourcePolicyIds().size > 0);
};

export const containsAnyMembers = (policy: AuthorizationPolicy) => {
  if (policy.appliesToAllUserGroups() || policy.appliesToAllUsers() || policy.appliesToAllMemberProjects()) {
    return true;
  }
  return (policy.users().size + policy.projectsAsMemberIds().size + policy.userGroups().size > 0);
};

export const renderPolicyWarningIcon = (policy: AuthorizationPolicy) => {
  let message = null;
  if (!containsAnyMembers(policy) && !containsAnyResources(policy)) {
    message = 'This policy contains no resources or members. Add members and resources to manage permissions.';
  }
  if (!containsAnyMembers(policy) && containsAnyResources(policy)) {
    message = 'This policy has resources but no members. Add members to grant permissions to policy resources.';
  }
  if (containsAnyMembers(policy) && !containsAnyResources(policy)) {
    message = 'This policy has members but no resources. Add resources to grant permissions to policy members.';
  }
  if (policy.resourcePolicyIds().size === 0 && ((policy.rolesToMembers.get(Roles.AUTHOR)?.size || 0) > 0)) {
    message = 'This policy has at least one member with the Author role but no policies as resources. Add at least one policy as a resource to fully utilize the Author role.';
  }
  if (!message) {
    return null;
  }
  return <TooltipTrigger
    placement="right"
    content={message}>
    <TamrIcon className={style.warningIcon} iconName="tamr-icon-warning" size={20} />
  </TooltipTrigger>;
};


const mapStateToProps = (state: AppState) => {
  const { accessControl: { policyDocs } } = state;
  return {
    policyDocs,
  };
};

const mapDispatchToProps = {
  onStartCreatePolicy: (): AppAction => ({ type: 'AccessControl.startCreatePolicy' }),
  onStartEditPolicy: (policy: Document<AuthorizationPolicy>): AppAction => ({ type: 'AccessControl.startEditPolicy', policy }),
  onStartDeletePolicy: (policy: Document<AuthorizationPolicy>): AppAction => ({ type: 'AccessControl.startDeletePolicy', policy }),
  onStartDuplicatePolicy: (policy: Document<AuthorizationPolicy>): AppAction => ({ type: 'AccessControl.startDuplicatePolicy', policy }),
  onStartManageUsers: (policyDoc: Document<AuthorizationPolicy>) => startManageMembers(policyDoc, PolicyMemberType.USER),
  onStartManageGroups: (policyDoc: Document<AuthorizationPolicy>) => startManageMembers(policyDoc, PolicyMemberType.USER_GROUP),
  onStartManageMemberProjects: (policyDoc: Document<AuthorizationPolicy>) => startManageMembers(policyDoc, PolicyMemberType.RESOURCE),
  onStartManageResourceProjects: (policyDoc: Document<AuthorizationPolicy>): AppAction => ({ type: 'AccessControl.startManageResources', policyDoc, resourceType: PolicyResourceType.PROJECTS }),
  onStartManageDatasets: (policyDoc: Document<AuthorizationPolicy>): AppAction => ({ type: 'AccessControl.startManageResources', policyDoc, resourceType: PolicyResourceType.DATASETS }),
  onStartManagePoliciesAsResource: (policyDoc: Document<AuthorizationPolicy>): AppAction => ({ type: 'AccessControl.startManageResources', policyDoc, resourceType: PolicyResourceType.POLICIES }),
  reloadPolicies: (): AppAction => ({ type: 'AccessControl.reloadPolicies' }),
};

type PoliciesListProps
  = ReturnType<typeof mapStateToProps>
  & typeof mapDispatchToProps

const PoliciesList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(class UnconnectedPoliciesList extends Component<PoliciesListProps> {
  componentDidMount() {
    this.props.reloadPolicies();
  }

  render() {
    const { policyDocs, onStartCreatePolicy, onStartEditPolicy, onStartDeletePolicy, onStartDuplicatePolicy,
      onStartManageUsers, onStartManageGroups, onStartManageMemberProjects, onStartManageResourceProjects,
      onStartManageDatasets, onStartManagePoliciesAsResource } = this.props;
    return (
      <div className={style.policyList}>
        <PageHeader title="Policies" />
        <PoliciesLoader />
        <PolicyConfigDialog />
        <div className={style.taskbar}>
          <Button onClick={onStartCreatePolicy}>Create new policy</Button>
        </div>
        <div className={style.tableContainer}>
          <AutoSizer>
            {({ width, height }) => (
              <ColumnWidthProvider>
                <Table
                  {...{ width, height }}
                  getLength={() => policyDocs.size}
                  tableType="stripes"
                  headerHeight={42}
                  rowHeight={54}
                  rowClassNameGetter={() => style.row}
                >
                  <Column
                    isResizable
                    key="name"
                    columnKey="name"
                    width={450}
                    header={() => <div className={classNames('table-header-cell', 'table-header-wrap', style.firstColumn)}>Name</div>}
                    cell={({ rowIndex }) => {
                      const policyDoc = policyDocs.get(rowIndex);
                      if (!policyDoc) return <Cell />;
                      const name = policyDoc.data.name;
                      const description = policyDoc.data.description;
                      return (
                        <div className={classNames(style.firstColumn, style.cell)}>
                          <span className={style.policyNameDesc}>
                            <div className={style.name} title={name}>{name}</div>
                            <div className={style.description} title={description || undefined}>{description}</div>
                          </span>
                          <TooltipTrigger placement="top" content={<span>Edit policy name and description</span>}>
                            <Button
                              className={style.policyButton}
                              buttonType="Link"
                              icon="edit"
                              iconSize={16}
                              onClick={e => { e.stopPropagation(); onStartEditPolicy(policyDoc); }}
                            />
                          </TooltipTrigger>
                          <TooltipTrigger placement="top" content={<span>Duplicate policy</span>}>
                            <Button
                              className={style.policyButton}
                              buttonType="Link"
                              icon="duplicate"
                              iconSize={16}
                              onClick={() => onStartDuplicatePolicy(policyDoc)}
                            />
                          </TooltipTrigger>
                          <TooltipTrigger placement="top" content={<span>Delete this policy</span>}>
                            <Button
                              className={style.policyButton}
                              buttonType="Link"
                              icon="delete"
                              iconSize={16}
                              onClick={e => { e.stopPropagation(); onStartDeletePolicy(policyDoc); }}
                            />
                          </TooltipTrigger>
                        </div>
                      );
                    }}
                  />
                  <Column
                    isResizable
                    key="members"
                    columnKey="members"
                    width={300}
                    header={() => <div className={classNames('table-header-cell', 'table-header-wrap')}>Members</div>}
                    cell={({ rowIndex }) => {
                      const policyDoc = policyDocs.get(rowIndex);
                      if (!policyDoc) return <Cell />;
                      const policyDocData = policyDoc.data;
                      return (
                        <div className={style.cell}>
                          <Button
                            className={style.memberResourceButton}
                            buttonType="Link"
                            onClick={() => onStartManageUsers(policyDoc)}
                          >
                            <UsersCount id={policyDoc.id.id} />,
                          </Button>
                          <Button
                            className={style.memberResourceButton}
                            buttonType="Link"
                            onClick={() => onStartManageGroups(policyDoc)}
                          >
                            <UserGroupsCount id={policyDoc.id.id} />,
                          </Button>
                          <Button
                            className={style.memberResourceButton}
                            buttonType="Link"
                            onClick={() => onStartManageMemberProjects(policyDoc)}
                          >
                            {memberProjectString(policyDocData)}
                          </Button>
                        </div>
                      );
                    }}
                  />
                  <Column
                    isResizable
                    key="resources"
                    columnKey="resources"
                    width={300}
                    header={() => <div className={classNames('table-header-cell', 'table-header-wrap')}>Resources</div>}
                    cell={({ rowIndex }) => {
                      const policyDoc = policyDocs.get(rowIndex);
                      if (!policyDoc) return <Cell />;
                      const policyDocData = policyDoc.data;
                      return (
                        <div className={style.cell}>
                          <Button
                            className={style.memberResourceButton}
                            buttonType="Link"
                            onClick={() => onStartManageResourceProjects(policyDoc)}
                          >
                            {resourceProjectString(policyDocData)},
                          </Button>
                          <Button
                            className={style.memberResourceButton}
                            buttonType="Link"
                            onClick={() => onStartManageDatasets(policyDoc)}
                          >
                            {datasetString(policyDocData)},
                          </Button>
                          <Button
                            className={style.memberResourceButton}
                            buttonType="Link"
                            onClick={() => onStartManagePoliciesAsResource(policyDoc)}
                          >
                            {resourcePolicyString(policyDocData)}
                          </Button>
                          {renderPolicyWarningIcon(policyDocData)}
                        </div>
                      );
                    }}
                  />

                </Table>
              </ColumnWidthProvider>
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }
});

export default PoliciesList;
