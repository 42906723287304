import { List } from 'immutable';

import CCProvider, { CCProviderE } from '../coreConnectService/CCProvider';
import CCRequestType, { CCRequestTypeE } from '../coreConnectService/CCRequestType';
import CoreConnectFileType, { CoreConnectFileTypeE } from '../coreConnectService/CoreConnectFileType';

export function getRequestForProvider({ requestType, provider, projectId, bucket, path, fileType, datasetName, primaryKeys, policyIds, recipeId, profile, resourceConfig, appendToDataset }: {
  requestType: CCRequestTypeE,
  provider: CCProviderE,
  projectId: string,
  bucket: string,
  path: string,
  fileType?: CoreConnectFileTypeE,
  datasetName: string | null,
  primaryKeys: List<string> | null,
  policyIds: Array<number> | null,
  recipeId: number | undefined,
  profile: boolean,
  resourceConfig?: { [key: string]: any },
  appendToDataset?: boolean
}) {
  const endpointProvider = provider === CCProvider.GCS ? 'gcs' : provider;
  let endpoint = `/${requestType}/${endpointProvider}`;
  resourceConfig = resourceConfig || {};
  let body: { [key: string]: any } = {};

  if (provider === CCProvider.ADLS2) {
    body = {
      url: `${provider}://${projectId}.blob.core.windows.net/${bucket}/${path}`,
    };
  } else {
    body = {
      url: `${provider}://${bucket}/${path}`,
    };
  }
  if (fileType === CoreConnectFileType.CSV && (requestType === CCRequestType.INGEST || requestType === CCRequestType.SCHEMA)) {
    resourceConfig.delimiterDetectionEnabled = true;
    body.resourceConfig = resourceConfig;
  }

  if (requestType === CCRequestType.INGEST) {
    body.truncateTamrDataset = !appendToDataset;
  }

  if (requestType !== CCRequestType.FILE) {
    if (fileType === CoreConnectFileType.CSV) {
      endpoint = `/${requestType}/${endpointProvider}/delimited?async=true`;
    } else if (fileType === CoreConnectFileType.AVRO) {
      endpoint = `/${requestType}/${endpointProvider}/avro?async=true`;
    } else {
      endpoint = `/${requestType}/${endpointProvider}/delimited?async=true`;
    }
    if (requestType === CCRequestType.INGEST) {
      body = {
        ...body,
        datasetName,
        primaryKey: primaryKeys,
        policyIds,
        profile,
        recipeId,
        resourceConfig,
      };
    } else if (requestType === CCRequestType.EXPORT) {
      body = {
        ...body,
        datasetName,
        resourceConfig,
      };
    }
  } else {
    body = {
      ...body,
      fileType,
    };
  }

  if (provider === CCProvider.S3) {
    body = {
      ...body,
      awsRegion: projectId,
    };
  }

  return { url: `/connect${endpoint}`, body };
}
