import { ArgTypes, checkArg } from '../utils/ArgValidation';
import HierarchicalName from './HierarchicalName';

const STEP_DELIMITER = '.';

export default class PrivilegeSpec extends HierarchicalName {
  constructor(privilege: string) {
    super({ spec: privilege, stepDelimiter: STEP_DELIMITER });
  }

  toJSON() {
    return this.parts.join(this.stepDelimiter);
  }

  static fromJSON(json: string) {
    checkArg({ json }, ArgTypes.string);
    return new PrivilegeSpec(json);
  }
}
