import classNames from 'classnames';
import { List, Set } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import _ from 'underscore';

import CenterContent from '../components/CenterContent';
import ColumnOrderSelector from '../components/ColumnOrderSelector';
import ColumnWidthProvider from '../components/ColumnWidthProvider';
import DataColumn from '../components/DataColumn';
import LoadingPanel from '../components/LoadingPanel';
import Pager from '../components/Table/PagerModel';
import Table from '../components/Table/Table';
import Term from '../components/Term';
import DataTables from '../constants/DataTables';
import { ORIGIN_SOURCE_NAME } from '../constants/ElasticConstants';
import { selectProfilingSchema } from '../datasets/DatasetUtils';
import AttributeProfilingInfo from '../models/AttributeProfilingInfo';
import DisplayColumn from '../models/DisplayColumn';
import EsRecord from '../models/EsRecord';
import { shouldShowBigEditor } from '../transforms/TransformsStore';
import { getCurrentlyProfilingDatasetNames, selectActiveProjectInfo } from '../utils/Selectors';
import InputDatasetColumn from './InputDatasetColumn';
import PreviewRecord from './PreviewRecord';
import { getColumnSettings, setColumnPreferences, setColumnWidth } from './RecordsColumns';
import { getActiveRecordIndex } from './RecordsStore';
import style from './RecordsTable.module.scss';
import { ES } from './RowProviders';

const ES_PAGE_SIZES = List.of(50, 100, 500, 1000);
const PREVIEW_RESULT_SIZES = List.of(250, 500, 1000, 2000);

const RecordsTable = _.compose(
  connect(state => {
    const {
      location: { recipeId },
      config: { elasticConfig },
      records,
      datasetFilter: { numDatasets, loading: datasetFilterLoading },
      schemaMapping: { allUnifiedAttributes },
    } = state;
    const projectInfo = selectActiveProjectInfo(state);
    const { projectType } = projectInfo;
    const generatedAttributes = allUnifiedAttributes.filter(att => att.generated).map(att => (att.name));
    const mlAttributes = (projectType === 'DEDUP' || projectType === 'CATEGORIZATION') ? allUnifiedAttributes.filter(att => !att.generated && att.mlEnabled).map(att => att.name).toSet() : Set();
    const { transforms } = state;
    const { newlyCreatedAttributeName, pageNum, pageSize, previewResultSize, total, rows, columnSortStates, loading: recordsLoading, provider, previewProfiledSchema, datasetType } = records;
    const unifiedDatasetName = projectInfo && projectInfo.unifiedDatasetName;
    const columnSettings = getColumnSettings(state, provider);
    const unifiedDatasetIsCurrentlyProfiling = getCurrentlyProfilingDatasetNames(state).has(unifiedDatasetName);
    return {
      showingBigEditor: shouldShowBigEditor(transforms),
      datasetAndProfiling: selectProfilingSchema(state),
      recipeId,
      noDatasets: numDatasets === 0,
      elasticConfig,
      activeRowNumber: getActiveRecordIndex(records),
      loading: recordsLoading || datasetFilterLoading,
      pageNum,
      pageSize,
      previewResultSize,
      total,
      rows,
      columnSortStates,
      provider,
      previewProfiledSchema,
      columnSettings,
      unifiedDatasetId: projectInfo && projectInfo.unifiedDatasetId,
      newlyCreatedAttributeName: newlyCreatedAttributeName && columnSettings.some(col => col.name === newlyCreatedAttributeName) && newlyCreatedAttributeName,
      unifiedDatasetIsCurrentlyProfiling,
      datasetType,
      generatedAttributes,
      mlAttributes,
    };
  }, {
    onSetActiveRowNumber: rowNum => ({ type: 'Records.setActiveRowNumber', rowNum }),
    onSetPage: pageNum => ({ type: 'Records.setPage', pageNum }),
    onSetPageSize: pageSize => ({ type: 'Records.setPageSize', pageSize }),
    onToggleSort: columnName => ({ type: 'Records.toggleSort', columnName }),
    onSetPreviewResultSize: (resultSize) => ({ type: 'Records.setPreviewResultSize', resultSize }),
    onSetColumnPreferences: setColumnPreferences,
    onColumnResize: setColumnWidth,
    resetNewlyCreatedAttributeName: () => ({ type: 'Records.resetNewlyCreatedAttributeName' }),
  }),
)(class RecordsTable extends React.Component {
  static propTypes = {
    activeRowNumber: PropTypes.number,
    columnSettings: ImmutablePropTypes.listOf(PropTypes.instanceOf(DisplayColumn)),
    columnSortStates: PropTypes.object,
    datasetAndProfiling: PropTypes.object,
    elasticConfig: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    onSetActiveRowNumber: PropTypes.func.isRequired,
    onSetPage: PropTypes.func.isRequired,
    onSetPageSize: PropTypes.func.isRequired,
    onToggleSort: PropTypes.func.isRequired,
    pageNum: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    previewProfiledSchema: ImmutablePropTypes.listOf(PropTypes.object),
    provider: PropTypes.string.isRequired,
    rows: ImmutablePropTypes.listOf(
      PropTypes.oneOfType([PropTypes.instanceOf(EsRecord), PropTypes.instanceOf(PreviewRecord)]),
    ).isRequired,
    showingBigEditor: PropTypes.bool,
    total: PropTypes.number.isRequired,
    unifiedDatasetId: PropTypes.number,
  };

  componentDidUpdate() {
    const { newlyCreatedAttributeName, resetNewlyCreatedAttributeName } = this.props;
    if (newlyCreatedAttributeName) {
      resetNewlyCreatedAttributeName();
    }
  }

  render() {
    const {
      rows, pageNum, pageSize, previewResultSize, total, elasticConfig, loading, columnSettings,
      columnSortStates, activeRowNumber, onToggleSort, onSetColumnPreferences, onColumnResize,
      onSetActiveRowNumber, onSetPage, onSetPageSize, onSetPreviewResultSize, provider,
      datasetAndProfiling, previewProfiledSchema, showingBigEditor, newlyCreatedAttributeName,
      unifiedDatasetIsCurrentlyProfiling, datasetType, generatedAttributes, mlAttributes,
    } = this.props;
    if (!elasticConfig) {
      return null;
    }
    const { dataset, schema, profilingUpToDate } = datasetAndProfiling;
    const datasetName = dataset && dataset.data.name;
    const noRecordsToShow = loading ? null : <CenterContent>No <Term>Records</Term> To Show</CenterContent>;
    const datasetNameColumn = columnSettings.find(col => col.name === ORIGIN_SOURCE_NAME);
    const configureTable = (
      <div>
        {total > 0 && columnSettings ?
          <ColumnOrderSelector
            columnSettings={columnSettings.filter(({ name }) => name !== ORIGIN_SOURCE_NAME)}
            numPinnedColumns={1}
            pageType={DataTables.RECORDS}
            onSetColumnPreferences={(page, columnPrefs) => onSetColumnPreferences(columnPrefs)}
            generatedAttributes={generatedAttributes}
            mlAttributes={mlAttributes}
          />
          : null}
      </div>
    );

    const pagerProps = showingBigEditor ? {} : provider === ES ? {
      pageSizes: ES_PAGE_SIZES,
      onPageSizeChange: onSetPageSize,
      onPageChange: onSetPage,
      pagerState: new Pager(rows, _.min([elasticConfig.maxResultWindow, total]), pageNum, pageSize),
      pagerContent: configureTable,
    } : {
      pageSizes: PREVIEW_RESULT_SIZES,
      onPageSizeChange: onSetPreviewResultSize,
      pagerState: new Pager(rows, total, 0, previewResultSize),
      pagerContent: configureTable,
      hidePageNavigation: true,
      pageSizeLabel: 'Preview result size:',
    };

    const getProfilingInfo = provider === ES
      ? (name) => AttributeProfilingInfo.fromMetrics(name, schema.toArray(), profilingUpToDate, datasetName)
      : (name) => AttributeProfilingInfo.fromMetrics(name, previewProfiledSchema.toArray(), true, datasetName);

    return (
      <div className={classNames(style.recordsTableContainer, { [style.bigEditor]: showingBigEditor })}>
        {loading ? <LoadingPanel aboveCenter /> : null}
        {total > 0 && columnSettings && datasetNameColumn ?
          <AutoSizer>
            {({ width, height }) => (
              <ColumnWidthProvider>
                <Table
                  {...{ width, height }}
                  {...pagerProps}
                  headerHeight={40}
                  tableType="stripes"
                  getLength={() => rows.size}
                  activeRowNumber={activeRowNumber}
                  onActiveCellChange={(e, rowNum) => onSetActiveRowNumber(rowNum)}
                  onColumnResizeEndCallback={onColumnResize}
                  scrollToColumn={newlyCreatedAttributeName ? columnSettings.findIndex(col => col.name === newlyCreatedAttributeName) : undefined}
                >
                  {[
                    InputDatasetColumn({
                      column: datasetNameColumn,
                      columnSortStates,
                      rows,
                      onSort: provider === ES ? onToggleSort : undefined,
                      profilingInfo: getProfilingInfo(datasetNameColumn.name),
                      currentlyProfiling: unifiedDatasetIsCurrentlyProfiling,
                      dataType: datasetType?.getField(ORIGIN_SOURCE_NAME),
                    }),
                    ...columnSettings.filterNot(({ name }) => name === ORIGIN_SOURCE_NAME).filter(col => col.visible).map(column => (
                      DataColumn({
                        column,
                        columnSortStates,
                        rows,
                        onSort: provider === ES ? onToggleSort : undefined,
                        profilingInfo: getProfilingInfo(column.name),
                        currentlyProfiling: unifiedDatasetIsCurrentlyProfiling,
                        dataType: datasetType?.getField(column.name),
                        isGeneratedAttribute: generatedAttributes.contains(column.name),
                        isMlAttribute: (mlAttributes.contains(column.name)),
                      })
                    )).toArray(),
                  ]}
                </Table>
              </ColumnWidthProvider>
            )}
          </AutoSizer>
          :
          noRecordsToShow
        }
      </div>
    );
  }
});

export default RecordsTable;
