import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import { attributeId } from '../models/AttributeId';
import SourceAttribute from '../models/SourceAttribute';
import { getUnifiedDatasetName } from '../utils/Selectors';
import MappingSelector from './MappingSelector';
import { changeMapping, safeUpdateMappable } from './SchemaMappingAsync';

const SourceAttributeMappingSelector = _.compose(
  connect((state) => {
    return {
      datasetName: getUnifiedDatasetName(state),
    };
  }, {
    onChangeMapping: changeMapping,
    onUpdateMappable: safeUpdateMappable,
  }),
)(class SourceAttributeMappingSelector extends React.Component {
  static propTypes = {
    attribute: PropTypes.instanceOf(SourceAttribute).isRequired,
    canUserEdit: PropTypes.bool.isRequired,
    datasetName: PropTypes.string,
    onChangeMapping: PropTypes.func.isRequired,
    onToggleFilterRelatedId: PropTypes.func.isRequired,
    onUpdateMappable: PropTypes.func.isRequired,
  };

  render() {
    const { attribute, datasetName, canUserEdit, onChangeMapping, onToggleFilterRelatedId, onUpdateMappable } = this.props;
    const mappable = !attribute.doNotMap.has(datasetName);
    const className = classNames('source-attribute-mapping-selector', {
      mappable,
      'has-selector': mappable && (attribute.mappedAttributes.size || attribute.recommendations.size),
    });
    return (
      <div className={className}>
        <MappingSelector
          isMappable={mappable}
          mappings={attribute.mappedAttributes}
          recommendations={attribute.recommendations}
          mappingTitle={'MAPPED ATTRIBUTES'}
          suggestionTitle={'TOP SUGGESTIONS (SIMILARITY)'}
          onMappingChange={(id, isMapped) => onChangeMapping(attributeId(attribute), id, isMapped)}
          onToggleFilterRelatedId={() => onToggleFilterRelatedId(attributeId(attribute))}
          onUpdateMappable={(m) => onUpdateMappable(attributeId(attribute), m)}
          canUserEdit={canUserEdit}
        />
      </div>
    );
  }
});

export default SourceAttributeMappingSelector;
