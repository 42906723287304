import classNames from 'classnames';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import DocLink from '../components/DocLink';
import Input from '../components/Input/Input';
import { AppAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { SET_EXTERNAL_DATASET_DESCRIPTION, SET_EXTERNAL_DATASET_NAME, SET_ID_COLUMN } from './AddDatasetActionTypes';
import style from './ExternalStorageContent.module.scss';
import Config from './ExternalStorageNodeConfig';
import StorageBrowser from './StorageBrowser';


/**
 * Metadata about the dataset that will be created (e.g. name and description)
 */
const DatasetMetadata = connect(({ externalStorage: { datasetName, datasetDescription } }: AppState) => {
  return {
    name: datasetName,
    description: datasetDescription,
  };
}, {
  onChangeName: (name: string): AppAction => ({ type: SET_EXTERNAL_DATASET_NAME, name }),
  onChangeDescription: (description: string): AppAction => ({ type: SET_EXTERNAL_DATASET_DESCRIPTION, description }),
})(({ name, description, onChangeName, onChangeDescription }) => (
  <div className={style.datasetMetadata}>
    <Input componentClassName={classNames(style.field, style.name)} value={name} title="Name" onChange={onChangeName} />
    <Input componentClassName={classNames(style.field, style.description)} value={description || ''} title="Description" onChange={onChangeDescription} />
  </div>
));

export default connect((state: AppState) => {
  const { externalStorage: { idColumn, storageProviders } } = state;
  return {
    idColumn,
    noProvidersConnected: storageProviders.isEmpty(),
  };
}, {
  onChangeIdColumn: (idColumn: string): AppAction => ({ type: SET_ID_COLUMN, idColumn }),
})(({ idColumn, noProvidersConnected, onChangeIdColumn }) => (
  <div className={style.container}>
    <div className={style.connectMessage}>
      Connect to data at a location configured by a Tamr administrator.&nbsp;
      <DocLink path="configuration-external-storage-providers">Learn more</DocLink>.
    </div>
    {!noProvidersConnected && (
      <Fragment>
        <StorageBrowser />
        <Config />
        <DatasetMetadata />
        <Input componentClassName={style.idColumn} title="ID Column" value={idColumn} onChange={onChangeIdColumn} />
      </Fragment>
    )}
  </div>
));
