import enumify from './enumify';

export const CATEGORIZATION = 'CATEGORIZATION';
export const DEDUP = 'DEDUP';
export const SCHEMA_MAPPING = 'SCHEMA_MAPPING';
export const SCHEMA_MAPPING_RECOMMENDATIONS = 'SCHEMA_MAPPING_RECOMMENDATIONS';
export const GOLDEN_RECORDS = 'GOLDEN_RECORDS';
export const TRANSFORM = 'TRANSFORM';
export const ENRICHMENT = 'ENRICHMENT';

export type RecipeTypeTSType
  = typeof CATEGORIZATION
  | typeof DEDUP
  | typeof SCHEMA_MAPPING
  | typeof SCHEMA_MAPPING_RECOMMENDATIONS
  | typeof GOLDEN_RECORDS
  | typeof TRANSFORM
  | typeof ENRICHMENT

export default enumify({ CATEGORIZATION, DEDUP, SCHEMA_MAPPING, SCHEMA_MAPPING_RECOMMENDATIONS, GOLDEN_RECORDS, TRANSFORM, ENRICHMENT });
