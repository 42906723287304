import { Map, Set } from 'immutable';
// @ts-expect-error for some reason we can't import uuid in TS?
import { v4 } from 'uuid';
import { refreshAuthCache } from '../auth/AuthCacheAsync';
import { fetchDatasetAttributes } from '../api/DatasetClient';
import { ORIGIN_SOURCE_ID, PERSISTENT_ID } from '../constants/PipelineConstants';
import { SHOW } from '../errorDialog/ErrorDialogActionTypes';
import {
  goldenRecordDataset,
  overrideDataset,
  sourceListDataset,
} from '../goldenRecords/constants/DatasetNames';
import { isReserved } from '../goldenRecords/constants/ReservedFields';
import * as GoldenRecordsAPI from '../goldenRecords/GoldenRecordsAPI';
import { submitJobsOnModuleCreation } from '../goldenRecords/GoldenRecordsAsync';
import * as GoldenRecordsModule from '../goldenRecords/GoldenRecordsModule';
import * as ModeRule from '../goldenRecords/ModeRule';
import { AppThunkAction, AppThunkDispatch } from '../stores/AppAction';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { history } from '../utils/History';
import { FUTURE_PROJECT_ID, FUTURE_PROJECT_NAME } from './ProjectPoliciesSelector';
import {
  CANCEL_ADDING_PROJECT,
  CREATE_GR_PROJECT_SET_WIZARD_STAGE,
  FETCH_PUBLISHED_CLUSTERS_DATASET_ATTRIBUTES,
  FETCH_PUBLISHED_CLUSTERS_DATASET_ATTRIBUTES_COMPLETED,
  FETCH_PUBLISHED_CLUSTERS_DATASET_ATTRIBUTES_FAILED,
  STOP_EDITING_PROJECT_POLICIES,
} from './ProjectsActionTypes';
import { fetch as fetchAllProjects } from './ProjectsApi';
import { getBaseGRPageUrl, selectPublishedMasteringProjectInfos } from './ProjectsStore';

export const NAME_AND_DESCRIPTION_STAGE = 'nameAndDescription';
export const SELECT_MASTERING_PROJECT_STAGE = 'selectMasteringProject';
export const MUST_CREATE_MASTERING_PROJECT_STAGE = 'mustCreateMasteringProject';
export const BOOTSTRAP_ATTRIBUTES_STAGE = 'bootstrapAttributes';
export const CREATE_PROJECT_STAGE = 'createProject';

export const fetchPublishedClustersDatasetAttributes = (
  datasetName: string,
  unifiedAttributes: string[],
) => (dispatch: AppThunkDispatch) => {
  dispatch({ type: FETCH_PUBLISHED_CLUSTERS_DATASET_ATTRIBUTES });
  fetchDatasetAttributes(datasetName)
    .then(attributes => attributes.map(attr => attr.name))
    .then(attributes => {
      // In order to avoid a potential schema mismatch between the published cluster dataset
      // and the unified dataset, we intersect the set of attributes from each dataset here.
      const bootstrapAttributes =
        Map(
          Set(attributes).intersect(Set(unifiedAttributes))
            .toList()
            // default to bootstrap non-reserved attributes
            .map(attr => [attr, !isReserved(attr)]),
        );

      dispatch({ type: FETCH_PUBLISHED_CLUSTERS_DATASET_ATTRIBUTES_COMPLETED, bootstrapAttributes });
    })
    .catch(response => dispatch(
      { type: FETCH_PUBLISHED_CLUSTERS_DATASET_ATTRIBUTES_FAILED, response: response?.message }),
    );
};

export const createGoldenRecord = (
  { displayName, description, clusterDatasetName, attributes, clusterNameField }: { displayName: string, description: string, clusterDatasetName: string, attributes: Set<string>, clusterNameField: string},
): AppThunkAction<void> => {
  checkArg({ attributes }, ArgTypes.Immutable.set.of(ArgTypes.string));
  checkArg({ clusterNameField }, ArgTypes.string);

  const ignoredFields = Set([PERSISTENT_ID, ORIGIN_SOURCE_ID]);
  const ruleAttributes = attributes
    .filter(a => a !== clusterNameField) // will be created as entityRule
    .filter(attribute => !ignoredFields.has(attribute)); // for convenience, don't bootstrap these
  return async (dispatch, getState) => {
    try {
      const { accessControl: { projectDraftPolicyManager } } = getState();
      const query = {
        resourcePolicyIds: projectDraftPolicyManager?.draftPolicyResourceship.toArray(),
        memberPolicyIds: projectDraftPolicyManager?.draftPolicyMembership.toArray(),
      };
      const grModule: GoldenRecordsModule.GoldenRecordsModule = {
        type: GoldenRecordsModule.TYPE,
        clusterDataset: {
          clusterColumn: PERSISTENT_ID,
          id: clusterDatasetName, // input dataset name (not id or external id)
          sourceColumn: ORIGIN_SOURCE_ID,
        },
        externalId: v4(), // random guid
        goldenRecordDataset: goldenRecordDataset(displayName),
        displayName, // Project name
        description,
        sourceListDataset: sourceListDataset(displayName),
        overrideDataset: overrideDataset(displayName),
        entityRule: {
          type: ModeRule.TYPE,
          outputAttributeName: clusterNameField,
          inputAttributeName: clusterNameField,
          suggested: true,
          filters: [],
        },
        rules: ruleAttributes.map((attribute) => ({
          inputAttributeName: attribute,
          filters: [],
          outputAttributeName: attribute,
          type: ModeRule.TYPE,
          suggested: true,
        } as ModeRule.ModeRule)).toArray(),
      };
      const moduleDoc = await GoldenRecordsAPI.createGoldenRecordsModule(grModule, query);
      const moduleId = moduleDoc.id.id;

      // As permissions are updated during project creation, we must force-refresh auth
      await refreshAuthCache(dispatch);

      await submitJobsOnModuleCreation(moduleId);
      dispatch({ type: CANCEL_ADDING_PROJECT });
      dispatch({ type: STOP_EDITING_PROJECT_POLICIES, projectName: FUTURE_PROJECT_NAME, projectId: FUTURE_PROJECT_ID });
      dispatch(fetchAllProjects());
      history.push(getBaseGRPageUrl(moduleId));
    } catch ({ data, message }) {
      dispatch({ type: SHOW, title: 'Unable to create golden record project', detail: message, response: { responseText: data?.stackTrace?.join('\n') } });
      dispatch({ type: STOP_EDITING_PROJECT_POLICIES, projectName: FUTURE_PROJECT_NAME, projectId: FUTURE_PROJECT_ID });
    }
  };
};

export const onGoldenRecordsCreationNext = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const goldenRecordsMasteringProjectOptions = selectPublishedMasteringProjectInfos(state);
  const {
    projects: {
      grProjectCreation: {
        bootstrapAttributes,
        displayName,
        description,
        selectedMasteringProject,
        wizardStage,
      },
    },
  } = state;

  const clusterDatasetName = selectedMasteringProject?.publishedClusterWithData;

  switch (wizardStage) {
    case NAME_AND_DESCRIPTION_STAGE:
      if (goldenRecordsMasteringProjectOptions.size === 0) {
        dispatch({ type: CREATE_GR_PROJECT_SET_WIZARD_STAGE, wizardStage: MUST_CREATE_MASTERING_PROJECT_STAGE });
      } else {
        dispatch({ type: CREATE_GR_PROJECT_SET_WIZARD_STAGE, wizardStage: SELECT_MASTERING_PROJECT_STAGE });
      }
      break;
    case MUST_CREATE_MASTERING_PROJECT_STAGE:
      break;
    case SELECT_MASTERING_PROJECT_STAGE:
      if (clusterDatasetName) {
        dispatch(fetchPublishedClustersDatasetAttributes(
          clusterDatasetName, selectedMasteringProject?.visibleMasteringFields),
        );
        dispatch({ type: CREATE_GR_PROJECT_SET_WIZARD_STAGE, wizardStage: BOOTSTRAP_ATTRIBUTES_STAGE });
      } else {
        console.error(`The selected project ${selectedMasteringProject?.project.name} doesn't have a published cluster dataset.`);
      }
      break;
    case BOOTSTRAP_ATTRIBUTES_STAGE:
      dispatch(createGoldenRecord({
        displayName,
        description,
        // @ts-expect-error
        clusterDatasetName,
        // only bootstrap the attributes that are currently selected
        attributes: bootstrapAttributes.filter(v => v).keySeq().toSet(),
        clusterNameField: selectedMasteringProject?.clusterNameField,
      }));
      dispatch({ type: CREATE_GR_PROJECT_SET_WIZARD_STAGE, wizardStage: CREATE_PROJECT_STAGE });
      break;
    case CREATE_PROJECT_STAGE:
      // not actually canceling creating the project, but use this as a way to reset the store
      dispatch({ type: CANCEL_ADDING_PROJECT });
      break;
    default:
      // TODO: consider adding an assertNever and create a more concrete type for wizardStage.
      //       Perhaps an enum type?
      console.error('Invalid stage for GR project create modal: ', wizardStage);
      break;
  }
};
