import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';

import SimilarityFunction, { isGeospatialSimilarityFunction } from '../schema-mapping/constants/SimilarityFunction';
import WarningIcon from './WarningIcon';

const propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  iconSize: PropTypes.number,
  similarityFunction: SimilarityFunction.propType,
};

const GeospatialBetaWarning: React.FC<InferProps<typeof propTypes>> = (
  { similarityFunction, className, iconClassName, iconSize },
) => {
  if (!similarityFunction || !isGeospatialSimilarityFunction(similarityFunction)) {
    return null;
  }

  return (
    <div className={classNames(className)}>
      <WarningIcon
        className={classNames(iconClassName)}
        size={iconSize}
      /> Geospatial functionality is in beta
    </div>
  );
};

GeospatialBetaWarning.propTypes = propTypes;

export default GeospatialBetaWarning;
