import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import PopoverTrigger from '../components/PopoverTrigger';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import { SHOW_BULK_UNMAP_DIALOG } from './SchemaMappingActionTypes';
import { bulkAllowMapping, bulkDoNotMapOrConfirm, bulkMapRecommendations } from './SchemaMappingAsync';
import style from './SchemaMappingBulkMap.module.scss';

const SchemaMappingBulkMap = _.compose(
  connect(({ schemaMapping: { sourceSelectedIds } }) => {
    return { anySelected: !!sourceSelectedIds.size };
  }, {
    onBulkDoNotMap: bulkDoNotMapOrConfirm,
    onBulkUnmap: () => ({ type: SHOW_BULK_UNMAP_DIALOG }),
    onBulkMapRecommendations: bulkMapRecommendations,
    onBulkAllowMapping: bulkAllowMapping,
  }),
)(class SchemaMappingBulkMap extends React.Component {
  static propTypes = {
    anySelected: PropTypes.bool.isRequired,
    canUserEdit: PropTypes.bool.isRequired,
    onBulkAllowMapping: PropTypes.func.isRequired,
    onBulkDoNotMap: PropTypes.func.isRequired,
    onBulkMapRecommendations: PropTypes.func.isRequired,
    onBulkUnmap: PropTypes.func.isRequired,
  };

  selectOption = (action, ...args) => {
    return () => {
      this.refs.popover.hide();
      action.apply(null, args);
    };
  };

  render() {
    const { onBulkUnmap, onBulkDoNotMap, onBulkMapRecommendations, onBulkAllowMapping, anySelected, canUserEdit } = this.props;
    const button = (
      <Button
        buttonType="Secondary"
        disabled={!anySelected || !canUserEdit}
      >
        <span className={style.buttonWrapper}>
          <span>Map</span>
          <TamrIcon iconName="arrow-drop-down" size={18} className="supplier-action-arrow" />
        </span>
      </Button>
    );
    if (!anySelected) {
      return button;
    }
    return (
      <PopoverTrigger
        ref="popover"
        className={style.mapMenuTrigger}
        placement="bottom"
        content={
          <div className={style.mapMenuContainer}>
            <div
              className={style.mapMenuItem}
              onClick={this.selectOption(onBulkMapRecommendations, { mapToTop: true })}
              data-test-element="source-map-option"
            >
              To Top Suggestion
            </div>
            <TooltipTrigger content="Maps to all suggestions respecting the similarity slider" placement="right">
              <div
                className={style.mapMenuItem}
                onClick={this.selectOption(onBulkMapRecommendations, { mapToTop: false })}
                data-test-element="source-map-option"
              >
                To Suggestions
              </div>
            </TooltipTrigger>
            <hr className={style.menuSeparator} />
            <div
              className={style.mapMenuItem}
              onClick={this.selectOption(onBulkDoNotMap)}
              data-test-element="source-map-option"
            >
              Do Not Map
            </div>
            <hr className={style.menuSeparator} />
            <div
              className={style.mapMenuItem}
              onClick={this.selectOption(onBulkAllowMapping)}
              data-test-element="source-map-option"
            >
              Allow Mapping
            </div>
            <hr className={style.menuSeparator} />
            <div
              className={style.mapMenuItem}
              onClick={this.selectOption(onBulkUnmap)}
              data-test-element="source-map-option"
            >
              Unmap
            </div>
          </div>
        }
      >
        {button}
      </PopoverTrigger>
    );
  }
});

export default SchemaMappingBulkMap;
