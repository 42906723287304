import { Map } from 'immutable';

import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes, toJSON } from './Model';

// com.tamr.procurify.models.geospatial.TileServer

export default class TileServer extends getModelHelpers({
  name: { type: ArgTypes.string },
  urlTemplate: { type: ArgTypes.string },
  wmts: { type: ArgTypes.bool },
  options: { type: ArgTypes.Immutable.map.of(ArgTypes.string, ArgTypes.string), defaultValue: Map<string, string>() },
  authUrl: { type: ArgTypes.nullable(ArgTypes.string) },
}, 'TileServer')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class TileServerRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  toJSON() {
    return toJSON(super.toJSON());
  }

  static fromJSON(obj: $TSFixMe) {
    return new TileServer({
      name: obj.name,
      urlTemplate: obj.urlTemplate,
      wmts: obj.wmts,
      options: Map(obj.options),
      authUrl: obj.authUrl,
    });
  }
}
