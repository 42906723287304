import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect, { Creatable as ReactCreatableSelect, ReactCreatableSelectProps, ReactSelectProps } from 'react-select';

import style from './Selector.module.scss';

export default function Selector<TValue>({ className, children, ...rest }: ReactSelectProps<TValue> & {
  className?: string,
  children?: React.ReactNode
}) {
  return (
    <ReactSelect className={[style.Selector, className].join(' ')} {...rest}>
      {children}
    </ReactSelect>
  );
}

Selector.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export function Creatable<TValue>({ className, children, ...rest }: ReactCreatableSelectProps<TValue> & {
  className?: string,
  children: React.ReactNode
}) {
  return (
    <ReactCreatableSelect className={[style.Selector, className].join(' ')} {...rest}>
      {children}
    </ReactCreatableSelect>
  );
}

Creatable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
