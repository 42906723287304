import PropTypes, { InferProps } from 'prop-types';
import React, { ReactNode, Requireable } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { isNumber, isString } from 'underscore';

import CenterContent from './CenterContent';
import { TamrIconName } from './Icons/TamrIconClasses';
import TamrIcon from './TamrIcon';

const propTypes = {
  items: ImmutablePropTypes.listOf(PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    label: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    iconName: PropTypes.string as Requireable<TamrIconName>,
    delineator: PropTypes.bool,
  }).isRequired).isRequired,
};

type DropdownListProps = InferProps<typeof propTypes>;

class DropdownList extends React.Component<DropdownListProps> {
  static propTypes = propTypes;

  renderItems = () => {
    const elements = [] as ReactNode[];
    this.props.items.forEach(item => {
      const { key, title, label, onClick, iconName, delineator } = item;
      const icon = iconName ? <TamrIcon iconName={iconName} size={14} /> : undefined;
      elements.push(
        <CenterContent
          key={(key || undefined) || (isString(label) || isNumber(label) ? label : undefined)}
          className="menu-option"
          horizontally={false}
          onClick={onClick}
          title={(title || undefined) || ((isString(label) || isNumber(label)) ? String(label) : undefined)}
        >
          {icon}
          <div className="menu-option-label">{label}</div>
        </CenterContent>,
      );
      if (delineator) {
        elements.push(<div key={`${label}-delineator`} className="delineator" />);
      }
    });
    return elements;
  };

  render() {
    return (
      <div className="dropdown-list">
        {this.renderItems()}
      </div>

    );
  }
}

export default DropdownList;
