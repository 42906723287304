import { List, Map, Record, Set } from 'immutable';
import { createSelector } from 'reselect';

import { getRecipeUrlFragment } from '../chrome/UpdatesUtils';
import ProjectTypes from '../constants/ProjectTypes';
import {
  CATEGORIZATION,
  DEDUP,
  ENRICHMENT,
  GOLDEN_RECORDS,
  SCHEMA_MAPPING,
  SCHEMA_MAPPING_RECOMMENDATIONS,
} from '../constants/RecipeType';
import { UPLOAD_AND_CREATE_COMPLETED } from '../datasets/FileUploadActionTypes';
import { SUBMIT_COMPLETED } from '../datasets/ManagedProjectDatasetsActionTypes';
import {
  REMOVE_DATASETS_FROM_PROJECT_COMPLETED,
  SUBMIT_MEMBERSHIP_CHANGES_COMPLETED,
} from '../datasets/ProjectDatasetCatalogActionTypes';
import { COMMIT_CONFIGURE_DATASETS_COMPLETED } from '../datasets/SharedDatasetCatalogActionTypes';
import { SHOW } from '../errorDialog/ErrorDialogActionTypes';
import { isReserved } from '../goldenRecords/constants/ReservedFields';
import {
  PAIR_ESTIMATES_COMPLETED_2S_AGO,
  PAIR_ESTIMATES_COMPLETED_5S_AGO,
} from '../job/JobsActionTypes';
import Document from '../models/doc/Document';
import DocumentId from '../models/doc/DocumentId';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import NavItem from '../models/NavItem';
import Project, { ENABLE_TRANSFORMATIONS_METADATA_KEY } from '../models/Project';
import ProjectInfo from '../models/ProjectInfo';
import ProjectWithStatus from '../models/ProjectWithStatus';
import Recipe from '../models/Recipe';
import RecipeWithStatus from '../models/RecipeWithStatus';
import TileServer from '../models/TileServer';
import {
  COMMIT_UNIFIED_ATTRIBUTES_COMPLETED,
  COMMIT_UNIFIED_ATTRIBUTES_FAILED,
} from '../schema-mapping/SchemaMappingActionTypes';
import { StoreReducers } from '../stores/AppAction';
import { CHANGE } from '../stores/LocationActionTypes';
import { AppState } from '../stores/MainStore';
import { DELETE_TAXONOMY_COMPLETED } from '../stores/TaxonomyActionTypes';
import { UPLOAD_TAXONOMY_COMPLETED } from '../taxonomy/TaxonomyUploadActionTypes';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import {
  activeUserIsCuratorForActiveProject,
  selectActiveProjectInfo,
  selectAllProjectInfos,
  selectLoggedInUserIsAdmin,
} from '../utils/Selectors';
import { projectTerm } from '../utils/Terms';
import { isDefined } from '../utils/Values';
import {
  BOOTSTRAP_ATTRIBUTES_STAGE,
  MUST_CREATE_MASTERING_PROJECT_STAGE,
  NAME_AND_DESCRIPTION_STAGE,
  SELECT_MASTERING_PROJECT_STAGE,
} from './CreateProjectDialogGoldenRecordsActions';
import {
  BEGIN_ADDING_PROJECT,
  BEGIN_CONFIRMING_DELETE_PROJECT,
  BEGIN_EDITING_PROJECT,
  CANCEL_ADDING_PROJECT,
  CANCEL_CONFIRMING_DELETE_PROJECT,
  CANCEL_EDITING_PROJECT,
  CREATE_GR_PROJECT_BACK,
  CREATE_GR_PROJECT_BOOTSTRAP_SEARCH_VALUE,
  CREATE_GR_PROJECT_SELECT_ALL_BOOTSTRAP_ATTRIBUTES,
  CREATE_GR_PROJECT_SET_BOOTSTRAP_ATTRIBUTES,
  CREATE_GR_PROJECT_SET_DESCRIPTION,
  CREATE_GR_PROJECT_SET_DISPLAY_NAME,
  CREATE_GR_PROJECT_SET_WIZARD_STAGE,
  CREATE_GR_PROJECT_TOGGLE_BOOTSTRAP_ATTRIBUTE,
  CREATE_PROJECT,
  CREATE_PROJECT_COMPLETED,
  DELETE_PROJECT,
  DELETE_PROJECT_COMPLETED,
  FETCH,
  FETCH_COMPLETED,
  FETCH_PUBLISHED_CLUSTERS_DATASET_ATTRIBUTES,
  FETCH_PUBLISHED_CLUSTERS_DATASET_ATTRIBUTES_COMPLETED,
  FETCH_PUBLISHED_CLUSTERS_DATASET_ATTRIBUTES_FAILED,
  FETCH_TILE_SERVERS,
  FETCH_TILE_SERVERS_COMPLETED,
  FETCH_TILE_SERVERS_FAILED,
  HIDE_CHOOSE_NEW_PROJECT_TYPE,
  INIT_PROJECT,
  INIT_PROJECT_COMPLETED,
  INIT_PROJECT_FAILED,
  RELOAD,
  RESET_GR_PROJECT_WORKFLOW,
  SELECT_ACTIVE_MASTERING_PROJECT_FOR_GR_PROJECT,
  SET_FILTER_TO_CATEGORIZATION,
  SET_FILTER_TO_ENRICHMENT,
  SET_FILTER_TO_GOLDEN_RECORDS,
  SET_FILTER_TO_MASTERING,
  SET_FILTER_TO_SCHEMA_MAPPING,
  SET_SEARCH_QUERY,
  SHOW_CHOOSE_NEW_PROJECT_TYPE,
  UPDATE_PROJECT_COMPLETED,
} from './ProjectsActionTypes';

export class GRProjectCreation extends getModelHelpers({
  // list of attributes that will be bootstraped
  bootstrapAttributes: {
    // keys are attributes' names, values are selection state for each attribute,
    type: ArgTypes.Immutable.map.of(ArgTypes.bool, ArgTypes.string),
    defaultValue: Map<string, boolean>(),
  },
  bootstrapAttributeSearchValue: { type: ArgTypes.string, defaultValue: '' },
  description: { type: ArgTypes.string, defaultValue: '' }, // string description of new project
  displayName: { type: ArgTypes.string, defaultValue: '' }, // string name of the new project
  loadingPublishedClustersDatasetAttributes: { type: ArgTypes.bool, defaultValue: false },
  selectedMasteringProject: { type: ArgTypes.orUndefined(ProjectInfo.argType) },
  wizardStage: { type: ArgTypes.string, defaultValue: NAME_AND_DESCRIPTION_STAGE },
}, 'GRProjectCreation')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class GRProjectCreationRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

export class ProjectsStore extends getModelHelpers({
  projectsWithStatus: {
    type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(ProjectWithStatus)),
    defaultValue: List<ProjectWithStatus>(),
  },
  initialFetch: { type: ArgTypes.bool, defaultValue: false },
  loading: { type: ArgTypes.bool, defaultValue: false },
  fetchSequence: { type: ArgTypes.number, defaultValue: 1 },
  loadedFilterInfo: { type: ArgTypes.any },
  tileServers: {
    type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(TileServer)),
    defaultValue: List<TileServer>(),
  },
  loadedTileServers: { type: ArgTypes.bool, defaultValue: false },
  initializingProject: { type: ArgTypes.orUndefined(ArgTypes.number) },

  // for projects page specifically
  showChooseNewProjectType: { type: ArgTypes.bool, defaultValue: false },
  addingProject: { type: ArgTypes.orUndefined(ProjectTypes.argType) },
  editingProjectId: { type: ArgTypes.orUndefined(ArgTypes.number) },
  showConfirmDeleteProject: { type: ArgTypes.orUndefined(ArgTypes.number) },
  filterToCategorization: { type: ArgTypes.bool, defaultValue: false },
  filterToMastering: { type: ArgTypes.bool, defaultValue: false },
  filterToSchemaMapping: { type: ArgTypes.bool, defaultValue: false },
  filterToGoldenRecords: { type: ArgTypes.bool, defaultValue: false },
  filterToEnrichment: { type: ArgTypes.bool, defaultValue: false },
  searchQuery: { type: ArgTypes.string, defaultValue: '' },

  // specific for GR projects creation
  grProjectCreation: { type: GRProjectCreation.argType, defaultValue: new GRProjectCreation({}) },
}, 'ProjectsStore')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ProjectsStoreRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  reduce(reduceFunction: Function, accumulator: any) {
    Array.from(this.toSeq().entries()).forEach(entry => {
      accumulator = reduceFunction(accumulator, entry[1], entry[0]);
    });
    return accumulator;
  }
}

const getProjectWithStatusById = (projectsWithStatus: List<ProjectWithStatus>, projectId: number)
  : ProjectWithStatus | undefined => {
  return projectsWithStatus.find(pws => pws.getIn(['project', 'id', 'id']) === projectId);
};

const getProjectsWithStatus = (state: AppState) => state.projects?.projectsWithStatus;

export const selectPublishedMasteringProjectInfos = createSelector(
  selectAllProjectInfos,
  (projectInfos) => projectInfos.filter((projectInfo) => projectInfo.publishedClusterWithData),
);

export const selectProjectDocsById = createSelector(
  getProjectsWithStatus,
  (projectsWithStatus): Map<number, Document<Project>> => projectsWithStatus?.map((pws: ProjectWithStatus) => pws.project)
    .groupBy((projectDoc: Document<Project>) => projectDoc.id.id)
    .toMap()
    .map((docList) => docList.first(undefined))
    .filter(isDefined),
);

export const selectProjectStatusById = createSelector(
  getProjectsWithStatus,
  (projectsWithStatus): Map<number, ProjectWithStatus> => projectsWithStatus?.groupBy((pws: ProjectWithStatus) => pws.project.id.id)
    .toMap()
    .map((pwsList) => pwsList.first(undefined))
    .filter(isDefined),
);

export const selectRecipeDocsById = createSelector(
  getProjectsWithStatus,
  (projectsWithStatus): Map<number, Document<Recipe>> => projectsWithStatus?.flatMap((pws: ProjectWithStatus) => pws.recipes)
    .map((rws: RecipeWithStatus) => rws.recipe)
    .groupBy((recipeDoc: Document<Recipe>) => recipeDoc.id.id)
    .toMap()
    .map((recipeDocList: List<Document<Recipe>>) => recipeDocList.first(undefined))
    .filter(isDefined),
);

const newNavItem = (label: string, destination: string) => new NavItem({ label, destination });

const getNavItemsForProject = (projectDoc: Document<Project>, recipeDocsById: Map<number, Document<Recipe>>)
  : List<NavItem> => {
  checkArg({ projectDoc }, Document.argTypeWithNestedClass(Project));
  checkArg({ recipeDocsById }, ArgTypes.Immutable.map.of(Document.argTypeWithNestedClass(Recipe), ArgTypes.positiveInteger));
  const project = projectDoc.data;
  const navItems: NavItem[] = [];
  if (recipeDocsById.isEmpty()) {
    return List();
  }
  const projectRecipeDocs = project.steps.map(docId => {
    return recipeDocsById.get(docId.id);
  });
  projectRecipeDocs.forEach((recipeDoc: Document<Recipe>, index: number) => {
    if (!recipeDoc) {
      return; // happens sometimes when projects store updates before recipe store
    }
    const recipeUrlFrag = getRecipeUrlFragment(recipeDoc);
    if (index === 0) {
      // right now only show datasets nav item for first recipe in project
      navItems.push(newNavItem('Datasets', `/datasets${recipeUrlFrag}`));
    }
    const recipe = recipeDoc.data;
    const { type } = recipe;
    if (type === SCHEMA_MAPPING) {
      navItems.push(newNavItem('Schema Mapping', `/schema-mapping${recipeUrlFrag}`));
    } else if (type === SCHEMA_MAPPING_RECOMMENDATIONS
      && (project.metadata.get('type') === ProjectTypes.SCHEMA_MAPPING_RECOMMENDATIONS || project.metadata.get(ENABLE_TRANSFORMATIONS_METADATA_KEY))) {
      navItems.push(newNavItem('Unified Dataset', `/records${recipeUrlFrag}`));
    } else if (type === DEDUP) {
      navItems.push(newNavItem('Group Records', `/pregroup${recipeUrlFrag}`));
      navItems.push(newNavItem(`${projectTerm(project, 'Pairs')}`, `/pairs${recipeUrlFrag}`));
      navItems.push(newNavItem('Clusters', `/suppliers${recipeUrlFrag}`));
    } else if (type === CATEGORIZATION) {
      navItems.push(newNavItem('Categories', `/taxonomy${recipeUrlFrag}`));
      navItems.push(newNavItem(`${projectTerm(project, 'Parts')}`, `/spend${recipeUrlFrag}`));
    }
  });
  return List(navItems);
};

const getNavItemsForRecipe = (
  projectDocs: List<Document<Project>>,
  navItemsByProjectId: Map<number, List<NavItem>>,
  recipeId: number,
): List<NavItem> => {
  const containingProjectDoc = projectDocs
    .find(projectDoc => !!projectDoc.data.steps.find(step => step.id === recipeId));

  const emptyList = List<NavItem>();

  if (containingProjectDoc) {
    return navItemsByProjectId.has(containingProjectDoc.id.id)
      ? navItemsByProjectId.get(containingProjectDoc.id.id, emptyList)
      : emptyList;
  }

  return emptyList;
};

export const getBaseGRPageUrl = (moduleId: number): string => {
  checkArg({ moduleId }, ArgTypes.positiveInteger); return `/goldenrecords/${moduleId}`;
};
export const getBaseEnrichmentPageUrl = (moduleId: number): string => {
  checkArg({ moduleId }, ArgTypes.positiveInteger); return `/enrichment/${moduleId}`;
};
export const getGRRulesPageUrl = (moduleId: number): string => {
  checkArg({ moduleId }, ArgTypes.positiveInteger); return `/grRules/${moduleId}`;
};

const getNavItemsForGoldenRecords = (moduleId: number, isCuratorForActiveProject: boolean): List<NavItem> => {
  checkArg({ moduleId }, ArgTypes.positiveInteger);
  checkArg({ isCuratorForActiveProject }, ArgTypes.bool);
  const goldenRecordsUrlFrag = getBaseGRPageUrl(moduleId);
  const grPageNavItem = newNavItem('Golden Records', goldenRecordsUrlFrag);
  if (isCuratorForActiveProject) {
    return List.of(
      newNavItem('Rules', getGRRulesPageUrl(moduleId)),
      grPageNavItem,
    );
  }
  return List.of(grPageNavItem);
};

const getNavItemsForEnrichment = (moduleId: number): List<NavItem> => {
  checkArg({ moduleId }, ArgTypes.positiveInteger);
  const enrichmentUrlFrag = getBaseEnrichmentPageUrl(moduleId);
  const enrichmentPageNavItem = newNavItem('Enrichment', enrichmentUrlFrag);
  return List.of(
    enrichmentPageNavItem,
  );
};
const newMapping: { [key: string]: string; } = {
  Dataset: 'Add Datasets',
};

export const selectTopNav = createSelector(
  selectLoggedInUserIsAdmin,
  selectActiveProjectInfo,
  selectProjectDocsById,
  selectRecipeDocsById,
  state => state.location?.recipeId,
  state => state.location?.moduleId,
  state => state.chrome?.showLastJobFailed,
  activeUserIsCuratorForActiveProject,
  (loggedInUserIsAdmin, project, projectDocsById, recipeDocsById, recipeId, moduleId, showLastJobFailed, isCuratorForActiveProject) => {
    const activeProjectIsGoldenRecords = !!moduleId && project?.projectType === GOLDEN_RECORDS;
    const activeProjectIsEnrichment = !!moduleId && project?.projectType === ENRICHMENT;

    const projectDocs = projectDocsById.toList();
    const navItemsByProjectId = projectDocsById
      .map((projectDoc: Document<Project>) => getNavItemsForProject(projectDoc, recipeDocsById));
    // navItems for active project
    const navItems = (activeProjectIsGoldenRecords
      ? getNavItemsForGoldenRecords(moduleId, isCuratorForActiveProject)
      : activeProjectIsEnrichment
        ? getNavItemsForEnrichment(moduleId)
        : getNavItemsForRecipe(projectDocs, navItemsByProjectId, recipeId) || List()).map((item) => {
      return (item.label in newMapping) ? newNavItem(newMapping[item.label], item.destination) : item;
    });

    // active project
    const projectModels = projectDocs.map((doc : Document<Project>) => doc.data);
    const activeProject = projectModels.find(({ steps }: { steps: List<DocumentId> }) => steps.some(({ id }) => id === recipeId));
    const projectName = activeProject ? activeProject.displayName : 'Projects';

    return {
      navItems,
      projectName,
      loggedInUserIsAdmin,
      projectIsDedup: project && project.projectType === DEDUP,
      lastJobFailed: showLastJobFailed,
      anyProjectAccess: loggedInUserIsAdmin || !projectDocs.isEmpty(),
    };
  },
);

export const getProjectById = (state: ProjectsStore, projectId: number) => {
  const projectWithStatus = getProjectWithStatusById(state.projectsWithStatus, projectId);
  return projectWithStatus ? projectWithStatus.getIn(['project', 'data']) : undefined;
};


export const getRecipeDocById = (
  projectsWithStatus: List<ProjectWithStatus>,
  recipeId: number,
): Document<Recipe> | undefined => {
  return projectsWithStatus
    .flatMap(pws => pws.recipes)
    .map(rws => rws.recipe)
    .find(recipeDoc => recipeDoc.id.id === recipeId);
};

export const getProjectIdByRecipeId = (
  projectsWithStatus: List<ProjectWithStatus>,
  recipeId: number,
): number | undefined => {
  const project = projectsWithStatus
    .find(pws => pws.recipes.some(rws => rws.recipe.id.id === recipeId));
  return project ? project.project.id.id : undefined;
};

export const getUnifiedDatasetIdsByProjectId = (state: ProjectsStore): Map<number, number> => {
  return Map(state.projectsWithStatus.map(pws => {
    const schemaMappingRWS = pws.recipes.find(rws => rws.recipe.data.type === SCHEMA_MAPPING);
    const unifiedDatasetDocId = schemaMappingRWS && schemaMappingRWS.recipe.data.outputDatasets.toList().get(0, undefined);
    const unifiedDatasetId = unifiedDatasetDocId && unifiedDatasetDocId.id;
    // type assertion will suppress TS compile error, but we will still need to make sure to
    // filter out keys with undefined unified dataset ID
    return [pws.project.id.id, unifiedDatasetId] as [number, number];
  })).filter(id => id);
};

export const getProjectGeneratedIdsByProjectId = (state: ProjectsStore): Map<number, Set<number>> => {
  const unifiedDatasetIds = getUnifiedDatasetIdsByProjectId(state).toSet();
  return Map(state.projectsWithStatus.map(pws => {
    const outputDocIds = pws.recipes.flatMap(rws => rws.recipe.data.outputDatasets.toList());
    const outputIds = outputDocIds.map(docId => docId.id);
    return [pws.project.id.id, outputIds.toSet().subtract(unifiedDatasetIds)];
  }));
};

const FilterInfo = Record({ fetchSequence: 1 });

export const getFilterInfo = (state: AppState) => {
  const { projects: { fetchSequence } } = state;
  return new FilterInfo({ fetchSequence });
};

export const initialState = new ProjectsStore({});

const reloadProjects = (state: ProjectsStore) => state.update('fetchSequence', x => x + 1);

// Unsupported recipes can break the UI
const recipeIsSupported = (recipe: RecipeWithStatus) =>
  [
    CATEGORIZATION,
    DEDUP,
    SCHEMA_MAPPING,
    SCHEMA_MAPPING_RECOMMENDATIONS,
    GOLDEN_RECORDS,
    ENRICHMENT,
  ].includes(recipe.recipe.data.type);

export const reducers: StoreReducers<ProjectsStore> = {
  [FETCH]: (state) => {
    return state.set('loading', true);
  },
  [FETCH_COMPLETED]: (state, { projectsWithStatus, filterInfo }) => {
    return state.merge({
      loading: false,
      projectsWithStatus: projectsWithStatus.filter(project => project.recipes.every(recipeIsSupported)),
      initialFetch: true,
      loadedFilterInfo: filterInfo,
    });
  },

  [RELOAD]: reloadProjects,

  [INIT_PROJECT]: (state, { projectId }) => {
    checkArg({ projectId }, ArgTypes.number);
    return state.set('initializingProject', projectId);
  },
  [INIT_PROJECT_COMPLETED]: (state) => {
    return reloadProjects(state).delete('initializingProject');
  },
  [INIT_PROJECT_FAILED]: (state) => {
    return state.delete('initializingProject');
  },

  [REMOVE_DATASETS_FROM_PROJECT_COMPLETED]: reloadProjects,
  [SUBMIT_MEMBERSHIP_CHANGES_COMPLETED]: reloadProjects,
  [UPLOAD_AND_CREATE_COMPLETED]: reloadProjects,
  [COMMIT_CONFIGURE_DATASETS_COMPLETED]: reloadProjects,
  [PAIR_ESTIMATES_COMPLETED_2S_AGO]: reloadProjects,
  [PAIR_ESTIMATES_COMPLETED_5S_AGO]: reloadProjects,
  [DELETE_PROJECT_COMPLETED]: reloadProjects,
  [UPDATE_PROJECT_COMPLETED]: reloadProjects,
  [DELETE_TAXONOMY_COMPLETED]: reloadProjects,
  [UPLOAD_TAXONOMY_COMPLETED]: reloadProjects,
  [SUBMIT_COMPLETED]: reloadProjects,
  [COMMIT_UNIFIED_ATTRIBUTES_COMPLETED]: reloadProjects,
  [COMMIT_UNIFIED_ATTRIBUTES_FAILED]: reloadProjects,

  /**
   * for projects page specifically
   */

  [CHANGE]: (state) => {
    return state.delete('editingProjectId');
  },

  [SHOW_CHOOSE_NEW_PROJECT_TYPE]: (state) => {
    return state.set('showChooseNewProjectType', true);
  },

  [HIDE_CHOOSE_NEW_PROJECT_TYPE]: (state) => {
    return state.set('showChooseNewProjectType', false);
  },

  [BEGIN_ADDING_PROJECT]: (state, { projectType }) => {
    checkArg({ projectType }, ProjectTypes.argType);
    return state.merge({ addingProject: projectType, showChooseNewProjectType: false });
  },

  [CANCEL_ADDING_PROJECT]: (state) => {
    return state.delete('addingProject');
  },

  [BEGIN_EDITING_PROJECT]: (state, { projectId }) => {
    checkArg({ projectId }, ArgTypes.number);
    return state.merge({ editingProjectId: projectId });
  },

  [CANCEL_EDITING_PROJECT]: (state) => {
    return state.delete('editingProjectId');
  },

  [BEGIN_CONFIRMING_DELETE_PROJECT]: (state, { projectId }) => {
    checkArg({ projectId }, ArgTypes.number);
    return state.set('showConfirmDeleteProject', projectId);
  },

  [CANCEL_CONFIRMING_DELETE_PROJECT]: (state) => {
    return state.delete('showConfirmDeleteProject');
  },

  [DELETE_PROJECT]: (state) => {
    return state.delete('showConfirmDeleteProject');
  },

  [SET_FILTER_TO_CATEGORIZATION]: (state, { value }) => {
    return state.set('filterToCategorization', value);
  },

  [SET_FILTER_TO_MASTERING]: (state, { value }) => {
    return state.set('filterToMastering', value);
  },

  [SET_FILTER_TO_SCHEMA_MAPPING]: (state, { value }) => {
    return state.set('filterToSchemaMapping', value);
  },

  [SET_FILTER_TO_GOLDEN_RECORDS]: (state, { value }) => {
    return state.set('filterToGoldenRecords', value);
  },

  [SET_FILTER_TO_ENRICHMENT]: (state, { value }) => {
    return state.set('filterToEnrichment', value);
  },

  [SET_SEARCH_QUERY]: (state, { searchQuery }) => {
    return state.set('searchQuery', searchQuery);
  },

  [CREATE_PROJECT]: (state) => {
    return state.set('loading', true).delete('addingProject');
  },

  [CREATE_PROJECT_COMPLETED]: (
    state,
    { projectsWithStatus }: { projectsWithStatus: List<ProjectWithStatus> },
  ) => {
    return state.merge({ projectsWithStatus, loading: false });
  },

  [SHOW]: (
    state,
  ) => {
    return state.merge({ loading: false });
  },

  [FETCH_TILE_SERVERS]: (state) => {
    return state.merge({ loading: true });
  },

  [FETCH_TILE_SERVERS_COMPLETED]: (
    state,
    { tileServers }: { tileServers: List<TileServer> },
  ) => {
    return state.merge({ loading: false, loadedTileServers: true, tileServers });
  },

  [FETCH_TILE_SERVERS_FAILED]: (state) => {
    return state.merge({ loading: false, loadedTileServers: false });
  },

  [CREATE_GR_PROJECT_SET_WIZARD_STAGE]: (state, { wizardStage }) => {
    return state.mergeIn(['grProjectCreation'], { wizardStage });
  },

  [CREATE_GR_PROJECT_SET_DISPLAY_NAME]: (state, { displayName }) => {
    return state.mergeIn(['grProjectCreation'], { displayName });
  },

  [CREATE_GR_PROJECT_SET_DESCRIPTION]: (state, { description }) => {
    return state.mergeIn(['grProjectCreation'], { description });
  },

  [CREATE_GR_PROJECT_SET_BOOTSTRAP_ATTRIBUTES]: (state, { bootstrapAttributes }) => {
    return state.mergeIn(['grProjectCreation'], { bootstrapAttributes });
  },

  [CREATE_GR_PROJECT_TOGGLE_BOOTSTRAP_ATTRIBUTE]: (state, { attributeName, selectionValue }) => {
    return state.setIn(['grProjectCreation', 'bootstrapAttributes', attributeName], selectionValue);
  },

  [CREATE_GR_PROJECT_SELECT_ALL_BOOTSTRAP_ATTRIBUTES]: (state, { deselect }) => {
    const { grProjectCreation: { bootstrapAttributes } } = state;
    const newAttributes = bootstrapAttributes.map((_v, k) => !deselect && !isReserved(k));
    return state.mergeIn(['grProjectCreation'], { bootstrapAttributes: newAttributes });
  },

  [CREATE_GR_PROJECT_BOOTSTRAP_SEARCH_VALUE]: (state, { searchValue }) => {
    return state.mergeIn(['grProjectCreation'], { bootstrapAttributeSearchValue: searchValue });
  },

  [SELECT_ACTIVE_MASTERING_PROJECT_FOR_GR_PROJECT]: (state, { project }) => {
    return state.mergeIn(['grProjectCreation'], { selectedMasteringProject: project });
  },

  [FETCH_PUBLISHED_CLUSTERS_DATASET_ATTRIBUTES]: (state) => {
    return state.mergeIn(['grProjectCreation'], { loadingPublishedClustersDatasetAttributes: true });
  },

  [FETCH_PUBLISHED_CLUSTERS_DATASET_ATTRIBUTES_COMPLETED]: (state, { bootstrapAttributes }) => {
    return state.mergeIn(
      ['grProjectCreation'],
      {
        loadingPublishedClustersDatasetAttributes: false,
        bootstrapAttributes,
      },
    );
  },

  [FETCH_PUBLISHED_CLUSTERS_DATASET_ATTRIBUTES_FAILED]: (state) => {
    return state.mergeIn(['grProjectCreation'], { loadingPublishedClustersDatasetAttributes: false });
  },

  [CREATE_GR_PROJECT_BACK]: (state) => {
    const { grProjectCreation: { wizardStage } } = state;
    if (wizardStage === MUST_CREATE_MASTERING_PROJECT_STAGE || wizardStage === SELECT_MASTERING_PROJECT_STAGE) {
      return state.mergeIn(['grProjectCreation'], { wizardStage: NAME_AND_DESCRIPTION_STAGE });
    } if (wizardStage === BOOTSTRAP_ATTRIBUTES_STAGE) {
      return state.mergeIn(['grProjectCreation'], { wizardStage: SELECT_MASTERING_PROJECT_STAGE });
    }
    return state;
  },

  [RESET_GR_PROJECT_WORKFLOW]: (state) => {
    return state.delete('addingProject')
      .set('grProjectCreation', new GRProjectCreation({}));
  },
};
