// com.tamr.dataset.models.Task.DriverState

import TaskStorageStatus from '../constants/TaskStorageStatus';
import { ArgTypes } from '../utils/ArgValidation';
import Model from './Model';

class DriverState extends Model({
  driverName: { type: ArgTypes.string },
  driverStatus: { type: TaskStorageStatus.argType },
  rollbackTxnId: { type: ArgTypes.nullable(ArgTypes.string) },
  uuid: { type: ArgTypes.string },
}, 'DriverState') {
  static fromJSON(obj) {
    const { driverName, driverStatus, rollbackTxnId, uuid } = obj;
    return new DriverState({
      driverName,
      driverStatus,
      rollbackTxnId,
      uuid,
    });
  }
}

export default DriverState;
