import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import Toolbar from '../components/Toolbar';
import { AppState } from '../stores/MainStore';
// @ts-expect-error
import CodemirrorTooltipPositioner from '../transforms/CodemirrorTooltipPositioner';
import { getUrlForPage } from '../utils/Routing';
import { selectAllExpanded } from './GoldenRecordsSelectors';
import style from './GoldenRecordsSidebar.module.scss';
import { selectAllUpdatedRuleNames } from './GoldenRecordsStore';
import ReadOnlyGoldenRecordsRule from './ReadOnlyGoldenRecordsRule';


const ReadOnlyGoldenRecordsSidebarContent = connect((state: AppState) => {
  const { goldenRecords } = state;
  const linkToRulesPage = getUrlForPage(state, 'grRules');
  return {
    allRuleNames: selectAllUpdatedRuleNames(goldenRecords),
    allExpanded: selectAllExpanded(state),
    linkToRulesPage,
  };
}, {
  toggleAllExpanded: () => ({ type: 'GoldenRecords.toggleAllExpanded' }),
})(({ allExpanded, allRuleNames, linkToRulesPage, toggleAllExpanded }) => {
  return (
    <div className={style.flex}>
      <CodemirrorTooltipPositioner />
      <div className={style.row}>
        <Toolbar
          className="mt-16 mb-8"
          left={<div className={style.name}>Golden Records rules</div>}
        />
        {/* TODO Expand all button is duped here and in EditGoldenRecordsRulesSidebarContent */}
        <Toolbar
          className="mt-16"
          left={<a href={linkToRulesPage} className={classNames('tamr-button', style.link, 'LinkBtn')}>Edit rules</a>}
          right={(
            <Button
              buttonType="Link"
              onClick={toggleAllExpanded}
              className={style.updateExpanded}>{allExpanded ? 'Collapse all' : 'Expand all'}
            </Button>
          )}
        />
      </div>
      <div className={style.rules}>
        {allRuleNames.map(ruleName => <ReadOnlyGoldenRecordsRule key={ruleName} name={ruleName} />)}
      </div>
    </div>
  );
});

export default ReadOnlyGoldenRecordsSidebarContent;
