import _ from 'underscore';

import enumify from './enumify';

// pages that require column ordering

export const RECORDS = 'RECORDS';
export const PAIRS = 'PAIRS';
export const CLUSTERS = 'CLUSTERS';
export const CLUSTERS_PANE = 'CLUSTERS_PANE';
export const SM_SOURCE_ATTRIBUTE_LIST = 'SM_SOURCE_ATTRIBUTE_LIST';
export const SM_UNIFIED_ATTRIBUTE_LIST = 'SM_UNIFIED_ATTRIBUTE_LIST';
export const TRANSFORMS = 'TRANSFORMS';
export const GOLDEN_RECORDS = 'GOLDEN_RECORDS';
export const GOLDEN_RECORDS_CLUSTER_SAMPLE = 'GOLDEN_RECORDS_CLUSTER_SAMPLE';

export const DataTables = {
  RECORDS,
  PAIRS,
  CLUSTERS,
  CLUSTERS_PANE,
  SM_SOURCE_ATTRIBUTE_LIST,
  SM_UNIFIED_ATTRIBUTE_LIST,
  TRANSFORMS,
  GOLDEN_RECORDS,
  GOLDEN_RECORDS_CLUSTER_SAMPLE,
} as const;

export type DataTablesType
  = typeof RECORDS
  | typeof PAIRS
  | typeof CLUSTERS
  | typeof CLUSTERS_PANE
  | typeof SM_SOURCE_ATTRIBUTE_LIST
  | typeof SM_UNIFIED_ATTRIBUTE_LIST
  | typeof TRANSFORMS
  | typeof GOLDEN_RECORDS
  | typeof GOLDEN_RECORDS_CLUSTER_SAMPLE

export default enumify({ RECORDS, PAIRS, CLUSTERS, SM_SOURCE_ATTRIBUTE_LIST, SM_UNIFIED_ATTRIBUTE_LIST, TRANSFORMS, GOLDEN_RECORDS, GOLDEN_RECORDS_CLUSTER_SAMPLE });

export function isDataTablesType(data: unknown): data is DataTablesType {
  return _.contains(DataTables, data);
}
