import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import Truncate from 'react-truncate';
import { List as VirtualScroll } from 'react-virtualized';

import favicon from '../../images/favicon.png';
import HoverTrigger from '../components/HoverTrigger';
import { ENRICHMENT, GOLDEN_RECORDS } from '../constants/ProjectTypes';
import { getBaseEnrichmentPageUrl, getBaseGRPageUrl } from '../projects/ProjectsStore';
import ProjectTypeIcon from '../projects/ProjectTypeIcon';
import { history } from '../utils/History';
import { selectActiveProjectInfo, selectAllProjectInfos } from '../utils/Selectors';
import { getPath } from '../utils/Values';
import style from './LogoNavItem.module.scss';


const LogoNavItem = connect((state) => {
  const projectInfos = selectAllProjectInfos(state);
  const activeProjectInfo = selectActiveProjectInfo(state);
  const activeProjectName = getPath(activeProjectInfo, 'project', 'name');
  return {
    projectInfos,
    activeProjectName,
  };
}, {

})(({ projectInfos, activeProjectName }) => {
  const sortedProjectInfos = projectInfos.sortBy(({ project: { displayName } }) => displayName.toLowerCase());
  const rowHeight = 45;
  const rowRenderer = ({ index, style: virtualizedStyle }) => {
    const { project: { displayName, name }, projectType, smRecipeId, moduleId } = sortedProjectInfos.get(index);
    return (
      <div
        key={name}
        title={displayName}
        className={classNames(style.dropdownItem, { [style.activeProjectLabel]: name === activeProjectName })}
        style={virtualizedStyle}
        onClick={() => history.push(projectType === GOLDEN_RECORDS
          ? getBaseGRPageUrl(moduleId)
          : projectType === ENRICHMENT
            ? getBaseEnrichmentPageUrl(moduleId)
            : `/dashboard/recipe/${smRecipeId}`)}
      >
        <ProjectTypeIcon projectType={projectType} className={style.typeIcon} size={24} />
        &nbsp;&nbsp;
        <div
          className={style.projectName}
        >
          <Truncate
            lines={2}
            ellipsis={'...'}
          >
            {displayName}
          </Truncate>
        </div>
      </div>
    );
  };

  const totalHeight = projectInfos.size * rowHeight;
  const maxHeight = 600;
  return (
    <HoverTrigger
      className={style.popover}
      placement="bottom"
      showDelay={0}
      disablePopover={projectInfos.isEmpty()}
      content={(
        <VirtualScroll
          {...{ rowHeight, rowRenderer }}
          width={546}
          height={totalHeight > maxHeight ? maxHeight : totalHeight}
          overscanRowCount={2}
          noRowsRenderer={() => <div />}
          rowCount={projectInfos.size}
        />
      )}
      triggerClassName="top-nav-item"
    >
      <img
        data-test-element="logo-nav-item"
        onClick={() => history.push('/')}
        src={favicon}
        style={{ height: 30 }}
      />
    </HoverTrigger>
  );
});

export default LogoNavItem;
