import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import ConditionalButton from '../components/ConditionalButton';
import Term from '../components/Term';
import PairLabelTypes from '../constants/PairLabelTypes';
import { getAuthorizedUser } from '../utils/Selectors';
import { pluralize } from '../utils/Strings';
import { provideFeedbackResponses, removeFeedbackResponses } from './RecordPairsAsync';
import style from './RecordPairsBulkLabelButtons.module.scss';
import { getSelectedPairs } from './RecordPairsStore';

const RecordPairsBulkLabelButtons = _.compose(
  connect(state => {
    const { recordPairs } = state;
    const { selectedRowNumbers } = recordPairs;
    const authorizedUser = getAuthorizedUser(state);
    return { selectedRowNumbers, selectedPairs: getSelectedPairs(recordPairs), loggedInUsername: authorizedUser && authorizedUser.username };
  }, {
    onProvideFeedbackResponses: provideFeedbackResponses,
    onRemoveFeedbackResponses: removeFeedbackResponses,
  }),
)(({ iconify, onProvideFeedbackResponses, onRemoveFeedbackResponses, selectedRowNumbers, selectedPairs, loggedInUsername }) => {
  const bulkRespond = (responseKey) => onProvideFeedbackResponses({ rowNumbers: selectedRowNumbers, responseKey });
  const anySelectedPreconditions = Map().set(<span>You must select one or more <Term>pairs</Term></span>, !selectedPairs.isEmpty());
  const allSelectedPairsAreAssigned = !selectedPairs.find(p => !p.assignedToUser(loggedInUsername));
  const allAssignedPreconditions = Map().set(<span>You must select one or more assigned <Term>pairs</Term></span>, !selectedPairs.isEmpty())
    .set(<span>All selected <Term>pairs</Term> must be assigned</span>, selectedPairs.isEmpty() || allSelectedPairsAreAssigned);
  const anySelectedHaveResponsePrecondition = Map().set(
    <span>You haven't responded to {pluralize(selectedPairs.size, 'the', 'any')} selected <Term amount={selectedPairs.size}>pair</Term> yet</span>,
    selectedPairs.isEmpty() || selectedPairs.some(pair => !!pair.getUserResponseKey(loggedInUsername)),
  );
  const someNotMatchPrecondition = Map().set(
    <span>You already responded with Match.</span>,
    selectedPairs.isEmpty() || selectedPairs.some(pair => pair.responseKeyForUser(loggedInUsername) !== 'MATCH'),
  );
  const someNotNotMatchPrecondition = Map().set(
    <span>You already responded with No match.</span>,
    selectedPairs.isEmpty() || selectedPairs.some(pair => pair.responseKeyForUser(loggedInUsername) !== 'NON_MATCH'),
  );
  const someNotSkipPrecondition = Map().set(
    <span>You already skipped {pluralize(selectedPairs.size, <span>this <Term>pair</Term></span>, <span>these <Term>pairs</Term></span>)}.</span>,
    selectedPairs.isEmpty() || selectedPairs.some(pair => pair.responseKeyForUser(loggedInUsername) !== 'SKIP'),
  );
  const matchLabel = 'Match';
  const noMatchLabel = 'No match';
  const skipLabel = 'Skip';
  const removeLabel = 'Remove response';
  return (
    <div className={style.component}>
      <div className={style.buttonGroup}>
        <ConditionalButton
          className={style.groupedButton}
          onClick={() => bulkRespond(PairLabelTypes.manual.MATCH)}
          buttonType="Secondary"
          icon="match"
          iconSize={16}
          preconditions={anySelectedPreconditions.merge(someNotMatchPrecondition)}
          tooltipLabel={iconify ? matchLabel : ''}
        >
          {iconify ? undefined : matchLabel}
        </ConditionalButton>
        <ConditionalButton
          className={style.groupedButton}
          onClick={() => bulkRespond(PairLabelTypes.manual.NON_MATCH)}
          buttonType="Secondary"
          icon="do-not-disturb-alt"
          iconSize={16}
          preconditions={anySelectedPreconditions.merge(someNotNotMatchPrecondition)}
          tooltipLabel={iconify ? noMatchLabel : ''}
        >
          {iconify ? undefined : noMatchLabel}
        </ConditionalButton>
        <ConditionalButton
          className={style.groupedButton}
          onClick={() => bulkRespond('SKIP')}
          buttonType="Secondary"
          icon="skip-next"
          iconSize={16}
          preconditions={allAssignedPreconditions.merge(someNotSkipPrecondition)}
          tooltipLabel={iconify ? skipLabel : ''}
        >
          {iconify ? undefined : skipLabel}
        </ConditionalButton>
      </div>
      <ConditionalButton
        className={style.ungroupedButton}
        onClick={() => onRemoveFeedbackResponses({ rowNumbers: selectedRowNumbers })}
        buttonType="Secondary"
        icon="remove-circle-outline"
        iconSize={16}
        preconditions={anySelectedPreconditions.concat(anySelectedHaveResponsePrecondition)}
        tooltipLabel={iconify ? removeLabel : ''}
      >
        {iconify ? undefined : removeLabel}
      </ConditionalButton>
    </div>
  );
});

RecordPairsBulkLabelButtons.propTypes = {
  iconify: PropTypes.bool.isRequired,
};

export default RecordPairsBulkLabelButtons;
