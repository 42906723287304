import './Input.scss';

import classNames from 'classnames';
import PropTypes, { InferProps, Validator } from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import _, { isNumber, isString } from 'underscore';

import { HTMLInputProps } from '../../utils/typescript-react';
import newId from './Id';

const propTypes = {

  // autoFocus this input when it mounts. This should be true when it is the first input in a dialog
  autoFocusOnMount: PropTypes.bool,

  // To be passed to the CONTAINER of the input, not to the input itself (like className is)
  // TODO change this to simply className, audit all usages of Button to ensure passing in className to container is fine
  componentClassName: PropTypes.string,

  /**
   * Starting value for text field.
   * Only truly applies when component is in uncontrolled state (value prop is not being specified)
   * otherwise starting value should just be set by initial props.value value
   */
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),

  // If true, styling will be highlighted yellow (default false)
  hasWarning: PropTypes.bool,

  // If true, styling will be highlighted red (default false)
  invalid: PropTypes.bool,

  // If true, value will be shown just as dots (eg. for password inputs) (default false)
  masked: PropTypes.bool,
  /**
   * onBlur()
   * Callback to be invoked when the input loses focus.
   */
  onBlur: PropTypes.func,
  /**
   * onChange(newValue)
   * Callback to be invoked when user changes value of the input.
   */
  onChange: PropTypes.func.isRequired as Validator<(value: string, event: React.ChangeEvent<HTMLInputElement>) => void>,

  // show the default-styled tooltip for the title on hover
  showTitleHover: PropTypes.bool,

  // The content of the floating label
  title: PropTypes.string.isRequired,
  // Just like <input type="..." />, except this class only supports text, number or password
  type: PropTypes.oneOf<'text' | 'number' | 'password'>(['text', 'number', 'password']),
  /**
   * Value of the field. Defining this prop puts this component in a controlled state
   * (component will not change its own value)
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /**
   * Whether the field is shown on a light or a dark background.  Default is `light`.
   */
  variant: PropTypes.oneOf<'light' | 'dark'>(['light', 'dark']),
  /**
   * Width as either a percentage, or pixel width.  Note: default is `100%`
   */
  width: PropTypes.string,
};
type InputProps = Omit<HTMLInputProps, keyof typeof propTypes> & InferProps<typeof propTypes>

const defaultProps = {
  invalid: false,
  masked: false,
  onBlur: () => {},
  variant: 'light',
  width: '100%',
  hasWarning: false,
};

type InputState = {
  id: string,
  focused: boolean
}

/**
 * Thin wrapper around <input> which pulls the value out of the change event.
 * Passes all non-specified <input> props directly through to inner <input>
 * Uses the floating label pattern to identify the input, see
 * http://mds.is/float-label-pattern/ for more info
 */
class Input extends React.Component<InputProps, InputState> {
  static propTypes = propTypes;

  state = {
    id: 'input_' + newId(),
    focused: this.props.autoFocusOnMount || false,
  };

  blur = () => {
    // @ts-expect-error findDOMNode returns Element | Text which doesn't support blur method
    ReactDOM.findDOMNode(this.refs.rawInput).blur();
  };

  focus = () => {
    // @ts-expect-error findDOMNode returns Element | Text which doesn't support focus method
    ReactDOM.findDOMNode(this.refs.rawInput).focus();
  };

  componentDidMount() {
    if (this.props.autoFocusOnMount) {
      setTimeout(this.focus, 350);
    }
  }

  handleBlur = () => {
    this.props.onBlur ? this.props.onBlur() : defaultProps.onBlur();
    this.setState({
      focused: false,
    });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Validate number since this causes styling badness
    if (this.props.type === 'number' && _.isNaN(parseInt(event.target.value, 10))) {
      this.props.onChange('', event);
    } else {
      this.props.onChange(event.target.value, event);
    }
  };

  handleFocus = () => {
    this.setState({
      focused: true,
    });
  };

  render() {
    const { showTitleHover, title, invalid, masked, componentClassName, value, defaultValue, ...rest } = this.props;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { autoFocusOnMount, ...htmlInputProps } = rest;

    // the input is empty if:
    // the value AND defaultValue are undefined/null/''
    const compClasses = classNames('tamr-input', componentClassName, {
      empty: (_.isUndefined(value) || _.isNull(value) || value === '') && (_.isUndefined(defaultValue) || _.isNull(defaultValue) || defaultValue === ''),
      focused: this.state.focused,
      'light-variant': (this.props.variant || defaultProps.variant) === 'light',
      'dark-variant': (this.props.variant || defaultProps.variant) === 'dark',
      invalid: this.props.invalid,
      hasWarning: this.props.hasWarning && !this.props.invalid, // If both warnign and error is present, error styling is used
    });
    const type = this.props.masked ? 'password' : (this.props.type || undefined);
    const width = this.props.width || defaultProps.width;

    return (
      <div
        className={compClasses}
        style={{ width }}
      >
        <label htmlFor={this.state.id}>{this.props.title}</label>
        <input
          {...htmlInputProps}
          ref="rawInput"
          width={width}
          title={showTitleHover ? title : undefined}
          defaultValue={defaultValue !== undefined ? String(defaultValue) : undefined /* placeholder if value is undefined (not ''!) */}
          value={isString(value) || isNumber(value) ? value : undefined /* if undefined, ignored and <input> is uncontrolled */}
          type={type}
          id={this.state.id}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          // @ts-expect-error unsure why TS input doesn't recognize invalid
          invalid={Boolean(invalid).toString()}
          masked={Boolean(masked).toString()}
        />
      </div>
    );
  }
}

export default Input;
