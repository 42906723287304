import { List, Set } from 'immutable';

import { ArgTypes } from '../utils/ArgValidation';
import AttributeId from './AttributeId';
import MappingRecommendation from './MappingRecommendation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

class SourceAttribute extends getModelHelpers({
  name: { type: ArgTypes.string },
  datasetName: { type: ArgTypes.string },
  description: { type: ArgTypes.nullable(ArgTypes.string) },
  doNotMap: { type: ArgTypes.Immutable.set.of(ArgTypes.string) },
  mappedAttributes: { type: ArgTypes.Immutable.set.of(ArgTypes.instanceOf(AttributeId)), defaultValue: Set<AttributeId>() },
  numRecommendations: { type: ArgTypes.number, defaultValue: 0 },
  recommendations: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(MappingRecommendation)), defaultValue: List<MappingRecommendation>() },
}, 'SourceAttribute')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class SourceAttributeRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

export default SourceAttribute;
