import { List, Map, Set } from 'immutable';
import React from 'react';
import _, { isNumber } from 'underscore';

import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import TooltipTrigger from '../components/TooltipTrigger';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import Project from '../models/Project';
import { history } from '../utils/History';
import style from './DatasetOriginColumn.module.scss';
import { DatasetTypeText } from './DatasetUtils';

const DatasetOriginColumn: React.FC<{
  datasets: List<Document<Dataset>>
  unifiedDatasetIdsByProjectId: Map<number, number>
  projectGeneratedIdsByProjectId: Map<number, Set<number>>
  projectsById: Map<number, Document<Project>>
}> = ({ datasets, unifiedDatasetIdsByProjectId, projectGeneratedIdsByProjectId, projectsById }) => {
  return (
    <Column
      key="origin"
      width={150}
      isResizable
      columnKey="origin"
      flexGrow={1}
      header={<Cell>Origin</Cell>}
      cell={({ rowIndex }) => { // eslint-disable-line react/no-multi-comp
        const datasetDoc = datasets.get(rowIndex);
        if (!datasetDoc) return <Cell />;
        const dataset = datasetDoc.data;
        const unifiedDatasetOfProjectId = unifiedDatasetIdsByProjectId.findKey(id => id === datasetDoc.id.id);
        const generatedFromProjectId = projectGeneratedIdsByProjectId.findKey(ids => ids.includes(datasetDoc.id.id));
        const getLink = (id: number | undefined) => {
          const project = isNumber(id) && projectsById.get(id)?.data;
          if (!project) return null;
          const { displayName, steps } = project;
          return <a onClick={() => history.push(`/dashboard/recipe/${steps.first(undefined)?.id}`)}>{displayName}</a>;
        };
        const getContents = () => {
          switch (true) {
            case _.isNumber(unifiedDatasetOfProjectId):
              return (
                <TooltipTrigger content={DatasetTypeText.UNIFIED_DATASET}>
                  <span>Unified Dataset for {getLink(unifiedDatasetOfProjectId)}</span>
                </TooltipTrigger>
              );
            case dataset.metadata.get('isSystemDataset') === true:
              return (
                <TooltipTrigger content={DatasetTypeText.SYSTEM_DATASET}>
                  <span>System</span>
                </TooltipTrigger>
              );
            case dataset.upstreamDatasets.isEmpty():
              return (
                <TooltipTrigger content={DatasetTypeText.SOURCE}>
                  <span>Source</span>
                </TooltipTrigger>
              );
            case _.isNumber(generatedFromProjectId):
              return (
                <TooltipTrigger content={DatasetTypeText.PROJECT_GENERATED}>
                  <span>Results and Internals for {getLink(generatedFromProjectId)}</span>
                </TooltipTrigger>
              );
            default:
              return (
                <TooltipTrigger content={DatasetTypeText.NON_PROJECT_DERIVED}>
                  <span>Sample, Group-by and API-derived</span>
                </TooltipTrigger>
              );
          }
        };
        return <Cell className={style.actionCell}>{getContents()}</Cell>;
      }}
    />
  );
};

export default DatasetOriginColumn;
