import { List, Map, OrderedSet, Set } from 'immutable';

import Dataset from '../models/Dataset';
import * as DatasetRefinement from '../models/DatasetRefinement';
import DatasetStatus from '../models/DatasetStatus';
import Document from '../models/doc/Document';
import Export from '../models/Export';
import ProfilingInfo from '../models/ProfilingInfo';
import { FETCH_COMPLETED } from '../projects/ProjectsActionTypes';
import { SET_SOURCE_FILTER_DATASETS } from '../schema-mapping/SchemaMappingActionTypes';
import { StoreReducers } from '../stores/AppAction';
import { getResetFns, merge, set, update } from '../utils/Collections';
import { keyModSelect } from '../utils/TableSelection';
import { UPLOAD_AND_CREATE_COMPLETED } from './FileUploadActionTypes';
import {
  REMOVE_DATASETS_FROM_PROJECT_COMPLETED,
  SUBMIT_MEMBERSHIP_CHANGES_COMPLETED,
} from './ProjectDatasetCatalogActionTypes';
import { COMMIT_CONFIGURE_DATASETS_COMPLETED } from './SharedDatasetCatalogActionTypes';

export interface ProjectDatasetCatalogFilterInfo {
  page: number,
  pageSize: number,
  search: string,
  showSource: boolean,
  showDerived: boolean,
  fetchSequence: number
  recipeId: number
}

export function getFilterInfo(store: ProjectDatasetCatalogStore, recipeId: number): ProjectDatasetCatalogFilterInfo {
  const { paging: { page, pageSize, search, showSource, showDerived }, fetchSequence } = store;
  return { page, pageSize, search, showSource, showDerived, fetchSequence, recipeId };
}

interface ProjectDatasetCatalogStore {
  noDatasetsInProject: boolean,
  // map of relevant profiling infos by dataset name
  profiling: Map<string, ProfilingInfo>,
  // map of relevant export configs by dataset id
  exporting: Map<number, Document<Export>>,
  // map of relevant dataset status by dataset name
  status: Map<string, DatasetStatus>,
  // list containing current page of dataset docs (no profiling or exporting information)
  datasets: List<Document<Dataset>>,
  // map representing currently running profiling jobs; maps job ID to dataset ID
  currentlyProfiling: Map<number, number>,
  // map representing currently running export jobs; maps job ID to dataset ID
  currentlyExporting: Map<number, number>,
  // current page / filter state
  paging: DatasetRefinement.DatasetRefinement,
  // whether a request is currently in flight
  loading: boolean,
  loadedFilterInfo: ProjectDatasetCatalogFilterInfo | undefined,
  fetchSequence: number,
  selectedRows: OrderedSet<number>,
  // for managing dataset membership
  currentlyManagingMembership: boolean,
  manageMembershipShowUpload: boolean,
  // for removing
  confirmingRemoveForDatasets: Set<string>,
}

export const initialState: ProjectDatasetCatalogStore = {
  noDatasetsInProject: false,
  profiling: Map<string, ProfilingInfo>(),
  exporting: Map<number, Document<Export>>(),
  status: Map<string, DatasetStatus>(),
  datasets: List<Document<Dataset>>(),
  currentlyProfiling: Map<number, number>(),
  currentlyExporting: Map<number, number>(),
  paging: DatasetRefinement.defaults,
  loading: false,
  loadedFilterInfo: undefined,
  fetchSequence: 1,
  selectedRows: OrderedSet<number>(),
  currentlyManagingMembership: false,
  manageMembershipShowUpload: false,
  confirmingRemoveForDatasets: Set<string>(),
} as const;

const { reset, resetter } = getResetFns(initialState);

const clearFilter = (state: ProjectDatasetCatalogStore) => reset(state, 'paging');
const reloadDatasets = (state: ProjectDatasetCatalogStore) => update(state, 'fetchSequence', x => x + 1);
const clearSelection = resetter('selectedRows');

const resetMembershipManagement = (state: ProjectDatasetCatalogStore) => {
  return update(state,
    resetter('currentlyManagingMembership'),
    resetter('manageMembershipShowUpload'));
};

export const reducers: StoreReducers<ProjectDatasetCatalogStore> = {
  [FETCH_COMPLETED]: reloadDatasets,
  'ProjectDatasetCatalog.reload': reloadDatasets,
  'Tags.linkTagCompleted': reloadDatasets,
  'Tags.removeLinkCompleted': reloadDatasets,
  'Datasets.profileDatasetCompleted': reloadDatasets,
  'ProjectDatasetCatalog.fetch': (state) => {
    return set(state, 'loading', true);
  },
  'ProjectDatasetCatalog.fetchCompleted': (state, { datasets, noDatasetsInProject, numDatasets, numPages, loadedFilterInfo, profiling, exporting, status }) => {
    return update(state,
      s => merge(s, { loadedFilterInfo, profiling, exporting, status, loading: false, noDatasetsInProject, datasets }),
      s => update(s, 'paging', p => merge(p, { numDatasets, numPages })));
  },
  'ProjectDatasetCatalog.setPage': (state, { pageNum }) => {
    return update(state,
      clearSelection,
      s => update(s, 'paging', p => set(p, 'page', Math.min(state.paging.numPages - 1, pageNum))));
  },
  'ProjectDatasetCatalog.setPageSize': (state, { pageSize }) => {
    return update(state,
      clearSelection,
      s => update(s, 'paging', p => set(p, 'pageSize', pageSize)));
  },
  'ProjectDatasetCatalog.setSearchString': (state, { search }) => {
    return update(state,
      clearSelection,
      s => update(s, 'paging', p => set(p, 'search', search)));
  },
  'ProjectDatasetCatalog.setShowingSource': (state, { showing }) => {
    return update(state, 'paging', p => set(p, 'showSource', showing));
  },
  'ProjectDatasetCatalog.setShowingDerived': (state, { showing }) => {
    return update(state, 'paging', p => set(p, 'showDerived', showing));
  },
  'ProjectDatasetCatalog.beginManagingMembership': (state) => {
    return set(state, 'currentlyManagingMembership', true);
  },
  'ProjectDatasetCatalog.resetMembershipManagement': (state) => {
    return resetMembershipManagement(state);
  },
  [SUBMIT_MEMBERSHIP_CHANGES_COMPLETED]: (state) => {
    return resetMembershipManagement(reloadDatasets(state));
  },
  'ProjectDatasetCatalog.toggleShowUpload': (state) => {
    return update(state, 'manageMembershipShowUpload', s => !s);
  },
  [UPLOAD_AND_CREATE_COMPLETED]: (state) => {
    return resetMembershipManagement(reloadDatasets(state));
  },
  'ProjectDatasetCatalog.confirmRemoveDatasets': (state, { datasetNames }) => {
    return set(state, 'confirmingRemoveForDatasets', datasetNames);
  },
  'ProjectDatasetCatalog.cancelRemovingDatasets': (state) => {
    return reset(state, 'confirmingRemoveForDatasets');
  },
  'ProjectDatasetCatalog.removeDatasetsFromProject': (state) => {
    return reset(state, 'confirmingRemoveForDatasets');
  },
  'ProjectDatasetCatalog.selectRows': (state, { selectedRows }) => {
    return merge(state, { selectedRows });
  },
  'ProjectDatasetCatalog.clickRows': (state, { keyMods, rowNum }) => {
    const rowsSelected = state.selectedRows;
    const { selectedRows } = keyModSelect({
      keyMods,
      selectedRows: rowsSelected,
      lastSelectedRow: rowsSelected.last() || -1,
      selectedRow: rowNum,
    });
    return merge(state, { selectedRows: selectedRows.toOrderedSet() });
  },
  [REMOVE_DATASETS_FROM_PROJECT_COMPLETED]: (state) => {
    return clearSelection(reloadDatasets(state));
  },
  [COMMIT_CONFIGURE_DATASETS_COMPLETED]: reloadDatasets,
  'Location.projectChange': (state) => {
    return clearSelection(clearFilter(state));
  },
  'SharedDatasetCatalog.confirmProfile': (state) => {
    return reset(state, 'selectedRows');
  },
  [SET_SOURCE_FILTER_DATASETS]: (state) => {
    return reset(state, 'selectedRows');
  },
  'AddDataset.hideDialog': (state) => {
    return resetMembershipManagement(state);
  },
  'AddDataset.createExternalDatasetCompleted': (state) => {
    return resetMembershipManagement(reloadDatasets(state));
  },
  'AddDataset.createExternalDatasetFailed': (state) => {
    return resetMembershipManagement(reloadDatasets(state));
  },
};
