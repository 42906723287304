/* eslint react/no-danger:0 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import DetailSidebar from '../components/DetailSidebar/DetailSidebar';
import DetailSidebarTabbedContent from '../components/DetailSidebar/DetailSidebarTabbedContent';
import SidebarTabs from '../constants/SidebarTabs';
import EsRecord from '../models/EsRecord';
import TransformsSidebarTab from '../transforms/TransformsSidebarTab';
import PreviewRecord from './PreviewRecord';
import { getActiveRecord } from './RecordsStore';

const RecordsSidebar = _.compose(
  connect((state) => {
    const { records } = state;
    const { expanded } = records;
    return { isExpanded: expanded, record: getActiveRecord(records) };
  }, {
    onCollapseEditor: () => ({ type: 'Transforms.setBigEditor', enabled: false }),
  }),
)(class RecordsSidebar extends React.Component {
  static propTypes = {
    isExpanded: PropTypes.bool.isRequired,
    onCollapseEditor: PropTypes.func.isRequired,
    record: PropTypes.oneOfType([
      PropTypes.instanceOf(EsRecord),
      PropTypes.instanceOf(PreviewRecord),
    ]),
  };

  render() {
    const { isExpanded } = this.props;
    const tabs = new Immutable.List([
      {
        eventKey: SidebarTabs.TRANSFORMS,
        title: 'Transformations',
        content: (<TransformsSidebarTab />),
      },
    ]);
    return (
      <DetailSidebar
        isExpanded={isExpanded}
      >
        <DetailSidebarTabbedContent
          id="transformations-tabbed-content"
          tabs={tabs}
        />
      </DetailSidebar>
    );
  }
});

export default RecordsSidebar;
