import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';

import TamrIcon from './TamrIcon';
import style from './Voter.module.scss';

export const Vote = Object.freeze({
  UP: 'UP',
  DOWN: 'DOWN',
});

const propTypes = {
  canVote: PropTypes.bool.isRequired,
  currentVote: PropTypes.string,
  numVotes: PropTypes.number,
  onVoteCancel: PropTypes.func.isRequired,
  onVoteDown: PropTypes.func,
  onVoteUp: PropTypes.func.isRequired,
};

type VoterProps = InferProps<typeof propTypes>;

/**
 * Component used to either up/down vote (up/down arrow) or simply vote (+)
 */
class Voter extends React.Component<VoterProps> {
  static propTypes = propTypes;

  render() {
    const { canVote, currentVote, onVoteCancel, onVoteDown, onVoteUp } = this.props;
    // TODO default-props-in-typescript
    const numVotes = (this.props.numVotes === undefined || this.props.numVotes === null) ? 0 : this.props.numVotes;
    return (
      <div className={style.component}>
        <div className={classNames(style.tally, { [style.positive]: currentVote && numVotes > 0, [style.negative]: currentVote && numVotes < 0 })}>
          {numVotes > 0 ? `+${numVotes}` : numVotes}
        </div>
        {canVote ?
          <div className={style.buttons}>
            <button className={style.button} onClick={currentVote === Vote.UP ? onVoteCancel : onVoteUp}>
              <TamrIcon
                className={classNames(style.upArrow, { [style.current]: currentVote === Vote.UP })}
                iconName="tamr-icon-rounded-arrow-down"
                size={16}
              />
            </button>
            <button className={style.button} onClick={(currentVote === Vote.DOWN ? onVoteCancel : onVoteDown) || undefined}>
              <TamrIcon
                className={classNames(style.downArrow, { [style.current]: currentVote === Vote.DOWN })}
                iconName="tamr-icon-rounded-arrow-down"
                size={16}
              />
            </button>
          </div>
          : null}
      </div>
    );
  }
}

export default Voter;
