import PolicyMemberType from '../constants/PolicyMemberType';
import PolicyResourceType from '../constants/PolicyResourceType';
import AuthorizationPolicy from '../models/AuthorizationPolicy';
import Document from '../models/doc/Document';
import Group from '../models/Group';
import MinimalAuthUser from '../models/MinimalAuthUser';
import TaggedUnion, { InferConstructedKind } from '../models/TaggedUnion';
import { ArgTypes } from '../utils/ArgValidation';

/**
 * Model representing policy configuration states and necessary information
 */
export const policyConfigKindDefinitions = {
  Create: {
    name: ArgTypes.string,
    description: ArgTypes.string,
    creating: ArgTypes.bool,
  },
  Delete: {
    currentPolicy: Document.argTypeWithNestedClass(AuthorizationPolicy),
    deleting: ArgTypes.bool,
  },
  Duplicate: {
    name: ArgTypes.string,
    description: ArgTypes.string,
    currentPolicy: Document.argTypeWithNestedClass(AuthorizationPolicy),
    duplicating: ArgTypes.bool,
  },
  Edit: {
    name: ArgTypes.string,
    description: ArgTypes.string,
    currentPolicy: Document.argTypeWithNestedClass(AuthorizationPolicy),
    updating: ArgTypes.bool,
  },
  ManageMembers: {
    currentPolicy: Document.argTypeWithNestedClass(AuthorizationPolicy),
    updating: ArgTypes.bool,
    tab: ArgTypes.valueIn(PolicyMemberType),
    authUsers: ArgTypes.Immutable.list.of(MinimalAuthUser.argType),
    groupDocs: ArgTypes.Immutable.list.of(Document.argTypeWithNestedClass(Group)),
    draftUsernamesToRole: ArgTypes.Immutable.map.of(ArgTypes.string, ArgTypes.string),
    draftGroupnamesToRole: ArgTypes.Immutable.map.of(ArgTypes.string, ArgTypes.string),
    draftProjectIdsToRole: ArgTypes.Immutable.map.of(ArgTypes.string, ArgTypes.number),
    applyToAllUsers: ArgTypes.bool,
    allUsersRole: ArgTypes.string,
    applyToAllUserGroups: ArgTypes.bool,
    allUserGroupsRole: ArgTypes.string,
    applyToAllProjects: ArgTypes.bool,
    query: ArgTypes.string,
  },
  ManageResources: {
    currentPolicy: Document.argTypeWithNestedClass(AuthorizationPolicy),
    updating: ArgTypes.bool,
    tab: ArgTypes.valueIn(PolicyResourceType),
    datasetFilterOpen: ArgTypes.bool,
    draftProjectIds: ArgTypes.Immutable.set.of(ArgTypes.string),
    draftResourcePolicyIds: ArgTypes.Immutable.set.of(ArgTypes.string),
    applyToAllProjects: ArgTypes.bool,
    applyToAllDatasets: ArgTypes.bool,
  },
};

const PolicyConfig = TaggedUnion(policyConfigKindDefinitions, 'PolicyConfig');
export type PolicyConfigType = InferConstructedKind<typeof policyConfigKindDefinitions>;

export default PolicyConfig;
