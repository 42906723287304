import 'codemirror/addon/mode/simple';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/comment/comment';
import './TransformCodeMirror.scss';

import classNames from 'classnames';
import cm from 'codemirror/lib/codemirror';
import { is, List, Set } from 'immutable';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';
import CodeMirror from 'react-codemirror';
import { connect } from 'react-redux';
import { TransformsKeywords } from 'tamr-transforms';
import _ from 'underscore';

import { browserIsOnMac } from '../models/KeyMods';
import {
  FUNCTION_TOKEN_NAME,
  getContext,
  getSyntaxHighlightingTokens,
  HINT_TOKEN_NAME,
  KEYWORD_TOKEN_NAME,
  NAMED_UNIFIED_ATTRIBUTE_TOKEN_NAME,
  TEMP_NAMED_UNIFIED_ATTRIBUTE_TOKEN_NAME,
  TEMP_UNIFIED_ATTRIBUTE_TOKEN_NAME,
  UNIFIED_ATTRIBUTE_TOKEN_NAME,
} from '../models/Transforms';
import { selectStagedUnifiedDatasetColumnNames } from '../records/RecordsColumns';
import ContentEnvelope, { ContentEnvelopeTypes } from './models/ContentEnvelope';
import { getErrors, getSuggestions } from './TransformInputFunctions';
import { getLatestForBulkTransform, getUnifiedScopedGuids, selectFunctionNames, selectFunctionTooltipMap, selectLints } from './TransformsStore';

const CODE_MIRROR_CLASS_PREFIX = 'cm-';
const EMPTY_LINT_ERRORS = List();
const dataTypeValidTokenNames = Set([UNIFIED_ATTRIBUTE_TOKEN_NAME, NAMED_UNIFIED_ATTRIBUTE_TOKEN_NAME, TEMP_UNIFIED_ATTRIBUTE_TOKEN_NAME, TEMP_NAMED_UNIFIED_ATTRIBUTE_TOKEN_NAME, FUNCTION_TOKEN_NAME]);
const dataTypeValidTokenClassNames = dataTypeValidTokenNames.map(name => CODE_MIRROR_CLASS_PREFIX + name);
const functionTypeValidTokenClassName = CODE_MIRROR_CLASS_PREFIX + FUNCTION_TOKEN_NAME;

export const CODEMIRROR_DATA_TYPE_TOOLTIP_CLASSNAME = 'CodeMirror-data-type-tooltip';
const CODEMIRROR_DATA_TYPE_MESSAGE_NAME = 'Codemirror-data-type-message';
const CODEMIRROR_DATA_TYPE_TOOLTIP_DELAY = 0;
const FORMULA_CONTENT_ENVELOPE = new ContentEnvelope({ pre: 'select *, ', type: ContentEnvelopeTypes.formula });

const toggleCommentHandler = (cmr) => {
  cmr.toggleComment({ indent: true, commentBlankLines: true });
};
// When a code mirror textbox is clicked it gets unmounted and remounted, this causes the cursor position to be lost
// The following variable tracks cursor position and reapplies it on click
let CURRENT_CURSOR_POSITION = null;

export class TransformInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    const { contents, guid, onChange, contentEnvelope, cursorBlinkRate } = props;
    const editable = !!onChange;
    this.contents = contents;
    this.syntaxHighlightingTokens = [];

    // we will intercept codemirror's onChange event to:
    //   a) update our syntaxHighlightingTokens.
    //      this runs before any tokenization in the codemirror mode
    //   b) call a debounced version of the onChange prop.
    //      since the prop updates flux, it's somewhat heavy.
    //      debouncing leads to smoother typing perf.
    const debouncedOnChange = _.debounce(updatedContents => this.props.onChange(updatedContents), 100);

    this.onChange = newContents => {
      this.updateContents(newContents);
      this.updateSyntaxHighlightingTokens(newContents);
      debouncedOnChange(newContents);
    };

    // define the CodeMirror "mode" for our syntax highlighting
    cm.defineMode(guid, () => {
      return {
        // startState() runs on mount, or any time the FIRST LINE is changed
        // this does not run if subsequent lines are changed
        startState: () => {
          return { globalPos: 0 };
        },

        lineComment: '//',
        blockCommentStart: '/*',
        blockCommentEnd: '*/',

        // copyState: (state) => copy of state;
        // copystate is another function that can be overridden here.
        // it runs at the start of every LINE, and by default CodeMirror will make a shallow copy
        // of the state object coming out of the previous line.
        // when any non-first line is affected, CodeMirror will start the state object that
        // gets passed to token() as what it was when copyState was last run for the line in question

        // token() runs in the context of a LINE, not the entire document, and will execute until stream.eol() (end of line) returns true
        // the usual way for token() to understand the entire document is via the state object
        // we don't really use this stream-based approach that CodeMirror provides.
        // we will take the entire document and parse it into syntax highlighting tokens
        // here we just need to make sure we look up the proper tokens
        token: (stream, state) => {
          let returnToken;
          // find the element that we currently are currently reading and return what token it is
          const item = _.find(this.syntaxHighlightingTokens, ({ start, end }) => {
            return start <= state.globalPos && state.globalPos <= end;
          });
          if (item) {
            returnToken = item.token;
          }
          do {
            stream.next();
            state.globalPos += 1 + stream.eol();
          } while (item && state.globalPos < item.end + 1 && !stream.eol());
          return returnToken;
        },
        // add to the globalPos to account for the newline character in blank lines
        blankLine: state => {
          state.globalPos += 1;
        },
      };
    });
    const extraKeys = {
      Tab: this.autocomplete,
    };
    if (!contentEnvelope) {
      if (browserIsOnMac()) {
        extraKeys['Cmd-/'] = toggleCommentHandler;
      } else {
        extraKeys['Ctrl-/'] = toggleCommentHandler;
      }
    }

    this.cmOptions = {
      autoCloseBrackets: false,
      matchBrackets: true,
      extraKeys,
      hintOptions: { completeSingle: false },
      gutters: ['CodeMirror-lint-markers'],
      lineNumbers: true,
      lint: {
        lintOnChange: false,
      },
      mode: guid,
      scrollbarStyle: 'null',
      // props based on whether the TransformInput is selected or not (onChange is determined by 'selected')
      editable,
      cursorBlinkRate: cursorBlinkRate || 530,
      autofocus: false,
      lineWrapping: !editable,
      readOnly: !editable,
    };
  }

  componentDidMount() {
    const { guid } = this.props;
    const codeMirrorInstance = this.refs.editor.getCodeMirrorInstance();
    const codeMirrorInstancePosition = function (line, column) {
      return codeMirrorInstance.Pos(line, column);
    };

    // initialize syntaxHighlightingTokens on mount
    this.updateSyntaxHighlightingTokens(this.contents);

    // define logic for getting completion suggestions
    codeMirrorInstance.registerHelper('hint', guid, editor => {
      // this will parse out what is a word, so can find current word to match
      const cursor = editor.getCursor();
      const curLine = editor.getLine(cursor.line);
      const context = this.retrieveContext(cursor.ch, cursor.line);
      // the showHint() method that receives this must take in the form { list, from, to }
      const completionsObject = getSuggestions(cursor, curLine, this.getSuggestionObjects(), codeMirrorInstancePosition, context);
      // put double-quotes around suggestions that need double-quotes to syntax highlight on autocompletion
      cm.on(completionsObject, 'select', completion => {
        if (!completion.modified) {
          completion.text = completion.text.replace(/"/g, '""');
          completion.modified = true;
          if (completion.className === UNIFIED_ATTRIBUTE_TOKEN_NAME && !completion.text.match(/(?:^[a-zA-Z]\w*$)/)) {
            completion.text = '"' + completion.text + '"';
          }
        }
      });

      // if picking a function, add parenthesis and set the cursor between them
      cm.on(completionsObject, 'pick', completion => {
        // IF SELECTION MATCHES AFTER THE CURSOR, OVERWRITE IT INSTEAD OF APPENDING:
        {
          // Initialize variables
          let to = completionsObject.to.ch;
          let stillMatching = true;

          // Continue to increase the 'to' value to match as much of the current selection to the text as we can
          for (let i = 0; i < completion.text.length && stillMatching; i++) {
            if (curLine[to]?.toLowerCase() === completion.text[completionsObject.to.ch - completionsObject.from.ch + i]?.toLowerCase()) {
              to++;
            } else {
              stillMatching = false;
            }
          }

          // Modify completed selection (unfortunately we have to do this after it already
          // is pasted in by CodeMirror because there is no intermediate event after choosing
          // but before pasting
          editor.setSelection(
            { line: editor.getCursor().line, ch: completionsObject.from.ch },
            {
              line: editor.getCursor().line,
              ch: completionsObject.from.ch + completion.text.length + (to - completionsObject.to.ch),
            },
          );
          editor.replaceSelection(completion.text);
        }

        // find the next character after the cursor
        editor.setSelection(
          { line: editor.getCursor().line, ch: editor.getCursor().ch },
          { line: editor.getCursor().line, ch: editor.getCursor().ch + 1 },
        );
        const nextCharacter = editor.getSelection();

        switch (completion.className) {
          case FUNCTION_TOKEN_NAME:
            // add parens accordingly
            switch (nextCharacter) {
              case ' ':
                editor.replaceSelection('()' + nextCharacter);
                editor.setCursor({ line: editor.getCursor().line, ch: editor.getCursor().ch - 2 });
                break;
              case '':
                editor.replaceSelection('()' + nextCharacter);
                editor.setCursor({ line: editor.getCursor().line, ch: editor.getCursor().ch - 1 });
                break;
              case '(':
                editor.replaceSelection(nextCharacter);
                break;
              default:
                editor.replaceSelection('(' + nextCharacter);
                editor.setCursor({ line: editor.getCursor().line, ch: editor.getCursor().ch - 1 });
                break;
            }
            break;
          default:
            // if there is a non-space character after our autocompletion, add a space between
            // the end of our autocompletion and it, else do nothing
            if (nextCharacter) {
              if (/[a-zA-Z0-9_]/.test(nextCharacter)) {
                editor.replaceSelection(' ' + nextCharacter);
              } else {
                editor.replaceSelection(nextCharacter);
              }
              editor.setCursor({ line: editor.getCursor().line, ch: editor.getCursor().ch - 1 });
            }
            break;
        }
        // this updates the editor so that if our autocompletion is off the side of the editor it will shift the view over
        // literally replaces our selection (which is nothing) with nothing, basically a no-op
        editor.replaceSelection('');
      });
      return completionsObject;
    });

    // define logic for getting lints
    codeMirrorInstance.registerHelper('lint', guid, () => {
      const { lintingErrors, hasNotBeenTypedIntoYet, contents, contentEnvelope } = this.props;
      return getErrors(lintingErrors, codeMirrorInstancePosition, contents, hasNotBeenTypedIntoYet, contentEnvelope);
    });

    this.codemirrorDidMount();

    // trigger syntax highlighting
    this.manuallyTriggerSyntaxHighlighting();
  }

  componentDidUpdate({ contents, functionNames, unifiedAttributes }) {
    const codeMirror = this.refs.editor.getCodeMirror();
    codeMirror.performLint();
    // in case of a rebase while code editor is open, force updating of CodeMirror with changed expression
    // rebase causing update of selected transform without conflict only happens if user has not typed anything into selected transform
    const currentContents = codeMirror.getValue();
    const previousContentProp = contents;
    const currentContentProp = this.props.contents;
    if (currentContents !== currentContentProp && previousContentProp !== currentContentProp) {
      this.updateContents(currentContentProp);
      this.updateSyntaxHighlightingTokens(currentContentProp);
      codeMirror.setValue(currentContentProp);
    } else if (!is(functionNames, this.props.functionNames) || !is(unifiedAttributes, this.props.unifiedAttributes)) {
      this.manuallyTriggerSyntaxHighlighting();
    }
  }

  onKeyDown = (e) => {
    if (['Control', 'Meta'].includes(e.key)) {
      this.setState({ mod: true });
    }
  };

  onKeyUp = (e) => {
    if (['Control', 'Meta'].includes(e.key)) {
      this.setState({ mod: false });
    }
  };

  // className is used to color the suggestions using CSS, potentialSuggestions is an array of string candidates
  // CSS formatting is found in the TransformCodeMirror.scss file under .CodeMirror-hint and .CodeMirror-hint-active
  getSuggestionObjects = () => {
    const { hintNames, functionNames, unifiedAttributes } = this.props;
    return [
      { className: UNIFIED_ATTRIBUTE_TOKEN_NAME, potentialSuggestions: unifiedAttributes.toArray() },
      { className: FUNCTION_TOKEN_NAME, potentialSuggestions: functionNames },
      { className: KEYWORD_TOKEN_NAME, potentialSuggestions: TransformsKeywords },
      { className: HINT_TOKEN_NAME, potentialSuggestions: hintNames },
    ];
  };

  updateContents = newContents => {
    this.contents = newContents;
  };

  updateSyntaxHighlightingTokens = (contents) => {
    const { unifiedAttributes, functionNames, contentEnvelope } = this.props;
    this.syntaxHighlightingTokens = getSyntaxHighlightingTokens(contents, unifiedAttributes.toArray(), functionNames, TransformsKeywords, contentEnvelope);
  };

  retrieveContext = (cursorPos, cursorLine) => {
    const { contentEnvelope } = this.props;
    return getContext(this.contents, cursorPos, cursorLine, contentEnvelope);
  };

  // display the data type of an attribute when the mouse cursor is hovering over it
  showDataTypesInline = () => {
    // get the CodeMirror editor
    const editor = this.refs.editor.getCodeMirror();
    // here we define what happens when we reach the timeout
    const determineCreateDataTypeAndFunctionHoverTooltip = e => {
      const { operationIndex, staticAnalysisResult, onChange, isUnifiedTransform, functionTooltips } = this.props;

      // only show data types if our transform is active and we are a transformation on the Unified Dataset
      // and our mouse event exists and is well-formed
      if (!onChange || !e || !e.target || !e.target.className) {
        return;
      }

      // if the mouseover event is coming from an attribute span...
      const hoveredTokenNeedsTooltip = !dataTypeValidTokenClassNames.intersect(Set(e.target.className.split(' '))).isEmpty();

      if (hoveredTokenNeedsTooltip) {
        // Looking for function
        if (e.target.className.search(functionTypeValidTokenClassName) !== -1) {
          const functionName = e.target.innerText;
          if (functionTooltips.has(functionName)) {
            this.createTooltip(functionTooltips.get(functionName));
          }
          return;
        }
        // Looking for Attributes in Unified Dataset
        // parse className for line and position number of token
        if (!isUnifiedTransform) {
          return;
        }
        const cursorLineRegexCapture = /line=([0-9]*)/.exec(e.target.className);
        let cursorLine;
        if (cursorLineRegexCapture) {
          cursorLine = +cursorLineRegexCapture[1] - 1;
        }
        const cursorPositionRegexCapture = /pos=([0-9]*)/.exec(e.target.className);
        let cursorPosition;
        if (cursorPositionRegexCapture) {
          cursorPosition = +cursorPositionRegexCapture[1];
        }

        // exit if we can't find the line or position of the cursor based on the token className
        if (!_.isNumber(cursorLine) || !_.isNumber(cursorPosition)) {
          return;
        }

        // find the token we are hovering over
        const syntaxHighlightingToken = editor.getTokenAt({ ch: cursorPosition, line: cursorLine });

        // exit if we have any undefined properties in our syntaxHighlightingToken
        if (!syntaxHighlightingToken || !syntaxHighlightingToken.type || !syntaxHighlightingToken.string) {
          return;
        }

        const hasBeenReassigned = !Set([NAMED_UNIFIED_ATTRIBUTE_TOKEN_NAME, TEMP_NAMED_UNIFIED_ATTRIBUTE_TOKEN_NAME])
          .intersect(Set(syntaxHighlightingToken.type.split(' ')))
          .isEmpty();

        if (staticAnalysisResult) {
          // gets the dataType string given the analysis result, the operation index, and the statement index
          const dataType = staticAnalysisResult.getFieldType(operationIndex, cursorLine, cursorPosition, syntaxHighlightingToken.string, hasBeenReassigned);
          // create DOM element
          if (dataType) {
            this.createTooltip(dataType.toString());
          }
        }
      }
    };
    // set a delay for our callback to the mouseover function
    let timeout = null;

    cm.on(editor.getWrapperElement(), 'mouseover', e => {
      timeout = setTimeout(() => determineCreateDataTypeAndFunctionHoverTooltip(e), CODEMIRROR_DATA_TYPE_TOOLTIP_DELAY);
    });

    cm.on(editor.getWrapperElement(), 'mouseout', () => {
      clearTimeout(timeout);
      $(`div.${CODEMIRROR_DATA_TYPE_TOOLTIP_CLASSNAME}`).remove();
    });

    cm.on(editor.getWrapperElement(), 'click', e => {
      CURRENT_CURSOR_POSITION = editor.getCursor();
      if ((e.ctrlKey || e.metaKey) && e.target.className.includes('cm-function')) {
        const baseUrl = process.env.NODE_ENV === 'development' ? window.location.protocol + '//' + window.location.hostname + ':5307' : window.location.origin;
        const page = window.open(`${baseUrl}/function-docs/#${e.target.textContent}`);
        page.blur();
        setTimeout(page.focus, 0);
        this.refs.editor
          .getCodeMirror()
          .getDoc()
          .undoSelection();
      }
    });
  };

  createTooltip = (ttContent) => {
    // remove all previous tooltips
    $(`div.${CODEMIRROR_DATA_TYPE_TOOLTIP_CLASSNAME}`).remove();
    // create new tooltip
    const ttmessage = document.createElement('div');
    ttmessage.appendChild(document.createTextNode(ttContent));
    ttmessage.className = CODEMIRROR_DATA_TYPE_MESSAGE_NAME;
    const tt = document.createElement('div');
    tt.className = CODEMIRROR_DATA_TYPE_TOOLTIP_CLASSNAME;
    tt.appendChild(ttmessage);
    document.body.appendChild(tt);
    // set timeout that modifies opacity so that it fades in,
    // since the transitions need to go from one opacity to another
    const modifyOpacity = () => {
      tt.style.opacity = '1';
    };
    setTimeout(modifyOpacity, 25);
  };

  manuallyTriggerSyntaxHighlighting = () => {
    const codeMirror = this.refs.editor.getCodeMirror();
    codeMirror.setValue(codeMirror.getValue()); // noop for contents, but refresh the syntax highlighting
  };

  autocomplete = c => {
    const codeMirrorInstance = this.refs.editor.getCodeMirrorInstance();
    codeMirrorInstance.showHint(c, codeMirrorInstance.hint.tamr);
  };

  // defers function calls until after codeMirror is guaranteed to have mounted
  codemirrorDidMount = () => {
    setTimeout(() => {
      if (this.refs.editor) {
        const cmr = this.refs.editor.getCodeMirror();
        if (!!cmr.getOption('editable') && CURRENT_CURSOR_POSITION) {
          cmr.setCursor(CURRENT_CURSOR_POSITION);
          cmr.scrollIntoView(CURRENT_CURSOR_POSITION);
        }
        cmr.performLint();
        this.showDataTypesInline();
      }
    }, 0);
  };

  render() {
    const { contents, contentEnvelope, onChange, lintingErrors, hasNotBeenTypedIntoYet } = this.props;
    const { mod } = this.state;
    return (
      <div style={{ overflowX: 'hidden', flex: 1 }} className={classNames({ mod })} onKeyDown={this.onKeyDown} onKeyUp={this.onKeyUp}>
        <CodeMirror
          ref="editor"
          autofocus={!!onChange}
          className={classNames({ readOnly: !onChange, invalid: lintingErrors.size > 0 || (!hasNotBeenTypedIntoYet && contents === '') })}
          onChange={this.onChange}
          options={this.cmOptions}
          placeholder={contentEnvelope ? 'Expression' : 'Transformation'}
          value={contents}
        />
      </div>
    );
  }
}

export const mapStateToProps = (state, { guid }) => {
  const {
    transforms: transformsState,
    transforms: { hintNames, hasNotBeenTypedIntoYet, staticAnalysisResult },
  } = state;
  const bulkTransform = getLatestForBulkTransform(transformsState, guid);
  const isFormula = _.isString(bulkTransform.operation.expr);
  const contents = isFormula ? bulkTransform.operation.expr : bulkTransform.operation.op;
  const unifiedAttributes = selectStagedUnifiedDatasetColumnNames(state);
  // for showing data types on hover
  const unifiedGuidIndexes = getUnifiedScopedGuids(transformsState)
    .toMap()
    .flip();
  const isUnifiedTransform = unifiedGuidIndexes.has(guid);
  const operationIndex = unifiedGuidIndexes.get(guid);
  const functionNames = selectFunctionNames(transformsState);
  const functionTooltips = selectFunctionTooltipMap(transformsState);
  return {
    guid,
    lintingErrors: selectLints(transformsState).get(guid) || EMPTY_LINT_ERRORS,
    staticAnalysisResult,
    isUnifiedTransform,
    operationIndex,
    hintNames,
    functionNames,
    functionTooltips,
    unifiedAttributes,
    hasNotBeenTypedIntoYet: hasNotBeenTypedIntoYet === guid,
    ...isFormula && { contentEnvelope: FORMULA_CONTENT_ENVELOPE },
    contents,
  };
};

const ConnectedTransformInput = _.compose(
  connect(mapStateToProps),
)(TransformInput);
ConnectedTransformInput.propTypes = {
  guid: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default ConnectedTransformInput;
