import classNames from 'classnames';
import { TableRowEventHandler } from 'fixed-data-table-2';
import { List, Set } from 'immutable';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import _, { isNumber } from 'underscore';

import CheckboxColumn from '../components/CheckboxColumn';
import ColumnOrderSelector from '../components/ColumnOrderSelector';
import ErrorBoundary from '../components/ErrorBoundary';
import Link from '../components/Link';
import LoadingPanel from '../components/LoadingPanel';
import SortableHeaderCell from '../components/SortableHeaderCell';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Pager from '../components/Table/PagerModel';
import Table from '../components/Table/Table';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import TextHeaderCell from '../components/TextHeaderCell';
import TooltipTrigger from '../components/TooltipTrigger';
import withKeyState, { InjectedKeyStateProps } from '../components/WithKeyState';
import * as DataTables from '../constants/DataTables';
// @ts-expect-error
import { getAvailableFields } from '../dnfBuilder/DnfBuilderStore';
import DisplayColumn from '../models/DisplayColumn';
import KeyMods from '../models/KeyMods';
import { DEDUP_INFO_METADATA_KEY } from '../models/Recipe';
import RecordPairWithData from '../models/RecordPairWithData';
import { AppAction } from '../stores/AppAction';
import { AppState } from '../stores/MainStore';
import { isCuratorByProjectId } from '../utils/Authorization';
import ElasticUtils from '../utils/ElasticUtils';
import PRODUCT_NAME from '../utils/ProductName';
import { getAuthorizedUser, selectActiveProjectInfo } from '../utils/Selectors';
import { $TSFixMe } from '../utils/typescript';
import { getPath, isDefined } from '../utils/Values';
// @ts-expect-error
import ConfusionMatrixProgress from './ConfusionMatrixProgress';
import { MlEnabledIcon } from './MlEnabledIcon';
import RecordPairDualValueCell from './RecordPairDualValueCell';
import { TOGGLE_USER_DEFINED_SIGNAL_SORT } from './RecordPairsActionTypes';
import { columnKeyStartsWithUDSPrefix, getPairsTableDisplayColumns, setPairsTableColumnWidth, udsColumnKeyToExternalId } from './RecordPairsColumns';
import RecordPairsPagerContent from './RecordPairsPagerContent';
import RecordPairSpecialIconsCell from './RecordPairSpecialIconsCell';
import RecordPairTamrResponseCell from './RecordPairTamrResponseCell';
import RecordPairYourResponseCell from './RecordPairYourResponseCell';
import UserDefinedSignal from './UserDefinedSignal';
import { UserDefinedSignalIcon } from './UserDefinedSignalIcon';

function displayColumnToUDS(
  column: DisplayColumn,
  userDefinedSignals: List<UserDefinedSignal> | undefined,
): UserDefinedSignal | undefined {
  return userDefinedSignals?.find(u => u.externalId === udsColumnKeyToExternalId(column.name));
}

const ROW_HEIGHT = 70;
// prefix applied to the column name in the table
const TABLE_COLUMN_PREFIX = 'attributeColumn__';
const COLUMN_MIN_WIDTH = 120; // content is 100, plus 10 on each side

const DEFAULT_COLUMN_PROPS = {
  width: COLUMN_MIN_WIDTH,
  minWidth: COLUMN_MIN_WIDTH,
  isResizable: true,
} as const;

const mapStateToProps = (state: AppState) => {
  const {
    config: { elasticConfig, pairConfidenceThresholds },
    recordPairs: {
      activeRowNumber, columnSortStates, currentlyProcessing, showFilterPanel, initialFetch, isLoading,
      noPairsInProject, pageNum, pageSize, pairs, pendingResolutionRowNumbers, selectedRowNumbers, total,
      userDefinedSignals, userDefinedSignalSorts,
    },
    location: { recipeId },
  } = state;
  const projectInfo = selectActiveProjectInfo(state);
  const fieldsAreAvailable = !getAvailableFields(state).isEmpty();
  const isUserACurator = isCuratorByProjectId(getAuthorizedUser(state), selectActiveProjectInfo(state)?.projectDoc.id.id);
  return {
    elasticConfig,
    confidenceThresholds: pairConfidenceThresholds,
    activeRowNumber,
    columnSortStates,
    currentlyProcessing,
    filterEnabled: showFilterPanel,
    initialFetch,
    isLoading,
    noPairsInProject,
    pageNum,
    pageSize,
    pairs,
    pendingResolutionRowNumbers,
    selectedRowNumbers,
    total,
    recipeId,
    projectInfo,
    columnSettings: getPairsTableDisplayColumns(state),
    dedupInfo: getPath(projectInfo, 'recipeDoc', 'data', 'metadata', DEDUP_INFO_METADATA_KEY),
    fieldsAreAvailable,
    isUserACurator,
    userDefinedSignals,
    userDefinedSignalSorts,
  };
};

const mapDispatchToProps = {
  toggleAttributeSort: (columnName: string): AppAction => ({ type: 'RecordPairs.toggleAttributeSort', columnName }),
  setActivePair: (activeRowNumber: number): AppAction => ({ type: 'RecordPairs.setActivePair', activeRowNumber }),
  setPageSize: (pageSize: number): AppAction => ({ type: 'RecordPairs.setPageSize', pageSize }),
  setPage: (pageNum: number): AppAction => ({ type: 'RecordPairs.setPageNum', pageNum }),
  onSelectPairRow: (rowNum: number, keyMods: KeyMods): AppAction => ({ type: 'RecordPairs.selectPairRow', rowNum, keyMods }),
  onSelectAllPairRows: (): AppAction => ({ type: 'RecordPairs.selectAllPairRows' }),
  onSetColumnWidth: setPairsTableColumnWidth,
  toggleUserDefinedSignalSort: (udsExternalId: string): AppAction => ({ type: TOGGLE_USER_DEFINED_SIGNAL_SORT, udsExternalId }),
};

type RecordPairsTableProps
  = ReturnType<typeof mapStateToProps>
  & typeof mapDispatchToProps
  & InjectedKeyStateProps

class RecordPairsTable extends React.Component<RecordPairsTableProps> {
  componentDidUpdate() {
    const focused = $(':focus');
    // @ts-expect-error
    if (focused.length && focused.get()[0].readOnly !== false) {
      const table = ReactDOM.findDOMNode(this.refs.table);
      if (table) {
        // steal focus unless focused element is editable
        // @ts-expect-error
        table.focus();
      }
    }
  }

  projectInfoIsAvailable = () => {
    return this.props.projectInfo !== undefined;
  };

  getDataSize = () => {
    return this.props.pairs.size;
  };

  renderSpecialIconsColumn = () => {
    const cell = ({ rowIndex }: { rowIndex: number }) => {
      return <RecordPairSpecialIconsCell {...{ rowIndex }} />;
    };
    return (
      <Column
        columnKey="specialIcons"
        width={30}
        key="specialIcons"
        header={<div />}
        cell={cell}
        fixed
      />
    );
  };

  renderYourResponseColumn = (pager: Pager<List<RecordPairWithData>>) => {
    const cell = ({ rowIndex }: { rowIndex: number }) => {
      const pair = pager.data.get(rowIndex);
      return pair ? (
        <RecordPairYourResponseCell {...{ rowIndex, pair }} />
      ) : <span />;
    };
    return (
      <Column
        columnKey="yourResponse"
        width={106}
        key="yourResponse"
        header={<TextHeaderCell>Your Response</TextHeaderCell>}
        cell={cell}
        fixed
      />
    );
  };

  renderTamrResponseColumn = (pager: Pager<List<RecordPairWithData>>) => {
    const cell = ({ rowIndex }: { rowIndex: number }) => {
      const pair = pager.data.get(rowIndex);
      return pair ? (
        <RecordPairTamrResponseCell pair={pair} />
      ) : <span />;
    };
    return (
      <Column
        columnKey="tamrResponse"
        width={81}
        key="tamrResponse"
        header={<TextHeaderCell>{PRODUCT_NAME}</TextHeaderCell>}
        cell={cell}
        fixed
      />
    );
  };

  isSortableColumn = (colName: string) => {
    const { dedupInfo } = this.props;
    if (!dedupInfo) {
      return false;
    }
    return _.some(_.flatten(dedupInfo.dnf.clauses), clauseEntry =>
      clauseEntry.fieldName === colName) || dedupInfo.signalTypes[colName];
  };

  renderAttributeColumn = (rows: List<RecordPairWithData>, col: DisplayColumn, isLastColumn: boolean) => {
    const { dedupInfo } = this.props;
    const cell = ({ rowIndex }: { rowIndex: number }) => {
      const pair = rows.get(rowIndex);
      if (!pair) return <Cell />;
      const prefixedAttributeName = ElasticUtils.sanitizeField(col.name);
      const txn1IsRaw = pair.txn1HighlightFields?.has(prefixedAttributeName);
      const txn2IsRaw = pair.txn2HighlightFields?.has(prefixedAttributeName);
      const txn1Data = txn1IsRaw ? pair.txn1HighlightFields : pair?.txn1Data;
      const txn1OriginalData = pair.txn1Data?.get(prefixedAttributeName);
      const txn2Data = txn2IsRaw ? pair.txn2HighlightFields : pair.txn2Data;
      const txn2OriginalData = pair.txn2Data?.get(prefixedAttributeName);
      let txn1Attribute = txn1Data ? txn1Data.get(prefixedAttributeName) : undefined;
      let txn2Attribute = txn2Data ? txn2Data.get(prefixedAttributeName) : undefined;
      if (List.isList(txn1Attribute)) {
        txn1Attribute = txn1Attribute.toArray();
      }
      if (List.isList(txn2Attribute)) {
        txn2Attribute = txn2Attribute.toArray();
      }

      const signalType = dedupInfo.signalTypes[col.name];
      return (
        <RecordPairDualValueCell
          attributeName={col.name}
          value1={txn1Attribute}
          value2={txn2Attribute}
          value1IsRaw={txn1IsRaw}
          value2IsRaw={txn2IsRaw}
          value1OriginalData={txn1OriginalData}
          value2OriginalData={txn2OriginalData}
          similarityScore={pair.attributeSimilarityScores?.get(
            ElasticUtils.removeColumnPrefix(ElasticUtils.sanitizeField(col.name)),
          )}
          signalType={signalType}
        />
      );
    };
    const key = TABLE_COLUMN_PREFIX + col.name;

    let header;

    const includedFields = Set(dedupInfo.includedFields);

    if (!this.isSortableColumn(col.name)) {
      header =
        <TextHeaderCell>
          <TooltipTrigger placement="top" content={`This attribute was created by ${PRODUCT_NAME}`}>
            <TamrIcon size={14} iconName="tamr-icon-logo" className="tamrIconLogo" />
          </TooltipTrigger>
          {col.displayName}
        </TextHeaderCell>;
    } else {
      header = (
        <SortableHeaderCell
          col={col.displayName}
          display={(
            <span className="attribute-column-header">
              {includedFields.includes(col.name) && <MlEnabledIcon />}
              {col.displayName}
            </span>
          )}
          sortCallback={this.props.toggleAttributeSort}
          sortState={this.props.columnSortStates.get(
            ElasticUtils.removeColumnPrefix(ElasticUtils.sanitizeField(col.name)),
          )}
        />
      );
    }

    return (
      <Column
        columnKey={key}
        key={key}
        header={header}
        cell={cell}
        {...DEFAULT_COLUMN_PROPS}
        width={col.width}
        flexGrow={isLastColumn ? 1 : 0}
      />
    );
  };

  renderUserDefinedSignalsColumn = (rows: List<RecordPairWithData>, col: DisplayColumn, isLastColumn: boolean): JSX.Element | undefined => {
    const { userDefinedSignals } = this.props;
    const columnKey = col.name;
    const udsExternalId = udsColumnKeyToExternalId(columnKey);
    const uds = userDefinedSignals?.find(u => u.externalId === udsExternalId);
    if (!uds) {
      return undefined;
    }
    return (
      <Column
        columnKey={columnKey}
        key={columnKey}
        header={(
          <SortableHeaderCell
            col={columnKey}
            titleHover={uds.externalId /* column key is prefixed, so want to override using that as title hover */}
            display={(
              <span className="attribute-column-header">
                <UserDefinedSignalIcon />
                {uds.displayName}
              </span>
            )}
            sortCallback={() => this.props.toggleUserDefinedSignalSort(uds.externalId)}
            sortState={this.props.userDefinedSignalSorts.get(uds.externalId)}
          />
        )}
        cell={({ rowIndex }: { rowIndex: number }) => {
          const signal = rows.get(rowIndex)?.userDefinedSignals?.get(uds.externalId);
          if (!isNumber(signal)) return <Cell />;
          return <Cell>{signal}</Cell>;
        }}
        {...DEFAULT_COLUMN_PROPS}
        width={col.width}
        flexGrow={isLastColumn ? 1 : 0}
      />
    );
  };

  // "data column" here means either a unified attribute or a user defined signal column
  renderDataColumns = (pager: Pager<List<RecordPairWithData>>): JSX.Element[] => {
    const { columnSettings } = this.props;
    const rows = pager.data;
    const visibleColumns = columnSettings.filter(col => col.visible);
    return visibleColumns.map((col, i) => {
      const isLastColumn = i === visibleColumns.size - 1;
      if (columnKeyStartsWithUDSPrefix(col.name)) {
        // this column represents a user defined signal
        return this.renderUserDefinedSignalsColumn(rows, col, isLastColumn);
      }
      // this column represents a unified dataset column
      return this.renderAttributeColumn(rows, col, isLastColumn);
    }).filter(isDefined)
      .toArray();
  };

  renderColumns = (pager: Pager<List<RecordPairWithData>>) => {
    let columns = [];
    columns.push(this.renderSpecialIconsColumn());
    columns.push(this.renderYourResponseColumn(pager));
    if (pager.data.find(pair => !!pair.suggestedLabel)) {
      columns.push(this.renderTamrResponseColumn(pager));
    }
    columns = columns.concat(this.renderDataColumns(pager));
    return columns;
  };

  cellChangeHandler = (e: ExtendedKeyboardEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>, rowNum: number) => {
    this.props.setActivePair(rowNum);
  };

  rowClassNameGetter = (index: number) => {
    const { pendingResolutionRowNumbers, selectedRowNumbers } = this.props;
    return classNames({
      'pending-resolution': pendingResolutionRowNumbers.includes(index),
      selected: selectedRowNumbers.has(index),
    });
  };

  renderTable = () => {
    const { columnSettings, projectInfo, noPairsInProject, pairs, pageNum, initialFetch,
      pageSize, total, elasticConfig, selectedRowNumbers, onSelectPairRow, onSelectAllPairRows,
      dedupInfo, userDefinedSignals } = this.props;

    if (projectInfo && !projectInfo.isUnifiedDatasetIndexed) {
      const smLink = <Link to={`/schema-mapping/recipe/${projectInfo.smRecipeId}`}>unified dataset</Link>;
      return (
        <div className="record-pairs-table-message">
          <div>You have not yet committed a {smLink}.</div>
        </div>
      );
    }
    if (noPairsInProject) {
      const dnfLink = <Link to={`/dnf-builder/recipe/${projectInfo?.recipeId}`}>set up how <Term>pairs</Term> are found</Link>;
      return (
        <div className="record-pairs-table-message">
          <div>There are no <Term>record</Term> <Term>pairs</Term> in this project.</div>
          <div>You may need to {dnfLink}.</div>
        </div>
      );
    }
    if (!initialFetch) { // the rest of the states need the initial fetch to be meaningful
      return null;
    }
    if (total === 0) {
      return (
        <span>
          <div className="record-pairs-table-message">
            <div>No <Term>record</Term> <Term>pairs</Term> matching your filters.</div>
          </div>
          <div className="record-pairs-table-empty-matrix">
            <RecordPairsPagerContent />
          </div>
        </span>
      );
    }

    if (!elasticConfig) {
      return null;
    }
    const pager = new Pager(
      pairs,
      // since es query looks at 2 * (offset + limit) pairs in worst case
      _.min([elasticConfig.maxResultWindow / 2, total]),
      pageNum,
      pageSize,
    );

    const includedFields = Set(dedupInfo.includedFields) as Set<$TSFixMe>;
    const visibleFields = Set(dedupInfo.visibleFields);
    const generatedAttributes = columnSettings
      // "visible" attributes were created by the user
      .filter(att => !visibleFields.contains(att.name))
      // user defined signals don't count as generated
      .filter(att => !displayColumnToUDS(att, userDefinedSignals))
      .map(att => (att.displayName))
      .toSet();
    const pagerContent = (
      <div>
        {total > 0 && columnSettings ?
          <ColumnOrderSelector
            columnSettings={columnSettings}
            pageType={DataTables.PAIRS}
            mlAttributes={includedFields}
            generatedAttributes={generatedAttributes}
            renderAdditionalItemContent={(item) => {
              if (displayColumnToUDS(item, userDefinedSignals)) {
                return <UserDefinedSignalIcon style={{ marginLeft: '5px' }} />;
              }
              // column does not represent a uds, so do not add additional item content
              return undefined;
            }}
          />
          : null}
      </div>
    );

    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            ref="table"
            activeRowNumber={this.props.activeRowNumber}
            height={height}
            headerHeight={42}
            width={width}
            getLength={this.getDataSize}
            pageSizes={List.of(25, 50, 100)}
            onActiveCellChange={this.cellChangeHandler}
            onPageSizeChange={this.props.setPageSize}
            onPageChange={this.props.setPage}
            pagerState={pager}
            tableType="stripes"
            rowHeight={ROW_HEIGHT}
            onToggleRow={index => onSelectPairRow(index, new KeyMods({}).enableToggle())}
            pagerContent={pagerContent}
            pagerContentLeft={<RecordPairsPagerContent />}
            pagerContentRight={<ConfusionMatrixProgress />}
            onColumnResizeEndCallback={this.columnResizeHandler}
            rowClassNameGetter={this.rowClassNameGetter}
            onRowClick={this.onRowClick}
          >
            {CheckboxColumn({
              selected: index => selectedRowNumbers.has(index),
              allSelected: selectedRowNumbers.size === pairs.size,
              onToggle: (index, selected, e) => onSelectPairRow(index, KeyMods.fromJSON(e).enableToggle()),
              onToggleAll: onSelectAllPairRows,
            })}
            {this.renderColumns(pager)}
          </Table>
        )}
      </AutoSizer>
    );
  };

  onRowClick: TableRowEventHandler = (e, rowNum) => {
    const { onSelectPairRow } = this.props;
    if (!window.getSelection()?.toString()) {
      onSelectPairRow(rowNum, KeyMods.fromJSON(e));
    }
  };

  columnResizeHandler = (width: number, col: string) => {
    const { onSetColumnWidth } = this.props;
    const columnKey = columnKeyStartsWithUDSPrefix(col)
      // col is prefixed to denote a uds coming from the Table Column. pass this directly to width handler.
      ? col
      // col is a unified dataset field. remove table column prefix before passing to width handler.
      : col.substring(TABLE_COLUMN_PREFIX.length, col.length);
    onSetColumnWidth(columnKey, width);
  };

  render() {
    const { projectInfo, noPairsInProject, isLoading, pendingResolutionRowNumbers, shiftKey, fieldsAreAvailable, isUserACurator } = this.props;

    let loadingPanel;
    const shouldShowLoadingPanel = !this.projectInfoIsAvailable() ||
      (isLoading && projectInfo?.isUnifiedDatasetIndexed && !noPairsInProject);
    if (shouldShowLoadingPanel) {
      loadingPanel = <LoadingPanel aboveCenter />;
    }

    const containerCssClasses = classNames('record-pairs-table-container', {
      'some-pending-resolution': !pendingResolutionRowNumbers.isEmpty(),
      unselectable: shiftKey,
    });

    let table;
    if (this.projectInfoIsAvailable()) {
      table = this.renderTable();
    }

    return (
      <div className={containerCssClasses}>
        {loadingPanel}
        <ErrorBoundary errorMessage={
          <div className="error-message">
            <div>Could not render <Term>pairs</Term></div>
            <div>You may need to regenerate pairs {
              (fieldsAreAvailable && isUserACurator)
                ? <span>from the <Link to={`/dnf-builder/recipe/${this.props.recipeId}`}><Term>pairs</Term> management page</Link></span>
                : <div />
            }</div>
          </div>
        }>
          {table}
        </ErrorBoundary>
      </div>
    );
  }
}

export default _.compose(
  withKeyState,
  connect(mapStateToProps, mapDispatchToProps),
)(RecordPairsTable);
