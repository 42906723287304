import { getLicense } from '../api/AuthClient';
import LicenseActions from '../constants/LicenseActions';
import { SHOW } from '../errorDialog/ErrorDialogActionTypes';

export const loadLicense = () => async (dispatch) => {
  await getLicense()
    .then((data) => {
      dispatch({ type: LicenseActions.LOAD_LICENSE_COMPLETED, data });
    })
    .catch(response => {
      dispatch({
        type: SHOW,
        detail: 'Error loading license information',
        response,
      });
    });
};
