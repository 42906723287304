import { List, Map, Record } from 'immutable';

import CategoryDashboardCategoryView from '../constants/CategoryDashboardCategoryView';
import CategoryDashboardTierSelector from '../constants/CategoryDashboardTierSelector';
import DashboardTimeSelector from '../constants/DashboardTimeSelector';
import SortState from '../constants/SortState';
import AssignmentSummary from '../models/AssignmentSummary';
import Model from '../models/Model';
import ModelPerformance from '../models/ModelPerformance';
import ProcurementTaxonomy from '../models/ProcurementTaxonomy';
import { ArgTypes, checkArg, checkReturn } from '../utils/ArgValidation';
import { routes } from '../utils/Routing';
import AverageConfidenceRecord from './categorization/AverageConfidenceRecord';
import FieldSortConfig from './categorization/FieldSortConfig';

const progressSortState = checkReturn(FieldSortConfig.argType, ({ currentSort, fieldToProgress }) => {
  checkArg({ currentSort }, FieldSortConfig.argType);
  checkArg({ fieldToProgress }, ArgTypes.string);
  return currentSort.nextConfig(fieldToProgress);
});

export const initialState = new (Model({
  numberOpen: { type: ArgTypes.nullable(ArgTypes.number) },
  numberToVerify: { type: ArgTypes.number, defaultValue: 0 },
  numberToVerifyAgree: { type: ArgTypes.number, defaultValue: 0 },
  numberToVerifyDisagree: { type: ArgTypes.number, defaultValue: 0 },
  numberVerified: { type: ArgTypes.number, defaultValue: 0 },
  taxonomy: { type: ArgTypes.nullable(ArgTypes.instanceOf(ProcurementTaxonomy)), defaultValue: null },
  selectedConfidenceTimeRange: { type: ArgTypes.valueIn(DashboardTimeSelector), defaultValue: DashboardTimeSelector.MONTH },
  selectedCategoryView: { type: ArgTypes.valueIn(CategoryDashboardCategoryView), defaultValue: CategoryDashboardCategoryView.TOTALS },
  selectedTier: { type: ArgTypes.oneOf(ArgTypes.number, ArgTypes.eq(CategoryDashboardTierSelector.LEAF_NODES_ONLY)), defaultValue: 1 }, // 1-based index of the selected tier
  categoryPage: { type: ArgTypes.number, defaultValue: 0 },
  categorySort: { type: FieldSortConfig.argType, defaultValue: new FieldSortConfig({ field: 'totalLabeledCount', order: SortState.SORTED_DESCENDING }) },
  categorySearch: { type: ArgTypes.string, defaultValue: '' },
  categoriesUsed: { type: ArgTypes.number, defaultValue: 0 },
  totalCategories: { type: ArgTypes.number, defaultValue: 0 },
  percentAgree: { type: ArgTypes.number, defaultValue: 0 },
  loading: { type: ArgTypes.bool, defaultValue: false },
  loadingAssignments: { type: ArgTypes.bool, defaultValue: false },
  loadingTaxonomy: { type: ArgTypes.bool, defaultValue: false },
  loadingAverageConfidences: { type: ArgTypes.bool, defaultValue: false },
  loadedFilterInfo: { type: ArgTypes.any },
  fetchSequence: { type: ArgTypes.number, defaultValue: 1 },
  initialFetch: { type: ArgTypes.bool, defaultValue: false },
  recipeId: { type: ArgTypes.nullable(ArgTypes.number) },
  assignmentSummary: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(AssignmentSummary)), defaultValue: List() },
  performanceOverTime: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(ModelPerformance)), defaultValue: List() },
  assignmentPage: { type: ArgTypes.number, defaultValue: 0 },
  assignmentSort: { type: FieldSortConfig.argType, defaultValue: new FieldSortConfig({ field: 'pending', order: SortState.SORTED_DESCENDING }) },
  averageConfidencesError: { type: ArgTypes.bool, defaultValue: false },
  averageConfidences: {
    type: ArgTypes.Immutable.map.of(
      AverageConfidenceRecord.argType,
      ArgTypes.Immutable.list.of(ArgTypes.string),
    ),
    defaultValue: Map(),
  },
  assignmentSearch: { type: ArgTypes.string, defaultValue: '' },
}))();

const FilterInfo = Record({ fetchSequence: 1 });

export const getFilterInfo = state => {
  const { categorizationDashboard: { fetchSequence } } = state;
  return new FilterInfo({ fetchSequence });
};

const reload = (state) => state.update('fetchSequence', x => x + 1);
const resetFilters = (state) => state
  .delete('assignmentPage')
  .delete('assignmentSearch')
  .delete('assignmentSort')
  .delete('categoryPage')
  .delete('categorySort')
  .delete('categorySearch')
  .delete('selectedCategoryView')
  .delete('selectedConfidenceTimeRange')
  .delete('selectedTier')
  .delete('taxonomy');

export const reducers = {
  'Location.change': (state, { location }) => {
    if (routes.dashboard.match(location.pathname)) {
      return reload(state);
    }
    return state;
  },
  'Location.projectChange': (state) => {
    return resetFilters(reload(state));
  },
  'CategorizationDashboard.fetchStats': (state) => {
    return state.merge({ loading: true, loadingAssignments: true, loadingTaxonomy: true, loadingAverageConfidences: true, averageConfidencesError: false });
  },
  'CategorizationDashboard.fetchStatsFailed': (state) => {
    return state.merge({ loading: true, loadingAssignments: true, loadingTaxonomy: true });
  },
  'CategorizationDashboard.fetchStatsCompleted': (state, { recipeId, loadedFilterInfo, numberOpen, numberToVerify, numberToVerifyAgree, numberToVerifyDisagree, numberVerified, percentAgree, performanceOverTime }) => {
    return state.merge({ recipeId, loadedFilterInfo, numberOpen, numberToVerify, numberToVerifyAgree, numberToVerifyDisagree, numberVerified, percentAgree, performanceOverTime, loading: false, initialFetch: true });
  },
  'CategorizationDashboard.fetchTaxonomyStatsCompleted': (state, { recipeId, loadedFilterInfo, taxonomy, categoriesUsed, totalCategories }) => {
    return state.merge({ recipeId, loadedFilterInfo, taxonomy, categoriesUsed, totalCategories, loadingTaxonomy: false, initialFetch: true });
  },
  'CategorizationDashboard.fetchAssignmentStatsCompleted': (state, { recipeId, loadedFilterInfo, assignmentSummary }) => {
    return state.merge({ recipeId, loadedFilterInfo, assignmentSummary, loadingAssignments: false, initialFetch: true });
  },
  'CategorizationDashboard.fetchAverageConfidencesCompleted': (state, { averageConfidences }) => {
    return state.merge({ averageConfidences, loadingAverageConfidences: false });
  },
  'CategorizationDashboard.fetchAverageConfidencesFailed': state => {
    return state.merge({ loadingAverageConfidences: false, averageConfidencesError: true });
  },
  'CategorizationDashboard.setAssignmentPage': (state, { assignmentPage }) => {
    return state.merge({ assignmentPage });
  },
  'CategorizationDashboard.setAssignmentSearch': (state, { assignmentSearch }) => {
    return state.merge({ assignmentSearch }).delete('assignmentPage');
  },
  'CategorizationDashboard.progressAssignmentSort': (state, { field }) => {
    return state.merge({ assignmentSort: progressSortState({ currentSort: state.assignmentSort, fieldToProgress: field }) }).delete('assignmentPage');
  },
  'CategorizationDashboard.setSelectedCategoryView': (state, { selectedCategoryView }) => {
    return state.merge({ selectedCategoryView });
  },
  'CategorizationDashboard.setSelectedConfidenceTimeRange': (state, { selectedConfidenceTimeRange }) => {
    return state.merge({ selectedConfidenceTimeRange });
  },
  'CategorizationDashboard.setSelectedTier': (state, { selectedTier }) => {
    return state.merge({ selectedTier });
  },
  'CategorizationDashboard.setCategoryPage': (state, { categoryPage }) => {
    return state.merge({ categoryPage });
  },
  'CategorizationDashboard.setCategorySearch': (state, { categorySearch }) => {
    return state.merge({ categorySearch }).delete('categoryPage');
  },
  'CategorizationDashboard.progressCategorySort': (state, { field }) => {
    return state.merge({ categorySort: progressSortState({ currentSort: state.categorySort, fieldToProgress: field }) }).delete('categoryPage');
  },
};
