import enumify from './enumify';

// com.tamr.dataset.models.Task.StorageStatus

export const IN_PROGRESS = 'IN_PROGRESS';
export const PREPARING_COMMIT = 'PREPARING_COMMIT';
export const PREPARED_COMMIT = 'PREPARED_COMMIT';
export const COMMITTING = 'COMMITTING';
export const COMMITTED = 'COMMITTED';
export const ROLLING_BACK = 'ROLLING_BACK';
export const ROLLED_BACK = 'ROLLED_BACK';

export default enumify({
  IN_PROGRESS,
  PREPARING_COMMIT,
  PREPARED_COMMIT,
  COMMITTING,
  COMMITTED,
  ROLLING_BACK,
  ROLLED_BACK,
});
