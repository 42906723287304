import _ from 'underscore';

import { HINT_TOKEN_NAME } from '../models/Transforms';
import { ContentEnvelopeTypes } from './models/ContentEnvelope';
import ValidationError from './ValidationError';

export const getErrors = function (results, codeMirrorInstancePosition, exprOrOp, hasNotBeenTypedIntoYet, contentEnvelope) {
  // once we are getting lint errors from server (after user types at least once), override message for empty lint box
  const isFormula = !!contentEnvelope && contentEnvelope.type === ContentEnvelopeTypes.formula;
  const isAggregation = !!contentEnvelope && contentEnvelope.type === ContentEnvelopeTypes.aggregation;
  if (!hasNotBeenTypedIntoYet && exprOrOp === '') {
    return [{
      from: codeMirrorInstancePosition(0, 0),
      to: codeMirrorInstancePosition(0, 0),
      message: isFormula
        ? ValidationError.FORMULA_EMPTY_EXPRESSION
        : isAggregation
          ? ValidationError.AGGREGATION_EMPTY_VALUE
          : ValidationError.SCRIPT_EMPTY_VALUE,
      severity: 'error',
    }];
  }
  const found = [];
  let result = null;
  for (let i = 0; i < results.size; i++) {
    result = results.get(i);
    const interval = result.get('interval');
    if (!interval) {
      return found;
    }
    const startLine = interval.startLine - 1;
    const endLine = interval.endLine - 1;
    const startCol = (contentEnvelope && startLine === 0) ? interval.startPosition - contentEnvelope.pre.length : interval.startPosition;
    const endCol = (contentEnvelope && startLine === 0) ? interval.endPosition - contentEnvelope.pre.length : interval.endPosition;

    if ((contentEnvelope && (exprOrOp.length + contentEnvelope.pre.length) >= startCol && startCol > -1) || !contentEnvelope || startLine > 0) {
      found.push({
        from: codeMirrorInstancePosition(startLine, startCol),
        to: codeMirrorInstancePosition(endLine, endCol),
        message: result.message,
        severity: result.level.toLowerCase(),
      });
    }
  }
  return found;
};

export const getSuggestions = function (cursor, curLine, suggestionObjects, codeMirrorInstancePosition, context) {
  const wordRegex = /([."\u4e00-\u9fa5\w])+/;
  const end = cursor.ch;
  let start = end;
  while (start && wordRegex.test(curLine.charAt(start - 1))) {
    --start;
  }
  const curWord = start !== end && curLine.slice(start, end).replace(/^["]|["]$/g, '');

  // create an array with all suggestions objects
  let validClassNames = [];
  let suggestionsList = suggestionObjects.reduce(
    (accumulator, { potentialSuggestions, className }) => {
      // make sure to keep track of all the given classNames here
      validClassNames.push(className);
      potentialSuggestions.map(suggestion => {
        const index = suggestion.toUpperCase().indexOf((curWord || '').toUpperCase());
        if (!curWord || index > -1) {
          accumulator.push({ text: suggestion, index, className });
        }
      });
      return accumulator;
    },
    []);

  // the only suggestions we want in a hint context are the hint suggestions
  // we don't want hint suggestions in normal contexts though
  if (context === HINT_TOKEN_NAME) {
    validClassNames = [HINT_TOKEN_NAME];
  } else {
    validClassNames = validClassNames.filter((className) => {
      return className !== HINT_TOKEN_NAME;
    });
  }

  // filter out invalid classNames
  suggestionsList = suggestionsList.filter((suggestion) => {
    return validClassNames.includes(suggestion.className);
  });

  // sort suggestions alphabetically and then by index
  suggestionsList = _(suggestionsList).chain()
    .sortBy((suggestion) => {
      return suggestion.text;
    })
    .sortBy((suggestion) => {
      return suggestion.index;
    })
    .value();
  return {
    list: suggestionsList,
    from: codeMirrorInstancePosition(cursor.line, start),
    to: codeMirrorInstancePosition(cursor.line, end),
  };
};
