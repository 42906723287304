import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import { attributeId } from '../models/AttributeId';
import SourceAttribute from '../models/SourceAttribute';
import UnifiedAttribute from '../models/UnifiedAttribute';
import { getUnifiedDatasetName } from '../utils/Selectors';
import MappingSelector from './MappingSelector';
import { changeMapping } from './SchemaMappingAsync';

const UnifiedAttributeMappingSelector = _.compose(
  connect((state) => {
    const { schemaMapping: { sourceAttributes } } = state;
    return {
      sourceAttributes,
      datasetName: getUnifiedDatasetName(state),
    };
  }, {
    onChangeMapping: changeMapping,
  }),
)(class UnifiedAttributeMappingSelector extends React.Component {
  static propTypes = {
    attribute: PropTypes.instanceOf(UnifiedAttribute).isRequired,
    canUserEdit: PropTypes.bool.isRequired,
    datasetName: PropTypes.string,
    onChangeMapping: PropTypes.func.isRequired,
    onToggleFilterRelatedId: PropTypes.func.isRequired,
    sourceAttributes: ImmutablePropTypes.listOf(PropTypes.instanceOf(SourceAttribute)),
  };

  render() {
    const { attribute, canUserEdit, onChangeMapping, onToggleFilterRelatedId } = this.props;
    return (
      <div className="unified-attribute-mapping-selector">
        <MappingSelector
          isMappable
          isGenerated={attribute.generated}
          mappings={attribute.mappedAttributes}
          mappingTitle={'SAMPLE OF MAPPED ATTRIBUTES'}
          suggestionTitle={'TOP SUGGESTIONS (SIMILARITY)'}
          recommendations={attribute.recommendations}
          onMappingChange={(sid, isMapped) => onChangeMapping(sid, attributeId(attribute), isMapped)}
          onToggleFilterRelatedId={() => onToggleFilterRelatedId(attributeId(attribute))}
          canUserEdit={canUserEdit}
        />
      </div>
    );
  }
});

export default UnifiedAttributeMappingSelector;
