import classNames from 'classnames';
import { List, Set } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import CategoryPath from '../components/CategoryPath';
import SidebarTabs from '../constants/SidebarTabs';
import EsRecord from '../models/EsRecord';
import ProcurementTaxonomy from '../models/ProcurementTaxonomy';
import { pluralize } from '../utils/Strings';
import { getPath } from '../utils/Values';

const CategorizationCell = _.compose(
  connect(null, {
    onOpenTab: () => ({ type: 'Transactions.showSidebarTab', sidebarTabKey: SidebarTabs.DETAILS }),
    onShowCategorizeDialog: record => ({ type: 'Transactions.openCategorizeDialog', categorizeDialogRecords: List.of(record) }),
  }),
)(class CategorizationCell extends React.Component {
  static propTypes = {
    isUserACurator: PropTypes.bool,
    isUserAVerifier: PropTypes.bool,
    onOpenTab: PropTypes.func.isRequired,
    record: PropTypes.instanceOf(EsRecord),
    rowNumber: PropTypes.number.isRequired,
    taxonomy: PropTypes.instanceOf(ProcurementTaxonomy),
  };

  /**
   * Gets the number of categorizations either suggested by Tamr or by expert that are different than the
   * current active categorization
   */
  getNumOthers = () => {
    const { record } = this.props;
    const { manualCategorization, suggestedCategorization } = record;
    const manualCategorizationId = getPath(manualCategorization, 'categoryId');
    const suggestedCategorizationId = getPath(suggestedCategorization, 'categoryId');
    const currentActiveCategorizationId =
      _.isNumber(manualCategorizationId) ? manualCategorizationId : suggestedCategorizationId;

    return Set(record.categorizationSuggestions.map(suggestion => suggestion.categoryId).filter(categoryId => categoryId !== currentActiveCategorizationId)).size;
  };

  render() {
    const { record, taxonomy, isUserACurator, isUserAVerifier, onOpenTab } = this.props;
    if (!taxonomy) {
      return <div />;
    }
    const { manualCategorization, suggestedCategorization } = record;
    let category;
    let hasManualCategorization;
    if (manualCategorization) {
      category = taxonomy.findCategoryById(manualCategorization.categoryId);
      hasManualCategorization = true;
    } else if (suggestedCategorization) {
      category = taxonomy.findCategoryById(suggestedCategorization.categoryId);
    }

    if (!taxonomy || !record) {
      return <div />;
    }

    const numOthers = this.getNumOthers();
    let numOthersText;
    if (numOthers > 0) {
      numOthersText = (
        <span className="num-others">
          {pluralize(numOthers, '(+1 other)', `(+${numOthers} others)`)}
        </span>
      );
    }

    let categoryPathComponent;
    if (category) {
      categoryPathComponent = (
        <CategoryPath
          path={category.path}
          onPathSegmentClick={onOpenTab}
        />
      );
    } else if (isUserACurator || isUserAVerifier) {
      categoryPathComponent = (
        <div
          className="add-category-button"
          onClick={() => this.props.onShowCategorizeDialog(this.props.record)}
        >
          add categorization
        </div>
      );
    } else {
      categoryPathComponent = <span className="no-categorization">no categorization</span>;
    }

    return (
      <div className={classNames('categorization-cell', { 'has-final-label': hasManualCategorization })}>
        {categoryPathComponent}
        {numOthersText}
      </div>
    );
  }
});

export default CategorizationCell;
