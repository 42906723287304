import SortState from '../../constants/SortState';
import Model from '../../models/Model';
import { ArgTypes, checkArg } from '../../utils/ArgValidation';
import SortUtils from '../../utils/SortUtils';

class FieldSortConfig extends Model({
  field: { type: ArgTypes.string },
  order: { type: ArgTypes.valueIn(SortState) },
}) {
  nextConfig(field) {
    checkArg({ field }, ArgTypes.string);
    return new FieldSortConfig({ field, order: SortUtils.getNext(this.field === field ? this.order : SortState.UNSORTED) });
  }
}

export default FieldSortConfig;
