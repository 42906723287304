import { getDatasetMetadata, setDatasetMetadata } from '../api/DatasetClient';
import DatasetMetadataSpec from '../models/DatasetMetadataSpec';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { getActiveDatasetMetadataFilterInfo, getSingleSelectedDataset } from './DatasetMetadataStore';

export const readMetadataForSelectedDataset = () => (dispatch, getState) => {
  dispatch({ type: 'DatasetMetadata.getDatasetMetadata' });

  const state = getState();

  const currentlySelectedDataset = getSingleSelectedDataset(state);
  const metadataFilterInfo = getActiveDatasetMetadataFilterInfo(state);

  getDatasetMetadata({ datasetName: currentlySelectedDataset.data.name })
    .then(metadata => dispatch({ type: 'DatasetMetadata.getDatasetMetadataCompleted', dataset: currentlySelectedDataset, metadata, currentlySelectedDataset, metadataFilterInfo }))
    .catch(response => dispatch({ type: 'DatasetMetadata.getDatasetMetadataFailed', response }));
};

export const updateMetadataForSelectedDataset = ({ datasetMetadata, deleteMetadata }) => (dispatch, getState) => {
  checkArg({ datasetMetadata }, ArgTypes.Immutable.list.of(DatasetMetadataSpec.argType));
  checkArg({ deleteMetadata }, ArgTypes.nullable(ArgTypes.bool));

  dispatch({ type: 'DatasetMetadata.writeMetadata' });

  const currentlySelectedDataset = getSingleSelectedDataset(getState());

  setDatasetMetadata({
    datasetName: currentlySelectedDataset.data.name,
    datasetMetadata,
    deleteMetadata,
  })
    .then(() => dispatch({ type: 'DatasetMetadata.writeMetadataCompleted' }))
    .catch(response => dispatch({ type: 'DatasetMetadata.writeMetadataFailed', response }));
};
