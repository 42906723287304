import './TopNav.scss';

import classNames from 'classnames';
import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import Link from '../components/Link';
import TamrIcon from '../components/TamrIcon';
import NavItem from '../models/NavItem';
import { selectTopNav } from '../projects/ProjectsStore';
import { AppState } from '../stores/MainStore';
import ClusterFeedbackLoader from '../suppliers/ClusterFeedbackLoader';
// @ts-expect-error
import LogoNavItem from './LogoNavItem';
import StorageSpaceItem from './StorageSpaceItem';
// @ts-expect-error
import TopNavMenu from './TopNavMenu';
// @ts-expect-error
import UpdatesItem from './UpdatesItem';

function getPageName(url: string) {
  let resolvedUrl = url;
  if (resolvedUrl.startsWith('#')) {
    resolvedUrl = resolvedUrl.slice(1);
  }
  if (resolvedUrl.startsWith('/')) {
    resolvedUrl = resolvedUrl.slice(1);
  }
  if (resolvedUrl.includes('/')) {
    resolvedUrl = resolvedUrl.split('/')[0];
  }
  if (resolvedUrl.includes('?')) {
    resolvedUrl = resolvedUrl.split('?')[0];
  }
  return resolvedUrl;
}

function isRecipePage(url: string) {
  return url.includes('recipe');
}

// TODO<Sam> Consider more graceful way to find what page we're on other than reaching into
// window.location directly
function getCurrentPageName() {
  return getPageName(window.location.hash);
}

const LinkItem: React.FC<{
  destination: string;
  label: React.ReactNode;
  addlClassName?: string;
}> = ({ destination, label, addlClassName }) => {
  const selected = getPageName(destination) === getCurrentPageName()
    && isRecipePage(destination) === isRecipePage(window.location.hash);
  return (
    <Link
      key={destination}
      to={destination}
      className={classNames('top-nav-item', addlClassName, { selected })}
    >
      {label}
    </Link>
  );
};

const ProjectNavItem: React.FC<{
  projectName: string;
  recipeId?: number;
}> = ({ projectName, recipeId }) => {
  if (recipeId !== undefined) {
    // within project context
    return (
      <LinkItem
        destination={`/dashboard/recipe/${recipeId}`}
        label={<span title={projectName}>{projectName}</span>}
        addlClassName="project-name"
      />
    );
  }
  // system page
  return null;
};

const ProjectItems: React.FC<{
  navItems: List<NavItem>;
  projectIsDedup: boolean | null;
}> = ({ navItems, projectIsDedup }) => {
  let items = navItems.map(navItem => <LinkItem key={navItem.destination} destination={navItem.destination} label={navItem.label} />);
  if (projectIsDedup) {
    items = items.push(<ClusterFeedbackLoader key="cf-loader" />);
  }
  return <>{items}</>;
};

function mapStateToProps(state: AppState) {
  return selectTopNav(state);
}

type TopNavProps = ReturnType<typeof mapStateToProps> & { recipeId?: number };

const TopNav: React.FC<TopNavProps> = ({ anyProjectAccess, loggedInUserIsAdmin, lastJobFailed, projectName, recipeId, navItems, projectIsDedup }) => {
  return (
    <div className="top-nav">
      <div className="top-nav-left">
        <LogoNavItem />
        <ProjectNavItem {...{ projectName, recipeId }} />
        <ProjectItems {...{ navItems, projectIsDedup }} />
        <StorageSpaceItem />
      </div>
      <div className="top-nav-right">
        {<UpdatesItem />}
        {!anyProjectAccess ? null : (
          <LinkItem
            destination={'/jobs'}
            label={(
              <React.Fragment>
                <TamrIcon iconName="hourglass-full" size={18} /> <span>Jobs</span>
                {lastJobFailed ? <span className="top-nav-job-failed-indicator" /> : null}
              </React.Fragment>
            )}
          />
        )}
        {!loggedInUserIsAdmin ? null : (
          <LinkItem
            destination={'/datasets'}
            label={(
              <React.Fragment>
                <TamrIcon iconName="library-books" size={16} /> <span>Dataset Catalog</span>
              </React.Fragment>
            )}
          />
        )}
        {!loggedInUserIsAdmin ? null : (
          <LinkItem
            destination={'/users'}
            label={(
              <React.Fragment>
                <TamrIcon iconName="people" size={16} /> <span>Users and Groups</span>
              </React.Fragment>
            )}
          />
        )}
        {!loggedInUserIsAdmin ? null : (
          <LinkItem
            destination={'/access/policies'}
            label={(
              <React.Fragment>
                <TamrIcon iconName="domain" size={16} /> <span>Policies</span>
              </React.Fragment>
            )}
          />
        )}
        {<TopNavMenu />}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(TopNav);
