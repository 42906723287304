import { ArgTypes, checkArg, InferCheckerMapType, UntypedCheckerMap } from './ArgValidation';

type CheckerMap<T, U extends UntypedCheckerMap> = T extends InferCheckerMapType<U> ? UntypedCheckerMap : never;

/**
 * Will simply check the given JSON against the given ArgTypes, and return
 *   the JSON as the given TS type.
 * Meant to be used when reading JSON returned by an API call.
 * @param checkerMap an object whose values are ArgType checkers
 * @param json any JSON data
 */
export function checkTypesAndCast<T extends InferCheckerMapType<U>, U extends CheckerMap<T, U>>(
  checkerMap: U,
): (json: unknown) => T {
  return (json: unknown) => {
    checkArg({ json }, ArgTypes.object.withShape(checkerMap));
    return json as T;
  };
}
