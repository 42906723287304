import { List } from 'immutable';

import { $TSFixMe } from '../utils/typescript';
import { DatasetSourceE } from './DatasetSource';
import { MimeTypeE } from './MimeType';
import SampleRecords from './SampleRecords';
import StorageNode from './StorageNode';

export const START_ADD_DATASET = 'AddDataset.startAddDataset';
export const SET_TAB = 'AddDataset.setTab';
export const ADD_DATASET = 'AddDataset.addDataset';
export const ADD_DATASET_FAILED = 'AddDataset.addDatasetFailed';
export const HIDE_DIALOG = 'AddDataset.hideDialog';
export const CREATE_EXTERNAL_DATASET_COMPLETED = 'AddDataset.createExternalDatasetCompleted';
export const CREATE_EXTERNAL_DATASET_FAILED = 'AddDataset.createExternalDatasetFailed';

interface AddDatasetStartAddDataset {
  type: typeof START_ADD_DATASET
  isCatalogPage: boolean
}

interface AddDatasetSetTab {
  type: typeof SET_TAB
  selectedTab: DatasetSourceE
}

interface AddDatasetAddDataset {
  type: typeof ADD_DATASET
}

interface AddDatasetAddDatasetFailed {
  type: typeof ADD_DATASET_FAILED
}

interface HideDialog {
  type: typeof HIDE_DIALOG
}

export const CREATE_EXTERNAL_DATASET = 'AddDataset.createExternalDataset';
interface CreateExternalDataset {
  type: typeof CREATE_EXTERNAL_DATASET
}

interface CreateExternalDatasetCompleted {
  type: typeof CREATE_EXTERNAL_DATASET_COMPLETED
}

interface CreateExternalDatasetFailed {
  type: typeof CREATE_EXTERNAL_DATASET_FAILED
}

export const SET_EXTERNAL_DATASET_NAME = 'AddDataset.setExternalDatasetName';
interface AddDatasetSetExternalDatasetName {
  type: typeof SET_EXTERNAL_DATASET_NAME
  name: string
}

export const SET_EXTERNAL_DATASET_DESCRIPTION = 'AddDataset.setExternalDatasetDescription';
interface AddDatasetSetExternalDatasetDescription {
  type: typeof SET_EXTERNAL_DATASET_DESCRIPTION
  description: string
}

export const SET_EXTERNAL_STORAGE_PROVIDER = 'AddDataset.setExternalStorageProvider';
interface AddDatasetSetExternalStorageProvider {
  type: typeof SET_EXTERNAL_STORAGE_PROVIDER
  name: string
}

export const SET_EXTERNAL_STORAGE_PROVIDER_COMPLETED = 'AddDataset.setExternalStorageProviderCompleted';
interface AddDatasetSetExternalStorageProviderCompleted {
  type: typeof SET_EXTERNAL_STORAGE_PROVIDER_COMPLETED
  name: string
  nodes: List<StorageNode>
}

export const SELECT_EXTERNAL_STORAGE_NODE = 'AddDataset.selectExternalStorageNode';
interface AddDatasetSelectExternalStorageNode {
  type: typeof SELECT_EXTERNAL_STORAGE_NODE
  node: StorageNode
}

export const SELECT_EXTERNAL_STORAGE_NODE_COMPLETED = 'AddDataset.selectExternalStorageNodeCompleted';
interface AddDatasetSelectExternalStorageNodeCompleted {
  type: typeof SELECT_EXTERNAL_STORAGE_NODE_COMPLETED
  selectedProvider: string
  nodes: List<StorageNode>
  path: List<string>
  sampleRecords: SampleRecords | undefined
}

export const SELECT_EXTERNAL_STORAGE_NODE_GROUP = 'AddDataset.selectExternalStorageNodeGroup';
interface AddDatasetSelectExternalStorageNodeGroup {
  type: typeof SELECT_EXTERNAL_STORAGE_NODE_GROUP
  node: StorageNode
  nodeType: MimeTypeE
}

export const SET_CONFIG_VALUE = 'AddDataset.setConfigValue';
interface AddDatasetSetConfigValue {
  type: typeof SET_CONFIG_VALUE
  key: string
  value: string
}

export const FETCH_STORAGE_PROVIDERS_COMPLETED = 'AddDataset.fetchStorageProvidersCompleted';
interface AddDatasetFetchStorageProvidersCompleted {
  type: typeof FETCH_STORAGE_PROVIDERS_COMPLETED
  externalStorageProviders: $TSFixMe
}

export const FETCH_STORAGE_PROVIDERS_FAILED = 'AddDataset.fetchStorageProvidersFailed';
interface AddDatasetFetchStorageProvidersFailed {
  type: typeof FETCH_STORAGE_PROVIDERS_FAILED
}

export const SET_ID_COLUMN = 'AddDataset.setIdColumn';
interface AddDatasetSetIdColumn {
  type: typeof SET_ID_COLUMN
  idColumn: string
}

export type AddDatasetActionTypes
  = AddDatasetStartAddDataset
  | AddDatasetSetTab
  | AddDatasetAddDataset
  | AddDatasetAddDatasetFailed
  | HideDialog
  | CreateExternalDataset
  | CreateExternalDatasetCompleted
  | CreateExternalDatasetFailed
  | AddDatasetSetExternalDatasetName
  | AddDatasetSetExternalDatasetDescription
  | AddDatasetSetExternalStorageProvider
  | AddDatasetSetExternalStorageProviderCompleted
  | AddDatasetSelectExternalStorageNode
  | AddDatasetSelectExternalStorageNodeCompleted
  | AddDatasetSelectExternalStorageNodeGroup
  | AddDatasetSetConfigValue
  | AddDatasetFetchStorageProvidersCompleted
  | AddDatasetFetchStorageProvidersFailed
  | AddDatasetSetIdColumn
