import { Map, Set } from 'immutable';
import _ from 'underscore';

import { ArgTypes, checkArg, Checker } from '../utils/ArgValidation';

/**
 * Generates a tagged union type, using the validation methods supplied
 *
 * For more information on the tagged union type, see: https://guide.elm-lang.org/types/custom_types.html
 *
 * Usage 1: Creating a type
 *  const Shape = TaggedUnion({
 *     Square: {
 *       sideLength: ArgTypes.number,
 *     },
 *     Rectangle: {
 *       height: ArgTypes.number,
 *       width: ArgTypes.number,
 *     },
 *     Circle: {
 *      radius: ArgTypes.number,
 *     },
 *  }, 'Shape');
 *
 *  Terminology in context of the above example:
 *    tags - Square, Rectangle, Circle
 *    fields - sideLength, height, width, radius
 *    types - ArgTypes.number
 *
 * Usage 2: Switching based on tag
 *  Once you've created a type, you can create an instance:
 *    const aShape = Shape.Rectangle({ height: 17, width: 4 });
 *
 *  When you have an instance, you can conditionally switch on it
 *  (based on the instance above, this will return: 'This is a Rectangle or a Circle'):
 *    aShape.case({
 *      Square: ({ sideLength }) => `This is a Square with sides of length ${sideLength}`,
 *      _: () => 'This is a Rectangle or a Circle',
 *    });
 *
 *  Note: the '_' case matches any other valid tags that aren't covered by the other cases.
 *    This is like the 'default' case of a switch statement.  However, it will throw an
 *    error if the value (e.g. aShape) is not a valid tag
 *  Note about TypeScript: using '_' is not supported in TaggedUnion when used in a TypeScript context!
 *    In fact, it might be an antipattern to have a "else" branch.
 *    In TypeScript, if you add a new tag to a TaggedUnion, the compiler will require all usages to be updated.
 */

type UntypedKindCheckers = {[key: string]: Checker<any>}
type UntypedKindDefinitions = {[key: string]: UntypedKindCheckers};
type InferCheckerType<V extends Checker<any>> = V extends Checker<infer T> ? T : never;
export type InferSingleKindParameters<V extends UntypedKindCheckers> = { [K in keyof V]-?: InferCheckerType<V[K]> };
type InferCases<T extends UntypedKindDefinitions> = <U>(cases: {[K in keyof T]-?: ((kindParameters: InferSingleKindParameters<T[K]>) => U) }) => U
export type InferConstructedKind<T extends UntypedKindDefinitions> = {
  case: InferCases<T>,
  tagName: keyof T,
  typeName: string,
};
type InferSingleKindConstructor<T extends UntypedKindDefinitions, V extends UntypedKindCheckers> = (args: InferSingleKindParameters<V>) => InferConstructedKind<T>;
type InferKindConstructors<V extends UntypedKindDefinitions> = {[K in keyof V]-?: InferSingleKindConstructor<V, V[K]>};

function TaggedUnion<T extends UntypedKindDefinitions>(tagsToFieldsToTypes: T, typeName: string) {
  checkArg({ tagsToFieldsToTypes }, ArgTypes.object.of(
    ArgTypes.object.of(ArgTypes.func),
  ));
  checkArg({ typeName }, ArgTypes.string);
  const tagNames: Set<keyof T> = Set(_.keys(tagsToFieldsToTypes));
  const caseGen: <K extends keyof T>(tagName: K, nestedValues: InferSingleKindParameters<T[K]>) => InferCases<T> = (tagName: keyof T, nestedValues) => (branches) => {
    const branchNames = Set(_.keys(branches));
    const missingBranches = tagNames.subtract(branchNames);
    if (!branchNames.has('_') && !missingBranches.isEmpty()) {
      console.warn(`Missing case branch for tags: ${missingBranches.toArray()}`);
    }
    if (branchNames.has('_') && missingBranches.has(tagName)) {
      // @ts-expect-error _ is entirely unsupported in the TypeScript usage of TaggedUnion.
      //            there are existing usages of TaggedUnion in JS-land that rely on this, so keep it for back-compat.
      return branches._();
    }
    return branches[tagName](nestedValues);
  };
  function generateKindConstructor<U extends keyof T>(tagName: U): InferSingleKindConstructor<T, T[U]> {
    return function kindConstructor(kindParameters: InferSingleKindParameters<T[U]>): InferConstructedKind<T> {
      if (kindParameters) {
        checkArg({ kindParameters }, ArgTypes.object);
      }
      _.forEach(tagsToFieldsToTypes[tagName], (argType, name) => {
        checkArg({ [name]: kindParameters[name] }, argType);
      });
      return {
        case: caseGen(tagName, kindParameters || {}),
        tagName,
        typeName,
      };
    };
  }
  type KindConstructors = InferKindConstructors<T>;
  const kindConstructors: KindConstructors = Map(tagNames.toArray().map((tagName) => {
    return [tagName, generateKindConstructor(tagName)];
  })).toObject() as KindConstructors; // can we remove this cast?
  const argType: Checker<InferConstructedKind<T>> = (value: InferConstructedKind<T>) => {
    if (!_.isObject(value) || !_.isFunction(value.case) || !tagNames.has(value.tagName) || value.typeName !== typeName) {
      return { noun: `an instantiation of the TaggedUnion '${typeName}'` };
    }
    return null;
  };
  return Object.assign(
    kindConstructors,
    {
      kindDefinitions: tagsToFieldsToTypes,
      argType,
    },
  );
}

export default TaggedUnion;
