import { List, Map, Set } from 'immutable';

import { FetchErrorResponseWithApiException } from '../api/FetchResult';
import Document from '../models/doc/Document';
import Page from '../models/Page';
import TypedTable from '../models/TypedTable';
import { OperationListResult } from '../transforms/models/StaticAnalysisModels';
import { RecordType } from '../transforms/models/Types';
import { $TSFixMe } from '../utils/typescript';
import { BucketIdType, DatasetFilter } from './DatasetFilter';
import { EsRecord } from './EsRecord';
import { FilterClusterRecords } from './FilterClusterRecords';
import { GoldenRecordsActionConfirmationTypeE } from './GoldenRecordsActionConfirmationType';
import { SourceListRecord } from './GoldenRecordsAPI';
import * as GoldenRecordsModule from './GoldenRecordsModule';
import { GoldenRecordsOverrideStats } from './GoldenRecordsOverrideStats';
import {
  ClusterProfileJobFilterInfo,
  DraftRecordsFilterInfo,
  FilterBookmarkStateE,
  ModuleFilterInfo,
  SourceListFilterInfo,
} from './GoldenRecordsStore';
import { ModuleStatus } from './ModuleStatus';
import { RuleTypeNameE } from './Rule';
import { RuleDelta } from './RuleDelta';

export const CLEAR_SELECTION = 'GoldenRecords.clearSelection';
export const FETCH_MODULE = 'GoldenRecords.fetchModule';
export const FETCH_MODULE_COMPLETED = 'GoldenRecords.fetchModuleCompleted';
export const TOGGLE_SIDEBAR = 'GoldenRecords.toggleSidebar';
export const SAVE_RULE = 'GoldenRecords.saveRule';
export const SAVE_RULE_COMPLETED = 'GoldenRecords.saveRuleCompleted';
export const SAVE_RULE_FAILED = 'GoldenRecords.saveRuleFailed';
export const SAVE_ALL_RULES = 'GoldenRecords.saveAllRules';
export const SAVE_ALL_RULES_COMPLETED = 'GoldenRecords.saveAllRulesCompleted';
export const SAVE_ALL_RULES_FAILED = 'GoldenRecords.saveAllRulesFailed';
export const CANCEL_RULE = 'GoldenRecords.cancelRule';
export const CANCEL_RULES = 'GoldenRecords.cancelRules';
export const SET_PAGE = 'GoldenRecords.setPage';
export const SET_PAGE_SIZE = 'GoldenRecords.setPageSize';
export const FETCH_INPUT_DATASET_SCHEMA_COMPLETED = 'GoldenRecords.fetchInputDatasetSchemaCompleted';
export const UPDATE_RULE = 'GoldenRecords.updateRule';
export const DELETE_RULE = 'GoldenRecords.deleteRule';
export const CHANGE_RULE_TYPE = 'GoldenRecords.changeRuleType';
export const MOVE_RULE_FILTER = 'GoldenRecords.moveRuleFilter';
export const ADD_RULE_FILTER = 'GoldenRecords.addRuleFilter';
export const REMOVE_RULE_FILTER = 'GoldenRecords.removeRuleFilter';
export const UPDATE_RULE_FILTER = 'GoldenRecords.updateRuleFilter';
export const CREATE_ATTRIBUTE = 'GoldenRecords.createAttribute';
export const TOGGLE_EXPANDED = 'GoldenRecords.toggleExpanded';
export const EXPAND = 'GoldenRecords.expand';
export const TOGGLE_ALL_EXPANDED = 'GoldenRecords.toggleAllExpanded';
export const QUERY_DRAFT = 'GoldenRecords.queryDraft';
export const QUERY_DRAFT_COMPLETED = 'GoldenRecords.queryDraftCompleted';
export const QUERY_DRAFT_FAILED = 'GoldenRecords.queryDraftFailed';
export const FETCH_MODULE_FROM_LAST_UPDATE = 'GoldenRecords.fetchModuleFromLastUpdate';
export const FETCH_MODULE_FROM_LAST_UPDATE_COMPLETED = 'GoldenRecords.fetchModuleFromLastUpdateCompleted';
export const FETCH_MODULE_FROM_LAST_UPDATE_FAILED = 'GoldenRecords.fetchModuleFromLastUpdateFailed';
export const FETCH_MODULE_FROM_LAST_PUBLISH_COMPLETED = 'GoldenRecords.fetchModuleFromLastPublishCompleted';
export const INDEX_DRAFT_JOB_FINISHED = 'GoldenRecords.indexDraftJobFinished';
export const SUBMIT_UPDATE_SOURCE_LIST_JOB = 'GoldenRecords.submitUpdateSourceListJob';
export const SUBMIT_PROFILE_JOB = 'GoldenRecords.submitProfileJob';
export const SUBMIT_UPDATE_SOURCE_LIST_JOB_FAILED = 'GoldenRecords.submitUpdateSourceListJobFailed';
export const SUBMIT_PROFILE_JOB_FAILED = 'GoldenRecords.submitProfileJobFailed';
export const SUBMIT_UPDATE_CLUSTER_PROFILE_JOB = 'GoldenRecords.submitUpdateClusterProfileJob';
export const SUBMIT_UPDATE_CLUSTER_PROFILE_JOB_FAILED = 'GoldenRecords.submitUpdateClusterProfileJobFailed';
export const UPDATE_SOURCE_LIST_JOB_FINISHED = 'GoldenRecords.updateSourceListJobFinished';
export const UPDATE_SOURCE_LIST_JOB_FINISHED_2S_AGO = 'GoldenRecords.updateSourceListJobFinished2sAgo';
export const UPDATE_CLUSTER_PROFILE_JOB_FINISHED = 'GoldenRecords.updateClusterProfileJobFinished';
export const UPDATE_CLUSTER_PROFILE_JOB_FINISHED_2S_AGO = 'GoldenRecords.updateClusterProfileJobFinished2sAgo';
export const FETCH_ALL_OVERRIDE_STATS_COMPLETED = 'GoldenRecords.fetchAllOverrideStatsCompleted';
export const FETCH_OVERRIDE_STATS_FOR_ATTRIBUTE = 'GoldenRecords.fetchOverrideStatsForAttribute';
export const UPDATE_OVERRIDE = 'GoldenRecords.updateOverride';
export const UPDATE_OVERRIDE_COMPLETED = 'GoldenRecords.updateOverrideCompleted';
export const UPDATE_SEARCH_STRING = 'GoldenRecords.updateSearchString';
export const CLEAR_ALL_FILTERS = 'GoldenRecords.clearAllFilters';
export const UPDATE_HAS_OVERRIDES = 'GoldenRecords.updateHasOverrides';
export const SET_FILTER_BOOKMARKS = 'GoldenRecords.setFilterBookmarks';
export const TOGGLE_FILTER_TO_RULE_WITH_OVERRIDES = 'GoldenRecords.toggleFilterToRuleWithOverrides';
export const SHOW_CONFLICT_DIALOG = 'GoldenRecords.showConflictDialog';
export const SAVE_MODULE_INVALID = 'GoldenRecords.saveModuleInvalid';
export const CANCEL_FORCE_SAVE_MODULE = 'GoldenRecords.cancelForceSaveModuleDialog';
export const SET_MODULE_TO_UPDATE = 'GoldenRecords.setModuleToUpdate';
export const BEGIN_CONFIRMING_UPDATE_GOLDEN_RECORDS = 'GoldenRecords.beginConfirmingUpdateGoldenRecords';
export const CANCEL_CONFIRMING_UPDATE_GOLDEN_RECORDS = 'GoldenRecords.cancelConfirmingUpdateGoldenRecords';
export const SUBMIT_UPDATE_GOLDEN_RECORDS_DRAFT = 'GoldenRecords.submitUpdateGoldenRecordsDraft';
export const SUBMIT_UPDATE_GOLDEN_RECORDS_DRAFT_FAILED = 'GoldenRecords.submitUpdateGoldenRecordsDraftFailed';
export const BEGIN_CONFIRMING_PUBLISH = 'GoldenRecords.beginConfirmingPublish';
export const CANCEL_CONFIRMING_PUBLISH = 'GoldenRecords.cancelConfirmingPublish';
export const EXCLUDE_UNACCOUNTED_SOURCES_COMPLETED = 'GoldenRecords.excludeUnaccountedSourcesCompleted';
export const PUBLISH = 'GoldenRecords.publish';
export const PUBLISH_COMPLETED = 'GoldenRecords.publishCompleted';
export const PUBLISH_FAILED = 'GoldenRecords.publishFailed';
export const CHANGE_SORT = 'GoldenRecords.changeSort';
export const PERFORM_STATIC_ANALYSIS_FOR_RULE_COMPLETED = 'GoldenRecords.performStaticAnalysisForRuleCompleted';
export const PERFORM_STATIC_ANALYSIS_FOR_FILTER_COMPLETED = 'GoldenRecords.performStaticAnalysisForFilterCompleted';
export const FETCH_SOURCE_LIST = 'GoldenRecords.fetchSourceList';
export const FETCH_SOURCE_LIST_COMPLETED = 'GoldenRecords.fetchSourceListCompleted';
export const FETCH_SOURCE_LIST_FAILED = 'GoldenRecords.fetchSourceListFailed';
export const FETCH_CLUSTER_PROFILE_JOB = 'GoldenRecords.fetchClusterProfileJob';
export const FETCH_CLUSTER_PROFILE_JOB_COMPLETED = 'GoldenRecords.fetchClusterProfileJobCompleted';
export const FETCH_CLUSTER_PROFILE_JOB_FAILED = 'GoldenRecords.fetchClusterProfileJobFailed';
export const BEGIN_EDITING_DATASET_FILTER = 'GoldenRecords.beginEditingDatasetFilter';
export const SHOW_READONLY_DATASET_FILTER = 'GoldenRecords.showReadonlyDatasetFilter';
export const CANCEL_EDITING_DATASET_FILTER = 'GoldenRecords.cancelEditingDatasetFilter';
export const SELECT_DATASET_FILTER_SOURCE = 'GoldenRecords.selectDatasetFilterSource';
export const FORCE_SELECT_DATASET_FILTER_SOURCE = 'GoldenRecords.forceSelectDatasetFilterSource';
export const FORCE_SELECT_DATASET_FILTER_BUCKET = 'GoldenRecords.forceSelectDatasetFilterBucket';
export const TOGGLE_DATASET_FILTER_EXCLUDED_SELECTED = 'GoldenRecords.toggleDatasetFilterExcludedSelected';
export const TOGGLE_DATASET_FILTER_PRIORITY_SELECTED = 'GoldenRecords.toggleDatasetFilterPrioritySelected';
export const TOGGLE_ALL_DATASET_FILTER_SOURCES_SELECTED = 'GoldenRecords.toggleAllDatasetFilterSourcesSelected';
export const MOVE_DATASETS_TO_NEW_TOP_PRIORITY = 'GoldenRecords.moveDatasetsToNewTopPriority';
export const MOVE_DATASETS_TO_NEW_BOTTOM_PRIORITY = 'GoldenRecords.moveDatasetsToNewBottomPriority';
export const MOVE_DATASETS_TO_NEW_PRIORITY_ABOVE = 'GoldenRecords.moveDatasetsToNewPriorityAbove';
export const MOVE_DATASETS_TO_NEW_PRIORITY_BELOW = 'GoldenRecords.moveDatasetsToNewPriorityBelow';
export const MOVE_DATASETS_TO_PRIORITY = 'GoldenRecords.moveDatasetsToPriority';
export const EXCLUDE_DATASETS = 'GoldenRecords.excludeDatasets';
export const BEGIN_CONFIRMING_REMOVE_SOURCE_FROM_DATASET_FILTERS = 'GoldenRecords.beginConfirmingRemoveSourceFromDatasetFilters';
export const CANCEL_CONFIRMING_REMOVE_SOURCE_FROM_DATASET_FILTERS = 'GoldenRecords.cancelConfirmingRemoveSourceFromDatasetFilters';
export const CONFIRM_REMOVE_SOURCE_FROM_DATASET_FILTERS = 'GoldenRecords.confirmRemoveSourceFromDatasetFilters';
export const SAVE_DATASET_FILTER_EDITS = 'GoldenRecords.saveDatasetFilterEdits';
export const UPDATE_EDIT_DATASET_FILTER_SEARCH_STRING = 'GoldenRecords.updateEditDatasetFilterSearchString';
export const SELECT_ROWS = 'GoldenRecords.selectRows';
export const TOGGLE_FILTER_PANEL = 'GoldenRecords.toggleFilterPanel';
export const SHOW_SELECT_OVERRIDE_FILTER_DIALOG = 'GoldenRecords.showSelectOverrideFilterDialog';
export const HIDE_SELECT_OVERRIDE_FILTER_DIALOG = 'GoldenRecords.hideSelectOverrideFilterDialog';
export const HIDE_BOOKMARKS_ONBOARDING_MESSAGE = 'GoldenRecords.hideBookmarksOnboardingMessage';
export const FETCH_CLUSTER_SAMPLE = 'GoldenRecords.fetchClusterSample';
export const FETCH_CLUSTER_SAMPLE_COMPLETED = 'GoldenRecords.fetchClusterSampleCompleted';
export const FETCH_CLUSTER_SAMPLE_FAILED = 'GoldenRecords.fetchClusterSampleFailed';
export const TOGGLE_SHOW_CLUSTER_SAMPLE_TABLE = 'GoldenRecords.toggleShowClusterSampleTable';

interface GoldenRecordsClearSelection {
  type: typeof CLEAR_SELECTION
}

interface GoldenRecordsFetchModule {
  type: typeof FETCH_MODULE
}

interface GoldenRecordsFetchModuleCompleted {
  type: typeof FETCH_MODULE_COMPLETED
  moduleDoc: Document<GoldenRecordsModule.GoldenRecordsModule>,
  moduleStatus: ModuleStatus,
  filterInfo: ModuleFilterInfo,
}

interface GoldenRecordsToggleSidebar {
  type: typeof TOGGLE_SIDEBAR,
  moduleDoc: Document<GoldenRecordsModule.GoldenRecordsModule>,
  ruleName: string
}

interface GoldenRecordsSaveRule {
  type: typeof SAVE_RULE
  module: GoldenRecordsModule.GoldenRecordsModule
  ruleName: string
}

interface GoldenRecordsSaveRuleCompleted {
  type: typeof SAVE_RULE_COMPLETED
  moduleDoc: Document<GoldenRecordsModule.GoldenRecordsModule>
  ruleName: string
}

interface GoldenRecordsSaveRuleFailed {
  type: typeof SAVE_RULE_FAILED
}

interface GoldenRecordsSaveAllRules {
  type: typeof SAVE_ALL_RULES
  module: GoldenRecordsModule.GoldenRecordsModule
}

interface GoldenRecordsSaveAllRulesCompleted {
  type: typeof SAVE_ALL_RULES_COMPLETED
  moduleDoc: Document<GoldenRecordsModule.GoldenRecordsModule>
}

interface GoldenRecordsSaveAllRulesFailed {
  type: typeof SAVE_ALL_RULES_FAILED
}

interface GoldenRecordsCancelRule {
  type: typeof CANCEL_RULE
  ruleName: string
}

interface GoldenRecordsCancelRules {
  type: typeof CANCEL_RULES
}

interface GoldenRecordsSetPage {
  type: typeof SET_PAGE
  pageNum: number
}

interface GoldenRecordsSetPageSize {
  type: typeof SET_PAGE_SIZE
  pageSize: number
}

interface GoldenRecordsFetchInputDatasetSchemaCompleted {
  type: typeof FETCH_INPUT_DATASET_SCHEMA_COMPLETED
  schema: RecordType
}

interface GoldenRecordsUpdateRule {
  type: typeof UPDATE_RULE
  ruleDelta: RuleDelta
}

interface GoldenRecordsDeleteRule {
  type: typeof DELETE_RULE
  ruleName: string
}

interface GoldenRecordsChangeRuleType {
  type: typeof CHANGE_RULE_TYPE
  ruleName: string
  newType: RuleTypeNameE
}

interface GoldenRecordsMoveRuleFilter {
  type: typeof MOVE_RULE_FILTER
  ruleName: string
  oldIndex: number
  newIndex: number
}

interface GoldenRecordsAddRuleFilter {
  type: typeof ADD_RULE_FILTER
  ruleName: string
}

interface GoldenRecordsRemoveRuleFilter {
  type: typeof REMOVE_RULE_FILTER
  ruleName: string
  index: number
}

interface GoldenRecordsUpdateRuleFilter {
  type: typeof UPDATE_RULE_FILTER
  ruleName: string
  index: number
  filter: FilterClusterRecords
}

interface GoldenRecordsCreateAttribute {
  type: typeof CREATE_ATTRIBUTE
  outputAttributeName: string
}

interface GoldenRecordsToggleExpanded {
  type: typeof TOGGLE_EXPANDED
  name: string
}

interface GoldenRecordsExpand {
  type: typeof EXPAND
  name: string
}

interface GoldenRecordsToggleAllExpanded {
  type: typeof TOGGLE_ALL_EXPANDED
}

interface GoldenRecordsQueryDraft {
  type: typeof QUERY_DRAFT
}

interface GoldenRecordsQueryDraftCompleted {
  type: typeof QUERY_DRAFT_COMPLETED
  page: Page<EsRecord>
  filterInfo: DraftRecordsFilterInfo
}

interface GoldenRecordsQueryDraftFailed {
  type: typeof QUERY_DRAFT_FAILED
  filterInfo: DraftRecordsFilterInfo
}

interface GoldenRecordsFetchModuleFromLastUpdate {
  type: typeof FETCH_MODULE_FROM_LAST_UPDATE
}

interface GoldenRecordsFetchModuleFromLastUpdateCompleted {
  type: typeof FETCH_MODULE_FROM_LAST_UPDATE_COMPLETED
  moduleFromLastUpdate: GoldenRecordsModule.GoldenRecordsModule
  moduleFromLastUpdateVersion: number
}

interface GoldenRecordsFetchModuleFromLastUpdateFailed {
  type: typeof FETCH_MODULE_FROM_LAST_UPDATE_FAILED
  moduleFromLastUpdateVersion: number
}

export const FETCH_DRAFT_RECORD_TOTAL = 'GoldenRecords.fetchDraftRecordTotal';
interface GoldenRecordsFetchDraftRecordTotal {
  type: typeof FETCH_DRAFT_RECORD_TOTAL
}

export const FETCH_DRAFT_RECORD_TOTAL_COMPLETED = 'GoldenRecords.fetchDraftRecordTotalCompleted';
interface GoldenRecordsFetchDraftRecordTotalCompleted {
  type: typeof FETCH_DRAFT_RECORD_TOTAL_COMPLETED
  totalRecords: number
  moduleFromLastUpdateVersion: number
}

export const FETCH_DRAFT_RECORD_TOTAL_FAILED = 'GoldenRecords.fetchDraftRecordTotalFailed';
interface GoldenRecordsFetchDraftRecordTotalFailed {
  type: typeof FETCH_DRAFT_RECORD_TOTAL_FAILED
  moduleFromLastUpdateVersion: number
}

interface GoldenRecordsFetchModuleFromLastPublishCompleted {
  type: typeof FETCH_MODULE_FROM_LAST_PUBLISH_COMPLETED
  module: GoldenRecordsModule.GoldenRecordsModule
}

interface GoldenRecordsIndexDraftJobFinished {
  type: typeof INDEX_DRAFT_JOB_FINISHED
}

interface GoldenRecordsSubmitUpdateSourceListJob {
  type: typeof SUBMIT_UPDATE_SOURCE_LIST_JOB
}

interface GoldenRecordsSubmitProfileJob {
  type: typeof SUBMIT_PROFILE_JOB
}

interface GoldenRecordsSubmitUpdateSourceListJobFailed {
  type: typeof SUBMIT_UPDATE_SOURCE_LIST_JOB_FAILED
  data: $TSFixMe
  message: $TSFixMe
}

interface GoldenRecordsSubmitProfileJobFailed {
  type: typeof SUBMIT_PROFILE_JOB_FAILED
  data: $TSFixMe
  message: $TSFixMe
}

interface GoldenRecordsSubmitUpdateClusterProfileJob {
  type: typeof SUBMIT_UPDATE_CLUSTER_PROFILE_JOB
  moduleId: number
}

interface GoldenRecordsSubmitUpdateClusterProfileJobFailed {
  type: typeof SUBMIT_UPDATE_CLUSTER_PROFILE_JOB_FAILED
  data: $TSFixMe
  message: $TSFixMe
}

interface GoldenRecordsUpdateSourceListJobFinished {
  type: typeof UPDATE_SOURCE_LIST_JOB_FINISHED
}

interface GoldenRecordsUpdateSourceListJobFinished2sAgo {
  type: typeof UPDATE_SOURCE_LIST_JOB_FINISHED_2S_AGO
}

interface GoldenRecordsUpdateClusterProfileJobFinished {
  type: typeof UPDATE_CLUSTER_PROFILE_JOB_FINISHED
}

interface GoldenRecordsUpdateClusterProfileJobFinished2sAgo {
  type: typeof UPDATE_CLUSTER_PROFILE_JOB_FINISHED_2S_AGO
}

interface GoldenRecordsFetchAllOverrideStatsCompleted {
  type: typeof FETCH_ALL_OVERRIDE_STATS_COMPLETED
  overrideStats: Map<string, GoldenRecordsOverrideStats>
}

interface GoldenRecordsFetchOverrideStatsForAttribute {
  type: typeof FETCH_OVERRIDE_STATS_FOR_ATTRIBUTE
  attributeName: string
  overrideStats: GoldenRecordsOverrideStats
}

interface GoldenRecordsUpdateOverride {
  type: typeof UPDATE_OVERRIDE
}
interface GoldenRecordsUpdateOverrideCompleted {
  type: typeof UPDATE_OVERRIDE_COMPLETED
}

interface GoldenRecordsUpdateSearchString {
  type: typeof UPDATE_SEARCH_STRING
  searchString: string
}

interface GoldenRecordsClearAllFilters {
  type: typeof CLEAR_ALL_FILTERS
}

interface GoldenRecordsUpdateHasOverrides {
  type: typeof UPDATE_HAS_OVERRIDES
  hasOverrides: Set<string>
}

interface GoldenRecordsSetFilterBookmarks {
  type: typeof SET_FILTER_BOOKMARKS
  filterBookmarks: Set<FilterBookmarkStateE>
}

interface GoldenRecordsToggleFilterToRuleWithOverrides {
  type: typeof TOGGLE_FILTER_TO_RULE_WITH_OVERRIDES
  ruleName: string
}

interface GoldenRecordsShowConflictDialog {
  type: typeof SHOW_CONFLICT_DIALOG
  showConflictDialog: boolean
}

interface GoldenRecordsSaveModuleInvalid {
  type: typeof SAVE_MODULE_INVALID
  saveModuleError: FetchErrorResponseWithApiException | undefined
}

interface GoldenRecordsCancelForceSaveModule {
  type: typeof CANCEL_FORCE_SAVE_MODULE
}

interface GoldenRecordsSetModuleToUpdate {
  type: typeof SET_MODULE_TO_UPDATE
  module: Document<GoldenRecordsModule.GoldenRecordsModule>
}

interface GoldenRecordsBeginConfirmingUpdateGoldenRecords {
  type: typeof BEGIN_CONFIRMING_UPDATE_GOLDEN_RECORDS
  confirmingAction: GoldenRecordsActionConfirmationTypeE
}

interface GoldenRecordsCancelConfirmingUpdateGoldenRecords {
  type: typeof CANCEL_CONFIRMING_UPDATE_GOLDEN_RECORDS
}

interface GoldenRecordsSubmitUpdateGoldenRecordsDraft {
  type: typeof SUBMIT_UPDATE_GOLDEN_RECORDS_DRAFT
}

interface GoldenRecordsSubmitUpdateGoldenRecordsDraftFailed {
  type: typeof SUBMIT_UPDATE_GOLDEN_RECORDS_DRAFT_FAILED
  data: $TSFixMe
  message: $TSFixMe
}

interface GoldenRecordsBeginConfirmingPublish {
  type: typeof BEGIN_CONFIRMING_PUBLISH
}
export function createActionGoldenRecordsBeginConfirmingPublish(): GoldenRecordsBeginConfirmingPublish {
  return { type: BEGIN_CONFIRMING_PUBLISH };
}

interface GoldenRecordsCancelConfirmingPublish {
  type: typeof CANCEL_CONFIRMING_PUBLISH
}

interface GoldenRecordsExcludeUnaccountedSourcesCompleted {
  type: typeof EXCLUDE_UNACCOUNTED_SOURCES_COMPLETED,
  moduleDoc: Document<GoldenRecordsModule.GoldenRecordsModule>
}

interface GoldenRecordsPublish {
  type: typeof PUBLISH
}

interface GoldenRecordsPublishCompleted {
  type: typeof PUBLISH_COMPLETED
}

interface GoldenRecordsPublishFailed {
  type: typeof PUBLISH_FAILED
  data: $TSFixMe
  message: $TSFixMe
}

interface GoldenRecordsChangeSort {
  type: typeof CHANGE_SORT
  field: string
}

interface GoldenRecordsPerformStaticAnalysisForRuleCompleted {
  type: typeof PERFORM_STATIC_ANALYSIS_FOR_RULE_COMPLETED
  ruleName: string
  staticAnalysisResult: OperationListResult
}

interface GoldenRecordsPerformStaticAnalysisForFilterCompleted {
  type: typeof PERFORM_STATIC_ANALYSIS_FOR_FILTER_COMPLETED
  ruleName: string
  filterIndex: number
  staticAnalysisResult: OperationListResult
}

interface GoldenRecordsFetchSourceList {
  type: typeof FETCH_SOURCE_LIST
}

interface GoldenRecordsFetchSourceListCompleted {
  type: typeof FETCH_SOURCE_LIST_COMPLETED
  sourceList: List<SourceListRecord>
  sourceListOutOfDate: boolean
  filterInfo: SourceListFilterInfo
}

interface GoldenRecordsFetchSourceListFailed {
  type: typeof FETCH_SOURCE_LIST_FAILED
  filterInfo: SourceListFilterInfo
}

interface GoldenRecordsFetchClusterProfileJob {
  type: typeof FETCH_CLUSTER_PROFILE_JOB
}

interface GoldenRecordsFetchClusterProfileJobCompleted {
  type: typeof FETCH_CLUSTER_PROFILE_JOB_COMPLETED
  clusterProfileJobOutOfDate: boolean
  previewIsUsable: boolean
  filterInfo: ClusterProfileJobFilterInfo
}

interface GoldenRecordsFetchClusterProfileJobFailed {
  type: typeof FETCH_CLUSTER_PROFILE_JOB_FAILED
  filterInfo: ClusterProfileJobFilterInfo
}

interface GoldenRecordsBeginEditingDatasetFilter {
  type: typeof BEGIN_EDITING_DATASET_FILTER
  ruleName: string
  filterIndex: number
}

interface GoldenRecordsShowReadonlyDatasetFilter {
  type: typeof SHOW_READONLY_DATASET_FILTER
  filter: DatasetFilter
}

interface GoldenRecordsCancelEditingDatasetFilter {
  type: typeof CANCEL_EDITING_DATASET_FILTER
}

interface GoldenRecordsSelectDatasetFilterSource {
  type: typeof SELECT_DATASET_FILTER_SOURCE
  sourceName: string
}

interface GoldenRecordsForceSelectDatasetFilterSource {
  type: typeof FORCE_SELECT_DATASET_FILTER_SOURCE
  sourceName: string
}

interface GoldenRecordsForceSelectDatasetFilterBucket {
  type: typeof FORCE_SELECT_DATASET_FILTER_BUCKET
  bucketId: BucketIdType
}

interface GoldenRecordsToggleDatasetFilterExcludedSelected {
  type: typeof TOGGLE_DATASET_FILTER_EXCLUDED_SELECTED
}

interface GoldenRecordsToggleDatasetFilterPrioritySelected {
  type: typeof TOGGLE_DATASET_FILTER_PRIORITY_SELECTED
  priorityIndex: number
}

interface GoldenRecordsToggleAllDatasetFilterSourcesSelected {
  type: typeof TOGGLE_ALL_DATASET_FILTER_SOURCES_SELECTED
}

interface GoldenRecordsMoveDatasetsToNewTopPriority {
  type: typeof MOVE_DATASETS_TO_NEW_TOP_PRIORITY
}

interface GoldenRecordsMoveDatasetsToNewBottomPriority {
  type: typeof MOVE_DATASETS_TO_NEW_BOTTOM_PRIORITY
}

interface GoldenRecordsMoveDatasetsToNewPriorityAbove {
  type: typeof MOVE_DATASETS_TO_NEW_PRIORITY_ABOVE
}

interface GoldenRecordsMoveDatasetsToNewPriorityBelow {
  type: typeof MOVE_DATASETS_TO_NEW_PRIORITY_BELOW
  priorityIndex: number
}

interface GoldenRecordsMoveDatasetsToPriority {
  type: typeof MOVE_DATASETS_TO_PRIORITY
  priorityIndex: number
}

interface GoldenRecordsExcludeDatasets {
  type: typeof EXCLUDE_DATASETS
}

interface GoldenRecordsBeginConfirmingRemoveSourceFromDatasetFilters {
  type: typeof BEGIN_CONFIRMING_REMOVE_SOURCE_FROM_DATASET_FILTERS
  datasetName: string
}

interface GoldenRecordsCancelConfirmingRemoveSourceFromDatasetFilters {
  type: typeof CANCEL_CONFIRMING_REMOVE_SOURCE_FROM_DATASET_FILTERS
}

interface GoldenRecordsConfirmRemoveSourceFromDatasetFilters {
  type: typeof CONFIRM_REMOVE_SOURCE_FROM_DATASET_FILTERS
}

interface GoldenRecordsSaveDatasetFilterEdits {
  type: typeof SAVE_DATASET_FILTER_EDITS
}

interface GoldenRecordsUpdateEditDatasetFilterSearchString {
  type: typeof UPDATE_EDIT_DATASET_FILTER_SEARCH_STRING
  searchString: string
}

interface GoldenRecordsSelectRows {
  type: typeof SELECT_ROWS
  selectedRowIndices: Set<number>
}

interface GoldenRecordsToggleFilterPanel {
  type: typeof TOGGLE_FILTER_PANEL
}

interface GoldenRecordsShowSelectOverrideFilterDialog {
  type: typeof SHOW_SELECT_OVERRIDE_FILTER_DIALOG
}

interface GoldenRecordsHideSelectOverrideFilterDialog {
  type: typeof HIDE_SELECT_OVERRIDE_FILTER_DIALOG
}

interface GoldenRecordsHideBookmarksOnboardingMessage {
  type: typeof HIDE_BOOKMARKS_ONBOARDING_MESSAGE
}

export const UPDATE_DESIRED_SAMPLE_CLUSTER = 'GoldenRecords.updateDesiredSampleCluster';
interface GoldenRecordsUpdateDesiredSampleCluster {
  type: typeof UPDATE_DESIRED_SAMPLE_CLUSTER
  rowNumber: number
}

interface GoldenRecordsFetchClusterSample {
  type: typeof FETCH_CLUSTER_SAMPLE
  clusterId: string
  clusterName: string
  clusterSize: number
  fetchSequence: number
}

interface GoldenRecordsFetchClusterSampleCompleted {
  type: typeof FETCH_CLUSTER_SAMPLE_COMPLETED
  table: TypedTable
  clusterId: string
}

interface GoldenRecordsFetchClusterSampleFailed {
  type: typeof FETCH_CLUSTER_SAMPLE_FAILED
  clusterId: string
}

interface GoldenRecordsToggleShowClusterSampleTable {
  type: typeof TOGGLE_SHOW_CLUSTER_SAMPLE_TABLE
}

export type GoldenRecordsActionTypes
  = GoldenRecordsClearSelection
  | GoldenRecordsFetchModule
  | GoldenRecordsFetchModuleCompleted
  | GoldenRecordsToggleSidebar
  | GoldenRecordsSaveRule
  | GoldenRecordsSaveRuleCompleted
  | GoldenRecordsSaveRuleFailed
  | GoldenRecordsSaveAllRules
  | GoldenRecordsSaveAllRulesCompleted
  | GoldenRecordsSaveAllRulesFailed
  | GoldenRecordsCancelRule
  | GoldenRecordsCancelRules
  | GoldenRecordsSetPage
  | GoldenRecordsSetPageSize
  | GoldenRecordsFetchInputDatasetSchemaCompleted
  | GoldenRecordsUpdateRule
  | GoldenRecordsDeleteRule
  | GoldenRecordsChangeRuleType
  | GoldenRecordsMoveRuleFilter
  | GoldenRecordsAddRuleFilter
  | GoldenRecordsRemoveRuleFilter
  | GoldenRecordsUpdateRuleFilter
  | GoldenRecordsCreateAttribute
  | GoldenRecordsToggleExpanded
  | GoldenRecordsExpand
  | GoldenRecordsToggleAllExpanded
  | GoldenRecordsQueryDraft
  | GoldenRecordsQueryDraftCompleted
  | GoldenRecordsQueryDraftFailed
  | GoldenRecordsFetchModuleFromLastUpdate
  | GoldenRecordsFetchModuleFromLastUpdateCompleted
  | GoldenRecordsFetchModuleFromLastUpdateFailed
  | GoldenRecordsFetchDraftRecordTotal
  | GoldenRecordsFetchDraftRecordTotalCompleted
  | GoldenRecordsFetchDraftRecordTotalFailed
  | GoldenRecordsFetchModuleFromLastPublishCompleted
  | GoldenRecordsIndexDraftJobFinished
  | GoldenRecordsSubmitUpdateSourceListJob
  | GoldenRecordsSubmitProfileJob
  | GoldenRecordsSubmitUpdateSourceListJobFailed
  | GoldenRecordsSubmitProfileJobFailed
  | GoldenRecordsSubmitUpdateClusterProfileJob
  | GoldenRecordsSubmitUpdateClusterProfileJobFailed
  | GoldenRecordsUpdateSourceListJobFinished
  | GoldenRecordsUpdateSourceListJobFinished2sAgo
  | GoldenRecordsUpdateClusterProfileJobFinished
  | GoldenRecordsUpdateClusterProfileJobFinished2sAgo
  | GoldenRecordsFetchAllOverrideStatsCompleted
  | GoldenRecordsFetchOverrideStatsForAttribute
  | GoldenRecordsUpdateOverride
  | GoldenRecordsUpdateOverrideCompleted
  | GoldenRecordsUpdateSearchString
  | GoldenRecordsClearAllFilters
  | GoldenRecordsUpdateHasOverrides
  | GoldenRecordsSetFilterBookmarks
  | GoldenRecordsToggleFilterToRuleWithOverrides
  | GoldenRecordsShowConflictDialog
  | GoldenRecordsSaveModuleInvalid
  | GoldenRecordsCancelForceSaveModule
  | GoldenRecordsSetModuleToUpdate
  | GoldenRecordsBeginConfirmingUpdateGoldenRecords
  | GoldenRecordsCancelConfirmingUpdateGoldenRecords
  | GoldenRecordsSubmitUpdateGoldenRecordsDraft
  | GoldenRecordsSubmitUpdateGoldenRecordsDraftFailed
  | GoldenRecordsBeginConfirmingPublish
  | GoldenRecordsCancelConfirmingPublish
  | GoldenRecordsExcludeUnaccountedSourcesCompleted
  | GoldenRecordsPublish
  | GoldenRecordsPublishCompleted
  | GoldenRecordsPublishFailed
  | GoldenRecordsChangeSort
  | GoldenRecordsPerformStaticAnalysisForRuleCompleted
  | GoldenRecordsPerformStaticAnalysisForFilterCompleted
  | GoldenRecordsFetchSourceList
  | GoldenRecordsFetchSourceListCompleted
  | GoldenRecordsFetchSourceListFailed
  | GoldenRecordsFetchClusterProfileJob
  | GoldenRecordsFetchClusterProfileJobCompleted
  | GoldenRecordsFetchClusterProfileJobFailed
  | GoldenRecordsBeginEditingDatasetFilter
  | GoldenRecordsShowReadonlyDatasetFilter
  | GoldenRecordsCancelEditingDatasetFilter
  | GoldenRecordsSelectDatasetFilterSource
  | GoldenRecordsForceSelectDatasetFilterSource
  | GoldenRecordsForceSelectDatasetFilterBucket
  | GoldenRecordsToggleDatasetFilterExcludedSelected
  | GoldenRecordsToggleDatasetFilterPrioritySelected
  | GoldenRecordsToggleAllDatasetFilterSourcesSelected
  | GoldenRecordsMoveDatasetsToNewTopPriority
  | GoldenRecordsMoveDatasetsToNewBottomPriority
  | GoldenRecordsMoveDatasetsToNewPriorityAbove
  | GoldenRecordsMoveDatasetsToNewPriorityBelow
  | GoldenRecordsMoveDatasetsToPriority
  | GoldenRecordsExcludeDatasets
  | GoldenRecordsBeginConfirmingRemoveSourceFromDatasetFilters
  | GoldenRecordsCancelConfirmingRemoveSourceFromDatasetFilters
  | GoldenRecordsConfirmRemoveSourceFromDatasetFilters
  | GoldenRecordsSaveDatasetFilterEdits
  | GoldenRecordsUpdateEditDatasetFilterSearchString
  | GoldenRecordsSelectRows
  | GoldenRecordsToggleFilterPanel
  | GoldenRecordsShowSelectOverrideFilterDialog
  | GoldenRecordsHideSelectOverrideFilterDialog
  | GoldenRecordsHideBookmarksOnboardingMessage
  | GoldenRecordsUpdateDesiredSampleCluster
  | GoldenRecordsFetchClusterSample
  | GoldenRecordsFetchClusterSampleCompleted
  | GoldenRecordsFetchClusterSampleFailed
  | GoldenRecordsToggleShowClusterSampleTable
