import './Input.scss';

import PropTypes, { InferProps } from 'prop-types';
import React from 'react';

/**
 * Thin wrapper around <input> which pulls the value out of the change event.
 * Passes all non-specified <input> props directly through to inner <input>
 * Uses the floating label pattern to identify the input, see
 * http://mds.is/float-label-pattern/ for more info
 */

const propTypes = {
  // autoFocus this input when it mounts
  onSetFile: PropTypes.func.isRequired as Required<(event: React.ChangeEvent<HTMLInputElement>) => void>,

  // open the file picker on mount
  openOnMount: PropTypes.bool,

  // the currently selected file
  selectedFile: PropTypes.instanceOf(File),
};

export default class FileInput extends React.Component<InferProps<typeof propTypes>> {
  static propTypes = propTypes;

  uploadFileRef = React.createRef<HTMLInputElement>();
  componentDidMount() {
    // open the upload file dialog after the component mounts
    if (this.props.openOnMount) {
      // @ts-expect-error
      this.uploadFileRef.current.click();
    }
  }

  render() {
    const { selectedFile, onSetFile } = this.props;
    return <div style={{ textAlign: selectedFile ? 'left' : 'center' }}>
      <label htmlFor="files" className="btn btn-primary" style={{ width: 'fit-content' }}>{selectedFile ? 'Change file' : 'Choose file'}</label>
      <input
        ref={this.uploadFileRef}
        id="files"
        type="file"
        name="file"
        className="upload-file"
        style={{ visibility: 'hidden', display: 'none' }}
        onChange={onSetFile}
      />
      {selectedFile ? <span style={{ marginLeft: '8px' }}>{selectedFile.name}</span> : null}
    </div>;
  }
}
