import classNames from 'classnames';
import { List, Set } from 'immutable';
import React from 'react';

import fillInfo from '../../images/transformation-fill.svg';
import formulaInfo from '../../images/transformation-formula.svg';
import multiFormulaInfo from '../../images/transformation-multiformula.svg';
import scriptInfo from '../../images/transformation-script.svg';
import unpivotInfo from '../../images/transformation-unpivot.svg';
import DocLink from '../components/DocLink';
import HoverTrigger from '../components/HoverTrigger';
import Selector from '../components/Selector';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
// @ts-expect-error
import Transforms from '../models/Transforms';
import Lint from './models/Lint';
import style from './Transform.module.scss';
import TransformErrorsIcon from './TransformErrorsIcon';
import { ValidationErrorE } from './ValidationError';

export const FILL = 'Fill';
export const FORMULA = 'Formula';
export const SCRIPT = 'Script';
export const UNPIVOT = 'Unpivot';
export const MULTI_FORMULA = 'MultiFormula';

const TransformTypeValues = {
  FILL,
  FORMULA,
  MULTI_FORMULA,
  SCRIPT,
  UNPIVOT,
} as const;
export type TransformTypeE = typeof TransformTypeValues[keyof typeof TransformTypeValues];

export const getIcon = (type: TransformTypeE) => {
  switch (type) {
    case FILL:
      return 'tamr-icon-fill';
    case FORMULA:
      return 'tamr-icon-fx';
    case MULTI_FORMULA:
      return 'tamr-icon-fx-multi';
    case SCRIPT:
      return 'tamr-icon-script';
    case UNPIVOT:
      return 'tamr-icon-unpivot';
    default:
      throw new Error('Unrecognized type: ' + type);
  }
};

const getExample = (type: TransformTypeE) => {
  switch (type) {
    case FILL:
      return fillInfo;
    case FORMULA:
      return formulaInfo;
    case MULTI_FORMULA:
      return multiFormulaInfo;
    case SCRIPT:
      return scriptInfo;
    case UNPIVOT:
      return unpivotInfo;
    default:
      throw new Error('Unrecognized type: ' + type);
  }
};

const getInfo = (type: TransformTypeE) => {
  switch (type) {
    case FILL:
      return 'Replace values within one column based on one condition.';
    case FORMULA:
      return 'Assign values to a single output column by writing code.';
    case MULTI_FORMULA:
      return 'Assign values to multiple output columns by writing code.';
    case SCRIPT:
      return 'Write code to perform a variety of operations.';
    case UNPIVOT:
      return 'Reshape columns into rows.';
    default:
      throw new Error('Unrecognized type: ' + type);
  }
};

// Map of transformation class to path in our readthedocs instance, e.g.
// https://tamr-documentation.readme.io/v0.20/docs/fill
const getDocUrlPath = (type: TransformTypeE) => {
  switch (type) {
    case FILL:
      return 'fill';
    case FORMULA:
      return 'formulas';
    case MULTI_FORMULA:
      return 'multi-formulas';
    case SCRIPT:
      return 'transformation-scripts';
    case UNPIVOT:
      return 'unpivot';
    default:
      throw new Error('Unrecognized type: ' + type);
  }
};

const options = [FILL, FORMULA, MULTI_FORMULA, SCRIPT, UNPIVOT].map(key => ({ value: key, label: key }));

interface TransformSelectorProps {
  className?: string,
  errors: List<Lint>,
  isDisabled?: boolean,
  modified?: boolean,
  name: TransformTypeE,
  onChange?: (type: { value: string, key: string }) => void,
  onOpen?: () => void,
  placeholder?: string,
  validationErrors: Set<ValidationErrorE>,
}

class TransformSelector extends React.Component<TransformSelectorProps> {
  static defaultProps = {
    errors: List(),
    validationErrors: Set(),
    placeholder: 'Select Transformation',
  };

  notifications = () => {
    const { modified, errors: lintingErrors, validationErrors, isDisabled } = this.props;
    const errors = lintingErrors.map(e => e.message).concat(validationErrors);
    if (errors && errors.size > 0) {
      return <TransformErrorsIcon errorMessages={errors} className={style.notification} isDisabled={isDisabled} />;
    }
    if (modified) {
      return (
        <div className={classNames(style.notification, style.warning, { [style.isDisabled]: isDisabled })}>
          <TamrIcon className={style.icon} iconName="tamr-icon-warning" size={16} />
          draft
        </div>
      );
    }
  };

  renderTitle = ({ value }: { key: TransformTypeE, value: TransformTypeE }) => {
    if (!value) {
      return (<div className={style.title} />);
    }

    const trigger = (
      <div className={style.titleRow}>
        <TamrIcon className={style.icon} iconName={getIcon(value)} size={22} />
        {value}
      </div>
    );

    return (
      <div className={classNames(style.title, { [style.isDisabled]: this.props.isDisabled })}>
        {
          this.props.isDisabled ? (
            <TooltipTrigger
              content={'This transformation is not included in your current preview.'}
              placement="left"
            >
              {trigger}
            </TooltipTrigger>
          ) : (
            <HoverTrigger
              className={style.tooltipBackground}
              disablePopover={this.props.isDisabled}
              placement="left"
              content={
                <div className={style.txInstructions}>
                  <div className={style.txInstructionsTitle}><span>{value}</span></div>
                  <div className={style.txInstructionsInfo}>
                    <div>{getInfo(value)}</div>
                    <DocLink
                      path={getDocUrlPath(value) || ''}
                      onMouseDown={(e) => {
                        // The Selector component closes on mousedown.
                        // The event needs to be stopped to allow the doc url click to go through.
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      Learn more
                    </DocLink>
                  </div>
                  <div>
                    <img src={getExample(value)} />
                  </div>
                </div>
              }
            >
              {trigger}
            </HoverTrigger>
          )
        }
        <div className={style.notification}>
          {this.notifications()}
        </div>
      </div>
    );
  };

  render() {
    const { name, onChange, placeholder, className, onOpen } = this.props;

    // Render just the icon and title
    if (!onChange) {
      return this.renderTitle({ value: name, key: name });
    }

    // Render the selector
    return (
      <Selector
        {...{ placeholder, onOpen }}
        className={classNames(style.transformSelector, className)}
        value={name}
        onChange={({ value }: { value: TransformTypeE, key: TransformTypeE }) => name !== value && onChange(new (Transforms[value])())}
        options={options}
        optionRenderer={(option : { value: TransformTypeE, key: TransformTypeE }) => this.renderTitle(option)}
        valueRenderer={(option : { value: TransformTypeE, key: TransformTypeE }) => this.renderTitle(option)}
        clearable={false}
      />
    );
  }
}

export default TransformSelector;
