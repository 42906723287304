import React from 'react';

import { SidebarSection } from '../../components/DetailSidebar/DetailSidebar';
import ScoreIcon from '../../components/ScoreIcon';
import EsRecord from '../../models/EsRecord';
import ProcurementTaxonomy from '../../models/ProcurementTaxonomy';
import ScoreThresholds from '../../models/ScoreThresholds';
import PRODUCT_NAME from '../../utils/ProductName';
import CategoryFeedbackSidebar from '../CategoryFeedbackSidebar';
import styles from './CategorizationSection.module.scss';


const CategorizationSection: React.FC<{
  isUserACurator: boolean,
  isUserAVerifier: boolean,
  recipeId: number,
  record: EsRecord,
  strengthThresholds: ScoreThresholds,
  taxonomy: ProcurementTaxonomy
}> = (props) => {
  const { isUserACurator, isUserAVerifier, recipeId, record, strengthThresholds, taxonomy } = props;
  const { suggestedCategorization } = record;
  if (!suggestedCategorization || !taxonomy) {
    return null;
  }
  return (
    <SidebarSection title={(
      <span>
        {PRODUCT_NAME} Categorization
        {suggestedCategorization && strengthThresholds && (
          <div className={styles.scoreIconContainer}>
            <ScoreIcon
              className={styles.scoreIcon}
              score={suggestedCategorization.score || undefined}
              scoreThresholds={strengthThresholds}
              titleNoun="Suggestion Confidence"
            />
          </div>
        )}
      </span>
    )}>
      <CategoryFeedbackSidebar
        {...{ taxonomy, recipeId, record, isUserACurator, isUserAVerifier }}
        category={taxonomy.findCategoryById(suggestedCategorization.categoryId)}
        isSystemCategorization
      />
    </SidebarSection>
  );
};

export default CategorizationSection;
