const AuthUserSortField = {
  USERNAME: 'USERNAME',
  GIVEN: 'GIVEN',
  SURNAME: 'SURNAME',
  EMAIL: 'EMAIL',
  LOGIN_TIME: 'LOGIN_TIME',
} as const;
export type AuthUserSortFieldE = typeof AuthUserSortField[keyof typeof AuthUserSortField];

export default AuthUserSortField;
