import { List, Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import BulletedTooltipTrigger from '../../components/BulletedTooltipTrigger';
import ConditionalButton from '../../components/ConditionalButton';
import RecipeOperations from '../../constants/RecipeOperations';
import ProjectInfo from '../../models/ProjectInfo';
import { AppDispatch, AppState } from '../../stores/MainStore';
import { isJobRunning, selectActiveProjectInfo } from '../../utils/Selectors';
import styles from './EstimateButton.module.scss';
import * as slice from './slice';

const mapState = (state: AppState) => {
  const projectInfo = selectActiveProjectInfo(state);
  const isComputingMetrics = isJobRunning(state, {
    recipeOperation: RecipeOperations.COMPUTE_CLUSTERS_ACCURACY,
    forActiveProject: true,
  });
  const { isSubmittingJob } = state.clusters.metrics;
  const { verified } = state.suppliers.recordsVerificationFilterTotals;
  return {
    projectInfo,
    isSubmittingJob,
    isComputingMetrics,
    verifiedRecordsCount: verified,
    totalTestClustersVerified: state.suppliers.totalTestClustersVerified,
  };
};

const mapDispatch = (dispatch: AppDispatch) => {
  return {
    computeMetrics: (projectInfo: ProjectInfo) => dispatch(slice.submitComputeMetricsJob(projectInfo)),
  };
};

type StateProps = ReturnType<typeof mapState>;
type DispatchProps = ReturnType<typeof mapDispatch>;
type Props = StateProps & DispatchProps

const EstimateButton: React.FC<Props> = ({
  children,
  projectInfo,
  isSubmittingJob,
  isComputingMetrics,
  computeMetrics,
  totalTestClustersVerified,
}) => {
  if (!projectInfo) {
    return null;
  }
  const preconditions = Map({
    'Verify more high impact clusters to enable cluster metrics': totalTestClustersVerified > 9,
    'There is already a job submitted to estimate cluster metrics': !isSubmittingJob,
    'There is already a job running to estimate cluster metrics': !isComputingMetrics,
  });
  const enabled = preconditions.valueSeq().every(x => x);
  if (enabled) {
    return <BulletedTooltipTrigger
      placement="bottom"
      items={List.of('Verify as many high impact clusters as possible for best results')}>
      <div>
        <ConditionalButton
          preconditions={preconditions}
          buttonType="Secondary"
          onClick={() => computeMetrics(projectInfo)}>
          <div className={styles.enabled}>
            {children}
          </div>
        </ConditionalButton>
      </div>
    </BulletedTooltipTrigger>;
  }

  return <ConditionalButton
    preconditions={preconditions}
    buttonType="Secondary"
    onClick={() => computeMetrics(projectInfo)}>
    <div className={styles.disabled}>
      {children}
    </div>
  </ConditionalButton>;
};

export default connect(mapState, mapDispatch)(EstimateButton);
