import { is, List, Map, Record, Set } from 'immutable';
import { InferType } from 'prop-types';
import queryString from 'query-string';
import { createSelector } from 'reselect';
import _ from 'underscore';

import { SCHEMA_MAPPING_RECOMMENDATIONS } from '../constants/RecipeType';
import { REMOVE_DATASETS_FROM_PROJECT } from '../datasets/ProjectDatasetCatalogActionTypes';
import {
  FINISHED_PROFILING,
  SCHEMA_MAPPING_RECOMMENDATIONS_JOB_COMPLETED,
} from '../job/JobsActionTypes';
import AttributeId, { attributeId } from '../models/AttributeId';
import BootstrapResponse from '../models/BootstrapResponse';
import Document from '../models/doc/Document';
import KeyMods from '../models/KeyMods';
import MappingChange from '../models/MappingChange';
import MappingRecommendation, {
  DO_NOT_MAP_NAME,
  getDisplaySimilarity,
} from '../models/MappingRecommendation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import ProfiledAttribute from '../models/ProfiledAttribute';
import ProfilingInfo from '../models/ProfilingInfo';
import ProjectInfo from '../models/ProjectInfo';
import Recipe from '../models/Recipe';
import RecipeWithStatus from '../models/RecipeWithStatus';
import SourceAttribute from '../models/SourceAttribute';
import UnifiedAttribute from '../models/UnifiedAttribute';
import { StoreReducers } from '../stores/AppAction';
import { PROJECT_CHANGE } from '../stores/LocationActionTypes';
import { AppState } from '../stores/MainStore';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { routes } from '../utils/Routing';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { $TSFixMe } from '../utils/typescript';
import { maybeSet, parseNumber, parseSet, parseString } from '../utils/Url';
import { getPath, resetExcept, sliceBounds, toggleHas } from '../utils/Values';
import {
  BOOTSTRAP_UNIFIED_ATTRIBUTES_COMPLETED,
  BULK_ALLOW_MAPPING_COMPLETED,
  BULK_DO_NOT_MAP_COMPLETED,
  BULK_MAP_RECOMMENDATIONS_COMPLETED,
  BULK_UNMAP_COMPLETED,
  CANCEL_DELETE_UNIFIED_ATTRIBUTES,
  CANCEL_UNIFIED_ATTRIBUTE_ACTION,
  CHECK_SMR_MODEL_EXPORTABLE,
  CHECK_SMR_MODEL_EXPORTABLE_COMPLETED,
  CHECK_SMR_MODEL_EXPORTABLE_FAILED,
  CLEAR_BOOTSTRAP_NAME_VALIDATION_ERRORS,
  CLEAR_FILTER_RELATED_ID,
  CLOSE_SOURCE_DATASET_FILTER_DIALOG,
  CLOSE_UNIFIED_DATASET_FILTER_DIALOG,
  CONFIRM_BULK_DELETE_ATTRIBUTES,
  CREATE_UNIFIED_ATTRIBUTE_COMPLETED,
  DELETE_UNIFIED_ATTRIBUTES_COMPLETED,
  FETCH_ALL_UNIFIED_ATTRIBUTES,
  FETCH_ALL_UNIFIED_ATTRIBUTES_COMPLETED,
  FETCH_ALL_UNIFIED_ATTRIBUTES_FAILED,
  FETCH_SOURCE_ATTRIBUTES,
  FETCH_SOURCE_ATTRIBUTES_COMPLETED,
  FETCH_SOURCE_ATTRIBUTES_FAILED,
  FETCH_UNIFIED_ATTRIBUTES,
  FETCH_UNIFIED_ATTRIBUTES_COMPLETED,
  FETCH_UNIFIED_ATTRIBUTES_FAILED,
  HIDE_BULK_DO_NOT_MAP_DIALOG,
  HIDE_BULK_UNMAP_DIALOG,
  HIDE_CONFIRM_BULK_RECS_DIALOG,
  HIDE_UPDATE_UNIFIED_DATASET_DIALOG,
  MAP_ATTRIBUTES_COMPLETED,
  OPEN_SOURCE_DATASET_FILTER_DIALOG,
  OPEN_UNIFIED_DATASET_FILTER_DIALOG,
  PROMPT_DO_NOT_MAP_CONFIRMATION,
  RESET_ATTRIBUTES,
  RESET_SOURCE_FILTER_DATASETS,
  RESET_UNIFIED_FILTER_DATASETS,
  SET_HAS_VALIDATION_ERRORS,
  SET_HEADER_SCROLL_BAR_OFFSET,
  SET_REQUIRED_ATTRIBUTE_TYPE_COMPLETED,
  SET_SIMILARITY_THRESHOLD,
  SET_SOURCE_ATTRIBUTES_WITH_TIEDS_RECS,
  SET_SOURCE_FILTER_DATASETS,
  SET_SOURCE_PAGE_NUM,
  SET_SOURCE_PAGE_SIZE,
  SET_UNIFIED_FILTER_DATASETS,
  SHOW_BULK_DO_NOT_MAP_DIALOG,
  SHOW_BULK_UNMAP_DIALOG,
  SHOW_CONFIRM_BULK_RECS_DIALOG,
  SHOW_UPDATE_UNIFIED_DATASET_DIALOG,
  SOURCE_BEGIN_DRAG,
  SOURCE_RESET_SOURCE_AND_UNIFIED_ATTRIBUTES,
  SOURCE_SEARCH,
  SOURCE_SELECT_ATTRIBUTE,
  SOURCE_TOGGLE_EXPAND_ALL,
  SOURCE_TOGGLE_EXPANDED,
  SOURCE_TOGGLE_FILTER_DNM,
  SOURCE_TOGGLE_FILTER_MAPPED,
  SOURCE_TOGGLE_FILTER_RELATED_ID,
  SOURCE_TOGGLE_FILTER_UNMAPPED,
  TOGGLE_GEO_ATTRIBUTE_COMPLETED,
  TOGGLE_ML_ENABLED_COMPLETED,
  TOGGLE_SORT_TYPE_COMPLETED,
  TOGGLE_UNIFIED_ATTRIBUTE_MENU_EXPANDED,
  UNIFIED_BEGIN_DRAG,
  UNIFIED_SEARCH,
  UNIFIED_SELECT_ATTRIBUTE,
  UNIFIED_TOGGLE_EXPAND_ALL,
  UNIFIED_TOGGLE_EXPANDED,
  UNIFIED_TOGGLE_FILTER_MAPPED,
  UNIFIED_TOGGLE_FILTER_RELATED_ID,
  UNIFIED_TOGGLE_FILTER_UNMAPPED,
  UPDATE_MAPPABLE_COMPLETED,
  UPDATE_NUMERIC_FIELD_RESOLUTION_COMPLETED,
  UPDATE_SIMILARITY_FUNCTION_COMPLETED,
  UPDATE_SOURCE_DESCRIPTION_COMPLETED,
  UPDATE_SOURCE_FILTER_DATASETS,
  UPDATE_TOKENIZER_CONFIG_COMPLETED,
  UPDATE_UNIFIED_NAME_AND_DESCRIPTION_COMPLETED,
} from './SchemaMappingActionTypes';

export function getRecommendationsRecipeWithStatus(projectInfo: ProjectInfo): RecipeWithStatus | undefined {
  const recipesWithStatus = getPath(projectInfo, 'recipesWithStatus');
  if (recipesWithStatus) {
    return recipesWithStatus.find((rws : RecipeWithStatus) => rws.recipe.data.type === SCHEMA_MAPPING_RECOMMENDATIONS);
  }
}

export function getRecommendationsRecipeDoc(projectInfo: ProjectInfo): Document<Recipe> | undefined {
  return getPath(getRecommendationsRecipeWithStatus(projectInfo), 'recipe');
}

export class SchemaMappingStore extends getModelHelpers({
  sourceAttributes: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(SourceAttribute)), defaultValue: List<SourceAttribute>() },
  // used for greyed out schema mapping rows
  nextSourceAttributes: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(SourceAttribute)), defaultValue: List<SourceAttribute>() },
  sourceAttributesRemoved: { type: ArgTypes.Immutable.set.of(ArgTypes.instanceOf(AttributeId)), defaultValue: Set<AttributeId>() },
  unifiedAttributes: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(UnifiedAttribute)), defaultValue: List<UnifiedAttribute>() },
  // used for greyed out schema mapping rows
  nextUnifiedAttributes: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(UnifiedAttribute)), defaultValue: List<UnifiedAttribute>() },
  unifiedAttributesRemoved: { type: ArgTypes.Immutable.set.of(ArgTypes.instanceOf(AttributeId)), defaultValue: Set<AttributeId>() },
  allUnifiedAttributes: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(UnifiedAttribute)), defaultValue: List<UnifiedAttribute>() },
  sourceExpanded: { type: ArgTypes.Immutable.set.of(ArgTypes.instanceOf(AttributeId)), defaultValue: Set<AttributeId>() },
  unifiedExpanded: { type: ArgTypes.Immutable.set.of(ArgTypes.instanceOf(AttributeId)), defaultValue: Set<AttributeId>() },
  sourceFilterMapped: { type: ArgTypes.bool, defaultValue: false },
  sourceFilterUnmapped: { type: ArgTypes.bool, defaultValue: false },
  sourceFilterDNM: { type: ArgTypes.bool, defaultValue: false },
  unifiedFilterMapped: { type: ArgTypes.bool, defaultValue: false },
  unifiedFilterUnmapped: { type: ArgTypes.bool, defaultValue: false },
  sourceFilterRelatedId: { type: ArgTypes.nullable(ArgTypes.instanceOf(AttributeId)) },
  unifiedFilterRelatedId: { type: ArgTypes.nullable(ArgTypes.instanceOf(AttributeId)) },
  sourceLoading: { type: ArgTypes.bool, defaultValue: false },
  unifiedLoading: { type: ArgTypes.bool, defaultValue: false },
  allUnifiedLoading: { type: ArgTypes.bool, defaultValue: false },
  sourceShowSpinner: { type: ArgTypes.bool, defaultValue: false },
  unifiedShowSpinner: { type: ArgTypes.bool, defaultValue: false },
  sourceHasNextPage: { type: ArgTypes.bool, defaultValue: false },
  sourcePageSize: { type: ArgTypes.number, defaultValue: 1000 },
  sourcePageNum: { type: ArgTypes.number, defaultValue: 0 },
  sourceSelectedIds: { type: ArgTypes.Immutable.set.of(ArgTypes.instanceOf(AttributeId)), defaultValue: Set<AttributeId>() },
  unifiedSelectedIds: { type: ArgTypes.Immutable.set.of(ArgTypes.instanceOf(AttributeId)), defaultValue: Set<AttributeId>() },
  sourceLastSelectedId: { type: ArgTypes.nullable(ArgTypes.instanceOf(AttributeId)) },
  unifiedLastSelectedId: { type: ArgTypes.nullable(ArgTypes.instanceOf(AttributeId)) },
  sourceFilterDatasets: { type: ArgTypes.Immutable.set.of(ArgTypes.any), defaultValue: Set<$TSFixMe>() },
  unifiedFilterDatasets: { type: ArgTypes.Immutable.set.of(ArgTypes.any), defaultValue: Set<$TSFixMe>() },
  sourceSearchTerm: { type: ArgTypes.string, defaultValue: '' },
  unifiedSearchTerm: { type: ArgTypes.string, defaultValue: '' },
  sourceProfilingInfo: { type: ArgTypes.Immutable.map.of(ArgTypes.instanceOf(ProfilingInfo), ArgTypes.string), defaultValue: Map<string, ProfilingInfo>() },
  sourceLoadedFilterInfo: { type: ArgTypes.any },
  unifiedLoadedFilterInfo: { type: ArgTypes.any },
  allUnifiedLoadedFilterInfo: { type: ArgTypes.any },
  sourceSequence: { type: ArgTypes.number, defaultValue: 0 },
  unifiedSequence: { type: ArgTypes.number, defaultValue: 0 },
  confirmDeleteAttributeIds: { type: ArgTypes.Immutable.set.of(ArgTypes.instanceOf(AttributeId)), defaultValue: Set<AttributeId>() },
  similarityThreshold: { type: ArgTypes.number, defaultValue: 0.8 },
  recommendationsLoading: { type: ArgTypes.bool, defaultValue: false },
  recommendationsLoadedFilterInfo: { type: ArgTypes.any },
  showConfirmBulkRecsWarning: { type: ArgTypes.orUndefined(ArgTypes.object.withShape({ mapToTop: ArgTypes.bool })) },
  sourceAttributesWithTiedRecs: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(SourceAttribute)), defaultValue: List<SourceAttribute>() },
  showUnmapWarning: { type: ArgTypes.bool, defaultValue: false },
  showDoNotMapWarning: { type: ArgTypes.bool, defaultValue: false },
  hasValidationErrors: { type: ArgTypes.bool, defaultValue: false },
  checkSourceFilterViolations: { type: ArgTypes.bool, defaultValue: false },
  checkUnifiedFilterViolations: { type: ArgTypes.bool, defaultValue: false },
  confirmingUpdateUnifiedDataset: { type: ArgTypes.bool, defaultValue: false },
  // this is the right offset in pixels introduced by the scrollbar in the attribute list
  headerScrollbarOffset: { type: ArgTypes.number, defaultValue: 0 },
  unifiedAttributeMenuExpanded: { type: ArgTypes.bool, defaultValue: false },
  sourceDatasetFilterDialogVisible: { type: ArgTypes.bool, defaultValue: false },
  unifiedDatasetFilterDialogVisible: { type: ArgTypes.bool, defaultValue: false },
  bootstrapNameValidationErrors: { type: ArgTypes.Immutable.map.of(ArgTypes.Immutable.list.of(ArgTypes.string), ArgTypes.string), defaultValue: Map<string, List<string>>() },
  hasSmrModel: { type: ArgTypes.bool, defaultValue: false },
  hasSmrModelLoaded: { type: ArgTypes.bool, defaultValue: false },
  hasSmrModelLoading: { type: ArgTypes.bool, defaultValue: false },
}, 'SchemaMappingStore')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class SchemaMappingStoreRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  reduce(reduceFunction: Function, accumulator: any) {
    Array.from(this.toSeq().entries()).forEach(entry => {
      accumulator = reduceFunction(accumulator, entry[1], entry[0]);
    });
    return accumulator;
  }
}

export function sourceAttributeFilterOn(state: AppState): boolean {
  const { schemaMapping: {
    sourceFilterDNM,
    sourceFilterMapped,
    sourceFilterUnmapped,
    sourceSearchTerm,
    sourceFilterDatasets,
    unifiedFilterRelatedId,
  } } = state;
  return !!(
    sourceFilterMapped ||
    sourceFilterDNM ||
    sourceFilterUnmapped ||
    sourceSearchTerm ||
    sourceFilterDatasets.size ||
    unifiedFilterRelatedId
  );
}

export function unifiedAttributeFilterOn(state: AppState): boolean {
  const { schemaMapping: {
    unifiedFilterDatasets,
    unifiedFilterMapped,
    unifiedFilterUnmapped,
    sourceFilterRelatedId,
    unifiedSearchTerm,
  } } = state;
  return !!(
    unifiedFilterDatasets.size ||
    unifiedSearchTerm ||
    unifiedFilterMapped ||
    unifiedFilterUnmapped ||
    sourceFilterRelatedId
  );
}

function hasTiedSimilarity(r1: MappingRecommendation, r2: MappingRecommendation): boolean {
  return (getDisplaySimilarity(r1.similarity) === getDisplaySimilarity(r2.similarity));
}

export function getSortedRecs(sa: SourceAttribute): List<MappingRecommendation> {
  return sa.recommendations
    .filterNot(r => sa.mappedAttributes.contains(r.recommended))
    .sortBy(m => -m.similarity);
}

export function getTiedRecs(sortedRecs: List<MappingRecommendation>): List<MappingRecommendation> {
  return sortedRecs.filter(_.partial(hasTiedSimilarity, sortedRecs.first()));
}

const BulkRecommendChanges = Record({
  toDnm: undefined as List<SourceAttribute> | undefined,
  toMap: undefined as List<SourceAttribute | MappingChange> | undefined,
  conflicts: undefined as Set<SourceAttribute> | undefined,
  attrsWithTiedRecs: undefined as List<SourceAttribute> | undefined,
  mappedButRecommendedDnm: undefined as List<SourceAttribute> | undefined,
});

type BulkRecommendChangesT = InferType<typeof BulkRecommendChanges>;

export function getBulkRecommendChanges(
  attributes: List<SourceAttribute>,
  unifiedDatasetName: string,
  mapToTop = false,
): BulkRecommendChangesT {
  checkArg({ attributes }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(SourceAttribute)));
  checkArg({ unifiedDatasetName }, ArgTypes.orUndefined(ArgTypes.string));
  checkArg({ mapToTop }, ArgTypes.bool);

  let toDnm = List<SourceAttribute>();
  let toMap = List<SourceAttribute | MappingChange>();
  let attrsWithTiedRecs = List<SourceAttribute>();
  let conflicts = Set<SourceAttribute>();

  const isDoNotMap = (r: MappingRecommendation): boolean => r.recommended.name === DO_NOT_MAP_NAME;

  for (const sa of attributes) {
    if (sa.doNotMap.contains(unifiedDatasetName)) {
      continue; // don't want to make any mapping changes since we're already DNM
    }

    let recommendations = getSortedRecs(sa);
    if (mapToTop && !recommendations.isEmpty()) {
      if (getTiedRecs(recommendations).size === 1) {
        recommendations = List.of(recommendations.first());
      } else {
        attrsWithTiedRecs = attrsWithTiedRecs.push(sa);
        continue;
      }
    }

    const indexOfDnm = recommendations.findIndex(isDoNotMap);
    if (indexOfDnm === 0) { // DNM is the top suggestion, so DNM
      toDnm = toDnm.push(sa);
    } else {
      toMap = toMap.concat(
        recommendations
          .filterNot(isDoNotMap)
          .map(r => MappingChange.map(attributeId(sa), r.recommended)),
      );
    }
    // a conflict occurs when the user has decided to map a selection of attributes
    // to all recommendations, but the current set of recommendations includes _both_
    // one or more unified attributes, and "do not map". In this case, we will
    // select DNM if it is the top suggestion by similarity, and otherwise map
    // to the other suggested UAs. However, we need to collect these cases so that
    // we can ask the user for confirmation.
    if (indexOfDnm !== -1 && recommendations.size > 1) {
      conflicts = conflicts.add(sa);
    }
  }

  // if we've recommended that an attribute should be "do not map", but it is _already_
  // mapped, then we need to warn the user that mappings will be removed as a result
  // of their action.
  const mappedButRecommendedDnm = toDnm.filter(sa => !sa.mappedAttributes.isEmpty());
  return new BulkRecommendChanges({ toDnm, toMap, conflicts, attrsWithTiedRecs, mappedButRecommendedDnm });
}

export function getSourceAttributesAllOnFirstPage(state: AppState): boolean {
  const { schemaMapping: { sourcePageNum, sourceHasNextPage } } = state;
  return !(sourcePageNum || sourceHasNextPage || sourceAttributeFilterOn(state));
}

export function getNoUnifiedAttributesInSystem(state: AppState): boolean {
  const { schemaMapping: { unifiedAttributes } } = state;
  return !(unifiedAttributes.size || unifiedAttributeFilterOn(state));
}

export function getNoSourceAttributesInSystem(state: AppState): boolean {
  const { schemaMapping: { sourceAttributes } } = state;
  return getSourceAttributesAllOnFirstPage(state) && !sourceAttributes.size;
}

export function getSourceProfiling(
  state: AppState,
  datasetName: string,
): { schema: List<ProfiledAttribute>, upToDate: boolean } {
  checkArg({ datasetName }, ArgTypes.string);
  const { schemaMapping: { sourceProfilingInfo } } = state;
  const profilingInfo = sourceProfilingInfo.get(datasetName) || {};
  const schema = getPath(profilingInfo, 'schema') || List<ProfiledAttribute>();
  const upToDate = getPath(profilingInfo, 'upToDate') || false;
  return { schema, upToDate };
}

const SourceFilterInfo = Record({
  sourceSequence: 0,
  recipeId: undefined as number | undefined,
  sourceFilterDatasets: undefined as Set<$TSFixMe> | undefined,
  sourceFilterMapped: false,
  sourceFilterUnmapped: false,
  sourcePageSize: 1000,
  sourcePageNum: 0,
  sourceFilterDNM: false,
  similarityThreshold: 0.0,
  sourceSearchTerm: '',
  unifiedFilterRelatedId: undefined as AttributeId | undefined,
  smRecipeId: undefined as number | undefined,
  unifiedDatasetName: undefined as string | undefined,
});

export type SourceFilterInfoT = InferType<typeof SourceFilterInfo>;

export function getSourceFilterInfo(state: AppState): SourceFilterInfoT {
  const projectInfo = selectActiveProjectInfo(state);
  const {
    schemaMapping: {
      sourceSequence,
      sourceFilterDatasets,
      sourceFilterMapped,
      sourceFilterUnmapped,
      sourceFilterDNM,
      sourcePageSize,
      sourcePageNum,
      similarityThreshold,
      sourceSearchTerm,
      unifiedFilterRelatedId,
    },
    location: { recipeId },
  } = state;

  return new SourceFilterInfo({
    sourceSequence,
    recipeId,
    sourceFilterDatasets,
    sourceFilterMapped,
    sourceFilterUnmapped,
    sourcePageSize,
    sourcePageNum,
    sourceFilterDNM,
    similarityThreshold,
    sourceSearchTerm,
    unifiedFilterRelatedId: unifiedFilterRelatedId || undefined,
    smRecipeId: projectInfo?.smRecipeId,
    unifiedDatasetName: projectInfo?.unifiedDataset?.name,
  });
}

const AllUnifiedFilterInfo = Record({
  unifiedSequence: 0,
  unifiedDatasetName: undefined as string | undefined,
});

type AllUnifiedFilterInfoT = InferType<typeof AllUnifiedFilterInfo>;

export function getAllUnifiedFilterInfo(state: AppState): AllUnifiedFilterInfoT {
  const { schemaMapping: { unifiedSequence } } = state;
  const projectInfo = selectActiveProjectInfo(state);
  return new AllUnifiedFilterInfo({
    unifiedSequence,
    unifiedDatasetName: projectInfo?.unifiedDataset?.name,
  });
}

const UnifiedFilterInfo = Record({
  unifiedSequence: 0,
  unifiedFilterDatasets: Set(),
  unifiedFilterMapped: false,
  unifiedFilterUnmapped: false,
  sourceFilterRelatedId: undefined as AttributeId | undefined,
  unifiedSearchTerm: '',
  similarityThreshold: 0.0,
  unifiedDatasetName: undefined as string | undefined,
});

type UnifiedFilterInfoT = InferType<typeof UnifiedFilterInfo>;

export function getUnifiedFilterInfo(state: AppState): UnifiedFilterInfoT {
  const { schemaMapping: {
    unifiedSequence,
    unifiedFilterDatasets,
    unifiedFilterMapped,
    unifiedFilterUnmapped,
    sourceFilterRelatedId,
    unifiedSearchTerm,
    similarityThreshold,
  } } = state;
  const projectInfo = selectActiveProjectInfo(state);
  return new UnifiedFilterInfo({
    unifiedSequence,
    unifiedFilterDatasets,
    unifiedFilterMapped,
    unifiedFilterUnmapped,
    sourceFilterRelatedId: sourceFilterRelatedId || undefined,
    unifiedSearchTerm,
    similarityThreshold,
    unifiedDatasetName: projectInfo?.unifiedDataset?.name,
  });
}

export const selectSourceMappings = createSelector(
  (state: SchemaMappingStore): List<SourceAttribute> => state.sourceAttributes,
  (sourceAttributes) => {
    return Map(sourceAttributes.flatMap(attr => {
      const sid = attributeId(attr);
      return attr.mappedAttributes.map(uid => [sid, uid]);
    }).groupBy(([sid]) => sid).map(pairs => pairs.map(([, uid]) => uid).toSet()));
  },
);

// TODO: this is just a pass-through now. Remove.
export const filteredSourceAttributes = createSelector(
  (state: SchemaMappingStore): List<SourceAttribute> => state.sourceAttributes,
  sourceAttributes => sourceAttributes,
);

// TODO: this is just a pass-through now. Remove.
export const filteredUnifiedAttributes = createSelector(
  (state: SchemaMappingStore): List<UnifiedAttribute> => state.unifiedAttributes,
  unifiedAttributes => unifiedAttributes,
);

const reloadSourceAttributes = (state: SchemaMappingStore) => state.update('sourceSequence', n => n + 1);
const reloadUnifiedAttributes = (state: SchemaMappingStore) => state.update('unifiedSequence', n => n + 1);
const reloadSourceAndUnifiedAttributes = (state: SchemaMappingStore) => reloadSourceAttributes(reloadUnifiedAttributes(state));

const setCheckSourceFilterViolations = (state: SchemaMappingStore) => state.set('checkSourceFilterViolations', true);
const setCheckUnifiedFilterViolations = (state: SchemaMappingStore) => state.set('checkUnifiedFilterViolations', true);

const clearSourceSelection = (state: SchemaMappingStore) => state.update('sourceSelectedIds', s => s.clear());
const clearUnifiedSelection = (state: SchemaMappingStore) => state.update('unifiedSelectedIds', s => s.clear());
const resetSourceExpanded = (state: SchemaMappingStore) => state.update('sourceExpanded', s => s.clear());
const resetSourcePage = (state: SchemaMappingStore) => resetSourceExpanded(clearSourceSelection(state)).set('sourcePageNum', 0);

function parseUnifiedAttributes(data: List<UnifiedAttribute>): List<UnifiedAttribute> {
  return data.sortBy(
    ({ generated, name, datasetName }) =>
      (generated ? '0' : '1') + name.toLowerCase() + datasetName.toLowerCase(),
  );
}

function diffUnifiedAttributes(
  oldList: List<UnifiedAttribute>,
  newList: List<UnifiedAttribute>,
): { removedSet: Set<AttributeId>, mergedList: List<UnifiedAttribute> } {
  const oldSet = Set(oldList.map(attribute => attributeId(attribute)));
  const newSet = Set(newList.map(attribute => attributeId(attribute)));
  const removedSet = oldSet.subtract(newSet);

  // keep all attributes from the new list, and add attributes from the old list that are
  // not in the new list already
  const mergedList =
    parseUnifiedAttributes(newList.concat(oldList.filter(a => !newSet.has(attributeId(a)))));

  return { removedSet, mergedList };
}

function diffSourceAttributes(
  oldList: List<SourceAttribute>,
  newList: List<SourceAttribute>,
): { removedSet: Set<AttributeId>, mergedList: List<SourceAttribute> } {
  const oldSet = Set(oldList.map(attribute => attributeId(attribute)));
  const newSet = Set(newList.map(attribute => attributeId(attribute)));
  const removedSet = oldSet.subtract(newSet);

  // keep all attributes from the new list, and add attributes from the old list that are
  // not in the new list already
  const mergedList = newList.concat(oldList.filter(a => !newSet.has(attributeId(a))));
  return { removedSet, mergedList };
}

function handleSelectAttributeIds(
  keyMods: KeyMods,
  ids: List<AttributeId>,
  selectedIds: Set<AttributeId>,
  id: AttributeId,
  lastSelectedId: AttributeId | null | undefined,
): Set<AttributeId> {
  switch (true) {
    case keyMods.shiftKey:
      // Note: if no id is previously selected while pressing shift, then fall back to the
      // default behavior. This prevents indexOf from throwing a runtime exception when
      // lastSelectedId is null or undefined
      if (!lastSelectedId) {
        return selectedIds.clear().add(id);
      }
      return selectedIds.union(
        ids.slice(...sliceBounds(ids.indexOf(lastSelectedId), ids.indexOf(id))),
      );
    case keyMods.toggleKey:
      return toggleHas(selectedIds, id);
    default:
      return selectedIds.clear().add(id);
  }
}

export const initialState = new SchemaMappingStore({});

export const reducers: StoreReducers<SchemaMappingStore> = {
  'Location.change': (state, { location }) => {
    if (routes.schemaMapping.match(location.pathname)) {
      const params = queryString.parse(location.search);
      return _.compose(
        maybeSet('sourceFilterDatasets', parseSet(parseString)(params.sourceFilterDatasets)),
        maybeSet('unifiedFilterDatasets', parseSet(parseString)(params.unifiedFilterDatasets)),
        maybeSet('similarityThreshold', parseNumber(params.similarityThreshold)),
      )(state);
    }
    return state;
  },

  [RESET_ATTRIBUTES]: (state) => {
    return reloadSourceAndUnifiedAttributes(state.merge({ sourceShowSpinner: true }).merge({ unifiedShowSpinner: true }));
  },

  [PROJECT_CHANGE]: (state) => resetExcept(state, ['sourceLoading', 'unifiedLoading']),

  [SET_HEADER_SCROLL_BAR_OFFSET]: (state, { offset }) => {
    return state.set('headerScrollbarOffset', offset);
  },

  [SET_SIMILARITY_THRESHOLD]: (state, { similarityThreshold }) => {
    // TODO: if unified related filter is active, reset the source attribute page number
    return state.set('similarityThreshold', similarityThreshold);
  },

  [FETCH_SOURCE_ATTRIBUTES]: (state) => {
    return state.set('sourceLoading', true);
  },

  [FETCH_SOURCE_ATTRIBUTES_COMPLETED]: (state, { filterInfo, data, unifiedDatasetName, profilingInfo }) => {
    const attributes = List(data.items);
    const pageEndOffset = (filterInfo.sourcePageNum * filterInfo.sourcePageSize) + attributes.size;
    const hasNextPage = data.total > pageEndOffset;
    const newSourceAttributes = attributes.map(
      ({
        name, datasetName, description, metadata: { schemaMapping = {} },
        mappedToAttributes, recommendations,
      }: {
        name: string, datasetName: string, description: string, metadata: $TSFixMe,
        mappedToAttributes: Set<string>, recommendations: List<MappingRecommendation>,
      }) => {
        return new SourceAttribute({
          name,
          datasetName,
          description,
          doNotMap: Set(_.pairs(schemaMapping).filter(([, v]) => v === false).map(([k]) => k)),
          mappedAttributes: unifiedDatasetName
            ? Set(mappedToAttributes.map(uaName => new AttributeId({ name: uaName, datasetName: unifiedDatasetName })))
            // if the unifiedDatasetName is undefined and then it's either been deleted or not created
            // yet. In either case, the set will be empty
            : Set<AttributeId>(),
          recommendations: List(recommendations.map(({ recommended, similarity, confidence }) => {
            return new MappingRecommendation({ recommended: attributeId(recommended), similarity, confidence });
          })),
        });
      });
    let sourceAttributes = newSourceAttributes;
    let sourceAttributesRemoved = Set();
    if (state.checkSourceFilterViolations) {
      const { removedSet, mergedList } = diffSourceAttributes(state.sourceAttributes, newSourceAttributes);
      sourceAttributes = mergedList.take(filterInfo.sourcePageSize);
      sourceAttributesRemoved = removedSet;
    }
    return clearSourceSelection(state).merge({
      sourceAttributes,
      nextSourceAttributes: newSourceAttributes,
      sourceAttributesRemoved,
      sourceLoading: false,
      sourceLoadedFilterInfo: filterInfo,
      sourceShowSpinner: false,
      sourceHasNextPage: hasNextPage,
      sourceProfilingInfo: profilingInfo,
      checkSourceFilterViolations: false,
    });
  },

  [FETCH_SOURCE_ATTRIBUTES_FAILED]: (state, { filterInfo }) => {
    return state.merge({ sourceLoading: false, sourceLoadedFilterInfo: filterInfo });
  },

  [SOURCE_TOGGLE_EXPANDED]: (state, { id }) => {
    return state.update('sourceExpanded', s => toggleHas(s, id));
  },

  [SOURCE_TOGGLE_EXPAND_ALL]: (state) => {
    return state.update('sourceExpanded', s => {
      return s.size === state.sourceAttributes.size ? s.clear() : s.union(state.sourceAttributes.map(attributeId));
    });
  },

  [SET_SOURCE_PAGE_NUM]: (state, { page }) => {
    return resetSourceExpanded(clearSourceSelection(state)).set('sourcePageNum', page);
  },

  [SET_SOURCE_PAGE_SIZE]: (state, { pageSize }) => {
    return resetSourcePage(state).set('sourcePageSize', pageSize);
  },

  [SOURCE_TOGGLE_FILTER_MAPPED]: (state) => {
    return resetSourcePage(state).update('sourceFilterMapped', b => !b);
  },

  [SOURCE_TOGGLE_FILTER_UNMAPPED]: (state) => {
    return resetSourcePage(state).update('sourceFilterUnmapped', b => !b);
  },

  [SOURCE_TOGGLE_FILTER_DNM]: (state) => {
    return resetSourcePage(state).update('sourceFilterDNM', b => !b);
  },

  [SOURCE_TOGGLE_FILTER_RELATED_ID]: (state, { id }) => {
    const updated = clearUnifiedSelection(state)
      .delete('unifiedFilterRelatedId')
      .update('sourceFilterRelatedId', rid => (is(rid, id) ? undefined : id));
    return state.get('unifiedFilterRelatedId') ? resetSourcePage(updated) : updated;
  },

  [UPDATE_SOURCE_FILTER_DATASETS]: (state, { datasetsToAdd, datasetsToRemove }) => {
    return resetSourcePage(state)
      .update('sourceFilterDatasets', s =>
        s.union(datasetsToAdd.map(d => d.data.name))
          .subtract(datasetsToRemove.map(d => d.data.name)),
      )
      .delete('sourceDatasetFilterDialogVisible');
  },

  [SET_SOURCE_FILTER_DATASETS]: (state, { datasetNames }) => {
    checkArg({ datasetNames }, ArgTypes.Immutable.set.of(ArgTypes.string));
    return state.set('sourceFilterDatasets', datasetNames);
  },

  [RESET_SOURCE_FILTER_DATASETS]: (state) => {
    return resetSourcePage(state).delete('sourceFilterDatasets');
  },

  [SOURCE_SELECT_ATTRIBUTE]: (state, { id, keyMods }) => {
    const ids = filteredSourceAttributes(state).map(attributeId);
    return state.update('sourceSelectedIds', si => handleSelectAttributeIds(
      keyMods, ids, si, id, state.sourceLastSelectedId,
    )).set('sourceLastSelectedId', id);
  },

  [SOURCE_BEGIN_DRAG]: (state, { id }) => {
    return state.update('sourceSelectedIds', ids => (ids.has(id) ? ids : ids.clear().add(id)));
  },

  [FETCH_ALL_UNIFIED_ATTRIBUTES]: (state) => {
    return state.set('allUnifiedLoading', true);
  },

  [FETCH_ALL_UNIFIED_ATTRIBUTES_COMPLETED]: (state, { filterInfo, data }) => {
    const allUnifiedAttributes = parseUnifiedAttributes(data);
    return state.merge({ allUnifiedAttributes, allUnifiedLoadedFilterInfo: filterInfo, allUnifiedLoading: false });
  },

  [FETCH_ALL_UNIFIED_ATTRIBUTES_FAILED]: (state, { filterInfo }) => {
    return state.merge({ allUnifiedLoading: false, allUnifiedLoadedFilterInfo: filterInfo });
  },

  [FETCH_UNIFIED_ATTRIBUTES]: (state) => {
    return state.set('unifiedLoading', true);
  },

  [FETCH_UNIFIED_ATTRIBUTES_COMPLETED]: (state, { filterInfo, data }) => {
    const nextUnifiedAttributes = parseUnifiedAttributes(data);
    let unifiedAttributes = nextUnifiedAttributes;
    let unifiedAttributesRemoved = Set();

    if (state.checkUnifiedFilterViolations) {
      const { removedSet, mergedList } = diffUnifiedAttributes(state.unifiedAttributes, unifiedAttributes);
      unifiedAttributes = mergedList;
      unifiedAttributesRemoved = removedSet;
    }

    return clearUnifiedSelection(state).merge({
      nextUnifiedAttributes,
      unifiedAttributesRemoved,
      unifiedAttributes,
      unifiedLoading: false,
      unifiedLoadedFilterInfo: filterInfo,
      unifiedShowSpinner: false,
      checkUnifiedFilterViolations: false,
    });
  },

  [FETCH_UNIFIED_ATTRIBUTES_FAILED]: (state, { filterInfo }) => {
    return state.merge({ unifiedLoading: false, unifiedLoadedFilterInfo: filterInfo });
  },

  [SOURCE_RESET_SOURCE_AND_UNIFIED_ATTRIBUTES]: (state) => {
    return clearSourceSelection(clearUnifiedSelection(state)).merge({
      unifiedAttributes: state.nextUnifiedAttributes,
      sourceAttributes: state.nextSourceAttributes,
      checkUnifiedFilterViolations: false,
      checkSourceFilterViolations: false,
      sourceAttributesRemoved: Set(),
      unifiedAttributesRemoved: Set(),
    });
  },

  [UNIFIED_TOGGLE_EXPANDED]: (state, { id }) => {
    return state.update('unifiedExpanded', s => toggleHas(s, id));
  },

  [UNIFIED_TOGGLE_EXPAND_ALL]: (state) => {
    return state.update('unifiedExpanded', s => {
      return s.size === state.unifiedAttributes.size ? s.clear() : s.union(state.unifiedAttributes.map(attributeId));
    });
  },

  [UNIFIED_TOGGLE_FILTER_MAPPED]: (state) => {
    return clearUnifiedSelection(state).update('unifiedFilterMapped', b => !b);
  },

  [UNIFIED_TOGGLE_FILTER_UNMAPPED]: (state) => {
    return clearUnifiedSelection(state).update('unifiedFilterUnmapped', b => !b);
  },

  [UNIFIED_TOGGLE_FILTER_RELATED_ID]: (state, { id }) => {
    return resetSourcePage(state)
      .delete('sourceFilterRelatedId')
      .update('unifiedFilterRelatedId', rid => (is(rid, id) ? undefined : id));
  },

  // This selector sets the unified attributes filter
  [SET_UNIFIED_FILTER_DATASETS]: (state, { datasetsToAdd, datasetsToRemove }) => {
    return state.update('unifiedFilterDatasets', s => s.union(datasetsToAdd.map(d => d.data.name)).subtract(datasetsToRemove.map(d => d.data.name)))
      .delete('unifiedDatasetFilterDialogVisible');
  },

  [RESET_UNIFIED_FILTER_DATASETS]: (state) => {
    return state.delete('unifiedFilterDatasets');
  },

  [UNIFIED_SELECT_ATTRIBUTE]: (state, { id, keyMods }) => {
    const ids = filteredUnifiedAttributes(state).map(attributeId);
    return state.update('unifiedSelectedIds', si => handleSelectAttributeIds(
      keyMods, ids, si, id, state.unifiedLastSelectedId,
    )).set('unifiedLastSelectedId', id);
  },

  [UNIFIED_BEGIN_DRAG]: (state, { id }) => {
    return state.update('unifiedSelectedIds', ids => (ids.has(id) ? ids : ids.clear().add(id)));
  },

  [UPDATE_MAPPABLE_COMPLETED]: (state) => setCheckUnifiedFilterViolations(setCheckSourceFilterViolations(reloadSourceAndUnifiedAttributes(state))),
  [UPDATE_SOURCE_DESCRIPTION_COMPLETED]: reloadSourceAttributes,
  [BULK_ALLOW_MAPPING_COMPLETED]: (state) => setCheckSourceFilterViolations(reloadSourceAndUnifiedAttributes(state)),
  [MAP_ATTRIBUTES_COMPLETED]: (state) => setCheckUnifiedFilterViolations(setCheckSourceFilterViolations(reloadSourceAndUnifiedAttributes(state))),
  [TOGGLE_ML_ENABLED_COMPLETED]: reloadUnifiedAttributes,
  [TOGGLE_GEO_ATTRIBUTE_COMPLETED]: reloadUnifiedAttributes,
  [TOGGLE_SORT_TYPE_COMPLETED]: reloadUnifiedAttributes,
  [UPDATE_SIMILARITY_FUNCTION_COMPLETED]: reloadUnifiedAttributes,
  [UPDATE_TOKENIZER_CONFIG_COMPLETED]: reloadUnifiedAttributes,
  [UPDATE_NUMERIC_FIELD_RESOLUTION_COMPLETED]: reloadUnifiedAttributes,
  [SET_REQUIRED_ATTRIBUTE_TYPE_COMPLETED]: reloadUnifiedAttributes,
  [UPDATE_UNIFIED_NAME_AND_DESCRIPTION_COMPLETED]: reloadUnifiedAttributes,
  [CREATE_UNIFIED_ATTRIBUTE_COMPLETED]: reloadUnifiedAttributes,
  [SCHEMA_MAPPING_RECOMMENDATIONS_JOB_COMPLETED]: (state) => setCheckUnifiedFilterViolations(setCheckSourceFilterViolations(reloadSourceAndUnifiedAttributes(state))),
  [FINISHED_PROFILING]: reloadSourceAndUnifiedAttributes,

  [BOOTSTRAP_UNIFIED_ATTRIBUTES_COMPLETED]: (state, { response }) => {
    checkArg({ response }, ArgTypes.instanceOf(BootstrapResponse));
    return setCheckUnifiedFilterViolations(
      setCheckSourceFilterViolations(reloadSourceAndUnifiedAttributes(state)),
    ).update(
      'bootstrapNameValidationErrors',
      e => (response.nameValidationErrors.isEmpty() ? e : response.nameValidationErrors),
    );
  },

  [CLEAR_BOOTSTRAP_NAME_VALIDATION_ERRORS]: (state) => {
    return state.delete('bootstrapNameValidationErrors');
  },

  [CONFIRM_BULK_DELETE_ATTRIBUTES]: (state) => {
    return state.set('confirmDeleteAttributeIds', state.unifiedSelectedIds);
  },

  [CANCEL_DELETE_UNIFIED_ATTRIBUTES]: (state) => {
    return state.delete('confirmDeleteAttributeIds');
  },

  [DELETE_UNIFIED_ATTRIBUTES_COMPLETED]: (state) => {
    return setCheckUnifiedFilterViolations(setCheckSourceFilterViolations(reloadSourceAndUnifiedAttributes(state))).delete('confirmDeleteAttributeIds');
  },

  [CLEAR_FILTER_RELATED_ID]: (state) => {
    return state.merge({ sourceFilterRelatedId: undefined, unifiedFilterRelatedId: undefined });
  },

  [SET_SOURCE_ATTRIBUTES_WITH_TIEDS_RECS]: (state, { sourceAttributes }) => {
    return state.set('sourceAttributesWithTiedRecs', sourceAttributes);
  },

  [SHOW_CONFIRM_BULK_RECS_DIALOG]: (state, { mapToTop }) => {
    return state.set('showConfirmBulkRecsWarning', { mapToTop });
  },

  [HIDE_CONFIRM_BULK_RECS_DIALOG]: (state) => {
    return state.delete('showConfirmBulkRecsWarning');
  },

  [BULK_MAP_RECOMMENDATIONS_COMPLETED]: (state) => {
    return setCheckUnifiedFilterViolations(setCheckSourceFilterViolations(reloadSourceAndUnifiedAttributes(state))).delete('showConfirmBulkRecsWarning');
  },

  [SHOW_BULK_DO_NOT_MAP_DIALOG]: (state) => {
    return state.set('showDoNotMapWarning', true);
  },

  [HIDE_BULK_DO_NOT_MAP_DIALOG]: (state) => {
    return state.set('showDoNotMapWarning', false);
  },

  [PROMPT_DO_NOT_MAP_CONFIRMATION]: (state, { id }) => {
    return state.merge({
      showDoNotMapWarning: true,
      sourceSelectedIds: Set.of(id),
    });
  },

  [BULK_DO_NOT_MAP_COMPLETED]: (state) => {
    return setCheckSourceFilterViolations(reloadSourceAndUnifiedAttributes(state)).set('showDoNotMapWarning', false);
  },

  [SHOW_BULK_UNMAP_DIALOG]: (state) => {
    return state.set('showUnmapWarning', true);
  },

  [HIDE_BULK_UNMAP_DIALOG]: (state) => {
    return state.set('showUnmapWarning', false);
  },

  [BULK_UNMAP_COMPLETED]: (state) => {
    return setCheckUnifiedFilterViolations(setCheckSourceFilterViolations(reloadSourceAndUnifiedAttributes(state))).set('showUnmapWarning', false);
  },

  [SOURCE_SEARCH]: (state, { text }) => {
    return resetSourcePage(state).set('sourceSearchTerm', text);
  },

  [UNIFIED_SEARCH]: (state, { text }) => {
    return clearUnifiedSelection(state).set('unifiedSearchTerm', text);
  },

  [SET_HAS_VALIDATION_ERRORS]: (state, { value }) => {
    return state.set('hasValidationErrors', value);
  },

  [CANCEL_UNIFIED_ATTRIBUTE_ACTION]: (state) => {
    return state.delete('confirmDeleteAttributeIds');
  },

  [SHOW_UPDATE_UNIFIED_DATASET_DIALOG]: (state) => {
    return state.merge({ confirmingUpdateUnifiedDataset: true });
  },

  [HIDE_UPDATE_UNIFIED_DATASET_DIALOG]: (state) => {
    return state.delete('confirmingUpdateUnifiedDataset');
  },

  [REMOVE_DATASETS_FROM_PROJECT]: (state, { datasetNames }) => {
    checkArg({ datasetNames }, ArgTypes.Immutable.set.of(ArgTypes.string));
    return state
      .update('sourceFilterDatasets', set => set.subtract(datasetNames))
      .update('unifiedFilterDatasets', set => set.subtract(datasetNames));
  },

  [TOGGLE_UNIFIED_ATTRIBUTE_MENU_EXPANDED]: (state) => {
    return state.update('unifiedAttributeMenuExpanded', b => !b);
  },

  [OPEN_SOURCE_DATASET_FILTER_DIALOG]: (state) => {
    return state.set('sourceDatasetFilterDialogVisible', true);
  },
  [OPEN_UNIFIED_DATASET_FILTER_DIALOG]: (state) => {
    return state.set('unifiedDatasetFilterDialogVisible', true);
  },
  [CLOSE_SOURCE_DATASET_FILTER_DIALOG]: (state) => {
    return state.set('sourceDatasetFilterDialogVisible', false);
  },
  [CLOSE_UNIFIED_DATASET_FILTER_DIALOG]: (state) => {
    return state.set('unifiedDatasetFilterDialogVisible', false);
  },
  [CHECK_SMR_MODEL_EXPORTABLE]: (state) => {
    return state.set('hasSmrModelLoading', true);
  },
  [CHECK_SMR_MODEL_EXPORTABLE_COMPLETED]: (state) => {
    return state.merge({
      hasSmrModel: true,
      hasSmrModelLoading: false,
      hasSmrModelLoaded: true,
    });
  },
  [CHECK_SMR_MODEL_EXPORTABLE_FAILED]: (state) => {
    return state.merge({
      hasSmrModel: false,
      hasSmrModelLoading: false,
      hasSmrModelLoaded: true,
    });
  },
};
