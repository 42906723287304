import moment from 'moment';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Selector from '../components/Input/Selector';
import LoadingPanel from '../components/LoadingPanel';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import TooltipTrigger from '../components/TooltipTrigger';
import { CHANGED_CLUSTERS_SET, NEW_CLUSTERS_SET, REMOVED_CLUSTERS_SET } from '../constants/ClusterChanges';
import { CHANGED_CLUSTER_SET, DELETED_RECORD_SET, NEW_RECORD_SET, UNCHANGED_CLUSTER_SET } from '../constants/ClusterRecordChanges';
import DashboardTimeSelector from '../constants/DashboardTimeSelector';
import CountsOverTime from '../models/CountsOverTime';
import { commafy } from '../utils/Numbers';
import { pluralize } from '../utils/Strings';
import { getTime } from '../utils/Time';
import style from './PublishClustersDialog.module.scss';
import { getLastPublishTime } from './SuppliersStore';

const MODIFIED_CLUSTER_TEXT = <span>Clusters that have membership changes (or no changes) since published clusters were last updated. Changes do not include <Term>record</Term> value changes made from updating the the Unified Dataset.</span>;
const NEW_CLUSTER_TEXT = <span>Clusters that did not exist in the last set of published clusters.</span>;
const REMOVED_CLUSTER_TEXT = <span>Clusters that had <Term>records</Term> in the last set of published clusters but are currently empty. These clusters will be archived when you update published clusters once again.</span>;


const NEW_RECORDS_TEXT = <span>new <Term>records</Term> from updated sources</span>;
const MODIFIED_RECORDS_TEXT = <span><Term>records</Term> moved between clusters</span>;
const UNCHANGED_RECORDS_TEXT = <span><Term>records</Term> stayed in current clusters</span>;
const REMOVED_RECORDS_TEXT = <span><Term>records</Term> deleted from updated sources</span>;

@connect(
  state => {
    const { suppliers: { top: { totalSuppliers }, publishedClustersDatasetStatusLoading }, publishClustersDialog: { show, modifiedClusters, modifiedClustersLoading, newClusters, newClustersLoading, movedRecords, movedRecordsLoading, unmovedRecords, unmovedRecordsLoading, newRecords, newRecordsLoading, removedClusters, removedClustersLoading, deletedRecords, deletedRecordsLoading, clusterCounts, clusterCountsLoading, selectedTimeRange } } = state;
    const lastRun = getLastPublishTime(state);

    let minDate;
    if (selectedTimeRange === DashboardTimeSelector.WEEK) {
      minDate = moment().subtract(1, 'weeks');
    } else if (selectedTimeRange === DashboardTimeSelector.MONTH) {
      minDate = moment().subtract(1, 'months');
    } else if (selectedTimeRange === DashboardTimeSelector.YEAR) {
      minDate = moment().subtract(1, 'years');
    }

    const validClusterCounts = clusterCounts
      // Filter points that fall within the desired range
      .filter((p, i) => {
        if (selectedTimeRange === DashboardTimeSelector.ALL) {
          return true;
        }
        return (i === clusterCounts.size - 1) || // Make sure we at least include the latest point, regardless of the filter
          moment(p.time).isSameOrAfter(minDate);
      });

    const changedClusters = newClusters - removedClusters;
    return { show, lastRun, totalSuppliers, modifiedClusters, modifiedClustersLoading, newClusters, newClustersLoading, movedRecords, movedRecordsLoading, unmovedRecords, unmovedRecordsLoading, newRecords, newRecordsLoading, removedClusters, removedClustersLoading, deletedRecords, deletedRecordsLoading, validClusterCounts, clusterCountsLoading, selectedTimeRange, changedClusters, publishedClustersDatasetStatusLoading };
  }, {
    onClosePublishDialog: () => ({ type: 'PublishClusterDialog.hide' }),
    onPublishClusters: () => ({ type: 'PublishClusterDialog.showWarning' }),
    onFilterToSelectedTimeRange: (selectedTimeRange) => ({ type: 'PublishClusterDialog.setSelectedTimeRange', selectedTimeRange }),
    onFilterToClustersModified: () => ({ type: 'Suppliers.setClusterChangesFilter', pane: 'top', clusterChanges: CHANGED_CLUSTERS_SET, clear: true }),
    onFilterToClustersNew: () => ({ type: 'Suppliers.setClusterChangesFilter', pane: 'top', clusterChanges: NEW_CLUSTERS_SET, clear: true }),
    onFilterToClustersRemoved: () => ({ type: 'Suppliers.setClusterChangesFilter', pane: 'top', clusterChanges: REMOVED_CLUSTERS_SET, clear: true }),
    onFilterToRecordsMoved: () => ({ type: 'Suppliers.setRecordChangesFilter', pane: 'top', recordChanges: CHANGED_CLUSTER_SET, clear: true }),
    onFilterToRecordsUnmoved: () => ({ type: 'Suppliers.setRecordChangesFilter', pane: 'top', recordChanges: UNCHANGED_CLUSTER_SET, clear: true }),
    onFilterToRecordsNew: () => ({ type: 'Suppliers.setRecordChangesFilter', pane: 'top', recordChanges: NEW_RECORD_SET, clear: true }),
    onFilterToRecordsDeleted: () => ({ type: 'Suppliers.setRecordChangesFilter', pane: 'top', recordChanges: DELETED_RECORD_SET, clear: true }),
  },
)
export default class PublishClustersDialog extends React.Component {
  renderClusterStat = (stat, text, loading, action, tooltip) => {
    return (
      <div className={style.clusterStat}>
        <a className={style.clusterCount} onClick={action}>{loading ? '--' : commafy(stat)}</a>
        <div className={style.clusterStatTitle}>
          {text}
          <TooltipTrigger
            placement="bottom"
            content={(
              <span>{tooltip}</span>
            )}
          >
            <span className={style.helpWrapper}>
              <TamrIcon className={style.help} iconName="info-outline" size={14} />
            </span>
          </TooltipTrigger>
        </div>
      </div>
    );
  };

  renderRecordStat = (stat, text, loading, action) => {
    return (
      <Button className={style.recordStat} buttonType="Link" onClick={action}>
        <span className={style.recordCount}>{loading ? '--' : commafy(stat)}</span>
        <span> {text}</span>
      </Button>
    );
  };

  getLinePoints = () => {
    const { validClusterCounts, totalSuppliers } = this.props;
    // Last point is the current cluster count
    return validClusterCounts.push(new CountsOverTime({ count: totalSuppliers, time: new Date().getTime() }));
  };

  lineData = () => {
    const { validClusterCounts } = this.props;
    const points = this.getLinePoints();

    return {
      datasets: [
        {
          label: 'Number of clusters',
          fill: false,
          lineTension: 0,
          borderColor: style.lineColor,
          pointBackgroundColor: style.lineColor,
          borderWidth: 2,
          borderCapStyle: 'butt',
          data: validClusterCounts.map(c => c.count).toJSON(),
        },
        {
          label: 'Number of clusters since last publish',
          fill: false,
          lineTension: 0,
          borderColor: style.lineColor,
          pointBackgroundColor: '#FFF',
          borderWidth: 2,
          borderCapStyle: 'butt',
          borderDash: [10, 4],
          data: points.map((c, i) => (i < points.size - 2 ? NaN : c.count)).toJSON(),
        },
      ],
      labels: points.map(c => moment(c.time)).toJSON(),
    };
  };

  getChartOptions = () => {
    const points = this.getLinePoints();

    return {
      legend: {
        display: false,
      },
      scales: {
        xAxes: [{
          gridLines: {
            drawOnChartArea: false,
            color: style.axisColor,
            zeroLineColor: style.axisColor,
            drawTicks: false,
          },
          ticks: {
            callback: d => moment(d).format('l'),
            fontFamily: style.labelFont,
            fontColor: style.labelColor,
            padding: 10,
          },
        }],
        yAxes: [{
          gridLines: {
            drawOnChartArea: false,
            drawBorder: false,
            color: '#FFF',
            zeroLineColor: '#FFF',
          },
          ticks: {
            callback: commafy,
            min: 0,
            display: false,
            fontFamily: style.labelFont,
            fontColor: style.labelColor,
          },
        }],
      },
      tooltips: {
        enabled: true,
        displayColors: false,
        backgroundColor: '#FFFFFF',
        titleFontColor: '#29384C',
        bodyFontColor: '#29384C',
        borderColor: '#29384C',
        borderWidth: 0.5,
        callbacks: {
          title: tooltipItems => `${moment(points.get(_.first(tooltipItems)?.index)?.time).format('LL')}`,
          label: ({ yLabel }) => `${commafy(yLabel)} clusters`,
        },
      },
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 20,
        },
      },
    };
  };

  renderLineChart = () => {
    const { clusterCountsLoading } = this.props;
    if (clusterCountsLoading) {
      return <LoadingPanel />;
    }
    return <Line data={this.lineData} options={this.getChartOptions()} height={style.chartHeight} />;
  };

  onPublishCluster = () => {
    const { onClosePublishDialog, onPublishClusters } = this.props;
    onClosePublishDialog();
    onPublishClusters();
  };

  render() {
    const { onClosePublishDialog, show, lastRun, modifiedClusters, modifiedClustersLoading, newClusters, newClustersLoading, removedClusters, removedClustersLoading, movedRecords, movedRecordsLoading, unmovedRecords, unmovedRecordsLoading, newRecords, newRecordsLoading, deletedRecords, deletedRecordsLoading, totalSuppliers, onFilterToClustersModified, onFilterToClustersNew, onFilterToClustersRemoved, onFilterToRecordsMoved, onFilterToRecordsUnmoved, onFilterToRecordsNew, onFilterToRecordsDeleted, onFilterToSelectedTimeRange, selectedTimeRange, changedClusters, publishedClustersDatasetStatusLoading } = this.props;
    const changedClustersDisp = (newClustersLoading || removedClustersLoading) ? '--' : commafy(Math.abs(changedClusters));

    let clusterChangeIndIcon;
    if (changedClusters > 0) {
      clusterChangeIndIcon = <TamrIcon className={style.pointArrowUp} iconName="arrow-back" size={12} />;
    } else if (changedClusters < 0) {
      clusterChangeIndIcon = <TamrIcon className={style.pointArrowDown} iconName="arrow-back" size={12} />;
    }

    return (
      <Dialog
        animation={false}
        className={style.dialog}
        show={show}
        onHide={onClosePublishDialog}
        title={<span>Review changes and update published clusters</span>}
        body={show ? (
          <div className={style.body}>
            {
              (lastRun || publishedClustersDatasetStatusLoading) ? (
                <div>
                  <div className={style.header}>
                    <div className={style.headerText}>Clusters over time</div>
                    <div className={style.selectChooser}>
                      Time Period:
                      <Selector
                        value={selectedTimeRange}
                        onChange={onFilterToSelectedTimeRange}
                        values={[
                          { display: 'Year', value: DashboardTimeSelector.YEAR },
                          { display: 'Month', value: DashboardTimeSelector.MONTH },
                          { display: 'Week', value: DashboardTimeSelector.WEEK },
                          { display: 'All time', value: DashboardTimeSelector.ALL },
                        ]}
                      />
                    </div>
                  </div>
                  <div className={style.clusterChangesOverTime}>
                    {this.renderLineChart()}
                  </div>
                  <div className={style.clusterInfo}>
                    <div className={style.clusterTotal}>
                      <div>
                        {commafy(totalSuppliers)} {pluralize(totalSuppliers, 'Cluster', 'Clusters')} (<Term amount={totalSuppliers}>Supplier</Term>)
                      </div>
                      <div className={style.clusterCountChange}>
                        {clusterChangeIndIcon} {changedClustersDisp} since last publish on {getTime(lastRun).format('MMM Do, YYYY')}
                      </div>
                    </div>
                    <div className={style.clusterChanges}>
                      {this.renderClusterStat(modifiedClusters, 'Modified', modifiedClustersLoading, onFilterToClustersModified, MODIFIED_CLUSTER_TEXT)}
                      {this.renderClusterStat(newClusters, 'New', newClustersLoading, onFilterToClustersNew, NEW_CLUSTER_TEXT)}
                      {this.renderClusterStat(removedClusters, 'Empty', removedClustersLoading, onFilterToClustersRemoved, REMOVED_CLUSTER_TEXT)}
                    </div>
                  </div>
                  <div className={style.recordsInfo}>
                    <div className={style.header}>Record Activity</div>
                    <div className={style.recordsChanges}>
                      {this.renderRecordStat(newRecords, NEW_RECORDS_TEXT, newRecordsLoading, onFilterToRecordsNew)}
                      {this.renderRecordStat(movedRecords, MODIFIED_RECORDS_TEXT, movedRecordsLoading, onFilterToRecordsMoved)}
                      {this.renderRecordStat(unmovedRecords, UNCHANGED_RECORDS_TEXT, unmovedRecordsLoading, onFilterToRecordsUnmoved)}
                      {this.renderRecordStat(deletedRecords, REMOVED_RECORDS_TEXT, deletedRecordsLoading, onFilterToRecordsDeleted)}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  Your clusters have never been published
                </div>
              )}
          </div>
        ) : null}
        dialogStyle={DialogStyle.PRIMARY}
        footer={
          <ButtonToolbar>
            <Button onClick={onClosePublishDialog} buttonType="Secondary">
              Cancel
            </Button>
            <Button onClick={this.onPublishCluster}>
              Update published clusters
            </Button>
          </ButtonToolbar>
        }
      />
    );
  }
}
