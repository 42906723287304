import { Path } from './Path';
import { Sort } from './Sort';

// com.tamr.persist.models.querying.Query

// NB: the Query.java constructor provides default values for all missing fields, except 'filters'
export interface Query {
  offset?: number
  limit?: number
  sort?: Sort
  secondarySort?: Sort[]
  filters: object[]
}

function newPath(...fields: string[]): Path {
  return { fields };
}

function newStringPath(path: Path): object {
  return { '@class': 'com.tamr.persist.models.querying.Path$Str', fields: path.fields };
}

function newIntegerPath(path: Path): object {
  return { '@class': 'com.tamr.persist.models.querying.Path$Int', fields: path.fields };
}

function newArrayPath(path: Path): object {
  return { '@class': 'com.tamr.persist.models.querying.Path$Array', fields: path.fields };
}

function newStringLiteral(value: string) {
  return { '@class': 'com.tamr.persist.models.querying.Literal$Str', value };
}

function newIntegerLiteral(value: number) {
  return { '@class': 'com.tamr.persist.models.querying.Literal$Int', value };
}

function newObjectLiteral(value: object) {
  return { '@class': 'com.tamr.persist.models.querying.Literal$Obj', value: JSON.stringify(value) };
}

function newIsIn(leftHandSide: object, rightHandSide: object) {
  return { '@class': 'com.tamr.persist.models.querying.IsIn', leftHandSide, rightHandSide };
}

function newLike(leftHandSide: object, rightHandSide: object): object {
  return { '@class': 'com.tamr.persist.models.querying.Like', leftHandSide, rightHandSide };
}

function newEquals(leftHandSide: object, rightHandSide: object): object {
  return { '@class': 'com.tamr.persist.models.querying.Equals', leftHandSide, rightHandSide };
}

function newContains(leftHandSide: object, rightHandSide: object): object {
  return { '@class': 'com.tamr.persist.models.querying.Contains', leftHandSide, rightHandSide };
}

function newContainsAny(leftHandSide: object, rightHandSide: object[]): object {
  return { '@class': 'com.tamr.persist.models.querying.ContainsAny', leftHandSide, rightHandSide };
}

function newIsNull(inner: object): object {
  return { '@class': 'com.tamr.persist.models.querying.IsNull', inner };
}

function newIsEmpty(inner: object): object {
  return { '@class': 'com.tamr.persist.models.querying.IsEmpty', inner };
}

function newDisjunction(conditions: object[]): object {
  return { '@class': 'com.tamr.persist.models.querying.Disjunction', conditions };
}

function newNegated(negated: object): object {
  return { '@class': 'com.tamr.persist.models.querying.Negated', negated };
}

interface CanAddFilter {
  _addFilter: (filter: object) => void
}

class FilterBuilder<Parent extends CanAddFilter> {
  parent: Parent;
  path: Path;

  constructor({ parent, path }: { parent: Parent, path: Path }) {
    this.parent = parent;
    this.path = path;
  }

  isInStrings(test: string[]): Parent {
    this.parent._addFilter(newIsIn(newStringPath(this.path), test.map(newStringLiteral)));
    return this.parent;
  }

  isInIntegers(test: number[]): Parent {
    this.parent._addFilter(newIsIn(newIntegerPath(this.path), test.map(newIntegerLiteral)));
    return this.parent;
  }

  isNotInIntegers(test: number[]): Parent {
    this.parent._addFilter(newNegated(newIsIn(newIntegerPath(this.path), test.map(newIntegerLiteral))));
    return this.parent;
  }

  isLike(test: string): Parent {
    this.parent._addFilter(newLike(newStringPath(this.path), newStringLiteral(test)));
    return this.parent;
  }

  isEqualTo(test: string): Parent {
    this.parent._addFilter(newEquals(newStringPath(this.path), newStringLiteral(test)));
    return this.parent;
  }

  containsInteger(test: number): Parent {
    this.parent._addFilter(newContains(newArrayPath(this.path), newIntegerLiteral(test)));
    return this.parent;
  }

  containsAnyObject(test: object[]): Parent {
    this.parent._addFilter(newContainsAny(newArrayPath(this.path), test.map(newObjectLiteral)));
    return this.parent;
  }

  isNull(): Parent {
    this.parent._addFilter(newIsNull(newStringPath(this.path)));
    return this.parent;
  }

  empty(): Parent {
    this.parent._addFilter(newIsEmpty(newArrayPath(this.path)));
    return this.parent;
  }
}

class DisjunctionBuilder<Parent extends CanAddFilter> {
  parent: Parent;
  _conditions: object[];

  constructor({ parent }: { parent: Parent }) {
    this.parent = parent;
    this._conditions = [];
  }

  _addFilter(filter: object) {
    this._conditions.push(filter);
  }

  whereData(...args: string[]): FilterBuilder<DisjunctionBuilder<Parent>> {
    return new FilterBuilder({ parent: this, path: newPath('data', ...args) });
  }

  disjunct() {
    return new DisjunctionBuilder({ parent: this });
  }

  buildDisjunct() {
    if (this._conditions.length > 0) {
      this.parent._addFilter(newDisjunction(this._conditions));
    }
    return this.parent;
  }
}

/**
 * Javascript helper to construct valid Query objects
 */
export class QueryBuilder {
  _offset: number;
  _limit: number | undefined;
  _sort: Sort | undefined;
  _filters: object[];

  constructor() {
    this._offset = 0;
    this._limit = undefined;
    this._sort = undefined;
    this._filters = [];
  }

  offset(offset: number) {
    this._offset = offset;
    return this;
  }

  limit(limit: number) {
    this._limit = limit;
    return this;
  }

  all() {
    this._limit = undefined;
    return this;
  }

  _addFilter(filter: object) {
    this._filters.push(filter);
  }

  where(...args: string[]) {
    return new FilterBuilder({ parent: this, path: newPath(...args) });
  }

  and(...args: string[]) {
    return this.where(...args);
  }

  whereData(...args: string[]) {
    return new FilterBuilder({ parent: this, path: newPath('data', ...args) });
  }

  disjunct() {
    return new DisjunctionBuilder({ parent: this });
  }

  // TODO: sorting

  build(): Query {
    return { offset: this._offset, limit: this._limit, sort: this._sort, filters: this._filters };
  }
}
