import { List, Map, Set } from 'immutable';

import {
  deletePolicy,
  getAllGroups,
  getAllPolicies,
  getUsers,
  postPolicy,
  putPolicy,
} from '../api/AuthClient';
import { postProjectsQuery } from '../api/RecipeClient';
import PolicyMemberType, { PolicyMemberTypeE } from '../constants/PolicyMemberType';
import {
  UPDATE_POLICY_MEMBERSHIP_COMPLETED,
  UPDATE_POLICY_MEMBERSHIP_FAILED,
} from '../constants/UsersActionTypes';
import {
  UPDATE_POLICY_RESOURCESHIP_COMPLETED,
  UPDATE_POLICY_RESOURCESHIP_FAILED,
} from '../datasets/DatasetsActionTypes';
import { SHOW } from '../errorDialog/ErrorDialogActionTypes';
import AuthorizationPolicy from '../models/AuthorizationPolicy';
import { Roles } from '../models/AuthUser';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import Member from '../models/Member';
import ResourceSpec from '../models/ResourceSpec';
import {
  UPDATE_PROJECT_POLICIES_COMPLETED,
  UPDATE_PROJECT_POLICIES_FAILED,
} from '../projects/ProjectsActionTypes';
import { AppThunkAction } from '../stores/AppAction';
import { checkArg } from '../utils/ArgValidation';
import {
  CREATE_POLICY,
  CREATE_POLICY_COMPLETED,
  CREATE_POLICY_FAILED,
  DELETE_POLICY,
  DELETE_POLICY_COMPLETED,
  DELETE_POLICY_FAILED,
  DUPLICATE_POLICY,
  DUPLICATE_POLICY_COMPLETED,
  DUPLICATE_POLICY_FAILED,
  EDIT_POLICY,
  EDIT_POLICY_COMPLETED,
  EDIT_POLICY_FAILED,
  FETCH_ALL_POLICIES,
  FETCH_ALL_POLICIES_COMPLETED,
  FETCH_ALL_POLICIES_FAILED,
  START_MANAGE_MEMBERS,
  START_MANAGE_MEMBERS_COMPLETED,
  START_MANAGE_MEMBERS_FAILED,
  UPDATE_POLICY_MEMBERS,
  UPDATE_POLICY_MEMBERS_COMPLETED,
  UPDATE_POLICY_MEMBERS_FAILED,
  UPDATE_POLICY_RESOURCES,
  UPDATE_POLICY_RESOURCES_COMPLETED,
  UPDATE_POLICY_RESOURCES_FAILED,
} from './AccessControlActionTypes';
import { getCurrentFilterAndCacheInfo } from './AccessControlStore';
import * as Groups from './GroupsActionTypes';

export const fetchAllPolicies = (): AppThunkAction<void> => (dispatch, getState) => {
  dispatch({ type: FETCH_ALL_POLICIES });
  const filterAndCacheInfo = getCurrentFilterAndCacheInfo(getState());
  Promise.all([getAllPolicies(), postProjectsQuery({})])
    .then(([policyDocs, projectDocs]) => {
      const projectNamesById = projectDocs.reduce((buffer, doc) => {
        return buffer.set(doc.id.id, doc.data.displayName);
      }, Map<number, string>());
      const projectDescriptionsById = projectDocs.reduce((buffer, doc) => {
        return buffer.set(doc.id.id, doc.data.description);
      }, Map<number, string>());
      dispatch({ type: FETCH_ALL_POLICIES_COMPLETED, filterAndCacheInfo, policyDocs, projectNamesById, projectDescriptionsById });
    })
    .catch(response => {
      // TODO add to error dialog store if necessary to show error message
      dispatch({ type: FETCH_ALL_POLICIES_FAILED, filterAndCacheInfo, responseText: response?.message });
    });
};

/**
 * Called when beginning to manage the members of the policy
 *
 * Fetches all the members into the state
 */
export const startManageMembers = (
  policyDoc: Document<AuthorizationPolicy>,
  memberType: PolicyMemberTypeE,
): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ policyDoc }, Document.argTypeWithNestedClass(AuthorizationPolicy));
  dispatch({ type: START_MANAGE_MEMBERS, policyDoc, memberType });
  const { auth } = getState();
  Promise.all([getUsers(auth.authorizedUser?.admin || false), getAllGroups({ useCache: true })])
    .then(([authUsers, groupDocs]) => dispatch({ type: START_MANAGE_MEMBERS_COMPLETED, authUsers, groupDocs }))
    .catch(response => dispatch({ type: START_MANAGE_MEMBERS_FAILED, response: response?.message }));
};

export const createPolicy = (): AppThunkAction<void> => (dispatch, getState) => {
  dispatch({ type: CREATE_POLICY });
  const { accessControl: { policyConfig } } = getState();
  const handleCreatePolicyFailed = () => Promise.resolve(dispatch({ type: CREATE_POLICY_FAILED }));

  policyConfig && policyConfig.case({
    Create: ({ name, description }: { name: string, description: string }) => postPolicy(new AuthorizationPolicy({
      name,
      description,
      rolesToMembers: Map(),
      resourceSpecs: List(),
    }))
      .then(policyDoc => {
        dispatch({ type: CREATE_POLICY_COMPLETED, policyDoc });
        dispatch(startManageMembers(policyDoc, PolicyMemberType.USER));
      })
      .catch(handleCreatePolicyFailed),
    Delete: handleCreatePolicyFailed,
    Duplicate: handleCreatePolicyFailed,
    Edit: handleCreatePolicyFailed,
    ManageMembers: handleCreatePolicyFailed,
    ManageResources: handleCreatePolicyFailed,
  });
};

export const removePolicy = (): AppThunkAction<void> => (dispatch, getState) => {
  dispatch({ type: DELETE_POLICY });
  const { accessControl: { policyConfig } } = getState();
  const handleDeletePolicyFailed = () => Promise.resolve(dispatch({ type: DELETE_POLICY_FAILED }));

  policyConfig && policyConfig.case({
    Delete: ({ currentPolicy }) => deletePolicy(currentPolicy.id.id)
      .then(() => dispatch({ type: DELETE_POLICY_COMPLETED }))
      .catch(handleDeletePolicyFailed),
    Create: handleDeletePolicyFailed,
    Duplicate: handleDeletePolicyFailed,
    Edit: handleDeletePolicyFailed,
    ManageMembers: handleDeletePolicyFailed,
    ManageResources: handleDeletePolicyFailed,
  });
};

export const duplicatePolicy = (): AppThunkAction<void> => (dispatch, getState) => {
  dispatch({ type: DUPLICATE_POLICY });
  const { accessControl: { policyConfig } } = getState();
  const handleDuplicatePolicyFailed = () => Promise.resolve(dispatch({ type: DUPLICATE_POLICY_FAILED }));

  policyConfig && policyConfig.case({
    Duplicate: ({ name, description, currentPolicy }) => postPolicy(new AuthorizationPolicy({
      name,
      description,
      rolesToMembers: currentPolicy.data.rolesToMembers,
      resourceSpecs: currentPolicy.data.resourceSpecs,
    }))
      .then(policyDoc => dispatch({ type: DUPLICATE_POLICY_COMPLETED, policyDoc }))
      .catch(handleDuplicatePolicyFailed),
    Create: handleDuplicatePolicyFailed,
    Delete: handleDuplicatePolicyFailed,
    Edit: handleDuplicatePolicyFailed,
    ManageMembers: handleDuplicatePolicyFailed,
    ManageResources: handleDuplicatePolicyFailed,
  });
};

export const editPolicy = (): AppThunkAction<void> => (dispatch, getState) => {
  dispatch({ type: EDIT_POLICY });
  const { accessControl: { policyConfig } } = getState();
  const handleEditPolicyFailed = () => Promise.resolve(dispatch({ type: EDIT_POLICY_FAILED }));

  policyConfig && policyConfig.case({
    Edit: ({ name, description, currentPolicy }) =>
      putPolicy(
        currentPolicy.id.id,
        new AuthorizationPolicy({
          name,
          description,
          rolesToMembers: currentPolicy.data.rolesToMembers,
          resourceSpecs: currentPolicy.data.resourceSpecs,
        }),
      )
        .then(policyDoc => dispatch({ type: EDIT_POLICY_COMPLETED, policyDoc }))
        .catch(handleEditPolicyFailed),
    Create: handleEditPolicyFailed,
    Delete: handleEditPolicyFailed,
    Duplicate: handleEditPolicyFailed,
    ManageMembers: handleEditPolicyFailed,
    ManageResources: handleEditPolicyFailed,
  });
};

export const updatePolicyMembers = (): AppThunkAction<void> => (dispatch, getState) => {
  dispatch({ type: UPDATE_POLICY_MEMBERS });
  const { accessControl: { policyConfig } } = getState();
  const handleUpdatePolicyMembersFailed = () => Promise.resolve(dispatch({ type: UPDATE_POLICY_MEMBERS_FAILED }));

  policyConfig && policyConfig.case({
    ManageMembers:
      ({
        currentPolicy, draftUsernamesToRole, draftGroupnamesToRole, draftProjectIdsToRole,
        applyToAllUsers, allUsersRole, applyToAllUserGroups, allUserGroupsRole, applyToAllProjects,
      }) => {
        const rolesToUserMembers = (applyToAllUsers && allUsersRole ? Map({ [allUsersRole]: List.of(Member.fromString('user:users')) }) : (draftUsernamesToRole
          .groupBy(role => role)
          .map(userNamesToRole =>
            userNamesToRole.keySeq().map(username => Member.fromString(`user:users/${username}`)).toList(),
          )))
          .toMap();
        const rolesToGroupMembers = (applyToAllUserGroups && allUserGroupsRole ? Map({ [allUserGroupsRole]: List.of(Member.fromString('user_group:usergroups')) }) : (draftGroupnamesToRole
          .groupBy(role => role)
          .map(groupNamesToRole =>
            groupNamesToRole.keySeq().map(groupname => Member.fromString(`user_group:usergroups/${groupname}`)).toList(),
          )))
          .toMap();
        const rolesToProjectMembers = (applyToAllProjects ? Map({ [Roles.PROJECT]: List.of(Member.fromString('resource:projects')) }) : (draftProjectIdsToRole
          .groupBy(role => role)
          .map(projectIdsToRole =>
            projectIdsToRole.keySeq().map(projectId => Member.fromString(`resource:projects/${projectId}`)).toList(),
          )))
          .toMap();
        return putPolicy(
          currentPolicy.id.id,
          new AuthorizationPolicy({
            name: currentPolicy.data.name,
            description: currentPolicy.data.description,
            rolesToMembers: rolesToUserMembers
              .mergeWith((usersList, groupsList) => usersList.concat(groupsList), rolesToGroupMembers)
              .mergeWith((usersAndGroupsList, projectsList) => usersAndGroupsList.concat(projectsList), rolesToProjectMembers),
            resourceSpecs: currentPolicy.data.resourceSpecs,
          }),
        )
          .then(() => dispatch({ type: UPDATE_POLICY_MEMBERS_COMPLETED }))
          .catch(handleUpdatePolicyMembersFailed);
      },
    Create: handleUpdatePolicyMembersFailed,
    Delete: handleUpdatePolicyMembersFailed,
    Duplicate: handleUpdatePolicyMembersFailed,
    Edit: handleUpdatePolicyMembersFailed,
    ManageResources: handleUpdatePolicyMembersFailed,
  });
};

function computeNewDatasetSpecs(
  currentPolicy: Document<AuthorizationPolicy>,
  datasetsToAdd: Set<Document<Dataset>>,
  datasetsToRemove: Set<Document<Dataset>>,
) {
  const currentDatasetIds = currentPolicy.data.datasetIds().map(Number);
  return currentDatasetIds
    .concat(datasetsToAdd.map(datasetDocument => datasetDocument.id.id))
    .filter(id => !datasetsToRemove.map(datasetDocument => datasetDocument.id.id).includes(id))
    .map(id => ResourceSpec.fromJSON(`datasets/${id}`));
}

export const updatePolicyResources = (): AppThunkAction<void> => (dispatch, getState) => {
  dispatch({ type: UPDATE_POLICY_RESOURCES });
  const { accessControl: { policyConfig }, datasetFilter: { datasetsToAdd, datasetsToRemove } } = getState();
  const handleUpdatePolicyResourcesFailed = () => Promise.resolve(dispatch({ type: UPDATE_POLICY_RESOURCES_FAILED }));

  policyConfig && policyConfig.case({
    ManageResources: ({ currentPolicy, draftProjectIds, applyToAllProjects, applyToAllDatasets, draftResourcePolicyIds }) => {
      const { name, description, rolesToMembers } = currentPolicy.data;
      const projectSpecs = applyToAllProjects ? List.of(ResourceSpec.fromJSON('projects')) : draftProjectIds.toList().map(id => ResourceSpec.fromJSON(`projects/${id}`));
      const policyAsResourceSpecs = draftResourcePolicyIds.toList().map(id => ResourceSpec.fromJSON(`policies/${id}`));
      const datasetSpecs = applyToAllDatasets
        ? List.of(ResourceSpec.fromJSON('datasets'))
        : computeNewDatasetSpecs(currentPolicy, datasetsToAdd, datasetsToRemove);
      return putPolicy(
        currentPolicy.id.id,
        new AuthorizationPolicy({
          name,
          description,
          rolesToMembers,
          resourceSpecs: projectSpecs.concat(datasetSpecs).concat(policyAsResourceSpecs),
        }),
      )
        .then(() => dispatch({ type: UPDATE_POLICY_RESOURCES_COMPLETED }))
        .catch(handleUpdatePolicyResourcesFailed);
    },
    Create: handleUpdatePolicyResourcesFailed,
    Delete: handleUpdatePolicyResourcesFailed,
    Duplicate: handleUpdatePolicyResourcesFailed,
    Edit: handleUpdatePolicyResourcesFailed,
    ManageMembers: handleUpdatePolicyResourcesFailed,
  });
};

// updates a specific dataset resourceship with a set of policies
export const updateDatasetPolicyResourceship = (): AppThunkAction<void> => (dispatch, getState) => {
  const { accessControl: { datasetDraftPolicyResourceship } } = getState();
  if (!datasetDraftPolicyResourceship) {
    return dispatch({
      type: UPDATE_POLICY_RESOURCESHIP_FAILED,
      response: 'Attempting to update resourceship from invalid state',
    });
  }
  const { draftPoliciesById } = datasetDraftPolicyResourceship;
  Promise.all(draftPoliciesById.map((policy, id) => putPolicy(id, policy)).valueSeq())
    .then(() => dispatch({ type: UPDATE_POLICY_RESOURCESHIP_COMPLETED }))
    .catch(response => dispatch({ type: UPDATE_POLICY_RESOURCESHIP_FAILED, response: response?.message }));
};

export const updateProjectPolicies = (): AppThunkAction<void> => (dispatch, getState) => {
  const { accessControl: { projectDraftPolicyManager, policyDocs } } = getState();
  if (!projectDraftPolicyManager) {
    return null;
  }
  const projectId = projectDraftPolicyManager.projectId;
  const updatedPolicyMembershipIds = projectDraftPolicyManager.draftPolicyMembership;
  const updatedPolicyResourceIds = projectDraftPolicyManager.draftPolicyResourceship;

  const originalIncludedMembershipIds = policyDocs.filter(p => p.data.projectsAsMemberIds().includes(projectId)).map(p => p.id.id);
  const removedPolicyMembershipIds = originalIncludedMembershipIds.filterNot(p => updatedPolicyMembershipIds.includes(p));
  const addedPolicyMembershipIds = updatedPolicyMembershipIds.filterNot(p => originalIncludedMembershipIds.includes(p));

  const originalIncludedResourcesIds = policyDocs.filter(p => p.data.projectIds().includes(projectId)).map(p => p.id.id);
  const removedPolicyResourceIds = originalIncludedResourcesIds.filterNot(p => updatedPolicyResourceIds.includes(p));
  const addedPolicyResourceIds = updatedPolicyResourceIds.filterNot(p => originalIncludedResourcesIds.includes(p));

  policyDocs.forEach(policy => {
    const policyId = policy.id.id;
    const membershipAdded = addedPolicyMembershipIds.includes(policyId);
    const membershipRemoved = removedPolicyMembershipIds.includes(policyId);

    let updatedPolicy = policy.data;
    if (membershipAdded) {
      updatedPolicy = updatedPolicy.addProjectToPolicyAsMember({ projectId });
    } else if (membershipRemoved) {
      updatedPolicy = updatedPolicy.removeProjectFromPolicyAsMember({ projectId });
    }

    const resourceAdded = addedPolicyResourceIds.includes(policyId);
    const resourceRemoved = removedPolicyResourceIds.includes(policyId);

    if (resourceAdded) {
      updatedPolicy = updatedPolicy.addProjectToPolicyAsResource({ projectId });
    } else if (resourceRemoved) {
      updatedPolicy = updatedPolicy.removeProjectFromPolicyAsResource({ projectId });
    }

    if (membershipAdded || membershipRemoved || resourceAdded || resourceRemoved) {
      putPolicy(policy.id.id, updatedPolicy)
        .then(() => dispatch({ type: UPDATE_PROJECT_POLICIES_COMPLETED }))
        .catch(response => {
          dispatch({ type: UPDATE_PROJECT_POLICIES_FAILED });
          dispatch({ type: SHOW, detail: 'Failed to update project policies', response });
        },
        );
    }
  });
};

export const updateUserPolicyMembership = (): AppThunkAction<void> => (dispatch, getState) => {
  const { accessControl: { userDraftPolicyMembership } } = getState();
  if (!userDraftPolicyMembership) {
    return dispatch({
      type: UPDATE_POLICY_MEMBERSHIP_FAILED,
      response: 'Attempted to update membership from invalid state',
    });
  }
  const { draftPoliciesById } = userDraftPolicyMembership;
  Promise.all(draftPoliciesById.map((policy, id) => putPolicy(id, policy)).valueSeq())
    .then(() => dispatch({ type: UPDATE_POLICY_MEMBERSHIP_COMPLETED }))
    .catch(response => dispatch({ type: UPDATE_POLICY_MEMBERSHIP_FAILED, response: response?.message }));
};

export const updateGroupPolicyMembership = (): AppThunkAction<void> => (dispatch, getState) => {
  const { accessControl: { userGroupDraftPolicyMembership } } = getState();
  if (!userGroupDraftPolicyMembership) {
    return dispatch({ type: Groups.UPDATE_POLICY_MEMBERSHIP_FAILED, response: 'Attempted to update membership from invalid state' });
  }
  const { draftPoliciesById } = userGroupDraftPolicyMembership;
  Promise.all(draftPoliciesById.map((policy, id) => putPolicy(id, policy)).valueSeq())
    .then(() => dispatch({ type: Groups.UPDATE_POLICY_MEMBERSHIP_COMPLETED }))
    .catch(response => dispatch({ type: Groups.UPDATE_POLICY_MEMBERSHIP_FAILED, response: response?.message }));
};
