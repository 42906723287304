import $ from 'jquery';
import React from 'react';
import { connect } from 'react-redux';

import { CODEMIRROR_DATA_TYPE_TOOLTIP_CLASSNAME } from './TransformInput';

// this must always match up with className CodeMirror attaches to the lint tooltip!
const CODEMIRROR_LINT_TOOLTIP_CLASSNAME = 'CodeMirror-lint-tooltip';

const LINT_TOOLTIP_VERTICAL_PADDING = 15; // px above cursor
const DATA_TYPE_TOOLTIP_VERTICAL_PADDING = 15; // px below cursor

/**
 * It's kind of horrible that I'm doing this. If there was a way to configure the style for CodeMirror's lint tooltip,
 * I'd do that. But it doesn't seem like there is.
 * For those interested, here is where CodeMirror positions the tooltip https://github.com/codemirror/CodeMirror/blob/master/addon/lint/lint.js#L21
 * This overrides that style with a constantly-changing set of !important positioning styles
 * THIS ASSUMES ONLY ONE OF THESE COMPONENTS IS EVER MOUNTED TO THE DOM
 */
const CodemirrorTooltipPositioner = connect((state) => {
  const { transforms: { showingBigEditor } } = state;
  return { showingBigEditor };
})(/**
 * It's kind of horrible that I'm doing this. If there was a way to configure the style for CodeMirror's lint tooltip,
 * I'd do that. But it doesn't seem like there is.
 * For those interested, here is where CodeMirror positions the tooltip https://github.com/codemirror/CodeMirror/blob/master/addon/lint/lint.js#L21
 * This overrides that style with a constantly-changing set of !important positioning styles
 * THIS ASSUMES ONLY ONE OF THESE COMPONENTS IS EVER MOUNTED TO THE DOM
 */
  class CodemirrorTooltipPositioner extends React.Component {
    componentDidMount() {
      document.addEventListener('mousemove', this.trackMouse);
    }

    componentWillUnmount() {
      document.removeEventListener('mousemove', this.trackMouse);
    }

    trackMouse = (e) => {
      const { showingBigEditor } = this.props;
      const windowWidth = $(document).width();
      const windowHeight = $(document).height();
      const mousePosX = e.clientX;
      const mousePosY = e.clientY;
      const element = this.refs.element;

      // position lint tooltip to top left of mouse if we aren't in the big editor, otherwise top right
      // position data type tooltip to bottom right of mouse if we aren't in the big editor, otherwise bottom left
      if (!showingBigEditor) {
        const lintBottom = `${windowHeight - mousePosY + LINT_TOOLTIP_VERTICAL_PADDING}px`;
        const lintRight = `${windowWidth - mousePosX}px`;

        const dataTypeTop = `${mousePosY + DATA_TYPE_TOOLTIP_VERTICAL_PADDING}px`;
        const dataTypeRight = `${windowWidth - mousePosX}px`;

        element.innerHTML = `.${CODEMIRROR_LINT_TOOLTIP_CLASSNAME} {
        top: inherit !important;
        left: inherit !important;
        right: ${lintRight} !important;
        bottom: ${lintBottom} !important;
      }
      .${CODEMIRROR_DATA_TYPE_TOOLTIP_CLASSNAME} {
        top: ${dataTypeTop} !important;
        left: inherit !important;
        right: ${dataTypeRight} !important;
      }`;
      } else {
        const dataTypeTop = `${mousePosY + DATA_TYPE_TOOLTIP_VERTICAL_PADDING}px`;
        const dataTypeLeft = `${mousePosX}px`;

        element.innerHTML = `.${CODEMIRROR_DATA_TYPE_TOOLTIP_CLASSNAME} {
        top: ${dataTypeTop} !important;
        left: ${dataTypeLeft} !important;
      }`;
      }
    };

    render() {
      return <style ref="element" />;
    }
  });

export default CodemirrorTooltipPositioner;
