import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'underscore';

import Button, { ButtonProps } from '../components/Button';
import CopyIcon from '../components/CopyIcon';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import TooltipTrigger, { PlacementOption } from '../components/TooltipTrigger';
import ClusterVerificationCounts from '../models/ClusterVerificationCounts';
import { AppDispatch, AppState } from '../stores/MainStore';
import { commafyMoney, shortMoney } from '../utils/Numbers';
import { getActiveSpendField } from '../utils/Selectors';
import { pluralize } from '../utils/Strings';
import style from './ClusterSidebarRecordContent.module.scss';
import { PaneE } from './Pane';
import VerificationIcon, { VERIFICATION_ICON_TYPE_VERIFIED_ELSEWHERE, VERIFICATION_ICON_TYPE_VERIFIED_HERE } from './VerificationIcon';


const SUMMARY_ICON_SIZE = 16;

const Middot = () => <span className={style.middot}>&middot;</span>;

export const ClusterAggregationIcons: React.FC<{
  className?: string,
  numberOfRecords: number,
  totalNumVerifiedHere?: number,
  totalNumVerifiedElsewhere?: number
  similarity?: number
}> = ({ className, numberOfRecords, totalNumVerifiedHere, totalNumVerifiedElsewhere, similarity }) => {
  return (
    <div className={classNames(style.summaryRow, className)}>
      {similarity ?
        <React.Fragment>
          <span className={style.leftHalf}>
            {Math.round(similarity * 100)}% similar
          </span>
          <Middot />
        </React.Fragment>
        : null
      }
      <span className={style.leftHalf}>{numberOfRecords} <Term>{pluralize(numberOfRecords, 'Record', 'Records')}</Term></span>
      {!!(totalNumVerifiedHere && totalNumVerifiedHere > 0) && (
        <React.Fragment>
          <Middot />
          <VerificationIcon className={style.verificationIcon} verificationIconType={VERIFICATION_ICON_TYPE_VERIFIED_HERE} size={SUMMARY_ICON_SIZE} />{totalNumVerifiedHere}
        </React.Fragment>
      )}
      {!!(totalNumVerifiedElsewhere && totalNumVerifiedElsewhere > 0) && (
        <React.Fragment>
          <Middot />
          <VerificationIcon className={style.verificationIcon} verificationIconType={VERIFICATION_ICON_TYPE_VERIFIED_ELSEWHERE} size={SUMMARY_ICON_SIZE} />{totalNumVerifiedElsewhere}
        </React.Fragment>
      )}
    </div>
  );
};

export const TwoPanesIconButton: React.FC<{
  className?: string,
  tooltipPlacement: PlacementOption,
  focusedPane: PaneE,
  onOpenClusterInOtherPane: () => void,
  mapIsOpen: boolean,
  iconSize?: number,
}> = ({ className, tooltipPlacement, focusedPane, onOpenClusterInOtherPane, mapIsOpen, iconSize }) => {
  return (
    <TooltipTrigger
      placement={tooltipPlacement}
      content={<span>{mapIsOpen ? 'Cannot open second pane when map is open' : 'Open ' + name + ' in ' + (focusedPane === 'top' ? 'bottom' : 'top') + ' pane'}</span>}
    >
      <TamrIcon
        className={className || undefined}
        iconName="view-agenda"
        size={iconSize || 16}
        onClick={(e) => { e.stopPropagation(); onOpenClusterInOtherPane(); }}
        disabled={mapIsOpen}
      />
    </TooltipTrigger>
  );
};

export const CopyClusterIdIconButton: React.FC<{
  className?: string,
  clusterId: string,
  tooltipPlacement: PlacementOption,
}> = ({ tooltipPlacement, clusterId, className }) => {
  return (
    <CopyIcon
      initialTooltipContent="Copy cluster ID"
      className={className || undefined}
      size={16}
      tooltipPlacement={tooltipPlacement}
      getValue={() => clusterId}
    />
  );
};

type ClusterCardOwnProps = {
  className?: string,
  clusterId: string,
  clusterName?: string,
  clusterVerificationCounts: ClusterVerificationCounts | undefined,
  focusedPane: PaneE,
  numberOfRecords: number,
  spend?: number | undefined | null,
  title?: string,
  button?: ButtonProps,
  similarity?: number,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ClusterCard = connect((state: AppState, ownProps: ClusterCardOwnProps) => {
  return {
    isSpendConfigured: !!getActiveSpendField(state),
    mapIsOpen: state.suppliers.geospatial.openMap,
  };
}, ((dispatch: AppDispatch, { clusterId, focusedPane }: ClusterCardOwnProps) => bindActionCreators({
  onFilterToCluster: () => ({ type: 'Suppliers.setSuppliersFilter', supplierId: clusterId, pane: focusedPane }),
  onOpenClusterInOtherPane: () => ({ type: 'Suppliers.openClusterBrowser', clusterId, pane: focusedPane }),
}, dispatch)))(({ mapIsOpen, clusterId, button, className, title, clusterName, clusterVerificationCounts, numberOfRecords, isSpendConfigured, spend, onFilterToCluster, onOpenClusterInOtherPane, focusedPane, similarity }) => {
  return (
    <div className={classNames(style.clusterCard, className)}>
      <div className={style.cardTitle}>{title || ''}</div>
      <div className={style.cardBodyContainer}>
        <div className={classNames(style.cardBody, { [style.buttonPresent]: !!button })}>
          <div className={style.clusterSummary}>
            <TooltipTrigger content={clusterName}>
              <div className={style.clusterName}>{clusterName || <span className={style.noClusterName}>No cluster name</span>}</div>
            </TooltipTrigger>
            {clusterVerificationCounts && (
              <ClusterAggregationIcons
                {...{ numberOfRecords, similarity }}
                totalNumVerifiedHere={clusterVerificationCounts.totalNumVerifiedHere || undefined}
                totalNumVerifiedElsewhere={clusterVerificationCounts.totalNumVerifiedElsewhere}
              />
            )}
          </div>
          <div className={style.clusterFilterButtons}>
            <TooltipTrigger placement="bottom" content={<span>Filter to {clusterName}</span>}>
              <TamrIcon iconName="filter-list" size={16} onClick={onFilterToCluster} />
            </TooltipTrigger>
            <TwoPanesIconButton tooltipPlacement="bottom" {...{ focusedPane, onOpenClusterInOtherPane, mapIsOpen }} />
            <CopyClusterIdIconButton tooltipPlacement="bottom" {...{ clusterId }} />
          </div>
          {isSpendConfigured && _.isNumber(spend) && <div className={style.clusterSpend} title={commafyMoney(spend)}><Term>currencyChar</Term>{shortMoney(spend)}</div>}
        </div>
        {button && <Button {...button} className={classNames(style.cardBodyButton, button.className)} />}
      </div>
    </div>
  );
});

export default ClusterCard;
