import { Action, combineReducers, PayloadAction } from '@reduxjs/toolkit';
import _ from 'underscore';

import * as metrics from './metrics/slice';
import Pane, { PaneE } from './Pane';
import * as testRecord from './test-record/reducer';

const hasPayload = (action: Action | PayloadAction): action is PayloadAction<any> => {
  return 'payload' in action && action.payload !== undefined;
};

// Pane

const paneInitialState = {
  testRecord: testRecord.paneInitialState,
};

const paneReducers = {
  testRecord: testRecord.paneReducer,
};

const paneReducersWithKey = _.values(_.mapObject(paneReducers, (r, key: keyof typeof paneReducers) => ({ key, reducer: r })));

const isPane = (x: string): x is PaneE => {
  return x === Pane.TOP || x === Pane.BOTTOM;
};

// State

export const initialState = {
  top: paneInitialState,
  bottom: paneInitialState,
  testRecord: testRecord.initialState,
  metrics: metrics.initialState,
};

type State = typeof initialState;

// Page reducers

const pageReducers = combineReducers({
  testRecord: testRecord.reducer,
  metrics: metrics.reducer,
});

// Reducer

export const reducer = (state?: State, action?: Action): State => {
  if (state === undefined || action === undefined) {
    return initialState;
  }

  if (action.type === 'Location.projectChange') {
    return initialState;
  }

  // all actions are processed by page reducers
  const newState = {
    ...state,
    // TODO only pass pageReducer keys in state to pageReducers
    ...pageReducers(state, action),
  };

  // if action has no 'pane' field in payload, do not process by pane reducers
  // payload can be action.payload for RTK actions OR just the action for non-RTK actions
  const payload = hasPayload(action) ? action.payload : action;
  const { pane } = payload;
  if (!isPane(pane)) {
    return newState;
  }

  // process pane actions
  const paneState = newState[pane];
  const newPaneState = paneReducersWithKey.reduce(
    (s, { key, reducer: r }) => ({
      ...s,
      [key]: r(s[key], action),
    }),
    paneState,
  );
  return {
    ...newState,
    [pane]: newPaneState,
  };
};
