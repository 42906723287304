import classNames from 'classnames';
import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';

import Button from '../components/Button';
import ColumnWidthProvider from '../components/ColumnWidthProvider';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import MultiValue, { recordValueShim } from '../components/MultiValue';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Table from '../components/Table/Table';
import EsRecord from '../models/EsRecord';
import { AppState } from '../stores/MainStore';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { pluralize } from '../utils/Strings';
import styles from './ClusterMapRecordDialog.module.scss';
import { CLEAR_SELECTED_GEO_ROWS } from './SuppliersActionTypes';


const DataCell: React.FC<{
  selectedGeoRows: List<EsRecord>
  field: string
  rowIndex: number
}> = ({ selectedGeoRows, field, rowIndex }) => {
  const value = selectedGeoRows.get(rowIndex)?.getValue(field);
  if (!value) {
    return <Cell />;
  }
  const { data, originalData, renderRaw } = value;
  return <Cell><MultiValue values={recordValueShim({ value: data, raw: renderRaw, originalData })} /></Cell>;
};

const ClusterMapRecordDialog = connect((state: AppState) => {
  const { suppliers: { geospatial: { selectedGeoRows } } } = state;
  return {
    selectedGeoRows,
  };
}, {
  onClose: () => ({ type: CLEAR_SELECTED_GEO_ROWS }),
})(({ selectedGeoRows, onClose }) => {
  checkArg({ selectedGeoRows }, ArgTypes.Immutable.list.of(EsRecord.argType));
  checkArg({ onClose }, ArgTypes.func);
  const columns = selectedGeoRows.isEmpty() ? null : selectedGeoRows.get(0)?.fields.map(field => {
    return (
      <Column
        isResizable
        key={field}
        columnKey={field}
        width={100}
        header={() => <div className={classNames('table-header-cell', 'table-header-wrap')}>{field}</div>}
        cell={({ rowIndex }) => <DataCell {...{ selectedGeoRows, field, rowIndex }} />}
      />
    );
  })
    .insert(0, (
      <Column
        isResizable
        key="clusterName"
        columnKey="clusterName"
        width={100}
        header={() => <div className={classNames('table-header-cell', 'table-header-wrap')}>Cluster</div>}
        cell={({ rowIndex }) => <Cell><MultiValue values={recordValueShim({ value: selectedGeoRows.get(rowIndex)?.clusterName, raw: true, originalData: selectedGeoRows.get(rowIndex)?.clusterName })} /></Cell>}
      />
    ))
    .toArray();
  return (
    <Dialog
      className={styles.dialog}
      dialogStyle={DialogStyle.PRIMARY}
      header={<div className={styles.dialogTitle}>{selectedGeoRows.size} {pluralize(selectedGeoRows.size, 'Feature', 'Features')}</div>}
      body={
        <div className={styles.dialogBody}>
          <AutoSizer>
            {({ width, height }) => (
              <ColumnWidthProvider>
                <Table
                  {...{ width, height }}
                  tableType="stripes"
                  rowHeight={26}
                  headerHeight={52}
                  getLength={() => selectedGeoRows.size}
                  rowClassNameGetter={() => styles.row}
                >
                  {columns}
                </Table>
              </ColumnWidthProvider>
            )}
          </AutoSizer>
        </div>
      }
      footer={<Button buttonType="Primary" onClick={onClose}>Done</Button>}
      show={!selectedGeoRows.isEmpty()}
      onHide={onClose}
    />
  );
});

export default ClusterMapRecordDialog;
