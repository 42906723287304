import * as Table from '../models/Table';
import TypedTable from '../models/TypedTable';
import { StoreReducers } from '../stores/AppAction';
import { merge, update } from '../utils/Collections';
import { isDefined, jsonContentToString } from '../utils/Values';
import * as ClusterSampleState from './ClusterSampleState';
import { UPDATE_CLUSTER_PROFILE_JOB_FINISHED, UPDATE_SOURCE_LIST_JOB_FINISHED } from './GoldenRecordsActionTypes';
import * as GoldenRecordsModule from './GoldenRecordsModule';
import { FETCH_CLUSTER_SAMPLE, FETCH_CLUSTER_SAMPLE_COMPLETED, FETCH_CLUSTER_SAMPLE_FAILED, TOGGLE_SHOW_CLUSTER_SAMPLE_TABLE, UPDATE_DESIRED_SAMPLE_CLUSTER } from './GoldenRecordsRulesActionTypes';
import { getClusterIdOfPreviewedRecord, getClusterNameOfPreviewedRecord, getClusterSizeOfPreviewedRecord } from './GoldenRecordsUtils';


export interface InputRecordsFilterInfo {
  previewIsUsable: boolean
  bookmarkedClusterIds: string[]
}

export interface PreviewFilterInfo {
  previewFetchSequence: number
  input: TypedTable | null
}

export interface GoldenRecordsRulesStore {
  showSidebar: boolean

  // loading input records
  showPreviewTooLargeDialog: boolean
  bookmarkedClustersTooLarge: boolean
  loadingPreviewInputRecords: boolean
  loadedInputRecordsFilterInfo: InputRecordsFilterInfo | undefined
  inputRecordsTable: TypedTable | null

  // loading preview output
  loadingPreview: boolean,
  previewFetchSequence: number,
  loadedPreviewFilterInfo: PreviewFilterInfo | undefined,
  previewTable: Table.TableType | null,
  previewedModule: GoldenRecordsModule.GoldenRecordsModule | null,

  // cluster sample
  clusterSampleState: ClusterSampleState.ClusterSampleState
}

export const initialState: GoldenRecordsRulesStore = {
  showSidebar: false,
  showPreviewTooLargeDialog: false,
  bookmarkedClustersTooLarge: false,
  loadingPreviewInputRecords: false,
  loadedInputRecordsFilterInfo: undefined,
  inputRecordsTable: null,
  loadingPreview: false,
  previewFetchSequence: 0,
  loadedPreviewFilterInfo: undefined,
  previewTable: null,
  previewedModule: null,
  clusterSampleState: ClusterSampleState.initialClusterSampleState(),
};

const fetchPreview = (store: GoldenRecordsRulesStore) => update(store, 'previewFetchSequence', x => x + 1);
const fetchClusterSample = (store: GoldenRecordsRulesStore) => update(store, 'clusterSampleState', ClusterSampleState.triggerFetch);

export const reducers: StoreReducers<GoldenRecordsRulesStore> = {
  'GoldenRecordsRules.toggleSidebar': (store) => {
    return update(store, 'showSidebar', s => !s);
  },
  'GoldenRecords.fetchPreviewInputRecords': (store) => {
    return merge(store, {
      loadingPreviewInputRecords: true,
      bookmarkedClustersTooLarge: false,
    });
  },
  'GoldenRecords.fetchPreviewInputRecordsCompleted': (store, { table, filterInfo }) => {
    return merge(store, {
      loadingPreviewInputRecords: false,
      bookmarkedClustersTooLarge: false,
      loadedInputRecordsFilterInfo: filterInfo,
      inputRecordsTable: table,
    });
  },
  'GoldenRecords.fetchPreviewInputRecordsFailed': (store, { filterInfo, bookmarkedClustersTooLarge }) => {
    return merge(store, {
      loadingPreviewInputRecords: false,
      loadedInputRecordsFilterInfo: filterInfo,
      bookmarkedClustersTooLarge,
    });
  },
  'GoldenRecords.fetchPreviewTable': (store) => {
    return merge(store, {
      loadingPreview: true,
    });
  },
  'GoldenRecords.fetchPreviewTableCompleted': (store, { table, filterInfo, module }) => {
    return merge(store, {
      loadingPreview: false,
      loadedPreviewFilterInfo: filterInfo,
      previewTable: table,
      previewedModule: module,
    });
  },
  'GoldenRecords.fetchPreviewTableFailed': (store, { filterInfo }) => {
    return merge(store, {
      loadingPreview: false,
      loadedPreviewFilterInfo: filterInfo,
    });
  },
  'GoldenRecords.triggerPreviewFetch': (store) => fetchPreview(store),
  'GoldenRecords.showPreviewTooLargeDialog': (store, { showPreviewTooLargeDialog }) => {
    return merge(store, { showPreviewTooLargeDialog });
  },
  [UPDATE_SOURCE_LIST_JOB_FINISHED]: (store) => {
    return update(store, fetchClusterSample);
  },
  [UPDATE_CLUSTER_PROFILE_JOB_FINISHED]: (store) => {
    return update(store, fetchClusterSample);
  },
  [UPDATE_DESIRED_SAMPLE_CLUSTER]: (store, { rowNumber }) => {
    const { previewTable, previewedModule } = store;
    const record = previewTable?.get(rowNumber);
    const clusterId = getClusterIdOfPreviewedRecord(previewedModule, record);
    if (!isDefined(clusterId)) {
      console.error(`Could not fetch cluster sample for previewed record - no cluster id for record at row number ${rowNumber}`);
      return store;
    }
    const clusterName = getClusterNameOfPreviewedRecord(previewedModule, record);
    const clusterNameAsString = jsonContentToString(clusterName);
    const clusterSize = getClusterSizeOfPreviewedRecord(record) || 0;
    return update(store, 'clusterSampleState', s => ClusterSampleState.updateDesiredSampleCluster(s, clusterId, clusterNameAsString, clusterSize));
  },
  [FETCH_CLUSTER_SAMPLE]: (store, { clusterId, clusterName, clusterSize, fetchSequence }) => {
    return update(store, 'clusterSampleState', previous => ClusterSampleState.loadingClusterSampleState(previous, clusterId, clusterName, clusterSize, fetchSequence));
  },
  [FETCH_CLUSTER_SAMPLE_COMPLETED]: (store, { table, clusterId }) => {
    return update(store, 'clusterSampleState', previous => ClusterSampleState.successClusterSampleState(previous, clusterId, table));
  },
  [FETCH_CLUSTER_SAMPLE_FAILED]: (store, { clusterId }) => {
    return update(store, 'clusterSampleState', previous => ClusterSampleState.errorClusterSampleState(previous, clusterId));
  },
  [TOGGLE_SHOW_CLUSTER_SAMPLE_TABLE]: (store) => {
    return update(store, 'clusterSampleState', ClusterSampleState.toggleShowClusterSampleTable);
  },
};
