import { Map } from 'immutable';

import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { getAuthorizedUser } from '../utils/Selectors';
import { getPath } from '../utils/Values';

const COMMON_PREFIX = 'common';

/**
 * Send information about an event to the MixPanel API
 * @param eventName Name of the event to send
 * @param eventProperties Optional object with additional properties to track
 */
export const track = (eventName, eventProperties) => (dispatch, getState) => {
  checkArg({ eventName }, ArgTypes.string);
  checkArg({ eventProperties }, ArgTypes.nullable(ArgTypes.object));
  const { mixpanel } = window;
  // If mixpanel hasn't been loaded (e.g. tracking has been disabled, do not log anything
  if (!mixpanel || !mixpanel.get_property) {
    return;
  }

  const state = getState();
  const authUser = getAuthorizedUser(state);
  const uniqueInstanceName = mixpanel.get_property(`${COMMON_PREFIX}:uniqueInstanceName`);
  // If user isn't logged in, don't allow tracking since we use the username to track
  if (!authUser) {
    return;
  }
  // Make sure that the mixpanel user tracker is properly set
  const roles = authUser.effectiveRoles;
  const trackingKey = `${uniqueInstanceName}-${authUser.username}`;
  mixpanel.identify(trackingKey);
  mixpanel.people.set({
    roles: roles ? roles.map(r => r.toString()).toJSON() : undefined,
    username: authUser.username,
    loginTime: authUser.loginTime,
    Name: trackingKey,
  });

  let props = new Map(eventProperties);

  // Get current logged in user and roles
  props = props.set(`${COMMON_PREFIX}:username`, authUser.username);
  if (roles) {
    props = props.set(`${COMMON_PREFIX}:effectiveRoles`, roles.map(r => r.toString()).toJSON());
  }

  // Get current license information
  const license = getPath(state, 'license', 'license');
  if (license) {
    props = props.set(`${COMMON_PREFIX}:tamr-license-customer`, license.customerName);
    props = props.set(`${COMMON_PREFIX}:tamr-license-evaluation`, license.evaluation);
    props = props.set(`${COMMON_PREFIX}:tamr-license-effectiveUntil`, license.effectiveUntil);
  }

  // Get current version information
  const version = getPath(state, 'version', 'version');
  if (version) {
    props = props.set(`${COMMON_PREFIX}:tamr-version-name`, version.version);
    props = props.set(`${COMMON_PREFIX}:tamr-version-git-commit-id`, version.gitCommitId);
    props = props.set(`${COMMON_PREFIX}:tamr-version-git-commit-time`, version.gitCommitTime);
  }

  // Get a count of types of projects in the system
  const projects = getPath(state, 'projects', 'projectsWithStatus');
  if (projects) {
    const projectsByType = projects.groupBy(p => getPath(p, 'project', 'data', 'metadata', 'type'));
    projectsByType.entrySeq().forEach(e => {
      props = props.set(`${COMMON_PREFIX}:projects-${e[0]}`, e[1].size);
    });
  }
  mixpanel.track(eventName, props.toJSON());
};
