import React from 'react';

import { ArgTypes, checkArg } from '../utils/ArgValidation';
import GeospatialCoordinate from './GeospatialCoordinate';
import { LongLat } from './GeoTamr';
import styles from './LongLatTable.module.scss';

/**
 * Renders a table with 2 columns: 'Longitude' and 'Latitude' with a row for
 * each long/lat pair.
 */
function LongLatTable({ longLats }) {
  checkArg({ longLats }, ArgTypes.Immutable.list.of(LongLat.argType));
  return (
    <div className={styles.longLatTable}>
      <div>
        <div className={styles.longLatColumnTitle}>Longitude</div>
        {longLats.map((ll, i) => <GeospatialCoordinate key={i} coordinate={ll.long} />)}
      </div>
      <div>
        <div className={styles.longLatColumnTitle}>Latitude</div>
        {longLats.map((ll, i) => <GeospatialCoordinate key={i} coordinate={ll.lat} />)}
      </div>
    </div>
  );
}

export default LongLatTable;
