import classNames from 'classnames';
import { List, Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import ConditionalButton from '../components/ConditionalButton';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Highlighter from '../components/Highlighter';
import Input from '../components/Input/Input';
import TextArea from '../components/Input/TextArea';
import Labeler from '../components/Labeler';
import LoadingPanel from '../components/LoadingPanel';
import Toolbar from '../components/Toolbar';
import { SUPPLIER_MASTERING } from '../constants/ProjectTypes';
import { isReserved } from '../goldenRecords/constants/ReservedFields';
import ProjectInfo from '../models/ProjectInfo';
import { AppState } from '../stores/MainStore';
import { projectCreationPoliciesValid } from '../utils/Authorization';
import PRODUCT_NAME from '../utils/ProductName';
import { getAuthorizedUser } from '../utils/Selectors';
import { pluralize } from '../utils/Strings';
import style from './CreateProjectDialogGoldenRecords.module.scss';
import {
  BOOTSTRAP_ATTRIBUTES_STAGE,
  CREATE_PROJECT_STAGE,
  MUST_CREATE_MASTERING_PROJECT_STAGE,
  NAME_AND_DESCRIPTION_STAGE,
  onGoldenRecordsCreationNext,
  SELECT_MASTERING_PROJECT_STAGE,
} from './CreateProjectDialogGoldenRecordsActions';
import ProjectPoliciesSelector, {
  FUTURE_PROJECT_ID,
  FUTURE_PROJECT_NAME,
} from './ProjectPoliciesSelector';
import {
  CREATE_GR_PROJECT_BACK,
  CREATE_GR_PROJECT_BOOTSTRAP_SEARCH_VALUE,
  CREATE_GR_PROJECT_SELECT_ALL_BOOTSTRAP_ATTRIBUTES,
  CREATE_GR_PROJECT_SET_DESCRIPTION,
  CREATE_GR_PROJECT_SET_DISPLAY_NAME,
  CREATE_GR_PROJECT_TOGGLE_BOOTSTRAP_ATTRIBUTE,
  RESET_GR_PROJECT_WORKFLOW, SELECT_ACTIVE_MASTERING_PROJECT_FOR_GR_PROJECT,
} from './ProjectsActionTypes';
import { selectPublishedMasteringProjectInfos } from './ProjectsStore';
import SimpleProjectCard from './SimpleProjectCard';

const NEXT_SELECTION_STATE = Map({
  selected: false,
  semi: true,
  unselected: true,
});

function getCssClass(
  goldenRecordsMasteringProjectOptions: List<ProjectInfo>,
  wizardStage: string,
): string {
  let result = '';
  if (wizardStage === NAME_AND_DESCRIPTION_STAGE || wizardStage === MUST_CREATE_MASTERING_PROJECT_STAGE) {
    result = 'golden-records-dialog-initial';
  } else if (wizardStage === SELECT_MASTERING_PROJECT_STAGE) {
    if (goldenRecordsMasteringProjectOptions.size === 1) {
      result = 'golden-records-mp-1';
    } else if (goldenRecordsMasteringProjectOptions.size === 2) {
      result = 'golden-records-mp-2';
    } else {
      result = 'golden-records-dialog-default';
    }
  } else {
    result = 'golden-records-dialog-default';
  }
  return classNames('create-project-dialog', 'golden-records-content', result);
}

function getLabelerOptions(
  bootstrapAttributes: Map<string, boolean>,
  searchValue: string,
): List<{
  display: JSX.Element
  selectionState: 'selected' | 'unselected'
  key: string
}> {
  return bootstrapAttributes
    .filter((value, key) => key.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1)
    .map((value, key) => {
      return {
        display: (<span title={key}>
          <Highlighter fullText={key} highlightText={searchValue} />
        </span>),
        selectionState: value && !isReserved(key) ? 'selected' : 'unselected' as 'selected' | 'unselected',
        key,
      };
    })
    .sortBy((_v, k) => k)
    .toList();
}

/**
 * Return a human-friendly list of reserved fields
 * Example:
 * ["cat"] => "cat"
 * ["cat", "dog"] => "cat" and "dog"
 * ["cat", "dog", "not human"] => "cat", "dog" and "not human"
 */
function getReservedFields(fields: List<string>): string {
  if (fields.isEmpty()) {
    return '';
  }

  const fieldsWithQuotation = fields.map(f => `"${f}"`);
  if (fieldsWithQuotation.size === 1) {
    return fieldsWithQuotation.first();
  }
  return fieldsWithQuotation.pop().join(', ').concat(` and ${fieldsWithQuotation.last()}`);
}

const CreateProjectDialogGoldenRecords = connect((state: AppState) => {
  const goldenRecordsMasteringProjectOptions = selectPublishedMasteringProjectInfos(state);
  const {
    projects: {
      grProjectCreation: {
        bootstrapAttributes,
        bootstrapAttributeSearchValue,
        displayName,
        description,
        wizardStage,
        selectedMasteringProject,
      },
    },
    accessControl: { projectDraftPolicyManager },
  } = state;
  return {
    goldenRecordsMasteringProjectOptions,
    bootstrapAttributes,
    bootstrapAttributeSearchValue,
    displayName,
    description,
    wizardStage,
    selectedMasteringProject,
    projectDraftPolicyManager,
    user: getAuthorizedUser(state),
  };
}, {
  onCancelAddingProject: () => ({ type: RESET_GR_PROJECT_WORKFLOW }),
  onCancelEditingPolicies: () => ({ type: 'Projects.stopEditingPolicies' }),
  onGoldenRecordsNext: onGoldenRecordsCreationNext,
  onGoldenRecordsBack: () => ({ type: CREATE_GR_PROJECT_BACK }),
  onGoldenRecordsSelectActiveMasteringProject: (project: ProjectInfo) =>
    ({ type: SELECT_ACTIVE_MASTERING_PROJECT_FOR_GR_PROJECT, project }),
  onSelectBootstrapAttribute: (
    attributeName: string,
    selectionValue: boolean,
  ) => ({ type: CREATE_GR_PROJECT_TOGGLE_BOOTSTRAP_ATTRIBUTE, attributeName, selectionValue }),
  onSelectAllAttributes: (deselect: boolean) => ({ type: CREATE_GR_PROJECT_SELECT_ALL_BOOTSTRAP_ATTRIBUTES, deselect }),
  onSetSearchValue: (searchValue: string) => ({ type: CREATE_GR_PROJECT_BOOTSTRAP_SEARCH_VALUE, searchValue }),
  onSetDisplayName: (displayName: string) => ({ type: CREATE_GR_PROJECT_SET_DISPLAY_NAME, displayName }),
  onSetDescription: (description: string) => ({ type: CREATE_GR_PROJECT_SET_DESCRIPTION, description }),
})((
  {
    goldenRecordsMasteringProjectOptions,
    bootstrapAttributes,
    bootstrapAttributeSearchValue,
    displayName,
    description,
    wizardStage,
    selectedMasteringProject,
    onCancelAddingProject,
    onCancelEditingPolicies,
    onGoldenRecordsNext,
    onGoldenRecordsBack,
    onGoldenRecordsSelectActiveMasteringProject,
    onSelectBootstrapAttribute,
    onSelectAllAttributes,
    onSetSearchValue,
    onSetDisplayName,
    onSetDescription,
    projectDraftPolicyManager,
    user,
  },
) => {
  const reservedAttributes = bootstrapAttributes.filter((_v, k) => isReserved(k)).keySeq().toList();

  const content = {
    [NAME_AND_DESCRIPTION_STAGE]: <div className="create-project golden-records">
      <p>Start creating Golden Records after a few a quick steps.</p>
      <p>First, write a name for your project.</p>
      <Input
        autoFocusOnMount
        componentClassName={classNames(style.name)}
        onChange={onSetDisplayName}
        type="text"
        title="Project name"
        value={displayName}
      />
      <TextArea
        className={classNames(style.description)}
        onChange={onSetDescription}
        type="Text"
        placeholder="Description (optional)"
        value={description}
      />
      <div className={style.policiesHeader}>Select project policies<div className={style.policiesSubheader}>{user?.admin ? '' : 'Project must be a resource of at least one policy'}</div></div>
      <ProjectPoliciesSelector projectName={FUTURE_PROJECT_NAME} projectId={FUTURE_PROJECT_ID} rowClassName={classNames(style.policiesTableRow)} headerClassName={classNames(style.policiesTableHeader)} />
    </div>,
    [MUST_CREATE_MASTERING_PROJECT_STAGE]: <div>
      <p className="request-mastering-projects">Please create a mastering project with published clusters.</p>
    </div>,
    [SELECT_MASTERING_PROJECT_STAGE]: <div>
      <div>
        <p>Select which mastering project has the clusters you want to create Golden Records from.</p>
        <div className={style.cards}>
          {goldenRecordsMasteringProjectOptions.map((option: ProjectInfo) =>
            <SimpleProjectCard
              // @ts-expect-error this prop is part of the props we are sending through spreading
              onClick={() => onGoldenRecordsSelectActiveMasteringProject(option)}
              key={option.projectName}
              displayName={option.projectDisplayName}
              description={option.projectDescription}
              projectType={SUPPLIER_MASTERING}
              selected={selectedMasteringProject === option} />)}
        </div>
      </div>
    </div>,
    // TODO: work with Sharon on this
    [BOOTSTRAP_ATTRIBUTES_STAGE]: <div>
      <div>
        <p>
          {
            `Select which input attributes you want to use to kick start your project. 
            ${PRODUCT_NAME} will create attributes for you with the same name as the selected 
            attributes, and start by applying the most common value rule to each attribute. 
            You can change this rule later. Once in the project, you can create new attributes at 
            any time. You will continue to have access to all input attributes.`
          }
        </p>
        { !reservedAttributes.isEmpty()
          ? (<p>
            {
              `Please note that ${pluralize(reservedAttributes.size, 'attribute', 'attributes')} 
              ${getReservedFields(reservedAttributes)} ${pluralize(reservedAttributes.size, 'is', 'are')} 
              generated by ${PRODUCT_NAME} in the project, and cannot be selected here. 
              You may still use ${pluralize(reservedAttributes.size, 'it', 'them')} as input attributes.`
            }
          </p>)
          : null
        }
      </div>
      <div>
        <ButtonToolbar>
          <ConditionalButton
            tooltipPlacement="top"
            buttonType="Link"
            onClick={() => onSelectAllAttributes(false)}
            preconditions={Map<string, boolean>().set('Remove search to select all', !bootstrapAttributeSearchValue)}
          >
            Select All
          </ConditionalButton>
          <ConditionalButton
            tooltipPlacement="top"
            buttonType="Link"
            onClick={() => onSelectAllAttributes(true)}
            preconditions={Map<string, boolean>().set('Remove search to deselect all', !bootstrapAttributeSearchValue)}
          >
            Deselect All
          </ConditionalButton>
        </ButtonToolbar>
      </div>
      <div>
        <Labeler
          className={style.bootstrapAttributes}
          disabledFunc={(option) => (isReserved(option.key) ? `This attribute name is generated by ${PRODUCT_NAME} and cannot be selected here` : undefined)}
          onOptionSelect={({ key, selectionState }: { key: string, selectionState: string}) => {
            onSelectBootstrapAttribute(key, NEXT_SELECTION_STATE.get(selectionState, false));
          }}
          onSearchValueChange={onSetSearchValue}
          options={getLabelerOptions(bootstrapAttributes, bootstrapAttributeSearchValue)}
          searchValue={bootstrapAttributeSearchValue}
        />
      </div>
    </div>,
    [CREATE_PROJECT_STAGE]: <div>
      <LoadingPanel message="Creating your project." />
    </div>,
  };
  return (
    <Dialog
      className={getCssClass(goldenRecordsMasteringProjectOptions, wizardStage)}
      show
      onHide={() => { onCancelAddingProject(); onCancelEditingPolicies(); }}
      header={<div className="create-project-dialog-header"> Start a new Golden Records project </div>}
      // @ts-expect-error not sure how to access an object key in TS for content[wizardStage]
      body={content[wizardStage] || content[NAME_AND_DESCRIPTION_STAGE]}
      footer={wizardStage !== CREATE_PROJECT_STAGE ? (
        <Toolbar
          left={wizardStage === MUST_CREATE_MASTERING_PROJECT_STAGE || wizardStage === SELECT_MASTERING_PROJECT_STAGE || wizardStage === BOOTSTRAP_ATTRIBUTES_STAGE ?
            <Button buttonType="Secondary" onClick={onGoldenRecordsBack}>Back</Button> : null}
          right={<ButtonToolbar>
            <Button
              buttonType="Secondary"
              onClick={() => { onCancelAddingProject(); onCancelEditingPolicies(); }}>Cancel</Button>
            <ConditionalButton
              tooltipPlacement="top"
              preconditions={Map({
                'Please create a mastering project': (wizardStage === MUST_CREATE_MASTERING_PROJECT_STAGE ? selectedMasteringProject !== undefined : true),
                'Please select a mastering project': (wizardStage === SELECT_MASTERING_PROJECT_STAGE ? selectedMasteringProject !== undefined : true),
                'Please enter a name for the project': (wizardStage === NAME_AND_DESCRIPTION_STAGE ? (displayName !== undefined && displayName !== '') : true),
                'Please add the project to a policy as a resource': projectCreationPoliciesValid(user, projectDraftPolicyManager),
              })}
              onClick={onGoldenRecordsNext}
              buttonType="Primary"
            >{wizardStage === BOOTSTRAP_ATTRIBUTES_STAGE ? 'Create project' : 'Next'}</ConditionalButton>
          </ButtonToolbar>} />
      ) : null}
      dialogStyle={DialogStyle.PRIMARY}
    />
  );
});

export default CreateProjectDialogGoldenRecords;
