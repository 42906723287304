import './Details.scss';

import PropTypes from 'prop-types';
import React from 'react';

import TemplateStrings from './TemplateStrings';

/**
 * Assorted key / value pairs of metadata on selected object.
 */
class Details extends React.Component {
  static propTypes = {
    description: PropTypes.string,
  };

  render() {
    let description;

    if (this.props.description) {
      description = (
        <div className="details-item">
          <div className="details-key">
            {TemplateStrings.Details.description}
          </div>
          <div className="details-value">
            {this.props.description}
          </div>
        </div>
      );
    }

    return (
      <div className="details-component">
        {description}
      </div>
    );
  }
}

export default Details;
