import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import PageHeader from '../chrome/PageHeader';
import SearchBox from '../components/SearchBox';
import Term from '../components/Term';
import SchemaMappingCommitButton from '../schema-mapping/SchemaMappingCommitButton';
import { isCuratorByProjectId } from '../utils/Authorization';
import { longFormat } from '../utils/Numbers';
import { getAuthorizedUser, getUnifiedDatasetName, selectActiveProjectInfo } from '../utils/Selectors';
import { getColumnSettings } from './RecordsColumns';
import style from './RecordsHeader.module.scss';
import RecordsSubHeader from './RecordsSubHeader';
import { ES } from './RowProviders';

/**
 * The records header consists of the dataset name/records/attributes info
 * as well as controls for toggling between preview & elasticsearch.
 */
const RecordsHeader = _.compose(
  connect(state => {
    const { records: { provider, total, rows, columns, queryString } } = state;
    return {
      total,
      rows,
      columns,
      provider,
      columnCount: getColumnSettings(state, provider).filter(({ visible }) => visible).size,
      unifiedDatasetName: getUnifiedDatasetName(state),
      queryString,
      canUserEdit: isCuratorByProjectId(getAuthorizedUser(state), selectActiveProjectInfo(state)?.projectDoc.id.id),
    };
  }, {
    onSetQueryString: queryString => ({ type: 'Records.setQueryString', queryString }),
  }),
)(({ total, columnCount, unifiedDatasetName, onSetQueryString, queryString, provider, canUserEdit }) => {
  return (
    <PageHeader
      title={(
        <div>
          {unifiedDatasetName || 'Loading...'}
          <span className="page-header-metrics">
            {' '}{longFormat(total)} <Term amount={total}>record</Term>, {longFormat(columnCount)} {columnCount === 1 ? 'attribute' : 'attributes'}
          </span>
        </div>
      )}
      subtitle={<RecordsSubHeader canUserEdit={canUserEdit} />}
      searchbox={(
        <div className={style.searchboxContainer}>
          {provider === ES ? <SearchBox className={style.searchbox} value={queryString} onSearch={onSetQueryString} /> : null}
          {canUserEdit ? <SchemaMappingCommitButton /> : null}
        </div>
      )}
    />
  );
});

export default RecordsHeader;
