import { FetchError } from '../api/FetchResult';
import { $TSFixMe } from '../utils/typescript';

export const SHOW = 'ErrorDialog.show';
export const API_ERROR = 'ErrorDialog.apiError';
export const SHOW_SIMPLE = 'ErrorDialog.displaySimpleError';
interface ErrorDialogShow {
  type: typeof SHOW
  detail: $TSFixMe
  response: $TSFixMe
}

interface ErrorDialogApiError {
  type: typeof API_ERROR
  title: string
  contents: FetchError
}

interface ErrorDialogShowSimple {
  type: typeof SHOW_SIMPLE
  title: string
  detail: string
}

export type ErrorDialogActionTypes
  = ErrorDialogShow
  | ErrorDialogApiError
  | ErrorDialogShowSimple
