import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

/**
 * Object used to identify a comment on a record
 */
export class RecordCommentId extends getModelHelpers({
  entityId: { type: ArgTypes.string },
  username: { type: ArgTypes.string },
  createdAt: { type: ArgTypes.timestamp },
}, 'RecordCommentId')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class RecordCommentIdRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  static fromJSON(json: $TSFixMe) {
    const { entityId, username, createdAt } = json;
    return new RecordCommentId({
      entityId,
      username,
      createdAt,
    });
  }
}

export default RecordCommentId;
