import classNames from 'classnames';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import MultiValue, { recordValueShim } from '../components/MultiValue';
import NullValue from '../components/NullValue';
import ProgressBar from '../components/ProgressBar/ProgressBar';
import {
  ABSOLUTE_COSINE,
  GEOSPATIAL_DIRECTIONAL_HAUSDORFF,
  GEOSPATIAL_HAUSDORFF,
  GEOSPATIAL_RELATIVE_AREA_OVERLAP,
  GEOSPATIAL_RELATIVE_HAUSDORFF,
  isAbsoluteSimilarityFunction,
  SimilarityFunctionE,
} from '../schema-mapping/constants/SimilarityFunction';
import { meters } from '../utils/Numbers';
import { JsonContent } from '../utils/typescript';
import RecordPairCell from './RecordPairCell';


function isValid(value: ValueType) {
  return value !== '' && value !== undefined;
}

const InnerValue: React.FC<{
  className?: string
  value: ValueType
  raw?: boolean
  originalData: ValueType
}> = ({ className, value, raw, originalData }) => {
  if (value === undefined) {
    return (
      <div className={className}>
        <div className="value-container"><NullValue /></div>
      </div>
    );
  }
  if (value !== '') {
    return (
      <div className={className} title={JSON.stringify(value)}>
        <div className="value-container">
          <MultiValue values={recordValueShim({ value, raw, originalData })} />
        </div>
      </div>
    );
  }
  return null;
};

type ValueType = JsonContent;

type RecordPairDualValueCellOwnProps = {
  attributeName: string,
  signalType?: SimilarityFunctionE,
  similarityScore?: number,
  value1?: ValueType,
  value1IsRaw?: boolean,
  value1OriginalData?: ValueType,
  value2?: ValueType,
  value2IsRaw?: boolean,
  value2OriginalData?: ValueType,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RecordPairDualValueCell = connect((state, ownProps: RecordPairDualValueCellOwnProps) => {
  return {};
}, {
  onClickGeospatialSimilarity: (attributeName: string) => ({
    type: 'RecordPairs.clickGeospatialSimilarity',
    attributeName,
  }),
})(({ attributeName, similarityScore, signalType, onClickGeospatialSimilarity,
  value1: value1Prop, value1IsRaw, value1OriginalData, value2: value2Prop, value2IsRaw, value2OriginalData }) => {
  const value1 = _.isArray(value1Prop) ? value1Prop.join(', ') : value1Prop;
  const value2 = _.isArray(value2Prop) ? value2Prop.join(', ') : value2Prop;
  // do not collapse top and bottom values in case of absolute cosne to always show the absolute similarity
  const valuesAreEqual = isValid(value1) && isValid(value2) && value1 === value2 && signalType !== ABSOLUTE_COSINE;

  const relativeHausdorff = (signalType === GEOSPATIAL_RELATIVE_AREA_OVERLAP || signalType === GEOSPATIAL_RELATIVE_HAUSDORFF);

  let tooltip;
  let similarityBar;
  if (!valuesAreEqual && _.isNumber(similarityScore) && _.isFinite(similarityScore)) {
    // Note: implementation is that absolute DIFF is a negative, this allows us to check if
    // absolute DIFF was used by simply checking the sign of the similarity score.
    // Current behavior is simply to hide the similarity score for absolute DIFF
    if (similarityScore >= 0 && signalType && !isAbsoluteSimilarityFunction(signalType)) {
      tooltip = `Similarity is ${Math.round(similarityScore * 10000) / 100}`;
      similarityBar = (
        <ProgressBar
          className="similarity-bar"
          min={0}
          max={100}
          variant="light"
        >
          <ProgressBar
            fillClass="similarity-fill"
            now={Math.max(similarityScore * 100, 2)}
          />
        </ProgressBar>
      );
    } else if (signalType === GEOSPATIAL_HAUSDORFF || signalType === GEOSPATIAL_DIRECTIONAL_HAUSDORFF || signalType === SimilarityFunctionE.GEOSPATIAL_MIN_DISTANCE) {
      const distance = similarityScore * -1;
      tooltip = `${distance} meters`;
      similarityBar = (
        <div
          className="hausdorff"
          title={tooltip}
          onClick={() => onClickGeospatialSimilarity(attributeName)}
        >
          {meters(distance)}
        </div>
      );
    } else if (signalType === ABSOLUTE_COSINE) {
      tooltip = `Absolute similarity is ${similarityScore}`;
      const roundSim = numeral(similarityScore).format('0.0');
      similarityBar = (
        <div className="abs-cosine">{roundSim}</div>
      );
    } else {
      tooltip = `Difference is ${similarityScore * -1}`;
    }
  }
  return (
    <RecordPairCell className="record-pair-dual-value-cell">
      {similarityBar}
      <div
        className={classNames('values-container', { hausdorff: relativeHausdorff })}
        title={tooltip}
        onClick={relativeHausdorff ? () => onClickGeospatialSimilarity(attributeName) : undefined}
      >
        <InnerValue
          className={classNames('value', 'top', { equal: valuesAreEqual })}
          value={value1}
          raw={value1IsRaw}
          originalData={value1OriginalData}
        />
        <InnerValue
          className={classNames('value', 'bottom', { equal: valuesAreEqual })}
          value={value2}
          raw={value2IsRaw}
          originalData={value2OriginalData}
        />
      </div>
    </RecordPairCell>
  );
});

export default RecordPairDualValueCell;
