import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import ActivitySidebarTab from '../components/ActivitySidebarTab';
import { SidebarSection } from '../components/DetailSidebar/DetailSidebar';
import DetailSidebarTabbedContent from '../components/DetailSidebar/DetailSidebarTabbedContent';
import SidebarTabs from '../constants/SidebarTabs';
import { AppState } from '../stores/MainStore';
import RecordFieldsSidebarSection from '../transactions/RecordFieldsSidebarSection';
// @ts-expect-error
import TransactionsCommentList from '../transactions/TransactionsCommentList';
import ActiveRecordClusterMembershipLoader from './ActiveRecordClusterMembershipLoader';
import ClusterCard from './ClusterCard';
import style from './ClusterSidebarRecordContent.module.scss';
// @ts-expect-error
import ClusterSidebarRecordContentDetailsGeospatial from './ClusterSidebarRecordContentDetailsGeospatial';
import { SHOW_SIDEBAR_TAB } from './SuppliersActionTypes';
import { comment, editComment, removeComment } from './SuppliersAsync';
import { getActiveRecord, getLastPublishTime } from './SuppliersStore';
import VerificationSidebarSection from './VerificationSidebarSection';


const ClusterSidebarRecordContent = connect((state: AppState) => {
  const { suppliers: { activeGeospatialAttribute, commentFocusSequence, sidebarTabKey, focusedPane }, location: { recipeId } } = state;
  const record = getActiveRecord(state, focusedPane);
  return {
    activeGeospatialAttribute,
    recipeId,
    commentFocusSequence,
    publishExists: !!getLastPublishTime(state),
    sidebarTabKey,
    record,
    focusedPane,
  };
}, {
  onShowSidebarTab: (sidebarTabKey: string) => ({ type: SHOW_SIDEBAR_TAB, sidebarTabKey }),
  onComment: comment,
  onEditComment: editComment,
  onDeleteComment: removeComment,
})(({ focusedPane, publishExists, recipeId, record, activeGeospatialAttribute, commentFocusSequence, onComment, onEditComment, onDeleteComment, onShowSidebarTab, sidebarTabKey }) => {
  return (
    <DetailSidebarTabbedContent
      activeKey={sidebarTabKey}
      onSelect={onShowSidebarTab}
      tabs={List([
        {
          eventKey: SidebarTabs.DETAILS,
          title: 'Details',
          content: activeGeospatialAttribute
            ? <ClusterSidebarRecordContentDetailsGeospatial />
            : record && (
              <div key={`sidebar-${record.datasetId}-${record.recordId}`} className="transaction-sidebar-details-tab">
                <ActiveRecordClusterMembershipLoader />
                {record.clusterId && (
                  <SidebarSection title="Cluster Membership">
                    <ClusterCard
                      title="Currently in"
                      clusterName={record.clusterName}
                      clusterId={record.clusterId}
                      clusterVerificationCounts={record.clusterVerificationCounts}
                      numberOfRecords={record.numRecordsInCluster}
                      spend={record.totalClusterSpend}
                      focusedPane={focusedPane}
                    />
                  </SidebarSection>
                )}
                {publishExists && (
                  <ClusterCard
                    className={style.lastPublishedCard}
                    title="Last published in"
                    clusterName={record.publishedClusterName}
                    clusterId={record.publishedClusterId}
                    numberOfRecords={record.numRecordsInPublishedCluster}
                    clusterVerificationCounts={record.clusterVerificationCounts}
                    spend={record.totalPublishedClusterSpend}
                    focusedPane={focusedPane}
                  />
                )}
                <VerificationSidebarSection />
                <RecordFieldsSidebarSection {...{
                  recipeId,
                  record,
                  onClickGeoTamrAttribute: attributeName => ({
                    type: 'Suppliers.openGeospatialDetailsSidebar',
                    attributeName,
                  }),
                }} />
              </div>
            ),
        },
        {
          eventKey: SidebarTabs.ACTIVITY,
          title: 'Comments',
          content: (
            <ActivitySidebarTab
              onAddComment={onComment}
              commentsList={<TransactionsCommentList object={record} onEditComment={onEditComment} onDeleteComment={onDeleteComment} />}
              focusSequence={commentFocusSequence}
            />
          ),
        },
      ])}
    />
  );
});

export default ClusterSidebarRecordContent;
