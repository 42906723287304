import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PaneE } from '../Pane';
import { CLEAR_RECORD_FILTERS } from '../SuppliersActionTypes';

// State

export const initialState = {
  precisionProblem: false as boolean,
  recallProblem: false as boolean,
  bothProblems: false as boolean,
  noProblem: false as boolean,
};

export type State = typeof initialState;

const anyProblem = (state: State): boolean => {
  return state.precisionProblem || state.recallProblem || state.bothProblems;
};

export const allProblems = (state: State): boolean => {
  return state.precisionProblem && state.recallProblem && state.bothProblems;
};

export const anyActive = (state: State): boolean => {
  return anyProblem(state) || state.noProblem;
};

// API compatibility

/**
 * Convert to API query parameters
 */
export const toApiFilters = ({
  precisionProblem,
  recallProblem,
  bothProblems,
  noProblem,
}: State): string[] => {
  return [
    ...(precisionProblem ? ['INACCURATE,ANY'] : []),
    ...(recallProblem ? ['ANY,INACCURATE'] : []),
    ...(bothProblems ? ['INACCURATE,INACCURATE'] : []),
    ...(noProblem ? ['ACCURATE,ACCURATE'] : []),
  ];
};


// Slice

type ToggleAction = PayloadAction<{ pane: PaneE }>;

const filterSlice = createSlice({
  name: 'clusters/test-records/filters',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    togglePrecisionProblem: (state, action: ToggleAction) => {
      state.precisionProblem = !state.precisionProblem;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    toggleRecallProblem: (state, action: ToggleAction) => {
      state.recallProblem = !state.recallProblem;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    toggleBothProblems: (state, action: ToggleAction) => {
      state.bothProblems = !state.bothProblems;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    toggleAnyProblem: (state, action: ToggleAction) => {
      if (allProblems(state)) {
        state.precisionProblem = false;
        state.recallProblem = false;
        state.bothProblems = false;
      } else {
        state.precisionProblem = true;
        state.recallProblem = true;
        state.bothProblems = true;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    toggleNoProblem: (state, action: ToggleAction) => {
      state.noProblem = !state.noProblem;
    },
  },
  extraReducers: {
    [CLEAR_RECORD_FILTERS]: () => {
      return initialState;
    },
  },
});

export const {
  toggleAnyProblem,
  togglePrecisionProblem,
  toggleRecallProblem,
  toggleBothProblems,
  toggleNoProblem,
} = filterSlice.actions;
export const reducer = filterSlice.reducer;
