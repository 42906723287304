// com.tamr.transform.models.profiling.ProfilingInfo

import { List } from 'immutable';

import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import Checkpoint from './doc/Checkpoint';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';
import ProfiledAttribute from './ProfiledAttribute';

class ProfilingInfo extends getModelHelpers({
  datasetName: { type: ArgTypes.string },
  profiledAt: { type: ArgTypes.nullable(ArgTypes.instanceOf(Checkpoint)) },
  revision: { type: ArgTypes.nullable(ArgTypes.number) },
  schema: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(ProfiledAttribute)) },
  upToDate: { type: ArgTypes.orUndefined(ArgTypes.bool) },
}, 'ProfilingInfo')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ProfilingInfoRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  get datasetRecordCount() {
    const datasetProfiledAttribute = this.schema.find(pa => !pa.attributeName);
    const datasetMetrics = datasetProfiledAttribute ? datasetProfiledAttribute.metrics : List();
    const datasetRecordCountMetric = datasetMetrics.find(({ metricName }: { metricName: string }) => metricName === 'rowCount');
    return datasetRecordCountMetric ? datasetRecordCountMetric.value : undefined;
  }

  static fromJSON(obj: $TSFixMe) {
    const { datasetName, revision, upToDate } = obj;
    return new ProfilingInfo({
      datasetName,
      revision,
      profiledAt: obj.profiledAt ? new Checkpoint(obj.profiledAt) : undefined,
      schema: List(obj.schema).map(ProfiledAttribute.fromJSON),
      upToDate,
    });
  }
}

export default ProfilingInfo;
