import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { pure } from 'recompose';
import _ from 'underscore';

import { shortFormat } from '../utils/Numbers';
import { pluralize } from '../utils/Strings';
import DashboardCard from './DashboardCard';

const DashboardValueCard = _.compose(
  pure,
)(class DashboardValueCard extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    short: PropTypes.bool,
    title: PropTypes.string,
    valuesList: ImmutablePropTypes.listOf(PropTypes.shape({
      value: PropTypes.number.isRequired,
      nounSingular: PropTypes.node.isRequired,
      nounPlural: PropTypes.node.isRequired,
      customMessage: PropTypes.node,
    })).isRequired,
  };

  static defaultProps = { short: false };

  renderCustomMessage = (customMessage, index) => {
    const message = customMessage;
    return (<div key={index} className="bottom">{message}</div>);
  };

  renderValue = (value, nounSingular, nounPlural, index) => {
    const numberMessage = shortFormat(value);
    const nounMessage = pluralize(value, nounSingular, nounPlural);
    return (<div key={index} className="bottom">{numberMessage}&nbsp;{nounMessage}</div>);
  };

  render() {
    const { short, valuesList, onClick, title } = this.props;
    const className = classNames('dashboard-dual-value-card', { short });
    const valueRows = valuesList.map(
      (v, i) =>
        (v.customMessage
          ? this.renderCustomMessage(v.customMessage, i)
          : this.renderValue(v.value, v.nounSingular, v.nounPlural, i)),
    ).toArray();
    return (
      <DashboardCard className={className} onClick={onClick}>
        <div>{title}</div>
        {valueRows}
      </DashboardCard>
    );
  }
});

export default DashboardValueCard;
