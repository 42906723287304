import createLoader from '../utils/createLoader';
import { fetchModuleFromLastUpdate } from './GoldenRecordsAsync';
import { getModuleVersionOfLastRunIndexDraft } from './GoldenRecordsSelectors';

const ModuleFromLastUpdateLoader = createLoader(state => {
  const { loadedModuleFromLastUpdateVersion, loadingModuleFromLastUpdate } = state.goldenRecords;
  const lastRunIdxDraft = getModuleVersionOfLastRunIndexDraft(state);
  return {
    canFetch: !!lastRunIdxDraft, // indexDraft must have been run successfully before
    shouldFetch: lastRunIdxDraft !== loadedModuleFromLastUpdateVersion,
    loading: loadingModuleFromLastUpdate,
  };
}, {
  onFetch: fetchModuleFromLastUpdate,
}, 'ModuleFromLastUpdateLoader');

export default ModuleFromLastUpdateLoader;
