import { Set } from 'immutable';

import { ArgTypes } from '../utils/ArgValidation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

export class PostButtonSpec extends getModelHelpers({
  buttonType: { type: ArgTypes.string },
  buttonId: { type: ArgTypes.string },
  buttonText: { type: ArgTypes.string },
  pageNames: { type: ArgTypes.Immutable.set.of(ArgTypes.string) },
  postUrl: { type: ArgTypes.string },
  postBodyKeys: { type: ArgTypes.Immutable.set.of(ArgTypes.string) },
  successMessage: { type: ArgTypes.string },
  failMessage: { type: ArgTypes.string },
  displayResponse: { type: ArgTypes.bool },
}, 'PostButtonSpec')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class PostButtonSpecRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
},
) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: any) {
    return new PostButtonSpec({
      buttonType: obj.buttonType,
      buttonId: obj.buttonId,
      buttonText: obj.buttonText,
      pageNames: Set(obj.pageNames),
      postUrl: obj.postUrl,
      postBodyKeys: Set(obj.postBodyKeys),
      successMessage: obj.successMessage,
      failMessage: obj.failMessage,
      displayResponse: obj.displayResponse,
    });
  }
  static get buttonTypeConstant() { return 'postButton'; }
}

export default PostButtonSpec;
