import { connect } from 'react-redux';
import _ from 'underscore';

import BulkExpandTrigger from './BulkExpandTrigger';
import { UNIFIED_TOGGLE_EXPAND_ALL } from './SchemaMappingActionTypes';

const UnifiedBulkExpandTrigger = _.compose(
  connect(({ schemaMapping }) => {
    const { allUnifiedAttributes, unifiedExpanded } = schemaMapping;
    return { allExpanded: unifiedExpanded.size === allUnifiedAttributes.size };
  }, {
    onToggle: () => ({ type: UNIFIED_TOGGLE_EXPAND_ALL }),
  }),
)(BulkExpandTrigger);

export default UnifiedBulkExpandTrigger;
