import React from 'react';

import TamrIcon, { TamrIconProps } from '../components/TamrIcon';
import TooltipTrigger, { TooltipTriggerProps } from '../components/TooltipTrigger';

type UserDefinedSignalIconProps = {
  tooltipProps?: TooltipTriggerProps
} & Omit<Partial<TamrIconProps>, 'iconName' | 'ref'>

export const UserDefinedSignalIcon: React.FC<UserDefinedSignalIconProps> = ({ tooltipProps, ...iconProps }) => {
  return (
    <TooltipTrigger placement="top" content="This attribute is a User Defined Signal" {...tooltipProps}>
      <TamrIcon size={14} iconName="person-pin-circle" {...iconProps} />
    </TooltipTrigger>
  );
};
