import { List, Set } from 'immutable';
import React from 'react';

import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import TooltipTrigger from '../components/TooltipTrigger';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import Tag from '../models/Tag';
// @ts-expect-error
import { setTagColor } from '../tags/TagStore';
import style from './DatasetTagsColumn.module.scss';

const DatasetTagsColumn: React.FC<{
  rows: List<Document<Dataset>>
  allTags: Set<Document<Tag>>
}> = ({ rows, allTags }) => {
  return (
    <Column
      key="tags"
      columnKey="tags"
      width={100}
      isResizable
      flexGrow={1}
      header={<Cell>Tags</Cell>}
      cell={({ rowIndex }) => { // eslint-disable-line react/no-multi-comp
        const tagIds = Set(rows.get(rowIndex)?.data.metadata.get('tag')).map(({ id }) => id);
        const tags = allTags.filter(tag => tagIds.includes(tag.id.id));
        return (
          <Cell>
            <TooltipTrigger content={tags.map(t => t.data.name).join(', ')}>
              <span>
                {tags.map(({ id: { id }, data: { name } }) =>
                  <span key={id} className={style.pill} style={{ backgroundColor: setTagColor(name, id), borderRadius: '5px', paddingTop: '3px', paddingBottom: '3px' }}>{name}</span>, // added padding and rounded off edges for tags
                )}
              </span>
            </TooltipTrigger>
          </Cell>
        );
      }}
    />
  );
};

export default DatasetTagsColumn;
