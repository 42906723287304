import React from 'react';

import { RenderGeoJson } from '../suppliers/TamrClusterGeoJSON';
import { NoGeoMessage } from './GeospatialMap';
import * as geotamr from './GeoTamr';

export function TamrSidebarGeoJSON({ color, geoRows }: { color: string, geoRows: geotamr.GeoTamrType[] }) {
  if (geoRows.length === 0) {
    return <NoGeoMessage />;
  }

  return <RenderGeoJson {...{ geoTamr: geoRows, color }} />;
}
