import classNames from 'classnames';
import PropTypes, { InferProps, Requireable } from 'prop-types';
import React from 'react';

const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func as Requireable<(value: boolean, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void>,
  value: PropTypes.bool.isRequired,
  width: PropTypes.number,
};

const defaultProps = { width: 36 };

class Toggle extends React.PureComponent<InferProps<typeof propTypes>> {
  static propTypes = propTypes;

  handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { disabled, onChange, value } = this.props;
    if (!disabled && onChange) {
      onChange(!value, e);
    }
  };

  render() {
    const { className, disabled, value, width } = this.props;
    const valueCssClass = value ? 'on' : 'off';
    const actualWidth = width || defaultProps.width;
    const height = actualWidth / 2;
    const borderRadius = height / 2;
    const resolvedClassName = classNames('toggle-component', valueCssClass, className,
      { disabled },
    );
    return (
      <div
        className={resolvedClassName}
        style={{ width: actualWidth, height, borderRadius }}
        onClick={this.handleClick}
      >
        <div className="toggle-inner" style={{ width: height, height }} />
      </div>
    );
  }
}

export default Toggle;
