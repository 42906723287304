import { Set } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import TooltipTrigger from '../components/TooltipTrigger';
import { onlyInputDatasets } from '../datasetFilter/DatasetFilterAsync';
import DatasetFilterDialog from '../datasetFilter/DatasetFilterDialog';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { pluralize } from '../utils/Strings';
import { getLatestForBulkTransform } from './TransformsStore';

const datasetsToString = (unifiedDatasetSelected, numSelectedDatasets, numInputDatasets) => {
  checkArg({ unifiedDatasetSelected }, ArgTypes.bool);
  checkArg({ numSelectedDatasets }, ArgTypes.number);
  checkArg({ numInputDatasets }, ArgTypes.number);
  if (unifiedDatasetSelected) {
    return 'unified dataset';
  }
  // All or no datasets selected = 'all datasets'
  if (numSelectedDatasets === 0 || (numSelectedDatasets === numInputDatasets)) {
    return 'all datasets';
  }
  // Some datasets selected
  return `${numSelectedDatasets} ${pluralize(numSelectedDatasets, 'dataset', 'datasets')}`;
};

const TransformsDatasetSelector = _.compose(
  connect((state, { guid }) => {
    const {
      allSourceDatasets: { datasets: allSourceDatasets },
      transforms, transforms: { datasetSelectorDialogVisible: show, loadedDatasetId: unifiedDatasetId, unifiedDatasetChecked },
      datasetFilter: { numDatasets, datasetsToAdd, datasetsToRemove },
    } = state;
    const allSourceDatasetIds = allSourceDatasets.map(dataset => dataset.id.id).toSet();
    // the input datasets associated with the tx might have been removed from the project, so
    // we need to join it back with the current set of input dataset IDs
    const selectedDatasetIds =
      Set(getLatestForBulkTransform(transforms, guid).datasetIds).intersect(allSourceDatasetIds);
    const unifiedDatasetSelected = selectedDatasetIds.has(unifiedDatasetId);
    const numInputDatasets = allSourceDatasets.size; // TODO this will only get the first 1000 input datasets in the project, need another way to figure out total
    const numSelectedDatasets = unifiedDatasetSelected ? 0 : selectedDatasetIds.size;
    const numCheckedDatasets = numSelectedDatasets + datasetsToAdd.size - datasetsToRemove.size;
    const modifyQuery = onlyInputDatasets(state);
    return { modifyQuery, selectedDatasetIds, unifiedDatasetSelected, unifiedDatasetChecked, numInputDatasets, numDatasets, show, unifiedDatasetId, numCheckedDatasets };
  }, {
    onOpenDialog: () => ({ type: 'Transforms.openDatasetSelectorDialog' }),
    onHide: () => ({ type: 'Transforms.closeDatasetSelectorDialog' }),
    onSubmit: (guid, datasetsToAdd, datasetsToRemove) => ({ type: 'Transforms.editDatasetIds', guid, datasetsToAdd, datasetsToRemove }),
  }),
)(({ guid, modifyQuery, numInputDatasets, show, unifiedDatasetSelected, unifiedDatasetChecked, selectedDatasetIds, numCheckedDatasets, onOpenDialog, onHide, onSubmit }) => {
  const currentDatasetScopeMsg = datasetsToString(unifiedDatasetSelected, selectedDatasetIds.size, numInputDatasets);
  return (
    <div>
      {!unifiedDatasetSelected ? (
        <TooltipTrigger placement="right" content="Select datasets to transform">
          <Button onClick={onOpenDialog} buttonType="Link">
            {currentDatasetScopeMsg}
          </Button>
        </TooltipTrigger>
      ) : currentDatasetScopeMsg}
      <DatasetFilterDialog
        {...{ show, onHide, onSubmit, modifyQuery }}
        onSubmit={(datasetsToAdd, datasetsToRemove) => onSubmit(guid, datasetsToAdd, datasetsToRemove)}
        getSelectedDatasets={(datasets) => datasets.filter(d => selectedDatasetIds.has(d.id.id)).map(d => d.data.name).toSet()}
        applyButtonText={`Transform ${datasetsToString(unifiedDatasetChecked, numCheckedDatasets, numInputDatasets)}`}
        title="Select datasets to transform"
      />
    </div>
  );
});

TransformsDatasetSelector.propTypes = {
  guid: PropTypes.string.isRequired,
};

export default TransformsDatasetSelector;
