import React from 'react';
import { connect } from 'react-redux';

import DetailSidebar from '../components/DetailSidebar/DetailSidebar';
import { AppState } from '../stores/MainStore';
import ReadOnlyGoldenRecordsSidebarContent from './ReadOnlyGoldenRecordsSidebarContent';


const GoldenRecordsSidebar = connect((state: AppState) => {
  const { goldenRecords: { showSidebar } } = state;
  return { showSidebar };
})(({ showSidebar }) => {
  return (
    <DetailSidebar
      isExpanded={showSidebar}
    >
      <ReadOnlyGoldenRecordsSidebarContent />
    </DetailSidebar>
  );
});

export default GoldenRecordsSidebar;
