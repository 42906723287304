import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Chrome from '../chrome/Chrome';
import LoadingPanel from '../components/LoadingPanel';
import CreateUADialog from '../records/CreateUADialog';
import CreateUnifiedDatasetPageContent from '../records/CreateUnifiedDatasetPageContent';
import RecordsFilter from '../records/RecordsFilter';
import RecordsHeader from '../records/RecordsHeader';
import RecordsLoader from '../records/RecordsLoader';
import RecordsSidebar from '../records/RecordsSidebar';
import RecordsTable from '../records/RecordsTable';
import UpdateUnifiedDatasetDialog from '../schema-mapping/UpdateUnifiedDatasetDialog';
import ConfirmDeleteTransformDialog from '../transforms/ConfirmDeleteTransformDialog';
import ListUpdateConflictDialog from '../transforms/ListUpdateConflictDialog';
import RebaseConflictDialog from '../transforms/RebaseConflictDialog';
import TransformationStepsButton from '../transforms/TransformationStepsButton';
import TransformEditorPanel from '../transforms/TransformEditorPanel';
import { isCuratorByProjectId } from '../utils/Authorization';
import { getAuthorizedUser, isUnifiedDatasetCreated, selectActiveProjectInfo } from '../utils/Selectors';
import style from './UnifiedDatasetRecords.module.scss';

const UnifiedDatasetRecords = _.compose(
  connect((state) => {
    const {
      records: { expanded },
      projects: { initialFetch: initialProjectsFetch },
    } = state;

    const unifiedDatasetCreated = isUnifiedDatasetCreated(state);

    return {
      initialProjectsFetch,
      expanded,
      unifiedDatasetCreated,
      canUserEdit: isCuratorByProjectId(getAuthorizedUser(state), selectActiveProjectInfo(state)?.projectDoc.id.id),
    };
  }),
)(class UnifiedDatasetRecords extends React.PureComponent {
  static propTypes = {
    expanded: PropTypes.bool.isRequired,
  };

  render() {
    const {
      initialProjectsFetch,
      expanded,
      unifiedDatasetCreated,
      canUserEdit,
    } = this.props;

    if (!initialProjectsFetch) {
      return (
        <Chrome className={style.component}>
          <LoadingPanel />
        </Chrome>
      );
    }
    if (!unifiedDatasetCreated) {
      return (
        <Chrome className={style.component}>
          <CreateUnifiedDatasetPageContent />
        </Chrome>
      );
    }
    return (
      <Chrome
        rightSidebar={unifiedDatasetCreated ? <RecordsSidebar /> : undefined}
        isExpanded={expanded}
        className={style.component}
      >
        <RecordsLoader />
        <RecordsHeader />
        <div className={style.filterContainer}>
          <RecordsFilter />
          <TransformationStepsButton />
          {canUserEdit ? <CreateUADialog /> : null}
        </div>
        <div className={style.tableContainer}>
          <RecordsTable />
          <TransformEditorPanel />
        </div>
        <UpdateUnifiedDatasetDialog />
        <ConfirmDeleteTransformDialog />
        <RebaseConflictDialog />
        <ListUpdateConflictDialog />
      </Chrome>
    );
  }
});

export default UnifiedDatasetRecords;
