import React from 'react';
import { connect } from 'react-redux';

import { ConfidenceRangeE, HIGH, LOW, MEDIUM } from '../../constants/ConfidenceRange';
import { AppAction } from '../../stores/AppAction';
import AppState from '../../stores/AppState';
import PRODUCT_NAME from '../../utils/ProductName';
import SelectFilter from './SelectFilter';


const ANY = 'ANY' as const;

type SelectorValue = ConfidenceRangeE | typeof ANY;

const values = [
  { display: 'No Filter', value: ANY },
  { display: 'High', value: HIGH },
  { display: 'Medium', value: MEDIUM },
  { display: 'Low', value: LOW },
];

const PairConfidenceFilter = connect(({ recordPairs: { confidenceRangeFilter } }: AppState) => {
  return { selectedConfidenceRange: confidenceRangeFilter };
}, {
  onSetConfidenceRangeFilter: (confidenceRangeFilter: ConfidenceRangeE | undefined): AppAction => ({ type: 'RecordPairs.setConfidenceRangeFilter', confidenceRangeFilter }),
})(({ onSetConfidenceRangeFilter, selectedConfidenceRange }) => {
  return (
    <SelectFilter<SelectorValue>
      label={`${PRODUCT_NAME}'s confidence`}
      onChange={(value) => onSetConfidenceRangeFilter(value === ANY ? undefined : value)}
      values={values}
      value={selectedConfidenceRange || ANY}
    />
  );
});

export default PairConfidenceFilter;
