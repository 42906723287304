import PropTypes from 'prop-types';
import React from 'react';

import { LoggedInUser } from '../chrome/UserAvatar';
import style from './ActivitySidebarTab.module.scss';
import Button from './Button';
import TextArea from './Input/TextArea';
import TamrIcon from './TamrIcon';

const NO_MESSAGES = { message: '' };

type ActivitySidebarTabProps = {
  focusSequence: number
  onAddComment: (comment: string) => void
  commentsList: React.ReactNode
}

class ActivitySidebarTab extends React.PureComponent<ActivitySidebarTabProps> {
  static propTypes = {
    commentsList: PropTypes.object,
    focusSequence: PropTypes.number,
    onAddComment: PropTypes.func.isRequired,
  };

  state = NO_MESSAGES;

  commentChange = (message: string) => {
    this.setState({ message });
  };

  UNSAFE_componentWillMount() {
    this.setState(NO_MESSAGES);
  }

  componentDidMount() {
    this.focusComment();
  }

  componentDidUpdate({ focusSequence }: ActivitySidebarTabProps) {
    if (focusSequence !== this.props.focusSequence) {
      this.focusComment();
    }
  }

  focusComment = () => {
    // @ts-expect-error
    this.refs.commentField.focus();
  };

  addComment = () => {
    if (this.state.message.length > 0) {
      this.props.onAddComment(this.state.message);
      this.setState(NO_MESSAGES);
    }
  };

  doSubmit = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      this.addComment();
    }
  };

  render() {
    const isEnabled = this.state.message.length > 0;
    return (
      <div className={style.activitySidebarTab}>
        <div className={style.headerSection}>
          <LoggedInUser className={style.userBackground} useToolTip={false} />
          <div className={style.commentInput}>
            <TextArea
              ref="commentField"
              className={style.commentField}
              maxRows={5}
              value={this.state.message}
              onKeyDown={this.doSubmit}
              onChange={this.commentChange}
              placeholder="Write your comment here"
            />
            <div className={style.textBoxInfo}>
              <TamrIcon
                className={style.subArraow}
                iconName="subdirectoryarrow"
                size={14}
              />
              <span className={style.info}>Shift + Enter for a new line</span>
            </div>
            <Button
              className={style.addCommentButton}
              onClick={this.addComment}
              disabled={!isEnabled}
            >
              Add comment
            </Button>
          </div>
        </div>
        {this.props.commentsList}
      </div>
    );
  }
}

export default ActivitySidebarTab;
