import { is, List, Map, OrderedMap, Record } from 'immutable';
import $ from 'jquery';
import _ from 'underscore';

import { getTransforms } from '../api/TransformClient';
import { updateUserPreferences } from '../auth/AuthAsync';
import { TRANSFORMS } from '../constants/DataTables';
import { TAMR_ID } from '../constants/ElasticConstants';
import { TRANSFORMATION_LIST_UPDATE } from '../constants/MessageType';
import { SHOW } from '../errorDialog/ErrorDialogActionTypes';
import Signature from '../functionDocs/models/Signature';
import BulkTransform from '../models/BulkTransform';
import Model from '../models/Model';
import { Formula, Script, supportedTransformArgType } from '../models/Transforms';
import { commitUnifiedAttributes } from '../schema-mapping/SchemaMappingAsync';
import { AppState } from '../stores/MainStore';
import { sendMessage } from '../stores/MessagingApi';
import { ArgTypes, checkArg, checkReturn } from '../utils/ArgValidation';
import { isCuratorByProjectId } from '../utils/Authorization';
import {
  getAuthorizedUser,
  getUnifiedDatasetName,
  selectActiveProjectInfo,
} from '../utils/Selectors';
import ChangedTransformsMessage from './models/ChangedTransformsMessage';
import Lint from './models/Lint';
import { AnalysisInput, OperationListResult } from './models/StaticAnalysisModels';
import TransformDelta from './models/TransformDelta';
import { RecordType, RecordTypeArgType } from './models/Types';
import {
  getUnifiedOperationList,
  inferUnifiedDatasetSchema,
  selectUnifiedOperationsReferencedDatasets,
} from './TransformsStore';

const STATIC_ANALYSIS_ERROR_CODE = 422;
const REBASE_CONFLICT_CODE = 409;

const TransformPrefs = Model({
  page: { type: ArgTypes.eq(TRANSFORMS), defaultValue: TRANSFORMS },
  recipeId: { type: ArgTypes.number },
  sourceTransformationListExpanded: { type: ArgTypes.bool, defaultValue: true },
  unifiedTransformationListExpanded: { type: ArgTypes.bool, defaultValue: true },
});

const getTransformPagePrefs = checkReturn(ArgTypes.instanceOf(TransformPrefs), (state) => {
  checkArg({ state }, ArgTypes.instanceOf(AppState));
  const { location: { recipeId } } = state;
  return new TransformPrefs(
    getAuthorizedUser(state).preferencesForPage(TRANSFORMS, recipeId) || { recipeId },
  );
});

export const getTransformListExpanded = checkReturn(ArgTypes.bool, (state, sourceLevel) => {
  checkArg({ state }, ArgTypes.instanceOf(AppState));
  checkArg({ sourceLevel }, ArgTypes.bool);
  const userTransformPrefs = getTransformPagePrefs(state);
  return sourceLevel ? userTransformPrefs.sourceTransformationListExpanded : userTransformPrefs.unifiedTransformationListExpanded;
});

export const setTransformListExpanded = (sourceLevel, expanded) => (dispatch, getState) => {
  checkArg({ sourceLevel }, ArgTypes.bool);
  checkArg({ expanded }, ArgTypes.bool);
  const state = getState();
  const updatedTransformPrefs = getTransformPagePrefs(state)
    .set(sourceLevel ? 'sourceTransformationListExpanded' : 'unifiedTransformationListExpanded', expanded);
  const updatedAuthUser = getAuthorizedUser(state)
    .updatePagePrefs(updatedTransformPrefs);
  dispatch(updateUserPreferences(updatedAuthUser));
};

/**
 * Reset the store to its initial (empty) state
 */
export const clearStore = () => (dispatch) => {
  dispatch({ type: 'Transforms.clearStore' });
};

/**
 * Clear the deltas from the state
 */
export const clearDeltas = () => (dispatch) => {
  dispatch({ type: 'Transforms.clearDeltas' });
};

// The update list endpoint returns { transforms } if not a dry run, or
// { transforms, savedTransforms } if dry run.
// this method will prefer using savedTransforms if present
const parseUpdateListResponse = checkReturn(ArgTypes.object.withShape({
  transforms: ArgTypes.Immutable.orderedMap.of(ArgTypes.instanceOf(BulkTransform), ArgTypes.string),
}), (rawResponse) => {
  checkArg({ rawResponse }, ArgTypes.oneOf(
    // The update list endpoint returns an array if not a dry run
    ArgTypes.array,
    // Or, it returns { transforms, savedTransforms } if dry run
    ArgTypes.object.withShape({
      transforms: ArgTypes.array,
      savedTransforms: ArgTypes.array,
    }),
  ));
  const transforms = rawResponse.savedTransforms || rawResponse.transforms || rawResponse;
  const parsedTransforms = OrderedMap(transforms && transforms.map(
    item => [item.guid, BulkTransform.fromJS(item.data)],
  ) || []);
  return { transforms: parsedTransforms };
});

const fetchTransforms = (unifiedDatasetName, unifiedDatasetId) => (dispatch) => {
  return getTransforms(unifiedDatasetName).then((data) => {
    const { transforms } = parseUpdateListResponse(data);
    dispatch({
      type: 'Transforms.updateListCompleted',
      loadedDatasetId: unifiedDatasetId,
      transforms,
      saved: true,
    });
  });
};

const doUpdateList = checkReturn(ArgTypes.deferred.withResolution(ArgTypes.object.withShape({
  transforms: ArgTypes.Immutable.map.of(ArgTypes.instanceOf(BulkTransform), ArgTypes.string),
})), ({ datasetName, dryRun, deltasToSend }) => {
  checkArg({ datasetName }, ArgTypes.string);
  checkArg({ dryRun }, ArgTypes.bool);
  checkArg({ deltasToSend }, ArgTypes.Immutable.list.of(TransformDelta.argType));
  const dryRunFlag = dryRun ? '?dry-run=true' : '';
  return $.ajax({
    url: SERVICES.transform(`/transform/${datasetName}${dryRunFlag}`),
    dataType: 'json',
    contentType: 'application/json',
    data: JSON.stringify(deltasToSend.map(bulkTransform => bulkTransform.toServerJSON())),
    method: 'POST',
  }).then((rawResponse) => {
    return parseUpdateListResponse(rawResponse);
  });
});

/**
 * Re-fetch the list of transforms from the server, POSTs any pending deltas
 */
export const updateList = (isSave = false) => (dispatch, getState) => {
  checkArg({ isSave }, ArgTypes.bool);
  dispatch({ type: 'Transforms.updateList' });
  const state = getState();
  const { transforms: { deltas, loadedDatasetId } } = state;
  const { unifiedDatasetId, smRecipeId, unifiedDatasetName, projectId } = selectActiveProjectInfo(state);

  if (!isCuratorByProjectId(getAuthorizedUser(state), projectId)) {
    // if the user is not at least a curator of a project, we don't want to update transformations'
    // deltas since the user won't have UPDATE access to the project. So simply fetch the current
    // transformations instead.
    return dispatch(fetchTransforms(unifiedDatasetName, unifiedDatasetId));
  }

  // user must be at least curator to post deltas for transformations
  const handler = (success, parsedResponse) => {
    const { transforms } = parsedResponse;

    if (isSave) {
      sendMessage(TRANSFORMATION_LIST_UPDATE, new ChangedTransformsMessage({
        smRecipeId,
      }));
    }
    dispatch({
      type: 'Transforms.updateListCompleted',
      loadedDatasetId: unifiedDatasetId,
      transforms,
      saved: isSave && success,
    });
  };

  const deltasToSend = unifiedDatasetId === loadedDatasetId ? deltas : List();

  return doUpdateList({
    datasetName: unifiedDatasetName,
    dryRun: !isSave,
    deltasToSend,
  }).then((parsedResponse) => {
    handler(true, parsedResponse);
  }).fail((jqXhr) => {
    const { status, responseJSON } = jqXhr;
    const dueToConflict = status === REBASE_CONFLICT_CODE;
    if (!loadedDatasetId) {
      // this is the first fetch, meaning there are saved errors in the transforms
      // need a way (any way) to get the transforms into the UI so the errors can be fixed
      dispatch(fetchTransforms(unifiedDatasetName, unifiedDatasetId));
    }
    dispatch({
      type: 'Transforms.updateListFailed',
      conflictMessage: dueToConflict ? responseJSON.message : undefined,
      loadedDatasetId: unifiedDatasetId,
    });
    if (!dueToConflict) {
      // unexpected error
      dispatch({ type: SHOW,
        detail: 'Error saving transformations. Your local changes have not been affected.',
        response: jqXhr,
      });
    }
  });
};

export const rebaseTransforms = () => (dispatch, getState) => {
  const state = getState();
  const { transforms: { deltas } } = state;
  const { unifiedDatasetName } = selectActiveProjectInfo(state);
  dispatch({ type: 'Transforms.rebase' });

  const successHandler = ({ transforms, errors }) => {
    dispatch({
      type: 'Transforms.rebaseCompleted',
      transforms,
      errors,
    });
  };

  doUpdateList({
    datasetName: unifiedDatasetName,
    dryRun: true,
    deltasToSend: deltas,
  }).then((parsedResponse) => {
    successHandler(parsedResponse);
  }).fail((jqXhr) => {
    const { status, responseJSON } = jqXhr;
    if (status === STATIC_ANALYSIS_ERROR_CODE) {
      successHandler(parseUpdateListResponse(responseJSON));
      return;
    }
    if (status === REBASE_CONFLICT_CODE) {
      dispatch({ type: 'Transforms.rebaseFailedDueToConflict' });
      return;
    }
    dispatch({
      type: 'Transforms.rebaseFailed',
    });
    dispatch({
      type: SHOW,
      detail: 'Error refreshing transform list',
      response: jqXhr,
    });
  });
};

const LINT_DELAY_MS = 200;
const lintableTransformType = ArgTypes.oneOf(ArgTypes.instanceOf(Formula), ArgTypes.instanceOf(Script));

const doLint = (draft, guid, dispatch) => {
  checkArg({ draft }, lintableTransformType);
  checkArg({ guid }, ArgTypes.string);
  // if transform box is empty, let ValidationError ecosystem handle error reporting
  let ajaxCall;
  if (draft.className === 'Formula' && draft.expr === '' || draft.className === 'Script' && draft.op === '') {
    ajaxCall = $.Deferred().resolve(List());
  } else {
    ajaxCall = $.ajax({
      url: SERVICES.transform('/statements/lint'),
      method: 'POST',
      data: draft.toTransformText(),
      contentType: 'application/json',
    }).then((lintingErrorsArr) => {
      checkArg({ lintingErrorsArr }, ArgTypes.array.of(ArgTypes.object));
      return List(lintingErrorsArr).map(Lint.fromJSON);
    });
  }
  ajaxCall.then((lintingErrors) => dispatch({ type: 'Transforms.lint', guid, lintingErrors }));
};


export const fetchFunctionNames = () => (dispatch) => {
  dispatch({ type: 'Transforms.fetchFunctionNames' });
  return $.ajax({
    url: SERVICES.transform('/functions'),
    dataType: 'json',
    contentType: 'application/json',
    method: 'GET',
  }).then((resp) => {
    const functionDocs = new List(resp).map(json => Signature.fromJSON(json));
    return dispatch({
      type: 'Transforms.fetchFunctionNamesCompleted',
      functionDocs,
    });
  });
};

export const fetchHintNames = () => (dispatch) => {
  dispatch({ type: 'Transforms.fetchHintNames' });
  return $.ajax({
    url: SERVICES.transform('/hints'),
    dataType: 'json',
    contentType: 'application/json',
    method: 'GET',
  }).then((rawResponse) => {
    return dispatch({
      type: 'Transforms.fetchHintNamesCompleted',
      hintNames: List(rawResponse.map(k => k.toLowerCase())),
    });
  });
};

const debouncedLint = _.debounce(doLint, LINT_DELAY_MS);
/**
 * Check whether a lintable transform's statement is syntactically valid
 */
export const lintStatement = (draft) => (dispatch, getState) => {
  checkArg({ draft }, lintableTransformType);
  // always gets called in reference to the selected (expanded) transform
  const guid = getState().transforms.selected;
  debouncedLint(draft, guid, dispatch);
};

export const doGetDatasetTypes = checkReturn(ArgTypes.deferred.withResolution(ArgTypes.Immutable.map.of(RecordTypeArgType, ArgTypes.string)), (datasetNames) => {
  checkArg({ datasetNames }, ArgTypes.Immutable.set.of(ArgTypes.string));
  return $.ajax({
    url: SERVICES.transform('/get-dataset-types'),
    method: 'POST',
    data: JSON.stringify(datasetNames),
    contentType: 'application/json',
  }).then(data => {
    return Map(data).map(type => RecordType.fromJSON(type));
  });
});

export const doGetIdFields = checkReturn(ArgTypes.deferred.withResolution(ArgTypes.Immutable.map.of(ArgTypes.Immutable.list.of(ArgTypes.string), ArgTypes.string)), (datasetNames) => {
  checkArg({ datasetNames }, ArgTypes.Immutable.set.of(ArgTypes.string));
  return $.ajax({
    url: SERVICES.transform('/get-dataset-pk'),
    method: 'POST',
    data: JSON.stringify(datasetNames),
    contentType: 'application/json',
  }).then(data => {
    return Map(data).map(pk => List(pk));
  });
});

export const doGetDatasetTypesAndKeys = (datasetNames) => {
  checkArg({ datasetNames }, ArgTypes.Immutable.set.of(ArgTypes.string));
  return doGetDatasetTypes(datasetNames)
    .then(async (datasetTypes) => {
      const validDatasetNames = datasetTypes.keySeq().toSet();
      const datasetIDs = await doGetIdFields(validDatasetNames);
      return [datasetTypes, datasetIDs];
    });
};

export const doAnalyzeTypes = checkReturn(ArgTypes.deferred.withResolution(OperationListResult.argType), (analysisInput) => {
  checkArg({ analysisInput }, AnalysisInput.argType);
  return $.ajax({
    url: SERVICES.transform('/analyze-types'),
    method: 'POST',
    data: JSON.stringify(analysisInput),
    contentType: 'application/json',
  }).then(result => {
    return OperationListResult.fromCompressedJSON(result);
  });
});

const StaticAnalysisInfo = Record({
  unifiedDatasetName: null,
  operationList: null,
});

export const getStaticAnalysisInfo = (state) => {
  checkArg({ state }, ArgTypes.instanceOf(AppState));
  return new StaticAnalysisInfo({
    unifiedDatasetName: getUnifiedDatasetName(state),
    operationList: getUnifiedOperationList(state.transforms),
  });
};
const doPerformStaticAnalysis = (dispatch, getState) => {
  const state = getState();
  const { transforms, transforms: { loadedUnifiedTransformsReferencedDatasetNames, unifiedTransformsReferencedDatasetTypes, unifiedTransformsReferencedDatasetKeys }, schemaMapping: { allUnifiedAttributes } } = state;
  const staticAnalysisInfo = getStaticAnalysisInfo(state);
  const { operationList } = staticAnalysisInfo;
  dispatch({ type: 'Transforms.performStaticAnalysis', operationList });
  // first, gather the types of reference datasets
  const unifiedTransformsReferencedDatasetNames = selectUnifiedOperationsReferencedDatasets(transforms);
  const loadedReferenceDatasetNames = loadedUnifiedTransformsReferencedDatasetNames;
  let referenceDatasetTypeAndKeyFetch;
  if (is(unifiedTransformsReferencedDatasetNames, loadedReferenceDatasetNames)) {
    referenceDatasetTypeAndKeyFetch = async () => {
      const types = unifiedTransformsReferencedDatasetTypes;
      const ids = unifiedTransformsReferencedDatasetKeys;
      return [types, ids];
    };
  } else {
    referenceDatasetTypeAndKeyFetch = () => doGetDatasetTypesAndKeys(unifiedTransformsReferencedDatasetNames);
  }
  referenceDatasetTypeAndKeyFetch().then(([referenceTypes, referenceKeys]) => {
    const activeKey = List.of(TAMR_ID);
    // need the active type now, the staged Unified Dataset
    const activeType = inferUnifiedDatasetSchema(allUnifiedAttributes);
    const analysisInput = new AnalysisInput({ activeType, referenceTypes, activeKey, referenceKeys, operationList });
    doAnalyzeTypes(analysisInput).then(operationListResult => {
      dispatch({ type: 'Transforms.performStaticAnalysisCompleted', staticAnalysisInfo, operationListResult, unifiedTransformsReferencedDatasetNames, analysisInput });
    }).fail(() => {
      // TODO how to we capture this error? usually translates to a fix on the back end
      dispatch({ type: 'Transforms.performStaticAnalysisFailed', staticAnalysisInfo, unifiedTransformsReferencedDatasetNames, analysisInput });
    });
  });
};
const debouncedPerformStaticAnalysis = _.debounce(doPerformStaticAnalysis, LINT_DELAY_MS);
export const performStaticAnalysis = () => (dispatch, getState) => {
  debouncedPerformStaticAnalysis(dispatch, getState);
};

/**
 * Writes the current deltas to the ledger, if successful they will be cleared
 */
export const saveTransforms = () => (dispatch, getState) => {
  updateList(true)(dispatch, getState);
};

/**
 * Writes the current deltas to the ledger, if successful they will be cleared and then the unified attributes will
 * be committed. However, if the ajax call fails they will not be cleared and the unified attributes will
 * not be committed.
 */
export const saveTransformsAndCommitUnifiedAttributes = () => (dispatch, getState) => {
  updateList(true)(dispatch, getState)
    .done(() => dispatch(commitUnifiedAttributes()));
};

/**
 * edit a transform and, if applicable, trigger a lint call
 */
export const editOperation = ({ guid, operation }) => (dispatch) => {
  checkArg({ operation }, supportedTransformArgType);
  dispatch({ type: 'Transforms.editOperation', guid, operation });
  if (operation instanceof Formula || operation instanceof Script) {
    // lintable
    debouncedLint(operation, guid, dispatch);
  }
};
