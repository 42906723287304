import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Chart, Line } from 'react-chartjs-2';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import emptyGraph from '../../images/empty-categorization-performance-graph.png';
import Button from '../components/Button';
import Selector from '../components/Input/Selector';
import LoadingPanel from '../components/LoadingPanel';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import TooltipTrigger from '../components/TooltipTrigger';
import DashboardTimeSelector from '../constants/DashboardTimeSelector';
import ModelPerformance from '../models/ModelPerformance';
import { history } from '../utils/History';
import { commafy } from '../utils/Numbers';
import PRODUCT_NAME from '../utils/ProductName';
import { getActiveRecipeDoc } from '../utils/Selectors';
import { getTerm } from '../utils/Terms';
import style from './CategorizationDashboardPerformanceCard.module.scss';

const GRADIENT_PLUGGIN_ID = 'area-gradient';
const CHART_HEIGHT = 205;

const navigate = (url, onResetTransactionsFilterState) => {
  if (_.isFunction(onResetTransactionsFilterState)) {
    onResetTransactionsFilterState();
  }
  history.push(url);
};

const CategorizationDashboardPerformanceCard = _.compose(
  connect(state => {
    const { categorizationDashboard: { performanceOverTime, selectedConfidenceTimeRange, loading } } = state;
    const categorizationRecipeId = getActiveRecipeDoc(state).id.id;
    const recordsTerm = getTerm(state, 'records');
    return { recipeId: categorizationRecipeId, performanceOverTime, selectedConfidenceTimeRange, recordsTerm, loading };
  }, {
    onResetTransactionsFilterState: () => ({ type: 'Transactions.resetFilters' }),
    onSetSelectedConfidenceTimeRange: selectedConfidenceTimeRange => ({ type: 'CategorizationDashboard.setSelectedConfidenceTimeRange', selectedConfidenceTimeRange }),
  }),
)(class CategorizationDashboardPerformanceCard extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    onResetTransactionsFilterState: PropTypes.func.isRequired,
    onSetSelectedConfidenceTimeRange: PropTypes.func.isRequired,
    performanceOverTime: ImmutablePropTypes.listOf(PropTypes.instanceOf(ModelPerformance)),
    recipeId: PropTypes.number,
    recordsTerm: PropTypes.string.isRequired,
  };

  componentDidMount() {
    Chart.pluginService.register({
      id: GRADIENT_PLUGGIN_ID,
      beforeDatasetsDraw: (chart, easing, options) => {
        if (!options.enabled) {
          return;
        }
        const { ctx, chartArea } = chart;

        // Draw dashed x-axis
        ctx.beginPath();
        ctx.save();
        ctx.setLineDash([5, 12]);
        ctx.moveTo(chartArea.left, chartArea.bottom);
        ctx.lineTo(chartArea.right, chartArea.bottom);
        ctx.strokeStyle = '#D2D5D7';
        ctx.stroke();
        ctx.restore();
      },
    });
  }

  renderEmpty = () => {
    const { recipeId, onResetTransactionsFilterState } = this.props;
    return (
      <div className={style.container}>
        <div className={style.title}>
          Average <Term>Record</Term> Confidence
          <TooltipTrigger
            delayHide={2000}
            content={<span>{PRODUCT_NAME}’s confidence is a measure how certain its suggestions are. Verifying multiple <Term>records</Term> in each of your categories will increase {PRODUCT_NAME}’s overall confidence. <a href="https://docs.tamr.com/new/docs/filtering-records-in-categorization-projects" target="_blank">Learn more</a></span>}
            placement="bottom"
          >
            <span className={style.infoIcon}>
              <TamrIcon iconName="info-outline" size={14} />
            </span>
          </TooltipTrigger>
        </div>
        <div className={style.paragraph}>
          You’ll see {PRODUCT_NAME}’s average <Term>record</Term> confidence over time here once you’ve generated {PRODUCT_NAME} categorizations.
        </div>
        <Button
          className={style.toRecordsButton}
          buttonType="Secondary"
          onClick={() => navigate(`/spend/recipe/${recipeId}`, onResetTransactionsFilterState)}
        >
          Go to <Term>records</Term>
        </Button>
        <div className={style.emptyGraphContainer}>
          <img src={emptyGraph} className={style.emptyGraph} style={{ height: 151 }} />
        </div>
      </div>
    );
  };

  render() {
    const { recipeId, performanceOverTime, onResetTransactionsFilterState, selectedConfidenceTimeRange, onSetSelectedConfidenceTimeRange, recordsTerm, loading } = this.props;

    if (loading) {
      return <LoadingPanel />;
    }

    let minDate;
    if (selectedConfidenceTimeRange === DashboardTimeSelector.WEEK) {
      minDate = moment().subtract(1, 'weeks');
    } else if (selectedConfidenceTimeRange === DashboardTimeSelector.MONTH) {
      minDate = moment().subtract(1, 'months');
    } else if (selectedConfidenceTimeRange === DashboardTimeSelector.YEAR) {
      minDate = moment().subtract(1, 'years');
    }

    const pointsWithAccuracy = performanceOverTime
      .filter(p => _.isNumber(p.accuracy));

    const validPoints = pointsWithAccuracy
      // Filter points that fall within the desired range
      .filter((p, i) => {
        if (selectedConfidenceTimeRange === DashboardTimeSelector.ALL) {
          return true;
        }
        return (i === pointsWithAccuracy.size - 1) || // Make sure we at least include the latest point, regardless of the filter
          moment(p.time * 1000).isSameOrAfter(minDate);
      });
    const latestPoint = validPoints.last();
    if (!latestPoint) {
      return this.renderEmpty();
    }

    const lineData = (canvas) => {
      const ctx = canvas.getContext('2d');
      const gradient = ctx.createLinearGradient(0, 0, 0, CHART_HEIGHT);
      gradient.addColorStop(0, 'rgba(207,228,240,1)');
      gradient.addColorStop(0.5, 'rgba(207,228,240,0.9)');
      gradient.addColorStop(1, 'rgba(207,228,240,0.1)');

      return {
        datasets: [
          {
            label: 'Average Confidence',
            fill: true,
            lineTension: 0,
            backgroundColor: gradient,
            borderColor: '#29384C',
            pointBackgroundColor: '#29384C',
            borderWidth: 1,
            borderCapStyle: 'butt',
            data: validPoints.map(p => Math.round(p.accuracy * 100)).toJSON(),
          },
        ],
        labels: validPoints.map(p => moment(p.time * 1000).format('M/D/YY')).toJSON(),
      };
    };
    const options = {
      legend: {
        display: false,
      },
      onClick: () => {
        navigate(`/spend/recipe/${recipeId}`, onResetTransactionsFilterState);
      },
      scales: {
        xAxes: [{
          gridLines: {
            drawOnChartArea: false,
            borderDash: [10, 4],
            color: '#FFF',
          },
          ticks: {
            fontFamily: style.labelFont,
            fontColor: style.labelColor,
          },
        }],
        yAxes: [{
          gridLines: {
            drawOnChartArea: false,
            drawBorder: false,
            color: '#FFF',
            zeroLineColor: '#FFF',
          },
          ticks: {
            callback: d => `${d}%`,
            stepSize: 25,
            min: 0,
            max: 100,
            fontFamily: style.labelFont,
            fontColor: style.labelColor,
          },
        }],
      },
      tooltips: {
        enabled: true,
        displayColors: false,
        backgroundColor: '#FFFFFF',
        titleFontColor: '#29384C',
        bodyFontColor: '#29384C',
        borderColor: '#29384C',
        borderWidth: 0.5,
        callbacks: {
          title: tooltipItems => {
            const data = validPoints.get(tooltipItems[0].index);
            return `UPDATE - ${moment(data.time * 1000).format('M/D/YY HH:mm')}`;
          },
          label: tooltipItem => {
            const data = validPoints.get(tooltipItem.index);
            return [
              `Average confidence: ${Math.round(data.accuracy * 100)}%`,
              `Total verified ${recordsTerm}: ${commafy(data.agreesWithTamr + data.disagreesWithTamr)}`,
              `Verified agrees with ${PRODUCT_NAME}: ${commafy(data.agreesWithTamr)}`,
            ];
          },
        },
      },
      plugins: {
        [GRADIENT_PLUGGIN_ID]: {
          enabled: true,
        },
      },
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 20,
        },
      },
    };

    const title = `${Math.round(latestPoint.accuracy * 100)}% Confidence`;
    return (
      <div className={style.container}>
        <div className={style.titleBar}>
          <div className={style.title}>
            {title}
            <TooltipTrigger
              delayHide={2000}
              content={<span>{PRODUCT_NAME}’s confidence is a measure how certain its suggestions are. Verifying multiple <Term>records</Term> in each of your categories will increase {PRODUCT_NAME}’s overall confidence. <a href="https://docs.tamr.com/new/docs/filtering-records-in-categorization-projects" target="_blank">Learn more</a></span>}
              placement="bottom"
            >
              <span className={style.infoIcon}>
                <TamrIcon iconName="info-outline" size={14} />
              </span>
            </TooltipTrigger>
          </div>
          <div className={style.selectChooser}>
            <Selector
              value={selectedConfidenceTimeRange}
              onChange={onSetSelectedConfidenceTimeRange}
              values={[
                { display: 'Year', value: DashboardTimeSelector.YEAR },
                { display: 'Month', value: DashboardTimeSelector.MONTH },
                { display: 'Week', value: DashboardTimeSelector.WEEK },
                { display: 'All time', value: DashboardTimeSelector.ALL },
              ]}
            />
          </div>
        </div>
        <div className={style.chartContainer}>
          <Line data={lineData} options={options} height={CHART_HEIGHT} />
        </div>
      </div>
    );
  }
});

export default CategorizationDashboardPerformanceCard;
