import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React, { Requireable } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

const propTypes = {
  /**
   * Path to render in order of appearance
   */
  path: ImmutablePropTypes.listOf(PropTypes.shape({
    /**
     * Optional action to take when clicking this particular element in the path
     */
    onClick: PropTypes.func as Requireable<() => void>,
    /**
     * Text value do display for this particular path element
     */
    pathSegmentName: PropTypes.node.isRequired,
  }).isRequired).isRequired,
};

type BreadcrumbProps = InferProps<typeof propTypes>;

/**
 * Component used to render an optionally clickable breadcrumb
 */
class Breadcrumb extends React.Component<BreadcrumbProps> {
  static propTypes = propTypes;

  render() {
    const { path } = this.props;
    return (
      <div className="breadcrumb-component">
        {path.map(({ pathSegmentName, onClick }, i) =>
          (<span className="breadcrumb-item" key={i}>
            <span className={classNames({ 'breadcrumb-link': !!onClick })} onClick={onClick || undefined}>
              {pathSegmentName}
            </span>
          </span>),
        )}
      </div>
    );
  }
}

export default Breadcrumb;
