import { List } from 'immutable';
import moment from 'moment';

import CountsOverTime from '../models/CountsOverTime';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';

/**
 * Model for the response of the endpoint in the dedup service:
 *     POST /mastering/published-cluster-versions/{dataset}
 */

class RecordId extends getModelHelpers({
  entityId: { type: ArgTypes.string },
  originSourceId: { type: ArgTypes.string },
  originEntityId: { type: ArgTypes.string },
}, 'RecordId')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class RecordRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: $TSFixMe) {
    checkArg({ obj }, ArgTypes.object);
    const { entityId, originSourceId, originEntityId } = obj;
    return new RecordId({
      entityId,
      originSourceId,
      originEntityId,
    });
  }
}

class Record extends getModelHelpers({
  persistentId: { type: ArgTypes.string },
  name: { type: ArgTypes.nullable(ArgTypes.string) },
  recordCount: { type: ArgTypes.number },
  totalSpend: { type: ArgTypes.number },
  verifiedLockRecordCount: { type: ArgTypes.number },
  averageLinkage: { type: ArgTypes.nullable(ArgTypes.number) },
  recordIds: { type: ArgTypes.Immutable.list.of(RecordId.argType) },
}, 'Record')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class RecordRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: $TSFixMe) {
    checkArg({ obj }, ArgTypes.object);
    const { persistentId, name, recordCount, totalSpend, clusterVerificationCounts, averageLinkage, recordIds } = obj;
    return new Record({
      persistentId,
      name,
      recordCount,
      totalSpend,
      verifiedLockRecordCount: clusterVerificationCounts ? clusterVerificationCounts.locked : 0,
      averageLinkage,
      recordIds: List(recordIds).map(RecordId.fromJSON),
    });
  }
}

class Version extends getModelHelpers({
  datasetVersion: { type: ArgTypes.number },
  materializationDate: { type: ArgTypes.timestamp },
  record: { type: Record.argType },
}, 'Version')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class VersionRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: $TSFixMe) {
    checkArg({ obj }, ArgTypes.object);
    const { datasetVersion, materializationDate, record } = obj;
    return new Version({
      datasetVersion,
      materializationDate: moment(materializationDate).valueOf(),
      record: Record.fromJSON(record),
    });
  }

  get countsOverTime() {
    return new CountsOverTime({
      time: this.materializationDate,
      count: this.record.recordCount,
    });
  }
}

class PersistentId extends getModelHelpers({
  persistentId: { type: ArgTypes.string },
}, 'PersistentId')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class PersistentIdRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: $TSFixMe) {
    checkArg({ obj }, ArgTypes.object);
    return new PersistentId({
      persistentId: obj.persistentId,
    });
  }
}

class PublishedClusterVersions extends getModelHelpers({
  id: { type: PersistentId.argType },
  versions: { type: ArgTypes.Immutable.list.of(Version.argType) },
}, 'PublishedClusterVersions')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class PublishedClusterVersionsRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: $TSFixMe) {
    checkArg({ obj }, ArgTypes.object);
    const { id, versions } = obj;
    return new PublishedClusterVersions({
      id: PersistentId.fromJSON(id),
      versions: List(versions).map(Version.fromJSON),
    });
  }

  /**
   * Conveninece method to return the counts at a given time
   *
   * If the provided time falls between publishes, uses the last known record count for the result
   */
  getRecordCountForDate(date: $TSFixMe) {
    checkArg({ date }, ArgTypes.timestamp);
    const matchedPublish = this.versions.find(v => v.materializationDate === date);
    const sortedVersions: List<Version> = this.versions
      // only get the versions that are newer than provided time
      .filter(v => v.materializationDate < date)
      // sort the versions by time (ASCENDING)
      .sortBy(v => v.materializationDate);
    // get the last (e.g. newest) version's record count; if that isn't present then this cluster didn't previously exist and has a count of 0
    const lastVersion = sortedVersions.last() as Version | undefined;
    return matchedPublish
      ? matchedPublish.countsOverTime
      : new CountsOverTime({
        time: date,
        count: lastVersion ? lastVersion.record.recordCount : 0,
      });
  }
}

export default PublishedClusterVersions;
