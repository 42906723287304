import { List, Set } from 'immutable';

import ConfidenceFilter from '../models/ConfidenceFilter';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import EsRecord from '../models/EsRecord';
import { $TSFixMe } from '../utils/typescript';

export const RESET_FILTERS = 'Transactions.resetFilters';
export const SET_SELECTED_DATASETS = 'Transactions.setSelectedDatasets';
export const RESET_DATASET_FILTER = 'Transactions.resetDatasetFilter';
export const SET_CATEGORY_IDS = 'Transactions.setCategoryIds';
export const SET_FILTER_EXTERNAL_DATA = 'Transactions.setFilterExternalData';
export const SET_FILTER_CATEGORIZED_BY_ME = 'Transactions.setFilterCategorizedByMe';
export const SET_FILTER_TAMR_LABELED = 'Transactions.setFilterTamrLabeled';
export const SET_FILTER_USER_LABELED = 'Transactions.setFilterUserLabeled';
export const SET_FILTER_UNLABELED = 'Transactions.setFilterUnlabeled';
export const SET_FILTER_UNLABELED_BY_TAMR = 'Transactions.setFilterUnlabeledByTamr';
export const SET_FILTER_LABEL_AGREES_WITH_TAMR = 'Transactions.setFilterLabelAgreesWithTamr';
export const SET_FILTER_LABEL_DISAGREES_WITH_TAMR = 'Transactions.setFilterLabelDisagreesWithTamr';
export const SET_FILTER_EXPERT_RESPONSES_AGREE = 'Transactions.setFilterExpertResponsesAgree';
export const SET_FILTER_EXPERT_RESPONSES_DISAGREE = 'Transactions.setFilterExpertResponsesDisagree';
export const SET_FILTER_EXPERTS_PENDING = 'Transactions.setFilterExpertsPending';
export const SET_FILTER_EXPERTS_UNSURE = 'Transactions.setFilterExpertsUnsure';
export const SET_FILTER_EXPERTS_NONE = 'Transactions.setFilterExpertsNone';
export const SET_FILTER_EXPERTS_SOME = 'Transactions.setFilterExpertsSome';
export const SET_FILTER_HAS_COMMENTS = 'Transactions.setFilterHasComments';
export const SET_ASSIGNMENT_STATUS_NONE = 'Transactions.setAssignmentStatusNone';
export const SET_ASSIGNMENT_STATUS_SOME = 'Transactions.setAssignmentStatusSome';
export const SET_ASSIGNMENT_STATUS_ALL = 'Transactions.setAssignmentStatusAll';
export const SET_ASSIGNMENT_STATUS_UNASSIGNED = 'Transactions.setAssignmentStatusUnassigned';
export const SET_ASSIGNMENT_STATUS_ASSIGNED = 'Transactions.setAssignmentStatusAssigned';
export const SET_FILTER_ASSIGNED_TO_ME_COMPLETE = 'Transactions.setFilterAssignedToMeComplete';
export const SET_FILTER_ASSIGNED_TO_ME_TO_DO = 'Transactions.setFilterAssignedToMeToDo';
export const SET_FILTER_ASSIGNED_TO_USERS = 'Transactions.setFilterAssignedToUsers';
export const SET_FILTER_CATEGORIZED_BY_USERS = 'Transactions.setFilterCategorizedByUsers';
export const SET_FILTER_HIGH_IMPACT = 'Transactions.setFilterHighImpact';
export const SET_CONFIDENCE_LOW = 'Transactions.setConfidenceLow';
export const SET_CONFIDENCE_MEDIUM = 'Transactions.setConfidenceMedium';
export const SET_CONFIDENCE_HIGH = 'Transactions.setConfidenceHigh';
export const SET_HAS_CONFIDENCE = 'Transactions.setHasConfidence';
export const SET_CONFIDENCE = 'Transactions.setConfidence';
export const PROGRESS_CONFIDENCE_SORT = 'Transactions.progressConfidenceSort';
export const CLEAR_REVIEWER_FILTERS = 'Transactions.clearReviewerFilters';
export const SET_REVIEWER_FILTERS = 'Transactions.setReviewerFilters';
export const CLEAR_CURATOR_FILTERS = 'Transactions.clearCuratorFilters';
export const SET_FILTER_NEEDS_VERIFICATION = 'Transactions.setFilterNeedsVerification';
export const CLEAR_FILTERS = 'Transactions.clearFilters';
export const FETCH_TRANSACTIONS = 'Transactions.fetchTransactions';
export const FETCH_TRANSACTIONS_COMPLETED = 'Transactions.fetchTransactionsCompleted';
export const FETCH_TRANSACTIONS_FAILED = 'Transactions.fetchTransactionsFailed';
export const TOGGLE_SORT = 'Transactions.toggleSort';
export const SET_PAGE = 'Transactions.setPage';
export const SET_PAGE_SIZE = 'Transactions.setPageSize';
export const SET_ACTIVE_ROW_NUMBER = 'Transactions.setActiveRowNumber';
export const SET_SELECTED_ROW_NUMBERS = 'Transactions.setSelectedRowNumbers';
export const OPEN_COMMENT_FORM = 'Transactions.openCommentForm';
export const TOGGLE_EXPAND_SIDEBAR = 'Transactions.toggleExpandSidebar';
export const TOGGLE_EXPAND_FILTER = 'Transactions.toggleExpandFilter';
export const SHOW_SIDEBAR_TAB = 'Transactions.showSidebarTab';
export const SET_SHOW_UPDATE_CATEGORIZATIONS_WARNING_DIALOG = 'Transactions.setShowUpdateCategorizationsWarningDialog';
export const SET_QUERY_STRING = 'Transactions.setQueryString';
export const COMMENT = 'Transactions.comment';
export const COMMENT_COMPLETED = 'Transactions.commentCompleted';
export const COMMENT_FAILED = 'Transactions.commentFailed';
export const EDIT_COMMENT = 'Transactions.editComment';
export const EDIT_COMMENT_COMPLETED = 'Transactions.editCommentCompleted';
export const EDIT_COMMENT_FAILED = 'Transactions.editCommentFailed';
export const DELETE_COMMENT = 'Transactions.deleteComment';
export const DELETE_COMMENT_COMPLETED = 'Transactions.deleteCommentCompleted';
export const DELETE_COMMENT_FAILED = 'Transactions.deleteCommentFailed';
export const SUGGEST_CATEGORIZATIONS = 'Transactions.suggestCategorizations';
export const SUGGEST_CATEGORIZATIONS_FAILED = 'Transactions.suggestCategorizationsFailed';
export const ADD_RESPONSES = 'Transactions.addResponses';
export const ADD_RESPONSES_COMPLETED = 'Transactions.addResponsesCompleted';
export const ADD_RESPONSES_FAILED = 'Transactions.addResponsesFailed';
export const SKIP_FEEDBACK = 'Transactions.skipFeedback';
export const SKIP_FEEDBACK_COMPLETED = 'Transactions.skipFeedbackCompleted';
export const SKIP_FEEDBACK_FAILED = 'Transactions.skipFeedbackFailed';
export const ADD_CATEGORIZATIONS = 'Transactions.addCategorizations';
export const ADD_CATEGORIZATIONS_COMPLETED = 'Transactions.addCategorizationsCompleted';
export const ADD_CATEGORIZATIONS_FAILED = 'Transactions.addCategorizationsFailed';
export const DELETE_CATEGORIZATION = 'Transactions.deleteCategorization';
export const DELETE_CATEGORIZATIONS_COMPLETED = 'Transactions.deleteCategorizationsCompleted';
export const DELETE_CATEGORIZATIONS_FAILED = 'Transactions.deleteCategorizationsFailed';
export const ASSIGN = 'Transactions.assign';
export const ASSIGN_COMPLETED = 'Transactions.assignCompleted';
export const ASSIGN_FAILED = 'Transactions.assignFailed';
export const FEEDBACK_SUMMARY_FETCH_COMPLETED = 'Transactions.feedbackSummaryFetchCompleted';
export const OPEN_CATEGORY_FILTER = 'Transactions.openCategoryFilter';
export const CANCEL_CATEGORY_FILTER = 'Transactions.cancelCategoryFilter';
export const OPEN_CATEGORIZE_DIALOG = 'Transactions.openCategorizeDialog';
export const CANCEL_CATEGORIZE_DIALOG = 'Transactions.cancelCategorizeDialog';
export const OPEN_ASSIGNED_TO_USERS_FILTER = 'Transactions.openAssignedToUsersFilter';
export const OPEN_CATEGORIZED_BY_USERS_FILTER = 'Transactions.openCategorizedByUsersFilter';
export const CANCEL_ASSIGNED_TO_USERS_FILTER = 'Transactions.cancelAssignedToUsersFilter';
export const CANCEL_CATEGORIZED_BY_USERS_FILTER = 'Transactions.cancelCategorizedByUsersFilter';
export const ADD_CATEGORY_FILTER = 'Transactions.addCategoryFilter';
export const REMOVE_CATEGORY_FILTER = 'Transactions.removeCategoryFilter';
export const OPEN_DATASET_FILTER_DIALOG = 'Transactions.openDatasetFilterDialog';
export const CLOSE_DATASET_FILTER_DIALOG = 'Transactions.closeDatasetFilterDialog';
export const CHECK_MODEL = 'Transactions.checkModel';
export const CHECK_MODEL_COMPLETED = 'Transactions.checkModelCompleted';
export const CHECK_MODEL_FAILED = 'Transactions.checkModelFailed';
export const OPEN_GEOSPATIAL_DETAILS_SIDEBAR = 'Transactions.openGeospatialDetailsSidebar';
export const CLOSE_GEOSPATIAL_DETAILS_SIDEBAR = 'Transactions.closeGeospatialDetailsSidebar';
export const OPEN_GEOSPATIAL_DETAILS_DIALOG = 'Transactions.openGeospatialDetailsDialog';
export const CLOSE_GEOSPATIAL_DETAILS_DIALOG = 'Transactions.closeGeospatialDetailsDialog';

interface TransactionsResetFilters {
  type: typeof RESET_FILTERS
}

interface TransactionsSetSelectedDatasets {
  type: typeof SET_SELECTED_DATASETS
  datasetsToAdd: Set<Document<Dataset>>
  datasetsToRemove: Set<Document<Dataset>>
}

interface TransactionsResetDatasetFilter {
  type: typeof RESET_DATASET_FILTER
}

interface TransactionsSetCategoryIds {
  type: typeof SET_CATEGORY_IDS
  categoryIds: List<number>
}

interface TransactionsSetFilterExternalData {
  type: typeof SET_FILTER_EXTERNAL_DATA
  showExternalData: boolean
}

interface TransactionsSetFilterCategorizedByMe {
  type: typeof SET_FILTER_CATEGORIZED_BY_ME
  categorizedByMe: boolean
}

interface TransactionsSetFilterTamrLabeled {
  type: typeof SET_FILTER_TAMR_LABELED
  labeledByTamr: boolean
}

interface TransactionsSetFilterUserLabeled {
  type: typeof SET_FILTER_USER_LABELED
  labeledByUser: boolean
}

interface TransactionsSetFilterUnlabeled {
  type: typeof SET_FILTER_UNLABELED
  unlabeled: boolean
}

interface TransactionsSetFilterUnlabeledByTamr {
  type: typeof SET_FILTER_UNLABELED_BY_TAMR
  unlabeledByTamr: boolean
}

interface TransactionsSetFilterLabelAgreesWithTamr {
  type: typeof SET_FILTER_LABEL_AGREES_WITH_TAMR
  labelAgreesWithTamr: boolean
}

interface TransactionsSetFilterLabelDisagreesWithTamr {
  type: typeof SET_FILTER_LABEL_DISAGREES_WITH_TAMR
  labelDisagreesWithTamr: boolean
}

interface TransactionsSetFilterExpertResponsesAgree {
  type: typeof SET_FILTER_EXPERT_RESPONSES_AGREE
  expertResponsesAgree: boolean
}

interface TransactionsSetFilterExpertResponsesDisagree {
  type: typeof SET_FILTER_EXPERT_RESPONSES_DISAGREE
  expertResponsesDisagree: boolean
}

interface TransactionsSetFilterExpertsPending {
  type: typeof SET_FILTER_EXPERTS_PENDING
  expertsPending: boolean
}

interface TransactionsSetFilterExpertsUnsure {
  type: typeof SET_FILTER_EXPERTS_UNSURE
  expertsUnsure: boolean
}

interface TransactionsSetFilterExpertsNone {
  type: typeof SET_FILTER_EXPERTS_NONE
  expertsNone: boolean
}

interface TransactionsSetFilterExpertsSome {
  type: typeof SET_FILTER_EXPERTS_SOME
  expertsSome: boolean
}

interface TransactionsSetFilterHasComments {
  type: typeof SET_FILTER_HAS_COMMENTS
  hasComments: boolean
}

interface TransactionsSetAssignmentStatusNone {
  type: typeof SET_ASSIGNMENT_STATUS_NONE
  assignmentStatusNone: boolean
}

interface TransactionsSetAssignmentStatusSome {
  type: typeof SET_ASSIGNMENT_STATUS_SOME
  assignmentStatusSome: boolean
}

interface TransactionsSetAssignmentStatusAll {
  type: typeof SET_ASSIGNMENT_STATUS_ALL
  assignmentStatusAll: boolean
}

interface TransactionsSetAssignmentStatusUnassigned {
  type: typeof SET_ASSIGNMENT_STATUS_UNASSIGNED
  assignmentStatusUnassigned: boolean
}

interface TransactionsSetAssignmentStatusAssigned {
  type: typeof SET_ASSIGNMENT_STATUS_ASSIGNED
  assignmentStatusAssigned: boolean
}

interface TransactionsSetFilterAssignedToMeComplete {
  type: typeof SET_FILTER_ASSIGNED_TO_ME_COMPLETE
  assignedToMeComplete: boolean
}

interface TransactionsSetFilterAssignedToMeToDo {
  type: typeof SET_FILTER_ASSIGNED_TO_ME_TO_DO
  assignedToMeToDo: boolean
}

interface TransactionsSetFilterAssignedToUsers {
  type: typeof SET_FILTER_ASSIGNED_TO_USERS
  assignedToUsers: Set<string>
}

interface TransactionsSetFilterCategorizedByUsers {
  type: typeof SET_FILTER_CATEGORIZED_BY_USERS
  categorizedByUsers: Set<string>
}

interface TransactionsSetFilterHighImpact {
  type: typeof SET_FILTER_HIGH_IMPACT
  highImpact: boolean
}

interface TransactionsSetConfidenceLow {
  type: typeof SET_CONFIDENCE_LOW
  confidenceLow: boolean
}

interface TransactionsSetConfidenceMedium {
  type: typeof SET_CONFIDENCE_MEDIUM
  confidenceMedium: boolean
}

interface TransactionsSetConfidenceHigh {
  type: typeof SET_CONFIDENCE_HIGH
  confidenceHigh: boolean
}

interface TransactionsSetHasConfidence {
  type: typeof SET_HAS_CONFIDENCE
  hasConfidence: boolean
}

interface TransactionsSetConfidence {
  type: typeof SET_CONFIDENCE
  confidence: ConfidenceFilter
}

interface TransactionsProgressConfidenceSort {
  type: typeof PROGRESS_CONFIDENCE_SORT
}

interface TransactionsClearReviewerFilters {
  type: typeof CLEAR_REVIEWER_FILTERS
}

interface TransactionsSetReviewerFilters {
  type: typeof SET_REVIEWER_FILTERS
}

interface TransactionsClearCuratorFilters {
  type: typeof CLEAR_CURATOR_FILTERS
}

interface TransactionsSetFilterNeedsVerification {
  type: typeof SET_FILTER_NEEDS_VERIFICATION
}

interface TransactionsClearFilters {
  type: typeof CLEAR_FILTERS
}

interface TransactionsFetchTransactions {
  type: typeof FETCH_TRANSACTIONS
}

interface TransactionsFetchTransactionsCompleted {
  type: typeof FETCH_TRANSACTIONS_COMPLETED
  rows: List<EsRecord>
  total: number
  filterInfo: $TSFixMe
}

interface TransactionsFetchTransactionsFailed {
  type: typeof FETCH_TRANSACTIONS_FAILED
  filterInfo: $TSFixMe
}

interface TransactionsToggleSort {
  type: typeof TOGGLE_SORT
  columnName: string
}

interface TransactionsSetPage {
  type: typeof SET_PAGE
  pageNum: number
}

interface TransactionsSetPageSize {
  type: typeof SET_PAGE_SIZE
  pageSize: number
}

interface TransactionsSetActiveRowNumber {
  type: typeof SET_ACTIVE_ROW_NUMBER
  rowNum?: number
}

interface TransactionsSetSelectedRowNumbers {
  type: typeof SET_SELECTED_ROW_NUMBERS
  rowNums: Set<number>
}

interface TransactionsOpenCommentForm {
  type: typeof OPEN_COMMENT_FORM
}

interface TransactionsToggleExpandSidebar {
  type: typeof TOGGLE_EXPAND_SIDEBAR
}

interface TransactionsToggleExpandFilter {
  type: typeof TOGGLE_EXPAND_FILTER
}

interface TransactionsShowSidebarTab {
  type: typeof SHOW_SIDEBAR_TAB
  sidebarTabKey: $TSFixMe
}

interface TransactionsSetShowUpdateCategorizationsWarningDialog {
  type: typeof SET_SHOW_UPDATE_CATEGORIZATIONS_WARNING_DIALOG
  show: boolean
  doApplyAndUpdate?: $TSFixMe
}

interface TransactionsSetQueryString {
  type: typeof SET_QUERY_STRING
  queryString: string
}

interface TransactionsComment {
  type: typeof COMMENT
  id: number
}

interface TransactionsCommentCompleted {
  type: typeof COMMENT_COMPLETED
  id: number
}

interface TransactionsCommentFailed {
  type: typeof COMMENT_FAILED
  id: number
}

interface TransactionsEditComment {
  type: typeof EDIT_COMMENT
  id: number
}

interface TransactionsEditCommentCompleted {
  type: typeof EDIT_COMMENT_COMPLETED
  id: number
}

interface TransactionsEditCommentFailed {
  type: typeof EDIT_COMMENT_FAILED
}

interface TransactionsDeleteComment {
  type: typeof DELETE_COMMENT
  id: number
}

interface TransactionsDeleteCommentCompleted {
  type: typeof DELETE_COMMENT_COMPLETED
  id: number
}

interface TransactionsDeleteCommentFailed {
  type: typeof DELETE_COMMENT_FAILED
}

interface TransactionsSuggestCategorizations {
  type: typeof SUGGEST_CATEGORIZATIONS
}

interface TransactionsSuggestCategorizationsFailed {
  type: typeof SUGGEST_CATEGORIZATIONS_FAILED
}

interface TransactionsAddResponses {
  type: typeof ADD_RESPONSES
  ids: $TSFixMe
}

interface TransactionsAddResponsesCompleted {
  type: typeof ADD_RESPONSES_COMPLETED
  ids: $TSFixMe
}

interface TransactionsAddResponsesFailed {
  type: typeof ADD_RESPONSES_FAILED
  ids: $TSFixMe
}

interface TransactionsSkipFeedback {
  type: typeof SKIP_FEEDBACK
  ids: $TSFixMe
}

interface TransactionsSkipFeedbackCompleted {
  type: typeof SKIP_FEEDBACK_COMPLETED
  ids: $TSFixMe
}

interface TransactionsSkipFeedbackFailed {
  type: typeof SKIP_FEEDBACK_FAILED
  ids: $TSFixMe
}

interface TransactionsAddCategorizations {
  type: typeof ADD_CATEGORIZATIONS
  ids: $TSFixMe
}

interface TransactionsAddCategorizationsCompleted {
  type: typeof ADD_CATEGORIZATIONS_COMPLETED
  ids: $TSFixMe
}

interface TransactionsAddCategorizationsFailed {
  type: typeof ADD_CATEGORIZATIONS_FAILED
  ids: $TSFixMe
}

interface TransactionsDeleteCategorization {
  type: typeof DELETE_CATEGORIZATION
  ids: $TSFixMe
}

interface TransactionsDeleteCategorizationsCompleted {
  type: typeof DELETE_CATEGORIZATIONS_COMPLETED
  ids: $TSFixMe
}

interface TransactionsDeleteCategorizationsFailed {
  type: typeof DELETE_CATEGORIZATIONS_FAILED
  ids: $TSFixMe
}

interface TransactionsAssign {
  type: typeof ASSIGN
  ids: $TSFixMe
}

interface TransactionsAssignCompleted {
  type: typeof ASSIGN_COMPLETED
  ids: $TSFixMe
}

interface TransactionsAssignFailed {
  type: typeof ASSIGN_FAILED
  ids: $TSFixMe
}

interface TransactionsFeedbackSummaryFetchCompleted {
  type: typeof FEEDBACK_SUMMARY_FETCH_COMPLETED
  numAssigned: number
  numResponded: number
}

interface TransactionsOpenCategoryFilter {
  type: typeof OPEN_CATEGORY_FILTER
}

interface TransactionsCancelCategoryFilter {
  type: typeof CANCEL_CATEGORY_FILTER
}

interface TransactionsOpenCategorizeDialog {
  type: typeof OPEN_CATEGORIZE_DIALOG
  categorizeDialogRecords: List<EsRecord>
}

interface TransactionsCancelCategorizeDialog {
  type: typeof CANCEL_CATEGORIZE_DIALOG
}

interface TransactionsOpenAssignedToUsersFilter {
  type: typeof OPEN_ASSIGNED_TO_USERS_FILTER
}

interface TransactionsOpenCategorizedByUsersFilter {
  type: typeof OPEN_CATEGORIZED_BY_USERS_FILTER
}

interface TransactionsCancelAssignedToUsersFilter {
  type: typeof CANCEL_ASSIGNED_TO_USERS_FILTER
}

interface TransactionsCancelCategorizedByUsersFilter {
  type: typeof CANCEL_CATEGORIZED_BY_USERS_FILTER
}

interface TransactionsAddCategoryFilter {
  type: typeof ADD_CATEGORY_FILTER
  categoryId: number
}

interface TransactionsRemoveCategoryFilter {
  type: typeof REMOVE_CATEGORY_FILTER
  categoryId: number
}

interface TransactionsOpenDatasetFilterDialog {
  type: typeof OPEN_DATASET_FILTER_DIALOG
}

interface TransactionsCloseDatasetFilterDialog {
  type: typeof CLOSE_DATASET_FILTER_DIALOG
}

interface TransactionsCheckModel {
  type: typeof CHECK_MODEL
}

interface TransactionsCheckModelCompleted {
  type: typeof CHECK_MODEL_COMPLETED
  exists: boolean
  recipeId: number
}

interface TransactionsCheckModelFailed {
  type: typeof CHECK_MODEL_FAILED
  recipeId?: number
}

interface TransactionsOpenGeospatialDetailsSidebar {
  type: typeof OPEN_GEOSPATIAL_DETAILS_SIDEBAR
  attributeName: string
}

interface TransactionsCloseGeospatialDetailsSidebar {
  type: typeof CLOSE_GEOSPATIAL_DETAILS_SIDEBAR
}

interface TransactionsOpenGeospatialDetailsDialog {
  type: typeof OPEN_GEOSPATIAL_DETAILS_DIALOG
}

interface TransactionsCloseGeospatialDetailsDialog {
  type: typeof CLOSE_GEOSPATIAL_DETAILS_DIALOG
}

export type TransactionsActionTypes =
  | TransactionsResetFilters
  | TransactionsSetSelectedDatasets
  | TransactionsResetDatasetFilter
  | TransactionsSetCategoryIds
  | TransactionsSetFilterExternalData
  | TransactionsSetFilterCategorizedByMe
  | TransactionsSetFilterTamrLabeled
  | TransactionsSetFilterUserLabeled
  | TransactionsSetFilterUnlabeled
  | TransactionsSetFilterUnlabeledByTamr
  | TransactionsSetFilterLabelAgreesWithTamr
  | TransactionsSetFilterLabelDisagreesWithTamr
  | TransactionsSetFilterExpertResponsesAgree
  | TransactionsSetFilterExpertResponsesDisagree
  | TransactionsSetFilterExpertsPending
  | TransactionsSetFilterExpertsUnsure
  | TransactionsSetFilterExpertsNone
  | TransactionsSetFilterExpertsSome
  | TransactionsSetFilterHasComments
  | TransactionsSetAssignmentStatusNone
  | TransactionsSetAssignmentStatusSome
  | TransactionsSetAssignmentStatusAll
  | TransactionsSetAssignmentStatusUnassigned
  | TransactionsSetAssignmentStatusAssigned
  | TransactionsSetFilterAssignedToMeComplete
  | TransactionsSetFilterAssignedToMeToDo
  | TransactionsSetFilterAssignedToUsers
  | TransactionsSetFilterCategorizedByUsers
  | TransactionsSetFilterHighImpact
  | TransactionsSetConfidenceLow
  | TransactionsSetConfidenceMedium
  | TransactionsSetConfidenceHigh
  | TransactionsSetHasConfidence
  | TransactionsSetConfidence
  | TransactionsProgressConfidenceSort
  | TransactionsClearReviewerFilters
  | TransactionsSetReviewerFilters
  | TransactionsClearCuratorFilters
  | TransactionsSetFilterNeedsVerification
  | TransactionsClearFilters
  | TransactionsFetchTransactions
  | TransactionsFetchTransactionsCompleted
  | TransactionsFetchTransactionsFailed
  | TransactionsToggleSort
  | TransactionsSetPage
  | TransactionsSetPageSize
  | TransactionsSetActiveRowNumber
  | TransactionsSetSelectedRowNumbers
  | TransactionsOpenCommentForm
  | TransactionsToggleExpandSidebar
  | TransactionsToggleExpandFilter
  | TransactionsShowSidebarTab
  | TransactionsSetShowUpdateCategorizationsWarningDialog
  | TransactionsSetQueryString
  | TransactionsComment
  | TransactionsCommentCompleted
  | TransactionsCommentFailed
  | TransactionsEditComment
  | TransactionsEditCommentCompleted
  | TransactionsEditCommentFailed
  | TransactionsDeleteComment
  | TransactionsDeleteCommentCompleted
  | TransactionsDeleteCommentFailed
  | TransactionsSuggestCategorizations
  | TransactionsSuggestCategorizationsFailed
  | TransactionsAddResponses
  | TransactionsAddResponsesCompleted
  | TransactionsAddResponsesFailed
  | TransactionsSkipFeedback
  | TransactionsSkipFeedbackCompleted
  | TransactionsSkipFeedbackFailed
  | TransactionsAddCategorizations
  | TransactionsAddCategorizationsCompleted
  | TransactionsAddCategorizationsFailed
  | TransactionsDeleteCategorization
  | TransactionsDeleteCategorizationsCompleted
  | TransactionsDeleteCategorizationsFailed
  | TransactionsAssign
  | TransactionsAssignCompleted
  | TransactionsAssignFailed
  | TransactionsFeedbackSummaryFetchCompleted
  | TransactionsOpenCategoryFilter
  | TransactionsCancelCategoryFilter
  | TransactionsOpenCategorizeDialog
  | TransactionsCancelCategorizeDialog
  | TransactionsOpenAssignedToUsersFilter
  | TransactionsOpenCategorizedByUsersFilter
  | TransactionsCancelAssignedToUsersFilter
  | TransactionsCancelCategorizedByUsersFilter
  | TransactionsAddCategoryFilter
  | TransactionsRemoveCategoryFilter
  | TransactionsOpenDatasetFilterDialog
  | TransactionsCloseDatasetFilterDialog
  | TransactionsCheckModel
  | TransactionsCheckModelCompleted
  | TransactionsCheckModelFailed
  | TransactionsOpenGeospatialDetailsSidebar
  | TransactionsCloseGeospatialDetailsSidebar
  | TransactionsOpenGeospatialDetailsDialog
  | TransactionsCloseGeospatialDetailsDialog
