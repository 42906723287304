import React from 'react';

import Checkbox from '../../components/Input/Checkbox';
import styles from './CheckboxFilter.module.scss';


const CheckboxFilter: React.FC<{
  onChange: (checked: boolean, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  title: React.ReactNode
  value: boolean | null | undefined
}> = ({ onChange, title, value }) => {
  return (
    <Checkbox
      className={styles.checkbox}
      onChange={onChange}
      size={16}
      title={title}
      titlePosition="right"
      value={value || false}
    />
  );
};

export default CheckboxFilter;
