import TaggedUnion, { InferConstructedKind } from '../models/TaggedUnion';
import { ArgTypes, checkArg } from '../utils/ArgValidation';

// Ternary.ts
// A TaggedUnion to represent ternary boolean logic
// states are Unknown, Known-to-be-true, and Known-to-be-false

const kindDefinitions = {
  Unknown: {},
  Known: { value: ArgTypes.bool },
};
const Ternary = TaggedUnion(kindDefinitions, 'Ternary');

export type TernaryType = InferConstructedKind<typeof kindDefinitions>;

export const isKnownTrue = (ternary: TernaryType) => {
  checkArg({ ternary }, Ternary.argType);
  return ternary.case({ Known: ({ value }) => value, Unknown: () => false });
};
export const isKnownFalse = (ternary: TernaryType) => {
  checkArg({ ternary }, Ternary.argType);
  return ternary.case({ Known: ({ value }) => !value, Unknown: () => false });
};

export default Ternary;
