import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import { connect } from 'react-redux';
import { isString } from 'underscore';

import Button from '../components/Button';
import Input from '../components/Input/Input';
import TamrIcon from '../components/TamrIcon';
import Toolbar from '../components/Toolbar';
import AppState from '../stores/AppState';
import { usePreserveValue } from '../utils/usePreserveValue';
import { isPrimitive } from '../utils/Values';
import * as EsRecord from './EsRecord';
import style from './GoldenRecord.module.scss';
import { deleteOverride, upsertOverride } from './GoldenRecordsAsync';
import { attributeNameToColumnKey, columnKeyToOverrideColumnKey, columnKeyToRuleOutputColumnKey } from './GoldenRecordsStore';
import { getClusterIdOfDraftRecord } from './GoldenRecordsUtils';


function getDraftRecordByClusterId(state: AppState, clusterId: string): EsRecord.EsRecord | undefined {
  const { goldenRecords: { draftPage, moduleFromLastUpdate } } = state;
  return draftPage?.items.find(record => {
    const recordClusterId = getClusterIdOfDraftRecord(moduleFromLastUpdate || undefined, record);
    return isString(recordClusterId) && recordClusterId === clusterId;
  });
}

/**
 * Required information about that target cluster / column that we are editing overrides for
 */
export interface OverrideTargetInfo {
  editOverridePopoverRef: React.RefObject<HTMLDivElement>
  clusterId: string
  attributeName: string
}

interface EditOverridesOverlayOwnProps {
  overrideTargetInfo: OverrideTargetInfo | undefined
  onClose: () => void
}

const EditOverridesOverlay = connect((state: AppState, ownProps: EditOverridesOverlayOwnProps) => {
  const { overrideTargetInfo } = ownProps;
  const record = overrideTargetInfo && getDraftRecordByClusterId(state, overrideTargetInfo.clusterId);
  if (record && overrideTargetInfo) {
    const columnKey = attributeNameToColumnKey(overrideTargetInfo.attributeName);
    const ruleValue = record.source[columnKeyToRuleOutputColumnKey(columnKey)];
    const existingOverrideValue = record.source[columnKeyToOverrideColumnKey(columnKey)];
    return { ruleValue, existingOverrideValue };
  }
  return {};
}, {
  onUpsertOverride: upsertOverride,
  onDeleteOverride: deleteOverride,
})(({ onClose, overrideTargetInfo, existingOverrideValue,
  onUpsertOverride, onDeleteOverride, ruleValue }) => {
  const cellIdentifiers = [overrideTargetInfo?.clusterId, overrideTargetInfo?.attributeName];

  const [editedOverrideValue, setEditedOverrideValue] = useState<string>('');
  // reset editedOverrideValue to existingOverrideValue when target changes
  useEffect(() => {
    if (overrideTargetInfo) {
      const overrideValue = !existingOverrideValue
        ? ''
        : isPrimitive(existingOverrideValue)
          ? String(existingOverrideValue)
          : '';
      setEditedOverrideValue(overrideValue);
    }
  }, cellIdentifiers);

  // we want to maintain preserved copies of these values so the overlay can fade out while maintaining contents
  const overlayTarget = usePreserveValue(overrideTargetInfo?.editOverridePopoverRef);
  const preservedExistingOverrideValue = usePreserveValue(existingOverrideValue, {
    parameters: [overrideTargetInfo],
    condition: () => !!overrideTargetInfo,
  });
  const preservedRuleValue = usePreserveValue(ruleValue, {
    parameters: [overrideTargetInfo],
    condition: () => !!overrideTargetInfo,
  });

  const saveOverride = () => {
    if (!overrideTargetInfo) {
      console.error('Cannot save override - do not have target attribute / cluster info');
      return;
    }
    const { clusterId, attributeName } = overrideTargetInfo;
    onUpsertOverride({ clusterId, attribute: attributeName, value: editedOverrideValue });
    onClose();
  };
  const removeOverride = () => {
    if (!overrideTargetInfo) {
      console.error('Cannot remove override - do not have target attribute / cluster info');
      return;
    }
    const { clusterId, attributeName } = overrideTargetInfo;
    onDeleteOverride({ clusterId, attribute: attributeName });
    onClose();
  };
  return (
    <Overlay
      target={overlayTarget?.current || undefined}
      placement="bottom"
      show={!!overrideTargetInfo}
    >
      <Popover
        id={'editPopover'}
        bsSize="large"
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            saveOverride();
          }
        }}
      >
        <div className={classnames(style['preferred-sources'], 'my-16')}>Override</div>
        <Input
          title="Override value"
          width="200px"
          value={editedOverrideValue}
          onChange={(v) => setEditedOverrideValue(v)}
          autoFocusOnMount
        />
        <p className="pt-16">Value from rule: {preservedRuleValue}</p>
        <Toolbar
          className="my-16"
          left={isString(preservedExistingOverrideValue) ? <TamrIcon className={style.blue} iconName="delete" size={20} onClick={removeOverride} /> : null}
          right={<React.Fragment>
            <Button buttonType="Secondary" onClick={onClose} className="mr-8">Cancel</Button>
            <Button onClick={saveOverride} disabled={editedOverrideValue === existingOverrideValue}>Save</Button>
          </React.Fragment>} />
      </Popover>
    </Overlay>
  );
});

export default EditOverridesOverlay;
