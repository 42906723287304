import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment, { Moment } from 'moment';

import * as DatasetClient from '../../api/DatasetClient';
import * as RecipeClient from '../../api/RecipeClient';
import RecipeOperations from '../../constants/RecipeOperations';
import ProjectInfo from '../../models/ProjectInfo';

export interface Metrics {
  version: number
  date: Moment
  precision: number,
  precisionConfidenceInterval: number
  precisionTestRecordsCount: number
  recall: number
  recallConfidenceInterval: number
  recallTestRecordsCount: number
}

const fromJSON = (data: any): Metrics => {
  return {
    version: data.datasetVersion,
    date: moment(data.materializationDate),
    precision: data.record.precision,
    precisionConfidenceInterval: data.record.precisionExpectedError,
    precisionTestRecordsCount: data.record.precisionTestRecordsCount,
    recall: data.record.recall,
    recallConfidenceInterval: data.record.recallExpectedError,
    recallTestRecordsCount: data.record.recallTestRecordsCount,
  };
};

// State


export const initialState = {
  isDialogOpen: false as boolean,
  historical: {
    loading: false as boolean,
    shouldFetch: true as boolean,
    metrics: undefined as unknown as Array<Metrics> | undefined,
  },
  isSubmittingJob: false,
};

export type State = typeof initialState;

// Actions

export const computeMetricsJobCompleted = createAction('clusters/metrics/compute/jobCompleted');

// Thunks

export const fetchMetrics = createAsyncThunk(
  'clusters/metrics/fetch',
  async (projectInfo: ProjectInfo) => {
    const { unifiedDatasetDoc } = projectInfo;
    if (!unifiedDatasetDoc) {
      return Promise.reject();
    }
    const datasetName = `${unifiedDatasetDoc.data.name}_dedup_cluster_accuracy_metrics`;
    // cluster metrics dataset has exactly one record with ID = 0 representing the latest metrics
    const data = await DatasetClient.fetchRecordVersions(datasetName, [{ id: 0 }]);

    if (!data.versions) {
      return [];
    }

    const metrics = data.versions.map(fromJSON);
    return metrics;
  },
);

export const submitComputeMetricsJob = createAsyncThunk(
  'clusters/metrics/compute',
  async (projectInfo: ProjectInfo) => {
    const job = await RecipeClient.runOperation(projectInfo.recipeId, RecipeOperations.COMPUTE_CLUSTERS_ACCURACY);
    return job;
  },
);

// Slice

const slice = createSlice({
  name: 'clusters/metrics',
  initialState,
  reducers: {
    openDialog: (state) => {
      state.isDialogOpen = true;
    },
    closeDialog: (state) => {
      state.isDialogOpen = false;
    },
  },
  extraReducers: builder => builder
    .addCase(fetchMetrics.pending, (state) => {
      state.historical.loading = true;
      state.historical.shouldFetch = false;
    })
    .addCase(fetchMetrics.fulfilled, (state, action) => {
      state.historical.loading = false;
      state.historical.metrics = action.payload;
    })
    .addCase(computeMetricsJobCompleted, (state) => {
      state.historical.shouldFetch = true;
    })
    .addCase(submitComputeMetricsJob.pending, (state) => {
      state.isSubmittingJob = true;
    })
    .addCase(submitComputeMetricsJob.fulfilled, (state) => {
      state.isSubmittingJob = false;
    })
    .addCase(submitComputeMetricsJob.rejected, (state) => {
      state.isSubmittingJob = false;
    }),
});

export const { openDialog, closeDialog } = slice.actions;
export const { reducer } = slice;
