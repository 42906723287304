import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Project from '../models/Project';
import { getProjectById } from '../projects/ProjectsStore';
import { AppState } from '../stores/MainStore';
import { selectActiveProjectWithStatus } from '../utils/Selectors';
import { projectTerm } from '../utils/Terms';
import { getPath } from '../utils/Values';

type TermOwnProps = {
  amount?: number,
  children: string,
  projectId?: number,
}

const Term = connect((state: AppState, { projectId }: TermOwnProps) => {
  const { projects } = state;
  const project: Project = _.isNumber(projectId)
    ? getProjectById(projects, projectId)
    : getPath(selectActiveProjectWithStatus(state), 'project', 'data');
  return { project };
})(({ amount, children, project }) => {
  return <span>{projectTerm(project, children, amount || undefined)}</span>;
});

export default Term;
