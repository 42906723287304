import React from 'react';
import { connect } from 'react-redux';

import AppState from '../stores/AppState';
import { selectActiveProjectId } from '../utils/Selectors';

const mapStateToProps = (state: AppState) => {
  const projectId = selectActiveProjectId(state);
  return { projectId, moduleId: state.location?.moduleId };
};
const mapDispatchToProps = {
  onProjectChange: () => ({ type: 'Location.projectChange' }),
};

type ProjectChangeMonitorProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const ProjectChangeMonitor = connect(
  mapStateToProps,
  mapDispatchToProps,
)(class ProjectChangeMonitor extends React.Component<ProjectChangeMonitorProps> {
  UNSAFE_componentWillReceiveProps(nextProps: ProjectChangeMonitorProps) {
    const { projectId, onProjectChange, moduleId } = this.props;
    if ((projectId && nextProps.projectId !== projectId) || (moduleId && nextProps.moduleId !== moduleId)) {
      onProjectChange();
    }
  }

  render() {
    return null;
  }
});

export default ProjectChangeMonitor;
