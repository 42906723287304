import React from 'react';
import { connect } from 'react-redux';

import TamrIcon from '../../components/TamrIcon';
import TooltipTrigger from '../../components/TooltipTrigger';
import { AppAction } from '../../stores/AppAction';
import AppState from '../../stores/AppState';
import { SET_FILTER_TO_INFERRED_LABELS } from '../RecordPairsActionTypes';
import CheckboxFilter from './CheckboxFilter';


const HasInferredResponsesFilter = connect(({ recordPairs: { filterToInferredLabels } }: AppState) => {
  return { filterToInferredLabels };
}, {
  onSetFilterToInferredLabels: (filterToInferredLabels: boolean): AppAction => ({ type: SET_FILTER_TO_INFERRED_LABELS, filterToInferredLabels }),
})(({ filterToInferredLabels, onSetFilterToInferredLabels }) => {
  return (
    <CheckboxFilter
      onChange={onSetFilterToInferredLabels}
      title={(<span>
        Has inferred or learned responses
        <TooltipTrigger
          placement="top"
          content={'Filter to pairs that have labels that Tamr Core inferred from record grouping criteria or learned from cluster verification'}>
          <TamrIcon style={{ marginLeft: '5px', marginBottom: '-2px' }} size={14} iconName="info-outline" />
        </TooltipTrigger>
      </span>)}
      value={filterToInferredLabels}
    />
  );
});

export default HasInferredResponsesFilter;
