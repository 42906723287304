/* eslint-disable prefer-spread */
import './Table.scss';

import classNames from 'classnames';
import { Table as FTable, TableRowEventHandler } from 'fixed-data-table-2';
import Immutable from 'immutable';
import $ from 'jquery';
import Mousetrap from 'mousetrap';
import PropTypes, { InferProps, Validator } from 'prop-types';
import React, { Requireable } from 'react';
import ReactDOM from 'react-dom';
import ImmutablePropTypes from 'react-immutable-proptypes';
import _ from 'underscore';

import { $TSFixMe } from '../../utils/typescript';
import CheckboxColumn from '../CheckboxColumn';
import PagerBar from './PagerBar';

const DEFAULT_ROW_HEIGHT = 35;
const DEFAULT_HEADER_HEIGHT = 36;
const DEFAULT_PAGER_HEIGHT = 35;

const propTypes = {
  /**
   * Optional number to set the current active (eg. focused) row.
   * A defined value for this puts the active row behavior in a controlled state.
   */
  activeRowNumber: PropTypes.number,

  children: PropTypes.node,

  // optional className to apply to container div (not actual table)
  className: PropTypes.string,

  /**
   * Whether or not to enable multiple row selection.  This includes rendering a checkbox column
   */
  enableMultipleRowSelection: PropTypes.bool,

  /**
   * DEPRECATED - use footerDataGetter instead.
   * Data that will be passed to footer cell renderers.
   */
  footerData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

  /**
   * Function that is called to get the data for the footer row.
   */
  footerDataGetter: PropTypes.func,

  /**
   * Pixel height of footer.
   */
  footerHeight: PropTypes.number,

  /**
   * Replaces FixedDataTable's rowsCount numeric option.  Prefering to a late binding callback
   */
  getLength: PropTypes.func.isRequired as Validator<() => number>,

  /**
   * Pixel height of the column group header.
   */
  groupHeaderHeight: PropTypes.number,

  /**
   * Function that is called to get the data for the header row.
   * If the function returns null, the header will be set to the
   * Column's label property.
   */
  headerDataGetter: PropTypes.func,

  /**
   * Pixel height of header.
   */
  headerHeight: PropTypes.number,

  /**
   * Pixel height of table. If all rows do not fit,
   * a vertical scrollbar will appear.
   *
   * If no value is specified, the height will be set to 100%
   */
  height: PropTypes.number,

  /**
   * Whether to hide the navigation
   */
  hidePageNavigation: PropTypes.bool,

  /**
   * Maximum pixel height of table. If all rows do not fit,
   * a vertical scrollbar will appear.
   */
  maxHeight: PropTypes.number,

  /**
   * Default minimum width in pixels with columns in the table. Can be overwritten by individual
   * columns.  Default is 20
   */
  minColumnWidth: PropTypes.number,

  /**
   * Function that is run whenever the active row is changed
   */
  onActiveCellChange: PropTypes.func as Requireable<
    (event: ExtendedKeyboardEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>, rowNum: number) => void
  >,

  /**
   * Method that is called when the table loses focus
   */
  onBlur: PropTypes.func as Requireable<(event: React.FocusEvent<HTMLDivElement>) => void>,

  /**
   * Callback that is called when resizer has been released
   * and column needs to be updated.
   *
   * Required if the isResizable property is true on any column.
   *
   * ```
   * function(
   *   newColumnWidth: number,
   *   columnKey: string,
   * )
   * ```
   */
  onColumnResizeEndCallback: PropTypes.func as Requireable<(newColumnWidth: number, columnKey: string) => void>,

  /**
   * Callback that is called when `rowHeightGetter` returns a different height
   * for a row than the `rowHeight` prop. This is necessary because initially
   * table estimates heights of some parts of the content.
   */
  onContentHeightChange: PropTypes.func,

  /**
   * Method that is called when the table is focused
   */
  onFocus: PropTypes.func as Requireable<(event: React.FocusEvent<HTMLDivElement>) => void>,

  /**
   * Method that is called when the page is changed (e.g. first, last or a page number)
   */
  onPageChange: PropTypes.func as Requireable<(pageNum: number) => void>,

  /**
   * Method that is called when the paging size is changed
   */
  onPageSizeChange: PropTypes.func as Requireable<(pageSize: number) => void>,

  /**
   * Callback that is called when a row is clicked.
   */
  onRowClick: PropTypes.func as Requireable<TableRowEventHandler>,

  /**
   * Callback that is called when a row is double clicked.
   */
  onRowDoubleClick: PropTypes.func,

  /**
   * Callback that is called when a mouse-down event happens on a row.
   */
  onRowMouseDown: PropTypes.func,

  /**
   * Callback that is called when a mouse-enter event happens on a row.
   */
  onRowMouseEnter: PropTypes.func,

  /**
   * Callback that is called when a mouse-leave event happens on a row.
   */
  onRowMouseLeave: PropTypes.func,

  /**
   * Callback that is called when scrolling ends or stops with new horizontal
   * and vertical scroll values.
   */
  onScrollEnd: PropTypes.func,

  /**
   * Callback that is called when scrolling starts with current horizontal
   * and vertical scroll values.
   */
  onScrollStart: PropTypes.func,

  /**
   * Method that is called when the row selection changes
   */
  onSelectedRowsChange: PropTypes.func,

  onToggleRow: PropTypes.func as Requireable<(rowNumber: number) => void>,

  overflowX: PropTypes.oneOf(['hidden', 'auto']),
  overflowY: PropTypes.oneOf(['hidden', 'auto']),

  /**
   * Pixel height of table's owner, this is used in a managed scrolling
   * situation when you want to slide the table up from below the fold
   * without having to constantly update the height on every scroll tick.
   * Instead, vary this property on scroll. By using `ownerHeight`, we
   * over-render the table while making sure the footer and horizontal
   * scrollbar of the table are visible when the current space for the table
   * in view is smaller than the final, over-flowing height of table. It
   * allows us to avoid resizing and reflowing table when it is moving in the
   * view.
   *
   * This is used if `ownerHeight < height` (or `maxHeight`).
   */
  ownerHeight: PropTypes.number,

  /**
   * Passthru to Pager, determines "Records per page:" message or equivalent
   */
  pageSizeLabel: PropTypes.node,

  /**
   * Immutable.List of strings where -1 represents ALL.  If nothing is passed in, paging will not be enabled
   */
  pageSizes: ImmutablePropTypes.listOf(PropTypes.number.isRequired),

  /**
   * Content to be displayed to the right of the pager
   */
  pagerContent: PropTypes.node,

  /**
   * Content to be displayed to the left of the pager
   */
  pagerContentLeft: PropTypes.node,

  /**
   * Content to be displayed to the right of the pager
   */
  pagerContentRight: PropTypes.node,

  /**
   * Content to be displayed above the pager
   */
  pagerContentTop: PropTypes.node,

  /**
   * Height of the pager.  Defaults to 35
   */
  pagerHeight: PropTypes.number,

  pagerInputDisabled: PropTypes.bool,

  /**
   * The current state of the pager for the table
   */
  pagerState: PropTypes.shape({
    /**
     * If true, do not show the pages dropdown
     */
    hidePageSize: PropTypes.bool,
    /**
     * The current page size
     */
    pageSize: PropTypes.number.isRequired,
    /**
     * The total number of pages.  If it is not supplied, then do not show the total count
     */
    pageCount: PropTypes.number,
    /**
     * The current number of the page
     */
    pageNum: PropTypes.number.isRequired,
  }),

  /**
   * To get any additional CSS classes that should be added to a row,
   * `rowClassNameGetter(index)` is called.
   */
  rowClassNameGetter: PropTypes.func as Requireable<(index: number) => string>,

  /**
   * To get rows to display in table, `rowGetter(index)`
   * is called. `rowGetter` should be smart enough to handle async
   * fetching of data and return temporary objects
   * while data is being fetched.
   * @Deprecated
   */
  rowGetter: PropTypes.func,

  /**
   * Pixel height of rows unless `rowHeightGetter` is specified and returns
   * different value.
   */
  rowHeight: PropTypes.number,

  /**
   * If specified, `rowHeightGetter(index)` is called for each row and the
   * returned value overrides `rowHeight` for particular row.
   */
  rowHeightGetter: PropTypes.func,

  /**
   * Value of horizontal scroll.
   */
  scrollLeft: PropTypes.number,

  /**
   * Index of column to scroll to.
   */
  scrollToColumn: PropTypes.number,

  /**
   * Index of row to scroll to.
   */
  scrollToRow: PropTypes.number,

  /**
   * Value of vertical scroll.
   */
  scrollTop: PropTypes.number,

  /**
   * Optional Immutable.Set of numbers to set the current selected rows.
   * A defined value for this puts the selected rows behavior in a controlled state.
   */
  selectedRowNumbers: ImmutablePropTypes.setOf(PropTypes.number.isRequired),

  /**
   * If true, when a Table-controlled selection checkbox is toggled the containing row will NOT be activated.
   * This decouples selection from activation.
   */
  selectionDoesNotActivate: PropTypes.bool,

  /**
   * Optionally specify a tab index for the component
   */
  tabIndex: PropTypes.number,

  /**
   * Define the type of table this should be
   */
  tableType: PropTypes.oneOf<'lines' | 'stripes'>(['lines', 'stripes']),

  /**
   * Pixel width of table. If all columns do not fit,
   * a horizontal scrollbar will appear.
   *
   * If no value is specified, the width will be set to 100%
   */
  width: PropTypes.number,
};

type TableProps = InferProps<typeof propTypes>;

type TableState = {
  activeRowNumber: number | undefined;
  selectedRowNumbers: Immutable.Set<number>;
};

/**
 * Passthrough class for FixedDataTable's Table component with some extra autosizing functionality and default values set
 */
class Table extends React.Component<TableProps, TableState> {
  triggerScrollToTop: boolean | undefined;
  triggerAutoScroll: boolean | undefined;
  hoveredRowNumber: number | undefined;

  static propTypes = propTypes;

  static defaultProps = {
    tableType: 'lines',
    minColumnWidth: 20,
    rowHeight: DEFAULT_ROW_HEIGHT,
    headerHeight: DEFAULT_HEADER_HEIGHT,
    pagerHeight: DEFAULT_PAGER_HEIGHT,
    selectionDoesNotActivate: false,
  };

  state = {
    activeRowNumber: undefined as number | undefined,
    selectedRowNumbers: Immutable.Set<number>(),
  };

  componentWillUnmount() {
    this.unregisterKeyListeners();
  }

  onActiveCellChange = (e: ExtendedKeyboardEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>, rowNum: number) => {
    // This makes me a little sad but there's no other way to set classes on the row-wrapper
    // See: https://github.com/facebook/fixed-data-table/issues/314
    const domNode = ReactDOM.findDOMNode(this.refs.tableComponent);
    if (!domNode) {
      console.error('Could not handle active cell change - could not find table DOM node');
      return;
    }
    const $rowWrappers = $(domNode).find('.fixedDataTableRowLayout_rowWrapper');
    $rowWrappers.find('.active').removeClass('active');
    $rowWrappers.find(`.row-number-${rowNum}`).addClass('active');

    if (this.props.onActiveCellChange) {
      this.props.onActiveCellChange(e, rowNum);
    }
    if (!this.activeRowIsControlled()) {
      this.setState({
        activeRowNumber: rowNum,
      });
    }
  };

  onBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    this.unregisterKeyListeners();
    if (this.props.onBlur) {
      this.props.onBlur.apply(this, [event]);
    }
  };

  onFocus = (event: React.FocusEvent<HTMLDivElement>) => {
    this.registerKeyListeners();
    if (this.props.onFocus) {
      this.props.onFocus.apply(this, [event]);
    }
  };

  onPageChange = (...args: $TSFixMe[]) => {
    this.props.onPageChange && this.props.onPageChange.apply(this, args);
    // Reset checked state on navigation
    this.massSelect(false);
    this.triggerScrollToTop = true;
  };

  // Add selection handler
  onRowClick: TableRowEventHandler = (...args) => {
    if (this.props.onRowClick) {
      this.props.onRowClick.apply(this, args);
    }
    this.onActiveCellChange.apply(this, args);
  };

  // Add hover handlers
  onRowMouseEnter: TableRowEventHandler = (e, rowNum) => {
    this.hoveredRowNumber = rowNum;

    // This, combined with using this variable in `rowClassNameGetter` gets us acceptable perf
    const domNode = ReactDOM.findDOMNode(this.refs.tableComponent);
    if (!domNode) {
      console.error('Could not handle row mouse enter event - could not find table DOM node');
      return;
    }
    $(domNode)
      .find('.row-number-' + rowNum)
      .addClass('hover');

    if (this.props.onRowMouseEnter) {
      this.props.onRowMouseEnter.apply(this, [e, rowNum]);
    }
  };

  onRowMouseLeave: TableRowEventHandler = (...args) => {
    this.hoveredRowNumber = -1;

    // This, combined with using this variable in `rowClassNameGetter` gets us acceptable perf
    const domNode = ReactDOM.findDOMNode(this.refs.tableComponent);
    if (!domNode) {
      console.error('Could not handle row mouse leave event - could not find table DOM node');
      return;
    }
    $(domNode).find('.public_fixedDataTable_bodyRow').removeClass('hover');

    if (this.props.onRowMouseLeave) {
      this.props.onRowMouseLeave.apply(this, args);
    }
  };

  /**
   * Reconciles the current notion of selectedRowNumbers with any changes present in rowSelections.
   * @param rowSelections an array of objects with shape {rowNum, selected}
   */
  onSelectedRowsChange = (rowSelections: { rowNum: number; selected: boolean }[]) => {
    let selectedRowNumbers = this.getSelectedRowNumbers();
    _.each(rowSelections, (rowSelection) => {
      if (rowSelection.selected) {
        selectedRowNumbers = selectedRowNumbers.add(rowSelection.rowNum);
      } else {
        selectedRowNumbers = selectedRowNumbers.delete(rowSelection.rowNum);
      }
    });
    if (this.props.onSelectedRowsChange) {
      this.props.onSelectedRowsChange(selectedRowNumbers, rowSelections);
    }
    if (!this.selectedRowsAreControlled()) {
      this.setState({ selectedRowNumbers });
    }
  };

  /**
   * Returns the current focused (active) cell.  If an activeRowNumber was passed in to props,
   * return that.  This allows managing active cell state from a separate component or store
   */
  getActiveRowNumber = (): number | undefined => {
    if (this.activeRowIsControlled()) {
      return this.props.activeRowNumber || undefined;
    }
    return this.state.activeRowNumber;
  };

  getCheckboxColumn = () => {
    const selectedRowNumbers = this.getSelectedRowNumbers();
    const shouldSelectAll = selectedRowNumbers.size < this.props.getLength();
    const selected = (rowIndex: number) => selectedRowNumbers.has(rowIndex);
    const allSelected = !shouldSelectAll;
    const onToggle = this.handleCheckboxCellChange;
    const onToggleAll = _.partial(this.massSelect, shouldSelectAll);
    return CheckboxColumn({ selected, allSelected, onToggle, onToggleAll });
  };

  getPager = () => {
    const {
      pageSizes,
      onPageSizeChange,
      onPageChange,
      pagerState,
      pageSizeLabel,
      pagerContent,
      pagerContentLeft,
      pagerContentTop,
      pagerContentRight,
      hidePageNavigation,
      pagerInputDisabled,
    } = this.props;
    const hasSufficientPageNavigationProps = hidePageNavigation || !!onPageChange;
    if (pageSizes && onPageSizeChange && hasSufficientPageNavigationProps && pagerState) {
      return (
        <PagerBar
          pageSizes={pageSizes}
          onPageSizeChange={onPageSizeChange}
          onPageChange={this.onPageChange}
          pagerState={pagerState}
          pageSizeLabel={pageSizeLabel}
          pagerContent={pagerContent}
          pagerContentLeft={pagerContentLeft}
          pagerContentTop={pagerContentTop}
          pagerContentRight={pagerContentRight}
          hidePageNavigation={hidePageNavigation}
          inputDisabled={pagerInputDisabled}
        />
      );
    }
  };

  /**
   * Returns the current selected cells.  If the selectedRowNumbers prop was passed in, return those.
   * This allows managing selected cell state from a separate component or store
   */
  getSelectedRowNumbers = (): Immutable.Set<number> => {
    if (this.props.selectedRowNumbers) {
      return this.props.selectedRowNumbers;
    }
    return this.state.selectedRowNumbers;
  };

  /**
   * Convenience method for readability.
   */
  activeRowIsControlled = () => {
    return this.props.hasOwnProperty('activeRowNumber');
  };

  handleCheckboxCellChange = (
    rowNum: number,
    selected: boolean,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (!selected) {
      // regardles of shift key, unselect row without activating it
      this.onSelectedRowsChange([{ rowNum, selected }]);
      return;
    }
    const selectedRowNumbers = this.getSelectedRowNumbers();
    const activeRowNumber = this.getActiveRowNumber();
    let rowSelectionChanges;
    if (!selectedRowNumbers.isEmpty() && e.shiftKey && activeRowNumber) {
      // select all rows between active row and what was just clicked, then activate the just clicked row
      const rows = _.range(Math.min(activeRowNumber, rowNum), Math.max(activeRowNumber, rowNum) + 1);
      rowSelectionChanges = _.map(rows, (row) => {
        return {
          rowNum: row,
          selected: true,
        };
      });
    } else {
      // select and activate row
      rowSelectionChanges = [{ rowNum, selected }];
    }
    this.onSelectedRowsChange(rowSelectionChanges);
    if (!this.props.selectionDoesNotActivate) {
      this.onActiveCellChange(e, rowNum);
    }
  };

  hasPager = () => {
    return this.props.hasOwnProperty('pageSizes');
  };

  massSelect = (select: boolean) => {
    const rowSelections = _.map(_.range(0, this.props.getLength()), (rowNum) => {
      return {
        rowNum,
        selected: select,
      };
    });

    this.onSelectedRowsChange(rowSelections);
  };

  /**
   * Register keylisteners for row navigation
   */
  registerKeyListeners = () => {
    Mousetrap.bind('up', (e) => {
      this.triggerAutoScroll = true;
      const activeRowNumber = this.getActiveRowNumber();
      if (activeRowNumber !== undefined) {
        const activeCell = Math.max(activeRowNumber - 1, 0);
        this.onActiveCellChange(e, activeCell);
      }
      return false;
    });

    Mousetrap.bind('down', (e) => {
      this.triggerAutoScroll = true;
      const activeRowNumber = this.getActiveRowNumber();
      if (activeRowNumber !== undefined) {
        const activeCell = Math.min(activeRowNumber + 1, this.props.getLength() - 1);
        this.onActiveCellChange(e, activeCell);
      }
      return false;
    });

    Mousetrap.bind('space', () => {
      this.triggerAutoScroll = true;
      const activeRowNumber = this.getActiveRowNumber();
      if (activeRowNumber === undefined) {
        // no active row number, so don't affect selected rows
        return false;
      }
      const rowSelection = {
        rowNum: activeRowNumber,
        selected: !this.getSelectedRowNumbers().has(activeRowNumber),
      };
      if (this.props.onToggleRow) {
        this.props.onToggleRow(activeRowNumber);
      }
      if (this.props.enableMultipleRowSelection) {
        this.onSelectedRowsChange.apply(this, [[rowSelection]]);
      }
      return false;
    });
  };

  rowClassNameGetter = (rowNum: number) => {
    let rowClasses = classNames({
      hover: rowNum === this.hoveredRowNumber,
      active: rowNum === this.getActiveRowNumber(),
      selected: this.getSelectedRowNumbers().has(rowNum),
    });

    // Add the row number to the classname for special styling
    rowClasses = rowClasses + ' row-number-' + rowNum;

    if (this.props.rowClassNameGetter) {
      rowClasses = rowClasses + ' ' + this.props.rowClassNameGetter.apply(this, [rowNum]);
    }
    return rowClasses;
  };

  selectedRowsAreControlled = () => {
    return this.props.hasOwnProperty('selectedRowNumbers');
  };

  unregisterKeyListeners = () => {
    Mousetrap.unbind('up');
    Mousetrap.unbind('down');
    Mousetrap.unbind('space');
  };

  render() {
    const { className, tableType, height, children, minColumnWidth, pagerHeight } = this.props;

    const containerClasses = classNames('table-component-container', className, tableType);

    let checkboxColumn;
    if (this.props.enableMultipleRowSelection) {
      checkboxColumn = this.getCheckboxColumn();
    }

    let scrollToRow;
    if (_.isNumber(this.props.scrollToRow)) {
      scrollToRow = this.props.scrollToRow;
    } else if (this.triggerScrollToTop) {
      scrollToRow = 0;
    } else if (this.triggerAutoScroll) {
      scrollToRow = this.getActiveRowNumber();
    }
    this.triggerAutoScroll = false;
    this.triggerScrollToTop = false;

    const columns = React.Children.map(children, (column) => {
      // @ts-expect-error
      return React.cloneElement(column, {
        // @ts-expect-error
        minWidth: column.props.minWidth || minColumnWidth,
        allowCellsRecycling: true,
      });
    });

    return (
      <div className={containerClasses} tabIndex={this.props.tabIndex || 1} onFocus={this.onFocus} onBlur={this.onBlur}>
        <FTable
          ref="tableComponent"
          rowsCount={this.props.getLength()}
          {...this.props}
          // @ts-expect-error
          height={this.hasPager() ? height - pagerHeight : height}
          rowClassNameGetter={this.rowClassNameGetter}
          onRowClick={this.onRowClick}
          onRowMouseEnter={this.onRowMouseEnter}
          onRowMouseLeave={this.onRowMouseLeave}
          scrollToRow={scrollToRow}
          isColumnResizing={false}>
          {checkboxColumn}
          {columns}
        </FTable>
        {this.getPager()}
      </div>
    );
  }
}

export default Table;
