import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import TamrIcon from '../components/TamrIcon';
import { history } from '../utils/History';
import style from './CategorizationDashboardGuide.module.scss';

const DashboardStep = ({ isCurrentKey, isFirstStep, stepInfo }) => {
  const { wizardTitle, wizardCompletedTitle, wizardIcon, wizardURL, isStepCompleted } = stepInfo;
  return (
    <div
      className={classNames(style.step, isStepCompleted ? style.previous : (isCurrentKey ? style.current : style.next))}
      onClick={isStepCompleted || isCurrentKey ? (() => history.push(wizardURL)) : undefined}
    >
      <div className={style.stepText}>{isStepCompleted ? wizardCompletedTitle : wizardTitle}</div>
      <div className={style.stepCircle}>
        <TamrIcon iconName={isStepCompleted ? 'check' : wizardIcon} size={20} />
      </div>
      {isFirstStep ? null : <div className={style.stepLine} />}
    </div>
  );
};

export const dashboardStepInfoPropType = PropTypes.shape({
  wizardTitle: PropTypes.node.isRequired,
  wizardCompletedTitle: PropTypes.node.isRequired,
  wizardIcon: PropTypes.string.isRequired,
  wizardURL: PropTypes.string.isRequired,
  cardTitle: PropTypes.node.isRequired,
  cardText: PropTypes.node.isRequired,
  documentationURL: PropTypes.string.isRequired,
  cardButtonText: PropTypes.node.isRequired,
  cardImage: PropTypes.string.isRequired,
  isStepCompleted: PropTypes.bool.isRequired,
});

DashboardStep.propTypes = {
  isCurrentKey: PropTypes.bool.isRequired,
  isFirstStep: PropTypes.bool.isRequired,
  stepInfo: dashboardStepInfoPropType.isRequired,
};

export default DashboardStep;
