import { List, OrderedSet, Set } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';

import { startAddDataset } from '../addDataset/AddDatasetAsync';
import Button from '../components/Button';
import ColumnWidthProvider from '../components/ColumnWidthProvider';
import LoadingPanel from '../components/LoadingPanel';
import Pager from '../components/Table/PagerModel';
import Table from '../components/Table/Table';
import { CLASSIFICATION } from '../constants/ProjectTypes';
import { SET_TAMR_SOURCE_DATASET, OPEN_EXPORT_DIALOG, OPEN_EXPORT_JDBC_DIALOG } from '../coreConnectService/CoreConnectServiceActionTypes';
import Dataset from '../models/Dataset';
import KeyMods from '../models/KeyMods';
import {
  getProjectGeneratedIdsByProjectId,
  getUnifiedDatasetIdsByProjectId,
  selectProjectDocsById,
} from '../projects/ProjectsStore';
import { AppAction, AppThunkAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { isAuthorByProjectId, isCuratorByProjectId } from '../utils/Authorization';
import {
  getAuthorizedUser,
  getCurrentlyExportingDatasetIds,
  getCurrentlyProfilingDatasetNames,
  selectActiveProjectInfo,
} from '../utils/Selectors';
import { getPath } from '../utils/Values';
import DatasetDescriptionColumn from './DatasetDescriptionColumn';
import DatasetExportColumn from './DatasetExportColumn';
import DatasetIdFieldColumn from './DatasetIdFieldColumn';
import DatasetLastUpdatedColumn from './DatasetLastUpdatedColumn';
import DatasetNameColumn from './DatasetNameColumn';
import DatasetNumAttributesColumn from './DatasetNumAttributesColumn';
import DatasetNumRecordsColumn from './DatasetNumRecordsColumn';
import DatasetOriginColumn from './DatasetOriginColumn';
import DatasetPoliciesColumn from './DatasetPoliciesColumn';
import DatasetTagsColumn from './DatasetTagsColumn';
import style from './ProjectDatasetCatalogTable.module.scss';
// @ts-expect-error
import { fetchDatasetSample } from './SharedDatasetCatalogApi';

const PAGE_SIZES = List.of(25, 50, 100);

const ProjectDatasetCatalogTable = connect((state: AppState) => {
  const {
    projects,
    tags: { allTags },
    projectDatasetCatalog: { datasets, profiling, exporting, status, paging, loading, selectedRows, noDatasetsInProject },
    coreConnectService: { coreconnectDefaultProvider },
    accessControl: { policyDocs },
  } = state;
  const projectInfo = selectActiveProjectInfo(state);
  const authUser = getAuthorizedUser(state);
  const isUserCurator = isCuratorByProjectId(authUser, getPath(projectInfo, 'projectId'));
  const isUserAuthor = isAuthorByProjectId(authUser, getPath(projectInfo, 'projectId'));

  return {
    allTags,
    projectsById: selectProjectDocsById(state),
    unifiedDatasetIdsByProjectId: getUnifiedDatasetIdsByProjectId(projects),
    projectGeneratedIdsByProjectId: getProjectGeneratedIdsByProjectId(projects),
    currentlyProfilingDatasetNames: getCurrentlyProfilingDatasetNames(state),
    recipeDoc: getPath(projectInfo, 'recipeDoc'),
    datasets,
    profiling,
    exporting,
    status,
    paging,
    loading,
    currentlyExporting: getCurrentlyExportingDatasetIds(state),
    isUserCurator,
    isUserAuthor,
    selectedRows,
    noDatasetsInProject,
    coreconnectDefaultProvider,
    policyDocs,
  };
}, {
  onConfirmRemoveDatasets: (datasetNames: Set<string>): AppAction => ({ type: 'ProjectDatasetCatalog.confirmRemoveDatasets', datasetNames }),
  onConfirmExport: (datasetNames: Set<string>): AppAction => ({ type: 'SharedDatasetCatalog.confirmExport', datasetNames }),
  onConfirmOverwriteExport: (datasetNames: Set<string>): AppAction => ({ type: 'SharedDatasetCatalog.confirmOverwriteExport', datasetNames }),
  onConfirmProfile: (datasetNames: Set<string>): AppAction => ({ type: 'SharedDatasetCatalog.confirmProfile', datasetNames }),
  onConfigureDataset: (datasetName: string): AppAction => ({ type: 'SharedDatasetCatalog.configureDataset', datasetName }),
  onShowExportFailureDialog: (errorMessage: string): AppAction => ({ type: 'SharedDatasetCatalog.showExportFailureDialog', errorMessage }),
  onPageChange: (pageNum: number): AppAction => ({ type: 'ProjectDatasetCatalog.setPage', pageNum }),
  onPageSizeChange: (pageSize: number): AppAction => ({ type: 'ProjectDatasetCatalog.setPageSize', pageSize }),
  onSelectRows: (selectedRows: OrderedSet<number>): AppAction => ({ type: 'ProjectDatasetCatalog.selectRows', selectedRows }),
  onClickRows: (keyMods: KeyMods, rowNum: number): AppAction => ({ type: 'ProjectDatasetCatalog.clickRows', keyMods, rowNum }),
  onFetchDatasetSample: fetchDatasetSample,
  onManageMembership: (): AppThunkAction<void> => startAddDataset({ isCatalogPage: false }),
  onToggleShowUpload: (): AppAction => ({ type: 'ProjectDatasetCatalog.toggleShowUpload' }),
  onOpenCoreConnectExportDialog: (): AppAction => ({ type: OPEN_EXPORT_DIALOG }),
  onOpenCoreConnectExportJDBCDialog: (): AppAction => ({ type: OPEN_EXPORT_JDBC_DIALOG }),
  onSetTamrSourceDataset: (tamrSourceDataset: Dataset): AppAction => ({ type: SET_TAMR_SOURCE_DATASET, tamrSourceDataset }),
  onStartEditingPolicyResourceship: ({ datasetId, datasetName }: {datasetId: string, datasetName: string}) => ({ type: 'Datasets.startEditingPolicyResourceship', datasetId, datasetName }),
})(({ onToggleShowUpload, onManageMembership, noDatasetsInProject, currentlyProfilingDatasetNames, recipeDoc,
  onPageChange, onPageSizeChange, onSelectRows, onClickRows, onFetchDatasetSample, selectedRows, profiling,
  onConfirmExport, onConfirmOverwriteExport, onShowExportFailureDialog, isUserCurator, isUserAuthor, currentlyExporting,
  exporting, status, loading, paging, datasets, unifiedDatasetIdsByProjectId, projectGeneratedIdsByProjectId,
  projectsById, allTags, onConfigureDataset, coreconnectDefaultProvider, onOpenCoreConnectExportDialog, onOpenCoreConnectExportJDBCDialog, onSetTamrSourceDataset, onStartEditingPolicyResourceship, policyDocs }) => {
  const pager = new Pager(
    datasets,
    paging.numDatasets,
    paging.page,
    paging.pageSize,
  );
  const noDatasetsInFilter = !datasets || datasets.isEmpty();

  return (
    <div className={style.component}>
      {noDatasetsInProject ? (
        <div className={style.center}>
          <span style={{ marginBottom: '6px' }} className={style.message}>You don't have any datasets in your project.</span>
          {isUserCurator ? (
            <div>
              <div style={{ textAlign: 'center' }}>
                <Button
                  onClick={() => {
                    onManageMembership();
                    onToggleShowUpload();
                  }}
                  style={{ margin: '4px' }}
                >
                  Add new dataset
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      ) : noDatasetsInFilter && !loading ? (
        <div className={style.center}>
          <span className={style.message}>No datasets matching your filters</span>
        </div>
      ) : (
        <AutoSizer>
          {({ width, height }) => (
            <div>
              {loading ? <LoadingPanel aboveCenter /> : null}
              <ColumnWidthProvider>
                <Table
                  enableMultipleRowSelection
                  getLength={() => datasets.size}
                  tableType="stripes"
                  height={height}
                  onPageChange={onPageChange}
                  onPageSizeChange={onPageSizeChange}
                  onSelectedRowsChange={onSelectRows}
                  pagerState={pager}
                  pageSizes={PAGE_SIZES}
                  selectedRowNumbers={selectedRows}
                  onRowClick={(e, rowNum) => {
                    if (!window.getSelection()?.toString()) {
                      onClickRows(KeyMods.fromJSON(e), rowNum);
                    }
                  }}
                  onToggleRow={index => onClickRows(new KeyMods({}).enableToggle(), index)}
                  rowClassNameGetter={(rowIndex) => {
                    const dataset = datasets.get(rowIndex);
                    if (!dataset) {
                      return '';
                    }
                    if (recipeDoc && recipeDoc.data.type === CLASSIFICATION) {
                      const metadata = recipeDoc.data.metadata.get('CATEGORIZATION');
                      if (metadata && metadata.datasetExternalStatus
                        && metadata.datasetExternalStatus[dataset.data.name]) {
                        return 'external-data';
                      }
                    }
                    return '';
                  }}
                  width={width}
                >
                  {DatasetNameColumn({ rows: datasets, showPreview: true, canUserPerformActions: isUserCurator, currentlyProfilingDatasetNames, profiling, status, onFetchDatasetSample, onConfigureDataset })}
                  {DatasetTagsColumn({ rows: datasets, allTags })}
                  {DatasetDescriptionColumn({ rows: datasets, width: 150 })}
                  {isUserAuthor ? DatasetPoliciesColumn({ datasetDocs: datasets, policyDocs, onStartEditingPolicyResourceship, width: 150 }) : null}
                  {DatasetOriginColumn({ datasets, unifiedDatasetIdsByProjectId, projectGeneratedIdsByProjectId, projectsById })}
                  {DatasetIdFieldColumn({ width: 150, rows: datasets })}
                  {DatasetNumAttributesColumn({ width: 85, rows: datasets })}
                  {DatasetNumRecordsColumn({ width: 85, rows: datasets, currentlyProfilingDatasetNames, profiling })}
                  {DatasetLastUpdatedColumn({ datasets })}
                  {isUserCurator ? DatasetExportColumn({ datasets, exporting, status, currentlyExportingDatasetIds: currentlyExporting.toSet(), onConfirmExport, onConfirmOverwriteExport, onShowExportFailureDialog, coreconnectDefaultProvider, onOpenCoreConnectExportDialog, onOpenCoreConnectExportJDBCDialog, onSetTamrSourceDataset }) : null}
                </Table>
              </ColumnWidthProvider>
            </div>
          )}
        </AutoSizer>
      )}
    </div>
  );
});

export default ProjectDatasetCatalogTable;
