import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import TamrIcon from '../components/TamrIcon';
import { profileDataset } from '../datasets/DatasetsApi';
import { getUnifiedDatasetName, isActiveUnifiedDatasetProfilingUpToDate } from '../utils/Selectors';
import RecordsProfileWarning from './RecordsProfileWarning';
import style from './RecordsSubHeader.module.scss';
import { ES, PREVIEW, PROVIDERS } from './RowProviders';

export const DEFAULT_NUM_PREVIEW_DATASETS = 5;

const RecordsSubHeader = _.compose(
  connect(state => {
    const { records: { provider, selectedDatasetNames } } = state;
    const numSelectedDatasets = selectedDatasetNames && selectedDatasetNames.size || 0;
    return {
      provider,
      numSelectedDatasets,
      selectedDatasetNames,
      profilingUpToDate: isActiveUnifiedDatasetProfilingUpToDate(state),
      unifiedDatasetName: getUnifiedDatasetName(state),
    };
  }, {
    onUseRowProvider: provider => ({ type: 'Records.useRowProvider', provider }),
    openDatasetFilterDialog: () => ({ type: 'Records.openDatasetFilterDialog' }),
    onProfile: profileDataset,
  }),
)(class RecordsSubHeader extends React.Component {
  static propTypes = {
    canUserEdit: PropTypes.bool.isRequired,
    onUseRowProvider: PropTypes.func.isRequired,
    provider: PropTypes.oneOf(PROVIDERS).isRequired,
  };

  render() {
    const {
      profilingUpToDate,
      onProfile,
      unifiedDatasetName,
      onUseRowProvider,
      provider,
      numSelectedDatasets,
      openDatasetFilterDialog,
      canUserEdit,
    } = this.props;

    const selectedMessage = numSelectedDatasets > 0
      ? <span>This is a sample of <b>{numSelectedDatasets}</b> source datasets.</span>
      : <span>This is a preview of your unified dataset.</span>;
    return (
      <div className={style.toggleHeader}>
        {provider === ES ?
          <div>
            <div>
              <span>
                You may have changes which are not reflected.&nbsp;
              </span>
              <a onClick={() => onUseRowProvider(PREVIEW)}>View preview</a>
              <span>.</span>
            </div>
            <div>
              {
                !profilingUpToDate && canUserEdit &&
                <span>
                  <TamrIcon className="warning" iconName="tamr-icon-warning" size={14} />
                  &nbsp;
                  <span>
                    The profiling information for your Unified Dataset is out of date. &nbsp;
                    <a onClick={() => onProfile(unifiedDatasetName)}>Re-profile</a>
                  </span>
                </span>
              }
            </div>
          </div>
          :
          <div>
            <div>
              {selectedMessage}&nbsp;
              <a onClick={openDatasetFilterDialog}>Choose</a>
              <span> which datasets to preview or </span>
              <a onClick={() => onUseRowProvider(ES)}>view all records</a>
              <span>.</span>
            </div>
            {canUserEdit && <RecordsProfileWarning />}
          </div>
        }
      </div>
    );
  }
});

export default RecordsSubHeader;
