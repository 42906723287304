import { ArgTypes } from '../utils/ArgValidation';
import { checkTypesAndCast } from '../utils/CheckTypesAndCast';
import { Maybe } from '../utils/typescript';
import ChoosingAggregationFunction, { ChoosingAggregationFunctionE } from './ChoosingAggregationFunction';

// com.tamr.recipe.models.flows.goldenRecords.ChoosingAggregation

export const TYPE = 'ChoosingAggregation';

// These fields are not actually optional from the perspective of the server,
//   but they are optional here to help the form UX for building filters
export interface ChoosingAggregation {
  type: typeof TYPE
  function: Maybe<ChoosingAggregationFunctionE>
  expression: Maybe<string>
}
const argTypes = {
  type: ArgTypes.eq(TYPE as typeof TYPE),
  function: ArgTypes.orUndefined(ArgTypes.valueIn(ChoosingAggregationFunction)),
  expression: ArgTypes.orUndefined(ArgTypes.string),
} as const;
export const fromJSON = checkTypesAndCast<ChoosingAggregation, typeof argTypes>(argTypes);

export const argType = ArgTypes.object.withShape(argTypes);

export function isValid(filter: ChoosingAggregation) {
  return !!filter.function && !!filter.expression;
}
