import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import ProgressBar from '../components/ProgressBar/ProgressBar';
import Term from '../components/Term';
import { getPerc, shortFormat } from '../utils/Numbers';
import style from './FeedbackProgress.module.scss';

const FeedbackProgress = _.compose(
  connect((state) => {
    const { transactions: { assignedToMe, feedbackSummaryNumAssigned: numAssigned, feedbackSummaryNumResponded: numResponded } } = state;
    return { assignedToMe, numAssigned, numResponded };
  }),
)(({ assignedToMe, numAssigned, numResponded }) => {
  if (!assignedToMe) {
    return <noscript />; // equivalent to null, currently stateless components can't return null
  }
  return (
    <div className={style.component}>
      <div className={style.label}>
        {shortFormat(numResponded)}/{shortFormat(numAssigned)} <Term amount={numResponded}>record</Term> reviewed
      </div>
      <ProgressBar now={getPerc(numResponded, numAssigned)} />
    </div>
  );
});

export default FeedbackProgress;
