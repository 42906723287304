import { List } from 'immutable';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import LoadingPanel from '../components/LoadingPanel';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Table from '../components/Table/Table';
import { ORIGIN_SOURCE_NAME } from '../constants/ElasticConstants';
import { State } from '../stores/AppState';
import { JsonContent } from '../utils/typescript';
import style from './GroupRecordsDialog.module.scss';
import { CANCEL_PREVIEW_GROUP_RECORDS, SET_PREVIEW_PAGE_NUMBER, SET_PREVIEW_PAGE_SIZE } from './PregroupActionTypes';
import { fetchPreviewGroupRecords } from './PregroupAsync';
import { PregroupData } from './PregroupData';
import PregroupRecordCell from './PregroupRecordCell';
import {
  ORIGIN_SOURCE_NAME_HEADER_NAME,
  selectColumnsWithWidths,
  selectIsPreviewGroupRecordsLoading,
  selectPreviewGroupPageNum,
  selectPreviewGroupPageSize,
  selectPreviewGroupRecordsData,
  selectPreviewGroupRowIndex,
} from './PregroupStore';


function mapStateToProps(state: State) {
  return {
    columns: selectColumnsWithWidths(state),
    groupRowIndex: selectPreviewGroupRowIndex(state),
    records: selectPreviewGroupRecordsData(state),
    pageNum: selectPreviewGroupPageNum(state),
    pageSize: selectPreviewGroupPageSize(state),
    isLoading: selectIsPreviewGroupRecordsLoading(state),
  };
}

const GroupRecordsDialog = connect(mapStateToProps, {
  onClose: () => ({ type: CANCEL_PREVIEW_GROUP_RECORDS }),
  onFetchRecords: () => fetchPreviewGroupRecords(),
  onSetPageNumber: (pageNum: number) => ({ type: SET_PREVIEW_PAGE_NUMBER, pageNum }),
  onSetPageSize: (pageSize: number) => ({ type: SET_PREVIEW_PAGE_SIZE, pageSize }),
})(({ columns, records, onClose, onFetchRecords, onSetPageNumber, onSetPageSize, groupRowIndex, pageNum, pageSize, isLoading }) => {
  useEffect(() => {
    groupRowIndex !== null && onFetchRecords();
  }, [groupRowIndex, pageNum, pageSize]);

  return (
    <Dialog
      show={groupRowIndex !== null && columns !== null}
      className={style.dialog}
      bodyClassName={style.dialogBody}
      dialogStyle={DialogStyle.FULL}
      onHide={onClose}
      title="Preview of Grouped Record"
      body={(groupRowIndex === null || columns === null) ? null : (
        <div className={style.previewContent}>
          <div className={style.tableTitle}>Grouped Record</div>
          <div className={style.groupRecordContainer}>
            <AutoSizer>
              {({ height, width }) => (
                <Table
                  {...{ height, width }}
                  getLength={() => 1}
                  rowHeight={30}
                  headerHeight={30}
                >
                  {columns.map((c, i) => (
                    <Column
                      key={i}
                      width={c.width}
                      header={<Cell>{c.column === ORIGIN_SOURCE_NAME ? ORIGIN_SOURCE_NAME_HEADER_NAME : c.column}</Cell>}
                      cell={() => <PregroupRecordCell rowIndex={groupRowIndex} column={c.column} />}
                    />
                  ))}
                </Table>
              )}
            </AutoSizer>
          </div>
          <div className={style.tableTitle}>Records {records && `(${records.total})`}</div>
          <div className={style.recordsContainer}>
            {isLoading && <LoadingPanel />}
            {records && (
              <AutoSizer>
                {({ height, width }) => (
                  <Table
                    {...{ height, width }}
                    getLength={() => records.items.size}
                    rowHeight={30}
                    headerHeight={30}
                    pageSizes={List([50, 100, 500, 1000])}
                    onPageChange={newPageNum => onSetPageNumber(newPageNum)}
                    onPageSizeChange={newPageSize => onSetPageSize(newPageSize)}
                    pagerState={{
                      pageSize,
                      pageCount: Math.ceil(records.total / pageSize),
                      pageNum,
                    }}
                >
                    {columns.map((c, i) => (
                      <Column
                        key={i}
                        width={c.width}
                        header={<Cell>{c.column}</Cell>}
                        cell={({ rowIndex }) => {
                          const record = records.items.get(rowIndex);
                          return (
                            <Cell>
                              {!record ? null : (
                                <PregroupData
                                  typedValue={record[c.column] as JsonContent}
                                />
                              )}
                            </Cell>
                          );
                        }}
                    />
                    ))}
                  </Table>
                )}
              </AutoSizer>
            )}
          </div>
        </div>
      )}
      footer={
        <ButtonToolbar>
          <Button onClick={onClose}>Close</Button>
        </ButtonToolbar>
      }
    />
  );
});

export default GroupRecordsDialog;
