// com.tamr.dedup.services.elastic.ClusterQuery.ClusterChangeStatus

import { Set } from 'immutable';

import enumify from './enumify';

export enum ClusterChangesE {
  UNCHANGED_CLUSTERS = 'UNCHANGED_CLUSTERS',
  NEW_CLUSTERS = 'NEW_CLUSTERS',
  REMOVED_CLUSTERS = 'REMOVED_CLUSTERS',
  CLUSTERS_WITH_NEW_RECORDS_ADDED = 'CLUSTERS_WITH_NEW_RECORDS_ADDED',
  CLUSTERS_WITH_DELETED_RECORDS_REMOVED = 'CLUSTERS_WITH_DELETED_RECORDS_REMOVED',
  CLUSTERS_WITH_EXISTING_RECORDS_ADDED = 'CLUSTERS_WITH_EXISTING_RECORDS_ADDED',
  CLUSTERS_WITH_EXISTING_RECORDS_REMOVED = 'CLUSTERS_WITH_EXISTING_RECORDS_REMOVED',
}

export const { UNCHANGED_CLUSTERS, NEW_CLUSTERS, REMOVED_CLUSTERS, CLUSTERS_WITH_NEW_RECORDS_ADDED, CLUSTERS_WITH_DELETED_RECORDS_REMOVED, CLUSTERS_WITH_EXISTING_RECORDS_ADDED, CLUSTERS_WITH_EXISTING_RECORDS_REMOVED } = ClusterChangesE;

export const WITH_CHANGES_FILTERS = Set([NEW_CLUSTERS, REMOVED_CLUSTERS, CLUSTERS_WITH_NEW_RECORDS_ADDED, CLUSTERS_WITH_DELETED_RECORDS_REMOVED, CLUSTERS_WITH_EXISTING_RECORDS_ADDED, CLUSTERS_WITH_EXISTING_RECORDS_REMOVED]);

export const CHANGED_CLUSTERS_SET = Set([CLUSTERS_WITH_NEW_RECORDS_ADDED, CLUSTERS_WITH_DELETED_RECORDS_REMOVED, CLUSTERS_WITH_EXISTING_RECORDS_ADDED, CLUSTERS_WITH_EXISTING_RECORDS_REMOVED]);
export const NEW_CLUSTERS_SET = Set([NEW_CLUSTERS]);
export const REMOVED_CLUSTERS_SET = Set([REMOVED_CLUSTERS]);

export default enumify(ClusterChangesE);
