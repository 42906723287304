import classNames from 'classnames';
import { List, Map } from 'immutable';
import React, { useState } from 'react';
import { AutoSizer } from 'react-virtualized';

import { FetchError } from '../api/FetchResult';
import SERVICES from '../api/ServiceProxy';
import Button from '../components/Button';
import ConditionalButton from '../components/ConditionalButton';
import Checkbox from '../components/Input/Checkbox';
import FileInput from '../components/Input/FileInput';
import Input from '../components/Input/Input';
import Selector from '../components/Input/Selector';
import TextArea from '../components/Input/TextArea';
import LoadingPanel from '../components/LoadingPanel';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Table from '../components/Table/Table';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import ErrorDialogBody from '../errorDialog/ErrorDialogBody';
import { getFetchErrorAdvancedDetail, getFetchErrorDetail } from '../errorDialog/ErrorDialogUtils';
import { canUpdateDatasetAtLeastOnePolicy } from '../utils/Authorization';
import PRODUCT_NAME from '../utils/ProductName';
import { getAuthorizedUser } from '../utils/Selectors';
import { $TSFixMe } from '../utils/typescript';
import { ConnectedFC } from '../utils/typescript-react';
import useForceUpdate from '../utils/useForceUpdate';
import DatasetPoliciesSelector, { FUTURE_DATASET_ID } from './DatasetPoliciesSelector';
import {
  SET_DESCRIPTION,
  SET_PREVIEW_COLUMN_WIDTH,
  SET_PRIMARY_KEY_COLUMN_NAME,
  SET_PRIMARY_KEY_CUSTOM_NAME,
  SET_SHOULD_PROFILE,
  SET_SHOULD_TRUNCATE,
  SET_VIEW_CURL_COMMAND,
  TOGGLE_CONFIG_VISIBLE,
} from './FileUploadActionTypes';
import { setFile, setFileUploadConfig } from './FileUploadAsync';
import { FileUploadConfig } from './FileUploadStore';


const PRIMARY_KEY_MESSAGE = `Datasets must have a primary key for ${PRODUCT_NAME} to reference individual records.  ${PRODUCT_NAME} will add a Primary Key column (with line numbers as values) to be used as a reference if accessing this dataset’s records via ${PRODUCT_NAME}’s API.`;
const PROFILING_MESSAGE = 'Profiling provides statistics about your dataset.  While profiling,  you will not be able to run other jobs.  You can profile datasets at any time.';
const TRUNCATING_MESSAGE = 'Truncating will drop existing rows in the dataset, if present, before uploading the new data file.';
const UNIQUE_PRIMARY_KEY_MESSAGE = 'Primary Keys must be unique values.  If multiple records have the same primary key, only one of those records will be preserved.';

const generateCurlCommand = ({
  config,
  description,
  fields,
  file,
  primaryKeyColumnName,
  primaryKeyCustomName,
  policyIds,
}: {
  config: FileUploadConfig,
  description: string,
  fields: List<string>,
  file: File,
  primaryKeyColumnName: string | undefined | null,
  primaryKeyCustomName: string | undefined | null,
  policyIds: Array<number>,
}) => {
  const header = '--header "Content-Type: multipart/form-data" ' +
    '--header "Accept: application/json"';
  const cookie = '-b "authToken=[ADD_YOUR_AUTH_SESSION_TOKEN_HERE]"';
  const fileName = file ? file.name : '';
  const filePath = `--form "file=@${fileName}"`;
  const idFieldMsg = `--form "idField=${(primaryKeyColumnName || primaryKeyCustomName)}"`;
  const descriptionMsg = `--form "description=${description || ''}"`;
  const fieldsMsg = fields.map((f) => `--form "fields=${f}"`).join(' ');
  const delimiter = `--form \$'delimiter=${config.get('columnSeparator')}'`;
  const quote = `--form "quote=\\${config.get('quoteCharacter')}"`;
  const escape = `--form "escape=\\${config.get('escapeCharacter')}"`;
  const comment = `--form "comment=${config.get('commentCharacter')}"`;
  const generatePrimaryKey = primaryKeyColumnName
    ? ''
    : '--form "generatePrimaryKey=true" ';
  const policyIdsQuery = policyIds.length > 0 ? ('?' + policyIds.map(id => `policyIds=${id}`).join('&')) : '';
  return `curl ${header} ${SERVICES.procure('/procurement/datasets')}${policyIdsQuery} ` +
    `${idFieldMsg} ${descriptionMsg} ${fieldsMsg} ` +
    `${delimiter} ${quote} ${escape} ${comment} ${generatePrimaryKey}${cookie} ${filePath}`;
};

const UploadFileErrorDetail: React.FC<{
  error: FetchError
}> = ({ error }) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  return (
    <ErrorDialogBody
      {...{ showMore, copied }}
      className="uploadErrorDetailsContainer"
      detail={getFetchErrorDetail(error)}
      advancedDetail={getFetchErrorAdvancedDetail(error)}
      onToggleShowMore={() => setShowMore(!showMore)}
      onCopy={() => setCopied(true)}
    />
  );
};


/**
 * Content for the Dialog used to add a new file to the Procurement app
 */

const FileUploadDialogContent = ConnectedFC(
  (state) => {
    const {
      fileUpload: {
        description,
        fields,
        file,
        shouldProfile,
        shouldTruncate,
        viewCurlCommand,
        loading,
        errorMessageHeader,
        errorMessage,
        fileUploadError,
        config,
        configVisible,
        primaryKeyColumnName,
        primaryKeyCustomName,
        previewData,
        previewColumnWidths,
      },
      accessControl: { datasetDraftPolicyResourceship },
    } = state;
    return {
      config,
      configVisible,
      description,
      errorMessage,
      errorMessageHeader,
      fileUploadError,
      fields,
      file,
      loading,
      primaryKeyColumnName,
      primaryKeyCustomName,
      shouldProfile,
      shouldTruncate,
      viewCurlCommand,
      previewData,
      previewColumnWidths,
      user: getAuthorizedUser(state),
      draftPolicyIds: datasetDraftPolicyResourceship ? Array.from(datasetDraftPolicyResourceship.getPolicyIds()) : [],
    };
  },
  {
    onSetFileUploadConfig: (config: FileUploadConfig) => setFileUploadConfig(config),
    onSetDescription: (description: string) => ({ type: SET_DESCRIPTION, description }),
    onSetFile: (file: $TSFixMe) => setFile(file),
    onSetShouldProfile: (shouldProfile: boolean) => ({ type: SET_SHOULD_PROFILE, shouldProfile }),
    onSetShouldTruncate: (shouldTruncate: boolean) => ({ type: SET_SHOULD_TRUNCATE, shouldTruncate }),
    onSetViewCurlCommand: (viewCurlCommand: boolean) => ({ type: SET_VIEW_CURL_COMMAND, viewCurlCommand }),
    onToggleConfigVisible: () => ({ type: TOGGLE_CONFIG_VISIBLE }),
    onSetPrimaryKeyColumnName: (columnName: string) => ({ type: SET_PRIMARY_KEY_COLUMN_NAME, columnName }),
    onSetPrimaryKeyCustomName: (customName: string) => ({ type: SET_PRIMARY_KEY_CUSTOM_NAME, customName }),
    onColumnResizeEndCallback: (column: string, width: number) => ({ type: SET_PREVIEW_COLUMN_WIDTH, column, width }),
  },
  (props) => {
    const {
      config,
      configVisible,
      description,
      errorMessage,
      errorMessageHeader,
      fileUploadError,
      fields,
      file,
      loading,
      onColumnResizeEndCallback,
      onSetDescription,
      onSetFile,
      onSetFileUploadConfig,
      onSetPrimaryKeyColumnName,
      onSetPrimaryKeyCustomName,
      onSetShouldProfile,
      onSetShouldTruncate,
      onSetViewCurlCommand,
      onToggleConfigVisible,
      previewColumnWidths,
      previewData,
      primaryKeyColumnName,
      primaryKeyCustomName,
      shouldProfile,
      shouldTruncate,
      viewCurlCommand,
      user,
      draftPolicyIds,
    } = props;
    const forceUpdate = useForceUpdate();
    return (
      <div className={classNames('file-upload-dialog-content', { loading })}>
        {loading ? (<LoadingPanel semiTransparent={false} message="Loading Dataset" />) : (
          <div>
            <FileInput
              openOnMount={false}
              onSetFile={(e: React.ChangeEvent<HTMLInputElement>) => onSetFile(e.target.files?.[0] || file)}
              selectedFile={file}
          />
            <div>
              {!file ? null : (
                <div className="form-component-spacer">
                  <div className="section-title">PREVIEW</div>
                  <div className="preview-table-container">
                    <AutoSizer>
                      {({ width, height }) => (
                        <Table
                          tableType="stripes"
                          getLength={() => previewData.size}
                          {...{ height, width }}
                          rowHeight={30}
                          onColumnResizeEndCallback={(newWidth, column) => {
                            /**
                             * Force an rerender to address an issue where the column resizer line doesn't disappear
                             *
                             * r/t https://github.com/facebookarchive/fixed-data-table/issues/401
                             */
                            forceUpdate();
                            onColumnResizeEndCallback(column, newWidth);
                          }}
                        >
                          {fields.map(f => (
                            <Column
                              key={f}
                              columnKey={f}
                              // 100 is the default min value
                              width={previewColumnWidths.get(f, 100)}
                              header={<Cell>{f}</Cell>}
                              cell={({ rowIndex }) =>
                                <Cell>{previewData.get(rowIndex)?.get(f)}</Cell>}
                              isResizable
                            />
                          ))}
                        </Table>
                      )}
                    </AutoSizer>
                  </div>
                </div>
              )}
              {!file ? null : (
                <div className="form-component-spacer">
                  <div className="section-title">CONFIGURE</div>
                  <Button
                    className="file-upload-toggle-link"
                    buttonType="Link"
                    onClick={onToggleConfigVisible}
                >
                    {configVisible ? 'Hide' : 'Show'} advanced CSV options
                  </Button>
                  {!configVisible ? null : (
                    <table className="file-upload-config">
                      <tbody>
                        <tr>
                          <td className="config-label">
                            <div className="file-upload-config-input-label">Column Separator:</div>
                          </td>
                          <td>
                            <Selector
                              className="file-upload-config-input-selector"
                              onChange={(columnSeparator) => onSetFileUploadConfig(config.set('columnSeparator', columnSeparator))}
                              value={config.get('columnSeparator')}
                              values={[
                                { value: ',', display: ',' },
                                { value: '\\t', display: '\\t' },
                                { value: ';', display: ';' },
                                { value: '|', display: '|' },
                                { value: '~', display: '~' },
                                { value: '^', display: '^' },
                              ]}
                            />
                          </td>
                          <td className="config-label">
                            <div className="file-upload-config-input-label">Quote Character:</div>
                          </td>
                          <td>
                            <Selector
                              className="file-upload-config-input-selector"
                              onChange={(quoteCharacter) => onSetFileUploadConfig(config.set('quoteCharacter', quoteCharacter))}
                              value={config.get('quoteCharacter')}
                              values={[
                                { value: '"', display: '"' },
                                { value: "'", display: "'" },
                                { value: '\\', display: '\\' },
                              ]}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="config-label">
                            <div className="file-upload-config-input-label">Escape Character:</div>
                          </td>
                          <td>
                            <Selector
                              className="file-upload-config-input-selector"
                              onChange={(escapeCharacter) => onSetFileUploadConfig(config.set('escapeCharacter', escapeCharacter))}
                              value={config.get('escapeCharacter')}
                              values={[
                                { value: '"', display: '"' },
                                { value: "'", display: "'" },
                                { value: '\\', display: '\\' },
                              ]}
                            />
                          </td>
                          <td className="config-label">
                            <div className="file-upload-config-input-label">Comment Character:</div>
                          </td>
                          <td>
                            <Selector
                              className="file-upload-config-input-selector"
                              onChange={(commentCharacter) => onSetFileUploadConfig(config.set('commentCharacter', commentCharacter))}
                              value={config.get('commentCharacter')}
                              values={[
                                { value: '#', display: '#' },
                                { value: '/', display: '/' },
                              ]}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              )}
              {!file ? null : (
                <Input
                  componentClassName="form-component-spacer"
                  title="Description"
                  onChange={v => onSetDescription(v)}
                  value={description}
              />
              )}
              {!file ? null : (
                <div className="primary-key-section form-component-spacer">
                  <div className="primary-key-section-label">Primary Key column:</div>
                  <TooltipTrigger
                    placement="top"
                    content={<span>{UNIQUE_PRIMARY_KEY_MESSAGE}</span>}
                  >
                    <TamrIcon className="info-icon" iconName="info-outline" size={12} />
                  </TooltipTrigger>
                  <Selector
                    className="primary-key-column-selector"
                    onChange={onSetPrimaryKeyColumnName}
                    placeholder="Select column"
                    values={[
                      {
                        value: undefined as (string | undefined | null),
                        display: (
                          <div className="no-primary-key-option">
                            No Primary Key
                            <TooltipTrigger
                              placement="top"
                              content={<span>{PRIMARY_KEY_MESSAGE}</span>}
                            >
                              <TamrIcon className="warning-icon" iconName="tamr-icon-warning" size={14} />
                            </TooltipTrigger>
                          </div>
                        ),
                      },
                    ].concat(fields.map(value => ({ display: (<span>{value}</span>), value })).toArray())}
                    value={primaryKeyColumnName}
                  />
                </div>
              )}
              {(!file || !!primaryKeyColumnName) ? null : (
                <div>
                  <div className="explanation form-component-spacer">
                    <span className="explanation-title">
                      <TamrIcon className="warning-icon" iconName="tamr-icon-warning" size={14} />
                      <span>Autogenerate Primary Key Column</span>
                    </span>
                    <ul className="explanation-content">
                      <li>{PRIMARY_KEY_MESSAGE}</li>
                    </ul>
                  </div>
                  <Input
                    componentClassName="form-component-spacer"
                    type="text"
                    title="Name of Primary Key Column"
                    onChange={onSetPrimaryKeyCustomName}
                    value={primaryKeyCustomName}
                  />
                </div>
              )}
              {!file || !canUpdateDatasetAtLeastOnePolicy({ user }) ? null : (
                <div className="form-component-spacer">
                  <div className="section-title">SELECT POLICIES</div>
                  <div className="policies-section">
                    <DatasetPoliciesSelector datasetId={FUTURE_DATASET_ID} datasetName={file.name} rowClassName="policies-table-row" headerClassName="policies-table-header" /></div>
                </div>
              )}
              {fileUploadError ? (
                <UploadFileErrorDetail error={fileUploadError} />
              ) : errorMessage ? (
                <div className="error-panel form-component-spacer">
                  <span className="error-header">
                    <TamrIcon className="error-warning-icon" iconName="tamr-icon-warning" size={12} />
                    {errorMessageHeader}
                  </span>
                  <span className="error-detail">
                    {errorMessage}
                  </span>
                </div>
              ) : null}
              {!file ? null : (
                <div className="form-component-spacer">
                  <Checkbox
                    title="Profile Dataset"
                    titlePosition="right"
                    onChange={onSetShouldProfile}
                    value={shouldProfile}
                  />
                  <TooltipTrigger
                    placement="top"
                    content={<span>{PROFILING_MESSAGE}</span>}
                  >
                    <TamrIcon className="info-icon" iconName="info-outline" size={12} />
                  </TooltipTrigger>
                </div>
              )}
              {!file ? null : (
                <div className="form-component-spacer">
                  <Checkbox
                    title="Truncate Existing Dataset"
                    titlePosition="right"
                    onChange={onSetShouldTruncate}
                    value={shouldTruncate}
                  />
                  <TooltipTrigger
                    placement="top"
                    content={<span>{TRUNCATING_MESSAGE}</span>}
                  >
                    <TamrIcon className="info-icon" iconName="info-outline" size={12} />
                  </TooltipTrigger>
                </div>
              )}
              {!file ? null : (
                <div className="form-component-spacer">
                  <ConditionalButton
                    preconditions={Map<string, boolean>().set(
                      'Must select a valid primary key',
                      !!file && !errorMessage && !!primaryKeyColumnName,
                    ).set('Cannot generate cURL with dataset truncation', !shouldTruncate)}
                    tooltipPlacement="top"
                    buttonType="Link"
                    className="file-upload-toggle-link"
                    onClick={() => onSetViewCurlCommand(!viewCurlCommand)}
                  >
                    {(viewCurlCommand) ? 'Hide' : 'Show'} cURL command
                  </ConditionalButton>
                  {!viewCurlCommand ? null : (
                    <TextArea
                      readOnly
                      className="curl-command"
                      value={generateCurlCommand({
                        config,
                        description,
                        fields,
                        file,
                        primaryKeyColumnName,
                        primaryKeyCustomName,
                        policyIds: draftPolicyIds,
                      })}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  },
);

export default FileUploadDialogContent;
