import React from 'react';
import { connect } from 'react-redux';

import AppState from '../stores/AppState';
// @ts-expect-error
import { track } from '../track/TrackingApi';

const mapStateToProps = (state: AppState) => {
  const { location: { page } } = state;
  return { page };
};
const mapDispatchToProps = {
  onTrack: track,
};

type PageTrackerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const PageTracker = connect(
  mapStateToProps,
  mapDispatchToProps,
)(class PageTracker extends React.Component<PageTrackerProps> {
  componentDidUpdate() {
    const { onTrack, page } = this.props;
    onTrack('Page View', { pageKey: page });
  }

  render() {
    return null;
  }
});

export default PageTracker;
