import { List } from 'immutable';

import SERVICES from '../api/ServiceProxy';
import RecordComment from '../models/RecordComment';
import RecordCommentId from '../models/RecordCommentId';
import { handleErrorsWithMessage, jsonHeaders, networkError } from '../utils/Api';

export function putRecordComment(
  comments: List<RecordComment>,
  unifiedDatasetName: string,
): Promise<Response> {
  return fetch(SERVICES.procure(`/transactions/comment/${unifiedDatasetName}`),
    { headers: jsonHeaders,
      method: 'PUT',
      body: JSON.stringify(comments),
    }).then(handleErrorsWithMessage('Unable to edit comment'), networkError);
}

export function deleteRecordComment(
  commentIds: List<RecordCommentId>,
  unifiedDatasetName: string,
): Promise<Response> {
  return fetch(
    SERVICES.procure(`/transactions/comment/${unifiedDatasetName}`),
    { headers: jsonHeaders,
      method: 'DELETE',
      body: JSON.stringify(commentIds),
    },
  ).then(handleErrorsWithMessage('Unable to delete comment'), networkError);
}
