import classNames from 'classnames';
import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ConditionalButton from '../components/ConditionalButton';
import Toggle from '../components/Toggle';
import AppState from '../stores/AppState';
import { getDedupInfo } from '../utils/Selectors';
import {
  SET_SHOW_SETTINGS,
  SET_RECORD_GROUPING_ENABLED,
  SET_IS_PREVIEW_ENABLED,
} from './PregroupActionTypes';
import { resetGrouping, runUpdatePreGroup, saveGrouping } from './PregroupAsync';
import style from './PregroupHeader.module.scss';


const ExcludeDatasetsFromGrouping = require('./ExcludeDatasetsFromGrouping').default;

function mapStateToProps(state: AppState) {
  const { pregroup: { isGroupingEnabled, sampleSpec, sampleStatus, isFetchComplete, outOfDate, isUpdateGroupingRunning, isPreviewEnabled } } = state;
  const hasGroupingKeys = sampleSpec ? (sampleSpec.groupingFields.length > 0) : false;
  const hasError = sampleStatus.type === 'error';
  const hasCleared = getDedupInfo(state)?.preGroupBy == null;

  const savePreconditions = Map({
    'Select at least one column as "Group Values" or use the toggle to disable grouping': !isGroupingEnabled || hasGroupingKeys,
    'Please correct any errors before saving': !isGroupingEnabled || !hasError,
    'Record grouping definition is already up to date': !isGroupingEnabled || outOfDate,
    'Record grouping is already cleared': isGroupingEnabled || !hasCleared,
  });

  const clearPreconditions = Map({
    'No changes to clear': outOfDate,
  });

  const runUpdatePreconditions = Map({
    'Please correct any errors before saving': !hasError,
    'Please save changes before updating group records': !isGroupingEnabled || !outOfDate,
    'Update group records does not need to be run when record grouping is disabled': isGroupingEnabled,
    'An update record grouping job is already running': !isUpdateGroupingRunning,
  });

  return {
    isGroupingEnabled,
    savePreconditions,
    clearPreconditions,
    runUpdatePreconditions,
    outOfDate,
    hasCleared,
    isFetchComplete,
    isPreviewEnabled,
  };
}

const PregroupHeader = connect(mapStateToProps, {
  onOpenSettings: () => ({ type: SET_SHOW_SETTINGS, show: true }),
  onSaveGrouping: () => saveGrouping(),
  onRunUpdatePreGroup: () => runUpdatePreGroup(),
  onToggleRecordGrouping: (isGroupingEnabled: boolean) => ({ type: SET_RECORD_GROUPING_ENABLED, isGroupingEnabled }),
  onTogglePreviewEnabled: (isPreviewEnabled: boolean) => ({ type: SET_IS_PREVIEW_ENABLED, isPreviewEnabled }),
  onClearChanges: () => resetGrouping(),
})(({ onOpenSettings, onSaveGrouping, onClearChanges, onRunUpdatePreGroup, onToggleRecordGrouping, isGroupingEnabled, savePreconditions, clearPreconditions, runUpdatePreconditions, outOfDate, hasCleared, isFetchComplete, isPreviewEnabled, onTogglePreviewEnabled }) => (
  <div className={style.container}>
    <div className={style.title}>
      <div className={style.titleText}>Combine records that have identical values</div>
      <div className={style.subtitleText}>Choose attributes to be grouping keys. Records with identical key values are grouped together.</div>
      <ExcludeDatasetsFromGrouping />
    </div>
    {isFetchComplete ?
      <div className={style.actionsContainer}>
        <div className={style.actions}>
          <span className={style.label}> Enable Record Grouping </span>
          <Toggle
            value={isGroupingEnabled}
            className={style.isActiveToggle}
            onChange={onToggleRecordGrouping}
      />
          <Button
            iconClassName={style.settingsButton}
            icon="settings"
            iconSize={20}
            buttonType="Link"
            onClick={onOpenSettings}
      />
          <ConditionalButton
            preconditions={runUpdatePreconditions}
            tooltipPlacement="left"
            className="PrimaryBtn"
            onClick={onRunUpdatePreGroup}
            style={{ marginRight: '15px' }}
          >
            Update Record Grouping
          </ConditionalButton>
        </div>

        <div className={style.actions}>
          {isGroupingEnabled ? <div className={style.actions}>
            <span className={style.label}>Enable Preview</span>
            <Toggle
              value={isPreviewEnabled}
              className={style.isActiveToggle}
              onChange={onTogglePreviewEnabled}
            />
          </div> : null
          }
          {isGroupingEnabled ? <ConditionalButton
            preconditions={clearPreconditions}
            tooltipPlacement="left"
            className="SecondaryBtn"
            onClick={onClearChanges}
            style={{ marginLeft: '10px', marginRight: '10px', marginTop: '10px' }}
          >
            Cancel Changes
          </ConditionalButton>
            : null
        }
          <ConditionalButton
            preconditions={savePreconditions}
            tooltipPlacement="left"
            className={isGroupingEnabled ? 'PrimaryBtn' : 'SecondaryBtn'}
            onClick={onSaveGrouping}
            style={{ marginTop: '10px' }}
      >
            {isGroupingEnabled ? 'Save Changes' : 'Clear Grouping Definition'}
          </ConditionalButton>
          <div className={classNames(
            style.buttonBadge,
            {
              [style.placeholder]: isGroupingEnabled ? !outOfDate : hasCleared,
              [style.disabled]: savePreconditions.some(v => !v) && isGroupingEnabled,
            },
          )}>
            !
          </div>
        </div>
      </div>
      : null}
  </div>

));

export default PregroupHeader;
