import createLoader from '../utils/createLoader';
import { fetchHintNames } from './TransformsApi';

const HintNamesLoader = createLoader((state) => {
  const { transforms: { loadingHintNames, loadedHintNames } } = state;
  return {
    canFetch: true,
    shouldFetch: !loadedHintNames,
    loading: loadingHintNames,
  };
}, {
  onFetch: fetchHintNames,
}, 'HintNamesLoader');

export default HintNamesLoader;
