import { getTileServers } from '../api/ProcurifyClient';
import { AppThunkDispatch } from '../stores/AppAction';
import {
  FETCH_TILE_SERVERS,
  FETCH_TILE_SERVERS_COMPLETED,
  FETCH_TILE_SERVERS_FAILED,
} from './ProjectsActionTypes';

/**
 * Fetch all tile servers into the state
 */
export const fetchTileServers = () => (dispatch: AppThunkDispatch) => {
  dispatch({ type: FETCH_TILE_SERVERS });
  getTileServers()
    .then(tileServers => dispatch({ type: FETCH_TILE_SERVERS_COMPLETED, tileServers }))
    .catch(response => dispatch({ type: FETCH_TILE_SERVERS_FAILED, response: response?.message }));
};
