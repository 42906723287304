import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../../models/Model';
import { ArgTypes } from '../../utils/ArgValidation';
import { $TSFixMe } from '../../utils/typescript';

// com.tamr.transform.models.functions.Deprecation
class Deprecation extends getModelHelpers({
  deprecatedSinceVersion: { type: ArgTypes.string },
  description: { type: ArgTypes.nullable(ArgTypes.string) },
}, 'Deprecation')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class DeprecationRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(json: $TSFixMe) {
    if (!json) return null;
    const { deprecatedSinceVersion, description } = json;
    return new Deprecation({
      deprecatedSinceVersion,
      description,
    });
  }
}

export default Deprecation;
