import { List, Map } from 'immutable';

import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

// com.tamr.dedup.models.PreGroupBy
class PreGroupBy extends getModelHelpers({
  groupingFields: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
  fieldAggregationMap: { type: ArgTypes.Immutable.map.of(ArgTypes.string, ArgTypes.string) },
  rootEntityIdsSampleSize: { type: ArgTypes.orUndefined(ArgTypes.number) },
}, 'PreGroupBy')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class PreGroupByRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(PreGroupBy); }

  static fromJSON(obj: $TSFixMe) {
    return new PreGroupBy({
      groupingFields: List(obj.groupingFields),
      fieldAggregationMap: Map(obj.fieldAggregationMap),
      rootEntityIdsSampleSize: obj.rootEntityIdsSampleSize,
    });
  }
}

export default PreGroupBy;
