import $ from 'jquery';

import { updateTaxonomy } from '../api/RecipeClient';
import { getRecipeDocById } from '../projects/ProjectsStore';
import { getAuthorizedUser, getUnifiedDatasetId } from '../utils/Selectors';
import { UPLOAD_TAXONOMY_COMPLETED } from './TaxonomyUploadActionTypes';

export const uploadTaxonomy = ({ selectedFile }) => (dispatch, getState) => {
  const state = getState();
  const unifiedDatasetId = getUnifiedDatasetId(state);
  if (!unifiedDatasetId) {
    console.error('Cannot import taxonomy - unifiedDatasetName is undefined');
    return;
  }
  const { location: { recipeId }, projects: { projectsWithStatus } } = state;
  const recipeDoc = getRecipeDocById(projectsWithStatus, recipeId);
  const authorizedUser = getAuthorizedUser(state);
  const username = authorizedUser.username;

  dispatch({ type: 'TaxonomyUpload.uploadTaxonomy' });
  const formData = new FormData();
  formData.append('file', selectedFile, selectedFile.name);
  $.ajax({
    url: SERVICES.taxonomy(`/categories/import/${unifiedDatasetId}`),
    method: 'POST',
    data: formData,
    processData: false,
    contentType: false,
  }).then(() => {
    updateTaxonomy({ recipeDoc, taxonomyName: selectedFile.name, username });
  }).then(() => {
    dispatch({ type: UPLOAD_TAXONOMY_COMPLETED });
  }, error => {
    dispatch({ type: 'TaxonomyUpload.uploadTaxonomyFailed', errorMessage: error.responseJSON.message || 'Error loading taxonomy' });
  });
};
