import enumify from './enumify';

export enum ConfidenceRangeE {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export const HIGH = ConfidenceRangeE.HIGH;
export const MEDIUM = ConfidenceRangeE.MEDIUM;
export const LOW = ConfidenceRangeE.LOW;

export default enumify({ HIGH, MEDIUM, LOW });
