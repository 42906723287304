import { is } from 'immutable';
import { connect } from 'react-redux';
import { createSink } from 'recompose';
import _ from 'underscore';

import { fetchFeedbackSummary } from '../transactions/TransactionApi';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { fetchStats } from './CategorizationDashboardApi';
import { getFilterInfo } from './CategorizationDashboardStore';

// TODO switch over to using createLoader()
const CategorizationDashboardStatsLoader = _.compose(
  connect(state => {
    const { categorizationDashboard: { loading, loadedFilterInfo } } = state;
    const projectInfo = selectActiveProjectInfo(state);
    const shouldFetch = !is(getFilterInfo(state), loadedFilterInfo);
    const canFetch = !!(projectInfo && projectInfo.unifiedDatasetId);

    return { loading, shouldFetch, canFetch };
  }, {
    onFetchStats: fetchStats,
    onFetchFeedbackSummary: fetchFeedbackSummary,
  }),
)(createSink(({ onFetchStats, onFetchFeedbackSummary, shouldFetch, canFetch, loading }) => {
  if (shouldFetch && canFetch && !loading) {
    onFetchStats();
    onFetchFeedbackSummary();
  }
}));

export default CategorizationDashboardStatsLoader;
