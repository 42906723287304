import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../../components/Button';
import EsRecord from '../../models/EsRecord';
import { AppState } from '../../stores/MainStore';


type TransactionActionButtonsOwnProps = {
  record: EsRecord
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TransactionActionButtons = connect((state: AppState, ownProps: TransactionActionButtonsOwnProps) => {
  return {};
}, {
  onOpenCategorizeDialog: (record: EsRecord) => ({ type: 'Transactions.openCategorizeDialog', categorizeDialogRecords: List.of(record) }),
})(({ onOpenCategorizeDialog, record }) => {
  return (
    <div className="transaction-action-buttons">
      <Button onClick={() => onOpenCategorizeDialog(record)} buttonType="Secondary">
        New categorization
      </Button>
    </div>
  );
});

export default TransactionActionButtons;
