import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import TamrIcon from '../components/TamrIcon';
import {
  CLASSIFICATION,
  ENRICHMENT,
  GOLDEN_RECORDS,
  SCHEMA_MAPPING_RECOMMENDATIONS,
  SUPPLIER_MASTERING,
} from '../constants/ProjectTypes';
import { FEATURE_FLAG_ENRICHMENT } from '../utils/FeatureFlags';
import style from './ChooseNewProjectTypeDialog.module.scss';
import { BEGIN_ADDING_PROJECT, HIDE_CHOOSE_NEW_PROJECT_TYPE } from './ProjectsActionTypes';
import ProjectTypeIcon from './ProjectTypeIcon';

const TypeCard = ({ projectType, label, description, onChoose }) => (
  <div role="button" tabIndex={0} className={style.typeCard} onClick={() => onChoose(projectType)}>
    <ProjectTypeIcon projectType={projectType} className={style.typeIcon} size={75} />
    <div className={style.label}>{label}</div>
    <div className={style.description}>{description}</div>
  </div>
);

const ChooseNewProjectTypeDialog = _.compose(
  connect(state => {
    const { projects: { showChooseNewProjectType } } = state;
    return { show: showChooseNewProjectType };
  }, {
    onHide: () => ({ type: HIDE_CHOOSE_NEW_PROJECT_TYPE }),
    onChoose: (projectType) => ({ type: BEGIN_ADDING_PROJECT, projectType }),
  }),
)(({ show, onHide, onChoose }) => {
  const body = <div className={style.typeCards}>
    <TypeCard
      projectType={SCHEMA_MAPPING_RECOMMENDATIONS}
      label="Schema Mapping"
      description="Use transformations to map attributes across your datasets to a single, unified dataset."
      {...{ onChoose }}
  />
    <TypeCard
      projectType={CLASSIFICATION}
      label="Categorization"
      description="Use your taxonomy to categorize all the records across your datasets."
      {...{ onChoose }}
  />
    <TypeCard
      projectType={SUPPLIER_MASTERING}
      label="Mastering"
      description="Find all duplicate records across your datasets and consolidate them into clusters."
      {...{ onChoose }}
  />
    <TypeCard
      projectType={GOLDEN_RECORDS}
      label="Golden Records"
      description="Create a single golden record for each cluster of a given entity type."
      {...{ onChoose }}
  />
    {FEATURE_FLAG_ENRICHMENT && <TypeCard
      projectType={ENRICHMENT}
      label="Enrichment"
      description="Enrich attributes and entities in your datasets to use in other projects."
      {...{ onChoose }}
    />}
  </div>;
  return (
    <Dialog
      dialogStyle={DialogStyle.PRIMARY}
      className={style.dialog}
      show={show}
      onHide={onHide}
      body={show ? (
        <div className={style.body}>
          <TamrIcon iconName="tamr-icon-close" size={25} className={style.closeIcon} onClick={onHide} />
          <div className={style.title}>
            Choose your project type
          </div>
          {body}
        </div>
      ) : null}
    />
  );
});

export default ChooseNewProjectTypeDialog;
