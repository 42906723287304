import classNames from 'classnames';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import CenterContent from '../components/CenterContent';
import ConditionalButton from '../components/ConditionalButton';
import NullValuesBar from '../components/NullValuesBar';
import Term from '../components/Term';
import TooltipTrigger from '../components/TooltipTrigger';
import WarningDialog from '../components/WarningDialog';
import { profileDataset } from '../datasets/DatasetsApi';
import { longFormat } from '../utils/Numbers';
import { getCurrentlyProfilingDatasetNames } from '../utils/Selectors';
import { pluralize } from '../utils/Strings';

const ELEMENTS_PER_COLUMN = 4;
const DEFAULT_NUMBER_OF_COLUMNS = 3;
const SchemaMappingProfilingInfo = connect((state, { datasetName }) => {
  return { currentlyProfiling: getCurrentlyProfilingDatasetNames(state).has(datasetName) };
}, {
  onProfile: profileDataset,
})(class SchemaMappingProfilingInfo extends React.Component {
  static propTypes = {
    canUserEdit: PropTypes.bool.isRequired,
    currentlyProfiling: PropTypes.bool.isRequired,
    datasetName: PropTypes.string.isRequired,
    isCommitted: PropTypes.bool,
    isUnifiedAttribute: PropTypes.bool,
    maxValues: PropTypes.number,
    meanValues: PropTypes.number,
    mostFrequentValues: PropTypes.arrayOf(PropTypes.shape({
      frequency: PropTypes.number,
      value: PropTypes.string,
    })),
    // TODO<Nathan>: update this profiling info to be multi-value aware
    numEmptyRecords: PropTypes.number,
    numMultivalue: PropTypes.number,
    numNotNullRecords: PropTypes.number,
    numNullRecords: PropTypes.number,
    numSingle: PropTypes.number,
    profiled: PropTypes.bool.isRequired,
  };

  state = {
    showingUpdateWarningDialog: false,
  };

  getTotalRecords = () => {
    const { numEmptyRecords, numNotNullRecords } = this.props;
    return numEmptyRecords + numNotNullRecords;
  };

  updateHoverState = (hoverParam, value) => {
    this.setState({ [hoverParam]: value });
  };

  renderNullBar = () => {
    const { maxValues, meanValues, numEmptyRecords, numNotNullRecords, numNullRecords, numMultivalue, numSingle, profiled } = this.props;
    const totalRecords = this.getTotalRecords();
    const profilingInfo = {
      profiled,
      numEmptyRecords,
      numNotNullRecords,
      numNullRecords, // old profiling
      numSingle,
      numMultivalue, // newer multivalue profiling
      maxValues,
      meanValues,
      totalRecords,
    };

    if (profiled && totalRecords > 0) {
      return (
        <div className="null-record-status-bar">
          <NullValuesBar profileInfo={profilingInfo} className="popover-null-bar" height={15} withHovers />
        </div>
      );
    }
    return <div className="null-record-status-bar" />;
  };

  /**
   * Order the mostFrequentValue into an array of arrays where the outer array represents columns in the UI
   * and the inner arrays represents the individual values
   */
  getFrequencyColumns = () => {
    const { mostFrequentValues } = this.props;
    const columns = [];
    _.each(mostFrequentValues, (value, i) => {
      const index = Math.floor(i / ELEMENTS_PER_COLUMN);
      if (i % ELEMENTS_PER_COLUMN === 0) {
        columns[index] = [];
      }
      columns[index].push(value);
    });
    return columns;
  };

  getPercent = (rawPercent) => {
    if (rawPercent < 1) {
      return '< 1%';
    }
    // DEV-5268, never return 101% Rounding up for other value below 100 is legitimate.
    return `${Math.min(Math.round(rawPercent), 100)}%`;
  };

  renderTitlePanel = () => {
    const { profiled } = this.props;
    const totalRecords = this.getTotalRecords();
    if (profiled && !(totalRecords > 0)) { // the specific case where the dataset has been profiled, but has no records
      return (
        <div
          className="no-records-panel"
        >
          <span>No <Term>records</Term> in this attribute</span>
        </div>
      );
    } if (profiled && totalRecords > 0) {
      return (
        <div
          className="title-panel"
        >
          <span>MOST FREQUENT VALUES</span>
          <span className="record-count">
            <span className="primary-text">
              <span>{longFormat(this.getTotalRecords())} <Term amount={this.getTotalRecords()}>RECORD</Term></span>
            </span>
          </span>
        </div>
      );
    }
    return (
      <div
        className="title-panel"
      >
        <span>SOURCE NOT YET PROFILED</span>
      </div>
    );
  };

  renderDataPanel = () => {
    const columns = this.getFrequencyColumns();
    return _.map(columns, (column, i) => (
      <div
        key={`data-pane-column-${i}`}
        className={classNames('data-panel-column', {
          'last-column': i === (columns.length - 1),
        })}
      >
        {
          _.map(column, (value, j) => {
            const valueMsg = value.rawValue === undefined
              ? '(null)'
              : value.value.trim() === '' ? '(empty)' : value.value;
            const valueFrequency = this.getTotalRecords() > 0 ? value.frequency : 0; // Make sure total records are > 0
            const valuePercentFrequency = Math.max(value.percentFrequency, 0);

            // Since profiling info is based on a sample, we have to check for oddball cases where
            // the percent frequency is 0 but values exist.
            let toolTipTotalValueMessage;
            if (value.percentFrequency === 0 && valueFrequency !== 0) {
              toolTipTotalValueMessage = ' ' + pluralize(valueFrequency, 'value', 'values');
            } else {
              // Given the frequency and percent frequency, calculate the total number of values
              // ex. 5 / 10 * 100 = 50% --> 5 / X * 100 = 50% --> 5 / 50% * 100 = X
              const totalValues = longFormat(valueFrequency / value.percentFrequency * 100);
              toolTipTotalValueMessage = ' of ' + totalValues + ' ' + pluralize(totalValues, 'value', 'values');
            }

            return (
              <div
                key={`row-${j}`}
                className="data-panel-cell"
              >
                <span
                  className={classNames('value', {
                    'is-empty': _.isEmpty(value.value) || value.rawValue === undefined,
                  })}
                  title={valueMsg}
                >
                  {valueMsg}
                </span>
                <TooltipTrigger
                  placement="bottom"
                  className={classNames('count-tooltip')}
                  content={
                    <span>
                      {longFormat(valueFrequency)}<sup>*</sup>{toolTipTotalValueMessage}
                      <br />
                      (<sup>*</sup>Estimate)
                    </span>
                  }
                >
                  <span className="count">
                    {this.getPercent(valuePercentFrequency)}
                  </span>
                </TooltipTrigger>
                <div className="percent-bar">
                  <span
                    className="bar"
                    style={{ width: `${valuePercentFrequency}%` }}
                  />
                </div>
              </div>
            );
          })
        }
      </div>
    ),
    );
  };

  showUpdateWarningDialog = () => {
    this.setState({ showingUpdateWarningDialog: true });
  };

  hideUpdateWarningDialog = () => {
    this.setState({ showingUpdateWarningDialog: false });
  };

  renderProfileButton = () => {
    const { datasetName, isUnifiedAttribute, isCommitted, currentlyProfiling } = this.props;
    const buttonPreconditions = Map()
      .set('You must Update your Unified Dataset before profiling it.', !isUnifiedAttribute || isCommitted)
      .set('This dataset is currently being profiled.', !currentlyProfiling);
    return (
      <div
        key="profile-button-container"
        className="profile-button-container"
      >
        <CenterContent>
          <ConditionalButton
            buttonType="Secondary"
            className="profile-button"
            onClick={this.showUpdateWarningDialog}
            preconditions={buttonPreconditions}
          >
            {currentlyProfiling ? 'Profiling...' : 'Profile'}
          </ConditionalButton>
        </CenterContent>
        <WarningDialog
          actionName="Profile Dataset"
          message={'Profiling a dataset can take a while. Are you sure you want to continue?'}
          onAccept={_.partial(this.props.onProfile, datasetName)}
          onHide={this.hideUpdateWarningDialog}
          show={this.state.showingUpdateWarningDialog}
        />
      </div>
    );
  };

  renderEmptyDataPanel = () => { // this draws the shaded placeholders for the profiled data
    let percent = 100;
    const elements = _.map(_.range(DEFAULT_NUMBER_OF_COLUMNS), (column, i) => (
      <div
        key={`data-pane-column-${i}`}
        className={classNames('data-panel-column', {
          'last-column': i === (DEFAULT_NUMBER_OF_COLUMNS - 1),
        })}
      >
        {
          _.map(_.range(ELEMENTS_PER_COLUMN), (value, j) => {
            percent -= _.random(percent);
            return (
              <div
                key={`row-${j}`}
                className="data-panel-cell"
              >
                <span className="value">
                  <span className="empty-value" />
                </span>
                <span className="count">
                  <span className="empty-value" />
                </span>
                <div className="percent-bar empty">
                  <span
                    className="bar"
                    style={{ width: `${percent}%` }}
                  />
                </div>
              </div>
            );
          })
        }
      </div>
    ),
    );
    if (this.props.canUserEdit) {
      elements.push(this.renderProfileButton());
    }
    return elements;
  };

  renderFrequencyPanel = () => {
    const { profiled } = this.props;
    return (
      <div className="frequency-panel">
        {this.renderTitlePanel()}
        <div
          className="data-panel"
        >
          {profiled ? this.renderDataPanel() : this.renderEmptyDataPanel()}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="schema-mapping-profiling-info">
        {this.renderNullBar()}
        {this.renderFrequencyPanel()}
      </div>
    );
  }
});

export default SchemaMappingProfilingInfo;
