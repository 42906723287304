import _ from 'underscore';

import Model from '../models/Model';
import { ArgTypes } from '../utils/ArgValidation';
import { PAGE_NAMES_ARG_TYPE, routes } from '../utils/Routing';
import { getIntOrUndef } from '../utils/Values';

export const initialState = new (Model({
  page: { type: PAGE_NAMES_ARG_TYPE, defaultValue: 'index' },
  path: { type: ArgTypes.string, defaultValue: '' },
  recipeId: { type: ArgTypes.nullable(ArgTypes.number) },
  moduleId: { type: ArgTypes.nullable(ArgTypes.number) },
}))();

export const reducers = {
  'Location.change': (state, { location }) => {
    const route = _.values(routes).find(r => r.match(location.pathname));
    const moduleId = route ? getIntOrUndef(route.match(location.pathname).moduleId) : undefined;
    return state.merge({
      page: route ? route.name : 'notfound',
      path: location.pathname,
      recipeId: route ? getIntOrUndef(route.match(location.pathname).recipeId) : undefined,
      moduleId,
    });
  },
};
