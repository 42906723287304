import { Set } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// @ts-expect-error
import FeedbackAssignDialog from '../feedback/FeedbackAssignDialog';
import { AppAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { PaneE } from './Pane';
import { assignClusters } from './SuppliersAsync';
import { getSelectedClusters } from './SuppliersStore';


const AssignClustersDialog = connect((state: AppState, { pane }: { pane: PaneE }) => {
  const { suppliers, suppliers: { [pane]: { assignDialogOpen, assignDialogSaving } } } = state;
  return { clusters: getSelectedClusters(suppliers, pane), assignDialogOpen, assignDialogSaving };
}, (dispatch, { pane }) => bindActionCreators({
  onCloseAssignDialog: (): AppAction => ({ type: 'Suppliers.closeAssignDialog', pane }),
  onAssignClusters: (usersToAssign: Set<string>, usersToUnassign: Set<string>) => assignClusters(pane, usersToAssign, usersToUnassign),
}, dispatch),
)(({ clusters, assignDialogOpen, assignDialogSaving, onCloseAssignDialog, onAssignClusters }) => {
  const userAssignmentCounts = clusters
    .flatMap(({ clusterAssignments }) => clusterAssignments.map(({ username }) => username))
    .countBy(x => x);
  const userAssignmentCount = (u: string) => (userAssignmentCounts.get(u, 0));
  return (
    <FeedbackAssignDialog
      noun="supplier"
      showDialog={assignDialogOpen}
      onHide={onCloseAssignDialog}
      userAssignmentCount={userAssignmentCount}
      recordCount={clusters.size}
      onAssign={onAssignClusters}
      busy={assignDialogSaving}
    />
  );
});

export default AssignClustersDialog;
