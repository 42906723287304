import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../../models/Model';
import { TypeArgType, typeFromJson } from '../../transforms/models/Types';
import { ArgTypes } from '../../utils/ArgValidation';
import { $TSFixMe } from '../../utils/typescript';

// com.tamr.transform.models.functions.Argument
class Argument extends getModelHelpers({
  name: { type: ArgTypes.string },
  type: { type: TypeArgType },
  description: { type: ArgTypes.nullable(ArgTypes.string) },
}, 'Argument')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ArgumentRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(json: $TSFixMe) {
    const { name, description, type } = json;
    return new Argument({
      name,
      description,
      type: typeFromJson(type),
    });
  }
}

export default Argument;
