import createLoader from '../utils/createLoader';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { fetchDnfPairEstimates } from './DnfBuilderApi';

const DnfPairEstimatesLoader = createLoader(state => {
  const { dnfBuilder: { pairEstimatesLoading, shouldFetchPairEstimates } } = state;
  const activeProjectInfo = selectActiveProjectInfo(state);
  return {
    canFetch: !!activeProjectInfo,
    shouldFetch: shouldFetchPairEstimates,
    loading: pairEstimatesLoading,
  };
}, {
  onFetch: fetchDnfPairEstimates,
}, 'DnfPairEstimatesLoader');

export default DnfPairEstimatesLoader;
