import { Map } from 'immutable';

import MinimalAuthUser from '../models/MinimalAuthUser';
import { $TSFixMe } from '../utils/typescript';

export const LOGIN = 'Auth.login';
export const LOGIN_COMPLETED = 'Auth.loginCompleted';
export const LOGIN_FAILED = 'Auth.loginFailed';
export const LOGOUT_COMPLETED = 'Auth.logoutCompleted';
export const SET_USER_PREFERENCES = 'Auth.setUserPreferences';

interface AuthLogin {
  type: typeof LOGIN
}

interface AuthLoginCompleted {
  type: typeof LOGIN_COMPLETED
  authorizedUser: MinimalAuthUser | undefined
}

interface AuthLoginFailed {
  type: typeof LOGIN_FAILED
  response: $TSFixMe
}

interface AuthLogoutCompleted {
  type: typeof LOGOUT_COMPLETED
}

interface AuthSetUserPreferences {
  type: typeof SET_USER_PREFERENCES
  preferences: Map<$TSFixMe, $TSFixMe>
}

export type AuthActionTypes
  = AuthLogin
  | AuthLoginCompleted
  | AuthLoginFailed
  | AuthLogoutCompleted
  | AuthSetUserPreferences
