import Cluster from '../models/Cluster';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import { ArgTypes } from '../utils/ArgValidation';

class ClusterSimilarity extends getModelHelpers({
  id: { type: ArgTypes.string },
  similarity: { type: ArgTypes.number.inRange(0, 1) },
  cluster: { type: Cluster.argType },
}, 'ClusterSimilarity')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ClusterSimilarityRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

export default ClusterSimilarity;
