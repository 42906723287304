import Cluster from '../models/Cluster';
import EsRecord from '../models/EsRecord';
import TaggedUnion, { InferConstructedKind } from '../models/TaggedUnion';
import { ArgTypes } from '../utils/ArgValidation';
import Pane from './Pane';

const kindDefinitions = {
  AcceptActiveRecordSuggestion: {
    record: EsRecord.argType,
    clusterId: ArgTypes.string,
  },
  MoveRecordsToExistingCluster: {
    fromPane: ArgTypes.valueIn(Pane),
    clusterId: ArgTypes.string,
  },
  MoveRecordsToNewCluster: {},
  MergeClusters: {
    clusters: ArgTypes.Immutable.list.of(Cluster.argType),
  },
  MergeClustersToTarget: {
    clusters: ArgTypes.Immutable.list.of(Cluster.argType), // not including target cluster
    targetCluster: Cluster.argType,
    fromPane: ArgTypes.valueIn(Pane),
  },
  NotConfirming: {},
};

export type VerificationActionConfirmationInfoType = InferConstructedKind<typeof kindDefinitions>;
const VerificationActionConfirmationInfo = TaggedUnion(kindDefinitions, 'VerificationActionConfirmationInfo');

export default VerificationActionConfirmationInfo;
