/**
 * Contains all the logic for creating our main redux store as a callable library.
 * Useful for tests to call these on beforeEach hooks.
 * MainStore will simply call these functions on being imported.
 */

import { configureStore } from '@reduxjs/toolkit';
import { Set } from 'immutable';

import { SET_ACCOUNT_CONFIG } from '../constants/UsersActionTypes';
// @ts-expect-error
import Log from '../utils/Log';
import { $TSFixMe } from '../utils/typescript';
import { reducer } from './reducer';


const sensitiveActions = Set([
  SET_ACCOUNT_CONFIG,
]);

const loggerMiddleware = ({ getState }: $TSFixMe) => (next: $TSFixMe) => (action: $TSFixMe) => {
  const { type, ...parameters } = action;

  const isFetchResultAction = !!type.match(/\.fetch(.)*Completed$/);
  const isSensitiveAction = sensitiveActions.includes(type);
  const hideParameters
    = isFetchResultAction // parameters for fetch results can potentially be huge, so we don't want to log them
    || isSensitiveAction; // these actions may contain sensitive information in their parameters, like passwords
  const logArguments = [type];
  if (!hideParameters) {
    logArguments.push(parameters);
  }
  Log.debug(...logArguments);
  const result = next(action);

  // @ts-expect-error
  window.state = getState();

  return result;
};

export function createAppStore() {
  return configureStore({
    reducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(loggerMiddleware),
  });
}

export type AppStore = ReturnType<typeof createAppStore>;
