import { Set } from 'immutable';
import PropTypes from 'prop-types';

import { ArgTypes, Checker } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes, toJSON } from './Model';
// @ts-expect-error
import Transforms, { convertStorageJSONToObject } from './Transforms';


export default class BulkTransform extends getModelHelpers({
  operation: { type: Transforms.argType as Checker<$TSFixMe> },
  datasetIds: { type: ArgTypes.Immutable.set.of(ArgTypes.number) },
}, 'BulkTransform')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class BulkTransformRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static get propType() { return PropTypes.instanceOf(BulkTransform); }
  toServerJSON() {
    return {
      operation: this.operation.toTransformText(),
      datasetIds: this.datasetIds.toJS(),
    };
  }

  toJSON() {
    return toJSON(super.toJSON());
  }

  static fromJS({ operation, datasetIds }: $TSFixMe) {
    return new BulkTransform({
      operation: Transforms.parse(operation),
      datasetIds: Set(datasetIds),
    });
  }

  static fromStorageJSON({ operation, datasetIds }: $TSFixMe) {
    return new BulkTransform({
      operation: convertStorageJSONToObject(operation),
      datasetIds: Set(datasetIds),
    });
  }
}
