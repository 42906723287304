import enumify from '../../constants/enumify';

// com.tamr.transform.models.functions.FunctionKind
export enum FunctionKindE {
    NORMAL = 'NORMAL',
    AGGREGATE = 'AGGREGATE',
    WINDOW = 'WINDOW'
}

export const { NORMAL, AGGREGATE, WINDOW } = FunctionKindE;

export default enumify(FunctionKindE);
