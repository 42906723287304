// com.tamr.transform.models.UnifiedAttribute

import { List, Set } from 'immutable';

import SortType, { SortTypeE } from '../constants/SortType';
import RequiredAttributeType from '../schema-mapping/constants/RequiredAttributeType';
import SimilarityFunction from '../schema-mapping/constants/SimilarityFunction';
import TokenizerConfig from '../schema-mapping/constants/TokenizerConfig';
import TokenWeighting from '../schema-mapping/constants/TokenWeighting';
import { ArrayType, stringType, TypeArgType, typeFromJson } from '../transforms/models/Types';
import { ArgTypes } from '../utils/ArgValidation';
import AttributeId from './AttributeId';
import MappingRecommendation from './MappingRecommendation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

const DEFAULT_TYPE = ArrayType.of(stringType());

class UnifiedAttribute extends getModelHelpers({
  name: { type: ArgTypes.string },
  datasetName: { type: ArgTypes.string },
  type: { type: TypeArgType },
  description: { type: ArgTypes.nullable(ArgTypes.string) },
  visible: { type: ArgTypes.bool, defaultValue: true },
  generated: { type: ArgTypes.bool, defaultValue: false },
  sortType: { type: SortType.argType, defaultValue: SortTypeE.TEXT },
  numMappings: { type: ArgTypes.number, defaultValue: 0 },
  numRecommendations: { type: ArgTypes.number, defaultValue: 0 },
  mappedAttributes: { type: ArgTypes.nullable(ArgTypes.Immutable.set.of(ArgTypes.instanceOf(AttributeId))), defaultValue: Set<AttributeId>() },
  recommendations: { type: ArgTypes.nullable(ArgTypes.Immutable.list.of(ArgTypes.instanceOf(MappingRecommendation))), defaultValue: List<MappingRecommendation>() },
  similarityFunction: { type: SimilarityFunction.argType, defaultValue: SimilarityFunction.COSINE },
  tokenizerConfig: { type: ArgTypes.orNull(TokenizerConfig.argType), defaultValue: TokenizerConfig.DEFAULT },
  tokenWeighting: { type: ArgTypes.orNull(TokenWeighting.argType), defaultValue: TokenWeighting.IDF },
  numericFieldResolution: { type: ArgTypes.nullable(ArgTypes.array.of(ArgTypes.number)) },
  requiredAttributeType: { type: RequiredAttributeType.argType, defaultValue: RequiredAttributeType.NONE },
  mlEnabled: { type: ArgTypes.bool, defaultValue: true },
}, 'UnifiedAttribute')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class UnifiedAttributeRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  constructor(obj: any) {
    super({
      ...obj,
      type: obj.type ? obj.type : DEFAULT_TYPE,
    });
  }

  static get argType() { return ArgTypes.instanceOf(this); }

  static fromJSON(obj: any) {
    return new UnifiedAttribute({
      ...obj,
      type: obj.type ? typeFromJson(obj.type) : null,
      mappedAttributes: Set(obj.mappedAttributes).map(aid => AttributeId.fromJSON(aid)),
      recommendations: List(obj.recommendations).map(mr => MappingRecommendation.fromJSON(mr)),
    });
  }
}

export default UnifiedAttribute;
