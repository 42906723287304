import React from 'react';
import { connect } from 'react-redux';

import { updateDatasetPolicyResourceship } from '../accessControl/AccessControlAsync';
import { getPolicyById } from '../accessControl/AccessControlStore';
import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Link from '../components/Link';
import style from './DatasetPoliciesDialog.module.scss';
import DatasetPoliciesSelector, { FUTURE_DATASET_ID } from './DatasetPoliciesSelector';
import { isAdmin } from '../utils/Authorization';
import { getAuthorizedUser } from '../utils/Selectors';


export default connect(state => {
  const { accessControl, accessControl: { policyDocs, datasetDraftPolicyResourceship } } = state;
  return {
    policyDocs,
    datasetDraftPolicyResourceship,
    policyById: getPolicyById(accessControl),
    isUserAdmin: isAdmin(getAuthorizedUser(state)),
  };
}, {
  onAddToPolicy: (policyId) => ({ type: 'Datasets.addToPolicyResourceship', policyId }),
  onRemoveFromPolicy: (policyId) => ({ type: 'Datasets.removeFromPolicyResourceship', policyId }),
  onStopEditing: () => ({ type: 'Datasets.stopEditingPolicyResourceship' }),
  onUpdatePolicyResourceship: updateDatasetPolicyResourceship,
})(({ onStopEditing, onUpdatePolicyResourceship, datasetDraftPolicyResourceship, isUserAdmin }) => (
  !datasetDraftPolicyResourceship || (datasetDraftPolicyResourceship.datasetId === FUTURE_DATASET_ID) ? null :
    (
      <Dialog
        className={style.datasetPoliciesDialog}
        dialogStyle={DialogStyle.PRIMARY}
        show
        onHide={onStopEditing}
        title={
          <div className={style.manageDatasetTitle}>
            <span className={style.leftText}>Policy management for "</span>
            <span className={style.nameText} title={datasetDraftPolicyResourceship.datasetName}>{datasetDraftPolicyResourceship.datasetName}</span>
            <span className={style.rightText}>"</span>
          </div>
      }
        body={<DatasetPoliciesSelector />}
        footer={
          <div className="policy-dialog-footer">
            {isUserAdmin ? <Link to={'/access/policies'}>Manage policies from Permissions page</Link> : null}
            <ButtonToolbar>
              <Button
                buttonType="Secondary"
                onClick={onStopEditing}
            >
                Cancel
              </Button>
              <Button
                onClick={onUpdatePolicyResourceship}
            >
                Save
              </Button>
            </ButtonToolbar>
          </div>
      }
    />
    )
));
