import { is } from 'immutable';

import createLoader from '../../utils/createLoader';
import { selectActiveProjectInfo } from '../../utils/Selectors';
import { fetchSuggestedLabelsByConfidence } from './DedupDashboardApi';

const SuggestedLabelCountsLoader = createLoader((state) => {
  const {
    config: { pairConfidenceThresholds },
    dedupDashboard: { loadingSuggestedLabelCounts: loading, loadedSuggestedLabelsForThresholds },
  } = state;
  const projectInfo = selectActiveProjectInfo(state);
  return {
    canFetch: !!(projectInfo && projectInfo.isUnifiedDatasetIndexed && pairConfidenceThresholds),
    shouldFetch: !is(pairConfidenceThresholds, loadedSuggestedLabelsForThresholds),
    loading,
  };
}, {
  onFetch: fetchSuggestedLabelsByConfidence,
}, 'SuggestedLabelCountsLoader');

export default SuggestedLabelCountsLoader;
