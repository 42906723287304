import Ajv from 'ajv';
import * as d3 from 'd3-geo';
import { List } from 'immutable';

import TaggedUnion, { InferConstructedKind } from '../models/TaggedUnion';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import GeoJSONSchema from './GeoJSONSchema.json';
import GeoTamrSchema from './GeoTamrSchema.json';


// ---------
// utilities
// ---------

// JSON schema (https://json-schema.org/) validation
const ajv = new Ajv();
export const validate = ajv.compile(GeoTamrSchema);
const validateGeoJSON = ajv.compile(GeoJSONSchema);

export function displayName(gt: GeoTamrType): string {
  return gt.case<string>({
    Point: () => 'Point',
    MultiPoint: () => 'Point',
    LineString: () => 'Line-string',
    MultiLineString: () => 'Line-string',
    Polygon: () => 'Polygon',
    MultiPolygon: () => 'Polygon',
  });
}

export function size(gt: GeoTamrType): number {
  const single = () => 1;
  return gt.case<number>({
    MultiPoint: ({ longLats }) => longLats.size,
    MultiLineString: ({ listsOfNodes }) => listsOfNodes.size,
    MultiPolygon: ({ listsOfLinearRings }) => listsOfLinearRings.size,
    Point: single,
    LineString: single,
    Polygon: single,
  });
}

type LongLatLists = List<List<LongLat>>
export function longLatLists(gt: GeoTamrType): LongLatLists {
  return gt.case<LongLatLists>({
    Point: ({ longLat }) => List.of(List.of(longLat)),
    MultiPoint: ({ longLats }) => List.of(longLats),
    LineString: ({ nodes }) => List.of(nodes),
    MultiLineString: ({ listsOfNodes }) => listsOfNodes,
    Polygon: ({ linearRings }) => List.of(linearRings.flatten(1) as List<LongLat>),
    MultiPolygon: ({ listsOfLinearRings }) => listsOfLinearRings.flatten(1) as List<List<LongLat>>,
  });
}

// ----------
// Data types
// ----------

export class LongLat {
  readonly long: number;
  readonly lat: number;

  constructor({ long, lat }: { long: number, lat: number }) {
    this.long = long;
    this.lat = lat;
  }

  static fromJS(obj: number[]) {
    if (obj.length !== 2) {
      console.warn(new Error('LongLat array should be of length 2'));
    }
    const [long, lat] = obj;
    return new LongLat({ long, lat });
  }

  static toJS({ long, lat }: { long: number, lat: number }) {
    return [long, lat];
  }

  static get argType() { return ArgTypes.instanceOf(this); }
}

// alias for convenience
const ListOf = ArgTypes.Immutable.list.of;

export const GeoTamr = TaggedUnion({
  Point: { longLat: LongLat.argType },
  MultiPoint: { longLats: ListOf(LongLat.argType) },
  LineString: { nodes: ListOf(LongLat.argType) },
  MultiLineString: { listsOfNodes: ListOf(ListOf(LongLat.argType)) },
  Polygon: { linearRings: ListOf(ListOf(LongLat.argType)) },
  MultiPolygon: { listsOfLinearRings: ListOf(ListOf(ListOf(LongLat.argType))) },
}, 'GeoTamr');
export type GeoTamrType = InferConstructedKind<typeof GeoTamr.kindDefinitions>;

// -----------------------
// Data format conversions
// - GeoTamr
// - GeoJSON
// - D3 variant of GeoJSON (same as GeoJSON except for winding order)
// -----------------------

/**
 * Convert a GeoTamr to a GeoTamr-formatted POJO.
 */


type GeoTamrJsonType
  = HasPointCoords
  | HasMultiPointCoords
  | HasLineStringCoords
  | HasMultiLineStringCoords
  | HasPolygonCoords
  | HasMultiPolygonCoords

export function toJS(gt: GeoTamrType): GeoTamrJsonType {
  return gt.case<GeoTamrJsonType>({
    Point: ({ longLat }) => ({
      point: LongLat.toJS(longLat),
    }),
    MultiPoint: ({ longLats }) => ({
      multiPoint: longLats.map(longLat => LongLat.toJS(longLat)).toJS(),
    }),
    LineString: ({ nodes }) => ({
      lineString: nodes.map(LongLat.toJS).toJS(),
    }),
    MultiLineString: ({ listsOfNodes }) => ({
      multiLineString: listsOfNodes.map(nodes => nodes.map(LongLat.toJS)).toJS(),
    }),
    Polygon: ({ linearRings }) => ({
      polygon: linearRings.map(lr => lr.map(LongLat.toJS)).toJS(),
    }),
    MultiPolygon: ({ listsOfLinearRings }) => ({
      multiPolygon: listsOfLinearRings.map(list => list.map(lr => lr.map(LongLat.toJS))).toJS(),
    }),
  });
}

/**
 * Convert a GeoTamr to a GeoJSON-formatted POJO.
 */

type GeoJSONPoint = {
  type: 'Point'
  coordinates: PointCoords
}
type GeoJSONMultiPoint = {
  type: 'MultiPoint'
  coordinates: MultiPointCoords
}
type GeoJSONLineString = {
  type: 'LineString'
  coordinates: LineStringCoords
}
type GeoJSONMultiLineString = {
  type: 'MultiLineString'
  coordinates: MultiLineStringCoords
}
type GeoJSONPolygon = {
  type: 'Polygon'
  coordinates: PolygonCoords
}
type GeoJSONMultiPolygon = {
  type: 'MultiPolygon'
  coordinates: MultiPolygonCoords
}
type GeoJSON
  = GeoJSONPoint
  | GeoJSONMultiPoint
  | GeoJSONLineString
  | GeoJSONMultiLineString
  | GeoJSONPolygon
  | GeoJSONMultiPolygon

export function toGeoJSON(gt: GeoTamrType): GeoJSON {
  const js = toJS(gt);
  return gt.case<GeoJSON>({
    Point: () => {
      const coordinates = (js as HasPointCoords).point;
      if (!coordinates) {
        throw new Error('Could not convert GeoTamr to GeoJSON Point - was not a Point.');
      }
      return { type: 'Point', coordinates };
    },
    MultiPoint: () => {
      const coordinates = (js as HasMultiPointCoords).multiPoint;
      if (!coordinates) {
        throw new Error('Could not convert GeoTamr to GeoJSON MultiPoint - was not a MultiPoint.');
      }
      return { type: 'MultiPoint', coordinates };
    },
    LineString: () => {
      const coordinates = (js as HasLineStringCoords).lineString;
      if (!coordinates) {
        throw new Error('Could not convert GeoTamr to GeoJSON LineString - was not a LineString.');
      }
      return { type: 'LineString', coordinates };
    },
    MultiLineString: () => {
      const coordinates = (js as HasMultiLineStringCoords).multiLineString;
      if (!coordinates) {
        throw new Error('Could not convert GeoTamr to GeoJSON MultiLineString - was not a MultiLineString.');
      }
      return { type: 'MultiLineString', coordinates };
    },
    Polygon: () => {
      const coordinates = (js as HasPolygonCoords).polygon;
      if (!coordinates) {
        throw new Error('Could not convert GeoTamr to GeoJSON Polygon - was not a Polygon.');
      }
      return { type: 'Polygon', coordinates };
    },
    MultiPolygon: () => {
      const coordinates = (js as HasMultiPolygonCoords).multiPolygon;
      if (!coordinates) {
        throw new Error('Could not convert GeoTamr to GeoJSON MultiPolygon - was not a MultiPolygon.');
      }
      return { type: 'MultiPolygon', coordinates };
    },
  });
}

/**
 * Reverse the winding order for polygons.
 * GeoTamr / GeoJSON winding order is:
 * - Exterior: counter-clockwise
 * - Interior: clockwise
 * D3 winding order is:
 * - Exterior: clockwise
 * - Interior: counter-clockwise
 */
export function reverseWindingOrder(gt: GeoTamrType): GeoTamrType {
  const identity = () => gt;
  return gt.case<GeoTamrType>({
    Polygon: ({ linearRings }) => {
      const d3WindingOrder = linearRings.map(lr => lr.reverse());
      return GeoTamr.Polygon({ linearRings: d3WindingOrder });
    },
    MultiPolygon: ({ listsOfLinearRings }) => {
      const d3WindingOrder = listsOfLinearRings.map(list => list.map(lr => lr.reverse()));
      return GeoTamr.MultiPolygon({ listsOfLinearRings: d3WindingOrder });
    },
    Point: identity,
    MultiPoint: identity,
    LineString: identity,
    MultiLineString: identity,
  });
}

/**
 * Winding order for a polygon determines which rings defined the outer bounds
 * of the polygon, and which rings determine negative space inside of the polygon.
 * GeoJSON convention uses counter-clockwise winding to determine the outer bounds,
 * and clockwise to define negative space inside the polygon. The D3 convention uses
 * the opposite.
 *
 * To support both conventions, we calculate the area of the polygon for counter-clockwise
 * winding, and clockwise winding. If the data is wound clockwise, we
 * reverse the order, so all polygons are wound counter-clockwise.
 *
 * For more on winding order, see:
 * - https://gis.stackexchange.com/questions/119150/order-of-polygon-vertices-in-general-gis-clockwise-or-counterclockwise/147971#147971
 */
function correctWindingOrder(gt: GeoTamrType): GeoTamrType {
  // Reverse the winding order for a second GeoTamr object.
  const reversedGt = reverseWindingOrder(gt);

  const reversedFeatures = {
    type: 'FeatureCollection',
    features: List.of({
      type: 'Feature',
      properties: {},
      geometry: toGeoJSON(reversedGt),
    }).toArray(),
  };

  // Build features using the original winding order and compare the area of the polygons.
  const originalFeatures = {
    type: 'FeatureCollection',
    features: List.of({
      type: 'Feature',
      properties: {},
      geometry: toGeoJSON(gt),
    }).toArray(),
  };

  // @ts-expect-error this violates the signature for some reason - someone more knowledgeable about geo should take a look at this
  const reversedArea = d3.geoArea(reversedFeatures);
  // @ts-expect-error this violates the signature for some reason - someone more knowledgeable about geo should take a look at this
  const originalArea = d3.geoArea(originalFeatures);

  // D3 assumes clockwise winding, but we want uniformly counter-clockwise winding, so
  // whichever area is larger will represent the GeoJSON counter-clockwise convention.
  return reversedArea < originalArea ? gt : reversedGt;
}

type PointCoords = number[];
type MultiPointCoords = number[][];
type LineStringCoords = number[][];
type MultiLineStringCoords = number[][][];
type PolygonCoords = number[][][];
type MultiPolygonCoords = number[][][][];

type HasPointCoords = { point: PointCoords };
type HasMultiPointCoords = { multiPoint: MultiPointCoords };
type HasLineStringCoords = { lineString: LineStringCoords };
type HasMultiLineStringCoords = { multiLineString: MultiLineStringCoords };
type HasPolygonCoords = { polygon: PolygonCoords };
type HasMultiPolygonCoords = { multiPolygon: MultiPolygonCoords };

function createPolygon(obj: HasPolygonCoords): GeoTamrType {
  const gt = GeoTamr.Polygon({
    linearRings: List(obj.polygon.map(linearRings =>
      List(linearRings.map(e => LongLat.fromJS(e))),
    )),
  });
  return correctWindingOrder(gt);
}

function createMultiPolygon(obj: HasMultiPolygonCoords): GeoTamrType {
  const gt = GeoTamr.MultiPolygon({
    listsOfLinearRings: List(obj.multiPolygon.map(list => List(list.map(linearRings =>
      List(linearRings.map(e => LongLat.fromJS(e))),
    )))),
  });
  return correctWindingOrder(gt);
}

export function calculateBounds(geoTamrs: GeoTamrType[]) {
  checkArg({ geoTamrs }, ArgTypes.array.of(GeoTamr.argType));

  const features = {
    type: 'FeatureCollection',
    features: geoTamrs.map(gt => ({
      type: 'Feature',
      properties: {},
      geometry: toGeoJSON(reverseWindingOrder(gt)),
    })),
  };
  // @ts-expect-error this violates the signature for some reason - someone more knowledgeable about geo should take a look at this
  const bounds = d3.geoBounds(features);

  // DEV-12597: If are bounds are a single point, this causes zoom level to be calculated as
  // infinite, causing many failed calculations down the line (such as Scale). This only occurs
  // when all the geometric features are identical points. In this case we adjust the bounds
  // slightly to avoid "Infinity" calculations.
  return (bounds[0][0] === bounds[1][0] && bounds[0][1] === bounds[1][1]) ?
    [bounds[0], [bounds[1][0] - 0.00000000000001, bounds[1][1] - 0.0000000000001]] :
    bounds;
}

export function calculateCentroid(bounds: $TSFixMe) {
  // @ts-expect-error this violates the signature for some reason - someone more knowledgeable about geo should take a look at this
  return d3.geoInterpolate(...bounds)(0.5);
}

/**
 * Convert from a GeoTamr-formatted POJO to a GeoTamr.
 */
export function fromJS(obj: {[key: string]: any}) {
  checkArg({ obj }, ArgTypes.object);
  if (!validate(obj)) {
    // throw the first validation error
    throw new Error(`Invalid object passed to GeoTamr::fromJS. Validation error: ${validate?.errors && validate.errors[0]}. Object: ${obj}`);
  }
  const pointObj = obj as HasPointCoords;
  if (pointObj.point) {
    return GeoTamr.Point({ longLat: LongLat.fromJS(pointObj.point) });
  }
  const multiPointObj = obj as HasMultiPointCoords;
  if (multiPointObj.multiPoint) {
    return GeoTamr.MultiPoint({
      longLats: List(multiPointObj.multiPoint.map(e => LongLat.fromJS(e))),
    });
  }
  const lineStringObj = obj as HasLineStringCoords;
  if (lineStringObj.lineString) {
    return GeoTamr.LineString({
      nodes: List(lineStringObj.lineString.map(e => LongLat.fromJS(e))),
    });
  }
  const multiLineStringObj = obj as HasMultiLineStringCoords;
  if (multiLineStringObj.multiLineString) {
    return GeoTamr.MultiLineString({
      listsOfNodes: List(multiLineStringObj.multiLineString.map(list => List(list.map(e => LongLat.fromJS(e))))),
    });
  }
  const polygonObj = obj as HasPolygonCoords;
  if (polygonObj.polygon) {
    return createPolygon(polygonObj);
  }
  const multiPolygonObj = obj as HasMultiPolygonCoords;
  if (multiPolygonObj.multiPolygon) {
    return createMultiPolygon(multiPolygonObj);
  }
  throw new Error(`Unrecognized obj type: ${obj}`);
}

/**
 * Convert a GeoJSON-formatted POJO to a GeoTamr.
 */
export function fromGeoJSON(obj: { [key: string]: any }) {
  checkArg({ obj }, ArgTypes.object);
  if (!validateGeoJSON(obj)) {
    // throw the first validation error
    throw new Error(`Invalid object passed to GeoTamr::fromGeoJSON. Validation error: ${validate?.errors && validate.errors[0]}. Object: ${obj}`);
  }
  switch (obj.type) {
    case 'Point':
      return fromJS({ point: obj.coordinates });
    case 'MultiPoint':
      return fromJS({ multiPoint: obj.coordinates });
    case 'LineString':
      return fromJS({ lineString: obj.coordinates });
    case 'MultiLineString':
      return fromJS({ multiLineString: obj.coordinates });
    case 'Polygon':
      return fromJS({ polygon: obj.coordinates });
    case 'MultiPolygon':
      return fromJS({ multiPolygon: obj.coordinates });
    default:
      throw new Error(`Unrecognized geospatial type: ${obj.type}`);
  }
}
