import { $TSFixMe } from '../utils/typescript';

export const CHANGE = 'Location.change';
export const PROJECT_CHANGE = 'Location.projectChange';

interface LocationChangeAction {
  type: typeof CHANGE,
  location: $TSFixMe,
  oldPath: string
}

interface LocationProjectChange {
  type: typeof PROJECT_CHANGE
}

export type LocationActionTypes
  = LocationChangeAction
  | LocationProjectChange
