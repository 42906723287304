/* eslint-disable react/jsx-indent */
import { List } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import UserAvatar from '../chrome/UserAvatar';
import MinimalAuthUser from '../models/MinimalAuthUser';
import { ArgTypes } from '../utils/ArgValidation';
import { getAuthorizedUser } from '../utils/Selectors';
import { getTime } from '../utils/Time';
import Button from './Button';
import style from './CommentItem.module.scss';
import DeleteCommentConfirmation from './DeleteCommentConfirmation';
import DropdownList from './DropdownList';
import TextArea from './Input/TextArea';
import PopoverTrigger from './PopoverTrigger';
import TamrIcon from './TamrIcon';

const PREVIEW_LENGTH = 250;
@connect((state) => {
  return {
    authorizedUser: getAuthorizedUser(state),
    commentState: state.comments,
  };
}, {
  onShowEdit: (username, createdAt) => ({ type: 'Comments.showEditState', username, createdAt }),
  onShowDeleteDialog: (username, createdAt) => ({ type: 'Comments.showDeleteDialog', username, createdAt }),
  onHideEdit: () => ({ type: 'Comments.hideEditState' }),
})
export default class Comment extends React.Component {
  static propTypes = {
    authorizedUser: PropTypes.instanceOf(MinimalAuthUser).isRequired,
    commentMessage: PropTypes.string.isRequired,
    lastModified: PropTypes.number,
    onDeleteComment: PropTypes.func,
    onEditComment: PropTypes.func,
    timeInSeconds: PropTypes.number.isRequired,
    user: PropTypes.shape({
      given: PropTypes.string,
      surname: PropTypes.string,
      username: PropTypes.string.isRequired,
    }).isRequired,
  };

  // attempted to implement the tracking and storing of a comment draft in CommentState,
  // however, the fact that state was rerendering on every addition of a character led
  // to the UI being incredibly slow to update.
  state = {
    draft: ArgTypes.nullable(ArgTypes.string),
    seeAll: false,
  };

  componentDidUpdate() {
    const { commentState, user, timeInSeconds } = this.props;
    commentState.case({
      Editing: ({ username, createdAt }) => {
        if (username === user?.username && createdAt === timeInSeconds) {
          this.focusComment();
        }
      },
      _: () => null,
    });
  }

  focusComment = () => {
    this.refs.commentField.focus();
  };

  componentWillUnmount() {
    this.props.onHideEdit();
  }

  enterEditState = () => {
    const { user, timeInSeconds, commentMessage, onShowEdit } = this.props;
    this.refs.commentMenu.hide();
    onShowEdit(user?.username, timeInSeconds);
    this.setState({ draft: commentMessage });
  };

  onEnterSubmit = (e) => {
    const { onEditComment } = this.props;
    const { draft } = this.state;
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      onEditComment(draft);
      this.setState({ draft: null });
    }
  };

  render() {
    const {
      commentMessage, timeInSeconds, lastModified, user, authorizedUser, commentState,
      onHideEdit, onShowDeleteDialog, onEditComment, onDeleteComment,
    } = this.props;
    const { draft, seeAll } = this.state;

    const given = user?.given;
    const surname = user?.surname;
    const username = user?.username;

    return (
      <div className={style.commentItem}>
        <div className={style.commentLine}>
          {commentState.case({
            DeleteConfirmation: ({ usernameState, createdAt }) => {
              if (usernameState === username && createdAt === timeInSeconds) {
                return (
                  <DeleteCommentConfirmation
                    onAccept={() => onDeleteComment(usernameState, createdAt)}
                    actionName={'Delete'}
                    message={'Are you sure you want to delete this comment?'}
                  />
                );
              }
            },
            _: () => null,
          })}
          <UserAvatar
            username={username}
            firstName={given}
            lastName={surname}
            className={style.userBackground}
            useToolTip
          />
          <div className={style.allCommentInfo}>
            <div className={style.commentHeader}>
              <div className={style.headerLeft}>
                <span
                  className={style.commentUser}
                  title={username
                    ? ''
                    : 'This user might have been deactivated or removed from the system.\nContact your admin for more information.'
                  }
                  style={{ cursor: 'pointer' }}
                >
                  {given && surname
                    ? given + ' ' + surname
                    : username || '[Unknown user]'}
                </span>
                <span className={style.commentDate}> {
                  timeInSeconds === lastModified ? getTime(timeInSeconds * 1000).fromNow()
                    : `edited ${getTime(lastModified * 1000).fromNow()}`
                }
                </span>
              </div>
              <div className={style.headerRight}>
                {username !== authorizedUser.username
                  ? null
                  : (
                    <PopoverTrigger
                      className={style.commentMenu}
                      ref="commentMenu"
                      placement="bottom"
                      content={
                        <DropdownList items={
                          List([
                            {
                              iconName: 'edit',
                              label: 'Edit',
                              onClick: this.enterEditState,
                            },
                            {
                              iconName: 'delete',
                              label: 'Delete',
                              onClick: () => {
                                onShowDeleteDialog(username, timeInSeconds);
                                this.refs.commentMenu.hide();
                              },
                            },
                          ])
                        }
                        />
                      }
                    >
                      <TamrIcon
                        iconName="more-vert"
                        className={style.menuIcon}
                        size={14}
                      />
                    </PopoverTrigger>
                  )
                }
              </div>
            </div>
            <div className={style.commentBody}>
              {commentState.case({
                Editing: ({ usernameState, createdAt }) => {
                  if (usernameState === username && createdAt === timeInSeconds) {
                    return (
                      <React.Fragment>
                        <TextArea
                          ref="commentField"
                          maxRows={5}
                          onChange={message => this.setState({ draft: message })}
                          title="Edit this comment"
                          value={draft || ''}
                          onKeyDown={this.onEnterSubmit}
                        />
                        <div className={style.editTextboxInfo}>
                          <TamrIcon
                            className={style.subArraow}
                            iconName="subdirectoryarrow"
                            size={14}
                          />
                          <span className={style.info}>Shift + Enter for a new line</span>
                        </div>
                        <div className={style.editButtonBar}>
                          <Button
                            className={style.cancelEdit}
                            buttonType="Secondary"
                            onClick={() => {
                              onHideEdit();
                              this.setState({ draft: null });
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            className={style.saveEdit}
                            onClick={() => {
                              onEditComment(draft);
                              this.setState({ draft: null });
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      </React.Fragment>
                    );
                  }
                  return (
                    <div>
                      {commentMessage}
                    </div>
                  );
                },
                _: () => {
                  if (commentMessage.length > PREVIEW_LENGTH) {
                    return (
                      <React.Fragment>
                        <div>
                          {seeAll ? commentMessage : `${commentMessage.slice(0, PREVIEW_LENGTH)} ...`}
                        </div>
                        <Button className={style.seeAllButton} buttonType={'Link'} onClick={() => this.setState({ seeAll: !seeAll })}>
                          {seeAll ? 'See less' : 'See all' }
                        </Button>
                      </React.Fragment>
                    );
                  }
                  return (
                    <div>
                      {commentMessage}
                    </div>
                  );
                },
              })}
            </div>
          </div>
        </div>
    </div>);
  }
}
