import createLoader from '../utils/createLoader';
import { getUnifiedDatasetName, selectActiveProjectInfo } from '../utils/Selectors';
import { fetchCategorizationModel } from './TransactionApi';

const ModelLoader = createLoader(state => {
  const { transactions: { loadingModel, modelRecipeId } } = state;
  const unifiedDataset = getUnifiedDatasetName(state);
  const recipeId = selectActiveProjectInfo(state)?.recipeId;
  return {
    canFetch: !!unifiedDataset,
    shouldFetch: recipeId !== modelRecipeId,
    loading: loadingModel,
  };
}, {
  onFetch: fetchCategorizationModel,
}, 'ModelLoader');

export default ModelLoader;
