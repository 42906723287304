import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import Selector from '../components/Input/Selector';
import { AppAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { SET_CONFIG_VALUE } from './AddDatasetActionTypes';
import style from './ExternalStorageContent.module.scss';


const UnconnectedConfigOption: React.FC<{
  onChangeValue: (key: string, value: string) => any
  valueKey: string
  name: string
  value: string
  values: Array<{
    value: string
    display: string
  }>
}> = ({ onChangeValue, valueKey, name, value, values }) => (
  <div className={style.configOption}>
    <div className={style.name}>{name}</div>
    <Selector
      className={style.configSelector}
      {...{ value, values }}
      onChange={newValue => onChangeValue(valueKey, newValue)}
    />
  </div>
);

const ConfigOption = connect(null, {
  onChangeValue: (key: string, value: string): AppAction => ({ type: SET_CONFIG_VALUE, key, value }),
})(UnconnectedConfigOption);

export default connect(({ externalStorage: { selectedNodeConfig } }: AppState) => {
  return {
    config: selectedNodeConfig,
  };
})(({ config }) => (
  <div className={style.configure}>
    {!config ? null : (
      <Fragment>
        {config.map((configOption, key) => (
          <ConfigOption
            key={key}
            valueKey={key}
            name={configOption.displayName}
            value={configOption.selectedValue}
            values={configOption.values}
          />
        )).valueSeq()}
      </Fragment>
    )}
  </div>
));
