import React from 'react';

import WarningIcon from '../components/WarningIcon';
import { ConnectedFC } from '../utils/typescript-react';
import { profileInputDataset } from './GoldenRecordsAsync';
import {
  getUpdateClusterProfileJobIsRunningOrSubmitting,
  getUpdateSourceListJobIsRunning,
} from './GoldenRecordsSelectors';
import { selectIsClusterProfileJobKnownToBeOutOfDate } from './GoldenRecordsStore';
import style from './GoldenRecordsTaskbar.module.scss';


const GoldenRecordsReprofileWarning = ConnectedFC(
  (state) => {
    const { goldenRecords, goldenRecords: { submittingUpdateSourceListJob, sourceListOutOfDate } } = state;
    return {
      sourceListOutOfDate,
      clusterProfileJobOutOfDate: selectIsClusterProfileJobKnownToBeOutOfDate(goldenRecords),
      clusterProfileJobIsRunning: getUpdateClusterProfileJobIsRunningOrSubmitting(state),
      updateSourceListJobIsRunning: submittingUpdateSourceListJob || getUpdateSourceListJobIsRunning(state),
    };
  },
  {
    onProfileInputDataset: profileInputDataset,
  },
  ({ updateSourceListJobIsRunning, sourceListOutOfDate, onProfileInputDataset, clusterProfileJobOutOfDate, clusterProfileJobIsRunning }) => {
    const warnAboutSourceListOutOfDate = sourceListOutOfDate && !updateSourceListJobIsRunning;
    const warnAboutClusterProfileOutOfDate = clusterProfileJobOutOfDate && !clusterProfileJobIsRunning;
    const warnAboutProfilingOutOfDate = warnAboutSourceListOutOfDate || warnAboutClusterProfileOutOfDate;
    return !warnAboutProfilingOutOfDate ? <noscript /> : (
      <div className={style.sourceListWarning}>
        <WarningIcon />&nbsp;Your input dataset needs to be&nbsp;<a onClick={onProfileInputDataset}>re-profiled</a>.
      </div>
    );
  },
);

export default GoldenRecordsReprofileWarning;
