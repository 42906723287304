import React, { useState } from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Selector from '../components/Input/Selector';
import { State } from '../stores/AppState';
import { APPLY_SETTINGS, SET_SHOW_SETTINGS } from './PregroupActionTypes';
import style from './PregroupSettingsDialog.module.scss';
import { selectSettingMaxGroupSize, selectSettingsShow } from './PregroupStore';

const SettingTitle: React.FC = ({ children }) => (
  <div className={style.settingTitle}>
    {children}
  </div>
);

const Setting: React.FC<{
  description: JSX.Element;
  onChange: (newValue: number) => void;
  value: number;
  values: { value: number, display: string }[];
}> = ({ description, onChange, value, values }) => (
  <div className={style.setting}>
    {description}
    <Selector className={style.settingSelector} onChange={onChange} value={value} values={values} />
  </div>
);

const PregroupSettingsDialog = connect((state: State) => {
  return {
    show: selectSettingsShow(state),
    maxGroupSize: selectSettingMaxGroupSize(state),
  };
}, {
  onApply: (maxGroupSize: number) => ({ type: APPLY_SETTINGS, maxGroupSize }),
  onClose: () => ({ type: SET_SHOW_SETTINGS, show: false }),
})(({ maxGroupSize, show, onApply, onClose }) => {
  const [draftMaxGroupSize, setDraftMaxGroupSize] = useState<number>(maxGroupSize);

  const formattedGroupSize = <span className={style.emphasize}>{draftMaxGroupSize.toLocaleString('en-US')}</span>;
  const warning = <i>Larger groups are not included in the preview</i>;
  const description =
    <span className={style.settingDescription}>
      Show groups of up to {formattedGroupSize} records
      <br />
      {warning}
    </span>;

  return (
    <Dialog
      dialogStyle={DialogStyle.PRIMARY}
      title="Settings"
      body={
        <>
          <SettingTitle>Maximum Preview Size</SettingTitle>
          <Setting
            description={description}
            onChange={setDraftMaxGroupSize}
            value={draftMaxGroupSize}
            values={[{ value: 100, display: '100' }, { value: 1000, display: '1,000' }, { value: 10000, display: '10,000' }]}
          />
        </>
      }
      footer={
        <ButtonToolbar>
          <Button
            buttonType="Secondary"
            onClick={() => {
              onClose();
              setDraftMaxGroupSize(maxGroupSize);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => onApply(draftMaxGroupSize)}
          >
            Apply
          </Button>
        </ButtonToolbar>
      }
      show={show}
      onHide={() => {
        onClose();
        setDraftMaxGroupSize(maxGroupSize);
      }}
    />
  );
});

export default PregroupSettingsDialog;
