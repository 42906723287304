import numeral from 'numeral';
import _ from 'underscore';

import { $TSFixMe } from './typescript';

// some values will be single-element arrays with the element being the number we care about
// for convenience, allow that format, but strip the array before passing to numeral
function stripArray(val: $TSFixMe) {
  if (_.isArray(val)) {
    if (val.length === 0 || val.length > 1) {
      throw new Error(`Illegal array passed to Numbers function, must be size 1.  Found: ${JSON.stringify(val)}`);
    }
    return val[0];
  }
  return val;
}

function strippedNumeral(val: number): Numeral {
  return numeral(stripArray(val));
}

function decimalFormat(num: $TSFixMe) {
  return strippedNumeral(num).format('0.00');
}

function shortFormat(num: number): string {
  return num < 1000 ? String(num) : strippedNumeral(num).format('0.[0]a').toUpperCase();
}

export const commafy = (num: number): string => {
  return strippedNumeral(num).format('0,0');
};

// switches between 12,345 and 3.6M formats considering `max` threshold
export function longFormat(num: number, max = 1000000): string {
  return num < max ? commafy(num) : shortFormat(num);
}

function longMoney(num: $TSFixMe, max = 1000000) {
  return longFormat(num, max);
}

export const commafyMoney = (num: number) => {
  return strippedNumeral(num).format('0,0.00');
};

function shortMoney(num: number) {
  const absVal = Math.abs(num);
  const strippedAbsVal = strippedNumeral(absVal);
  const absFormatted = absVal < 1000 ? strippedAbsVal.format('0.00') : strippedAbsVal.format('0.[0]a');
  return num < 0 ? `-${absFormatted}` : absFormatted;
}

function getFloat(num: $TSFixMe) {
  return 1.0 * num;
}

function safeDivide(dividend: $TSFixMe, divisor: $TSFixMe, defaultQuotient = 0) {
  if (divisor === 0) {
    return defaultQuotient;
  }
  return getFloat(dividend) / getFloat(divisor);
}

const getPerc = (num: $TSFixMe, denom: $TSFixMe) => {
  if (denom === 0) {
    return 0;
  }
  return 100.0 * num / denom;
};

function shortPerc(num: $TSFixMe, denom: $TSFixMe) {
  return strippedNumeral(getPerc(num, denom)).format('0.0') + '%';
}

export function meters(num: $TSFixMe) {
  if (num === 0) {
    return '0m';
  }
  if (num < 1) { // cm
    return numeral(num * 100).format('0') + 'cm';
  }
  if (num >= 1000) { // km
    return numeral(num / 1000).format('0,0.0') + 'km';
  }
  return numeral(num).format('0.0') + 'm';
}

export {
  decimalFormat,
  shortPerc,
  shortFormat,
  getPerc,
  longMoney,
  shortMoney,
  getFloat,
  safeDivide,
  strippedNumeral,
};
