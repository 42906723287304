import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import { fetchTransactions } from '../records/RecordsApi';
import style from './SaveTransformsButton.module.scss';
import { clearDeltas, updateList } from './TransformsApi';

const ClearEditsButton = _.compose(
  connect(({ transforms: { deltas } }) => {
    let tooltip = 'Cancel all changes';
    let disabled = false;
    if (!deltas || deltas.isEmpty()) {
      tooltip = 'No changes to cancel';
      disabled = true;
    }
    return { disabled, tooltip };
  }, {
    onClearDeltas: clearDeltas,
    onUpdateList: updateList,
    onFetchTransactions: fetchTransactions,
  }),
)(({ onClearDeltas, onUpdateList, onFetchTransactions, ...props }) => {
  const resetFunc = () => {
    onClearDeltas();
    onUpdateList();
    onFetchTransactions();
  };
  return (
    <Button {...props} className={style.cancelButton} buttonType="Secondary" onClick={resetFunc}>
      Cancel changes
    </Button>
  );
});

export default ClearEditsButton;
