import { Map, Set } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import ConditionalButton from '../components/ConditionalButton';
import Term from '../components/Term';
import { AppState } from '../stores/MainStore';
import { isCuratorByProjectId, isVerifierByProjectId } from '../utils/Authorization';
import { getAuthorizedUser, selectActiveProjectInfo } from '../utils/Selectors';
import style from './RecordPairsFeedbackAssignButton.module.scss';


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RecordPairsFeedbackAssignButton = connect((state: AppState, ownProps: { iconify: boolean, rowNumbers: Set<number> }) => {
  const projectInfo = selectActiveProjectInfo(state);
  const authUser = getAuthorizedUser(state);
  return { isCurator: authUser && isCuratorByProjectId(authUser, projectInfo && projectInfo.projectId), isVerifier: authUser && isVerifierByProjectId(authUser, projectInfo && projectInfo.projectId) };
}, {
  onBeginAssigningFeedback: (rowNumbers: Set<number>) => ({ type: 'RecordPairs.beginAssigningFeedback', rowNumbers }),
})(({ isCurator, isVerifier, onBeginAssigningFeedback, rowNumbers, iconify }) => {
  if (!(isCurator || isVerifier)) {
    return null;
  }
  const preconditions = Map.of(
    <span>You must select at least one <Term>record</Term> <Term>pair</Term>.</span>,
    !rowNumbers.isEmpty(),
  );
  const label = 'Assign';
  return (
    <ConditionalButton
      className={style.component}
      buttonType="Secondary"
      onClick={() => onBeginAssigningFeedback(rowNumbers)}
      preconditions={preconditions}
      icon="group-add"
      iconSize={16}
      tooltipLabel={iconify ? label : ''}
    >
      {iconify ? undefined : label}
    </ConditionalButton>
  );
});

export default RecordPairsFeedbackAssignButton;
