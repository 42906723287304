import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Term from '../components/Term';
import { getFieldNamesUsedByDnf } from '../dnfBuilder/DnfBuilderStore';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { pluralize } from '../utils/Strings';
import { joinWithUltimate } from '../utils/Values';
import { CANCEL_UNIFIED_ATTRIBUTE_ACTION } from './SchemaMappingActionTypes';
import { deleteUnifiedAttributes } from './SchemaMappingAsync';

const UnifiedAttributeActionWarningDialog = _.compose(
  connect((state) => {
    const { schemaMapping: { confirmDeleteAttributeIds } } = state;
    const confirmDeleteAttributeNames = confirmDeleteAttributeIds.map(a => a.name);
    const warnForDnfAttributeNames =
      getFieldNamesUsedByDnf(selectActiveProjectInfo(state).recipe).intersect(confirmDeleteAttributeNames);
    return { confirmDeleteAttributeNames, warnForDnfAttributeNames };
  }, {
    onCancel: () => ({ type: CANCEL_UNIFIED_ATTRIBUTE_ACTION }),
    onConfirmDelete: deleteUnifiedAttributes,
  }),
)(({ confirmDeleteAttributeNames, warnForDnfAttributeNames, onCancel, onConfirmDelete }) => {
  const show = !confirmDeleteAttributeNames.isEmpty() || !warnForDnfAttributeNames.isEmpty();
  return (
    <Dialog
      dialogStyle={DialogStyle.PRIMARY}
      show={show}
      onHide={onCancel}
      closeButton
      title={show ? `Delete ${pluralize(confirmDeleteAttributeNames.size, 'Attribute', 'Attributes')}` : null}
      body={show ? (
        <div>
          {!warnForDnfAttributeNames.isEmpty() ? (
            <span>
              <b>{joinWithUltimate(warnForDnfAttributeNames, ', ', ', and ', ' and ')}</b> {pluralize(warnForDnfAttributeNames.size, 'is', 'are')} currently used to determine how <Term>pairs</Term> are found.&nbsp;
              'Removing it from your Unified Dataset will require you to reconfigure how pairs are found.'
              <br />
              <br />
            </span>
          ) : null}
          Are you sure you want to&nbsp;
          <span>delete <b>{pluralize(confirmDeleteAttributeNames.size, confirmDeleteAttributeNames.first(), `${confirmDeleteAttributeNames.size} attributes`)}</b>?</span>
          &nbsp;
          <span>This action cannot be undone, and changes will not take effect until you update your Unified Dataset.</span>
        </div>
      ) : null}
      footer={show ? (
        <ButtonToolbar>
          <Button buttonType="Secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button buttonType="Primary" onClick={onConfirmDelete}>
            {`Delete ${pluralize(confirmDeleteAttributeNames.size, 'Attribute', 'Attributes')}`}
          </Button>
        </ButtonToolbar>
      ) : null}
    />
  );
});

export default UnifiedAttributeActionWarningDialog;
