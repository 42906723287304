import { is } from 'immutable';

import createLoader from '../utils/createLoader';
import { fetchSourceAttributes } from './SchemaMappingAsync';
import { getSourceFilterInfo } from './SchemaMappingStore';

const SourceAttributesLoader = createLoader((state) => {
  const { schemaMapping: { sourceLoading, sourceLoadedFilterInfo } } = state;
  const filterInfo = getSourceFilterInfo(state);
  return {
    canFetch: !!filterInfo.smRecipeId && !!filterInfo.unifiedDatasetName,
    shouldFetch: !is(filterInfo, sourceLoadedFilterInfo),
    loading: sourceLoading,
  };
}, {
  onFetch: fetchSourceAttributes,
}, 'SourceAttributesLoader');

export default SourceAttributesLoader;
