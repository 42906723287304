import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Checkbox from '../components/Input/Checkbox';
import Input from '../components/Input/Input';
import { createGroupBy } from './GroupByApi';

/**
 * Verify the name of the new view being created
 */
const GroupByCreateDialog = connect((state) => {
  const { groupBy: { viewName, showSaveDialog, profile } } = state;
  return { viewName, show: showSaveDialog, profile };
}, {
  onHide: () => ({ type: 'GroupBy.hideSaveDialog' }),
  onCreateGroupBy: createGroupBy,
  onNameChange: (name) => ({ type: 'GroupBy.updateName', name }),
  onProfileChange: (profile) => ({ type: 'GroupBy.updateProfile', profile }),
})(/**
 * Verify the name of the new view being created
 */
  class GroupByCreateDialog extends React.Component {
    static propTypes = {
      onCreateGroupBy: PropTypes.func.isRequired,
      onHide: PropTypes.func.isRequired,
      onNameChange: PropTypes.func.isRequired,
      onProfileChange: PropTypes.func.isRequired,
      profile: PropTypes.bool,
      show: PropTypes.bool.isRequired,
      viewName: PropTypes.string,
    };

    renderNameField = () => {
      return (
        <div
          key="dataset-name"
          className="view-create-form"
      >
          <Input
            title="Name for new dataset"
            value={this.props.viewName}
            onChange={this.props.onNameChange}
        />
        </div>
      );
    };

    renderProfileCheckbox = () => {
      return (
        <div
          key="profile-checkbox"
          className="form-component-spacer"
      >
          <Checkbox
            title="Profile Dataset"
            titlePosition="right"
            onChange={this.props.onProfileChange}
            value={this.props.profile}
        />
          <div>
            Profiling provides statistics about your dataset.  While profiling,  you will not be able to run
            other jobs.  You can profile datasets at any time.
          </div>
        </div>
      );
    };

    render() {
      return (
        <Dialog
          className="group-by-create-dialog"
          show={this.props.show}
          onHide={this.props.onHide}
          title="Create new views"
          dialogStyle={DialogStyle.PRIMARY}
          body={[
            this.renderNameField(),
            this.renderProfileCheckbox(),
          ]}
          footer={(
            <ButtonToolbar>
              <Button onClick={this.props.onCreateGroupBy}>Okay</Button>
              <Button onClick={this.props.onHide} buttonType="Secondary">Cancel</Button>
            </ButtonToolbar>
        )}
      />
      );
    }
  });

export default GroupByCreateDialog;
