import { Map } from 'immutable';

import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import Model from '../models/Model';
import { ArgTypes } from '../utils/ArgValidation';
import { routes } from '../utils/Routing';
import { resetExcept } from '../utils/Values';
import GroupByEntry from './GroupByEntry';

const DEFAULT_AGGREGATION = 'com.tamr.workflow.specs.relational.aggregations.First';

const startGroupByEntry = (fieldName) => new GroupByEntry({
  fieldName,
  aggregationName: fieldName + '_aggregation',
  aggregationType: DEFAULT_AGGREGATION,
  groupBy: false,
  selected: false,
});

export const initialState = new (Model({
  datasetId: { type: ArgTypes.orUndefined(ArgTypes.number) },
  completed: { type: ArgTypes.bool, defaultValue: false },
  loadedDatasetId: { type: ArgTypes.nullable(ArgTypes.number) },
  datasetDoc: { type: ArgTypes.nullable(Document.argTypeWithNestedClass(Dataset)) },
  idField: { type: ArgTypes.string, defaultValue: 'tamr_id' },
  fields: { type: ArgTypes.Immutable.map.of(ArgTypes.instanceOf(GroupByEntry), ArgTypes.string), defaultValue: Map() }, // aggregationName to entry
  loading: { type: ArgTypes.bool, defaultValue: false },
  viewName: { type: ArgTypes.nullable(ArgTypes.string) },
  viewDescription: { type: ArgTypes.string, defaultValue: '' },
  profile: { type: ArgTypes.bool, defaultValue: false },
  errorMessage: { type: ArgTypes.nullable(ArgTypes.string) },
  showSaveDialog: { type: ArgTypes.bool, defaultValue: false },
  showAddFieldDialog: { type: ArgTypes.bool, defaultValue: false },
}))();

export const reducers = {
  'Location.change': (state, { location }) => {
    const m = routes.groupBy.match(location.pathname);
    if (m) {
      return state.set('datasetId', parseInt(m.datasetId, 10));
    }
    return state;
  },
  'GroupBy.reset': (state) => {
    return resetExcept(state, ['datasetId']);
  },
  'GroupBy.removeFields': (state, { deletedFields }) => {
    let { fields } = state;
    deletedFields.forEach((field) => {
      fields = fields.delete(field.fieldName);
    });
    return state.set('fields', fields);
  },
  'GroupBy.addFields': (state, { fieldNames }) => {
    let { fields } = state;
    fieldNames.forEach((fieldName) => {
      const field = startGroupByEntry(fieldName);
      fields = fields.set(field.fieldName, field);
    });
    return state.set('fields', fields);
  },
  'GroupBy.updateName': (state, { name }) => {
    return state.set('viewName', name);
  },
  'GroupBy.updateProfile': (state, { profile }) => {
    return state.merge({ profile });
  },
  'GroupBy.updateDescription': (state, { description }) => {
    return state.set('viewDescription', description);
  },
  'GroupBy.updateIdField': (state, { idField }) => {
    return state.merge({ idField });
  },
  'GroupBy.updateFields': (state, { updateFields, attribute, value }) => {
    let { fields } = state;
    updateFields.forEach((field) => {
      fields = fields.update(field.fieldName, f => f.set(attribute, value));
    });
    return state.set('fields', fields);
  },
  'GroupBy.createGroupBy': (state) => {
    return state.set('loading', true).set('showSaveDialog', false);
  },
  'GroupBy.createGroupByCompleted': (state) => {
    return state.set('completed', true);
  },
  'GroupBy.createGroupByFailed': (state, { errorMessage }) => {
    return state.merge({ loading: false, errorMessage });
  },
  'GroupBy.showSaveDialog': (state) => {
    return state.set('showSaveDialog', true);
  },
  'GroupBy.hideSaveDialog': (state) => {
    return state.set('showSaveDialog', false);
  },
  'GroupBy.fetchDataset': (state) => {
    return resetExcept(state, ['datasetId']).set('loading', true);
  },
  'GroupBy.fetchDatasetCompleted': (state, { datasetDoc }) => {
    return state.merge({ datasetDoc, loading: false, loadedDatasetId: datasetDoc.id.id, viewName: `${datasetDoc.data.name} View` });
  },
  'GroupBy.fetchDatasetFailed': (state, { datasetId }) => {
    return state.set('loading', false).set('loadedDatasetId', datasetId).delete('datasetDoc');
  },
  'GroupBy.clearErrors': (state) => {
    return state.delete('errorMessage');
  },
  'GroupBy.showAddFieldDialog': (state) => {
    return state.set('showAddFieldDialog', true);
  },
  'GroupBy.hideAddFieldDialog': (state) => {
    return state.set('showAddFieldDialog', false);
  },
};
