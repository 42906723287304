import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import { updateUserPolicyMembership } from '../accessControl/AccessControlAsync';
import { getPolicyById } from '../accessControl/AccessControlStore';
import { UserGroupsCount, UsersCount } from '../accessControl/Count';
import {
  datasetString,
  memberProjectString,
  resourceProjectString,
} from '../accessControl/PoliciesList';
import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Link from '../components/Link';
import PopoverTrigger from '../components/PopoverTrigger';
import SplitGrid, { Column } from '../components/SplitGrid';
import TooltipTrigger from '../components/TooltipTrigger';
import { Roles } from '../models/AuthUser';
import { AppAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import style from './PoliciesDialog.module.scss';


export default connect((state: AppState) => {
  const { accessControl, accessControl: { policyDocs, userDraftPolicyMembership } } = state;
  return {
    policyDocs,
    userDraftPolicyMembership,
    userInPolicy: (policyId: number): string | undefined => {
      if (!userDraftPolicyMembership) return undefined;
      const { draftPoliciesById, user, user: { username } } = userDraftPolicyMembership;
      if (user.admin) {
        return undefined;
      }
      if (draftPoliciesById.has(policyId)) {
        return draftPoliciesById.get(policyId)?.roleForUsername(username) || undefined;
      }
      return getPolicyById(accessControl)(policyId)?.roleForUsername(username) || undefined;
    },
    policyById: getPolicyById(accessControl),
  };
}, {
  onAddToPolicyDraft: ({ policyId, role }: { policyId: number, role: string }): AppAction => ({ type: 'Users.addToPolicyMembership', policyId, role }),
  onRemoveFromPolicyDraft: (policyId: number): AppAction => ({ type: 'Users.removeFromPolicyMembership', policyId }),
  onStopEditing: (): AppAction => ({ type: 'Users.stopEditingPolicyMembership' }),
  onUpdatePolicyMembership: updateUserPolicyMembership,
})(({ onAddToPolicyDraft, onRemoveFromPolicyDraft, onStopEditing, onUpdatePolicyMembership, policyDocs, userDraftPolicyMembership, policyById, userInPolicy }) => (!userDraftPolicyMembership ? null : (
  <Dialog
    className={style.policiesDialog}
    dialogStyle={DialogStyle.PRIMARY}
    show
    onHide={onStopEditing}
    title={`Policy membership for ${userDraftPolicyMembership.user.user.name || userDraftPolicyMembership.user.username}`}
    body={
      <SplitGrid
        topIds={policyDocs.filter(doc => doc.data.roleForUsername(userDraftPolicyMembership.user.username)).sortBy(doc => doc.data.name).map(doc => doc.id.id)}
        bottomIds={policyDocs.filter(doc => !doc.data.roleForUsername(userDraftPolicyMembership.user.username)).sortBy(doc => doc.data.name).map(doc => doc.id.id)}
      >
        {/* @ts-expect-error unsure how to not pass in topIds / bottomIds here */}
        <Column
          className={style.nameColumn}
          renderHeader={() => 'Policy name'}
          renderCell={({ id }) => {
            const policy = policyById(id);
            return (
              <div className={style.nameCell}>
                <div className={style.name}>{policy?.name}</div>
                <div className={style.description}>{policy?.description}</div>
              </div>
            );
          }}
        />
        {/* @ts-expect-error unsure how to not pass in topIds / bottomIds here */}
        <Column
          className={style.membersColumn}
          renderHeader={() => 'Members'}
          renderCell={({ id }) => {
            const policy = policyById(id);
            if (!policy) return <div />;
            const userRoleInPolicy = userInPolicy(id);
            return (
              <div>
                <TooltipTrigger
                  content={!userRoleInPolicy
                    ? null
                    : <span>User has {userRoleInPolicy === Roles.CURATOR ? 'Curator' : (userRoleInPolicy === Roles.VERIFIER ? 'Verifier' : 'Reviewer')} permissions in this policy</span>
                  }
                >
                  <span><UsersCount id={id} className={classNames({ [style.inPolicy]: userInPolicy(id) })} />,</span>
                </TooltipTrigger>
                <span> <UserGroupsCount id={id} />, {memberProjectString(policy)}</span>
              </div>
            );
          }}
        />
        {/* @ts-expect-error unsure how to not pass in topIds / bottomIds here */}
        <Column
          className={style.resourcesColumn}
          renderHeader={() => 'Resources'}
          renderCell={({ id }) => {
            const policy = policyById(id);
            if (!policy) return <div />;
            return (
              <div>
                <span>{resourceProjectString(policy)}</span>,
                <span> {datasetString(policy)}</span>
              </div>
            );
          }}
        />
        {/* @ts-expect-error unsure how to not pass in topIds / bottomIds here */}
        <Column
          className={style.actionColumn}
          renderHeader={() => null}
          renderCell={({ id }) => (
            <div className={style.actionButtonCell}>
              {userInPolicy(id)
                ? (
                  <TooltipTrigger
                    content={policyById(id)?.appliesToAllUsers()
                      ? 'You cannot remove users from a policy that includes all users. Admins can change this setting on the Policies page.'
                      : 'Remove user from policy'
                    }
                    placement="top"
                  >
                    <Button
                      className={style.removeFromPolicyButton}
                      buttonType="Link"
                      icon="close"
                      iconSize={14}
                      iconClassName={style.removeFromPolicyIcon}
                      onClick={() => onRemoveFromPolicyDraft(id)}
                      disabled={!!(policyById(id)?.appliesToAllUsers())}
                    />
                  </TooltipTrigger>
                ) : (
                  <PopoverTrigger
                    className={style.addToPolicyPopover}
                    content={
                      <div className={style.optionsList}>
                        <Button
                          buttonType="Link"
                          onClick={() => onAddToPolicyDraft({ policyId: id, role: Roles.REVIEWER })}
                        >Add as Reviewer
                        </Button>
                        <Button
                          buttonType="Link"
                          onClick={() => onAddToPolicyDraft({ policyId: id, role: Roles.VERIFIER })}
                        >Add as Verifier
                        </Button>
                        <Button
                          buttonType="Link"
                          onClick={() => onAddToPolicyDraft({ policyId: id, role: Roles.CURATOR })}
                        >Add as Curator
                        </Button>
                        <Button
                          buttonType="Link"
                          onClick={() => onAddToPolicyDraft({ policyId: id, role: Roles.AUTHOR })}
                        >Add as Author
                        </Button>
                      </div>
                    }
                    placement="left"
                  >
                    <Button
                      className={style.addToPolicyButton}
                      buttonType="Link"
                      icon="tamr-icon-add"
                      iconSize={14}
                    />
                  </PopoverTrigger>
                )
              }
            </div>
          )}
        />
      </SplitGrid>
    }
    footer={
      <div className={style.footer}>
        <Link className={style.linkToPolicies} to={'/access/policies'}>
          Manage policies from Policies page
        </Link>
        <ButtonToolbar>
          <Button
            buttonType="Secondary"
            onClick={onStopEditing}
          >
            Cancel
          </Button>
          <Button
            onClick={onUpdatePolicyMembership}
          >
            Save
          </Button>
        </ButtonToolbar>
      </div>
    }
  />
)));
