import { Map, Set } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { CATEGORIZATIONS, PAIRS, PREDICT_CATEGORIZATIONS, PREDICT_CLUSTER, RECORDS, TRAIN_PREDICT_CLUSTER } from '../constants/RecipeOperations';
import Document from '../models/doc/Document';
import Job, { PROJECT_ID_METADATA_KEY, RECIPE_ID_METADATA_KEY, RECIPE_OPERATION_METADATA_KEY } from '../models/Job';
import ProjectStepName from '../projects/ProjectStepName';
import { getPath } from '../utils/Values';

const PROJECT_STEPS_WITH_SPECIAL_COPY = Set([RECORDS, PAIRS, TRAIN_PREDICT_CLUSTER, PREDICT_CLUSTER, CATEGORIZATIONS, PREDICT_CATEGORIZATIONS]);

export const isProjectStepJob = (jobDoc) => {
  const recipeOperation = getPath(jobDoc, 'data', 'metadata', RECIPE_OPERATION_METADATA_KEY);
  return PROJECT_STEPS_WITH_SPECIAL_COPY.has(recipeOperation);
};

const JobName = connect((state) => {
  const { projects: { projectsWithStatus } } = state;
  return { projectsWithStatus };
})(({ jobDoc, projectsWithStatus, progressiveTense }) => {
  const { data: { metadata, description } } = jobDoc;
  if (isProjectStepJob(jobDoc)) {
    const recipeOperation = metadata.get(RECIPE_OPERATION_METADATA_KEY);
    const projectId = metadata.get(PROJECT_ID_METADATA_KEY);
    const projectStepName = <ProjectStepName projectStep={recipeOperation} {...{ progressiveTense, projectId }} />;
    if (recipeOperation === RECORDS) {
      // add unified dataset name in this special case
      const recipeId = metadata.get(RECIPE_ID_METADATA_KEY);
      const recipeWithStatus = projectsWithStatus.flatMap(pws => pws.recipes).find(rws => rws.recipe.id.id === recipeId);
      const unifiedDatasetName = (getPath(recipeWithStatus, 'recipe', 'data', 'outputDatasets') || Map()).keySeq().first();
      return <span>{projectStepName} <b>{unifiedDatasetName}</b></span>;
    }
    return projectStepName;
  }
  return <span>{description}</span>;
});

JobName.propTypes = {
  jobDoc: Document.propType.withDataType(Job).isRequired,
  progressiveTense: PropTypes.bool,
};

export default JobName;
