import PropTypes from 'prop-types';
import React from 'react';

import ProcurementCategory from '../models/ProcurementCategory';
import ProcurementTaxonomy from '../models/ProcurementTaxonomy';
import TemplateStrings from './TemplateStrings';

/**
 * Details widget showing summary of categories within a specific sub-tree.
 * If no selection specified, show summary of all categories in the taxonomy.
 */
class CategorySummary extends React.Component {
  static propTypes = {
    activeCategory: PropTypes.instanceOf(ProcurementCategory),
    taxonomy: PropTypes.instanceOf(ProcurementTaxonomy),
  };

  render() {
    let categoriesInSelection;
    if (this.props.activeCategory) {
      const activeCategoryPathAsString = this.props.activeCategory.path.join();
      categoriesInSelection = this.props.taxonomy.categories.filter((category) => {
        return category.path.join().indexOf(activeCategoryPathAsString) === 0
         && !(this.props.activeCategory.categoryId === category.categoryId);
      });
    } else {
      categoriesInSelection = this.props.taxonomy.categories;
    }

    const categoriesInSelectionGroupedByTier = categoriesInSelection.groupBy((category) => {
      return category.path.size;
    });

    return (
      <div className="details-item category-summary-component">
        <div className="total-categories">
          {TemplateStrings.Details.totalChildCategories(categoriesInSelection.size)}
        </div>
        {
          categoriesInSelectionGroupedByTier.map((categories, tierNumber) => {
            const tierClassName = `tier-${tierNumber}-categories category-tier`;
            return (
              <div className={tierClassName} key={tierClassName}>
                <span className="em-dash">&mdash;</span>
                {TemplateStrings.Details.tierCategories(tierNumber, categoriesInSelectionGroupedByTier.get(tierNumber).size)}
              </div>
            );
          }).toArray()
        }
      </div>
    );
  }
}

export default CategorySummary;
