import { List } from 'immutable';
import { connect } from 'react-redux';
import _ from 'underscore';

import PagerBar from '../components/Table/PagerBar';
import { SET_SOURCE_PAGE_NUM, SET_SOURCE_PAGE_SIZE } from './SchemaMappingActionTypes';

const PAGE_SIZES = List.of(25, 50, 100, 500, 1000);

const SourceAttributePager = _.compose(
  connect(({ schemaMapping }) => {
    const { sourcePageSize, sourcePageNum, sourceHasNextPage } = schemaMapping;
    return {
      pagerState: { pageSize: sourcePageSize, pageNum: sourcePageNum, hasNextPage: sourceHasNextPage }, pageSizes: PAGE_SIZES,
    };
  }, {
    onPageChange: page => ({ type: SET_SOURCE_PAGE_NUM, page }),
    onPageSizeChange: pageSize => ({ type: SET_SOURCE_PAGE_SIZE, pageSize }),
  }),
)(PagerBar);

export default SourceAttributePager;
