import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import FilterButton from '../components/FilterButton';
import TamrIcon from '../components/TamrIcon';
import iconStyle from '../components/WarningIcon.module.scss';
import { onlyInputDatasets } from '../datasetFilter/DatasetFilterAsync';
import DatasetFilterDialog from '../datasetFilter/DatasetFilterDialog';
import { isUnifiedDatasetCreated } from '../utils/Selectors';
import style from './RecordsFilter.module.scss';
import { DEFAULT_NUM_PREVIEW_DATASETS } from './RecordsSubHeader';
import { PREVIEW } from './RowProviders';

const RecordsFilter = _.compose(
  connect((state) => {
    const { records: { selectedDatasetNames, provider, datasetFilterDialogVisible }, datasetFilter: { datasetsToAdd, datasetsToRemove } } = state;
    const modifyQuery = onlyInputDatasets(state);
    const numCheckedDatasets = selectedDatasetNames.size + datasetsToAdd.size - datasetsToRemove.size;
    const unifiedDatasetCreated = isUnifiedDatasetCreated(state);
    return { unifiedDatasetCreated, numCheckedDatasets, datasetFilterDialogVisible, selectedDatasetNames, provider, modifyQuery };
  }, {
    onSubmit: (datasetsToAdd, datasetsToRemove) => ({ type: 'Records.setSelectedDatasets', datasetsToAdd, datasetsToRemove }),
    onOpen: () => ({ type: 'Records.openDatasetFilterDialog' }),
    onHide: () => ({ type: 'Records.closeDatasetFilterDialog' }),
    onClear: () => ({ type: 'Records.resetSelectedDatasets' }),
  }),
)(({ numCheckedDatasets, modifyQuery, datasetFilterDialogVisible, provider, selectedDatasetNames, onOpen, onClear, onHide, onSubmit, unifiedDatasetCreated }) => {
  return (
    <div className={style.recordsFilterComponent}>
      <FilterButton
        filterExpanded={datasetFilterDialogVisible}
        hasFilters={selectedDatasetNames.size}
        preconditions={Map().set('Please create your unified dataset on the Schema Mapping page', unifiedDatasetCreated)}
        disabledTooltipPlacement="right"
        onClick={onOpen}
        onClear={selectedDatasetNames.size ? onClear : undefined}
      />
      <DatasetFilterDialog
        {...{ onHide, onSubmit, modifyQuery }}
        show={datasetFilterDialogVisible}
        getSelectedDatasets={() => selectedDatasetNames}
        extraFooterContent={provider === PREVIEW && numCheckedDatasets > DEFAULT_NUM_PREVIEW_DATASETS ? (
          <span className={style.warningText}>
            <TamrIcon className={iconStyle.warningIcon} iconName="tamr-icon-warning" size={12} />{' '}
            Selecting more than {DEFAULT_NUM_PREVIEW_DATASETS} datasets will slow down your preview
          </span>
        ) : undefined}
      />
    </div>
  );
});

export default RecordsFilter;
