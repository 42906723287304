import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

const VALUE_SEPARATOR_MARKER = '..';

class ConfidenceFilter extends getModelHelpers({
  lowerBound: { type: ArgTypes.number },
  upperBound: { type: ArgTypes.number },
}, 'ConfidenceFilter')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ConfidenceFilterRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  toUrlString() {
    const { lowerBound, upperBound } = this;
    return `${lowerBound}${VALUE_SEPARATOR_MARKER}${upperBound}`;
  }

  static fromUrlString(urlString: string) {
    checkArg({ urlString }, ArgTypes.string);
    const filterStringSegments = urlString.split(VALUE_SEPARATOR_MARKER);
    if (filterStringSegments.length !== 2) {
      throw new Error('Confidence url fragment must contain exactly 2 ".." separated numbers.');
    }
    const lowerBound = parseFloat(filterStringSegments[0]);
    const upperBound = parseFloat(filterStringSegments[1]);
    return new ConfidenceFilter({
      lowerBound,
      upperBound,
    });
  }
}

export default ConfidenceFilter;
