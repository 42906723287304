import { Map } from 'immutable';
import React from 'react';
import _ from 'underscore';

import ConditionalButton from '../components/ConditionalButton';
import Toolbar from '../components/Toolbar';
import Document from '../models/doc/Document';
import Job from '../models/Job';
import { ConnectedFC } from '../utils/typescript-react';
import style from './EnichmentHeader.module.scss';
import { submitPublish } from './EnrichmentAsync';

const EnrichmentHeader = ConnectedFC(
  (state) => {
    const { enrichment: { submitting, module }, chrome: { runningJobs } } = state;
    const publishRunning = !!runningJobs.find((j: Document<Job>) => j?.data.metadata?.get('moduleId') === module?.id.id);
    const mappedEnricherFields = module?.data.inputFields.map(f => f.enricherName) || [];
    const unmappedRequiredFields = module?.data.enricherType?.schema?.inputs
      .filter(i => i.required)
      .filter(i => !_.contains(mappedEnricherFields, i.name)) || [];
    return { submitting, publishRunning, inputDataset: module?.data.inputDataset, outputDataset: module?.data.outputDataset, enricherType: module?.data.enricherType, unmappedRequiredFields, displayName: module?.data.displayName };
  },
  { onSubmitPublish: submitPublish },
  ({ onSubmitPublish, submitting, publishRunning, unmappedRequiredFields, inputDataset, outputDataset, enricherType, displayName }) => {
    return (
      <div className={style.headerContainer}>
        <Toolbar
          className="my-8"
          left={(
            <div className={style.titleContainer}>
              <div className={style.title}>
                {displayName} Enrichment
              </div>
              <div className={style.subTitle}>
                The enriched dataset will only include the enriched columns along with the Tamr record ID. To join the enriched columns to your dataset, head over to tranformations in your desired project.
              </div>
            </div>
          )}
          right={(
            <React.Fragment>
              <ConditionalButton
                preconditions={Map<string, boolean>()
                  .set('Job is submitting.', !submitting)
                  .set('Job is running.', !publishRunning)
                  .set('Must map all required inputs.', unmappedRequiredFields.length === 0)
                  .set('Input dataset must be specified.', !!inputDataset)
                  .set('Output dataset must be specified.', !!outputDataset)
                  .set('Enrichment type must be specified.', !!enricherType)
                }
                onClick={onSubmitPublish}
              >
                Run Enrichment
              </ConditionalButton>
            </React.Fragment>
          )}
        />
      </div>
    );
  },
);

export default EnrichmentHeader;
