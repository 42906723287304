import { List, Map, Record, Set } from 'immutable';
import qs from 'query-string';
import _ from 'underscore';

import ClusterChanges, { ClusterChangesE, WITH_CHANGES_FILTERS } from '../constants/ClusterChanges';
import ClusterRecordChanges, { ClusterRecordChangesValueType } from '../constants/ClusterRecordChanges';
import ConfidenceRange, { ConfidenceRangeE } from '../constants/ConfidenceRange';
import DashboardTimeSelector from '../constants/DashboardTimeSelector';
import SidebarTabs from '../constants/SidebarTabs';
import SortState, { SortStateValues, SortStateValueType } from '../constants/SortState';
import ActiveGeospatialAttribute from '../models/ActiveGeospatialAttribute';
import Cluster from '../models/Cluster';
import ConfidenceFilter from '../models/ConfidenceFilter';
import DatasetStatus from '../models/DatasetStatus';
import EsRecord from '../models/EsRecord';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import ScoreThresholds from '../models/ScoreThresholds';
import { StoreReducers } from '../stores/AppAction';
import { AppState } from '../stores/MainStore';
import { DEFAULT_PAGE_SIZE } from '../transactions/TransactionUtils';
import { ArgTypes, checkArg, Checker } from '../utils/ArgValidation';
import ElasticUtils from '../utils/ElasticUtils';
import { routes } from '../utils/Routing';
import { getUnifiedDatasetName } from '../utils/Selectors';
import SortUtils from '../utils/SortUtils';
import { keyModSelect } from '../utils/TableSelection';
import { $TSFixMe } from '../utils/typescript';
import {
  maybeSet,
  parseBoolean,
  parseBooleanOrDefault,
  parseNumber,
  parseSet,
  parseSort,
  parseString,
  parseStringOrDefault,
  parseType,
} from '../utils/Url';
import { getPath, isDefined, resetExcept } from '../utils/Values';
import ClusterMember from './ClusterMember';
import ClusterSimilarity from './ClusterSimilarity';
import ClustersSort, { ClustersSortE } from './ClustersSort';
import Pane, { PaneE } from './Pane';
import PublishedClusterVersions from './PublishedClusterVersions';
import {
  BEGIN_CONFIRMING_ACCEPT_SUGGESTION,
  CLEAR_FILTERS,
  CLEAR_RECORD_FILTERS,
  CLEAR_SELECTED_GEO_ROWS,
  FETCH_ADJACENT_GEOSPATIAL_TRANSACTIONS,
  FETCH_ADJACENT_GEOSPATIAL_TRANSACTIONS_COMPLETED,
  FETCH_ADJACENT_GEOSPATIAL_TRANSACTIONS_FAILED,
  FETCH_GEOSPATIAL_TRANSACTIONS,
  FETCH_GEOSPATIAL_TRANSACTIONS_COMPLETED,
  FETCH_GEOSPATIAL_TRANSACTIONS_FAILED,
  FETCH_INITIAL_GEOSPATIAL_TRANSACTIONS,
  FETCH_INITIAL_GEOSPATIAL_TRANSACTIONS_COMPLETED,
  FETCH_INITIAL_GEOSPATIAL_TRANSACTIONS_FAILED,
  FETCH_TRANSACTIONS_BY_BOUNDS_COMPLETED,
  FETCH_TRANSACTIONS_COMPLETED,
  MOVE_RECORDS_COMPLETED,
  MOVE_RECORDS_TO_NEW,
  MOVE_RECORDS_TO_NEW_COMPLETED,
  MOVE_RECORDS_TO_NEW_FAILED,
  TOGGLE_CLUSTER_HIGH_IMPACT_FILTER,
  TOGGLE_SHOW_GEOSPATIAL_OVERLAY,
  UPDATE_GEOSPATIAL_BOUNDS,
} from './SuppliersActionTypes';
import * as testRecordFiltersSlice from './test-record/filtersSlice';
import VerificationActionConfirmationInfo from './VerificationActionConfirmationInfo';
import {
  ClusterVerificationFilters,
  SuggestionsFilters,
  VerificationFilterTotals,
  VerifiedFilters,
} from './VerificationFilters';

class ClusterBrowser extends getModelHelpers({
  lastFetchData: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(Cluster)), defaultValue: List<Cluster>() },
  data: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(Cluster)), defaultValue: List<Cluster>() },
  selectedSuppliers: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: Set<string>() },
  lastSelectedSupplier: { type: ArgTypes.nullable(ArgTypes.string) },
  total: { type: ArgTypes.number, defaultValue: 0 },
  totalSuppliers: { type: ArgTypes.number, defaultValue: 0 },
  queryString: { type: ArgTypes.string, defaultValue: '' },
  loading: { type: ArgTypes.bool, defaultValue: false },
  saving: { type: ArgTypes.bool, defaultValue: false },
  pageNum: { type: ArgTypes.number, defaultValue: 0 },
  suppliersFilter: { type: ArgTypes.nullable(ArgTypes.string) },
  selectedSourceDatasets: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: Set<string>() },
  sourceDatasetsFilterDialogVisible: { type: ArgTypes.bool, defaultValue: false },
  filterUnresolved: { type: ArgTypes.bool, defaultValue: false },
  filterResolved: { type: ArgTypes.bool, defaultValue: false },
  confidenceFilter: { type: ArgTypes.Immutable.set.of(ConfidenceRange.argType as Checker<ConfidenceRangeE>), defaultValue: Set<ConfidenceRangeE>() },
  hasCustomConfidenceFilter: { type: ArgTypes.bool, defaultValue: false },
  customConfidenceFilter: { type: ArgTypes.instanceOf(ConfidenceFilter), defaultValue: new ConfidenceFilter({ lowerBound: 0, upperBound: 1 }) },
  hasCustomSimilarityFilter: { type: ArgTypes.bool, defaultValue: false },
  customSimilarityFilter: { type: ArgTypes.number, defaultValue: 0.5 },
  loadedFilterInfo: { type: ArgTypes.any },
  suppliersSequence: { type: ArgTypes.number, defaultValue: 0 },
  assignDialogOpen: { type: ArgTypes.bool, defaultValue: false },
  assignDialogSaving: { type: ArgTypes.bool, defaultValue: false },
  mergeClusterId: { type: ArgTypes.nullable(ArgTypes.string) },
  similarSuppliers: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(ClusterSimilarity)), defaultValue: List<ClusterSimilarity>() },
  loadedSimilarSuppliersId: { type: ArgTypes.nullable(ArgTypes.string) },
  loadingSimilarSuppliers: { type: ArgTypes.bool, defaultValue: false },
  pendingResolutionRowNumbers: { type: ArgTypes.Immutable.set.of(ArgTypes.number), defaultValue: Set<number>() },
  changedClusterNames: { type: ArgTypes.Immutable.map.of(ArgTypes.string, ArgTypes.string), defaultValue: Map() as Map<string, string> },
  rows: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(EsRecord)), defaultValue: List<EsRecord>() },
  numRecords: { type: ArgTypes.number, defaultValue: 0 },
  totalSupplierRecords: { type: ArgTypes.number, defaultValue: 0 },
  recordsPageNum: { type: ArgTypes.number, defaultValue: 0 },
  recordsPageSize: { type: ArgTypes.number, defaultValue: DEFAULT_PAGE_SIZE },
  activeRecordId: { type: ArgTypes.nullable(ArgTypes.string) },
  activeRecordClusterMembership: { type: ArgTypes.nullable(ArgTypes.instanceOf(ClusterMember)) },
  activeRecordRelatedCluster: { type: ArgTypes.orUndefined(Cluster.argType) }, // could be suggested cluster, or previously verified cluster
  selectedRecordIds: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: Set<string>() },
  pinnedRecords: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: Set<string>() },
  recordsLoading: { type: ArgTypes.bool, defaultValue: false },
  recordsSaving: { type: ArgTypes.bool, defaultValue: false },
  recordsVerifiedFilters: { type: VerifiedFilters.argType, defaultValue: new VerifiedFilters({}) },
  recordsSuggestionsFilters: { type: SuggestionsFilters.argType, defaultValue: new SuggestionsFilters({}) },
  clusterVerificationFilters: { type: ArgTypes.instanceOf(ClusterVerificationFilters), defaultValue: new ClusterVerificationFilters({}) },
  hasComments: { type: ArgTypes.bool, defaultValue: false },
  recordsSelectedSourceDatasets: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: Set<string>() },
  recordsSourceDatasetsFilterDialogVisible: { type: ArgTypes.bool, defaultValue: false },
  recordsLoadedFilterInfo: { type: ArgTypes.any },
  transactionsSequence: { type: ArgTypes.number, defaultValue: 0 },
  softReload: { type: ArgTypes.bool, defaultValue: false },
  clustersSort: { type: ArgTypes.valueIn(ClustersSort), defaultValue: ClustersSort.RECORDS_MOST_FIRST },
  recordsColumnSortStates: { type: ArgTypes.Immutable.map.of(ArgTypes.valueIn(SortStateValues), ArgTypes.string), defaultValue: Map() as Map<string, SortStateValueType> },
  clusterChanges: { type: ArgTypes.Immutable.set.of(ClusterChanges.argType), defaultValue: Set<ClusterChangesE>() },
  recordChanges: { type: ArgTypes.Immutable.set.of(ClusterRecordChanges.argType), defaultValue: Set<ClusterRecordChangesValueType>() },
  isClusterDiffVisible: { type: ArgTypes.bool, defaultValue: false },
  confirmingVerificationAction: { type: VerificationActionConfirmationInfo.argType, defaultValue: VerificationActionConfirmationInfo.NotConfirming({}) },
  clusterHighImpactFilter: { type: ArgTypes.bool, defaultValue: false },
}, 'ClusterBrowser')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ClusterBrowserRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  reduce(reduceFunction: Function, accumulator: any) {
    Array.from(this.toSeq().entries()).forEach(entry => {
      accumulator = reduceFunction(accumulator, entry[1], entry[0]);
    });
    return accumulator;
  }
}

export class Geospatial extends getModelHelpers({
  activeClusterId: { type: ArgTypes.orNull(ArgTypes.string), defaultValue: null },
  activeClusterName: { type: ArgTypes.orNull(ArgTypes.string), defaultValue: null },
  activeGeospatialRenderingAttribute: { type: ArgTypes.orNull(ArgTypes.string), defaultValue: null },
  adjacentGeoRows: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(EsRecord)), defaultValue: List() },
  currentBounds: { type: ArgTypes.array.of(ArgTypes.array.of(ArgTypes.number)), defaultValue: [] },
  geoRows: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(EsRecord)), defaultValue: List() },
  initialBounds: { type: ArgTypes.array.of(ArgTypes.array.of(ArgTypes.number)), defaultValue: [] },
  initialCenter: { type: ArgTypes.array.of(ArgTypes.number), defaultValue: [] },
  initialTotalGeoFeatures: { type: ArgTypes.number, defaultValue: 0 },
  loadedAdjacentBounds: { type: ArgTypes.array.of(ArgTypes.array.of(ArgTypes.number)), defaultValue: [] },
  loadedBounds: { type: ArgTypes.array.of(ArgTypes.array.of(ArgTypes.number)), defaultValue: [] },
  loadingAdjacentRows: { type: ArgTypes.bool, defaultValue: false },
  loadingBounds: { type: ArgTypes.bool, defaultValue: false },
  loadingRows: { type: ArgTypes.bool, defaultValue: false },
  openMap: { type: ArgTypes.bool, defaultValue: false },
  selectedGeoRows: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(EsRecord)), defaultValue: List<EsRecord>() },
  showGeospatialOverlay: { type: ArgTypes.bool, defaultValue: false },
  totalAdjacentGeoFeatures: { type: ArgTypes.number, defaultValue: 0 },
  totalGeoFeatures: { type: ArgTypes.number, defaultValue: 0 },
}, 'Geospatial')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class GeospatialRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

class SuppliersStore extends getModelHelpers({
  expanded: { type: ArgTypes.bool, defaultValue: false },
  // TODO: move this as well to Geospatial sub-state
  activeGeospatialAttribute: { type: ArgTypes.orNull(ActiveGeospatialAttribute.argType), defaultValue: null },
  sidebarTabKey: { type: ArgTypes.string, defaultValue: SidebarTabs.DETAILS },
  commentFocusSequence: { type: ArgTypes.number, defaultValue: 0 },
  showConflictError: { type: ArgTypes.bool, defaultValue: false },
  totalTestClustersVerified: { type: ArgTypes.number, defaultValue: 0 },
  top: { type: ArgTypes.instanceOf(ClusterBrowser), defaultValue: new ClusterBrowser({}) },
  bottom: { type: ArgTypes.instanceOf(ClusterBrowser), defaultValue: new ClusterBrowser({}) },
  geospatial: { type: ArgTypes.instanceOf(Geospatial), defaultValue: new Geospatial({}) },
  twoPanes: { type: ArgTypes.bool, defaultValue: false },
  focusedPane: { type: ArgTypes.valueIn(Pane), defaultValue: Pane.TOP },
  loadingClusterPublishInfo: { type: ArgTypes.bool, defaultValue: false },
  publishTimes: { type: ArgTypes.Immutable.list.of(ArgTypes.timestamp), defaultValue: List() as List<number> },
  loadedPublishedClusterId: { type: ArgTypes.nullable(ArgTypes.string) },
  publishedClusterVersions: { type: ArgTypes.nullable(PublishedClusterVersions.argType) },
  publishedClustersDatasetStatus: { type: ArgTypes.nullable(DatasetStatus.argType) },
  publishedClustersDatasetStatusLoading: { type: ArgTypes.bool, defaultValue: false },
  recordsGraphTimePeriod: { type: ArgTypes.valueIn(DashboardTimeSelector), defaultValue: DashboardTimeSelector.YEAR },
  recordsVerificationFilterTotals: { type: VerificationFilterTotals.argType, defaultValue: new VerificationFilterTotals({}) },
  loadingActiveRecordClusterMembership: { type: ArgTypes.bool, defaultValue: false },
  loadedActiveRecordClusterMembershipFilterInfo: { type: ArgTypes.any },
  activeRecordClusterMembershipFetchSequence: { type: ArgTypes.number, defaultValue: 1 },
}, 'SuppliersStore')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class SuppliersStoreRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  reduce(reduceFunction: Function, accumulator: any) {
    Array.from(this.toSeq().entries()).forEach(entry => {
      accumulator = reduceFunction(accumulator, entry[1], entry[0]);
    });
    return accumulator;
  }
}

export const initialState = new SuppliersStore({});

/**
 * Returns last publish time or `NaN` if clusters haven't been published for this project.
*/
export const getLastPublishTime = (state: AppState) => {
  const publishedClustersDatasetStatus: DatasetStatus | undefined = getPath(state, 'suppliers', 'publishedClustersDatasetStatus');
  if (publishedClustersDatasetStatus && publishedClustersDatasetStatus.lastMaterializedTimestamp) {
    return publishedClustersDatasetStatus.lastMaterializedTimestamp * 1000;
  }
};

const parseCustomConfidence = (urlString: string) => {
  if (!urlString) {
    return new ConfidenceFilter({
      lowerBound: 0,
      upperBound: 1,
    });
  }
  const filterStringSegments = urlString.split(' ');
  if (filterStringSegments.length !== 2) {
    throw new Error('Confidence url fragment must contain exactly 2 space separated numbers.');
  }
  const lowerBound = parseFloat(filterStringSegments[0]);
  const upperBound = parseFloat(filterStringSegments[1]);
  return new ConfidenceFilter({
    lowerBound,
    upperBound,
  });
};

export const getSelectedIndexes = (suppliers: SuppliersStore, pane: PaneE) => {
  const { [pane]: { data, selectedSuppliers } } = suppliers;
  return selectedSuppliers.map(clusterId => data.findIndex(s => s.clusterId === clusterId));
};

export const getLastSelectClusterIndex = (suppliers: SuppliersStore, pane: PaneE) => {
  const { [pane]: { data, lastSelectedSupplier } } = suppliers;
  return data.findIndex(s => s.clusterId === lastSelectedSupplier);
};

export const getSelectedClusters = (suppliers: SuppliersStore, pane: PaneE): List<Cluster> => {
  const { [pane]: { data, selectedSuppliers } } = suppliers;
  return data.filter(({ clusterId }) => selectedSuppliers.has(clusterId));
};

export interface ClusterFilterInfo {
  suppliersSequence: number
  clusterVerificationFilters: ClusterVerificationFilters
  confidenceFilter: Set<ConfidenceRangeE>
  hasCustomConfidenceFilter: boolean
  customConfidenceFilter: ConfidenceFilter
  hasCustomSimilarityFilter: boolean
  customSimilarityFilter: number
  filterResolved: boolean
  filterUnresolved: boolean
  pageNum: number
  queryString: string
  clustersSort: ClustersSortE
  suppliersFilter: string | null | undefined
  selectedSourceDatasets: Set<string>
  unifiedDatasetName: string | undefined
  clusterConfidenceThresholds: ScoreThresholds | null | undefined
  clusterChanges: Set<ClusterChangesE>
  clusterHighImpactFilter: boolean
}

export const getClustersFilterInfo = (state: AppState, pane: PaneE): ClusterFilterInfo => {
  const { suppliers: { [pane]: { suppliersSequence, clusterVerificationFilters, confidenceFilter, hasCustomConfidenceFilter, customConfidenceFilter, hasCustomSimilarityFilter, customSimilarityFilter, filterResolved, filterUnresolved, pageNum, queryString, suppliersFilter, selectedSourceDatasets, clustersSort, clusterChanges, clusterHighImpactFilter } }, config: { clusterConfidenceThresholds } } = state;
  return { suppliersSequence, clusterVerificationFilters, confidenceFilter, hasCustomConfidenceFilter, customConfidenceFilter, hasCustomSimilarityFilter, customSimilarityFilter, filterResolved, filterUnresolved, pageNum, queryString, clustersSort, suppliersFilter, selectedSourceDatasets, unifiedDatasetName: getUnifiedDatasetName(state), clusterConfidenceThresholds, clusterChanges, clusterHighImpactFilter };
};

export const getAnyClusterFilters = (state: AppState, pane: PaneE) => {
  const paneState = state.suppliers[pane];
  return (
    !!paneState.selectedSourceDatasets.size
    || paneState.filterUnresolved
    || paneState.filterResolved
    || !!paneState.confidenceFilter.size
    || paneState.clusterVerificationFilters.anyActive
    || !!paneState.clusterChanges.size
    || (paneState.hasCustomConfidenceFilter && !!paneState.customConfidenceFilter)
    || paneState.hasCustomSimilarityFilter
    || paneState.clusterHighImpactFilter
  );
};

export const urlifyConfidenceRangeFilter = (confidenceRangeFilter: ConfidenceRangeE | undefined, confidenceThresholds: ScoreThresholds | undefined): string | undefined => {
  checkArg({ confidenceRangeFilter }, ArgTypes.nullable(ConfidenceRange.argType));
  checkArg({ confidenceThresholds }, ArgTypes.nullable(ArgTypes.instanceOf(ScoreThresholds)));
  if (!confidenceRangeFilter || !confidenceThresholds) {
    return undefined;
  }
  let confidenceRange;
  if (confidenceRangeFilter) {
    switch (confidenceRangeFilter) {
      case ConfidenceRange.LOW:
        confidenceRange = confidenceThresholds.lowRange;
        break;
      case ConfidenceRange.MEDIUM:
        confidenceRange = confidenceThresholds.mediumRange;
        break;
      case ConfidenceRange.HIGH:
        confidenceRange = confidenceThresholds.highRange;
        break;
      default:
        console.error('unknown confidenceRange value: ', confidenceRange);
    }
  }
  return confidenceRange;
};

const clustersSortToMap = (clustersSort: ClustersSortE): Map<string, SortStateValueType> => {
  checkArg({ clustersSort }, ArgTypes.valueIn(ClustersSort));
  switch (clustersSort) {
    case ClustersSort.NAME:
      return Map({ name: SortState.SORTED_ASCENDING });
    case ClustersSort.NAME_REVERSE:
      return Map({ name: SortState.SORTED_DESCENDING });
    case ClustersSort.RECORDS_MOST_FIRST:
      return Map({ recordCount: SortState.SORTED_DESCENDING });
    case ClustersSort.RECORDS_LEAST_FIRST:
      return Map({ recordCount: SortState.SORTED_ASCENDING });
    case ClustersSort.SPEND_HIGHEST_FIRST:
      return Map({ totalSpend: SortState.SORTED_DESCENDING });
    case ClustersSort.SPEND_LOWEST_FIRST:
      return Map({ totalSpend: SortState.SORTED_ASCENDING });
    default:
      return Map();
  }
};

export const getClustersSort = (state: AppState, pane: PaneE) => {
  const { suppliers: { [pane]: { clustersSort } } } = state;
  return clustersSortToMap(clustersSort);
};

const clearClusterFilters = (cb: ClusterBrowser) => {
  return cb
    .delete('selectedSourceDatasets')
    .delete('filterResolved')
    .delete('filterUnresolved')
    .delete('clusterChanges')
    .delete('confidenceFilter')
    .delete('hasCustomConfidenceFilter')
    .delete('customConfidenceFilter')
    .delete('hasCustomSimilarityFilter')
    // Don't delete `customSimilarityFilter` since its not set in cluster filters. It's only toggled on and off there.
    .delete('clusterVerificationFilters')
    .delete('suppliersFilter')
    .delete('lastSelectedSupplier')
    .delete('pageNum')
    .delete('recordsPageNum')
    .delete('clusterHighImpactFilter');
};

const clearClusterRecordFilters = (cb: ClusterBrowser) => {
  return cb
    .delete('recordsVerifiedFilters')
    .delete('recordsSuggestionsFilters')
    .delete('recordsSelectedSourceDatasets')
    .delete('hasComments')
    .delete('recordChanges');
};

const isClusterDiffEnabled = (selectedSuppliers: Set<string>) => {
  return selectedSuppliers.size === 1;
};

const deriveClusterDiffVisible = (state: ClusterBrowser, selectedSuppliers: Set<string>) => {
  // whenever there is not exactly 1 cluster selected, we want to disable the Cluster Diff
  // BUT we also want the Cluster Diff not to be on next time we do only have 1 cluster selected
  // so we reset the Cluster Diff to be inactive whenever it gets disabled
  // disabled: greyed out (grey button w/ grey icon)
  // inactive: enabled but not currently active (white button w/ blue icon)
  // active: enabled and currently active (blue-ish button w/ blue icon)
  return isClusterDiffEnabled(selectedSuppliers) ? state.get('isClusterDiffVisible') : false;
};

const filterToOne = (state: ClusterBrowser, clusterId: string) => {
  const selectedSuppliers = Set([clusterId]);
  return clearClusterFilters(state)
    .delete('loadedSimilarSuppliersId')
    .merge({
      suppliersFilter: clusterId,
      selectedSuppliers,
      lastSelectedSupplier: clusterId,
      isClusterDiffVisible: deriveClusterDiffVisible(state, selectedSuppliers),
    });
};

const getFilteredClusterCounts = ({
  filterInfo,
  total,
  totalSuppliers,
}: {
  filterInfo: ClusterFilterInfo,
  total: number,
  totalSuppliers: number
}) => {
  checkArg({ total }, ArgTypes.number);
  checkArg({ totalSuppliers }, ArgTypes.number);
  return (!filterInfo.selectedSourceDatasets.isEmpty()
    || filterInfo.suppliersFilter
    || filterInfo.clusterVerificationFilters.anyActive
    || filterInfo.clusterChanges
    || filterInfo.confidenceFilter
    || (filterInfo.hasCustomConfidenceFilter && filterInfo.customConfidenceFilter)
    || (filterInfo.hasCustomSimilarityFilter)
    || filterInfo.filterResolved
    || filterInfo.filterUnresolved
    || filterInfo.queryString)
    ? { total, totalSuppliers: Math.max(total, totalSuppliers) }
    : { total: totalSuppliers, totalSuppliers };
};

const transformChangedClusterIds = (state: ClusterBrowser, clusterIdTransform: Map<string, string>) => {
  checkArg({ clusterIdTransform }, ArgTypes.Immutable.map.of(ArgTypes.string, ArgTypes.string));

  let updatedState = state;
  updatedState = updatedState.update('data', data => data.map(
    (cluster) => cluster.withId(clusterIdTransform.get(cluster.clusterId, cluster.clusterId))),
  );

  updatedState = updatedState.update('selectedSuppliers', selectedSuppliers =>
    selectedSuppliers.map(id => clusterIdTransform.get(id, id)));

  return updatedState;
};

const resolveWithServerState = (state: SuppliersStore, {
  currentPage,
  affectedIds,
  affectedClusters,
  total,
  totalSuppliers,
  filterInfo,
  pane,
}: {
  currentPage: List<Cluster>,
  affectedIds: Set<string>,
  affectedClusters: Set<Cluster>,
  total: number,
  totalSuppliers: number,
  filterInfo: ClusterFilterInfo,
  pane: PaneE
}) => {
  checkArg({ currentPage }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(Cluster)));
  checkArg({ affectedIds }, ArgTypes.Immutable.set.of(ArgTypes.string));
  checkArg({ affectedClusters }, ArgTypes.Immutable.set.of(ArgTypes.instanceOf(Cluster)));
  checkArg({ total }, ArgTypes.number);
  checkArg({ totalSuppliers }, ArgTypes.number);

  // remove clusters that no longer exist (they would be in affected clusters if they exist)
  return state.update(pane, s => {
    let updatedState = s;
    updatedState = updatedState.update('data', data => data.filterNot(oldCluster => !!affectedIds.find(id => id === oldCluster.clusterId) && !affectedClusters.find(c => c.clusterId === oldCluster.clusterId)));

    // remove selections for clusters that no longer exist
    const selectedSuppliers = updatedState.get('selectedSuppliers').filter(clusterId => updatedState.data.find(c => c.clusterId === clusterId));
    updatedState = updatedState.merge({
      selectedSuppliers,
      isClusterDiffVisible: deriveClusterDiffVisible(updatedState, selectedSuppliers),
    });

    // determine whether the server thinks any cluster names have changed
    type NameChange = { oldName: string, newName: string };
    const changedNames = Map(updatedState.data.map((oldCluster): [string, NameChange] | undefined => {
      const newCluster = currentPage.find(c => c.clusterId === oldCluster.clusterId) || affectedClusters.find(c => c.clusterId === oldCluster.clusterId);
      if (!newCluster || newCluster.name === oldCluster.name) {
        return undefined;
      }
      return [oldCluster.clusterId, { oldName: oldCluster.name, newName: newCluster.name }];
    }).filter(isDefined));
    changedNames.forEach(({ newName, oldName }: NameChange, id: string) => {
      updatedState = updatedState.updateIn(['data', updatedState.data.findIndex(c => c.clusterId === id)], c => c.set('name', newName));
      updatedState = updatedState.setIn(['changedClusterNames', id], oldName);
    });

    // swap out clusters in memory with clusters retrieved from server
    updatedState = updatedState.update('data', data => data.map((cluster) => {
      const affectedCluster = affectedClusters.find(c => c.clusterId === cluster.clusterId);
      if (affectedCluster) {
        return affectedCluster;
      }
      const currentPageCluster = currentPage.find(c => c.clusterId === cluster.clusterId);
      return currentPageCluster || cluster;
    }));


    // update total
    updatedState = updatedState.merge(getFilteredClusterCounts({ filterInfo, total, totalSuppliers }));

    // check for filter violations
    const clusterIds = updatedState.data.map(c => c.clusterId).toSet();
    const currentPageIds = currentPage.map(c => c.clusterId);
    const violatingClusterIds = clusterIds.subtract(currentPageIds);
    const nonViolatingClusterIds = clusterIds.subtract(violatingClusterIds);
    const rowNumbersToAddToPending = updatedState.data.entrySeq()
      .filter(entry => violatingClusterIds.includes(entry[1].clusterId))
      .map(entry => entry[0]);
    const rowNumbersToRemoveFromPending = s.data.entrySeq()
      .filter(entry => nonViolatingClusterIds.includes(entry[1].clusterId))
      .map(entry => entry[0]);
    updatedState = updatedState.update('pendingResolutionRowNumbers', p => p.union(rowNumbersToAddToPending).subtract(rowNumbersToRemoveFromPending));

    return updatedState.merge({ loading: false }).delete('loadedSimilarSuppliersId');
  });
};

const getActiveClusterId = (state: AppState, pane: PaneE) => {
  const { suppliers: { [pane]: { lastSelectedSupplier, selectedSuppliers } } } = state;
  return selectedSuppliers.find(id => id === lastSelectedSupplier);
};

export const getActiveCluster = (state: AppState, pane: PaneE): Cluster | undefined => {
  const { suppliers: { [pane]: { data } } } = state;
  const clusterId = getActiveClusterId(state, pane);
  return clusterId ? data.find(cluster => cluster.clusterId === clusterId) : undefined;
};

export const getActiveRecord = (state: AppState, pane: PaneE) => {
  const { suppliers: { [pane]: { rows, activeRecordId } } } = state;
  return rows.find(r => r.recordId === activeRecordId);
};

export const getActiveRecordIndex = (state: { suppliers: SuppliersStore }, pane: PaneE) => {
  const { suppliers: { [pane]: { rows, activeRecordId } } } = state;
  return rows.findIndex(r => r.recordId === activeRecordId);
};

export const getSelectedRecords = (state: AppState, pane: PaneE) => {
  const { suppliers: { [pane]: { rows, selectedRecordIds } } } = state;
  return rows.filter(r => selectedRecordIds.has(r.recordId));
};

export const getSelectedRecordIndexes = (state: { suppliers: SuppliersStore }, pane: PaneE) => {
  const { suppliers: { [pane]: { rows, selectedRecordIds } } } = state;
  return selectedRecordIds.map(id => rows.findIndex(r => r.recordId === id));
};

const RecordsFilterInfo = Record({
  isClusterDiffVisible: undefined as unknown as boolean,
  recordsVerifiedFilters: undefined as unknown as VerifiedFilters,
  recordsSuggestionsFilters: undefined as unknown as SuggestionsFilters,
  transactionsSequence: undefined as unknown as number,
  selectedSuppliers: undefined as unknown as Set<string>,
  queryString: undefined as unknown as string,
  recordsSelectedSourceDatasets: undefined as unknown as Set<string>,
  pageNum: undefined as unknown as number,
  pageSize: undefined as unknown as number,
  columnSortStates: undefined as unknown as Map<string, SortStateValueType>,
  pinnedRecords: undefined as unknown as Set<string>,
  hasComments: undefined as unknown as boolean,
  unifiedDatasetName: undefined as unknown as string,
  recordChanges: undefined as unknown as Set<$TSFixMe>,
  testRecordFilters: undefined as unknown as testRecordFiltersSlice.State,
});

export type RecordsFilterInfo = ReturnType<typeof RecordsFilterInfo>

export const getRecordsFilterInfo = (state: AppState, pane: PaneE) => {
  const {
    hasComments,
    isClusterDiffVisible,
    pinnedRecords,
    queryString,
    recordChanges,
    recordsColumnSortStates,
    recordsPageNum,
    recordsPageSize,
    recordsSelectedSourceDatasets,
    recordsSuggestionsFilters,
    recordsVerifiedFilters,
    selectedSuppliers,
    transactionsSequence,
  } = state.suppliers[pane];
  const { testRecord } = state.clusters[pane];
  return new RecordsFilterInfo({
    columnSortStates: recordsColumnSortStates,
    hasComments,
    isClusterDiffVisible,
    pageNum: recordsPageNum,
    pageSize: recordsPageSize,
    pinnedRecords,
    queryString,
    recordChanges,
    recordsSelectedSourceDatasets,
    recordsSuggestionsFilters,
    recordsVerifiedFilters,
    selectedSuppliers,
    testRecordFilters: testRecord.filters,
    transactionsSequence,
    unifiedDatasetName: getUnifiedDatasetName(state),
  });
};

export const getAnyRecordsFilters = (state: AppState, pane: PaneE) => {
  const legacyPaneState = state.suppliers[pane];
  const rtkPaneState = state.clusters[pane];

  return (
    !!legacyPaneState.recordsSelectedSourceDatasets.size
    || legacyPaneState.hasComments
    || !!legacyPaneState.recordChanges.size
    || legacyPaneState.recordsVerifiedFilters.anyActive
    || legacyPaneState.recordsSuggestionsFilters.anyActive
    || testRecordFiltersSlice.anyActive(rtkPaneState.testRecord.filters)
  );
};

const ActiveRecordClusterMembershipFilterInfo = Record({
  focusedPane: undefined as PaneE | undefined,
  activeRecordId: undefined as string | null | undefined,
  activeRecordClusterMembershipFetchSequence: undefined as number | undefined,
});
export const getActiveRecordClusterMembershipFilterInfo = (state: AppState) => {
  const { suppliers, suppliers: { focusedPane, activeRecordClusterMembershipFetchSequence } } = state;
  const { activeRecordId } = suppliers[focusedPane];
  return new ActiveRecordClusterMembershipFilterInfo({ focusedPane, activeRecordId, activeRecordClusterMembershipFetchSequence });
};

const updateBoth = (state: SuppliersStore, fn: (browser: ClusterBrowser) => ClusterBrowser) => state.update('top', fn).update('bottom', fn);
const reloadSuppliers = (state: ClusterBrowser) => state.update('suppliersSequence', x => x + 1);
const reloadRecords = (state: ClusterBrowser) => state.update('transactionsSequence', x => x + 1);
// @ts-expect-error immutableUpdateWithOneArgument
const reloadAll = (state: ClusterBrowser) => state.update(reloadSuppliers).update(reloadRecords);
const softReloadSuppliers = (state: ClusterBrowser) => state.set('softReload', true);
const reloadSuppliersBoth = (state: SuppliersStore) => updateBoth(state, reloadSuppliers);
const softReloadBoth = (state: SuppliersStore) => updateBoth(state, softReloadSuppliers);
const reloadAllBoth = (state: SuppliersStore) => updateBoth(state, reloadAll);
const reloadRecordsBoth = (state: SuppliersStore) => updateBoth(state, reloadRecords);
const clearSelection = (state: ClusterBrowser) => state.delete('selectedRecordIds').delete('activeRecordId').delete('activeRecordClusterMembership');
const fetchVerificationFilterTotals = (state: ClusterBrowser) => state.updateIn(['recordsVerificationFilterTotals', 'fetchTriggers', 'sequence'], s => s + 1);
const refetchClusterMembership = (state: SuppliersStore) => state.update('activeRecordClusterMembershipFetchSequence', x => x + 1);
const refetchEverything = (state: SuppliersStore) => state
  // @ts-expect-error immutableUpdateWithOneArgument
  .update(reloadAllBoth)
  // @ts-expect-error immutableUpdateWithOneArgument
  .update(fetchVerificationFilterTotals)
  // @ts-expect-error immutableUpdateWithOneArgument
  .update(refetchClusterMembership);

const otherPane = (pane: PaneE): PaneE => {
  return pane === Pane.TOP ? Pane.BOTTOM : Pane.TOP;
};

const fullReset = (state: SuppliersStore, { pane }: { pane: PaneE }) => state.update(pane, s => resetExcept(s, ['loading', 'recordsLoading']));

export const reducers: StoreReducers<SuppliersStore> = {
  'Suppliers.setQueryString': (state, { queryString, pane }) => {
    return state.mergeIn([pane], { queryString, pageNum: 0 });
  },

  'Suppliers.reload': (state, { pane }) => {
    return state.update(pane, s => reloadRecords(reloadSuppliers(s)));
  },

  'Suppliers.reloadBoth': (state) => {
    return reloadSuppliersBoth(reloadRecordsBoth(state));
  },

  'Suppliers.setSuppliersPage': (state, { pageNum, pane }) => {
    return state.mergeIn([pane], { pageNum });
  },

  'Suppliers.setSuppliersFilter': (state, { supplierId, pane }) => {
    return state.update(pane, s => filterToOne(s, supplierId));
  },

  'Suppliers.clearSuppliersFilter': (state, { pane }) => {
    return state.deleteIn([pane, 'suppliersFilter']);
  },

  'Suppliers.selectAllSupplierRows': (state, { pane }) => {
    return state.update(pane, s => {
      const selectedSuppliers = s.get('selectedSuppliers').size === s.data.size ?
        Set() : s.data.map(r => r.clusterId).toSet();
      return s.merge({
        selectedSuppliers,
        isClusterDiffVisible: deriveClusterDiffVisible(s, selectedSuppliers),
      });
    });
  },

  'Suppliers.selectSupplierRow': (state, { keyMods, selectedSupplierRow, pane }) => {
    const { selectedRows: selectedSupplierRows, lastSelectedRow: newLastSelectedRow } = keyModSelect({
      keyMods,
      selectedRows: getSelectedIndexes(state, pane),
      lastSelectedRow: state[pane].data.findIndex(s => s.clusterId === state[pane].lastSelectedSupplier),
      selectedRow: selectedSupplierRow,
    });
    const selectedSuppliers = selectedSupplierRows.map(i => state[pane].data.get(i)?.clusterId).filter(isDefined);
    const lastSelectedSupplier = newLastSelectedRow !== undefined ? state[pane].data.get(newLastSelectedRow) : undefined;
    return state.set('focusedPane', pane).update(pane, s => {
      return s.merge({
        selectedSuppliers,
        isClusterDiffVisible: deriveClusterDiffVisible(s, selectedSuppliers),
        lastSelectedSupplier: lastSelectedSupplier ? lastSelectedSupplier.clusterId : undefined,
        recordsPageNum: 0,
      }).delete('loadedSimilarSuppliersId');
    });
  },

  'Suppliers.startDragCluster': (state, { clusterId, pane }) => {
    const currentSelectedSuppliers = state.getIn([pane, 'selectedSuppliers']);
    const selectedSuppliers = currentSelectedSuppliers.has(clusterId) ? currentSelectedSuppliers : Set([clusterId]);
    return state.mergeIn([pane], {
      selectedSuppliers,
      isClusterDiffVisible: deriveClusterDiffVisible(state.get(pane), selectedSuppliers),
    });
  },

  'Suppliers.setSelectedSourceDatasets': (state, { pane, datasetsToAdd, datasetsToRemove }) => {
    checkArg({ datasetsToAdd }, ArgTypes.Immutable.set.of(ArgTypes.string));
    checkArg({ datasetsToRemove }, ArgTypes.Immutable.set.of(ArgTypes.string));
    return state.setIn([pane, 'sourceDatasetsFilterDialogVisible'], false).updateIn([pane, 'selectedSourceDatasets'], currentSelection => currentSelection.union(datasetsToAdd).subtract(datasetsToRemove));
  },

  'Suppliers.setSourceDatasetsDialogVisible': (state, { pane, show }) => {
    return state.setIn([pane, 'sourceDatasetsFilterDialogVisible'], show);
  },

  'Suppliers.toggleFilterUnresolved': (state, { pane }) => {
    return state.update(pane, s => s.update('filterUnresolved', b => !b).set('pageNum', 0));
  },

  'Suppliers.toggleFilterResolved': (state, { pane }) => {
    return state.update(pane, s => s.update('filterResolved', b => !b).set('pageNum', 0));
  },

  'Suppliers.toggleConfidenceFilter': (state, { pane, filterOption }) => {
    checkArg({ filterOption }, ConfidenceRange.argType);
    return state.updateIn([pane, 'confidenceFilter'], currentConfidenceFilter => {
      return currentConfidenceFilter.has(filterOption) ? currentConfidenceFilter.delete(filterOption) : currentConfidenceFilter.add(filterOption);
    }).deleteIn([pane, 'pageNum']);
  },

  'Suppliers.setClusterChangesFilter': (state, { pane, clusterChanges, clear }) => {
    checkArg({ pane }, ArgTypes.string);
    checkArg({ clusterChanges }, ArgTypes.Immutable.set.of(ClusterChanges.argType));
    checkArg({ clear }, ArgTypes.bool);
    let modState;
    if (clear) {
      modState = state.update(pane, clearClusterFilters);
    } else {
      modState = state;
    }
    return modState.mergeIn([pane], { clusterChanges }).deleteIn([pane, 'pageNum']);
  },

  'Suppliers.toggleClusterChangesFilter': (state, { pane, filterOption }) => {
    checkArg({ filterOption }, ClusterChanges.argType);
    return state.updateIn([pane, 'clusterChanges'], current => {
      return current.has(filterOption) ? current.delete(filterOption) : current.add(filterOption);
    }).deleteIn([pane, 'pageNum']);
  },

  'Suppliers.toggleWithChangesFilter': (state, { pane }) => {
    return state.updateIn([pane, 'clusterChanges'], current => {
      // if all of the "with changes" options are selected, make them all unselected
      if (WITH_CHANGES_FILTERS.every(f => current.has(f))) {
        return current.subtract(WITH_CHANGES_FILTERS);
      }
      // if none or some of the "with changes" options are selected, make them all selected
      return current.union(WITH_CHANGES_FILTERS);
    }).deleteIn([pane, 'pageNum']);
  },

  'Suppliers.setMyAssignments': (state, { pane }) => {
    return state.updateIn([pane], clearClusterFilters).setIn([pane, 'filterUnresolved'], true).deleteIn([pane, 'pageNum']);
  },

  'Suppliers.setClustersWithChanges': (state, { pane }) => {
    return state.updateIn([pane], clearClusterFilters).setIn([pane, 'clusterChanges'], WITH_CHANGES_FILTERS).deleteIn([pane, 'pageNum']);
  },

  'Suppliers.toggleCustomConfidenceFilter': (state, { pane, value }) => {
    checkArg({ value }, ArgTypes.bool);
    return state.setIn([pane, 'hasCustomConfidenceFilter'], value)
      .deleteIn([pane, 'pageNum']);
  },

  'Suppliers.setCustomConfidenceFilter': (state, { pane, customConfidenceFilter }) => {
    checkArg({ customConfidenceFilter }, ArgTypes.instanceOf(ConfidenceFilter));
    return state.setIn([pane, 'customConfidenceFilter'], customConfidenceFilter)
      .deleteIn([pane, 'pageNum']);
  },

  'Suppliers.toggleCustomSimilarityFilter': (state, { pane, value }) => {
    checkArg({ value }, ArgTypes.bool);
    return state.setIn([pane, 'hasCustomSimilarityFilter'], value)
      .deleteIn([pane, 'pageNum']);
  },

  'Suppliers.setCustomSimilarityFilter': (state, { pane, customSimilarityFilter }) => {
    checkArg({ customSimilarityFilter }, ArgTypes.number);
    return state.setIn([pane, 'customSimilarityFilter'], customSimilarityFilter);
  },

  [CLEAR_FILTERS]: (state, { pane }) => {
    const panes = List(pane ? [pane] : ['top', 'bottom']);
    return panes.reduce(
      (reduction, p: PaneE) => reduction.update(p, clearClusterFilters),
      state,
    );
  },

  [CLEAR_RECORD_FILTERS]: (state, { pane, clearSearch }) => {
    const panes = List(pane ? [pane] : ['top', 'bottom']);
    return panes.reduce(
      (reduction, p: PaneE) => reduction.update(p, clusterBrowser => {
        const withClearedFilters = clearClusterRecordFilters(clusterBrowser);
        return clearSearch ? withClearedFilters.delete('queryString') : withClearedFilters;
      }),
      state,
    );
  },

  'Location.projectChange': (state) => {
    // @ts-expect-error immutableUpdateWithOneArgument
    return state.update(s => resetExcept(s, ['top', 'bottom']))
      // @ts-expect-error immutableUpdateWithOneArgument
      .update(s => fullReset(s, { pane: 'top' }))
      // @ts-expect-error immutableUpdateWithOneArgument
      .update(s => fullReset(s, { pane: 'bottom' }));
  },

  'Location.change': (state, { location }) => {
    if (routes.suppliers.match(location.pathname)) {
      const params = qs.parse(location.search);
      // @ts-expect-error
      return state.update('top', _.compose(
        maybeSet('recordsColumnSortStates', parseSort(params.sort)),
        maybeSet('clustersSort', (params.sSort || state.top.clustersSort)),
        maybeSet('queryString', parseString(params.queryString)),
        maybeSet('pageNum', parseNumber(params.sPageNum)),
        maybeSet('suppliersFilter', parseString(params.sSuppliersFilter)),
        maybeSet('selectedSuppliers', parseSet(parseString)(params.suppliers)),
        maybeSet('filterResolved', parseBoolean(params.filterResolved)),
        maybeSet('filterUnresolved', parseBoolean(params.filterUnresolved)),
        maybeSet('clusterVerificationFilters', ClusterVerificationFilters.newWithDefaults({
          hasVerifiedFilterState: parseStringOrDefault(undefined)(params.sHasVerifiedFilterState),
          hasVerifiedElsewhere: parseBooleanOrDefault(undefined)(params.sHasVerifiedElsewhere),
          hasNoneVerified: parseBooleanOrDefault(undefined)(params.sHasNoneVerified),
        })),
        maybeSet('clusterChanges', parseSet(parseType(ClusterChanges.argType))(params.clusterChanges)),
        maybeSet('confidenceFilter', parseSet(parseType(ConfidenceRange.argType))(params.confidenceFilter)),
        maybeSet('customConfidenceFilter', parseCustomConfidence(params.customConfidenceFilter)),
        maybeSet('hasCustomConfidenceFilter', parseBoolean(params.hasCustomConfidenceFilter)),
        maybeSet('customSimilarityFilter', parseNumber(params.customSimilarityFilter)),
        maybeSet('hasCustomSimilarityFilter', parseBoolean(params.hasCustomSimilarityFilter)),
        maybeSet('selectedSourceDatasets', parseSet(parseString)(params.selectedSourceDatasets)),
        maybeSet('recordsPageNum', parseNumber(params.pageNum)),
        maybeSet('recordsPageSize', parseNumber(params.pageSize)),
        maybeSet('pinnedRecords', parseSet(parseString)(params.pinnedRecords)),
        maybeSet('lastSelectedSupplier', parseString(params.lastSelectedSupplier)),
        // @ts-expect-error immutableUpdateWithOneArgument
      )).update(reloadAllBoth)
        .mergeIn(['geospatial'], { openMap: false });
    }
    return state;
  },

  'Suppliers.fetchSuppliers': (state, { pane }) => {
    return state.mergeIn([pane], { loading: true, softReload: false });
  },

  'Suppliers.fetchSuppliersCompleted': (state, { clusters: data, total, totalSuppliers, filterInfo, pane }) => {
    const ids = data.map(({ clusterId }) => clusterId).toSet();
    return state.update(pane, s => {
      const selectedSuppliers = s.get('selectedSuppliers').filter(id => ids.has(id));
      return s
        .merge({
          data,
          lastFetchData: data,
          loading: false,
          ...getFilteredClusterCounts({ filterInfo, total, totalSuppliers }),
          loadedFilterInfo: filterInfo,
          selectedSuppliers,
          isClusterDiffVisible: deriveClusterDiffVisible(s, selectedSuppliers),
        })
        .delete('loadedSimilarSuppliersId')
        .update('lastSelectedSupplier', id => (id && ids.has(id) ? id : null))
        .delete('pendingResolutionRowNumbers')
        .delete('changedClusterNames');
    });
  },

  'Suppliers.fetchSuppliersFailed': (state, { filterInfo, pane }) => {
    return state.mergeIn([pane], { loading: false, loadedFilterInfo: filterInfo });
  },

  'Suppliers.beginConfirmingMergeClusters': (state, { clusters, pane }) => {
    return state.mergeIn([pane], { confirmingVerificationAction: VerificationActionConfirmationInfo.MergeClusters({ clusters }) });
  },
  'Suppliers.beginConfirmingMergeSelectedClustersToTarget': (state, { targetClusterId, pane, fromPane }) => {
    checkArg({ pane }, ArgTypes.valueIn(Pane));
    checkArg({ fromPane }, ArgTypes.valueIn(Pane));
    checkArg({ targetClusterId }, ArgTypes.string);
    const clusters = getSelectedClusters(state, fromPane);
    const targetCluster = state[pane].data.find(cluster => cluster.clusterId === targetClusterId);

    if (!targetCluster) {
      console.error('Unable to find target cluster');
      return state;
    }

    return state.mergeIn([pane], { confirmingVerificationAction: VerificationActionConfirmationInfo.MergeClustersToTarget({ clusters, targetCluster, fromPane }) });
  },
  'Suppliers.beginConfirmingMergeClustersToTarget': (state, { pane, clusters, targetCluster, fromPane }) => {
    return state.mergeIn([pane], { confirmingVerificationAction: VerificationActionConfirmationInfo.MergeClustersToTarget({ clusters, targetCluster, fromPane }) });
  },
  'Suppliers.cancelVerificationAction': (state, { pane }) => {
    return state.deleteIn([pane, 'confirmingVerificationAction']);
  },

  'Suppliers.mergeClusters': (state, { pane }) => {
    return state.setIn([pane, 'saving'], true)
      .deleteIn([pane, 'confirmingVerificationAction']);
  },

  'Suppliers.mergeClustersCompleted': (state, { pane }) => {
    return reloadRecordsBoth(softReloadBoth(state)).update(pane, s => {
      return fetchVerificationFilterTotals(transformChangedClusterIds(s, Map() /* TODO remove clusterIdTransform considerations entirely */).set('saving', false));
    });
  },

  'Suppliers.mergeClustersFailed': (state, { pane }) => {
    return state.mergeIn([pane], { saving: false });
  },

  'Suppliers.verifyClusters': (state, { pane }) => {
    return state.mergeIn([pane], { loading: true })
      // @ts-expect-error immutableUpdateWithOneArgument
      .update(refetchClusterMembership);
  },
  'Suppliers.verifyClustersCompleted': (state, { pane }) => {
    return reloadRecordsBoth(reloadSuppliersBoth(state.mergeIn([pane], { loading: false })))
      // @ts-expect-error immutableUpdateWithOneArgument
      .update(fetchVerificationFilterTotals);
  },
  'Suppliers.verifyClustersFailed': (state, { pane }) => {
    return state.mergeIn([pane], { loading: false });
  },

  'Suppliers.openAssignDialog': (state, { pane }) => {
    return state.mergeIn([pane], { assignDialogOpen: true });
  },

  'Suppliers.closeAssignDialog': (state, { pane }) => {
    return state.mergeIn([pane], { assignDialogOpen: false });
  },

  'Suppliers.assignClusters': (state, { pane }) => {
    return state.mergeIn([pane], { assignDialogSaving: true });
  },

  'Suppliers.assignClustersCompleted': (state, { pane }) => {
    return softReloadBoth(state).update(pane, s => {
      return s.merge({ assignDialogSaving: false, assignDialogOpen: false });
    });
  },

  'Suppliers.assignClustersFailed': (state, { pane }) => {
    return state.mergeIn([pane], { assignDialogSaving: false });
  },

  'Suppliers.resolveClusters': (state, { pane }) => {
    return state.mergeIn([pane], { saving: true });
  },

  'Suppliers.resolveClustersCompleted': (state, { pane }) => {
    return softReloadBoth(state).update(pane, s => {
      return s.set('saving', false);
    });
  },

  'Suppliers.resolveClustersFailed': (state, { pane }) => {
    return state.mergeIn([pane], { saving: false });
  },

  [BEGIN_CONFIRMING_ACCEPT_SUGGESTION]: (state, { pane, record, clusterId }) => {
    return state.mergeIn([pane], { confirmingVerificationAction: VerificationActionConfirmationInfo.AcceptActiveRecordSuggestion({ record, clusterId }) });
  },
  'Suppliers.beginConfirmingMoveRecords': (state, { pane, fromPane, clusterId }) => {
    checkArg({ pane }, ArgTypes.valueIn(Pane));
    checkArg({ fromPane }, ArgTypes.valueIn(Pane));
    checkArg({ clusterId }, ArgTypes.string);
    return state.mergeIn([pane], { confirmingVerificationAction: VerificationActionConfirmationInfo.MoveRecordsToExistingCluster({ fromPane, clusterId }) });
  },
  'Suppliers.moveRecords': (state, { pane }) => {
    return state.mergeIn([pane], { saving: true, recordsSaving: true })
      .deleteIn([pane, 'confirmingVerificationAction']);
  },
  [MOVE_RECORDS_COMPLETED]: (state, { pane }) => {
    return reloadRecordsBoth(softReloadBoth(state)).update(pane, s => {
      return s.merge({ saving: false, recordsSaving: false });
      // @ts-expect-error immutableUpdateWithOneArgument
    }).update(fetchVerificationFilterTotals)
      // @ts-expect-error immutableUpdateWithOneArgument
      .update(refetchClusterMembership);
  },
  'Suppliers.moveRecordsFailed': (state, { pane }) => {
    return state.mergeIn([pane], { saving: false, recordsSaving: false });
  },
  'Suppliers.beginConfirmingMoveRecordsToNew': (state, { pane }) => {
    return state.mergeIn([pane], { confirmingVerificationAction: VerificationActionConfirmationInfo.MoveRecordsToNewCluster({}) });
  },
  [MOVE_RECORDS_TO_NEW]: (state, { pane }) => {
    return state.mergeIn([pane], { saving: true, recordsSaving: true })
      .deleteIn([pane, 'confirmingVerificationAction']);
  },
  [MOVE_RECORDS_TO_NEW_COMPLETED]: (state, { pane }) => {
    return reloadRecordsBoth(softReloadBoth(state))
      .update(pane, s => s.merge({ saving: false, recordsSaving: false }))
      // @ts-expect-error immutableUpdateWithOneArgument
      .update(fetchVerificationFilterTotals)
      // @ts-expect-error immutableUpdateWithOneArgument
      .update(refetchClusterMembership);
  },
  [MOVE_RECORDS_TO_NEW_FAILED]: (state, { pane }) => {
    return state.mergeIn([pane], { saving: false, recordsSaving: false });
  },

  'Suppliers.verifyRecords': (state, { pane }) => {
    return state.mergeIn([pane], { recordsSaving: true });
  },
  'Suppliers.verifyRecordsCompleted': (state, { pane }) => {
    return reloadRecordsBoth(reloadSuppliersBoth(state)).update(pane, s => clearSelection(s).set('recordsSaving', false))
      // @ts-expect-error immutableUpdateWithOneArgument
      .update(fetchVerificationFilterTotals)
      // @ts-expect-error immutableUpdateWithOneArgument
      .update(refetchClusterMembership);
  },

  'Jobs.trainPredictClusterCompleted': refetchEverything,
  'Jobs.predictClusterCompleted': refetchEverything,

  'Suppliers.fetchSimilarSuppliers': (state, { pane }) => {
    return state.mergeIn([pane], { loadingSimilarSuppliers: true });
  },

  'Suppliers.fetchSimilarSuppliersCompleted': (state, { supplierId, similarSuppliers, pane }) => {
    return state.mergeIn([pane], {
      loadingSimilarSuppliers: false,
      loadedSimilarSuppliersId: supplierId,
      similarSuppliers,
    });
  },

  'Suppliers.fetchSimilarSuppliersFailed': (state, { supplierId, pane }) => {
    return state.update(pane, s => s.delete('loadingSimilarSuppliers').set('loadedSimilarSuppliersId', supplierId));
  },

  'Suppliers.conflictError': (state) => {
    return state.set('showConflictError', true);
  },

  'Suppliers.hideConflictError': (state) => {
    return state.set('showConflictError', false);
  },

  'Suppliers.reloadConflictError': (state) => {
    return reloadSuppliersBoth(state).set('showConflictError', false);
  },

  'Suppliers.setRecordsSelectedSourceDatasets': (state, { datasetsToAdd, datasetsToRemove, pane }) => {
    checkArg({ datasetsToAdd }, ArgTypes.Immutable.set.of(ArgTypes.string));
    checkArg({ datasetsToRemove }, ArgTypes.Immutable.set.of(ArgTypes.string));
    return state.setIn([pane, 'recordsSourceDatasetsFilterDialogVisible'], false).updateIn([pane, 'recordsSelectedSourceDatasets'], currentSelection => currentSelection.union(datasetsToAdd).subtract(datasetsToRemove));
  },

  'Suppliers.setRecordsSourceDatasetsDialogVisible': (state, { pane, show }) => {
    return state.setIn([pane, 'recordsSourceDatasetsFilterDialogVisible'], show);
  },

  'Suppliers.fetchTransactions': (state, { pane }) => {
    return state.mergeIn([pane], { recordsLoading: true });
  },

  [FETCH_TRANSACTIONS_COMPLETED]: (
    state,
    { rows, total, totalSupplierRecords, filterInfo, pane, activeGeospatialRenderingAttribute },
  ) => {
    const ids = rows.map(({ recordId }) => recordId).toSet();
    return state.update(pane, s => {
      return s
        .merge({
          recordsLoading: false,
          recordsLoadedFilterInfo: filterInfo,
          rows,
          numRecords: total,
          totalSupplierRecords,
        })
        .update('selectedRecordIds', sel => sel.filter(id => ids.has(id)))
        .update('activeRecordId', id => (id && ids.has(id) ? id : null));
    }).mergeIn(['geospatial'], { activeGeospatialRenderingAttribute });
  },

  'Suppliers.fetchTransactionsFailed': (state, { filterInfo, pane }) => {
    return state.mergeIn([pane], { recordsLoading: false, recordsLoadedFilterInfo: filterInfo });
  },

  [FETCH_INITIAL_GEOSPATIAL_TRANSACTIONS]: (state) => {
    return state.mergeIn(['geospatial'], { openMap: true, loadingRows: true });
  },

  [FETCH_INITIAL_GEOSPATIAL_TRANSACTIONS_COMPLETED]: (state, {
    activeClusterId,
    activeClusterName,
    activeGeospatialRenderingAttribute,
    geoRows,
    totalGeoFeatures,
    initialTotalGeoFeatures,
    initialBounds,
    initialCenter,
    currentBounds,
    loadedBounds,
    loadingRows,
  }) => {
    return state.mergeIn(['geospatial'], {
      activeClusterId,
      activeClusterName,
      activeGeospatialRenderingAttribute,
      geoRows,
      totalGeoFeatures,
      initialTotalGeoFeatures,
      initialBounds,
      initialCenter,
      currentBounds,
      loadedBounds,
      loadingRows,
    });
  },

  [FETCH_INITIAL_GEOSPATIAL_TRANSACTIONS_FAILED]: (state) => {
    return state.mergeIn(['geospatial'], { loadingRows: false });
  },

  [FETCH_GEOSPATIAL_TRANSACTIONS]: (state) => {
    return state.mergeIn(['geospatial'], { loadingBounds: true });
  },

  [FETCH_GEOSPATIAL_TRANSACTIONS_COMPLETED]: (state, { geoRows, totalGeoFeatures, loadedBounds, loadingBounds }) => {
    return state.mergeIn(['geospatial'], { geoRows, totalGeoFeatures, loadedBounds, loadingBounds });
  },

  [FETCH_GEOSPATIAL_TRANSACTIONS_FAILED]: (state) => {
    return state.mergeIn(['geospatial'], { loadingBounds: false });
  },

  [FETCH_ADJACENT_GEOSPATIAL_TRANSACTIONS]: (state) => {
    return state.mergeIn(['geospatial'], { loadingAdjacentRows: true });
  },

  [FETCH_ADJACENT_GEOSPATIAL_TRANSACTIONS_COMPLETED]: (state, { adjacentGeoRows, totalAdjacentGeoFeatures, loadedAdjacentBounds, loadingAdjacentRows }) => {
    return state.mergeIn(['geospatial'], { adjacentGeoRows, totalAdjacentGeoFeatures, loadedAdjacentBounds, loadingAdjacentRows });
  },

  [FETCH_ADJACENT_GEOSPATIAL_TRANSACTIONS_FAILED]: (state) => {
    return state.mergeIn(['geospatial'], { loadingAdjacentRows: false });
  },

  [FETCH_TRANSACTIONS_BY_BOUNDS_COMPLETED]: (state, { selectedGeoRows }) => {
    return state.mergeIn(['geospatial'], {
      selectedGeoRows,
    });
  },

  [CLEAR_SELECTED_GEO_ROWS]: (state) => {
    return state.mergeIn(['geospatial'], {
      selectedGeoRows: List(),
    });
  },

  [UPDATE_GEOSPATIAL_BOUNDS]: (state, { bounds }) => {
    return state.mergeIn(['geospatial'], { currentBounds: bounds, adjacentGeoRows: List(), totalAdjacentGeoFeatures: 0 });
  },

  [TOGGLE_SHOW_GEOSPATIAL_OVERLAY]: (state) => {
    return state.updateIn(['geospatial', 'showGeospatialOverlay'], b => !b);
  },

  'Suppliers.closeMap': (state) => {
    return state.mergeIn(['geospatial'], { openMap: false });
  },

  'Suppliers.setActiveRowNumber': (state, { rowNum, pane }) => {
    return state.mergeIn([pane], { activeRecordId: state[pane].rows.get(rowNum)?.recordId });
  },

  'Suppliers.selectRecordRow': (state, { rowNum, keyMods, pane }) => {
    const { selectedRows, lastSelectedRow } = keyModSelect({
      keyMods,
      selectedRows: getSelectedRecordIndexes({ suppliers: state }, pane),
      selectedRow: rowNum,
      lastSelectedRow: getActiveRecordIndex({ suppliers: state }, pane),
    });
    return state
      .mergeIn([pane], {
        selectedRecordIds: selectedRows.map(i => state[pane].rows.get(i)?.recordId),
        activeRecordId: lastSelectedRow !== undefined ? state[pane].rows.get(lastSelectedRow)?.recordId : undefined,
      })
      .merge({ focusedPane: pane });
  },

  'Suppliers.selectAllRecordRows': (state, { pane }) => {
    return state.update(pane, s => {
      return s.update('selectedRecordIds', ids => {
        return ids.size === s.rows.size ? ids.clear() : s.rows.map(r => r.recordId).toSet();
      });
    });
  },

  'Suppliers.startDragRecord': (state, { recordId, pane }) => {
    return state.update(pane, s => {
      return s.update('selectedRecordIds', ids => {
        return ids.has(recordId) ? ids : ids.clear().add(recordId);
      }).set('activeRecordId', recordId);
    });
  },

  'Suppliers.showSidebarTab': (state, { sidebarTabKey }) => {
    return state.merge({ sidebarTabKey, expanded: true });
  },

  'Suppliers.setPage': (state, { pageNum, pane }) => {
    return state.mergeIn([pane], { recordsPageNum: pageNum });
  },

  'Suppliers.setPageSize': (state, { pageSize, pane }) => {
    return state.mergeIn([pane], { recordsPageSize: pageSize, recordsPageNum: 0 });
  },

  'Suppliers.toggleSort': (state, { columnName, pane }) => {
    const sanitizedColName = ElasticUtils.sanitizeField(columnName);
    const currentState = state.get(pane).recordsColumnSortStates.get(sanitizedColName) || SortState.UNSORTED;
    return state.mergeIn([pane], {
      recordsColumnSortStates: Map({ [sanitizedColName]: SortUtils.getNext(currentState) }),
      // @ts-expect-error immutableMergeWithPlainJS
    }).mergeDeep({ top: { recordsPageNum: 0 }, bottom: { recordsPageNum: 0 } });
  },

  'Suppliers.pinRecords': (state, { pane }) => {
    return state.update(pane, s => clearSelection(s).update('pinnedRecords', r => r.union(state[pane].selectedRecordIds)));
  },

  'Suppliers.unpinRecords': (state, { pane }) => {
    return state.update(pane, s => clearSelection(s).update('pinnedRecords', r => r.subtract(state[pane].selectedRecordIds)));
  },

  'Suppliers.toggleVerifiedFilter': (state, { pane }) => {
    return state.updateIn([pane, 'recordsVerifiedFilters'], recordsVerifiedFilters => {
      if (recordsVerifiedFilters.verified) {
        return recordsVerifiedFilters.delete('verifiedHere').delete('verifiedElsewhere');
      }
      return recordsVerifiedFilters.set('verifiedHere', true).set('verifiedElsewhere', true);
    });
  },
  'Suppliers.toggleVerifiedInCurrentCluster': (state, { pane }) => {
    return state.updateIn([pane, 'recordsVerifiedFilters', 'verifiedHere'], verifiedHere => !verifiedHere);
  },
  'Suppliers.toggleVerifiedInAnotherCluster': (state, { pane }) => {
    return state.updateIn([pane, 'recordsVerifiedFilters', 'verifiedElsewhere'], verifiedElsewhere => !verifiedElsewhere);
  },
  'Suppliers.toggleNotVerifiedFilter': (state, { pane }) => {
    return state.updateIn([pane, 'recordsVerifiedFilters', 'notVerified'], notVerified => !notVerified);
  },
  'Suppliers.toggleSuggestionsEnabled': (state, { pane }) => {
    return state.updateIn([pane, 'recordsSuggestionsFilters'], recordsSuggestionsFilters => {
      if (recordsSuggestionsFilters.suggestionsEnabled) { // both active
        return recordsSuggestionsFilters.delete('moveSuggested').delete('noMoveSuggested');
      }
      return recordsSuggestionsFilters.set('moveSuggested', true).set('noMoveSuggested', true);
    });
  },
  'Suppliers.toggleMoveSuggested': (state, { pane }) => {
    return state.updateIn([pane, 'recordsSuggestionsFilters', 'moveSuggested'], x => !x);
  },
  'Suppliers.toggleNoMoveSuggested': (state, { pane }) => {
    return state.updateIn([pane, 'recordsSuggestionsFilters', 'noMoveSuggested'], x => !x);
  },
  'Suppliers.toggleSuggestionsDisabled': (state, { pane }) => {
    return state.updateIn([pane, 'recordsSuggestionsFilters', 'suggestionsDisabled'], x => !x);
  },
  'Suppliers.toggleSuggestionsAutoAccepted': (state, { pane }) => {
    return state.updateIn([pane, 'recordsSuggestionsFilters', 'suggestionsAutoAccepted'], x => !x);
  },

  'Suppliers.toggleClusterHasVerifiedHereFilter': (state, { pane }) => {
    return state.updateIn([pane, 'clusterVerificationFilters'], f => f.toggleHasVerifiedHere());
  },
  'Suppliers.toggleClusterHasVerifiedElsewhereFilter': (state, { pane }) => {
    return state.updateIn([pane, 'clusterVerificationFilters', 'hasVerifiedElsewhere'], x => !x);
  },
  'Suppliers.toggleClusterHasMoveSuggestedFilter': (state, { pane }) => {
    return state.updateIn([pane, 'clusterVerificationFilters'], f => f.toggleHasMoveSuggested());
  },
  'Suppliers.toggleClusterHasNoneVerifiedFilter': (state, { pane }) => {
    return state.updateIn([pane, 'clusterVerificationFilters', 'hasNoneVerified'], x => !x);
  },

  'Suppliers.toggleFilterHasComments': (state, { pane }) => {
    return state.update(pane, s => s.update('hasComments', b => !b).set('recordsPageNum', 0));
  },

  'Suppliers.toggleRecordChangesFilter': (state, { pane, filterOption }) => {
    return state.updateIn([pane, 'recordChanges'], current => {
      return current.has(filterOption) ? current.delete(filterOption) : current.add(filterOption);
    });
  },

  'Suppliers.setRecordChangesFilter': (state, { pane, recordChanges, clear }) => {
    checkArg({ pane }, ArgTypes.string);
    checkArg({ recordChanges }, ArgTypes.Immutable.set.of(ClusterRecordChanges.argType));
    checkArg({ clear }, ArgTypes.bool);
    let modState;
    if (clear) {
      modState = state.update(pane, clearClusterRecordFilters);
    } else {
      modState = state;
    }
    return modState.mergeIn([pane], { recordChanges }).deleteIn([pane, 'pageNum']);
  },


  'Suppliers.toggleExpandSidebar': (state) => {
    return state.update('expanded', b => !b);
  },

  'Suppliers.openCommentForm': (state) => {
    return state
      .merge({ sidebarTabKey: SidebarTabs.ACTIVITY, expanded: true })
      .update('commentFocusSequence', n => n + 1);
  },

  'Suppliers.commentCompleted': (state) => {
    return reloadRecordsBoth(state);
  },

  'Suppliers.editCommentCompleted': (state) => {
    return reloadRecordsBoth(state);
  },

  'Suppliers.deleteCommentCompleted': (state) => {
    return reloadRecordsBoth(state);
  },

  'Suppliers.fetchTotalTestClustersVerifiedCompleted': (state, { totalTestClustersVerified }) => {
    return state.merge({ totalTestClustersVerified });
  },

  'Suppliers.fetchSuppliersUpdate': (state, { pane }) => {
    return state.mergeIn([pane], { softReload: false, loading: true });
  },

  'Suppliers.fetchSuppliersUpdateCompleted': (state, { currentPage, total, filterInfo, totalSuppliers, affectedIds, affectedClusters, pane }) => {
    return resolveWithServerState(state, { currentPage, total, filterInfo, totalSuppliers, affectedIds, affectedClusters, pane });
  },

  'Suppliers.fetchSuppliersUpdateFailed': (state, { pane }) => {
    return state.mergeIn([pane], { loading: false });
  },

  'Suppliers.openClusterBrowser': (state, { clusterId, pane }) => {
    return state
      .merge({ twoPanes: true })
      .update(otherPane(pane), s => filterToOne(s, clusterId))
      .update(otherPane(pane), s => s.set('clustersSort', state.get(pane).clustersSort))
      .update(otherPane(pane), s => s.set('recordsColumnSortStates', state.get(pane).recordsColumnSortStates));
  },

  'Suppliers.closeClusterBrowser': (state, { pane }) => {
    return (pane === 'top' ? state.set('top', state.bottom) : state).merge({ twoPanes: false, focusedPane: Pane.TOP });
  },

  'Suppliers.setClustersSort': (state, { clustersSort, pane }) => {
    return state
      .mergeIn([pane], { clustersSort })
      .deleteIn([pane, 'pageNum']);
  },

  'Suppliers.setRecordsGraphTimePeriod': (state, { recordsGraphTimePeriod }) => {
    return state.merge({ recordsGraphTimePeriod });
  },

  'Suppliers.fetchClusterPublishInfo': (state) => {
    return state.set('loadingClusterPublishInfo', true).delete('publishedClusterVersions').delete('publishTimes');
  },

  'Suppliers.fetchClusterPublishInfoFailed': (state, { loadedPublishedClusterId }) => {
    return state.merge({ loadingClusterPublishInfo: false, loadedPublishedClusterId });
  },

  'Suppliers.fetchClusterPublishInfoCompleted': (state, { publishTimes, publishedClusterVersions, loadedPublishedClusterId }) => {
    return state.merge({ loadingClusterPublishInfo: false, publishTimes, publishedClusterVersions, loadedPublishedClusterId });
  },

  'Suppliers.toggleClusterDiffVisible': (state, { pane }) => {
    return state.updateIn([pane, 'isClusterDiffVisible'], status => !status);
  },

  'Suppliers.fetchActiveRecordClusterMembership': (state, { pane }) => {
    return state
      .set('loadingActiveRecordClusterMembership', true)
      .deleteIn([pane, 'activeRecordClusterMembership']);
  },

  'Suppliers.fetchActiveRecordClusterMembershipCompleted': (state, { activeRecordClusterMembership, activeRecordRelatedCluster, pane, filterInfo }) => {
    checkArg({ activeRecordRelatedCluster }, ArgTypes.orUndefined(Cluster.argType));
    return state.mergeIn([pane], { activeRecordClusterMembership, activeRecordRelatedCluster })
      .set('loadedActiveRecordClusterMembershipFilterInfo', filterInfo)
      .set('loadingActiveRecordClusterMembership', false);
  },

  'Suppliers.fetchActiveRecordClusterMembershipFailed': (state, { filterInfo }) => {
    return state
      .set('loadedActiveRecordClusterMembershipFilterInfo', filterInfo)
      .set('loadingActiveRecordClusterMembership', false);
  },

  'Suppliers.openGeospatialDetailsSidebar': (state, { attributeName }) => {
    return state.merge({
      sidebarTabKey: SidebarTabs.DETAILS,
      expanded: true,
      activeGeospatialAttribute: new ActiveGeospatialAttribute({
        name: attributeName,
        isOpenDialog: false,
      }),
    });
  },
  'Suppliers.closeGeospatialDetailsSidebar': (state) => {
    return state.merge({ activeGeospatialAttribute: null });
  },

  'Suppliers.openGeospatialDetailsDialog': (state) => {
    const { activeGeospatialAttribute } = state;
    if (activeGeospatialAttribute === null) {
      return state;
    }
    return state.merge({
      activeGeospatialAttribute: activeGeospatialAttribute
        .set('isOpenDialog', true),
    });
  },
  'Suppliers.closeGeospatialDetailsDialog': (state) => {
    const { activeGeospatialAttribute } = state;
    if (activeGeospatialAttribute === null) {
      return state;
    }
    return state.merge({
      activeGeospatialAttribute: activeGeospatialAttribute
        .set('isOpenDialog', false),
    });
  },
  'Suppliers.fetchPublishedClustersDatasetStatus': (state) => {
    return state.set('publishedClustersDatasetStatusLoading', true).delete('publishedClustersDatasetStatus');
  },
  'Suppliers.fetchPublishedClustersDatasetStatusCompleted': (state, { publishedClustersDatasetStatus }) => {
    return state.merge({
      publishedClustersDatasetStatus,
      publishedClustersDatasetStatusLoading: false,
    });
  },
  'Suppliers.fetchPublishedClustersDatasetStatusFailed': (state) => {
    return state.merge({
      publishedClustersDatasetStatusLoading: false,
    });
  },

  'Suppliers.fetchVerificationFilterTotals': (store) => {
    return store.update('recordsVerificationFilterTotals', totals => totals.beginLoading());
  },
  'Suppliers.fetchVerificationFilterTotalsCompleted': (store, { totals, fetchTriggers }) => {
    return store.update('recordsVerificationFilterTotals', t =>
      t.set('loading', false)
        .set('loadedFetchTriggers', fetchTriggers)
        .merge(totals),
    );
  },
  'Suppliers.fetchVerificationFilterTotalsFailed': (store, { fetchTriggers }) => {
    return store.update('recordsVerificationFilterTotals', t =>
      t.set('loading', false)
        .set('loadedFetchTriggers', fetchTriggers),
    );
  },

  [TOGGLE_CLUSTER_HIGH_IMPACT_FILTER]: (state, { pane }) => {
    return state.update(pane, s => s.update('clusterHighImpactFilter', b => !b).set('pageNum', 0));
  },
};
