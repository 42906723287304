import {
  getCoreConnectDefaultCloudProvider,
  getFilesWithCoreConnect,
  getSchemaFromCloud,
  transferCloudToTamr,
  transferTamrToCloud,
} from '../api/CoreConnectService';
import { AppThunkAction } from '../stores/AppAction';
import {
  FETCH_DEFAULT_CLOUD_PROVIDER,
  FETCH_DEFAULT_CLOUD_PROVIDER_COMPLETED,
  FETCH_DEFAULT_CLOUD_PROVIDER_FAILED,
  FETCH_FILES_WITH_CONNECT,
  FETCH_FILES_WITH_CONNECT_COMPLETED,
  FETCH_FILES_WITH_CONNECT_FAILED,
  FETCH_SCHEMA_FROM_CLOUD,
  FETCH_SCHEMA_FROM_CLOUD_COMPLETED,
  FETCH_SCHEMA_FROM_CLOUD_FAILED,
  TRANSFER_CLOUD_TO_TAMR,
  TRANSFER_CLOUD_TO_TAMR_COMPLETED,
  TRANSFER_CLOUD_TO_TAMR_FAILED,
  TRANSFER_TAMR_TO_CLOUD,
  TRANSFER_TAMR_TO_CLOUD_COMPLETED,
  TRANSFER_TAMR_TO_CLOUD_FAILED,
} from './CoreConnectServiceActionTypes';

export const fetchCoreConnectDefaultCloudProvider = (): AppThunkAction<void> => (dispatch) => {
  dispatch({ type: FETCH_DEFAULT_CLOUD_PROVIDER });
  return getCoreConnectDefaultCloudProvider()
    .then(coreconnectDefaultProvider => dispatch({ type: FETCH_DEFAULT_CLOUD_PROVIDER_COMPLETED, coreconnectDefaultProvider, coreconnectEnabled: ['GCS', 'ADLS2', 'S3'].includes(coreconnectDefaultProvider) }))
    .catch(response => dispatch({ type: FETCH_DEFAULT_CLOUD_PROVIDER_FAILED, errorMessage: response.statusText }));
};

export const fetchSchemaFromCloud = (): AppThunkAction<void> => (dispatch, getState) => {
  dispatch({ type: FETCH_SCHEMA_FROM_CLOUD });

  const {
    coreConnectService: {
      coreconnectDefaultProvider,
      sourceProjectName,
      sourceBucketName,
      sourcePathPrefix,
      sourceFileType,
    },
  } = getState();

  if (
    coreconnectDefaultProvider === undefined
    || sourceProjectName === undefined
    || sourceBucketName === undefined
    || sourcePathPrefix === undefined
    || sourceFileType === undefined
  ) {
    console.error('Could not fetch files with CoreConnect because one or more parameters was undefined.');
    return;
  }

  return getSchemaFromCloud({
    coreconnectDefaultProvider,
    projectId: sourceProjectName,
    region: sourceProjectName,
    bucket: sourceBucketName,
    pathPrefix: sourcePathPrefix,
    fileType: sourceFileType,
  })
    .then(schema => dispatch({ type: FETCH_SCHEMA_FROM_CLOUD_COMPLETED, schema }))
    .catch(response => dispatch({ type: FETCH_SCHEMA_FROM_CLOUD_FAILED, errorMessage: response.statusText }));
};

export const fetchFilesWithCoreConnect = (): AppThunkAction<void> => (dispatch, getState) => {
  const {
    coreConnectService: {
      coreconnectDefaultProvider,
      sourceProjectName,
      sourceBucketName,
      sourcePathPrefix,
      sourceFileType,
      exportDialogOpen,
      sinkProjectName,
      sinkBucketName,
      sinkPathPrefix,
    },
  } = getState();

  if (coreconnectDefaultProvider === undefined) {
    console.error('No coreconnect provider is specified.');
    return;
  }

  let project: string;
  let bucket: string;
  let path: string;

  if (exportDialogOpen === false) {
    if (sourceProjectName === undefined || sourceBucketName === undefined) {
      console.error('The source path was not fully specified; could not fetch files from CoreConnect');
      return;
    }

    project = sourceProjectName;
    bucket = sourceBucketName;
    path = sourcePathPrefix || '';
  } else {
    if (sinkProjectName === undefined || sinkBucketName === undefined) {
      console.error('The sink path was not fully specified; could not fetch files from CoreConnect');
      return;
    }

    project = sinkProjectName;
    bucket = sinkBucketName;
    path = sinkPathPrefix || '';
  }

  dispatch({ type: FETCH_FILES_WITH_CONNECT });

  return getFilesWithCoreConnect({
    provider: coreconnectDefaultProvider,
    project,
    bucket,
    path,
    fileType: sourceFileType,
  })
    .then(sourceFiles => {
      dispatch({ type: FETCH_FILES_WITH_CONNECT_COMPLETED, sourceFiles });
      dispatch(fetchSchemaFromCloud());
    })
    .catch(response => dispatch({ type: FETCH_FILES_WITH_CONNECT_FAILED, errorMessage: response.statusText }));
};

export const startTransferCloudToTamr = (): AppThunkAction<void> => (dispatch, getState) => {
  dispatch({ type: TRANSFER_CLOUD_TO_TAMR });

  const {
    location: { recipeId },
    coreConnectService: {
      coreconnectDefaultProvider,
      sourceProjectName,
      sourceBucketName,
      sourcePathPrefix,
      sourceFileType,
      primaryKeys,
      sinkPathPrefix,
      profileDataset,
      appendToDataset,
    },
    accessControl: {
      datasetDraftPolicyResourceship,
    },
  } = getState();

  if (
    coreconnectDefaultProvider === undefined
    || sourceProjectName === undefined
    || sourceBucketName === undefined
    || sourcePathPrefix === undefined
    || sourceFileType === undefined
    || sinkPathPrefix === undefined
  ) {
    dispatch({
      type: TRANSFER_CLOUD_TO_TAMR_FAILED,
      errorMessage: 'Could not fetch files with CoreConnect because one or more parameters was undefined.',
    });
    return;
  }

  return transferCloudToTamr({
    coreconnectDefaultProvider,
    projectId: sourceProjectName,
    region: sourceProjectName,
    bucket: sourceBucketName,
    pathPrefix: sourcePathPrefix,
    fileType: sourceFileType,
    primaryKeys,
    datasetName: sinkPathPrefix,
    policyIds: Array.from(datasetDraftPolicyResourceship?.getPolicyIds() || []),
    recipeId,
    profile: profileDataset,
    appendToDataset,
  })
    .then(job => dispatch({ type: TRANSFER_CLOUD_TO_TAMR_COMPLETED, job }))
    .catch(response => dispatch({ type: TRANSFER_CLOUD_TO_TAMR_FAILED, errorMessage: response.statusText }));
};

export const startTransferTamrToCloud = (): AppThunkAction<void> => (dispatch, getState) => {
  dispatch({ type: TRANSFER_TAMR_TO_CLOUD });

  const {
    coreConnectService: {
      coreconnectDefaultProvider,
      sinkProjectName,
      sinkBucketName,
      sinkPathPrefix,
      sinkFileType,
      tamrSourceDataset,
    },
  } = getState();

  if (
    coreconnectDefaultProvider === undefined
    || sinkProjectName === undefined
    || sinkBucketName === undefined
    || sinkPathPrefix === undefined
    || sinkFileType === undefined
    || tamrSourceDataset === undefined
  ) {
    console.error('Could not fetch files with CoreConnect because one or more parameters was undefined.');
    return;
  }

  return transferTamrToCloud({
    coreconnectDefaultProvider,
    projectId: sinkProjectName,
    region: sinkProjectName,
    bucket: sinkBucketName,
    pathPrefix: sinkPathPrefix,
    fileType: sinkFileType,
    sourceDataset: tamrSourceDataset,
  })
    .then(job => dispatch({ type: TRANSFER_TAMR_TO_CLOUD_COMPLETED, job }))
    .catch(response => dispatch({ type: TRANSFER_TAMR_TO_CLOUD_FAILED, errorMessage: response.statusText }));
};
