import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import * as UsersActions from '../constants/UsersActionTypes';
import AppState from '../stores/AppState';
import PRODUCT_NAME from '../utils/ProductName';
import { pluralize } from '../utils/Strings';
import style from './ChangeActiveStatusDialog.module.scss';
import { changeActiveStatus } from './UsersAsync';
import { getUsernamesToChangeActiveStatus } from './UsersStore';


const ACTIVATE_MESSAGE = `Activating these users will allow them to access the ${PRODUCT_NAME} platform.`;
const DEACTIVATE_MESSAGE = `These users will lose access to the ${PRODUCT_NAME} platform completely.`;

const getUserCountString = (count: number) => `${count} ${pluralize(count, 'user', 'users')}`;

const ChangeActiveStatusDialog = connect((state: AppState) => {
  const { users: { changeActiveStatusActivate, showChangeActiveStatusDialog } } = state;
  const count = getUsernamesToChangeActiveStatus(state).size;
  return { activate: changeActiveStatusActivate, count, show: showChangeActiveStatusDialog };
}, {
  onCancel: () => ({ type: UsersActions.HIDE_CHANGE_ACTIVE_STATUS_DIALOG }),
  onSubmit: changeActiveStatus,
})(({ activate, count, onCancel, onSubmit, show }) => (
  <Dialog
    show={show}
    dialogStyle={DialogStyle.PRIMARY}
    onHide={onCancel}
    title={activate ? 'Activate users' : 'Deactivate users'}
    body={
      <span>
        You are about to {activate ? 'activate' : 'deactivate'} {getUserCountString(count)}.  {activate ? ACTIVATE_MESSAGE : DEACTIVATE_MESSAGE}
      </span>
    }
    footer={
      <ButtonToolbar>
        <Button buttonType="Secondary" onClick={onCancel}>Cancel</Button>
        <Button className={activate ? style.activate : style.deactivate} buttonType="Primary" onClick={onSubmit}>{activate ? 'Activate ' : 'Deactivate '}{getUserCountString(count)}</Button>
      </ButtonToolbar>
    }
  />
));

export default ChangeActiveStatusDialog;
