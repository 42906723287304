import { List } from 'immutable';

import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import Clause from './Clause';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

// com.tamr.dedup.models.dnf.Dnf
class Dnf extends getModelHelpers({
  clauses: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(Clause)) },

}, 'Dnf')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class DnfRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(Dnf); }

  static fromJSON(obj: $TSFixMe) {
    return new Dnf({
      clauses: List(obj.clauses).map(Clause.fromJSON),
    });
  }

  /**
   * Checks if two DNFs are equal
   *
   * Ignores whether their IDs are different or their clause entries are in different orders
   */
  equalsIgnoreId(other: Dnf) {
    checkArg({ other }, Dnf.argType);
    return this.clauses.size === other.clauses.size
      && this.clauses.every(clause => !!other.clauses.find(c => c.equalsIgnoreId(clause)));
  }

  activeClauses() {
    const { clauses } = this;
    const activeClauses = clauses.filter(c => c.active);

    return new Dnf({ clauses: activeClauses });
  }
}

export default Dnf;
