import enumify from '../../constants/enumify';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../../models/Model';
import { ArgTypes } from '../../utils/ArgValidation';

export const ContentEnvelopeTypes = enumify({ formula: 'FORMULA', aggregation: 'AGGREGATION', expression: 'EXPRESSION' });

class ContentEnvelope extends getModelHelpers({
  pre: { type: ArgTypes.string, defaultValue: '' },
  post: { type: ArgTypes.string, defaultValue: '' },
  type: { type: ArgTypes.valueIn(ContentEnvelopeTypes), defaultValue: ContentEnvelopeTypes.formula },
}, 'ContentEnvelope')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ContentEnvelopeRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() {
    return ArgTypes.instanceOf(this);
  }
}

export default ContentEnvelope;
