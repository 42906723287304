import Immutable, { List, Set } from 'immutable';
import _ from 'underscore';
import uri from 'urijs';

import SERVICES from '../api/ServiceProxy';
import ClusterRecordChanges, { ClusterRecordChangesValueType } from '../constants/ClusterRecordChanges';
import { DEDUP } from '../constants/RecipeType';
import SortState, { SortStateValueType } from '../constants/SortState';
import VerificationTypeFilter, { VerificationTypeFilterE } from '../constants/VerificationTypeFilter';
import ConfidenceFilter from '../models/ConfidenceFilter';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import EsRecord from '../models/EsRecord';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import SortUtils from '../utils/SortUtils';
import { $TSFixMe } from '../utils/typescript';
import { undef } from '../utils/Values';

const DEFAULT_PAGE_SIZE = 50;

class TxnUrlBuilder {
  _projectType: string;
  _hideReviewed: boolean | null | undefined;
  _clusterIds: string[];
  _username: undefined;
  _unifiedDatasetName: string;
  _datasetIds: number[];
  _datasetNames: string[];
  _datasetIdsToIgnore: number[];
  _pageNum: number;
  _pageSize: number;
  _categoryIds: number[];
  _categorizedByMe: boolean | null | undefined;
  _labeledByTamr: boolean | null | undefined;
  _labeledByUser: boolean | null | undefined;
  _unlabeled: boolean | null | undefined;
  _unlabeledByTamr: boolean | null | undefined;
  _unverified: boolean | null | undefined;
  _labelAgreesWithTamr: boolean | null | undefined;
  _labelDisagreesWithTamr: boolean | null | undefined;
  _excludeExternalData: boolean | null | undefined;
  _expertResponsesAgree: boolean | null | undefined;
  _expertResponsesDisagree: boolean | null | undefined;
  _expertsPending: boolean | null | undefined;
  _expertsUnsure: boolean | null | undefined;
  _expertsNone: boolean | null | undefined;
  _queryString: string | null | undefined;
  _sort: string[];
  _sortBySpend: boolean;
  _includePublishedClusterIds: boolean;
  _pinnedRecords: string[];
  _hasComments: boolean;
  _verificationTypeFilters: VerificationTypeFilterE[];
  _assignmentStatusNone: boolean;
  _assignmentStatusSome: boolean;
  _assignmentStatusAll: boolean;
  _assignmentStatusUnassigned: boolean;
  _assignedToUsers: string[];
  _categorizedByUsers: string[];
  _highImpact: boolean;
  _confidenceLow: boolean;
  _confidenceMedium: boolean;
  _confidenceHigh: boolean;
  _hasConfidence: boolean;
  _confidence: ConfidenceFilter | null | undefined;
  _sortByConfidence: SortStateValueType | null | undefined;
  _sortByQuestionImpactRank: boolean;
  _changeStatusFilters: ClusterRecordChangesValueType[];
  _geospatialEnvelopFilter: string | undefined;
  _assignedToMe: boolean;
  _notClusterIds: string[];
  _testRecordsAccuracyFilter: string[];
  _numericFields: string[];
  _fetchRecordsWithoutClusters: boolean;

  constructor(projectType: string) {
    this._projectType = projectType;
    this._unifiedDatasetName = '';
    this._datasetIds = [];
    this._datasetNames = [];
    this._datasetIdsToIgnore = [];
    this._pageNum = 0;
    this._pageSize = DEFAULT_PAGE_SIZE;
    this._categoryIds = [];
    this._categorizedByMe = undef();
    this._labeledByTamr = undef();
    this._labeledByUser = undef();
    this._unlabeled = undef();
    this._unlabeledByTamr = undef();
    this._unverified = undef();
    this._labelAgreesWithTamr = undef();
    this._labelDisagreesWithTamr = undef();
    this._excludeExternalData = undef();
    this._expertResponsesAgree = undef();
    this._expertResponsesDisagree = undef();
    this._expertsPending = undef();
    this._expertsUnsure = undef();
    this._expertsNone = undef();
    this._queryString = undef();
    this._sort = [];
    this._sortBySpend = false;
    this._username = undef();
    this._hideReviewed = undef();
    this._clusterIds = [];
    this._notClusterIds = [];
    this._includePublishedClusterIds = false;
    this._pinnedRecords = [];
    this._hasComments = false;
    this._verificationTypeFilters = [];
    this._assignmentStatusNone = false;
    this._assignmentStatusSome = false;
    this._assignmentStatusAll = false;
    this._assignmentStatusUnassigned = false;
    this._assignedToUsers = [];
    this._categorizedByUsers = [];
    this._highImpact = false;
    this._confidenceLow = false;
    this._confidenceMedium = false;
    this._confidenceHigh = false;
    this._hasConfidence = false;
    this._confidence = undef();
    this._sortByConfidence = undef();
    this._sortByQuestionImpactRank = false;
    this._changeStatusFilters = [];
    this._geospatialEnvelopFilter = undef();
    this._testRecordsAccuracyFilter = [];
    this._fetchRecordsWithoutClusters = false;
  }
  unifiedDatasetName(name: string) {
    checkArg({ name }, ArgTypes.string);
    this._unifiedDatasetName = name;
    return this;
  }
  datasetIds(ids: number[]) {
    checkArg({ ids }, ArgTypes.array.of(ArgTypes.number));
    this._datasetIds = ids;
    return this;
  }
  datasetNames(names: string[]) {
    checkArg({ names }, ArgTypes.array.of(ArgTypes.string));
    this._datasetNames = names;
    return this;
  }
  datasetIdsToIgnore(ids: number[]) {
    checkArg({ ids }, ArgTypes.array.of(ArgTypes.number));
    this._datasetIdsToIgnore = ids;
    return this;
  }
  pageNum(pageNum: number) {
    checkArg({ pageNum }, ArgTypes.number);
    this._pageNum = pageNum;
    return this;
  }
  pageSize(pageSize: number) {
    checkArg({ pageSize }, ArgTypes.number);
    this._pageSize = pageSize;
    return this;
  }
  categoryIds(categoryIds: number[]) {
    checkArg({ categoryIds }, ArgTypes.array.of(ArgTypes.number));
    this._categoryIds = categoryIds;
    return this;
  }
  assignedToMe(assignedToMe: boolean) {
    this._assignedToMe = assignedToMe;
    return this;
  }
  hideReviewed(hideReviewed: boolean | null | undefined) {
    checkArg({ hideReviewed }, ArgTypes.oneOf(ArgTypes.bool, ArgTypes.missing));
    this._hideReviewed = hideReviewed;
    return this;
  }
  categorizedByMe(categorizedByMe: boolean | null | undefined) {
    checkArg({ categorizedByMe }, ArgTypes.oneOf(ArgTypes.bool, ArgTypes.missing));
    this._categorizedByMe = categorizedByMe;
    return this;
  }
  labeledByTamr(labeledByTamr: boolean | null | undefined) {
    checkArg({ labeledByTamr }, ArgTypes.oneOf(ArgTypes.bool, ArgTypes.missing));
    this._labeledByTamr = labeledByTamr;
    return this;
  }
  labeledByUser(labeledByUser: boolean | null | undefined) {
    checkArg({ labeledByUser }, ArgTypes.oneOf(ArgTypes.bool, ArgTypes.missing));
    this._labeledByUser = labeledByUser;
    return this;
  }
  unlabeled(unlabeled: boolean | null | undefined) {
    checkArg({ unlabeled }, ArgTypes.oneOf(ArgTypes.bool, ArgTypes.missing));
    this._unlabeled = unlabeled;
    return this;
  }
  unlabeledByTamr(unlabeledByTamr: boolean | null | undefined) {
    checkArg({ unlabeledByTamr }, ArgTypes.oneOf(ArgTypes.bool, ArgTypes.missing));
    this._unlabeledByTamr = unlabeledByTamr;
    return this;
  }
  unverified(unverified: boolean | null | undefined) {
    checkArg({ unverified }, ArgTypes.oneOf(ArgTypes.bool, ArgTypes.missing));
    this._unverified = unverified;
    return this;
  }
  labelAgreesWithTamr(labelAgreesWithTamr: boolean | null | undefined) {
    checkArg({ labelAgreesWithTamr }, ArgTypes.oneOf(ArgTypes.bool, ArgTypes.missing));
    this._labelAgreesWithTamr = labelAgreesWithTamr;
    return this;
  }
  labelDisagreesWithTamr(labelDisagreesWithTamr: boolean | null | undefined) {
    checkArg({ labelDisagreesWithTamr }, ArgTypes.oneOf(ArgTypes.bool, ArgTypes.missing));
    this._labelDisagreesWithTamr = labelDisagreesWithTamr;
    return this;
  }

  excludeExternalData(excludeExternalData: boolean | null | undefined) {
    checkArg({ excludeExternalData }, ArgTypes.oneOf(ArgTypes.bool, ArgTypes.missing));
    this._excludeExternalData = excludeExternalData;
    return this;
  }
  expertResponsesAgree(expertResponsesAgree: boolean | null | undefined) {
    this._expertResponsesAgree = expertResponsesAgree;
    return this;
  }

  expertResponsesDisagree(expertResponsesDisagree: boolean | null | undefined) {
    this._expertResponsesDisagree = expertResponsesDisagree;
    return this;
  }

  expertsPending(expertsPending: boolean | null | undefined) {
    checkArg({ expertsPending }, ArgTypes.oneOf(ArgTypes.bool, ArgTypes.missing));
    this._expertsPending = expertsPending;
    return this;
  }
  expertsUnsure(expertsUnsure: boolean | null | undefined) {
    checkArg({ expertsUnsure }, ArgTypes.oneOf(ArgTypes.bool, ArgTypes.missing));
    this._expertsUnsure = expertsUnsure;
    return this;
  }
  expertsNone(expertsNone: boolean | null | undefined) {
    checkArg({ expertsNone }, ArgTypes.oneOf(ArgTypes.bool, ArgTypes.missing));
    this._expertsNone = expertsNone;
    return this;
  }
  queryString(queryString: string | null | undefined) {
    checkArg({ queryString }, ArgTypes.oneOf(ArgTypes.string, ArgTypes.missing));
    this._queryString = queryString;
    return this;
  }
  sort(sort: string[]) {
    this._sort = sort;
    return this;
  }
  sortBySpend() {
    this._sortBySpend = true;
    return this;
  }
  clusterIds(clusterIds: string[]) {
    checkArg({ clusterIds }, ArgTypes.array.of(ArgTypes.string));
    this._clusterIds = clusterIds;
    return this;
  }
  notClusterIds(notClusterIds: string[]) {
    checkArg({ notClusterIds }, ArgTypes.array.of(ArgTypes.string));
    this._notClusterIds = notClusterIds;
    return this;
  }
  includePublishedClusterIds(includePublishedClusterIds: boolean) {
    checkArg({ includePublishedClusterIds }, ArgTypes.bool);
    this._includePublishedClusterIds = includePublishedClusterIds;
    return this;
  }
  pinnedRecords(pinnedRecords: string[]) {
    checkArg({ pinnedRecords }, ArgTypes.array.of(ArgTypes.string));
    this._pinnedRecords = pinnedRecords;
    return this;
  }
  hasComments(hasComments: boolean) {
    checkArg({ hasComments }, ArgTypes.bool);
    this._hasComments = hasComments;
    return this;
  }
  verificationTypeFilters(verificationTypeFilters: VerificationTypeFilterE[]) {
    checkArg({ verificationTypeFilters }, ArgTypes.array.of(VerificationTypeFilter.argType));
    this._verificationTypeFilters = verificationTypeFilters;
    return this;
  }
  assignmentStatusNone(assignmentStatusNone: boolean) {
    checkArg({ assignmentStatusNone }, ArgTypes.bool);
    this._assignmentStatusNone = assignmentStatusNone;
    return this;
  }
  assignmentStatusSome(assignmentStatusSome: boolean) {
    checkArg({ assignmentStatusSome }, ArgTypes.bool);
    this._assignmentStatusSome = assignmentStatusSome;
    return this;
  }
  assignmentStatusAll(assignmentStatusAll: boolean) {
    checkArg({ assignmentStatusAll }, ArgTypes.bool);
    this._assignmentStatusAll = assignmentStatusAll;
    return this;
  }
  assignmentStatusUnassigned(assignmentStatusUnassigned: boolean) {
    checkArg({ assignmentStatusUnassigned }, ArgTypes.bool);
    this._assignmentStatusUnassigned = assignmentStatusUnassigned;
    return this;
  }
  assignedToUsers(assignedToUsers: string[]) {
    checkArg({ assignedToUsers }, ArgTypes.array.of(ArgTypes.string));
    this._assignedToUsers = assignedToUsers;
    return this;
  }

  categorizedByUsers(categorizedByUsers: string[]) {
    checkArg({ categorizedByUsers }, ArgTypes.array.of(ArgTypes.string));
    this._categorizedByUsers = categorizedByUsers;
    return this;
  }
  highImpact(highImpact: boolean) {
    checkArg({ highImpact }, ArgTypes.bool);
    this._highImpact = highImpact;
    return this;
  }
  confidenceLow(confidenceLow: boolean) {
    checkArg({ confidenceLow }, ArgTypes.bool);
    this._confidenceLow = confidenceLow;
    return this;
  }
  confidenceMedium(confidenceMedium: boolean) {
    checkArg({ confidenceMedium }, ArgTypes.bool);
    this._confidenceMedium = confidenceMedium;
    return this;
  }
  confidenceHigh(confidenceHigh: boolean) {
    checkArg({ confidenceHigh }, ArgTypes.bool);
    this._confidenceHigh = confidenceHigh;
    return this;
  }
  hasConfidence(hasConfidence: boolean) {
    checkArg({ hasConfidence }, ArgTypes.bool);
    this._hasConfidence = hasConfidence;
    return this;
  }
  confidence(confidence: ConfidenceFilter | null | undefined) {
    checkArg({ confidence }, ArgTypes.nullable(ArgTypes.instanceOf(ConfidenceFilter)));
    this._confidence = confidence;
    return this;
  }
  sortByConfidence(sortByConfidence: SortStateValueType | null | undefined) {
    checkArg({ sortByConfidence }, ArgTypes.nullable(ArgTypes.valueIn(SortState)));
    this._sortByConfidence = sortByConfidence;
    return this;
  }
  sortByQuestionImpactRank(sortByQuestionImpactRank: boolean) {
    checkArg({ sortByQuestionImpactRank }, ArgTypes.bool);
    this._sortByQuestionImpactRank = sortByQuestionImpactRank;
    return this;
  }
  changeStatusFilters(changeStatusFilters: Set<ClusterRecordChangesValueType>) {
    checkArg({ changeStatusFilters }, ArgTypes.Immutable.set.of(ClusterRecordChanges.argType));
    this._changeStatusFilters = changeStatusFilters.toArray();
    return this;
  }
  geospatialEnvelopFilter(geospatialEnvelopFilter: string | undefined) {
    checkArg({ geospatialEnvelopFilter }, ArgTypes.orUndefined(ArgTypes.string));
    this._geospatialEnvelopFilter = geospatialEnvelopFilter;
    return this;
  }
  testRecordsAccuracyFilter(filters: string[]) {
    this._testRecordsAccuracyFilter = filters;
    return this;
  }
  numericFields(numericFields: string[]) {
    this._numericFields = numericFields;
    return this;
  }
  fetchRecordsWithoutClusters(value: boolean) {
    this._fetchRecordsWithoutClusters = value;
    return this;
  }

  build() {
    const unifyUrl = uri(SERVICES.procure(`/transactions/${this._unifiedDatasetName}`));
    const dedupUrl = uri(SERVICES.dedup(`/records/${this._unifiedDatasetName}`));

    const baseUrl = this._projectType === DEDUP.toString() ? dedupUrl : unifyUrl;
    return baseUrl.query({
      datasetIds: this._datasetIds,
      datasetNames: this._datasetNames,
      datasetIdsToIgnore: this._datasetIdsToIgnore,
      offset: this._pageNum * this._pageSize,
      limit: this._pageSize,
      categoryIds: this._categoryIds,
      categorizedByMe: this._categorizedByMe,
      labeledByTamr: this._labeledByTamr,
      labeledByUser: this._labeledByUser,
      expertResponsesAgree: this._expertResponsesAgree,
      expertResponsesDisagree: this._expertResponsesDisagree,
      expertResponsesPending: this._expertsPending,
      expertResponsesUnsure: this._expertsUnsure,
      expertResponsesNone: this._expertsNone,
      unlabeled: this._unlabeled,
      unlabeledByTamr: this._unlabeledByTamr,
      unverified: this._unverified,
      labelAgreesWithTamr: this._labelAgreesWithTamr,
      labelDisagreesWithTamr: this._labelDisagreesWithTamr,
      excludeExternalData: this._excludeExternalData,
      q: this._queryString,
      sort: this._sort,
      sortBySpend: this._sortBySpend,
      assignedToMe: this._assignedToMe,
      hideReviewed: this._hideReviewed,
      clusterIds: this._clusterIds,
      notClusterIds: this._notClusterIds,
      searchPublishedClusterIds: this._includePublishedClusterIds,
      pinnedRecordIds: this._pinnedRecords,
      hasComments: this._hasComments,
      verificationTypeFilters: this._verificationTypeFilters,
      assignmentStatusNone: this._assignmentStatusNone,
      assignmentStatusSome: this._assignmentStatusSome,
      assignmentStatusAll: this._assignmentStatusAll,
      assignmentStatusUnassigned: this._assignmentStatusUnassigned,
      assignedToUsers: this._assignedToUsers,
      categorizedByUsers: this._categorizedByUsers,
      impactRankHigh: this._highImpact,
      sortByQuestionImpactRank: this._sortByQuestionImpactRank,
      confidenceLow: this._confidenceLow,
      confidenceMedium: this._confidenceMedium,
      confidenceHigh: this._confidenceHigh,
      confidence: (this._hasConfidence && this._confidence) ? this._confidence.toUrlString() : undefined,
      sortByConfidence: SortUtils.getBaseUrlSortState(this._sortByConfidence),
      changeStatusFilters: this._changeStatusFilters,
      geospatialEnvelopFilter: this._geospatialEnvelopFilter,
      testRecordsAccuracyFilter: this._testRecordsAccuracyFilter,
      numericFields: this._numericFields,
      fetchRecordsWithoutClusters: this._fetchRecordsWithoutClusters,
    });
  }
}

/**
 * Which columns should be shown on the page?
 */
const getVisibleColumns = (rows: List<EsRecord>, datasets: Document<Dataset>[]) => {
  const datasetsOnPage = rows
    .map(row => row.datasetId)
    .toSet();
  let cols = Set();
  for (const dataset of datasets) {
    if (datasetsOnPage.contains(dataset.id.id)) {
      const catMetadata = dataset.data.metadata.get('categorization') || {};
      // If no visible fields are selected, show all
      cols = cols.concat(_.isEmpty(catMetadata.visibleFields)
        ? dataset.data.fields
        : Set(catMetadata.visibleFields));
    }
  }
  return cols.toList();
};

/**
 * Given a blob of transaction data from the server and the configuration
 * from datasets, form the transaction data so that it is useful for display, etc.
 */
const processTxnData = (data: $TSFixMe, processHit: $TSFixMe) => {
  const total = data.total;
  const rows = Immutable.List(data.items)
    .map(processHit);
  return { total, rows };
};

export {
  TxnUrlBuilder,
  processTxnData,
  getVisibleColumns,
  DEFAULT_PAGE_SIZE,
};
