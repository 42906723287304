import { List, Map } from 'immutable';

import ProjectInfo from '../models/ProjectInfo';
import ProjectWithStatus from '../models/ProjectWithStatus';
import TileServer from '../models/TileServer';
import { $TSFixMe } from '../utils/typescript';

export const FETCH = 'Projects.fetch';
export const FETCH_COMPLETED = 'Projects.fetchCompleted';
export const RELOAD = 'Projects.reload';
export const INIT_PROJECT = 'Projects.initProject';
export const INIT_PROJECT_COMPLETED = 'Projects.initProjectCompleted';
export const INIT_PROJECT_FAILED = 'Projects.initProjectFailed';
export const DELETE_PROJECT_COMPLETED = 'Projects.deleteProjectCompleted';
export const UPDATE_PROJECT_COMPLETED = 'Projects.updateProjectCompleted';
export const SHOW_CHOOSE_NEW_PROJECT_TYPE = 'Projects.showChooseNewProjectType';
export const HIDE_CHOOSE_NEW_PROJECT_TYPE = 'Projects.hideChooseNewProjectType';
export const BEGIN_ADDING_PROJECT = 'Projects.beginAddingProject';
export const CANCEL_ADDING_PROJECT = 'Projects.cancelAddingProject';
export const BEGIN_EDITING_PROJECT = 'Projects.beginEditingProject';
export const CANCEL_EDITING_PROJECT = 'Projects.cancelEditingProject';
export const BEGIN_CONFIRMING_DELETE_PROJECT = 'Projects.beginConfirmingDeleteProject';
export const CANCEL_CONFIRMING_DELETE_PROJECT = 'Projects.cancelConfirmingDeleteProject';
export const DELETE_PROJECT = 'Projects.deleteProject';
export const SET_FILTER_TO_CATEGORIZATION = 'Projects.setFilterToCategorization';
export const SET_FILTER_TO_MASTERING = 'Projects.setFilterToMastering';
export const SET_FILTER_TO_SCHEMA_MAPPING = 'Projects.setFilterToSchemaMapping';
export const SET_FILTER_TO_GOLDEN_RECORDS = 'Projects.setFilterToGoldenRecords';
export const SET_FILTER_TO_ENRICHMENT = 'Projects.setFilterToEnrichment';
export const SET_SEARCH_QUERY = 'Projects.setSearchQuery';
export const CREATE_PROJECT = 'Projects.createProject';
export const CREATE_PROJECT_COMPLETED = 'Projects.createProjectCompleted';
export const FETCH_TILE_SERVERS = 'Projects.fetchTileServers';
export const FETCH_TILE_SERVERS_COMPLETED = 'Projects.fetchTileServersCompleted';
export const FETCH_TILE_SERVERS_FAILED = 'Projects.fetchTileServersFailed';
export const CREATE_GR_PROJECT_SET_WIZARD_STAGE = 'Projects.createGRProjectSetWizardStage';
export const CREATE_GR_PROJECT_SET_DISPLAY_NAME = 'Projects.createGRProjectSetName';
export const CREATE_GR_PROJECT_SET_DESCRIPTION = 'Projects.createGRProjectSetDescription';
export const CREATE_GR_PROJECT_SET_BOOTSTRAP_ATTRIBUTES =
  'Projects.createGRProjectSetBootstrapAttributes';
export const CREATE_GR_PROJECT_BOOTSTRAP_SEARCH_VALUE =
  'Projects.createGRProjectBootstrapSearchValue';
export const CREATE_GR_PROJECT_TOGGLE_BOOTSTRAP_ATTRIBUTE =
  'Projects.createGRProjectToggleBootstrapAttribute';
export const CREATE_GR_PROJECT_SELECT_ALL_BOOTSTRAP_ATTRIBUTES =
  'Projects.createGRProjectSelectAllBootstrapAttributes';
export const CREATE_GR_PROJECT_BACK = 'Projects.createGRProjectBack';
export const RESET_GR_PROJECT_WORKFLOW = 'Projects.resetGRProjectWorkflow';
export const FETCH_PUBLISHED_CLUSTERS_DATASET_ATTRIBUTES = 'Projects.fetchDatasetAttributes';
export const FETCH_PUBLISHED_CLUSTERS_DATASET_ATTRIBUTES_COMPLETED =
  'Projects.fetchDatasetAttributesCompleted';
export const FETCH_PUBLISHED_CLUSTERS_DATASET_ATTRIBUTES_FAILED =
  'Projects.fetchDatasetAttributesFailed';
export const SELECT_ACTIVE_MASTERING_PROJECT_FOR_GR_PROJECT =
  'Projects.selectActiveMasteringProjectForGRProject';
export const START_EDITING_PROJECT_POLICIES = 'Projects.startEditingPolicies';
export const STOP_EDITING_PROJECT_POLICIES = 'Projects.stopEditingPolicies';
export const ADD_PROJECT_TO_POLICY_AS_RESOURCE = 'Projects.addToPolicyResourceship';
export const ADD_PROJECT_TO_POLICY_AS_MEMBER = 'Projects.addToPolicyMembership';
export const REMOVE_PROJECT_FROM_POLICY_AS_RESOURCE = 'Projects.removeFromPolicyResourceship';
export const REMOVE_PROJECT_FROM_POLICY_AS_MEMBER = 'Projects.removeFromPolicyMembership';
export const UPDATE_PROJECT_POLICIES_COMPLETED = 'Projects.updatePoliciesCompleted';
export const UPDATE_PROJECT_POLICIES_FAILED = 'Projects.updatePoliciesFailed';

interface Fetch {
  type: typeof FETCH
}

interface FetchCompleted {
  type: typeof FETCH_COMPLETED
  projectsWithStatus: List<ProjectWithStatus>
  filterInfo: $TSFixMe
}

interface Reload {
  type: typeof RELOAD
}

interface InitProject {
  type: typeof INIT_PROJECT
  projectId: number
}

interface InitProjectCompleted {
  type: typeof INIT_PROJECT_COMPLETED
}

interface InitProjectFailed {
  type: typeof INIT_PROJECT_FAILED
}

interface DeleteProjectCompleted {
  type: typeof DELETE_PROJECT_COMPLETED
}

interface UpdateProjectCompleted {
  type: typeof UPDATE_PROJECT_COMPLETED
}

interface ShowChooseNewProjectType {
  type: typeof SHOW_CHOOSE_NEW_PROJECT_TYPE
}

interface HideChooseNewProjectType {
  type: typeof HIDE_CHOOSE_NEW_PROJECT_TYPE
}

interface BeginAddingProject {
  type: typeof BEGIN_ADDING_PROJECT
  projectType: $TSFixMe
}

interface CancelAddingProject {
  type: typeof CANCEL_ADDING_PROJECT
}

interface BeginEditingProject {
  type: typeof BEGIN_EDITING_PROJECT
  projectId: number
}

interface CancelEditingProject {
  type: typeof CANCEL_EDITING_PROJECT
}

interface BeginConfirmingDeleteProject {
  type: typeof BEGIN_CONFIRMING_DELETE_PROJECT
  projectId: number
}

interface CancelConfirmingDeleteProject {
  type: typeof CANCEL_CONFIRMING_DELETE_PROJECT
}

interface DeleteProject {
  type: typeof DELETE_PROJECT
}

interface SetFilterToCategorization {
  type: typeof SET_FILTER_TO_CATEGORIZATION
  value: boolean
}

interface SetFilterToMastering {
  type: typeof SET_FILTER_TO_MASTERING
  value: boolean
}

interface SetFilterToSchemaMapping {
  type: typeof SET_FILTER_TO_SCHEMA_MAPPING
  value: boolean
}

interface SetFilterToGoldenRecords {
  type: typeof SET_FILTER_TO_GOLDEN_RECORDS
  value: boolean
}

interface SetFilterToEnrichment {
  type: typeof SET_FILTER_TO_ENRICHMENT
  value: boolean
}

interface SetSearchQuery {
  type: typeof SET_SEARCH_QUERY
  searchQuery: string
}

interface CreateProject {
  type: typeof CREATE_PROJECT
}

interface CreateProjectCompleted {
  type: typeof CREATE_PROJECT_COMPLETED
  projectsWithStatus: List<ProjectWithStatus>
}

interface FetchTileServers {
  type: typeof FETCH_TILE_SERVERS
}

interface FetchTileServersCompleted {
  type: typeof FETCH_TILE_SERVERS_COMPLETED
  tileServers: List<TileServer>
}

interface FetchTileServersFailed {
  type: typeof FETCH_TILE_SERVERS_FAILED
}

interface CreateGRProjectSetWizardStage {
  type: typeof CREATE_GR_PROJECT_SET_WIZARD_STAGE
  wizardStage: string
}

interface CreateGRProjectSetDisplayName {
  type: typeof CREATE_GR_PROJECT_SET_DISPLAY_NAME
  displayName: string
}

interface CreateGRProjectSetDescription {
  type: typeof CREATE_GR_PROJECT_SET_DESCRIPTION
  description: string
}

interface CreateGRProjectSetBootstrapAttributes {
  type: typeof CREATE_GR_PROJECT_SET_BOOTSTRAP_ATTRIBUTES
  bootstrapAttributes: Map<string, boolean>
}

interface CreateGRProjectToggleBootstrapAttribute {
  type: typeof CREATE_GR_PROJECT_TOGGLE_BOOTSTRAP_ATTRIBUTE
  attributeName: string
  selectionValue: boolean
}

interface CreateGRProjectSelectAllBootstrapAttributes {
  type: typeof CREATE_GR_PROJECT_SELECT_ALL_BOOTSTRAP_ATTRIBUTES
  deselect: boolean
}

interface CreateGRProjectBootstrapSearchValue {
  type: typeof CREATE_GR_PROJECT_BOOTSTRAP_SEARCH_VALUE
  searchValue: string
}

interface CreateGRProjectBack {
  type: typeof CREATE_GR_PROJECT_BACK
}

interface ResetGRProjectWorkflow {
  type: typeof RESET_GR_PROJECT_WORKFLOW
}

interface FetchPublishedClustersDatasetAttributes {
  type: typeof FETCH_PUBLISHED_CLUSTERS_DATASET_ATTRIBUTES;
}

interface FetchPublishedDatasetAttributesCompleted {
  type: typeof FETCH_PUBLISHED_CLUSTERS_DATASET_ATTRIBUTES_COMPLETED;
  bootstrapAttributes: Map<string, boolean>
}

interface FetchPublishedDatasetAttributesFailed {
  type: typeof FETCH_PUBLISHED_CLUSTERS_DATASET_ATTRIBUTES_FAILED;
}

interface SelectActiveMasteringProjectForGRProject {
  type: typeof SELECT_ACTIVE_MASTERING_PROJECT_FOR_GR_PROJECT
  project: ProjectInfo
}

interface StartEditingPoliciesForProject {
  type: typeof START_EDITING_PROJECT_POLICIES
  projectId: number
  projectName: string
}

interface StopEditingPoliciesForProject {
  type: typeof STOP_EDITING_PROJECT_POLICIES
  projectId: number
  projectName: string
}

interface AddProjectToPolicyAsResource {
  type: typeof ADD_PROJECT_TO_POLICY_AS_RESOURCE
  policyId: number
}

interface AddProjectToPolicyAsMember {
  type: typeof ADD_PROJECT_TO_POLICY_AS_MEMBER
  policyId: number
}

interface RemoveProjectFromPolicyAsResource {
  type: typeof REMOVE_PROJECT_FROM_POLICY_AS_RESOURCE
  policyId: number
}

interface RemoveProjectFromPolicyAsMember {
  type: typeof REMOVE_PROJECT_FROM_POLICY_AS_MEMBER
  policyId: number
}

interface UpdateProjectPoliciesCompleted {
  type: typeof UPDATE_PROJECT_POLICIES_COMPLETED
}

interface UpdateProjectPoliciesFailed {
  type: typeof UPDATE_PROJECT_POLICIES_FAILED
}

export type ProjectsActionTypes
  = Fetch
  | FetchCompleted
  | Reload
  | InitProject
  | InitProjectCompleted
  | InitProjectFailed
  | DeleteProjectCompleted
  | UpdateProjectCompleted
  | ShowChooseNewProjectType
  | HideChooseNewProjectType
  | BeginAddingProject
  | CancelAddingProject
  | BeginEditingProject
  | CancelEditingProject
  | BeginConfirmingDeleteProject
  | CancelConfirmingDeleteProject
  | DeleteProject
  | SetFilterToCategorization
  | SetFilterToMastering
  | SetFilterToSchemaMapping
  | SetFilterToGoldenRecords
  | SetFilterToEnrichment
  | SetSearchQuery
  | CreateProject
  | CreateProjectCompleted
  | FetchTileServers
  | FetchTileServersCompleted
  | FetchTileServersFailed
  | CreateGRProjectSetWizardStage
  | CreateGRProjectSetDisplayName
  | CreateGRProjectSetDescription
  | CreateGRProjectSetBootstrapAttributes
  | CreateGRProjectToggleBootstrapAttribute
  | CreateGRProjectSelectAllBootstrapAttributes
  | CreateGRProjectBootstrapSearchValue
  | CreateGRProjectBack
  | ResetGRProjectWorkflow
  | FetchPublishedClustersDatasetAttributes
  | FetchPublishedDatasetAttributesCompleted
  | FetchPublishedDatasetAttributesFailed
  | SelectActiveMasteringProjectForGRProject
  | StartEditingPoliciesForProject
  | StopEditingPoliciesForProject
  | AddProjectToPolicyAsMember
  | AddProjectToPolicyAsResource
  | RemoveProjectFromPolicyAsMember
  | RemoveProjectFromPolicyAsResource
  | UpdateProjectPoliciesCompleted
  | UpdateProjectPoliciesFailed
