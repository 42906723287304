import _ from 'underscore';

import SimilarityFunction from '../schema-mapping/constants/SimilarityFunction';
import TokenizerConfig from '../schema-mapping/constants/TokenizerConfig';
import TokenWeighting from '../schema-mapping/constants/TokenWeighting';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

// com.tamr.dedup.models.dnf.ClauseEntry
class ClauseEntry extends getModelHelpers({
  fieldName: { type: ArgTypes.string },
  similarityFunction: { type: ArgTypes.valueIn(SimilarityFunction) },
  tokenizerConfig: { type: ArgTypes.orNull(ArgTypes.valueIn(TokenizerConfig)) },
  tokenWeighting: { type: ArgTypes.orNull(ArgTypes.valueIn(TokenWeighting)) },
  threshold: { type: ArgTypes.orNull(ArgTypes.number) },
  termId: { type: ArgTypes.orUndefined(ArgTypes.string) },
}, 'ClauseEntry')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ClauseEntryRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  get isNull() {
    return !_.isNumber(this.threshold);
  }

  static get argType() {
    return ArgTypes.instanceOf(ClauseEntry);
  }

  static fromJSON(obj: $TSFixMe) {
    // tokenizerConfig and threshold need to be actually `null` if missing, undefined is unacceptable
    const { fieldName, similarityFunction, tokenizerConfig, tokenWeighting, threshold, termId } = obj;
    return new ClauseEntry({
      fieldName,
      similarityFunction,
      tokenizerConfig: (tokenizerConfig || null),
      tokenWeighting: (tokenWeighting || null),
      threshold: _.isNumber(threshold) ? threshold : null,
      termId,
    });
  }

  /**
   * Checks if two ClauseEntries are equal
   *
   * Ignores whether their IDs are different
   */
  equalsIgnoreId(other: ClauseEntry) {
    checkArg({ other }, ClauseEntry.argType);
    return this.fieldName === other.fieldName
      && this.similarityFunction === other.similarityFunction
      && this.tokenizerConfig === other.tokenizerConfig
      && this.tokenWeighting === other.tokenWeighting
      && this.threshold === other.threshold;
  }
}

export default ClauseEntry;
