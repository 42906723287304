import './CategoryDetailPage.scss';

import { List, Set } from 'immutable';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';
import uri from 'urijs';

import CategoryBreadcrumbs from '../categoryDetail/CategoryBreadcrumbs';
import { fetchRecords } from '../categoryDetail/CategoryDetailApi';
import CategoryDetailLoader from '../categoryDetail/CategoryDetailLoader';
import Chrome from '../chrome/Chrome';
import PageHeader from '../chrome/PageHeader';
import Button from '../components/Button';
import LoadingPanel from '../components/LoadingPanel';
import ProgressBar from '../components/ProgressBar/ProgressBar';
import SimpleTxnTable from '../components/SimpleTxnTable';
import Term from '../components/Term';
import EsRecord from '../models/EsRecord';
import ProcurementTaxonomy from '../models/ProcurementTaxonomy';
import Recipe from '../models/Recipe';
import TaxonomyBreadcrumb from '../taxonomy/TaxonomyBreadcrumb';
import TaxonomyLoader from '../taxonomy/TaxonomyLoader';
import { getVisibleColumns } from '../transactions/TransactionUtils';
import { history } from '../utils/History';
import { getPerc, longFormat } from '../utils/Numbers';
import { getPermalink } from '../utils/Permalinks';
import PRODUCT_NAME from '../utils/ProductName';
import { getActiveRecipe, selectActiveProjectInfo } from '../utils/Selectors';
import { pluralize } from '../utils/Strings';
import { getTime } from '../utils/Time';
import { getIntOrUndef, getPath } from '../utils/Values';

const CategoryDetailPage = _.compose(
  connect((state) => {
    const { location: { recipeId }, categoryDetail: { categoryId, records, totalRecords, loading }, taxonomy: { taxonomy } } = state;
    const { unifiedDatasetDoc } = selectActiveProjectInfo(state) || {};
    const columns = (records && unifiedDatasetDoc)
      ? getVisibleColumns(records, [unifiedDatasetDoc])
      : List();
    return { recipeId, records, totalRecords, loading, columns, taxonomy, categoryId, recipe: getActiveRecipe(state) };
  }, {
    onFetchCategoryRecords: fetchRecords,
  }),
)(class CategoryDetailPage extends React.Component {
  static propTypes = {
    columns: ImmutablePropTypes.listOf(PropTypes.string).isRequired,
    loading: PropTypes.bool.isRequired,
    onFetchCategoryRecords: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    recipe: PropTypes.instanceOf(Recipe).isRequired,
    records: ImmutablePropTypes.listOf(PropTypes.instanceOf(EsRecord)).isRequired,
    taxonomy: PropTypes.instanceOf(ProcurementTaxonomy),
    totalRecords: PropTypes.number,
  };

  getCategory = () => {
    const { taxonomy, categoryId } = this.props;
    return taxonomy.categories.find(
      cat => cat.categoryId === categoryId,
    );
  };

  isPageLoading = () => {
    return !(
      this.props.taxonomy
    );
  };

  transactionsAreLoading = () => {
    return this.props.loading;
  };

  goToCategory = (categoryId) => {
    const recipeId = this.props.recipeId;
    if (_.isNumber(categoryId)) {
      history.push(`/category/recipe/${recipeId}/${categoryId}`);
    } else {
      history.push(`/taxonomy/recipe/${recipeId}`);
    }
  };

  getComputedState = () => {
    const taxonomy = this.props.taxonomy;
    const category = this.getCategory();
    const categoryId = this.props.categoryId;
    const { manualCategorizations } = category;
    const suggestedCategorizations = category.totalAggForSuggestedCategorizations;
    const categoryName = category.name;

    const totalSpend =
      manualCategorizations.spend + suggestedCategorizations.spend;

    const totalClassified =
      manualCategorizations.count + suggestedCategorizations.count;

    const manualPerc = numeral(getPerc(manualCategorizations.count, totalClassified)).format('0.0');
    const suggestedPerc = numeral(getPerc(suggestedCategorizations.count, totalClassified)).format('0.0');
    return {
      taxonomy,
      category,
      categoryName,
      manualCategorizations,
      suggestedCategorizations,
      totalSpend,
      totalClassified,
      manualPerc,
      suggestedPerc,
      categoryId,
    };
  };

  renderHeader = (computed) => {
    const breadcrumb = (
      <CategoryBreadcrumbs
        category={computed.category}
        recipeId={this.props.recipeId}
      />
    );
    return (
      <PageHeader
        title={computed.categoryName}
        breadcrumb={breadcrumb}
        searchbox={this.renderRightSection()}
      />
    );
  };

  renderRightSection = () => {
    const { createdBy, modifiedBy, modifiedAt } = this.getCategory();
    const modifiedTimestamp = getTime(modifiedAt, 'X').calendar();
    return (
      <div className="details-meta">
        <div className="modifications">
          Created by: {createdBy}. Last modified by {modifiedBy}, {modifiedTimestamp}
        </div>
        <div className="permalink">
          Permalink: {getPermalink(window.location)}
        </div>
      </div>
    );
  };

  renderTransactionBreakdown = (computed) => {
    const manualCount = computed.manualCategorizations.count;
    const suggestedCount = computed.suggestedCategorizations.count;
    const manualCountMessage = pluralize(manualCount, 'User verified categorization', 'User verified categorizations');
    const suggestedCountMessage = pluralize(suggestedCount, `${PRODUCT_NAME} categorization`, `${PRODUCT_NAME} categorizations`);
    return (
      <div className="section txn-breakdown">
        <div className="breakdown-header">
          <div className="spend-total">${longFormat(computed.totalSpend)}</div>
          {' '} total <Term>spend</Term> from {' '}
          {longFormat(computed.totalClassified)} <Term amount={computed.totalClassified}>record</Term>
        </div>
        <ProgressBar>
          <ProgressBar fillClass="manual" now={getIntOrUndef(computed.manualPerc)} />
          <ProgressBar fillClass="suggested" now={getIntOrUndef(computed.suggestedPerc)} />
        </ProgressBar>
        <div className="clearfix">
          <div className="figure manual-cat-label">
            {longFormat(manualCount)}
            <span> {manualCountMessage} &mdash; {computed.manualPerc}%</span>
          </div>
          <div className="figure suggested-cat-label">
            {longFormat(suggestedCount)}
            <span> {suggestedCountMessage} &mdash; {computed.suggestedPerc}%</span>
          </div>
        </div>
      </div>
    );
  };

  renderTaxonomyBreadcrumbs = (computed) => {
    return (
      <div className="section tax-breadcrumbs">
        <div className="details-title breadcrumbs-title">TAXONOMY</div>
        <hr />
        <TaxonomyBreadcrumb
          categoryId={computed.categoryId}
          handleSelection={this.goToCategory}
        />
      </div>
    );
  };

  renderTransactionTable = (computed) => {
    const { records, columns, totalRecords, recipe } = this.props;
    if (this.transactionsAreLoading()) {
      return <LoadingPanel />;
    }
    if (records.isEmpty()) {
      return <div />;
    }
    const includedFields = new Set(getPath(recipe, 'metadata', 'CATEGORIZATION', 'includedFields'));

    return (
      <div>
        <div className="details-row">
          <div className="details-title table-title">
            TOP <Term>RECORDS</Term> BY <Term>SPEND</Term> AMOUNT
          </div>
          <SimpleTxnTable rows={records} columns={columns} total={totalRecords} height={280} mlAttributes={includedFields} />
        </div>
        <div className="details-row">
          <div className="go-to-transactions">
            <Button
              onClick={this.goToTransactionPage}
              buttonType="Secondary"
            >
              View all {computed.categoryName} <Term>records</Term>
            </Button>
          </div>
        </div>
      </div>
    );
  };

  goToTransactionPage = () => {
    const { categoryId } = this.props;
    history.push(uri(`/spend/recipe/${this.props.recipeId}`).query({ categoryIds: [categoryId] }).toString());
  };

  render() {
    const loading = this.isPageLoading();
    const computed = loading ? {} : this.getComputedState();
    return (
      <Chrome className="category-details-container">
        {loading ? null : this.renderHeader(computed)}
        {loading ? null : (
          <div className="details-row clearfix">
            {this.renderTransactionBreakdown(computed)}
            {this.renderTaxonomyBreadcrumbs(computed)}
          </div>
        )}
        {loading ? null : this.renderTransactionTable(computed)}
        <CategoryDetailLoader />
        <TaxonomyLoader />
      </Chrome>
    );
  }
});

export default CategoryDetailPage;
