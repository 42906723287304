/* eslint-disable no-use-before-define */

import { List } from 'immutable';
import PropTypes, { Requireable } from 'prop-types';
import _ from 'underscore';

import { toJSON } from '../../models/Model';
import { ArgTypes, Checker } from '../../utils/ArgValidation';

export const RECORD_TOSTRING_INDENT = 4;

function titleCase(str: string) {
  return str.slice(0, 1).toUpperCase() + str.slice(1);
}

type SimpleJsonRepresentation
  = typeof AnyType.jsonRep
  | typeof NeverType.jsonRep
  | typeof NumberType.jsonRep
  | typeof IntType.jsonRep
  | typeof LongType.jsonRep
  | typeof FloatType.jsonRep
  | typeof DoubleType.jsonRep
  | typeof BoolType.jsonRep
  | typeof StringType.jsonRep
  | typeof FunctionType.jsonRep

export const ARRAY_JSON_TYPE = 'array';
export const RECORD_JSON_TYPE = 'record';

type FieldJsonRepresentation = {
  name: string,
  type: TypeJsonRepresentation
}
type RecordJsonRepresentation = {
  type: typeof RECORD_JSON_TYPE,
  fullySpecified: boolean,
  fields: FieldJsonRepresentation[]
}
type ArrayJsonRepresentation = {
  type: typeof ARRAY_JSON_TYPE,
  elementType: TypeJsonRepresentation
}
type ComplexJsonRepresentation = RecordJsonRepresentation | ArrayJsonRepresentation;
type TypeJsonRepresentation = SimpleJsonRepresentation | ComplexJsonRepresentation;

export interface GenericType {
  toJSON: () => TypeJsonRepresentation,
  as: (name: string) => Field,
  toString: () => string,
}

export type Type
  = AnyType
  | NeverType
  | NumberType
  | IntType
  | LongType
  | FloatType
  | DoubleType
  | BoolType
  | StringType
  | ArrayType
  | RecordType
  | FunctionType;

export type ConcreteType
  = IntType
  | LongType
  | FloatType
  | DoubleType
  | BoolType
  | StringType
  | ArrayType
  | RecordType

export function typeIsConcrete(type: Type): type is ConcreteType {
  return type instanceof IntType
    || type instanceof LongType
    || type instanceof FloatType
    || type instanceof DoubleType
    || type instanceof BoolType
    || type instanceof StringType
    || type instanceof ArrayType
    || type instanceof RecordType;
}

abstract class BasicType implements GenericType {
  abstract get jsonRep(): SimpleJsonRepresentation;
  get name(): string {
    return this.jsonRep;
  }
  toJSON(): SimpleJsonRepresentation {
    return this.jsonRep;
  }
  as(name: string): Field {
    return new Field({ name, type: this });
  }
  toString(): string {
    return titleCase(this.jsonRep);
  }
}

export class AnyType extends BasicType {
  static jsonRep = 'any' as const;
  get jsonRep() { return AnyType.jsonRep; }
}
export class NeverType extends BasicType {
  static jsonRep = 'never' as const;
  get jsonRep() { return NeverType.jsonRep; }
}
export class NumberType extends BasicType {
  static jsonRep = 'number' as const;
  get jsonRep() { return NumberType.jsonRep; }
}
export class IntType extends BasicType {
  static jsonRep = 'int' as const;
  get jsonRep() { return IntType.jsonRep; }
}
export class LongType extends BasicType {
  static jsonRep = 'long' as const;
  get jsonRep() { return LongType.jsonRep; }
}
export class FloatType extends BasicType {
  static jsonRep = 'float' as const;
  get jsonRep() { return FloatType.jsonRep; }
}
export class DoubleType extends BasicType {
  static jsonRep = 'double' as const;
  get jsonRep() { return DoubleType.jsonRep; }
}
export class BoolType extends BasicType {
  static jsonRep = 'bool' as const;
  get jsonRep() { return BoolType.jsonRep; }
}
export class StringType extends BasicType {
  static jsonRep = 'string' as const;
  get jsonRep() { return StringType.jsonRep; }
}
// TODO function is not technically a basic type according to the backend type hierarchy!
//   However, this accomplishes current requirements (function docs for HOFs), and we have not yet determined a proper JSON representation for function types.
export class FunctionType extends BasicType {
  static jsonRep = 'function';
  get jsonRep() { return FunctionType.jsonRep; }
}

export const BasicTypes = [
  AnyType,
  NeverType,
  NumberType,
  IntType,
  LongType,
  FloatType,
  DoubleType,
  BoolType,
  StringType,
  FunctionType,
];

export const any = () => new AnyType();
export const never = () => new NeverType();
export const numberType = () => new NumberType();
export const intType = () => new IntType();
export const longType = () => new LongType();
export const floatType = () => new FloatType();
export const doubleType = () => new DoubleType();
export const boolType = () => new BoolType();
export const stringType = () => new StringType();
export const functionType = () => new FunctionType();

export const typeFromJson = (json: TypeJsonRepresentation): Type => {
  if (_.isString(json)) {
    switch (json) {
      case AnyType.jsonRep: return any();
      case NeverType.jsonRep: return never();
      case NumberType.jsonRep: return numberType();
      case IntType.jsonRep: return intType();
      case LongType.jsonRep: return longType();
      case FloatType.jsonRep: return floatType();
      case DoubleType.jsonRep: return doubleType();
      case BoolType.jsonRep: return boolType();
      case StringType.jsonRep: return stringType();
      case FunctionType.jsonRep: return functionType();
    }
  }
  if (_.isObject(json) && (<Object> json).hasOwnProperty('type')) {
    json = <ComplexJsonRepresentation> json;
    if (json.type === 'record') {
      return RecordType.fromJSON(json);
    }
    if (json.type === 'array') {
      return ArrayType.fromJSON(json);
    }
  }
  console.error('Could not parse type from JSON: ', json);
  // @ts-expect-error
  return undefined;
};

// have to declare these before we've defined ArrayType / RecordType
// since those need to refer to themselves during construction for arg types
export const ArrayTypeArgType: Checker<ArrayType> = (arg) => ArgTypes.instanceOf(ArrayType)(arg);
export const RecordTypeArgType: Checker<RecordType> = (arg) => ArgTypes.instanceOf(RecordType)(arg);

export const TypeArgType: Checker<Type> = ArgTypes.oneOf(
  ArgTypes.instanceOf(AnyType),
  ArgTypes.instanceOf(NeverType),
  ArgTypes.instanceOf(NumberType),
  ArgTypes.instanceOf(IntType),
  ArgTypes.instanceOf(LongType),
  ArgTypes.instanceOf(FloatType),
  ArgTypes.instanceOf(DoubleType),
  ArgTypes.instanceOf(BoolType),
  ArgTypes.instanceOf(StringType),
  ArgTypes.instanceOf(FunctionType),
  ArrayTypeArgType,
  RecordTypeArgType,
);

export class ArrayType implements GenericType {
  readonly elementType: Type;
  constructor({ elementType }: { elementType: Type }) {
    this.elementType = elementType;
  }
  static get argType() { return ArgTypes.instanceOf(ArrayType); }
  toJSON(): ArrayJsonRepresentation {
    const type: typeof ARRAY_JSON_TYPE = ARRAY_JSON_TYPE;
    return {
      type,
      elementType: this.elementType.toJSON(),
    };
  }
  toString(): string {
    const { elementType } = this;
    return `Array<${elementType.toString()}>`;
  }
  as(name: string): Field {
    return new Field({ name, type: this });
  }
  static fromJSON(json: Omit<ArrayJsonRepresentation, 'type'>) {
    const elementType = typeFromJson(json.elementType);
    if (!elementType) {
      console.error('ArrayType::fromJSON - elementType could not be inferred from JSON.');
    }
    return new ArrayType({
      elementType: elementType as Type,
    });
  }
  static of(elementType: Type) {
    return new ArrayType({ elementType });
  }
}

export class Field {
  readonly name: string;
  readonly type: Type;
  constructor({ name, type }: { name: string, type: Type }) {
    this.name = name;
    this.type = type;
  }
  static get argType() { return ArgTypes.instanceOf(Field); }
  static fromJSON(json: FieldJsonRepresentation) {
    const type = typeFromJson(json.type);
    if (!type) {
      console.error('Field::fromJSON - type could not be inferred from JSON.');
    }
    return new Field({
      name: json.name,
      type: type as Type,
    });
  }
  toString(): string {
    const { name, type } = this;
    return `${name}: ${type.toString()}`;
  }
  withType(type: Type): Field {
    return new Field({ name: this.name, type });
  }
}

type RecordTypeMap = { [key: string]: RecordTypeMap | string };
export class RecordType implements GenericType {
  readonly fields: List<Field>;
  readonly fullySpecified: boolean;
  constructor({ fields, fullySpecified }: { fields: List<Field>, fullySpecified: boolean }) {
    this.fields = fields;
    this.fullySpecified = fullySpecified;
  }
  static get argType() { return ArgTypes.instanceOf(RecordType); }
  toJSON(): RecordJsonRepresentation {
    const type = RECORD_JSON_TYPE as typeof RECORD_JSON_TYPE;
    return {
      type,
      fullySpecified: this.fullySpecified,
      fields: toJSON(this.fields), // recursive toJSON
    };
  }
  toString(): string {
    const { fullySpecified, fields } = this;
    const ending = fullySpecified ? '}'
      : fields.size > 0
        ? ' '.repeat(RECORD_TOSTRING_INDENT) + '...\n}'
        : '...}';
    return JSON.stringify(this.toMap(), null, RECORD_TOSTRING_INDENT)
      .replace(/"/g, '')
      .replace(/}$/, ending);
  }
  toMap() {
    const { fields } = this;
    return fields.reduce((acc, f) => {
      acc[f.name] = f.type instanceof RecordType
        ? f.type.toMap() : f.type.toString();
      return acc;
    }, {} as RecordTypeMap);
  }
  as(name: string): Field {
    return new Field({ name, type: this });
  }
  getField(fieldName: string) {
    const { fields, fullySpecified } = this;
    const field = fields.find(({ name }) => name === fieldName);
    const typeIfMissing = fullySpecified ? never() : any();
    return field ? field.type : typeIfMissing;
  }
  static fromJSON(json: Omit<RecordJsonRepresentation, 'type'>) {
    return new RecordType({
      fields: List(json.fields).map(fieldType => Field.fromJSON(fieldType)),
      fullySpecified: json.fullySpecified,
    });
  }
  static fullySpecified(...fields: Field[]) {
    return new RecordType({ fullySpecified: true, fields: List(fields) });
  }
  static partiallySpecified(...fields: Field[]) {
    return new RecordType({ fullySpecified: false, fields: List(fields) });
  }
  setField(fieldIndex: number, field: Field): RecordType {
    return new RecordType({
      fullySpecified: this.fullySpecified,
      fields: this.fields.set(fieldIndex, field),
    });
  }
}

export const TypePropType = PropTypes.oneOfType([
  PropTypes.instanceOf(AnyType),
  PropTypes.instanceOf(NeverType),
  PropTypes.instanceOf(NumberType),
  PropTypes.instanceOf(IntType),
  PropTypes.instanceOf(LongType),
  PropTypes.instanceOf(FloatType),
  PropTypes.instanceOf(DoubleType),
  PropTypes.instanceOf(BoolType),
  PropTypes.instanceOf(StringType),
  PropTypes.instanceOf(FunctionType),
  PropTypes.instanceOf(ArrayType),
  PropTypes.instanceOf(RecordType),
]) as Requireable<Type>;

export const NumericPropType = PropTypes.oneOfType([
  PropTypes.instanceOf(NumberType),
  PropTypes.instanceOf(IntType),
  PropTypes.instanceOf(LongType),
  PropTypes.instanceOf(FloatType),
  PropTypes.instanceOf(DoubleType),
]);
