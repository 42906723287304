import React from 'react';

import Chrome from '../chrome/Chrome';
import CenterContent from '../components/CenterContent';

class NotFound extends React.Component {
  render() {
    return (
      <Chrome>
        <CenterContent className="unauthorized-page">
          404!  The page was not found
        </CenterContent>
      </Chrome>
    );
  }
}

export default NotFound;
