/* eslint react/no-danger:0 */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { DragSource as dragSource, DropTarget as dropTarget } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import EditableInlineText from '../components/EditableInlineText';
import Highlighter from '../components/Highlighter';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import TooltipTrigger from '../components/TooltipTrigger';
import WarningDialog from '../components/WarningDialog';
import { SCHEMA_MAPPING_RECOMMENDATIONS } from '../constants/ProjectTypes';
import { profileDataset } from '../datasets/DatasetsApi';
import { selectProfilingSchema } from '../datasets/DatasetUtils';
import { attributeId } from '../models/AttributeId';
import AttributeProfilingInfo from '../models/AttributeProfilingInfo';
import Dataset from '../models/Dataset';
import KeyMods from '../models/KeyMods';
import ProjectInfo from '../models/ProjectInfo';
import UnifiedAttribute from '../models/UnifiedAttribute';
import EditUADialog from '../records/EditUADialog';
import PRODUCT_NAME from '../utils/ProductName';
import { getUnifiedDatasetName, selectActiveProjectInfo } from '../utils/Selectors';
import RequiredAttributeType from './constants/RequiredAttributeType';
import { ControlledUACellWidth } from './ListHeaderRow';
import ListItemIcon from './ListItemIcon';
import {
  UNIFIED_BEGIN_DRAG,
  UNIFIED_SELECT_ATTRIBUTE,
  UNIFIED_TOGGLE_EXPANDED,
} from './SchemaMappingActionTypes';
import {
  ATTRIBUTE_COL_NAME,
  MAPPINGS_COL_NAME,
  mapUnified,
  toggleMlEnabled,
  updateUnifiedNameAndDescription,
} from './SchemaMappingAsync';
import style from './SchemaMappingAttributeListItem.module.scss';
import SchemaMappingProfilingInfo from './SchemaMappingProfilingInfo';
import UnifiedAttributeContextualMenu from './UnifiedAttributeContextualMenu';
import UnifiedAttributeMappingStatus from './UnifiedAttributeMappingStatus';

const UnifiedAttributeItem = _.compose(
  connect((state, { attribute }) => {
    const {
      schemaMapping: { unifiedExpanded, unifiedSelectedIds, unifiedSearchTerm, unifiedAttributesRemoved },
    } = state;
    const id = attributeId(attribute);
    const projectInfo = selectActiveProjectInfo(state);
    const profilingSchemaInfo = selectProfilingSchema(state);
    const { dataset, schema, profilingUpToDate } = profilingSchemaInfo;
    return {
      dataset: dataset && dataset.data,
      schema,
      profilingUpToDate,
      searchTerm: unifiedSearchTerm,
      isExpanded: unifiedExpanded.has(id),
      isSelected: unifiedSelectedIds.has(id),
      isRemoved: unifiedAttributesRemoved.has(id),
      datasetName: getUnifiedDatasetName(state),
      datasetId: projectInfo && projectInfo.unifiedDatasetId,
      projectInfo,
    };
  }, {
    onBeginDrag: id => ({ type: UNIFIED_BEGIN_DRAG, id }),
    onToggleExpanded: id => ({ type: UNIFIED_TOGGLE_EXPANDED, id }),
    onClick: (id, keyMods) => ({ type: UNIFIED_SELECT_ATTRIBUTE, id, keyMods }),
    onUpdateUnifiedNameAndDescription: updateUnifiedNameAndDescription,
    onToggleMlEnabled: toggleMlEnabled,
    onMap: mapUnified,
    onProfile: profileDataset,
  }),
  dragSource('unifiedAttribute', {
    beginDrag: ({ attribute, onBeginDrag }) => {
      onBeginDrag(attributeId(attribute));
      return { attribute };
    },
    canDrag: ({ attribute: { generated }, datasetName, canUserEdit }) => {
      return !generated && canUserEdit && datasetName;
    },
  }, connector => {
    return {
      connectDragPreview: connector.dragPreview(),
      connectDragSource: connector.dragSource(),
    };
  }),
  dropTarget('sourceAttribute', {
    canDrop: ({ attribute: { generated }, isRemoved }) => {
      return !generated && !isRemoved;
    },
    drop: ({ attribute, onMap }) => {
      onMap(attributeId(attribute));
    },
  }, (connector, monitor) => {
    return {
      connectDropTarget: connector.dropTarget(),
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    };
  }),
)(class UnifiedAttributeItem extends React.Component {
  static propTypes = {
    attribute: PropTypes.instanceOf(UnifiedAttribute).isRequired,
    canDrop: PropTypes.bool.isRequired,
    canUserEdit: PropTypes.bool.isRequired,
    connectDragPreview: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    dataset: PropTypes.instanceOf(Dataset),
    datasetName: PropTypes.string,
    isExpanded: PropTypes.bool.isRequired,
    isOver: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onBeginDrag: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    onMap: PropTypes.func.isRequired,
    onToggleExpanded: PropTypes.func.isRequired,
    onToggleMlEnabled: PropTypes.func.isRequired,
    onUpdateUnifiedNameAndDescription: PropTypes.func.isRequired,
    profilingUpToDate: PropTypes.bool.isRequired,
    projectInfo: PropTypes.instanceOf(ProjectInfo),
    schema: PropTypes.object,
    searchTerm: PropTypes.string.isRequired,
  };

  state = {
    showingUpdateWarningDialog: false,
    unifiedAttributeHovered: false,
  };

  showUpdateWarningDialog = () => {
    this.setState({ showingUpdateWarningDialog: true });
  };

  hideUpdateWarningDialog = () => {
    this.setState({ showingUpdateWarningDialog: false });
  };

  renderUnifiedAttributeName = () => {
    const { attribute, searchTerm, canUserEdit, onToggleExpanded, isExpanded, onClick, isSelected } = this.props;
    const { unifiedAttributeHovered } = this.state;

    const name = (
      <Highlighter
        key="attributeName"
        className={style.unifiedAttributeName}
        fullText={attribute.name}
        highlightText={searchTerm}
      />);

    const editDialog = (
      <EditUADialog
        originalAttribute={attribute}
        hoverActive={unifiedAttributeHovered && canUserEdit && !attribute.generated}
        additionalOnSave={(newName) => {
          // Preserve the expansion and selection states after rename
          const oldId = attributeId(attribute);
          const newId = attributeId({
            name: newName,
            datasetName: attribute.datasetName,
          });
          if (isExpanded) {
            onToggleExpanded(newId);
            onToggleExpanded(oldId);
          }
          if (isSelected) {
            onClick(newId, KeyMods.fromJSON({}));
          }
        }}
      />);


    return [
      name, editDialog,
    ];
  };

  renderDescription = (profileUpToDate) => {
    let profileButton;
    let description;
    const { attribute, schema, profilingUpToDate, onUpdateUnifiedNameAndDescription } = this.props;

    if (!this.props.canUserEdit || attribute.generated) {
      description = (
        <div key="description" title={attribute.description} className={classNames('description', 'disabled', { 'out-of-date': !profileUpToDate })}>
          {attribute.description}&nbsp;
        </div>
      );
    } else {
      description = (
        <EditableInlineText
          key="description"
          className={classNames('description', { 'out-of-date': !profileUpToDate })}
          commitAction={v => onUpdateUnifiedNameAndDescription(attribute, attribute.name, v)}
          placeholder="Edit Description"
          value={attribute.description}
        />
      );
    }
    const profileData = AttributeProfilingInfo.fromMetrics(attribute.name, schema.toArray(), profilingUpToDate, attribute.datasetName); // If never profiled before, do not flag as out of date
    if (profileData.profiled && !profileUpToDate) {
      profileButton = (
        <span key="profile-button" className="profile-out-of-date">
          <Button buttonType="Link" onClick={this.showUpdateWarningDialog} disabled={!this.props.canUserEdit}>
            <TooltipTrigger content="Click to profile dataset" placement="bottom">
              <span>profiling out of date</span>
            </TooltipTrigger>
          </Button>
          <WarningDialog
            actionName="Profile Dataset"
            message={'Profiling a dataset can take a while. Are you sure you want to continue?'}
            onAccept={_.partial(this.props.onProfile, attribute.datasetName)}
            onHide={this.hideUpdateWarningDialog}
            show={this.state.showingUpdateWarningDialog}
          />
          <TamrIcon className="warning-button" iconName="tamr-icon-warning" size={14} />
        </span>
      );
    }
    return [
      description,
      profileButton,
    ];
  };

  renderExpandedContent = () => {
    const { attribute, canUserEdit, schema, profilingUpToDate, projectInfo } = this.props;
    const profileData = AttributeProfilingInfo.fromMetrics(attribute.name, schema.toArray(), profilingUpToDate, attribute.datasetName);
    return (
      <div className="expanded-content">
        {this.renderDescription(profileData.profileUpToDate)}
        <SchemaMappingProfilingInfo {...profileData.toJS()} canUserEdit={canUserEdit} isUnifiedAttribute isCommitted={projectInfo.isUnifiedDatasetIndexed} />
      </div>
    );
  };

  componentDidMount() {
    // Use empty image as a drag preview so browsers don't draw it
    // and we can draw whatever we want on the custom drag layer instead.
    this.props.connectDragPreview(getEmptyImage());
  }

  render() {
    const { onToggleMlEnabled, onToggleExpanded, attribute, canDrop, connectDragSource, connectDropTarget, isExpanded, isRemoved, isSelected, isOver, onClick, projectInfo, canUserEdit } = this.props;
    const { generated, requiredAttributeType, mlEnabled } = attribute;
    const id = attributeId(attribute);
    const className = classNames('schema-mapping-attribute-list-item', 'unified', {
      expanded: isExpanded,
      selected: isSelected,
      [style.isRemoved]: isRemoved,
      generated,
      'dragging-over': isOver && canDrop,
    });
    return connectDragSource(connectDropTarget(
      <div title={isRemoved ? 'This attribute does not match the filters on this page' : undefined} className={className} onClick={generated ? null : e => onClick(id, KeyMods.fromJSON(e))} data-test-element="sm-unified-item">
        <div className="unexpanded-content">
          <ControlledUACellWidth colName={MAPPINGS_COL_NAME}>
            <UnifiedAttributeMappingStatus {...{ attribute, canUserEdit, isRemoved }} advertiseDropTarget={canDrop} />
          </ControlledUACellWidth>
          <ControlledUACellWidth
            colName={ATTRIBUTE_COL_NAME}
            className={style.unifiedAttributeNameCell}
            title={attribute.name}
            onMouseEnter={() => { this.setState({ unifiedAttributeHovered: true }); }}
            onMouseLeave={() => { this.setState({ unifiedAttributeHovered: false }); }}
            onBlur={() => { this.setState({ unifiedAttributeHovered: false }); }}
            >
            <ListItemIcon
              onClick={() => onToggleExpanded(id)}
              iconName={isExpanded ? 'tamr-icon-rounded-arrow-down' : 'tamr-icon-rounded-arrow-right'}
            />
            {this.renderUnifiedAttributeName()}
            {generated && (<div className={style.deactiveRightSide}>
              <ListItemIcon
                iconName="tamr-icon-logo"
                size={14}
                tooltip={<span>This attribute was created by {PRODUCT_NAME}</span>}
              />
              <ListItemIcon iconName="tamr-icon-more-vert" size={16} />
            </div>)}
            {!generated && (<div className={style.rightSide}>
              {requiredAttributeType === RequiredAttributeType.SUPPLIER && (
                <ListItemIcon
                  iconName="group-work"
                  iconClassName={style.activeIcon}
                  tooltip={<span>This attribute represents the <Term>Supplier</Term> value</span>}
                />
              )}
              {requiredAttributeType === RequiredAttributeType.SPEND && (
                <ListItemIcon
                  iconName="attach-money"
                  iconClassName={style.activeIcon}
                  tooltip={<span>This attribute represents the <Term>Spend</Term> value</span>}
                />
              )}
              {projectInfo.projectType !== SCHEMA_MAPPING_RECOMMENDATIONS && (
                <ListItemIcon
                  iconClassName={mlEnabled ? style.activeIcon : undefined}
                  onClick={canUserEdit ? () => onToggleMlEnabled(id) : undefined}
                  iconName={mlEnabled ? 'tamr-icon-ml-on' : 'tamr-icon-ml-off'}
                  tooltip={`This attribute will ${mlEnabled ? '' : 'not'} be included in machine learning`}
                />
              )}
              <UnifiedAttributeContextualMenu {...{ attribute, canUserEdit }} />
            </div>)}
          </ControlledUACellWidth>
        </div>
        {isExpanded ? this.renderExpandedContent() : null}
      </div>,
    ));
  }
});

export default UnifiedAttributeItem;
