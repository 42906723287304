import React from 'react';
import { connect } from 'react-redux';

import FilterButton from '../components/FilterButton';
import { onlyInputDatasets } from '../datasetFilter/DatasetFilterAsync';
import DatasetFilterDialog from '../datasetFilter/DatasetFilterDialog';
import {
  CLOSE_SOURCE_DATASET_FILTER_DIALOG, CLOSE_UNIFIED_DATASET_FILTER_DIALOG,
  OPEN_SOURCE_DATASET_FILTER_DIALOG, OPEN_UNIFIED_DATASET_FILTER_DIALOG,
  RESET_SOURCE_FILTER_DATASETS, RESET_UNIFIED_FILTER_DATASETS, SET_UNIFIED_FILTER_DATASETS,
  UPDATE_SOURCE_FILTER_DATASETS,
} from './SchemaMappingActionTypes';

const SchemaMappingDatasetFilter = connect((state) => {
  const modifyQuery = onlyInputDatasets(state);
  return { modifyQuery };
})(({ show, onHide, title, onSubmit, onOpen, onClear, selectedDatasets, modifyQuery }) => {
  const hasDatasets = !!selectedDatasets.size;
  return (
    <div>
      <FilterButton onClick={onOpen} filterExpanded={show} hasFilters={hasDatasets} onClear={hasDatasets ? onClear : undefined} />
      <DatasetFilterDialog
        {...{ show, onHide, title, onSubmit, modifyQuery }}
        getSelectedDatasets={() => selectedDatasets}
      />
    </div>
  );
});

export const SchemaMappingSADatasetFilter = connect(state => {
  const { schemaMapping: { sourceFilterDatasets, sourceDatasetFilterDialogVisible } } = state;
  return {
    show: sourceDatasetFilterDialogVisible,
    title: 'Select source datasets',
    selectedDatasets: sourceFilterDatasets,
  };
}, {
  onHide: () => ({ type: CLOSE_SOURCE_DATASET_FILTER_DIALOG }),
  onSubmit: (datasetsToAdd, datasetsToRemove) => ({ type: UPDATE_SOURCE_FILTER_DATASETS, datasetsToAdd, datasetsToRemove }),
  onOpen: () => ({ type: OPEN_SOURCE_DATASET_FILTER_DIALOG }),
  onClear: () => ({ type: RESET_SOURCE_FILTER_DATASETS }),
})(SchemaMappingDatasetFilter);

export const SchemaMappingUADatasetFilter = connect(state => {
  const { schemaMapping: { unifiedFilterDatasets, unifiedDatasetFilterDialogVisible } } = state;
  return {
    show: unifiedDatasetFilterDialogVisible,
    title: 'Select mapped datasets',
    selectedDatasets: unifiedFilterDatasets,
  };
}, {
  onHide: () => ({ type: CLOSE_UNIFIED_DATASET_FILTER_DIALOG }),
  onSubmit: (datasetsToAdd, datasetsToRemove) => ({ type: SET_UNIFIED_FILTER_DATASETS, datasetsToAdd, datasetsToRemove }),
  onOpen: () => ({ type: OPEN_UNIFIED_DATASET_FILTER_DIALOG }),
  onClear: () => ({ type: RESET_UNIFIED_FILTER_DATASETS }),
})(SchemaMappingDatasetFilter);

export default SchemaMappingDatasetFilter;
