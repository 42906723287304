import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import style from './SplitGrid.module.scss';

const idsListPropType = ImmutablePropTypes.listOf(PropTypes.number.isRequired);

const splitGridPropTypes = {
  /**
   * The ids of the items to render in the bottom section of the table (below the line)
   */
  bottomIds: idsListPropType.isRequired,
  /**
   * Function that returns an additional className to apply to each cell in the grid
   *
   * Called with the ID of the row item:
   * `cellClassNameGetter({ id })`
   */
  cellClassNameGetter: PropTypes.func,
  /**
   * The children to render inside of the grid
   *
   * Expected to be SplitGrid.Columns
   */
  children: PropTypes.arrayOf(PropTypes.node),
  /**
   * Optional className to apply to the entire grid container
   */
  className: PropTypes.string,
  /**
   * The ids of the items to render in the top section of the table (above the line)
   *
   * Will be passed into each of the children components
   */
  topIds: idsListPropType.isRequired,
};

type SplitGridProps = InferProps<typeof splitGridPropTypes>;

/**
 * A grid component that renders two chunks of data: "above the line" and "below the line"
 */
const SplitGrid: React.FC<SplitGridProps> = ({
  className,
  children,
  topIds,
  bottomIds,
  cellClassNameGetter,
}) => (
  <div className={classNames(style.splitGrid, className)}>
    {React.Children.map(children, child =>
      // @ts-expect-error unsure how to properly clone children...
      React.cloneElement(child, { topIds, bottomIds, cellClassNameGetter }))}
  </div>
);

SplitGrid.propTypes = splitGridPropTypes;

const columnPropTypes = {
  /**
   * The ids of the items to render in the bottom section of the table (below the line)
   *
   * Will be passed-in via the SplitGrid component
   */
  bottomIds: idsListPropType.isRequired,
  cellClassNameGetter: PropTypes.func,
  /**
   * Optional className to apply to the entire column container
   */
  className: PropTypes.string,
  /**
   * Optional className to apply to the header row
   */
  headerClassName: PropTypes.string,
  /**
   * Function called for each row, should return the desired cell contents (can return null)
   */
  renderCell: PropTypes.func.isRequired,
  /**
   * Function called to render the header cell contents (can return null)
   */
  renderHeader: PropTypes.func.isRequired,
  /**
   * The ids of the items to render in the top section of the table (above the line)
   *
   * Will be passed-in via the SplitGrid component
   */
  topIds: idsListPropType.isRequired,
};

type ColumnProps = InferProps<typeof columnPropTypes>;

export const Column: React.FC<ColumnProps> = ({
  className,
  headerClassName,
  topIds,
  bottomIds,
  renderHeader,
  renderCell,
  cellClassNameGetter,
}) => {
  return (
    <div className={classNames(style.column, className)}>
      {/* add space character in each of these divs they don't get height 0 if result of render is null */}
      <div className={classNames(style.headerCell, headerClassName)}>{renderHeader()}&nbsp;</div>
      {topIds.map(id =>
        <div key={String(id)} className={classNames(style.cellContainer, cellClassNameGetter && cellClassNameGetter({ id }))}>
          {renderCell({ id })}&nbsp;
        </div>,
      )}
      {topIds.isEmpty() || bottomIds.isEmpty() ? null : <div className={style.divider} />}
      {bottomIds.map(id =>
        <div key={String(id)} className={classNames(style.cellContainer, cellClassNameGetter && cellClassNameGetter({ id }))}>
          {renderCell({ id })}&nbsp;
        </div>,
      )}
    </div>
  );
};

Column.propTypes = columnPropTypes;

export default SplitGrid;
