import enumify from '../../constants/enumify';

// com.tamr.connect.primitives.signals.SignalSpec.TokenWeighting

export enum TokenWeightingE {
  NONE = 'NONE',
  IDF = 'IDF',
}

export const { NONE, IDF } = TokenWeightingE;

export default enumify(TokenWeightingE);
