import classNames from 'classnames';
import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import { compose } from 'underscore';

import { policyString } from '../accessControl/PoliciesList';
import Button from '../components/Button';
import CheckboxColumn from '../components/CheckboxColumn';
import ColumnWidthProvider from '../components/ColumnWidthProvider';
import SortableHeaderCell from '../components/SortableHeaderCell';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Pager from '../components/Table/PagerModel';
import Table from '../components/Table/Table';
import TextHeaderCell from '../components/TextHeaderCell';
import TooltipTrigger from '../components/TooltipTrigger';
import withKeyState, { InjectedKeyStateProps } from '../components/WithKeyState';
import * as UsersActions from '../constants/UsersActionTypes';
import KeyMods from '../models/KeyMods';
import MinimalAuthUser from '../models/MinimalAuthUser';
import { AppAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { unixToReadableDateTime } from '../utils/Time';
import UserPoliciesDialog from './UserPoliciesDialog';
import { setPageNumber, setPageSize, setShowEditAccountModal, toggleSort } from './UsersAsync';
import style from './UsersTable.module.scss';


const CONNECTED_COLUMN_WIDTH = 40;
const USERNAME_COLUMN_DEFAULT_WIDTH = 100;
const NAME_COLUMN_DEFAULT_WIDTH = 120;
const ROLES_COLUMN_DEFAULT_WIDTH = 90;
const ADMIN_COLUMN__WIDTH = 100;
const EMAIL_COLUMN_DEFAULT_WIDTH = 120;
const GROUPS_COLUMN_DEFAULT_WIDTH = 120;
const LAST_LOGGED_IN_COLUMN_DEFAULT_WIDTH = 60;

const mapStateToProps = (state: AppState) => {
  const {
    users: { selectedRowNumbers, activeRowNumber, fullUsers, sortFieldName, sortDirection, pageNumber, pageSize, totalUsersMatchingQuery },
    projects: { projectsWithStatus }, accessControl: { policyDocs },
  } = state;
  return {
    numberOfUsers: fullUsers.size,
    activeRowNumber,
    selectedRowNumbers,
    users: fullUsers,
    projectDocs: projectsWithStatus.map(pws => pws.project),
    sortFieldName,
    sortDirection,
    pageSizes: List.of(25, 50, 100),
    pagerState: new Pager(fullUsers, totalUsersMatchingQuery, pageNumber, pageSize),
    policyDocs,
  };
};

const mapDispatchToProps = {
  onEditUser: setShowEditAccountModal,
  onActiveCellChange: (event: ExtendedKeyboardEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>, rowNumber: number): AppAction => ({ type: UsersActions.SET_ACTIVE_ROW_NUMBER, rowNumber }),
  onRowClick: (keyMods: KeyMods, rowNumber: number): AppAction => ({ type: UsersActions.SELECT_ROW, keyMods, rowNumber }),
  onSelectAllRows: (): AppAction => ({ type: UsersActions.SELECT_ALL_ROWS }),
  onClickHeader: toggleSort,
  onPageChange: setPageNumber,
  onPageSizeChange: setPageSize,
  onStartEditingPolicyMembership: (user: MinimalAuthUser): AppAction => ({ type: 'Users.startEditingPolicyMembership', user }),
};

type UsersTableProps
  = ReturnType<typeof mapStateToProps>
  & typeof mapDispatchToProps
  & InjectedKeyStateProps;

const UsersTable: React.FC<UsersTableProps> = ({ numberOfUsers, activeRowNumber, selectedRowNumbers, users, onRowClick, onActiveCellChange,
  onSelectAllRows, shiftKey, sortFieldName, sortDirection, onClickHeader, pageSizes, pagerState, onPageChange,
  onPageSizeChange, policyDocs, onStartEditingPolicyMembership, onEditUser }) => (
    <div className={classNames(style.usersTableContainer, { unselectable: shiftKey })}>
      <UserPoliciesDialog />
      <AutoSizer>
        {({ width, height }) => (
          <ColumnWidthProvider>
            <Table
              {...{ width, height }}
              tableType="stripes"
              getLength={() => numberOfUsers}
              activeRowNumber={activeRowNumber}
              selectedRowNumbers={selectedRowNumbers}
              onActiveCellChange={onActiveCellChange}
              onRowClick={(event, rowNumber) => onRowClick(KeyMods.fromJSON(event), rowNumber)}
              onToggleRow={rowNumber => onRowClick(new KeyMods({}).enableToggle(), rowNumber)}
              pageSizes={pageSizes}
              pagerState={pagerState}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
            >
              {CheckboxColumn({
                selected: index => selectedRowNumbers.has(index),
                allSelected: selectedRowNumbers.size === users.size,
                onToggle: (rowNumber, selected, event) => onRowClick(KeyMods.fromJSON(event).enableToggle(), rowNumber),
                onToggleAll: onSelectAllRows,
              })}
              <Column
                columnKey="connected"
                key="connected"
                width={CONNECTED_COLUMN_WIDTH}
                header={(<Cell />)}
                cell={({ rowIndex }) => (
                  <Cell className={style.loggedInIndicatorCell}>
                    {users.get(rowIndex)?.volatileStatistics.has('lastActivityTime')
                      ? (
                        <TooltipTrigger
                          placement="bottom"
                          content={<span>Logged in since:<br />{unixToReadableDateTime(users.get(rowIndex)?.loginTime)}</span>}
                        >
                          <div className={style.loggedInIndicator} />
                        </TooltipTrigger>)
                      : null
                    }
                  </Cell>
                )}
              />
              <Column
                columnKey="username"
                key="username"
                width={USERNAME_COLUMN_DEFAULT_WIDTH}
                flexGrow={1}
                header={<SortableHeaderCell col="USERNAME" display="Username" sortState={sortFieldName === 'USERNAME' ? sortDirection : null} sortCallback={onClickHeader} />}
                cell={({ rowIndex }) =>
                  <Cell>
                    <div className={classNames(style.cell, style.usernameCell)}>
                      <span className={style.username}>{users.get(rowIndex)?.username}</span>
                      <TooltipTrigger placement="top" content={<span>Edit user</span>}>
                        <Button
                          className={style.editButton}
                          buttonType="Link"
                          icon="edit"
                          iconSize={16}
                          onClick={() => onEditUser(rowIndex)}
                        />
                      </TooltipTrigger>
                    </div>
                  </Cell>}
                isResizable
              />
              <Column
                columnKey="name"
                key="name"
                width={NAME_COLUMN_DEFAULT_WIDTH}
                flexGrow={1}
                header={<SortableHeaderCell col="GIVEN" display="Name" sortState={sortFieldName === 'GIVEN' ? sortDirection : null} sortCallback={onClickHeader} />}
                cell={({ rowIndex }) => <Cell>{users.get(rowIndex)?.user.name}</Cell>}
                isResizable
              />
              <Column
                columnKey="policyMembership"
                key="policyMembership"
                width={ROLES_COLUMN_DEFAULT_WIDTH}
                flexGrow={1}
                header={<TextHeaderCell>Member of policy</TextHeaderCell>}
                cell={({ rowIndex }) => {
                  const fullUser = users.get(rowIndex);
                  if (!fullUser) return <Cell />;
                  const numberOfPolicies = policyDocs.filter(doc => doc.data.roleForUsername(fullUser.username)).size;
                  return (
                    <Cell>
                      <TooltipTrigger
                        content={fullUser?.admin && 'User already has full access due to admin status'}
                        placement="right"
                      >
                        <Button
                          className={classNames(style.policyCount, { [style.disabled]: fullUser?.admin })}
                          buttonType="Link"
                          onClick={() => onStartEditingPolicyMembership(fullUser.toMinimalAuthUser())}
                          disabled={fullUser?.admin}
                        >
                          {fullUser?.admin
                            ? '--'
                            : policyString(numberOfPolicies)
                          }
                        </Button>
                      </TooltipTrigger>
                    </Cell>
                  );
                }}
                isResizable
              />
              <Column
                columnKey="admin"
                key="admin"
                width={ADMIN_COLUMN__WIDTH}
                header={<TextHeaderCell>Admin</TextHeaderCell>}
                cell={({ rowIndex }) => {
                  const isAdmin = users.get(rowIndex)?.admin;
                  return (
                    <Cell>
                      <Button
                        className={style.policyCount}
                        buttonType="Link"
                        onClick={() => onEditUser(rowIndex)}
                      >
                        {isAdmin ? 'Yes' : 'No'}
                      </Button>
                    </Cell>
                  );
                }}
                isResizable
              />
              <Column
                columnKey="email"
                key="email"
                width={EMAIL_COLUMN_DEFAULT_WIDTH}
                flexGrow={1}
                header={<SortableHeaderCell col="EMAIL" display="Email" sortState={sortFieldName === 'EMAIL' ? sortDirection : null} sortCallback={onClickHeader} />}
                cell={({ rowIndex }) => <Cell>{users.get(rowIndex)?.user.email}</Cell>}
                isResizable
              />
              <Column
                columnKey="groups"
                key="groups"
                width={GROUPS_COLUMN_DEFAULT_WIDTH}
                flexGrow={1}
                header={<Cell>Groups</Cell>}
                cell={({ rowIndex }) => <Cell>{users.get(rowIndex)?.groups.join(', ')}</Cell>}
                isResizable
              />
              <Column
                columnKey="last-logged-in"
                key="last-logged-in"
                width={LAST_LOGGED_IN_COLUMN_DEFAULT_WIDTH}
                flexGrow={1}
                header={<SortableHeaderCell col="LOGIN_TIME" display="Last logged in" sortState={sortFieldName === 'LOGIN_TIME' ? sortDirection : null} sortCallback={onClickHeader} />}
                cell={({ rowIndex }) => {
                  const user = users.get(rowIndex);
                  if (!user) return <Cell />;
                  const { loginTime } = user;
                  return (
                    <Cell>
                      {loginTime ? unixToReadableDateTime(loginTime) : '--'}
                    </Cell>
                  );
                }}
                isResizable
              />
            </Table>
          </ColumnWidthProvider>
        )}
      </AutoSizer>
    </div>
);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withKeyState,
)(UsersTable);
