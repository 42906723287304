import { ThunkDispatch } from '@reduxjs/toolkit';

import * as DedupClient from '../api/DedupClient';
import * as ProcurifyClient from '../api/ProcurifyClient';
import { AppAction, AppThunkAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import * as Result from '../utils/Result';


const fetchElasticConfig = (dispatch: ThunkDispatch<AppState, undefined, AppAction>) => {
  ProcurifyClient.getElasticConfig().then(Result.handler(
    ({ maxResultWindow, maxGeospatialFeaturesDefault }) =>
      dispatch({ type: 'Config.fetchElasticConfigCompleted', maxResultWindow, maxGeospatialFeaturesDefault }),
    () => {}, // don't handle error
  ));
};

const fetchCategorizationStrengthThresholds = (dispatch: ThunkDispatch<AppState, undefined, AppAction>) => {
  ProcurifyClient.getCategorizationStrengthThresholds().then(Result.handler(
    categorizationStrengthThresholds =>
      dispatch({ type: 'Config.fetchCategorizationStrengthThresholdsCompleted', categorizationStrengthThresholds }),
    () => {}, // don't handle error
  ));
};

const fetchPairConfidenceThresholds = (dispatch: ThunkDispatch<AppState, undefined, AppAction>) => {
  DedupClient.getPairConfidenceThresholds().then(Result.handler(
    pairConfidenceThresholds =>
      dispatch({ type: 'Config.fetchPairConfidenceThresholdsCompleted', pairConfidenceThresholds }),
    () => {}, // don't handle error
  ));
};

const fetchClusterConfidenceThresholds = (dispatch: ThunkDispatch<AppState, undefined, AppAction>) => {
  DedupClient.getClusterConfidenceThresholds().then(Result.handler(
    clusterConfidenceThresholds =>
      dispatch({ type: 'Config.fetchClusterConfidenceThresholdsCompleted', clusterConfidenceThresholds }),
    () => {}, // don't handle error
  ));
};

export const fetchAllConfigs = (): AppThunkAction<void> => (dispatch) => {
  fetchElasticConfig(dispatch);
  fetchCategorizationStrengthThresholds(dispatch);
  fetchPairConfidenceThresholds(dispatch);
  fetchClusterConfidenceThresholds(dispatch);
};
