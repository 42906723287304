import { Map, Set } from 'immutable';
import _ from 'underscore';

import Model from '../models/Model';
import { RECIPE_INPUTS_KEY } from '../projects/RecipeConstants';
import { ArgTypes } from '../utils/ArgValidation';
import { SUBMIT_COMPLETED } from './ManagedProjectDatasetsActionTypes';

export function getProjectNameToMemberDatasetNames(projectDocs, datasetDocs) {
  let projectNameToMemberDatasetNames = new Map();
  projectDocs.forEach(projectDoc => {
    const projectRecipeIds = projectDoc.data.steps.map(docId => docId.id).toArray();
    const memberDatasetDocs = datasetDocs.filter(datasetDoc => {
      const recipeInputs = datasetDoc.data.metadata.get(RECIPE_INPUTS_KEY);
      return !_.isEmpty(_.intersection(recipeInputs, projectRecipeIds));
    });
    projectNameToMemberDatasetNames =
      projectNameToMemberDatasetNames.set(projectDoc.data.name, memberDatasetDocs.map(doc => doc.data.name).toSet());
  });
  return projectNameToMemberDatasetNames;
}

export const initialState = new (Model({
  managingProjectsForDatasets: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: new Set() },
  editingChanges: { // for staged changes to project membership
    type: ArgTypes.Immutable.map.of(
      ArgTypes.bool, // values are toggle membership on/off state
      ArgTypes.name, // keys are project names
    ),
    defaultValue: new Map() },
  searchValue: { type: ArgTypes.string, defaultValue: '' },
  confirming: { type: ArgTypes.bool, defaultValue: false },
}))();

export const reducers = {
  'ManageProjectDatasets.startManagingForDatasets': (state, { datasets }) => {
    return state.set('managingProjectsForDatasets', datasets);
  },
  'ManageProjectDatasets.cancel': (state) => {
    return state.clear();
  },
  [SUBMIT_COMPLETED]: (state) => {
    return state.clear();
  },
  'ManageProjectDatasets.makeEditingChange': (state, { projectName, selectionValue }) => {
    return state.setIn(['editingChanges', projectName], selectionValue);
  },
  'ManageProjectDatasets.setSearchValue': (state, { searchValue }) => {
    return state.merge({ searchValue });
  },
  'ManageProjectDatasets.startConfirming': (state) => {
    return state.set('confirming', true);
  },
};
