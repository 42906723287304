import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import ConditionalButton from '../components/ConditionalButton';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Input from '../components/Input/Input';
import LoadingPanel from '../components/LoadingPanel';
import PopoverTrigger from '../components/PopoverTrigger';
import TamrIcon from '../components/TamrIcon';
import Toggle from '../components/Toggle';
import TooltipTrigger from '../components/TooltipTrigger';
import * as UsersActions from '../constants/UsersActionTypes';
import { AppAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import PRODUCT_NAME from '../utils/ProductName';
import { pluralize } from '../utils/Strings';
import style from './AccountDialog.module.scss';
import { submitAccountDialog } from './UsersAsync';
import { getAccountManagementSubmitPreconditions, isEditUserAlwaysAdmin, isEmailValid, isPassword2Invalid, isPasswordInvalid, isUsernameInvalid } from './UsersStore';
import AccountConfig from './utils/AccountConfig';


const ADMIN_MESSAGE = 'The Administrator role is required for dataset, project and user management.';
const EXTERNAL_MESSAGE = `Enabling this will clear any internal ${PRODUCT_NAME} password that has been created or updated. User metadata will also be synced from any external system used to authenticate.`;
const ACTIVE_MESSAGE = `Disabling this will prevent this user from logging into ${PRODUCT_NAME} or accessing any of its services.`;
const INACTIVE_TOOLTIP_MESSAGE = 'This user is inactive.  Activate them to toggle whether they are ';

const AccountDialog = connect((state: AppState) => {
  const { users: { accountConfig, showAccountModal, editAccountMode, groups, userRolesLoading } } = state;
  return {
    show: showAccountModal,
    isEditMode: editAccountMode,
    accountConfig,
    usernameInvalid: isUsernameInvalid(state),
    passwordInvalid: isPasswordInvalid(state),
    password2Invalid: isPassword2Invalid(state),
    emailValid: isEmailValid(state),
    editUserAlwaysAdmin: isEditUserAlwaysAdmin(state),
    preconditions: getAccountManagementSubmitPreconditions(state),
    groups,
    userRolesLoading,
  };
}, {
  onChangeConfig: (accountConfig: AccountConfig): AppAction => ({ type: UsersActions.SET_ACCOUNT_CONFIG, accountConfig }),
  onHide: (): AppAction => ({ type: UsersActions.SET_SHOW_ACCOUNT_MODAL, showAccountModal: false }),
  onSubmit: submitAccountDialog,
})(({ show, isEditMode, accountConfig, usernameInvalid, passwordInvalid, password2Invalid, emailValid, editUserAlwaysAdmin, preconditions, onChangeConfig, onHide, onSubmit, groups, userRolesLoading }) => (
  <Dialog
    {...{ show, onHide }}
    className={style.accountDialog}
    dialogStyle={DialogStyle.FULL}
    title={isEditMode ? 'Edit user' : 'Create new user'}
    header
    body={userRolesLoading
      ? <LoadingPanel />
      : (<div className={style.accountDialogContent}>
        <div className={style.detailsSection}>
          <div className={style.userForm}>
            <div className={style.nameSection}>
              <Input title="First" componentClassName={style.nameInput} onChange={given => onChangeConfig(accountConfig.set('given', given))} value={accountConfig.given || ''} disabled={accountConfig.externallyManaged || undefined} />
              <Input title="Last" componentClassName={style.nameInput} onChange={surname => onChangeConfig(accountConfig.set('surname', surname))} value={accountConfig.surname || ''} disabled={accountConfig.externallyManaged || undefined} />
            </div>
            <Input title="Email" componentClassName={style.emailInput} invalid={!emailValid} onChange={email => onChangeConfig(accountConfig.set('email', email))} value={accountConfig.email || ''} disabled={accountConfig.externallyManaged || undefined} />
            <Input title={isEditMode ? 'Username' : 'Username*'} componentClassName={style.usernameInput} invalid={accountConfig.username !== '' && usernameInvalid} disabled={isEditMode} onChange={username => onChangeConfig(accountConfig.set('username', username))} value={accountConfig.username} />
            <div className={style.passwordSection}>
              <Input title={isEditMode ? 'Change password' : 'Password*'} componentClassName={style.passwordInput} invalid={passwordInvalid} type="password" onChange={password => onChangeConfig(accountConfig.set('password', password))} value={accountConfig.password} disabled={accountConfig.externallyManaged || undefined} />
              <Input title={isEditMode ? 'Re-enter new password' : 'Re-enter password*'} componentClassName={style.passwordInput} invalid={password2Invalid} type="password" onChange={password2 => onChangeConfig(accountConfig.set('password2', password2))} value={accountConfig.password2} disabled={accountConfig.externallyManaged || undefined} />
            </div>
            <div className={style.passwordExplanation}>
              Password must have a minimum of 8 characters and a maximum of 64 characters
            </div>
          </div>
          {editUserAlwaysAdmin ? null : (
            <div className={style.toggleSection}>
              <div className={style.toggleSubsection}>
                <TooltipTrigger placement="bottom" content={accountConfig.deactivated ? <span>{INACTIVE_TOOLTIP_MESSAGE} admin</span> : undefined}>
                  <Toggle className={style.toggle} disabled={editUserAlwaysAdmin || accountConfig.deactivated} onChange={() => onChangeConfig(accountConfig.toggleAdmin())} value={accountConfig.admin} />
                </TooltipTrigger>
                Admin
                <TooltipTrigger placement="bottom" content={<span>{ADMIN_MESSAGE}</span>}>
                  <TamrIcon className={style.info} iconName="info-outline" size={16} />
                </TooltipTrigger>
              </div>
              {!isEditMode ? null : (
                <div className={style.toggleSubsection}>
                  <TooltipTrigger placement="bottom" content={accountConfig.deactivated ? <span>{INACTIVE_TOOLTIP_MESSAGE} externally managed</span> : undefined}>
                    <Toggle className={style.toggle} disabled={editUserAlwaysAdmin || accountConfig.deactivated} onChange={externallyManaged => onChangeConfig(accountConfig.set('externallyManaged', externallyManaged))} value={!!accountConfig.externallyManaged} />
                  </TooltipTrigger>
                  Manage externally
                  <TooltipTrigger placement="bottom" content={<span>{EXTERNAL_MESSAGE}</span>}>
                    <TamrIcon className={style.info} iconName="info-outline" size={16} />
                  </TooltipTrigger>
                </div>
              )}
              <div className={style.toggleSubsection}>
                <Toggle className={style.toggle} disabled={editUserAlwaysAdmin} onChange={deactivated => onChangeConfig(accountConfig.set('deactivated', !deactivated))} value={!accountConfig.deactivated} />
                Active
                <TooltipTrigger placement="bottom" content={<span>{ACTIVE_MESSAGE}</span>}>
                  <TamrIcon className={style.info} iconName="info-outline" size={16} />
                </TooltipTrigger>
              </div>
            </div>
          )}
        </div>
        <div className={style.permissionsSession}>
          <div className={style.sectionHeader}>
            <div className={style.membership}>
              Member of&nbsp;{accountConfig.groups.size + pluralize(accountConfig.groups.size, ' group', ' groups')}
              {groups.size === 0 ? null : (
                <PopoverTrigger
                  className={style.popover}
                  placement="right"
                  content={
                    <div>
                      {groups.map(({ data: { groupname, admin } }) =>
                        (<div key={groupname} className={style.groupRow} onClick={() => onChangeConfig(accountConfig.toggleGroup(groupname))}>
                          <TamrIcon className={style.groupCheck} iconName={accountConfig.groups.has(groupname) ? 'tamr-icon-checkbox-checked' : 'tamr-icon-checkbox-unchecked'} size={14} />
                          <span className={style.groupName}>{groupname}</span>
                          <span className={style.groupRoles}>{admin ? 'Admin for all projects' : null}</span>
                        </div>),
                      )}
                    </div>
                  }
                >
                  <TamrIcon iconName="edit" className={style.groupsButton} size={18} />
                </PopoverTrigger>
              )}
            </div>
          </div>
        </div>
      </div>)
    }
    footer={
      <ButtonToolbar>
        <Button buttonType="Secondary" onClick={onHide}>Cancel</Button>
        <ConditionalButton preconditions={preconditions} onClick={onSubmit}>{isEditMode ? 'Update user' : 'Create user'}</ConditionalButton>
      </ButtonToolbar>
    }
  />
));

export default AccountDialog;
