import { is } from 'immutable';
import _ from 'underscore';

import createLoader from '../utils/createLoader';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { fetchAll } from './AllSourceDatasetsApi';
import { getFilterInfo } from './AllSourceDatasetsStore';

const AllSourceDatasetsLoader = createLoader((state) => {
  const { allSourceDatasets } = state;
  const { loading, loadedFilterInfo } = allSourceDatasets;
  const { projectId, hasSMRecipe } = selectActiveProjectInfo(state) || {};
  return {
    canFetch: _.isNumber(projectId) && hasSMRecipe,
    shouldFetch: !is(loadedFilterInfo, getFilterInfo(allSourceDatasets, projectId)),
    loading,
  };
}, {
  onFetch: fetchAll,
}, 'AllSourceDatasetsLoader');

export default AllSourceDatasetsLoader;
