import { List, Map } from 'immutable';

import { PolicyMemberTypeE } from '../constants/PolicyMemberType';
import { PolicyResourceTypeE } from '../constants/PolicyResourceType';
import AuthorizationPolicy from '../models/AuthorizationPolicy';
import Document from '../models/doc/Document';
import Group from '../models/Group';
import MinimalAuthUser from '../models/MinimalAuthUser';
import { PolicyFilterAndCacheInfo } from './AccessControlStore';

export const RELOAD_POLICIES = 'AccessControl.reloadPolicies';
export const FETCH_ALL_POLICIES = 'AccessControl.fetchAllPolicies';
export const FETCH_ALL_POLICIES_COMPLETED = 'AccessControl.fetchAllPoliciesCompleted';
export const FETCH_ALL_POLICIES_FAILED = 'AccessControl.fetchAllPoliciesFailed';
export const STOP_CONFIG_POLICY = 'AccessControl.stopConfigPolicy';
export const START_CREATE_POLICY = 'AccessControl.startCreatePolicy';
export const SET_NEW_POLICY_NAME = 'AccessControl.setNewPolicyName';
export const SET_NEW_POLICY_DESCRIPTION = 'AccessControl.setNewPolicyDescription';
export const CREATE_POLICY = 'AccessControl.createPolicy';
export const CREATE_POLICY_COMPLETED = 'AccessControl.createPolicyCompleted';
export const CREATE_POLICY_FAILED = 'AccessControl.createPolicyFailed';
export const START_EDIT_POLICY = 'AccessControl.startEditPolicy';
export const EDIT_POLICY = 'AccessControl.editPolicy';
export const EDIT_POLICY_NAME = 'AccessControl.editPolicyName';
export const EDIT_POLICY_DESCRIPTION = 'AccessControl.editPolicyDescription';
export const EDIT_POLICY_COMPLETED = 'AccessControl.editPolicyCompleted';
export const EDIT_POLICY_FAILED = 'AccessControl.editPolicyFailed';
export const START_DUPLICATE_POLICY = 'AccessControl.startDuplicatePolicy';
export const DUPLICATE_POLICY = 'AccessControl.duplicatePolicy';
export const SET_DUPLICATE_POLICY_NAME = 'AccessControl.setDuplicatePolicyName';
export const SET_DUPLICATE_POLICY_DESCRIPTION = 'AccessControl.setDuplicatePolicyDescription';
export const DUPLICATE_POLICY_COMPLETED = 'AccessControl.duplicatePolicyCompleted';
export const DUPLICATE_POLICY_FAILED = 'AccessControl.duplicatePolicyFailed';
export const START_DELETE_POLICY = 'AccessControl.startDeletePolicy';
export const DELETE_POLICY = 'AccessControl.deletePolicy';
export const DELETE_POLICY_COMPLETED = 'AccessControl.deletePolicyCompleted';
export const DELETE_POLICY_FAILED = 'AccessControl.deletePolicyFailed';
export const START_MANAGE_MEMBERS = 'AccessControl.startManageMembers';
export const START_MANAGE_MEMBERS_COMPLETED = 'AccessControl.startManageMembersCompleted';
export const START_MANAGE_MEMBERS_FAILED = 'AccessControl.startManageMembersFailed';
export const SET_MANAGE_MEMBERS = 'AccessControl.setManageMembers';
export const TOGGLE_ALL_USERS = 'AccessControl.toggleAllUsers';
export const UPDATE_ALL_USERS_ROLE = 'AccessControl.updateAllUsersRole';
export const ADD_USER_TO_POLICY = 'AccessControl.addUserToPolicy';
export const CHANGE_USER_ROLE_IN_POLICY = 'AccessControl.changeUserRoleInPolicy';
export const REMOVE_USER_FROM_POLICY = 'AccessControl.removeUserFromPolicy';
export const TOGGLE_ALL_USER_GROUPS = 'AccessControl.toggleAllUserGroups';
export const UPDATE_ALL_USER_GROUPS_ROLE = 'AccessControl.updateAllUserGroupsRole';
export const ADD_GROUP_TO_POLICY = 'AccessControl.addGroupToPolicy';
export const CHANGE_GROUP_ROLE_IN_POLICY = 'AccessControl.changeGroupRoleInPolicy';
export const REMOVE_GROUP_FROM_POLICY = 'AccessControl.removeGroupFromPolicy';
export const ADD_PROJECT_AS_MEMBER_TO_POLICY = 'AccessControl.addProjectAsMemberToPolicy';
export const REMOVE_PROJECT_AS_MEMBER_FROM_POLICY = 'AccessControl.removeProjectAsMemberFromPolicy';
export const ADD_POLICY_AS_RESOURCE_TO_POLICY = 'AccessControl.addPolicyAsResourceToPolicy';
export const REMOVE_POLICY_AS_RESOURCE_FROM_POLICY = 'AccessControl.removePolicyAsResourceFromPolicy';
export const TOGGLE_ALL_PROJECTS_AS_MEMBERS = 'AccessControl.toggleAllProjectsAsMembers';
export const UPDATE_POLICY_MEMBERS = 'AccessControl.updatePolicyMembers';
export const UPDATE_POLICY_MEMBERS_COMPLETED = 'AccessControl.updatePolicyMembersCompleted';
export const UPDATE_POLICY_MEMBERS_FAILED = 'AccessControl.updatePolicyMembersFailed';
export const START_MANAGE_RESOURCES = 'AccessControl.startManageResources';
export const SET_MANAGE_RESOURCES = 'AccessControl.setManageResources';
export const TOGGLE_ALL_PROJECTS_AS_RESOURCES = 'AccessControl.toggleAllProjectsAsResources';
export const TOGGLE_ALL_DATASETS = 'AccessControl.toggleAllDatasets';
export const ADD_PROJECT_TO_POLICY = 'AccessControl.addProjectToPolicy';
export const REMOVE_PROJECT_FROM_POLICY = 'AccessControl.removeProjectFromPolicy';
export const UPDATE_POLICY_RESOURCES = 'AccessControl.updatePolicyResources';
export const UPDATE_POLICY_RESOURCES_COMPLETED = 'AccessControl.updatePolicyResourcesCompleted';
export const UPDATE_POLICY_RESOURCES_FAILED = 'AccessControl.updatePolicyResourcesFailed';
export const START_MANAGE_DATASETS = 'AccessControl.startManageDatasets';
export const UPDATE_POLICY_DATASETS_COMPLETED = 'AccessControl.updatePolicyDatasetsCompleted';
export const UPDATE_POLICY_DATASETS_FAILED = 'AccessControl.updatePolicyDatasetsFailed';
export const SET_QUERY = 'AccessControl.setQuery';

interface AccessControlReloadPolicies {
  type: typeof RELOAD_POLICIES
}

interface AccessControlFetchAllPolicies {
  type: typeof FETCH_ALL_POLICIES
}

interface AccessControlFetchAllPoliciesCompleted {
  type: typeof FETCH_ALL_POLICIES_COMPLETED
  filterAndCacheInfo: PolicyFilterAndCacheInfo
  policyDocs: List<Document<AuthorizationPolicy>>
  projectNamesById: Map<number, string>
  projectDescriptionsById: Map<number, string>
}

interface AccessControlFetchAllPoliciesFailed {
  type: typeof FETCH_ALL_POLICIES_FAILED
  filterAndCacheInfo: PolicyFilterAndCacheInfo
}

interface AccessControlStopConfigPolicy {
  type: typeof STOP_CONFIG_POLICY
}

interface AccessControlStartCreatePolicy {
  type: typeof START_CREATE_POLICY
}

interface AccessControlSetNewPolicyName {
  type: typeof SET_NEW_POLICY_NAME
  name: string
}

interface AccessControlSetNewPolicyDescription {
  type: typeof SET_NEW_POLICY_DESCRIPTION
  description: string
}

interface AccessControlCreatePolicy {
  type: typeof CREATE_POLICY
}

interface AccessControlCreatePolicyCompleted {
  type: typeof CREATE_POLICY_COMPLETED
}

interface AccessControlCreatePolicyFailed {
  type: typeof CREATE_POLICY_FAILED
}

interface AccessControlStartEditPolicy {
  type: typeof START_EDIT_POLICY
  policy: Document<AuthorizationPolicy>
}

interface AccessControlEditPolicy {
  type: typeof EDIT_POLICY
}

interface AccessControlEditPolicyName {
  type: typeof EDIT_POLICY_NAME
  name: string
}

interface AccessControlEditPolicyDescription {
  type: typeof EDIT_POLICY_DESCRIPTION
  description: string
}

interface AccessControlEditPolicyCompleted {
  type: typeof EDIT_POLICY_COMPLETED
}

interface AccessControlEditPolicyFailed {
  type: typeof EDIT_POLICY_FAILED
}

interface AccessControlStartDuplicatePolicy {
  type: typeof START_DUPLICATE_POLICY
  policy: Document<AuthorizationPolicy>
}

interface AccessControlDuplicatePolicy {
  type: typeof DUPLICATE_POLICY
}

interface AccessControlSetDuplicatePolicyName {
  type: typeof SET_DUPLICATE_POLICY_NAME
  name: string
}

interface AccessControlSetDuplicatePolicyDescription {
  type: typeof SET_DUPLICATE_POLICY_DESCRIPTION
  description: string
}

interface AccessControlDuplicatePolicyCompleted {
  type: typeof DUPLICATE_POLICY_COMPLETED
}

interface AccessControlDuplicatePolicyFailed {
  type: typeof DUPLICATE_POLICY_FAILED
}

interface AccessControlStartDeletePolicy {
  type: typeof START_DELETE_POLICY
  policy: Document<AuthorizationPolicy>
}

interface AccessControlDeletePolicy {
  type: typeof DELETE_POLICY
}

interface AccessControlDeletePolicyCompleted {
  type: typeof DELETE_POLICY_COMPLETED
}

interface AccessControlDeletePolicyFailed {
  type: typeof DELETE_POLICY_FAILED
}

interface AccessControlStartManageMembers {
  type: typeof START_MANAGE_MEMBERS
  policyDoc: Document<AuthorizationPolicy>
  memberType: PolicyMemberTypeE
}

interface AccessControlStartManageMembersCompleted {
  type: typeof START_MANAGE_MEMBERS_COMPLETED
  authUsers: List<MinimalAuthUser>
  groupDocs: List<Document<Group>>
}

interface AccessControlStartManageMembersFailed {
  type: typeof START_MANAGE_MEMBERS_FAILED
}

interface AccessControlSetManageMembers {
  type: typeof SET_MANAGE_MEMBERS
  memberType: PolicyMemberTypeE
}

interface AccessControlToggleAllUsers {
  type: typeof TOGGLE_ALL_USERS
}

interface AccessControlUpdateAllUsersRole {
  type: typeof UPDATE_ALL_USERS_ROLE
  allUsersRole: string
}

interface AccessControlAddUserToPolicy {
  type: typeof ADD_USER_TO_POLICY
  username: string
}

interface AccessControlChangeUserRoleInPolicy {
  type: typeof CHANGE_USER_ROLE_IN_POLICY
  username: string
  newRole: string
}

interface AccessControlRemoveUserFromPolicy {
  type: typeof REMOVE_USER_FROM_POLICY
  username: string
}

interface AccessControlToggleAllUserGroups {
  type: typeof TOGGLE_ALL_USER_GROUPS
}

interface AccessControlUpdateAllUserGroupsRole {
  type: typeof UPDATE_ALL_USER_GROUPS_ROLE
  allUserGroupsRole: string
}

interface AccessControlAddGroupToPolicy {
  type: typeof ADD_GROUP_TO_POLICY
  groupname: string
}

interface AccessControlChangeGroupRoleInPolicy {
  type: typeof CHANGE_GROUP_ROLE_IN_POLICY
  groupname: string
  newRole: string
}

interface AccessControlRemoveGroupFromPolicy {
  type: typeof REMOVE_GROUP_FROM_POLICY
  groupname: string
}

interface AccessControlAddProjectAsMemberToPolicy {
  type: typeof ADD_PROJECT_AS_MEMBER_TO_POLICY
  id: string
}

interface AccessControlRemoveProjectAsMemberFromPolicy {
  type: typeof REMOVE_PROJECT_AS_MEMBER_FROM_POLICY
  id: string
}

interface AccessControlToggleAllProjectsAsMembers {
  type: typeof TOGGLE_ALL_PROJECTS_AS_MEMBERS
}

interface AccessControlUpdatePolicyMembers {
  type: typeof UPDATE_POLICY_MEMBERS
}

interface AccessControlUpdatePolicyMembersCompleted {
  type: typeof UPDATE_POLICY_MEMBERS_COMPLETED
}

interface AccessControlUpdatePolicyMembersFailed {
  type: typeof UPDATE_POLICY_MEMBERS_FAILED
}

interface AccessControlStartManageResources {
  type: typeof START_MANAGE_RESOURCES
  policyDoc: Document<AuthorizationPolicy>
  resourceType: PolicyResourceTypeE
}

interface AccessControlSetManageResources {
  type: typeof SET_MANAGE_RESOURCES
  resourceType: PolicyResourceTypeE
}

interface AccessControlToggleAllProjectsAsResources {
  type: typeof TOGGLE_ALL_PROJECTS_AS_RESOURCES
}

interface AccessControlToggleAllDatasets {
  type: typeof TOGGLE_ALL_DATASETS
}

interface AccessControlAddProjectToPolicy {
  type: typeof ADD_PROJECT_TO_POLICY
  id: string
}

interface AccessControlRemoveProjectFromPolicy {
  type: typeof REMOVE_PROJECT_FROM_POLICY
  id: string
}

interface AccessControlAddPolicyAsResourceToPolicy {
  type: typeof ADD_POLICY_AS_RESOURCE_TO_POLICY
  id: string
}

interface AccessControlRemovePolicyAsResourceFromPolicy {
  type: typeof REMOVE_POLICY_AS_RESOURCE_FROM_POLICY
  id: string
}

interface AccessControlUpdatePolicyResources {
  type: typeof UPDATE_POLICY_RESOURCES
}

interface AccessControlUpdatePolicyResourcesCompleted {
  type: typeof UPDATE_POLICY_RESOURCES_COMPLETED
}

interface AccessControlUpdatePolicyResourcesFailed {
  type: typeof UPDATE_POLICY_RESOURCES_FAILED
}

interface AccessControlStartManageDatasets {
  type: typeof START_MANAGE_DATASETS
}

interface AccessControlSetQuery {
  type: typeof SET_QUERY
  query: string
}

export type AccessControlActionTypes
  = AccessControlReloadPolicies
  | AccessControlFetchAllPolicies
  | AccessControlFetchAllPoliciesCompleted
  | AccessControlFetchAllPoliciesFailed
  | AccessControlStopConfigPolicy
  | AccessControlStartCreatePolicy
  | AccessControlSetNewPolicyName
  | AccessControlSetNewPolicyDescription
  | AccessControlCreatePolicy
  | AccessControlCreatePolicyCompleted
  | AccessControlCreatePolicyFailed
  | AccessControlStartEditPolicy
  | AccessControlEditPolicy
  | AccessControlEditPolicyName
  | AccessControlEditPolicyDescription
  | AccessControlEditPolicyCompleted
  | AccessControlEditPolicyFailed
  | AccessControlStartDuplicatePolicy
  | AccessControlDuplicatePolicy
  | AccessControlSetDuplicatePolicyName
  | AccessControlSetDuplicatePolicyDescription
  | AccessControlDuplicatePolicyCompleted
  | AccessControlDuplicatePolicyFailed
  | AccessControlStartDeletePolicy
  | AccessControlDeletePolicy
  | AccessControlDeletePolicyCompleted
  | AccessControlDeletePolicyFailed
  | AccessControlStartManageMembers
  | AccessControlStartManageMembersCompleted
  | AccessControlStartManageMembersFailed
  | AccessControlSetManageMembers
  | AccessControlToggleAllUsers
  | AccessControlUpdateAllUsersRole
  | AccessControlAddUserToPolicy
  | AccessControlChangeUserRoleInPolicy
  | AccessControlRemoveUserFromPolicy
  | AccessControlToggleAllUserGroups
  | AccessControlUpdateAllUserGroupsRole
  | AccessControlAddGroupToPolicy
  | AccessControlChangeGroupRoleInPolicy
  | AccessControlRemoveGroupFromPolicy
  | AccessControlAddProjectAsMemberToPolicy
  | AccessControlRemoveProjectAsMemberFromPolicy
  | AccessControlAddPolicyAsResourceToPolicy
  | AccessControlRemovePolicyAsResourceFromPolicy
  | AccessControlToggleAllProjectsAsMembers
  | AccessControlUpdatePolicyMembers
  | AccessControlUpdatePolicyMembersCompleted
  | AccessControlUpdatePolicyMembersFailed
  | AccessControlStartManageResources
  | AccessControlSetManageResources
  | AccessControlToggleAllProjectsAsResources
  | AccessControlToggleAllDatasets
  | AccessControlAddProjectToPolicy
  | AccessControlRemoveProjectFromPolicy
  | AccessControlUpdatePolicyResources
  | AccessControlUpdatePolicyResourcesCompleted
  | AccessControlUpdatePolicyResourcesFailed
  | AccessControlStartManageDatasets
  | AccessControlSetQuery
