import { is } from 'immutable';

import createLoader from '../utils/createLoader';
import { fetchJobs } from './JobApi';
import { getFilterInfo } from './JobStore';

const JobsLoader = createLoader(({ jobs }) => {
  const { loading, loadedFilterInfo } = jobs;
  return {
    canFetch: true, // there is no precondition for fetching
    shouldFetch: !is(getFilterInfo(jobs), loadedFilterInfo),
    loading,
  };
}, {
  onFetch: fetchJobs,
}, 'JobsLoader');

export default JobsLoader;
