import { List } from 'immutable';
import React from 'react';

import MultiValue, { recordValueShim } from '../components/MultiValue';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';


const DatasetIdFieldColumn: React.FC<{
  width: number
  rows: List<Document<Dataset>>
}> = ({ width, rows }) => {
  return (
    <Column
      key="id"
      width={width}
      isResizable
      columnKey="id"
      flexGrow={1}
      header={<Cell>ID Field</Cell>}
      cell={({ rowIndex }) => {
        const data = rows.get(rowIndex)?.data.idFields.join(', ');
        return <Cell><MultiValue values={recordValueShim({ value: data })} /></Cell>;
      }}
    />
  );
};

export default DatasetIdFieldColumn;
