import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';

type ColumnWidthProviderProps = {
  only?: string[]
}
type ColumnWidthProviderState = {
  columnWidths: Map<string, number>
}

class ColumnWidthProvider extends React.Component<ColumnWidthProviderProps, ColumnWidthProviderState> {
  static propTypes = {
    only: PropTypes.arrayOf(PropTypes.string),
  };

  state = { columnWidths: Map<string, number>() };

  render() {
    const { children, only } = this.props;
    const { columnWidths } = this.state;
    const child = React.Children.only(children) as React.ReactElement;
    return React.cloneElement(child, {
      onColumnResizeEndCallback: (width: number, column: string) => {
        if (!only || _.includes(only, column)) {
          this.setState(state => ({ columnWidths: state.columnWidths.set(column, width) }));
        }
        if (child.props.onColumnResizeEndCallback) {
          child.props.onColumnResizeEndCallback(width, column);
        }
      },
      children: React.Children.map(child.props.children, c => {
        // @ts-expect-error
        return React.isValidElement(c) ? React.cloneElement(c, { width: columnWidths.get(c.props.columnKey) || c.props.width }) : c;
      }),
    });
  }
}

export default ColumnWidthProvider;
