import { List, Map } from 'immutable';

import { ArgTypes, checkArg, Checker } from '../utils/ArgValidation';
import { JsonContent } from '../utils/typescript';

// TODO this does not exactly mirror Table.java, this completely omits schema.
export type TableType = List<Map<string, JsonContent>>;
export const argType = ArgTypes.Immutable.list.of(ArgTypes.Immutable.map.of(ArgTypes.any as Checker<JsonContent>, ArgTypes.string /* field name */));
export const fromJSON = (obj: any): TableType => {
  checkArg({ obj }, ArgTypes.array.of(ArgTypes.object));
  return List(obj).map((r: {[key: string]: any}) => Map(r));
};
