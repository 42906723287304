import AppState from '../stores/AppState';
import MimeType from './MimeType';

export function getIsValidSelection(state: AppState): boolean {
  const { externalStorage: { selectedNode, selectedNodeType } } = state;
  // if the node is a directory, must have a valid selected node type
  if (
    !selectedNode // this is true in the case when selected node group is in top-level of a storage provider content
    || selectedNode?.mimeType === MimeType.DIRECTORY
  ) {
    return !!selectedNodeType;
  }
  // otherwise make sure the node's mime type is valid
  return !!selectedNode?.mimeType;
}
