import { TableType } from '../models/Table';
import TypedTable from '../models/TypedTable';
import { GoldenRecordsModule } from './GoldenRecordsModule';
import { InputRecordsFilterInfo, PreviewFilterInfo } from './GoldenRecordsRulesStore';

export const TOGGLE_SIDEBAR = 'GoldenRecordsRules.toggleSidebar';
export const FETCH_PREVIEW_INPUT_RECORDS = 'GoldenRecords.fetchPreviewInputRecords';
export const FETCH_PREVIEW_INPUT_RECORDS_COMPLETED = 'GoldenRecords.fetchPreviewInputRecordsCompleted';
export const FETCH_PREVIEW_INPUT_RECORDS_FAILED = 'GoldenRecords.fetchPreviewInputRecordsFailed';
export const FETCH_PREVIEW_TABLE = 'GoldenRecords.fetchPreviewTable';
export const FETCH_PREVIEW_TABLE_COMPLETED = 'GoldenRecords.fetchPreviewTableCompleted';
export const FETCH_PREVIEW_TABLE_FAILED = 'GoldenRecords.fetchPreviewTableFailed';
export const TRIGGER_PREVIEW_FETCH = 'GoldenRecords.triggerPreviewFetch';
export const SHOW_PREVIEW_TOO_LARGE_DIALOG = 'GoldenRecords.showPreviewTooLargeDialog';

interface GoldenRecordsToggleSidebar {
  type: typeof TOGGLE_SIDEBAR
}

interface GoldenRecordsFetchPreviewInputRecords {
  type: typeof FETCH_PREVIEW_INPUT_RECORDS
}

interface GoldenRecordsFetchPreviewInputRecordsCompleted {
  type: typeof FETCH_PREVIEW_INPUT_RECORDS_COMPLETED
  table: TypedTable
  filterInfo: InputRecordsFilterInfo
}

interface GoldenRecordsFetchPreviewInputRecordsFailed {
  type: typeof FETCH_PREVIEW_INPUT_RECORDS_FAILED
  filterInfo: InputRecordsFilterInfo
  bookmarkedClustersTooLarge: boolean
}

interface GoldenRecordsFetchPreviewTable {
  type: typeof FETCH_PREVIEW_TABLE
}

interface GoldenRecordsFetchPreviewTableCompleted {
  type: typeof FETCH_PREVIEW_TABLE_COMPLETED
  table: TableType
  filterInfo: PreviewFilterInfo
  module: GoldenRecordsModule
}

interface GoldenRecordsFetchPreviewTableFailed {
  type: typeof FETCH_PREVIEW_TABLE_FAILED
  filterInfo: PreviewFilterInfo
}

interface GoldenRecordsTriggerPreviewFetch {
  type: typeof TRIGGER_PREVIEW_FETCH
}

interface GoldenRecordsShowPreviewTooLargeDialog {
  type: typeof SHOW_PREVIEW_TOO_LARGE_DIALOG
  showPreviewTooLargeDialog: boolean
}

export const UPDATE_DESIRED_SAMPLE_CLUSTER = 'GoldenRecordsRules.updateDesiredSampleCluster';
interface GoldenRecordsRulesUpdateDesiredSampleCluster {
  type: typeof UPDATE_DESIRED_SAMPLE_CLUSTER
  rowNumber: number
}

export const FETCH_CLUSTER_SAMPLE = 'GoldenRecordsRules.fetchClusterSample';
interface GoldenRecordsRulesFetchClusterSample {
  type: typeof FETCH_CLUSTER_SAMPLE
  clusterId: string
  clusterName: string
  clusterSize: number
  fetchSequence: number
}

export const FETCH_CLUSTER_SAMPLE_COMPLETED = 'GoldenRecordsRules.fetchClusterSampleCompleted';
interface GoldenRecordsRulesFetchClusterSampleCompleted {
  type: typeof FETCH_CLUSTER_SAMPLE_COMPLETED
  table: TypedTable
  clusterId: string
}

export const FETCH_CLUSTER_SAMPLE_FAILED = 'GoldenRecordsRules.fetchClusterSampleFailed';
interface GoldenRecordsRulesFetchClusterSampleFailed {
  type: typeof FETCH_CLUSTER_SAMPLE_FAILED
  clusterId: string
}

export const TOGGLE_SHOW_CLUSTER_SAMPLE_TABLE = 'GoldenRecordsRules.toggleShowClusterSampleTable';
interface GoldenRecordsRulesToggleShowClusterSampleTable {
  type: typeof TOGGLE_SHOW_CLUSTER_SAMPLE_TABLE
}

export type GoldenRecordsRulesActionTypes
  = GoldenRecordsToggleSidebar
  | GoldenRecordsFetchPreviewInputRecords
  | GoldenRecordsFetchPreviewInputRecordsCompleted
  | GoldenRecordsFetchPreviewInputRecordsFailed
  | GoldenRecordsFetchPreviewTable
  | GoldenRecordsFetchPreviewTableCompleted
  | GoldenRecordsFetchPreviewTableFailed
  | GoldenRecordsTriggerPreviewFetch
  | GoldenRecordsShowPreviewTooLargeDialog
  | GoldenRecordsRulesUpdateDesiredSampleCluster
  | GoldenRecordsRulesFetchClusterSample
  | GoldenRecordsRulesFetchClusterSampleCompleted
  | GoldenRecordsRulesFetchClusterSampleFailed
  | GoldenRecordsRulesToggleShowClusterSampleTable
