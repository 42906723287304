import './Input.scss';

import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';

import { TamrIconName } from '../Icons/TamrIconClasses';
import TamrIcon from '../TamrIcon';
import newId from './Id';


/**
 * Checkbox
 * Thin wrapper around <input type="checkbox"> which pulls the boolean out of
 * the event
 */

const propTypes = {
  /**
   * Extra classname to give component
   */
  className: PropTypes.string,
  /**
   * Optional value with which to initialize the checkbox
   */
  defaultValue: PropTypes.bool,
  /**
   * Whether this checkbox can be toggled
   */
  disabled: PropTypes.bool,
  /**
   * Method to invoke when changing the value of the checkbox.  The inputs are:
   * - The new value
   * - The triggering event
   */
  onChange: PropTypes.func.isRequired as PropTypes.Validator<(checked: boolean, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void>,
  /*
  * Boolean indicating whether or not the checkbox should be semi-checked
  * If both this and value are true, it will default to checked.
  */
  semiChecked: PropTypes.bool,
  /**
   * Size, in px, of the checkbox (e.g. width and height)  Default is 14
   */
  size: PropTypes.number,
  /**
   * Label text to give checkbox
   */
  title: PropTypes.node,
  /**
   * Position of label relative to the checkbox.  Default is 'left'
   */
  titlePosition: PropTypes.oneOf(['left', 'right']),
  /**
   * Boolean indicating whether or not checkbox should be checked
   */
  value: PropTypes.bool,
};

type CheckboxProps = InferProps<typeof propTypes>;

type CheckboxState = {
  checked: boolean
  id: string
}

class Checkbox extends React.Component<CheckboxProps, CheckboxState> {
  static propTypes = propTypes;

  static defaultProps = { defaultValue: false, semiChecked: false };

  constructor(props: CheckboxProps) {
    super(props);
    let initialChecked;
    if (props.value === undefined) {
      // component is uncontrolled
      // seed the internally managed checked state with provided defaultValue
      initialChecked = props.defaultValue;
    }

    this.state = {
      checked: !!initialChecked,
      id: 'check_' + newId(),
    };
  }

  handleChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!this.props.disabled) {
      const checked = !this.isChecked();
      this.setState({ checked });
      this.props.onChange(checked, event);
    }
    event.stopPropagation();
    event.preventDefault();
  };

  isChecked = () => {
    if (this.props.hasOwnProperty('value')) {
      return this.props.value;
    }
    return this.state.checked;
  };

  render() {
    const checkboxWrapperClass = classNames(
      this.props.className, 'tamr-checkbox-wrapper',
      {
        'has-label': this.props.hasOwnProperty('title'),
        'position-left': this.props.titlePosition === 'left',
        'position-right': this.props.titlePosition === 'right',
        disabled: this.props.disabled,
      },
    );

    const checkboxClass = classNames({
      'tamr-checkbox': true,
      checked: this.isChecked(),
      semiChecked: this.props.semiChecked,
    });

    const iconName: TamrIconName = this.isChecked()
      ? 'tamr-icon-checkbox-checked'
      : this.props.semiChecked
        ? 'tamr-icon-checkbox-partial'
        : 'tamr-icon-checkbox-unchecked';

    let label;
    if (this.props.title) {
      label = <label htmlFor={this.state.id}>{this.props.title}</label>;
    }
    return (
      <button
        className={checkboxWrapperClass}
        id={this.state.id}
        onClick={this.handleChange}
      >
        {label}
        <TamrIcon
          className={checkboxClass}
          iconName={iconName}
          size={this.props.size || 14}
        />
      </button>
    );
  }
}

export default Checkbox;
