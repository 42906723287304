import classNames from 'classnames';
import { is } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import HoverTrigger from '../components/HoverTrigger';
import { attributeId } from '../models/AttributeId';
import { DO_NOT_MAP_NAME } from '../models/MappingRecommendation';
import SourceAttribute from '../models/SourceAttribute';
import { getUnifiedDatasetName } from '../utils/Selectors';
import MappingStatusIcons from './MappingStatusIcons';
import { SOURCE_TOGGLE_FILTER_RELATED_ID } from './SchemaMappingActionTypes';
import SingleSuggestion from './SingleSuggestion';
import SourceAttributeMappingSelector from './SourceAttributeMappingSelector';


const SourceAttributeMappingStatus = _.compose(
  connect((state, { attribute }) => {
    const { schemaMapping } = state;
    const { sourceFilterRelatedId } = schemaMapping;
    const id = attributeId(attribute);
    return {
      filterActivated: is(sourceFilterRelatedId, id),
      datasetName: getUnifiedDatasetName(state),
    };
  }, {
    onToggleFilterRelatedId: id => ({ type: SOURCE_TOGGLE_FILTER_RELATED_ID, id }),
  }),
)(class SourceAttributeMappingStatus extends React.Component {
  static propTypes = {
    advertiseDropTarget: PropTypes.bool.isRequired,
    attribute: PropTypes.instanceOf(SourceAttribute).isRequired,
    canUserEdit: PropTypes.bool.isRequired,
    datasetName: PropTypes.string,
    filterActivated: PropTypes.bool.isRequired,
    onToggleFilterRelatedId: PropTypes.func.isRequired,
  };

  state = { cellIsHovered: false };

  onMouseEnter = () => {
    this.setState({ cellIsHovered: true });
  };

  onMouseLeave = () => {
    this.setState({ cellIsHovered: false });
  };

  render() {
    const { attribute, datasetName, advertiseDropTarget, filterActivated, canUserEdit, onToggleFilterRelatedId } = this.props;
    const mappings = attribute.mappedAttributes;
    const hasSelector = !advertiseDropTarget && mappings.size || canUserEdit;
    const className = classNames('mapping-status', 'source', {
      'has-selector': !!hasSelector,
    });
    const visibleRecommendations = attribute.recommendations.filter(r => !mappings.contains(r.recommended));
    const doNotMap = attribute.doNotMap.has(datasetName);
    const showSingle = visibleRecommendations.size === 1 && !doNotMap;
    const singleSuggestion = showSingle ? visibleRecommendations.first().recommended.name : undefined;
    const status = (
      <div
        className={className}
        data-test-element="source-mapping-status"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div className={classNames('message', { 'show-single-suggestion': showSingle })}>
          {(() => {
            switch (true) {
              case !!advertiseDropTarget: return 'Drop to map';
              case doNotMap: return 'Do Not Map';
              case mappings.size === 1: return <span className="emphasis">{mappings.first().name}</span>;
              case mappings.size > 1: return <span><span className="emphasis">{mappings.size}</span> attributes</span>;
              default: return <span />;
            }
          })()}
        </div>
        {
          showSingle ?
            <SingleSuggestion
              suggestedAttrName={singleSuggestion === DO_NOT_MAP_NAME ? 'Do Not Map' : singleSuggestion}
            /> : null
        }
        <MappingStatusIcons
          mappings={mappings}
          topRecommendations={visibleRecommendations}
          numRecommendations={attribute.numRecommendations}
          filterActivated={filterActivated}
          isHovered={this.state.cellIsHovered}
          doNotMap={doNotMap}
          onToggleFilterRelatedId={() => onToggleFilterRelatedId(attributeId(attribute))}
        />
      </div>
    );
    if (hasSelector) {
      return (
        <HoverTrigger
          className="mapping-selector-container"
          placement="right"
          content={<SourceAttributeMappingSelector {...{ attribute, canUserEdit, onToggleFilterRelatedId }} />}
        >
          {status}
        </HoverTrigger>
      );
    }

    return status;
  }
});

export default SourceAttributeMappingStatus;
