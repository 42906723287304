import { is, Map, Set } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Highlighter from '../components/Highlighter';
import Checkbox from '../components/Input/Checkbox';
import ListSelector from '../components/ListSelector/ListSelector';
import { AppState } from '../stores/MainStore';
import { textMatch } from '../utils/Values';
import { selectAllRulesWithOverrides } from './GoldenRecordsStore';
import style from './OverrideFilterSelectionDialog.module.scss';

const mapStateToProps = (state: AppState) => {
  const { goldenRecords, goldenRecords: { overrideStats, showingSelectOverrideFilterDialog, hasOverrides } } = state;
  return {
    rulesWithOverrides: selectAllRulesWithOverrides(goldenRecords),
    show: showingSelectOverrideFilterDialog,
    overrideStats,
    hasOverrides,
  };
};

const mapDispatchToProps = {
  onUpdateHasOverrides: (hasOverrides: Set<string>) => ({ type: 'GoldenRecords.updateHasOverrides', hasOverrides }),
  onHide: () => ({ type: 'GoldenRecords.hideSelectOverrideFilterDialog' }),
};

type OverrideFilterSelectionDialogProps
  = ReturnType<typeof mapStateToProps>
  & typeof mapDispatchToProps

type OverrideFilterSelectionDialogState = {
  toAdd: Set<string>
  toRemove: Set<string>
  searchValue: string
}

const OverrideFilterSelectionDialog = connect(
  mapStateToProps,
  mapDispatchToProps,
)(class OverrideFilterSelectionDialog extends React.Component<OverrideFilterSelectionDialogProps, OverrideFilterSelectionDialogState> {
  getInitialState = () => {
    return {
      toAdd: Set(),
      toRemove: Set(),
      searchValue: '',
    };
  };

  constructor(props: OverrideFilterSelectionDialogProps) {
    super(props);
    this.state = this.getInitialState();
  }

  hide = () => {
    this.props.onHide();
    this.setState(this.getInitialState());
  };

  onChange = (changedData: Map<string, boolean>) => {
    let { toAdd, toRemove } = this.state;
    changedData.forEach((selected, rule) => {
      if (selected) {
        toAdd = toAdd.add(rule);
        toRemove = toRemove.remove(rule);
      } else {
        toAdd = toAdd.remove(rule);
        toRemove = toRemove.add(rule);
      }
    });
    this.setState({ toAdd, toRemove });
  };

  toggleAll = () => {
    const { rulesWithOverrides } = this.props;
    if (this.areAllSelected()) {
      this.setState({
        toAdd: Set(),
        toRemove: rulesWithOverrides.toSet(),
      });
    } else {
      this.setState({
        toAdd: rulesWithOverrides.toSet(),
        toRemove: Set(),
      });
    }
  };

  areAllSelected = () => {
    return is(this.getSelectedRules(), this.props.rulesWithOverrides.toSet());
  };

  accept = () => {
    this.props.onUpdateHasOverrides(this.getSelectedRules());
    this.hide();
  };

  getSelectedRules = () => {
    const { toAdd, toRemove } = this.state;
    return this.props.hasOverrides.subtract(toRemove).union(toAdd);
  };

  render() {
    const { overrideStats } = this.props;
    const { searchValue } = this.state;
    const selectedRules = this.getSelectedRules();
    const selectorValues = this.props.rulesWithOverrides
      .filter(name => !searchValue || textMatch(name, searchValue))
      .sort()
      .map(name => {
        const overrideStatForRule = overrideStats.get(name);
        const numOverrides = (overrideStatForRule?.totalNumOverrides || 0);
        const selected = selectedRules.contains(name);
        return {
          value: name,
          label: (
            <div className={style.attributeSelectionValue}>
              <span className={style.titleCell}>
                <Highlighter fullText={name} highlightText={searchValue} />
              </span>
              <span className={style.overridesCell}>
                {numOverrides}
              </span>
            </div>
          ),
          selected,
        };
      });

    return (
      <Dialog
        className={style.attributeSelectionDialog}
        show={this.props.show}
        onHide={this.hide}
        title="Select Attributes"
        dialogStyle={DialogStyle.PRIMARY}
        body={(
          <ListSelector
            values={selectorValues}
            enableSearch
            height={250}
            onSelect={this.onChange}
            onSearch={(value) => this.setState({ searchValue: value })}
            searchValue={this.state.searchValue}
            headerRow={(
              <div className={style.headerRow}>
                <Checkbox className={style.selectAllCheckbox} value={this.areAllSelected()} onChange={this.toggleAll} size={15} />
                <span className={style.titleCell}>
                  Attribute
                </span>
                <span className={style.overridesCell}>
                  Overrides
                </span>
              </div>
            )}
          />
        )}
        footer={(
          <ButtonToolbar>
            <Button
              onClick={this.hide}
              buttonType="Secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={this.accept}
            >
              Okay
            </Button>
          </ButtonToolbar>
        )}
      />
    );
  }
});

export default OverrideFilterSelectionDialog;
