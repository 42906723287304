import classNames from 'classnames';
import { List, Map, Set } from 'immutable';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import ConditionalButton from '../components/ConditionalButton';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Input from '../components/Input/Input';
import Selector from '../components/Input/Selector';
import LoadingPanel from '../components/LoadingPanel';
import SearchBox from '../components/SearchBox';
import Toggle from '../components/Toggle';
import TooltipTrigger from '../components/TooltipTrigger';
import PolicyMemberType from '../constants/PolicyMemberType';
import PolicyResourceType from '../constants/PolicyResourceType';
import DatasetFilter from '../datasetFilter/DatasetFilter';
import AuthorizationPolicy from '../models/AuthorizationPolicy';
import { Roles } from '../models/AuthUser';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import { InferSingleKindParameters } from '../models/TaggedUnion';
import { AppAction } from '../stores/AppAction';
import { AppState } from '../stores/MainStore';
import { isBlank } from '../utils/Strings';
import {
  ADD_POLICY_AS_RESOURCE_TO_POLICY,
  REMOVE_POLICY_AS_RESOURCE_FROM_POLICY,
} from './AccessControlActionTypes';
import {
  createPolicy,
  duplicatePolicy,
  editPolicy,
  removePolicy,
  updatePolicyMembers,
  updatePolicyResources,
} from './AccessControlAsync';
import {
  getDescriptionByGroupname,
  getDescriptionByProjectId,
  getEmailByUsername,
  getGroupsByUsername,
  getInPolicyAsMemberByProjectId,
  getInPolicyByGroupname,
  getInPolicyByProjectId,
  getInPolicyByResourcePolicyId,
  getInPolicyByUsername,
  getNameByProjectId,
  getNameByUsername,
  getPolicyById,
  getPolicyConfig,
  getPolicyRoleByGroupname,
  getPolicyRoleByUsername,
  getUserGroupsCount,
  getUsersCount,
  selectGroupnamesInPolicyToManage,
  selectGroupnamesNotInPolicyToManage,
  selectProjectAsMemberIdsInPolicyToManage,
  selectProjectAsMemberIdsNotInPolicyToManage,
  selectProjectIdsInPolicyToManage,
  selectProjectIdsNotInPolicyToManage,
  selectResourcePolicyIdsInPolicyToManage,
  selectResourcePolicyIdsNotInPolicyToManage,
  selectUsernamesInPolicyToManage,
  selectUsernamesNotInPolicyToManage,
} from './AccessControlStore';
import { UserGroupsCount, UsersCount } from './Count';
import style from './PoliciesList.module.scss';
import { policyConfigKindDefinitions, PolicyConfigType } from './PolicyConfig';
import PolicyConfigTable, { PolicyConfigTableRenderRowArgs, PolicyConfigTableRow } from './PolicyConfigTable';


const PolicyMetadata: React.FC<{
  invalid?: boolean
  name: string
  description: string
  loading?: boolean
  onChangeName: (value: string) => void
  onChangeDescription: (value: string) => void
}> = ({ invalid, name, description, loading, onChangeName, onChangeDescription }) => {
  return loading ? <LoadingPanel /> : (
    <div>
      <Input
        componentClassName={style.policyNameInput}
        title="Name"
        value={name}
        onChange={onChangeName}
        invalid={invalid}
      />
      <Input title="Description" value={description} onChange={onChangeDescription} />
    </div>
  );
};

const DeleteBody: React.FC<{
  policy: Document<AuthorizationPolicy>
}> = ({ policy, policy: { data } }) => {
  const projectsSize = data.projectIds().size;
  const datasetsSize = data.datasetIds().size;
  return (
    <div className={style.body}>
      Are you sure? This policy is connected to the following:
      <div className={style.policyConnectionsContainer}>
        <ul className={style.connectionList}>
          <li><UsersCount id={policy.id.id} /></li>
          <li><UserGroupsCount id={policy.id.id} /></li>
          {/* TODO: Include information about projects as member as well */}
          <li>{projectsSize} Associated Projects</li>
          <li>{datasetsSize} Associated Datasets</li>
        </ul>
      </div>
    </div>
  );
};

function ifManageMembers<T>(
  policyConfig: PolicyConfigType,
  lambda: (caseArgs: InferSingleKindParameters<typeof policyConfigKindDefinitions['ManageMembers']>) => T,
  defaultValue: T,
): T {
  return policyConfig.case<T>({
    ManageMembers: (caseArgs) => lambda(caseArgs),
    Create: () => defaultValue,
    Delete: () => defaultValue,
    Duplicate: () => defaultValue,
    Edit: () => defaultValue,
    ManageResources: () => defaultValue,
  });
}

function ifManageResources<T>(
  policyConfig: PolicyConfigType,
  lambda: (caseArgs: InferSingleKindParameters<typeof policyConfigKindDefinitions['ManageResources']>) => T,
  defaultValue: T,
): T {
  return policyConfig.case<T>({
    ManageResources: (caseArgs) => lambda(caseArgs),
    Create: () => defaultValue,
    Delete: () => defaultValue,
    Duplicate: () => defaultValue,
    Edit: () => defaultValue,
    ManageMembers: () => defaultValue,
  });
}

const ManageUsersTable = connect((state: AppState) => {
  const policyConfig = getPolicyConfig(state);
  return {
    disabled: !policyConfig ? false : ifManageMembers(policyConfig, ({ applyToAllUsers }) => applyToAllUsers, false),
    idsActuallyInPolicy: selectUsernamesInPolicyToManage(state),
    idsNotActuallyInPolicy: selectUsernamesNotInPolicyToManage(state),
    getIsInPolicyById: getInPolicyByUsername(state),
    getNameById: getNameByUsername(state),
    getSubNameById: getEmailByUsername(state),
    getGroupsById: getGroupsByUsername(state),
    getRoleById: getPolicyRoleByUsername(state),
  };
}, {
  addToPolicy: (username: string): AppAction => ({ type: 'AccessControl.addUserToPolicy', username }),
  changeRoleInPolicy: ({ id, newRole }: { id: string, newRole: string }): AppAction => ({ type: 'AccessControl.changeUserRoleInPolicy', username: id, newRole }),
  removeFromPolicy: (username: string): AppAction => ({ type: 'AccessControl.removeUserFromPolicy', username }),
})(({ disabled, idsActuallyInPolicy, idsNotActuallyInPolicy, getIsInPolicyById, getNameById, getSubNameById, getGroupsById, getRoleById, addToPolicy, changeRoleInPolicy, removeFromPolicy }) => (
  <PolicyConfigTable
    {...{ idsActuallyInPolicy, idsNotActuallyInPolicy, getGroupsById, getRoleById }}
    renderRow={({ id, index, getGroupsById: getUserGroups, getRoleById: getUserRole }: PolicyConfigTableRenderRowArgs) => (
      <PolicyConfigTableRow
        key={id}
        className={classNames({ [style.lastActuallyInPolicy]: index === idsActuallyInPolicy.size - 1 && idsNotActuallyInPolicy.size > 0 })}
        disabled={disabled}
        groups={getUserGroups && getUserGroups(id)}
        inPolicy={getIsInPolicyById(id)}
        name={getNameById(id)}
        subName={getSubNameById(id) || undefined}
        role={getUserRole && getUserRole(id) || undefined}
        onAddToPolicy={() => addToPolicy(id)}
        onChangeRoleInPolicy={(newRole: string) => changeRoleInPolicy({ id, newRole })}
        onRemoveFromPolicy={() => removeFromPolicy(id)}
      />
    )}
  />
));

const ManageGroupsTable = connect((state: AppState) => {
  const policyConfig = getPolicyConfig(state);
  return {
    disabled: !policyConfig ? false : ifManageMembers(policyConfig, ({ applyToAllUserGroups }) => applyToAllUserGroups, false),
    idsActuallyInPolicy: selectGroupnamesInPolicyToManage(state),
    idsNotActuallyInPolicy: selectGroupnamesNotInPolicyToManage(state),
    getIsInPolicyById: getInPolicyByGroupname(state),
    getNameById: (groupname: string) => groupname,
    getSubNameById: getDescriptionByGroupname(state),
    getRoleById: getPolicyRoleByGroupname(state),
  };
}, {
  addToPolicy: (groupname: string): AppAction => ({ type: 'AccessControl.addGroupToPolicy', groupname }),
  changeRoleInPolicy: ({ id, newRole }: { id: string, newRole: string }): AppAction => ({ type: 'AccessControl.changeGroupRoleInPolicy', groupname: id, newRole }),
  removeFromPolicy: (groupname: string): AppAction => ({ type: 'AccessControl.removeGroupFromPolicy', groupname }),
})(({ disabled, idsActuallyInPolicy, idsNotActuallyInPolicy, getIsInPolicyById, getNameById, getSubNameById, getRoleById, addToPolicy, changeRoleInPolicy, removeFromPolicy }) => (
  <PolicyConfigTable
    {...{ idsActuallyInPolicy, idsNotActuallyInPolicy, getRoleById }}
    renderRow={({ id, index, getRoleById: getGroupRole }: PolicyConfigTableRenderRowArgs) => (
      <PolicyConfigTableRow
        key={id}
        disabled={disabled}
        className={classNames({ [style.lastActuallyInPolicy]: index === idsActuallyInPolicy.size - 1 && idsNotActuallyInPolicy.size > 0 })}
        inPolicy={getIsInPolicyById(id)}
        name={getNameById(id)}
        subName={getSubNameById(id) || undefined}
        role={getGroupRole && getGroupRole(id) || undefined}
        onAddToPolicy={() => addToPolicy(id)}
        onChangeRoleInPolicy={(newRole: string) => changeRoleInPolicy({ id, newRole })}
        onRemoveFromPolicy={() => removeFromPolicy(id)}
      />
    )}
  />
));

const ManageProjectsAsMemberTable = connect((state: AppState) => {
  const policyConfig = getPolicyConfig(state);
  return {
    disabled: !policyConfig ? false : ifManageMembers(policyConfig, ({ applyToAllProjects }) => applyToAllProjects, false),
    idsActuallyInPolicy: selectProjectAsMemberIdsInPolicyToManage(state),
    idsNotActuallyInPolicy: selectProjectAsMemberIdsNotInPolicyToManage(state),
    getIsInPolicyById: getInPolicyAsMemberByProjectId(state),
    getNameById: getNameByProjectId(state),
    getSubNameById: getDescriptionByProjectId(state),
  };
}, {
  addToPolicy: (id: string): AppAction => ({ type: 'AccessControl.addProjectAsMemberToPolicy', id }),
  removeFromPolicy: (id: string): AppAction => ({ type: 'AccessControl.removeProjectAsMemberFromPolicy', id }),
})(({ disabled, idsActuallyInPolicy, idsNotActuallyInPolicy, getIsInPolicyById, getNameById, getSubNameById, addToPolicy, removeFromPolicy }) => (
  <PolicyConfigTable
    {...{ idsActuallyInPolicy, idsNotActuallyInPolicy, disabled }}
    renderRow={({ id, index }: PolicyConfigTableRenderRowArgs) => (
      <PolicyConfigTableRow
        key={id}
        className={classNames({ [style.lastActuallyInPolicy]: index === idsActuallyInPolicy.size - 1 && idsNotActuallyInPolicy.size > 0 })}
        disabled={disabled}
        inPolicy={getIsInPolicyById(id)}
        name={getNameById(id)}
        subName={getSubNameById(id)}
        onAddToPolicy={() => addToPolicy(id)}
        onRemoveFromPolicy={() => removeFromPolicy(id)}
      />
    )}
  />
));

const ManageProjectsTable = connect((state: AppState) => {
  const policyConfig = getPolicyConfig(state);
  return {
    disabled: !policyConfig ? false : ifManageResources(policyConfig, ({ applyToAllProjects }) => applyToAllProjects, false),
    idsActuallyInPolicy: selectProjectIdsInPolicyToManage(state),
    idsNotActuallyInPolicy: selectProjectIdsNotInPolicyToManage(state),
    getIsInPolicyById: getInPolicyByProjectId(state),
    getNameById: getNameByProjectId(state),
    getSubNameById: getDescriptionByProjectId(state),
  };
}, {
  addToPolicy: (id: string): AppAction => ({ type: 'AccessControl.addProjectToPolicy', id }),
  removeFromPolicy: (id: string): AppAction => ({ type: 'AccessControl.removeProjectFromPolicy', id }),
})(({ disabled, idsActuallyInPolicy, idsNotActuallyInPolicy, getIsInPolicyById, getNameById, getSubNameById, addToPolicy, removeFromPolicy }) => (
  <PolicyConfigTable
    {...{ idsActuallyInPolicy, idsNotActuallyInPolicy, disabled }}
    renderRow={({ id, index }: PolicyConfigTableRenderRowArgs) => (
      <PolicyConfigTableRow
        key={id}
        className={classNames({ [style.lastActuallyInPolicy]: index === idsActuallyInPolicy.size - 1 && idsNotActuallyInPolicy.size > 0 })}
        disabled={disabled}
        inPolicy={getIsInPolicyById(id)}
        name={getNameById(id)}
        subName={getSubNameById(id)}
        onAddToPolicy={() => addToPolicy(id)}
        onRemoveFromPolicy={() => removeFromPolicy(id)}
      />
    )}
  />
));

const ManageDatasetsTable = connect((state: AppState) => {
  const { accessControl: { policyConfig } } = state;
  return {
    getSelectedDatasets: (datasets: List<Document<Dataset>>) => (!policyConfig ? Set() : ifManageResources(policyConfig,
      ({ currentPolicy, applyToAllDatasets }) => datasets
        .filter(datasetDocument => (applyToAllDatasets ? true : currentPolicy.data.datasetIds().includes(String(datasetDocument.id.id))))
        .map(datasetDocument => datasetDocument.data.name)
        .toSet(),
      Set())),
  };
})(DatasetFilter);

const ManagePoliciesAsResourceTable = connect((state: AppState) => {
  const { accessControl } = state;
  return {
    idsActuallyInPolicy: selectResourcePolicyIdsInPolicyToManage(state),
    idsNotActuallyInPolicy: selectResourcePolicyIdsNotInPolicyToManage(state),
    getIsInPolicyById: getInPolicyByResourcePolicyId(state),
    getPolicyDataById: getPolicyById(accessControl),
  };
}, {
  addToPolicy: (id: string): AppAction => ({ type: ADD_POLICY_AS_RESOURCE_TO_POLICY, id }),
  removeFromPolicy: (id: string): AppAction => ({ type: REMOVE_POLICY_AS_RESOURCE_FROM_POLICY, id }),
})(({ idsActuallyInPolicy, idsNotActuallyInPolicy, getIsInPolicyById, getPolicyDataById, addToPolicy, removeFromPolicy }) => (
  <PolicyConfigTable
    {...{ idsActuallyInPolicy, idsNotActuallyInPolicy }}
    renderRow={({ id, index }: PolicyConfigTableRenderRowArgs) => (
      <PolicyConfigTableRow
        key={id}
        className={classNames({ [style.lastActuallyInPolicy]: index === idsActuallyInPolicy.size - 1 && idsNotActuallyInPolicy.size > 0 })}
        disabled={false}
        inPolicy={getIsInPolicyById(id)}
        name={getPolicyDataById(Number(id))?.name}
        subName={getPolicyDataById(Number(id))?.description ?? ''}
        onAddToPolicy={() => addToPolicy(id)}
        onRemoveFromPolicy={() => removeFromPolicy(id)}
      />
    )}
  />
));

const PolicyConfigDialog = connect((state: AppState) => {
  const { accessControl: { policyDocs, policyConfig } } = state;
  return {
    policyDocs,
    policyConfig,
    usersInPolicyCount: getUsersCount(state),
    userGroupsInPolicyCount: getUserGroupsCount(state),
  };
}, {
  onHidePolicyConfigDialog: (): AppAction => ({ type: 'AccessControl.stopConfigPolicy' }),
  onSetNewPolicyName: (name: string): AppAction => ({ type: 'AccessControl.setNewPolicyName', name }),
  onSetNewPolicyDescription: (description: string): AppAction => ({ type: 'AccessControl.setNewPolicyDescription', description }),
  onEditPolicyName: (name: string): AppAction => ({ type: 'AccessControl.editPolicyName', name }),
  onEditPolicyDescription: (description: string): AppAction => ({ type: 'AccessControl.editPolicyDescription', description }),
  onDuplicatePolicyName: (name: string): AppAction => ({ type: 'AccessControl.setDuplicatePolicyName', name }),
  onDuplicatePolicyDescription: (description: string): AppAction => ({ type: 'AccessControl.setDuplicatePolicyDescription', description }),
  onCreatePolicy: createPolicy,
  onDeletePolicy: removePolicy,
  onDuplicatePolicy: duplicatePolicy,
  onEditPolicy: editPolicy,
  onToggleAllUsers: (): AppAction => ({ type: 'AccessControl.toggleAllUsers' }),
  onChangeRoleForAllUsers: (newRole: string): AppAction => ({ type: 'AccessControl.updateAllUsersRole', allUsersRole: newRole }),
  onManageUsers: (): AppAction => ({ type: 'AccessControl.setManageMembers', memberType: PolicyMemberType.USER }),
  onToggleAllUserGroups: (): AppAction => ({ type: 'AccessControl.toggleAllUserGroups' }),
  onChangeRoleForAllUserGroups: (newRole: string): AppAction => ({ type: 'AccessControl.updateAllUserGroupsRole', allUserGroupsRole: newRole }),
  onManageGroups: (): AppAction => ({ type: 'AccessControl.setManageMembers', memberType: PolicyMemberType.USER_GROUP }),
  onManageProjectsAsMember: (): AppAction => ({ type: 'AccessControl.setManageMembers', memberType: PolicyMemberType.RESOURCE }),
  onToggleAllMemberProjects: (): AppAction => ({ type: 'AccessControl.toggleAllProjectsAsMembers' }),
  onManageProjects: (): AppAction => ({ type: 'AccessControl.setManageResources', resourceType: PolicyResourceType.PROJECTS }),
  onManageDatasets: (): AppAction => ({ type: 'AccessControl.setManageResources', resourceType: PolicyResourceType.DATASETS }),
  onManagePoliciesAsResource: (): AppAction => ({ type: 'AccessControl.setManageResources', resourceType: PolicyResourceType.POLICIES }),
  onToggleAllResourceProjects: (): AppAction => ({ type: 'AccessControl.toggleAllProjectsAsResources' }),
  onToggleAllDatasets: (): AppAction => ({ type: 'AccessControl.toggleAllDatasets' }),
  onPickDatasets: (): AppAction => ({ type: 'AccessControl.startManageDatasets' }),
  onUpdatePolicyMembers: updatePolicyMembers,
  onUpdatePolicyResources: updatePolicyResources,
  onSearch: (query: string): AppAction => ({ type: 'AccessControl.setQuery', query }),
})(({ policyDocs, policyConfig, onHidePolicyConfigDialog, onSetNewPolicyName,
  onSetNewPolicyDescription, onEditPolicyName, onEditPolicyDescription, onCreatePolicy,
  onEditPolicy, onDuplicatePolicy, onDuplicatePolicyName, onDuplicatePolicyDescription,
  onDeletePolicy, onToggleAllUsers, onChangeRoleForAllUsers, onManageUsers, onToggleAllUserGroups,
  onChangeRoleForAllUserGroups, onManageGroups, onManageProjectsAsMember, onToggleAllMemberProjects,
  onManageProjects, onManageDatasets, onToggleAllResourceProjects, onToggleAllDatasets, onPickDatasets,
  onUpdatePolicyMembers, onUpdatePolicyResources, onSearch, usersInPolicyCount, userGroupsInPolicyCount,
  onManagePoliciesAsResource }) => {
  return !policyConfig ? null : (
    <Dialog
      className={style.policyConfigDialog}
      show
      dialogStyle={ifManageResources(policyConfig, ({ datasetFilterOpen }) => (datasetFilterOpen ? DialogStyle.FULL : DialogStyle.PRIMARY), DialogStyle.PRIMARY)}
      onHide={onHidePolicyConfigDialog}
      title={policyConfig.case<React.ReactNode>({
        Create: () => 'Create new policy',
        Duplicate: ({ currentPolicy }) => `Duplicate "${currentPolicy.data.name}" policy`,
        Delete: () => 'Delete policy',
        Edit: () => 'Edit policy',
        ManageMembers: ({ currentPolicy }) => (
          <div className={style.manageMembersTitle}>
            <span className={style.leftText}>Manage "</span>
            <span className={style.nameText}>{currentPolicy.data.name}</span>
            <span className={style.rightText}>" members</span>
          </div>
        ),
        ManageResources: ({ currentPolicy }) => (
          <div className={style.manageMembersTitle}>
            <span className={style.leftText}>Manage "</span>
            <span className={style.nameText}>{currentPolicy.data.name}</span>
            <span className={style.rightText}>" resources</span>
          </div>
        ),
      })}
      body={policyConfig.case({
        Create: ({ name, description }) => <PolicyMetadata {...{ name, description }} onChangeName={onSetNewPolicyName} onChangeDescription={onSetNewPolicyDescription} invalid={Boolean(policyDocs.find(policy => policy.data.name === name))} />,
        Edit: ({ name, description, currentPolicy }) => <PolicyMetadata {...{ name, description }} onChangeName={onEditPolicyName} onChangeDescription={onEditPolicyDescription} invalid={Boolean(policyDocs.find(policy => policy.data.name === name)) && name !== currentPolicy.data.name} />,
        Duplicate: ({ name, description }) => (
          <div>
            <div className={style.duplicateBody}>
              Duplicating this policy will create a new policy with the same associated users, groups, projects, and datasets.
            </div>
            <PolicyMetadata {...{ name, description }} onChangeName={onDuplicatePolicyName} onChangeDescription={onDuplicatePolicyDescription} invalid={Boolean(policyDocs.find(policy => policy.data.name === name))} />
          </div>
        ),
        Delete: ({ currentPolicy }) => <DeleteBody policy={currentPolicy} />,
        ManageMembers: ({ currentPolicy, tab, query, applyToAllUsers, allUsersRole, applyToAllUserGroups, allUserGroupsRole, applyToAllProjects }) => (
          <div>
            <div className={style.manageMembersResourcesTabs}>
              <div
                className={classNames('page-header-tab', { active: tab === PolicyMemberType.USER })}
                onClick={onManageUsers}
              >
                Users ({currentPolicy.data.appliesToAllUsers() ? 'all' : usersInPolicyCount(currentPolicy.id.id).count})
              </div>
              <div
                className={classNames('page-header-tab', { active: tab === PolicyMemberType.USER_GROUP })}
                onClick={onManageGroups}
              >
                Groups ({currentPolicy.data.appliesToAllUserGroups() ? 'all' : userGroupsInPolicyCount(currentPolicy.id.id).count})
              </div>
              <div
                className={classNames('page-header-tab', { active: tab === PolicyMemberType.RESOURCE })}
                onClick={onManageProjectsAsMember}
              >
                Projects ({currentPolicy.data.appliesToAllMemberProjects() ? 'all' : currentPolicy.data.projectsAsMemberIds().size})
              </div>
            </div>
            {
              {
                [PolicyMemberType.RESOURCE]: (
                  <div className={style.toggleAllSection}>
                    <Toggle onChange={onToggleAllMemberProjects} value={applyToAllProjects} />
                    <span className={style.toggleMessage}>All current and future projects have access to policy resources</span>
                  </div>
                ),
                [PolicyMemberType.USER]: (
                  <div className={style.toggleAllSection}>
                    <Toggle onChange={onToggleAllUsers} value={applyToAllUsers} />
                    <span className={style.toggleMessage}>All current and future users have </span>
                    <TooltipTrigger
                      placement="top"
                      content={!applyToAllUsers ? 'Toggle on this setting to change the permissions for all users' : null}
                    >
                      <span>
                        <Selector
                          disabled={!applyToAllUsers}
                          className={style.roleSelector}
                          onChange={onChangeRoleForAllUsers}
                          value={allUsersRole}
                          values={[
                            { value: Roles.AUTHOR, display: 'Author' },
                            { value: Roles.CURATOR, display: 'Curator' },
                            { value: Roles.REVIEWER, display: 'Reviewer' },
                            { value: Roles.VERIFIER, display: 'Verifier' },
                          ]}
                        />
                      </span>
                    </TooltipTrigger>
                    access to policy resources
                  </div>
                ),
                [PolicyMemberType.USER_GROUP]: (
                  <div className={style.toggleAllSection}>
                    <Toggle onChange={onToggleAllUserGroups} value={applyToAllUserGroups} />
                    <span className={style.toggleMessage}>All current and future groups have </span>
                    <TooltipTrigger
                      placement="top"
                      content={!applyToAllUserGroups ? 'Toggle on this setting to change the permissions for all groups' : null}
                    >
                      <span>
                        <Selector
                          disabled={!applyToAllUserGroups}
                          className={style.roleSelector}
                          onChange={onChangeRoleForAllUserGroups}
                          value={allUserGroupsRole}
                          values={[
                            { value: Roles.CURATOR, display: 'Curator' },
                            { value: Roles.REVIEWER, display: 'Reviewer' },
                            { value: Roles.VERIFIER, display: 'Verifier' },
                          ]}
                        />
                      </span>
                    </TooltipTrigger>
                    access to policy resources
                  </div>
                ),
              }[tab]
            }

            <SearchBox value={query} onSearch={onSearch} className={style.searchBox} />
            {
              /* a switch statement of what to render based on the selected tab */
              {
                [PolicyMemberType.USER]: <ManageUsersTable />,
                [PolicyMemberType.USER_GROUP]: <ManageGroupsTable />,
                [PolicyMemberType.RESOURCE]: <ManageProjectsAsMemberTable />,
              }[tab]
            }
          </div>
        ),
        ManageResources: ({ currentPolicy, tab, datasetFilterOpen, applyToAllProjects, applyToAllDatasets }) => (datasetFilterOpen ? <ManageDatasetsTable /> : (
          <div>
            <div className={style.manageMembersResourcesTabs}>
              <div
                className={classNames('page-header-tab', { active: tab === PolicyResourceType.PROJECTS })}
                onClick={onManageProjects}
              >
                Projects ({currentPolicy.data.projectIds().size})
              </div>
              <div
                className={classNames('page-header-tab', { active: tab === PolicyResourceType.DATASETS })}
                onClick={onManageDatasets}
              >
                Datasets ({ currentPolicy.data.appliesToAllDatasets() ? 'all' : currentPolicy.data.datasetIds().size})
              </div>
              <div
                className={classNames('page-header-tab', { active: tab === PolicyResourceType.POLICIES })}
                onClick={onManagePoliciesAsResource}
              >
                Policies ({ currentPolicy.data.resourcePolicyIds().size})
              </div>
            </div>
            {
              {
                [PolicyResourceType.PROJECTS]: (
                  <Fragment>
                    <div className={style.toggleAllSection}>
                      <Toggle onChange={onToggleAllResourceProjects} value={applyToAllProjects} />
                      <span className={style.toggleMessage}>Apply this policy to all current and future projects</span>
                    </div>
                    <ManageProjectsTable />
                  </Fragment>
                ),
                [PolicyResourceType.DATASETS]: (
                  <Fragment>
                    <div className={style.toggleAllSection}>
                      <Toggle onChange={onToggleAllDatasets} value={applyToAllDatasets} />
                      <span className={style.toggleMessage}>Apply this policy to all current and future datasets</span>
                    </div>
                    <div className={style.datasetsSummaryMessage}>
                      <div>{currentPolicy.data.appliesToAllDatasets() ? 'All' : currentPolicy.data.datasetIds().size} Dataset resources included in this policy</div>
                      <Button onClick={onPickDatasets} disabled={applyToAllDatasets}>Open dataset catalog</Button>
                    </div>
                  </Fragment>
                ),
                [PolicyResourceType.POLICIES]: (
                  <Fragment>
                    <ManagePoliciesAsResourceTable />
                  </Fragment>
                ),
              }[tab]
            }
          </div>
        )),
      })}
      footer={policyConfig.case({
        Create: ({ name }) => (
          <ButtonToolbar>
            <Button
              onClick={onHidePolicyConfigDialog}
              buttonType="Secondary"
            >
              Cancel
            </Button>
            <ConditionalButton
              onClick={onCreatePolicy}
              preconditions={Map({
                'Policy name cannot be blank': !isBlank(name),
                'Policy name must be unique': !policyDocs.find(policy => policy.data.name === name),
              })}
            >
              Create policy
            </ConditionalButton>
          </ButtonToolbar>
        ),
        Delete: () => (
          <ButtonToolbar>
            <Button onClick={onHidePolicyConfigDialog} buttonType="Secondary">Cancel</Button>
            <Button onClick={onDeletePolicy}>Delete policy</Button>
          </ButtonToolbar>
        ),
        Duplicate: ({ name }) => (
          <ButtonToolbar>
            <Button onClick={onHidePolicyConfigDialog} buttonType="Secondary">Cancel</Button>
            <ConditionalButton
              onClick={onDuplicatePolicy}
              preconditions={Map({
                'Policy name cannot be blank': !isBlank(name),
                'Policy name must be unique': !policyDocs.find(policy => policy.data.name === name),
              })}
            >
              Duplicate policy
            </ConditionalButton>
          </ButtonToolbar>
        ),
        Edit: ({ name, currentPolicy }) => (
          <ButtonToolbar>
            <Button
              onClick={onHidePolicyConfigDialog}
              buttonType="Secondary"
            >
              Cancel
            </Button>
            <ConditionalButton
              onClick={onEditPolicy}
              preconditions={Map({
                'Policy name cannot be blank': !isBlank(name),
                'Policy name must be unique': !policyDocs.find(policy => policy.data.name === name) || currentPolicy.data.name === name,
              })}
            >
              Update policy
            </ConditionalButton>
          </ButtonToolbar>
        ),
        ManageMembers: () => (
          <ButtonToolbar>
            <Button
              onClick={onHidePolicyConfigDialog}
              buttonType="Secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={onUpdatePolicyMembers}
            >
              Save
            </Button>
          </ButtonToolbar>
        ),
        ManageResources: () => (
          <ButtonToolbar>
            <Button
              onClick={onHidePolicyConfigDialog}
              buttonType="Secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={onUpdatePolicyResources}
            >
              Save
            </Button>
          </ButtonToolbar>
        ),
      })}
    />
  );
});

export default PolicyConfigDialog;
