import _ from 'underscore';

import RecipeType from '../constants/RecipeType';
import createLoader from '../utils/createLoader';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { getPath } from '../utils/Values';
import { fetchPublishedClustersStatus } from './SuppliersAsync';

const PublishedClustersStatusLoader = createLoader(state => {
  const { suppliers: { publishedClustersDatasetStatusLoading, publishedClustersDatasetStatus } } = state;

  // See if clusters have ever been published
  const projectInfo = selectActiveProjectInfo(state);
  let clustersPublished;
  if (projectInfo) {
    const dedupRecipe = projectInfo.projectWithStatus.recipes.find(r => r.recipe.data.type === RecipeType.DEDUP);
    clustersPublished = !!getPath(dedupRecipe, 'materializations', 'publishClusters', 'lastRun', 'timestamp');
  }
  const loadedPublishedClustersDatasetName = publishedClustersDatasetStatus?.datasetName;
  const publishedClustersDatasetName = `${projectInfo?.unifiedDatasetDoc?.data?.name}_dedup_published_clusters`;
  return {
    canFetch: !!projectInfo?.unifiedDatasetDoc,
    shouldFetch: !_.isUndefined(clustersPublished) && (loadedPublishedClustersDatasetName !== publishedClustersDatasetName),
    loading: publishedClustersDatasetStatusLoading,
  };
}, {
  onFetch: fetchPublishedClustersStatus,
}, 'PublishedClustersStatusLoader');

export default PublishedClustersStatusLoader;
