// com.tamr.transform.spark.recommendDag.MappingRecDagCreator#DO_NOT_MAP_NAME
import { ArgTypes } from '../utils/ArgValidation';
import AttributeId from './AttributeId';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

export const DO_NOT_MAP_NAME = 'tamr_do_not_map';
export function getDisplaySimilarity(similarity: number): number {
  return Math.floor(100 * similarity);
}

class MappingRecommendation extends getModelHelpers({
  recommended: { type: ArgTypes.instanceOf(AttributeId) },
  similarity: { type: ArgTypes.number },
  confidence: { type: ArgTypes.nullable(ArgTypes.number) },
}, 'MappingRecommendation')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class MappingRecommendationRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  static fromJSON(obj: any) {
    return new MappingRecommendation({
      ...obj,
      recommended: new AttributeId(obj.recommended),
    });
  }
}

export default MappingRecommendation;
