import { Set } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import TooltipTrigger from '../components/TooltipTrigger';
import Document from '../models/doc/Document';
import Tag from '../models/Tag';
import { deleteTag } from './TagApi';
import style from './TagConfirmDeleteDialog.module.scss';
import { setTagColor } from './TagStore';

const TagConfirmDeleteDialog = connect((state) => {
  const { tags: { allTags, tagsToDelete } } = state;
  return { allTags, tagsToDelete };
}, {
  onDeleteTag: deleteTag,
  onSetConfirmDeleteDialogVisibility: (tagsToDelete) => ({ type: 'Tags.setConfirmDeleteDialogVisibility', tagsToDelete }),
})(class TagConfirmDeleteDialog extends React.Component {
  static propTypes = {
    allTags: ImmutablePropTypes.setOf(Document.propType.withDataType(Tag)).isRequired,
    tagsToDelete: ImmutablePropTypes.setOf(PropTypes.string),
  };

  accept = () => {
    const { tagsToDelete, onDeleteTag, onSetConfirmDeleteDialogVisibility } = this.props;
    tagsToDelete.forEach(onDeleteTag);
    onSetConfirmDeleteDialogVisibility(new Set());
  };

  getTagColor = (tagName) => { // TODO: follow on to pass tag type, rather than string
    const tagDoc = this.props.allTags.find(tag => tag.data.name === tagName);
    const tagId = tagDoc && tagDoc.id ? tagDoc.id.id : 0;
    return setTagColor(tagName, tagId);
  };

  renderToDeleteList = () => {
    return (
      <div className={style.tagsToDelete}>
        {this.props.tagsToDelete.map(tagName => (
          <div key={tagName} className={style.tagDeleteRow}>
            <TooltipTrigger
              className={style.tagDeleteOverflowTooltip}
              placement="bottom"
              content={tagName}
              trigger={['hover']}
            >
              <span className={style.tagDeletePill} style={{ background: this.getTagColor(tagName) }}>
                {tagName}
              </span>
            </TooltipTrigger>
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { tagsToDelete, onSetConfirmDeleteDialogVisibility } = this.props;
    if (!tagsToDelete || tagsToDelete.isEmpty()) {
      return <div />;
    }
    const bodyMultiTags = (
      <div className={style.tagDeletePillContainer}>
        <div>Deleting these {tagsToDelete.size} tags will remove them from all datasets and projects.</div>
        <div>{this.renderToDeleteList()}</div>
        <div>Are you sure you want to delete {tagsToDelete.size === 2 ? 'both' : 'all ' + tagsToDelete.size} tags?</div>
      </div>
    );
    const singleTag = (
      <TooltipTrigger
        className={style.tagDeleteOverflowTooltip}
        content={tagsToDelete}
        placement="bottom"
        trigger={['hover']}
      >
        <div className={style.tagDeleteSinglePill} style={{ background: this.getTagColor(tagsToDelete.first()) }}>
          {tagsToDelete.first()}
        </div>
      </TooltipTrigger>
    );
    const bodySingleTag = (
      <div className={style.tagDeletePillContainer}>
        <div>Deleting the {singleTag} tag will remove it from all datasets and projects.</div>
        <div className={style.tagDeleteSpacer}>Are you sure you want to delete the {singleTag} tag?</div>
      </div>
    );
    return (
      <Dialog
        key="tag-confirm-deletion-dialog"
        closeButton
        dialogStyle={DialogStyle.PRIMARY}
        onHide={() => onSetConfirmDeleteDialogVisibility(new Set())}
        show={!tagsToDelete.isEmpty()}
        title="Delete Tag"
        body={tagsToDelete.size > 1 ? bodyMultiTags : bodySingleTag}
        footer={(
          <ButtonToolbar>
            <Button
              onClick={this.accept}
            >
              Delete
            </Button>
            <Button
              onClick={() => onSetConfirmDeleteDialogVisibility(new Set())}
              buttonType="Secondary"
            >
              Cancel
            </Button>
          </ButtonToolbar>
        )}
      />
    );
  }
});

export default TagConfirmDeleteDialog;
