// com.tamr.dedup.models.AllAssignmentsFilterType

const AllAssignmentsFilterType = {
  ALL: 'ALL',
  ALL_RESPONDED: 'ALL_RESPONDED',
  SOME_PENDING: 'SOME_PENDING',
} as const;
export type AllAssignmentsFilterTypeE = typeof AllAssignmentsFilterType[keyof typeof AllAssignmentsFilterType];

export default AllAssignmentsFilterType;
