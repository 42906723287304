import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import ConditionalButton from '../components/ConditionalButton';
import PlainInput from '../components/Input/PlainInput';
import Selector from '../components/Input/Selector';
import Dataset from '../models/Dataset';
import DatasetMetadataSpec from '../models/DatasetMetadataSpec';
import Document from '../models/doc/Document';
import style from './AddMetadataBlock.module.scss';
import { getSingleSelectedDataset } from './DatasetMetadataStore';
import { updateMetadataForSelectedDataset } from './MetadataAsync';

const AddMetadataBlock = _.compose(
  connect(state => {
    const selectedDataset = getSingleSelectedDataset(state);
    return {
      selectedDataset,
    };
  }, {
    onAddProperty: ({ dataset, attribute, key, value }) => updateMetadataForSelectedDataset({
      dataset,
      datasetMetadata: List.of(new DatasetMetadataSpec({ attribute, key, value })),
    }),
    onCancelAddProperty: () => ({ type: 'DatasetMetadata.cancelAddProperty' }),
  }),
)(class AddMetadataBlock extends React.Component {
  static propTypes = {
    onAddProperty: PropTypes.func.isRequired,
    onCancelAddProperty: PropTypes.func.isRequired,
    selectedDataset: Document.propType.withDataType(Dataset),
  };

  state = {
    newAttribute: null,
    newKey: null,
    newValue: null,
  };

  handleChangeAttribute = (newAttribute) => {
    this.setState({ newAttribute });
  };

  handleChangeKeyName = (e) => {
    this.setState({ newKey: e.target.value });
  };

  handleChangeValueName = (e) => {
    this.setState({ newValue: e.target.value });
  };

  render() {
    const { selectedDataset, onAddProperty, onCancelAddProperty } = this.props;

    // This component should only be used when a dataset is selected.
    if (!selectedDataset) { return null; }

    // The attribute is allowed to be null before adding a property. The default attribute is the entire dataset.
    const addPropertyPreconditions = Map().set(
      'You must provide a key and value before proceeding.',
      (this.state.newKey !== null) && (this.state.newValue !== null),
    );

    const selectorValues = selectedDataset.data.fields.map(field => ({ value: field, display: field }))
      .unshift({ value: null, display: selectedDataset.data.name })
      .toArray();

    return (
      <div>
        <Selector
          className={style.objectSelector}
          placeholder={this.state.newAttribute ? this.state.newAttribute : selectedDataset.data.name}
          values={selectorValues}
          onChange={this.handleChangeAttribute}
        />
        <PlainInput
          className={style.keyInput}
          type="string"
          title="Key"
          placeholder="Key"
          onChange={this.handleChangeKeyName}
        />
        <PlainInput
          className={style.valueInput}
          type="string"
          title="Value"
          placeholder="Value"
          onChange={this.handleChangeValueName}
        />
        <ConditionalButton
          preconditions={addPropertyPreconditions}
          className={style.saveButton}
          buttonType="Primary"
          onClick={() => {
            onAddProperty({
              dataset: selectedDataset,
              attribute: this.state.newAttribute,
              key: this.state.newKey,
              value: this.state.newValue,
            });
          }}
        >
          Add Property
        </ConditionalButton>
        <Button
          className={style.cancelButton}
          buttonType="Secondary"
          onClick={onCancelAddProperty}
        >
          Cancel
        </Button>
      </div>
    );
  }
});

export default AddMetadataBlock;
