import { connect } from 'react-redux';

import { getUser } from '../users/UsersStore';
import Comment from './Comment';

const TransactionsComment = connect((state, { comment, onEditComment, onDeleteComment }) => {
  return {
    commentMessage: comment.message,
    timeInSeconds: comment.createdAt,
    lastModified: comment.modifiedAt,
    user: getUser(state, comment.username),
    onEditComment,
    onDeleteComment,
  };
})(Comment);

export default TransactionsComment;
