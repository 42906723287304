import { ArgTypes } from '../utils/ArgValidation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

// com.tamr.dedup.models.ClusterVerificationCounts

export default class ClusterVerificationCounts extends getModelHelpers({
  neverVerified: { type: ArgTypes.nullable(ArgTypes.number) },
  unverified: { type: ArgTypes.nullable(ArgTypes.number) },
  locked: { type: ArgTypes.nullable(ArgTypes.number) },
  movableDisagree: { type: ArgTypes.nullable(ArgTypes.number) },
  movableAgree: { type: ArgTypes.nullable(ArgTypes.number) },
  suggestDisagree: { type: ArgTypes.nullable(ArgTypes.number) },
  suggestAgree: { type: ArgTypes.nullable(ArgTypes.number) },
}, 'ClusterVerificationCounts')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ClusterVerificationCountsRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
    get totalNumVerifiedHere() {
      const { locked, movableAgree, suggestAgree, suggestDisagree } = this;
      return [movableAgree, suggestAgree, suggestDisagree, locked]
        .reduce((a, b) => (a || 0) + (b || 0), 0);
    }
    get totalNumVerifiedElsewhere() {
      return this.movableDisagree || 0;
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  static fromJSON(obj: {[key: string]: any}) {
    const { neverVerified, unverified, locked, movableDisagree, movableAgree, suggestDisagree, suggestAgree } = obj;
    return new ClusterVerificationCounts({
      neverVerified,
      unverified,
      locked,
      movableDisagree,
      movableAgree,
      suggestDisagree,
      suggestAgree,
    });
  }
}
