import enumify from '../constants/enumify';

export const ALL = 'ALL';
export const SOURCE = 'SOURCE';
export const UNIFIED_DATASET = 'UNIFIED_DATASET';
export const PROJECT_GENERATED = 'PROJECT_GENERATED';
export const NON_PROJECT_DERIVED = 'NON_PROJECT_DERIVED';
export const SYSTEM_DATASET = 'SYSTEM_DATASET';

export default enumify({ ALL, SOURCE, UNIFIED_DATASET, PROJECT_GENERATED, NON_PROJECT_DERIVED, SYSTEM_DATASET });
