import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Chrome from '../chrome/Chrome';
import { CLASSIFICATION, SCHEMA_MAPPING_RECOMMENDATIONS, SUPPLIER_MASTERING } from '../constants/ProjectTypes';
import CategorizationDashboard from '../dashboard/CategorizationDashboard';
import CategorizationDashboardStatsLoader from '../dashboard/CategorizationDashboardStatsLoader';
import DedupDashboard from '../dashboard/DedupDashboard';
import SchemaMappingDashboard from '../dashboard/SchemaMappingDashboard';
import ProjectInfo from '../models/ProjectInfo';
import { selectActiveProjectInfo } from '../utils/Selectors';

const ProjectDashboardPage = connect(state => {
  return { projectInfo: selectActiveProjectInfo(state) };
})(class ProjectDashboardPage extends React.Component {
  static propTypes = {
    projectInfo: PropTypes.instanceOf(ProjectInfo),
  };

  render() {
    const { projectInfo } = this.props;
    return (
      <Chrome>
        {(() => {
          switch (projectInfo && projectInfo.projectType) {
            case SUPPLIER_MASTERING: return <DedupDashboard />;
            case CLASSIFICATION:
              return (
                <div style={{ height: '100%', width: '100%' }}>
                  <CategorizationDashboardStatsLoader />
                  <CategorizationDashboard />
                </div>
              );
            case SCHEMA_MAPPING_RECOMMENDATIONS: return <SchemaMappingDashboard />;
          }
        })()}
      </Chrome>
    );
  }
});

export default ProjectDashboardPage;
