import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import { AppState } from '../stores/MainStore';


const ConflictDialog = connect((state: AppState) => {
  const { goldenRecords: { showConflictDialog } } = state;
  return { show: showConflictDialog };
}, {
  onHide: () => ({ type: 'GoldenRecords.showConflictDialog', showConflictDialog: false }),
})(({ show, onHide }) => {
  return (
    <Dialog
      dialogStyle={DialogStyle.PRIMARY}
      show={show}
      onHide={onHide}
      title="Encountered conflict saving changes"
      body={(
        <span>
          A conflict was encountered when attempting to put your local changes on top of changes recently saved.
          Your local changes have not been affected.
          <br /><br />
          You may want to <a rel="noopener noreferrer" target="_blank" href={window.location.href}>view the updated golden records module in a new tab</a>.
          There you can reproduce your local changes and Save without conflict, as you will not be able to Save changes here.
        </span>
      )}
      footer={
        <ButtonToolbar>
          <Button key="hide" buttonType="Secondary" onClick={onHide}>Okay</Button>
        </ButtonToolbar>
      }
    />
  );
});

export default ConflictDialog;
