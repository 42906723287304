import classNames from 'classnames';
import localforage from 'localforage';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import { fetchTransactions } from '../records/RecordsApi';
import { PREVIEW } from '../records/RowProviders';
import { activeUserIsCuratorForActiveProject, selectActiveProjectInfo } from '../utils/Selectors';
import AddTransformSelector from './AddTransformSelector';
import BulkTransform from './BulkTransform';
import style from './BulkTransformList.module.scss';
import { localForageKeyPrefix } from './models/LocalForageUtils';
import TransformDropTarget from './TransformDropTarget';
import { getTransformListExpanded, setTransformListExpanded } from './TransformsApi';
import { DISABLE_ALL_TRANSFORMS, getSourceScopedGuids, getUnifiedScopedGuids, selectOrdering } from './TransformsStore';

const BulkTransformList = _.compose(
  connect(
    (state, { sourceLevel }) => {
      const {
        transforms: transformsState,
        transforms: { deltas, dragging, transforms, loadedDatasetId },
      } = state;
      const projectInfo = selectActiveProjectInfo(state);
      const { unifiedDatasetId, projectId } = projectInfo;
      const projectTimestamp = projectInfo?.projectWithStatus?.project?.created?.timestamp;
      const expanded = getTransformListExpanded(state, sourceLevel);
      const bothCollapsed = !getTransformListExpanded(state, !sourceLevel) && !expanded;
      const ordering = selectOrdering(transformsState);
      const userIsCurator = activeUserIsCuratorForActiveProject(state);
      return { userIsCurator, ordering, deltas, isDragging: !!dragging, transforms, loadedDatasetId, unifiedDatasetId, projectTimestamp, projectId, expanded, bothCollapsed };
    },
    (dispatch, { sourceLevel }) => {
      return {
        setExpanded: expanded => dispatch(setTransformListExpanded(sourceLevel, expanded)),
        setCutoff: guid => dispatch({ type: 'Transforms.setPreviewCutoff', guid }),
        onUsePreview: () => dispatch({ type: 'Records.useRowProvider', provider: PREVIEW }),
        setCutoffHover: guid => dispatch({ type: 'Transforms.setPreviewCutoffHover', guid }),
        onRefetch: () => dispatch(fetchTransactions()),
      };
    },
  ),
)(class BulkTransformList extends React.Component {
  state = {};

  getScrollTopKey = () => {
    const { projectTimestamp, projectId, sourceLevel } = this.props;
    return localForageKeyPrefix(projectId, projectTimestamp) +
      (sourceLevel ? 'SCROLL_TOP_INPUT' : 'SCROLL_TOP_UNIFIED');
  };

  componentDidUpdate(prevProps) {
    // wait for transforms to load before we update the scroll position
    if (prevProps.loadedDatasetId !== this.props.loadedDatasetId) {
      this.fetchAndSetScrollTop();
    }
  }

  fetchAndSetScrollTop = () => {
    localforage.getItem(this.getScrollTopKey()).then((val) => {
      if (val) {
        this.nav.current.scrollTop = val;
      } else {
        this.nav.current.scrollTop = 0;
      }
    });
  };

  componentDidMount() {
    this.fetchAndSetScrollTop();
    this.nav.current.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    this.nav.current.removeEventListener('scroll', this.handleScroll);
  }

  nav = React.createRef();

  handleScroll = debounce(() => {
    localforage.setItem(this.getScrollTopKey(), this.nav.current.scrollTop);
  }, 100);

  set = (stateToSave) => {
    return () => {
      this.setState(stateToSave);
    };
  };

  resetAdditionalAddTransformSelector = this.set({ addAt: null });

  render() {
    const { addAt } = this.state;
    const previewFetch = () => {
      this.props.setCutoff(DISABLE_ALL_TRANSFORMS);
      this.props.onUsePreview();
      this.props.onRefetch();
    };
    const previewHover = () => {
      this.props.setCutoffHover(DISABLE_ALL_TRANSFORMS);
    };
    const { userIsCurator, ordering, expanded, setExpanded, sourceLevel, deltas, isDragging, transforms, loadedDatasetId, bothCollapsed } = this.props;
    const getScopedGuidsFn = sourceLevel ? getSourceScopedGuids : getUnifiedScopedGuids;
    const thisListGuids = getScopedGuidsFn({ deltas, transforms, loadedDatasetId });
    let bulkTransforms = thisListGuids.map((guid, index) => (
      <BulkTransform key={guid} guid={guid} currentIndex={index} sourceLevel={sourceLevel} resetAdditionalAddTransformSelector={this.resetAdditionalAddTransformSelector} />
    ));
    if (isDragging) {
      // put drop targets before every transform and at the end of the list
      bulkTransforms = thisListGuids
        .map((guid, index) => <TransformDropTarget {...{ sourceLevel }} index={index} key={`move-${index}`} />)
        .interleave(bulkTransforms)
        .push(<TransformDropTarget {...{ sourceLevel }} index={thisListGuids.size} key={`move-${thisListGuids.size}`} />);
    } else if (expanded && userIsCurator) {
      bulkTransforms = thisListGuids
        .map(
          (guid, index) =>
            (index === addAt ? (
              <div key={`add-${index}`} className={style.addTransformationDropDown}>
                <AddTransformSelector {...{ sourceLevel, toIndex: index }} resetAdditionalAddTransformSelector={this.resetAdditionalAddTransformSelector} />
              </div>
            ) : (
              <div key={`hover-add-${index}`} className={style.addTransformation} onClick={this.set({ addAt: index })}>
                <div className={style.container}>
                  <div className={style.line} />
                  <span className={style.text}>Add transformation</span>
                  <div className={style.line} />
                </div>
              </div>
            )),
        )
        .interleave(bulkTransforms);
    }
    return (
      <div
        className={classNames(style.component, { [style.expanded]: expanded, [style.borderBottom]: bothCollapsed && !sourceLevel })}
        ref={this.nav}
        data-test-element={sourceLevel ? 'input-transform-list' : 'unified-transform-list'}
      >
        <div className={style.header}>
          <TamrIcon
            onClick={() => {
              this.resetAdditionalAddTransformSelector();
              return setExpanded(!expanded);
            }}
            iconName={expanded ? 'tamr-icon-caret' : 'tamr-icon-caret-right'}
            className={style.expandIcon}
            size={16}
          />
          <span className={style.title}>{sourceLevel ? 'Transformations for Input Datasets' : 'Transformations on Unified Dataset'}</span>
          <span className={classNames(style.count, { [style.empty]: thisListGuids.isEmpty() })}>({thisListGuids.size})</span>
          <TooltipTrigger
            content={
              sourceLevel
                ? 'These transformations act on records from input datasets before they are unified in the Unified Dataset. Input datasets themselves will remain unchanged.'
                : 'These transformations act on records in the Unified Dataset after all input datasets are unified.'
            }
            placement="left"
          >
            <TamrIcon iconName="info-outline" className={style.infoIcon} size={14} />
          </TooltipTrigger>
        </div>
        {expanded && sourceLevel && !ordering.isEmpty() && userIsCurator ? (
          <div className={style.previewNoTransforms}>
            <Button buttonType="Link" onClick={previewFetch} onMouseEnter={previewHover} onMouseLeave={() => this.props.setCutoffHover()}>
              Preview
            </Button>{' '}
            without any transformations.
          </div>
        ) : null}
        {expanded ? bulkTransforms.isEmpty() ? <div className={style.noTransforms}>No transformations applied</div> : bulkTransforms.toArray() : null}
        {expanded && userIsCurator ? <AddTransformSelector {...{ sourceLevel }} /> : null}
      </div>
    );
  }
});

BulkTransformList.propTypes = {
  sourceLevel: PropTypes.bool.isRequired, // is this list for only source level transforms, vs. unified dataset transforms
};

export default BulkTransformList;
