import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Term from '../components/Term';
import { getAuthorizedUser, selectActiveProjectInfo } from '../utils/Selectors';
import { pluralize } from '../utils/Strings';
import { getPath } from '../utils/Values';
import { confirmRemovingResponse } from './RecordPairsAsync';

const ConfirmRemoveResponseDialog = _.compose(
  connect((state) => {
    const { recordPairs: { warningAboutRemoveResponse, pairs }, users: { users } } = state;
    const { projectId } = selectActiveProjectInfo(state) || {};
    const loggedInUsername = getAuthorizedUser(state).username;
    return { warningAboutRemoveResponse, pairs, projectId, users, loggedInUsername };
  }, {
    onCancelRemovingResponse: () => ({ type: 'RecordPairs.cancelRemovingResponse' }),
    onConfirmRemovingResponse: confirmRemovingResponse,
  }),
)(({ pairs, warningAboutRemoveResponse, onCancelRemovingResponse, projectId, users, loggedInUsername, onConfirmRemovingResponse }) => {
  const show = !!warningAboutRemoveResponse;
  const affectedPairs = show && pairs.filter((pair, i) => {
    return warningAboutRemoveResponse.rowNumbers.has(i)
      && getPath(pair.getVerifiedResponse(projectId, users), 'username') === loggedInUsername
      && pair.otherCuratorResponses(projectId, users, loggedInUsername).isEmpty();
  });
  return (
    <Dialog
      show={show}
      onHide={onCancelRemovingResponse}
      closeButton
      dialogStyle={DialogStyle.PRIMARY}
      title="Remove Verification?"
      body={show ? (
        <div>
          <span>
            You are the only curator to have verified a response for&nbsp;
            {pluralize(
              warningAboutRemoveResponse.rowNumbers.size,
              <span>this <Term>pair</Term></span>,
              <span>{affectedPairs.size === warningAboutRemoveResponse.rowNumbers.size ? '' : `${affectedPairs.size} of `}the selected <Term>pairs</Term></span>,
            )}
            .
          </span>
          <br /><br />
          <span>
            {warningAboutRemoveResponse.action === 'SKIP' ? 'Skipping' : 'Removing your response'}
            &nbsp;will remove {pluralize(affectedPairs.size, 'this verification', 'these verifications')}. Are you sure you want to continue?
          </span>
        </div>
      ) : null}
      footer={(
        <ButtonToolbar>
          <Button buttonType="Secondary" onClick={onCancelRemovingResponse}>Cancel</Button>
          <Button onClick={onConfirmRemovingResponse}>Yes (remove {pluralize(affectedPairs.size || 0, 'verification', 'verifications')})</Button>
        </ButtonToolbar>
      )}
    />
  );
});

export default ConfirmRemoveResponseDialog;
