import TypedTable from '../models/TypedTable';
import { setter, update, updater } from '../utils/Collections';
import * as DescribedAsyncStatus from '../utils/DescribedAsyncStatus';


export interface ClusterSampleResourceDescription {
  clusterId: string
  fetchSequence: number
}

interface ClusterSampleInformation {
  clusterName: string
  clusterSize: number
}

type ClusterSampleAsyncStatus = DescribedAsyncStatus.DescribedAsyncStatus<TypedTable, ClusterSampleResourceDescription>

export interface ClusterSampleState {
  showClusterSampleTable: boolean
  clusterInformation: ClusterSampleInformation | null
  desiredResourceInformation: {
    clusterId: string | null,
    fetchSequence: number
  }
  table: ClusterSampleAsyncStatus
}

export function initialClusterSampleState(): ClusterSampleState {
  return {
    showClusterSampleTable: false,
    clusterInformation: null,
    desiredResourceInformation: {
      clusterId: null,
      fetchSequence: 1,
    },
    table: DescribedAsyncStatus.initial(),
  };
}

export function getLastFetchedResourceDescription(state: ClusterSampleState): ClusterSampleResourceDescription | null {
  return DescribedAsyncStatus.getLastFetchedResourceDescription(state.table);
}

export function getDesiredResourceDescription(state: ClusterSampleState): ClusterSampleResourceDescription | null {
  const { desiredResourceInformation: { clusterId } } = state;
  if (clusterId === null) {
    return null;
  }
  return {
    clusterId,
    fetchSequence: state.desiredResourceInformation.fetchSequence,
  };
}

function getLastFetchedFetchSequence(state: ClusterSampleState): number {
  return getLastFetchedResourceDescription(state)?.fetchSequence || 1; // default fetch sequence
}

export function toggleShowClusterSampleTable(state: ClusterSampleState): ClusterSampleState {
  return update(state, 'showClusterSampleTable', show => !show);
}

export function triggerFetch(state: ClusterSampleState): ClusterSampleState {
  return update(state, 'desiredResourceInformation', updater('fetchSequence', x => x + 1));
}

export function updateDesiredSampleCluster(
  state: ClusterSampleState,
  clusterId: string,
  clusterName: string,
  clusterSize: number,
): ClusterSampleState {
  return update(state,
    setter('showClusterSampleTable', true),
    updater('desiredResourceInformation', setter('clusterId', clusterId)),
    setter('clusterInformation', {
      clusterName,
      clusterSize,
    }));
}

export function loadingClusterSampleState(
  previousState: ClusterSampleState,
  clusterId: string,
  clusterName: string,
  clusterSize: number,
  fetchSequence: number,
): ClusterSampleState {
  return {
    ...previousState,
    clusterInformation: { clusterName, clusterSize },
    table: DescribedAsyncStatus.toLoading(previousState.table, {
      clusterId,
      fetchSequence,
    }),
  };
}

export function successClusterSampleState(previousState: ClusterSampleState, clusterId: string, table: TypedTable): ClusterSampleState {
  return {
    ...previousState,
    table: DescribedAsyncStatus.success(table, {
      clusterId,
      fetchSequence: getLastFetchedFetchSequence(previousState),
    }),
  };
}

export function errorClusterSampleState(previousState: ClusterSampleState, clusterId: string): ClusterSampleState {
  return {
    ...previousState,
    table: DescribedAsyncStatus.toError(previousState.table, {
      clusterId,
      fetchSequence: getLastFetchedFetchSequence(previousState),
    }),
  };
}

export function isLoading(state: ClusterSampleState): boolean {
  return state.table.type === 'loading';
}

export function shouldFetch(state: ClusterSampleState): boolean {
  return DescribedAsyncStatus.resourceDescriptionDiffers(state.table, getDesiredResourceDescription(state));
}
