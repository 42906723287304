import { is } from 'immutable';

import createLoader from '../utils/createLoader';
import { fetchSourceList } from './GoldenRecordsAsync';
import { selectSourceListFilterInfo } from './GoldenRecordsStore';


const SourceListLoader = createLoader((state) => {
  const { goldenRecords, goldenRecords: { loadedSourceListFilterInfo, loadingSourceList } } = state;
  const filterInfo = selectSourceListFilterInfo(goldenRecords);
  return {
    canFetch: !!filterInfo.sourceListDatasetName,
    shouldFetch: !is(filterInfo, loadedSourceListFilterInfo),
    loading: loadingSourceList,
  };
}, {
  onFetch: fetchSourceList,
}, 'SourceListLoader');

export default SourceListLoader;
