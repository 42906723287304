import classNames from 'classnames';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import BulletedTooltipTrigger from '../components/BulletedTooltipTrigger';
import style from './SchemaMappingListHeader.module.scss';
import { getNoUnifiedAttributesInSystem } from './SchemaMappingStore';
import UnifiedBulkExpandTrigger from './UnifiedBulkExpandTrigger';
import UnifiedListStats from './UnifiedListStats';

const UnifiedListHeader = connect((state) => {
  return { noAttributes: getNoUnifiedAttributesInSystem(state) };
})(class UnifiedListHeader extends React.Component {
  static propTypes = {
    canUserEdit: PropTypes.bool.isRequired,
    noAttributes: PropTypes.bool.isRequired,
  };

  render() {
    const { noAttributes, canUserEdit } = this.props;
    return (
      <div>
        {noAttributes ?
          <BulletedTooltipTrigger items={List(['You have no unified attributes'])}>
            <div className={style.shroud} />
          </BulletedTooltipTrigger>
          : null}
        <div className={style.controlsContainer} />
        <div className={style.statsContainer}>
          <UnifiedListStats canUserEdit={canUserEdit} />
          <div className={classNames(style.controls, style.unified)}>
            <UnifiedBulkExpandTrigger />
          </div>
        </div>
      </div>
    );
  }
});

export default UnifiedListHeader;
