import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes, toJSON } from './Model';

export default class HealthCheckResult extends getModelHelpers({
  healthy: { type: ArgTypes.bool },
  message: { type: ArgTypes.nullable(ArgTypes.string) },
  timestamp: { type: ArgTypes.string },
  details: { type: ArgTypes.any },
}, 'HealthCheckResult')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class HealthCheckResultRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  toJSON() {
    return toJSON(super.toJSON());
  }

  static fromJSON(obj: $TSFixMe) {
    return new HealthCheckResult({
      healthy: obj.healthy,
      message: obj.message,
      timestamp: obj.timestamp,
      details: obj.details,
    });
  }
}
