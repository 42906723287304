import React from 'react';
import { connect } from 'react-redux';

import { recordValueShim } from '../components/MultiValue';
import TamrIcon from '../components/TamrIcon';
import GeospatialDetailsDialog from '../geospatial/GeospatialDetailsDialog';
import LongLatCard from '../geospatial/LongLatCard';
import SidebarMap from '../geospatial/SidebarMap';
import styles from './TransactionSidebarDetailsGeospatial.module.scss';
import { getActiveRecord } from './TransactionStore';

function TransactionSidebarDetailsGeospatial({
  attributeName,
  geoTamr,
  closeSubSidebar,
  isOpenDialog,
  openDetailsDialog,
  closeDetailsDialog,
  tileServers,
}) {
  return (
    <div className={styles.detailsGeospatial}>
      {GeospatialDetailsDialog({
        attributeName,
        geoTamrs: [geoTamr],
        isOpen: isOpenDialog,
        close: closeDetailsDialog,
      })}
      <div onClick={closeSubSidebar} className={styles.navigation}>
        <TamrIcon
          size={14}
          iconName="arrow-back"
          className={styles.backArrow}
        />
        <span>Record details</span>
      </div>
      <div className={styles.attributeHeader}>
        <div className={styles.attributeHeading}>{attributeName}</div>
      </div>
      {SidebarMap({
        geoTamrs: [geoTamr],
        tileServers,
      })}
      <div>
        <div className={styles.recordLocationText}>RECORD LOCATION</div>
        {LongLatCard({ geoTamr, openDetailsDialog })}
      </div>
    </div>
  );
}

export default connect(
  state => {
    const { transactions, projects: { tileServers } } = state;
    const attribute = transactions.activeGeospatialAttribute;
    const attributeName = attribute.name;
    const record = getActiveRecord(transactions);
    const { data, renderRaw, originalData } = record.getValue(attributeName);
    const recordValues = recordValueShim({ value: data, raw: renderRaw, originalData });
    return {
      attributeName,
      isOpenDialog: attribute.isOpenDialog,
      geoTamr: recordValues[0].case({
        GeoTamr: ({ geoTamr }) => geoTamr,
        _: () => {
          console.error(`${attributeName} is not GeoTamr`);
          return null;
        },
      }),
      tileServers,
    };
  },
  {
    closeSubSidebar: () => ({ type: 'Transactions.closeGeospatialDetailsSidebar' }),
    openDetailsDialog: () => ({ type: 'Transactions.openGeospatialDetailsDialog' }),
    closeDetailsDialog: () => ({ type: 'Transactions.closeGeospatialDetailsDialog' }),
  },
)(TransactionSidebarDetailsGeospatial);
