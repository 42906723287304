import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import BulletedTooltipTrigger from './BulletedTooltipTrigger';
import Button, { ButtonProps } from './Button';
import { propTypes as tooltipTriggerPropTypes } from './TooltipTrigger';

export const preconditionsPropType = ImmutablePropTypes.mapOf(PropTypes.bool.isRequired, PropTypes.node.isRequired);

const propTypes = {
  preconditions: preconditionsPropType.isRequired,
  tooltipLabel: PropTypes.node,
  tooltipPlacement: tooltipTriggerPropTypes.placement,
};


type ConditionalButtonComponentProps = InferProps<typeof propTypes>; // not including spread
type ConditionalButtonProps = ConditionalButtonComponentProps & ButtonProps;

export type PreconditionsMap = ConditionalButtonComponentProps['preconditions']

/**
 * Intended in cases that require a button that has a list of preconditions that need to be met
 *   before the button enables (eg. "You must label at least 5 records before you can submit this job.")
 * Will display failed preconditions in a tooltip.
 */
export default class ConditionalButton extends React.Component<ConditionalButtonProps> {
  static propTypes = propTypes;
  render() {
    const { preconditions, tooltipLabel, tooltipPlacement, children, ...spreadProps } = this.props;

    // just here to make sure that spreadProps is properly typed by this point
    const typedSpreadProps: ButtonProps = spreadProps;

    return (
      <BulletedTooltipTrigger
        className="action-button-tooltip"
        items={preconditions.filter(passing => !passing).keySeq().toList()}
        label={tooltipLabel}
        placement={tooltipPlacement}
      >
        <Button
          disabled={preconditions.some(passing => passing === false)}
          {...(typedSpreadProps)}
        >
          {children}
        </Button>
      </BulletedTooltipTrigger>
    );
  }
}
