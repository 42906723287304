import { List } from 'immutable';
import $ from 'jquery';
import _ from 'underscore';
import uri from 'urijs';

import { updateRecipe } from '../api/RecipeClient';
import { SHOW } from '../errorDialog/ErrorDialogActionTypes';
import { ExportConfig } from '../models/Export';
import { RecordType } from '../transforms/models/Types';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { getPath } from '../utils/Values';
import { doProfileDataset, doUpdateDataset } from './DatasetsApi';
import { COMMIT_CONFIGURE_DATASETS_COMPLETED } from './SharedDatasetCatalogActionTypes';

const getDatasets = (state, datasetNames) => {
  const { location: { recipeId }, projectDatasetCatalog: { datasets: projectDatasets }, datasetCatalog: { datasets: allDatasets } } = state;
  if (_.isNumber(recipeId)) {
    return projectDatasets.filter(d => datasetNames.has(d.data.name));
  }
  return allDatasets.filter(d => datasetNames.has(d.data.name));
};

const getStatus = state => {
  const { location: { recipeId }, projectDatasetCatalog: { status: projectStatus }, datasetCatalog: { status } } = state;
  if (_.isNumber(recipeId)) {
    return projectStatus;
  }
  return status;
};

export const fetchDatasetSample = ({ datasetName }) => (dispatch) => {
  dispatch({ type: 'SharedDatasetCatalog.fetchDatasetSample', datasetName });
  $.ajax({
    url: uri(SERVICES.transform(`/samples/${datasetName}`)),
    method: 'GET',
    cache: false,
  }).then(
    (response) => {
      dispatch({
        type: 'SharedDatasetCatalog.fetchDatasetSampleCompleted',
        rows: List(response.rows),
        schema: RecordType.fromJSON(response.schema),
      });
    },
    (response) => {
      dispatch({ type: 'SharedDatasetCatalog.clearDatasetSample' });
      dispatch({ type: SHOW, detail: 'Error viewing dataset', response });
    });
};

export const submitExport = () => (dispatch, getState) => {
  const state = getState();
  const { sharedDatasetCatalog: { confirmingExportForDatasets, confirmingOverwriteExportForDatasets } } = state;
  const datasetNames = confirmingExportForDatasets.isEmpty() ? confirmingOverwriteExportForDatasets : confirmingExportForDatasets;
  const datasets = getDatasets(state, datasetNames);
  const status = getStatus(state);
  dispatch({ type: 'SharedDatasetCatalog.submitExport' });
  const ajaxCalls = datasets.map(dataset => {
    const exportConfig = ExportConfig.fromJSON({
      datasetId: dataset.id.id,
      revision: status.get(dataset.data.name).currentRevision,
      columns: dataset.data.fields,
      formatConfiguration: {
        writeHeader: true,
        delimiterCharacter: ',',
        quoteCharacter: '"',
        nullValue: '',
      },
    });
    return $.ajax({
      url: uri(SERVICES.procure('/export')),
      method: 'POST',
      data: JSON.stringify(exportConfig.toJSON()),
      contentType: 'application/json',
      error: (response) => {
        dispatch({ type: SHOW,
          detail: 'Error launching export',
          response,
        });
      },
    });
  });
  $.when(...ajaxCalls).then(() => {
    dispatch({ type: 'SharedDatasetCatalog.submitExportCompleted' });
  });
};

export const submitProfile = () => (dispatch, getState) => {
  const { sharedDatasetCatalog: { confirmingProfileForDatasets } } = getState();
  dispatch({ type: 'SharedDatasetCatalog.submitProfile' });
  const ajaxCalls = confirmingProfileForDatasets.map(datasetName => doProfileDataset(datasetName, dispatch)).toArray();
  $.when(...ajaxCalls).then(() => {
    dispatch({ type: 'SharedDatasetCatalog.submitProfileCompleted' });
  }).fail((response) => {
    dispatch({ type: SHOW,
      detail: 'Error launching profiling',
      response,
    });
  });
};

export const commitConfigureDataset = ({ description, weight, external }) => (dispatch, getState) => {
  dispatch({ type: 'SharedDatasetCatalog.commitConfigureDataset' });
  const state = getState();
  const { sharedDatasetCatalog: { configuringDataset }, projectDatasetCatalog: { datasets: projectDatasets }, datasetCatalog: { datasets: allDatasets }, location: { recipeId } } = state;
  const datasets = _.isNumber(recipeId) ? projectDatasets : allDatasets;
  const datasetDoc = datasets.find(d => d.data.name === configuringDataset);
  const ajaxCalls = [];
  if (datasetDoc.data.description !== description) {
    const updatedDataset = datasetDoc.setIn(['data', 'description'], description);
    ajaxCalls.push(doUpdateDataset(updatedDataset));
  }
  const datasetName = datasetDoc.data.name;
  const { recipeDoc } = selectActiveProjectInfo(state);
  const c12nMetadata = getPath(recipeDoc, 'data', 'metadata', 'CATEGORIZATION');
  if (
    (weight !== undefined || external !== undefined) && (
      (weight !== getPath(c12nMetadata, 'datasetWeights', datasetName)) ||
      (external !== getPath(c12nMetadata, 'datasetExternalStatus', datasetName))
    )
  ) {
    const recipe = recipeDoc.data.update('metadata', m => {
      const cMetadata = m.get('CATEGORIZATION') || {};
      cMetadata.datasetWeights = cMetadata.datasetWeights || {};
      cMetadata.datasetWeights[datasetName] = parseInt(weight, 10);
      cMetadata.datasetExternalStatus = cMetadata.datasetExternalStatus || {};
      cMetadata.datasetExternalStatus[datasetName] = external;
      return m.set('CATEGORIZATION', cMetadata);
    });
    ajaxCalls.push(updateRecipe({
      recipeId: recipeDoc.id.id,
      version: recipeDoc.lastModified.version,
      recipe,
    }));
  }
  $.when(...ajaxCalls).then(() => {
    dispatch({ type: COMMIT_CONFIGURE_DATASETS_COMPLETED });
  });
};
