import React from 'react';
import { connect } from 'react-redux';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import _ from 'underscore';

import Button from '../components/Button';
import { getPath } from '../utils/Values';
import style from './TransformEditorPanel.module.scss';
import { mapStateToProps as mapTransformInputStateToProps, TransformInput } from './TransformInput';
import { lintStatement } from './TransformsApi';
import { getLatestForBulkTransform, shouldShowBigEditor } from './TransformsStore';


class TransformEditor extends React.Component {
  onFormulaChange = (newExpr, formula) => {
    const { onEdit, onGetLint, guid } = this.props;
    const newFormula = formula.set('expr', newExpr);
    onEdit(guid, newFormula);
    onGetLint(newFormula);
  };

  onMultiFormulaChange = (newExpr, multiFormula) => {
    const { onEdit, onGetLint, guid } = this.props;
    const newMultiFormula = multiFormula.set('expr', newExpr);
    onEdit(guid, newMultiFormula);
    onGetLint(newMultiFormula);
  };

  onScriptChange = (newValue, script) => {
    const { onEdit, onGetLint, guid } = this.props;
    const newScript = script.set('op', newValue);
    onEdit(guid, newScript);
    onGetLint(newScript);
  };

  visitFill = () => null;

  visitFormula = (formula) => {
    const { guid, inputState } = this.props;
    return (
      <div key={'input-' + guid} className={style.transformInput}>
        <TransformInput
          {...inputState}
          guid={guid}
          onChange={newExpr => this.onFormulaChange(newExpr, formula)}
        />
      </div>
    );
  };

  visitMultiFormula = (multiFormula) => {
    const { guid, inputState } = this.props;
    return (
      <div key={'input-' + guid} className={style.transformInput}>
        <TransformInput
          {...inputState}
          guid={guid}
          onChange={newExpr => this.onMultiFormulaChange(newExpr, multiFormula)}
          />
      </div>
    );
  };

  visitScript = (script) => {
    const { guid, inputState } = this.props;
    return (
      <div key={'input-' + guid} className={style.transformInput}>
        <TransformInput
          {...inputState}
          guid={guid}
          onChange={newValue => this.onScriptChange(newValue, script)}
        />
      </div>
    );
  };

  visitUnpivot = () => null;

  render() {
    const { transform, onCollapse } = this.props;
    const content = transform.accept(this);
    if (!content) {
      return null;
    }
    return (
      <div key="editor-panel" className={style.editorPanel}>
        <div className={style.topContainer}>
          <div className={style.headerContainer}>
            EDIT CODE
          </div>
          <div className={style.buttonContainer}>
            <Button
              className={style.collapseButton}
              buttonType="Secondary"
              onClick={onCollapse}
            >
              Collapse
            </Button>
          </div>
        </div>
        <div className={style.editorContent}>
          {content}
        </div>
      </div>
    );
  }
}

const TransformEditorPanel = _.compose(
  connect((state) => {
    const { transforms, transforms: { selected } } = state;
    if (!selected) {
      return {};
    }
    const transform = getLatestForBulkTransform(transforms, selected);
    // because ReactCSSTransitionGroup will re-render its child (with the last props passed in!)
    // after the child gets removed, we have to bypass TransformInput's connect() method, since we could pass through a guid
    // that would cause an NPE in the connect() method on this re-render after a delete.
    const inputState = mapTransformInputStateToProps(state, { guid: selected });
    return {
      showEditor: shouldShowBigEditor(transforms),
      transform: getPath(transform, 'operation'),
      guid: selected,
      inputState,
    };
  }, {
    onEdit: (guid, operation) => ({ type: 'Transforms.editOperation', guid, operation }),
    onGetLint: lintStatement,
    onCollapse: () => ({ type: 'Transforms.setBigEditor', enabled: false }),
  }),
)(({ transform, guid, onEdit, onCollapse, onGetLint, showEditor, inputState }) => {
  const transitionTime = parseInt(style.transitionTime, 10);
  return (
    <ReactCSSTransitionGroup
      transitionName={{
        enter: style.expandEnter,
        enterActive: style.expandEnterActive,
        leave: style.expandLeave,
        leaveActive: style.expandLeaveActive,
      }}
      transitionEnterTimeout={transitionTime}
      transitionLeaveTimeout={transitionTime}
    >
      {showEditor ? <TransformEditor {...{ transform, guid, onEdit, onCollapse, onGetLint, showEditor, inputState }} /> : null}
    </ReactCSSTransitionGroup>
  );
});

export default TransformEditorPanel;
