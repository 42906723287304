import { ArgTypes } from '../utils/ArgValidation';
import { checkTypesAndCast } from '../utils/CheckTypesAndCast';
import { AbstractRule, genericRuleArgTypes } from './Rule';

// com.tamr.recipe.models.flows.goldenRecords.AggExprRule

export const TYPE = 'Expression';

export interface AggExprRule extends AbstractRule {
  type: typeof TYPE
  expression: string
}
export const argTypes = {
  type: ArgTypes.eq(TYPE as typeof TYPE),
  expression: ArgTypes.string,
  ...genericRuleArgTypes,
} as const;
export const fromJSON = checkTypesAndCast<AggExprRule, typeof argTypes>(argTypes);

export const argType = ArgTypes.object.withShape(argTypes);
