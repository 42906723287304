import { ArgTypes } from '../utils/ArgValidation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

// com.tamr.wms.job.JobStatus.Metric

class JobMetric extends getModelHelpers({
  metricGroup: { type: ArgTypes.nullable(ArgTypes.string) },
  attributeName: { type: ArgTypes.nullable(ArgTypes.string) },
  metricKey: { type: ArgTypes.string },
  metricValue: { type: ArgTypes.any },
}, 'JobMetric')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class JobMetricRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: any) {
    const { metricGroup, attributeName, metricKey, metricValue } = obj;
    return new JobMetric({
      metricGroup,
      attributeName,
      metricKey,
      metricValue,
    });
  }
}

export default JobMetric;
