import './ProgressBar.scss';

import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import { ProgressBar } from 'react-bootstrap';

import baseProgressBar from './BaseProgressBar';
import { baseClass, variants } from './Constants';


const propTypes = {
  /**
   * Children should be other instances of the tamr progress bar,
   * and should be used when dividing the fill area into more than
   * two segments.
   */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  className: PropTypes.string,
  /**
   * CSS class containing styles for the progress segment's fill.
   * Usually will contain background color
   */
  fillClass: PropTypes.string,
  /**
   * Enables log-scale progress bars (they're linear by default)
   */
  logarithmic: PropTypes.bool,
  /**
   * Maximum value that now can take. Used to compute percentage.
   */
  max: PropTypes.number,
  /**
   * Minimum value that now can take. Used to compute percentage.
   */
  min: PropTypes.number,
  /**
   * Current value of progress section
   */
  now: PropTypes.number,
  /**
   * Determines which fill color to use for negative space
   */
  variant: PropTypes.oneOf(variants),
};

class TamrProgressBar extends React.Component<InferProps<typeof propTypes>> {
  static propTypes = propTypes;

  render() {
    const { max, min, now } = this.props;
    if (React.Children.count(this.props.children)) {
      const children = React.Children.map(
        this.props.children,
        // @ts-expect-error
        child => baseProgressBar(child.props),
      );
      return (
        <ProgressBar
          {...this.props}
          max={max || undefined}
          min={min || undefined}
          now={now || undefined}
          className={classNames(baseClass, this.props.className)}
        >
          {children}
        </ProgressBar>
      );
    }
    return baseProgressBar({
      ...this.props,
      max: max || undefined,
      min: min || undefined,
      now: now || undefined,
    });
  }
}

export default TamrProgressBar;
