import PropTypes from 'prop-types';
import React from 'react';
import { pure } from 'recompose';
import _ from 'underscore';

import SearchBox from '../components/SearchBox';

const SchemaMappingRefinement = _.compose(
  pure,
)(class SchemaMappingRefinement extends React.Component {
  static propTypes = {
    onSearch: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  };

  render() {
    const { value, onSearch } = this.props;
    return (
      <div className="schema-mapping-refinement">
        <span className="input-container">
          <SearchBox value={value} onSearch={onSearch} />
        </span>
      </div>
    );
  }
});

export default SchemaMappingRefinement;
