import React from 'react';
import { connect } from 'react-redux';

import TamrIcon from '../components/TamrIcon';
import AppState from '../stores/AppState';
import styles from './GeospatialMap.module.scss';

function getGeoFeatureLoadingWarningMessage(
  currentGeoRows: number,
  currentAdjacentGeoRows: number,
  totalGeoRows: number,
  totalAdjacentGeoRows: number,
  clusterName: string | null,
  showGeospatialOverlay: boolean,
) {
  let current;
  let total;
  let target;

  if (totalGeoRows > currentGeoRows) {
    current = currentGeoRows;
    total = totalGeoRows;
    target = clusterName;
  } else if (totalAdjacentGeoRows > currentAdjacentGeoRows && showGeospatialOverlay) {
    current = currentAdjacentGeoRows;
    total = totalAdjacentGeoRows;
    target = 'other clusters';
  } else {
    return null;
  }

  return `Only ${current} of ${total} features from ${target} can be shown.`;
}

const mapStateToProps = (state: AppState) => {
  const {
    suppliers: {
      geospatial: {
        geoRows,
        adjacentGeoRows,
        totalGeoFeatures,
        totalAdjacentGeoFeatures,
        activeClusterName,
        showGeospatialOverlay,
      },
    },
  } = state;

  return {
    geoFeatureLoadingWarningMsg: getGeoFeatureLoadingWarningMessage(
      geoRows.size,
      adjacentGeoRows.size,
      totalGeoFeatures,
      totalAdjacentGeoFeatures,
      activeClusterName,
      showGeospatialOverlay,
    ),
  };
};

const GeoFeatureLoadingErrorMessage: React.FC<ReturnType<typeof mapStateToProps>> = (({ geoFeatureLoadingWarningMsg }) => {
  return geoFeatureLoadingWarningMsg
    ? (<div className={styles.tileError}>
      <TamrIcon className={styles.warningIcon} iconName="warning" size={14} />
      <span>{geoFeatureLoadingWarningMsg}</span>
    </div>) : null;
});

export default connect(mapStateToProps)(GeoFeatureLoadingErrorMessage);
