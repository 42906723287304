// com.tamr.wms.job.Job

import { Map } from 'immutable';

import { ArgTypes } from '../utils/ArgValidation';
import JobStatus from './JobStatus';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

export const RECIPE_OPERATION_METADATA_KEY = 'recipeOperation';
export const RECIPE_ID_METADATA_KEY = 'recipeId';
export const PROJECT_ID_METADATA_KEY = 'projectId';

class Job extends getModelHelpers({
  executorId: { type: ArgTypes.nullable(ArgTypes.string) },
  specification: { type: ArgTypes.nullable(ArgTypes.Immutable.map) },
  workspace: { type: ArgTypes.string },
  status: { type: ArgTypes.instanceOf(JobStatus) },
  description: { type: ArgTypes.string },
  metadata: { type: ArgTypes.nullable(ArgTypes.Immutable.map) },
  uuid: { type: ArgTypes.nullable(ArgTypes.string) },
  totalNodeCount: { type: ArgTypes.number },
}, 'Job')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class JobRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  static fromJSON(obj: any) {
    const { executorId, specification, workspace, status, description, metadata, uuid, totalNodeCount } = obj;
    return new Job({
      executorId,
      specification: specification ? Map(specification) : specification,
      workspace,
      status: JobStatus.fromJSON(status),
      description,
      metadata: metadata ? Map(metadata) : metadata,
      uuid,
      totalNodeCount,
    });
  }
}

export default Job;
