import { List } from 'immutable';
import _ from 'underscore';

import { ProjectStepsKey, ProjectStepsKeyE } from '../constants/ProjectStepsKey';
import { CATEGORIZATION, DEDUP, ENRICHMENT, GOLDEN_RECORDS } from '../constants/RecipeType';
import Document from '../models/doc/Document';
import MaterializationStatus from '../models/MaterializationStatus';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import ProjectInfo from '../models/ProjectInfo';
import Recipe from '../models/Recipe';
import { AppState } from '../stores/MainStore';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { getPath } from '../utils/Values';

const MAT_STATUS_ARGTYPE = ArgTypes.instanceOf(MaterializationStatus);

function isInitialized(materialization: MaterializationStatus) {
  checkArg({ materialization }, MAT_STATUS_ARGTYPE);
  return _.isNumber(getPath(materialization.lastRun, 'version'));
}

// returns true if the materialization has been run before and is now out of date
function isUpToDate(materialization: MaterializationStatus): boolean {
  checkArg({ materialization }, MAT_STATUS_ARGTYPE);
  return !isInitialized(materialization) || materialization.current;
}

export function getRecipeUrlFragment(recipeDoc: Document<Recipe>): string {
  checkArg({ recipeDoc }, Document.argTypeWithNestedClass(Recipe));
  return `/recipe/${recipeDoc.id.id}`;
}

export class ProjectStepInfo extends getModelHelpers({
  key: { type: ArgTypes.string },
  destination: { type: ArgTypes.orUndefined(ArgTypes.string) }, // url of page this project step corresponds to, or undefined to denote no link
  initialized: { type: ArgTypes.bool },
  upToDate: { type: ArgTypes.bool },
}, 'ProjectStepInfo')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ProjectStepInfoRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  static fromMaterialization(
    key: string,
    materialization: MaterializationStatus | undefined,
    forceOutOfDateness: boolean,
    destination?: string,
  ): ProjectStepInfo {
    checkArg({ materialization }, MAT_STATUS_ARGTYPE);

    const initialized = !!materialization && isInitialized(materialization);
    const upToDate = (forceOutOfDateness || !materialization) ? false : isUpToDate(materialization);
    return new ProjectStepInfo({ key, destination, initialized, upToDate });
  }
}

// returns List of ProjectStepInfo models for tracked operations in all recipes
// note: schema mapping recommendations is not tracked
export const getProjectStepInfos = function (projectInfo: ProjectInfo | null | undefined) {
  if (!projectInfo) {
    return List();
  }
  checkArg({ projectInfo }, ArgTypes.instanceOf(ProjectInfo));

  if (projectInfo.projectType === GOLDEN_RECORDS) {
    return List.of(
      ProjectStepInfo.fromMaterialization(
        ProjectStepsKey.INDEX_DRAFT,
        projectInfo.grRecipeWithStatus?.materializations.first(),
        false,
      ),
    );
  }

  if (projectInfo.projectType === ENRICHMENT) {
    return List.of(
      ProjectStepInfo.fromMaterialization(
        ProjectStepsKey.INDEX_DRAFT,
        projectInfo.enrichmentRecipeWithStatus?.materializations.first(),
        false,
      ),
    );
  }

  const { smRecipeWithStatus, smRecipeDoc, recipeWithStatus, recipeDoc,
    projectType } = projectInfo;
  const infos = [];
  const smRecipeUrlFrag = smRecipeDoc && getRecipeUrlFragment(smRecipeDoc);
  const recipeUrlFrag = getRecipeUrlFragment(recipeDoc);

  // schema mapping
  // NB currently there are two instances of unintended UX:
  //      1) adding a dataset to a project triggers out of dateness for the schema mapping operation
  //      2) this out of dateness does not cause downstream operations to be out of date
  //    solving (1) at this time requires backend reworking, or a prohibitive amount of custom
  //    logic in the front end.
  //    The use of `smUpToDate` below and `forceOutOfDateness` above is to address (2).
  const smMaterialization = smRecipeWithStatus?.materializations.first(undefined);
  const smProjectStepInfo = ProjectStepInfo.fromMaterialization(
    ProjectStepsKey.RECORDS,
    smMaterialization,
    false,
    `/schema-mapping${smRecipeUrlFrag}`,
  );
  const smUpToDate = smProjectStepInfo.upToDate;
  infos.push(smProjectStepInfo);

  // terminal recipe operations
  const { materializations } = recipeWithStatus;
  const emptyMaterialization = new MaterializationStatus({ lastRun: null, latestData: null, current: false });
  if (projectType === DEDUP) {
    // pairs
    const pairsMaterialization = materializations.get(ProjectStepsKey.PAIRS);
    infos.push(ProjectStepInfo.fromMaterialization(
      ProjectStepsKey.PAIRS,
      pairsMaterialization,
      !smUpToDate,
      `/dnf-builder${recipeUrlFrag}`,
    ));

    // trainPredictCluster
    const tpcMaterialization = materializations.get(ProjectStepsKey.TRAIN_PREDICT_CLUSTERS, emptyMaterialization);
    const pcMaterialization = materializations.get(ProjectStepsKey.PREDICT_CLUSTER, emptyMaterialization);
    const predictMaterialization = materializations.get(ProjectStepsKey.PREDICT_PAIRS, emptyMaterialization);
    const clusterMaterialization = materializations.get(ProjectStepsKey.CLUSTER_PAIRS, emptyMaterialization);
    infos.push(new ProjectStepInfo({
      key: ProjectStepsKey.TRAIN_PREDICT_CLUSTERS,
      destination: `/pairs${recipeUrlFrag}`,
      initialized: isInitialized(pcMaterialization) || isInitialized(tpcMaterialization),
      /**
       * If either predictClusters or trainPredictCluster has been run and at least one of them is up to date, mark the project as up to date.
       *
       * Note that if trainPredictCluster is up to date regardless of whether predictClusters is up
       * to date, the model and resulting clusters are up to date in relation to the latest input
       * data and labels.  Alternatively, if predictClusters is up to date and trainPredictCluster
       * is not, the model is not up to date in relation to the latest labels.  However, there is
       * no distinction between these two cases in the current implementation.
       *
       * The project is also up to date if the predict and cluster steps were run individually
       * which is represented by the logic clause after the second OR operator below.
       */
      upToDate: (isInitialized(pcMaterialization) && isUpToDate(pcMaterialization))
        || (isInitialized(tpcMaterialization) && isUpToDate(tpcMaterialization))
        || (isInitialized(predictMaterialization) && isUpToDate(predictMaterialization)
          && isInitialized(clusterMaterialization) && isUpToDate(clusterMaterialization)
        ),
    }));
  } else if (projectType === CATEGORIZATION) {
    // categorization
    const c12nMaterialization = materializations.get(ProjectStepsKey.CATEGORIZATIONS, emptyMaterialization);
    const c12nPredictMaterialization = materializations.get(ProjectStepsKey.PREDICT_CATEGORIZATIONS, emptyMaterialization);
    infos.push(new ProjectStepInfo({
      key: ProjectStepsKey.CATEGORIZATIONS,
      destination: `/spend${recipeUrlFrag}`,
      initialized: isInitialized(c12nMaterialization),
      upToDate: smUpToDate && ((isInitialized(c12nMaterialization) && isUpToDate(c12nMaterialization))
        || (isInitialized(c12nPredictMaterialization) && isUpToDate(c12nPredictMaterialization))),
    }));
  }

  return List(infos);
};

export function isStepUpToDate(state: AppState, stepKey: ProjectStepsKeyE): boolean {
  const projectInfo = selectActiveProjectInfo(state);
  const projectStepInfos = getProjectStepInfos(projectInfo);
  return projectStepInfos.some(step => step.key === stepKey && step.upToDate);
}

export function isStepInitialized(state: AppState, stepKey: ProjectStepsKeyE): boolean {
  const projectInfo = selectActiveProjectInfo(state);
  const projectStepInfos = getProjectStepInfos(projectInfo);
  return projectStepInfos.some(step => step.key === stepKey && step.initialized);
}

export function getNumOutOfDateSteps(projectStepInfos: List<ProjectStepInfo>) {
  return projectStepInfos.count(projectStepInfo => (
    projectStepInfo.initialized && !projectStepInfo.upToDate
  ));
}
