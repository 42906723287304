import PropTypes from 'prop-types';

import modelJSON from '../../../../../../connect/persistence/core/src/main/resources/fixtures/models/Document.json';
import { ArgTypes, checkArg, Checker } from '../../utils/ArgValidation';
import { $TSFixMe } from '../../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes, toJSON } from '../Model';
import Checkpoint from './Checkpoint';
import DocumentId from './DocumentId';

// Facebook's checkPropTypes doesn't return the propType, but rather logs the error directly to the console
// This implementation intercepts that call to the console and instead returns the error, if there was one
// TODO this implementation is deeply coupled with the implementation of checkPropTypes
const checkPropTypes = (propTypes: $TSFixMe, props: $TSFixMe, componentName: $TSFixMe) => {
  const redundantPrefix = 'Warning: Failed prop type: ';
  let foundError;
  const oldConsoleError = console.error;
  console.error = (emsg: $TSFixMe) => {
    const withoutPrefix = emsg.startsWith(redundantPrefix)
      ? emsg.charAt(redundantPrefix.length).toLowerCase() + emsg.slice(redundantPrefix.length + 1) // TODO returning this with a capital I (in 'Invalid') gets intercepted by React's prop type checking and turned into undefined somehow
      : emsg;
    foundError = new Error(withoutPrefix);
  };
  PropTypes.checkPropTypes(propTypes, props, 'prop', componentName);
  console.error = oldConsoleError;
  return foundError;
};

class Document<T> extends getModelHelpers({
  id: { type: ArgTypes.instanceOf(DocumentId) },
  created: { type: ArgTypes.instanceOf(Checkpoint) },
  lastModified: { type: ArgTypes.instanceOf(Checkpoint) },
  data: { type: ArgTypes.object }, // TODO can we inject this to be more specific?
}, 'Document')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class DocumentRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  data: T;

  static get argType() { return ArgTypes.instanceOf(this); }
  get version() {
    return this.lastModified.version;
  }

  /**
   * Create a document from a JSON blob and a data constructor
   */
  static fromJSON<T>(doc: any, dataConstructor: ((dataJSON: any) => T) = d => d) {
    checkArg({ doc }, ArgTypes.object);
    return new Document<T>({
      id: DocumentId.fromJSON(doc.documentId || doc.id),
      created: Checkpoint.fromJSON(doc.created),
      lastModified: Checkpoint.fromJSON(doc.lastModified),
      data: dataConstructor(doc.data),
    });
  }
  toJSON() {
    return toJSON(super.toJSON());
  }

  static argTypeWithNestedClass = function<C extends { new (...args: any): InstanceType<C> }> (clazz: C): Checker<Document<InstanceType<C>>> {
    return function (doc: Document<InstanceType<C>>) {
      const docCheckerStatus = Document.argType(doc);
      if (docCheckerStatus) {
        return docCheckerStatus;
      }
      const dataCheckerStatus = ArgTypes.instanceOf(clazz)(doc.data);
      if (dataCheckerStatus) {
        dataCheckerStatus.argName = argName => `the data within ${argName}`;
        return dataCheckerStatus;
      }
      return null;
    };
  };
  static withNestedArgType = function<T extends Function> (nestedChecker: Checker<T>): Checker<Document<T>> {
    return (arg: Document<T>) => {
      const docCheckerStatus = Document.argType(arg);
      if (docCheckerStatus) return docCheckerStatus;
      const nestedCheckerStatus = nestedChecker(arg.data);
      if (nestedCheckerStatus) {
        nestedCheckerStatus.argName = argName => `the data within ${argName}`;
        return nestedCheckerStatus;
      }
      return null;
    };
  };

  static propType = Object.assign(
    PropTypes.instanceOf(Document),
    {
      withDataType(clazz: $TSFixMe) {
        const baseChecker = (isRequired: $TSFixMe, props: $TSFixMe, propName: $TSFixMe, component: $TSFixMe) => {
          const docPropType = isRequired ? Document.propType.isRequired : Document.propType;
          const docPropTypeStatus = checkPropTypes({ [propName]: docPropType }, props, component);
          if (docPropTypeStatus) {
            return docPropTypeStatus;
          }
          if (!isRequired && props[propName] === undefined) {
            return undefined;
          }
          const nestedData = props[propName].data;
          if (!(nestedData instanceof clazz)) {
            let badValue = `${nestedData}`;
            if (nestedData && nestedData.constructor) {
              badValue = `${nestedData} (${nestedData.constructor.name})`;
            }
            return new Error(`Invalid prop \`${propName}\` supplied to \`${component.toString()}\``
                           + `, expected nested data type to be an instance of ${clazz.name}`
                           + `, found ${badValue}.`);
          }
        };
        // @ts-expect-error
        const notIsRequired = (props, propName, component) => baseChecker(false, props, propName, component);
        // @ts-expect-error
        notIsRequired.isRequired = (props, propName, component) => baseChecker(true, props, propName, component);
        return notIsRequired;
      },
    },
  );
}

export const documentWithUserJSON = modelJSON;

export default Document;
