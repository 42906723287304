import { ArgTypes } from '../utils/ArgValidation';
import { checkTypesAndCast } from '../utils/CheckTypesAndCast';
import * as EnricherSchema from './EnricherSchema';

export interface EnricherRegistration {
  id: string
  location: string
  name: string
  schema: EnricherSchema.EnricherSchema
  type: string
  version: string
}

export const argTypes = {
  id: ArgTypes.string,
  location: ArgTypes.string,
  name: ArgTypes.string,
  schema: EnricherSchema.argType,
  type: ArgTypes.string,
  version: ArgTypes.string,
} as const;

export const fromJSON = checkTypesAndCast<EnricherRegistration, typeof argTypes>(argTypes);
export const argType = ArgTypes.object.withShape(argTypes);
