const ClustersSort = {
  NAME: 'name..asc',
  NAME_REVERSE: 'name..desc',
  RECORDS_MOST_FIRST: 'recordCount..desc',
  RECORDS_LEAST_FIRST: 'recordCount..asc',
  SPEND_HIGHEST_FIRST: 'totalSpend..desc',
  SPEND_LOWEST_FIRST: 'totalSpend..asc',
} as const;
export type ClustersSortE = typeof ClustersSort[keyof typeof ClustersSort];

export default ClustersSort;
