import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import DatasetManagementDialog from '../addDataset/DatasetManagementDialog';
import Chrome from '../chrome/Chrome';
import PageHeader from '../chrome/PageHeader';
import SearchBox from '../components/SearchBox';
import ConfigureDatasetDialog from '../datasets/ConfigureDatasetDialog';
import ConfirmChangeProjectMembershipsDialog from '../datasets/ConfirmChangeProjectMembershipsDialog';
import ConfirmDeleteDatasetDialog from '../datasets/ConfirmDeleteDatasetDialog';
import ConfirmDeleteMetadataFromDatasetDialog from '../datasets/ConfirmDeleteMetadataFromDatasetDialog';
import ConfirmExportDialog from '../datasets/ConfirmExportDialog';
import ConfirmProfileDialog from '../datasets/ConfirmProfileDialog';
import ConfirmRemoveDatasetsFromProjectDialog from '../datasets/ConfirmRemoveDatasetsFromProjectDialog';
import DatasetPreviewDialog from '../datasets/DatasetPreviewDialog';
import ExportFailureDialog from '../datasets/ExportFailureDialog';
import ExportWithCoreConnectService from '../datasets/ExportWithCoreConnectService';
import ExportWithJDBCDialog from '../datasets/ExportWithJDBCDialog';
import ManageProjectsForDatasetsDialog from '../datasets/ManageProjectsForDatasetsDialog';
import MetadataSidebar from '../datasets/MetadataSidebar';
import ProjectDatasetCatalogLoader from '../datasets/ProjectDatasetCatalogLoader';
import ProjectDatasetCatalogTable from '../datasets/ProjectDatasetCatalogTable';
import ProjectDatasetCatalogTaskbar from '../datasets/ProjectDatasetCatalogTaskbar';
import { longFormat } from '../utils/Numbers';
import PoliciesLoader from '../accessControl/PoliciesLoader';
import DatasetPoliciesDialog from '../datasets/DatasetPoliciesDialog';

const ProjectDatasetCatalogPage = connect((state) => {
  const { projectDatasetCatalog: { paging } } = state;
  const { datasetMetadata: { propertiesSidebarOpen } } = state;
  return { paging, propertiesSidebarOpen };
}, {
  onFetch: () => ({ type: 'ProjectDatasetCatalog.reload' }),
  onSetSearchString: (search) => ({ type: 'ProjectDatasetCatalog.setSearchString', search }),
})(class ProjectDatasetCatalogPage extends React.Component {
  static propTypes = {
    onFetch: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.onFetch();
  }

  render() {
    const { paging, propertiesSidebarOpen, onSetSearchString } = this.props;
    return (
      <Chrome
        rightSidebar={<MetadataSidebar />}
        isExpanded={propertiesSidebarOpen}
      >
        <PageHeader
          title={<span>Input Datasets <span className="page-header-metrics">{longFormat(paging.numDatasets)}</span></span>}
          searchbox={<SearchBox value={paging.search} onSearch={onSetSearchString} />}
        />
        <ProjectDatasetCatalogTaskbar />
        <ProjectDatasetCatalogTable />
        <ExportFailureDialog />
        <ProjectDatasetCatalogLoader />
        <PoliciesLoader />
        <DatasetPoliciesDialog />
        <DatasetManagementDialog />
        <ConfirmRemoveDatasetsFromProjectDialog />
        <ConfirmDeleteDatasetDialog />
        <ManageProjectsForDatasetsDialog />
        <ConfirmChangeProjectMembershipsDialog />
        <ConfirmExportDialog />
        <ConfirmProfileDialog />
        <ConfigureDatasetDialog />
        <DatasetPreviewDialog />
        <ConfirmDeleteMetadataFromDatasetDialog />
        <ExportWithCoreConnectService />
        <ExportWithJDBCDialog />
      </Chrome>
    );
  }
});

export default ProjectDatasetCatalogPage;
