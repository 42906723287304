import classNames from 'classnames';
import React from 'react';
import _ from 'underscore';

import style from './TextHeaderCell.module.scss';


const TextHeaderCell: React.FC<{
  children: React.ReactNode
  truncate?: boolean
}> = ({ children, truncate = false }) => {
  return (
    <div className="table-header-cell">
      <div className={classNames(style.inner, { [style.truncate]: truncate })} title={_.isString(children) && children || undefined}>{children}</div>
    </div>
  );
};

export default TextHeaderCell;
