import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';

import SortState from '../constants/SortState';
import { FCWithPropTypes } from '../utils/typescript-react';
import TamrIcon from './TamrIcon';

/**
 * Renderer for the header of a column that should be sortable
 * @param className extra className to add to header
 * @param col The key of the column
 * @param display What to show in the column header. If not provided, uses 'col' value.
 * @param sortState The current state of the sort column
 * @param sortCallback The function to call when sorting
 * @param rightAlign If true, right align the header
 */
const SortableHeaderCell = FCWithPropTypes({
  className: PropTypes.string,
  col: PropTypes.string.isRequired,
  display: PropTypes.node,
  rightAlign: PropTypes.bool,
  sortCallback: PropTypes.func.isRequired,
  sortState: PropTypes.oneOf(_.values(SortState)),
  titleHover: PropTypes.string,
}, ({ className, col, display, sortState, sortCallback, rightAlign, titleHover }) => {
  const displayVal = display || col;
  return (
    <div className={classNames('table-header-cell', 'sortable-header', className)} onClick={_.partial(sortCallback, col)}>
      <div className={classNames('table-header-wrap', { 'right-align': rightAlign })} title={titleHover || col}>{displayVal}</div>
      <div style={{ flex: 'none' }}>
        {(() => {
          switch (sortState) {
            case SortState.SORTED_ASCENDING:
              return <TamrIcon iconName="tamr-icon-rounded-sort-up" className="table-header-icon" size={14} />;
            case SortState.SORTED_DESCENDING:
              return <TamrIcon iconName="tamr-icon-rounded-sort-down" className="table-header-icon" size={14} />;
            default:
              return <TamrIcon iconName="dummy-no-icon-for-spacing" className="table-header-icon" size={14} />;
          }
        })()}
      </div>
    </div>
  );
});

export default SortableHeaderCell;
