import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import DnfBuilderClause from './DnfBuilderClause';

const DnfBuilderClauses = connect((state) => {
  const { dnfBuilder: { dnf: { clauses } } } = state;
  return { clauses };
}, {
  onAddNewClauseEntry: () => ({ type: 'DnfBuilder.addNewClauseEntry' }),
})(({ clauses, onAddNewClauseEntry }) => {
  return (
    <div className="dnf-builder-clauses">
      {clauses.flatMap((clause, i) => {
        const value = [
          <DnfBuilderClause
            key={`clause-${i}`}
            clause={clause}
            clauseOrder={i}
          />,
        ];
        if (i !== clauses.size - 1) {
          value.push(
            <div key={`clause-separator-${i}`} className="clause-separator">
              <hr />
              <span className="or-overlay">OR</span>
            </div>,
          );
        }
        return value;
      })}
      <Button
        buttonType="Link"
        className="add-clause-entry-button"
        onClick={onAddNewClauseEntry}
      >
        Add another row
      </Button>
    </div>
  );
});

export default DnfBuilderClauses;
