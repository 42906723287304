import classNames from 'classnames';
import { List, Set } from 'immutable';
import PropTypes, { InferProps, Requireable, Validator } from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import _ from 'underscore';

import DataTables, { DataTablesType } from '../constants/DataTables';
import { PERSISTENT_ID } from '../constants/PipelineConstants';
import DisplayColumn from '../models/DisplayColumn';
import { AppDispatch } from '../stores/MainStore';
import { setColumnPreferences } from '../users/UsersAsync';
import PRODUCT_NAME from '../utils/ProductName';
import { moveIndex, sliceBounds, textMatch } from '../utils/Values';
import Button from './Button';
import ButtonToolbar from './ButtonToolbar';
import style from './ColumnOrderSelector.module.scss';
import Dialog, { DialogStyle } from './Dialog/Dialog';
import Highlighter from './Highlighter';
import SearchBox from './SearchBox';
import TamrIcon from './TamrIcon';
import TooltipTrigger from './TooltipTrigger';


export const ColumnHandle = SortableHandle(({ className }: { className?: string }) => {
  return (
    <span className={classNames(style.handle, className)}>
      <TamrIcon iconName="drag-handle" size={16} />
    </span>
  );
});

const ColumnItem = SortableElement(({ item, onToggle, onSendToTop, onSendToBottom, searchValue, isMlAttribute, isGeneratedAttribute, renderAdditionalItemContent }: {
  item: DisplayColumn,
  onToggle: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onSendToTop: (() => void) | null
  onSendToBottom: (() => void) | null
  searchValue: string
  isMlAttribute: boolean
  isGeneratedAttribute: boolean
  renderAdditionalItemContent?: (item: DisplayColumn) => React.ReactNode | undefined
}) => {
  const { displayName, visible } = item;
  return (
    <div className={style.row}>
      <ColumnHandle />
      <div className={style.label} onClick={onToggle}>
        <TamrIcon
          iconName={visible ? 'tamr-icon-checkbox-checked' : 'tamr-icon-checkbox-unchecked'}
          className={style.checkbox}
          size={15}
        />
        <div className={classNames(style.columnName, { [style.selected]: visible })}>
          <Highlighter fullText={displayName === PERSISTENT_ID ? 'Cluster Id' : displayName} highlightText={searchValue} />
          {isMlAttribute ? (
            <TooltipTrigger placement="top" content="This attribute is included in machine learning">
              <TamrIcon size={12} iconName="tamr-icon-ml-on" className={style.tamrIconMlOn} />
            </TooltipTrigger>
          ) : null}
          {isGeneratedAttribute ? (
            <TooltipTrigger placement="top" content={`This attribute was created by ${PRODUCT_NAME}`}>
              <TamrIcon size={14} iconName="tamr-icon-logo" className={style.tamrIconLogo} />
            </TooltipTrigger>
          ) : null}
          {renderAdditionalItemContent && renderAdditionalItemContent(item)}
        </div>
      </div>
      {onSendToTop ?
        <TooltipTrigger content="Send to top" placement="top">
          <span className={style.sendButton} onClick={onSendToTop}>
            <TamrIcon iconName="tamr-icon-rounded-sort-up" size={16} />
          </span>
        </TooltipTrigger>
        : null}
      {onSendToBottom ?
        <TooltipTrigger content="Send to bottom" placement="top">
          <span className={style.sendButton} onClick={onSendToBottom}>
            <TamrIcon iconName="tamr-icon-rounded-sort-down" size={16} />
          </span>
        </TooltipTrigger>
        : null}
    </div>
  );
});

const ColumnList = SortableContainer(({ items, onToggle, onSendToTop, onSendToBottom, searchValue, numPinnedColumns, mlAttributes, generatedAttributes, renderAdditionalItemContent }: {
  items: List<DisplayColumn>
  onToggle: (index: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onSendToTop: (order: number) => void
  onSendToBottom: (order: number) => void
  searchValue: string
  numPinnedColumns: number
  mlAttributes: Set<string>
  generatedAttributes: Set<string>
  renderAdditionalItemContent?: (item: DisplayColumn) => React.ReactNode | undefined
}) => {
  return (
    <div className={style.list}>
      {items.filter(({ displayName }) => !searchValue || textMatch(displayName, searchValue)).map((item, i) =>
        (<ColumnItem
          key={i}
          item={item}
          index={i}
          onToggle={(event) => onToggle(i, event)}
          onSendToTop={item.order > numPinnedColumns ? () => onSendToTop(item.order) : null}
          onSendToBottom={item.order < items.size - 1 + numPinnedColumns ? () => onSendToBottom(item.order) : null}
          searchValue={searchValue}
          isMlAttribute={mlAttributes.contains(item.displayName)}
          isGeneratedAttribute={generatedAttributes.contains(item.displayName)}
          {...{ renderAdditionalItemContent }}
        />),
      )}
    </div>
  );
});

const columnOrderSelectorPropTypes = {
  columnSettings: ImmutablePropTypes.listOf(PropTypes.instanceOf(DisplayColumn).isRequired).isRequired,
  generatedAttributes: ImmutablePropTypes.setOf(PropTypes.string),
  mlAttributes: ImmutablePropTypes.setOf(PropTypes.string),
  numPinnedColumns: PropTypes.number,
  onSetColumnPreferences: PropTypes.func as Requireable<(page: DataTablesType, columns: List<DisplayColumn>) => void>,
  pageType: DataTables.propType.isRequired as Validator<DataTablesType>,
  // TODO samgqroberts 2020-10-21 this should be the only way we render additional item content
  //   which is to say generatedAttributes and mlAttributes props should be removed, and this should be used
  //   to achieve that functionality
  renderAdditionalItemContent: PropTypes.func as Requireable<(item: DisplayColumn) => React.ReactNode | undefined>,
};

type ColumnOrderSelectorOwnProps = InferProps<typeof columnOrderSelectorPropTypes>;

const columnOrderSelectorMapDispatchToProps = (dispatch: AppDispatch, props: ColumnOrderSelectorOwnProps) => {
  const onSetColumnPreferences: (page: DataTablesType, columns: List<DisplayColumn>) => void
    = props.onSetColumnPreferences
      ? props.onSetColumnPreferences
      : (page: DataTablesType, columns: List<DisplayColumn>) => dispatch(setColumnPreferences(page, columns));
  return { onSetColumnPreferences };
};

type ColumnOrderSelectorProps = Omit<ColumnOrderSelectorOwnProps, 'onSetColumnPreferences'> & {
  onSetColumnPreferences: (page: DataTablesType, columns: List<DisplayColumn>) => void
};

type ColumnOrderSelectorState = {
  dialogVisible: boolean
  searchValue: string
  columns: List<DisplayColumn> | undefined
  lastSelectedIndex: number
}

class UnconnectedColumnOrderSelector extends React.Component<ColumnOrderSelectorProps, ColumnOrderSelectorState> {
  static defaultProps = {
    mlAttributes: Set<string>(),
    generatedAttributes: Set<string>(),
    numPinnedColumns: 0,
  };

  state: ColumnOrderSelectorState = {
    dialogVisible: false,
    searchValue: '',
    columns: undefined,
    lastSelectedIndex: 0,
  };

  onMove = (i1: number, i2: number) => {
    const { numPinnedColumns } = this.props;
    this.setState(({ columns }) => ({
      columns: moveIndex(columns, i1, i2).map((col: DisplayColumn, i: number) => col.set('order', i + (numPinnedColumns || 0))),
    }));
  };

  onToggle = (index: number, { shiftKey }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // @ts-expect-error
    this.setState(({ columns, lastSelectedIndex, searchValue }) => {
      if (!columns) {
        return;
      }
      // @ts-expect-error
      const items = columns.map((column, i) => [column, i]).filter(([{ displayName }]) => !searchValue || textMatch(displayName, searchValue));
      if (!items) {
        return;
      }
      if (shiftKey && _.isNumber(lastSelectedIndex)) {
        return { columns: items.slice(...sliceBounds(index, lastSelectedIndex)).reduce((rn, [, i]) => rn.setIn([i, 'visible'], true), columns) };
      }
      // @ts-expect-error
      return { columns: columns.updateIn([items.get(index)[1], 'visible'], b => !b), lastSelectedIndex: index };
    });
  };

  onSubmit = () => {
    const { props: { pageType, onSetColumnPreferences }, state: { columns } } = this;
    if (!columns) {
      return;
    }
    onSetColumnPreferences(pageType, columns);
    this.onClose();
  };

  onOpen = () => {
    const { columnSettings } = this.props;
    this.setState({ dialogVisible: true, searchValue: '', columns: columnSettings });
  };

  onClose = () => {
    this.setState({ dialogVisible: false });
  };

  onSearch = (searchValue: string) => {
    this.setState({ searchValue, lastSelectedIndex: 0 });
  };

  render() {
    const { dialogVisible, columns, searchValue } = this.state;
    const { numPinnedColumns, mlAttributes, generatedAttributes, renderAdditionalItemContent } = this.props;
    return (
      <div>
        <div className={style.openButton}>
          <Button
            buttonType="Flat"
            onClick={this.onOpen}
            icon="settings"
          >
            Configure Table
          </Button>
        </div>
        <Dialog
          show={dialogVisible}
          onHide={this.onClose}
          title="Configure Table"
          dialogStyle={DialogStyle.PRIMARY}
          body={
            <div>
              <div className={style.description}>
                Select the columns you want visible. Drag to reorder them.
              </div>
              <SearchBox value={searchValue} onSearch={this.onSearch} searchOnKeyup />
              <ColumnList
                // @ts-expect-error
                items={columns}
                searchValue={searchValue}
                helperClass={style.dragged}
                lockAxis="y"
                lockToContainerEdges
                useDragHandle
                onToggle={this.onToggle}
                onSortEnd={({ oldIndex, newIndex }) => this.onMove(oldIndex, newIndex)}
                // @ts-expect-error
                onSendToTop={index => this.onMove(index - numPinnedColumns, 0)}
                // @ts-expect-error
                onSendToBottom={index => this.onMove(index - numPinnedColumns, columns.size - 1)}
                shouldCancelStart={() => !!searchValue}
                // @ts-expect-error
                numPinnedColumns={numPinnedColumns}
                // @ts-expect-error
                mlAttributes={mlAttributes}
                // @ts-expect-error
                generatedAttributes={generatedAttributes}
                {...{ renderAdditionalItemContent }}
              />
            </div>
          }
          footer={
            <ButtonToolbar>
              <Button onClick={this.onClose} buttonType="Secondary">
                Cancel
              </Button>
              <Button onClick={this.onSubmit}>
                Done
              </Button>
            </ButtonToolbar>
          }
        />
      </div>
    );
  }
}

const ColumnOrderSelector = connect(null, columnOrderSelectorMapDispatchToProps)(UnconnectedColumnOrderSelector);
ColumnOrderSelector.propTypes = columnOrderSelectorPropTypes;

export default ColumnOrderSelector;
