/* eslint-disable global-require */
const TamrIconClasses = {
  'access-time': require('./images/access-time.svg'),
  'arrow-back': require('./images/arrow-back.svg'),
  'arrow-drop-down': require('./images/arrow-drop-down.svg'),
  'attach-money': require('./images/attach-money.svg'),
  bookmark: require('./images/bookmark.svg'),
  'bookmark-empty': require('./images/bookmark-empty.svg'),
  business: require('./images/business.svg'),
  book: require('./images/book.svg'),
  'call-made': require('./images/call-made.svg'),
  'call-merge': require('./images/call-merge.svg'),
  'categorization-project': require('./images/categorization-project.svg'),
  'chat-bubble': require('./images/chat-bubble.svg'),
  check: require('./images/check.svg'),
  'check-circle': require('./images/check-circle.svg'),
  'chevron-left': require('./images/chevron-left.svg'),
  'chevron-right': require('./images/chevron-right.svg'),
  close: require('./images/close.svg'),
  comment: require('./images/comment.svg'),
  delete: require('./images/delete.svg'),
  domain: require('./images/domain.svg'),
  'do-not-disturb-alt': require('./images/do-not-disturb-alt.svg'),
  'drag-handle': require('./images/drag-handle.svg'),
  duplicate: require('./images/duplicate.svg'),
  'dummy-no-icon-for-spacing': require('./images/dummy-no-icon-for-spacing.svg'),
  edit: require('./images/edit.svg'),
  'enrichment-project': require('./images/enrichment-project.svg'),
  error: require('./images/error.svg'),
  expand: require('./images/expand.svg'),
  'file-copy': require('./images/file-copy.svg'),
  'filter-list': require('./images/filter-list.svg'),
  'geospatial-toggle-off': require('./images/geospatial-toggle-off.svg'),
  'geospatial-toggle-on': require('./images/geospatial-toggle-on.svg'),
  'group-add': require('./images/group-add.svg'),
  'group-work': require('./images/group-work.svg'),
  help: require('./images/help.svg'),
  'highlight-off': require('./images/highlight-off.svg'),
  'hourglass-full': require('./images/hourglass-full.svg'),
  'hourglass-empty': require('./images/hourglass-empty.svg'),
  'info-outline': require('./images/info-outline.svg'),
  laptop: require('./images/laptop.svg'),
  'label-filled': require('./images/label-filled.svg'),
  'library-books': require('./images/library-books.svg'),
  'line-style': require('./images/line-style.svg'),
  'lightbulb-on': require('./images/lightbulb-on.svg'),
  'lightbulb-off': require('./images/lightbulb-off.svg'),
  lock: require('./images/lock.svg'),
  'lock-open': require('./images/lock-open.svg'),
  'mastering-project': require('./images/mastering-project.svg'),
  'golden-records-project': require('./images/golden-records-project.svg'),
  map: require('./images/map-24px.svg'),
  match: require('./images/match.svg'),
  menu: require('./images/menu.svg'),
  'more-vert': require('./images/more-vert.svg'),
  'my-location': require('./images/my_location-24px.svg'),
  new: require('./images/new.svg'),
  'open-in-new': require('./images/open-in-new.svg'),
  people: require('./images/people.svg'),
  person: require('./images/person.svg'),
  'person-pin-circle': require('./images/person-pin-circle.svg'),
  place: require('./images/place.svg'),
  'radio-button-checked': require('./images/radio-button-checked.svg'),
  'radio-button-unchecked': require('./images/radio-button-unchecked.svg'),
  redo: require('./images/redo.svg'),
  refresh: require('./images/refresh.svg'),
  'remove-circle-outline': require('./images/remove-circle-outline.svg'),
  'schema-mapping-project': require('./images/schema-mapping-project.svg'),
  search: require('./images/search.svg'),
  share: require('./images/share.svg'),
  settings: require('./images/settings.svg'),
  'settings-input-component-rotated': require('./images/settings-input-component-rotated.svg'),
  star: require('./images/star.svg'),
  'star-border': require('./images/star-border.svg'),
  'skip-next': require('./images/skip-next.svg'),
  'view-agenda': require('./images/view-agenda.svg'),
  subdirectoryarrow: require('./images/subdirectory_arrow_right.svg'),
  visibility: require('./images/visibility.svg'),
  'visibility-off': require('./images/visibility-off.svg'),
  warning: require('./images/warning.svg'),
  whatshot: require('./images/whatshot.svg'),
  'tamr-icon-add': require('./images/tamr-icon-add.svg'),
  'tamr-icon-alphabetical': require('./images/tamr-icon-alphabetical.svg'),
  'tamr-icon-caret': require('./images/tamr-icon-caret.svg'),
  'tamr-icon-caret-flat': require('./images/tamr-icon-caret-flat.svg'),
  'tamr-icon-caret-left': require('./images/tamr-icon-caret-left.svg'),
  'tamr-icon-caret-right': require('./images/tamr-icon-caret-right.svg'),
  'tamr-icon-caret-up': require('./images/tamr-icon-caret-up.svg'),
  'tamr-icon-check': require('./images/tamr-icon-check.svg'),
  'tamr-icon-check-inverted': require('./images/tamr-icon-check-inverted.svg'),
  'tamr-icon-checkbox-check': require('./images/tamr-icon-checkbox-check.svg'),
  'tamr-icon-checkbox-checked': require('./images/tamr-icon-checkbox-checked.svg'),
  'tamr-icon-checkbox-partial': require('./images/tamr-icon-checkbox-partial.svg'),
  'tamr-icon-checkbox-unchecked': require('./images/tamr-icon-checkbox-unchecked.svg'),
  'tamr-icon-circle-inverted': require('./images/tamr-icon-circle-inverted.svg'),
  'tamr-icon-close': require('./images/tamr-icon-close.svg'),
  'tamr-icon-close-inverted': require('./images/tamr-icon-close-inverted.svg'),
  'tamr-icon-fill': require('./images/tamr-icon-fill.svg'),
  'tamr-icon-fx': require('./images/tamr-icon-fx.svg'),
  'tamr-icon-fx-multi': require('./images/tamr-icon-fx-multi.svg'),
  'tamr-icon-ic_assignment': require('./images/tamr-icon-ic_assignment.svg'),
  'tamr-icon-ic_skip_next_black': require('./images/tamr-icon-ic_skip_next_black.svg'),
  'tamr-icon-lightbulb': require('./images/tamr-icon-lightbulb.svg'),
  'tamr-icon-lightning-bolt': require('./images/tamr-icon-lightning-bolt.svg'),
  'tamr-icon-logo': require('./images/tamr-icon-logo.svg'),
  'tamr-icon-mag-glass': require('./images/tamr-icon-mag-glass.svg'),
  'tamr-icon-ml-on': require('./images/tamr-icon-ml-on.svg'),
  'tamr-icon-ml-off': require('./images/tamr-icon-ml-off.svg'),
  'tamr-icon-more-vert': require('./images/tamr-icon-more-vert.svg'),
  'tamr-icon-numeric': require('./images/tamr-icon-numeric.svg'),
  'tamr-icon-remove': require('./images/tamr-icon-remove.svg'),
  'tamr-icon-rounded-arrow-down': require('./images/tamr-icon-rounded-arrow-down.svg'),
  'tamr-icon-rounded-arrow-right': require('./images/tamr-icon-rounded-arrow-right.svg'),
  'tamr-icon-rounded-sort-down': require('./images/tamr-icon-sort-down.svg'),
  'tamr-icon-rounded-sort-up': require('./images/tamr-icon-sort-up.svg'),
  'tamr-icon-sources-nav': require('./images/tamr-icon-sources-nav.svg'),
  'tamr-icon-script': require('./images/tamr-icon-script.svg'),
  'tamr-icon-stacked': require('./images/tamr-icon-stacked.svg'),
  'tamr-icon-unpivot': require('./images/tamr-icon-unpivot.svg'),
  'tamr-icon-warning': require('./images/tamr-icon-warning.svg'),
  folder: require('./images/folder.svg'),
  'folder-open': require('./images/folder-open.svg'),
};

export type TamrIconName = keyof typeof TamrIconClasses;
export const TamrIconNames = Object.keys(TamrIconClasses) as TamrIconName[];

export default TamrIconClasses;
