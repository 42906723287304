import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Term from '../../components/Term';
import ProjectInfo from '../../models/ProjectInfo';
import { history } from '../../utils/History';
import { selectActiveProjectInfo } from '../../utils/Selectors';
import DashboardValueCard from '../DashboardValueCard';
import EmptyValueCard from '../EmptyValueCard';

const UnifiedDatasetCard = _.compose(
  connect((state) => {
    const { schemaMapping: { allUnifiedAttributes }, unifiedDatasets: { profiling } } = state;
    const { unifiedDataset } = selectActiveProjectInfo(state) || {};
    const { name } = unifiedDataset || {};
    const unifiedDatasetProfilingInfo = profiling.get(name);
    let unifiedDatasetIsProfiled = false;
    let numUnifiedRecords = 0;
    if (unifiedDatasetProfilingInfo) {
      unifiedDatasetIsProfiled = true;
      numUnifiedRecords = unifiedDatasetProfilingInfo.datasetRecordCount;
    }
    return { numUnifiedFields: allUnifiedAttributes.size, unifiedDatasetIsProfiled, numUnifiedRecords };
  }),
)(class UnifiedDatasetCard extends React.Component {
  static propTypes = {
    numUnifiedFields: PropTypes.number.isRequired,
    numUnifiedRecords: PropTypes.number.isRequired,
    projectInfo: PropTypes.instanceOf(ProjectInfo).isRequired,
    short: PropTypes.bool,
    smRecipeId: PropTypes.number.isRequired,
    unifiedDatasetIsProfiled: PropTypes.bool.isRequired,
  };

  static defaultProps = { short: false };

  navigateToSchemaMapping = () => {
    const { smRecipeId } = this.props;
    history.push(`/schema-mapping/recipe/${smRecipeId}`);
  };

  render() {
    const { numUnifiedFields, numUnifiedRecords, projectInfo, short, unifiedDatasetIsProfiled } = this.props;
    const title = 'UNIFIED DATASET';
    const unifiedDatasetDoc = projectInfo.unifiedDatasetDoc;
    if (!unifiedDatasetDoc) {
      return (
        <EmptyValueCard
          onClick={this.navigateToSchemaMapping}
          emptyMsg="Begin mapping attributes to create a unified dataset."
          title={title}
        />
      );
    }
    const message = unifiedDatasetIsProfiled ? null : <span>-- <Term>Records</Term> (Not yet profiled)</span>;
    const valuesList = Immutable.List.of(
      {
        value: numUnifiedFields,
        nounSingular: 'Unified Attribute',
        nounPlural: 'Unified Attributes',
      },
      {
        value: numUnifiedRecords,
        nounSingular: <Term>Record</Term>,
        nounPlural: <Term>Records</Term>,
        customMessage: message,
      },
    );
    return (
      <DashboardValueCard
        onClick={this.navigateToSchemaMapping}
        short={short}
        title={title}
        valuesList={valuesList}
      />
    );
  }
});

export default UnifiedDatasetCard;
