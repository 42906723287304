import classNames from 'classnames';
import { List, Set } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import Input from '../components/Input/Input';
import Selector from '../components/Selector';
import TamrIcon from '../components/TamrIcon';
import { ArgTypes, checkArg, checkReturn } from '../utils/ArgValidation';
import ColumnSelector from './ColumnSelector';
import { OperationListResult } from './models/StaticAnalysisModels';
import style from './Transform.module.scss';
import Col from './TransformColumn';
import TransformInput from './TransformInput';
import { editOperation } from './TransformsApi';
import Section from './TransformSection';
import { FILL, FORMULA, getIcon, MULTI_FORMULA, SCRIPT, UNPIVOT } from './TransformSelector';
import {
  getLatestForBulkTransform,
  getUnifiedScopedGuids,
  selectLints,
  selectValidationErrors,
  shouldShowBigEditor,
  transformIsAfterCutoff,
} from './TransformsStore';
import Summary from './TransformSummary';
import Val from './TransformValue';
import UnpivotDialog from './UnpivotDialog';
import ValidationError from './ValidationError';

// safely returns the { input, output } type of the specified column converted to strings
// if no type is found, the value is undefined
const getFieldTypeString = checkReturn(ArgTypes.object.withShape({
  input: ArgTypes.orUndefined(ArgTypes.string),
  output: ArgTypes.orUndefined(ArgTypes.string),
}), (staticAnalysisResult, column, operationIndex) => {
  checkArg({ staticAnalysisResult }, ArgTypes.orUndefined(ArgTypes.instanceOf(OperationListResult)));
  checkArg({ column }, ArgTypes.orUndefined(ArgTypes.string));
  checkArg({ operationIndex }, ArgTypes.orUndefined(ArgTypes.number));
  let fieldInputType;
  let fieldOutputType;
  if (column && staticAnalysisResult && _.isNumber(operationIndex)) {
    fieldInputType = staticAnalysisResult.getFieldType(operationIndex, 0, 0, column, false);
    fieldOutputType = staticAnalysisResult.getFieldType(operationIndex, 0, 0, column, true);
  }
  return { input: fieldInputType && fieldInputType.toString(), output: fieldOutputType && fieldOutputType.toString() };
});

const DropdownSection = ({ labelClassName, label, selector, erroring, errorMessage }) => {
  return (
    <div className={classNames(style.dropdownSection, { [style.erroring]: erroring })}>
      {label ? <label className={labelClassName}>{label}</label> : null}
      <div>
        {selector}
        {erroring ? <span className={style.errorMessage}>{errorMessage}</span> : null}
      </div>
    </div>
  );
};

const InputPlaceholder = ({ onCollapseEditor }) => {
  return (
    <div className={style.inputPlaceholder}>
      Viewing code in expanded editor.<Button className={style.collapseButton} buttonType="Link" onClick={onCollapseEditor}>Collapse</Button>
    </div>
  );
};

class Transform extends React.Component {
  visitFill = (fill) => {
    const { errors, selected, onEdit, isUnifiedTransform, staticAnalysisResult, operationIndex, modified, validationErrors, hasNotBeenClosedYet, isDisabled } = this.props;
    const { coalesce, column, value } = fill;
    if (!selected) {
      return (
        <Summary name={FILL} icon={getIcon(FILL)} {...{ errors, validationErrors, modified, isDisabled }}>
          <Col>{column}</Col> {coalesce ? 'empty' : 'all'} records with: <Val>{value}</Val>
        </Summary>
      );
    }
    const validationError = validationErrors.has(ValidationError.FILL_MISSING_ATTRIBUTE)
      ? ValidationError.FILL_MISSING_ATTRIBUTE
      : validationErrors.has(ValidationError.FILL_INVALID_ATTRIBUTE)
        ? ValidationError.FILL_INVALID_ATTRIBUTE
        : '';
    // retrieve the column's types and display them if defined
    const { input: fillFieldInputTypeString, output: fillFieldOutputTypeString } = getFieldTypeString(staticAnalysisResult, column, operationIndex);
    const outputTypeHTML = isUnifiedTransform
      ? (<div key="output-type">
        {fillFieldInputTypeString && fillFieldOutputTypeString
          ? 'Output attribute type: ' + fillFieldInputTypeString + ' → ' + fillFieldOutputTypeString
          : 'Output attribute type: No output attribute selected'}
      </div>)
      : <div />;
    return [
      <Summary key="summary" name={FILL} onChange={onEdit} isDisabled={isDisabled} />,
      <DropdownSection
        key="attribute"
        label="Attribute:"
        labelClassName={style.fillLabel}
        erroring={validationError && !hasNotBeenClosedYet}
        errorMessage={validationError}
        selector={(
          <ColumnSelector
            className={classNames(style.val, style.grow)}
            value={column}
            clearable={false}
            onChange={option => onEdit(fill.set('column', option.value))}
          />
        )}
      />,
      <Section key="coalesce">
        <label className={style.fillLabel}>Records:</label>
        <Selector
          className={style.val}
          value={coalesce}
          options={[
            { value: false, label: 'all' },
            { value: true, label: 'empty' },
          ]}
          onChange={option => onEdit(fill.set('coalesce', option.value))}
          clearable={false}
        />
      </Section>,
      <Section key="config">
        <Input
          title="Fill value"
          className={style.val}
          value={value}
          onChange={newValue => onEdit(fill.set('value', newValue))}
          invalid={Boolean(errors.size > 0).toString()}
        />
      </Section>,
      outputTypeHTML,
    ];
  };

  visitFormula = (formula) => {
    const { showingBigEditor, errors, staticAnalysisResult, operationIndex, isUnifiedTransform, guid, selected, onEdit, modified, validationErrors, onExpandEditor, onCollapseEditor, hasNotBeenClosedYet, isDisabled } = this.props;
    const { column } = formula;
    if (!selected) {
      return (
        <Summary name={FORMULA} icon={getIcon(FORMULA)} {...{ errors, validationErrors, modified, isDisabled }}>
          <div>{new List(formula.inputColumns.map(name => (<Col key={name}>{name}</Col>))).interpose(', ')}</div>
          <TransformInput
            onChange={undefined}
            guid={guid}
          />
          <div>output column: <Val>{column}</Val></div>
        </Summary>
      );
    }
    const validationError = validationErrors.has(ValidationError.FORMULA_MISSING_OUTPUT_ATTRIBUTE)
      ? ValidationError.FORMULA_MISSING_OUTPUT_ATTRIBUTE
      : validationErrors.has(ValidationError.FORMULA_INVALID_OUTPUT_ATTRIBUTE)
        ? ValidationError.FORMULA_INVALID_OUTPUT_ATTRIBUTE : undefined;
    // retrieve the column's types and display them if defined
    const { input: formulaFieldInputTypeString, output: formulaFieldOutputTypeString } = getFieldTypeString(staticAnalysisResult, column, operationIndex);
    const outputTypeHTML = isUnifiedTransform
      ? (<div key="output-type">
        {formulaFieldInputTypeString && formulaFieldOutputTypeString
          ? 'Output attribute type: ' + formulaFieldInputTypeString + ' → ' + formulaFieldOutputTypeString
          : 'Output attribute type: No output attribute selected'}
      </div>)
      : <div />;
    return [
      <div key="icon" className={style.headerContainer}>
        <Summary key="summary" name={FORMULA} onChange={onEdit} isDisabled={isDisabled} />
        {showingBigEditor ? null : (
          <TamrIcon
            key="icon"
            iconName="expand"
            className={style.expandButton}
            size={16}
            onClick={onExpandEditor}
          />
        )}
      </div>,
      <Section key="expression">
        {showingBigEditor ? <InputPlaceholder {...{ onCollapseEditor }} /> : (
          <TransformInput
            onChange={newExpr => onEdit(formula.set('expr', newExpr))}
            guid={guid}
          />
        )}
      </Section>,
      <DropdownSection
        key="output"
        label="Output attribute:"
        labelClassName={style.formulaLabel}
        erroring={validationError && !hasNotBeenClosedYet}
        errorMessage={validationError}
        selector={(
          <ColumnSelector
            className={classNames(style.val, style.grow)}
            value={column}
            clearable={false}
            onChange={({ value }) => onEdit(formula.set('column', value))}
          />
        )}
      />,
      outputTypeHTML,
    ];
  };

  visitMultiFormula = (multiFormula) => {
    const { showingBigEditor, errors, guid, selected, onEdit, modified, validationErrors, onExpandEditor, onCollapseEditor, hasNotBeenClosedYet, isDisabled } = this.props;
    const { targetColumns } = multiFormula;
    const targetColumnsList = targetColumns.join(', ');
    if (!selected) {
      return (
        <Summary name={MULTI_FORMULA} icon={getIcon(MULTI_FORMULA)} {...{ errors, validationErrors, modified, isDisabled }}>
          <Col>{targetColumnsList}</Col>
          <TransformInput
            onChange={undefined}
            guid={guid}
              />
        </Summary>
      );
    }
    const exprValidationError = validationErrors.has(ValidationError.MULTI_FORMULA_EMPTY_EXPRESSION)
      ? ValidationError.MULTI_FORMULA_EMPTY_EXPRESSION : undefined;
    const attributeValidationErrors = validationErrors.has(ValidationError.MULTI_FORMULA_EMPTY_ATTRIBUTES) && !hasNotBeenClosedYet
      ? ValidationError.MULTI_FORMULA_EMPTY_ATTRIBUTES
      : validationErrors.has(ValidationError.MULTI_FORMULA_INVALID_ATTRIBUTE_NAME)
        ? ValidationError.MULTI_FORMULA_INVALID_ATTRIBUTE_NAME : undefined;
    return [
      <div key="icon" className={style.headerContainer}>
        <Summary key="summary" name={MULTI_FORMULA} onChange={onEdit} isDisabled={isDisabled} />
        {showingBigEditor ? null : (
          <TamrIcon
            key="icon"
            iconName="expand"
            className={style.expandButton}
            size={16}
            onClick={onExpandEditor}
              />
        )}
      </div>,
      <div>Replace $COL with:</div>,
      <DropdownSection
        key="attributes"
        erroring={attributeValidationErrors}
        errorMessage={attributeValidationErrors}
        selector={(
          <ColumnSelector
            className={classNames(style.val, style.grow)}
            clearable={false}
            value={targetColumns}
            onChange={option => onEdit(multiFormula.set('targetColumns', option.map(o => o.value)))}
            multi
              />
            )}
          />,
      <Section key="expression">
        {showingBigEditor ? <InputPlaceholder {...{ onCollapseEditor }} /> : (
          <TransformInput
            onChange={newValue => onEdit(multiFormula.set('expr', newValue))}
            guid={guid}
            erroring={exprValidationError}
            errorMessage={exprValidationError}
              />
        )}
      </Section>,
      <div>AS $COL</div>,
    ];
  };

  visitScript = (script) => {
    const { errors, validationErrors, guid, selected, onEdit, modified, onExpandEditor, onCollapseEditor, showingBigEditor, isDisabled } = this.props;
    if (!selected) {
      return (
        <Summary name={SCRIPT} icon={getIcon(SCRIPT)} {...{ errors, validationErrors, modified, isDisabled }}>
          <TransformInput
            onChange={undefined}
            guid={guid}
          />
        </Summary>
      );
    }
    return [
      <div key="icon" className={style.headerContainer}>
        <Summary key="summary" name={SCRIPT} onChange={onEdit} isDisabled={isDisabled} />
        {showingBigEditor ? null : (
          <TamrIcon
            key="icon"
            iconName="expand"
            className={style.expandButton}
            size={16}
            onClick={onExpandEditor}
          />
        )}
      </div>,
      <Section key="body">
        {showingBigEditor ? <InputPlaceholder {...{ onCollapseEditor }} /> : (
          <TransformInput
            onChange={newValue => onEdit(script.set('op', newValue))}
            guid={guid}
          />
        )}
      </Section>,
    ];
  };

  visitUnpivot = (unpivot) => {
    const { selected, onEdit, errors, staticAnalysisResult, isUnifiedTransform, operationIndex, modified, validationErrors, hasNotBeenClosedYet, isDisabled } = this.props;
    const { unpivotColumns, variableColumn, valueColumn, dependentColumns } = unpivot;
    const unpivotColumnsList = unpivotColumns.join(', ');
    if (!selected) {
      return (
        <Summary name={UNPIVOT} icon={getIcon(UNPIVOT)} {...{ errors, validationErrors, modified, isDisabled }}>
          <Col>{unpivotColumnsList}</Col>
          <div>
            Variable attribute: <Val>{variableColumn}</Val>
          </div>
          <div>
            Value attribute: <Val>{valueColumn}</Val>
          </div>
          {!_.isEmpty(dependentColumns) ?
            (<div>
              Dependent attribute(s): <Val>{_.uniq(_.compact(dependentColumns)).join(', ')}</Val>
            </div>)
            : null}
        </Summary>
      );
    }
    const unpivotValidationError = validationErrors.has(ValidationError.UNPIVOT_MISSING_UNPIVOT_ATTRIBUTES)
      ? ValidationError.UNPIVOT_MISSING_UNPIVOT_ATTRIBUTES
      : validationErrors.has(ValidationError.UNPIVOT_INVALID_UNPIVOT_ATTRIBUTES)
        ? ValidationError.UNPIVOT_INVALID_UNPIVOT_ATTRIBUTES
        : '';
    const variableValidationError = validationErrors.has(ValidationError.UNPIVOT_MISSING_VARIABLE_ATTRIBUTE)
      ? ValidationError.UNPIVOT_MISSING_VARIABLE_ATTRIBUTE
      : validationErrors.has(ValidationError.UNPIVOT_INVALID_VARIABLE_ATTRIBUTE)
        ? ValidationError.UNPIVOT_INVALID_VARIABLE_ATTRIBUTE
        : '';
    const valueValidationError = validationErrors.has(ValidationError.UNPIVOT_MISSING_VALUE_ATTRIBUTE)
      ? ValidationError.UNPIVOT_MISSING_VALUE_ATTRIBUTE
      : validationErrors.has(ValidationError.UNPIVOT_INVALID_VALUE_ATTRIBUTE)
        ? ValidationError.UNPIVOT_INVALID_VALUE_ATTRIBUTE
        : '';
    const dependentValidationError = validationErrors.has(ValidationError.UNPIVOT_INVALID_DEPENDENT_COLUMNS)
      ? ValidationError.UNPIVOT_INVALID_DEPENDENT_COLUMNS
      : '';
    // retrieve the columns' types and display them if defined
    const { input: variableFieldInputTypeString, output: variableFieldOutputTypeString } = getFieldTypeString(staticAnalysisResult, variableColumn, operationIndex);
    const variableOutputTypeHTML = isUnifiedTransform
      ? (<div key="variable-output-type" className={style.variableOutputType}>
        {variableFieldInputTypeString && variableFieldOutputTypeString
          && 'Variable attribute type: ' + variableFieldInputTypeString + ' → ' + variableFieldOutputTypeString}
      </div>)
      : <div />;
    const { input: valueFieldInputTypeString, output: valueFieldOutputTypeString } = getFieldTypeString(staticAnalysisResult, valueColumn, operationIndex);
    const valueOutputTypeHTML = isUnifiedTransform
      ? (<div key="value-output-type">
        {valueFieldInputTypeString && valueFieldOutputTypeString
          && 'Value attribute type: ' + valueFieldInputTypeString + ' → ' + valueFieldOutputTypeString}
      </div>)
      : <div />;
    return [
      <Summary key="summary" name={UNPIVOT} onChange={onEdit} isDisabled={isDisabled} />,
      <DropdownSection
        key="attributes"
        erroring={unpivotValidationError && !hasNotBeenClosedYet}
        errorMessage={unpivotValidationError}
        selector={(
          <ColumnSelector
            className={classNames(style.val, style.grow)}
            clearable={false}
            value={unpivotColumns}
            onChange={option => onEdit(unpivot.set('unpivotColumns', option.map(o => o.value)))}
            multi
          />
        )}
      />,
      <DropdownSection
        key="variable"
        label="Variable attribute:"
        labelClassName={style.unpivotLabel}
        erroring={variableValidationError && !hasNotBeenClosedYet}
        errorMessage={variableValidationError}
        selector={(
          <ColumnSelector
            className={classNames(style.val, style.grow)}
            value={variableColumn}
            clearable={false}
            onChange={option => onEdit(unpivot.set('variableColumn', option.value))}
          />
        )}
      />,
      variableOutputTypeHTML,
      <DropdownSection
        key="value"
        label="Value attribute:"
        labelClassName={style.unpivotLabel}
        erroring={valueValidationError && !hasNotBeenClosedYet}
        errorMessage={valueValidationError}
        selector={(
          <ColumnSelector
            className={classNames(style.val, style.grow)}
            value={valueColumn}
            clearable={false}
            onChange={option => onEdit(unpivot.set('valueColumn', option.value))}
          />
        )}
      />,
      valueOutputTypeHTML,
      <UnpivotDialog
        key="dialog"
        dependentColumns={unpivot.get('dependentColumns')}
        dependentColumnValues={unpivot.get('dependentColumnValues')}
        onEdit={onEdit}
        unpivot={unpivot}
        unpivotColumns={unpivot.get('unpivotColumns')}
        valueColumn={unpivot.get('valueColumn')}
        variableColumn={unpivot.get('variableColumn')}
        className={style.dependentColumnDialog}
        erroring={dependentValidationError && !hasNotBeenClosedYet}
        errorMessage={dependentValidationError}
      />,
    ];
  };

  render() {
    const { transform, isDisabled } = this.props;
    return (
      <div className={classNames({ [style.isDisabled]: isDisabled })}>
        {transform.accept(this)}
      </div>
    );
  }
}

const ConnectedTransform = connect((state, { guid }) => {
  const { transforms: transformsState, transforms: { deltas, selected, hasNotBeenClosedYet, staticAnalysisResult } } = state;
  const unifiedGuidIndexes = getUnifiedScopedGuids(transformsState).toMap().flip();
  const operationIndex = unifiedGuidIndexes.get(guid);
  const isUnifiedTransform = unifiedGuidIndexes.has(guid);
  return {
    transform: getLatestForBulkTransform(transformsState, guid).operation,
    staticAnalysisResult,
    operationIndex,
    isUnifiedTransform,
    errors: selectLints(transformsState).get(guid),
    validationErrors: selectValidationErrors(state).get(guid) || Set(),
    modified: deltas.map(delta => delta.guid).toSet().has(guid),
    selected: guid === selected,
    hasNotBeenClosedYet: hasNotBeenClosedYet === guid,
    isDisabled: transformIsAfterCutoff(state.get('transforms'), guid),
    showingBigEditor: shouldShowBigEditor(state.get('transforms')),
  };
}, (dispatch, { guid }) => {
  return {
    onEdit: (operation) => dispatch(editOperation({ guid, operation })),
    onExpandEditor: () => dispatch({ type: 'Transforms.setBigEditor', enabled: true }),
    onCollapseEditor: () => dispatch({ type: 'Transforms.setBigEditor', enabled: false }),
  };
})(Transform);

ConnectedTransform.propTypes = {
  guid: PropTypes.string.isRequired,
};

export default ConnectedTransform;
