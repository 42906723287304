import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import { getDisplaySimilarity } from '../models/MappingRecommendation';
import style from './ConfirmTiedRecsDialog.module.scss';
import { SET_SOURCE_ATTRIBUTES_WITH_TIEDS_RECS } from './SchemaMappingActionTypes';
import { getSortedRecs, getTiedRecs } from './SchemaMappingStore';

const ConfirmTiedRecsDialog = _.compose(
  connect(({ schemaMapping }) => {
    const { sourceAttributesWithTiedRecs } = schemaMapping;
    const showDialog = !sourceAttributesWithTiedRecs.isEmpty();
    return { sourceAttributesWithTiedRecs, showDialog };
  }, {
    onClearDialog: () => ({ type: SET_SOURCE_ATTRIBUTES_WITH_TIEDS_RECS, sourceAttributes: new List() }),
  }),
)(({ showDialog, onClearDialog, sourceAttributesWithTiedRecs }) => {
  const numTied = sourceAttributesWithTiedRecs.size;
  return (
    <Dialog
      className={style.dialog}
      show={showDialog}
      onHide={onClearDialog}
      body={
        <div>
          <div className={style.description1}>
            {numTied} of the input attributes you selected could not be mapped to the top suggestion because there are two or more suggestions of equal similarity.
          </div>
          <div className={style.tableContainer}>
            <div className={style.tableContainer}>
              <table className={style.table}>
                <tbody>
                  <tr className={style.header}>
                    <th className={style.source}>Input Attribute</th>
                    <th className={style.target}>Top Suggestions</th>
                  </tr>
                  {sourceAttributesWithTiedRecs.map(sa => (
                    <tr key={`source-attr-${sa.name}-${sa.datasetName}`} className={style.tableBody}>
                      <td className={style.source}>{sa.name} <span className={style.sourceDataset}>from {sa.datasetName}</span></td>
                      <td className={style.target}>
                        {
                          getTiedRecs(getSortedRecs(sa)).map(rec => (
                            <div key={`rec-${sa.name}-${sa.datasetName}-${rec.recommended.name}`}>
                              {rec.recommended.name} <span className={style.similarity}>{getDisplaySimilarity(rec.similarity)}%</span>
                            </div>
                          ))
                        }
                      </td>
                    </tr>
                  )).toList()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      }
      footer={
        <ButtonToolbar>
          <Button buttonType="Primary" onClick={onClearDialog}>Okay</Button>
        </ButtonToolbar>
      }
      title="Top Suggestion Ties"
      dialogStyle={DialogStyle.PRIMARY}
      closeButton
    />
  );
});

export default ConfirmTiedRecsDialog;
