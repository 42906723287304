import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import ConditionalButton from '../components/ConditionalButton';
import { AppState } from '../stores/MainStore';
import { saveAllRules } from './GoldenRecordsAsync';
import { selectNumberOfChanges, selectSavable } from './GoldenRecordsSelectors';
import style from './GoldenRecordsSidebar.module.scss';


const SaveGoldenRecordsRulesButton = connect((state: AppState) => {
  const { goldenRecords: { savingRules } } = state;
  return {
    numChanges: selectNumberOfChanges(state),
    savable: selectSavable(state),
    savingRules,
  };
}, {
  onSaveAllRules: saveAllRules,
})(({ numChanges, savable, onSaveAllRules, savingRules }) => {
  return (
    <ConditionalButton
      className={style.saveChangesButton}
      onClick={onSaveAllRules}
      preconditions={Map<string, boolean>()
        .set('Some rules have errors', numChanges === 0 || savable)
        .set('No changes to save', numChanges > 0 && !savingRules)}
    >
      Save changes
      {numChanges > 0 ? (
        <div className={style.buttonBadge}>
          {numChanges}
        </div>
      ) : null}
    </ConditionalButton>
  );
});

export default SaveGoldenRecordsRulesButton;
