import enumify from '../../constants/enumify';

// com.tamr.workflow.specs.TokenizerComponentSpec.Config

export enum TokenizerConfigE {
  DEFAULT = 'DEFAULT',
  STEMMING_EN = 'STEMMING_EN',
  BIGRAM = 'BIGRAM',
  TRIGRAM = 'TRIGRAM',
  REGEX = 'REGEX',
  BIWORD = 'BIWORD',
}

export const { DEFAULT, STEMMING_EN, BIGRAM, TRIGRAM, REGEX, BIWORD } = TokenizerConfigE;

export default enumify(TokenizerConfigE);
