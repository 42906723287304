import React from 'react';
import { connect } from 'react-redux';

import FilterButton from '../components/FilterButton';
import { AppState } from '../stores/MainStore';
import { CLEAR_ALL_FILTERS, TOGGLE_FILTER_PANEL } from './GoldenRecordsActionTypes';
import { selectAreFiltersEnabled } from './GoldenRecordsStore';


const GoldenRecordsFilterPanelButton = connect((state: AppState) => {
  const { goldenRecords: { showFilterPanel }, goldenRecords } = state;
  const hasFilters = selectAreFiltersEnabled(goldenRecords);
  return { showFilterPanel, hasFilters };
}, {
  onToggle: () => ({ type: TOGGLE_FILTER_PANEL }),
  onClear: () => ({ type: CLEAR_ALL_FILTERS }),
})(({ showFilterPanel, hasFilters, onToggle, onClear }) => {
  return (
    <FilterButton
      onClick={onToggle}
      hasFilters={hasFilters}
      filterExpanded={showFilterPanel}
      onClear={hasFilters ? onClear : undefined}
    />
  );
});

export default GoldenRecordsFilterPanelButton;
