import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '../components/Button';
import TooltipTrigger from '../components/TooltipTrigger';
import AppState from '../stores/AppState';
import { PaneE } from './Pane';


const SupplierSelectorPagerContent = connect((state: AppState, { pane }: { pane: PaneE }) => {
  const { suppliers: { [pane]: { data, lastFetchData } } } = state;
  return { anyChange: !data.equals(lastFetchData) };
}, (dispatch, { pane }) => bindActionCreators({
  onReload: () => ({ type: 'Suppliers.reload', pane }),
}, dispatch),
)(({ anyChange, onReload }) => {
  if (!anyChange) {
    return <noscript />;
  }
  return (
    <TooltipTrigger placement="top" content={<span>Reload page to remove clusters no longer matching your filters</span>}>
      <Button buttonType="Secondary" onClick={onReload}>
        Reload
      </Button>
    </TooltipTrigger>
  );
});

export default SupplierSelectorPagerContent;
