import classNames from 'classnames';
import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import _ from 'underscore';

import BulletedTooltipTrigger from '../components/BulletedTooltipTrigger';
import ColumnWidthProvider from '../components/ColumnWidthProvider';
import Highlighter from '../components/Highlighter';
import LoadingPanel from '../components/LoadingPanel';
import SearchBox from '../components/SearchBox';
import SortableHeaderCell from '../components/SortableHeaderCell';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Pager from '../components/Table/PagerModel';
import Table from '../components/Table/Table';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import TooltipTrigger from '../components/TooltipTrigger';
import CategoryDashboardCategoryView from '../constants/CategoryDashboardCategoryView';
import CategoryDashboardTierSelector from '../constants/CategoryDashboardTierSelector';
import SortState from '../constants/SortState';
import VerifiedIcon from '../pairs/VerifiedIcon';
import CategoryTree from '../taxonomy/CategoryTree';
import { history } from '../utils/History';
import { commafy } from '../utils/Numbers';
import PRODUCT_NAME from '../utils/ProductName';
import { getActiveRecipeDoc, selectActiveProjectInfo } from '../utils/Selectors';
import SortUtils from '../utils/SortUtils';
import { pluralize } from '../utils/Strings';
import { noop } from '../utils/Values';
import style from './CategorizationDashboardCategoriesCard.module.scss';
import CategorizationDashboardCategoriesTierSelector from './CategorizationDashboardCategoriesTierSelector';

const PAGE_SIZE = 10;
const AVERAGE_CONFIDENCES_ERROR = `${PRODUCT_NAME} cannot find average record confidences for each of the categories. You may need to update categorizations`;
const NO_CONFIDENCE = 'There are no records categorized to this category';

const navigate = (url, onResetTransactionsFilterState) => {
  if (_.isFunction(onResetTransactionsFilterState)) {
    onResetTransactionsFilterState();
  }
  history.push(url);
};

@connect(state => {
  const { categorizationDashboard: { taxonomy, selectedTier, categoryPage, categorySort, categorySearch, selectedCategoryView, loadingTaxonomy, averageConfidences, averageConfidencesError, loadingAverageConfidences } } = state;
  const categorizationRecipeId = getActiveRecipeDoc(state).id.id;
  if (!taxonomy || loadingTaxonomy) {
    return { recipeId: categorizationRecipeId, loadingTaxonomy };
  }
  const projectInfo = selectActiveProjectInfo(state);
  const hasSpend = !!(projectInfo.unifiedDataset ? projectInfo.unifiedDataset.metadata.get('spendField') : false);
  let tierCategories;
  if (selectedTier === CategoryDashboardTierSelector.LEAF_NODES_ONLY) {
    const parentNodes = taxonomy.categories.groupBy(category => category.path.slice(0, -1)).keySeq().toSet();
    tierCategories = taxonomy.categories.filter(category => !parentNodes.has(category.path)).toList();
  } else {
    tierCategories = taxonomy.categories.filter(c => c.path.size === selectedTier).toList();
  }
  const sortComparator = SortUtils.comparator(categorySort.order);
  const filteredTierCategories = tierCategories
    .filter(value => (categorySearch ? value.path.last().toLowerCase().indexOf(categorySearch.toLowerCase()) > -1 : true))
    .sortBy(value => (value[categorySort.field] || averageConfidences.get(value.path)?.averageConfidence || -1), sortComparator);

  const visibleTierCategories = filteredTierCategories
    .slice(categoryPage * PAGE_SIZE, (categoryPage + 1) * PAGE_SIZE);

  const usedTierCategoryCount = tierCategories.filter(c => c.manualCategorizations.count > 0).size;
  return { recipeId: categorizationRecipeId, taxonomy, tierCategories, filteredTierCategoriesSize: filteredTierCategories.size, visibleTierCategories, selectedTier, selectedCategoryView, usedTierCategoryCount, categoryPage, categorySort, categorySearch, hasSpend, loadingTaxonomy, averageConfidences, averageConfidencesError, loadingAverageConfidences };
}, {
  onResetTransactionsFilterState: () => ({ type: 'Transactions.resetFilters' }),
  onChangeCategoryPage: categoryPage => ({ type: 'CategorizationDashboard.setCategoryPage', categoryPage }),
  onChangeCategorySort: field => ({ type: 'CategorizationDashboard.progressCategorySort', field }),
  onChangeCategorySearch: categorySearch => ({ type: 'CategorizationDashboard.setCategorySearch', categorySearch }),
})
export default class CategorizationDashboardCategoriesCard extends React.Component {
  renderNumberColumn = ({ visibleTierCategories, field, columnHeader, onChangeCategorySort, categorySort, linkProvider, onResetTransactionsFilterState, hasSpend, showVerifiedIcon }) => {
    const sortState = categorySort.field === field ? categorySort.order : SortState.UNSORTED;
    const verifiedIcon = showVerifiedIcon
      ? (
        <TooltipTrigger content={<span>{PRODUCT_NAME} only learns from responses that have been verified by curators or admins. These help {PRODUCT_NAME} suggest better categorizations for <Term>records</Term>.</span>}>
          <VerifiedIcon className={style.verifiedIcon} size={12} />
        </TooltipTrigger>
      ) : undefined;
    const header = <SortableHeaderCell className={classNames(style.headerCell, style.numberHeader)} col={field} display={(<span>{verifiedIcon}{columnHeader}</span>)} sortCallback={onChangeCategorySort} sortState={sortState} />;
    const currencyChar = hasSpend ? <Term>currencyChar</Term> : undefined;
    const cell = ({ rowIndex }) => {
      const category = visibleTierCategories.get(rowIndex);
      const renderedNumber = <span title={commafy(category[field])}>{currencyChar}{commafy(category[field])}</span>;
      const link = _.isFunction(linkProvider) ? linkProvider(category) : undefined;
      return <Cell>{link ? <a onClick={() => navigate(link, onResetTransactionsFilterState)}>{renderedNumber}</a> : renderedNumber}</Cell>;
    };
    return (
      <Column columnKey={field} key={field} width={110} {...{ header, cell }} />
    );
  };

  renderNumberColumns = () => {
    const { selectedCategoryView, visibleTierCategories, recipeId, onChangeCategorySort, categorySort, hasSpend, onResetTransactionsFilterState, averageConfidences, averageConfidencesError } = this.props;
    let numberColumns;
    let linkProvider;
    switch (selectedCategoryView) {
      case CategoryDashboardCategoryView.TOTALS:
        linkProvider = category => `/spend/recipe/${recipeId}?categoryIds=${category.categoryId}`;
        numberColumns = [
          <Column
            columnKey="averageConfidence"
            key="averageConfidence"
            width={130}
            header={(
              <SortableHeaderCell
                className={classNames(style.headerCell, style.numberHeader)}
                col="averageConfidence"
                display={<BulletedTooltipTrigger placement="top" items={averageConfidencesError ? List.of(<span>{AVERAGE_CONFIDENCES_ERROR}</span>) : List()}><span>{averageConfidencesError ? <TamrIcon className={style.warningIcon} iconName="tamr-icon-warning" size={12} /> : null} Avg. record conf.</span></BulletedTooltipTrigger>}
                sortState={categorySort.field === 'averageConfidence' ? categorySort.order : SortState.UNSORTED}
                sortCallback={onChangeCategorySort}
              />
            )}
            cell={({ rowIndex }) => {
              const category = visibleTierCategories.get(rowIndex);
              const averageConfidence = averageConfidences.get(category.path)?.averageConfidence;
              return averageConfidencesError ? <Cell /> : (
                <Cell><a onClick={() => navigate(linkProvider(category), onResetTransactionsFilterState)}>{averageConfidence ? (averageConfidence * 100).toFixed(0) + '%' : <TooltipTrigger placement="top" content={<span>{NO_CONFIDENCE}</span>}><span>--</span></TooltipTrigger>}</a></Cell>
              );
            }}
          />,
          this.renderNumberColumn({ visibleTierCategories, field: 'totalLabeledCount', columnHeader: 'Total records', onChangeCategorySort, categorySort, linkProvider, showVerifiedIcon: false }),
          hasSpend ? this.renderNumberColumn({ visibleTierCategories, field: 'totalLabeledSpend', columnHeader: <span>Total <Term>spend</Term></span>, onChangeCategorySort, categorySort, linkProvider, onResetTransactionsFilterState, hasSpend: true, showVerifiedIcon: false }) : undefined,
        ];
        break;
      case CategoryDashboardCategoryView.VERIFIED:
        linkProvider = category => `/spend/recipe/${recipeId}?categoryIds=${category.categoryId}&labeledByUser=true&unlabeledByTamr=true&labelAgreesWithTamr=true&labelDisagreesWithTamr=true`;
        numberColumns = [
          this.renderNumberColumn({ visibleTierCategories, field: 'manualLabeledCount', columnHeader: 'Verified records', onChangeCategorySort, categorySort, linkProvider, onResetTransactionsFilterState, showVerifiedIcon: true }),
          hasSpend ? this.renderNumberColumn({ visibleTierCategories, field: 'manualLabeledSpend', columnHeader: <span>Verified <Term>spend</Term></span>, onChangeCategorySort, categorySort, linkProvider, onResetTransactionsFilterState, hasSpend: true, showVerifiedIcon: true }) : undefined,
        ];
        break;
      default:
        throw Error(`Unrecognized view selection: ${selectedCategoryView}`);
    }
    return numberColumns;
  };

  renderCategoryColumn = () => {
    const { taxonomy, visibleTierCategories, onChangeCategorySort, categorySort, categorySearch, recipeId, selectedTier, onResetTransactionsFilterState } = this.props;
    const sortState = categorySort.field === 'name' ? categorySort.order : SortState.UNSORTED;
    const header = <SortableHeaderCell className={style.headerCell} col="name" display="Category" sortCallback={onChangeCategorySort} sortState={sortState}>Reviewer</SortableHeaderCell>;
    const cell = ({ rowIndex }) => {
      const category = visibleTierCategories.get(rowIndex);
      const clickableCategory = (
        <a className={style.categoryName} onClick={() => navigate(`/category/recipe/${recipeId}/${category.categoryId}`, onResetTransactionsFilterState)}>
          <Highlighter
            fullText={category.name}
            highlightText={categorySearch}
          />
        </a>
      );
      const renderedCategory = selectedTier === 1
        ? clickableCategory
        : (
          <div className={style.deeperCategory}>
            <div className={style.parentCategories}>
              {category.path.slice(0, -1).map(entry => `${entry} > `)}
            </div>
            {clickableCategory}
          </div>
        );
      const renderedCategoryWithTooltip = (
        <TooltipTrigger
          className={style.categoryTooltip}
          placement="top"
          content={(
            <div className="category-tree-container">
              <CategoryTree {...{ taxonomy, category }} />
            </div>
          )}
        >
          <div className={style.categoryNameWrapper}>
            {renderedCategory}
          </div>
        </TooltipTrigger>
      );
      return <Cell><div className={style.tooltipWrapper}>{renderedCategoryWithTooltip}</div></Cell>;
    };
    return (
      <Column columnKey="username" key="username" width={100} flexGrow={1} {...{ header, cell }} />
    );
  };

  render() {
    const { taxonomy, tierCategories, filteredTierCategoriesSize, visibleTierCategories, usedTierCategoryCount, categoryPage, loadingTaxonomy, onChangeCategoryPage, selectedTier, loadingAverageConfidences, categorySearch, onChangeCategorySearch } = this.props;
    return (loadingTaxonomy || !taxonomy || loadingAverageConfidences) ? <LoadingPanel /> : (
      <div>
        <div className={style.titleContainer}>
          <div className={style.titleSection}>
            <div className={style.title}>{commafy(usedTierCategoryCount)} {selectedTier > 0 ? `Tier-${selectedTier}` : 'Leaf'} {pluralize(usedTierCategoryCount, 'Category', 'Categories')} Used <TooltipTrigger placement="top" content={<span>These categories include those with records categorized into them. If this number does not match the total number below, there are categories from your taxonomy that have no <Term>records</Term> categorized into them.</span>}><span className={style.infoIcon}><TamrIcon iconName="info-outline" size={12} /></span></TooltipTrigger></div>
            <div className={style.subtitle}>{commafy(tierCategories.size)} total {selectedTier > 0 ? `Tier ${selectedTier}` : 'Leaf'} {pluralize(tierCategories.size, 'Category', 'Categories')}</div>
          </div>
          <div className={style.searchSection}>
            <SearchBox
              onSearch={onChangeCategorySearch}
              value={categorySearch}
            />
          </div>
          <div className={style.viewSelectorContainer}>
            <CategorizationDashboardCategoriesTierSelector />
          </div>
        </div>
        <div className={style.tableContainer}>
          {filteredTierCategoriesSize === 0 && categorySearch
            ? (
              <div className={style.center}>
                <div>No categories matching your filter</div>
              </div>
            )
            : (
              <AutoSizer>
                {({ width, height }) => (
                  <div>
                    <ColumnWidthProvider>
                      <Table
                        {...{ width, height }}
                        getLength={() => visibleTierCategories.size}
                        tableType="stripes"
                        rowHeight={40}
                        onPageChange={onChangeCategoryPage}
                        onPageSizeChange={noop}
                        pageSizes={List.of(PAGE_SIZE)}
                        pagerHeight={45}
                        pagerState={new Pager(visibleTierCategories, filteredTierCategoriesSize, categoryPage, PAGE_SIZE, true)}
                      >
                        <Column key="start" width={18} />
                        {this.renderCategoryColumn()}
                        {this.renderNumberColumns()}
                        <Column key="end" width={18} />
                      </Table>
                    </ColumnWidthProvider>
                    <div className={style.bottomShadowContainer}>
                      <div className={style.bottomShadow} />
                    </div>
                  </div>
                )}
              </AutoSizer>
            )}
        </div>
      </div>
    );
  }
}
