import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import PageHeader from '../chrome/PageHeader';
import Button from '../components/Button';
import LoadingPanel from '../components/LoadingPanel';
import PopoverTrigger from '../components/PopoverTrigger';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import { AppDispatch, AppState } from '../stores/MainStore';
import { commafy } from '../utils/Numbers';
import { getActiveSpendField } from '../utils/Selectors';
import AssignClustersDialog from './AssignClustersDialog';
import ClusterFilters from './ClusterFilters';
import style from './ClusterRecordsHeader.module.scss';
import ClusterSelectorTable from './ClusterSelectorTable';
import ClustersSort, { ClustersSortE } from './ClustersSort';
import ConfirmVerificationActionDialog from './ConfirmVerificationActionDialog';
import { PaneE } from './Pane';
import SupplierActionMenu from './SupplierActionMenu';
import { CLEAR_FILTERS } from './SuppliersActionTypes';
import SuppliersLoader from './SuppliersLoader';
import { getAnyClusterFilters } from './SuppliersStore';
import SuppliersUpdateLoader from './SuppliersUpdateLoader';


const { NAME, NAME_REVERSE, RECORDS_LEAST_FIRST, RECORDS_MOST_FIRST, SPEND_HIGHEST_FIRST, SPEND_LOWEST_FIRST } = ClustersSort;

type CheckableOptionOwnProps = {
  onClick: () => void
  pane: PaneE
  value: ClustersSortE
}

const checkableOptionMapStateToProps = (state: AppState, { onClick, pane, value }: CheckableOptionOwnProps) => {
  const { suppliers: { [pane]: { clustersSort } } } = state;
  return { clustersSort, onClick, value };
};

const checkableOptionMapDispatchToProps = (dispatch: AppDispatch, { pane }: CheckableOptionOwnProps) => ({
  onSetSort: (clustersSort: ClustersSortE) => dispatch({ type: 'Suppliers.setClustersSort', clustersSort, pane }),
});

type CheckableOptionProps
  = CheckableOptionOwnProps
  & ReturnType<typeof checkableOptionMapStateToProps>
  & ReturnType<typeof checkableOptionMapDispatchToProps>

const CheckableOption = connect(
  checkableOptionMapStateToProps,
  checkableOptionMapDispatchToProps,
)(class CheckableOption extends React.Component<CheckableOptionProps> {
  getDisplayValue() {
    const { value } = this.props;
    switch (value) {
      case NAME:
        return 'Name (a - z)';
      case NAME_REVERSE:
        return 'Name (z - a)';
      case RECORDS_MOST_FIRST:
        return 'Records (high - low)';
      case RECORDS_LEAST_FIRST:
        return 'Records (low - high)';
      case SPEND_HIGHEST_FIRST:
        return <span><Term>Spend</Term> (high - low)</span>;
      case SPEND_LOWEST_FIRST:
        return <span><Term>Spend</Term> (low - high)</span>;
      default:
        return '';
    }
  }

  render() {
    const { clustersSort, onClick, onSetSort, value } = this.props;
    return (
      <span className={classNames(style.checkable, { selected: clustersSort === value })} onClick={() => { onSetSort(value); onClick(); }}>
        {clustersSort === value ? <TamrIcon iconName="tamr-icon-checkbox-check" size={18} className={style.checkableCheck} /> : null}
        {this.getDisplayValue()}
      </span>
    );
  }
});

type SupplierSelectorOwnProps = {
  pane: PaneE
}

const mapStateToProps = (state: AppState, { pane }: SupplierSelectorOwnProps) => {
  const { suppliers: { [pane]: { clustersSort, totalSuppliers, total, queryString, loading, saving, suppliersFilter } } } = state;
  return {
    totalSuppliers,
    total,
    queryString,
    busy: loading || saving,
    anyFilters: getAnyClusterFilters(state, pane),
    suppliersFilter,
    clustersSort,
    isSpendConfigured: !!getActiveSpendField(state),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch, { pane }: SupplierSelectorOwnProps) => ({
  onClearFilters: () => dispatch({ type: CLEAR_FILTERS, pane }),
  onSetSort: (clustersSort: ClustersSortE) => dispatch({ type: 'Suppliers.setClustersSort', clustersSort, pane }),
});

type SupplierSelectorProps
  = SupplierSelectorOwnProps
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>

/**
 * Table that displays cluster name, record count and spend for a cluster of
 * suppliers. Is wired to the supplier store, which controls the cluster that
 * shows in the right side transaction table.
 */
const SupplierSelector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(class SupplierSelector extends React.Component<SupplierSelectorProps> {
  renderButtonToolbar = () => {
    const { pane, isSpendConfigured } = this.props;
    const hideClusterSorter = () =>
      // @ts-expect-error
      this.refs.clusterSorter.hide();
    return (
      <div className={classNames(style.clustersToolbar, 'selector-toolbar')}>
        <div className="selector-toolbar-container">
          <ClusterFilters pane={pane} />
          <SupplierActionMenu pane={pane} />
          <PopoverTrigger
            ref="clusterSorter"
            className={style.clustersSort}
            placement="bottom"
            content={
              <div className={style.clustersSortOptions}>
                <CheckableOption onClick={hideClusterSorter} pane={pane} value={NAME} />
                <CheckableOption onClick={hideClusterSorter} pane={pane} value={NAME_REVERSE} />
                <CheckableOption onClick={hideClusterSorter} pane={pane} value={RECORDS_MOST_FIRST} />
                <CheckableOption onClick={hideClusterSorter} pane={pane} value={RECORDS_LEAST_FIRST} />
                {isSpendConfigured ? <CheckableOption onClick={hideClusterSorter} pane={pane} value={SPEND_HIGHEST_FIRST} /> : null}
                {isSpendConfigured ? <CheckableOption onClick={hideClusterSorter} pane={pane} value={SPEND_LOWEST_FIRST} /> : null}
              </div>
            }
          >
            <Button buttonType="Secondary">
              <span className="supplier-action-button-wrapper">
                <TamrIcon iconName="tamr-icon-checkbox-check" size={18} className={style.clustersSortCheck} />
                <span>Sort</span>
                <TamrIcon iconName="arrow-drop-down" size={18} className={style.clustersSortArrow} />
              </span>
            </Button>
          </PopoverTrigger>
        </div>
      </div>
    );
  };

  renderTitle = () => {
    const { totalSuppliers, total, queryString } = this.props;
    const countModifier = (totalSuppliers === total) ? '' : ` of ${commafy(totalSuppliers)}`;
    const title = (
      <span>
        {queryString ? 'Search results' : 'Clusters'}
        <span className="page-header-metrics"> {commafy(total)}{countModifier}</span>
      </span>
    );
    return (
      <PageHeader title={title} />
    );
  };

  renderLoading = () => {
    const { busy } = this.props;
    return busy ? <LoadingPanel /> : null;
  };

  renderNoSuppliers = () => {
    const { anyFilters, queryString, suppliersFilter, onClearFilters } = this.props;
    return (
      <div className="supplier-selector-table">
        <div className="supplier-clear-msg">
          <div>No clusters to show</div>
          {anyFilters || queryString || suppliersFilter ?
            <div>
              <a className="clear-supplier-filter" onClick={onClearFilters}>Clear search and cluster filters</a>
            </div>
            : null}
        </div>
      </div>
    );
  };

  render() {
    const { total, pane } = this.props;
    const emptySuppliers = total === 0;
    return (
      <div className="supplier-selector">
        <SuppliersLoader pane={pane} />
        <SuppliersUpdateLoader pane={pane} />
        {this.renderLoading()}
        <AssignClustersDialog pane={pane} />
        <ConfirmVerificationActionDialog pane={pane} />
        {this.renderTitle()}
        {this.renderButtonToolbar()}
        {emptySuppliers ? this.renderNoSuppliers() : <ClusterSelectorTable pane={pane} />}
      </div>
    );
  }
});

export default SupplierSelector;
