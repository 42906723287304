import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { DropTarget as dropTarget } from 'react-dnd';
import { connect } from 'react-redux';
import _ from 'underscore';

import style from './TransformDropTarget.module.scss';

/**
 * Drop target for reordering transformations in the sidebar
 */
const TransformDropTarget = _.compose(
  connect((state) => {
    const { transforms: { dragging } } = state;
    return { guid: dragging };
  }),
  dropTarget('transform', {
    canDrop: (props, monitor) => {
      const { currentIndex, sourceLevel: currentSourceLevel } = monitor.getItem();
      const { index, sourceLevel } = props;
      return currentSourceLevel !== sourceLevel || index < currentIndex || index > currentIndex + 1;
    },
    drop: ({ index, guid, sourceLevel }) => {
      // NOTE must allow the monitor to understand that this has been dropped BEFORE calling any redux methods
      //      redux methods could unmount this component (moving guid out of list), leading to "i never got dropped" shenanigans
      //      The returned information here can be retrieved in other react-dnd lifecycle methods via monitor.getDropResult()
      return { guid, index, sourceLevel };
    },
  }, (connector, monitor) => ({
    connectDropTarget: connector.dropTarget(), isOver: monitor.isOver(), canDrop: monitor.canDrop(),
  })),
)(({ isOver, connectDropTarget, canDrop }) => {
  const classes = classNames({
    [style.hoverOverTarget]: isOver && canDrop,
  });
  return connectDropTarget(
    <div className={classes}>
      <div className={style.dropTarget}>
        <div className={style.dropInner} />
        <span className={style.dropText}>Move transformation</span>
        <div className={style.dropInner} />
      </div>
    </div>,
  );
});

TransformDropTarget.propTypes = {
  index: PropTypes.number.isRequired,
  sourceLevel: PropTypes.bool.isRequired,
};

export default TransformDropTarget;
