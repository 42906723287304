import PropTypes from 'prop-types';
import React from 'react';
import { pure } from 'recompose';
import _ from 'underscore';

import CenterContent from '../components/CenterContent';

const BulkExpandTrigger = _.compose(
  pure,
)(class BulkExpandTrigger extends React.Component {
  static propTypes = {
    allExpanded: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
  };

  render() {
    const { allExpanded, onToggle } = this.props;
    return (
      <CenterContent className="bulk-expand" onClick={onToggle}>
        {allExpanded ? 'collapse' : 'expand'} all
      </CenterContent>
    );
  }
});

export default BulkExpandTrigger;
