import { Set } from 'immutable';

/**
 * Constants related to objects in our generated pipelines.
 * This list is a UI copy of the list in UnifiedAttribute.java and needs to be kept in sync.
 */
export const CLUSTER_ID = 'clusterId';
export const ORIGIN_SOURCE_ID = 'originSourceId';
export const ORIGIN_ENTITY_ID = 'originEntityId';
export const SOURCE_ID = 'sourceId';
export const ENTITY_ID = 'entityId';
export const SUGGESTED_CLUSTER_ID = 'suggestedClusterId';
export const VERIFICATION_TYPE = 'verificationType';
export const VERIFIED_CLUSTER_ID = 'verifiedClusterId';
export const GROUP_UNIFIED_ID = 'groupUnifiedId';
export const CLUSTER_NAME = 'clusterName';
export const PERSISTENT_ID = 'persistentId';

// categorization field names
export const MANUAL_CLASSIFICATION_PATH = 'manualClassificationPath';
export const MANUAL_CLASSIFICATION_ID = 'manualClassificationId';
export const SUGGESTED_CLASSIFICATION_PATH = 'suggestedClassificationPath';
export const SUGGESTED_CLASSIFICATION_ID = 'suggestedClassificationId';
export const SUGGESTED_CLASSIFICATION_CONFIDENCE = 'suggestedClassificationConfidence';
export const SUGGESTED_CLASSIFICATION_TIER_CONFIDENCES = 'suggestedClassificationTierConfidences';
export const SUGGESTED_CLASSIFICATION_PATH_ABOVE_THRESHOLD = 'suggestedClassificationPathAboveThreshold';
export const SUGGESTED_CLASSIFICATION_ID_ABOVE_THRESHOLD = 'suggestedClassificationIdAboveThreshold';
export const SUGGESTED_CLASSIFICATION_CONFIDENCE_ABOVE_THRESHOLD = 'suggestedClassificationConfidenceAboveThreshold';
export const OVERRIDE_FUNCTION_CATEGORY_ID = 'overrideFunctionCategoryId';
export const OVERRIDE_FUNCTION_CATEGORY_PATH = 'overrideFunctionCategoryPath';
export const TRAINING_FUNCTION_CATEGORY_ID = 'trainingFunctionCategoryId';
export const TRAINING_FUNCTION_CATEGORY_PATH = 'trainingFunctionCategoryPath';
export const FUNCTION_CATEGORY_PATHS = 'functionCategoryPaths';
export const FUNCTION_CATEGORY_IDS = 'functionCategoryIds';
export const FINAL_CATEGORY_ID = 'finalCategoryId';
export const FINAL_CATEGORY_PATH = 'finalCategoryPath';
export const QUESTION_IMPACT_RANK = 'questionImpactRank';
export const HIGH_IMPACT_RANK = 'highImpactRank';

export default Set([
  CLUSTER_ID, ORIGIN_SOURCE_ID, ORIGIN_ENTITY_ID, SOURCE_ID, ENTITY_ID,
  SUGGESTED_CLUSTER_ID, VERIFICATION_TYPE, VERIFIED_CLUSTER_ID, GROUP_UNIFIED_ID, CLUSTER_NAME,
  PERSISTENT_ID, MANUAL_CLASSIFICATION_PATH, MANUAL_CLASSIFICATION_ID, SUGGESTED_CLASSIFICATION_PATH,
  SUGGESTED_CLASSIFICATION_ID, SUGGESTED_CLASSIFICATION_CONFIDENCE, SUGGESTED_CLASSIFICATION_TIER_CONFIDENCES,
  SUGGESTED_CLASSIFICATION_PATH_ABOVE_THRESHOLD, SUGGESTED_CLASSIFICATION_ID_ABOVE_THRESHOLD,
  SUGGESTED_CLASSIFICATION_CONFIDENCE_ABOVE_THRESHOLD, OVERRIDE_FUNCTION_CATEGORY_ID,
  OVERRIDE_FUNCTION_CATEGORY_PATH, TRAINING_FUNCTION_CATEGORY_ID, TRAINING_FUNCTION_CATEGORY_PATH,
  FUNCTION_CATEGORY_PATHS, FUNCTION_CATEGORY_IDS, FINAL_CATEGORY_ID, FINAL_CATEGORY_PATH,
  QUESTION_IMPACT_RANK, HIGH_IMPACT_RANK,
]);
