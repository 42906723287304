import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import { history } from '../utils/History';
import PRODUCT_NAME from '../utils/ProductName';
import style from './CategorizationDashboardAssignmentsAllDone.module.scss';

const navigate = (url, onResetTransactionsFilterState) => {
  if (_.isFunction(onResetTransactionsFilterState)) {
    onResetTransactionsFilterState();
  }
  history.push(url);
};

const CategorizationDashboardAssignmentsAllDone = _.compose(
  connect(state => {
    const { location: { recipeId } } = state;
    return { recipeId };
  }, {
    onResetTransactionsFilterState: () => ({ type: 'Transactions.resetFilters' }),
  }),
)(({ recipeId, onResetTransactionsFilterState, className }) => {
  const tamrTip = (
    <span className={style.tamrTip}>
      <TamrIcon className={style.icon} iconName="whatshot" size={14} />
      {PRODUCT_NAME} Tip:
    </span>
  );
  return (
    <div className={className}>
      <div className={style.title}>
        <span className={style.iconWrapper}>
          <TamrIcon className={style.headerIcon} iconName="check-circle" size={30} />
        </span>
        All User Categorizations Verified
      </div>
      <div className={style.textContainer}>
        <div className={style.paragraph}>
          {tamrTip}
          To improve the confidence of {PRODUCT_NAME} categorizations, find categories that do not have many user verified <Term>records</Term> and assign <Term>records</Term> from those categories to people in your organization with relevant expertise. {PRODUCT_NAME} will learn from the categorizations made by these reviewers after they’ve been verified by a curator.
        </div>
        <div className={style.paragraph}>
          {tamrTip}
          If you’ve verified user categorizations since the last update, you’ll need to <a onClick={() => navigate(`/spend/recipe/${recipeId}`, onResetTransactionsFilterState)}>update {PRODUCT_NAME}’s categorizations</a> once again to apply the new user input and generate better {PRODUCT_NAME} suggestions.
        </div>
      </div>
    </div>
  );
});

export default CategorizationDashboardAssignmentsAllDone;
