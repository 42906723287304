import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { FCWithPropTypes } from '../utils/typescript-react';
import TamrIcon from './TamrIcon';
import style from './WarningIcon.module.scss';

// simple wrapper for the warning icon to apply color to it
const WarningIcon = FCWithPropTypes(
  {
    className: PropTypes.string,
    size: PropTypes.number,
    error: PropTypes.bool,
  },
  ({ className, size, error }) => {
    return (
      <TamrIcon
        iconName="warning"
        className={classNames(style.warningIcon, className, { [style.error]: !!error })}
        size={size || 16}
      />
    );
  },
);

export default WarningIcon;
