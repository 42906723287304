import _ from 'underscore';
import uri from 'urijs';

const ROUTER_HASH_PARAM = '_k';

// blacklistedParams is an array of ephemeral or client-specific params that
// should not be preserved in the query string.
const getPermalink = (location, blacklistedParams = [ROUTER_HASH_PARAM]) => {
  const currentUri = uri(location);

  // rewrite uri fragment to exclude blacklisted params
  const fragmentUri = uri(currentUri.fragment());
  const params = fragmentUri.search(true);
  const withAllowedParams = fragmentUri
    .search(_.omit(params, ...blacklistedParams));

  return currentUri
    .fragment(withAllowedParams)
    .valueOf();
};

export {
  getPermalink,
};
