import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import TamrIcon from '../components/TamrIcon';
import { deleteTaxonomy } from './TaxonomyApi';

const ConfirmDeleteTaxonomyDialog = connect(null, {
  onAccept: deleteTaxonomy,
})(class ConfirmDeleteTaxonomyDialog extends React.Component {
  static propTypes = {
    onAccept: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
  };

  accept = () => {
    this.props.onAccept();
    this.props.onHide();
  };

  render() {
    const title = 'Delete Taxonomy';
    const message = 'Are you sure you want to delete the taxonomy associated with this project?';
    const subMessage = 'This will delete all associated labels, suggestions, and feedback.';
    const warning = 'This action cannot be undone.';
    const body = (
      <div className="delete-taxonomy-body">
        <div className="message">{message}</div>
        <div className="sub-message">{subMessage}</div>
        <div className="warning-box">
          <div className="warning-message">
            <TamrIcon
              ref="warningIcon"
              className="warning-icon"
              size={16}
              iconName="tamr-icon-warning"
            />
            {warning}
          </div>
        </div>
      </div>
    );
    return (
      <Dialog
        className="confirm-delete-taxonomy-dialog"
        show={this.props.show}
        onHide={this.props.onHide}
        title={title}
        body={body}
        dialogStyle={DialogStyle.PRIMARY}
        footer={(
          <ButtonToolbar>
            <Button
              onClick={this.accept}
            >
              Confirm
            </Button>
            <Button
              onClick={this.props.onHide}
              buttonType="Secondary"
            >
              Cancel
            </Button>
          </ButtonToolbar>
        )}
      />
    );
  }
});

export default ConfirmDeleteTaxonomyDialog;
