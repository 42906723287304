import enumify from './enumify';

// com.tamr.procurify.models.dedup.VerificationTypeFilter

export enum VerificationTypeFilterE {
  NEVER_VERIFIED = 'NEVER_VERIFIED',
  UNVERIFIED = 'UNVERIFIED',
  MOVABLE_AGREE = 'MOVABLE_AGREE',
  MOVABLE_DISAGREE = 'MOVABLE_DISAGREE',
  SUGGEST_AGREE = 'SUGGEST_AGREE',
  SUGGEST_DISAGREE = 'SUGGEST_DISAGREE',
  LOCK = 'LOCK',
}

export const NEVER_VERIFIED = VerificationTypeFilterE.NEVER_VERIFIED;
export const UNVERIFIED = VerificationTypeFilterE.UNVERIFIED;
export const MOVABLE_AGREE = VerificationTypeFilterE.MOVABLE_AGREE;
export const MOVABLE_DISAGREE = VerificationTypeFilterE.MOVABLE_DISAGREE;
export const SUGGEST_AGREE = VerificationTypeFilterE.SUGGEST_AGREE;
export const SUGGEST_DISAGREE = VerificationTypeFilterE.SUGGEST_DISAGREE;
export const LOCK = VerificationTypeFilterE.LOCK;

const VerificationTypeFilter = enumify({ NEVER_VERIFIED, UNVERIFIED, MOVABLE_AGREE, MOVABLE_DISAGREE, SUGGEST_AGREE, SUGGEST_DISAGREE, LOCK });

export default VerificationTypeFilter;
