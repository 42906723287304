import classNames from 'classnames';
import { List } from 'immutable';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { fetchAllPolicies } from '../accessControl/AccessControlAsync';

import { DatasetPolicyResourceship, getPolicyById } from '../accessControl/AccessControlStore';
import { UserGroupsCount, UsersCount } from '../accessControl/Count';
import {
  memberProjectString,
  resourcePolicyString,
  resourceProjectString,
} from '../accessControl/PoliciesList';
import Button from '../components/Button';
import SplitGrid, { Column } from '../components/SplitGrid';
import TooltipTrigger from '../components/TooltipTrigger';
import AuthorizationPolicy from '../models/AuthorizationPolicy';
import Document from '../models/doc/Document';
import { AppState } from '../stores/MainStore';
import { pluralize } from '../utils/Strings';
import style from './DatasetPoliciesSelector.module.scss';

// ID value to use as a placeholder for datasets that are being created
export const FUTURE_DATASET_ID = '-1';
export const FUTURE_DATASET_NAME = '-1';

/**
 * Return whether the dataset is in the policy with the given policyId
 *
 * Checks if the dataset is in the draft state of the policy; otherwise, if there is no draft state
 * of the policy, checks the actual state of the policy
 *
 * @param policy                 the actual version of the policy
 * @param policyId                  the current policy ID
 * @param datasetDraftPolicyResourceship the object that contains the editing information the dataset
 * @return true if the dataset is edited to be a resource of the policy, or is already one in the existing policy
 */
const getDatasetInPolicy = (policy: AuthorizationPolicy | undefined, policyId: number, datasetDraftPolicyResourceship: DatasetPolicyResourceship) => {
  const { datasetId } = datasetDraftPolicyResourceship;
  // first, check if the dataset is in a draft state of the policy exists
  // otherwise, check from the actual version of the policy
  const draftPolicy = datasetDraftPolicyResourceship.draftPoliciesById.has(policyId)
    ? datasetDraftPolicyResourceship.draftPoliciesById.get(policyId)
    : null;
  return draftPolicy
    ? draftPolicy.hasDataset({ datasetId })
    : policy?.hasDataset({ datasetId });
};

const getSplitIds = (getInPolicyIds: boolean, policyDocs: List<Document<AuthorizationPolicy>>, datasetDraftPolicyResourceship: DatasetPolicyResourceship) => {
  return policyDocs
    .filter(doc => {
      const datasetInPolicy = doc.data.hasDataset({ datasetId: datasetDraftPolicyResourceship.datasetId });
      return (getInPolicyIds) ? datasetInPolicy : !datasetInPolicy;
    })
    .sort((doc1, doc2) => doc1.data.name.localeCompare(doc2.data.name))
    .map(doc => doc.id.id);
};

const DatasetPoliciesSelectorPropTypes = {
  datasetName: PropTypes.string,
  datasetId: PropTypes.string,
  rowClassName: PropTypes.string,
  headerClassName: PropTypes.string,
};

type DatasetPoliciesSelectorOwnProps = InferProps<typeof DatasetPoliciesSelectorPropTypes>;

const DatasetPoliciesSelector = connect((state: AppState, { datasetName, datasetId, rowClassName, headerClassName }: DatasetPoliciesSelectorOwnProps) => {
  const { accessControl, accessControl: { policyDocs, datasetDraftPolicyResourceship, loadingPolicies } } = state;
  return {
    policyDocs,
    loadingPolicies,
    datasetDraftPolicyResourceship,
    policyById: getPolicyById(accessControl),
    datasetName,
    datasetId,
    rowClassName,
    headerClassName,
  };
}, {
  onAddToPolicy: (policyId: number) => ({ type: 'Datasets.addToPolicyResourceship', policyId }),
  onStartEditing: (datasetId: string, datasetName: string) => ({ type: 'Datasets.startEditingPolicyResourceship', datasetId, datasetName }),
  onRemoveFromPolicy: (policyId: number) => ({ type: 'Datasets.removeFromPolicyResourceship', policyId }),
  onStopEditing: () => ({ type: 'Datasets.stopEditingPolicyResourceship' }),
  onFetchPolicies: fetchAllPolicies,
})((
  { onAddToPolicy, onRemoveFromPolicy, onFetchPolicies, loadingPolicies, policyDocs, datasetDraftPolicyResourceship, policyById, onStartEditing, datasetName, datasetId, rowClassName, headerClassName }) => {
  if (policyDocs.isEmpty()) {
    if (!loadingPolicies) {
      onFetchPolicies();
    }
    return null;
  }
  // If editing hasn't been initiated in the store yet, initiate it
  if (!datasetDraftPolicyResourceship) {
    if (datasetId && datasetName) {
      onStartEditing(datasetId, datasetName);
    }
    return null;
  }
  return (
    <SplitGrid
      topIds={getSplitIds(true, policyDocs, datasetDraftPolicyResourceship)}
      bottomIds={getSplitIds(false, policyDocs, datasetDraftPolicyResourceship)}
      className={style.grid}
      cellClassNameGetter={({ id }) => {
        const policy = policyById(id);
        return !getDatasetInPolicy(policy, id, datasetDraftPolicyResourceship)
          ? style.notInPolicy
          : null;
      }}
        >
      <Column
        topIds={getSplitIds(true, policyDocs, datasetDraftPolicyResourceship)}
        bottomIds={getSplitIds(false, policyDocs, datasetDraftPolicyResourceship)}
        className={classNames(style.idColumn, rowClassName)}
        headerClassName={headerClassName}
        renderHeader={() => 'ID'}
        renderCell={({ id }) => {
          const policy = policyById(id);
          return !policy ? null : (
            <div className={style.idCell}>
              <div className={classNames(style.idValue)}>
                {id}
              </div>
            </div>
          );
        }}
      />
      <Column
        topIds={getSplitIds(true, policyDocs, datasetDraftPolicyResourceship)}
        bottomIds={getSplitIds(false, policyDocs, datasetDraftPolicyResourceship)}
        className={classNames(style.nameColumn, rowClassName)}
        headerClassName={headerClassName}
        renderHeader={() => 'Policy Name'}
        cellClassNameGetter={({ id }) => {
          const policy = policyById(id);
          return !getDatasetInPolicy(policy, id, datasetDraftPolicyResourceship)
            ? style.notInPolicy
            : null;
        }}
        renderCell={({ id }) => {
          const policy = policyById(id);
          return !policy ? null : (
            <div className={style.nameCell}>
              <TooltipTrigger
                placement="top"
                content={policy.name}
                className={classNames(style.nameToolTip)}>
                <div className={classNames(style.name)}>
                  {policy.name}
                </div>
              </TooltipTrigger>
              <div className={classNames(style.description)}>
                {policy.description}
              </div>
            </div>
          );
        }}
          />
      <Column
        topIds={getSplitIds(true, policyDocs, datasetDraftPolicyResourceship)}
        bottomIds={getSplitIds(false, policyDocs, datasetDraftPolicyResourceship)}
        className={classNames(style.membersColumn, rowClassName)}
        headerClassName={headerClassName}
        renderHeader={() => 'Members'}
        renderCell={({ id }) => {
          const policy = policyById(id);
          return !policy ? null : (
            <div className={classNames(style.membersCell)}>
              <span>
                <UsersCount id={id} />,&nbsp;
                <UserGroupsCount id={id} />,&nbsp;
                {memberProjectString(policy)}
              </span>
            </div>
          );
        }}
          />
      <Column
        topIds={getSplitIds(true, policyDocs, datasetDraftPolicyResourceship)}
        bottomIds={getSplitIds(false, policyDocs, datasetDraftPolicyResourceship)}
        className={classNames(style.resourcesColumn, rowClassName)}
        headerClassName={headerClassName}
        renderHeader={() => 'Resources'}
        renderCell={({ id }) => {
          const originalPolicy = policyById(id);
          const draftPolicy = datasetDraftPolicyResourceship.draftPoliciesById.has(id)
            ? datasetDraftPolicyResourceship.draftPoliciesById.get(id)
            : null;
          if (!originalPolicy) {
            return null;
          }
          const originallyIncluded = originalPolicy.hasDataset({ datasetId: datasetDraftPolicyResourceship.datasetId });
          const currentlyIncluded = draftPolicy ? draftPolicy.hasDataset({ datasetId: datasetDraftPolicyResourceship.datasetId }) : originallyIncluded;

          // Adjust the number of datasets based If the project was a member, but no longer is, adjust the number of projects for the policy.
          let numDatasets = originalPolicy.datasetIds().size;
          numDatasets = originallyIncluded && !currentlyIncluded ? numDatasets - 1 : numDatasets;
          numDatasets = currentlyIncluded && !originallyIncluded ? numDatasets + 1 : numDatasets;
          const datasetContent = originalPolicy.appliesToAllDatasets() ?
            <span className={style.included}>All datasets</span> :
            <span className={numDatasets ? style.included : ''}>{numDatasets} {pluralize(numDatasets, 'dataset', 'datasets')}</span>;
          return (
            <div>
              <span>{resourceProjectString(originalPolicy)},&nbsp;</span>
              <span className={classNames(style.dataset)}>{datasetContent},&nbsp;</span>
              <span>{resourcePolicyString(originalPolicy)}</span>
            </div>
          );
        }}
          />
      <Column
        topIds={getSplitIds(true, policyDocs, datasetDraftPolicyResourceship)}
        bottomIds={getSplitIds(false, policyDocs, datasetDraftPolicyResourceship)}
        className={classNames(style.actionColumn, rowClassName)}
        headerClassName={headerClassName}
        renderHeader={() => null}
        renderCell={({ id }) => {
          const policy = policyById(id);
          return !policy ? null : (
            <div className={classNames(style.actionButtonCell)}>
              {getDatasetInPolicy(policy, id, datasetDraftPolicyResourceship)
                ? (
                  <TooltipTrigger
                    content={policy.appliesToAllDatasets()
                      ? 'You cannot remove datasets from a policy that includes all datasets. Admins can change this setting on the Policies page.'
                      : 'Remove dataset from policy'
                        }
                    placement="top">
                    <Button
                      className={style.removeFromPolicyButton}
                      buttonType="Link"
                      icon="close"
                      iconSize={14}
                      iconClassName={style.removeFromPolicyIcon}
                      onClick={() => onRemoveFromPolicy(id)}
                      disabled={policy.appliesToAllDatasets()}
                        />
                  </TooltipTrigger>
                ) : (
                  <TooltipTrigger
                    content={'Add dataset to policy'}
                    placement="top">
                    <Button
                      className={style.addToPolicyButton}
                      buttonType="Link"
                      icon="tamr-icon-add"
                      iconSize={14}
                      onClick={() => onAddToPolicy(id)}
                        />
                  </TooltipTrigger>
                )
                  }
            </div>
          );
        }}
          />
    </SplitGrid>
  );
});

export default DatasetPoliciesSelector;
