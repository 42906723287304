import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import CenterContent from '../components/CenterContent';
import TaxonomyLoader from '../taxonomy/TaxonomyLoader';
import TransactionLoader from '../transactions/TransactionLoader';
import { selectActiveProjectInfo } from '../utils/Selectors';
import SuggestedCategorizationsCard from './categorization/SuggestedCategorizationsCard';
import TopCategoriesByCountCard from './categorization/TopCategoriesByCountCard';
import TopCategoriesBySpendCard from './categorization/TopCategoriesBySpendCard';
import DatasetsCard from './schema-mapping/DatasetsCard';
import FieldsCard from './schema-mapping/FieldsCard';

const CategorizationDashboardReviewerCards = _.compose(
  connect(state => {
    const { recipeId, smRecipeId } = selectActiveProjectInfo(state);
    return { recipeId, smRecipeId };
  }),
)(({ recipeId, smRecipeId }) => {
  return (
    <CenterContent className="project-dashboard" vertically={false}>
      <div className="cards-container">
        <TaxonomyLoader />
        <TransactionLoader />
        <div className="left-column">
          <DatasetsCard smRecipeId={smRecipeId} />
          <FieldsCard smRecipeId={smRecipeId} />
          <TopCategoriesByCountCard c12nRecipeId={recipeId} />
        </div>
        <div className="right-column">
          <SuggestedCategorizationsCard c12nRecipeId={recipeId} />
          <TopCategoriesBySpendCard c12nRecipeId={recipeId} />
        </div>
      </div>
    </CenterContent>
  );
});

export default CategorizationDashboardReviewerCards;
