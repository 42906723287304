export function sourceListDataset(displayName: string): string {
  return `${displayName}_source_list`;
}

export function overrideDataset(displayName: string): string {
  return `${displayName}_golden_records_overrides`;
}

export function goldenRecordDataset(displayName: string): string {
  return `${displayName}_golden_records`;
}
