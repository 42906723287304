import SSOEnabled from '../models/SSOEnabled';

export const AUTH_COMPLETED = 'SSO.authCompleted';
export const AUTH_FAILED = 'SSO.authFailed';

interface AuthCompleted {
  type: typeof AUTH_COMPLETED
  ssoEnabled?: SSOEnabled
}

interface AuthFailed {
  type: typeof AUTH_FAILED
}

export type SSOActionTypes
  = AuthCompleted
  | AuthFailed
