import { connect } from 'react-redux';
import { withProps } from 'recompose';
import _ from 'underscore';

import AttributeList from './AttributeList';
import { filteredUnifiedAttributes } from './SchemaMappingStore';
import UnifiedAttributeItem from './UnifiedAttributeItem';

const UnifiedAttributeList = _.compose(
  connect(({ schemaMapping }) => {
    const { unifiedExpanded } = schemaMapping;
    return {
      expandedIds: unifiedExpanded,
      attributes: filteredUnifiedAttributes(schemaMapping),
    };
  }),
  withProps({
    AttributeItem: UnifiedAttributeItem,
    outerHeight: 'calc( 100% + 50px )',
  }),
)(AttributeList);

export default UnifiedAttributeList;
