import { is } from 'immutable';

import createLoader from '../utils/createLoader';
import { fetchJobSpec } from './JobApi';
import { getSpecFilterInfo } from './JobStore';

const JobSpecLoader = createLoader(({ jobs }) => {
  const { specLoading, specJobId, loadedSpecFilterInfo } = jobs;
  return {
    canFetch: true, // there is no precondition to fetching, is this a problem?
    shouldFetch: !!specJobId && !is(getSpecFilterInfo(jobs), loadedSpecFilterInfo),
    loading: specLoading,
  };
}, {
  onFetch: fetchJobSpec,
}, 'JobSpecLoader');

export default JobSpecLoader;
