import { List } from 'immutable';
import $ from 'jquery';
import { sortBy } from 'lodash';

import SortState from '../constants/SortState';
import Document from '../models/doc/Document';
import Job from '../models/Job';
import Task from '../models/Task';
import { getFilterInfo, getSpecFilterInfo } from './JobStore';

export const fetchTasks = () => (dispatch, getState) => {
  const { jobs } = getState();
  const uuids = List(jobs.jobs).map((j) => j.data.uuid);
  $.ajax({
    url: SERVICES.dataset('/tasks/uuid'),
    method: 'POST',
    data: JSON.stringify(uuids),
    cache: false,
    contentType: 'application/json',
  }).then(
    (data) => {
      dispatch({
        type: 'Jobs.fetchTasksCompleted',
        tasks: List(data).map((d) => Document.fromJSON(d, Task.fromJSON)),
      });
    },
    () => {
      dispatch({ type: 'Jobs.fetchTasksFailed' });
    },
  );
};

const getDateFromJob = (job) => {
  const date = job?.created?.timestamp * 1000 || job?.createdAt;
  return date ? new Date(date) : null;
};

const getSortColumnAndDirection = (columnSortStates) => {
  for (const [key, value] of columnSortStates.entries()) {
    if (value !== SortState.UNSORTED) {
      return `${key}..${value}`;
    }
  }
  return '';
};

export const fetchJobs = () => async (dispatch, getState) => {
  const {
    jobs,
    coreConnectService: { coreconnectEnabled },
  } = getState();
  const { pageNum, pageSize, offset, searchString, columnSortStates } = jobs;
  const filterInfo = getFilterInfo(jobs);
  dispatch({ type: 'Jobs.fetchJobs' });
  const curOffset = offset.get(pageNum);
  const sortOption = getSortColumnAndDirection(columnSortStates);
  if (coreconnectEnabled) {
    let fetchCCJobs = false;
    // if sorting is requested for a column, don't fetch coreconnect jobs
    if (
      (!searchString ||
        'data movement job'.includes(String(searchString).toLowerCase())) &&
      !sortOption
    ) {
      fetchCCJobs = true;
    }
    const [tamrJobs, coreconnectJobs] = await Promise.all([
      $.ajax({
        url: SERVICES.dataset('/jobs'),
        data: {
          limit: pageSize + 1,
          ...(curOffset && { createdBefore: +new Date(curOffset) / 1000 }),
          searchString,
          sortOption,
        },
        cache: false,
        dataType: 'json',
      }),
      fetchCCJobs
        ? fetch(
          `/api/connect/jobs?limit=${pageSize}&sortOption=desc${
            curOffset
              ? `&beforeDate=${new Date(curOffset).toISOString()}`
              : ''
          }`,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: ' BasicCreds YWRtaW46ZHQ=',
            },
            method: 'GET',
          },
        )
          .then((x) => x.json())
          .then((data) => {
            return data.map((job) => {
              return {
                ...job,
                createdAt: job.submittedTimeUtc,
                completedAt: job.finishTimeUtc,
                startedAt: job.startTimeUtc || job.submittedTimeUtc,
                id: job.jobId,
                state: job.jobStatus,
                message: '',
              };
            });
          })
          .catch(() => {})
        : undefined,
    ]);

    let coreconnectAndSparkJobs;
    if (coreconnectJobs !== undefined) {
      coreconnectAndSparkJobs = sortBy(
        [...tamrJobs, ...coreconnectJobs],
        [(o) => -getDateFromJob(o)],
      ).map((o) =>
        (o.created?.timestamp ? Document.fromJSON(o, Job.fromJSON) : o),
      );
    } else {
      coreconnectAndSparkJobs = List(tamrJobs).map((o) =>
        (o.created?.timestamp ? Document.fromJSON(o, Job.fromJSON) : o),
      );
    }

    let nextOffset = offset;
    if (pageNum === 0) {
      nextOffset = List();
    }
    if (!nextOffset.get(pageNum + 1)) {
      const index = Math.min(coreconnectAndSparkJobs.length - 1, pageSize - 1);
      const date = getDateFromJob(coreconnectAndSparkJobs[index]);
      nextOffset = nextOffset.set(pageNum + 1, date ? +date - 1 : null);
    }

    dispatch({
      type: 'Jobs.fetchMergedJobsCompleted',
      filterInfo,
      coreconnectAndSparkJobs: List(coreconnectAndSparkJobs.slice(0, pageSize)),
      offset: nextOffset,
      jobs: List(tamrJobs).map((d) => Document.fromJSON(d, Job.fromJSON)),
    });

    if (tamrJobs.length > 0) {
      dispatch(fetchTasks());
    }
  } else {
    $.ajax({
      url: SERVICES.dataset('/jobs'),
      data: {
        offset: pageNum * pageSize,
        limit: pageSize,
        searchString,
        sortOption,
      },
      cache: false,
      dataType: 'json',
    }).then(
      (data) => {
        const jobDocs = List(data).map((d) =>
          Document.fromJSON(d, Job.fromJSON),
        );
        dispatch({
          type: 'Jobs.fetchJobsCompleted',
          filterInfo,
          jobs: jobDocs,
        });
        if (!jobDocs.isEmpty()) {
          dispatch(fetchTasks());
        }
      },
      () => {
        dispatch({ type: 'Jobs.fetchJobsFailed', filterInfo });
      },
    );
  }
};

export const cancelJob = () => (dispatch, getState) => {
  const {
    jobs: { cancelJobId },
  } = getState();
  dispatch({ type: 'Jobs.cancelJob' });
  $.ajax({
    url: SERVICES.dataset(`/jobs/${cancelJobId}/cancel`),
    method: 'POST',
  }).then(
    () => {
      dispatch({ type: 'Jobs.cancelJobCompleted' });
    },
    () => {
      dispatch({ type: 'Jobs.cancelJobFailed' });
    },
  );
};

export const fetchJobSpec = () => (dispatch, getState) => {
  const { jobs } = getState();
  const { specJobId } = jobs;
  const filterInfo = getSpecFilterInfo(jobs);
  dispatch({ type: 'Jobs.fetchSpec' });
  $.ajax({
    url: SERVICES.dataset(`/jobs/${specJobId}`),
    data: { includeSpec: true },
    cache: false,
    dataType: 'json',
  }).then(
    (job) => {
      dispatch({ type: 'Jobs.fetchSpecCompleted', filterInfo, job });
    },
    () => {
      dispatch({ type: 'Jobs.fetchSpecFailed', filterInfo });
    },
  );
};
