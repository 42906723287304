import { List, Set } from 'immutable';
import { createSelector } from 'reselect';
import { isNumber } from 'underscore';

import { PAIRS } from '../constants/DataTables';
import { ORIGIN_SOURCE_NAME } from '../constants/ElasticConstants';
import DisplayColumn from '../models/DisplayColumn';
import { AppThunkAction } from '../stores/AppAction';
import { setColumnPreferences } from '../users/UsersAsync';
import { getColumnSettingsForUserForPage } from '../utils/Columns';
import { AppSelector, getAuthorizedUser, selectActiveProjectInfo } from '../utils/Selectors';
import { getPath } from '../utils/Values';
import UserDefinedSignal from './UserDefinedSignal';


const UDS_COLUMN_KEY_PREFIX = 'uds.';

/**
 * Converts the externalId of a {@link UserDefinedSignal} to a string value that's guaranteed to be unique
 *   in a namespace that includes unified attribute names.
 * This is accomplished by including a '.' character in the column key - that's not allowed in a unified attribute name.
 */
export function udsExternalIdToColumnKey(udsExternalId: string): string {
  return `${UDS_COLUMN_KEY_PREFIX}${udsExternalId}`;
}

/**
 * Converts the columnKey of a {@link UserDefinedSignal} to its externalId.
 * The inverse operation of {@link udsExternalIdsToColumnKey}
 */
export function udsColumnKeyToExternalId(udsColumnKey: string): string {
  return udsColumnKey.substring(UDS_COLUMN_KEY_PREFIX.length);
}

export function columnKeyStartsWithUDSPrefix(columnKey: string): boolean {
  return columnKey.startsWith(UDS_COLUMN_KEY_PREFIX);
}

/**
 * Provides the {@link List} of {@link DisplayColumn}s to populate the record pairs table.
 * Combines unified dataset fields and user defined signals into the same list, so they can be ordered together
 *   by the user.
 * Draws on user preferences, if any exist.
 */
export const getPairsTableDisplayColumns: AppSelector<List<DisplayColumn>> = createSelector(
  getAuthorizedUser,
  s => s.location.recipeId,
  s => s.recordPairs.userDefinedSignals,
  selectActiveProjectInfo,
  (user, recipeId, userDefinedSignals, projectInfo) => {
    const unifiedDataset = projectInfo?.unifiedDataset;
    if (!user || !isNumber(recipeId) || !projectInfo || !unifiedDataset) {
      return List();
    }
    const unifiedDatasetFieldNames = unifiedDataset.fields.toSet();
    const visibleFieldNames: Set<string> = List<string>(getPath(projectInfo.recipe.metadata, projectInfo.projectType, 'visibleFields')).toSet();
    const invisibleFieldNames = unifiedDatasetFieldNames.toSet().subtract(visibleFieldNames);
    const udsList = (userDefinedSignals || List<UserDefinedSignal>());
    const userDefinedSignalExternalIds = udsList.map(uds => uds.externalId).toSet();

    // the set of all field names (as column keys, not display names) that the table needs to account for
    const fieldNames = Set()
      // table accounts for all fields listed as fields of the unified dataset
      .union(unifiedDatasetFieldNames)
      // table also accounts for all user defined signals, with a proper namespacing prefix
      .union(userDefinedSignalExternalIds.map(udsExternalIdToColumnKey));

    const defaults = List()
      // by default, fields not listed in dedup metadata as 'visibleFields' are hidden in the table
      .concat(invisibleFieldNames.map(name => new DisplayColumn({ name, visible: false })))
      // by default, ORIGIN_SOURCE_NAME column appears on the far left of the table, and has alias "Dataset"
      .push(new DisplayColumn({ name: ORIGIN_SOURCE_NAME, order: -Infinity, alias: 'Dataset' }));

    const columns = getColumnSettingsForUserForPage({
      page: PAIRS,
      recipeId,
      user,
      fieldNames,
      defaults,
    }).map(col => {
      const uds = udsList.find(u => udsExternalIdToColumnKey(u.externalId) === col.name);
      if (uds) return col.set('alias', uds.displayName);
      return col;
    });
    return columns;
  },
);

/**
 * This has to handle updating column preferences for both unified dataset fields and user defined signal columns.
 *
 * samgqroberts 2020-10-20 as of right now, the stored user preferences for columns on the record pairs table
 *   store unified dataset fields by raw name. if we change that (by, say, starting to prefix those stored names
 *   with RecordPairsTable's TABLE_COLUMN_PREFIX) users will effectively have their preferences reset. therefore
 *   while adding user defined signal considerations, we prefix those external ids when storing as column names, and
 *   leave the unified dataset fields raw.
 *
 * @param columnKey either raw unified attribute name, or a prefixed user defined signal external id (a uds columnKey)
 */
export const setPairsTableColumnWidth = (
  columnKey: string,
  newWidth: number,
): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const existingColumnPrefs = getPairsTableDisplayColumns(state);
  const newColumnPrefs = existingColumnPrefs.map(c => (c.name === columnKey ? c.set('width', newWidth) : c));
  dispatch(setColumnPreferences(PAIRS, newColumnPrefs));
};
