/* eslint-disable no-cond-assign */
import './Highlighter.scss';

import PropTypes, { InferProps } from 'prop-types';
import React from 'react';

const propTypes = {
  className: PropTypes.string,
  /**
   * Full text to apply highlighter to
   */
  fullText: PropTypes.string,
  /**
   * Highlight class
   */
  highlightClassName: PropTypes.string,
  /**
   * Text to highlight
   */
  highlightText: PropTypes.string,
};

/**
 * Component used to render highlighted text
 */
class Highlighter extends React.Component<InferProps<typeof propTypes>> {
  static propTypes = propTypes;

  doHighlight = () => {
    const segments = [];
    let position = 0;
    let lastPosition = 0;
    let key = 0;
    // Note: purposely doing the assignment inline with the `while` evaluation.  Even if that makes
    // ESLint so mad!
    while ((position =
      // @ts-expect-error
      this.props.fullText.toLowerCase()
      // @ts-expect-error
        .indexOf(this.props.highlightText.toLowerCase(), lastPosition)) > -1) {
      // Note: substring uses position as a second argument while substr uses length
      segments.push(<span
        key={`text-${key++}`}>{
          // @ts-expect-error
          this.props.fullText.substring(lastPosition, position)
        }</span>);
      segments.push((
        <span key={`text-${key++}`} className={this.props.highlightClassName || 'search-highlight'}>
          {
            // @ts-expect-error
            this.props.fullText.substr(position, this.props.highlightText.length)
          }
        </span>));
      // @ts-expect-error
      lastPosition = position + this.props.highlightText.length;
    }
    segments.push(<span
      key={`text-${key++}`}>{
        // @ts-expect-error
        this.props.fullText.substring(lastPosition, this.props.fullText.length)
      }</span>);
    return segments;
  };

  render() {
    const { className, fullText, highlightText } = this.props;
    if (fullText && highlightText) {
      // @ts-expect-error
      return (<span title={fullText} className={className}>{this.doHighlight()}</span>);
    }
    // @ts-expect-error
    return (<span title={fullText} className={className}>{fullText}</span>);
  }
}

export default Highlighter;
