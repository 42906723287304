import classNames from 'classnames';
import React from 'react';

const FilterIndicator = ({ className, filterAttributeId, onToggleFilterRelatedId }) => {
  return (
    <div className={classNames('filter-indicator', className)}>
      Mapped and suggested attributes for <div className="attr-label" title={filterAttributeId.name}>{filterAttributeId.name}.</div>{' '}
      <a className="clear-link" onClick={onToggleFilterRelatedId}>clear</a>
    </div>
  );
};

export default FilterIndicator;
