import React from 'react';
import { connect } from 'react-redux';
import { AutoSizer, List as VirtualScroll } from 'react-virtualized';

import LoadingPanel from '../components/LoadingPanel';
import ProjectCard from './ProjectCard';
import style from './ProjectList.module.scss';
import styleConstants from './ProjectListConstants.module.scss';
import { BEGIN_ADDING_PROJECT } from './ProjectsActionTypes';
import { selectFilteredProjectInfos } from './ProjectsSelectors';


const projectCardHeight = parseInt(styleConstants.projectCardHeight, 10);
const projectCardRowVerticalPadding = parseInt(styleConstants.projectCardRowVerticalPadding, 10);

const NUM_CARDS_PER_ROW = 3;

const renderCards = (projectIds) => {
  return projectIds.map(projectId => {
    return (
      <ProjectCard key={`ProjectCard${projectId}`} {...{ projectId }} />
    );
  });
};

const ProjectList = connect((state) => {
  const { projects: { loading, initialFetch } } = state;
  const filteredProjectInfos = selectFilteredProjectInfos(state);
  return {
    loading,
    initialFetch,
    filteredProjectInfos,
  };
}, {
  onBeginAddingProject: (projectType) => ({ type: BEGIN_ADDING_PROJECT, projectType }),
})(({ loading, initialFetch, filteredProjectInfos }) => {
  const projectIds = filteredProjectInfos
    .sortBy(({ project: { displayName } }) => displayName.toLowerCase())
    .map(({ projectDoc: { id: { id } } }) => id);

  const rowHeight = projectCardHeight + projectCardRowVerticalPadding;
  const rowRenderer = ({ index, style: virtualizedStyle }) => {
    const startIndex = index * NUM_CARDS_PER_ROW;
    const rowProjectIds = projectIds.slice(startIndex, startIndex + NUM_CARDS_PER_ROW);
    return (
      <div
        key={`row-${index}`}
        className={style.row}
        style={virtualizedStyle}
      >
        {renderCards(rowProjectIds)}
      </div>
    );
  };
  return (
    <div className={style.projectList}>
      {initialFetch && !loading ? (
        <AutoSizer>
          {({ width, height }) => (
            <VirtualScroll
              {...{ width, height, rowHeight, rowRenderer }}
              overscanRowCount={2}
              noRowsRenderer={() => <div />}
              rowCount={Math.ceil(projectIds.size / NUM_CARDS_PER_ROW)}
            />
          )}
        </AutoSizer>
      ) : (
        <LoadingPanel message="Loading projects..." />
      )}
    </div>
  );
});

export default ProjectList;
