/**
 * Pager data controls. Note: this class is exported as 'Pager' for consistency
 * with the existing procurify model.
 */
class Pager<T> {
  data: T;
  pageSize: number;
  dataSize: number;
  pageCount: number;
  pageNum: number;
  hidePageSize: boolean;
  constructor(data: T, total: number, pageNum: number, pageSize: number, hidePageSize = false) {
    this.pageSize = pageSize;
    this.data = data;
    this.dataSize = total;
    this.pageCount = Math.ceil(total / pageSize);
    this.pageNum = pageNum;
    this.hidePageSize = hidePageSize;
  }
}

export default Pager;
