import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { pure } from 'recompose';
import _ from 'underscore';

import Breadcrumb from '../components/Breadcrumb';
import ProcurementCategory from '../models/ProcurementCategory';
import { history } from '../utils/History';

const CategoryBreadcrumbs = _.compose(
  pure,
)(props => {
  const path = Immutable.List.of(
    {
      pathSegmentName: 'categories',
      onClick: () => history.push(`/taxonomy/recipe/${props.recipeId}`),
    },
    {
      pathSegmentName: props.category.name,
    },
  );
  return <Breadcrumb path={path} />;
});

CategoryBreadcrumbs.propTypes = {
  category: PropTypes.instanceOf(ProcurementCategory),
  recipeId: PropTypes.number.isRequired,
};

export default CategoryBreadcrumbs;
