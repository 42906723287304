import React from 'react';
import { connect } from 'react-redux';

import { GeoTamrType } from '../geospatial/GeoTamr';
import AppState from '../stores/AppState';
import { geoTamrFromRows } from './ClusterRecordsMap';
import { RenderGeoJson } from './TamrClusterGeoJSON';

interface ownProps {
  color: string
  onClickFeature: (lng: number, lat: number, northBound: number, southBound: number) => void
}

const TamrClusterOverlayGeoJSONWithToggle = connect(
  // eslint-disable-next-line
  (state: AppState, ownProps: ownProps) => {
    const { suppliers: { geospatial: { adjacentGeoRows, currentBounds, activeGeospatialRenderingAttribute: geoField } } } = state;

    return {
      geoRows: geoField ? geoTamrFromRows(adjacentGeoRows, geoField).toArray() : [] as GeoTamrType[],
      currentBounds,
    };
  },
)(({ geoRows, color, onClickFeature, currentBounds }) => {
  if (geoRows.length === 0) {
    return <div />;
  }

  return <RenderGeoJson {...{ geoTamr: geoRows, color, onClickFeature, currentBounds }} />;
});

export default TamrClusterOverlayGeoJSONWithToggle;
