import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'underscore';

import Checkbox from '../components/Input/Checkbox';
import style from './FilterSection.module.scss';

const AssignedToMeFilter = _.compose(
  connect((state, { pane }) => {
    const { suppliers: { [pane]: { filterResolved, filterUnresolved } } } = state;
    return { filterResolved, filterUnresolved };
  }, (dispatch, { pane }) => bindActionCreators({
    onToggleFilterResolved: () => ({ type: 'Suppliers.toggleFilterResolved', pane }),
    onToggleFilterUnresolved: () => ({ type: 'Suppliers.toggleFilterUnresolved', pane }),
  }, dispatch)),
)(class AssignedToMeFilter extends React.Component {
  static propTypes = {
    filterResolved: PropTypes.bool.isRequired,
    filterUnresolved: PropTypes.bool.isRequired,
    onToggleFilterResolved: PropTypes.func.isRequired,
    onToggleFilterUnresolved: PropTypes.func.isRequired,
  };

  render() {
    const { filterResolved, filterUnresolved, onToggleFilterResolved, onToggleFilterUnresolved } = this.props;
    return (
      <div className={style.filterSection}>
        <div className={style.filterPanelHeader}>
          Assignment
        </div>
        <div className={style.option}>
          <Checkbox
            className={style.checkboxWrapper}
            onChange={onToggleFilterUnresolved}
            title="My open assignments"
            titlePosition="right"
            value={filterUnresolved}
          />
        </div>
        <div className={style.option}>
          <Checkbox
            className={style.checkboxWrapper}
            onChange={onToggleFilterResolved}
            title="My resolved assignments"
            titlePosition="right"
            value={filterResolved}
          />
        </div>
      </div>
    );
  }
});

export default AssignedToMeFilter;
