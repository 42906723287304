import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Term from '../../components/Term';
import CheckboxFilter from './CheckboxFilter';

const HasCommentsFilterSection = _.compose(
  connect(({ recordPairs: { hasComments } }) => {
    return { hasComments };
  }, {
    onSetHasCommentsFilter: (hasComments) => ({ type: 'RecordPairs.setHasCommentsFilter', hasComments }),
  }),
)(class HasCommentsFilterSection extends React.Component {
  static propTypes = {
    hasComments: PropTypes.bool.isRequired,
    onSetHasCommentsFilter: PropTypes.func.isRequired,
  };

  render() {
    const { hasComments, onSetHasCommentsFilter } = this.props;
    return (
      <CheckboxFilter
        onChange={onSetHasCommentsFilter}
        title={<span><Term>Pairs</Term> with comments</span>}
        value={hasComments}
      />
    );
  }
});

export default HasCommentsFilterSection;
