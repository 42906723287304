import L, { LatLng, Layer, LeafletMouseEvent } from 'leaflet';
import React from 'react';
import { GeoJSON } from 'react-leaflet';
import { connect } from 'react-redux';

import { NoGeoMessage } from '../geospatial/GeospatialMap';
import * as geotamr from '../geospatial/GeoTamr';
import AppState from '../stores/AppState';
import { geoTamrFromRows } from './ClusterRecordsMap';

function point(latlong: LatLng) {
  const geojsonMarkerOptions = {
    radius: 4,
    weight: 1,
  };
  return L.circleMarker(latlong, geojsonMarkerOptions);
}

function style(feature: GeoJSON.Feature | undefined, color: string) {
  return {
    fillColor: color,
    color,
    opacity: 1,
    fillOpacity: feature?.geometry.type === 'Point' ? 1 : 0.4,
  };
}

export const RenderGeoJson: React.FC<{
  geoTamr: geotamr.GeoTamrType[],
  color: string,
  onClickFeature? : (lng: number, lat: number, northBound: number, southBond: number) => void,
  currentBounds? : number[][],
}> = ({ geoTamr, color, onClickFeature, currentBounds }) => {
  const onEachFeature = (feature: GeoJSON.Feature, layer: Layer) => {
    layer.on({
      click: (event: LeafletMouseEvent) => onClickFeature && currentBounds && onClickFeature(event.latlng.lng, event.latlng.lat, currentBounds[0][0], currentBounds[1][0]),
    });
  };

  return <>{geoTamr.map(
    gt => (
      <GeoJSON
        // GeoJSON only re-renders when elements with new keys are introduced, so we need unique
        // keys on each render...
        // Could hash the geometry property for a unique ID, as long as it doesn't slow down UI.
        key={'geo' + Math.random()}
        style={feature => style(feature, color)}
        data={geotamr.toGeoJSON(geotamr.reverseWindingOrder(gt))}
        pointToLayer={(feature, latlng) => point(latlng)}
        onEachFeature={(feature, layer) => onEachFeature(feature, layer)}
      />
    ),
  )}</>;
};

interface ownProps {
  color: string
  onClickFeature: (lng: number, lat: number, northBound: number, southBound: number) => void
}

const mapStateToProps = (state: AppState) => {
  const { suppliers: { geospatial: { geoRows, currentBounds, activeGeospatialRenderingAttribute: geoField } } } = state;

  return {
    geoRows: geoField ? geoTamrFromRows(geoRows, geoField).toArray() : ([] as geotamr.GeoTamrType[]),
    currentBounds,
  };
};

type Props = ownProps & ReturnType<typeof mapStateToProps>;

const TamrClusterGeoJSON: React.FC<Props> = ({ geoRows, color, onClickFeature, currentBounds }) => {
  if (geoRows.length === 0) {
    return <NoGeoMessage />;
  }

  return <RenderGeoJson {...{ color, onClickFeature, currentBounds, geoTamr: geoRows }} />;
};

export default connect(mapStateToProps)(TamrClusterGeoJSON);
