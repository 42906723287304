import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import Term from '../components/Term';
import PairsDatasetFilter from '../constants/PairsDatasetFilter';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import { pluralize } from '../utils/Strings';
import DatasetSelectionDialog from '../datasets/DatasetSelectionDialog';
import style from './RecordPairsFilterPanel.module.scss';
import AllAssignmentsFilter from './RecordPairsFilterPanel/AllAssignmentsFilter';
import AssignedToMeFilter from './RecordPairsFilterPanel/AssignedToMeFilter';
import AttributeSimilarityFilterSection
  from './RecordPairsFilterPanel/AttributeSimilarityFilterSection';
import ButtonFilter from './RecordPairsFilterPanel/ButtonFilter';
import FilterSection from './RecordPairsFilterPanel/FilterSection';
import HasCommentsFilterSection from './RecordPairsFilterPanel/HasCommentsFilterSection';
import HighImpactFilterSection from './RecordPairsFilterPanel/HighImpactFilterSection';
import ResponseFilterSection from './RecordPairsFilterPanel/ResponseFilterSection';
import SelectFilter from './RecordPairsFilterPanel/SelectFilter';
import {
  RESET_FILTERS,
  SET_BOTTOM_ROW_DATASET_NAME,
  SET_DATASET_FILTER_SEARCH_VALUE,
  SET_DATASET_FILTER_SELECTION_DELTA,
  SET_SELECTED_DATASETS,
  SET_TOP_AND_BOTTOM_ROW_DATASET_NAMES,
  SET_TOP_ROW_DATASET_NAME,
  TOGGLE_DATASET_SELECTOR,
} from './RecordPairsActionTypes';

const RecordPairsFilterPanel = _.compose(
  connect(({
    allSourceDatasets: { datasets },
    recordPairs: { bottomRowDatasetName, datasetFilterSearchValue, datasetFilterSelectionDelta, noFiltersOn, selectedDatasetNames, topRowDatasetName, showDatasetSelector },
  }) => {
    return { datasets, bottomRowDatasetName, datasetFilterSearchValue, datasetFilterSelectionDelta, noFiltersOn, selectedDatasetNames, topRowDatasetName, showDatasetSelector };
  }, {
    onClearFilters: () => ({ type: RESET_FILTERS }),
    onSetBottomRowDatasetName: (datasetName) => ({ type: SET_BOTTOM_ROW_DATASET_NAME, datasetName }),
    onSetTopRowDatasetName: (datasetName) => ({ type: SET_TOP_ROW_DATASET_NAME, datasetName }),
    onSetTopAndBottomRowDatasetNames: (top, bottom) => ({ type: SET_TOP_AND_BOTTOM_ROW_DATASET_NAMES, top, bottom }),
    onSetSelectedDatasets: (selectedDatasetNames) => ({ type: SET_SELECTED_DATASETS, selectedDatasetNames }),
    toggleDatasetSelector: () => ({ type: TOGGLE_DATASET_SELECTOR }),
    onHide: () => ({ type: TOGGLE_DATASET_SELECTOR }),
    setSelectionDelta: (newDeltaMap) => ({ type: SET_DATASET_FILTER_SELECTION_DELTA, newDeltaMap }),
    setSearchValue: (searchValue) => ({ type: SET_DATASET_FILTER_SEARCH_VALUE, searchValue }),
  }),
)(class RecordPairsFilterPanel extends React.Component {
  static propTypes = {
    bottomRowDatasetName: PropTypes.string.isRequired,
    datasetFilterSearchValue: PropTypes.string.isRequired,
    datasetFilterSelectionDelta: ImmutablePropTypes.mapOf(PropTypes.bool, PropTypes.string).isRequired,
    datasets: ImmutablePropTypes.listOf(Document.propType.withDataType(Dataset)).isRequired,
    noFiltersOn: PropTypes.bool,
    onClearFilters: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    onSetBottomRowDatasetName: PropTypes.func.isRequired,
    onSetSelectedDatasets: PropTypes.func.isRequired,
    onSetTopAndBottomRowDatasetNames: PropTypes.func.isRequired,
    onSetTopRowDatasetName: PropTypes.func.isRequired,
    selectedDatasetNames: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
    setSearchValue: PropTypes.func.isRequired,
    setSelectionDelta: PropTypes.func.isRequired,
    showDatasetSelector: PropTypes.bool.isRequired,
    toggleDatasetSelector: PropTypes.func.isRequired,
    topRowDatasetName: PropTypes.string.isRequired,
  };

  getAllDatasets = () => {
    return this.props.datasets.map(dataset => dataset.data.name).toSet();
  };

  getSelectedDatasets = () => {
    return (this.props.selectedDatasetNames.isEmpty())
      ? this.getAllDatasets()
      : this.props.selectedDatasetNames;
  };

  getRowSelectorOptions = (isTop) => {
    const selected = this.getSelectedDatasets();

    let options = [];
    options.push({
      display: 'Any source',
      value: PairsDatasetFilter.ANY_DATASET,
    });

    const toAdd = this.props.datasets
      .filter(dataset => selected.has(dataset.data.name))
      .map(dataset => ({
        display: dataset.data.name,
        value: dataset.data.name,
      }),
      ).toJS();

    if (isTop) {
      options = options.concat(toAdd);
    } else if (this.props.topRowDatasetName !== PairsDatasetFilter.ANY_DATASET) {
      if (selected.size !== 1) {
        options.push({
          display: 'Any other source',
          value: PairsDatasetFilter.ANY_OTHER_DATASET,
        });
      }

      options = options.concat(toAdd);
    }

    return options;
  };

  setTopRowDataset = (selection) => {
    if (selection === PairsDatasetFilter.ANY_DATASET) {
      this.props.onSetBottomRowDatasetName(PairsDatasetFilter.ANY_DATASET);
    }
    this.props.onSetTopRowDatasetName(selection);
  };

  setBottomRowDataset = (selection) => {
    this.props.onSetBottomRowDatasetName(selection);
  };

  setSelectedDatasets = (delta) => {
    const datasets = this.getSelectedDatasets();

    const toAdd = delta.filter(present => present)
      .keySeq().toSet();

    const toDel = delta.filter(present => !present)
      .keySeq().toSet();

    const newDatasets = datasets.union(toAdd).subtract(toDel);

    let topRowDataset = this.props.topRowDatasetName;
    let bottomRowDataset = this.props.bottomRowDatasetName;

    if (topRowDataset !== PairsDatasetFilter.ANY_DATASET && !newDatasets.has(topRowDataset)) {
      topRowDataset = PairsDatasetFilter.ANY_DATASET;
      bottomRowDataset = PairsDatasetFilter.ANY_DATASET;
    }

    if (bottomRowDataset !== PairsDatasetFilter.ANY_DATASET &&
      bottomRowDataset !== PairsDatasetFilter.ANY_OTHER_DATASET &&
      !newDatasets.has(bottomRowDataset)
    ) {
      bottomRowDataset = PairsDatasetFilter.ANY_DATASET;
    }

    this.props.onSetTopAndBottomRowDatasetNames(topRowDataset, bottomRowDataset);

    this.props.onSetSelectedDatasets(
      newDatasets.isEmpty()
        ? this.getAllDatasets()
        : newDatasets,
    );
  };

  renderDatasetDialog = () => {
    return (
      <DatasetSelectionDialog
        onSubmit={this.setSelectedDatasets}
        onHide={this.props.onHide}
        setSelectionDelta={this.props.setSelectionDelta}
        setSearchValue={this.props.setSearchValue}
        showDialog={this.props.showDatasetSelector}
        searchValue={this.props.datasetFilterSearchValue}
        datasetSelectionDelta={this.props.datasetFilterSelectionDelta}
        selectedDatasets={this.getSelectedDatasets()}
    />
    );
  };

  renderClearFilterButton = () => {
    return (
      <div className={style.clearFilterContainer}>
        <Button
          buttonType="Link"
          disabled={this.props.noFiltersOn}
          onClick={this.props.onClearFilters}
    >
          Clear all
        </Button>
      </div>
    );
  };

  renderDatasetSelectorPanel = () => {
    const allDatasets = this.getAllDatasets();
    const selected = this.getSelectedDatasets();

    const numberMsg = allDatasets.size === selected.size ? 'All' : selected.size;
    const noun = pluralize(numberMsg === 'All' ? 2 : numberMsg, 'source', 'sources');
    const buttonLabel = `${numberMsg} ${noun}`;

    return (
      <ButtonFilter
        label={<span>Show <Term>pairs</Term> from</span>}
        buttonLabel={buttonLabel}
        onClick={this.props.toggleDatasetSelector}
    />
    );
  };

  render() {
    return (
      <div>
        {this.renderClearFilterButton()}
        <FilterSection className="pairs-section" titleLabel={<Term>Pairs</Term>}>
          <HighImpactFilterSection />
          <HasCommentsFilterSection />
        </FilterSection>
        <FilterSection titleLabel="Assignment">
          <AssignedToMeFilter />
          <AllAssignmentsFilter />
        </FilterSection>
        <ResponseFilterSection />
        <FilterSection titleLabel="Sources">
          {this.renderDatasetDialog()}
          {this.renderDatasetSelectorPanel()}
          <SelectFilter
            onChange={this.setTopRowDataset}
            label="Top row from"
            values={this.getRowSelectorOptions(true)}
            value={this.props.topRowDatasetName}
          />
          <SelectFilter
            onChange={this.setBottomRowDataset}
            label="Bottom row from"
            values={this.getRowSelectorOptions(false)}
            value={this.props.bottomRowDatasetName}
          />
        </FilterSection>
        <AttributeSimilarityFilterSection />
      </div>
    );
  }
});

export default RecordPairsFilterPanel;
