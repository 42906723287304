import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import CenterContent from '../components/CenterContent';
import ConfusionMatrixLoader from '../pairs/ConfusionMatrixLoader';
import RecordPairsLoader from '../pairs/RecordPairsLoader';
import AllUnifiedAttributesLoader from '../schema-mapping/AllUnifiedAttributesLoader';
import SourceAttributesLoader from '../schema-mapping/SourceAttributesLoader';
import SuppliersLoader from '../suppliers/SuppliersLoader';
import { selectActiveProjectInfo } from '../utils/Selectors';
import ClustersCard from './dedup/ClustersCard';
import PairLabelsCard from './dedup/PairLabelsCard';
import SuggestedLabelCountsLoader from './dedup/SuggestedLabelCountsLoader';
import SuggestedPairLabelsCard from './dedup/SuggestedPairLabelsCard';
import TopClustersByCountCard from './dedup/TopClustersByCountCard';
import TopClustersBySpendCard from './dedup/TopClustersBySpendCard';
import TopClustersLoader from './dedup/TopClustersLoader';
import DatasetsCard from './schema-mapping/DatasetsCard';
import FieldsCard from './schema-mapping/FieldsCard';

const DedupDashboard = _.compose(
  connect(state => {
    return { projectInfo: selectActiveProjectInfo(state) };
  }),
)(({ projectInfo }) => {
  const { smRecipeId, recipeId } = projectInfo;
  return (
    <CenterContent className="project-dashboard" vertically={false}>
      <div className="cards-container">
        <SuppliersLoader pane="top" />
        <RecordPairsLoader />
        <ConfusionMatrixLoader />
        <SuggestedLabelCountsLoader />
        <TopClustersLoader />
        <SourceAttributesLoader />
        <AllUnifiedAttributesLoader />
        <div className="left-column">
          <DatasetsCard smRecipeId={smRecipeId} />
          <FieldsCard smRecipeId={smRecipeId} />
          <ClustersCard dedupRecipeId={recipeId} />
          <TopClustersByCountCard dedupRecipeId={recipeId} />
        </div>
        <div className="right-column">
          <PairLabelsCard dedupRecipeId={recipeId} />
          <SuggestedPairLabelsCard dedupRecipeId={recipeId} />
          <TopClustersBySpendCard dedupRecipeId={recipeId} />
        </div>
      </div>
    </CenterContent>
  );
});

export default DedupDashboard;
