import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import { attributeId } from '../models/AttributeId';
import SourceAttribute from '../models/SourceAttribute';
import { pluralize } from '../utils/Strings';
import style from './ConfirmDialog.module.scss';
import ConfirmRemoveMappingsTable from './ConfirmRemoveMappingsTable';
import { HIDE_BULK_DO_NOT_MAP_DIALOG } from './SchemaMappingActionTypes';
import { doBulkDoNotMap } from './SchemaMappingAsync';

const ConfirmBulkDoNotMapDialog = _.compose(
  connect(({ schemaMapping }) => {
    const { sourceSelectedIds, showDoNotMapWarning, sourceAttributes } = schemaMapping;
    const selectedSourceAttributes = sourceAttributes.filter(sa => sourceSelectedIds.has(attributeId(sa)));
    const mappedSelectedSourceAttributes = selectedSourceAttributes.filter(sa => !sa.mappedAttributes.isEmpty());
    return {
      mappedSelectedSourceAttributes,
      selectedSourceAttributes,
      showWarning: showDoNotMapWarning,
    };
  }, {
    onHide: () => ({ type: HIDE_BULK_DO_NOT_MAP_DIALOG }),
    onAccept: doBulkDoNotMap,
  }),
)(class ConfirmBulkDoNotMapDialog extends React.Component {
  static propTypes = {
    mappedSelectedSourceAttributes: ImmutablePropTypes.listOf(PropTypes.instanceOf(SourceAttribute)),
    onAccept: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    selectedSourceAttributes: ImmutablePropTypes.listOf(PropTypes.instanceOf(SourceAttribute)),
    showWarning: PropTypes.bool.isRequired,
  };

  render() {
    const { showWarning, onHide, onAccept, selectedSourceAttributes, mappedSelectedSourceAttributes } = this.props;
    const numMapped = mappedSelectedSourceAttributes.size;

    const description = numMapped > 0 ? (
      <span>
        {pluralize(
          numMapped,
          <span><span className={style.attributeName}>{mappedSelectedSourceAttributes.first().name}</span> is</span>,
          `${numMapped} of the source attributes you selected are`,
        )}
        &nbsp;currently mapped. Setting {numMapped === 1 ? 'this' : 'these'} to "Do Not Map" will
        disable mapping, removing the following existing mappings:
      </span>)
      : `Set the selected ${selectedSourceAttributes.size} ${pluralize(selectedSourceAttributes.size, 'attribute', 'attributes')} to "Do Not Map"?`;

    return (
      <Dialog
        className={style.dialog}
        show={showWarning}
        onHide={onHide}
        body={
          <div>
            <div className={style.description1}>{description}</div>
            <div className={style.tableContainer}>
              <ConfirmRemoveMappingsTable sourceAttributes={selectedSourceAttributes} />
            </div>
            <div className={style.description2}>
              {numMapped > 0 ? 'Are you sure you want to continue, removing these mappings?' : ''}
            </div>
          </div>
        }
        footer={
          <ButtonToolbar>
            <Button buttonType="Secondary" onClick={onHide}>Cancel</Button>
            <Button buttonType="Primary" onClick={onAccept}>
              Do Not Map (Remove Existing Mappings)
            </Button>
          </ButtonToolbar>
        }
        title="Do Not Map"
        dialogStyle={DialogStyle.PRIMARY}
        closeButton
      />
    );
  }
});

export default ConfirmBulkDoNotMapDialog;
