import { ArgTypes } from '../utils/ArgValidation';
import { checkTypesAndCast } from '../utils/CheckTypesAndCast';
import { Maybe } from '../utils/typescript';

// com.tamr.recipe.models.flows.goldenRecords.FilterEmpties

export const TYPE = 'FilterEmpties';

// These fields are not actually optional from the perspective of the server,
//   but they are optional here to help the form UX for building filters
export interface FilterEmpties {
  type: typeof TYPE
  inputAttributeName: Maybe<string>
}
const argTypes = {
  type: ArgTypes.eq(TYPE as typeof TYPE),
  inputAttributeName: ArgTypes.orUndefined(ArgTypes.string),
} as const;
export const fromJSON = checkTypesAndCast<FilterEmpties, typeof argTypes>(argTypes);

export const argType = ArgTypes.object.withShape(argTypes);

export function isValid(filter: FilterEmpties) {
  return !!filter.inputAttributeName;
}
