import { List, Map } from 'immutable';
import $ from 'jquery';
import uri from 'urijs';

import SortState from '../../constants/SortState';
import { SHOW } from '../../errorDialog/ErrorDialogActionTypes';
import { selectActiveProjectInfo } from '../../utils/Selectors';
import SortUtils from '../../utils/SortUtils';
import { ClusterTotal, SuggestedLabelCounts } from './DedupDashboardStore';

const fetchSuggestedLabelCount = (dispatch, unifiedDatasetName, stateFieldName, unitRange) => {
  const params = {
    offset: 0,
    limit: 0,
    suggestedLabelConfidence: unitRange,
  };
  return $.ajax({
    url: uri(SERVICES.dedup(`/pairs/${unifiedDatasetName}`)).query(params),
    method: 'GET',
  });
};

export const fetchSuggestedLabelsByConfidence = () => (dispatch, getState) => {
  dispatch({ type: 'DedupDashboard.fetchSuggestedLabelsByConfidence' });
  const state = getState();
  const { config: { pairConfidenceThresholds } } = state;
  const { unifiedDatasetName } = selectActiveProjectInfo(state);
  const ajaxCalls = [
    fetchSuggestedLabelCount(dispatch, unifiedDatasetName, 'total'),
    fetchSuggestedLabelCount(dispatch, unifiedDatasetName, 'highConfidence', pairConfidenceThresholds.highRange),
    fetchSuggestedLabelCount(dispatch, unifiedDatasetName, 'mediumConfidence', pairConfidenceThresholds.mediumRange),
    fetchSuggestedLabelCount(dispatch, unifiedDatasetName, 'lowConfidence', pairConfidenceThresholds.lowRange),
  ];
  $.when(...ajaxCalls)
    .done(([totalPage], [highConfidencePage], [mediumConfidencePage], [lowConfidencePage]) => {
      dispatch({
        type: 'DedupDashboard.fetchSuggestedLabelsByConfidenceCompleted',
        suggestedLabels: new SuggestedLabelCounts({
          total: totalPage.total,
          highConfidence: highConfidencePage.total,
          mediumConfidence: mediumConfidencePage.total,
          lowConfidence: lowConfidencePage.total,
        }),
        loadedSuggestedLabelsForThresholds: pairConfidenceThresholds,
      });
    })
    .fail((response) => {
      dispatch({ type: SHOW,
        detail: 'Error fetching suggested pair label counts',
        response,
      });
    });
};

const MAX_CLUSTERS_TO_SHOW = 5;


const fetchTopClustersByField = (clusterFieldName, unifiedDatasetName) => {
  const params = {
    offset: 0,
    limit: MAX_CLUSTERS_TO_SHOW,
    sort: SortUtils.getUrlSortStates(new Map({ [clusterFieldName]: SortState.SORTED_DESCENDING })),
  };
  return $.ajax({
    url: uri(SERVICES.dedup(`/clusters/${unifiedDatasetName}`)).query(params),
    method: 'GET',
    cache: false,
  });
};

export const fetchTopClusters = () => (dispatch, getState) => {
  const { unifiedDatasetName } = selectActiveProjectInfo(getState());
  dispatch({ type: 'DedupDashboard.fetchTopClusters' });
  $.when(
    fetchTopClustersByField('recordCount', unifiedDatasetName),
    fetchTopClustersByField('totalSpend', unifiedDatasetName),
  ).then(([recordCountPage], [totalSpendPage]) => {
    const clusterTotals = Map({
      recordCount: recordCountPage,
      totalSpend: totalSpendPage,
    }).map((page, clusterFieldName) => {
      return List(page.items.map(item => {
        const { name } = item;
        const value = item[clusterFieldName];
        return new ClusterTotal({ name, value });
      }));
    });
    dispatch({
      type: 'DedupDashboard.fetchTopClustersCompleted',
      totalClusters: recordCountPage.total,
      topClustersByCount: clusterTotals.get('recordCount'),
      topClustersBySpend: clusterTotals.get('totalSpend'),
    });
  }).fail((response) => {
    dispatch({ type: SHOW, detail: 'Error fetching clusters', response });
  });
};
