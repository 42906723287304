import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import { attributeId } from '../models/AttributeId';
import SourceAttribute from '../models/SourceAttribute';
import { getUnifiedDatasetName } from '../utils/Selectors';
import { pluralize } from '../utils/Strings';
import { getPath } from '../utils/Values';
import style from './ConfirmDialog.module.scss';
import ConfirmRemoveMappingsTable from './ConfirmRemoveMappingsTable';
import { HIDE_CONFIRM_BULK_RECS_DIALOG } from './SchemaMappingActionTypes';
import { bulkMapRecommendations } from './SchemaMappingAsync';
import { getBulkRecommendChanges } from './SchemaMappingStore';

const ConfirmBulkAcceptDialog = _.compose(
  connect(state => {
    const { schemaMapping: { sourceSelectedIds, showConfirmBulkRecsWarning, sourceAttributes } } = state;
    const showWarning = !!showConfirmBulkRecsWarning;
    const mapToTop = !!getPath(showConfirmBulkRecsWarning, 'mapToTop');
    const unifiedDatasetName = getUnifiedDatasetName(state);
    const selectedSourceAttributes = sourceAttributes.filter(sa => sourceSelectedIds.has(attributeId(sa)));
    const { toDnm, conflicts, mappedButRecommendedDnm } =
      getBulkRecommendChanges(selectedSourceAttributes, unifiedDatasetName);

    return {
      toDnm,
      conflicts,
      mappedButRecommendedDnm,
      showWarning,
      mapToTop,
    };
  }, {
    onHide: () => ({ type: HIDE_CONFIRM_BULK_RECS_DIALOG }),
    onAccept: bulkMapRecommendations,
  }),
)(class ConfirmBulkAcceptDialog extends React.Component {
  static propTypes = {
    conflict: ImmutablePropTypes.setOf(PropTypes.instanceOf(SourceAttribute)),
    mapToTop: PropTypes.bool.isRequired,
    mappedButRecommendedDnm: ImmutablePropTypes.listOf(PropTypes.instanceOf(SourceAttribute)),
    onAccept: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    selectedSourceAttributes: ImmutablePropTypes.listOf(PropTypes.instanceOf(SourceAttribute)),
    showWarning: PropTypes.bool.isRequired,
    toDnm: ImmutablePropTypes.listOf(PropTypes.instanceOf(SourceAttribute)),
  };

  render() {
    const { showWarning, onHide, onAccept, conflicts, mapToTop, mappedButRecommendedDnm, toDnm } = this.props;
    const numConflicts = conflicts.size;
    const numDnm = mappedButRecommendedDnm.size;
    const description = numDnm > 0 ? (
      <span>
        {pluralize(
          numDnm,
          <span><span className={style.attributeName}>{mappedButRecommendedDnm.first().name}</span> is</span>,
          `${numDnm} of the source attributes you selected are`,
        )}
        &nbsp;currently mapped. Setting {numDnm === 1 ? 'this' : 'these'} to "Do Not Map" will
        disable mapping, removing the following existing mappings:
      </span>)
      : `Set the selected ${toDnm.size} ${pluralize(toDnm.size, 'attribute', 'attributes')} to "Do Not Map"?`;

    return (
      <Dialog
        className={style.dialog}
        show={showWarning}
        onHide={onHide}
        body={
          <div>
            {numConflicts > 0 ? (
              <div className={style.description1}>
                <p>
                  Some of your attributes are suggested to map to both another attribute and be set to Do Not Map. Attributes will be mapped to option with the higher similarity, and the other suggestions may be ignored.
                </p>
                <p>
                  If an attribute has higher similarity, then all of the suggested attributes will be mapped and Do Not Map will be ignored.
                </p>
                <p>
                  If Do Not Map has a higher similarity, then none of the suggested attributes will be mapped and any current mappings will be removed. {numDnm > 0 ? 'The following attributes are mapped, but Do Not Map is the highest suggestion.' : ''}
                </p>
              </div>) : null}
            {numDnm > 0 ? (
              <div>
                {numConflicts === 0 ? <div className={style.description1}>{description}</div> : null}
                <div className={style.tableContainer}>
                  <ConfirmRemoveMappingsTable sourceAttributes={mappedButRecommendedDnm} />
                </div>
              </div>) : null}
            {numConflicts ? <div className={style.description2}>Would you like to map to the most similar option?</div> :
              (numDnm ? <div className={style.description2}>Are you sure you want to continue, removing these mappings?</div> : null)}
          </div>
        }
        footer={
          <ButtonToolbar>
            <Button buttonType="Secondary" onClick={onHide}>Cancel</Button>
            <Button buttonType="Primary" onClick={_.partial(onAccept, { mapToTop, ignoreWarnings: true })}>{numDnm > 0 ? (numConflicts > 0 ? 'Map' : 'Do Not Map (Remove Existing Mappings)') : 'Map'}</Button>
          </ButtonToolbar>
        }
        title={numConflicts > 0 ? 'Conflicting Suggestions' : 'Do Not Map'}
        dialogStyle={DialogStyle.PRIMARY}
        closeButton
      />
    );
  }
});

export default ConfirmBulkAcceptDialog;
