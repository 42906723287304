// com.tamr.rest.client.ApiExceptionRepresentation

import { isNumber, isString } from 'underscore';

import { isArrayOf, isObject } from '../utils/Values';


export interface ApiException {
  service: string
  status: number
  class: string
  message: string
  stackTrace: string[]
  causedBy?: ApiException | undefined | null
}

export function isApiException(data: unknown): data is ApiException {
  if (!isObject(data)) return false;
  if (!isString(data.service)) return false;
  if (!isNumber(data.status)) return false;
  if (!isString(data.class)) return false;
  if (!isString(data.message)) return false;
  if (!isArrayOf(data.stackTrace, isString)) return false;
  if (data.causedBy !== undefined && data.causedBy !== null && !isApiException(data.causedBy)) {
    return false;
  }
  return true;
}
