import { List } from 'immutable';
import $ from 'jquery';
import uri from 'urijs';

import { doFetchProfiling } from '../api/TransformClient';
import { SCHEMA_MAPPING } from '../constants/RecipeType';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import { selectActiveProjectWithStatus } from '../utils/Selectors';
import { getFilterInfo, LIMIT } from './AllSourceDatasetsStore';

export const fetchAll = () => (dispatch, getState) => {
  dispatch({ type: 'AllSourceDatasets.fetchAll' });
  const state = getState();
  const activePWS = selectActiveProjectWithStatus(getState());
  const filterInfo = getFilterInfo(state.allSourceDatasets, activePWS.project.id.id);
  const smRWS = activePWS.recipes.find(rws => rws.recipe.data.type === SCHEMA_MAPPING);
  const smRecipeId = smRWS.recipe.id.id;
  const query = { limit: LIMIT };
  $.ajax({
    url: uri(SERVICES.recipe(`/datasets/${smRecipeId}`)).query(query),
    dataType: 'json',
    method: 'GET',
    contentType: 'application/json',
    success(datasetsPage) {
      const datasets = new List(datasetsPage.items).map(doc => Document.fromJSON(doc, Dataset.fromJSON));
      const datasetNames = datasets.map(doc => doc.data.name).toSet();
      doFetchProfiling(datasetNames).then((profiling) => {
        dispatch({ type: 'AllSourceDatasets.fetchAllCompleted', datasets, profiling, loadedFilterInfo: filterInfo });
      });
    },
  });
};
