import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import Term from '../components/Term';
import TooltipTrigger from '../components/TooltipTrigger';
import { AppState } from '../stores/MainStore';

const RecordPairsPagerContent = connect(({ recordPairs: { pendingResolution } }: AppState) => {
  return { somePendingResolution: !pendingResolution.isEmpty() };
}, {
  onRefreshPairs: () => ({ type: 'RecordPairs.refreshPairs' }),
})(({ somePendingResolution, onRefreshPairs }) => {
  return (
    <div className="record-pairs-pager-content">
      {somePendingResolution && (
        <TooltipTrigger placement="top" content={<span>Reload page to remove <Term>pairs</Term> no longer matching your filters</span>}>
          <Button
            buttonType="Secondary"
            className="refresh-button"
            onClick={onRefreshPairs}
          >
            Reload
          </Button>
        </TooltipTrigger>
      )}
    </div>
  );
});

export default RecordPairsPagerContent;
