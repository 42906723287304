import { List, Map } from 'immutable';

import SimilarityFunction from '../schema-mapping/constants/SimilarityFunction';
import TokenizerConfig from '../schema-mapping/constants/TokenizerConfig';
import TokenWeighting from '../schema-mapping/constants/TokenWeighting';
import VerificationType from '../suppliers/VerificationType';
import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import Dnf from './Dnf';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';
import PreGroupBy from './PreGroupBy';

// com.tamr.dedup.models.DedupInfo
class DedupInfo extends getModelHelpers({
  unifiedDatasetName: { type: ArgTypes.string },
  nameField: { type: ArgTypes.orUndefined(ArgTypes.nullable(ArgTypes.string)) },
  visibleFields: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
  includedFields: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
  sortTypes: { type: ArgTypes.Immutable.map.of(ArgTypes.string, ArgTypes.string) },
  signalTypes: { type: ArgTypes.Immutable.map.of(ArgTypes.valueIn(SimilarityFunction), ArgTypes.string) },
  tokenizerConfig: { type: ArgTypes.Immutable.map.of(ArgTypes.valueIn(TokenizerConfig), ArgTypes.string) },
  tokenWeightings: { type: ArgTypes.Immutable.map.of(ArgTypes.valueIn(TokenWeighting), ArgTypes.string) },
  dnf: { type: ArgTypes.orUndefined(ArgTypes.nullable(ArgTypes.instanceOf(Dnf))) },
  previousDnf: { type: ArgTypes.orUndefined(ArgTypes.nullable(ArgTypes.instanceOf(Dnf))) },
  noDedupWithinSources: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
  disableIncrementalDedup: { type: ArgTypes.orUndefined(ArgTypes.nullable(ArgTypes.bool)) },
  minRelativeClusterOverlap: { type: ArgTypes.orUndefined(ArgTypes.nullable(ArgTypes.number)) },
  publishedClusterTtl: { type: ArgTypes.orUndefined(ArgTypes.nullable(ArgTypes.string)) },
  recordSampleSize: { type: ArgTypes.orUndefined(ArgTypes.nullable(ArgTypes.number)) },
  noClusteringWithinSources: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
  sparkConfigOverrides: { type: ArgTypes.orUndefined(ArgTypes.nullable(ArgTypes.string)) },
  matchSparkConfigOverrides: { type: ArgTypes.orUndefined(ArgTypes.nullable(ArgTypes.string)) },
  idfTrimTokensFrequencyThreshold: { type: ArgTypes.orUndefined(ArgTypes.nullable(ArgTypes.number)) },
  preGroupBy: { type: ArgTypes.orUndefined(ArgTypes.nullable(ArgTypes.instanceOf(PreGroupBy))) },
  trainingVerificationTypes: { type: ArgTypes.Immutable.list.of(ArgTypes.valueIn(VerificationType)) },
  maxInferredPairsPerCluster: { type: ArgTypes.orUndefined(ArgTypes.nullable(ArgTypes.number)) },
  numberOfTrainingClusters: { type: ArgTypes.orUndefined(ArgTypes.nullable(ArgTypes.number)) },
  numberOfNewTestRecords: { type: ArgTypes.orUndefined(ArgTypes.nullable(ArgTypes.number)) },
  maxNumberOfPendingTestRecords: { type: ArgTypes.orUndefined(ArgTypes.nullable(ArgTypes.number)) },
  pairsSampleSize: { type: ArgTypes.orUndefined(ArgTypes.nullable(ArgTypes.number)) },
  numberOfHistogramBins: { type: ArgTypes.orUndefined(ArgTypes.nullable(ArgTypes.number)) },
  dnfEstimationPairSampleSize: { type: ArgTypes.orUndefined(ArgTypes.nullable(ArgTypes.number)) },
  disableEs: { type: ArgTypes.orUndefined(ArgTypes.nullable(ArgTypes.bool)) },
  newStratumThreshold: { type: ArgTypes.orUndefined(ArgTypes.nullable(ArgTypes.number)) },
  datasetRenames: { type: ArgTypes.orUndefined(ArgTypes.nullable(ArgTypes.Immutable.map.of(ArgTypes.string, ArgTypes.string))) },
}, 'DedupInfo')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class DedupInfoRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(DedupInfo); }

  static fromJSON(obj: $TSFixMe) {
    return new DedupInfo({
      unifiedDatasetName: obj.unifiedDatasetName,
      nameField: obj.nameField,
      visibleFields: List(obj.visibleFields),
      includedFields: List(obj.includedFields),
      sortTypes: Map(obj.sortTypes),
      signalTypes: Map(obj.signalTypes),
      tokenizerConfig: Map(obj.tokenizerConfig),
      tokenWeightings: Map(obj.tokenWeightings),
      dnf: obj.dnf ? Dnf.fromJSON(obj.dnf) : undefined,
      previousDnf: obj.previousDnf ? Dnf.fromJSON(obj.previousDnf) : undefined,
      noDedupWithinSources: List(obj.noDedupWithinSources),
      disableIncrementalDedup: obj.disableIncrementalDedup,
      minRelativeClusterOverlap: obj.minRelativeClusterOverlap,
      publishedClusterTtl: obj.publishedClusterTtl,
      recordSampleSize: obj.recordSampleSize,
      noClusteringWithinSources: List(obj.noClusteringWithinSources),
      sparkConfigOverrides: obj.sparkConfigOverrides,
      idfTrimTokensFrequencyThreshold: obj.idfTrimTokensFrequencyThreshold,
      preGroupBy: obj.preGroupBy ? PreGroupBy.fromJSON(obj.preGroupBy) : undefined,
      trainingVerificationTypes: List(obj.trainingVerificationTypes),
      maxInferredPairsPerCluster: obj.maxInferredPairsPerCluster,
      numberOfTrainingClusters: obj.numberOfTrainingClusters,
      numberOfNewTestRecords: obj.numberOfNewTestRecords,
      maxNumberOfPendingTestRecords: obj.maxNumberOfPendingTestRecords,
      pairsSampleSize: obj.pairsSampleSize,
      numberOfHistogramBins: obj.numberOfHistogramBins,
      dnfEstimationPairSampleSize: obj.dnfEstimationPairSampleSize,
      disableEs: obj.disableEs,
      newStratumThreshold: obj.newStratumThreshold,
      datasetRenames: obj.datasetRenames,
    });
  }
}

export default DedupInfo;
