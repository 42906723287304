import { List } from 'immutable';
import React from 'react';

import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';

const DatasetDescriptionColumn: React.FC<{
  rows: List<Document<Dataset>>
  width: number
}> = ({ rows, width }) => {
  return (
    <Column
      key="description"
      width={width}
      isResizable
      columnKey="description"
      flexGrow={1}
      header={<Cell>Description</Cell>}
      cell={({ rowIndex }) => {
        const description = rows.get(rowIndex)?.data.description;
        return <Cell><span title={description || undefined}>{description}</span></Cell>;
      }}
    />
  );
};

export default DatasetDescriptionColumn;
