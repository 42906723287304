import React from 'react';
import { connect } from 'react-redux';
import TamrIcon from '../components/TamrIcon';
import { SHOW_SIMPLE } from '../errorDialog/ErrorDialogActionTypes';
import AppState from '../stores/AppState';
import { dispatch } from '../stores/MainStore';
import style from './StorageSpaceItem.module.scss';

const StorageSpaceItem = connect((state: AppState) => {
  const { chrome: { storageHealthStatus } } = state;
  return { storageHealthStatus };
}, {
})(({ storageHealthStatus }) => {
  if (storageHealthStatus === undefined || storageHealthStatus.storageSpace.healthy) {
    return null;
  }
  return (

    <div id="StorageSpaceStatus" style={{ padding: '5px', alignSelf: 'center' }}>

      <button
        onClick={() => {
          dispatch({ type: SHOW_SIMPLE, title: storageHealthStatus.storageSpace.message ?? 'Storage health problem', detail: JSON.stringify(storageHealthStatus.storageSpace.details, null, 4) ?? 'No details available' });
        }}
        className={style.storageItemButton}
        // style={{ borderColor: 'red', color: 'red', backgroundColor: 'transparent', borderRadius: '8px', display: 'flex' }}
        >
        <TamrIcon
          style={{
            marginRight: '5px',
          }}
          iconName="warning"
          size={20} /> View failed system healthcheck
      </button>

    </div>

  );
});

export default StorageSpaceItem;
