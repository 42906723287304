import localforage from 'localforage';
import { connect } from 'react-redux';
import { createSink, pure } from 'recompose';
import _ from 'underscore';

import { selectActiveProjectInfo } from '../utils/Selectors';
import StoredTransformsState, { storedTransformsStateKey } from './models/StoredTransformsState';

/**
 * Stores transforms edit state in local storage
 */
const TransformsBrowserStorage = _.compose(
  connect((state) => {
    const { transforms: { transforms, deltas, loadedDatasetId }, records: { useLocalStorage } } = state;
    const projectInfo = selectActiveProjectInfo(state);
    const { projectId } = projectInfo;
    const projectTimestamp = projectInfo?.projectWithStatus?.project?.created?.timestamp;
    return { transforms, deltas, projectId, projectTimestamp, loadedDatasetId, useLocalStorage };
  }),
  pure,
)(createSink(({ transforms, deltas, projectId, loadedDatasetId, projectTimestamp, useLocalStorage }) => {
  if (!_.isNumber(loadedDatasetId) || !useLocalStorage) {
    return;
  }
  if (deltas.isEmpty()) {
    localforage.removeItem(storedTransformsStateKey(projectTimestamp, projectId));
  } else {
    const stateToStore = new StoredTransformsState({
      unifiedDatasetId: loadedDatasetId,
      transforms,
      deltas,
    });
    localforage.setItem(storedTransformsStateKey(projectTimestamp, projectId), stateToStore.toJSON());
  }
}));


export default TransformsBrowserStorage;
