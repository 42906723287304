import './SearchBox.scss';

import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';

import { $TSFixMe } from '../utils/typescript';
import TamrIcon from './TamrIcon';

const propTypes = {

  // autoFocus this input when it mounts
  autoFocusOnMount: PropTypes.bool,

  className: PropTypes.string,

  /**
   * When search is submitted`onSearch(value)` is called
   */
  onSearch: PropTypes.func.isRequired,

  /**
   * Placeholder for the searchbox input
   */
  placeholder: PropTypes.string,

  /**
   * Whether to trigger callback after each key
   */
  searchOnKeyup: PropTypes.bool,

  /**
   * Current value of the search input
   */
  value: PropTypes.string.isRequired,
};
type SearchBoxProps = InferProps<typeof propTypes>;

type SearchBoxState = {
  value: string,
  focus: boolean
}

class SearchBox extends React.Component<SearchBoxProps, SearchBoxState> {
  static propTypes = propTypes;

  state = {
    value: '',
    focus: false,
  };

  UNSAFE_componentWillMount() {
    this.setState({ value: this.props.value });
  }

  UNSAFE_componentWillReceiveProps(nextProps: SearchBoxProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ value: nextProps.value });
    }
  }

  clearSearch = () => {
    const { onSearch } = this.props;
    this.setState({ value: '' });
    onSearch('');
  };

  handleChange = (e: $TSFixMe) => {
    const { searchOnKeyup, onSearch } = this.props;
    this.setState({ value: e.target.value });
    if (searchOnKeyup) {
      onSearch(e.target.value);
    }
  };

  handleKeyDown = (e: $TSFixMe) => {
    const { value } = this.state;
    const { onSearch } = this.props;
    if (e.keyCode === 27) {
      this.clearSearch();
    } else if (e.keyCode === 13) {
      onSearch(value);
    }
  };

  render() {
    const { value, focus } = this.state;
    const { onSearch, placeholder, className } = this.props;
    return (
      <div className={classNames(className, 'search-component', { focus })}>
        <button className="search-component-button" onClick={() => onSearch(value)}>
          <TamrIcon iconName="tamr-icon-mag-glass" size={18} />
        </button>
        <input
          autoFocus={!!this.props.autoFocusOnMount}
          className="search-component-input"
          placeholder={(placeholder !== undefined ? placeholder : 'Search') || undefined}
          value={value}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onFocus={() => this.setState({ focus: true })}
          onBlur={() => this.setState({ focus: false })}
          style={{ borderRadius: '10px' }}
        />
        <button className={classNames('search-component-clear', { hidden: !value })} onClick={this.clearSearch}>
          <TamrIcon iconName="tamr-icon-close" size={18} />
        </button>
      </div>
    );
  }
}

export default SearchBox;
