import { Set } from 'immutable';
import moment from 'moment';
import React from 'react';
import _ from 'underscore';

import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import actionCellStyle from './DatasetActionCell.module.scss';

const DatasetProfilingStatusColumn = ({ width, rows, currentlyProfilingDatasetNames, profiling, status, onConfirmProfile }) => {
  return (
    <Column
      key="profile"
      width={width}
      columnKey="profile"
      cell={({ rowIndex }) => {
        const dataset = rows.get(rowIndex);
        const datasetName = dataset.data.name;
        const profiledAt = profiling.getIn([datasetName, 'profiledAt']);
        const profiledRevision = profiling.getIn([datasetName, 'revision']);
        const profileUpToDate = !(_.isNumber(profiledRevision) && profiledRevision !== status.get(datasetName).currentRevision);
        const profileTooltip = profiledAt
          ? `Dataset last profiled ${moment.unix(profiledAt.timestamp).format('L [at] hh:mm:ss A')}`
          : 'Dataset has not been profiled';
        return (
          <Cell className={actionCellStyle.actionCell}>
            {currentlyProfilingDatasetNames.has(dataset.data.name) ?
              <div className={actionCellStyle.actionInProgress}>
                Profiling in progress
              </div>
              :
              <div className={actionCellStyle.actionCellLink}>
                <TooltipTrigger placement="bottom" content={profileTooltip}>
                  <a onClick={() => onConfirmProfile(Set.of(dataset.data.name))}>
                    {profiledAt ? 'Re-Profile' : 'Profile'}
                  </a>
                </TooltipTrigger>
                {!profileUpToDate ?
                  <TooltipTrigger placement="bottom" content="Profiling is out of date (dataset has been updated)">
                    <span className={actionCellStyle.actionWarningWrapper}>
                      <TamrIcon iconName="tamr-icon-warning" className={actionCellStyle.actionWarning} size={16} />
                    </span>
                  </TooltipTrigger>
                  : null}
              </div>
            }
          </Cell>
        );
      }}
    />
  );
};

export default DatasetProfilingStatusColumn;
