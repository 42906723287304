import { bindActionCreators } from 'redux';

import createLoader from '../utils/createLoader';
import { fetchClusterPublishInfo } from './SuppliersAsync';
import { getLastPublishTime } from './SuppliersStore';

const ClusterSidebarClusterLoader = createLoader((state, { targetClusterId }) => {
  const { suppliers: { loadingClusterPublishInfo, loadedPublishedClusterId } } = state;
  return {
    canFetch: !!getLastPublishTime(state),
    shouldFetch: targetClusterId !== loadedPublishedClusterId,
    loading: loadingClusterPublishInfo,
  };
}, (dispatch, { targetClusterId }) => bindActionCreators({
  onFetch: () => fetchClusterPublishInfo(targetClusterId),
}, dispatch), 'ClusterSidebarClusterLoader');

export default ClusterSidebarClusterLoader;
