import classnames from 'classnames';
import { List } from 'immutable';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import _, { clone } from 'underscore';

import Button from '../components/Button';
import TamrIcon from '../components/TamrIcon';
import Toolbar from '../components/Toolbar';
import TooltipTrigger from '../components/TooltipTrigger';
import WarningDialog from '../components/WarningDialog';
import { AppDispatch, AppState } from '../stores/MainStore';
import ContentEnvelope from '../transforms/models/ContentEnvelope';
import Lint from '../transforms/models/Lint';
import { OperationListResult } from '../transforms/models/StaticAnalysisModels';
// @ts-expect-error
import { TransformInput } from '../transforms/TransformInput';
// @ts-expect-error
import { selectFunctionNames, selectFunctionTooltipMap } from '../transforms/TransformsStore';
import { merge, set } from '../utils/Collections';
import { FilterClusterRecords } from './FilterClusterRecords';
import FilterElement from './FilterElement';
import { deleteRule, performStaticAnalysisForRule, saveRule } from './GoldenRecordsAsync';
import style from './GoldenRecordsSidebar.module.scss';
import { getNextRuleDelta, getRuleExpressionAnalysisResult, getValidationErrorsForRule, isRuleNameEntityRule, selectAggregationContentEnvelope, selectInputAttributeAsOptions, selectInputAttributesByName } from './GoldenRecordsStore';
import { RuleTypeName, RuleTypeNameE } from './Rule';
import { RuleDelta, RuleDeltaTypes } from './RuleDelta';
import RULES_OPTIONS, { RulesOptionsE } from './RulesOptions';
import SidebarRuleSummary from './SidebarRuleSummary';

type Mutable<T> = {
  -readonly [P in keyof T]: T[P];
};
const EDITABLE_RULES_OPTIONS = RULES_OPTIONS.map(o => clone<Mutable<typeof o>>(o));

type GRInputOwnProps = {
  name: string,
  onChange?: (expression: string) => void,
  contents: string,
  // TODO these can be grabbed from TransformInput once that's TS-ified
  contentEnvelope: ContentEnvelope | null,
  staticAnalysisResult?: OperationListResult,
  lintingErrors: List<Lint>,
  hasNotBeenTypedIntoYet?: boolean
}

// TODO there is a bug with GRInput where lints pointing to outside the ContentEnvelope
//      don't show up properly as lints in the CodeMirror instance
export const GRInput = connect((store: AppState, { name, onChange, contents }: GRInputOwnProps) => {
  const { transforms } = store;
  const functionNames = selectFunctionNames(transforms);
  const functionTooltips = selectFunctionTooltipMap(transforms);
  const unifiedAttributes = selectInputAttributesByName(store.goldenRecords);
  return {
    name,
    contents,
    guid: name,
    isUnifiedTransform: true,
    operationIndex: 0,
    hintNames: transforms.hintNames,
    functionNames,
    functionTooltips,
    unifiedAttributes,
    isFormula: false,
    cursorBlinkRate: !onChange ? -1 : 530,
  };
})(TransformInput);

const SortableFilterElement = SortableElement(FilterElement);

const SortableFilterContainer = SortableContainer(({ ruleName, filters }: { ruleName: string, filters: List<FilterClusterRecords> }) => {
  return (
    <div>
      {filters.map((filter, index) => (
        <SortableFilterElement
          key={`item-${index}`}
          {...{ index, ruleName }}
        />
      ))}
    </div>
  );
});

type EditableGoldenRecordsRuleOwnProps = {
  name: string
}

const mapStateToProps = (state: AppState, { name }: EditableGoldenRecordsRuleOwnProps) => {
  const { goldenRecords, goldenRecords: { expandedRuleNames, nextRules, nextEntityRule } } = state;
  const isEntityRule = isRuleNameEntityRule(goldenRecords, name);
  const isExpanded = expandedRuleNames.has(name);
  const ruleDeltaIndex = isEntityRule ? undefined : nextRules.findIndex((rd) => rd.rule.outputAttributeName === name);
  const ruleDelta = isEntityRule ? nextEntityRule : (_.isNumber(ruleDeltaIndex) && nextRules.get(ruleDeltaIndex));
  const validationErrors = getValidationErrorsForRule(state.goldenRecords, { ruleName: name });
  const staticAnalysisResult = getRuleExpressionAnalysisResult(goldenRecords, { ruleName: name });
  return {
    isEntityRule,
    isExpanded,
    ruleDeltaIndex,
    ruleDelta,
    validationErrors,
    staticAnalysisResult,
    contentEnvelope: selectAggregationContentEnvelope(goldenRecords, name),
    inputAttributesOptions: selectInputAttributeAsOptions(goldenRecords),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch, { name }: EditableGoldenRecordsRuleOwnProps) => ({
  deleteGoldenRule: () => dispatch(
    deleteRule({ ruleName: name })),
  expand: () => dispatch(({ type: 'GoldenRecords.expand', name })),
  onPerformStaticAnalysis: (expression: string) => dispatch(
    performStaticAnalysisForRule({ ruleName: name, expression })),
  updateRule: (ruleDelta: RuleDelta) => dispatch(({ type: 'GoldenRecords.updateRule', ruleDelta })),
  onCancelRule: () => dispatch(({ type: 'GoldenRecords.cancelRule', ruleName: name })),
  onFilterMove: ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => dispatch(({ type: 'GoldenRecords.moveRuleFilter', ruleName: name, oldIndex, newIndex })),
  onFilterAdd: () => dispatch(({ type: 'GoldenRecords.addRuleFilter', ruleName: name })),
  onChangeRuleType: (newType: RuleTypeNameE) => dispatch(({ type: 'GoldenRecords.changeRuleType', ruleName: name, newType })),
  onSaveRule: () => dispatch(
    saveRule({ ruleName: name })),
});

type EditableGoldenRecordsRuleProps
  = EditableGoldenRecordsRuleOwnProps
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>

type EditableGoldenRecordsRuleState = {
  hasNotBeenTypedIntoYet: boolean
  showDeleteDialog: boolean
}

const EditableGoldenRecordsRule = connect(
  mapStateToProps,
  mapDispatchToProps,
)(class EditableGoldenRecordsRule extends PureComponent<EditableGoldenRecordsRuleProps, EditableGoldenRecordsRuleState> {
  state = {
    hasNotBeenTypedIntoYet: true,
    showDeleteDialog: false,
  };

  performStaticAnalysisDebounced = async (expression: string) => {
    const { onPerformStaticAnalysis } = this.props;
    onPerformStaticAnalysis(expression);
    this.setState({ hasNotBeenTypedIntoYet: false });
  };

  handleChangeRule = (rule: { expression?: string, inputAttributeName?: string }) => {
    const { updateRule, ruleDelta } = this.props;
    if (ruleDelta) {
      updateRule(set(getNextRuleDelta(ruleDelta, RuleDeltaTypes.CHANGE), 'rule', merge(ruleDelta.rule, rule)));
      if ('expression' in rule && rule.expression) {
        this.performStaticAnalysisDebounced(rule.expression);
      }
    }
  };

  handleChangeExpr = (expression: string) => this.handleChangeRule({ expression });

  handleInputAttributeName = (option: { value: string }) => this.handleChangeRule({ inputAttributeName: option.value });
  toggleDeleteDialog = () => this.setState({ showDeleteDialog: !this.state.showDeleteDialog });

  render() {
    const { onCancelRule, staticAnalysisResult, validationErrors, isExpanded, ruleDelta, name, contentEnvelope, inputAttributesOptions, isEntityRule, onFilterMove, onFilterAdd, onChangeRuleType, onSaveRule, deleteGoldenRule } = this.props;
    const { showDeleteDialog } = this.state;
    if (!ruleDelta) {
      console.error(`Could not show EditableGoldenRecordsRule for undefined rule detla with name: ${name}`);
      return null;
    }
    const hasErrors = !validationErrors.isEmpty();

    const ruleOption = RULES_OPTIONS.find(option => option.value === ruleDelta.rule.type);
    return (
      <div className={classnames(style.rule)}>
        <SidebarRuleSummary {...{ name, validationErrors }} />
        {isExpanded && (
          <div className={style.ruleDetails}>
            <div className={style.ruleWord}>Rule</div>
            <div className={style.inlineSelect}>
              <span className={style.title}> Rule: </span>
              <Select
                className={style.select}
                clearable={false}
                multi={false}
                value={ruleOption}
                onChange={({ value }: RulesOptionsE) => onChangeRuleType(value)}
                options={EDITABLE_RULES_OPTIONS}
              />
            </div>
            {
              ruleDelta.rule.type === RuleTypeName.Expression
              && <div className={style.textArea}>
                <GRInput
                  name={name}
                  contents={('expression' in ruleDelta.rule && ruleDelta.rule.expression) || ''}
                  onChange={this.handleChangeExpr}
                  contentEnvelope={contentEnvelope}
                  staticAnalysisResult={staticAnalysisResult}
                  lintingErrors={staticAnalysisResult ? staticAnalysisResult.operations.flatMap(o => o.lints) : List()}
                  hasNotBeenTypedIntoYet={this.state.hasNotBeenTypedIntoYet} /> </div>}
            {
              ruleDelta.rule.type !== RuleTypeName.Expression
              && <div className={style.inlineSelect}><span className={style.title}> Input Attribute: </span><Select
                className={style.select}
                clearable={false}
                multi={false}
                value={inputAttributesOptions.find(option => 'inputAttributeName' in ruleDelta.rule && option.value === ruleDelta.rule.inputAttributeName)}
                onChange={this.handleInputAttributeName}
                options={inputAttributesOptions} /></div>}
            {/* Conditions */}
            <div className={style.ruleWord}>Conditions
              <TooltipTrigger content="Conditions narrow down on which set of records to apply your rule. Your rule will be applied after all conditions." placement="top"><TamrIcon size={14} iconName="info-outline" className={classnames('ml-4', style.verticalMiddle)} /></TooltipTrigger>
            </div>
            {ruleDelta.rule.filters.length > 0 && (
              <SortableFilterContainer
                lockAxis="y"
                filters={List(ruleDelta.rule.filters)}
                helperClass={style.dragged}
                useDragHandle
                lockToContainerEdges
                onSortEnd={onFilterMove}
                ruleName={name}
              />
            )}
            <Button className="px-0" buttonType="Link" onClick={onFilterAdd}>+ Add a condition</Button>
            <TooltipTrigger content={`Delete ${ruleDelta.rule.outputAttributeName}`} placement="top">
              <WarningDialog
                actionName="Delete"
                onAccept={deleteGoldenRule}
                onHide={this.toggleDeleteDialog}
                show={showDeleteDialog}
                message={(
                  <p>Are you sure you want to delete <strong>{ruleDelta.rule.outputAttributeName}</strong>? This change will be immediately saved.</p>
                )}
              />
            </TooltipTrigger>
            <Toolbar
              left={isEntityRule ? null : (
                <Button className="px-0" buttonType="Link" onClick={this.toggleDeleteDialog}>
                  <TamrIcon size={16} iconName="delete" />
                </Button>
              )}
              right={<React.Fragment>
                <Button
                  buttonType="Secondary"
                  disabled={ruleDelta.type === RuleDeltaTypes.UNCHANGED}
                  className="mr-8 my-16"
                  onClick={onCancelRule}
                >
                  Cancel
                </Button>
                <TooltipTrigger content={validationErrors.join('\n')} placement="top">
                  <Button
                    disabled={ruleDelta.type === RuleDeltaTypes.UNCHANGED || hasErrors}
                    className="my-16"
                    onClick={onSaveRule}
                  >
                    Save
                  </Button>
                </TooltipTrigger>
              </React.Fragment>}
            />
          </div>
        )}
      </div>
    );
  }
});

export default EditableGoldenRecordsRule;
