import createLoader from '../utils/createLoader';
import { fetchDraftRecordTotal } from './GoldenRecordsAsync';
import { getModuleVersionOfLastRunIndexDraft } from './GoldenRecordsSelectors';

const TotalDraftRecordsLoader = createLoader(state => {
  const { loadedDraftRecordTotalForModuleVersion, loadingDraftRecordTotal } = state.goldenRecords;
  const lastRunIdxDraft = getModuleVersionOfLastRunIndexDraft(state);
  return {
    canFetch: !!lastRunIdxDraft, // indexDraft must have been run successfully before
    shouldFetch: lastRunIdxDraft !== loadedDraftRecordTotalForModuleVersion,
    loading: loadingDraftRecordTotal,
  };
}, {
  onFetch: fetchDraftRecordTotal,
}, 'TotalDraftRecordsLoader');

export default TotalDraftRecordsLoader;
