import React from 'react';
import { DragLayer as dragLayer, DragLayerMonitor } from 'react-dnd';

import style from './MouseTrackingDragLayer.module.scss';

const collector = (monitor: DragLayerMonitor) => {
  return {
    itemType: monitor.getItemType(),
    clientOffset: monitor.getClientOffset(),
  };
};

type MouseTrackingDragLayerOwnProps = {
  getText: (itemType?: string | symbol | number) => string
}
type MouseTrackingDragLayerProps = MouseTrackingDragLayerOwnProps
  & ReturnType<typeof collector>;

const MouseTrackingDragLayer = dragLayer(
  collector,
)(class MouseTrackingDragLayer extends React.Component<MouseTrackingDragLayerProps> {
  render() {
    const { props: { itemType, clientOffset, getText } } = this;
    const inlineStyle = clientOffset ? { transform: `translate(-50%, -100%) translate(${clientOffset.x}px, ${clientOffset.y}px)` } : {};
    return itemType && (
      <div className={style.dragLayer} style={inlineStyle}>
        {getText(itemType)}
      </div>
    );
  }
});

export default MouseTrackingDragLayer;
