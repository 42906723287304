// com.tamr.recipe.models.Operations

import enumify from './enumify';

export const RecipeOperations = {
  INIT: 'init',

  /*
   * SCHEMA MAPPING
   */
  RECORDS: 'records',

  /*
   * SCHEMA MAPPING RECOMMENDATIONS
   */
  TRAIN_RECS: 'trainRecommendations',
  RECOMMENDATIONS: 'recommendations',

  /*
   * DEDUP
   */
  PAIRS: 'pairs',
  SAMPLE_PAIRS: 'samplePairs',
  TRAIN_PREDICT_CLUSTER: 'trainPredictCluster',
  PREDICT_CLUSTER: 'predictCluster',
  LEARN_DNF: 'learnDnf',
  GEN_HIGH_IMPACT_PAIRS: 'generateHighImpactPairs',
  GENERATE_TRAINING_CLUSTERS: 'generateTrainingClusters',
  TRAIN_MASTERING_MODEL: 'trainMasteringModel',
  PUBLISH_CLUSTERS: 'publishClusters',
  PREDICT_PAIRS: 'predictPairs',
  CLUSTER_PAIRS: 'clusterPairs',
  GENERATE_TEST_RECORDS: 'generateTestRecords',
  COMPUTE_CLUSTERS_ACCURACY: 'computeClustersAccuracy',
  ESTIMATE_PAIRS: 'estimatePairs',

  /*
   * CATEGORIZATION
   */
  CATEGORIZATIONS: 'categorizations',
  TRAIN_CATEGORIZATIONS: 'trainCategorizations',
  PREDICT_CATEGORIZATIONS: 'predictCategorizations',

  /*
   * GOLDEN RECORDS
   */
  INDEX_DRAFT: 'indexDraft',
  UPDATE_SOURCE_LIST: 'updateSourceList',
  UPDATE_CLUSTER_PROFILE: 'updateClusterProfile',
  UPDATE_PUBLISHED_DATASETS: 'updatePublishedDatasets',
  PROFILE: 'profile',
  UPDATE_PUBLISHED_DATASETS_AND_PROFILE: 'updatePublishedDatasetsAndProfile',
} as const;

export const {
  RECORDS,
  TRAIN_RECS,
  RECOMMENDATIONS,
  PAIRS,
  TRAIN_PREDICT_CLUSTER,
  PREDICT_CLUSTER,
  CATEGORIZATIONS,
  PREDICT_CATEGORIZATIONS,
  PUBLISH_CLUSTERS,
  INDEX_DRAFT,
  UPDATE_SOURCE_LIST,
  UPDATE_CLUSTER_PROFILE,
  PROFILE,
  UPDATE_PUBLISHED_DATASETS_AND_PROFILE,
  UPDATE_PUBLISHED_DATASETS,
  COMPUTE_CLUSTERS_ACCURACY,
  ESTIMATE_PAIRS,
} = RecipeOperations;

export type RecipeOperationsE = typeof RecipeOperations[keyof typeof RecipeOperations];

export default enumify(RecipeOperations);
