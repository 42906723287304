import { is } from 'immutable';

import createLoader from '../utils/createLoader';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { fetchUnifiedAttributes } from './SchemaMappingAsync';
import { getUnifiedFilterInfo } from './SchemaMappingStore';

const UnifiedAttributesLoader = createLoader((state) => {
  const { schemaMapping: { unifiedLoading, unifiedLoadedFilterInfo } } = state;
  const projectInfo = selectActiveProjectInfo(state);
  return {
    canFetch: !!projectInfo && !!projectInfo.unifiedDataset?.name,
    shouldFetch: !is(getUnifiedFilterInfo(state), unifiedLoadedFilterInfo),
    loading: unifiedLoading,
  };
}, {
  onFetch: fetchUnifiedAttributes,
}, 'UnifiedAttributesLoader');

export default UnifiedAttributesLoader;
