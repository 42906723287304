import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import Chrome from '../chrome/Chrome';
import AppState from '../stores/AppState';
import ClusterRecordsSelector from '../suppliers/ClusterRecordsSelector';
// @ts-expect-error
import ClusterSidebar from '../suppliers/ClusterSidebar';
// @ts-expect-error
import ConflictErrorDialog from '../suppliers/ConflictErrorDialog';
import MetricsLoader from '../suppliers/metrics/MetricsLoader';
import PublishedClustersStatusLoader from '../suppliers/PublishedClustersStatusLoader';
import { CLUSTER_TABLE_DEFAULT_WIDTH, getClusterTableWidth, saveClusterTableWidth } from '../suppliers/SuppliersAsync';
// @ts-expect-error
import SuppliersDragLayer from '../suppliers/SuppliersDragLayer';
import SupplierSelector from '../suppliers/SupplierSelector';
import FiltersCountsLoader from '../suppliers/test-record/FiltersCountsLoader';
import VerificationFilterTotalsLoader from '../suppliers/VerificationFilterTotalsLoader';


const mapStateToProps = (state: AppState) => {
  const { suppliers: { expanded, twoPanes }, location: { recipeId } } = state;
  const clusterTableWidth = getClusterTableWidth(state);
  return { expanded, clusterTableWidth, recipeId, twoPanes };
};

const mapDispatchToProps = {
  onSaveClusterTableWidth: saveClusterTableWidth,
};

type ClustersProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

interface ClustersState {
  dragging: boolean
  clusterTableWidth: number
}

const Clusters = connect(
  mapStateToProps,
  mapDispatchToProps,
)(class Suppliers extends React.Component<ClustersProps, ClustersState> {
  state = {
    dragging: false,
    clusterTableWidth: this.props.clusterTableWidth || CLUSTER_TABLE_DEFAULT_WIDTH,
  };

  UNSAFE_componentWillReceiveProps(nextProps: ClustersProps) {
    const { clusterTableWidth } = nextProps;
    if (!this.state.clusterTableWidth || clusterTableWidth !== this.props.clusterTableWidth) {
      this.setState({ clusterTableWidth });
    }
  }

  getSidebar = () => {
    return (
      <ClusterSidebar recipeId={this.props.recipeId} />
    );
  };

  dragHandle = () => {
    const { onSaveClusterTableWidth } = this.props;
    this.setState({ dragging: true });
    document.body.onmousemove = (moveEvt) => {
      this.setState({ clusterTableWidth: moveEvt.pageX });
    };
    document.body.onmouseup = () => {
      document.body.onmousemove = null;
      document.body.onmouseup = null;
      onSaveClusterTableWidth(this.state.clusterTableWidth);
      this.setState({ dragging: false });
    };
  };

  render() {
    const { expanded, twoPanes } = this.props;
    const { dragging, clusterTableWidth } = this.state;
    return (
      <Chrome
        className="suppliers-page"
        rightSidebar={this.getSidebar()}
        isExpanded={expanded}
      >
        <ConflictErrorDialog />
        <VerificationFilterTotalsLoader />
        <PublishedClustersStatusLoader />
        <FiltersCountsLoader />
        <MetricsLoader />
        <div
          className={classNames('supplier-selector-container', { dragging })}
          style={{ width: clusterTableWidth }}
        >
          <SupplierSelector pane="top" />
          {twoPanes ? <div className="cluster-pane-divider" /> : null}
          {twoPanes ? <SupplierSelector pane="bottom" /> : null}
        </div>
        <div
          className={classNames('supplier-sidebar-resize', { dragging })}
          style={{ left: clusterTableWidth }}
          onMouseDown={this.dragHandle}
        >
          {twoPanes ? <div className="cluster-pane-divider-half" /> : null}
          {twoPanes ? <div className="cluster-pane-divider-shim" /> : null}
          {twoPanes ? <div className="cluster-pane-divider-half" /> : null}
        </div>
        <div
          className={classNames('transactions-column-container', { dragging })}
          style={{ left: clusterTableWidth + 5 }}
        >
          <ClusterRecordsSelector pane="top" />
          {twoPanes ? <div className="cluster-pane-divider" /> : null}
          {twoPanes ? <ClusterRecordsSelector pane="bottom" /> : null}
        </div>
        <SuppliersDragLayer />
      </Chrome>
    );
  }
});

export default Clusters;
