import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import CenterContent from '../components/CenterContent';
import Input from '../components/Input/Input';
import { initProject } from '../projects/ProjectsApi';
import { selectActiveProjectInfo } from '../utils/Selectors';
import style from './InitializeUnifiedDataset.module.scss';

export const getDefaultUDName = (projectName) => {
  return projectName ? `${projectName}_unified_dataset` : undefined;
};

export const NO_UNIFIED_DATASET_CANNOT_EDIT_MSG = 'You do not have a unified dataset.';

const InitializeUnifiedDataset = connect(state => {
  const projectInfo = selectActiveProjectInfo(state);
  const { projects: { initializingProject } } = state;
  return {
    projectId: projectInfo && projectInfo.projectId,
    projectName: projectInfo && projectInfo.project.displayName,
    initializingProject: _.isNumber(initializingProject),
  };
}, {
  onInitProject: initProject,
})(class InitializeUnifiedDataset extends React.Component {
  static propTypes = {
    canUserEdit: PropTypes.bool.isRequired,
    initializingProject: PropTypes.bool.isRequired,
    projectId: PropTypes.number,
    projectName: PropTypes.string,
  };

  state = {
    datasetName: getDefaultUDName(this.props.projectName),
  };

  UNSAFE_componentWillReceiveProps(props) {
    if (props.projectName && this.state.datasetName === undefined) {
      this.setState({ datasetName: getDefaultUDName(props.projectName) });
    }
  }

  handleInit = () => {
    const { projectId, onInitProject } = this.props;
    onInitProject(projectId, { unifiedDatasetName: this.state.datasetName });
  };

  onDatasetNameChange = (datasetName) => {
    this.setState({ datasetName });
  };

  canCreate = () => {
    return !_.isEmpty(this.state.datasetName);
  };

  render() {
    const { initializingProject } = this.props;
    if (initializingProject) {
      return (
        <CenterContent className={style.component}>Creating Unified Dataset...</CenterContent>
      );
    }
    if (!this.props.canUserEdit) {
      return (
        <CenterContent>
          {NO_UNIFIED_DATASET_CANNOT_EDIT_MSG}
        </CenterContent>
      );
    }
    return (
      <CenterContent className={style.component}>
        <div className={style.inputContainer}>
          <Input
            title="Unified dataset name"
            onChange={this.onDatasetNameChange}
            value={this.state.datasetName}
          />
        </div>
        <CenterContent className="textCenter">
          <Button onClick={this.handleInit} disabled={!this.canCreate()}>Create Unified Dataset</Button>
        </CenterContent>
      </CenterContent>
    );
  }
});

export default InitializeUnifiedDataset;
