export function copyToClipboardFromDomElement(getDomElement: () => Element | HTMLInputElement | HTMLTextAreaElement): void {
  const domElement = getDomElement();
  if (!domElement) {
    console.error('Could not copy to clipboard: no DOM element provided');
    return;
  }
  if (!('select' in domElement)) {
    console.error('Could not copy to clipboard: provided DOM element is not selectable');
    return;
  }
  domElement.select();
  document.execCommand('copy');
  const selection = document.getSelection();
  selection && selection.removeAllRanges();
}

export function copyToClipboard(getValue: () => string): void {
  const textarea = document.createElement('textarea');
  textarea.value = getValue();
  document.body.appendChild(textarea);
  copyToClipboardFromDomElement(() => textarea);
  document.body.removeChild(textarea);
}
