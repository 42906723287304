import classNames from 'classnames';
import { withProps } from 'recompose';

import { TamrIconName } from '../components/Icons/TamrIconClasses';
import TamrIcon from '../components/TamrIcon';
import style from './VerifiedIcon.module.scss';


const VerifiedIcon = withProps((props: { className?: string, size?: number }) => {
  const { className, size } = props;
  return {
    className: classNames(style.verifiedIcon, className),
    size: size || 16,
    iconName: 'tamr-icon-check-inverted',
  } as {
    className?: string
    size: number
    iconName: TamrIconName
  };
})(TamrIcon);

export default VerifiedIcon;
