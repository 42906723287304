import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import Button from './Button';
import style from './DeleteCommentConfirmation.module.scss';

// An opaque dialog modal for the comment section
@connect(null, {
  onHideDeleteDialog: () => ({ type: 'Comments.hideDeleteDialog' }),
})
export default class DeleteCommentConfirmation extends React.Component {
  static propTypes = {
    actionName: PropTypes.string,
    message: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
  };

  componentDidMount() {
    ReactDOM.findDOMNode(this.refs.delete).focus();
  }

  render() {
    const { onHideDeleteDialog, actionName, message } = this.props;
    return (
    // One cannot focus a <div>, thus using tabIndex allows us to control which elements
    // are focus-able. If I can focus this confirmation, then onBlur also functions.
    // Here is a helpful link about tabIndex: https://bitsofco.de/how-and-when-to-use-the-tabindex-attribute/
      <div tabIndex="-1" ref="delete" className={style.dialog} onBlur={onHideDeleteDialog}>
        <div className={style.dialogBody}>
          <div className="dialog-body">
            {message}
          </div>
        </div>

        <div className={style.dialogFooter}>
          <Button onClick={onHideDeleteDialog} buttonType="Secondary">{'Cancel'} </Button>
          <Button onMouseDown={this.props.onAccept}>{actionName || 'Confirm'}</Button>
        </div>
      </div>
    );
  }
}
