import classNames from 'classnames';
import { Set } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Highlighter from '../components/Highlighter';
import Input from '../components/Input/Input';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import Document from '../models/doc/Document';
import Tag from '../models/Tag';
import { updateTag } from './TagApi';
import style from './TagManagerListElement.module.scss';
import { setTagColor } from './TagStore';

const ENTER_KEY = 13;
const ESC_KEY = 27;

/**
 * Component that shows a single value in the list selector
 */
const TagManagerListElement = connect((state) => {
  const { tags: { allTags, newTagName, selectedTags, updateIndex } } = state;
  return { allTags, newTagName, selectedTags, updateIndex };
}, {
  onSelectTag: (selectedTagName) => ({ type: 'Tags.selectTag', selectedTagName }),
  onSetConfirmDeleteDialogVisibility: (tagsToDelete) => ({ type: 'Tags.setConfirmDeleteDialogVisibility', tagsToDelete }),
  onSetCreateNew: (createNew) => ({ type: 'Tags.setCreateNew', createNew }),
  onSetNewTagName: (tagName) => ({ type: 'Tags.setNewTagName', tagName }),
  onSetUpdateIndex: (updateIndex) => ({ type: 'Tags.setUpdateIndex', updateIndex }),
  onUpdateTag: updateTag,
})(/**
 * Component that shows a single value in the list selector
 */
  class TagManagerListElement extends React.Component {
    static propTypes = {
      allTags: ImmutablePropTypes.setOf(Document.propType.withDataType(Tag)).isRequired,
      id: PropTypes.number.isRequired,
      index: PropTypes.number.isRequired,
      newTagName: PropTypes.string,
      onSelectTag: PropTypes.func.isRequired,
      onSetConfirmDeleteDialogVisibility: PropTypes.func.isRequired,
      onSetCreateNew: PropTypes.func.isRequired,
      onSetNewTagName: PropTypes.func.isRequired,
      onSetUpdateIndex: PropTypes.func.isRequired,
      onUpdateTag: PropTypes.func.isRequired,
      selectedTags: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
      updateIndex: PropTypes.number,
      value: PropTypes.string.isRequired,
    };

    renderUpdateInput = () => {
      const { onSetNewTagName, allTags, newTagName, selectedTags, value } = this.props;
      const allTagNames = allTags.map(tagDoc => tagDoc.data.name);
      const selected = selectedTags.has(value);
      const title = 'Edit ' + value;
      const tagNameNotUnique = allTagNames.contains(newTagName);
      return (
        <div className={style.tagManagerUpdate}>
          <div className={style.tagManagerListCheckbox}>
            <TamrIcon
              iconName={selected ? 'tamr-icon-checkbox-checked' : 'tamr-icon-checkbox-unchecked'}
              className={classNames(style.tagManagerListCheckboxDisabled, { checked: selected })}
              size={18}
          />
          </div>
          <div className={style.tagUpdate} onClick={(e) => e.stopPropagation()}>
            <div className={style.inputContainer}>
              <Input
                autoFocus
                onChange={onSetNewTagName}
                onKeyUp={
                newTagName.length !== 0 && (!allTagNames.contains(newTagName) || newTagName === value)
                  ? this.onCreateKeyUp
                  : _.noop
              }
                title={newTagName ? title : ''}
                type="text"
                value={newTagName}
                variant="light"
            />
            </div>
            <Button
              className={style.cancel}
              buttonType="Secondary"
              onClick={() => this.props.onSetUpdateIndex(-1)}
              title="Cancel"
          >
              Cancel
            </Button>
            <Button
              className={style.save}
              buttonType="Primary"
              onClick={() => {
                const { onSetUpdateIndex, onUpdateTag } = this.props;
                const formattedNewTagName = newTagName.trim().toLowerCase();
                onSetNewTagName(formattedNewTagName);
                if (formattedNewTagName === value) {
                  onSetUpdateIndex(-1);
                  onSetNewTagName('');
                } else {
                  onUpdateTag(value, formattedNewTagName);
                }
              }}
              disabled={!newTagName || tagNameNotUnique}
          >
              Save
            </Button>
          </div>
          <div className={style.tagUpdateDuplicateError}>
            {newTagName && tagNameNotUnique && newTagName !== value
              ? <div>"{newTagName}" already exists.</div>
              : <div />}
            {newTagName && newTagName.length === 0 || tagNameNotUnique && newTagName !== value
              ? <div>Please create a unique tag name</div>
              : <div />}
          </div>
        </div>
      );
    };

    onCreateKeyUp = (e) => {
      const { onSetUpdateIndex, onSetNewTagName, onUpdateTag, newTagName, value } = this.props;
      if (e.keyCode === ESC_KEY) {
        onSetUpdateIndex(-1);
      }
      if (newTagName && e.keyCode === ENTER_KEY) {
        const formattedNewTagName = newTagName.trim().toLowerCase();
        onSetNewTagName(formattedNewTagName);
        if (formattedNewTagName === value) {
          onSetUpdateIndex(-1);
          onSetNewTagName('');
        } else {
          onUpdateTag(value, formattedNewTagName);
        }
      }
    };

    renderCheckbox = () => {
      const { onSelectTag, id, selectedTags, value } = this.props;
      const selected = selectedTags.has(value);
      const color = setTagColor(value, id);
      return (
        <div className={style.tagManagerListLabel} onClick={() => onSelectTag(value)}>
          <TamrIcon
            iconName={selected ? 'tamr-icon-checkbox-checked' : 'tamr-icon-checkbox-unchecked'}
            className={classNames(style.tagManagerListCheckbox, { checked: selected })}
            size={18}
        />
          <span className={style.tagManagerOption}>
            <TooltipTrigger
              className={style.tagManagerOverflowTooltip}
              placement="bottom"
              content={value}
              trigger={['hover']}
          >
              <span className={style.tagManagerPill} style={{ background: color, height: '35px', paddingTop: '3px', paddingBottom: '3px' }}>
                <Highlighter fullText={value} />
              </span>
            </TooltipTrigger>
          </span>
        </div>
      );
    };

    renderTagButtons = () => {
      const { onSetUpdateIndex, onSetCreateNew, onSetNewTagName, onSetConfirmDeleteDialogVisibility, value, index } = this.props;
      return (
        <ButtonToolbar className={style.tagManagerListButtons}>
          <Button
            className={style.tagManagerListButton}
            buttonType="Flat"
            onClick={(e) => (
              onSetUpdateIndex(index),
              onSetCreateNew(false),
              onSetNewTagName(value),
              e.stopPropagation()
            )}
            title="Edit tag"
          >
            <TamrIcon iconName="edit" size={16} />
          </Button>
          <Button
            className={style.tagManagerListButton}
            buttonType="Flat"
            onClick={() => onSetConfirmDeleteDialogVisibility(new Set().add(value))}
            title="Delete tag"
        >
            <TamrIcon iconName="delete" size={16} />
          </Button>
        </ButtonToolbar>
      );
    };

    render() {
      const { index, selectedTags, value, updateIndex } = this.props;
      const update = updateIndex === index;
      const selected = selectedTags.has(value);
      return (
        <div className={classNames(style.tagManagerListElement, update || selected ? style.tagInputHighlighter : null)}>
          {update ? this.renderUpdateInput() : this.renderCheckbox()}
          {update ? null : this.renderTagButtons()}
        </div>
      );
    }
  });

export default TagManagerListElement;
