import { RawFetchResult } from '../utils/Api';
import * as Result from '../utils/Result';
import { JsonObject } from '../utils/typescript';
import * as ApiException from './ApiException';

/**
 * Most "known" errors coming back from our server will be Java Exceptions that
 *   get converted into {@link ApiException.ApiException} json objects.
 * This type tracks that case.
 */
export interface FetchErrorResponseWithApiException {
  type: 'ApiException',
  apiException: ApiException.ApiException
}
/**
 * This tracks if the error coming back from the server is not 200-class (is not "ok")
 *   and it has json headers, but the contained json does not conform to {@link ApiException.ApiException}
 */
interface FetchErrorResponseWithJson {
  type: 'json',
  status: number,
  json: JsonObject
}
/**
 * This tracks if the error coming back from the server is not 200-class (is not "ok")
 *   and it does NOT have json headers, so the response's contained data is read out and displayed as
 *   simple text
 */
interface FetchErrorResponseWithText {
  type: 'text',
  status: number,
  text: string
}
/**
 * This tracks the case where the fetch promise itself is rejected
 */
interface FetchErrorUnknownError {
  type: 'unknown',
  reason: unknown,
}
export type FetchError
  = FetchErrorResponseWithApiException
  | FetchErrorResponseWithJson
  | FetchErrorResponseWithText
  | FetchErrorUnknownError

export type FetchResult<SuccessData> = Result.Result<SuccessData, FetchError>;

export function hasJsonHeaders(response: Response): boolean {
  const contentTypeHeaders = response.headers.get('Content-Type');
  return !!contentTypeHeaders && contentTypeHeaders.includes('application/json');
}

async function getApiErrorResponseContents(response: Response): Promise<FetchError> {
  if (hasJsonHeaders(response)) {
    const json = await response.json();
    if (ApiException.isApiException(json)) {
      return { type: 'ApiException', apiException: json };
    }
    return { type: 'json', status: response.status, json };
  }
  const text = await response.text();
  return { type: 'text', status: response.status, text };
}

interface ConvertRawFetchResultToFetchResult {
  (rawFetchResult: RawFetchResult): Promise<FetchResult<undefined>>
  <SuccessData>(rawFetchResult: RawFetchResult, toSuccessData: (data: unknown) => SuccessData): Promise<FetchResult<SuccessData>>
}

const convertRawFetchResultToFetchResult: ConvertRawFetchResultToFetchResult = async <SuccessData = undefined>(
  rawFetchResult: RawFetchResult,
  toSuccessData?: (data: unknown) => SuccessData,
): Promise<FetchResult<SuccessData | undefined>> => {
  return Result.map<Response, any, Promise<FetchResult<SuccessData | undefined>>>(rawFetchResult,
    async response => {
      if (!response.ok) {
        return Result.constructFailure(await getApiErrorResponseContents(response));
      }
      if (toSuccessData) {
        const json = await response.json();
        return Result.constructSuccess(toSuccessData(json));
      }
      return Result.constructSuccess(undefined);
    },
    reason => {
      return Promise.resolve(Result.constructFailure({ type: 'unknown', reason }));
    },
  );
};

interface ToFetchResult {
  /**
   * Provides a function for converting a {@link RawFetchResult} into a {@link FetchResult}, where the SuccessData
   * of that fetch result is {@link undefined}.
   * The body of the response will not be read or awaited.
   * @returns A function to unwrap a {@link RawFetchResult} and convert it to a {@link FetchResult}.
   */
  (): (rawFetchResult: RawFetchResult) => Promise<FetchResult<undefined>>
  /**
   * Provides a function for converting a {@link RawFetchResult} into a {@link FetchResult}, where the SuccessData
   * of that fetch result is determined by {@param toSuccessData}.
   * Note that this function assumes that the {@link Response} in the {@link RawFetchResult} has a json body;
   *   response.json() will be awaited, which may hang forever (`await response.json()` never returns) if the body
   *   of the Response is not JSON.
   * @param toSuccessData A function to convert the retrieved response JSON to SuccessData.
   * @returns A function to unwrap a {@link RawFetchResult} and convert it to a {@link FetchResult}.
   *          uses the {@param toSuccessData} argument if the raw fetch result is a success.
   */
  <SuccessData>(toSuccessData: (data: unknown) => SuccessData): (rawFetchResult: RawFetchResult) => Promise<FetchResult<SuccessData>>
}

export const toFetchResult: ToFetchResult = <SuccessData>(
  toSuccessData?: (data: unknown) => SuccessData,
): (rawFetchResult: RawFetchResult) => Promise<FetchResult<SuccessData | undefined>> => {
  if (toSuccessData) return (rawFetchResult) => convertRawFetchResultToFetchResult(rawFetchResult, toSuccessData);
  return (rawFetchResult) => convertRawFetchResultToFetchResult(rawFetchResult);
};
