import React from 'react';
import { connect } from 'react-redux';

import WarningDialog from '../components/WarningDialog';
import { submitProfile } from './SharedDatasetCatalogApi';

const ConfirmProfileDialog = connect((state) => {
  const { sharedDatasetCatalog: { confirmingProfileForDatasets } } = state;
  return { confirmingProfileForDatasets };
}, {
  onSubmitProfile: submitProfile,
  onCancel: () => ({ type: 'SharedDatasetCatalog.cancelConfirmProfile' }),
})(({ confirmingProfileForDatasets, onSubmitProfile, onCancel }) => {
  const profilingSize = confirmingProfileForDatasets.size;
  return (
    <WarningDialog
      actionName={`Profile ${profilingSize} ${profilingSize > 1 ? 'Datasets' : 'Dataset'}`}
      message={'Profiling a dataset can take a while. Are you sure you want to continue?'}
      onAccept={onSubmitProfile}
      onHide={onCancel}
      show={!confirmingProfileForDatasets.isEmpty()}
    />
  );
});

export default ConfirmProfileDialog;
