import React from 'react';

import Highlighter from '../components/Highlighter';
import { shortMoney } from '../utils/Numbers';

const spendTaxonomyCell = (category, tier, taxonomy, searchString, tierLabelMaxCounts) => {
  const categoryName = (
    <Highlighter
      fullText={category.name}
      highlightText={searchString}
    />
  );

  if (!tierLabelMaxCounts) {
    return categoryName;
  }

  const totalSpend = category.manualCategorizations.spend +
    category.totalAggForSuggestedCategorizations.spend;
  const totalSpendFormatted = totalSpend ? shortMoney(totalSpend) : '';
  return (
    <div className="category-spend">
      <span className="category-name">{categoryName}</span>
      <span className="category-spend-value">{totalSpendFormatted}</span>
    </div>
  );
};

export default spendTaxonomyCell;
