import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';

import Button from './Button';
import ButtonToolbar from './ButtonToolbar';
import Dialog, { DialogStyle } from './Dialog/Dialog';
import style from './WarningDialog.module.scss';

const propTypes = {
  actionName: PropTypes.node,
  cancelName: PropTypes.string,
  className: PropTypes.string,
  message: PropTypes.node.isRequired,
  onAccept: PropTypes.func.isRequired,
  // default to onHide if undefined
  onCancel: PropTypes.func,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
};
type WarningDialogProps = InferProps<typeof propTypes>

/**
 * Component that shows a warning dialog and only performs the action specified if the Confirm button is clicked
 */
export default class WarningDialog extends React.Component<WarningDialogProps> {
  static propTypes = propTypes;

  accept = () => {
    this.props.onAccept();
    // before, this was conflated with canceling the dialog's accept action. This means that
    // if onAccept makes changes to the state, onHide can potentially undo it, even though
    // we only mean to hide the dialog here. Therefore, we are de-conflating onCancel from
    // onHide. Most of them time, hiding means canceling the dialog, but not in this case.
    this.props.onHide();
  };

  render() {
    const body = (
      <div className={style.body}>
        {this.props.message}
      </div>
    );
    return (
      <Dialog
        className={classNames(this.props.className)}
        show={this.props.show}
        onHide={this.props.onCancel || this.props.onHide}
        title={this.props.title}
        body={body}
        dialogStyle={DialogStyle.PRIMARY}
        footer={(
          <ButtonToolbar>
            <Button onClick={this.props.onCancel || this.props.onHide} buttonType="Secondary">{this.props.cancelName ? this.props.cancelName : 'Cancel'}</Button>
            <Button onClick={this.accept}>{this.props.actionName ? this.props.actionName : 'Confirm'}</Button>
          </ButtonToolbar>
        )}
      />
    );
  }
}
