import React from 'react';
import { connect } from 'react-redux';

import { AppState } from '../stores/MainStore';
import style from './GoldenRecordsSidebar.module.scss';

const NoGoldenRecords = connect(({ goldenRecords: { searchString, hasOverrides } }: AppState) => {
  return {
    searchString,
    hasOverrides,
  };
}, {
  clearAllFilters: () => ({ type: 'GoldenRecords.clearAllFilters' }),
})(({ searchString, hasOverrides, clearAllFilters }) => {
  const noPublishedRecordsMsg = !searchString && !hasOverrides ? 'Your dataset has no clusters, please use a dataset with one or more clusters.' : '';
  return (
    <div className={style.noRecords}>
      No golden records matching your search and filters. {noPublishedRecordsMsg}
      {!noPublishedRecordsMsg && <div><a onClick={clearAllFilters}>Clear search and filters</a></div>}
    </div>
  );
});

export default NoGoldenRecords;
