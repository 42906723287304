import './Notifications.scss';

import { List, Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import Link from '../components/Link';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import { AppAction } from '../stores/AppAction';
import { AppState } from '../stores/MainStore';
import { VerificationTypeE } from '../suppliers/VerificationType';
// @ts-expect-error
import { rebaseTransforms } from '../transforms/TransformsApi';
import { pluralize } from '../utils/Strings';
import { NotificationTypeE } from './NotificationType';

const { CLUSTER_CHANGED, CLUSTER_MERGE, CLUSTER_MOVE_RECORDS, CLUSTER_MOVE_RECORDS_TO_NEW, DISCONNECTED, TRANSFORMATION_LIST_UPDATE, CoreConnect_JOB_SUBMITTED } = NotificationTypeE;

type ActionItem = {
  text: string,
  action: AppAction
}

const VerificationMsg: React.FunctionComponent<{
  nounMsg?: string
  numRecords?: number
  verificationType: VerificationTypeE
}> = ({ nounMsg, numRecords, verificationType }) =>
  <span>&nbsp;{nounMsg || pluralize(numRecords || 0, 'It has', 'They have')} been verified with suggestions {verificationType === VerificationTypeE.SUGGEST ? 'enabled' : 'disabled'}.</span>;

function getMessageAndActionItems(notificationType: NotificationTypeE | undefined, parameters: Map<string, any>): [JSX.Element, List<ActionItem>] {
  let numRecords;
  let targetClusterId;
  let targetClusterName;
  let verificationMsg = null;
  switch (notificationType) {
    case CLUSTER_CHANGED:
      return [
        <span>Another user recently made changes to the clusters on this page.</span>,
        List.of({ text: 'refresh', action: { type: 'Suppliers.reloadBoth' } }),
      ];
    case CLUSTER_MERGE:
      const numMerged = parameters.get('numMerged');
      targetClusterId = parameters.get('targetClusterId');
      verificationMsg = <VerificationMsg nounMsg="Their records have" verificationType={parameters.get('verificationType')} />;
      return [
        <span>Merged <b>{numMerged} {pluralize(numMerged, 'cluster', 'clusters')}</b> together.{verificationMsg}</span>,
        List.of({ text: 'show', action: { type: 'Suppliers.setSuppliersFilter', supplierId: targetClusterId, pane: parameters.get('pane') } }),
      ];
    case CLUSTER_MOVE_RECORDS:
      numRecords = parameters.get('numRecords');
      targetClusterId = parameters.get('targetClusterId');
      targetClusterName = parameters.get('targetClusterName');
      verificationMsg = <VerificationMsg numRecords={numRecords} verificationType={parameters.get('verificationType')} />;
      return [
        <span>Moved <b>{numRecords} <Term amount={numRecords}>record</Term></b> to <b>{targetClusterName}</b>.{verificationMsg}</span>,
        List.of({ text: 'show', action: { type: 'Suppliers.setSuppliersFilter', supplierId: targetClusterId, pane: parameters.get('pane') } }),
      ];
    case CLUSTER_MOVE_RECORDS_TO_NEW:
      numRecords = parameters.get('numRecords');
      targetClusterId = parameters.get('targetClusterId');
      verificationMsg = <VerificationMsg numRecords={numRecords} verificationType={parameters.get('verificationType')} />;
      return [
        <span>Moved <b>{numRecords} <Term amount={numRecords}>record</Term></b> to a new cluster.{verificationMsg}</span>,
        List.of({ text: 'show', action: { type: 'Suppliers.setSuppliersFilter', supplierId: targetClusterId, pane: parameters.get('pane') } }),
      ];
    case DISCONNECTED:
      return [<span>Lost connection to Tamr. Trying to reconnect...</span>, List()];
    case TRANSFORMATION_LIST_UPDATE:
      return [
        <span>Another user recently saved changes to the transformations.</span>,
        List.of({ text: 'Load Changes', action: rebaseTransforms() }),
      ];
    case CoreConnect_JOB_SUBMITTED:
      return [
        <span>
          Action complete... <Link to="/jobs">View status in jobs</Link>
        </span>,
        List(),
      ];
    default:
      return [<span />, List()];
  }
}

type NotificationBoxOwnProps = {
  onMouseEnter: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  onMouseLeave: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NotificationBox = connect((state: AppState, ownProps: NotificationBoxOwnProps) => {
  const { notification: { notificationType, parameters, permanent } } = state;
  return { notificationType, parameters, permanent };
}, {
  onHide: () => ({ type: 'Notifications.hide' }),
  onDispatch: (action: AppAction) => (action),
})((props) => {
  const { notificationType, parameters, permanent, onHide, onDispatch, ...rest } = props;
  const [message, actionItems] = getMessageAndActionItems(notificationType, parameters);
  return (
    <ReactCSSTransitionGroup
      transitionName="transition"
      transitionEnterTimeout={200}
      transitionLeaveTimeout={200}
      transitionAppearTimeout={200}
      transitionAppear
    >
      {notificationType ? (
        <div className="notification" {...rest}>
          <div className="message-container">
            {message}
          </div>
          <div className="action-items-container">
            {actionItems.flatMap((actionItem, i) => {
              const returnArray = [];
              returnArray.push(
                <a
                  key={`actionItem${i}`}
                  className="action-item"
                  onClick={() => {
                    onHide();
                    onDispatch(actionItem.action);
                  }}
                >
                  {actionItem.text}
                </a>,
              );
              if (i < actionItems.size - 1) {
                returnArray.push(<span key={`divider${i}`} className="divider">|</span>);
              }
              return returnArray;
            })}
          </div>
          {!permanent ? (
            <div className="remove-icon-container">
              <TamrIcon
                className="remove-icon"
                iconName="tamr-icon-remove"
                size={10}
                onClick={onHide}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </ReactCSSTransitionGroup>
  );
});

export default NotificationBox;
