import { ArgTypes } from '../../utils/ArgValidation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../Model';

class DocumentId extends getModelHelpers({
  namespace: { type: ArgTypes.string },
  id: { type: ArgTypes.number },
}, 'DocumentId')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class DocumentIdRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: any) { // kept for back-compat
    return new DocumentId(obj);
  }
}

export default DocumentId;
