import enumify from '../constants/enumify';

const GroupByAggregations = [
  { display: 'Average', value: 'com.tamr.workflow.specs.relational.aggregations.Average' },
  { display: 'Count', value: 'com.tamr.workflow.specs.relational.aggregations.Count' },
  { display: 'Count Distinct', value: 'com.tamr.workflow.specs.relational.aggregations.CountDistinct' },
  { display: 'First Value', value: 'com.tamr.workflow.specs.relational.aggregations.First' },
  { display: 'Max Value', value: 'com.tamr.workflow.specs.relational.aggregations.Max' },
  { display: 'Min Value', value: 'com.tamr.workflow.specs.relational.aggregations.Min' },
  { display: 'Most Frequent', value: 'com.tamr.workflow.specs.relational.aggregations.MostFrequent' },
  { display: 'All Unique Values', value: 'com.tamr.workflow.specs.relational.aggregations.SetOf' },
  { display: 'String Concatenation', value: 'com.tamr.workflow.specs.relational.aggregations.Concat' },
  { display: 'Sum', value: 'com.tamr.workflow.specs.relational.aggregations.Sum' },
  { display: 'Top 10 Most Frequent', value: 'com.tamr.workflow.specs.relational.aggregations.Top' },
];

const valuesReflection = {};
GroupByAggregations.forEach(a => valuesReflection[a.value] = a.value);

export const AggregationsEnum = enumify(valuesReflection);

export default GroupByAggregations;
