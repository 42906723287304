import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import TransformSelector from './TransformSelector';
import style from './TransformSidebarTab.module.scss';

const AddTransformSelector = _.compose(
  connect(
    state => {
      const {
        transforms: { loadedDatasetId },
      } = state;
      const disabled = !loadedDatasetId;
      return { disabled };
    },
    {
      onNewTransform: (operationClassName, sourceLevel, toIndex) => ({ type: 'Transforms.createNew', operationClassName, sourceLevel, toIndex }),
    },
  ),
)(({ className, onNewTransform, onOpen, sourceLevel, toIndex, resetAdditionalAddTransformSelector }) => {
  return (
    <TransformSelector
      {...{ onOpen }}
      className={classNames(style.addTransformButton, className)}
      onChange={transform => {
        if (resetAdditionalAddTransformSelector) resetAdditionalAddTransformSelector();
        return onNewTransform(transform.className, sourceLevel, toIndex);
      }}
      placeholder="Add transformation"
    />
  );
});

AddTransformSelector.propTypes = {
  classNames: PropTypes.string,
  onOpen: PropTypes.func,
  resetAdditionalAddTransformSelector: PropTypes.func,
  toIndex: PropTypes.number,
};

export default AddTransformSelector;
