/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';

import { HTMLAnchorProps } from '../utils/typescript-react';

type LinkProps = {
  to: string
  openInNewTab?: boolean
} & HTMLAnchorProps;

const Link: React.FunctionComponent<LinkProps> = ({ to, openInNewTab, ...props }) => {
  const openInNewTabProps = openInNewTab
    // see SUP-4687: if we include target="_blank" to direct the link to open in a new tab
    //   must include these rel props to prevent unresponsive original tab when opened tab is closed.
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {};
  return (
    <a
      href={`#${to}`}
      {...openInNewTabProps}
      {...props}
    />
  );
};

export default Link;
