import { Set } from 'immutable';
import $ from 'jquery';
import uri from 'urijs';

import { SHOW } from '../errorDialog/ErrorDialogActionTypes';
import Document from '../models/doc/Document';
import { QueryBuilder } from '../models/doc/Query';
import Tag from '../models/Tag';
import { ArgTypes, checkArg, checkReturn } from '../utils/ArgValidation';

const doFetch = checkReturn(ArgTypes.deferred.withResolution(ArgTypes.Immutable.set.of(Document.argTypeWithNestedClass(Tag))), (query) => {
  return $.ajax({
    url: uri(SERVICES.procure('/procurement/datasets/tags/query')),
    cache: false,
    contentType: 'application/json',
    data: JSON.stringify(query),
    dataType: 'json',
    method: 'POST',
  }).then(response => {
    return new Set(response.map(doc => Document.fromJSON(doc, d => new Tag(d))));
  });
});

const resolveTagSelection = checkReturn(ArgTypes.Immutable.set.of(ArgTypes.string), (selectedTags, allTags) => {
  checkArg({ selectedTags }, ArgTypes.Immutable.set.of(ArgTypes.string));
  checkArg({ allTags }, ArgTypes.Immutable.set.of(Document.argTypeWithNestedClass(Tag)));
  const allTagNames = !allTags.isEmpty() ? allTags.map(doc => doc.data.name) : new Set();
  return selectedTags.filter(tagName => allTagNames.contains(tagName));
});

export const fetchAllTags = () => (dispatch, getState) => {
  const { tags: { selectedTags } } = getState();
  const query = new QueryBuilder().all().build();
  doFetch(query).then(allTags => {
    dispatch({ type: 'Tags.fetchAllTagsCompleted', allTags, selectedTags: resolveTagSelection(selectedTags, allTags) });
  }).fail(response => {
    dispatch({ type: SHOW,
      detail: 'Error loading tags',
      response,
    });
  });
};

export const createTag = (newTagName) => (dispatch, getState) => {
  const { tags: { allTags, selectedTags } } = getState();
  const name = newTagName.trim().toLowerCase();
  const allTagNames = allTags.map(tagDoc => tagDoc.data.name);
  if (allTagNames.contains(name)) {
    return;
  }
  return $.ajax({
    url: uri(SERVICES.procure('/procurement/datasets/tags')),
    dataType: 'json',
    cache: false,
    contentType: 'application/json; charset=utf-8',
    data: JSON.stringify({ name }),
    method: 'POST',
    success() {
      const query = new QueryBuilder().all().build();
      doFetch(query).then(allTagsUpdated => {
        dispatch({ type: 'Tags.createTagCompleted', allTags: allTagsUpdated, selectedTags: resolveTagSelection(selectedTags, allTagsUpdated), newTagName: name });
        return allTagsUpdated.find((tag) => {
          return tag.data.name === newTagName;
        });
      }).fail(response => {
        dispatch({ type: SHOW,
          detail: 'Error loading tags',
          response,
        });
      });
    },
    error(response) {
      dispatch({ type: SHOW,
        detail: 'Error creating tag',
        response,
      });
    },
  });
};

export const deleteTag = (tagName) => (dispatch) => {
  $.ajax({
    url: uri(SERVICES.procure(`/procurement/datasets/tags/${tagName}`)),
    dataType: 'json',
    cache: false,
    context: this,
    method: 'DELETE',
    success() {
      dispatch(fetchAllTags());
    },
    error(response) {
      dispatch({ type: SHOW,
        detail: 'Error deleting tags',
        response,
      });
    },
  });
};

export const updateTag = (tagName, tagName2) => (dispatch, getState) => {
  const { tags: { allTags, selectedTags } } = getState();
  const tagNameNew = tagName2.trim().toLowerCase();
  const allTagNames = allTags.map(tagDoc => tagDoc.data.name);
  if (allTagNames.contains(tagNameNew)) {
    return;
  }
  $.ajax({
    url: uri(SERVICES.procure(`/procurement/datasets/tags/${tagName}`)),
    dataType: 'json',
    cache: false,
    context: this,
    contentType: 'application/json; charset=utf-8',
    data: JSON.stringify({ name: tagNameNew }),
    method: 'PUT',
    success() {
      // do fetch without calling an action -- in success of THAT, set allTags + scrollToTagWithName
      const query = new QueryBuilder().all().build();
      doFetch(query).then(allTagsUpdated => {
        dispatch({ type: 'Tags.updateTagCompleted', allTags: allTagsUpdated, selectedTags: resolveTagSelection(selectedTags, allTagsUpdated), newTagName: tagNameNew });
      }).fail(response => {
        dispatch({ type: SHOW,
          detail: 'Error loading tags',
          response,
        });
      });
    },
    error(response) {
      dispatch({ type: SHOW,
        detail: 'Error updating tag',
        response,
      });
    },
  });
};

export const linkTag = (datasetNames, tagName) => (dispatch) => {
  checkArg({ datasetNames }, ArgTypes.Immutable.set.of(ArgTypes.string));
  const ajaxCalls = datasetNames.map(dsName => { // TODO: short term fix for until we can handle multiple datasets on link
    return $.ajax({
      url: uri(SERVICES.procure(`/procurement/datasets/${dsName}/tags/${tagName}`)),
      dataType: 'json',
      cache: false,
      context: this,
      contentType: 'application/json; charset=utf-8',
      method: 'PUT',
    });
  });
  $.when(...ajaxCalls.toArray())
    .then(() => {
      dispatch({ type: 'Tags.linkTagCompleted' });
    })
    .fail((response) => {
      dispatch({ type: SHOW,
        detail: 'Error linking tag',
        response,
      });
    });
};

// NB: backend will error if you attempt to unlink a not already linked tag, so watch out on bulk unlinks
export const removeLink = (datasetNames, tagName) => (dispatch) => {
  checkArg({ datasetNames }, ArgTypes.Immutable.set.of(ArgTypes.string));
  const ajaxCalls = datasetNames.map(dsName => { // TODO: short term fix for until we can handle multiple datasets on link
    return $.ajax({
      url: uri(SERVICES.procure(`/procurement/datasets/${dsName}/tags/${tagName}`)),
      dataType: 'json',
      cache: false,
      context: this,
      contentType: 'application/json; charset=utf-8',
      method: 'DELETE',
    });
  });
  $.when(...ajaxCalls.toArray())
    .then(() => {
      dispatch({ type: 'Tags.removeLinkCompleted' });
    })
    .fail((response) => {
      dispatch({ type: SHOW,
        detail: 'Error removing tag link',
        response,
      });
    });
};
