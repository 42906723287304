import classNames from 'classnames';
import { List, Set } from 'immutable';
import React, { ReactNode } from 'react';

import Lint from './models/Lint';
import style from './Transform.module.scss';
import TransformSelector, { TransformTypeE } from './TransformSelector';
import { ValidationErrorE } from './ValidationError';

const Summary: React.FC<{
  children?: ReactNode,
  errors?: List<Lint>,
  isDisabled?: boolean,
  modified?: boolean,
  name: TransformTypeE,
  onChange?: (type: { value: string, key: string }) => void,
  validationErrors?: Set<ValidationErrorE>,
}> = ({ children, errors, isDisabled, modified, name, onChange, validationErrors, ...rest }) => {
  return (
    <div className={classNames(style.summary, style.section, { [style.isDisabled]: isDisabled })} {...rest}>
      <TransformSelector {...{ name, onChange, errors, validationErrors, modified, isDisabled }} />
      <div className={style.summaryContent}>{children}</div>
    </div>
  );
};

export default Summary;
