import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Highlighter from '../components/Highlighter';
import Labeler from '../components/Labeler';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import ProjectInfo from '../models/ProjectInfo';
import { selectAllProjectInfos } from '../utils/Selectors';
import { submit } from './ManageProjectDatasetsApi';
import { getProjectNameToMemberDatasetNames } from './ManageProjectDatasetsStore';
import style from './ManageProjectsForDatasetsDialog.module.scss';

const NEXT_SELECTION_STATE = {
  selected: false,
  semi: true,
  unselected: true,
};

function getDatasetNamesMsg(datasets) {
  if (datasets.isEmpty()) {
    return '';
  }
  if (datasets.size === 1) {
    return datasets.first();
  }
  return datasets.size + ' datasets';
}

function getLabelerOptions(managingProjectsForDatasets, projectNameToMemberDatasetNames, searchValue, editingChanges, projectNamesToExclude) {
  const projectNameToSelectionState = projectNameToMemberDatasetNames.mapEntries(([projectName, memberDatasetNames]) => {
    let selectionState;
    switch (true) {
      case memberDatasetNames.isEmpty():
        selectionState = 'unselected';
        break;
      case memberDatasetNames.size < managingProjectsForDatasets.size:
        selectionState = 'semi';
        break;
      default:
        selectionState = 'selected';
    }
    return [projectName, selectionState];
  });
  const options = projectNameToSelectionState.map((selectionState, projectName) => {
    let resolvedSelectionState = selectionState;
    if (editingChanges.has(projectName)) {
      resolvedSelectionState = editingChanges.get(projectName) ? 'selected' : 'unselected';
    }
    return { key: projectName, selectionState: resolvedSelectionState };
  }).toList()
    .filter(option => option.key.indexOf(searchValue) !== -1)
    .filter(option => !projectNamesToExclude.includes(option.key))
    .map(option => { // apply highlighter
      option.display = (
        <span title={option.key}>
          <Highlighter fullText={option.key} highlightText={searchValue} />
        </span>
      );
      return option;
    })
    .sortBy(option => option.key);
  return options;
}

const ManageProjectsForDatasetsDialog = _.compose(
  connect((state) => {
    const {
      datasetCatalog: { datasets: allDatasetDocs },
      manageProjectDatasets: { confirming, managingProjectsForDatasets, editingChanges, searchValue },
      projects: { projectsWithStatus },
    } = state;
    const datasetDocs = allDatasetDocs.filter(doc => managingProjectsForDatasets.includes(doc.data.name));
    const projectDocs = projectsWithStatus.map(pws => pws.project);
    const projectNameToMemberDatasetNames = getProjectNameToMemberDatasetNames(projectDocs, datasetDocs);
    const datasetIds = datasetDocs.map(doc => doc.id.id).toArray();
    const projectInfos = selectAllProjectInfos(state);
    const projectNamesToExclude = projectInfos.filter(projectInfo => {
      const outputDatasetIds = projectInfo.outputDatasetIds.toArray();
      return !_.isEmpty(_.intersection(datasetIds, outputDatasetIds));
    }).map(projectInfo => projectInfo.project.name).toSet();
    return { projectInfos, projectNamesToExclude, confirming, managingProjectsForDatasets, editingChanges, searchValue, projectNameToMemberDatasetNames, datasetDocs };
  }, {
    onStartConfirming: () => ({ type: 'ManageProjectDatasets.startConfirming' }),
    onSubmit: submit,
    onCancel: () => ({ type: 'ManageProjectDatasets.cancel' }),
    onMakeEditingChange: (projectName, selectionValue) => ({ type: 'ManageProjectDatasets.makeEditingChange', projectName, selectionValue }),
    onSetSearchValue: (searchValue) => ({ type: 'ManageProjectDatasets.setSearchValue', searchValue }),
  }),
)(class ManageProjectsForDatasetsDialog extends React.Component {
  static propTypes = {
    confirming: PropTypes.bool.isRequired,
    datasetDocs: ImmutablePropTypes.listOf(Document.propType.withDataType(Dataset)).isRequired,
    editingChanges: ImmutablePropTypes.mapOf(PropTypes.bool, PropTypes.name).isRequired,
    managingProjectsForDatasets: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
    onCancel: PropTypes.func.isRequired,
    onMakeEditingChange: PropTypes.func.isRequired,
    onSetSearchValue: PropTypes.func.isRequired,
    onStartConfirming: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    projectInfos: ImmutablePropTypes.listOf(PropTypes.instanceOf(ProjectInfo)).isRequired,
    projectNameToMemberDatasetNames: ImmutablePropTypes.mapOf(
      ImmutablePropTypes.setOf(PropTypes.string),
      PropTypes.string,
    ).isRequired,
    projectNamesToExclude: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
    searchValue: PropTypes.string.isRequired,
  };

  onSubmit = () => {
    const { editingChanges, onStartConfirming, onSubmit } = this.props;
    // if any datasets are set to be removed from any projects, show warning dialog
    const someDatasetsRemoved = editingChanges.find(membershipState => membershipState === false) !== undefined;
    if (someDatasetsRemoved) {
      onStartConfirming();
      return;
    }
    // otherwise, commit changes to the server
    onSubmit();
  };

  render() {
    const { onCancel, onMakeEditingChange, onSetSearchValue, confirming, editingChanges, managingProjectsForDatasets, projectNameToMemberDatasetNames, searchValue, projectNamesToExclude } = this.props;
    return (
      <Dialog
        dialogStyle={DialogStyle.PRIMARY}
        onHide={onCancel}
        show={!confirming && !managingProjectsForDatasets.isEmpty()}
        header={(
          <Dialog.Header>
            <span title={managingProjectsForDatasets.join(', ')}>
              Manage project membership for {getDatasetNamesMsg(managingProjectsForDatasets)}
            </span>
          </Dialog.Header>
        )}
        body={(
          <Labeler
            className={style.labeler}
            onOptionSelect={({ key, selectionState }) => {
              onMakeEditingChange(key, NEXT_SELECTION_STATE[selectionState]);
            }}
            onSearchValueChange={onSetSearchValue}
            options={getLabelerOptions(managingProjectsForDatasets, projectNameToMemberDatasetNames, searchValue, editingChanges, projectNamesToExclude)}
            searchValue={searchValue}
          />
        )}
        footer={(
          <div className={style.footer}>
            <Button onClick={this.onSubmit} disabled={editingChanges.isEmpty()}>Update</Button>
            <Button buttonType="Secondary" onClick={onCancel}>Cancel</Button>
          </div>
        )}
      />
    );
  }
});

export default ManageProjectsForDatasetsDialog;
