import { List } from 'immutable';

import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../../models/Model';
import { TypeArgType, typeFromJson } from '../../transforms/models/Types';
import { ArgTypes } from '../../utils/ArgValidation';
import { $TSFixMe } from '../../utils/typescript';
import FunctionKind from '../constants/FunctionKind';
import Argument from './Argument';
import Deprecation from './Deprecation';
import Example from './Example';
import Varargs from './Varargs';

// com.tamr.transform.models.functions.Signature
class Signature extends getModelHelpers({
  names: { type: ArgTypes.Immutable.list.of(ArgTypes.any) },
  name: { type: ArgTypes.string },
  kind: { type: FunctionKind.argType },
  args: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(Argument)) },
  varargs: { type: ArgTypes.nullable(ArgTypes.instanceOf(Varargs)) },
  output: { type: TypeArgType },
  description: { type: ArgTypes.nullable(ArgTypes.string) },
  deprecation: { type: ArgTypes.nullable(ArgTypes.instanceOf(Deprecation)) },
  examples: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(Example)) },
}, 'Signature')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class SignatureRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(json: $TSFixMe) {
    const { names, name, kind, args, varargs, output, description, deprecation, examples } = json;
    return new Signature({
      names: List(names),
      name,
      kind,
      description,
      deprecation: Deprecation.fromJSON(deprecation),
      args: List(args).map(Argument.fromJSON),
      examples: List(examples).map(Example.fromJSON),
      varargs: Varargs.fromJSON(varargs),
      output: typeFromJson(output),
    });
  }
}

export default Signature;
