import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Input from '../components/Input/Input';
import TamrIcon from '../components/TamrIcon';
import { createUnifiedAttribute } from '../schema-mapping/SchemaMappingAsync';
import { validateName } from '../schema-mapping/UnifiedAttributeSubsection';
import { getUnifiedDatasetName, selectUnifiedAttributeNames } from '../utils/Selectors';
import style from './UADialog.module.scss';

const ENTER_KEY = 13;
const ESCAPE_KEY = 27;

const CreateUADialog = _.compose(
  connect((state) => {
    return {
      datasetName: getUnifiedDatasetName(state),
      unifiedAttributeNames: selectUnifiedAttributeNames(state),
    };
  }, {
    onCreateUnifiedAttribute: createUnifiedAttribute,
  }),
)(class CreateUADialog extends React.Component {
  static propTypes = {
    btnText: PropTypes.string.isRequired,
    datasetName: PropTypes.string,
    onCreateUnifiedAttribute: PropTypes.func.isRequired,
    unifiedAttributeNames: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
  };

  state = {
    name: undefined, // string name of new attribute
    description: undefined, // string description of new attribute
    show: false,
  };

  onFormKeyUp = (e) => {
    if (e.keyCode === ENTER_KEY) {
      if (this.isCreateValid()) {
        this.create();
      }
    } else if (e.keyCode === ESCAPE_KEY) {
      this.onClose();
    }
  };

  isCreateValid = () => {
    const { name } = this.state;
    const { unifiedAttributeNames } = this.props;
    return !!name && !validateName(name, unifiedAttributeNames);
  };

  onOpen = () => {
    this.setState({ show: true });
  };

  onClose = () => {
    this.setState({
      show: false,
      name: undefined,
      description: undefined,
    });
  };

  onNameChange = (name) => {
    this.setState({ name });
  };

  onDescriptionChange = (description) => {
    this.setState({ description });
  };

  create = () => {
    const { name, description } = this.state;
    const { onCreateUnifiedAttribute, unifiedAttributeNames } = this.props;
    const error = validateName(name, unifiedAttributeNames);
    if (!error) {
      onCreateUnifiedAttribute(name, description);
      this.onClose();
    }
  };

  renderNameInput = (hasError) => {
    return (
      <div className={style.nameInputContainer}>
        <Input
          autoFocusOnMount
          onChange={this.onNameChange}
          type="text"
          title="Attribute Name"
          value={this.state.name}
          onKeyUp={this.onFormKeyUp}
          invalid={hasError}
        />
      </div>
    );
  };

  renderDescriptionInput = () => {
    return (
      <div className={style.descriptionInputContainer}>
        <Input
          onChange={this.onDescriptionChange}
          type="text"
          title="Attribute Description"
          value={this.state.description}
          onKeyUp={this.onFormKeyUp}
        />
      </div>
    );
  };

  renderHeader = () => {
    return (
      <div className={style.dialogHeader}>
        Define a new unified attribute
      </div>
    );
  };

  renderBody = () => {
    const { name } = this.state;
    const { unifiedAttributeNames } = this.props;
    const error = validateName(name, unifiedAttributeNames);
    return (
      <div>
        {error
          ? (<div className={classNames(style.nameMsgError, style.createMsg)}><TamrIcon iconName="tamr-icon-warning" size={14} />{' ' + error}</div>)
          : <div className={style.createMsg} />
        }
        {this.renderNameInput(error)}
        {this.renderDescriptionInput()}
      </div>
    );
  };

  renderFooter = () => {
    return (
      <ButtonToolbar>
        <Button buttonType="Secondary" onClick={this.onClose}>Cancel</Button>
        <Button buttonType="Primary" onClick={this.create} disabled={!this.isCreateValid()}>Create</Button>
      </ButtonToolbar>
    );
  };

  render() {
    const { datasetName, btnText } = this.props;
    return (
      <div className={style.uaComponent}>
        <Dialog
          dialogStyle={DialogStyle.PRIMARY}
          header={this.renderHeader()}
          body={this.renderBody()}
          footer={this.renderFooter()}
          onHide={this.onClose}
          show={this.state.show}
        />
        <Button
          buttonType="Secondary"
          className={style.managerButton}
          disabled={!datasetName}
          onClick={this.onOpen}
        >
          {btnText}
        </Button>
      </div>
    );
  }
});

CreateUADialog.defaultProps = {
  btnText: 'Create attribute',
};

export default CreateUADialog;
