import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import { uploadTaxonomy } from './TaxonomyUploadApi';
import TaxonomyUploadDialogContent from './TaxonomyUploadDialogContent';

/**
 * Dialog used to upload a new Taxonomy to the Procurement App
 */
const TaxonomyUploadDialog = _.compose(
  connect(
    state => {
      const {
        taxonomyUpload: { fileName, isLoading, selectedFile, showDialog },
        location: { recipeId },
      } = state;
      return { fileName, isLoading, selectedFile, showDialog, recipeId };
    },
    {
      onTaxonomyUpload: uploadTaxonomy,
      onHideTaxonomyUploadDialog: () => ({ type: 'TaxonomyUpload.hideDialog' }),
      onResetTaxonomyUpload: () => ({ type: 'TaxonomyUpload.reset' }),
    },
  ),
)(
  class TaxonomyUploadDialog extends React.Component {
    static propTypes = {
      fileName: PropTypes.string,
      onHideTaxonomyUploadDialog: PropTypes.func.isRequired,
      onResetTaxonomyUpload: PropTypes.func.isRequired,
      onTaxonomyUpload: PropTypes.func.isRequired,
      recipeId: PropTypes.number, // if missing we aren't in the context of a project
      selectedFile: PropTypes.object,
      showDialog: PropTypes.bool.isRequired,
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (
        this.props.recipeId !== nextProps.recipeId &&
        _.isNumber(nextProps.recipeId)
      ) {
        this.props.onResetTaxonomyUpload();
      }
    }

    canSave = () => {
      return (
        this.props.selectedFile &&
        this.props.fileName &&
        this.props.fileName.toString().trim() !== '' &&
        this.props.fileName.toString().trim().length === this.props.fileName.toString().length
      );
    };

    render() {
      const {
        selectedFile,
        showDialog,
        onHideTaxonomyUploadDialog,
        onTaxonomyUpload,
      } = this.props;
      return (
        <Dialog
          className="form-upload-dialog"
          show={showDialog}
          onHide={onHideTaxonomyUploadDialog}
          title="Upload taxonomy data"
          dialogStyle={DialogStyle.PRIMARY}
          body={<TaxonomyUploadDialogContent />}
          footer={
            <ButtonToolbar>
              <Button
                onClick={onHideTaxonomyUploadDialog}
                buttonType="Secondary"
              >
                Cancel
              </Button>
              <Button
                tooltipPlacement="bottom"
                onClick={() =>
                  onTaxonomyUpload({ selectedFile })
                }
                disabled={!this.canSave()}
              >
                Upload
              </Button>
            </ButtonToolbar>
          }
        />
      );
    }
  },
);

export default TaxonomyUploadDialog;
