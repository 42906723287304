import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import Input from '../components/Input/Input';
import LoadingPanel from '../components/LoadingPanel';
import { initProject } from '../projects/ProjectsApi';
import { getDefaultUDName, NO_UNIFIED_DATASET_CANNOT_EDIT_MSG } from '../schema-mapping/InitializeUnifiedDataset';
import { activeUserIsCuratorForActiveProject, selectActiveProjectInfo } from '../utils/Selectors';
import { getPath } from '../utils/Values';
import style from './CreateUnifiedDatasetPageContent.module.scss';

const CreateUnifiedDatasetPageContent = connect((state) => {
  const { projects: { initializingProject, loading } } = state;
  const activeProjectInfo = selectActiveProjectInfo(state);
  const projectId = getPath(activeProjectInfo, 'projectId');
  const projectName = getPath(activeProjectInfo, 'project', 'name');
  const canUserEdit = activeUserIsCuratorForActiveProject(state);
  return { loading: initializingProject || loading, projectId, projectName, canUserEdit };
}, {
  onInitProject: initProject,
})(class CreateUnifiedDatasetPageContent extends React.Component {
  state = { datasetName: getDefaultUDName(this.props.projectName) };

  UNSAFE_componentWillReceiveProps(props) {
    const { projectName } = props;
    if (projectName && this.state.datasetName === undefined) {
      this.setState({ datasetName: getDefaultUDName(projectName) });
    }
  }

  render() {
    const { loading, projectId, onInitProject, canUserEdit } = this.props;
    const { datasetName } = this.state;
    return (
      <div className={style.background}>
        {canUserEdit ? (
          <div className={style.content}>
            {loading ? <LoadingPanel /> : null}
            <div className={style.heading}>
              Create your Unified Dataset to view and transform unified attributes.
            </div>
            <div className={style.inputRow}>
              <Input
                componentClassName={style.datasetNameInput}
                title="Unified Dataset name"
                value={datasetName}
                onChange={(value) => this.setState({ datasetName: value })}
              />
              <Button
                className={style.createDatasetButton}
                onClick={() => onInitProject(projectId, { unifiedDatasetName: datasetName })}
              >
                Create Unified Dataset
              </Button>
            </div>
          </div>
        ) : (
          <div className={style.noUnifiedDatasetCannotEdit}>
            {NO_UNIFIED_DATASET_CANNOT_EDIT_MSG}
          </div>
        )}
      </div>
    );
  }
});

export default CreateUnifiedDatasetPageContent;
