import { List } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import style from './TagIcon.module.scss';
import { getTagNames } from './TagStore';

const TagIcon = _.compose(
  connect((state, { datasets, sourceDatasetName }) => {
    const dataset = datasets.find(d => d.data.name === sourceDatasetName);
    const sourceTags = new List(dataset ? dataset.data.metadata.get('tag') : []);
    const tagIds = sourceTags.map(t => t.id);
    const tagNames = getTagNames(state, tagIds);
    return { tagNames };
  }),
)(class TagIcon extends React.Component {
  static propTypes = {
    tagNames: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
  };

  render() {
    const { tagNames } = this.props;
    if (tagNames.isEmpty()) {
      return null;
    }
    return (
      <TooltipTrigger
        className={style.tagIconOverflowTooltip}
        content={<div>{tagNames.join(', ')}</div>}
        placement="bottom"
      >
        <TamrIcon iconName="label-filled" size={12} />
      </TooltipTrigger>
    );
  }
});

export default TagIcon;
