import $ from 'jquery';

import { CATEGORIZATION } from '../constants/RecipeType';
import { SHOW } from '../errorDialog/ErrorDialogActionTypes';
import EsRecord from '../models/EsRecord';
import { processTxnData, TxnUrlBuilder } from '../transactions/TransactionUtils';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { selectActiveProjectInfo } from '../utils/Selectors';

export const fetchRecords = (categoryId) => (dispatch, getState) => {
  checkArg({ categoryId }, ArgTypes.number);
  dispatch({ type: 'CategoryDetail.fetchRecords' });
  const state = getState();
  const { unifiedDatasetDoc, unifiedDatasetName } = selectActiveProjectInfo(state);
  $.ajax({
    method: 'GET',
    cache: false,
    url: new TxnUrlBuilder(CATEGORIZATION)
      .unifiedDatasetName(unifiedDatasetName)
      .categoryIds([categoryId])
      .pageSize(5)
      // Do not include external data on the category page
      .excludeExternalData(true)
      .sortBySpend()
      .build(),
    success(data) {
      const processHit = hit => {
        return new EsRecord(
          hit,
          unifiedDatasetDoc,
        );
      };
      const { total: totalRecords, rows: records } = processTxnData(data, processHit);
      dispatch({ type: 'CategoryDetail.fetchRecordsCompleted', totalRecords, records, loadedCategoryId: categoryId });
    },
    error(response) {
      dispatch({ type: SHOW,
        detail: 'Error loading data',
        response,
      });
    },
  });
};
