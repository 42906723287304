import classNames from 'classnames';
import { Map } from 'immutable';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { StandardButtonTypePropType } from './Button';
import ConditionalButton from './ConditionalButton';
import style from './DropdownButton.module.scss';
import PopoverTrigger from './PopoverTrigger';
import TamrIcon from './TamrIcon';

export const OptionPropType = PropTypes.shape({
  text: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  preconditions: ImmutablePropTypes.mapOf(PropTypes.bool.isRequired, PropTypes.node.isRequired),
});
export const OptionsPropType = ImmutablePropTypes.listOf(OptionPropType.isRequired);

const dummyOptionProps = { option: OptionPropType.isRequired };
export type OptionType = InferProps<typeof dummyOptionProps>['option'];

const propTypes = {
  buttonType: StandardButtonTypePropType,
  className: PropTypes.string,
  options: OptionsPropType.isRequired,
  popoverMenuClassName: PropTypes.string,
  preconditions: ImmutablePropTypes.mapOf(PropTypes.bool.isRequired, PropTypes.node.isRequired),
  text: PropTypes.string,
};

/*
 * This must be a class due to ref usage
 * TODO reconcile with DropdownList?
 */
class DropdownButton extends React.Component<InferProps<typeof propTypes>> {
  static propTypes = propTypes;
  render() {
    const { text, preconditions, className, options, buttonType, popoverMenuClassName } = this.props;
    return (
      <PopoverTrigger
        className={classNames(popoverMenuClassName, style.popoverMenu)}
        ref="popover"
        placement="bottom"
        content={
          <div>
            {options.map((o, i) => (
              <ConditionalButton
                key={i}
                className={style.option}
                buttonType="Link"
                onClick={() => {
                  // @ts-expect-error
                  this.refs.popover.hide();
                  o.onClick();
                }}
                preconditions={o.preconditions || Map()}
              >
                {o.text}
              </ConditionalButton>
            ))}
          </div>
        }
      >
        <ConditionalButton
          preconditions={preconditions || Map()}
          className={classNames(style.button, className)}
          buttonType={buttonType || 'Secondary'}
        >
          {text}
          <TamrIcon iconName="arrow-drop-down" size={24} />
        </ConditionalButton>
      </PopoverTrigger>
    );
  }
}

export default DropdownButton;
