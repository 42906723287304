import AppState from '../stores/AppState';
import createLoader from '../utils/createLoader';
import * as ClusterSampleState from './ClusterSampleState';
import { grPageFetchClusterSample, rulesPageFetchClusterSample } from './GoldenRecordsAsync';
import { getAnyProfilingJobIsRunningOrSubmitting } from './GoldenRecordsSelectors';


export function canFetch(state: ClusterSampleState.ClusterSampleState, appState: AppState): boolean {
  const { showClusterSampleTable } = state;
  const profilingIsRunning = getAnyProfilingJobIsRunningOrSubmitting(appState);
  return showClusterSampleTable // we do not want to unnecessarily fetch if user does not have the table open
    && !profilingIsRunning; // we do not want to fetch while the sample is being recomputed
}

export function getLoaderParameters(
  state: ClusterSampleState.ClusterSampleState,
  appState: AppState,
): {
  canFetch: boolean
  loading: boolean
  shouldFetch: boolean
} {
  return {
    canFetch: canFetch(state, appState),
    loading: ClusterSampleState.isLoading(state),
    shouldFetch: ClusterSampleState.shouldFetch(state),
  };
}

export const GrPageClusterSampleLoader = createLoader(state => {
  return getLoaderParameters(state.goldenRecords.clusterSampleState, state);
}, {
  onFetch: grPageFetchClusterSample,
}, 'GrPageClusterSampleLoader');

export const RulesPageClusterSampleLoader = createLoader(state => {
  return getLoaderParameters(state.goldenRecordsRules.clusterSampleState, state);
}, {
  onFetch: rulesPageFetchClusterSample,
}, 'RulesPageClusterSampleLoader');
