// Customer data in elastic is stored with sanitized fields that are prefixed
// to prevent collisions with metadata. This is the prefix.
const COLUMN_PREFIX = 'tamr__';

// Elastic's indexed internal unique ID (concats _type and _id)
const DATASET_ID_PREFIX = 'dataset_id_';

const ORIGIN_SOURCE_NAME = 'origin_source_name';
const TAMR_ID = 'tamr_id';
const ORIGIN_ENTITY_ID = 'origin_entity_id';

export {
  COLUMN_PREFIX,
  DATASET_ID_PREFIX,
  ORIGIN_SOURCE_NAME,
  ORIGIN_ENTITY_ID,
  TAMR_ID,
};
