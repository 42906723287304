import './TooltipTrigger.scss';

import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import { OverlayTrigger, Tooltip as RBTooltip } from 'react-bootstrap';


export type PlacementOption = 'top' | 'right' | 'bottom' | 'left';
const allowedPlacementValues: PlacementOption[] = ['top', 'right', 'bottom', 'left'];

export const propTypes = {
  /**
   * Children is the "button" (doesn't need to be a button) that triggers the tooltip's show.
   * Children must be a React element.
   * It cannot be a string or number because react-bootstrap OverlayTrigger needs to do
   * fanciness with it.
   */
  children: PropTypes.element.isRequired,
  /**
   * Classname is passed to the tooltip.
   */
  className: PropTypes.string,
  /**
   * Content is the content of the tooltip. The tooltip will not be rendered when this is null.
   */
  content: PropTypes.node,
  /**
   * Delay in MS before opening tooltip.  Defaults to 300
   */
  delay: PropTypes.number,
  /**
   * Delay in hiding in MS after opening tooltip.  Defaults to 300
   */
  delayHide: PropTypes.number,
  /**
   * Placement determines on which side of Children the tooltip will be placed.
   */
  placement: PropTypes.oneOf<PlacementOption>(allowedPlacementValues),
  /**
   * What triggers this tooltip
   */
  trigger: PropTypes.arrayOf(PropTypes.oneOf(['click', 'hover', 'focus']).isRequired),
};

export type TooltipTriggerProps = InferProps<typeof propTypes>;

class TooltipTrigger extends React.Component<TooltipTriggerProps> {
  static propTypes = propTypes;
  hide = () => {
    // @ts-expect-error
    this.refs.labelerTrigger.hide();
  };

  render() {
    const { children, className, content } = this.props;

    // TODO default-props-in-typescript
    let { delay, placement, trigger, delayHide } = this.props;
    delay = (delay === undefined || delay === null) ? 300 : delay;
    delayHide = (delayHide === undefined || delayHide === null) ? 300 : delayHide;
    placement = (placement === undefined || placement === null) ? 'left' : placement;
    trigger = (trigger === undefined || trigger === null) ? ['hover'] : trigger;

    if (!content) {
      return children;
    }
    const tooltipClassNames = classNames('tamr-tooltip-component', className);
    const tooltipElement = (
      <RBTooltip key="tooltip" ref="tooltip" id="tooltip" className={tooltipClassNames}>
        {content}
      </RBTooltip>
    );
    return (
      <OverlayTrigger
        key="trigger"
        ref="labelerTrigger"
        trigger={trigger}
        placement={placement}
        overlay={tooltipElement}
        delay={delay}
        delayHide={delayHide}
        // @ts-expect-error this is not documented, but is required to make sure the tooltip updates its position if the content changes
        shouldUpdatePosition
      >
        {children}
      </OverlayTrigger>
    );
  }
}

export default TooltipTrigger;
