import { List } from 'immutable';

import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../../models/Model';
import { ArgTypes, checkArg } from '../../utils/ArgValidation';
import { $TSFixMe } from '../../utils/typescript';

class AverageConfidenceRecord extends getModelHelpers({
  classificationPath: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
  averageConfidence: { type: ArgTypes.number },
}, 'AverageConfidenceRecord')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class AverageConfidenceRecordRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  get getClassificationPath() {
    return this.classificationPath;
  }

  get getAverageConfidence() {
    return this.averageConfidence;
  }

  static fromJSON(json: $TSFixMe) {
    const { classificationPath, averageConfidence } = json;
    checkArg({ classificationPath }, ArgTypes.array.of(ArgTypes.string));
    return new AverageConfidenceRecord({
      classificationPath: List(classificationPath),
      averageConfidence,
    });
  }
}

export default AverageConfidenceRecord;
