import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import { updateGroupPolicyMembership } from '../accessControl/AccessControlAsync';
import { getPolicyById, UserGroupPolicyMembership } from '../accessControl/AccessControlStore';
import { UserGroupsCount, UsersCount } from '../accessControl/Count';
import { datasetString, memberProjectString, resourceProjectString } from '../accessControl/PoliciesList';
import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Link from '../components/Link';
import PopoverTrigger from '../components/PopoverTrigger';
import SplitGrid, { Column } from '../components/SplitGrid';
import Cell from '../components/Table/Cell';
import TooltipTrigger from '../components/TooltipTrigger';
import AuthorizationPolicy from '../models/AuthorizationPolicy';
import { Roles, RolesE } from '../models/AuthUser';
import { AppAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import style from './PoliciesDialog.module.scss';


const getGroupInPolicy = ({ policyDoc, policyId, userGroupDraftPolicyMembership }: {
  policyDoc: AuthorizationPolicy | undefined
  policyId: number
  userGroupDraftPolicyMembership: UserGroupPolicyMembership | null | undefined
}): string | null | undefined => {
  if (!userGroupDraftPolicyMembership) return null;
  const { draftPoliciesById, group } = userGroupDraftPolicyMembership;
  return draftPoliciesById.has(policyId)
    ? draftPoliciesById.get(policyId)?.roleForGroupname(group.groupname)
    : policyDoc?.roleForGroupname(group.groupname);
};

export default connect((state: AppState) => {
  const { accessControl, accessControl: { policyDocs, userGroupDraftPolicyMembership } } = state;
  return {
    policyDocs,
    userGroupDraftPolicyMembership,
    groupInPolicy: (policyId: number) => getGroupInPolicy({ policyDoc: getPolicyById(accessControl)(policyId), policyId, userGroupDraftPolicyMembership }),
    policyById: getPolicyById(accessControl),
  };
}, {
  onAddToPolicy: ({ policyId, role }: { policyId: number, role: RolesE }): AppAction => ({ type: 'Groups.addToPolicyMembership', policyId, role }),
  onRemoveFromPolicy: (policyId: number): AppAction => ({ type: 'Groups.removeFromPolicyMembership', policyId }),
  onStopEditing: (): AppAction => ({ type: 'Groups.stopEditingPolicyMembership' }),
  onUpdatePolicyMembership: updateGroupPolicyMembership,
})(({ groupInPolicy, policyById, policyDocs, userGroupDraftPolicyMembership, onAddToPolicy, onRemoveFromPolicy, onStopEditing, onUpdatePolicyMembership }) => (!userGroupDraftPolicyMembership ? null : (
  <Dialog
    className={style.policiesDialog}
    dialogStyle={DialogStyle.PRIMARY}
    show
    onHide={onStopEditing}
    title={`Policy membership for ${userGroupDraftPolicyMembership.group.groupname}`}
    body={
      <SplitGrid
        topIds={
          policyDocs
            .filter(doc => doc.data.roleForGroupname(userGroupDraftPolicyMembership.group.groupname))
            .sortBy(doc => doc.data.name)
            .map(doc => doc.id.id)
        }
        bottomIds={
          policyDocs
            .filter(doc => !doc.data.roleForGroupname(userGroupDraftPolicyMembership.group.groupname))
            .sortBy(doc => doc.data.name)
            .map(doc => doc.id.id)
        }
      >
        {/* @ts-expect-error unsure how to not pass in topIds / bottomIds here */}
        <Column
          className={style.nameColumn}
          renderHeader={() => 'Policy name'}
          renderCell={({ id }) => {
            const policy = policyById(id);
            return (
              <div className={style.nameCell}>
                <div className={style.name}>{policy?.name}</div>
                <div className={style.description}>{policy?.description}</div>
              </div>
            );
          }}
        />
        {/* @ts-expect-error unsure how to not pass in topIds / bottomIds here */}
        <Column
          className={style.membersColumn}
          renderHeader={() => 'Members'}
          renderCell={({ id }) => {
            const policy = policyById(id);
            if (!policy) return <Cell />;
            const groupRoleInPolicy = groupInPolicy(id);
            return (
              <div>
                <span>
                  <UsersCount id={id} />,&nbsp;
                  <TooltipTrigger
                    content={!groupRoleInPolicy
                      ? null
                      : <span>Group has {groupRoleInPolicy === Roles.CURATOR ? 'Curator' : (groupRoleInPolicy === Roles.VERIFIER ? 'Verifier' : 'Reviewer')} permissions in this policy</span>
                    }
                  >
                    <span className={classNames({ [style.inPolicy]: groupInPolicy(id) })}><UserGroupsCount id={id} />,&nbsp;</span>
                  </TooltipTrigger>
                  {memberProjectString(policy)}
                </span>
              </div>
            );
          }}
        />
        {/* @ts-expect-error unsure how to not pass in topIds / bottomIds here */}
        <Column
          className={style.resourcesColumn}
          renderHeader={() => 'Resources'}
          renderCell={({ id }) => {
            const policy = policyById(id);
            if (!policy) return <Cell />;
            return (
              <div>
                <span>{resourceProjectString(policy)},&nbsp;</span>
                <span>{datasetString(policy)}</span>
              </div>
            );
          }}
        />
        {/* @ts-expect-error unsure how to not pass in topIds / bottomIds here */}
        <Column
          className={style.actionColumn}
          renderHeader={() => null}
          renderCell={({ id }) => {
            const policy = policyById(id);
            if (!policy) return <Cell />;
            return (
              <div className={style.actionButtonCell}>
                {getGroupInPolicy({ policyDoc: policy, policyId: id, userGroupDraftPolicyMembership })
                  ? (
                    <TooltipTrigger
                      content={policy.appliesToAllUserGroups()
                        ? 'You cannot remove groups from a policy that includes all groups. Admins can change this setting on the Policies page.'
                        : 'Remove group from policy'
                      }
                      placement="top"
                    >
                      <Button
                        className={style.removeFromPolicyButton}
                        buttonType="Link"
                        icon="close"
                        iconClassName={style.removeFromPolicyIcon}
                        iconSize={14}
                        onClick={() => onRemoveFromPolicy(id)}
                        disabled={!!policy.appliesToAllUserGroups()}
                      />
                    </TooltipTrigger>
                  ) : (
                    <PopoverTrigger
                      className={style.addToPolicyPopover}
                      content={
                        <div className={style.optionsList}>
                          <Button
                            buttonType="Link"
                            onClick={() => onAddToPolicy({ policyId: id, role: Roles.REVIEWER })}
                          >Add as Reviewer
                          </Button>
                          <Button
                            buttonType="Link"
                            onClick={() => onAddToPolicy({ policyId: id, role: Roles.VERIFIER })}
                          >Add as Verifier
                          </Button>
                          <Button
                            buttonType="Link"
                            onClick={() => onAddToPolicy({ policyId: id, role: Roles.CURATOR })}
                          >Add as Curator
                          </Button>
                          <Button
                            buttonType="Link"
                            onClick={() => onAddToPolicy({ policyId: id, role: Roles.AUTHOR })}
                          >Add as Author
                          </Button>
                        </div>
                      }
                    >
                      <Button
                        className={style.addToPolicyButton}
                        buttonType="Link"
                        icon="tamr-icon-add"
                        iconSize={14}
                      />
                    </PopoverTrigger>
                  )
                }
              </div>
            );
          }}
        />
      </SplitGrid>
    }
    footer={
      <div className={style.footer}>
        <Link className={style.linkToPolicies} to={'/access/policies'}>
          Manage policies from Policies page
        </Link>
        <ButtonToolbar>
          <Button
            buttonType="Secondary"
            onClick={onStopEditing}
          >
            Cancel
          </Button>
          <Button
            onClick={onUpdatePolicyMembership}
          >
            Save
          </Button>
        </ButtonToolbar>
      </div>
    }
  />
)));
