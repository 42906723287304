// com.tamr.procurify.models.CategorizationProjectStatusAgg.ModelPerformance
import { ArgTypes } from '../utils/ArgValidation';
import Model from './Model';

const ModelPerformance = Model({
  time: { type: ArgTypes.timestamp },
  agreesWithTamr: { type: ArgTypes.orNull(ArgTypes.number) },
  disagreesWithTamr: { type: ArgTypes.orNull(ArgTypes.number) },
  accuracy: { type: ArgTypes.orNull(ArgTypes.number) },
});

export default ModelPerformance;
