import { List, Map, Set } from 'immutable';
import moment from 'moment';
import React from 'react';
import _ from 'underscore';

import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Dataset from '../models/Dataset';
import DatasetStatus from '../models/DatasetStatus';
import Document from '../models/doc/Document';
import Export from '../models/Export';
// @ts-expect-error
import DatasetExportCell from './DatasetExportCell';

const DatasetExportColumn: React.FC<{
  datasets: List<Document<Dataset>>
  exporting: Map<number, Document<Export>>
  status: Map<string, DatasetStatus>
  currentlyExportingDatasetIds: Set<number>
  onConfirmExport: (datasetNames: Set<string>) => void
  onConfirmOverwriteExport: (datasetNames: Set<string>) => void
  onShowExportFailureDialog: (errorMessage: string) => void
  coreconnectDefaultProvider: string | undefined
  onOpenCoreConnectExportDialog: () => void
  onOpenCoreConnectExportJDBCDialog: () => void
  onSetTamrSourceDataset: (tamrSourceDataset: Dataset) => void
}> = ({ datasets, exporting, status, currentlyExportingDatasetIds, onConfirmExport, onConfirmOverwriteExport, onShowExportFailureDialog, coreconnectDefaultProvider, onOpenCoreConnectExportDialog, onOpenCoreConnectExportJDBCDialog, onSetTamrSourceDataset }) => {
  return (
    <Column
      key="export"
      columnKey="export"
      width={160}
      header={<Cell>Export</Cell>}
      cell={({ rowIndex }) => {
        const dataset = datasets.get(rowIndex);
        if (!dataset) return <Cell />;
        const exportRevision = exporting.getIn([dataset.id.id, 'data', 'revision']);
        const errorMessage = exporting.getIn([dataset.id.id, 'data', 'errorMessage']);
        const exportUpToDate = !(_.isNumber(exportRevision) && exportRevision !== status.get(dataset.data.name)?.currentRevision);
        const exportStatus = currentlyExportingDatasetIds.has(dataset.id.id) ? 'PENDING' : exporting.getIn([dataset.id.id, 'data', 'status']);
        const lastGenerated = moment.unix(exporting.getIn([dataset.id.id, 'lastModified', 'timestamp'])).fromNow();
        const exportingTooltip = 'This will update the download link to the latest version. This may take a while.';
        const exportingToCoreConnectTooltip = `This will send the export to ${coreconnectDefaultProvider?.toUpperCase()}`;
        return (
          <DatasetExportCell
            dataset={dataset}
            errorMessage={errorMessage}
            exportUpToDate={exportUpToDate}
            exportStatus={exportStatus}
            lastGenerated={lastGenerated}
            exportingTooltip={exportingTooltip}
            onShowExportFailureDialog={onShowExportFailureDialog}
            onConfirmOverwriteExport={onConfirmOverwriteExport}
            onConfirmExport={onConfirmExport}
            coreconnectDefaultProvider={coreconnectDefaultProvider}
            exportingToCoreConnectTooltip={exportingToCoreConnectTooltip}
            onOpenCoreConnectExportDialog={onOpenCoreConnectExportDialog}
            onOpenCoreConnectExportJDBCDialog={onOpenCoreConnectExportJDBCDialog}
            onSetTamrSourceDataset={onSetTamrSourceDataset}
          />
        );
      }}
    />
  );
};

export default DatasetExportColumn;
