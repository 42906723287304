import { useEffect, useState } from 'react';

/**
 * Preserves some value until it changes such that the updateCondition returns true.
 * Useful for components like dialogs that want to fade out while maintaining the state they just had.
 * If a dialog starts fading out due to some passed in prop being unset upstream, the state that the dialog was
 *   using to render is no longer available.
 * This helps preserve that state.
 */
export function usePreserveValue<T, U extends any[] = [T]>(
  incomingValue: T,
  updateInformation?: {
    parameters: U
    condition: () => boolean,
  },
): T {
  if (!updateInformation) {
    updateInformation = {
      parameters: [incomingValue] as U,
      condition: () => incomingValue !== undefined && incomingValue !== null,
    };
  }
  const [stateValue, setStateValue] = useState(incomingValue);
  useEffect(() => {
    if (updateInformation?.condition()) setStateValue(incomingValue);
  }, updateInformation.parameters);
  return stateValue;
}
