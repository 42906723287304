import CoreConnectProvider from '../coreConnectService/CoreConnectProvider';

export const DatasetSource = {
  UPLOAD: 'Upload File',
  CATALOG: 'Select from Dataset Catalog',
  CONNECT: 'Connect to Source',
  S3: `Connect to ${CoreConnectProvider.get('S3')}`,
  GCS: `Connect to ${CoreConnectProvider.get('GCS')}`,
  ADLS2: `Connect to ${CoreConnectProvider.get('ADLS2')}`,
  JDBC: 'Connect with JDBC',
} as const;
export type DatasetSourceE = typeof DatasetSource[keyof typeof DatasetSource];

export default DatasetSource;
