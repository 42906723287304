import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import ListSelector from '../components/ListSelector/ListSelector';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';

const DatasetSelectionDialog = _.compose(
  connect((state) => {
    const { allSourceDatasets: { datasets } } = state;
    const sourceDatasetNames = datasets.map(d => d.data.name).toSet();
    return { datasets, sourceDatasetNames };
  }),
)(class DatasetSelectionDialog extends React.Component {
  static propTypes = {
    datasetSelectionDelta: ImmutablePropTypes.mapOf(PropTypes.bool, PropTypes.string).isRequired,
    datasets: ImmutablePropTypes.listOf(Document.propType.withDataType(Dataset)),
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    searchValue: PropTypes.string.isRequired,
    selectedDatasets: ImmutablePropTypes.setOf(PropTypes.string),
    setSearchValue: PropTypes.func.isRequired,
    setSelectionDelta: PropTypes.func.isRequired,
    showDialog: PropTypes.bool.isRequired,
    sourceDatasetNames: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
  };

  resetState = () => {
    this.props.setSearchValue('');
    this.props.setSelectionDelta(new Map());
  };

  onHide = () => {
    this.props.onHide();
    this.resetState();
  };

  onSubmit = () => {
    this.props.onSubmit(this.props.datasetSelectionDelta);
    this.onHide();
  };

  onChange = (changedData) => {
    this.props.setSelectionDelta(this.props.datasetSelectionDelta.merge(changedData));
  };

  onSelectAll = (select) => {
    const { sourceDatasetNames } = this.props;
    const selectAllWithNameKeys = Map(sourceDatasetNames.map(name => [name, select]));
    this.props.setSelectionDelta(this.props.datasetSelectionDelta.merge(selectAllWithNameKeys));
  };

  onSearch = (value) => {
    this.props.setSearchValue(value);
  };

  getDatasets = () => {
    return this.props.datasets.map((dataset) => {
      const name = dataset.data.name;
      const delta = this.props.datasetSelectionDelta;
      return {
        value: name,
        selected: delta.has(name)
          ? delta.get(name)
          : this.props.selectedDatasets.has(name),
        label: dataset.data.name,
      };
    }).filter(dataset => {
      return dataset.label.toLowerCase().indexOf(this.props.searchValue.toLowerCase()) > -1;
    }).sortBy(dataset => dataset.label.toLowerCase());
  };

  getDatasetSelector = () => {
    return (
      <ListSelector
        values={this.getDatasets()}
        enableSearch
        height={250}
        onSelect={this.onChange}
        onSearch={this.onSearch}
        searchValue={this.props.searchValue}
    />
    );
  };

  render() {
    return (
      <Dialog
        className="form-upload-dialog"
        show={this.props.showDialog}
        onHide={this.onHide}
        title="Select Datasets"
        dialogStyle={DialogStyle.PRIMARY}
        body={this.getDatasetSelector()}
        footer={(
          <div className="footer-buttons">
            <ButtonToolbar>
              <Button
                onClick={() => this.onSelectAll(true)}
                buttonType="Link"
              >
                Select All
              </Button>
              <Button
                onClick={() => this.onSelectAll(false)}
                buttonType="Link"
              >
                Unselect All
              </Button>
              <Button
                style={{ marginLeft: 'auto' }}
                onClick={this.onHide}
                buttonType="Secondary"
              >
                Cancel
              </Button>
              <Button
                onClick={this.onSubmit}
              >
                Done
              </Button>
            </ButtonToolbar>
          </div>
        )}
      />
    );
  }
});

export default DatasetSelectionDialog;
