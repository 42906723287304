import { List } from 'immutable';

import modelJSON from '../../../../../../connect/auth/models/src/main/resources/fixtures/models/QueryAuthUsersResponse.json';
import AuthUser from '../../models/AuthUser';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../../models/Model';
import { ArgTypes, checkArg } from '../../utils/ArgValidation';
import { $TSFixMe } from '../../utils/typescript';


export class QueryAuthUsersResponse extends getModelHelpers({
  total: { type: ArgTypes.number },
  authUsers: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(AuthUser)) },
}, 'QueryAuthUsersResponse')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class QueryAuthUsersResponseRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  static fromJSON(data: $TSFixMe) {
    checkArg({ data }, ArgTypes.object);
    return new QueryAuthUsersResponse({
      total: data.total,
      authUsers: List(data.authUsers).map(AuthUser.fromJSON),
    });
  }
}

export const queryAuthUsersResponseJSON = modelJSON;
export const sampleQueryAuthUsersResponse = QueryAuthUsersResponse.fromJSON(modelJSON);

export default QueryAuthUsersResponse;
