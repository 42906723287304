import React from 'react';
import { DragLayer as dragLayer } from 'react-dnd';
import { connect } from 'react-redux';
import { toClass } from 'recompose';
import _ from 'underscore';

import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import { shortFormat } from '../utils/Numbers';
import style from './SuppliersDragLayer.module.scss';
import { getSelectedClusters, getSelectedRecords } from './SuppliersStore';

const SuppliersDragLayer = _.compose(
  dragLayer(monitor => {
    const item = monitor.getItem();
    return { itemType: monitor.getItemType(), clientOffset: monitor.getClientOffset(), pane: item && item.pane };
  }),
  connect((state, { pane }) => {
    return {
      selectedClusters: pane && getSelectedClusters(state.suppliers, pane),
      selectedRecords: pane && getSelectedRecords(state, pane),
    };
  }),
  toClass,
)(({ itemType, clientOffset, selectedClusters, selectedRecords }) => {
  const transform = clientOffset ? { transform: `translate(-15px, -50%) translate(${clientOffset.x}px, ${clientOffset.y}px)` } : {};
  // target is mergeClusterId
  // dragged item(s) is selectedClusters
  switch (itemType) {
    case 'cluster': return (
      <div className={style.cluster} style={transform}>
        <TamrIcon iconName="drag-handle" size={14} />
        <div className={style.clusterName}>
          {selectedClusters.size === 1 ? selectedClusters.first().name : <span>{selectedClusters.size} clusters</span>}
        </div>
        <div>
          {shortFormat(selectedClusters.reduce((rn, c) => rn + c.recordCount, 0))}
        </div>
      </div>
    );
    case 'record': return (
      <div className={style.record} style={transform}>
        <TamrIcon iconName="drag-handle" size={14} />
        <div>
          {selectedRecords.size === 1 ? selectedRecords.first().clusterName : <span>{selectedRecords.size} <Term>records</Term></span>}
        </div>
      </div>
    );
    default: return <div />;
  }
});

export default SuppliersDragLayer;
