import { List, Map } from 'immutable';
import _ from 'underscore';

import RecipeOperations, { RecipeOperationsE } from '../constants/RecipeOperations';
import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import Document from './doc/Document';
import MaterializationStatus from './MaterializationStatus';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';
import Recipe from './Recipe';

class RecipeWithStatus extends getModelHelpers({
  recipe: { type: Document.argTypeWithNestedClass(Recipe) },
  materializations: { type: ArgTypes.Immutable.map.of(ArgTypes.instanceOf(MaterializationStatus), RecipeOperations.argType) },
  upgradeFailures: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
  current: { type: ArgTypes.bool },
}, 'RecipeWithStatus')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class RecipeWithStatusRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: $TSFixMe) {
    const { current } = obj;
    return new RecipeWithStatus({
      recipe: Document.fromJSON(obj.recipe, Recipe.fromJSON),
      materializations: Map(_.mapObject(obj.materializations, MaterializationStatus.fromJSON)) as Map<RecipeOperationsE, MaterializationStatus>,
      upgradeFailures: List(obj.upgradeFailures),
      current,
    });
  }
}

export default RecipeWithStatus;
