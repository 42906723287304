import { List } from 'immutable';

import { ArrayType, doubleType, Field, RecordType } from '../transforms/models/Types';

export const GEOTAMR_RECORD_TYPE = new RecordType({
  fullySpecified: true,
  fields: List([
    new Field({
      name: 'point',
      type: new ArrayType({
        elementType: doubleType(),
      }),
    }),
    new Field({
      name: 'multiPoint',
      type: new ArrayType({
        elementType: new ArrayType({
          elementType: doubleType(),
        }),
      }),
    }),
    new Field({
      name: 'lineString',
      type: new ArrayType({
        elementType: new ArrayType({
          elementType: doubleType(),
        }),
      }),
    }),
    new Field({
      name: 'multiLineString',
      type: new ArrayType({
        elementType: new ArrayType({
          elementType: new ArrayType({
            elementType: doubleType(),
          }),
        }),
      }),
    }),
    new Field({
      name: 'polygon',
      type: new ArrayType({
        elementType: new ArrayType({
          elementType: new ArrayType({
            elementType: doubleType(),
          }),
        }),
      }),
    }),
    new Field({
      name: 'multiPolygon',
      type: new ArrayType({
        elementType: new ArrayType({
          elementType: new ArrayType({
            elementType: new ArrayType({
              elementType: doubleType(),
            }),
          }),
        }),
      }),
    }),
  ]),
});
