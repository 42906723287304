import React from 'react';
import { connect } from 'react-redux';

// @ts-ignore
import { startAddDataset } from '../addDataset/AddDatasetAsync';
import DatasetManagementDialog from '../addDataset/DatasetManagementDialog';
import PlainInput from '../components/Input/PlainInput';
import Selector from '../components/Input/Selector';
import LoadingPanel from '../components/LoadingPanel';
import { AppState } from '../stores/MainStore';
import { noop } from '../utils/Values';
import {
  fetchEnricherTypes,
  fetchEnrichmentModule,
  setDatasetName,
  setEnricherType,
  setFieldMapping,
} from './EnrichmentAsync';
import EnrichmentBox from './EnrichmentBox';
import EnrichmentHeader from './EnrichmentHeader';
import style from './EnrichmentMain.module.scss';


const EnrichmentMain = connect((state: AppState) => {
  const { location: { moduleId }, enrichment: { types, typesLoading, module, moduleLoading, inputDataset, inputDatasetLoading, moduleHasBeenSubmitted } } = state;
  return {
    moduleId,
    unifiedDatasetName: 'getUnifiedDatasetName(state)',
    enrichers: types,
    isMappable: !!module?.data.inputDataset && !!module?.data.enricherType,
    typesLoading,
    module,
    moduleLoading,
    inputDataset,
    inputDatasetLoading,
    moduleHasBeenSubmitted,
  };
}, {
  onFetchEnrichmentModule: fetchEnrichmentModule,
  onFetchEnricherTypes: fetchEnricherTypes,
  onSetEnricherType: setEnricherType,
  onSetFieldMapping: setFieldMapping,
  onSetDatasetName: setDatasetName,
  showFileUploadDialog: () => startAddDataset({ isCatalogPage: true }),

})(({ moduleId, typesLoading, enrichers, showFileUploadDialog,
  onSetEnricherType, onSetFieldMapping, onFetchEnricherTypes, onFetchEnrichmentModule,
  module, moduleLoading, inputDataset, inputDatasetLoading, isMappable, onSetDatasetName, moduleHasBeenSubmitted }) => {
  React.useEffect(() => {
    if (moduleId) {
      onFetchEnrichmentModule();
    }
    onFetchEnricherTypes();
  }, []);

  const values = enrichers.map(e => ({ value: e.id, display: e.name }));
  const fields = inputDataset?.data?.fields.toJSON() || [];
  const initLoading = typesLoading || moduleLoading || inputDatasetLoading;

  const attributes = [{ value: '', display: <em>Do not map</em> }].concat(fields.map(f => ({ value: f, display: <span>{f}</span> })));
  return (
    <div className={style.container}>
      {initLoading && <LoadingPanel />}
      {!initLoading && (
        <div>
          <DatasetManagementDialog />
          <EnrichmentHeader />
          <EnrichmentBox
            title="Setup"
            boxNum={1}
            active={!moduleHasBeenSubmitted}
          >
            <div className={style.setupContainer}>
              <div className={style.setupItemContainer}>
                <div className={style.label}>Input Dataset</div>
                <PlainInput disabled={moduleHasBeenSubmitted} title="" value={module?.data?.inputDataset || ''} onClick={showFileUploadDialog} onChange={noop} />
              </div>
              <div className={style.setupItemContainer}>
                <div className={style.label}>Enrichment Type</div>
                <Selector disabled={moduleHasBeenSubmitted} onChange={(v) => { const type = enrichers.find(e => e.id === v); return type && onSetEnricherType(type); }} values={values} value={module?.data?.enricherType?.id} />
              </div>
              <div className={style.setupItemContainer}>
                <div className={style.label}>Enriched Dataset Name</div>
                <PlainInput disabled={!!module?.data?.outputDataset} title="" defaultValue={module?.data?.outputDataset || ''} onBlur={(e) => onSetDatasetName(e.target.value)} />
              </div>
            </div>
          </EnrichmentBox>
          <EnrichmentBox
            title="Map Columns"
            boxNum={2}
            active={isMappable}
          >
            {!isMappable && <div className={style.mappingNotAllowedContainer}>Please select an input dataset and an enrichment type</div>}
            {isMappable && <div className={style.mappingContainer}>
              <div className={style.table}>
                <div className={style.tableHeader}>
                  <div className={style.inputColumn}>
                    Input Attribute
                    <span className={style.headerSeparator} />
                  </div>
                  <div className={style.outputColumn}>
                    Mapped Attribute
                    <span className={style.headerSeparator} />
                  </div>
                  <div className={style.typeColumn}>
                    Type
                    <span className={style.headerSeparator} />
                  </div>
                  <div className={style.descriptionColumn}>
                    Description
                  </div>
                </div>
                <div className={style.tableBody}>
                  {module?.data.enricherType?.schema.inputs?.map((i, idx) => {
                    return (
                      <div key={idx} className={style.mappingRow}>
                        <div className={style.inputColumn}>
                          {attributes && <Selector
                            onChange={v => onSetFieldMapping(v || '', i.name)}
                            values={attributes}
                            value={module?.data.inputFields.find(f => f.enricherName === i.name)?.inputName}
                          />}
                        </div>
                        <div className={style.outputColumn}>
                          {i.name} {i.required ? '*' : ''}
                        </div>
                        <div className={style.typeColumn}>
                          {i.type}
                        </div>
                        <div className={style.descriptionColumn}>
                          {i.description}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>}
          </EnrichmentBox>
          {isMappable && <div className={style.outputSchemaContainer}>
            <div className={style.table}>
              <div className={style.outputTableHeader}>
                <div className={style.outputNameColumn}>
                  Field Name
                  <span className={style.headerSeparator} />
                </div>
                <div className={style.outputTypeColumn}>
                  Type
                  <span className={style.headerSeparator} />
                </div>
                <div className={style.outputDescriptionColumn}>
                  Description
                </div>
              </div>
              <div className={style.tableBody}>
                {module?.data.enricherType?.schema.outputs?.map((i, idx) => {
                  return (
                    <div key={idx} className={style.outputTableRow}>
                      <div className={style.outputNameColumn}>
                        {i.name}
                      </div>
                      <div className={style.outputTypeColumn}>
                        {i.type}
                      </div>
                      <div className={style.outputDescriptionColumn}>
                        {i.description}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>}
        </div>
      )}
    </div>
  );
});

export default EnrichmentMain;
