import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../../components/Button';
import AttributeSelectionDialog from './AttributeSelectionDialog';
import AttributeSimilarityFilter from './AttributeSimilarityFilter';
import styles from './AttributeSimilarityFilterSection.module.scss';
import FilterSection from './FilterSection';


const AttributeSimilarityFilterSection = _.compose(
  connect(state => {
    const { recordPairs: { attributeSimilarityFilterStates } } = state;
    return { attributeSimilarityFilterStates };
  }, {
    showAddFiltersDialog: () => ({ type: 'RecordPairs.showAddAttributeSimilarityFilterDialog' }),
  }),
)(({ showAddFiltersDialog, attributeSimilarityFilterStates }) => {
  return (
    <FilterSection titleLabel="Attribute similarities">
      <div>
        {Array.from(attributeSimilarityFilterStates.map(({ attributeName }) => <AttributeSimilarityFilter key={attributeName} attributeName={attributeName} />).values())}
      </div>
      <Button
        className={styles.addFiltersButton}
        buttonType="Link"
        onClick={showAddFiltersDialog}
      >
        Add attributes
      </Button>
      <AttributeSelectionDialog />
    </FilterSection>
  );
});

export default AttributeSimilarityFilterSection;
