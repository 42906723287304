import { COLUMN_PREFIX, DATASET_ID_PREFIX } from '../constants/ElasticConstants';
import { ArgTypes, checkArg } from './ArgValidation';

const hasColumnPrefix = function (str: string) {
  checkArg({ str }, ArgTypes.string);
  return str.substring(0, COLUMN_PREFIX.length) === COLUMN_PREFIX;
};

const ElasticUtils = {
  hasColumnPrefix,
  extractDatasetIdFromType(str: string) {
    checkArg({ str }, ArgTypes.string);
    return parseInt(str.substring(DATASET_ID_PREFIX.length), 10);
  },
  sanitizeField(str: string) {
    checkArg({ str }, ArgTypes.string);
    return `${COLUMN_PREFIX}${str}`;
  },
  removeColumnPrefix(str: string) {
    checkArg({ str }, ArgTypes.string);
    if (hasColumnPrefix(str)) {
      return str.slice(COLUMN_PREFIX.length);
    }
    return str;
  },
};

export default ElasticUtils;
