import classNames from 'classnames';
import { List } from 'immutable';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import _ from 'underscore';

import style from './BulletedTooltipTrigger.module.scss';
import TooltipTrigger, { propTypes as tooltipTriggerPropTypes } from './TooltipTrigger';

const propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  items: ImmutablePropTypes.listOf(PropTypes.node),
  label: PropTypes.node,
  placement: tooltipTriggerPropTypes.placement,
  trigger: PropTypes.arrayOf(PropTypes.string.isRequired),
};

type BulletedTooltipTriggerProps = InferProps<typeof propTypes>;

// if label is undefined and items are empty, just return children
// if label is defined and items are empty, wrap with label in tooltip
// if label is undefined and items has size 1, show single item (no bullet) in tooltip on hover (by default)
// if label is defined and items has size 1, show single item with bullet
// if items has size >1, show bulleted list in tooltip on hover, with label if defined
export default class BulletedTooltipTrigger extends React.Component<BulletedTooltipTriggerProps> {
  static propTypes = propTypes;

  render() {
    const { children, className, label } = this.props;

    // TODO default-props-in-typescript
    let { items, placement, trigger } = this.props;
    items = (items === undefined || items === null) ? List() : items;
    placement = (placement === undefined || placement === null) ? 'bottom' : placement;
    trigger = (trigger === undefined || trigger === null) ? ['hover'] : trigger;

    const labelDefined = !_.isEmpty(label);
    if (!labelDefined && items.isEmpty()) {
      return children;
    }
    let tooltipContent;
    if (labelDefined && items.isEmpty()) {
      tooltipContent = label;
    } else { // there are items
      const showBullet = labelDefined || items.size > 1;
      const itemsList = (
        <div className={style.items}>
          {items.map((item, i) => (
            <div key={i} className={style.item}>{showBullet ? <span>&bull;&nbsp;</span> : null}{item}</div>
          )).toJSON()}
        </div>
      );
      if (!labelDefined) {
        tooltipContent = itemsList;
      } else {
        tooltipContent = (
          <div className={style.labelAndItemsContainer}>
            <div className={style.label}>{label}</div>
            {itemsList}
          </div>
        );
      }
    }
    return (
      <TooltipTrigger
        className={classNames(style.tooltipContainer, className, { [style.exactlyOneElement]: items.size === 1 })}
        trigger={trigger}
        placement={placement}
        content={tooltipContent}
      >
        {children}
      </TooltipTrigger>
    );
  }
}
