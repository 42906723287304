import React from 'react';
import { connect } from 'react-redux';

import CenterContent from '../components/CenterContent';
import DetailSidebar from '../components/DetailSidebar/DetailSidebar';
import Term from '../components/Term';
import ClusterSidebarClusterContent from './ClusterSidebarClusterContent';
import ClusterSidebarRecordContent from './ClusterSidebarRecordContent';
import { getActiveCluster, getActiveRecord } from './SuppliersStore';

const NO_SELECTION_MESSAGE = <div>Select a <Term>record</Term> or cluster to view its details.</div>;

@connect(state => {
  const { suppliers: { expanded, focusedPane } } = state;
  return {
    isExpanded: expanded,
    clusterSelected: !!getActiveCluster(state, focusedPane),
    recordSelected: !!getActiveRecord(state, focusedPane),
  };
})
export default class ClusterSidebar extends React.Component {
  render() {
    const { clusterSelected, recordSelected, isExpanded } = this.props;
    return (
      <DetailSidebar
        className="transaction-sidebar"
        animate
        isExpanded={isExpanded}
      >
        {recordSelected
          ? <ClusterSidebarRecordContent />
          : clusterSelected
            ? <ClusterSidebarClusterContent />
            : <CenterContent className="no-content">{NO_SELECTION_MESSAGE}</CenterContent>
        }
      </DetailSidebar>
    );
  }
}
