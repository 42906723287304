import classnames from 'classnames';
import { List, Map, Set } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'underscore';

import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import { AppState } from '../stores/MainStore';
import { getUrlForPage } from '../utils/Routing';
import { pluralize } from '../utils/Strings';
import GeneratedRuleIcon from './GeneratedRuleIcon';
import style from './GoldenRecordsSidebar.module.scss';
import { DEFAULT_PAGE_SIZE, getValidationErrorsForRule, isRuleNameEntityRule } from './GoldenRecordsStore';
import { Rule } from './Rule';
import { RuleDeltaTypes } from './RuleDelta';
import RULES_OPTIONS from './RulesOptions';


type SidebarRuleSummaryOwnProps = {
  name: string
  rule?: Rule
  validationErrors?: List<string>
  readonly?: boolean
}

const SidebarRuleSummary = connect((state: AppState, { name, rule, validationErrors }: SidebarRuleSummaryOwnProps) => {
  const { goldenRecords, goldenRecords: { expandedRuleNames, nextRules, hasOverrides, nextEntityRule } } = state;
  const isEntityRule = isRuleNameEntityRule(goldenRecords, name);
  const isExpanded = expandedRuleNames.has(name);
  let ruleDelta;
  if (!rule) {
    const ruleDeltaIndex = isEntityRule ? undefined : nextRules.findIndex((rd) => rd.rule.outputAttributeName === name);
    ruleDelta = isEntityRule ? nextEntityRule : (_.isNumber(ruleDeltaIndex) && nextRules.get(ruleDeltaIndex)) || undefined;
  }

  const overrideStats = goldenRecords.overrideStats.get(name);
  return {
    isExpanded,
    ruleDelta,
    rule: rule || ruleDelta?.rule,
    overrideStats,
    validationErrors: validationErrors || getValidationErrorsForRule(state.goldenRecords, { ruleName: name }),
    overrideFiltered: hasOverrides.contains(name),
    filterUrl: getUrlForPage(state.mergeIn(['goldenRecords'], { showSidebar: true, pageNum: 0, pageSize: DEFAULT_PAGE_SIZE, searchString: '', hasOverrides: Set([name]), columnSortStates: Map() }), 'goldenrecords'),
  };
}, (dispatch, { name, rule }) => bindActionCreators({
  toggleExpanded: () => ({ type: 'GoldenRecords.toggleExpanded', name }),
  toggleHasOverrides: () => ({ type: 'GoldenRecords.toggleFilterToRuleWithOverrides', ruleName: rule?.outputAttributeName }),
}, dispatch))(({ toggleExpanded, validationErrors, overrideFiltered, isExpanded, ruleDelta, toggleHasOverrides, overrideStats, rule, readonly, filterUrl }) => {
  const overrideTotal = overrideStats?.totalNumOverrides || 0;
  const hasErrors = !validationErrors.isEmpty();
  const ruleOption = RULES_OPTIONS.find(option => option.value === rule?.type);
  return (
    <div
      className={classnames(style.flexContainer)}
      onClick={toggleExpanded}
    >
      <TamrIcon
        className={style.mx10}
        size={10}
        iconName={isExpanded ? 'tamr-icon-caret' : 'tamr-icon-caret-right'}
      />
      <div className={style.ruleHeaderContainerLeft}>
        <span className={style.ruleNameContainer} title={rule?.outputAttributeName}>
          {rule?.outputAttributeName}
        </span>
        <div className={style.subheading}>
          {!!rule?.suggested && <GeneratedRuleIcon placement="top" />}
          {(ruleOption && 'shortLabel' in ruleOption && ruleOption.shortLabel) || ruleOption?.label || ''} | {`${rule?.filters.length} ${rule && pluralize(rule.filters.length, 'condition', 'conditions')}`}
        </div>
      </div>
      <div className={style.ruleHeaderContainerRight}>
        <div className={style.changes}>
          {ruleDelta && ruleDelta.type !== RuleDeltaTypes.UNCHANGED && (
            <TooltipTrigger content={validationErrors.join('\n') || 'Changes need to be saved'} placement="top">
              <span className={classnames({ [style.error]: hasErrors }, style.validation)}>
                <TamrIcon size={16} iconName="warning" className={style.warning} />{hasErrors ? 'error' : 'draft'}
              </span>
            </TooltipTrigger>
          )}
        </div>
        <p className={style.simpleText}>
          {(overrideTotal > 0 && !readonly) && <a className="mx-4" href={filterUrl} target="_blank" rel="noopener noreferrer">
            <TamrIcon size={14} iconName="filter-list" className={classnames('ml-4', style.verticalMiddle, style.overrideFilter)} />
          </a>}
          {(overrideTotal > 0 && !overrideFiltered && readonly) &&
          <a className="mx-4" onClick={toggleHasOverrides}>
            <TamrIcon size={14} iconName="filter-list" className={classnames('ml-4', style.verticalMiddle, style.overrideFilter)} />
          </a>}
          {(readonly && overrideFiltered) &&
            <a className="mx-4" onClick={toggleHasOverrides}>
              <TamrIcon size={14} iconName="filter-list" className={classnames('ml-4', style.verticalMiddle, style.overrideFilterClear)} />
              </a>}
          {overrideTotal} {pluralize(overrideTotal, 'override', 'overrides')}
          <TooltipTrigger content="You can override the rule and enter a specific value for a golden record by clicking on the edit pencil in a table cell." placement="top"><TamrIcon size={14} iconName="info-outline" className={classnames('ml-4', style.verticalMiddle)} /></TooltipTrigger>
        </p>
      </div>
    </div>
  );
});

export default SidebarRuleSummary;
