import classNames from 'classnames';
import React from 'react';

import styles from './FilterSection.module.scss';

// just to standardize the styling/layout of sections in RecordPairsFilterPanel
const FilterSection: React.FC<{
  children: React.ReactNode
  className?: string
  titleLabel: React.ReactNode,
}> = ({ children, className, titleLabel }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.title}>{titleLabel}</div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default FilterSection;
