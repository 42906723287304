import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import TamrIcon from '../components/TamrIcon';
import WarningDialog from '../components/WarningDialog';
import { profileDataset } from '../datasets/DatasetsApi';
import { history } from '../utils/History';
import { getCurrentlyProfilingDatasetNames } from '../utils/Selectors';
import { pluralize } from '../utils/Strings';
import style from './RecordsProfileWarning.module.scss';

const RecordsProfileWarning = _.compose(
  connect((state) => {
    const { records: { datasetsToProfile, finishedProfiling } } = state;
    const numberOfProfilingJobs = getCurrentlyProfilingDatasetNames(state).intersect(datasetsToProfile).size;
    return { datasetsToProfile, finishedProfiling, numberOfProfilingJobs };
  }, {
    onProfile: profileDataset,
  }),
)(class RecordsProfileWarning extends React.Component {
  static propTypes = {
    datasetsToProfile: ImmutablePropTypes.listOf(PropTypes.string).isRequired,
    finishedProfiling: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
    numberOfProfilingJobs: PropTypes.number.isRequired,
  };

  state = {
    showingProfileWarningDialog: false,
  };

  profileDatasets = () => {
    this.props.datasetsToProfile.forEach(this.props.onProfile);
  };

  showProfileWarningDialog = () => {
    this.setState({ showingProfileWarningDialog: true });
  };

  hideProfileWarningDialog = () => {
    this.setState({ showingProfileWarningDialog: false });
  };

  navigateToJobsPage = () => {
    history.push('/jobs');
  };

  render() {
    const { datasetsToProfile, finishedProfiling } = this.props;
    if (!datasetsToProfile || datasetsToProfile.isEmpty() || datasetsToProfile.toSet().isSubset(finishedProfiling)) {
      return <div className={style.warningMessage}>&nbsp;</div>; // preserve spacing
    }
    const numSourcesToProfile = this.props.datasetsToProfile.size;
    const numProfilingJobs = this.props.numberOfProfilingJobs;
    const sourceMessage = pluralize(numSourcesToProfile, 'source needs', 'sources need');
    const profileDatasetsMessage = pluralize(numSourcesToProfile, 'a dataset', 'datasets');
    const profilingMessage = `${numProfilingJobs} ${pluralize(numProfilingJobs, 'source is', 'sources are')} being profiled. `;
    const toProfileMessage = `${numSourcesToProfile} ${sourceMessage} profiling. `;

    const link = (
      <span>
        <a onClick={this.showProfileWarningDialog}>Profile now</a>
        <WarningDialog
          actionName="Profile Dataset"
          message={`Profiling ${profileDatasetsMessage} can take a while. Are you sure you want to continue?`}
          onAccept={this.profileDatasets}
          onHide={this.hideProfileWarningDialog}
          show={this.state.showingProfileWarningDialog}
        />
      </span>
    );
    if (numProfilingJobs === 0) {
      return (
        <div className={style.warningMessage}>
          <TamrIcon
            className={style.warningIcon}
            iconName="tamr-icon-warning"
            size={14}
          />
          <span className={style.warningMessage}>
            {toProfileMessage}
          </span>
          {link}
        </div>
      );
    }
    return (
      <div className={style.warningMessage}>
        <span>
          {profilingMessage}
        </span>
        <a onClick={this.navigateToJobsPage}>View Jobs</a>
      </div>
    );
  }
});

export default RecordsProfileWarning;
