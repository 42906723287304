import React, { useState } from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import AppState from '../stores/AppState';
import { decimalFormat } from '../utils/Numbers';
import PRODUCT_NAME from '../utils/ProductName';
import ConfusionMatrix from './ConfusionMatrix';
import styles from './ConfusionMatrixDialog.module.scss';

const InfoIcon: React.FC<{
  children: string
}> = ({ children }) => {
  return (
    <TooltipTrigger placement="left" {...{ content: children }}>
      <TamrIcon
        style={{ marginBottom: '1px', verticalAlign: 'sub' }}
        iconName="info-outline"
        size={14}
      />
    </TooltipTrigger>
  );
};

const Metric: React.FC<{
  name: string
  info: string
  score: number
}> = ({
  name,
  info,
  score,
}) => {
  return (
    <div className={styles.advancedMetric}>
      <span>{name}</span>
      &nbsp;
      <InfoIcon>{info}</InfoIcon>
      <span className={styles.advancedMetricScore}>{decimalFormat(score)}</span>
    </div>
  );
};

const AdvancedMetrics: React.FC<Props> = ({
  precision,
  recall,
  accuracy,
  f1,
}) => {
  return (
    <div className={styles.advancedMetricsContainer}>
      <span className={styles.advancedMetricsTitle}>
        In-sample pair metrics
        &nbsp;
        <InfoIcon>Metrics calculated from training pairs. For metrics that are representative of true performance, use cluster metrics instead.</InfoIcon>
      </span>
      <div className={styles.advancedMetrics}>
        <Metric
          name="Precision"
          info={`The number of verified pairs that ${PRODUCT_NAME} correctly suggested to be matches, divided by the total number of verified pairs that ${PRODUCT_NAME} suggested to be matches (whether correctly or not).`}
          score={precision}
        />
        <Metric
          name="Recall"
          info={`The number of verified pairs that ${PRODUCT_NAME} correctly suggested to be matches, divided by the total number of verified pairs that are actually matches.`}
          score={recall}
        />
        <Metric
          name="Accuracy"
          info={`The percentage of agreement between user-verified responses and ${PRODUCT_NAME}’s suggestions.`}
          score={accuracy}
        />
        <Metric
          name="F-score"
          info="The harmonic average of precision and recall."
          score={f1}
      />
      </div>
    </div>
  );
};

const mapState = (state: AppState) => {
  const { precision, recall, accuracy, f1 } = state.confusionMatrix.matrix;
  return {
    precision,
    recall,
    accuracy,
    f1,
  };
};

type StateProps = ReturnType<typeof mapState>
type Props = StateProps

const ConfusionMatrixDialog: React.FC<Props> = ({
  precision,
  recall,
  accuracy,
  f1,
}) => {
  const [isAdvancedOpen, setAdvancedOpen] = useState(false);
  return (
    <div className={styles.flex}>
      <div className={styles.main}>
        <ConfusionMatrix />
        <Button
          buttonType="Flat"
          onClick={() => setAdvancedOpen(!isAdvancedOpen)}
        >
          {isAdvancedOpen ? 'Hide' : 'View'} advanced metrics
        </Button>
      </div>
      {isAdvancedOpen ? <AdvancedMetrics {...{ precision, recall, accuracy, f1 }} /> : null}
    </div>
  );
};

export default connect(mapState)(ConfusionMatrixDialog);
