import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getUsersWithAccess } from '../api/AuthClient';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import { selectActiveProjectInfo } from '../utils/Selectors';
import FeedbackAssignDialogFooter from './FeedbackAssignDialogFooter';
import FeedbackAssignDialogTitle from './FeedbackAssignDialogTitle';
import UserSelectorContent from './UserSelectorContent';

@connect(state => {
  const projectInfo = selectActiveProjectInfo(state);
  return { projectId: projectInfo?.projectId };
})
export default class FeedbackAssignDialog extends React.Component {
  static propTypes = {
    actionName: PropTypes.string, // If specified overrides the default CTA button text
    busy: PropTypes.bool,
    noun: PropTypes.string,
    onAssign: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    projectId: PropTypes.string,
    recordCount: PropTypes.number.isRequired,
    showDialog: PropTypes.bool.isRequired,
    titleMessage: PropTypes.node, // If specified, override the default title
    userAssignmentCount: PropTypes.func.isRequired,
  };

  async componentDidMount() {
    if (this.props.projectId) {
      this.setState({ users: await getUsersWithAccess('project.read', `projects/${this.props.projectId}`) });
    }
  }
  async componentDidUpdate(prevProps) {
  // Typical usage (don't forget to compare props):
    if (this.props.projectId && this.props.projectId !== prevProps.projectId) {
      this.setState({ users: await getUsersWithAccess('project.read', `projects/${this.props.projectId}`) });
    }
  }

  state = {
    selectedUsers: Map(),
    users: List(),
  };

  resetState = () => {
    this.setState({
      selectedUsers: Map(),
    });
  };

  getUsersToAssign = () => {
    const { userAssignmentCount, recordCount } = this.props;
    return this.state.selectedUsers
      .filter((selectionState, username) => {
        return selectionState === 'selected' && userAssignmentCount(username) !== recordCount;
      })
      .keySeq()
      .toSet();
  };

  getUsersToUnassign = () => {
    const { userAssignmentCount } = this.props;
    return this.state.selectedUsers
      .filter((selectionState, username) => {
        return selectionState === 'unselected' && userAssignmentCount(username) > 0;
      })
      .keySeq()
      .toSet();
  };

  onUserSelect = (userOption) => {
    const username = userOption.key;
    const newSelectionState = this.state.selectedUsers.update(username, selectionState => {
      switch (selectionState) {
        case 'unselected':
        case 'semi':
          return 'selected';
        case 'selected':
          return 'unselected';
      }
    });
    this.setState({ selectedUsers: newSelectionState });
  };

  selectAll = (select = true) => {
    const { users } = this.state;
    this.setState({ selectedUsers: Map(users?.map(({ username }) => [username, select ? 'selected' : 'unselected'])) });
  };

  renderTitle = () => {
    const {
      props: { recordCount, noun, titleMessage },
    } = this;
    if (titleMessage) {
      return <span>{titleMessage}</span>;
    }
    return <FeedbackAssignDialogTitle {...{ recordCount, noun }} numToAssign={this.getUsersToAssign().size} numToUnassign={this.getUsersToUnassign().size} />;
  };

  renderBody = () => {
    const {
      props: { userAssignmentCount, recordCount, noun },
      state: { selectedUsers, users },
    } = this;
    return <UserSelectorContent {...{ userAssignmentCount, recordCount, noun, users }} onUserSelect={this.onUserSelect} selectionState={selectedUsers} />;
  };

  renderFooter = () => {
    const {
      props: { actionName, recordCount, onHide, onAssign, busy },
    } = this;
    return (
      <FeedbackAssignDialogFooter
        {...{ actionName, recordCount, onHide, onAssign, busy }}
        usersToAssign={this.getUsersToAssign()}
        usersToUnassign={this.getUsersToUnassign()}
        onSelectAll={this.selectAll}
        resetState={this.resetState}
      />
    );
  };

  /**
   * Update the selectedUsers state once new props come in
   */
  UNSAFE_componentWillReceiveProps({ showDialog, recordCount, userAssignmentCount }) {
    const { users } = this.state;
    if (!this.props.showDialog && showDialog) {
      const selectedUsers = Map(
        users.map(({ username }) => {
          const assignmentCount = userAssignmentCount(username);
          const getSelectionState = () => {
            switch (true) {
              case assignmentCount === 0:
                return 'unselected';
              case assignmentCount === recordCount:
                return 'selected';
              default:
                return 'semi';
            }
          };
          return [username, getSelectionState()];
        }),
      );
      this.setState({ selectedUsers });
    }
  }

  render() {
    return (
      <Dialog
        className="form-upload-dialog"
        show={this.props.showDialog}
        onHide={this.props.onHide}
        header={this.renderTitle()}
        body={this.renderBody()}
        footer={this.renderFooter()}
        dialogStyle={DialogStyle.PRIMARY}
      />
    );
  }
}
