import { connect } from 'react-redux';
import { pure, withProps } from 'recompose';
import _ from 'underscore';

import Button from '../components/Button';
import { SHOW_CHOOSE_NEW_PROJECT_TYPE } from './ProjectsActionTypes';

const CreateNewProjectButton = _.compose(
  connect(null, {
    onClick: () => ({ type: SHOW_CHOOSE_NEW_PROJECT_TYPE }),
  }),
  withProps({
    buttonType: 'Primary',
    children: 'Create new project',
  }),
  pure,
)(Button);

export default CreateNewProjectButton;
