import { List, Map, Set } from 'immutable';
import React from 'react';
import _ from 'underscore';

import Button from '../components/Button';
import ConditionalButton from '../components/ConditionalButton';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import Dataset from '../models/Dataset';
import DatasetStatus from '../models/DatasetStatus';
import Document from '../models/doc/Document';
import ProfilingInfo from '../models/ProfilingInfo';
import { getPath } from '../utils/Values';
import style from './DatasetNameColumn.module.scss';

const DatasetNameColumn: React.FC<{
  rows: List<Document<Dataset>>
  showPreview?: boolean
  currentlyProfilingDatasetNames?: Set<string>
  profiling?: Map<string, ProfilingInfo>
  status?: Map<string, DatasetStatus>
  onFetchDatasetSample?: (arg: { datasetName: string }) => void
  onConfigureDataset?: (datasetName: string) => void
  canUserPerformActions?: boolean
}> = ({ rows, showPreview, currentlyProfilingDatasetNames, profiling, status, onFetchDatasetSample, onConfigureDataset, canUserPerformActions }) => {
  return (
    <Column
      key="name"
      width={200}
      isResizable
      columnKey="name"
      flexGrow={1}
      header={<Cell>Name</Cell>}
      cell={({ rowIndex }) => {
        const datasetDoc = rows.get(rowIndex);
        if (!datasetDoc) return <Cell />;
        const { data: { name: datasetName } } = datasetDoc;
        const isSampleDataset = showPreview && !!getPath(rows.get(rowIndex), 'data', 'metadata', 'sampleMetadata');
        const profiledRevision = showPreview && profiling && profiling.getIn([datasetName, 'revision']);
        const profileUpToDate = showPreview && status && (!(_.isNumber(profiledRevision) && profiledRevision !== status.get(datasetName)?.currentRevision));
        const isCurrentlyProfiling = showPreview && currentlyProfilingDatasetNames && currentlyProfilingDatasetNames.has(datasetName);
        const profileData = showPreview && profiling && profiling.getIn([datasetName, 'schema']);
        const hasNotProfiled = showPreview && (!profileData || profileData.isEmpty());
        return (
          <Cell className={style.datasetNameCell}>
            <span className={style.datasetName} title={datasetName}>{datasetName}</span>
            {(showPreview && canUserPerformActions) && (
              <TooltipTrigger content="Edit the properties for this dataset" placement="bottom">
                <Button className={style.datasetNameLink} buttonType="Link" onClick={onConfigureDataset && _.partial(onConfigureDataset, datasetName)}>
                  <TamrIcon iconName="edit" size={16} />
                </Button>
              </TooltipTrigger>)}

            {(showPreview && canUserPerformActions) && (
              <ConditionalButton
                className={style.datasetNameLink}
                buttonType="Link"
                onClick={onFetchDatasetSample && (() => onFetchDatasetSample({ datasetName }))}
                preconditions={(() => {
                  if (isSampleDataset) return Map.of('Sample datasets cannot be previewed', false);
                  if (isCurrentlyProfiling) return Map.of('Profiling in progress', false);
                  if (hasNotProfiled) return Map.of('Not profiled', false);
                  if (!profileUpToDate) return Map.of('Profiling is out of date', false);
                  return Map();
                })()
                }
              >
                Preview
              </ConditionalButton>)
            }
          </Cell>
        );
      }}
    />
  );
};

export default DatasetNameColumn;
