import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import { PostButtonSpec } from '../models/PostButtonSpec';
import ProjectInfo from '../models/ProjectInfo';
import { RedirectButtonSpec } from '../models/RedirectButtonSpec';
import AppState from '../stores/AppState';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { doPostAction, getPageName } from './ChromeAsync';
import style from './ExtensionButtonBar.module.scss';
import ExtensionResponseDialog from './ExtensionResponseDialog';

/**
 * Create a button displaying the provided text that directs the user to the provided url
 */
function createRedirectButton(buttonSpec: RedirectButtonSpec, pageName: string, projectId: string) {
  /* eslint-disable no-template-curly-in-string */
  const url = buttonSpec.redirectUrl
    .replace('${pageName}', encodeURIComponent(pageName))
    .replace('${projectId}', encodeURIComponent(projectId))
    .replace('${buttonId}', encodeURIComponent(buttonSpec.buttonId));
  /* eslint-enable no-template-curly-in-string */

  return <Button
    key={buttonSpec.buttonText}
    buttonType="Extension"
    className={style.extensionButton}
    href={url}
    hrefBlank={buttonSpec.openInNewTab}
  >
    <div className={style.extensionButtonText} title={buttonSpec.buttonText}>{buttonSpec.buttonText}</div>
  </Button>;
}

/**
 * Create a button displaying the provided text that calls a POST api
 */
function createPostButton(buttonSpec: PostButtonSpec, pageName: string, projectId: string, onDoPostAction: Function) {
  return <Button
    key={buttonSpec.buttonText}
    buttonType="Extension"
    className={style.extensionButton}
    onClick={() => {
      onDoPostAction(buttonSpec, pageName, projectId);
    }}>
    <div className={style.extensionButtonText} title={buttonSpec.buttonText}>{buttonSpec.buttonText}</div>
  </Button>;
}

/**
 * Displays user-defined extension buttons
 */
const ExtensionButtonBar = connect((state: AppState) => {
  const { location: { page }, chrome: { extensionButtons } } = state;
  const projectInfo: ProjectInfo | null = selectActiveProjectInfo(state);
  return {
    extensionButtons, page, projectInfo,
  };
}, { onDoPostAction: doPostAction },
)(({ extensionButtons, page, projectInfo, onDoPostAction }) => {
  const buttonNodes = extensionButtons.map(
    (spec: RedirectButtonSpec| PostButtonSpec) => {
      const projectId = projectInfo?.projectId.toString() ?? 'null';
      const pageName = getPageName(page, projectInfo);
      if (spec instanceof PostButtonSpec) { return createPostButton(spec, pageName, projectId, onDoPostAction); }
      return createRedirectButton(spec, pageName, projectId);
    },
  );
  return (
    <div className={style.extensionBar}>
      {buttonNodes}
      <ExtensionResponseDialog />
    </div>
  );
});

export default ExtensionButtonBar;
