import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import LoadingPanel from '../components/LoadingPanel';
import { submitExport } from './SharedDatasetCatalogApi';

const ConfirmExportDialog = connect((state) => {
  const { sharedDatasetCatalog: { confirmingExportForDatasets, confirmingOverwriteExportForDatasets, submittingExport } } = state;
  const show = !(confirmingExportForDatasets.isEmpty() && confirmingOverwriteExportForDatasets.isEmpty()) || submittingExport;
  const overwrite = !confirmingOverwriteExportForDatasets.isEmpty();
  return { show, overwrite, submittingExport };
}, {
  onAccept: submitExport,
  onHide: () => ({ type: 'SharedDatasetCatalog.cancelConfirmExport' }),
})(class ConfirmExportDialog extends React.Component {
  static propTypes = {
    onAccept: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    overwrite: PropTypes.bool.isRequired,
    show: PropTypes.bool.isRequired,
    submittingExport: PropTypes.bool.isRequired,
  };

  render() {
    const { show, onHide, onAccept, overwrite, submittingExport } = this.props;
    return (
      <Dialog
        className="confirm-export-dialog"
        show={show}
        onHide={onHide}
        dialogStyle={DialogStyle.PRIMARY}
        body={submittingExport ? (
          <LoadingPanel style={{ position: 'relative', height: '100px' }} />
        ) : show ? (
          <div className="generate-export-body">
            <div className="message">
              {'Generating a dataset export can take a while. '
                + `${overwrite ? 'This will replace the existing export. ' : ''}`
                + 'Are you sure you want to continue?'}
            </div>
          </div>
        ) : null}
        footer={(
          <ButtonToolbar>
            <Button onClick={onHide} buttonType="Secondary">Cancel</Button>
            <Button onClick={onAccept} disabled={submittingExport}>Generate Export</Button>
          </ButtonToolbar>
        )}
      />
    );
  }
});

export default ConfirmExportDialog;
