import PropTypes from 'prop-types';
import React from 'react';
import { pure } from 'recompose';
import _ from 'underscore';

import Term from '../components/Term';
import { longFormat } from '../utils/Numbers';

const TransactionTitle = _.compose(
  pure,
)(class TransactionTitle extends React.Component {
  static propTypes = {
    transactionTotal: PropTypes.number.isRequired,
  };

  render() {
    const { transactionTotal } = this.props;
    return (
      <span><Term>Parts</Term> <span className="page-header-metrics">{longFormat(transactionTotal)}</span></span>
    );
  }
});

export default TransactionTitle;
