import { Map, Set } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import WarningDialog from '../components/WarningDialog';
import { AppState } from '../stores/MainStore';
import style from './ConfirmPublishGoldenRecordsDialog.module.scss';
import { GoldenRecordsActionConfirmationTypeE } from './GoldenRecordsActionConfirmationType';
import { CANCEL_CONFIRMING_PUBLISH } from './GoldenRecordsActionTypes';
import { confirmPublishGoldenRecords, confirmUpdateGoldenRecords } from './GoldenRecordsAsync';
import { selectDatasetsNewToRules } from './GoldenRecordsStore';

const PublishedDatasetMsg: React.FunctionComponent<{ publishedDatasetName?: string }> = ({ publishedDatasetName }) => {
  if (!publishedDatasetName) return <span>&nbsp;</span>;
  return <span>&nbsp;Your published dataset can be found in the dataset catalog as <b>{publishedDatasetName}</b>.&nbsp;</span>;
};

/**
 * Shared component (for Update and Update and Publish actions) to alert user that
 * some Dataset filters do not account for some present sources.
 */
const UnaccountedSourcesDialog: React.FunctionComponent<{
  onHide: () => any
  datasetsNewToRules: Map<string, Set<string>>
  onConfirm: () => any
  operationName: string
  publishedDatasetName?: string // optional in case where action does not include publish
}> = ({ onHide, datasetsNewToRules, onConfirm, operationName, publishedDatasetName }) => {
  return (
    <Dialog
      show
      {...{ onHide }}
      title="New datasets in project"
      dialogStyle={DialogStyle.PRIMARY}
      className={style.dialog}
      body={(
        <div>
          <p>
            There are new datasets in your project since you last updated.
            If you have attribute filters configured, the new datasets will be excluded from these filters. You can:
          </p>
          <ul>
            <li>
              Proceed with {operationName}.
              This will exclude new datasets from attribute filters.
              <PublishedDatasetMsg {...{ publishedDatasetName }} />
              Or,
            </li>
            <li>
              Cancel this action and modify dataset filters so that they apply to the new datasets. You can then repeat {operationName}.
            </li>
          </ul>
          <div className={style.newDatasetListContainer}>
            {datasetsNewToRules.map((ruleNames: Set<string>, datasetName: string) => (
              <div key={datasetName} className={style.newDataset}>
                <span className={style.datasetName}>{datasetName}</span>
                <ul className={style.ruleList}>
                  {ruleNames.map((ruleName: string) => (
                    <li key={ruleName} className={style.rule}>{ruleName}</li>
                  ))}
                </ul>
              </div>
            )).toList()}
          </div>
        </div>
      )}
      footer={(
        <ButtonToolbar>
          <Button onClick={onHide} buttonType="Secondary">Cancel</Button>
          <Button onClick={onConfirm} buttonType="Primary">Exclude datasets</Button>
        </ButtonToolbar>
      )}
    />
  );
};

/**
 * Dialog to confirm Update (not Update and Publish)
 */
const ConfirmUpdateGoldenRecordsDialog = connect((state: AppState) => {
  const { goldenRecords, goldenRecords: { confirmingAction } } = state;
  return {
    show: confirmingAction === GoldenRecordsActionConfirmationTypeE.UPDATE,
    datasetsNewToRules: selectDatasetsNewToRules(goldenRecords),
  };
}, {
  onUpdate: (exclude: boolean) => confirmUpdateGoldenRecords(exclude),
  onHide: () => ({ type: CANCEL_CONFIRMING_PUBLISH }),
})(({ show, datasetsNewToRules, onHide, onUpdate }) => {
  if (!show) {
    return null;
  }
  if (datasetsNewToRules.isEmpty()) {
    // no sourcelist validation concerns
    return (
      <WarningDialog
        {...{ onHide }}
        show
        actionName="Update Golden Records"
        message={(
          <span>
            Update your Golden Records to incorporate your saved rules and data changes.
          </span>
        )}
        onAccept={() => onUpdate(false)}
      />
    );
  }
  return (
    <UnaccountedSourcesDialog
      onHide={onHide}
      onConfirm={() => onUpdate(true)}
      datasetsNewToRules={datasetsNewToRules}
      operationName="Update"
      />
  );
});

/**
 * Dialog to confirm Update and Publish
 */
const ConfirmUpdateAndPublishGoldenRecordsDialog = connect((state: AppState) => {
  const { goldenRecords, goldenRecords: { confirmingAction, goldenRecordDocument } } = state;
  return {
    show: confirmingAction === GoldenRecordsActionConfirmationTypeE.UPDATE_AND_PUBLISH,
    publishedDatasetName: goldenRecordDocument?.data?.goldenRecordDataset,
    datasetsNewToRules: selectDatasetsNewToRules(goldenRecords),
  };
}, {
  onUpdate: (exclude: boolean) => confirmUpdateGoldenRecords(exclude),
  onHide: () => ({ type: CANCEL_CONFIRMING_PUBLISH }),
})(({ show, publishedDatasetName, datasetsNewToRules, onHide, onUpdate }) => {
  if (!show) {
    return null;
  }
  if (datasetsNewToRules.isEmpty()) {
    // no sourcelist validation concerns
    return (
      <WarningDialog
        {...{ onHide }}
        show
        actionName="Update and Publish"
        message={(
          <span>
            Update your Golden Records to incorporate your saved rules and data changes, then
            Publish the Golden Records and overrides.
            <PublishedDatasetMsg {...{ publishedDatasetName }} />
          </span>
        )}
        onAccept={() => onUpdate(false)}
      />
    );
  }
  return (
    <UnaccountedSourcesDialog
      onHide={onHide}
      onConfirm={() => onUpdate(true)}
      datasetsNewToRules={datasetsNewToRules}
      operationName="Update and Publish"
      publishedDatasetName={publishedDatasetName}
      />
  );
});

/**
 * Dialog for confirming the Publish (no update) action.
 * Publishing without an update means publishing the last updated versions of the config and data.
 * We do not perform sourcelist validation in the UI for the user in this case
 *   - it may return as an error message from the server however.
 */
const ConfirmPublishGoldenRecordsDialog = connect((state: AppState) => {
  const { goldenRecords: { confirmingAction, goldenRecordDocument } } = state;
  return {
    show: confirmingAction === GoldenRecordsActionConfirmationTypeE.PUBLISH,
    publishedDatasetName: goldenRecordDocument?.data?.goldenRecordDataset,
  };
}, {
  onPublish: () => confirmPublishGoldenRecords(),
  onHide: () => ({ type: CANCEL_CONFIRMING_PUBLISH }),
})(({ show, publishedDatasetName, onHide, onPublish }) => {
  return (
    <WarningDialog
      {...{ onHide, show }}
      actionName="Publish"
      message={(
        <span>
          Publish the Golden Records and overrides.
          <PublishedDatasetMsg {...{ publishedDatasetName }} />
        </span>
      )}
      onAccept={() => onPublish()}
    />
  );
});

const GoldenRecordsActionConfirmationDialogs = () => {
  return (
    <React.Fragment>
      <ConfirmUpdateGoldenRecordsDialog />
      <ConfirmPublishGoldenRecordsDialog />
      <ConfirmUpdateAndPublishGoldenRecordsDialog />
    </React.Fragment>
  );
};

export default GoldenRecordsActionConfirmationDialogs;
