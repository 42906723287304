import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

import Button, { ButtonProps } from '../components/Button';
import { SidebarSection } from '../components/DetailSidebar/DetailSidebar';
import LoadingPanel from '../components/LoadingPanel';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import Cluster from '../models/Cluster';
import EsRecord from '../models/EsRecord';
import { AppAction } from '../stores/AppAction';
import { AppState } from '../stores/MainStore';
import { assertNever } from '../utils/typescript';
import ClusterCard from './ClusterCard';
import ClusterMember from './ClusterMember';
import clusterCardStyles from './ClusterSidebarRecordContent.module.scss';
import { PaneE } from './Pane';
import { BEGIN_CONFIRMING_ACCEPT_SUGGESTION, SuppliersBeginConfirmingAcceptSuggestion } from './SuppliersActionTypes';
import { getActiveRecord } from './SuppliersStore';
import VerificationIcon, { VerificationIconTypeE } from './VerificationIcon';
import styles from './VerificationSidebarSection.module.scss';
import { VerificationTypeE } from './VerificationType';

/**
 * @param timestamp Epoch in milliseconds
 */
function proseTimeSince(timestampMs: number | null | undefined): string {
  if (!timestampMs) {
    return '';
  }
  return moment(timestampMs * 1000).fromNow();
}

const Title: React.FC = ({ children }) => {
  return <span className={styles.title}>{children}</span>;
};

const Subtitle: React.FC = ({ children }) => {
  return <span className={styles.subtitle}>{children}</span>;
};

const Top: React.FC = ({ children }) => {
  return <div className={styles.top}>{children}</div>;
};

const Message: React.FC = ({ children }) => {
  return <div className={styles.message}>{children}</div>;
};

const InfoIcon: React.FC = ({ children }) => {
  return (
    <TooltipTrigger placement="bottom" {...{ content: children }}>
      <TamrIcon
        iconName="info-outline"
        size={14}
        style={{ marginBottom: '1px', verticalAlign: 'sub' }}
      />
    </TooltipTrigger>
  );
};

const VerifiedIcon: React.FC<{type: VerificationIconTypeE}> = ({ type }) => {
  return <VerificationIcon
    {...{ verificationIconType: type }}
    containerProps={{
      className: styles.verifiedIcon,
    }}
    size={22}
  />;
};

const RelatedClusterCard: React.FC<{
  button?: ButtonProps
  cluster: Cluster
  focusedPane: PaneE
}> = ({
  button,
  cluster,
  focusedPane,
}) => {
  const { clusterId, name, clusterVerificationCounts, recordCount, totalSpend } = cluster;
  if (!clusterVerificationCounts) {
    return null;
  }
  return (
    <ClusterCard
      button={button}
      clusterId={clusterId}
      clusterName={name}
      clusterVerificationCounts={clusterVerificationCounts}
      focusedPane={focusedPane}
      numberOfRecords={recordCount}
      spend={totalSpend}
    />
  );
};

const NewClusterCard: React.FC<{
  acceptSuggestion: (clusterId: string) => AppAction
  clusterId: string | null
}> = ({
  acceptSuggestion,
  clusterId,
}) => {
  return (
    <div className={clusterCardStyles.clusterCard}>
      <div className={clusterCardStyles.cardBodyContainer}>
        <div className={clusterCardStyles.cardBody}>
          <div className={clusterCardStyles.clusterSummary}>
            <div className={clusterCardStyles.clusterName}>NEW CLUSTER</div>
            <span>{`ID: ${clusterId}`}</span>
          </div>
        </div>
        <Button className={clusterCardStyles.cardBodyButton} onClick={() => clusterId && acceptSuggestion(clusterId)}>Accept suggestion</Button>
      </div>
    </div>
  );
};

/**
 * Record has never been verified
 */
const VerifiedNever = () => {
  return (
    <Message>
      <Title>This record has never been verified</Title>
      <Subtitle>
        Suggestions for this record will be auto-accepted&nbsp;
        <InfoIcon>Tamr will automatically move this record based on its own suggestions.</InfoIcon>
      </Subtitle>
    </Message>
  );
};

/**
 * Record is verified (LOCK)
 */
const VerifiedLock: React.FC<{
  info: ClusterMember
}> = ({ info }) => {
  const { timestamp, username } = info;
  return (
    <Top>
      <VerifiedIcon type={VerificationIconTypeE.VERIFICATION_ICON_TYPE_LOCKED} />
      <Message>
        <Title>{`Verified by ${username} ${proseTimeSince(timestamp)}`}</Title>
        <Subtitle>
          Suggestions for this record have been disabled
        </Subtitle>
      </Message>
    </Top>
  );
};

/**
 * Record is verified (MOVABLE) in its current cluster.
 */
const VerifiedMovable: React.FC<{
  info: ClusterMember
}> = ({ info }) => {
  const { timestamp, username } = info;
  return (
    <Top>
      <VerifiedIcon type={VerificationIconTypeE.VERIFICATION_ICON_TYPE_VERIFIED_HERE} />
      <Message>
        <Title>{`Verified by ${username} ${proseTimeSince(timestamp)}`}</Title>
        <Subtitle>
          Suggestions for this record will be auto-accepted&nbsp;
          <InfoIcon>Tamr will automatically move this record based on its own suggestions.</InfoIcon>
        </Subtitle>
      </Message>
    </Top>
  );
};

/**
 * Record is verified (MOVABLE) in another cluster.
 */
const VerifiedMovableMoved: React.FC<{
  focusedPane: PaneE
  info: ClusterMember
  relatedCluster?: Cluster
}> = ({
  focusedPane,
  info,
  relatedCluster,
}) => {
  const { timestamp, username } = info;
  const titleText = 'Before Tamr moved this record by auto-accepting its suggestion,'
    + ` ${username} verified it ${proseTimeSince(timestamp)} in another cluster`
    + (relatedCluster ? ':' : ' that no longer exists.');
  return (
    <>
      <Top>
        <VerifiedIcon type={VerificationIconTypeE.VERIFICATION_ICON_TYPE_VERIFIED_ELSEWHERE} />
        <Message>
          <Title>{titleText}</Title>
        </Message>
      </Top>
      {!!relatedCluster && <RelatedClusterCard
        cluster={relatedCluster}
        focusedPane={focusedPane}
      />}
      <Subtitle>
        Suggestions for this record will be auto-accepted&nbsp;
        <InfoIcon>Tamr will automatically move this record based on its own suggestions.</InfoIcon>
      </Subtitle>
    </>
  );
};

/**
 * Record is verified (SUGGEST), but no suggestions are available yet.
 */
const VerifiedSuggestMissing: React.FC<{
  info: ClusterMember
}> = ({ info }) => {
  const { timestamp, username } = info;
  return (
    <Top>
      <VerifiedIcon type={VerificationIconTypeE.VERIFICATION_ICON_TYPE_SUGGESTABLE_NO_SUGGESTION} />
      <Message>
        <Title>{`Verified by ${username} ${proseTimeSince(timestamp)}`}</Title>
        <Subtitle>
          Update clusters to see suggestions&nbsp;
          <InfoIcon>Suggestions will be generated after updating clusters.</InfoIcon>
        </Subtitle>
      </Message>
    </Top>
  );
};

/**
 * Record is verified (SUGGEST) and suggested to be in its current cluster.
 */
const VerifiedSuggestCurrent: React.FC<{
  info: ClusterMember
}> = ({ info }) => {
  const { timestamp, username } = info;
  return (
    <Top>
      <VerifiedIcon type={VerificationIconTypeE.VERIFICATION_ICON_TYPE_SUGGESTABLE_NO_SUGGESTION} />
      <Message>
        <Title>{`Verified by ${username} ${proseTimeSince(timestamp)}`}</Title>
        <Subtitle>Tamr suggests to keep this record in the current cluster.</Subtitle>
      </Message>
    </Top>
  );
};

/**
 * Record is verified (SUGGEST) and suggested to be in another cluster.
 */
const VerifiedSuggest: React.FC<{
  acceptSuggestion: (clusterId: string) => AppAction,
  focusedPane: PaneE,
  info: ClusterMember,
  record: EsRecord,
  suggestedCluster?: Cluster,
}> = ({
  acceptSuggestion,
  focusedPane,
  info,
  record,
  suggestedCluster,
}) => {
  const { timestamp, username } = info;
  const subtitle = 'Tamr suggests moving this record to'
    + (suggestedCluster ? ':' : ' a new cluster:');

  const clusterCard = suggestedCluster
    ? <RelatedClusterCard
        cluster={suggestedCluster}
        focusedPane={focusedPane}
        button={{
          onClick: () => acceptSuggestion(suggestedCluster.clusterId),
          children: 'Accept suggestion',
        }}
      />
    : <NewClusterCard clusterId={record.suggestedClusterId} acceptSuggestion={acceptSuggestion} />;
  return (
    <>
      <Top>
        <VerifiedIcon type={VerificationIconTypeE.VERIFICATION_ICON_TYPE_SUGGESTABLE_HAS_SUGGESTION} />
        <Message>
          <Title>{`Verified by ${username} ${proseTimeSince(timestamp)}`}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Message>
      </Top>
      {clusterCard}
    </>
  );
};

/**
 * Record is unverified (UNVERIFIED).
 */
const VerifiedUnverified: React.FC<{
  focusedPane: PaneE
  info: ClusterMember
  relatedCluster?: Cluster
}> = ({
  focusedPane,
  info,
  relatedCluster,
}) => {
  const { timestamp, username } = info;
  const title = `${username} removed this record's verification ${proseTimeSince(timestamp)}.`
    + ' It had previously been verified in'
    + (relatedCluster ? ':' : ' a cluster that no longer exists.');
  return (
    <>
      <Title>{title}</Title>
      {!!relatedCluster && <RelatedClusterCard cluster={relatedCluster} focusedPane={focusedPane} />}
      <Subtitle>
        Suggestions for this record will be auto-accepted&nbsp;
        <InfoIcon>Tamr will automatically move this record based on its own suggestions.</InfoIcon>
      </Subtitle>
    </>
  );
};

const Body = connect(
  (state: AppState) => {
    const { suppliers, suppliers: { focusedPane } } = state;
    const record = getActiveRecord(state, focusedPane);
    const paneState = suppliers[focusedPane];
    const clusterMembershipInfo = paneState.activeRecordClusterMembership;
    const relatedCluster: Cluster | undefined = paneState.activeRecordRelatedCluster;
    const loading: boolean = suppliers.loadingActiveRecordClusterMembership;
    return {
      clusterMembershipInfo,
      focusedPane,
      loading,
      record,
      relatedCluster,
    };
  },
  {
    acceptSuggestion: (pane: PaneE, record: EsRecord, clusterId: string): SuppliersBeginConfirmingAcceptSuggestion => ({
      type: BEGIN_CONFIRMING_ACCEPT_SUGGESTION,
      pane,
      record,
      clusterId,
    }),
  },
)(({
  acceptSuggestion,
  clusterMembershipInfo,
  focusedPane,
  loading,
  record,
  relatedCluster,
}) => {
  if (!record) {
    return null;
  }
  if (loading) {
    return <LoadingPanel size={24} />;
  }

  if (!clusterMembershipInfo) {
    return <VerifiedNever />;
  }
  const { verificationType } = clusterMembershipInfo;
  if (!verificationType) {
    return <VerifiedNever />;
  }

  switch (verificationType) {
    case VerificationTypeE.LOCK:
      return <VerifiedLock info={clusterMembershipInfo} />;
    case VerificationTypeE.MOVABLE:
      if (record.verifiedInAnotherCluster) {
        return <VerifiedMovableMoved
          focusedPane={focusedPane}
          info={clusterMembershipInfo}
          relatedCluster={relatedCluster}
        />;
      }
      return <VerifiedMovable info={clusterMembershipInfo} />;
    case VerificationTypeE.SUGGEST:
      if (!record.suggestedClusterId) {
        return <VerifiedSuggestMissing info={clusterMembershipInfo} />;
      }
      if (record.suggestedClusterId === record.currentClusterId) {
        return <VerifiedSuggestCurrent info={clusterMembershipInfo} />;
      }
      return <VerifiedSuggest
        acceptSuggestion={(clusterId) => acceptSuggestion(focusedPane, record, clusterId)}
        focusedPane={focusedPane}
        info={clusterMembershipInfo}
        record={record}
        suggestedCluster={relatedCluster}
      />;
    case VerificationTypeE.UNVERIFIED:
      return <VerifiedUnverified
        focusedPane={focusedPane}
        info={clusterMembershipInfo}
        relatedCluster={relatedCluster}
      />;
    default:
      assertNever(verificationType);
  }
});

const VerificationSidebarSection = () => {
  return (
    <SidebarSection title="Verification & Suggestions">
      <div className={styles.container}>
        <Body />
      </div>
    </SidebarSection>
  );
};

export default VerificationSidebarSection;
