import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import _ from 'underscore';

import Button from '../components/Button';
import TamrIcon from '../components/TamrIcon';
import AttributeId from '../models/AttributeId';
import MappingRecommendation, {
  DO_NOT_MAP_NAME,
  getDisplaySimilarity,
} from '../models/MappingRecommendation';
import PRODUCT_NAME from '../utils/ProductName';

const MappingOption = ({ option, formatLabel, actionLabel, action, canUserEdit }) => {
  const formattedLabel = formatLabel(option);
  return (
    <div
      className={classNames('mapping-option', { 'allow-actions': canUserEdit })}
    >
      {
        option.showRec ? (
          <div className="option-suggestion-icon-container">
            <TamrIcon className="option-suggestion-icon" iconName="tamr-icon-lightbulb" size={16} />
          </div>
        ) : null
      }
      <div
        className={classNames('option-attr-name', { 'suggestion-icon-present': option.showRec })}
        title={_.isString(formattedLabel) ? formattedLabel : null}
      >
        <div className="attr-label">{formattedLabel}</div>
      </div>
      <div className="option-dataset-name">
        <div className="dataset-label" title={option.attributeId.datasetName}>
          {option.attributeId.datasetName}
        </div>
        {!action ? null :
        <Button buttonType="Secondary" className="action-button" onClick={action} title={option.attributeId.datasetName}>
          {actionLabel}
        </Button>
        }
      </div>
    </div>
  );
};

const fakeOption = name => ({ attributeId: new AttributeId({ name, datasetName: '' }) });

const AllowMappings = ({ onUpdateMappable, canUserEdit }) => (
  <div className="mapping-section">
    <div className="mapping-options">
      <MappingOption
        option={fakeOption('Do Not Map')}
        formatLabel={o => o.attributeId.name}
        actionLabel={'Allow Mapping'}
        action={() => onUpdateMappable(true)}
        canUserEdit={canUserEdit}
      />
    </div>
  </div>
);

const DisallowMappings = () => (
  <div className="mapping-section">
    <div className="mapping-options">
      <MappingOption
        option={fakeOption(`You cannot map into ${PRODUCT_NAME} generated columns`)}
        formatLabel={o => o.attributeId.name}
      />
    </div>
  </div>
);

class MappingSelector extends React.Component {
  static propTypes = {
    canUserEdit: PropTypes.bool.isRequired,
    className: PropTypes.string,
    isGenerated: PropTypes.bool,
    isMappable: PropTypes.bool,
    mappingTitle: PropTypes.string.isRequired,
    mappings: ImmutablePropTypes.setOf(PropTypes.instanceOf(AttributeId)).isRequired,
    onMappingChange: PropTypes.func.isRequired,
    onToggleFilterRelatedId: PropTypes.func.isRequired,
    onUpdateMappable: PropTypes.func,
    recommendations: ImmutablePropTypes.listOf(PropTypes.instanceOf(MappingRecommendation)).isRequired,
    suggestionTitle: PropTypes.string.isRequired,
  };

  renderFilter = () => {
    const { mappings, recommendations, isMappable, onToggleFilterRelatedId } = this.props;
    if (!isMappable || (mappings.isEmpty() && recommendations.isEmpty())) {
      return null;
    }
    return (
      <div className="mapping-trigger" onClick={onToggleFilterRelatedId}>
        <TamrIcon className="filter-icon" iconName="filter-list" size={21} />
        <div className="filter-message">
          {(() => {
            switch (true) {
              case mappings.isEmpty(): return 'View all suggestions';
              case recommendations.isEmpty(): return 'View all mappings';
              default: return 'View all mappings and suggestions';
            }
          })()}
        </div>
      </div>
    );
  };

  renderMappedAttributes = () => {
    const recommendations = this.props.recommendations.map(r => r.recommended).toSet();
    const options = this.props.mappings
      .map(
        attributeId => ({
          attributeId,
          showRec: recommendations.contains(attributeId),
        }),
      )
      .sortBy(m => m.attributeId.datasetName)
      .sortBy(m => m.attributeId.name)
      .toArray();

    return (
      <div className="mapping-section mappings">
        <div className="section-header">
          {this.props.mappingTitle}
        </div>
        {
          (options.length === 0) ? (
            <div className="empty-state">No mappings</div>
          ) : (
            <div className="option-list">
              {
                options.map(option => (
                  <MappingOption
                    key={`mapping-${option.attributeId.name}-${option.attributeId.datasetName}`}
                    option={option}
                    formatLabel={o => o.attributeId.name}
                    actionLabel={'Unmap'}
                    action={_.partial(this.props.onMappingChange, option.attributeId, false /* unmap */)}
                    canUserEdit={this.props.canUserEdit}
                  />
                ))
              }
            </div>
          )
        }
      </div>
    );
  };

  renderSuggestions = () => {
    const mappings = this.props.mappings.toSet();
    const options = this.props.recommendations
      .filter(r => !mappings.contains(r.recommended))
      .map(
        r => ({
          attributeId: r.recommended,
          similarity: r.similarity,
          showRec: true,
        }),
      )
      .sortBy(m => -m.similarity)
      .toArray();

    return (
      <div className="mapping-section suggestions">
        <div className="section-header">
          {this.props.suggestionTitle}
        </div>
        {
          (options.length === 0) ? (
            <div className="empty-state">No suggestions</div>
          ) : (
            <div className="option-list">
              {
                options.map(option => {
                  const isDoNotMap = option.attributeId.name === DO_NOT_MAP_NAME;
                  const labelText = isDoNotMap ? 'Do Not Map' : option.attributeId.name;
                  return (
                    <MappingOption
                      key={`suggestion-${option.attributeId.name}-${option.attributeId.datasetName}`}
                      option={option}
                      formatLabel={o => (
                        <div className="suggestion">
                          <div className="overflow-inner" title={labelText}>{labelText}</div>
                          <div className="percentage">({getDisplaySimilarity(o.similarity)}%)</div>
                        </div>
                      )}
                      actionLabel={isDoNotMap ? 'Do Not Map' : 'Map'}
                      action={isDoNotMap ? () => this.props.onUpdateMappable(false) : _.partial(this.props.onMappingChange, option.attributeId, true /* map */)}
                      canUserEdit={this.props.canUserEdit}
                    />
                  );
                })
              }
            </div>
          )
        }
      </div>
    );
  };

  render() {
    const { isMappable, isGenerated, canUserEdit, onUpdateMappable } = this.props;
    const className = classNames('mapping-selector', this.props.className);
    if (isGenerated) {
      return (<div className={className}><DisallowMappings /></div>);
    }
    if (!isMappable && canUserEdit) {
      return (<div className={className}><AllowMappings {...{ canUserEdit, onUpdateMappable }} /></div>);
    }
    return (
      <div className={className}>
        {this.renderMappedAttributes()}
        {this.renderSuggestions()}
        {this.renderFilter()}
      </div>
    );
  }
}

export default MappingSelector;
