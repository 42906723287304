import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Labeler from '../components/Labeler';
import Term from '../components/Term';
import User from '../models/User';
import { ArgTypes } from '../utils/ArgValidation';
import style from './UserSelectorContent.module.scss';

class UserSelectorContent extends React.Component {
  static propTypes = {
    noun: PropTypes.string,
    onUserSelect: PropTypes.func,
    recipeId: PropTypes.number,
    recordCount: PropTypes.number.isRequired,
    selectionState: ImmutablePropTypes.mapOf(PropTypes.oneOf(['selected', 'semi', 'unselected']), PropTypes.string),
    userAssignmentCount: PropTypes.func.isRequired,
    users: ImmutablePropTypes.listOf(ArgTypes.instanceOf(User)).isRequired,
  };

  state = {
    searchString: '',
  };

  getRecipeId = () => {
    return this.props.recipeId;
  };

  setSearchString = (value) => {
    this.setState({ searchString: value });
  };

  // if user is assigned to one or more, tells # of assignments out of # of {noun}s
  getLabel = (username, name) => {
    const { userAssignmentCount, recordCount, noun } = this.props;
    const ac = userAssignmentCount(username);
    const progressString = (ac && noun)
      ? <span> (currently assigned {ac} of the {recordCount} selected <Term amount={recordCount}>{noun}</Term>)</span>
      : null;
    const tooltip = ((name != null) ? name + ` (${username})` : username)
      + ((ac && noun) ? ` currently assigned ${ac} of the ${recordCount} selected ${noun}` : '');
    return <div title={tooltip}><span>{name || username}{progressString}</span></div>;
  };

  getOptions = () => {
    const { users, selectionState } = this.props;
    const { searchString } = this.state;
    return users
      .filter(user => (user.name || user.username).toLowerCase().indexOf(searchString.toLowerCase()) > -1)
      .sort((user1, user2) => {
        return (user1.name || user1.username).toLowerCase().localeCompare((user2.name || user2.username).toLowerCase());
      })
      .map((user) => {
        return {
          display: this.getLabel(user.username, user.name),
          key: user.username,
          selectionState: selectionState.has(user.username) ? selectionState.get(user.username) : 'unselected',
        };
      });
  };

  render() {
    const { onUserSelect } = this.props;
    const { searchString } = this.state;
    return (
      <Labeler
        className={style.component}
        options={this.getOptions()}
        onOptionSelect={onUserSelect}
        onSearchValueChange={this.setSearchString}
        searchValue={searchString}
      />
    );
  }
}

export default UserSelectorContent;
