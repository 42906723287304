import classNames from 'classnames';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import Sortable from 'react-sortablejs';
import _ from 'underscore';

import Button from '../components/Button';
import GeospatialBetaWarning from '../components/GeospatialBetaWarning';
import Checkbox from '../components/Input/Checkbox';
import Input from '../components/Input/Input';
import Selector from '../components/Input/Selector';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import Toggle from '../components/Toggle';
import TooltipTrigger from '../components/TooltipTrigger';
import WarningIcon from '../components/WarningIcon';
import Clause from '../models/Clause';
import PairEstimates from '../models/PairEstimates';
import Recipe from '../models/Recipe';
import {
  ABSOLUTE_COSINE,
  ABSOLUTE_SIMILARITIES,
  COSINE,
  DIFF,
  GEOSPATIAL_DIRECTIONAL_HAUSDORFF,
  GEOSPATIAL_HAUSDORFF,
  GEOSPATIAL_MIN_DISTANCE,
  GEOSPATIAL_RELATIVE_AREA_OVERLAP,
  GEOSPATIAL_RELATIVE_HAUSDORFF,
  isGeospatialSimilarityFunction,
  JACCARD,
  NUMERICAL_SIMILARITIES,
  RELATIVE_DIFF,
} from '../schema-mapping/constants/SimilarityFunction';
import {
  BIGRAM,
  BIWORD,
  DEFAULT,
  REGEX,
  STEMMING_EN,
  TRIGRAM,
} from '../schema-mapping/constants/TokenizerConfig';
import { IDF, NONE } from '../schema-mapping/constants/TokenWeighting';
import { longFormat, shortFormat } from '../utils/Numbers';
import { getActiveRecipe } from '../utils/Selectors';
import { getAvailableFields, getRemovedFieldClauseEntries } from './DnfBuilderStore';
import FieldSignal from './FieldSignal';

const TOKENIZER_HELP = {
  DEFAULT: (
    <ul className="tipList">
      <li>Splits values on spaces and special characters except underscores, decimals in numbers, and periods in URLs</li>
      <li>Converts text to lowercase</li>
    </ul>
  ),
  STEMMING_EN: (
    <ul className="tipList">
      <li>Splits values on spaces and special characters except underscores, decimals in numbers, and periods in URLs</li>
      <li>Converts text to lowercase</li>
      <li>Converts words to their root or stem. For example, “fishing” and “fished” become “fish.”</li>
    </ul>
  ),
  REGEX: (
    <ul className="tipList">
      <li>Splits values on spaces and special characters including underscores, decimals in numbers, and periods in URLs</li>
      <li>Converts text to lowercase</li>
    </ul>
  ),
  BIGRAM: (
    <ul className="tipList">
      <li>Values are converted to strings, which are then split into bigrams (sequences of two consecutive characters). Spaces are added to the first and last characters. For example, bigrams for “taco” are “&nbsp;t”, “ta”, “ac”, “co”, and “o&nbsp;”.</li>
    </ul>
  ),
  TRIGRAM: (
    <ul className="tipList">
      <li>Values are converted to strings, which are then split into trigrams (sequences of three consecutive characters). Spaces are added to the first and last characters. For example, trigrams for “taco” are “&nbsp;&nbsp;t”, “&nbsp;ta”, “tac”, “aco”, “co&nbsp;”, and “o&nbsp;&nbsp;”.</li>
    </ul>
  ),
  BIWORD: (
    <ul className="tipList">
      <li>Splits values on spaces and special characters except underscores, decimals in numbers, and periods in URLs</li>
      <li>Converts text to lowercase</li>
      <li>Individual words as well as pairs of consecutive words are generated. For example, Bi-Words for “w1 w2 w3” are “w1”, “w2”, “w3”, “w1 w2”, “w2 w3”.</li>
    </ul>
  ),
};

const TOKEN_WEIGHTING_HELP = {
  IDF: ('Uses Inverse Document Frequency (IDF) to weigh tokens.'),
  NONE: ('Tokens are equally weighted.'),
};
const DnfBuilderClause = connect((state) => {
  const { dnfBuilder: { dnf: { clauses }, sidebarExpanded, pairEstimates } } = state;
  const availableFields = getAvailableFields(state);
  return { availableFields, clauses, sidebarExpanded, recipe: getActiveRecipe(state), pairEstimates };
}, {
  onMoveClauseEntry: (sourceClauseIndex, sourceClauseEntryIndex, destClauseIndex, destClauseEntryIndex) => ({ type: 'DnfBuilder.moveClauseEntry', sourceClauseIndex, sourceClauseEntryIndex, destClauseIndex, destClauseEntryIndex }),
  onSetClauseEntry: (clauseIndex, clauseEntry, clauseEntryIndex) => ({ type: 'DnfBuilder.setClauseEntry', clauseIndex, clauseEntry, clauseEntryIndex }),
  onSetClause: (clause, clauseIndex) => ({ type: 'DnfBuilder.setClause', clause, clauseIndex }),
  onRemoveClauseEntry: (clauseIndex, clauseEntryIndex) => ({ type: 'DnfBuilder.removeClauseEntry', clauseIndex, clauseEntryIndex }),
  onSplitClause: (clauseIndex, clauseEntryIndex) => ({ type: 'DnfBuilder.splitClause', clauseIndex, clauseEntryIndex }),
})(class DnfBuilderClause extends React.Component {
  static propTypes = {
    availableFields: ImmutablePropTypes.setOf(PropTypes.instanceOf(FieldSignal)).isRequired,
    clause: PropTypes.instanceOf(Clause).isRequired,
    clauseOrder: PropTypes.number.isRequired,
    clauses: ImmutablePropTypes.listOf(PropTypes.instanceOf(Clause)).isRequired,
    onMoveClauseEntry: PropTypes.func.isRequired,
    onRemoveClauseEntry: PropTypes.func.isRequired,
    onSetClause: PropTypes.func.isRequired,
    onSetClauseEntry: PropTypes.func.isRequired,
    onSplitClause: PropTypes.func.isRequired,
    pairEstimates: PropTypes.instanceOf(PairEstimates),
    recipe: PropTypes.instanceOf(Recipe).isRequired,
  };

  renderHeader = () => {
    return (
      <div className="clause-header">
        <div className="column select-attribute">Select Attribute</div>
        <div className="column select-signal">Select Similarity</div>
        <div className="column select-token-weighting">Select Token Weighting</div>
        <div className="column select-tokenizer">Select Tokenizer</div>
        <div className="column is-active">Active</div>
        <div className="column estimate">Estimate</div>
      </div>
    );
  };

  dummyChangeHandler = () => {
    /* To fool drag and drop to refresh */
  };

  handleDragAndDropEnd = event => {
    const { oldDraggableIndex, newDraggableIndex } = event;
    const fromClauseIndex = parseInt(event.from.getAttribute('data-clause-id'), 10);
    const toClauseIndex = parseInt(event.to.getAttribute('data-clause-id'), 10);
    if (toClauseIndex !== fromClauseIndex || oldDraggableIndex !== newDraggableIndex) {
      this.props.onMoveClauseEntry(fromClauseIndex, oldDraggableIndex, toClauseIndex, newDraggableIndex);
    }
  };

  renderClause = () => {
    return (
      <Sortable
        className={`clause-line-sortable-wrapper c${this.props.clauseOrder}`}
        data-clause-id={this.props.clauseOrder}
        options={{
          group: 'clause',
          filter: '.ignore-drag',
          draggable: '.clause-line',
          handle: '.reorder-drag-handle',
          forceFallback: true,
          sort: true,
          onEnd: this.handleDragAndDropEnd,
        }}
        onChange={this.dummyChangeHandler}
      >
        {this.props.clause.clauseEntries.map((clauseEntry, i) =>
          this.renderLine(clauseEntry, i, i === this.props.clause.clauseEntries.size - 1))}
        <div className="clause-line drop-target" />
      </Sortable>
    );
  };

  updateFieldName = (clauseEntry, clauseEntryOrder, value) => {
    const { clauseOrder } = this.props;
    const updatedClauseEntry = clauseEntry.set('fieldName', value);
    this.props.onSetClauseEntry(clauseOrder, updatedClauseEntry, clauseEntryOrder);
  };

  updateIsNull = (clauseEntry, clauseEntryOrder, value) => {
    const { clauseOrder } = this.props;
    const updatedClauseEntry = clauseEntry.set('threshold', value ? null : 1.0);
    this.props.onSetClauseEntry(clauseOrder, updatedClauseEntry, clauseEntryOrder);
  };

  updateSimilarityFunction = (clauseEntry, clauseEntryOrder, value) => {
    const { clauseOrder } = this.props;
    const updatedClauseEntry = clauseEntry.set('similarityFunction', value)
      .update('tokenizerConfig', t => (
        NUMERICAL_SIMILARITIES.has(value)
          ? null
          : t === null ? DEFAULT : t
      ))
      .update('tokenWeighting', t => (
        NUMERICAL_SIMILARITIES.has(value)
          ? null
          : t === null ? IDF : t
      ))
      .update('threshold', t => (
        ABSOLUTE_SIMILARITIES.has(value)
          ? -1 * Math.abs(t * 100)
          : ABSOLUTE_SIMILARITIES.has(clauseEntry.similarityFunction) ? Math.min(Math.abs(t) / 100, 1) : t
      ));
    this.props.onSetClauseEntry(clauseOrder, updatedClauseEntry, clauseEntryOrder);
  };

  updateTokenizerConfig = (clauseEntry, clauseEntryOrder, value) => {
    const { clauseOrder } = this.props;
    let tokenizerConfig = value;
    // If make sure that the tokenizerConfig is set to default if one is required (e.g. not using
    // DIFF as a similarity function)
    if (!NUMERICAL_SIMILARITIES.has(clauseEntry.similarityFunction) && !value) {
      tokenizerConfig = DEFAULT;
    }
    const updatedClauseEntry = clauseEntry.set('tokenizerConfig', tokenizerConfig);
    this.props.onSetClauseEntry(clauseOrder, updatedClauseEntry, clauseEntryOrder);
    return updatedClauseEntry;
  };

  updateTokenWeighting = (clauseEntry, clauseEntryOrder, value) => {
    const { clauseOrder } = this.props;
    let tokenWeighting = value;
    // If make sure that the tokenizerConfig is set to default if one is required (e.g. not using
    // DIFF as a similarity function)
    if (!NUMERICAL_SIMILARITIES.has(clauseEntry.similarityFunction) && !value) {
      tokenWeighting = IDF;
    }
    const updatedClauseEntry = clauseEntry.set('tokenWeighting', tokenWeighting);
    this.props.onSetClauseEntry(clauseOrder, updatedClauseEntry, clauseEntryOrder);
    return updatedClauseEntry;
  };

  updateThreshold = (clauseEntry, clauseEntryOrder, value) => {
    const { clauseOrder } = this.props;
    if (clauseEntry.isNull) {
      return null;
    }
    let updatedClauseEntry;
    if (ABSOLUTE_COSINE === clauseEntry.similarityFunction) {
      updatedClauseEntry = clauseEntry.set('threshold', Math.abs(value));
    } else if (ABSOLUTE_SIMILARITIES.has(clauseEntry.similarityFunction)) {
      updatedClauseEntry = clauseEntry.set('threshold', -1 * Math.abs(value));
    } else {
      updatedClauseEntry = clauseEntry.set('threshold', Math.min(Math.abs(value) / 100, 1));
    }
    this.props.onSetClauseEntry(clauseOrder, updatedClauseEntry, clauseEntryOrder);
    return updatedClauseEntry;
  };

  updateActiveState = (value) => {
    const { clause, clauseOrder } = this.props;
    this.props.onSetClause(clause.set('active', value), clauseOrder);
  };

  removeClauseEntryHandler = (clauseEntryOrder) => {
    const { clauseOrder } = this.props;
    this.props.onRemoveClauseEntry(clauseOrder, clauseEntryOrder);
  };

  renderAttributeSelector = (clauseEntry, clauseEntryOrder) => {
    const { availableFields, sidebarExpanded } = this.props;
    if (availableFields.isEmpty()) {
      return <div />;
    }
    const removedFieldClauseEntries = getRemovedFieldClauseEntries(this.props);
    const thisClauseEntryFieldWasRemoved = removedFieldClauseEntries.some(ce => ce.fieldName === clauseEntry.fieldName);
    const minThreshold = clauseEntry.similarityFunction === JACCARD ? 0.2 : 0;
    const maxThreshold = 1.0;
    const thresholdClassname = classNames('similarity-filter', {
      disabled: clauseEntry.isNull,
    });
    let threshold;
    let percentageText;
    let thresholdWidth;
    let thresholdMeasureText;
    let thresholdTextBoxText;
    if (ABSOLUTE_SIMILARITIES.has(clauseEntry.similarityFunction)) {
      threshold = Math.abs(clauseEntry.threshold);
      thresholdWidth = '100px';
      thresholdMeasureText = isGeospatialSimilarityFunction(clauseEntry.similarityFunction)
        ? 'distance (m)'
        : (ABSOLUTE_COSINE === clauseEntry.similarityFunction ? 'absolute similarity' : 'difference');
      thresholdTextBoxText = ABSOLUTE_COSINE === clauseEntry.similarityFunction ? 'Above' : 'Less than';
    } else {
      percentageText = <span className="percentage-text">%</span>;
      // Have to round to fix a bug in Chrome where random numbers are added when you multiply 0.07 by 100, which is what happens when
      // you type a 7.  The "* 1000 / 1000" is to get up to 4 decimal points
      threshold = Math.round(Math.min(Math.max(minThreshold, Math.abs(clauseEntry.threshold) * 100), maxThreshold * 100) * 1000) / 1000;
      thresholdWidth = '80px';
      thresholdMeasureText = 'similarity';
      thresholdTextBoxText = 'Above';
    }
    return (
      <div className={classNames('select-attribute-cell', { minimize: sidebarExpanded, 'field-was-removed': thisClauseEntryFieldWasRemoved })}>
        <div className="tamr-selector-wrapper">
          {thisClauseEntryFieldWasRemoved ? (
            <TooltipTrigger
              placement="right"
              content={<span><b>{clauseEntry.fieldName}</b> is no longer a machine learning attribute and cannot be used in how <Term>pairs</Term> are found.</span>}
            >
              <WarningIcon error className="removed-field-warning-icon" size={22} />
            </TooltipTrigger>
          ) : null}
          <Selector
            placeholder={thisClauseEntryFieldWasRemoved ? clauseEntry.fieldName : 'Select Attribute'}
            onChange={_.partial(this.updateFieldName, clauseEntry, clauseEntryOrder)}
            values={availableFields.map((value) => {
              return { display: <TooltipTrigger className="tooltip" placement="right" content={value.fieldName}><div>{value.fieldName}</div></TooltipTrigger>,
                value: value.fieldName };
            }).sortBy(({ value }) => value.toLowerCase()).toArray()}
            value={thisClauseEntryFieldWasRemoved ? undefined : clauseEntry.fieldName}
          />
        </div>
        <span className="line-text">has {thresholdMeasureText}</span>
        <TooltipTrigger
          placement="top"
          content="Pairs where at least one of the attributes is null will pass this clause"
        >
          <span className="is-null-checkbox">
            <Checkbox
              className="is-null-checkbox"
              size={16}
              title="Is Null"
              value={clauseEntry.isNull}
              onChange={_.partial(this.updateIsNull, clauseEntry, clauseEntryOrder)}
            />
          </span>
        </TooltipTrigger>
        <div
          className={thresholdClassname}
        >
          <Input
            title={thresholdTextBoxText}
            type="number"
            onChange={_.partial(this.updateThreshold, clauseEntry, clauseEntryOrder)}
            value={threshold}
            width={thresholdWidth}
            min={1}
            disabled={clauseEntry.isNull}
          />
          {percentageText}
        </div>
        <span className="line-text">with</span>
      </div>
    );
  };

  renderSignalSelector = (clauseEntry, clauseEntryOrder) => {
    const { sidebarExpanded } = this.props;
    return (
      <div className="tamr-selector-wrapper">
        <Selector
          className={classNames('signal-selector', { minimize: sidebarExpanded })}
          placeholder="Select Signal"
          onChange={_.partial(this.updateSimilarityFunction, clauseEntry, clauseEntryOrder)}
          values={[
            { display: 'Cosine', value: COSINE },
            { display: 'Absolute Cosine', value: ABSOLUTE_COSINE },
            { display: 'Jaccard', value: JACCARD },
            { display: 'Absolute Diff', value: DIFF },
            { display: 'Relative Diff', value: RELATIVE_DIFF },
            { display: 'Hausdorff Distance', value: GEOSPATIAL_HAUSDORFF },
            { display: 'Directional Hausdorff Distance', value: GEOSPATIAL_DIRECTIONAL_HAUSDORFF },
            { display: 'Minimum Distance', value: GEOSPATIAL_MIN_DISTANCE },
            { display: 'Relative Hausdorff', value: GEOSPATIAL_RELATIVE_HAUSDORFF },
            { display: 'Relative Area Overlap', value: GEOSPATIAL_RELATIVE_AREA_OVERLAP },
          ]}
          value={clauseEntry.similarityFunction}
        />
        <GeospatialBetaWarning
          similarityFunction={clauseEntry.similarityFunction}
          className="geospatial-warning-section"
          iconClassName="geospatial-warning-icon"
          iconSize={14}
        />
      </div>
    );
  };

  renderTokenWeightingSelector = (clauseEntry, clauseEntryOrder) => {
    const { sidebarExpanded } = this.props;
    let selector;
    if (NUMERICAL_SIMILARITIES.has(clauseEntry.similarityFunction)) {
      selector = <div className="no-token-weighting-selector">--</div>;
    } else {
      selector = (
        <Selector
          className={classNames('token-weighting-selector', { minimize: sidebarExpanded })}
          placeholder="Select Token Weighting"
          onChange={_.partial(this.updateTokenWeighting, clauseEntry, clauseEntryOrder)}
          values={[
            { display: <TooltipTrigger className="tooltip" content={TOKEN_WEIGHTING_HELP.IDF}><div>IDF Weighting</div></TooltipTrigger>, value: IDF },
            { display: <TooltipTrigger className="tooltip" content={TOKEN_WEIGHTING_HELP.NONE}><div>No Weighting</div></TooltipTrigger>, value: NONE },
          ]}
          value={clauseEntry.tokenWeighting}
        />
      );
    }
    return (
      <div className="tamr-selector-wrapper">
        {selector}
      </div>
    );
  };

  renderTokenizerSelector = (clauseEntry, clauseEntryOrder) => {
    const { sidebarExpanded } = this.props;
    let removeButton;
    // Don't show the remove button if only one clause entry in total is present
    if (!(this.props.clauses.size === 1 && this.props.clauses.get(0).clauseEntries.size === 1)) {
      removeButton = (
        <Button
          buttonType="Link"
          className="remove-entry"
          icon="delete"
          iconSize={16}
          onClick={_.partial(this.removeClauseEntryHandler, clauseEntryOrder)}
        />
      );
    }
    let selector;
    if (NUMERICAL_SIMILARITIES.has(clauseEntry.similarityFunction)) {
      selector = <div className="no-tokenizer-selector">--</div>;
    } else {
      selector = (
        <Selector
          className={classNames('tokenizer-selector', { minimize: sidebarExpanded })}
          placeholder="Select Tokenizer"
          onChange={_.partial(this.updateTokenizerConfig, clauseEntry, clauseEntryOrder)}
          values={[
            { display: <TooltipTrigger className="tooltip" content={TOKENIZER_HELP.DEFAULT}><div>Default</div></TooltipTrigger>, value: DEFAULT },
            { display: <TooltipTrigger className="tooltip" content={TOKENIZER_HELP.STEMMING_EN}><div>Stemming (English)</div></TooltipTrigger>, value: STEMMING_EN },
            { display: <TooltipTrigger className="tooltip" content={TOKENIZER_HELP.REGEX}><div>Spaces and special chars</div></TooltipTrigger>, value: REGEX },
            { display: <TooltipTrigger className="tooltip" content={TOKENIZER_HELP.BIGRAM}><div>Bigram</div></TooltipTrigger>, value: BIGRAM },
            { display: <TooltipTrigger className="tooltip" content={TOKENIZER_HELP.TRIGRAM}><div>Trigram</div></TooltipTrigger>, value: TRIGRAM },
            { display: <TooltipTrigger className="tooltip" content={TOKENIZER_HELP.BIWORD}><div>Bi-Word</div></TooltipTrigger>, value: BIWORD },
          ]}
          value={clauseEntry.tokenizerConfig}
        />
      );
    }
    return (
      <div>
        <div className="tamr-selector-wrapper">
          {selector}
        </div>
        {removeButton}
      </div>
    );
  };

  renderClauseWideItems = (clauseEntry, clauseEntryOrder) => {
    const { clause, pairEstimates } = this.props;
    const lastRunDnf = pairEstimates?.estimatedDnf;
    const lastRunClauseEntry = lastRunDnf ? lastRunDnf.clauses.findEntry(c => c.equalsIgnoreId(clause)) : undefined;
    const lastRunClauseIndex = lastRunClauseEntry ? lastRunClauseEntry[0] : 0;
    const numInactiveUntilLastRunClause = lastRunDnf ? lastRunDnf.clauses.slice(0, lastRunClauseIndex).filter(c => !c.active).size : 0;
    const clauseEstimates = pairEstimates ? pairEstimates.clauseEstimates : List();
    const clauseEstimate = clause.active ? clauseEstimates.get(lastRunClauseIndex - numInactiveUntilLastRunClause) : undefined;
    if (clauseEntryOrder === 0) {
      const text = lastRunClauseEntry && clauseEstimate ? (
        <TooltipTrigger
          content={
            `${longFormat(clauseEstimate.dnfPairCount)} pairs are estimated to be generated from a
            total of ${longFormat(clauseEstimate.candidateCount)} potential candidate pairs. 
            Each record is expected to be in ${longFormat(Math.ceil(clauseEstimate.averageBinsPerEntity))} bins.`
          }
        >
          <span>
            {`${shortFormat(clauseEstimate.dnfPairCount)} from ${shortFormat(clauseEstimate.candidateCount)}`}
            <br />
            {`${longFormat(Math.ceil(clauseEstimate.averageBinsPerEntity))} blocks per record`}
          </span>
        </TooltipTrigger>
      ) : (
        <TooltipTrigger
          content={pairEstimates
            ? 'This clause was either changed or created since the last time Estimate Counts was run.'
            : 'You must run Estimate Counts to compute this value.'}
        >
          <span>----</span>
        </TooltipTrigger>
      );
      const rowSpan = clause.clauseEntries.size * 2 - 1;
      return (
        <div key={`clause-line-wide-${clauseEntryOrder}`} className="clause-line-wide">
          <div
            key={'column-is-active'}
            className="column is-active"
            rowSpan={rowSpan}
          >
            <Toggle
              value={clause.active}
              onChange={this.updateActiveState}
            />
          </div>
          <div
            key="column-estimate"
            className="column estimate"
            rowSpan={rowSpan}
          >
            {text}
          </div>
        </div>
      );
    }
    return [];
  };

  orButtonHandler = (clauseEntry, clauseEntryOrder, isLast) => {
    const { clauseOrder } = this.props;
    if (!isLast) {
      this.props.onSplitClause(clauseOrder, clauseEntryOrder);
    }
  };

  renderOrButton = (clauseEntry, clauseEntryOrder, isLast) => {
    const className = classNames('clause-line', 'ignore-drag', {
      'is-last': isLast,
    });
    return (
      <div key="or-button" className={className}>
        <div
          className="or-button-container"
          colSpan={3}
          onClick={_.partial(this.orButtonHandler, clauseEntry, clauseEntryOrder, isLast)}
        >
          <div
            className="or-button"
          >
            <hr />
            <span className="or-overlay">
              OR
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderLine = (clauseEntry, clauseEntryOrder, isLast) => {
    return [
      <div
        key={`clause-line-${clauseEntryOrder}`}
        className="clause-line item"
        data-id={`${this.props.clauseOrder}-${clauseEntryOrder}`}
      >
        <div className="column select-attribute">
          <div className="reorder-drag-handle">
            <TamrIcon
              iconName="drag-handle"
              size={16}
            />
          </div>
          {this.renderAttributeSelector(clauseEntry, clauseEntryOrder)}
        </div>
        <div className="column select-signal">
          {this.renderSignalSelector(clauseEntry, clauseEntryOrder)}
        </div>
        <div className="column select-token-weighting">
          {this.renderTokenWeightingSelector(clauseEntry, clauseEntryOrder)}
        </div>
        <div className="column select-tokenizer">
          {this.renderTokenizerSelector(clauseEntry, clauseEntryOrder)}
        </div>
        {this.renderOrButton(clauseEntry, clauseEntryOrder, isLast)}
      </div>,
      this.renderClauseWideItems(clauseEntry, clauseEntryOrder),
    ];
  };

  render() {
    return (
      <div className="dnf-builder-clause">
        {this.renderHeader()}
        {this.renderClause()}
      </div>
    );
  }
});

export default DnfBuilderClause;
