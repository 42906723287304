import { isBoolean, isNumber, isString } from 'underscore';

import { ArgTypes } from '../utils/ArgValidation';
import { isObject } from '../utils/Values';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

const displayColumnTypesAndDefaults = {
  name: { type: ArgTypes.string },
  order: { type: ArgTypes.number, defaultValue: Infinity },
  width: { type: ArgTypes.number, defaultValue: 120 },
  visible: { type: ArgTypes.bool, defaultValue: true },
  alias: { type: ArgTypes.nullable(ArgTypes.string) },
};

/**
 * The "read types" of DisplayColumn, meaning the types available when reading from a DisplayColumn object.
 * Fields with default values are optional when constructing, but guaranteed to be available when reading (here).
 */
export type DisplayColumnI = InferReadTypes<typeof displayColumnTypesAndDefaults>;

/**
 * The construction types of DisplayColumn - all fields are optional except the for the key ("name").
 */
export type DisplayColumnPartialI = Omit<Partial<DisplayColumnI>, 'name'> & { name: string }

export function isDisplayColumnPartialI(data: unknown): data is DisplayColumnPartialI {
  if (!isObject(data)) return false;
  if (!isString(data.name)) return false;
  if (data.order !== undefined && !isNumber(data.order)) return false;
  if (data.width !== undefined && !isNumber(data.width)) return false;
  if (data.visible !== undefined && !isBoolean(data.visible)) return false;
  if (data.alias !== undefined && data.alias !== null && !isString(data.alias)) return false;
  return true;
}

class DisplayColumn extends getModelHelpers(displayColumnTypesAndDefaults,
  'DisplayColumn')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class DisplayColumnRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  get displayName() { return this.alias ? this.alias : this.name; }
  withOrder(order: number) {
    return new DisplayColumn({ ...this.toObject(), order });
  }
}

export default DisplayColumn;
