import PropTypes from 'prop-types';
import React from 'react';
import { pure } from 'recompose';
import _ from 'underscore';

import { shortFormat } from '../utils/Numbers';
import { pluralize } from '../utils/Strings';

const TitleLabel = _.compose(
  pure,
)(class TitleLabel extends React.Component {
  static propTypes = {
    plural: PropTypes.node.isRequired,
    singular: PropTypes.node.isRequired,
    value: PropTypes.number.isRequired,
  };

  render() {
    const { value, singular, plural } = this.props;
    let numberMessage = shortFormat(value);
    numberMessage = <span className="value">{numberMessage}</span>;
    let nounMessage = pluralize(value, singular, plural);
    nounMessage = <span className="noun">{nounMessage}</span>;
    return (
      <div className="title-label">{numberMessage} {nounMessage}</div>
    );
  }
});

export default TitleLabel;
