// feature: Clusters / Test Records / Filter
import React from 'react';
import { connect } from 'react-redux';

import Checkbox from '../../components/Input/Checkbox';
import TamrIcon from '../../components/TamrIcon';
import TooltipTrigger from '../../components/TooltipTrigger';
import AppState from '../../stores/AppState';
import { AppDispatch } from '../../stores/MainStore';
import filterStyle from '../FilterSection.module.scss';
import { PaneE } from '../Pane';
import {
  allProblems,
  toggleAnyProblem,
  toggleBothProblems,
  toggleNoProblem,
  togglePrecisionProblem,
  toggleRecallProblem,
} from './filtersSlice';

// Helpers

const MoreInfo: React.FC<{
  children: string
}> = ({ children }) => {
  return (
    <TooltipTrigger placement="right" content={children}>
      <TamrIcon
        style={{ marginBottom: '1px', verticalAlign: 'sub' }}
        iconName="info-outline"
        size={14}
      />
    </TooltipTrigger>
  );
};

const Filter: React.FC<{
  title: React.ReactNode
  value: boolean
  onChange: () => void
  indent?: number
}> = ({
  title,
  value,
  onChange,
  indent = 0,
}) => {
  return (
    <div className={filterStyle.option} style={{ marginLeft: indent * 16 }}>
      <Checkbox
        className={filterStyle.checkboxWrapper}
        size={15}
        title={title}
        titlePosition="right"
        value={value}
        onChange={onChange}
        />
    </div>
  );
};

const displayRecordCount = (count?: number): string => {
  if (count === undefined) {
    return '';
  }
  return ` (${count})`;
};

// Redux mapping

const mapState = (state: AppState, { pane }: OwnProps) => {
  const { filters } = state.clusters[pane].testRecord;
  const { filtersCounts } = state.clusters.testRecord;
  return { filters, filtersCounts };
};

const mapDispatch = (dispatch: AppDispatch, { pane }: OwnProps) => {
  return {
    toggleAny: () => dispatch(toggleAnyProblem({ pane })),
    togglePrecision: () => dispatch(togglePrecisionProblem({ pane })),
    toggleRecall: () => dispatch(toggleRecallProblem({ pane })),
    toggleBoth: () => dispatch(toggleBothProblems({ pane })),
    toggleNo: () => dispatch(toggleNoProblem({ pane })),
  };
};

interface OwnProps {
  pane: PaneE
}

type StateProps = ReturnType<typeof mapState>;
type DispatchProps = ReturnType<typeof mapDispatch>;

type Props = OwnProps & StateProps & DispatchProps;

// Main component

const ClusterRecordTestFilters: React.FC<Props> = ({
  filters,
  filtersCounts,
  toggleAny,
  togglePrecision,
  toggleRecall,
  toggleBoth,
  toggleNo,
}) => {
  const anyProblemCount =
    (filtersCounts.precisionProblem ?? 0)
    + (filtersCounts.recallProblem ?? 0)
    + (filtersCounts.bothProblems ?? 0);
  return (
    <div className={filterStyle.filterSection}>
      <div className={filterStyle.filterPanelHeader}>
        Test Records&nbsp;
        <MoreInfo>Records that have been used in the last round of cluster metric estimation</MoreInfo>
      </div>
      <Filter
        title={
          <span>
            Test records problems
            {displayRecordCount(anyProblemCount)}
          </span>
        }
        value={allProblems(filters)}
        onChange={toggleAny}
      />
      <Filter
        indent={1}
        title={
          <span>
            Test records with only precision problems
            {displayRecordCount(filtersCounts.precisionProblem)}
            &nbsp;
            <MoreInfo>
              Records that had to be moved out of its suggested cluster because they didn’t match the records in their original cluster.
              This usually indicates over-clustering problems.
            </MoreInfo>
          </span>
          }
        value={filters.precisionProblem}
        onChange={togglePrecision}
      />
      <Filter
        indent={1}
        title={
          <span>
            Test records with only recall problems
            {displayRecordCount(filtersCounts.recallProblem)}
            &nbsp;
            <MoreInfo>
              Records that had to be moved to another existing suggested cluster because they matched the records in these clusters.
              This usually indicates under-clustering problems.
            </MoreInfo>
          </span>
          }
        value={filters.recallProblem}
        onChange={toggleRecall}
      />
      <Filter
        indent={1}
        title={
          <span>
            Test records with both precision and recall problems
            {displayRecordCount(filtersCounts.bothProblems)}
            &nbsp;
            <MoreInfo>
              Records that had to be moved out of its suggested cluster to another existing suggested cluster.
            </MoreInfo>
          </span>
          }
        value={filters.bothProblems}
        onChange={toggleBoth}
      />
      <Filter
        title={'Test records with no problems' + displayRecordCount(filtersCounts.noProblem)}
        value={filters.noProblem}
        onChange={toggleNo}
      />
    </div>
  );
};

export default connect(mapState, mapDispatch)(ClusterRecordTestFilters);
