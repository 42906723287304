import './MenuLayout.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import ExtensionButtonBar from './ExtensionButtonBar';

class MenuLayout extends React.Component {
  static propTypes = {
    /**
     * Additional class name to add to the layout container
     */
    className: PropTypes.string,

    extensionButtons: PropTypes.object,
    /**
     *  The main panel of the layout
     */
    main: PropTypes.node,
    /**
     * The primary (top) navigation menu
     */
    navBar: PropTypes.node.isRequired,
    /**
     * Optional details sidebar
     */
    sidebar: PropTypes.node,
    /**
     * Specified whether or not the sidebar should be expanded.  Will scrunch down the main body
     * if it is
     */
    sidebarIsExpanded: PropTypes.bool,
  };

  render() {
    const { className, sidebarIsExpanded, navBar, main, sidebar, extensionButtons } = this.props;
    const containerClass = classNames('menu-layout', className);
    const mainClass = classNames('main-section', { 'sidebar-expanded': sidebarIsExpanded });
    const sidebarClass = classNames('sidebar-container', { expanded: sidebarIsExpanded });
    const bodyClass = classNames('body-section', { 'extensionbar-present': !extensionButtons.isEmpty() });
    const extensionClass = classNames('extentionbar-section', { 'extensionbar-present': !extensionButtons.isEmpty() });
    return (
      <div className={containerClass}>
        <div className="navbar-section">
          {navBar}
        </div>
        <div className={extensionClass}> <ExtensionButtonBar buttons={extensionButtons} /> </div>
        <div className={bodyClass}>
          <div className={mainClass}>
            {main}
          </div>
          <div className={sidebarClass}>
            {sidebar}
          </div>
        </div>
      </div>
    );
  }
}

export default MenuLayout;
