import { is } from 'immutable';

import { AppState } from '../stores/MainStore';
import createLoader from '../utils/createLoader';
import { fetchPreviewTable } from './GoldenRecordsAsync';
import { selectPreviewFilterInfo } from './GoldenRecordsSelectors';


const GoldenRecordsPreviewLoader = createLoader((state: AppState) => {
  const { goldenRecordsRules: { inputRecordsTable, loadedPreviewFilterInfo, loadingPreview } } = state;
  return {
    canFetch: !!inputRecordsTable,
    shouldFetch: !is(loadedPreviewFilterInfo, selectPreviewFilterInfo(state)),
    loading: loadingPreview,
  };
}, {
  onFetch: fetchPreviewTable,
}, 'GoldenRecordsPreviewLoader');

export default GoldenRecordsPreviewLoader;
