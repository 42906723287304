import PropTypes from 'prop-types';
import React from 'react';
import { pure } from 'recompose';
import _ from 'underscore';

import Input from '../../components/Input/Input';
import { capitalize } from '../../utils/Strings';
import Term from './Term';

/**
 * A pair of input boxes for configuring a term's singular & plural forms
 */
const TermInput = _.compose(
  pure,
)(/**
 * A pair of input boxes for configuring a term's singular & plural forms
 */
  class TermInput extends React.Component {
    static propTypes = {
      alternateTitle: PropTypes.string,
      autoUpdatePlural: PropTypes.bool,
      defaultTerm: PropTypes.instanceOf(Term),
      hasPlural: PropTypes.bool.isRequired,
      onChange: PropTypes.func.isRequired,
    };

    static defaultProps = { autoUpdatePlural: true };

    constructor(props) {
      super(props);
      const { defaultTerm } = props;
      const state = defaultTerm ? defaultTerm.toJSON() : {};
      if (!props.autoUpdatePlural) {
        state.pluralChanged = true;
      }
      this.state = state;
    }

    updateParent = () => {
      this.props.onChange(Term.fromJSON(this.state));
    };

    onSingularChange = (singular) => {
      const newState = { singular };
      if (!this.state.pluralChanged) {
        newState.plural = singular + 's';
      }
      this.setState(newState, this.updateParent);
    };

    onPluralChange = (plural) => {
      this.setState({ plural, pluralChanged: true }, this.updateParent);
    };

    renderInput = (key, onChange) => {
      const title = this.props.alternateTitle ? this.props.alternateTitle : key;
      return (
        <Input
          title={capitalize(title)}
          value={this.state[key]}
          width="48%"
          type="text"
          onChange={onChange}
      />
      );
    };

    render() {
      const { hasPlural } = this.props;
      const pluralInput = hasPlural ? this.renderInput('plural', this.onPluralChange) : undefined;
      return (
        <div className="proj-term-input">
          {this.renderInput('singular', this.onSingularChange)}
          {pluralInput}
        </div>
      );
    }
  });

export default TermInput;
