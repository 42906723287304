// com.tamr.procurify.models.messaging.MessageType

import enumify from './enumify';

export const JOB = 'JOB';
export const CLUSTER = 'CLUSTER';
export const CLUSTER_MEMBER = 'CLUSTER_MEMBER';
export const TRANSFORMATION_LIST_UPDATE = 'TRANSFORMATION_LIST_UPDATE';

export default enumify({ JOB, CLUSTER, CLUSTER_MEMBER, TRANSFORMATION_LIST_UPDATE });
