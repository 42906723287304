import moment from 'moment';

/**
 * Due to clock drift, the server might return a timestamp in the future for events
 * that occurred in the recent past. To remedy this, this function wraps moment
 * and will return either the supplied time or the current time, if the supplied
 * time is in the future.
 */
export const getTime = (...args: Parameters<typeof moment>) => {
  return moment.min(
    moment(), // now
    moment(...args),
  );
};

export const unixToReadableDateTime = (time: number) => {
  return moment(time * 1000).format('YYYY-MM-DD HH:mm:ss');
};

export const unixToReadableDate = (time: number) => {
  return moment(time * 1000).format('YYYY-MM-DD');
};
