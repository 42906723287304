import PropTypes from 'prop-types';
import React from 'react';
import { Nav, NavItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Checkbox from '../components/Input/Checkbox';

const SpecDialog = _.compose(
  connect(({ jobs: { spec, showSpecDialog } }) => {
    return { show: showSpecDialog, spec };
  }, {
    onHide: () => ({ type: 'Jobs.hideSpecDialog' }),
    onRefresh: () => ({ type: 'Jobs.refreshSpec' }),
  }),
)(class SpecDialog extends React.Component {
  static propTypes = {
    onHide: PropTypes.func.isRequired,
    onRefresh: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    spec: PropTypes.any,
  };

  state = { activeKey: 'json', autoRefresh: true };

  componentDidUpdate() {
    const { show, onRefresh } = this.props;
    const { autoRefresh } = this.state;
    if (show && autoRefresh) {
      if (!this.interval) {
        this.interval = setInterval(onRefresh, 5000);
      }
    } else {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = undefined;
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { onHide, show, spec } = this.props;
    const { activeKey } = this.state;
    return (
      <Dialog
        className="error-log-dialog"
        show={show}
        onHide={onHide}
        header={
          <Nav bsStyle="pills" activeKey={activeKey} onSelect={k => this.setState({ activeKey: k })}>
            <NavItem eventKey="json">As JSON</NavItem>
          </Nav>
        }
        body={
          <textarea className="status-box" value={JSON.stringify(spec, null, 2)} readOnly />
        }
        footer={
          <div className="job-spec-dialog-footer">
            <Checkbox
              title="Auto-refresh"
              onChange={() => this.setState(({ autoRefresh }) => ({ autoRefresh: !autoRefresh }))}
              value={this.state.autoRefresh}
              titlePosition="right"
            />
            <Button buttonType="Secondary" onClick={onHide}>Close</Button>
          </div>
        }
        dialogStyle={DialogStyle.FULL}
      />
    );
  }
});

export default SpecDialog;
