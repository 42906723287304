export type AsyncStatus<DataType> =
  | { type: 'initial' }
  | { type: 'loading', lastSuccessfulData: DataType | null }
  | { type: 'success', data: DataType }
  | { type: 'error', lastSuccessfulData: DataType | null, message: string };

export function getLastSuccessfulData<DataType>(status: AsyncStatus<DataType>): DataType | null {
  if (status.type === 'success') {
    return status.data;
  }
  if (status.type !== 'initial') {
    return status.lastSuccessfulData;
  }
  return null;
}
