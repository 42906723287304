import React from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';

import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import { AppState, State } from '../stores/MainStore';
import { longFormat } from '../utils/Numbers';
import {
  isCommitSchemaJobRunningForActiveProject,
  selectIsUnifiedDatasetIndexed,
} from '../utils/Selectors';
import { SET_SHOW_METRICS } from './PregroupActionTypes';
import {
  selectDedupUnifiedAttributes,
  selectGroupingKeys,
  selectIsInitialState,
  selectNonGroupingKeys,
  selectSampleMetrics,
  selectSampleNumInputRecords, UNIFIED_DATASET_INTERNAL_FIELDS,
} from './PregroupStore';
import style from './PregroupSummary.module.scss';

interface MetricProps {
  value: number;
  label: string;
  tooltip: React.ReactNode;
  formatValue?: boolean;
}
const Metric: React.FC<MetricProps> = ({ value, label, tooltip, formatValue = true }) => {
  return (
    <div className={style.metric}>
      <div className={style.value}>{formatValue ? longFormat(value, 10000000) : value}</div>
      <div className={style.label}>
        {label}
        <TooltipTrigger
          content={tooltip}
          placement="bottom"
        >
          <TamrIcon className={style.tooltipIcon} iconName="info-outline" size={14} />
        </TooltipTrigger>
      </div>
    </div>
  );
};

function mapStateToMetricsProps(state: State) {
  return {
    numInputRecords: selectSampleNumInputRecords(state),
    metrics: selectSampleMetrics(state),
  };
}
const PregroupMetrics = connect(mapStateToMetricsProps)(({ metrics, numInputRecords }) => {
  return numInputRecords === null ? null : (
    <div className={style.statement}>
      <div className={style.metrics}>
        <Metric label="Input Records" value={numInputRecords} tooltip="Total number of records in the unified dataset." />
        {metrics !== null && (
          <>
            <Metric label="Output" value={metrics.numberOfGroups} tooltip="Number of groups plus number of ungrouped records (empty values and excluded datasets)." />
            <Metric label="Non-Singleton Groups" value={metrics.numberOfGroups - metrics.numberOfRecordsInTrivialGroups - metrics.numberOfNonTrivialSingletonGroups} tooltip="Number of groups with multiple member records." />
            <Metric label="Average Group Size" formatValue={false} value={parseFloat(metrics.averageGroupSize.toFixed(3))} tooltip="Input records divided by output record groups (including singleton groups)." />
            <Metric label="Singleton Groups" value={metrics.numberOfNonTrivialSingletonGroups} tooltip="Groups with a single member record due to distinct, non-identical values for the grouping key(s)." />
            <Metric label="Ungrouped Records" value={metrics.numberOfRecordsInTrivialGroups} tooltip='Records in excluded datasets plus records with an empty value for a grouping key that does not "Group if empty".' />
          </>
        )}
      </div>
    </div>
  );
});

function GroupingKeyList(props: { groupingKeys: string[] }) {
  const numKeys = props.groupingKeys.length;
  return (
    <span className={style.highlight}>
      {props.groupingKeys
        .map<React.ReactNode>((k, j) => <span className={style.groupingKey} key={`key-${j}`}>{k}</span>)
        .reduce((acc, c, i) => {
          return [
            acc,
            (numKeys > 1) && (i === numKeys - 1) ? <span key={`and-${i}`}> and </span> : <span className={style.groupingKeySeparator} key={`separator-${i}`}>, </span>,
            c,
          ];
        })
      }
    </span>
  );
}

function mapStateToStatementProps(state: AppState) {
  const groupingKeys = selectGroupingKeys(state);
  const nonGroupingKeys = selectNonGroupingKeys(state);
  return {
    groupingKeys,
    nonGroupingKeys,
  };
}
const PregroupStatement = connect(mapStateToStatementProps)(({ groupingKeys, nonGroupingKeys }) => {
  return (
    <div className={style.statement}>
      <div className={style.title}>
        <span>
          {groupingKeys === null || groupingKeys.length === 0
            ? <>Reduce the number of records in your project by grouping those with the same value for attributes you select below.</>
            : <>Records with values for <GroupingKeyList groupingKeys={groupingKeys} /> are organized into groups of one or more. Groups have multiple records if the values for all grouping keys are identical.
              {nonGroupingKeys === null ? '' :
                ` Values for other attributes (${nonGroupingKeys[0]}${nonGroupingKeys.length > 1 ? ', etc.' : ''}) are aggregated using the specified functions.`} </>
          }
          <TooltipTrigger
            content={
              <div className={style.ul}>
                A record with an empty value for a grouping key that does not "Group if empty" is left ungrouped. Empty values include nulls, empty strings and strings of spaces or tabs only, and arrays with zero elements or any number of empty elements.
              </div>
            }
            placement="bottom"
          >
            <TamrIcon className={style.tooltipIcon} iconName="info-outline" size={14} />
          </TooltipTrigger>
        </span>
      </div>
    </div>
  );
});

function mapStateToWarningProps(state: AppState) {
  const updateUnifiedDatasetRunning = isCommitSchemaJobRunningForActiveProject(state);
  const dedupUnifiedAttributes = selectDedupUnifiedAttributes(state);
  const schemaUnifiedAttributes = state.schemaMapping.unifiedAttributes;
  return {
    warnings: state.pregroup.warnings,
    updateUnifiedDatasetRunning,
    dedupUnifiedAttributes,
    schemaUnifiedAttributes,
  };
}

const PregroupWarnings = connect(mapStateToWarningProps)(({ warnings, updateUnifiedDatasetRunning, dedupUnifiedAttributes, schemaUnifiedAttributes }) => {
  if (dedupUnifiedAttributes.length === 0 || schemaUnifiedAttributes.size === 0) {
    return null;
  }

  dedupUnifiedAttributes = dedupUnifiedAttributes.filter(ua => !UNIFIED_DATASET_INTERNAL_FIELDS.contains(ua.name));
  schemaUnifiedAttributes = schemaUnifiedAttributes.filter(ua => !UNIFIED_DATASET_INTERNAL_FIELDS.contains(ua.name));

  const unifiedAttributesSame = dedupUnifiedAttributes.every(dedupUa => {
    const smUa = schemaUnifiedAttributes.find(i => i.name === dedupUa.name);
    if (!smUa) {
      return false;
    }
    return (smUa.mlEnabled === dedupUa.mlEnabled) &&
      (smUa.requiredAttributeType === dedupUa.requiredAttributeType);
  }) && (dedupUnifiedAttributes.length === schemaUnifiedAttributes.size);

  let warnContent;
  if (updateUnifiedDatasetRunning) {
    warnContent = <>
      <strong>Unified dataset updating!</strong>
      <p>Record grouping table records may be inconsistent while the update unified dataset job is running.</p>
    </>;
  } else if (!unifiedAttributesSame) {
    warnContent = <>
      <strong>Unified attributes changed!</strong>
      <p>Changes have been made to the unified attributes. Run "Update Unified Dataset" on the Schema Mapping page to view those changes reflected here.</p>
    </>;
  } else if (warnings.removedFields.length > 0 || warnings.addedFields.length > 0) {
    warnContent = <>
      <strong>Record grouping outdated!</strong>
      <p>Review and save the following automated changes to record grouping resulting from changes to the Unified Dataset.</p>
      {warnings.removedFields.length > 0 ? <p><strong>Attributes removed:</strong> {warnings.removedFields.join(', ')}</p> : null}
      {warnings.addedFields.length > 0 ? <p><strong>Attributes added:</strong> {warnings.addedFields.join(', ')}</p> : null}
    </>;
  }

  if (!warnContent) {
    return null;
  }
  return (
    <div>
      <Alert bsStyle="warning" className={style.warningMessage}>
        <TamrIcon className="warning" iconName="tamr-icon-warning" size={16} />&nbsp;
        {warnContent}
      </Alert>
    </div>
  );
});

function mapStateToShowSummaryProps(state: State) {
  return {
    showGroupingMetrics: state.pregroup.showGroupingMetrics,
  };
}

export const ShowPregroupSummary = connect(mapStateToShowSummaryProps, {
  onShowMetrics: () => ({ type: SET_SHOW_METRICS }),
})(({ showGroupingMetrics, onShowMetrics }) => {
  return (
    <a onClick={onShowMetrics}>{showGroupingMetrics ? 'Hide Estimated Metrics' : 'Show Estimated Metrics'}</a>
  );
});

function mapStateToSummaryProps(state: State) {
  return {
    isInitial: selectIsInitialState(state),
    isUnifiedDatasetCommitted: selectIsUnifiedDatasetIndexed(state as AppState),
    isGroupingEnabled: state.pregroup.isGroupingEnabled,
    showGroupingMetrics: state.pregroup.showGroupingMetrics,
  };
}
export const PregroupSummary = connect(mapStateToSummaryProps)(({ isInitial, isUnifiedDatasetCommitted, isGroupingEnabled, showGroupingMetrics }) => {
  return <div>
    <PregroupWarnings />
    {(isInitial || !isUnifiedDatasetCommitted || !isGroupingEnabled) ? null : (
      !showGroupingMetrics ?
        <div className={style.summary}>
          <ShowPregroupSummary />
        </div> :
        <div className={style.summary}>
          <PregroupStatement />
          <PregroupMetrics />
          <div className={style.metrics}>
            <ShowPregroupSummary />
          </div>
        </div>
    )}
  </div>;
});
