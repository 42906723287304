import { Set } from 'immutable';
import _ from 'underscore';

import { VerificationTypeFilterE } from '../constants/VerificationTypeFilter';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import { ArgTypes, checkArg } from '../utils/ArgValidation';

const { MOVABLE_AGREE, SUGGEST_AGREE, SUGGEST_DISAGREE, LOCK, MOVABLE_DISAGREE, UNVERIFIED, NEVER_VERIFIED } = VerificationTypeFilterE;

export class VerificationFilterTotalsFetchTriggers extends getModelHelpers({
  sequence: { type: ArgTypes.wholeNumber, defaultValue: 0 },
}, 'VerificationFilterTotalsFetchTriggers')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class VerificationFilterTotalsFetchTriggersRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

export class VerificationFilterTotals extends getModelHelpers({
  // loader pattern fields
  loading: { type: ArgTypes.bool, defaultValue: false },
  fetchTriggers: { type: VerificationFilterTotalsFetchTriggers.argType, defaultValue: new VerificationFilterTotalsFetchTriggers({}) },
  loadedFetchTriggers: { type: ArgTypes.orUndefined(VerificationFilterTotalsFetchTriggers.argType) },
  // filters themselves
  verifiedHere: { type: ArgTypes.orUndefined(ArgTypes.wholeNumber) },
  verifiedElsewhere: { type: ArgTypes.orUndefined(ArgTypes.wholeNumber) },
  notVerified: { type: ArgTypes.orUndefined(ArgTypes.wholeNumber) },
  moveSuggested: { type: ArgTypes.orUndefined(ArgTypes.wholeNumber) },
  noMoveSuggested: { type: ArgTypes.orUndefined(ArgTypes.wholeNumber) },
  suggestionsDisabled: { type: ArgTypes.orUndefined(ArgTypes.wholeNumber) },
  suggestionsAutoAccepted: { type: ArgTypes.orUndefined(ArgTypes.wholeNumber) },
}, 'VerificationFilterTotals')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class VerificationFilterTotalsRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
    beginLoading() {
      return new VerificationFilterTotals({});
    }
    get verified() {
      const { verifiedHere, verifiedElsewhere } = this;
      return (_.isNumber(verifiedHere) && _.isNumber(verifiedElsewhere)) ? verifiedHere + verifiedElsewhere : null;
    }
    get suggestionsEnabled() {
      const { moveSuggested, noMoveSuggested } = this;
      return (_.isNumber(moveSuggested) && _.isNumber(noMoveSuggested)) ? moveSuggested + noMoveSuggested : null;
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

export class VerifiedFilters extends getModelHelpers({
  verifiedHere: { type: ArgTypes.bool, defaultValue: false },
  verifiedElsewhere: { type: ArgTypes.bool, defaultValue: false },
  notVerified: { type: ArgTypes.bool, defaultValue: false },
}, 'VerifiedFilters')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  type VerifiedFiltersI = ReadTypes;
  return class VerifiedFiltersRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
    get verified() {
      return this.verifiedHere && this.verifiedElsewhere;
    }
    get anyActive() {
      return this.verifiedHere || this.verifiedElsewhere || this.notVerified;
    }
    static filterTranslations: {[K in keyof Required<VerifiedFiltersI>]: Set<VerificationTypeFilterE>} = {
      verifiedHere: Set([MOVABLE_AGREE, SUGGEST_AGREE, SUGGEST_DISAGREE, LOCK]),
      verifiedElsewhere: Set([MOVABLE_DISAGREE]),
      notVerified: Set([UNVERIFIED, NEVER_VERIFIED]),
    };
    get filterStates(): Set<VerificationTypeFilterE> {
      const self = this;
      return Object.keys(VerifiedFilters.filterTranslations)
        .reduce((activeFilterStates, key: keyof VerifiedFiltersI) =>
          activeFilterStates.union(self[key] ? VerifiedFilters.filterTranslations[key] : Set()),
        Set());
    }
    static enabledFilters(suggestionsFilters: SuggestionsFilters): Required<VerifiedFiltersI> {
      const suggestionsFilterStates = suggestionsFilters.filterStates;
      const isEnabled = (key: keyof VerifiedFiltersI) =>
        suggestionsFilterStates.isEmpty() ||
        !VerifiedFilters.filterTranslations[key].intersect(suggestionsFilterStates).isEmpty();
      return {
        verifiedHere: isEnabled('verifiedHere'),
        verifiedElsewhere: isEnabled('verifiedElsewhere'),
        notVerified: isEnabled('notVerified'),
      };
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

export class SuggestionsFilters extends getModelHelpers({
  moveSuggested: { type: ArgTypes.bool, defaultValue: false },
  noMoveSuggested: { type: ArgTypes.bool, defaultValue: false },
  suggestionsDisabled: { type: ArgTypes.bool, defaultValue: false },
  suggestionsAutoAccepted: { type: ArgTypes.bool, defaultValue: false },
}, 'SuggestionsFilters')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  type SuggestionsFiltersI = ReadTypes;
  return class SuggestionsFiltersRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
    get suggestionsEnabled() {
      return this.moveSuggested && this.noMoveSuggested;
    }
    get anyActive() {
      return this.moveSuggested || this.noMoveSuggested || this.suggestionsDisabled || this.suggestionsAutoAccepted;
    }
    static filterTranslations: {[K in keyof Required<SuggestionsFiltersI>]: Set<VerificationTypeFilterE>} = {
      moveSuggested: Set([SUGGEST_DISAGREE]),
      noMoveSuggested: Set([SUGGEST_AGREE]),
      suggestionsDisabled: Set([LOCK]),
      suggestionsAutoAccepted: Set([MOVABLE_AGREE, MOVABLE_DISAGREE]),
    };
    get filterStates(): Set<VerificationTypeFilterE> {
      const self = this;
      return Object.keys(SuggestionsFilters.filterTranslations)
        .reduce((activeFilterStates, key: keyof SuggestionsFiltersI) =>
          activeFilterStates.union(self[key] ? SuggestionsFilters.filterTranslations[key] : Set()),
        Set());
    }
    static enabledFilters(verifiedFilters: VerifiedFilters): Required<SuggestionsFiltersI> {
      const verifiedFilterStates = verifiedFilters.filterStates;
      const isEnabled = (key: keyof SuggestionsFiltersI) =>
        verifiedFilterStates.isEmpty() ||
        !SuggestionsFilters.filterTranslations[key].intersect(verifiedFilterStates).isEmpty();
      return {
        moveSuggested: isEnabled('moveSuggested'),
        noMoveSuggested: isEnabled('noMoveSuggested'),
        suggestionsDisabled: isEnabled('suggestionsDisabled'),
        suggestionsAutoAccepted: isEnabled('suggestionsAutoAccepted'),
      };
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

// In ClusterVerificationFilters, the hasMoveSuggested filter being active implies that the hasVerifiedHere filter is active
//   so we represent those two filters (which will appear as checkboxes) with this enum, to eliminate the impossible state
//   of hasMoveSuggested=true and hasVerifiedHere=false
enum ClusterHasVerifiedFilterState {
  Inactive = 'Inactive',
  HasVerifiedHere = 'HasVerifiedHere',
  HasMoveSuggested = 'HasMoveSuggested', // implies hasVerifiedHere=true
}
const { Inactive, HasVerifiedHere, HasMoveSuggested } = ClusterHasVerifiedFilterState;

export class ClusterVerificationFilters extends getModelHelpers({
  hasVerifiedFilterState: { type: ArgTypes.valueIn([Inactive, HasVerifiedHere, HasMoveSuggested]), defaultValue: Inactive },
  hasVerifiedElsewhere: { type: ArgTypes.bool, defaultValue: false },
  hasNoneVerified: { type: ArgTypes.bool, defaultValue: false },
}, 'VerifiedFilters')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ClusterVerificationFiltersRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
    // normal construction considers an object with an 'undefined' value for a field to mean
    //   it should not replace that field with the default (as long as it hasOwnProperty).
    // this static constructor is here to replace 'undefined' values with defaults
    static newWithDefaults(args: ConstructorArgTypes) {
      if (!args.hasVerifiedFilterState) delete args.hasVerifiedFilterState;
      if (!_.isBoolean(args.hasVerifiedElsewhere)) delete args.hasVerifiedElsewhere;
      if (!_.isBoolean(args.hasNoneVerified)) delete args.hasNoneVerified;
      return new ClusterVerificationFilters(args);
    }
    get anyActive() {
      const { hasVerifiedHere, hasVerifiedElsewhere, hasMoveSuggested, hasNoneVerified } = this;
      return hasVerifiedHere || hasVerifiedElsewhere || hasMoveSuggested || hasNoneVerified;
    }
    get hasVerifiedHere(): boolean {
      const { hasVerifiedFilterState } = this;
      return hasVerifiedFilterState === HasVerifiedHere || hasVerifiedFilterState === HasMoveSuggested;
    }
    toggleHasVerifiedHere() {
      return this.set('hasVerifiedFilterState', this.hasVerifiedHere ? Inactive : HasVerifiedHere);
    }
    get hasMoveSuggested(): boolean {
      return this.hasVerifiedFilterState === HasMoveSuggested;
    }
    toggleHasMoveSuggested() {
      return this.set('hasVerifiedFilterState', this.hasMoveSuggested ? HasVerifiedHere : HasMoveSuggested);
    }
    // API can express positive filters (clusters that have records like this) or negative filters (clusters that have none like this)
    get positiveFilterStates(): Set<VerificationTypeFilterE> {
      const { hasVerifiedHere, hasVerifiedElsewhere, hasMoveSuggested } = this;
      let filters: Set<VerificationTypeFilterE> = Set();
      if (hasMoveSuggested) {
        filters = filters.concat([SUGGEST_DISAGREE]);
      } else if (hasVerifiedHere) {
        filters = filters.concat([MOVABLE_AGREE, SUGGEST_AGREE, SUGGEST_DISAGREE, LOCK]);
      }
      if (hasVerifiedElsewhere) {
        filters = filters.add(MOVABLE_DISAGREE);
      }
      return filters;
    }
    get negativeFilterStates(): Set<VerificationTypeFilterE> {
      const { hasNoneVerified } = this;
      if (hasNoneVerified) {
        return Set([MOVABLE_AGREE, MOVABLE_DISAGREE, SUGGEST_AGREE, SUGGEST_DISAGREE, LOCK]);
      }
      return Set();
    }
  };
}) {}

export const urlifyVerificationFilters = (recordsVerifiedFilters: VerifiedFilters, recordsSuggestionsFilters: SuggestionsFilters): Array<VerificationTypeFilterE> => {
  checkArg({ recordsVerifiedFilters }, VerifiedFilters.argType);
  checkArg({ recordsSuggestionsFilters }, SuggestionsFilters.argType);
  if (recordsVerifiedFilters.filterStates.isEmpty()) {
    return recordsSuggestionsFilters.filterStates.toArray();
  }
  if (recordsSuggestionsFilters.filterStates.isEmpty()) {
    return recordsVerifiedFilters.filterStates.toArray();
  }
  return recordsVerifiedFilters.filterStates.intersect(recordsSuggestionsFilters.filterStates).toArray();
};

export const urlifyPositiveClusterVerificationFilters = (clusterVerificationFilters: ClusterVerificationFilters): Array<VerificationTypeFilterE> => {
  checkArg({ clusterVerificationFilters }, ArgTypes.instanceOf(ClusterVerificationFilters));
  return clusterVerificationFilters.positiveFilterStates.toArray();
};

export const urlifyNegativeClusterVerificationFilters = (clusterVerificationFilters: ClusterVerificationFilters): Array<VerificationTypeFilterE> => {
  checkArg({ clusterVerificationFilters }, ArgTypes.instanceOf(ClusterVerificationFilters));
  return clusterVerificationFilters.negativeFilterStates.toArray();
};
