/**
 * Values for the different tabs in sidebars
 */
const SidebarTabs = {
  DETAILS: 'details',
  ACTIVITY: 'activity',
  TRANSFORMS: 'transforms',
};

export default SidebarTabs;
