import { List, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import DatasetFilter from '../datasetFilter/DatasetFilter';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import { QueryBuilder } from '../models/doc/Query';
import { RECIPE_INPUTS_KEY } from '../projects/RecipeConstants';
import AppState from '../stores/AppState';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { $TSFixMe } from '../utils/typescript';

const ManageProjectDatasetsFilterContent = connect((state: AppState) => {
  const { outputDatasetIds, smRecipeId } = selectActiveProjectInfo(state) || { outputDatasetIds: Set<number>(), smRecipeId: -1 };
  // ts fixme is due to DatasetFilter not recognizing this as properly satisfying the modifyQuery prop... mysterious to me
  const modifyQuery: $TSFixMe = (query: QueryBuilder): void => {
    if (!outputDatasetIds.isEmpty()) {
      query.where('id').isNotInIntegers(outputDatasetIds.toArray());
    }
  };
  const getSelectedDatasets = (datasets: List<Document<Dataset>>) => {
    return datasets
      .filter(ds => {
        const recipeInputs = ds.data.metadata.get(RECIPE_INPUTS_KEY);
        return recipeInputs && recipeInputs.includes(smRecipeId);
      })
      .map(ds => ds.data.name)
      .toSet();
  };
  const isSingleSelect = !!state.enrichment.module;
  return { modifyQuery, getSelectedDatasets, isSingleSelect };
})(({ modifyQuery, getSelectedDatasets, isSingleSelect }) =>
  (<DatasetFilter modifyQuery={modifyQuery} getSelectedDatasets={getSelectedDatasets} isSingleSelect={isSingleSelect} />));

export default ManageProjectDatasetsFilterContent;
