import { List } from 'immutable';
import uri from 'urijs';

import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import EsRecord from '../models/EsRecord';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import Page from '../models/Page';
import PostButtonSpec from '../models/PostButtonSpec';
import RedirectButtonSpec from '../models/RedirectButtonSpec';
import ScoreThresholds from '../models/ScoreThresholds';
import SSOEnabled from '../models/SSOEnabled';
import StorageHealthCheckResult from '../models/StorageHealthCheckResult';
import HealthCheckResult from '../models/HealthCheckResult';
import TileServer from '../models/TileServer';
import { TxnUrlBuilder } from '../transactions/TransactionUtils';
import { fetchAsResult, handleErrorsWithMessage, jsonHeaders, networkError, toJSON } from '../utils/Api';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { FetchResult, toFetchResult } from './FetchResult';
import ServiceProxy from './ServiceProxy';


export const getTileServers = (): Promise<List<TileServer>> => {
  return fetch(
    ServiceProxy.procure('/config/tile-servers'),
    { headers: jsonHeaders, method: 'GET' },
  )
    .then(handleErrorsWithMessage('Error getting tile servers information'), networkError)
    .then(toJSON)
    .then(data => List(data).map(TileServer.fromJSON));
};

export const getStorageStatus = (): Promise<StorageHealthCheckResult> => {
  return fetch(
    ServiceProxy.procure('/service/health'),
    { headers: jsonHeaders, method: 'GET' },
  )
    .then(toJSON)
    .then(data => new StorageHealthCheckResult({ storageSpace: new HealthCheckResult(data.storageSpace) }));
};

// TODO datasetDoc is required because EsRecord requires the datasetDoc its records belong to to be constructed.
//   that requirement should be removed, and this method should thereafter not require being handed a datasetDoc
export const fetchTransactions = (
  txnUrlBuilder: TxnUrlBuilder,
  datasetDoc: Document<Dataset>,
): Promise<Page<EsRecord>> => {
  checkArg({ txnUrlBuilder }, ArgTypes.instanceOf(TxnUrlBuilder));
  checkArg({ datasetDoc }, Document.argTypeWithNestedClass(Dataset));
  return fetch(txnUrlBuilder.build().toString(), { headers: jsonHeaders, method: 'GET' })
    .then(handleErrorsWithMessage('Error fetching records'), networkError)
    .then(toJSON)
    // @ts-expect-error EsRecord doesn't have the a compatible constructor for Page.fromJSON
    .then(pageJSON => Page.fromJSON(pageJSON, hit => new EsRecord(hit, datasetDoc)));
};

export function uploadDataset(formData: FormData, policyIds: string[]): Promise<FetchResult<Document<Dataset>>> {
  return fetchAsResult(
    uri(ServiceProxy.procure('/procurement/datasets')).query({ policyIds }).toString(),
    {
      method: 'POST',
      headers: { },
      body: formData,
      cache: 'no-cache',
    },
  ).then(toFetchResult(data => Document.fromJSON(data, Dataset.fromJSON)));
}

export function getSSOEnabled(): Promise<SSOEnabled> {
  return fetch(
    ServiceProxy.procure('/config/sso-enabled'),
    { method: 'GET', headers: jsonHeaders, cache: 'no-cache' },
  ).then(handleErrorsWithMessage('Error getting sso information'), networkError)
    .then(toJSON)
    .then(ssoEnabled => SSOEnabled.fromJSON(ssoEnabled));
}

export class ElasticConfig extends getModelHelpers({
  maxResultWindow: { type: ArgTypes.number, defaultValue: 10000 },
  maxGeospatialFeaturesDefault: { type: ArgTypes.number, defaultValue: 1000 },
}, 'PolicyEntityCount')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class PolicyEntityCountRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

export function getElasticConfig(): Promise<FetchResult<ElasticConfig>> {
  return fetchAsResult(ServiceProxy.procure('/service/elastic'), { headers: jsonHeaders, method: 'GET' })
    .then(toFetchResult(data => new ElasticConfig(data as $TSFixMe)));
}

export function getCategorizationStrengthThresholds(): Promise<FetchResult<ScoreThresholds>> {
  return fetchAsResult(ServiceProxy.procure('/config/strength-thresholds'), { headers: jsonHeaders, method: 'GET' })
    .then(toFetchResult(data => new ScoreThresholds(data as $TSFixMe)));
}

export function getExtensionPageButtons(pageName: string): Promise<List<PostButtonSpec|RedirectButtonSpec>> {
  return fetch(
    uri(ServiceProxy.procure(`/extension/${pageName}`)).toString(),
    { method: 'GET', headers: jsonHeaders })
    .then(toJSON)
    .then(data => List(data).map((spec: PostButtonSpec|RedirectButtonSpec) => {
      if (spec.buttonType === PostButtonSpec.buttonTypeConstant) { return PostButtonSpec.fromJSON(spec); }
      if (spec.buttonType === RedirectButtonSpec.buttonTypeConstant) { return RedirectButtonSpec.fromJSON(spec); }
      throw new TypeError(
        `Cannot create extension button of unknown type '${spec.buttonType}'. 
        Known types: ['${PostButtonSpec.buttonTypeConstant}', '${RedirectButtonSpec.buttonTypeConstant}']`,
      );
    }))
    .catch(() => { return List(); });
}
