import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Term from '../../components/Term';
import { history } from '../../utils/History';
import TopNCard, { Bar } from '../TopNCard';
import { ClusterTotal } from './DedupDashboardStore';

const TopClustersByCountCard = _.compose(
  connect((state) => {
    const { clusterFeedbackStore, dedupDashboard: { topClustersByCount } } = state;
    return { numAssignments: clusterFeedbackStore.clusterAssignments.size, topClustersByCount };
  }),
)(class TopClustersByCountCard extends React.Component {
  static propTypes = {
    dedupRecipeId: PropTypes.number.isRequired,
    numAssignments: PropTypes.number.isRequired,
    topClustersByCount: ImmutablePropTypes.listOf(PropTypes.instanceOf(ClusterTotal)),
  };

  getBars = () => {
    return this.props.topClustersByCount.map(clusterTotal => (
      new Bar({ value: clusterTotal.value, label: clusterTotal.name })
    ));
  };

  navigateToClusters = () => {
    const { dedupRecipeId } = this.props;
    history.push(`/suppliers/recipe/${dedupRecipeId}`);
  };

  render() {
    return (
      <TopNCard
        bars={this.getBars()}
        onClick={this.navigateToClusters}
        metric={<span>Number of <Term>Records</Term></span>}
        nounPlural={<span>Clusters (<Term>Suppliers</Term>)</span>}
        nounSingular={<span>Cluster (<Term>Supplier</Term>)</span>}
        superlative="Largest"
      />
    );
  }
});

export default TopClustersByCountCard;
