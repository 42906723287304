import Document from '../../models/doc/Document';
import Group from '../../models/Group';
import TaggedUnion, { InferConstructedKind } from '../../models/TaggedUnion';
import { ArgTypes } from '../../utils/ArgValidation';

/**
 * Model representing group configuration states and necessary information
 */

const groupConfigKinds = {
  Create: {
    admin: ArgTypes.bool,
    description: ArgTypes.string,
    groupname: ArgTypes.string,
  },
  Edit: {
    admin: ArgTypes.bool,
    currentGroup: Document.argTypeWithNestedClass(Group),
    description: ArgTypes.string,
  },
  Delete: {
    id: ArgTypes.number,
    name: ArgTypes.string,
    numberOfUsers: ArgTypes.number,
  },
};

const GroupConfig = TaggedUnion(groupConfigKinds, 'GroupConfig');
export type GroupConfigE = InferConstructedKind<typeof groupConfigKinds>;

export default GroupConfig;
