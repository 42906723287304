import { Set } from 'immutable';
import _ from 'underscore';
import uri from 'urijs';
import { v4 } from 'uuid';

import MessageType, { TRANSFORMATION_LIST_UPDATE } from '../constants/MessageType';
import {
  COMPUTE_CLUSTERS_ACCURACY,
  INDEX_DRAFT,
  UPDATE_CLUSTER_PROFILE,
  UPDATE_SOURCE_LIST,
} from '../constants/RecipeOperations';
import {
  PAIR_ESTIMATES_COMPLETED_2S_AGO,
  PAIR_ESTIMATES_COMPLETED_5S_AGO,
} from '../job/JobsActionTypes';
import Document from '../models/doc/Document';
import Job from '../models/Job';
import { RELOAD } from '../projects/ProjectsActionTypes';
import { computeMetricsJobCompleted, fetchMetrics } from '../suppliers/metrics/slice';
import ChangedTransformsMessage from '../transforms/models/ChangedTransformsMessage';
import { handleErrorsWithMessage, jsonHeaders, networkError, toJSON } from '../utils/Api';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import Log from '../utils/Log';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { getPath } from '../utils/Values';
import { UPDATE_RECORD_GROUPING_JOB_DESCRIPTION } from '../pregroup/PregroupAsync';
import { UPDATE_GROUPING_COMPLETED } from '../pregroup/PregroupActionTypes';

const currentClientId = 'ui_' + v4();

const receiveMessage = (message) => (dispatch, getState) => {
  Log.debug('MessagingApi::receiveMessage', message);
  const state = getState();

  const { type, sequenceNum, clientId, payload } = message;
  checkArg({ type }, ArgTypes.string);
  checkArg({ sequenceNum }, ArgTypes.wholeNumber);
  checkArg({ clientId }, ArgTypes.string);
  checkArg({ payload }, ArgTypes.orUndefined(ArgTypes.object));

  dispatch({ type: 'Messaging.messageReceived', lastSequenceNum: sequenceNum });

  if (clientId === currentClientId) {
    return; // Ignore messages that we sent
  }

  if (type === 'CLUSTER' || type === 'CLUSTER_MEMBER') {
    const { location: { recipeId }, suppliers: { bottom, top } } = state;
    const rows = top.rows.concat(bottom.rows);
    const data = top.data.concat(bottom.rows);
    const onCurrentPage = payload.recipeId === recipeId;
    if (onCurrentPage) {
      const affectedIds = type === 'CLUSTER' ? Set(payload.clusterIds) : Set(payload.recordIds);
      const affectsCurrentWindow = type === 'CLUSTER' ? data.some(cluster => affectedIds.contains(cluster.clusterId)) : rows.some(record => affectedIds.contains(record.recordId));
      if (affectsCurrentWindow) {
        dispatch({ type: 'Suppliers.clustersHaveChanged' });
      }
    }
  }
  if (type === TRANSFORMATION_LIST_UPDATE) {
    const { location: { page } } = state;
    const activeProjectSmRecipeId = getPath(selectActiveProjectInfo(state), 'smRecipeId');
    const typedMessage = new ChangedTransformsMessage(payload);
    const { smRecipeId } = typedMessage;
    const onCurrentPage = page === 'records' && smRecipeId === activeProjectSmRecipeId;
    if (onCurrentPage) {
      dispatch({ type: 'Transforms.transformationListHasChanged', message: typedMessage });
    }
  }
  if (type === 'JOB') {
    const job = Document.fromJSON(payload, Job.fromJSON);

    // need to refetch projects if running job status has changed
    const { chrome: { runningJobs } } = state;
    if (runningJobs.has(job.id.id) && job.data.status.state !== runningJobs.get(job.id.id).data.status.state) {
      dispatch({ type: RELOAD });
    }

    dispatch({ type: 'Jobs.jobUpdate', job });

    if (payload.data.status.state === 'SUCCEEDED') {
      if (_.isString(payload.data.metadata.recipeOperation)) {
        switch (payload.data.metadata.recipeOperation) {
          case 'categorizations':
            dispatch({ type: 'Jobs.classificationCompleted' });
            break;
          case 'predictCategorizations':
            dispatch({ type: 'Jobs.classificationCompleted' });
            break;
          case 'trainPredictCluster':
            dispatch({ type: 'Jobs.trainPredictClusterCompleted' });
            break;
          case 'predictCluster':
            dispatch({ type: 'Jobs.predictClusterCompleted' });
            break;
          case 'recommendations':
            dispatch({ type: 'Jobs.schemaMappingRecommendationsJobCompleted' });
            break;
          case INDEX_DRAFT:
            dispatch({ type: 'GoldenRecords.indexDraftJobFinished' });
            break;
          case UPDATE_SOURCE_LIST:
            dispatch({ type: 'GoldenRecords.updateSourceListJobFinished' });
            setTimeout(() => {
              dispatch({ type: 'GoldenRecords.updateSourceListJobFinished2sAgo' });
            }, 2000);
            break;
          case UPDATE_CLUSTER_PROFILE:
            dispatch({ type: 'GoldenRecords.updateClusterProfileJobFinished' });
            setTimeout(() => {
              dispatch({ type: 'GoldenRecords.updateClusterProfileJobFinished2sAgo' });
            }, 2000);
            break;
          case COMPUTE_CLUSTERS_ACCURACY:
            dispatch(computeMetricsJobCompleted());
            dispatch(fetchMetrics(selectActiveProjectInfo(state)));
            break;
          case 'records':
            dispatch({ type: 'Pregroup.reloadData' });
            break;
        }
        return;
      }
      if (payload.data.metadata.isProfilingJob) {
        dispatch({ type: 'Jobs.finishedProfiling', dataset: payload.data.metadata.datasetName });
        return;
      }
      // TODO relying on description is brittle. prefer something intended as a key.
      switch (payload.data.description) {
        case 'Generate Pair Estimates':
          dispatch({ type: 'Jobs.pairEstimatesCompleted' });
          // need to allow for server to update recipe metadata, so provide delayed actions so fetch can be delayed
          // TODO find more robust way to do this
          setTimeout(() => {
            dispatch({ type: PAIR_ESTIMATES_COMPLETED_2S_AGO });
          }, 2000);
          setTimeout(() => {
            dispatch({ type: PAIR_ESTIMATES_COMPLETED_5S_AGO });
          }, 5000);
          break;
        case UPDATE_RECORD_GROUPING_JOB_DESCRIPTION:
          dispatch({ type: UPDATE_GROUPING_COMPLETED });
          break;
      }
    }
  }
};

export const startPollingForMessages = () => (dispatch, getState) => {
  const state = getState();
  const { messaging: { lastSequenceNum, lastResponseWasError } } = state;

  // If we've been reconnected after an error we want to know quickly so that we can dismiss
  // the notification, so set the timeout to the minimum value of 1 second.
  const messagingUrl = uri(SERVICES.procure('/messaging'))
    .query(lastResponseWasError ? { lastSequenceNum, timeout: 1 } : { lastSequenceNum });

  fetch(messagingUrl, { headers: jsonHeaders, method: 'GET' })
    .then(handleErrorsWithMessage('Unable to receive messages'), networkError)
    .then(toJSON)
    .then(message => dispatch(receiveMessage(message)))
    .then(
      // Always end by checking again, either immediately or after a delay if something went
      // wrong (to avoid making rapid-fire requests to a dead or dying server).
      () => dispatch(startPollingForMessages()),
      () => {
        dispatch({ type: 'Messaging.error' });
        setTimeout(() => dispatch(startPollingForMessages()), 5000);
      },
    );
};

/**
 * Send message that will be rebroadcast to all active connections
 * @param messageType The type of the message
 * @param payload The data being sent in the message.
 */
export const sendMessage = (messageType, payload) => {
  Log.debug('MessagingApi::sendMessage', { messageType, payload });
  checkArg({ messageType }, ArgTypes.valueIn(MessageType));
  checkArg({ payload }, ArgTypes.object);

  const data = { type: messageType, clientId: currentClientId, payload };
  fetch(SERVICES.procure('/messaging'), { headers: jsonHeaders, method: 'POST', body: JSON.stringify(data) })
    .then(handleErrorsWithMessage('Unable to send message'), networkError);
};
