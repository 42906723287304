type UrlProxy = (url: string) => string

declare global {
  interface Window {
    SERVICES: {
      procure: UrlProxy,
      transform: UrlProxy,
      taxonomy: UrlProxy,
      user: UrlProxy,
      dataset: UrlProxy
      dedup: UrlProxy,
      recipe: UrlProxy
    };
  }
}

const ServiceProxy = window.SERVICES;

export default ServiceProxy;
