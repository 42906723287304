import enumify from './enumify';

export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const REORDER = 'REORDER';

const LedgerAction = {
  CREATE,
  UPDATE,
  DELETE,
  REORDER,
} as const;

export type LedgerActionE = typeof LedgerAction[keyof typeof LedgerAction];

export default enumify({ CREATE, UPDATE, DELETE, REORDER });
