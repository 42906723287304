import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import _ from 'underscore';

import { SM_SOURCE_ATTRIBUTE_LIST, SM_UNIFIED_ATTRIBUTE_LIST } from '../constants/DataTables';
import style from './ListHeaderRow.module.scss';
import { ATTRIBUTE_COL_NAME, DATASET_COL_NAME, getSAColumnSettings, getUAColumnSettings, MAPPINGS_COL_NAME, setSAColumnWidth, setUAColumnWidth } from './SchemaMappingAsync';
import { datasetColumnPaddingLeft, listItemIconWidth, mappingColumnPaddingLeft } from './SchemaMappingConstants.module.scss';

const ICON_WIDTH = parseInt(listItemIconWidth, 10);
const MAPPING_COL_PADDING_LEFT = parseInt(mappingColumnPaddingLeft, 10);
const DATASET_COL_PADDING_LEFT = parseInt(datasetColumnPaddingLeft, 10);
const COL_TEXT_MIN_WIDTH = 10;

class ListHeaderRow extends React.Component {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
      className: PropTypes.string,
      colName: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      minWidth: PropTypes.number.isRequired,
      widthRatio: PropTypes.number.isRequired,
    }).isRequired).isRequired,
    onSetColumnWidth: PropTypes.func.isRequired,
    paddingRight: PropTypes.number,
  };

  static defaultProps = { paddingRight: 0 };
  state = { draggingIndex: undefined, draggingBounds: undefined, numDrags: 0 };

  onStart = (colIndex) => {
    const { columns } = this.props;

    // set draggable bounds respecting column minWidths
    const colDef = columns[colIndex];
    const prevColDef = columns[colIndex - 1];
    const child = this[`child-${colIndex}`];
    const prevChild = this[`child-${colIndex - 1}`];
    const childNode = ReactDOM.findDOMNode(child);
    const prevChildNode = ReactDOM.findDOMNode(prevChild);

    const boundLeft = -(prevChildNode.offsetWidth - prevColDef.minWidth);
    const boundRight = childNode.offsetWidth - colDef.minWidth;

    this.setState({ draggingIndex: colIndex, draggingBounds: { left: boundLeft, right: boundRight } });
  };

  onStop = (colIndex, e, data) => {
    const { onSetColumnWidth, paddingRight, columns } = this.props;

    // set new column widths
    const { lastX } = data;
    const child = this[`child-${colIndex}`];
    const childNode = ReactDOM.findDOMNode(child);
    const thisNode = ReactDOM.findDOMNode(this);

    const newWidthPx = childNode.offsetWidth - lastX;
    const totalWidthPx = thisNode.offsetWidth - paddingRight;
    const newWidthRatio = 100 * newWidthPx / totalWidthPx;
    const colName = columns[colIndex].colName;
    onSetColumnWidth(colName, newWidthRatio);

    this.setState({
      draggingIndex: undefined,
      draggingBounds: undefined,
      numDrags: this.state.numDrags + 1, // TODO this + Draggable key in render causes benign "setState on unmounted comp" error
    });
  };

  render() {
    const { paddingRight, columns } = this.props;
    const { draggingIndex, draggingBounds, numDrags } = this.state;
    return (
      <div className={style.listHeaderRow} style={{ paddingRight }}>
        {columns.map(({ className, label, widthRatio }, index) => {
          const keyAndRef = `child-${index}`;
          return (
            <div
              key={keyAndRef}
              ref={(node) => { this[keyAndRef] = node; }}
              className={classNames(style.columnHeader, className)}
              style={{ width: `${widthRatio}%` }}
              title={label}
            >
              {index > 0 ? (
                <Draggable
                  key={`draggable-${index}-${numDrags}` /* NB necessary to reposition after drag end */}
                  ref={node => { this[`draggable-${index}`] = node; }}
                  onStart={_.partial(this.onStart, index)}
                  onStop={_.partial(this.onStop, index)}
                  axis="x"
                  bounds={draggingIndex === index ? draggingBounds : undefined}
                >
                  <div className={style.divider}>
                    <div className={style.dividerLine} />
                  </div>
                </Draggable>
              ) : null}
              <span className={style.columnHeaderText}>{ label }</span>
            </div>
          );
        })}
      </div>
    );
  }
}

export const SAListHeaderRow = connect((state) => {
  const { schemaMapping: { headerScrollbarOffset } } = state;
  return { headerScrollbarOffset, columnSettings: getSAColumnSettings(state) };
}, {
  onSetColumnWidth: setSAColumnWidth,
})(({ headerScrollbarOffset, onSetColumnWidth, columnSettings }) => {
  return (
    <ListHeaderRow
      paddingRight={headerScrollbarOffset}
      tableName={SM_SOURCE_ATTRIBUTE_LIST}
      columns={[
        {
          className: style.sourceAttributeColumnHeader,
          label: 'Input Attribute',
          colName: ATTRIBUTE_COL_NAME,
          widthRatio: columnSettings.get(ATTRIBUTE_COL_NAME),
          minWidth: ICON_WIDTH + COL_TEXT_MIN_WIDTH,
        },
        {
          className: style.datasetColumnHeader,
          label: 'Input Dataset',
          colName: DATASET_COL_NAME,
          widthRatio: columnSettings.get(DATASET_COL_NAME),
          minWidth: COL_TEXT_MIN_WIDTH + DATASET_COL_PADDING_LEFT,
        },
        {
          className: style.mappingColumnHeader,
          label: 'Mappings',
          colName: MAPPINGS_COL_NAME,
          widthRatio: columnSettings.get(MAPPINGS_COL_NAME),
          minWidth: COL_TEXT_MIN_WIDTH + MAPPING_COL_PADDING_LEFT + ICON_WIDTH + 40, /* TODO don't magic constant the light bulb width */
        },
      ]}
      {...{ onSetColumnWidth }}
    />
  );
});

export const UAListHeaderRow = connect((state) => {
  return { columnSettings: getUAColumnSettings(state) };
}, {
  onSetColumnWidth: setUAColumnWidth,
})(({ onSetColumnWidth, columnSettings }) => {
  return (
    <ListHeaderRow
      tableName={SM_UNIFIED_ATTRIBUTE_LIST}
      columns={[
        {
          className: style.mappingColumnHeader,
          label: 'Mappings',
          colName: MAPPINGS_COL_NAME,
          widthRatio: columnSettings.get(MAPPINGS_COL_NAME),
          minWidth: COL_TEXT_MIN_WIDTH + MAPPING_COL_PADDING_LEFT + ICON_WIDTH + 40, /* TODO don't magic constant the light bulb width */
        },
        {
          className: style.unifiedAttributeColumnHeader,
          label: 'Attribute',
          colName: ATTRIBUTE_COL_NAME,
          widthRatio: columnSettings.get(ATTRIBUTE_COL_NAME),
          minWidth: ICON_WIDTH * 5 + COL_TEXT_MIN_WIDTH,
        },
      ]}
      {...{ onSetColumnWidth }}
    />
  );
});

// TODO move cell stuff to own file, or name this file appropriately
const ControlledCellWidth = connect((state, { tableName, colName }) => {
  const widthRatio = tableName === SM_SOURCE_ATTRIBUTE_LIST
    ? getSAColumnSettings(state).get(colName)
    : getUAColumnSettings(state).get(colName);
  return { widthRatio };
})(({ widthRatio, className, ...rest }) => {
  return (
    <div style={{ width: `${widthRatio}%` }} className={classNames(style.controlledCellWidth, className)} {...rest} />
  );
});
ControlledCellWidth.propTypes = {
  className: PropTypes.string,
  colName: PropTypes.oneOf([ATTRIBUTE_COL_NAME, MAPPINGS_COL_NAME, DATASET_COL_NAME]).isRequired,
  tableName: PropTypes.oneOf([SM_SOURCE_ATTRIBUTE_LIST, SM_UNIFIED_ATTRIBUTE_LIST]).isRequired,
};

export const ControlledSACellWidth = ({ ...rest }) => {
  return (
    <ControlledCellWidth tableName={SM_SOURCE_ATTRIBUTE_LIST} {...rest} />
  );
};

export const ControlledUACellWidth = ({ ...rest }) => {
  return (
    <ControlledCellWidth tableName={SM_UNIFIED_ATTRIBUTE_LIST} {...rest} />
  );
};
