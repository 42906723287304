import { ArgTypes, checkArg } from './ArgValidation';
import { $TSFixMe } from './typescript';

export function pluralize(value: number, singular: string, plural: string) {
  checkArg({ value }, ArgTypes.number);
  return value === 1 ? singular : plural;
}

export function capitalize(word: string) {
  checkArg({ word }, ArgTypes.string);
  return word.replace(/^\w/, c => c.toUpperCase());
}

export function isBlank(word?: string) {
  checkArg({ word }, ArgTypes.nullable(ArgTypes.string));
  if (word === null || word === undefined) {
    return true;
  }
  return /^\s*$/.test(word);
}

export function stringify(o: $TSFixMe) {
  return typeof o === 'object' ? JSON.stringify(o) : o;
}

/**
 * Tries to parse a value as a JSON string, returning `null` if it cannot be parsed.
 */
export function tryParseJSON(value: $TSFixMe) {
  if (!(typeof value === 'string')) {
    return null;
  }
  try {
    return JSON.parse(value);
  } catch (e) {
    if (e instanceof SyntaxError) {
      return null;
    }
    throw e;
  }
}
