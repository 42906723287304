import { Map } from 'immutable';
import _ from 'underscore';

import SortState, { SortStateValues, SortStateValueType } from '../constants/SortState';
import { ArgTypes, checkArg } from './ArgValidation';
import { $TSFixMe } from './typescript';

/**
 * Helpers to aid in working with sort states
 */
export default Object.freeze({
  getNext(state: SortStateValueType): SortStateValueType {
    checkArg({ state }, ArgTypes.valueIn(SortStateValues));
    if (state === SortState.UNSORTED) {
      return SortState.SORTED_DESCENDING;
    } if (state === SortState.SORTED_DESCENDING) {
      return SortState.SORTED_ASCENDING;
    } if (state === SortState.SORTED_ASCENDING) {
      return SortState.UNSORTED;
    }
    return SortState.UNSORTED; // should never reach here
  },

  /**
   * Converts the more ergonomic map of column sort states to a url formatted value
   */
  getUrlSortStates(columnSortStates: Map<string, SortStateValueType>): string[] {
    checkArg({ columnSortStates }, ArgTypes.Immutable.map.of(ArgTypes.valueIn(SortStateValues)));
    if (!columnSortStates.isEmpty()) {
      const sort = _.compact(columnSortStates.map((v, k) => {
        const colName = k;
        if (v === SortState.SORTED_ASCENDING) {
          return `${colName}..asc`;
        } if (v === SortState.SORTED_DESCENDING) {
          return `${colName}..desc`;
        }
        return '';
      }).valueSeq().toArray());
      return sort;
    }
    return [];
  },

  getBaseUrlSortState(sortState: SortStateValueType | null | undefined): string {
    checkArg({ sortState }, ArgTypes.nullable(ArgTypes.valueIn(SortStateValues)));
    if (sortState === SortState.SORTED_ASCENDING) {
      return 'ASC';
    } if (sortState === SortState.SORTED_DESCENDING) {
      return 'DESC';
    }
    return '';
  },

  /**
   * Returns a comparator that can be used to perform client-side sorts
   * @param sortOrder A SortState sort order that should be used to perform the sort
   */
  comparator(sortOrder: SortStateValueType) {
    return (valueA: $TSFixMe, valueB: $TSFixMe) => {
      if (sortOrder === SortState.UNSORTED) {
        return 0;
      }
      if (sortOrder === SortState.SORTED_ASCENDING) {
        if (_.isNumber(valueA)) {
          return valueA - valueB;
        }
        return valueA.localeCompare(valueB);
      }
      if (sortOrder === SortState.SORTED_DESCENDING) {
        if (_.isNumber(valueA)) {
          return valueB - valueA;
        }
        return -1 * valueA.localeCompare(valueB);
      }
    };
  },
});
