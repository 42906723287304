import { isEqual } from 'underscore';

import createLoader from '../utils/createLoader';
import { fetchFullUsers } from './UsersAsync';
import { getFullUsersFilterInfo } from './UsersStore';

const UsersLoader = createLoader(state => {
  const { users: { fullUsersLoading, loadedFullUsersFilterInfo } } = state;
  return {
    canFetch: true, // no preconditions
    loading: fullUsersLoading,
    shouldFetch: !isEqual(getFullUsersFilterInfo(state), loadedFullUsersFilterInfo),
  };
}, {
  onFetch: fetchFullUsers,
}, 'UsersLoader');

export default UsersLoader;
