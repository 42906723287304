import { List, Map } from 'immutable';

import { ArgTypes, checkArg } from '../utils/ArgValidation';
import DocumentId from './doc/DocumentId';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

export const ENABLE_TRANSFORMATIONS_METADATA_KEY = 'enableTransformations';

class Project extends getModelHelpers({
  name: { type: ArgTypes.string },
  description: { type: ArgTypes.string },
  steps: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(DocumentId)) },
  metadata: { type: ArgTypes.Immutable.map },
  displayName: { type: ArgTypes.string },
  projectSparkConfigOverrides: { type: ArgTypes.nullable(ArgTypes.string) },
  indexName: { type: ArgTypes.nullable(ArgTypes.string) },
}, 'Project')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ProjectRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: any) {
    checkArg({ obj }, ArgTypes.object);
    return new Project({
      name: obj.name,
      description: obj.description,
      steps: List(obj.steps.map(DocumentId.fromJSON)),
      metadata: Map(obj.metadata),
      displayName: obj.displayName,
      projectSparkConfigOverrides: obj.projectSparkConfigOverrides,
      indexName: obj.indexName,
    });
  }
}

export default Project;
