import { List } from 'immutable';
import Document from '../models/doc/Document';
import Job from '../models/Job';

import { handleErrorsWithMessage, jsonHeaders, networkError, toJSON } from '../utils/Api';
import SERVICES from './ServiceProxy';

export function getSparkConfigNamesQuery(): Promise<List<string>> {
  return fetch(SERVICES.dataset('/jobs/sparkConfigNames'), { headers: jsonHeaders, method: 'GET' })
    .then(handleErrorsWithMessage('Unable to get Spark config names'), networkError)
    .then(toJSON)
    .then(data => List(data));
}

export function updateJobMetadata(jobId: number, metadata: {}): Promise<Document<Job>> {
  return fetch(SERVICES.dataset(`/jobs/${jobId}`), { headers: jsonHeaders, method: 'PUT', body: JSON.stringify(metadata) })
    .then(handleErrorsWithMessage(`Unable to update metadata for job ${jobId}`), networkError)
    .then(toJSON)
    .then(data => Document.fromJSON(data, Job.fromJSON));
}
