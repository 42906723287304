import { List } from 'immutable';

import { BrowserFile, CoreConnectJob } from '../api/CoreConnectService';
import Dataset from '../models/Dataset';
import { StoreReducers } from '../stores/AppAction';
import CoreConnectFileType, { CoreConnectFileTypeE } from './CoreConnectFileType';
import {
  CLOSE_EXPORT_DIALOG,
  FETCH_DEFAULT_CLOUD_PROVIDER_COMPLETED,
  FETCH_FILES_WITH_CONNECT,
  FETCH_FILES_WITH_CONNECT_COMPLETED,
  FETCH_FILES_WITH_CONNECT_FAILED,
  FETCH_SCHEMA_FROM_CLOUD,
  FETCH_SCHEMA_FROM_CLOUD_COMPLETED,
  FETCH_SCHEMA_FROM_CLOUD_FAILED,
  OPEN_EXPORT_DIALOG,
  OPEN_EXPORT_JDBC_DIALOG,
  RESET_CONNECT_STORE,
  SET_ADVANCED_OPTIONS_OPEN,
  SET_APPEND_TO_DATASET,
  SET_NUM_THREADS,
  SET_PRIMARY_KEYS,
  SET_PROFILE_DATASET,
  SET_SINK_BUCKET_NAME,
  SET_SINK_DESCRIPTION,
  SET_SINK_FILE_TYPE,
  SET_SINK_PATH_PREFIX,
  SET_SINK_PROJECT_NAME,
  SET_SOURCE_BUCKET_NAME,
  SET_SOURCE_FILE_TYPE,
  SET_SOURCE_PATH_PREFIX,
  SET_SOURCE_PROJECT_NAME,
  SET_TAMR_SOURCE_DATASET,
  TRANSFER_CLOUD_TO_TAMR,
  TRANSFER_CLOUD_TO_TAMR_COMPLETED,
  TRANSFER_CLOUD_TO_TAMR_FAILED,
  TRANSFER_TAMR_TO_CLOUD,
  TRANSFER_TAMR_TO_CLOUD_COMPLETED,
  TRANSFER_TAMR_TO_CLOUD_FAILED,
} from './CoreConnectServiceActionTypes';

interface CoreConnectServiceStore {
  coreconnectDefaultProvider: string | undefined,
  coreconnectEnabled: boolean,
  coreconnectDefaultProviderRetrievalAttempted: boolean,
  sourceProjectName: string | undefined,
  sourceBucketName: string | undefined,
  sourcePathPrefix: string | undefined,
  sourceFileType: CoreConnectFileTypeE,
  sinkProjectName: string | undefined,
  sinkBucketName: string | undefined,
  sinkPathPrefix: string | undefined,
  sinkFileType: CoreConnectFileTypeE,
  retrievingSourceFiles: boolean,
  fetchSchemaCallCompleted: boolean,
  sourceFiles: List<BrowserFile>,
  numThreads: number,
  profileDataset: boolean,
  appendToDataset: boolean,
  sourceSchema: List<string>,
  primaryKeys: List<string>,
  transferJobs: List<CoreConnectJob>,
  advancedOptionsOpen: boolean,
  sinkDescription: string | undefined,
  exportDialogOpen: boolean,
  exportJDBCDialogOpen: boolean,
  tamrSourceDataset: Dataset | undefined,
  submittingJob: boolean,
}

export const initialState: CoreConnectServiceStore = {
  coreconnectDefaultProvider: undefined,
  coreconnectEnabled: false,
  coreconnectDefaultProviderRetrievalAttempted: false,
  sourceProjectName: localStorage.sourceProjectName,
  sourceBucketName: localStorage.sourceBucketName,
  sourcePathPrefix: localStorage.sourcePathPrefix,
  sourceFileType: CoreConnectFileType.NONE,
  sinkProjectName: localStorage.sinkProjectName,
  sinkBucketName: localStorage.sinkBucketName,
  sinkPathPrefix: localStorage.sinkPathPrefix,
  sinkFileType: CoreConnectFileType.NONE,
  retrievingSourceFiles: false,
  fetchSchemaCallCompleted: false,
  sourceFiles: List(),
  numThreads: 8,
  profileDataset: true,
  appendToDataset: true,
  sourceSchema: List(),
  primaryKeys: List(),
  transferJobs: List(),
  advancedOptionsOpen: false,
  sinkDescription: undefined,
  exportDialogOpen: false,
  exportJDBCDialogOpen: false,
  tamrSourceDataset: undefined,
  submittingJob: false,
} as const;

export const reducers: StoreReducers<CoreConnectServiceStore> = {
  [RESET_CONNECT_STORE]: (state) => {
    return {
      ...state,
      sourceProjectName: undefined,
      sourceBucketName: undefined,
      sourcePathPrefix: undefined,
      sourceFileType: CoreConnectFileType.NONE,
      sinkProjectName: undefined,
      sinkBucketName: undefined,
      sinkPathPrefix: undefined,
      sinkFileType: CoreConnectFileType.NONE,
      retrievingSourceFiles: false,
      fetchSchemaCallCompleted: false,
      sourceFiles: List(),
      numThreads: 4,
      profileDataset: true,
      appendToDataset: false,
      sourceSchema: List(),
      primaryKeys: List(),
      advancedOptionsOpen: false,
      sinkDescription: undefined,
    };
  },
  [FETCH_DEFAULT_CLOUD_PROVIDER_COMPLETED]: (state, { coreconnectDefaultProvider, coreconnectEnabled }) => {
    return { ...state, coreconnectDefaultProvider, coreconnectDefaultProviderRetrievalAttempted: true, coreconnectEnabled };
  },
  [SET_SOURCE_PROJECT_NAME]: (state, { sourceProjectName }) => {
    localStorage.sourceProjectName = sourceProjectName;
    return { ...state, sourceProjectName };
  },
  [SET_SOURCE_BUCKET_NAME]: (state, { sourceBucketName }) => {
    localStorage.sourceBucketName = sourceBucketName;
    return { ...state, sourceBucketName };
  },
  [SET_SOURCE_PATH_PREFIX]: (state, { sourcePathPrefix }) => {
    localStorage.sourcePathPrefix = sourcePathPrefix;
    return { ...state, sourcePathPrefix };
  },
  [SET_SOURCE_FILE_TYPE]: (state, { sourceFileType }) => {
    return { ...state, sourceFileType };
  },
  [SET_SINK_PROJECT_NAME]: (state, { sinkProjectName }) => {
    localStorage.sinkProjectName = sinkProjectName;
    return { ...state, sinkProjectName };
  },
  [SET_SINK_BUCKET_NAME]: (state, { sinkBucketName }) => {
    localStorage.sinkBucketName = sinkBucketName;
    return { ...state, sinkBucketName };
  },
  [SET_SINK_PATH_PREFIX]: (state, { sinkPathPrefix }) => {
    localStorage.sinkPathPrefix = sinkPathPrefix;
    return { ...state, sinkPathPrefix };
  },
  [SET_SINK_FILE_TYPE]: (state, { sinkFileType }) => {
    return { ...state, sinkFileType };
  },
  [FETCH_FILES_WITH_CONNECT]: (state) => {
    return { ...state, retrievingSourceFiles: true };
  },
  [FETCH_FILES_WITH_CONNECT_COMPLETED]: (state, { sourceFiles }) => {
    const { retrievingSourceFiles } = state;

    return {
      ...state,
      sourceFiles: retrievingSourceFiles ? sourceFiles : List(),
      retrievingSourceFiles: false,
    };
  },
  [FETCH_FILES_WITH_CONNECT_FAILED]: (state) => {
    return { ...state, retrievingSourceFiles: false };
  },
  [FETCH_SCHEMA_FROM_CLOUD]: (state) => {
    return { ...state, fetchSchemaCallCompleted: false };
  },
  [FETCH_SCHEMA_FROM_CLOUD_COMPLETED]: (state, { schema }) => {
    return { ...state, sourceSchema: schema, fetchSchemaCallCompleted: true };
  },
  [FETCH_SCHEMA_FROM_CLOUD_FAILED]: (state) => {
    return { ...state, fetchSchemaCallCompleted: true };
  },
  [SET_PRIMARY_KEYS]: (state, { primaryKeys }) => {
    return { ...state, primaryKeys };
  },
  [TRANSFER_CLOUD_TO_TAMR]: (state) => {
    return { ...state, submittingJob: true };
  },
  [TRANSFER_CLOUD_TO_TAMR_COMPLETED]: (state, { job }) => {
    const { transferJobs } = state;
    return { ...state, transferJobs: transferJobs.insert(transferJobs.size, job), submittingJob: false };
  },
  [TRANSFER_CLOUD_TO_TAMR_FAILED]: (state) => {
    return { ...state, submittingJob: false };
  },
  [SET_ADVANCED_OPTIONS_OPEN]: (state) => {
    const { advancedOptionsOpen } = state;
    return { ...state, advancedOptionsOpen: !advancedOptionsOpen };
  },
  [SET_SINK_DESCRIPTION]: (state, { sinkDescription }) => {
    return { ...state, sinkDescription };
  },
  [SET_NUM_THREADS]: (state, { numThreads }) => {
    return { ...state, numThreads };
  },
  [SET_PROFILE_DATASET]: (state, { profileDataset }) => {
    return { ...state, profileDataset };
  },
  [SET_APPEND_TO_DATASET]: (state, { appendToDataset }) => {
    return { ...state, appendToDataset };
  },
  [OPEN_EXPORT_DIALOG]: (state) => {
    return { ...state, exportDialogOpen: true };
  },
  [OPEN_EXPORT_JDBC_DIALOG]: (state) => {
    return { ...state, exportJDBCDialogOpen: true };
  },
  [CLOSE_EXPORT_DIALOG]: (state) => {
    return {
      ...state,
      sinkFileType: CoreConnectFileType.CSV,
      sinkProjectName: undefined,
      sinkBucketName: undefined,
      sinkPathPrefix: undefined,
      exportDialogOpen: false,
      exportJDBCDialogOpen: false,
      tamrSourceDataset: undefined,
      sourceFiles: List(),
      retrievingSourceFiles: false,
    };
  },
  [SET_TAMR_SOURCE_DATASET]: (state, { tamrSourceDataset }) => {
    return { ...state, tamrSourceDataset };
  },
  [TRANSFER_TAMR_TO_CLOUD]: (state) => {
    return { ...state, submittingJob: true };
  },
  [TRANSFER_TAMR_TO_CLOUD_COMPLETED]: (state, { job }) => {
    const { transferJobs } = state;
    return { ...state, transferJobs: transferJobs.insert(transferJobs.size, job), submittingJob: false, exportDialogOpen: false };
  },
  [TRANSFER_TAMR_TO_CLOUD_FAILED]: (state) => {
    return { ...state, submittingJob: false };
  },
};
