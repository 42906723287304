import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import CenterContent from '../components/CenterContent';
import PopoverTrigger from '../components/PopoverTrigger';
import SearchBox from '../components/SearchBox';
import TamrIcon from '../components/TamrIcon';
import ProcurementTaxonomy from '../models/ProcurementTaxonomy';
import ConfirmDeleteTaxonomyDialog from './ConfirmDeleteTaxonomyDialog';
import style from './TaxonomyTitle.module.scss';

const TaxonomyTitle = connect(null, {
})(class TaxonomyTitle extends React.Component {
  static propTypes = {
    isUserACurator: PropTypes.bool.isRequired,
    onSearch: PropTypes.func,
    recipeId: PropTypes.number.isRequired,
    startingSearchQuery: PropTypes.string,
    taxonomy: PropTypes.instanceOf(ProcurementTaxonomy),
  };

  state = {
    showConfirmDeleteDialog: false,
  };

  handleDeleteTaxonomy = (e) => {
    e.preventDefault();
    this.refs.taxonomyPopover.hide();
    this.setState({
      showConfirmDeleteDialog: true,
    });
  };

  handleHideDialog = () => {
    this.setState({
      showConfirmDeleteDialog: false,
    });
  };

  renderSearchBox = () => {
    return (
      <div className={style.searchBox}>
        <SearchBox placeholder="Search for categories" value={this.props.startingSearchQuery} onSearch={this.props.onSearch} />
      </div>
    );
  };

  renderDeleteTaxonomyDialog = () => {
    if (!this.props.isUserACurator) {
      return <div />;
    }
    return (
      <ConfirmDeleteTaxonomyDialog
        onHide={this.handleHideDialog}
        show={this.state.showConfirmDeleteDialog}
      />
    );
  };

  renderOptionsDropdown = () => {
    if (!this.props.isUserACurator || this.state.editingName) {
      return <div />;
    }
    const content = (
      <div className={style.taxonomyMenu}>
        <a onClick={this.handleDeleteTaxonomy}>Delete taxonomy</a>
      </div>
    );

    return (
      <div className={style.optionsPopover}>
        <CenterContent horizontally={false}>
          <PopoverTrigger placement="bottom" content={content} ref="taxonomyPopover">
            <TamrIcon className="dropdown-indicator" iconName="tamr-icon-more-vert" size={18} />
          </PopoverTrigger>
        </CenterContent>
      </div>
    );
  };

  renderName = () => {
    return <span className={style.title}>{'Taxonomy'}</span>;
  };

  render() {
    return (
      <div className={style.taxonomyTitle}>
        <span title="Taxonomy">
          {this.renderName()}
        </span>
        {this.renderOptionsDropdown()}
        {this.renderSearchBox()}
        {this.renderDeleteTaxonomyDialog()}
      </div>
    );
  }
});

export default TaxonomyTitle;
