import classNames from 'classnames';
import { List, Map, Set } from 'immutable';
import Slider, { Range } from 'rc-slider';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BulletedTooltipTrigger from '../components/BulletedTooltipTrigger';
import Button from '../components/Button';
import ConditionalButton from '../components/ConditionalButton';
import FilterButton from '../components/FilterButton';
import Checkbox from '../components/Input/Checkbox';
import PlainInput from '../components/Input/PlainInput';
import PopoverTrigger from '../components/PopoverTrigger';
import { ScoreIconForced } from '../components/ScoreIcon';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import TooltipTrigger from '../components/TooltipTrigger';
import { CLUSTERS_WITH_DELETED_RECORDS_REMOVED, CLUSTERS_WITH_EXISTING_RECORDS_ADDED, CLUSTERS_WITH_EXISTING_RECORDS_REMOVED, CLUSTERS_WITH_NEW_RECORDS_ADDED, NEW_CLUSTERS, REMOVED_CLUSTERS, UNCHANGED_CLUSTERS, WITH_CHANGES_FILTERS } from '../constants/ClusterChanges';
import { HIGH, LOW, MEDIUM } from '../constants/ConfidenceRange';
import { onlyInputDatasets } from '../datasetFilter/DatasetFilterAsync';
import ConfidenceFilter from '../models/ConfidenceFilter';
import { Symbol } from '../models/ScoreThresholds';
import AppState from '../stores/AppState';
import { AppDispatch } from '../stores/MainStore';
import { shortPerc } from '../utils/Numbers';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { getPath } from '../utils/Values';
// @ts-expect-error
import AssignedToMeFilter from './AssignedToMeFilter';
import ClusterVerificationFilterSection from './ClusterVerificationFilterSection';
import filterStyle from './FilterSection.module.scss';
import HighImpactClustersFilterSection from './HighImpactClustersFilterSection';
import { PaneE } from './Pane';
// @ts-expect-error
import SourceDatasetFilter from './SourceDatasetFilter';
import { CLEAR_FILTERS, SET_CUSTOM_SIMILARITY_FILTER, TOGGLE_CUSTOM_SIMILARITY_FILTER } from './SuppliersActionTypes';
import { getAnyClusterFilters } from './SuppliersStore';

interface BoundInputOwnProps {
  key: string
  title: string
  value: string | Array<string> | number
  onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void
}

const BoundInput = ({ key, title, value, onChange }: BoundInputOwnProps) => (
  <PlainInput
    key={key}
    type="number"
    className={filterStyle.inputBox}
    defaultValue={value.toString()}
    title={title}
    onKeyDown={(e) => {
      if (e.keyCode === 13) {
        onChange(e);
      }
    }}
    onBlur={onChange}
    max={100}
    min={0}
    step={1}
    size={3}
  />
);

interface ClusterSourceDatasetFilterOwnProps {
  onHideDialog: () => void
  onOpenDialog: () => void
  pane: PaneE
}

const ClusterSourceDatasetFilter = connect(
  (state: AppState, { onHideDialog, onOpenDialog, pane }: ClusterSourceDatasetFilterOwnProps) => {
    const { suppliers: { [pane]: { selectedSourceDatasets, sourceDatasetsFilterDialogVisible } } } = state;
    return {
      dialogVisible: sourceDatasetsFilterDialogVisible,
      infoTooltipMessage: 'Filter to clusters that have one or more records from the specified sources',
      modifyQuery: onlyInputDatasets(state),
      onHideDialog,
      onOpenDialog,
      selectedSourceDatasets,
    };
  },
  (dispatch, { pane }) => bindActionCreators(
    {
      onRemoveSelectedSource: name => ({
        type: 'Suppliers.setSelectedSourceDatasets',
        pane,
        datasetsToAdd: Set(),
        datasetsToRemove: Set.of(name),
      }),
      onSetDialogVisible: show => ({
        type: 'Suppliers.setSourceDatasetsDialogVisible',
        pane,
        show,
      }),
      onSubmitDialog: (datasetsToAdd, datasetsToRemove) => ({
        type: 'Suppliers.setSelectedSourceDatasets',
        pane,
        datasetsToAdd,
        datasetsToRemove,
      }),
    },
    dispatch,
  ),
)(SourceDatasetFilter);

interface OwnProps {
  pane: PaneE
}

const mapStateToProps = (state: AppState, { pane }: OwnProps) => {
  const {
    suppliers: {
      [pane]: {
        data,
        lastFetchData,
        confidenceFilter,
        clusterChanges,
        hasCustomConfidenceFilter,
        customConfidenceFilter,
        hasCustomSimilarityFilter,
        customSimilarityFilter,
      },
    },
    config: { clusterConfidenceThresholds },
  } = state;
  return {
    anyChange: !data.equals(lastFetchData),
    anyFilters: getAnyClusterFilters(state, pane),
    clusterChanges,
    confidenceFilter,
    pane,
    publishedClustersExist: !!getPath(selectActiveProjectInfo(state)?.projectWithStatus.recipes.find(r => r.recipe.data.type === 'DEDUP'), 'materializations', 'publishClusters', 'lastRun', 'timestamp'),
    hasCustomConfidenceFilter,
    customConfidenceFilter,
    clusterConfidenceThresholds,
    hasCustomSimilarityFilter,
    customSimilarityFilter: Math.round(customSimilarityFilter * 100),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: AppDispatch, { pane }: OwnProps) => bindActionCreators({
  onClearFilters: () => ({ type: CLEAR_FILTERS, pane }),
  onFilterToMyAssignments: () => ({ type: 'Suppliers.setMyAssignments', pane }),
  onFilterToClustersWithChanges: () => ({ type: 'Suppliers.setClustersWithChanges', pane }),
  onReload: () => ({ type: 'Suppliers.reload', pane }),
  onToggleClusterChangesFilter: filterOption => ({ type: 'Suppliers.toggleClusterChangesFilter', pane, filterOption }),
  onToggleWithClusterChangesFilter: () => ({ type: 'Suppliers.toggleWithChangesFilter', pane }),
  onToggleConfidenceFilter: filterOption => ({ type: 'Suppliers.toggleConfidenceFilter', pane, filterOption }),
  onToggleCustomConfidenceFilter: value => ({ type: 'Suppliers.toggleCustomConfidenceFilter', pane, value }),
  onSetCustomConfidenceFilter: customConfidenceFilter => ({ type: 'Suppliers.setCustomConfidenceFilter', pane, customConfidenceFilter }),
  onToggleCustomSimilarityFilter: value => ({ type: TOGGLE_CUSTOM_SIMILARITY_FILTER, pane, value }),
  onSetCustomSimilarityFilter: customSimilarityFilter => ({ type: SET_CUSTOM_SIMILARITY_FILTER, pane, customSimilarityFilter: customSimilarityFilter / 100 }),
}, dispatch);

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type Bounds = [number, number];

class ClusterFilters extends React.Component<Props> {
  filterPanel = React.createRef<PopoverTrigger>();

  state = {
    sliderKey: 1, // to force reconsideration of default handle positions after input change
    inputKey: 1, // to force reconsideration of default input values after slider change
    lowerBound: 0,
    upperBound: 1,
    similaritySliderKey: -1, // Start at -1 to not conflict with `sliderKey`. Decrements by 2 on each update to not conflict with `similarityInputKey`
    similarityInputKey: -2, // Start at -2 to not conflict with `inputKey`. Also decrements by 2 on each update
    similarityThreshold: this.props.customSimilarityFilter,
  };

  UNSAFE_componentWillMount() {
    const { customConfidenceFilter } = this.props;
    if (customConfidenceFilter) {
      this.setState({ lowerBound: customConfidenceFilter.lowerBound, upperBound: customConfidenceFilter.upperBound });
    }
  }

  onConfidenceChangeUncommitted = ([lowerBound, upperBound]: Bounds) => {
    this.setState({ lowerBound, upperBound, inputKey: this.state.inputKey + 1 });
  };

  onConfidenceChangeSlider = ([lowerBound, upperBound]: Bounds) => {
    this.setState({ inputKey: this.state.inputKey + 1 });
    return this.onConfidenceChange([lowerBound, upperBound]);
  };

  onConfidenceChangeInput = ([lowerBound, upperBound]: Bounds) => {
    this.setState({ sliderKey: this.state.sliderKey + 1 });
    return this.onConfidenceChange([lowerBound, upperBound]);
  };

  onConfidenceChange = ([lowerBound, upperBound]: Bounds) => {
    const { onSetCustomConfidenceFilter } = this.props;
    this.setState({ lowerBound, upperBound });
    onSetCustomConfidenceFilter(new ConfidenceFilter({ lowerBound, upperBound }));
  };

  renderConfidenceSlider = () => {
    const { hasCustomConfidenceFilter, customConfidenceFilter, clusterConfidenceThresholds } = this.props;
    const { sliderKey, inputKey, lowerBound, upperBound } = this.state;

    if (!hasCustomConfidenceFilter || !customConfidenceFilter || !clusterConfidenceThresholds) {
      return undefined;
    }
    const { medium, high } = clusterConfidenceThresholds;
    return (
      <div className={filterStyle.confidenceFilter}>
        <BoundInput
          key={`lower-${inputKey}`}
          value={Math.round(lowerBound * 100)}
          title="Lower Bound"
          onChange={e => {
            const target = e.target as HTMLInputElement;
            this.onConfidenceChangeInput([
              parseFloat(target.value) / 100,
              customConfidenceFilter.upperBound,
            ]);
          }}
        />
        <Range
          key={sliderKey}
          allowCross={false}
          className={filterStyle.range}
          min={0}
          max={1}
          step={0.01}
          defaultValue={[customConfidenceFilter.lowerBound, customConfidenceFilter.upperBound]}
          onChange={this.onConfidenceChangeUncommitted}
          onAfterChange={this.onConfidenceChangeSlider}
          marks={{
            0: {
              style: { textAlign: 'center', marginLeft: 0, left: 0, width: shortPerc(medium, 1) },
              label: <ScoreIconForced className="score-icon-legend" symbol={Symbol.L} size={10} />,
            },
            [medium]: {
              style: { textAlign: 'center', marginLeft: 0, left: shortPerc(medium, 1), width: shortPerc(high - medium, 1) },
              label: <ScoreIconForced className="score-icon-legend" symbol={Symbol.M} size={10} />,
            },
            [high]: {
              style: { textAlign: 'center', marginLeft: 0, left: shortPerc(high, 1), width: shortPerc(1 - high, 1) },
              label: <ScoreIconForced className="score-icon-legend" symbol={Symbol.H} size={10} />,
            },
            1: { style: null, label: '' },
          }}
        />
        <BoundInput
          key={`upper-${inputKey}`}
          value={Math.round(upperBound * 100)}
          title="Upper Bound"
          onChange={e => {
            const target = e.target as HTMLInputElement;
            this.onConfidenceChangeInput([
              customConfidenceFilter.lowerBound,
              parseFloat(target.value) / 100,
            ]);
          }}
        />
      </div>
    );
  };

  onSimilarityChangeSlider = (newSimilarityThreshold: number) => {
    this.setState({
      similarityInputKey: this.state.similarityInputKey - 2,
    });

    this.onChangeToSimilarityThreshold(newSimilarityThreshold);
  };

  onSimilarityChangeInput = (newSimilarityThreshold: number) => {
    if (newSimilarityThreshold >= 0 && newSimilarityThreshold <= 100) {
      this.setState({
        similaritySliderKey: this.state.similaritySliderKey - 2,
      });

      this.onChangeToSimilarityThreshold(newSimilarityThreshold);
    }
  };

  onChangeToSimilarityThreshold = (newSimilarityThreshold: number) => {
    const { hasCustomSimilarityFilter, onSetCustomSimilarityFilter } = this.props;

    this.setState({ similarityThreshold: newSimilarityThreshold });

    if (hasCustomSimilarityFilter) {
      onSetCustomSimilarityFilter(newSimilarityThreshold);
    }
  };

  clearFilters = () => {
    const { onClearFilters } = this.props;
    onClearFilters();
    this.setState({
      lowerBound: 0,
      upperBound: 1,
    });
  };

  render() {
    const { anyChange, anyFilters, clusterChanges, confidenceFilter, pane, onFilterToMyAssignments, onFilterToClustersWithChanges, onReload, onToggleClusterChangesFilter, onToggleWithClusterChangesFilter, onToggleConfidenceFilter, publishedClustersExist, onToggleCustomConfidenceFilter, hasCustomConfidenceFilter, onToggleCustomSimilarityFilter, hasCustomSimilarityFilter } = this.props;

    const { similaritySliderKey, similarityInputKey, similarityThreshold } = this.state;

    return (
      <FilterButton
        onClear={anyFilters ? this.clearFilters : undefined}
        hasFilters={anyFilters}
        onRefresh={anyChange ? onReload : undefined}
        noun="clusters"
        render={content => (
          <PopoverTrigger
            ref={this.filterPanel}
            className={classNames(filterStyle.supplierPageFilter, filterStyle.clusterFilters)}
            placement="bottom"
            content={
              <div>
                <div className={filterStyle.titleContainer}>
                  <div className={filterStyle.title}>Cluster filters</div>
                  <Button className={filterStyle.titleButton} buttonType="Link" onClick={this.clearFilters}>clear all</Button>
                </div>
                <div className={filterStyle.filterSection}>
                  <Button
                    className={filterStyle.quickButton}
                    buttonType="Secondary"
                    onClick={onFilterToMyAssignments}
                  >
                    My open assignments
                  </Button>
                </div>
                <div className={filterStyle.filterSection}>
                  <ConditionalButton
                    className={filterStyle.quickButton}
                    buttonType="Secondary"
                    onClick={onFilterToClustersWithChanges}
                    preconditions={Map<string, boolean>().set('Published clusters must exist before filtering based on cluster changes', publishedClustersExist)}
                    tooltipPlacement="right"
                  >
                    Clusters with changes
                  </ConditionalButton>
                </div>
                <AssignedToMeFilter pane={pane} />
                <HighImpactClustersFilterSection pane={pane} />
                <ClusterVerificationFilterSection pane={pane} />
                <div className={filterStyle.filterSection}>
                  <div className={filterStyle.filterPanelHeader}>
                    Average Confidence&nbsp;
                    <TooltipTrigger
                      placement="right"
                      content={(
                        <span>
                          Cluster confidence is a direct result of how similar each <Term>record</Term> is within the cluster.&nbsp;
                          Clusters with 1 record as well as clusters that have been changed since the last update are not associated with any confidence scores.
                        </span>
                      )}
                    >
                      <TamrIcon className={filterStyle.help} iconName="info-outline" size={14} />
                    </TooltipTrigger>
                  </div>
                  <div className={filterStyle.option}>
                    <Checkbox
                      className={filterStyle.checkboxWrapper}
                      onChange={() => onToggleConfidenceFilter(HIGH)}
                      title="High"
                      value={confidenceFilter.has(HIGH)}
                      size={15}
                      titlePosition="right"
                    />
                    <div>
                      <ScoreIconForced
                        className="score-icon"
                        symbol={Symbol.H}
                        size={10}
                      />
                    </div>
                  </div>
                  <div className={filterStyle.option}>
                    <Checkbox
                      className={filterStyle.checkboxWrapper}
                      onChange={() => onToggleConfidenceFilter(MEDIUM)}
                      title="Medium"
                      value={confidenceFilter.has(MEDIUM)}
                      size={15}
                      titlePosition="right"
                    />
                    <div>
                      <ScoreIconForced
                        className="score-icon"
                        symbol={Symbol.M}
                        size={10}
                      />
                    </div>
                  </div>
                  <div className={filterStyle.option}>
                    <Checkbox
                      className={filterStyle.checkboxWrapper}
                      onChange={() => onToggleConfidenceFilter(LOW)}
                      title="Low"
                      value={confidenceFilter.has(LOW)}
                      size={15}
                      titlePosition="right"
                    />
                    <div>
                      <ScoreIconForced
                        className="score-icon"
                        symbol={Symbol.L}
                        size={10}
                      />
                    </div>
                  </div>
                  <div className={filterStyle.option}>
                    <Checkbox
                      className={filterStyle.checkboxWrapper}
                      onChange={onToggleCustomConfidenceFilter}
                      title="Custom range"
                      value={hasCustomConfidenceFilter}
                      size={15}
                      titlePosition="right"
                    />
                  </div>
                  {this.renderConfidenceSlider()}
                </div>
                <div className={filterStyle.filterSection}>
                  <div className={filterStyle.filterPanelHeader}>
                    Similarity
                  </div>
                  <div className={filterStyle.option}>
                    <Checkbox
                      className={filterStyle.checkboxWrapper}
                      onChange={onToggleCustomSimilarityFilter}
                      title={`Has clusters at or more than ${similarityThreshold}% similar`}
                      value={hasCustomSimilarityFilter}
                      size={15}
                      titlePosition="right"
                    />
                  </div>
                  <div className={filterStyle.similarityFilter}>
                    <Slider
                      key={similaritySliderKey}
                      className={filterStyle.similaritySlider}
                      min={0}
                      max={100}
                      step={1}
                      defaultValue={similarityThreshold}
                      onChange={this.onSimilarityChangeSlider}
                      disabled={!hasCustomSimilarityFilter}
                    />
                    <PlainInput
                      key={similarityInputKey}
                      type="number"
                      className={classNames(filterStyle.inputBox, { [filterStyle.inputBoxInactive]: !hasCustomSimilarityFilter })}
                      defaultValue={similarityThreshold.toString()}
                      onKeyDown={(e) => {
                        const target = e.target as HTMLInputElement;
                        if (e.key === 'Enter') {
                          this.onSimilarityChangeInput(parseInt(target.value, 10));
                        }
                      }}
                      onBlur={(e) => this.onSimilarityChangeInput(parseInt(e.target.value, 10))}
                      min={0}
                      max={100}
                      step={1}
                      size={3}
                      disabled={!hasCustomSimilarityFilter}
                    />
                  </div>
                </div>
                <div className={classNames(filterStyle.filterSection, { [filterStyle.disabled]: !publishedClustersExist })}>
                  <div className={filterStyle.filterPanelHeader}>
                    <BulletedTooltipTrigger
                      placement="right"
                      items={publishedClustersExist ? List() : List.of('Published clusters must exist before filtering based on cluster changes')}
                    >
                      <span className={filterStyle.clusterChangesTitle}>Cluster changes from last publish&nbsp;
                        <BulletedTooltipTrigger
                          placement="right"
                          items={publishedClustersExist ? List.of('Filter to clusters that have or have not changed since published clusters were last updated. Changes do not include record value changes made from updating the Unified Dataset.') : List()}
                        >
                          <TamrIcon className={filterStyle.help} iconName="info-outline" size={14} />
                        </BulletedTooltipTrigger>
                      </span>
                    </BulletedTooltipTrigger>
                  </div>
                  <div className={filterStyle.option}>
                    <Checkbox
                      className={filterStyle.checkboxWrapper}
                      onChange={() => onToggleClusterChangesFilter(UNCHANGED_CLUSTERS)}
                      title="Unchanged"
                      value={clusterChanges.has(UNCHANGED_CLUSTERS)}
                      size={15}
                      titlePosition="right"
                      disabled={!publishedClustersExist}
                    />
                  </div>
                  <div className={filterStyle.option}>
                    <Checkbox
                      className={filterStyle.checkboxWrapper}
                      onChange={() => onToggleWithClusterChangesFilter()}
                      title="With changes"
                      value={WITH_CHANGES_FILTERS.every(f => clusterChanges.has(f))}
                      semiChecked={WITH_CHANGES_FILTERS.some(f => clusterChanges.has(f))}
                      size={15}
                      titlePosition="right"
                      disabled={!publishedClustersExist}
                    />
                  </div>
                  <div className={classNames(filterStyle.option, filterStyle.indented)}>
                    <Checkbox
                      className={filterStyle.checkboxWrapper}
                      onChange={() => onToggleClusterChangesFilter(CLUSTERS_WITH_NEW_RECORDS_ADDED)}
                      title="Records added from new or updated sources"
                      value={clusterChanges.has(CLUSTERS_WITH_NEW_RECORDS_ADDED)}
                      size={15}
                      titlePosition="right"
                      disabled={!publishedClustersExist}
                    />
                  </div>
                  <div className={classNames(filterStyle.option, filterStyle.indented)}>
                    <Checkbox
                      className={filterStyle.checkboxWrapper}
                      onChange={() => onToggleClusterChangesFilter(CLUSTERS_WITH_EXISTING_RECORDS_ADDED)}
                      title="Records moved from other clusters"
                      value={clusterChanges.has(CLUSTERS_WITH_EXISTING_RECORDS_ADDED)}
                      size={15}
                      titlePosition="right"
                      disabled={!publishedClustersExist}
                    />
                  </div>
                  <div className={classNames(filterStyle.option, filterStyle.indented)}>
                    <Checkbox
                      className={filterStyle.checkboxWrapper}
                      onChange={() => onToggleClusterChangesFilter(CLUSTERS_WITH_EXISTING_RECORDS_REMOVED)}
                      title="Records moved to other clusters"
                      value={clusterChanges.has(CLUSTERS_WITH_EXISTING_RECORDS_REMOVED)}
                      size={15}
                      titlePosition="right"
                      disabled={!publishedClustersExist}
                    />
                  </div>
                  <div className={classNames(filterStyle.option, filterStyle.indented)}>
                    <Checkbox
                      className={filterStyle.checkboxWrapper}
                      onChange={() => onToggleClusterChangesFilter(CLUSTERS_WITH_DELETED_RECORDS_REMOVED)}
                      title="Records deleted from sources"
                      value={clusterChanges.has(CLUSTERS_WITH_DELETED_RECORDS_REMOVED)}
                      size={15}
                      titlePosition="right"
                      disabled={!publishedClustersExist}
                    />
                  </div>
                  <div className={classNames(filterStyle.option, filterStyle.indented)}>
                    <Checkbox
                      className={filterStyle.checkboxWrapper}
                      onChange={() => onToggleClusterChangesFilter(NEW_CLUSTERS)}
                      title="New clusters"
                      value={clusterChanges.has(NEW_CLUSTERS)}
                      size={15}
                      titlePosition="right"
                      disabled={!publishedClustersExist}
                    />
                    <BulletedTooltipTrigger
                      placement="right"
                      items={publishedClustersExist ? List.of('Clusters that did not exist in the last set of published clusters.') : List()}
                    >
                      <TamrIcon className={filterStyle.help} iconName="info-outline" size={14} />
                    </BulletedTooltipTrigger>
                  </div>
                  <div className={classNames(filterStyle.option, filterStyle.indented)}>
                    <Checkbox
                      className={filterStyle.checkboxWrapper}
                      onChange={() => onToggleClusterChangesFilter(REMOVED_CLUSTERS)}
                      title="Empty clusters"
                      value={clusterChanges.has(REMOVED_CLUSTERS)}
                      size={15}
                      titlePosition="right"
                      disabled={!publishedClustersExist}
                    />
                    <BulletedTooltipTrigger
                      placement="right"
                      items={publishedClustersExist ? List.of(<span>Clusters that had <Term>records</Term> in the last set of published clusters but are currently empty. These clusters will be archived when you update published clusters again</span>) : List()}
                    >
                      <TamrIcon className={filterStyle.help} iconName="info-outline" size={14} />
                    </BulletedTooltipTrigger>
                  </div>
                </div>
                <ClusterSourceDatasetFilter
                  pane={pane}
                  onHideDialog={() => this.filterPanel.current?.attachHideListener()}
                  onOpenDialog={() => this.filterPanel.current?.removeHideListener()}
                />
              </div>
            }
          >
            {content}
          </PopoverTrigger>
        )}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClusterFilters);
