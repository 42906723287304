import { List } from 'immutable';
/* eslint-disable react/no-multi-comp */
import React from 'react';
import _ from 'underscore';

import { $TSFixMe } from '../utils/typescript';
import CommentButton from './CommentButton';
import style from './CommentColumn.module.scss';
import Cell from './Table/Cell';
import Column from './Table/Column';
import TamrIcon from './TamrIcon';
import TooltipTrigger from './TooltipTrigger';

const CommentColumn: React.FC<{
  onClick: () => void
  rows: List<$TSFixMe>
}> = ({ onClick, rows }) => {
  const header = (
    <Cell className={style.header}>
      <TooltipTrigger placement="top" content="Comments">
        <TamrIcon iconName="comment" size={16} />
      </TooltipTrigger>
    </Cell>
  );
  const cell = ({ rowIndex }: { rowIndex: number }) => {
    return (
      <CommentButton onClick={onClick} hasComments={!_.isEmpty(rows.get(rowIndex).comments)} />
    );
  };
  return (
    <Column key="comment" columnKey="comment" width={30} {...{ header, cell }} allowCellsRecycling fixed />
  );
};

export default CommentColumn;
