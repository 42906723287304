import { List } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import _ from 'underscore';

import Button from '../components/Button';
import ColumnWidthProvider from '../components/ColumnWidthProvider';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Table from '../components/Table/Table';
import TooltipTrigger from '../components/TooltipTrigger';
import Dataset from '../models/Dataset';
import DatasetMetadataSpec from '../models/DatasetMetadataSpec';
import Document from '../models/doc/Document';
import { getSingleSelectedDataset } from './DatasetMetadataStore';
import style from './MetadataTable.module.scss';

const MetadataTable = _.compose(
  connect(state => {
    const selectedDataset = getSingleSelectedDataset(state);
    const { datasetMetadata: { currentMetadata } } = state;
    return {
      selectedDataset,
      currentMetadata,
    };
  }, {
    onConfirmDeleteProperty: (metadataToDelete) => ({ type: 'DatasetMetadata.confirmDeleteMetadata', metadataToDelete: List.of(metadataToDelete) }),
  }),
)(class MetadataTable extends React.Component {
  static propTypes = {
    currentMetadata: ImmutablePropTypes.listOf(PropTypes.instanceOf(DatasetMetadataSpec)),
    onConfirmDeleteProperty: PropTypes.func.isRequired,
    selectedDataset: Document.propType.withDataType(Dataset),
  };

  render() {
    const { currentMetadata, onConfirmDeleteProperty, selectedDataset } = this.props;

    if (!selectedDataset) { return null; }

    return (
      <div className={style.metadataTable}>
        <AutoSizer>
          {({ width, height }) => (
            <ColumnWidthProvider>
              <Table
                getLength={() => currentMetadata.size}
                tableType="stripes"
                height={height}
                width={width}
              >
                <Column
                  key="attribute"
                  columnKey="attribute"
                  width={0.25 * width}
                  isResizable
                  flexGrow={1}
                  header={<Cell>Object</Cell>}
                  cell={({ rowIndex }) => {
                    const attr = currentMetadata.get(rowIndex).attribute || selectedDataset.data.name;

                    return (
                      <span title={attr}>
                        <Cell>
                          { attr }
                        </Cell>
                      </span>
                    );
                  }}
                />
                <Column
                  key="key"
                  columnKey="key"
                  width={0.25 * width}
                  isResizable
                  flexGrow={1}
                  header={<Cell>Key</Cell>}
                  cell={({ rowIndex }) => {
                    const key = currentMetadata.get(rowIndex).key;

                    return (
                      <span title={key}>
                        <Cell>
                          { key }
                        </Cell>
                      </span>
                    );
                  }}
                />
                <Column
                  key="value"
                  columnKey="value"
                  width={0.3 * width}
                  isResizable
                  flexGrow={1}
                  header={<Cell>Value</Cell>}
                  cell={({ rowIndex }) => {
                    const val = currentMetadata.get(rowIndex).value;

                    return (
                      <span
                        className={style.valueColumn}
                        title={val}
                      >
                        <Cell className={style.valueCell}>
                          { val }
                        </Cell>
                        <TooltipTrigger placement="top" content={<span>Delete property</span>}>
                          <Button
                            className={style.deleteButton}
                            buttonType="Link"
                            icon="delete"
                            iconSize={16}
                            onClick={() => onConfirmDeleteProperty(currentMetadata.get(rowIndex))}
                          />
                        </TooltipTrigger>
                      </span>
                    );
                  }}
                />
              </Table>
            </ColumnWidthProvider>
          )}
        </AutoSizer>
      </div>
    );
  }
});

export default MetadataTable;
