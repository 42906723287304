import { List, Record } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Term from '../components/Term';
import PRODUCT_NAME from '../utils/ProductName';
import { selectActiveProjectInfo } from '../utils/Selectors';
import style from './CategorizationDashboardGuide.module.scss';
import DashboardGuide from './DashboardGuide';
import { getCategorizationStatus } from './DashboardUtils';
import categorizeImage from './images/categorize.svg';
import datasetsImage from './images/datasets.svg';
import taxonomyImage from './images/taxonomy.svg';
import unifiedDatasetImage from './images/unifiedDataset.svg';

const CategorizationDashboardGuide = _.compose(
  connect(state => {
    const { recipeId, smRecipeId } = selectActiveProjectInfo(state);
    if (state.categorizationDashboard.loading ||
      state.categorizationDashboard.loadingTaxonomy ||
      state.unifiedDatasets.loading
    ) {
      return { loading: true };
    }
    return { recipeId, smRecipeId, stepCompletionStatuses: getCategorizationStatus(state), loading: false };
  }),
)(({ stepCompletionStatuses, recipeId, smRecipeId }) => {
  const dashboardStepsInfo = List([
    new (Record({
      wizardTitle: <span>Add<br />Datasets</span>,
      wizardCompletedTitle: <span>Datasets<br />Added</span>,
      wizardIcon: 'library-books',
      wizardURL: `/datasets/recipe/${smRecipeId}`,
      cardTitle: <span>Add your data</span>,
      cardText: <span>Adding data will bring your datasets into one central place and allow you to move forward with your categorization project.</span>,
      documentationURL: 'overall-workflow-classification',
      cardButtonText: <span>Go to Datasets</span>,
      cardImage: datasetsImage,
      isStepCompleted: stepCompletionStatuses.datasets,
    }))(),
    new (Record({
      wizardTitle: <span>Create Unified<br />Dataset</span>,
      wizardCompletedTitle: <span>Unified Dataset<br />Mapped</span>,
      wizardIcon: 'settings-input-component-rotated',
      wizardURL: `/schema-mapping/recipe/${smRecipeId}`,
      cardTitle: <span>Create and apply your unified dataset</span>,
      cardText: <span>Map attributes across your datasets to a single, unified dataset.</span>,
      documentationURL: 'working-with-the-unified-dataset',
      cardButtonText: <span>Go to Schema Mapping</span>,
      cardImage: unifiedDatasetImage,
      isStepCompleted: stepCompletionStatuses.unifiedDataset,
    }))(),
    new (Record({
      wizardTitle: <span>Add<br />Categories</span>,
      wizardCompletedTitle: <span>Categories<br />Added</span>,
      wizardIcon: 'line-style',
      wizardURL: `/taxonomy/recipe/${recipeId}`,
      cardTitle: <span>Add your taxonomy</span>,
      cardText: <span>Your taxonomy will be used to categorize all the <Term>records</Term> across the datasets you provide.</span>,
      documentationURL: 'working-with-the-taxonomy',
      cardButtonText: <span>Go to Categories</span>,
      cardImage: taxonomyImage,
      isStepCompleted: stepCompletionStatuses.taxonomy,
    }))(),
    new (Record({
      wizardTitle: <div><div>Categorize</div><div className={style.stepEllipsis}><Term>Parts</Term></div></div>,
      wizardCompletedTitle: <div><div className={style.stepEllipsis}><Term>Parts</Term></div><div>Verified</div></div>,
      wizardIcon: 'people',
      wizardURL: `/spend/recipe/${recipeId}`,
      cardTitle: <span>Categorize <Term>records</Term> and generate {PRODUCT_NAME} categorizations</span>,
      cardText: <span>{PRODUCT_NAME} learns from each verified categorization so it can categorize the rest of your <Term>records</Term> automatically. As a curator, you can categorize <Term>records</Term> yourself or assign and verify reviewers’ categorizations.</span>,
      documentationURL: 'categorizing-records',
      cardButtonText: <span>Go to <Term>Parts</Term></span>,
      cardImage: categorizeImage,
      isStepCompleted: stepCompletionStatuses.categorize,
    }))(),
  ]);
  return (
    <DashboardGuide dashboardStepsInfo={dashboardStepsInfo} />
  );
});

export default CategorizationDashboardGuide;
