import { List, Set } from 'immutable';
import $ from 'jquery';
import _ from 'underscore';
import uri from 'urijs';

import { runOperation } from '../api/RecipeClient';
import FeedbackStatus from '../constants/FeedbackStatus';
import { determineFilterByExpertStatus } from '../constants/FilterByExpertStatus';
import { RecipeOperations } from '../constants/RecipeOperations';
import { CATEGORIZATION } from '../constants/RecipeType';
import Response from '../constants/Response';
import SortState from '../constants/SortState';
import { SHOW } from '../errorDialog/ErrorDialogActionTypes';
import EsRecord from '../models/EsRecord';
import FeedbackResponse from '../models/FeedbackResponse';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import {
  getAuthorizedUser,
  getUnifiedDatasetName,
  selectActiveProjectInfo,
} from '../utils/Selectors';
import SortUtils from '../utils/SortUtils';
import { getPath } from '../utils/Values';
import { getFilterInfo, getSelectedRecords, getTopExpertCategorizations } from './TransactionStore';
import { TxnUrlBuilder } from './TransactionUtils';

const doFetchFeedbackSummary = (dispatch, unifiedDatasetName) => {
  return $.ajax({
    url: uri(SERVICES.procure('/feedback/summary')).query({ dataset: unifiedDatasetName }),
    method: 'GET',
    cache: false,
    success(data) {
      dispatch({ type: 'Transactions.feedbackSummaryFetchCompleted', numAssigned: data.numberAssigned, numResponded: data.numberResponded });
    },
    error(response) {
      dispatch({ type: SHOW,
        detail: 'Error fetching feedback summary',
        response,
      });
    },
  });
};

export const fetchFeedbackSummary = () => (dispatch, getState) => {
  const state = getState();
  const { unifiedDatasetName } = selectActiveProjectInfo(state);
  return doFetchFeedbackSummary(dispatch, unifiedDatasetName);
};

export const fetchTransactions = () => (dispatch, getState) => {
  const state = getState();
  const { transactions: { pageNum, pageSize, columnSortStates, selectedDatasetIds, categoryIds, categorizedByMe, labeledByTamr, showExternalData, expertResponsesAgree, expertResponsesDisagree, expertsPending, expertsUnsure, expertsNone, unlabeled, unlabeledByTamr, labelAgreesWithTamr, labelDisagreesWithTamr, hasComments, queryString, assignedToMeComplete, assignedToMeToDo, hideReviewed, assignmentStatusNone, assignmentStatusSome, assignmentStatusAll, assignmentStatusUnassigned, assignedToUsers, categorizedByUsers, highImpact, confidenceLow, confidenceMedium, confidenceHigh, hasConfidence, confidence, sortByConfidence, fetchRecordsWithoutClusters }, allSourceDatasets: { datasets: sourceDatasetDocs } } = state;
  const { unifiedDatasetName, recipeDoc, unifiedDatasetDoc, projectType } = selectActiveProjectInfo(state);
  const filterInfo = getFilterInfo(state);
  const datasetIdsToIgnore = showExternalData
    ? []
    : sourceDatasetDocs
      .filter(ds => getPath(recipeDoc, 'data', 'metadata', 'CATEGORIZATION', 'datasetExternalStatus', ds.data.name))
      .map(ds => ds.id.id)
      .toJS();
  dispatch({ type: 'Transactions.fetchTransactions' });
  $.ajax({
    url: new TxnUrlBuilder(projectType)
      .unifiedDatasetName(unifiedDatasetName)
      .datasetIds(selectedDatasetIds.toJS())
      .datasetIdsToIgnore(datasetIdsToIgnore)
      .pageNum(pageNum)
      .pageSize(pageSize)
      .categoryIds(categoryIds.toJS())
      .categorizedByMe(categorizedByMe)
      .labeledByTamr(labeledByTamr)
      .expertResponsesAgree(expertResponsesAgree)
      .expertResponsesDisagree(expertResponsesDisagree)
      .expertsPending(expertsPending)
      .expertsUnsure(expertsUnsure)
      .expertsNone(expertsNone)
      .unlabeled(unlabeled)
      .unlabeledByTamr(unlabeledByTamr)
      .labelAgreesWithTamr(labelAgreesWithTamr)
      .labelDisagreesWithTamr(labelDisagreesWithTamr)
      .hasComments(hasComments)
      .queryString(queryString)
      .sort(SortUtils.getUrlSortStates(columnSortStates))
      .assignedToMe(determineFilterByExpertStatus(assignedToMeComplete, assignedToMeToDo))
      .hideReviewed(hideReviewed)
      .assignmentStatusNone(assignmentStatusNone)
      .assignmentStatusSome(assignmentStatusSome)
      .assignmentStatusAll(assignmentStatusAll)
      .assignmentStatusUnassigned(assignmentStatusUnassigned)
      .assignedToUsers(assignedToUsers.toJS())
      .categorizedByUsers(categorizedByUsers.toJS())
      .highImpact(highImpact)
      .confidenceLow(confidenceLow)
      .confidenceMedium(confidenceMedium)
      .confidenceHigh(confidenceHigh)
      .hasConfidence(hasConfidence)
      .confidence(confidence)
      .sortByConfidence(sortByConfidence)
      .sortByQuestionImpactRank((!sortByConfidence || sortByConfidence === SortState.UNSORTED) && projectType === CATEGORIZATION)
      .fetchRecordsWithoutClusters(fetchRecordsWithoutClusters)
      .build(),
    method: 'GET',
    cache: false,
    success: ({ items, total }) => {
      const rows = List(items).map(hit => {
        return new EsRecord(hit, unifiedDatasetDoc);
      });
      dispatch({ type: 'Transactions.fetchTransactionsCompleted', rows, total, filterInfo });
      if (assignedToMeComplete || assignedToMeToDo) {
        doFetchFeedbackSummary(dispatch, unifiedDatasetName);
      }
    },
    error: (response) => {
      dispatch({ type: 'Transactions.fetchTransactionsFailed', filterInfo });
      dispatch({ type: SHOW, detail: 'Error loading data', response });
    },
  });
};

const doRespond = ({ state, dispatch, records, categoryId, response, reason }) => {
  const unifiedDatasetName = getUnifiedDatasetName(state);
  const { taxonomy: { taxonomy } } = state;
  const authorizedUser = getAuthorizedUser(state);
  const data = records.map((record) => {
    const category = taxonomy.findCategoryById(categoryId || (record.manualCategorization || record.suggestedCategorization).categoryId);
    const userResponse = record.feedbackForUser(authorizedUser.username)?.responses.find(suggestion => suggestion.categoryId === category.categoryId);
    return FeedbackResponse.fromJSON({
      entityId: record.recordId,
      response,
      categoryId: category.categoryId,
      reason: reason === undefined && userResponse ? userResponse.reason : reason,
    });
  });

  return $.ajax({
    url: SERVICES.procure(`/feedback/response/${unifiedDatasetName}`),
    method: 'POST',
    data: JSON.stringify(data.toJS()),
    contentType: 'application/json',
    success: () => {
      doFetchFeedbackSummary(dispatch, unifiedDatasetName);
    },
    error: (resp) => {
      dispatch({ type: SHOW, detail: 'Error responding to questions', response: resp });
    },
  });
};

const addResponses = ({ records, categoryId, response, reason }) => (dispatch, getState) => {
  dispatch({ type: 'Transactions.addResponses', ids: records.map(r => r.recordId) });
  doRespond({ state: getState(), dispatch, records, categoryId, response, reason })
    .done(() => {
      dispatch({ type: 'Transactions.addResponsesCompleted', ids: records.map(r => r.recordId) });
    })
    .fail(() => {
      dispatch({ type: 'Transactions.addResponsesFailed', ids: records.map(r => r.recordId) });
    });
};

export const respond = ({ record, categoryId, response }) => {
  return addResponses({ records: Set([record]), response, categoryId });
};

export const bulkRespond = response => (dispatch, getState) => {
  const { transactions } = getState();
  const records = getSelectedRecords(transactions);
  return addResponses({ records, response })(dispatch, getState);
};

export const suggest = ({ records, categoryId, reason }) => {
  return addResponses({ records, categoryId, reason, response: Response.AGREE });
};

export const bulkSkip = () => (dispatch, getState) => {
  const state = getState();
  const { transactions } = state;
  const unifiedDatasetName = getUnifiedDatasetName(state);
  const transactionIds = getSelectedRecords(transactions).map(t => t.recordId);
  dispatch({ type: 'Transactions.skipFeedback', ids: transactionIds });
  $.ajax({
    url: SERVICES.procure(`/feedback/skip/${unifiedDatasetName}`),
    method: 'POST',
    data: JSON.stringify(transactionIds.toJSON()),
    contentType: 'application/json',
    success: () => {
      dispatch({ type: 'Transactions.skipFeedbackCompleted', ids: transactionIds });
      doFetchFeedbackSummary(dispatch, unifiedDatasetName);
    },
    error: (response) => {
      dispatch({ type: 'Transactions.skipFeedbackFailed', ids: transactionIds });
      dispatch({ type: SHOW, detail: 'Error skipping questions', response });
    },
  });
};

export const categorize = ({ records, categoryId, reason }) => (dispatch, getState) => {
  const state = getState();
  const projectInfo = selectActiveProjectInfo(state);
  const categorizations = records.map(record => ({
    recordId: record.recordId,
    categoryId: categoryId || getPath(record, 'suggestedCategorization', 'categoryId'),
    reason,
  })).filter(cat => _.isNumber(cat.categoryId));
  if (categorizations.isEmpty()) {
    return;
  }

  dispatch({ type: 'Transactions.addCategorizations', ids: records.map(r => r.recordId) });
  $.ajax({
    url: uri(SERVICES.procure('/transactions/categorize')).query({ datasetName: projectInfo.unifiedDatasetName }),
    method: 'POST',
    data: JSON.stringify(categorizations.toJS()),
    contentType: 'application/json',
    success: () => {
      doRespond({ state, dispatch, records, categoryId, response: Response.AGREE, reason })
        .done(() => {
          dispatch({ type: 'Transactions.addCategorizationsCompleted', ids: records.map(r => r.recordId) });
        });
    },
    error: (response) => {
      dispatch({ type: 'Transactions.addCategorizationsFailed', ids: records.map(r => r.recordId) });
      dispatch({ type: SHOW, detail: 'Error setting category', response });
    },
  });
};

export const bulkAccept = () => (dispatch, getState) => {
  const { transactions } = getState();
  const records = getSelectedRecords(transactions);
  return categorize({ records })(dispatch, getState);
};

/**
 * Goes through selected records and accepts top expert suggestions if there is a clear leader.
 * If not, do not accept
 */
export const bulkAcceptExpert = () => (dispatch, getState) => {
  const state = getState();
  const records = getSelectedRecords(state.transactions);
  const projectInfo = selectActiveProjectInfo(state);
  const categorizations = getTopExpertCategorizations(state);

  // if no categorizations were changed, don't bother making an API call
  if (categorizations.isEmpty()) {
    return;
  }

  dispatch({ type: 'Transactions.addCategorizations', ids: records.map(r => r.recordId) });
  $.ajax({
    url: uri(SERVICES.procure('/transactions/categorize')).query({ datasetName: projectInfo.unifiedDatasetName }),
    method: 'POST',
    data: JSON.stringify(categorizations.toJS()),
    contentType: 'application/json',
    success: () => {
      dispatch({ type: 'Transactions.addCategorizationsCompleted', ids: records.map(r => r.recordId) });
    },
    error: (response) => {
      dispatch({ type: 'Transactions.addCategorizationsFailed', ids: records.map(r => r.recordId) });
      dispatch({ type: SHOW, detail: 'Error verifying categories', response });
    },
  });
};

export const deleteCategorizations = (records) => (dispatch, getState) => {
  const state = getState();
  const projectInfo = selectActiveProjectInfo(state);
  const recordIds = records.map(r => r.recordId);

  dispatch({ type: 'Transactions.deleteCategorizations', ids: recordIds });
  $.ajax({
    url: uri(SERVICES.procure('/transactions/categorize')).query({ datasetName: projectInfo.unifiedDatasetName }),
    method: 'DELETE',
    data: JSON.stringify(recordIds.toJSON()),
    contentType: 'application/json',
    success: () => {
      dispatch({ type: 'Transactions.deleteCategorizationsCompleted', ids: recordIds });
    },
    error: (response) => {
      dispatch({ type: 'Transactions.deleteCategorizationsFailed', ids: recordIds });
      dispatch({ type: SHOW, detail: 'Error removing category', response });
    },
  });
};

export const suggestCategorizations = () => (dispatch, getState) => {
  const state = getState();
  const { location: { recipeId }, transactions: { applyFeedbackAndUpdateResults } } = state;
  const operation = applyFeedbackAndUpdateResults
    ? RecipeOperations.CATEGORIZATIONS
    : RecipeOperations.PREDICT_CATEGORIZATIONS;
  dispatch({ type: 'Transactions.suggestCategorizations' });
  runOperation(recipeId, operation).then(() => {
    dispatch({ type: 'Transactions.suggestCategorizationsCompleted' });
  }, response => {
    dispatch({ type: 'Transactions.suggestCategorizationsFailed' });
    dispatch({ type: SHOW, detail: 'Error submitting generate categorizations job', response });
  });
};

export const assignFeedback = (usersToAssign, transactions) => (dispatch, getState) => {
  checkArg({ usersToAssign }, ArgTypes.Immutable.set.of(ArgTypes.string));
  checkArg({ transactions }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(EsRecord)));
  const state = getState();
  const unifiedDatasetName = getUnifiedDatasetName(state);
  const transactionIds = transactions.map(value => value.recordId);
  const feedbackAssignment = usersToAssign.toMap().map(() => transactionIds);
  dispatch({ type: 'Transactions.assign', ids: transactions.map(t => t.recordId) });
  $.ajax({
    url: uri(SERVICES.procure(`/feedback/${unifiedDatasetName}`)),
    method: 'POST',
    cache: false,
    datatype: 'json',
    contentType: 'application/json; charset=utf-8',
    data: JSON.stringify(feedbackAssignment),
    success() {
      dispatch({ type: 'Transactions.assignCompleted', ids: transactions.map(t => t.recordId) });
      doFetchFeedbackSummary(dispatch, unifiedDatasetName);
    },
    error(response) {
      dispatch({ type: 'Transactions.assignFailed', ids: transactions.map(t => t.recordId) });
      dispatch({ type: SHOW,
        detail: 'Error assigning questions',
        response,
      });
    },
  });
};

export const deleteFeedback = (usersToUnassign, transactions) => (dispatch, getState) => {
  checkArg({ usersToUnassign }, ArgTypes.Immutable.set.of(ArgTypes.string));
  checkArg({ transactions }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(EsRecord)));
  const state = getState();
  const unifiedDatasetName = getUnifiedDatasetName(state);
  const feedbackAssignment = usersToUnassign.toMap().map(user => {
    const unassignedTransactions = transactions
      .filter(value => value.feedbackForUser(user)) // guard against redundant unassignments
      .filter(value => value.feedbackForUser(user).assignmentInfo.status === FeedbackStatus.PENDING);
    return unassignedTransactions.map(value => value.recordId);
  }).filter(value => !value.isEmpty());
  // Skip trying to delete if none of the assignments are removable
  if (feedbackAssignment.isEmpty()) {
    return;
  }
  dispatch({ type: 'Transactions.assign', ids: transactions.map(t => t.recordId) });
  $.ajax({
    url: uri(SERVICES.procure(`/feedback/delete/${unifiedDatasetName}`)),
    method: 'POST',
    cache: false,
    datatype: 'json',
    contentType: 'application/json; charset=utf-8',
    data: JSON.stringify(feedbackAssignment),
    success() {
      dispatch({ type: 'Transactions.assignCompleted', ids: transactions.map(t => t.recordId) });
      doFetchFeedbackSummary(dispatch, unifiedDatasetName);
    },
    error(response) {
      dispatch({ type: 'Transactions.assignFailed', ids: transactions.map(t => t.recordId) });
      dispatch({ type: SHOW,
        detail: 'Error removing assignments',
        response,
      });
    },
  });
};

export const fetchCategorizationModel = () => (dispatch, getState) => {
  const state = getState();
  const unifiedDatasetName = getUnifiedDatasetName(state);
  const recipeId = selectActiveProjectInfo(state)?.recipeId;
  dispatch({ type: 'Transactions.checkModel' });
  // Use a HEAD request to check that the model exists and is exportable without actually exporting it
  return fetch(SERVICES.procure(`/classification/exportModel/${unifiedDatasetName}`), { method: 'HEAD' })
    .then(response => {
      if (response.ok) {
        return dispatch({ type: 'Transactions.checkModelCompleted', exists: true, recipeId });
      }
      return dispatch({ type: 'Transactions.checkModelCompleted', exists: false, recipeId });
    }, error => {
      return dispatch({ type: 'Transactions.checkModelFailed', recipeId, detail: error.message });
    });
};
