import { List } from 'immutable';

import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';


/**
 * This class specifies the information about a specific field that makes up a schema.
 *
 * @param name     The name of the field that is displayed in the header row.
 * @param type     The object type of the values that make up this field.
 * @param nullable True if a value that is part of this field can be null, false otherwise.
 */
export class Field extends getModelHelpers({
  name: { type: ArgTypes.string },
  type: { type: ArgTypes.string },
  nullable: { type: ArgTypes.bool },
}, 'Field')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class FieldRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: $TSFixMe) {
    return new Field({
      name: obj.name,
      type: obj.type,
      nullable: obj.nullable,
    });
  }
}

/**
 * This class specifies the schema of some data.
 *
 * @param type           Will always be "record", indicating that the schema represents a record.
 * @param fullySpecified True if the schema is not missing any information/fields, false otherwise.
 * @param fields         A list of specific information about each field that makes up the schema.
 */
export class RecordType extends getModelHelpers({
  type: { type: ArgTypes.string },
  fullySpecified: { type: ArgTypes.bool },
  fields: { type: ArgTypes.Immutable.list.of(Field.argType) },
}, 'RecordType')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class RecordTypeRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: $TSFixMe) {
    return new RecordType({
      type: 'record',
      fullySpecified: obj.fullySpecified,
      fields: List(obj.fields).map(f => Field.fromJSON(f)),
    });
  }
}
