import classNames from 'classnames';
import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import PoliciesLoader from '../accessControl/PoliciesLoader';
import PageHeader from '../chrome/PageHeader';
import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import ConditionalButton from '../components/ConditionalButton';
import PopoverTrigger from '../components/PopoverTrigger';
import SearchBox from '../components/SearchBox';
import TamrIcon from '../components/TamrIcon';
import * as UsersActions from '../constants/UsersActionTypes';
import { AppAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import ChangeActiveStatusDialog from './ChangeActiveStatusDialog';
import GroupsTable from './GroupsTable';
import { setQuery } from './UsersAsync';
import style from './UsersContent.module.scss';
import { getSelectedFullUsers } from './UsersStore';
import UsersTable from './UsersTable';


const mapStateToProps = (state: AppState) => {
  const { users: { selectedTab, groups, selectedRowNumbers, totalUsersMatchingQuery, query } } = state;
  const selectedUsers = getSelectedFullUsers(state);
  const allSelectionActivated = selectedUsers.filter(u => u.user.deactivated).size !== 0;
  const allSelectionDeactivated = selectedUsers.filter(u => !u.user.deactivated && u.username !== 'admin' && u.username !== 'system').size !== 0;
  const onlyAdminSystemSelected = selectedUsers.filter(u => u.username !== 'admin' && u.username !== 'system').size === 0;
  return { selectedTab, groupsCount: groups.size, usersCount: totalUsersMatchingQuery, atLeastOneUserSelected: selectedRowNumbers.size >= 1, query, allSelectionActivated, allSelectionDeactivated, onlyAdminSystemSelected };
};

const mapDispatchToProps = {
  onBulkActivate: (): AppAction => ({ type: UsersActions.SET_CHANGE_ACTIVE_STATUS_ACTIVATE, activate: true }),
  onBulkDeactivate: (): AppAction => ({ type: UsersActions.SET_CHANGE_ACTIVE_STATUS_ACTIVATE, activate: false }),
  onClickAddUser: (): AppAction => ({ type: UsersActions.SET_SHOW_ACCOUNT_MODAL, showAccountModal: true }),
  onClickCreateGroup: (): AppAction => ({ type: UsersActions.START_CREATE_GROUP }),
  onReloadPolicies: (): AppAction => ({ type: 'AccessControl.reloadPolicies' }),
  onSelectTab: (tab: string): AppAction => ({ type: UsersActions.SET_SELECTED_TAB, tab }),
  onSearch: setQuery,
};

type UsersContentProps
  = ReturnType<typeof mapStateToProps>
  & typeof mapDispatchToProps

const UsersContent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(class UsersContent extends React.Component<UsersContentProps> {
  componentDidMount() {
    this.props.onReloadPolicies();
  }

  render() {
    const { selectedTab, usersCount, groupsCount, onSelectTab, onClickAddUser, atLeastOneUserSelected, onSearch, query, onBulkActivate, onBulkDeactivate, allSelectionActivated, allSelectionDeactivated, onlyAdminSystemSelected, onClickCreateGroup } = this.props;
    return (
      <div className={style.contentContainer}>
        <PoliciesLoader />
        <ChangeActiveStatusDialog />
        <PageHeader
          title={<div className="page-header-tabs">
            <div className={classNames('page-header-tab', style.pageHeaderTitle, { active: selectedTab === 'users' })} onClick={() => onSelectTab('users')}>
              Users <span className="page-header-metrics">{usersCount}</span>
            </div>
            <div className={classNames('page-header-tab', style.pageHeaderTitle, { active: selectedTab === 'groups' })} onClick={() => onSelectTab('groups')}>
              Groups <span className="page-header-metrics">{groupsCount}</span>
            </div>
          </div>}
          searchbox={selectedTab === 'users' ? <SearchBox value={query} onSearch={onSearch} /> : null}
        />
        {selectedTab !== 'users'
          ? (
            <ButtonToolbar className={style.accountManagementToolbar}>
              <Button onClick={onClickCreateGroup}>Create new group</Button>
            </ButtonToolbar>
          ) : (
            <ButtonToolbar className={style.accountManagementToolbar}>
              <Button onClick={onClickAddUser}>Create new user</Button>
              <PopoverTrigger
                className={style.activeStatus}
                ref="popover"
                placement="bottom"
                content={(
                  <div>
                    <ConditionalButton
                      className={style.activeStatusOption}
                      buttonType="Link"
                      tooltipPlacement="left"
                      onClick={() => {
                        // @ts-expect-error need to hook this up as a ref
                        this.refs.popover.hide();
                        onBulkDeactivate();
                      }}
                      preconditions={Map<string, boolean>()
                        .set('All selected users are already deactivated', onlyAdminSystemSelected ? true : allSelectionDeactivated)
                        .set('The selected user(s) cannot be deactivated', !onlyAdminSystemSelected)
                      }
                    >
                      Deactivate
                    </ConditionalButton>
                    <ConditionalButton
                      className={style.activeStatusOption}
                      buttonType="Link"
                      tooltipPlacement="left"
                      onClick={() => {
                        // @ts-expect-error need to hook this up as a ref
                        this.refs.popover.hide();
                        onBulkActivate();
                      }}
                      preconditions={Map<string, boolean>()
                        .set('All selected users are already active', allSelectionActivated)
                      }
                    >
                      Activate
                    </ConditionalButton>
                  </div>
                )}
              >
                <ConditionalButton
                  onClick={() => {}}
                  buttonType="Secondary"
                  tooltipPlacement="left"
                  preconditions={Map<string, boolean>().set('Must select at least one user to change the status of', atLeastOneUserSelected)}
                >
                  <span className={style.activeStatusButton}>
                    <span className={style.activeStatusText}>Active status</span>
                    <TamrIcon iconName="arrow-drop-down" size={18} />
                  </span>
                </ConditionalButton>
              </PopoverTrigger>
            </ButtonToolbar>
          )
        }
        {selectedTab === 'users' ? <UsersTable /> : <GroupsTable />}
      </div>
    );
  }
});

export default UsersContent;
