import { is, Record } from 'immutable';
import _ from 'underscore';

import { ArgTypes, checkArg, Checker } from '../utils/ArgValidation';

// this toJSON allows for custom serialization in nested Models
//   in that it asks complex objects if they have a toJSON method
//   and uses that if so.
export const toJSON = (value: any): any => {
  if (!value) {
    return value;
  }
  if (_.isFunction(value.toJSON)) {
    value = value.toJSON();
  }
  if (typeof value !== 'object') {
    return value;
  }
  if (_.isArray(value)) {
    return _.map(value, (v) => toJSON(v));
  }
  return _.mapObject(value, (v) => toJSON(v));
};

export type UntypedFieldMap = {
  [key: string]: JustType<any> | TypeAndDefault<any>
}

// TODO if type and defaultValue disagree with each, LCD T (often, 'any') is returned
export type TypeAndDefault<T> = {
  type: Checker<T>
  defaultValue: T
}
export type JustType<T> = {
  type: Checker<T>
}

export type InferType<V> = V extends TypeAndDefault<infer T> ? T : V extends JustType<infer U> ? U : never;
export type InferReadTypes<V extends UntypedFieldMap> = { [K in keyof V]-?: InferType<V[K]>; };
export type InferDefaultSuppliedFields<V extends UntypedFieldMap> = { [K in keyof V]-?: V[K] extends TypeAndDefault<any> ? K : never }[keyof V]
export type InferDefaultNotSuppliedFields<V extends UntypedFieldMap> = Exclude<keyof V, InferDefaultSuppliedFields<V>>
export type InferUndefinedAcceptableFields<V extends UntypedFieldMap> = { [K in keyof V]-?: undefined extends InferReadTypes<V>[K] ? K : never }[keyof V]
export type InferRequiredConstructorArgTypes<V extends UntypedFieldMap> = Omit<Omit<InferReadTypes<V>, InferDefaultSuppliedFields<V>>, InferUndefinedAcceptableFields<V>>;
export type InferOptionalConstructorArgTypes<V extends UntypedFieldMap> = Partial<Pick<InferReadTypes<V>, InferDefaultSuppliedFields<V> | InferUndefinedAcceptableFields<V>>>;
export type InferConstructorArgTypes<V extends UntypedFieldMap> = InferRequiredConstructorArgTypes<V> & InferOptionalConstructorArgTypes<V>;

export type InferFieldNames<V extends UntypedFieldMap> = keyof V;
export type InferCheckerTypes<V extends UntypedFieldMap> = { [K in keyof V]-?: V[K] extends TypeAndDefault<infer T> ? Checker<T> : V[K] extends JustType<infer U> ? Checker<U> : never };
export type InferDefaultValueTypes<V extends UntypedFieldMap> = { [K in keyof V]-?: V[K] extends TypeAndDefault<infer T> ? T : never };

/**
 * This function returns an ImmutableJS Record.Factory whose fields are derived
 *   from the ArgValidation Checker types provided.
 * The returned value also has helper methods for applying these Checkers, both for
 *   construction and set().
 * The extending subclass must apply these helpers itself - that is to say, this function
 *   cannot go as far as to provide the complete subclass, because extending a class with
 *   dynamically-derived types (this function takes a generic T) is impossible.
 * Please note that this Record.Factory does not provide any of the usual custom Model
 *   instance / static methods. For example, .delete() will still have the undesired behavior
 *   of setting the field to undefined, instead of to its default value.
 */
export function getModelHelpers<T extends UntypedFieldMap>(typesAndDefaults: T, classDisplayName: string) {
  type ReadTypes = InferReadTypes<T>;
  type ConstructorArgTypes = InferConstructorArgTypes<T>;
  type CheckerTypes = InferCheckerTypes<T>;
  type DefaultValueTypes = InferDefaultValueTypes<T>;
  const namesToTypes: CheckerTypes = _.mapObject(typesAndDefaults, typeAndDefault => typeAndDefault.type) as CheckerTypes; // can we remove this cast?
  const namesToDefaults: DefaultValueTypes = _.mapObject(typesAndDefaults, typeAndDefault => (typeAndDefault as TypeAndDefault<any>).defaultValue) as DefaultValueTypes; // can we remove this cast?
  const RecordClass = Record(namesToDefaults as unknown as ReadTypes, classDisplayName);
  const helpers = {
    RecordClass,
    typesAndDefaults,
    checkConstructorArgs(args: ConstructorArgTypes) {
      const namesToValuesWithDefaults = _.mapObject(namesToDefaults, (defaultValue, name: keyof T) => {
        // @ts-expect-error
        if (args && args[name] !== undefined) {
          // @ts-expect-error
          return args[name];
        }
        return defaultValue;
      });
      // run checkArg
      // @ts-expect-error
      _.each(namesToTypes, (type, name) => checkArg({ [name]: namesToValuesWithDefaults[name] }, type));
    },
    checkSetArgs<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      // if value is undefined, super.set() will reset the value to the default value, so check for that
      checkArg({ [name]: value === undefined ? namesToDefaults[name] : value }, namesToTypes[name]);
    },
    checkPartialTypes<K extends keyof ReadTypes>(namesToValues: {[key in K]: ReadTypes[K]}) {
      checkArg({ namesToValues }, ArgTypes.object);
      _.each(namesToValues, (value, name) => checkArg({ [name]: value }, namesToTypes[name]));
    },
  };
  return function<T> (application: (recordClass: typeof helpers) => T) {
    return application(helpers);
  };
}

// SR 12/9/19
//   This is the old, not TypeScript way to generate Immutable.Record subclasses.
//   If used from a TypeScript context, it generates an object that allows reading any
//     unknown properties as the 'any' type!
//   This is obviously horrible, so to avoid people accidentally using this
//     I've marked it as returning a 'never'.
//   Use getModelHelpers() above instead!
export default function ModelGenerator(namesToTypesAndDefaults: UntypedFieldMap, recordName: string): never {
  checkArg({ namesToTypesAndDefaults }, ArgTypes.object.of(ArgTypes.object.withShape({
    type: ArgTypes.func,
    defaultValue: ArgTypes.any,
  })));

  const namesToTypes = _.mapObject(namesToTypesAndDefaults, typeAndDefault => typeAndDefault.type);
  // @ts-expect-error
  const namesToDefaults = _.mapObject(namesToTypesAndDefaults, typeAndDefault => typeAndDefault.defaultValue);

  // create Immutable.Record instance that understands the arg names
  const TypedRecord = Record(namesToDefaults, recordName);

  // subclass the Record instance and wrap normal operations with arg checking
  // currently this does not provide a default implementation of fromJSON, which may be desirable
  class Model extends TypedRecord {
    // checkArg all constructor arguments
    constructor(argNamesToValues?: {[key: string]: any}) {
      checkArg({ argNamesToValues }, ArgTypes.oneOf(ArgTypes.object, ArgTypes.undefined));
      // guard against nothing provided, which is valid
      const namesToValuesUnresolved = argNamesToValues || {};
      // should be able to pass in Immutable Map / Record (and subclasses)
      const namesToValues = _.isFunction(namesToValuesUnresolved.toObject)
        ? namesToValuesUnresolved.toObject()
        : namesToValuesUnresolved;
      // mix in defaults for unprovided fields
      const namesToValuesWithDefaults = _.mapObject(namesToDefaults, (defaultValue, name) => {
        if (namesToValues.hasOwnProperty(name)) {
          return namesToValues[name];
        }
        return defaultValue;
      });
      // run checkArg
      _.each(namesToTypes, (type, name) => checkArg({ [name]: namesToValuesWithDefaults[name] }, type));

      // call superclass constructor
      super(argNamesToValues);
    }

    // checkArg and then perform set
    // all setting functions delegate to this at some point, so this will catch everything
    set(name: string | number, value: any) {
      // if value is undefined, super.set() will reset the value to the default value, so check for that
      checkArg({ name }, ArgTypes.valueIn(_.keys(namesToTypes)));
      if (namesToTypes.hasOwnProperty(name)) {
        checkArg({ [name]: value === undefined ? namesToDefaults[name] : value }, namesToTypes[name]);
      }
      return super.set(name, value);
    }

    // contains was removed from Record in Immutable 4
    // so lets bring it back
    contains(value: any) {
      return super.toSeq().some(v => is(v, value));
    }

    reduce(reduceFunction: Function, accumulator: any) {
      Array.from(super.entries()).forEach(entry => {
        // @ts-expect-error
        accumulator = reduceFunction(accumulator, entry[1], entry[0]);
      });
      return accumulator;
    }

    // checks supplied arg names and values against types this model was created with
    static checkTypes(namesToValues: {[key: string]: any}) {
      checkArg({ namesToValues }, ArgTypes.object);
      _.each(namesToValues, (value, name) => checkArg({ [name]: value }, namesToTypes[name]));
    }
    checkTypes(namesToValues: {[key: string]: any}) {
      return Model.checkTypes(namesToValues);
    }

    static objectArgTypeWithFields(...fieldNames: string[]) {
      const typeDefinition = fieldNames.reduce(
        (memo: {[key: string]: any}, fieldName) => { memo[fieldName] = namesToTypes[fieldName]; return memo; },
        {},
      );
      return ArgTypes.object.withShape(typeDefinition);
    }
    objectArgTypeWithFields(...fieldNames: string[]) {
      return Model.objectArgTypeWithFields(...fieldNames);
    }

    static get argType() {
      return ArgTypes.instanceOf(this);
    }

    toJSON() {
      // this allows Models to allows nested custom serializations by default
      return toJSON(super.toJSON());
    }
  }
  return Model as never;
}
