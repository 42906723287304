import classNames from 'classnames';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import BulletedTooltipTrigger from '../components/BulletedTooltipTrigger';
import style from './SchemaMappingListHeader.module.scss';
import { getNoSourceAttributesInSystem } from './SchemaMappingStore';
import SourceBulkExpandTrigger from './SourceBulkExpandTrigger';
import SourceListStats from './SourceListStats';

const SourceListHeader = connect((state) => {
  return { noAttributes: getNoSourceAttributesInSystem(state) };
})(class SourceListHeader extends React.Component {
  static propTypes = {
    canUserEdit: PropTypes.bool.isRequired,
    noAttributes: PropTypes.bool.isRequired,
  };

  render() {
    const { noAttributes, canUserEdit } = this.props;
    return (
      <div>
        {noAttributes ?
          <BulletedTooltipTrigger items={List(['You have no source attributes'])}>
            <div className={style.shroud} />
          </BulletedTooltipTrigger>
          : null}
        <div className={style.controlsContainer} />
        <div className={style.statsContainer}>
          <SourceListStats canUserEdit={canUserEdit} />
          <div className={classNames(style.controls, style.source)}>
            <SourceBulkExpandTrigger />
          </div>
        </div>
      </div>
    );
  }
});

export default SourceListHeader;
