import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { AutoSizer } from 'react-virtualized';

import EsRecord from '../models/EsRecord';
import ColumnWidthProvider from './ColumnWidthProvider';
import MultiValue, { recordValueShim } from './MultiValue';
import style from './SimpleTxnTable.module.scss';
import Cell from './Table/Cell';
import Column from './Table/Column';
import Table from './Table/Table';
import TamrIcon from './TamrIcon';
import TooltipTrigger from './TooltipTrigger';


const propTypes = {
  columns: ImmutablePropTypes.listOf(PropTypes.string.isRequired).isRequired,
  height: PropTypes.number,
  mlAttributes: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
  rows: ImmutablePropTypes.listOf(PropTypes.instanceOf(EsRecord).isRequired).isRequired,
};

type SimpleTxnTableProps = InferProps<typeof propTypes>;

const SimpleTxnTable: React.FC<SimpleTxnTableProps> = ({ columns, height, mlAttributes, rows }) => {
  return (
    <div className="simple-transaction-table-container">
      <AutoSizer>
        {({ width }) => (
          <ColumnWidthProvider>
            <Table
              {...{ width, height }}
              getLength={() => rows.size}
              tableType="stripes"
            >
              {columns.map(col => {
                const cell = ({ rowIndex }: { rowIndex: number }) => {
                  const value = rows.get(rowIndex)?.getValue(col);
                  return <Cell><MultiValue values={recordValueShim({ value: value?.data })} /></Cell>;
                };
                const isMlAttribute = mlAttributes.contains(col);
                return (
                  <Column
                    columnKey={col}
                    width={200}
                    key={col}
                    header={(
                      <Cell>
                        <span className={style.dataColumnHeader}>
                          {isMlAttribute ? (
                            <TooltipTrigger placement="top" content="This attribute is included in machine learning">
                              <TamrIcon size={12} iconName="tamr-icon-ml-on" />
                            </TooltipTrigger>
                          ) : null}
                          {col}
                        </span>
                      </Cell>
                    )}
                    cell={cell}
                    isResizable
                  />
                );
              }).toArray()}
            </Table>
          </ColumnWidthProvider>
        )}
      </AutoSizer>
    </div>
  );
};

SimpleTxnTable.propTypes = propTypes;

export default SimpleTxnTable;
