import React from 'react';
import { connect } from 'react-redux';

import TooltipTrigger, { PlacementOption } from '../components/TooltipTrigger';
import RecordPairWithData from '../models/RecordPairWithData';
import { AppState } from '../stores/MainStore';
import { getAuthorizedUser, selectActiveProjectInfo } from '../utils/Selectors';
import { getTime } from '../utils/Time';
import { $TSFixMe } from '../utils/typescript';
import VerifiedIcon from './VerifiedIcon';


type VerifiedPairIconOwnProps = {
  pair: RecordPairWithData,
  size?: number,
  tooltipClassName?: string,
  tooltipPlacement?: PlacementOption,
  iconProps?: $TSFixMe
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VerifiedPairIcon = connect((state: AppState, ownProps: VerifiedPairIconOwnProps) => {
  const projectInfo = selectActiveProjectInfo(state);
  const projectId = projectInfo?.projectId;
  const { users: { users } } = state;
  const authUser = getAuthorizedUser(state);
  const loggedInUsername = authUser?.username;
  return { projectId, users, loggedInUsername };
})((props) => {
  const { tooltipClassName, tooltipPlacement, pair, projectId, users, loggedInUsername, iconProps } = props;
  const verifiedResponse = projectId && pair.getVerifiedResponse(projectId, users) as $TSFixMe;

  let tooltipContent;
  if (verifiedResponse) {
    const { username, response: { lastModified } } = verifiedResponse;
    const date = getTime(lastModified * 1000).fromNow();
    tooltipContent = `Verified by ${username === loggedInUsername ? 'you' : username} ${date}`;
  } else {
    tooltipContent = 'Verified via API';
  }

  return (
    <TooltipTrigger
      className={tooltipClassName}
      placement={tooltipPlacement || 'top'}
      content={tooltipContent}
    >
      <VerifiedIcon {...iconProps} />
    </TooltipTrigger>
  );
});

export default VerifiedPairIcon;
