import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import MinimalAuthUser from '../models/MinimalAuthUser';
import AppState from '../stores/AppState';
import { getAuthorizedUser } from '../utils/Selectors';
import Highlighter from './Highlighter';
import style from './UserName.module.scss';

const propTypes = {
  className: PropTypes.string,
  highlight: PropTypes.string,
  username: PropTypes.string.isRequired,
};

type UserNameOwnProps = InferProps<typeof propTypes>;

const mapStateToProps = (state: AppState) => {
  const { users: { users } } = state;
  return { authorizedUser: getAuthorizedUser(state), users };
};

type UserNameProps = ReturnType<typeof mapStateToProps> & UserNameOwnProps;

/**
 * Component used to render a user's (potentially) full name
 */
const UserName = connect(
  mapStateToProps,
)(/**
 * Component used to render a user's (potentially) full name
 */
  class UserName extends React.Component<UserNameProps> {
    static propTypes = propTypes;

    render() {
      const { authorizedUser, className, users, username, highlight } = this.props;
      const user = users.find((u: MinimalAuthUser) => u.username === username);
      let userDisplay;
      let isYouIndicator;
      if (!user) {
        userDisplay = username;
      } else {
        if (user.user.given && user.user.surname) {
          userDisplay = `${user.user.given} ${user.user.surname}`;
        } else {
          userDisplay = username;
        }
        if (user.username === authorizedUser?.username) {
          isYouIndicator = <span className={style.isYouIndicator}> (You)</span>;
        }
      }

      return (
        <span className={classNames(className)} title={username}>
          <span className={style.userContent}>
            <Highlighter
              fullText={userDisplay}
              highlightText={highlight}
          />
          </span>
          {isYouIndicator}
        </span>
      );
    }
  });

export default UserName;
