import React from 'react';

import Highlighter from '../components/Highlighter';
import { getPerc } from '../utils/Numbers';

const labelCountTaxonomyCell = (category, tier, taxonomy, searchString, tierLabelMaxCounts) => {
  const categoryName = (
    <Highlighter
      fullText={category.name}
      highlightText={searchString}
    />
  );
  if (!tierLabelMaxCounts) {
    return categoryName;
  }

  const manualCount = category.manualCategorizations.count;
  const manualRatio =
    tierLabelMaxCounts.get(tier) ? getPerc(manualCount, tierLabelMaxCounts.get(tier)) : 0;
  const suggestedCount = category.totalAggForSuggestedCategorizations.count;
  const suggestedRatio =
    tierLabelMaxCounts.get(tier) ? getPerc(suggestedCount, tierLabelMaxCounts.get(tier)) : 0;

  return (
    <div className="category-labeled-count">
      <span className="category-name">{categoryName}</span>
      <span className="category-label-progress">
        <span className="label-count manual" style={{ width: `${manualRatio}%` }} />
        <span className="label-count suggested" style={{ width: `${suggestedRatio}%` }} />
      </span>
    </div>
  );
};

export default labelCountTaxonomyCell;
