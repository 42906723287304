import _ from 'underscore';

import { ArgTypes } from '../../utils/ArgValidation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../Model';

class Checkpoint extends getModelHelpers({
  username: { type: ArgTypes.string },
  timestamp: { type: ArgTypes.number },
  version: { type: ArgTypes.number },
}, 'Checkpoint')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class CheckpointRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: any) { // kept for backwards compatibility
    if (_.isFunction(obj.toJSON)) {
      return new Checkpoint(obj.toJSON());
    }
    return new Checkpoint(obj);
  }
  asQueryParams() {
    return {
      version: this.version,
    };
  }
}

export default Checkpoint;
