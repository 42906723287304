import classNames from 'classnames';
import React from 'react';
import { pure } from 'recompose';
import _ from 'underscore';

import CategorizationDashboardAssignmentsCard from './CategorizationDashboardAssignmentsCard';
import CategorizationDashboardCategoriesCard from './CategorizationDashboardCategoriesCard';
import CategorizationDashboardCuratorAssignmentCard from './CategorizationDashboardCuratorAssignmentCard';
import style from './CategorizationDashboardCuratorCards.module.scss';
import CategorizationDashboardPerformanceCard from './CategorizationDashboardPerformanceCard';
import CategorizationDashboardStatsLoader from './CategorizationDashboardStatsLoader';

const CategorizationDashboardCuratorCards = _.compose(
  pure,
)(() => {
  return (
    <div className={style.container}>
      <CategorizationDashboardStatsLoader />
      <div className={classNames(style.cardRow, style.topRow)}>
        <div className={classNames(style.card, style.scroll)}>
          <CategorizationDashboardAssignmentsCard />
        </div>
        <div className={classNames(style.card, style.scroll)}>
          <CategorizationDashboardPerformanceCard />
        </div>
      </div>
      <div className={style.cardRow}>
        <div className={style.card}>
          <CategorizationDashboardCuratorAssignmentCard />
        </div>
        <div className={style.card}>
          <CategorizationDashboardCategoriesCard />
        </div>
      </div>
    </div>
  );
});

export default CategorizationDashboardCuratorCards;
