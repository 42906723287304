import { RecordPairFeedbackResponseTypeE } from '../pairs/RecordPairFeedbackResponseType';
import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';
import RecordPairFeedbackAssignmentInfo from './RecordPairFeedbackAssignmentInfo';
import RecordPairFeedbackResponse from './RecordPairFeedbackResponse';

/**
 * Equivalent to RecordPair.java#Feedback, which is RecordPairFeedback without id information,
 * since this is expected to be nested within a RecordPair object that carries that id info
 */
class RecordPairInnerFeedback extends getModelHelpers({
  username: { type: ArgTypes.string },
  assignmentInfo: { type: ArgTypes.nullable(ArgTypes.instanceOf(RecordPairFeedbackAssignmentInfo)) },
  response: { type: ArgTypes.nullable(ArgTypes.instanceOf(RecordPairFeedbackResponse)) },
}, 'RecordPairInnerFeedback')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class RecordPairInnerFeedbackRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  static fromJSON(obj: $TSFixMe) {
    const { username } = obj;
    const assignmentInfo = obj.assignmentInfo ? new RecordPairFeedbackAssignmentInfo(obj.assignmentInfo) : undefined;
    const response = obj.response ? new RecordPairFeedbackResponse(obj.response) : undefined;
    return new RecordPairInnerFeedback({
      username,
      assignmentInfo,
      response,
    });
  }

  // accounts for fact that response can be MATCH or NON_MATCH in 'response' field, or SKIP in assignmentInfo.status
  get responseKey(): RecordPairFeedbackResponseTypeE | undefined {
    switch (true) {
      case this.getIn(['response', 'label']) === 'MATCH':
        return 'MATCH';
      case this.getIn(['response', 'label']) === 'NON_MATCH':
        return 'NON_MATCH';
      case this.getIn(['assignmentInfo', 'status']) === 'SKIP':
        return 'SKIP';
    }
  }
  get hasResponseKey() {
    return !!this.responseKey;
  }

  get responseTimestamp() {
    if (this.response) {
      return this.response.lastModified;
    }
    if (this.assignmentInfo && this.assignmentInfo.status === 'SKIP') {
      return this.assignmentInfo.lastModified;
    }
  }
}

export default RecordPairInnerFeedback;
