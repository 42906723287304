import { List } from 'immutable';

import createLoader from '../utils/createLoader';
import { fetchAdjacentGeospatialTransactions } from './SuppliersAsync';

// Fetch when the current bounds of the geospatial changes
const AdjacentGeospatialTxnLoader = createLoader((state) => {
  const {
    suppliers: {
      geospatial: {
        showGeospatialOverlay,
        activeGeospatialRenderingAttribute,
        loadingAdjacentRows,
        loadingBounds,
        loadingRows,
        currentBounds,
        loadedAdjacentBounds,
      },
    },
  } = state;

  return {
    canFetch: !!activeGeospatialRenderingAttribute && showGeospatialOverlay && !(loadingBounds || loadingRows),
    shouldFetch: !List(currentBounds).flatten().equals(List(loadedAdjacentBounds).flatten()),
    loading: loadingAdjacentRows,
  };
}, {
  onFetch: fetchAdjacentGeospatialTransactions,
}, 'AdjacentGeospatialTxnLoader');

export default AdjacentGeospatialTxnLoader;
