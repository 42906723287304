import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import FeedbackAssignDialog from '../feedback/FeedbackAssignDialog';
import RecordPairWithData from '../models/RecordPairWithData';
import { updateFeedbackAssignments } from './RecordPairsAsync';

const RecordPairFeedbackAssignDialog = _.compose(
  connect(state => {
    const { recordPairs: { pairs, assigningFeedbackForRows } } = state;
    return { assigningFeedbackForPairs: assigningFeedbackForRows.map(r => pairs.get(r)) };
  }, {
    onHideFeedbackAssignDialog: () => ({ type: 'RecordPairs.stopAssigningFeedback' }),
    onUpdateAssignments: updateFeedbackAssignments,
  }),
)(class RecordPairFeedbackAssignDialog extends React.Component {
  static propTypes = {
    assigningFeedbackForPairs: ImmutablePropTypes.setOf(PropTypes.instanceOf(RecordPairWithData)).isRequired,
    onHideFeedbackAssignDialog: PropTypes.func.isRequired,
    onUpdateAssignments: PropTypes.func.isRequired,
  };

  render() {
    const { onUpdateAssignments, onHideFeedbackAssignDialog, assigningFeedbackForPairs } = this.props;
    return (
      <FeedbackAssignDialog
        noun="pair"
        showDialog={!assigningFeedbackForPairs.isEmpty()}
        onAssign={onUpdateAssignments}
        onHide={onHideFeedbackAssignDialog}
        userAssignmentCount={(username) => assigningFeedbackForPairs.count(p => p.assignedToUser(username))}
        recordCount={assigningFeedbackForPairs.size}
      />
    );
  }
});

export default RecordPairFeedbackAssignDialog;
