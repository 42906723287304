import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Button from '../components/Button';
import DatasetFilterDialog from '../datasetFilter/DatasetFilterDialog';
import filterStyle from './FilterSection.module.scss';

export default class SelectSourcesButton extends React.Component {
  static propTypes = {
    dialogVisible: PropTypes.bool.isRequired,
    modifyQuery: PropTypes.func.isRequired,
    onHideDialog: PropTypes.func.isRequired,
    onOpenDialog: PropTypes.func.isRequired,
    onSetDialogVisible: PropTypes.func.isRequired,
    onSubmitDialog: PropTypes.func.isRequired,
    selectedDatasets: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
  };

  handleDialogOpen() {
    this.props.onOpenDialog();
    this.props.onSetDialogVisible(true);
  }

  handleDialogHide() {
    this.props.onHideDialog();
    this.props.onSetDialogVisible(false);
  }

  handleDialogSubmit(datasetsToAdd, datasetsToRemove) {
    this.props.onHideDialog();
    this.props.onSubmitDialog(datasetsToAdd.map(d => d.data.name), datasetsToRemove.map(d => d.data.name));
  }

  render() {
    return (
      <div>
        <Button className={filterStyle.selectSources} buttonType="Link" onClick={() => this.handleDialogOpen()}>Select sources</Button>
        <DatasetFilterDialog
          show={this.props.dialogVisible}
          onHide={() => this.handleDialogHide()}
          onSubmit={(datasetsToAdd, datasetsToRemove) => this.handleDialogSubmit(datasetsToAdd, datasetsToRemove)}
          getSelectedDatasets={datasets => datasets.filter(d => this.props.selectedDatasets.has(d.data.name)).map(d => d.data.name).toSet()}
          modifyQuery={this.props.modifyQuery}
        />
      </div>
    );
  }
}
