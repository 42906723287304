import classNames from 'classnames';
import { List, Map } from 'immutable';
import React from 'react';

import Button from '../components/Button';
import ConditionalButton from '../components/ConditionalButton';
import Selector from '../components/Input/Selector';
import TooltipTrigger from '../components/TooltipTrigger';
import { Roles } from '../models/AuthUser';
import style from './PoliciesList.module.scss';


export type PolicyConfigTableRenderRowArgs = {
  id: string
  index: number
  getGroupsById?: (value: string) => List<string>
  getRoleById?: (value: string) => string | null | undefined
  disabled?: boolean
}

export const PolicyConfigTableRow: React.FC<{
  className?: string
  groups?: List<string>
  inPolicy: boolean
  name?: string
  onAddToPolicy: () => void
  onChangeRoleInPolicy?: (newRole: string) => void
  onRemoveFromPolicy?: () => void
  role?: string
  subName?: string
  disabled?: boolean
}> = ({
  className,
  disabled,
  groups,
  inPolicy,
  name,
  subName,
  role,
  onAddToPolicy,
  onRemoveFromPolicy,
  onChangeRoleInPolicy,
}) => (
  <div className={classNames(style.row, className, { [style.inPolicy]: inPolicy, [style.disabled]: disabled })}>
    <div className={classNames(style.cell, style.nameColumn)}>
      <div className={style.policyName} title={name}>{name}</div>
      {subName && <div className={style.policySubName} title={subName}>{subName}</div>}
    </div>
    {!groups
      ? null
      : (
        <div className={classNames(style.cell, style.groupsColumn)} title={groups.join(', ')}>
          {groups.size === 0 ? 'No groups' : (
            <span className={style.groupNamesContainer}>
              <span className={style.total}>({groups.size}) </span>
              <span className={style.groupNames}>{groups.join(', ')}</span>
            </span>
          )}
        </div>
      )
    }
    <div className={classNames(style.cell, style.roleColumn, { [style.hasGroups]: !!groups, [style.notInPolicy]: !inPolicy, [style.noRole]: !role })}>
      {!inPolicy
        ? <ConditionalButton
            className={style.addToPolicyButton}
            buttonType="Link"
            tooltipPlacement="top"
            preconditions={Map<string, boolean>().set('Cannot add to policy that applies to all.', !disabled)}
            onClick={onAddToPolicy}>+ add to policy
        </ConditionalButton>
        : (role && onChangeRoleInPolicy)
          ? (
            <TooltipTrigger placement="top" content={disabled ? 'Cannot remove from policy that applies to all.' : null}>
              <div className={style.roleConfig}>
                <Selector<string>
                  className={style.roleSelector}
                  onChange={onChangeRoleInPolicy}
                  disabled={disabled}
                  value={role}
                  values={[
                    { value: Roles.AUTHOR, display: 'Author' },
                    { value: Roles.CURATOR, display: 'Curator' },
                    { value: Roles.REVIEWER, display: 'Reviewer' },
                    { value: Roles.VERIFIER, display: 'Verifier' },
                  ]}
                />
                <Button
                  className={style.removeFromPolicyButton}
                  buttonType="Link"
                  disabled={disabled}
                  icon="close"
                  iconSize={14}
                  iconClassName={style.removeFromPolicyIcon}
                  onClick={onRemoveFromPolicy}
                />
              </div>
            </TooltipTrigger>
          ) : (
            <ConditionalButton
              className={style.removeFromPolicyButton}
              buttonType="Link"
              disabled={disabled}
              preconditions={Map<string, boolean>().set('Cannot remove from policy that applies to all.', !disabled)}
              tooltipPlacement="top"
              icon="close"
              iconSize={14}
              iconClassName={style.removeFromPolicyIcon}
              onClick={onRemoveFromPolicy}
            />
          )
      }
    </div>
  </div>
);

const PolicyConfigTable: React.FC<{
  getGroupsById?: (value: string) => List<string>
  getRoleById?: (value: string) => string | null | undefined
  disabled?: boolean
  idsActuallyInPolicy: List<string>
  idsNotActuallyInPolicy: List<string>
  renderRow: (args: PolicyConfigTableRenderRowArgs) => JSX.Element
}> = ({
  disabled,
  idsActuallyInPolicy,
  idsNotActuallyInPolicy,
  getGroupsById,
  getRoleById,
  renderRow,
}) => (
  <div className={classNames(style.policyConfigTable, { [style.disabled]: disabled })}>
    <div className={style.header}>
      <div className={classNames(style.cell, style.headerCell, style.nameColumn)}>Name</div>
      {getGroupsById ? <div className={classNames(style.cell, style.headerCell, style.groupsColumn)}>Groups</div> : null}
      <div className={classNames(style.cell, style.headerCell, style.roleColumn)}>{getRoleById ? 'Role' : null}</div>
    </div>
    <div className={style.body}>
      {idsActuallyInPolicy
        .concat(idsNotActuallyInPolicy)
        .map((id, index) => renderRow({ id, index, disabled, getGroupsById, getRoleById }))
      }
    </div>
  </div>
);

export default PolicyConfigTable;
