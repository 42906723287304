import $ from 'jquery';

export const loadRunningJobs = () => (dispatch) => {
  dispatch({ type: 'Chrome.loadRunningJobs' });
  return $.ajax({
    url: SERVICES.dataset('/tasks/jobs'),
    dataType: 'json',
    data: { active: true },
    cache: false,
    contentType: 'application/json',
    success: (jobs) => {
      dispatch({ type: 'Chrome.loadRunningJobsCompleted', jobs });
    },
    error: () => {
      throw Error('Failed to query for running jobs');
    },
  });
};
