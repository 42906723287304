import './PageHeader.scss';

import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';


const propTypes = {
  breadcrumb: PropTypes.element,
  children: PropTypes.node,
  className: PropTypes.string,
  searchbox: PropTypes.node,
  subtitle: PropTypes.node,
  title: PropTypes.node.isRequired,
};

type PageHeaderProps = InferProps<typeof propTypes>;


/**
 * Header used on various pages in the Procurement application
 */
class PageHeader extends React.PureComponent<PageHeaderProps> {
  static propTypes = propTypes;

  render() {
    const { breadcrumb, className, searchbox, title, subtitle, children } = this.props;
    return (
      <div className={classNames('page-header', className)}>
        <div className="title-text-section">
          {breadcrumb}
          <div className="page-header-title">{title}</div>
          {subtitle}
        </div>
        {searchbox}
        {children}
      </div>
    );
  }
}

export default PageHeader;
