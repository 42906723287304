import { is } from 'immutable';

import createLoader from '../utils/createLoader';
import { fetchAll } from './UnifiedDatasetsApi';
import { getFilterInfo } from './UnifiedDatasetsStore';

const UnifiedDatasetsLoader = createLoader((state) => {
  const { unifiedDatasets: { loading, loadedFilterInfo, initialFetch } } = state;
  return {
    canFetch: true,
    shouldFetch: !initialFetch || !is(getFilterInfo(state), loadedFilterInfo),
    loading,
  };
}, {
  onFetch: fetchAll,
}, 'UnifiedDatasetsLoader');

export default UnifiedDatasetsLoader;
