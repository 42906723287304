import moment from 'moment';

import { ArgTypes } from '../utils/ArgValidation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

class CountsOverTime extends getModelHelpers({
  time: { type: ArgTypes.timestamp },
  count: { type: ArgTypes.number },
}, 'CountsOverTime')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class CountsOverTimeRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static withStringDate(obj: { time: string, count: number }) {
    const { time, count } = obj;
    return new CountsOverTime({
      count,
      time: moment(time).valueOf(),
    });
  }
}

export default CountsOverTime;
