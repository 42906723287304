import { List, Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import { isNumber, isString } from 'underscore';

import Button from '../components/Button';
import ColumnOrderSelector from '../components/ColumnOrderSelector';
import ColumnWidthProvider from '../components/ColumnWidthProvider';
import LoadingPanel from '../components/LoadingPanel';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Table from '../components/Table/Table';
import TamrIcon from '../components/TamrIcon';
import * as DataTables from '../constants/DataTables';
import { CLUSTER_ID, PERSISTENT_ID } from '../constants/PipelineConstants';
import TypedData, { ArrayRenderingOption, StringRenderingOption, WhitespaceRenderingOption } from '../dataRendering/TypedData';
import DisplayColumn from '../models/DisplayColumn';
import { AppAction } from '../stores/AppAction';
import { AppState } from '../stores/MainStore';
import { typedValueConstructor } from '../transforms/models/TypedValue';
import { RecordType } from '../transforms/models/Types';
import { getLastSuccessfulData } from '../utils/DescribedAsyncStatus';
import { longFormat } from '../utils/Numbers';
import ClusterSampleDivider from './ClusterSampleDivider';
import * as ClusterSampleState from './ClusterSampleState';
import * as grPageActions from './GoldenRecordsActionTypes';
import { profileInputDataset } from './GoldenRecordsAsync';
import styles from './GoldenRecordsClusterSampleTable.module.scss';
import { selectClusterSampleTableColumns, setClusterSampleColumnPreferences, setClusterSampleColumnWidth } from './GoldenRecordsColumns';
import * as rulesPageActions from './GoldenRecordsRulesActionTypes';
import { pagerWithoutPages } from './GoldenRecordsRulesTable';
import { getAnyProfilingJobIsRunningOrSubmitting, selectClusterSampleTableVerticalProportion, selectInputDedupRecipeId } from './GoldenRecordsSelectors';
import { selectIsClusterProfileJobKnownToBeOutOfDate } from './GoldenRecordsStore';
import { InputDedupProjectLink } from './GoldenRecordTableComponents';


interface ClusterSampleTableProps {
  clusterSampleState: ClusterSampleState.ClusterSampleState
  clusterSampleTableVerticalProportion: number
  inputDedupRecipeId: number
  profilingIsRunning: boolean
  profilingIsOutOfDate: boolean
  columnSettings: List<any>
  onToggleShowClusterSampleTable: () => any
  onProfile: () => any
  onSetColumnPreferences: (columnPrefs: List<DisplayColumn>) => any
  onSetColumnWidth: (newWidth: number, col: string) => any

}

const ClusterSampleTable: React.FC<ClusterSampleTableProps> = ({
  clusterSampleState,
  clusterSampleTableVerticalProportion,
  inputDedupRecipeId,
  profilingIsRunning,
  profilingIsOutOfDate,
  columnSettings,
  onToggleShowClusterSampleTable,
  onProfile,
  onSetColumnPreferences,
  onSetColumnWidth,
}) => {
  const { showClusterSampleTable, table, clusterInformation: loadedClusterInformation } = clusterSampleState;
  if (!showClusterSampleTable) {
    return null;
  }
  const typedTable = getLastSuccessfulData(table);
  const data = typedTable?.data || List<Map<string, any>>();
  const type = typedTable?.type || RecordType.fullySpecified();
  const sampleSize = data.size;
  const clusterId = ClusterSampleState.getLastFetchedResourceDescription(clusterSampleState)?.clusterId;
  const clusterSampleClusterName = loadedClusterInformation?.clusterName;
  const clusterSampleClusterSize = loadedClusterInformation?.clusterSize;
  const numRecordsMsg = `${longFormat(sampleSize)} of ${longFormat(clusterSampleClusterSize as number)} records`;
  const loading = table.type === 'loading';
  const cannotDisplayTable = profilingIsRunning || profilingIsOutOfDate;


  const pagerProps = pagerWithoutPages(
    <ColumnOrderSelector
      columnSettings={columnSettings}
      onSetColumnPreferences={(page, columnPrefs) => onSetColumnPreferences(columnPrefs)}
      pageType={DataTables.GOLDEN_RECORDS_CLUSTER_SAMPLE}
      numPinnedColumns={0}
    />,
  );

  return (
    <>
      <ClusterSampleDivider />
      <div
        className={styles.clusterSampleTableSection}
        style={{ height: `${clusterSampleTableVerticalProportion * 100}%` }}
      >
        {loading ? (
          <LoadingPanel message="Fetching cluster sample" />
        ) : null}
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <div className={styles.clusterName}>
              {clusterSampleClusterName}
              {isNumber(inputDedupRecipeId) && isString(clusterId) && (
                <InputDedupProjectLink
                  {...{ inputDedupRecipeId, clusterId }}
                  className={styles.inputDedupClusterLink}
                >
                  <TamrIcon iconName="open-in-new" size={14} />
                </InputDedupProjectLink>
              )}
            </div>
            {!cannotDisplayTable && (
              <div className={styles.headerSampleCount}>
                {numRecordsMsg}
              </div>
            )}
          </div>
          <Button buttonType="Secondary" className={styles.closeLink} onClick={onToggleShowClusterSampleTable}>
            Close Sample
          </Button>
        </div>
        <div className={styles.tableContainer}>
          {profilingIsRunning ? (
            <span className={styles.outOfDateWarning}>
              This dataset is currently being profiled, so we cannot display a sample of the underlying cluster
            </span>
          ) : profilingIsOutOfDate ? (
            <span className={styles.outOfDateWarning}>
              Profiling for this dataset is out of date. <a onClick={onProfile}>Click here</a> to profile.
            </span>
          ) : (
            <AutoSizer>
              {({ width, height }) => (
                <ColumnWidthProvider>
                  <Table
                    className={styles.table}
                    tableType="stripes"
                    getLength={() => (data.size)}
                    {... { height, width }}
                    rowHeight={56}
                    pagerHeight={48}
                    onColumnResizeEndCallback={(newWidth, columnKey) => onSetColumnWidth(newWidth, columnKey)}
                    {...pagerProps}
                  >
                    {columnSettings.filter(x => x.visible !== false).map(field => {
                      const name = field.name === PERSISTENT_ID ? CLUSTER_ID : field.name;
                      return (
                        <Column
                          key={name}
                          columnKey={name}
                          isResizable
                          width={field.width || 120}
                          header={<Cell>{name}</Cell>}
                          cell={({ rowIndex }) => {
                            const record = data.get(rowIndex);
                            return (
                              <Cell>
                                {record && (
                                  <TypedData
                                  // @ts-ignore
                                    typedValue={typedValueConstructor(record.get(field.name), type.fields.find(x => x.name === field.name).type)}
                                    strings={StringRenderingOption.ONLY_SHOW_VALUE}
                                    whitespace={WhitespaceRenderingOption.DO_NOT_ADD_SPACE}
                                    arrays={ArrayRenderingOption.SHOW_BRACKETS_AND_POPOVER_WHEN_MULTIPLE_ELEMENTS}
                                    highlighting={false}
                                  />
                                )}
                              </Cell>
                            );
                          }}
                        />
                      );
                    })}
                  </Table>
                </ColumnWidthProvider>
              )}
            </AutoSizer>
          )}
        </div>
      </div>
    </>
  );
};

const sharedMapStateToProps = (state: AppState) => {
  const clusterSampleTableVerticalProportion = selectClusterSampleTableVerticalProportion(state);
  const inputDedupRecipeId = selectInputDedupRecipeId(state);
  const profilingIsRunning = getAnyProfilingJobIsRunningOrSubmitting(state);
  const profilingIsOutOfDate = selectIsClusterProfileJobKnownToBeOutOfDate(state.goldenRecords);

  const columnSettings = selectClusterSampleTableColumns(state);

  return {
    clusterSampleTableVerticalProportion,
    inputDedupRecipeId,
    profilingIsRunning,
    profilingIsOutOfDate,
    columnSettings,
  };
};

const sharedMapDispatchToProps = {
  onProfile: profileInputDataset,
  onSetColumnPreferences: setClusterSampleColumnPreferences,
  onSetColumnWidth: setClusterSampleColumnWidth,
};

const rulesPageMapStateToProps = (state: AppState) => {
  const { goldenRecordsRules: { clusterSampleState } } = state;
  return { clusterSampleState, ...sharedMapStateToProps(state) };
};

const rulesPageMapDispatchToProps = {
  ...sharedMapDispatchToProps,
  onToggleShowClusterSampleTable: (): AppAction => ({ type: rulesPageActions.TOGGLE_SHOW_CLUSTER_SAMPLE_TABLE }),
};

export const RulesPageClusterSampleTable = connect(rulesPageMapStateToProps, rulesPageMapDispatchToProps)(ClusterSampleTable);

const grPageMapStateToProps = (state: AppState) => {
  const { goldenRecords: { clusterSampleState } } = state;
  return { clusterSampleState, ...sharedMapStateToProps(state) };
};

const grPageMapDispatchToProps = {
  ...sharedMapDispatchToProps,
  onToggleShowClusterSampleTable: (): AppAction => ({ type: grPageActions.TOGGLE_SHOW_CLUSTER_SAMPLE_TABLE }),
};

export const GrPageClusterSampleTable = connect(grPageMapStateToProps, grPageMapDispatchToProps)(ClusterSampleTable);
