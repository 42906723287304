import './TextArea.scss';

import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React, { Requireable } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import { $TSFixMe } from '../../utils/typescript';


const propTypes = {
  /**
   * The class name of the element
   */
  className: PropTypes.string,
  /**
   * The limit of how large a textarea can get
   */
  maxRows: PropTypes.number,
  /**
   * To be invoked when input value is changed.
   */
  onChange: PropTypes.func.isRequired as Required<(value: string) => void>,
  /**
   * To be called when a key is pressed.
   */
  onKeyDown: PropTypes.func as Requireable<(event: React.KeyboardEvent<HTMLTextAreaElement>) => void>,
  /**
   * A hint to the user of what can be entered in the control.
   */
  placeholder: PropTypes.string,
  /**
   * Whether the text area is editable or not.
   */
  readOnly: PropTypes.bool,
  /**
   * Holds the type of the text area
   */
  type: PropTypes.string,
  /**
   * Holds the contents of the text area and can be used to set or get the content as well.
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string.isRequired),
  ]),
};
type TextAreaProps = InferProps<typeof propTypes>

/**
 * Thin wrapper around <textarea>.
 */
export default class TextArea extends React.Component<TextAreaProps> {
  static propTypes = propTypes;

  handleChange = (event: $TSFixMe) => {
    this.props.onChange(event.target.value);
  };

  focus = () => {
    // @ts-expect-error findDOMNode returns Element | Text which doesn't support focus method
    ReactDOM.findDOMNode(this.refs.rawInput).focus();
  };

  render() {
    const { placeholder, maxRows, value, className, readOnly, type, onKeyDown } = this.props;
    return (
      <TextareaAutosize
        maxRows={maxRows || undefined}
        className={classNames(className)}
        ref="rawInput"
        value={_.isString(value) ? value : undefined}
        placeholder={(placeholder !== undefined ? placeholder : 'Text') || undefined}
        type={type || undefined}
        onChange={this.handleChange}
        onKeyDown={onKeyDown || undefined}
        readOnly={readOnly || false}
      />
    );
  }
}
