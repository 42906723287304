import React from 'react';
import { connect } from 'react-redux';

import AppState from '../stores/AppState';
import { canCreateProjects } from '../utils/Authorization';
import { getAuthorizedUser } from '../utils/Selectors';
// @ts-expect-error
import ChooseNewProjectTypeDialog from './ChooseNewProjectTypeDialog';
import CreateNewProjectButton from './CreateNewProjectButton';
// @ts-expect-error
import CreateProjectDialog from './CreateProjectDialog';
// @ts-expect-error
import DeleteProjectDialog from './DeleteProjectDialog';
// @ts-expect-error
import EditProjectDialog from './EditProjectDialog';
// @ts-expect-error
import ProjectList from './ProjectList';
import style from './ProjectManagement.module.scss';
import ProjectTaskbar from './ProjectTaskbar';

const ProjectManagement = connect((state: AppState) => {
  const { projects: { projectsWithStatus, initialFetch, loading } } = state;
  return {
    userCanCreateProjects: canCreateProjects(getAuthorizedUser(state)),
    noProjects: projectsWithStatus.isEmpty() && initialFetch,
    loading,
  };
})(({ noProjects, userCanCreateProjects, loading }) => {
  return (
    <div className={style.component}>
      {noProjects && !loading ? (
        userCanCreateProjects ? (
          <div className={style.startNewProject}>
            <div className={style.prompt}>
              Ready to start your first project?
            </div>
            <CreateNewProjectButton className={style.startProjectButton} />
          </div>
        ) : (
          <div className={style.noProjects}>
            No projects
          </div>
        )
      ) : (
        <div className={style.tableAndTaskbarContainer}>
          <ProjectTaskbar />
          <ProjectList />
        </div>
      )}
      <CreateProjectDialog />
      <EditProjectDialog />
      <DeleteProjectDialog />
      <ChooseNewProjectTypeDialog />
    </div>
  );
});

export default ProjectManagement;
