import { List, Set } from 'immutable';

import { RecordType } from '../transforms/models/Types';
import { $TSFixMe } from '../utils/typescript';

export const CONFIRM_EXPORT = 'SharedDatasetCatalog.confirmExport';
export const CONFIRM_OVERWRITE_EXPORT = 'SharedDatasetCatalog.confirmOverwriteExport';
export const CANCEL_CONFIRM_EXPORT = 'SharedDatasetCatalog.cancelConfirmExport';
export const SUBMIT_EXPORT = 'SharedDatasetCatalog.submitExport';
export const SUBMIT_EXPORT_COMPLETED = 'SharedDatasetCatalog.submitExportCompleted';
export const CONFIRM_PROFILE = 'SharedDatasetCatalog.confirmProfile';
export const CANCEL_CONFIRM_PROFILE = 'SharedDatasetCatalog.cancelConfirmProfile';
export const SUBMIT_PROFILE = 'SharedDatasetCatalog.submitProfile';
export const SUBMIT_PROFILE_COMPLETED = 'SharedDatasetCatalog.submitProfileCompleted';
export const CONFIGURE_DATASET = 'SharedDatasetCatalog.configureDataset';
export const CANCEL_CONFIGURING_DATASET = 'SharedDatasetCatalog.cancelConfiguringDataset';
export const COMMIT_CONFIGURE_DATASETS_COMPLETED =
  'SharedDatasetCatalog.commitConfigureDatasetCompleted';
export const COMMIT_CONFIGURE_DATASET = 'SharedDatasetCatalog.commitConfigureDataset';
export const SHOW_EXPORT_FAILURE_DIALOG = 'SharedDatasetCatalog.showExportFailureDialog';
export const HIDE_EXPORT_FAILURE_DIALOG = 'SharedDatasetCatalog.hideExportFailureDialog';
export const FETCH_DATASET_SAMPLE = 'SharedDatasetCatalog.fetchDatasetSample';
export const FETCH_DATASET_SAMPLE_COMPLETED = 'SharedDatasetCatalog.fetchDatasetSampleCompleted';
export const CLEAR_DATASET_SAMPLE = 'SharedDatasetCatalog.clearDatasetSample';
export const SET_DATASET_SAMPLE_COLUMN_WIDTH = 'SharedDatasetCatalog.setDatasetSampleColumnWidth';

interface ConfirmExport {
  type: typeof CONFIRM_EXPORT
  datasetNames: Set<string>
}

interface ConfirmOverwriteExport {
  type: typeof CONFIRM_OVERWRITE_EXPORT
  datasetNames: Set<string>
}

interface CancelConfirmExport {
  type: typeof CANCEL_CONFIRM_EXPORT
}

interface SubmitExport {
  type: typeof SUBMIT_EXPORT
}

interface SubmitExportCompleted {
  type: typeof SUBMIT_EXPORT_COMPLETED
}

interface ConfirmProfile {
  type: typeof CONFIRM_PROFILE
  datasetNames: Set<string>
}

interface CancelConfirmProfile {
  type: typeof CANCEL_CONFIRM_PROFILE
}

interface SubmitProfile {
  type: typeof SUBMIT_PROFILE
}

interface SubmitProfileCompleted {
  type: typeof SUBMIT_PROFILE_COMPLETED
}

interface ConfigureDataset {
  type: typeof CONFIGURE_DATASET
  datasetName: string
}

interface CancelConfiguringDataset {
  type: typeof CANCEL_CONFIGURING_DATASET
}

interface CommitConfigureDataset {
  type: typeof COMMIT_CONFIGURE_DATASET
}

interface CommitConfigureDatasetCompleted {
  type: typeof COMMIT_CONFIGURE_DATASETS_COMPLETED
}

interface ShowExportFailureDialog {
  type: typeof SHOW_EXPORT_FAILURE_DIALOG
  errorMessage: string
}

interface HideExportFailureDialog {
  type: typeof HIDE_EXPORT_FAILURE_DIALOG
}

interface FetchDatasetSample {
  type: typeof FETCH_DATASET_SAMPLE
  datasetName: string
}

interface FetchDatasetSampleCompleted {
  type: typeof FETCH_DATASET_SAMPLE_COMPLETED
  rows: List<$TSFixMe>
  schema: RecordType
}

interface ClearDatasetSample {
  type: typeof CLEAR_DATASET_SAMPLE
}

interface SetDatasetSampleColumnWidth {
  type: typeof SET_DATASET_SAMPLE_COLUMN_WIDTH
  column: string
  width: number
}

export type SharedDatasetCatalogActionTypes
  = ConfirmExport
  | ConfirmOverwriteExport
  | CancelConfirmExport
  | SubmitExport
  | SubmitExportCompleted
  | ConfirmProfile
  | CancelConfirmProfile
  | SubmitProfile
  | SubmitProfileCompleted
  | ConfigureDataset
  | CancelConfiguringDataset
  | CommitConfigureDataset
  | CommitConfigureDatasetCompleted
  | ShowExportFailureDialog
  | HideExportFailureDialog
  | FetchDatasetSample
  | FetchDatasetSampleCompleted
  | ClearDatasetSample
  | SetDatasetSampleColumnWidth
