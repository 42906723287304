import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import { predictSuggestions, trainSuggestions } from './SchemaMappingAsync';
import SuggestionsButton from './SuggestionButton';
import style from './SuggestionButton.module.scss';

const TrainPredictButtons = connect(state => {
  const { schemaMapping: { hasSmrModel, hasSmrModelLoaded } } = state;
  return {
    hasTrainedBefore: hasSmrModel && hasSmrModelLoaded,
  };
}, {
  onTrainSuggestions: trainSuggestions,
  onPredictSuggestions: predictSuggestions,
})(({ onTrainSuggestions, onPredictSuggestions, hasTrainedBefore }) => {
  const generateSuggestionsPreconditions = new Map({
    'Please learn from mappings first': hasTrainedBefore,
  });
  return (
    <div className={style.trainPredictButtons}>
      <SuggestionsButton text="Learn from mappings" operation={onTrainSuggestions} />
      <SuggestionsButton
        text="Generate mapping suggestions"
        operation={onPredictSuggestions}
        additionalPreconditions={generateSuggestionsPreconditions}
      />
    </div>
  );
});

export default TrainPredictButtons;
