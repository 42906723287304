import _ from 'underscore';

import { ArgTypes, checkArg } from '../../utils/ArgValidation';

function titleCase(noun) {
  return noun.slice(0, 1).toUpperCase() + noun.slice(1);
}

const strOrMissing = ArgTypes.oneOf(ArgTypes.string, ArgTypes.missing);

/**
 * A user-configurable word inside the UI with built-in support for pluralization
 * and different cases. These are generally stored in the metadata of a project in
 * order to allow for per-project terminology.
 */
class Term {
  constructor(singular, plural) {
    checkArg({ singular }, strOrMissing);
    this._singular = _.isEmpty(singular) ? '' : singular.toLowerCase();
    checkArg({ plural }, strOrMissing);
    this._plural = (_.isEmpty(plural) ? singular + 's' : plural).toLowerCase();
  }

  get singular() { return this._singular; }
  get Singular() { return titleCase(this.singular); }
  get SINGULAR() { return this.singular.toUpperCase(); }

  get plural() { return this._plural; }
  get Plural() { return titleCase(this.plural); }
  get PLURAL() { return this.plural.toUpperCase(); }

  toJSON() {
    const { singular, plural } = this;
    return { singular, plural };
  }

  static fromJSON(data) {
    checkArg({ data }, ArgTypes.object);
    return new Term(data.singular, data.plural);
  }
}

export default Term;
export {
  titleCase,
};
