import React from 'react';
import { connect } from 'react-redux';

import AppState from '../stores/AppState';
import { history, triggers } from '../utils/History';
import { getUrl } from '../utils/Routing';
import { $TSFixMe } from '../utils/typescript';

type Location = $TSFixMe;

const mapStateToProps = (state: AppState) => {
  return { url: getUrl(state), oldPath: state.location.path };
};
const mapDispatchToProps = {
  onLocationChange: (location: Location, oldPath: string) => ({ type: 'Location.change', location, oldPath }),
};

type UrlManagerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const UrlManager = connect(
  mapStateToProps,
  mapDispatchToProps,
)(class UrlManager extends React.Component<UrlManagerProps> {
  unlisten: Function;

  UNSAFE_componentWillMount() {
    this.unlisten = history.listen((location: Location) => this.handleLocationEvent(location));
  }

  handleLocationEvent(location: Location) {
    const { onLocationChange, oldPath } = this.props;
    if (location.action === 'POP' || location.action === 'PUSH') {
      onLocationChange(location, oldPath);
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    this.updateUrl();
  }

  componentDidUpdate() {
    this.updateUrl();
  }

  updateUrl = () => {
    const { url } = this.props;
    if (triggers.pushNext) {
      history.push(url);
      triggers.pushNext = false;
    } else {
      history.replace(url);
    }
  };

  render() {
    return null;
  }
});

export default UrlManager;
