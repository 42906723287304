import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { FCWithPropTypes } from '../utils/typescript-react';
import style from './EnichmentBox.module.scss';

const EnrichmentBox = FCWithPropTypes(
  {
    /**
     * Pass the contents of the box in.
     */
    children: PropTypes.node.isRequired,
    /**
     * The title of the box.
     */
    title: PropTypes.string,
    /**
     * If true, will render the border as active.
     */
    active: PropTypes.bool,
    /**
     * The number of the box
     */
    boxNum: PropTypes.number,
  },
  ({ children, active, title, boxNum }) => {
    const containerClasses = classNames({
      [style.boxContainer]: true,
      [style.isActive]: !!active,
    });
    return (
      <div className={containerClasses}>
        <div className={style.header}>
          <span className={style.boxNum}>{boxNum}</span>
          <span className={style.title}>{title}</span>
        </div>
        <div className={style.childrenContainer}>
          {children}
        </div>
        <div />
      </div>
    );
  },
);

export default EnrichmentBox;
