import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import Checkbox from '../components/Input/Checkbox';
import Selector from '../components/Input/Selector';
import Toggle from '../components/Toggle';
import GroupByAggregations from './GroupByAggregations';
import GroupByEntry from './GroupByEntry';
import GroupByInput from './GroupByInput';

const GroupByField = connect(null, {
  onUpdateFields: (updateFields, attribute, value) => ({ type: 'GroupBy.updateFields', updateFields, attribute, value }),
  onRemoveFields: (deletedFields) => ({ type: 'GroupBy.removeFields', deletedFields }),
})(class GroupByField extends React.Component {
  static propTypes = {
    field: PropTypes.instanceOf(GroupByEntry).isRequired,
    onRemoveFields: PropTypes.func.isRequired,
    onUpdateFields: PropTypes.func.isRequired,
  };

  updateFieldValue = (attribute, value) => {
    this.props.onUpdateFields(Immutable.List.of(this.props.field), attribute, value);
  };

  removeField = () => {
    this.props.onRemoveFields(Immutable.List.of(this.props.field));
  };

  renderAggregationName = () => {
    return (
      <GroupByInput
        onExit={_.partial(this.updateFieldValue, 'aggregationName')}
        defaultText={this.props.field.aggregationName}
        text={this.props.field.aggregationName}
      />
    );
  };

  renderShow = () => {
    if (this.props.field.groupBy) {
      return <span>{this.props.field.fieldName} used in Group By.</span>;
    }
    return (
      <Selector
        placeholder="Select Aggregation"
        onChange={_.partial(this.updateFieldValue, 'aggregationType')}
        values={GroupByAggregations}
        value={this.props.field.aggregationType}
      />
    );
  };

  renderGroupBy = () => {
    return (
      <Toggle
        value={this.props.field.groupBy}
        onChange={_.partial(this.updateFieldValue, 'groupBy', !this.props.field.groupBy)}
      />
    );
  };

  renderDelete = () => {
    return (
      <Button
        buttonType="Link"
        icon="delete"
        iconSize={16}
        onClick={_.partial(this.removeField)}
      />
    );
  };

  renderLine = () => {
    return (
      <div className="field-line item">
        <div className="column selected">
          <Checkbox
            onChange={_.partial(this.updateFieldValue, 'selected', !this.props.field.selected)}
            value={this.props.field.selected}
          />
        </div>
        <div className="column attribute">{this.renderAggregationName()}</div>
        <div className="column field">{this.props.field.fieldName}</div>
        <div className="column group-by">{this.renderGroupBy()}</div>
        <div className="column show">{this.renderShow()}</div>
        <div className="column delete">{this.renderDelete()}</div>
      </div>
    );
  };

  render() {
    return (
      <div className="group-by-field">
        {this.renderLine()}
      </div>
    );
  }
});

export default GroupByField;
