// com.tamr.dedup.models.UserDefinedSignal

import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';

class UserDefinedSignal extends getModelHelpers({
  externalId: { type: ArgTypes.string },
  displayName: { type: ArgTypes.string },
  datasetName: { type: ArgTypes.string },
  expression: { type: ArgTypes.string },
  description: { type: ArgTypes.orNull(ArgTypes.string) },
}, 'UserDefinedSignal')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class UserDefinedSignalRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  static fromJSON(obj: $TSFixMe) {
    const { externalId, displayName, datasetName, expression, description } = obj;
    return new UserDefinedSignal({
      externalId,
      displayName,
      datasetName,
      expression,
      description: description || null,
    });
  }
}

export default UserDefinedSignal;
