import Model from '../models/Model';
import TamrVersion from '../models/TamrVersion';
import { ArgTypes } from '../utils/ArgValidation';

/**
 * Store that contains the Tamr version information
 */

export const initialState = new (Model({
  version: { type: ArgTypes.nullable(ArgTypes.instanceOf(TamrVersion)) },
}))();

export const reducers = {
  'Version.loadVersionCompleted': (state, { version }) => {
    return state.merge({ version });
  },
};
