import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import DetailSidebar from '../components/DetailSidebar/DetailSidebar';
import DetailSidebarTabbedContent from '../components/DetailSidebar/DetailSidebarTabbedContent';
import Selector from '../components/Input/Selector';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';

const DnfSidebar = _.compose(
  connect(({ dnfBuilder: { sourcesToBlockSelfMatch, sourcesToBlockSelfCluster }, allSourceDatasets: { datasets: sourceDatasetDocs } }) => {
    return {
      sourcesToBlockSelfMatch,
      sourcesToBlockSelfCluster,
      sourceDatasetDocNames: sourceDatasetDocs.map(d => d.data.name).toSet(),
    };
  }, {
    onSetSourceToBlockSelfMatch: (index, datasetName) => ({ type: 'DnfBuilder.setSourceToBlockSelfMatch', index, datasetName }),
    onRemoveSourceToBlockSelfMatch: (index) => ({ type: 'DnfBuilder.removeSourceToBlockSelfMatch', index }),
    onAddSourceToBlockSelfMatch: () => ({ type: 'DnfBuilder.addSourceToBlockSelfMatch' }),
    onSetSourceToBlockSelfCluster: (index, datasetName) => ({ type: 'DnfBuilder.setSourceToBlockSelfCluster', index, datasetName }),
    onRemoveSourceToBlockSelfCluster: (index) => ({ type: 'DnfBuilder.removeSourceToBlockSelfCluster', index }),
    onAddSourceToBlockSelfCluster: () => ({ type: 'DnfBuilder.addSourceToBlockSelfCluster' }),
  }),
)(class DnfSidebar extends React.Component {
  static propTypes = {
    isExpanded: PropTypes.bool,
    onAddSourceToBlockSelfCluster: PropTypes.func.isRequired,
    onAddSourceToBlockSelfMatch: PropTypes.func.isRequired,
    onRemoveSourceToBlockSelfCluster: PropTypes.func.isRequired,
    onRemoveSourceToBlockSelfMatch: PropTypes.func.isRequired,
    onSetSourceToBlockSelfCluster: PropTypes.func.isRequired,
    onSetSourceToBlockSelfMatch: PropTypes.func.isRequired,
    sidebarKey: PropTypes.any,
    sourceDatasetDocNames: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
    sourcesToBlockSelfCluster: ImmutablePropTypes.listOf(PropTypes.string).isRequired,
    sourcesToBlockSelfMatch: ImmutablePropTypes.listOf(PropTypes.string).isRequired,
  };

  renderPairBlockingHeader = () => {
    return (
      <div className="first-sidebar-header">
        <div className="header1">
          EXCLUDE <Term>PAIRS</Term> WITHIN THESE SOURCES
        </div>
        <div className="header2">
          You can choose to exclude searching for matches within sources.
        </div>
      </div>
    );
  };

  renderPairBlockingSelector = (datasetName, i, values) => {
    return (
      <div
        className="dataset-selector"
        key={`selector-${i}`}
      >
        <div
          className="selector-container"
        >
          <Selector
            placeholder="Select a dataset"
            onChange={_.partial(this.props.onSetSourceToBlockSelfMatch, i)}
            values={values}
            value={datasetName}
          />
        </div>
        <TamrIcon
          className="delete-button"
          iconName="tamr-icon-remove"
          size={14}
          onClick={_.partial(this.props.onRemoveSourceToBlockSelfMatch, i)}
        />
      </div>
    );
  };

  isDatasetValid = (datasetName) => {
    const { sourceDatasetDocNames } = this.props;
    // note that undefined datasetName has meaning here as a placeholder of a new source, so
    // it is valid.
    return !datasetName || sourceDatasetDocNames.contains(datasetName);
  };

  renderPairBlockingBody = () => {
    const { sourcesToBlockSelfMatch, sourceDatasetDocNames } = this.props;

    /**
     * This is to account for the fact that there can be ghost source datasets in
     * sourcesToBlockSelfMatch, which is initialized from the recipe's metadata. This
     * can be the result of different high level operations such as dataset delete, or
     * input dataset removal. Therefore, we need to guard against this issue by:
     *
     * - Do not render the ghost source dataset as a selected option.
     * - Make sure that total count include the count for ghost datasets, so that when
     *   we add a new source, the index of that new source is in sync with what's in
     *   the state.
     */
    const sourceDatasetsCount = sourceDatasetDocNames.count();

    // note that this removes the undefined values from this set
    const validSourcesToBlockSelfMatch = sourcesToBlockSelfMatch.filter(this.isDatasetValid).toSet();

    const totalDatasetCount =
      sourceDatasetsCount + sourcesToBlockSelfMatch.filter(ds => !this.isDatasetValid(ds)).count();

    return (
      <div className="sidebar-body">
        {/* Note that we iterate over the collections that can have the ghost
        source datasets here in order to maintain the correct indexes of the excluded
        sources store in the state */}
        {sourcesToBlockSelfMatch.map((ds, i) => {
          if (!this.isDatasetValid(ds) || !(i < totalDatasetCount)) {
            return undefined;
          }

          function isMasterSource(datasetName) {
            return !validSourcesToBlockSelfMatch.contains(datasetName) || datasetName === ds;
          }

          const nonExcludedSources = sourceDatasetDocNames.filter(isMasterSource).map(dsName => {
            return {
              display: dsName,
              value: dsName,
            };
          }).toArray();

          return this.renderPairBlockingSelector(ds, i, nonExcludedSources);
        })}
        <Button
          className="add-new-link"
          buttonType="Link"
          onClick={() => this.props.onAddSourceToBlockSelfMatch()}
        >
          {totalDatasetCount > sourcesToBlockSelfMatch.count() ? '+ add source' : ''}
        </Button>
      </div>
    );
  };

  renderClusterBlockingHeader = () => {
    return (
      <div className="sidebar-header">
        <div className="header1">
          EXCLUDE CLUSTERING WITHIN THESE SOURCES
        </div>
        <div className="header2">
          You can choose to exclude clustering records within sources.
        </div>
      </div>
    );
  };

  renderClusterBlockingSelector = (datasetName, i, values) => {
    return (
      <div
        className="dataset-selector"
        key={`selector-${i}`}
      >
        <div
          className="selector-container"
        >
          <Selector
            placeholder="Select a dataset"
            onChange={_.partial(this.props.onSetSourceToBlockSelfCluster, i)}
            values={values}
            value={datasetName}
          />
        </div>
        <TamrIcon
          className="delete-button"
          iconName="tamr-icon-remove"
          size={14}
          onClick={_.partial(this.props.onRemoveSourceToBlockSelfCluster, i)}
        />
      </div>
    );
  };

  renderClusterBlockingBody = () => {
    const { sourcesToBlockSelfCluster, sourceDatasetDocNames } = this.props;

    /**
     * This is to account for the fact that there can be ghost source datasets in
     * sourcesToBlockSelfCluster, which is initialized from the recipe's metadata. This
     * can be the result of different high level operations such as dataset delete, or
     * input dataset removal. Therefore, we need to guard against this issue by:
     *
     * - Do not render the ghost source dataset as a selected option.
     * - Make sure that total count include the count for ghost datasets, so that when
     *   we add a new source, the index of that new source is in sync with what's in
     *   the state.
     */
    const sourceDatasetsCount = sourceDatasetDocNames.count();

    // note that this removes the undefined values from this set
    const validSourcesToBlockSelfCluster = sourcesToBlockSelfCluster.filter(this.isDatasetValid).toSet();

    const totalDatasetCount =
      sourceDatasetsCount + sourcesToBlockSelfCluster.filter(ds => !this.isDatasetValid(ds)).count();

    return (
      <div className="sidebar-body">
        {/* Note that we iterate over the collections that can have the ghost
        source datasets here in order to maintain the correct indexes of the excluded
        sources store in the state */}
        {sourcesToBlockSelfCluster.map((ds, i) => {
          if (!this.isDatasetValid(ds) || !(i < totalDatasetCount)) {
            return undefined;
          }

          function isMasterSource(datasetName) {
            return !validSourcesToBlockSelfCluster.contains(datasetName) || datasetName === ds;
          }

          const nonExcludedSources = sourceDatasetDocNames.filter(isMasterSource).map(dsName => {
            return {
              display: dsName,
              value: dsName,
            };
          }).toArray();

          return this.renderClusterBlockingSelector(ds, i, nonExcludedSources);
        })}
        <Button
          className="add-new-link"
          buttonType="Link"
          onClick={() => this.props.onAddSourceToBlockSelfCluster()}
        >
          {totalDatasetCount > sourcesToBlockSelfCluster.count() ? '+ add source' : ''}
        </Button>
      </div>
    );
  };

  renderSourcesTab = () => {
    if (this.props.sourceDatasetDocNames.size < 2) {
      return (
        <div className="sidebar-sources">
          You can not exclude matching within a dataset if there is only a single input dataset in your project
        </div>
      );
    }
    return (
      <div className="sidebar-sources">
        {this.renderPairBlockingHeader()}
        {this.renderPairBlockingBody()}
        {this.renderClusterBlockingHeader()}
        {this.renderClusterBlockingBody()}
      </div>
    );
  };

  render() {
    return (
      <DetailSidebar
        className="dnf-sidebar"
        isExpanded={this.props.isExpanded}
        isExpandable
      >
        <DetailSidebarTabbedContent
          tabs={
            new Immutable.List([
              {
                eventKey: 'sources',
                title: 'Sources',
                content: this.renderSourcesTab(),
              },
            ])
          }
        />
      </DetailSidebar>
    );
  }
});

export default DnfSidebar;
