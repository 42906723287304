import createLoader from '../../utils/createLoader';
import { selectActiveProjectInfo } from '../../utils/Selectors';
import { fetchTopClusters } from './DedupDashboardApi';

const TopClustersLoader = createLoader((state) => {
  const { dedupDashboard: { loadingTopClusters: loading, loadedTopClusters } } = state;
  const projectInfo = selectActiveProjectInfo(state);
  return {
    canFetch: !!(projectInfo && projectInfo.isUnifiedDatasetIndexed),
    shouldFetch: !loadedTopClusters,
    loading,
  };
}, {
  onFetch: fetchTopClusters,
}, 'TopClustersLoader');

export default TopClustersLoader;
