import { List } from 'immutable';
import React from 'react';

import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';

const DatasetNumAttributesColumn: React.FC<{
  rows: List<Document<Dataset>>
  width: number
}> = ({ rows, width }) => {
  return (
    <Column
      key="numAttributes"
      width={width}
      isResizable
      columnKey="numAttributes"
      flexGrow={1}
      header={<Cell>Attributes</Cell>}
      cell={({ rowIndex }) => <Cell>{rows.get(rowIndex)?.data.fields.size}</Cell>}
    />
  );
};

export default DatasetNumAttributesColumn;
