import classNames from 'classnames';
import { Map, Set } from 'immutable';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import ConditionalButton from '../components/ConditionalButton';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import CoreConnectFileType from '../coreConnectService/CoreConnectFileType';
import {
  RESET_CONNECT_STORE,
  TRANSFER_CLOUD_TO_TAMR_COMPLETED,
} from '../coreConnectService/CoreConnectServiceActionTypes';
import { startTransferCloudToTamr } from '../coreConnectService/CoreConnectServiceAsync';
import Privileges from '../constants/Privileges';
import FileUploadDialogContent from '../datasets/FileUploadDialogContent';
import ManageProjectDatasetsFilterContent from '../datasets/ManageProjectDatasetsFilterContent';
import MinimalAuthUser from '../models/MinimalAuthUser';
import PrivilegeSpec from '../models/PrivilegeSpec';
import ResourceSpec from '../models/ResourceSpec';
import { AppAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { canUpdateDatasetAtLeastOnePolicy, hasPermission } from '../utils/Authorization';
import { getAuthorizedUser } from '../utils/Selectors';
import { isBlank } from '../utils/Strings';
import { addDataset } from './AddDatasetAsync';
import CloudStorageContent from './CloudStorageContent';
import style from './DatasetManagementDialog.module.scss';
import DatasetSource, { DatasetSourceE } from './DatasetSource';
import ExternalStorageContent from './ExternalStorageContent';
import { getIsValidSelection } from './ExternalStorageSelectors';
import JDBCContent from './JDBCContent';

interface TabItemOwnProps {
  name: DatasetSourceE
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TabItem = connect((state: AppState, ownProps: TabItemOwnProps) => {
  const { addDataset: { selectedTab, addingDataset } } = state;
  return {
    selectedTab,
    addingDataset,
  };
}, {
  onSelect: (selectedTab: DatasetSourceE): AppAction => ({ type: 'AddDataset.setTab', selectedTab }),
})(({ addingDataset, selectedTab, name, onSelect }) => (
  <div
    className={classNames(style.tabItem, { [style.selected]: name === selectedTab })}
    onClick={!addingDataset ? () => onSelect(name) : () => {}}
  >
    {name}
  </div>
));

/**
 * Admins, Authors, and curators with access to future datasets can upload data
 * curators without access to future datasets and other users cannot upload data
 */
const renderIfCanUploadData = ({
  user,
  uploadTab,
}: {
  user: MinimalAuthUser | undefined,
  uploadTab: ReactElement
}) => {
  const permissionToFutureDatasets = user ? hasPermission({
    user,
    privilege: new PrivilegeSpec(Privileges.DATASET_UPDATE),
    resource: new ResourceSpec('datasets/*'),
  }) : false;

  if (permissionToFutureDatasets || canUpdateDatasetAtLeastOnePolicy({ user })) {
    return uploadTab;
  }
  return (
    <div className={style.cantUploadMsg}>You do not have permission to upload datasets for this project. Contact a Tamr admin to upload the dataset or change your permissions.</div>
  );
};

export const isPolicySelectionValid = ({
  user,
  policies,
}: {
  user: MinimalAuthUser | undefined,
  policies: Set<number>
}) => {
  const permissionToFutureDatasets = user ? hasPermission({
    user,
    privilege: new PrivilegeSpec(Privileges.DATASET_UPDATE),
    resource: new ResourceSpec('datasets/*'),
  }) : false;
  return policies.size > 0 || permissionToFutureDatasets;
};

/**
 * Dialog for adding a dataset to the system, or managing current project membership of datasets
 *
 * Used in two cases:
 *  1. add dataset to a project / managed project dataset membership
 *  2. add dataset to dataset catalog (no management here)
 *
 * There are three different methods for adding datasets:
 *  1. upload file (CSV/TSV/etc. format)
 *  2. add from dataset catalog
 */

interface DatasetManagementDialogProps {
  isCatalogPage?: boolean,
  isSingleSelect?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DatasetManagementDialog = connect((state: AppState, ownProps: DatasetManagementDialogProps) => {
  const {
    addDataset: { addingDataset, selectedTab },
    fileUpload: { errorMessage, primaryKeyColumnName, primaryKeyCustomName, file },
    datasetFilter: { datasetsToAdd, datasetsToRemove },
    externalStorage: { datasetName, idColumn },
    coreConnectService: {
      coreconnectEnabled,
      coreconnectDefaultProvider,
      sourceProjectName,
      sourceBucketName,
      sourcePathPrefix,
      sourceFileType,
      sinkPathPrefix,
    },
    accessControl: { datasetDraftPolicyResourceship },
  } = state;
  const draftDatasetPolicyIds = Set(datasetDraftPolicyResourceship?.getPolicyIds() || []);
  return {
    addingDataset,
    isValidExternalContentSelection: getIsValidSelection(state),
    selectedTab,
    uploadFileErrorMessage: errorMessage,
    uploadFilePrimaryKey: primaryKeyColumnName || primaryKeyCustomName,
    uploadFileSelected: !!file,
    datasetsToAdd,
    datasetsToRemove,
    datasetName,
    idColumn,
    coreconnectEnabled,
    // @ts-ignore
    coreconnectTab: DatasetSource[coreconnectDefaultProvider?.toUpperCase() || ''] === selectedTab,
    coreconnectDefaultProvider: coreconnectDefaultProvider?.toUpperCase(),
    sourceProjectName,
    sourceBucketName,
    sourcePathPrefix,
    sourceFileType,
    sinkPathPrefix,
    user: getAuthorizedUser(state),
    draftDatasetPolicyIds,
    recipeId: state.location?.recipeId,
  };
}, (dispatch, { isCatalogPage }) => bindActionCreators({
  onAdd: () => (addDataset(!isCatalogPage)),
  onAddCoreConnect: () => (startTransferCloudToTamr()),
  onAddCoreConnectJDBC: () => ({ type: TRANSFER_CLOUD_TO_TAMR_COMPLETED }),
  onCancel: (): AppAction => ({ type: 'AddDataset.hideDialog' }),
  onCancelCoreConnect: (): AppAction => ({ type: RESET_CONNECT_STORE }),
  onCancelPolicyEditing: (): AppAction => ({ type: 'Datasets.stopEditingPolicyResourceship' }),
}, dispatch))(({
  /**
   * Whether dataset is currently being added
   */
  addingDataset,
  datasetsToAdd,
  datasetsToRemove,
  draftDatasetPolicyIds,
  /**
   * Whether this dialog is on the dataset catalog page (aka catalog tab is disabled)
   * If not on the catalog page, then it's on the project catalog page, meaning offer user ability to manage membership
   */
  isCatalogPage,
  /**
   * Whether the selection for the external data is valid (i.e. supported file format)
   */
  isValidExternalContentSelection,
  /**
   * Called to add the dataset
   */
  onAdd,
  /**
   * Called to cancel out of the dialog
   */
  onCancel,
  /**
   * Which tab is currently selected (e.g. upload file, add from catalog)
   */
  selectedTab,
  /**
   * Error message from uploading a file
   */
  uploadFileErrorMessage,
  /**
   * Name of the primary key column for the file to upload
   */
  uploadFilePrimaryKey,
  /**
   * Whether a file to upload is selected
   */
  uploadFileSelected,
  /**
    * Name of the dataset that will be created
    */
  datasetName,
  /**
    * Name of the column that will be used as the ID
    */
  idColumn,
  /**
    * True if CoreConnect is enabled
    */
  coreconnectEnabled,
  coreconnectTab,
  coreconnectDefaultProvider,
  sourceProjectName,
  sourceBucketName,
  sourcePathPrefix,
  sourceFileType,
  sinkPathPrefix,
  onAddCoreConnect,
  onCancelCoreConnect,
  onAddCoreConnectJDBC,
  onCancelPolicyEditing,
  user,
  recipeId,
}) => (!selectedTab ? null : (
  <Dialog
    show
    className={style.dialog}
    dialogStyle={DialogStyle.FULL}
    title={isCatalogPage ? 'Add Dataset' : 'Edit Datasets'}
    onHide={() => {
      onCancel();
      onCancelCoreConnect();
      onCancelPolicyEditing();
    }}
    body={
      <div className={style.topLevel}>
        <div className={style.tabBar}>
          {!isCatalogPage && <TabItem name={DatasetSource.CATALOG} />}
          <TabItem name={DatasetSource.UPLOAD} />
          {coreconnectEnabled ?
            (coreconnectDefaultProvider === 'S3' ? <TabItem name={DatasetSource.S3} />
              : (coreconnectDefaultProvider === 'GCS' ? <TabItem name={DatasetSource.GCS} />
                : (coreconnectDefaultProvider === 'ADLS2' ? <TabItem name={DatasetSource.ADLS2} /> : null)))
            : <TabItem name={DatasetSource.CONNECT} />}
          {coreconnectEnabled && <TabItem name={DatasetSource.JDBC} />}

        </div>
        <div className={style.content}>
          {
            {
              [DatasetSource.UPLOAD]: renderIfCanUploadData({ user, uploadTab: <FileUploadDialogContent /> }),
              [DatasetSource.CATALOG]: <ManageProjectDatasetsFilterContent />,
              [DatasetSource.CONNECT]: <ExternalStorageContent />,
              [DatasetSource.S3]: <CloudStorageContent />,
              [DatasetSource.GCS]: <CloudStorageContent />,
              [DatasetSource.ADLS2]: <CloudStorageContent />,
              [DatasetSource.JDBC]: <JDBCContent onSubmit={onAddCoreConnectJDBC} user={user} policyIds={draftDatasetPolicyIds} recipeId={recipeId} />,
            }[selectedTab]
          }
        </div>
      </div>
    }
    footer={
      <ButtonToolbar>
        {!isCatalogPage && (!datasetsToAdd.isEmpty() || !datasetsToRemove.isEmpty()) && (
          <span>
            Adding {datasetsToAdd.size}, removing {datasetsToRemove.size}
          </span>
        )}
        <Button
          buttonType="Secondary"
          onClick={() => {
            onCancel();
            onCancelCoreConnect();
            onCancelPolicyEditing();
          }}
        >
          Cancel
        </Button>
        {selectedTab !== DatasetSource.JDBC && <ConditionalButton
          tooltipPlacement="top"
          preconditions={Map({
            'Adding dataset in progress': !addingDataset,
            'Error while uploading file': selectedTab === DatasetSource.UPLOAD ? !uploadFileErrorMessage : true,
            'Please select primary key column': selectedTab === DatasetSource.UPLOAD ? !!uploadFilePrimaryKey : true,
            'Please select a file': selectedTab === DatasetSource.UPLOAD ? uploadFileSelected : true,
            'Please select a policy': isPolicySelectionValid({ user, policies: draftDatasetPolicyIds }),
            'No changes to project membership': selectedTab === DatasetSource.CATALOG ? (!datasetsToAdd.isEmpty() || !datasetsToRemove.isEmpty()) : true,
            'Please select CSV, Avro, or Parquet files': selectedTab === DatasetSource.CONNECT ? isValidExternalContentSelection : true,
            'Please enter a name for the dataset': selectedTab === DatasetSource.CONNECT ? datasetName !== '' : true,
            'Please enter a column name to use as the ID': selectedTab === DatasetSource.CONNECT ? idColumn !== '' : true,
            'Please select a file type': coreconnectTab ? sourceFileType === CoreConnectFileType.CSV || sourceFileType === CoreConnectFileType.AVRO : true,
            'Please select a source file': coreconnectTab ? (
              !isBlank(sourceProjectName)
              && !isBlank(sourceBucketName)
              && !isBlank(sourcePathPrefix)
              && !isBlank(sinkPathPrefix)
              && !isBlank(sinkPathPrefix)
              && !sinkPathPrefix?.includes('/')
            ) : true,
            'Please enter a name for dataset': coreconnectTab ? !isBlank(sinkPathPrefix) : true,
          })}
          onClick={coreconnectTab ? onAddCoreConnect : onAdd}
        >
          {isCatalogPage ? 'Add Dataset' : 'Save Datasets'}
        </ConditionalButton>}
        <span id={'JDBCPortal'} />
      </ButtonToolbar>
    }
  />
)));

export default DatasetManagementDialog;
