import classNames from 'classnames';
import numeral from 'numeral';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';

import style from './NullValuesBar.module.scss';
import TooltipTrigger from './TooltipTrigger';

const DEFAULT_HEIGHT = 10;
const DEFAULT_WIDTH = '100%';

function tooltip(enabled: boolean): (child: JSX.Element, content: JSX.Element | string) => JSX.Element {
  return (child, content) => {
    if (enabled) {
      return <TooltipTrigger content={content} placement="top">{child}</TooltipTrigger>;
    }
    return child;
  };
}

function roundedPercent(num: number, total: number) {
  return Math.min(100, num / total * 100);
}

const propTypes = {
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  profileInfo: PropTypes.any,
  width: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  withHovers: PropTypes.bool,
};

type NullValuesBarProps = InferProps<typeof propTypes>;

/* eslint-disable react/no-multi-comp */
const NullValuesBar: React.FC<NullValuesBarProps> = ({ className, profileInfo, height, width, withHovers = false }) => {
  height = height || DEFAULT_HEIGHT;
  width = width || DEFAULT_WIDTH;
  const containerClass = classNames(style.densityBar, className);
  if (!profileInfo.profiled) {
    return (
      <div className={containerClass} style={{ height, width }}>
        <span className={classNames(style.bar, style.emptyValue)} />
      </div>
    );
  }
  const {
    numEmptyRecords, numNotNullRecords, numNullRecords, // old profiling
    numSingle, numMultivalue, // newer multivalue profiling
    maxValues, meanValues,
    totalRecords,
  } = profileInfo;

  let ratioEmpty;
  let ratioSingleValue;
  let ratioMultiValue;
  if (isNaN(numSingle) || isNaN(numMultivalue)) {
    // backwards compatibility
    ratioEmpty = roundedPercent(numEmptyRecords + numNullRecords, totalRecords);
    ratioSingleValue = roundedPercent(numNotNullRecords, totalRecords);
    ratioMultiValue = 0;
  } else {
    // multivalue profiling: any length == 0 records are empty, includes null and []
    ratioEmpty = roundedPercent(numEmptyRecords, totalRecords);
    ratioSingleValue = roundedPercent(numSingle, totalRecords);
    ratioMultiValue = roundedPercent(numMultivalue, totalRecords);
  }

  const tooltipMaybe = tooltip(withHovers || false);
  const mvString = (
    <span>
      {Math.round(ratioMultiValue)}% lists with multiple values
      <br />
      Max: {maxValues} values
      <br />
      Mean: {numeral(meanValues).format('0.00')} values
    </span>
  );

  return (
    <div className={containerClass} style={{ height, width }}>
      {tooltipMaybe(
        <span className={classNames(style.bar, style.singleValues)} style={{ width: `${ratioSingleValue}%` }} />,
        `${Math.round(ratioSingleValue)}% text`,
      )}
      {tooltipMaybe(
        <span className={classNames(style.bar, style.multiValues)} style={{ width: `${ratioMultiValue}%` }} />,
        mvString,
      )}
      {tooltipMaybe(
        <span className={classNames(style.bar, style.emptyValues)} style={{ width: `${ratioEmpty}%` }} />,
        `${Math.round(ratioEmpty)}% empty (blank string, empty list or null)`,
      )}
    </div>
  );
};
NullValuesBar.propTypes = propTypes;

export default NullValuesBar;
