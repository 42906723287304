import { SUPPLIER_MASTERING } from '../constants/ProjectTypes';
import { RecipeOperations } from '../constants/RecipeOperations';
import {
  CATEGORIZATION,
  DEDUP, ENRICHMENT,
  GOLDEN_RECORDS,
  SCHEMA_MAPPING,
  SCHEMA_MAPPING_RECOMMENDATIONS,
} from '../constants/RecipeType';
import { ArgTypes } from '../utils/ArgValidation';
import { pathExists } from '../utils/Values';
import Dataset from './Dataset';
import Document from './doc/Document';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';
import ProjectWithStatus from './ProjectWithStatus';
import RecipeWithStatus from './RecipeWithStatus';

const MODULE_NAME_METADATA_KEY = 'resultingFromModule';

class ProjectInfo extends getModelHelpers({
  projectWithStatus: { type: ArgTypes.instanceOf(ProjectWithStatus) },
  unifiedDatasetDoc: { type: ArgTypes.nullable(Document.argTypeWithNestedClass(Dataset)) },
}, 'ProjectInfo')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ProjectInfoRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  get moduleId() { return this.recipe?.metadata?.get(MODULE_NAME_METADATA_KEY); }

  get projectDoc() { return this.projectWithStatus.project; }
  get projectId() { return this.projectDoc.id.id; }
  get project() { return this.projectDoc.data; }

  get recipeDocs() { return this.projectWithStatus.recipes.map(rws => rws.recipe); }
  get recipesWithStatus() { return this.projectWithStatus.recipes; }

  get smR12nsRecipeWithStatus() { return this.projectWithStatus.recipes.find(rws => rws.recipe.data.type === SCHEMA_MAPPING_RECOMMENDATIONS); }
  get smR12nsRecipeDoc() { return this.smR12nsRecipeWithStatus?.recipe; }
  get dedupRecipeWithStatus() { return this.projectWithStatus.recipes.find(rws => rws.recipe.data.type === DEDUP); }
  get dedupRecipeDoc() { return this.dedupRecipeWithStatus?.recipe; }
  get c12nRecipeWithStatus() { return this.projectWithStatus.recipes.find(rws => rws.recipe.data.type === CATEGORIZATION); }
  get c12nRecipeDoc() { return this.c12nRecipeWithStatus?.recipe; }
  get grRecipeWithStatus() { return this.projectWithStatus.recipes.find(rws => rws.recipe.data.type === GOLDEN_RECORDS); }
  get grRecipeDoc() { return this.grRecipeWithStatus?.recipe; }
  get enrichmentRecipeWithStatus() { return this.projectWithStatus.recipes.find(rws => rws.recipe.data.type === ENRICHMENT); }
  get enrichmentRecipeDoc() { return this.enrichmentRecipeWithStatus?.recipe; }

  get recipeWithStatus() {
    return this.enrichmentRecipeWithStatus || this.grRecipeWithStatus || this.c12nRecipeWithStatus || this.dedupRecipeWithStatus || this.smR12nsRecipeWithStatus as RecipeWithStatus; // we gloss over the case where recipe list is empty here
  }
  get recipeDoc() { return this.recipeWithStatus.recipe; }
  get recipeId() { return this.recipeDoc.id.id; }
  get recipe() { return this.recipeDoc.data; }
  get projectName() { return this.project?.name; }
  get projectDisplayName() { return this.project?.displayName; }
  get projectDescription() { return this.project?.description; }
  get smRecipeWithStatus() { return this.projectWithStatus.recipes.find(rws => rws.recipe.data.type === SCHEMA_MAPPING); }
  get smRecipeDoc() { return this.smRecipeWithStatus?.recipe; }
  get smRecipeId() { return this.smRecipeDoc?.id?.id; }
  get smRecipe() { return this.smRecipeDoc?.data; }
  get hasSMRecipe() { return !!(this.smRecipe); }

  get smRecsRecipeWithStatus() { return this.projectWithStatus.recipes.find(rws => rws.recipe.data.type === SCHEMA_MAPPING_RECOMMENDATIONS); }
  get smRecsRecipeDoc() { return this.smRecsRecipeWithStatus?.recipe; }
  get smRecsRecipeId() { return this.smRecsRecipeDoc?.id.id; }

  get unifiedDatasetId() { return this.unifiedDatasetDoc?.id.id; }
  get unifiedDataset() { return this.unifiedDatasetDoc?.data; }
  get sourceDatasetIds() { return this.projectWithStatus.recipes.get(0)?.recipe.data.inputDatasets.map(docId => docId.id); } // TODO should this not be used for scalability concerns?
  get outputDatasetIds() {
    return this.recipesWithStatus.flatMap(rws => {
      return rws.recipe.data.outputDatasets.toList().map(d => d.id);
    }).toSet();
  }
  /** The tag used is based on the ml recipe */
  get tag() { return `recipe_${this.recipeId}`; }
  /*
   * TODO: This logic is very error-prone as it heavily depends on the behavior of the
   *       old recipe system. We currently do not update the recipe when any dataset in
   *       the pipeline deleted by the user directly, both through API or UI. Therefore, the
   *       name of the dataset might be present, but the dataset is not.
   *       We should revisit this as part of the transition to the module system.
   *       In the short-term, we can change this logic to "this.unifiedDataset.name" instead,
   *       However, that would require an audit of the usage of this method ot make sure all the
   *       assumptions about the dataset name is covered.
   */
  get unifiedDatasetName() { return this.smRecipe?.outputDatasets.keySeq().first(undefined); }
  get projectType() { return this.recipeDoc.data.type; }
  get isUnifiedDatasetIndexed() {
    return !!(
      this.unifiedDatasetName &&
      pathExists(this.smRecipeDoc, 'data', 'materializedRevisions', 'records')
    );
  }
  get smRecipeUpToDate() {
    return this.smRecipeWithStatus?.current;
  }
  get recipeUpToDate() {
    const { recipeWithStatus, recipeDoc } = this;
    let recipeUpToDate = false;
    if (recipeWithStatus) {
      if (recipeDoc.data.type === DEDUP) {
        const pairs = recipeWithStatus.materializations.get(RecipeOperations.PAIRS);
        const trainPredictCluster = recipeWithStatus.materializations.get(RecipeOperations.TRAIN_PREDICT_CLUSTER);
        const predictCluster = recipeWithStatus.materializations.get(RecipeOperations.PREDICT_CLUSTER);
        recipeUpToDate = !!(
          pairs?.current &&
          trainPredictCluster?.current &&
          (
            predictCluster?.lastRun &&
            predictCluster?.current || !predictCluster?.lastRun
          )
        );
      } else {
        recipeUpToDate = recipeWithStatus.current;
      }
    }
    return recipeUpToDate;
  }
  get projectUpToDate() {
    const { smRecipeUpToDate, smRecipeDoc, recipeUpToDate } = this;
    // Note, not using project-up-to-date since that includes schema mapping recommendation, which we don't export in the UI
    // Also, we want to include some other parameters (e.g. make sure there are source datasets
    return recipeUpToDate && smRecipeUpToDate && (smRecipeDoc?.data.numInputDatasets || 0) > 0;
  }

  /*
   * DEDUP PROJECT SPECIFIC STUFF
   */
  get hasDnf() {
    return !!this.recipeWithStatus.recipe.data?.metadata.get(DEDUP)?.dnf;
  }
  get visibleMasteringFields() { return this.recipe?.metadata?.get(SUPPLIER_MASTERING)?.visibleFields; }
  get publishedClusterWithData() { return this.dedupRecipeDoc?.data?.outputDatasets?.findKey((v, k) => k.includes('_published_clusters_with_data')); }
  get clusterNameField() {
    // TODO constants
    return this.dedupRecipeDoc?.data.metadata.get('DEDUP')?.nameField;
  }
}
export default ProjectInfo;
