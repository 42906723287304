import { connect } from 'react-redux';
import _ from 'underscore';

import { StatusDialogComponent } from '../job/StatusDialog';
import { getPath } from '../utils/Values';

const ExportFailureDialog = connect((state) => {
  const { sharedDatasetCatalog: { showExportFailureDialogForMessage } } = state;
  const show = !_.isUndefined(showExportFailureDialogForMessage);
  const message = getPath(showExportFailureDialogForMessage);
  return { show, message };
}, {
  onHide: () => ({ type: 'SharedDatasetCatalog.hideExportFailureDialog' }),
})(StatusDialogComponent);

export default ExportFailureDialog;
