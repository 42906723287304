import { is } from 'immutable';

import createLoader from '../utils/createLoader';
import { fetchActiveRecordClusterMembership } from './SuppliersAsync';
import { getActiveRecordClusterMembershipFilterInfo } from './SuppliersStore';

const ActiveRecordClusterMembershipLoader = createLoader((state) => {
  const { suppliers: { expanded, loadedActiveRecordClusterMembershipFilterInfo, loadingActiveRecordClusterMembership } } = state;
  const currentFilterInfo = getActiveRecordClusterMembershipFilterInfo(state);
  const { activeRecordId } = currentFilterInfo;
  return {
    canFetch: expanded && !!activeRecordId,
    shouldFetch: !is(loadedActiveRecordClusterMembershipFilterInfo, getActiveRecordClusterMembershipFilterInfo(state)),
    loading: loadingActiveRecordClusterMembership,
  };
}, {
  onFetch: fetchActiveRecordClusterMembership,
}, 'ActiveRecordClusterMembershipLoader');

export default ActiveRecordClusterMembershipLoader;
