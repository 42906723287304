import classNames from 'classnames';
import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import { TamrIconName } from '../components/Icons/TamrIconClasses';
import ScoreIcon from '../components/ScoreIcon';
import TamrIcon from '../components/TamrIcon';
import RecordPairInnerFeedback from '../models/RecordPairInnerFeedback';
import { AppState } from '../stores/MainStore';
import PRODUCT_NAME from '../utils/ProductName';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { getTime } from '../utils/Time';
// @ts-expect-error
import RecordPairsSidebarDetailsGeospatial from './RecordPairsSidebarDetailsGeospatial';
import style from './RecordPairsSidebarDetailsTab.module.scss';
import { getActivePair } from './RecordPairsStore';
import VerifiedPairIcon from './VerifiedPairIcon';

const mapStateToProps = (state: AppState) => {
  const { config: { pairConfidenceThresholds }, recordPairs, users: { users } } = state;
  const { activeRowNumber, activeGeospatialAttribute } = recordPairs;
  const activePair = getActivePair(recordPairs);
  const projectInfo = selectActiveProjectInfo(state);
  return { users, activePair, activeRowNumber, activeGeospatialAttribute, pairConfidenceThresholds, projectId: projectInfo && projectInfo.projectId };
};

type RecordPairsSidebarDetailsTabProps = ReturnType<typeof mapStateToProps>;

const RecordPairsSidebarDetailsTab = connect(
  mapStateToProps,
)(class RecordPairsSidebarDetailsTab extends React.Component<RecordPairsSidebarDetailsTabProps> {
  renderVerifiedIcon = () => {
    const { activePair } = this.props;
    return activePair && <VerifiedPairIcon pair={activePair} />;
  };

  renderResponseList = (title: string, iconName: TamrIconName, verifiedResponseUsername: string | undefined, responses: List<RecordPairInnerFeedback>, className: string) => {
    if (responses.isEmpty()) {
      return null;
    }
    return (
      <div className={classNames(style.responseList, className)}>
        <div className={style.listHeader}>
          <TamrIcon size={18} iconName={iconName} />
          <span className={style.listTitle}>{title}</span>
        </div>
        {responses
          .sortBy(({ responseTimestamp }) => -(responseTimestamp || 0))
          .map(({ username, responseKey, response, assignmentInfo }) => {
            const date = responseKey === 'SKIP' ? assignmentInfo?.lastModified : response?.lastModified;
            return (
              <div key={username} className={style.response}>
                <span className={style.responseUsername}>
                  {username === verifiedResponseUsername ? this.renderVerifiedIcon() : null}
                  {username}
                </span>
                <span className={style.responseDate}>{getTime((date || 0) * 1000).calendar()}</span>
              </div>
            );
          })}
      </div>
    );
  };

  renderCount = (value: number, label: string, verified: boolean) => {
    return (
      <div className={style.count}>
        <div className={style.countValue}>{value}</div>
        <div className={style.countLabel}>
          <span>
            {label}
            {verified ? this.renderVerifiedIcon() : null}
          </span>
        </div>
      </div>
    );
  };

  render() {
    const { activeGeospatialAttribute, activePair, pairConfidenceThresholds, projectId, users } = this.props;
    if (!activePair || !_.isNumber(projectId)) {
      return null;
    }
    const { manualLabel, suggestedLabel, suggestedLabelConfidence, feedback } = activePair;

    const matchResponses = feedback.filter(f => f.responseKey === 'MATCH');
    const nonMatchResponses = feedback.filter(f => f.responseKey === 'NON_MATCH');
    const skipResponses = feedback.filter(f => f.responseKey === 'SKIP');
    const pending = feedback.filter(f => f.getIn(['assignmentInfo', 'status']) === 'PENDING');

    const verifiedResponse = activePair.getVerifiedResponse(projectId, users);
    const verifiedResponseUsername = verifiedResponse ? verifiedResponse.username : undefined;

    if (activeGeospatialAttribute !== null) {
      return <RecordPairsSidebarDetailsGeospatial />;
    }

    return (
      <div className={style.component}>
        <div className={style.counts}>
          {this.renderCount(matchResponses.size, 'MATCH', manualLabel === 'MATCH')}
          {this.renderCount(nonMatchResponses.size, 'NO MATCH', manualLabel === 'NON_MATCH')}
        </div>
        {suggestedLabel ? (
          <div className={style.tamrResponse}>
            <span className={style.tamrSays}>
              {PRODUCT_NAME} says {suggestedLabel === 'MATCH' ? 'Match' : ' No match'}
              {pairConfidenceThresholds && (<ScoreIcon
                className={style.confidenceIcon}
                score={suggestedLabelConfidence || undefined}
                scoreThresholds={pairConfidenceThresholds}
                titleNoun={`${PRODUCT_NAME} Label Confidence`}
              />)}
            </span>
          </div>
        ) : null}
        {this.renderResponseList('MATCH', 'match', verifiedResponseUsername, matchResponses, style.matchResponses)}
        {this.renderResponseList('NO MATCH', 'do-not-disturb-alt', verifiedResponseUsername, nonMatchResponses, style.nonMatchResponses)}
        {this.renderResponseList('SKIPPED', 'skip-next', verifiedResponseUsername, skipResponses, style.skipResponses)}
        {!pending.isEmpty() ? (
          <div className={style.pendingResponseSection}>
            <div className={style.listHeader}>
              <TamrIcon size={20} className={style.pendingIcon} iconName="tamr-icon-ic_assignment" />
              <span className={style.listTitle}>PENDING RESPONSES</span>
            </div>
            <div className={style.pending}>{pending.map(p => p.username).join(', ')}</div>
          </div>
        ) : null}
      </div>
    );
  }
});

export default RecordPairsSidebarDetailsTab;
