export const PROFILE_DATASET_COMPLETED = 'Datasets.profileDatasetCompleted';
export const START_EDITING_POLICY_RESOURCESHIP = 'Datasets.startEditingPolicyResourceship';
export const STOP_EDITING_POLICY_RESOURCESHIP = 'Datasets.stopEditingPolicyResourceship';
export const ADD_TO_POLICY_RESOURCESHIP = 'Datasets.addToPolicyResourceship';
export const REMOVE_FROM_POLICY_RESOURCESHIP = 'Datasets.removeFromPolicyResourceship';
export const UPDATE_POLICY_RESOURCESHIP_COMPLETED = 'Datasets.updatePolicyResourceshipCompleted';
export const UPDATE_POLICY_RESOURCESHIP_FAILED = 'Datasets.updatePolicyResourceshipFailed';

interface DatasetsProfileDatasetCompleted {
  type: typeof PROFILE_DATASET_COMPLETED;
}

interface DatasetsStartEditingPolicyResourceship {
  type: typeof START_EDITING_POLICY_RESOURCESHIP;
  datasetId: string
  datasetName: string
}

interface DatasetsStopEditingPolicyResourceship {
  type: typeof STOP_EDITING_POLICY_RESOURCESHIP;
}

interface DatasetsAddToPolicyResourceship {
  type: typeof ADD_TO_POLICY_RESOURCESHIP;
  policyId: number
}

interface DatasetsRemoveFromPolicyResourceship {
  type: typeof REMOVE_FROM_POLICY_RESOURCESHIP;
  policyId: number
}

interface DatasetsUpdatePolicyResourceshipCompleted {
  type: typeof UPDATE_POLICY_RESOURCESHIP_COMPLETED;
}

interface DatasetsUpdatePolicyResourceshipFailed {
  type: typeof UPDATE_POLICY_RESOURCESHIP_FAILED;
}

export type DatasetsActionTypes
  = DatasetsProfileDatasetCompleted
  | DatasetsStartEditingPolicyResourceship
  | DatasetsStopEditingPolicyResourceship
  | DatasetsAddToPolicyResourceship
  | DatasetsRemoveFromPolicyResourceship
  | DatasetsUpdatePolicyResourceshipCompleted
  | DatasetsUpdatePolicyResourceshipFailed
