import { List } from 'immutable';
import React from 'react';

import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import EsRecord from '../models/EsRecord';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import styles from './ClusterRecordsTable.module.scss';
import VerificationIcon, {
  getVerificationIconType,
  VERIFICATION_ICON_TYPE_LOCKED,
  VERIFICATION_ICON_TYPE_SUGGESTABLE_HAS_SUGGESTION,
  VERIFICATION_ICON_TYPE_SUGGESTABLE_NO_SUGGESTION,
  VERIFICATION_ICON_TYPE_VERIFIED_ELSEWHERE,
  VERIFICATION_ICON_TYPE_VERIFIED_HERE,
  VerificationIconType,
  VerificationIconTypeE,
} from './VerificationIcon';
import VerificationType from './VerificationType';

const getVerificationIconTooltipMessage = (verificationIconType: VerificationIconTypeE) => {
  checkArg({ verificationIconType }, VerificationIconType.argType);
  switch (verificationIconType) {
    case VERIFICATION_ICON_TYPE_VERIFIED_HERE: return 'Verified. Suggestions auto-accepted.';
    case VERIFICATION_ICON_TYPE_VERIFIED_ELSEWHERE: return 'Verified in another cluster. Suggestions auto-accepted.';
    case VERIFICATION_ICON_TYPE_SUGGESTABLE_HAS_SUGGESTION: return 'Verified. Move suggested.';
    case VERIFICATION_ICON_TYPE_SUGGESTABLE_NO_SUGGESTION: return 'Verified. No move suggested.';
    case VERIFICATION_ICON_TYPE_LOCKED: return 'Verified. Suggestions disabled.';
  }
};

const getVerificationIcon = (record: EsRecord) => {
  const { verificationType, verifiedClusterId, currentClusterId, suggestedClusterId } = record;
  checkArg({ verificationType }, ArgTypes.orNull(VerificationType.argType));
  checkArg({ verifiedClusterId }, ArgTypes.orNull(ArgTypes.string));
  checkArg({ currentClusterId }, ArgTypes.string);
  checkArg({ suggestedClusterId }, ArgTypes.orNull(ArgTypes.string));
  const verificationIconType = (verificationType && currentClusterId)
    ? getVerificationIconType(verificationType, currentClusterId, suggestedClusterId, verifiedClusterId)
    : null;
  if (!verificationIconType) {
    return <noscript />;
  }
  return (
    <TooltipTrigger content={getVerificationIconTooltipMessage(verificationIconType)}>
      <VerificationIcon {...{ verificationIconType }} size={16} />
    </TooltipTrigger>
  );
};

export const renderVerificationColumn = (rows: List<EsRecord>) => {
  checkArg({ rows }, ArgTypes.Immutable.list.of(EsRecord.argType));
  const header = (
    <Cell className={styles.verificationColHeader}>
      <TooltipTrigger placement="top" content="Verification and suggestions">
        <TamrIcon iconName="check-circle" size={16} />
      </TooltipTrigger>
    </Cell>
  );
  const cell = ({ rowIndex }: { rowIndex: number }) => {
    const record = rows.get(rowIndex);
    return (
      <div className={styles.verificationColCell}>
        {record && getVerificationIcon(record)}
      </div>
    );
  };
  return (
    <Column key="verification" columnKey="verification" width={30} {...{ header, cell }} allowCellsRecycling />
  );
};
