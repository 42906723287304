import { is } from 'immutable';

import createLoader from '../utils/createLoader';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { fetchVerificationFilterTotals } from './SuppliersAsync';

const VerificationFilterTotalsLoader = createLoader(state => {
  const { suppliers: { recordsVerificationFilterTotals: { loading, fetchTriggers, loadedFetchTriggers } } } = state;
  return {
    canFetch: !!selectActiveProjectInfo(state)?.unifiedDatasetDoc,
    shouldFetch: !is(fetchTriggers, loadedFetchTriggers),
    loading,
  };
}, {
  onFetch: fetchVerificationFilterTotals,
}, 'VerificationFilterTotalsLoader');

export default VerificationFilterTotalsLoader;
