import './ErrorDialog.scss';

import classNames from 'classnames';
import React from 'react';

import Button from '../components/Button';
import { copyToClipboardFromDomElement } from '../utils/copyToClipboard';
import styles from './ErrorDialogBody.module.scss';


const DETAILS_TEXTAREA_CLASS = styles.advancedDetailBox;

const copyToClipboard = () => {
  copyToClipboardFromDomElement(() => document.getElementsByClassName(DETAILS_TEXTAREA_CLASS)[0]);
};

export const ErrorDialogBody: React.FC<{
  className?: string
  showMore: boolean
  detail: React.ReactNode
  advancedDetail?: string
  onToggleShowMore: () => void
  onCopy: () => void
  copied: boolean
}> = ({ className, showMore, detail, advancedDetail, onToggleShowMore, onCopy, copied }) => {
  return (
    <div className={className}>
      <div className="detail-description">An error came up while performing the last action:</div>
      <div className={styles.detailContent}>{detail}</div>
      {advancedDetail ? (
        <div className={styles.advancedDetailContent}>
          <div className={styles.toggleSection}>
            <div className={styles.advancedDetailTitle}>Details</div>
            {onToggleShowMore ? (
              <Button
                className={styles.toggleMoreLessButton}
                buttonType="Link"
                onClick={onToggleShowMore}
              >
                see more
              </Button>
            ) : null}
            {copied ? (
              <div className={styles.copyDetailsToClipboard}>
                <span>copied </span>
                <Button buttonType="Link" onClick={() => { copyToClipboard(); onCopy(); }}>
                  copy again
                </Button>
              </div>
            ) : (
              <div className={styles.copyDetailsToClipboard}>
                <Button buttonType="Link" onClick={() => { copyToClipboard(); onCopy(); }}>
                  copy
                </Button>
              </div>
            )}
          </div>
          <textarea
            className={classNames(DETAILS_TEXTAREA_CLASS, { [styles.showMore]: showMore })}
            defaultValue={advancedDetail.replace('\\n', '\n').replace('\\t', '\t')}
            readOnly
          />
        </div>
      ) : null}
    </div>
  );
};

export default ErrorDialogBody;
