import { is } from 'immutable';
import _ from 'underscore';

import createLoader from '../utils/createLoader';
import { fetchModule } from './GoldenRecordsAsync';
import { selectModuleFilterInfo } from './GoldenRecordsSelectors';


// loads modules and module status
const ModuleLoader = createLoader((state) => {
  const { location: { moduleId }, goldenRecords: { loadedModuleFilterInfo, loadingModule } } = state;
  return {
    canFetch: _.isNumber(moduleId),
    shouldFetch: !is(loadedModuleFilterInfo, selectModuleFilterInfo(state)),
    loading: loadingModule,
  };
}, {
  onFetch: fetchModule,
}, 'ModuleLoader');

export default ModuleLoader;
