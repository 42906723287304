import classNames from 'classnames';
import { Set } from 'immutable';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

// @ts-expect-error
import ReadMore from '../components/ReadMore';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import Voter, { Vote } from '../components/Voter';
import Response, { ResponseE } from '../constants/Response';
import { CategorizationSuggestion } from '../models/EsFeedback';
import EsRecord from '../models/EsRecord';
import MinimalAuthUser from '../models/MinimalAuthUser';
import ProcurementCategory from '../models/ProcurementCategory';
import ProcurementTaxonomy from '../models/ProcurementTaxonomy';
import { AppState } from '../stores/MainStore';
// @ts-expect-error
import CategoryTree from '../taxonomy/CategoryTree';
import { history } from '../utils/History';
import PRODUCT_NAME from '../utils/ProductName';
import { getAuthorizedUser } from '../utils/Selectors';
import style from './CategoryFeedbackSidebar.module.scss';
// @ts-expect-error
import { categorize, deleteCategorizations, respond } from './TransactionApi';


type CategoryFeedback = {
  username: string
  response: CategorizationSuggestion
}

const getCategoryFeedback = ({ record, category }: { record: EsRecord, category: ProcurementCategory }): Set<CategoryFeedback> => {
  let primaryCategorizations = Set<CategoryFeedback>();
  if (record.suggestedCategorization && record.suggestedCategorization.categoryId === category.categoryId) {
    primaryCategorizations = primaryCategorizations.add({
      username: `${PRODUCT_NAME}`,
      // @ts-expect-error
      response: {
        categoryId: record.suggestedCategorization.categoryId,
        categoryPath: category.path,
        created: new Date(record.suggestedCategorization.timestamp).getTime() * 1000,
      },
    });
  }

  return record.feedback.reduce((memo, feedback) => {
    const response = feedback.responses.find((r) => r.categoryId === category.categoryId);
    if (response) {
      return memo.add({ username: feedback.username, response });
    }
    return memo;
  }, primaryCategorizations).sortBy(response => response.response.created);
};

const CategorizedBySection: React.FC<{
  username: string
  timestamp: number
  reason?: string
  upvotedBy: Set<string>
  downvotedBy: Set<string>
}> = ({ username, timestamp, reason, upvotedBy, downvotedBy }) => (
  <div className={style.categorizedText}>
    <span className={style.categorizeBy}>Categorized by {username} {moment(timestamp).fromNow()}</span>
    <div className={style.categorizeReason}>
      <ReadMore>{reason || ''}</ReadMore>
    </div>
    {upvotedBy.size === 0 ? null : (
      <span className={style.categorizedAgreement}>{upvotedBy.join(', ')} upvoted this</span>
    )}
    {downvotedBy.size === 0 ? null : (
      <span className={style.categorizedDisagreement}>{downvotedBy.join(', ')} downvoted this</span>
    )}
  </div>
);

const getVoteForCurrentUserAndCategory = ({ authorizedUser, record, category }: {
  authorizedUser: MinimalAuthUser
  record: EsRecord
  category: ProcurementCategory
}): CategorizationSuggestion | undefined => {
  const feedback = record.feedbackForUser(authorizedUser.username);
  if (feedback) {
    return feedback.responses.find(response => response.categoryId === category.categoryId);
  }
};

const getIsActiveCurrent = ({ category, record }: {
  category: ProcurementCategory
  record: EsRecord
}) => {
  let suggestedCategoryId;
  if (record.manualCategorization) {
    suggestedCategoryId = record.manualCategorization.categoryId;
  } else if (record.suggestedCategorization) {
    suggestedCategoryId = record.suggestedCategorization.categoryId;
  }

  if (suggestedCategoryId) {
    return suggestedCategoryId === category.categoryId;
  }
};

const IsCurrentSection: React.FC<{
  isActiveCurrent: boolean
  isUserACurator: boolean
  isUserAVerifier: boolean
  isVerified: boolean
  onToggleVerify?: () => void
}> = ({ isActiveCurrent, isUserACurator, isUserAVerifier, isVerified, onToggleVerify }) => {
  const iconClass = (isActiveCurrent && isVerified) && style.activeAndCurrent;
  const iconType = isActiveCurrent ? style.filled : style.outlined;
  const tooltip = isActiveCurrent
    ? (isVerified ? 'This category has been verified. Click to unverify.' : `This category was suggested by ${PRODUCT_NAME}. ` + ((isUserACurator || isUserAVerifier) ? 'Click to verify this categorization' : ''))
    : 'This category has not been verified. ' + ((isUserACurator || isUserAVerifier) ? 'Click to verify this categorization' : '');

  return (
    <TooltipTrigger
      content={tooltip}
      placement="bottom"
    >
      <div
        className={classNames(style.makeActiveButton, iconClass, iconType, { [style.isClickable]: (isUserACurator || isUserAVerifier) })}
        onClick={onToggleVerify}
      >
        <TamrIcon
          iconName="tamr-icon-checkbox-check"
          size={16}
        />
      </div>
    </TooltipTrigger>
  );
};

const CategoryVoter: React.FC<{
  authorizedUser: MinimalAuthUser
  record: EsRecord
  category: ProcurementCategory
  onRespond: (arg: {
    record: EsRecord,
    categoryId: number
    response?: ResponseE
  }) => void
}> = ({ authorizedUser, record, category, onRespond }) => {
  const voteScore = record.numResponses(Response.AGREE, category.categoryId) - record.numResponses(Response.DISAGREE, category.categoryId);
  const userVote = getVoteForCurrentUserAndCategory({ authorizedUser, record, category });
  let currentVote;
  if (userVote) {
    switch (userVote.response) {
      case Response.AGREE:
        currentVote = Vote.UP;
        break;
      case Response.DISAGREE:
        currentVote = Vote.DOWN;
        break;
    }
  }
  return (
    <div className={style.voterContainer}>
      <Voter
        numVotes={voteScore}
        currentVote={currentVote}
        canVote
        onVoteCancel={() => onRespond({ record, categoryId: category.categoryId })}
        onVoteUp={() => onRespond({ record, categoryId: category.categoryId, response: Response.AGREE })}
        onVoteDown={() => onRespond({ record, categoryId: category.categoryId, response: Response.DISAGREE })}
      />
    </div>
  );
};

type CategoryFeedbackSidebarOwnProps = {
  category: ProcurementCategory | undefined
  className?: string
  isSystemCategorization?: boolean
  isUserACurator: boolean
  isUserAVerifier: boolean
  record: EsRecord
  taxonomy: ProcurementTaxonomy | undefined
}

/**
 * Component used to show up/down votes next to categorizations in the spend sidebar
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CategoryFeedbackSidebar = connect((state: AppState, ownProps: CategoryFeedbackSidebarOwnProps) => {
  const { location: { recipeId } } = state;
  return {
    authorizedUser: getAuthorizedUser(state),
    onSelectCategory: (categoryId: number) => history.push(`/category/recipe/${recipeId}/${categoryId}`),
    recipeId,
  };
}, {
  onCategorize: categorize,
  onRespond: respond,
  onDeleteCategorizations: deleteCategorizations,
})(({ authorizedUser, category, isSystemCategorization, isUserACurator, isUserAVerifier, onCategorize, onDeleteCategorizations, onRespond, onSelectCategory, record, taxonomy }) => {
  if (!category || !authorizedUser) {
    return <div />;
  }
  const categoryFeedback = getCategoryFeedback({ record, category });
  const firstFeedback: CategoryFeedback | undefined = categoryFeedback.isEmpty() ? undefined : categoryFeedback.first();
  return (
    <div className={style.sidebarCategoryContainer}>
      <CategoryVoter
        authorizedUser={authorizedUser}
        record={record}
        category={category}
        onRespond={onRespond}
      />
      <CategoryTree
        taxonomy={taxonomy}
        onSidebarCategoryClick={onSelectCategory}
        category={category}
      />
      {!(isUserACurator || isUserAVerifier) && !(getIsActiveCurrent({ category, record }) && record.manualCategorization) ? null : (
        <IsCurrentSection
          isActiveCurrent={getIsActiveCurrent({ category, record }) || false}
          isUserACurator={isUserACurator}
          isUserAVerifier={isUserAVerifier}
          isVerified={!!record.manualCategorization}
          onToggleVerify={!(isUserACurator || isUserAVerifier) ? undefined :
            ((!!record.manualCategorization && record.manualCategorization.categoryId === category.categoryId)
              ? () => onDeleteCategorizations(Set.of(record))
              : () => onCategorize({ records: Set.of(record), categoryId: category.categoryId, reason: (getVoteForCurrentUserAndCategory({ authorizedUser, record, category }) || {}).reason }))
          }
        />
      )}
      {firstFeedback && (
        <CategorizedBySection
          username={isSystemCategorization ? `${PRODUCT_NAME}` : firstFeedback.username}
          timestamp={isSystemCategorization ? record.suggestedCategorization.timestamp * 1000 : firstFeedback.response.created}
          reason={isSystemCategorization ? '' : firstFeedback.response.reason || undefined}
          upvotedBy={categoryFeedback.filter(r => r.response.response === Response.AGREE).sortBy(r => r.response.created).map(r => r.username)}
          downvotedBy={categoryFeedback.filter(r => r.response.response === Response.DISAGREE).sortBy(r => r.response.created).map(r => r.username)}
        />
      )}
    </div>
  );
});

export default CategoryFeedbackSidebar;
