import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import style from '../dashboard/CategorizationDashboardGuide.module.scss';
import { history } from '../utils/History';
import { getUnifiedDatasetId } from '../utils/Selectors';
import TaxonomyUploadDialog from './TaxonomyUploadDialog';

const BlankTaxonomyPage = _.compose(
  connect((state) => {
    const { taxonomyUpload: { fileName, isLoading, selectedFile, showDialog }, location: { recipeId } } = state;
    const unifiedDatasetId = getUnifiedDatasetId(state);
    return { fileName, isLoading, selectedFile, showDialog, recipeId, unifiedDatasetId };
  }, {
    onShowTaxonomyUploadDialog: () => ({ type: 'TaxonomyUpload.showDialog' }),
  }),
)(class BlankTaxonomyPage extends React.Component {
  static propTypes = {
    onShowTaxonomyUploadDialog: PropTypes.func.isRequired,
    recipeId: PropTypes.number, // // if missing we aren't in the context of a project
    unifiedDatasetId: PropTypes.number, // if missing, redirect the user to schema mapping page
  };

  render() {
    if (!_.isNumber(this.props.recipeId)) {
      return <div />;
    }
    if (!_.isNumber(this.props.unifiedDatasetId)) {
      return (
        <div className="blank-taxonomy-page">
          <div className="center-box">
            <div className="description">
              You cannot add categories until you create a unified dataset.
            </div>
            <div className={style.button}>
              <Button onClick={() => history.push(`/schema-mapping/recipe/${this.props.recipeId}`)}>
                Create Unified Dataset
              </Button>
            </div>
          </div>
          <TaxonomyUploadDialog />
        </div>
      );
    }
    return (
      <div className="blank-taxonomy-page">
        <div className="center-box">
          <div className="description">
            You currently have no categories loaded in your Taxonomy.
          </div>
          <Button
            onClick={this.props.onShowTaxonomyUploadDialog}
          >
            Upload
          </Button>
        </div>
        <TaxonomyUploadDialog />
      </div>
    );
  }
});

export default BlankTaxonomyPage;
