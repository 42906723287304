import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import { AppState } from '../stores/MainStore';


const PreviewTooLargeDialog = connect((state: AppState) => {
  const { goldenRecordsRules: { showPreviewTooLargeDialog } } = state;
  return { show: showPreviewTooLargeDialog };
}, {
  onHide: () => ({ type: 'GoldenRecords.showPreviewTooLargeDialog', showPreviewTooLargeDialog: false }),
})(({ show, onHide }) => {
  return (
    <Dialog
      dialogStyle={DialogStyle.PRIMARY}
      show={show}
      onHide={onHide}
      title="Cannot Preview Bookmarks"
      body={(
        <span>
          The entities you are previewing exceed the defined record limit. Please choose smaller clusters to preview, or contact your admin to change the limit.
        </span>
      )}
      footer={
        <ButtonToolbar>
          <Button key="hide" buttonType="Secondary" onClick={onHide}>Okay</Button>
        </ButtonToolbar>
      }
    />
  );
});

export default PreviewTooLargeDialog;
