import { List, Set } from 'immutable';

import { ClusterChangesE } from '../constants/ClusterChanges';
import { ClusterRecordChangesValueType } from '../constants/ClusterRecordChanges';
import { ConfidenceRangeE } from '../constants/ConfidenceRange';
import Cluster from '../models/Cluster';
import ConfidenceFilter from '../models/ConfidenceFilter';
import DatasetStatus from '../models/DatasetStatus';
import EsRecord from '../models/EsRecord';
import KeyMods from '../models/KeyMods';
import { $TSFixMe } from '../utils/typescript';
import ClusterMember from './ClusterMember';
import ClusterSimilarity from './ClusterSimilarity';
import { ClustersSortE } from './ClustersSort';
import { PaneE } from './Pane';
import PublishedClusterVersions from './PublishedClusterVersions';
import { ClusterFilterInfo, RecordsFilterInfo } from './SuppliersStore';
import { VerificationFilterTotals, VerificationFilterTotalsFetchTriggers } from './VerificationFilters';
import { VerificationTypeE } from './VerificationType';


export const SET_SUPPLIERS_FILTER = 'Suppliers.setSuppliersFilter';
export const RELOAD_BOTH = 'Suppliers.reloadBoth';
export const CLUSTERS_HAVE_CHANGED = 'Suppliers.clustersHaveChanged';
export const SET_QUERY_STRING = 'Suppliers.setQueryString';
export const RELOAD = 'Suppliers.reload';
export const SET_SUPPLIERS_PAGE = 'Suppliers.setSuppliersPage';
export const CLEAR_SUPPLIERS_FILTER = 'Suppliers.clearSuppliersFilter';
export const SELECT_ALL_SUPPLIER_ROWS = 'Suppliers.selectAllSupplierRows';
export const SELECT_SUPPLIER_ROW = 'Suppliers.selectSupplierRow';
export const START_DRAG_CLUSTER = 'Suppliers.startDragCluster';
export const SET_SELECTED_SOURCE_DATASETS = 'Suppliers.setSelectedSourceDatasets';
export const SET_SOURCE_DATASETS_DIALOG_VISIBLE = 'Suppliers.setSourceDatasetsDialogVisible';
export const TOGGLE_FILTER_UNRESOLVED = 'Suppliers.toggleFilterUnresolved';
export const TOGGLE_FILTER_RESOLVED = 'Suppliers.toggleFilterResolved';
export const TOGGLE_CONFIDENCE_FILTER = 'Suppliers.toggleConfidenceFilter';
export const SET_CLUSTER_CHANGES_FILTER = 'Suppliers.setClusterChangesFilter';
export const TOGGLE_CLUSTER_CHANGES_FILTER = 'Suppliers.toggleClusterChangesFilter';
export const TOGGLE_WITH_CHANGES_FILTER = 'Suppliers.toggleWithChangesFilter';
export const SET_MY_ASSIGNMENTS = 'Suppliers.setMyAssignments';
export const SET_CLUSTERS_WITH_CHANGES = 'Suppliers.setClustersWithChanges';
export const TOGGLE_CUSTOM_CONFIDENCE_FILTER = 'Suppliers.toggleCustomConfidenceFilter';
export const SET_CUSTOM_CONFIDENCE_FILTER = 'Suppliers.setCustomConfidenceFilter';
export const TOGGLE_CUSTOM_SIMILARITY_FILTER = 'Suppliers.toggleCustomSimilarityFilter';
export const SET_CUSTOM_SIMILARITY_FILTER = 'Suppliers.setCustomSimilarityFilter';
export const CLEAR_FILTERS = 'Suppliers.clearFilters';
export const CLEAR_RECORD_FILTERS = 'Suppliers.clearRecordFilters';
export const FETCH_SUPPLIERS = 'Suppliers.fetchSuppliers';
export const FETCH_SUPPLIERS_COMPLETED = 'Suppliers.fetchSuppliersCompleted';
export const FETCH_SUPPLIERS_FAILED = 'Suppliers.fetchSuppliersFailed';
export const BEGIN_CONFIRMING_MERGE_CLUSTERS = 'Suppliers.beginConfirmingMergeClusters';
export const BEGIN_CONFIRMING_MERGE_SELECTED_CLUSTERS_TO_TARGET = 'Suppliers.beginConfirmingMergeSelectedClustersToTarget';
export const BEGIN_CONFIRMING_MERGE_CLUSTERS_TO_TARGET = 'Suppliers.beginConfirmingMergeClustersToTarget';
export const CANCEL_VERIFICATION_ACTION = 'Suppliers.cancelVerificationAction';
export const MERGE_CLUSTERS = 'Suppliers.mergeClusters';
export const MERGE_CLUSTERS_COMPLETED = 'Suppliers.mergeClustersCompleted';
export const MERGE_CLUSTERS_FAILED = 'Suppliers.mergeClustersFailed';
export const VERIFY_CLUSTERS = 'Suppliers.verifyClusters';
export const VERIFY_CLUSTERS_COMPLETED = 'Suppliers.verifyClustersCompleted';
export const VERIFY_CLUSTERS_FAILED = 'Suppliers.verifyClustersFailed';
export const OPEN_ASSIGN_DIALOG = 'Suppliers.openAssignDialog';
export const CLOSE_ASSIGN_DIALOG = 'Suppliers.closeAssignDialog';
export const ASSIGN_CLUSTERS = 'Suppliers.assignClusters';
export const ASSIGN_CLUSTERS_COMPLETED = 'Suppliers.assignClustersCompleted';
export const ASSIGN_CLUSTERS_FAILED = 'Suppliers.assignClustersFailed';
export const RESOLVE_CLUSTERS = 'Suppliers.resolveClusters';
export const RESOLVE_CLUSTERS_COMPLETED = 'Suppliers.resolveClustersCompleted';
export const RESOLVE_CLUSTERS_FAILED = 'Suppliers.resolveClustersFailed';
export const BEGIN_CONFIRMING_ACCEPT_SUGGESTION = 'Suppliers.beginConfirmingAcceptSuggestion';
export const BEGIN_CONFIRMING_MOVE_RECORDS = 'Suppliers.beginConfirmingMoveRecords';
export const MOVE_RECORDS = 'Suppliers.moveRecords';
export const MOVE_RECORDS_COMPLETED = 'Suppliers.moveRecordsCompleted';
export const MOVE_RECORDS_FAILED = 'Suppliers.moveRecordsFailed';
export const BEGIN_CONFIRMING_MOVE_RECORDS_TO_NEW = 'Suppliers.beginConfirmingMoveRecordsToNew';
export const MOVE_RECORDS_TO_NEW = 'Suppliers.moveRecordsToNew';
export const MOVE_RECORDS_TO_NEW_COMPLETED = 'Suppliers.moveRecordsToNewCompleted';
export const MOVE_RECORDS_TO_NEW_FAILED = 'Suppliers.moveRecordsToNewFailed';
export const VERIFY_RECORDS = 'Suppliers.verifyRecords';
export const VERIFY_RECORDS_COMPLETED = 'Suppliers.verifyRecordsCompleted';
export const FETCH_SIMILAR_SUPPLIERS = 'Suppliers.fetchSimilarSuppliers';
export const FETCH_SIMILAR_SUPPLIERS_COMPLETED = 'Suppliers.fetchSimilarSuppliersCompleted';
export const FETCH_SIMILAR_SUPPLIERS_FAILED = 'Suppliers.fetchSimilarSuppliersFailed';
export const CONFLICT_ERROR = 'Suppliers.conflictError';
export const HIDE_CONFLICT_ERROR = 'Suppliers.hideConflictError';
export const RELOAD_CONFLICT_ERROR = 'Suppliers.reloadConflictError';
export const SET_RECORDS_SELECTED_SOURCE_DATASETS = 'Suppliers.setRecordsSelectedSourceDatasets';
export const SET_RECORDS_SOURCE_DATASETS_DIALOG_VISIBLE = 'Suppliers.setRecordsSourceDatasetsDialogVisible';
export const FETCH_TRANSACTIONS = 'Suppliers.fetchTransactions';
export const FETCH_TRANSACTIONS_COMPLETED = 'Suppliers.fetchTransactionsCompleted';
export const FETCH_TRANSACTIONS_FAILED = 'Suppliers.fetchTransactionsFailed';
export const FETCH_TRANSACTIONS_BY_BOUNDS_COMPLETED = 'Suppliers.fetchTransactionsByBoundsCompleted';
export const CLEAR_SELECTED_GEO_ROWS = 'Suppliers.clearSelectedGeoRows';
export const FETCH_INITIAL_GEOSPATIAL_TRANSACTIONS = 'Suppliers.fetchInitialGeospatialTransactions';
export const FETCH_INITIAL_GEOSPATIAL_TRANSACTIONS_COMPLETED = 'Suppliers.fetchInitialGeospatialTransactionsCompleted';
export const FETCH_INITIAL_GEOSPATIAL_TRANSACTIONS_FAILED = 'Suppliers.fetchInitialGeospatialTransactionsFailed';
export const FETCH_GEOSPATIAL_TRANSACTIONS = 'Suppliers.fetchGeospatialTransactions';
export const FETCH_GEOSPATIAL_TRANSACTIONS_COMPLETED = 'Suppliers.fetchGeospatialTransactionsCompleted';
export const FETCH_GEOSPATIAL_TRANSACTIONS_FAILED = 'Suppliers.fetchGeospatialTransactionsFailed';
export const FETCH_ADJACENT_GEOSPATIAL_TRANSACTIONS = 'Suppliers.fetchAdjacentGeospatialTransactions';
export const FETCH_ADJACENT_GEOSPATIAL_TRANSACTIONS_COMPLETED = 'Suppliers.fetchAdjacentGeospatialTransactionsCompleted';
export const FETCH_ADJACENT_GEOSPATIAL_TRANSACTIONS_FAILED = 'Suppliers.fetchAdjacentGeospatialTransactionsFailed';
export const UPDATE_GEOSPATIAL_BOUNDS = 'Suppliers.updateGeospatialBounds';
export const SET_ACTIVE_ROW_NUMBER = 'Suppliers.setActiveRowNumber';
export const SELECT_RECORD_ROW = 'Suppliers.selectRecordRow';
export const SELECT_ALL_RECORD_ROWS = 'Suppliers.selectAllRecordRows';
export const START_DRAG_RECORD = 'Suppliers.startDragRecord';
export const SHOW_SIDEBAR_TAB = 'Suppliers.showSidebarTab';
export const SET_PAGE = 'Suppliers.setPage';
export const SET_PAGE_SIZE = 'Suppliers.setPageSize';
export const TOGGLE_SORT = 'Suppliers.toggleSort';
export const PIN_RECORDS = 'Suppliers.pinRecords';
export const UNPIN_RECORDS = 'Suppliers.unpinRecords';
export const TOGGLE_VERIFIED_FILTER = 'Suppliers.toggleVerifiedFilter';
export const TOGGLE_VERIFIED_IN_CURRENT_CLUSTER = 'Suppliers.toggleVerifiedInCurrentCluster';
export const TOGGLE_VERIFIED_IN_ANOTHER_CLUSTER = 'Suppliers.toggleVerifiedInAnotherCluster';
export const TOGGLE_NOT_VERIFIED_FILTER = 'Suppliers.toggleNotVerifiedFilter';
export const TOGGLE_SUGGESTIONS_ENABLED = 'Suppliers.toggleSuggestionsEnabled';
export const TOGGLE_MOVE_SUGGESTED = 'Suppliers.toggleMoveSuggested';
export const TOGGLE_NO_MOVE_SUGGESTED = 'Suppliers.toggleNoMoveSuggested';
export const TOGGLE_SUGGESTIONS_DISABLED = 'Suppliers.toggleSuggestionsDisabled';
export const TOGGLE_SUGGESTIONS_AUTO_ACCEPTED = 'Suppliers.toggleSuggestionsAutoAccepted';
export const TOGGLE_CLUSTER_HAS_VERIFIED_HERE_FILTER = 'Suppliers.toggleClusterHasVerifiedHereFilter';
export const TOGGLE_CLUSTER_HAS_VERIFIED_ELSEWHERE_FILTER = 'Suppliers.toggleClusterHasVerifiedElsewhereFilter';
export const TOGGLE_CLUSTER_HAS_MOVE_SUGGESTED_FILTER = 'Suppliers.toggleClusterHasMoveSuggestedFilter';
export const TOGGLE_CLUSTER_HAS_NONE_VERIFIED_FILTER = 'Suppliers.toggleClusterHasNoneVerifiedFilter';
export const TOGGLE_FILTER_HAS_COMMENTS = 'Suppliers.toggleFilterHasComments';
export const TOGGLE_RECORD_CHANGES_FILTER = 'Suppliers.toggleRecordChangesFilter';
export const TOGGLE_SHOW_GEOSPATIAL_OVERLAY = 'Suppliers.toggleShowGeospatialOverlay';
export const SET_RECORD_CHANGES_FILTER = 'Suppliers.setRecordChangesFilter';
export const TOGGLE_EXPAND_SIDEBAR = 'Suppliers.toggleExpandSidebar';
export const OPEN_COMMENT_FORM = 'Suppliers.openCommentForm';
export const COMMENT_COMPLETED = 'Suppliers.commentCompleted';
export const COMMENT_FAILED = 'Suppliers.commentFailed';
export const EDIT_COMMENT = 'Suppliers.editComment';
export const EDIT_COMMENT_COMPLETED = 'Suppliers.editCommentCompleted';
export const EDIT_COMMENT_FAILED = 'Suppliers.editCommentFailed';
export const DELETE_COMMENT = 'Suppliers.deleteComment';
export const DELETE_COMMENT_COMPLETED = 'Suppliers.deleteCommentCompleted';
export const DELETE_COMMENT_FAILED = 'Suppliers.deleteCommentFailed';
export const FETCH_SUPPLIERS_UPDATE = 'Suppliers.fetchSuppliersUpdate';
export const FETCH_SUPPLIERS_UPDATE_COMPLETED = 'Suppliers.fetchSuppliersUpdateCompleted';
export const FETCH_TOTAL_TEST_CLUSTERS_VERIFIED_COMPLETED = 'Suppliers.fetchTotalTestClustersVerifiedCompleted';
export const FETCH_SUPPLIERS_UPDATE_FAILED = 'Suppliers.fetchSuppliersUpdateFailed';
export const OPEN_MAP = 'Suppliers.openMap';
export const CLOSE_MAP = 'Suppliers.closeMap';
export const OPEN_CLUSTER_BROWSER = 'Suppliers.openClusterBrowser';
export const CLOSE_CLUSTER_BROWSER = 'Suppliers.closeClusterBrowser';
export const SET_CLUSTERS_SORT = 'Suppliers.setClustersSort';
export const SET_RECORDS_GRAPH_TIME_PERIOD = 'Suppliers.setRecordsGraphTimePeriod';
export const FETCH_CLUSTER_PUBLISH_INFO = 'Suppliers.fetchClusterPublishInfo';
export const FETCH_CLUSTER_PUBLISH_INFO_COMPLETED = 'Suppliers.fetchClusterPublishInfoCompleted';
export const FETCH_CLUSTER_PUBLISH_INFO_FAILED = 'Suppliers.fetchClusterPublishInfoFailed';
export const TOGGLE_CLUSTER_DIFF_VISIBLE = 'Suppliers.toggleClusterDiffVisible';
export const FETCH_ACTIVE_RECORD_CLUSTER_MEMBERSHIP = 'Suppliers.fetchActiveRecordClusterMembership';
export const FETCH_ACTIVE_RECORD_CLUSTER_MEMBERSHIP_COMPLETED = 'Suppliers.fetchActiveRecordClusterMembershipCompleted';
export const FETCH_ACTIVE_RECORD_CLUSTER_MEMBERSHIP_FAILED = 'Suppliers.fetchActiveRecordClusterMembershipFailed';
export const OPEN_GEOSPATIAL_DETAILS_SIDEBAR = 'Suppliers.openGeospatialDetailsSidebar';
export const CLOSE_GEOSPATIAL_DETAILS_SIDEBAR = 'Suppliers.closeGeospatialDetailsSidebar';
export const OPEN_GEOSPATIAL_DETAILS_DIALOG = 'Suppliers.openGeospatialDetailsDialog';
export const CLOSE_GEOSPATIAL_DETAILS_DIALOG = 'Suppliers.closeGeospatialDetailsDialog';
export const FETCH_VERIFICATION_FILTER_TOTALS = 'Suppliers.fetchVerificationFilterTotals';
export const FETCH_VERIFICATION_FILTER_TOTALS_COMPLETED = 'Suppliers.fetchVerificationFilterTotalsCompleted';
export const FETCH_VERIFICATION_FILTER_TOTALS_FAILED = 'Suppliers.fetchVerificationFilterTotalsFailed';
export const FETCH_PUBLISHED_CLUSTERS_DATASET_STATUS = 'Suppliers.fetchPublishedClustersDatasetStatus';
export const FETCH_PUBLISHED_CLUSTERS_DATASET_STATUS_COMPLETED = 'Suppliers.fetchPublishedClustersDatasetStatusCompleted';
export const FETCH_PUBLISHED_CLUSTERS_DATASET_STATUS_FAILED = 'Suppliers.fetchPublishedClustersDatasetStatusFailed';
export const TOGGLE_CLUSTER_HIGH_IMPACT_FILTER = 'Suppliers.toggleClusterHighImpactFilter';

interface SuppliersReloadBoth {
  type: typeof RELOAD_BOTH
}

interface SuppliersClustersHaveChanged {
  type: typeof CLUSTERS_HAVE_CHANGED
}

interface SuppliersSetQueryString {
  type: typeof SET_QUERY_STRING
  queryString: string
  pane: PaneE
}

interface SuppliersReload {
  type: typeof RELOAD
  pane: PaneE
}

interface SuppliersSetSuppliersPage {
  type: typeof SET_SUPPLIERS_PAGE
  pageNum: number
  pane: PaneE
}

interface SuppliersSetSuppliersFilter {
  type: typeof SET_SUPPLIERS_FILTER
  supplierId: string
  pane: PaneE
}

interface SuppliersClearSuppliersFilter {
  type: typeof CLEAR_SUPPLIERS_FILTER
  pane: PaneE
}

interface SuppliersSelectAllSupplierRows {
  type: typeof SELECT_ALL_SUPPLIER_ROWS
  pane: PaneE
}

interface SuppliersSelectSupplierRow {
  type: typeof SELECT_SUPPLIER_ROW
  keyMods: KeyMods
  selectedSupplierRow: number
  pane: PaneE
}

interface SuppliersStartDragCluster {
  type: typeof START_DRAG_CLUSTER
  clusterId: string
  pane: PaneE
}

interface SuppliersSetSelectedSourceDatasets {
  type: typeof SET_SELECTED_SOURCE_DATASETS
  datasetsToAdd: Set<string>
  datasetsToRemove: Set<string>
  pane: PaneE
}

interface SuppliersSetSourceDatasetsDialogVisible {
  type: typeof SET_SOURCE_DATASETS_DIALOG_VISIBLE
  show: boolean
  pane: PaneE
}

interface SuppliersToggleFilterUnresolved {
  type: typeof TOGGLE_FILTER_UNRESOLVED
  pane: PaneE
}

interface SuppliersToggleFilterResolved {
  type: typeof TOGGLE_FILTER_RESOLVED
  pane: PaneE
}

interface SuppliersToggleConfidenceFilter {
  type: typeof TOGGLE_CONFIDENCE_FILTER
  filterOption: ConfidenceRangeE
  pane: PaneE
}

interface SuppliersSetClusterChangesFilter {
  type: typeof SET_CLUSTER_CHANGES_FILTER
  clusterChanges: Set<ClusterChangesE>
  clear: boolean
  pane: PaneE
}

interface SuppliersToggleClusterChangesFilter {
  type: typeof TOGGLE_CLUSTER_CHANGES_FILTER
  filterOption: ClusterChangesE
  pane: PaneE
}

interface SuppliersToggleWithChangesFilter {
  type: typeof TOGGLE_WITH_CHANGES_FILTER
  pane: PaneE
}

interface SuppliersSetMyAssignments {
  type: typeof SET_MY_ASSIGNMENTS
  pane: PaneE
}

interface SuppliersSetClustersWithChanges {
  type: typeof SET_CLUSTERS_WITH_CHANGES
  pane: PaneE
}

interface SuppliersToggleCustomConfidenceFilter {
  type: typeof TOGGLE_CUSTOM_CONFIDENCE_FILTER
  value: boolean
  pane: PaneE
}

interface SuppliersSetCustomConfidenceFilter {
  type: typeof SET_CUSTOM_CONFIDENCE_FILTER
  customConfidenceFilter: ConfidenceFilter
  pane: PaneE
}

interface SuppliersToggleCustomSimilarityFilter {
  type: typeof TOGGLE_CUSTOM_SIMILARITY_FILTER
  value: boolean
  pane: PaneE
}

interface SuppliersSetCustomSimilarityFilter {
  type: typeof SET_CUSTOM_SIMILARITY_FILTER
  customSimilarityFilter: number
  pane: PaneE
}

interface SuppliersClearFilters {
  type: typeof CLEAR_FILTERS
  pane: PaneE
}

interface SuppliersClearRecordFilters {
  type: typeof CLEAR_RECORD_FILTERS
  pane: PaneE
  clearSearch?: boolean
}

interface SuppliersFetchSuppliers {
  type: typeof FETCH_SUPPLIERS
  pane: PaneE
}

interface SuppliersFetchSuppliersCompleted {
  type: typeof FETCH_SUPPLIERS_COMPLETED
  clusters: List<Cluster>
  total: number
  totalSuppliers: number
  filterInfo: ClusterFilterInfo
  pane: PaneE
}

interface SuppliersFetchSuppliersFailed {
  type: typeof FETCH_SUPPLIERS_FAILED
  filterInfo: ClusterFilterInfo
  pane: PaneE
}

interface SuppliersBeginConfirmingMergeClusters {
  type: typeof BEGIN_CONFIRMING_MERGE_CLUSTERS
  clusters: List<Cluster>
  pane: PaneE
}

interface SuppliersBeginConfirmingMergeSelectedClustersToTarget {
  type: typeof BEGIN_CONFIRMING_MERGE_SELECTED_CLUSTERS_TO_TARGET
  targetClusterId: string
  pane: PaneE
  fromPane: PaneE
}

interface SuppliersBeginConfirmingMergeClustersToTarget {
  type: typeof BEGIN_CONFIRMING_MERGE_CLUSTERS_TO_TARGET
  clusters: List<Cluster>
  targetCluster: Cluster
  pane: PaneE
  fromPane: PaneE
}

interface SuppliersCancelVerificationAction {
  type: typeof CANCEL_VERIFICATION_ACTION
  pane: PaneE
}

interface SuppliersMergeClusters {
  type: typeof MERGE_CLUSTERS
  pane: PaneE
}

interface SuppliersMergeClustersCompleted {
  type: typeof MERGE_CLUSTERS_COMPLETED
  mergeClusterId: string
  numMerged: number
  pane: PaneE
  verificationType: VerificationTypeE
}

interface SuppliersMergeClustersFailed {
  type: typeof MERGE_CLUSTERS_FAILED
  clusterIds: Set<string>
  pane: PaneE
}

interface SuppliersVerifyClusters {
  type: typeof VERIFY_CLUSTERS
  pane: PaneE
}

interface SuppliersVerifyClustersCompleted {
  type: typeof VERIFY_CLUSTERS_COMPLETED
  pane: PaneE
}

interface SuppliersVerifyClustersFailed {
  type: typeof VERIFY_CLUSTERS_FAILED
  pane: PaneE
}

interface SuppliersOpenAssignDialog {
  type: typeof OPEN_ASSIGN_DIALOG
  pane: PaneE
}

interface SuppliersCloseAssignDialog {
  type: typeof CLOSE_ASSIGN_DIALOG
  pane: PaneE
}

interface SuppliersAssignClusters {
  type: typeof ASSIGN_CLUSTERS
  pane: PaneE
}

interface SuppliersAssignClustersCompleted {
  type: typeof ASSIGN_CLUSTERS_COMPLETED
  pane: PaneE
}

interface SuppliersAssignClustersFailed {
  type: typeof ASSIGN_CLUSTERS_FAILED
  pane: PaneE
}

interface SuppliersResolveClusters {
  type: typeof RESOLVE_CLUSTERS
  pane: PaneE
}

interface SuppliersResolveClustersCompleted {
  type: typeof RESOLVE_CLUSTERS_COMPLETED
  pane: PaneE
}

interface SuppliersResolveClustersFailed {
  type: typeof RESOLVE_CLUSTERS_FAILED
  pane: PaneE
}

export interface SuppliersBeginConfirmingAcceptSuggestion {
  type: typeof BEGIN_CONFIRMING_ACCEPT_SUGGESTION
  pane: PaneE
  record: EsRecord
  clusterId: string
}

interface SuppliersBeginConfirmingMoveRecords {
  type: typeof BEGIN_CONFIRMING_MOVE_RECORDS
  pane: PaneE
  fromPane: PaneE
  clusterId: string
}

interface SuppliersMoveRecords {
  type: typeof MOVE_RECORDS
  pane: PaneE
}

interface SuppliersMoveRecordsCompleted {
  type: typeof MOVE_RECORDS_COMPLETED
  numRecords: number
  targetClusterId: string
  targetClusterName: string
  pane: PaneE
  verificationType: VerificationTypeE
}

interface SuppliersMoveRecordsFailed {
  type: typeof MOVE_RECORDS_FAILED
  pane: PaneE
}

interface SuppliersBeginConfirmingMoveRecordsToNew {
  type: typeof BEGIN_CONFIRMING_MOVE_RECORDS_TO_NEW
  pane: PaneE
}

interface SuppliersMoveRecordsToNew {
  type: typeof MOVE_RECORDS_TO_NEW
  pane: PaneE
}

interface SuppliersMoveRecordsToNewCompleted {
  type: typeof MOVE_RECORDS_TO_NEW_COMPLETED
  numRecords: number
  targetClusterId: string
  pane: PaneE
  verificationType: VerificationTypeE
}

interface SuppliersMoveRecordsToNewFailed {
  type: typeof MOVE_RECORDS_TO_NEW_FAILED
  pane: PaneE
}

interface SuppliersVerifyRecords {
  type: typeof VERIFY_RECORDS
  pane: PaneE
}

interface SuppliersVerifyRecordsCompleted {
  type: typeof VERIFY_RECORDS_COMPLETED
  pane: PaneE
}

interface SuppliersFetchSimilarSuppliers {
  type: typeof FETCH_SIMILAR_SUPPLIERS
  pane: PaneE
}

interface SuppliersFetchSimilarSuppliersCompleted {
  type: typeof FETCH_SIMILAR_SUPPLIERS_COMPLETED
  pane: PaneE
  supplierId: string
  similarSuppliers: List<ClusterSimilarity>
}

interface SuppliersFetchSimilarSuppliersFailed {
  type: typeof FETCH_SIMILAR_SUPPLIERS_FAILED
  pane: PaneE
  supplierId: string
}

interface SuppliersConflictError {
  type: typeof CONFLICT_ERROR
}

interface SuppliersHideConflictError {
  type: typeof HIDE_CONFLICT_ERROR
}

interface SuppliersReloadConflictError {
  type: typeof RELOAD_CONFLICT_ERROR
}

interface SuppliersSetRecordsSelectedSourceDatasets {
  type: typeof SET_RECORDS_SELECTED_SOURCE_DATASETS
  datasetsToAdd: Set<string>
  datasetsToRemove: Set<string>
  pane: PaneE
}

interface SuppliersSetRecordsSourceDatasetsDialogVisible {
  type: typeof SET_RECORDS_SOURCE_DATASETS_DIALOG_VISIBLE
  show: boolean
  pane: PaneE
}

interface SuppliersFetchTransactions {
  type: typeof FETCH_TRANSACTIONS
  pane: PaneE
}

interface SuppliersFetchTransactionsCompleted {
  type: typeof FETCH_TRANSACTIONS_COMPLETED
  rows: List<EsRecord>
  total: number
  totalSupplierRecords: number
  filterInfo: RecordsFilterInfo
  activeGeospatialRenderingAttribute: $TSFixMe
  pane: PaneE
}

interface SuppliersFetchTransactionsFailed {
  type: typeof FETCH_TRANSACTIONS_FAILED
  filterInfo: RecordsFilterInfo
  pane: PaneE
}

interface SuppliersFetchTransactionsByBoundsCompleted {
  type: typeof FETCH_TRANSACTIONS_BY_BOUNDS_COMPLETED
  selectedGeoRows: List<EsRecord>
}

interface SuppliersClearSelectedGeoRows {
  type: typeof CLEAR_SELECTED_GEO_ROWS
}

interface SuppliersFetchInitialGeospatialTransactions {
  type: typeof FETCH_INITIAL_GEOSPATIAL_TRANSACTIONS
}

interface SuppliersFetchInitialGeospatialTransactionsCompleted {
  type: typeof FETCH_INITIAL_GEOSPATIAL_TRANSACTIONS_COMPLETED
  activeClusterId: string,
  activeClusterName: string | undefined,
  activeGeospatialRenderingAttribute: string | undefined,
  geoRows: List<EsRecord>,
  totalGeoFeatures: number,
  initialTotalGeoFeatures: number,
  initialBounds: number[][],
  initialCenter: number[],
  currentBounds: number[][],
  loadedBounds: number[][],
  loadingRows: false,
}

interface SuppliersFetchInitialGeospatialTransactionsFailed {
  type: typeof FETCH_INITIAL_GEOSPATIAL_TRANSACTIONS_FAILED
}

interface SuppliersFetchGeospatialTransactions {
  type: typeof FETCH_GEOSPATIAL_TRANSACTIONS
}

interface SuppliersFetchGeospatialTransactionsCompleted {
  type: typeof FETCH_GEOSPATIAL_TRANSACTIONS_COMPLETED
  geoRows: List<EsRecord>
  totalGeoFeatures: number
  loadedBounds: number[][]
  loadingBounds: boolean
}

interface SuppliersFetchGeospatialTransactionsFailed {
  type: typeof FETCH_GEOSPATIAL_TRANSACTIONS_FAILED
}

interface SuppliersFetchAdjacentGeospatialTransactions {
  type: typeof FETCH_ADJACENT_GEOSPATIAL_TRANSACTIONS
}

interface SuppliersFetchAdjacentGeospatialTransactionsCompleted {
  type: typeof FETCH_ADJACENT_GEOSPATIAL_TRANSACTIONS_COMPLETED
  adjacentGeoRows: List<EsRecord>,
  totalAdjacentGeoFeatures: number,
  loadedAdjacentBounds: number[][],
  loadingAdjacentRows: boolean,
}

interface SuppliersFetchAdjacentGeospatialTransactionsFailed {
  type: typeof FETCH_ADJACENT_GEOSPATIAL_TRANSACTIONS_FAILED
}

interface SuppliersUpdateGeospatialBounds {
  type: typeof UPDATE_GEOSPATIAL_BOUNDS
  bounds: number[][]
}

interface SuppliersSetActiveRowNumber {
  type: typeof SET_ACTIVE_ROW_NUMBER
  rowNum: number
  pane: PaneE
}

interface SuppliersSelectRecordRow {
  type: typeof SELECT_RECORD_ROW
  rowNum: number
  keyMods: KeyMods
  pane: PaneE
}

interface SuppliersSelectAllRecordRows {
  type: typeof SELECT_ALL_RECORD_ROWS
  pane: PaneE
}

interface SuppliersStartDragRecord {
  type: typeof START_DRAG_RECORD
  recordId: string
  pane: PaneE
}

interface SuppliersShowSidebarTab {
  type: typeof SHOW_SIDEBAR_TAB
  sidebarTabKey: $TSFixMe
}

interface SuppliersSetPage {
  type: typeof SET_PAGE
  pageNum: number
  pane: PaneE
}

interface SuppliersSetPageSize {
  type: typeof SET_PAGE_SIZE
  pageSize: number
  pane: PaneE
}

interface SuppliersToggleSort {
  type: typeof TOGGLE_SORT
  columnName: string
  pane: PaneE
}

interface SuppliersPinRecords {
  type: typeof PIN_RECORDS
  pane: PaneE
}

interface SuppliersUnpinRecords {
  type: typeof UNPIN_RECORDS
  pane: PaneE
}

interface SuppliersToggleVerifiedFilter {
  type: typeof TOGGLE_VERIFIED_FILTER
  pane: PaneE
}

interface SuppliersToggleVerifiedInCurrentCluster {
  type: typeof TOGGLE_VERIFIED_IN_CURRENT_CLUSTER
  pane: PaneE
}

interface SuppliersToggleVerifiedInAnotherCluster {
  type: typeof TOGGLE_VERIFIED_IN_ANOTHER_CLUSTER
  pane: PaneE
}

interface SuppliersToggleNotVerifiedFilter {
  type: typeof TOGGLE_NOT_VERIFIED_FILTER
  pane: PaneE
}

interface SuppliersToggleSuggestionsEnabled {
  type: typeof TOGGLE_SUGGESTIONS_ENABLED
  pane: PaneE
}

interface SuppliersToggleMoveSuggested {
  type: typeof TOGGLE_MOVE_SUGGESTED
  pane: PaneE
}

interface SuppliersToggleNoMoveSuggested {
  type: typeof TOGGLE_NO_MOVE_SUGGESTED
  pane: PaneE
}

interface SuppliersToggleSuggestionsDisabled {
  type: typeof TOGGLE_SUGGESTIONS_DISABLED
  pane: PaneE
}

interface SuppliersToggleSuggestionsAutoAccepted {
  type: typeof TOGGLE_SUGGESTIONS_AUTO_ACCEPTED
  pane: PaneE
}

interface SuppliersToggleClusterHasVerifiedHereFilter {
  type: typeof TOGGLE_CLUSTER_HAS_VERIFIED_HERE_FILTER
  pane: PaneE
}

interface SuppliersToggleClusterHasVerifiedElsewhereFilter {
  type: typeof TOGGLE_CLUSTER_HAS_VERIFIED_ELSEWHERE_FILTER
  pane: PaneE
}

interface SuppliersToggleClusterHasMoveSuggestedFilter {
  type: typeof TOGGLE_CLUSTER_HAS_MOVE_SUGGESTED_FILTER
  pane: PaneE
}

interface SuppliersToggleClusterHasNoneVerifiedFilter {
  type: typeof TOGGLE_CLUSTER_HAS_NONE_VERIFIED_FILTER
  pane: PaneE
}

interface SuppliersToggleFilterHasComments {
  type: typeof TOGGLE_FILTER_HAS_COMMENTS
  pane: PaneE
}

interface SuppliersToggleRecordChangesFilter {
  type: typeof TOGGLE_RECORD_CHANGES_FILTER
  filterOption: $TSFixMe
  pane: PaneE
}

interface SuppliersToggleShowGeospatialOverlay {
  type: typeof TOGGLE_SHOW_GEOSPATIAL_OVERLAY
}

interface SuppliersSetRecordChangesFilter {
  type: typeof SET_RECORD_CHANGES_FILTER
  recordChanges: Set<ClusterRecordChangesValueType>
  clear: boolean
  pane: PaneE
}

interface SuppliersToggleExpandSidebar {
  type: typeof TOGGLE_EXPAND_SIDEBAR
}

interface SuppliersOpenCommentForm {
  type: typeof OPEN_COMMENT_FORM
}

interface SuppliersCommentCompleted {
  type: typeof COMMENT_COMPLETED
}

interface SuppliersCommentFailed {
  type: typeof COMMENT_FAILED
}

interface SuppliersEditComment {
  type: typeof EDIT_COMMENT
}

interface SuppliersEditCommentCompleted {
  type: typeof EDIT_COMMENT_COMPLETED
}

interface SuppliersEditCommentFailed {
  type: typeof EDIT_COMMENT_FAILED
}

interface SuppliersDeleteComment {
  type: typeof DELETE_COMMENT
}

interface SuppliersDeleteCommentCompleted {
  type: typeof DELETE_COMMENT_COMPLETED
}

interface SuppliersDeleteCommentFailed {
  type: typeof DELETE_COMMENT_FAILED
}

interface SuppliersFetchSuppliersUpdate {
  type: typeof FETCH_SUPPLIERS_UPDATE
  pane: PaneE
}

interface SuppliersFetchSuppliersUpdateCompleted {
  type: typeof FETCH_SUPPLIERS_UPDATE_COMPLETED
  currentPage: List<Cluster>
  total: number
  filterInfo: ClusterFilterInfo
  totalSuppliers: number
  affectedIds: Set<string>
  affectedClusters: Set<Cluster>
  pane: PaneE
}

interface SuppliersFetchTotalTestClustersVerifiedCompleted {
  type: typeof FETCH_TOTAL_TEST_CLUSTERS_VERIFIED_COMPLETED
  totalTestClustersVerified: number
}

interface SuppliersFetchSuppliersUpdateFailed {
  type: typeof FETCH_SUPPLIERS_UPDATE_FAILED
  pane: PaneE
}

interface SuppliersOpenMap {
  type: typeof OPEN_MAP
}

interface SuppliersCloseMap {
  type: typeof CLOSE_MAP
}

interface SuppliersOpenClusterBrowser {
  type: typeof OPEN_CLUSTER_BROWSER
  clusterId: string
  pane: PaneE
}

interface SuppliersCloseClusterBrowser {
  type: typeof CLOSE_CLUSTER_BROWSER
  pane: PaneE
}

interface SuppliersSetClustersSort {
  type: typeof SET_CLUSTERS_SORT
  clustersSort: ClustersSortE
  pane: PaneE
}

interface SuppliersSetRecordsGraphTimePeriod {
  type: typeof SET_RECORDS_GRAPH_TIME_PERIOD
  recordsGraphTimePeriod: $TSFixMe
}

interface SuppliersFetchClusterPublishInfo {
  type: typeof FETCH_CLUSTER_PUBLISH_INFO
}

interface SuppliersFetchClusterPublishInfoCompleted {
  type: typeof FETCH_CLUSTER_PUBLISH_INFO_COMPLETED
  publishTimes: List<number>
  publishedClusterVersions: PublishedClusterVersions
  loadedPublishedClusterId: string
}

interface SuppliersFetchClusterPublishInfoFailed {
  type: typeof FETCH_CLUSTER_PUBLISH_INFO_FAILED
  loadedPublishedClusterId: string
}

interface SuppliersToggleClusterDiffVisible {
  type: typeof TOGGLE_CLUSTER_DIFF_VISIBLE
  pane: PaneE
}

interface SuppliersFetchActiveRecordClusterMembership {
  type: typeof FETCH_ACTIVE_RECORD_CLUSTER_MEMBERSHIP
  pane: PaneE
}

interface SuppliersFetchActiveRecordClusterMembershipCompleted {
  type: typeof FETCH_ACTIVE_RECORD_CLUSTER_MEMBERSHIP_COMPLETED
  activeRecordClusterMembership: ClusterMember | undefined
  activeRecordRelatedCluster: Cluster | undefined
  filterInfo: $TSFixMe
  pane: PaneE
}

interface SuppliersFetchActiveRecordClusterMembershipFailed {
  type: typeof FETCH_ACTIVE_RECORD_CLUSTER_MEMBERSHIP_FAILED
  filterInfo: $TSFixMe
}

interface SuppliersOpenGeospatialDetailsSidebar {
  type: typeof OPEN_GEOSPATIAL_DETAILS_SIDEBAR
  attributeName: string
}

interface SuppliersCloseGeospatialDetailsSidebar {
  type: typeof CLOSE_GEOSPATIAL_DETAILS_SIDEBAR
}

interface SuppliersOpenGeospatialDetailsDialog {
  type: typeof OPEN_GEOSPATIAL_DETAILS_DIALOG
}

interface SuppliersCloseGeospatialDetailsDialog {
  type: typeof CLOSE_GEOSPATIAL_DETAILS_DIALOG
}

interface SuppliersFetchVerificationFilterTotals {
  type: typeof FETCH_VERIFICATION_FILTER_TOTALS
}

interface SuppliersFetchVerificationFilterTotalsCompleted {
  type: typeof FETCH_VERIFICATION_FILTER_TOTALS_COMPLETED
  totals: VerificationFilterTotals
  fetchTriggers: VerificationFilterTotalsFetchTriggers
}

interface SuppliersFetchVerificationFilterTotalsFailed {
  type: typeof FETCH_VERIFICATION_FILTER_TOTALS_FAILED
  fetchTriggers: VerificationFilterTotalsFetchTriggers
}

interface SuppliersPublishedClustersDatasetStatus {
  type: typeof FETCH_PUBLISHED_CLUSTERS_DATASET_STATUS
}

interface SuppliersPublishedClustersDatasetStatusCompleted {
  type: typeof FETCH_PUBLISHED_CLUSTERS_DATASET_STATUS_COMPLETED
  publishedClustersDatasetStatus: DatasetStatus | undefined
}

interface SuppliersPublishedClustersDatasetStatusFailed {
  type: typeof FETCH_PUBLISHED_CLUSTERS_DATASET_STATUS_FAILED
}

interface SuppliersToggleClusterHighImpactFilter {
  type: typeof TOGGLE_CLUSTER_HIGH_IMPACT_FILTER
  pane: PaneE
}

export const PUBLISH_CLUSTERS = 'Suppliers.publishClusters';
interface SuppliersPublishClusters {
  type: typeof PUBLISH_CLUSTERS
}

export const PUBLISH_CLUSTERS_COMPLETED = 'Suppliers.publishClustersCompleted';
interface SuppliersPublishClustersCompleted {
  type: typeof PUBLISH_CLUSTERS_COMPLETED
}

export const PUBLISH_CLUSTERS_FAILED = 'Suppliers.publishClustersFailed';
interface SuppliersPublishClustersFailed {
  type: typeof PUBLISH_CLUSTERS_FAILED
}

export type SuppliersActionTypes
  = SuppliersSetSuppliersFilter
  | SuppliersReloadBoth
  | SuppliersClustersHaveChanged
  | SuppliersSetQueryString
  | SuppliersReload
  | SuppliersSetSuppliersPage
  | SuppliersClearSuppliersFilter
  | SuppliersSelectAllSupplierRows
  | SuppliersSelectSupplierRow
  | SuppliersStartDragCluster
  | SuppliersSetSelectedSourceDatasets
  | SuppliersSetSourceDatasetsDialogVisible
  | SuppliersToggleFilterUnresolved
  | SuppliersToggleFilterResolved
  | SuppliersToggleConfidenceFilter
  | SuppliersSetClusterChangesFilter
  | SuppliersToggleClusterChangesFilter
  | SuppliersToggleWithChangesFilter
  | SuppliersSetMyAssignments
  | SuppliersSetClustersWithChanges
  | SuppliersToggleCustomConfidenceFilter
  | SuppliersSetCustomConfidenceFilter
  | SuppliersToggleCustomSimilarityFilter
  | SuppliersSetCustomSimilarityFilter
  | SuppliersClearFilters
  | SuppliersClearRecordFilters
  | SuppliersFetchSuppliers
  | SuppliersFetchSuppliersCompleted
  | SuppliersFetchSuppliersFailed
  | SuppliersBeginConfirmingMergeClusters
  | SuppliersBeginConfirmingMergeSelectedClustersToTarget
  | SuppliersBeginConfirmingMergeClustersToTarget
  | SuppliersCancelVerificationAction
  | SuppliersMergeClusters
  | SuppliersMergeClustersCompleted
  | SuppliersMergeClustersFailed
  | SuppliersVerifyClusters
  | SuppliersVerifyClustersCompleted
  | SuppliersVerifyClustersFailed
  | SuppliersOpenAssignDialog
  | SuppliersCloseAssignDialog
  | SuppliersAssignClusters
  | SuppliersAssignClustersCompleted
  | SuppliersAssignClustersFailed
  | SuppliersResolveClusters
  | SuppliersResolveClustersCompleted
  | SuppliersResolveClustersFailed
  | SuppliersBeginConfirmingAcceptSuggestion
  | SuppliersBeginConfirmingMoveRecords
  | SuppliersMoveRecords
  | SuppliersMoveRecordsCompleted
  | SuppliersMoveRecordsFailed
  | SuppliersBeginConfirmingMoveRecordsToNew
  | SuppliersMoveRecordsToNew
  | SuppliersMoveRecordsToNewCompleted
  | SuppliersMoveRecordsToNewFailed
  | SuppliersVerifyRecords
  | SuppliersVerifyRecordsCompleted
  | SuppliersFetchSimilarSuppliers
  | SuppliersFetchSimilarSuppliersCompleted
  | SuppliersFetchSimilarSuppliersFailed
  | SuppliersConflictError
  | SuppliersHideConflictError
  | SuppliersReloadConflictError
  | SuppliersSetRecordsSelectedSourceDatasets
  | SuppliersSetRecordsSourceDatasetsDialogVisible
  | SuppliersFetchTransactions
  | SuppliersFetchTransactionsCompleted
  | SuppliersFetchTransactionsFailed
  | SuppliersFetchTransactionsByBoundsCompleted
  | SuppliersClearSelectedGeoRows
  | SuppliersFetchInitialGeospatialTransactions
  | SuppliersFetchInitialGeospatialTransactionsCompleted
  | SuppliersFetchInitialGeospatialTransactionsFailed
  | SuppliersFetchGeospatialTransactions
  | SuppliersFetchGeospatialTransactionsCompleted
  | SuppliersFetchGeospatialTransactionsFailed
  | SuppliersFetchAdjacentGeospatialTransactions
  | SuppliersFetchAdjacentGeospatialTransactionsCompleted
  | SuppliersFetchAdjacentGeospatialTransactionsFailed
  | SuppliersUpdateGeospatialBounds
  | SuppliersSetActiveRowNumber
  | SuppliersSelectRecordRow
  | SuppliersSelectAllRecordRows
  | SuppliersStartDragRecord
  | SuppliersShowSidebarTab
  | SuppliersSetPage
  | SuppliersSetPageSize
  | SuppliersToggleSort
  | SuppliersPinRecords
  | SuppliersUnpinRecords
  | SuppliersToggleVerifiedFilter
  | SuppliersToggleVerifiedInCurrentCluster
  | SuppliersToggleVerifiedInAnotherCluster
  | SuppliersToggleNotVerifiedFilter
  | SuppliersToggleSuggestionsEnabled
  | SuppliersToggleMoveSuggested
  | SuppliersToggleNoMoveSuggested
  | SuppliersToggleSuggestionsDisabled
  | SuppliersToggleSuggestionsAutoAccepted
  | SuppliersToggleClusterHasVerifiedHereFilter
  | SuppliersToggleClusterHasVerifiedElsewhereFilter
  | SuppliersToggleClusterHasMoveSuggestedFilter
  | SuppliersToggleClusterHasNoneVerifiedFilter
  | SuppliersToggleFilterHasComments
  | SuppliersToggleRecordChangesFilter
  | SuppliersToggleShowGeospatialOverlay
  | SuppliersSetRecordChangesFilter
  | SuppliersToggleExpandSidebar
  | SuppliersOpenCommentForm
  | SuppliersCommentCompleted
  | SuppliersCommentFailed
  | SuppliersEditComment
  | SuppliersEditCommentCompleted
  | SuppliersEditCommentFailed
  | SuppliersDeleteComment
  | SuppliersDeleteCommentCompleted
  | SuppliersDeleteCommentFailed
  | SuppliersFetchSuppliersUpdate
  | SuppliersFetchSuppliersUpdateCompleted
  | SuppliersFetchTotalTestClustersVerifiedCompleted
  | SuppliersFetchSuppliersUpdateFailed
  | SuppliersOpenMap
  | SuppliersCloseMap
  | SuppliersOpenClusterBrowser
  | SuppliersCloseClusterBrowser
  | SuppliersSetClustersSort
  | SuppliersSetRecordsGraphTimePeriod
  | SuppliersFetchClusterPublishInfo
  | SuppliersFetchClusterPublishInfoCompleted
  | SuppliersFetchClusterPublishInfoFailed
  | SuppliersToggleClusterDiffVisible
  | SuppliersFetchActiveRecordClusterMembership
  | SuppliersFetchActiveRecordClusterMembershipCompleted
  | SuppliersFetchActiveRecordClusterMembershipFailed
  | SuppliersOpenGeospatialDetailsSidebar
  | SuppliersCloseGeospatialDetailsSidebar
  | SuppliersOpenGeospatialDetailsDialog
  | SuppliersCloseGeospatialDetailsDialog
  | SuppliersFetchVerificationFilterTotals
  | SuppliersFetchVerificationFilterTotalsCompleted
  | SuppliersFetchVerificationFilterTotalsFailed
  | SuppliersPublishedClustersDatasetStatus
  | SuppliersPublishedClustersDatasetStatusCompleted
  | SuppliersPublishedClustersDatasetStatusFailed
  | SuppliersToggleClusterHighImpactFilter
  | SuppliersPublishClusters
  | SuppliersPublishClustersCompleted
  | SuppliersPublishClustersFailed
