import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import ConditionalButton from '../components/ConditionalButton';
import style from './SaveTransformsButton.module.scss';
import { saveTransforms } from './TransformsApi';
import { selectDraftCount, selectSavePreconditions } from './TransformsStore';

const SaveTransformsButton = _.compose(
  connect((state) => {
    const { transforms } = state;
    const numDrafts = selectDraftCount(transforms);
    const savePreconditions = selectSavePreconditions(state);
    return {
      numDrafts,
      savePreconditions,
    };
  }, {
    onSaveTransforms: saveTransforms,
  }),
)(class SaveTransformsButton extends React.Component {
  render() {
    const { onSaveTransforms, numDrafts, savePreconditions } = this.props;
    return (
      <ConditionalButton
        className={style.saveButton}
        onClick={onSaveTransforms}
        preconditions={savePreconditions}
      >
        Save changes
        {numDrafts !== 0 ? (
          <div className={classNames(style.buttonBadge, { [style.disabled]: savePreconditions.some(v => v === false) })}>
            {this.props.numDrafts}
          </div>
        ) : null}
      </ConditionalButton>
    );
  }
});

export default SaveTransformsButton;
