import classNames from 'classnames';
import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import _ from 'underscore';

import ColumnWidthProvider from '../components/ColumnWidthProvider';
import LoadingPanel from '../components/LoadingPanel';
import SearchBox from '../components/SearchBox';
import SortableHeaderCell from '../components/SortableHeaderCell';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Pager from '../components/Table/PagerModel';
import Table from '../components/Table/Table';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import TooltipTrigger from '../components/TooltipTrigger';
import UserName from '../components/UserName';
import SortState from '../constants/SortState';
import AssignmentSummary from '../models/AssignmentSummary';
import { inProject } from '../utils/Authorization';
import { history } from '../utils/History';
import { commafy } from '../utils/Numbers';
import { getActiveRecipeDoc, selectActiveProjectInfo } from '../utils/Selectors';
import SortUtils from '../utils/SortUtils';
import { pluralize } from '../utils/Strings';
import { getPath, noop } from '../utils/Values';
import style from './CategorizationDashboardCuratorAssignmentCard.module.scss';

const navigate = (url, onResetTransactionsFilterState) => {
  if (_.isFunction(onResetTransactionsFilterState)) {
    onResetTransactionsFilterState();
  }
  history.push(url);
};

const renderUserColumn = ({ visibleAssignmentSummary, onChangeAssignmentSort, assignmentSort, recipeId, onResetTransactionsFilterState, assignmentSearch }) => {
  const sortState = assignmentSort.field === 'username' ? assignmentSort.order : SortState.UNSORTED;
  const header = <SortableHeaderCell className={style.headerCell} col="username" display="Reviewer" sortCallback={onChangeAssignmentSort} sortState={sortState}>Reviewer</SortableHeaderCell>;
  const cell = ({ rowIndex }) => {
    const username = visibleAssignmentSummary.get(rowIndex).username;
    const renderedUser = <UserName username={username} youIndicatorClassName={style.youIndicatorClassName} highlight={assignmentSearch} />;
    const link = `/spend/recipe/${recipeId}?assignedToUsers=${username}`;
    return (
      <Cell>
        <a className={style.username} onClick={() => navigate(link, onResetTransactionsFilterState)}>{renderedUser}</a>
      </Cell>
    );
  };
  return (
    <Column columnKey="username" key="username" width={100} flexGrow={1} {...{ header, cell }} />
  );
};

const renderNumberColumn = ({ visibleAssignmentSummary, field, columnHeader, onChangeAssignmentSort, assignmentSort, isResizable }) => {
  const sortState = assignmentSort.field === field ? assignmentSort.order : SortState.UNSORTED;
  const header = <SortableHeaderCell className={classNames(style.headerCell, style.numberHeader)} col={field} display={columnHeader} sortCallback={onChangeAssignmentSort} sortState={sortState}>{columnHeader}</SortableHeaderCell>;
  const cell = ({ rowIndex }) => {
    const value = visibleAssignmentSummary.get(rowIndex)[field];
    return <Cell><span title={commafy(value)}>{commafy(value)}</span></Cell>;
  };
  return (
    <Column columnKey={field} key={field} width={110} {...{ header, cell }} isResizable={isResizable} />
  );
};

const renderSpacerColumn = (key) => {
  return <Column key={key} width={18} />;
};

const PAGE_SIZE = 10;

const CategorizationDashboardCuratorAssignmentCard = _.compose(
  connect(state => {
    const { categorizationDashboard: { loadingAssignments, numberOpen, assignmentSummary, assignmentPage, assignmentSearch, assignmentSort }, users: { users } } = state;
    const categorizationRecipeId = getActiveRecipeDoc(state).id.id;
    const projectInfo = selectActiveProjectInfo(state);
    const projectUsers = users.filter(u => inProject(u, projectInfo && projectInfo.projectId));
    const sortComparator = SortUtils.comparator(assignmentSort.order);
    // Add users that have access to the project but have no assignments
    const assignedUsers = assignmentSummary.groupBy(assignment => assignment.username).map(g => g.first());
    const augmentedAssignmentSummary = projectUsers.map(user => assignedUsers.get(user.username) || new AssignmentSummary({ username: user.username, pending: 0, skipped: 0, answered: 0 }));

    const filteredAssignmentSummary = augmentedAssignmentSummary
      .filter(value => {
        if (!assignmentSearch) {
          return true;
        }
        const user = getPath(projectUsers.find(u => u.username === value.username), 'user') || { username: value.username, given: '', surname: '' };
        return user.username.toLowerCase().indexOf(assignmentSearch.toLowerCase()) > -1 ||
          `${user.given || ''} ${user.surname || ''}`.toLowerCase().indexOf(assignmentSearch.toLowerCase()) > -1;
      })
      .sortBy(value => value[assignmentSort.field], sortComparator);

    const visibleAssignmentSummary = filteredAssignmentSummary
      .slice(assignmentPage * PAGE_SIZE, (assignmentPage + 1) * PAGE_SIZE);
    return { recipeId: categorizationRecipeId, numberOpen, assignmentSummary: augmentedAssignmentSummary, visibleAssignmentSummary, filteredAssignmentSummarySize: filteredAssignmentSummary.size, assignmentPage, assignmentSearch, assignmentSort, projectUsers, loadingAssignments };
  }, {
    onResetTransactionsFilterState: () => ({ type: 'Transactions.resetFilters' }),
    onChangeAssignmentPage: assignmentPage => ({ type: 'CategorizationDashboard.setAssignmentPage', assignmentPage }),
    onChangeAssignmentSearch: assignmentSearch => ({ type: 'CategorizationDashboard.setAssignmentSearch', assignmentSearch }),
    onChangeAssignmentSort: field => ({ type: 'CategorizationDashboard.progressAssignmentSort', field }),
  }),
)(({ recipeId, onResetTransactionsFilterState, numberOpen, visibleAssignmentSummary, filteredAssignmentSummarySize, projectUsers, assignmentPage, onChangeAssignmentPage, assignmentSort, onChangeAssignmentSort, loadingAssignments, assignmentSearch, onChangeAssignmentSearch }) => {
  if (loadingAssignments) {
    return <LoadingPanel />;
  }
  const helperIcon = (
    <TooltipTrigger
      placement="top"
      content={<span>Open assignments are assigned <Term>records</Term> that have not received responses from all assignees..</span>}
    >
      <span className={style.infoIcon}><TamrIcon iconName="info-outline" size={12} /></span>
    </TooltipTrigger>
  );
  return (
    <div>
      <div className={style.titleContainer}>
        <div className={style.titleSection}>
          <div className={style.title}>{commafy(projectUsers.size)} {pluralize(projectUsers.size, 'User', 'Users')}</div>
          <div className={style.subtitle}>{_.isNumber(numberOpen) ? commafy(numberOpen) : '--' } open {pluralize(numberOpen || 0, 'Assignment', 'Assignments')} {helperIcon}</div>
        </div>
        <div className={style.searchSection}>
          <SearchBox
            onSearch={onChangeAssignmentSearch}
            value={assignmentSearch}
          />
        </div>
      </div>
      <div className={style.tableContainer}>
        {filteredAssignmentSummarySize === 0 && assignmentSearch
          ? (
            <div className={style.center}>
              <div>No users matching your filter</div>
            </div>
          )
          : (
            <AutoSizer>
              {({ width, height }) => (
                <div>
                  <ColumnWidthProvider>
                    <Table
                      {...{ width, height }}
                      getLength={() => visibleAssignmentSummary.size}
                      tableType="stripes"
                      rowHeight={40}
                      onPageChange={onChangeAssignmentPage}
                      onPageSizeChange={noop}
                      pageSizes={List.of(PAGE_SIZE)}
                      pagerHeight={45}
                      pagerState={new Pager(visibleAssignmentSummary, filteredAssignmentSummarySize, assignmentPage, PAGE_SIZE, true)}
                    >
                      {renderSpacerColumn('start')}
                      {renderUserColumn({ visibleAssignmentSummary, onChangeAssignmentSort, assignmentSort, recipeId, onResetTransactionsFilterState, assignmentSearch })}
                      {renderNumberColumn({ visibleAssignmentSummary, field: 'pending', columnHeader: 'Open assignments', onChangeAssignmentSort, assignmentSort })}
                      {renderNumberColumn({ visibleAssignmentSummary, field: 'skipped', columnHeader: 'Skipped', onChangeAssignmentSort, assignmentSort })}
                      {renderNumberColumn({ visibleAssignmentSummary, field: 'answered', columnHeader: 'Categorized', onChangeAssignmentSort, assignmentSort })}
                      {renderSpacerColumn('end')}
                    </Table>
                  </ColumnWidthProvider>
                  <div className={style.bottomShadowContainer}>
                    <div className={style.bottomShadow} />
                  </div>
                </div>
              )}
            </AutoSizer>
          )}
      </div>
    </div>
  );
});

export default CategorizationDashboardCuratorAssignmentCard;
