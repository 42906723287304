import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import { AppState } from '../stores/MainStore';

import { HIDE_UP_TO_DATE_DIALOG } from './PregroupActionTypes';

/**
 * Popup window to display when pregroup job submitted, but already up to date
 */
const UpToDateDialog = connect((state: AppState) => {
  const { pregroup: { showUpToDateDialog } } = state;
  return {
    show: showUpToDateDialog,
  };
}, {
  onHide: () => ({ type: HIDE_UP_TO_DATE_DIALOG }),
},
)(({ show, onHide }) => {
  return (
    <Dialog
      dialogStyle={DialogStyle.PRIMARY}
      title="Group Records"
      body={<div>Record groups are already up to date.</div>}
      footer={
        <ButtonToolbar>
          <Button buttonType="Primary" onClick={onHide}>Close</Button>
        </ButtonToolbar>
      }
      onHide={onHide}
      show={show}
    />
  );
});


export default UpToDateDialog;
