import { List, Map, Record } from 'immutable';
import qs from 'query-string';
import _ from 'underscore';

import Model from '../models/Model';
import ProcurementCategory from '../models/ProcurementCategory';
import ProcurementTaxonomy from '../models/ProcurementTaxonomy';
import { UPLOAD_TAXONOMY_COMPLETED } from '../taxonomy/TaxonomyUploadActionTypes';
import { ArgTypes } from '../utils/ArgValidation';
import { routes } from '../utils/Routing';
import { parseString, setOrDelete } from '../utils/Url';
import { resetExcept } from '../utils/Values';
import { DELETE_TAXONOMY_COMPLETED } from './TaxonomyActionTypes';

export const initialState = new (Model({
  taxonomy: { type: ArgTypes.nullable(ArgTypes.instanceOf(ProcurementTaxonomy)), defaultValue: null },
  loading: { type: ArgTypes.bool, defaultValue: false },
  loadedFilterInfo: { type: ArgTypes.any },
  taxonomySequence: { type: ArgTypes.number, defaultValue: 0 },
  expanded: { type: ArgTypes.bool, defaultValue: false },
  initialCategory: { type: ArgTypes.nullable(ArgTypes.instanceOf(ProcurementCategory)) },
  activeCategory: { type: ArgTypes.nullable(ArgTypes.instanceOf(ProcurementCategory)) },
  selectedCategories: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(ProcurementCategory)), defaultValue: List() },
  searchValue: { type: ArgTypes.string, defaultValue: '' },
  tierLabelMaxCounts: { type: ArgTypes.Immutable.map.of(ArgTypes.number, ArgTypes.number), defaultValue: Map() },
  viewType: { type: ArgTypes.string, defaultValue: 'alphabetical' },
}))();

const FilterInfo = Record({
  taxonomySequence: null,
});

export const getFilterInfo = state => {
  const { taxonomy: { taxonomySequence } } = state;
  return new FilterInfo({ taxonomySequence });
};

const reloadTaxonomy = state => state.update('taxonomySequence', n => n + 1);

const setCategorizationDialogInitState = (state, { categoryId }) => {
  const { taxonomy } = state;
  const category = taxonomy.categories.find(c => c.categoryId === categoryId);
  return state
    .update('selectedCategories', sc => (category ? sc.clear().push(category) : sc.clear()))
    .set('initialCategory', category)
    .set('activeCategory', category);
};

export const reducers = {
  'Location.change': (state, { location }) => {
    if (routes.taxonomy.match(location.pathname)) {
      const params = qs.parse(location.search);
      return _.compose(
        setOrDelete('viewType', parseString(params.viewType)),
        setOrDelete('searchValue', parseString(params.searchValue)),
      )(state);
    }
    return state;
  },

  'Location.projectChange': (state) => resetExcept(state, ['loading']),

  'Taxonomy.clearSelection': (state) => {
    return state.delete('activeCategory').delete('selectedCategories');
  },

  'Taxonomy.fetchTaxonomy': (state) => {
    return state.set('loading', true);
  },

  'Taxonomy.fetchTaxonomyCompleted': (state, { taxonomy, filterInfo }) => {
    // Update active and selected categories
    const activeCategory = state.activeCategory
      ? taxonomy.findCategoryById(state.activeCategory.categoryId)
      : state.activeCategory;
    const selectedCategories = state.selectedCategories.map(c => taxonomy.findCategoryById(c.categoryId));

    // Get the maximum total count of labels by tier.  Perf improvement to cache it in the taxonomy store
    const tierLabelMaxCounts = taxonomy.categories
      .groupBy(category => category.path.size)
      .map(tier => tier
        .map(category => category.manualCategorizations.count + category.totalAggForSuggestedCategorizations.count)
        .max(),
      );
    return state.merge({ taxonomy, loading: false, loadedFilterInfo: filterInfo, activeCategory, selectedCategories, tierLabelMaxCounts });
  },

  'Taxonomy.fetchTaxonomyFailed': (state, { filterInfo }) => {
    return state.merge({ loading: false, loadedFilterInfo: filterInfo });
  },

  [DELETE_TAXONOMY_COMPLETED]: (state) => {
    return state.set('taxonomy', undefined);
  },

  'Taxonomy.deleteCategoryCompleted': (state, { categoryId }) => {
    // Make sure any selection of category being deleted is cleared
    let newState = state;
    if (state.activeCategory && state.activeCategory.categoryId === categoryId) {
      newState = state.delete('activeCategory');
    }
    newState = newState.update('selectedCategories', s => s.filter(c => c.categoryId !== categoryId));

    return reloadTaxonomy(newState);
  },
  'Taxonomy.deleteCategorizationsCompleted': reloadTaxonomy,
  'Taxonomy.addCategoryCompleted': reloadTaxonomy,
  'Taxonomy.updateMetadataCompleted': reloadTaxonomy,
  'Taxonomy.renameCategoryCompleted': reloadTaxonomy,
  'Transactions.deleteCategorizationsCompleted': reloadTaxonomy,
  'Transactions.addCategorizationsCompleted': reloadTaxonomy,
  'Jobs.classificationCompleted': reloadTaxonomy,
  [UPLOAD_TAXONOMY_COMPLETED]: reloadTaxonomy,

  'Taxonomy.toggleExpanded': (state) => {
    return state.update('expanded', b => !b);
  },

  'Taxonomy.selectCategory': (state, { selectedCategory }) => {
    const { activeCategory, selectedCategories } = state;
    const cascadingRemove = () => {
      return selectedCategories.filter(c => c.path.size < selectedCategory.path.size);
    };
    if (activeCategory && activeCategory.categoryId === selectedCategory.categoryId) {
      const newSelected = cascadingRemove();
      return state.merge({
        selectedCategories: newSelected,
        activeCategory: newSelected.maxBy(c => c.path.size),
      });
    }
    return state.merge({
      activeCategory: selectedCategory,
      selectedCategories: selectedCategories.some(c => c.categoryId === selectedCategory.categoryId)
        ? selectedCategories
        : cascadingRemove().push(selectedCategory),
    });
  },

  'Taxonomy.setSearchValue': (state, { searchValue }) => {
    return state.set('searchValue', searchValue);
  },

  'Taxonomy.setViewType': (state, { viewType }) => {
    return state.set('viewType', viewType);
  },

  'Transactions.openCategoryFilter': setCategorizationDialogInitState,
  'Transactions.openCategorizeDialog': setCategorizationDialogInitState,
};
