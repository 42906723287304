
import createLoader from '../../utils/createLoader';
import { selectActiveProjectInfo } from '../../utils/Selectors';
import { fetchMetrics } from './slice';

const MetricsLoader = createLoader(state => {
  const { loading, shouldFetch } = state.clusters.metrics.historical;

  const projectInfo = selectActiveProjectInfo(state);

  return {
    canFetch: !!projectInfo?.unifiedDatasetDoc,
    shouldFetch: !loading && shouldFetch,
    loading,
    fetchArgs: [projectInfo],
  };
}, {
  onFetch: fetchMetrics,
}, 'ClusterMetricsLoader');

export default MetricsLoader;
