import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
// @ts-expect-error Do we need to find another library that is TS-compatible?
import Truncate from 'react-truncate';

import TamrIcon from '../components/TamrIcon';
import ProjectTypes from '../constants/ProjectTypes';
import { FCWithPropTypes } from '../utils/typescript-react';
import style from './ProjectCard.module.scss';
// @ts-expect-error Resolve when ProjectTypeIcon is converted to TS
import ProjectTypeIcon from './ProjectTypeIcon';

const ICON_CHECK_INVERTED = 'tamr-icon-check-inverted';

const SimpleProjectCard = FCWithPropTypes({
  projectType: ProjectTypes.propType,
  displayName: PropTypes.string,
  description: PropTypes.string,
  selected: PropTypes.bool,
}, ({ projectType, displayName, description, selected, ...rest }) => {
  return (
    <div {...rest} className={classNames(style.card, style.simple, { [style.selected]: selected })} data-test-element="project-card">
      <ProjectTypeIcon projectType={projectType} className={style.typeIcon} size={61} />
      {!!selected && <TamrIcon iconName={ICON_CHECK_INVERTED} className={style.selectedCheck} size={16} />}
      <div className={style.name} title={displayName || undefined}>{displayName}</div>
      <div className={style.description} title={description || undefined}>
        <Truncate
          lines={2}
          ellipsis="…"
        >
          {description}
        </Truncate>
      </div>
    </div>
  );
});

export default SimpleProjectCard;
