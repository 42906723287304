import { Set } from 'immutable';

import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

/**
 * Clusters that have changed since the last time the state on the clusters page was loaded
 */
class ChangedClusters extends getModelHelpers({
  recipeId: { type: ArgTypes.number },
  clusterIds: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: Set() },
}, 'ChangedClusters')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ChangedClustersRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  static fromJSON(obj: $TSFixMe) {
    checkArg({ obj }, ArgTypes.object);
    const { recipeId, clusterIds } = obj;
    return new ChangedClusters({
      recipeId,
      clusterIds: Set(clusterIds),
    });
  }
}

export default ChangedClusters;
