import { List, Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import { OptionType } from '../components/DropdownButton';
import MenuButton from '../components/MenuButton';
import { AppAction } from '../stores/AppAction';
import { AppState } from '../stores/MainStore';
import { GoldenRecordsActionConfirmationTypeE } from './GoldenRecordsActionConfirmationType';
import {
  BEGIN_CONFIRMING_UPDATE_GOLDEN_RECORDS,
  createActionGoldenRecordsBeginConfirmingPublish,
} from './GoldenRecordsActionTypes';
import {
  getIndexDraftJobIsRunning,
  getPublishJobIsRunning,
  selectNumberOfChanges,
} from './GoldenRecordsSelectors';

function getPublishPreconditions(
  publishGoldenRecordsJobRunning: boolean,
): Map<string, boolean> {
  return Map<string, boolean>()
    .set('Already publishing', !publishGoldenRecordsJobRunning);
}

function getUpdatePreconditions(
  updateGoldenRecordsJobRunning: boolean,
  outstandingChanges: boolean,
): Map<string, boolean> {
  return Map<string, boolean>()
    .set('Please save changes before updating', !outstandingChanges)
    .set('An Update job is already running', !updateGoldenRecordsJobRunning);
}

type JobButtonProps = {
  buttonText: string
  buttonPreconditions: Map<string, boolean>,
  buttonOnClick: () => void
}
/**
 * A helper component for this file, mainly to share the use of MenuButton and the sub-menu button
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GoldenRecordsJobButton = connect((state: AppState, ownProps: JobButtonProps) => {
  const { goldenRecords: { submittingUpdateGoldenRecords, submittingPublishGoldenRecords } } = state;
  return {
    updateGoldenRecordsJobRunning: submittingUpdateGoldenRecords || getIndexDraftJobIsRunning(state),
    publishGoldenRecordsJobRunning: submittingPublishGoldenRecords || getPublishJobIsRunning(state),
    outstandingChanges: selectNumberOfChanges(state) > 0,
  };
}, {
  onUpdateAndPublish: (): AppAction => ({ type: BEGIN_CONFIRMING_UPDATE_GOLDEN_RECORDS, confirmingAction: GoldenRecordsActionConfirmationTypeE.UPDATE_AND_PUBLISH }),
})(({ buttonText, buttonPreconditions, buttonOnClick,
  updateGoldenRecordsJobRunning, onUpdateAndPublish, publishGoldenRecordsJobRunning, outstandingChanges }) => {
  return (
    <MenuButton
      buttonText={buttonText}
      buttonPreconditions={buttonPreconditions}
      buttonOnClick={buttonOnClick}
      dropdownPreconditions={Map()}
      options={List.of<OptionType>({
        text: 'Update and Publish',
        onClick: onUpdateAndPublish,
        preconditions: getPublishPreconditions(publishGoldenRecordsJobRunning)
          .merge(getUpdatePreconditions(updateGoldenRecordsJobRunning, outstandingChanges)),
      })}
    />
  );
});

export const PublishGoldenRecordsButton = connect((state: AppState) => {
  const { goldenRecords: { submittingPublishGoldenRecords } } = state;
  return {
    publishGoldenRecordsJobRunning: submittingPublishGoldenRecords || getPublishJobIsRunning(state),
  };
}, {
  onPublish: () => createActionGoldenRecordsBeginConfirmingPublish(),
})(({ onPublish, publishGoldenRecordsJobRunning }) => {
  return (
    <GoldenRecordsJobButton
      buttonText="Publish"
      buttonPreconditions={getPublishPreconditions(publishGoldenRecordsJobRunning)}
      buttonOnClick={onPublish}
    />
  );
});

export const UpdateGoldenRecordsButton = connect((state: AppState) => {
  const { goldenRecords: { submittingUpdateGoldenRecords } } = state;
  return {
    updateGoldenRecordsJobRunning: submittingUpdateGoldenRecords || getIndexDraftJobIsRunning(state),
    outstandingChanges: selectNumberOfChanges(state) > 0,
  };
}, {
  onUpdate: (): AppAction => ({ type: BEGIN_CONFIRMING_UPDATE_GOLDEN_RECORDS, confirmingAction: GoldenRecordsActionConfirmationTypeE.UPDATE }),
})(({ updateGoldenRecordsJobRunning, onUpdate, outstandingChanges }) => {
  return (
    <GoldenRecordsJobButton
      buttonText="Update Golden Records"
      buttonPreconditions={getUpdatePreconditions(updateGoldenRecordsJobRunning, outstandingChanges)}
      buttonOnClick={onUpdate}
    />
  );
});
