import { List, Map } from 'immutable';

import { deleteGroup, deleteUserCredentials, getAllGroups, getFullUsers, getUserPreference, getUserRoles, getUsers, getUsersFullQuery, postChangeActiveStatus, postGroup, postUserCreateOrUpdate, postUserCredentialsCreate, putUserCredentialsUpdate, putUserRolesCreateOrUpdate } from '../api/AuthClient';
import { FetchError } from '../api/FetchResult';
import { SET_USER_PREFERENCES } from '../auth/AuthActionTypes';
import { updateUserPreferences } from '../auth/AuthAsync';
import DataTables, { DataTablesType } from '../constants/DataTables';
import SortState, { SortStateValueType } from '../constants/SortState';
import * as UsersActions from '../constants/UsersActionTypes';
import { SHOW } from '../errorDialog/ErrorDialogActionTypes';
import { apiError } from '../errorDialog/ErrorDialogUtils';
import AuthUser from '../models/AuthUser';
import DisplayColumn from '../models/DisplayColumn';
import Group from '../models/Group';
import User from '../models/User';
import UserCredentials from '../models/UserCredentials';
import { AppAction, AppThunkAction } from '../stores/AppAction';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import * as Result from '../utils/Result';
import { getAuthorizedUser, getUDColumnSettingsForPage } from '../utils/Selectors';
import { $TSFixMe } from '../utils/typescript';
import { getFullUsersFilterInfo, getGroupsFilterInfo, getSelectedFullUser, getUsernamesToChangeActiveStatus } from './UsersStore';
import { AuthUserSortFieldE } from './utils/AuthUserSortField';


type ActionTypesThatFetch
  = typeof UsersActions.CREATE_USER_COMPLETED
  | typeof UsersActions.UPDATE_USER_COMPLETED
  | typeof UsersActions.SET_QUERY_COMPLETED
  | typeof UsersActions.SET_SORT_COMPLETED
  | typeof UsersActions.SET_PAGE_SIZE_COMPLETED
  | typeof UsersActions.SET_PAGE_NUMBER_COMPLETED
  | typeof UsersActions.SUBMIT_CHANGE_ACTIVE_STATUS_COMPLETED

const doFetchUsersTotal = ({ query, pageNumber, pageSize, sortFieldName, sortDirection }: {
  query: string,
  pageNumber: number,
  pageSize: number,
  sortFieldName: AuthUserSortFieldE | null | undefined,
  sortDirection: SortStateValueType | null | undefined,
}, successType: ActionTypesThatFetch, failType: AppAction['type'], failDetail: $TSFixMe): AppThunkAction<void> => async (dispatch) => {
  let apiSortDirection: 'ASCENDING' | 'DESCENDING' | null = null;
  if (sortDirection === SortState.SORTED_ASCENDING) {
    apiSortDirection = 'ASCENDING';
  } else if (sortDirection === SortState.SORTED_DESCENDING) {
    apiSortDirection = 'DESCENDING';
  }

  await Promise.all([getFullUsers(), getUsersFullQuery({
    query,
    pageNumber,
    pageSize,
    sortFieldName,
    sortDirection: apiSortDirection,
  })]).then(([users, data]) => {
    const { total, authUsers } = data;
    dispatch({
      type: successType,
      users,
      fullUsers: authUsers,
      total,
    });
  }, response => {
    // @ts-expect-error this is dispatching some failure type, but using a deprecated pattern
    //                  need to revisit once we rework the catch-based fetch pattern
    dispatch({
      type: failType,
      response,
      detail: failDetail,
    });
  });
};

export const setColumnPreferences = (page: DataTablesType, columns: List<DisplayColumn>): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  let { location: { recipeId } } = state;
  const user = getAuthorizedUser(state);
  if (!user) {
    console.error('Could not set user column preferences: no authorized user');
    return;
  }
  // recipeId is being overloaded here, it is actually moduleId in case of a GoldenRecord
  if (page === DataTables.GOLDEN_RECORDS || page === DataTables.GOLDEN_RECORDS_CLUSTER_SAMPLE) recipeId = state.location.moduleId;
  const newUser = user.updatePagePrefs({
    recipeId,
    page,
    columnsPreferences: columns.toJS(),
  });
  dispatch(updateUserPreferences(newUser));
};

export const export_funcs = {
  doFetchUsersTotal,
  setColumnPreferences,
};

export const upsertGroup = (): AppThunkAction<void> => async (dispatch, getState) => {
  const { users: { groupConfig } } = getState();

  if (!groupConfig) {
    return dispatch({
      type: UsersActions.UPSERT_GROUP_FAILED,
      response: 'Trying to upsert group from an invalid state',
    });
  }

  const newGroup = groupConfig.case({
    Create: ({ groupname, description, admin }) => Group.fromJSON({ groupname, description, admin }),
    Edit: ({ description, admin, currentGroup }) => currentGroup?.data.merge({ description, admin }),
    Delete: () => null,
  });
  if (!newGroup) {
    console.error('Cannot upsert group: group is in delete mode');
    return;
  }
  await postGroup(newGroup)
    .then(groupDoc => dispatch({ type: UsersActions.UPSERT_GROUP_COMPLETED, groupDoc }))
    .catch(response => {
      dispatch({ type: UsersActions.UPSERT_GROUP_FAILED, response: response?.message });
      dispatch({ type: SHOW, detail: 'Error upserting group', response });
    });
};

export const removeGroup = (): AppThunkAction<void> => async (dispatch, getState) => {
  const { users: { groupConfig } } = getState();

  if (!groupConfig) {
    return dispatch({
      type: UsersActions.DELETE_GROUP_FAILED,
      response: 'Trying to delete group from an invalid state',
    });
  }

  const invalidAction = async () => dispatch({ type: UsersActions.DELETE_GROUP_FAILED, response: 'Trying to delete group from an invalid state' });

  await groupConfig.case<Promise<unknown>>({
    Delete: async ({ id }) => {
      await deleteGroup(id)
        .then(() => dispatch({ type: UsersActions.DELETE_GROUP_COMPLETED }))
        .catch(response => dispatch({ type: UsersActions.DELETE_GROUP_FAILED, response }));
    },
    Edit: invalidAction,
    Create: invalidAction,
  });
};

export const setShowEditAccountModal = (rowIndex: number): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();

  const username = state.users.fullUsers.get(rowIndex)?.username;

  if (!username) {
    console.error(`Cannot show edit account modal: cannot find user for rowIndex ${rowIndex}`);
    return;
  }

  dispatch({
    type: UsersActions.SET_SHOW_ACCOUNT_MODAL,
    showAccountModal: true,
    editAccountMode: true,
    rowNumber: rowIndex,
  });
  dispatch({ type: UsersActions.FETCH_USER_ROLES });

  await getUserRoles(username).then(response => {
    dispatch({ type: UsersActions.FETCH_USER_ROLES_COMPLETED, userRoles: response.data });
  }, response => {
    dispatch({ type: UsersActions.FETCH_USER_ROLES_FAILED, response, detail: 'Error loading userRoles' });
  });
};

export const fetchGroups = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const filterInfo = getGroupsFilterInfo(state);
  dispatch({ type: UsersActions.FETCH_GROUPS });
  getAllGroups({ useCache: false })
    .then(data => {
      dispatch({ type: UsersActions.FETCH_GROUPS_COMPLETED, data, filterInfo });
    }, () => {
      dispatch({ type: UsersActions.FETCH_GROUPS_FAILED, filterInfo });
    });
};

export const saveUserPreference = (): AppThunkAction<void> => (dispatch, getState) => {
  const username = getState().auth.authorizedUser?.username;
  username && getUserPreference(username)
    .then((preferencesDoc) => {
      dispatch({
        type: SET_USER_PREFERENCES,
        preferences: Map(preferencesDoc.data.preferences as Map<any, any>),
      });
    }, response => {
      dispatch({ type: SHOW, detail: 'Error getting user preferences', response });
    });
};

export const fetchUsers = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  // get
  getUsers(state.auth.authorizedUser?.admin || false).then(data => {
    dispatch({ type: UsersActions.FETCH_USERS_COMPLETED, data });
  }, response => {
    dispatch({ type: SHOW, detail: 'Error loading users', response });
  });
};

export const fetchFullUsers = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const { users: { query, pageNumber, pageSize, sortFieldName, sortDirection } } = state;

  // sanitize sort direction for usage in the http request
  let apiSortDirection: 'ASCENDING' | 'DESCENDING' | null = null;
  if (sortDirection === SortState.SORTED_ASCENDING) {
    apiSortDirection = 'ASCENDING';
  } else if (sortDirection === SortState.SORTED_DESCENDING) {
    apiSortDirection = 'DESCENDING';
  }

  const filterInfo = getFullUsersFilterInfo(state);

  getUsersFullQuery({ query, pageNumber, pageSize, sortFieldName, sortDirection: apiSortDirection })
    .then(data => {
      const { total, authUsers } = data;
      dispatch({ type: UsersActions.FETCH_FULL_USERS_COMPLETED, total, fullUsers: authUsers, filterInfo });
    }, response => {
      dispatch({ type: UsersActions.FETCH_FULL_USERS_FAILED, filterInfo, response });
    });
};

export const setColumnWidth = (
  page: DataTablesType,
  col: string,
  newWidth: number,
  modifyFields?: $TSFixMe,
  modifyDefaults?: $TSFixMe,
): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  const existingColumnSettings = getUDColumnSettingsForPage(state, page, modifyFields, modifyDefaults);
  if (!existingColumnSettings) {
    console.error('Cannot set column width: cannot get settings for current page');
    return;
  }
  const newColumnSettings = existingColumnSettings.map(c => (c.name === col ? c.set('width', newWidth) : c));
  await dispatch(export_funcs.setColumnPreferences(page, newColumnSettings));
};

const doesUserExist = (username: string, usersList: List<AuthUser>) => {
  checkArg({ username }, ArgTypes.string);
  checkArg({ usersList }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(AuthUser)));
  return usersList.filter(x => x.username === username).size === 1;
};

export const createUser = (): AppThunkAction<void> => async (dispatch, getState) => {
  const { users: { accountConfig: { username, password, given, surname, email, deactivated, groups, admin }, fullUsers, query, pageNumber, pageSize, sortFieldName, sortDirection } } = getState();
  if (doesUserExist(username, fullUsers)) {
    dispatch({
      type: SHOW,
      title: 'Error creating user',
      detail: 'Error creating user',
      response: { responseText: 'User already exists' },
    });
    return;
  }
  await Promise.resolve()
    .then(() => postUserCreateOrUpdate(new User({ username, given, surname, email, deactivated }))
      .catch(response => {
        dispatch({
          type: SHOW,
          title: 'Error creating user',
          detail: 'Error creating user: ' + username,
          response,
        });
        throw Error('fail');
      }))
    .then(() => putUserRolesCreateOrUpdate({ username, groups, admin })
      .catch(response => {
        dispatch({
          type: SHOW,
          title: 'Error creating user',
          detail: 'Error creating roles for user: ' + username,
          response,
        });
        throw Error('fail');
      }))
    .then(() => postUserCredentialsCreate(new UserCredentials({ username, password }))
      .then(Result.mapper(
        () => Promise.resolve() /* no success handler */,
        (error) => {
          apiError(dispatch, `Error creating credentials for new user ${username}`, error);
          return Promise.reject();
        })))
    .then(() => {
      // successfully created user
      dispatch(export_funcs.doFetchUsersTotal({ query, pageNumber, pageSize, sortFieldName, sortDirection },
        UsersActions.CREATE_USER_COMPLETED, UsersActions.CREATE_USER_FAILED,
        'User successfully updated, but error while refetching',
      ));
    })
    .catch(() => {}); // skip to here on fail
};

export const updateUser = (): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  const { users: { fullUsers, accountConfig: { username, password, given, surname, email, admin, externallyManaged, deactivated, groups }, userRoles, query, pageNumber, pageSize, sortFieldName, sortDirection } } = state;
  if (!doesUserExist(username, fullUsers)) {
    dispatch({
      type: SHOW,
      title: 'Error updating user',
      detail: 'Error updating user',
      response: { responseText: 'User does not exist' },
    });
    return;
  }
  const selectedFullUser = getSelectedFullUser(state);

  if (!selectedFullUser) {
    console.error('Cannot update user: selected full user is undefined');
    return;
  }
  if (!userRoles) {
    console.error('Cannot update user: userRoles is undefined');
    return;
  }

  await Promise.resolve()
    .then(() => postUserCreateOrUpdate(new User({
      username,
      given,
      surname,
      email,
      externallyManaged,
      deactivated,
    }))
      .catch(response => {
        dispatch({
          type: SHOW,
          title: 'Error updating user',
          detail: 'Error updating user: ' + username,
          response,
        });
        throw Error('fail');
      }))
    .then(() => putUserRolesCreateOrUpdate({ username, groups, admin })
      .catch(response => {
        // error while updating user roles
        // attempt to rollback the changes that were already made
        postUserCreateOrUpdate(selectedFullUser.user);
        dispatch({
          type: SHOW,
          title: 'Error updating user',
          detail: 'Error updating roles for user: ' + username,
          response,
        });
        throw Error('fail');
      }))
    .then(() => {
      const dispatchError = (error: FetchError) =>
        apiError(dispatch, `Error updating credentials for user: ${username}`, error);

      if (externallyManaged && !selectedFullUser.user.externallyManaged) {
        return deleteUserCredentials({ username }).then(Result.mapper(
          () => Promise.resolve() /* no success handler */,
          (error) => {
            dispatchError(error);
            return Promise.reject();
          },
        ));
      }
      if (password !== '') {
        return putUserCredentialsUpdate({
          username,
          userCredentials: new UserCredentials({ username, password }),
        }).then(Result.mapper(
          () => Promise.resolve() /* no success handler */,
          (error) => {
            // error while updating user credentials
            // attempt to rollback the changes that were already made
            postUserCreateOrUpdate(selectedFullUser.user);
            putUserRolesCreateOrUpdate({
              username,
              groups: selectedFullUser.groups,
              admin: userRoles.admin,
            });
            dispatchError(error);
            return Promise.reject();
          }));
      }
    })
    .then(() => {
      dispatch(export_funcs.doFetchUsersTotal({ query, pageNumber, pageSize, sortFieldName, sortDirection },
        UsersActions.UPDATE_USER_COMPLETED, UsersActions.UPDATE_USER_FAILED,
        'User successfully updated, but error while refetching',
      ));
    })
    .catch(() => {}); // skip to here on fail
};

export const submitAccountDialog = (): AppThunkAction<void> => async (dispatch, getState) => {
  const { users: { editAccountMode } } = getState();
  if (editAccountMode) {
    await dispatch(updateUser());
  } else {
    await dispatch(createUser());
  }
};

export const setQuery = (query: string): AppThunkAction<void> => async (dispatch, getState) => {
  dispatch({ type: UsersActions.SET_QUERY, query });
  const { users: { pageNumber, pageSize, sortFieldName, sortDirection } } = getState();

  dispatch(export_funcs.doFetchUsersTotal({ query, pageNumber, pageSize, sortFieldName, sortDirection },
    UsersActions.SET_QUERY_COMPLETED, UsersActions.SET_QUERY_FAILED,
    'Error while fetching users matching query',
  ));
};

export const toggleSort = (col: AuthUserSortFieldE): AppThunkAction<void> => async (dispatch, getState) => {
  const { users: { sortFieldName, sortDirection, query, pageNumber, pageSize } } = getState();
  let newSortFieldName: AuthUserSortFieldE | null = col;
  let newSortDirection: SortStateValueType | null = SortState.SORTED_ASCENDING;
  if (sortFieldName && sortFieldName === col) {
    if (sortDirection === SortState.SORTED_ASCENDING) {
      newSortDirection = SortState.SORTED_DESCENDING;
    } else if (sortDirection === SortState.SORTED_DESCENDING) {
      newSortFieldName = null;
      newSortDirection = null;
    }
  }
  dispatch({
    type: UsersActions.SET_SORT,
    sortFieldName: newSortFieldName,
    sortDirection: newSortDirection,
  });
  dispatch(export_funcs.doFetchUsersTotal({ query, pageNumber, pageSize, sortFieldName: newSortFieldName, sortDirection: newSortDirection },
    UsersActions.SET_SORT_COMPLETED, UsersActions.SET_SORT_FAILED,
    'Error while fetching users after changing sort',
  ));
};

export const setPageNumber = (pageNumber: number): AppThunkAction<void> => async (dispatch, getState) => {
  dispatch({ type: UsersActions.SET_PAGE_NUMBER, pageNumber });
  const { users: { query, pageSize, sortFieldName, sortDirection } } = getState();
  dispatch(export_funcs.doFetchUsersTotal({ query, pageNumber, pageSize, sortFieldName, sortDirection },
    UsersActions.SET_PAGE_NUMBER_COMPLETED, UsersActions.SET_PAGE_NUMBER_FAILED,
    'Error while fetching users after changing paging',
  ));
};

export const setPageSize = (pageSize: number): AppThunkAction<void> => async (dispatch, getState) => {
  dispatch({ type: UsersActions.SET_PAGE_SIZE, pageSize });
  const { users: { query, pageNumber, sortFieldName, sortDirection } } = getState();
  dispatch(export_funcs.doFetchUsersTotal({ query, pageSize, pageNumber, sortFieldName, sortDirection },
    UsersActions.SET_PAGE_SIZE_COMPLETED, UsersActions.SET_PAGE_SIZE_FAILED,
    'Error while fetching users after changing paging',
  ));
};

export const changeActiveStatus = (): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  const { users: { changeActiveStatusActivate, query, pageNumber, pageSize, sortFieldName, sortDirection } } = state;
  const usernames = getUsernamesToChangeActiveStatus(state);
  await postChangeActiveStatus(usernames, !!changeActiveStatusActivate).then(() => {
    dispatch(export_funcs.doFetchUsersTotal({ query, pageSize, pageNumber, sortFieldName, sortDirection },
      UsersActions.SUBMIT_CHANGE_ACTIVE_STATUS_COMPLETED, UsersActions.SUBMIT_CHANGE_ACTIVE_STATUS_FAILED,
      'Error while fetching users after updating their active status',
    ));
  }, response => {
    dispatch({
      type: UsersActions.SUBMIT_CHANGE_ACTIVE_STATUS_FAILED,
      response,
      detail: 'Error changing active status for users: ' + usernames,
    });
  });
};

export const setModulePreferences = (newModulePreferences: $TSFixMe): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  const user = getAuthorizedUser(state);
  if (!user) {
    console.error('Could not set user module preferences: no authorized user');
    return;
  }
  const updatedUser = user.updateModulePrefs(newModulePreferences);
  dispatch(updateUserPreferences(updatedUser));
};

/**
 * @param skipSavingToServer if true, only updates redux, but does not save this preference to the server
 *                           useful if user is currently editing the value, and we want to see the effects of that editing,
 *                           but we don't want to be chatty with the server.
 */
export const updateGlobalPreferences = (newGlobalPrefs: $TSFixMe, skipSavingToServer?: boolean): AppThunkAction<void> => async (dispatch, getState) => {
  const user = getAuthorizedUser(getState());
  if (!user) {
    console.error('Could not set user global preferences: no authorized user');
    return;
  }
  await dispatch(updateUserPreferences(
    user.updateGlobalPrefs(newGlobalPrefs),
    skipSavingToServer,
  ));
};
