import React, { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';

import Cell from '../components/Table/Cell';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import TooltipTrigger from '../components/TooltipTrigger';
import { ORIGIN_ENTITY_ID, ORIGIN_SOURCE_NAME, TAMR_ID } from '../constants/ElasticConstants';
import UnifiedAttribute from '../models/UnifiedAttribute';
import RequiredAttributeType from '../schema-mapping/constants/RequiredAttributeType';
import ListItemIcon from '../schema-mapping/ListItemIcon';
import { AppState } from '../stores/MainStore';
import PRODUCT_NAME from '../utils/ProductName';
import { hashCode } from '../utils/Values';
import { SET_AGGREGATION } from './PregroupActionTypes';
import {
  AggregationFunctionTypes,
  createAggregationFunction,
  defaultAggregationFunction,
  parseAggregationFunction,
} from './PreGroupBy';
import style from './PregroupRecordsTable.module.scss';
import { GROUP_COUNT_COLUMN_NAME, ORIGIN_SOURCE_NAME_HEADER_NAME } from './PregroupStore';

const options: {value:AggregationFunctionTypes, label:string, tooltip:string}[] = [
  { value: 'top', label: 'Top K', tooltip: 'Keep the K most frequent values' },
  { value: 'sum', label: 'Sum', tooltip: 'Add the values' },
  { value: 'collect_set', label: 'Collect Set', tooltip: 'Keep all distinct values' },
  { value: 'collect_subset', label: 'Collect Subset K', tooltip: 'Keep K distinct values (random)' },
  { value: 'drop', label: 'Remove', tooltip: 'Do not keep any values' },
  { value: 'custom', label: 'Custom', tooltip: 'Use transformations to write an aggregation expression' },
];

function mapStateToProps(state: AppState, ownProps: { unifiedAttribute: UnifiedAttribute | undefined }) {
  if (!ownProps.unifiedAttribute) {
    return null;
  }
  const sampleSpec = state.pregroup.sampleSpec;
  const isSpend = ownProps.unifiedAttribute.requiredAttributeType === RequiredAttributeType.SPEND;
  const isSupplier = ownProps.unifiedAttribute.requiredAttributeType === RequiredAttributeType.SUPPLIER;
  const isInternalField = [TAMR_ID, ORIGIN_ENTITY_ID, ORIGIN_SOURCE_NAME, GROUP_COUNT_COLUMN_NAME].includes(ownProps.unifiedAttribute.name);
  const isMlEnabled = ownProps.unifiedAttribute.mlEnabled;

  const name = ownProps.unifiedAttribute.name;
  const aggregationFunction = sampleSpec?.fieldAggregationMap[name] || defaultAggregationFunction(ownProps.unifiedAttribute);

  return {
    name,
    isSpend,
    isSupplier,
    isInternalField,
    isMlEnabled,
    isGroupingKey: sampleSpec?.groupingFields.includes(name) || false,
    doGroupNulls: sampleSpec?.groupNullFields.includes(name) || false,
    doGroupSources: sampleSpec?.groupingFields.includes(ORIGIN_SOURCE_NAME) || false,
    aggregationFunction,
    ...parseAggregationFunction(aggregationFunction, name),
  };
}

export default connect(mapStateToProps, {
  setAggregation: (fieldName: string, aggregationFunction: string, doGroupNulls: boolean, isGrouping: boolean) => ({ type: SET_AGGREGATION, fieldName, aggregationFunction, doGroupNulls, isGrouping }),
})(({ isGroupingKey, name, isSpend, isSupplier, isInternalField, isMlEnabled, setAggregation, doGroupNulls, doGroupSources, aggregationFunction, aggregationFunctionType, k }) => {
  const isAggField = !isGroupingKey && !isInternalField;
  const [aggFunc, handleOnChange] = useState(
    aggregationFunctionType === 'custom' ? aggregationFunction : createAggregationFunction('custom', true, 1, name),
  );
  const [kValue, handleKValue] = useState(k.toString());

  const submitK = () => {
    let kAsNumber = parseInt(kValue, 10);
    if (!kAsNumber) {
      kAsNumber = 1;
    } else if (kAsNumber > 9999) {
      kAsNumber = 9999;
    }

    setAggregation(name, createAggregationFunction(aggregationFunctionType, true, kAsNumber, name), doGroupNulls, false);
    handleKValue(kAsNumber.toString(10));
  };

  return (
    <Cell>
      <div className={style.header}>
        {isInternalField ? null :
        <label><input type="radio" name={`aggregation-${hashCode(name)}`} checked={isGroupingKey} onChange={() => setAggregation(name, aggregationFunction, doGroupNulls, true)} />
          Grouping Key
        </label>}
        {isGroupingKey && !isInternalField ?
          <label className={style.sublabel}><input type="checkbox" checked={doGroupNulls} onChange={(e) => setAggregation(name, aggregationFunction, e.target.checked, isGroupingKey)} />
            Group if empty
            <TooltipTrigger
              placement="top"
              content={'Check to group records that have an empty value for this key.'}>
              <TamrIcon style={{ marginLeft: '5px', marginBottom: '-2px' }} size={14} iconName="info-outline" />
            </TooltipTrigger>
          </label> : null}
        {isInternalField ? null :
        <label><input type="radio" name={`aggregation-${hashCode(name)}}`} checked={!isGroupingKey} onChange={() => setAggregation(name, aggregationFunction, false, false)} />
          Aggregation Function
        </label>}

        {name === ORIGIN_SOURCE_NAME ?
          <label><input
            type="checkbox"
            checked={!doGroupSources}
            onChange={(e) => setAggregation(name, aggregationFunction, false, !e.target.checked)} />
            {'Group across sources'}
          </label> : null
        }
        {isAggField ? <label className={style.sublabel}>
          <select
            value={aggregationFunctionType}
            className={style.selectFunction}
            onChange={(e) => {
              if (e.target.value === 'custom') {
                setAggregation(name, aggFunc, doGroupNulls, false);
              } else {
                setAggregation(name, createAggregationFunction(e.target.value as AggregationFunctionTypes, true, parseInt(kValue, 10), name), doGroupNulls, false);
              }
            }}
        >
            {options.map((option) => (
              <option
                value={option.value}
                key={option.value}
                disabled={(option.value === 'drop' && (isMlEnabled || isSpend))}
                title={`${option.tooltip}${option.value === 'drop' && (isMlEnabled || isSpend) ? '. Not available for ML or Spend attributes.' : ''}`}
              >
                {option.label}
              </option>
            ))}
          </select></label> : null}

        {isAggField && aggregationFunctionType === 'custom' ?
        /* custom input */
          <label className={style.sublabel}><DebounceInput
            debounceTimeout={1000}
            minLength={5}
            type="textarea"
            style={{ padding: '0 .3em', width: '100%' }}
            onChange={(e) => handleOnChange(e.target.value)}
            onKeyPress={(e: { key: string; }) => (e.key === 'Enter' ? setAggregation(name, aggFunc, doGroupNulls, false) : {})}
            onBlur={() => setAggregation(name, aggFunc, doGroupNulls, false)}
            value={aggregationFunction} /></label>
          : null}
        {isAggField && ['collect_subset', 'top'].includes(aggregationFunctionType) ?
        /* k input */
          <label className={style.sublabel}>K = <DebounceInput
            type="number"
            onChange={(e) => handleKValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === '-') {
                e.preventDefault();
              }
              if (e.key === 'Enter') {
                if (document.activeElement !== null) (document.activeElement as HTMLElement).blur();
              }
            }}
            onKeyPress={(e: { key: string; }) => (e.key === 'Enter' ? submitK() : {})}
            onBlur={() => submitK()}
            value={k === 1 || k >= 9999 ? kValue : k} /></label>
          : null}

        <div className={style.columnNameContainer}>
          <div className={style.columnName}>{name === ORIGIN_SOURCE_NAME ? ORIGIN_SOURCE_NAME_HEADER_NAME : name}</div>
          {isSpend ?
            <ListItemIcon
              iconName="attach-money"
              iconClassName={style.activeIcon}
              tooltip={<span>This attribute represents the <Term>Spend</Term> value</span>}
            /> : null}
          {isSupplier ?
            <ListItemIcon
              iconName="group-work"
              iconClassName={style.activeIcon}
              tooltip={<span>This attribute represents the <Term>Supplier</Term> value</span>}
            /> : null
          }
          {isInternalField ?
            <ListItemIcon
              iconName="tamr-icon-logo"
              iconClassName={style.inactiveIcon}
              size={14}
              tooltip={<span>This attribute was created by {PRODUCT_NAME}</span>}
            /> :
            <ListItemIcon
              iconName={isMlEnabled ? 'tamr-icon-ml-on' : 'tamr-icon-ml-off'}
              iconClassName={isMlEnabled ? style.activeIcon : style.inactiveIcon}
              tooltip={`This attribute will ${isMlEnabled ? '' : 'not'} be included in machine learning`}
            />
          }
        </div>
      </div>
    </Cell>
  );
});
