/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { connect } from 'react-redux';

import AppState from '../stores/AppState';
import { selectDocUrl } from '../utils/Selectors';
import { HTMLAnchorProps } from '../utils/typescript-react';

type DocLinkOwnProps = HTMLAnchorProps & {
  path: string,
  skipPrependDocs?: boolean
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DocLink = connect((state: AppState, ownProps: DocLinkOwnProps) => {
  return { docUrlBase: selectDocUrl(state) };
})(({ path, docUrlBase, skipPrependDocs, ...props }) => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={docUrlBase + (skipPrependDocs ? '' : 'docs/') + path} {...props} />
  );
});

export default DocLink;
