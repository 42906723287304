import { List, Set } from 'immutable';
import { createSelector, Selector } from 'reselect';
import _, { isArray, isNumber } from 'underscore';

import {
  INDEX_DRAFT,
  UPDATE_CLUSTER_PROFILE, UPDATE_PUBLISHED_DATASETS,
  UPDATE_SOURCE_LIST,
} from '../constants/RecipeOperations';
import AppState from '../stores/AppState';
import { Field } from '../transforms/models/Types';
import { ArgTypes, checkArg, Checker } from '../utils/ArgValidation';
import { setter, update } from '../utils/Collections';
import { getLastSuccessfulData } from '../utils/DescribedAsyncStatus';
import { getUrlForPage } from '../utils/Routing';
import {
  AppSelector,
  createAppStateSelector,
  getAuthorizedUser,
  isJobRunning,
  selectAllProjectInfos,
} from '../utils/Selectors';
import * as GoldenRecordsModule from './GoldenRecordsModule';
import { InputRecordsFilterInfo, PreviewFilterInfo } from './GoldenRecordsRulesStore';
import {
  DraftRecordsFilterInfo, FilterBookmarkState,
  GoldenRecordsStore,
  ModuleFilterInfo,
  selectIsPreviewKnownToBeUsable,
  selectValidationErrors,
} from './GoldenRecordsStore';
import { lastRunIndexDraft } from './ModuleStatus';
import * as Rule from './Rule';
import * as RuleDelta from './RuleDelta';

export const getCurrentGoldenRecordUI: AppSelector<GoldenRecordsStore> = ({ goldenRecords }) => goldenRecords;

export const getNextRules: AppSelector<List<RuleDelta.RuleDelta>> = ({ goldenRecords }) => goldenRecords.nextRules || List();
export const getNextEntityRule: AppSelector<RuleDelta.RuleDelta | undefined> = ({ goldenRecords }) => goldenRecords.nextEntityRule;

const getTableAttributeNames = (clusterIdColumnName: string, rules: List<Rule.Rule>, entityRule: Rule.Rule): Set<string> => {
  checkArg({ clusterIdColumnName }, ArgTypes.string);
  checkArg({ rules }, ArgTypes.Immutable.list.of(Rule.argType));
  checkArg({ entityRule }, Rule.argType);
  return rules.map(rule => rule.outputAttributeName).toSet()
    .add(Rule.SPECIAL_RULE_NAMES.sources)
    .add(Rule.SPECIAL_RULE_NAMES.clusterSize)
    .add(clusterIdColumnName)
    .add(entityRule?.outputAttributeName).filter(name => !!name);
};

export const selectPreviewTableColumnNames = createAppStateSelector(
  ArgTypes.Immutable.set.of(ArgTypes.string),
  s => s.goldenRecords.goldenRecordDocument?.data.clusterDataset.clusterColumn,
  getNextRules,
  getNextEntityRule,
  (clusterIdColumnName, rules, entityRule) => ((clusterIdColumnName && entityRule) ? getTableAttributeNames(
    clusterIdColumnName,
    rules.map(r => r.rule),
    entityRule.rule,
  ) : Set()),
);

export const selectAllRecordsTableColNames = createAppStateSelector(
  ArgTypes.Immutable.set.of(ArgTypes.string),
  s => s.goldenRecords.moduleFromLastUpdate,
  (moduleFromLastUpdate) => (moduleFromLastUpdate ? getTableAttributeNames(
    moduleFromLastUpdate.clusterDataset.clusterColumn,
    List(moduleFromLastUpdate.rules),
    moduleFromLastUpdate.entityRule,
  ) : Set()),
);


export const selectClusterSampleTableColNames = createAppStateSelector(
  ArgTypes.Immutable.set.of(ArgTypes.string),
  s => getLastSuccessfulData(s.goldenRecords.clusterSampleState.table),
  (typedTable) => ((typedTable?.type) ? typedTable?.type.fields.map(field => field.name).toSet() : Set()),
);


export const selectTableColNameSuggestedSet: Selector<AppState, Set<string>> = createSelector(
  ({ goldenRecords }) => goldenRecords.moduleFromLastUpdate?.rules || [],
  ({ goldenRecords }) => goldenRecords.moduleFromLastUpdate?.entityRule,
  (tableRules, entityRule) => Set((entityRule ? List(tableRules).push(entityRule) : List(tableRules)).toSeq().filter(rule => rule?.suggested).map(rule => rule.outputAttributeName)),
);

export const selectInputAttributesTypes = createAppStateSelector(
  ArgTypes.Immutable.list.of(Field.argType),
  state => state.goldenRecords.inputDatasetSchema,
  inputDatasetSchema => inputDatasetSchema?.fields || List(),
);

export const selectNumberOfChanges = createSelector(
  getNextRules,
  getNextEntityRule,
  (nextRules, nextEntityRule) => nextRules.filter(({ type }) => type !== RuleDelta.RuleDeltaTypes.UNCHANGED).size + (nextEntityRule?.type === RuleDelta.RuleDeltaTypes.UNCHANGED ? 0 : 1),
);

export const selectSavable = createAppStateSelector(
  ArgTypes.bool,
  state => selectValidationErrors(state.goldenRecords),
  (validationErrors) => validationErrors.every(errors => errors.isEmpty()),
);

export const selectNextRuleNameValid = createAppStateSelector(
  ArgTypes.func as Checker<(s: string) => boolean>,
  getNextRules,
  getNextEntityRule,
  (nextRules, nextEntityRule) => {
    const entityRuleName = nextEntityRule?.rule?.outputAttributeName.toLowerCase();
    const names = Set(nextRules.map(ruleDelta => ruleDelta.rule.outputAttributeName.toLowerCase()))
      .update(s => (entityRuleName ? s.add(entityRuleName) : s));
    // Case insensitive, no leading or trailing spaces, no dots, no repeat names, length > 0
    return (name: string) => {
      name = name.toLowerCase();
      return name.length > 0
        && (name.match(/^\w$/) || !!name.match(/^[^ \.][^\.]*?[^ \.]$/))
        && !names.has(name);
    };
  },
);

export const selectInputDedupRecipeId = createAppStateSelector(
  ArgTypes.orUndefined(ArgTypes.positiveInteger),
  selectAllProjectInfos,
  (state) => state.goldenRecords.goldenRecordDocument?.data?.clusterDataset?.id,
  (projectInfos, goldenRecordClusterDatsetId) => {
    if (!goldenRecordClusterDatsetId) {
      return undefined;
    }
    return projectInfos
      .find(projectInfo => !!projectInfo?.recipeDoc?.data?.outputDatasets?.get(goldenRecordClusterDatsetId))
      ?.recipeDoc?.id?.id;
  },
);


export const selectAllExpanded = createSelector(
  ({ goldenRecords }) => (goldenRecords.nextRules.size + (goldenRecords.nextEntityRule?.rule?.outputAttributeName ? 1 : 0)),
  ({ goldenRecords: { expandedRuleNames } }) => expandedRuleNames.size,
  (total, expanded) => total === expanded,
);

export const getIndexDraftJobIsRunning: AppSelector<boolean> = (state) => isJobRunning(state, ({ recipeOperation: INDEX_DRAFT, forActiveProject: true }));
export const getPublishJobIsRunning: AppSelector<boolean> = (state) => isJobRunning(state, ({ recipeOperation: UPDATE_PUBLISHED_DATASETS, forActiveProject: true }));
export const getUpdateSourceListJobIsRunning: AppSelector<boolean> = (state) => isJobRunning(state, ({ recipeOperation: UPDATE_SOURCE_LIST, forActiveProject: true }));
export const getUpdateSourceListJobIsRunningOrSubmitting: AppSelector<boolean> = (state) => state.goldenRecords.submittingUpdateSourceListJob || getUpdateSourceListJobIsRunning(state);
export const getUpdateClusterProfileJobIsRunning: AppSelector<boolean> = (state) => isJobRunning(state, ({ recipeOperation: UPDATE_CLUSTER_PROFILE, forActiveProject: true }));
export const getUpdateClusterProfileJobIsRunningOrSubmitting: AppSelector<boolean> = (state) => state.goldenRecords.submittingUpdateClusterProfileJob || getUpdateClusterProfileJobIsRunning(state);
export const getAnyProfilingJobIsRunningOrSubmitting: AppSelector<boolean> = (state) => getUpdateSourceListJobIsRunningOrSubmitting(state) || getUpdateClusterProfileJobIsRunningOrSubmitting(state);

export const selectIndexDraftHasBeenRun = createAppStateSelector(
  ArgTypes.bool,
  state => state.goldenRecords.moduleStatus,
  (moduleStatus) => !!(moduleStatus && _.isNumber(lastRunIndexDraft(moduleStatus))),
);

export const selectBookmarkedClusterIds: AppSelector<Set<string>> = createSelector(
  getAuthorizedUser,
  state => state.location.moduleId,
  (user, moduleId) => {
    const preference = isNumber(moduleId) && user?.preferencesForModuleById(moduleId)?.bookmarkedClusterIds;
    if (isArray(preference)) {
      return Set(preference);
    }
    return Set();
  },
);

export const isClusterIdBookmarked = (state: AppState, clusterId: string): boolean => {
  checkArg({ clusterId }, ArgTypes.string);
  return selectBookmarkedClusterIds(state).has(clusterId);
};

export const selectModuleFilterInfo: AppSelector<ModuleFilterInfo> = createSelector(
  s => s.location.moduleId,
  s => s.goldenRecords.moduleFetchSequence,
  (moduleId, fetchSequence) => ({ moduleId, fetchSequence }),
);

export const selectInputRecordsFilterInfo: AppSelector<InputRecordsFilterInfo> = createSelector(
  s => selectIsPreviewKnownToBeUsable(s.goldenRecords),
  selectBookmarkedClusterIds,
  (previewIsUsable, bookmarkedClusterIds) => ({ previewIsUsable, bookmarkedClusterIds: bookmarkedClusterIds.toArray() }),
);

export const selectModuleForPreview: Selector<AppState, GoldenRecordsModule.GoldenRecordsModule | undefined> = createSelector(
  getNextRules,
  getNextEntityRule,
  s => s.goldenRecords.goldenRecordDocument?.data,
  (nextRules, nextEntityRule, module) => module && nextEntityRule && update(module,
    setter('rules', nextRules.map(r => r.rule).toArray()),
    setter('entityRule', nextEntityRule.rule)),
);

export const selectRuleFromModuleFromLastUpdate = createAppStateSelector(
  ArgTypes.func as Checker<(name: string) => Rule.Rule | undefined>,
  s => s.goldenRecords.moduleFromLastUpdate,
  (module) => (ruleName) => module && ((module?.entityRule?.outputAttributeName === ruleName) ? module?.entityRule : module?.rules?.find(rule => rule.outputAttributeName === ruleName)),
);

export const selectPreviewFilterInfo: AppSelector<PreviewFilterInfo> = createSelector(
  s => s.goldenRecordsRules.previewFetchSequence,
  s => s.goldenRecordsRules.inputRecordsTable,
  (previewFetchSequence, input) => ({ previewFetchSequence, input }),
);

interface ClusterFilters {
  includedClusters: List<string>
  excludedClusters: List<string>
}

export const selectClustersForFilterInfo: AppSelector<ClusterFilters> = createSelector(
  state => state.goldenRecords.filterBookmarks,
  selectBookmarkedClusterIds,
  (filterBookmarks, clusters) => {
    // filterBookmarks is a set of two FilterBookmarkStates 'bookmarks', and 'noBookmarks'
    // Since we are intentionally using checkmarks, we need to be able to track if both are checked
    // even through that is the same as no filters
    if (filterBookmarks.size !== 1) { return { includedClusters: List(), excludedClusters: List() }; }
    if (filterBookmarks.has(FilterBookmarkState.bookmarks)) {
      return { includedClusters: clusters.toList(), excludedClusters: List() };
    }
    if (filterBookmarks.has(FilterBookmarkState.noBookmarks)) {
      return { includedClusters: List(), excludedClusters: clusters.toList() };
    }
    throw new Error(`Cannot select clusters for filter info - filterBookmarks has unexpected contents: ${filterBookmarks}`);
  },
);

export const selectFilterInfo: AppSelector<DraftRecordsFilterInfo> = createSelector(
  state => state.goldenRecords.draftRecordsFetchSequence,
  state => state.goldenRecords.pageNum,
  state => state.goldenRecords.pageSize,
  state => state.goldenRecords.searchString,
  state => state.goldenRecords.hasOverrides,
  state => state.goldenRecords.columnSortStates,
  selectClustersForFilterInfo,
  (draftRecordsFetchSequence, pageNum, pageSize, searchString, hasOverridesForAttribute, columnSortStates, { includedClusters, excludedClusters }) => ({ draftRecordsFetchSequence, pageNum, pageSize, searchString, hasOverridesForAttribute, columnSortStates, includedClusters, excludedClusters }),
);

const selectHideBookmarksOnboardingMessagePref = createAppStateSelector(
  ArgTypes.bool, // true if user wants to hide the onboarding message
  getAuthorizedUser,
  (user) => !!user?.globalPrefs.get('hideBookmarksOnboardingMessage'),
);

export const selectShowBookmarksOnboardingMessage = createAppStateSelector(
  ArgTypes.bool,
  s => s.goldenRecords.linkedFromOnboardingButton,
  selectHideBookmarksOnboardingMessagePref,
  (linkedFromOnboardingButton, hideBookmarksOnboardingMessage) => linkedFromOnboardingButton && !hideBookmarksOnboardingMessage,
);

export const getOnboardingLink: AppSelector<string> = state => getUrlForPage(state.setIn(['goldenRecords', 'linkedFromOnboardingButton'], true), 'goldenrecords');

export const CLUSTER_SAMPLE_TABLE_VERTICAL_PROPORTION_PREF_KEY = 'clusterSampleTableVerticalProportion';
const DEFAULT_CLUSTER_SAMPLE_TABLE_VERTICAL_PROPORTION = 0.5;

export const selectClusterSampleTableVerticalProportion: AppSelector<number> = createSelector(
  getAuthorizedUser,
  (user) => {
    const pref = user?.globalPrefs.get(CLUSTER_SAMPLE_TABLE_VERTICAL_PROPORTION_PREF_KEY);
    return isNumber(pref) ? pref : DEFAULT_CLUSTER_SAMPLE_TABLE_VERTICAL_PROPORTION;
  },
);

export const getModuleVersionOfLastRunIndexDraft: AppSelector<number | undefined> = createSelector(
  state => state.goldenRecords.moduleStatus,
  moduleStatus => moduleStatus && lastRunIndexDraft(moduleStatus),
);
