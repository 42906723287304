import { is } from 'immutable';

import { DEDUP } from '../constants/RecipeType';
import createLoader from '../utils/createLoader';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { fetch } from './ConfusionMatrixApi';
import { getFilterInfo } from './ConfusionMatrixStore';

// Fetch when unifiedDatasetName changes (and we're in a DEDUP project) or when sequence changes
const ConfusionMatrixLoader = createLoader((state) => {
  const { confusionMatrix: { loading, loadedFilterInfo } } = state;
  const projectInfo = selectActiveProjectInfo(state);
  return {
    canFetch: !!(projectInfo && projectInfo.isUnifiedDatasetIndexed && projectInfo.projectType === DEDUP),
    shouldFetch: !is(getFilterInfo(state), loadedFilterInfo),
    loading,
  };
}, {
  onFetch: fetch,
}, 'ConfusionMatrixLoader');

export default ConfusionMatrixLoader;
