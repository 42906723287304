import classNames from 'classnames';
import { Map } from 'immutable';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import ConditionalButton from '../components/ConditionalButton';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Input from '../components/Input/Input';
import LoadingPanel from '../components/LoadingPanel';
import TamrIcon from '../components/TamrIcon';
import Toggle from '../components/Toggle';
import TooltipTrigger from '../components/TooltipTrigger';
import WarningIcon from '../components/WarningIcon';
import * as UsersActions from '../constants/UsersActionTypes';
import { AppAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { isBlank, pluralize } from '../utils/Strings';
import style from './GroupConfigDialog.module.scss';
import { removeGroup, upsertGroup } from './UsersAsync';


const GroupMetadata: React.FC<{
  invalid: boolean
  groupname: string
  description: string
  loading?: boolean
  admin?: boolean
  isEditMode?: boolean
  onChangeName: (value: string) => void
  onChangeDescription: (value: string) => void
  onToggleAdminRole: (value: boolean) => void
}> = ({ invalid, groupname, description, loading, admin, isEditMode, onChangeName, onChangeDescription, onToggleAdminRole }) => {
  return loading ? <LoadingPanel /> : (
    <div>
      <TooltipTrigger placement="top" content={isEditMode ? 'Group names cannot be changed' : null}>
        <Input
          componentClassName={classNames(style.groupNameInput, isEditMode ? style.disabled : null)}
          title="Name"
          value={groupname}
          onChange={onChangeName}
          invalid={invalid}
          disabled={isEditMode}
        />
      </TooltipTrigger>
      {isEditMode ? null : <div className={style.warningIconWrapper}><WarningIcon size={16} />Group names cannot be changed</div>}
      <Input title="Description" componentClassName={style.groupDescriptionInput} value={description} onChange={onChangeDescription} />
      <div className={style.adminRow}>
        <Toggle className={style.adminToggle} value={!!admin} onChange={onToggleAdminRole} />
        <div className={style.adminLabel}>Admin</div>
        <TooltipTrigger placement="right" content="Admins have full access across all projects.">
          <TamrIcon iconName="info-outline" size={14} />
        </TooltipTrigger>
      </div>
    </div>
  );
};

const GroupConfigDialog = connect((state: AppState) => {
  const { users: { groups, groupConfig } } = state;
  return { groups, groupConfig };
}, {
  onHideGroupConfigDialog: (): AppAction => ({ type: UsersActions.STOP_GROUP_CONFIG }),
  onSetGroupName: (groupname: string): AppAction => ({ type: UsersActions.SET_GROUP_NAME, groupname }),
  onSetGroupDescription: (description: string): AppAction => ({ type: UsersActions.SET_GROUP_DESC, description }),
  onUpsertGroup: upsertGroup,
  onDeleteGroup: removeGroup,
  onToggleAdminRole: (): AppAction => ({ type: UsersActions.TOGGLE_ADMIN_ROLE }),
})(({ groups, groupConfig, onHideGroupConfigDialog, onSetGroupName, onSetGroupDescription, onUpsertGroup,
  onDeleteGroup, onToggleAdminRole }) => {
  return !groupConfig ? null : (
    <Dialog
      className={style.groupConfigDialog}
      show
      dialogStyle={DialogStyle.PRIMARY}
      onHide={onHideGroupConfigDialog}
      title={groupConfig.case<ReactNode>({
        Create: () => 'Create new group',
        Edit: () => 'Edit group',
        Delete: ({ name }) => (
          <div className={style.deleteGroupTitle}>
            <span className={style.leftText}>Delete "</span>
            <span className={style.nameText}>{ name }</span>
            <span className={style.rightText}>"</span>
          </div>
        ),
      })}
      body={groupConfig.case({
        Create: ({ groupname, description, admin }) =>
          <GroupMetadata
            {...{ groupname, description, admin }}
            onChangeName={onSetGroupName}
            onChangeDescription={onSetGroupDescription}
            onToggleAdminRole={onToggleAdminRole}
            invalid={Boolean(groups.find(group => group.data.groupname === groupname))}
            isEditMode={false}
          />,
        Edit: ({ description, admin, currentGroup }) =>
          <GroupMetadata
            {...{ description, admin }}
            groupname={currentGroup.data.groupname}
            onChangeName={() => {}}
            onChangeDescription={onSetGroupDescription}
            invalid={false} // group names are unchangable, so the name is always valid
            onToggleAdminRole={onToggleAdminRole}
            isEditMode
          />,
        Delete: ({ numberOfUsers }) =>
          <div className={style.body}>
            Are you sure? This group has:
            <ul className={style.groupStatsContainer}>
              <li>{numberOfUsers} {pluralize(numberOfUsers, 'user', 'users')}</li>
            </ul>
          </div>,
      })}
      footer={groupConfig.case({
        Create: ({ groupname }) => (
          <ButtonToolbar>
            <Button
              onClick={onHideGroupConfigDialog}
              buttonType="Secondary"
            >
              Cancel
            </Button>
            <ConditionalButton
              onClick={onUpsertGroup}
              preconditions={Map({
                'Group name cannot be blank': !isBlank(groupname),
                'Group name must be unique': !groups.find(group => group.data.groupname === groupname),
              })}
            >
              Create group
            </ConditionalButton>
          </ButtonToolbar>
        ),
        Edit: () => (
          <ButtonToolbar>
            <Button onClick={onHideGroupConfigDialog} buttonType="Secondary">Cancel</Button>
            <Button onClick={onUpsertGroup}>Update group</Button>
          </ButtonToolbar>
        ),
        Delete: () => (
          <ButtonToolbar>
            <Button onClick={onHideGroupConfigDialog} buttonType="Secondary">Cancel</Button>
            <Button onClick={onDeleteGroup}>Delete group</Button>
          </ButtonToolbar>
        ),
      })}
    />
  );
});

export default GroupConfigDialog;
