import enumify from './enumify';

export default enumify({
  DATASET_ALL: 'dataset',
  DATASET_RUN: 'dataset.run',
  DATASET_CREATE: 'dataset.create',
  DATASET_READ: 'dataset.read',
  DATASET_UPDATE: 'dataset.update',
  DATASET_DELETE: 'dataset.delete',
  PROJECT_ALL: 'project',
  PROJECT_RUN: 'project.run',
  PROJECT_CREATE: 'project.create',
  PROJECT_READ: 'project.read',
  PROJECT_UPDATE: 'project.update',
  PROJECT_DELETE: 'project.delete',
  PROJECT_UPDATE_VERIFY: 'project.update.verify',
  POLICY_UPDATE: 'policy.update',
  POLICY_UPDATE_RESOURCE_PROJECT: 'policy.update.resource.project',
  POLICY_UPDATE_MEMBER_PROJECT: 'policy.update.member.project',
  POLICY_UPDATE_DATASET_RESOURCE: 'policy.update.resource.dataset',
});
