import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import PageHeader from '../chrome/PageHeader';
import Button from '../components/Button';
import DetailSidebar from '../components/DetailSidebar/DetailSidebar';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import DatasetMetadataSpec from '../models/DatasetMetadataSpec';
import AddMetadataBlock from './AddMetadataBlock';
import DatasetMetadataLoader from './DatasetMetadataLoader';
import style from './MetadataSidebar.module.scss';
import MetadataTable from './MetadataTable';

const MetadataSidebar = _.compose(
  connect(state => {
    const { datasetMetadata: { currentMetadata, currentlyAddingProperty } } = state;
    return {
      currentMetadata,
      currentlyAddingProperty,
    };
  }, {
    onTriggerAddProperty: () => ({ type: 'DatasetMetadata.startAddProperty' }),
  }),
)(class MetadataSidebar extends React.Component {
  static propTypes = {
    currentMetadata: ImmutablePropTypes.listOf(PropTypes.instanceOf(DatasetMetadataSpec)),
    currentlyAddingProperty: PropTypes.bool,
    onTriggerAddProperty: PropTypes.func.isRequired,
  };

  render() {
    const { currentMetadata, currentlyAddingProperty, onTriggerAddProperty } = this.props;

    return (
      <DetailSidebar
        animate
      >
        <DatasetMetadataLoader />
        <PageHeader
          title={
            <span className={style.propertiesHeader}>
              Properties
              <span>
                <TooltipTrigger
                  placement="right"
                  content="Properties are key-value pairs that can be attached to either the dataset or any of its attributes."
                >
                  <TamrIcon
                    className={style.propertiesTooltip}
                    iconName="info-outline"
                    size={16}
                  />
                </TooltipTrigger>
              </span>
            </span>
          }
        />
        { currentMetadata.size > 0 ? <MetadataTable /> : null }
        { currentlyAddingProperty ?
          <AddMetadataBlock /> :
          currentMetadata.size > 0 ?
            <Button
              className={style.rightAddPropertyButton}
              buttonType="Secondary"
              onClick={onTriggerAddProperty}
            >
              Add property
            </Button> :
            <Button
              className={style.leftAddPropertyButton}
              buttonType="Secondary"
              onClick={onTriggerAddProperty}
            >
              Add property
            </Button>
        }
      </DetailSidebar>
    );
  }
});

export default MetadataSidebar;
