import _ from 'underscore';

import { mapObject } from '../../utils/Collections';
import { JsonArray, JsonContent, JsonObject } from '../../utils/typescript';
import { ARRAY_JSON_TYPE, ArrayType, BoolType, boolType, ConcreteType, DoubleType, doubleType, Field, FloatType, floatType, IntType, intType, LongType, longType, NumberType, RECORD_JSON_TYPE, RecordType, StringType, stringType, Type, typeIsConcrete } from './Types';

type NumericValue = number | '-Infinity' | 'Infinity' | 'NaN' | null

export type StringTypedValue = {
  typeDiscriminator: typeof StringType.jsonRep
  type: StringType
  value: string | null
}

export type IntTypedValue = {
  typeDiscriminator: typeof IntType.jsonRep
  type: IntType
  value: NumericValue
}

export type LongTypedValue = {
  typeDiscriminator: typeof LongType.jsonRep
  type: LongType
  value: NumericValue
}

export type FloatTypedValue = {
  typeDiscriminator: typeof FloatType.jsonRep
  type: FloatType
  value: NumericValue
}

export type DoubleTypedValue = {
  typeDiscriminator: typeof DoubleType.jsonRep
  type: DoubleType
  value: NumericValue
}

export type BoolTypedValue = {
  typeDiscriminator: typeof BoolType.jsonRep
  type: BoolType
  value: boolean | null
}

export type ArrayTypedValue = {
  typeDiscriminator: typeof ARRAY_JSON_TYPE
  type: ArrayType
  value: TypedValue[] | null
}

export type RecordTypedValue = {
  typeDiscriminator: typeof RECORD_JSON_TYPE
  type: RecordType
  value: { [key: string]: TypedValue } | null
}

export type TypedValue
  = StringTypedValue
  | IntTypedValue
  | LongTypedValue
  | FloatTypedValue
  | DoubleTypedValue
  | BoolTypedValue
  | ArrayTypedValue
  | RecordTypedValue;


/**
 * Helper functions for constructing the types above
 */

export function stringTypedValue(value: StringTypedValue['value'], type?: StringType): StringTypedValue {
  return { typeDiscriminator: StringType.jsonRep, type: type || stringType(), value };
}

export function intTypedValue(value: IntTypedValue['value'], type?: IntType): IntTypedValue {
  return { typeDiscriminator: IntType.jsonRep, type: type || intType(), value };
}

export function longTypedValue(value: LongTypedValue['value'], type?: LongType): LongTypedValue {
  return { typeDiscriminator: LongType.jsonRep, type: type || longType(), value };
}

export function floatTypedValue(value: FloatTypedValue['value'], type?: FloatType): FloatTypedValue {
  return { typeDiscriminator: FloatType.jsonRep, type: type || floatType(), value };
}

export function doubleTypedValue(value: DoubleTypedValue['value'], type?: DoubleType): DoubleTypedValue {
  return { typeDiscriminator: DoubleType.jsonRep, type: type || doubleType(), value };
}

export function boolTypedValue(value: BoolTypedValue['value'], type?: BoolType): BoolTypedValue {
  return { typeDiscriminator: BoolType.jsonRep, type: type || boolType(), value };
}

export function arrayTypedValue(value: JsonArray | null, type: ArrayType): ArrayTypedValue {
  return {
    typeDiscriminator: 'array',
    type,
    value: value === null
      ? value
      : value.map(v => typedValueConstructor(v, type.elementType)), // eslint-disable-line no-use-before-define
  };
}

export function arrayOfStringsTypedValue(value: Array<string | null> | null, type?: ArrayType): ArrayTypedValue {
  const elementType = type ? type.elementType : stringType();
  if (!(elementType instanceof StringType)) {
    throw new Error(`Could not create typed array of strings value because given ArrayType did not have a string elementType, found: ${elementType}`);
  }
  return arrayTypedValue(value, type || ArrayType.of(elementType));
}

export function recordTypedValue(value: JsonObject | null, type: RecordType): RecordTypedValue {
  return {
    typeDiscriminator: 'record',
    type,
    value: value === null
      ? value
      : mapObject(value, (fieldValue, fieldName) => {
        const fieldType = type.getField(fieldName);
        if (!typeIsConcrete(fieldType)) {
          throw new Error(`Could not create typed record value because nested field ${fieldName} has non-concrete type ${fieldType}`);
        }
        return typedValueConstructor(fieldValue, fieldType); // eslint-disable-line no-use-before-define
      }),
  };
}

type RenderableType = ConcreteType | NumberType;

function typeIsRenderable(type: Type): type is RenderableType {
  return type instanceof NumberType || typeIsConcrete(type);
}

export interface TypedValueConstructor {
  (value: StringTypedValue['value'], type: StringType): StringTypedValue
  (value: IntTypedValue['value'], type: IntType): IntTypedValue
  (value: LongTypedValue['value'], type: LongType): LongTypedValue
  (value: FloatTypedValue['value'], type: FloatType): FloatTypedValue
  (value: DoubleTypedValue['value'], type: DoubleType): DoubleTypedValue
  (value: BoolTypedValue['value'], type: BoolType): BoolTypedValue
  (value: JsonArray | null, type: ArrayType): ArrayTypedValue
  (value: JsonObject | null, type: RecordType): RecordTypedValue
  // generic fallthrough
  (value: JsonContent | null, type: Type): TypedValue
}

export const typedValueConstructor = (value: JsonContent, type: Type): TypedValue => {
  if (!typeIsRenderable(type)) {
    throw new Error(`Unsupported: rendering a value of type ${type}`);
  }
  if (value === null || value === undefined) {
    if (type instanceof StringType) return stringTypedValue(null, type);
    if (type instanceof IntType) return intTypedValue(null, type);
    if (type instanceof LongType) return longTypedValue(null, type);
    if (type instanceof FloatType) return floatTypedValue(null, type);
    if (type instanceof DoubleType) return doubleTypedValue(null, type);
    if (type instanceof NumberType) return doubleTypedValue(null); // special case
    if (type instanceof BoolType) return boolTypedValue(null, type);
    if (type instanceof ArrayType) return arrayTypedValue(null, type);
    if (type instanceof RecordType) return recordTypedValue(null, type);
  } else {
    if (_.isString(value) && type instanceof StringType) return stringTypedValue(value, type);
    if (_.isNumber(value) || value === '-Infinity' || value === 'Infinity' || value === 'NaN') {
      if (type instanceof IntType) return intTypedValue(value as NumericValue, type);
      if (type instanceof LongType) return longTypedValue(value as NumericValue, type);
      if (type instanceof FloatType) return floatTypedValue(value as NumericValue, type);
      if (type instanceof DoubleType) return doubleTypedValue(value as NumericValue, type);
      // special case: render generic Numbers as doubles
      if (type instanceof NumberType) return doubleTypedValue(value as NumericValue);
    }
    if (_.isBoolean(value) && type instanceof BoolType) return boolTypedValue(value, type);
    if (_.isArray(value) && type instanceof ArrayType) return arrayTypedValue(value, type);
    if (_.isObject(value) && type instanceof RecordType) return recordTypedValue(value as JsonObject, type);
  }
  throw new Error(`Could not convert value ${value} into typed value with type ${type}`);
};

export const constructTypedValue = typedValueConstructor as TypedValueConstructor;

export function inferTypeOfUnknownJson(value: JsonContent): Type {
  if (value === undefined || value === null) return stringType();
  if (_.isString(value)) return stringType();
  if (_.isNumber(value)) return doubleType();
  if (_.isBoolean(value)) return boolType();
  if (_.isArray(value)) {
    if (value.length === 0) return ArrayType.of(stringType());
    // important! assumes all members are of same type
    return ArrayType.of(inferTypeOfUnknownJson(value[0]));
  }
  if (_.isObject(value)) {
    const fields: Field[] = [];
    for (const key of Object.keys(value)) {
      fields.push(new Field({ name: key, type: inferTypeOfUnknownJson(value[key]) }));
    }
    return RecordType.fullySpecified(...fields);
  }
  // should never get here, but just in case, return a lowest-common-denominator type
  return ArrayType.of(stringType());
}

export function inferTypedValueOfUnknownJson(value: JsonContent): TypedValue {
  const type = inferTypeOfUnknownJson(value);
  return typedValueConstructor(value, type);
}
