import { Map, Set } from 'immutable';
import { GroupRecordsPage } from './GroupRecordsPage';
import { PreGroupBy } from './PreGroupBy';
import { PreGroupStats } from './PreGroupStats';
import { PregroupWarnings } from './PregroupStore';
import { RecordGroup } from './RecordGroup';

export const FETCH_DATA = 'Pregroup.fetchData';
export const FETCH_DATA_COMPLETED = 'Pregroup.fetchDataCompleted';
export const FETCH_DATA_FAILED = 'Pregroup.fetchDataFailed';
export const SET_SAMPLE_PAGE_NUM = 'Pregroup.setSamplePageNum';
export const SET_SAMPLE_PAGE_SIZE = 'Pregroup.setSamplePageSize';
export const SET_SHOW_SETTINGS = 'Pregroup.setShowSettings';
export const APPLY_SETTINGS = 'Pregroup.applySettings';
export const RESIZE_COLUMN = 'Pregroup.resizeColumn';
export const SET_AGGREGATION = 'Pregroup.setAggregation';
export const SAVE_GROUPING = 'Pregroup.saveGrouping';
export const SAVE_GROUPING_COMPLETED = 'Pregroup.saveGroupingCompleted';
export const SAVE_GROUPING_FAILED = 'Pregroup.saveGroupingFailed';
export const PREVIEW_GROUP_RECORDS = 'Pregroup.previewGroupRecords';
export const CANCEL_PREVIEW_GROUP_RECORDS = 'Pregroup.cancelPreviewGroupRecords';
export const FETCH_PREVIEW_GROUP_RECORDS = 'Pregroup.fetchPreviewGroupRecords';
export const FETCH_PREVIEW_GROUP_RECORDS_COMPLETED = 'Pregroup.fetchPreviewGroupRecordsCompleted';
export const FETCH_PREVIEW_GROUP_RECORDS_FAILED = 'Pregroup.fetchPreviewGroupRecordsFailed';
export const SET_PREVIEW_PAGE_NUMBER = 'Pregroup.setPreviewPageNumber';
export const SET_PREVIEW_PAGE_SIZE = 'Pregroup.setPreviewPageSize';
export const TOGGLE_EXCLUDE_DATASET_SELECTOR = 'Pregroup.toggleExcludeDatasetSelector';
export const SET_SHOW_METRICS = 'Pregroup.setShowMetrics';
export const SET_EXCLUDE_DATASET_SEARCH_VALUE = 'Pregroup.setExcludeDatasetSearchValue';
export const SET_EXCLUDE_DATASET_SELECTION_DELTA = 'Pregroup.setExcludeDatasetSelectionDelta';
export const SET_EXCLUDED_DATASETS = 'Pregroup.setExcludedDatasets';
export const SET_RECORD_GROUPING_ENABLED = 'Pregroup.setRecordGroupingEnabled';
export const RELOAD_DATA = 'Pregroup.reloadData';
export const SET_IS_PREVIEW_ENABLED = 'Pregoup.setPauseReload';
export const GROUPING_OUT_OF_DATE = 'Pregroup.outOfDate';
export const UPDATE_GROUPING = 'Pregroup.updateGrouping';
export const UPDATE_GROUPING_COMPLETED = 'Pregroup.updateGroupingCompleted';
export const SHOW_UP_TO_DATE_DIALOG = 'Pregroup.showUpToDateDialog';
export const HIDE_UP_TO_DATE_DIALOG = 'Pregroup.hideUpToDateDialog';

interface FetchData {
  type: typeof FETCH_DATA
}

interface FetchDataCompleted {
  type: typeof FETCH_DATA_COMPLETED
  sample: RecordGroup[]
  sampleNumInputRecords: number
  sampleMetrics: PreGroupStats | null
  sampleSpec: PreGroupBy | null
  warnings: PregroupWarnings
  excludedDatasetNames: Set<string>
}

interface FetchDataFailed {
  type: typeof FETCH_DATA_FAILED
  errorMessage: string
}

interface SetSamplePageNum {
  type: typeof SET_SAMPLE_PAGE_NUM
  pageNum: number
}

interface SetSamplePageSize {
  type: typeof SET_SAMPLE_PAGE_SIZE
  pageSize: number
}

interface SetExcludeDatasetSearchValue {
  type: typeof SET_EXCLUDE_DATASET_SEARCH_VALUE
  excludeDatasetSearchValue: string
}

interface SetExcludeDatasetSelectionDelta {
  type: typeof SET_EXCLUDE_DATASET_SELECTION_DELTA
  newDeltaMap: Map<string, boolean>
}

interface SetExcludedDatasets {
  type: typeof SET_EXCLUDED_DATASETS
  excludedDatasetNames: Set<string>
}

interface ResizeColumn {
  type: typeof RESIZE_COLUMN
  column: string
  width: number
}

interface SetShowSettings {
  type: typeof SET_SHOW_SETTINGS
  show: boolean
}

interface ApplySettings {
  type: typeof APPLY_SETTINGS
  numRecords: number
  maxGroupSize: number
}

interface SET_AGGREGATION {
  type: typeof SET_AGGREGATION
  fieldName: string
  aggregationFunction: string,
  doGroupNulls: boolean,
  isGrouping: true
}

interface SaveGrouping {
  type: typeof SAVE_GROUPING
}

interface SaveGroupingCompleted {
  type: typeof SAVE_GROUPING_COMPLETED
  sampleSpec: PreGroupBy | null
  outOfDate: boolean
}

interface GroupingOutOfDate {
  type: typeof GROUPING_OUT_OF_DATE
}

interface SaveGroupingFailed {
  type: typeof SAVE_GROUPING_FAILED
  message: string
}

interface PreviewGroupRecords {
  type: typeof PREVIEW_GROUP_RECORDS
  rowIndex: number
}

interface CancelPreviewGroupRecords {
  type: typeof CANCEL_PREVIEW_GROUP_RECORDS
}

interface FetchPreviewGroupRecords {
  type: typeof FETCH_PREVIEW_GROUP_RECORDS
}

interface FetchPreviewGroupRecordsCompleted {
  type: typeof FETCH_PREVIEW_GROUP_RECORDS_COMPLETED
  records: GroupRecordsPage
}

interface FetchPreviewGroupRecordsFailed {
  type: typeof FETCH_PREVIEW_GROUP_RECORDS_FAILED
  message: string
}

interface SetPreviewPageNumber {
  type: typeof SET_PREVIEW_PAGE_NUMBER
  pageNum: number
}

interface SetPreviewPageSize {
  type: typeof SET_PREVIEW_PAGE_SIZE
  pageSize: number
}

interface ToggleExcludeDatasetSelector {
  type: typeof TOGGLE_EXCLUDE_DATASET_SELECTOR
}

interface SetShowMetrics {
  type: typeof SET_SHOW_METRICS
}

interface SetRecordGroupingEnabled {
  type: typeof SET_RECORD_GROUPING_ENABLED
  isGroupingEnabled: boolean
}

interface ReloadData {
  type: typeof RELOAD_DATA
}

interface SetIsPreviewEnabled {
  type: typeof SET_IS_PREVIEW_ENABLED
  isPreviewEnabled: boolean
}

interface UpdateGrouping {
  type: typeof UPDATE_GROUPING
}

interface UpdateGroupingComplete {
  type: typeof UPDATE_GROUPING_COMPLETED
}

interface ShowUpToDateDialog {
  type: typeof SHOW_UP_TO_DATE_DIALOG
}

interface HideUpToDateDialog {
  type: typeof HIDE_UP_TO_DATE_DIALOG
}

export type PregroupActionTypes =
  | FetchData
  | FetchDataCompleted
  | FetchDataFailed
  | SetSamplePageNum
  | SetSamplePageSize
  | ResizeColumn
  | SetShowSettings
  | SetExcludeDatasetSearchValue
  | SetExcludeDatasetSelectionDelta
  | SetExcludedDatasets
  | ApplySettings
  | SET_AGGREGATION
  | SaveGrouping
  | SaveGroupingCompleted
  | SaveGroupingFailed
  | PreviewGroupRecords
  | CancelPreviewGroupRecords
  | FetchPreviewGroupRecords
  | FetchPreviewGroupRecordsCompleted
  | FetchPreviewGroupRecordsFailed
  | SetPreviewPageNumber
  | SetPreviewPageSize
  | ToggleExcludeDatasetSelector
  | SetRecordGroupingEnabled
  | SetShowMetrics
  | GroupingOutOfDate
  | ReloadData
  | SetIsPreviewEnabled
  | UpdateGroupingComplete
  | UpdateGrouping
  | ShowUpToDateDialog
  | HideUpToDateDialog;
