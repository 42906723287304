import { Map } from 'immutable';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import ConditionalButton from '../../components/ConditionalButton';
import { AppDispatch, AppState } from '../../stores/MainStore';
import Dialog from './Dialog';
import EstimateButton from './EstimateButton';
import * as slice from './slice';

const mapState = (state: AppState) => {
  const { metrics } = state.clusters.metrics.historical;
  return {
    historicalMetrics: metrics,
  };
};

const mapDispatch = (dispatch: AppDispatch) => {
  return {
    openDialog: () => dispatch(slice.openDialog()),
  };
};

type StateProps = ReturnType<typeof mapState>;
type DispatchProps = ReturnType<typeof mapDispatch>;
type Props = StateProps & DispatchProps;

const Metrics: React.FC<Props> = ({
  historicalMetrics,
  openDialog,
}) => {
  const estimate = <EstimateButton>Estimate cluster metrics</EstimateButton>;
  if (historicalMetrics === undefined) {
    return estimate;
  }
  const latestMetrics = _.maxBy(historicalMetrics, m => m.date);
  if (historicalMetrics.length === 0 || latestMetrics === undefined) {
    return estimate;
  }
  if (latestMetrics.precisionTestRecordsCount < 10 || latestMetrics.recallTestRecordsCount < 10) {
    return estimate;
  }
  return <>
    <ConditionalButton
      preconditions={Map({})}
      buttonType="Secondary"
      onClick={() => {
        openDialog();
      }}
    >
      View cluster metrics
    </ConditionalButton>
    <Dialog />
  </>;
};

export default connect(mapState, mapDispatch)(Metrics);
