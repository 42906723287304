import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Term from '../../components/Term';
import ProcurementTaxonomy from '../../models/ProcurementTaxonomy';
import { history } from '../../utils/History';
import TopNCard, { Bar } from '../TopNCard';

const TopCategoriesByCountCard = connect(state => {
  const { taxonomy: { taxonomy } } = state;
  return { taxonomy };
})(class TopCategoriesByCountCard extends React.Component {
  static propTypes = {
    c12nRecipeId: PropTypes.number.isRequired,
    taxonomy: PropTypes.instanceOf(ProcurementTaxonomy),
  };

  navigateToTaxonomy = () => {
    const { c12nRecipeId } = this.props;
    history.push(`/taxonomy/recipe/${c12nRecipeId}`);
  };

  render() {
    const { taxonomy } = this.props;
    if (!taxonomy) {
      return <div />;
    }
    const total = c => c.manualCategorizations.count + c.totalAggForSuggestedCategorizations.count;
    const bars = taxonomy.categories.toList()
      .filter(c => c.path.size === 1)
      .sortBy(c => c.path.first())
      .sortBy(c => -total(c))
      .map(c => new Bar({ value: total(c), label: c.path.first() }));
    return (
      <TopNCard
        onClick={this.navigateToTaxonomy}
        bars={bars}
        metric={<span>Number of <Term>Parts</Term></span>}
        nounPlural="Categories"
        nounSingular="Category"
      />
    );
  }
});

export default TopCategoriesByCountCard;
