import './TierBrowser.scss';

import Immutable, { is, List } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import _ from 'underscore';

import ProcurementCategory from '../models/ProcurementCategory';
import ProcurementTaxonomy from '../models/ProcurementTaxonomy';
import Tier from './Tier';

function isCategoryDescendant(possibleAncestor, possibleDescendant) {
  return is(possibleDescendant.path.take(possibleAncestor.path.size), possibleAncestor.path);
}

const MAX_COLS_BEFORE_SCROLL = 3;
/**
 * Component for exploring an individual taxonomy. Browse through columns representing each tier in the hierarchy.
 */
const TierBrowser = _.compose(
  connect(state => {
    const { taxonomy: { activeCategory, selectedCategories, taxonomy } } = state;
    return { taxonomy, activeCategory, selectedCategories };
  }, {
    onSelectCategory: selectedCategory => ({ type: 'Taxonomy.selectCategory', selectedCategory }),
  }),
)(/**
 * Component for exploring an individual taxonomy. Browse through columns representing each tier in the hierarchy.
 */
  class TierBrowser extends React.Component {
    static propTypes = {
      activeCategory: PropTypes.instanceOf(ProcurementCategory),
      canDeleteCategorizationsChecker: PropTypes.func,
      canDeleteCategoryChecker: PropTypes.func,
      categoryCellRenderer: PropTypes.func,
      comparator: PropTypes.func,
      disabledDeleteCategorizationsTooltip: PropTypes.func,
      disabledDeleteTooltip: PropTypes.func,
      distFromBottom: PropTypes.number,
      filter: PropTypes.func,
      onCreateCategory: PropTypes.func,
      onDeleteCategorizations: PropTypes.func,
      onDeleteCategory: PropTypes.func,
      onRenameCategory: PropTypes.func,
      onSelectCategory: PropTypes.func,
      searchString: PropTypes.string,
      selectedCategories: ImmutablePropTypes.listOf(ProcurementCategory),
      taxonomy: PropTypes.instanceOf(ProcurementTaxonomy),
      tierLabelMaxCounts: ImmutablePropTypes.mapOf(PropTypes.number, PropTypes.number),
    };

    static defaultProps = {
      comparator: (category) => category.path.get(-1),
    };

    render() {
    // TODO: Empty component considerations
      if (!this.props.taxonomy || !this.props.selectedCategories) {
        return <div />;
      }

      let numTiers = this.props.taxonomy.categories.reduce((memo, c) => {
        return c.path.size > memo ? c.path.size : memo;
      }, 0);

      let filteredCategories;
      let ghostIds = new Immutable.Set();
      if (this.props.filter) {
      // Store as a map at first to avoid duplicates
        filteredCategories = this.props.taxonomy.categories
          .filter(this.props.filter)
          .reduce((memo, category) => memo.set(category.categoryId, category), new Immutable.Map());

        // Add parents into filtered category with flag indicating that they were not part of the
        // search result (e.g. they are side-effects of the search)
        let parentCategories = new Immutable.Map();
        if (filteredCategories.size !== this.props.taxonomy.categories.size) {
          filteredCategories.forEach((category) => {
          // Find the parents
            category.path.butLast().forEach((pathSegment, segmentDepth) => {
              const parentCategory = this.props.taxonomy.findCategoryByPath(category.path.take(segmentDepth + 1));
              // only show ghost if they don't already pass the filter
              if (!filteredCategories.has(parentCategory.categoryId)) {
                ghostIds = ghostIds.add(parentCategory.categoryId);
              }
              parentCategories = parentCategories.set(parentCategory.categoryId, parentCategory);
            });
          });
        }
        filteredCategories = parentCategories.merge(filteredCategories).toList();
      } else {
        filteredCategories = this.props.taxonomy.categories;
      }

      // a map from tier number to category
      let categoriesGroupedByTier = filteredCategories.groupBy((category) => {
        return category.path.size;
      });
      // Fill in empty tiers
      _.times(numTiers, (i) => {
        if (!categoriesGroupedByTier.get(i + 1)) {
          categoriesGroupedByTier = categoriesGroupedByTier.set(i + 1, List());
        }
      });

      // filter that maps to respect selectedCategories
      const selectedCategoriesSortedByTier = this.props.selectedCategories.sortBy((category) => {
        return category.path.size;
      });
      selectedCategoriesSortedByTier.forEach((selectedCategory) => {
        const selectedCategoryTier = selectedCategory.path.size;
        for (let tierNumber = selectedCategoryTier + 1; tierNumber <= numTiers; tierNumber++) {
          categoriesGroupedByTier = categoriesGroupedByTier.update(tierNumber, cats => { // eslint-disable-line no-loop-func
            return cats.filter(_.partial(isCategoryDescendant, selectedCategory));
          });
        }
      });

      // If a leaf categorization is selected and there are no children and it is allowed to add a category
      const selectedPath = this.props.activeCategory ? this.props.activeCategory.path : List();
      const leafTierCategories = categoriesGroupedByTier.get(numTiers + 1);
      const actualNumTiers = numTiers;
      if (selectedPath.size === numTiers && this.props.onCreateCategory && (!leafTierCategories || leafTierCategories.isEmpty())) {
        categoriesGroupedByTier = categoriesGroupedByTier.set(numTiers + 1, List());
        numTiers++;
      }

      return (
        <div
          className="taxonomy-tier-browser-component"
          style={{ bottom: this.props.distFromBottom || 0 }}
      >
          <AutoSizer ref="autosizer" className="autosizer">
            {({ width, height }) => {
              const tierWidth = (1 / Math.min(numTiers, MAX_COLS_BEFORE_SCROLL)) * width * (actualNumTiers > MAX_COLS_BEFORE_SCROLL ? 0.90 : 1);
              return (
                <div
                  className="taxonomy-tier-browser-scroll-container"
                  style={{ width, height }}
              >
                  <div className="taxonomy-tier-browser-scroll" style={{ width: tierWidth * numTiers, height: height - 20 }}>
                    {categoriesGroupedByTier
                      .map((categories, tierNumber) => ({ categories, tierNumber }))
                      .toList()
                      .sortBy(({ tierNumber }) => tierNumber)
                      .map(({ categories, tierNumber }) =>
                        (<Tier
                          key={'tier' + tierNumber}
                          activeCategory={this.props.activeCategory}
                          canDeleteCategorizationsChecker={this.props.canDeleteCategorizationsChecker}
                          canDeleteCategoryChecker={this.props.canDeleteCategoryChecker}
                          categoryCellRenderer={this.props.categoryCellRenderer}
                          disabledDeleteCategorizationsTooltip={this.props.disabledDeleteCategorizationsTooltip}
                          disabledDeleteTooltip={this.props.disabledDeleteTooltip}
                          onCreateCategory={this.props.onCreateCategory}
                          onDeleteCategorizations={this.props.onDeleteCategorizations}
                          onDeleteCategory={this.props.onDeleteCategory}
                          onRenameCategory={this.props.onRenameCategory}
                          onSelectCategory={this.props.onSelectCategory}
                          selectedCategories={this.props.selectedCategories}
                          taxonomy={this.props.taxonomy}
                          tierCategories={categories.toList().sortBy(this.props.comparator)}
                          tierNumber={parseInt(tierNumber, 10)}
                          width={tierWidth}
                          ghostIds={ghostIds}
                          searchString={this.props.searchString}
                          tierLabelMaxCounts={this.props.tierLabelMaxCounts}
                      />),
                      )
                      .toArray()}
                  </div>
                </div>
              );
            }}
          </AutoSizer>
        </div>
      );
    }
  });

export default TierBrowser;
