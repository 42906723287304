import PropTypes from 'prop-types';
// @ts-expect-error for some reason we can't import uuid in TS?
import uuid from 'uuid';

import LedgerAction, { CREATE, DELETE, REORDER, UPDATE } from '../constants/LedgerAction';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import Model, { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

export class Location extends getModelHelpers({
  before: { type: ArgTypes.nullable(ArgTypes.string), defaultValue: null },
  after: { type: ArgTypes.nullable(ArgTypes.string), defaultValue: null },
}, 'Location')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class LocationRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static get proptype() { return PropTypes.instanceOf(this); }
  static fromJSON({ before, after }: $TSFixMe) {
    if (!before && !after) {
      return null;
    }
    return new Location({ before, after });
  }
}

/**
 * Generates the JS version of a com.tamr.persist.concurrent.list.Delta
 * @param payloadArgType The argType of the payload
 *
 * TODO samgqroberts 2020-09-30 I've just very dumbly converted this to TS. Ideally, this would use generics,
 *   and super-ideally this would stop using Model.ts altogether.
 */
export default function Generator(payloadArgType: $TSFixMe) {
  const Delta: $TSFixMe = Model({
    action: { type: LedgerAction.argType },
    guid: { type: ArgTypes.string },
    location: { type: ArgTypes.nullable(Location.argType), defaultValue: null },
    payload: { type: ArgTypes.nullable(payloadArgType), defaultValue: null },
    oldLocation: { type: ArgTypes.nullable(Location.argType), defaultValue: null },
    oldPayload: { type: ArgTypes.nullable(payloadArgType), defaultValue: null },
  }, 'LedgerDelta');

  Delta.propType = PropTypes.instanceOf(Delta);

  Delta.prototype.toServerJSON = function () {
    const { action, guid, location, payload, oldLocation, oldPayload } = this;
    return { action, guid, location: location?.toJSON() || null, payload: payload?.toServerJSON() || null, oldLocation: oldLocation?.toJSON() || null, oldPayload: oldPayload?.toServerJSON() || null };
  };

  Delta.create = ({ payload, before, after }: $TSFixMe) => new Delta({
    action: CREATE,
    guid: uuid.v4(),
    payload,
    location: Location.fromJSON({ before, after }),
  });

  Delta.reorder = ({ guid, oldBefore, oldAfter, before, after }: $TSFixMe) => new Delta({
    action: REORDER,
    guid,
    location: new Location({ before, after }),
    oldLocation: new Location({ before: oldBefore, after: oldAfter }),
  });

  Delta.update = ({ guid, payload, oldPayload }: $TSFixMe) => new Delta({
    action: UPDATE,
    guid,
    payload,
    oldPayload,
  });

  Delta.delete = ({ guid, oldPayload }: $TSFixMe) => new Delta({
    action: DELETE,
    guid,
    oldPayload,
  });

  Delta.fromJSON = ({ action, guid, location, payload, oldLocation, oldPayload }: $TSFixMe, dataConstructor: $TSFixMe) => {
    checkArg({ dataConstructor }, ArgTypes.func);

    return new Delta({
      action: action || undefined,
      guid: guid || undefined,
      location: location ? Location.fromJSON(location) : null,
      oldLocation: oldLocation ? Location.fromJSON(oldLocation) : null,
      payload: payload ? dataConstructor(payload) : null,
      oldPayload: oldPayload ? dataConstructor(oldPayload) : null,
    });
  };

  Delta.toJSON = ({ action, guid, payload, location, oldLocation, oldPayload }: $TSFixMe) => {
    return {
      action: action || undefined,
      guid: guid || undefined,
      location: location ? Location.fromJSON(location) : null,
      oldLocation: oldLocation ? Location.fromJSON(oldLocation) : null,
      payload: payload ? payload.toJSON() : null,
      oldPayload: oldPayload ? oldPayload.toJSON() : null,
    };
  };

  return Delta;
}
