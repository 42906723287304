import numeral from 'numeral';
import React from 'react';

import { ArgTypes, checkArg } from '../utils/ArgValidation';
import styles from './GeospatialCoordinate.module.scss';

/**
 * Render a geospatial coordinate (either longitude or latitude).
 */
function GeospatialCoordinate({ coordinate }) {
  checkArg({ coordinate }, ArgTypes.number);
  return (
    <div
      title={coordinate}
      className={styles.geoCoordinate}
    >
      {numeral(coordinate).format('0.000000')}
    </div>
  );
}

export default GeospatialCoordinate;
