import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import CenterContent from '../components/CenterContent';
import PopoverTrigger from '../components/PopoverTrigger';
import TamrIcon from '../components/TamrIcon';
import CategoryDashboardCategoryView from '../constants/CategoryDashboardCategoryView';
import CategoryDashboardTierSelector from '../constants/CategoryDashboardTierSelector';
import style from './CategorizationDashboardCategoriesTierSelector.module.scss';

const CategorizationDashboardCategoriesTierSelector = _.compose(
  connect(state => {
    const { categorizationDashboard: { taxonomy, selectedTier, selectedCategoryView } } = state;
    return { taxonomy, selectedTier, selectedCategoryView };
  }, {
    onSelectTier: selectedTier => ({ type: 'CategorizationDashboard.setSelectedTier', selectedTier }),
    onSelectCategoryView: selectedCategoryView => ({ type: 'CategorizationDashboard.setSelectedCategoryView', selectedCategoryView }),
  }),
)(({ taxonomy, selectedTier, selectedCategoryView, onSelectTier, onSelectCategoryView }) => {
  if (!taxonomy) {
    return <div />;
  }
  const numTiers = taxonomy.categories.map(category => category.path.size).max();
  const checkedIndicator = <TamrIcon iconName="radio-button-checked" size={14} />;
  const uncheckedIndicator = <TamrIcon iconName="radio-button-unchecked" size={14} />;
  const content = (
    <div className={style.viewSelection}>
      <div className={style.viewSelectionHeader}>View</div>
      <div>
        <div className={style.viewSelectionLabel}>
          <div className={style.viewSelector} onClick={() => onSelectCategoryView(CategoryDashboardCategoryView.TOTALS)}>{selectedCategoryView === CategoryDashboardCategoryView.TOTALS ? checkedIndicator : uncheckedIndicator}<span className={style.viewSelectorText}>Totals</span></div>
        </div>
        <div className={style.viewSelectionLabel}>
          <div className={style.viewSelector} onClick={() => onSelectCategoryView(CategoryDashboardCategoryView.VERIFIED)}>{selectedCategoryView === CategoryDashboardCategoryView.VERIFIED ? checkedIndicator : uncheckedIndicator}<span className={style.viewSelectorText}>Verified</span></div>
        </div>
      </div>
      <div className={style.viewSelectionHeader}>Categories</div>
      <div>
        {_.range(1, numTiers + 1)
          .map(tier => (
            <div key={`tier-${tier}`} className={style.viewSelectionLabel}>
              <div className={style.viewSelector} onClick={() => onSelectTier(tier)}>{tier === selectedTier ? checkedIndicator : uncheckedIndicator}<span className={style.viewSelectorText}>Tier {tier}</span></div>
            </div>
          ))
        }
        <div className={style.viewSelectionLabel}>
          <div className={style.viewSelector} onClick={() => onSelectTier(CategoryDashboardTierSelector.LEAF_NODES_ONLY)}>{CategoryDashboardTierSelector.LEAF_NODES_ONLY === selectedTier ? checkedIndicator : uncheckedIndicator}<span className={style.viewSelectorText}>All leaf categories</span></div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={style.optionsPopover}>
      <CenterContent horizontally={false}>
        <PopoverTrigger placement="bottom" content={content} className={style.popoverTrigger}>
          <TamrIcon className={style.dropdownIndicator} iconName="tamr-icon-more-vert" size={16} />
        </PopoverTrigger>
      </CenterContent>
    </div>
  );
});

export default CategorizationDashboardCategoriesTierSelector;
