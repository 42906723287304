import { InferProps } from 'prop-types';
import {
  connect,
  Dispatch,
  InferableComponentEnhancerWithProps,
} from 'react-redux';

import { AppState } from '../stores/MainStore';
import { $TSFixMe } from './typescript';

// this is included in the type definitions for react-redux in 7.x, but we are on 5.x.
export type ConnectedProps<TConnector> =
  TConnector extends InferableComponentEnhancerWithProps<infer TInjectedProps, any>
    ? TInjectedProps
    : never;

export function FCWithPropTypes<PropTypes, SpreadProps>(propTypes: PropTypes, render: (props: (InferProps<PropTypes> & SpreadProps)) => JSX.Element) {
  const retVal: React.FC<InferProps<PropTypes> & SpreadProps> = (props) => render(props);
  retVal.propTypes = propTypes;
  return retVal;
}

type MapStateToProps<OwnProps, StateProps> = (state: AppState, ownProps: OwnProps) => StateProps;

// TODO can we be better about type-checking that valid actions are being dispatched?
export type DispatchObject = {[key: string]: Function}
type MapDispatchToPropsFn<OwnProps, DispatchProps extends DispatchObject> = ((dispatch: Dispatch<$TSFixMe>, ownProps: OwnProps) => DispatchProps);

// not to be used if component has ownProps
// Deprecated: we are moving away from using this method. Use the connect() method directly
// instead.
export function ConnectedFC<StateProps, DispatchProps extends DispatchObject>(
  mapStateToProps: MapStateToProps<{}, StateProps>,
  mapDispatchToProps: DispatchProps | MapDispatchToPropsFn<InferProps<{}>, DispatchProps>,
  render: (props: StateProps & DispatchProps) => JSX.Element,
) {
  const baseCompononent: React.FC<StateProps & DispatchProps> = render;
  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(baseCompononent);
}

/*
 * Helper types for working with the basic react html elements, like <div /> or <input />.
 * Useful for creating components that spread props into one of these basic elements.
 */

export type HTMLDivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
export type HTMLButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
export type HTMLInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
export type HTMLAnchorProps = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;
export type HTMLSVGProps = React.SVGProps<SVGSVGElement>;
