import classNames from 'classnames';
import { List } from 'immutable';
import PropTypes, { InferProps, ReactNodeLike, Validator } from 'prop-types';
import React from 'react';
import { Tab as TabRB, Tabs } from 'react-bootstrap';
import ImmutablePropTypes from 'react-immutable-proptypes';

import CenterContent from '../CenterContent';
import styles from './DetailSidebar.module.scss';


type Tab = {
  content: ReactNodeLike
  eventKey: any
  title: string
}

const propTypes = {
  /**
   * If specified, controls which tab should be visible
   */
  activeKey: PropTypes.any,

  /**
   * Additional class name to add to the sidebar
   */
  className: PropTypes.string,

  /**
   * If specified, this component will be controlled and the state can and will be managed
   * externally by controlling the activeKey property
   */
  onSelect: PropTypes.func,

  /**
   * Tab contents
   */
  tabs: ImmutablePropTypes.listOf(PropTypes.shape({
    content: PropTypes.node.isRequired,
    eventKey: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired).isRequired as Validator<List<Tab>>,
};

type DetailSidebarTabbedContentProps = InferProps<typeof propTypes>;

/**
 * React element to be used in conjunction with the right sidebar for managing tabbed content in
 * said sidebar
 */
class DetailSidebarTabbedContent extends React.Component<DetailSidebarTabbedContentProps> {
  static propTypes = propTypes;

  render() {
    const containerClass = classNames(styles.sidebarTabbedContainer, this.props.className);
    const firstElement = this.props.tabs.first<Tab>();
    return (
      <Tabs
        {...this.props}
        onSelect={this.props.onSelect || undefined}
        className={containerClass}
        id="sidebar-tabs"
        defaultActiveKey={firstElement ? firstElement.eventKey : undefined}
        animation={false}
      >
        {
          this.props.tabs.map((tab) => (
            <TabRB
              key={tab.eventKey}
              title={<CenterContent>{tab.title}</CenterContent>}
              eventKey={tab.eventKey}
            >
              {tab.content}
            </TabRB>
          ))
        }
      </Tabs>
    );
  }
}

export default DetailSidebarTabbedContent;
