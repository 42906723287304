import { List, Map, Set } from 'immutable';
import uri from 'urijs';

import AttributeId from '../models/AttributeId';
import Dataref from '../models/Dataref';
import Document from '../models/doc/Document';
import MappingChange from '../models/MappingChange';
import ProfilingInfo from '../models/ProfilingInfo';
import UnifiedAttribute from '../models/UnifiedAttribute';
import { AnalysisInput, OperationListResult } from '../transforms/models/StaticAnalysisModels';
import { RecordType } from '../transforms/models/Types';
import {
  fetchAsResult,
  handleErrorsWithMessage,
  jsonHeaders,
  networkError,
  RawFetchResult,
  toJSON,
} from '../utils/Api';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import ServiceProxy from './ServiceProxy';

export function getDatasetType(datasetName: string): Promise<RecordType> {
  // datasetName is assumed to be unescaped at this point
  // we must replace certain characters (like spaces) with escape characters (like '\\ ')
  // before sending the name off to the get-dataset-types API
  const escapedDatasetName = Dataref.id(datasetName);
  return fetch(ServiceProxy.transform('/get-dataset-types'), { headers: jsonHeaders, body: JSON.stringify([escapedDatasetName]), method: 'POST' })
    .then(handleErrorsWithMessage(`Unable to fetch schema for ${datasetName || 'requested datasets'}`), networkError)
    .then(toJSON)
    .then(resp => RecordType.fromJSON(resp[escapedDatasetName]));
}

export function analyzeTypes(analysisInput: AnalysisInput): Promise<OperationListResult> {
  // TODO the dataset names in the keys of analysisInput.referenceTypes and analysisInput.referenceKeys
  //   must have special characters escaped (via Dataref::id) before sending request to server.
  // This is a latent bug! The only reason this doesn't manifest currently is because the sole current
  //   usage does not use referenced datasets.
  return fetch(ServiceProxy.transform('/analyze-types'), { headers: jsonHeaders, method: 'POST', body: JSON.stringify(analysisInput.toJSON()) })
    .then(handleErrorsWithMessage('Unable to perform static analysis'), networkError)
    .then(toJSON)
    .then(data => OperationListResult.fromCompressedJSON(data));
}

export function putUnifiedAttributes(
  attrs: UnifiedAttribute[],
  withoutMappings = true,
): Promise<Response> {
  return fetch(
    ServiceProxy.transform('/schema-mapping/unified-attributes'),
    {
      method: 'PUT',
      body: JSON.stringify(
        withoutMappings ? attrs.map(attr => attr.set('mappedAttributes', null)) : attrs,
      ),
      headers: jsonHeaders,
    },
  ).then(handleErrorsWithMessage('Unable to put unified attributes'), networkError);
}

export async function getAllUnifiedAttributeDocs(
  datasetName: string,
): Promise<List<Document<UnifiedAttribute>>> {
  return fetch(
    ServiceProxy.transform(`/schema-mapping/unified-attributes/${datasetName}`),
    { method: 'GET', headers: jsonHeaders },
  ).then(handleErrorsWithMessage(`Unable to fetch attributes from dataset '${datasetName}'`), networkError)
    .then(toJSON)
    .then(data => List(data).map(attrDoc => Document.fromJSON(attrDoc, UnifiedAttribute.fromJSON)));
}

export async function putOneUnifiedAttribute(
  currentAttrName: string,
  attr: UnifiedAttribute,
): Promise<Response> {
  // Current name provided seperately from attribute defintion to allow for lookup by name and enable renames
  const allAttrDocs = await getAllUnifiedAttributeDocs(attr.datasetName);
  const resourceId = allAttrDocs.find(a => a.data.name === currentAttrName)?.id.id;
  return fetch(
    ServiceProxy.transform(`/schema-mapping/unified-attribute/${resourceId}`),
    {
      method: 'PUT',
      body: JSON.stringify(attr),
      headers: jsonHeaders,
    },
  ).then(handleErrorsWithMessage(`Unable to update unified attribute '${resourceId}' to '${attr}'`), networkError);
}

export function getAllUnifiedAttributes(
  unifiedDatasetName: string,
  recipeId: number,
  filterInfo?: $TSFixMe,
): Promise<List<UnifiedAttribute>> {
  checkArg({ recipeId }, ArgTypes.positiveInteger);

  return fetch(
    uri(ServiceProxy.transform(`/schema-mapping/unified-attributes/${unifiedDatasetName}/display`))
      .query({
        tag: `recipe_${recipeId}`,
        threshold: filterInfo?.similarityThreshold || 1,
        mapped: filterInfo ? filterInfo.unifiedFilterMapped : true,
        unmapped: filterInfo ? filterInfo.unifiedFilterUnmapped : true,
        search: filterInfo?.unifiedSearchTerm,
        relatedAttrName: filterInfo?.getIn(['sourceFilterRelatedId', 'name']),
        relatedAttrDataset: filterInfo?.getIn(['sourceFilterRelatedId', 'datasetName']),
      }).toString(),
    {
      method: 'POST',
      headers: jsonHeaders,
      body: JSON.stringify(filterInfo ? filterInfo.unifiedFilterDatasets.toJS() : []),
      cache: 'no-cache',
    },
  ).then(handleErrorsWithMessage(
    `Unable to fetch unified attributes for unified dataset [${unifiedDatasetName}] ` +
    `from recipe ID ${recipeId}.\n` +
    'The dataset might have been deleted or not yet created.\n',
  ), networkError)
    .then(toJSON)
    .then(data => List(data).map(d => UnifiedAttribute.fromJSON(d)));
}

export function getTransforms(unifiedDatasetName: string): Promise<$TSFixMe> {
  return fetch(
    ServiceProxy.transform(`/transform/${unifiedDatasetName}`),
    { method: 'GET', headers: jsonHeaders, cache: 'no-cache' },
  ).then(handleErrorsWithMessage(`Unable to fetch transforms for UD ${unifiedDatasetName}`))
    .then(toJSON);
}

/**
 * Fetch profiling information for the given dataset names
 * @param datasetNames the list of dataset names
 */
export function doFetchProfiling(datasetNames: Set<string>): Promise<Map<string, ProfilingInfo>> {
  if (datasetNames.isEmpty()) {
    return Promise.resolve(Map<string, ProfilingInfo>());
  }

  return fetch(
    ServiceProxy.transform('/profile/query'),
    { method: 'POST', body: JSON.stringify(datasetNames), headers: jsonHeaders },
  ).then(handleErrorsWithMessage(`Unable to fetch profiling info for datasets ${datasetNames}`))
    .then(toJSON)
    .then(items => Map(List(items).map((pi: $TSFixMe) => [pi.datasetName, ProfilingInfo.fromJSON(pi)])));
}

export function postMappings(
  unifiedDatasetName: string,
  changes: List<MappingChange>,
): Promise<Response> {
  return fetch(
    ServiceProxy.transform(`/schema-mapping/unified-attributes/${unifiedDatasetName}/mappings`),
    {
      method: 'POST',
      body: JSON.stringify(changes.map(change => change.toJS()).toJS()),
      headers: jsonHeaders,
    },
  ).then(
    handleErrorsWithMessage(`Unable to post mappings for unified dataset ${unifiedDatasetName}`),
    networkError,
  );
}

export function bootstrapMappings(
  unifiedDatasetName: string,
  attributeIds: List<AttributeId>,
): Promise<Response> {
  return fetch(
    ServiceProxy.transform(`/schema-mapping/unified-attributes/${unifiedDatasetName}/bootstrap`),
    {
      method: 'POST',
      body: JSON.stringify(attributeIds.map(change => change.toJS()).toJS()),
      headers: jsonHeaders,
    },
  ).then(
    handleErrorsWithMessage(`Unable to bootstrap mappings for unified dataset ${unifiedDatasetName}`),
    networkError,
  );
}

export function deleteUnifiedAttributes(
  unifiedDatasetName: string,
  attributeIds: Set<AttributeId>,
): Promise<Response> {
  return fetch(
    ServiceProxy.transform(`/schema-mapping/unified-attributes/${unifiedDatasetName}/delete`),
    {
      method: 'POST',
      body: JSON.stringify(attributeIds.map(({ name }) => name)),
      headers: jsonHeaders,
    },
  ).then(
    handleErrorsWithMessage(
      `Unable to delete unified attributes of unified dataset ${unifiedDatasetName}`,
    ),
    networkError,
  );
}

export function checkSmrModelExportable(unifiedDatasetName: string): Promise<RawFetchResult> {
  return fetchAsResult(
    ServiceProxy.transform(`/schema-mapping/similarities/exportModel?targetDataset=${unifiedDatasetName}`),
    { method: 'HEAD' },
  );
}
