import { List } from 'immutable';

import CCProvider, { CCProviderK } from '../coreConnectService/CCProvider';
import CCRequestType from '../coreConnectService/CCRequestType';
import CoreConnectFileType, { CoreConnectFileTypeE } from '../coreConnectService/CoreConnectFileType';
import Dataset from '../models/Dataset';
import { jsonHeaders, toJSON } from '../utils/Api';
import {
  getRequestForProvider,
} from './CoreConnectMovementService';
import ServiceProxy from './ServiceProxy';

export interface BrowserFile {
  key: string;
  modified?: number;
  size?: number;
}

export interface CoreConnectJob {
  id: string;
  type: string;
}

export function getCoreConnectDefaultCloudProvider(): Promise<string> {
  return fetch(ServiceProxy.procure('/config/connect-default-cloud-provider'), {
    headers: jsonHeaders,
    method: 'GET',
  })
    .then(x => x.text());
}

export function getFilesWithCoreConnect({ provider, project, bucket, path, fileType }: {
  provider: string,
  project: string,
  bucket: string,
  path: string,
  fileType: CoreConnectFileTypeE,
}): Promise<List<BrowserFile>> {
  const request = getRequestForProvider({ requestType: CCRequestType.FILE,
    provider: CCProvider[provider as CCProviderK],
    projectId: project,
    bucket,
    path,
    fileType,
    datasetName: null,
    primaryKeys: null,
    policyIds: null,
    recipeId: undefined,
    profile: false,
  });
  const { url, body } = request;

  // Commented for Development purpose
  return fetch(ServiceProxy.procure(
    // @ts-ignore
    url,
  ),
  {
    headers: jsonHeaders,
    method: 'POST',
    body: JSON.stringify(body),
  },
  )
    .then(toJSON)
    .then(x => {
      if (!Array.isArray(x)) {
        return [{ name: '', date: null, size: null, relativeKey: '' }];
      }
      x.push({ name: '', date: null, size: 0, relativeKey: '' });
      return x;
    })
    .then((data: any) => {
      const files: BrowserFile[] = data.map((blob: any) => {
        return { key: blob.name, modified: parseInt(blob.date || 0, 10), size: parseInt(blob.size || 0, 10) };
      });
      return List(files);
    });
}

export function transferCloudToTamr({ coreconnectDefaultProvider, projectId, region, bucket, pathPrefix, fileType, primaryKeys, datasetName, policyIds, recipeId, profile, appendToDataset }: {
  coreconnectDefaultProvider: string,
  projectId: string,
  region: string,
  bucket: string,
  pathPrefix: string,
  fileType: CoreConnectFileTypeE,
  primaryKeys: List<string>,
  datasetName: string,
  policyIds: Array<number>,
  recipeId: number | undefined,
  profile: boolean,
  appendToDataset: boolean
}): Promise<CoreConnectJob> {
  const provider = CCProvider[coreconnectDefaultProvider as CCProviderK];
  const request = getRequestForProvider({ requestType: CCRequestType.INGEST,
    provider,
    projectId: provider === CCProvider.S3 ? region : projectId,
    bucket,
    path: pathPrefix,
    fileType,
    datasetName,
    primaryKeys,
    policyIds,
    recipeId,
    profile,
    appendToDataset,
  });
  const { url, body } = request;

  return fetch(ServiceProxy.procure(url), {
    headers: jsonHeaders,
    method: 'POST',
    body: JSON.stringify(body),
  })
    .then(toJSON)
    .then(data => {
      const { type, id } = data;
      return { type, id };
    });
}

export function getSchemaFromCloud({ coreconnectDefaultProvider, projectId, region, bucket, pathPrefix, fileType }: {
  coreconnectDefaultProvider: string,
  projectId: string,
  region: string,
  bucket: string,
  pathPrefix: string,
  fileType: CoreConnectFileTypeE,
}): Promise<List<string>> {
  const provider = CCProvider[coreconnectDefaultProvider as CCProviderK];
  const request = getRequestForProvider({ requestType: CCRequestType.SCHEMA,
    provider: CCProvider[coreconnectDefaultProvider as CCProviderK],
    projectId: provider === CCProvider.S3 ? region : projectId,
    bucket,
    path: pathPrefix,
    fileType,
    datasetName: null,
    primaryKeys: null,
    policyIds: null,
    recipeId: undefined,
    profile: false,
  });
  const { url, body } = request;

  return fetch(ServiceProxy.procure(url), {
    headers: jsonHeaders,
    method: 'POST',
    body: JSON.stringify(body),
  })
    .then(toJSON)
    .then(x => {
      if (!Array.isArray(x)) {
        throw new Error({ ...x, statusText: x.message });
      }
      return x;
    })
    .then(data => List(data.map((d: any) => d.name)));
}


export async function transferTamrToCloud({ coreconnectDefaultProvider, projectId, region, bucket, pathPrefix, fileType, sourceDataset }: {
  coreconnectDefaultProvider: string,
  projectId: string,
  region: string,
  bucket: string,
  pathPrefix: string,
  fileType: CoreConnectFileTypeE,
  sourceDataset: Dataset,
}): Promise<CoreConnectJob> {
  const renameAttributes = {};
  if (fileType === CoreConnectFileType.CSV) {
    try {
      await fetch(`/api/dataset/datasets/${sourceDataset.name}/schema`, {
        headers: {
          accept: 'application/json',
        },
      }).then(x => x.json())
        .then(x => x.recordType.fields.forEach(
          // @ts-expect-error
          ({ name }) => renameAttributes[name] = name.replace(/[ ,;{}<>()\n\t=]/g, '_')));
    } catch (e) {
      console.error(e);
    }
  }

  const provider = CCProvider[coreconnectDefaultProvider as CCProviderK];
  const request = getRequestForProvider({ requestType: CCRequestType.EXPORT,
    provider,
    projectId: provider === CCProvider.S3 ? region : projectId,
    bucket,
    path: pathPrefix,
    fileType,
    datasetName: sourceDataset.name,
    primaryKeys: null,
    policyIds: null,
    recipeId: undefined,
    profile: false,
  });
  const { url, body } = request;
  return fetch(ServiceProxy.procure(url), {
    headers: jsonHeaders,
    method: 'POST',
    body: JSON.stringify(body),
  })
    .then(toJSON)
    .then(data => {
      const { type, id } = data;
      return { type, id };
    });
}
