// com.tamr.procurify.models.FilterByExpert.Status

export const FilterByExpertStatus = Object.freeze({
  SKIP: 'SKIP',
  PENDING: 'PENDING',
  ANSWERED: 'ANSWERED',
  COMPLETE: 'COMPLETE',
  ALL: 'ALL',
  NO_FILTER: undefined,
});

export const determineFilterByExpertStatus = (isCompleteVar, isToDoVar) => {
  if (!isCompleteVar && !isToDoVar) {
    return FilterByExpertStatus.NO_FILTER;
  } if (isCompleteVar && !isToDoVar) {
    return FilterByExpertStatus.COMPLETE;
  } if (!isCompleteVar && isToDoVar) {
    return FilterByExpertStatus.PENDING;
  } if (isCompleteVar && isToDoVar) {
    return FilterByExpertStatus.ALL;
  }
};
