import { List, Map } from 'immutable';

import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';


class SpendData extends getModelHelpers({
  count: { type: ArgTypes.number },
  spend: { type: ArgTypes.number },
}, 'SpendData')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class SpendDataRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

// com.tamr.procurify.models.taxonomy.ProcurementCategory

const BASE_STATS = new SpendData({
  count: 0,
  spend: 0,
});

const BASE_SUGGESTIONS = Map({
  high: BASE_STATS,
  med: BASE_STATS,
  low: BASE_STATS,
});

export default class ProcurementCategory extends getModelHelpers({
  categoryId: { type: ArgTypes.number },
  path: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
  metadata: { type: ArgTypes.Immutable.map },
  createdBy: { type: ArgTypes.string },
  createdAt: { type: ArgTypes.number },
  modifiedBy: { type: ArgTypes.string },
  modifiedAt: { type: ArgTypes.number },
  manualCategorizations: { type: ArgTypes.instanceOf(SpendData) },
  suggestedCategorizations: { type: ArgTypes.Immutable.map.of(ArgTypes.instanceOf(SpendData), ArgTypes.string) },
}, 'ProcurementCategory')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ProcurementCategoryRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  get name() {
    return this.path.get(-1);
  }

  get totalAggForSuggestedCategorizations() {
    const summed = BASE_STATS.toJSON();
    this.suggestedCategorizations.forEach((spendData) => {
      summed.count += spendData.count;
      summed.spend += spendData.spend;
    });
    return new SpendData(summed);
  }

  // Convenience accessors
  get manualLabeledCount() {
    return this.manualCategorizations.count;
  }

  get manualLabeledSpend() {
    return this.manualCategorizations.spend;
  }

  get suggestedLabeledCount() {
    return this.suggestedCategorizations.reduce((memo, value) => memo + value.count, 0);
  }

  get suggestedLabeledSpend() {
    return this.suggestedCategorizations.reduce((memo, value) => memo + value.spend, 0);
  }

  get totalLabeledCount() {
    return this.manualLabeledCount + this.suggestedLabeledCount;
  }

  get totalLabeledSpend() {
    return this.manualLabeledSpend + this.suggestedLabeledSpend;
  }

  static fromJSON(obj: $TSFixMe) {
    return new ProcurementCategory({
      categoryId: obj.categoryId,
      path: List(obj.path),
      metadata: Map(obj.metadata),
      createdBy: obj.createdBy,
      createdAt: obj.createdAt,
      modifiedBy: obj.modifiedBy,
      modifiedAt: obj.modifiedAt,
      manualCategorizations: obj.manualCategorizations ? new SpendData(obj.manualCategorizations) : BASE_STATS,
      // @ts-expect-error
      suggestedCategorizations: obj.suggestedCategorizations ? Map(obj.suggestedCategorizations).map(spendData => new SpendData(spendData)) : BASE_SUGGESTIONS,
    });
  }
}
