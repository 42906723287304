import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Chart, Doughnut } from 'react-chartjs-2';
import { connect } from 'react-redux';
import _ from 'underscore';

import Term from '../components/Term';
import { history } from '../utils/History';
import PRODUCT_NAME from '../utils/ProductName';
import { pluralize } from '../utils/Strings';
import style from './CategorizationDashboardAssignmentChart.module.scss';
import CategorizationDashboardCompletedPanel from './CategorizationDashboardCompletedPanel';

const SHADOW_PLUGGIN_ID = 'doughnut-shadow';

const navigate = (url, onResetTransactionsFilterState) => {
  if (_.isFunction(onResetTransactionsFilterState)) {
    onResetTransactionsFilterState();
  }
  history.push(url);
};

const CategorizationDashboardAssignmentChart = _.compose(
  connect(state => {
    const { location: { recipeId }, categorizationDashboard: { numberOpen, numberToVerify, numberToVerifyAgree, numberToVerifyDisagree, numberVerified } } = state;
    return { recipeId, numberOpen, numberToVerify, numberToVerifyAgree, numberToVerifyDisagree, numberVerified };
  }, {
    onResetTransactionsFilterState: () => ({ type: 'Transactions.resetFilters' }),
  }),
)(class CategorizationDashboardAssignmentChart extends React.Component {
  static propTypes = {
    numberToVerifyAgree: PropTypes.number,
    numberToVerifyDisagree: PropTypes.number,
    onResetTransactionsFilterState: PropTypes.func.isRequired,
    recipeId: PropTypes.number,
  };

  state = {
    hover: false,
  };

  componentDidMount() {
    Chart.pluginService.register({
      id: SHADOW_PLUGGIN_ID,
      beforeDatasetsDraw: (chart, easing, options) => {
        if (!options.enabled) {
          return;
        }
        const { ctx, chartArea, outerRadius } = chart;
        if (outerRadius === 0) {
          return;
        }
        const centerX = chartArea.left + (chartArea.right - chartArea.left) / 2;
        const centerY = chartArea.top + (chartArea.bottom - chartArea.top) / 2;

        ctx.save();
        ctx.fillStyle = '#FFF';
        ctx.shadowBlur = 20;
        ctx.shadowColor = 'rgba(9, 28, 46, 0.10)';
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 10;

        ctx.beginPath();
        ctx.arc(centerX, centerY, outerRadius - 1, 0, Math.PI * 2);
        ctx.closePath();

        ctx.fill();
        ctx.restore();
        ctx.imageSmoothingEnabled = true;
      },
    });
  }

  render() {
    const { recipeId, numberToVerifyAgree, numberToVerifyDisagree, numberToVerify, onResetTransactionsFilterState } = this.props;
    const { hover } = this.state;

    const doughnutData = {
      datasets: [{
        data: [numberToVerifyAgree, numberToVerifyDisagree],
        backgroundColor: [style.agreeColor, style.disagreeColor],
        hoverBackgroundColor: [style.agreeColorHover, style.disagreeColorHover],
        borderWidth: [0, 0],
      }],
      labels: [
        `Single category suggested (${numberToVerifyAgree})`,
        `Disagreement on categories (${numberToVerifyDisagree})`,
      ],
    };
    const options = {
      legend: {
        display: false,
      },
      hover: {
        onHover: (e, chartElement) => {
          // HACK: track hover state here. This will then apply a hovered state to the containing React element
          this.setState({ hover: _.size(chartElement) > 0 });
        },
      },
      onClick: (event, chartElements) => {
        if (_.size(chartElements) === 1) {
          const index = chartElements[0]._index;
          if (index === 0) {
            navigate(`/spend/recipe/${recipeId}?expertResponsesAgree=true&unlabeled=true&assignmentStatusAll=true&assignmentStatusUnassigned=true&expertsSome=true&assignmentStatusAssigned=true`, onResetTransactionsFilterState);
          } else if (index === 1) {
            navigate(`/spend/recipe/${recipeId}?expertResponsesDisagree=true&unlabeled=true&assignmentStatusAll=true&assignmentStatusUnassigned=true&expertsSome=true&assignmentStatusAssigned=true`, onResetTransactionsFilterState);
          }
        }
      },
      title: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      plugins: {
        [SHADOW_PLUGGIN_ID]: {
          enabled: true,
        },
      },
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 40,
          bottom: 40,
        },
      },
    };

    const title = numberToVerify > 0
      ? <span>{numberToVerify} {pluralize(numberToVerify, 'Needs', 'Need')} Verification</span>
      : <span>No responses to verify</span>;

    const graph = numberToVerify > 0
      ? (
        <Doughnut
          ref="doughnut"
          data={doughnutData}
          options={options}
          width={100}
          height={170}
        />
      )
      : (
        <CategorizationDashboardCompletedPanel message={<span>Categorize your assignments or assign more <Term>records</Term> to improve {PRODUCT_NAME}'s confidence.</span>} />
      );

    const legend = numberToVerify > 0
      ? (
        <div className={style.legend}>
          <div className={style.rowWrapper}>
            <div className={style.legendRow}>
              <div className={classNames(style.legendBox, style.agreeBox)} />
              <div className={style.legendText}>Single category suggested <b>({numberToVerifyAgree})</b></div>
            </div>
          </div>
          <div className={style.rowWrapper}>
            <div className={style.legendRow}>
              <div className={classNames(style.legendBox, style.disagreeBox)} />
              <div className={style.legendText}>Disagreement on categories <b>({numberToVerifyDisagree})</b></div>
            </div>
          </div>
        </div>
      )
      : undefined;
    return (
      <div className={style.container}>
        <div className={style.title}>
          {title}
        </div>
        <div className={classNames({ [style.hover]: hover })}>
          {graph}
        </div>
        {legend}
      </div>
    );
  }
});

export default CategorizationDashboardAssignmentChart;
