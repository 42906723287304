import { ArgTypes } from '../utils/ArgValidation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';
import RecordPairId from './RecordPairId';

export class PairId extends getModelHelpers({
  entityId1: { type: ArgTypes.string },
  entityId2: { type: ArgTypes.string },
}, 'PairId')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class PairIdRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      const { entityId1, entityId2 } = args;
      // the correct order of entityId's follows: id1 < id2
      const swap = entityId1 > entityId2;
      const argsInCorrectOrder = {
        entityId1: swap ? entityId2 : entityId1,
        entityId2: swap ? entityId1 : entityId2,
      };
      super(argsInCorrectOrder);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  /**
   * instance methods
   */
  swapRecords(): PairId {
    const { entityId1, entityId2 } = this;
    return new PairId({ entityId2, entityId1 });
  }

  toRecordPairId() {
    const { entityId1, entityId2 } = this;
    return new RecordPairId({
      sourceId1: '',
      entityId1,
      sourceId2: '',
      entityId2,
    });
  }
}

export default PairId;
