import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '../components/Button';
import Cell from '../components/Table/Cell';
import { State } from '../stores/AppState';
import { PREVIEW_GROUP_RECORDS } from './PregroupActionTypes';
import style from './PregroupRecordsTable.module.scss';
import { selectGroupedRecordSize } from './PregroupStore';

function mapStateToProps(state: State, { rowIndex }: { rowIndex: number }) {
  return {
    count: selectGroupedRecordSize(rowIndex)(state),
  };
}

const PregroupRecordCountCell = connect(mapStateToProps, (dispatch, { rowIndex }) => bindActionCreators({
  onPreview: () => ({ type: PREVIEW_GROUP_RECORDS, rowIndex }),
}, dispatch))(({ count, onPreview }) => (
  <Cell className={style.groupSizeCell}>
    {count === 0
      ? '--'
      : <Button buttonType="Link" onClick={onPreview}>{count}</Button>
    }
  </Cell>
));

export default PregroupRecordCountCell;
