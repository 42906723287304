import { is, Map } from 'immutable';
import { bindActionCreators } from 'redux';

import createLoader from '../utils/createLoader';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { PaneE } from './Pane';
import { fetchTransactions } from './SuppliersAsync';
import { getClustersFilterInfo, getRecordsFilterInfo } from './SuppliersStore';

const SuppliersTxnLoader = createLoader((state, { pane }: { pane: PaneE}) => {
  const { suppliers: { [pane]: { loadedFilterInfo, recordsLoading, recordsLoadedFilterInfo } } } = state;
  const projectInfo = selectActiveProjectInfo(state);
  return {
    canFetch: !!projectInfo && !!projectInfo.unifiedDatasetName && !!projectInfo.unifiedDatasetId,
    shouldFetch: is(Map<any>(getClustersFilterInfo(state, pane)), Map(loadedFilterInfo)) && !is(getRecordsFilterInfo(state, pane), recordsLoadedFilterInfo),
    loading: recordsLoading,
  };
}, (dispatch, { pane }) => bindActionCreators({
  onFetch: () => fetchTransactions(pane),
}, dispatch), 'SuppliersTxnLoader');

export default SuppliersTxnLoader;
