import { connect } from 'react-redux';
import _ from 'underscore';

import BulkExpandTrigger from './BulkExpandTrigger';
import { SOURCE_TOGGLE_EXPAND_ALL } from './SchemaMappingActionTypes';

const SourceBulkExpandTrigger = _.compose(
  connect(({ schemaMapping }) => {
    const { sourceAttributes, sourceExpanded } = schemaMapping;
    return { allExpanded: sourceExpanded.size === sourceAttributes.size };
  }, {
    onToggle: () => ({ type: SOURCE_TOGGLE_EXPAND_ALL }),
  }),
)(BulkExpandTrigger);

export default SourceBulkExpandTrigger;
