import { Set } from 'immutable';
import moment from 'moment';
import React from 'react';

import { SidebarSection } from '../components/DetailSidebar/DetailSidebar';
import FeedbackStatus from '../constants/FeedbackStatus';
import EsFeedback from '../models/EsFeedback';
import EsRecord from '../models/EsRecord';
import ProcurementTaxonomy from '../models/ProcurementTaxonomy';
import CategoryFeedbackSidebar from './CategoryFeedbackSidebar';
import styles from './ExpertFeedback.module.scss';


/**
 * Collect all suggested categories
 * @returns {Set} of category Ids suggested by users for this record
 */
function getFeedbackCategories(record?: EsRecord): Set<number> {
  if (record) {
    const categoryIds = Set(record.categorizationSuggestions.map(suggestion => suggestion.categoryId));

    if (record.manualCategorization) {
      return categoryIds.add(record.manualCategorization.categoryId);
    }
    return categoryIds;
  }
  return Set();
}

function getNonAnsweredQuestions(record: EsRecord, status: FeedbackStatus): Set<EsFeedback> {
  return Set(record.feedback.filter(feedback => feedback.assignmentInfo?.status === status));
}

const NonAnsweredResponses: React.FC<{
  record: EsRecord
  status: FeedbackStatus
  sectionTitle: string
}> = ({ record, status, sectionTitle }) => {
  const pendingQuestions = getNonAnsweredQuestions(record, status);
  if (pendingQuestions.isEmpty()) {
    return <div />;
  }
  return (
    <SidebarSection title={sectionTitle}>
      <ul className={styles.outstandingQuestions}>
        {pendingQuestions.map(feedback => {
          const timestamp = feedback.assignmentInfo?.lastModified;
          return (
            <li
              key={`user-${feedback.username}`}
              className={styles.assignedUser}
              title={moment(timestamp).format()}
            >
              {feedback.username} - {moment(timestamp).fromNow()}
            </li>
          );
        })}
      </ul>
    </SidebarSection>
  );
};

const ExpertFeedback: React.FC<{
    isUserACurator: boolean
    isUserAVerifier: boolean
    record?: EsRecord
    taxonomy?: ProcurementTaxonomy
}> = ({ isUserACurator, isUserAVerifier, record, taxonomy }) => {
  if (!record || !taxonomy) {
    return null;
  }
  const feedbackCategories = getFeedbackCategories(record);
  const suggestedId = record?.suggestedCategorization ? record?.suggestedCategorization.categoryId : undefined;
  return (
    <SidebarSection title="Expert Categorizations">
      <div className={styles.categoriesContainer}>
        {feedbackCategories
          .filter(categoryId => categoryId !== suggestedId)
          .map(categoryId => (
            <CategoryFeedbackSidebar
              taxonomy={taxonomy}
              category={taxonomy.findCategoryById(categoryId)}
              key={`category-${categoryId}`}
              {...{ record, isUserACurator, isUserAVerifier }}
            />
          ))
        }
      </div>
      <NonAnsweredResponses {...{ record }} status={FeedbackStatus.PENDING} sectionTitle="Waiting for Response" />
      <NonAnsweredResponses {...{ record }} status={FeedbackStatus.SKIP} sectionTitle="Skipped Responses" />
    </SidebarSection>
  );
};

export default ExpertFeedback;
