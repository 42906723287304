// com.tamr.auth.AuthUser

import { List, Map, Set } from 'immutable';

import modelJSON from '../../../../../connect/auth/models/src/main/resources/fixtures/models/AuthUser.json';
import DataTables, { DataTablesType } from '../constants/DataTables';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getPath } from '../utils/Values';
import MinimalAuthUser from './MinimalAuthUser';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';
import PrivilegeSpec from './PrivilegeSpec';
import ResourceSpec from './ResourceSpec';
import User from './User';

const AUTHOR = 'unify_author';
const CURATOR = 'unify_curator';
const REVIEWER = 'unify_reviewer';
const VERIFIER = 'unify_verifier';
const PROJECT = 'unify_project';

export const Roles = {
  AUTHOR,
  CURATOR,
  REVIEWER,
  VERIFIER,
  PROJECT,
} as const;
export type RolesE = typeof Roles[keyof typeof Roles];

class AuthUser extends getModelHelpers({
  token: { type: ArgTypes.nullable(ArgTypes.string) },
  username: { type: ArgTypes.string },
  user: { type: ArgTypes.instanceOf(User) },
  groups: { type: ArgTypes.Immutable.set.of(ArgTypes.string) },
  preferences: { type: ArgTypes.Immutable.map },
  statistics: { type: ArgTypes.Immutable.map },
  volatileStatistics: { type: ArgTypes.Immutable.map },
  privilegesToResources: { type: ArgTypes.Immutable.map.of(ArgTypes.Immutable.set.of(ResourceSpec.argType), PrivilegeSpec.argType) },
  admin: { type: ArgTypes.bool },
}, 'AuthUser')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class AuthUserRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  get loginTime() {
    return this.statistics.get('loginTime');
  }

  preferencesForPage(pageName: DataTablesType, recipeId: number) {
    checkArg({ pageName }, DataTables.argType); // TODO "DataTables" is used for page level preferences as well
    checkArg({ recipeId }, ArgTypes.number);
    const columnsPreferences = this.preferences.get('columnsPreferences') || List(); // TODO all preferences are kept under "columnPreferences", even page level prefs
    return columnsPreferences.find((p: $TSFixMe) => p.recipeId === recipeId && p.page === pageName);
  }

  columnPreferencesForPage(pageName: DataTablesType, recipeId: number) {
    // TODO there's two levels of nesting for 'columnsPreferences' a lot of the time
    return List(getPath(this.preferencesForPage(pageName, recipeId), 'columnsPreferences'));
  }

  updatePagePrefs(pagePrefs: { recipeId: number, page: DataTablesType, columnsPreferences: $TSFixMe }) {
    const { recipeId, page } = pagePrefs;
    return this.updateIn(['preferences', 'columnsPreferences'], prefs => {
      return List(prefs) // empty list if undefined, listify if array, noop if already a List
        .filter((p: $TSFixMe) => !(p.recipeId === recipeId && p.page === page))
        .push(pagePrefs);
    });
  }

  updateModulePrefs(modulePrefs: { moduleId: number }) {
    checkArg({ modulePrefs }, ArgTypes.object.withShape({ moduleId: ArgTypes.positiveInteger }));
    const { moduleId } = modulePrefs;
    return this.updateIn(['preferences', 'modulePreferences'], prefs => List(prefs)
      .filter((p: $TSFixMe) => !(p.moduleId === moduleId)) // remove existing prefs for this module
      .push(modulePrefs));
  }

  updateGlobalPrefs(newPrefs: $TSFixMe) {
    checkArg({ newPrefs }, ArgTypes.object);
    return this.updateIn(['preferences', 'globalPreferences'], existingPrefs =>
      Map(existingPrefs) // empty map if undefined
        .merge(newPrefs),
    );
  }

  get globalPrefs() {
    return Map(this.getIn(['preferences', 'globalPreferences']));
  }

  preferencesForModuleById(moduleId: number) {
    checkArg({ moduleId }, ArgTypes.positiveInteger);
    return List(this.preferences?.get('modulePreferences')).find((prefs: $TSFixMe) => prefs.moduleId === moduleId);
  }

  static fromJSON(data: $TSFixMe) {
    checkArg({ data }, ArgTypes.object);
    return new AuthUser({
      token: data.token,
      username: data.username,
      user: new User(data.user),
      groups: Set(data.groups),
      preferences: Map(data.preferences),
      statistics: Map(data.statistics),
      volatileStatistics: Map(data.volatileStatistics),
      privilegesToResources: Map(data.privilegesToResources)
        .mapEntries(([privilegeSpec, resourceSpecSet]) =>
          // @ts-expect-error
          [PrivilegeSpec.fromJSON(privilegeSpec), Set(resourceSpecSet).map(resourceSpec => ResourceSpec.fromJSON(resourceSpec))],
        ),
      admin: data.admin,
    });
  }

  toMinimalAuthUser(): MinimalAuthUser {
    const { username, user, groups, privilegesToResources, preferences, statistics, volatileStatistics, admin } = this;
    return new MinimalAuthUser({ username, user, groups, privilegesToResources, preferences, statistics, volatileStatistics, admin });
  }
}

export const authUserJSON = modelJSON;
export const sampleAuthUser = AuthUser.fromJSON(authUserJSON);

export default AuthUser;
