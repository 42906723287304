import { List, Set } from 'immutable';

import { ConfidenceRangeE } from '../constants/ConfidenceRange';
import { ConfusionMatrixQuadrantE } from '../constants/ConfusionMatrixQuadrant';
import { LabelConsensusE } from '../constants/LabelConsensus';
import { RecordPairLabelFilterE } from '../constants/RecordPairLabelFilter';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import KeyMods from '../models/KeyMods';
import MinimalAuthUser from '../models/MinimalAuthUser';
import { PairComment } from '../models/PairComment';
import PairCommentId from '../models/PairCommentId';
import RecordPairId from '../models/RecordPairId';
import RecordPairWithData from '../models/RecordPairWithData';
import { $TSFixMe } from '../utils/typescript';
import { AllAssignmentsFilterTypeE } from './AllAssignmentsFilterType';
import { AllResponsesFilterTypeE } from './AllResponsesFilterType';
import { AssignmentStatusFilterTypeE } from './AssignmentStatusFilterType';
import AttributeSimilarityFilterModel from './AttributeSimilarityFilterModel';
import { RecordPairFeedbackResponseTypeE } from './RecordPairFeedbackResponseType';
import { RecordPairsFilterInfo } from './RecordPairsStore';
import { ResponseFilterTypeE } from './ResponseFilterType';
import UserDefinedSignal from './UserDefinedSignal';

export const RESET_FILTERS = 'RecordPairs.resetFilters';
export const SET_HAS_COMMENTS_FILTER = 'RecordPairs.setHasCommentsFilter';
export const SET_HAS_RESPONSES_FILTER = 'RecordPairs.setHasResponsesFilter';
export const SET_ASSIGNMENT_STATUS_FILTER = 'RecordPairs.setAssignmentStatusFilter';
export const SET_RESPONSE_FILTER = 'RecordPairs.setResponseFilter';
export const SET_ALL_ASSIGNMENTS_FILTER = 'RecordPairs.setAllAssignmentsFilter';
export const SET_ALL_RESPONSES_FILTER = 'RecordPairs.setAllResponsesFilter';
export const SET_HIGH_IMPACT_FILTER = 'RecordPairs.setHighImpactFilter';
export const UPDATE_ATTRIBUTE_SIMILARITY_FILTER = 'RecordPairs.updateAttributeSimilarityFilter';
export const ADD_ATTRIBUTE_SIMILARITY_FILTERS = 'RecordPairs.addAttributeSimilarityFilters';
export const DELETE_ATTRIBUTE_SIMILARITY_FILTERS = 'RecordPairs.deleteAttributeSimilarityFilters';
export const SET_LABEL_FILTERS = 'RecordPairs.setLabelFilters';
export const SET_CONFIDENCE_RANGE_FILTERS = 'RecordPairs.setConfidenceRangeFilter';
export const TOGGLE_ATTRIBUTE_SORT = 'RecordPairs.toggleAttributeSort';
export const SET_PAGE_NUM = 'RecordPairs.setPageNum';
export const SET_PAGE_SIZE = 'RecordPairs.setPageSize';
export const SET_SELECTED_DATASETS = 'RecordPairs.setSelectedDatasets';
export const SET_TOP_ROW_DATASET_NAME = 'RecordPairs.setTopRowDatasetName';
export const SET_BOTTOM_ROW_DATASET_NAME = 'RecordPairs.setBottomRowDatasetName';
export const SET_TOP_AND_BOTTOM_ROW_DATASET_NAMES = 'RecordPairs.setTopAndBottomRowDatasetNames';
export const SET_QUERY_STRING = 'RecordPairs.setQueryString';
export const FETCH_PAIRS = 'RecordPairs.fetchPairs';
export const FETCH_PAIRS_COMPLETED = 'RecordPairs.fetchPairsCompleted';
export const FETCH_PAIRS_FAILED = 'RecordPairs.fetchPairsFailed';
export const CHECK_FILTER_VIOLATIONS = 'RecordPairs.checkFilterViolations';
export const CHECK_FILTER_VIOLATIONS_COMPLETED = 'RecordPairs.checkFilterViolationsCompleted';
export const SHOW_PREDICT_WARNING = 'RecordPairs.showPredictWarning';
export const HIDE_PREDICT_WARNING = 'RecordPairs.hidePredictWarning';
export const SHOW_TRAIN_PREDICT_WARNING = 'RecordPairs.showTrainPredictWarning';
export const HIDE_TRAIN_PREDICT_WARNING = 'RecordPairs.hideTrainPredictWarning';
export const LAUNCH_TRAIN_PREDICT_CLUSTER = 'RecordPairs.launchTrainPredictCluster';
export const LAUNCH_PREDICT_CLUSTER = 'RecordPairs.launchPredictCluster';
export const LAUNCH_TRAIN_PREDICT_CLUSTER_COMPLETED = 'RecordPairs.launchTrainPredictClusterCompleted';
export const LAUNCH_PREDICT_CLUSTER_COMPLETED = 'RecordPairs.launchPredictClusterCompleted';
export const LAUNCH_TRAIN_PREDICT_CLUSTER_FAILED = 'RecordPairs.launchTrainPredictClusterFailed';
export const LAUNCH_PREDICT_CLUSTER_FAILED = 'RecordPairs.launchPredictClusterFailed';
export const SET_ACTIVE_PAIR = 'RecordPairs.setActivePair';
export const TOGGLE_EXPAND_SIDEBAR = 'RecordPairs.toggleExpandSidebar';
export const SHOW_SIDEBAR_TAB = 'RecordPairs.showSidebarTab';
export const REFRESH_PAIRS = 'RecordPairs.refreshPairs';
export const COMMENT = 'RecordPairs.comment';
export const COMMENT_COMPLETED = 'RecordPairs.commentCompleted';
export const COMMENT_FAILED = 'RecordPairs.commentFailed';
export const EDIT_COMMENT = 'RecordPairs.editComment';
export const EDIT_COMMENT_COMPLETED = 'RecordPairs.editCommentCompleted';
export const EDIT_COMMENT_FAILED = 'RecordPairs.editCommentFailed';
export const DELETE_COMMENT = 'RecordPairs.deleteComment';
export const DELETE_COMMENT_COMPLETED = 'RecordPairs.deleteCommentCompleted';
export const DELETE_COMMENT_FAILED = 'RecordPairs.deleteCommentFailed';
export const TOGGLE_CONFUSION_MATRIX_FILTER = 'RecordPairs.toggleConfusionMatrixFilter';
export const RESET_MATRIX_FILTER = 'RecordPairs.resetMatrixFilter';
export const TOGGLE_FILTER_PANEL = 'RecordPairs.toggleFilterPanel';
export const SHOW_DETAILS_DIALOG = 'RecordPairs.showDetailsDialog';
export const CLOSE_DETAILS_DIALOG = 'RecordPairs.closeDetailsDialog';
export const TOGGLE_DATASET_SELECTOR = 'RecordPairs.toggleDatasetSelector';
export const SET_DATASET_FILTER_SELECTION_DELTA = 'RecordPairs.setDatasetFilterSelectionDelta';
export const SET_DATASET_FILTER_SEARCH_VALUE = 'RecordPairs.setDatasetFilterSearchValue';
export const SHOW_ADD_ATTRIBUTE_SIMILARITY_FILTER_DIALOG = 'RecordPairs.showAddAttributeSimilarityFilterDialog';
export const CLOSE_ADD_ATTRIBUTE_SIMILARITY_FILTER_DIALOG = 'RecordPairs.closeAddAttributeSimilarityFilterDialog';
export const BEGIN_ASSIGNING_FEEDBACK = 'RecordPairs.beginAssigningFeedback';
export const STOP_ASSIGNING_FEEDBACK = 'RecordPairs.stopAssigningFeedback';
export const UPDATE_FEEDBACK_ASSIGNMENTS = 'RecordPairs.updateFeedbackAssignments';
export const UPDATE_FEEDBACK_ASSIGNMENTS_COMPLETED = 'RecordPairs.updateFeedbackAssignmentsCompleted';
export const PROVIDE_FEEDBACK_RESPONSES = 'RecordPairs.provideFeedbackResponses';
export const PROVIDE_FEEDBACK_RESPONSES_COMPLETED = 'RecordPairs.provideFeedbackResponsesCompleted';
export const REMOVE_FEEDBACK_RESPONSES = 'RecordPairs.removeFeedbackResponses';
export const REMOVE_FEEDBACK_RESPONSES_COMPLETED = 'RecordPairs.removeFeedbackResponsesCompleted';
export const RESPOND_AND_VERIFY = 'RecordPairs.respondAndVerify';
export const RESPOND_AND_VERIFY_COMPLETED = 'RecordPairs.respondAndVerifyCompleted';
export const REMOVE_RESPONSE_AND_VERIFY = 'RecordPairs.removeResponseAndVerify';
export const REMOVE_RESPONSE_AND_VERIFY_COMPLETED = 'RecordPairs.removeResponseAndVerifyCompleted';
export const WARN_ABOUT_REMOVE_RESPONSE = 'RecordPairs.warnAboutRemoveResponse';
export const CANCEL_REMOVING_RESPONSE = 'RecordPairs.cancelRemovingResponse';
export const SELECT_PAIR_ROW = 'RecordPairs.selectPairRow';
export const SELECT_ALL_PAIR_ROWS = 'RecordPairs.selectAllPairRows';
export const CLICK_GEOSPATIAL_SIMILARITY = 'RecordPairs.clickGeospatialSimilarity';
export const CLOSE_GEOSPATIAL_DETAILS = 'RecordPairs.closeGeospatialDetails';
export const OPEN_GEOSPATIAL_DETAILS_DIALOG = 'RecordPairs.openGeospatialDetailsDialog';
export const CLOSE_GEOSPATIAL_DETAILS_DIALOG = 'RecordPairs.closeGeospatialDetailsDialog';

interface RecordPairsResetFilters {
  type: typeof RESET_FILTERS
}

interface RecordPairsSetHasCommentsFilter {
  type: typeof SET_HAS_COMMENTS_FILTER
  hasComments: boolean
}

interface RecordPairsSetHasResponsesFilter {
  type: typeof SET_HAS_RESPONSES_FILTER
  hasResponses: boolean
}

export const SET_FILTER_TO_INFERRED_LABELS = 'RecordPairs.filterToInferredLabels';
interface RecordPairsSetFilterToInferredLabels {
  type: typeof SET_FILTER_TO_INFERRED_LABELS
  filterToInferredLabels: boolean
}

interface RecordPairsSetAssignmentStatusFilter {
  type: typeof SET_ASSIGNMENT_STATUS_FILTER
  assignmentStatus: AssignmentStatusFilterTypeE | null | undefined
}

interface RecordPairsSetResponseFilter {
  type: typeof SET_RESPONSE_FILTER
  response: ResponseFilterTypeE | undefined
}

interface RecordPairsSetAllAssignmentsFilter {
  type: typeof SET_ALL_ASSIGNMENTS_FILTER
  allAssignments: AllAssignmentsFilterTypeE | null | undefined
}

interface RecordPairsSetAllResponsesFilter {
  type: typeof SET_ALL_RESPONSES_FILTER
  allResponses: AllResponsesFilterTypeE | null | undefined
}

interface RecordPairsSetHighImpactFilter {
  type: typeof SET_HIGH_IMPACT_FILTER
  highImpact: boolean
}

interface RecordPairsUpdateAttributeSimilarityFilter {
  type: typeof UPDATE_ATTRIBUTE_SIMILARITY_FILTER
  filter: AttributeSimilarityFilterModel
}

interface RecordPairsAddAttributeSimilarityFilters {
  type: typeof ADD_ATTRIBUTE_SIMILARITY_FILTERS
  filters: Set<AttributeSimilarityFilterModel>
}

interface RecordPairsDeleteAttributeSimilarityFilters {
  type: typeof DELETE_ATTRIBUTE_SIMILARITY_FILTERS
  filterKeys: Set<string>
}

interface RecordPairsSetLabelFilters {
  type: typeof SET_LABEL_FILTERS
  humanFilters: Set<RecordPairLabelFilterE>,
  suggFilters: Set<RecordPairLabelFilterE>,
  labelConsensus: LabelConsensusE | undefined,
}

interface RecordPairsSetConfidenceRangeFilter {
  type: typeof SET_CONFIDENCE_RANGE_FILTERS
  confidenceRangeFilter: ConfidenceRangeE | undefined
}

interface RecordPairsToggleAttributeSort {
  type: typeof TOGGLE_ATTRIBUTE_SORT
  columnName: string
}

interface RecordPairsSetPageNum {
  type: typeof SET_PAGE_NUM
  pageNum: number
}

interface RecordPairsSetPageSize {
  type: typeof SET_PAGE_SIZE
  pageSize: number
}

interface RecordPairsSetSelectedDatasets {
  type: typeof SET_SELECTED_DATASETS
  selectedDatasetNames: Set<string>
}

interface RecordPairsSetTopRowDatasetName {
  type: typeof SET_TOP_ROW_DATASET_NAME
  datasetName: string
}

interface RecordPairsSetBottomRowDatasetName {
  type: typeof SET_BOTTOM_ROW_DATASET_NAME
  datasetName: string
}

interface RecordPairsSetTopAndBottomRowDatasetNames {
  type: typeof SET_TOP_AND_BOTTOM_ROW_DATASET_NAMES
  top: string
  bottom: string
}

interface RecordPairsSetQueryString {
  type: typeof SET_QUERY_STRING
  queryString: string
}

interface RecordPairsFetchPairs {
  type: typeof FETCH_PAIRS
}

interface RecordPairsFetchPairsCompleted {
  type: typeof FETCH_PAIRS_COMPLETED
  items: List<RecordPairWithData>
  unfilteredTotal: number
  total: number
  filterInfo: RecordPairsFilterInfo
  sourceDatasetDocs: List<Document<Dataset>>
}

interface RecordPairsFetchPairsFailed {
  type: typeof FETCH_PAIRS_FAILED
  filterInfo: RecordPairsFilterInfo
}

interface RecordPairsCheckFilterViolations {
  type: typeof CHECK_FILTER_VIOLATIONS
  rpIds: Set<RecordPairId>
}

interface RecordPairsCheckFilterViolationsCompleted {
  type: typeof CHECK_FILTER_VIOLATIONS_COMPLETED
  rpIds: Set<RecordPairId>
  currentPageIds: Set<RecordPairId>
}

interface RecordPairsShowPredictWarning {
  type: typeof SHOW_PREDICT_WARNING
}

interface RecordPairsHidePredictWarning {
  type: typeof HIDE_PREDICT_WARNING
}

interface RecordPairsShowTrainPredictWarning {
  type: typeof SHOW_TRAIN_PREDICT_WARNING
}

interface RecordPairsHideTrainPredictWarning {
  type: typeof HIDE_TRAIN_PREDICT_WARNING
}

interface RecordPairsLaunchTrainPredictCluster {
  type: typeof LAUNCH_TRAIN_PREDICT_CLUSTER
}

interface RecordPairsLaunchPredictCluster {
  type: typeof LAUNCH_PREDICT_CLUSTER
}

interface RecordPairsLaunchTrainPredictClusterCompleted {
  type: typeof LAUNCH_TRAIN_PREDICT_CLUSTER_COMPLETED
}

interface RecordPairsLaunchPredictClusterCompleted {
  type: typeof LAUNCH_PREDICT_CLUSTER_COMPLETED
}

interface RecordPairsLaunchTrainPredictClusterFailed {
  type: typeof LAUNCH_TRAIN_PREDICT_CLUSTER_FAILED
}

interface RecordPairsLaunchPredictClusterFailed {
  type: typeof LAUNCH_PREDICT_CLUSTER_FAILED
}

interface RecordPairsSetActivePair {
  type: typeof SET_ACTIVE_PAIR
  activeRowNumber: number
}

interface RecordPairsToggleExpandSidebar {
  type: typeof TOGGLE_EXPAND_SIDEBAR
}

interface RecordPairsShowSidebarTab {
  type: typeof SHOW_SIDEBAR_TAB
  tabKey: string
}

interface RecordPairsRefreshPairs {
  type: typeof REFRESH_PAIRS
}

interface RecordPairsComment {
  type: typeof COMMENT
  rpComment: PairComment
}

interface RecordPairsCommentCompleted {
  type: typeof COMMENT_COMPLETED
  rpComment: PairComment
}

interface RecordPairsCommentFailed {
  type: typeof COMMENT_FAILED
  rpComment: PairComment
  response: $TSFixMe
}

interface RecordPairsEditComment {
  type: typeof EDIT_COMMENT
  rpComment: PairComment
}

interface RecordPairsEditCommentFailed {
  type: typeof EDIT_COMMENT_FAILED
  rpComment: PairComment
  response: $TSFixMe
}

interface RecordPairsEditCommentCompleted {
  type: typeof EDIT_COMMENT_COMPLETED
  rpComment: PairComment
}

interface RecordPairsDeleteComment {
  type: typeof DELETE_COMMENT
  rpCommentId: PairCommentId
}

interface RecordPairsDeleteCommentCompleted {
  type: typeof DELETE_COMMENT_COMPLETED
  rpCommentId: PairCommentId
}

interface RecordPairsDeleteCommentFailed {
  type: typeof DELETE_COMMENT_FAILED
  response: $TSFixMe
}

interface RecordPairsToggleConfusionMatrixFilter {
  type: typeof TOGGLE_CONFUSION_MATRIX_FILTER
  quadrant: ConfusionMatrixQuadrantE
}

interface RecordPairsResetMatrixFilter {
  type: typeof RESET_MATRIX_FILTER
}

interface RecordPairsToggleFilterPanel {
  type: typeof TOGGLE_FILTER_PANEL
}

interface RecordPairsShowDetailsDialog {
  type: typeof SHOW_DETAILS_DIALOG
}

interface RecordPairsCloseDetailsDialog {
  type: typeof CLOSE_DETAILS_DIALOG
}

interface RecordPairsToggleDatasetSelector {
  type: typeof TOGGLE_DATASET_SELECTOR
}

interface RecordPairsSetDatasetFilterSelectionDelta {
  type: typeof SET_DATASET_FILTER_SELECTION_DELTA
  newDeltaMap: $TSFixMe
}

interface RecordPairsSetDatasetFilterSearchValue {
  type: typeof SET_DATASET_FILTER_SEARCH_VALUE
  searchValue: string
}

interface RecordPairsShowAddAttributeSimilarityFilterDialog {
  type: typeof SHOW_ADD_ATTRIBUTE_SIMILARITY_FILTER_DIALOG
}

interface RecordPairsCloseAddAttributeSimilarityFilterDialog {
  type: typeof CLOSE_ADD_ATTRIBUTE_SIMILARITY_FILTER_DIALOG
}

interface RecordPairsBeginAssigningFeedback {
  type: typeof BEGIN_ASSIGNING_FEEDBACK
  rowNumbers: Set<number>
}

interface RecordPairsStopAssigningFeedback {
  type: typeof STOP_ASSIGNING_FEEDBACK
}

interface RecordPairsUpdateFeedbackAssignments {
  type: typeof UPDATE_FEEDBACK_ASSIGNMENTS
  pairIndexes: Set<number>
  usersToAssign: Set<string>
  usersToUnassign: Set<string>
}

interface RecordPairsUpdateFeedbackAssignmentsCompleted {
  type: typeof UPDATE_FEEDBACK_ASSIGNMENTS_COMPLETED
  pairIndexes: Set<number>
}

interface RecordPairsProvideFeedbackResponses {
  type: typeof PROVIDE_FEEDBACK_RESPONSES
  responseKey: RecordPairFeedbackResponseTypeE
  rowNumbers: Set<number>
  loggedInUsername: string
  projectId: number
  users: List<MinimalAuthUser>
}

interface RecordPairsProvideFeedbackResponsesCompleted {
  type: typeof PROVIDE_FEEDBACK_RESPONSES_COMPLETED
  rowNumbers: Set<number>
}

interface RecordPairsRemoveFeedbackResponses {
  type: typeof REMOVE_FEEDBACK_RESPONSES
  rowNumbers: Set<number>
  loggedInUsername: string
  projectId: number
  users: List<MinimalAuthUser>
}

interface RecordPairsRemoveFeedbackResponsesCompleted {
  type: typeof REMOVE_FEEDBACK_RESPONSES_COMPLETED
  rowNumbers: Set<number>
}

interface RecordPairsRespondAndVerify {
  type: typeof RESPOND_AND_VERIFY
  rowNumbers: Set<number>
  responseKey: RecordPairFeedbackResponseTypeE
  loggedInUsername: string
}

interface RecordPairsRespondAndVerifyCompleted {
  type: typeof RESPOND_AND_VERIFY_COMPLETED
  rowNumbers: Set<number>
}

interface RecordPairsRemoveResponseAndVerify {
  type: typeof REMOVE_RESPONSE_AND_VERIFY
  rowNumbers: Set<number>
  loggedInUsername: string
  projectId: number
  users: List<MinimalAuthUser>
}

interface RecordPairsRemoveResponseAndVerifyCompleted {
  type: typeof REMOVE_RESPONSE_AND_VERIFY_COMPLETED
  rowNumbers: Set<number>
}

interface RecordPairsWarnAboutRemoveResponse {
  type: typeof WARN_ABOUT_REMOVE_RESPONSE
  action: 'SKIP' | 'REMOVE'
  rowNumbers: Set<number>
}

interface RecordPairsCancelRemovingResponse {
  type: typeof CANCEL_REMOVING_RESPONSE
}

interface RecordPairsSelectPairRow {
  type: typeof SELECT_PAIR_ROW
  keyMods: KeyMods
  rowNum: number
}

interface RecordPairsSelectAllPairRows {
  type: typeof SELECT_ALL_PAIR_ROWS
}

interface RecordPairsClickGeospatialSimilarity {
  type: typeof CLICK_GEOSPATIAL_SIMILARITY
  attributeName: string
}

interface RecordPairsCloseGeospatialDetails {
  type: typeof CLOSE_GEOSPATIAL_DETAILS
}

interface RecordPairsOpenGeospatialDetailsDialog {
  type: typeof OPEN_GEOSPATIAL_DETAILS_DIALOG
}

interface RecordPairsCloseGeospatialDetailsDialog {
  type: typeof CLOSE_GEOSPATIAL_DETAILS_DIALOG
}

export const FETCH_USER_DEFINED_SIGNALS = 'RecordPairs.fetchUserDefinedSignals';
interface RecordPairsFetchUserDefinedSignals {
  type: typeof FETCH_USER_DEFINED_SIGNALS
}

export const FETCH_USER_DEFINED_SIGNALS_COMPLETED = 'RecordPairs.fetchUserDefinedSignalsCompleted';
interface RecordPairsFetchUserDefinedSignalsCompleted {
  type: typeof FETCH_USER_DEFINED_SIGNALS_COMPLETED
  userDefinedSignals: List<UserDefinedSignal>
}

export const FETCH_USER_DEFINED_SIGNALS_FAILED = 'RecordPairs.fetchUserDefinedSignalsFailed';
interface RecordPairsFetchUserDefinedSignalsFailed {
  type: typeof FETCH_USER_DEFINED_SIGNALS_FAILED
}

export const TOGGLE_USER_DEFINED_SIGNAL_SORT = 'RecordPairs.toggleUserDefinedSignalSort';
interface RecordPairsToggleUserDefinedSignalSort {
  type: typeof TOGGLE_USER_DEFINED_SIGNAL_SORT
  udsExternalId: string
}

export type RecordPairsActionTypes
  = RecordPairsResetFilters
  | RecordPairsSetHasCommentsFilter
  | RecordPairsSetHasResponsesFilter
  | RecordPairsSetFilterToInferredLabels
  | RecordPairsSetAssignmentStatusFilter
  | RecordPairsSetResponseFilter
  | RecordPairsSetAllAssignmentsFilter
  | RecordPairsSetAllResponsesFilter
  | RecordPairsSetHighImpactFilter
  | RecordPairsUpdateAttributeSimilarityFilter
  | RecordPairsAddAttributeSimilarityFilters
  | RecordPairsDeleteAttributeSimilarityFilters
  | RecordPairsSetLabelFilters
  | RecordPairsSetConfidenceRangeFilter
  | RecordPairsToggleAttributeSort
  | RecordPairsSetPageNum
  | RecordPairsSetPageSize
  | RecordPairsSetSelectedDatasets
  | RecordPairsSetTopRowDatasetName
  | RecordPairsSetBottomRowDatasetName
  | RecordPairsSetTopAndBottomRowDatasetNames
  | RecordPairsSetQueryString
  | RecordPairsFetchPairs
  | RecordPairsFetchPairsCompleted
  | RecordPairsFetchPairsFailed
  | RecordPairsCheckFilterViolations
  | RecordPairsCheckFilterViolationsCompleted
  | RecordPairsShowPredictWarning
  | RecordPairsHidePredictWarning
  | RecordPairsShowTrainPredictWarning
  | RecordPairsHideTrainPredictWarning
  | RecordPairsLaunchTrainPredictCluster
  | RecordPairsLaunchPredictCluster
  | RecordPairsLaunchTrainPredictClusterCompleted
  | RecordPairsLaunchPredictClusterCompleted
  | RecordPairsLaunchTrainPredictClusterFailed
  | RecordPairsLaunchPredictClusterFailed
  | RecordPairsSetActivePair
  | RecordPairsToggleExpandSidebar
  | RecordPairsShowSidebarTab
  | RecordPairsRefreshPairs
  | RecordPairsComment
  | RecordPairsCommentCompleted
  | RecordPairsCommentFailed
  | RecordPairsEditComment
  | RecordPairsEditCommentCompleted
  | RecordPairsEditCommentFailed
  | RecordPairsDeleteComment
  | RecordPairsDeleteCommentCompleted
  | RecordPairsDeleteCommentFailed
  | RecordPairsToggleConfusionMatrixFilter
  | RecordPairsResetMatrixFilter
  | RecordPairsToggleFilterPanel
  | RecordPairsShowDetailsDialog
  | RecordPairsCloseDetailsDialog
  | RecordPairsToggleDatasetSelector
  | RecordPairsSetDatasetFilterSelectionDelta
  | RecordPairsSetDatasetFilterSearchValue
  | RecordPairsShowAddAttributeSimilarityFilterDialog
  | RecordPairsCloseAddAttributeSimilarityFilterDialog
  | RecordPairsBeginAssigningFeedback
  | RecordPairsStopAssigningFeedback
  | RecordPairsUpdateFeedbackAssignments
  | RecordPairsUpdateFeedbackAssignmentsCompleted
  | RecordPairsProvideFeedbackResponses
  | RecordPairsProvideFeedbackResponsesCompleted
  | RecordPairsRemoveFeedbackResponses
  | RecordPairsRemoveFeedbackResponsesCompleted
  | RecordPairsRespondAndVerify
  | RecordPairsRespondAndVerifyCompleted
  | RecordPairsRemoveResponseAndVerify
  | RecordPairsRemoveResponseAndVerifyCompleted
  | RecordPairsWarnAboutRemoveResponse
  | RecordPairsCancelRemovingResponse
  | RecordPairsSelectPairRow
  | RecordPairsSelectAllPairRows
  | RecordPairsClickGeospatialSimilarity
  | RecordPairsCloseGeospatialDetails
  | RecordPairsOpenGeospatialDetailsDialog
  | RecordPairsCloseGeospatialDetailsDialog
  | RecordPairsFetchUserDefinedSignals
  | RecordPairsFetchUserDefinedSignalsCompleted
  | RecordPairsFetchUserDefinedSignalsFailed
  | RecordPairsToggleUserDefinedSignalSort
