// com.tamr.procurify.models.feedback.AssignmentSummary
import { ArgTypes } from '../utils/ArgValidation';
import Model from './Model';

const AssignmentSummary = Model({
  username: { type: ArgTypes.string },
  pending: { type: ArgTypes.number },
  skipped: { type: ArgTypes.number },
  answered: { type: ArgTypes.number },
});

export default AssignmentSummary;
