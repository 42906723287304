import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';

import { TamrIconNames } from '../components/Icons/TamrIconClasses';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import style from './ListItemIcon.module.scss';

const propTypes = {
  containerClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  iconName: PropTypes.oneOf(TamrIconNames).isRequired,
  onClick: PropTypes.func,
  size: PropTypes.number,
  tooltip: PropTypes.node,
};

const defaultProps = { size: 12 };

// NB this can't be stateless functional component since it has to be given ref by PopoverTrigger elsewhere
class ListItemIcon extends React.Component<InferProps<typeof propTypes>> {
  static propTypes = propTypes;

  render() {
    const { iconName, onClick, containerClassName, iconClassName, size, tooltip, ...rest } = this.props;
    const containerClassNames = classNames(style.iconContainer, containerClassName, {
      [style.clickable]: !!onClick,
    });
    const iconClassNames = classNames(style.icon, iconClassName);

    let centeredIcon = (
      <div className={containerClassNames} onClick={onClick || undefined} role={onClick ? 'button' : undefined} {...rest}>
        <TamrIcon
          className={iconClassNames}
          iconName={iconName}
          size={size || defaultProps.size}
        />
      </div>
    );
    if (tooltip) {
      centeredIcon = (
        <TooltipTrigger placement="top" content={tooltip}>
          {centeredIcon}
        </TooltipTrigger>
      );
    }
    return centeredIcon;
  }
}

export default ListItemIcon;
