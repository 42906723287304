import { List } from 'immutable';
import $ from 'jquery';
import uri from 'urijs';

import { SHOW } from '../../errorDialog/ErrorDialogActionTypes';
import { selectActiveProjectInfo } from '../../utils/Selectors';

export const fetchSourceAttributes = () => (dispatch, getState) => {
  const { location: { recipeId } } = getState();
  dispatch({ type: 'SchemaMappingRecs.fetchSourceAttributes' });
  return $.ajax({
    url: uri(SERVICES.recipe(`/datasets/${recipeId}/input-attributes`)),
    cache: false,
  }).then((data) => {
    dispatch({ type: 'SchemaMappingRecs.fetchSourceAttributesCompleted', data: List(data) });
  }, (response) => {
    dispatch({ type: 'SchemaMappingRecs.fetchSourceAttributesFailed' });
    dispatch({ type: SHOW, detail: 'Error loading source attributes', response });
  });
};

export const fetchUnifiedAttributes = () => (dispatch, getState) => {
  const { unifiedDatasetName } = selectActiveProjectInfo(getState());
  dispatch({ type: 'SchemaMappingRecs.fetchUnifiedAttributes' });
  return $.ajax({
    url: SERVICES.transform(`/schema-mapping/unified-attributes/${unifiedDatasetName}`),
    cache: false,
  }).then((data) => {
    dispatch({ type: 'SchemaMappingRecs.fetchUnifiedAttributesCompleted', data: List(data) });
  }, (response) => {
    dispatch({ type: 'SchemaMappingRecs.fetchUnifiedAttributesFailed' });
    dispatch({ type: SHOW, detail: 'Error loading unified attributes', response });
  });
};
