import React from 'react';
import { connect } from 'react-redux';

import style from '../components/CommentList.module.scss';
import LoadingPanel from '../components/LoadingPanel';
import PairsComment from '../components/PairsComment';
import RecordPairWithData from '../models/RecordPairWithData';
import { AppState } from '../stores/MainStore';
import { editComment, removeComment } from './RecordPairsAsync';


type PairsCommentListOwnProps = {
  pair: RecordPairWithData,
}

const mapStateToProps = ({ recordPairs: { currentlyProcessing } }: AppState, { pair }: PairsCommentListOwnProps) => {
  return {
    pair,
    currentlyProcessing: currentlyProcessing.has(pair.toPairId().toRecordPairId()),
  };
};

const mapDispatchToProps = {
  onEdit: editComment,
  onDelete: removeComment,
};

type PairsCommentListProps
  = PairsCommentListOwnProps
  & ReturnType<typeof mapStateToProps>
  & typeof mapDispatchToProps

class PairsCommentList extends React.Component<PairsCommentListProps> {
  componentDidUpdate(prevProps: PairsCommentListProps) {
    if (prevProps.pair.comments.size !== this.props.pair.comments.size) {
      // @ts-expect-error
      this.refs.commentList.scrollTo({ top, behavior: 'smooth' });
    }
  }

  render() {
    const { pair, onDelete, onEdit, currentlyProcessing } = this.props;
    const listSize = pair.comments.size;
    return (
      <React.Fragment>
        <div className={style.commentSectionDivide}>
          <hr className={style.commentSeparator} />
          {(listSize > 0
            && (
            <div className={style.commentsCount}>
              {listSize} {listSize > 1 ? ' COMMENTS' : ' COMMENT'}
            </div>
            ))}
        </div>
        <div ref="commentList" className={style.commentsList}>
          {currentlyProcessing
            ? <LoadingPanel semiTransparent />
            : null}
          {pair.comments
            .sortBy(comment => comment.createdAt * -1)
            .map((comment, key) => (
              <PairsComment
                onEditComment={(message: string) => onEdit(message, comment)}
                onDeleteComment={onDelete}
                comment={comment}
                key={key}
              />
            ))}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PairsCommentList);
