export const ManualPairLabelType = {
  MATCH: 'MATCH',
  NON_MATCH: 'NON_MATCH',
  UNCERTAIN: 'UNCERTAIN',
} as const;
export type ManualPairLabelTypeE = typeof ManualPairLabelType[keyof typeof ManualPairLabelType];

export const SuggestedPairLabelType = {
  MATCH: 'MATCH',
  NON_MATCH: 'NON_MATCH',
} as const;
export type SuggestedPairLabelTypeE = typeof SuggestedPairLabelType[keyof typeof SuggestedPairLabelType];

const PairLabelTypes = {
  manual: ManualPairLabelType,
  suggested: SuggestedPairLabelType,
} as const;
export type PairLabelTypesE = typeof PairLabelTypes[keyof typeof PairLabelTypes];

export default PairLabelTypes;
