import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import FilterButton from '../components/FilterButton';
import Term from '../components/Term';

const RecordPairsFilterPanelButton = _.compose(
  connect(({ recordPairs: { noFiltersOn, pendingResolution, showFilterPanel } }) => {
    return { noFiltersOn, anyPending: !!pendingResolution.size, showFilterPanel };
  }, {
    onToggle: () => ({ type: 'RecordPairs.toggleFilterPanel' }),
    onClear: () => ({ type: 'RecordPairs.resetFilters' }),
    onRefresh: () => ({ type: 'RecordPairs.refreshPairs' }),
  }),
)(({ onToggle, noFiltersOn, onClear, anyPending, onRefresh, showFilterPanel }) => {
  return (
    <FilterButton onClick={onToggle} hasFilters={!noFiltersOn} filterExpanded={showFilterPanel} onClear={noFiltersOn ? undefined : onClear} onRefresh={anyPending ? onRefresh : undefined} noun={<Term>pairs</Term>} />
  );
});

export default RecordPairsFilterPanelButton;
