import React from 'react';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import LoadingPanel from '../components/LoadingPanel';
import MultiValue, { recordValueShim } from '../components/MultiValue';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Table from '../components/Table/Table';
import { AppState } from '../stores/MainStore';
import { ArrayType, RecordType, Type, TypeArgType } from '../transforms/models/Types';
import { checkArg } from '../utils/ArgValidation';
import useForceUpdate from '../utils/useForceUpdate';
import style from './DatasetPreviewDialog.module.scss';
import { CLEAR_DATASET_SAMPLE, SET_DATASET_SAMPLE_COLUMN_WIDTH } from './SharedDatasetCatalogActionTypes';
import { getDatasetSampleColumns } from './SharedDatasetCatalogStore';


const shouldRenderAsJson = (type: Type | undefined): boolean => {
  checkArg({ type }, TypeArgType);
  if (!type) return false;
  if (type instanceof RecordType) return true;
  if (type instanceof ArrayType) {
    const { elementType } = type;
    if ((elementType instanceof ArrayType) || (elementType instanceof RecordType)) {
      return true;
    }
  }
  // ... since the type is either an atom or an array of atoms
  return false;
};

const DatasetPreviewDialog = connect((state: AppState) => {
  const { sharedDatasetCatalog: { datasetSample, datasetSampleRows, datasetSampleSchema, datasetSampleColumnWidths }, sharedDatasetCatalog } = state;
  const rows = datasetSampleRows;
  const columns = getDatasetSampleColumns(sharedDatasetCatalog);
  const show = !!datasetSample;
  const loading = !!(show && !datasetSampleSchema);
  return { show, loading, datasetSample, rows, columns, schema: datasetSampleSchema, columnWidths: datasetSampleColumnWidths };
}, {
  onHide: () => ({ type: CLEAR_DATASET_SAMPLE }),
  onResizeColumn: ({ column, width }: { column: string, width: number }) => ({ type: SET_DATASET_SAMPLE_COLUMN_WIDTH, column, width }),
})(({ show, loading, onHide, onResizeColumn, datasetSample, rows, columns, schema, columnWidths }) => {
  const forceUpdate = useForceUpdate();
  return (
    <Dialog
      show={show}
      onHide={onHide}
      title={`Preview of ${datasetSample}`}
      dialogStyle={DialogStyle.FULL}
      body={
        <div className={style.previewDatasetTable}>
          {
            loading ? (
              <LoadingPanel />
            ) : show ? (
              <AutoSizer>
                {({ width, height }) => (
                  <Table
                    tableType="stripes"
                    getLength={() => rows.size}
                    {... { height, width }}
                    rowHeight={30}
                    onColumnResizeEndCallback={(newWidth, column) => {
                      /**
                       * Force a rerender to address an issue where the column resizer line doesn't disappear
                       *
                       * r/t https://github.com/facebookarchive/fixed-data-table/issues/401
                       */
                      //
                      forceUpdate();
                      onResizeColumn({ column, width: newWidth });
                    }}
                  >
                    {columns.map(columnName => (
                      <Column
                        key={columnName}
                        columnKey={columnName}
                        width={columnWidths.get(columnName) || 50}
                        header={<Cell>{columnName}</Cell>}
                        cell={({ rowIndex }) => (
                          <Cell>
                            {(() => {
                              const value = rows.get(rowIndex)[columnName];
                              const fieldType = schema?.getField(columnName);
                              // TODO(pcattori): shouldRenderAsJson should not swallow GeoTamr, but currently it does
                              if (shouldRenderAsJson(fieldType)) return JSON.stringify(value);
                              return <MultiValue values={recordValueShim({ value })} />;
                            })()}
                          </Cell>
                        )}
                        isResizable
                      />
                    ))}
                  </Table>
                )}
              </AutoSizer>
            ) : null
          }
        </div>
      }
      footer={(
        <ButtonToolbar>
          <Button onClick={onHide} buttonType="Secondary">Close</Button>
        </ButtonToolbar>
      )}
    />
  );
});

export default DatasetPreviewDialog;
