import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import CenterContent from '../components/CenterContent';
import LoadingPanel from '../components/LoadingPanel';
import AllUnifiedAttributesLoader from '../schema-mapping/AllUnifiedAttributesLoader';
import SourceAttributesLoader from '../schema-mapping/SourceAttributesLoader';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { getSchemaMappingStatus } from './DashboardUtils';
import CompletedSourcesCard from './schema-mapping-recommendations/CompletedSourcesCard';
import SourceDatasetsCard from './schema-mapping-recommendations/SourceDatasetsCard';
import UnifiedDatasetCard from './schema-mapping-recommendations/UnifiedDatasetCard';
import SchemaMappingDashboardGuide from './SchemaMappingDashboardGuide';

const SchemaMappingDashboard = _.compose(
  connect(state => {
    return {
      projectInfo: selectActiveProjectInfo(state),
      stepCompletionStatuses: getSchemaMappingStatus(state),
      loading: state.schemaMapping.allUnifiedLoading,
    };
  }),
)(({ loading, projectInfo, stepCompletionStatuses }) => {
  const { smRecipeId } = projectInfo;
  return <div style={{ height: '100%', width: '100%' }}>
    <AllUnifiedAttributesLoader />
    {loading
      ? <LoadingPanel />
      : !(stepCompletionStatuses?.hasAddedDatasets && stepCompletionStatuses?.hasMappedAttributes)
        ? <SchemaMappingDashboardGuide />
        : <CenterContent className="project-dashboard" vertically={false}>
          <div className="cards-container">
            <SourceAttributesLoader />
            <AllUnifiedAttributesLoader />
            <div className="left-column">
              <SourceDatasetsCard smRecipeId={smRecipeId} />
              <CompletedSourcesCard smRecipeId={smRecipeId} projectInfo={projectInfo} />
            </div>
            <div className="right-column">
              <UnifiedDatasetCard smRecipeId={smRecipeId} projectInfo={projectInfo} />
            </div>
          </div>
        </CenterContent>
    }
  </div>;
});

export default SchemaMappingDashboard;
