import Transforms from './src/main/antlr/com/tamr/transform/antlr/Transforms.g4';

export default Transforms;
export const TransformsLexer = Transforms.TransformsLexer;
export const TransformsParser = Transforms.TransformsParser;
export const TransformsListener = Transforms.TransformsListener;
export const TransformsVisitor = Transforms.TransformsVisitor;

const keywords = Object.keys(Transforms.TransformsLexer)
  .filter(k => k.startsWith('K_'))
  .map(k => {
    const keyword = k.substring(2).toUpperCase();
    // if the keyword name is not the same as the literal text of the keyword, we need
    // to translate it -- otherwise syntax highlighting and autocomplete will not work as
    // expected.
    if (keyword === 'CONVERT_ARRAY') {
      return 'ARRAY.OF';
    }
    if (keyword === 'CONVERT_STRUCT')
      return 'STRUCT.OF';
    return keyword;
  })


export const TransformsKeywords = keywords;
