import { Map } from 'immutable';
import React from 'react';

import Button from '../components/Button';
import ConditionalButton from '../components/ConditionalButton';
import Toolbar from '../components/Toolbar';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { ConnectedFC } from '../utils/typescript-react';
import style from './GoldenRecordsHeader.module.scss'; // shared with other page
import GoldenRecordsReprofileWarning from './GoldenRecordsReprofileWarning';
import { TOGGLE_SIDEBAR, TRIGGER_PREVIEW_FETCH } from './GoldenRecordsRulesActionTypes';
import { getOnboardingLink, selectSavable } from './GoldenRecordsSelectors';
import { UpdateGoldenRecordsButton } from './UpdateGoldenRecordsButton';

// helper component
const SelectBookmarksButton = ConnectedFC(
  (state) => {
    const linkToAllRecordsPage = getOnboardingLink(state);
    return { linkToAllRecordsPage };
  },
  {},
  ({ linkToAllRecordsPage }) => {
    return (
      <Button buttonType="Secondary" href={linkToAllRecordsPage} icon="bookmark" style={{ marginRight: '16px' }}>
        Select Bookmarks
      </Button>
    );
  },
);

const GoldenRecordsRulesHeader = ConnectedFC(
  (state) => {
    const { goldenRecordsRules: { showSidebar }, goldenRecords: { goldenRecordDocument } } = state;
    const savable = selectSavable(state);
    const projectInfo = selectActiveProjectInfo(state);
    const projectName = goldenRecordDocument?.data?.displayName || projectInfo?.projectName;
    return { showSidebar, savable, projectName };
  },
  {
    toggleSidebar: () => ({ type: TOGGLE_SIDEBAR }),
    onPreview: () => ({ type: TRIGGER_PREVIEW_FETCH }),
  },
  ({ showSidebar, toggleSidebar, onPreview, savable, projectName }) => {
    return (
      <div className={style.filterContainer}>
        <Toolbar
          className="my-8"
          left={(
            <span className={style.title}>
              {projectName}
            </span>
          )}
          right={<UpdateGoldenRecordsButton />}
        />
        <Toolbar
          className="mb-8"
          left={(
            <React.Fragment>
              <SelectBookmarksButton />
              <GoldenRecordsReprofileWarning />
            </React.Fragment>
          )}
          right={(
            <React.Fragment>
              <ConditionalButton
                buttonType="Secondary"
                onClick={onPreview}
                style={{ marginRight: '16px' }}
                preconditions={Map<string, boolean>().set('Some rules have errors', savable)}
              >
                Preview rules
              </ConditionalButton>
              <Button buttonType="Secondary" onClick={() => toggleSidebar()}>
                {showSidebar ? 'Close rules' : 'Open rules'}
              </Button>
            </React.Fragment>
          )}
        />
      </div>
    );
  },
);

export default GoldenRecordsRulesHeader;
