import enumify from './enumify';

// synced with CategorizationSuggestion.Response java enum

export enum ResponseE {
  AGREE = 'AGREE',
  DISAGREE = 'DISAGREE'
}

export const AGREE = ResponseE.AGREE;
export const DISAGREE = ResponseE.DISAGREE;

export default enumify({ AGREE, DISAGREE });
