import { List, Set } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';

import Button from '../components/Button';
import ColumnWidthProvider from '../components/ColumnWidthProvider';
import Checkbox from '../components/Input/Checkbox';
import Labeler, { LabelerSelectionStateE } from '../components/Labeler';
import LoadingPanel from '../components/LoadingPanel';
import SearchBox from '../components/SearchBox';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Pager from '../components/Table/PagerModel';
import Table from '../components/Table/Table';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import { UNTAGGED_ID } from '../constants/UntaggedId';
import DatasetDescriptionColumn from '../datasets/DatasetDescriptionColumn';
import DatasetNameColumn from '../datasets/DatasetNameColumn';
import DatasetTagsColumn from '../datasets/DatasetTagsColumn';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import { QueryBuilder } from '../models/doc/Query';
import Tag from '../models/Tag';
import { AppAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
// @ts-expect-error
import { setTagColor } from '../tags/TagStore';
import { isDefined } from '../utils/Values';
import style from './DatasetFilter.module.scss';
import DatasetFilterLoader from './DatasetFilterLoader';

const PAGE_SIZES = List.of(25, 50, 100, 500, 1000);

const mapStateToProps = (state: AppState) => {
  const {
    tags: { allTags: tags },
    datasetFilter: {
      disabledDatasetIds,
      selectedDatasets,
      datasets,
      datasetsToAdd,
      datasetsToRemove,
      loading,
      page,
      pageSize,
      searchValue,
      numDatasets,
      tagSearchValue,
      selectedTags,
    },
  } = state;
  return {
    tags: tags as Set<Document<Tag>>,
    disabledDatasetIds,
    selectedDatasets,
    datasets,
    datasetsToAdd,
    datasetsToRemove,
    loading,
    page,
    pageSize,
    searchValue,
    numDatasets,
    tagSearchValue,
    selectedTags,
  };
};

const mapDispatchToProps = {
  toggleRow: (rowNumber: number): AppAction => ({ type: 'DatasetFilter.toggleRow', rowNumber }),
  toggleRowExclusive: (rowNumber: number): AppAction => ({ type: 'DatasetFilter.toggleRowExclusive', rowNumber }),
  toggleAll: (): AppAction => ({ type: 'DatasetFilter.toggleAll' }),
  setPage: (page: number): AppAction => ({ type: 'DatasetFilter.setPage', page }),
  setPageSize: (pageSize: number): AppAction => ({ type: 'DatasetFilter.setPageSize', pageSize }),
  setSearchValue: (searchValue: string): AppAction => ({ type: 'DatasetFilter.setSearchValue', searchValue }),
  setSelectedTags: (tagNames: Set<number>): AppAction => ({ type: 'DatasetFilter.setSelectedTags', tagNames }),
  setTagSearchValue: (searchValue: string): AppAction => ({ type: 'DatasetFilter.setTagSearchValue', searchValue }),
  reset: (): AppAction => ({ type: 'DatasetFilter.reset' }),
};

interface DatasetFilterOwnProps {
  // placed alongside tag filters in sidebar
  customFilters?: React.ReactNode
  // needs to take the current page of datasets fetched from server and return selected dataset names
  // reminder: selected means previously committed, not necessarily "checked"
  getSelectedDatasets: (datasets: List<Document<Dataset>>) => Set<string>
  /**
   * Whether more than one dataset can be selected
   */
  isSingleSelect?: boolean
  // override to load all datasets in the system, not just ones associated with the active project
  // default: false
  loadAllDatasets?: boolean
  // this function will be passed a QueryBuilder instance, for uses of the dataset filter to impose their own query filtering
  // eg. a common one is to modify query so only input datasets for current project are returned
  modifyQuery: undefined | ((query: QueryBuilder) => void) // QueryBuilder is mutable
}

type DatasetFilterProps
  = DatasetFilterOwnProps
  & ReturnType<typeof mapStateToProps>
  & typeof mapDispatchToProps

const DatasetFilter = connect(
  mapStateToProps,
  mapDispatchToProps,
)(class DatasetFilter extends React.Component<DatasetFilterProps> {
  UNSAFE_componentWillMount() {
    this.props.reset();
  }

  render() {
    const { loading, setTagSearchValue, toggleAll, toggleRow, toggleRowExclusive, disabledDatasetIds, datasetsToAdd, datasetsToRemove,
      modifyQuery, getSelectedDatasets, selectedTags, setSelectedTags, tagSearchValue, customFilters, datasets,
      page, pageSize, searchValue, numDatasets, selectedDatasets, setSearchValue, setPage, setPageSize, tags,
      loadAllDatasets, isSingleSelect } = this.props;
    const pager = new Pager(datasets, numDatasets, page, pageSize);
    const loadingPanel = loading ? <LoadingPanel aboveCenter /> : undefined;
    const datasetNamesToAdd = datasetsToAdd.map(d => d.data.name);
    const datasetNamesToRemove = datasetsToRemove.map(d => d.data.name);
    const checkedDatasetNames = datasets.map(d => d.data.name).filter(datasetName => {
      return (selectedDatasets.has(datasetName) || datasetNamesToAdd.has(datasetName)) && !datasetNamesToRemove.has(datasetName);
    });
    const tagObjects = tags.map(doc => ({ id: doc.id.id, name: doc.data.name })).toList();
    return (
      <div className={style.dialogFilterBody}>
        <DatasetFilterLoader {...{ modifyQuery, getSelectedDatasets, loadAllDatasets: !!loadAllDatasets }} />
        <div className={style.dialogSidebar}>
          {customFilters}
          <div className={style.dialogSideBarFilters}>
            <div className={style.datasetFiltersHeader}>
              <div className={style.datasetFiltersTitle}>Tags</div>
              <Button
                className={style.clearFilterButton}
                buttonType="Link"
                onClick={() => setSelectedTags(Set())}
              >
                CLEAR TAG FILTER
              </Button>
            </div>
            <Labeler
              key="tags"
              options={tagObjects
                .filter(t => t.name.includes(tagSearchValue.toLowerCase()))
                .map(t => ({
                  key: t.name,
                  selectionState: selectedTags.contains(t.id) ? 'selected' : 'unselected' as LabelerSelectionStateE,
                  display: (
                    <TooltipTrigger
                      className={style.dialogOverflowTagTooltip}
                      placement="right"
                      content={t.name}
                      trigger={['hover']}
                    >
                      <div
                        className={style.dialogTagPill}
                        style={{ background: setTagColor(t.name, t.id) }}
                        role="button"
                        tabIndex={0}
                      >
                        {t.name}
                      </div>
                    </TooltipTrigger>
                  ),
                }))
                .sortBy(t => t.key)
                .unshift({
                  key: 'unassociated',
                  selectionState: selectedTags.has(UNTAGGED_ID) ? 'selected' : 'unselected',
                  display: <span role="button" tabIndex={0} className={style.dialogTagUnassociated}>No tags</span>,
                })}
              onOptionSelect={({ key: tagName }) => {
                const tagId = tagName === 'unassociated' ? UNTAGGED_ID : tagObjects.find(d => d.name === tagName)?.id;
                const newSelectedTags = isDefined(tagId)
                  ? (selectedTags.has(tagId) ? selectedTags.remove(tagId) : selectedTags.add(tagId))
                  : selectedTags;
                setSelectedTags(newSelectedTags);
              }}
              onSearchValueChange={setTagSearchValue}
              searchValue={tagSearchValue}
              placeholder="Search"
            />
          </div>
        </div>
        <div className={style.dialogTable}>
          <div className={style.dialogTableTop}>
            <div className={style.dialogTableTitle}>Datasets</div>
            <div className={style.dialogTableSearch}>
              <SearchBox autoFocusOnMount value={searchValue} onSearch={setSearchValue} searchOnKeyup />
            </div>
          </div>
          <div className={style.dialogTableMain}>
            <AutoSizer>
              {({ width, height }) => (
                <div>
                  {loadingPanel}
                  <ColumnWidthProvider>
                    <Table
                      width={width}
                      height={height}
                      tableType="stripes"
                      getLength={() => datasets.size}
                      onRowClick={isSingleSelect ? (e, rowNumber) => toggleRowExclusive(rowNumber) : (e, rowNumber) => toggleRow(rowNumber)}
                      onPageChange={setPage}
                      onPageSizeChange={setPageSize}
                      pagerState={pager}
                      pageSizes={PAGE_SIZES}
                      pageSizeLabel="Datasets per page:"
                      rowClassNameGetter={(rowIndex) => {
                        const datasetId = datasets.get(rowIndex)?.id.id;
                        return isDefined(datasetId) && disabledDatasetIds.contains(datasetId)
                          ? style.disabledRow
                          : '';
                      }}
                    >
                      {!isSingleSelect && <Column
                        width={60}
                        columnKey="Checkboxes"
                        isResizable
                        header={
                          (<Cell>
                            <TamrIcon
                              iconName={
                                checkedDatasetNames.isEmpty() ? 'tamr-icon-checkbox-unchecked'
                                  : checkedDatasetNames.size === datasets.size ? 'tamr-icon-checkbox-checked'
                                    : 'tamr-icon-checkbox-partial'
                              }
                              onClick={toggleAll}
                              size={16}
                            />
                          </Cell>)
                        }
                        cell={
                          ({ rowIndex }) => {
                            const dataset = datasets.get(rowIndex);
                            if (!dataset) return <Cell />;
                            return disabledDatasetIds.contains(dataset.id.id) ?
                              (
                                <Cell>
                                  <TooltipTrigger
                                    placement="right"
                                    content="This project does not have privileges to this dataset."
                                  >
                                    <div>
                                      <Checkbox
                                        onChange={() => toggleRow(rowIndex)}
                                        value={checkedDatasetNames.contains(dataset.data.name)}
                                        disabled
                                      />
                                    </div>
                                  </TooltipTrigger>
                                </Cell>
                              ) : (
                                <Cell>
                                  <Checkbox
                                    onChange={() => toggleRow(rowIndex)}
                                    value={checkedDatasetNames.contains(dataset.data.name)}
                                  />
                                </Cell>
                              );
                          }
                        }
                      />}
                      {DatasetNameColumn({ rows: datasets })}
                      {DatasetTagsColumn({ rows: datasets, allTags: tags })}
                      {DatasetDescriptionColumn({ rows: datasets, width: 200 })}
                    </Table>
                  </ColumnWidthProvider>
                </div>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
    );
  }
});

export default DatasetFilter;
