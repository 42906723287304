import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import { ProgressBar } from 'react-bootstrap';

import { baseClass, variants } from './Constants';


function getValue(value: number | undefined, logarithmic: boolean | undefined) {
  return (value && value > 0 && logarithmic) ? Math.log(value) : value;
}

const propTypes = {
  className: PropTypes.string,
  fillClass: PropTypes.string,
  logarithmic: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  now: PropTypes.number,
  variant: PropTypes.oneOf(variants),
};

// need to use functional component so that we can map
// directly over children of parent component and have react
// bootstrap not complain about child type
const baseProgressBar = (props: InferProps<typeof propTypes>) => {
  const variant = props.variant || 'light';
  const className = classNames(baseClass, props.className, variant, props.fillClass);
  return (
    <ProgressBar
      {...props}
      className={className}
      now={getValue(props.now || undefined, props.logarithmic || undefined)}
      min={getValue(props.min || undefined, props.logarithmic || undefined)}
      max={getValue(props.max || undefined, props.logarithmic || undefined)}
    />
  );
};

baseProgressBar.propTypes = propTypes;

export default baseProgressBar;
