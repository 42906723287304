import { Map } from 'immutable';

import * as DatasetClient from '../api/DatasetClient';
import { changeRecipeInputs } from '../api/RecipeClient';
import { doFetchInputDataset, doUpdateEnrichmentModule } from '../enrichment/EnrichmentAsync';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import { QueryBuilder } from '../models/doc/Query';
import { RECIPE_INPUTS_KEY } from '../projects/RecipeConstants';
import { AppThunkAction } from '../stores/AppAction';
import {
  doFetchDatasetsProfilingAndExportsWithQuery,
} from './DatasetsApi';
import {
  FETCH,
  FETCH_COMPLETED,
  REMOVE_DATASETS_FROM_PROJECT_COMPLETED,
  SUBMIT_MEMBERSHIP_CHANGES_COMPLETED,
} from './ProjectDatasetCatalogActionTypes';
import { getFilterInfo } from './ProjectDatasetCatalogStore';

export const fetch = (): AppThunkAction<void> => (dispatch, getState) => {
  dispatch({ type: FETCH });
  const { projectDatasetCatalog, location: { recipeId } } = getState();
  const filterInfo = getFilterInfo(projectDatasetCatalog, recipeId);
  const { pageSize, page, search, showSource, showDerived } = filterInfo;
  const offset = page * pageSize;
  const query = new QueryBuilder().offset(offset).limit(pageSize);
  if (search) {
    query.whereData('name').isLike(search);
  }
  if (!showSource) {
    // derived only - always has an entry in upstream datasets
    query.whereData('upstreamDatasets').isLike('dataset');
  }
  if (!showDerived) {
    // source only - empty upstream datasets
    query.whereData('upstreamDatasets').isEqualTo('[]');
  }
  query.whereData('metadata', RECIPE_INPUTS_KEY).containsInteger(recipeId);
  const builtQuery = query.build();
  const ajaxCalls = [
    doFetchDatasetsProfilingAndExportsWithQuery(builtQuery).promise(),
    DatasetClient.postStatusQuery(builtQuery),
  ] as const;
  Promise.all(ajaxCalls).then(([
    { datasets, numDatasets, profiling, exporting },
    status,
  ]) => {
    const numPages = Math.ceil(numDatasets / pageSize);
    dispatch({ type: FETCH_COMPLETED,
      datasets,
      numDatasets,
      numPages,
      profiling,
      exporting,
      status: Map(status.map(s => [s.datasetName, s])),
      loadedFilterInfo: filterInfo,
      noDatasetsInProject: numDatasets === 0 });
  });
};

export const removeDatasetsFromProject = (): AppThunkAction<void> => (dispatch, getState) => {
  const { projectDatasetCatalog: { confirmingRemoveForDatasets }, location: { recipeId } } = getState();
  const datasetsToMembershipValue = Map(confirmingRemoveForDatasets.map(dsName => [dsName, false]));
  dispatch({ type: 'ProjectDatasetCatalog.removeDatasetsFromProject', datasetNames: confirmingRemoveForDatasets });
  changeRecipeInputs(recipeId, datasetsToMembershipValue).then(() => {
    dispatch({ type: REMOVE_DATASETS_FROM_PROJECT_COMPLETED, datasetNames: confirmingRemoveForDatasets });
  });
};

export const submitMembershipChanges = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const { datasetFilter: { datasetsToAdd, datasetsToRemove }, enrichment: { module }, location: { recipeId } } = state;
  // module being present indicates that this is an enrichment module
  if (module) {
    const dataset: Document<Dataset> | undefined = datasetsToAdd.first();
    module.data.inputDataset = dataset?.data.name;
    doFetchInputDataset(dispatch, getState);
    doUpdateEnrichmentModule(dispatch, module)
      .then(() => {
        dispatch({ type: SUBMIT_MEMBERSHIP_CHANGES_COMPLETED });
      }).catch(response => {
        dispatch({
          type: 'ProjectDatasetCatalog.submitMembershipChangesFailed',
          detail: 'The specified input dataset changes could not be made to this project',
          response,
        });
      });
  } else {
    let datasetsToMembershipValue = Map<string, boolean>();
    datasetsToAdd.forEach((d: Document<Dataset>) => datasetsToMembershipValue = datasetsToMembershipValue.set(d.data.name, true));
    datasetsToRemove.forEach((d: Document<Dataset>) => datasetsToMembershipValue = datasetsToMembershipValue.set(d.data.name, false));
    changeRecipeInputs(recipeId, datasetsToMembershipValue).then(() => {
      dispatch({ type: SUBMIT_MEMBERSHIP_CHANGES_COMPLETED });
    }).catch(response => {
      dispatch({
        type: 'ProjectDatasetCatalog.submitMembershipChangesFailed',
        detail: 'The specified input dataset changes could not be made to this project',
        response,
      });
    });
  }
};
