import { ArgTypes } from '../utils/ArgValidation';
import { checkTypesAndCast } from '../utils/CheckTypesAndCast';
import { genericRuleArgTypes } from './Rule';
import * as SingleInputAttributeRule from './SingleInputAttributeRule';

// com.tamr.recipe.models.flows.goldenRecords.CountDistinctRule

export const TYPE = 'CountDistinct';

export interface CountDistinctRule extends SingleInputAttributeRule.AbstractSingleInputAttributeRule {
  type: typeof TYPE
}
export const argTypes = {
  type: ArgTypes.eq(TYPE as typeof TYPE),
  ...genericRuleArgTypes,
} as const;
export const fromJSON = checkTypesAndCast<CountDistinctRule, typeof argTypes>(argTypes);

export const argType = ArgTypes.object.withShape(argTypes);
