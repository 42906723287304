/* eslint react/no-danger:0 */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { DropTarget as dropTarget } from 'react-dnd';
import { pure } from 'recompose';
import _ from 'underscore';

import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import style from './UnpivotTarget.module.scss';

const UnpivotTarget = _.compose(
  dropTarget('unifiedAttribute', {
    drop(props, monitor) {
      const attributeItem = monitor.getItem();
      props.onChange(props.unpivotColumn, attributeItem.attributeName, props.index);
    },
  }, (connector, monitor) => {
    return {
      attributeItem: monitor.getItem(),
      connectDropTarget: connector.dropTarget(),
      isOver: monitor.isOver(),
    };
  }),
  pure,
)(class UnpivotTarget extends React.Component {
  static propTypes = {
    connectDragPreview: PropTypes.func,
    connectDragSource: PropTypes.func,
    connectDropTarget: PropTypes.func,
    dependentAttribute: PropTypes.string,
    index: PropTypes.number.isRequired,
    isOver: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    unpivotColumn: PropTypes.string.isRequired,
  };

  render() {
    const { connectDropTarget, dependentAttribute, index, isOver, onDelete, unpivotColumn } = this.props;
    const css = classNames(style.unpivotTargets, {
      [style.isOver]: isOver,
    });

    return connectDropTarget(
      <div className={css}>
        {dependentAttribute ?
          <div className={style.unpivotTargetPillContainers}>
            <TooltipTrigger
              trigger={['hover']}
              placement="right"
              content={dependentAttribute}
            >
              <span className={style.unpivotTargetPills}>
                <span className={style.unpivotTargetText}>
                  {dependentAttribute}
                </span>
                <TamrIcon iconName="tamr-icon-remove" className={style.removeTargetPill} size={10} onClick={() => onDelete(unpivotColumn, index)} />
              </span>
            </TooltipTrigger>
          </div>
          : null}
      </div>,
    );
  }
});

export default UnpivotTarget;
