export default class Optional<T> {
  private value: T | undefined;

  private constructor(value: T | undefined) {
    this.value = value;
  }

  static of = <T>(value: T | undefined | null): Optional<T> => {
    return new Optional(value === null ? undefined : value);
  };

  static empty = <T>(): Optional<T> => {
    return new Optional(undefined as T | undefined);
  };

  get(): T | undefined {
    return this.value;
  }

  map = <U>(mapper: (value: T) => U): Optional<U> => {
    const { value } = this;
    return value === undefined ? Optional.of<U>(undefined) : Optional.of<U>(mapper(value));
  };

  orElse = (defaultValue: T): T => {
    const { value } = this;
    return value === undefined ? defaultValue : value;
  };

  orElseGet = (supplier: () => T): T => {
    const { value } = this;
    return value === undefined ? supplier() : value;
  };
}
