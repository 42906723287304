import { Set } from 'immutable';

import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';


/**
 * Records that have changed since the last time the state on the clusters page was loaded
 */
class ChangedRecords extends getModelHelpers({
  recipeId: { type: ArgTypes.number },
  recordIds: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: Set<string>() },
}, 'ChangedRecords')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ChangedRecordsRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  static fromJSON(obj: $TSFixMe) {
    checkArg({ obj }, ArgTypes.object);
    const { recipeId, recordIds } = obj;
    return new ChangedRecords({
      recipeId,
      recordIds: Set(recordIds),
    });
  }
}

export default ChangedRecords;
