import { List, Map } from 'immutable';

import { getModelHelpers, InferConstructorArgTypes, InferReadTypes, toJSON } from '../models/Model';
import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { RecordType } from './SchemaTypes';


export default class SampleRecords extends getModelHelpers({
  schema: { type: ArgTypes.instanceOf(RecordType) },
  rows: { type: ArgTypes.Immutable.list.of(ArgTypes.Immutable.map.of(ArgTypes.any, ArgTypes.string)) },
}, 'SampleRecords')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class SampleRecordsRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  toJSON() {
    return toJSON(super.toJSON());
  }
  static fromJSON(obj: $TSFixMe) {
    return new SampleRecords({
      schema: RecordType.fromJSON(obj.schema),
      rows: List(obj.rows).map(record => Map<string, $TSFixMe>(record as $TSFixMe)),
    });
  }
}
