import enumify from './enumify';

// com.tamr.dataset.models.JobSubmissionStatus

export const NONE = 'NONE';
export const UNSUBMITTED = 'UNSUBMITTED';
export const SUBMITTING = 'SUBMITTING';
export const SUBMIT_FAILED = 'SUBMIT_FAILED';
export const SUBMITTED = 'SUBMITTED';
export const CANCELING = 'CANCELING';
export const CANCELED = 'CANCELED';
export const RUNNING = 'RUNNING';
export const FAILED = 'FAILED';
export const SUCCEEDED = 'SUCCEEDED';

export default enumify({
  NONE, UNSUBMITTED, SUBMITTING, SUBMIT_FAILED, SUBMITTED, CANCELING, CANCELED, RUNNING, FAILED, SUCCEEDED,
});
