import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import PageHeader from '../chrome/PageHeader';
import { isStepUpToDate } from '../chrome/UpdatesUtils';
import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import ConditionalButton from '../components/ConditionalButton';
import Term from '../components/Term';
import WarningDialog from '../components/WarningDialog';
import { ProjectStepsKey as ProjectStepKey } from '../constants/ProjectStepsKey';
import { isAbsoluteSimilarityFunction } from '../schema-mapping/constants/SimilarityFunction';
import { history } from '../utils/History';
import { isJobRunning } from '../utils/Selectors';
import { pluralize } from '../utils/Strings';
import { joinWithUltimate } from '../utils/Values';
import { generateEstimates, generatePairs } from './DnfBuilderApi';
import style from './DnfBuilderHeader.module.scss';
import { getRemovedFieldNames } from './DnfBuilderStore';

const DnfBuilderHeader = _.compose(
  connect((state) => {
    const { dnfBuilder: { dnf: { clauses }, showUpdateWarningDialog, generateLaunching, estimateLaunching, sidebarExpanded }, location: { recipeId } } = state;
    const removedFieldNames = getRemovedFieldNames(state);
    const schemaMappingOutOfDate = !isStepUpToDate(state, ProjectStepKey.RECORDS);
    return {
      clauses,
      showUpdateWarningDialog,
      recipeId,
      removedFieldNames,
      schemaMappingOutOfDate,
      sidebarExpanded,
      busy: generateLaunching || estimateLaunching || isJobRunning(state, { recipeOperation: 'pairs', forActiveProject: true })
        || isJobRunning(state, { description: 'Generate Pair Estimates', forActiveProject: true }), // NOTE this is currently broken becuase recipeId is not attached to estimates job metadata
    };
  }, {
    onReset: () => ({ type: 'DnfBuilder.reset' }),
    onGenerateEstimates: generateEstimates,
    onGeneratePairs: generatePairs,
    onToggleSidebar: () => ({ type: 'DnfBuilder.toggleExpandSidebar' }),
    onShowUpdateWarningDialog: () => ({ type: 'DnfBuilder.showUpdateWarningDialog' }),
    onHideUpdateWarningDialog: () => ({ type: 'DnfBuilder.hideUpdateWarningDialog' }),
  }),
)(({ sidebarExpanded, schemaMappingOutOfDate, removedFieldNames, onReset, recipeId, busy, clauses, onToggleSidebar, onGenerateEstimates, onGeneratePairs, onShowUpdateWarningDialog, onHideUpdateWarningDialog, showUpdateWarningDialog }) => {
  const activeClauses = clauses.filter(clause => clause.active);

  const allActiveEntriesHaveFields = !activeClauses.find(({ clauseEntries }) => {
    return clauseEntries.find(clauseEntry => _.isEmpty(clauseEntry.fieldName));
  });

  const allActiveEntriesHaveValidThresholds = !activeClauses.find(({ clauseEntries }) => {
    return clauseEntries.find(clauseEntry => !clauseEntry.isNull && (!isAbsoluteSimilarityFunction(clauseEntry.similarityFunction) && (clauseEntry.threshold <= 0 || clauseEntry.threshold > 100)));
  });

  const buttonPreconditions = Map()
    .set(<span>A <Term>pair</Term> / estimate generation job can not be currently running</span>, !busy)
    .set('Must have at least one active clause', !activeClauses.isEmpty())
    .set('All active clause entries must have selected fields', allActiveEntriesHaveFields)
    .set('All active relative similarity clause entries must have thresholds greater than 0 and less than or equal to 100', allActiveEntriesHaveValidThresholds)
    .set(
      <span>
        <b>{joinWithUltimate(removedFieldNames, ', ', ', and ', ' and ')}</b>&nbsp;
        {pluralize(removedFieldNames.size, 'is', 'are')} no longer&nbsp;
        {pluralize(removedFieldNames.size, 'a machine learning attribute', 'machine learning attributes')}&nbsp;
        and cannot be used in how <Term>pairs</Term> are found.
      </span>,
      removedFieldNames.isEmpty(),
    );

  const upToDatenessPrecondition = Map()
    .set(<span>Please Update Unified Dataset first</span>, !schemaMappingOutOfDate);

  return (
    <PageHeader
      title={<span>A <Term>pair</Term> of <Term>records</Term> may be a match if:</span>}
      className={style.component}
    >
      <ButtonToolbar>
        <Button
          buttonType="Link"
          onClick={() => {
            onReset();
            history.push(`/pairs/recipe/${recipeId}`);
          }}
        >
          Back to <Term>pairs</Term>
        </Button>
        <ConditionalButton
          buttonType="Secondary"
          onClick={onGenerateEstimates}
          preconditions={buttonPreconditions}
        >
          Estimate counts
        </ConditionalButton>
        <ConditionalButton
          buttonType="Primary"
          onClick={onShowUpdateWarningDialog}
          preconditions={buttonPreconditions.merge(upToDatenessPrecondition)}
        >
          Generate <Term>pairs</Term>
        </ConditionalButton>
        <WarningDialog
          actionName={<span>Generate <Term>pairs</Term></span>}
          message={<span>Generating <Term>pairs</Term> can take a while. Are you sure you want to continue?</span>}
          onAccept={onGeneratePairs}
          onHide={onHideUpdateWarningDialog}
          show={showUpdateWarningDialog}
        />
        <Button
          buttonType="Secondary"
          onClick={onToggleSidebar}
        >
          {sidebarExpanded
            ? 'Hide exclusions'
            : 'Show exclusions'
          }
        </Button>
      </ButtonToolbar>
    </PageHeader>
  );
});

export default DnfBuilderHeader;
