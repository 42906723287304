import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import DocLink from '../components/DocLink';
import Input from '../components/Input/Input';
import TamrIcon from '../components/TamrIcon';
import UnifiedAttribute from '../models/UnifiedAttribute';
import { updateUnifiedNameAndDescription } from '../schema-mapping/SchemaMappingAsync';
import { validateName } from '../schema-mapping/UnifiedAttributeSubsection';
import { selectUnifiedAttributeNames } from '../utils/Selectors';
import style from './UADialog.module.scss';

const ENTER_KEY = 13;
const ESCAPE_KEY = 27;

const EditUADialog = _.compose(
  connect((state) => {
    return {
      unifiedAttributeNames: selectUnifiedAttributeNames(state),
    };
  }, {
    onUpdateUnifiedNameAndDescription: updateUnifiedNameAndDescription,
  }),
)(class EditUADialog extends React.Component {
  static propTypes = {
    additionalOnSave: PropTypes.func.isRequired,
    hoverActive: PropTypes.bool.isRequired,
    originalAttribute: PropTypes.instanceOf(UnifiedAttribute).isRequired,
    unifiedAttributeNames: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
  };

  state = {
    showDialog: false,
    name: this.props.originalAttribute.name,
    description: this.props.originalAttribute.description,
  };

  onFormKeyUp = (e) => {
    if (e.keyCode === ENTER_KEY) {
      if (this.isSaveValid()) {
        this.save();
      }
    } else if (e.keyCode === ESCAPE_KEY) {
      this.onClose();
    }
  };

  onOpen = () => {
    this.setState({
      showDialog: true,
      name: this.props.originalAttribute.name,
      description: this.props.originalAttribute.description,
    });
  };

  onClose = () => {
    this.setState({
      showDialog: false,
    });
  };

  onNameChange = (name) => {
    this.setState({ name });
  };

  onDescriptionChange = (description) => {
    this.setState({ description });
  };

  isSaveValid = () => {
    const { name, description } = this.state;
    const { unifiedAttributeNames, originalAttribute } = this.props;
    return (
      ((name !== originalAttribute.name) || (description !== originalAttribute.description))
      && (validateName(name, unifiedAttributeNames.filter(n => n !== originalAttribute.name)) === undefined)
    );
  };

  save = () => {
    const { name, description } = this.state;
    const { onUpdateUnifiedNameAndDescription, originalAttribute, additionalOnSave } = this.props;
    onUpdateUnifiedNameAndDescription(originalAttribute, name, description);
    additionalOnSave(name);
    this.onClose();
  };

  renderNameInput = (hasError, hasWarning) => {
    return (
      <div className={classNames(style.nameInputContainer)}>
        <Input
          autoFocus
          onChange={this.onNameChange}
          type="text"
          title="Attribute Name"
          value={this.state.name}
          onKeyUp={this.onFormKeyUp}
          invalid={hasError}
          hasWarning={hasWarning}
        />
      </div>
    );
  };

  renderDescriptionInput = () => {
    return (
      <div className={style.descriptionInputContainer}>
        <Input
          onChange={this.onDescriptionChange}
          type="text"
          title="Attribute Description"
          value={this.state.description}
          onKeyUp={this.onFormKeyUp}
        />
      </div>
    );
  };

  renderHeader = () => {
    return (
      <div className={style.dialogHeader}>
        Updating unified attribute "{this.props.originalAttribute.name}"
      </div>
    );
  };

  renderBody = () => {
    const { name } = this.state;
    const { unifiedAttributeNames, originalAttribute } = this.props;
    const nameError = validateName(name, unifiedAttributeNames.filter(n => n !== originalAttribute.name));

    let headerMsg;
    let nameHasWarning = (name !== originalAttribute.name);

    if (nameError) {
      headerMsg = (
        <div className={classNames(style.nameMsgError, style.editMsg)}>
          <span> <br />
            <TamrIcon iconName="tamr-icon-warning" size={14} />
            {' ' + nameError}
          </span>
        </div>);
    } else if (nameHasWarning) {
      headerMsg = (
        <div className={classNames(style.nameMsgWarning, style.editMsg)}>
          <span>
            <TamrIcon iconName="tamr-icon-warning" size={14} />
            Renaming will not update project configurations. <br /> {'Manual changes may be required!  '}
            <DocLink path="creating-unified-attributes-admin#renaming-a-unified-attribute">
              Learn more
            </DocLink>
          </span>
        </div>);
      nameHasWarning = true;
    } else {
      headerMsg = (
        <div className={classNames(style.editMsg)} />);
    }

    return (
      <div>
        {headerMsg}
        {this.renderNameInput(nameError, nameHasWarning)}
        {this.renderDescriptionInput()}
      </div>
    );
  };

  renderFooter = () => {
    return (
      <ButtonToolbar>
        <Button buttonType="Secondary" onClick={this.onClose}>Cancel</Button>
        <Button buttonType="Primary" onClick={this.save} disabled={!this.isSaveValid()}>Save</Button>
      </ButtonToolbar>
    );
  };

  render() {
    return (
      <div className={style.uaComponent}>
        <Dialog
          dialogStyle={DialogStyle.PRIMARY}
          header={this.renderHeader()}
          body={this.renderBody()}
          footer={this.renderFooter()}
          onHide={this.onClose}
          show={this.state.showDialog}
        />
        <TamrIcon
          key="editIcon"
          className={style.pencil}
          iconName="edit"
          size={20}
          onClick={this.onOpen}
          style={{ visibility: this.props.hoverActive ? 'visible' : 'hidden' }}
        />
      </div>
    );
  }
});

export default EditUADialog;
