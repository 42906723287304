import { EnrichmentModule } from '../enrichment/EnrichmentModule';
import { GoldenRecordsModule } from '../goldenRecords/GoldenRecordsModule';
import { ArgTypes } from '../utils/ArgValidation';

export interface AbstractModule {
  displayName: string
  externalId: string
  description: string
}
export const abstractModuleArgTypes = {
  displayName: ArgTypes.string,
  externalId: ArgTypes.string,
  description: ArgTypes.string,
} as const;

export type Module =
  | GoldenRecordsModule
  | EnrichmentModule
  // more types to come...

export enum PublishVersion {
  STAGED = 'STAGED',
  LATEST = 'LATEST'
}
