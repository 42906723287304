import { bindActionCreators } from 'redux';

import createLoader from '../utils/createLoader';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { PaneE } from './Pane';
import { fetchSimilarSuppliers } from './SuppliersAsync';

const SimilarSuppliersLoader = createLoader((state, { pane }: { pane: PaneE }) => {
  const { suppliers: { [pane]: { loadedSimilarSuppliersId, loadingSimilarSuppliers, selectedSuppliers } } } = state;
  const projectInfo = selectActiveProjectInfo(state);
  return {
    canFetch: !!(projectInfo && projectInfo.isUnifiedDatasetIndexed) && selectedSuppliers.size === 1,
    shouldFetch: selectedSuppliers.size === 1 && selectedSuppliers.first() !== loadedSimilarSuppliersId,
    loading: loadingSimilarSuppliers,
  };
}, (dispatch, { pane }) => bindActionCreators({
  onFetch: () => fetchSimilarSuppliers(pane),
}, dispatch), 'SimilarSuppliersLoader');

export default SimilarSuppliersLoader;
