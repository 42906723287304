import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Checkbox from '../components/Input/Checkbox';
import { AppDispatch, AppState } from '../stores/MainStore';
import filterStyle from './FilterSection.module.scss';
import { PaneE } from './Pane';
import { ClusterVerificationFilters } from './VerificationFilters';
import { FilterVerificationIcon } from './VerificationFilterSection';
import { VERIFICATION_ICON_TYPE_SUGGESTABLE_HAS_SUGGESTION, VERIFICATION_ICON_TYPE_VERIFIED_ELSEWHERE, VERIFICATION_ICON_TYPE_VERIFIED_HERE } from './VerificationIcon';


type ClusterVerificationFilterSectionOwnProps = {
  pane: PaneE
}

const ClusterVerificationFilterSection = connect((state: AppState, { pane }: ClusterVerificationFilterSectionOwnProps) => {
  const paneState = state.suppliers[pane];
  const clusterVerificationFilters: ClusterVerificationFilters = paneState.clusterVerificationFilters;
  return {
    clusterVerificationFilters,
  };
}, (dispatch: AppDispatch, { pane }: ClusterVerificationFilterSectionOwnProps) => bindActionCreators({
  onToggleHasVerifiedHereFilter: () => ({ type: 'Suppliers.toggleClusterHasVerifiedHereFilter', pane }),
  onToggleHasVerifiedElsewhereFilter: () => ({ type: 'Suppliers.toggleClusterHasVerifiedElsewhereFilter', pane }),
  onToggleHasMoveSuggestedFilter: () => ({ type: 'Suppliers.toggleClusterHasMoveSuggestedFilter', pane }),
  onToggleHasNoneVerifiedFilter: () => ({ type: 'Suppliers.toggleClusterHasNoneVerifiedFilter', pane }),
}, dispatch))(({ clusterVerificationFilters, onToggleHasVerifiedHereFilter, onToggleHasVerifiedElsewhereFilter, onToggleHasMoveSuggestedFilter, onToggleHasNoneVerifiedFilter }) => {
  const { hasVerifiedHere: hasVerifiedHereFilterActive, hasVerifiedElsewhere: hasVerifiedElsewhereFilterActive,
    hasMoveSuggested: hasSuggestionFilterActive, hasNoneVerified: hasNoneVerifiedFilterActive } = clusterVerificationFilters;
  return (
    <div className={filterStyle.filterSection}>
      <div className={filterStyle.filterPanelHeader}>Verification</div>
      <div className={filterStyle.option}>
        <Checkbox
          className={filterStyle.checkboxWrapper}
          onChange={onToggleHasVerifiedHereFilter}
          title={<span><FilterVerificationIcon verificationIconType={VERIFICATION_ICON_TYPE_VERIFIED_HERE} />&nbsp;Has records verified in current cluster</span>}
          value={hasVerifiedHereFilterActive}
          size={15}
          titlePosition="right"
        />
      </div>
      <div className={filterStyle.subfiltersContainer}>
        <div className={filterStyle.option}>
          <Checkbox
            className={filterStyle.checkboxWrapper}
            onChange={onToggleHasMoveSuggestedFilter}
            title={<span><FilterVerificationIcon verificationIconType={VERIFICATION_ICON_TYPE_SUGGESTABLE_HAS_SUGGESTION} />&nbsp;With move suggested</span>}
            value={hasSuggestionFilterActive}
            size={15}
            titlePosition="right"
          />
        </div>
      </div>
      <div className={filterStyle.option}>
        <Checkbox
          className={filterStyle.checkboxWrapper}
          onChange={onToggleHasVerifiedElsewhereFilter}
          title={<span><FilterVerificationIcon verificationIconType={VERIFICATION_ICON_TYPE_VERIFIED_ELSEWHERE} />&nbsp;Has records verified in another cluster</span>}
          value={hasVerifiedElsewhereFilterActive}
          size={15}
          titlePosition="right"
        />
      </div>
      <div className={filterStyle.option}>
        <Checkbox
          className={filterStyle.checkboxWrapper}
          onChange={onToggleHasNoneVerifiedFilter}
          title={<span>Has no verified records</span>}
          value={hasNoneVerifiedFilterActive}
          size={15}
          titlePosition="right"
        />
      </div>
    </div>
  );
},
);

export default ClusterVerificationFilterSection;
