import createLoader from '../utils/createLoader';
import { fetchClusterFeedback } from './ClusterFeedbackAsync';


const ClusterFeedbackLoader = createLoader((state) => {
  const { clusterFeedbackStore: { loaded, loading } } = state;
  return {
    canFetch: true,
    shouldFetch: !loaded,
    loading,
  };
}, {
  onFetch: fetchClusterFeedback,
}, 'ClusterFeedbackLoader');

export default ClusterFeedbackLoader;
