import createLoader from '../utils/createLoader';
import { fetchFunctionNames } from './TransformsApi';

const FunctionNamesLoader = createLoader((state) => {
  const { transforms: { loadingFunctionNames, loadedFunctionNames } } = state;
  return {
    canFetch: true,
    shouldFetch: !loadedFunctionNames,
    loading: loadingFunctionNames,
  };
}, {
  onFetch: fetchFunctionNames,
}, 'FunctionNamesLoader');

export default FunctionNamesLoader;
