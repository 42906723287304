import classNames from 'classnames';
import { Set } from 'immutable';
import PropTypes from 'prop-types';
import { Range } from 'rc-slider';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../../components/Button';
import PlainInput from '../../components/Input/PlainInput';
import TamrIcon from '../../components/TamrIcon';
import Toggle from '../../components/Toggle';
import TooltipTrigger from '../../components/TooltipTrigger';
import PRODUCT_NAME from '../../utils/ProductName';
import AttributeSimilarityFilterModel from '../AttributeSimilarityFilterModel';
import style from './AttributeSimilarityFilter.module.scss';

const headerRowHeight = parseInt(style.headerRowHeight, 10);

const InclusivityButton = (props) => {
  const { onUpdateAttributeSimilarityFilter, bound, filterModel, representsInclusive, disabled } = props;
  const boundIsInclusive = bound === 'lower' ? filterModel.lowerBoundIsInclusive : filterModel.upperBoundIsInclusive;
  const selected = ((boundIsInclusive && representsInclusive) || (!boundIsInclusive && !representsInclusive));
  const onClick = selected ? undefined : () => onUpdateAttributeSimilarityFilter(filterModel.update(bound === 'lower' ? 'lowerBoundIsInclusive' : 'upperBoundIsInclusive', i => !i));
  const symbol = bound === 'lower'
    ? representsInclusive ? String.fromCharCode(8805) : '>'
    : representsInclusive ? String.fromCharCode(8804) : '<';
  return (
    <TooltipTrigger
      placement="bottom"
      content={`${bound === 'lower' ? 'Greater' : 'Less'} than${representsInclusive ? ' or equal to' : ''}`}
    >
      <Button
        className={classNames(style.inclusivityButton, { [style.inclusive]: representsInclusive, [style.exclusive]: !representsInclusive })}
        buttonType={selected ? 'Primary' : 'Secondary'}
        onClick={onClick}
        disabled={disabled}
      >
        {symbol}
      </Button>
    </TooltipTrigger>
  );
};
InclusivityButton.propTypes = {
  bound: PropTypes.oneOf(['lower', 'upper']),
  disabled: PropTypes.bool,
  filterModel: PropTypes.instanceOf(AttributeSimilarityFilterModel),
  onUpdateAttributeSimilarityFilter: PropTypes.func.isRequired,
  representsInclusive: PropTypes.bool.isRequired,
};

const BoundInput = (props) => {
  const { comparingAbsoluteDifference, comparingAbsoluteCosine, onChange, value, title, className, disabled } = props;
  return (
    <PlainInput
      className={classNames(style.input, className, { [style.wide]: comparingAbsoluteDifference })}
      type="number"
      defaultValue={comparingAbsoluteDifference || comparingAbsoluteCosine ? value : parseFloat((value * 100).toFixed(1), 10)}
      title={title}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          onChange(e);
        }
      }}
      onBlur={onChange}
      max={comparingAbsoluteDifference || comparingAbsoluteCosine ? undefined : 100}
      min={0}
      step={1}
      size={comparingAbsoluteDifference || comparingAbsoluteCosine ? 5 : 3}
      disabled={disabled}
    />
  );
};

const AttributeSimilarityFilter = connect((state, { attributeName }) => {
  const { recordPairs: { attributeSimilarityFilterStates } } = state;
  const filterModel = attributeSimilarityFilterStates.find(f => f.attributeName === attributeName);
  return { filterModel };
}, {
  onUpdateAttributeSimilarityFilter: (filter) => ({ type: 'RecordPairs.updateAttributeSimilarityFilter', filter }),
  onDeleteAttributeSimilarityFilters: (filterKeys) => ({ type: 'RecordPairs.deleteAttributeSimilarityFilters', filterKeys }),
})(class AttributeSimilarityFilter extends React.Component {
  static propTypes = {
    filterModel: PropTypes.instanceOf(AttributeSimilarityFilterModel).isRequired,
    onDeleteAttributeSimilarityFilters: PropTypes.func.isRequired,
    onUpdateAttributeSimilarityFilter: PropTypes.func.isRequired,
  };

  state = {
    sliderKey: 1, // to force reconsideration of default handle positions after input change
    inputKey: 1, // to force reconsideration of default input values after slider change
    lowerBound: undefined,
    upperBound: undefined,
  };

  onSliderChange = ([lowerBound, upperBound]) => {
    this.setState({ lowerBound, upperBound, inputKey: this.state.inputKey + 1 });
  };

  onAfterSliderChange = ([lowerBound, upperBound]) => {
    const { onUpdateAttributeSimilarityFilter, filterModel } = this.props;
    onUpdateAttributeSimilarityFilter(filterModel.merge({ lowerBound, upperBound }));
  };

  onInputChange = (boundKey, e) => {
    const { onUpdateAttributeSimilarityFilter, filterModel } = this.props;
    const { comparingAbsoluteDifference, comparingAbsoluteCosine, lowerBound, upperBound } = filterModel;
    let boundValue = parseFloat(e.target.value, 10);
    // correct by /100 if not comparing absolute difference
    if (!comparingAbsoluteDifference && !comparingAbsoluteCosine) {
      boundValue /= 100.0;
    }
    // enforce bound invariants
    let affected = false;
    if (isNaN(boundValue)) {
      boundValue = 0;
      affected = true;
    }
    if (boundKey === 'lowerBound') {
      if (boundValue > upperBound) {
        boundValue = upperBound;
        affected = true;
      }
      if (boundValue < 0) {
        boundValue = 0;
        affected = true;
      }
    }
    if (boundKey === 'upperBound') {
      if (boundValue < lowerBound) {
        boundValue = lowerBound;
        affected = true;
      }
      if (!comparingAbsoluteDifference && !comparingAbsoluteCosine && boundValue > 1) {
        boundValue = 1;
        affected = true;
      }
    }
    this.setState({
      [boundKey]: boundValue,
      sliderKey: this.state.sliderKey + 1,
      inputKey: affected ? this.state.inputKey + 1 : this.state.inputKey,
    });
    onUpdateAttributeSimilarityFilter(filterModel.set(boundKey, boundValue));
  };

  UNSAFE_componentWillMount() {
    const { filterModel: { lowerBound, upperBound } } = this.props;
    this.setState({ lowerBound, upperBound });
  }

  render() {
    const { filterModel, onUpdateAttributeSimilarityFilter, onDeleteAttributeSimilarityFilters } = this.props;
    const { sliderKey, inputKey, lowerBound, upperBound } = this.state;
    const { sanitizedAttributeName, attributeName, comparingAbsoluteDifference, comparingAbsoluteCosine, includeNulls, includeRange } = filterModel;
    return (
      <div className={classNames(style.component, { [style.inactive]: !includeRange })}>
        <div className={style.headerRow}>
          <div className={classNames(style.attributeName, { [style.attributeNameInactive]: !includeRange && !includeNulls })} title={attributeName}>{attributeName}</div>
          <div className={style.deleteSection}>
            <TamrIcon
              className={style.deleteIcon}
              iconName="delete"
              size={headerRowHeight}
              onClick={() => onDeleteAttributeSimilarityFilters(Set.of(sanitizedAttributeName))}
            />
          </div>
        </div>
        <div className={style.similarityRow}>
          <div className={style.label}>Pairs with similarity scores
            <span className={style.infoIconWrapper}>
              <TooltipTrigger
                placement="top"
                content={<span>{PRODUCT_NAME} will show pairs where the similarity of these values falls in the range specified below. You can change the similarity metric used for each unified attribute in Schema Mapping.</span>}
              >
                <TamrIcon
                  className={style.infoIcon}
                  iconName="info-outline"
                  size={12}
                />
              </TooltipTrigger>
            </span>
          </div>
          <div className={style.toggleSection}>
            <Toggle
              className={style.isActiveToggle}
              value={includeRange}
              width={headerRowHeight * 2}
              onChange={(value) => onUpdateAttributeSimilarityFilter(filterModel.set('includeRange', value))}
            />
          </div>
        </div>
        <div className={style.inputRow}>
          <InclusivityButton {...this.props} bound="lower" disabled={!includeRange} representsInclusive={false} />
          <InclusivityButton {...this.props} bound="lower" disabled={!includeRange} representsInclusive />
          <BoundInput
            key={`lowerBoundInput-${inputKey}`}
            comparingAbsoluteDifference={comparingAbsoluteDifference}
            comparingAbsoluteCosine={comparingAbsoluteCosine}
            className={style.lowerBoundInput}
            value={lowerBound}
            title="Lower Bound"
            onChange={(e) => this.onInputChange('lowerBound', e)}
            disabled={!includeRange}
          />
          {comparingAbsoluteDifference || comparingAbsoluteCosine ? (
            <div className={style.to}>to</div>
          ) : (
            <div className={style.sliderContainer}>
              <Range
                key={sliderKey}
                range
                allowCross={false}
                min={0}
                max={1}
                step={0.01}
                defaultValue={[lowerBound, upperBound]}
                onChange={this.onSliderChange}
                onAfterChange={this.onAfterSliderChange}
                disabled={!includeRange}
              />
            </div>
          )}
          <BoundInput
            key={`upperBoundInput-${inputKey}`}
            comparingAbsoluteDifference={comparingAbsoluteDifference}
            comparingAbsoluteCosine={comparingAbsoluteCosine}
            className={style.upperBoundInput}
            value={upperBound}
            title="Upper Bound"
            onChange={(e) => this.onInputChange('upperBound', e)}
            disabled={!includeRange}
          />
          <InclusivityButton {...this.props} bound="upper" disabled={!includeRange} representsInclusive={false} />
          <InclusivityButton {...this.props} bound="upper" disabled={!includeRange} representsInclusive />
        </div>
        <div className={style.containsNullToggleSection}>
          <span className={classNames(style.nullLabel, { [style.nullLabelInactive]: !includeNulls })}>
            Pairs without similarity scores
            <span className={style.infoIconWrapper}>
              <TooltipTrigger
                placement="top"
                content={<span>{PRODUCT_NAME} cannot calculate similarity when either or both values of a pair’s attribute are empty (blank string, empty list or null) or contain special characters.</span>}
              >
                <TamrIcon
                  className={style.infoIcon}
                  iconName="info-outline"
                  size={12}
                />
              </TooltipTrigger>
            </span>
          </span>
          <div className={style.containsNullsToggle}>
            <Toggle
              value={includeNulls}
              width={headerRowHeight * 2}
              onChange={(value) => onUpdateAttributeSimilarityFilter(filterModel.set('includeNulls', value))}
            />
          </div>
        </div>
      </div>
    );
  }
});

AttributeSimilarityFilter.propTypes = {
  attributeName: PropTypes.string.isRequired,
};

export default AttributeSimilarityFilter;
