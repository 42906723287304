import { Set } from 'immutable';
import pathToRegexp, { Key, PathFunction } from 'path-to-regexp';
import qs from 'query-string';
import _ from 'underscore';

import AttributeSimilarityFilterModel from '../pairs/AttributeSimilarityFilterModel';
import AppState from '../stores/AppState';
import { ArgTypes, checkArg } from './ArgValidation';
import {
  stringifyBoolean,
  stringifyList,
  stringifyNumber,
  stringifySet,
  stringifySort,
  stringifyString,
} from './Url';

/** README
 * TO add a route, add a route in the routeSpec. Note we use path-to-regexp to parse the path
 * Next map your component to a route to Router.jsx
 * Now routing in Tamr has two parts:
 *  1. read url and update the redux store, example below
export const reducers = {
  'Location.change': (state, { location }) => {
    const m = routes.goldenrecords.match(location.pathname);
    if (m) {
      return state.set('inputDatasetName', decodeURIComponent(m.inputDatasetName));
    }
    return state;
  },
...}
 *  2. read store and update the url, example below
goldenrecords: () => {
  const { goldenrecords: { inputDatasetName } } = state;
  return routes.goldenrecords.generate({ inputDatasetName });
},
 * Notice `match` makes an object and `generate` makes a url string which you may want to add querystrings things to.
 * Both parts are needed to create a new route.
 *
 * Don't forget to add your reducers and initial state to MainStore.js
 */

const pageNames = [
  'index',
  'datasetCatalog',
  'jobs',
  'users',
  'projectDatasetCatalog',
  'groupBy',
  'schemaMapping',
  'records',
  'dashboard',
  'taxonomy',
  'categoryDetail',
  'spend',
  'dnfBuilder',
  'pairs',
  'suppliers',
  'projectStatus',
  'goldenrecords',
  'grRules',
  'policies',
  'pregroup',
  'enrichment',
] as const;

type KnownPageName = typeof pageNames[number];
type PageName = KnownPageName | 'notfound';

const routeSpecs: { [key in KnownPageName]: string } = {
  index: '/',
  datasetCatalog: '/datasets',
  jobs: '/jobs',
  users: '/users',
  projectDatasetCatalog: '/datasets/recipe/:recipeId',
  groupBy: '/datasets/groupby/:datasetId',
  schemaMapping: '/schema-mapping/recipe/:recipeId',
  records: '/records/recipe/:recipeId',
  dashboard: '/dashboard/recipe/:recipeId',
  taxonomy: '/taxonomy/recipe/:recipeId',
  categoryDetail: '/category/recipe/:recipeId/:id',
  spend: '/spend/recipe/:recipeId',
  dnfBuilder: '/dnf-builder/recipe/:recipeId',
  pairs: '/pairs/recipe/:recipeId',
  suppliers: '/suppliers/recipe/:recipeId',
  projectStatus: '/project-status/:recipeId',
  goldenrecords: '/goldenrecords/:moduleId',
  grRules: '/grRules/:moduleId',
  policies: '/access/policies',
  pregroup: '/pregroup/recipe/:recipeId',
  enrichment: '/enrichment/:moduleId',
} as const;

export const PAGE_NAMES = Set(_.keys(routeSpecs)).add('notfound');
export const PAGE_NAMES_ARG_TYPE = ArgTypes.valueIn(PAGE_NAMES.toArray());

interface RouteInfo<T extends PageName> {
  name: T
  match: (path: string) => Object | null
  generate: PathFunction
}
export const routes: { [key in PageName]: RouteInfo<key> } = _.mapObject(routeSpecs, (spec, name: PageName) => {
  const keys: Key[] = [];
  const re = pathToRegexp(spec, keys, { sensitive: true, strict: true });
  const keyNames = keys.map(k => k.name);
  const match = (path: string): Object | null => {
    const m = path.match(re);
    return m && _.object(keyNames, _.rest(m));
  };
  const generate = pathToRegexp.compile(spec);
  return { name, match, generate };
}) as { [key in PageName]: RouteInfo<key> };

export const getUrl = (state: AppState) => {
  const { location } = state;
  const page: PageName = location.page;

  const routeHandlers: { [key in PageName]: () => string } = {
    index: () => {
      return routes.index.generate();
    },
    datasetCatalog: () => {
      return routes.datasetCatalog.generate();
    },
    jobs: () => {
      return routes.jobs.generate();
    },
    users: () => {
      return routes.users.generate();
    },
    projectDatasetCatalog: () => {
      const { location: { recipeId } } = state;
      return routes.projectDatasetCatalog.generate({ recipeId });
    },
    groupBy: () => {
      const { groupBy: { datasetId } } = state;
      return routes.groupBy.generate({ datasetId });
    },
    goldenrecords: () => {
      const { location: { moduleId }, goldenRecords: { showSidebar, pageNum, pageSize, searchString, columnSortStates, hasOverrides, linkedFromOnboardingButton } } = state;
      const search = qs.stringify({
        sort: stringifySort(columnSortStates),
        pageNum: stringifyNumber(pageNum),
        pageSize: stringifyNumber(pageSize),
        searchString: stringifyString(searchString),
        showSidebar: stringifyBoolean(showSidebar),
        hasOverrides: stringifySet(stringifyString)(hasOverrides),
        onboarding: stringifyBoolean(linkedFromOnboardingButton),
      });
      return routes.goldenrecords.generate({ moduleId }) + '?' + search;
    },
    grRules: () => {
      const { location: { moduleId } } = state;
      return routes.grRules.generate({ moduleId });
    },
    schemaMapping: () => {
      const { location: { recipeId }, schemaMapping: { sourceFilterDatasets, unifiedFilterDatasets, similarityThreshold } } = state;
      const search = qs.stringify({
        sourceFilterDatasets: stringifySet(stringifyString)(sourceFilterDatasets),
        unifiedFilterDatasets: stringifySet(stringifyString)(unifiedFilterDatasets),
        similarityThreshold: stringifyNumber(similarityThreshold),
      });
      return routes.schemaMapping.generate({ recipeId }) + '?' + search;
    },
    records: () => {
      const { location: { recipeId }, records: { useLocalStorage, pageNum, pageSize, provider, hasComments, selectedDatasetNames, queryString, columnSortStates } } = state;
      const search = qs.stringify({
        useLocalStorage: stringifyBoolean(useLocalStorage),
        pageNum: stringifyNumber(pageNum),
        pageSize: stringifyNumber(pageSize),
        provider: stringifyString(provider),
        hasComments: stringifyBoolean(hasComments),
        selectedDatasets: stringifySet(stringifyString)(selectedDatasetNames),
        queryString: stringifyString(queryString),
        sort: stringifySort(columnSortStates),
      });
      return routes.records.generate({ recipeId }) + '?' + search;
    },
    dashboard: () => {
      const { location: { recipeId } } = state;
      return routes.dashboard.generate({ recipeId });
    },
    taxonomy: () => {
      const { location: { recipeId }, taxonomy: { viewType, searchValue } } = state;
      const search = qs.stringify({
        viewType: stringifyString(viewType),
        searchValue: stringifyString(searchValue),
      });
      return routes.taxonomy.generate({ recipeId }) + '?' + search;
    },
    categoryDetail: () => {
      const { location: { recipeId }, categoryDetail: { categoryId } } = state;
      return routes.categoryDetail.generate({ recipeId, id: categoryId });
    },
    spend: () => {
      const { location: { recipeId }, transactions: { pageNum, pageSize, selectedDatasetIds, categoryIds, categorizedByMe, labeledByTamr, labeledByUser, unlabeled, unlabeledByTamr, labelAgreesWithTamr, labelDisagreesWithTamr, showExternalData, expertResponsesAgree, expertResponsesDisagree, expertsPending, expertsUnsure, expertsNone, expertsSome, hasComments, queryString, columnSortStates, assignedToMeComplete, assignedToMeToDo, assignmentStatusNone, assignmentStatusSome, assignmentStatusAll, assignmentStatusUnassigned, assignmentStatusAssigned, assignedToUsers, filterExpanded, highImpact, confidenceLow, confidenceMedium, confidenceHigh, hasConfidence, confidence, sortByConfidence } } = state;
      const search = qs.stringify({
        pageNum: stringifyNumber(pageNum),
        pageSize: stringifyNumber(pageSize),
        selectedDatasetIds: stringifySet(stringifyNumber)(selectedDatasetIds),
        categoryIds: stringifyList(stringifyNumber)(categoryIds),
        categorizedByMe: stringifyBoolean(categorizedByMe),
        labeledByTamr: stringifyBoolean(labeledByTamr),
        labeledByUser: stringifyBoolean(labeledByUser),
        unlabeled: stringifyBoolean(unlabeled),
        unlabeledByTamr: stringifyBoolean(unlabeledByTamr),
        labelAgreesWithTamr: stringifyBoolean(labelAgreesWithTamr),
        labelDisagreesWithTamr: stringifyBoolean(labelDisagreesWithTamr),
        showExternalData: stringifyBoolean(showExternalData),
        expertResponsesAgree: stringifyBoolean(expertResponsesAgree),
        expertResponsesDisagree: stringifyBoolean(expertResponsesDisagree),
        expertsPending: stringifyBoolean(expertsPending),
        expertsUnsure: stringifyBoolean(expertsUnsure),
        expertsNone: stringifyBoolean(expertsNone),
        expertsSome: stringifyBoolean(expertsSome),
        hasComments: stringifyBoolean(hasComments),
        queryString: stringifyString(queryString),
        sort: stringifySort(columnSortStates),
        assignedToMeComplete: stringifyBoolean(assignedToMeComplete),
        assignedToMeToDo: stringifyBoolean(assignedToMeToDo),
        assignmentStatusNone: stringifyBoolean(assignmentStatusNone),
        assignmentStatusSome: stringifyBoolean(assignmentStatusSome),
        assignmentStatusAll: stringifyBoolean(assignmentStatusAll),
        assignmentStatusUnassigned: stringifyBoolean(assignmentStatusUnassigned),
        assignmentStatusAssigned: stringifyBoolean(assignmentStatusAssigned),
        assignedToUsers: stringifySet(stringifyString)(assignedToUsers),
        filterExpanded: stringifyBoolean(filterExpanded),
        highImpact: stringifyBoolean(highImpact),
        confidenceLow: stringifyBoolean(confidenceLow),
        confidenceMedium: stringifyBoolean(confidenceMedium),
        confidenceHigh: stringifyBoolean(confidenceHigh),
        hasConfidence: stringifyBoolean(hasConfidence),
        confidence: confidence ? stringifyString(confidence.toUrlString()) : undefined,
        sortByConfidence: stringifyString(sortByConfidence),
      });
      return routes.spend.generate({ recipeId }) + '?' + search;
    },
    dnfBuilder: () => {
      const { location: { recipeId } } = state;
      return routes.dnfBuilder.generate({ recipeId });
    },
    pairs: () => {
      const {
        location: { recipeId },
        recordPairs: { pageNum, pageSize, queryString, topRowDatasetName, bottomRowDatasetName, labelConsensus,
          highImpact, hasComments, filterToInferredLabels, attributeSimilarityFilterStates, columnSortStates,
          userDefinedSignalSorts, manualLabelFilters, suggestedLabelFilters, confidenceRangeFilter, hasResponses,
          assignmentStatus, response, allAssignments, allResponses, showFilterPanel, selectedDatasetNames },
      } = state;
      const search = qs.stringify({
        pageNum: stringifyNumber(pageNum),
        pageSize: stringifyNumber(pageSize),
        queryString: stringifyString(queryString),
        topRowDatasetName: stringifyString(topRowDatasetName),
        bottomRowDatasetName: stringifyString(bottomRowDatasetName),
        labelConsensus: stringifyString(labelConsensus),
        highImpact: stringifyBoolean(highImpact),
        hasComments: stringifyBoolean(hasComments),
        attributeSimilarityFilters: stringifyList((m: AttributeSimilarityFilterModel) => m.toUrlString())(attributeSimilarityFilterStates),
        sort: stringifySort(columnSortStates),
        userDefinedSignalSort: stringifySort(userDefinedSignalSorts),
        manualLabel: stringifySet(stringifyString)(manualLabelFilters),
        suggestedLabel: stringifySet(stringifyString)(suggestedLabelFilters),
        confidenceRange: stringifyString(confidenceRangeFilter),
        hasResponses: stringifyBoolean(hasResponses),
        assignmentStatus: stringifyString(assignmentStatus),
        response: stringifyString(response),
        filterToInferredLabels: stringifyBoolean(filterToInferredLabels),
        allAssignments: stringifyString(allAssignments),
        allResponses: stringifyString(allResponses),
        selectedDatasetNames: stringifySet(stringifyString)(selectedDatasetNames),
        showFilterPanel: stringifyBoolean(showFilterPanel),
      });
      return routes.pairs.generate({ recipeId }) + '?' + search;
    },
    suppliers: () => {
      const { location: { recipeId }, suppliers: { top: { clustersSort, recordsColumnSortStates, queryString, pageNum, suppliersFilter, selectedSuppliers, filterResolved, filterUnresolved, clusterVerificationFilters, confidenceFilter, selectedSourceDatasets, recordsPageNum, recordsPageSize, pinnedRecords, lastSelectedSupplier, clusterChanges, customConfidenceFilter, hasCustomConfidenceFilter, hasCustomSimilarityFilter, customSimilarityFilter } } } = state;
      const search = qs.stringify({
        sort: stringifySort(recordsColumnSortStates),
        sSort: clustersSort,
        queryString: stringifyString(queryString),
        sPageNum: stringifyNumber(pageNum),
        sSuppliersFilter: stringifyString(suppliersFilter),
        suppliers: stringifySet(stringifyString)(selectedSuppliers),
        filterResolved: stringifyBoolean(filterResolved),
        filterUnresolved: stringifyBoolean(filterUnresolved),
        sHasVerifiedFilterState: clusterVerificationFilters.hasVerifiedFilterState,
        sHasVerifiedElsewhere: stringifyBoolean(clusterVerificationFilters.hasVerifiedElsewhere),
        sHasNoneVerified: stringifyBoolean(clusterVerificationFilters.hasNoneVerified),
        confidenceFilter: stringifySet(stringifyString)(confidenceFilter),
        customConfidenceFilter: customConfidenceFilter ? `${customConfidenceFilter.lowerBound} ${customConfidenceFilter.upperBound}` : undefined,
        hasCustomConfidenceFilter: stringifyBoolean(hasCustomConfidenceFilter),
        hasCustomSimilarityFilter: stringifyBoolean(hasCustomSimilarityFilter),
        customSimilarityFilter: stringifyNumber(customSimilarityFilter),
        selectedSourceDatasets: stringifySet(stringifyString)(selectedSourceDatasets),
        pageNum: stringifyNumber(recordsPageNum),
        pageSize: stringifyNumber(recordsPageSize),
        lastSelectedSupplier: lastSelectedSupplier ? stringifyString(lastSelectedSupplier) : undefined,
        pinnedRecords: stringifySet(stringifyString)(pinnedRecords),
        clusterChanges: stringifySet(stringifyString)(clusterChanges),
      });
      return routes.suppliers.generate({ recipeId }) + '?' + search;
    },
    projectStatus: () => {
      const { location: { recipeId } } = state;
      return routes.projectStatus.generate({ recipeId });
    },
    policies: () => {
      return routes.policies.generate();
    },
    pregroup: () => {
      const { location: { recipeId } } = state;
      return routes.pregroup.generate({ recipeId });
    },
    enrichment: () => {
      const { location: { moduleId } } = state;
      return routes.enrichment.generate({ moduleId });
    },
    notfound: () => {
      const { location: { path } } = state;
      return path;
    },
  };
  const url = routeHandlers[page]();
  return url;
};

export const getUrlWithHash = (state: AppState) => {
  return '#' + getUrl(state);
};
export const getUrlWithDomain = (state: AppState) => {
  return `${location.origin}/${getUrlWithHash(state)}`; // eslint-disable-line no-restricted-globals
};
export const getUrlForPage = (state: AppState, page: PageName) => {
  checkArg({ state }, ArgTypes.className('AppState'));
  checkArg({ page }, PAGE_NAMES_ARG_TYPE);
  return getUrlWithDomain(state.setIn(['location', 'page'], page));
};
