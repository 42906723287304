import { is, Map } from 'immutable';
import { bindActionCreators } from 'redux';

import createLoader from '../utils/createLoader';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { PaneE } from './Pane';
import { fetchSuppliers } from './SuppliersAsync';
import { getClustersFilterInfo } from './SuppliersStore';


const SuppliersLoader = createLoader((state, { pane }: { pane: PaneE }) => {
  const { suppliers } = state;
  const { [pane]: { loadedFilterInfo, loading } } = suppliers;
  const projectInfo = selectActiveProjectInfo(state);
  return {
    canFetch: !!(projectInfo && projectInfo.isUnifiedDatasetIndexed),
    shouldFetch: !is(Map<any>(getClustersFilterInfo(state, pane)), Map(loadedFilterInfo)),
    loading,
  };
}, (dispatch, { pane }) => bindActionCreators({
  onFetch: () => fetchSuppliers(pane),
}, dispatch), 'SuppliersLoader');

export default SuppliersLoader;
