import { useState } from 'react';
import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import ReactDOM from 'react-dom';
import { Alert } from 'react-bootstrap';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Map, Set } from 'immutable';
import { AutoSizer } from 'react-virtualized';
import Checkbox from '../components/Input/Checkbox';
import Input from '../components/Input/Input';
import DatasetPoliciesSelector, {
  FUTURE_DATASET_ID,
  FUTURE_DATASET_NAME,
} from '../datasets/DatasetPoliciesSelector';
import MinimalAuthUser from '../models/MinimalAuthUser';
import { jsonHeaders } from '../utils/Api';
import DocLink from '../components/DocLink';
import ConditionalButton from '../components/ConditionalButton';
import { canUpdateDatasetAtLeastOnePolicy } from '../utils/Authorization';
import { isPolicySelectionValid } from './DatasetManagementDialog';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Table from '../components/Table/Table';
import useDebounce from '../components/hooks';
import ColumnWidthProvider from '../components/ColumnWidthProvider';
import Button from '../components/Button';
import skeletonTable from './SkeletonTable.svg';
// @ts-expect-error
import loader from '../../images/tamr-loader.gif';


export const isLocal = window.location.host.startsWith('localhost');

const handleResponse = (response: Response) => {
  if (response.ok) {
    return Promise.resolve(response.json()); // This will end up in SUCCESS part
  }
  return Promise.resolve(response.json()).then((responseInJson) => { // This will end up in ERROR part
    return Promise.reject(responseInJson); //  responseInJson.message = "Some nasty error message!"
  });
};

export const JDBCApi = {
  ingest: (body: any) => fetch('/api/connect/jdbcIngest/ingest?async=true', { headers: jsonHeaders, method: 'POST', body: JSON.stringify(body) }).then(handleResponse),
  preview: (body: string) => fetch('/api/connect/jdbcIngest/preview', { headers: jsonHeaders, method: 'POST', body }).then(handleResponse),
  export: (body: any) => fetch('/api/connect/urlExport/jdbc?async=true', { headers: jsonHeaders, method: 'POST', body: JSON.stringify(body) }).then(handleResponse),
};

const JDBCContent = ({ user, policyIds, onSubmit, recipeId }: {recipeId: number|undefined, user: MinimalAuthUser | undefined, policyIds: Set<number>, onSubmit: () => void; }) => {
  const [jdbcUrl, setJdbcUrl] = useState<string>(isLocal ? 'jdbc:parquet:URI=s3://tamr-core-connect-test/parquet-variety/alltypes_dictionary.parquet;AuthScheme=AwsCredentialsFile' : '');
  const [jdbcQuery, setJdbcQuery] = useState<string>(isLocal ? 'select * from alltypes_dictionary' : '');
  const [primaryKey, setPrimaryKey] = useState<string>('');
  const [dbUsername, setDbUsername] = useState<string>('');
  const [dbPassword, setDbPassword] = useState<string>('');
  const [fetchSize, setFetchSize] = useState<number>(10000);
  const [profile, setProfile] = useState<boolean>(true);

  const [datasetName, setDatasetName] = useState<string>('');
  const mutation = useMutation(JDBCApi.ingest);
  const portal = document.getElementById('JDBCPortal');
  const [appendData, setAppendData] = useState<boolean>(false);
  const previewBody = useDebounce(JSON.stringify({ queryConfig: { jdbcUrl, dbUsername, dbPassword, fetchSize }, datasetName, query: jdbcQuery, primaryKey: (primaryKey === '') ? undefined : [primaryKey] }), 500);
  const preview = useQuery(['connect/jdbcIngest/preview', previewBody], () => JDBCApi.preview(previewBody), { enabled: false });

  return <div>
    <p>Ingest data using JDBC. <DocLink path={'reference/using-the-core-connect-api'} skipPrependDocs>Learn more.</DocLink></p>
    {mutation.isSuccess && !!mutation.data?.jobId !== false && <Alert bsStyle="success" className="my-8"> Successfully started Connect JDBC ingest job: {mutation.data?.jobId}. Track its progress in the Jobs page.</Alert>}
    <TextareaAutosize value={jdbcUrl} onChange={(e) => setJdbcUrl(e.currentTarget.value)} placeholder="JDBC Url" style={{ display: 'block', width: '100%', marginBottom: '15px' }} maxRows={15} />
    <TextareaAutosize value={jdbcQuery} onChange={(e) => setJdbcQuery(e.currentTarget.value)} placeholder="JDBC Query" style={{ display: 'block', width: '100%', marginBottom: '15px' }} maxRows={15} />
    <Input componentClassName={'mb-16'} value={primaryKey} onChange={e => setPrimaryKey(e)} title="Primary Key" style={{ display: 'block' }} />
    <Input componentClassName={'mb-16'} type="text" value={datasetName} onChange={e => setDatasetName(e)} title="Dataset Name" style={{ display: 'block' }} />
    <Input componentClassName={'mb-16'} type="text" value={dbUsername} onChange={e => setDbUsername(e)} title="Database Username (Optional)" style={{ display: 'block' }} />
    <Input componentClassName={'mb-16'} type="password" value={dbPassword} onChange={e => setDbPassword(e)} title="Database Password (Optional)" style={{ display: 'block' }} />
    <Input componentClassName={'mb-16'} type="number" value={fetchSize} onChange={e => (Number(e) ? setFetchSize(Number(e)) : null)} title="Fetch Size" style={{ display: 'block' }} />
    <Checkbox className={'mr-8'} titlePosition="right" title="Profile dataset" value={profile} onChange={checked => setProfile(checked)} />
    <Checkbox className={'mr-8'} titlePosition="right" title="Append data to tamr dataset" value={!appendData} onChange={checked => setAppendData(!checked)} />

    {!canUpdateDatasetAtLeastOnePolicy({ user }) ? null : (
      <div className="form-component-spacer">
        <div className="section-title">SELECT POLICIES</div>
        <div className="policies-section">
          <DatasetPoliciesSelector datasetId={FUTURE_DATASET_ID} datasetName={FUTURE_DATASET_NAME} /></div>
      </div>
    )}

    <div className="form-component-spacer">
      <div className="section-title" style={{ display: 'inline-block', lineHeight: '37px' }}>PREVIEW {((preview.isLoading && preview.fetchStatus !== 'idle')) && <img src={loader} style={{ width: '37px', height: '37px', opacity: 0.4, filter: 'alpha(opacity=40)', marginLeft: '12px' }} />}</div>
      <Button style={{ float: 'right', marginBottom: '10px' }} onClick={() => preview.refetch()} disabled={!jdbcUrl || !jdbcQuery}>Generate Preview</Button>
      <div style={{ height: '250px', clear: 'both' }}>
        {preview.isLoading && <img src={skeletonTable} height="240px" width="100%" />}
        {preview.isError && <div style={{ color: 'red' }}>Unable to fetch preview. <b>{(preview.error as any)?.message}</b></div>}
        {preview.isSuccess && <AutoSizer>
          {({ width, height }) => (
            <ColumnWidthProvider>
              <Table
                tableType="stripes"
                getLength={() => preview.data.length}
                {... { height, width }}
                rowHeight={30}
            >
                {Object.keys(preview.data?.[0] || {}).map(name => (
                  <Column
                    key={name}
                    columnKey={name}
                    width={125}
                    header={<Cell>{name}</Cell>}
                    cell={({ rowIndex, columnKey }) => <Cell><span title={preview.data?.[rowIndex]?.[columnKey || '']}>{preview.data?.[rowIndex]?.[columnKey || '']}</span></Cell>}
                    isResizable
                />
                ))}
              </Table>
            </ColumnWidthProvider>
          )}
        </AutoSizer>}
      </div>
    </div>
    {portal && ReactDOM.createPortal(<ConditionalButton
      tooltipPlacement="top"
      preconditions={Map({
        'Adding dataset in progress': !mutation.isLoading,
        'Please enter a name for the dataset': datasetName.length !== 0,
        'Please enter a jdbc url': jdbcUrl.length !== 0,
        'Please enter a jdbc query': jdbcQuery.length !== 0,
        'Please select a policy': isPolicySelectionValid({ user, policies: policyIds }),
      })}
      onClick={() => {
        mutation.mutate({ queryConfig: { jdbcUrl, dbUsername, dbPassword, fetchSize }, datasetName, query: jdbcQuery, primaryKey: (primaryKey === '') ? undefined : [primaryKey], policyIds, recipeId, profile, truncateTamrDataset: !appendData });
        onSubmit();
      }}
      >Save Dataset</ConditionalButton>, portal)}
  </div>;
};
export default JDBCContent;
