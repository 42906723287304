import React from 'react';

import styles from './CheckboxColumn.module.scss';
import Checkbox from './Input/Checkbox';
import Cell from './Table/Cell';
import Column from './Table/Column';


const CheckboxColumn: React.FunctionComponent<{
  selected: (rowIndex: number) => boolean
  allSelected: boolean
  onToggle: (rowIndex: number, checked: boolean, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onToggleAll: () => void
}> = ({ selected, allSelected, onToggle, onToggleAll }) => {
  const header = () => {
    return (
      <Cell className={styles.checkboxCell}>
        <Checkbox value={allSelected} onChange={onToggleAll} />
      </Cell>
    );
  };
  const cell = ({ rowIndex }: { rowIndex: number }) => {
    return (
      <Cell className={styles.checkboxCell}>
        <Checkbox value={selected(rowIndex)} onChange={(...args) => onToggle(rowIndex, ...args)} />
      </Cell>
    );
  };
  return (
    <Column width={30} key="__cb__" {...{ header, cell }} fixed />
  );
};

export default CheckboxColumn;
