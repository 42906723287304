import * as EnricherRegistration from '../models/EnricherRegistration';
import { AbstractModule, abstractModuleArgTypes } from '../models/Module';
import { ArgTypes } from '../utils/ArgValidation';
import { checkTypesAndCast } from '../utils/CheckTypesAndCast';

// com.tamr.recipe.models.flows.enrichment.EnrichmentModule

export const TYPE = 'Enrichment (ALPHA)';

export interface InputField {
  inputName: string
  enricherName: string
  type: string
  required: boolean
}
export interface OutputField {
  name: string
  type: string
}

export interface EnrichmentModule extends AbstractModule {
  type: typeof TYPE
  displayName: string
  externalId: string
  description: string
  inputDataset: string | undefined | null
  outputDataset: string | undefined | null
  enricherType: EnricherRegistration.EnricherRegistration | undefined | null
  inputFields: InputField[]
  outputFields: OutputField[]
}
export const argTypes = {
  ...abstractModuleArgTypes,
  displayName: ArgTypes.string,
  externalId: ArgTypes.string,
  description: ArgTypes.string,
  inputDataset: ArgTypes.nullable(ArgTypes.string),
  outputDataset: ArgTypes.nullable(ArgTypes.string),
  enricherType: ArgTypes.nullable(EnricherRegistration.argType),
  inputFields: ArgTypes.array.of(ArgTypes.object.withShape({ inputName: ArgTypes.string, enricherName: ArgTypes.string, type: ArgTypes.string, required: ArgTypes.bool })),
  outputFields: ArgTypes.array.of(ArgTypes.object.withShape({ name: ArgTypes.string, type: ArgTypes.string })),
} as const;

export const fromJSON = checkTypesAndCast<EnrichmentModule, typeof argTypes>(argTypes);
