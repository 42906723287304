import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import LoadingPanel from '../components/LoadingPanel';
import { isCuratorByProjectId, isVerifierByProjectId } from '../utils/Authorization';
import { getAuthorizedUser, selectActiveProjectInfo } from '../utils/Selectors';
import CategorizationDashboardCuratorCards from './CategorizationDashboardCuratorCards';
import CategorizationDashboardGuide from './CategorizationDashboardGuide';
import CategorizationDashboardIntro from './CategorizationDashboardIntro';
import CategorizationDashboardReviewerCards from './CategorizationDashboardReviewerCards';
import { getCategorizationStatus } from './DashboardUtils';

const CategorizationDashboard = _.compose(
  connect(state => {
    if (state.categorizationDashboard.loading ||
      state.categorizationDashboard.loadingTaxonomy ||
      state.unifiedDatasets.loading
    ) {
      return { loading: true };
    }
    return {
      isUserACurator: isCuratorByProjectId(getAuthorizedUser(state), selectActiveProjectInfo(state)?.projectDoc.id.id),
      isUserAVerifier: isVerifierByProjectId(getAuthorizedUser(state), selectActiveProjectInfo(state)?.projectDoc.id.id),
      stepCompletionStatuses: getCategorizationStatus(state),
      loading: false,
    };
  }),
)(({ isUserACurator, isUserAVerifier, stepCompletionStatuses, loading }) => {
  switch (true) {
    case (isUserACurator || isUserAVerifier) && !stepCompletionStatuses.contains(false): return <CategorizationDashboardCuratorCards />;
    case loading: return <LoadingPanel />;
    case !isUserACurator && !isUserAVerifier: return <CategorizationDashboardReviewerCards />;
    case !stepCompletionStatuses.contains(true): return <CategorizationDashboardIntro />;
    default: return <CategorizationDashboardGuide />;
  }
});

export default CategorizationDashboard;
