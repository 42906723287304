import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import ProcurementCategory from '../models/ProcurementCategory';
import ProcurementTaxonomy from '../models/ProcurementTaxonomy';
import CategorySummary from './CategorySummary';
import CategoryTree from './CategoryTree';
import Details from './Details';
import DetailsHeader from './DetailsHeader';

/**
 * Panel with detailed information about a selected object (taxonomy / tier / category).
 * Includes header with critical identifying information.
 * Includes general key / value pairs of metadata on selected object.
 * Includes specialized widgets (e.g. summary of category counts, counts of labels passed in from caller microservice).
 */
const DetailsPane = connect(state => {
  const { taxonomy: { taxonomy, activeCategory } } = state;
  return { taxonomy, activeCategory };
})(/**
 * Panel with detailed information about a selected object (taxonomy / tier / category).
 * Includes header with critical identifying information.
 * Includes general key / value pairs of metadata on selected object.
 * Includes specialized widgets (e.g. summary of category counts, counts of labels passed in from caller microservice).
 */
  class DetailsPane extends React.Component {
    static propTypes = {
      activeCategory: PropTypes.instanceOf(ProcurementCategory),
      onSidebarCategoryClick: PropTypes.func,
      sidebarWidgetRenderer: PropTypes.func,
      taxonomy: PropTypes.instanceOf(ProcurementTaxonomy),
    };

    renderExtraDetails = () => {
      if (!this.props.sidebarWidgetRenderer) {
        return <div />;
      }
      return this.props.sidebarWidgetRenderer(this.props.activeCategory);
    };

    renderCategoryTree = () => {
      if (this.props.activeCategory) {
        return (
          <div className="details-pane-section">
            <CategoryTree
              category={this.props.activeCategory}
              taxonomy={this.props.taxonomy}
              title="Category Tree"
              onSidebarCategoryClick={this.props.onSidebarCategoryClick}
          />
          </div>
        );
      }
    };

    render() {
      if (!this.props.taxonomy) {
        return <div />;
      }
      // TODO: Enable caller microservice to pass in data for aggregations
      // Number of suggested/finalized labeled records is a first-class aggregation
      // Should support other arbitrary aggregations (e.g. $ spend per category, num suppliers per category)
      // Caller will need to pass in things like callback actions (e.g. go to page of records and filter on selected category)

      // TODO: Show Tier summary (in addition to category and taxonomy summaries)
      let realName;
      let createdBy;
      let createdAt;
      let description;
      if (this.props.activeCategory) { // show details for activeCategory
        realName = this.props.activeCategory.path.get(-1);
        createdBy = this.props.activeCategory.createdBy;
        createdAt = this.props.activeCategory.createdAt * 1000;
        description = this.props.activeCategory.metadata.get('description');
      } else {
        realName = 'taxonomy';
        createdBy = 'admin';
        createdAt = 0;
      }

      return (
        <div className="details-pane-component">
          <DetailsHeader
            createdBy={createdBy}
            createdAt={createdAt}
            realName={realName}
        />
          {this.renderExtraDetails()}
          <CategorySummary
            activeCategory={this.props.activeCategory}
            taxonomy={this.props.taxonomy}
        />
          <Details
            description={description}
        />
          {this.renderCategoryTree()}
        </div>
      );
    }
  });

export default DetailsPane;
