import * as DedupClient from '../api/DedupClient';
import { AppThunkAction } from '../stores/AppAction';
import * as Result from '../utils/Result';
import { getUnifiedDatasetName } from '../utils/Selectors';


export const fetchDedupModel = (): AppThunkAction<void> => (dispatch, getState) => {
  const unifiedDatasetName = getUnifiedDatasetName(getState());
  if (unifiedDatasetName) {
    dispatch({ type: 'Dedup.fetchDedupModel' });
    DedupClient.fetchDedupModel(unifiedDatasetName).then(Result.handler(
      () => dispatch({ type: 'Dedup.fetchDedupModelCompleted' }),
      () => dispatch({ type: 'Dedup.fetchDedupModelFailed' }),
    ));
  }
};
