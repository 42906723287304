import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

// @ts-expect-error
import InitialLoad from '../stores/InitialLoad';
import { AppState, dispatch } from '../stores/MainStore';
import { fetchUsers } from '../users/UsersAsync';
import { getAuthorizedUser } from '../utils/Selectors';

const mapStateToProps = (state: AppState) => {
  return { hasUser: !!getAuthorizedUser(state) };
};
const mapDispatchToProps = {
  onFetchUsers: fetchUsers,
};

type UsersContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const UsersContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class UsersContainer extends React.Component<UsersContainerProps> {
    static propTypes = {
      hasUser: PropTypes.bool.isRequired,
      onFetchUsers: PropTypes.func.isRequired,
    };

    componentDidMount() {
      const { onFetchUsers, hasUser } = this.props;
      onFetchUsers();
      if (hasUser) {
        InitialLoad(dispatch);
      }
    }

    componentDidUpdate(prevProps: UsersContainerProps) {
      if (this.props.hasUser && prevProps.hasUser !== this.props.hasUser) {
        InitialLoad(dispatch);
      }
    }

    render() {
      const { hasUser, children } = this.props;
      return <div>{hasUser ? children : null}</div>;
    }
  },
);

export default UsersContainer;
