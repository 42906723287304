import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _, { compose } from 'underscore';

import Button from '../components/Button';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import TamrIcon from '../components/TamrIcon';
import { RECIPE_INPUTS_KEY } from '../projects/RecipeConstants';
import style from './ConfirmChangeProjectMembershipsDialog.module.scss';
import { submit } from './ManageProjectDatasetsApi';

const ConfirmChangeProjectMembershipsDialog = compose(
  connect(({
    datasetCatalog: { datasets: allDatasetDocs },
    manageProjectDatasets: { managingProjectsForDatasets, editingChanges, confirming },
    projects: { projectsWithStatus },
  }) => {
    const datasetDocs = allDatasetDocs.filter(doc => managingProjectsForDatasets.includes(doc.data.name));
    const projectDocs = projectsWithStatus.map(pws => pws.project);
    let additions = new Map();
    let removals = new Map();
    projectDocs.forEach(projectDoc => {
      const projectName = projectDoc.data.name;
      const projectEditingChange = editingChanges.get(projectDoc.data.name);
      if (projectEditingChange === undefined) {
        return;
      }
      const projectRecipeIds = projectDoc.data.steps.map(docId => docId.id).toArray();
      const memberDatasetIds = datasetDocs.filter(datasetDoc => {
        const recipeInputs = datasetDoc.data.metadata.get(RECIPE_INPUTS_KEY);
        return !_.isEmpty(_.intersection(recipeInputs, projectRecipeIds));
      }).map(doc => doc.id.id);
      if (projectEditingChange) {
        const datasetDocsToAdd = datasetDocs.filter(datasetDoc => {
          return !memberDatasetIds.includes(datasetDoc.id.id);
        });
        additions = additions.set(projectName, datasetDocsToAdd.map(doc => doc.data.name).toSet());
      } else { // must have been removed
        const datasetDocsToRemove = datasetDocs.filter(datasetDoc => {
          return memberDatasetIds.includes(datasetDoc.id.id);
        });
        removals = removals.set(projectName, datasetDocsToRemove.map(doc => doc.data.name).toSet());
      }
    });

    return { managingProjectsForDatasets, editingChanges, confirming, additions, removals };
  }, {
    onCancel: () => ({ type: 'ManageProjectDatasets.cancel' }),
    onSubmit: submit,
  }),
)(class ConfirmChangeProjectMembershipsDialog extends React.Component {
  static propTypes = {
    additions: ImmutablePropTypes.mapOf(ImmutablePropTypes.setOf(PropTypes.string), PropTypes.string), // project name to dataset names
    confirming: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    removals: ImmutablePropTypes.mapOf(ImmutablePropTypes.setOf(PropTypes.string), PropTypes.string), // project name to dataset names
  };

  render() {
    const { confirming, additions, removals, onCancel, onSubmit } = this.props;
    return (
      <Dialog
        dialogStyle={DialogStyle.PRIMARY}
        onHide={onCancel}
        show={confirming}
        header={(
          <Dialog.Header>
            Are you sure?
          </Dialog.Header>
        )}
        body={(
          <div>
            <div className={style.explanationContainer}>
              <TamrIcon className={style.warningIcon} iconName="tamr-icon-warning" size={13} />
              <span className={style.explanation}>
                Removing datasets from a project will remove all attribute mappings and records from
                those projects.
              </span>
            </div>
            {removals.map((datasetNames, projectName) => {
              return (
                <div key={projectName}>
                  <div>Remove datasets from <span className={style.projectName}>{projectName}</span></div>
                  <ul>{datasetNames.map(name => <li key={name}>{name}</li>)}</ul>
                </div>
              );
            }).toArray()}
            {additions.map((datasetNames, projectName) => {
              return (
                <div key={projectName}>
                  <div>Add datasets to <span className={style.projectName}>{projectName}</span></div>
                  <ul>{datasetNames.map(name => <li key={name}>{name}</li>)}</ul>
                </div>
              );
            }).toArray()}
          </div>
        )}
        footer={(
          <div className={style.footer}>
            <Button onClick={onSubmit}>Yes</Button>
            <Button buttonType="Secondary" onClick={onCancel}>Cancel</Button>
          </div>
        )}
      />
    );
  }
});

export default ConfirmChangeProjectMembershipsDialog;
