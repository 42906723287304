import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import { publishClusters } from './SuppliersAsync';

const PublishClustersWarningDialog = _.compose(
  connect((state) => {
    const { publishClustersDialog: { showWarning } } = state;
    return { show: showWarning };
  }, (dispatch) => bindActionCreators({
    onClosePublishWarningDialog: () => ({ type: 'PublishClusterDialog.hideWarning' }),
    onPublishClusters: publishClusters,
  }, dispatch)),
)(class PublishClustersDialog extends React.Component {
  static propTypes = {
    onClosePublishWarningDialog: PropTypes.func.isRequired,
    onPublishClusters: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
  };

  render() {
    const { show, onClosePublishWarningDialog, onPublishClusters } = this.props;
    return (
      <Dialog
        show={show}
        onHide={onClosePublishWarningDialog}
        title={<span>Review changes and update published clusters</span>}
        body={
          <div>
            Publishing a version of clusters can take a while. Are you sure you want to continue?
          </div>
        }
        dialogStyle={DialogStyle.PRIMARY}
        footer={
          <ButtonToolbar>
            <Button onClick={onClosePublishWarningDialog} buttonType="Secondary">
              Cancel
            </Button>
            <Button onClick={onPublishClusters}>
              Update published clusters
            </Button>
          </ButtonToolbar>
        }
      />
    );
  }
});

export default PublishClustersWarningDialog;
