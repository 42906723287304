import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { getSparkConfigNamesQuery } from '../api/JobClient';
import { AppState } from '../stores/MainStore';

/**
 * Fetch all Spark config names into the state
 */
export const fetchSparkConfigNames = (): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
  dispatch({ type: 'Jobs.fetchSparkConfigNames' });
  return getSparkConfigNamesQuery()
    .then(configNames => dispatch({ type: 'Jobs.fetchSparkConfigNamesCompleted', configNames }))
    .catch(response => dispatch({ type: 'Jobs.fetchSparkConfigNamesFailed', response: response && response.message }));
};
