import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import CenterContent from '../components/CenterContent';
import { history } from '../utils/History';
import style from './NoSourceAttributes.module.scss';

const NoSourceAttributes = connect(state => {
  const { location: { recipeId } } = state;
  return { recipeId };
})(class NoSourceAttributes extends React.Component {
  static propTypes = {
    canUserEdit: PropTypes.bool.isRequired,
    recipeId: PropTypes.number.isRequired,
  };

  navigateToDatasets = () => {
    history.push(`/datasets/recipe/${this.props.recipeId}`);
  };

  render() {
    const { canUserEdit } = this.props;
    return (
      <CenterContent>
        <div className={classNames({ [style.message]: canUserEdit })}>You have no source attributes.</div>
        {canUserEdit
          ? (<CenterContent vertically={false}>
            <Button onClick={this.navigateToDatasets}>Add datasets</Button>
          </CenterContent>)
          : null
        }
      </CenterContent>
    );
  }
});

export default NoSourceAttributes;
