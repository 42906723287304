import { isNumber } from 'underscore';

import { getDatasetByName } from '../api/DatasetClient';
import { FetchResult } from '../api/FetchResult';
import {
  createModule,
  getEnricherTypes,
  getModule,
  getModuleStatus,
  publishModule,
  updateModule,
} from '../api/RecipeClient';
import { refreshAuthCache } from '../auth/AuthCacheAsync';
import { SUBMIT_MEMBERSHIP_CHANGES_COMPLETED } from '../datasets/ProjectDatasetCatalogActionTypes';
import Document from '../models/doc/Document';
import { EnricherRegistration } from '../models/EnricherRegistration';
import { FUTURE_PROJECT_ID, FUTURE_PROJECT_NAME } from '../projects/ProjectPoliciesSelector';
import {
  CANCEL_ADDING_PROJECT,
  STOP_EDITING_PROJECT_POLICIES,
} from '../projects/ProjectsActionTypes';
import { fetch } from '../projects/ProjectsApi';
import { AppThunkAction, AppThunkDispatch } from '../stores/AppAction';
import { AppState } from '../stores/MainStore';
import { toJSON } from '../utils/Api';
import { history } from '../utils/History';
import * as Result from '../utils/Result';
import {
  CREATE_MODULE,
  CREATE_MODULE_COMPLETED,
  CREATE_MODULE_FAILED,
  FETCH_INPUT_DATASET,
  FETCH_INPUT_DATASET_COMPLETED,
  FETCH_INPUT_DATASET_FAILED,
  FETCH_MODULE,
  FETCH_MODULE_COMPLETED,
  FETCH_MODULE_FAILED,
  FETCH_MODULE_STATUS_COMPLETED,
  FETCH_TYPES,
  FETCH_TYPES_COMPLETED,
  FETCH_TYPES_FAILED, OUTPUT_DATASET_NAME_INVALID, OUTPUT_DATASET_NAME_VALID,
  SET_DATASET_NAME,
  SET_FIELD_MAPPING,
  SET_OUTPUT_FIELDS,
  SET_TYPE,
  SUBMIT_PUBLISH,
  SUBMIT_PUBLISH_COMPLETED,
  SUBMIT_PUBLISH_FAILED,
  UPDATE_MODULE,
  UPDATE_MODULE_COMPLETED,
  UPDATE_MODULE_FAILED,
} from './EnrichmentActionTypes';
import * as EnrichmentModule from './EnrichmentModule';


export const doFetchInputDataset = async (dispatch: AppThunkDispatch, getState: () => AppState) => {
  dispatch({ type: FETCH_INPUT_DATASET });
  const { enrichment: { module } } = getState();
  const inputDataset = module?.data?.inputDataset;
  if (inputDataset) {
    return getDatasetByName(inputDataset)
      .then(dataset => dispatch({ type: FETCH_INPUT_DATASET_COMPLETED, inputDataset: dataset }))
      .catch(response => dispatch({
        type: FETCH_INPUT_DATASET_FAILED,
        errorMessage: response.statusText,
      }));
  }
};

export const validateOutputName = (datasetName: string): AppThunkAction<void> => async (dispatch) => {
  return getDatasetByName(datasetName)
    .then(() => dispatch({ type: OUTPUT_DATASET_NAME_INVALID }))
    .catch(() => dispatch({ type: OUTPUT_DATASET_NAME_VALID }));
};

/**
 * Wrapper over {@link RecipeClient.updateModule} that dispatches actions at each step of the update http request.
 */
export function doUpdateEnrichmentModule(
  dispatch: AppThunkDispatch,
  module: Document<EnrichmentModule.EnrichmentModule>,
): Promise<FetchResult<Document<EnrichmentModule.EnrichmentModule>>> {
  dispatch({ type: UPDATE_MODULE });
  return updateModule({
    moduleId: module.id.id,
    version: module.lastModified.version,
    module: module.data,
    fromJSON: EnrichmentModule.fromJSON,
    skipConfigValidation: true,
  }).then(Result.mapperMonad(
    mod => {
      dispatch({ type: UPDATE_MODULE_COMPLETED, module: mod });
      return mod;
    },
    (error) => {
      dispatch({ type: UPDATE_MODULE_FAILED, errorMessage: 'Error updating module' });
      return error;
    },
  ));
}

export function updateEnrichmentInputDataset(
  dispatch: AppThunkDispatch,
  getState: () => AppState,
  module: Document<EnrichmentModule.EnrichmentModule>,
  datasetName: string,
): Promise<FetchResult<Document<EnrichmentModule.EnrichmentModule>>> {
  const updatedModuleDoc = module.setIn(['data', 'inputDataset'], datasetName);
  return doUpdateEnrichmentModule(dispatch, updatedModuleDoc).then(Result.mapperMonad(
    (updatedModule) => {
      doFetchInputDataset(dispatch, getState);
      dispatch({ type: SUBMIT_MEMBERSHIP_CHANGES_COMPLETED });
      return updatedModule;
    },
    error => error,
  ));
}

// consistent with the projects fetch actions - fetches module status along with module every time
export const fetchEnrichmentModule = (): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  const { location: { moduleId } } = state;
  if (!isNumber(moduleId)) {
    console.error('Cannot fetch module - moduleId is not defined');
    return;
  }
  dispatch({ type: FETCH_MODULE, moduleId });
  return Promise.all([
    getModule(moduleId, EnrichmentModule.fromJSON),
    getModuleStatus(moduleId),
  ])
    .then(([module, moduleStatusResult]) => {
      dispatch({ type: FETCH_MODULE_COMPLETED, module });
      if (module.data.inputDataset) {
        doFetchInputDataset(dispatch, getState);
      }
      Result.handle(moduleStatusResult,
        moduleStatus => {
          dispatch({ type: FETCH_MODULE_STATUS_COMPLETED, moduleHasBeenSubmitted: !!moduleStatus.lastPublishedModuleVersion });
        },
        // This just means that the module has never been published
        () => dispatch({ type: FETCH_MODULE_STATUS_COMPLETED, moduleHasBeenSubmitted: false }),
      );
    })
    .catch(response => dispatch({ type: FETCH_MODULE_FAILED, errorMessage: response.statusText }));
};

export const createEnrichmentModule = (displayName: string, description: string, enricherType: EnricherRegistration, outputDataset: string): AppThunkAction<void> => async (dispatch, getState) => {
  dispatch({ type: CREATE_MODULE });
  const { accessControl: { projectDraftPolicyManager } } = getState();
  const query = {
    resourcePolicyIds: projectDraftPolicyManager?.draftPolicyResourceship.toArray(),
    memberPolicyIds: projectDraftPolicyManager?.draftPolicyMembership.toArray(),
  };
  return createModule({
    module: {
      type: EnrichmentModule.TYPE,
      displayName,
      externalId: displayName,
      description,
      enricherType,
      inputDataset: undefined,
      outputDataset,
      inputFields: [],
      outputFields: enricherType.schema?.outputs.map(({ name, type }) => ({ name, type })),
    },
    fromJSON: EnrichmentModule.fromJSON,
    query,
    errorMessage: 'Error creating module',
  })
    .then(module => {
      fetch()(dispatch, getState, undefined);
      history.push(`/enrichment/${module.id.id}`);
      // Close the creation dialog
      dispatch({ type: CANCEL_ADDING_PROJECT });
      dispatch({ type: STOP_EDITING_PROJECT_POLICIES, projectName: FUTURE_PROJECT_NAME, projectId: FUTURE_PROJECT_ID });
      return dispatch({ type: CREATE_MODULE_COMPLETED, module });
    })
    // As permissions are updated during module creation, we must force-refresh auth
    .then(() => refreshAuthCache(dispatch))
    .catch(response => {
      dispatch({ type: CREATE_MODULE_FAILED, errorMessage: response.statusText });
      dispatch({ type: STOP_EDITING_PROJECT_POLICIES, projectName: FUTURE_PROJECT_NAME, projectId: FUTURE_PROJECT_ID });
    });
};

export const updateEnrichmentModule = (): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  const { enrichment: { module } } = state;
  if (module) {
    doUpdateEnrichmentModule(dispatch, module);
  }
};

export const fetchEnricherTypes = (): AppThunkAction<void> => async (dispatch) => {
  dispatch({ type: FETCH_TYPES });
  getEnricherTypes()
    .then(toJSON)
    .then(json => dispatch({ type: FETCH_TYPES_COMPLETED, types: json }))
    .catch(response => dispatch({ type: FETCH_TYPES_FAILED, errorMessage: response.statusText }));
};

export const setEnricherType = (enricherType: EnricherRegistration): AppThunkAction<void> => async (dispatch, getState) => {
  dispatch({ type: SET_TYPE, enricherType });
  dispatch({ type: SET_OUTPUT_FIELDS, outputFields: enricherType.schema?.outputs.map(({ name, type }) => ({ name, type })) });
  updateEnrichmentModule()(dispatch, getState, undefined);
};

export const setDatasetName = (datasetName: string): AppThunkAction<void> => async (dispatch, getState) => {
  const { enrichment: { module } } = getState();
  dispatch({ type: SET_DATASET_NAME, datasetName });
  dispatch({ type: SET_OUTPUT_FIELDS, outputFields: module?.data.enricherType?.schema?.outputs.map(({ name, type }) => ({ name, type })) || [] });
  updateEnrichmentModule()(dispatch, getState, undefined);
};

export const setFieldMapping = (inputName: string, enricherName: string): AppThunkAction<void> => async (dispatch, getState) => {
  dispatch({ type: SET_FIELD_MAPPING, inputName, enricherName });
  updateEnrichmentModule()(dispatch, getState, undefined);
  fetchEnrichmentModule();
};

export const submitPublish = (): AppThunkAction<void> => async (dispatch, getState) => {
  const { enrichment: { module } } = getState();
  dispatch({ type: SUBMIT_PUBLISH });
  if (module) {
    dispatch({ type: SET_OUTPUT_FIELDS, outputFields: module?.data.enricherType?.schema?.outputs.map(({ name, type }) => ({ name, type })) || [] });

    // Error handling is handled within the update method
    return doUpdateEnrichmentModule(dispatch, module).then(() => {
      return publishModule(module.id.id)
        .then(toJSON)
        .then(() => dispatch({
          type: SUBMIT_PUBLISH_COMPLETED,
        }))
        .catch(response => dispatch({
          type: SUBMIT_PUBLISH_FAILED,
          errorMessage: response.statusText,
        }));
    });
  }
};
