import { Set } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import uri from 'urijs';

import PopoverTrigger from '../components/PopoverTrigger';
import Cell from '../components/Table/Cell';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import { CANCELED, CANCELING, FAILED, RUNNING, SUCCEEDED } from '../constants/JobState';
import CoreConnectProvider from '../coreConnectService/CoreConnectProvider';
import Document from '../models/doc/Document';
import actionCellStyle from './DatasetActionCell.module.scss';
import style from './DatasetExportCell.module.scss';

function renderCsvDownloadContent(dataset, errorMessage, exportUpToDate, exportStatus, lastGenerated, exportingTooltip, onConfirmOverwriteExport, onConfirmExport, cannotExportToCsv) {
  switch (exportStatus) {
    case 'PENDING':
    case RUNNING:
    case CANCELING:
      return (
        <TooltipTrigger placement="bottom" content="This can take a while.">
          <div className={actionCellStyle.actionInProgress}>
            Generating CSV for download
          </div>
        </TooltipTrigger>
      );
    case CANCELED:
    case FAILED:
      return (<div>
        <div className={style.exportMenuItem}>
          <a onClick={() => this.onShowExportFailureDialogAndHide(errorMessage)}>
            <span>View CSV for download export error</span>
          </a>
        </div>
        <div className={style.exportMenuItem}>
          <TooltipTrigger placement="bottom" content={exportingTooltip}>
            <a onClick={() => onConfirmOverwriteExport(Set.of(dataset.data.name))}>
              <span>Regenerate CSV for download</span>
            </a>
          </TooltipTrigger>
        </div>
      </div>

      );
    case SUCCEEDED:
      return (

        <div>
          <div className={style.exportMenuItem}>
            <TooltipTrigger placement="bottom" content={`Generated ${lastGenerated}.`}>
              <a
                href={uri(SERVICES.procure(`/export/read/dataset/${dataset.id.id}`))}
                rel="noopener noreferrer"
                target="_blank"
              >
                <span>Download CSV</span>
              </a>
            </TooltipTrigger>
            {exportUpToDate ?
              null
              :
              <TooltipTrigger
                placement="bottom"
                content="Export is out of date (dataset has been updated)">
                <span className={actionCellStyle.actionWarningWrapper}>
                  <TamrIcon
                    iconName="tamr-icon-warning"
                    className={actionCellStyle.actionWarning}
                    size={12} />
                </span>
              </TooltipTrigger>
            }
          </div>
          <div className={style.exportMenuItem}>
            <TooltipTrigger placement="bottom" content={exportingTooltip}>
              <a onClick={() => {
                onConfirmOverwriteExport(Set.of(dataset.data.name));
              }}>
                <span>Regenerate CSV for download</span>
              </a>
            </TooltipTrigger>
          </div>
        </div>
      );
    default:
      if (cannotExportToCsv) {
        return (
          <div className={style.exportMenuItem}>
            <div className={style.disabled}>
              <TooltipTrigger
                placement="bottom"
                content="This dataset has a complex schema and cannot be downloaded as a CSV."
            >
                <span>Export CSV for download unavailable</span>
              </TooltipTrigger>
            </div>
          </div>
        );
      }
      return (
        <div className={style.exportMenuItem}>
          <TooltipTrigger placement="bottom" content={exportingTooltip}>
            <a onClick={() => onConfirmExport(Set.of(dataset.data.name))}>
              Export CSV for download
            </a>
          </TooltipTrigger>
        </div>
      );
  }
}

class DatasetExportCell extends React.Component {
  static propTypes = {
    coreconnectDefaultProvider: PropTypes.string.isRequired,
    dataset: PropTypes.instanceOf(Document).isRequired,
    errorMessage: PropTypes.string,
    exportStatus: PropTypes.string,
    exportUpToDate: PropTypes.bool.isRequired,
    exportingToCoreConnectTooltip: PropTypes.string.isRequired,
    exportingTooltip: PropTypes.string.isRequired,
    lastGenerated: PropTypes.string.isRequired,
    onConfirmExport: PropTypes.func.isRequired,
    onConfirmOverwriteExport: PropTypes.func.isRequired,
    onOpenCoreConnectExportDialog: PropTypes.func.isRequired,
    onOpenCoreConnectExportJDBCDialog: PropTypes.func.isRequired,
    onSetTamrSourceDataset: PropTypes.func.isRequired,
    onShowExportFailureDialog: PropTypes.func.isRequired,
  };

  onShowExportFailureDialogAndHide = (errorMessage) => {
    const { onShowExportFailureDialog } = this.props;
    onShowExportFailureDialog(errorMessage);
    this.refs.exportErrorPopover.hide();
  };

  render() {
    const {
      dataset,
      errorMessage,
      exportUpToDate,
      exportStatus,
      lastGenerated,
      exportingTooltip,
      onConfirmOverwriteExport,
      onConfirmExport,
      coreconnectDefaultProvider,
      exportingToCoreConnectTooltip,
      onOpenCoreConnectExportDialog,
      onOpenCoreConnectExportJDBCDialog,
      onSetTamrSourceDataset,
    } = this.props;
    const cannotExportToCsv = dataset.data.metadata.get('cannotExportToCsv');
    return (
      <Cell className={actionCellStyle.actionCell}>
        <div className={actionCellStyle.actionCellLink}>
          <PopoverTrigger
            placement="left"
            contentFunction={(hide) => {
              return (
                <div>
                  {renderCsvDownloadContent(
                    dataset, errorMessage, exportUpToDate, exportStatus, lastGenerated,
                    exportingTooltip, onConfirmOverwriteExport, onConfirmExport, cannotExportToCsv,
                  )}
                  <div className={style.exportMenuItem}>
                    <TooltipTrigger placement="bottom" content={exportingToCoreConnectTooltip}>
                      <a
                        onClick={() => {
                          onSetTamrSourceDataset(dataset.data);
                          onOpenCoreConnectExportDialog();
                          hide();
                        }}
                      >
                        <span>Export to {CoreConnectProvider.get(coreconnectDefaultProvider?.toUpperCase())}</span>
                      </a>
                    </TooltipTrigger>
                  </div>
                  <div className={style.exportMenuItem}>
                    <TooltipTrigger placement="bottom" content={'Export with JDBC'}>
                      <a
                        onClick={() => {
                          onSetTamrSourceDataset(dataset.data);
                          onOpenCoreConnectExportJDBCDialog();
                          hide();
                        }}
                      >
                        <span>Export with JDBC</span>
                      </a>
                    </TooltipTrigger>
                  </div>
                </div>
              );
            }
              }
            >
            <a>
              Export
            </a>
          </PopoverTrigger>

        </div>
      </Cell>
    );
  }
}

export default DatasetExportCell;
