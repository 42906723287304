import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import * as ProcurifyClient from '../../api/ProcurifyClient';
import { DEDUP } from '../../constants/RecipeType';
import ProjectInfo from '../../models/ProjectInfo';
import { TxnUrlBuilder } from '../../transactions/TransactionUtils';
import * as filtersSlice from './filtersSlice';

// State

export const initialState = {
  loading: false as boolean,
  precisionProblem: undefined as number | undefined,
  recallProblem: undefined as number | undefined,
  bothProblems: undefined as number | undefined,
  noProblem: undefined as number | undefined,
};

export type State = typeof initialState

// Thunks

const fetchRecordCountForFilter = async (projectInfo: ProjectInfo, filters: filtersSlice.State) => {
  const { unifiedDatasetDoc } = projectInfo;
  if (!unifiedDatasetDoc) {
    return Promise.reject();
  }

  const url = new TxnUrlBuilder(DEDUP)
    .unifiedDatasetName(unifiedDatasetDoc.data.name)
    .pageNum(0)
    .pageSize(1)
    .testRecordsAccuracyFilter(filtersSlice.toApiFilters(filters));

  return ProcurifyClient.fetchTransactions(url, unifiedDatasetDoc)
    .then(({ total }) => total);
};

export const fetchRecordCounts = createAsyncThunk(
  'clusters/test-records/filters/fetchRecordCounts',
  async (projectInfo: ProjectInfo) => {
    return Promise.all([
      fetchRecordCountForFilter(projectInfo, {
        precisionProblem: true,
        recallProblem: false,
        bothProblems: false,
        noProblem: false,
      }),
      fetchRecordCountForFilter(projectInfo, {
        precisionProblem: false,
        recallProblem: true,
        bothProblems: false,
        noProblem: false,
      }),
      fetchRecordCountForFilter(projectInfo, {
        precisionProblem: false,
        recallProblem: false,
        bothProblems: true,
        noProblem: false,
      }),
      fetchRecordCountForFilter(projectInfo, {
        precisionProblem: false,
        recallProblem: false,
        bothProblems: false,
        noProblem: true,
      }),
    ]).then(
      ([precisionProblem, recallProblem, bothProblems, noProblem]) => ({
        precisionProblem,
        recallProblem,
        bothProblems,
        noProblem,
      }),
    );
  },
);

// Reducer

export const reducer = createReducer(
  initialState,
  builder => builder
    .addCase(fetchRecordCounts.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchRecordCounts.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    })
    .addCase(fetchRecordCounts.rejected, (state) => {
      state.loading = false;
    })
  ,
);
