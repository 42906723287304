import { Set } from 'immutable';

import enumify from '../../constants/enumify';

// com.tamr.workflow.specs.dedup.SignalSpecConfig.SimilarityFunction

export enum SimilarityFunctionE {
  COSINE = 'COSINE',
  ABSOLUTE_COSINE = 'ABSOLUTE_COSINE',
  JACCARD = 'JACCARD',
  DIFF = 'DIFF',
  RELATIVE_DIFF = 'RELATIVE_DIFF',
  GEOSPATIAL_HAUSDORFF = 'GEOSPATIAL_HAUSDORFF',
  GEOSPATIAL_DIRECTIONAL_HAUSDORFF = 'GEOSPATIAL_DIRECTIONAL_HAUSDORFF',
  GEOSPATIAL_MIN_DISTANCE = 'GEOSPATIAL_MIN_DISTANCE',
  GEOSPATIAL_RELATIVE_HAUSDORFF = 'GEOSPATIAL_RELATIVE_HAUSDORFF',
  GEOSPATIAL_RELATIVE_AREA_OVERLAP = 'GEOSPATIAL_RELATIVE_AREA_OVERLAP',
}

export const {
  COSINE,
  ABSOLUTE_COSINE,
  JACCARD,
  DIFF,
  RELATIVE_DIFF,
  GEOSPATIAL_HAUSDORFF,
  GEOSPATIAL_DIRECTIONAL_HAUSDORFF,
  GEOSPATIAL_MIN_DISTANCE,
  GEOSPATIAL_RELATIVE_HAUSDORFF,
  GEOSPATIAL_RELATIVE_AREA_OVERLAP,
} = SimilarityFunctionE;

export const NUMERICAL_SIMILARITIES = Set([
  DIFF,
  RELATIVE_DIFF,
  GEOSPATIAL_HAUSDORFF,
  GEOSPATIAL_DIRECTIONAL_HAUSDORFF,
  GEOSPATIAL_MIN_DISTANCE,
  GEOSPATIAL_RELATIVE_HAUSDORFF,
  GEOSPATIAL_RELATIVE_AREA_OVERLAP,
]);

export const ABSOLUTE_SIMILARITIES = Set([
  ABSOLUTE_COSINE,
  DIFF,
  GEOSPATIAL_HAUSDORFF,
  GEOSPATIAL_DIRECTIONAL_HAUSDORFF,
  GEOSPATIAL_MIN_DISTANCE,
]);

export const GEOSPATIAL_SIMILARITIES = Set([
  GEOSPATIAL_HAUSDORFF,
  GEOSPATIAL_DIRECTIONAL_HAUSDORFF,
  GEOSPATIAL_MIN_DISTANCE,
  GEOSPATIAL_RELATIVE_HAUSDORFF,
  GEOSPATIAL_RELATIVE_AREA_OVERLAP,
]);

export const allowsTokenizerConfig = (simFunc: SimilarityFunctionE): boolean => {
  return !NUMERICAL_SIMILARITIES.has(simFunc);
};

export const isGeospatialSimilarityFunction = (simFunc: SimilarityFunctionE): boolean => {
  return GEOSPATIAL_SIMILARITIES.has(simFunc);
};

export const isAbsoluteSimilarityFunction = (simFunc: SimilarityFunctionE): boolean => {
  return ABSOLUTE_SIMILARITIES.has(simFunc);
};

export default enumify(SimilarityFunctionE);
