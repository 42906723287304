import { List } from 'immutable';
import uri from 'urijs';

import DatasetSpecWithTableConfig from '../addDataset/DatasetSpecWithTableConfig';
import SampleRecords from '../addDataset/SampleRecords';
import StorageNode from '../addDataset/StorageNode';
import StorageProvider from '../addDataset/StorageProvider';
import Attribute from '../models/Attribute';
import Dataset from '../models/Dataset';
import DatasetMetadataSpec from '../models/DatasetMetadataSpec';
import DatasetStatus from '../models/DatasetStatus';
import Document from '../models/doc/Document';
import { Query } from '../models/doc/Query';
import Job from '../models/Job';
import {
  fetchAsResult,
  handleErrorsWithMessage,
  jsonHeaders,
  networkError,
  toJSON,
} from '../utils/Api';
import { $TSFixMe, JsonObject } from '../utils/typescript';
import { FetchResult, toFetchResult } from './FetchResult';
import ServiceProxy from './ServiceProxy';

export function getExternalStorageProviders(): Promise<List<StorageProvider>> {
  return fetch(ServiceProxy.dataset('/external-storage-providers'), { headers: jsonHeaders, method: 'GET' })
    .then(handleErrorsWithMessage('Unable to get external storage providers'), networkError)
    .then(toJSON)
    .then(data => List(data).map(StorageProvider.fromJSON));
}

export function getExternalStorageNodeChildren({ storageProviderName, path }: {
  storageProviderName: string
  path: string | null
}): Promise<FetchResult<List<StorageNode>>> {
  return fetchAsResult(uri(ServiceProxy.dataset(`/external-storage-providers/${storageProviderName}/node-children`)).query({ path }).toString(), { headers: jsonHeaders, method: 'GET' })
    .then(toFetchResult(data => List(data as $TSFixMe).map(StorageNode.fromJSON)));
}

export function postDataset({ datasetSpec }: {
  datasetSpec: DatasetSpecWithTableConfig
}): Promise<FetchResult<Document<Dataset>>> {
  return fetchAsResult(uri(ServiceProxy.dataset('/datasets')).toString(), { headers: jsonHeaders, method: 'POST', body: JSON.stringify(datasetSpec) })
    .then(toFetchResult(data => Document.fromJSON(data, Dataset.fromJSON)));
}

export function getExternalSample({ storageProviderName, path, numRecords }: {
  storageProviderName: string
  path: string
  numRecords: number
}): Promise<FetchResult<SampleRecords>> {
  return fetchAsResult(
    uri(ServiceProxy.dataset(`/external-storage-providers/${storageProviderName}/sample`)).query({ path, numRecords }).toString(),
    { headers: jsonHeaders, method: 'GET' },
  ).then(toFetchResult(data => SampleRecords.fromJSON(data)));
}

export function getDatasetMetadata({ datasetName }: { datasetName: string }): Promise<List<DatasetMetadataSpec>> {
  return fetch(
    uri(ServiceProxy.dataset(`/datasets/${datasetName}/metadata`)).toString(),
    { headers: jsonHeaders, method: 'GET' },
  )
    .then(handleErrorsWithMessage(`Unable to get metadata for ${datasetName}.`), networkError)
    .then(toJSON)
    // parse metadata as a spec object since the dataset name is known.
    .then(data => List(data).map(DatasetMetadataSpec.fromJSON));
}

export function setDatasetMetadata({ datasetName, datasetMetadata, deleteMetadata }: {
  datasetName: string
  datasetMetadata: List<DatasetMetadataSpec>
  deleteMetadata?: boolean | undefined | null
}): Promise<Response> {
  // If the metadata is to be deleted, add a delete tag to every metadata object.
  const metadata = deleteMetadata ? datasetMetadata.map(m => m.asDelete()) : datasetMetadata;

  return fetch(
    uri(ServiceProxy.dataset(`/datasets/${datasetName}/metadata`)).toString(),
    {
      headers: jsonHeaders,
      method: 'POST',
      body: JSON.stringify(metadata),
    },
  )
    .then(handleErrorsWithMessage(`Unable to write metadata for ${datasetName}.`), networkError);
}

/**
 * Returns a promise containing a list of parsed JSON representations of the records in the given dataset
 */
export function fetchDatasetMaterialization(datasetName: string): Promise<List<JsonObject>> {
  return fetch(ServiceProxy.dataset(`/datasets/${datasetName}/materialize`))
    .then(handleErrorsWithMessage(`Unable to read materialization for dataset '${datasetName}'`), networkError)
    .then(response => response.text())
    .then(jsonLines => List(jsonLines.split('\n')))
    .then(recordStrings => recordStrings.map(s => JSON.parse(s) as JsonObject));
}

export function fetchDatasetAttributes(datasetName: string): Promise<List<Attribute>> {
  return fetch(
    ServiceProxy.dataset('/schema/attributes/query-docs-with-datasets'),
    { headers: jsonHeaders, method: 'POST', body: JSON.stringify([datasetName]) },
  )
    .then(handleErrorsWithMessage(`Error getting attributes for dataset: '${datasetName}'`), networkError)
    .then(toJSON)
    // @ts-expect-error result in JSON has any type
    .then(attributes => attributes.map(attr => Document.fromJSON(attr)).map(attrDoc => attrDoc.data));
}

export function getDatasetByName(datasetName: string): Promise<Document<Dataset>> {
  return fetch(ServiceProxy.dataset(`/datasets/named/${datasetName}`), { headers: jsonHeaders, method: 'GET' })
    .then(handleErrorsWithMessage(`Unable to fetch metadata for dataset: ${datasetName}`), networkError)
    .then(toJSON)
    .then(data => Document.fromJSON(data, Dataset.fromJSON));
}

export function postStatusQuery(query: Query): Promise<List<DatasetStatus>> {
  return fetch(ServiceProxy.dataset('/datasets/status'), { headers: jsonHeaders, method: 'POST', body: JSON.stringify(query) })
    .then(handleErrorsWithMessage('Unable to query dataset status'), networkError)
    .then(toJSON)
    .then(data => List(data).map(DatasetStatus.fromJSON));
}

export function patchAttributes(attrs: $TSFixMe): Promise<Response> {
  return fetch(
    ServiceProxy.dataset('/schema/attributes'),
    {
      method: 'PATCH',
      headers: jsonHeaders,
      body: JSON.stringify(attrs),
    },
  ).then(handleErrorsWithMessage('Unable to patch attributes'), networkError);
}

interface RecordId {
  [key: string]: string | number
}

export const fetchRecordVersions = async (datasetName: string, recordIds: Array<RecordId>) => {
  const response = await fetch(
    ServiceProxy.dataset(`/datasets/${datasetName}/recordVersions`),
    {
      method: 'POST',
      headers: jsonHeaders,
      body: recordIds.map(x => JSON.stringify(x)).join('\n'),
    },
  );
  return response.json();
};

export function materializeDatasets(
  jobDescription: string,
  sparkConfigOverrides: string | null | undefined,
  datasetNames: string[],
): Promise<Document<Job> | null> {
  return fetch(
    uri(ServiceProxy.dataset('/datasets/materialize'))
      .query(sparkConfigOverrides ? { sparkConfigOverrides } : {}).toString(),
    {
      method: 'POST',
      headers: jsonHeaders,
      body: JSON.stringify({
        jobDescription,
        nonIncrementalDatasets: [],
        targets: datasetNames,
      }),
    },
  ).then(
    handleErrorsWithMessage(`Unable to submit '${jobDescription}' (a materialize datasets job) for datasets ${datasetNames}`),
    networkError,
  )
    .then(toJSON)
    .then(data => {
      if (!data.job) {
        return null;
      }
      return Document.fromJSON(data.job, Job.fromJSON);
    });
}
