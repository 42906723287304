import { Set } from 'immutable';
import { isString } from 'underscore';

import { AbstractModule, abstractModuleArgTypes } from '../models/Module';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { checkTypesAndCast } from '../utils/CheckTypesAndCast';
import * as ClusterDatasetConfig from './ClusterDatasetConfig';
import * as Rule from './Rule';

// com.tamr.recipe.models.flows.goldenRecords.GoldenRecordsModule

export const TYPE = 'GoldenRecords';

export const getPinnedClusterDatasetName: {
  (publishedDatasetName: string): string
  (module: GoldenRecordsModule): string
} = (arg: string | GoldenRecordsModule) => {
  const publishedDatasetName = isString(arg) ? arg : arg.goldenRecordDataset;
  return `${publishedDatasetName}_pinned_cluster_input`;
};
export const getSourceListDiffDatasetName = (sourceListDatasetName: string) => {
  checkArg({ sourceListDatasetName }, ArgTypes.string);
  return `${sourceListDatasetName}_diff`;
};
export const getClusterProfileDatasetName: {
  (publishedDatasetName: string): string
  (module: GoldenRecordsModule): string
} = (arg: string | GoldenRecordsModule) => {
  const publishedDatasetName = isString(arg) ? arg : arg.goldenRecordDataset;
  return `${publishedDatasetName}_cluster_profile`;
};

export interface GoldenRecordsModule extends AbstractModule {
  type: typeof TYPE,
  clusterDataset: ClusterDatasetConfig.ClusterDatasetConfig
  // This field was not present before v2019.014, and deserializing previous versions of the
  //   module (say, the version at which indexDraft was last run) needs to support this field
  //   being missing. Past v2019.014 however, this field is always present.
  sourceListDataset: string | null | undefined
  overrideDataset: string
  goldenRecordDataset: string
  entityRule: Rule.Rule
  rules: Rule.Rule[]
}
export const argTypes = {
  ...abstractModuleArgTypes,
  clusterDataset: ClusterDatasetConfig.argType,
  sourceListDataset: ArgTypes.nullable(ArgTypes.string),
  overrideDataset: ArgTypes.string,
  goldenRecordDataset: ArgTypes.string,
  entityRule: Rule.argType,
  rules: ArgTypes.array.of(Rule.argType),
} as const;

export const fromJSON = checkTypesAndCast<GoldenRecordsModule, typeof argTypes>(argTypes);

export function ruleNames(module: GoldenRecordsModule): Set<string> {
  return Set(module.rules.map(r => r.outputAttributeName)).add(module.entityRule.outputAttributeName);
}
