import { getDnfPairEstimates } from '../api/DedupClient';
import { getDnf, runOperation, updateRecipe } from '../api/RecipeClient';
import RecipeOperations from '../constants/RecipeOperations';
import { DEDUP_INFO_METADATA_KEY } from '../models/Recipe';
import { fetch as fetchProjectsStatus } from '../projects/ProjectsApi';
import { selectActiveProjectInfo } from '../utils/Selectors';

export const fetchDnf = () => (dispatch, getState) => {
  const state = getState();
  const activeProjectInfo = selectActiveProjectInfo(state);
  if (activeProjectInfo.hasDnf) {
    const { unifiedDatasetName, recipeId } = activeProjectInfo;
    dispatch({ type: 'Dnf.fetchDnf' });
    getDnf(unifiedDatasetName)
      .then(dnf => dispatch({ type: 'Dnf.fetchDnfCompleted', dnf, recipeId }))
      .catch(response => dispatch({ type: 'Dnf.fetchDnfFailed', response, recipeId }));
  }
};

const updatePairGenerationModel = () => (dispatch, getState) => {
  const state = getState();
  const { dnfBuilder: { sourcesToBlockSelfMatch, sourcesToBlockSelfCluster, dnf } } = state;
  const { recipe, recipeId, recipeDoc: { lastModified } } = selectActiveProjectInfo(state);
  dispatch({ type: 'DnfBuilder.updatePairGenerationModel' });
  return updateRecipe({
    recipeId,
    version: lastModified.version,
    recipe: recipe
      .updateIn(['metadata', DEDUP_INFO_METADATA_KEY], dedupInfo => {
        return Object.assign({}, dedupInfo, {
          noDedupWithinSources: sourcesToBlockSelfMatch,
          noClusteringWithinSources: sourcesToBlockSelfCluster,
          dnf,
        });
      }),
  })
    .then(recipeDoc => {
      return dispatch(fetchProjectsStatus()).then(() => {
        dispatch({ type: 'DnfBuilder.updatePairGenerationModelCompleted', recipeDoc });
      });
    })
    .catch(response => {
      dispatch({ type: 'DnfBuilder.updatePairGenerationModelFailed', response });
      return Promise.reject();
    });
};

// Generate estimated counts for candidate pairs
export const generateEstimates = () => (dispatch, getState) => {
  dispatch({ type: 'DnfBuilder.submitGenerateEstimates' });
  const state = getState();
  const { recipeId } = selectActiveProjectInfo(state);
  dispatch(updatePairGenerationModel())
    .then(() => (
      runOperation(recipeId, RecipeOperations.ESTIMATE_PAIRS)
        .catch(response => dispatch({ type: 'DnfBuilder.submitGenerateEstimatesFailed', response }))
    ))
    .then(job => dispatch({ type: 'DnfBuilder.submitGenerateEstimatesCompleted', job }))
    .catch(() => dispatch({ type: 'DnfBuilder.submitGenerateEstimatesFailed' })); // Don't pass response info if `updatePairGenerationModel` failed. Response info already passed earlier in `updatePairGenerationModel`.
};

// TODO: change this to call dedup directly
// Generate candidate pairs
export const generatePairs = () => (dispatch, getState) => {
  dispatch({ type: 'DnfBuilder.generatePairs' });
  const state = getState();
  const { recipeId } = selectActiveProjectInfo(state);
  dispatch(updatePairGenerationModel())
    .then(() => (
      runOperation(recipeId, RecipeOperations.PAIRS)
        .catch(response => dispatch({ type: 'DnfBuilder.generatePairsFailed', response }))
    ))
    .then(() => dispatch({ type: 'DnfBuilder.generatePairsCompleted' }))
    .catch(() => dispatch({ type: 'DnfBuilder.generatePairsFailed' })); // Don't pass response info if `updatePairGenerationModel` failed. Response info already passed earlier in `updatePairGenerationModel`.
};

export const fetchDnfPairEstimates = () => (dispatch, getState) => {
  const state = getState();
  const activeProjectInfo = selectActiveProjectInfo(state);
  if (activeProjectInfo.hasDnf) {
    const { unifiedDatasetName } = activeProjectInfo;
    dispatch({ type: 'PairEstimates.fetch' });
    getDnfPairEstimates(unifiedDatasetName)
      .then(pairEstimates => dispatch({ type: 'PairEstimates.fetchCompleted', pairEstimates }))
      .catch(response => dispatch({ type: 'PairEstimates.fetchFailed', response }));
  }
};
