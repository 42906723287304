import React from 'react';
import { connect } from 'react-redux';

import WarningDialog from '../components/WarningDialog';
import { cancelJob } from './JobApi';

const JobCancelConfirmDialog = connect((state) => {
  const { jobs: { confirmingCancelForJob } } = state;
  return { confirmingCancelForJob };
}, {
  onCancelJob: cancelJob,
  onCancel: () => ({ type: 'Jobs.cancelConfirmCancelJob' }),
})(({ confirmingCancelForJob, onCancelJob, onCancel }) => {
  return (
    <WarningDialog
      actionName={'Yes, cancel job'}
      cancelName={'Do not cancel job'}
      message={'Are you sure you want to cancel this job?'}
      onAccept={onCancelJob}
      onHide={onCancel}
      show={confirmingCancelForJob}
    />
  );
});

export default JobCancelConfirmDialog;
