import classNames from 'classnames';
import React from 'react';

import { TamrIconName } from '../components/Icons/TamrIconClasses';
import TamrIcon, { TamrIconProps } from '../components/TamrIcon';
import enumify from '../constants/enumify';
import { clusterSuggestionExists, isVerifiedInAnotherCluster } from '../models/EsRecord';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { Nullable } from '../utils/typescript';
import styles from './VerificationIcon.module.scss';
import VerificationType, { LOCK, MOVABLE, SUGGEST, UNVERIFIED, VerificationTypeE } from './VerificationType';


const CHECK_CIRCLE_ICON_NAME = 'check-circle';
const LIGHTBULB_ON_ICON_NAME = 'lightbulb-on';
const LIGHTBULB_OFF_ICON_NAME = 'lightbulb-off';
const LOCK_ICON_NAME = 'lock';

export enum VerificationIconTypeE {
  VERIFICATION_ICON_TYPE_VERIFIED_HERE = 'VERIFICATION_ICON_TYPE_VERIFIED_HERE',
  VERIFICATION_ICON_TYPE_VERIFIED_ELSEWHERE = 'VERIFICATION_ICON_TYPE_VERIFIED_ELSEWHERE',
  VERIFICATION_ICON_TYPE_SUGGESTABLE_HAS_SUGGESTION = 'VERIFICATION_ICON_TYPE_SUGGESTABLE_HAS_SUGGESTION',
  VERIFICATION_ICON_TYPE_SUGGESTABLE_NO_SUGGESTION = 'VERIFICATION_ICON_TYPE_SUGGESTABLE_NO_SUGGESTION',
  VERIFICATION_ICON_TYPE_LOCKED = 'VERIFICATION_ICON_TYPE_LOCKED',
}
export const VERIFICATION_ICON_TYPE_VERIFIED_HERE = VerificationIconTypeE.VERIFICATION_ICON_TYPE_VERIFIED_HERE;
export const VERIFICATION_ICON_TYPE_VERIFIED_ELSEWHERE = VerificationIconTypeE.VERIFICATION_ICON_TYPE_VERIFIED_ELSEWHERE;
export const VERIFICATION_ICON_TYPE_SUGGESTABLE_HAS_SUGGESTION = VerificationIconTypeE.VERIFICATION_ICON_TYPE_SUGGESTABLE_HAS_SUGGESTION;
export const VERIFICATION_ICON_TYPE_SUGGESTABLE_NO_SUGGESTION = VerificationIconTypeE.VERIFICATION_ICON_TYPE_SUGGESTABLE_NO_SUGGESTION;
export const VERIFICATION_ICON_TYPE_LOCKED = VerificationIconTypeE.VERIFICATION_ICON_TYPE_LOCKED;
export const VerificationIconType = enumify({ VERIFICATION_ICON_TYPE_VERIFIED_HERE, VERIFICATION_ICON_TYPE_VERIFIED_ELSEWHERE, VERIFICATION_ICON_TYPE_SUGGESTABLE_HAS_SUGGESTION, VERIFICATION_ICON_TYPE_SUGGESTABLE_NO_SUGGESTION, VERIFICATION_ICON_TYPE_LOCKED });

export const getVerificationIconType = (
  verificationType: VerificationTypeE,
  currentClusterId: string,
  suggestedClusterId: Nullable<string>,
  verifiedClusterId: Nullable<string>,
): VerificationIconTypeE | null => {
  checkArg({ verificationType }, ArgTypes.orNull(VerificationType.argType));
  checkArg({ currentClusterId }, ArgTypes.string);
  checkArg({ suggestedClusterId }, ArgTypes.orNull(ArgTypes.string));
  checkArg({ verifiedClusterId }, ArgTypes.orNull(ArgTypes.string));
  if (verificationType === null || verificationType === UNVERIFIED) {
    return null;
  }
  if (verificationType === MOVABLE) {
    const verifiedElsewhere = isVerifiedInAnotherCluster(verificationType, currentClusterId, verifiedClusterId);
    if (verifiedElsewhere) {
      return VERIFICATION_ICON_TYPE_VERIFIED_ELSEWHERE;
    }
    return VERIFICATION_ICON_TYPE_VERIFIED_HERE;
  }
  if (verificationType === SUGGEST) {
    const hasSuggestion = clusterSuggestionExists(verificationType, currentClusterId, suggestedClusterId);
    if (hasSuggestion) {
      return VERIFICATION_ICON_TYPE_SUGGESTABLE_HAS_SUGGESTION;
    }
    return VERIFICATION_ICON_TYPE_SUGGESTABLE_NO_SUGGESTION;
  }
  if (verificationType === LOCK) {
    return VERIFICATION_ICON_TYPE_LOCKED;
  }
  return null;
};

const getBaseIconColorClassName = (verificationIconType: VerificationIconTypeE): string => {
  checkArg({ verificationIconType }, ArgTypes.orNull(VerificationIconType.argType));
  switch (verificationIconType) {
    case VERIFICATION_ICON_TYPE_VERIFIED_ELSEWHERE: return styles.unverifiedIconColor;
    default: return styles.verifiedIconColor;
  }
};

const getSecondaryIconName = (verificationIconType: VerificationIconTypeE): TamrIconName | null => {
  checkArg({ verificationIconType }, ArgTypes.orNull(VerificationIconType.argType));
  switch (verificationIconType) {
    case VERIFICATION_ICON_TYPE_SUGGESTABLE_HAS_SUGGESTION: return LIGHTBULB_ON_ICON_NAME;
    case VERIFICATION_ICON_TYPE_SUGGESTABLE_NO_SUGGESTION: return LIGHTBULB_OFF_ICON_NAME;
    case VERIFICATION_ICON_TYPE_LOCKED: return LOCK_ICON_NAME;
    default: return null;
  }
};

const SecondaryIcon = ({ iconName, baseIconSize }: { iconName: TamrIconName, baseIconSize: number }): JSX.Element => {
  const size = baseIconSize * 0.7;
  const pushFactor = size * -0.25; // to place near the top-right corner
  return (
    <TamrIcon
      iconName={iconName}
      className={styles.secondaryIcon}
      size={size}
      style={{ top: pushFactor, right: pushFactor }}
    />
  );
};

interface VerificationIconOwnProps {
  className?: string
  containerProps?: { className?: string }
  verificationIconType: VerificationIconTypeE
  size: number
}

type VerificationIconProps = VerificationIconOwnProps & Omit<TamrIconProps, 'ref' | 'iconName'>

const VerificationIcon: React.FC<VerificationIconProps> = ({ containerProps, verificationIconType, className, size, ...rest }) => {
  const secondaryIconName = getSecondaryIconName(verificationIconType);
  return (
    <span {...containerProps} className={classNames(styles.iconContainer, containerProps?.className)} style={{ height: `${size}px` }}>
      <TamrIcon
        {...rest}
        iconName={CHECK_CIRCLE_ICON_NAME}
        className={classNames(styles.baseIcon, getBaseIconColorClassName(verificationIconType), className)}
        size={size}
      />
      {secondaryIconName && <SecondaryIcon iconName={secondaryIconName} baseIconSize={size} />}
    </span>
  );
};

export default VerificationIcon;
