import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';

const ConfirmDeleteTransformDialog = connect((state) => {
  const { transforms: { confirmingDeleteTransform } } = state;
  return { guid: confirmingDeleteTransform };
}, {
  onDelete: guid => ({ type: 'Transforms.delete', guid }),
  onHide: () => ({ type: 'Transforms.cancelDeleteTransform' }),
})(({ guid, onHide, onDelete }) => {
  const show = !!guid;
  return (
    <Dialog
      dialogStyle={DialogStyle.PRIMARY}
      show={show}
      onHide={onHide}
      title="Remove Transformation"
      body="Are you sure you want to remove this transformation?"
      footer={
        <ButtonToolbar>
          <Button key="hide" buttonType="Secondary" onClick={onHide}>Cancel</Button>
          <Button key="remove" buttonType="Primary" onClick={() => onDelete(guid)}>Remove</Button>
        </ButtonToolbar>
      }
    />
  );
});

export default ConfirmDeleteTransformDialog;
