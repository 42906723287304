import React from 'react';

import CenterContent from '../components/CenterContent';

class NoUnifiedDataset extends React.Component {
  render() {
    return (
      <CenterContent>
        <div>You have no unified dataset.</div>
      </CenterContent>
    );
  }
}

export default NoUnifiedDataset;
