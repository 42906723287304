import { List } from 'immutable';

import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import ClusterAssignment from './ClusterAssignment';
import ClusterVerificationCounts from './ClusterVerificationCounts';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

// com.tamr.dedup.models.Cluster

class Cluster extends getModelHelpers({
  clusterId: { type: ArgTypes.string },
  name: { type: ArgTypes.string },
  clusterNameHighlight: { type: ArgTypes.string },
  publishedName: { type: ArgTypes.nullable(ArgTypes.string) },
  recordCount: { type: ArgTypes.number },
  clusterVerificationCounts: { type: ArgTypes.nullable(ArgTypes.instanceOf(ClusterVerificationCounts)) },
  totalSpend: { type: ArgTypes.nullable(ArgTypes.number) },
  distinctDatasets: { type: ArgTypes.number },
  categoryIds: { type: ArgTypes.Immutable.list.of(ArgTypes.number) },
  clusterAssignments: { type: ArgTypes.array.of(ArgTypes.instanceOf(ClusterAssignment)) },
  averageLinkage: { type: ArgTypes.nullable(ArgTypes.number) }, // can be null in upgrade cases, or if cluster was manually edited
  matchingRecordsCount: { type: ArgTypes.number },
  deletedRecordsRemovedCount: { type: ArgTypes.number },
  existingRecordsRemovedCount: { type: ArgTypes.number },
  newRecordsAddedCount: { type: ArgTypes.number },
  existingRecordsAddedCount: { type: ArgTypes.number },
  trainingCluster: { type: ArgTypes.bool },
  testCluster: { type: ArgTypes.bool },
}, 'Cluster')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ClusterRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  withId(newId: string) {
    return this.set('clusterId', newId);
  }

  static fromJSON(obj: $TSFixMe) {
    const { clusterId, name, publishedName, clusterNameHighlight, recordCount, clusterVerificationCounts, totalSpend, distinctDatasets, averageLinkage, matchingRecordsCount, deletedRecordsRemovedCount, existingRecordsRemovedCount, newRecordsAddedCount, existingRecordsAddedCount, trainingCluster, testCluster, clusterAssignments } = obj;
    return new Cluster({
      clusterId,
      name,
      clusterNameHighlight,
      publishedName,
      recordCount,
      clusterVerificationCounts: clusterVerificationCounts ? ClusterVerificationCounts.fromJSON(clusterVerificationCounts) : null,
      totalSpend,
      distinctDatasets,
      averageLinkage,
      categoryIds: List(obj.categoryIds),
      clusterAssignments,
      matchingRecordsCount,
      deletedRecordsRemovedCount,
      existingRecordsRemovedCount,
      newRecordsAddedCount,
      existingRecordsAddedCount,
      trainingCluster,
      testCluster,
    });
  }
}

export default Cluster;
