import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Breadcrumb from '../components/Breadcrumb';
import ProcurementTaxonomy from '../models/ProcurementTaxonomy';
import { getIntOrUndef } from '../utils/Values';

const TaxonomyBreadcrumb = _.compose(
  connect(state => {
    const { taxonomy: { taxonomy } } = state;
    return { taxonomy };
  }),
)(class TaxonomyBreadcrumb extends React.Component {
  static propTypes = {
    categoryId: PropTypes.number,
    handleSelection: PropTypes.func.isRequired,
    taxonomy: PropTypes.instanceOf(ProcurementTaxonomy),
  };

  clearSelection = () => {
    this.props.handleSelection();
  };

  handleCategoryClick = (path) => {
    const category = this.props.taxonomy.findCategoryByPath(path);
    if (category) {
      this.props.handleSelection(category.categoryId);
    }
  };

  render() {
    let path = Immutable.List.of({ pathSegmentName: 'all', onClick: this.clearSelection });
    const categoryId = getIntOrUndef(this.props.categoryId);
    if (_.isNumber(categoryId) && this.props.taxonomy) {
      const category = this.props.taxonomy.findCategoryById(categoryId);
      if (category) {
        category.path.forEach((pathSegment, segmentDepth) => {
          const catPath = category.path.take(segmentDepth + 1);
          const isLeaf = category.path.size === segmentDepth + 1;
          const clickFunction = isLeaf ? undefined : _.partial(this.handleCategoryClick, catPath);
          path = path.push({
            pathSegmentName: pathSegment, onClick: clickFunction,
          });
        });
      }
    }
    return (
      <Breadcrumb
        path={path}
      />
    );
  }
});

export default TaxonomyBreadcrumb;
