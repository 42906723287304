import Response from '../constants/Response';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import Model from './Model';

class FeedbackResponse extends Model({
  entityId: { type: ArgTypes.string },
  response: { type: ArgTypes.nullable(ArgTypes.valueIn(Response)) },
  categoryId: { type: ArgTypes.number },
  reason: { type: ArgTypes.nullable(ArgTypes.string) },
}) {
  constructor() { super(...arguments); }

  static fromJSON(obj) {
    checkArg({ obj }, ArgTypes.object);
    return new FeedbackResponse({
      entityId: obj.entityId,
      response: obj.response,
      categoryId: obj.categoryId,
      reason: obj.reason,
    });
  }
}

export default FeedbackResponse;
