import { Map } from 'immutable';

import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import Dnf from './Dnf';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

export class PairEstimate extends getModelHelpers({
  candidateCount: { type: ArgTypes.number },
  dnfPairCount: { type: ArgTypes.number },
  averageBinsPerEntity: { type: ArgTypes.number },
}, 'PairEstimate')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class PairEstimateRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {}

// com.tamr.dedup.models.dnf.PairEstimates
class PairEstimates extends getModelHelpers({
  totalEstimate: { type: ArgTypes.instanceOf(PairEstimate) },
  clauseEstimates: { type: ArgTypes.Immutable.map.of(ArgTypes.instanceOf(PairEstimate), ArgTypes.number) },
  estimatedDnf: { type: ArgTypes.instanceOf(Dnf) },
  upToDate: { type: ArgTypes.bool },
}, 'PairEstimates')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class PairEstimatesRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static fromJSON(obj: $TSFixMe) {
    return new PairEstimates({
      totalEstimate: new PairEstimate(obj.totalEstimate),
      clauseEstimates: Map<string, PairEstimate>(obj.clauseEstimates)
        .mapEntries(([idx, pe]) => [parseInt(idx, 10), new PairEstimate(pe)]),
      estimatedDnf: Dnf.fromJSON(obj.estimatedDnf),
      upToDate: obj.upToDate,
    });
  }
}

export default PairEstimates;
