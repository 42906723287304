
import createLoader from '../../utils/createLoader';
import { selectActiveProjectInfo } from '../../utils/Selectors';
import { fetchRecordCounts } from './filtersCountsSlice';

const FiltersCountsLoader = createLoader(state => {
  const { loading, precisionProblem, recallProblem, noProblem } = state.clusters.testRecord.filtersCounts;

  const projectInfo = selectActiveProjectInfo(state);

  return {
    canFetch: !!projectInfo?.unifiedDatasetDoc,
    shouldFetch: !loading && (precisionProblem === undefined || recallProblem === undefined || noProblem === undefined),
    loading,
    fetchArgs: [projectInfo],
  };
}, {
  onFetch: fetchRecordCounts,
}, 'TestRecordFiltersRecordCountsLoader');

export default FiltersCountsLoader;
