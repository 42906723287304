import classNames from 'classnames';
import { List } from 'immutable';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Select, { Option } from 'react-select';
import _ from 'underscore';

import { ColumnHandle } from '../components/ColumnOrderSelector';
import TamrIcon from '../components/TamrIcon';
import Toolbar from '../components/Toolbar';
import TooltipTrigger from '../components/TooltipTrigger';
import WarningIcon from '../components/WarningIcon';
import { AppDispatch, AppState } from '../stores/MainStore';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { set } from '../utils/Collections';
import * as ca from './ChoosingAggregation';
import ChoosingAggregationFunction, { ChoosingAggregationFunctionE } from './ChoosingAggregationFunction';
import * as DatasetFilter from './DatasetFilter';
import { GRInput } from './EditableGoldenRecordsRule';
import * as fcr from './FilterClusterRecords';
import * as FilterEmpties from './FilterEmpties';
import { getFilterExpressionEnvelope, performStaticAnalysisForFilter } from './GoldenRecordsAsync';
import style from './GoldenRecordsSidebar.module.scss';
import { findRuleDeltaByName, getFilterExpressionAnalysisResult, getRuleFilter, selectAllPrioritizedSources, selectInputAttributeAsOptions } from './GoldenRecordsStore';
import * as Predicate from './Predicate';
import * as sif from './SingleInputFilter';

type FilterClusterRecords = fcr.FilterClusterRecords;

const { MODE, MAX, MIN } = ChoosingAggregationFunction;
const { CHOOSING_AGGREGATION, SINGLE_INPUT, DATASET_FILTER, FILTER_EMPTIES, PREDICATE } = fcr.FilterClusterRecordsTypeName;

export const FILTER_OPTIONS = [
  { label: 'Most common value', value: MODE },
  { label: 'Max', value: MAX },
  { label: 'Min', value: MIN },
  { label: 'Dataset', value: DATASET_FILTER },
  { label: 'Is not empty', value: FILTER_EMPTIES },
  { label: 'Logical filter', value: PREDICATE },
] as const;

function isCustomChoosingAggregation(filter: FilterClusterRecords | undefined): filter is ca.ChoosingAggregation {
  return !!filter && filter.type === ca.TYPE;
}

function isAnyChoosingAggregation(filter: FilterClusterRecords | undefined): filter is ca.ChoosingAggregation | sif.SingleInputFilter {
  return !!filter && (filter.type === ca.TYPE || filter.type === sif.TYPE);
}

function hasCustomExpression(filter: FilterClusterRecords | undefined): boolean {
  return !!filter && 'expression' in filter && !!filter.expression;
}

function changeFilterType({ filter, newType, allPrioritizedSources }: {
  filter: FilterClusterRecords,
  newType: (typeof FILTER_OPTIONS[number])['value'],
  allPrioritizedSources: List<string>
}): FilterClusterRecords {
  checkArg({ newType }, ArgTypes.valueIn(FILTER_OPTIONS.map(({ value }) => value)));
  checkArg({ allPrioritizedSources }, ArgTypes.Immutable.list.of(ArgTypes.string));
  const isChoosingAggregation = Object.values(ChoosingAggregationFunction).includes(newType as ChoosingAggregationFunctionE);
  if (isChoosingAggregation) {
    const fn = newType as ChoosingAggregationFunctionE;
    if (isCustomChoosingAggregation(filter)) {
      return { type: CHOOSING_AGGREGATION, function: fn, expression: undefined };
    }
    return { type: SINGLE_INPUT, function: fn, inputAttributeName: undefined };
  }

  const type = newType as fcr.FilterClusterRecordsTypeNameE;
  if (type === DatasetFilter.TYPE) {
    return { type, priorities: [allPrioritizedSources.toArray()], excluded: [] };
  }
  if (type === Predicate.TYPE) {
    return { type, expression: undefined };
  }
  return { type: FilterEmpties.TYPE, inputAttributeName: undefined };
}

type FilterElementOwnProps = {
  ruleName: string
  index: number
}

const mapStateToProps = (state: AppState, { ruleName, index }: FilterElementOwnProps) => {
  const { goldenRecords } = state;
  const filter = getRuleFilter(goldenRecords, { ruleName, filterIndex: index });
  const inputAttributesOptions = selectInputAttributeAsOptions(goldenRecords);
  const staticAnalysisResult = getFilterExpressionAnalysisResult(goldenRecords, { ruleName, filterIndex: index });
  const { sourceList } = goldenRecords;
  const allPrioritizedSources = selectAllPrioritizedSources(goldenRecords);
  const allRuleFilters = findRuleDeltaByName(goldenRecords, ruleName)?.rule.filters;
  return { filter, inputAttributesOptions, staticAnalysisResult, sourceList, allPrioritizedSources, allRuleFilters };
};

const mapDispatchToProps = (dispatch: AppDispatch, { ruleName, index }: FilterElementOwnProps) => ({
  onFilterRemove: () => dispatch({ type: 'GoldenRecords.removeRuleFilter', ruleName, index }),
  onFilterUpdate: (filter: FilterClusterRecords) => dispatch({ type: 'GoldenRecords.updateRuleFilter', ruleName, index, filter }),
  onPerformStaticAnalysis: ({ filterIndex, expression }: { filterIndex: number, expression: string }) =>
    dispatch(performStaticAnalysisForFilter({ ruleName, filterIndex, expression })),
  onBeginEditingDatasetFilter: () => dispatch({ type: 'GoldenRecords.beginEditingDatasetFilter', ruleName, filterIndex: index }),
});

type FilterElementProps
  = FilterElementOwnProps
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>

type FilterElementState = {
  hasNotBeenTypedIntoYet: boolean
}

const FilterElement = connect(
  mapStateToProps,
  mapDispatchToProps,
)(class FilterElement extends Component<FilterElementProps, FilterElementState> {
  constructor(props: FilterElementProps) {
    super(props);
    this.state = {
      hasNotBeenTypedIntoYet: true,
    };
    props.filter && hasCustomExpression(props.filter) && 'expression' in props.filter && _.isString(props.filter.expression) && this.performStaticAnalysisDebounced(props.filter.expression, true);
  }
  performStaticAnalysisDebounced = async (expression: string, duringConstruction?: boolean) => {
    const { onPerformStaticAnalysis, index } = this.props;
    onPerformStaticAnalysis({ filterIndex: index, expression });
    !duringConstruction && this.setState({ hasNotBeenTypedIntoYet: false });
  };
  handleChangeExpr = (expression: string) => {
    const { onFilterUpdate, filter } = this.props;
    if (filter && 'expression' in filter) {
      onFilterUpdate(set(filter, 'expression', expression));
      this.performStaticAnalysisDebounced(expression);
    }
  };
  toggleCustom = () => {
    const { onFilterUpdate, filter } = this.props;
    if (filter) {
      const newFilter: FilterClusterRecords = isCustomChoosingAggregation(filter)
        ? { type: sif.TYPE, function: filter.function, inputAttributeName: undefined }
        : { type: ca.TYPE, function: filter.type === sif.TYPE ? filter.function : undefined, expression: undefined };
      onFilterUpdate(newFilter);
    }
  };

  render() {
    const { sourceList, allPrioritizedSources, staticAnalysisResult, filter, index, onFilterRemove, inputAttributesOptions, onFilterUpdate, onBeginEditingDatasetFilter, allRuleFilters } = this.props;
    const { hasNotBeenTypedIntoYet } = this.state;
    const custom = isCustomChoosingAggregation(filter);
    const lintingErrors = staticAnalysisResult ? staticAnalysisResult.getIn(['operations', 0, 'lints']) : List();

    const inputAttributeRow = (
      <Toolbar
        left={isAnyChoosingAggregation(filter) ? null : <div>Input attribute:</div>}
        right={(
          <Select
            className={style.rightSelect}
            clearable={false}
            multi={false}
            value={inputAttributesOptions.find(option => filter && 'inputAttributeName' in filter && filter.inputAttributeName === option.value)}
            onChange={({ value }: Option<string>) => filter && 'inputAttributeName' in filter && onFilterUpdate(set(filter, 'inputAttributeName', value))}
            options={inputAttributesOptions}
          />
        )}
      />
    );


    const customRow = (
      <div className={style.customRow}>
        <div>Input: </div>
        <div className={style.customRowOptions}>
          <div className={style.customRowOption} onClick={this.toggleCustom}>
            <TamrIcon iconName={custom ? 'radio-button-unchecked' : 'radio-button-checked'} size={14} />
            <span>Attribute</span>
          </div>
          <div className={style.customRowOption} onClick={this.toggleCustom}>
            <TamrIcon iconName={custom ? 'radio-button-checked' : 'radio-button-unchecked'} size={14} />
            <span>Custom</span>
          </div>
        </div>
      </div>
    );

    const expressionRow = filter && (
      <div className={style.expression}>
        <GRInput
          {... { staticAnalysisResult, lintingErrors, hasNotBeenTypedIntoYet }}
          name={`expr${index}`}
          contentEnvelope={getFilterExpressionEnvelope(filter)}
          contents={('expression' in filter && filter.expression) || ''}
          onChange={this.handleChangeExpr}
        />
      </div>
    );

    const getElement = () => {
      switch (filter?.type) {
        case SINGLE_INPUT:
          return <Fragment>
            {customRow}
            {inputAttributeRow}
          </Fragment>;
        case CHOOSING_AGGREGATION:
          return <Fragment>
            {customRow}
            {expressionRow}
          </Fragment>;
        case PREDICATE:
          return <Fragment>
            {expressionRow}
          </Fragment>;
        case FILTER_EMPTIES:
          return <Fragment>
            {inputAttributeRow}
          </Fragment>;
        case DATASET_FILTER:
          const { priorities, excluded } = DatasetFilter.incorporateUnaccountedDatasets(filter, allPrioritizedSources);
          const numPrioritized = priorities.reduce((memo, prio) => memo + prio.length, 0);
          const numExcluded = excluded.length;
          const text = `${numPrioritized} datasets prioritized | ${numExcluded} datasets excluded`;
          return sourceList ? (
            <a className={style.datasetFilterSummary} onClick={onBeginEditingDatasetFilter}>
              {text}
            </a>
          ) : (
            <TooltipTrigger content="This is out of date. Profile your input dataset by clicking the link on the top left of the page." placement="top">
              <span className={classNames(style.datasetFilterSummary, style.disabled)}>
                <WarningIcon size={16} />&nbsp;
                <span>
                  {text}
                </span>
              </span>
            </TooltipTrigger>
          );
        default:
          break;
      }
    };
    const ruleHasOtherDatasetFilter = filter?.type !== DATASET_FILTER
      && !!allRuleFilters?.find(f => f.type === DATASET_FILTER);
    const filterOptions = ruleHasOtherDatasetFilter
      ? List(FILTER_OPTIONS).update(options => options.delete(options.findIndex(o => o.value === DatasetFilter.TYPE))).toArray()
      : FILTER_OPTIONS;
    return (
      <div className={style.filterList}>
        <ColumnHandle className={style.filterHandle} />
        <div className={style.filter}>
          <Toolbar
            left={<div>Condition {index + 1}: </div>}
            right={(
              <Select
                className={style.rightSelect}
                clearable={false}
                multi={false}
                value={filterOptions.find(option => option.value === filter?.type || (filter && 'function' in filter && option.value === filter.function))}
                onChange={({ value }: { value: (typeof FILTER_OPTIONS[number]['value']) }) => filter && onFilterUpdate(changeFilterType({ filter, allPrioritizedSources, newType: value }))}
                // @ts-expect-error
                options={filterOptions}
              />
            )}
          />
          {getElement()}
        </div>
        <div className={style.removeFilter}>
          <TooltipTrigger content="Remove condition" placement="top">
            <TamrIcon
              size={10}
              iconName="tamr-icon-remove"
              onClick={onFilterRemove}
            />
          </TooltipTrigger>
        </div>
      </div>
    );
  }
});

export default FilterElement;
