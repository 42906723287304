import React from 'react';
import { connect } from 'react-redux';

import PoliciesList from '../accessControl/PoliciesList';
import Chrome from '../chrome/Chrome';
import { isAdmin } from '../utils/Authorization';
import { getAuthorizedUser } from '../utils/Selectors';
import Unauthorized from './Unauthorized';

const Policies = ({ isAuthorized }) => (!isAuthorized ? <Unauthorized /> : (
  <Chrome>
    <div style={{ display: 'flex', height: '100%' }}>
      <PoliciesList />
    </div>
  </Chrome>
));

export default connect(state => {
  return {
    isAuthorized: isAdmin(getAuthorizedUser(state)),
  };
})(Policies);
