import Immutable, { List, Set } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import { attributeId } from '../../models/AttributeId';
import ProjectInfo from '../../models/ProjectInfo';
import { SET_SOURCE_FILTER_DATASETS } from '../../schema-mapping/SchemaMappingActionTypes';
import { history } from '../../utils/History';
import DashboardValueCard from '../DashboardValueCard';
import SegmentedBarGraphCard, { Segment } from '../SegmentedBarGraphCard';
import { fetchSourceAttributes, fetchUnifiedAttributes } from './SchemaMappingApi';
import { selectSourceMappings } from './SchemaMappingRecsStore';


const CompletedSourcesCard = _.compose(
  connect((state, { projectInfo }) => {
    const unifiedDatasetName = projectInfo && projectInfo.unifiedDatasetName;
    const { schemaMappingRecs, allSourceDatasets: { datasets } } = state;
    const { sourceAttributes } = schemaMappingRecs;
    const sourceMappings = selectSourceMappings(schemaMappingRecs);
    const categories = sourceAttributes.groupBy(({ datasetName }) => datasetName).map(attrs => {
      const count = attrs.filter(attr => attr.doNotMap.has(unifiedDatasetName) || (sourceMappings.get(attributeId(attr)) || Set()).size).size;
      switch (count) {
        case attrs.size: return 'complete';
        case 0: return 'fresh';
        default: return 'mapped';
      }
    }).groupBy(k => k).map(m => m.keySeq().toSet());
    return {
      numSourceDatasets: datasets.size,
      freshSources: categories.get('fresh') || Set(),
      mappedSources: categories.get('mapped') || Set(),
      completeSources: categories.get('complete') || Set(),
    };
  }, {
    onSetSourceFilterDatasets: datasetNames => ({ type: SET_SOURCE_FILTER_DATASETS, datasetNames }),
    onFetchSourceAttributes: fetchSourceAttributes,
    onFetchUnifiedAttributes: fetchUnifiedAttributes,
  }),
)(class CompletedSourcesCard extends React.Component {
  static propTypes = {
    completeSources: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
    freshSources: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
    mappedSources: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
    numSourceDatasets: PropTypes.number.isRequired,
    onFetchSourceAttributes: PropTypes.func.isRequired,
    onFetchUnifiedAttributes: PropTypes.func.isRequired,
    onSetSourceFilterDatasets: PropTypes.func.isRequired,
    projectInfo: PropTypes.instanceOf(ProjectInfo).isRequired,
    short: PropTypes.bool,
    smRecipeId: PropTypes.number.isRequired,
  };

  static defaultProps = { short: false };

  componentDidMount() {
    this.props.onFetchSourceAttributes();
    this.props.onFetchUnifiedAttributes();
  }

  navigateToSchemaMapping = () => {
    const { smRecipeId } = this.props;
    history.push(`/schema-mapping/recipe/${smRecipeId}`);
  };

  onSegmentClick = (datasetList) => {
    const datasetSet = datasetList.toSet();
    this.props.onSetSourceFilterDatasets(datasetSet);
    this.navigateToSchemaMapping();
  };

  checkNoSources = (numSourcesSubset) => {
    const { numSourceDatasets } = this.props;
    return numSourceDatasets === 0 ? 0 : numSourcesSubset;
  };

  renderValueCard = () => {
    const { completeSources, freshSources, mappedSources, projectInfo, short, numSourceDatasets } = this.props;
    const numCompleteSources = completeSources.size;
    const numMappedSources = mappedSources.size;
    const numFreshSources = freshSources.size;
    const numCompleteSourcesWUnifiedDataset = projectInfo.unifiedDatasetDoc ? numCompleteSources : 0;
    const numPartiallyMappedSourcesWUnifiedDataset = projectInfo.unifiedDatasetDoc ? numMappedSources : 0;
    const numFreshSourcesWUnifiedDataset = projectInfo.unifiedDatasetDoc ? numFreshSources : numSourceDatasets;
    const valuesList = Immutable.List.of(
      {
        value: this.checkNoSources(numCompleteSourcesWUnifiedDataset),
        nounSingular: 'Fully Mapped',
        nounPlural: 'Fully Mapped',
      },
      {
        value: this.checkNoSources(numPartiallyMappedSourcesWUnifiedDataset),
        nounSingular: 'Partially Mapped',
        nounPlural: 'Partially Mapped',
      },
      {
        value: this.checkNoSources(numFreshSourcesWUnifiedDataset),
        nounSingular: 'None Mapped',
        nounPlural: 'None Mapped',
      },
    );
    return (
      <DashboardValueCard
        onClick={this.navigateToSchemaMapping}
        short={short}
        title="MAPPING"
        valuesList={valuesList}
      />
    );
  };

  getSegments = () => {
    const { completeSources, freshSources, mappedSources } = this.props;
    const numCompleteSources = completeSources.size;
    const numMappedSources = mappedSources.size;
    const numFreshSources = freshSources.size;
    const fullyMappedPercent = numCompleteSources;
    const partiallyMappedPercent = numMappedSources;
    const noneMappedPercent = numFreshSources;
    return new List([
      new Segment({ value: fullyMappedPercent, label: 'Fully Mapped', onClick: _.partial(this.onSegmentClick, completeSources) }),
      new Segment({ value: partiallyMappedPercent, label: 'Partially Mapped', onClick: _.partial(this.onSegmentClick, mappedSources) }),
      new Segment({ value: noneMappedPercent, label: 'None Mapped', onClick: _.partial(this.onSegmentClick, freshSources) }),
    ]);
  };

  renderMappingViz = () => {
    const { completeSources, numSourceDatasets } = this.props;
    const numCompleteSources = completeSources.size;
    return (
      <SegmentedBarGraphCard
        className="dashboard-suggested-categorizations-card"
        mappingGraph
        onClick={this.navigateToSchemaMapping}
        segments={this.getSegments()}
        totalMappedValue={numCompleteSources}
        totalNounPlural="Datasets Fully Mapped"
        totalNounSingular="Dataset Fully Mapped"
        totalValue={numSourceDatasets}
      />
    );
  };

  render() {
    const { numSourceDatasets, projectInfo } = this.props;
    return !projectInfo.unifiedDatasetDoc || !numSourceDatasets > 0 ? this.renderValueCard() : this.renderMappingViz();
  }
});

export default CompletedSourcesCard;
