import { List, Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MenuButton from '../components/MenuButton';
import TooltipTrigger from '../components/TooltipTrigger';
import { AppThunkDispatch } from '../stores/AppAction';
import { AppState } from '../stores/MainStore';
import { pluralize } from '../utils/Strings';
import style from './ClusterRecordsHeader.module.scss';
import { PaneE } from './Pane';
import { verifyRecords } from './SuppliersAsync';
import { getSelectedRecords } from './SuppliersStore';
import VerificationIcon, { VERIFICATION_ICON_TYPE_LOCKED, VERIFICATION_ICON_TYPE_SUGGESTABLE_HAS_SUGGESTION, VERIFICATION_ICON_TYPE_VERIFIED_HERE, VerificationIconTypeE } from './VerificationIcon';
import VerificationType from './VerificationType';


const OPTION_ICON_SIZE = 16;

const OptionContentWithIcon: React.FC<{
  disabled: boolean
  verificationIconType?: VerificationIconTypeE,
  text?: string
  tooltip?: string
}> = ({ disabled, verificationIconType, text, tooltip }) => {
  const content = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {verificationIconType ? <VerificationIcon {...{ verificationIconType }} size={OPTION_ICON_SIZE} /> : <div style={{ width: `${OPTION_ICON_SIZE}px` }} />}
      {text && <React.Fragment>&nbsp;&nbsp;<span>{text}</span></React.Fragment>}
    </div>
  );
  if (disabled || !tooltip) return content;
  return (
    <TooltipTrigger content={tooltip}>
      {content}
    </TooltipTrigger>
  );
};

type VerifyButtonOwnProps = {
  iconify: boolean,
  pane: PaneE,
}

const VerifyButton = connect((state: AppState, { pane }: VerifyButtonOwnProps) => {
  const selectedRecords = getSelectedRecords(state, pane);
  return { anyRecordSelected: !selectedRecords.isEmpty(), numRecords: selectedRecords.size };
}, (dispatch: AppThunkDispatch, { pane }: VerifyButtonOwnProps) => bindActionCreators({
  onVerifySuggest: () => verifyRecords(pane, VerificationType.SUGGEST),
  onVerifyMovable: () => verifyRecords(pane, VerificationType.MOVABLE),
  onVerifyLock: () => verifyRecords(pane, VerificationType.LOCK),
  onRemoveVerification: () => verifyRecords(pane, VerificationType.UNVERIFIED),
}, dispatch))(({ iconify, anyRecordSelected, onVerifySuggest, onVerifyMovable, onVerifyLock, onRemoveVerification, numRecords }) => {
  const recordsTerm = (singular: string, plural: string) => pluralize(numRecords, singular, plural);
  const preconditions = Map<string, boolean>().set('You must select one or more records', anyRecordSelected);
  const defaultOptionLabel = 'Verify and enable suggestions';
  const defaultOptionTooltip = `Enable Tamr to make suggestions for moving ${recordsTerm('this record', 'these records')}.`;
  const disabled = !anyRecordSelected;
  return (
    <MenuButton
      buttonOnClick={onVerifySuggest}
      buttonPreconditions={preconditions}
      buttonText={iconify ? (
        <OptionContentWithIcon {...{ disabled }} verificationIconType={VERIFICATION_ICON_TYPE_SUGGESTABLE_HAS_SUGGESTION} tooltip={defaultOptionTooltip} />
      ) : (
        <OptionContentWithIcon {...{ disabled }} verificationIconType={VERIFICATION_ICON_TYPE_SUGGESTABLE_HAS_SUGGESTION} text="Verify and enable suggestions" tooltip={defaultOptionTooltip} />
      )}
      buttonType="Secondary"
      buttonTooltipLabel={iconify ? defaultOptionLabel : undefined}
      dropdownPreconditions={preconditions}
      popoverMenuClassName={style.verifyPopoverMenu}
      options={List([
        {
          text: <OptionContentWithIcon {...{ disabled }} verificationIconType={VERIFICATION_ICON_TYPE_VERIFIED_HERE} text="Verify and auto-accept suggestions" tooltip={`Enable Tamr to automatically move ${recordsTerm('this record', 'these records')} based on its own suggestions.`} />,
          onClick: onVerifyMovable,
        },
        {
          text: <OptionContentWithIcon {...{ disabled }} verificationIconType={VERIFICATION_ICON_TYPE_LOCKED} text="Verify and disable suggestions" tooltip={`Disable Tamr from making suggestions for moving ${recordsTerm('this record', 'these records')}.`} />,
          onClick: onVerifyLock,
        },
        {
          text: <OptionContentWithIcon {...{ disabled }} text="Remove verification" />,
          onClick: onRemoveVerification,
        },
      ])}
    />
  );
});

export default VerifyButton;
