import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Chrome from '../chrome/Chrome';
import AccountDialog from '../users/AccountDialog';
import GroupConfigDialog from '../users/GroupConfigDialog';
import GroupsLoader from '../users/GroupsLoader';
import UsersContent from '../users/UsersContent';
import UsersLoader from '../users/UsersLoader';
import { isAdmin } from '../utils/Authorization';
import { getAuthorizedUser } from '../utils/Selectors';
import Unauthorized from './Unauthorized';

const Users = connect(state => {
  return { isUserAnAdmin: isAdmin(getAuthorizedUser(state)) };
})(class Users extends React.Component {
  static propTypes = {
    isUserAnAdmin: PropTypes.bool.isRequired,
  };

  render() {
    const { isUserAnAdmin } = this.props;

    if (!isUserAnAdmin) {
      return <Unauthorized />;
    }

    return (
      <Chrome>
        <AccountDialog />
        <UsersContent />
        <GroupsLoader />
        <UsersLoader />
        <GroupConfigDialog />
      </Chrome>
    );
  }
});

export default Users;
