import classNames from 'classnames';
import { List } from 'immutable';
import React from 'react';
import _, { Dictionary } from 'underscore';
import urlRegex from 'url-regex';

import CopyIcon from '../components/CopyIcon';
import PopoverTrigger from '../components/PopoverTrigger';
import TamrIcon from '../components/TamrIcon';
import { PlacementOption } from '../components/TooltipTrigger';
import { ArrayTypedValue, BoolTypedValue, DoubleTypedValue, FloatTypedValue, IntTypedValue, LongTypedValue, RecordTypedValue, StringTypedValue, TypedValue, typedValueConstructor } from '../transforms/models/TypedValue';
import { AnyType, ArrayType, BoolType, DoubleType, FloatType, FunctionType, IntType, LongType, NeverType, NumberType, RecordType, StringType, Type } from '../transforms/models/Types';
import { pluralize } from '../utils/Strings';
import { assertNever } from '../utils/typescript';
import style from './TypedData.module.scss';

/**
 * TypedData
 * This library's main export is the component <TypedData />, which takes in
 *   a TypedValue (just a value linked with a Type), plus rendering options,
 *   and renders the value according to its type and those options.
 * Immediately below are the options that must be specified, culminating in TypedDataRenderingOptions.
 */

export const StringRenderingOption = {
  // do not wrap with quotes, nor show spaces in any special way
  ONLY_SHOW_VALUE: 'ONLY_SHOW_VALUE',
  // wrap in quotes, but do not show spaces in any special way
  SHOW_QUOTES: 'SHOW_QUOTES',
  // wrap in quotes, and replace spaces with middots, as well as newlines / tabs with '\n' and '\t'
  SHOW_QUOTES_AND_INVISIBLE_CHARACTERS: 'SHOW_QUOTES_AND_INVISIBLE_CHARACTERS',
} as const;
export type StringRenderingOptionE = typeof StringRenderingOption[keyof typeof StringRenderingOption];

export const WhitespaceRenderingOption = {
  // add visible whitespace when encountering \n or \t characters, eg. <br /> for \n
  ADD_SPACE: 'ADD_SPACE',
  // do not add visible whitespace when encountering these characters
  DO_NOT_ADD_SPACE: 'DO_NOT_ADD_SPACE',
} as const;
export type WhitespaceRenderingOptionE = typeof WhitespaceRenderingOption[keyof typeof WhitespaceRenderingOption];

export const ArrayRenderingOption = {
  // do not show wrapping brackets, ever, which also means do not show an array popover ever
  HIDE_BRACKETS: 'HIDE_BRACKETS',
  // show wrapping brackets and array count in all cases, but do not spawn an array popover on clicking the count
  SHOW_BRACKETS: 'SHOW_BRACKETS',
  // show brackets / count in all cases, and spawn an array popover on clicking the count
  SHOW_BRACKETS_AND_POPOVER: 'SHOW_BRACKETS_AND_POPOVER',
  // like SHOW_BRACKETS, but only applies when there are 2 or more elements in the array
  SHOW_BRACKETS_WHEN_MULTIPLE_ELEMENTS: 'SHOW_BRACKETS_WHEN_MULTIPLE_ELEMENTS',
  // like SHOW_BRACKETS_AND_POPOVER, but only applies when there are 2 or more elements in the array
  SHOW_BRACKETS_AND_POPOVER_WHEN_MULTIPLE_ELEMENTS: 'SHOW_BRACKETS_AND_POPOVER_WHEN_MULTIPLE_ELEMENTS',
} as const;
export type ArrayRenderingOptionE = typeof ArrayRenderingOption[keyof typeof ArrayRenderingOption];

// when specifying that text should be highlighting, these parameters need to be supplied
export type HighlightingParameters = {
  // the string value directly preceding the highlighted section
  prefix: string
  // the string value directly succeeding the highlighted section
  postfix: string
  // the className that should be applied to the highlighted section
  className: string
}
// the actual option to TypedData is either these parameters, or "false" signifying no highlighting should be done
export type HighlightingOptionE = HighlightingParameters | false;

// all of our ES-backed highlighting currently has these parameters
export const DEFAULT_HIGHLIGHT_PREFIX = '<span class="search-highlight">';
export const DEFAULT_HIGHLIGHT_POSTFIX = '</span>';
export const DEFAULT_HIGHLIGHT_CLASSNAME = 'search-highlight';
export const DEFAULT_HIGHLIGHTING_PARAMETERS: HighlightingParameters = {
  prefix: DEFAULT_HIGHLIGHT_PREFIX,
  postfix: DEFAULT_HIGHLIGHT_POSTFIX,
  className: DEFAULT_HIGHLIGHT_CLASSNAME,
};

export type TypedDataRenderingOptions = {
  strings: StringRenderingOptionE
  whitespace: WhitespaceRenderingOptionE
  arrays: ArrayRenderingOptionE
  highlighting: HighlightingOptionE
}

/* End rendering option definitions */

type MaybeHighlightedSegment = {
  str: string
  highlighted: boolean
}

// TODO this does not have intentionally defined behavior when encountering nested highlighting sections
function splitIntoHighlightSections(str: string, highlighting: HighlightingParameters): List<MaybeHighlightedSegment> {
  const noHighlight: List<MaybeHighlightedSegment> = List.of({ str, highlighted: false });
  const firstPrefixIndex = str.indexOf(highlighting.prefix);
  if (firstPrefixIndex === -1) {
    return noHighlight;
  }
  // find matching postfix
  const afterFirstPrefix = str.substring(firstPrefixIndex + highlighting.prefix.length);
  const postfixIndex = afterFirstPrefix.indexOf(highlighting.postfix);
  if (postfixIndex === -1) {
    return noHighlight;
  }
  const beforeHighlightedSegment = str.substring(0, firstPrefixIndex);
  const highlightedSegment = afterFirstPrefix.substring(0, postfixIndex);
  const afterHighlightedSegment = afterFirstPrefix.substring(postfixIndex + highlighting.postfix.length);
  return List([
    { str: beforeHighlightedSegment, highlighted: false },
    { str: highlightedSegment, highlighted: true },
  ]).concat(splitIntoHighlightSections(afterHighlightedSegment, highlighting));
}

function stripHighlightingInfo(value: string, highlighting: HighlightingOptionE) {
  return highlighting
    ? splitIntoHighlightSections(value, highlighting).map(segment => segment.str).join('')
    : value;
}

const isWhitespaceChar = (char: string) => char === ' ' || char === '\n' || char === '\t';
// eg. breaks " hi  there" into [" ", "hi", "  ", "there"]
// TODO does not recognize other non-standard whitespace characters
export const tokenizeByWhitespace = (str: string): List<string> => {
  const tokens: string[] = [];
  if (str.length === 0) return List(tokens);

  // initialize re: first character
  let currentBuffer = str.charAt(0);
  let onWhitespaceToken = isWhitespaceChar(str.charAt(0));

  // start on second character
  for (let i = 1; i < str.length; i++) {
    const char = str.charAt(i);
    const isWhitespace = isWhitespaceChar(char);
    if (isWhitespace !== onWhitespaceToken) {
      tokens.push(currentBuffer);
      currentBuffer = '';
    }
    currentBuffer += char;
    onWhitespaceToken = isWhitespace;
  }
  if (currentBuffer.length > 0) {
    tokens.push(currentBuffer);
  }
  return List(tokens);
};

const HiddenSpace: React.FC = () => <span className={style.hiddenSpace}>{' '}</span>;
const MiddotSpace: React.FC = () => (
  <span className={style.spaceChar}>
    {/* hidden space required for cells to wrap on word boundaries, and for copy-paste / ctrl-f to find a space */}
    <HiddenSpace />
  </span>
);
const MultivalueSeparator = () => (
  <span className={style.multivalueSeparator}>
    ,
    {/* hidden space required for copy-paste / ctrl-f to find a space after the comma */}
    <HiddenSpace />
  </span>
);
const MultivalueBracket: React.FC<{
  closing?: boolean
}> = ({ closing }) => <span className={style.multivalueBracket}>{ closing ? ']' : '['}</span>;
const MultivalueColon: React.FC<{}> = () => (
  <span className={style.multivalueColon}>
    :
    {/* hidden space required for copy-paste / ctrl-f to find a space after the colon */}
    <HiddenSpace />
  </span>
);
const MultivalueCurlyBracket: React.FC<{
  closing?: boolean
}> = ({ closing }) => <span className={style.multivalueBracket}>{ closing ? '}' : '{'}</span>;
const NullValue: React.FC<{ children: '-Infinity' | 'Infinity' | 'NaN' | null }> = ({ children }) => (
  <span className={style.nullCell}>
    {children === null ? 'null' : children}
  </span>
);

function wrapInQuotes(value: List<JSX.Element>): List<JSX.Element> {
  return List.of(<span key="quote-wrapper" className={style.wrappedInQuotes}>{value}</span>);
}

function showWhitespace(
  value: string,
  showInvisibleCharacters: boolean,
  addSpaceOnWhitespaceCharacters: boolean,
  highlighting: HighlightingOptionE,
): List<JSX.Element> {
  const segments: List<MaybeHighlightedSegment> = highlighting
    ? splitIntoHighlightSections(value, highlighting)
    : List.of({ str: value, highlighted: false });

  return segments.flatMap((segment, k) => {
    const { str, highlighted } = segment;
    const tokenized = tokenizeByWhitespace(str.replace('\\n', '\n').replace('\\t', '\t'));
    const convertedSpace = showInvisibleCharacters ? <MiddotSpace /> : ' ';
    const convertedNewline = <span>{showInvisibleCharacters && '\\n'}{addSpaceOnWhitespaceCharacters && <br />}</span>;
    const convertedTab = <span>{showInvisibleCharacters && '\\t'}{addSpaceOnWhitespaceCharacters && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}</span>;
    const renderedChildren: List<JSX.Element> = tokenized.map((token, i) => {
      const tokenKey = `token-${k}-${i}`;
      if (isWhitespaceChar(token.charAt(0)) && (showInvisibleCharacters || addSpaceOnWhitespaceCharacters)) {
        return (
          <span key={tokenKey} className={style.whitespaceToken}>
            {List(token).map((sp, j) => (
              <span key={j} className={style.whitespaceChar}>
                {sp === ' ' ? convertedSpace
                  : sp === '\n' ? convertedNewline
                    : sp === '\t' ? convertedTab
                      : ''}
              </span>
            ))}
          </span>
        );
      }
      return <span key={tokenKey}>{token}</span>;
    });
    return highlighting && highlighted
      ? List.of(<span key={`highlighted-string-${k}`} className={highlighting.className}>{renderedChildren}</span>)
      : renderedChildren;
  });
}

function renderStringComponents(
  value: string,
  strings: StringRenderingOptionE,
  whitespace: WhitespaceRenderingOptionE,
  highlighting: HighlightingOptionE,
): List<JSX.Element> {
  const addSpaceOnWhitespaceCharacters = whitespace === 'ADD_SPACE';
  if (strings === 'ONLY_SHOW_VALUE') {
    return showWhitespace(value, false, addSpaceOnWhitespaceCharacters, highlighting);
  }
  if (strings === 'SHOW_QUOTES') {
    return wrapInQuotes(showWhitespace(value, false, addSpaceOnWhitespaceCharacters, highlighting));
  }
  if (strings === 'SHOW_QUOTES_AND_INVISIBLE_CHARACTERS') {
    return wrapInQuotes(showWhitespace(value, true, addSpaceOnWhitespaceCharacters, highlighting));
  }
  assertNever(strings);
}

const urlre = urlRegex({ exact: true });

export const StringValue: React.FC<{
  typedValue: StringTypedValue
  whitespace: WhitespaceRenderingOptionE
  strings: StringRenderingOptionE
  highlighting: HighlightingOptionE
}> = ({ typedValue, whitespace, strings, highlighting }) => {
  const { value } = typedValue;
  // if value is null or undefined, render as null
  // this also catches the case of mis-typing leading to a non-string getting to this point
  if (!_.isString(value)) {
    return <NullValue>{value}</NullValue>;
  }
  // if value is a valid url, linkify and add an icon
  if (urlre.test(value)) {
    return (
      <a href={value} rel="noopener noreferrer" target="_blank">
        <TamrIcon iconName="open-in-new" size={14} /> {value}
      </a>
    );
  }
  // otherwise, render as typed data respecting the options
  return (
    <span title={stripHighlightingInfo(value, highlighting)} key={`string-value-${value}`}>
      {renderStringComponents(value, strings, whitespace, highlighting)}
    </span>
  );
};

type ArrayPopoverRowProps = {
  children: React.ReactNode
  copyValue: string
  copyIconAlwaysVisible?: boolean
  copyTooltipPlacement?: PlacementOption
};

const ArrayPopoverRow: React.FC<ArrayPopoverRowProps> = ({ children, copyValue, copyIconAlwaysVisible, copyTooltipPlacement = 'top' }) => {
  return (
    <div className={style.arrayPopoverRow}>
      <span className={style.arrayPopoverRowContent}>{children}</span>
      <div className={classNames(style.arrayPopoverCopyIconContainer, { [style.alwaysVisible]: !!copyIconAlwaysVisible })}>
        <CopyIcon
          className={classNames(style.arrayPopoverRowCopyIcon)}
          size={14}
          getValue={() => copyValue}
          initialTooltipContent={<div className={style.arrayPopoverRowCopyIconInitialContent}>Copy as {copyValue}</div>}
          tooltipPlacement={copyTooltipPlacement}
        />
      </div>
    </div>
  );
};

// returns a corresponding ArrayRenderingOptionE that does not show popovers
function withoutPopovers(arrays: ArrayRenderingOptionE): ArrayRenderingOptionE {
  switch (arrays) {
    case 'SHOW_BRACKETS_AND_POPOVER':
      return 'SHOW_BRACKETS';
    case 'SHOW_BRACKETS_AND_POPOVER_WHEN_MULTIPLE_ELEMENTS':
      return 'SHOW_BRACKETS_WHEN_MULTIPLE_ELEMENTS';
    case 'HIDE_BRACKETS':
    case 'SHOW_BRACKETS':
    case 'SHOW_BRACKETS_WHEN_MULTIPLE_ELEMENTS':
      return arrays;
    default:
      assertNever(arrays);
  }
}

function shouldShowBrackets(arrays: ArrayRenderingOptionE, numValues: number): boolean {
  switch (arrays) {
    case 'HIDE_BRACKETS':
      return false;
    case 'SHOW_BRACKETS':
    case 'SHOW_BRACKETS_AND_POPOVER':
      return true;
    case 'SHOW_BRACKETS_WHEN_MULTIPLE_ELEMENTS':
    case 'SHOW_BRACKETS_AND_POPOVER_WHEN_MULTIPLE_ELEMENTS':
      return numValues > 1;
    default:
      assertNever(arrays);
  }
}

function typedValueToString(value: TypedValue, arrays: ArrayRenderingOptionE, highlighting: HighlightingOptionE): string {
  if (value.value === null || value.value === undefined) {
    return 'null';
  }
  switch (value.typeDiscriminator) {
    case 'array':
      const commaDelimitedValues = value.value?.map(v => typedValueToString(v, arrays, highlighting)).join(', ') || '';
      if (shouldShowBrackets(arrays, value.value?.length || 0)) {
        return `[${commaDelimitedValues}]`;
      }
      return commaDelimitedValues;
    case 'record':
      const fieldValues = value.value;
      const fieldStrings = value.type.fields
        .map(field => {
          const { name } = field;
          const fieldValue = (fieldValues && fieldValues.hasOwnProperty(name)) ? fieldValues[name] : typedValueConstructor(null, field.type);
          return `${name}: ${typedValueToString(fieldValue, arrays, highlighting)}`;
        }).join(', ');
      return value.type.fields.isEmpty()
        ? '{}'
        : `{ ${fieldStrings} }`;
    case 'string':
      return value.value ? stripHighlightingInfo(value.value, highlighting) : 'null';
    case 'bool':
    case 'int':
    case 'long':
    case 'float':
    case 'double':
      return String(value.value);
    default:
      assertNever(value);
  }
}

type TypeName = {
  singular: string
  plural: string
}

function getTypeName(type: Type): TypeName {
  if (type instanceof NumberType) return { singular: 'number', plural: 'numbers' };
  if (type instanceof IntType) return { singular: 'int', plural: 'ints' };
  if (type instanceof LongType) return { singular: 'long', plural: 'longs' };
  if (type instanceof FloatType) return { singular: 'float', plural: 'floats' };
  if (type instanceof DoubleType) return { singular: 'double', plural: 'doubles' };
  if (type instanceof BoolType) return { singular: 'boolean', plural: 'booleans' };
  if (type instanceof StringType) return { singular: 'string', plural: 'strings' };
  if (type instanceof ArrayType) return { singular: 'array', plural: 'arrays' };
  if (type instanceof RecordType) return { singular: 'struct', plural: 'structs' };
  // strange cases, but included for completion
  if (type instanceof AnyType) return { singular: 'any', plural: 'any' };
  if (type instanceof NeverType) return { singular: 'never', plural: 'never' };
  if (type instanceof FunctionType) return { singular: 'function', plural: 'functions' };
  assertNever(type);
}

function getStringDescription(value: ArrayTypedValue): string {
  const { elementType } = value.type;
  const { singular, plural } = getTypeName(elementType);
  const numValues = value.value?.length || 0;
  const elementName = pluralize(numValues, singular, plural);
  return `array with ${numValues} ${elementName}`;
}

const ArrayPopover: React.FC<{
  children: JSX.Element
  elementValues: ArrayTypedValue
  placement?: PlacementOption
  copyTooltipPlacement?: PlacementOption
} & Omit<TypedDataRenderingOptions, 'whitespace'>
> = ({ children, elementValues, strings, arrays, highlighting, placement = 'top', copyTooltipPlacement }) => {
  const nestedRenderingOptions: TypedDataRenderingOptions = {
    // render strings in whatever manner the base rendering was configured
    strings,
    highlighting,
    // do not add newlines or tabs in this view
    whitespace: 'DO_NOT_ADD_SPACE',
    // turn off popovers for nested array rendering
    arrays: withoutPopovers(arrays),
  };
  const rowProps: List<ArrayPopoverRowProps> = elementValues.value
    ? List(elementValues.value).map(element => {
      return {
        children: <TypedData typedValue={element} {...nestedRenderingOptions} />,
        copyValue: typedValueToString(element, arrays, highlighting),
        copyTooltipPlacement,
      };
    })
    : List<ArrayPopoverRowProps>();
  return (
    <PopoverTrigger
      className={style.arrayPopoverContainer}
      content={(
        <div className={style.arrayPopover}>
          <ArrayPopoverRow
            copyValue={typedValueToString(elementValues, arrays, highlighting)}
            copyIconAlwaysVisible
            copyTooltipPlacement={copyTooltipPlacement}
          >
            <span className={style.arrayPopoverTitle}>{getStringDescription(elementValues)}</span>
          </ArrayPopoverRow>
          <div className={style.arrayPopoverValues}>
            {rowProps.map((props, i) => <ArrayPopoverRow key={`row-${i}`} {...props} />)}
          </div>
        </div>
      )}
      placement={placement}
    >
      {children}
    </PopoverTrigger>
  );
};

function considerAddingBrackets(
  elements: List<JSX.Element>,
  elementValues: ArrayTypedValue,
  arrays: ArrayRenderingOptionE,
  whitespace: WhitespaceRenderingOptionE,
  strings: StringRenderingOptionE,
  highlighting: HighlightingOptionE,
  arrayPopoverPlacement?: PlacementOption,
  arrayPopoverCopyTooltipPlacement?: PlacementOption,
): List<JSX.Element> {
  const numValues = elementValues.value?.length || 0;
  if (!shouldShowBrackets(arrays, numValues)) {
    return elements;
  }

  const beforeElements = numValues >= 1
    ? <span key="array-value-before-elements" className={style.multivalueBracket}>
      <MultivalueBracket />
      <span className={style.multivalueBracket}>{numValues}</span>
      <MultivalueColon />
    </span>
    : <MultivalueBracket key={'opening-brace'} />;

  const showPopover = arrays === 'SHOW_BRACKETS_AND_POPOVER' || arrays === 'SHOW_BRACKETS_AND_POPOVER_WHEN_MULTIPLE_ELEMENTS';
  const beforeElementsWithPopover = showPopover
    ? <ArrayPopover key="countPopover" {...{ elementValues, strings, whitespace, arrays, highlighting, placement: arrayPopoverPlacement, copyTooltipPlacement: arrayPopoverCopyTooltipPlacement }}>{beforeElements}</ArrayPopover>
    : beforeElements;

  return elements.insert(0, beforeElementsWithPopover).push(<MultivalueBracket closing key={'closing-brace'} />);
}

// show arrays with formatting characters (brackets, commas)
export const ArrayValue: React.FC<{
  typedValue: ArrayTypedValue
  arrayPopoverPlacement?: PlacementOption
  arrayPopoverCopyTooltipPlacement?: PlacementOption
} & TypedDataRenderingOptions
> = ({ typedValue, strings, whitespace, arrays, highlighting, arrayPopoverPlacement, arrayPopoverCopyTooltipPlacement }) => {
  const { value } = typedValue;
  if (value === null) {
    return <NullValue>{value}</NullValue>;
  }
  const size = value.length;
  const elements = List(value).flatMap((nestedTypedValue, i) => {
    const keyPrefix = `element-${i}`;
    const toRender = [(
      <TypedData
        key={`${keyPrefix}`}
        typedValue={nestedTypedValue}
        {...{ strings, whitespace, arrays, highlighting }}
      />
    )];
    if (i < size - 1) {
      toRender.push(<MultivalueSeparator key={`${keyPrefix}-separator`} />);
    }
    return toRender;
  });

  return (
    <span key="array-container" title={typedValueToString(typedValue, arrays, highlighting)}>
      {considerAddingBrackets(elements, typedValue, arrays, whitespace, strings, highlighting, arrayPopoverPlacement, arrayPopoverCopyTooltipPlacement)}
    </span>
  );
};

export const RecordValue: React.FC<{
  typedValue: RecordTypedValue
} & TypedDataRenderingOptions
> = ({ typedValue, strings, whitespace, arrays, highlighting }) => {
  const { value } = typedValue;
  if (value === null) {
    return <NullValue>{value}</NullValue>;
  }
  let numValues = 0;
  const records: Dictionary<React.ReactNode> = _.mapObject(value, (fieldValue) => {
    const keyPrefix = `element-${numValues++}`;
    return <TypedData key={`${keyPrefix}`} typedValue={fieldValue} {...{ strings, whitespace, arrays, highlighting }} />;
  });
  const result: React.ReactNode[] = [<MultivalueCurlyBracket key={'opening-brace'} />];
  let numRemainingValues = numValues;
  _.forEach(records, (v, k) => {
    result.push(k + ' : ');
    result.push(v);
    if (numRemainingValues > 1) {
      result.push(<MultivalueSeparator key={`${k}-separator`} />);
      numRemainingValues--;
    }
  });
  result.push(<MultivalueCurlyBracket closing key={'closing-brace'} />);
  return <span title={typedValueToString(typedValue, arrays, highlighting)}>{result}</span>;
};

export const NumberValue: React.FC<{
  typedValue: IntTypedValue | LongTypedValue | FloatTypedValue | DoubleTypedValue
}> = ({ typedValue }) => {
  const { value, type } = typedValue;
  // render NaN and the Infinities like we render nulls
  if (!_.isNumber(value)) {
    return <NullValue>{value}</NullValue>;
  }
  if (type instanceof FloatType || type instanceof DoubleType) {
    const doubleValue = value % 1 === 0 ? value + '.0' : String(value);
    return <span title={doubleValue}>{doubleValue}</span>;
  }
  const stringValue = String(value);
  return <span title={stringValue}>{stringValue}</span>;
};

export const BoolValue: React.FC<{
  typedValue: BoolTypedValue
}> = ({ typedValue }) => {
  const { value } = typedValue;
  if (!_.isBoolean(value)) {
    return <NullValue>{value}</NullValue>;
  }
  // simply show the boolean value
  const stringValue = String(value);
  return <span title={stringValue}>{stringValue}</span>;
};

export type TypedDataProps = {
  typedValue: TypedValue
  arrayPopoverPlacement?: PlacementOption
  arrayPopoverCopyTooltipPlacement?: PlacementOption
} & TypedDataRenderingOptions;

/**
 * Entry point for all the various type-aware rendering components
 * Given a typed value, as well as rendering options, will route to the
 *   appropriate type's renderer, passing along rendering options.
 */
const TypedData: React.FC<TypedDataProps> = ({
  typedValue,
  strings,
  whitespace,
  arrays,
  highlighting,
  arrayPopoverPlacement,
  arrayPopoverCopyTooltipPlacement,
}) => {
  // render all values according to their types and the specified rendering options
  if (typedValue.typeDiscriminator === StringType.jsonRep) {
    return <StringValue {...{ typedValue, strings, whitespace, highlighting }} />;
  }
  if (typedValue.typeDiscriminator === IntType.jsonRep
    || typedValue.typeDiscriminator === LongType.jsonRep
    || typedValue.typeDiscriminator === FloatType.jsonRep
    || typedValue.typeDiscriminator === DoubleType.jsonRep) {
    return <NumberValue {...{ typedValue }} />;
  }
  if (typedValue.typeDiscriminator === BoolType.jsonRep) {
    return <BoolValue {...{ typedValue }} />;
  }
  if (typedValue.typeDiscriminator === 'array') {
    return <ArrayValue {...{ typedValue, strings, whitespace, arrays, highlighting, arrayPopoverPlacement, arrayPopoverCopyTooltipPlacement }} />;
  }
  if (typedValue.typeDiscriminator === 'record') {
    return <RecordValue {...{ typedValue, strings, whitespace, arrays, highlighting }} />;
  }

  assertNever(typedValue);
};

export default TypedData;
