import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import TooltipTrigger from '../components/TooltipTrigger';
import { onlyInputDatasets } from '../datasetFilter/DatasetFilterAsync';
import DatasetFilterDialog from '../datasetFilter/DatasetFilterDialog';

const CategorizationDatasetFilter = _.compose(
  connect((state) => {
    const { transactions: { selectedDatasetIds, datasetFilterDialogVisible } } = state;
    const modifyQuery = onlyInputDatasets(state);
    return { selectedDatasetIds, datasetFilterDialogVisible, modifyQuery };
  }, {
    onOpen: () => ({ type: 'Transactions.openDatasetFilterDialog' }),
    onHide: () => ({ type: 'Transactions.closeDatasetFilterDialog' }),
    onSubmit: (datasetsToAdd, datasetsToRemove) => ({ type: 'Transactions.setSelectedDatasets', datasetsToAdd, datasetsToRemove }),
  }),
)(({ onOpen, onHide, onSubmit, selectedDatasetIds, datasetFilterDialogVisible, modifyQuery }) => {
  return (
    <div>
      <TooltipTrigger placement="right" content="Select datasets">
        <Button className="select-trigger-button" onClick={onOpen} buttonType="Link">
          Select sources
        </Button>
      </TooltipTrigger>
      <DatasetFilterDialog
        {...{ onHide, onSubmit, modifyQuery }}
        show={datasetFilterDialogVisible}
        getSelectedDatasets={(datasets) => datasets.filter(d => selectedDatasetIds.has(d.id.id)).map(d => d.data.name).toSet()}
      />
    </div>
  );
});

export default CategorizationDatasetFilter;
