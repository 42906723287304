import React from 'react';

import Chrome from '../chrome/Chrome';
import ProjectStatusContent from '../projectStatus/ProjectStatusContent';

class Jobs extends React.Component {
  render() {
    return (
      <Chrome>
        <ProjectStatusContent />
      </Chrome>
    );
  }
}

export default Jobs;
