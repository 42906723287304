import classNames from 'classnames';
import { List } from 'immutable';
import React from 'react';

import BulletedTooltipTrigger from '../components/BulletedTooltipTrigger';
import TamrIcon from '../components/TamrIcon';
import style from './Transform.module.scss';

const TransformErrorsIcon: React.FC<{
  className?: string,
  errorMessages: List<string>,
  isDisabled?: boolean,
}> = ({ className, errorMessages, isDisabled }) => {
  if (errorMessages.isEmpty()) {
    return <noscript />;
  }
  return (
    <BulletedTooltipTrigger
      placement="left"
      items={errorMessages}
    >
      <div className={classNames(className, style.error, { [style.isDisabled]: !!isDisabled })}>
        <TamrIcon className={style.icon} iconName="tamr-icon-warning" size={16} />
        <span className={style.message}>&nbsp;errors</span>
      </div>
    </BulletedTooltipTrigger>
  );
};

export default TransformErrorsIcon;
