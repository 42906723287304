import { ArgTypes } from '../utils/ArgValidation';
import { checkTypesAndCast } from '../utils/CheckTypesAndCast';

// com.tamr.recipe.models.flows.goldenRecords.ClusterDatasetConfig

export interface ClusterDatasetConfig {
  id: string
  clusterColumn: string
  sourceColumn: string
}
export const argTypes = {
  id: ArgTypes.string,
  clusterColumn: ArgTypes.string,
  sourceColumn: ArgTypes.string,
} as const;
export const fromJSON = checkTypesAndCast<ClusterDatasetConfig, typeof argTypes>(argTypes);

export const argType = ArgTypes.object.withShape(argTypes);
