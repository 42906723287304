import React from 'react';
import { AutoSizer } from 'react-virtualized';

import AdjacentGeospatialTxnLoader from './AdjacentGeospatialTxnLoader';
import ClusterMapRecordDialog from './ClusterMapRecordDialog';
// @ts-expect-error
import ClusterRecordsHeader from './ClusterRecordsHeader';
import ClusterRecordsMap from './ClusterRecordsMap';
import ClusterRecordsTable from './ClusterRecordsTable';
import GeospatialTxnLoader from './GeospatialTxnLoader';
import { PaneE } from './Pane';
import SimilarSuppliersLoader from './SimilarSuppliersLoader';
import SuppliersTxnLoader from './SuppliersTxnLoader';

const ICONIFY_THRESHOLD = 725;

const ClusterRecordsSelector = ({ pane }: { pane: PaneE }) => {
  return (
    <div className="cluster-records-selector">
      <AutoSizer>
        {({ width, height }) => (
          <div style={{ width, height }}>
            <SuppliersTxnLoader pane={pane} />
            <SimilarSuppliersLoader pane={pane} />
            <ClusterRecordsHeader pane={pane} iconify={width < ICONIFY_THRESHOLD} />
            <ClusterRecordsTable pane={pane} />
            <ClusterRecordsMap />
            <ClusterMapRecordDialog />
            <GeospatialTxnLoader />
            <AdjacentGeospatialTxnLoader />
          </div>
        )}
      </AutoSizer>
    </div>
  );
};

export default ClusterRecordsSelector;
