import { List } from 'immutable';

import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import ElasticUtils from '../utils/ElasticUtils';

const VALUE_SEPARATOR_MARKER = '..';
const EXCLUSIVE_MARKER = '+';

function parseBound(bound: string) {
  checkArg({ bound }, ArgTypes.string);
  const inclusive = !bound.endsWith(EXCLUSIVE_MARKER);
  const valueString = bound.substring(0, bound.length - (inclusive ? 0 : 1));
  return { inclusive, value: parseFloat(valueString) };
}

class AttributeSimilarityFilterModel extends getModelHelpers({
  attributeName: { type: ArgTypes.string },
  lowerBound: { type: ArgTypes.orUndefined(ArgTypes.number) },
  upperBound: { type: ArgTypes.orUndefined(ArgTypes.number) },
  lowerBoundIsInclusive: { type: ArgTypes.bool },
  upperBoundIsInclusive: { type: ArgTypes.bool },
  comparingAbsoluteDifference: { type: ArgTypes.bool },
  comparingAbsoluteCosine: { type: ArgTypes.bool },
  includeNulls: { type: ArgTypes.bool },
  includeRange: { type: ArgTypes.bool },
}, 'AttributeSimilarityFilterModel')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class AttributeSimilarityFilterModelRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  get isActive(): boolean {
    return this.includeNulls || this.includeRange;
  }

  disable(): AttributeSimilarityFilterModel {
    return this.set('includeNulls', false).set('includeRange', false);
  }

  get sanitizedAttributeName() {
    const { attributeName } = this;
    return AttributeSimilarityFilterModel.sanitizeAttribute(attributeName);
  }

  toUrlString() {
    const { sanitizedAttributeName, lowerBound, upperBound, lowerBoundIsInclusive, upperBoundIsInclusive, comparingAbsoluteDifference, comparingAbsoluteCosine, includeNulls, includeRange } = this;
    const absDiffSegment = comparingAbsoluteDifference ? `${VALUE_SEPARATOR_MARKER}absDiff` : '';
    const absCosineSegment = comparingAbsoluteCosine ? `${VALUE_SEPARATOR_MARKER}absCosine` : '';
    const includeNullsSegment = includeNulls ? `${VALUE_SEPARATOR_MARKER}nulls` : '';
    const includeRangeSegment = includeRange ? `${VALUE_SEPARATOR_MARKER}range` : '';
    const lowerBoundSegment = `${lowerBound}${lowerBoundIsInclusive ? '' : EXCLUSIVE_MARKER}`;
    const upperBoundSegment = `${upperBound}${upperBoundIsInclusive ? '' : EXCLUSIVE_MARKER}`;
    return `${sanitizedAttributeName}${VALUE_SEPARATOR_MARKER}${lowerBoundSegment}${VALUE_SEPARATOR_MARKER}${upperBoundSegment}${absDiffSegment}${absCosineSegment}${includeRangeSegment}${includeNullsSegment}`;
  }

  toUrlStringForApi() {
    const { sanitizedAttributeName, lowerBound, upperBound, lowerBoundIsInclusive, upperBoundIsInclusive, comparingAbsoluteDifference, includeNulls, includeRange } = this;
    const absDiffSegment = comparingAbsoluteDifference ? `${VALUE_SEPARATOR_MARKER}abs` : '';
    const includeNullsSegment = includeNulls ? `${VALUE_SEPARATOR_MARKER}nulls` : '';
    const lowerBoundSegment = `${lowerBound}${lowerBoundIsInclusive ? '' : EXCLUSIVE_MARKER}`;
    const upperBoundSegment = `${upperBound}${upperBoundIsInclusive ? '' : EXCLUSIVE_MARKER}`;
    const rangeSegment = includeRange ? `${VALUE_SEPARATOR_MARKER}${lowerBoundSegment}${VALUE_SEPARATOR_MARKER}${upperBoundSegment}` : '';
    return `${sanitizedAttributeName}${rangeSegment}${absDiffSegment}${includeNullsSegment}`;
  }

  static fromUrlString(urlString: string) {
    checkArg({ urlString }, ArgTypes.string);
    const filterStringSegments = List(urlString.split(VALUE_SEPARATOR_MARKER));
    const attributeName = filterStringSegments.get(0);
    const { value: lowerBound, inclusive: lowerBoundIsInclusive } = parseBound(filterStringSegments.get(1) || '');
    const { value: upperBound, inclusive: upperBoundIsInclusive } = parseBound(filterStringSegments.get(2) || '');
    const comparingAbsoluteDifference = filterStringSegments.contains('absDiff');
    const comparingAbsoluteCosine = filterStringSegments.contains('absCosine');
    const includeNulls = filterStringSegments.contains('nulls');
    const includeRange = filterStringSegments.contains('range');
    return new AttributeSimilarityFilterModel({
      attributeName: attributeName || '',
      lowerBound,
      upperBound,
      lowerBoundIsInclusive,
      upperBoundIsInclusive,
      comparingAbsoluteDifference,
      comparingAbsoluteCosine,
      includeNulls,
      includeRange,
    });
  }

  static sanitizeAttribute(attributeName: string) {
    checkArg({ attributeName }, ArgTypes.string);
    return ElasticUtils.removeColumnPrefix(ElasticUtils.sanitizeField(attributeName));
  }
}

export default AttributeSimilarityFilterModel;
