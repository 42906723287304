import { ArgTypes, checkArg, checkReturn } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

export const browserIsOnMac = checkReturn(ArgTypes.bool, () => navigator.appVersion.indexOf('Mac') !== -1);

class KeyMods extends getModelHelpers({
  metaKey: { type: ArgTypes.bool, defaultValue: false },
  ctrlKey: { type: ArgTypes.bool, defaultValue: false },
  shiftKey: { type: ArgTypes.bool, defaultValue: false },
}, 'KeyMods')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class KeyModsRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  /**
   * with respect to single-select toggling in a multi-select scenario:
   * meta key is command on mac, which should toggle selection
   * meta key is windows key on windows, which is unusable since it opens up windows' start menu
   * ctrl key on mac usually right-clicks, which is unusable for selection (fine if it does work, though)
   * ctrl key on windows should toggle selection
   * perhaps in the future we can switch on OS, but this works for now
   */
  get toggleKey() {
    return browserIsOnMac() ? this.metaKey : this.ctrlKey;
  }

  enableToggle() {
    return this.merge({
      metaKey: browserIsOnMac() ? true : this.metaKey,
      ctrlKey: browserIsOnMac() ? this.ctrlKey : true,
    });
  }

  static fromJSON(obj: $TSFixMe) {
    checkArg({ obj }, ArgTypes.object);
    return new KeyMods(obj);
  }
}

export default KeyMods;
