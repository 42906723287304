import { List, Set } from 'immutable';
import { createSelector } from 'reselect';
import _ from 'underscore';

import AttributeId, { attributeId } from '../../models/AttributeId';
import Model from '../../models/Model';
import SourceAttribute from '../../models/SourceAttribute';
import UnifiedAttribute from '../../models/UnifiedAttribute';
import { ArgTypes, checkReturn } from '../../utils/ArgValidation';

export const initialState = new (Model({
  sourceAttributes: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(SourceAttribute)), defaultValue: List() },
  unifiedAttributes: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(UnifiedAttribute)), defaultValue: List() },
  sourceLoading: { type: ArgTypes.bool, defaultValue: false },
  unifiedLoading: { type: ArgTypes.bool, defaultValue: false },
  sourceShowSpinner: { type: ArgTypes.bool, defaultValue: false },
  unifiedShowSpinner: { type: ArgTypes.bool, defaultValue: false },
}))();

export const selectSourceMappings = createSelector(
  state => state.unifiedAttributes,
  checkReturn(ArgTypes.Immutable.map.of(ArgTypes.Immutable.set.of(ArgTypes.instanceOf(AttributeId))), (unifiedAttributes) => {
    return unifiedAttributes.flatMap(attr => {
      const uid = attributeId(attr);
      return attr.mappedAttributes.map(sid => [sid, uid]);
    }).groupBy(([sid]) => sid).map(pairs => pairs.map(([, uid]) => uid).toSet());
  }),
);

const reloadSourceAttributes = state => state.update('sourceSequence', n => n + 1);
const reloadUnifiedAttributes = state => state.update('unifiedSequence', n => n + 1);

export const reducers = {
  'SchemaMappingRecs.resetAttributes': (state) => {
    return reloadSourceAttributes(reloadUnifiedAttributes(state.merge({ sourceShowSpinner: true, unifiedShowSpinner: true })));
  },

  'SchemaMappingRecs.fetchSourceAttributes': (state) => {
    return state.set('sourceLoading', true);
  },

  'SchemaMappingRecs.fetchSourceAttributesCompleted': (state, { data }) => {
    const sourceAttributes = data.map(({ name, datasetName, description, metadata: { schemaMapping = {} } }) => {
      return new SourceAttribute({
        name,
        datasetName,
        description,
        doNotMap: Set(_.pairs(schemaMapping).filter(([, v]) => v === false).map(([k]) => k)),
      });
    }).sortBy(({ name }) => name);
    return state.merge({
      sourceAttributes, sourceLoading: false, sourceShowSpinner: false,
    });
  },

  'SchemaMappingRecs.fetchSourceAttributesFailed': (state) => {
    return state.merge({ sourceLoading: false });
  },

  'SchemaMappingRecs.fetchUnifiedAttributes': (state) => {
    return state.set('unifiedLoading', true);
  },

  'SchemaMappingRecs.fetchUnifiedAttributesCompleted': (state, { data }) => {
    const unifiedAttributes = data.map(({ data: { name, datasetName, generated, mappedAttributes } }) => {
      return new UnifiedAttribute({
        name,
        datasetName,
        generated: !!generated,
        mappedAttributes: Set(mappedAttributes.map(attributeId)),
      });
    }).sortBy(({ name }) => name);
    return state.merge({
      unifiedAttributes, unifiedLoading: false, unifiedShowSpinner: false,
    });
  },

  'SchemaMappingRecs.fetchUnifiedAttributesFailed': (state) => {
    return state.merge({ unifiedLoading: false });
  },
};
