import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';
import PairId from './PairId';

/**
 * Object used to create a comment ID
 */
export class PairCommentId extends getModelHelpers({
  entityId1: { type: ArgTypes.string },
  entityId2: { type: ArgTypes.string },
  username: { type: ArgTypes.string },
  createdAt: { type: ArgTypes.timestamp },
}, 'PairCommentId')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class PairCommentIdRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      const { entityId1, entityId2, username, createdAt } = args;
      const swap = entityId1 > entityId2;
      const argsInCorrectOrder = {
        entityId1: swap ? entityId2 : entityId1,
        entityId2: swap ? entityId1 : entityId2,
        username,
        createdAt,
      };
      super(argsInCorrectOrder);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }


  static fromJSON(json: $TSFixMe) {
    const { entityId1, entityId2, username, createdAt } = json;
    return new PairCommentId({ entityId1, entityId2, username, createdAt });
  }

  static fromPair(pair: $TSFixMe, username: string, createdAt : number) {
    const { entityId1, entityId2 } = pair;
    return new PairCommentId({
      entityId1,
      entityId2,
      username,
      createdAt,
    });
  }

  toPairId() {
    const { entityId1, entityId2 } = this;
    return new PairId({
      entityId1,
      entityId2,
    });
  }
}

export default PairCommentId;
