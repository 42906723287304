import React from 'react';

import TypedData, { ArrayRenderingOption, StringRenderingOption, WhitespaceRenderingOption } from '../dataRendering/TypedData';
import { inferTypedValueOfUnknownJson } from '../transforms/models/TypedValue';
import { JsonContent } from '../utils/typescript';

interface PregroupDataProps {
  typedValue: JsonContent
}

export const PregroupData: React.FC<PregroupDataProps> = ({ typedValue }) => (
  <TypedData
    typedValue={inferTypedValueOfUnknownJson(typedValue)}
    strings={StringRenderingOption.SHOW_QUOTES}
    whitespace={WhitespaceRenderingOption.DO_NOT_ADD_SPACE}
    arrays={ArrayRenderingOption.SHOW_BRACKETS_AND_POPOVER_WHEN_MULTIPLE_ELEMENTS}
    highlighting={false}
  />
);
