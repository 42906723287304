import { sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { Set, Map, List } from 'immutable';

import { ORIGIN_ENTITY_ID, ORIGIN_SOURCE_NAME, TAMR_ID } from '../constants/ElasticConstants';
import UnifiedAttribute from '../models/UnifiedAttribute';
import RequiredAttributeType from '../schema-mapping/constants/RequiredAttributeType';
import requiredAttributeType from '../schema-mapping/constants/RequiredAttributeType';
import { StoreReducers } from '../stores/AppAction';
import { State } from '../stores/AppState';
import { AppState } from '../stores/MainStore';
import { ArrayType, doubleType, longType, stringType } from '../transforms/models/Types';
import { AsyncStatus, getLastSuccessfulData } from '../utils/AsyncStatus';
import { getDedupInfo, selectActiveProjectInfo } from '../utils/Selectors';
import { getPath } from '../utils/Values';
import { GroupRecordsPage } from './GroupRecordsPage';
import {
  APPLY_SETTINGS,
  CANCEL_PREVIEW_GROUP_RECORDS,
  FETCH_DATA,
  FETCH_DATA_COMPLETED,
  FETCH_DATA_FAILED,
  FETCH_PREVIEW_GROUP_RECORDS,
  FETCH_PREVIEW_GROUP_RECORDS_COMPLETED,
  FETCH_PREVIEW_GROUP_RECORDS_FAILED,
  PREVIEW_GROUP_RECORDS,
  RESIZE_COLUMN,
  SAVE_GROUPING,
  SAVE_GROUPING_COMPLETED,
  SAVE_GROUPING_FAILED,
  SET_AGGREGATION,
  SET_EXCLUDE_DATASET_SEARCH_VALUE,
  SET_EXCLUDE_DATASET_SELECTION_DELTA,
  SET_PREVIEW_PAGE_NUMBER,
  SET_PREVIEW_PAGE_SIZE,
  SET_SAMPLE_PAGE_NUM,
  SET_SAMPLE_PAGE_SIZE,
  SET_EXCLUDED_DATASETS,
  SET_SHOW_SETTINGS,
  TOGGLE_EXCLUDE_DATASET_SELECTOR,
  SET_RECORD_GROUPING_ENABLED,
  SET_SHOW_METRICS,
  RELOAD_DATA,
  GROUPING_OUT_OF_DATE,
  UPDATE_GROUPING_COMPLETED,
  UPDATE_GROUPING,
  SHOW_UP_TO_DATE_DIALOG,
  HIDE_UP_TO_DATE_DIALOG, SET_IS_PREVIEW_ENABLED,
} from './PregroupActionTypes';
import { PreGroupBy, setAggregation } from './PreGroupBy';
import { PreGroupStats } from './PreGroupStats';
import { RecordGroup } from './RecordGroup';


export const DEFAULT_COLUMN_WIDTH = 200;

export const GROUP_COUNT_COLUMN_KEY = 'tamr__group_count_column';
export const GROUP_COUNT_COLUMN_NAME = 'Group Size';
export const GROUP_SIZE_ATTRIBUTE = new UnifiedAttribute({ name: GROUP_COUNT_COLUMN_NAME, datasetName: 'internal', type: longType() });
export const ORIGIN_SOURCE_NAME_HEADER_NAME = 'Source';
export const UNIFIED_DATASET_INTERNAL_FIELDS = List([ORIGIN_ENTITY_ID, ORIGIN_SOURCE_NAME, TAMR_ID]);

interface PageInfo {
  sampleMaxGroupSize: number
  sampleCurrentLimit: number
  sampleCurrentPageNum: number
}

interface PregroupStore {
  columnWidthByKey: { [key: string]: number }
  countColumnWidth: number
  outOfDate: boolean
  excludeDatasetSelectionDelta: Map<string, boolean>
  sampleStatus: AsyncStatus<RecordGroup[]>
  sampleCurrentPageNum: number;
  sampleCurrentLimit: number;
  sampleSpec: PreGroupBy | null
  sampleNumInputRecords: number | null
  sampleMetrics: PreGroupStats | null
  sampleSettingsShow: boolean
  sampleMaxGroupSize: number
  excludedDatasetNames: Set<string> | null
  excludedDatasetsDraft: Set<string> | null
  excludeDatasetSearchValue: string
  showExcludeDatasetSelector: boolean
  showGroupingMetrics: boolean
  previewGroupRowIndex: number | null
  previewGroupRecords: AsyncStatus<GroupRecordsPage>
  previewGroupRecordsPageNum: number
  previewGroupRecordsLimit: number
  warnings: PregroupWarnings
  isGroupingEnabled: boolean
  isFetchComplete: boolean
  lastPageInfo: PageInfo | null
  lastSampleSpec: PreGroupBy | null
  tripFetchSampleOnError: boolean
  reloadData: boolean
  isUpdateGroupingRunning: boolean
  showUpToDateDialog: boolean
  isInitialFetch: boolean
  isPreviewEnabled: boolean
}

export interface PregroupWarnings {
  removedFields: string[], addedFields: string[]
}

export const noWarnings = { removedFields: [], addedFields: [] };
export const initialState: PregroupStore = {
  columnWidthByKey: {},
  countColumnWidth: 200,
  outOfDate: false,
  excludeDatasetSelectionDelta: Map<string, boolean>(),
  sampleStatus: { type: 'initial' },
  sampleCurrentPageNum: 0,
  sampleCurrentLimit: 100,
  sampleSpec: null,
  sampleNumInputRecords: null,
  sampleMetrics: null,
  sampleSettingsShow: false,
  sampleMaxGroupSize: 1000,
  excludedDatasetNames: null,
  excludedDatasetsDraft: null,
  excludeDatasetSearchValue: '',
  showExcludeDatasetSelector: false,
  showGroupingMetrics: false,
  previewGroupRowIndex: null,
  previewGroupRecords: { type: 'initial' },
  previewGroupRecordsPageNum: 0,
  previewGroupRecordsLimit: 100,
  warnings: noWarnings,
  isGroupingEnabled: false,
  isFetchComplete: false,
  lastPageInfo: null,
  lastSampleSpec: null,
  tripFetchSampleOnError: false,
  reloadData: false,
  isUpdateGroupingRunning: false,
  showUpToDateDialog: false,
  isInitialFetch: true,
  isPreviewEnabled: true,
};

export const reducers: StoreReducers<PregroupStore> = {
  [RELOAD_DATA]: (store) => {
    return { ...store, reloadData: true };
  },
  [SET_IS_PREVIEW_ENABLED]: (store, { isPreviewEnabled }) => {
    return {
      ...store,
      isPreviewEnabled,
    };
  },
  [FETCH_DATA]: (store) => {
    return { ...store, sampleStatus: { type: 'loading', lastSuccessfulData: getLastSuccessfulData<RecordGroup[]>(store.sampleStatus) }, reloadData: false };
  },
  [FETCH_DATA_COMPLETED]: (store, { sample, sampleMetrics, sampleSpec, sampleNumInputRecords, warnings, excludedDatasetNames }) => {
    const { sampleCurrentLimit, sampleMaxGroupSize, sampleCurrentPageNum } = store;
    return { ...store,
      columnWidthByKey: {},
      sampleStatus: { type: 'success' as 'success', data: sample },
      sampleNumInputRecords,
      sampleMetrics,
      warnings,
      lastSampleSpec: sampleSpec,
      sampleSpec,
      lastPageInfo: { sampleCurrentLimit, sampleMaxGroupSize, sampleCurrentPageNum },
      excludedDatasetNames,
      excludedDatasetsDraft: excludedDatasetNames,
      isFetchComplete: true,
      isInitialFetch: false,
    };
  },
  [FETCH_DATA_FAILED]: (store, { errorMessage }) => {
    return { ...store, tripFetchSampleOnError: true, sampleStatus: { type: 'error', lastSuccessfulData: getLastSuccessfulData<RecordGroup[]>(store.sampleStatus), message: errorMessage }, lastSampleSpec: store.sampleSpec, isInitialFetch: false };
  },
  [SET_SAMPLE_PAGE_NUM]: (store, { pageNum }) => {
    return {
      ...store,
      sampleCurrentPageNum: pageNum,
    };
  },
  [SET_SAMPLE_PAGE_SIZE]: (store, { pageSize }) => {
    return {
      ...store,
      sampleCurrentLimit: pageSize,
      sampleCurrentPageNum: 0, // reset back to first page to avoid cases where changing the limit causes the current page not to exist
    };
  },
  [SET_EXCLUDED_DATASETS]: (store, { excludedDatasetNames }) => {
    if (!store.outOfDate) {
      store.outOfDate = true;
    }
    return {
      ...store,
      excludedDatasetNames,
    };
  },
  [SET_EXCLUDE_DATASET_SEARCH_VALUE]: (store, { excludeDatasetSearchValue }) => {
    return {
      ...store,
      excludeDatasetSearchValue,
    };
  },
  [SET_EXCLUDE_DATASET_SELECTION_DELTA]: (store, { newDeltaMap }) => {
    return {
      ...store,
      excludeDatasetSelectionDelta: newDeltaMap,
    };
  },
  [RESIZE_COLUMN]: (store, { column, width }) => {
    if (column === GROUP_COUNT_COLUMN_KEY) {
      return { ...store, countColumnWidth: width };
    }
    return { ...store, columnWidthByKey: { ...store.columnWidthByKey, [column]: width } };
  },
  [SET_SHOW_SETTINGS]: (store, { show }) => {
    return { ...store, sampleSettingsShow: show };
  },
  [APPLY_SETTINGS]: (store, { maxGroupSize }) => {
    return { ...store, sampleSettingsShow: false, sampleMaxGroupSize: maxGroupSize };
  },
  [SET_AGGREGATION]: (store, { fieldName, aggregationFunction, doGroupNulls, isGrouping }) => {
    if (store.sampleSpec === null) {
      return store;
    }
    if (!store.outOfDate) {
      store.outOfDate = true;
    }
    return {
      ...store,
      sampleSpec: setAggregation(store.sampleSpec, fieldName, aggregationFunction, doGroupNulls, isGrouping),
      tripFetchSampleOnError: false,
    };
  },
  [PREVIEW_GROUP_RECORDS]: (store, { rowIndex }) => {
    return {
      ...store,
      previewGroupRowIndex: rowIndex,
      previewGroupRecords: { type: 'initial' },
      previewGroupRecordsPageNum: 0,
      previewGroupRecordsLimit: 100,
    };
  },
  [CANCEL_PREVIEW_GROUP_RECORDS]: (store) => {
    return {
      ...store,
      previewGroupRowIndex: null,
    };
  },
  [FETCH_PREVIEW_GROUP_RECORDS]: (store) => {
    return {
      ...store,
      previewGroupRecords: { type: 'loading', lastSuccessfulData: getLastSuccessfulData<GroupRecordsPage>(store.previewGroupRecords) },
    };
  },
  [FETCH_PREVIEW_GROUP_RECORDS_COMPLETED]: (store, { records }) => {
    return {
      ...store,
      previewGroupRecords: { type: 'success', data: records },
    };
  },
  [FETCH_PREVIEW_GROUP_RECORDS_FAILED]: (store, { message }) => {
    return {
      ...store,
      previewGroupRecords: { type: 'error', message, lastSuccessfulData: getLastSuccessfulData<GroupRecordsPage>(store.previewGroupRecords) },
    };
  },
  [SET_PREVIEW_PAGE_NUMBER]: (store, { pageNum }) => {
    return {
      ...store,
      previewGroupRecordsPageNum: pageNum,
    };
  },
  [SET_PREVIEW_PAGE_SIZE]: (store, { pageSize }) => {
    return {
      ...store,
      previewGroupRecordsLimit: pageSize,
      previewGroupRecordsPageNum: 0, // reset back to first page to avoid cases where changing the limit causes the current page not to exist
    };
  },
  [SAVE_GROUPING]: (store) => {
    return { ...store, sampleStatus: { type: 'loading', lastSuccessfulData: getLastSuccessfulData<RecordGroup[]>(store.sampleStatus) } };
  },
  [SAVE_GROUPING_COMPLETED]: (store, { sampleSpec, outOfDate }) => {
    return {
      ...store,
      warnings: { removedFields: [], addedFields: [] },
      sampleStatus: { type: 'success', data: store.sampleStatus.type === 'loading' ? store.sampleStatus.lastSuccessfulData || Array.of() : Array.of() },
      sampleSpec,
      outOfDate, // outOfDate will be set true in the case where grouping is disabled, because the sampleSpec has been updated. If grouping is turned back on, the group definition is "outOfDate" and user will be able to save
    };
  },
  [SAVE_GROUPING_FAILED]: (store, { message }) => {
    return {
      ...store,
      sampleStatus: { type: 'error', message, lastSuccessfulData: getLastSuccessfulData<RecordGroup[]>(store.sampleStatus) },
    };
  },
  [GROUPING_OUT_OF_DATE]: (store) => {
    return {
      ...store,
      outOfDate: true,
    };
  },
  [TOGGLE_EXCLUDE_DATASET_SELECTOR]: (store) => {
    return {
      ...store,
      showExcludeDatasetSelector: !store.showExcludeDatasetSelector,
    };
  },
  [SET_RECORD_GROUPING_ENABLED]: (store, { isGroupingEnabled }) => {
    return {
      ...store,
      isGroupingEnabled,
    };
  },
  [SET_SHOW_METRICS]: (store) => {
    return {
      ...store,
      showGroupingMetrics: !store.showGroupingMetrics,
    };
  },
  'Location.change': () => {
    return initialState;
  },
  [UPDATE_GROUPING]: (store) => {
    return {
      ...store,
      isUpdateGroupingRunning: true,
    };
  },
  [UPDATE_GROUPING_COMPLETED]: (store) => {
    return {
      ...store,
      isUpdateGroupingRunning: false,
    };
  },
  [SHOW_UP_TO_DATE_DIALOG]: (store) => {
    return {
      ...store,
      showUpToDateDialog: true,
    };
  },
  [HIDE_UP_TO_DATE_DIALOG]: (store) => {
    return {
      ...store,
      showUpToDateDialog: false,
    };
  },
};


const selectSampleStatus = (state: State) => state.pregroup.sampleStatus;
export const selectSamplePageNum = (state: State) => state.pregroup.sampleCurrentPageNum;
export const selectSamplePageSize = (state: State) => state.pregroup.sampleCurrentLimit;
export const selectSamplePageCount = (state: State) => Math.ceil((state.pregroup.sampleMetrics?.numberOfGroups || state.pregroup.sampleNumInputRecords || 0) / state.pregroup.sampleCurrentLimit);
export const selectSampleNumInputRecords = (state: State) => state.pregroup.sampleNumInputRecords;
export const selectSampleMetrics = (state: State) => state.pregroup.sampleMetrics;
export const selectSettingsShow = (state: State) => state.pregroup.sampleSettingsShow;
export const selectSettingMaxGroupSize = (state: State) => state.pregroup.sampleMaxGroupSize;
const selectSample = createSelector(
  selectSampleStatus,
  status => getLastSuccessfulData<RecordGroup[]>(status),
);
export const selectSampleSpec = (state: State) => state.pregroup.sampleSpec;
export const selectPreGroupBy = createSelector(
  selectSampleSpec,
  getDedupInfo,
  (sampleSpec, dedupInfo): PreGroupBy | undefined => sampleSpec || dedupInfo?.preGroupBy,
);
export const selectIsInitialState = createSelector(
  selectSample,
  sample => sample === null,
);
export const selectIsLoading = createSelector(
  selectSampleStatus,
  status => status.type === 'loading',
);

export const selectDedupUnifiedAttributes = (state: AppState): UnifiedAttribute[] => {
  const projectInfo = selectActiveProjectInfo(state);
  const dedupRecipeDoc = projectInfo?.dedupRecipeDoc;
  if (!dedupRecipeDoc) {
    return [];
  }
  const spendField : string = getPath(projectInfo, 'unifiedDataset', 'metadata', 'spendField');
  const supplierField : string = getPath(dedupRecipeDoc, 'data', 'metadata', 'DEDUP', 'nameField');
  const allFields : string[] = getPath(dedupRecipeDoc, 'data', 'metadata', 'DEDUP', 'visibleFields')
    .concat(Array.from(UNIFIED_DATASET_INTERNAL_FIELDS));
  const mlFields : string[] = getPath(dedupRecipeDoc, 'data', 'metadata', 'DEDUP', 'includedFields');
  const unifiedAttributes = allFields.map(name => {
    return new UnifiedAttribute(
      {
        name,
        datasetName: projectInfo?.unifiedDatasetName,
        type: name === spendField ? doubleType() : UNIFIED_DATASET_INTERNAL_FIELDS.contains(name) ? stringType() : ArrayType.of(stringType()),
        requiredAttributeType: name === spendField ? requiredAttributeType.SPEND : name === supplierField ? requiredAttributeType.SUPPLIER : RequiredAttributeType.NONE,
        mlEnabled: List(mlFields).contains(name),
      },
    );
  });
  return sortBy(
    unifiedAttributes.filter(c => ![TAMR_ID, ORIGIN_ENTITY_ID].includes(c.name)),
    // Sort origin_source_name first, then ML enabled,  with name sorting within
    // Can be removed when column order preferences are implemented
    c => c.name !== ORIGIN_SOURCE_NAME,
    c => !c.mlEnabled,
    'name',
  );
};

export const selectColumns = createSelector(
  selectDedupUnifiedAttributes,
  (unifiedAttributes) => unifiedAttributes?.map(c => c.name),
);

export const selectColumnsWithWidths = createSelector(
  selectColumns,
  (state: State) => state.pregroup.columnWidthByKey,
  (columns, columnWidthByKey) => {
    return columns?.map(c => ({ column: c, width: columnWidthByKey[c] || DEFAULT_COLUMN_WIDTH }));
  },
);

export const selectCountColumnWidth = (state: State) => state.pregroup.countColumnWidth;
export const selectGroupingKeys = createSelector(
  selectSampleSpec,
  sampleSpec => {
    return sampleSpec?.groupingFields ?? null;
  },
);
export const selectNonGroupingKeys = createSelector(
  selectGroupingKeys,
  selectColumns,
  (groupingKeys, columns) => {
    if (columns === null || columns === undefined || groupingKeys === null) return null;
    return Array.from(columns)
      .filter(c => !groupingKeys.includes(c));
  },
);
export const selectNumRecords = createSelector(
  selectSample,
  sample => sample?.length,
);
export const selectCellData = (column: string, rowIndex: number) => createSelector(
  selectSample,
  sample => {
    if (sample === null) return { groupSize: 0, isGroupedValue: false, content: null };
    const record = sample[rowIndex];
    const rawContent = record ? record.fieldValues[column] : null;
    // NB rawContent can be anything
    //    if it's null, that's probably an error but just pass it along
    //    otherwise, it's the return value of the aggregation used during record grouping
    //    here we're going to assume an aggregation function that returns an array of
    //    strings is used (to simplify the number of cases that needs to be handled)
    const content = rawContent === null
      ? null
      : rawContent as string[];
    return {
      isGroupedValue: record ? Object.keys(record.groupIds).includes(column) : false,
      numRecordsInGroup: record?.groupSize,
      content,
    };
  },
);
export const selectGroupedRecordSize = (rowIndex: number) => createSelector(
  selectSample,
  sample => {
    if (sample === null) return 0;
    return sample[rowIndex]?.groupSize ?? 0;
  },
);

export const selectPreviewGroupRowIndex = (state: State) => state.pregroup.previewGroupRowIndex;
export const selectPreviewGroupPageNum = (state: State) => state.pregroup.previewGroupRecordsPageNum;
export const selectPreviewGroupPageSize = (state: State) => state.pregroup.previewGroupRecordsLimit;
export const selectPreviewGroupRecordsData = createSelector(
  (state: State) => state.pregroup.previewGroupRecords,
  previewGroupRecords => getLastSuccessfulData<GroupRecordsPage>(previewGroupRecords),
);
export const selectIsPreviewGroupRecordsLoading = (state: State) => state.pregroup.previewGroupRecords.type === 'loading';
export const selectPreviewGroupRecordId = createSelector(
  selectSample,
  selectPreviewGroupRowIndex,
  (sample, rowIndex) => {
    if (sample === null || rowIndex === null) return null;
    return sample[rowIndex]?.groupIds || null;
  },
);
