// com.tamr.transform.models.UnifiedAttribute.RequiredAttributeType

import enumify from '../../constants/enumify';

export enum RequiredAttributeTypeE {
  NONE = 'NONE',
  SPEND = 'SPEND',
  SUPPLIER = 'SUPPLIER',
}

export default enumify(RequiredAttributeTypeE);
