import PropTypes from 'prop-types';
import React from 'react';
import { pure } from 'recompose';
import _ from 'underscore';

import Button from '../../components/Button';
import styles from './ButtonFilter.module.scss';


const ButtonFilter = _.compose(
  pure,
)(class ButtonFilter extends React.Component {
  static propTypes = {
    buttonLabel: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  render() {
    const { onClick, label, buttonLabel } = this.props;
    return (
      <div className={styles.container}>
        <span className={styles.buttonLabel}>{label}</span>
        <Button
          buttonType="Secondary"
          onClick={onClick}
        >
          {buttonLabel}
        </Button>
      </div>
    );
  }
});

export default ButtonFilter;
