import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import PopoverTrigger from '../components/PopoverTrigger';
import TamrIcon from '../components/TamrIcon';
import { isCuratorByProjectId } from '../utils/Authorization';
import { getAuthorizedUser, selectActiveProjectInfo } from '../utils/Selectors';
import style from './UpdatesItem.module.scss';
import UpdatesPopoverContent from './UpdatesPopoverContent';
import { getNumOutOfDateSteps, getProjectStepInfos } from './UpdatesUtils';

const UpdatesItem = _.compose(
  connect(state => {
    const projectInfo = selectActiveProjectInfo(state);
    const projectStepInfos = getProjectStepInfos(projectInfo);
    const numOutOfDateSteps = getNumOutOfDateSteps(projectStepInfos);
    const authorizedUser = getAuthorizedUser(state);
    const userIsCurator = isCuratorByProjectId(authorizedUser, projectInfo?.projectDoc.id.id);
    return { userIsCurator, outOfDate: numOutOfDateSteps !== 0 };
  }),
)(({ userIsCurator, outOfDate }) => {
  if (!outOfDate || !userIsCurator) {
    return <noscript />;
  }
  return (
    <PopoverTrigger className={style.updatesItemPopover} placement="bottom" content={<UpdatesPopoverContent />}>
      <div className={classNames('top-nav-item', 'top-nav-icon-item', style.menuItem)}>
        <TamrIcon
          className={style.icon}
          iconName="error"
          size={20}
        />
      </div>
    </PopoverTrigger>
  );
});

export default UpdatesItem;
