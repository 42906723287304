import './Button.scss';

import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React, { ButtonHTMLAttributes, Requireable } from 'react';
import _ from 'underscore';

import { TamrIconName, TamrIconNames } from './Icons/TamrIconClasses';
import TamrIcon from './TamrIcon';


const buttonTypeClasses = {
  Primary: 'PrimaryBtn',
  Secondary: 'SecondaryBtn',
  Shaded: 'ShadedBtn',
  Flat: 'FlatBtn',
  Link: 'LinkBtn', // SR 2020-01-08 this should not be used anymore! Just use an <a>
  Extension: 'ExtensionBtn',
};

export type ButtonType = keyof typeof buttonTypeClasses;
export type StandardButtonType = 'Primary' | 'Secondary';
export const StandardButtonTypePropType = PropTypes.oneOf<StandardButtonType>(['Primary', 'Secondary']);

export type ButtonOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void

const propTypes = {
  buttonType: PropTypes.oneOf<keyof typeof buttonTypeClasses>(['Primary', 'Secondary', 'Shaded', 'Flat', 'Link', 'Extension']),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  /**
   * href: if supplied, turns the button into an anchor tag (ie. <a>) and adds the href to it.
   *       the button will still be styled like a button.
   */
  href: PropTypes.string,

  /**
   * hrefBlank: if href is supplied, and hrefBlank is true, opens the href of the button
   *            on a new tab.
   */
  hrefBlank: PropTypes.bool,

  /**
   * icon: places the given node in the "icon" slot of the button (to the left of children)
   * if the value is a string, it will be used to look up and use a TamrIcon
   */
  icon: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.oneOf<TamrIconName>(TamrIconNames).isRequired]),

  iconClassName: PropTypes.string,
  iconSize: PropTypes.number,
  onClick: PropTypes.func as Requireable<ButtonOnClick>,
};


// This component accepts spread props to apply to the underlying <button>.
// Currently, this component does not spread anything into the underlying <a>, in the mode where href is supplied
type ButtonComponentProps = InferProps<typeof propTypes>; // not including spread
type HTMLButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;
export type ButtonProps = ButtonComponentProps & HTMLButtonProps;

class Button extends React.Component<ButtonProps> {
  static propTypes = propTypes;

  render() {
    const { icon, iconClassName, className, children, onClick, disabled, href, hrefBlank, ...nonConstantProps } = this.props;

    // TODO default-props-in-typescript
    // eslint-disable-next-line prefer-const
    let { buttonType, iconSize, ...spreadProps } = nonConstantProps;
    buttonType = (buttonType === undefined || buttonType === null) ? 'Primary' : buttonType;
    iconSize = (iconSize === undefined || iconSize === null) ? 12 : iconSize;

    const containerClassNames = classNames('tamr-button', className, buttonTypeClasses[buttonType], { disabled, 'appearance-button': !!href });
    const childrenElement =
      <span className="button-wrapper">
        {_.isString(icon) ? <TamrIcon className={iconClassName || undefined} iconName={icon} size={iconSize} /> : icon}
        {children ? <span>{children}</span> : undefined}
      </span>;

    if (href) {
      return (
        <a className={containerClassNames} href={href} target={hrefBlank ? '_blank' : undefined}>
          {childrenElement}
        </a>
      );
    }

    // just here to make sure that spreadProps is properly typed by this point
    const typedSpreadProps: HTMLButtonProps = spreadProps;
    return (
      <button
        {...(typedSpreadProps)}
        className={classNames('tamr-button', className, buttonTypeClasses[buttonType], { disabled, 'appearance-button': !!href })}
        onClick={disabled ? undefined : onClick || undefined}
      >
        <span className="button-wrapper">
          {_.isString(icon) ? <TamrIcon className={iconClassName || undefined} iconName={icon} size={iconSize} /> : icon}
          {children ? <span>{children}</span> : undefined}
        </span>
      </button>
    );
  }
}

export default Button;
