import { is, Map } from 'immutable';
import { bindActionCreators } from 'redux';

import createLoader from '../utils/createLoader';
import { PaneE } from './Pane';
import { fetchSuppliersUpdate } from './SuppliersAsync';
import { getClustersFilterInfo } from './SuppliersStore';


const SuppliersUpdateLoader = createLoader((state, { pane }: { pane: PaneE }) => {
  const { suppliers: { [pane]: { loadedFilterInfo, softReload, loading } } } = state;
  return {
    canFetch: true,
    shouldFetch: is(Map<any>(getClustersFilterInfo(state, pane)), Map(loadedFilterInfo)) && softReload,
    loading,
  };
}, (dispatch, { pane }) => bindActionCreators({
  onFetch: () => fetchSuppliersUpdate(pane),
}, dispatch), 'SuppliersUpdateLoader');

export default SuppliersUpdateLoader;
