import { Action } from '@reduxjs/toolkit';
import _ from 'underscore';

import { $TSFixMe } from '../utils/typescript';
import { AppAction } from './AppAction';
import AppState, { legacyReducers, rtkReducers } from './AppState';

const legacyReducersWithKey = _.values(_.mapObject(legacyReducers, ({ reducers }, key) => ({ key, reducers })));
const rtkReducersWithKey = _.values(_.mapObject(rtkReducers, ({ reducer }, key) => ({ key, reducer })));

export const reducer = (state: AppState, action: AppAction | Action) => {
  if (!state) {
    return new AppState();
  }

  const legacyState = legacyReducersWithKey.reduce(
    (rn: AppState, { key, reducers }: { key: keyof typeof legacyReducers, reducers: $TSFixMe }) => {
      return reducers[action.type] ? rn.update(key, (v: $TSFixMe) => reducers[action.type](v, action)) : rn;
    },
    state,
  );

  const rtkState = rtkReducersWithKey.reduce(
    (rn: AppState, { key, reducer: rtkReducer }: { key: keyof typeof rtkReducers, reducer: $TSFixMe }) => {
      return rn.update(key, (v: $TSFixMe) => rtkReducer(v, action));
    },
    legacyState,
  );

  return rtkState;
};
