import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Button from '../components/Button';
import DocLink from '../components/DocLink';
import { history } from '../utils/History';
import style from './CategorizationDashboardGuide.module.scss';
import DashboardStep, { dashboardStepInfoPropType } from './DashboardStep';

const DashboardGuide = ({ dashboardStepsInfo }) => {
  // put completed steps first, unfinished steps next
  const sortedDashboardStepsInfo = dashboardStepsInfo.sortBy(k => { return k.isStepCompleted ? 0 : 1; });

  // the current step is the first uncompleted step
  const currentStepInfo = sortedDashboardStepsInfo.find(k => !k.isStepCompleted);
  return (
    <div className={style.container}>
      <div className={style.stepRow}>
        {sortedDashboardStepsInfo.map((k, index) => {
          return (
            <DashboardStep
              key={k.stepName}
              isCurrentKey={k === currentStepInfo}
              isFirstStep={index === 0}
              stepInfo={k}
            />
          );
        })}
      </div>
      <div className={style.card}>
        <div className={style.title}>{currentStepInfo.cardTitle}</div>
        <div className={style.text}>
          {currentStepInfo.cardText}
          {' '}<DocLink path={currentStepInfo.documentationURL}>Learn more</DocLink>
        </div>
        <div className={style.button}>
          <Button onClick={() => history.push(currentStepInfo.wizardURL)}>{currentStepInfo.cardButtonText}</Button>
        </div>
        <img src={currentStepInfo.cardImage} />
      </div>
    </div>
  );
};

DashboardGuide.propTypes = {
  dashboardStepsInfo: ImmutablePropTypes.listOf(dashboardStepInfoPropType).isRequired,
};

export default DashboardGuide;
