// com.tamr.dataset.models.DatasetStatus
import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

class DatasetStatus extends getModelHelpers({
  datasetId: { type: ArgTypes.positiveInteger },
  datasetName: { type: ArgTypes.string },
  currentRevision: { type: ArgTypes.positiveInteger },
  pinnedRevision: { type: ArgTypes.nullable(ArgTypes.positiveInteger) },
  lastMaterializedVersion: { type: ArgTypes.nullable(ArgTypes.positiveInteger) },
  lastMaterializedTimestamp: { type: ArgTypes.nullable(ArgTypes.number) },
}, 'DatasetStatus')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class DatasetStatusRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(json: $TSFixMe) {
    const { datasetId, datasetName, currentRevision, pinnedRevision, lastMaterializedVersion, lastMaterializedTimestamp } = json;
    return new DatasetStatus({
      datasetId,
      datasetName,
      currentRevision,
      pinnedRevision,
      lastMaterializedVersion,
      lastMaterializedTimestamp,
    });
  }
}

export default DatasetStatus;
