import moment from 'moment';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';

import CenterContent from '../components/CenterContent';
import LoadingPanel from '../components/LoadingPanel';
import DashboardTimeSelector from '../constants/DashboardTimeSelector';
import { AppState } from '../stores/MainStore';
import { commafy } from '../utils/Numbers';
import { pluralize } from '../utils/Strings';
import { $TSFixMe } from '../utils/typescript';
import style from './ClusterRecordsGraph.module.scss';
import { getActiveCluster } from './SuppliersStore';


const ClusterRecordsGraph = connect((state: AppState) => {
  const { suppliers: { loadingClusterPublishInfo, publishedClusterVersions, publishTimes, focusedPane, recordsGraphTimePeriod } } = state;
  const timePeriodLowerBound = recordsGraphTimePeriod === DashboardTimeSelector.ALL ? 0 : moment().subtract(1, recordsGraphTimePeriod).valueOf();
  return {
    loading: loadingClusterPublishInfo,
    publishes: publishedClusterVersions && publishTimes.map(date => publishedClusterVersions.getRecordCountForDate(date)).filter(c => !(c.time < timePeriodLowerBound)),
    currentCount: getActiveCluster(state, focusedPane)?.recordCount,
  };
})(({ loading, publishes, currentCount }) => {
  return loading
    ? <div className={style.loading}><LoadingPanel message="Loading cluster history" semiTransparent={false} /></div>
    : !publishes ? <CenterContent className={style.loading}>Error loading publish info</CenterContent> : (
      <div className={style.container}>
        <Line
          data={{
            datasets: [
              {
                label: 'Number of records',
                fill: false,
                lineTension: 0,
                borderColor: style.lineColor,
                pointBackgroundColor: style.lineColor,
                borderWidth: 2,
                borderCapStyle: 'butt',
                data: publishes.map(p => p.count).toJSON(),
              },
              {
                label: 'Number of records since last publish',
                fill: false,
                borderColor: style.lineColor,
                borderWidth: 2,
                borderCapStyle: 'butt',
                borderDash: [10, 4],
                data: publishes.butLast()
                  .map<number | undefined>(() => NaN)
                  .push(publishes?.last(undefined)?.count)
                  .push(currentCount)
                  .filter(x => x !== undefined)
                  .toJSON() as number[],
              },
            ],
            labels: publishes.map(p => moment(p.time).toString()).push(moment().toString()).toJSON(),
          }}
          options={{
            legend: {
              display: false,
            },
            layout: {
              padding: 10,
            },
            scales: {
              xAxes: [{
                gridLines: {
                  drawOnChartArea: false,
                  color: style.axisColor,
                  zeroLineColor: style.axisColor,
                  drawTicks: false,
                },
                ticks: {
                  callback: (d: $TSFixMe) => moment(d).format('l'),
                  fontFamily: style.labelFont,
                  fontColor: style.labelColor,
                  padding: 10,
                },
              }],
              yAxes: [{
                gridLines: {
                  drawOnChartArea: false,
                  drawBorder: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false,
                  min: 0,
                },
              }],
            },
            tooltips: {
              enabled: true,
              displayColors: false,
              backgroundColor: style.tooltipBackgroundColor,
              titleFontColor: style.tooltipTitleColor,
              bodyFontColor: style.tooltipBodyColor,
              borderColor: style.tooltipBorderColor,
              borderWidth: 0.5,
              callbacks: {
                title: (tooltipItems: $TSFixMe) => tooltipItems[0]?.xLabel,
                label: ({ yLabel }: $TSFixMe) => `${commafy(yLabel)} ${pluralize(yLabel, 'record', 'records')}`,
              },
            },
            maintainAspectRatio: false,
          }}
        />
      </div>
    );
});

export default ClusterRecordsGraph;
