import createLoader from '../utils/createLoader';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { fetchDnf } from './DnfBuilderApi';

const DnfLoader = createLoader(state => {
  const { dnfBuilder: { dnfLoading, dnfLoadedRecipeId } } = state;
  const activeProjectInfo = selectActiveProjectInfo(state);
  return {
    canFetch: true,
    shouldFetch: !!activeProjectInfo && !dnfLoadedRecipeId,
    loading: dnfLoading,
  };
}, {
  onFetch: fetchDnf,
}, 'DnfLoader');

export default DnfLoader;
