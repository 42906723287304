import './CenterContent.scss';

import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';

import { HTMLDivProps } from '../utils/typescript-react';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  horizontally: PropTypes.bool,
  innerClassName: PropTypes.string,
  vertically: PropTypes.bool,
};

type CenterContentProps = HTMLDivProps & InferProps<typeof propTypes>;

class CenterContent extends React.PureComponent<CenterContentProps> {
  static propTypes = propTypes;

  static defaultProps = { vertically: true, horizontally: true };

  render() {
    const { children, className, vertically, horizontally, innerClassName, ...props } = this.props;
    const outerCss = classNames('center-content', className);
    const innerCss = classNames('center-content-inner', innerClassName, {
      'vertically-centered': vertically,
      'horizontally-centered': horizontally,
    });
    return (
      <div {...props} className={outerCss}>
        <div className={innerCss}>
          {children}
        </div>
      </div>
    );
  }
}

export default CenterContent;
