import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { pure } from 'recompose';
import _ from 'underscore';

import DashboardCard from './DashboardCard';
import style from './EmptyValueCard.module.scss';

const DashboardValueCard = _.compose(
  pure,
)(class DashboardValueCard extends React.Component {
  static propTypes = {
    emptyMsg: PropTypes.string,
    onClick: PropTypes.func,
    short: PropTypes.bool,
    title: PropTypes.string,
  };

  static defaultProps = { short: false };

  render() {
    const { emptyMsg, onClick, short, title } = this.props;
    const className = classNames(style.dashboardEmptyValueCard, { short });
    return (
      <DashboardCard className={className} onClick={onClick}>
        <div>{title}</div>
        <div className={style.bottom}>{emptyMsg}</div>
      </DashboardCard>
    );
  }
});

export default DashboardValueCard;
