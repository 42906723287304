import { Set } from 'immutable';

import AuthUser from '../../models/AuthUser';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../../models/Model';
import UserRoles from '../../models/UserRoles';
import { ArgTypes, checkArg } from '../../utils/ArgValidation';
import { toggleHas } from '../../utils/Values';


export class AccountConfig extends getModelHelpers({
  given: { type: ArgTypes.nullable(ArgTypes.string) },
  surname: { type: ArgTypes.nullable(ArgTypes.string) },
  email: { type: ArgTypes.nullable(ArgTypes.string) },
  username: { type: ArgTypes.string, defaultValue: '' },
  password: { type: ArgTypes.string, defaultValue: '' },
  password2: { type: ArgTypes.string, defaultValue: '' },
  // "admin" is the admin state of the user themselves. "effectiveAdmin" is the
  // OR of the user's "admin" state and all the "admin" states of all groups
  // of which the user is a member (recursively)
  admin: { type: ArgTypes.bool, defaultValue: false },
  effectiveAdmin: { type: ArgTypes.bool, defaultValue: false },
  deactivated: { type: ArgTypes.nullable(ArgTypes.bool), defaultValue: false },
  externallyManaged: { type: ArgTypes.nullable(ArgTypes.bool), defaultValue: false },
  groups: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: Set() },
}, 'AccountConfig')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class AccountConfigRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  toggleGroup(groupname: string) {
    checkArg({ groupname }, ArgTypes.string);
    return this.update('groups', s => toggleHas(s, groupname));
  }

  isAdmin() {
    return this.admin;
  }

  isEffectiveAdmin() {
    return this.effectiveAdmin;
  }

  toggleAdmin() {
    return this.update('admin', s => !s);
  }

  static fromAuthUser(authUser: AuthUser, userRoles: UserRoles) {
    checkArg({ authUser }, ArgTypes.instanceOf(AuthUser));
    return new AccountConfig({
      username: authUser.username,
      given: authUser.user.given,
      surname: authUser.user.surname,
      email: authUser.user.email,
      admin: !!userRoles?.admin,
      effectiveAdmin: authUser.admin,
      deactivated: authUser.user.deactivated,
      externallyManaged: authUser.user.externallyManaged,
      groups: authUser.groups,
    });
  }
}

export default AccountConfig;
