import FileSaver from 'file-saver';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import TamrIcon from '../components/TamrIcon';
import Log from '../utils/Log';
import PRODUCT_NAME from '../utils/ProductName';
import { selectLoggedInUserIsAdmin } from '../utils/Selectors';
import style from './AboutDialog.module.scss';

function downloadLogs() {
  const logLines = Log.exportToArray().map(line => line + '\n');
  const blob = new Blob(logLines, { type: 'text/plain;charset=utf-8' });
  FileSaver.saveAs(blob, 'unify-ui.log');
}

const AboutDialog = connect((state) => {
  const { version: { version }, license: { license } } = state;
  return { version, license, loggedInUserIsAdmin: selectLoggedInUserIsAdmin(state) };
})(({ show, onHide, loggedInUserIsAdmin, version, license }) => {
  const expirationDate = license && moment(license.effectiveUntil * 1000);
  const days = expirationDate && expirationDate.diff(moment(), 'days') <= 31 ? `(${expirationDate.fromNow('day')})` : '';
  return (
    <Dialog
      className={style.aboutTamrDialog}
      show={show}
      onHide={onHide}
      title={`About ${PRODUCT_NAME}`}
      body={(
        <div className={style.dialogBody}>
          <TamrIcon
            className={style.tamrLogo}
            iconName="tamr-icon-stacked"
            size={150}
            title="transform"
          />
          {version && (
            <div>
              <div className={style.tamrVersion}>{`${version.version}`}</div>
              <div className={style.tamrBuild} title={version.gitCommitTime}>{`build ${version.gitCommitShort}`}</div>
            </div>
          )}
          {license && (
            <div className={style.tamrLicense}>
              {`${license.evaluation ? 'Evaluation' : 'License'} expires ${expirationDate.format('MMMM Do, YYYY')} ${days}`}
            </div>
          )}
          <Button
            className={style.tamrLink}
            buttonType="Link"
            onClick={() => window.open('http://www.tamr.com')}
          >
            www.tamr.com
          </Button>
        </div>
      )}
      dialogStyle={DialogStyle.PRIMARY}
      footer={(
        <ButtonToolbar className={style.footerToolbar}>
          {loggedInUserIsAdmin && (
            <Button
              buttonType="Link"
              onClick={downloadLogs}
            >
              Download UI Logs
            </Button>
          )}
          <Button
            style={{ marginLeft: 'auto' }}
            onClick={onHide}
            buttonType="Primary"
          >
            Done
          </Button>
        </ButtonToolbar>
      )}
      closeButton
    />
  );
});
AboutDialog.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default AboutDialog;
