// com.tamr.dedup.models.RecordPairsQuery.LabelFilter

const RecordPairLabelFilter = {
  MISSING: 'MISSING',
  MATCH: 'MATCH',
  NON_MATCH: 'NON_MATCH',
} as const;
export type RecordPairLabelFilterE = typeof RecordPairLabelFilter[keyof typeof RecordPairLabelFilter];

export default RecordPairLabelFilter;
