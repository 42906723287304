import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import PageHeader from '../chrome/PageHeader';
import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import ConditionalButton from '../components/ConditionalButton';
import Checkbox from '../components/Input/Checkbox';
import Selector from '../components/Input/Selector';
import LoadingPanel from '../components/LoadingPanel';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import { history } from '../utils/History';
import GroupByAggregations from './GroupByAggregations';
import GroupByCreateDialog from './GroupByCreateDialog';
import GroupByFields from './GroupByFields';
import GroupByInput from './GroupByInput';
import GroupByLoader from './GroupByLoader';

/**
 * Page used to configure a new derived dataset via GroupBy Functions.  If the base dataset is a
 * source dataset, load the original attributes. Otherwise, this view is updating an existing
 * GroupBy configuration.
 */
const GroupByPage = connect((state) => {
  const { groupBy: { completed, loadedDatasetId, loading, datasetDoc, errorMessage, idField, fields, viewName, viewDescription } } = state;
  return { completed, loadedDatasetId, loading, fields, viewName, viewDescription, idField, errorMessage, datasetDoc };
}, {
  onClearErrors: () => ({ type: 'GroupBy.clearErrors' }),
  onRemoveFields: (deletedFields) => ({ type: 'GroupBy.removeFields', deletedFields }),
  onReset: () => ({ type: 'GroupBy.reset' }),
  onShowSaveDialog: () => ({ type: 'GroupBy.showSaveDialog' }),
  onUpdateDescription: (description) => ({ type: 'GroupBy.updateDescription', description }),
  onUpdateFields: (updateFields, attribute, value) => ({ type: 'GroupBy.updateFields', updateFields, attribute, value }),
  onUpdateIdField: (idField) => ({ type: 'GroupBy.updateIdField', idField }),
  onUpdateName: (name) => ({ type: 'GroupBy.updateName', name }),
})(/**
 * Page used to configure a new derived dataset via GroupBy Functions.  If the base dataset is a
 * source dataset, load the original attributes. Otherwise, this view is updating an existing
 * GroupBy configuration.
 */
  class GroupByPage extends React.Component {
    static propTypes = {
      completed: PropTypes.bool.isRequired,
      datasetDoc: Document.propType.withDataType(Dataset),
      errorMessage: PropTypes.string,
      fields: ImmutablePropTypes.map.isRequired,
      idField: PropTypes.string,
      loadedDatasetId: PropTypes.number,
      loading: PropTypes.bool.isRequired,
      onClearErrors: PropTypes.func.isRequired,
      onRemoveFields: PropTypes.func.isRequired,
      onReset: PropTypes.func.isRequired,
      onShowSaveDialog: PropTypes.func.isRequired,
      onUpdateDescription: PropTypes.func.isRequired,
      onUpdateFields: PropTypes.func.isRequired,
      onUpdateIdField: PropTypes.func.isRequired,
      onUpdateName: PropTypes.func.isRequired,
      viewDescription: PropTypes.string,
      viewName: PropTypes.string,
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.completed) {
        this.cancelHandler();
      }
    }

    onGroupAll = (groupBy) => {
      this.props.onUpdateFields(this.getSelectedFields(), 'groupBy', groupBy);
    };

    getSelectedFields = () => {
      return this.props.fields.filter(field => field.selected).toList();
    };

    cancelHandler = () => {
      this.props.onReset();
      history.push('/datasets');
    };

    renderDescription = () => {
      const { viewDescription, onUpdateDescription } = this.props;
      return [
        <GroupByInput
          key="description-input"
          onExit={onUpdateDescription}
          defaultText="Description"
          text={viewDescription}
      />,
        <div key="group-by-instructions">
          <span>
            Create a new view by grouping records with the same values in one of more attributes.
          </span>
        </div>,
      ];
    };

    renderIdField = () => {
      const { idField, onUpdateIdField } = this.props;
      const items = [
        <div key="id-field-details">
          Specify the name of the unique Group By ID field:
        </div>,
        <GroupByInput
          key="id-field-input"
          onExit={onUpdateIdField}
          defaultText="Default to tamr_id"
          text={idField}
      />,
      ];
      return (
        <div className="id-field">
          {items}
        </div>
      );
    };

    renderToolbar = () => {
      const { fields, onUpdateFields, onRemoveFields } = this.props;
      const title = fields.filter(field => field.selected).size +
      ' of ' + fields.size + ' selected';
      return (
        <div className="group-by-toolbar">
          <div className="column select-all-fields">
            <Checkbox
              onChange={(selected) => onUpdateFields(fields, 'selected', selected)}
              title={title}
              titlePosition="right"
          />
          </div>
          <div className="column grouping-buttons">
            <ButtonToolbar>
              <Button buttonType="Secondary" onClick={_.partial(this.onGroupAll, true)}>
                Group
              </Button>
              <Button buttonType="Secondary" onClick={_.partial(this.onGroupAll, false)}>
                Ungroup
              </Button>
            </ButtonToolbar>
          </div>
          <div className="column aggregate-button">
            <Selector
              placeholder="Select Aggregation"
              onChange={(aggregation) => onUpdateFields(this.getSelectedFields(), 'aggregationType', aggregation)}
              values={GroupByAggregations}
          />
          </div>
          <div className="column delete-button">
            <Button
              buttonType="Link"
              icon="delete"
              iconSize={16}
              onClick={() => onRemoveFields(this.getSelectedFields())}
          />
          </div>
        </div>
      );
    };

    render() {
      const { loadedDatasetId, datasetDoc, loading, viewName, errorMessage, onUpdateName, onShowSaveDialog, fields, onClearErrors } = this.props;
      let content;
      if (loading || !loadedDatasetId) {
        content = <LoadingPanel semiTransparent={false} />;
      } else if (!datasetDoc) {
        content = (
          <div className="group-by-page-no-dataset-warning">
            <span>No dataset with id&nbsp;{loadedDatasetId}.</span>
            <a onClick={this.cancelHandler}>Back to dataset catalog</a>
          </div>
        );
      } else {
        content = (
          <div className="group-by-page">
            {errorMessage ? (
              <div className="error-panel">
                {errorMessage.split('\n').map(line => <div key={line} className="line">{line}</div>)}
                <Button buttonType="Primary" onClick={onClearErrors}>OK</Button>
              </div>
            ) : null}
            <PageHeader
              title={(
                <div>
                  <span>Sources {'>'} {datasetDoc.data.name} {'>'} </span>
                  <GroupByInput
                    className="name-input"
                    onExit={onUpdateName}
                    defaultText={viewName}
                    text={viewName}
                />
                </div>
            )}
              subtitle={this.renderDescription()}
          >
              <ButtonToolbar>
                <Button buttonType="Link" onClick={this.cancelHandler}>
                  Cancel
                </Button>
                <ConditionalButton
                  buttonType="Primary"
                  onClick={onShowSaveDialog}
                  preconditions={Map().set('You have not specified any group by fields.', fields.some(field => field.groupBy))}
              >
                  Create View
                </ConditionalButton>
              </ButtonToolbar>
            </PageHeader>
            {this.renderToolbar()}
            <div className="group-by-container">
              {this.renderIdField()}
              <GroupByFields />
            </div>
            <GroupByCreateDialog />
          </div>
        );
      }
      return (
        <div className="group-by-page-container">
          {content}
          <GroupByLoader />
        </div>
      );
    }
  });

export default GroupByPage;
