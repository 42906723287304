import PropTypes from 'prop-types';
import React from 'react';
import Truncate from 'react-truncate';
import _ from 'underscore';

import Button from './Button';

class ReadMore extends React.Component {
  static propTypes = {
    /**
     * Children is the text that is going to be toggled visible or not
     */
    children: PropTypes.string.isRequired,
    /**
     * What to show when the text is truncated
     *
     * Default is "..."
     */
    ellipsis: PropTypes.string,
    /**
     * Number of lines of the text to show when truncated
     */
    numberOfVisibleLines: PropTypes.number,
    /**
     * Callback that is invoked when text visibility is toggled (i.e. see more -> see less)
     *
     * Usage:
     *   onToggleVisibility(currentShowAllState)
     *     currentShowAllState - boolean - indicates if currently showing all of the text (before toggle)
     */
    onToggleVisibility: PropTypes.func,
    /**
     * Text to put on button for "read less"
     *
     * Default: "See less"
     */
    readLessText: PropTypes.string,
    /**
     * Text to put on button for "read more"
     *
     * Default: "See more"
     */
    readMoreText: PropTypes.string,
  };

  static defaultProps = {
    ellipsis: '...',
    numberOfVisibleLines: 3,
    readMoreText: 'see more',
    readLessText: 'see less',
  };

  state = {
    showAll: false,
    firstLoad: true,
    doTruncate: true,
  };

  onToggleVisibility = () => {
    const { onToggleVisibility } = this.props;
    if (_.isFunction(onToggleVisibility)) {
      onToggleVisibility(this.state.showAll);
    }
    this.setState({ showAll: !this.state.showAll });
  };

  /**
   * Set state so if the text is short (doesn't need to be truncated), "see more" won't appear
   */
  onTruncate = (truncated) => {
    if (this.state.firstLoad) {
      this.setState({ firstLoad: false, doTruncate: truncated });
    }
  };

  /**
   * react-truncate doesn't handle children updates nicely
   *
   * because of this we set key={children} to force a re-render every time
   * the children are updated
   */
  renderDoTruncate = () => {
    const { children, numberOfVisibleLines } = this.props;
    return (
      <div className="read-more">
        <Truncate
          key={children}
          lines={this.state.showAll ? children.length : numberOfVisibleLines}
          ellipsis={this.props.ellipsis}
          onTruncate={this.onTruncate}
        >
          {children}
        </Truncate>
        {this.renderToggleButton()}
      </div>
    );
  };

  /**
   * If children is short enough that it doesn't need to be truncated
   */
  renderNoTruncate = () => {
    return (
      <div className="read-more">{this.props.children}</div>
    );
  };

  renderToggleButton = () => {
    const text = this.state.showAll ? this.props.readLessText : this.props.readMoreText;
    const button = (
      <Button
        buttonType="Link"
        onClick={this.onToggleVisibility}
        className="toggle-button"
      >
        {text}
      </Button>
    );
    return (
      <div className="toggle-button-section">
        {button}
      </div>
    );
  };

  render() {
    return this.state.doTruncate ? this.renderDoTruncate() : this.renderNoTruncate();
  }
}

export default ReadMore;
