// com.tamr.dedup.models.UnitRange

export class UnitRange {
  lowerBound: number;
  upperBound: number;

  constructor(lowerBound: number, upperBound: number) {
    this.lowerBound = lowerBound;
    this.upperBound = upperBound;
  }

  toUrlParam() {
    const { lowerBound, upperBound } = this;
    return `${lowerBound} ${upperBound}`;
  }
}
