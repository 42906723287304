import { isEqual, pickBy } from 'lodash';
import createLoader from '../utils/createLoader';
import {
  selectActiveProjectInfo,
} from '../utils/Selectors';
import { fetchPregroupData } from './PregroupAsync';
import {
  selectDedupUnifiedAttributes,
  selectIsLoading,
} from './PregroupStore';

const PregroupLoader = createLoader((state) => {
  const activeProjectInfo = selectActiveProjectInfo(state);
  const loading = selectIsLoading(state);
  const unifiedAttributes = selectDedupUnifiedAttributes(state);
  const { pregroup: { reloadData, isPreviewEnabled, lastPageInfo, lastSampleSpec, sampleSpec, sampleMaxGroupSize, sampleCurrentPageNum, sampleCurrentLimit, tripFetchSampleOnError, excludedDatasetNames, excludedDatasetsDraft } } = state;

  // remove any fields that have been set to drop as their aggregation function before comparison
  // because a "drop" should be treated as equal to the field not being present in the map
  if (sampleSpec) {
    sampleSpec.fieldAggregationMap = pickBy(sampleSpec?.fieldAggregationMap, (aggFunc) => aggFunc !== 'drop');
  }
  if (lastSampleSpec) {
    lastSampleSpec.fieldAggregationMap = pickBy(lastSampleSpec?.fieldAggregationMap, (aggFunc) => aggFunc !== 'drop');
  }

  return {
    canFetch: !!activeProjectInfo &&
      activeProjectInfo.unifiedDataset !== undefined &&
      activeProjectInfo.isUnifiedDatasetIndexed &&
      unifiedAttributes.length > 0,
    shouldFetch: tripFetchSampleOnError === false // if a fetch request fails, we don't want to keep fetching unless there is a change that resets this variable
      && isPreviewEnabled
      && (reloadData // fetch on reload
        || !isEqual({ ...sampleSpec, sampleMaxGroupSize, sampleCurrentPageNum, sampleCurrentLimit }, { ...lastPageInfo, ...lastSampleSpec }) || !isEqual(excludedDatasetNames, excludedDatasetsDraft)), // we are diffing the last successful fetch params with the fetch params the UI expects
    loading,
  };
}, {
  onFetch: fetchPregroupData,
}, 'PregroupLoader');

export default PregroupLoader;
