import PropTypes from 'prop-types';

import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../../models/Model';
import { ArgTypes } from '../../utils/ArgValidation';
import { $TSFixMe } from '../../utils/typescript';
import Interval from './Interval';

// Imported in StaticAnalysisModels
class Lint extends getModelHelpers({
  level: { type: ArgTypes.string },
  message: { type: ArgTypes.string },
  interval: { type: Interval.argType },
}, 'Lint')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class LintRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON({ level, message, interval }: $TSFixMe) {
    return new Lint({ level, message, interval: Interval.fromJSON(interval) });
  }
  static propType = PropTypes.instanceOf(Lint);
}

export default Lint;
