import { is, Map } from 'immutable';

import { AppThunkAction } from '../stores/AppAction';
import createLoader from '../utils/createLoader';
import { getUnifiedDatasetName } from '../utils/Selectors';
import { $TSFixMe } from '../utils/typescript';
import { fetchDedupModel } from './DedupModelApi';
import { fetchPairs } from './RecordPairsAsync';
import { getFilterInfo } from './RecordPairsStore';


// TODO samgqroberts 2020-10-21 these really shouldn't be fetched together.
//   dedup model should probably just be fetched once on page load.
const fetchPairsAndDedupModel = (): AppThunkAction<void> => (dispatch) => {
  dispatch(fetchPairs());
  dispatch(fetchDedupModel());
};

const RecordPairsLoader = createLoader(state => {
  const {
    recordPairs: { loadedFilterInfo, isLoading },
    allSourceDatasets: { loadedFilterInfo: sourceDatasetLoadedFilterInfo },
    config: { pairConfidenceThresholds },
  } = state;
  const sourceDatasetsLoaded = !!sourceDatasetLoadedFilterInfo;
  const unifiedDatasetName = getUnifiedDatasetName(state);
  // construct an Immutable.Map around the filter infos so they can be compared via Immutable.is
  const filterInfo = Map(getFilterInfo(state) as $TSFixMe);
  const loadedFilterInfoMap = Map(loadedFilterInfo);
  return {
    canFetch: !!pairConfidenceThresholds && !!sourceDatasetsLoaded && !!unifiedDatasetName,
    shouldFetch: !is(filterInfo, loadedFilterInfoMap),
    loading: isLoading,
  };
}, {
  onFetch: fetchPairsAndDedupModel,
}, 'RecordPairsLoader');

export default RecordPairsLoader;
