import { connect } from 'react-redux';

import WarningDialog from '../components/WarningDialog';
import { suggestCategorizations } from './TransactionApi';

export default connect(({ transactions: { showUpdateCategorizationsWarningDialog, applyFeedbackAndUpdateResults } }) => {
  return {
    show: showUpdateCategorizationsWarningDialog,
    actionName: `${applyFeedbackAndUpdateResults ? 'Apply feedback and u' : 'U'}pdate results`,
    message: `${applyFeedbackAndUpdateResults ? 'Applying feedback and u' : 'U'}pdating results can take a while. Are you sure you want to continue?`,
  };
}, {
  onAccept: suggestCategorizations,
  onHide: () => ({ type: 'Transactions.setShowUpdateCategorizationsWarningDialog', show: false }),
})(WarningDialog);
