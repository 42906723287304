import { List, Map, Set } from 'immutable';
import { reduce } from 'lodash';
import uri from 'urijs';

import { RecipeOperationsE } from '../constants/RecipeOperations';
import { RecipeTypeTSType } from '../constants/RecipeType';
import * as EsRecord from '../goldenRecords/EsRecord';
import * as GoldenRecordsDraftQuery from '../goldenRecords/GoldenRecordsDraftQuery';
import * as GoldenRecordsModule from '../goldenRecords/GoldenRecordsModule';
import * as GoldenRecordsOverrideStats from '../goldenRecords/GoldenRecordsOverrideStats';
import { ModuleStatus, moduleStatusFromJSON } from '../goldenRecords/ModuleStatus';
import DedupInfo from '../models/DedupInfo';
import Dnf from '../models/Dnf';
import Document from '../models/doc/Document';
import Job from '../models/Job';
import * as Module from '../models/Module';
import Page from '../models/Page';
import Project from '../models/Project';
import ProjectWithStatus from '../models/ProjectWithStatus';
import Recipe, { C12N_INFO_METADATA_KEY } from '../models/Recipe';
import * as Table from '../models/Table';
import Taxonomy from '../models/Taxonomy';
import TypedTable from '../models/TypedTable';
import { SourceFilterInfoT } from '../schema-mapping/SchemaMappingStore';
import {
  fetchAsResult,
  handleErrorsWithMessage,
  jsonHeaders,
  networkError,
  toJSON,
} from '../utils/Api';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { FetchResult, toFetchResult } from './FetchResult';
import ServiceProxy from './ServiceProxy';


type JSONifyModule<T> = ((json: any) => T);

export function createProject(recipeType: RecipeTypeTSType, params: {}): Promise<Document<Project>> {
  return fetch(uri(ServiceProxy.recipe(`/projects/new/${recipeType}`)).query(params).toString(), { method: 'POST', headers: jsonHeaders })
    .then(handleErrorsWithMessage('Unable to create project'), networkError)
    .then(toJSON)
    .then(newProjectDoc => Document.fromJSON(newProjectDoc, Project.fromJSON));
}

/**
 * @param limit the maximum number of ProjectWithStatus resources to return.
 *              This client method sets this default value to something very high, considered unreachable.
 *              This means that the UI is not, by default, protected from fetching an immense amount of projects.
 *              A user of the UI may reach a more "natural" limit here, where the system becomes
 *                incrementally slower.
 */
export const fetchProjectsWithStatus = (limit = 9999999): Promise<List<ProjectWithStatus>> => {
  return fetch(ServiceProxy.recipe(`/projects/status?limit=${limit}`), { headers: jsonHeaders, method: 'GET' })
    .then(handleErrorsWithMessage('Unable to fetch projects'), networkError)
    .then(toJSON)
    .then(({ items }) => List(items).map(ProjectWithStatus.fromJSON));
};

export const postProjectsQuery = (query: $TSFixMe): Promise<List<Document<Project>>> => {
  return fetch(ServiceProxy.recipe('/projects/all'), { headers: jsonHeaders, method: 'POST', body: JSON.stringify(query) })
    .then(handleErrorsWithMessage('Unable to query projects'), networkError)
    .then(toJSON)
    .then(data => List(data).map(projectDoc => Document.fromJSON(projectDoc, Project.fromJSON)));
};

export function deleteProject(projectId: number): Promise<Response> {
  return fetch(ServiceProxy.recipe(`/projects/${projectId}`), { headers: jsonHeaders, method: 'DELETE' })
    .then(handleErrorsWithMessage('Unable to query projects'), networkError);
}

export function initProject(projectId: number, parameters: {[key: string]: any}): Promise<Response> {
  return fetch(ServiceProxy.recipe(`/projects/init/${projectId}`), { method: 'POST', headers: jsonHeaders, body: JSON.stringify(parameters) })
    .then(handleErrorsWithMessage('Unable to initialize project'), networkError);
}

export function updateProject(updatedProjectDoc: Document<Project>): Promise<Response> {
  const queryParams = updatedProjectDoc.lastModified.asQueryParams();
  const body = JSON.stringify(updatedProjectDoc.data);
  return fetch(uri(ServiceProxy.recipe(`/projects/${updatedProjectDoc.id.id}`)).query(queryParams).toString(), { headers: jsonHeaders, method: 'PUT', body })
    .then(handleErrorsWithMessage('Unable to update project'), networkError);
}

export function createModule<T extends Module.Module>(
  { module, fromJSON, query, errorMessage }: { module: T, fromJSON: JSONifyModule<T>, query: {}, errorMessage: string },
): Promise<Document<T>> {
  checkArg({ fromJSON }, ArgTypes.func);
  checkArg({ errorMessage }, ArgTypes.string);
  return fetch(uri(ServiceProxy.recipe('/modules')).query(query).toString(), { method: 'POST', headers: jsonHeaders, body: JSON.stringify(module) })
    .then(handleErrorsWithMessage(errorMessage), networkError)
    .then(toJSON)
    .then(moduleDoc => Document.fromJSON(moduleDoc, fromJSON));
}

// deletes a module, which automatically deletes attached project / recipe
export function deleteModule(moduleId: number): Promise<Response> {
  return fetch(ServiceProxy.recipe(`/modules/${moduleId}`), { headers: jsonHeaders, method: 'DELETE' })
    .then(handleErrorsWithMessage('Unable to query projects'), networkError);
}

export function updateModule<T extends Module.Module>({
  moduleId,
  version,
  module,
  fromJSON,
  skipConfigValidation,
}: {
  moduleId: number
  version: number
  module: T
  fromJSON: JSONifyModule<T>
  skipConfigValidation?: boolean
}): Promise<FetchResult<Document<T>>> {
  return fetchAsResult(uri(ServiceProxy.recipe(`/modules/${moduleId}`)).query({
    version,
    skipConfigValidation: !!skipConfigValidation,
  }).toString(), {
    headers: jsonHeaders,
    method: 'PUT',
    body: JSON.stringify(module),
  }).then(toFetchResult(moduleDoc => Document.fromJSON(moduleDoc, fromJSON)));
}

export function getModule<T extends Module.Module>(
  moduleId: number, fromJSON: (obj: any) => T,
): Promise<Document<T>> {
  checkArg({ moduleId }, ArgTypes.positiveInteger);
  return fetch(ServiceProxy.recipe(`/modules/${moduleId}`), { headers: jsonHeaders })
    .then(handleErrorsWithMessage(`Unable to fetch module with id ${moduleId}`), networkError)
    .then(toJSON)
    .then(data => Document.fromJSON(data, fromJSON));
}

export function getModuleAtVersion<T extends Module.Module>(
  moduleId: number,
  version: number,
  fromJSON: (obj: any) => T,
): Promise<FetchResult<T>> {
  return fetchAsResult(ServiceProxy.recipe(`/modules/${moduleId}/version/${version}`), { headers: jsonHeaders })
    .then(toFetchResult((logDocument: $TSFixMe) => fromJSON(logDocument.data)));
}

export function getModuleStatus(moduleId: number): Promise<FetchResult<ModuleStatus>> {
  return fetchAsResult(ServiceProxy.recipe(`/modules/${moduleId}/status`), { headers: jsonHeaders })
    .then(toFetchResult(moduleStatusFromJSON));
}
export function runModuleJob(
  { moduleId, jobName, errorMessage /* when network request fails */ }: {moduleId: number, jobName: string, errorMessage: string},
): Promise<Document<Job>> {
  checkArg({ moduleId }, ArgTypes.wholeNumber);
  checkArg({ jobName }, ArgTypes.string);
  checkArg({ errorMessage }, ArgTypes.string);
  return fetch(ServiceProxy.recipe(`/modules/${moduleId}/job/${jobName}`), { headers: jsonHeaders, method: 'POST' })
    .then(handleErrorsWithMessage(errorMessage), networkError)
    .then(toJSON)
    .then(data => Document.fromJSON(data, Job.fromJSON));
}

// TODO actually return the returned Job document
export function publishModule(moduleId: number, version?: Module.PublishVersion): Promise<Response> {
  checkArg({ moduleId }, ArgTypes.number);
  return fetch(ServiceProxy.recipe(`/modules/${moduleId}/publish?validate=true${version ? '&version=' + version : ''}`), { headers: jsonHeaders, method: 'POST' })
    .then(handleErrorsWithMessage('Unable to publish golden records'), networkError);
}

export function fetchGoldenRecordsClusterRecords(moduleId: number, clusterIds: Set<string>): Promise<TypedTable> {
  checkArg({ moduleId }, ArgTypes.positiveInteger);
  checkArg({ clusterIds }, ArgTypes.Immutable.set.of(ArgTypes.string));
  const body = JSON.stringify(clusterIds.toArray());
  return fetch(ServiceProxy.recipe(`/modules/golden-records/${moduleId}/clusterRecords`), { headers: jsonHeaders, method: 'POST', body })
    .then(handleErrorsWithMessage(`Unable to fetch cluster records for module id: ${moduleId}`), networkError)
    .then(toJSON)
    .then(json => TypedTable.fromJSON(json));
}

export function fetchGoldenRecordsPreview(input: TypedTable, module: GoldenRecordsModule.GoldenRecordsModule): Promise<Table.TableType> {
  checkArg({ input }, TypedTable.argType);
  const body = JSON.stringify({ input: input.toJSON(), module });
  return fetch(ServiceProxy.recipe('/modules/golden-records/preview'), { headers: jsonHeaders, method: 'POST', body })
    .then(handleErrorsWithMessage('Unable to fetch preview'), networkError)
    .then(toJSON)
    .then(json => Table.fromJSON(json));
}

export function queryGoldenRecordsDraft(
  moduleId: number,
  queryParameters: GoldenRecordsDraftQuery.GoldenRecordsDraftQuery,
): Promise<FetchResult<Page<EsRecord.EsRecord>>> {
  return fetchAsResult(ServiceProxy.recipe(`/modules/golden-records/${moduleId}/draft/query`), { headers: jsonHeaders, method: 'POST', body: JSON.stringify(queryParameters) })
    .then(toFetchResult(data => Page.fromJSON<EsRecord.EsRecord>(data as $TSFixMe, EsRecord.fromJSON)));
}

export function queryGoldenRecordsOverrideStats(moduleId: number, attributeNames: List<string>): Promise<Map<string, GoldenRecordsOverrideStats.GoldenRecordsOverrideStats>> {
  return fetch(ServiceProxy.recipe(`/modules/golden-records/${moduleId}/overrideStats`), { headers: jsonHeaders, method: 'POST', body: JSON.stringify(attributeNames) })
    .then(handleErrorsWithMessage('Unable to query Golden Records override stats'), networkError)
    .then(toJSON)
    .then(data => reduce(data, (acc, value, key) => acc.set(key, GoldenRecordsOverrideStats.fromJSON(value)), Map()));
}

export interface CreateOrUpdateOverrideSpec {
  action: 'CREATE',
  clusterId: string,
  attribute: string,
  value: string,
}
export interface DeleteOverrideSpec {
  action: 'DELETE',
  clusterId: string,
  attribute: string
}
export type OverrideSpec = CreateOrUpdateOverrideSpec | DeleteOverrideSpec;

export function updateGoldenRecordsOverrides(moduleId: number, overrideSpecs: List<OverrideSpec>): Promise<void> {
  const body = overrideSpecs.map(json => JSON.stringify(json)).join('\n');
  return fetch(ServiceProxy.recipe(`/modules/golden-records/${moduleId}/resources/overrides/update`), { headers: jsonHeaders, method: 'POST', body })
    .then(handleErrorsWithMessage('Unable to save manual override'), networkError)
    .then(toJSON);
}

interface OptionalJob {
  job: Job | null
}

// returns an OptionalJob
export function upgradeRecipe({ recipeId, runOperations, sparkConfigOverrides }: {
  recipeId: number,
  runOperations: boolean,
  sparkConfigOverrides?: string,
}): Promise<OptionalJob> {
  return fetch(
    uri(ServiceProxy.recipe(`/recipes/${recipeId}/upgradeRecipe`)).query({ runOperations, sparkConfigOverrides }).toString(),
    { headers: jsonHeaders, method: 'POST' },
  )
    .then(handleErrorsWithMessage('Unable to upgrade recipe'), networkError)
    .then(toJSON);
}

export function updateRecipe({ recipeId, version, recipe }: {
  recipeId: number,
  version?: number,
  recipe: Recipe,
}): Promise<Document<Recipe>> {
  const body = JSON.stringify(recipe.toJSON());
  return fetch(
    uri(ServiceProxy.recipe(`/recipes/${recipeId}`)).query({ version }).toString(),
    { headers: jsonHeaders, method: 'PUT', body },
  )
    .then(handleErrorsWithMessage(`Unable to update recipe ID ${recipeId}`), networkError)
    .then(toJSON)
    .then(data => Document.fromJSON(data, Recipe.fromJSON));
}

export function getSourceAttributes(
  filterInfo: SourceFilterInfoT,
  tag: string,
  sourceFilterDatasets: Set<$TSFixMe>,
): Promise<$TSFixMe> {
  return fetch(
    uri(ServiceProxy.recipe('/transforms/source-attributes/display'))
      .query({
        recipeId: filterInfo.smRecipeId,
        tag,
        threshold: filterInfo.similarityThreshold,
        mapped: filterInfo.sourceFilterMapped,
        unmapped: filterInfo.sourceFilterUnmapped,
        doNotMap: filterInfo.sourceFilterDNM,
        search: filterInfo.sourceSearchTerm,
        relatedAttrName: filterInfo.getIn(['unifiedFilterRelatedId', 'name']),
        offset: filterInfo.sourcePageSize * filterInfo.sourcePageNum,
        limit: filterInfo.sourcePageSize,
      }).toString(),
    {
      body: JSON.stringify(sourceFilterDatasets.toJS()),
      cache: 'no-cache',
      method: 'POST',
      headers: jsonHeaders,
    },
  ).then(handleErrorsWithMessage(`Unable to fetch source-attributes for tag ${tag}`), networkError)
    .then(toJSON);
}

export function runOperation(
  recipeId: number,
  operation: RecipeOperationsE,
): Promise<Document<Job>> {
  return fetch(
    ServiceProxy.recipe(`/recipes/${recipeId}/run/${operation}`),
    {
      method: 'POST',
      headers: jsonHeaders,
      body: JSON.stringify({}),
    },
  ).then(
    handleErrorsWithMessage(`Unable to fetch source-attributes for recipe ID ${recipeId}`),
    networkError,
  ).then(toJSON)
    .then(data => Document.fromJSON(data, Job.fromJSON));
}

export function updateOperationDatasets(
  recipeId: number,
  operation: RecipeOperationsE,
): Promise<Response> {
  return fetch(
    ServiceProxy.recipe(`/recipes/${recipeId}/updateDataset/${operation}`),
    {
      method: 'POST',
      headers: jsonHeaders,
      body: JSON.stringify({}),
    },
  ).then(
    handleErrorsWithMessage(`Unable to update datasets for recipe ID ${recipeId}, operation ${operation}`),
    networkError,
  );
}

export function populate(recipeId: number): Promise<Response> {
  return fetch(
    ServiceProxy.recipe(`/recipes/${recipeId}/populate`),
    {
      method: 'POST',
      headers: jsonHeaders,
      body: JSON.stringify({}),
    },
  );
}

export function estimatePairCounts(unifiedDatasetName: string): Promise<Document<Job>> {
  return fetch(
    ServiceProxy.dedup(`/mastering/submit-estimate-pair-counts/${unifiedDatasetName}`),
    {
      method: 'POST',
      headers: jsonHeaders,
    },
  ).then(toJSON).then(data => Document.fromJSON(data, Job.fromJSON));
}

export function getDnf(unifiedDatasetName: string): Promise<Dnf | undefined | null> {
  return fetch(
    ServiceProxy.dedup(`/mastering/configs/${unifiedDatasetName}`),
    { method: 'GET', headers: jsonHeaders },
  ).then(toJSON)
    .then(d => DedupInfo.fromJSON(d))
    .then(dedupInfo => dedupInfo.dnf);
}

function addOrRemoveRecipeInputDataset(
  recipeId: number,
  datasetName: string,
  operation: 'ADD' | 'REMOVE',
): Promise<FetchResult<void>> {
  const method = operation === 'ADD' ? 'PUT' : 'DELETE';
  return fetchAsResult(
    ServiceProxy.recipe(`/datasets/${recipeId}/input/${datasetName}`),
    { headers: jsonHeaders, method },
  ).then(toFetchResult());
}

export function addInputDatasetToRecipe(
  recipeId: number,
  datasetName: string,
): Promise<FetchResult<void>> {
  return addOrRemoveRecipeInputDataset(recipeId, datasetName, 'ADD');
}

export function removeInputDatasetFromRecipe(
  recipeId: number,
  datasetName: string,
): Promise<FetchResult<void>> {
  return addOrRemoveRecipeInputDataset(recipeId, datasetName, 'REMOVE');
}

export function changeRecipeInputs(
  recipeId: number,
  datasetsToMembershipValue: Map<string, boolean>,
): Promise<FetchResult<void>[]> {
  return Promise.all(datasetsToMembershipValue.map((isMember, datasetName) => {
    return addOrRemoveRecipeInputDataset(recipeId, datasetName, isMember ? 'ADD' : 'REMOVE');
  }).valueSeq().toArray());
}

export function getEnricherTypes(): Promise<Response> {
  return fetch(
    ServiceProxy.recipe('/modules/enrichment/enrichers'),
    { method: 'GET', headers: jsonHeaders },
  );
}

export function updateTaxonomy(
  { recipeDoc, taxonomyName, username }: { recipeDoc: Document<Recipe>, taxonomyName: string, username: string },
): Promise<void> {
  const recipe = recipeDoc.data.updateIn(['metadata', C12N_INFO_METADATA_KEY], metadata => {
    const updated = (metadata || {});
    const timestamp = Math.floor(Date.now() / 1000);
    const createdBy = metadata && metadata.taxonomy ? metadata.taxonomy.createdBy : username;
    const createdAt = metadata && metadata.taxonomy ? metadata.taxonomy.createdAt : timestamp;

    updated.taxonomy = new Taxonomy({
      name: taxonomyName,
      createdBy,
      createdAt,
      modifiedBy: username,
      modifiedAt: timestamp,
    });

    return updated;
  });

  return updateRecipe({
    recipeId: recipeDoc.id.id,
    version: recipeDoc.lastModified.version,
    recipe,
  }).then(() => {});
}
