/* eslint-disable react/no-danger */

import classNames from 'classnames';
import { TableRowEventHandler } from 'fixed-data-table-2';
import { List, Map, Set } from 'immutable';
import React from 'react';
import { DragSource as dragSource, DragSourceConnector } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import { bindActionCreators } from 'redux';
import _ from 'underscore';

import Button from '../components/Button';
import CheckboxColumn from '../components/CheckboxColumn';
import ColumnOrderSelector from '../components/ColumnOrderSelector';
import ColumnWidthProvider from '../components/ColumnWidthProvider';
import CommentColumn from '../components/CommentColumn';
import DataColumn from '../components/DataColumn';
import LoadingPanel from '../components/LoadingPanel';
import MultiValue, { recordValueShim, Value } from '../components/MultiValue';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Pager from '../components/Table/PagerModel';
import Table from '../components/Table/Table';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import TooltipTrigger from '../components/TooltipTrigger';
import withKeyState, { InjectedKeyStateProps } from '../components/WithKeyState';
import { DELETED_RECORD, NEW_RECORD } from '../constants/ClusterRecordChanges';
import * as DataTables from '../constants/DataTables';
import DisplayColumn from '../models/DisplayColumn';
import KeyMods from '../models/KeyMods';
import { AppAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { AppDispatch } from '../stores/MainStore';
import { setColumnWidth } from '../users/UsersAsync';
import { isCuratorByProjectId, isVerifierByProjectId } from '../utils/Authorization';
import PRODUCT_NAME from '../utils/ProductName';
import { getAuthorizedUser, getUDColumnSettingsForPage, selectActiveProjectInfo } from '../utils/Selectors';
import { $TSFixMe } from '../utils/typescript';
import { getPath } from '../utils/Values';
import { TwoPanesIconButton } from './ClusterCard';
import ClusterRecordsSummary from './ClusterRecordsSummary';
import styles from './ClusterRecordsTable.module.scss';
import { PaneE } from './Pane';
import { CLEAR_RECORD_FILTERS } from './SuppliersActionTypes';
import { getActiveRecordIndex, getAnyRecordsFilters, getSelectedClusters } from './SuppliersStore';
import { renderVerificationColumn } from './VerificationColumnComponents';


const CLUSTER_COLUMN_NAME = '__tamr_cluster_name_column_preference_key';

// Insert first, before Dataset column
const modifyFields = (fields: List<string>) => fields.insert(0, CLUSTER_COLUMN_NAME);
const modifyDefaults = (defs: List<DisplayColumn>) => defs.push(new DisplayColumn({ name: CLUSTER_COLUMN_NAME, order: -Infinity, alias: 'Cluster' }));

const handleDragConnection = (connector: DragSourceConnector) => {
  return { connectDragPreview: connector.dragPreview(), connectDragSource: connector.dragSource() };
};

type HandleProps = ReturnType<typeof handleDragConnection>;

const Handle = _.compose(
  connect(null, (dispatch, { pane }: { pane: PaneE }) => bindActionCreators({
    onStartDrag: (recordId): AppAction => ({ type: 'Suppliers.startDragRecord', recordId, pane }),
  }, dispatch)),
  dragSource('record', {
    beginDrag: ({ id, onStartDrag, pane }) => {
      onStartDrag(id);
      return { id, pane };
    },
  }, handleDragConnection),
)(class Handle extends React.Component<HandleProps> {
  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage());
  }

  render() {
    const { connectDragSource } = this.props;
    return connectDragSource(
      <div className="supplier-dnd-handle">
        <TamrIcon iconName="drag-handle" size={14} />
      </div>,
    );
  }
});

interface ClusterRecordsTableOwnProps {
  pane: PaneE
}

const mapStateToProps = (state: AppState, { pane }: ClusterRecordsTableOwnProps) => {
  const { config: { elasticConfig }, suppliers: { [pane]: { data, rows, recordsPageNum, recordsPageSize, numRecords, recordsLoading, recordsSaving, queryString, selectedRecordIds, recordsColumnSortStates, isClusterDiffVisible }, geospatial: { openMap }, twoPanes } } = state;
  const selectedClusters = getSelectedClusters(state.suppliers, pane);
  return {
    activeRowNumber: getActiveRecordIndex(state, pane),
    anyFilters: getAnyRecordsFilters(state, pane) || !!queryString,
    busy: recordsLoading || recordsSaving,
    clusterNameHighlightById: data.reduce((highlights, cluster) => highlights.set(cluster.clusterId, cluster.clusterNameHighlight), Map<string, string>()),
    columnSettings: getUDColumnSettingsForPage(state, DataTables.RECORDS, modifyFields, modifyDefaults),
    columnSortStates: recordsColumnSortStates,
    elasticConfig,
    isUserACurator: isCuratorByProjectId(getAuthorizedUser(state), selectActiveProjectInfo(state)?.projectDoc.id.id),
    isUserAVerifier: isVerifierByProjectId(getAuthorizedUser(state), selectActiveProjectInfo(state)?.projectDoc.id.id),
    openMap,
    pageNum: recordsPageNum,
    pageSize: recordsPageSize,
    queryString,
    recipe: (selectActiveProjectInfo(state) || {}).recipe,
    rows,
    selectedRecordIds,
    total: numRecords,
    twoPanes,
    isClusterDiffVisible,
    selectedClusterId: selectedClusters.size === 1 ? selectedClusters.first(undefined)?.clusterId : null,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch, { pane }: ClusterRecordsTableOwnProps) => bindActionCreators({
  onSort: (columnName: string): AppAction => ({ type: 'Suppliers.toggleSort', columnName, pane }),
  onSetActiveRowNumber: (rowNum: number): AppAction => ({ type: 'Suppliers.setActiveRowNumber', rowNum, pane }),
  onSetPage: (pageNum: number): AppAction => ({ type: 'Suppliers.setPage', pageNum, pane }),
  onSetPageSize: (pageSize: number): AppAction => ({ type: 'Suppliers.setPageSize', pageSize, pane }),
  onSelectRecordRow: (rowNum: number, keyMods: KeyMods): AppAction => ({ type: 'Suppliers.selectRecordRow', rowNum, keyMods, pane }),
  onSelectAllRecordRows: (): AppAction => ({ type: 'Suppliers.selectAllRecordRows', pane }),
  onSetSuppliersFilter: (supplierId: string): AppAction => ({ type: 'Suppliers.setSuppliersFilter', supplierId, pane }),
  onOpenCommentForm: (): AppAction => ({ type: 'Suppliers.openCommentForm' }),
  onClearFilters: (): AppAction => ({ type: CLEAR_RECORD_FILTERS, pane, clearSearch: true }),
  onOpenClusterBrowser: (clusterId: string): AppAction => ({ type: 'Suppliers.openClusterBrowser', clusterId, pane }),
  onSetColumnWidth: setColumnWidth,
  onClickGeoTamrAttribute: (attributeName: string): AppAction => ({ type: 'Suppliers.openGeospatialDetailsSidebar', attributeName }),
}, dispatch);

type ClusterRecordsTableProps
  = ClusterRecordsTableOwnProps
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & InjectedKeyStateProps

class ClusterRecordsTable extends React.Component<ClusterRecordsTableProps> {
  renderHandleColumn = () => {
    const { rows, isUserACurator, isUserAVerifier, pane } = this.props;
    if (!(isUserACurator || isUserAVerifier)) {
      return;
    }
    const cell = ({ rowIndex }: { rowIndex: number }) => {
      const row = rows.get(rowIndex);
      if (!row) {
        return <Cell />;
      }
      const { recordId } = row;
      return (
        <Handle id={recordId} pane={pane} />
      );
    };
    return (
      <Column key="handle" columnKey="handle" width={24} header={<Cell />} cell={cell} fixed />
    );
  };

  renderClusterDiffColumn = () => {
    const { isClusterDiffVisible, rows } = this.props;
    if (!isClusterDiffVisible) {
      return null;
    }
    const header = (
      <Cell className={styles.clusterRecordsDiffHeader}>
        <TooltipTrigger placement="top" content="Changes to source datasets">
          <TamrIcon iconName="visibility" size={16} />
        </TooltipTrigger>
      </Cell>
    );
    const cell = ({ rowIndex }: { rowIndex: number }) => {
      const row = rows.get(rowIndex);
      if (!row) {
        return <Cell />;
      }
      const { changeStatus } = row;
      if (changeStatus === NEW_RECORD) {
        return (
          <div className={styles.clusterRecordsDiffCell}>
            <TooltipTrigger placement="top" content="This record was added to its source dataset">
              <TamrIcon className={styles.clusterDiffIconNew} iconName="new" size={16} />
            </TooltipTrigger>
          </div>
        );
      }
      if (changeStatus === DELETED_RECORD) {
        return (
          <div className={styles.clusterRecordsDiffCell}>
            <TooltipTrigger placement="top" content="This record was deleted from its source dataset">
              <TamrIcon className={styles.clusterDiffIconDelete} iconName="delete" size={16} />
            </TooltipTrigger>
          </div>
        );
      }
      return <Cell />;
    };
    return (
      <Column key="clusterDiff" columnKey="clusterDiff" width={30} header={header} cell={cell} />
    );
  };

  renderSupplierNameColumn = ({ name, displayName, width }: { name: string, displayName: string, width: number }) => {
    const { rows, onSetSuppliersFilter, onOpenClusterBrowser, pane, clusterNameHighlightById, openMap } = this.props;
    const header = (
      <Cell className="table-header-cell">
        <div className="table-header-wrap">
          <TooltipTrigger placement="top" content={`This attribute was created by ${PRODUCT_NAME}`}>
            <TamrIcon size={14} iconName="tamr-icon-logo" className={styles.tamrIconLogo} />
          </TooltipTrigger>
          {displayName}
        </div>
      </Cell>
    );
    const cell = ({ rowIndex }: { rowIndex: number }) => {
      const row = rows.get(rowIndex);
      if (!row) {
        return <Cell />;
      }
      const { clusterId, clusterName } = row;
      const clusterNameHighlight = clusterNameHighlightById.get(clusterId);
      const nameToUse = clusterNameHighlight ? <span dangerouslySetInnerHTML={{ __html: clusterNameHighlight }} /> : clusterName;
      return (
        <div className={styles.clusterRecordNameCell}>
          <span className={styles.clusterRecordName}>{nameToUse || <span className={styles.emptyClusterName}>No cluster name</span>}</span>
          <TooltipTrigger placement="left" content={<span>Filter to {clusterName || 'this unnamed cluster'}</span>}>
            <Button buttonType="Link" onClick={e => { e.stopPropagation(); onSetSuppliersFilter(clusterId); }} className={styles.clusterRecordFilterButton}>
              <TamrIcon iconName="filter-list" size={16} />
            </Button>
          </TooltipTrigger>
          <TwoPanesIconButton className="supplier-pane-button" focusedPane={pane} mapIsOpen={openMap} tooltipPlacement="right" onOpenClusterInOtherPane={() => onOpenClusterBrowser(clusterId)} />
        </div>
      );
    };
    return (
      <Column columnKey={name} key={name} {...{ width, header, cell }} isResizable allowCellsRecycling />
    );
  };

  onRowClick: TableRowEventHandler = (e, rowNum) => {
    const { onSelectRecordRow } = this.props;
    if (!window.getSelection()?.toString()) {
      onSelectRecordRow(rowNum, KeyMods.fromJSON(e));
    }
  };

  render() {
    const {
      activeRowNumber,
      anyFilters,
      busy,
      columnSettings,
      columnSortStates,
      elasticConfig,
      onClearFilters,
      onClickGeoTamrAttribute,
      onOpenCommentForm,
      onSelectAllRecordRows,
      onSelectRecordRow,
      onSetActiveRowNumber,
      onSetColumnWidth,
      onSetPage,
      onSetPageSize,
      onSort,
      openMap,
      pageNum,
      pageSize,
      pane,
      queryString,
      recipe,
      rows,
      selectedRecordIds,
      shiftKey,
      total,
      twoPanes,
      isClusterDiffVisible,
      selectedClusterId,
    } = this.props;
    if (!elasticConfig) {
      return null; // must load config
    }
    const pager = new Pager(rows, _.min([elasticConfig.maxResultWindow, total]), pageNum, pageSize);
    const includedFields: Set<string> = Set(getPath(recipe, 'metadata', 'DEDUP', 'includedFields'));
    const visibleFields: Set<string> = Set(getPath(recipe, 'metadata', 'DEDUP', 'visibleFields'));
    const generatedAttributes = columnSettings ? columnSettings.filter(att => !visibleFields.contains(att.name)).map(att => (att.displayName)).toSet() : Set<string>();
    const isSelected = (index: number): boolean => {
      const recordId = rows.get(index)?.recordId;
      return !!(recordId && selectedRecordIds.has(recordId));
    };
    const isDiffAdded = (index: number) => {
      if (!isClusterDiffVisible) {
        return false;
      }
      const row = rows.get(index);
      return row && row.clusterId === selectedClusterId
        && row.publishedClusterId !== selectedClusterId;
    };
    const isDiffRemoved = (index: number) => {
      if (!isClusterDiffVisible) {
        return false;
      }
      const row = rows.get(index);
      return row && row.clusterId !== selectedClusterId
        && row.publishedClusterId === selectedClusterId;
    };
    const configureTable = (
      <div>
        {total > 0 && columnSettings ?
          <ColumnOrderSelector {...{ columnSettings }} pageType={DataTables.RECORDS} mlAttributes={includedFields} generatedAttributes={generatedAttributes} />
          : null}
      </div>
    );
    const clearSearchFooter = (
      <div className={styles.clearSearchFooter}>
        <div>{`Showing results for "${queryString}"`}</div>
        <a className="clear-supplier-filter" onClick={onClearFilters}>Clear search and <Term>record</Term> filters</a>
      </div>
    );
    const clearSearchHeight = 46; // manually measured
    return (
      <div className={classNames(styles.clusterRecordsTable, { openMap, unselectable: shiftKey, twoPanes, bottom: (pane === 'top' && !twoPanes) || pane === 'bottom' })}>
        {busy ? <LoadingPanel aboveCenter /> : null}
        {total > 0 && columnSettings ?
          <AutoSizer>
            {({ width, height }) => (
              <>
                <ClusterRecordsSummary pane={pane} width={width} />
                <ColumnWidthProvider only={['name']}>
                  <Table
                    // Cluster records summary is 48px tall
                    {...{ width, height: height - 48 }}
                    tableType="stripes"
                    rowHeight={26}
                    headerHeight={52}
                    getLength={() => rows.size}
                    activeRowNumber={activeRowNumber}
                    pageSizes={List.of(50, 100, 500, 1000)}
                    onActiveCellChange={(e, rowNum) => onSetActiveRowNumber(rowNum)}
                    onToggleRow={index => onSelectRecordRow(index, new KeyMods({}).enableToggle())}
                    onPageSizeChange={onSetPageSize}
                    onPageChange={onSetPage}
                    pagerState={pager}
                    pagerContent={configureTable}
                    pagerContentTop={queryString ? clearSearchFooter : null}
                    pagerHeight={queryString ? clearSearchHeight + 35 : 35}
                    rowClassNameGetter={index => classNames({
                      'pinned-data': rows.get(index)?.pinned,
                      selected: isSelected(index),
                      [styles.diffAdded]: isDiffAdded(index),
                      [styles.diffRemoved]: isDiffRemoved(index),
                    })}
                    onColumnResizeEndCallback={(newWidth, col) => onSetColumnWidth(DataTables.RECORDS, col, newWidth, modifyFields, modifyDefaults)}
                    onRowClick={this.onRowClick}
                >
                    {_.compact([
                      this.renderHandleColumn(),
                      CheckboxColumn({
                        selected: isSelected,
                        allSelected: selectedRecordIds.size === rows.size,
                        onToggle: (index, selected, e) => onSelectRecordRow(index, KeyMods.fromJSON(e).enableToggle()),
                        onToggleAll: onSelectAllRecordRows,
                      }),
                      renderVerificationColumn(rows),
                      CommentColumn({ onClick: onOpenCommentForm, rows }),
                      this.renderClusterDiffColumn(),
                      ...columnSettings.filter(col => col.visible).map(column => (
                        column.name === CLUSTER_COLUMN_NAME ?
                          this.renderSupplierNameColumn(column) :
                          DataColumn({
                            cell: ({ rowIndex }) => {
                              const row = rows.get(rowIndex);
                              if (!row) {
                                return <Cell />;
                              }
                              const { data, renderRaw, originalData } = row.getValue(column.name);
                              return (
                                <Cell>
                                  <MultiValue
                                    values={recordValueShim({ value: data, raw: renderRaw, originalData })}
                                    renderValue={(v: $TSFixMe, i: $TSFixMe) => {
                                      return v.case({
                                        GeoTamr: () =>
                                          <span
                                            key={i}
                                            className={styles.clickableGeoTamr}
                                            onClick={() => onClickGeoTamrAttribute(column.name)}
                                        >
                                            <Value value={v} />
                                          </span>,
                                        _: () =>
                                          <Value key={i} nestedKey={i} value={v} />,
                                      });
                                    }}
                                />
                                </Cell>
                              );
                            },
                            column,
                            columnSortStates,
                            rows,
                            onSort,
                            isMlAttribute: includedFields.contains(column.name),
                            isGeneratedAttribute: generatedAttributes.contains(column.displayName),
                          })
                      )).toArray(),
                    ])}
                  </Table>
                </ColumnWidthProvider>
              </>
            )}
          </AutoSizer>
          :
          <div className="supplier-clear-msg">
            <div>No <Term>records</Term> to show</div>
            {anyFilters ?
              <div>
                <a className="clear-supplier-filter" onClick={onClearFilters}>Clear search and <Term>record</Term> filters</a>
              </div>
              : null}
          </div>
        }
      </div>
    );
  }
}

export default _.compose(
  withKeyState,
  connect(mapStateToProps, mapDispatchToProps),
)(ClusterRecordsTable);
