import { List, Map } from 'immutable';
import _ from 'underscore';

import RecipeOperations from '../constants/RecipeOperations';
import RecipeType from '../constants/RecipeType';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import DocumentId from './doc/DocumentId';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

export const C12N_INFO_METADATA_KEY = 'CATEGORIZATION';
export const DEDUP_INFO_METADATA_KEY = 'DEDUP';

class Recipe extends getModelHelpers({
  name: { type: ArgTypes.string },
  description: { type: ArgTypes.nullable(ArgTypes.string) },
  project: { type: ArgTypes.string },
  projectStep: { type: ArgTypes.number },
  type: { type: RecipeType.argType },
  inputDatasets: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(DocumentId)) },
  metadata: { type: ArgTypes.Immutable.map },
  materializedRevisions: { type: ArgTypes.Immutable.map.of(ArgTypes.number, RecipeOperations.argType) },
  outputDatasets: { type: ArgTypes.Immutable.map.of(ArgTypes.instanceOf(DocumentId), ArgTypes.string) }, // key is dataset name
  numInputDatasets: { type: ArgTypes.number },
}, 'Recipe')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class RecipeRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(data: $TSFixMe) {
    checkArg({ data }, ArgTypes.object);
    const { name, description, project, projectStep, type, numInputDatasets } = data;
    return new Recipe({
      name,
      description,
      project,
      projectStep,
      type,
      inputDatasets: List(data.inputDatasets.map(DocumentId.fromJSON)),
      metadata: Map(data.metadata),
      materializedRevisions: Map(data.materializedRevisions),
      outputDatasets: Map(_.mapObject(data.outputDatasets, DocumentId.fromJSON)),
      numInputDatasets,
    });
  }
}

export default Recipe;
