import { List, Set } from 'immutable';
import { isArray, isNumber, isString } from 'underscore';

import * as DedupClient from '../api/DedupClient';
import { FetchResult } from '../api/FetchResult';
import { runOperation } from '../api/RecipeClient';
import { PREDICT_CLUSTER, TRAIN_PREDICT_CLUSTER } from '../constants/RecipeOperations';
import { apiError } from '../errorDialog/ErrorDialogUtils';
import LabelType from '../models/LabelType';
import Page from '../models/Page';
import { PairComment } from '../models/PairComment';
import PairCommentId from '../models/PairCommentId';
import PairId from '../models/PairId';
import RecordPairId from '../models/RecordPairId';
import { RecordPairsQuery } from '../models/RecordPairsQuery';
import RecordPairWithData from '../models/RecordPairWithData';
import ScoreThresholds from '../models/ScoreThresholds';
import { AppThunkAction, AppThunkDispatch } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { isCuratorByProjectId, isVerifierByProjectId } from '../utils/Authorization';
import ElasticUtils from '../utils/ElasticUtils';
import * as Result from '../utils/Result';
import {
  getAuthorizedUser,
  getUnifiedDatasetName,
  selectActiveProjectInfo,
} from '../utils/Selectors';
import SortUtils from '../utils/SortUtils';
import { isDefined } from '../utils/Values';
import { RecordPairFeedbackResponseTypeE } from './RecordPairFeedbackResponseType';
import {
  COMMENT_COMPLETED,
  COMMENT_FAILED,
  DELETE_COMMENT,
  DELETE_COMMENT_COMPLETED,
  DELETE_COMMENT_FAILED,
  EDIT_COMMENT,
  EDIT_COMMENT_COMPLETED,
  EDIT_COMMENT_FAILED,
  FETCH_USER_DEFINED_SIGNALS,
  FETCH_USER_DEFINED_SIGNALS_COMPLETED,
  FETCH_USER_DEFINED_SIGNALS_FAILED,
} from './RecordPairsActionTypes';
import {
  confidenceFilterToUnitRange,
  getActivePair,
  getFilterInfo,
  prepAttributeSimilarityFiltersForQuery,
  RecordPairsFilterInfo,
} from './RecordPairsStore';


function doFetch(
  unifiedDatasetName: string,
  filterInfo: RecordPairsFilterInfo,
  pairConfidenceThresholds: ScoreThresholds,
): Promise<FetchResult<Page<RecordPairWithData>>> {
  const { pageNum, pageSize, queryString, columnSortStates, attributeSimilarityFilterStates, manualLabelFilters,
    suggestedLabelFilters, labelConsensus, highImpact, hasComments, hasResponses, assignmentStatus, response,
    allAssignments, allResponses, confidenceRangeFilter, datasetNames, otherRecordDatasetNames, filterToInferredLabels,
    userDefinedSignalSorts,
  } = filterInfo;

  const query: RecordPairsQuery = {
    queryString,
    datasetNames,
    otherRecordDatasetNames,
    attributeSimilaritySortOptions: List(SortUtils.getUrlSortStates(columnSortStates)),
    attributeSimilarityFilters: prepAttributeSimilarityFiltersForQuery(attributeSimilarityFilterStates),
    userDefinedSignalsSortOptions: List(SortUtils.getUrlSortStates(userDefinedSignalSorts)),
    manualLabels: manualLabelFilters,
    suggestedLabels: suggestedLabelFilters,
    labelConsensus,
    suggestedLabelConfidence: confidenceFilterToUnitRange(confidenceRangeFilter, pairConfidenceThresholds),
    highImpact,
    hasComments,
    hasResponses,
    assignmentStatusFilter: assignmentStatus,
    responseFilter: response,
    allAssignmentsFilterType: allAssignments,
    allResponsesFilterType: allResponses,
    labelTypesFilter: filterToInferredLabels
      ? Set([LabelType.INFERRED_FROM_CLUSTER_VERIFICATION, LabelType.INFERRED_FROM_PREGROUPING])
      : Set(),
    offset: pageNum * pageSize,
    limit: pageSize,
  };

  return DedupClient.fetchPairs(unifiedDatasetName, query);
}

export const fetchPairs = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const { config: { pairConfidenceThresholds }, allSourceDatasets: { datasets: sourceDatasetDocs } } = state;
  const filterInfo = getFilterInfo(state);
  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    console.error('Cannot fetchPairs - unifiedDatasetName is undefined');
    return;
  }
  if (!pairConfidenceThresholds) {
    console.error('Cannot fetchPairs - have not loaded the pair confidence thresholds config');
    return;
  }
  dispatch({ type: 'RecordPairs.fetchPairs' });
  return Promise.all([
    // fetch the filtered page of record pairs
    doFetch(unifiedDatasetName, filterInfo, pairConfidenceThresholds),
    // fetch an unfiltered view of record pairs, to get total stats
    DedupClient.fetchPairs(unifiedDatasetName, { limit: 1, offset: 0 }),
  ]).then(([filteredPairsResult, unfilteredPairsResult]) => {
    Result.handleBoth([filteredPairsResult, unfilteredPairsResult],
      (filteredPairs, unfilteredPairs) => dispatch({
        type: 'RecordPairs.fetchPairsCompleted',
        items: filteredPairs.items,
        unfilteredTotal: unfilteredPairs.total,
        total: filteredPairs.total,
        filterInfo,
        sourceDatasetDocs,
      }),
      (firstError) => {
        apiError(dispatch, 'Error loading pairs', firstError, { type: 'RecordPairs.fetchPairsFailed', filterInfo });
      });
  });
};

export const checkFilterViolations = (rpIds: Set<RecordPairId>): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ rpIds }, ArgTypes.Immutable.set.of(ArgTypes.instanceOf(RecordPairId)));
  dispatch({ type: 'RecordPairs.checkFilterViolations', rpIds });
  const state = getState();
  const { config: { pairConfidenceThresholds } } = state;
  const filterInfo = getFilterInfo(state);
  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    console.error('Cannot checkFilterViolations - unifiedDatasetName is undefined');
    return;
  }
  if (!pairConfidenceThresholds) {
    console.error('Cannot checkFilterViolations - have not loaded the pair confidence thresholds config');
    return;
  }
  return doFetch(unifiedDatasetName, filterInfo, pairConfidenceThresholds)
    .then(Result.handler(
      ({ items }) => { // success
        const currentPageIds = items.map(i => new RecordPairId(i)).toSet();
        dispatch({ type: 'RecordPairs.checkFilterViolationsCompleted', rpIds, currentPageIds });
      },
      error => apiError(dispatch, 'Error loading pairs', error),
    ));
};

export const comment = (message: string): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ message }, ArgTypes.string);
  const state = getState();
  const user = getAuthorizedUser(state);
  if (!user) {
    console.error('Cannot comment because there is no authorized user');
    return;
  }
  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    console.error('Cannot comment because there is no handle to a unified dataset name');
    return;
  }
  const { recordPairs } = state;
  const activePair = getActivePair(recordPairs);
  const rpComment = PairComment.fromPair(activePair, message, user.username);
  dispatch({ type: 'RecordPairs.comment', rpComment });
  DedupClient.putRecordPairComments(List.of(rpComment), unifiedDatasetName)
    .then(() => dispatch({ type: COMMENT_COMPLETED, rpComment }))
    .catch((response) => dispatch({ type: COMMENT_FAILED, rpComment, response }));
};

export const editComment = (message: string, currentComment: PairComment): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ message }, ArgTypes.string);
  checkArg({ currentComment }, ArgTypes.instanceOf(PairComment));
  const state = getState();
  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    console.error('Cannot edit comment because there is no handle to a unified dataset name');
    return;
  }
  const rpComment = PairComment.fromExistingComment(currentComment, message);
  dispatch({ type: EDIT_COMMENT, rpComment });
  DedupClient.putRecordPairComments(List.of(rpComment), unifiedDatasetName)
    .then(() => dispatch({ type: EDIT_COMMENT_COMPLETED, rpComment }))
    .catch((response) => dispatch({ type: EDIT_COMMENT_FAILED, rpComment, response }));
};

export const removeComment = (username : string, createdAt: number): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ createdAt }, ArgTypes.number);
  const state = getState();
  const user = getAuthorizedUser(state);
  if (!user) {
    console.error('Cannot comment because there is no authorized user');
    return;
  }
  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    console.error('Cannot delete comment because there is no handle to a unified dataset name');
    return;
  }
  const { recordPairs } = state;
  const activePair = getActivePair(recordPairs);
  const rpCommentId = PairCommentId.fromPair(activePair, username, createdAt);
  dispatch({ type: DELETE_COMMENT, rpCommentId });
  DedupClient.deleteRecordPairComments(List.of(rpCommentId), unifiedDatasetName)
    .then(() => dispatch({ type: DELETE_COMMENT_COMPLETED, rpCommentId }))
    .catch((response) => dispatch({ type: DELETE_COMMENT_FAILED, response }));
};

function assertStringArray(data: unknown): data is string[] {
  return isArray(data) && data.every(isString);
}

function buildFeedbackResponseSpecs(pairs: List<RecordPairWithData>, responseType: RecordPairFeedbackResponseTypeE) {
  return pairs.map(p => {
    const rawLeftRootIds = p.txn1Data?.get(ElasticUtils.sanitizeField('rootUnifiedIds'));
    const rawRightRootIds = p.txn2Data?.get(ElasticUtils.sanitizeField('rootUnifiedIds'));

    return {
      entityId1: p.entityId1,
      entityId2: p.entityId2,
      leftRootIds: assertStringArray(rawLeftRootIds) ? rawLeftRootIds : [],
      rightRootIds: assertStringArray(rawRightRootIds) ? rawRightRootIds : [],
      responseType,
    };
  }).toArray();
}

function doProvideFeedbackResponses(
  state: AppState,
  dispatch: AppThunkDispatch,
  rowNumbers: Set<number>,
  responseKey: RecordPairFeedbackResponseTypeE,
): void {
  checkArg({ rowNumbers }, ArgTypes.Immutable.set.of(ArgTypes.number));
  checkArg({ responseKey }, ArgTypes.valueIn(['MATCH', 'NON_MATCH', 'SKIP']));

  const { recordPairs: { pairs }, users: { users } } = state;

  const authorizedUser = getAuthorizedUser(state);
  if (!authorizedUser) {
    console.error('Cannot provide feedback responses - no handle on the logged in user');
    return;
  }
  const projectInfo = selectActiveProjectInfo(state);
  if (!projectInfo) {
    console.error('Cannot provide feedback responses - no handle on active project info');
    return;
  }
  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    console.error('Cannot provide feedback responses - unifiedDatasetName is undefined');
    return;
  }

  const { projectId } = projectInfo;
  const userIsCurator = isCuratorByProjectId(authorizedUser, projectInfo?.projectDoc.id.id);
  const userIsVerifier = isVerifierByProjectId(authorizedUser, projectInfo?.projectDoc.id.id);
  const loggedInUsername = authorizedUser.username;

  const alsoVerify = (userIsCurator || userIsVerifier) && (responseKey === 'MATCH' || responseKey === 'NON_MATCH');
  const actionType = alsoVerify ? 'RecordPairs.respondAndVerify' : 'RecordPairs.provideFeedbackResponses';
  const completedActionType = alsoVerify ? 'RecordPairs.respondAndVerifyCompleted' : 'RecordPairs.provideFeedbackResponsesCompleted';

  const affectedPairIndices = rowNumbers.filter(index => {
    return pairs.get(index)?.feedback.find(({ username }) => username === loggedInUsername)?.responseKey !== responseKey;
  });
  const affectedPairs = pairs.filter((pair, i) => affectedPairIndices.has(i));
  dispatch({ type: actionType, rowNumbers: affectedPairIndices, responseKey, loggedInUsername, projectId, users });

  const feedbackPromise = DedupClient.providePairFeedbackResponses(unifiedDatasetName, buildFeedbackResponseSpecs(affectedPairs, responseKey), userIsCurator || userIsVerifier);

  feedbackPromise.then(results => Result.handle(results,
    () => dispatch({ type: completedActionType, rowNumbers: affectedPairIndices, responseKey }),
    error => apiError(dispatch, 'Error providing pair response(s)', error),
  ));
}

function buildUnifiedIds(pairs: List<RecordPairWithData>): List<PairId> {
  return pairs.map(({ entityId1, entityId2 }) => {
    return new PairId({ entityId1, entityId2 });
  });
}

function doRemoveFeedbackResponses(
  state: AppState,
  dispatch: AppThunkDispatch,
  rowNumbers: Set<number>,
): void {
  const { recordPairs: { pairs }, users: { users } } = state;

  const authorizedUser = getAuthorizedUser(state);
  if (!authorizedUser) {
    console.error('Cannot remove feedback responses - no handle on the logged in user');
    return;
  }
  const projectInfo = selectActiveProjectInfo(state);
  if (!projectInfo) {
    console.error('Cannot remove feedback responses - no handle on active project info');
    return;
  }
  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    console.error('Cannot remove feedback responses - unifiedDatasetName is undefined');
    return;
  }

  const { projectId } = projectInfo;
  const loggedInUsername = authorizedUser.username;
  const userIsCurator = isCuratorByProjectId(authorizedUser, projectInfo?.projectDoc.id.id);
  const userIsVerifier = isVerifierByProjectId(authorizedUser, projectInfo?.projectDoc.id.id);
  const actionType = (userIsCurator || userIsVerifier) ? 'RecordPairs.removeResponseAndVerify' : 'RecordPairs.removeFeedbackResponses';
  const completedActionType = (userIsCurator || userIsVerifier) ? 'RecordPairs.removeResponseAndVerifyCompleted' : 'RecordPairs.removeFeedbackResponsesCompleted';
  dispatch({ type: actionType, rowNumbers, loggedInUsername, projectId, users });

  const affectedPairIndices = rowNumbers.filter(index => {
    const pair = pairs.get(index);
    return !!pair?.feedback.find(f => f.username === loggedInUsername && f.hasResponseKey);
  });
  const affectedPairs = pairs.filter((pair, i) => affectedPairIndices.has(i));

  const feedbackPromise = DedupClient.removePairFeedbackResponses(unifiedDatasetName, buildUnifiedIds(affectedPairs).toArray(), userIsCurator || userIsVerifier);

  feedbackPromise.then(results => Result.handle(results,
    () => dispatch({ type: completedActionType, rowNumbers }),
    error => apiError(dispatch, 'Error removing pair response(s)', error),
  ));
}

export const confirmRemovingResponse = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const { recordPairs: { warningAboutRemoveResponse } } = state;
  if (!warningAboutRemoveResponse) {
    console.error('Cannot confirm remove response - not currently confirming this!');
    return;
  }
  const { rowNumbers, action } = warningAboutRemoveResponse;
  if (action === 'SKIP') {
    doProvideFeedbackResponses(state, dispatch, rowNumbers, action);
    return;
  }
  doRemoveFeedbackResponses(state, dispatch, rowNumbers);
};

function needToWarnAboutRemoveResponse(state: AppState, rowNumbers: Set<number>): boolean {
  checkArg({ rowNumbers }, ArgTypes.Immutable.set.of(ArgTypes.number));

  const authorizedUser = getAuthorizedUser(state);
  const projectInfo = selectActiveProjectInfo(state);
  const userIsCurator = isCuratorByProjectId(authorizedUser, projectInfo?.projectDoc.id.id);
  const userIsVerifier = isVerifierByProjectId(authorizedUser, projectInfo?.projectDoc.id.id);
  const projectId = projectInfo?.projectId;
  if (!isNumber(projectId)) return false;
  const { recordPairs: { pairs }, users: { users } } = state;
  const loggedInUsername = authorizedUser?.username;
  if (!loggedInUsername) return false;

  const thisIsOnlyCuratorResponseForSomePairs = (userIsCurator || userIsVerifier) && rowNumbers.some(rowNumber => !!pairs.get(rowNumber)?.userHasOnlyCuratorResponse(projectId, users, loggedInUsername));
  return thisIsOnlyCuratorResponseForSomePairs;
}

export const provideFeedbackResponses = ({ rowNumbers, responseKey }: {
  rowNumbers: Set<number>,
  responseKey: RecordPairFeedbackResponseTypeE,
}): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ rowNumbers }, ArgTypes.Immutable.set.of(ArgTypes.number));
  checkArg({ responseKey }, ArgTypes.valueIn(['MATCH', 'NON_MATCH', 'SKIP']));

  const state = getState();

  if (responseKey === 'SKIP' && needToWarnAboutRemoveResponse(state, rowNumbers)) {
    dispatch({ type: 'RecordPairs.warnAboutRemoveResponse', action: 'SKIP', rowNumbers });
    return;
  }

  doProvideFeedbackResponses(state, dispatch, rowNumbers, responseKey);
};

export const removeFeedbackResponses = ({ rowNumbers }: {
  rowNumbers: Set<number>
}): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ rowNumbers }, ArgTypes.Immutable.set.of(ArgTypes.number));

  const state = getState();

  if (needToWarnAboutRemoveResponse(state, rowNumbers)) {
    dispatch({ type: 'RecordPairs.warnAboutRemoveResponse', action: 'REMOVE', rowNumbers });
    return;
  }

  doRemoveFeedbackResponses(state, dispatch, rowNumbers);
};

export const launchTrainPredictCluster = (): AppThunkAction<void> => (dispatch, getState) => {
  const { location: { recipeId } } = getState();
  dispatch({ type: 'RecordPairs.launchTrainPredictCluster' });
  runOperation(recipeId, TRAIN_PREDICT_CLUSTER)
    .then(() => {
      dispatch({ type: 'RecordPairs.launchTrainPredictClusterCompleted' });
    })
    .catch(response => dispatch({ type: 'RecordPairs.launchTrainPredictClusterFailed', response }));
};

export const launchPredictCluster = (): AppThunkAction<void> => (dispatch, getState) => {
  const { location: { recipeId } } = getState();
  dispatch({ type: 'RecordPairs.launchPredictCluster' });
  runOperation(recipeId, PREDICT_CLUSTER)
    .then(() => dispatch({ type: 'RecordPairs.launchPredictClusterCompleted' }))
    .catch(response => dispatch({ type: 'RecordPairs.launchPredictClusterFailed', response }));
};

function buildFeedbackIdSpecs(
  usernames: Set<string>,
  pairs: Set<RecordPairWithData>,
  assignOrUnassign: boolean,
) {
  return usernames.flatMap(username => {
    return pairs.filter(pair => { // guard against redundant assignments / unassignments
      return pair.assignedToUser(username) !== assignOrUnassign;
    }).map(({ entityId1, entityId2 }) => {
      return { username, entityId1, entityId2 };
    });
  }).toJSON();
}

export const updateFeedbackAssignments = (
  usersToAssign: Set<string>,
  usersToUnassign: Set<string>,
): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ usersToAssign }, ArgTypes.Immutable.set.of(ArgTypes.string));
  checkArg({ usersToUnassign }, ArgTypes.Immutable.set.of(ArgTypes.string));
  const state = getState();
  const { recordPairs } = state;
  const { pairs, assigningFeedbackForRows } = recordPairs;
  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    console.error('Cannot update feedback assignments - unifiedDatasetName is undefined');
    return;
  }
  const selectedPairs = assigningFeedbackForRows.map(r => pairs.get(r)).filter(isDefined);
  dispatch({ type: 'RecordPairs.updateFeedbackAssignments', pairIndexes: assigningFeedbackForRows, usersToAssign, usersToUnassign });
  const promises: Promise<FetchResult<unknown>>[] = [];
  if (!usersToAssign.isEmpty()) {
    const feedbackIds = buildFeedbackIdSpecs(usersToAssign, selectedPairs, true);
    promises.push(DedupClient.assignPairFeedback(unifiedDatasetName, feedbackIds));
  }
  if (!usersToUnassign.isEmpty()) {
    const feedbackIds = buildFeedbackIdSpecs(usersToUnassign, selectedPairs, false);
    promises.push(DedupClient.unassignPairFeedback(unifiedDatasetName, feedbackIds));
  }
  Promise.all(promises).then(results => Result.handleAll(results,
    () => dispatch({ type: 'RecordPairs.updateFeedbackAssignmentsCompleted', pairIndexes: assigningFeedbackForRows }),
    error => apiError(dispatch, 'Error updating feedback assignments', error),
  ));
};

export const fetchUserDefinedSignals = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    console.error('Cannot fetch user defined signals - unifiedDatasetName is undefined');
    return;
  }
  dispatch({ type: FETCH_USER_DEFINED_SIGNALS });
  DedupClient.getUserDefinedSignalsByDatasetName(unifiedDatasetName).then(Result.handler(
    userDefinedSignals => dispatch({ type: FETCH_USER_DEFINED_SIGNALS_COMPLETED, userDefinedSignals }),
    error => apiError(dispatch, 'Error fetching user defined signals', error, { type: FETCH_USER_DEFINED_SIGNALS_FAILED }),
  ));
};
