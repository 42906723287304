/* eslint-disable react/no-multi-comp */
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';

import Button from '../components/Button';
import ColumnWidthProvider from '../components/ColumnWidthProvider';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Table from '../components/Table/Table';
import TooltipTrigger from '../components/TooltipTrigger';
import * as UsersActions from '../constants/UsersActionTypes';
import Group from '../models/Group';
import { AppAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { pluralize } from '../utils/Strings';
import GroupPoliciesDialog from './GroupPoliciesDialog';
import style from './UsersTable.module.scss';


const GroupsTable = connect(({ users: { groups }, accessControl: { policyDocs } }: AppState) => {
  return { groups, policyDocs };
}, {
  onStartEditingGroup: (id: number): AppAction => ({ type: UsersActions.START_EDIT_GROUP, id }),
  onStartEditingPolicyMembership: (group: Group): AppAction => ({ type: 'Groups.startEditingPolicyMembership', group }),
  onStartDeleteGroup: (id: number): AppAction => ({ type: UsersActions.START_DELETE_GROUP, id }),
})(({ groups, policyDocs, onStartEditingGroup, onStartEditingPolicyMembership, onStartDeleteGroup }) => {
  return (
    <div className={style.usersTableContainer}>
      <GroupPoliciesDialog />
      <AutoSizer>
        {({ width, height }) => (
          <ColumnWidthProvider>
            <Table
              {...{ height, width }}
              getLength={() => groups.size}
              tableType="stripes"
              rowHeight={54}
              rowClassNameGetter={() => style.row}
            >
              <Column
                key="group"
                columnKey="group"
                width={100}
                flexGrow={1}
                header={<Cell>Group</Cell>}
                cell={({ rowIndex }) => {
                  const group = groups.get(rowIndex);
                  if (!group) return <Cell />;
                  const name = group.data.groupname;
                  const description = group.data.description;
                  return (
                    <div className={classNames(style.firstColumn, style.cell)}>
                      <span className={style.groupNameDesc}>
                        <div className={style.groupname} title={name}>{name}</div>
                        <div className={style.description} title={description}>{description}</div>
                      </span>
                      <TooltipTrigger placement="top" content={<span>Edit group</span>}>
                        <Button
                          className={style.editButton}
                          buttonType="Link"
                          icon="edit"
                          iconSize={16}
                          onClick={() => onStartEditingGroup(group.id.id)}
                        />
                      </TooltipTrigger>
                      <TooltipTrigger placement="top" content={<span>Delete group</span>}>
                        <Button
                          className={style.editButton}
                          buttonType="Link"
                          icon="delete"
                          iconSize={16}
                          onClick={() => onStartDeleteGroup(group.id.id)}
                        />
                      </TooltipTrigger>
                    </div>
                  );
                }}
              />
              <Column
                key="memberOfPolicy"
                columnKey="memberOfPolicy"
                width={100}
                flexGrow={1}
                header={<Cell>Member of policy</Cell>}
                cell={({ rowIndex }) => {
                  const group = groups.get(rowIndex)?.data;
                  if (!group) return <Cell />;
                  const numberOfPolicies = policyDocs.filter(doc => doc.data.roleForGroupname(group.groupname)).size;
                  return (
                    <Cell>
                      <TooltipTrigger
                        content={group.admin && 'Group already has full access due to admin status'}
                        placement="right"
                      >
                        <Button
                          buttonType="Link"
                          className={style.groupLinkButton}
                          onClick={() => onStartEditingPolicyMembership(group)}
                          disabled={group.admin}
                        >
                          {group.admin
                            ? '--'
                            : `${numberOfPolicies || 'No'} ${pluralize(numberOfPolicies, 'Policy', 'Policies')}`
                          }
                        </Button>
                      </TooltipTrigger>
                    </Cell>
                  );
                }}
              />
              <Column
                key="admin"
                columnKey="admin"
                width={50}
                flexGrow={1}
                header={<Cell>Admin</Cell>}
                cell={({ rowIndex }) => {
                  const group = groups.get(rowIndex);
                  if (!group) return <Cell />;
                  const { data: { admin }, id: { id } } = group;
                  return (
                    <Cell>
                      <Button buttonType="Link" className={style.groupLinkButton} onClick={() => onStartEditingGroup(id)}>
                        <span>{admin ? 'Yes' : 'No'}</span>
                      </Button>
                    </Cell>
                  );
                }}
              />
            </Table>
          </ColumnWidthProvider>
        )}
      </AutoSizer>
    </div>
  );
});

export default GroupsTable;
