import { List, Map } from 'immutable';

import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import { ArgTypes } from '../utils/ArgValidation';
import DriverSpec from './DriverSpec';

export default class DatasetSpecWithTableConfig extends getModelHelpers({
  name: { type: ArgTypes.string },
  description: { type: ArgTypes.orUndefined(ArgTypes.string) },
  externalId: { type: ArgTypes.orUndefined(ArgTypes.string) },
  fields: { type: ArgTypes.Immutable.list.of(ArgTypes.string), defaultValue: List() },
  idFields: { type: ArgTypes.Immutable.list.of(ArgTypes.string), defaultValue: List() },
  metadata: { type: ArgTypes.Immutable.map, defaultValue: Map() },
  driverSpecs: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(DriverSpec)), defaultValue: List() },
}, 'DatasetSpecWithTableConfig')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class DatasetSpecWithTableConfigRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}
