import classNames from 'classnames';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import unpivotInfo from '../../images/unpivot-dependent-data-column.svg';
import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import PopoverTrigger from '../components/PopoverTrigger';
import SearchBox from '../components/SearchBox';
import Selector from '../components/Selector';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import { selectStagedUnifiedDatasetColumns } from '../records/RecordsColumns';
import transformStyle from './Transform.module.scss';
import TransformErrorsIcon from './TransformErrorsIcon';
import style from './UnpivotDialog.module.scss';
import UnpivotItem from './UnpivotItem';
import UnpivotTarget from './UnpivotTarget';

const UnpivotDialog = _.compose(
  connect((state) => {
    return { options: selectStagedUnifiedDatasetColumns(state).map(col => col.name).toArray() };
  }),
)(class UnpivotDialog extends React.Component {
  static propTypes = {
    dependentColumnValues: PropTypes.object,
    dependentColumns: PropTypes.arrayOf(PropTypes.string),
    errorMessage: PropTypes.string,
    erroring: PropTypes.bool,
    onEdit: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    unpivot: PropTypes.object,
    unpivotColumns: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    valueColumn: PropTypes.string,
    variableColumn: PropTypes.string,
  };

  static defaultProps = { erroring: false };

  state = {
    addDependentCol: false,
    initialDependentColumns: [],
    dependentColumnValues: {},
    dependentColumns: [],
    dialogVisible: false,
    searchString: '',
  };

  onOpen = () => { // set states to initially be the same as the passed props, this is necessary if we need to remove depedent attrs and vals
    const dependentColumnInitialNames = [];
    const dependentColumnInitialValues = {};
    if (this.props.dependentColumns) {
      this.props.dependentColumns.map(dep => dependentColumnInitialNames.push(dep));
    }
    // to avoid nested objects being copied by reference, instead we want them to be duplicated
    this.props.unpivotColumns.map(col => dependentColumnInitialValues[col] = _.extend({}, this.props.dependentColumnValues[col]));
    this.setState({
      dependentColumnValues: dependentColumnInitialValues,
      dependentColumns: dependentColumnInitialNames,
      initialDependentColumns: dependentColumnInitialNames,
      initialDependentColumnValues: dependentColumnInitialValues,
      dialogVisible: true,
    });
  };

  onClose = () => {
    this.setState({
      dialogVisible: false,
      searchString: '',
      addDependentCol: false,
    });
    this.onClear();
  };

  onClear = () => {
    this.setState({
      dependentColumnValues: {},
      dependentColumns: [],
    });
  };

  onRemove = (index) => {
    const { unpivotColumns } = this.props;
    const { addDependentCol, dependentColumns, dependentColumnValues } = this.state;
    // if the removed col is the dummy addDependentCol, set it to false and delete last index value
    if (addDependentCol && index === _.uniq(_.compact(dependentColumns)).length) {
      // remove all dep col values across unpivot cols at the dummy / last index
      const dependentCurrentColumnValues = {};
      unpivotColumns.map(col => {
        dependentCurrentColumnValues[col] = _.extend({}, dependentColumnValues[col]);
        dependentCurrentColumnValues[col][index] = null;
        delete dependentCurrentColumnValues[col][index];
      });
      this.setState({ addDependentCol: false, dependentColumnValues: dependentCurrentColumnValues });
    } else {
      // remove the dep col name at this index
      const dependentCurrentColumnNames = [];
      dependentColumns.filter((col, i) => i !== index).map(dep => dependentCurrentColumnNames.push(dep));
      // remove all dep col values across unpivot cols at this index
      // set all dep col values to the previous index
      const dependentCurrentColumnValues = {};
      unpivotColumns.map(col => {
        dependentCurrentColumnValues[col] = _.extend({}, dependentColumnValues[col]);
        dependentCurrentColumnValues[col][index] = null;
        for (let j = index; j < dependentColumns.length - 1; j++) {
          const nextValue = dependentCurrentColumnValues[col][j + 1];
          dependentCurrentColumnValues[col][j] = nextValue;
        }
        delete dependentCurrentColumnValues[col][dependentColumns.length - 1];
      });
      this.setState({ dependentColumnValues: dependentCurrentColumnValues, dependentColumns: dependentCurrentColumnNames });
    }
  };

  onSubmit = () => {
    const { onEdit, unpivot, unpivotColumns } = this.props;
    const { dependentColumns, dependentColumnValues } = this.state;
    const submitDependentColumnValues = {};
    unpivotColumns.map(col => submitDependentColumnValues[col] = _.extend({}, dependentColumnValues[col]));
    const submitDependentColumns = [];
    dependentColumns.map(col => submitDependentColumns.push(col));
    onEdit(unpivot.set('dependentColumns', submitDependentColumns).set('dependentColumnValues', submitDependentColumnValues));
    this.onClose();
  };

  onChangeDependentValues = (newKey, newValue, index) => {
    const { dependentColumns, dependentColumnValues } = this.state;
    const { unpivotColumns } = this.props;
    const newDependentColumnValues = {};
    unpivotColumns.map(col => newDependentColumnValues[col] = _.extend({}, dependentColumnValues[col]));
    if (newDependentColumnValues[newKey]) {
      newDependentColumnValues[newKey][index] = newValue;
    } else {
      newDependentColumnValues[newKey] = {};
      newDependentColumnValues[newKey][index] = newValue;
    }
    dependentColumns.map((dep, i) => (newDependentColumnValues[newKey][i] ? _.noop() : newDependentColumnValues[newKey][i] = null));
    this.setState({ dependentColumnValues: newDependentColumnValues });
  };

  onDeleteDependentValues = (deletedValueKey, index) => {
    const { dependentColumnValues } = this.state;
    const { unpivotColumns } = this.props;
    const newDependentColumnValues = {};
    unpivotColumns.map(col => newDependentColumnValues[col] = _.extend({}, dependentColumnValues[col]));
    newDependentColumnValues[deletedValueKey][index] = null;
    this.setState({ dependentColumnValues: newDependentColumnValues });
  };

  renderAllUnifiedAttributesPanel = () => {
    const { options, unpivotColumns } = this.props;
    const unifiedAttributeList = options
      .filter(ua => ua.toLowerCase().indexOf(this.state.searchString.toLowerCase()) > -1)
      .filter(ua => !unpivotColumns.includes(ua));
    return (
      <div className={style.unpivotAllUAs}>
        <div>
          Attributes
        </div>
        <div className={style.unpivotAllSearch}>
          <SearchBox value={this.state.searchString} onSearch={searchString => this.setState({ searchString })} searchOnKeyup />
        </div>
        <div className={style.unpivotAllAttributesPanelBody}>
          {unifiedAttributeList.map(ua =>
            (<div className={style.unpivotItemPillContainers}>
              <UnpivotItem
                key={ua}
                attributeName={ua}
              />
            </div>),
          )}
        </div>
      </div>
    );
  };

  renderVariableAttributesPanel = () => {
    const { unpivotColumns, variableColumn } = this.props;
    const multiDep = this.state.addDependentCol || this.state.dependentColumns.length > 1;
    return (
      <div className={classNames(style.unpivotVariableAttributes, { [style.unpivotVariableAttributesMultiDep]: multiDep })}>
        <div className={style.unpivotVariableName}>
          Variable attribute
        </div>
        <div className={style.unpivotVariableColumn}>
          {variableColumn}
        </div>
        <div className={style.unpivotPanelBody}>
          {unpivotColumns.map(va =>
            (<div className={style.unpivotItemPillContainers} key={va}>
              <TooltipTrigger
                trigger={['hover']}
                placement="right"
                content={va}
              >
                <span className={style.unpivotPills}>{va}</span>
              </TooltipTrigger>
            </div>),
          )}
        </div>
      </div>
    );
  };

  renderValueAttributesPanel = () => {
    const { unpivotColumns, valueColumn } = this.props;
    const multiDep = this.state.addDependentCol || this.state.dependentColumns.length > 1;
    return (
      <div className={classNames(style.unpivotValueAttributes, { [style.unpivotValueAttributesMultiDep]: multiDep })}>
        <div className={style.unpivotValueName}>
          Value attribute
        </div>
        <div className={style.unpivotValueColumn}>
          {valueColumn}
        </div>
        <div className={style.unpivotPanelBody}>
          {unpivotColumns.map(va => <div className={style.unpivotValues} key={va}>value from {va}</div>)}
        </div>
      </div>
    );
  };

  renderDependentDataPanel = (index) => {
    const { options, unpivotColumns, valueColumn, variableColumn } = this.props;
    const { addDependentCol, dependentColumnValues, dependentColumns } = this.state;
    const multiDep = addDependentCol || dependentColumns.length > 1;
    const allOptions = options
      // filter out used depCol names, but not THIS depCol name
      .filter(option => !_.includes(dependentColumns.filter((col, i) => (i !== index)), option))
      .filter(option => !_.includes(unpivotColumns, option))
      .filter(option => valueColumn !== option)
      .filter(option => variableColumn !== option)
      .map(option => ({ value: option, label: option }));
    return (
      <div className={multiDep ? style.unpivotDependentCols : style.unpivotDependentColOnly}>
        <div>
          Dependent data column
          <Button
            buttonType="Link"
            onClick={() => ((addDependentCol || dependentColumns.length > 1) ? this.onRemove(index) : this.onClear())}
          >
            {addDependentCol || dependentColumns.length > 1 ? 'x' : 'clear'}
          </Button>
        </div>
        <div>
          <Selector
            allowCreate={false}
            options={allOptions}
            className={[style.val, style.grow].join(' ')}
            value={dependentColumns[index]}
            clearable={false}
            onChange={option => {
              const dependentCols = [];
              this.state.dependentColumns.map(col => dependentCols.push(col));
              dependentCols[index] = option.value;
              this.setState({ dependentColumns: dependentCols });
              if (addDependentCol && index === _.uniq(_.compact(dependentColumns)).length) {
                this.setState({
                  addDependentCol: false,
                });
              }
            }}
          />
        </div>
        <div className={style.unpivotPanelBody}>
          {unpivotColumns.map(da =>
            (<UnpivotTarget
              key={da}
              className={style.unpivotTargets}
              dependentAttribute={dependentColumnValues[da] ? dependentColumnValues[da][index] : null}
              index={index}
              onChange={this.onChangeDependentValues}
              onDelete={this.onDeleteDependentValues}
              unpivotColumn={da}
            />),
          )}
        </div>
      </div>
    );
  };

  renderUnpivotDialog = () => {
    const { addDependentCol, dependentColumns } = this.state;
    const { options, unpivotColumns } = this.props;
    const unifiedAttributes = options
      .filter(ua => !unpivotColumns.includes(ua));
    return (
      <div className={style.unpivotDialogContainer}>
        <div>
          Drag attributes from the attribute list into the corresponding dependent data attribute.
          <PopoverTrigger
            trigger={['hover', 'click']}
            className={transformStyle.tooltipBackground}
            placement="right"
            content={
              <div className={transformStyle.txInstructions}>
                <div className={transformStyle.txInstructionsTitle}><span>Dependent Data Column</span></div>
                <div className={transformStyle.txInstructionsInfo}>
                  <div>Unpivot related columns.</div>
                </div>
                <div>
                  <img
                    src={unpivotInfo}
                  />
                </div>
              </div>
            }
          >
            <TamrIcon iconName="info-outline" size={14} />
          </PopoverTrigger>
          <Button
            buttonType="Link"
            className={style.addDependentButton}
            disabled={addDependentCol || _.isEmpty(_.compact(dependentColumns)) || !(dependentColumns.length < unifiedAttributes.length)}
            onClick={() => this.setState({ addDependentCol: true })}
          >
            add dependent column
          </Button>
        </div>
        <div className={style.unpivotBody}>
          {this.renderAllUnifiedAttributesPanel()}
          <div className={style.unpivotRows}>
            <div>
              {this.renderVariableAttributesPanel()}
            </div>
            <div>
              {this.renderValueAttributesPanel()}
            </div>
            <div className={style.unpivotDependentColumnsContainer}>
              {!_.isEmpty(_.compact(dependentColumns))
                ? (_.uniq(_.compact(dependentColumns)).map((col, i) =>
                  (<div className={style.unpivotDependentColumns}>
                    {this.renderDependentDataPanel(i)}
                  </div>)))
                : (<div className={style.unpivotDependentColumns}>
                  {this.renderDependentDataPanel(0)}
                </div>)}
              {addDependentCol
                ? (<div className={style.unpivotDependentColumns}>
                  {this.renderDependentDataPanel(_.uniq(_.compact(dependentColumns)).length)}
                </div>)
                : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderDialog = () => {
    const { dependentColumns, dependentColumnValues, initialDependentColumns, initialDependentColumnValues, dialogVisible } = this.state;
    return (
      <Dialog
        className="column-order-selection-dialog"
        show={dialogVisible}
        onHide={this.onClose}
        title="Add dependent columns"
        dialogStyle={DialogStyle.FULL}
        body={this.renderUnpivotDialog()}
        footer={(
          <ButtonToolbar>
            <Button
              onClick={this.onClose}
              buttonType="Secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={this.onSubmit}
              disabled={_.isEqual(dependentColumns, initialDependentColumns) && _.isEqual(dependentColumnValues, initialDependentColumnValues)}
            >
              Update
            </Button>
          </ButtonToolbar>
        )}
      />
    );
  };

  render() {
    const { unpivotColumns, valueColumn, variableColumn, dependentColumns, erroring, errorMessage } = this.props;
    return (
      <div className={style.unpivotDialogLinkContainer}>
        <Button
          buttonType="Link"
          disabled={!unpivotColumns || !valueColumn || !variableColumn}
          onClick={this.onOpen}
          className={style.openDialogButton}
        >
          {_.isEmpty(dependentColumns) ? 'add dependent column' : 'edit dependent columns'}
        </Button>
        <TransformErrorsIcon className={style.dependentColErrors} errorMessages={erroring ? List.of(errorMessage) : List()} />
        {this.renderDialog()}
      </div>
    );
  }
});

export default UnpivotDialog;
