import ResourceSpecCollections from '../constants/ResourceSpecCollections';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import HierarchicalName from './HierarchicalName';

const STEP_DELIMITER = '/';

export default class ResourceSpec extends HierarchicalName {
  constructor(resource: string) {
    super({ spec: resource, stepDelimiter: STEP_DELIMITER });
  }

  toJSON() {
    return this.parts.join(this.stepDelimiter);
  }

  static fromJSON(json: string) {
    checkArg({ json }, ArgTypes.string);
    return new ResourceSpec(json);
  }

  static fromUsername(username: string) {
    checkArg({ username }, ArgTypes.string);
    return new ResourceSpec(`${ResourceSpecCollections.USERS}${STEP_DELIMITER}${username}`);
  }

  static fromUsergroup(usergroup: string) {
    checkArg({ usergroup }, ArgTypes.string);
    return new ResourceSpec(`${ResourceSpecCollections.USERGROUPS}${STEP_DELIMITER}${usergroup}`);
  }

  static fromProjectId(projectId: number) {
    checkArg({ usergroup: projectId }, ArgTypes.number);
    return new ResourceSpec(`${ResourceSpecCollections.PROJECTS}${STEP_DELIMITER}${projectId}`);
  }
}
