import { ArgTypes } from '../utils/ArgValidation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

export const AssignmentStatus = {
  SKIP: 'SKIP',
  PENDING: 'PENDING',
  ANSWERED: 'ANSWERED',
} as const;
export type AssignmentStatusE = typeof AssignmentStatus[keyof typeof AssignmentStatus];

export class RecordPairFeedbackAssignmentInfo extends getModelHelpers({
  status: { type: ArgTypes.valueIn(AssignmentStatus) },
  lastModified: { type: ArgTypes.timestamp },
}, 'RecordPairFeedbackAssignmentInfo')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class RecordPairFeedbackAssignmentInfoRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

export default RecordPairFeedbackAssignmentInfo;
