import classNames from 'classnames';
import { is } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import HoverTrigger from '../components/HoverTrigger';
import { attributeId } from '../models/AttributeId';
import UnifiedAttribute from '../models/UnifiedAttribute';
import MappingStatusIcons from './MappingStatusIcons';
import { UNIFIED_TOGGLE_FILTER_RELATED_ID } from './SchemaMappingActionTypes';
import SingleSuggestion from './SingleSuggestion';
import UnifiedAttributeMappingSelector from './UnifiedAttributeMappingSelector';
import style from './UnifiedAttributeMappingStatus.module.scss';

const UnifiedAttributeMappingStatus = _.compose(
  connect(({ schemaMapping: { unifiedFilterRelatedId } }, { attribute }) => {
    const id = attributeId(attribute);
    return {
      filterActivated: is(unifiedFilterRelatedId, id),
    };
  }, {
    onToggleFilterRelatedId: id => ({ type: UNIFIED_TOGGLE_FILTER_RELATED_ID, id }),
  }),
)(class UnifiedAttributeMappingStatus extends React.Component {
  static propTypes = {
    advertiseDropTarget: PropTypes.bool.isRequired,
    attribute: PropTypes.instanceOf(UnifiedAttribute).isRequired,
    canUserEdit: PropTypes.bool.isRequired,
    filterActivated: PropTypes.bool.isRequired,
    onToggleFilterRelatedId: PropTypes.func.isRequired,
  };

  state = { cellIsHovered: false };

  onMouseEnter = () => {
    this.setState({ cellIsHovered: true });
  };

  onMouseLeave = () => {
    this.setState({ cellIsHovered: false });
  };

  render() {
    const { attribute, filterActivated, advertiseDropTarget, canUserEdit, onToggleFilterRelatedId, isRemoved } = this.props;
    const { mappedAttributes, numMappings, numRecommendations } = attribute;
    const mappings = mappedAttributes;
    const hasSelector = !advertiseDropTarget;
    const className = classNames('mapping-status', 'unified', {
      'has-selector': hasSelector,
    });
    const visibleRecommendations = attribute.recommendations.filter(r => !mappings.contains(r.recommended));
    const singleSuggestion = visibleRecommendations.size === 1 ? visibleRecommendations.first().recommended.name : undefined;
    const status = (
      <div
        className={className}
        data-test-element="unified-mapping-status"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div className={classNames('message', { 'show-single-suggestion': !!singleSuggestion })}>
          {(() => {
            switch (true) {
              case !!advertiseDropTarget: return 'Drop to map';
              case isRemoved: return <span className={style.mappingChanged}>Mapping changed</span>;
              case numMappings === 1: return <span className="emphasis">{mappings.first().name}</span>;
              case numMappings > 1: return <span><span className="emphasis">{numMappings}</span> attributes</span>;
              default: return <span />;
            }
          })()}
        </div>
        {singleSuggestion ? <SingleSuggestion suggestedAttrName={singleSuggestion} /> : null}
        <MappingStatusIcons
          mappings={mappings}
          topRecommendations={visibleRecommendations}
          numRecommendations={numRecommendations}
          filterActivated={filterActivated}
          isHovered={this.state.cellIsHovered}
          onToggleFilterRelatedId={() => onToggleFilterRelatedId(attributeId(attribute))}
        />
      </div>
    );
    if (hasSelector && !isRemoved) {
      return (
        <HoverTrigger
          className="mapping-selector-container"
          placement="left"
          content={<UnifiedAttributeMappingSelector {...{ attribute, canUserEdit, onToggleFilterRelatedId }} />}
        >
          {status}
        </HoverTrigger>
      );
    }
    return status;
  }
});

export default UnifiedAttributeMappingStatus;
