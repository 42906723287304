import { List, Set } from 'immutable';
import uri from 'urijs';

import AuthorizationPolicy from '../models/AuthorizationPolicy';
import Document from '../models/doc/Document';
import Group from '../models/Group';
// @ts-expect-error
import License from '../models/License';
import MinimalAuthUser from '../models/MinimalAuthUser';
import ResourceSpec from '../models/ResourceSpec';
import User from '../models/User';
import UserCredentials from '../models/UserCredentials';
import UserRoles from '../models/UserRoles';
import { AuthUserSortFieldE } from '../users/utils/AuthUserSortField';
import QueryAuthUsersResponse from '../users/utils/QueryAuthUsersResponse';
import UserPreferences from '../users/utils/UserPreferences';
import {
  encodedHeaders,
  fetchAsResult,
  handleErrorsWithMessage,
  jsonHeaders,
  networkError,
  toJSON,
} from '../utils/Api';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { FetchResult, toFetchResult } from './FetchResult';
import ServiceProxy from './ServiceProxy';


export const getLicense = (): Promise<License> => {
  return fetch(ServiceProxy.user('/license'), { headers: jsonHeaders, cache: 'no-cache' })
    .then(handleErrorsWithMessage('Error loading license information'), networkError)
    .then(toJSON)
    .then(data => License.fromJSON(data));
};

// WARNING: this code is used to send authentication credentials to the server.  Please be very
// cautious about modifications
export const postAuthLogin = (username: string, password: string): Promise<MinimalAuthUser | undefined> => {
  checkArg({ username }, ArgTypes.string);
  checkArg({ password }, ArgTypes.string);
  return fetch(uri(ServiceProxy.user('/auth/minimal')).toString(), {
    headers: encodedHeaders,
    method: 'POST',
    // Building the request body like this since the API expects form parameters which are in the
    // form of a URL query and since IE does not support using URLSearchParams.
    // Using the URI.js library seems like the next less bad option
    body: uri('').setSearch({ username, password }).query(),
    cache: 'no-cache',
  })
    .then(handleErrorsWithMessage('Unable to log in'), networkError)
    .then(toJSON)
    .then(data => MinimalAuthUser.fromJSON(data));
};

export const deleteAuthLogout = (): Promise<Response> => {
  return fetch(ServiceProxy.user('/auth/token'), { headers: jsonHeaders, method: 'DELETE' })
    .then(handleErrorsWithMessage('Error logging out'), networkError);
};

export const getUsersFullQuery = ({ query, pageNumber, pageSize, sortFieldName, sortDirection }: {
  query: string
  pageNumber: number
  pageSize: number
  sortFieldName: AuthUserSortFieldE | null | undefined
  sortDirection: 'ASCENDING' | 'DESCENDING' | null | undefined
}): Promise<QueryAuthUsersResponse> => {
  checkArg({ query }, ArgTypes.string);
  checkArg({ pageNumber }, ArgTypes.wholeNumber);
  checkArg({ pageSize }, ArgTypes.positiveInteger);
  checkArg({ sortDirection }, ArgTypes.nullable(ArgTypes.valueIn(['ASCENDING', 'DESCENDING'])));
  return fetch(uri(ServiceProxy.user('/auth-users/query')).query({
    query,
    offset: pageNumber,
    limit: pageSize,
    sortFieldName,
    sortDirection,
  }).toString(), {
    headers: jsonHeaders,
    cache: 'no-cache',
  })
    .then(handleErrorsWithMessage('Unable to get full users'), networkError)
    .then(toJSON)
    .then(data => QueryAuthUsersResponse.fromJSON(data));
};

export const getBulkPrivileges = (member: string, privilege: string, resources: List<string>): Promise<List<ResourceSpec>> => {
  checkArg({ member }, ArgTypes.string);
  checkArg({ privilege }, ArgTypes.string);
  checkArg({ resources }, ArgTypes.Immutable.list.of(ArgTypes.string));
  return fetch(ServiceProxy.user('/authorization/bulk'), {
    headers: jsonHeaders,
    method: 'POST',
    body: JSON.stringify({ member, privilege, resources: resources.toArray() }),
  })
    .then(handleErrorsWithMessage('Unable to fetch authorization privileges'), networkError)
    .then(toJSON)
    .then(data => List(data).map(ResourceSpec.fromJSON));
};

export const getAllGroups = ({ useCache }: { useCache: boolean }): Promise<List<Document<Group>>> => {
  checkArg({ useCache }, ArgTypes.bool);
  return fetch(ServiceProxy.user('/groups'), { headers: jsonHeaders, cache: useCache ? 'default' : 'no-cache' })
    .then(handleErrorsWithMessage('Unable to get all groups'), networkError)
    .then(toJSON)
    .then(data => List(data).map(groupDoc => Document.fromJSON(groupDoc, Group.fromJSON)));
};

/**
 * Upsert a group with the given group. Group names are unchangable.
 */
export const postGroup = (group: Group): Promise<Document<Group>> => {
  checkArg({ group }, Group.argType);
  return fetch(ServiceProxy.user('/groups'), {
    headers: jsonHeaders,
    method: 'POST',
    body: JSON.stringify(group),
  })
    .then(handleErrorsWithMessage(`Unable to update group: ${group}`), networkError)
    .then(toJSON)
    .then(data => Document.fromJSON(data, Group.fromJSON));
};

/**
 * Deletes a single group, and removes associations to users and policies, based on the group's
 * unique ID.
 */
export const deleteGroup = (id: number): Promise<Response> => {
  checkArg({ id }, ArgTypes.positiveInteger);
  return fetch(ServiceProxy.user('/groups/' + id), { headers: jsonHeaders, method: 'DELETE' })
    .then(handleErrorsWithMessage('Unable to delete group'), networkError);
};

export const getFullUsers = (): Promise<List<MinimalAuthUser>> => {
  // This is only for Admin users
  return fetch(ServiceProxy.user('/minimal-auth-users'), {
    cache: 'no-cache',
  })
    .then(handleErrorsWithMessage('Unable to get users'), networkError)
    .then(toJSON)
    .then(data => List(data).map(MinimalAuthUser.fromJSON));
};

export const getMinimumAuthUsers = (): Promise<List<MinimalAuthUser>> => {
  // return Promise.resolve(List<MinimalAuthUser>());
  return fetch(ServiceProxy.user('/users'), {
    cache: 'no-cache',
  })
    .then(handleErrorsWithMessage('Unable to get users'), networkError)
    .then(toJSON)
    .then(data => List(data).map((user:{data: User}) => MinimalAuthUser.fromJSON({ user: user.data, username: user.data?.username })));
};

export const getUsers = (isAdmin: boolean): Promise<List<MinimalAuthUser>> => {
  return isAdmin ? getFullUsers() : getMinimumAuthUsers();
};

export const getAllPolicies = (): Promise<List<Document<AuthorizationPolicy>>> => {
  return fetch(ServiceProxy.user('/policies'), { headers: jsonHeaders })
    .then(handleErrorsWithMessage('Unable to get all policies'), networkError)
    .then(toJSON)
    .then(data => List(data).map(policyDoc => Document.fromJSON(policyDoc, AuthorizationPolicy.fromJSON)));
};

export const postPolicy = (policy: AuthorizationPolicy): Promise<Document<AuthorizationPolicy>> => {
  checkArg({ policy }, AuthorizationPolicy.argType);
  return fetch(ServiceProxy.user('/policies'), {
    headers: jsonHeaders,
    method: 'POST',
    body: JSON.stringify(policy),
  })
    .then(handleErrorsWithMessage('Unable to create policy'), networkError)
    .then(toJSON)
    .then(data => Document.fromJSON(data, AuthorizationPolicy.fromJSON));
};

export const deletePolicy = (policyId: number): Promise<Response> => {
  checkArg({ policyId }, ArgTypes.positiveInteger);
  return fetch(ServiceProxy.user('/policies/' + policyId), { headers: jsonHeaders, method: 'DELETE' })
    .then(handleErrorsWithMessage('Unable to delete policy'), networkError);
};

export const putPolicy = (policyId: number, policy: AuthorizationPolicy): Promise<Document<AuthorizationPolicy>> => {
  checkArg({ policyId }, ArgTypes.positiveInteger);
  checkArg({ policy }, AuthorizationPolicy.argType);
  return fetch(ServiceProxy.user('/policies/' + policyId), {
    headers: jsonHeaders,
    method: 'PUT',
    body: JSON.stringify(policy),
  })
    .then(handleErrorsWithMessage('Unable to update policy'), networkError)
    .then(toJSON)
    .then(data => Document.fromJSON(data, AuthorizationPolicy.fromJSON));
};

export const postUserCreateOrUpdate = (user: User): Promise<Document<User>> => {
  checkArg({ user }, User.argType);
  return fetch(ServiceProxy.user('/users'), {
    headers: jsonHeaders,
    method: 'POST',
    body: JSON.stringify(user.toJSON()),
  })
    .then(handleErrorsWithMessage('Unable to post user'), networkError)
    .then(toJSON)
    .then(data => Document.fromJSON(data, User.fromJSON));
};

export const getUserRoles = (username: string): Promise<Document<UserRoles>> => {
  checkArg({ username }, ArgTypes.string);
  return fetch(ServiceProxy.user(`/user-roles/named/${username}`), {
    headers: jsonHeaders,
    method: 'GET',
    cache: 'no-cache',
  })
    .then(handleErrorsWithMessage('Unable to get user roles'), networkError)
    .then(toJSON)
    .then(data => Document.fromJSON(data, UserRoles.fromJSON));
};

export const putUserRolesCreateOrUpdate = ({ username, groups, admin }: { username: string, groups: Set<string>, admin: boolean }): Promise<Document<UserRoles>> => {
  checkArg({ username }, ArgTypes.string);
  checkArg({ groups }, ArgTypes.Immutable.set.of(ArgTypes.string));
  checkArg({ admin }, ArgTypes.bool);
  return fetch(ServiceProxy.user('/user-roles'), {
    headers: jsonHeaders,
    method: 'PUT',
    body: JSON.stringify({ username, groups, admin }),
  })
    .then(handleErrorsWithMessage('Unable to get user roles'), networkError)
    .then(toJSON)
    .then(data => Document.fromJSON(data, UserRoles.fromJSON));
};

export const postUserCredentialsCreate = (userCredentials: UserCredentials): Promise<FetchResult<undefined>> => {
  checkArg({ userCredentials }, UserCredentials.argType);
  return fetchAsResult(ServiceProxy.user('/user-credentials'), {
    headers: jsonHeaders,
    method: 'POST',
    body: JSON.stringify(userCredentials.toJSON()),
  }).then(toFetchResult());
};

export const putUserCredentialsUpdate = ({ username, userCredentials }: { username: string, userCredentials: UserCredentials }): Promise<FetchResult<undefined>> => {
  checkArg({ username }, ArgTypes.string);
  checkArg({ userCredentials }, UserCredentials.argType);
  return fetchAsResult(ServiceProxy.user(`/user-credentials/${username}`), {
    headers: jsonHeaders,
    method: 'PUT',
    body: JSON.stringify(userCredentials.toJSON()),
  }).then(toFetchResult());
};

export const deleteUserCredentials = ({ username }: { username: string }): Promise<FetchResult<undefined>> => {
  checkArg({ username }, ArgTypes.string);
  return fetchAsResult(ServiceProxy.user(`/user-credentials/${username}`), {
    headers: jsonHeaders,
    method: 'DELETE',
  }).then(toFetchResult());
};

export const postChangeActiveStatus = (usernames: Set<string>, changeActiveStatusActivate: boolean): Promise<List<Document<User>>> => {
  checkArg({ usernames }, ArgTypes.Immutable.set.of(ArgTypes.string));
  checkArg({ changeActiveStatusActivate }, ArgTypes.bool);
  return fetch(uri(ServiceProxy.user('/users/changeActiveStatus')).query({ activate: changeActiveStatusActivate }).toString(), {
    headers: jsonHeaders,
    method: 'POST',
    body: JSON.stringify(usernames),
  })
    .then(handleErrorsWithMessage('Unable to change active status'), networkError)
    .then(toJSON)
    .then(data => List(data).map(usersDoc => Document.fromJSON(usersDoc, User.fromJSON)));
};

export function postUserPreference(user: MinimalAuthUser): Promise<Document<UserPreferences>> {
  return fetch(ServiceProxy.user('/user-preferences'), {
    headers: jsonHeaders,
    method: 'POST',
    body: JSON.stringify({ username: user.username, preferences: user.preferences }),
  })
    .then(handleErrorsWithMessage('Unable to post user preference'), networkError)
    .then(toJSON)
    .then(data => Document.fromJSON(data, UserPreferences.fromJSON));
}

export function getUserPreference(username: string): Promise<Document<UserPreferences>> {
  return fetch(ServiceProxy.user(`/user-preferences/named/${username}`), {
    headers: jsonHeaders,
    method: 'get',
  })
    .then(handleErrorsWithMessage('Unable to get user preference'), networkError)
    .then(toJSON)
    .then(data => Document.fromJSON(data, UserPreferences.fromJSON));
}

export const getUsersWithAccess = (privilege: string, resource: string): Promise<List<User>> => {
  return fetch(ServiceProxy.user(`/users/withAccess?resource=${resource}&privilege=${privilege}`), {
    cache: 'no-cache',
  })
    .then(handleErrorsWithMessage(`Unable to get users with ${privilege} on ${resource}`), networkError)
    .then(toJSON)
    .then(data => {
      return List(data).map((doc: Document<User>) => User.fromJSON(doc.data)).filter(x => x.username !== 'system');
    }).catch(() => {
      return List();
    });
};
