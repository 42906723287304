import { List, Map } from 'immutable';
import L, { LatLngBounds, LatLngTuple } from 'leaflet';
import React, { useRef, useState } from 'react';
import {
  LayerGroup,
  LayersControl,
  Map as LeafletMap,
  ScaleControl,
  TileLayer,
  ZoomControl,
} from 'react-leaflet';
// @ts-expect-error there's no corresponding TS library yet
import Control from 'react-leaflet-control';
import ReactResizeDetector from 'react-resize-detector';

import Button from '../components/Button';
import CenterContent from '../components/CenterContent';
import LoadingPanel from '../components/LoadingPanel';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import TileServer from '../models/TileServer';
import styles from './GeospatialMap.module.scss';
import WMTSRestTileLayer from './WMTSRestTileLayer';

// Overwrite _initControlPos to introduce a 'topcenter' position for leaflet controls.
// @ts-expect-error will be resolved when react-leaflet-control has a corresponding TS library
L.Map.prototype._initControlPos = (function (...rest) {
  return function () {
    rest[0].apply(this, rest);
    this._controlCorners.topcenter = L.DomUtil.create('div', 'leaflet-center', this._controlContainer);
  };
  // @ts-expect-error will be resolved when react-leaflet-control has a corresponding TS library
}(L.Map.prototype._initControlPos));

/**
 * Extract bound information from leaflet bounds
 */
function extractLeafletBounds(bounds: LatLngBounds): number[][] {
  // we extract in this format because when we actually make the API request to ES, we flip
  // the north and south, so that we will end up with NW/SE points that are expected by ES.
  // TODO: centralize the ordering of points in the bounds so that it's not confusing
  return [[bounds.getWest(), bounds.getSouth()], [bounds.getEast(), bounds.getNorth()]];
}

function isValidBounds(bounds: number[][]): bounds is [LatLngTuple, LatLngTuple] {
  return !!bounds && bounds.length === 2
    && !!bounds[0] && bounds[0].length === 2 && !!bounds[0][0] && !!bounds[0][1]
    && !!bounds[1] && bounds[1].length === 2 && !!bounds[1][0] && !!bounds[1][1];
}

function isValidCenter(center: number[]): center is LatLngTuple {
  return !!center && center.length === 2 && !!center[0] && !!center[1];
}

function intOptionOrDefault(options: Map<string, string>, key: string, defaultVal: string) {
  return parseInt(options.get(key, defaultVal), 10);
}

export const NoGeoMessage: React.FC = () => (
  <span className={styles.emptyGeoMessage}>
    <CenterContent>No Geometry features specified.</CenterContent>
  </span>
);

/**
 * Shows a map view of the Geospatial data.
 *
 * @param tileServers              list of tileServers
 * @param onCloseMap               function that gets triggered when the map is closed
 * @param onLoad                   function that gets triggered when the map finishes loading.
 * @param onMapMoveEnd             function that gets triggered when the map finishes moving (panning/zooming)
 * @param bounds                   the bounding box for the map
 * @param center                   the center of the map
 * @param loadingRows              indicator of whether the client is still waiting to load
 *                                 geospatial records from the server
 * @param showGeospatialOverlay    If TRUE, display the geospatial features in the overlay.
 * @param onToggleGeospatialOverlay    Event handler when toggling the geospatial overlay button.
 * @param geoFeatureLoadingWarningMsg  the component that renders any warning message related to
 *                                     loading geospatial features. Note that this must be in form
 *                                     of a function that returns a <Component />
 * @param disableOverlayToggleMsg  message to be displayed when the toggle for overlay is disabled
 * @param children                 the component that renders the main geospatial features
 * @param overlayChildren          the component that renders the overlay geospatial features.
 *                                 Note that this must be in the form of a function that returns a
 *                                 <Component />
 */
function GeospatialMap({
  tileServers,
  onCloseMap,
  onLoad,
  onMapMoveEnd,
  bounds,
  center,
  loadingRows,
  showGeospatialOverlay,
  onToggleGeospatialOverlay,
  geoFeatureLoadingWarningMsg,
  disableOverlayToggleMsg,
  children,
  overlayChildren,
}: {
  tileServers: List<TileServer>,
  onCloseMap?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  onLoad?: (bounds: number[][]) => void,
  onMapMoveEnd?: (bounds: number[][]) => void,
  bounds: number[][],
  center: number[],
  loadingRows?: boolean,
  showGeospatialOverlay?: boolean,
  onToggleGeospatialOverlay?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  geoFeatureLoadingWarningMsg?: React.ReactNode,
  disableOverlayToggleMsg?: string,
  children?: React.ReactNode,
  overlayChildren?: () => React.ReactNode,
}) {
  if (!isValidBounds(bounds)) {
    return loadingRows ? <LoadingPanel /> : <NoGeoMessage />;
  }

  const firstTileServer = tileServers.get(0)?.name || '';

  const [tileLoadError, setTileLoadError] = useState(false);
  const [authUrl, setAuthUrl] = useState('');
  const [selectedTileServer, setSelectedTileServer] = useState(firstTileServer);

  const tileLoadErrorBody = tileLoadError ? (
    <div className={styles.tileError}>
      <TamrIcon className={styles.warningIcon} iconName="warning" size={14} />
      <span>
        {'Could not connect to the tile server. '}
        {authUrl ?
          (
            <span>
              <a className={styles.authLink} href={authUrl} target="_blank" rel="noopener noreferrer">Click here</a>
              {' to update authentication.'}
            </span>
          ) : null}
      </span>
    </div>
  ) : null;

  const mapRef = useRef<LeafletMap>(null);
  const overlayRef = useRef<LayerGroup>(null);

  const closeButton = onCloseMap ? (
    <Control position="topright">
      <Button
        className={styles.customMapButton}
        buttonType="Secondary"
        icon="highlight-off"
        iconSize={16}
        onClick={onCloseMap} />
    </Control>) : null;
  const tileLayers = tileServers.map((ts) => (
    <LayersControl.BaseLayer name={ts.name} checked={ts.name === selectedTileServer} key={'tileserver' + Math.random()}>
      {ts.wmts ?
        <WMTSRestTileLayer
          // @ts-expect-error not sure how to resolve this
          url={ts.urlTemplate}
          onLoad={() => { return onLoad && mapRef.current ? onLoad(extractLeafletBounds(mapRef.current.leafletElement.getBounds())) : null; }}
          onTileLoadStart={() => setSelectedTileServer(ts.name)}
          onTileLoad={() => setTileLoadError(false)}
          onTileError={() => {
            setTileLoadError(true);
            setAuthUrl(ts.authUrl || '');
          }}
          layer={ts.options.get('layer')}
          tilematrixSet={ts.options.get('tilematrixSet')}
          format={ts.options.get('format')}
          maxZoom={intOptionOrDefault(ts.options, 'maxZoom', '18')}
          maxNativeZoom={intOptionOrDefault(ts.options, 'maxNativeZoom', '18')}
          minZoom={intOptionOrDefault(ts.options, 'minZoom', '0')}
          minNativeZoom={intOptionOrDefault(ts.options, 'minNativeZoom', '0')}
        /> :
        <TileLayer
          url={ts.urlTemplate}
          onLoad={() => { return onLoad && mapRef.current ? onLoad(extractLeafletBounds(mapRef.current.leafletElement.getBounds())) : null; }}
          onTileLoadStart={() => setSelectedTileServer(ts.name)}
          onTileLoad={() => setTileLoadError(false)}
          onTileError={() => {
            setTileLoadError(true);
            setAuthUrl(ts.authUrl || '');
          }}
          maxZoom={intOptionOrDefault(ts.options, 'maxZoom', '18')}
          maxNativeZoom={intOptionOrDefault(ts.options, 'maxNativeZoom', '18')}
          minZoom={intOptionOrDefault(ts.options, 'minZoom', '0')}
          minNativeZoom={intOptionOrDefault(ts.options, 'minNativeZoom', '0')}
        />
      }
    </LayersControl.BaseLayer>
  ));

  const displayBox = tileLoadError || geoFeatureLoadingWarningMsg ? (
    <Control position="topcenter">
      {tileLoadErrorBody}
      {geoFeatureLoadingWarningMsg}
    </Control>
  ) : null;

  const overlay = overlayChildren
    ? (<LayerGroup ref={overlayRef} key={'overlay-' + Math.random()}>{overlayChildren()}</LayerGroup>)
    : null;

  const map = (
    <div className={styles.dialogBody}>
      <ReactResizeDetector handleWidth handleHeight onResize={() => mapRef.current && mapRef.current.leafletElement.invalidateSize()} />
      {loadingRows ? <LoadingPanel /> : null}
      <LeafletMap
        ref={mapRef}
        className={styles.map}
        center={isValidCenter(center) ? center : undefined}
        bounds={bounds}
        zoomControl={false}
        onmoveend={() => { return onMapMoveEnd && mapRef.current ? onMapMoveEnd(extractLeafletBounds(mapRef.current.leafletElement.getBounds())) : null; }}
      >
        {closeButton}
        {displayBox}
        <Control position="topleft">
          <Button
            className={styles.customMapButton}
            buttonType="Secondary"
            icon="my-location"
            iconSize={16}
            onClick={() => mapRef.current && mapRef.current.leafletElement.flyToBounds(bounds)} />
        </Control>
        <ZoomControl />
        { children }
        <ScaleControl />
        { !tileServers.isEmpty()
          ? <LayersControl position="topleft">{tileLayers}</LayersControl>
          : <TileLayer url="" />
        }
        { showGeospatialOverlay && !disableOverlayToggleMsg ? overlay : null }
        { onCloseMap ? (<Control position="topleft">
          <TooltipTrigger
            placement="top"
            content={disableOverlayToggleMsg || `${showGeospatialOverlay ? 'Hide' : 'Show'} geospatial features from other clusters`}>
            <Button
              className={styles.customMapButton}
              buttonType="Secondary"
              icon={showGeospatialOverlay ? 'geospatial-toggle-on' : 'geospatial-toggle-off'}
              iconSize={16}
              disabled={!!disableOverlayToggleMsg}
              onClick={onToggleGeospatialOverlay}
            />
          </TooltipTrigger>
        </Control>) : null }
      </LeafletMap>
    </div>
  );

  // When a leaflet map is left open and rendered, without being removed, it can
  // re-render with a zoom of 0, and ultimately display a broken map.  To avoid this,
  // check for a zoom of 0 and do not display the broken map. This tends to occur when
  // leaving a page, so having the user re-select features to display seems reasonable.
  if (mapRef && mapRef.current && mapRef.current.leafletElement && mapRef.current.leafletElement.getZoom() === 0) {
    return <NoGeoMessage />;
  }
  return map;
}

export default GeospatialMap;
