import { ArgTypes, checkArg } from './ArgValidation';
import { $TSFixMe } from './typescript';

/**
 * This function accepts an array of Promise-producing lambdas and runs them all sequentially
 *   as in, once the first one resolves successfully, the second one is executed, so forth
 * @return A Promise that resolves when all the lambdas have been successfully executed
 */
export default (executionLambdas: $TSFixMe) => {
  checkArg({ executionLambdas }, ArgTypes.array.of(ArgTypes.func));
  const resolvers: $TSFixMe = [];
  const rejecters: $TSFixMe = [];
  const promises: $TSFixMe = [];
  // set up and store, by index, the promises and their resolver / reject functions
  // these will be returned immediately and acts as a proxy for the execution promises
  // which won't be available until we actually execute them
  executionLambdas.forEach(() => {
    promises.push(new Promise((resolve, reject) => {
      resolvers.push(resolve);
      rejecters.push(reject);
    }));
  });
  // for everything but the first, set up the mechanism to execute once the previous execution succeeds
  // also set up the promises to succeed / fail based on whether the execution succeeds or fails
  const submitJob = (index: number) => executionLambdas[index]()
    .then((result: $TSFixMe) => resolvers[index](result))
    .catch((result: $TSFixMe) => rejecters[index](result));
  for (let i = 1; i < executionLambdas.length; i++) {
    promises[i - 1].then(() => submitJob(i));
  }
  // initiate the first execution, which ought to set off the chain reaction set up above
  submitJob(0);

  return Promise.all(promises);
};
