import { List, Map } from 'immutable';
import $ from 'jquery';

import { SHOW } from '../errorDialog/ErrorDialogActionTypes';
import AssignmentSummary from '../models/AssignmentSummary';
import ModelPerformance from '../models/ModelPerformance';
import ProcurementTaxonomy from '../models/ProcurementTaxonomy';
import { ArgTypes, checkArg, checkReturn } from '../utils/ArgValidation';
import {
  getActiveRecipeWithStatus,
  getUnifiedDatasetId,
  getUnifiedDatasetName,
} from '../utils/Selectors';
import AverageConfidenceRecord from './categorization/AverageConfidenceRecord';
import { getFilterInfo } from './CategorizationDashboardStore';

const doGetAverageConfidences = checkReturn(
  ArgTypes.deferred.withResolution(ArgTypes.Immutable.map.of(
    AverageConfidenceRecord.argType,
    ArgTypes.Immutable.list.of(ArgTypes.string)),
  ),
  (unifiedDatasetName, taxonomy) => {
    checkArg({ unifiedDatasetName }, ArgTypes.string);
    checkArg({ taxonomy }, ArgTypes.instanceOf(ProcurementTaxonomy));
    return $.ajax({
      method: 'POST',
      url: SERVICES.dataset(`/datasets/${unifiedDatasetName}_classifications_average_confidences/records`),
      contentType: 'application/json',
      data: taxonomy.categories.map(c => JSON.stringify({ classificationPath: c.path })).join('\n'),
      dataType: 'text',
    })
      .then(response => {
        // response is a string of newline-separated record strings
        const parsed = response.split(/\r?\n/).reduce((result, record) => {
          const json = JSON.parse(record);
          return result.set(List(json.classificationPath), AverageConfidenceRecord.fromJSON(json));
        }, Map());
        return parsed;
      });
  });

const fetchTaxonomyStats = () => (dispatch, getState) => {
  const state = getState();
  const activeRecipe = getActiveRecipeWithStatus(state);
  const recipeId = activeRecipe.recipe.id.id;
  const unifiedDatasetName = getUnifiedDatasetName(state);
  const unifiedDatasetId = getUnifiedDatasetId(state);
  const loadedFilterInfo = getFilterInfo(state);
  $.ajax({
    url: SERVICES.procure(`/procurement/categories/${unifiedDatasetId}`),
    cache: false,
  })
    .fail(() => dispatch({ type: 'CategorizationDashboard.fetchTaxonomyStatsError' }))
    .then(data => {
      const taxonomy = ProcurementTaxonomy.fromJSON(data);
      dispatch({
        type: 'CategorizationDashboard.fetchTaxonomyStatsCompleted',
        recipeId,
        loadedFilterInfo,
        taxonomy,
        categoriesUsed: taxonomy.categories.filter(c => c.manualCategorizations.count > 0).length,
        totalCategories: taxonomy.categories.length,
      });
      return taxonomy;
    })
    .then(taxonomy => doGetAverageConfidences(unifiedDatasetName, taxonomy))
    .fail(() => dispatch({ type: 'CategorizationDashboard.fetchAverageConfidencesFailed' }))
    .then(averageConfidences => dispatch({ type: 'CategorizationDashboard.fetchAverageConfidencesCompleted', averageConfidences }));
};

const fetchFeedbackAssignments = () => (dispatch, getState) => {
  const state = getState();
  const activeRecipe = getActiveRecipeWithStatus(state);
  const recipeId = activeRecipe.recipe.id.id;
  const unifiedDatasetName = getUnifiedDatasetName(state);
  const loadedFilterInfo = getFilterInfo(state);
  $.ajax({
    url: SERVICES.procure('/feedback/summary-assignment'),
    data: { dataset: unifiedDatasetName },
    success: data => dispatch({
      type: 'CategorizationDashboard.fetchAssignmentStatsCompleted',
      recipeId,
      loadedFilterInfo,
      assignmentSummary: List(data).map(d => new AssignmentSummary(d)),
    }),
  });
};

const fetchTransactionsInfo = () => (dispatch, getState) => {
  const state = getState();
  const activeRecipe = getActiveRecipeWithStatus(state);
  const recipeId = activeRecipe.recipe.id.id;
  const unifiedDatasetName = getUnifiedDatasetName(state);
  const loadedFilterInfo = getFilterInfo(state);
  $.when(
    $.ajax({
      url: SERVICES.procure('/transactions/summary'),
      data: { tag: `recipe_${recipeId}`, dataset: unifiedDatasetName },
    }),
    $.ajax({
      url: SERVICES.procure(`/transactions/${unifiedDatasetName}`),
      data: { labelAgreesWithTamr: true, limit: 0 },
    }),
  ).then(([{ numberOpen, numberToVerify, numberToVerifyAgree, numberToVerifyDisagree, numberVerified, performanceOverTime }], [{ total: totalAgree }]) => {
    dispatch({
      type: 'CategorizationDashboard.fetchStatsCompleted',
      recipeId,
      loadedFilterInfo,
      numberOpen,
      numberToVerify,
      numberToVerifyAgree,
      numberToVerifyDisagree,
      numberVerified,
      percentAgree: numberVerified ? Math.floor(100.0 * totalAgree / numberVerified) : 0,
      performanceOverTime: List(performanceOverTime).map(d => new ModelPerformance(d)),
    });
  }, response => {
    dispatch({ type: 'CategorizationDashboard.fetchStatsFailed' });
    dispatch({ type: SHOW, detail: 'Error loading project stats', response });
  });
};

export const fetchStats = () => dispatch => {
  dispatch({ type: 'CategorizationDashboard.fetchStats' });
  dispatch(fetchTaxonomyStats());
  dispatch(fetchFeedbackAssignments());
  dispatch(fetchTransactionsInfo());
};
