import './CategoryTree.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';

import ProcurementCategory from '../models/ProcurementCategory';
import ProcurementTaxonomy from '../models/ProcurementTaxonomy';

class CategoryTree extends React.Component {
  static propTypes = {
    category: PropTypes.instanceOf(ProcurementCategory),
    onSidebarCategoryClick: PropTypes.func,
    taxonomy: PropTypes.instanceOf(ProcurementTaxonomy),
    title: PropTypes.string,
  };

  onSidebarCategoryClick = (path) => {
    if (this.props.onSidebarCategoryClick) {
      const categoryId = this.props.taxonomy.findCategoryByPath(path).categoryId;
      this.props.onSidebarCategoryClick(categoryId);
    }
  };

  renderTitle = () => {
    if (this.props.title) {
      return (
        <div className="category-tree-title">
          {this.props.title}
        </div>
      );
    }
  };

  render() {
    if (!this.props.category || !this.props.taxonomy) {
      return <div />;
    }
    const pathLength = this.props.category.path.size;
    return (
      <div className="category-tree-component">
        {this.renderTitle()}
        {this.props.category.path.map((pathSegment, segmentDepth) => {
          const nodeClassNames = classNames({
            'category-tree-node': true,
            ['depth-' + segmentDepth]: true,
            leaf: segmentDepth === pathLength - 1,
            clickable: _.isFunction(this.props.onSidebarCategoryClick),
          });
          return (
            <div className={nodeClassNames} key={'depth-' + segmentDepth}>
              <span className={'indent depth' + segmentDepth}>
                <span className="hor-connector" />
              </span>
              <span
                className="tree-value"
                title={pathSegment}
                onClick={_.partial(
                  this.onSidebarCategoryClick,
                  this.props.category.path.take(segmentDepth + 1),
                )}
              >
                {pathSegment}
              </span>
            </div>
          );
        }).toArray()}
      </div>
    );
  }
}

export default CategoryTree;
