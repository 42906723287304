import classNames from 'classnames';
import React from 'react';

const Cell: React.FunctionComponent<{
  className?: string
  children?: React.ReactNode
}> = ({ className, children }) => {
  return (
    <div className={classNames('fixedDataTableCellContent', className)}>
      <div className="table-cell-inner">{children}</div>
    </div>
  );
};

export default Cell;
