import classNames from 'classnames';
import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';

import LoadingPanel from '../components/LoadingPanel';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Table from '../components/Table/Table';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import { AppAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { SELECT_EXTERNAL_STORAGE_NODE_GROUP } from './AddDatasetActionTypes';
import { selectExternalStorageNode, selectExternalStorageProvider } from './AddDatasetAsync';
import { getChildStorageNodes, getStorageNodeSample } from './ExternalStorageContentStore';
import MimeType, { DIRECTORY, getDisplayName, MimeTypeE } from './MimeType';
import SampleRecords from './SampleRecords';
import style from './StorageBrowser.module.scss';
import StorageNode from './StorageNode';


const Sample: React.FC<{
  sample: SampleRecords
}> = ({ sample }) => {
  return (
    <div className={style.sample}>
      <div className={style.previewHeader}>PREVIEW</div>
      <div className={style.previewTable}>
        <AutoSizer>
          {({ width, height }) => (
            <Table
              tableType="stripes"
              getLength={() => sample.rows.size}
              {... { height, width }}
              rowHeight={30}
            >
              {sample.schema.fields.map(f => (
                <Column
                  key={f.name}
                  columnKey={f.name}
                  width={125}
                  header={<Cell>{f.name}</Cell>}
                  cell={({ rowIndex }) => <Cell>{sample.rows.get(rowIndex)?.get(f.name)}</Cell>}
                  isResizable
                />
              ))}
            </Table>
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

const UnconnectedNode: React.FC<{
  selectedName?: string | null
  node: StorageNode
  onSelect: (node: StorageNode) => any
}> = ({ selectedName, node, onSelect }) => {
  // you can always click on directories, also nodes that can be selected as datasets
  const clickable = !!node.mimeType && (node.selectable || node.mimeType === DIRECTORY);
  return <div
    className={classNames(style.node, {
      [style.selected]: node.name === selectedName,
      [style.disabled]: !clickable,
    })}
    title={node.mimeType ? undefined : 'Unsupported type'}
    onClick={clickable ? () => onSelect(node) : undefined}
  >
    {node.name}
  </div>;
};

const Node = connect(null, {
  onSelect: selectExternalStorageNode,
})(UnconnectedNode);

const UnconnectedNodeGroup: React.FC<{
  type: MimeTypeE
  selectedName: string | null | undefined
  nodes: List<StorageNode>
  allSelected: boolean
  onSelectAll: ({ node, nodeType }: { node: StorageNode, nodeType: string }) => any
}> = ({ type, selectedName, nodes, allSelected, onSelectAll }) => (
  <div className={classNames(style.nodeGroup, { [style.allSelected]: allSelected })}>
    <div className={style.selectAll} onClick={() => onSelectAll({ nodeType: type, node: nodes.first() })}>
      SELECT ALL {getDisplayName(type)} HERE
      <TooltipTrigger
        content={
          <div>
            <div className={style.selectAllInfo}>Treat these and any future files in this folder as a single dataset.</div>
            <div><TamrIcon iconName="tamr-icon-warning" size={12} />If these files do not represent parts of a whole dataset you may experience issues with the resulting dataset.</div>
          </div>
        }
      >
        <TamrIcon className={style.selectAllTooltipIcon} iconName="info-outline" size={14} />
      </TooltipTrigger>
    </div>
    {nodes.map(node => <Node key={node.name} {...{ node, selectedName }} />)}
  </div>
);

const NodeGroup = connect(({ externalStorage: { selectedNodeType } }: AppState, { type }: { type: MimeTypeE }) => {
  return {
    allSelected: selectedNodeType === type,
  };
}, {
  onSelectAll: ({ node, nodeType }: { node: StorageNode, nodeType: MimeTypeE }): AppAction => ({ type: SELECT_EXTERNAL_STORAGE_NODE_GROUP, node, nodeType }),
})(UnconnectedNodeGroup);

const Path: React.FC<{
  nodes: List<StorageNode> | null
  selectedName: string | undefined
  sample?: SampleRecords | null
}> = ({ nodes, selectedName, sample }) => {
  if (!nodes && !sample) return <div className={style.path}><LoadingPanel className={style.loader} /></div>;
  if (sample) {
    return (
      <div className={style.path}>
        <Sample sample={sample} />
      </div>
    );
  }
  if (!nodes || nodes.isEmpty()) return null;
  const groupedNodes = nodes.groupBy(n => n.mimeType);
  const supportedTypes = groupedNodes.keySeq().filter(k => k !== null).sort();
  return (
    <div className={style.path}>
      {supportedTypes?.map(type => {
        if (!type) return null;
        const thisNodes = groupedNodes.get(type)?.toList();
        if (!thisNodes) return null;
        // these types cannot be selected together as parts of one dataset
        if (type === MimeType.BIGQUERY || type === MimeType.DIRECTORY) {
          return groupedNodes.get(type, undefined)?.map(node => <Node key={node.name} {...{ node, selectedName }} />);
        }
        // otherwise, we can group these nodes together
        return <NodeGroup key={type || undefined} {...{ type, selectedName, nodes: thisNodes }} />;
      })}
      {groupedNodes.get(null)?.map(node => <Node key={node.name || undefined} node={node} />)}
    </div>
  );
};

const Providers = connect(({ externalStorage: { storageProviders, selectedProvider } }: AppState) => {
  return {
    providers: storageProviders,
    selectedProvider,
  };
}, {
  onSelect: selectExternalStorageProvider,
})(({ providers, selectedProvider, onSelect }) => (
  <div className={style.providerList}>
    {providers.map(p => (
      <div key={p.name} className={classNames(style.provider, { [style.selected]: p.name === selectedProvider })} onClick={() => onSelect(p.name)}>
        <div className={style.providerMeta}>
          <div className={style.name}>{p.name}</div>
          <div className={style.description}>{p.description}</div>
        </div>
      </div>
    ))}
  </div>
));

export default connect(({ externalStorage: { selectedProvider, selectedNode, storageContent } }: AppState) => {
  return {
    selectedProvider,
    currentPath: selectedNode?.parent.push(selectedNode.name),
    content: selectedProvider && storageContent.get(selectedProvider),
  };
})(({ selectedProvider, currentPath, content }) => {
  return (
    <React.Fragment>
      {(!selectedProvider || selectedProvider === 'bigquery')
        ? null
        : <div className={style.betaWarning}>
          <div className={style.warning}><TamrIcon className={style.warningIcon} iconName="tamr-icon-warning" size={14} /> Connect to a group of files</div>
          <div>To add a dataset that is connected to a group of files—rather than a single file—select all the files (of either CSV or Avro format) in a path that represent parts of a whole dataset. If these files do not share the same schema, you will experience issues or errors when attempting to use this dataset.</div>
        </div>
      }
      <div className={style.container}>
        <Providers />
        {!selectedProvider
          ? null
          : !content
            ? <div className={style.loaderContainer}><LoadingPanel className={style.loader} /></div>
            : (
              <React.Fragment>
                <Path selectedName={currentPath?.get(0)} nodes={getChildStorageNodes({ content, path: List() })} />
                {currentPath && currentPath.map((_, i) =>
                  <Path
                    key={i}
                    selectedName={currentPath.get(i + 1)}
                    nodes={getChildStorageNodes({ content, path: currentPath.slice(0, i + 1) })}
                    sample={getStorageNodeSample({ content, path: currentPath.slice(0, i + 1) })}
                  />,
                )}
              </React.Fragment>
            )
        }
      </div>
    </React.Fragment>
  );
});
