import { List } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import Input from '../components/Input/Input';
import Document from '../models/doc/Document';
import Tag from '../models/Tag';
import { createTag } from './TagApi';
import style from './TagManagerList.module.scss';
import TagManagerListElement from './TagManagerListElement';

const ENTER_KEY = 13;
const ESC_KEY = 27;

const TagManagerList = connect((state) => {
  const { tags: { allTags, createNew, newTagName, scrollIntoView, searchTagValue } } = state;
  return { allTags, createNew, newTagName, scrollIntoView, searchTagValue };
}, {
  onCreateTag: createTag,
  onSetCreateNew: (createNew) => ({ type: 'Tags.setCreateNew', createNew }),
  onSetNewTagName: (tagName) => ({ type: 'Tags.setNewTagName', tagName }),
  onSetScrollIntoView: (scrollIntoView) => ({ type: 'Tags.setScrollIntoView', scrollIntoView }),
})(class TagManagerList extends React.Component {
  static propTypes = {
    allTags: ImmutablePropTypes.setOf(Document.propType.withDataType(Tag)).isRequired,
    createNew: PropTypes.bool.isRequired,
    newTagName: PropTypes.string.isRequired,
    onCreateTag: PropTypes.func.isRequired,
    onSetCreateNew: PropTypes.func.isRequired,
    onSetNewTagName: PropTypes.func.isRequired,
    onSetScrollIntoView: PropTypes.func.isRequired,
    scrollIntoView: PropTypes.string,
    searchTagValue: PropTypes.string.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.scrollIntoView && this.props.scrollIntoView) {
      this.scrollToUpdate();
    }
  }

  scrollToUpdate = () => {
    const { onSetScrollIntoView } = this.props;
    const updateNode = this.props.scrollIntoView;
    const node = this.refs[updateNode];
    node.scrollIntoView({ behavior: 'smooth' });
    node.focus();
    // then set back to undefined
    onSetScrollIntoView();
  };

  renderCreateNewButton = () => {
    const { onSetCreateNew } = this.props;
    return (
      <Button
        buttonType="Flat"
        icon="tamr-icon-add"
        onClick={() => onSetCreateNew(true)}
      >
        CREATE NEW TAG
      </Button>
    );
  };

  renderCreateNewInput = () => {
    const { onSetNewTagName, allTags, newTagName, onSetCreateNew } = this.props;
    const allTagNames = allTags.map(tagDoc => tagDoc.data.name);
    const tagNameNotUnique = allTagNames.contains(newTagName);
    return (
      <div className={style.createTagContainer}>
        <div className={style.inputContainer} onClick={(e) => e.stopPropagation()}>
          <Input
            autoFocus
            onChange={onSetNewTagName}
            onKeyUp={newTagName.length !== 0 && !allTagNames.contains(newTagName) ? this.onCreateKeyUp : _.noop}
            ref="createNew"
            title="Create a new tag"
            type="text"
            value={newTagName}
            variant="light"
          />
          <Button
            className={style.cancel}
            buttonType="Secondary"
            onClick={() => {
              onSetCreateNew(false);
            }}
            title="Cancel"
          >
            Cancel
          </Button>
          <Button
            className={style.save}
            buttonType="Primary"
            onClick={() => {
              const { onCreateTag } = this.props;
              onSetNewTagName(newTagName.trim().toLowerCase());
              onCreateTag(newTagName);
            }}
            disabled={!newTagName || tagNameNotUnique}
          >
            Save
          </Button>
        </div>
        <div className={style.errorContainer}>
          {tagNameNotUnique
            ? <div>"{newTagName}" already exists.</div>
            : <div />}
          {newTagName && newTagName.length === 0 || tagNameNotUnique
            ? <div>Please create a unique tag name</div>
            : <div />}
        </div>
      </div>
    );
  };

  onCreateKeyUp = (e) => {
    const { onCreateTag, onSetNewTagName, onSetCreateNew, newTagName } = this.props;
    if (e.keyCode === ESC_KEY) {
      onSetCreateNew(false);
    }
    if (newTagName && e.keyCode === ENTER_KEY) {
      onSetNewTagName(newTagName.trim().toLowerCase());
      onCreateTag(newTagName);
    }
  };

  render() {
    const { allTags, searchTagValue } = this.props;
    return (
      <div className={style.tagManagerListComponent}>
        <div className={style.tagManagerCreate}>
          {!this.props.createNew ? this.renderCreateNewButton() : this.renderCreateNewInput()}
        </div>
        <div className={style.tagManagerListContainer}>
          {(new List(allTags))
            .sortBy(doc => doc.data.name)
            .filter(({ data: { name } }) => (searchTagValue ? name.toLowerCase().indexOf(searchTagValue.toLowerCase()) > -1 : true))
            .map(({ id: { id }, data: { name } }, i) => <div key={name} ref={name}><TagManagerListElement key={name} value={name} id={id} index={i} /></div>)}
        </div>
      </div>
    );
  }
});

export default TagManagerList;
