import PairLabelTypes from '../constants/PairLabelTypes';
import { ArgTypes } from '../utils/ArgValidation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';
import RecordPairId from './RecordPairId';

class RecordPairLabel extends getModelHelpers({
  sourceId1: { type: ArgTypes.string },
  entityId1: { type: ArgTypes.string },
  sourceId2: { type: ArgTypes.string },
  entityId2: { type: ArgTypes.string },
  leftRootIds: { type: ArgTypes.orUndefined(ArgTypes.array.of(ArgTypes.string)) },
  rightRootIds: { type: ArgTypes.orUndefined(ArgTypes.array.of(ArgTypes.string)) },
  manualLabel: { type: ArgTypes.valueIn(PairLabelTypes.manual) },
}, 'RecordPairLabel')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class RecordPairLabelRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      const { sourceId1, entityId1, sourceId2, entityId2, leftRootIds, rightRootIds, manualLabel } = args;
      const swap = [sourceId1, entityId1] > [sourceId2, entityId2];
      const argsInCorrectOrder = {
        sourceId1: swap ? sourceId2 : sourceId1,
        entityId1: swap ? entityId2 : entityId1,
        sourceId2: swap ? sourceId1 : sourceId2,
        entityId2: swap ? entityId1 : entityId2,
        leftRootIds: swap ? rightRootIds : leftRootIds,
        rightRootIds: swap ? leftRootIds : rightRootIds,
        manualLabel,
      };
      super(argsInCorrectOrder);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  toRecordPairId() {
    const { sourceId1, entityId1, sourceId2, entityId2 } = this;
    return new RecordPairId({ sourceId1, entityId1, sourceId2, entityId2 });
  }
}

export default RecordPairLabel;
