import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import _ from 'underscore';

import { SidebarSection } from '../components/DetailSidebar/DetailSidebar';
import { GeoValueSummary, tryParseDataAsGeo } from '../components/MultiValue';
import Term from '../components/Term';
import * as DataTables from '../constants/DataTables';
import TypedData, { ArrayRenderingOption, DEFAULT_HIGHLIGHTING_PARAMETERS, StringRenderingOption, WhitespaceRenderingOption } from '../dataRendering/TypedData';
import * as geotamr from '../geospatial/GeoTamr';
import EsRecord from '../models/EsRecord';
import { AppAction } from '../stores/AppAction';
import { AppDispatch, AppState } from '../stores/MainStore';
import { inferTypedValueOfUnknownJson } from '../transforms/models/TypedValue';
import { updateGlobalPreferences } from '../users/UsersAsync';
import { shortMoney } from '../utils/Numbers';
import Optional from '../utils/Optional';
import { getAuthorizedUser, getUDColumnSettingsForPage, selectActiveProjectInfo } from '../utils/Selectors';
import { getTerm } from '../utils/Terms';
import { getPath } from '../utils/Values';
import style from './RecordFieldsSidebarSection.module.scss';


const TRUNCATE_SIDEBAR_DATA_VALUES_PREFERENCE_KEY = 'truncateSidebarDataValues';

const selectTruncateSidebarDataValuesUserPreference = createSelector(
  getAuthorizedUser,
  (user) => !!user?.globalPrefs.get(TRUNCATE_SIDEBAR_DATA_VALUES_PREFERENCE_KEY),
);

const Datum: React.FC<{
  field: string
  record: EsRecord
  onClickGeoTamrAttribute: (attributeName: string) => AppAction
  truncateValues: boolean
}> = ({ field, record, onClickGeoTamrAttribute, truncateValues }) => {
  const value = record.getHighlightOrValue(field);
  return (
    <tr className={style.datumRow}>
      <td
        className={classNames(style.datumName, { [style.truncateValues]: truncateValues })}
        title={field}
      >
        {field}:
      </td>
      <td className={classNames(style.datumValue, { [style.truncateValues]: truncateValues })}>
        {Optional.of(tryParseDataAsGeo(value))
          .map(geoJson => (
            <span
              className={style.clickableGeoTamr}
              onClick={() => onClickGeoTamrAttribute(field)}
            >
              <GeoValueSummary geoTamr={geotamr.fromGeoJSON(geoJson)} />
            </span>
          ))
          .orElseGet(() => (
            // TODO use actual dataset schema for typing
            <TypedData
              typedValue={inferTypedValueOfUnknownJson(value)}
              strings={StringRenderingOption.ONLY_SHOW_VALUE}
              whitespace={WhitespaceRenderingOption.ADD_SPACE}
              arrays={ArrayRenderingOption.SHOW_BRACKETS_AND_POPOVER_WHEN_MULTIPLE_ELEMENTS}
              highlighting={DEFAULT_HIGHLIGHTING_PARAMETERS}
              arrayPopoverPlacement="left"
              arrayPopoverCopyTooltipPlacement="right"
            />
          ))}
      </td>
    </tr>
  );
};

type DataSidebarOwnProps = {
  className?: string
  onClickGeoTamrAttribute: (attributeName: string) => AppAction
  recipeId: number
  record: EsRecord
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RecordFieldsSidebarSection = connect((state: AppState, ownProps: DataSidebarOwnProps) => {
  return {
    columnSettings: getUDColumnSettingsForPage(state, DataTables.RECORDS),
    currencyChar: getTerm(state, 'currencyChar'),
    spendTerm: getTerm(state, 'spend'),
    projectInfo: selectActiveProjectInfo(state),
    truncateSidebarDataValuesUserPreference: selectTruncateSidebarDataValuesUserPreference(state),
  };
}, (dispatch: AppDispatch, ownProps: DataSidebarOwnProps) => ({
  onClickGeoTamrAttribute: (attributeName: string) => dispatch(ownProps.onClickGeoTamrAttribute(attributeName)),
  onUpdateTruncatePreference: (preference: boolean) =>
    dispatch(updateGlobalPreferences({ [TRUNCATE_SIDEBAR_DATA_VALUES_PREFERENCE_KEY]: preference })),
}))(({ record, onClickGeoTamrAttribute, columnSettings, currencyChar, spendTerm, projectInfo, onUpdateTruncatePreference, truncateSidebarDataValuesUserPreference }) => {
  const { hiddenFields } = record;
  const headerFields = ['tamr_id', 'origin_source_name', 'origin_entity_id'];
  const orderedHiddenFields = columnSettings
    ?.filter(c => _.contains(hiddenFields, c.name) && !_.contains(headerFields, c.name));
  const headerData = _.map(headerFields, field => <Datum key={field} {...{ field, record, onClickGeoTamrAttribute, truncateValues: truncateSidebarDataValuesUserPreference }} />);
  const hiddenData = orderedHiddenFields?.map(c => <Datum key={c.name} field={c.name} {...{ record, onClickGeoTamrAttribute, truncateValues: truncateSidebarDataValuesUserPreference }} />);
  const unifiedDataset = projectInfo?.unifiedDataset;
  const spendField = getPath(unifiedDataset, 'metadata', 'spendField');
  const spend = spendField ? currencyChar + shortMoney(record.spend) : '--';

  return (
    <SidebarSection
      title="Values"
      additionalTitleContent={(
        <a
          className={style.truncateValuesToggle}
          onClick={() => {
            onUpdateTruncatePreference(!truncateSidebarDataValuesUserPreference);
          }}
        >
          {truncateSidebarDataValuesUserPreference ? 'show more' : 'show less'}
        </a>
      )}
    >
      <div className={style.component}>
        <table className={style.dataTable}>
          <tbody>
            <tr className={style.datumRow}>
              <td className={style.datumName} title={spendTerm}><Term>spend</Term>:</td>
              <td className={style.datumValue} title={spend}>{spend}</td>
            </tr>
            {headerData}
            {hiddenData}
          </tbody>
        </table>
      </div>
    </SidebarSection>
  );
});

export default RecordFieldsSidebarSection;
