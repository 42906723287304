import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import ConditionalButton from '../components/ConditionalButton';
import Term from '../components/Term';
import EsRecord from '../models/EsRecord';
import { isCuratorByProjectId } from '../utils/Authorization';
import { getAuthorizedUser, selectActiveProjectInfo } from '../utils/Selectors';
import { getSelectedRecords } from './TransactionStore';

const TransactionBulkCategorizeButton = _.compose(
  connect(state => {
    const { transactions } = state;
    const authorizedUser = getAuthorizedUser(state);
    return {
      records: getSelectedRecords(transactions),
      isUserACurator: isCuratorByProjectId(authorizedUser, selectActiveProjectInfo(state)?.projectDoc.id.id),
      username: getAuthorizedUser(state).username,
    };
  }, {
    onShowCategorizeDialog: categorizeDialogRecords => ({ type: 'Transactions.openCategorizeDialog', categorizeDialogRecords }),
  }),
)(class TransactionBulkCategorizeButton extends React.Component {
  static propTypes = {
    iconify: PropTypes.bool.isRequired,
    isUserACurator: PropTypes.bool.isRequired,
    records: ImmutablePropTypes.listOf(PropTypes.instanceOf(EsRecord)),
    username: PropTypes.string,
  };

  canUserVote = (feedbacks) => {
    const { username } = this.props;
    return _.some(feedbacks, (feedback) => feedback.username === username);
  };

  renderButton = () => {
    const { iconify, onShowCategorizeDialog, records } = this.props;
    const label = 'New categorization';
    const preconditions = Map()
      .set(<span>You must select at least one <Term>record</Term></span>, !this.props.records.isEmpty());
    return (
      <ConditionalButton
        onClick={() => onShowCategorizeDialog(records)}
        buttonType="Secondary"
        preconditions={preconditions}
        icon="edit"
        iconSize={16}
        tooltipLabel={iconify ? label : ''}
      >
        {iconify ? undefined : label}
      </ConditionalButton>
    );
  };

  render() {
    return (
      <div className="transaction-bulk-categorize-button">
        {this.renderButton()}
      </div>
    );
  }
});

export default TransactionBulkCategorizeButton;
