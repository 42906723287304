import { List, Set } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import { QueryBuilder } from '../models/doc/Query';
import AppState from '../stores/AppState';
import DatasetFilter from './DatasetFilter';
import style from './DatasetFilter.module.scss';

interface DatasetFilterDialogOwnProps {
  applyButtonText?: string
  customFilters?: React.ReactNode
  extraFooterContent?: React.ReactNode
  getSelectedDatasets: (datasets: List<Document<Dataset>>) => Set<string>
  modifyQuery?: (query: QueryBuilder) => void
  onHide: () => void
  onSubmit: (datasetsToAdd: Set<Document<Dataset>>, datasetsToRemove: Set<Document<Dataset>>) => void
  show: boolean
  title: React.ReactNode
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DatasetFilterDialog = connect((state: AppState, ownProps: DatasetFilterDialogOwnProps) => {
  const { datasetFilter: { datasetsToAdd, datasetsToRemove } } = state;
  return { datasetsToAdd, datasetsToRemove };
})(({ applyButtonText, customFilters, extraFooterContent, getSelectedDatasets, modifyQuery, onHide, onSubmit, show, title, datasetsToAdd, datasetsToRemove }) => {
  return (
    <Dialog
      show={show}
      onHide={onHide}
      title={title}
      dialogStyle={DialogStyle.FULL}
      body={show ? (
        <DatasetFilter {...{ customFilters, getSelectedDatasets, modifyQuery }} />
      ) : null}
      footer={(
        <div className={style.dialogFooter}>
          <div className={style.extraFooter}>
            {extraFooterContent}
          </div>
          <ButtonToolbar className={style.buttonToolbar}>
            <Button onClick={onHide} buttonType="Secondary">
              Cancel
            </Button>
            <Button onClick={() => onSubmit(datasetsToAdd, datasetsToRemove)}>
              {applyButtonText}
            </Button>
          </ButtonToolbar>
        </div>
      )}
    />
  );
});

DatasetFilterDialog.propTypes = {
  applyButtonText: PropTypes.string,
  customFilters: PropTypes.node, // passthru to contents
  extraFooterContent: PropTypes.node,
  getSelectedDatasets: PropTypes.func.isRequired, // passthru to contents
  modifyQuery: PropTypes.func, // passthru to contents
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.node.isRequired,
};

DatasetFilterDialog.defaultProps = {
  applyButtonText: 'Apply',
  title: 'Select datasets',
};

export default DatasetFilterDialog;
