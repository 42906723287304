import { List, Set } from 'immutable';
import { createSelector } from 'reselect';

import * as DataTables from '../constants/DataTables';
import { ORIGIN_SOURCE_NAME } from '../constants/ElasticConstants';
import DisplayColumn from '../models/DisplayColumn';
import { AppThunkAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { setColumnPreferences as userApiSetColumnPreferences } from '../users/UsersAsync';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { getColumnSettingsForUserForPage } from '../utils/Columns';
import { AppSelector, selectActiveProjectInfo } from '../utils/Selectors';
import { assertNever } from '../utils/typescript';
import { getPath } from '../utils/Values';
import { ES, PREVIEW, RowProvidersE } from './RowProviders';

const SOURCE_COLUMN_FIRST_DEFAULT = List([{ name: ORIGIN_SOURCE_NAME, order: -Infinity }]);

// returns column preferences for all staged unified attributes, + system columns
// staged != committed into the unified dataset
const getRecipeId = (state: AppState) => state.location?.recipeId;
const getAuthorizedUser = (state: AppState) => state.auth?.authorizedUser;
const getAllUnifiedAttributes = (state: AppState) => state.schemaMapping?.allUnifiedAttributes;

export const selectStagedUnifiedDatasetColumns: AppSelector<List<DisplayColumn>> = createSelector(
  getRecipeId,
  getAuthorizedUser,
  getAllUnifiedAttributes,
  (recipeId, authorizedUser, allUnifiedAttributes) => (authorizedUser ? getColumnSettingsForUserForPage({
    page: DataTables.RECORDS,
    recipeId,
    user: authorizedUser,
    fieldNames: allUnifiedAttributes.map(({ name }) => name).toSet(),
    defaults: SOURCE_COLUMN_FIRST_DEFAULT,
  }) : List()),
);
export const selectStagedUnifiedDatasetColumnNames: AppSelector<List<string>> = createSelector(
  selectStagedUnifiedDatasetColumns,
  columns => columns.map(col => col.name),
);
export const selectCommittedUnifiedDatasetColumns: AppSelector<List<DisplayColumn>> = createSelector(
  getRecipeId,
  getAuthorizedUser,
  selectActiveProjectInfo,
  (recipeId, authorizedUser, projectInfo) => (authorizedUser ? getColumnSettingsForUserForPage({
    page: DataTables.RECORDS,
    recipeId,
    user: authorizedUser,
    fieldNames: Set(getPath(projectInfo, 'unifiedDataset', 'fields')),
    defaults: SOURCE_COLUMN_FIRST_DEFAULT,
  }) : List()),
);

export const getColumnSettings = (state: AppState, provider: RowProvidersE): List<DisplayColumn> => {
  checkArg({ provider }, ArgTypes.valueIn([ES, PREVIEW]));
  switch (provider) {
    case ES:
      return selectCommittedUnifiedDatasetColumns(state);
    case PREVIEW:
      return selectStagedUnifiedDatasetColumns(state);
    default:
      assertNever(provider);
  }
};

export const setColumnPreferences = (columnPrefs: List<DisplayColumn>): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ columnPrefs }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(DisplayColumn)));
  const state = getState();
  const { records: { provider } } = state;
  const columnNames = columnPrefs.map(({ name }) => name).toSet();
  let combinedColumnPrefs = columnPrefs;
  getColumnSettings(state, provider === ES ? PREVIEW : ES)
    .filter(({ name }) => !columnNames.has(name))
    .forEach(col => {
      combinedColumnPrefs = combinedColumnPrefs.insert(col.order, col);
    });
  combinedColumnPrefs = combinedColumnPrefs.map((col, i) => col.withOrder(i));
  dispatch(userApiSetColumnPreferences(DataTables.RECORDS, combinedColumnPrefs));
};

export const setColumnWidth = (newWidth: number, col: string): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ newWidth }, ArgTypes.number);
  checkArg({ col }, ArgTypes.string);
  const state = getState();
  const { records: { provider } } = state;
  const columnSettings = getColumnSettings(state, provider);
  dispatch(setColumnPreferences(columnSettings.map(c => (c.name === col ? c.set('width', newWidth) : c))));
};
