import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import SearchBox from '../components/SearchBox';
import Document from '../models/doc/Document';
import Tag from '../models/Tag';
import TagConfirmDeleteDialog from './TagConfirmDeleteDialog';
import style from './TagManager.module.scss';
import TagManagerList from './TagManagerList';

// To create, delete and update tags

const TagManager = connect((state) => {
  const { tags: { allTags, createNew, searchTagValue, selectedTags, showingManageDialog } } = state;
  return { allTags, createNew, searchTagValue, selectedTags, showingManageDialog };
}, {
  onSetConfirmDeleteDialogVisibility: (tagsToDelete) => ({ type: 'Tags.setConfirmDeleteDialogVisibility', tagsToDelete }),
  onSetCreateNew: (createNew) => ({ type: 'Tags.setCreateNew', createNew }),
  onSetManageDialogVisibility: (showingManageDialog) => ({ type: 'Tags.setManageDialogVisibility', showingManageDialog }),
  onSetTagSearch: (searchTagValue) => ({ type: 'Tags.setTagSearch', searchTagValue }),
  onSetUpdateIndex: (updateIndex) => ({ type: 'Tags.setUpdateIndex', updateIndex }),
})(// To create, delete and update tags

  class TagManager extends React.Component {
    static propTypes = {
      allTags: ImmutablePropTypes.setOf(Document.propType.withDataType(Tag)).isRequired,
      createNew: PropTypes.bool.isRequired,
      onSetConfirmDeleteDialogVisibility: PropTypes.func.isRequired,
      onSetCreateNew: PropTypes.func.isRequired,
      onSetManageDialogVisibility: PropTypes.func.isRequired,
      onSetTagSearch: PropTypes.func.isRequired,
      onSetUpdateIndex: PropTypes.func.isRequired,
      searchTagValue: PropTypes.string.isRequired,
      selectedTags: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
      showingManageDialog: PropTypes.bool,
    };

    renderSearch = () => {
      const { onSetTagSearch, searchTagValue } = this.props;
      return (
        <SearchBox value={searchTagValue} onSearch={onSetTagSearch} searchOnKeyup />
      );
    };

    renderManager = () => {
      const { onSetConfirmDeleteDialogVisibility, onSetUpdateIndex, onSetCreateNew, createNew, selectedTags } = this.props;
      return (
        <div onClick={() => (createNew ? onSetCreateNew(false) : onSetUpdateIndex(-1))}>
          <div>
            <Button
              className={style.selectorBtn}
              buttonType="Secondary"
              disabled={!selectedTags.size > 0}
              icon="delete"
              iconSize={16}
              onClick={() => onSetConfirmDeleteDialogVisibility(selectedTags)}
          >
              Delete
            </Button>
            <div className={style.tagSearch}>
              {this.renderSearch()}
            </div>
          </div>
          <TagManagerList />
        </div>
      );
    };

    renderManagerDialog = () => {
      const { onSetManageDialogVisibility } = this.props;
      return (
        <Dialog
          bodyClassName="modal-body-manage-tags"
          show={this.props.showingManageDialog}
          onHide={() => onSetManageDialogVisibility(false)}
          title="Manage Tags"
          dialogStyle={DialogStyle.PRIMARY}
          body={this.renderManager()}
          closeButton
          footer={(
            <ButtonToolbar>
              <Button
                onClick={() => onSetManageDialogVisibility(false)}
            >
                Done
              </Button>
            </ButtonToolbar>
        )}
      />
      );
    };

    render() {
      return (
        <div style={{ display: 'none' }}>
          {this.renderManagerDialog()}
          <TagConfirmDeleteDialog />
        </div>
      );
    }
  });

export default TagManager;
