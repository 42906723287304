import _ from 'underscore';

import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import * as RecipeType from './RecipeType';

// Javascript equivalent of com.tamr.dataset.models.ProjectType - maps JS names
// to their java counterparts
export const SUPPLIER_MASTERING = RecipeType.DEDUP;
export const CLASSIFICATION = RecipeType.CATEGORIZATION;
export const SCHEMA_MAPPING_RECOMMENDATIONS = RecipeType.SCHEMA_MAPPING_RECOMMENDATIONS;
export const GOLDEN_RECORDS = RecipeType.GOLDEN_RECORDS;
export const ENRICHMENT = RecipeType.ENRICHMENT;

function propType(props: $TSFixMe, propName: string, component: $TSFixMe, isRequired: boolean) {
  const prop = props[propName];
  const invalidBecauseUndefined = prop === undefined && isRequired;
  const invalidValue = prop !== undefined && prop !== SUPPLIER_MASTERING && prop !== CLASSIFICATION && prop !== SCHEMA_MAPPING_RECOMMENDATIONS && prop !== GOLDEN_RECORDS;
  if (invalidBecauseUndefined || invalidValue) {
    return new Error(`Invalid prop ${propName} with value ${props[propName]} supplied`
      + ` to ${component.toString()} is not one of [${SUPPLIER_MASTERING}, ${CLASSIFICATION}, ${SCHEMA_MAPPING_RECOMMENDATIONS}].`);
  }
}
const nonRequiredPropType = _.partial(propType, _, _, _, false);
// @ts-expect-error
nonRequiredPropType.isRequired = _.partial(propType, _, _, _, true);

export default {
  SUPPLIER_MASTERING,
  CLASSIFICATION,
  SCHEMA_MAPPING_RECOMMENDATIONS,
  GOLDEN_RECORDS,
  ENRICHMENT,

  argType: ArgTypes.valueIn([SUPPLIER_MASTERING, CLASSIFICATION, SCHEMA_MAPPING_RECOMMENDATIONS, GOLDEN_RECORDS, ENRICHMENT]),
  propType: nonRequiredPropType,
};
