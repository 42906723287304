import { Set } from 'immutable';
import randomColor from 'randomcolor';

import Document from '../models/doc/Document';
import Model from '../models/Model';
import Tag from '../models/Tag';
import { ArgTypes, checkArg } from '../utils/ArgValidation';

const sortTags = (allTags) => {
  checkArg({ allTags }, ArgTypes.Immutable.set.of(Document.argTypeWithNestedClass(Tag)));
  return allTags.sortBy((tag) => tag.data.name);
};

export const getTagNames = ({ tags: { allTags } }, tagIds) => {
  checkArg({ tagIds }, ArgTypes.Immutable.list.of(ArgTypes.number));
  const tagDocs = allTags.filter(tagDoc => tagIds.includes(tagDoc.id.id));
  return tagDocs.map(doc => doc.data.name);
};

export const setTagColor = (name, id) => {
  const hueList = ['#0F7BB5', '#FFBC6D', '#B51837', '#16CCF5', '#00C38D', '#9A9FF4', '#00946B', '#0F99B8', '#6F74C9', '#EC6F88', '#EE7B24', '#24EEB9', '#B124EE', '#B91BCB', '#CB1B7B', '#765DB8', '#B85DAE', '#54964F', '#9D86A4', '#86A486', '#A48686'];
  const pickHue = id % hueList.length;
  const tagColor = randomColor({
    luminosity: 'dark',
    hue: hueList[pickHue],
    seed: name,
  });
  return tagColor;
};

export const initialState = new (Model({
  allTags: { type: ArgTypes.Immutable.set.of(Document.argTypeWithNestedClass(Tag)), defaultValue: new Set() },
  createNew: { type: ArgTypes.bool, defaultValue: false },
  newTagName: { type: ArgTypes.string, defaultValue: '' },
  scrollIntoView: { type: ArgTypes.nullable(ArgTypes.string) },
  searchTagValue: { type: ArgTypes.string, defaultValue: '' },
  selectedTags: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: new Set() },
  showingManageDialog: { type: ArgTypes.bool, defaultValue: false },
  tagsToDelete: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: new Set() },
  updateIndex: { type: ArgTypes.number, defaultValue: -1 },
}))();

export const reducers = {
  'Tags.setTagSearch': (state, { searchTagValue }) => {
    return state.merge({ searchTagValue });
  },

  'Tags.setNewTagName': (state, { tagName }) => {
    const newTagName = tagName.toLowerCase().replace(/[^\w\s\-\$\.\+\!\*\'\(\)\,]/gi, '');
    return state.merge({ newTagName });
  },

  'Tags.setCreateNew': (state, { createNew }) => {
    return state.merge({ createNew, newTagName: '' });
  },

  'Tags.setUpdateIndex': (state, { updateIndex }) => {
    return state.merge({ updateIndex, newTagName: '' });
  },

  'Tags.selectTag': (state, { selectedTagName }) => {
    const { selectedTags } = state;
    const newSelectedTags = selectedTags.includes(selectedTagName)
      ? selectedTags.delete(selectedTagName)
      : selectedTags.add(selectedTagName);
    return state.merge({ selectedTags: newSelectedTags });
  },

  'Tags.setManageDialogVisibility': (state, { showingManageDialog }) => {
    return state.merge({ showingManageDialog, selectedTags: new Set(), searchTagValue: '' });
  },

  'Tags.setConfirmDeleteDialogVisibility': (state, { tagsToDelete }) => {
    checkArg({ tagsToDelete }, ArgTypes.Immutable.set.of(ArgTypes.string));
    return state.merge({ tagsToDelete });
  },

  'Tags.setScrollIntoView': (state, { scrollIntoView }) => {
    return state.merge({ scrollIntoView });
  },

  'Tags.fetchAllTagsCompleted': (state, { allTags, selectedTags }) => {
    return state.merge({ allTags: sortTags(allTags), selectedTags });
  },

  'Tags.createTagCompleted': (state, { allTags, selectedTags, newTagName }) => {
    return state.merge({
      allTags: sortTags(allTags),
      selectedTags,
      scrollIntoView: newTagName,
      createNew: false,
    });
  },

  'Tags.updateTagCompleted': (state, { allTags, selectedTags, tagNameNew }) => {
    return state.merge({
      allTags: sortTags(allTags),
      selectedTags,
      scrollIntoView: tagNameNew,
      updateIndex: -1,
      newTagName: '',
    });
  },
};
