import { List, Set } from 'immutable';

import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';
import ProcurementCategory from './ProcurementCategory';


// com.tamr.procurify.models.taxonomy.ProcurementTaxonomy

export default class ProcurementTaxonomy extends getModelHelpers({
  categories: { type: ArgTypes.Immutable.set.of(ArgTypes.instanceOf(ProcurementCategory)), defaultValue: Set() },
}, 'ProcurementTaxonomy')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ProcurementTaxonomyRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  _pathsToCategories: { [key: string]: ProcurementCategory };
  _idsToCategories: { [key: string]: ProcurementCategory };

  static get argType() { return ArgTypes.instanceOf(this); }

  constructor(args: $TSFixMe) {
    super(args);

    // cache category by path
    const pathsToCategories: { [key: string]: ProcurementCategory } = {};
    const idsToCategories: { [key: number]: ProcurementCategory } = {};
    this.categories.forEach((category) => {
      pathsToCategories[category.path.toArray().toString()] = category; // array as key of object is A-okay
      idsToCategories[category.categoryId] = category;
    });
    this._pathsToCategories = pathsToCategories;
    this._idsToCategories = idsToCategories;
  }

  findCategoryByPath(path: List<string> | Array<string>) {
    checkArg({ path }, ArgTypes.oneOf(ArgTypes.Immutable.list.of(ArgTypes.string), ArgTypes.array.of(ArgTypes.string)));
    return this._pathsToCategories[path.join(',')];
  }

  findCategoryById(id: number) {
    checkArg({ id }, ArgTypes.number);
    return this._idsToCategories[id];
  }

  get numUserCategorizations() {
    // categorizations will increase count stat of child and any ancestor category
    // because of this, aggregating t1 categories correctly counts categorizations
    return this.categories
      .filter(category => category.path.size === 1)
      .reduce((totalCount, t1Category) => totalCount + t1Category.manualCategorizations.count, 0);
  }

  static fromJSON(categories: $TSFixMe) {
    return new ProcurementTaxonomy({
      categories: Set(categories).map(ProcurementCategory.fromJSON),
    });
  }
}
