import { List } from 'immutable';

import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';


// com.tamr.procurify.models.export.CsvFormat.Configuration
export class CsvFormatConfig extends getModelHelpers({
  writeHeader: { type: ArgTypes.bool },
  delimiterCharacter: { type: ArgTypes.string },
  quoteCharacter: { type: ArgTypes.string },
  nullValue: { type: ArgTypes.string },
}, 'CsvFormatConfig')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class CsvFormatConfigRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  get clazz() { return 'com.tamr.procurify.models.export.CsvFormat$Configuration'; }
  toJSON() {
    return {
      '@class': this.clazz,
      delimiterCharacter: this.delimiterCharacter,
      quoteCharacter: this.quoteCharacter,
      nullValue: this.nullValue,
      writeHeader: this.writeHeader,
    };
  }
}

export class ExportConfig extends getModelHelpers({
  formatConfiguration: { type: ArgTypes.instanceOf(CsvFormatConfig) },
  datasetId: { type: ArgTypes.number },
  revision: { type: ArgTypes.number },
  columns: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
}, 'ExportConfig')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ExportConfigRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: $TSFixMe) {
    const { datasetId, revision } = obj;
    return new ExportConfig({
      datasetId,
      revision,
      formatConfiguration: new CsvFormatConfig(obj.formatConfiguration),
      columns: List(obj.columns),
    });
  }
}

export class CsvFormat extends getModelHelpers({
  path: { type: ArgTypes.string },
  configuration: { type: ArgTypes.instanceOf(CsvFormatConfig) },
}, 'CsvFormat')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class CsvFormatRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  get clazz() { return 'com.tamr.procurify.models.export.CsvFormat'; }
  // @ts-expect-error
  toJSON() {
    return {
      '@class': this.clazz,
      path: this.path,
      configuration: this.configuration.toJSON(),
    };
  }
  static fromJSON(obj: $TSFixMe) {
    const { path } = obj;
    return new CsvFormat({
      path,
      configuration: new CsvFormatConfig(obj.configuration),
    });
  }
}

// com.tamr.procurify.models.export.Export
class Export extends getModelHelpers({
  format: { type: ArgTypes.instanceOf(CsvFormat) }, // NB: java class can take JsonFormat as well
  datasetId: { type: ArgTypes.number },
  revision: { type: ArgTypes.number },
  columns: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
  jobId: { type: ArgTypes.nullable(ArgTypes.number) },
  status: { type: ArgTypes.nullable(ArgTypes.string) },
  errorMessage: { type: ArgTypes.nullable(ArgTypes.string) },
}, 'Export')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ExportRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: $TSFixMe) {
    const { datasetId, revision, jobId, status, errorMessage } = obj;
    return new Export({
      datasetId,
      revision,
      jobId,
      format: CsvFormat.fromJSON(obj.format),
      columns: List(obj.columns),
      status,
      errorMessage,
    });
  }
}

export default Export;
