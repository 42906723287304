import { List, Map } from 'immutable';

import { FetchError } from '../api/FetchResult';
import { $TSFixMe } from '../utils/typescript';
import { FileUploadConfig } from './FileUploadStore';

export const UPLOAD_AND_CREATE_COMPLETED = 'FileUpload.uploadAndCreateCompleted';
export const RESET = 'FileUpload.reset';
export const SET_FILE = 'FileUpload.setFile';
export const SET_PARSE_FILE_RESULT = 'FileUpload.setParseFileResult';
export const SET_DESCRIPTION = 'FileUpload.setDescription';
export const SET_SHOULD_PROFILE = 'FileUpload.setShouldProfile';
export const SET_SHOULD_TRUNCATE = 'FileUpload.setShouldTruncate';
export const SET_ERROR_MESSAGE = 'FileUpload.setErrorMessage';
export const SET_VIEW_CURL_COMMAND = 'FileUpload.setViewCurlCommand';
export const UPLOAD_AND_CREATE = 'FileUpload.uploadAndCreate';
export const UPLOAD_AND_CREATE_FAILED = 'FileUpload.uploadAndCreateFailed';
export const UPLOAD_FILE_FAILED = 'FileUpload.uploadFileFailed';
export const TOGGLE_CONFIG_VISIBLE = 'FileUpload.toggleConfigVisible';
export const SET_FILE_UPLOAD_CONFIG = 'FileUpload.setFileUploadConfig';
export const SET_PRIMARY_KEY_COLUMN_NAME = 'FileUpload.setPrimaryKeyColumnName';
export const SET_PRIMARY_KEY_CUSTOM_NAME = 'FileUpload.setPrimaryKeyCustomName';
export const SET_PREVIEW_COLUMN_WIDTH = 'FileUpload.setPreviewColumnWidth';

interface UploadAndCreateCompleted {
  type: typeof UPLOAD_AND_CREATE_COMPLETED
}

interface Reset {
  type: typeof RESET
}

interface SetFile {
  type: typeof SET_FILE
  file: $TSFixMe
}

interface SetParseFileResult {
  type: typeof SET_PARSE_FILE_RESULT
  fields: List<string>
  previewData: List<Map<string, string>>
}

interface SetDescription {
  type: typeof SET_DESCRIPTION
  description: string
}

interface SetShouldProfile {
  type: typeof SET_SHOULD_PROFILE
  shouldProfile: boolean
}

interface SetShouldTruncate {
  type: typeof SET_SHOULD_TRUNCATE
  shouldTruncate: boolean
}

interface SetErrorMessage {
  type: typeof SET_ERROR_MESSAGE
  errorMessage: string | undefined
}

interface SetViewCurlCommand {
  type: typeof SET_VIEW_CURL_COMMAND
  viewCurlCommand: boolean
}

interface UploadAndCreate {
  type: typeof UPLOAD_AND_CREATE
}

interface UploadAndCreateFailed {
  type: typeof UPLOAD_AND_CREATE_FAILED
  errorMessageHeader: string | undefined
  errorMessage: string | undefined
}

/**
 * similar to {@link UploadAndCreateFailed}, but models the error more precisely, allowing the rendering layer
 * to decide how to display the error.
 * multiple stages in the upload process can go wrong, and this better modeling has only been hooked up to the file
 * upload bit ({@link UploadAndCreateFailed} is still being used for, say, failures in adding the dataset to the project)
 */
interface FileUploadUploadFileFailed {
  type: typeof UPLOAD_FILE_FAILED
  error: FetchError
}

interface ToggleConfigVisible {
  type: typeof TOGGLE_CONFIG_VISIBLE
}

interface SetFileUploadConfig {
  type: typeof SET_FILE_UPLOAD_CONFIG
  config: FileUploadConfig
}

interface SetPrimaryKeyColumnName {
  type: typeof SET_PRIMARY_KEY_COLUMN_NAME
  columnName: string | undefined
}

interface SetPrimaryKeyCustomName {
  type: typeof SET_PRIMARY_KEY_CUSTOM_NAME
  customName: string | undefined
}

interface SetPreviewColumnWidth {
  type: typeof SET_PREVIEW_COLUMN_WIDTH
  column: string
  width: number
}

export type FileUploadActionTypes
  = UploadAndCreateCompleted
  | Reset
  | SetFile
  | SetParseFileResult
  | SetDescription
  | SetShouldProfile
  | SetShouldTruncate
  | SetErrorMessage
  | SetViewCurlCommand
  | UploadAndCreate
  | UploadAndCreateFailed
  | FileUploadUploadFileFailed
  | ToggleConfigVisible
  | SetFileUploadConfig
  | SetPrimaryKeyColumnName
  | SetPrimaryKeyCustomName
  | SetPreviewColumnWidth
