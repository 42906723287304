import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import { logout } from '../auth/AuthAsync';
import DropdownList from '../components/DropdownList';
import PopoverTrigger from '../components/PopoverTrigger';
import TamrIcon from '../components/TamrIcon';
import { isAdmin } from '../utils/Authorization';
import PRODUCT_NAME from '../utils/ProductName';
import { getAuthorizedUser, selectDocUrl } from '../utils/Selectors';
import AboutDialog from './AboutDialog';
import style from './TopNavMenu.module.scss';

const TopNavMenu = connect(state => {
  const authorizedUser = getAuthorizedUser(state);
  return {
    isUserAnAdmin: isAdmin(authorizedUser),
    docUrl: selectDocUrl(state),
    username: authorizedUser.username,
  };
}, {
  onLogout: logout,
})(class TopNavMenu extends React.Component {
  state = {
    showAboutDialog: false,
  };

  aboutHandler = () => {
    this.setState({ showAboutDialog: true });
    this.refs.userTrigger.hide();
  };

  documentationHandler = () => {
    window.open(this.props.docUrl);
  };

  getDropdownItems = () => {
    const { onLogout, username } = this.props;
    const dropdownItems = new List([
      {
        iconName: 'book',
        label: 'Documentation',
        onClick: this.documentationHandler,
      },
      {
        iconName: 'laptop',
        label: `About ${PRODUCT_NAME}`,
        onClick: this.aboutHandler,
      },
      {
        iconName: 'person',
        label:
        `Log Out (${username})`,
        onClick: onLogout,
      },
    ]);
    return dropdownItems;
  };

  renderMenuItem = () => {
    return (
      <TamrIcon iconName="more-vert" size={24} className={style.moreVertIcon} />
    );
  };

  render() {
    const popoverContent = <DropdownList items={this.getDropdownItems()} />;
    return (
      <div className="top-nav-item top-nav-icon-item">
        <PopoverTrigger
          ref="userTrigger"
          className={style.menuDropDown}
          placement="bottom"
          content={popoverContent}
        >
          {this.renderMenuItem()}
        </PopoverTrigger>
        <AboutDialog
          onHide={() => this.setState({ showAboutDialog: false })}
          show={this.state.showAboutDialog}
        />
      </div>
    );
  }
});

export default TopNavMenu;
