import { List } from 'immutable';

import * as DatasetClient from '../api/DatasetClient';
import { FetchResult } from '../api/FetchResult';
import * as RecipeClient from '../api/RecipeClient';
import { INDEX_DRAFT, UPDATE_CLUSTER_PROFILE, UPDATE_SOURCE_LIST } from '../constants/RecipeOperations';
import Document from '../models/doc/Document';
import Job from '../models/Job';
import * as GoldenRecordsModule from './GoldenRecordsModule';
import { Rule } from './Rule';

/**
 * GoldenRecordsApi.ts
 * Helper library - wraps client methods for common Golden Records operations
 */

export function getLoggedModuleVersion(
  moduleId: number,
  version: number,
): Promise<FetchResult<GoldenRecordsModule.GoldenRecordsModule>> {
  return RecipeClient.getModuleAtVersion(moduleId, version, GoldenRecordsModule.fromJSON);
}

export function createGoldenRecordsModule(module: GoldenRecordsModule.GoldenRecordsModule, query: {}): Promise<Document<GoldenRecordsModule.GoldenRecordsModule>> {
  return RecipeClient.createModule({
    module,
    fromJSON: GoldenRecordsModule.fromJSON,
    query,
    errorMessage: 'Unable to create Golden Records module',
  });
}

/**
 * Thin wrapper around RecipeClient.getModule that sorts the rules by name
 */
export function fetchGoldenRecordsModuleDocument(moduleId: number): Promise<Document<GoldenRecordsModule.GoldenRecordsModule>> {
  return RecipeClient.getModule(moduleId, GoldenRecordsModule.fromJSON)
    .then(grModuleDoc => grModuleDoc.updateIn(['data', 'rules'], (rules: List<Rule>) =>
      rules.sort((a, b) => a.outputAttributeName.localeCompare(b.outputAttributeName))));
}

export function indexDraft(moduleId: number): Promise<Document<Job>> {
  return RecipeClient.runModuleJob({
    moduleId,
    jobName: INDEX_DRAFT,
    errorMessage: 'Unable to submit update Golden Records draft job',
  });
}

export function updateSourceList(moduleId: number): Promise<Document<Job>> {
  return RecipeClient.runModuleJob({
    moduleId,
    jobName: UPDATE_SOURCE_LIST,
    errorMessage: 'Unable to submit update Golden Records source list job',
  });
}

export function updateClusterProfile(moduleId: number): Promise<Document<Job>> {
  return RecipeClient.runModuleJob({
    moduleId,
    jobName: UPDATE_CLUSTER_PROFILE,
    errorMessage: 'Unable to submit update Golden Records cluster profile job',
  });
}

export type SourceListRecord = {
  sourceName: string,
  isNew: boolean
};
export function fetchSourceList(sourceListName: string): Promise<List<SourceListRecord>> {
  return DatasetClient.fetchDatasetMaterialization(sourceListName)
    .then(recordJsonList => recordJsonList.map(recordJson => {
      return {
        sourceName: recordJson.source_name as string,
        isNew: recordJson.is_new_since_last_publish as boolean,
      };
    }));
}
