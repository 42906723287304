// samgqroberts 2020-08-11
// this may want to be reconciled with AsyncStatus
// perhaps we rally around all AsyncStatus objects containing resource descriptions,
//   and that is the basis for the loader pattern

import { isEqual } from 'underscore';

import { assertNever } from './typescript';

type InitialDescribedAsyncStatus = {
  type: 'initial'
}
type LoadingDescribedAsyncStatus<DataType, ResourceDescription> = {
  type: 'loading',
  previousStatus: InitialDescribedAsyncStatus
    | SuccessDescribedAsyncStatus<DataType, ResourceDescription>
    | ErrorDescribedAsyncStatus<DataType, ResourceDescription>
  resourceDescription: ResourceDescription
}
type SuccessDescribedAsyncStatus<DataType, ResourceDescription> = {
  type: 'success',
  data: DataType,
  resourceDescription: ResourceDescription
}
type ErrorDescribedAsyncStatus<DataType, ResourceDescription> = {
  type: 'error',
  lastSuccessfulData: DataType | null,
  resourceDescription: ResourceDescription,
  message?: string
}

export type DescribedAsyncStatus<DataType, ResourceDescription> =
  InitialDescribedAsyncStatus
  | LoadingDescribedAsyncStatus<DataType, ResourceDescription>
  | SuccessDescribedAsyncStatus<DataType, ResourceDescription>
  | ErrorDescribedAsyncStatus<DataType, ResourceDescription>

export function getLastSuccessfulData<DataType>(
  status: DescribedAsyncStatus<DataType, any>,
): DataType | null {
  if (status.type === 'initial') return null;
  if (status.type === 'loading') {
    if (status.previousStatus.type === 'initial') return null;
    if (status.previousStatus.type === 'success') return status.previousStatus.data;
    if (status.previousStatus.type === 'error') return status.previousStatus.lastSuccessfulData;
    assertNever(status.previousStatus);
  }
  if (status.type === 'success') {
    return status.data;
  }
  if (status.type === 'error') {
    return status.lastSuccessfulData;
  }
  assertNever(status);
}

/**
 * If available, returns the {@link ResourceDescription} of the last completed fetch, whether that fetch succeeded
 *   or errored.
 */
export function getLoadedResourceDescription<DataType, ResourceDescription>(
  status: DescribedAsyncStatus<DataType, ResourceDescription>,
): ResourceDescription | null {
  if (status.type === 'initial') return null;
  if (status.type === 'loading') {
    if (status.previousStatus.type === 'initial') return null;
    if (status.previousStatus.type === 'success') return status.previousStatus.resourceDescription;
    if (status.previousStatus.type === 'error') return status.previousStatus.resourceDescription;
    assertNever(status.previousStatus);
  }
  if (status.type === 'success') {
    return status.resourceDescription;
  }
  if (status.type === 'error') {
    return status.resourceDescription;
  }
  assertNever(status);
}

/**
 * If available, returns the {@link ResourceDescription} of the last initiated fetch.
 * Differs from {@link getLoadedResourceDescription} in that this considers the resource description that is
 *   currently being loaded (if status is type loading) to be of the "last fetch", so will return that.
 */
export function getLastFetchedResourceDescription<DataType, ResourceDescription>(
  status: DescribedAsyncStatus<DataType, ResourceDescription>,
): ResourceDescription | null {
  if (status.type === 'loading') {
    return status.resourceDescription;
  }
  return getLoadedResourceDescription(status);
}

export function initial(): InitialDescribedAsyncStatus {
  return { type: 'initial' };
}

export function toLoading<DataType, ResourceDescription>(
  status: DescribedAsyncStatus<DataType, ResourceDescription>,
  resourceDescription: ResourceDescription,
): LoadingDescribedAsyncStatus<DataType, ResourceDescription> {
  const previousStatus = status.type === 'loading' ? status.previousStatus : status;
  return {
    type: 'loading',
    previousStatus,
    resourceDescription,
  };
}

export function success<DataType, ResourceDescription>(
  data: DataType,
  resourceDescription: ResourceDescription,
): SuccessDescribedAsyncStatus<DataType, ResourceDescription> {
  return {
    type: 'success',
    data,
    resourceDescription,
  };
}

export function toError<DataType, ResourceDescription>(
  status: DescribedAsyncStatus<DataType, ResourceDescription>,
  resourceDescription: ResourceDescription,
): ErrorDescribedAsyncStatus<DataType, ResourceDescription> {
  return {
    type: 'error',
    lastSuccessfulData: getLastSuccessfulData(status),
    resourceDescription,
  };
}

export function resourceDescriptionDiffers<DataType, ResourceDescription>(
  status: DescribedAsyncStatus<DataType, ResourceDescription>,
  resourceDescription: ResourceDescription,
): boolean {
  return !isEqual(getLoadedResourceDescription(status), resourceDescription);
}
