import { List } from 'immutable';

import DashboardTimeSelector, { DashboardTimeSelectorE } from '../constants/DashboardTimeSelector';
import CountsOverTime from '../models/CountsOverTime';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import { StoreReducers } from '../stores/AppAction';
import { ArgTypes, Checker } from '../utils/ArgValidation';


/**
 * A store to track state for cluster publishing / review dialogs
 */
export class PublishClusterDialogStore extends getModelHelpers({
  show: { type: ArgTypes.bool, defaultValue: false },
  showWarning: { type: ArgTypes.bool, defaultValue: false },
  modifiedClusters: { type: ArgTypes.nullable(ArgTypes.number) },
  modifiedClustersLoading: { type: ArgTypes.bool, defaultValue: false },
  newClusters: { type: ArgTypes.nullable(ArgTypes.number) },
  newClustersLoading: { type: ArgTypes.bool, defaultValue: false },
  removedClusters: { type: ArgTypes.nullable(ArgTypes.number) },
  removedClustersLoading: { type: ArgTypes.bool, defaultValue: false },
  clusterCounts: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(CountsOverTime)), defaultValue: List<CountsOverTime>() },
  clusterCountsLoading: { type: ArgTypes.bool, defaultValue: false },
  movedRecords: { type: ArgTypes.nullable(ArgTypes.number) },
  movedRecordsLoading: { type: ArgTypes.bool, defaultValue: false },
  unmovedRecords: { type: ArgTypes.nullable(ArgTypes.number) },
  unmovedRecordsLoading: { type: ArgTypes.bool, defaultValue: false },
  newRecords: { type: ArgTypes.nullable(ArgTypes.number) },
  newRecordsLoading: { type: ArgTypes.bool, defaultValue: false },
  deletedRecords: { type: ArgTypes.nullable(ArgTypes.number) },
  deletedRecordsLoading: { type: ArgTypes.bool, defaultValue: false },
  submitting: { type: ArgTypes.bool, defaultValue: false },
  selectedTimeRange: { type: ArgTypes.valueIn(DashboardTimeSelector) as Checker<DashboardTimeSelectorE>, defaultValue: DashboardTimeSelector.MONTH },
}, 'PolicyEntityCount')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class PolicyEntityCountRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

export const initialState = new PublishClusterDialogStore({});

export const reducers: StoreReducers<PublishClusterDialogStore> = {

  'PublishClusterDialog.resetStats': (state) => {
    return state
      .delete('show')
      .delete('showWarning')
      .delete('modifiedClusters')
      .delete('modifiedClustersLoading')
      .delete('newClusters')
      .delete('newClustersLoading')
      .delete('removedClusters')
      .delete('removedClustersLoading')
      .delete('clusterCounts')
      .delete('clusterCountsLoading')
      .delete('movedRecords')
      .delete('movedRecordsLoading')
      .delete('unmovedRecords')
      .delete('unmovedRecordsLoading')
      .delete('newRecords')
      .delete('newRecordsLoading')
      .delete('deletedRecords')
      .delete('deletedRecordsLoading')
      .delete('submitting')
      .delete('selectedTimeRange');
  },

  'PublishClusterDialog.show': (state) => {
    return state.merge({
      show: true,
    });
  },

  'PublishClusterDialog.hide': (state) => {
    return state.merge({
      show: false,
    });
  },

  'PublishClusterDialog.setSelectedTimeRange': (state, { selectedTimeRange }) => {
    return state.merge({ selectedTimeRange });
  },

  'Suppliers.setClusterChangesFilter': (state) => {
    return state.merge({
      show: false,
    });
  },

  'Suppliers.setRecordChangesFilter': (state) => {
    return state.merge({
      show: false,
    });
  },

  'PublishClusterDialog.showWarning': (state) => {
    return state.merge({
      showWarning: true,
    });
  },

  'PublishClusterDialog.hideWarning': (state) => {
    return state.merge({
      showWarning: false,
    });
  },

  'Suppliers.publishClusters': (state) => {
    return state.merge({
      showWarning: false,
      submitting: true,
    });
  },

  'Suppliers.publishClustersCompleted': (state) => {
    return state.merge({
      submitting: false,
    });
  },

  'Suppliers.publishClustersFailed': (state) => {
    return state.merge({
      submitting: false,
    });
  },

  'PublishClusterDialog.fetchModifiedClusters': (state) => {
    return state.merge({
      modifiedClustersLoading: true,
    });
  },

  'PublishClusterDialog.fetchModifiedClustersCompleted': (state, { modifiedClusters }) => {
    return state.merge({
      modifiedClusters,
      modifiedClustersLoading: false,
    });
  },

  'PublishClusterDialog.fetchNewClusters': (state) => {
    return state.merge({
      newClustersLoading: true,
    });
  },

  'PublishClusterDialog.fetchNewClustersCompleted': (state, { newClusters }) => {
    return state.merge({
      newClusters,
      newClustersLoading: false,
    });
  },

  'PublishClusterDialog.fetchRemovedClusters': (state) => {
    return state.merge({
      removedClustersLoading: true,
    });
  },

  'PublishClusterDialog.fetchRemovedClustersCompleted': (state, { removedClusters }) => {
    return state.merge({
      removedClusters,
      removedClustersLoading: false,
    });
  },

  'PublishClusterDialog.fetchClusterCounts': (state) => {
    return state.merge({
      clusterCountsLoading: true,
    });
  },

  'PublishClusterDialog.fetchClusterCountsCompleted': (state, { clusterCounts }) => {
    return state.merge({
      clusterCounts,
      clusterCountsLoading: false,
    });
  },

  'PublishClusterDialog.fetchClusterCountsFailed': (state) => {
    return state.merge({
      clusterCountsLoading: false,
    });
  },

  'PublishClusterDialog.fetchMovedRecords': (state) => {
    return state.merge({
      movedRecordsLoading: true,
    });
  },

  'PublishClusterDialog.fetchMovedRecordsCompleted': (state, { movedRecords }) => {
    return state.merge({
      movedRecords,
      movedRecordsLoading: false,
    });
  },

  'PublishClusterDialog.fetchUnmovedRecords': (state) => {
    return state.merge({
      unmovedRecordsLoading: true,
    });
  },

  'PublishClusterDialog.fetchUnmovedRecordsCompleted': (state, { unmovedRecords }) => {
    return state.merge({
      unmovedRecords,
      unmovedRecordsLoading: false,
    });
  },

  'PublishClusterDialog.fetchNewRecords': (state) => {
    return state.merge({
      newRecordsLoading: true,
    });
  },

  'PublishClusterDialog.fetchNewRecordsCompleted': (state, { newRecords }) => {
    return state.merge({
      newRecords,
      newRecordsLoading: false,
    });
  },

  'PublishClusterDialog.fetchDeletedRecords': (state) => {
    return state.merge({
      deletedRecordsLoading: true,
    });
  },

  'PublishClusterDialog.fetchDeletedRecordsCompleted': (state, { deletedRecords }) => {
    return state.merge({
      deletedRecords,
      deletedRecordsLoading: false,
    });
  },
};
