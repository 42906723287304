import Immutable, { Set } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Term from '../../components/Term';
import TooltipTrigger from '../../components/TooltipTrigger';
import LabelConsensus, { LabelConsensusE } from '../../constants/LabelConsensus';
import RecordPairLabelFilter, { RecordPairLabelFilterE } from '../../constants/RecordPairLabelFilter';
import { AppAction } from '../../stores/AppAction';
import AppState from '../../stores/AppState';
import PRODUCT_NAME from '../../utils/ProductName';
import VerifiedIcon from '../VerifiedIcon';
import AllResponsesFilter from './AllResponsesFilter';
import FilterSection from './FilterSection';
import HasInferredResponsesFilter from './HasInferredResponsesFilter';
import HasResponsesFilter from './HasResponsesFilter';
import MyResponseFilter from './MyResponseFilter';
import PairConfidenceFilter from './PairConfidenceFilter';
import SelectFilter from './SelectFilter';


const msg = {
  UNFILTERED: 'No Filter',
  DOES_NOT_EXIST: 'Nothing',
  EXISTS: 'Anything',
  MATCH: 'Match',
  NON_MATCH: 'No Match',
} as const;

const humanMsg = {
  AGREE: `The same as ${PRODUCT_NAME}'s`,
  DISAGREE: `Different from ${PRODUCT_NAME}'s`,
} as const;

const suggMsg = {
  AGREE: 'The same as mine',
  DISAGREE: 'Different from mine',
} as const;

const inconsistentOptionPairings = [ // [0] for human, [1] for suggested
  [msg.UNFILTERED, suggMsg.DISAGREE],
  [msg.UNFILTERED, suggMsg.AGREE],
  [humanMsg.DISAGREE, msg.UNFILTERED],
  [humanMsg.AGREE, msg.UNFILTERED],
  [msg.DOES_NOT_EXIST, suggMsg.DISAGREE],
  [msg.DOES_NOT_EXIST, suggMsg.AGREE],
  [humanMsg.DISAGREE, msg.DOES_NOT_EXIST],
  [humanMsg.AGREE, msg.DOES_NOT_EXIST],
  [humanMsg.DISAGREE, suggMsg.DISAGREE],
  [humanMsg.DISAGREE, suggMsg.AGREE],
  [humanMsg.AGREE, suggMsg.DISAGREE],
  [humanMsg.AGREE, suggMsg.AGREE],
] as const;

function getFiltersForGenericMessage(message: string) {
  switch (message) {
    case msg.UNFILTERED:
      return [];
    case msg.DOES_NOT_EXIST:
      return [RecordPairLabelFilter.MISSING];
    case msg.EXISTS:
      return [RecordPairLabelFilter.MATCH, RecordPairLabelFilter.NON_MATCH];
    case msg.MATCH:
      return [RecordPairLabelFilter.MATCH];
    case msg.NON_MATCH:
      return [RecordPairLabelFilter.NON_MATCH];
    default:
      return []; // labelConsensus is involved, so label-specific filters must be empty
  }
}

function getGenericMessageForFilters(filters: Set<RecordPairLabelFilterE>, consensus: LabelConsensusE | undefined) {
  if ((filters.size === 0 || filters.size === 3) && !consensus) {
    return msg.UNFILTERED;
  }
  if (Immutable.is(filters, Set.of(RecordPairLabelFilter.MISSING))) {
    return msg.DOES_NOT_EXIST;
  }
  if (Immutable.is(filters, Set.of(RecordPairLabelFilter.MATCH, RecordPairLabelFilter.NON_MATCH))) {
    return msg.EXISTS;
  }
  if (Immutable.is(filters, Set.of(RecordPairLabelFilter.MATCH))) {
    return msg.MATCH;
  }
  if (Immutable.is(filters, Set.of(RecordPairLabelFilter.NON_MATCH))) {
    return msg.NON_MATCH;
  }
}

function getInternalLabelFilterState({ manualLabelFilters: human, suggestedLabelFilters: sugg, labelConsensus: consensus, onSetLabelFilters }: {
  manualLabelFilters: Set<RecordPairLabelFilterE>
  suggestedLabelFilters: Set<RecordPairLabelFilterE>
  labelConsensus: LabelConsensusE | undefined
  onSetLabelFilters: SetLabelFiltersActionCreator
}) {
  let humanLabelMessage: string | undefined = getGenericMessageForFilters(human, consensus);
  let suggestedLabelMessage: string | undefined = getGenericMessageForFilters(sugg, consensus);
  if (consensus === LabelConsensus.AGREE) {
    // assumed from upstream validation to only be exists, match or non
    if (suggestedLabelMessage && !humanLabelMessage) {
      humanLabelMessage = humanMsg.AGREE;
    }
    if (humanLabelMessage && !suggestedLabelMessage) {
      suggestedLabelMessage = suggMsg.AGREE;
    }
  }
  if (consensus === LabelConsensus.DISAGREE) {
    if (suggestedLabelMessage && !humanLabelMessage) {
      humanLabelMessage = humanMsg.DISAGREE;
    }
    if (humanLabelMessage && !suggestedLabelMessage) {
      suggestedLabelMessage = suggMsg.DISAGREE;
    }
  }
  if (!humanLabelMessage || !suggestedLabelMessage) {
    console.error('unsupported label filter combination!'
      + ' human: ', human.toArray(), ', sugg: ', sugg.toArray(), ', consensus: ', consensus);
    humanLabelMessage = msg.UNFILTERED;
    suggestedLabelMessage = msg.UNFILTERED;
    onSetLabelFilters(Set(), Set(), undefined);
  }
  return [humanLabelMessage, suggestedLabelMessage];
}

function getLabelOptions(currentMessages: string[], forHumanLabel: boolean) {
  const thisPairingIndex = forHumanLabel ? 0 : 1;
  const otherPairingIndex = forHumanLabel ? 1 : 0;
  const otherLabelOption = forHumanLabel ? currentMessages[1] : currentMessages[0];
  const baseOptions = Object.assign({}, msg, forHumanLabel ? humanMsg : suggMsg);
  const currentInconsistentOptionPairings = _.filter(inconsistentOptionPairings,
    pairing => pairing[otherPairingIndex] === otherLabelOption,
  );
  const inconsistentLabelOptions = _.map(currentInconsistentOptionPairings,
    pairing => pairing[thisPairingIndex],
  );
  const currentLabelOptions = _.difference(_.values(baseOptions), inconsistentLabelOptions);
  return currentLabelOptions.map(option => {
    return { display: option, value: option };
  });
}

function getFilterStateForOption(human: string, sugg: string) {
  const humanFilters = getFiltersForGenericMessage(human);
  const suggFilters = getFiltersForGenericMessage(sugg);
  let labelConsensus;
  if (human === humanMsg.AGREE || sugg === suggMsg.AGREE) {
    labelConsensus = LabelConsensus.AGREE;
  }
  if (human === humanMsg.DISAGREE || sugg === suggMsg.DISAGREE) {
    labelConsensus = LabelConsensus.DISAGREE;
  }
  return { humanFilters, suggFilters, labelConsensus };
}

function setFilters(humanOption: string, suggOption: string, onSetLabelFilters: SetLabelFiltersActionCreator) {
  const { humanFilters, suggFilters, labelConsensus } = getFilterStateForOption( // (human, sugg)
    humanOption, suggOption);
  onSetLabelFilters(
    humanFilters ? Set(humanFilters) : Set(),
    suggFilters ? Set(suggFilters) : Set(),
    labelConsensus as LabelConsensusE | undefined,
  );
}

type SetLabelFiltersActionCreator = (
  humanFilters: Set<RecordPairLabelFilterE>,
  suggFilters: Set<RecordPairLabelFilterE>,
  labelConsensus: LabelConsensusE | undefined
) => AppAction;

const onSetLabelFiltersActionCreator: SetLabelFiltersActionCreator = (humanFilters, suggFilters, labelConsensus) => ({ type: 'RecordPairs.setLabelFilters', humanFilters, suggFilters, labelConsensus });

const ResponseFilterSection = connect(({ recordPairs: { labelConsensus, manualLabelFilters, suggestedLabelFilters } }: AppState) => {
  return { labelConsensus: labelConsensus || undefined, manualLabelFilters, suggestedLabelFilters };
}, {
  onSetLabelFilters: onSetLabelFiltersActionCreator,
})(({ labelConsensus, manualLabelFilters, suggestedLabelFilters, onSetLabelFilters }) => {
  const messages = getInternalLabelFilterState({ labelConsensus, manualLabelFilters, suggestedLabelFilters, onSetLabelFilters }); // [0] for human, [1] for suggested
  const humanLabelOptions = getLabelOptions(messages, true);
  const suggLabelOptions = getLabelOptions(messages, false);
  const humanLabelValue = messages[0];
  const suggLabelValue = messages[1];
  const humanSelectorOnChange = (human: string) => setFilters(human, suggLabelValue, onSetLabelFilters);
  const suggSelectorOnChange = (sugg: string) => setFilters(humanLabelValue, sugg, onSetLabelFilters);
  return (
    <FilterSection className="response-filter-section" titleLabel="Responses">
      <HasResponsesFilter />
      <HasInferredResponsesFilter />
      <MyResponseFilter />
      <AllResponsesFilter />
      <SelectFilter
        onChange={humanSelectorOnChange}
        label={(
          <span>
            Verified response&nbsp;&nbsp;
            <TooltipTrigger
              placement="top"
              content={<span>{PRODUCT_NAME} only learns from responses that have been verified by curators or admins. These help {PRODUCT_NAME} suggest better <Term>pair</Term> labels and <Term>suppliers</Term>.</span>}
            >
              <VerifiedIcon size={13} />
            </TooltipTrigger>
          </span>
        )}
        values={humanLabelOptions}
        value={humanLabelValue}
      />
      <SelectFilter
        onChange={suggSelectorOnChange}
        label={`${PRODUCT_NAME}'s response`}
        values={suggLabelOptions}
        value={suggLabelValue}
      />
      <PairConfidenceFilter />
    </FilterSection>
  );
});

export default ResponseFilterSection;
