import { List, Map, Record, Set } from 'immutable';

import Dataset from '../models/Dataset';
import DatasetStatus from '../models/DatasetStatus';
import Document from '../models/doc/Document';
import Export from '../models/Export';
import Model from '../models/Model';
import ProfilingInfo from '../models/ProfilingInfo';
import { FETCH_COMPLETED } from '../projects/ProjectsActionTypes';
import { getProjectGeneratedIdsByProjectId, getUnifiedDatasetIdsByProjectId } from '../projects/ProjectsStore';
import { ArgTypes } from '../utils/ArgValidation';
import DatasetCatalogFilterType from './DatasetCatalogFilterType';
import { UPLOAD_AND_CREATE_COMPLETED } from './FileUploadActionTypes';

const FilterInfo = Record({
  page: 0,
  pageSize: 100,
  search: '',
  filterType: DatasetCatalogFilterType.ALL,
  fetchSequence: 1,
  unifiedDatasetIds: Set(),
  projectGeneratedIds: Set(),
});

// argument is entire state, not just datasetCatalog state
export const getFilterInfo = ({
  datasetCatalog: { paging: { page, pageSize, search, filterType }, fetchSequence },
  projects,
}) => {
  const unifiedDatasetIds = getUnifiedDatasetIdsByProjectId(projects).toSet();
  const projectGeneratedIds = getProjectGeneratedIdsByProjectId(projects).toSet().flatMap(s => s);
  return new FilterInfo({
    page,
    pageSize,
    search,
    filterType,
    fetchSequence,
    unifiedDatasetIds,
    projectGeneratedIds,
  });
};

export const initialState = new (Model({
  // boolean indicating whether store believes that NO datasets have been loaded
  noDatasets: { type: ArgTypes.bool, defaultValue: false },
  // map of relevant profiling infos by dataset name
  profiling: { type: ArgTypes.Immutable.map.of(ArgTypes.instanceOf(ProfilingInfo), ArgTypes.string), defaultValue: new Map() },
  // map of relevant export configs by dataset id
  exporting: { type: ArgTypes.Immutable.map.of(Document.argTypeWithNestedClass(Export), ArgTypes.number), defaultValue: new Map() },
  // map of relevant dataset status by dataset name
  status: { type: ArgTypes.Immutable.map.of(ArgTypes.instanceOf(DatasetStatus), ArgTypes.string), defaultValue: new Map() },
  // list containing current page of dataset docs (no profiling or exporting information)
  datasets: { type: ArgTypes.Immutable.list.of(Document.argTypeWithNestedClass(Dataset)), defaultValue: new List() },

  // current page / filter state
  paging: { type: ArgTypes.instanceOf(Record),
    defaultValue: new (Model({
      page: { type: ArgTypes.number, defaultValue: 0 },
      pageSize: { type: ArgTypes.number, defaultValue: 100 },
      search: { type: ArgTypes.string, defaultValue: '' },
      filterType: { type: DatasetCatalogFilterType.argType, defaultValue: DatasetCatalogFilterType.ALL },
      numDatasets: { type: ArgTypes.number, defaultValue: 0 },
      numPages: { type: ArgTypes.number, defaultValue: 1 },
    }))() },

  // whether a request is currently in flight
  loading: { type: ArgTypes.nullable(ArgTypes.bool), defaultValue: null },
  loadedFilterInfo: { type: ArgTypes.any },
  fetchSequence: { type: ArgTypes.number, defaultValue: 1 },
  selectedRows: { type: ArgTypes.Immutable.set.of(ArgTypes.number), defaultValue: Set() },

  // for deletes
  confirmingDeleteForDatasetNames: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: Set() },
  descendants: { type: ArgTypes.Immutable.map.of(ArgTypes.Immutable.list.of(Document.argTypeWithNestedClass(Dataset)), ArgTypes.string), defaultValue: Map() },
  loadingDescendants: { type: ArgTypes.bool, defaultValue: false },
  deleting: { type: ArgTypes.bool, defaultValue: false },

  // for file uploads
  showFileUploadDialog: { type: ArgTypes.bool, defaultValue: false },
}))();

const reloadDatasets = (state) => state.update('fetchSequence', x => x + 1);
const clearSelection = (state) => state.delete('selectedRows').delete('lastSelectedRow');
const resetPage = (state) => state.deleteIn(['paging', 'page']);

export const reducers = {
  [FETCH_COMPLETED]: reloadDatasets,
  'DatasetCatalog.reload': reloadDatasets,
  'Tags.linkTagCompleted': reloadDatasets,
  'Tags.removeLinkCompleted': reloadDatasets,
  'Datasets.profileDatasetCompleted': reloadDatasets,
  'DatasetCatalog.fetch': (state) => {
    return state.set('loading', true);
  },
  'DatasetCatalog.fetchCompleted': (state, { datasets, noDatasets, numDatasets, numPages, loadedFilterInfo, profiling, exporting, status }) => {
    return state.merge({ loadedFilterInfo, datasets, profiling, exporting, status, loading: false, noDatasets })
      .update('paging', p => p.merge({ numDatasets, numPages }));
  },
  'DatasetCatalog.setPage': (state, { pageNum }) => {
    return clearSelection(state).setIn(['paging', 'page'], Math.min(state.paging.numPages - 1, pageNum));
  },
  'DatasetCatalog.setPageSize': (state, { pageSize }) => {
    return clearSelection(resetPage(state)).setIn(['paging', 'pageSize'], pageSize);
  },
  'DatasetCatalog.setSearchString': (state, { search }) => {
    return clearSelection(resetPage(state)).mergeDeep({ paging: { search } });
  },
  'DatasetCatalog.setFilterType': (state, { filterType }) => {
    return clearSelection(resetPage(state)).setIn(['paging', 'filterType'], filterType);
  },
  'DatasetCatalog.beginConfirmingDeleteAndFetchDescendants': (state, { datasetNames }) => {
    return state.merge({ confirmingDeleteForDatasetNames: datasetNames, loadingDescendants: true }).delete('descendants');
  },
  'DatasetCatalog.fetchDescendantsCompleted': (state, { descendants }) => {
    // if user canceled before fetch came back, retain 0 state
    if (state.confirmingDeleteForDatasetNames === undefined) {
      return state.delete('descendants').delete('loadingDescendants');
    }
    return state.merge({ descendants, loadingDescendants: false });
  },
  'DatasetCatalog.cancelConfirmingDelete': (state) => {
    return state.delete('confirmingDeleteForDatasetNames');
  },
  'DatasetCatalog.deleteDataset': (state) => {
    return clearSelection(state).set('deleting', true);
  },
  'DatasetCatalog.deleteDatasetCompleted': (state) => {
    return reloadDatasets(state).delete('confirmingDeleteForDatasetNames').delete('deleting');
  },
  'DatasetCatalog.showFileUploadDialog': (state) => {
    return state.set('showFileUploadDialog', true);
  },
  'DatasetCatalog.hideFileUploadDialog': (state) => {
    return state.set('showFileUploadDialog', false);
  },
  [UPLOAD_AND_CREATE_COMPLETED]: (state) => {
    return reloadDatasets(state).set('showFileUploadDialog', false);
  },
  'DatasetCatalog.selectRows': (state, { selectedRows }) => {
    return state.merge({ selectedRows });
  },
  'AddDataset.createExternalDatasetCompleted': (state) => {
    return reloadDatasets(state);
  },
};
