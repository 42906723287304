import { List, Map } from 'immutable';

import { UPLOAD_AND_CREATE_COMPLETED } from '../datasets/FileUploadActionTypes';
import { SUBMIT_MEMBERSHIP_CHANGES_COMPLETED } from '../datasets/ProjectDatasetCatalogActionTypes';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import { StoreReducers } from '../stores/AppAction';
import { ArgTypes, checkArg, Checker } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import MimeType, { MimeTypeE } from './MimeType';
import PathElement from './PathElement';
import SampleRecords from './SampleRecords';
import StorageNode from './StorageNode';
import StorageProvider from './StorageProvider';
import { DELIMITER, ESCAPE_CHAR, QUOTE_CHAR } from './TableConfig';


class ConfigOption extends getModelHelpers({
  displayName: { type: ArgTypes.string },
  values: { type: ArgTypes.array.of(ArgTypes.object.withShape({ value: ArgTypes.string, display: ArgTypes.string })) },
  selectedValue: { type: ArgTypes.string },
}, 'ConfigOption')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ConfigOptionRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

const CsvConfig = Map({
  columnSeparator: new ConfigOption({
    displayName: 'Column Separator',
    values: [
      { value: ',', display: ',' },
      { value: '\\t', display: '\\t' },
      { value: ';', display: ';' },
      { value: '|', display: '|' },
      { value: '~', display: '~' },
      { value: '^', display: '^' },
    ],
    selectedValue: ',',
  }),
  quoteCharacter: new ConfigOption({
    displayName: 'Quote Character',
    values: [
      { value: '"', display: '"' },
      { value: "'", display: "'" },
      { value: '\\', display: '\\' },
    ],
    selectedValue: '"',
  }),
  escapeCharacter: new ConfigOption({
    displayName: 'Escape Character',
    values: [
      { value: '"', display: '"' },
      { value: "'", display: "'" },
      { value: '\\', display: '\\' },
    ],
    selectedValue: '"',
  }),
});

export function mapifyCsvConfig(config: Map<string, ConfigOption> | null): Map<string, $TSFixMe> {
  checkArg({ config }, ArgTypes.orNull(ArgTypes.Immutable.map.of(ConfigOption.argType, ArgTypes.string)));
  if (!config) {
    return Map();
  }
  return Map({
    [DELIMITER]: config.get('columnSeparator')?.selectedValue,
    [QUOTE_CHAR]: config.get('quoteCharacter')?.selectedValue,
    [ESCAPE_CHAR]: config.get('escapeCharacter')?.selectedValue,
  });
}

class ExternalStorageContentStore extends getModelHelpers({
  storageProviders: { type: ArgTypes.Immutable.list.of(StorageProvider.argType), defaultValue: List<StorageProvider>() },
  storageContent: { type: ArgTypes.Immutable.map.of(ArgTypes.Immutable.map.of(PathElement.argType, ArgTypes.string), ArgTypes.string), defaultValue: Map<string, Map<string, PathElement>>() },
  selectedProvider: { type: ArgTypes.orNull(ArgTypes.string), defaultValue: null },
  selectedNode: { type: ArgTypes.orNull(StorageNode.argType), defaultValue: null },
  selectedNodeType: { type: ArgTypes.orNull(ArgTypes.valueIn(MimeType.values)) as Checker<MimeTypeE | null>, defaultValue: null },
  datasetName: { type: ArgTypes.string, defaultValue: '' },
  datasetDescription: { type: ArgTypes.orNull(ArgTypes.string), defaultValue: null },
  datasetNameDraft: { type: ArgTypes.orNull(ArgTypes.string), defaultValue: null },
  selectedNodeConfig: { type: ArgTypes.orNull(ArgTypes.Immutable.map.of(ConfigOption.argType, ArgTypes.string)), defaultValue: null },
  idColumn: { type: ArgTypes.string, defaultValue: '' },
}, 'ExternalStorageContentStore')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ExternalStorageContentStoreRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

export const initialState = new ExternalStorageContentStore({});

function getParentNode({ content, node }: { content: Map<string, PathElement>, node: StorageNode }): StorageNode | null {
  checkArg({ content }, ArgTypes.Immutable.map.of(PathElement.argType));
  checkArg({ node }, StorageNode.argType);
  const path = node.parent;
  let i = 1;
  if (!path) return null;
  const firstPathElement = path.get(0);
  let collection = firstPathElement ? content.get(firstPathElement) : undefined;
  if (!collection) return null;
  while (i !== path.size) {
    const key = path.get(i++);
    if (!collection?.get('children') || !collection?.get('children')?.has(key)) {
      return null;
    }
    collection = collection?.get('children')?.get(key);
  }
  return collection?.node || null;
}

/**
 * Returns the list of the child nodes of a given path, null if they haven't been loaded yet
 */
export function getChildStorageNodes({ path, content }: { path: List<string>, content: Map<string, PathElement> }): List<StorageNode> | null {
  checkArg({ path }, ArgTypes.Immutable.list.of(ArgTypes.string));
  checkArg({ content }, ArgTypes.Immutable.map.of(PathElement.argType));
  if (content.isEmpty()) return List();
  if (path.isEmpty()) return content.valueSeq().map(pathElement => pathElement.node).toList();
  let i = 1;
  const firstPathElement = path.get(0);
  let collection = firstPathElement ? content.get(firstPathElement) : undefined;
  if (!collection) return null;
  while (i !== path.size) {
    const key = path.get(i++);
    if (!collection?.get('children') || !collection?.get('children')?.has(key)) {
      return null;
    }
    collection = collection?.get('children')?.get(key);
  }
  return collection?.children?.valueSeq().filter(e => !!e).map(c => c?.node).toList() || null;
}

/**
 * Returns a sample of the file at the given path, null if no sample exists.
 */
export function getStorageNodeSample({ path, content }: { path: List<string>, content: Map<$TSFixMe, PathElement> }): SampleRecords | null {
  if (content.isEmpty()) return null;
  if (path.isEmpty()) return null;
  let i = 1;
  let collection = content.get(path.get(0));
  if (!collection) return null;
  while (i !== path.size) {
    const key = path.get(i++);
    if (!collection?.get('children') || !collection?.get('children')?.has(key)) {
      return null;
    }
    collection = collection?.get('children')?.get(key);
  }

  return collection?.get('sample') || null;
}

export const reducers: StoreReducers<ExternalStorageContentStore> = {
  'AddDataset.hideDialog': (state) => {
    return state.clear();
  },
  'AddDataset.addDatasetFailed': (state) => {
    return state.clear();
  },
  [UPLOAD_AND_CREATE_COMPLETED]: (state) => {
    return state.clear();
  },
  [SUBMIT_MEMBERSHIP_CHANGES_COMPLETED]: (state) => {
    return state.clear();
  },
  'ProjectDatasetCatalog.submitMembershipChangesFailed': (state) => {
    return state.clear();
  },
  'AddDataset.setExternalDatasetName': (state, { name }) => {
    return state.set('datasetName', name);
  },
  'AddDataset.setExternalDatasetDescription': (state, { description }) => {
    return state.set('datasetDescription', description);
  },
  'AddDataset.setExternalStorageProvider': (state, { name }) => {
    return state.set('selectedProvider', name).delete('selectedNode').delete('selectedNodeType').delete('selectedNodeConfig');
  },
  'AddDataset.setExternalStorageProviderCompleted': (state, { name, nodes }) => {
    return state.update('storageContent', current => {
      return current.set(name, nodes.toMap().mapEntries(([, node]) => [node.name, new PathElement({ node, children: null })]));
    });
  },
  'AddDataset.selectExternalStorageNode': (state, { node }) => {
    return state.merge({
      selectedNode: node,
      selectedNodeConfig: node.mimeType === MimeType.CSV ? CsvConfig : null,
    }).delete('selectedNodeType');
  },
  'AddDataset.selectExternalStorageNodeCompleted': (state, { selectedProvider, nodes, path, sampleRecords }) => {
    const contents = state.storageContent.get(selectedProvider);

    const basePath = path.flatMap((value, index, array) => (array.size - 1 !== index ? [value, 'children'] : [value]));

    // If sampleRecords is not null/undefined, it means the node represents a dataset and has no children.
    // Insert either 'sample' or 'children' into the path so that this path can be used by the `setIn` function.
    const newPath = sampleRecords ? basePath.push('sample') : basePath.push('children');
    const newData = sampleRecords || nodes.toOrderedMap().mapEntries(([, node]) => [node.name, new PathElement({ node, children: null, sample: null })]);

    return state.setIn(
      ['storageContent', selectedProvider],
      contents?.setIn(newPath.toArray(), newData),
    );
  },
  'AddDataset.selectExternalStorageNodeGroup': (state, { node, nodeType }) => {
    if (!state.selectedProvider) {
      console.error('Cannot selectExternalStorageNodeGroup - selectedProvider is null');
      return state;
    }
    const content = state.storageContent.get(state.selectedProvider);
    if (!content) {
      console.error(`Cannot selectExternalStorageNodeGroup - no storage content for selected provider ${state.selectedProvider}`);
      return state;
    }
    return state.merge({
      selectedNode: getParentNode({ content, node }),
      selectedNodeType: nodeType,
      selectedNodeConfig: nodeType === MimeType.CSV ? CsvConfig : null,
    });
  },
  'AddDataset.setConfigValue': (state, { key, value }) => {
    return state.update('selectedNodeConfig', current => {
      const newValue = current?.get(key)?.set('selectedValue', value);
      if (current && newValue) {
        return current.set(key, newValue);
      }
      return current;
    });
  },
  'AddDataset.fetchStorageProvidersCompleted': (state, { externalStorageProviders }) => {
    return state.set('storageProviders', externalStorageProviders);
  },
  'AddDataset.setIdColumn': (state, { idColumn }) => {
    return state.merge({ idColumn });
  },
  'AddDataset.createExternalDatasetCompleted': (state) => {
    return state.clear();
  },
  'AddDataset.createExternalDatasetFailed': (state) => {
    return state.clear();
  },
};
