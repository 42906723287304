import React from 'react';
import { connect } from 'react-redux';

import Chrome from '../chrome/Chrome';
import EnrichmentMain from '../enrichment/EnrichmentMain';
import style from '../enrichment/EnrichmentPage.module.scss';

const EnrichmentPage = connect(() => ({}))(() => {
  return (
    <Chrome
      className={style.page}
    >
      <EnrichmentMain />
    </Chrome>
  );
});

export default EnrichmentPage;
