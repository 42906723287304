import { List, Map } from 'immutable';

import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../../models/Model';
import { ArgTypes, checkArg } from '../../utils/ArgValidation';
import { $TSFixMe } from '../../utils/typescript';
import Interval from './Interval';
import Lint from './Lint';
import { RecordType, TypeArgType } from './Types';

export class StatementResult extends getModelHelpers({
  interval: { type: ArgTypes.instanceOf(Interval) },
  inputType: { type: ArgTypes.instanceOf(RecordType) },
}, 'StatementResult')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class StatementResultRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: $TSFixMe) {
    return new StatementResult({
      interval: Interval.fromJSON(obj.interval),
      inputType: RecordType.fromJSON(obj.inputType),
    });
  }
}

export class OperationResult extends getModelHelpers({
  lints: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(Lint)) },
  statements: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(StatementResult)) },
}, 'OperationResult')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class OperationResultRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: $TSFixMe) {
    return new OperationResult({
      lints: List(obj.lints).map(Lint.fromJSON),
      statements: List(obj.statements).map(statement => StatementResult.fromJSON(statement)),
    });
  }
}

export class OperationListResult extends getModelHelpers({
  outputType: { type: ArgTypes.instanceOf(RecordType) },
  namedTypes: { type: ArgTypes.Immutable.map.of(ArgTypes.instanceOf(RecordType), ArgTypes.string) },
  operations: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(OperationResult)) },
}, 'OperationListResult')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class OperationListResultRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromCompressedJSON(compressed: $TSFixMe) {
    const { outputType, namedTypes, operations } = compressed;
    const types = compressed.types.map((type: $TSFixMe) => RecordType.fromJSON(type));
    return new OperationListResult({
      outputType: types[outputType],
      // @ts-expect-error
      namedTypes: Map(namedTypes).map(typeIndex => types[typeIndex]),
      operations: List(operations).map(({ lints, statements }) => new OperationResult({
        lints: List(lints).map(Lint.fromJSON),
        statements: List(statements).map(({ interval, inputType }) => new StatementResult({
          interval: Interval.fromJSON(interval),
          inputType: types[inputType],
        })),
      })),
    });
  }
  static fromJSON(obj: $TSFixMe) {
    return new OperationListResult({
      outputType: RecordType.fromJSON(obj.outputType),
      // @ts-expect-error
      namedTypes: Map(obj.namedTypes).map(type => RecordType.fromJSON(type)),
      operations: List(obj.operations).map(opResult => OperationResult.fromJSON(opResult)),
    });
  }
  getFieldType(
    operationIndex: $TSFixMe,
    cursorLine: $TSFixMe,
    cursorPosition: $TSFixMe,
    fieldName: $TSFixMe,
    hasBeenReassigned: $TSFixMe,
  ) {
    const { operations, outputType } = this;
    const inLastOperation = operationIndex === operations.size - 1;
    const statements = operations.get(operationIndex) &&
      // @ts-expect-error
      operations.get(operationIndex).get('statements');

    // return undefined if there are no statements at the operation index
    if (!statements) {
      return undefined;
    }

    // loop through all statements to find the one we are in
    const statementIndex = statements.findIndex((statement) => {
      const { startLine, endLine, startPosition, endPosition } = statement.interval;
      const onStartLine = startLine === cursorLine + 1;
      const onEndLine = endLine === cursorLine + 1;
      // this boolean logic defines whether we are in the current statement
      return ((startLine < cursorLine + 1 && cursorLine + 1 < endLine)
        || (onStartLine && !(onEndLine) && startPosition <= cursorPosition)
        || (onEndLine && !(onStartLine) && cursorPosition <= endPosition)
        || (onStartLine && onEndLine && startPosition <= cursorPosition && cursorPosition <= endPosition));
    });

    // if we haven't found the statementIndex, don't show the dataType
    if (statementIndex === -1) {
      return;
    }
    const inLastStatement = statementIndex === statements.size - 1;

    // gets the dataType given the analysis result, the operation index, and the statement index
    // @ts-expect-error
    const getDataType = (theOperationIndex, theStatementIndex) => {
      const operation = operations.get(theOperationIndex);
      const statement = operation ? operation.statements.get(theStatementIndex) : undefined;
      return statement ? statement.inputType.getField(fieldName) : undefined;
    };

    let dataType; // the data type that we are going to show in a tooltip

    // if we are reassigning our attribute in a 'rename_expr'
    if (hasBeenReassigned) {
      // if we are in the last statement of the operation
      if (inLastStatement) {
        // if we are in the last operation, we get the global output type:
        if (inLastOperation) {
          dataType = outputType.getField(fieldName);
        } else { // else, take the inputType from the next operation:
          dataType = getDataType(operationIndex + 1, 0);
        }
      } else if (statementIndex < statements.size) { // else if we are in a reassignment but not in the last statement:
        dataType = getDataType(operationIndex, statementIndex + 1);
      }
    } else if (statementIndex < statements.size) { // else if we are not on a reassignment, we take the input type:
      dataType = getDataType(operationIndex, statementIndex);
    }
    // dataType can return undefined if the supplied 'fieldName' isn't present in the 'fields' map
    checkArg({ dataType }, ArgTypes.orUndefined(TypeArgType));
    return dataType;
  }
}

export class CompressedStatementResult extends getModelHelpers({
  interval: { type: ArgTypes.instanceOf(Interval) },
  inputType: { type: ArgTypes.number },
}, 'CompressedStatementResult')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class CompressedStatementResultRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: $TSFixMe) {
    return new CompressedStatementResult({
      interval: Interval.fromJSON(obj.interval),
      inputType: obj.inputType,
    });
  }
}

export class CompressedOperationResult extends getModelHelpers({
  lints: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(Lint)) },
  statements: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(CompressedStatementResult)) },
}, 'CompressedOperationResult')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class CompressedOperationResultRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: $TSFixMe) {
    return new CompressedOperationResult({
      lints: List(obj.lints).map(Lint.fromJSON),
      statements: List(obj.statements).map(statement => CompressedStatementResult.fromJSON(statement)),
    });
  }
}

export class CompressedOperationListResult extends getModelHelpers({
  types: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(RecordType)) },
  outputType: { type: ArgTypes.number },
  namedTypes: { type: ArgTypes.Immutable.map.of(ArgTypes.number, ArgTypes.string) },
  operations: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(CompressedOperationResult)) },
}, 'CompressedOperationListResult')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class CompressedOperationListResultRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: $TSFixMe) {
    return new CompressedOperationListResult({
      types: List(obj.types).map(type => RecordType.fromJSON(type as $TSFixMe)),
      outputType: obj.outputType,
      namedTypes: Map(obj.namedTypes),
      operations: List(obj.operations).map(opResult => CompressedOperationResult.fromJSON(opResult)),
    });
  }
}

export class AnalysisInput extends getModelHelpers({
  activeType: { type: ArgTypes.instanceOf(RecordType) },
  operationList: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
  referenceTypes: { type: ArgTypes.Immutable.map.of(ArgTypes.instanceOf(RecordType), ArgTypes.string) },
  activeKey: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
  referenceKeys: { type: ArgTypes.Immutable.map.of(ArgTypes.Immutable.list.of(ArgTypes.string), ArgTypes.string) },
}, 'AnalysisInput')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class AnalysisInputRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: $TSFixMe) {
    return new AnalysisInput({
      activeType: RecordType.fromJSON(obj.activeType),
      operationList: List(obj.operationList),
      // @ts-expect-error
      referenceTypes: Map(obj.referenceTypes).map(type => RecordType.fromJSON(type)),
      activeKey: List(obj.activeKey),
      // @ts-expect-error
      referenceKeys: Map(obj.referenceKeys).map(type => List(type)),
    });
  }
}
