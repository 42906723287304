import { List } from 'immutable';

import modelJSON from '../../../../../connect/auth/models/src/main/resources/fixtures/models/Group.json';
import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';


class Group extends getModelHelpers({
  groupname: { type: ArgTypes.string },
  description: { type: ArgTypes.string },
  admin: { type: ArgTypes.bool },
  subgroupNames: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
  sourceGroupNames: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
}, 'Group')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class GroupRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: $TSFixMe) {
    return new Group({
      groupname: obj.groupname,
      description: obj.description,
      admin: !!obj.admin,
      subgroupNames: List(obj.subgroupNames || []),
      sourceGroupNames: List(obj.sourceGroupNames || []),
    });
  }
}

export const groupJSON = modelJSON;
export const sampleGroup = Group.fromJSON(modelJSON);

export default Group;
