import { Map } from 'immutable';

import { changeRecipeInputs } from '../api/RecipeClient';
import { selectAllProjectInfos } from '../utils/Selectors';
import { SUBMIT_COMPLETED } from './ManagedProjectDatasetsActionTypes';
import { getProjectNameToMemberDatasetNames } from './ManageProjectDatasetsStore';

export const submit = () => (dispatch, getState) => {
  const state = getState();
  const { manageProjectDatasets: { editingChanges, managingProjectsForDatasets }, datasetCatalog: { datasets } } = state;
  const datasetDocs = datasets.filter(doc => managingProjectsForDatasets.includes(doc.data.name));
  const projectDocs = getState().projects.projectsWithStatus.map(pws => pws.project);
  const projectNameToMemberDatasetNames = getProjectNameToMemberDatasetNames(projectDocs, datasetDocs);
  const projectInfos = selectAllProjectInfos(state);
  editingChanges.forEach((membershipState, projectName) => {
    const projectInfo = projectInfos.find(pInfo => pInfo.project.name === projectName);
    const { smRecipeId } = projectInfo;
    let changedSelectionState = new Map();
    datasetDocs.forEach(dsDoc => {
      const dsName = dsDoc.data.name;
      if (projectNameToMemberDatasetNames.get(projectName).includes(dsName) !== membershipState) {
        changedSelectionState = changedSelectionState.set(dsName, membershipState);
      }
    });
    changeRecipeInputs(smRecipeId, changedSelectionState).then(() => {
      dispatch({ type: SUBMIT_COMPLETED });
    }).fail(response => {
      dispatch({
        type: 'ManageProjectDatasets.submitFailed',
        detail: 'Unable to update input datasets for project "' + projectName + '"',
        response,
      });
    });
  });
};
