import $ from 'jquery';

import { getUnifiedDatasetName } from '../utils/Selectors';
import { getFilterInfo } from './ConfusionMatrixStore';

export const fetch = () => (dispatch, getState) => {
  const state = getState();
  const filterInfo = getFilterInfo(state);
  const unifiedDatasetName = getUnifiedDatasetName(state);
  dispatch({ type: 'ConfusionMatrix.fetch' });
  $.ajax({
    url: SERVICES.dedup(`/pairs/stats/${unifiedDatasetName}`),
    method: 'GET',
    contentType: 'application/json',
    cache: false,
    success: (data) => dispatch({ type: 'ConfusionMatrix.fetchCompleted', data, loadedFilterInfo: filterInfo }),
  });
};
