import './JobsStatus.scss';

import React from 'react';
import { connect } from 'react-redux';

import loader from '../../images/jobs-loader.gif';
import Button from '../components/Button';
import Link from '../components/Link';
import JobName from '../job/JobName';
import { pluralize } from '../utils/Strings';

/**
 * Shows a small jobs status modal
 */
const JobsStatus = connect((state) => {
  const { chrome: { runningJobs, userHiddenId } } = state;
  return {
    jobs: runningJobs,
    show: !runningJobs.isEmpty() && runningJobs.first().id.id !== userHiddenId,
  };
}, {
  onCloseJobStatus: () => ({ type: 'Chrome.closeJobStatus' }),
})(({ show, jobs, onCloseJobStatus }) => {
  if (!show) {
    return <noscript />;
  }
  const scale = 0.8;

  const numJobsRunning = jobs.size;
  let displayName;
  if (numJobsRunning === 0) {
    displayName = 'No jobs';
  } else if (numJobsRunning === 1) {
    displayName = <JobName jobDoc={jobs.first()} progressiveTense />;
  } else {
    displayName = <span><JobName jobDoc={jobs.first()} progressiveTense /> and {numJobsRunning - 1} more.</span>;
  }

  return (
    <div className="jobs-status-indicator">
      <span className="message-section">
        <Link to="/jobs">
          {pluralize(numJobsRunning, 'Job', 'Jobs')}
        </Link>
        <span className="standard-text">
          running
        </span>
        <div
          className="loading-spinner"
        >
          <img
            src={loader}
            style={{
              width: 160 * scale,
              height: 10 * scale,
            }}
          />
        </div>
        <Button
          buttonType="Link"
          className="close-job-status"
          icon="tamr-icon-close"
          onClick={onCloseJobStatus}
        />
      </span>
      <span className="message-section">
        {displayName}
      </span>
    </div>
  );
});

export default JobsStatus;
