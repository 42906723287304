import { useState } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import TextareaAutosize from 'react-autosize-textarea';
import { Alert, Button } from 'react-bootstrap';
import { Map } from 'immutable';
import { isLocal, JDBCApi } from '../addDataset/JDBCContent';
import {
  CLOSE_EXPORT_DIALOG,
} from '../coreConnectService/CoreConnectServiceActionTypes';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Input from '../components/Input/Input';
import ButtonToolbar from '../components/ButtonToolbar';
import DocLink from '../components/DocLink';

import Checkbox from '../components/Input/Checkbox';
import ConditionalButton from '../components/ConditionalButton';

const ExportWithJDBCDialog = connect((state) => {
  const {
    coreConnectService: {
      tamrSourceDataset,
      exportJDBCDialogOpen,
    },
  } = state;
  return {
    unifyDatasetName: tamrSourceDataset?.name,
    exportJDBCDialogOpen,
  };
}, {
  onCloseExportDialog: () => ({ type: CLOSE_EXPORT_DIALOG }),
})(({
  onCloseExportDialog, exportJDBCDialogOpen, unifyDatasetName,
}) => {
  const [jdbcUrl, setJdbcUrl] = useState(isLocal ? 'jdbc:parquet:URI=s3://tamr-core-connect-test/export/;AuthScheme=AwsCredentialsFile' : '');
  const [dbUsername, setDbUsername] = useState('');
  const [dbPassword, setDbPassword] = useState('');
  const [targetTableName, setTargetTableName] = useState(unifyDatasetName || '');
  const [createTable, setCreateTable] = useState(true);
  const [truncateBeforeLoad, setTruncateBeforeLoad] = useState(false);
  const [batchInsertSize, setBatchInsertSize] = useState(10000);
  const mutation = useMutation(JDBCApi.export);
  return <Dialog
    className="confirm-export-dialog"
    show={exportJDBCDialogOpen}
    onHide={onCloseExportDialog}
    dialogStyle={DialogStyle.PRIMARY}
    title={`Export dataset ${unifyDatasetName} `}
    body={<div>
      <p>Export a dataset using JDBC <DocLink path="core-connect">
        Learn more
      </DocLink>.</p>
      {mutation.isSuccess && !!mutation.data?.jobId !== false && <Alert bsStyle="success" className="my-8"> Successfully started Connect JDBC export job: {mutation.data?.jobId}. Track its progress in the Jobs page.</Alert>}
      <TextareaAutosize value={jdbcUrl} onChange={e => setJdbcUrl(e.target.value)} placeholder="JDBC Url *" style={{ display: 'block', width: '100%', marginBottom: '15px' }} maxRows={15} />
      <Input componentClassName={'mb-16'} type="text" value={targetTableName} onChange={e => setTargetTableName(e)} title="Target Table Name *" style={{ display: 'block' }} />
      <Input componentClassName={'mb-16'} type="text" value={dbUsername} onChange={e => setDbUsername(e)} title="Database Username" style={{ display: 'block' }} />
      <Input componentClassName={'mb-16'} type="text" value={dbPassword} onChange={e => setDbPassword(e)} title="Database Password" style={{ display: 'block' }} />
      <Input componentClassName={'mb-16'} type="number" value={batchInsertSize} onChange={e => (Number(e) ? setBatchInsertSize(Number(e)) : null)} title="Batch Insert Size" style={{ display: 'block' }} />
      <Checkbox className={'mr-8'} titlePosition="right" title="Create table" value={createTable} onChange={checked => setCreateTable(checked)} />
      <Checkbox titlePosition="right" title="Truncate table before load" value={truncateBeforeLoad} onChange={checked => setTruncateBeforeLoad(checked)} />
    </div>}
    footer={(
      <ButtonToolbar>
        <Button onClick={onCloseExportDialog} buttonType="Secondary">Cancel</Button>
        <ConditionalButton
          tooltipPlacement="top"
          preconditions={Map({
            'Adding dataset in progress': !mutation.isLoading,
            'Please enter a table name': targetTableName.length !== 0,
            'Table name should not be a query': !targetTableName.match(/^select /i),
            'Please enter a jdbc url': jdbcUrl.length !== 0,
          })}
          onClick={() => {
            mutation.mutate({ queryConfig: { jdbcUrl, dbUsername, dbPassword }, unifyDatasetName, batchInsertSize, createTable, truncateBeforeLoad, targetTableName });
          }}
          style={{ display: 'block' }}
        >Export dataset</ConditionalButton>
      </ButtonToolbar>
        )}
      />;
});
export default ExportWithJDBCDialog;
