import { is, List, Map, Set } from 'immutable';
import { flatMap, flowRight } from 'lodash';
import qs from 'query-string';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import _, { isString } from 'underscore';

import { FetchErrorResponseWithApiException } from '../api/FetchResult';
import { UPDATE_CLUSTER_PROFILE, UPDATE_SOURCE_LIST } from '../constants/RecipeOperations';
import SortState, { SortStateValueType } from '../constants/SortState';
import Document from '../models/doc/Document';
import Page from '../models/Page';
import TaggedUnion, { InferConstructedKind } from '../models/TaggedUnion';
import { Selector, StoreReducers } from '../stores/AppAction';
import { CHANGE, PROJECT_CHANGE } from '../stores/LocationActionTypes';
import ContentEnvelope, { ContentEnvelopeTypes } from '../transforms/models/ContentEnvelope';
import { OperationListResult } from '../transforms/models/StaticAnalysisModels';
import { RecordType } from '../transforms/models/Types';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import {
  getResetFns,
  insert,
  merge,
  mergeExcept,
  merger,
  push,
  set,
  setter,
  union,
  update,
  updater,
} from '../utils/Collections';
import { routes } from '../utils/Routing';
import SortUtils from '../utils/SortUtils';
import {
  maybeSet,
  parseBoolean,
  parseNumber,
  parseSet,
  parseSort,
  parseString,
} from '../utils/Url';
import { isDefined, isNotNull, jsonContentToString, moveIndex } from '../utils/Values';
import ChoosingAggregationFunction from './ChoosingAggregationFunction';
import * as ClusterSampleState from './ClusterSampleState';
import * as df from './DatasetFilter';
import * as EsRecord from './EsRecord';
import * as fcr from './FilterClusterRecords';
import { GoldenRecordsActionConfirmationTypeE } from './GoldenRecordsActionConfirmationType';
import {
  ADD_RULE_FILTER,
  BEGIN_CONFIRMING_PUBLISH,
  BEGIN_CONFIRMING_REMOVE_SOURCE_FROM_DATASET_FILTERS,
  BEGIN_CONFIRMING_UPDATE_GOLDEN_RECORDS,
  BEGIN_EDITING_DATASET_FILTER,
  CANCEL_CONFIRMING_PUBLISH,
  CANCEL_CONFIRMING_REMOVE_SOURCE_FROM_DATASET_FILTERS,
  CANCEL_CONFIRMING_UPDATE_GOLDEN_RECORDS,
  CANCEL_EDITING_DATASET_FILTER,
  CANCEL_FORCE_SAVE_MODULE,
  CANCEL_RULE,
  CANCEL_RULES,
  CHANGE_RULE_TYPE,
  CHANGE_SORT,
  CLEAR_ALL_FILTERS,
  CLEAR_SELECTION,
  CONFIRM_REMOVE_SOURCE_FROM_DATASET_FILTERS,
  CREATE_ATTRIBUTE,
  DELETE_RULE,
  EXCLUDE_DATASETS,
  EXCLUDE_UNACCOUNTED_SOURCES_COMPLETED,
  EXPAND,
  FETCH_ALL_OVERRIDE_STATS_COMPLETED,
  FETCH_CLUSTER_PROFILE_JOB,
  FETCH_CLUSTER_PROFILE_JOB_COMPLETED,
  FETCH_CLUSTER_PROFILE_JOB_FAILED,
  FETCH_CLUSTER_SAMPLE,
  FETCH_CLUSTER_SAMPLE_COMPLETED,
  FETCH_CLUSTER_SAMPLE_FAILED,
  FETCH_DRAFT_RECORD_TOTAL,
  FETCH_DRAFT_RECORD_TOTAL_COMPLETED,
  FETCH_DRAFT_RECORD_TOTAL_FAILED,
  FETCH_INPUT_DATASET_SCHEMA_COMPLETED,
  FETCH_MODULE,
  FETCH_MODULE_COMPLETED,
  FETCH_MODULE_FROM_LAST_PUBLISH_COMPLETED,
  FETCH_MODULE_FROM_LAST_UPDATE,
  FETCH_MODULE_FROM_LAST_UPDATE_COMPLETED,
  FETCH_MODULE_FROM_LAST_UPDATE_FAILED,
  FETCH_OVERRIDE_STATS_FOR_ATTRIBUTE,
  FETCH_SOURCE_LIST,
  FETCH_SOURCE_LIST_COMPLETED,
  FETCH_SOURCE_LIST_FAILED,
  FORCE_SELECT_DATASET_FILTER_BUCKET,
  FORCE_SELECT_DATASET_FILTER_SOURCE,
  HIDE_BOOKMARKS_ONBOARDING_MESSAGE,
  HIDE_SELECT_OVERRIDE_FILTER_DIALOG,
  INDEX_DRAFT_JOB_FINISHED,
  MOVE_DATASETS_TO_NEW_BOTTOM_PRIORITY,
  MOVE_DATASETS_TO_NEW_PRIORITY_ABOVE,
  MOVE_DATASETS_TO_NEW_PRIORITY_BELOW,
  MOVE_DATASETS_TO_NEW_TOP_PRIORITY,
  MOVE_DATASETS_TO_PRIORITY,
  MOVE_RULE_FILTER,
  PERFORM_STATIC_ANALYSIS_FOR_FILTER_COMPLETED,
  PERFORM_STATIC_ANALYSIS_FOR_RULE_COMPLETED,
  PUBLISH,
  PUBLISH_COMPLETED,
  PUBLISH_FAILED,
  QUERY_DRAFT,
  QUERY_DRAFT_COMPLETED,
  QUERY_DRAFT_FAILED,
  REMOVE_RULE_FILTER,
  SAVE_ALL_RULES,
  SAVE_ALL_RULES_COMPLETED,
  SAVE_ALL_RULES_FAILED,
  SAVE_DATASET_FILTER_EDITS,
  SAVE_MODULE_INVALID,
  SAVE_RULE,
  SAVE_RULE_COMPLETED,
  SAVE_RULE_FAILED,
  SELECT_DATASET_FILTER_SOURCE,
  SELECT_ROWS,
  SET_FILTER_BOOKMARKS,
  SET_PAGE,
  SET_PAGE_SIZE,
  SHOW_CONFLICT_DIALOG,
  SHOW_READONLY_DATASET_FILTER,
  SHOW_SELECT_OVERRIDE_FILTER_DIALOG,
  SUBMIT_UPDATE_CLUSTER_PROFILE_JOB,
  SUBMIT_UPDATE_CLUSTER_PROFILE_JOB_FAILED,
  SUBMIT_UPDATE_GOLDEN_RECORDS_DRAFT,
  SUBMIT_UPDATE_GOLDEN_RECORDS_DRAFT_FAILED,
  SUBMIT_UPDATE_SOURCE_LIST_JOB,
  SUBMIT_UPDATE_SOURCE_LIST_JOB_FAILED,
  TOGGLE_ALL_DATASET_FILTER_SOURCES_SELECTED,
  TOGGLE_ALL_EXPANDED,
  TOGGLE_DATASET_FILTER_EXCLUDED_SELECTED,
  TOGGLE_DATASET_FILTER_PRIORITY_SELECTED,
  TOGGLE_EXPANDED,
  TOGGLE_FILTER_PANEL,
  TOGGLE_FILTER_TO_RULE_WITH_OVERRIDES,
  TOGGLE_SHOW_CLUSTER_SAMPLE_TABLE,
  TOGGLE_SIDEBAR,
  UPDATE_CLUSTER_PROFILE_JOB_FINISHED,
  UPDATE_CLUSTER_PROFILE_JOB_FINISHED_2S_AGO,
  UPDATE_DESIRED_SAMPLE_CLUSTER,
  UPDATE_EDIT_DATASET_FILTER_SEARCH_STRING,
  UPDATE_HAS_OVERRIDES,
  UPDATE_OVERRIDE,
  UPDATE_OVERRIDE_COMPLETED,
  UPDATE_RULE,
  UPDATE_RULE_FILTER,
  UPDATE_SEARCH_STRING,
  UPDATE_SOURCE_LIST_JOB_FINISHED,
  UPDATE_SOURCE_LIST_JOB_FINISHED_2S_AGO,
} from './GoldenRecordsActionTypes';
import * as GoldenRecordsModule from './GoldenRecordsModule';
import * as GoldenRecordsOverrideStats from './GoldenRecordsOverrideStats';
import { getClusterIdOfDraftRecord, getClusterNameOfDraftRecord, getClusterSizeOfDraftRecord } from './GoldenRecordsUtils';
import { ModuleStatus } from './ModuleStatus';
import * as Rule from './Rule';
import * as RuleDelta from './RuleDelta';
import * as sif from './SingleInputFilter';
import Ternary, { isKnownTrue, TernaryType } from './Ternary';


/*
 * SR 10/02/19
 * user-defined column names appear in the tables side-by-side with static columns that we define
 *   so to avoid a columnKey collision in the table, we prefix the user-defined columns
 * when we store column preferences, we use the raw user-defined column names
 *   this means that, given this current strategy, we will have to namespace static tamr-defined columns
 *   if we ever want the user to be able to store preferences for those.
 *   at this point people already have column preferences stored in terms of raw user-defined attribute names,
 *   which is why I don't want to change it at this point.
 */
const COLUMN_KEY_PREFIX = 'gr__';
export const grColumnKeyArgType = ArgTypes.string.startingWith(COLUMN_KEY_PREFIX);
export const attributeNameToDisplayName = (attributeName: string, clusterIdColumnName: string) => {
  checkArg({ attributeName }, ArgTypes.string);
  checkArg({ clusterIdColumnName }, ArgTypes.string);
  if (attributeName === clusterIdColumnName) {
    return 'Cluster Id';
  }
  return attributeName;
};
export const attributeNameToColumnKey = (attributeName: string) => {
  checkArg({ attributeName }, ArgTypes.string);
  return COLUMN_KEY_PREFIX + attributeName;
};
export const columnKeyToAttributeName = (columnKey: string) => {
  checkArg({ columnKey }, ArgTypes.string);
  return columnKey.substring(COLUMN_KEY_PREFIX.length);
};

export const OVERRIDES_COLUMN_SUFFIX = '-override';
export const RULE_OUTPUT_COLUMN_SUFFIX = '-ruleOutput';
export function columnKeyToOverrideColumnKey(columnKey: string): string {
  return columnKey + OVERRIDES_COLUMN_SUFFIX;
}
export function columnKeyToRuleOutputColumnKey(columnKey: string): string {
  return columnKey + RULE_OUTPUT_COLUMN_SUFFIX;
}

type DatasetFilter = df.DatasetFilter;
type FilterClusterRecords = fcr.FilterClusterRecords;
const { BucketId } = df;

const RuleDeltaTypes = RuleDelta.RuleDeltaTypes;

export const DEFAULT_PAGE_SIZE = 50;

export interface RuleFilterKey {
  ruleName: string
  filterIndex: number
  readOnly: boolean
}
const ruleFilterKeyArgType = ArgTypes.object.withShape({
  ruleName: ArgTypes.string,
  filterIndex: ArgTypes.wholeNumber,
  readOnly: ArgTypes.bool,
});

const datasetFilterStateKindDefinitions = {
  Editing: {
    ruleFilterKey: ruleFilterKeyArgType,
    filter: df.argType,
  },
  ReadOnly: {
    filter: df.argType,
  },
  Hidden: { },
};
export const DatasetFilterState = TaggedUnion(datasetFilterStateKindDefinitions, 'DatasetFilterState');
type DatasetFilterStateType = InferConstructedKind<typeof datasetFilterStateKindDefinitions>

const logInvalidDatasetFilterstate = () => console.error('DatasetFilterState must be in Editable state');

type DatasetFilterEditingState = {
  ruleFilterKey: RuleFilterKey
  filter: DatasetFilter
};

// only used in error states!
const emptyDatasetFilter: DatasetFilter = { type: df.TYPE, priorities: [], excluded: [] };
const emptyDatasetFilterEditingState: DatasetFilterEditingState = {
  ruleFilterKey: {
    ruleName: '',
    filterIndex: 0,
    readOnly: false,
  },
  filter: emptyDatasetFilter,
};

const updateEditingDatasetFilterState = (store: GoldenRecordsStore, fn: ((editingState: DatasetFilterEditingState) => DatasetFilterEditingState)): GoldenRecordsStore => {
  const invalidErr = () => { logInvalidDatasetFilterstate(); return emptyDatasetFilterEditingState; };
  return set(store, 'datasetFilterState', DatasetFilterState.Editing(store.datasetFilterState.case({
    Editing: fn,
    ReadOnly: invalidErr,
    Hidden: invalidErr,
  })));
};

export const FilterBookmarkState = {
  bookmarks: 'bookmarks',
  noBookmarks: 'noBookmarks',
} as const;
export type FilterBookmarkStateE = typeof FilterBookmarkState[keyof typeof FilterBookmarkState];

export const getDraftDatasetFilter = (store: GoldenRecordsStore): DatasetFilter => {
  const invalidErr = () => { logInvalidDatasetFilterstate(); return emptyDatasetFilter; };
  return store.datasetFilterState.case({
    Editing: ({ filter }) => filter,
    ReadOnly: invalidErr,
    Hidden: invalidErr,
  });
};

export interface GoldenRecordsStore {
  // UI
  showSidebar: boolean
  expandedRuleNames: Set<string>
  showConflictDialog: boolean
  linkedFromOnboardingButton: boolean
  saveModuleError: FetchErrorResponseWithApiException | null

  selectedRowIndices: Set<number>

  // Table
  pageNum: number
  pageSize: number
  searchString: string
  hasOverrides: Set<string>
  draftPage: Page<EsRecord.EsRecord> | undefined
  columnSortStates: Map<string, SortStateValueType>
  filterBookmarks: Set<FilterBookmarkStateE>

  // Data
  nextRules: List<RuleDelta.RuleDelta>
  inputDatasetSchema: RecordType | undefined
  fetchAllOverrides: boolean
  nextEntityRule: RuleDelta.RuleDelta | undefined
  overrideStats: Map<string /* rule name */, GoldenRecordsOverrideStats.GoldenRecordsOverrideStats>

  // Table Filter Panel
  showFilterPanel: boolean
  showingSelectOverrideFilterDialog: boolean

  // editing dataset filter
  editDatasetFilterSearchString: string
  selectedDatasetFilterSources: Set<string>
  confirmingRemoveSourceFromDatasetFilters: string | null

  // loaders
  loading: boolean
  loadedFilterInfo: DraftRecordsFilterInfo | undefined
  draftRecordsFetchSequence: number
  updatingOverride: boolean

  // module fetching - perhaps bring this into generic store at some point
  loadingModule: boolean
  moduleFetchSequence: number
  loadedModuleFilterInfo: ModuleFilterInfo | undefined
  goldenRecordDocument: Document<GoldenRecordsModule.GoldenRecordsModule> | null
  moduleStatus: ModuleStatus | undefined

  // draft module fetching - the module from the time of last Update
  loadingModuleFromLastUpdate: boolean
  loadedModuleFromLastUpdateVersion: number | null
  moduleFromLastUpdate: GoldenRecordsModule.GoldenRecordsModule | null

  // total draft records fetching
  loadingDraftRecordTotal: boolean
  loadedDraftRecordTotalForModuleVersion: number | null
  totalRecords: number | undefined

  // published module fetching
  moduleFromLastPublish: GoldenRecordsModule.GoldenRecordsModule | null

  // source list
  submittingUpdateSourceListJob: boolean
  loadingSourceList: boolean
  sourceListFetchSequence: number
  loadedSourceListFilterInfo: SourceListFilterInfo | undefined
  sourceList: List<string> | undefined
  newSourcesSinceLastPublish: Set<string>
  sourceListOutOfDate: boolean

  // cluster profile
  submittingUpdateClusterProfileJob: boolean
  loadingClusterProfileJob: boolean
  clusterProfileJobFetchSequence: number
  loadedClusterProfileJobFilterInfo: ClusterProfileJobFilterInfo | undefined
  clusterProfileJobOutOfDate: TernaryType
  previewIsUsable: TernaryType

  // static analysis
  ruleExpressionAnalysisResults: Map<string, OperationListResult>
  filterExpressionAnalysisResults: Map<RuleFilterKey, OperationListResult>

  // update modules, and data
  submittingUpdateGoldenRecords: boolean
  savingRules: boolean
  confirmingAction: GoldenRecordsActionConfirmationTypeE | undefined
  submittingPublishGoldenRecords: boolean
  datasetFilterState: DatasetFilterStateType
  moduleToUpdate: GoldenRecordsModule.GoldenRecordsModule | null
  // indicates if a single rule is being updated/deleted. Undefined value indicates
  // that all rules are being updated.
  singleRuleNameUpdate: string | undefined

  // cluster sample
  clusterSampleState: ClusterSampleState.ClusterSampleState
}

export const initialState: GoldenRecordsStore = {
  // UI
  showSidebar: false,
  expandedRuleNames: Set<string>([]),
  showConflictDialog: false,
  linkedFromOnboardingButton: false,
  saveModuleError: null,

  selectedRowIndices: Set<number>(),

  // Table
  pageNum: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  searchString: '',
  hasOverrides: Set<string>(),
  draftPage: undefined,
  columnSortStates: Map<string, SortStateValueType>(),
  filterBookmarks: Set<FilterBookmarkStateE>(),

  // Data
  nextRules: List<RuleDelta.RuleDelta>(),
  inputDatasetSchema: undefined,
  fetchAllOverrides: true,
  nextEntityRule: undefined,
  overrideStats: Map<string, GoldenRecordsOverrideStats.GoldenRecordsOverrideStats>(),

  // Table Filter Panel
  showFilterPanel: false,
  showingSelectOverrideFilterDialog: false,

  // editing dataset filter
  editDatasetFilterSearchString: '',
  selectedDatasetFilterSources: Set<string>(),
  confirmingRemoveSourceFromDatasetFilters: null,

  // loaders
  loading: false,
  loadedFilterInfo: undefined,
  draftRecordsFetchSequence: 0,
  updatingOverride: false,

  // module fetching - perhaps bring this into generic store at some point
  loadingModule: false,
  moduleFetchSequence: 0,
  loadedModuleFilterInfo: undefined,
  goldenRecordDocument: null,
  moduleStatus: undefined,

  // draft module fetching - the module from the time of last Update
  loadingModuleFromLastUpdate: false,
  loadedModuleFromLastUpdateVersion: null,
  moduleFromLastUpdate: null,

  // total draft records fetching
  loadingDraftRecordTotal: false,
  loadedDraftRecordTotalForModuleVersion: null,
  totalRecords: 0,

  // published module fetching
  moduleFromLastPublish: null,

  // source list
  submittingUpdateSourceListJob: false,
  loadingSourceList: false,
  sourceListFetchSequence: 0,
  loadedSourceListFilterInfo: undefined,
  sourceList: undefined,
  newSourcesSinceLastPublish: Set<string>(),
  sourceListOutOfDate: false,

  // cluster profile
  submittingUpdateClusterProfileJob: false,
  loadingClusterProfileJob: false,
  clusterProfileJobFetchSequence: 0,
  loadedClusterProfileJobFilterInfo: undefined,
  clusterProfileJobOutOfDate: Ternary.Unknown({}),
  previewIsUsable: Ternary.Unknown({}),

  // static analysis
  ruleExpressionAnalysisResults: Map<string, OperationListResult>(),
  filterExpressionAnalysisResults: Map<RuleFilterKey, OperationListResult>(),

  // update module
  submittingUpdateGoldenRecords: false,
  savingRules: false,
  confirmingAction: undefined,
  submittingPublishGoldenRecords: false,
  datasetFilterState: DatasetFilterState.Hidden({}),
  moduleToUpdate: null,
  singleRuleNameUpdate: undefined,

  // cluster sample
  clusterSampleState: ClusterSampleState.initialClusterSampleState(),
};

type GoldenRecordsSelector<ReturnType> = Selector<GoldenRecordsStore, ReturnType>;

const { reset, resetter } = getResetFns(initialState);

const fetchModule = (store: GoldenRecordsStore) => update(store, 'moduleFetchSequence', x => x + 1);

const fetchSourceList = (store: GoldenRecordsStore) => update(store, 'sourceListFetchSequence', x => x + 1);
const fetchClusterProfileJob = (store: GoldenRecordsStore) => update(store, 'clusterProfileJobFetchSequence', x => x + 1);
const fetchClusterSample = (store: GoldenRecordsStore) => update(store, 'clusterSampleState', ClusterSampleState.triggerFetch);

const queryDraft = (store: GoldenRecordsStore) => update(store, 'draftRecordsFetchSequence', x => x + 1);

const clearSelection = (store: GoldenRecordsStore) => reset(store, 'selectedRowIndices');

export const isRuleNameEntityRule = (state: GoldenRecordsStore, ruleName: string): boolean => {
  checkArg({ ruleName }, ArgTypes.string);
  return state.nextEntityRule?.rule?.outputAttributeName === ruleName;
};

export const isRuleDeltaEntityRule = (state: GoldenRecordsStore, ruleDelta: RuleDelta.RuleDelta): boolean => {
  return isRuleNameEntityRule(state, ruleDelta.rule.outputAttributeName);
};

export const getNextRuleDelta = (ruleDelta: RuleDelta.RuleDelta, ruleDeltaType: RuleDelta.RuleDeltaTypesE) => {
  const type = (ruleDeltaType === RuleDeltaTypes.CHANGE && ruleDelta.type !== RuleDeltaTypes.UNCHANGED) ? ruleDelta.type : ruleDeltaType;
  return merge(ruleDelta, { type });
};

const updateRule = (state: GoldenRecordsStore, ruleDelta: RuleDelta.RuleDelta): GoldenRecordsStore => {
  ruleDelta = update(ruleDelta, 'rule', r => set(r, 'suggested', false));
  if (isRuleDeltaEntityRule(state, ruleDelta)) {
    return set(state, 'nextEntityRule', ruleDelta);
  }
  const index = state.nextRules.findIndex((rd) => rd.rule.outputAttributeName === ruleDelta.rule.outputAttributeName);
  return update(state, 'nextRules', nextRules => nextRules.set(index, ruleDelta));
};

export const findRuleDeltaByName = (state: GoldenRecordsStore, ruleName: string): RuleDelta.RuleDelta | undefined => {
  checkArg({ ruleName }, ArgTypes.string);
  return isRuleNameEntityRule(state, ruleName) ? state.nextEntityRule : state.nextRules.find(rd => rd.rule.outputAttributeName === ruleName);
};

const updateRuleFilters = (
  state: GoldenRecordsStore,
  ruleName: string,
  reducer: (filters: List<FilterClusterRecords>) => List<FilterClusterRecords>,
): GoldenRecordsStore => {
  checkArg({ ruleName }, ArgTypes.string);
  checkArg({ reducer }, ArgTypes.func);
  const ruleDelta = findRuleDeltaByName(state, ruleName);
  if (ruleDelta) {
    return updateRule(state, update(getNextRuleDelta(ruleDelta, RuleDeltaTypes.CHANGE), 'rule', r => update(r, 'filters', f => reducer(List(f)).toArray())));
  }
  return state;
};

export const selectIsClusterProfileJobKnownToBeOutOfDate: GoldenRecordsSelector<boolean> = createSelector(
  (s: GoldenRecordsStore) => s.clusterProfileJobOutOfDate,
  (outOfDatenessState) => isKnownTrue(outOfDatenessState),
);
export const selectIsPreviewKnownToBeUsable: GoldenRecordsSelector<boolean> = createSelector(
  (s: GoldenRecordsStore) => s.previewIsUsable,
  (previewIsUsableState) => isKnownTrue(previewIsUsableState),
);

export interface ModuleFilterInfo {
  moduleId: number
  fetchSequence: number
}

export interface DraftRecordsFilterInfo {
  draftRecordsFetchSequence: number
  pageNum: number
  pageSize: number
  searchString: string
  hasOverridesForAttribute: Set<string>
  columnSortStates: Map<string, SortStateValueType>
  includedClusters: List<string>
  excludedClusters: List<string>
}

export interface SourceListFilterInfo {
  sourceListDatasetName: string | undefined
  fetchSequence: number
}
export const selectSourceListFilterInfo: GoldenRecordsSelector<SourceListFilterInfo> = createSelector(
  s => s.goldenRecordDocument,
  s => s.sourceListFetchSequence,
  (moduleDoc, fetchSequence) => ({ sourceListDatasetName: moduleDoc?.data.sourceListDataset || undefined, fetchSequence }),
);

export interface ClusterProfileJobFilterInfo {
  publishedDatasetName: string | undefined
  fetchSequence: number
}
export const selectClusterProfileJobFilterInfo: GoldenRecordsSelector<ClusterProfileJobFilterInfo> = createSelector(
  s => s.goldenRecordDocument,
  s => s.clusterProfileJobFetchSequence,
  (moduleDoc, fetchSequence) => ({ publishedDatasetName: moduleDoc?.data.goldenRecordDataset, fetchSequence }),
);

export const selectInputAttributesByName: GoldenRecordsSelector<List<string>> = createSelector(
  state => state.inputDatasetSchema,
  inputDatasetSchema => inputDatasetSchema?.fields.map((f) => f.name) || List(),
);

export const selectInputAttributeAsOptions: GoldenRecordsSelector<Array<{ label: string, value: string }>> = createSelector(
  state => state.inputDatasetSchema,
  inputDatasetSchema => inputDatasetSchema?.fields.map(({ name }) => ({ label: name, value: name })).toList().toJS() || [],
);

const resetAllDeltas = (state: GoldenRecordsStore) => {
  const { goldenRecordDocument } = state;
  if (!goldenRecordDocument) {
    console.error('Cannot resetAllDeltas on Golden Records store - module document is undefined');
    return state;
  }
  const { rules, entityRule } = goldenRecordDocument.data;
  return merge(state, {
    nextRules: List(rules).map((rule) => ({ type: RuleDeltaTypes.UNCHANGED, rule })),
    nextEntityRule: { type: RuleDeltaTypes.UNCHANGED, rule: entityRule },
  });
};

// sorted as it will appear in the sidebar
export const selectAllLatestRules: GoldenRecordsSelector<List<Rule.Rule>> = createSelector(
  s => s.nextEntityRule,
  s => s.nextRules,
  (entityRule, rules) => (entityRule && rules ? rules.sortBy(r => r.rule.outputAttributeName).insert(0, entityRule).map(r => r.rule) : List()),
);

// sorted as it will appear in the sidebar
export const selectAllRuleNames: GoldenRecordsSelector<List<string>> = createSelector(
  selectAllLatestRules,
  allRules => allRules.map(r => r.outputAttributeName),
);


export const selectAllRulesWithOverrides: GoldenRecordsSelector<List<string>> = createSelector(
  selectAllRuleNames,
  s => s.overrideStats,
  (rules, overrideStats) => {
    return rules.filter(r => {
      const totalNumOverrides = overrideStats?.get(r)?.totalNumOverrides || 0;
      return overrideStats.has(r) && totalNumOverrides > 0;
    });
  },
);

export const selectAllPublishedRules: GoldenRecordsSelector<List<Rule.Rule>> = createSelector(
  s => s.moduleFromLastPublish?.entityRule,
  s => s.moduleFromLastPublish?.rules,
  (entityRule, rules) => (entityRule && rules ? List(rules).sortBy(r => r.outputAttributeName).insert(0, entityRule) : List()),
);

export const selectAllUpdatedRules: GoldenRecordsSelector<List<Rule.Rule>> = createSelector(
  s => s.moduleFromLastUpdate?.entityRule,
  s => s.moduleFromLastUpdate?.rules,
  (entityRule, rules) => (entityRule && rules ? List(rules).sortBy(r => r.outputAttributeName).insert(0, entityRule) : List()),
);

export const selectAllUpdatedRuleNames: GoldenRecordsSelector<List<string>> = createSelector(
  selectAllUpdatedRules,
  allRules => allRules.map(r => r.outputAttributeName),
);

export const getPrioritizedSourcesFromRules = (rules: List<Rule.Rule> | null): List<string> => {
  checkArg({ rules }, ArgTypes.orNull(ArgTypes.Immutable.list.of(Rule.argType)));
  if (!rules) return List();
  return rules
    .flatMap(r => flatMap(r.filters, f => (f.type === df.TYPE ? df.allDatasets(f).toArray() : [])))
    .toList().sort();
};

export const selectPrioritizedSourcesFromLastPublish: GoldenRecordsSelector<List<string>> = createSelector(
  selectAllPublishedRules,
  getPrioritizedSourcesFromRules,
);

export const selectAllPrioritizedSources: GoldenRecordsSelector<List<string>> = createSelector(
  selectAllLatestRules,
  s => s.sourceList,
  (rules, sourceList) => getPrioritizedSourcesFromRules(rules).toSet().union((sourceList || List()).toSet()).toList(),
);

export const selectAreFiltersEnabled: GoldenRecordsSelector<boolean> = createSelector(
  s => s.hasOverrides,
  s => s.filterBookmarks.size > 0,
  (hasOverrides, hasBookmarks) => !hasOverrides.isEmpty() || hasBookmarks,
);

export const getRuleExpressionAnalysisResult = (state: GoldenRecordsStore, { ruleName }: { ruleName: string }): OperationListResult | undefined => {
  checkArg({ ruleName }, ArgTypes.string);
  return state.ruleExpressionAnalysisResults.get(ruleName);
};

export const getFilterExpressionAnalysisResult = (state: GoldenRecordsStore, { ruleName, filterIndex }: { ruleName: string, filterIndex: number }): OperationListResult | undefined => {
  checkArg({ ruleName }, ArgTypes.string);
  checkArg({ filterIndex }, ArgTypes.wholeNumber);
  return state.filterExpressionAnalysisResults.get({ ruleName, filterIndex, readOnly: false });
};

const ValidationMessages = {
  missingInputAttributeName: 'Input attribute must be selected for this rule',
  badFilter: 'Condition is missing required information',
  ruleHasLintErrors: 'Rule has lint errors',
  filterHasLintErrors: 'A filter has lint errors',
};

export const selectValidationErrors: GoldenRecordsSelector<Map<string, List<string>>> = createSelector(
  state => state.nextEntityRule,
  state => state.nextRules,
  state => state.ruleExpressionAnalysisResults,
  state => state.filterExpressionAnalysisResults,
  (nextEntityRule, nextRules, ruleExpressionAnalysisResults, filterExpressionAnalysisResults) => {
    const allRules = nextRules.update(r => (nextEntityRule ? r.push(nextEntityRule) : r));
    return Map(allRules.filter(r => !!r).map(ruleDelta => {
      const ruleName = ruleDelta.rule.outputAttributeName;
      const validation = [];
      const { rule } = ruleDelta;
      if ('inputAttributeName' in rule && !rule.inputAttributeName /* empty string here is considered missing input attribute name */) {
        validation.push(ValidationMessages.missingInputAttributeName);
      }
      if (rule.filters.find(filter => !fcr.isValid(filter))) {
        validation.push(ValidationMessages.badFilter);
      }
      const ruleExpressionAnalysisResult = ruleExpressionAnalysisResults.get(ruleName);
      // NOTE: we decide that by convention rules or filters that have expressions that have lints will go into the expression property of the Model
      if (ruleExpressionAnalysisResult && !ruleExpressionAnalysisResult.operations.get(0)?.lints.isEmpty()) {
        validation.push(ValidationMessages.ruleHasLintErrors);
      }
      const filterWithLintError = ruleDelta.rule.filters.find((filter, filterIndex) => {
        const staticAnalysisResult = filterExpressionAnalysisResults.get({ ruleName, filterIndex, readOnly: false });
        return staticAnalysisResult && !staticAnalysisResult.operations.get(0)?.lints.isEmpty();
      });
      if (filterWithLintError) {
        validation.push(ValidationMessages.filterHasLintErrors);
      }
      return [ruleName, List(validation)];
    }));
  },
);

export const getValidationErrorsForRule = (state: GoldenRecordsStore, { ruleName }: { ruleName: string }): List<string> => {
  return selectValidationErrors(state).get(ruleName) || List();
};

export const getRuleFilter = (state: GoldenRecordsStore, { ruleName, filterIndex }: { ruleName: string, filterIndex: number }): FilterClusterRecords | undefined => {
  return findRuleDeltaByName(state, ruleName)?.rule.filters[filterIndex];
};

export const selectAggregationContentEnvelope = createCachedSelector(
  (state: GoldenRecordsStore) => state.goldenRecordDocument?.data.clusterDataset.clusterColumn,
  (state: GoldenRecordsStore, ruleName: string) => ruleName,
  (clusterColumn: string, ruleName: string) => new ContentEnvelope({ pre: 'GROUP ', post: `AS "${ruleName}" BY "${clusterColumn}";`, type: ContentEnvelopeTypes.aggregation }),
)((state, ruleName) => ruleName);

const getSelectedPriorityIndexes = (draft: DatasetFilter, selected: Set<string>): Set<number> => {
  checkArg({ selected }, ArgTypes.Immutable.set.of(ArgTypes.string));
  return selected
    .map(dataset => (draft.excluded.includes(dataset)
      ? null
      : draft.priorities.findIndex((bucket) => bucket.includes(dataset))
    ))
    .filter(isNotNull);
};

const removeEmptyBuckets = (draft: DatasetFilter) => {
  return update(draft, 'priorities', (p) => p.filter((bucket) => bucket.length !== 0));
};

const removeSelectedFromAll = (draft: DatasetFilter, selected: Set<string>): DatasetFilter => {
  checkArg({ selected }, ArgTypes.Immutable.set.of(ArgTypes.string));
  return update(draft,
    updater('priorities', p => p.map(bucket => Set(bucket).subtract(selected).toArray())),
    updater('excluded', e => Set(e).subtract(selected).toArray()));
};

const unselectAllDatasets = (store: GoldenRecordsStore) => reset(store, 'selectedDatasetFilterSources');

export const selectAllSavedRules: GoldenRecordsSelector<List<Rule.Rule>> = createSelector(
  s => s.goldenRecordDocument?.data,
  module => (module ? List(module.rules).sortBy(r => r.outputAttributeName).insert(0, module.entityRule) : List()),
);

function getAllUnaccountedDatasets(sourceList: List<string> | undefined, allSavedRules: List<Rule.Rule>): Map<string, Set<string>> {
  if (!sourceList) {
    return Map();
  }
  return Map(sourceList.map(datasetName => {
    const rulesWithFiltersMissingDataset = allSavedRules
      .filter(r => r.filters.some((f) => f.type === df.TYPE && !df.allDatasets(f).has(datasetName)))
      .map(r => r.outputAttributeName);
    return [datasetName, rulesWithFiltersMissingDataset.toSet()];
  })).filter((rules) => !rules.isEmpty());
}

/**
 * Selects all datasets that are unaccounted for (do not have an entry) in any rules that have Dataset filters.
 * If the selector's return value is an empty map, that means all datasets are accounted for in all Dataset filters.
 * This selector compares the current set of input datasets against the currently saved version of the module config
 *   (not, say, the staged module config).
 */
export const selectDatasetsNewToRules: GoldenRecordsSelector<Map<string, Set<string>>> = createSelector(
  s => s.sourceList,
  selectAllSavedRules,
  (sourceList, allSavedRules) => getAllUnaccountedDatasets(sourceList, allSavedRules),
);

const removeDatasetFromDatasetFilter = (ruleDelta: RuleDelta.RuleDelta, datasetName: string) => {
  if (ruleDelta.type === RuleDeltaTypes.DELETE) {
    return ruleDelta;
  }
  return update(ruleDelta, delta => {
    const filters = delta.rule.filters.map((f) => (f.type === df.TYPE ? df.removeDataset(f, datasetName) : f));
    const type = delta.type === RuleDeltaTypes.CREATE || is(filters, delta.rule.filters)
      ? delta.type
      : RuleDeltaTypes.CHANGE;
    return update(delta,
      updater('rule', r => set(r, 'filters', filters)),
      setter('type', type),
    );
  });
};

export const selectSelectedClusterIds: GoldenRecordsSelector<Set<string>> = createSelector(
  store => store.selectedRowIndices,
  store => store.draftPage,
  (selectedRowIndices, draftPage) => selectedRowIndices.map(index => draftPage?.items.get(index)?.id /* uses elasticsearch id for cluster id */).filter(isDefined),
);

export const reducers: StoreReducers<GoldenRecordsStore> = {
  [CHANGE]: (store, { location }) => {
    const m = routes.goldenrecords.match(location.pathname);
    if (m) {
      const params = qs.parse(location.search) || {};
      const next = store || initialState;
      return flowRight(
        // todo useLocalStorage should be in TransformsStore but we are unsure of how to share the URL between multiple stores
        maybeSet('pageNum', parseNumber(params.pageNum)),
        maybeSet('pageSize', parseNumber(params.pageSize)),
        maybeSet('searchString', parseString(params.searchString)),
        maybeSet('showSidebar', parseBoolean(params.showSidebar)),
        maybeSet('columnSortStates', parseSort(params.sort)),
        // TODO ARP: represent this set more compactly
        maybeSet('hasOverrides', parseSet(parseString)(params.hasOverrides)),
        maybeSet('linkedFromOnboardingButton', parseBoolean(params.onboarding)),
      )(next);
    }
    return store;
  },
  [PROJECT_CHANGE]: (state) => {
    return mergeExcept(state, initialState, 'loading', 'loadingModule', 'loadingModuleFromLastUpdate', 'loadingSourceList', 'loadingClusterProfileJob');
  },
  [CLEAR_SELECTION]: (store) => {
    return clearSelection(store);
  },
  [FETCH_MODULE]: (state) => {
    return set(state, 'loadingModule', true);
  },
  [FETCH_MODULE_COMPLETED]: (state, { moduleDoc, moduleStatus, filterInfo }) => {
    return update(state,
      setter('loadingModule', false),
      setter('goldenRecordDocument', moduleDoc),
      updater('nextRules', nextRules => {
        return List(moduleDoc.data.rules).map(rule => nextRules.find(r => r.rule.outputAttributeName === rule.outputAttributeName && r.type !== RuleDelta.RuleDeltaTypes.UNCHANGED) || RuleDelta.unchanged(rule));
      }),
      updater('nextEntityRule', nextEntityRule => {
        if (!nextEntityRule || nextEntityRule.type === RuleDelta.RuleDeltaTypes.UNCHANGED) {
          return RuleDelta.unchanged(moduleDoc.data.entityRule);
        }
        return nextEntityRule;
      }),
      setter('moduleStatus', moduleStatus),
      setter('loadedModuleFilterInfo', filterInfo),
    );
  },
  [TOGGLE_SIDEBAR]: (store) => set(store, 'showSidebar', !store.showSidebar),
  [SAVE_RULE]: (state, { module, ruleName }) => {
    return update(
      state,
      setter('savingRules', true),
      setter('moduleToUpdate', module),
      setter('singleRuleNameUpdate', ruleName),
    );
  },
  [SAVE_RULE_COMPLETED]: (state, { moduleDoc, ruleName }) => {
    return update(state,
      setter('goldenRecordDocument', moduleDoc),
      setter('savingRules', false),
      resetter('saveModuleError'),
      resetter('singleRuleNameUpdate'),
      updater(s => {
        if (isRuleNameEntityRule(s, ruleName)) {
          return set(s, 'nextEntityRule', RuleDelta.unchanged(moduleDoc.data.entityRule));
        }
        const ruleDeltaIndex = s.nextRules.findIndex((rd) => rd.rule.outputAttributeName === ruleName);
        const ruleDelta = findRuleDeltaByName(s, ruleName);
        if (ruleDelta?.type === RuleDeltaTypes.DELETE) {
          return update(s, 'nextRules', nextRules => nextRules.delete(ruleDeltaIndex));
        }
        const rule = moduleDoc.data.rules.find(r => r.outputAttributeName === ruleName);
        if (rule) {
          return update(s, 'nextRules', nextRules => nextRules.set(ruleDeltaIndex, RuleDelta.unchanged(rule)));
        }
        return s;
      }),
    );
  },
  [SAVE_RULE_FAILED]: (state) => {
    return update(
      state,
      setter('savingRules', false),
      resetter('saveModuleError'),
      resetter('singleRuleNameUpdate'),
    );
  },
  [SAVE_ALL_RULES]: (store, { module }) => {
    return update(
      store,
      setter('savingRules', true),
      setter('moduleToUpdate', module),
    );
  },
  [SAVE_ALL_RULES_COMPLETED]: (state, { moduleDoc }) => {
    return update(state,
      resetter('savingRules'),
      setter('goldenRecordDocument', moduleDoc),
      resetter('saveModuleError'),
      updater(resetAllDeltas),
    );
  },
  [SAVE_ALL_RULES_FAILED]: (store) => {
    return update(store,
      resetter('savingRules'),
      resetter('saveModuleError'),
    );
  },
  [CANCEL_RULE]: (store, { ruleName }) => {
    const { goldenRecordDocument } = store;
    if (!goldenRecordDocument) {
      return store;
    }
    const { data: { entityRule, rules } } = goldenRecordDocument;
    if (ruleName === entityRule.outputAttributeName) {
      // don't have to guard against entityRule having been newly created -- that is not allowed in the UI
      return update(store, 'nextEntityRule', nextEntityRule => (nextEntityRule
        ? update(nextEntityRule, setter('rule', entityRule), setter('type', RuleDeltaTypes.UNCHANGED))
        : nextEntityRule));
    }
    const wasCreated = store.nextRules.find(delta => delta.rule.outputAttributeName === ruleName && delta.type === RuleDeltaTypes.CREATE);
    if (wasCreated) {
      return update(store, 'nextRules', nextRules => nextRules.filterNot(delta => delta.rule?.outputAttributeName === ruleName));
    }
    const savedRule = rules.find(r => r.outputAttributeName === ruleName);
    return update(store, 'nextRules', nextRules => nextRules.map(ruleDelta => ((ruleDelta?.rule?.outputAttributeName === ruleName && savedRule) ? update(ruleDelta, setter('rule', savedRule), setter('type', RuleDeltaTypes.UNCHANGED)) : ruleDelta)));
  },
  [CANCEL_RULES]: resetAllDeltas,
  [SET_PAGE]: (store, { pageNum }) => {
    return update(store, setter('pageNum', pageNum),
      updater(clearSelection));
  },
  [SET_PAGE_SIZE]: (store, { pageSize }) => {
    return update(store, setter('pageSize', pageSize),
      updater(clearSelection));
  },
  [FETCH_INPUT_DATASET_SCHEMA_COMPLETED]: (state, { schema }) => {
    return set(state, 'inputDatasetSchema', schema);
  },
  [UPDATE_RULE]: (state, { ruleDelta }) => {
    return updateRule(state, ruleDelta);
  },
  [DELETE_RULE]: (state, { ruleName }) => {
    const ruleDeltaIndex = state.nextRules.findIndex(rd => rd.rule.outputAttributeName === ruleName);
    return update(state,
      updater('nextRules', nextRules => nextRules.setIn([ruleDeltaIndex, 'type'], RuleDeltaTypes.DELETE)));
  },
  [CHANGE_RULE_TYPE]: (state, { ruleName, newType }) => {
    const ruleDelta = findRuleDeltaByName(state, ruleName);
    if (!ruleDelta) {
      console.error(`Cannot changeRuleType - ruleDelta for rule name '${ruleName}' is undefined`);
      return state;
    }
    const inputAttributesOptions = selectInputAttributeAsOptions(state);
    const oldRule = ruleDelta.rule;
    // preserve these from the old rule
    const { filters, outputAttributeName } = oldRule;

    const inputAttributeOptionFromOutputAttribute = inputAttributesOptions.find(({ value }) => value === ruleDelta?.rule.outputAttributeName)?.label;

    const newRule: Rule.Rule = newType === Rule.RuleTypeName.Expression
      // new type that user has selected is "Expression" - preserve what we can from old rule and create blank expression
      ? { type: newType, filters, outputAttributeName, expression: '', suggested: false }
      // new type is not "Expression" - again preserve what we can
      : {
        type: newType,
        filters,
        outputAttributeName,
        suggested: false,
        // if old rule had an inputAttributeName preserve that
        //   otherwise see if we can smartly infer the input attribute name from the rule name
        //   otherwise just set it to empty string, which is taken to mean unset
        inputAttributeName: oldRule.type === Rule.RuleTypeName.Expression
          ? (inputAttributeOptionFromOutputAttribute || '')
          : oldRule.inputAttributeName,
      };
    return update(state,
      s => updateRule(s, set(getNextRuleDelta(ruleDelta, RuleDeltaTypes.CHANGE), 'rule', newRule)),
      // if switching away from Expression, reset any static analysis for this rule
      s => (newType !== Rule.RuleTypeName.Expression ? update(s, 'ruleExpressionAnalysisResults', r => r.delete(ruleName)) : s));
  },
  [MOVE_RULE_FILTER]: (state, { ruleName, oldIndex, newIndex }) => {
    checkArg({ ruleName }, ArgTypes.string);
    checkArg({ oldIndex }, ArgTypes.wholeNumber);
    checkArg({ newIndex }, ArgTypes.wholeNumber);
    return updateRuleFilters(state, ruleName, (filters) => moveIndex(filters, oldIndex, newIndex));
  },
  [ADD_RULE_FILTER]: (state, { ruleName }) => {
    checkArg({ ruleName }, ArgTypes.string);
    return updateRuleFilters(state, ruleName, (filters) => filters.push({
      type: sif.TYPE,
      function: ChoosingAggregationFunction.MODE,
      inputAttributeName: undefined,
    }));
  },
  [REMOVE_RULE_FILTER]: (state, { ruleName, index }) => {
    checkArg({ ruleName }, ArgTypes.string);
    checkArg({ index }, ArgTypes.wholeNumber);
    return updateRuleFilters(state, ruleName, (filters) => filters.remove(index));
  },
  [UPDATE_RULE_FILTER]: (state, { ruleName, index, filter }) => {
    checkArg({ ruleName }, ArgTypes.string);
    checkArg({ index }, ArgTypes.wholeNumber);
    if (!('expression' in filter) || filter.expression === undefined) {
      state = update(state, 'filterExpressionAnalysisResults', r => r.delete({ ruleName, filterIndex: index, readOnly: false }));
    }
    return updateRuleFilters(state, ruleName, (filters) => filters.set(index, filter));
  },
  [CREATE_ATTRIBUTE]: (store, { outputAttributeName }) => {
    const nextRules = store.nextRules.push({ rule: { type: Rule.RuleTypeName.MostCommonValue, outputAttributeName, inputAttributeName: '', filters: [], suggested: false }, type: RuleDeltaTypes.CREATE });
    return set(store, 'nextRules', nextRules);
  },
  [TOGGLE_EXPANDED]: (store, { name }) => update(store, 'expandedRuleNames', (expandedRuleNames) => (expandedRuleNames.has(name) ? expandedRuleNames.remove(name) : expandedRuleNames.add(name))),
  [EXPAND]: (store, { name }) => update(store, 'expandedRuleNames', (expandedRuleNames) => (expandedRuleNames.add(name))),
  [TOGGLE_ALL_EXPANDED]: (store) => {
    let allRules = store.nextRules.map((x) => x.rule.outputAttributeName);
    if (store.nextEntityRule?.rule?.outputAttributeName !== undefined) {
      allRules = allRules.push(store.nextEntityRule?.rule?.outputAttributeName);
    }
    return update(store, 'expandedRuleNames', (expandedRuleNames) => ((expandedRuleNames.size === allRules.size) ? Set<string>() : Set(allRules)));
  },
  [QUERY_DRAFT]: (store) => set(store, 'loading', true),
  [QUERY_DRAFT_COMPLETED]: (store, { page, filterInfo }) => merge(store, {
    loading: false,
    loadedFilterInfo: filterInfo,
    draftPage: page,
  }),
  [QUERY_DRAFT_FAILED]: (store, { filterInfo }) => merge(store, {
    loading: false,
    loadedFilterInfo: filterInfo,
  }),
  [FETCH_MODULE_FROM_LAST_UPDATE]: (store) => {
    return set(store, 'loadingModuleFromLastUpdate', true);
  },
  [FETCH_MODULE_FROM_LAST_UPDATE_COMPLETED]: (store, { moduleFromLastUpdate, moduleFromLastUpdateVersion }) => {
    return merge(store, {
      loadingModuleFromLastUpdate: false,
      moduleFromLastUpdate,
      loadedModuleFromLastUpdateVersion: moduleFromLastUpdateVersion,
    });
  },
  [FETCH_MODULE_FROM_LAST_UPDATE_FAILED]: (store, { moduleFromLastUpdateVersion }) => {
    return merge(store, {
      loadingModuleFromLastUpdate: false,
      loadedModuleFromLastUpdateVersion: moduleFromLastUpdateVersion,
    });
  },
  [FETCH_DRAFT_RECORD_TOTAL]: (store) => {
    return set(store, 'loadingDraftRecordTotal', true);
  },
  [FETCH_DRAFT_RECORD_TOTAL_COMPLETED]: (store, { totalRecords, moduleFromLastUpdateVersion }) => {
    return merge(store, {
      loadingDraftRecordTotal: false,
      totalRecords,
      loadedDraftRecordTotalForModuleVersion: moduleFromLastUpdateVersion,
    });
  },
  [FETCH_DRAFT_RECORD_TOTAL_FAILED]: (store, { moduleFromLastUpdateVersion }) => {
    return merge(store, {
      loadingDraftRecordTotal: false,
      loadedDraftRecordTotalForModuleVersion: moduleFromLastUpdateVersion,
    });
  },
  [FETCH_MODULE_FROM_LAST_PUBLISH_COMPLETED]: (store, { module }) => {
    return set(store, 'moduleFromLastPublish', module);
  },
  [INDEX_DRAFT_JOB_FINISHED]: (store) => {
    return update(store,
      resetter('submittingUpdateGoldenRecords'),
      updater(queryDraft),
      updater(fetchModule),
      updater(fetchSourceList),
      updater(fetchClusterProfileJob),
    );
  },
  [SUBMIT_UPDATE_SOURCE_LIST_JOB]: (store) => {
    return set(store, 'submittingUpdateSourceListJob', true);
  },
  [SUBMIT_UPDATE_SOURCE_LIST_JOB_FAILED]: (store) => {
    return reset(store, 'submittingUpdateSourceListJob');
  },
  [SUBMIT_UPDATE_CLUSTER_PROFILE_JOB]: (store) => {
    return set(store, 'submittingUpdateClusterProfileJob', true);
  },
  [SUBMIT_UPDATE_CLUSTER_PROFILE_JOB_FAILED]: (store) => {
    return reset(store, 'submittingUpdateClusterProfileJob');
  },
  'Jobs.jobUpdate': (store, { job }) => {
    return update(store,
      updater(s => (job.data.metadata.get('recipeOperation') === UPDATE_SOURCE_LIST ? reset(s, 'submittingUpdateSourceListJob') : s)),
      updater(s => (job.data.metadata.get('recipeOperation') === UPDATE_CLUSTER_PROFILE ? reset(s, 'submittingUpdateClusterProfileJob') : s)),
    );
  },
  [UPDATE_SOURCE_LIST_JOB_FINISHED]: (store) => {
    return update(store,
      fetchSourceList,
      fetchClusterSample,
    );
  },
  [UPDATE_SOURCE_LIST_JOB_FINISHED_2S_AGO]: (store) => {
    const { sourceListOutOfDate } = store;
    // if the source list is still out of date, refetch again. this works around a race condition
    //   where datasets associated with a job may not actually be tracked as materialized until
    //   shortly after the associated job completes.
    // note that "fetchSourceList" will update the fetchSequence, meaning the associated loader
    //   will either fetch right now, or will spawn another fetch once the outstanding fetch comes back
    if (sourceListOutOfDate) return update(store, fetchSourceList);
    return store;
  },
  [UPDATE_CLUSTER_PROFILE_JOB_FINISHED]: (store) => {
    return update(store,
      fetchClusterProfileJob,
      fetchClusterSample,
    );
  },
  [UPDATE_CLUSTER_PROFILE_JOB_FINISHED_2S_AGO]: (store) => {
    // see the note for updateSourceListJobFinished2sAgo
    return isKnownTrue(store.clusterProfileJobOutOfDate) ? update(store, fetchClusterProfileJob) : store;
  },
  [FETCH_ALL_OVERRIDE_STATS_COMPLETED]: (state, { overrideStats }) => {
    return merge(state, {
      overrideStats,
      fetchAllOverrides: false,
    });
  },
  [FETCH_OVERRIDE_STATS_FOR_ATTRIBUTE]: (state, { attributeName, overrideStats }) => {
    checkArg({ attributeName }, ArgTypes.string);
    return update(state, 'overrideStats', s => s.set(attributeName, overrideStats));
  },
  [UPDATE_OVERRIDE]: (store) => {
    return set(store, 'updatingOverride', true);
  },
  [UPDATE_OVERRIDE_COMPLETED]: (store) => {
    return set(queryDraft(store), 'updatingOverride', false);
  },
  [UPDATE_SEARCH_STRING]: (store, { searchString }) => {
    return update(store, merger<GoldenRecordsStore>({ searchString, pageNum: 0 }),
      updater(clearSelection));
  },
  [CLEAR_ALL_FILTERS]: (store) => {
    return update(store, merger<GoldenRecordsStore>({ searchString: '', pageNum: 0, hasOverrides: Set(), filterBookmarks: Set() }),
      updater(clearSelection));
  },
  [UPDATE_HAS_OVERRIDES]: (store, { hasOverrides }) => {
    return update(store, merger<GoldenRecordsStore>({ hasOverrides, pageNum: 0 }),
      updater(clearSelection));
  },
  [SET_FILTER_BOOKMARKS]: (store, { filterBookmarks }) => {
    checkArg({ filterBookmarks }, ArgTypes.Immutable.set.of(ArgTypes.valueIn(FilterBookmarkState)));
    // selectBookmarkedClusterIds
    return update(store, merger<GoldenRecordsStore>({ filterBookmarks, pageNum: 0 }),
      updater(clearSelection));
  },
  [TOGGLE_FILTER_TO_RULE_WITH_OVERRIDES]: (store, { ruleName }) => {
    checkArg({ ruleName }, ArgTypes.string);
    const { hasOverrides } = store;
    const filterEnabledForRule = hasOverrides.contains(ruleName);
    const newFilters = filterEnabledForRule ? hasOverrides.remove(ruleName) : hasOverrides.add(ruleName);
    return update(store, merger<GoldenRecordsStore>({ hasOverrides: newFilters, pageNum: 0 }),
      updater(clearSelection));
  },
  [SHOW_CONFLICT_DIALOG]: (store, { showConflictDialog }) => merge(store, { showConflictDialog }),
  [SAVE_MODULE_INVALID]: (store, { saveModuleError }) => merge(store, { saveModuleError }),
  [CANCEL_FORCE_SAVE_MODULE]: (store) => {
    return update(
      store,
      resetter('saveModuleError'),
      setter('savingRules', false),
      resetter('singleRuleNameUpdate'),
      updater('nextRules', r => {
        return r.map(ruleDelta => (ruleDelta.type === RuleDeltaTypes.DELETE ? RuleDelta.unchanged(ruleDelta.rule) : ruleDelta));
      }),
    );
  },
  [BEGIN_CONFIRMING_UPDATE_GOLDEN_RECORDS]: (store, { confirmingAction }) => {
    return set(store, 'confirmingAction', confirmingAction);
  },
  [CANCEL_CONFIRMING_UPDATE_GOLDEN_RECORDS]: (store) => {
    return reset(store, 'confirmingAction');
  },
  [SUBMIT_UPDATE_GOLDEN_RECORDS_DRAFT]: (store) => {
    return update(store,
      resetter('confirmingAction'),
      // NB: this does not get unset when submission has completed successfully
      //     but rather, when a message is received that the job is complete
      setter('submittingUpdateGoldenRecords', true));
  },
  [SUBMIT_UPDATE_GOLDEN_RECORDS_DRAFT_FAILED]: (store) => {
    return reset(store, 'submittingUpdateGoldenRecords');
  },
  [BEGIN_CONFIRMING_PUBLISH]: (store) => {
    return set(store, 'confirmingAction', GoldenRecordsActionConfirmationTypeE.PUBLISH);
  },
  [CANCEL_CONFIRMING_PUBLISH]: (store) => {
    return reset(store, 'confirmingAction');
  },
  [EXCLUDE_UNACCOUNTED_SOURCES_COMPLETED]: (store, { moduleDoc }) => {
    return update(store,
      setter('goldenRecordDocument', moduleDoc),
      fetchModule);
  },
  [PUBLISH]: (store) => {
    return update(store,
      setter('submittingPublishGoldenRecords', true),
      resetter('confirmingAction'));
  },
  [PUBLISH_COMPLETED]: (store) => {
    return update(store, resetter('submittingPublishGoldenRecords'),
      updater(fetchModule));
  },
  [PUBLISH_FAILED]: (store) => {
    return reset(store, 'submittingPublishGoldenRecords');
  },
  [CHANGE_SORT]: (store, { field }) => {
    return set(store, 'columnSortStates', Map({ [field]: SortUtils.getNext(store.columnSortStates.get(field) || SortState.UNSORTED) }));
  },
  [PERFORM_STATIC_ANALYSIS_FOR_RULE_COMPLETED]: (store, { ruleName, staticAnalysisResult }) => {
    return update(store, 'ruleExpressionAnalysisResults', r => r.set(ruleName, staticAnalysisResult));
  },
  [PERFORM_STATIC_ANALYSIS_FOR_FILTER_COMPLETED]: (store, { ruleName, filterIndex, staticAnalysisResult }) => {
    return update(store, 'filterExpressionAnalysisResults', r => r.setIn([{ ruleName, filterIndex, readOnly: false }], staticAnalysisResult));
  },
  [FETCH_SOURCE_LIST]: (store) => {
    return set(store, 'loadingSourceList', true);
  },
  [FETCH_SOURCE_LIST_COMPLETED]: (store, { sourceList, sourceListOutOfDate, filterInfo }) => {
    checkArg({ sourceList },
      ArgTypes.orNull(ArgTypes.Immutable.list.of(ArgTypes.object.withShape({ sourceName: ArgTypes.string, isNew: ArgTypes.bool }))));
    checkArg({ sourceListOutOfDate }, ArgTypes.bool);

    const hasPublished = !!store.moduleStatus?.lastPublishedModuleVersion;

    return update(store, setter('loadingSourceList', false),
      setter('sourceListOutOfDate', sourceListOutOfDate),
      setter('loadedSourceListFilterInfo', filterInfo),
      updater(prev => (sourceList ? set(prev, 'sourceList', sourceList.map(s => s.sourceName).sort()) : prev)),
      updater(prev => (sourceList ? set(prev, 'newSourcesSinceLastPublish',
        hasPublished ? sourceList.filter(s => s.isNew).map(s => s.sourceName).toSet() : Set(),
      ) : prev)));
  },
  [FETCH_SOURCE_LIST_FAILED]: (store, { filterInfo }) => {
    // TODO does this necessarily mean the source list is out of date?
    return update(store,
      merger<GoldenRecordsStore>({
        loadingSourceList: false,
        sourceListOutOfDate: true,
      }),
      setter('loadedSourceListFilterInfo', filterInfo),
    );
  },
  [FETCH_CLUSTER_PROFILE_JOB]: (store) => {
    return set(store, 'loadingClusterProfileJob', true);
  },
  [FETCH_CLUSTER_PROFILE_JOB_COMPLETED]: (store, { clusterProfileJobOutOfDate, previewIsUsable, filterInfo }) => {
    return update(store, setter('loadingClusterProfileJob', false),
      setter('clusterProfileJobOutOfDate', Ternary.Known({ value: clusterProfileJobOutOfDate })),
      setter('previewIsUsable', Ternary.Known({ value: previewIsUsable })),
      setter('loadedClusterProfileJobFilterInfo', filterInfo),
    );
  },
  [FETCH_CLUSTER_PROFILE_JOB_FAILED]: (store, { filterInfo }) => {
    return update(store, setter('loadingClusterProfileJob', false),
      setter('loadedClusterProfileJobFilterInfo', filterInfo));
  },
  [BEGIN_EDITING_DATASET_FILTER]: (store, { ruleName, filterIndex }) => {
    const ruleFilterKey = { ruleName, filterIndex, readOnly: false };
    const ruleFilter = getRuleFilter(store, ruleFilterKey);

    if (!ruleFilter) {
      console.error(`Cannot beginEditingDatasetFilter - ruleFilter for rule name '${ruleName}' and filter index ${filterIndex} is undefined`);
      return store;
    }
    if (ruleFilter.type !== df.TYPE) {
      console.error('Cannot beginEditingDatasetFilter - ruleFilter is not of type DatasetFilter');
      return store;
    }

    return update(store,
      setter('datasetFilterState', DatasetFilterState.Editing({ ruleFilterKey, filter: df.incorporateUnaccountedDatasets(ruleFilter, selectAllPrioritizedSources(store)) })),
      resetter('editDatasetFilterSearchString'));
  },
  [SHOW_READONLY_DATASET_FILTER]: (store, { filter }) => {
    return set(store, 'datasetFilterState', DatasetFilterState.ReadOnly({ filter }));
  },
  [CANCEL_EDITING_DATASET_FILTER]: (store) => {
    return set(store, 'datasetFilterState', DatasetFilterState.Hidden({}));
  },
  [SELECT_DATASET_FILTER_SOURCE]: (store, { sourceName }) => {
    return update(store, 'selectedDatasetFilterSources', s => (s.has(sourceName) ? s.remove(sourceName) : s.add(sourceName)));
  },
  [FORCE_SELECT_DATASET_FILTER_SOURCE]: (store, { sourceName }) => {
    // if source is selected, no-op. if it's not selected, it's now the only selected source.
    return update(store, 'selectedDatasetFilterSources', s => (s.has(sourceName) ? s : Set.of(sourceName)));
  },
  [FORCE_SELECT_DATASET_FILTER_BUCKET]: (store, { bucketId }) => {
    // if all sources in bucket are selected, no-op. if some aren't selected, then sources in this bucket are now the only selected sources.
    checkArg({ bucketId }, BucketId.argType);
    const draftDatasetFilter = getDraftDatasetFilter(store);
    const bucket = df.getBucket(draftDatasetFilter, bucketId);
    if (!bucket) {
      console.error(`Cannot forceSelectDatasetFilterBucket - bucket at bucketId ${bucketId} is undefined`);
      return store;
    }
    return update(store, 'selectedDatasetFilterSources', s => (s.union(bucket).size === s.size ? s : bucket));
  },
  [TOGGLE_DATASET_FILTER_EXCLUDED_SELECTED]: (store) => {
    const { excluded } = getDraftDatasetFilter(store);
    return update(store, 'selectedDatasetFilterSources', selected => {
      return excluded.every((datasetName) => selected.has(datasetName)) ? selected.subtract(excluded) : selected.union(excluded);
    });
  },
  [TOGGLE_DATASET_FILTER_PRIORITY_SELECTED]: (store, { priorityIndex }) => {
    const bucket = getDraftDatasetFilter(store).priorities[priorityIndex] || Set();
    return update(store, 'selectedDatasetFilterSources', selected => {
      return bucket.every((datasetName) => selected.has(datasetName)) ? selected.subtract(bucket) : selected.union(bucket);
    });
  },
  [TOGGLE_ALL_DATASET_FILTER_SOURCES_SELECTED]: (store) => {
    const { selectedDatasetFilterSources } = store;
    const allDatasets = df.allDatasets(getDraftDatasetFilter(store));
    return set(store, 'selectedDatasetFilterSources', is(selectedDatasetFilterSources, allDatasets) ? Set() : allDatasets);
  },
  [MOVE_DATASETS_TO_NEW_TOP_PRIORITY]: (store) => {
    const { selectedDatasetFilterSources } = store;
    return update(store,
      s => updateEditingDatasetFilterState(s, ({ ruleFilterKey, filter }) => ({
        ruleFilterKey,
        filter: update(filter,
          d => removeSelectedFromAll(d, selectedDatasetFilterSources),
          updater(removeEmptyBuckets),
          updater('priorities', p => insert(p, 0, selectedDatasetFilterSources.toArray())),
          updater(removeEmptyBuckets)),
      })),
      updater(unselectAllDatasets));
  },
  [MOVE_DATASETS_TO_NEW_BOTTOM_PRIORITY]: (store) => {
    const { selectedDatasetFilterSources } = store;
    return update(store,
      s => updateEditingDatasetFilterState(s, ({ ruleFilterKey, filter }) => ({
        ruleFilterKey,
        filter: update(filter,
          updater(d => removeSelectedFromAll(d, selectedDatasetFilterSources)),
          updater(removeEmptyBuckets),
          updater('priorities', p => push(p, selectedDatasetFilterSources.toArray())),
          updater(removeEmptyBuckets)),
      })),
      updater(unselectAllDatasets));
  },
  [MOVE_DATASETS_TO_NEW_PRIORITY_ABOVE]: (store) => {
    const { selectedDatasetFilterSources } = store;
    const draftDatasetFilter = getDraftDatasetFilter(store);
    const highestSelectedPriorityIndex = getSelectedPriorityIndexes(draftDatasetFilter, selectedDatasetFilterSources)
      .min(); // "highest" priority is lowest index
    const newPriorityIndex = _.isNumber(highestSelectedPriorityIndex)
      ? Math.max(highestSelectedPriorityIndex, 0)
      : draftDatasetFilter.priorities.length; // all selected datasets were excluded
    return update(store,
      s => updateEditingDatasetFilterState(s, ({ ruleFilterKey, filter }) => ({
        ruleFilterKey,
        filter: update(filter,
          updater(d => removeSelectedFromAll(d, selectedDatasetFilterSources)),
          updater('priorities', p => insert(p, newPriorityIndex, selectedDatasetFilterSources.toArray())),
          updater(removeEmptyBuckets)),
      })),
      updater(unselectAllDatasets));
  },
  [MOVE_DATASETS_TO_NEW_PRIORITY_BELOW]: (store, { priorityIndex }) => {
    // if index is supplied, this moves selected datasets to new priority below that priority index
    // otherwise, the new priority is below the lowest selected dataset
    const { selectedDatasetFilterSources } = store;
    const lowestSelectedPriorityIndex = _.isNumber(priorityIndex)
      ? priorityIndex
      : getSelectedPriorityIndexes(getDraftDatasetFilter(store), selectedDatasetFilterSources)
        .max(); // "lowest" priority is highest index
    if (!_.isNumber(lowestSelectedPriorityIndex)) {
      return store; // all selected datasets were excluded, this is a noop
    }
    const newPriorityIndex = lowestSelectedPriorityIndex + 1;

    return update(store,
      s => updateEditingDatasetFilterState(s, ({ ruleFilterKey, filter }) => ({
        ruleFilterKey,
        filter: update(filter,
          updater(d => removeSelectedFromAll(d, selectedDatasetFilterSources)),
          updater('priorities', p => insert(p, newPriorityIndex, selectedDatasetFilterSources.toArray())),
          updater(removeEmptyBuckets)),
      })),
      updater(unselectAllDatasets));
  },
  [MOVE_DATASETS_TO_PRIORITY]: (store, { priorityIndex }) => {
    checkArg({ priorityIndex }, ArgTypes.wholeNumber);
    const { selectedDatasetFilterSources } = store;
    return update(store,
      s => updateEditingDatasetFilterState(s, ({ ruleFilterKey, filter }) => ({
        ruleFilterKey,
        filter: update(filter,
          updater(d => removeSelectedFromAll(d, selectedDatasetFilterSources)),
          updater('priorities', p => (p.length === 0
            ? push(p, selectedDatasetFilterSources.toArray())
            : update(p, priorityIndex, (bucket) => union(bucket, selectedDatasetFilterSources.toArray())))),
          updater(removeEmptyBuckets)),
      })),
      updater(unselectAllDatasets));
  },
  [EXCLUDE_DATASETS]: (store) => {
    const { selectedDatasetFilterSources } = store;
    return update(store,
      s => updateEditingDatasetFilterState(s, ({ ruleFilterKey, filter }) => ({
        ruleFilterKey,
        filter: update(filter,
          updater(d => removeSelectedFromAll(d, selectedDatasetFilterSources)),
          updater(removeEmptyBuckets),
          updater('excluded', e => union(e, selectedDatasetFilterSources.toArray()))),
      })),
      updater(unselectAllDatasets));
  },
  [BEGIN_CONFIRMING_REMOVE_SOURCE_FROM_DATASET_FILTERS]: (store, { datasetName }) => {
    return set(store, 'confirmingRemoveSourceFromDatasetFilters', datasetName);
  },
  [CANCEL_CONFIRMING_REMOVE_SOURCE_FROM_DATASET_FILTERS]: (store) => {
    return reset(store, 'confirmingRemoveSourceFromDatasetFilters');
  },
  [CONFIRM_REMOVE_SOURCE_FROM_DATASET_FILTERS]: (store) => {
    const datasetName = store.confirmingRemoveSourceFromDatasetFilters;
    if (!isString(datasetName)) {
      console.error('Cannot confirmRemoveSourceFromDatasetFilters - not confirming for any defined dataset name');
      return store;
    }
    return update(store,
      s => updateEditingDatasetFilterState(s, ({ ruleFilterKey, filter }) => ({
        ruleFilterKey,
        filter: df.removeDataset(filter, datasetName),
      })),
      resetter('confirmingRemoveSourceFromDatasetFilters'),
      updater('nextEntityRule', ruleDelta => (ruleDelta ? removeDatasetFromDatasetFilter(ruleDelta, datasetName) : ruleDelta)),
      updater('nextRules', ruleDeltas => ruleDeltas.map(ruleDelta => removeDatasetFromDatasetFilter(ruleDelta, datasetName))),
      updater(unselectAllDatasets),
    );
  },
  [SAVE_DATASET_FILTER_EDITS]: (store) => {
    const { datasetFilterState } = store;
    const invalidErr = () => { logInvalidDatasetFilterstate(); return store; };
    return datasetFilterState.case({
      Editing: ({ ruleFilterKey, filter }) => {
        const ruleName = ruleFilterKey.ruleName;
        const filterIndex = ruleFilterKey.filterIndex;
        const draftDatasetFilter = filter;
        return update(store,
          s => updateRuleFilters(s, ruleName, (filters) => filters.set(filterIndex, draftDatasetFilter)),
          setter('datasetFilterState', DatasetFilterState.Hidden({})),
          resetter('editDatasetFilterSearchString'),
          resetter('selectedDatasetFilterSources'));
      },
      ReadOnly: invalidErr,
      Hidden: invalidErr,
    });
  },
  [UPDATE_EDIT_DATASET_FILTER_SEARCH_STRING]: (store, { searchString }) => {
    return set(store, 'editDatasetFilterSearchString', searchString);
  },
  [SELECT_ROWS]: (store, { selectedRowIndices }) => {
    return set(store, 'selectedRowIndices', selectedRowIndices);
  },
  [TOGGLE_FILTER_PANEL]: (store) => {
    return set(store, 'showFilterPanel', !store.showFilterPanel);
  },
  [SHOW_SELECT_OVERRIDE_FILTER_DIALOG]: (store) => {
    return set(store, 'showingSelectOverrideFilterDialog', true);
  },
  [HIDE_SELECT_OVERRIDE_FILTER_DIALOG]: (store) => {
    return set(store, 'showingSelectOverrideFilterDialog', false);
  },
  [HIDE_BOOKMARKS_ONBOARDING_MESSAGE]: (store) => {
    return reset(store, 'linkedFromOnboardingButton');
  },
  [UPDATE_DESIRED_SAMPLE_CLUSTER]: (store, { rowNumber }) => {
    const { moduleFromLastUpdate, draftPage } = store;
    const record = draftPage?.items.get(rowNumber);
    const clusterId = getClusterIdOfDraftRecord(moduleFromLastUpdate, record);
    if (!isDefined(clusterId)) {
      console.error(`Could not fetch cluster sample for draft record - no cluster id for record at row number ${rowNumber}`);
      return store;
    }
    const clusterName = getClusterNameOfDraftRecord(moduleFromLastUpdate, record);
    const clusterNameAsString = jsonContentToString(clusterName);
    const clusterSize = getClusterSizeOfDraftRecord(record) || 0;
    return update(store, 'clusterSampleState', s => ClusterSampleState.updateDesiredSampleCluster(s, clusterId, clusterNameAsString, clusterSize));
  },
  [FETCH_CLUSTER_SAMPLE]: (store, { clusterId, clusterName, clusterSize, fetchSequence }) => {
    return update(store, 'clusterSampleState', previous => ClusterSampleState.loadingClusterSampleState(previous, clusterId, clusterName, clusterSize, fetchSequence));
  },
  [FETCH_CLUSTER_SAMPLE_COMPLETED]: (store, { table, clusterId }) => {
    return update(store, 'clusterSampleState', previous => ClusterSampleState.successClusterSampleState(previous, clusterId, table));
  },
  [FETCH_CLUSTER_SAMPLE_FAILED]: (store, { clusterId }) => {
    return update(store, 'clusterSampleState', previous => ClusterSampleState.errorClusterSampleState(previous, clusterId));
  },
  [TOGGLE_SHOW_CLUSTER_SAMPLE_TABLE]: (store) => {
    return update(store, 'clusterSampleState', ClusterSampleState.toggleShowClusterSampleTable);
  },
};
