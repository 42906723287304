/* eslint-disable no-restricted-globals */

import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import { reducers as recordsReducers } from '../records/RecordsStore';
import { getUrlWithHash } from '../utils/Routing';

const RebaseConflictDialog = connect((state) => {
  const { transforms: { showingRebaseConflictDialog } } = state;
  return {
    url: getUrlWithHash(state.update('records', recordsState => recordsReducers['Records.useServerStorage'](recordsState))),
    show: showingRebaseConflictDialog,
  };
}, {
  onHide: () => ({ type: 'Transforms.hideRebaseConflictDialog' }),
})(({ url, show, onHide }) => {
  return (
    <Dialog
      dialogStyle={DialogStyle.PRIMARY}
      show={show}
      onHide={onHide}
      title="Encountered conflict loading saved changes"
      body={(
        <span>
          A conflict was encountered when attempting to put your local changes on top of changes recently saved to the transformation list by another user.
          Your local changes have not been affected.
          <br /><br />
          You may want to <a rel="noopener noreferrer" target="_blank" href={url}>view the updated transformations in a new tab</a>.
          There you can reproduce your local changes and Save without conflict, as you will not be able to Save changes here.
        </span>
      )}
      footer={
        <ButtonToolbar>
          <Button key="hide" buttonType="Secondary" onClick={onHide}>Okay</Button>
        </ButtonToolbar>
      }
    />
  );
});

export default RebaseConflictDialog;
