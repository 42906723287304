import { Set } from 'immutable';

export const RESERVED_COLUMN_NAMES = Set.of(
  'Sources',
  'Cluster Size',
).map(s => s.toLowerCase());

export function isReserved(fieldName: string) {
  return RESERVED_COLUMN_NAMES.contains(fieldName.toLowerCase());
}
