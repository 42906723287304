import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Checkbox from '../components/Input/Checkbox';
import Input from '../components/Input/Input';
import LoadingPanel from '../components/LoadingPanel';
import { CLASSIFICATION } from '../constants/ProjectTypes';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import Recipe from '../models/Recipe';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { getPath } from '../utils/Values';
import { commitConfigureDataset } from './SharedDatasetCatalogApi';

// TODO description changes are attached to Dataset, so not multi-project safe
const ConfigureDatasetDialog = connect((state) => {
  const { sharedDatasetCatalog: { configuringDataset, committingConfigureDataset }, projectDatasetCatalog: { datasets: projectDatasets }, datasetCatalog: { datasets: allDatasets }, location: { recipeId } } = state;
  const datasets = _.isNumber(recipeId) ? projectDatasets : allDatasets;
  const datasetDoc = datasets.find(d => d.data.name === configuringDataset);
  const { recipeDoc } = selectActiveProjectInfo(state) || {};
  return { committingConfigureDataset, datasetDoc, recipeDoc };
}, {
  onCommitConfigureDataset: commitConfigureDataset,
  onHide: () => ({ type: 'SharedDatasetCatalog.cancelConfiguringDataset' }),
})(
  // TODO description changes are attached to Dataset, so not multi-project safe
  class ConfigureDatasetDialog extends React.Component {
    static propTypes = {
      committingConfigureDataset: PropTypes.bool.isRequired,
      datasetDoc: Document.propType.withDataType(Dataset),
      onCommitConfigureDataset: PropTypes.func.isRequired,
      onHide: PropTypes.func.isRequired,
      recipeDoc: Document.propType.withDataType(Recipe), // ML recipe, not schema mapping
    };

    state = {
      description: undefined,
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.datasetDoc && this.props.datasetDoc !== nextProps.datasetDoc) {
        this.setState({ description: nextProps.datasetDoc.data.description });

        if (nextProps.recipeDoc && nextProps.recipeDoc.data.type === CLASSIFICATION) {
          const datasetName = nextProps.datasetDoc.data.name;
          const metadata = nextProps.recipeDoc.data.metadata.get('CATEGORIZATION');
          const weight = getPath(metadata, 'datasetWeights', datasetName) || 1;
          const external = getPath(metadata, 'datasetExternalStatus', datasetName) || false;
          this.setState({ weight, external });
        }
      }
    }

    setField = (key) => {
      return (value) => {
        this.setState({ [key]: value });
      };
    };

    setMetadataField = (key) => {
      return (value) => {
        const recipeMetadata = _.clone(this.state.recipeMetadata);
        recipeMetadata[key] = { [this.props.datasetDoc.data.name]: value };
        this.setState({ recipeMetadata });
      };
    };

    commit = () => {
      const { onCommitConfigureDataset } = this.props;
      const description = this.state.description;
      const weight = this.isCategorizationProject() ? this.state.weight : undefined;
      const external = this.isCategorizationProject() ? this.state.external : undefined;
      onCommitConfigureDataset({ description, weight, external });
    };

    renderDescription = () => {
      return (
        <div className="section">
          <Input
            title="Description"
            onChange={this.setField('description')}
            value={this.state.description}
          />
        </div>
      );
    };

    renderWeight = () => {
      if (!this.props.datasetDoc || !this.isCategorizationProject()) {
        return undefined;
      }
      return (
        <div className="section">
          <Input
            title="Weight"
            onChange={this.setField('weight')}
            value={this.state.weight}
            type="number"
          />
        </div>
      );
    };

    renderIsExternal = () => {
      if (!this.props.datasetDoc || !this.isCategorizationProject()) {
        return undefined;
      }
      return (
        <div className="section">
          <Checkbox
            title="Is the dataset external"
            onChange={this.setField('external')}
            value={this.state.external}
            titlePosition="right"
          />
        </div>
      );
    };

    isCategorizationProject = () => {
      const { recipeDoc } = this.props;
      return recipeDoc && recipeDoc.data.type === CLASSIFICATION;
    };

    render() {
      const { datasetDoc, onHide, committingConfigureDataset } = this.props;
      const show = !!datasetDoc;
      return (
        <Dialog
          show={show}
          onHide={onHide}
          className="configure-dataset-dialog"
          dialogStyle={DialogStyle.PRIMARY}
          title={show ? `Edit "${datasetDoc.data.name}"` : ''}
          body={committingConfigureDataset ? (
            <LoadingPanel style={{ position: 'relative', height: '100px' }} />
          ) : show ? (
            <div className="configure-dataset-dialog-content">
              {this.renderDescription()}
              {this.renderWeight()}
              {this.renderIsExternal()}
            </div>
          ) : null}
          footer={show ? (
            <ButtonToolbar>
              <Button
                onClick={onHide}
                buttonType="Secondary"
              >
                Cancel
              </Button>
              <Button
                onClick={this.commit}
              >
                Save Changes
              </Button>
            </ButtonToolbar>
          ) : null}
        />
      );
    }
  },
);

export default ConfigureDatasetDialog;
