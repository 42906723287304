// com.tamr.wms.job.JobStatus

import { List, Map } from 'immutable';

import JobState from '../constants/JobState';
import { ArgTypes } from '../utils/ArgValidation';
import JobMetric from './JobMetric';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

class JobStatus extends getModelHelpers({
  state: { type: JobState.argType }, // TODO should treat JobState as a typed enum
  message: { type: ArgTypes.string },
  cancelUser: { type: ArgTypes.nullable(ArgTypes.string) },
  startTime: { type: ArgTypes.nullable(ArgTypes.timestamp) },
  endTime: { type: ArgTypes.nullable(ArgTypes.timestamp) },
  metrics: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(JobMetric)) },
  componentExecutionTimes: { type: ArgTypes.Immutable.map.of(ArgTypes.number, ArgTypes.string) },
}, 'JobStatus')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class JobStatusRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: any) {
    const { state, message, cancelUser, startTime, endTime, metrics, componentExecutionTimes } = obj;
    return new JobStatus({
      state,
      message,
      cancelUser,
      startTime,
      endTime,
      metrics: List(metrics).map(jm => JobMetric.fromJSON(jm)),
      componentExecutionTimes: Map(componentExecutionTimes),
    });
  }
}

export default JobStatus;
