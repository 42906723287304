import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { pure } from 'recompose';
import _ from 'underscore';

import { shortFormat } from '../utils/Numbers';
import { pluralize } from '../utils/Strings';
import DashboardCard from './DashboardCard';
import TitleLabel from './TitleLabel';

const DualValueCard = _.compose(
  pure,
)(class DualValueCard extends React.Component {
  static propTypes = {
    bottomNounPlural: PropTypes.node.isRequired,
    bottomNounSingular: PropTypes.node.isRequired,
    bottomValue: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    short: PropTypes.bool,
    topNounPlural: PropTypes.node.isRequired,
    topNounSingular: PropTypes.node.isRequired,
    topValue: PropTypes.number.isRequired,
  };

  static defaultProps = { short: false };

  renderTop = () => {
    const { topValue, topNounSingular, topNounPlural } = this.props;
    return <TitleLabel value={topValue} singular={topNounSingular} plural={topNounPlural} />;
  };

  renderBottom = () => {
    const { bottomValue, bottomNounSingular, bottomNounPlural } = this.props;
    const numberMessage = shortFormat(bottomValue);
    const nounMessage = pluralize(bottomValue, bottomNounSingular, bottomNounPlural);
    return <div className="bottom">From &nbsp;{numberMessage}&nbsp;{nounMessage}</div>;
  };

  render() {
    const className = classNames('dashboard-dual-value-card', { short: this.props.short });
    return (
      <DashboardCard className={className} onClick={this.props.onClick}>
        {this.renderTop()}
        {this.renderBottom()}
      </DashboardCard>
    );
  }
});

export default DualValueCard;
