import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import LoadingPanel from '../components/LoadingPanel';
import Recipe from '../models/Recipe';
import { getActiveRecipeDoc, isJobRunning } from '../utils/Selectors';
import style from './DnfBuilder.module.scss';
import DnfBuilderClauses from './DnfBuilderClauses';
import DnfBuilderFooter from './DnfBuilderFooter';
import DnfBuilderHeader from './DnfBuilderHeader';

const DnfBuilder = _.compose(
  connect((state) => {
    const { dnfBuilder: { dnfLoading } } = state;
    const recipeDoc = getActiveRecipeDoc(state);
    const recipeId = recipeDoc ? recipeDoc.id.id : undefined;
    const recipe = recipeDoc ? recipeDoc.data : undefined;
    return {
      recipe,
      recipeId,
      loading: dnfLoading,
      busy: isJobRunning(state, { recipeOperation: 'pairs', forActiveProject: true })
        || isJobRunning(state, { description: 'Generate Pair Estimates', forActiveProject: true }), // NOTE this is currently broken becuase recipeId is not attached to estimates job metadata
    };
  }, {
    onUpdateInitialDedupInfo: (recipe) => ({ type: 'DnfBuilder.updateInitialDedupInfo', recipe }),
  }),
)(class DnfBuilder extends React.Component {
  static propTypes = {
    busy: PropTypes.bool.isRequired,
    onUpdateInitialDedupInfo: PropTypes.func.isRequired,
    recipe: PropTypes.instanceOf(Recipe).isRequired,
  };

  componentDidMount() {
    const { onUpdateInitialDedupInfo, recipe } = this.props;
    onUpdateInitialDedupInfo(recipe);
  }

  render() {
    const { busy, loading } = this.props;
    return loading ? <LoadingPanel /> : (
      <div className={style.component}>
        <DnfBuilderHeader />
        <DnfBuilderClauses />
        <DnfBuilderFooter />
        {busy ? <LoadingPanel semiTransparent /> : null}
      </div>
    );
  }
});

export default DnfBuilder;
