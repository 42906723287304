import 'react-keyed-file-browser/dist/react-keyed-file-browser.css';

import { Map } from 'immutable';
import React from 'react';
// @ts-expect-error
import FileBrowser from 'react-keyed-file-browser';
import { connect } from 'react-redux';

import { BrowserFile } from '../api/CoreConnectService';
import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import ConditionalButton from '../components/ConditionalButton';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import DocLink from '../components/DocLink';
import Input from '../components/Input/Input';
import Selector from '../components/Input/Selector';
import LoadingPanel from '../components/LoadingPanel';
import TamrIcon from '../components/TamrIcon';
import CoreConnectFileType, { CoreConnectFileTypeE } from '../coreConnectService/CoreConnectFileType';
import CoreConnectProvider from '../coreConnectService/CoreConnectProvider';
import {
  CLOSE_EXPORT_DIALOG,
  SET_SINK_BUCKET_NAME,
  SET_SINK_FILE_TYPE,
  SET_SINK_PATH_PREFIX,
  SET_SINK_PROJECT_NAME,
} from '../coreConnectService/CoreConnectServiceActionTypes';
import { fetchFilesWithCoreConnect, startTransferTamrToCloud } from '../coreConnectService/CoreConnectServiceAsync';
import { AppAction, AppThunkAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { isBlank } from '../utils/Strings';
import style from './ExportWithCoreConnectService.module.scss';

export function isDirectory(path: string): boolean {
  return path.endsWith('/');
}

export default connect((state: AppState) => {
  const {
    coreConnectService: {
      coreconnectDefaultProvider,
      sinkProjectName,
      sinkBucketName,
      sinkPathPrefix,
      sinkFileType,
      retrievingSourceFiles,
      exportDialogOpen,
      sourceFiles,
      submittingJob,
      tamrSourceDataset,
    },
  } = state;
  return {
    coreconnectDefaultProvider: coreconnectDefaultProvider?.toUpperCase(),
    sinkProjectName,
    sinkBucketName,
    sinkPathPrefix,
    sinkFileType,
    retrievingSourceFiles,
    exportDialogOpen,
    sinkFiles: sourceFiles,
    readyToFetchFiles: !isBlank(sinkProjectName)
      && !isBlank(sinkBucketName)
      && !isBlank(sinkPathPrefix)
      && !isBlank(coreconnectDefaultProvider),
    submittingJob,
    projectDisplayName: coreconnectDefaultProvider?.toUpperCase() === 'S3' ? 'Region' : 'Project',
    tamrSourceDataset,
  };
}, {
  onChangeSinkProjectName: (sinkProjectName: string): AppAction => ({ type: SET_SINK_PROJECT_NAME, sinkProjectName }),
  onChangeSinkBucketName: (sinkBucketName: string): AppAction => ({ type: SET_SINK_BUCKET_NAME, sinkBucketName }),
  onChangeSinkPathPrefix: (sinkPathPrefix: string): AppAction => ({ type: SET_SINK_PATH_PREFIX, sinkPathPrefix }),
  onChangeSinkFileType: (sinkFileType: CoreConnectFileTypeE): AppAction => ({ type: SET_SINK_FILE_TYPE, sinkFileType }),
  onCloseExportDialog: (): AppAction => ({ type: CLOSE_EXPORT_DIALOG }),
  onFetchFilesWithCoreConnect: (): AppThunkAction<void> => fetchFilesWithCoreConnect(),
  onExportWithCoreConnect: (): AppThunkAction<void> => startTransferTamrToCloud(),
})(({
  coreconnectDefaultProvider,
  sinkProjectName,
  sinkBucketName,
  sinkPathPrefix,
  sinkFileType,
  retrievingSourceFiles,
  exportDialogOpen,
  onChangeSinkProjectName,
  onChangeSinkBucketName,
  onChangeSinkPathPrefix,
  onChangeSinkFileType,
  onCloseExportDialog,
  readyToFetchFiles,
  sinkFiles,
  onFetchFilesWithCoreConnect,
  onExportWithCoreConnect,
  submittingJob,
  projectDisplayName,
  tamrSourceDataset,
}) => {
  // @ts-ignore
  const topLevelProject:string = { S3: 'Region', ADLS2: 'Account Name', GCS: 'Project' }[coreconnectDefaultProvider] || '';
  // @ts-ignore
  const secondLevelBucket:string = { S3: 'Bucket', ADLS2: 'Container', GCS: 'Bucket' }[coreconnectDefaultProvider] || '';
  return (
    <Dialog
      show={exportDialogOpen}
      className={style.dialog}
      dialogStyle={DialogStyle.PRIMARY}
      title={`Export Dataset ${tamrSourceDataset?.name}`}
      onHide={onCloseExportDialog}
      body={
        <>
          { submittingJob ? <LoadingPanel /> : null }
          <div className={style.container}>
            Export a CSV or AVRO dataset to {coreconnectDefaultProvider !== undefined ? CoreConnectProvider.get(coreconnectDefaultProvider) : null}.&nbsp;
            <DocLink path="exporting">
              Learn more
            </DocLink>.
          </div>
          <div className={style.container}>
            <div className={style.sinkConfig}>
              <div className={style.subContainer}>
                <div className={style.selectorLabel}>
                  File type:
                </div>
                <Selector
                  className={style.fileTypeSelector}
                  onChange={onChangeSinkFileType}
                  placeholder="Select file type"
                  values={[
                    { value: CoreConnectFileType.CSV, display: CoreConnectFileType.CSV },
                    { value: CoreConnectFileType.AVRO, display: CoreConnectFileType.AVRO },
                  ]}
                  value={sinkFileType}
                />
              </div>
              <div className={style.subContainer}>
                <Input
                  componentClassName={style.project}
                  value={sinkProjectName}
                  title={topLevelProject}
                  onChange={onChangeSinkProjectName}
                />
              </div>
              <div className={style.subContainer}>
                <Input
                  componentClassName={style.bucket}
                  value={sinkBucketName}
                  title={secondLevelBucket}
                  onChange={onChangeSinkBucketName}
                />
              </div>
              <div className={style.subContainer}>
                <Input
                  componentClassName={style.path}
                  value={sinkPathPrefix}
                  title="Path (include filename with suffix)"
                  onChange={onChangeSinkPathPrefix}
                  invalid={(sinkFileType === CoreConnectFileType.AVRO && !sinkPathPrefix?.toLowerCase().endsWith('.avro'))}
                />
              </div>
              <div className={style.subContainer}>
                <div className={style.applyButton}>
                  <ConditionalButton
                    tooltipPlacement="top"
                    preconditions={Map({
                      'Please fill out the fields above to browse': readyToFetchFiles,
                    })}
                    onClick={onFetchFilesWithCoreConnect}
                  >
                    Apply
                  </ConditionalButton>
                </div>
              </div>
            </div>
            <div
              className={style.fileBrowserContainer}
              style={{ backgroundColor: sinkFiles.size === 0 ? '#E7E7E7' : 'white' }}
            >
              { sinkFiles.size === 0 ? (
                <div className={style.fileBrowserLabel}>
                  Specify at least a {projectDisplayName} and Bucket to browse destinations.
                </div>
              ) : (
                <FileBrowser
                  files={sinkFiles}
                  icons={{
                    Folder: <TamrIcon className={style.fileBrowserIcon} iconName="folder" size={16} />,
                    FolderOpen: <TamrIcon className={style.fileBrowserIcon} iconName="folder-open" size={16} />,
                  }}
                  onSelect={(browserFile: BrowserFile) => {
                    browserFile && onChangeSinkPathPrefix(browserFile.key);
                  }}
                  />
              )}
              { retrievingSourceFiles ? <LoadingPanel /> : null }
            </div>
          </div>
        </>
      }
      footer={
        <ButtonToolbar>
          <Button buttonType="Secondary" onClick={onCloseExportDialog}>Cancel</Button>
          <ConditionalButton
            tooltipPlacement="top"
            preconditions={Map({
              'Please provide a file type for the exported dataset': sinkFileType != null,
              'Please specify the .avro extension in the path': !(sinkFileType === CoreConnectFileType.AVRO && !sinkPathPrefix?.toLowerCase().endsWith('.avro')),
              'Please provide details about where to the export the dataset to': readyToFetchFiles,
            })}
            onClick={onExportWithCoreConnect}
          >
            Export Dataset
          </ConditionalButton>
        </ButtonToolbar>
      }
    />
  );
});
