import { Map, Set } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import { startAddDataset } from '../addDataset/AddDatasetAsync';
import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import ConditionalButton from '../components/ConditionalButton';
import { SET_SOURCE_FILTER_DATASETS } from '../schema-mapping/SchemaMappingActionTypes';
import { AppAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
// @ts-expect-error
import TagManager from '../tags/TagManager';
// @ts-expect-error
import TagSelector from '../tags/TagSelector';
import { isCuratorByProjectId } from '../utils/Authorization';
import { history } from '../utils/History';
import { getAuthorizedUser, selectActiveProjectInfo } from '../utils/Selectors';
import { getPath, isDefined } from '../utils/Values';
import style from './ProjectDatasetCatalogTaskbar.module.scss';

const ProjectDatasetCatalogTaskbar = connect((state: AppState) => {
  const {
    projectDatasetCatalog: { selectedRows, datasets, noDatasetsInProject },
    datasetMetadata: { propertiesSidebarOpen },
  } = state;
  const projectInfo = selectActiveProjectInfo(state);
  const authUser = getAuthorizedUser(state);
  const canUserPerformActions = isCuratorByProjectId(authUser, getPath(projectInfo, 'projectId'));
  return {
    projectInfo,
    selectedRows,
    canUserPerformActions,
    datasets,
    noDatasetsInProject,
    propertiesSidebarOpen,
  };
}, {
  onConfirmRemoveDatasets: (datasetNames: Set<string>): AppAction => ({ type: 'ProjectDatasetCatalog.confirmRemoveDatasets', datasetNames }),
  onConfirmProfile: (datasetNames: Set<string>): AppAction => ({ type: 'SharedDatasetCatalog.confirmProfile', datasetNames }),
  onManageMembership: () => startAddDataset({ isCatalogPage: false }),
  onSetSourceFilterDatasets: (datasetNames: Set<string>): AppAction => ({ type: SET_SOURCE_FILTER_DATASETS, datasetNames }),
  onToggleProperties: () => ({ type: 'DatasetMetadata.toggleSidebar' }),
})(({ noDatasetsInProject, onManageMembership, projectInfo, onSetSourceFilterDatasets, onConfirmProfile,
  canUserPerformActions, datasets, selectedRows, onConfirmRemoveDatasets, onToggleProperties,
  propertiesSidebarOpen }) => {
  if (noDatasetsInProject) {
    return <noscript />;
  }
  const selectedDatasets = selectedRows.map(i => datasets.get(i));
  const selectedDatasetNames = selectedDatasets.map(d => d?.data.name).filter(isDefined);
  const smRecipeId = getPath(projectInfo, 'smRecipeDoc', 'id', 'id');
  const mustSelectPreconditions = Map<string, boolean>().set('You must select one or more datasets', !selectedRows.isEmpty());
  const propertiesPreconditions = Map<string, boolean>().set('You must select exactly one dataset.', selectedRows.size === 1);
  const togglePropertiesButtonName = propertiesSidebarOpen ? 'Close properties' : 'Open properties';
  return (
    <div className={style.component}>
      {canUserPerformActions ?
        <ButtonToolbar className={style.buttonToolbar}>
          <TagSelector selectedDatasets={selectedDatasets} />
          <ConditionalButton
            className={style.map}
            preconditions={mustSelectPreconditions}
            buttonType="Secondary"
            onClick={() => {
              onSetSourceFilterDatasets(selectedDatasetNames.toSet());
              history.push(`/schema-mapping/recipe/${smRecipeId}`);
            }}
          >
            Map
          </ConditionalButton>
          <ConditionalButton
            className={style.profile}
            preconditions={mustSelectPreconditions}
            buttonType="Secondary"
            onClick={() => {
              onConfirmProfile(selectedDatasetNames);
            }}
          >
            Profile
          </ConditionalButton>
          <ConditionalButton
            className={style.delete}
            preconditions={mustSelectPreconditions}
            buttonType="Secondary"
            onClick={() => {
              onConfirmRemoveDatasets(selectedDatasetNames);
            }}
          >
            Remove from project
          </ConditionalButton>
          <div className={style.spacer} />
          <div>
            <ConditionalButton
              preconditions={propertiesPreconditions}
              buttonType="Secondary"
              onClick={onToggleProperties}
              style={{ margin: '3px' }}
            >
              {togglePropertiesButtonName}
            </ConditionalButton>
          </div>
          <div>
            <Button
              buttonType="Primary"
              onClick={onManageMembership}
              style={{ margin: '3px' }}
            >
              Edit datasets
            </Button>
          </div>
        </ButtonToolbar>
        : null}
      <TagManager />
    </div>
  );
});

export default ProjectDatasetCatalogTaskbar;
