import { List } from 'immutable';

import PostButtonSpec from '../models/PostButtonSpec';
import RedirectButtonSpec from '../models/RedirectButtonSpec';
import StorageHealthCheckResult from '../models/StorageHealthCheckResult';

export const PROFILE_JOB_REQUESTED = 'Chrome.profileJobRequested';
export const PROFILE_JOB_REQUEST_COMPLETE = 'Chrome.profileJobRequestComplete';
export const FETCH_EXTENSION_BUTTONS_COMPLETE = 'Chrome.fetchExtensionButtionsComplete';
export const FETCH_EXTENSION_BUTTONS_FAILED = 'Chrome.fetchExtensionButtionsFailed';
export const EXTENSION_BUTTON_POST_CALL_REQUESTED = 'Chrome.extensionButtonPostCallRequested';
export const EXTENSION_BUTTON_POST_CALL_COMPLETE = 'Chrome.extensionButtonPostCallComplete';
export const SHOW_EXTENSION_RESPONSE_DIALOG = 'Chrome.showExtensionButtonPostCallDialog';

export const STORAGE_HEALTH_CHECK_COMPLETE = 'Chrome.storageHealthCheckComplete';

export const STORAGE_HEALTH_CHECK_FAILED = 'Chrome.storageHealthCheckFailed';


interface ChromeProfileJobRequested {
  type: typeof PROFILE_JOB_REQUESTED
}

interface ChromeProfileJobRequestComplete {
  type: typeof PROFILE_JOB_REQUEST_COMPLETE
}

interface FetchExtensionButtonsComplete {
  type: typeof FETCH_EXTENSION_BUTTONS_COMPLETE
  data: List<PostButtonSpec|RedirectButtonSpec>
}

interface FetchExtensionButtonsFailed {
  type: typeof FETCH_EXTENSION_BUTTONS_FAILED
}

interface ExtensionButtonPostCallRequested {
  type: typeof EXTENSION_BUTTON_POST_CALL_REQUESTED
  buttonSpec: PostButtonSpec
}

interface ShowExtensionResponseDialog {
  type: typeof SHOW_EXTENSION_RESPONSE_DIALOG
  show: boolean
}

interface ExtensionButtonPostCallComplete {
  type: typeof EXTENSION_BUTTON_POST_CALL_COMPLETE
  successful: boolean
  message: string
}

interface StorageHealthCheckComplete {
  type: typeof STORAGE_HEALTH_CHECK_COMPLETE
  data: StorageHealthCheckResult
}

interface StorageHealthCheckFailed {
  type: typeof STORAGE_HEALTH_CHECK_FAILED
}

export type ChromeActionTypes
  = ChromeProfileJobRequested
  | ChromeProfileJobRequestComplete
  | FetchExtensionButtonsComplete
  | FetchExtensionButtonsFailed
  | ExtensionButtonPostCallRequested
  | ExtensionButtonPostCallComplete
  | ShowExtensionResponseDialog
  | StorageHealthCheckComplete
  | StorageHealthCheckFailed
