import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import style from './ActionBar.module.scss';

@connect(({ taxonomy: { expanded } }) => {
  return { expanded };
}, {
  onToggleSidebar: () => ({ type: 'Taxonomy.toggleExpanded' }),
})
class ActionBar extends React.Component {
  render() {
    const { expanded, onToggleSidebar } = this.props;
    return (
      <div className={style.actionBar}>
        <Button
          className={style.showCategoriesDetailsButton}
          buttonType="Secondary"
          onClick={onToggleSidebar}
        >
          {expanded ? 'Close details' : 'Open details'}
        </Button>
      </div>
    );
  }
}

export default ActionBar;
