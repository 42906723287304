// com.tamr.dataset.TableConfig

export const PATH = 'external.file.path';
export const FILE_TYPE = 'external.file.type';
export const DELIMITER = 'external.file.delimiter';
export const QUOTE_CHAR = 'external.file.quote_char';
export const ESCAPE_CHAR = 'external.file.escape_char';
export const BIGQUERY_PROJECT = 'bigquery.project';
export const BIGQUERY_DATASET = 'bigquery.dataset';
export const BIGQUERY_TABLE = 'bigquery.table';
