import classNames from 'classnames';
import React from 'react';
import _ from 'underscore';

import ScoreThresholds, { Symbol, SymbolE } from '../models/ScoreThresholds';
import CenterContent from './CenterContent';
import style from './ScoreIcon.module.scss';

interface BaseProps {
  className?: string
  score?: number
  size?: number
  titleNoun?: string
}

export const ScoreIconForced: React.FC<BaseProps & { symbol: SymbolE}> = ({
  titleNoun,
  score,
  className,
  size = 14,
  symbol,
}) => {
  let confidenceIconColorClass;
  let titlePrefix;
  if (symbol === Symbol.H) {
    confidenceIconColorClass = style.high;
    titlePrefix = 'High';
  } else if (symbol === Symbol.M) {
    confidenceIconColorClass = style.medium;
    titlePrefix = 'Medium';
  } else {
    confidenceIconColorClass = style.low;
    titlePrefix = 'Low';
  }
  let title;
  if (titleNoun) {
    title = `${titlePrefix} ${titleNoun} - Score: ${(_.isNumber(score) && score.toPrecision(3))}`;
  }
  const classes = classNames(style.component, confidenceIconColorClass, className);
  const cssStyle = {
    width: size,
    height: size,
    fontSize: size - 3,
  };
  return (
    <CenterContent
      className={classes}
      style={cssStyle}
      title={title}
    >
      {symbol}
    </CenterContent>
  );
};

const ScoreIcon: React.FC<BaseProps & {
  scoreThresholds: ScoreThresholds
}> = ({ score, scoreThresholds, ...props }) => {
  if (!_.isNumber(score)) {
    return null;
  }
  const symbol = scoreThresholds.getSymbol(score);
  return <ScoreIconForced {...props} score={score} symbol={symbol} />;
};

export default ScoreIcon;
