import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import DocLink from '../components/DocLink';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import { history } from '../utils/History';
import { selectActiveProjectInfo } from '../utils/Selectors';
import style from './CategorizationDashboardIntro.module.scss';

const CategorizationDashboardIntro = _.compose(
  connect(state => {
    const { recipeId, smRecipeId } = selectActiveProjectInfo(state);
    return { recipeId, smRecipeId };
  }),
)(({ smRecipeId }) => {
  return (
    <div className={style.container}>
      <div className={style.card}>
        <div className={style.row}>
          <div className={style.side}>
            <div><TamrIcon iconName="library-books" className={style.icon} size={72} /></div>
            <div className={style.title}>Start with your data</div>
            <div>All the <Term>records</Term> across your datasets will be categorized using a taxonomy you provide later.</div>
            <div className={style.button}>
              <Button onClick={() => history.push(`/datasets/recipe/${smRecipeId}`)}>Go to Datasets</Button>
            </div>
          </div>
        </div>
        <div className={style.footer}>
          <DocLink path="overall-workflow-classification">Learn more</DocLink>
        </div>
      </div>
    </div>
  );
});

export default CategorizationDashboardIntro;
