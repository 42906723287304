import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import AllUnifiedAttributesLoader from '../../schema-mapping/AllUnifiedAttributesLoader';
import SourceAttributesLoader from '../../schema-mapping/SourceAttributesLoader';
import { history } from '../../utils/History';
import DualValueCard from '../DualValueCard';

const FieldsCard = connect(({ schemaMapping: { allUnifiedAttributes, sourceAttributes } }) => {
  return { numSourceFields: sourceAttributes.size, numUnifiedFields: allUnifiedAttributes.size };
})(class FieldsCard extends React.Component {
  static propTypes = {
    numSourceFields: PropTypes.number.isRequired,
    numUnifiedFields: PropTypes.number.isRequired,
    short: PropTypes.bool,
    smRecipeId: PropTypes.number.isRequired,
  };

  static defaultProps = { short: false };

  navigateToSchemaMapping = () => {
    const { smRecipeId } = this.props;
    history.push(`/schema-mapping/recipe/${smRecipeId}`);
  };

  render() {
    const { numSourceFields, numUnifiedFields } = this.props;
    return (
      <div className="dashboard-card">
        <DualValueCard
          onClick={this.navigateToSchemaMapping}
          short={this.props.short}
          bottomNounPlural="Source Fields"
          bottomNounSingular="Source Field"
          bottomValue={numSourceFields}
          topNounPlural={'Unified Fields'}
          topNounSingular={'Unified Field'}
          topValue={numUnifiedFields}
        />
        <SourceAttributesLoader />
        <AllUnifiedAttributesLoader />
      </div>
    );
  }
});

export default FieldsCard;
