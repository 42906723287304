import { List, Set } from 'immutable';

import * as DataTables from '../constants/DataTables';
import DisplayColumn from '../models/DisplayColumn';
import MinimalAuthUser from '../models/MinimalAuthUser';
import { AppThunkAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { setColumnPreferences as userApiSetColumnPreferences } from '../users/UsersAsync';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { combineColumnPrefs, getColumnSettingsForUserForPage } from '../utils/Columns';
import { createAppStateSelector } from '../utils/Selectors';
import {
  getNextEntityRule,
  selectAllRecordsTableColNames,
  selectClusterSampleTableColNames,
  selectPreviewTableColumnNames,
} from './GoldenRecordsSelectors';


const getModuleId = (state: AppState) => state.location?.moduleId;
const getAuthorizedUser = (state: AppState) => state.auth?.authorizedUser;

const getGoldenRecordsColumnSettings = ({ moduleId, user, fieldNames, entityRuleName }: {
  moduleId: number
  user: MinimalAuthUser | undefined
  fieldNames: Set<string>
  entityRuleName: string | undefined
}) => {
  return (!moduleId || !user) ? List() : getColumnSettingsForUserForPage({
    page: DataTables.GOLDEN_RECORDS,
    recipeId: moduleId,
    user,
    fieldNames,
  }).map((col) => (col.name === entityRuleName ? col.set('order', -1) : col)) // ensure entity rule is always furthest to left
    .sort((a, b) => a.order - b.order)
    .map((x, i) => x.withOrder(i));
};

export const getGoldenRecordsClusterSampleColumnSettings = ({ moduleId, user, fieldNames }: {
  moduleId: number
  user: MinimalAuthUser | undefined
  fieldNames: Set<string>
}) => {
  return (!moduleId || !user) ? List() : getColumnSettingsForUserForPage({
    page: DataTables.GOLDEN_RECORDS_CLUSTER_SAMPLE,
    recipeId: moduleId,
    user,
    fieldNames,
  })
    .sort((a, b) => a.order - b.order)
    .map((x, i) => x.withOrder(i));
};


export const selectPreviewTableColumns = createAppStateSelector(
  ArgTypes.Immutable.list.of(ArgTypes.instanceOf(DisplayColumn)),
  getModuleId,
  getAuthorizedUser,
  selectPreviewTableColumnNames,
  s => getNextEntityRule(s)?.rule.outputAttributeName,
  (moduleId, authorizedUser, previewTableColumnNames, entityRuleName) => getGoldenRecordsColumnSettings({
    moduleId,
    user: authorizedUser,
    fieldNames: previewTableColumnNames.toSet(),
    entityRuleName,
  }),
);

export const selectAllRecordsTableColumns = createAppStateSelector(
  ArgTypes.Immutable.list.of(ArgTypes.instanceOf(DisplayColumn)),
  getModuleId,
  getAuthorizedUser,
  selectAllRecordsTableColNames,
  s => s.goldenRecords.moduleFromLastUpdate?.entityRule.outputAttributeName,
  (moduleId, authorizedUser, allRecordsTableColumnNames, entityRuleName) => getGoldenRecordsColumnSettings({
    moduleId,
    user: authorizedUser,
    fieldNames: allRecordsTableColumnNames.toSet(),
    entityRuleName,
  }),
);

const onPreviewPage = (state: AppState) => state.location.page === 'grRules';

// assumes user columns DO NOT come in with 'gr__' prefix
export const setColumnPreferences = (columnPrefs: List<DisplayColumn>): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ columnPrefs }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(DisplayColumn)));
  const state = getState();
  const otherTablePrefs = onPreviewPage(state) ? selectAllRecordsTableColumns(state) : selectPreviewTableColumns(state);
  const combinedColumnPrefs = combineColumnPrefs(columnPrefs, otherTablePrefs);
  dispatch(userApiSetColumnPreferences(DataTables.GOLDEN_RECORDS, combinedColumnPrefs));
};

// assumes user columns DO NOT come in with 'gr__' prefix
export const setColumnWidth = (newWidth: number, col: string): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ newWidth }, ArgTypes.number);
  checkArg({ col }, ArgTypes.string);
  const state = getState();
  const columnSettings = onPreviewPage(state) ? selectPreviewTableColumns(state) : selectAllRecordsTableColumns(state);
  dispatch(setColumnPreferences(columnSettings.map(c => (c.name === col ? c.set('width', newWidth) : c))));
};

export const selectClusterSampleTableColumns = createAppStateSelector(
  ArgTypes.Immutable.list.of(ArgTypes.instanceOf(DisplayColumn)),
  getModuleId,
  getAuthorizedUser,
  selectClusterSampleTableColNames,
  (moduleId, authorizedUser, allRecordsTableColumnNames) => getGoldenRecordsClusterSampleColumnSettings({
    moduleId,
    user: authorizedUser,
    fieldNames: allRecordsTableColumnNames.toSet(),
  }),
);

export const setClusterSampleColumnPreferences = (columnPrefs: List<DisplayColumn>): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ columnPrefs }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(DisplayColumn)));
  const state = getState();
  const otherTablePrefs = selectClusterSampleTableColumns(state);
  const combinedColumnPrefs = combineColumnPrefs(columnPrefs, otherTablePrefs);
  dispatch(userApiSetColumnPreferences(DataTables.GOLDEN_RECORDS_CLUSTER_SAMPLE, combinedColumnPrefs));
};

export const setClusterSampleColumnWidth = (newWidth: number, col: string): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ newWidth }, ArgTypes.number);
  checkArg({ col }, ArgTypes.string);
  const state = getState();
  const columnSettings = selectClusterSampleTableColumns(state);
  dispatch(setClusterSampleColumnPreferences(columnSettings.map(c => (c.name === col ? c.set('width', newWidth) : c))));
};
