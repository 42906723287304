import './StatusDialog.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import { getPath } from '../utils/Values';

export const StatusDialogComponent = ({ message, show, onHide }) => {
  return (
    <Dialog
      className="error-log-dialog"
      show={show}
      onHide={onHide}
      title="Job status"
      body={
        <textarea className="status-box" value={message} readOnly />
      }
      footer={
        <Button buttonType="Secondary" onClick={onHide}>Close</Button>
      }
      dialogStyle={DialogStyle.FULL}
    />
  );
};

StatusDialogComponent.propTypes = {
  message: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
const StatusDialog = _.compose(
  connect(({ jobs: { jobs, statusJobId, coreconnectAndSparkJobs }, coreConnectService: { coreconnectEnabled } }) => {
    if (coreconnectEnabled && typeof statusJobId === 'string' && coreconnectAndSparkJobs.find(x => x?.id === statusJobId)) {
      const job = coreconnectAndSparkJobs.find(x => x?.id === statusJobId);
      return {
        show: !!statusJobId,
        message: (job.id.startsWith('cc') ? JSON.stringify(job, undefined, 2) : job.status),
      };
    }
    return {
      show: !!statusJobId,
      message: statusJobId && getPath(jobs.find(({ id: { id } }) => id === statusJobId), 'data', 'status', 'message'),
    };
  }, {
    onHide: () => ({ type: 'Jobs.hideStatusDialog' }),
  }),
)(StatusDialogComponent);

export default StatusDialog;
