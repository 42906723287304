import createLoader from '../utils/createLoader';
import { getUnifiedDatasetName } from '../utils/Selectors';
import { fetchUserDefinedSignals } from './RecordPairsAsync';


const UserDefinedSignalsLoader = createLoader((state) => {
  const { recordPairs: { loadingUserDefinedSignals, loadedUserDefinedSignals } } = state;
  return {
    canFetch: !!getUnifiedDatasetName(state),
    shouldFetch: !loadedUserDefinedSignals,
    loading: loadingUserDefinedSignals,
  };
}, {
  onFetch: fetchUserDefinedSignals,
}, 'UserDefinedSignalsLoader');

export default UserDefinedSignalsLoader;
