import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import ConditionalButton from '../components/ConditionalButton';
import Term from '../components/Term';
import { ORIGIN_ENTITY_ID, ORIGIN_SOURCE_NAME, TAMR_ID } from '../constants/ElasticConstants';
import { SUPPLIER_MASTERING } from '../constants/ProjectTypes';
import PRODUCT_NAME from '../utils/ProductName';
import { isJobRunningForActiveUnifiedDataset, selectActiveProjectInfo } from '../utils/Selectors';
import RequiredAttributeType from './constants/RequiredAttributeType';
import { isReserved } from './constants/ReservedFields';
import { SHOW_UPDATE_UNIFIED_DATASET_DIALOG } from './SchemaMappingActionTypes';

const SchemaMappingCommitButton = _.compose(
  connect(state => {
    const { schemaMapping: { allUnifiedAttributes: unifiedAttributes } } = state;
    const projectInfo = selectActiveProjectInfo(state) || {};
    return {
      // If there are no source datasets, skip some validation, as the user may be trying to clear
      // the unified dataset.
      hasUnifiedDataset: projectInfo.unifiedDataset !== undefined,
      noSourceDatasets: projectInfo.sourceDatasetIds.isEmpty(),
      noMappedAttributes: !unifiedAttributes.some(({ mappedAttributes }) => mappedAttributes.size),
      unifiedAttributesUsingReservedNames: unifiedAttributes.map(attribute => attribute.name)
        .filterNot(name => [ORIGIN_SOURCE_NAME, ORIGIN_ENTITY_ID, TAMR_ID].includes(name))
        .filter(name => isReserved(name)),
      noNameAttribute: !unifiedAttributes.some(({ requiredAttributeType }) => requiredAttributeType === RequiredAttributeType.SUPPLIER),
      enforceNeedsName: projectInfo.projectType === SUPPLIER_MASTERING,
      commitInProgress: isJobRunningForActiveUnifiedDataset(state),
    };
  }, {
    onShowUpdateUnifiedAttributeDialog: () => ({ type: SHOW_UPDATE_UNIFIED_DATASET_DIALOG }),
  }),
)(({ className, hasUnifiedDataset, noMappedAttributes, noNameAttribute, noSourceDatasets, commitInProgress, enforceNeedsName, onShowUpdateUnifiedAttributeDialog, unifiedAttributesUsingReservedNames }) => {
  const noNameMessage = <span>Please mark a unified attribute to represent your <Term>supplier</Term> name by configuring it in the list below</span>;
  const showUpdateWarningPreconditions = new Map({
    'You have not created a unified dataset.': hasUnifiedDataset,
    'You have no mapped unified attributes.': noSourceDatasets || !noMappedAttributes,
    'There is already a job running for this unified dataset.': !commitInProgress,
  }).set(noNameMessage, noSourceDatasets || !(enforceNeedsName && noNameAttribute))
    .set(`Some attributes (${unifiedAttributesUsingReservedNames.toJSON()}) are using ${PRODUCT_NAME} reserved names.`, unifiedAttributesUsingReservedNames.isEmpty());
  return (
    <ConditionalButton
      className={className}
      onClick={onShowUpdateUnifiedAttributeDialog}
      preconditions={showUpdateWarningPreconditions}
      tooltipLabel={showUpdateWarningPreconditions.some((value) => !value) ? undefined : 'Apply any mapping or schema changes, as well as saved transformations, to the Unified Dataset.'}
    >
      Update Unified Dataset
    </ConditionalButton>
  );
});

export default SchemaMappingCommitButton;
