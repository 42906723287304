import { Set } from 'immutable';

import { ArgTypes } from '../utils/ArgValidation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

export class RedirectButtonSpec extends getModelHelpers({
  buttonType: { type: ArgTypes.string },
  buttonId: { type: ArgTypes.string },
  buttonText: { type: ArgTypes.string },
  pageNames: { type: ArgTypes.Immutable.set.of(ArgTypes.string) },
  redirectUrl: { type: ArgTypes.string },
  openInNewTab: { type: ArgTypes.bool },
}, 'RedirectButtonSpec')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class RedirectButtonSpecRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: any) {
    return new RedirectButtonSpec({
      buttonType: obj.buttonType,
      buttonId: obj.buttonId,
      buttonText: obj.buttonText,
      pageNames: Set(obj.pageNames),
      redirectUrl: obj.redirectUrl,
      openInNewTab: obj.openInNewTab,
    });
  }
  static get buttonTypeConstant() { return 'redirectButton'; }
}

export default RedirectButtonSpec;
