import { is } from 'immutable';

import createLoader from '../utils/createLoader';
import { getActiveDatasetMetadataFilterInfo, getSingleSelectedDataset } from './DatasetMetadataStore';
import { readMetadataForSelectedDataset } from './MetadataAsync';

const DatasetMetadataLoader = createLoader((state) => {
  const selectedDataset = getSingleSelectedDataset(state);
  const { datasetMetadata: { loading, loadedDatasetMetadataFilterInfo, propertiesSidebarOpen } } = state;

  return {
    canFetch: !!selectedDataset && propertiesSidebarOpen, // True if selectedDataset exists and metadata sidebar is open, false otherwise
    shouldFetch: !is(getActiveDatasetMetadataFilterInfo(state), loadedDatasetMetadataFilterInfo),
    loading,
  };
}, {
  onFetch: readMetadataForSelectedDataset,
}, 'DatasetMetadataLoader');

export default DatasetMetadataLoader;
