import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';


class DatasetMetadataSpec extends getModelHelpers({
  attribute: { type: ArgTypes.nullable(ArgTypes.string) },
  key: { type: ArgTypes.string },
  value: { type: ArgTypes.string },
}, 'DatasetMetadataSpec')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class DatasetMetadataSpecRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: $TSFixMe) {
    const { attribute, key, value } = obj;

    return new DatasetMetadataSpec({
      attribute,
      key,
      value,
    });
  }

  asDelete() {
    // Not adding delete to the DatasetMetadata model since it is a reserved keyword.
    return ({
      attribute: this.attribute,
      key: this.key,
      value: this.value,
      delete: true,
    });
  }
}

export default DatasetMetadataSpec;
