import { ArgTypes } from '../utils/ArgValidation';
import getDocUrl from '../utils/getDocUrl';
import Model from './Model';

class TamrVersion extends Model({
  version: { type: ArgTypes.string },
  gitDescribe: { type: ArgTypes.string },
  gitCommitId: { type: ArgTypes.string },
  gitCommitShort: { type: ArgTypes.string },
  gitCommitTime: { type: ArgTypes.string },
  buildTime: { type: ArgTypes.string },
}) {
  get docUrlBase() {
    return getDocUrl(this.version);
  }
}

export default TamrVersion;
