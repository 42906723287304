import Immutable from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

// @ts-expect-error importingImagesInTypescript
import multipleRecords from '../../images/multiple-records.png';
import ActivitySidebarTab from '../components/ActivitySidebarTab';
import CenterContent from '../components/CenterContent';
import DetailSidebar from '../components/DetailSidebar/DetailSidebar';
import DetailSidebarTabbedContent from '../components/DetailSidebar/DetailSidebarTabbedContent';
import Term from '../components/Term';
import SidebarTabs from '../constants/SidebarTabs';
import { AppState } from '../stores/MainStore';
import ExpertFeedback from './ExpertFeedback';
import RecordFieldsSidebarSection from './RecordFieldsSidebarSection';
import CategorizationSection from './sidebar/CategorizationSection';
import TransactionActionButtons from './sidebar/TransactionActionButtons';
import { OPEN_GEOSPATIAL_DETAILS_SIDEBAR } from './TransactionsActionTypes';
import { comment, editComment, removeComment } from './TransactionsAsync';
// @ts-expect-error
import TransactionsCommentList from './TransactionsCommentList';
// @ts-expect-error
import TransactionSidebarDetailsGeospatial from './TransactionSidebarDetailsGeospatial';
import { getActiveRecord } from './TransactionStore';


type SpendTransactionSidebarOwnProps = {
  recipeId: number
  isUserACurator: boolean
  isUserAVerifier: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SpendTransactionSidebar = connect((state: AppState, ownProps: SpendTransactionSidebarOwnProps) => {
  const { config: { categorizationStrengthThresholds }, transactions, taxonomy: { taxonomy } } = state;
  const { activeGeospatialAttribute, expanded, sidebarTabKey, selectedRecordIds, commentFocusSequence } = transactions;
  return {
    activeGeospatialAttribute,
    strengthThresholds: categorizationStrengthThresholds,
    isExpanded: expanded,
    sidebarKey: sidebarTabKey,
    record: getActiveRecord(transactions),
    numSelectedRecords: selectedRecordIds.size,
    commentFocusSequence,
    taxonomy,
  };
}, {
  onShowSidebarTab: (sidebarTabKey: string) => ({ type: 'Transactions.showSidebarTab', sidebarTabKey }),
  onComment: comment,
  onEditComment: editComment,
  onDeleteComment: removeComment,
})(({ isExpanded, onComment, onEditComment, onDeleteComment, commentFocusSequence, recipeId, strengthThresholds, taxonomy, isUserACurator, isUserAVerifier, activeGeospatialAttribute, numSelectedRecords, record, onShowSidebarTab, sidebarKey }) => {
  let content;
  if (numSelectedRecords > 1) {
    const scale = 0.5;
    content = (
      <div>
        <CenterContent
          vertically={false}
          className="multi-record"
        >
          <img
            className="multi-record-image"
            src={multipleRecords}
            style={{
              width: 244 * scale,
              height: 244 * scale,
            }}
          />
        </CenterContent>
        <div className="multi-select-title">You've Selected {numSelectedRecords} <Term>Records</Term></div>
      </div>
    );
  } else if (record) {
    content = (
      <DetailSidebarTabbedContent
        activeKey={sidebarKey}
        onSelect={onShowSidebarTab}
        tabs={
          Immutable.List([
            {
              eventKey: SidebarTabs.DETAILS,
              title: 'Details',
              content: activeGeospatialAttribute
                ? <TransactionSidebarDetailsGeospatial />
                : (
                  <div key={`sidebar-${record.datasetId}-${record.recordId}`} className="transaction-sidebar-details-tab">
                    {strengthThresholds && <CategorizationSection {...{ recipeId, record, strengthThresholds, taxonomy, isUserACurator, isUserAVerifier }} />}
                    <ExpertFeedback {...{ isUserACurator, isUserAVerifier, recipeId, record, taxonomy }} />
                    <TransactionActionButtons {...{ record }} />
                    <RecordFieldsSidebarSection {...{
                      recipeId,
                      record,
                      onClickGeoTamrAttribute: attributeName => ({
                        type: OPEN_GEOSPATIAL_DETAILS_SIDEBAR,
                        attributeName,
                      }),
                    }} />
                  </div>
                ),
            },
            {
              eventKey: SidebarTabs.ACTIVITY,
              title: 'Comments',
              content: (
                <ActivitySidebarTab
                  commentsList={<TransactionsCommentList object={record} onEditComment={onEditComment} onDeleteComment={onDeleteComment} />}
                  onAddComment={onComment}
                  focusSequence={commentFocusSequence}
                />
              ),
            },
          ])
        }
      />
    );
  } else {
    content = <CenterContent className="no-content"><div>No <Term>record</Term> selected</div></CenterContent>;
  }
  return (
    <DetailSidebar
      className="transaction-sidebar"
      isExpanded={isExpanded}
    >
      {content}
    </DetailSidebar>
  );
});

export default SpendTransactionSidebar;
