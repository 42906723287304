import { List, Set } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';

import ColumnOrderSelector from '../components/ColumnOrderSelector';
import ColumnWidthProvider from '../components/ColumnWidthProvider';
import SortableHeaderCell from '../components/SortableHeaderCell';
import Column from '../components/Table/Column';
import Table from '../components/Table/Table';
import * as DataTables from '../constants/DataTables';
import SortState from '../constants/SortState';
import { AppAction } from '../stores/AppAction';
import { AppState } from '../stores/MainStore';
import { CHANGE_SORT, SELECT_ROWS, SET_PAGE, SET_PAGE_SIZE, UPDATE_DESIRED_SAMPLE_CLUSTER } from './GoldenRecordsActionTypes';
import GoldenRecordsBookmarkCell from './GoldenRecordsBookmarkCell';
import { selectAllRecordsTableColumns, setColumnPreferences, setColumnWidth } from './GoldenRecordsColumns';
import { getCurrentGoldenRecordUI, selectTableColNameSuggestedSet } from './GoldenRecordsSelectors';
import { columnKeyToAttributeName } from './GoldenRecordsStore';
import style from './GoldenRecordsTable.module.scss';
import { AllRecordsTableRuleCell, RuleColumnHeader, ruleColumns, ShowOverridePopoverFnType } from './GoldenRecordTableComponents';
import NoGoldenRecords from './NoGoldenRecords';


const PAGE_SIZES = List.of(50, 100, 500, 1000);

type GoldenRecordsTableOwnProps = {
  showOverridePopover: ShowOverridePopoverFnType
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GoldenRecordsTable = connect((store: AppState, { showOverridePopover }: GoldenRecordsTableOwnProps) => {
  const { draftPage, pageSize, pageNum, moduleFromLastUpdate, columnSortStates, selectedRowIndices }
    = getCurrentGoldenRecordUI(store);
  const columnSettings = selectAllRecordsTableColumns(store);
  const suggested = selectTableColNameSuggestedSet(store);
  return {
    draftPage,
    selectedRowIndices,
    columnSettings,
    pageSize,
    pageNum,
    suggested,
    columnSortStates,
    moduleFromLastUpdate,
  };
}, {
  onSetColumnPreferences: setColumnPreferences,
  onSetColumnWidth: setColumnWidth,
  onSetPage: (pageNum: number): AppAction => ({ type: SET_PAGE, pageNum }),
  onSetPageSize: (pageSize: number): AppAction => ({ type: SET_PAGE_SIZE, pageSize }),
  onToggleSort: (field: string): AppAction => ({ type: CHANGE_SORT, field }),
  onSelectRows: (selectedRowIndices: Set<number>): AppAction => ({ type: SELECT_ROWS, selectedRowIndices }),
  onFetchClusterSample: (rowNumber: number): AppAction => ({ type: UPDATE_DESIRED_SAMPLE_CLUSTER, rowNumber }),
})((({ draftPage, onFetchClusterSample, onSetColumnWidth, onSelectRows, selectedRowIndices, showOverridePopover,
  columnSettings, onSetPageSize, onSetPage, onSetColumnPreferences, pageSize, pageNum, suggested, onToggleSort,
  columnSortStates, moduleFromLastUpdate }) => {
  const clusterIdColumnName = moduleFromLastUpdate?.clusterDataset?.clusterColumn;
  const rowCount = draftPage?.items?.size;
  const total = draftPage?.total || 0;
  if (!columnSettings || rowCount === undefined || !clusterIdColumnName) return null;
  return (
    <React.Fragment>
      {rowCount === 0 && <NoGoldenRecords />}
      <AutoSizer>
        {({ width, height }) => (
          <ColumnWidthProvider>
            <Table
              className={style.table}
              tableType="stripes"
              getLength={() => rowCount}
              {... { height, width }}
              onColumnResizeEndCallback={(newWidth, columnKey) => onSetColumnWidth(newWidth, columnKeyToAttributeName(columnKey))}
              rowHeight={56}
              pagerHeight={48}
              onPageSizeChange={onSetPageSize}
              onPageChange={onSetPage}
              pageSizes={PAGE_SIZES}
              pagerContent={(
                <ColumnOrderSelector
                  columnSettings={columnSettings.slice(1) /* cannot configure entity rule column */}
                  onSetColumnPreferences={(page, columnPrefs) => onSetColumnPreferences(columnPrefs.insert(0, columnSettings.get(0))) /* put the entity rule column back in */}
                  pageType={DataTables.GOLDEN_RECORDS}
                  numPinnedColumns={1}
                />
              )}
              pagerState={{
                pageSize,
                pageCount: Math.ceil(total / pageSize),
                pageNum,
              }}
              enableMultipleRowSelection
              onSelectedRowsChange={onSelectRows}
              selectedRowNumbers={selectedRowIndices}
              selectionDoesNotActivate
              onActiveCellChange={(e, rowNum) => {
                onFetchClusterSample(rowNum);
              }}
            >
              <Column
                key="bookmark"
                columnKey="bookmark"
                fixed
                align="left"
                width={24}
                cell={({ rowIndex }) => <GoldenRecordsBookmarkCell {...{ rowIndex }} />}
              />
              {ruleColumns({
                columnSettings,
                clusterIdColumnName,
                getIsSuggested: (attributeName: string) => suggested.has(attributeName),
                renderHeader: ({ attributeName, displayName, isSuggested }) => {
                  return (
                    <SortableHeaderCell
                      col={attributeName}
                      display={<RuleColumnHeader {...{ attributeName, displayName, isSuggested }} />}
                      sortCallback={onToggleSort}
                      sortState={columnSortStates.get(attributeName) || SortState.UNSORTED}
                    />
                  );
                },
                renderCell: ({ columnKey, rowIndex }) => <AllRecordsTableRuleCell {...{ columnKey, rowIndex, showOverridePopover }} />,
              })}
            </Table>
          </ColumnWidthProvider>
        )}
      </AutoSizer>
    </React.Fragment>
  );
}));

export default GoldenRecordsTable;
