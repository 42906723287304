import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { FCWithPropTypes } from '../../utils/typescript-react';
import CenterContent from '../CenterContent';
import TamrIcon from '../TamrIcon';

const DialogHeader = FCWithPropTypes(
  {
    /**
     * Pass the title (or any react node) for the header into children.
     * Children are required (there should never be a blank header).
     */
    children: PropTypes.node.isRequired,
    /**
     * The closeButton prop dictates whether the dialog should include
     * an icon in its header (right side) that triggers its onHide function
     * when clicked. The close icon is only shown if the dialog's header
     * prop is true.
     */
    closeButton: PropTypes.bool,
    /**
     * Simply the click handler for the close button.  Unused if there is no close button.
     * Will get supplied (via a cloneElement) by Dialog class.
     */
    onHide: PropTypes.func,
  },
  ({ children, closeButton, onHide }) => {
    // TODO default-props-in-typescript
    closeButton = (closeButton === undefined || closeButton === null) ? true : closeButton;

    const childrenContainerClasses = classNames({
      'children-container': true,
      'close-button-present': !!closeButton,
    });
    return (
      <div className="tamr-dialog-header">
        <div className={childrenContainerClasses}>
          {children}
        </div>
        <div />
        {closeButton && (
          <div className="close-button-container">
            <CenterContent horizontally={false}>
              <TamrIcon
                className="close"
                iconName="tamr-icon-close"
                size={24}
                onClick={onHide || undefined}
              />
            </CenterContent>
          </div>
        )}
      </div>
    );
  },
);

export default DialogHeader;
