import { List, Map, Set } from 'immutable';

import AttributeId from '../models/AttributeId';
import BootstrapResponse from '../models/BootstrapResponse';
import Document from '../models/doc/Document';
import Job from '../models/Job';
import KeyMods from '../models/KeyMods';
import ProfilingInfo from '../models/ProfilingInfo';
import SourceAttribute from '../models/SourceAttribute';
import UnifiedAttribute from '../models/UnifiedAttribute';
import { $TSFixMe } from '../utils/typescript';

export const RESET_ATTRIBUTES = 'SchemaMapping.resetAttributes';
export const SET_HEADER_SCROLL_BAR_OFFSET = 'SchemaMapping.setHeaderScrollbarOffset';
export const SET_SIMILARITY_THRESHOLD = 'SchemaMapping.setSimilarityThreshold';
export const FETCH_SOURCE_ATTRIBUTES = 'SchemaMapping.fetchSourceAttributes';
export const FETCH_SOURCE_ATTRIBUTES_COMPLETED = 'SchemaMapping.fetchSourceAttributesCompleted';
export const FETCH_SOURCE_ATTRIBUTES_FAILED = 'SchemaMapping.fetchSourceAttributesFailed';
export const FETCH_ALL_UNIFIED_ATTRIBUTES = 'SchemaMapping.fetchAllUnifiedAttributes';
export const FETCH_ALL_UNIFIED_ATTRIBUTES_COMPLETED = 'SchemaMapping.fetchAllUnifiedAttributesCompleted';
export const FETCH_ALL_UNIFIED_ATTRIBUTES_FAILED = 'SchemaMapping.fetchAllUnifiedAttributesFailed';
export const FETCH_UNIFIED_ATTRIBUTES = 'SchemaMapping.fetchUnifiedAttributes';
export const FETCH_UNIFIED_ATTRIBUTES_COMPLETED = 'SchemaMapping.fetchUnifiedAttributesCompleted';
export const FETCH_UNIFIED_ATTRIBUTES_FAILED = 'SchemaMapping.fetchUnifiedAttributesFailed';
export const COMMIT_UNIFIED_ATTRIBUTES = 'SchemaMapping.commitUnifiedAttribute';
export const COMMIT_UNIFIED_ATTRIBUTES_COMPLETED = 'SchemaMapping.commitUnifiedAttributesCompleted';
export const COMMIT_UNIFIED_ATTRIBUTES_FAILED = 'SchemaMapping.commitUnifiedAttributesFailed';
export const SOURCE_TOGGLE_EXPANDED = 'SchemaMapping.sourceToggleExpanded';
export const SOURCE_TOGGLE_EXPAND_ALL = 'SchemaMapping.sourceToggleExpandAll';
export const SET_SOURCE_PAGE_NUM = 'SchemaMapping.setSourcePageNum';
export const SET_SOURCE_PAGE_SIZE = 'SchemaMapping.setSourcePageSize';
export const SOURCE_TOGGLE_FILTER_MAPPED = 'SchemaMapping.sourceToggleFilterMapped';
export const SOURCE_TOGGLE_FILTER_UNMAPPED = 'SchemaMapping.sourceToggleFilterUnmapped';
export const SOURCE_TOGGLE_FILTER_DNM = 'SchemaMapping.sourceToggleFilterDNM';
export const SOURCE_TOGGLE_FILTER_RELATED_ID = 'SchemaMapping.sourceToggleFilterRelatedId';
export const UPDATE_SOURCE_FILTER_DATASETS = 'SchemaMapping.updateSourceFilterDatasets';
export const SET_SOURCE_FILTER_DATASETS = 'SchemaMapping.setSourceFilterDatasets';
export const RESET_SOURCE_FILTER_DATASETS = 'SchemaMapping.resetSourceFilterDatasets';
export const SOURCE_SELECT_ATTRIBUTE = 'SchemaMapping.sourceSelectAttribute';
export const SOURCE_BEGIN_DRAG = 'SchemaMapping.sourceBeginDrag';
export const SOURCE_RESET_SOURCE_AND_UNIFIED_ATTRIBUTES = 'SchemaMapping.resetSourceAndUnifiedAttributes';
export const UNIFIED_TOGGLE_EXPANDED = 'SchemaMapping.unifiedToggleExpanded';
export const UNIFIED_TOGGLE_EXPAND_ALL = 'SchemaMapping.unifiedToggleExpandAll';
export const UNIFIED_TOGGLE_FILTER_MAPPED = 'SchemaMapping.unifiedToggleFilterMapped';
export const UNIFIED_TOGGLE_FILTER_UNMAPPED = 'SchemaMapping.unifiedToggleFilterUnmapped';
export const UNIFIED_TOGGLE_FILTER_RELATED_ID = 'SchemaMapping.unifiedToggleFilterRelatedId';
export const SET_UNIFIED_FILTER_DATASETS = 'SchemaMapping.setUnifiedFilterDatasets';
export const RESET_UNIFIED_FILTER_DATASETS = 'SchemaMapping.resetUnifiedFilterDatasets';
export const UNIFIED_SELECT_ATTRIBUTE = 'SchemaMapping.unifiedSelectAttribute';
export const UNIFIED_BEGIN_DRAG = 'SchemaMapping.unifiedBeginDrag';
export const UPDATE_MAPPABLE_COMPLETED = 'SchemaMapping.updateMappableCompleted';
export const UPDATE_SOURCE_DESCRIPTION_COMPLETED = 'SchemaMapping.updateSourceDescriptionCompleted';
export const BULK_ALLOW_MAPPING_COMPLETED = 'SchemaMapping.bulkAllowMappingCompleted';
export const MAP_ATTRIBUTES_COMPLETED = 'SchemaMapping.mapAttributesCompleted';
export const TOGGLE_ML_ENABLED_COMPLETED = 'SchemaMapping.toggleMlEnabledCompleted';
export const TOGGLE_GEO_ATTRIBUTE_COMPLETED = 'SchemaMapping.toggleGeoAttributeCompleted';
export const TOGGLE_SORT_TYPE_COMPLETED = 'SchemaMapping.toggleSortTypeCompleted';
export const UPDATE_SIMILARITY_FUNCTION_COMPLETED = 'SchemaMapping.updateSimilarityFunctionCompleted';
export const UPDATE_TOKENIZER_CONFIG_COMPLETED = 'SchemaMapping.updateTokenizerConfigCompleted';
export const UPDATE_NUMERIC_FIELD_RESOLUTION_COMPLETED = 'SchemaMapping.updateNumericFieldResolutionsCompleted';
export const SET_REQUIRED_ATTRIBUTE_TYPE_COMPLETED = 'SchemaMapping.setRequiredAttributeTypeCompleted';
export const UPDATE_UNIFIED_NAME_AND_DESCRIPTION_COMPLETED = 'SchemaMapping.updateUnifiedNameAndDescriptionCompleted';
export const CREATE_UNIFIED_ATTRIBUTE_COMPLETED = 'SchemaMapping.createUnifiedAttributeCompleted';
export const BOOTSTRAP_UNIFIED_ATTRIBUTES_COMPLETED = 'SchemaMapping.bootstrapUnifiedAttributesCompleted';
export const CLEAR_BOOTSTRAP_NAME_VALIDATION_ERRORS = 'SchemaMapping.clearBootstrapNameValidationErrors';
export const CONFIRM_BULK_DELETE_ATTRIBUTES = 'SchemaMapping.confirmBulkDeleteAttributes';
export const CANCEL_DELETE_UNIFIED_ATTRIBUTES = 'SchemaMapping.cancelDeleteUnifiedAttributes';
export const DELETE_UNIFIED_ATTRIBUTES_COMPLETED = 'SchemaMapping.deleteUnifiedAttributesCompleted';
export const CLEAR_FILTER_RELATED_ID = 'SchemaMapping.clearFilterRelatedId';
export const SET_SOURCE_ATTRIBUTES_WITH_TIEDS_RECS = 'SchemaMapping.setSourceAttributesWithTiedRecs';
export const SHOW_CONFIRM_BULK_RECS_DIALOG = 'SchemaMapping.showConfirmBulkRecsDialog';
export const HIDE_CONFIRM_BULK_RECS_DIALOG = 'SchemaMapping.hideConfirmBulkRecsDialog';
export const BULK_MAP_RECOMMENDATIONS_COMPLETED = 'SchemaMapping.bulkMapRecommendationsCompleted';
export const PROMPT_DO_NOT_MAP_CONFIRMATION = 'SchemaMapping.promptDoNotMapConfirmation';
export const SHOW_BULK_DO_NOT_MAP_DIALOG = 'SchemaMapping.showBulkDoNotMapDialog';
export const HIDE_BULK_DO_NOT_MAP_DIALOG = 'SchemaMapping.hideBulkDoNotMapDialog';
export const BULK_DO_NOT_MAP_COMPLETED = 'SchemaMapping.bulkDoNotMapCompleted';
export const SHOW_BULK_UNMAP_DIALOG = 'SchemaMapping.showBulkUnmapDialog';
export const HIDE_BULK_UNMAP_DIALOG = 'SchemaMapping.hideBulkUnmapDialog';
export const BULK_UNMAP_COMPLETED = 'SchemaMapping.bulkUnmapCompleted';
export const SOURCE_SEARCH = 'SchemaMapping.sourceSearch';
export const UNIFIED_SEARCH = 'SchemaMapping.unifiedSearch';
export const SET_HAS_VALIDATION_ERRORS = 'SchemaMapping.setHasValidationErrors';
export const CANCEL_UNIFIED_ATTRIBUTE_ACTION = 'SchemaMapping.cancelUnifiedAttributeAction';
export const SHOW_UPDATE_UNIFIED_DATASET_DIALOG = 'SchemaMapping.showUpdateUnifiedDatasetDialog';
export const HIDE_UPDATE_UNIFIED_DATASET_DIALOG = 'SchemaMapping.hideUpdateUnifiedDatasetDialog';
export const TOGGLE_UNIFIED_ATTRIBUTE_MENU_EXPANDED = 'SchemaMapping.toggleUnifiedAttributeMenuExpanded';
export const OPEN_SOURCE_DATASET_FILTER_DIALOG = 'SchemaMapping.openSourceDatasetFilterDialog';
export const OPEN_UNIFIED_DATASET_FILTER_DIALOG = 'SchemaMapping.openUnifiedDatasetFilterDialog';
export const CLOSE_SOURCE_DATASET_FILTER_DIALOG = 'SchemaMapping.closeSourceDatasetFilterDialog';
export const CLOSE_UNIFIED_DATASET_FILTER_DIALOG = 'SchemaMapping.closeUnifiedDatasetFilterDialog';
export const TRAIN_SUGGESTIONS = 'SchemaMapping.trainSuggestions';
export const TRAIN_SUGGESTIONS_FAILED = 'SchemaMapping.trainSuggestionsFailed';
export const TRAIN_SUGGESTIONS_COMPLETED = 'SchemaMapping.trainSuggestionsCompleted';
export const PREDICT_SUGGESTIONS = 'SchemaMapping.predictSuggestions';
export const PREDICT_SUGGESTIONS_COMPLETED = 'SchemaMapping.predictSuggestionsCompleted';
export const PREDICT_SUGGESTIONS_FAILED = 'SchemaMapping.predictSuggestionsFailed';
export const CHECK_SMR_MODEL_EXPORTABLE = 'SchemaMapping.checkSmrModelExportable';
export const CHECK_SMR_MODEL_EXPORTABLE_COMPLETED = 'SchemaMapping.checkSmrModelExportableCompleted';
export const CHECK_SMR_MODEL_EXPORTABLE_FAILED = 'SchemaMapping.checkSmrModelExportableFailed';

interface ResetAttributes {
  type: typeof RESET_ATTRIBUTES
}

interface SetHeaderScrollBarOffset {
  type: typeof SET_HEADER_SCROLL_BAR_OFFSET
  offset: number
}

interface SetSimilarityThreshold {
  type: typeof SET_SIMILARITY_THRESHOLD
  similarityThreshold: number
}

interface FetchSourceAttributes {
  type: typeof FETCH_SOURCE_ATTRIBUTES
}

interface FetchSourceAttributesCompleted {
  type: typeof FETCH_SOURCE_ATTRIBUTES_COMPLETED
  filterInfo: $TSFixMe
  data: $TSFixMe
  unifiedDatasetName: string | undefined
  profilingInfo: Map<string, ProfilingInfo>
}

interface FetchSourceAttributesFailed {
  type: typeof FETCH_SOURCE_ATTRIBUTES_FAILED
  filterInfo: $TSFixMe
}

interface FetchAllUnifiedAttributes {
  type: typeof FETCH_ALL_UNIFIED_ATTRIBUTES
}

interface FetchAllUnifiedAttributesCompleted {
  type: typeof FETCH_ALL_UNIFIED_ATTRIBUTES_COMPLETED
  filterInfo: $TSFixMe
  data: List<UnifiedAttribute>
}

interface FetchAllUnifiedAttributesFailed {
  type: typeof FETCH_ALL_UNIFIED_ATTRIBUTES_FAILED
  filterInfo: $TSFixMe
}

interface FetchUnifiedAttributes {
  type: typeof FETCH_UNIFIED_ATTRIBUTES
}

interface FetchUnifiedAttributesCompleted {
  type: typeof FETCH_UNIFIED_ATTRIBUTES_COMPLETED
  filterInfo: $TSFixMe
  data: List<UnifiedAttribute>
}

interface FetchUnifiedAttributesFailed {
  type: typeof FETCH_UNIFIED_ATTRIBUTES_FAILED
  filterInfo: $TSFixMe
}

interface SourceToggleExpanded {
  type: typeof SOURCE_TOGGLE_EXPANDED
  id: AttributeId
}

interface SourceToggleExpandAll {
  type: typeof SOURCE_TOGGLE_EXPAND_ALL
}

interface SetSourcePageNum {
  type: typeof SET_SOURCE_PAGE_NUM
  page: number
}

interface SetSourcePageSize {
  type: typeof SET_SOURCE_PAGE_SIZE
  pageSize: number
}

interface SourceToggleFilterMapped {
  type: typeof SOURCE_TOGGLE_FILTER_MAPPED
}

interface SourceToggleFilterUnmapped {
  type: typeof SOURCE_TOGGLE_FILTER_UNMAPPED
}

interface SourceToggleFilterDNM {
  type: typeof SOURCE_TOGGLE_FILTER_DNM
}

interface SourceToggleFilterRelatedId {
  type: typeof SOURCE_TOGGLE_FILTER_RELATED_ID
  id: AttributeId
}

interface UpdateSourceFilterDatasets {
  type: typeof UPDATE_SOURCE_FILTER_DATASETS
  datasetsToAdd: Set<$TSFixMe>
  datasetsToRemove: Set<$TSFixMe>
}

interface SetSourceFilterDatasets {
  type: typeof SET_SOURCE_FILTER_DATASETS
  datasetNames: Set<string>
}

interface ResetSourceFilterDatasets {
  type: typeof RESET_SOURCE_FILTER_DATASETS
}

interface SourceSelectAttribute {
  type: typeof SOURCE_SELECT_ATTRIBUTE
  id: AttributeId
  keyMods: KeyMods
}

interface SourceBeginDrag {
  type: typeof SOURCE_BEGIN_DRAG
  id: AttributeId
}

interface ResetSourceAndUnifiedAttributes {
  type: typeof SOURCE_RESET_SOURCE_AND_UNIFIED_ATTRIBUTES
}

interface UnifiedToggleExpanded {
  type: typeof UNIFIED_TOGGLE_EXPANDED
  id: AttributeId
}

interface UnifiedToggleExpandAll {
  type: typeof UNIFIED_TOGGLE_EXPAND_ALL
}

interface UnifiedToggleFilterMapped {
  type: typeof UNIFIED_TOGGLE_FILTER_MAPPED
}

interface UnifiedToggleFilterUnmapped {
  type: typeof UNIFIED_TOGGLE_FILTER_UNMAPPED
}

interface UnifiedToggleFilterRelatedId {
  type: typeof UNIFIED_TOGGLE_FILTER_RELATED_ID
  id: AttributeId
}

interface SetUnifiedFilterDatasets {
  type: typeof SET_UNIFIED_FILTER_DATASETS
  datasetsToAdd: Set<$TSFixMe>
  datasetsToRemove: Set<$TSFixMe>
}

interface ResetUnifiedFilterDatasets {
  type: typeof RESET_UNIFIED_FILTER_DATASETS
}

interface UnifiedSelectAttribute {
  type: typeof UNIFIED_SELECT_ATTRIBUTE
  id: AttributeId
  keyMods: KeyMods
}

interface UnifiedBeginDrag {
  type: typeof UNIFIED_BEGIN_DRAG
  id: AttributeId
}

interface UpdateMappableCompleted {
  type: typeof UPDATE_MAPPABLE_COMPLETED
}

interface UpdateSourceDescriptionCompleted {
  type: typeof UPDATE_SOURCE_DESCRIPTION_COMPLETED
}

interface BulkAllowMappingCompleted {
  type: typeof BULK_ALLOW_MAPPING_COMPLETED
}

interface MapAttributesCompleted {
  type: typeof MAP_ATTRIBUTES_COMPLETED
}

interface ToggleMlEnabledCompleted {
  type: typeof TOGGLE_ML_ENABLED_COMPLETED
}

interface ToggleGeoAttributeCompleted {
  type: typeof TOGGLE_GEO_ATTRIBUTE_COMPLETED
}

interface ToggleSortTypeCompleted {
  type: typeof TOGGLE_SORT_TYPE_COMPLETED
}

interface UpdateSimilarityFunctionCompleted {
  type: typeof UPDATE_SIMILARITY_FUNCTION_COMPLETED
}

interface UpdateTokenizerConfigCompleted {
  type: typeof UPDATE_TOKENIZER_CONFIG_COMPLETED
}

interface UpdateNumericFieldResolutionsCompleted {
  type: typeof UPDATE_NUMERIC_FIELD_RESOLUTION_COMPLETED
}

interface SetRequiredAttributeTypeCompleted {
  type: typeof SET_REQUIRED_ATTRIBUTE_TYPE_COMPLETED
}

interface UpdateUnifiedDescriptionAndNameCompleted {
  type: typeof UPDATE_UNIFIED_NAME_AND_DESCRIPTION_COMPLETED
}

interface CreateUnifiedAttributeCompleted {
  type: typeof CREATE_UNIFIED_ATTRIBUTE_COMPLETED
  attribute: UnifiedAttribute
  page: string
}

interface BootstrapUnifiedAttributesCompleted {
  type: typeof BOOTSTRAP_UNIFIED_ATTRIBUTES_COMPLETED
  response: BootstrapResponse
}

interface ClearBootstrapNameValidationErrors {
  type: typeof CLEAR_BOOTSTRAP_NAME_VALIDATION_ERRORS
}

interface ConfirmBulkDeleteAttributes {
  type: typeof CONFIRM_BULK_DELETE_ATTRIBUTES
}

interface CancelDeleteUnifiedAttributes {
  type: typeof CANCEL_DELETE_UNIFIED_ATTRIBUTES
}

interface DeleteUnifiedAttributesCompleted {
  type: typeof DELETE_UNIFIED_ATTRIBUTES_COMPLETED
}

interface ClearFilterRelatedId {
  type: typeof CLEAR_FILTER_RELATED_ID
}

interface SetSourceAttributesWithTiedRecs {
  type: typeof SET_SOURCE_ATTRIBUTES_WITH_TIEDS_RECS
  sourceAttributes: List<SourceAttribute>
}

interface ShowConfirmBulkRecsDialog {
  type: typeof SHOW_CONFIRM_BULK_RECS_DIALOG
  mapToTop: boolean
}

interface HideConfirmBulkRecsDialog {
  type: typeof HIDE_CONFIRM_BULK_RECS_DIALOG
}

interface BulkMapRecommendationsCompleted {
  type: typeof BULK_MAP_RECOMMENDATIONS_COMPLETED
}

interface ShowBulkDoNotMapDialog {
  type: typeof SHOW_BULK_DO_NOT_MAP_DIALOG
}

interface PromptDoNotMapConfirmation {
  type: typeof PROMPT_DO_NOT_MAP_CONFIRMATION
  id: AttributeId
}

interface HideBulkDoNotMapDialog {
  type: typeof HIDE_BULK_DO_NOT_MAP_DIALOG
}

interface BulkDoNotMapCompleted {
  type: typeof BULK_DO_NOT_MAP_COMPLETED
}

interface ShowBulkUnmapDialog {
  type: typeof SHOW_BULK_UNMAP_DIALOG
}

interface HideBulkUnmapDialog {
  type: typeof HIDE_BULK_UNMAP_DIALOG
}

interface BulkUnmapCompleted {
  type: typeof BULK_UNMAP_COMPLETED
}

interface SourceSearch {
  type: typeof SOURCE_SEARCH
  text: string
}

interface UnifiedSearch {
  type: typeof UNIFIED_SEARCH
  text: string
}

interface SetHasValidationErrors {
  type: typeof SET_HAS_VALIDATION_ERRORS
  value: boolean
}

interface CancelUnifiedAttributeAction {
  type: typeof CANCEL_UNIFIED_ATTRIBUTE_ACTION
}

interface ShowUpdateUnifiedDatasetDialog {
  type: typeof SHOW_UPDATE_UNIFIED_DATASET_DIALOG
}

interface HideUpdateUnifiedDatasetDialog {
  type: typeof HIDE_UPDATE_UNIFIED_DATASET_DIALOG
}

interface ToggleUnifiedAttributeMenuExpanded {
  type: typeof TOGGLE_UNIFIED_ATTRIBUTE_MENU_EXPANDED
}

interface OpenSourceDatasetFilterDialog {
  type: typeof OPEN_SOURCE_DATASET_FILTER_DIALOG
}

interface OpenUnifiedDatasetFilterDialog {
  type: typeof OPEN_UNIFIED_DATASET_FILTER_DIALOG
}

interface CloseSourceDatasetFilterDialog {
  type: typeof CLOSE_SOURCE_DATASET_FILTER_DIALOG
}

interface CloseUnifiedDatasetFilterDialog {
  type: typeof CLOSE_UNIFIED_DATASET_FILTER_DIALOG
}

interface CommitUnifiedAttributes {
  type: typeof COMMIT_UNIFIED_ATTRIBUTES
  recipeId: number
}

interface CommitUnifiedAttributesCompleted {
  type: typeof COMMIT_UNIFIED_ATTRIBUTES_COMPLETED
  recipeId: number
  job: Document<Job>
}

interface CommitUnifiedAttributesFailed {
  type: typeof COMMIT_UNIFIED_ATTRIBUTES_FAILED
  recipeId: number
}

interface TrainSuggestions {
  type: typeof TRAIN_SUGGESTIONS
  recipeId: number
}

interface TrainSuggestionsFailed {
  type: typeof TRAIN_SUGGESTIONS_FAILED
  recipeId: number
}

interface TrainSuggestionsCompleted {
  type: typeof TRAIN_SUGGESTIONS_COMPLETED
  recipeId: number
  job: Document<Job>
}

interface PredictSuggestions {
  type: typeof PREDICT_SUGGESTIONS
  recipeId: number
}

interface PredictSuggestionsCompleted {
  type: typeof PREDICT_SUGGESTIONS_COMPLETED
  recipeId: number
  job: Document<Job>
}

interface PredictSuggestionsFailed {
  type: typeof PREDICT_SUGGESTIONS_FAILED
  recipeId: number
}

interface CheckSmrModelExportable {
  type: typeof CHECK_SMR_MODEL_EXPORTABLE
}

interface CheckSmrModelExportableCompleted {
  type: typeof CHECK_SMR_MODEL_EXPORTABLE_COMPLETED
}

interface CheckSmrModelExportableFailed {
  type: typeof CHECK_SMR_MODEL_EXPORTABLE_FAILED
}

export type SchemaMappingActionTypes
  = ResetAttributes
  | SetHeaderScrollBarOffset
  | SetSimilarityThreshold
  | FetchSourceAttributes
  | FetchSourceAttributesCompleted
  | FetchSourceAttributesFailed
  | FetchAllUnifiedAttributes
  | FetchAllUnifiedAttributesCompleted
  | FetchAllUnifiedAttributesFailed
  | FetchUnifiedAttributes
  | FetchUnifiedAttributesCompleted
  | FetchUnifiedAttributesFailed
  | SourceToggleExpanded
  | SourceToggleExpandAll
  | SetSourcePageNum
  | SetSourcePageSize
  | SourceToggleFilterMapped
  | SourceToggleFilterUnmapped
  | SourceToggleFilterDNM
  | SourceToggleFilterRelatedId
  | UpdateSourceFilterDatasets
  | SetSourceFilterDatasets
  | ResetSourceFilterDatasets
  | SourceSelectAttribute
  | SourceBeginDrag
  | ResetSourceAndUnifiedAttributes
  | UnifiedToggleExpanded
  | UnifiedToggleExpandAll
  | UnifiedToggleFilterMapped
  | UnifiedToggleFilterUnmapped
  | UnifiedToggleFilterRelatedId
  | SetUnifiedFilterDatasets
  | ResetUnifiedFilterDatasets
  | UnifiedSelectAttribute
  | UnifiedBeginDrag
  | UpdateMappableCompleted
  | UpdateSourceDescriptionCompleted
  | BulkAllowMappingCompleted
  | MapAttributesCompleted
  | ToggleMlEnabledCompleted
  | ToggleGeoAttributeCompleted
  | ToggleSortTypeCompleted
  | UpdateSimilarityFunctionCompleted
  | UpdateTokenizerConfigCompleted
  | UpdateNumericFieldResolutionsCompleted
  | SetRequiredAttributeTypeCompleted
  | UpdateUnifiedDescriptionAndNameCompleted
  | CreateUnifiedAttributeCompleted
  | BootstrapUnifiedAttributesCompleted
  | ClearBootstrapNameValidationErrors
  | ConfirmBulkDeleteAttributes
  | CancelDeleteUnifiedAttributes
  | DeleteUnifiedAttributesCompleted
  | ClearFilterRelatedId
  | SetSourceAttributesWithTiedRecs
  | ShowConfirmBulkRecsDialog
  | HideConfirmBulkRecsDialog
  | BulkMapRecommendationsCompleted
  | ShowBulkDoNotMapDialog
  | PromptDoNotMapConfirmation
  | HideBulkDoNotMapDialog
  | BulkDoNotMapCompleted
  | ShowBulkUnmapDialog
  | HideBulkUnmapDialog
  | BulkUnmapCompleted
  | SourceSearch
  | UnifiedSearch
  | SetHasValidationErrors
  | CancelUnifiedAttributeAction
  | ShowUpdateUnifiedDatasetDialog
  | HideUpdateUnifiedDatasetDialog
  | ToggleUnifiedAttributeMenuExpanded
  | OpenSourceDatasetFilterDialog
  | OpenUnifiedDatasetFilterDialog
  | CloseSourceDatasetFilterDialog
  | CloseUnifiedDatasetFilterDialog
  | CommitUnifiedAttributes
  | CommitUnifiedAttributesCompleted
  | CommitUnifiedAttributesFailed
  | TrainSuggestions
  | TrainSuggestionsFailed
  | TrainSuggestionsCompleted
  | PredictSuggestions
  | PredictSuggestionsCompleted
  | PredictSuggestionsFailed
  | CheckSmrModelExportable
  | CheckSmrModelExportableCompleted
  | CheckSmrModelExportableFailed
