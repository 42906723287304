import { List, Map, Set } from 'immutable';
import { isBoolean, isNumber, isString } from 'underscore';

import { DataTablesType } from '../constants/DataTables';
import DisplayColumn, { DisplayColumnPartialI } from '../models/DisplayColumn';
import MinimalAuthUser from '../models/MinimalAuthUser';
import { ArgTypes, checkArg } from './ArgValidation';
import { indexBy } from './Values';


export const columnListType = ArgTypes.Immutable.list.of(ArgTypes.instanceOf(DisplayColumn));

export function getColumnDefinitions(
  fields: List<string>,
  prefs: List<DisplayColumnPartialI>,
  defaults?: List<DisplayColumnPartialI>,
): List<DisplayColumn> {
  const prefsByName = indexBy(prefs, pref => pref.name);
  const defaultsByName = defaults ? indexBy(defaults, def => def.name) : Map<string, DisplayColumnPartialI>();

  return fields
    .map(name => {
      const pref = prefsByName.get(name);
      const def = defaultsByName.get(name);
      // preferences and defaults do not need to specify all settings.
      // prefer to take the preference setting, and if that doesn't exist take the default setting.
      return new DisplayColumn({
        name,
        order: isNumber(pref?.order) ? pref?.order : isNumber(def?.order) ? def?.order : undefined,
        width: isNumber(pref?.width) ? pref?.width : isNumber(def?.width) ? def?.width : undefined,
        visible: isBoolean(pref?.visible) ? pref?.visible : isBoolean(def?.visible) ? def?.visible : undefined,
        alias: isString(pref?.alias) ? pref?.alias : isString(def?.alias) ? def?.alias : undefined,
      });
    })
    .sortBy(column => column.order)
    .map((column, i) => column.withOrder(i));
}

export function getColumnSettingsForUserForPage({ page, recipeId, user, fieldNames, defaults }: {
  page: DataTablesType
  recipeId: number
  user: MinimalAuthUser
  fieldNames: Set<string>
  defaults?: List<DisplayColumnPartialI>
}): List<DisplayColumn> {
  const prefs = user.columnPreferencesForPage(page, recipeId);
  return getColumnDefinitions(fieldNames.toList().sort(), prefs ? List(prefs) : List(), defaults);
}

// TODO use in RecordsColumns.js
export function combineColumnPrefs(prefs1: List<DisplayColumn>, prefs2: List<DisplayColumn>): List<DisplayColumn> {
  checkArg({ prefs1 }, columnListType);
  checkArg({ prefs2 }, columnListType);
  const prefs1Names = prefs1.map(({ name }) => name).toSet();
  let combinedColumnPrefs = prefs1;
  prefs2
    .filter(({ name }) => !prefs1Names.has(name))
    .forEach(col => {
      combinedColumnPrefs = combinedColumnPrefs.insert(col.order, col);
    });
  combinedColumnPrefs = combinedColumnPrefs.map((col, i) => col.withOrder(i));
  return combinedColumnPrefs;
}
