export const PREVIEW = 'preview';
export const ES = 'elasticsearch';

export const PROVIDERS = [PREVIEW, ES];

export const RowProviders = {
  PREVIEW,
  ES,
} as const;
export type RowProvidersE = typeof RowProviders[keyof typeof RowProviders];
