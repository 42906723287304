import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Term from '../../components/Term';
import { history } from '../../utils/History';
import { getActiveSpendField } from '../../utils/Selectors';
import TopNCard, { Bar } from '../TopNCard';
import { ClusterTotal } from './DedupDashboardStore';

const TopClustersByCountCard = _.compose(
  connect((state) => {
    const { clusterFeedbackStore, dedupDashboard: { topClustersBySpend } } = state;
    const isSpendConfigured = !!getActiveSpendField(state);
    return { numAssignments: clusterFeedbackStore.clusterAssignments.size, isSpendConfigured, topClustersBySpend };
  }),
)(class TopClustersByCountCard extends React.Component {
  static propTypes = {
    dedupRecipeId: PropTypes.number.isRequired,
    isSpendConfigured: PropTypes.bool.isRequired,
    numAssignments: PropTypes.number.isRequired,
    topClustersBySpend: ImmutablePropTypes.listOf(PropTypes.instanceOf(ClusterTotal)).isRequired,
  };

  getBars = () => {
    return this.props.topClustersBySpend.map(clusterTotal => (
      new Bar({ value: clusterTotal.value, label: clusterTotal.name })
    ));
  };

  navigateToClusters = () => {
    const { dedupRecipeId } = this.props;
    history.push(`/suppliers/recipe/${dedupRecipeId}`);
  };

  render() {
    if (!this.props.isSpendConfigured) {
      return <div />;
    }
    return (
      <TopNCard
        bars={this.getBars()}
        onClick={this.navigateToClusters}
        metric={<Term>Spend</Term>}
        nounPlural={<span>Clusters (<Term>Suppliers</Term>)</span>}
        nounSingular={<span>Cluster (<Term>Supplier</Term>)</span>}
        superlative="Largest"
      />
    );
  }
});

export default TopClustersByCountCard;
