import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import WarningDialog from '../components/WarningDialog';
import { saveTransformsAndCommitUnifiedAttributes } from '../transforms/TransformsApi';
import { HIDE_UPDATE_UNIFIED_DATASET_DIALOG } from './SchemaMappingActionTypes';
import { commitUnifiedAttributes } from './SchemaMappingAsync';


const UpdateUnifiedDatasetDialog = _.compose(
  connect((state) => {
    const { schemaMapping: { confirmingUpdateUnifiedDataset }, transforms: { deltas } } = state;
    return { confirmingUpdateUnifiedDataset, deltas };
  }, {
    onCommitUnifiedAttributes: commitUnifiedAttributes,
    onSaveAndUpdate: saveTransformsAndCommitUnifiedAttributes,
    onHide: () => ({ type: HIDE_UPDATE_UNIFIED_DATASET_DIALOG }),
  }),
)(({ confirmingUpdateUnifiedDataset, deltas, onHide, onSaveAndUpdate, onCommitUnifiedAttributes }) => {
  // if there are unsaved transformations (deltas), return a dialogue that asks the user if they want to...
  // 'Save and Update', 'Only Update', or 'Cancel'
  if (deltas.size) {
    return (
      <Dialog
        show={confirmingUpdateUnifiedDataset}
        onHide={onHide}
        body="Would you like us to save your transformations before updating your Unified Dataset? Updating a Unified Dataset can take a while."
        dialogStyle={DialogStyle.PRIMARY}
        footer={(
          <ButtonToolbar>
            <Button onClick={onHide} buttonType="Secondary">Cancel</Button>
            <Button onClick={() => {
              onCommitUnifiedAttributes();
              onHide();
            }}
            >
              Only Update
            </Button>
            <Button onClick={() => {
              onSaveAndUpdate();
              onHide();
            }}
            >
              Save and Update
            </Button>
          </ButtonToolbar>
        )}
      />
    );
  }
  // however, if there are no unsaved changes then don't ask about saving unsaved changes
  return (
    <WarningDialog
      actionName="Update Unified Dataset"
      message="Updating a unified dataset can take a while. Are you sure you want to continue?"
      onAccept={onCommitUnifiedAttributes}
      onHide={onHide}
      show={confirmingUpdateUnifiedDataset}
    />
  );
});

export default UpdateUnifiedDatasetDialog;
