import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';

import Link from '../components/Link';
import LoadingPanel from '../components/LoadingPanel';
import Column from '../components/Table/Column';
import Table from '../components/Table/Table';
import { AppState } from '../stores/MainStore';
import {
  selectActiveProjectInfo,
  selectIsUnifiedDatasetIndexed,
} from '../utils/Selectors';
import GroupRecordsDialog from './GroupRecordsDialog';
import { RESIZE_COLUMN, SET_SAMPLE_PAGE_NUM, SET_SAMPLE_PAGE_SIZE } from './PregroupActionTypes';
import { fetchPregroupData } from './PregroupAsync';
import PregroupRecordCell from './PregroupRecordCell';
import PregroupRecordCountCell from './PregroupRecordCountCell';
import style from './PregroupRecordsTable.module.scss';
import {
  GROUP_COUNT_COLUMN_KEY,
  GROUP_SIZE_ATTRIBUTE,
  selectColumnsWithWidths,
  selectCountColumnWidth,
  selectGroupingKeys,
  selectIsInitialState,
  selectIsLoading,
  selectNumRecords,
  selectSamplePageCount,
  selectSamplePageNum,
  selectSamplePageSize,
  selectSettingMaxGroupSize,
  selectDedupUnifiedAttributes,
} from './PregroupStore';
import PregroupTableHeaderCell from './PregroupTableHeaderCell';

const PregroupRecordsTable = connect((state: AppState) => {
  const activeProjectInfo = selectActiveProjectInfo(state);
  return {
    isUnifiedDatasetCommitted: selectIsUnifiedDatasetIndexed(state),
    schemaMappingRecipeId: selectActiveProjectInfo(state)?.smRecipeId,
    numRecords: state.pregroup.sampleStatus.type === 'error' ? 0 : selectNumRecords(state),
    columns: selectColumnsWithWidths(state),
    unifiedAttributes: selectDedupUnifiedAttributes(state),
    countColumnWidth: selectCountColumnWidth(state),
    settingMaxGroupSize: selectSettingMaxGroupSize(state),
    isInitialState: selectIsInitialState(state),
    isLoading: selectIsLoading(state),
    groupingKeys: selectGroupingKeys(state),
    pageNum: selectSamplePageNum(state),
    pageSize: selectSamplePageSize(state),
    pageCount: selectSamplePageCount(state),
    // when we have recipe and unifiedDatasetColumns, and ml info
    canFetch: activeProjectInfo !== null && activeProjectInfo.unifiedDataset !== undefined && state.schemaMapping.unifiedAttributes.size > 0,
    isGroupingEnabled: state.pregroup.isGroupingEnabled,
    isFetchComplete: state.pregroup.isFetchComplete,
    sampleMetrics: state.pregroup.sampleMetrics,
  };
}, {
  onLoad: fetchPregroupData,
  onResizeColumn: (width: number, column: string) => ({ type: RESIZE_COLUMN, width, column }),
  onSetPageNum: (pageNum: number) => ({ type: SET_SAMPLE_PAGE_NUM, pageNum }),
  onSetPageSize: (pageSize: number) => ({ type: SET_SAMPLE_PAGE_SIZE, pageSize }),
})(({ onResizeColumn, onSetPageNum, onSetPageSize, columns, unifiedAttributes, countColumnWidth, numRecords, isUnifiedDatasetCommitted, schemaMappingRecipeId, isLoading, pageNum, pageSize, pageCount, isGroupingEnabled, isFetchComplete, sampleMetrics }) => {
  return (
    <div className={style.container}>
      <GroupRecordsDialog />
      {isLoading && <LoadingPanel />}
      {!isUnifiedDatasetCommitted
        ? (
          <div className={style.centeredContent}>
            <span>You have not yet committed a <Link to={`/schema-mapping/recipe/${schemaMappingRecipeId}`}>unified dataset</Link>.</span>
          </div>
        ) : isFetchComplete && !isGroupingEnabled
          ? (
            <div className={style.centeredContent}>
              {!isLoading ?
                <span>Record grouping is disabled. Enable to consolidate records with identical values prior to pair generation.</span>
                : null}
            </div>
          ) : !columns
            ? <div className={style.centeredContent}>No Data</div>
            : isFetchComplete ?
              <AutoSizer>
                {({ height, width }) => (
                  <div>
                    {sampleMetrics?.numberOfRecordsInNonTrivialGroups === 0 ?
                      <div style={{
                        position: 'absolute',
                        top: '75%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}>
                        <i>There are no record groups to display</i>
                      </div> : ''}
                    <Table
                      {...{ height, width }}
                      tableType="stripes"
                      getLength={() => (numRecords || 0)}
                      rowHeight={30}
                      headerHeight={165}
                      onColumnResizeEndCallback={onResizeColumn}
                      pageSizes={List([50, 100, 500, 1000])}
                      onPageChange={newPageNum => onSetPageNum(newPageNum)}
                      onPageSizeChange={newPageSize => onSetPageSize(newPageSize)}
                      pagerState={{
                        pageNum,
                        pageSize,
                        pageCount,
                      }}
                  >
                      <Column
                        key="count_column"
                        isResizable
                        columnKey={GROUP_COUNT_COLUMN_KEY}
                        width={countColumnWidth}
                        header={<PregroupTableHeaderCell unifiedAttribute={GROUP_SIZE_ATTRIBUTE} />}
                        cell={({ rowIndex }) => <PregroupRecordCountCell rowIndex={rowIndex} />}
                    />
                      {columns.map((c, i) => (
                        <Column
                          key={i}
                          isResizable
                          columnKey={c.column}
                          width={c.width}
                          header={<PregroupTableHeaderCell unifiedAttribute={unifiedAttributes?.find((ua) => ua.name === c.column)} />}
                          cell={({ rowIndex }) => <PregroupRecordCell column={c.column} rowIndex={rowIndex} />}
                      />
                      ))}
                    </Table>
                  </div>
                )}
              </AutoSizer>
              : null}
    </div>
  );
});

export default PregroupRecordsTable;
