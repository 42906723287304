import { is, List, Map } from 'immutable';
import $ from 'jquery';
import { isEqual } from 'lodash';
import { ThunkDispatch } from 'redux-thunk';
import _ from 'underscore';

import * as DatasetClient from '../api/DatasetClient';
import { getSourceAttributes, populate, runOperation, updateRecipe } from '../api/RecipeClient';
import * as TransformClient from '../api/TransformClient';
import { SM_SOURCE_ATTRIBUTE_LIST, SM_UNIFIED_ATTRIBUTE_LIST } from '../constants/DataTables';
import { GEOTAMR_RECORD_TYPE } from '../constants/GeoTamrRecordType';
import { RecipeOperations } from '../constants/RecipeOperations';
import RecipeType, { SCHEMA_MAPPING_RECOMMENDATIONS } from '../constants/RecipeType';
import { SortTypeE } from '../constants/SortType';
import { SHOW } from '../errorDialog/ErrorDialogActionTypes';
import AttributeId, { attributeId } from '../models/AttributeId';
import BootstrapResponse from '../models/BootstrapResponse';
import DisplayColumn from '../models/DisplayColumn';
import Document from '../models/doc/Document';
import MappingChange from '../models/MappingChange';
import Recipe from '../models/Recipe';
import SourceAttribute from '../models/SourceAttribute';
import UnifiedAttribute from '../models/UnifiedAttribute';
// @ts-expect-error will be removed when RecordsApi is TS-ified
// eslint-disable-next-line
import { fetchTransactions as recordsFetchTransactions } from '../records/RecordsApi';
import { AppThunkAction } from '../stores/AppAction';
import { AppDispatch, AppState } from '../stores/MainStore';
import { ArrayType, stringType } from '../transforms/models/Types';
import { setColumnPreferences } from '../users/UsersAsync';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import * as Result from '../utils/Result';
import {
  getAllRecipeDocs,
  getAuthorizedUser,
  getUnifiedDatasetName,
  selectActiveProjectInfo,
} from '../utils/Selectors';
import { $TSFixMe } from '../utils/typescript';
import RequiredAttributeType, { RequiredAttributeTypeE } from './constants/RequiredAttributeType';
import SimilarityFunction, {
  allowsTokenizerConfig,
  SimilarityFunctionE,
} from './constants/SimilarityFunction';
import TokenizerConfig, { TokenizerConfigE } from './constants/TokenizerConfig';
import TokenWeighting, { TokenWeightingE } from './constants/TokenWeighting';
import {
  BOOTSTRAP_UNIFIED_ATTRIBUTES_COMPLETED,
  BULK_ALLOW_MAPPING_COMPLETED,
  BULK_DO_NOT_MAP_COMPLETED,
  BULK_MAP_RECOMMENDATIONS_COMPLETED,
  BULK_UNMAP_COMPLETED,
  CHECK_SMR_MODEL_EXPORTABLE,
  CHECK_SMR_MODEL_EXPORTABLE_COMPLETED,
  CHECK_SMR_MODEL_EXPORTABLE_FAILED,
  COMMIT_UNIFIED_ATTRIBUTES,
  COMMIT_UNIFIED_ATTRIBUTES_COMPLETED,
  COMMIT_UNIFIED_ATTRIBUTES_FAILED,
  CREATE_UNIFIED_ATTRIBUTE_COMPLETED,
  DELETE_UNIFIED_ATTRIBUTES_COMPLETED,
  FETCH_ALL_UNIFIED_ATTRIBUTES,
  FETCH_ALL_UNIFIED_ATTRIBUTES_COMPLETED,
  FETCH_ALL_UNIFIED_ATTRIBUTES_FAILED,
  FETCH_SOURCE_ATTRIBUTES,
  FETCH_SOURCE_ATTRIBUTES_COMPLETED,
  FETCH_SOURCE_ATTRIBUTES_FAILED,
  FETCH_UNIFIED_ATTRIBUTES,
  FETCH_UNIFIED_ATTRIBUTES_COMPLETED,
  FETCH_UNIFIED_ATTRIBUTES_FAILED,
  MAP_ATTRIBUTES_COMPLETED,
  PREDICT_SUGGESTIONS,
  PREDICT_SUGGESTIONS_COMPLETED,
  PREDICT_SUGGESTIONS_FAILED,
  PROMPT_DO_NOT_MAP_CONFIRMATION,
  SET_REQUIRED_ATTRIBUTE_TYPE_COMPLETED,
  SET_SOURCE_ATTRIBUTES_WITH_TIEDS_RECS,
  SHOW_BULK_DO_NOT_MAP_DIALOG,
  SHOW_CONFIRM_BULK_RECS_DIALOG,
  TOGGLE_GEO_ATTRIBUTE_COMPLETED,
  TOGGLE_ML_ENABLED_COMPLETED,
  TOGGLE_SORT_TYPE_COMPLETED,
  TRAIN_SUGGESTIONS,
  TRAIN_SUGGESTIONS_COMPLETED,
  TRAIN_SUGGESTIONS_FAILED,
  UPDATE_MAPPABLE_COMPLETED,
  UPDATE_NUMERIC_FIELD_RESOLUTION_COMPLETED,
  UPDATE_SIMILARITY_FUNCTION_COMPLETED,
  UPDATE_SOURCE_DESCRIPTION_COMPLETED,
  UPDATE_TOKENIZER_CONFIG_COMPLETED,
  UPDATE_UNIFIED_NAME_AND_DESCRIPTION_COMPLETED,
} from './SchemaMappingActionTypes';
import {
  getAllUnifiedFilterInfo,
  getBulkRecommendChanges,
  getRecommendationsRecipeDoc,
  getSourceFilterInfo,
  getUnifiedFilterInfo,
  selectSourceMappings,
} from './SchemaMappingStore';

export const ATTRIBUTE_COL_NAME = 'attribute';
export const DATASET_COL_NAME = 'dataset';
export const MAPPINGS_COL_NAME = 'mappings';

type ColNameType
  = typeof ATTRIBUTE_COL_NAME
  | typeof DATASET_COL_NAME
  | typeof MAPPINGS_COL_NAME

const SA_DEFAULT_WIDTH_RATIOS = Map({
  [ATTRIBUTE_COL_NAME]: 50,
  [DATASET_COL_NAME]: 20,
  [MAPPINGS_COL_NAME]: 30,
});
const UA_DEFAULT_WIDTH_RATIOS = Map({
  [MAPPINGS_COL_NAME]: 30,
  [ATTRIBUTE_COL_NAME]: 70,
});

const NO_UNIFIED_DATASET_ERROR_MESSAGE = 'Unable to retrieve unified dataset. Maybe it has ' +
  'been deleted. Check your project to make sure the unified dataset is present.';
const NO_ACTIVE_PROJECT_ERROR_MESSAGE = 'Unable to retrieve the active project. Maybe it has ' +
  'been deleted. Check your instance to make sure the project is present.';
export const NO_SMR_RECIPE_FOR_PROJECT_ERROR_MESSAGE = (projectId: number): string => {
  return `Unable to retrieve ${SCHEMA_MAPPING_RECOMMENDATIONS} recipe for project ID ${projectId}`;
};
const NO_ATTRIBUTE_WITH_ID_ERROR_MESSAGE = (id: AttributeId): string => {
  return `Unable to find attribute with id ${id}`;
};

const getColumnSettings = (
  state: AppState,
  tableName: string,
  defaults: Map<string, number>,
): Map<string, number> => {
  const { location: { recipeId } } = state;
  const authorizedUser = getAuthorizedUser(state);
  const pagePrefs = (authorizedUser?.preferences.get('columnsPreferences') || List())
    .find((p: $TSFixMe) => p.recipeId === recipeId && p.page === tableName);
  return (pagePrefs ? pagePrefs.columnsPreferences : [])
    .reduce(
      (rn: Map<string, number>, { name, width }: { name: string, width: number }) =>
        rn.update(name, (w: number) => width || w),
      defaults,
    );
};

export const getSAColumnSettings = (state: AppState): Map<string, number> => {
  return getColumnSettings(state, SM_SOURCE_ATTRIBUTE_LIST, SA_DEFAULT_WIDTH_RATIOS);
};

export const getUAColumnSettings = (state: AppState): Map<string, number> => {
  return getColumnSettings(state, SM_UNIFIED_ATTRIBUTE_LIST, UA_DEFAULT_WIDTH_RATIOS);
};

const setColumnWidth = (
  { columnSettings, colName, prevColName, newWidthRatio, dispatch, tableName }:
    {
      columnSettings: Map<string, number>,
      colName: ColNameType,
      prevColName: ColNameType,
      newWidthRatio: number,
      tableName: typeof SM_SOURCE_ATTRIBUTE_LIST | typeof SM_UNIFIED_ATTRIBUTE_LIST,
      dispatch: ThunkDispatch<AppState, $TSFixMe, $TSFixMe>
    },
) => {
  const oldColWidth = columnSettings.get(colName);
  const newColumnSettings = columnSettings
    .set(colName, newWidthRatio)
    .update(
      prevColName,
      // TODO: We do a no-op update in case of null, but that not be correct.
      //       However, the previous behavior would just throw.
      widthRatio => (oldColWidth ? widthRatio + (oldColWidth - newWidthRatio) : widthRatio),
    );
  const columns = newColumnSettings.map((width, name) => new DisplayColumn({ name, width })).toList();
  dispatch(setColumnPreferences(tableName, columns));
};

export const setSAColumnWidth = (
  colName: ColNameType,
  newWidthRatio: number,
): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ colName }, ArgTypes.valueIn([DATASET_COL_NAME, MAPPINGS_COL_NAME])); // can't resize ATTRIBUTE_COL_NAME, as such!
  checkArg({ newWidthRatio }, ArgTypes.number.inRange(0, 100));
  const state = getState();
  const columnSettings = getSAColumnSettings(state);
  const prevColName = colName === DATASET_COL_NAME ? ATTRIBUTE_COL_NAME : DATASET_COL_NAME;
  setColumnWidth({
    newWidthRatio,
    columnSettings,
    colName,
    prevColName,
    dispatch,
    tableName: SM_SOURCE_ATTRIBUTE_LIST,
  });
};

export const setUAColumnWidth = (
  colName: ColNameType,
  newWidthRatio: number,
): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ colName }, ArgTypes.valueIn([ATTRIBUTE_COL_NAME]));
  checkArg({ newWidthRatio }, ArgTypes.number.inRange(0, 100));
  const state = getState();
  const columnSettings = getUAColumnSettings(state);
  const prevColName = MAPPINGS_COL_NAME;
  setColumnWidth({
    newWidthRatio,
    columnSettings,
    colName,
    prevColName,
    dispatch,
    tableName: SM_UNIFIED_ATTRIBUTE_LIST,
  });
};

export const fetchSourceAttributes = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const { schemaMapping: { sourceFilterDatasets } } = state;

  const projectInfo = selectActiveProjectInfo(state);
  const filterInfo = getSourceFilterInfo(state);

  const failureHandler = (detail: $TSFixMe, response: $TSFixMe) => {
    dispatch({ type: FETCH_SOURCE_ATTRIBUTES_FAILED, filterInfo });
    dispatch({ type: SHOW, detail, response });
  };

  dispatch({ type: FETCH_SOURCE_ATTRIBUTES });

  if (!projectInfo) {
    return failureHandler(NO_ACTIVE_PROJECT_ERROR_MESSAGE, null);
  }

  const { unifiedDataset } = projectInfo;
  if (!unifiedDataset) {
    return failureHandler(NO_UNIFIED_DATASET_ERROR_MESSAGE, null);
  }

  const recsRecipeId = getRecommendationsRecipeDoc(projectInfo)?.id.id;

  if (!recsRecipeId) {
    return failureHandler(NO_SMR_RECIPE_FOR_PROJECT_ERROR_MESSAGE(projectInfo.projectId), null);
  }

  getSourceAttributes(filterInfo, `recipe_${recsRecipeId}`, sourceFilterDatasets)
    .then((data) => {
      const datasetNames = List(data.items).map(({ datasetName }) => datasetName).toSet();
      /**
       * NB: We're fetching profile information after we've finished fetching
       * source attributes, and blocking page render until it's all been loaded.
       *
       * If page load time becomes a serious issue, consider loading source attributes
       * immediately and maintaining a separate loading state for profile information
       * since it's not visible by default.
       */
      TransformClient.doFetchProfiling(datasetNames).then(profilingInfo => {
        dispatch({
          type: FETCH_SOURCE_ATTRIBUTES_COMPLETED,
          filterInfo,
          data,
          unifiedDatasetName: unifiedDataset.name,
          profilingInfo,
        });
      }, _.partial(failureHandler, 'Error loading attribute profiling data'));
    }, _.partial(failureHandler, 'Error loading source attributes'));
};

export const fetchAllUnifiedAttributes = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const projectInfo = selectActiveProjectInfo(state);
  const filterInfo = getAllUnifiedFilterInfo(state);

  const failureHandler = (detail: $TSFixMe, response: $TSFixMe) => {
    dispatch({ type: FETCH_ALL_UNIFIED_ATTRIBUTES_FAILED, filterInfo });
    dispatch({ type: SHOW, detail, response });
  };

  dispatch({ type: FETCH_ALL_UNIFIED_ATTRIBUTES });

  if (!projectInfo) {
    return failureHandler(NO_ACTIVE_PROJECT_ERROR_MESSAGE, null);
  }

  if (!projectInfo.unifiedDataset) {
    return failureHandler(NO_UNIFIED_DATASET_ERROR_MESSAGE, null);
  }

  const recsRecipeId = getRecommendationsRecipeDoc(projectInfo)?.id.id;

  if (!recsRecipeId) {
    return failureHandler(NO_SMR_RECIPE_FOR_PROJECT_ERROR_MESSAGE(projectInfo.projectId), null);
  }

  return TransformClient.getAllUnifiedAttributes(projectInfo.unifiedDataset.name, recsRecipeId)
    .then((data) => {
      dispatch({ type: FETCH_ALL_UNIFIED_ATTRIBUTES_COMPLETED, filterInfo, data });
    }, (response) => failureHandler('Error loading all unified attributes', response));
};

export const fetchUnifiedAttributes = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const projectInfo = selectActiveProjectInfo(state);
  const filterInfo = getUnifiedFilterInfo(state);

  const failureHandler = (detail: $TSFixMe, response: $TSFixMe) => {
    dispatch({ type: FETCH_UNIFIED_ATTRIBUTES_FAILED, filterInfo });
    dispatch({ type: SHOW, detail, response });
  };

  dispatch({ type: FETCH_UNIFIED_ATTRIBUTES });

  if (!projectInfo) {
    return failureHandler(NO_ACTIVE_PROJECT_ERROR_MESSAGE, null);
  }

  if (!projectInfo.unifiedDataset) {
    return failureHandler(NO_UNIFIED_DATASET_ERROR_MESSAGE, null);
  }

  const recsRecipeId = getRecommendationsRecipeDoc(projectInfo)?.id.id;

  if (!recsRecipeId) {
    return failureHandler(NO_SMR_RECIPE_FOR_PROJECT_ERROR_MESSAGE(projectInfo.projectId), null);
  }

  return TransformClient.getAllUnifiedAttributes(projectInfo.unifiedDataset.name, recsRecipeId, filterInfo)
    .then((data) => {
      dispatch({
        type: FETCH_UNIFIED_ATTRIBUTES_COMPLETED,
        filterInfo,
        data,
      });
    }, (response) => failureHandler('Error loading unified attributes', response));
};

const setMappable = (
  state: AppState,
  attrs: SourceAttribute[],
  mappable: boolean,
): Promise<Response> => {
  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    return Promise.reject(Error(NO_UNIFIED_DATASET_ERROR_MESSAGE));
  }

  return DatasetClient.patchAttributes(
    attrs.map(({ name, datasetName, doNotMap }) => {
      return {
        name,
        datasetName,
        metadata: {
          schemaMapping: {
            ...Map(doNotMap.map(d => [d, false])).toObject(),
            [unifiedDatasetName]: mappable,
          },
        },
        revisionId: -1,
      };
    }),
  );
};

export const updateMappable = (
  id: AttributeId,
  mappable: boolean,
): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ id }, ArgTypes.instanceOf(AttributeId));
  checkArg({ mappable }, ArgTypes.bool);
  const state = getState();
  const { schemaMapping: { sourceAttributes } } = state;
  const attr = sourceAttributes.find(a => is(attributeId(a), id));

  if (!attr) {
    console.warn(NO_ATTRIBUTE_WITH_ID_ERROR_MESSAGE(id));
    return;
  }

  setMappable(state, [attr], mappable).then(() => {
    dispatch({ type: UPDATE_MAPPABLE_COMPLETED });
  }, response => {
    dispatch({ type: SHOW, detail: 'Error updating mappable state', response });
  });
};

// TODO consolidate this logic -- update SchemaMappingBulkMap to use this
export const safeUpdateMappable = (
  id: AttributeId,
  mappable: boolean,
): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ id }, ArgTypes.instanceOf(AttributeId));
  checkArg({ mappable }, ArgTypes.bool);
  const state = getState();
  const { schemaMapping } = state;
  const sourceMappings = selectSourceMappings(schemaMapping);
  const isMapped = sourceMappings.get(id, Map()).isEmpty();
  if (mappable || !isMapped) {
    dispatch(updateMappable(id, mappable));
  } else {
    dispatch({ type: PROMPT_DO_NOT_MAP_CONFIRMATION, id });
  }
};

const updateMappings = (
  dispatch: AppDispatch,
  state: AppState,
  changes: List<MappingChange>,
): Promise<void> => {
  checkArg({ changes }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(MappingChange)));

  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    return Promise.reject(Error(NO_UNIFIED_DATASET_ERROR_MESSAGE));
  }

  return TransformClient.postMappings(unifiedDatasetName, changes).then(() => {
    dispatch({ type: MAP_ATTRIBUTES_COMPLETED });
  }, response => {
    dispatch({ type: SHOW, detail: 'Error updating mappings', response });
  });
};

export const changeMapping = (
  sid: AttributeId,
  uid: AttributeId,
  isMapped: boolean,
): AppThunkAction<void> => (dispatch, getState) => {
  const createChange = isMapped ? MappingChange.map : MappingChange.unmap;
  return updateMappings(dispatch, getState(), List([createChange(sid, uid)]));
};

export const mapUnified = (uid: AttributeId): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const { schemaMapping: { sourceSelectedIds } } = state;
  return updateMappings(
    dispatch,
    state,
    sourceSelectedIds.map(sid => MappingChange.map(sid, uid)).toList(),
  );
};

export const mapSource = (sid: AttributeId): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const { schemaMapping: { unifiedSelectedIds } } = state;
  return updateMappings(
    dispatch,
    state,
    unifiedSelectedIds.map(uid => MappingChange.map(sid, uid)).toList(),
  );
};

export const toggleMlEnabled = (id: AttributeId): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const { schemaMapping: { unifiedAttributes } } = state;
  const attr = unifiedAttributes.find(a => is(attributeId(a), id));

  if (!attr) {
    console.warn(NO_ATTRIBUTE_WITH_ID_ERROR_MESSAGE(id));
    return;
  }

  const updatedAttr = attr.update('mlEnabled', b => !b);
  TransformClient.putUnifiedAttributes([updatedAttr]).then(() => {
    dispatch({ type: TOGGLE_ML_ENABLED_COMPLETED });
  }, response => {
    dispatch({ type: SHOW, detail: 'Error updating ML status', response });
  });
};

export const toggleGeoAttribute = (id: AttributeId): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const { schemaMapping: { unifiedAttributes } } = state;
  const attr = unifiedAttributes.find((a: UnifiedAttribute) => is(attributeId(a), id));

  if (!attr) {
    console.warn(NO_ATTRIBUTE_WITH_ID_ERROR_MESSAGE(id));
    return;
  }

  const type = isEqual(attr.type.toJSON(), GEOTAMR_RECORD_TYPE.toJSON()) ?
    ArrayType.of(stringType()) : GEOTAMR_RECORD_TYPE;
  const updatedAttr = attr.update('type', () => type);
  TransformClient.putUnifiedAttributes([updatedAttr]).then(() => {
    dispatch({ type: TOGGLE_GEO_ATTRIBUTE_COMPLETED });
  }, response => {
    dispatch({ type: SHOW, detail: 'Error updating attribute type', response });
  });
};

export const setSortType = (
  id: AttributeId,
  sortType: SortTypeE,
): AppThunkAction<void> => (dispatch, getState) => {
  const { schemaMapping } = getState();
  const { unifiedAttributes } = schemaMapping;
  const attr = unifiedAttributes.find(a => is(attributeId(a), id))?.set('sortType', sortType);

  if (!attr) {
    console.warn(NO_ATTRIBUTE_WITH_ID_ERROR_MESSAGE(id));
    return;
  }

  TransformClient.putUnifiedAttributes([attr]).then(() => {
    dispatch({ type: TOGGLE_SORT_TYPE_COMPLETED });
  }, response => {
    dispatch({ type: SHOW, detail: 'Error updating sort type', response });
  });
};

export const updateSimilarityFunction = (
  id: AttributeId,
  similarityFunction: SimilarityFunctionE,
): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ similarityFunction }, SimilarityFunction.argType);
  const { schemaMapping } = getState();
  const { unifiedAttributes } = schemaMapping;
  const { DEFAULT } = TokenizerConfig;
  const { IDF } = TokenWeighting;
  const attr = unifiedAttributes.find(a => is(attributeId(a), id))
    ?.set('similarityFunction', similarityFunction)
    .update('tokenizerConfig', (tc: TokenizerConfigE) => (allowsTokenizerConfig(similarityFunction) ? (tc || DEFAULT) : null))
    .update('tokenWeighting', (tc: TokenWeightingE) => (allowsTokenizerConfig(similarityFunction) ? (tc || IDF) : null));

  if (!attr) {
    console.warn(NO_ATTRIBUTE_WITH_ID_ERROR_MESSAGE(id));
    return;
  }

  TransformClient.putUnifiedAttributes([attr]).then(() => {
    dispatch({ type: UPDATE_SIMILARITY_FUNCTION_COMPLETED });
  }, response => {
    dispatch({ type: SHOW, detail: 'Error updating similarity function', response });
  });
};

export const updateTokenizerConfig = (
  id: AttributeId,
  value: TokenizerConfigE,
): AppThunkAction<void> => (dispatch, getState) => {
  const { schemaMapping } = getState();
  const { unifiedAttributes } = schemaMapping;
  const attr = unifiedAttributes.find(a => is(attributeId(a), id))
    ?.set('tokenizerConfig', value)
    .delete('numericFieldResolution');

  if (!attr) {
    console.warn(NO_ATTRIBUTE_WITH_ID_ERROR_MESSAGE(id));
    return;
  }

  TransformClient.putUnifiedAttributes([attr]).then(() => {
    dispatch({ type: UPDATE_TOKENIZER_CONFIG_COMPLETED });
  }, response => {
    dispatch({ type: SHOW, detail: 'Error updating tokenizer config', response });
  });
};

export const updateTokenWeighting = (
  id: AttributeId,
  value: TokenWeightingE,
): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ value }, TokenWeighting.argType);
  const { schemaMapping } = getState();
  const { unifiedAttributes } = schemaMapping;
  const attr = unifiedAttributes.find(a => is(attributeId(a), id))?.set('tokenWeighting', value);

  if (!attr) {
    console.warn(NO_ATTRIBUTE_WITH_ID_ERROR_MESSAGE(id));
    return;
  }

  TransformClient.putUnifiedAttributes([attr]).then(() => {
    dispatch({ type: UPDATE_TOKENIZER_CONFIG_COMPLETED });
  }, response => {
    dispatch({ type: SHOW, detail: 'Error updating tokenizer config', response });
  });
};

export const updateNumericFieldResolutions = (
  id: AttributeId,
  value: number[],
): AppThunkAction<void> => (dispatch, getState) => {
  const { schemaMapping } = getState();
  const { unifiedAttributes } = schemaMapping;
  const attr = unifiedAttributes.find(a => is(attributeId(a), id))
    ?.set('numericFieldResolution', value)
    .set('tokenizerConfig', null);

  if (!attr) {
    console.warn(NO_ATTRIBUTE_WITH_ID_ERROR_MESSAGE(id));
    return;
  }

  TransformClient.putUnifiedAttributes([attr]).then(() => {
    dispatch({ type: UPDATE_NUMERIC_FIELD_RESOLUTION_COMPLETED });
  }, response => {
    dispatch({ type: SHOW, detail: 'Error updating alpha/numeric field', response });
  });
};

export const setRequiredAttributeType = (
  id: AttributeId,
  type: RequiredAttributeTypeE,
): AppThunkAction<void> => (dispatch, getState) => {
  const { schemaMapping } = getState();
  const { unifiedAttributes } = schemaMapping;
  const { NONE } = RequiredAttributeType;
  const attr = unifiedAttributes.find(a => is(attributeId(a), id))?.set('requiredAttributeType', type);

  if (!attr) {
    console.warn(NO_ATTRIBUTE_WITH_ID_ERROR_MESSAGE(id));
    return;
  }

  const unsetAttrs = unifiedAttributes
    .filter(({ requiredAttributeType }) => requiredAttributeType === type && type !== NONE)
    .map(ua => ua.set('requiredAttributeType', NONE));

  TransformClient.putUnifiedAttributes([attr, ...unsetAttrs.toArray()]).then(() => {
    dispatch({ type: SET_REQUIRED_ATTRIBUTE_TYPE_COMPLETED });
  }, response => {
    dispatch({ type: SHOW, detail: 'Error updating required attribute type', response });
  });
};

export const deleteUnifiedAttributes = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const { schemaMapping: { confirmDeleteAttributeIds } } = state;
  const unifiedDatasetName = getUnifiedDatasetName(state);

  if (!unifiedDatasetName) {
    return dispatch({ type: SHOW, detail: NO_UNIFIED_DATASET_ERROR_MESSAGE, response: null });
  }

  TransformClient.deleteUnifiedAttributes(unifiedDatasetName, confirmDeleteAttributeIds).then(() => {
    dispatch({ type: DELETE_UNIFIED_ATTRIBUTES_COMPLETED });
  }, response => {
    dispatch({ type: SHOW, detail: 'Error deleting unified attribute', response });
  });
};

export const createUnifiedAttribute = (
  name: string,
  description: string,
): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    return dispatch({ type: SHOW, detail: NO_UNIFIED_DATASET_ERROR_MESSAGE, response: null });
  }

  let attr = new UnifiedAttribute({ name, datasetName: unifiedDatasetName });
  if (description) {
    attr = new UnifiedAttribute({ name, datasetName: unifiedDatasetName, description });
  }

  TransformClient.putUnifiedAttributes([attr]).then(() => {
    dispatch({ type: CREATE_UNIFIED_ATTRIBUTE_COMPLETED, attribute: attr, page: state.location.page });
    if (state.location.page === 'records') {
      dispatch(recordsFetchTransactions());
    }
  }, response => {
    dispatch({ type: SHOW, detail: 'Error creating unified attribute', response });
  });
};

export const bootstrapUnifiedAttributes = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const { schemaMapping: { sourceSelectedIds } } = state;
  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    return dispatch({ type: SHOW, detail: NO_UNIFIED_DATASET_ERROR_MESSAGE, response: null });
  }

  TransformClient.bootstrapMappings(unifiedDatasetName, sourceSelectedIds.toList())
    .then((response) => {
      dispatch({ type: BOOTSTRAP_UNIFIED_ATTRIBUTES_COMPLETED, response: BootstrapResponse.fromJSON(response) });
    }, response => {
      dispatch({ type: SHOW, detail: 'Error bootstrapping unified attributes', response });
    });
};

export const updateSourceDescription = (
  id: AttributeId,
  value: string,
): AppThunkAction<void> => (dispatch) => {
  const { name, datasetName } = id;
  DatasetClient.patchAttributes([{ name, datasetName, description: value, metadata: {}, revisionId: -1 }])
    .then(() => {
      dispatch({ type: UPDATE_SOURCE_DESCRIPTION_COMPLETED });
    }, response => {
      dispatch({ type: SHOW, detail: 'Error updating description', response });
    });
};

export const updateUnifiedNameAndDescription = (
  attr: UnifiedAttribute,
  newName: string,
  newDescription: string,
): AppThunkAction<void> => (dispatch) => {
  const currentAttrName = attr.name;
  attr = attr.set('name', newName).set('description', newDescription);
  return TransformClient.putOneUnifiedAttribute(currentAttrName, attr).then(() => {
    dispatch({ type: UPDATE_UNIFIED_NAME_AND_DESCRIPTION_COMPLETED });
  }, response => {
    dispatch({ type: SHOW, detail: 'Error updating unified attribute', response });
  });
};

const unmap = (
  unifiedDatasetName: string,
  attributes: List<SourceAttribute>,
): Promise<Response> => {
  return TransformClient.postMappings(
    unifiedDatasetName,
    attributes.flatMap(
      sa => sa.mappedAttributes.map(uid => MappingChange.unmap(attributeId(sa), uid)).toList(),
    ),
  );
};

const unmapAll = (dispatch: AppDispatch, getState: () => AppState): Promise<Response> => {
  const state = getState();
  const { schemaMapping: { sourceAttributes, sourceSelectedIds } } = state;
  const unifiedDatasetName = getUnifiedDatasetName(state);

  if (!unifiedDatasetName) {
    return Promise.reject(Error(NO_UNIFIED_DATASET_ERROR_MESSAGE));
  }

  return unmap(
    unifiedDatasetName,
    sourceAttributes.filter(sa => sourceSelectedIds.contains(attributeId(sa))),
  );
};

export const bulkUnmap = (): AppThunkAction<void> => (dispatch, getState) => {
  unmapAll(dispatch, getState).then(() => {
    dispatch({ type: BULK_UNMAP_COMPLETED });
  }, response => {
    dispatch({ type: SHOW, detail: 'Error bulk unmapping', response });
  });
};

export const doBulkDoNotMap = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const { schemaMapping: { sourceAttributes, sourceSelectedIds } } = state;
  unmapAll(dispatch, getState).then(() => {
    const attrs = sourceAttributes.filter(a => sourceSelectedIds.has(attributeId(a)));
    return setMappable(state, attrs.toArray(), false);
  }).then(() => {
    dispatch({ type: BULK_DO_NOT_MAP_COMPLETED });
  }, response => {
    dispatch({ type: SHOW, detail: 'Error bulk unmapping', response });
  });
};

export const bulkDoNotMapOrConfirm = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const { schemaMapping: { sourceAttributes, sourceSelectedIds } } = state;
  const attrsToModify = sourceAttributes.filter(a => sourceSelectedIds.has(attributeId(a)));
  const someMapped = attrsToModify.some(attr => !attr.mappedAttributes.isEmpty());
  if (someMapped) {
    dispatch({ type: SHOW_BULK_DO_NOT_MAP_DIALOG });
  } else { // safe to just run the update since none are mapped
    dispatch(doBulkDoNotMap());
  }
};

export const bulkAllowMapping = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const { schemaMapping } = state;
  const { sourceAttributes, sourceSelectedIds } = schemaMapping;
  const attrs = sourceAttributes.filter(a => sourceSelectedIds.has(attributeId(a)));

  setMappable(state, attrs.toArray(), true).then(() => {
    dispatch({ type: BULK_ALLOW_MAPPING_COMPLETED });
  }, response => {
    dispatch({ type: SHOW, detail: 'Error bulk allowing mapping', response });
  });
};

export const bulkMapRecommendations = (
  { mapToTop, ignoreWarnings }: { mapToTop: boolean, ignoreWarnings: boolean },
): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const { schemaMapping: { sourceAttributes, sourceSelectedIds } } = state;

  const selectedSourceAttrs = sourceAttributes.filter(sa => sourceSelectedIds.contains(attributeId(sa)));
  const unifiedDatasetName = getUnifiedDatasetName(state);

  if (!unifiedDatasetName) {
    return dispatch({ type: SHOW, detail: NO_UNIFIED_DATASET_ERROR_MESSAGE, response: null });
  }

  const { toDnm, toMap, conflicts, attrsWithTiedRecs, mappedButRecommendedDnm }
    = getBulkRecommendChanges(selectedSourceAttrs, unifiedDatasetName, mapToTop);

  if (!ignoreWarnings && (!conflicts.isEmpty() || !mappedButRecommendedDnm.isEmpty())) {
    dispatch({ type: SHOW_CONFIRM_BULK_RECS_DIALOG, mapToTop });
    return;
  }

  // if we've made it this far, there are either no conflicts, or the user has
  // signed off on the changes that we will make.

  return TransformClient.postMappings(unifiedDatasetName, toMap)
    .then(() => unmap(unifiedDatasetName, toDnm))
    .then(() => setMappable(state, toDnm.toArray(), false))
    .then(
      () => {
        dispatch({ type: BULK_MAP_RECOMMENDATIONS_COMPLETED });
        // inform users which mappings were skipped due to tied recommendation scores
        if (!attrsWithTiedRecs.isEmpty()) {
          dispatch({ type: SET_SOURCE_ATTRIBUTES_WITH_TIEDS_RECS, sourceAttributes: attrsWithTiedRecs });
        }
      },
      response => {
        dispatch({ type: SHOW, detail: 'Failed to update the unified dataset', response });
      },
    );
};

export const commitUnifiedAttributes = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const projectInfo = selectActiveProjectInfo(state);
  if (!projectInfo) {
    return dispatch({ type: SHOW, detail: NO_ACTIVE_PROJECT_ERROR_MESSAGE, response: null });
  }

  const recipeId = projectInfo.smRecipeId;
  const outId = projectInfo.smRecipe?.outputDatasets.first();

  if (!recipeId || !outId) {
    return dispatch({
      type: SHOW,
      detail: 'Unable to find the associated recipe for the active project',
      response: null,
    });
  }

  const { DEDUP, CATEGORIZATION } = RecipeType;

  const downstreamRecipes = getAllRecipeDocs(state).filter(({ data: { inputDatasets, type } }) => {
    return _.includes([DEDUP, CATEGORIZATION], type) && inputDatasets.some(did => did.equals(outId));
  }).map(doc => doc.id.id);
  dispatch({ type: COMMIT_UNIFIED_ATTRIBUTES, recipeId });
  runOperation(recipeId, RecipeOperations.RECORDS).then(jobDoc => {
    // job does not immediately come back decorated with recipe id in metadata, but we rely on that downstream
    const job = jobDoc.setIn(['data', 'metadata', 'recipeId'], recipeId);
    return $.when(...(downstreamRecipes.map(populate).toArray())).then(() => job);
  }).then((job) => {
    dispatch({ type: COMMIT_UNIFIED_ATTRIBUTES_COMPLETED, recipeId, job });
  }, (response) => {
    dispatch({ type: COMMIT_UNIFIED_ATTRIBUTES_FAILED, recipeId });
    dispatch({ type: SHOW, detail: 'Failed to update the unified dataset', response });
  });
};

export const trainSuggestions = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const projectInfo = selectActiveProjectInfo(state);
  if (!projectInfo) {
    return dispatch({ type: SHOW, detail: NO_ACTIVE_PROJECT_ERROR_MESSAGE, response: null });
  }

  const recipeId = getRecommendationsRecipeDoc(projectInfo)?.id.id;

  if (!recipeId) {
    return dispatch({
      type: SHOW,
      detail: NO_SMR_RECIPE_FOR_PROJECT_ERROR_MESSAGE(projectInfo.projectId),
      response: null,
    });
  }

  dispatch({ type: TRAIN_SUGGESTIONS, recipeId });

  runOperation(recipeId, RecipeOperations.TRAIN_RECS).then(jobDoc => {
    // job does not immediately come back decorated with recipe id in metadata, but we rely on that downstream
    const job = jobDoc.setIn(['data', 'metadata', 'recipeId'], recipeId);
    dispatch({ type: TRAIN_SUGGESTIONS_COMPLETED, recipeId, job });
    dispatch({ type: CHECK_SMR_MODEL_EXPORTABLE_COMPLETED });
  }, (response) => {
    dispatch({ type: TRAIN_SUGGESTIONS_FAILED, recipeId });
    dispatch({ type: SHOW, detail: 'Failed to train schema mapping suggestions model', response });
  });
};

export const predictSuggestions = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const projectInfo = selectActiveProjectInfo(state);
  if (!projectInfo) {
    return dispatch({ type: SHOW, detail: NO_ACTIVE_PROJECT_ERROR_MESSAGE, response: null });
  }

  const maybeUpdateRecipe = (recipeDoc: Document<Recipe>): Promise<void> => {
    if (projectInfo.smRecipeId === recipeDoc.data.metadata.get('schemaMappingRecipeId')) {
      return Promise.resolve();
    }
    const toModify = recipeDoc.toJSON();
    toModify.data.metadata.schemaMappingRecipeId = projectInfo.smRecipeId;
    const modified = Recipe.fromJSON(toModify.data);
    return updateRecipe({
      recipeId: recipeDoc.id.id,
      version: recipeDoc.lastModified.version,
      recipe: modified,
    }).then(() => {});
  };

  const recipeDoc = getRecommendationsRecipeDoc(projectInfo);
  if (!recipeDoc) {
    return dispatch({
      type: SHOW,
      detail: NO_SMR_RECIPE_FOR_PROJECT_ERROR_MESSAGE(projectInfo.projectId),
      response: null,
    });
  }

  const recipeId = recipeDoc.id.id;

  dispatch({ type: PREDICT_SUGGESTIONS, recipeId });
  maybeUpdateRecipe(recipeDoc).then(() => {
    runOperation(recipeId, RecipeOperations.RECOMMENDATIONS)
      .then(jobDoc => {
        dispatch({ type: PREDICT_SUGGESTIONS_COMPLETED, recipeId, job: jobDoc });
      }, (response) => {
        dispatch({ type: PREDICT_SUGGESTIONS_FAILED, recipeId });
        dispatch({ type: SHOW, detail: 'Failed to apply schema mapping suggestions model', response });
      });
  });
};

export const checkExportableSmrModel = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    return dispatch({ type: SHOW, detail: NO_UNIFIED_DATASET_ERROR_MESSAGE, response: null });
  }

  dispatch({ type: CHECK_SMR_MODEL_EXPORTABLE });

  TransformClient.checkSmrModelExportable(unifiedDatasetName).then(Result.handler(
    response => {
      if (response.ok) {
        dispatch({ type: CHECK_SMR_MODEL_EXPORTABLE_COMPLETED });
      } else {
        dispatch({ type: CHECK_SMR_MODEL_EXPORTABLE_FAILED });
      }
    },
    () => {
      dispatch({ type: CHECK_SMR_MODEL_EXPORTABLE_FAILED });
    },
  ));
};
