import classNames from 'classnames';
import React from 'react';

import { HTMLButtonProps } from '../utils/typescript-react';
import style from './CommentColumn.module.scss';
import TamrIcon from './TamrIcon';


interface CommentButtonOwnProps {
  hasComments: boolean
}

type CommentButtonProps = CommentButtonOwnProps & HTMLButtonProps

const CommentButton: React.FC<CommentButtonProps> = ({ hasComments, className, ...props }) => {
  return (
    <button className={classNames(className, style.button, { [style.hasComments]: hasComments })} {...props}>
      <TamrIcon iconName="comment" size={16} className={style.icon} />
    </button>
  );
};

export default CommentButton;
