// com.tamr.transform.models.SortType

import enumify from './enumify';

export enum SortTypeE {
  TEXT = 'TEXT',
  NUMERIC = 'NUMERIC',
}

export const { TEXT, NUMERIC } = SortTypeE;

export default enumify(SortTypeE);
