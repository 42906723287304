import { connect } from 'react-redux';

import { AppState } from '../stores/MainStore';
import { getUser } from '../users/UsersStore';
import { $TSFixMe } from '../utils/typescript';
// @ts-expect-error
import Comment from './Comment';

const PairsComment = connect((state: AppState, { comment, onEditComment, onDeleteComment }: { comment: $TSFixMe, onEditComment: $TSFixMe, onDeleteComment: $TSFixMe }) => {
  return {
    commentMessage: comment.message,
    timeInSeconds: comment.createdAt,
    lastModified: comment.modifiedAt,
    user: getUser(state, comment.username),
    onEditComment,
    onDeleteComment,
  };
})(Comment);

export default PairsComment;
