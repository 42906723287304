import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// @ts-expect-error
import CategoryDetailPage from '../pages/CategoryDetailPage';
import Clusters from '../pages/Clusters';
// @ts-expect-error
import DatasetCatalogPage from '../pages/DatasetCatalogPage';
// @ts-expect-error
import DnfBuilderPage from '../pages/DnfBuilderPage';
import EnrichmentPage from '../pages/EnrichmentPage';
import GoldenRecordsPage from '../pages/GoldenRecordsPage';
import GoldenRecordsRulesPage from '../pages/GoldenRecordsRulesPage';
// @ts-expect-error
import GroupByDatasets from '../pages/GroupByDatasets';
// @ts-expect-error
import JobsPage from '../pages/JobsPage';
// @ts-expect-error
import NotFound from '../pages/NotFound';
// @ts-expect-error
import Policies from '../pages/Policies';
import Pregroup from '../pages/Pregroup';
// @ts-expect-error
import Dashboard from '../pages/ProjectDashboardPage';
// @ts-expect-error
import ProjectDatasetCatalogPage from '../pages/ProjectDatasetCatalogPage';
import ProjectManagementPage from '../pages/ProjectManagementPage';
// @ts-expect-error
import ProjectStatus from '../pages/ProjectStatus';
// @ts-expect-error
import RecordPairs from '../pages/RecordPairs';
// @ts-expect-error
import SchemaMappingPage from '../pages/SchemaMappingPage';
// @ts-expect-error
import Spend from '../pages/Spend';
// @ts-expect-error
import Taxonomy from '../pages/Taxonomy';
// @ts-expect-error
import UnifiedDatasetRecords from '../pages/UnifiedDatasetRecords';
// @ts-expect-error
import Users from '../pages/Users';
import AppState from '../stores/AppState';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { pageTitleMap } from './PageTitleMap';

const Router = connect((state: AppState) => {
  const { location: { page } } = state;
  const projectInfo = selectActiveProjectInfo(state);
  return { page, projectInfo };
})(({ page, projectInfo }) => {
  useEffect(() => {
    if (projectInfo && projectInfo.projectDisplayName != null) {
      document.title = `${projectInfo.projectDisplayName} - ${pageTitleMap[page]}`;
    } else if (projectInfo && projectInfo.projectName != null) {
      document.title = `${projectInfo.projectName} - ${pageTitleMap[page]}`;
    } else {
      document.title = `${pageTitleMap[page]}`;
    }
  }, [page, projectInfo]);
  switch (page) {
    case 'index': return <ProjectManagementPage />;
    case 'datasetCatalog': return <DatasetCatalogPage />;
    case 'jobs': return <JobsPage />;
    case 'users': return <Users />;
    case 'projectDatasetCatalog': return <ProjectDatasetCatalogPage />;
    case 'groupBy': return <GroupByDatasets />;
    case 'schemaMapping': return <SchemaMappingPage />;
    case 'records': return <UnifiedDatasetRecords />;
    case 'dashboard': return <Dashboard />;
    case 'taxonomy': return <Taxonomy />;
    case 'categoryDetail': return <CategoryDetailPage />;
    case 'spend': return <Spend />;
    case 'dnfBuilder': return <DnfBuilderPage />;
    case 'pairs': return <RecordPairs />;
    case 'suppliers': return <Clusters />;
    case 'projectStatus': return <ProjectStatus />;
    case 'goldenrecords': return <GoldenRecordsPage />;
    case 'grRules': return <GoldenRecordsRulesPage />;
    case 'policies': return <Policies />;
    case 'pregroup': return <Pregroup />;
    case 'enrichment': return <EnrichmentPage />;
    case 'notfound': return <NotFound />;
  }
  return null;
});

export default Router;
