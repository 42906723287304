import { connect } from 'react-redux';

import MouseTrackingDragLayer from '../components/MouseTrackingDragLayer';

const SchemaMappingDragLayer = connect(({ schemaMapping: { sourceSelectedIds, unifiedSelectedIds } }) => {
  const getText = (itemType) => {
    const ids = itemType === 'sourceAttribute' ? sourceSelectedIds : unifiedSelectedIds;
    return (ids.size === 1 ? `"${ids.first().name}"` : `${ids.size} ${itemType === 'sourceAttribute' ? 'source' : 'unified'} attributes`);
  };
  return { getText };
})(MouseTrackingDragLayer);

export default SchemaMappingDragLayer;
