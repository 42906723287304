import { List, Set } from 'immutable';
import { isEqual } from 'underscore';

import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import { QueryBuilder } from '../models/doc/Query';
import createLoader from '../utils/createLoader';
import { fetchDatasets } from './DatasetFilterAsync';
import { getFilterInfo } from './DatasetFilterStore';

interface DatasetFilterLoaderOwnProps {
  getSelectedDatasets: (datasets: List<Document<Dataset>>) => Set<string>
  modifyQuery: (query: QueryBuilder) => void
  loadAllDatasets: boolean
}

const DatasetFilterLoader = createLoader((state, { getSelectedDatasets, modifyQuery, loadAllDatasets }: DatasetFilterLoaderOwnProps) => {
  const { datasetFilter, datasetFilter: { loading, loadedFilterInfo } } = state;
  const filterInfo = getFilterInfo(datasetFilter);
  return {
    canFetch: true,
    shouldFetch: !isEqual(filterInfo, loadedFilterInfo),
    loading,
    fetchArgs: [modifyQuery, getSelectedDatasets, loadAllDatasets],
  };
}, {
  onFetch: fetchDatasets,
}, 'DatasetFilterLoader');

export default DatasetFilterLoader;
