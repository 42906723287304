// TODO address the unusued state that the linter would complain about
/* eslint-disable react/no-unused-state */

import './Tier.scss';

import classNames from 'classnames';
import { is, List, Map } from 'immutable';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { AutoSizer, List as VirtualScroll } from 'react-virtualized';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import CenterContent from '../components/CenterContent';
import ConditionalButton from '../components/ConditionalButton';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import PlainInput from '../components/Input/PlainInput';
import PopoverTrigger from '../components/PopoverTrigger';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import TooltipTrigger from '../components/TooltipTrigger';
import ProcurementCategory from '../models/ProcurementCategory';
import ProcurementTaxonomy from '../models/ProcurementTaxonomy';
import TemplateStrings from './TemplateStrings';

const DEFAULT_CATEGORY_CELL_HEIGHT = 40;

/**
 * Scroll and select categories within a given taxonomy tier.
 */
class Tier extends React.Component {
  static propTypes = {
    activeCategory: PropTypes.instanceOf(ProcurementCategory),
    canDeleteCategorizationsChecker: PropTypes.func,
    canDeleteCategoryChecker: PropTypes.func,
    categoryCellRenderer: PropTypes.func,
    defaultCategoryCellHeight: PropTypes.number,
    disabledDeleteCategorizationsTooltip: PropTypes.func,
    disabledDeleteTooltip: PropTypes.func,
    ghostIds: ImmutablePropTypes.setOf(PropTypes.number).isRequired,
    onCreateCategory: PropTypes.func,
    onDeleteCategorizations: PropTypes.func,
    onDeleteCategory: PropTypes.func,
    onRenameCategory: PropTypes.func,
    onSelectCategory: PropTypes.func,
    searchString: PropTypes.string,
    selectedCategories: ImmutablePropTypes.listOf(ProcurementCategory),
    taxonomy: PropTypes.instanceOf(ProcurementTaxonomy),
    tierCategories: ImmutablePropTypes.listOf(ProcurementCategory).isRequired,
    tierLabelMaxCounts: ImmutablePropTypes.mapOf(PropTypes.number, PropTypes.number),
    tierNumber: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  };

  state = {
    categoryEditCategory: undefined,
    categoryEditCategoryName: undefined,
    columnHeight: 100,
    rowHeight: 10,
    categoryToAdd: undefined, // in the form {parentPath, name}
    saving: false,
    showDeleteCategorizationWarning: false,
    showDeleteWarning: false,
    showRenameWarning: false,
  };

  componentDidMount() {
    this.resize();
    $(window).on('resize', this.resize);
  }

  componentWillUnmount() {
    $(window).off('resize', this.resize);
  }

  onBlur = () => {
    this.setState({ focused: false });
  };

  onFocus = () => {
    this.setState({ focused: true });
  };

  getPathToCreate = () => {
    return this.state.categoryToAdd.parentPath.push(this.state.categoryToAdd.name);
  };

  getPathToEdit = () => {
    const currentPath = this.state.categoryEditCategory.path;
    return currentPath.pop().push(this.state.categoryEditCategoryName);
  };

  canSaveEditedCategory = () => {
    const { tierCategories } = this.props;
    if (this.state.categoryEditCategory) {
      if (this.state.saving) {
        // Don't allow double saving
        return false;
      } if (!this.state.categoryEditCategoryName) {
        return false;
      } if (tierCategories.find(cat => is(cat.path, this.getPathToEdit()))) {
        return false;
      }
      return true;
    }
  };

  canSaveNewCategory = () => {
    const { tierCategories } = this.props;
    if (this.state.categoryToAdd) {
      if (this.state.saving) {
        // Don't allow double saving
        return false;
      } if (!this.state.categoryToAdd.name) {
        return false;
      } if (tierCategories.find(cat => is(cat.path, this.getPathToCreate()))) {
        return false;
      }
      return true;
    }
  };

  disableSaveCategoryTooltip = () => {
    const { tierCategories } = this.props;
    if (this.state.categoryEditCategory) {
      if (this.state.saving) {
        // Don't allow double saving
        return 'Already saving';
      } if (!this.state.categoryEditCategoryName) {
        return 'Category name cannot be empty';
      } if (tierCategories.find(cat => is(cat.path, this.getPathToEdit()))) {
        return 'There is already a category with this name';
      }
    }
  };

  disableSaveNewCategoryTooltip = () => {
    const { tierCategories } = this.props;
    if (this.state.categoryToAdd) {
      if (this.state.saving) {
        // Don't allow double saving
        return 'Already saving';
      } if (!this.state.categoryToAdd.name) {
        return 'Category name cannot be empty';
      } if (tierCategories.find(cat => is(cat.path, this.getPathToCreate()))) {
        return 'There is already a category with this name';
      }
    }
  };

  handleAddCategory = () => {
    const { activeCategory, tierNumber } = this.props;
    let parentPath;
    if (activeCategory) {
      const { path } = activeCategory;
      // Select the parent path along the current activeCategory's path

      parentPath = path.take(tierNumber - 1);
    } else {
      parentPath = List();
    }
    this.setState({
      categoryToAdd: {
        name: undefined,
        parentPath,
      },
      categoryEditCategory: undefined,
      categoryEditCategoryName: undefined,
    });
  };

  handleCategoryClick = (category, e) => {
    e.stopPropagation();
    if (this.props.onSelectCategory) {
      this.props.onSelectCategory(category);
    }
    // TODO: clear search string
  };

  handleChangeExistingCategoryName = (e) => {
    this.setState({ categoryEditCategoryName: e.target.value });
  };

  handleChangeNewCategoryName = (e) => {
    const { categoryToAdd } = this.state;
    categoryToAdd.name = e.target.value;
    this.setState({ categoryToAdd });
  };

  handleDeleteCategorizations = () => {
    const { activeCategory, onDeleteCategorizations } = this.props;
    if (activeCategory) {
      onDeleteCategorizations(activeCategory.categoryId).then(() =>
        this.setState({ showDeleteCategorizationWarning: false }));
    }
  };

  handleDeleteCategory = () => {
    const { activeCategory, onDeleteCategory } = this.props;
    if (activeCategory) {
      onDeleteCategory(activeCategory.categoryId);
      this.setState({ showDeleteWarning: false });
    }
  };

  handleEditCategory = (category, e) => {
    e.stopPropagation();
    this.setState({
      categoryEditCategory: category,
      categoryEditCategoryName: category.name,
      categoryToAdd: undefined,
    });
  };

  handleSaveCategory = () => {
    this.props.onCreateCategory(this.getPathToCreate());
    this.setState({ categoryToAdd: undefined });
  };

  handleSaveEditedCategory = () => {
    this.props.onRenameCategory(this.state.categoryEditCategory.categoryId, this.state.categoryEditCategoryName);
    this.setState({ categoryEditCategoryName: undefined, categoryEditCategory: undefined, showRenameWarning: false });
  };

  hideDeleteCategorizationWarningDialog = () => {
    this.setState({ showDeleteCategorizationWarning: false });
  };

  hideDeleteWarningDialog = () => {
    this.setState({ showDeleteWarning: false });
  };

  hideRenameWarningDialog = () => {
    this.setState({ showRenameWarning: false });
  };

  resize = () => {
    _.throttle(() => {
      this.setState({
        columnHeight: $('.tier-column .values').height(),
        rowHeight: $('.tier-column .category').height() || this.props.defaultCategoryCellHeight || DEFAULT_CATEGORY_CELL_HEIGHT,
      });
    }, 100)();
  };

  showDeleteCategorizationWarningDialog = () => {
    this.refs.bulkActionPopover.hide();
    this.setState({ showDeleteCategorizationWarning: true });
  };

  showDeleteWarningDialog = () => {
    this.setState({ showDeleteWarning: true });
  };

  showRenameWarningDialog = () => {
    this.setState({ showRenameWarning: true });
  };

  renderDeleteCategorizationWarningDialog = () => {
    const { activeCategory } = this.props;
    let categoryName;
    if (activeCategory) {
      categoryName = activeCategory.path.get(-1);
    }
    return (
      <Dialog
        show={this.state.showDeleteCategorizationWarning}
        onHide={this.hideDeleteCategorizationWarningDialog}
        title={<div>Clear categorizations from <Term>parts</Term>?</div>}
        body={(
          <div className="dialog-text">
            Are you sure you want to clear the <b>{categoryName}</b> category and its subcategories from all <Term>parts</Term>?<br /><br />
            This action cannot be undone.
          </div>
        )}
        dialogStyle={DialogStyle.PRIMARY}
        footer={(
          <ButtonToolbar>
            <Button onClick={this.hideDeleteCategorizationWarningDialog} buttonType="Secondary">Cancel</Button>
            <Button onClick={this.handleDeleteCategorizations}>Remove</Button>
          </ButtonToolbar>
        )}
        closeButton
      />
    );
  };

  renderDeleteWarningDialog = () => {
    const { activeCategory } = this.props;
    let categoryName;
    if (activeCategory) {
      categoryName = activeCategory.path.get(-1);
    }
    return (
      <Dialog
        show={this.state.showDeleteWarning}
        onHide={this.hideDeleteWarningDialog}
        title="Remove category?"
        body={(
          <div className="dialog-text">
            Are you sure you want to remove <b>{categoryName}</b><br /><br />
            This action cannot be undone, but you can add the same category again in the future.
          </div>
        )}
        dialogStyle={DialogStyle.PRIMARY}
        footer={(
          <ButtonToolbar>
            <Button onClick={this.hideDeleteWarningDialog} buttonType="Secondary">Cancel</Button>
            <Button onClick={this.handleDeleteCategory}>Remove</Button>
          </ButtonToolbar>
        )}
        closeButton
      />
    );
  };

  renderEmpty = () => {
    const selectedPath = this.props.activeCategory ? this.props.activeCategory.path : List();
    const canAddChild = _.isFunction(this.props.onCreateCategory) &&
      selectedPath.size >= (this.props.tierNumber - 1);
    if (!canAddChild) {
      return undefined;
    }

    const subCategoryText = !selectedPath.isEmpty() ? ` in "${selectedPath.get(-1)}"` : undefined;
    return (
      <CenterContent className="empty-section">
        <div className="empty-message">There are no sub-categories{subCategoryText}</div>
        <div className="empty-action" onClick={this.handleAddCategory}>Want to add one now?</div>
      </CenterContent>
    );
  };

  renderRenameCategorizationWarningDialog = () => {
    const { categoryEditCategory, categoryEditCategoryName } = this.state;
    let categoryName;
    if (categoryEditCategory) {
      categoryName = categoryEditCategory.path.get(-1);
    }
    return (
      <Dialog
        show={this.state.showRenameWarning}
        onHide={this.hideRenameWarningDialog}
        title={<div>Rename category?</div>}
        body={(
          <div className="dialog-text">
            Are you sure you want to rename <b>{categoryName}</b> to <b>{categoryEditCategoryName}</b>?
          </div>
        )}
        dialogStyle={DialogStyle.PRIMARY}
        footer={(
          <ButtonToolbar>
            <Button onClick={this.hideRenameWarningDialog} buttonType="Secondary">Cancel</Button>
            <Button onClick={this.handleSaveEditedCategory}>Rename</Button>
          </ButtonToolbar>
        )}
        closeButton
      />
    );
  };

  render() {
    const {
      taxonomy,
      selectedCategories,
      activeCategory,
      tierCategories,
      categoryCellRenderer,
      tierNumber,
      onCreateCategory,
      onDeleteCategorizations,
      onDeleteCategory,
      onRenameCategory,
      canDeleteCategorizationsChecker,
      canDeleteCategoryChecker,
      disabledDeleteCategorizationsTooltip,
      disabledDeleteTooltip,
      ghostIds,
      searchString,
      tierLabelMaxCounts,
    } = this.props;
    if (!taxonomy || !selectedCategories) {
      return <div />;
    }
    // TODO: Empty component considerations

    let selectedIndex = 0;
    let columnContents = (
      tierCategories.map((category, i) => {
        const lastSegmentOfCategoryPath = category.path.get(-1);
        const isActive = activeCategory && category.categoryId === activeCategory.categoryId;
        const isSelected = !!selectedCategories.find((selectedCategory) => {
          return category.categoryId === selectedCategory.categoryId;
        });
        const isAncestorOfActiveCategory = activeCategory && !isActive &&
          is(
            activeCategory.path.take(category.path.size),
            category.path,
          );
        if (isSelected || isAncestorOfActiveCategory) {
          selectedIndex = i;
        }
        const cellClasses = classNames({
          category: true,
          active: isActive,
          selected: isSelected,
          'parent-of-active': isAncestorOfActiveCategory,
          'is-ghost': ghostIds.has(category.categoryId),
          'is-first': i === 0,
        });

        let cellContents;
        // If a custom renderer was specified, invoke it here
        if (categoryCellRenderer) {
          cellContents = categoryCellRenderer(
            category, tierNumber, taxonomy, searchString, tierLabelMaxCounts);
        } else {
          cellContents = lastSegmentOfCategoryPath;
        }
        if (this.state.categoryEditCategory && this.state.categoryEditCategory.categoryId === category.categoryId) {
          // Editing the name
          return ({ key, style }) =>
            (<div key={key} style={style} className="category edit-category rename-category">
              <div className="edit-category-container">
                <TooltipTrigger
                  autoFocus
                  placement="bottom"
                  className={classNames('can-not-save-tooltip', { inactive: this.canSaveEditedCategory() })}
                  content={this.disableSaveCategoryTooltip()}
                >
                  <PlainInput
                    autoFocus
                    className={classNames({ 'disable-save': !this.canSaveEditedCategory() })}
                    value={this.state.categoryEditCategoryName}
                    onChange={this.handleChangeExistingCategoryName}
                  />
                </TooltipTrigger>
                <div title="Cancel">
                  <Button
                    className="category-add-button cancel-button"
                    buttonType="Secondary"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({ categoryEditCategory: undefined });
                    }}
                    title="Cancel"
                  >
                    Cancel
                  </Button>
                </div>
                <div title={this.disableSaveCategoryTooltip() || 'Save'}>
                  <Button
                    className="category-add-button save-button"
                    buttonType="Primary"
                    onClick={this.showRenameWarningDialog}
                    disabled={!this.canSaveEditedCategory()}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>);
        }
        return ({ key, style }) =>
          (<div
            className={cellClasses}
            style={style}
            onClick={_.partial(this.handleCategoryClick, category)}
            key={key}
          >
            <div className="category-label" title={lastSegmentOfCategoryPath}>{cellContents}</div>
            {onRenameCategory ?
              <Button className="rename-category-button" buttonType="Link" icon="edit" onClick={(e) => this.handleEditCategory(category, e)} />
              : null}
          </div>);
      })
    );

    let addingCreateCell = false;
    if (this.state.categoryToAdd && this.state.categoryToAdd.parentPath.size === tierNumber - 1) {
      addingCreateCell = true;
      columnContents = columnContents.push(
        ({ key, style }) => (<div key={key} style={style} className="category edit-category">
          <div className="edit-category-container">
            <TooltipTrigger
              placement="bottom"
              content={this.disableSaveNewCategoryTooltip()}
              className={classNames('can-not-save-tooltip', { inactive: this.canSaveNewCategory() })}
            >
              <PlainInput
                autoFocus
                className={classNames({ 'disable-save': !this.canSaveNewCategory() })}
                value={this.state.categoryToAdd.name}
                onChange={this.handleChangeNewCategoryName}
              />
            </TooltipTrigger>
            <div title="Cancel">
              <Button
                className="category-add-button cancel-button"
                buttonType="Secondary"
                onClick={() => this.setState({ categoryToAdd: undefined })}
                title="Cancel"
              >
                Cancel
              </Button>
            </div>
            <div title="Save">
              <Button
                className="category-add-button save-button"
                buttonType="Primary"
                onClick={this.handleSaveCategory}
                disabled={!this.canSaveNewCategory()}
                title={this.disableSaveNewCategoryTooltip()}
              >
                Save
              </Button>
            </div>
          </div>
        </div>),
      );
    }
    const numCategoriesMessage =
      TemplateStrings.Taxonomy.numCategories(tierCategories.size);

    const selectedPath = activeCategory ? activeCategory.path : List();
    const showDeleteButton = _.isFunction(onDeleteCategory) &&
      selectedPath.size === tierNumber;
    const canDeleteCategory = (_.isFunction(canDeleteCategoryChecker) && activeCategory)
      ? canDeleteCategoryChecker(activeCategory) : true;

    const deleteCategoryButton = showDeleteButton
      ? <Button className="delete-category" buttonType="Link" icon="delete" iconSize={16} onClick={this.showDeleteWarningDialog} disabled={!canDeleteCategory} />
      : undefined;

    let wrappedDeleteCategoryButton;
    if (!canDeleteCategory && _.isFunction(disabledDeleteTooltip) && showDeleteButton) {
      wrappedDeleteCategoryButton = (
        <TooltipTrigger
          placement="top"
          content={disabledDeleteTooltip(activeCategory)}
        >
          <div>
            {deleteCategoryButton}
          </div>
        </TooltipTrigger>
      );
    } else {
      wrappedDeleteCategoryButton = deleteCategoryButton;
    }

    const showDeleteCategorizationsButton = _.isFunction(onDeleteCategorizations) &&
      selectedPath.size === tierNumber;
    const canDeleteCategorizations = (_.isFunction(canDeleteCategorizationsChecker) && activeCategory)
      ? canDeleteCategorizationsChecker(activeCategory) : true;
    const deleteCategorizationButton = showDeleteCategorizationsButton
      ? <Button className="delete-categorization" buttonType="Link" onClick={this.showDeleteCategorizationWarningDialog} disabled={!canDeleteCategorizations}>Clear categorizations</Button>
      : undefined;

    let wrappedDeleteCategorizationButton;
    if (!canDeleteCategorizations && _.isFunction(disabledDeleteCategorizationsTooltip) && showDeleteCategorizationsButton) {
      wrappedDeleteCategorizationButton = (
        <TooltipTrigger
          placement="bottom"
          content={disabledDeleteCategorizationsTooltip(activeCategory)}
        >
          <div>
            {deleteCategorizationButton}
          </div>
        </TooltipTrigger>
      );
    } else {
      wrappedDeleteCategorizationButton = deleteCategorizationButton;
    }
    const bulkActionsButton = showDeleteCategorizationsButton
      ? (
        <PopoverTrigger
          ref="bulkActionPopover"
          placement="bottom"
          className="bulk-actions-popover"
          content={(
            <div>
              {wrappedDeleteCategorizationButton}
            </div>
          )}
        >
          <TamrIcon iconName="tamr-icon-more-vert" size={16} />
        </PopoverTrigger>
      )
      // ? <Button className="delete-category" buttonType="Link" icon="delete" iconSize={16} onClick={this.showDeleteWarningDialog} disabled={!canDeleteCategory}/>
      : undefined;

    // Need to keep the scroll to value constant.
    // TODO: not entirely sure why
    const scrollTo = addingCreateCell
      ? columnContents.size - 1
      : !this.state.focused ? selectedIndex : undefined; // Scroll to selected category unless the tier is focused so it doesn't jump on click

    return (
      <div className="tier-column" style={{ width: this.props.width }} onFocus={this.onFocus} onBlur={this.onBlur} data-test-element={`tier-${tierNumber}`}>
        <div className="tier-header">
          <div className="header-primary">
            {TemplateStrings.Taxonomy.tierName(tierNumber)}
          </div>
          <div className="header-secondary" title={numCategoriesMessage}>
            {numCategoriesMessage}
          </div>
          {_.isFunction(onCreateCategory) ?
            <div className="action-button">
              <ConditionalButton
                className="add-category"
                buttonType="Link"
                icon="tamr-icon-add"
                onClick={this.handleAddCategory}
                preconditions={Map().set(`You must first select a tier ${tierNumber - 1} category to add a new category at this tier.`, selectedPath.size >= (tierNumber - 1))}
              />
            </div>
            : null}
          <div className="action-button bulk-actions">{bulkActionsButton}</div>
          <div className="action-button delete-category">{wrappedDeleteCategoryButton}</div>
        </div>
        <div className="values">
          <AutoSizer ref="autosizer" className="autosizer" onFocus={this.onFocus}>
            {({ width, height }) => (
              <VirtualScroll
                ref="vScroll"
                className="category-list"
                height={height - 2}
                overscanRowCount={6}
                noRowsRenderer={() => this.renderEmpty()}
                rowCount={columnContents.size}
                rowHeight={DEFAULT_CATEGORY_CELL_HEIGHT}
                rowRenderer={i => columnContents.get(i.index)(i)}
                width={width - 2}
                scrollToAlignment="center"
                scrollToIndex={scrollTo}
                mainCollectionUpToDateTestaroni={columnContents}
                scrollToIndexTestAroni={scrollTo}
              />
            )}
          </AutoSizer>
        </div>
        {this.renderDeleteWarningDialog()}
        {this.renderDeleteCategorizationWarningDialog()}
        {this.renderRenameCategorizationWarningDialog()}
      </div>
    );
  }
}

export default Tier;
