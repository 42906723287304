import { List, Record } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import { selectActiveProjectInfo } from '../utils/Selectors';
import DashboardGuide from './DashboardGuide';
import { getSchemaMappingStatus } from './DashboardUtils';
import datasetsImage from './images/datasets.svg';
import unifiedDatasetImage from './images/unifiedDataset.svg';

const SchemaMappingDashboardGuide = _.compose(
  connect(state => {
    const { recipeId, smRecipeId } = selectActiveProjectInfo(state);
    return { recipeId, smRecipeId, stepCompletionStatuses: getSchemaMappingStatus(state) };
  }),
)(({ stepCompletionStatuses, smRecipeId }) => {
  const dashboardStepsInfo = List([
    new (Record({
      wizardTitle: <span>Add<br />Datasets</span>,
      wizardCompletedTitle: <span>Datasets<br />Added</span>,
      wizardIcon: 'library-books',
      wizardURL: `/datasets/recipe/${smRecipeId}`,
      cardTitle: <span>Add your data</span>,
      cardText: <span>Adding data will bring your datasets into one central place and allow you to map them to a unified schema.</span>,
      documentationURL: 'overall-workflow-schema',
      cardButtonText: <span>Go to Datasets</span>,
      cardImage: datasetsImage,
      isStepCompleted: stepCompletionStatuses.hasAddedDatasets,
    }))(),
    new (Record({
      wizardTitle: <span>Map Unified<br />Dataset</span>,
      wizardCompletedTitle: <span>Unified Dataset<br />Mapped</span>,
      wizardIcon: 'settings-input-component-rotated',
      wizardURL: `/schema-mapping/recipe/${smRecipeId}`,
      cardTitle: <span>Map and transform your unified dataset</span>,
      cardText: <span>Map your data to a unified dataset with a single schema. After mapping your data, you can transform your unified dataset.</span>,
      documentationURL: 'unified-attributes',
      cardButtonText: <span>Go to Schema Mapping</span>,
      cardImage: unifiedDatasetImage,
      isStepCompleted: stepCompletionStatuses.hasMappedAttributes,
    }))(),
  ]);
  return (
    <DashboardGuide dashboardStepsInfo={dashboardStepsInfo} />
  );
});

export default SchemaMappingDashboardGuide;
