import { isEqual } from 'underscore';

import { AppState } from '../stores/MainStore';
import createLoader from '../utils/createLoader';
import { fetchPreviewInputRecords } from './GoldenRecordsAsync';
import { selectInputRecordsFilterInfo } from './GoldenRecordsSelectors';


const GRPreviewInputRecordsLoader = createLoader((state: AppState) => {
  const { goldenRecordsRules: { loadedInputRecordsFilterInfo, loadingPreviewInputRecords } } = state;
  const filterInfo = selectInputRecordsFilterInfo(state);
  const { previewIsUsable, bookmarkedClusterIds } = filterInfo;
  return {
    canFetch: previewIsUsable && !!bookmarkedClusterIds.length,
    shouldFetch: !isEqual(filterInfo, loadedInputRecordsFilterInfo),
    loading: loadingPreviewInputRecords,
  };
}, {
  onFetch: fetchPreviewInputRecords,
}, 'GRPreviewInputRecordsLoader');

export default GRPreviewInputRecordsLoader;
