import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Selector from '../components/Input/Selector';
import { getTerm } from '../utils/Terms';
import { getActiveSpendField } from '../utils/Selectors';

const TaxonomyViewSelector = _.compose(
  connect(state => {
    return { spendTerm: getTerm(state, 'Spend'), spendField: getActiveSpendField(state) };
  }),
)(class TaxonomyViewSelector extends React.Component {
  static propTypes = {
    onSelectionChange: PropTypes.func.isRequired,
    spendTerm: PropTypes.string.isRequired,
    value: PropTypes.string,
  };

  getLegend = () => {
    if (this.props.value === 'labels') {
      return (
        <div className="taxonomy-legend">
          <div className="legend-item">
            <div className="color-box user-categories" />
            <div className="legend-text">User verified categorizations</div>
          </div>
          <div className="legend-item">
            <div className="color-box tamr-categories" />
            <div className="legend-text">Tamr categorizations</div>
          </div>
        </div>
      );
    }
  };

  render() {
    const { spendTerm, spendField } = this.props;
    const valuesListWithSpend = [
      { display: 'Alphabetical', value: 'alphabetical' },
      { display: 'Labels', value: 'labels' },
      { display: spendTerm, value: 'spend' },
    ];
    const valuesListWithoutSpend = [
      { display: 'Alphabetical', value: 'alphabetical' },
      { display: 'Labels', value: 'labels' },
    ];
    const valuesList = !spendField ? valuesListWithoutSpend : valuesListWithSpend;
    return (
      <div className="taxonomy-view-selector">
        <span className="view-selector-label">View:</span>
        <Selector
          dropup
          value={this.props.value}
          onChange={this.props.onSelectionChange}
          placeholder="Select a view"
          values={valuesList}
        />
        {this.getLegend()}
      </div>
    );
  }
});

export default TaxonomyViewSelector;
