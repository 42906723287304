import classNames from 'classnames';
import { isEqual } from 'lodash';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import GeospatialBetaWarning from '../components/GeospatialBetaWarning';
import Selector from '../components/Input/Selector';
import PopoverTrigger from '../components/PopoverTrigger';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import Toggle from '../components/Toggle';
import TooltipTrigger from '../components/TooltipTrigger';
import { GEOTAMR_RECORD_TYPE } from '../constants/GeoTamrRecordType';
import ProjectTypes from '../constants/ProjectTypes';
import SortType from '../constants/SortType';
import AttributeId, { attributeId } from '../models/AttributeId';
import Project from '../models/Project';
import UnifiedAttribute from '../models/UnifiedAttribute';
import { AppState } from '../stores/MainStore';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { $TSFixMe } from '../utils/typescript';
import { ConnectedProps } from '../utils/typescript-react';
import RequiredAttributeType from './constants/RequiredAttributeType';
import SimilarityFunction, {
  allowsTokenizerConfig,
} from './constants/SimilarityFunction';
import TokenizerConfig from './constants/TokenizerConfig';
import TokenWeighting from './constants/TokenWeighting';
import ListItemIcon from './ListItemIcon';
import { TOGGLE_UNIFIED_ATTRIBUTE_MENU_EXPANDED } from './SchemaMappingActionTypes';
import {
  setRequiredAttributeType,
  setSortType,
  toggleGeoAttribute,
  updateNumericFieldResolutions,
  updateSimilarityFunction,
  updateTokenizerConfig,
  updateTokenWeighting,
} from './SchemaMappingAsync';
import style from './UnifiedAttributeContextualMenu.module.scss';

const SIMILARITY_FUNCTION_VALUES = [ // TODO provide better plain english explanations
  { display: 'Cosine', value: SimilarityFunction.COSINE },
  { display: 'Absolute Cosine', value: SimilarityFunction.ABSOLUTE_COSINE },
  { display: 'Jaccard', value: SimilarityFunction.JACCARD },
  { display: 'Absolute Difference', value: SimilarityFunction.DIFF },
  { display: 'Relative Difference', value: SimilarityFunction.RELATIVE_DIFF },
  { display: 'Hausdorff Distance', value: SimilarityFunction.GEOSPATIAL_HAUSDORFF },
  { display: 'Directional Hausdorff Distance', value: SimilarityFunction.GEOSPATIAL_DIRECTIONAL_HAUSDORFF },
  { display: 'Minimum Distance', value: SimilarityFunction.GEOSPATIAL_MIN_DISTANCE },
  { display: 'Relative Hausdorff', value: SimilarityFunction.GEOSPATIAL_RELATIVE_HAUSDORFF },
  { display: 'Relative Area Overlap', value: SimilarityFunction.GEOSPATIAL_RELATIVE_AREA_OVERLAP },
];

const TOKEN_WEIGHTING_VALUES = [
  { display: 'IDF Weighting', value: TokenWeighting.IDF },
  { display: 'No Weighting', value: TokenWeighting.NONE },
];

const TOKENIZER_HELP = {
  DEFAULT: (
    <ul className={style.tipList}>
      <li>Splits values on spaces and special characters except underscores, decimals in numbers, and periods in URLs</li>
      <li>Converts text to lowercase</li>
    </ul>
  ),
  STEMMING_EN: (
    <ul className={style.tipList}>
      <li>Splits values on spaces and special characters except underscores, decimals in numbers, and periods in URLs</li>
      <li>Converts text to lowercase</li>
      <li>Converts words to their root or stem. For example, “fishing” and “fished” become “fish.”</li>
    </ul>
  ),
  REGEX: (
    <ul className={style.tipList}>
      <li>Splits values on spaces and special characters including underscores, decimals in numbers, and periods in URLs</li>
      <li>Converts text to lowercase</li>
    </ul>
  ),
  BIGRAM: (
    <ul className={style.tipList}>
      <li>Values are converted to strings, which are then split into bigrams (sequences of two consecutive characters). Spaces are added to the first and last characters. For example, bigrams for “taco” are “&nbsp;t”, “ta”, “ac”, “co”, and “o&nbsp;”.</li>
    </ul>
  ),
  TRIGRAM: (
    <ul className={style.tipList}>
      <li>Values are converted to strings, which are then split into trigrams (sequences of three consecutive characters). Spaces are added to the first and last characters. For example, trigrams for “taco” are “&nbsp;&nbsp;t”, “&nbsp;ta”, “tac”, “aco”, “co&nbsp;”, and “o&nbsp;&nbsp;”.</li>
    </ul>
  ),
  BIWORD: (
    <ul className={style.tipList}>
      <li>Splits values on spaces and special characters except underscores, decimals in numbers, and periods in URLs</li>
      <li>Converts text to lowercase</li>
      <li>Individual words as well as pairs of consecutive words are generated. For example, Bi-Words for “w1 w2 w3” are “w1”, “w2”, “w3”, “w1 w2”, “w2 w3”.</li>
    </ul>
  ),
  NUMERIC: (
    <ul className={style.tipList}>
      <li>Values are not split, but treated as one numeric value.</li>
    </ul>
  ),
};
const DEFAULT_NUMERIC_FIELD_RESOLUTIONS = [10, 100];
const NUMERIC_TOKENIZER_OPTION = 'NUMERIC_TOKENIZER_OPTION';
const TOKENIZER_CONFIG_VALUES = [
  {
    display: <TooltipTrigger className={style.tooltip} content={TOKENIZER_HELP.DEFAULT}><div>Default</div></TooltipTrigger>,
    value: TokenizerConfig.DEFAULT,
    displayIn: [ProjectTypes.SUPPLIER_MASTERING, ProjectTypes.CLASSIFICATION],
  },
  {
    display: <TooltipTrigger className={style.tooltip} content={TOKENIZER_HELP.STEMMING_EN}><div>Stemming (English)</div></TooltipTrigger>,
    value: TokenizerConfig.STEMMING_EN,
    displayIn: [ProjectTypes.SUPPLIER_MASTERING, ProjectTypes.CLASSIFICATION],
  },
  {
    display: <TooltipTrigger className={style.tooltip} content={TOKENIZER_HELP.REGEX}><div>Spaces and special chars</div></TooltipTrigger>,
    value: TokenizerConfig.REGEX,
    displayIn: [ProjectTypes.SUPPLIER_MASTERING, ProjectTypes.CLASSIFICATION],
  },
  {
    display: <TooltipTrigger className={style.tooltip} content={TOKENIZER_HELP.BIGRAM}><div>Bigram</div></TooltipTrigger>,
    value: TokenizerConfig.BIGRAM,
    displayIn: [ProjectTypes.SUPPLIER_MASTERING, ProjectTypes.CLASSIFICATION],
  },
  {
    display: <TooltipTrigger className={style.tooltip} content={TOKENIZER_HELP.TRIGRAM}><div>Trigram</div></TooltipTrigger>,
    value: TokenizerConfig.TRIGRAM,
    displayIn: [ProjectTypes.SUPPLIER_MASTERING, ProjectTypes.CLASSIFICATION],
  },
  {
    display: <TooltipTrigger className={style.tooltip} content={TOKENIZER_HELP.BIWORD}><div>Bi-Word</div></TooltipTrigger>,
    value: TokenizerConfig.BIWORD,
    displayIn: [ProjectTypes.SUPPLIER_MASTERING, ProjectTypes.CLASSIFICATION],
  },
  {
    display: <TooltipTrigger className={style.tooltip} content={TOKENIZER_HELP.NUMERIC}><div>Numeric</div></TooltipTrigger>,
    value: NUMERIC_TOKENIZER_OPTION,
    displayIn: [ProjectTypes.CLASSIFICATION],
  },
];

const ownPropTypes = {
  attribute: PropTypes.instanceOf(UnifiedAttribute).isRequired,
  canUserEdit: PropTypes.bool.isRequired,
  project: PropTypes.instanceOf(Project),
};

const mapStateToProps = (state: AppState) => {
  const { schemaMapping: { unifiedAttributeMenuExpanded } } = state;
  const projectInfo = selectActiveProjectInfo(state);
  return {
    projectType: projectInfo && projectInfo.projectType,
    expanded: unifiedAttributeMenuExpanded,
  };
};

const mapDispatchToProps = {
  onToggle: () => ({ type: TOGGLE_UNIFIED_ATTRIBUTE_MENU_EXPANDED }),
  onToggleGeoAttribute: toggleGeoAttribute,
  onUpdateSimilarityFunction: updateSimilarityFunction,
  onUpdateTokenizerConfig: (id: AttributeId, value: $TSFixMe) => {
    if (value === NUMERIC_TOKENIZER_OPTION) {
      return updateNumericFieldResolutions(id, DEFAULT_NUMERIC_FIELD_RESOLUTIONS);
    }
    return updateTokenizerConfig(id, value);
  },
  onUpdateTokenWeighting: updateTokenWeighting,
  onSetRequiredAttributeType: setRequiredAttributeType,
  onSetSortType: setSortType,
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
);

type UnifiedAttributeContextualMenuProps =
  ConnectedProps<typeof connector> & InferProps<typeof ownPropTypes>;

class UnifiedAttributeContextualMenu extends React.Component<UnifiedAttributeContextualMenuProps> {
  static propTypes = ownPropTypes;

  getRequiredTypeValues = () => {
    const { projectType } = this.props;
    const requiredTypeValues = [
      { display: 'This does not represent a required attribute', value: RequiredAttributeType.NONE },
      { display: <Term>Spend</Term>, value: RequiredAttributeType.SPEND },
    ];
    if (projectType === ProjectTypes.SUPPLIER_MASTERING) {
      requiredTypeValues.push({ display: <Term>Supplier</Term>, value: RequiredAttributeType.SUPPLIER });
    }
    return requiredTypeValues;
  };

  renderToggleGeoSection = () => {
    const { attribute, onToggleGeoAttribute } = this.props;
    const isGeoAttribute = isEqual(attribute.type.toJSON(), GEOTAMR_RECORD_TYPE.toJSON());
    return (
      <div>
        <div className={style.title}>Geospatial Attribute</div>
        <Toggle onChange={() => onToggleGeoAttribute(attributeId(attribute))} value={isGeoAttribute} />
      </div>
    );
  };

  renderComparingValuesSection = () => {
    const { attribute, onUpdateSimilarityFunction, onUpdateTokenizerConfig, onUpdateTokenWeighting, projectType } = this.props;
    const { similarityFunction, tokenizerConfig, numericFieldResolution, tokenWeighting } = attribute;
    const tokenizerConfigToUse = numericFieldResolution ? NUMERIC_TOKENIZER_OPTION : tokenizerConfig;
    const tokenWeightingToUse = numericFieldResolution ? null : tokenWeighting;
    const tokenizerDesc = projectType === ProjectTypes.SUPPLIER_MASTERING
      ? <span>Select how <Term>pair</Term> values are split:</span>
      : <span>Select how values are split.</span>;
    return (
      <div>
        <div className={style.title}>Value similarity settings</div>
        {projectType === ProjectTypes.SUPPLIER_MASTERING ?
          <div>
            <div className={style.prompt}>Select how <Term>pair</Term> values are compared:</div>
            <div className={style.inputRow}>
              <div>Similarity function:</div>
              <Selector
                className={style.smallSelector}
                placeholder="Similarity Function"
                values={SIMILARITY_FUNCTION_VALUES}
                value={similarityFunction}
                onChange={(v : $TSFixMe) => onUpdateSimilarityFunction(attributeId(attribute), v)}
              />
            </div>
          </div>
          : null}
        <GeospatialBetaWarning {...{ similarityFunction }} className={style.warningSection} />
        {(projectType === ProjectTypes.CLASSIFICATION || allowsTokenizerConfig(similarityFunction)) ?
          <div>
            <div className={style.prompt}>{tokenizerDesc}</div>
            <div className={style.inputRow}>
              <div>Tokenizer:</div>
              <Selector
                className={style.smallSelector}
                placeholder="Tokenizer Config"
                values={_.filter(TOKENIZER_CONFIG_VALUES, v => _.contains(v.displayIn, projectType))}
                value={tokenizerConfigToUse}
                onChange={(v : $TSFixMe) => onUpdateTokenizerConfig(attributeId(attribute), v)}
              />
            </div>
          </div>
          : null}
        {projectType === ProjectTypes.SUPPLIER_MASTERING && allowsTokenizerConfig(similarityFunction) ?
          <div>
            <div className={style.prompt}>Select how value tokens are weighted:</div>
            <div className={style.inputRow}>
              <div>Token weighting:</div>
              <Selector
                className={style.smallSelector}
                placeholder="Token Weighting"
                values={TOKEN_WEIGHTING_VALUES}
                value={tokenWeightingToUse}
                onChange={(v : $TSFixMe) => onUpdateTokenWeighting(attributeId(attribute), v)}
              />
            </div>
          </div>
          : null}
      </div>
    );
  };

  renderSpendWarningSection = () => {
    const { projectType } = this.props;
    const entity = projectType === ProjectTypes.SUPPLIER_MASTERING ? 'Supplier' : 'Part';
    return (
      <div className={style.spendWarning}>
        <TamrIcon className={style.warningIcon} iconName="tamr-icon-warning" size={20} />
        To treat this attribute as <Term>{entity}</Term> <Term>Spend</Term>, its values will
        be converted to decimals in the unified dataset. The following cases will default to 0.0:
        <ul>
          <li>Empty values (“”)</li>
          <li>Special values (“Infinity”, "-Infinity", "NaN")</li>
          <li>Multiple values (“12, 4.3”)</li>
          <li>Values with text (“1K”)</li>
          <li>Values with commas (“1,392”)</li>
        </ul>
        To keep an unmodified version of your spend attribute in the unified dataset, you can map it
        to a second unified attribute.
      </div>
    );
  };

  renderRequiredAttributesSection = () => {
    const { attribute, onSetRequiredAttributeType } = this.props;
    const { requiredAttributeType } = attribute;
    return (
      <div>
        <div className={classNames(style.title, style.first)}>Required attributes</div>
        <div className={style.prompt}>
          Does this attribute represent a required attribute for this project?
        </div>
        <Selector
          className={style.requiredSelector}
          placeholder="Required Attribute Type"
          values={this.getRequiredTypeValues()}
          value={requiredAttributeType}
          onChange={(v : $TSFixMe) => onSetRequiredAttributeType(attributeId(attribute), v)}
        />
        {requiredAttributeType === RequiredAttributeType.SPEND ? this.renderSpendWarningSection() : null}
      </div>
    );
  };

  renderSortTypeSection = () => {
    const { canUserEdit, onSetSortType, attribute } = this.props;
    const { sortType } = attribute;
    return (
      <div className={style.sortTypeSection}>
        <div className={classNames(style.title, style.first)}>Table View</div>
        <div className={style.sortTypeSelectContainer}>
          <div className={style.label}>Sort values:</div>
          <Selector
            className={style.sortTypeSelector}
            placeholder="Sort Type"
            values={[
              { display: 'Alphabetically', value: SortType.TEXT },
              { display: 'Numerically', value: SortType.NUMERIC },
            ]}
            value={sortType}
            onChange={canUserEdit ? ((v : $TSFixMe) => onSetSortType(attributeId(attribute), v)) : () => {}}
          />
        </div>
      </div>
    );
  };

  renderMenuPopover = () => {
    const { projectType, canUserEdit, attribute: { generated, mlEnabled }, expanded, onToggle } = this.props;
    if (generated) { return this.renderSortTypeSection(); }
    return (
      <div>
        {this.renderSortTypeSection()}
        {projectType !== ProjectTypes.SCHEMA_MAPPING_RECOMMENDATIONS ? this.renderRequiredAttributesSection() : null}
        <a className={style.toggleLink} onClick={onToggle}>{expanded ? 'Hide advanced' : 'Advanced'}</a>
        {(projectType === ProjectTypes.SUPPLIER_MASTERING || projectType === ProjectTypes.CLASSIFICATION) && mlEnabled ?
          <div>{expanded ? this.renderComparingValuesSection() : null}</div>
          : null}
        <div>{ expanded ? this.renderToggleGeoSection() : null}</div>
        {!canUserEdit ? <div className={style.mask} /> : null}
      </div>
    );
  };

  render() {
    return (
      <PopoverTrigger className={style.popover} placement="left" content={this.renderMenuPopover()}>
        <ListItemIcon iconName="tamr-icon-more-vert" size={16} />
      </PopoverTrigger>
    );
  }
}

export default connector(UnifiedAttributeContextualMenu);
