import React from 'react';
import { connect } from 'react-redux';
import { pure } from 'recompose';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import style from './DeleteProjectDialog.module.scss';
import { CANCEL_CONFIRMING_DELETE_PROJECT } from './ProjectsActionTypes';
import { deleteProject } from './ProjectsApi';

const DeleteProjectDialog = _.compose(
  connect((state) => {
    const { projects: { projectsWithStatus, showConfirmDeleteProject } } = state;
    const show = _.isNumber(showConfirmDeleteProject);
    const doc = projectsWithStatus.map(pws => pws.project).find(projectDoc => projectDoc.id.id === showConfirmDeleteProject);
    return { show, doc };
  }, {
    onDelete: deleteProject,
    onHide: () => ({ type: CANCEL_CONFIRMING_DELETE_PROJECT }),
  }),
  pure,
)(({ show, doc, onHide, onDelete }) => {
  return (
    <Dialog
      dialogStyle={DialogStyle.PRIMARY}
      className="delete-project-dialog"
      show={show}
      onHide={onHide}
      body={show ? (
        <div className={style.deleteProjectText}>
          Are you sure you want to delete <b title={doc.data.name}>{doc.data.name}</b>?
        </div>
      ) : null}
      footer={show ? (
        <ButtonToolbar>
          <Button buttonType="Secondary" onClick={onHide}>Cancel</Button>
          <Button buttonType="Primary" onClick={() => onDelete(doc.id.id)}>Delete</Button>
        </ButtonToolbar>
      ) : null}
    />
  );
});

export default DeleteProjectDialog;
