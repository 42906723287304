import $ from 'jquery';

import { SHOW } from '../errorDialog/ErrorDialogActionTypes';
import TamrVersion from '../models/TamrVersion';
import { ArgTypes, checkReturn } from '../utils/ArgValidation';

export const doLoadVersion = checkReturn(ArgTypes.deferred.withResolution(TamrVersion.argType), () => {
  return $.ajax({
    url: SERVICES.procure('/service/version'),
    method: 'GET',
    contentType: 'application/json',
    cache: false,
  }).then(data => new TamrVersion(data));
});

export const loadVersion = () => (dispatch) => {
  return doLoadVersion()
    .then((version) => {
      dispatch({ type: 'Version.loadVersionCompleted', version });
    })
    .fail(response => {
      dispatch({ type: SHOW,
        detail: 'Error loading version information',
        response,
      });
    });
};
