import { ElasticConfig } from '../api/ProcurifyClient';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import ScoreThresholds from '../models/ScoreThresholds';
import { StoreReducers } from '../stores/AppAction';
import { ArgTypes } from '../utils/ArgValidation';

export class ConfigStore extends getModelHelpers({
  elasticConfig: { type: ArgTypes.nullable(ArgTypes.instanceOf(ElasticConfig)) },
  categorizationStrengthThresholds: { type: ArgTypes.nullable(ArgTypes.instanceOf(ScoreThresholds)) },
  pairConfidenceThresholds: { type: ArgTypes.nullable(ArgTypes.instanceOf(ScoreThresholds)) },
  clusterConfidenceThresholds: { type: ArgTypes.nullable(ArgTypes.instanceOf(ScoreThresholds)) },
}, 'PolicyEntityCount')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class PolicyEntityCountRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

export const initialState = new ConfigStore({});

export const reducers: StoreReducers<ConfigStore> = {
  'Config.fetchElasticConfigCompleted': (state, { maxResultWindow, maxGeospatialFeaturesDefault }) => {
    return state.set('elasticConfig', new ElasticConfig({ maxResultWindow, maxGeospatialFeaturesDefault }));
  },

  'Config.fetchCategorizationStrengthThresholdsCompleted': (state, { categorizationStrengthThresholds }) => {
    return state.merge({ categorizationStrengthThresholds });
  },

  'Config.fetchPairConfidenceThresholdsCompleted': (state, { pairConfidenceThresholds }) => {
    return state.merge({ pairConfidenceThresholds });
  },

  'Config.fetchClusterConfidenceThresholdsCompleted': (state, { clusterConfidenceThresholds }) => {
    return state.merge({ clusterConfidenceThresholds });
  },
};
