import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { auth } from '../sso/SSOAsync';
import LoginPage from './LoginPage';

const AuthenticatedAppPage = connect((state) => {
  const { auth: { validatingToken, authorizedUser } } = state;
  return {
    validatingToken,
    loggedIn: !!authorizedUser,
  };
}, {
  onCheckAuth: auth,
})(class AuthenticatedAppPage extends React.Component {
  checkAuth = () => {
    const { loggedIn, validatingToken, onCheckAuth } = this.props;
    if (!loggedIn && !validatingToken) {
      onCheckAuth();
    }
  };

  componentDidMount() { this.checkAuth(); }
  componentDidUpdate() { this.checkAuth(); }

  render() {
    const { children, loggedIn, loggingIn } = this.props;
    if (loggedIn) {
      return children;
    }
    if (!loggingIn) {
      return <LoginPage />;
    }
    // Return an empty div if the app is currently being authenticated to avoid a jitter
    // on the screen
    return <div />;
  }
});
AuthenticatedAppPage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthenticatedAppPage;
