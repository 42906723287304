import { List } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import ProcurementTaxonomy from '../../models/ProcurementTaxonomy';
import { history } from '../../utils/History';
import PRODUCT_NAME from '../../utils/ProductName';
import SegmentedBarGraphCard, { Segment } from '../SegmentedBarGraphCard';

const SuggestedCategorizationsCard = connect(state => {
  const { taxonomy: { taxonomy } } = state;
  return { taxonomy };
})(class SuggestedCategorizationsCard extends React.Component {
  static propTypes = {
    c12nRecipeId: PropTypes.number.isRequired,
    taxonomy: PropTypes.instanceOf(ProcurementTaxonomy),
  };

  navigateToSpend = () => {
    const { c12nRecipeId } = this.props;
    history.push(`/spend/recipe/${c12nRecipeId}`);
  };

  render() {
    const { taxonomy } = this.props;
    if (!taxonomy) {
      return <div />;
    }
    const [high, med, low] = ['high', 'med', 'low'].map(key => {
      return taxonomy.categories
        .filter(c => c.path.size === 1)
        .reduce((rn, c) => rn + c.suggestedCategorizations.get(key).count, 0);
    });
    const segments = List([
      new Segment({ value: high, label: 'High Confidence' }),
      new Segment({ value: med, label: 'Medium Confidence' }),
      new Segment({ value: low, label: 'Low Confidence' }),
    ]);
    return (
      <SegmentedBarGraphCard
        className="dashboard-suggested-categorizations-card"
        onClick={this.navigateToSpend}
        segments={segments}
        totalNounPlural={`${PRODUCT_NAME} Categorizations`}
        totalNounSingular={`${PRODUCT_NAME} Categorization`}
        totalValue={high + med + low}
      />
    );
  }
});

export default SuggestedCategorizationsCard;
