import Immutable, { List, Record, Set } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import _ from 'underscore';

import { startAddDataset } from '../addDataset/AddDatasetAsync';
import Button from '../components/Button';
import ColumnWidthProvider from '../components/ColumnWidthProvider';
import LoadingPanel from '../components/LoadingPanel';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Pager from '../components/Table/PagerModel';
import Table from '../components/Table/Table';
import TextHeaderCell from '../components/TextHeaderCell';
import { OPEN_EXPORT_DIALOG, OPEN_EXPORT_JDBC_DIALOG, SET_TAMR_SOURCE_DATASET } from '../coreConnectService/CoreConnectServiceActionTypes';
import Dataset from '../models/Dataset';
import DatasetStatus from '../models/DatasetStatus';
import Document from '../models/doc/Document';
import Export from '../models/Export';
import ProfilingInfo from '../models/ProfilingInfo';
import Project from '../models/Project';
import Tag from '../models/Tag';
import {
  getProjectGeneratedIdsByProjectId,
  getUnifiedDatasetIdsByProjectId,
  selectProjectDocsById,
} from '../projects/ProjectsStore';
import { RECIPE_INPUTS_KEY } from '../projects/RecipeConstants';
import {
  getCurrentlyExportingDatasetIds,
  getCurrentlyProfilingDatasetNames,
} from '../utils/Selectors';
import style from './DatasetCatalogTable.module.scss';
import DatasetDescriptionColumn from './DatasetDescriptionColumn';
import DatasetExportColumn from './DatasetExportColumn';
import DatasetIdFieldColumn from './DatasetIdFieldColumn';
import DatasetLastUpdatedColumn from './DatasetLastUpdatedColumn';
import DatasetNameColumn from './DatasetNameColumn';
import DatasetNumAttributesColumn from './DatasetNumAttributesColumn';
import DatasetNumRecordsColumn from './DatasetNumRecordsColumn';
import DatasetOriginColumn from './DatasetOriginColumn';
import DatasetPreviewColumn from './DatasetPreviewColumn';
import DatasetProfilingStatusColumn from './DatasetProfilingStatusColumn';
import DatasetTagsColumn from './DatasetTagsColumn';
import { fetchDatasetSample } from './SharedDatasetCatalogApi';
import DatasetPoliciesColumn from './DatasetPoliciesColumn';

const PAGE_SIZES = Immutable.List.of(25, 50, 100);

const DatasetCatalogTable = _.compose(
  connect((state) => {
    const {
      projects,
      datasetCatalog: { selectedRows, datasets, profiling, exporting, status, noDatasets, paging, loading },
      tags: { allTags },
      accessControl: { policyDocs },
      coreConnectService: { coreconnectDefaultProvider },
    } = state;
    return {
      allTags,
      selectedRows,
      datasets,
      profiling,
      exporting,
      status,
      noDatasets,
      paging,
      loading,
      currentlyExportingDatasetIds: getCurrentlyExportingDatasetIds(state),
      currentlyProfilingDatasetNames: getCurrentlyProfilingDatasetNames(state),
      projectsById: selectProjectDocsById(state),
      unifiedDatasetIdsByProjectId: getUnifiedDatasetIdsByProjectId(projects),
      projectGeneratedIdsByProjectId: getProjectGeneratedIdsByProjectId(projects),
      policyDocs,
      coreconnectDefaultProvider,
    };
  }, {
    onStartManagingForDatasets: (datasets) => ({ type: 'ManageProjectDatasets.startManagingForDatasets', datasets }),
    onPageChange: (pageNum) => ({ type: 'DatasetCatalog.setPage', pageNum }),
    onPageSizeChange: (pageSize) => ({ type: 'DatasetCatalog.setPageSize', pageSize }),
    onSetSearchString: (search) => ({ type: 'DatasetCatalog.setSearchString', search }),
    onSetShowingSource: (showing) => ({ type: 'DatasetCatalog.setShowingSource', showing }),
    onSetShowingDerived: (showing) => ({ type: 'DatasetCatalog.setShowingDerived', showing }),
    onSelectRows: (selectedRows) => ({ type: 'DatasetCatalog.selectRows', selectedRows }),
    onConfirmProfile: (datasetNames) => ({ type: 'SharedDatasetCatalog.confirmProfile', datasetNames }),
    onConfirmExport: (datasetNames) => ({ type: 'SharedDatasetCatalog.confirmExport', datasetNames }),
    onConfirmOverwriteExport: (datasetNames) => ({ type: 'SharedDatasetCatalog.confirmOverwriteExport', datasetNames }),
    onShowExportFailureDialog: (errorMessage) => ({ type: 'SharedDatasetCatalog.showExportFailureDialog', errorMessage }),
    onFetchDatasetSample: fetchDatasetSample,
    onShowFileUploadDialog: () => startAddDataset({ isCatalogPage: true }),
    onStartEditingPolicyResourceship: ({ datasetId, datasetName }) => ({ type: 'Datasets.startEditingPolicyResourceship', datasetId, datasetName }),
    onOpenCoreConnectExportDialog: () => ({ type: OPEN_EXPORT_DIALOG }),
    onOpenCoreConnectExportJDBCDialog: () => ({ type: OPEN_EXPORT_JDBC_DIALOG }),
    onSetTamrSourceDataset: (tamrSourceDataset) => ({ type: SET_TAMR_SOURCE_DATASET, tamrSourceDataset }),
  }),
)(class DatasetCatalogTable extends React.Component {
  static propTypes = {
    allTags: ImmutablePropTypes.setOf(Document.propType.withDataType(Tag)).isRequired,
    coreconnectDefaultProvider: PropTypes.string.isRequired,
    currentlyExportingDatasetIds: ImmutablePropTypes.setOf(PropTypes.number).isRequired,
    currentlyProfilingDatasetNames: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
    datasets: ImmutablePropTypes.listOf(Document.propType.withDataType(Dataset)).isRequired,
    exporting: ImmutablePropTypes.mapOf(Document.propType.withDataType(Export), PropTypes.number).isRequired,
    loading: PropTypes.bool.isRequired,
    onConfirmExport: PropTypes.func.isRequired,
    onConfirmOverwriteExport: PropTypes.func.isRequired,
    onConfirmProfile: PropTypes.func.isRequired,
    onOpenCoreConnectExportDialog: PropTypes.func.isRequired,
    onOpenCoreConnectExportJDBCDialog: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onPageSizeChange: PropTypes.func.isRequired,
    onSelectRows: PropTypes.func.isRequired,
    onSetTamrSourceDataset: PropTypes.func.isRequired,
    onShowExportFailureDialog: PropTypes.func.isRequired,
    onStartManagingForDatasets: PropTypes.func.isRequired,
    paging: PropTypes.instanceOf(Record).isRequired,
    profiling: ImmutablePropTypes.mapOf(PropTypes.instanceOf(ProfilingInfo), PropTypes.string).isRequired,
    projectGeneratedIdsByProjectId: ImmutablePropTypes.mapOf(ImmutablePropTypes.setOf(PropTypes.number), PropTypes.number).isRequired,
    projectsById: ImmutablePropTypes.mapOf(Document.propType.withDataType(Project), PropTypes.number).isRequired,
    selectedRows: ImmutablePropTypes.setOf(PropTypes.number),
    status: ImmutablePropTypes.mapOf(PropTypes.instanceOf(DatasetStatus), PropTypes.string).isRequired,
    unifiedDatasetIdsByProjectId: ImmutablePropTypes.mapOf(PropTypes.number, PropTypes.number).isRequired,
  };

  render() {
    const { noDatasets, onShowFileUploadDialog, datasets, loading, paging, profiling, onStartManagingForDatasets, projectsById,
      onPageChange, onPageSizeChange, onFetchDatasetSample, onSelectRows, selectedRows, unifiedDatasetIdsByProjectId,
      projectGeneratedIdsByProjectId, currentlyProfilingDatasetNames, onConfirmProfile,
      exporting, status, currentlyExportingDatasetIds, onConfirmExport, onConfirmOverwriteExport, onShowExportFailureDialog, allTags,
      policyDocs, onStartEditingPolicyResourceship, coreconnectDefaultProvider, onOpenCoreConnectExportDialog, onOpenCoreConnectExportJDBCDialog, onSetTamrSourceDataset } = this.props;
    const pager = new Pager(
      datasets,
      paging.numDatasets,
      paging.page,
      paging.pageSize,
    );

    return (
      <div className={style.component}>
        {noDatasets ? (
          <div className={style.center}>
            <span>You don't have any datasets in your system.</span>
            <Button
              buttonType="Primary"
              onClick={() => {
                onShowFileUploadDialog();
              }}
            >
              Add new dataset
            </Button>
          </div>
        ) : datasets.isEmpty() && loading !== null && !loading ? (
          <div className={style.center}>
            <div>No datasets matching your filters</div>
          </div>
        ) : (
          <AutoSizer>
            {({ width, height }) => (
              <div>
                {loading ? <LoadingPanel aboveCenter /> : null}
                <ColumnWidthProvider>
                  <Table
                    enableMultipleRowSelection
                    getLength={() => datasets.size}
                    tableType="stripes"
                    height={height}
                    width={width}
                    pageSizes={PAGE_SIZES}
                    pagerState={pager}
                    onPageSizeChange={onPageSizeChange}
                    onPageChange={onPageChange}
                    onSelectedRowsChange={onSelectRows}
                    selectedRowNumbers={selectedRows}
                  >
                    {DatasetNameColumn({ rows: datasets })}
                    {DatasetTagsColumn({ rows: datasets, allTags })}
                    {DatasetDescriptionColumn({ rows: datasets, width: 100 })}
                    {DatasetPoliciesColumn({ datasetDocs: datasets, policyDocs, onStartEditingPolicyResourceship, width: 150 })}
                    {DatasetOriginColumn({ datasets, unifiedDatasetIdsByProjectId, projectGeneratedIdsByProjectId, projectsById })}
                    {DatasetIdFieldColumn({ width: 120, rows: datasets })}
                    <Column
                      key="projectMembership"
                      width={140}
                      isResizable
                      columnKey="projectMembership"
                      flexGrow={1}
                      header={<TextHeaderCell>Input to projects</TextHeaderCell>}
                      cell={({ rowIndex }) => {
                        const dataset = datasets.get(rowIndex);
                        const recipeInputs = dataset.data.metadata.get(RECIPE_INPUTS_KEY); // can be undefined, new List(...) takes care of it
                        const containingProjectDocs = new List(recipeInputs).map(recipeId => { // projects this dataset is a member of
                          return projectsById.find(projectDoc => {
                            return !!projectDoc.data.steps.find(recipeDocId => recipeDocId.id === recipeId);
                          });
                        }).filter(doc => doc !== undefined); // NB there is currently a bug where recipeInputs does
                        // not get updated when project is deleted, causing an
                        // NPE here. So if the project doc is for that reason
                        // or any other unfindable, don't consider it
                        const onClick = () => onStartManagingForDatasets(Set.of(dataset.data.name));
                        const title = containingProjectDocs.map(pDoc => (pDoc ? pDoc.data.name : '')).join(', ');
                        let content;
                        if (containingProjectDocs.isEmpty()) {
                          content = <a {...{ onClick, title }}>--</a>;
                        } else {
                          content = (
                            <span>
                              {containingProjectDocs.map(doc => (
                                <a key={doc.data.name} {...{ onClick, title }}>{doc.data.displayName}</a>
                              )).interpose(', ').toArray()}
                            </span>
                          );
                        }
                        return <Cell className={style.actionCell}>{content}</Cell>;
                      }}
                    />
                    {DatasetNumAttributesColumn({ width: 60, rows: datasets })}
                    {DatasetNumRecordsColumn({ width: 60, rows: datasets, currentlyProfilingDatasetNames, profiling })}
                    {DatasetLastUpdatedColumn({ datasets })}
                    {DatasetProfilingStatusColumn({ width: 125, rows: datasets, currentlyProfilingDatasetNames, profiling, status, onConfirmProfile })}
                    {DatasetPreviewColumn({ width: 125, rows: datasets, currentlyProfilingDatasetNames, profiling, status, onFetchDatasetSample })}
                    {DatasetExportColumn({ datasets, exporting, status, currentlyExportingDatasetIds, onConfirmExport, onConfirmOverwriteExport, onShowExportFailureDialog, coreconnectDefaultProvider, onOpenCoreConnectExportDialog, onSetTamrSourceDataset, onOpenCoreConnectExportJDBCDialog })}
                  </Table>
                </ColumnWidthProvider>
              </div>
            )}
          </AutoSizer>
        )}
      </div>
    );
  }
});

export default DatasetCatalogTable;
