import classNames from 'classnames';
import { List, Set } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BulletedTooltipTrigger from '../components/BulletedTooltipTrigger';
import Button from '../components/Button';
import FilterButton from '../components/FilterButton';
import Checkbox from '../components/Input/Checkbox';
import PopoverTrigger from '../components/PopoverTrigger';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import { CHANGED_CLUSTER, DELETED_RECORD, NEW_RECORD, UNCHANGED_CLUSTER } from '../constants/ClusterRecordChanges';
import { onlyInputDatasets } from '../datasetFilter/DatasetFilterAsync';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { getPath } from '../utils/Values';
import filterStyle from './FilterSection.module.scss';
import SourceDatasetFilter from './SourceDatasetFilter';
import { CLEAR_RECORD_FILTERS } from './SuppliersActionTypes';
import { getAnyRecordsFilters } from './SuppliersStore';
import TestRecordsFilter from './test-record/Filters';
import VerificationFilterSection from './VerificationFilterSection';

const ClusterRecordSourceDatasetFilter = connect((state, { onHideDialog, onOpenDialog, pane }) => {
  const { suppliers: { [pane]: { recordsSelectedSourceDatasets, recordsSourceDatasetsFilterDialogVisible } } } = state;
  return {
    dialogVisible: recordsSourceDatasetsFilterDialogVisible,
    infoTooltipMessage: 'Filter to records from the specified sources',
    modifyQuery: onlyInputDatasets(state),
    onHideDialog,
    onOpenDialog,
    selectedSourceDatasets: recordsSelectedSourceDatasets,
  };
}, (dispatch, { pane }) => bindActionCreators({
  onRemoveSelectedSource: name => ({ type: 'Suppliers.setRecordsSelectedSourceDatasets', pane, datasetsToAdd: Set(), datasetsToRemove: Set.of(name) }),
  onSetDialogVisible: show => ({ type: 'Suppliers.setRecordsSourceDatasetsDialogVisible', pane, show }),
  onSubmitDialog: (datasetsToAdd, datasetsToRemove) => ({ type: 'Suppliers.setRecordsSelectedSourceDatasets', pane, datasetsToAdd, datasetsToRemove }),
}, dispatch))(SourceDatasetFilter);

ClusterRecordSourceDatasetFilter.propTypes = {
  pane: PropTypes.string.isRequired,
};

@connect((state, { pane }) => {
  const { suppliers: { [pane]: { hasComments, recordChanges } } } = state;
  return {
    hasComments,
    anyFilters: getAnyRecordsFilters(state, pane),
    publishedClustersExist: !!getPath(selectActiveProjectInfo(state)?.projectWithStatus.recipes.find(r => r.recipe.data.type === 'DEDUP'), 'materializations', 'publishClusters', 'lastRun', 'timestamp'),
    recordChanges,
  };
}, (dispatch, { pane }) => bindActionCreators({
  onToggleFilterHasComments: () => ({ type: 'Suppliers.toggleFilterHasComments', pane }),
  onToggleRecordChangesFilter: filterOption => ({ type: 'Suppliers.toggleRecordChangesFilter', pane, filterOption }),
  onClearFilters: () => ({ type: CLEAR_RECORD_FILTERS, pane }),
}, dispatch))
export default class ClusterRecordsFilters extends React.Component {
  filterPanel = React.createRef();

  render() {
    const { anyFilters, onClearFilters, onToggleFilterHasComments, hasComments, pane, onToggleRecordChangesFilter, recordChanges, publishedClustersExist } = this.props;
    return (
      <FilterButton
        onClear={anyFilters ? onClearFilters : undefined}
        hasFilters={anyFilters}
        render={content => (
          <PopoverTrigger
            ref={this.filterPanel}
            className={filterStyle.supplierPageFilter}
            placement="bottom"
            content={
              <div>
                <div className={filterStyle.titleContainer}>
                  <div className={filterStyle.title}>Record filters</div>
                  <Button className={filterStyle.titleButton} buttonType="Link" onClick={onClearFilters}>clear all</Button>
                </div>
                <VerificationFilterSection {...{ pane }} />
                <div className={filterStyle.filterSection}>
                  <div className={filterStyle.filterPanelHeader}>Comments</div>
                  <div className={filterStyle.option}>
                    <Checkbox
                      className={filterStyle.checkboxWrapper}
                      onChange={onToggleFilterHasComments}
                      title="Has comments"
                      value={hasComments}
                      size={15}
                      titlePosition="right"
                    />
                  </div>
                </div>
                <TestRecordsFilter pane={pane} />
                <div className={classNames(filterStyle.filterSection, { [filterStyle.disabled]: !publishedClustersExist })}>
                  <div className={filterStyle.filterPanelHeader}>
                    <BulletedTooltipTrigger
                      placement="right"
                      items={publishedClustersExist ? List() : List.of('Published clusters must exist before filtering based on cluster membership changes')}
                    >
                      <span className={filterStyle.clusterChangesTitle}>Record changes from last publish&nbsp;
                        <BulletedTooltipTrigger
                          placement="right"
                          items={publishedClustersExist ? List.of('Filter to records that have or do not have cluster membership changes since published clusters were last updated. Changes do not include record value changes made from updating the the Unified Dataset.') : List()}
                        >
                          <TamrIcon className={filterStyle.help} iconName="info-outline" size={14} />
                        </BulletedTooltipTrigger>
                      </span>
                    </BulletedTooltipTrigger>
                  </div>
                  <div className={filterStyle.option}>
                    <Checkbox
                      className={filterStyle.checkboxWrapper}
                      onChange={() => onToggleRecordChangesFilter(NEW_RECORD)}
                      title={<span>New <Term>records</Term> added from updated sources</span>}
                      value={recordChanges.has(NEW_RECORD)}
                      size={15}
                      titlePosition="right"
                      disabled={!publishedClustersExist}
                    />
                  </div>
                  <div className={filterStyle.option}>
                    <Checkbox
                      className={filterStyle.checkboxWrapper}
                      onChange={() => onToggleRecordChangesFilter(CHANGED_CLUSTER)}
                      title={<span><Term>Records</Term> moved between clusters</span>}
                      value={recordChanges.has(CHANGED_CLUSTER)}
                      size={15}
                      titlePosition="right"
                      disabled={!publishedClustersExist}
                    />
                  </div>
                  <div className={filterStyle.option}>
                    <Checkbox
                      className={filterStyle.checkboxWrapper}
                      onChange={() => onToggleRecordChangesFilter(UNCHANGED_CLUSTER)}
                      title={<span><Term>Records</Term> stayed in current clusters</span>}
                      value={recordChanges.has(UNCHANGED_CLUSTER)}
                      size={15}
                      titlePosition="right"
                      disabled={!publishedClustersExist}
                    />
                  </div>
                  <div className={filterStyle.option}>
                    <Checkbox
                      className={filterStyle.checkboxWrapper}
                      onChange={() => onToggleRecordChangesFilter(DELETED_RECORD)}
                      title={<span><Term>Records</Term> deleted from updated sources</span>}
                      value={recordChanges.has(DELETED_RECORD)}
                      size={15}
                      titlePosition="right"
                      disabled={!publishedClustersExist}
                    />
                  </div>
                </div>
                <ClusterRecordSourceDatasetFilter pane={pane} onHideDialog={() => this.filterPanel.current.attachHideListener()} onOpenDialog={() => this.filterPanel.current.removeHideListener()} />
              </div>
            }
          >
            {content}
          </PopoverTrigger>
        )}
      />
    );
  }
}

ClusterRecordsFilters.propTypes = {
  pane: PropTypes.string.isRequired,
};
