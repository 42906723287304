import React from 'react';

import Chrome from '../chrome/Chrome';
import GroupByPage from '../groupby/GroupByPage';

/**
 * A page layout for configuring a new derived dataset using Group By functions on an existing
 * dataset.
 */
class GroupByDatasets extends React.Component {
  render() {
    return (
      <Chrome className="dataset-config-container">
        <GroupByPage />
      </Chrome>
    );
  }
}

export default GroupByDatasets;
