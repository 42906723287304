import enumify from '../constants/enumify';

export enum NotificationTypeE {
  CLUSTER_CHANGED = 'CLUSTER_CHANGED',
  CLUSTER_MERGE = 'CLUSTER_MERGE',
  CLUSTER_MOVE_RECORDS = 'CLUSTER_MOVE_RECORDS',
  CLUSTER_MOVE_RECORDS_TO_NEW = 'CLUSTER_MOVE_RECORDS_TO_NEW',
  DISCONNECTED = 'DISCONNECTED',
  TRANSFORMATION_LIST_UPDATE = 'TRANSFORMATION_LIST_UPDATE',
  CoreConnect_JOB_SUBMITTED = 'CoreConnect_JOB_SUBMITTED',
}
export default enumify(NotificationTypeE);
