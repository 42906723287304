/* eslint-disable react/no-danger */

import classNames from 'classnames';
import { TableRowEventHandler } from 'fixed-data-table-2';
import { is, List, Record, Set } from 'immutable';
import React from 'react';
import { DragSource as dragSource, DragSourceConnector, DropTarget as dropTarget, DropTargetConnector, DropTargetMonitor } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import { onlyUpdateForKeys, withState } from 'recompose';
import { bindActionCreators } from 'redux';
import _ from 'underscore';

import Button from '../components/Button';
import CheckboxColumn from '../components/CheckboxColumn';
import SortableHeaderCell from '../components/SortableHeaderCell';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Pager from '../components/Table/PagerModel';
import Table from '../components/Table/Table';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import TooltipTrigger from '../components/TooltipTrigger';
import withKeyState, { InjectedKeyStateProps } from '../components/WithKeyState';
import { TRAIN_PREDICT_CLUSTER } from '../constants/RecipeOperations';
import KeyMods from '../models/KeyMods';
import { AppAction, AppThunkDispatch } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { isCuratorByProjectId, isVerifierByProjectId } from '../utils/Authorization';
import { commafy, commafyMoney, shortFormat, shortMoney } from '../utils/Numbers';
import { getActiveSpendField, getAuthorizedUser, isJobRunning, selectActiveProjectInfo } from '../utils/Selectors';
import { pluralize } from '../utils/Strings';
import { getTerm } from '../utils/Terms';
import { isDefined } from '../utils/Values';
import { CopyClusterIdIconButton, TwoPanesIconButton } from './ClusterCard';
import styles from './ClusterSelectorTable.module.scss';
import { PaneE } from './Pane';
import {
  fetchInitialGeospatialTransactions, getColumnSettings, HIGH_IMPACT_COL_NAME, moveSelectedRecords, NAME_COL_NAME, onlyClusterSelected,
  selectAllClusters,
  selectCluster, setColumnWidth, SPEND_COL_NAME,
} from './SuppliersAsync';
import SupplierSelectorPagerContent from './SupplierSelectorPagerContent';
import { getLastSelectClusterIndex, getSelectedIndexes } from './SuppliersStore';
import VerificationIcon, { VERIFICATION_ICON_TYPE_VERIFIED_ELSEWHERE, VERIFICATION_ICON_TYPE_VERIFIED_HERE } from './VerificationIcon';
import { VerificationTypeE } from './VerificationType';

class HoverId extends Record({ clusterId: '' as string, key: '' as string }) {}

const handleDragConnection = (connector: DragSourceConnector) => {
  return { connectDragPreview: connector.dragPreview(), connectDragSource: connector.dragSource() };
};

type HandleProps = ReturnType<typeof handleDragConnection>;

const Handle = _.compose(
  connect(null, (dispatch, { pane }: { pane: PaneE }) => bindActionCreators({
    onStartDrag: (clusterId: string): AppAction => ({ type: 'Suppliers.startDragCluster', clusterId, pane }),
  }, dispatch)),
  dragSource('cluster', {
    beginDrag: ({ id, onStartDrag, pane }) => {
      onStartDrag(id);
      return { id, pane };
    },
  }, connector => {
    return { connectDragPreview: connector.dragPreview(), connectDragSource: connector.dragSource() };
  }),
)(class SupplierSelectorTableHandle extends React.Component<HandleProps> {
  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage());
  }

  render() {
    const { connectDragSource } = this.props;
    return connectDragSource(
      <div className="supplier-dnd-handle">
        <TamrIcon iconName="drag-handle" size={14} />
      </div>,
    );
  }
});

const dropTargetConnection = (connector: DropTargetConnector, monitor: DropTargetMonitor) => {
  const item = monitor.getItem();
  return {
    connectDropTarget: connector.dropTarget(),
    isOver: monitor.isOver() && monitor.canDrop(),
    dragging: monitor.getItemType() === 'cluster',
    // @ts-ignore
    dragPane: item && item.pane,
  };
};

interface DropTargetOwnProps {
  clusterId: string
  updateHoverState: (updater: (current: Set<HoverId>) => Set<HoverId>) => void
  hoverId: HoverId
  selected: boolean
  present: boolean
  pane: PaneE
}

type DropTargetProps = DropTargetOwnProps & ReturnType<typeof dropTargetConnection>

const DropTarget = _.compose(
  connect((state: AppState, { clusterId, pane }: { clusterId: string, pane: PaneE }) => {
    const { suppliers: { [pane]: { selectedSuppliers } } } = state;
    return { selected: selectedSuppliers.has(clusterId) };
  }, (dispatch, { pane }) => bindActionCreators({
    onBeginConfirmingMoveRecord: (fromPane, clusterId) => ({ type: 'Suppliers.beginConfirmingMoveRecords', pane, fromPane, clusterId }),
    onBeginConfirmingMergeClustersToTarget: (fromPane, targetClusterId) => ({ type: 'Suppliers.beginConfirmingMergeSelectedClustersToTarget', pane, fromPane, targetClusterId }),
  }, dispatch)),
  dropTarget(['cluster', 'record'], {
    canDrop: ({ clusterId }, monitor) => {
      return monitor.getItemType() === 'record' || monitor.getItem().id !== clusterId;
    },
    drop: ({ clusterId, onBeginConfirmingMoveRecord, onBeginConfirmingMergeClustersToTarget }, monitor) => {
      const item = monitor.getItem();
      if (monitor.getItemType() === 'record') {
        onBeginConfirmingMoveRecord(item.pane, clusterId);
      } else {
        onBeginConfirmingMergeClustersToTarget(item.pane, clusterId);
      }
    },
  }, (connector, monitor) => {
    const item = monitor.getItem();
    return {
      connectDropTarget: connector.dropTarget(),
      isOver: monitor.isOver() && monitor.canDrop(),
      dragging: monitor.getItemType() === 'cluster',
      // @ts-ignore
      dragPane: item && item.pane,
    };
  }),
  onlyUpdateForKeys(['isOver', 'present', 'children', 'dragging', 'selected']),
)(class SupplierSelectorTableDropTarget extends React.Component<DropTargetProps> {
  componentDidUpdate() {
    const { isOver, present, hoverId, updateHoverState } = this.props;
    if (isOver && !present) {
      updateHoverState(s => s.add(hoverId));
    } else if (!isOver && present) {
      updateHoverState(s => s.delete(hoverId));
    }
  }

  render() {
    const { connectDropTarget, children, dragging, selected, dragPane, pane } = this.props;
    return connectDropTarget(
      <div className={classNames('supplier-dnd-wrapper', { faded: selected && dragging && dragPane === pane })}>{children}</div>,
    );
  }
});

interface SupplierSelectorTableOwnProps {
  pane: PaneE
}

const mapStateToProps = (state: AppState, { pane }: SupplierSelectorTableOwnProps) => {
  const {
    suppliers,
    suppliers: {
      [pane]: {
        changedClusterNames,
        data,
        lastFetchData,
        loading,
        pageNum,
        pendingResolutionRowNumbers,
        queryString,
        rows,
        selectedRecordIds,
        suppliersFilter,
        selectedSuppliers,
        total,
      },
      geospatial: { openMap, activeGeospatialRenderingAttribute },
      twoPanes,
    },
    location: { recipeId },
  } = state;
  const projectInfo = selectActiveProjectInfo(state);
  return {
    isUserACurator: isCuratorByProjectId(getAuthorizedUser(state), projectInfo?.projectDoc.id.id),
    isUserAVerifier: isVerifierByProjectId(getAuthorizedUser(state), projectInfo?.projectDoc.id.id),
    selectedIndexes: getSelectedIndexes(suppliers, pane),
    lastSelectedSupplierRow: getLastSelectClusterIndex(suppliers, pane),
    anyTransactionsSelected: !selectedRecordIds.isEmpty(),
    data,
    pageNum,
    loading,
    total,
    spendTerm: getTerm(state, 'Spend'),
    recordTerm: getTerm(state, 'Record'),
    recordsTerm: getTerm(state, 'Records'),
    pendingResolutionRowNumbers,
    anyChange: !is(data, lastFetchData),
    changedClusterNames,
    columnSettings: getColumnSettings(state),
    isSpendConfigured: !!getActiveSpendField(state),
    recipeId,
    suggestionRunning: isJobRunning(state, { recipeOperation: TRAIN_PREDICT_CLUSTER, forActiveProject: true }),
    suppliersFilter: !!suppliersFilter,
    selectedSuppliers,
    openMap,
    activeGeospatialRenderingAttribute,
    twoPanes,
    rows,
    searchActive: !!queryString,
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch, { pane }: SupplierSelectorTableOwnProps) => bindActionCreators({
  onSetSuppliersPage: (pageNum: number): AppAction => ({ type: 'Suppliers.setSuppliersPage', pageNum, pane }),
  onSelectCluster: (keyMods: KeyMods, rowNum: number) => selectCluster(keyMods, rowNum, pane),
  onSelectAllClusters: () => selectAllClusters(pane),
  onClearSuppliersFilter: (): AppAction => ({ type: 'Suppliers.clearSuppliersFilter', pane }),
  onOpenClusterBrowser: (clusterId: string): AppAction => ({ type: 'Suppliers.openClusterBrowser', clusterId, pane }),
  onOpenMap: (keyMods: KeyMods, rowNum: number, clusterId: string) => fetchInitialGeospatialTransactions(keyMods, rowNum, clusterId, pane),
  onCloseMap: (): AppAction => ({ type: 'Suppliers.closeMap' }),
  onMoveRecords: (targetClusterId: string, verificationType: VerificationTypeE) => moveSelectedRecords(pane, pane, targetClusterId, verificationType),
  onSetColumnWidth: setColumnWidth,
}, dispatch);

type SupplierSelectorTableProps
  = SupplierSelectorTableOwnProps
  & InjectedKeyStateProps
  & { hoverState: Set<HoverId>, updateHoverState: (updater: (current: Set<HoverId>) => Set<HoverId>) => void }
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>

const SupplierSelectorTable = _.compose(
  withKeyState,
  withState('hoverState', 'updateHoverState', Set<HoverId>()),
  connect(mapStateToProps, mapDispatchToProps),
)(class SupplierSelectorTable extends React.Component<SupplierSelectorTableProps> {
  renderHandleColumn = () => {
    const { data, isUserACurator, isUserAVerifier, pane } = this.props;
    if (!(isUserACurator || isUserAVerifier)) {
      return;
    }
    const cell = ({ rowIndex }: { rowIndex: number }) => {
      const row = data.get(rowIndex);
      if (!row) {
        return <Cell />;
      }
      const { clusterId } = row;
      return (
        <DropTarget {...this.getDropTargetProps('handle', clusterId)}>
          <Handle id={clusterId} pane={pane} />
        </DropTarget>
      );
    };
    return (
      <Column key="handle" columnKey="handle" width={24} header={<Cell />} cell={cell} fixed />
    );
  };

  renderCheckboxColumn = () => {
    const { selectedIndexes, data, onSelectCluster, onSelectAllClusters } = this.props;
    const isSelected = (index: number) => selectedIndexes.has(index);
    return CheckboxColumn({
      selected: isSelected,
      allSelected: selectedIndexes.size === data.size,
      onToggle: (index, selected, e) => onSelectCluster(KeyMods.fromJSON(e).enableToggle(), index),
      onToggleAll: onSelectAllClusters,
    });
  };

  renderNameColumn = () => {
    const { data, changedClusterNames, columnSettings, onOpenClusterBrowser, onOpenMap, onCloseMap, pane, recordTerm, recordsTerm, isSpendConfigured, searchActive, openMap, twoPanes, activeGeospatialRenderingAttribute, selectedSuppliers } = this.props;
    const cell = ({ rowIndex }: { rowIndex: number }) => {
      const row = data.get(rowIndex);
      if (!row) {
        return <Cell />;
      }
      const { name, clusterNameHighlight, publishedName, clusterId, clusterVerificationCounts, recordCount, matchingRecordsCount } = row;
      const nameToUse = name
        ? clusterNameHighlight === '' ? name : <span dangerouslySetInnerHTML={{ __html: clusterNameHighlight }} />
        : publishedName || <span className="empty-cluster-name">No cluster name</span>;
      const oldName = changedClusterNames.get(clusterId);
      const changed = changedClusterNames.has(clusterId)
        ? <span className="changed-name-label" title={`name changed from ${oldName}`}>was <span className="cluster-name">{oldName}</span></span>
        : null;
      const totalNumVerifiedHere = clusterVerificationCounts?.totalNumVerifiedHere || 0;
      const totalNumVerifiedElsewhere = clusterVerificationCounts?.totalNumVerifiedElsewhere || 0;
      const detailTooltipAggregationMsg = `${totalNumVerifiedHere > 0 ? '. ' + commafy(totalNumVerifiedHere) + ' verified in current cluster' : ''}`
        + `${totalNumVerifiedElsewhere > 0 ? '. ' + commafy(totalNumVerifiedElsewhere) + ' verified in another cluster' : ''}`;
      const detailTooltip = `${commafy(recordCount)} ${pluralize(recordCount, recordTerm, recordsTerm)}${detailTooltipAggregationMsg}`;
      const clusterSelectedWithMapOpen = onlyClusterSelected(selectedSuppliers, clusterId) && openMap;
      return (
        <DropTarget {...this.getDropTargetProps('name', clusterId)}>
          <div className={classNames('supplier-name-cell', { 'changed-name': !!changed })}>
            <div className="supplier-info-section">
              <div className="supplier-name-header">
                <TooltipTrigger content={clusterId} placement="right">
                  <span className="supplier-name">{nameToUse} {changed}</span>
                </TooltipTrigger>
              </div>
              <TooltipTrigger content={detailTooltip} placement="top">
                <div className="supplier-name-details">
                  <div className="ellipse-wrapper">
                    <span className="record-count">
                      {searchActive
                        ? <span>{shortFormat(matchingRecordsCount)} of {shortFormat(recordCount)} matching <Term>records</Term></span>
                        : `${shortFormat(recordCount)} ${pluralize(recordCount, recordTerm, recordsTerm)}`
                      }
                    </span>
                    <span className="verification-counts">
                      {totalNumVerifiedHere > 0 || totalNumVerifiedElsewhere > 0 ? (
                        <span>
                          {totalNumVerifiedHere > 0 && (
                            <React.Fragment>
                              <span className="verification-counts-separator">&middot;</span>
                              <VerificationIcon className="verification-icon" verificationIconType={VERIFICATION_ICON_TYPE_VERIFIED_HERE} size={12} />
                              <span>{shortFormat(totalNumVerifiedHere)}</span>
                            </React.Fragment>
                          )}
                          {totalNumVerifiedElsewhere > 0 && (
                            <React.Fragment>
                              <span className="verification-counts-separator">&middot;</span>
                              <VerificationIcon className="verification-icon" verificationIconType={VERIFICATION_ICON_TYPE_VERIFIED_ELSEWHERE} size={12} />
                              <span>{shortFormat(totalNumVerifiedElsewhere)}</span>
                            </React.Fragment>
                          )}
                        </span>
                      ) : null}
                    </span>
                  </div>
                </div>
              </TooltipTrigger>
            </div>
            { !!activeGeospatialRenderingAttribute && (
              <TooltipTrigger
                placement="bottom"
                content={<span>
                  {twoPanes
                    ? 'Cannot open map view when two panes are open'
                    : clusterSelectedWithMapOpen
                      ? 'Close map view for geospatial data.'
                      : 'Open map view for geospatial data.'
                  }</span>}>
                <Button
                  buttonType="Link"
                  disabled={twoPanes}
                  onClick={e => {
                    e.stopPropagation();
                    if (clusterSelectedWithMapOpen) {
                      onCloseMap();
                    } else {
                      onOpenMap(KeyMods.fromJSON(e), rowIndex, clusterId);
                    }
                  }}
                  className={classNames('supplier-pane-button', clusterSelectedWithMapOpen ? 'active' : null)}
                >
                  <TamrIcon iconName="map" size={16} />
                </Button>
              </TooltipTrigger>
            )}
            <TwoPanesIconButton className="supplier-pane-button" focusedPane={pane} mapIsOpen={openMap} tooltipPlacement="right" onOpenClusterInOtherPane={() => onOpenClusterBrowser(clusterId)} />
            <CopyClusterIdIconButton className="supplier-pane-button" clusterId={clusterId} tooltipPlacement="right" />
          </div>
        </DropTarget>
      );
    };
    return (
      <Column
        columnKey={NAME_COL_NAME}
        key={NAME_COL_NAME}
        width={0}
        flexGrow={columnSettings.get(NAME_COL_NAME)}
        header={this.getHeader('Cluster')}
        cell={cell}
        isResizable={isSpendConfigured}
      />
    );
  };

  renderSpendColumn = () => {
    const { data, isSpendConfigured, spendTerm, columnSettings } = this.props;
    if (!isSpendConfigured) {
      return undefined;
    }
    const cell = ({ rowIndex }: { rowIndex: number }) => {
      const row = data.get(rowIndex);
      if (!row) {
        return <Cell />;
      }
      const { totalSpend, clusterId } = row;
      const totalSpendOrZero = totalSpend || 0;
      return (
        <DropTarget {...this.getDropTargetProps('spend', clusterId)}>
          <Cell className="right-align">
            { <span title={commafyMoney(totalSpendOrZero)}><Term>currencyChar</Term>{shortMoney(totalSpendOrZero)}</span> }
          </Cell>
        </DropTarget>
      );
    };
    return (
      <Column
        columnKey={SPEND_COL_NAME}
        key={SPEND_COL_NAME}
        align="right"
        width={0}
        flexGrow={columnSettings.get(SPEND_COL_NAME)}
        header={this.getHeader(spendTerm, true)}
        cell={cell}
      />
    );
  };

  renderHighImpactColumn = () => {
    const { data } = this.props;
    const cell = ({ rowIndex }: { rowIndex: number }) => {
      const cluster = data.get(rowIndex);
      if (!cluster) {
        return <Cell />;
      }
      if (cluster.trainingCluster || cluster.testCluster) {
        return <span title="High Impact" className={styles.iconContainer}><TamrIcon iconName="tamr-icon-lightning-bolt" size={16} className={styles.highImpactIcon} /></span>;
      }
      return <Cell />;
    };
    return (
      <Column
        columnKey={HIGH_IMPACT_COL_NAME}
        key={HIGH_IMPACT_COL_NAME}
        width={24}
        header={<Cell />}
        cell={cell}
      />
    );
  };

  onRowClick: TableRowEventHandler = (e, rowNum) => {
    const { onSelectCluster } = this.props;
    if (!window.getSelection()?.toString()) {
      onSelectCluster(KeyMods.fromJSON(e), rowNum);
    }
  };

  getHeader = (displayName: string, rightAlign?: boolean) => {
    return (
      <SortableHeaderCell
        className="supplier-selector-header"
        col={displayName}
        sortState="unsorted"
        sortCallback={() => {}}
        rightAlign={rightAlign}
      />
    );
  };

  rowClassNameGetter = (index: number) => {
    const { pendingResolutionRowNumbers, data, hoverState } = this.props;
    const clusterId = data.get(index)?.clusterId;
    return classNames({
      'pending-resolution': pendingResolutionRowNumbers.includes(index),
      'dnd-hover': hoverState.some(r => isDefined(clusterId) && r?.clusterId === clusterId),
    });
  };

  getDropTargetProps = (key: string, clusterId: string) => {
    const { hoverState, updateHoverState, pane } = this.props;
    const hoverId: HoverId = new HoverId({ clusterId, key });
    return { clusterId, updateHoverState, hoverId, present: hoverState.has(hoverId), pane };
  };

  render() {
    const { pageNum, lastSelectedSupplierRow, total, data, selectedIndexes, onSetSuppliersPage, anyChange, onSetColumnWidth, shiftKey, suppliersFilter, onClearSuppliersFilter, pane, twoPanes } = this.props;
    const pager = new Pager(data, Math.min(total, 10000), pageNum, 50);
    pager.hidePageSize = true;

    return (
      <div className={classNames('supplier-selector-table', { 'no-text-select': shiftKey, 'some-pending-resolution': anyChange, bottom: (pane === 'top' && !twoPanes) || pane === 'bottom' })}>
        {suppliersFilter ?
          <div className="supplier-clear-msg">
            <div>You've filtered to one cluster</div>
            <div><a className="clear-supplier-filter" onClick={onClearSuppliersFilter}>Clear filter</a></div>
          </div>
          : null}
        <AutoSizer>
          {({ width, height }) => (
            <Table
              tableType="stripes"
              getLength={() => data.size}
              height={height}
              width={width}
              rowHeight={60}
              headerHeight={50}
              pageSizes={List.of(50, 100, 500, 1000)}
              pagerState={pager}
              pagerContent={<SupplierSelectorPagerContent pane={pane} />}
              onPageSizeChange={() => null}
              onPageChange={onSetSuppliersPage}
              onRowClick={this.onRowClick}
              selectedRowNumbers={selectedIndexes}
              activeRowNumber={_.isNumber(lastSelectedSupplierRow) ? lastSelectedSupplierRow : -1}
              rowClassNameGetter={this.rowClassNameGetter}
              onColumnResizeEndCallback={onSetColumnWidth}
            >
              {_.compact([
                this.renderHandleColumn(),
                this.renderCheckboxColumn(),
                this.renderNameColumn(),
                this.renderSpendColumn(),
                this.renderHighImpactColumn(),
              ])}
            </Table>
          )}
        </AutoSizer>
      </div>
    );
  }
});

export default SupplierSelectorTable;
