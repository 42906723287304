import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import TamrIcon from '../components/TamrIcon';
import SidebarTabs from '../constants/SidebarTabs';
import { AppState } from '../stores/MainStore';
import { getAuthorizedUser } from '../utils/Selectors';
import RecordPairCell from './RecordPairCell';
import style from './RecordPairSpecialIconsCell.module.scss';


type RecordPairSpecialIconsCellOwnProps = {
  rowIndex: number
}

const RecordPairSpecialIconsCell = connect((state: AppState, { rowIndex }: RecordPairSpecialIconsCellOwnProps) => {
  const { recordPairs: { pairs } } = state;
  const authorizedUser = getAuthorizedUser(state);
  const pair = pairs.get(rowIndex);
  return { pair, loggedInUsername: authorizedUser ? authorizedUser.username : undefined };
}, {
  onClickComment: () => ({ type: 'RecordPairs.showSidebarTab', tabKey: SidebarTabs.ACTIVITY }),
})(({ pair, onClickComment, loggedInUsername }) => {
  if (!pair) {
    return null;
  }
  const hasComments = !pair.comments.isEmpty();
  return (
    <RecordPairCell className={style.component} innerClassName={style.inner}>
      {pair.highImpact ? (
        <span title="High Impact" className={style.wrapper}>
          <TamrIcon iconName="tamr-icon-lightning-bolt" className={style.highImpactIcon} size={16} />
        </span>
      ) : null}
      <span title="Comments" className={style.wrapper}>
        <TamrIcon
          className={classNames(style.commentIcon, { [style.hasComments]: hasComments })}
          iconName="comment"
          size={20}
          onClick={onClickComment}
        />
      </span>
      {pair.assignedToUser(loggedInUsername) ? (
        <span title="Assigned to you" className={style.wrapper}>
          <TamrIcon
            className={style.assignedIcon}
            iconName="tamr-icon-ic_assignment"
            size={16}
          />
        </span>
      ) : null}
    </RecordPairCell>
  );
});

export default RecordPairSpecialIconsCell;
