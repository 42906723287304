// com.tamr.dedup.models.AssignmentStatusFilter.Type

const AssignmentStatusFilterType = {
  ALL: 'ALL',
  SKIP: 'SKIP',
  PENDING: 'PENDING',
  ANSWERED: 'ANSWERED',
} as const;
export type AssignmentStatusFilterTypeE = typeof AssignmentStatusFilterType[keyof typeof AssignmentStatusFilterType];

export default AssignmentStatusFilterType;
