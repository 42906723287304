import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { safeDivide } from '../utils/Numbers';

// Abandon all hope ye who enter here
class ConfusionMatrix {
  constructor(
    truePositives,
    trueNegatives,
    falsePositives,
    falseNegatives,
  ) {
    checkArg({ truePositives }, ArgTypes.number);
    checkArg({ trueNegatives }, ArgTypes.number);
    checkArg({ falsePositives }, ArgTypes.number);
    checkArg({ falseNegatives }, ArgTypes.number);
    this._tp = truePositives;
    this._tn = trueNegatives;
    this._fp = falsePositives;
    this._fn = falseNegatives;
  }

  isEmpty() {
    return (this.agreements + this.disagreements) === 0;
  }

  get truePositives() {
    return this._tp;
  }

  get trueNegatives() {
    return this._tn;
  }

  get falsePositives() {
    return this._fp;
  }

  get falseNegatives() {
    return this._fn;
  }

  get total() {
    return this.truePositives + this.trueNegatives + this.falsePositives + this.falseNegatives;
  }

  get agreements() {
    return this._tp + this._tn;
  }

  get disagreements() {
    return this._fp + this._fn;
  }

  get precision() {
    return safeDivide(this._tp, this._tp + this._fp);
  }

  get recall() {
    return safeDivide(this._tp, this._tp + this._fn);
  }

  get accuracy() {
    return safeDivide(this.agreements, this.agreements + this.disagreements);
  }

  get f1() {
    return safeDivide(2 * this.precision * this.recall, this.precision + this.recall);
  }

  static fromJSON(obj) {
    checkArg({ obj }, ArgTypes.object);
    return new ConfusionMatrix(
      obj.truePositives,
      obj.trueNegatives,
      obj.falsePositives,
      obj.falseNegatives,
    );
  }
}

export default ConfusionMatrix;
