import { Range } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import style from './SchemaMappingDialogTable.module.scss';

// displays a 2-column table, meant to be used in explanatory dialogs on the SchemaMapping page
const SchemaMappingDialogTable = ({ header1, header2, numRows, rowRenderer }) => {
  return (
    <table className={style.table}>
      <tbody>
        <tr className={style.header}>
          <th className={style.col1}>{header1}</th>
          <th className={style.col2}>{header2}</th>
        </tr>
        {Range(0, numRows).map(rowIndex => {
          const [cell1, cell2] = rowRenderer(rowIndex);
          return (
            <tr key={`row-${rowIndex}`} className={style.tableBody}>
              <td className={style.col1}>{cell1}</td>
              <td className={style.col2}>{cell2}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
SchemaMappingDialogTable.propTypes = {
  header1: PropTypes.node.isRequired,
  header2: PropTypes.node.isRequired,
  numRows: PropTypes.number.isRequired,
  rowRenderer: PropTypes.func.isRequired,
};

export default SchemaMappingDialogTable;
