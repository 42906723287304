import { getSSOEnabled } from '../api/ProcurifyClient';
import { AppThunkAction } from '../stores/AppAction';
import { saveUserPreference } from '../users/UsersAsync';
import { AUTH_COMPLETED, AUTH_FAILED } from './SSOActionTypes';

export const auth = (): AppThunkAction<void> => (dispatch, getState) => {
  getSSOEnabled()
    .then(ssoEnabled => {
      dispatch({ type: AUTH_COMPLETED, ssoEnabled });
      saveUserPreference()(dispatch, getState, undefined);
    })
    .catch(() => dispatch({ type: AUTH_FAILED }));
};
