import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import { ArgTypes } from '../utils/ArgValidation';
import VerificationType, { VerificationTypeE } from './VerificationType';

// com.tamr.dedup.models.ClusterMember

class ClusterMember extends getModelHelpers({
  recordId: { type: ArgTypes.string },
  verifiedClusterId: { type: ArgTypes.nullable(ArgTypes.string) },
  verificationType: { type: ArgTypes.nullable(VerificationType.argType) },
  username: { type: ArgTypes.nullable(ArgTypes.string) },
  timestamp: { type: ArgTypes.nullable(ArgTypes.timestamp) },
  testRecord: { type: ArgTypes.nullable(ArgTypes.bool) },
}, 'ClusterMember')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ClusterMemberRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: any) {
    return new ClusterMember(obj);
  }

  get verifiedThenUnverified(): boolean {
    return this.verificationType === VerificationTypeE.UNVERIFIED && !!this.verifiedClusterId;
  }
}

export default ClusterMember;
