import { Set } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import DatasetSelectionDialog from '../datasets/DatasetSelectionDialog';
import {
  SET_EXCLUDE_DATASET_SEARCH_VALUE,
  SET_EXCLUDE_DATASET_SELECTION_DELTA,
  SET_EXCLUDED_DATASETS,
  TOGGLE_EXCLUDE_DATASET_SELECTOR,
} from './PregroupActionTypes';
import style from './PregroupHeader.module.scss';
import { pluralize } from '../utils/Strings';

const ExcludeDatasetsFromGrouping = _.compose(
  connect(({
    allSourceDatasets: { datasets },
    sourceDatasetNames = datasets.map(d => d.data.name).toSet(),
    pregroup: { excludedDatasetNames, showExcludeDatasetSelector, excludeDatasetSearchValue, excludeDatasetSelectionDelta, isGroupingEnabled },
  }) => {
    return { datasets, excludeDatasetSearchValue, excludeDatasetSelectionDelta, excludedDatasetNames, showExcludeDatasetSelector, sourceDatasetNames, isGroupingEnabled };
  }, {
    onSetExcludedDatasets: (excludedDatasetNames) => ({ type: SET_EXCLUDED_DATASETS, excludedDatasetNames }),
    toggleDatasetSelector: () => ({ type: TOGGLE_EXCLUDE_DATASET_SELECTOR }),
    onHide: () => ({ type: TOGGLE_EXCLUDE_DATASET_SELECTOR }),
    setSelectionDelta: (newDeltaMap) => ({ type: SET_EXCLUDE_DATASET_SELECTION_DELTA, newDeltaMap }),
    setSearchValue: (excludeDatasetSearchValue) => ({ type: SET_EXCLUDE_DATASET_SEARCH_VALUE, excludeDatasetSearchValue }),
  }),
)(class ExcludeDatasetsFromGrouping extends React.Component {
  static propTypes = {
    datasets: ImmutablePropTypes.listOf(Document.propType.withDataType(Dataset)).isRequired,
    excludeDatasetSearchValue: PropTypes.string.isRequired,
    excludeDatasetSelectionDelta: ImmutablePropTypes.mapOf(PropTypes.bool, PropTypes.string).isRequired,
    excludedDatasetNames: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
    isGroupingEnabled: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onSetExcludedDatasets: PropTypes.func.isRequired,
    setSearchValue: PropTypes.func.isRequired,
    setSelectionDelta: PropTypes.func.isRequired,
    showExcludeDatasetSelector: PropTypes.bool.isRequired,
    sourceDatasetNames: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
    toggleDatasetSelector: PropTypes.func.isRequired,
  };

  isDatasetValid = (datasetName) => {
    const { sourceDatasetNames } = this.props;
    return sourceDatasetNames.contains(datasetName);
  };

  getExcludedDatasets = () => {
    const excluded = this.props.excludedDatasetNames;
    return excluded.filter(this.isDatasetValid);
  };
  setExcludedDatasets = (delta) => {
    const datasets = this.getExcludedDatasets();

    const toAdd = delta.filter(present => present)
      .keySeq().toSet();

    const toDel = delta.filter(present => !present)
      .keySeq().toSet();

    const newDatasets = datasets.union(toAdd).subtract(toDel);
    this.props.onSetExcludedDatasets(newDatasets);
  };

  renderDatasetDialog = () => {
    return (
      <DatasetSelectionDialog
        onSubmit={this.setExcludedDatasets}
        onHide={this.props.onHide}
        showDialog={this.props.showExcludeDatasetSelector}
        searchValue={this.props.excludeDatasetSearchValue}
        datasetSelectionDelta={this.props.excludeDatasetSelectionDelta}
        setSelectionDelta={this.props.setSelectionDelta}
        setSearchValue={this.props.setSearchValue}
        selectedDatasets={this.props.excludedDatasetNames == null ? Set() : this.props.excludedDatasetNames}
      />
    );
  };

  renderDatasetSelectorPanel = () => {
    const numExcludedDatasets = this.props.excludedDatasetNames == null ? 0 : this.props.excludedDatasetNames.size;
    const warning = pluralize(numExcludedDatasets, `${numExcludedDatasets} dataset is currently excluded.`, `${numExcludedDatasets} datasets are currently excluded.`);
    return (
      this.props.isGroupingEnabled ?
        (<div className={style.subtitleText}>
          <a onClick={this.props.toggleDatasetSelector}>Choose</a>
          <span> which datasets to exclude from record grouping. <i>{warning}</i></span>
        </div>)
        : null

    );
  };

  render() {
    return (
      <div>
        {this.renderDatasetDialog()}
        {this.renderDatasetSelectorPanel()}
      </div>
    );
  }
});

export default ExcludeDatasetsFromGrouping;
