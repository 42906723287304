import { is, OrderedSet, Set } from 'immutable';
import _ from 'underscore';

import KeyMods from '../models/KeyMods';
import { ArgTypes, checkArg } from './ArgValidation';
import { sliceBounds } from './Values';

export const keyModSelect = ({
  keyMods,
  selectedRows,
  lastSelectedRow,
  selectedRow,
}: {
  keyMods: KeyMods,
  selectedRows: Set<number>,
  lastSelectedRow: number | null | undefined,
  selectedRow: number
}): {
  selectedRows: Set<number>,
  lastSelectedRow: number,
} => {
  checkArg({ keyMods }, ArgTypes.instanceOf(KeyMods));
  checkArg({ selectedRows }, ArgTypes.Immutable.set.of(ArgTypes.number));
  checkArg({ lastSelectedRow }, ArgTypes.nullable(ArgTypes.number));
  checkArg({ selectedRow }, ArgTypes.number);
  if (keyMods.shiftKey && lastSelectedRow && lastSelectedRow !== -1) {
    // Handle bulk multi-select
    const bounds = sliceBounds(selectedRow, lastSelectedRow);
    return { selectedRows: selectedRows.union(_.range(bounds[0], bounds[1])), lastSelectedRow: selectedRow };
  } if (keyMods.toggleKey) {
    // Handle additive select single select & update last selected row
    if (selectedRows.has(selectedRow)) {
      return { selectedRows: selectedRows.delete(selectedRow), lastSelectedRow: selectedRow };
    }
    return { selectedRows: selectedRows.add(selectedRow), lastSelectedRow: selectedRow };
  } if (is(selectedRows, Set([selectedRow]))) {
    // Handle click to deselect only row
    return { selectedRows: OrderedSet(), lastSelectedRow: selectedRow };
  }
  // Handle single select & update last selected row
  return { selectedRows: OrderedSet([selectedRow]), lastSelectedRow: selectedRow };
};
