import classNames from 'classnames';
import { List } from 'immutable';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  ProjectPolicyManager,
  getPolicyById,
} from '../accessControl/AccessControlStore';
import { UserGroupsCount, UsersCount } from '../accessControl/Count';
import { datasetString, resourcePolicyString } from '../accessControl/PoliciesList';
import Button from '../components/Button';
import SplitGrid, { Column } from '../components/SplitGrid';
import TooltipTrigger from '../components/TooltipTrigger';
import AuthorizationPolicy from '../models/AuthorizationPolicy';
import Document from '../models/doc/Document';
import { AppState } from '../stores/MainStore';
import { pluralize } from '../utils/Strings';
import style from './ProjectPoliciesSelector.module.scss';

// Placeholder for name and id of projects that are being created
export const FUTURE_PROJECT_NAME = '-1';
export const FUTURE_PROJECT_ID = -1;

const projectInDraftPolicy = (policyId: number, policy: AuthorizationPolicy, projectDraftPolicyManager: ProjectPolicyManager) => {
  return projectDraftPolicyManager.draftPolicyResourceship.includes(policyId)
    || projectDraftPolicyManager.draftPolicyMembership.includes(policyId)
    // draft cannot update "applies to all" so we check those from the current policy
    || policy.appliesToAllMemberProjects()
    || policy.appliesToAllResourceProjects();
};

const getSplitIds = (getInPolicyIds: boolean, policyDocs: List<Document<AuthorizationPolicy>>, projectId: number) => {
  return policyDocs
    .filter(doc => {
      const isProjectInPolicy = (doc.data.hasProjectAsResource({ projectId })
        || doc.data.hasProjectAsMember({ projectId })
        || doc.data.appliesToAllMemberProjects()
        || doc.data.appliesToAllResourceProjects());
      return (getInPolicyIds) ? isProjectInPolicy : !isProjectInPolicy;
    })
    .sort((doc1, doc2) => doc1.data.name.localeCompare(doc2.data.name))
    .map(doc => doc.id.id);
};

const ProjectPoliciesSelectorPropTypes = {
  query: PropTypes.string,
  projectName: PropTypes.string,
  projectId: PropTypes.number,
  rowClassName: PropTypes.string,
  headerClassName: PropTypes.string,
};

type ProjectPoliciesSelectorOwnProps = InferProps<typeof ProjectPoliciesSelectorPropTypes>;

const ProjectPoliciesSelector = connect((state: AppState, { projectName, projectId, query, rowClassName, headerClassName }: ProjectPoliciesSelectorOwnProps) => {
  const { accessControl, accessControl: { policyDocs, projectDraftPolicyManager, loadingPolicies } } = state;
  return {
    policyDocs: query ? policyDocs.filter(p => p.data.name.includes(query) || p.data.description?.includes(query)) : policyDocs,
    loadingPolicies,
    projectDraftPolicyManager,
    policyById: getPolicyById(accessControl),
    projectName,
    projectId,
    rowClassName,
    headerClassName,
  };
}, {
  onAddToPolicyAsResource: (policyId: number) => ({ type: 'Projects.addToPolicyResourceship', policyId }),
  onAddToPolicyAsMember: (policyId: number) => ({ type: 'Projects.addToPolicyMembership', policyId }),
  onRemoveFromPolicyAsResource: (policyId: number) => ({ type: 'Projects.removeFromPolicyResourceship', policyId }),
  onRemoveFromPolicyAsMember: (policyId: number) => ({ type: 'Projects.removeFromPolicyMembership', policyId }),
  onStartEditing: (projectId: number, projectName: string) => ({ type: 'Projects.startEditingPolicies', projectId, projectName }),
  onStopEditing: () => ({ type: 'Projects.stopEditingPolicies' }),
})((
  { onAddToPolicyAsResource, onAddToPolicyAsMember, onRemoveFromPolicyAsResource, onRemoveFromPolicyAsMember, policyDocs, projectDraftPolicyManager, policyById, onStartEditing, projectName, projectId, rowClassName, headerClassName }) => {
  // If editing hasn't been initiated in the store yet, initiate it
  if (!projectDraftPolicyManager) {
    if (projectId && projectName) {
      onStartEditing(projectId, projectName);
    }
    return null;
  }
  return (
    <div>
      <SplitGrid
        topIds={getSplitIds(true, policyDocs, projectDraftPolicyManager.projectId)}
        bottomIds={getSplitIds(false, policyDocs, projectDraftPolicyManager.projectId)}
        className={classNames(style.grid)}
        cellClassNameGetter={({ id }) => {
          const policy = policyById(id);
          if (!policy) {
            return null;
          }
          return !projectInDraftPolicy(id, policy, projectDraftPolicyManager)
            ? style.notInPolicy
            : null;
        }}
        >
        <Column
          topIds={getSplitIds(true, policyDocs, projectDraftPolicyManager.projectId)}
          bottomIds={getSplitIds(false, policyDocs, projectDraftPolicyManager.projectId)}
          className={classNames(style.idColumn, rowClassName)}
          headerClassName={headerClassName}
          renderHeader={() => 'ID'}
          renderCell={({ id }) => {
            const policy = policyById(id);
            return !policy ? null : (
              <div className={style.idCell}>
                <div className={classNames(style.idValue)}>
                  {id}
                </div>
              </div>
            );
          }}
        />
        <Column
          topIds={getSplitIds(true, policyDocs, projectDraftPolicyManager.projectId)}
          bottomIds={getSplitIds(false, policyDocs, projectDraftPolicyManager.projectId)}
          className={classNames(style.nameColumn, rowClassName)}
          headerClassName={headerClassName}
          renderHeader={() => 'Policy Name'}
          renderCell={({ id }) => {
            const policy = policyById(id);
            return !policy ? null : (
              <div className={style.nameCell}>
                <TooltipTrigger
                  placement="left"
                  content={policy.name}>
                  <div className={classNames(style.name)}>
                    {policy.name}
                  </div>
                </TooltipTrigger>
                <div className={classNames(style.description)}>
                  {policy.description}
                </div>
              </div>
            );
          }}
          />
        <Column
          topIds={getSplitIds(true, policyDocs, projectDraftPolicyManager.projectId)}
          bottomIds={getSplitIds(false, policyDocs, projectDraftPolicyManager.projectId)}
          className={classNames(style.membersColumn, rowClassName)}
          headerClassName={headerClassName}
          renderHeader={() => 'Members'}
          renderCell={({ id }) => {
            const policy = policyById(id);
            if (policy == null) {
              return null;
            }
            const originallyIncluded = projectId ? policy.projectsAsMemberIds().includes(projectId) : false;
            const projectIncluded = projectDraftPolicyManager.draftPolicyMembership.includes(id);

            // Adjust the number of projects based If the project was a member, but no longer is, adjust the number of projects for the policy.
            let numProjects = policy.projectsAsMemberIds().size;
            numProjects = originallyIncluded && !projectIncluded ? numProjects - 1 : numProjects;
            numProjects = projectIncluded && !originallyIncluded ? numProjects + 1 : numProjects;
            const projectContent = policy.appliesToAllMemberProjects() ?
              <span className={style.included}>All projects</span> :
              <span className={projectIncluded ? style.included : ''}>{numProjects} {pluralize(numProjects, 'project', 'projects')}</span>;
            return (
              <div className="cell">
                <UsersCount id={id} />,&nbsp;
                <UserGroupsCount id={id} />,&nbsp;
                {projectContent}
              </div>
            );
          }}
          />
        <Column
          topIds={getSplitIds(true, policyDocs, projectDraftPolicyManager.projectId)}
          bottomIds={getSplitIds(false, policyDocs, projectDraftPolicyManager.projectId)}
          className={classNames(style.actionColumn, rowClassName)}
          headerClassName={headerClassName}
          renderHeader={() => null}
          renderCell={({ id }) => {
            const policy = policyById(id);
            return !policy ? null : (
              <div className={classNames(style.actionButtonCell)}>
                {(projectDraftPolicyManager.draftPolicyMembership.has(id) || policy.appliesToAllMemberProjects())
                  ? (
                    <TooltipTrigger
                      content={policy.appliesToAllMemberProjects()
                        ? 'You cannot remove projects from a policy that includes all projects. Admins can change this setting on the Policies page.'
                        : 'Remove project as policy member'
                        }
                      placement="top">
                      <Button
                        className={style.removeFromPolicyButton}
                        buttonType="Link"
                        icon="close"
                        iconSize={16}
                        iconClassName={style.removeFromPolicyIcon}
                        onClick={() => onRemoveFromPolicyAsMember(id)}
                        disabled={policy.appliesToAllMemberProjects()}
                        />
                    </TooltipTrigger>
                  ) : (
                    <TooltipTrigger
                      content={'Add project as policy member'}
                      placement="top">
                      <Button
                        className={style.addToPolicyButton}
                        buttonType="Link"
                        icon="tamr-icon-add"
                        iconSize={16}
                        onClick={() => onAddToPolicyAsMember(id)}
                        />
                    </TooltipTrigger>
                  )
                  }
              </div>
            );
          }}
          />
        <Column
          topIds={getSplitIds(true, policyDocs, projectDraftPolicyManager.projectId)}
          bottomIds={getSplitIds(false, policyDocs, projectDraftPolicyManager.projectId)}
          className={classNames(style.resourcesColumn, rowClassName)}
          headerClassName={headerClassName}
          renderHeader={() => 'Resources'}
          renderCell={({ id }) => {
            const policy = policyById(id);
            if (!policy || !projectId) {
              return null;
            }
            const originallyIncluded = policy.hasProjectAsResource({ projectId });
            const projectIncluded = projectDraftPolicyManager.draftPolicyResourceship.includes(id);

            // Adjust the number of projects based If the project was a member, but no longer is, adjust the number of projects for the policy.
            let numProjects = policy.projectIds().size;
            numProjects = originallyIncluded && !projectIncluded ? numProjects - 1 : numProjects;
            numProjects = projectIncluded && !originallyIncluded ? numProjects + 1 : numProjects;
            const projectContent = policy.appliesToAllResourceProjects() ?
              <span className={style.included}>All projects</span> :
              <span className={projectIncluded ? style.included : ''}>{numProjects} {pluralize(numProjects, 'project', 'projects')}</span>;
            return (
              <div>
                <span>{projectContent},&nbsp;</span>
                <span>{datasetString(policy)},&nbsp;</span>
                <span>{resourcePolicyString(policy)}</span>
              </div>
            );
          }}
      />
        <Column
          topIds={getSplitIds(true, policyDocs, projectDraftPolicyManager.projectId)}
          bottomIds={getSplitIds(false, policyDocs, projectDraftPolicyManager.projectId)}
          className={classNames(style.actionColumn, rowClassName)}
          headerClassName={headerClassName}
          renderHeader={() => null}
          renderCell={({ id }) => {
            const policy = policyById(id);
            return !policy ? null : (
              <div className={classNames(style.actionButtonCell)}>
                {(projectDraftPolicyManager.draftPolicyResourceship.has(id) ||
                  policy.appliesToAllResourceProjects())
                  ? (
                    <TooltipTrigger
                      content={policy.appliesToAllResourceProjects()
                        ? 'You cannot remove projects from a policy that includes all projects. Admins can change this setting on the Policies page.'
                        : 'Remove project as policy resource'
                    }
                      placement="top">
                      <Button
                        className={style.removeFromPolicyButton}
                        buttonType="Link"
                        icon="close"
                        iconSize={16}
                        iconClassName={style.removeFromPolicyIcon}
                        onClick={() => onRemoveFromPolicyAsResource(id)}
                        disabled={policy.appliesToAllResourceProjects()}
                    />
                    </TooltipTrigger>
                  ) : (
                    <TooltipTrigger
                      content={'Add project as policy resource'}
                      placement="top">
                      <Button
                        className={style.addToPolicyButton}
                        buttonType="Link"
                        icon="tamr-icon-add"
                        iconSize={16}
                        onClick={() => onAddToPolicyAsResource(id)}
                    />
                    </TooltipTrigger>
                  )
              }
              </div>
            );
          }}
      />
      </SplitGrid>
    </div>
  );
});

export default ProjectPoliciesSelector;
