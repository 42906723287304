import React from 'react';
import { connect } from 'react-redux';

import Chrome from '../chrome/Chrome';
import PregroupHeader from '../pregroup/PregroupHeader';
import PregroupLoader from '../pregroup/PregroupLoader';
import PregroupRecordsTable from '../pregroup/PregroupRecordsTable';
import PregroupSettingsDialog from '../pregroup/PregroupSettingsDialog';
import { PregroupSummary } from '../pregroup/PregroupSummary';
import UpToDateDialog from '../pregroup/UpToDateDialog';
import UnifiedAttributesLoader from '../schema-mapping/UnifiedAttributesLoader';
import State from '../stores/AppState';
import { isCuratorByProjectId } from '../utils/Authorization';
import { getAuthorizedUser, selectActiveProjectInfo } from '../utils/Selectors';
import style from './Pregroup.module.scss';

const Unauthorized = require('./Unauthorized').default;

function mapStateToProps(state: State) {
  const projectId = selectActiveProjectInfo(state)?.projectDoc.id.id;
  const isUserACurator = isCuratorByProjectId(getAuthorizedUser(state), projectId);
  return {
    projectId,
    isUserACurator,
  };
}

const Pregroup = connect(mapStateToProps)(({ isUserACurator, projectId }) => (
  projectId === undefined ?
    <Chrome>
      {null}
    </Chrome> :
    !isUserACurator ?
      <Unauthorized /> :
      <Chrome>
        <div className={style.container}>
          <UnifiedAttributesLoader />
          <PregroupLoader />
          <PregroupSettingsDialog />
          <PregroupHeader />
          <PregroupSummary />
          <PregroupRecordsTable />
          <UpToDateDialog />
        </div>
      </Chrome>
));

export default Pregroup;
