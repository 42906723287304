import classNames from 'classnames';
import React from 'react';

import TamrIcon from '../components/TamrIcon';
import TooltipTrigger, { PlacementOption } from '../components/TooltipTrigger';
import PRODUCT_NAME from '../utils/ProductName';
import style from './GoldenRecordsSidebar.module.scss';


const GeneratedRuleIcon: React.FunctionComponent<{
  placement?: PlacementOption
  className?: string
}> = ({ placement, className }) => {
  return (
    <TooltipTrigger content={`${PRODUCT_NAME} has generated this default rule.`} placement={placement || 'bottom'}>
      <TamrIcon size={8} iconName="tamr-icon-circle-inverted" className={classNames(style.suggested, className)} />
    </TooltipTrigger>
  );
};

export default GeneratedRuleIcon;
