import './TamrIcon.scss';

import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React, { Requireable } from 'react';
import _ from 'underscore';

import { HTMLSVGProps } from '../utils/typescript-react';
import TamrIconClasses, { TamrIconNames } from './Icons/TamrIconClasses';

const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  iconName: PropTypes.oneOf(TamrIconNames).isRequired,
  onClick: PropTypes.func as Requireable<(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void>,
  size: PropTypes.number.isRequired,
  title: PropTypes.string,
};

export type TamrIconProps = InferProps<typeof propTypes> & HTMLSVGProps;

// has to be a class because it is given a ref in our codebase
class TamrIcon extends React.Component<TamrIconProps> {
  static propTypes = propTypes;
  render() {
    const { className, iconName, onClick, size, ...props } = this.props;
    const disabled = !!this.props.disabled; // default is false
    const svgDefinition = TamrIconClasses[iconName] && TamrIconClasses[iconName].default;
    const viewBox = svgDefinition && svgDefinition.viewBox;
    const xlinkHref = svgDefinition && svgDefinition.id;
    return (
      <svg
        {...props}
        onClick={(!disabled && onClick) || undefined}
        className={classNames('icon', 'tamr-icon', className, { clickable: _.isFunction(onClick), clickDisabled: disabled })}
        data-icon={iconName}
        viewBox={viewBox}
        width={size}
        height={size}>
        <use xlinkHref={`#${xlinkHref}`} />
      </svg>
    );
  }
}

export default TamrIcon;
