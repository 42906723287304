import { isEqual } from 'underscore';

import createLoader from '../utils/createLoader';
import { fetchGroups } from './UsersAsync';
import { getGroupsFilterInfo } from './UsersStore';

const GroupsLoader = createLoader(state => {
  const { users: { groupsLoading, loadedGroupsFilterInfo } } = state;
  return {
    canFetch: true, // no preconditions
    loading: groupsLoading,
    shouldFetch: !isEqual(getGroupsFilterInfo(state), loadedGroupsFilterInfo),
  };
}, {
  onFetch: fetchGroups,
}, 'GroupsLoader');

export default GroupsLoader;
