import React from 'react';
import { connect } from 'react-redux';

// @ts-expect-error gifsInTypescript
import loader from '../../images/tamr-loader.gif';
import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import PostButtonSpec from '../models/PostButtonSpec';
import { AppState } from '../stores/MainStore';
import { SHOW_EXTENSION_RESPONSE_DIALOG } from './ChromeActionTypes';
import style from './ExtensionResponseDialog.module.scss';


/**
 * Finds links in text and makes them clickable on render
 */
function convertUrlsToLinks(text: string) {
  const linksFound = text.match(/https?:\/\/([!#$&-;=?-[\]_a-z~%])+/g);

  if (linksFound === null) {
    return [text];
  }

  const nodes = [];
  let remainingText = text;
  for (const linkFound of linksFound) {
    const linkNode = <a href={linkFound} target="_blank">{linkFound}</a>;
    const textParts = remainingText.split(linkFound, 2);
    nodes.push(textParts[0]);
    nodes.push(linkNode);
    remainingText = textParts[1];
  }
  nodes.push(remainingText);
  return nodes;
}

/**
 * Display main content of dialog
 */
function renderBody(inProgress: boolean, buttonSpec: PostButtonSpec, successful: boolean, message: string) {
  if (inProgress) {
    return (<img src={loader} className={style.loadingGif} />);
  }

  let mainMessage;
  let responseMessage;

  if (successful) {
    mainMessage = convertUrlsToLinks(buttonSpec.successMessage);
    try {
      // If the response is JSON, format it with keys on newlines and 4 space indentation
      responseMessage = JSON.stringify(JSON.parse(message), null, 4);
    } catch (error) {
      if (error instanceof SyntaxError) {
        responseMessage = message;
      } else {
        throw error;
      }
    }
  } else {
    mainMessage = convertUrlsToLinks(buttonSpec.failMessage);
    responseMessage = message;
  }

  if (buttonSpec.displayResponse) {
    return (<div>
      {mainMessage}
      <br /><br />
      <pre className={style.responseMessage}>{convertUrlsToLinks(responseMessage)}</pre>
    </div>);
  }
  return (<div>{mainMessage}</div>);
}

/**
 * Popup window to display results of a Post API call made from an extension button
 */
const ExtensionResponseDialog = connect((state: AppState) => {
  const { chrome: { extensionButtonPostCallInProgress, extensionButtonPostCallConfig, extensionButtonPostCallSuccessful, extensionButtonPostCallMessage, showExtensionButtonPostCallDialog } } = state;
  return {
    inProgress: extensionButtonPostCallInProgress,
    buttonSpec: extensionButtonPostCallConfig,
    successful: extensionButtonPostCallSuccessful,
    message: extensionButtonPostCallMessage,
    show: showExtensionButtonPostCallDialog,
  };
}, {
  onHide: () => ({ type: SHOW_EXTENSION_RESPONSE_DIALOG, show: false }),
},
)(({ inProgress, buttonSpec, successful, message, show, onHide }) => {
  if (buttonSpec === undefined) {
    return (<div />);
  }
  return (
    <Dialog
      dialogStyle={DialogStyle.PRIMARY}
      header={<div>{buttonSpec?.buttonText}</div>}
      body={renderBody(inProgress, buttonSpec, successful, message)}
      footer={
        <ButtonToolbar>
          <Button buttonType="Primary" onClick={onHide}>Close</Button>
        </ButtonToolbar>
      }
      onHide={onHide}
      show={show}
        />
  );
});


export default ExtensionResponseDialog;
