import { Set } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import TooltipTrigger from '../components/TooltipTrigger';
import WarningIcon from '../components/WarningIcon';
import Cluster from '../models/Cluster';
import EsRecord from '../models/EsRecord';
import { AppDispatch, AppState } from '../stores/MainStore';
import { pluralize } from '../utils/Strings';
import style from './ConfirmVerificationActionDialog.module.scss';
import { PaneE } from './Pane';
import { dropMergeClusters, mergeClusters, moveRecord, moveRecordsToNew, moveSelectedRecords } from './SuppliersAsync';
import { getSelectedRecords } from './SuppliersStore';
import { VerificationActionConfirmationInfoType } from './VerificationActionConfirmationInfo';
import VerificationIcon, { VERIFICATION_ICON_TYPE_LOCKED, VERIFICATION_ICON_TYPE_SUGGESTABLE_HAS_SUGGESTION, VerificationIconTypeE } from './VerificationIcon';
import { VerificationTypeE } from './VerificationType';


const ButtonIcon = ({ type }: { type: VerificationIconTypeE }) =>
  <VerificationIcon className={style.verificationIcon} size={16} verificationIconType={type} />;

type AllowedVerificationType = VerificationTypeE.SUGGEST | VerificationTypeE.LOCK;

const sumClusterRecords = (clusters: Set<Cluster>) => clusters.reduce((accumulator: number, cluster: Cluster) => accumulator + cluster.recordCount, 0);

type ConfirmVerificationActionDialogOwnProps = {
  pane: PaneE
}

// covers both moveRecords and moveRecordsToNew cases
const ConfirmVerificationActionDialog = connect((state: AppState, { pane }: ConfirmVerificationActionDialogOwnProps) => {
  const paneState = state.suppliers[pane];
  const confirmingVerificationAction: VerificationActionConfirmationInfoType = paneState.confirmingVerificationAction;
  const numRecords = confirmingVerificationAction.case<number>({
    AcceptActiveRecordSuggestion: () => 1,
    MoveRecordsToExistingCluster: ({ fromPane }) => getSelectedRecords(state, fromPane).size,
    MoveRecordsToNewCluster: () => getSelectedRecords(state, pane).size,
    MergeClusters: ({ clusters }) => sumClusterRecords(clusters.toSet()),
    MergeClustersToTarget: ({ clusters, targetCluster }) => sumClusterRecords(clusters.toSet().add(targetCluster)),
    NotConfirming: () => 0,
  });
  return { confirmingVerificationAction, numRecords };
}, (dispatch: AppDispatch, { pane }: ConfirmVerificationActionDialogOwnProps) => bindActionCreators({
  onConfirmMoveRecordsToNew: (verificationType: AllowedVerificationType) => moveRecordsToNew(pane, verificationType),
  onCancel: () => ({ type: 'Suppliers.cancelVerificationAction', pane }),
  onConfirmAcceptActiveRecordSuggestion: (record: EsRecord, targetClusterId: string, verificationType: AllowedVerificationType) => moveRecord(record, pane, targetClusterId, verificationType),
  onConfirmMoveRecords: (verificationType: AllowedVerificationType, fromPane: PaneE, clusterId: string) => moveSelectedRecords(pane, fromPane, clusterId, verificationType),
  onConfirmMergeClusters: (verificationType: AllowedVerificationType) => mergeClusters(pane, verificationType),
  onConfirmMergeClustersToTarget: (verificationType: AllowedVerificationType, fromPane: PaneE, targetClusterId: string) => dropMergeClusters(targetClusterId, pane, fromPane, verificationType),
}, dispatch))(({ confirmingVerificationAction, onConfirmMoveRecords, onConfirmAcceptActiveRecordSuggestion, onConfirmMoveRecordsToNew, onCancel, numRecords, onConfirmMergeClusters, onConfirmMergeClustersToTarget }) => {
  const recordsTerm = (singular: string, plural: string) => (confirmingVerificationAction.tagName === 'MergeClusters' ? plural : pluralize(numRecords, singular, plural));
  const onConfirm = confirmingVerificationAction.case<Function>({
    AcceptActiveRecordSuggestion: ({ record, clusterId }) => (type: AllowedVerificationType) => onConfirmAcceptActiveRecordSuggestion(record, clusterId, type),
    MoveRecordsToExistingCluster: ({ fromPane, clusterId }) => (type: AllowedVerificationType) => onConfirmMoveRecords(type, fromPane, clusterId),
    MoveRecordsToNewCluster: () => onConfirmMoveRecordsToNew,
    MergeClusters: () => (type: AllowedVerificationType) => onConfirmMergeClusters(type),
    MergeClustersToTarget: ({ fromPane, targetCluster }) => (type: AllowedVerificationType) => onConfirmMergeClustersToTarget(type, fromPane, targetCluster.clusterId),
    NotConfirming: () => () => { },
  });
  const moveRecordsTitle = () => recordsTerm('Move record', 'Move records');
  const mergeClustersTitle = () => 'Merge clusters';
  const dialogTitle = confirmingVerificationAction.case<string>({
    AcceptActiveRecordSuggestion: moveRecordsTitle,
    MoveRecordsToExistingCluster: moveRecordsTitle,
    MoveRecordsToNewCluster: moveRecordsTitle,
    MergeClusters: mergeClustersTitle,
    MergeClustersToTarget: mergeClustersTitle,
    NotConfirming: () => '',
  });
  const moveRecordsMsg = () => `By moving ${recordsTerm('this record', 'these records')}, you are verifying ${recordsTerm('it', `all ${numRecords} of them`)} to ${recordsTerm('its', 'their')} new cluster.`;
  const mergeClustersMsg = (allClusters: Set<Cluster>) => {
    const recordCount = allClusters.reduce((accumulator: number, cluster: Cluster) => accumulator + cluster.recordCount, 0);
    return `By merging these clusters, you are verifying all ${recordCount} of their total records.`;
  };
  const msg = confirmingVerificationAction.case<string>({
    AcceptActiveRecordSuggestion: moveRecordsMsg,
    MoveRecordsToExistingCluster: moveRecordsMsg,
    MoveRecordsToNewCluster: moveRecordsMsg,
    MergeClusters: ({ clusters }) => mergeClustersMsg(clusters.toSet()),
    MergeClustersToTarget: ({ clusters, targetCluster }) => mergeClustersMsg(clusters.toSet().add(targetCluster)),
    NotConfirming: () => '',
  });
  const mergeClustersWarning = () => (
    <div className={style.warningMsg}>
      <WarningIcon size={22} />
      &nbsp;
      Your selection will apply to all records in the newly merged cluster.
    </div>
  );
  const warningSection = confirmingVerificationAction.case<JSX.Element>({
    AcceptActiveRecordSuggestion: () => <noscript />,
    MoveRecordsToExistingCluster: () => <noscript />,
    MoveRecordsToNewCluster: () => <noscript />,
    MergeClusters: mergeClustersWarning,
    MergeClustersToTarget: mergeClustersWarning,
    NotConfirming: () => <noscript />,
  });
  return (
    <Dialog
      dialogStyle={DialogStyle.PRIMARY}
      className="form-upload-dialog"
      show={confirmingVerificationAction.tagName !== 'NotConfirming'}
      onHide={onCancel}
      title={dialogTitle}
      body={(
        <div>
          {msg} Do you want to enable Tamr to make suggestions for moving {recordsTerm('this record', 'these records')} in the future?
          {warningSection}
        </div>
      )}
      footer={(
        <ButtonToolbar>
          <Button
            buttonType="Secondary"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <TooltipTrigger placement="top" content={`Disable Tamr from making suggestions for moving ${recordsTerm('this record', 'these records')}`}>
            <Button
              buttonType="Secondary"
              onClick={() => onConfirm(VerificationTypeE.LOCK)}
            >
              <ButtonIcon type={VERIFICATION_ICON_TYPE_LOCKED} />
              &nbsp;
              Verify and disable suggestions
            </Button>
          </TooltipTrigger>
          <TooltipTrigger placement="top" content={`Enable Tamr to make suggestions for moving ${recordsTerm('this record', 'these records')}`}>
            <Button
              buttonType="Primary"
              onClick={() => onConfirm(VerificationTypeE.SUGGEST)}
            >
              <ButtonIcon type={VERIFICATION_ICON_TYPE_SUGGESTABLE_HAS_SUGGESTION} />
              &nbsp;
              Verify and enable suggestions
            </Button>
          </TooltipTrigger>
        </ButtonToolbar>
      )}
    />
  );
});

export default ConfirmVerificationActionDialog;
