import { RolesE } from '../models/AuthUser';
import Group from '../models/Group';

export const START_EDITING_POLICY_MEMBERSHIP = 'Groups.startEditingPolicyMembership';
export const ADD_TO_POLICY_MEMBERSHIP = 'Groups.addToPolicyMembership';
export const REMOVE_FROM_POLICY_MEMBERSHIP = 'Groups.removeFromPolicyMembership';
export const STOP_EDITING_POLICY_MEMBERSHIP = 'Groups.stopEditingPolicyMembership';
export const UPDATE_POLICY_MEMBERSHIP_COMPLETED = 'Groups.updatePolicyMembershipCompleted';
export const UPDATE_POLICY_MEMBERSHIP_FAILED = 'Groups.updatePolicyMembershipFailed';

interface GroupsStartEditingPolicyMembership {
  type: typeof START_EDITING_POLICY_MEMBERSHIP
  group: Group
}

interface GroupsAddToPolicyMembership {
  type: typeof ADD_TO_POLICY_MEMBERSHIP
  policyId: number
  role: RolesE
}

interface GroupsRemoveFromPolicyMembership {
  type: typeof REMOVE_FROM_POLICY_MEMBERSHIP
  policyId: number
}

interface GroupsStopEditingPolicyMembership {
  type: typeof STOP_EDITING_POLICY_MEMBERSHIP
}

interface GroupsUpdatePolicyMembershipCompleted {
  type: typeof UPDATE_POLICY_MEMBERSHIP_COMPLETED
}

interface GroupsUpdatePolicyMembershipFailed {
  type: typeof UPDATE_POLICY_MEMBERSHIP_FAILED
}

export type GroupsActionTypes
  = GroupsStartEditingPolicyMembership
  | GroupsAddToPolicyMembership
  | GroupsRemoveFromPolicyMembership
  | GroupsStopEditingPolicyMembership
  | GroupsUpdatePolicyMembershipCompleted
  | GroupsUpdatePolicyMembershipFailed
