import React from 'react';
import { connect } from 'react-redux';

// @ts-expect-error gifsInTypescript
import loader from '../../images/tamr-loader.gif';
import RecordPairWithData from '../models/RecordPairWithData';
import { AppState } from '../stores/MainStore';
import { getAuthorizedUser } from '../utils/Selectors';
import RecordPairCell from './RecordPairCell';
import styleConstants from './RecordPairConstants.module.scss';
import style from './RecordPairYourResponseCell.module.scss';
import ResponseButton from './ResponseButton';


const ICON_SIZE = parseInt(styleConstants.cellIconSize, 10);

const SPINNER_SIZE = 12; // px

type RecordPairYourResponseCellOwnProps = {
  pair: RecordPairWithData
  rowIndex: number
}

const RecordPairYourResponseCell = connect((state: AppState, { pair }: RecordPairYourResponseCellOwnProps) => {
  const { recordPairs: { currentlyProcessing } } = state;
  const authorizedUser = getAuthorizedUser(state);
  return {
    currentlyProcessing: currentlyProcessing.includes(pair.toPairId().toRecordPairId()),
    loggedInUsername: authorizedUser && authorizedUser.username,
  };
})(({ rowIndex, pair, loggedInUsername, currentlyProcessing }) => {
  const feedbackForUser = pair.feedback.find(f => f.username === loggedInUsername);
  const skipped = feedbackForUser && feedbackForUser.assignmentInfo && feedbackForUser.assignmentInfo.status === 'SKIP';
  return (
    <RecordPairCell innerClassName={style.component}>
      <div className={style.buttonsWrapper}>
        <ResponseButton
          buttonClassName={style.matchButton}
          containerClassName={style.matchButtonContainer}
          index={rowIndex}
          responseIconSize={ICON_SIZE}
          responseKey="MATCH"
        />
        <ResponseButton
          buttonClassName={style.noMatchButton}
          containerClassName={style.noMatchButtonContainer}
          index={rowIndex}
          responseIconSize={ICON_SIZE}
          responseKey="NON_MATCH"
        />
        {skipped ? (
          <span className={style.skipped}>Skipped</span>
        ) : null}
      </div>
      {currentlyProcessing ? (
        <img
          src={loader}
          style={{
            width: SPINNER_SIZE,
            height: SPINNER_SIZE,
          }}
        />
      ) : null}
    </RecordPairCell>
  );
});

export default RecordPairYourResponseCell;
