import React from 'react';
import { connect } from 'react-redux';

import WarningDialog from '../components/WarningDialog';
import { AppState } from '../stores/MainStore';
import style from './ConfirmRemoveSourceFromDatasetFiltersDialog.module.scss';


const ConfirmRemoveSourceFromDatasetFiltersDialog = connect((state: AppState) => {
  const { goldenRecords: { confirmingRemoveSourceFromDatasetFilters } } = state;
  return { confirmingRemoveSourceFromDatasetFilters };
}, {
  onConfirmRemoveSourceFromDatasetFilters: () => ({ type: 'GoldenRecords.confirmRemoveSourceFromDatasetFilters' }),
  onHide: () => ({ type: 'GoldenRecords.cancelConfirmingRemoveSourceFromDatasetFilters' }),
})(({ confirmingRemoveSourceFromDatasetFilters, onHide, onConfirmRemoveSourceFromDatasetFilters }) => {
  return (
    <WarningDialog
      className={style.dialog}
      actionName="Remove from project"
      message={(
        <span>
          Are you sure you want to remove the dataset <strong>{confirmingRemoveSourceFromDatasetFilters}</strong> from this project?
          It will be removed from all dataset filters.
        </span>
      )}
      onAccept={onConfirmRemoveSourceFromDatasetFilters}
      onHide={onHide}
      show={!!confirmingRemoveSourceFromDatasetFilters}
    />
  );
});

export default ConfirmRemoveSourceFromDatasetFiltersDialog;
