import { List, Map, Record, Set } from 'immutable';
import qs from 'query-string';
import _ from 'underscore';

import SortState from '../constants/SortState';
import EsRecord from '../models/EsRecord';
import Model from '../models/Model';
import UnifiedAttribute from '../models/UnifiedAttribute';
import { CREATE_UNIFIED_ATTRIBUTE_COMPLETED } from '../schema-mapping/SchemaMappingActionTypes';
import { DEFAULT_PAGE_SIZE } from '../transactions/TransactionUtils';
import { RecordTypeArgType } from '../transforms/models/Types';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import ElasticUtils from '../utils/ElasticUtils';
import { routes } from '../utils/Routing';
import { getUnifiedDatasetName } from '../utils/Selectors';
import SortUtils from '../utils/SortUtils';
import {
  maybeSet,
  parseBoolean,
  parseNumber,
  parseSet,
  parseSort,
  parseString,
} from '../utils/Url';
import { resetExcept } from '../utils/Values';
import PreviewRecord from './PreviewRecord';
import { PREVIEW } from './RowProviders';

export const initialState = new (Model({
  activeRecordId: { type: ArgTypes.nullable(ArgTypes.string) },
  columns: { type: ArgTypes.Immutable.list.of(ArgTypes.string), defaultValue: List() },
  columnSortStates: { type: ArgTypes.Immutable.map.of(ArgTypes.string), defaultValue: Map() },
  commentFocusSequence: { type: ArgTypes.number, defaultValue: 0 },
  datasetsToProfile: { type: ArgTypes.Immutable.list.of(ArgTypes.string), defaultValue: List() },
  expanded: { type: ArgTypes.bool, defaultValue: true },
  filteredSourceDatasets: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: new Set() },
  finishedProfiling: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: Set() },
  hasComments: { type: ArgTypes.bool, defaultValue: false },
  loadedFilterInfo: { type: ArgTypes.any },
  loading: { type: ArgTypes.bool, defaultValue: false },
  pageNum: { type: ArgTypes.number, defaultValue: 0 },
  pageSize: { type: ArgTypes.number, defaultValue: DEFAULT_PAGE_SIZE },
  previewResultSize: { type: ArgTypes.number, defaultValue: 1000 },
  previewProfiledSchema: { type: ArgTypes.Immutable.list.of(ArgTypes.object), defaultValue: List() },
  provider: { type: ArgTypes.any, defaultValue: PREVIEW },
  queryString: { type: ArgTypes.string, defaultValue: '' },
  rows: { type: ArgTypes.Immutable.list.of(ArgTypes.oneOf(ArgTypes.instanceOf(EsRecord), ArgTypes.instanceOf(PreviewRecord))), defaultValue: List() },
  selectedDatasetNames: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: Set() },
  total: { type: ArgTypes.number, defaultValue: 0 },
  transactionsSequence: { type: ArgTypes.number, defaultValue: 0 },
  datasetFilterDialogVisible: { type: ArgTypes.bool, defaultValue: false },
  newlyCreatedAttributeName: { type: ArgTypes.orUndefined(ArgTypes.string) },
  useLocalStorage: { type: ArgTypes.bool, defaultValue: true },
  datasetType: { type: ArgTypes.orUndefined(RecordTypeArgType) },
}))();

export const getActiveRecord = ({ rows, activeRecordId }) => {
  return rows.find(r => r.recordId === activeRecordId);
};

export const getActiveRecordIndex = ({ rows, activeRecordId }) => {
  return rows.findIndex(r => r.recordId === activeRecordId);
};

const FilterInfo = Record({
  transactionsSequence: null,
  unifiedDatasetName: null,
  pageNum: null,
  pageSize: null,
  hasComments: null,
  queryString: null,
  selectedDatasetNames: null,
  columnSortStates: null,
  provider: null,
  previewResultSize: null,
});

export const getFilterInfo = state => {
  const { records: { transactionsSequence, pageNum, pageSize, hasComments, queryString, selectedDatasetNames, columnSortStates, provider, previewResultSize } } = state;
  return new FilterInfo({ transactionsSequence, unifiedDatasetName: getUnifiedDatasetName(state), pageNum, pageSize, hasComments, queryString, selectedDatasetNames, columnSortStates, provider, previewResultSize });
};

const reloadRecords = state => state.update('transactionsSequence', x => x + 1);

const resetFilter = state => state
  .delete('pageNum')
  .delete('selectedDatasetNames');

export const reducers = {
  'Location.projectChange': (state) => {
    return resetExcept(state, ['loading']);
  },
  'Transforms.updateListCompleted': (state, { saved }) => {
    if (saved) {
      return state.set('useLocalStorage', true);
    }
    return state;
  },
  'Records.useServerStorage': (state) => {
    return state.set('useLocalStorage', false);
  },
  'Location.change': (state, { location }) => {
    if (routes.records.match(location.pathname)) {
      const params = qs.parse(location.search) || {};
      return _.compose(
        // todo useLocalStorage should be in TransformsStore but we are unsure of how to share the URL between multiple stores
        maybeSet('useLocalStorage', parseBoolean(params.useLocalStorage)),
        maybeSet('pageNum', parseNumber(params.pageNum)),
        maybeSet('pageSize', parseNumber(params.pageSize)),
        maybeSet('hasComments', parseBoolean(params.hasComments)),
        maybeSet('selectedDatasetNames', parseSet(parseString)(params.selectedDatasets)),
        maybeSet('queryString', parseString(params.queryString)),
        maybeSet('columnSortStates', parseSort(params.sort)),
        maybeSet('provider', parseString(params.provider)),
      )(state).update(reloadRecords);
    }
    return state;
  },

  'Jobs.finishedProfiling': (state, { dataset }) => {
    return state.merge({
      finishedProfiling: state.get('finishedProfiling').add(dataset),
    });
  },

  'Records.fetchTransactions': (state) => {
    return state.set('loading', true);
  },

  'Records.fetchTransactionsCompleted': (state, { filterInfo, rows, total, columns, previewProfiledSchema, datasetsToProfile, filteredDatasets, datasetType }) => {
    const ids = rows.map(({ recordId }) => recordId).toSet();
    const newState = state
      .merge({
        loading: false,
        rows,
        total,
        loadedFilterInfo: filterInfo,
        columns: columns || List(),
        previewProfiledSchema: previewProfiledSchema || List(),
        datasetsToProfile: datasetsToProfile || List(),
        finishedProfiling: Set(),
        datasetType,
      })
      .update('activeRecordId', id => (ids.has(id) ? id : null));
    if (filteredDatasets && !filteredDatasets.isEmpty()) {
      // The server applied a filter as a performance precaution, make sure the UI reflects it
      return newState.set('selectedDatasetNames', filteredDatasets);
    }
    return newState;
  },

  'Records.fetchTransactionsFailed': (state, { filterInfo, datasetsToProfile }) => {
    return state.merge({
      loading: false,
      loadedFilterInfo: filterInfo,
      datasetsToProfile: datasetsToProfile || List(),
    });
  },

  'Records.setActiveRowNumber': (state, { rowNum }) => {
    return state.set('activeRecordId', state.rows.get(rowNum).recordId);
  },

  'Records.setPage': (state, { pageNum }) => {
    return state.set('pageNum', pageNum);
  },

  'Records.setPageSize': (state, { pageSize }) => {
    return state.merge({ pageSize, pageNum: 0 });
  },

  'Records.setQueryString': (state, { queryString }) => {
    return state.merge({ queryString });
  },

  'Records.toggleSort': (state, { columnName }) => {
    const sanitizedColName = ElasticUtils.sanitizeField(columnName);
    const currentState = state.columnSortStates.get(sanitizedColName) || SortState.UNSORTED;
    return state.merge({
      columnSortStates: Map({ [sanitizedColName]: SortUtils.getNext(currentState) }),
      pageNum: 0,
    });
  },

  'Records.setSelectedDatasets': (state, { datasetsToAdd, datasetsToRemove }) => {
    return state.update('selectedDatasetNames', s => s.union(datasetsToAdd.map(d => d.data.name)).subtract(datasetsToRemove.map(d => d.data.name)))
      .set('datasetFilterDialogVisible', false)
      .set('pageNum', 0);
  },

  'Records.resetSelectedDatasets': (state) => {
    return state.delete('selectedDatasetNames');
  },

  'Records.resetFilter': (state) => {
    return resetFilter(state);
  },

  'Records.setSelectedDatasetList': (state, { filteredSourceDatasets }) => {
    checkArg({ filteredSourceDatasets }, ArgTypes.Immutable.set.of(ArgTypes.string));
    return state.merge({ filteredSourceDatasets });
  },

  'Records.toggleExpandSidebar': (state) => {
    return state.update('expanded', b => !b);
  },

  'Records.openCommentForm': (state) => {
    return state.set('expanded', true).update('commentFocusSequence', n => n + 1);
  },

  'Records.useRowProvider': (state, { provider }) => {
    if (state.get('provider') === provider) return state;
    return state.merge({
      loading: false,
      columns: List(),
      rows: List(),
      previewProfiledSchema: List(),
      datasetsToProfile: List(),
      finishedProfiling: Set(),
      total: 0,
      pageNum: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      loadedFilterInfo: new FilterInfo(),
      activeRecordId: null,
      provider,
    });
  },

  'Records.commentCompleted': (state) => {
    return reloadRecords(state);
  },

  'Records.openDatasetFilterDialog': (state) => {
    return state.set('datasetFilterDialogVisible', true);
  },
  'Records.closeDatasetFilterDialog': (state) => {
    return state.set('datasetFilterDialogVisible', false);
  },

  'Records.setPreviewResultSize': (state, { resultSize }) => {
    return state.set('previewResultSize', resultSize);
  },

  [CREATE_UNIFIED_ATTRIBUTE_COMPLETED]: (state, { attribute, page }) => {
    checkArg({ attribute }, ArgTypes.instanceOf(UnifiedAttribute));
    checkArg({ page }, ArgTypes.string);
    if (page === 'records') {
      return state.set('newlyCreatedAttributeName', attribute.name);
    }
    return state;
  },
  'Records.resetNewlyCreatedAttributeName': (state) => {
    return state.delete('newlyCreatedAttributeName');
  },
  'Transforms.rebaseCompleted': (state) => {
    return reloadRecords(state);
  },
};
