import { List } from 'immutable';

import { ArgTypes } from '../utils/ArgValidation';
import Model from './Model';

// com.tamr.license.models.License

class License extends Model({
  customerName: { type: ArgTypes.string },
  features: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
  evaluation: { type: ArgTypes.bool },
  issuedDate: { type: ArgTypes.timestamp },
  effectiveUntil: { type: ArgTypes.timestamp },
  description: { type: ArgTypes.nullable(ArgTypes.string) },
  version: { type: ArgTypes.string },
}, 'License') {
  static fromJSON(obj) {
    return new License({
      customerName: obj.customerName,
      features: List(obj.features),
      evaluation: obj.evaluation,
      issuedDate: obj.issuedDate,
      effectiveUntil: obj.effectiveUntil,
      description: obj.description,
      version: obj.version,
    });
  }
}

export default License;
