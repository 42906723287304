import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { pure } from 'recompose';
import _ from 'underscore';

const ENTER_KEY = 13;
const ESCAPE_KEY = 27;

const EditableInlineText = _.compose(
  pure,
)(class EditableInlineText extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    commitAction: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string,
  };

  state = {
    editing: false,
    editedValue: this.props.value,
    updatingValue: undefined,
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.value !== this.props.value) {
      this.setState({ updatingValue: newProps.value, editing: false });
    }
  }

  handleChange = (e) => {
    this.setState({ editedValue: e.target.value });
  };

  onFormKeyUp = (e) => {
    if (e.keyCode === ENTER_KEY) {
      this.commitValue();
    } else if (e.keyCode === ESCAPE_KEY) {
      this.cancelChange();
    }
  };

  onClick = () => {
    if (!this.state.editing) {
      this.setState({
        editing: true,
        editedValue: this.props.value,
      });
    }
  };

  onFocusText = (e) => {
    // Moves the selection to the end of the text box
    e.target.selectionStart = e.target.value.length;
    e.target.selectionEnd = e.target.value.length;
    e.target.scrollLeft = 99999999;
  };

  onBlurText = () => {
    this.commitValue();
  };

  cancelChange = () => {
    this.setState({ editing: false });
  };

  commitValue = () => {
    this.props.commitAction(this.state.editedValue);
    this.setState({
      editing: false,
      updatingValue: this.state.editedValue,
    });
  };

  render() {
    let valueContent;
    if (this.state.editing) {
      valueContent = (
        <input
          ref="input"
          type="text"
          value={this.state.editedValue}
          title={this.state.editedValue}
          className="value-input"
          onChange={this.handleChange}
          onKeyUp={this.onFormKeyUp}
          onBlur={this.onBlurText}
          onFocus={this.onFocusText}
          autoFocus
        />
      );
    } else {
      let value;
      if (this.state.updatingValue !== undefined) {
        value = this.state.updatingValue ? this.state.updatingValue : this.props.placeholder;
      } else {
        value = this.props.value ? this.props.value : this.props.placeholder;
      }
      valueContent = (
        <span title={value} className="value-text">{value}</span>
      );
    }
    const componentClass = classNames('editable-inline-text', this.props.className, {
      editing: this.state.editing,
    });
    const editValueIconClass = classNames({
      'trigger-edit-value': true,
      'yet-unset': _.isEmpty(this.props.value) && this.state.updatingValue === undefined,
    });

    return (
      <div className={componentClass} onClick={this.onClick}>
        <span className="value-container">
          {valueContent}
        </span>
        <span className={editValueIconClass} />
      </div>
    );
  }
});

export default EditableInlineText;
