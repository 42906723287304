import classNames from 'classnames';
import { List, Set } from 'immutable';
import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import ActivitySidebarTab from '../components/ActivitySidebarTab';
import Button from '../components/Button';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import DialogHeader from '../components/Dialog/DialogHeader';
import { GeoValueSummary, tryParseDataAsGeo } from '../components/MultiValue';
import ProgressBar from '../components/ProgressBar/ProgressBar';
import ScoreIcon from '../components/ScoreIcon';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import TypedData, { ArrayRenderingOption, DEFAULT_HIGHLIGHTING_PARAMETERS, StringRenderingOption, WhitespaceRenderingOption } from '../dataRendering/TypedData';
import * as geotamr from '../geospatial/GeoTamr';
import DisplayColumn from '../models/DisplayColumn';
import { DEDUP_INFO_METADATA_KEY } from '../models/Recipe';
import {
  ABSOLUTE_COSINE, ABSOLUTE_SIMILARITIES,
  GEOSPATIAL_DIRECTIONAL_HAUSDORFF,
  GEOSPATIAL_HAUSDORFF,
  GEOSPATIAL_MIN_DISTANCE,
} from '../schema-mapping/constants/SimilarityFunction';
import { AppState } from '../stores/MainStore';
import { inferTypedValueOfUnknownJson } from '../transforms/models/TypedValue';
import { isCuratorByProjectId, isVerifierByProjectId } from '../utils/Authorization';
import ElasticUtils from '../utils/ElasticUtils';
import { meters } from '../utils/Numbers';
import Optional from '../utils/Optional';
import PRODUCT_NAME from '../utils/ProductName';
import { getAuthorizedUser, selectActiveProjectInfo } from '../utils/Selectors';
import { JsonContent } from '../utils/typescript';
import { getPath } from '../utils/Values';
import { MlEnabledIcon } from './MlEnabledIcon';
import PairsCommentList from './PairsCommentList';
import { comment, provideFeedbackResponses, removeFeedbackResponses } from './RecordPairsAsync';
import { getPairsTableDisplayColumns, udsColumnKeyToExternalId } from './RecordPairsColumns';
import styles from './RecordPairsCompareDetailsDialog.module.scss';
import RecordPairsFeedbackAssignButton from './RecordPairsFeedbackAssignButton';
import { calculateLabelConfidenceTitle } from './RecordPairTamrResponseCell';
import ResponseButton from './ResponseButton';
import { ResponseKeyE } from './ResponseKey';
import { UserDefinedSignalIcon } from './UserDefinedSignalIcon';


const M_KEY = 77;
const N_KEY = 78;
const S_KEY = 83;
const LEFT_ARROW_KEY = 37;
const RIGHT_ARROW_KEY = 39;

const RecordPairsCompareDetailsDialog = connect((state: AppState) => {
  const {
    config: { pairConfidenceThresholds },
    recordPairs: { activeRowNumber, pairs, showingDetailsDialog, pageNum, pageSize, total, commentFocusSequence,
      userDefinedSignals },
  } = state;
  const authorizedUser = getAuthorizedUser(state);
  const projectInfo = selectActiveProjectInfo(state);
  return {
    pairConfidenceThresholds,
    activeRowNumber: activeRowNumber || 0,
    pairs,
    show: showingDetailsDialog,
    pageNum,
    pageSize,
    total,
    commentFocusSequence,
    visibleColumnNames: (getPairsTableDisplayColumns(state) || List<DisplayColumn>()).filter(c => c.visible).map(c => c.name),
    loggedInUsername: authorizedUser?.username,
    userIsCurator: authorizedUser && isCuratorByProjectId(authorizedUser, projectInfo?.projectDoc.id.id),
    userIsVerifier: authorizedUser && isVerifierByProjectId(authorizedUser, projectInfo?.projectDoc.id.id),
    dedupInfo: getPath(projectInfo, 'recipeDoc', 'data', 'metadata', DEDUP_INFO_METADATA_KEY),
    userDefinedSignals,
  };
}, {
  doComment: comment,
  onHide: () => ({ type: 'RecordPairs.closeDetailsDialog' }),
  onSetActivePair: (activeRowNumber: number) => ({ type: 'RecordPairs.setActivePair', activeRowNumber }),
  onClickGeospatialSimilarity: (attributeName: string) => ({
    type: 'RecordPairs.clickGeospatialSimilarity',
    attributeName,
  }),
  onProvideFeedbackResponses: provideFeedbackResponses,
  onRemoveFeedbackResponses: removeFeedbackResponses,
})(({ doComment, onClickGeospatialSimilarity, dedupInfo, commentFocusSequence, visibleColumnNames, onSetActivePair,
  onProvideFeedbackResponses, onRemoveFeedbackResponses, show, pairs, loggedInUsername, userIsCurator, userIsVerifier,
  activeRowNumber, pageNum, pageSize, total, pairConfidenceThresholds, onHide, userDefinedSignals }) => {
  const pair = _.isNumber(activeRowNumber) && pairs.get(activeRowNumber) || undefined;
  if (!pair) {
    return (
      <Dialog
        {...{ show, onHide }}
        className={styles.detailsDialog}
        dialogStyle={DialogStyle.FULL}
        header={<div>No pair selected</div>}
        body={undefined}
      />
    );
  }
  const { suggestedLabel, suggestedLabelConfidence, feedback } = pair;

  const assignedToUser = loggedInUsername && pair.assignedToUser(loggedInUsername);

  const currentFilteredIndex = (pageNum * pageSize) + activeRowNumber + 1;

  const confidenceTitle = (suggestedLabelConfidence && pairConfidenceThresholds)
    ? calculateLabelConfidenceTitle(suggestedLabelConfidence, pairConfidenceThresholds)
    : undefined;

  const feedbackForUser = feedback.find(f => f.username === loggedInUsername);
  const userResponseLabel = feedbackForUser && feedbackForUser.response && feedbackForUser.response.label;
  const matchSelected = userResponseLabel === 'MATCH';
  const nonMatchSelected = userResponseLabel === 'NON_MATCH';
  const skipped = feedbackForUser && feedbackForUser.assignmentInfo && feedbackForUser.assignmentInfo.status === 'SKIP';

  const deleteResponse = () => onRemoveFeedbackResponses({ rowNumbers: Set.of(activeRowNumber) });
  const respond = (responseKey: ResponseKeyE) => onProvideFeedbackResponses({ rowNumbers: Set.of(activeRowNumber), responseKey });
  const noPreviousPair = activeRowNumber === 0;
  const noNextPair = activeRowNumber === pairs.size - 1;
  const moveToPreviousPair = () => onSetActivePair(Math.max(activeRowNumber - 1, 0));
  const moveToNextPair = () => onSetActivePair(Math.min(activeRowNumber + 1, pairs.size - 1));

  // name of the attribute that contains the origin source name, with the ES prefix
  const prefixedOriginSourceNameAttributeName = ElasticUtils.sanitizeField('origin_source_name');

  const record1Source = pair.txn1Data?.get(prefixedOriginSourceNameAttributeName);
  const record2Source = pair.txn2Data?.get(prefixedOriginSourceNameAttributeName);

  useEffect(() => {
    if (show) {
      const handleKeyEvent = (e: KeyboardEvent) => {
        // @ts-expect-error readOnly does not exist on EventTarget apparently?
        if (e.target?.readOnly === false) {
          return; // only want to have key input behavior when user isn't typing into, say, comment input box
        }
        switch (e.keyCode) {
          case M_KEY:
            if (matchSelected) {
              deleteResponse();
            } else {
              respond('MATCH');
            }
            break;
          case N_KEY:
            if (nonMatchSelected) {
              deleteResponse();
            } else {
              respond('NON_MATCH');
            }
            break;
          case S_KEY:
            if (assignedToUser) {
              if (skipped) {
                deleteResponse();
              } else {
                respond('SKIP');
              }
            }
            break;
          case LEFT_ARROW_KEY:
            if (!noPreviousPair) {
              moveToPreviousPair();
            }
            break;
          case RIGHT_ARROW_KEY:
            if (!noNextPair) {
              moveToNextPair();
            }
            break;
        }
      };
      window.addEventListener('keydown', handleKeyEvent);
      return () => window.removeEventListener('keydown', handleKeyEvent);
    }
  });

  const [expandedAttributeNames, setExpandedAttributeNames] = useState<Set<string>>(Set<string>());
  const allAttributesExpanded = expandedAttributeNames.equals(visibleColumnNames.toSet());

  return (
    <Dialog
      {...{ show, onHide }}
      className={styles.detailsDialog}
      dialogStyle={DialogStyle.FULL}
      header={(
        <DialogHeader closeButton>
          <div className={styles.headerLeft}>
            <div className={styles.pairCount}>
              {pair?.highImpact ? (
                <TamrIcon
                  className={styles.highImpact}
                  iconName="tamr-icon-lightning-bolt"
                  size={16}
                />
              ) : null}
              <Term>Pair</Term>{' '}{currentFilteredIndex} of {total}
            </div>
            {suggestedLabel ? (
              <div className={styles.tamrResponse} title={confidenceTitle}>
                {PRODUCT_NAME} says{' '}
                {suggestedLabel === 'MATCH'
                  ? <span className={styles.match}>Match</span>
                  : <span className={styles.noMatch}>No match</span>}
                {pairConfidenceThresholds && (<ScoreIcon
                  className={styles.scoreIcon}
                  score={suggestedLabelConfidence || undefined}
                  scoreThresholds={pairConfidenceThresholds}
                />)}
              </div>
            ) : null}
          </div>
          <div className={styles.headerCenter}>
            <div className={styles.previousButtonSection}>
              <Button
                buttonType="Link"
                onClick={moveToPreviousPair}
                disabled={noPreviousPair}
              >
                Previous
              </Button>
            </div>
            <div className={styles.nextButtonSection}>
              <Button
                buttonType="Link"
                onClick={noNextPair ? onHide : moveToNextPair}
              >
                {noNextPair ? 'End of page (close)' : 'Next'}
              </Button>
            </div>
            <div className={styles.responseButtonsSection}>
              <span className={styles.responseButtonSectionInner}>
                <ResponseButton
                  buttonClassName={styles.match}
                  containerClassName={styles.responseButtonContainer}
                  index={activeRowNumber}
                  responseIconSize={22}
                  responseKey="MATCH"
                  verifiedIconSize={18}
                  verifiedTooltipBelow
                />
                <ResponseButton
                  buttonClassName={styles.noMatch}
                  containerClassName={styles.responseButtonContainer}
                  index={activeRowNumber}
                  responseIconSize={22}
                  responseKey="NON_MATCH"
                  verifiedIconSize={18}
                  verifiedTooltipBelow
                />
                {assignedToUser ? (
                  <ResponseButton
                    buttonClassName={styles.skip}
                    containerClassName={styles.responseButtonContainer}
                    index={activeRowNumber}
                    responseIconSize={22}
                    responseKey="SKIP"
                    verifiedIconSize={18}
                  />
                ) : null}
              </span>
            </div>
          </div>
          {(userIsCurator || userIsVerifier) ? (
            <div className={styles.headerRight}>
              <RecordPairsFeedbackAssignButton rowNumbers={Set.of(activeRowNumber)} iconify={false} />
            </div>
          ) : null}
        </DialogHeader>
      )}
      body={(
        <div className={styles.body}>
          <div className={styles.tableSection}>
            <div className={styles.columnHeaders}>
              <div className={classNames(styles.attributeColumn, styles.headerCell)} title="ATTRIBUTES">
                ATTRIBUTES
                <a onClick={() => (allAttributesExpanded ? setExpandedAttributeNames(Set<string>()) : setExpandedAttributeNames(visibleColumnNames.toSet()))}>
                  {allAttributesExpanded ? 'collapse all' : 'expand all'}
                </a>
              </div>
              <div className={classNames(styles.record1Column, styles.headerCell)} title={JSON.stringify(record1Source)}>
                {record1Source}
              </div>
              <div className={classNames(styles.record2Column, styles.headerCell)} title={JSON.stringify(record2Source)}>
                {record2Source}
              </div>
            </div>
            <div className={styles.tableContainer}>
              <div className={styles.attributeColumnShadow} />
              <table className={styles.table}>
                {visibleColumnNames.map((attributeName, index) => {
                  // with the ES prefix
                  const prefixedAttributeName = ElasticUtils.sanitizeField(attributeName);

                  // all cells but the ones in the bottom row have bottom
                  // borders, because the table already has a bottom border
                  const bottomBorderClassName = index === visibleColumnNames.size - 1 ? '' : styles.bottomBorder;

                  // the similarity between the values of this attribute
                  const similarity = pair.attributeSimilarityScores?.get(attributeName);

                  // if the values are equal, use a special class for styling
                  const isEqual = _.isNumber(similarity) && similarity === 1;
                  const signalType = dedupInfo.signalTypes[attributeName];

                  let similarityTooltip;
                  if (_.isNumber(similarity)) {
                    if (ABSOLUTE_SIMILARITIES.includes(signalType)) {
                      similarityTooltip = `Similarity is ${similarity}`;
                    } else {
                      similarityTooltip = `Similarity is ${Math.round(similarity * 10000) / 100}`;
                    }
                  }

                  const record1Value = pair.txn1Data?.get(prefixedAttributeName);
                  const record2Value = pair.txn2Data?.get(prefixedAttributeName);


                  let similarityBar;
                  if ((signalType === GEOSPATIAL_HAUSDORFF || signalType === GEOSPATIAL_DIRECTIONAL_HAUSDORFF || signalType === GEOSPATIAL_MIN_DISTANCE) && similarity !== undefined) {
                    const distance = similarity * -1;
                    const tooltip = `${distance} meters`;
                    similarityBar = <div
                      onClick={() => onClickGeospatialSimilarity(attributeName)}
                      className={styles.hausdorff}
                      title={tooltip}>{meters(distance)}</div>;
                  } else if (_.isNumber(similarity) && signalType === ABSOLUTE_COSINE) {
                    const roundSim = numeral(similarity).format('0.0');
                    similarityBar = <div>{roundSim}</div>;
                  } else if (_.isNumber(similarity) && !ABSOLUTE_SIMILARITIES.includes(signalType)) {
                    similarityBar = (
                      <ProgressBar
                        min={0}
                        max={100}
                        className={styles.progressBar}
                      >
                        <ProgressBar
                          now={Math.max(similarity * 100, 1)}
                          fillClass={styles.similarityFill}
                        />
                      </ProgressBar>
                    );
                  }

                  const truncateValues = !expandedAttributeNames.has(attributeName);
                  const expandCollapseLink = (
                    <a
                      className={styles.expandCollapseLink}
                      onClick={() => setExpandedAttributeNames(truncateValues ? expandedAttributeNames.add(attributeName) : expandedAttributeNames.delete(attributeName))}
                    >
                      {truncateValues ? 'expand' : 'collapse'}
                    </a>
                  );

                  const uds = userDefinedSignals?.find(u => u.externalId === udsColumnKeyToExternalId(attributeName));
                  const udsValue = uds && pair.userDefinedSignals?.get(uds.externalId);

                  return (
                    <tr className={classNames(styles.tableRow, bottomBorderClassName)}>
                      <td className={classNames(styles.attributeColumn, styles.dataCell)} title={uds ? uds.externalId : attributeName}>
                        <div className={classNames(styles.attributeColumnNameContainer, { [styles.truncateValues]: truncateValues })}>
                          {List(dedupInfo.includedFields).includes(attributeName) && (
                            <MlEnabledIcon className={styles.mlEnabledIcon} size={10} />
                          )}
                          {uds && (
                            <UserDefinedSignalIcon size={12} />
                          )}
                          {!truncateValues && expandCollapseLink}
                          <span className={styles.attributeNameCellValue}>
                            {uds ? uds.displayName : attributeName}
                          </span>
                          {truncateValues && expandCollapseLink}
                        </div>
                        <div title={similarityTooltip}>
                          {similarityBar}
                        </div>
                      </td>
                      {uds ? (
                        <DataCell {...{ truncateValues, isEqual }} recordValue={udsValue} side="center" />
                      ) : (
                        <>
                          <DataCell {...{ truncateValues, isEqual }} recordValue={record1Value} side="left" />
                          <DataCell {...{ truncateValues, isEqual }} recordValue={record2Value} side="right" />
                        </>
                      )}
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
          <div className={styles.commentSection}>
            <div className={styles.columnHeaders}>
              <div className={classNames(styles.commentColumn, styles.headerCell)}>
                COMMENTS
              </div>
            </div>
            <div className={styles.commentListContainer}>
              {pair && (
                <ActivitySidebarTab
                  commentsList={<PairsCommentList pair={pair} />}
                  onAddComment={doComment}
                  focusSequence={commentFocusSequence}
                />
              )}
            </div>
          </div>
        </div>
      )}
    />
  );
});

const DataCell: React.FC<{
  recordValue: JsonContent,
  truncateValues: boolean
  isEqual: boolean
  side: 'left' | 'right' | 'center'
}> = ({ recordValue, truncateValues, isEqual, side }) => {
  return (
    <td
      className={classNames(styles.dataCell, {
        [styles.equal]: isEqual,
        [styles.truncateValues]: truncateValues,
        [styles.record1Column]: side === 'left',
        [styles.record2Column]: side === 'right',
        [styles.udsColumn]: side === 'center',
      })}
    >
      {Optional.of(tryParseDataAsGeo(recordValue))
        .map(geoJson => <GeoValueSummary geoTamr={geotamr.fromGeoJSON(geoJson)} />)
        .orElseGet(() => (
          <TypedData
            typedValue={inferTypedValueOfUnknownJson(recordValue)}
            strings={StringRenderingOption.ONLY_SHOW_VALUE}
            whitespace={truncateValues ? WhitespaceRenderingOption.DO_NOT_ADD_SPACE : WhitespaceRenderingOption.ADD_SPACE}
            arrays={ArrayRenderingOption.SHOW_BRACKETS_AND_POPOVER_WHEN_MULTIPLE_ELEMENTS}
            highlighting={DEFAULT_HIGHLIGHTING_PARAMETERS}
            arrayPopoverPlacement="left"
            arrayPopoverCopyTooltipPlacement="right"
          />
        ))}
    </td>
  );
};

export default RecordPairsCompareDetailsDialog;
