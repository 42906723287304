import { List } from 'immutable';
import _ from 'underscore';

import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { stringify } from '../utils/Strings';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';
import ProfiledAttribute, { ProfilingMetric } from './ProfiledAttribute';


function getMetric(metrics: List<ProfilingMetric>, metricName: string) {
  return metrics.find(m => m.metricName === metricName)?.value;
}

/**
 * Profiling information about an attribute
 *
 * We added multivalue profiling in july '17, but this model needs to also workflow
 * with profiling information that was collected in earlier releases. Code paths
 * intended to support the old profiling format have been marked with 'backwards compatibility'
 */
class AttributeProfilingInfo extends getModelHelpers({
  attributeName: { type: ArgTypes.string },
  datasetName: { type: ArgTypes.string },
  numEmptyRecords: { type: ArgTypes.number, defaultValue: 0 },
  numNotNullRecords: { type: ArgTypes.number, defaultValue: 0 },
  numNullRecords: { type: ArgTypes.nullable(ArgTypes.number), defaultValue: 0 },
  numSingle: { type: ArgTypes.number, defaultValue: 0 },
  numMultivalue: { type: ArgTypes.number, defaultValue: 0 },
  minValues: { type: ArgTypes.number, defaultValue: 0 },
  maxValues: { type: ArgTypes.number, defaultValue: 0 },
  meanValues: { type: ArgTypes.number, defaultValue: 0 },
  mostFrequentValues: { type: ArgTypes.array, defaultValue: [] as $TSFixMe[] },
  profiled: { type: ArgTypes.bool, defaultValue: false },
  profileUpToDate: { type: ArgTypes.bool, defaultValue: false },
}, 'AttributeProfilingInfo')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class AttributeProfilingInfoRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  get totalRecords() {
    if (isNaN(this.numSingle) || isNaN(this.numMultivalue)) {
      // backwards compatibility with .17 and below
      return this.numEmptyRecords + this.numNotNullRecords + (this.numNullRecords || 0);
    }
    return this.numEmptyRecords + this.numSingle + this.numMultivalue;
  }


  static fromMetrics(attributeName: string, profileData: Array<ProfiledAttribute>, upToDate: boolean, datasetName: string) {
    checkArg({ attributeName }, ArgTypes.string);
    checkArg({ profileData }, ArgTypes.array);
    checkArg({ upToDate }, ArgTypes.bool);
    checkArg({ datasetName }, ArgTypes.string);

    if (!profileData || _.isEmpty(profileData)) {
      return new AttributeProfilingInfo({ attributeName, datasetName });
    }

    // Only metrics on dataset have the metrics object at its root
    // backwards compatibility with .17 and below
    const datasetMetrics = _.find(profileData, m => m && m.metrics && !m.attributeName);
    const rowCount = datasetMetrics && getMetric(datasetMetrics.metrics, 'rowCount') || 0;

    const attributeMetrics = _.findWhere(profileData, { attributeName });
    if (!attributeMetrics && upToDate) {
      // then we want a special case where profiling has happened, but there are no records in the dataset
      return new AttributeProfilingInfo({ attributeName, datasetName, profiled: true });
    }
    if (attributeMetrics) {
      let numEmptyRecords = getMetric(attributeMetrics.metrics, 'numEmpty');
      const numNullRecords = getMetric(attributeMetrics.metrics, 'nullCount');
      if (numEmptyRecords === undefined) {
        // backwards compatibility with .17 and below
        // emptyCount is ONLY the number of empty values
        // numEmpty includes blank strings, empty lists and NULLS
        const numEmptyCount = getMetric(attributeMetrics.metrics, 'emptyCount');
        numEmptyRecords = numEmptyCount + numNullRecords;
      }
      const numSingle = getMetric(attributeMetrics.metrics, 'numSingle');
      const numMultivalue = getMetric(attributeMetrics.metrics, 'numMultivalue');
      const minValues = getMetric(attributeMetrics.metrics, 'minValues');
      const maxValues = getMetric(attributeMetrics.metrics, 'maxValues');
      const meanValues = getMetric(attributeMetrics.metrics, 'meanValues');

      // backwards compatibility with .17 and below
      const numNotNullRecords = rowCount - numEmptyRecords;

      const mostFrequentValues = getMetric(attributeMetrics.metrics, 'mostFrequentValues')
        .map((m: ProfilingMetric) => ({ ...m, value: stringify(m.value) }))
        .map((m: $TSFixMe) => ({
          frequency: m.frequency,
          percentFrequency: m.percentFrequency,
          value: String(m.value),
          rawValue: m.value,
        }));

      return new AttributeProfilingInfo({
        attributeName,
        datasetName,
        numEmptyRecords,
        numSingle,
        numMultivalue,
        minValues,
        maxValues,
        meanValues,
        mostFrequentValues,
        numNullRecords,
        numNotNullRecords, // backwards compatibility with .17 and below
        profiled: true,
        profileUpToDate: upToDate,
      });
    }

    return new AttributeProfilingInfo({ attributeName, datasetName });
  }
}

export default AttributeProfilingInfo;
