import './LoginPage.scss';

import PropTypes from 'prop-types';
import React from 'react';
import cookie from 'react-cookie';
import { connect } from 'react-redux';
import _ from 'underscore';
import uri from 'urijs';

import favicon from '../../images/Tamr-Stacked.svg';
import Button from '../components/Button';
import CenterContent from '../components/CenterContent';
import Input from '../components/Input/Input';
import { login } from './AuthAsync';

const forceSamlReauth = (targetFragment) => window.location.assign(uri(window.location.href).query({ samlForce: true, targetFragment }));

const LoginPage = connect((state) => {
  const { auth: { authenticationFailed, loggingIn, sanitizedErrorMessage } } = state;
  return { authenticationFailed, loggingIn, sanitizedErrorMessage };
}, {
  onLogin: login,
})(class LoginPage extends React.Component {
  static propTypes = {
    authenticationFailed: PropTypes.bool,
    loggingIn: PropTypes.bool,
    sanitizedErrorMessage: PropTypes.string,
  };

  state = {
    username: '',
    password: '',
  };

  doLogin = (e) => {
    e.preventDefault();
    this.props.onLogin(this.state.username, this.state.password);
  };

  updateValue = (field, value) => {
    const newState = {};
    newState[field] = value;
    this.setState(newState);
  };

  getLogo = () => {
    return (
      <div className="logo-wrapper">
        <img
          src={favicon}
          style={{
            width: 250,
            height: 250,
          }}
        />
      </div>
    );
  };

  getUserNameField = () => {
    return (
      <div className="form-component-spacer">
        <Input
          title="Username"
          value={this.state.username}
          onChange={(username) => this.setState({ username })}
        />
      </div>
    );
  };

  getPasswordField = () => {
    return (
      <div className="form-component-spacer">
        <Input
          title="Password"
          value={this.state.password}
          onChange={(password) => this.setState({ password })}
          type="password"
        />
      </div>
    );
  };

  getSubmitButton = () => {
    const { loggingIn } = this.props;
    const { username, password } = this.state;
    const message = loggingIn ? 'Logging In...' : 'Log In';
    const disabled = loggingIn || _.isEmpty(username) || _.isEmpty(password);
    return (
      <div className="form-component-spacer">
        <Button
          className="login-button"
          type="submit"
          disabled={disabled}
        >
          {message}
        </Button>
      </div>
    );
  };

  getErrorMessage = () => {
    const { authenticationFailed, sanitizedErrorMessage } = this.props;
    let message;
    if (authenticationFailed) {
      message = (
        <div className="login-failure">
          {sanitizedErrorMessage}
        </div>
      );
    } else {
      message = <div className="login-failure">&nbsp;</div>;
    }
    return message;
  };

  renderSamlRedirect = () => {
    if (cookie.load('hasSaml', { path: '/' })) {
      const fragment = window.location.hash ? window.location.hash.substr(1) : undefined;
      return [
        <div key="or-separator" className="form-component-spacer or-separator">
          or
        </div>,
        <div key="button" className="form-component-spacer">
          <Button
            className="login-button"
            onClick={e => {
              e.preventDefault();
              return forceSamlReauth(fragment);
            }}
          >
            Log In With My Company Account
          </Button>
        </div>,
      ];
    }
  };

  componentDidMount() {
    document.title = 'Login';
  }

  render() {
    return (
      <div className="login-page-container">
        <CenterContent>
          <form
            className="login-page"
            onSubmit={this.doLogin}
          >
            <div className="form-wrapper">
              {this.getLogo()}
              {this.getErrorMessage()}
              {this.getUserNameField()}
              {this.getPasswordField()}
              {this.getSubmitButton()}
              {this.renderSamlRedirect()}
            </div>
          </form>
        </CenterContent>
      </div>
    );
  }
});

export default LoginPage;
