import { Map } from 'immutable';
import { isNumber, isString } from 'underscore';

import { JsonContent } from '../utils/typescript';
import { jsonContentToString } from '../utils/Values';
import * as EsRecord from './EsRecord';
import * as GoldenRecordsModule from './GoldenRecordsModule';
import { attributeNameToColumnKey } from './GoldenRecordsStore';
import { SPECIAL_RULE_NAMES } from './Rule';


export function getClusterIdOfDraftRecord(
  moduleFromLastUpdate: GoldenRecordsModule.GoldenRecordsModule | null | undefined,
  record: EsRecord.EsRecord | undefined,
): string | undefined {
  if (moduleFromLastUpdate && record) {
    // the draft page EsRecord stores data via column keys (eg. 'gr__attributeName')
    const columnKey = attributeNameToColumnKey(moduleFromLastUpdate.clusterDataset.clusterColumn);
    return jsonContentToString(record.source[columnKey]);
  }
  return undefined;
}

// cluster name is synonymous with the output of the entity rule
export function getClusterNameOfDraftRecord(
  moduleFromLastUpdate: GoldenRecordsModule.GoldenRecordsModule | null | undefined,
  record: EsRecord.EsRecord | undefined,
): JsonContent | undefined {
  if (moduleFromLastUpdate && record) {
    const entityRuleAttributeName = moduleFromLastUpdate?.entityRule.outputAttributeName;
    const columnKey = attributeNameToColumnKey(entityRuleAttributeName);
    return record.source[columnKey];
  }
  return undefined;
}

export function getClusterSizeOfDraftRecord(
  record: EsRecord.EsRecord | undefined,
): number | undefined {
  if (record) {
    const columnKey = attributeNameToColumnKey(SPECIAL_RULE_NAMES.clusterSize);
    const value = record.source[columnKey];
    if (isNumber(value)) return value;
    if (isString(value)) return parseInt(value, 10);
  }
  return undefined;
}

export function getClusterIdOfPreviewedRecord(
  previewedModule: GoldenRecordsModule.GoldenRecordsModule | null | undefined,
  record: Map<string, JsonContent> | undefined,
): string | undefined {
  if (previewedModule && record) {
    const clusterIdColumn = previewedModule.clusterDataset.clusterColumn;
    return jsonContentToString(record.get(clusterIdColumn));
  }
  return undefined;
}

export function getClusterNameOfPreviewedRecord(
  previewedModule: GoldenRecordsModule.GoldenRecordsModule | null | undefined,
  record: Map<string, JsonContent> | undefined,
): JsonContent | undefined {
  if (previewedModule && record) {
    const clusterNameColumn = previewedModule.entityRule.outputAttributeName;
    return record.get(clusterNameColumn);
  }
  return undefined;
}

export function getClusterSizeOfPreviewedRecord(
  record: Map<string, JsonContent> | undefined,
): number | undefined {
  if (record) {
    const value = record.get(SPECIAL_RULE_NAMES.clusterSize);
    if (isNumber(value)) return value;
    if (isString(value)) return parseInt(value, 10);
  }
  return undefined;
}
