import PropTypes from 'prop-types';
import _, { ObjectIterator } from 'underscore';

import { ArgTypes, checkArg } from '../utils/ArgValidation';

export type InferValuesType<V extends {[key: string]: any}> = { [K in keyof V]-?: V[K] }[keyof V];

// simply freeze enum key->value object and attach argType / propType from convenience
// could be extended with other enum-y stuff
// SR 12/17/19 this function now supports passing a TS Enum directly in!
export default function enumify<T extends {[key: string]: any}>(keysToValues: T) {
  checkArg({ keysToValues }, ArgTypes.object);
  type ValuesType = InferValuesType<T>;
  const values: ValuesType[] = _.values(keysToValues) as ValuesType;
  const returnObj = Object.assign({}, keysToValues, {
    argType: ArgTypes.valueIn<ValuesType>(values),
    propType: PropTypes.oneOf<ValuesType>(values),
    forEach: (fn: ObjectIterator<ValuesType, void>) => _.forEach(keysToValues, fn),
    values,
    includes: (value: ValuesType) => _.contains(values, value),
  });
  return Object.freeze(returnObj);
}
