import { is } from 'immutable';

import { getFilterInfo } from '../stores/TaxonomyStore';
import createLoader from '../utils/createLoader';
import { getUnifiedDatasetId } from '../utils/Selectors';
import { fetchTaxonomy } from './TaxonomyApi';

const TaxonomyLoader = createLoader((state) => {
  const { taxonomy: { loading, loadedFilterInfo } } = state;
  return {
    canFetch: !!(getUnifiedDatasetId(state)),
    shouldFetch: !is(getFilterInfo(state), loadedFilterInfo),
    loading,
  };
}, {
  onFetch: fetchTaxonomy,
}, 'TaxonomyLoader');

export default TaxonomyLoader;
