import './JobsTable.scss';

import classNames from 'classnames';
import { List } from 'immutable';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import _ from 'underscore';
import _string from 'underscore.string';

import loader from '../../images/tamr-loader.gif';
import CenterContent from '../components/CenterContent';
import ColumnWidthProvider from '../components/ColumnWidthProvider';
import ProgressBar from '../components/ProgressBar/ProgressBar';
import SortableHeaderCell from '../components/SortableHeaderCell';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Table from '../components/Table/Table';
import TooltipTrigger from '../components/TooltipTrigger';
import {
  CANCELED,
  CANCELING,
  FAILED,
  RUNNING,
  SUBMITTED,
  SUBMITTING,
  SUCCEEDED,
  UNSUBMITTED,
} from '../constants/JobState';
import Privileges from '../constants/Privileges';
import { ENRICHMENT, GOLDEN_RECORDS } from '../constants/ProjectTypes';
import SortState from '../constants/SortState';
import { COMMITTING, ROLLING_BACK } from '../constants/TaskStorageStatus';
import AuthUser from '../models/AuthUser';
import Document from '../models/doc/Document';
import { PROJECT_ID_METADATA_KEY } from '../models/Job';
import PrivilegeSpec from '../models/PrivilegeSpec';
import ProjectInfo from '../models/ProjectInfo';
import ResourceSpec from '../models/ResourceSpec';
import Task from '../models/Task';
import {
  getBaseEnrichmentPageUrl,
  getBaseGRPageUrl,
  selectProjectStatusById,
} from '../projects/ProjectsStore';
import {
  hasPermission,
  isAdmin,
  isCuratorByProjectId,
  isReviewerByProjectId,
} from '../utils/Authorization';
import { history } from '../utils/History';
import { shortFormat } from '../utils/Numbers';
import { getAuthorizedUser } from '../utils/Selectors';
import { getPath, noop } from '../utils/Values';
import JobName, { isProjectStepJob } from './JobName';
import Pager from './Pager';

const DEFAULT_WIDTH = 140;
const JobsTable = _.compose(
  connect((state) => {
    const {
      jobs: {
        jobs,
        pageNum,
        pageSize,
        showSpinner,
        tasks,
        coreconnectAndSparkJobs,
        columnSortStates,
      },
      coreConnectService: {
        coreconnectEnabled,
      },
    } = state;
    const projectsById = selectProjectStatusById(state);
    const authorizedUser = getAuthorizedUser(state);
    return { projectsById, jobs: coreconnectEnabled ? coreconnectAndSparkJobs : jobs, pageNum, pageSize, showSpinner, tasks, columnSortStates, authorizedUser, coreconnectEnabled };
  }, {
    onSetPage: pageNum => ({ type: 'Jobs.setPage', pageNum }),
    onSetPageSize: pageSize => ({ type: 'Jobs.setPageSize', pageSize }),
    onShowSpecDialog: jobId => ({ type: 'Jobs.showSpecDialog', jobId }),
    onShowStatusDialog: jobId => ({ type: 'Jobs.showStatusDialog', jobId }),
    onShowCoreConnectStatusDialog: jobId => ({ type: 'Jobs.showStatusDialog', jobId }),
    onCancelJob: jobId => ({ type: 'Jobs.showConfirmCancelJobDialog', jobId }),
    onCancelDmsJob: jobId => ({ type: 'Jobs.cancelCoreConnectJob', jobId }),
    onToggleSort: (column) => ({ type: 'Jobs.changeSort', column }),
  }),
)(class JobsTable extends React.Component {
  static propTypes = {
    authorizedUser: PropTypes.instanceOf(AuthUser).isRequired,
    jobs: ImmutablePropTypes.listOf(PropTypes.any),
    onCancelDmsJob: PropTypes.func.isRequired,
    onCancelJob: PropTypes.func.isRequired,
    onSetPage: PropTypes.func.isRequired,
    onSetPageSize: PropTypes.func.isRequired,
    onShowSpecDialog: PropTypes.func.isRequired,
    onShowStatusDialog: PropTypes.func.isRequired,
    pageNum: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    showSpinner: PropTypes.bool.isRequired,
    tasks: ImmutablePropTypes.listOf(Document.propType.withDataType(Task)),
  };

  isCoreConnect = (job) => job?.jobId?.startsWith('cc');

  renderIDColumn = () => {
    const { jobs } = this.props;
    const header = <Cell>Job ID</Cell>;
    const cell = ({ rowIndex }) => <Cell>{ this.isCoreConnect(jobs.get(rowIndex)) ? jobs.get(rowIndex).id : jobs.get(rowIndex).id.id }</Cell>;
    return (
      <Column columnKey="id" key="id" width={DEFAULT_WIDTH / 2} {...{ header, cell }} isResizable />
    );
  };

  renderDescriptionColumn = () => {
    const { jobs, onShowSpecDialog } = this.props;
    const header = <Cell>Description</Cell>;
    const cell = ({ rowIndex }) => {
      const row = jobs.get(rowIndex);
      return (
        <Cell>
          {this.isCoreConnect(row) ? `Connect: ${jobs.get(rowIndex).type?.toLowerCase().replaceAll('_', ' ')}` : (<span className="job-spec-text clickable" onClick={() => onShowSpecDialog(row.id.id)}>
            {jobs.get(rowIndex).data.description}
          </span>)}
        </Cell>
      );
    };
    return (
      <Column columnKey="description" width={450} flexGrow={1} key="description" {...{ header, cell }} isResizable />
    );
  };

  renderUserColumn = () => {
    const { jobs } = this.props;
    const header = <Cell>Submitted By</Cell>;
    const cell = ({ rowIndex }) => <Cell>{this.isCoreConnect(jobs.get(rowIndex)) ? '--' : jobs.get(rowIndex).created.username}</Cell>;
    return (
      <Column columnKey="createdBy" key="createdBy" width={175} flexGrow={1} {...{ header, cell }} isResizable />
    );
  };

  renderCreatedColumn = () => {
    return this.renderDateColumn('createdAt', 'Submitted', 'created.timestamp', 'createdAt');
  };

  renderEndedColumn = () => {
    return this.renderDateColumn('endedAt', 'Ended', 'data.status.endTime', 'completedAt');
  };

  calcDiff = ({ data: { status: { startTime, endTime, state } } }) => {
    const lastModified = moment(this.secondsToMillis(endTime) || Date.now());
    const created = moment(this.secondsToMillis(startTime));
    if (startTime && (endTime || state === 'RUNNING')) {
      return this.displayDuration(lastModified, created);
    }
    return '--';
  };

  calcDiffCoreConnect = ({ startedAt, completedAt, state }) => {
    const lastModified = moment(completedAt || Date.now());
    const created = moment(startedAt);
    // if (startedAt && (completedAt || state === 'RUNNING')) {
    if (completedAt || state === 'RUNNING') {
      return this.displayDuration(lastModified, created);
    }
    return '--';
  };

  padTimeElements = (value) => {
    return _string.lpad(value, 2, '0');
  };

  renderProgressBar = (jobData) => {
    const numExecuted = jobData.status.componentExecutionTimes.size;
    const numTotal = jobData.totalNodeCount;
    const progress = numTotal ? numExecuted / numTotal * 100 : 0;
    return (
      <TooltipTrigger content={`Completed ${shortFormat(numExecuted)} of ${shortFormat(numTotal)} steps`} placement="bottom">
        <span>
          <ProgressBar className="job-running-progress">
            <ProgressBar fillClass="running" now={progress} />
          </ProgressBar>
        </span>
      </TooltipTrigger>
    );
  };

  renderCancel = (jobId, jobData) => {
    const { onCancelJob, authorizedUser } = this.props;
    const projectId = jobData.metadata.get(PROJECT_ID_METADATA_KEY);
    const datasetId = jobData.metadata.get('datasetId');
    const hasAdminPrivilege = isAdmin(authorizedUser);
    const hasProjectPrivilege = isCuratorByProjectId(authorizedUser, projectId);
    const hasDatasetPrivilege = datasetId && hasPermission({
      user: authorizedUser,
      privilege: new PrivilegeSpec(Privileges.DATASET_ALL),
      resource: new ResourceSpec(`datasets/${datasetId}`),
    });
    if (hasAdminPrivilege || hasProjectPrivilege || hasDatasetPrivilege) {
      return (
        <span>
          <span className="status-text"> | </span>
          <span className="status-text clickable" onClick={() => onCancelJob(jobId)}>
            Cancel
          </span>
        </span>
      );
    }
    return null;
  };

  renderCanceling = (id, jobData, onShowStatusDialog) => {
    return (
      <span>
        <TooltipTrigger content="This job is cancelling. Cancellation may take several minutes to complete and in rare cases may not succeed." placement="bottom">
          <span className="status-text clickable" onClick={() => onShowStatusDialog(id)}>Cancelling</span>
        </TooltipTrigger>
        {this.renderProgressBar(jobData)}
      </span>
    );
  };

  renderDurationColumn = () => {
    const { jobs } = this.props;
    const header = <Cell>Duration</Cell>;
    const cell = ({ rowIndex }) => (
      <Cell>
        {this.isCoreConnect(jobs.get(rowIndex)) ? this.calcDiffCoreConnect(jobs.get(rowIndex)) : this.calcDiff(jobs.get(rowIndex))}
      </Cell>
    );
    return (
      <Column columnKey="duration" width={150} flexGrow={1} key="duration" {...{ header, cell }} isResizable />
    );
  };

  isJobPending = (status) => {
    return status === UNSUBMITTED || status === SUBMITTING || status === SUBMITTED;
  };

  renderStatusColumn = () => {
    const { jobs, tasks, onShowStatusDialog, onCancelDmsJob } = this.props;
    const header = <Cell>Status</Cell>;
    const cell = ({ rowIndex }) => {
      if (this.isCoreConnect(jobs.get(rowIndex))) {
        const { status, id, progress } = jobs.get(rowIndex);
        const state = status;
        const progressPercent = parseInt(progress ? progress.replace('%', '') : 0, 10);
        const iconClassName = classNames('status-ind', {
          error: state === 'FAILED',
          running: state === 'RUNNING',
          succeeded: state === 'SUCCEEDED',
          pending: state === 'QUEUED',
          canceled: state === 'CANCELED',
        });
        const cancel = () => {
          onCancelDmsJob(id);
          fetch(`/api/connect/jobs/${id}/cancel`,
            {
              headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: ' BasicCreds YWRtaW46ZHQ=' },
              method: 'POST',
            },
          );
        };

        return (
          <Cell>
            <span className={iconClassName} />
            {(() => {
              switch (state) {
                case 'QUEUED':
                  return (
                    <span>
                      <TooltipTrigger content="This job cannot begin until resources are available." placement="bottom">
                        <span className="status-text">Waiting for resources</span>
                      </TooltipTrigger>
                      <span>
                        <span className="status-text"> | </span>
                        <span
                          className="status-text clickable"
                          onClick={cancel}>
                          Cancel
                        </span>
                      </span>
                      <ProgressBar className="job-running-progress">
                        <ProgressBar fillClass="running" now={0} />
                      </ProgressBar>
                    </span>
                  );
                case 'RUNNING':
                  return (
                    (
                      <span>
                        <TooltipTrigger content="This job is running." placement="bottom">
                          <span className="status-text"> Running </span>
                        </TooltipTrigger>
                        <ProgressBar className="job-running-progress">
                          <ProgressBar fillClass="running" now={progressPercent} />
                        </ProgressBar>
                      </span>
                    )
                  );
                case 'FAILED': return (
                  <TooltipTrigger
                    content={'This job could not be finished.'}
                    placement="bottom"
                  >
                    <span className="status-text clickable" onClick={() => onShowStatusDialog(id)}>
                      Failed
                    </span>
                  </TooltipTrigger>
                );
                case 'SUCCEEDED': return (
                  <TooltipTrigger content="This job completed successfully." placement="bottom">
                    <span className="status-text clickable" onClick={() => onShowStatusDialog(id)}>
                      Succeeded
                    </span>
                  </TooltipTrigger>
                );
                default: return (
                  <span className="status-text">
                    {state}
                  </span>
                );
              }
            })()}
          </Cell>
        );
      }
      const { data, data: { status: { state: status, cancelUser } }, id: { id } } = jobs.get(rowIndex);
      const task = tasks.find(t => t.data.uuid === data.uuid);
      const incompleteTasks = tasks.filter(t => t.data.storageStatus === ROLLING_BACK ||
        t.data.storageStatus === COMMITTING);
      const numExecuted = data.status.componentExecutionTimes.size;
      const numTotal = data.totalNodeCount;
      const iconClassName = classNames('status-ind', {
        error: status === FAILED,
        running: status === RUNNING && !cancelUser,
        succeeded: status === SUCCEEDED,
        pending: this.isJobPending(status) && !cancelUser,
        canceled: status === CANCELED,
        canceling: (status === CANCELING) || ((status === RUNNING || this.isJobPending(status)) && !!cancelUser),
      });
      return (
        <Cell>
          <span className={iconClassName} />
          {(() => {
            switch (status) {
              case UNSUBMITTED:
                return (
                  cancelUser ? this.renderCanceling(id, data, onShowStatusDialog) : (
                    <span>
                      <TooltipTrigger content="This job cannot begin until it has results from other jobs." placement="bottom">
                        <span className="status-text">Waiting for results</span>
                      </TooltipTrigger>
                      {this.renderCancel(id, data)}
                      {this.renderProgressBar(data)}
                    </span>
                  )
                );
              case SUBMITTING:
              case SUBMITTED:
                return (
                  cancelUser ? this.renderCanceling(id, data, onShowStatusDialog) : (
                    <span>
                      <TooltipTrigger content="This job is waiting cluster resources. These will become available as Spark scales up or as other jobs finish." placement="bottom">
                        <span className="status-text">Waiting for resources</span>
                      </TooltipTrigger>
                      {this.renderCancel(id, data)}
                      {this.renderProgressBar(data)}
                    </span>
                  )
                );
              case RUNNING:
                return (
                  cancelUser ? this.renderCanceling(id, data, onShowStatusDialog) : (
                    <span>
                      <TooltipTrigger content="This job is running." placement="bottom">
                        <span className="status-text">
                          {incompleteTasks.isEmpty() ? 'Running' : 'Running - waiting for jobs'}
                        </span>
                      </TooltipTrigger>
                      {this.renderCancel(id, data)}
                      {this.renderProgressBar(data)}
                    </span>
                  )
                );
              case FAILED: return (
                <TooltipTrigger
                  content={this.isTaskRollingBack(task) ? 'This job could not be finished; it is undoing changes.' : 'This job could not be finished.'}
                  placement="bottom"
                >
                  <span className="status-text clickable" onClick={() => onShowStatusDialog(id)}>
                    {this.isTaskRollingBack(task) ? 'Failed - undoing changes' : 'Failed'}
                  </span>
                </TooltipTrigger>
              );
              case SUCCEEDED: return (
                <TooltipTrigger content="This job completed successfully." placement="bottom">
                  <span className="status-text clickable" onClick={() => onShowStatusDialog(id)}>
                    Succeeded
                  </span>
                </TooltipTrigger>
              );
              case CANCELING: return this.renderCanceling(id, data, onShowStatusDialog);
              case CANCELED: return (
                <TooltipTrigger
                  content={this.isTaskRollingBack(task) ? 'This job has been cancelled and is undoing changes.' : `${cancelUser} cancelled this job. Completed ${shortFormat(numExecuted)} of ${shortFormat(numTotal)} steps.`}
                  placement="bottom"
                >
                  <span className="status-text clickable" onClick={() => onShowStatusDialog(id)}>
                    {this.isTaskRollingBack(task) ? 'Cancelled - undoing changes' : 'Cancelled'}
                  </span>
                </TooltipTrigger>
              );
              default: return (
                <span className="status-text">
                  {status.toLowerCase()}
                </span>
              );
            }
          })()}
        </Cell>
      );
    };
    return (
      <Column columnKey="status" width={150} flexGrow={1} key="status" {...{ header, cell }} isResizable />
    );
  };

  isTaskRollingBack = (task) => {
    return task?.data.storageStatus === ROLLING_BACK;
  };

  renderProjectColumn = () => {
    const { jobs, projectsById, authorizedUser } = this.props;
    const header = <Cell>Project</Cell>;
    const cell = ({ rowIndex }) => {
      if (this.isCoreConnect(jobs.get(rowIndex))) {
        return <Cell>
          <span className={classNames('project-name')}>
            --
          </span>
        </Cell>;
      }
      const projectId = jobs.get(rowIndex).data.metadata.get(PROJECT_ID_METADATA_KEY);
      const canClick = isReviewerByProjectId(authorizedUser, projectId);
      const smStep = projectId ? ((getPath(projectsById, projectId, 'project', 'data', 'steps') || List()).first()) : undefined;
      const recipeId = smStep ? smStep.id : undefined;
      const clickable = _.isNumber(recipeId) && canClick;
      const moduleId = projectsById.getIn([projectId, 'project', 'data', 'metadata', 'resultingFromModule']);
      const projectWithStatus = projectsById.get(projectId);
      const projectType = projectWithStatus ? new ProjectInfo({ projectWithStatus }).projectType : undefined;
      const action = clickable ? () => (projectType === GOLDEN_RECORDS
        ? history.push(getBaseGRPageUrl(moduleId)) : projectType === ENRICHMENT
          ? history.push(getBaseEnrichmentPageUrl(moduleId)) : history.push(`/dashboard/recipe/${recipeId}`)) : noop;

      return (
        <Cell>
          <span className={classNames('project-name', { clickable })} onClick={action}>
            {projectId ? (getPath(projectsById, projectId, 'project', 'data', 'displayName') || projectId) : '--'}
          </span>
        </Cell>
      );
    };
    return (
      <Column columnKey="project" width={250} flexGrow={1} key="project" {...{ header, cell }} isResizable />
    );
  };

  renderProjectStepColumn = () => {
    const { jobs } = this.props;
    const header = <Cell>Step</Cell>;
    const cell = ({ rowIndex }) => {
      if (this.isCoreConnect(jobs.get(rowIndex))) {
        return <Cell> -- </Cell>;
      }
      const jobDoc = jobs.get(rowIndex);
      return (
        <Cell>{isProjectStepJob(jobDoc) ? <JobName {...{ jobDoc }} /> : '--'}</Cell>
      );
    };
    return (
      <Column columnKey="step" width={200} flexGrow={1} key="step" {...{ header, cell }} isResizable />
    );
  };

  secondsToMillis = (seconds) => {
    return seconds ? seconds * 1000 : null;
  };

  renderDateColumn = (key, title, fieldName, coreconnectFieldName) => {
    const { jobs, columnSortStates, onToggleSort } = this.props;
    const header = <SortableHeaderCell
      col={title}
      display={title}
      className="sort-header"
      sortCallback={() => onToggleSort(title)}
      sortState={columnSortStates.get(title) || SortState.UNSORTED}
  />;
    const cell = ({ rowIndex }) => {
      const coreconnect = this.isCoreConnect(jobs.get(rowIndex));
      const dateValue = coreconnect ? jobs.get(rowIndex)[coreconnectFieldName] : this.secondsToMillis(get(jobs.get(rowIndex), fieldName));
      return (
        <Cell>
          {dateValue ? moment(dateValue).format('YYYY-MM-DD HH:mm:ss') : '--'}
        </Cell>
      );
    };
    return (
      <Column columnKey={key} width={150} flexGrow={1} key={key} {...{ header, cell }} isResizable />
    );
  };

  renderLoadingIndicator = () => {
    return (
      <div className="loading-container">
        <div className="loading-panel">
          <img src={loader} style={{ width: 50, height: 50 }} />
        </div>
      </div>
    );
  };

  displayDuration(lastModified, created) {
    const duration = moment.duration(Math.max(lastModified.diff(created), 0));
    const hours = this.padTimeElements(Math.floor(duration.as('hours')));
    const minutes = this.padTimeElements(Math.floor(duration.as('minutes')) % 60);
    const seconds = this.padTimeElements(Math.floor(duration.as('seconds')) % 60);
    return `${hours}:${minutes}:${seconds}`;
  }

  render() {
    const {
      showSpinner,
      jobs,
      pageNum,
      pageSize,
      onSetPage,
      onSetPageSize,
    } = this.props;

    return (
      <div className="jobs-table-container">
        {showSpinner ? (
          this.renderLoadingIndicator()
        ) : (!jobs.size && pageNum === 0) ? (
          <CenterContent>No jobs to view</CenterContent>
        ) : (
          <AutoSizer>
            {({ width, height }) => (
              <ColumnWidthProvider>
                <Table
                  {...{ width, height }}
                  getLength={() => jobs.size}
                  tableType="stripes"
                  onPageSizeChange={onSetPageSize}
                  onPageChange={onSetPage}
                  pageSizes={List.of(5, 25, 100)}
                  pagerState={new Pager(jobs, undefined, pageNum, pageSize)}
                  pagerInputDisabled
                >
                  {[
                    this.renderIDColumn(),
                    this.renderDescriptionColumn(),
                    this.renderProjectColumn(),
                    this.renderProjectStepColumn(),
                    this.renderUserColumn(),
                    this.renderCreatedColumn(),
                    this.renderEndedColumn(),
                    this.renderDurationColumn(),
                    this.renderStatusColumn(),
                  ]}
                </Table>
              </ColumnWidthProvider>
            )}
          </AutoSizer>
        )}
      </div>
    );
  }
});

export default JobsTable;
