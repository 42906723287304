import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import PoliciesLoader from '../accessControl/PoliciesLoader';
import Chrome from '../chrome/Chrome';
import ProjectManagement from '../projects/ProjectManagement';
import { AppAction } from '../stores/AppAction';

const mapDispatchToProps = {
  resetStats: (): AppAction => ({ type: 'ProjectStats.reset' }),
};

type ProjectManagementPageProps = typeof mapDispatchToProps;

class ProjectManagementPage extends React.Component<ProjectManagementPageProps> {
  static propTypes = {
    resetStats: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    this.props.resetStats();
  }

  render() {
    return (
      <Chrome>
        <ProjectManagement />
        <PoliciesLoader />
      </Chrome>
    );
  }
}

export default connect(null, mapDispatchToProps)(ProjectManagementPage);
