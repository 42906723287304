import $ from 'jquery';
import uri from 'urijs';

import { doFetchDatasetById, doProfileDataset } from '../datasets/DatasetsApi';

export const createGroupBy = () => (dispatch, getState) => {
  const state = getState();
  const { groupBy: { idField, viewDescription, fields, datasetDoc, viewName, profile } } = state;
  const data = {
    name: viewName,
    description: viewDescription,
    idField,
    groupingColumns: fields.toList().filter(f => f.groupBy).map(f => f.fieldName).toArray(),
    aggregations: fields.toList().filter(f => !f.groupBy).map(f => f.getAggregation()).toArray(),
  };
  dispatch({ type: 'GroupBy.createGroupBy' });
  $.ajax({
    url: uri(SERVICES.transform(`/group-by/${datasetDoc.data.name}`)),
    method: 'POST',
    contentType: 'application/json',
    timeout: 0,
    data: JSON.stringify(data),
    success() {
      if (profile) {
        doProfileDataset(viewName, dispatch).then(() => {
          dispatch({ type: 'GroupBy.createGroupByCompleted' });
        }).fail(() => {
          dispatch({ type: 'GroupBy.createGroupByFailed', errorMessage: 'Unable to launch profiling for the new dataset' });
        });
        return;
      }
      dispatch({ type: 'GroupBy.createGroupByCompleted' });
    },
    error(r) {
      let errorMessage = 'Unable to commit GroupBy configuration';
      if (r.responseJSON && r.responseJSON.message) {
        errorMessage = r.responseJSON.message;
      }
      dispatch({ type: 'GroupBy.createGroupByFailed', errorMessage });
    },
  });
};

export const fetchDataset = () => (dispatch, getState) => {
  const { groupBy: { datasetId } } = getState();
  dispatch({ type: 'GroupBy.fetchDataset' });
  doFetchDatasetById(datasetId).then((datasetDoc) => {
    dispatch({ type: 'GroupBy.fetchDatasetCompleted', datasetDoc });
  }).fail(() => {
    dispatch({ type: 'GroupBy.fetchDatasetFailed', datasetId });
  });
};
