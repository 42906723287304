import enumify from '../constants/enumify';

// com.tamr.dedup.models.VerificationType

export enum VerificationTypeE {
  UNVERIFIED = 'UNVERIFIED',
  MOVABLE = 'MOVABLE',
  SUGGEST = 'SUGGEST',
  LOCK = 'LOCK',
}

export const UNVERIFIED = VerificationTypeE.UNVERIFIED;
export const MOVABLE = VerificationTypeE.MOVABLE;
export const SUGGEST = VerificationTypeE.SUGGEST;
export const LOCK = VerificationTypeE.LOCK;

const VerificationType = enumify(VerificationTypeE);
export default VerificationType;
