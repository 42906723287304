import { size } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';

import style from '../components/CommentList.module.scss';
import TransactionsComment from '../components/TransactionsComment';
import EsRecord from '../models/EsRecord';

export default class TransactionsCommentList extends React.Component {
  static propTypes = {
    object: PropTypes.instanceOf(EsRecord),
    onDeleteComment: PropTypes.func.isRequired,
    onEditComment: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    const prevComments = prevProps.object.comments;
    const currComments = this.props.object.comments;
    if (size(prevComments) !== size(currComments)) {
      this.refs.commentList.scrollTo({ top, behavior: 'smooth' });
    }
  }

  render() {
    const { object, onDeleteComment, onEditComment } = this.props;
    const listofComments = object.comments;
    const count = size(listofComments);
    return (
      <React.Fragment>
        <div className={style.commentSectionDivide}>
          <hr className={style.commentSeparator} />
          {(count > 0
            && (
            <div className={style.commentsCount}>
              {count} {count > 1 ? ' COMMENTS' : ' COMMENT'}
            </div>
            ))}
        </div>
        <div ref="commentList" className={style.commentsList}>
          {_.chain(listofComments)
            .sortBy(comment => -1 * comment.createdAt)
            .map((comment, key) => (
              <TransactionsComment
                comment={comment}
                key={key}
                onEditComment={(draft) => onEditComment(comment, draft)}
                onDeleteComment={() => onDeleteComment(comment)}
              />
            )).value()}
        </div>
      </React.Fragment>
    );
  }
}
