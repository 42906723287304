import { List } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import RecordPairLabelFilter from '../../constants/RecordPairLabelFilter';
import { history } from '../../utils/History';
import PRODUCT_NAME from '../../utils/ProductName';
import SegmentedBarGraphCard, { Segment } from '../SegmentedBarGraphCard';

const PairLabelsCard = connect(({ confusionMatrix: { manualLabels, matrix } }) => {
  return {
    total: manualLabels,
    agreeWithTamr: matrix.agreements,
    disagreeWithTamr: matrix.disagreements,
  };
}, {
  onResetPairsFilterState: () => ({ type: 'RecordPairs.resetFilters' }),
})(class PairLabelsCard extends React.Component {
  static propTypes = {
    agreeWithTamr: PropTypes.number.isRequired,
    dedupRecipeId: PropTypes.number.isRequired,
    disagreeWithTamr: PropTypes.number.isRequired,
    onResetPairsFilterState: PropTypes.func.isRequired,
    total: PropTypes.number.isRequired,
  };

  getSegments = () => {
    const { agreeWithTamr, disagreeWithTamr, total } = this.props;
    const totalTamrResponses = agreeWithTamr + disagreeWithTamr;
    const noTamrLabels = total - totalTamrResponses;
    if (noTamrLabels > 0) {
      return new List([
        new Segment({ value: agreeWithTamr, label: `Agree with ${PRODUCT_NAME}` }),
        new Segment({ value: disagreeWithTamr, label: `Disagree with ${PRODUCT_NAME}` }),
        new Segment({ value: noTamrLabels, label: `No ${PRODUCT_NAME} Response` }),
      ]);
    }
    return new List([
      new Segment({ value: agreeWithTamr, label: `Agree with ${PRODUCT_NAME}` }),
      new Segment({ value: disagreeWithTamr, label: `Disagree with ${PRODUCT_NAME}` }),
    ]);
  };

  navigateToPairs = () => {
    const { dedupRecipeId, onResetPairsFilterState } = this.props;
    const verifiedResponse = [RecordPairLabelFilter.NON_MATCH, RecordPairLabelFilter.MATCH];
    onResetPairsFilterState();
    history.push(`/pairs/recipe/${dedupRecipeId}?manualLabel=${verifiedResponse}`);
  };

  render() {
    return (
      <SegmentedBarGraphCard
        className="dashboard-pair-labels-card"
        onClick={this.navigateToPairs}
        segments={this.getSegments()}
        showLabelValues
        totalNounPlural="Verified responses"
        totalNounSingular="Verified response"
        totalValue={this.props.total}
      />
    );
  }
});

export default PairLabelsCard;
