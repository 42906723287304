import { is } from 'immutable';

import createLoader from '../utils/createLoader';
import { getStaticAnalysisInfo, performStaticAnalysis } from './TransformsApi';

const StaticAnalysisLoader = createLoader((state) => {
  const { transforms: { loadingStaticAnalysis } } = state;
  const staticAnalysisInfo = getStaticAnalysisInfo(state);
  const loadedStaticAnalysisInfo = state.transforms.loadedStaticAnalysisInfo;
  return {
    canFetch: staticAnalysisInfo
              && staticAnalysisInfo.unifiedDatasetName
              && !staticAnalysisInfo.operationList.isEmpty(),
    shouldFetch: !is(staticAnalysisInfo, loadedStaticAnalysisInfo),
    loading: loadingStaticAnalysis,
  };
}, {
  onFetch: performStaticAnalysis,
}, 'StaticAnalysisLoader');

export default StaticAnalysisLoader;
