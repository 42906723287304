import { createAppStore } from './AppStore';


// TODO remove this re-export, rewire all imports to point directly to AppState file
export { default as AppState, State } from './AppState';

// on import of this file, the main redux store is created
const MainStore = createAppStore();

export const dispatch = MainStore.dispatch;
export type AppDispatch = typeof dispatch;

// @ts-expect-error
window.dispatch = dispatch; // useful for debugging

export default MainStore;
