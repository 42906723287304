import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// @ts-expect-error gifsInTypescript
import loader from '../../images/tamr-loader.gif';
import { FCWithPropTypes } from '../utils/typescript-react';
import CenterContent from './CenterContent';

const LoadingPanel = FCWithPropTypes(
  {
    aboveCenter: PropTypes.bool,
    className: PropTypes.string,
    message: PropTypes.node,
    semiTransparent: PropTypes.bool,
    size: PropTypes.number,
  },
  ({ aboveCenter, className, message, semiTransparent, size, ...props }) => {
    // TODO default-props-in-typescript
    //      how to declare these as default props not in this annoying way?
    aboveCenter = (aboveCenter === undefined || aboveCenter === null) ? false : aboveCenter;
    semiTransparent = (semiTransparent === undefined || semiTransparent === null) ? true : semiTransparent;
    size = size || 50;
    const cssClasses = classNames('loading-panel', className, {
      'above-center': aboveCenter,
      'semi-transparent': semiTransparent,
    });

    return (
      <CenterContent {...props} className={cssClasses}>
        <CenterContent className="gif-container" vertically={false}>
          <img src={loader} style={{ width: size, height: size }} />
        </CenterContent>
        {message ? <div className="message-section">{message}</div> : null}
        <div className="mask" />
      </CenterContent>
    );
  },
);

export default LoadingPanel;
