import { Set } from 'immutable';

import enumify from './enumify';

export const NEW_RECORD = 'NEW_RECORD';
export const DELETED_RECORD = 'DELETED_RECORD';
export const CHANGED_CLUSTER = 'CHANGED_CLUSTER';
export const UNCHANGED_CLUSTER = 'UNCHANGED_CLUSTER';
export type ClusterRecordChangesValueType
  = typeof NEW_RECORD
  | typeof DELETED_RECORD
  | typeof CHANGED_CLUSTER
  | typeof UNCHANGED_CLUSTER;

export const CHANGED_CLUSTER_SET = Set<ClusterRecordChangesValueType>([CHANGED_CLUSTER]);
export const UNCHANGED_CLUSTER_SET = Set<ClusterRecordChangesValueType>([UNCHANGED_CLUSTER]);
export const NEW_RECORD_SET = Set<ClusterRecordChangesValueType>([NEW_RECORD]);
export const DELETED_RECORD_SET = Set<ClusterRecordChangesValueType>([DELETED_RECORD]);

export default enumify({ NEW_RECORD, DELETED_RECORD, CHANGED_CLUSTER, UNCHANGED_CLUSTER });
