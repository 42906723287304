import React from 'react';
import { connect } from 'react-redux';

import { AppAction } from '../../stores/AppAction';
import AppState from '../../stores/AppState';
import ResponseFilterType, { ResponseFilterTypeE } from '../ResponseFilterType';
import SelectFilter from './SelectFilter';


const { PRESENT, MISSING, MATCH, NON_MATCH, SKIP } = ResponseFilterType;

const NO_FILTER = 'NOFILTER';

const OPTIONS = [
  { value: NO_FILTER as typeof NO_FILTER, display: 'No Filter' },
  { value: PRESENT, display: 'Anything' },
  { value: MATCH, display: 'Match' },
  { value: NON_MATCH, display: 'No match' },
  { value: SKIP, display: 'Skip' },
  { value: MISSING, display: 'No Response' },
];

type SelectorValue = ResponseFilterTypeE | typeof NO_FILTER;

const MyResponseFilter = connect(({ recordPairs: { response } }: AppState) => {
  return { response };
}, {
  onSetResponseFilter: (response: ResponseFilterTypeE | undefined): AppAction => ({ type: 'RecordPairs.setResponseFilter', response }),
})(({ response, onSetResponseFilter }) => {
  return (
    <SelectFilter<SelectorValue>
      onChange={value => onSetResponseFilter(value === NO_FILTER ? undefined : value)}
      label="My response"
      values={OPTIONS}
      value={response || NO_FILTER}
    />
  );
});

export default MyResponseFilter;
