import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import Checkbox from '../components/Input/Checkbox';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import { AppState } from '../stores/MainStore';
import { toggleHas } from '../utils/Values';
import FilterSection from './FilterSection';
import style from './GoldenRecordsFilterPanel.module.scss';
import { FilterBookmarkState, selectAllRulesWithOverrides } from './GoldenRecordsStore';
import OverrideFilterSelectionDialog from './OverrideFilterSelectionDialog';


const GoldenRecordsFilterPanel = connect(({ goldenRecords, goldenRecords: { hasOverrides, filterBookmarks } }: AppState) => {
  const rulesWithOverrides = selectAllRulesWithOverrides(goldenRecords);
  return { hasOverrides, rulesWithOverrides, filterBookmarks };
}, {
  onShowSelectionDialog: () => ({ type: 'GoldenRecords.showSelectOverrideFilterDialog' }),
  toggleHasOverrides: (ruleName: string) => ({ type: 'GoldenRecords.toggleFilterToRuleWithOverrides', ruleName }),
  setFilterOverrides: (filterBookmarks: boolean) => ({ type: 'GoldenRecords.setFilterBookmarks', filterBookmarks }),
})(({ hasOverrides, rulesWithOverrides, onShowSelectionDialog, toggleHasOverrides, filterBookmarks, setFilterOverrides }) => {
  return (
    <div className={style.filterPanel}>
      <FilterSection
        titleLabel="BOOKMARKS"
        className={style.checkboxFilters}
      >
        <div>
          <Checkbox className="my-8" onChange={() => setFilterOverrides(toggleHas(filterBookmarks, FilterBookmarkState.bookmarks))} value={filterBookmarks.has(FilterBookmarkState.bookmarks)} title="Bookmarked" titlePosition="right" />

        </div>
        <Checkbox className="my-8" onChange={() => setFilterOverrides(toggleHas(filterBookmarks, FilterBookmarkState.noBookmarks))} value={filterBookmarks.has(FilterBookmarkState.noBookmarks)} title="Not bookmarked" titlePosition="right" />
      </FilterSection>
      <FilterSection
        className="pt-0"
        titleLabel="ATTRIBUTE OVERRIDES"
        linkContent={(
          rulesWithOverrides.isEmpty() ?
            <TooltipTrigger content="No attributes have overrides" placement="top">
              <Button
                buttonType="Link"
                disabled={rulesWithOverrides.isEmpty()}
                onClick={onShowSelectionDialog}> Select </Button>
            </TooltipTrigger> :
            <Button
              buttonType="Link"
              disabled={rulesWithOverrides.isEmpty()}
              onClick={onShowSelectionDialog}> Select </Button>
        )}>
        {!hasOverrides.isEmpty() && (
          <div>
            {hasOverrides.toList().sort().map((attr, i) => (
              <div className={style.overrideFilterItem} key={i}>
                <span>{attr}</span>
                <a className={style.cancelOverrideFilterLink} onClick={(e) => { e.stopPropagation(); toggleHasOverrides(attr); }}>
                  <TamrIcon iconName="tamr-icon-remove" size={10} className={style.cancelOverrideFilterIcon} />
                </a>
              </div>
            ))}
          </div>
        )}
        {hasOverrides.isEmpty() && (
          <span>None selected.</span>
        )}
      </FilterSection>
      <OverrideFilterSelectionDialog />
    </div>
  );
});

export default GoldenRecordsFilterPanel;
