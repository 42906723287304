import classNames from 'classnames';
import { mapProps, pure } from 'recompose';
import _ from 'underscore';

import TamrIcon from '../components/TamrIcon';
import {
  CLASSIFICATION,
  ENRICHMENT,
  GOLDEN_RECORDS,
  SCHEMA_MAPPING_RECOMMENDATIONS,
  SUPPLIER_MASTERING,
} from '../constants/ProjectTypes';
import style from './ProjectTypeIcon.module.scss';

const TYPE_ICON_CLASSES = {
  [SUPPLIER_MASTERING]: 'mastering-project',
  [CLASSIFICATION]: 'categorization-project',
  [SCHEMA_MAPPING_RECOMMENDATIONS]: 'schema-mapping-project',
  [GOLDEN_RECORDS]: 'golden-records-project',
  [ENRICHMENT]: 'enrichment-project',
};

const ProjectTypeIcon = _.compose(
  mapProps((props) => {
    const { projectType, className, ...extraProps } = props;
    return {
      iconName: TYPE_ICON_CLASSES[projectType],
      className: classNames(style.typeIcon, className, {
        [style.mastering]: projectType === SUPPLIER_MASTERING,
        [style.categorization]: projectType === CLASSIFICATION,
        [style.schemaMapping]: projectType === SCHEMA_MAPPING_RECOMMENDATIONS,
        [style.goldenRecords]: projectType === GOLDEN_RECORDS,
        [style.enrichment]: projectType === ENRICHMENT,
      }),
      ...extraProps,
    };
  }),
  pure,
)(TamrIcon);

export default ProjectTypeIcon;
