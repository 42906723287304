import { RuleTypeName } from './Rule';

const RULES_OPTIONS = [
  { value: RuleTypeName.MostCommonValue, label: 'Most common value' },
  { value: RuleTypeName.Max, label: 'Max' },
  { value: RuleTypeName.Min, label: 'Min' },
  { value: RuleTypeName.Longest, label: 'Longest', shortLabel: 'Longest' },
  { value: RuleTypeName.Shortest, label: 'Shortest', shortLabel: 'Shortest' },
  { value: RuleTypeName.Sum, label: 'Sum' },
  { value: RuleTypeName.Mean, label: 'Mean' },
  { value: RuleTypeName.Count, label: 'Count (ignore blank string, empty list, and null)', shortLabel: 'Count' },
  { value: RuleTypeName.Distinct, label: 'Distinct (ignore blank string, empty list, and null)', shortLabel: 'Distinct' },
  { value: RuleTypeName.CountDistinct, label: 'Count distinct (ignore blank string, empty list, and null)', shortLabel: 'Count distinct' },
  { value: RuleTypeName.Expression, label: 'Expression' },
] as const;
export type RulesOptionsE = typeof RULES_OPTIONS[number];

export default RULES_OPTIONS;
