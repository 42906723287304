import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ProgressBar from '../components/ProgressBar/ProgressBar';
import useClickOutside from '../hooks/useClickOutside';
import { getPerc } from '../utils/Numbers';
import PRODUCT_NAME from '../utils/ProductName';
import ConfusionMatrixDialog from './ConfusionMatrixDialog';
import style from './ConfusionMatrixProgress.module.scss';

const NO_LABELS_MESSAGE = `Apply feedback and update results to see how often you agree with ${PRODUCT_NAME}`;

export default connect(({ confusionMatrix: { matrix: { agreements, disagreements }, visible } }) => {
  return { agreements, disagreements, visible };
}, {
  onShow: () => ({ type: 'ConfusionMatrix.show' }),
  onHide: () => ({ type: 'ConfusionMatrix.hide' }),
})(({ agreements, disagreements, onHide, onShow, visible }) => {
  const totalLabels = disagreements + agreements;
  const ref = useClickOutside(onHide);

  return (
    <div className={classNames(style.agreementProgress, { [style.noLabels]: !totalLabels })}>
      {!totalLabels ? (<div>{NO_LABELS_MESSAGE}</div>) : (
        <div className={classNames(style.progressLabel, style.clearfix)}>
          <div className={classNames(style.legendLabel, style.agreement)}>
            <div className={style.inner}>
              <span className={style.fillLegend} />
              You and {PRODUCT_NAME} agree
            </div>
          </div>
          <div className={classNames(style.legendLabel, style.disagreement)}>
            <div className={style.inner}>
              <span className={style.fillLegend} />
              You and {PRODUCT_NAME} disagree
            </div>
          </div>
          <div className={classNames(style.legendLabel, style.moreStats)}>
            <div ref={ref} className={style.showDetails}>
              <Button
                buttonType="Flat"
                className={style.showDetailsButton}
                onClick={visible ? onHide : onShow}
              >
                {visible ? 'Hide' : 'Show'} details
              </Button>
              {visible ? <div className={style.confusionMatrixDetail}><ConfusionMatrixDialog /></div> : null}
            </div>
          </div>
        </div>
      )}
      <ProgressBar>
        <ProgressBar now={!totalLabels ? 75.0 : getPerc(agreements, totalLabels)} fillClass={classNames(style.agreementFill, { [style.placeholder]: !totalLabels })} />
        <ProgressBar now={!totalLabels ? 25.0 : getPerc(disagreements, totalLabels)} fillClass={classNames(style.disagreementFill, { [style.placeholder]: !totalLabels })} />
      </ProgressBar>
    </div>
  );
});
