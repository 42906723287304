import { List, Set } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import ListSelector from '../components/ListSelector/ListSelector';

/**
 * Select a number of fields for Group By configuration.
 */
@connect(
  state => {
    const {
      groupBy: { showAddFieldDialog, datasetDoc },
    } = state;
    return { show: showAddFieldDialog, availableFields: datasetDoc ? datasetDoc.data.fields : List() };
  },
  {
    onHide: () => ({ type: 'GroupBy.hideAddFieldDialog' }),
    onSubmit: fieldNames => ({ type: 'GroupBy.addFields', fieldNames }),
  },
)
export default class GroupByFieldDialog extends React.Component {
  static propTypes = {
    availableFields: ImmutablePropTypes.listOf(PropTypes.string).isRequired,
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
  };

  state = {
    selectedFields: Set(),
    searchValue: '',
  };

  resetState = () => {
    this.setState({
      selectedFields: Set(),
      searchValue: '',
    });
  };

  onHide = () => {
    this.props.onHide();
    this.resetState();
  };

  onSubmit = () => {
    this.props.onSubmit(this.state.selectedFields);
    this.onHide();
  };

  onChange = changedData => {
    let { selectedFields } = this.state;
    changedData.entrySeq().forEach(entrySet => {
      const field = entrySet[0];
      const selected = entrySet[1];
      if (selected) {
        selectedFields = selectedFields.add(field);
      } else {
        selectedFields = selectedFields.remove(field);
      }
    });
    this.setState({ selectedFields });
  };

  render() {
    const { show, availableFields } = this.props;
    const { selectedFields, searchValue } = this.state;
    const filteredAvailableFields = availableFields.filter(fieldName => fieldName.indexOf(searchValue) > -1);
    return (
      <Dialog
        className="group-by-field-dialog"
        show={show}
        onHide={this.onHide}
        title="Select Columns"
        dialogStyle={DialogStyle.PRIMARY}
        body={
          show ? (
            <ListSelector
              values={filteredAvailableFields.map(name => ({ label: name, value: name, selected: selectedFields.contains(name) }))}
              enableSearch
              height={400}
              onSelect={this.onChange}
              onSearch={value => this.setState({ searchValue: value })}
              searchValue={this.state.searchValue}
            />
          ) : null
        }
        footer={
          <ButtonToolbar>
            <Button onClick={this.onSubmit}>Okay</Button>
            <Button onClick={this.onHide} buttonType="Secondary">
              Cancel
            </Button>
            <Button onClick={() => this.setState({ selectedFields: filteredAvailableFields.toSet() })} buttonType="Secondary">
              Select All
            </Button>
          </ButtonToolbar>
        }
      />
    );
  }
}
