import React from 'react';
import { connect } from 'react-redux';

import { AppAction } from '../../stores/AppAction';
import AppState from '../../stores/AppState';
import AllResponsesFilterType, { AllResponsesFilterTypeE } from '../AllResponsesFilterType';
import SelectFilter from './SelectFilter';


const { MATCH, NON_MATCH, SKIP } = AllResponsesFilterType;

const NO_FILTER = 'NOFILTER';

const OPTIONS = [
  { value: NO_FILTER as typeof NO_FILTER, display: 'No Filter' },
  { value: MATCH, display: 'Match' },
  { value: NON_MATCH, display: 'No match' },
  { value: SKIP, display: 'Skipped' },
];

type SelectorValue = AllResponsesFilterTypeE | typeof NO_FILTER;

const AllResponsesFilter = connect(({ recordPairs: { allResponses } }: AppState) => {
  return { allResponses };
}, {
  onSetAllResponsesFilter: (allResponses: AllResponsesFilterTypeE | undefined): AppAction => ({ type: 'RecordPairs.setAllResponsesFilter', allResponses }),
})(({ allResponses, onSetAllResponsesFilter }) => {
  return (
    <SelectFilter<SelectorValue>
      onChange={value => onSetAllResponsesFilter(value === NO_FILTER ? undefined : value)}
      label="Experts agree on"
      values={OPTIONS}
      value={allResponses || NO_FILTER}
    />
  );
});

export default AllResponsesFilter;
