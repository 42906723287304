import './ListSelector.scss';

import classNames from 'classnames';
import { Map } from 'immutable';
import PropTypes, { InferProps, Validator } from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import _ from 'underscore';

import SearchBox from '../SearchBox';
import ListSelectorElement from './ListSelectorElement';


const valuePropType = {
  /**
   * The display value of the element.  If it isn't specified, will use the `key`
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  /**
   * Whether this particular element is selected
   */
  selected: PropTypes.bool,
  /**
   * The internal value of the element
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /**
   * Optional boolean to control whether this element should be shown, defaults to true.
   * Note: in order for correct behavior re: hiding send to top/bottom for first/last element,
   *       all elements must be present in values list, with filtering being controlled by
   *       this prop.
   */
  visible: PropTypes.bool,
};

type ValueType = InferProps<typeof valuePropType>;

const propTypes = {
  /**
   * Optional extra classname to give this component
   */
  className: PropTypes.string,
  /**
   * If true, will display a search box at the top of the component
   */
  enableSearch: PropTypes.bool,
  /**
   * A header row to insert between the search bar and selection list
   */
  headerRow: PropTypes.node,
  /**
   * The height of the component.  Forcing a number now to enforce scrolling
   */
  height: PropTypes.number,
  /**
   * Callback that gets invoked when the text in the searchbox changes
   */
  onSearch: PropTypes.func,
  /**
   * Callback that gets invoked when the selection changes.  The callback has one value:
   * - An Immutable.map keyed on values' key and a whose value is the checked state
   */
  onSelect: PropTypes.func.isRequired as Validator<(selectionStates: Map<string, boolean>) => void>,
  /**
   * If true, commits search on keyup event rather than enter keypress
   */
  searchOnKeyup: PropTypes.bool,
  /**
   * Value of search box
   */
  searchValue: PropTypes.string.isRequired,
  /**
   * The values to show in the list
   */
  values: ImmutablePropTypes.listOf(PropTypes.shape(valuePropType).isRequired).isRequired,
};

type ListSelectorProps = InferProps<typeof propTypes>;
type ListSelectorState = {
  lastSelectedIndex: number | undefined
}

/**
 * Component that shows a list and allows:
 * - Searching through list
 * - Selecting elements in the list
 */
class ListSelector extends React.PureComponent<ListSelectorProps, ListSelectorState> {
  static propTypes = propTypes;

  static defaultProps = { searchOnKeyup: false };

  state = {
    lastSelectedIndex: undefined,
  };

  /**
   * Handle bulk selection from here
   */
  onSelect = (value: string | number, order: number, checked: boolean, event: React.MouseEvent<HTMLDivElement>) => {
    const rowSelection = {} as { [key: string]: boolean };
    if (_.isFunction(this.props.onSelect)) {
      if (event.shiftKey) {
        // Select all rows between the last selected row and what was just clicked to fill in selection
        const lastSelectedIndex = this.state.lastSelectedIndex || 0;
        const rows = _.range(Math.min(lastSelectedIndex, order), Math.max(lastSelectedIndex, order) + 1);
        _.forEach(rows, row => {
          const rowValue = this.props.values.get(row)?.value;
          if (rowValue) {
            rowSelection[rowValue] = checked;
          }
        });
      } else {
        rowSelection[value] = checked;
      }
      this.setState({ lastSelectedIndex: order });
      this.props.onSelect(Map(rowSelection));
    }
  };

  renderList = () => {
    return <div className="list-selector-container">{this.props.values.flatMap((v, i) => (v.visible === false ? [] : [this.renderSelectorElement(v, i)]))}</div>;
  };

  renderHeader = () => {
    const { headerRow } = this.props;
    if (headerRow) return headerRow;
    return <div />;
  };

  renderSearch = () => {
    const { enableSearch, searchValue, onSearch, searchOnKeyup } = this.props;
    if (enableSearch && onSearch) {
      return <SearchBox value={searchValue} onSearch={onSearch} searchOnKeyup={searchOnKeyup} />;
    }
    return <div />;
  };

  renderSelectorElement = (item: ValueType, index: number) => {
    return (
      <ListSelectorElement
        label={item.label}
        selected={item.selected || undefined}
        value={item.value}
        key={item.value}
        onSelect={this.onSelect}
        searchValue={this.props.searchValue}
        order={index}
        active={this.state.lastSelectedIndex === index}
      />
    );
  };

  render() {
    const style: React.CSSProperties = {};
    if (_.isNumber(this.props.height)) {
      style.height = this.props.height;
    }
    return (
      <div
        className={classNames('list-selector-component', this.props.className, {
          'has-search': this.props.enableSearch,
        })}
        style={style}>
        {this.renderSearch()}
        {this.renderHeader()}
        {this.renderList()}
      </div>
    );
  }
}

export default ListSelector;
