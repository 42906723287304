import PropTypes from 'prop-types';
import React from 'react';
import { pure } from 'recompose';
import _ from 'underscore';

import TamrIcon from '../components/TamrIcon';
import style from './CategorizationDashboardCompletedPanel.module.scss';

const CategorizationDashboardCompletedPanel = _.compose(
  pure,
)(class CategorizationDashboardCompletedPanel extends React.Component {
  static propTypes = {
    message: PropTypes.node.isRequired,
  };

  render() {
    const { className, message } = this.props;
    return (
      <div className={className}>
        <div className={style.iconWrapper}>
          <div className={style.iconBox}>
            <div className={style.iconMask} />
            <TamrIcon className={style.icon} iconName="check-circle" size={85} />
          </div>
        </div>
        <div className={style.message}>{message}</div>
      </div>
    );
  }
});

export default CategorizationDashboardCompletedPanel;
