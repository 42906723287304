import { uniq } from 'lodash';
import _ from 'underscore';
import { ORIGIN_SOURCE_NAME } from '../constants/ElasticConstants';
import UnifiedAttribute from '../models/UnifiedAttribute';
import RequiredAttributeType from '../schema-mapping/constants/RequiredAttributeType';

// com.tamr.dedup.models
export interface PreGroupBy {
  groupingFields: string[];
  fieldAggregationMap: { [key: string]: string };
  rootEntityIdsSampleSize: number;
  groupNullFields: string[];
}

export type AggregationFunctionTypes = 'custom' | 'top' | 'collect_subset' | 'collect_set' | 'sum' | 'drop'
export function parseAggregationFunction(aggregationFunction: string, fieldName: string): ({ aggregationFunctionType: AggregationFunctionTypes, nonEmpties: boolean, k: number }) {
  if (aggregationFunction === 'first(array.of(to_string, null))') {
    return {
      nonEmpties: false,
      aggregationFunctionType: 'drop' as AggregationFunctionTypes,
      k: 10,
    };
  }

  const hasNonEmpties = aggregationFunction.includes('array.non_empties');
  const aggFuncMatches = aggregationFunction.match(new RegExp('.*(top|collect_subset|collect_set|sum)\\((\\d+)?.*'));

  const customAgg = {
    nonEmpties: false,
    aggregationFunctionType: 'custom' as AggregationFunctionTypes,
    k: 10,
  };
  if (!aggFuncMatches) {
    return customAgg;
  }

  const aggFuncTypeMaybe = aggFuncMatches[1] as AggregationFunctionTypes;
  const kMaybe = parseInt(aggFuncMatches[2], 10) || 10;
  if (createAggregationFunction(aggFuncTypeMaybe, hasNonEmpties, kMaybe, fieldName) === aggregationFunction) {
    return {
      nonEmpties: hasNonEmpties,
      aggregationFunctionType: aggFuncTypeMaybe,
      k: kMaybe,
    };
  }
  return customAgg;
}

export function createAggregationFunction(aggregationFunctionType: AggregationFunctionTypes, nonEmpties: boolean, k: number, fieldName: string) {
  if (aggregationFunctionType === 'sum') return `sum("${fieldName}")`;
  if (aggregationFunctionType === 'drop') return 'first(array.of(to_string, null))'; // this makes the value null with the type Array<String>
  // mapping to first as the default here since it can take a single value or an array and work fine (spend is a number, all other fields are string[]). It also makes it easy to change to avg. If a value is already defined, it will use that
  if (aggregationFunctionType === 'custom') return `first("${fieldName}")`;
  return `${aggregationFunctionType}(${['top', 'collect_subset'].includes(aggregationFunctionType) ? `${k}, ` : ''}...${nonEmpties ? 'array.non_empties(' : ''}"${fieldName}")${nonEmpties ? ')' : ''}`;
}

/**
 * Generates a string of the default aggregation function
 *    e.g. top(10, ...FIELD_NAME)
 */
export function defaultAggregationFunction(unifiedAttribute: UnifiedAttribute) {
  const defaultFunction = unifiedAttribute.name === ORIGIN_SOURCE_NAME ? 'drop'
    : unifiedAttribute.requiredAttributeType === RequiredAttributeType.SPEND ? 'sum'
      : 'top';
  return createAggregationFunction(
    defaultFunction,
    true,
    unifiedAttribute.mlEnabled ? 10 : 5,
    unifiedAttribute.name,
  );
}

/**
 * Generates a default record grouping by definition, for the first time grouping is enabled
 */
export function defaultPregroupSpec(unifiedAttributes: UnifiedAttribute[]) {
  return {
    groupingFields: unifiedAttributes.filter(ua => ua.mlEnabled).map(ua => ua.name)
      .concat([ORIGIN_SOURCE_NAME]),
    groupNullFields: [],
    fieldAggregationMap: unifiedAttributes.filter(ua => !ua.mlEnabled)
      .filter(ua => ua.name !== ORIGIN_SOURCE_NAME)
      .reduce((acc, ua) => ({
        ...acc,
        [ua.name]: defaultAggregationFunction(ua),
      }), {}),
    rootEntityIdsSampleSize: 10,
  };
}

/**
 * Given a pregroupby spec, toggle whether a given field is a grouping field
 *
 * @param current state prior to toggling the given field
 * @param fieldName name of the field to toggle
 */
export function setAggregation(
  current: PreGroupBy,
  fieldName: string,
  aggregationFunction: string,
  doGroupNulls: boolean,
  isGrouping: boolean,
): PreGroupBy {
  const groupingFields = isGrouping ? uniq([...current.groupingFields, fieldName]) : _.without(current.groupingFields, fieldName);
  const fieldAggregationMap = isGrouping ? _.omit(current.fieldAggregationMap, fieldName) : Object.assign({}, current.fieldAggregationMap, { [fieldName]: aggregationFunction });
  const groupNullFields = doGroupNulls ? uniq([...current.groupNullFields, fieldName]) : _.without(current.groupNullFields, fieldName);
  return {
    groupingFields,
    // never include origin source name as an aggregated field
    fieldAggregationMap: _.omit(fieldAggregationMap, ORIGIN_SOURCE_NAME),
    rootEntityIdsSampleSize: current.rootEntityIdsSampleSize,
    groupNullFields,
  };
}
