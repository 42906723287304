import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import { recordValueShim } from '../components/MultiValue';
import GeoFeatureLoadingErrorMessage from '../geospatial/GeoFeatureLoadingErrorMessage';
import GeospatialMap from '../geospatial/GeospatialMap';
import { GeoTamrType } from '../geospatial/GeoTamr';
import EsRecord from '../models/EsRecord';
import { AppAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { $TSFixMe } from '../utils/typescript';
import { isNotNull } from '../utils/Values';
import styles from './ClusterRecordsMap.module.scss';
import { TOGGLE_SHOW_GEOSPATIAL_OVERLAY } from './SuppliersActionTypes';
import { fetchTransactionsUnderCursor, updateGeospatialBounds } from './SuppliersAsync';
import TamrClusterGeoJSON from './TamrClusterGeoJSON';
import TamrOverlayGeoJSONWithToggle from './TamrClusterOverlayGeoJSONWithToggle';

function getGeoTamrValue(columnValue: { renderRaw: boolean, data: $TSFixMe, originalData?: $TSFixMe}): GeoTamrType | null {
  const { data, renderRaw, originalData } = columnValue;
  const recordValues = recordValueShim({ value: data, raw: renderRaw, originalData });
  return recordValues.length === 0 ? null : recordValues[0].case({
    GeoTamr: ({ geoTamr }) => geoTamr,
    HighlightedSearchResult: () => null,
    Raw: () => null,
    Null: () => null,
  });
}

export function geoTamrFromRows(rows: List<EsRecord>, field: string): List<GeoTamrType> {
  // Convert the row data at the given field to GeoTamr features, and filter out the null values
  // at the end.
  return field
    ? rows.map(row => getGeoTamrValue(row.getValue(field))).filter(isNotNull)
    : List<GeoTamrType>();
}

const mapStateToProps = (state: AppState) => {
  const {
    suppliers: {
      geospatial: {
        geoRows,
        activeGeospatialRenderingAttribute,
        initialBounds,
        initialCenter,
        openMap,
        loadingRows,
        showGeospatialOverlay,
        totalGeoFeatures,
      },
    },
    projects: { tileServers },
  } = state;

  const initialGeoRowsSize = geoRows.size;

  return {
    openMap,
    geoField: activeGeospatialRenderingAttribute,
    initialBounds,
    initialCenter,
    tileServers,
    loadingRows,
    showGeospatialOverlay,
    disableOverlayToggleMsg: totalGeoFeatures > initialGeoRowsSize
      ? `The maximum number of features (${initialGeoRowsSize}) are shown.`
      : undefined,
  };
};

const mapDispatchToProps = {
  onCloseMap: (): AppAction => ({ type: 'Suppliers.closeMap' }),
  onLoad: (bounds: number[][]) => updateGeospatialBounds(bounds),
  onMapMoveEnd: (bounds: number[][]) => updateGeospatialBounds(bounds),
  onClickFeature: (lng: number, lat: number, northBound: number, southBound: number) => (fetchTransactionsUnderCursor(lng, lat, northBound, southBound)),
  onToggleGeospatialOverlay: (): AppAction => ({ type: TOGGLE_SHOW_GEOSPATIAL_OVERLAY }),
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const ClusterRecordsMap: React.FC<Props> = ({
  openMap,
  initialBounds,
  initialCenter,
  tileServers,
  onCloseMap,
  onLoad,
  onMapMoveEnd,
  onClickFeature,
  loadingRows,
  showGeospatialOverlay,
  onToggleGeospatialOverlay,
  disableOverlayToggleMsg,
}) => {
  const getOverlay = () => {
    return <TamrOverlayGeoJSONWithToggle color={'#000000'} onClickFeature={onClickFeature} />;
  };

  return openMap ? (
    <div className={styles.clusterMapView}>
      <GeospatialMap
        tileServers={tileServers}
        onCloseMap={onCloseMap}
        onLoad={onLoad}
        onMapMoveEnd={onMapMoveEnd}
        // Leaflet wants Latitude, Longitude, so we reverse. slide() creates a copy of the array
        // so that reverse() won't change the original array in-place
        bounds={initialBounds.map(arr => arr.slice().reverse())}
        center={initialCenter}
        loadingRows={loadingRows}
        showGeospatialOverlay={showGeospatialOverlay}
        onToggleGeospatialOverlay={onToggleGeospatialOverlay}
        geoFeatureLoadingWarningMsg={<GeoFeatureLoadingErrorMessage />}
        disableOverlayToggleMsg={disableOverlayToggleMsg}
        overlayChildren={getOverlay}
      >
        <TamrClusterGeoJSON color={'#0A608E'} onClickFeature={onClickFeature} />
      </GeospatialMap>
    </div>
  ) : null;
};

export default connect(mapStateToProps, mapDispatchToProps)(ClusterRecordsMap);
