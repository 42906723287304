import { is } from 'immutable';
import { connect } from 'react-redux';
import { createSink, shouldUpdate } from 'recompose';
import _ from 'underscore';

import { selectActiveProjectInfo } from '../utils/Selectors';
import { checkFilterViolations } from './RecordPairsAsync';

const RecordPairsFilterViolationChecker = _.compose(
  connect(state => {
    const { recordPairs: { shouldCheckFilterViolations, currentlyCheckingFilterViolations } } = state;
    const projectInfo = selectActiveProjectInfo(state);
    return {
      shouldCheckFilterViolations,
      currentlyCheckingFilterViolations,
      projectInfoInitialized: projectInfo && projectInfo.isUnifiedDatasetIndexed,
    };
  }, {
    onCheckFilterViolations: checkFilterViolations,
  }),
  // necessary for now to guard against multiple renders
  shouldUpdate((props, nextProps) => !_.every(props, (v, k) => is(v, nextProps[k]))),
)(createSink(({ projectInfoInitialized, shouldCheckFilterViolations, currentlyCheckingFilterViolations, onCheckFilterViolations }) => {
  const difference = shouldCheckFilterViolations.subtract(currentlyCheckingFilterViolations);
  if (projectInfoInitialized && !difference.isEmpty()) {
    onCheckFilterViolations(difference);
  }
}));

export default RecordPairsFilterViolationChecker;
