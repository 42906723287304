/**
 * Labeler is a widget to apply single text values to one or more target objects (with "apply"
 * being defined by the client developer).
 * Functionally it is very similar to the Gmail Label widget.
 */

import './Labeler.scss';

import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { AutoSizer, List, ListRowRenderer } from 'react-virtualized';

import SearchBox from './SearchBox';
import TamrIcon from './TamrIcon';
import TooltipTrigger from './TooltipTrigger';

const style = {
  component: 'tamr-labeler-component',
  listContainer: 'tamr-labeler-list-container',
  listRow: 'tamr-labeler-list-row',
  listRowCheckbox: 'tamr-labeler-list-row-checkbox',
};

const ROW_HEIGHT = 35;

const checkboxIconNames = {
  selected: 'tamr-icon-checkbox-checked',
  semi: 'tamr-icon-checkbox-partial',
  unselected: 'tamr-icon-checkbox-unchecked',
} as const;

export type LabelerSelectionStateE = 'selected' | 'semi' | 'unselected';

const propTypes = {
  // optional className to be applied to container component
  className: PropTypes.string,

  /**
   * optional function to determine if a certain row should be disabled. The function should
   * take in an option object and return the disabled tooltip text when the option should be
   * disabled, otherwise undefined.
   */
  disabledFunc: PropTypes.func,

  /**
   * onOptionSelect(option, e)
   * callback for when user clicks / hits enter on a option
   */
  onOptionSelect: PropTypes.func.isRequired,

  /**
   * onSearchValueChange(value, e)
   * callback for when user changes search value
   */
  onSearchValueChange: PropTypes.func.isRequired,

  /**
   * The options to display in the list.
   * Key must be unique.
   */
  options: ImmutablePropTypes.listOf(PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    selectionState: PropTypes.oneOf<LabelerSelectionStateE>(['selected', 'semi', 'unselected']).isRequired,
    display: PropTypes.node.isRequired,
  }).isRequired).isRequired,

  // Optional placeholder for the search input. Default is "Type your search here".
  placeholder: PropTypes.string,

  /**
   * The value of the search box
   */
  searchValue: PropTypes.string.isRequired,
};

type LabelerProps = InferProps<typeof propTypes>;

class Labeler extends React.Component<LabelerProps> {
  static propTypes = propTypes;

  static defaultProps = {
    placeholder: 'Type your search here',
  };

  renderNoRows = () => {
    return <div />;
  };

  renderRow: ListRowRenderer = (fromVirtualized) => {
    const { index, key } = fromVirtualized;
    const option = this.props.options.get(index);
    if (!option) {
      return null;
    }
    const disabled = this.props.disabledFunc && this.props.disabledFunc(option);
    const { selectionState, display } = option;
    const iconName = checkboxIconNames[selectionState];
    return (
      <div
        key={key}
        style={fromVirtualized.style}
        className={classNames(style.listRow, disabled ? 'disabled' : null)}
        onClick={!disabled ? (e) => this.props.onOptionSelect(option, index, e) : undefined}
      >
        <TamrIcon {...{ iconName }} className={style.listRowCheckbox} size={14} />
        <TooltipTrigger placement="top" content={disabled}>
          <div className="tamr-labeler-text">{display}</div>
        </TooltipTrigger>
      </div>
    );
  };

  render() {
    const { className, onSearchValueChange, options, placeholder, searchValue } = this.props;
    return (
      <div className={classNames(style.component, className)}>
        <SearchBox placeholder={placeholder} value={searchValue} onSearch={onSearchValueChange} searchOnKeyup />
        <div className={style.listContainer}>
          <AutoSizer ref="autosizer">
            {({ width, height }) => (
              <List
                height={height}
                width={width}
                rowRenderer={this.renderRow}
                noRowsRenderer={this.renderNoRows}
                rowCount={options.size}
                rowHeight={ROW_HEIGHT}
                overscanRowCount={5}
                /* provide immutable collections to serve as trigger to re-render (any prop name suffices) */
                mainCollectionUpToDateTestaroni={options}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

export default Labeler;
