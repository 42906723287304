import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Dataset from '../models/Dataset';
import DatasetMetadataSpec from '../models/DatasetMetadataSpec';
import Document from '../models/doc/Document';
import { pluralize } from '../utils/Strings';
import style from './ConfirmDeleteMetadataFromDatasetDialog.module.scss';
import { getSingleSelectedDataset } from './DatasetMetadataStore';
import { updateMetadataForSelectedDataset } from './MetadataAsync';

const ConfirmDeleteMetadataFromDatasetDialog = _.compose(
  connect(state => {
    const selectedDataset = getSingleSelectedDataset(state);
    const { datasetMetadata: { confirmingDeleteMetadata } } = state;
    return {
      selectedDataset,
      metadataToDelete: confirmingDeleteMetadata,
      show: !confirmingDeleteMetadata.isEmpty(),
    };
  }, {
    onAccept: (metadataToDelete) => updateMetadataForSelectedDataset({
      datasetMetadata: metadataToDelete,
      deleteMetadata: true,
    }),
    onHide: () => ({ type: 'DatasetMetadata.cancelDeleteMetadata' }),
  }),
)(class ConfirmDeleteMetadataFromDatasetDialog extends React.Component {
  static propTypes = {
    metadataToDelete: ImmutablePropTypes.listOf(PropTypes.instanceOf(DatasetMetadataSpec)),
    onAccept: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    selectedDataset: Document.propType.withDataType(Dataset),
    show: PropTypes.bool.isRequired,
  };

  accept = (metadataToDelete) => {
    this.props.onAccept(metadataToDelete);
    this.props.onHide();
  };

  render() {
    const { metadataToDelete, selectedDataset, show, onHide } = this.props;
    return (
      <Dialog
        dialogStyle={DialogStyle.PRIMARY}
        show={show}
        onHide={onHide}
        title={show ? `Delete ${pluralize(metadataToDelete.size, 'Property', 'Properties')}` : null}
        body={show ? (
          <div className={style.body}>
            Are you sure you want to remove {pluralize(metadataToDelete.size, 'this property', 'these properties')} from dataset {selectedDataset.data.name}?
            <ul className={style.list}>{metadataToDelete.map(m => <li className={style.metadataList}>Object: {m.attribute || selectedDataset.data.name}, Key: {m.key}, Value: {m.value}</li>)}</ul>
          </div>
        ) : null}
        footer={show ? (
          <ButtonToolbar>
            <Button onClick={onHide} buttonType="Secondary">Cancel</Button>
            <Button onClick={() => this.accept(metadataToDelete)} title="Delete Property">Confirm</Button>
          </ButtonToolbar>
        ) : null}
      />
    );
  }
});

export default ConfirmDeleteMetadataFromDatasetDialog;
