import React from 'react';

import style from './NullValue.module.scss';

const NullValue = () => {
  return (
    <span className={style.nullValue}>null</span>
  );
};

export default NullValue;
