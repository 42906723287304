import React, { FC } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'underscore';

import Checkbox from '../components/Input/Checkbox';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import { AppDispatch, AppState } from '../stores/MainStore';
import filterStyle from './FilterSection.module.scss';
import { PaneE } from './Pane';
import { SuggestionsFilters, VerificationFilterTotals, VerifiedFilters } from './VerificationFilters';
import VerificationIcon, {
  VERIFICATION_ICON_TYPE_LOCKED, VERIFICATION_ICON_TYPE_SUGGESTABLE_HAS_SUGGESTION,
  VERIFICATION_ICON_TYPE_SUGGESTABLE_NO_SUGGESTION, VERIFICATION_ICON_TYPE_VERIFIED_ELSEWHERE, VERIFICATION_ICON_TYPE_VERIFIED_HERE,
  VerificationIconTypeE,
} from './VerificationIcon';


export const FilterVerificationIcon: FC<{
  verificationIconType: VerificationIconTypeE
}> = ({ verificationIconType }) => (
  <VerificationIcon className={filterStyle.verificationIcon} size={15} {...{ verificationIconType }} />
);

const attachTotal = (label: string, total: number | null | undefined) => `${label}${_.isNumber(total) ? ` (${total})` : ''}`;

const InfoIcon: FC<{
  content: string
}> = ({ content }) => {
  return (
    <TooltipTrigger placement="right" {...{ content }}>
      <TamrIcon
        style={{ marginBottom: '1px', verticalAlign: 'sub' }}
        iconName="info-outline"
        size={14}
      />
    </TooltipTrigger>
  );
};

function getFilterState(state: AppState, pane: PaneE) {
  const paneState = state.suppliers[pane];
  const recordsVerifiedFilters: VerifiedFilters = paneState.recordsVerifiedFilters;
  const recordsSuggestionsFilters: SuggestionsFilters = paneState.recordsSuggestionsFilters;
  const recordsVerificationFilterTotals: VerificationFilterTotals = state.suppliers.recordsVerificationFilterTotals;
  return {
    recordsVerifiedFilters,
    recordsVerificationFilterTotals,
    recordsSuggestionsFilters,
  };
}

type JustVerificationFilterSectionOwnProps = {
  pane: PaneE
}

const JustVerificationFilterSection = connect((state: AppState, { pane }: JustVerificationFilterSectionOwnProps) => {
  return getFilterState(state, pane);
}, (dispatch: AppDispatch, { pane }: JustVerificationFilterSectionOwnProps) => bindActionCreators({
  onToggleVerified: () => ({ type: 'Suppliers.toggleVerifiedFilter', pane }),
  onToggleVerifiedInCurrentCluster: () => ({ type: 'Suppliers.toggleVerifiedInCurrentCluster', pane }),
  onToggleVerifiedInAnotherCluster: () => ({ type: 'Suppliers.toggleVerifiedInAnotherCluster', pane }),
  onToggleNotVerified: () => ({ type: 'Suppliers.toggleNotVerifiedFilter', pane }),
}, dispatch))(({
  recordsVerifiedFilters, recordsVerificationFilterTotals, recordsSuggestionsFilters,
  onToggleVerified, onToggleVerifiedInCurrentCluster, onToggleVerifiedInAnotherCluster, onToggleNotVerified,
}) => {
  const { verified: verifiedFilterActive, verifiedHere: verifiedInCurrentClusterFilterActive,
    verifiedElsewhere: verifiedInAnotherClusterFilterActive, notVerified: notVerifiedFilterActive } = recordsVerifiedFilters;
  const { verified: numVerified, verifiedHere: numVerifiedInCurrentCluster,
    verifiedElsewhere: numVerifiedInAnotherCluster, notVerified: numNotVerified } = recordsVerificationFilterTotals;
  const { verifiedHere: verifiedInCurrentClusterFilterEnabled, verifiedElsewhere: verifiedInAnotherClusterFilterEnabled,
    notVerified: notVerifiedFilterEnabled } = VerifiedFilters.enabledFilters(recordsSuggestionsFilters);
  const verifiedFilterEnabled = verifiedInCurrentClusterFilterEnabled && verifiedInAnotherClusterFilterEnabled;
  return (
    <div className={filterStyle.filterSection}>
      <div className={filterStyle.filterPanelHeader}>Verification&nbsp;<InfoIcon content="Curators can verify records that are in the right cluster." /></div>
      <div className={filterStyle.option}>
        <Checkbox
          className={filterStyle.checkboxWrapper}
          onChange={() => onToggleVerified()}
          title={attachTotal('Verified', numVerified)}
          value={verifiedFilterActive}
          size={15}
          titlePosition="right"
          disabled={!verifiedFilterEnabled}
        />
      </div>
      <div className={filterStyle.subfiltersContainer}>
        <div className={filterStyle.option}>
          <Checkbox
            className={filterStyle.checkboxWrapper}
            onChange={() => onToggleVerifiedInCurrentCluster()}
            title={(
              <span>
                <FilterVerificationIcon verificationIconType={VERIFICATION_ICON_TYPE_VERIFIED_HERE} />
                &nbsp;
                {attachTotal('Verified in current cluster', numVerifiedInCurrentCluster)}
              </span>
            )}
            value={verifiedInCurrentClusterFilterActive}
            size={15}
            titlePosition="right"
            disabled={!verifiedInCurrentClusterFilterEnabled}
          />
        </div>
        <div className={filterStyle.option}>
          <Checkbox
            className={filterStyle.checkboxWrapper}
            onChange={() => onToggleVerifiedInAnotherCluster()}
            title={(
              <span>
                <FilterVerificationIcon verificationIconType={VERIFICATION_ICON_TYPE_VERIFIED_ELSEWHERE} />
                &nbsp;
                {attachTotal('Verified in another cluster', numVerifiedInAnotherCluster)}
                &nbsp;
                <InfoIcon content="Records verified in a cluster other than their current cluster. This happens when a curator verifies a cluster, allows Tamr to auto-accept its suggestions, and Tamr then moves the record." />
              </span>
            )}
            value={verifiedInAnotherClusterFilterActive}
            size={15}
            titlePosition="right"
            disabled={!verifiedInAnotherClusterFilterEnabled}
          />
        </div>
      </div>
      <div className={filterStyle.option}>
        <Checkbox
          className={filterStyle.checkboxWrapper}
          onChange={() => onToggleNotVerified()}
          title={attachTotal('Not verified', numNotVerified)}
          value={notVerifiedFilterActive}
          size={15}
          titlePosition="right"
          disabled={!notVerifiedFilterEnabled}
        />
      </div>
    </div>
  );
});

type SuggestionsFilterSectionOwnProps = {
  pane: PaneE
}

const SuggestionsFilterSection = connect((state: AppState, { pane }: SuggestionsFilterSectionOwnProps) => {
  return getFilterState(state, pane);
}, (dispatch: AppDispatch, { pane }: SuggestionsFilterSectionOwnProps) => bindActionCreators({
  onToggleSuggestionsEnabled: () => ({ type: 'Suppliers.toggleSuggestionsEnabled', pane }),
  onToggleMoveSuggested: () => ({ type: 'Suppliers.toggleMoveSuggested', pane }),
  onToggleNoMoveSuggested: () => ({ type: 'Suppliers.toggleNoMoveSuggested', pane }),
  onToggleSuggestionsDisabled: () => ({ type: 'Suppliers.toggleSuggestionsDisabled', pane }),
  onToggleSuggestionsAutoAccepted: () => ({ type: 'Suppliers.toggleSuggestionsAutoAccepted', pane }),
}, dispatch))(({
  recordsVerifiedFilters, recordsVerificationFilterTotals, recordsSuggestionsFilters,
  onToggleSuggestionsEnabled, onToggleMoveSuggested, onToggleNoMoveSuggested, onToggleSuggestionsDisabled, onToggleSuggestionsAutoAccepted,
}) => {
  const { suggestionsEnabled: suggestionsEnabledFilterActive, moveSuggested: moveSuggestedFilterActive, noMoveSuggested: noMoveSuggestedFilterActive,
    suggestionsDisabled: suggestionsDisabledFilterActive, suggestionsAutoAccepted: suggestionsAutoAcceptedFilterActive } = recordsSuggestionsFilters;
  const { suggestionsEnabled: numSuggestionsEnabled, moveSuggested: numMoveSuggested, noMoveSuggested: numNoMoveSuggested,
    suggestionsDisabled: numSuggestionsDisabled, suggestionsAutoAccepted: numSuggestionsAutoAccepted } = recordsVerificationFilterTotals;
  const { moveSuggested: moveSuggestedFilterEnabled, noMoveSuggested: noMoveSuggestedFilterEnabled,
    suggestionsDisabled: suggestionsDisabledFilterEnabled, suggestionsAutoAccepted: suggestionsAutoAcceptedFilterEnabled } = SuggestionsFilters.enabledFilters(recordsVerifiedFilters);
  const suggestionsEnabledFilterEnabled = moveSuggestedFilterEnabled && noMoveSuggestedFilterEnabled;
  return (
    <div className={filterStyle.filterSection}>
      <div className={filterStyle.filterPanelHeader}>Suggestions&nbsp;<InfoIcon content="Curators can choose how Tamr generates suggestions for records." /></div>
      <div className={filterStyle.option}>
        <Checkbox
          className={filterStyle.checkboxWrapper}
          onChange={() => onToggleSuggestionsEnabled()}
          title={attachTotal('Suggestions enabled', numSuggestionsEnabled)}
          value={suggestionsEnabledFilterActive}
          size={15}
          titlePosition="right"
          disabled={!suggestionsEnabledFilterEnabled}
        />
      </div>
      <div className={filterStyle.subfiltersContainer}>
        <div className={filterStyle.option}>
          <Checkbox
            className={filterStyle.checkboxWrapper}
            onChange={() => onToggleMoveSuggested()}
            title={(
              <span>
                <FilterVerificationIcon verificationIconType={VERIFICATION_ICON_TYPE_SUGGESTABLE_HAS_SUGGESTION} />
                &nbsp;
                {attachTotal('Move suggested', numMoveSuggested)}
                &nbsp;
                <InfoIcon content="Records that Tamr suggests moving." />
              </span>
            )}
            value={moveSuggestedFilterActive}
            size={15}
            titlePosition="right"
            disabled={!moveSuggestedFilterEnabled}
          />
        </div>
        <div className={filterStyle.option}>
          <Checkbox
            className={filterStyle.checkboxWrapper}
            onChange={() => onToggleNoMoveSuggested()}
            title={(
              <span>
                <FilterVerificationIcon verificationIconType={VERIFICATION_ICON_TYPE_SUGGESTABLE_NO_SUGGESTION} />
                &nbsp;
                {attachTotal('No move suggested', numNoMoveSuggested)}
                &nbsp;
                <InfoIcon content="Records that Tamr does not suggest moving." />
              </span>
            )}
            value={noMoveSuggestedFilterActive}
            size={15}
            titlePosition="right"
            disabled={!noMoveSuggestedFilterEnabled}
          />
        </div>
      </div>
      <div className={filterStyle.option}>
        <Checkbox
          className={filterStyle.checkboxWrapper}
          onChange={() => onToggleSuggestionsDisabled()}
          title={(
            <span>
              <FilterVerificationIcon verificationIconType={VERIFICATION_ICON_TYPE_LOCKED} />
              &nbsp;
              {attachTotal('Suggestions disabled', numSuggestionsDisabled)}
              &nbsp;
              <InfoIcon content="Records for which Tamr will not make suggestions or move." />
            </span>
          )}
          value={suggestionsDisabledFilterActive}
          size={15}
          titlePosition="right"
          disabled={!suggestionsDisabledFilterEnabled}
        />
      </div>
      <div className={filterStyle.option}>
        <Checkbox
          className={filterStyle.checkboxWrapper}
          onChange={() => onToggleSuggestionsAutoAccepted()}
          title={<span>{attachTotal('Suggestions auto-accepted', numSuggestionsAutoAccepted)}&nbsp;<InfoIcon content="Records that Tamr will automatically move based on its own suggestions." /></span>}
          value={suggestionsAutoAcceptedFilterActive}
          size={15}
          titlePosition="right"
          disabled={!suggestionsAutoAcceptedFilterEnabled}
        />
      </div>
    </div>
  );
});

const VerificationFilterSection: React.FC<{
  pane: PaneE
}> = ({ pane }) => {
  return (
    <React.Fragment>
      <JustVerificationFilterSection {...{ pane }} />
      <SuggestionsFilterSection {...{ pane }} />
    </React.Fragment>
  );
};

export default VerificationFilterSection;
