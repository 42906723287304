import { List, Map } from 'immutable';
import PropTypes from 'prop-types';

import BulkTransform from '../../models/BulkTransform';
import Model from '../../models/Model';
import { ArgTypes } from '../../utils/ArgValidation';

// Imported in RecordApi
class TransformListPreview extends Model({
  transformList: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(BulkTransform)) },
  sampleDatasets: { type: ArgTypes.Immutable.map.of(ArgTypes.string, ArgTypes.string) },
}) {
  static fromJSON({ transformList, sampleDatasets }) {
    return new TransformListPreview({
      transformList: List(transformList).map(json => BulkTransform.fromJS(json)),
      sampleDatasets: Map(sampleDatasets),
    });
  }
  toJSON() {
    return {
      transformList: this.transformList.map((bulkTransform) => bulkTransform.toServerJSON()),
      sampleDatasets: this.sampleDatasets,
    };
  }
}
TransformListPreview.propType = PropTypes.instanceOf(TransformListPreview);

export default TransformListPreview;
