import { List, Set } from 'immutable';
import uri from 'urijs';

import { ClusterChangesE } from '../constants/ClusterChanges';
import { HighImpactTypeE } from '../constants/HighImpactType';
import { VerificationTypeFilterE } from '../constants/VerificationTypeFilter';
import Cluster from '../models/Cluster';
import DedupInfo from '../models/DedupInfo';
import Page from '../models/Page';
import { PairComment } from '../models/PairComment';
import PairCommentId from '../models/PairCommentId';
import PairEstimates from '../models/PairEstimates';
import PairId from '../models/PairId';
import RecordComment from '../models/RecordComment';
import RecordCommentId from '../models/RecordCommentId';
import { RecordPairsQuery } from '../models/RecordPairsQuery';
import RecordPairWithData from '../models/RecordPairWithData';
import ScoreThresholds from '../models/ScoreThresholds';
import Similarity from '../models/Similarity';
import { RecordPairFeedbackResponseTypeE } from '../pairs/RecordPairFeedbackResponseType';
import UserDefinedSignal from '../pairs/UserDefinedSignal';
import { GroupRecordsPage } from '../pregroup/GroupRecordsPage';
import { PreGroupBy } from '../pregroup/PreGroupBy';
import { PreGroupStats } from '../pregroup/PreGroupStats';
import { RecordGroup } from '../pregroup/RecordGroup';
import ClusterMember from '../suppliers/ClusterMember';
import ClusterMoveResponse from '../suppliers/ClusterMoveResponse';
import PublishedClusterVersions from '../suppliers/PublishedClusterVersions';
import VerificationType, { VerificationTypeE } from '../suppliers/VerificationType';
import {
  fetchAsResult,
  handleErrorsWithMessage,
  jsonHeaders,
  networkError,
  toJSON,
} from '../utils/Api';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { $TSFixMe, JsonArray, JsonObject } from '../utils/typescript';
import { FetchResult, toFetchResult } from './FetchResult';
import ServiceProxy from './ServiceProxy';

export function putRecordPairComments(comments: List<PairComment>, unifiedDatasetName: string): Promise<Response> {
  checkArg({ comments }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(PairComment)));
  checkArg({ unifiedDatasetName }, ArgTypes.string);
  return fetch(ServiceProxy.dedup(`/pairs/comment/${unifiedDatasetName}`),
    {
      headers: jsonHeaders,
      method: 'PUT',
      body: JSON.stringify(comments.toJSON()),
    }).then(handleErrorsWithMessage('Unable to edit comment'), networkError);
}

export function deleteRecordPairComments(commentIds: List<PairCommentId>, unifiedDatasetName: string): Promise<Response> {
  checkArg({ commentIds }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(PairCommentId)));
  checkArg({ unifiedDatasetName }, ArgTypes.string);
  return fetch(ServiceProxy.dedup(`/pairs/comment/${unifiedDatasetName}`),
    {
      headers: jsonHeaders,
      method: 'DELETE',
      body: JSON.stringify(commentIds.toJSON()),
    }).then(handleErrorsWithMessage('Unable to delete comment'), networkError);
}

function toNewlineDelimitedJSON(list: List<{ toJSON: Function }>): string {
  checkArg({ list }, ArgTypes.Immutable.list.of(ArgTypes.object));
  return list.map(model => JSON.stringify(model.toJSON())).join('\n');
}

export function fetchClusterMembers(datasetName: string, recordIds: List<string>): Promise<List<ClusterMember>> {
  checkArg({ datasetName }, ArgTypes.string);
  checkArg({ recordIds }, ArgTypes.Immutable.list.of(ArgTypes.string));
  return fetch(uri(ServiceProxy.dedup(`/clusters/${datasetName}/members`)).setSearch({ recordIds: recordIds.toArray() }).toString(), { headers: jsonHeaders, method: 'GET' })
    .then(handleErrorsWithMessage('Unable to fetch cluster members'), networkError)
    .then(toJSON)
    .then(clusterMembers => List(clusterMembers).map(ClusterMember.fromJSON));
}

export interface FetchClustersQueryParams {
  q?: string
  offset?: number
  limit?: number
  sort?: string[]
  clusterIds?: string[]
  sourceDatasets?: string[]
  username?: string
  resolved?: boolean
  averageLinkageRanges?: string[]
  clusterSimilarityThreshold?: number
  positiveVerificationFilters?: VerificationTypeFilterE[]
  negativeVerificationFilters?: VerificationTypeFilterE[]
  clusterChanges?: ClusterChangesE[]
  filterHighImpactClusters?: HighImpactTypeE
}

export function fetchClusters(
  unifiedDatasetName: string,
  queryParams: FetchClustersQueryParams,
): Promise<FetchResult<Page<Cluster>>> {
  return fetchAsResult(
    uri(ServiceProxy.dedup(`/clusters/${unifiedDatasetName}`)).query(queryParams).toString(),
    { headers: jsonHeaders, method: 'GET' })
    .then(toFetchResult(data => Page.fromJSON(data as JsonObject, Cluster.fromJSON)));
}

export function fetchClustersByIds(
  unifiedDatasetName: string,
  clusterIds: string[],
  queryParams: FetchClustersQueryParams,
): Promise<FetchResult<Cluster[]>> {
  return fetchAsResult(
    uri(ServiceProxy.dedup(`/clusters/byIds/${unifiedDatasetName}`)).query(queryParams).toString(),
    { headers: jsonHeaders, body: JSON.stringify(clusterIds), method: 'POST' })
    .then(toFetchResult(data => (data as JsonArray).map(Cluster.fromJSON)));
}

export function fetchTotalClustersCount(
  unifiedDatasetName: string,
): Promise<FetchResult<number>> {
  return fetchAsResult(ServiceProxy.dedup(`/clusters/${unifiedDatasetName}/total`), { headers: jsonHeaders, method: 'GET' })
    .then(toFetchResult(data => data as number));
}

// everything ClusterMember contains minus the datasetName field, as that has to be
//   (A) the same for all ClusterMembers in a single verify API call, and
//   (B) supplied in the path of verify API calls
// the Client methods here will create the proper ClusterMembers that the API expects
//   with these spec objects + the supplied datasetName
const ClusterMemberSpecType = ArgTypes.object.withShape({
  recordId: ArgTypes.string,
  verifiedClusterId: ArgTypes.nullable(ArgTypes.string),
  verificationType: ArgTypes.nullable(VerificationType.argType),
});
interface ClusterMemberSpec {
  recordId: string
  verifiedClusterId: string | null | undefined,
  verificationType: VerificationTypeE | null | undefined
}

export function verifyRecords({ unifiedDatasetName, clusterMembers, verificationType }: {
  unifiedDatasetName: string,
  clusterMembers: List<ClusterMemberSpec>,
  verificationType: VerificationTypeE | null | undefined,
}): Promise<number> {
  checkArg({ unifiedDatasetName }, ArgTypes.string);
  checkArg({ clusterMembers }, ArgTypes.Immutable.list.of(ClusterMemberSpecType));
  checkArg({ verificationType }, VerificationType.argType);
  // cast to ClusterMember to ensure types, then create newline delimited json
  const body = toNewlineDelimitedJSON(clusterMembers.map(spec => new ClusterMember({
    recordId: spec.recordId,
    verifiedClusterId: spec.verifiedClusterId,
    verificationType: spec.verificationType,
  })));
  return fetch(ServiceProxy.dedup(`/clusters/${unifiedDatasetName}/verify?&verificationType=${verificationType}`), { headers: jsonHeaders, method: 'POST', body })
    .then(handleErrorsWithMessage('Unable to apply verification to records'), networkError)
    .then(toJSON);
}

export function verifyClusters({ unifiedDatasetName, clusterIds, verificationType }: {
  unifiedDatasetName: string
  clusterIds: Set<string>
  verificationType: VerificationTypeE
}): Promise<number> {
  checkArg({ unifiedDatasetName }, ArgTypes.string);
  checkArg({ clusterIds }, ArgTypes.Immutable.set.of(ArgTypes.string));
  checkArg({ verificationType }, VerificationType.argType);
  const body = JSON.stringify(clusterIds.toArray());
  return fetch(ServiceProxy.dedup(`/clusters/${unifiedDatasetName}/verifyClusters?&verificationType=${verificationType}`), { headers: jsonHeaders, method: 'POST', body })
    .then(handleErrorsWithMessage('Unable to apply verification to clusters'), networkError)
    .then(toJSON);
}

export function moveClusterRecords({ unifiedDatasetName, clusterNameField, targetClusterId, verificationType, clusterMembers }: {
  unifiedDatasetName: string
  clusterNameField: string
  targetClusterId: string
  verificationType: VerificationTypeE
  clusterMembers: List<ClusterMemberSpec>
}): Promise<ClusterMoveResponse> {
  checkArg({ unifiedDatasetName }, ArgTypes.string);
  checkArg({ clusterNameField }, ArgTypes.string);
  checkArg({ targetClusterId }, ArgTypes.string);
  checkArg({ verificationType }, VerificationType.argType);
  checkArg({ clusterMembers }, ArgTypes.Immutable.list.of(ClusterMemberSpecType));
  // cast to ClusterMember to ensure types, then create newline delimited json
  const body = toNewlineDelimitedJSON(clusterMembers.map(spec => new ClusterMember({ ...spec })));
  return fetch(ServiceProxy.dedup(`/clusters/${unifiedDatasetName}/move?clusterNameField=${clusterNameField}&cluster=${targetClusterId}&verificationType=${verificationType}`), { headers: jsonHeaders, method: 'POST', body })
    .then(handleErrorsWithMessage('Unable to move cluster records'), networkError)
    .then(toJSON)
    .then(resp => new ClusterMoveResponse(resp));
}

export function moveClusterRecordsToNew({ unifiedDatasetName, clusterNameField, verificationType, clusterMembers }: {
  unifiedDatasetName: string
  clusterNameField: string
  verificationType: VerificationTypeE
  clusterMembers: List<ClusterMemberSpec>
}): Promise<ClusterMoveResponse> {
  checkArg({ unifiedDatasetName }, ArgTypes.string);
  checkArg({ clusterNameField }, ArgTypes.string);
  checkArg({ verificationType }, VerificationType.argType);
  checkArg({ clusterMembers }, ArgTypes.Immutable.list.of(ClusterMemberSpecType));
  // cast to ClusterMember to ensure types, then create newline delimited json
  const body = toNewlineDelimitedJSON(clusterMembers.map(spec => new ClusterMember({ ...spec })));
  return fetch(ServiceProxy.dedup(`/clusters/${unifiedDatasetName}/move-to-new?clusterNameField=${clusterNameField}&verificationType=${verificationType}`), { headers: jsonHeaders, method: 'POST', body })
    .then(handleErrorsWithMessage('Unable to move records to new cluster'), networkError)
    .then(toJSON)
    .then(resp => new ClusterMoveResponse(resp));
}

export async function fetchPregroupSample({ unifiedDatasetName, preGroupBy, maxGroupSize, limit, offset, noClusteringWithinSources }: {
  unifiedDatasetName: string;
  preGroupBy: PreGroupBy;
  maxGroupSize: number;
  limit?: number;
  offset?: number;
  noClusteringWithinSources?: string[];
}): Promise<RecordGroup[]> {
  const body = JSON.stringify(preGroupBy);
  return fetch(
    uri(ServiceProxy.dedup(`/pregrouping/sample/${unifiedDatasetName}`)).query({ maxGroupSize, limit, offset, noClusteringWithinSources }).toString(),
    { headers: jsonHeaders, method: 'POST', body },
  )
    .then(handleErrorsWithMessage('Unable to fetch sample for grouped records.'), networkError)
    .then(toJSON);
}

export async function fetchPregroupStats({ unifiedDatasetName, preGroupBy, noClusteringWithinSources }: {
  unifiedDatasetName: string;
  preGroupBy: PreGroupBy;
  noClusteringWithinSources?: string[];
}): Promise<PreGroupStats> {
  const body = JSON.stringify(preGroupBy);
  return fetch(
    uri(ServiceProxy.dedup(`/pregrouping/stats/${unifiedDatasetName}`)).query({ noClusteringWithinSources }).toString(),
    { headers: jsonHeaders, method: 'POST', body },
  )
    .then(handleErrorsWithMessage('Unable to fetch stats for grouped records.'), networkError)
    .then(toJSON);
}

export async function fetchGroupRecords({ unifiedDatasetName, limit, offset, groupIdByGroupingField }: {
  unifiedDatasetName: string;
  limit: number;
  offset: number;
  groupIdByGroupingField: { [field: string]: string | null }[];
}): Promise<GroupRecordsPage> {
  const body = groupIdByGroupingField.map(o => JSON.stringify(o)).join('\n');
  return fetch(
    uri(ServiceProxy.dedup(`/pregrouping/groupRecords/${unifiedDatasetName}`)).query({ limit, offset }).toString(),
    { headers: jsonHeaders, method: 'POST', body },
  )
    .then(handleErrorsWithMessage('Unable to fetch grouped records'), networkError)
    .then(toJSON)
    // NB casting here because toJSON returns `JsonContent` but we're expecting a GroupRecordsPage
    .then(data => Page.fromJSON(data, d => d) as GroupRecordsPage);
}

export async function fetchPairs(
  unifiedDatasetName: string,
  query: RecordPairsQuery,
): Promise<FetchResult<Page<RecordPairWithData>>> {
  const { offset, limit, queryString, datasetNames, otherRecordDatasetNames, attributeSimilaritySortOptions, attributeSimilarityFilters,
    userDefinedSignalsSortOptions, manualLabels, suggestedLabels, labelConsensus, suggestedLabelConfidence, highImpact,
    hasComments, hasResponses, assignmentStatusFilter, responseFilter, allAssignmentsFilterType,
    allResponsesFilterType, labelTypesFilter,
  } = query;
  return fetchAsResult(uri(ServiceProxy.dedup(`/pairs/${unifiedDatasetName}`)).query({
    offset,
    limit,
    q: queryString,
    datasetNames: datasetNames?.toArray(),
    otherRecordDatasetNames: otherRecordDatasetNames?.toArray(),
    attributeSimilaritySort: attributeSimilaritySortOptions?.toArray(),
    attributeSimilarityFilters: attributeSimilarityFilters?.map(f => f.toUrlStringForApi()).toArray(),
    userDefinedSignalsSort: userDefinedSignalsSortOptions?.toArray(),
    manualLabel: manualLabels?.toArray(),
    suggestedLabel: suggestedLabels?.toArray(),
    labelConsensus,
    highImpact,
    hasComments,
    suggestedLabelConfidence: suggestedLabelConfidence?.toUrlParam(),
    hasResponses,
    assignmentStatus: assignmentStatusFilter,
    response: responseFilter,
    allAssignments: allAssignmentsFilterType,
    allResponses: allResponsesFilterType,
    labelTypesFilter: labelTypesFilter?.toArray(),
  }).toString())
    .then(toFetchResult(data => Page.fromJSON<RecordPairWithData>(data as JsonObject, RecordPairWithData.fromJSON)));
}

export interface ClustersToMerge {
  clustersToMerge: string[]
  targetCluster: string
}

export function mergeClusters(
  unifiedDatasetName: string,
  clusterNameField: string,
  verificationType: VerificationTypeE,
  clustersToMerge: ClustersToMerge[],
): Promise<FetchResult<ClusterMoveResponse>> {
  const body = clustersToMerge.map(c => JSON.stringify(c)).join('\n');
  return fetchAsResult(
    uri(ServiceProxy.dedup(`/clusters/${unifiedDatasetName}/merge`))
      .query({ clusterNameField, verificationType }).toString(),
    { headers: jsonHeaders, method: 'POST', body })
    .then(toFetchResult(data => new ClusterMoveResponse(data as $TSFixMe)));
}

export interface ClusterAssignmentRequest {
  usernames: string[]
  clusterIds: string[]
}

export function assignClusters(
  unifiedDatasetName: string,
  assignment: ClusterAssignmentRequest,
): Promise<FetchResult<void>> {
  const body = JSON.stringify(assignment);
  return fetchAsResult(
    ServiceProxy.dedup(`/clusters/${unifiedDatasetName}/feedback/assign`),
    { headers: jsonHeaders, method: 'POST', body })
    .then(toFetchResult());
}

export function removeAssignments(
  unifiedDatasetName: string,
  assignmentsToRemove: ClusterAssignmentRequest,
): Promise<FetchResult<void>> {
  const body = JSON.stringify(assignmentsToRemove);
  return fetchAsResult(
    ServiceProxy.dedup(`/clusters/${unifiedDatasetName}/feedback/remove`),
    { headers: jsonHeaders, method: 'POST', body })
    .then(toFetchResult());
}

export function resolveAssignments(
  unifiedDatasetName: string,
  assignmentsToResolve: ClusterAssignmentRequest,
): Promise<FetchResult<void>> {
  const body = JSON.stringify(assignmentsToResolve);
  return fetchAsResult(
    ServiceProxy.dedup(`/clusters/${unifiedDatasetName}/feedback/resolve`),
    { headers: jsonHeaders, method: 'POST', body })
    .then(toFetchResult());
}

export function getClusterSimilarities(
  unifiedDatasetName: string,
  clusterId: string,
): Promise<FetchResult<List<Similarity>>> {
  return fetchAsResult(
    ServiceProxy.dedup(`/clusters/similarities/${unifiedDatasetName}/${clusterId}`),
    { headers: jsonHeaders, method: 'GET' })
    .then(toFetchResult(data => List(data as $TSFixMe).map(d => new Similarity(d as $TSFixMe))));
}

export function getPublishedClusterVersions(
  unifiedDatasetName: string,
  clusterIds: string[],
): Promise<FetchResult<PublishedClusterVersions>> {
  // newline delimited streaming format of cluster id strings
  const body = clusterIds.map(s => JSON.stringify(s)).join('\n');
  return fetchAsResult(
    ServiceProxy.dedup(`/mastering/published-cluster-versions/${unifiedDatasetName}`),
    { headers: jsonHeaders, method: 'POST', body })
    .then(toFetchResult(data => PublishedClusterVersions.fromJSON(data)));
}

export function getPairConfidenceThresholds(): Promise<FetchResult<ScoreThresholds>> {
  return fetchAsResult(ServiceProxy.dedup('/config/pair-confidence-thresholds'), { headers: jsonHeaders, method: 'GET' })
    .then(toFetchResult(data => new ScoreThresholds(data as $TSFixMe)));
}

export function getClusterConfidenceThresholds(): Promise<FetchResult<ScoreThresholds>> {
  return fetchAsResult(ServiceProxy.dedup('/config/cluster-confidence-thresholds'), { headers: jsonHeaders, method: 'GET' })
    .then(toFetchResult(data => new ScoreThresholds(data as $TSFixMe)));
}

// com.tamr.dedup.models.feedback.RecordPairFeedbackResponse
interface RecordPairFeedbackResponse {
  entityId1: string
  entityId2: string
  leftRootIds: string[]
  rightRootIds: string[]
  responseType: RecordPairFeedbackResponseTypeE
}

export function providePairFeedbackResponses(
  unifiedDatasetName: string,
  responseList: RecordPairFeedbackResponse[],
  verified: boolean,
): Promise<FetchResult<void /* TODO type more strongly as List<Document<RecordPairFeedback>> */>> {
  const body = JSON.stringify(responseList);
  return fetchAsResult(
    uri(ServiceProxy.dedup(`/pairs/feedback/response/${unifiedDatasetName}`)).query({ verified }).toString(),
    { headers: jsonHeaders, method: 'POST', body })
    .then(toFetchResult());
}

export function removePairFeedbackResponses(
  unifiedDatasetName: string,
  pairIds: PairId[],
  verified: boolean,
): Promise<FetchResult<void>> {
  const body = JSON.stringify(pairIds);
  return fetchAsResult(
    uri(ServiceProxy.dedup(`/pairs/feedback/response/remove/${unifiedDatasetName}`)).query({ verified }).toString(),
    { headers: jsonHeaders, method: 'POST', body })
    .then(toFetchResult());
}

// com.tamr.dedup.models.matching.UsernamePairId
interface UsernamePairId {
  entityId1: string
  entityId2: string
  username: string
}

export function assignPairFeedback(
  unifiedDatasetName: string,
  usernamePairIds: UsernamePairId[],
): Promise<FetchResult<void /* TODO type more strongly as List<Document<RecordPairFeedback>> */>> {
  const body = JSON.stringify(usernamePairIds);
  return fetchAsResult(ServiceProxy.dedup(`/pairs/feedback/assignment/${unifiedDatasetName}`),
    { headers: jsonHeaders, method: 'POST', body })
    .then(toFetchResult());
}

export function unassignPairFeedback(
  unifiedDatasetName: string,
  usernamePairIds: UsernamePairId[],
): Promise<FetchResult<void>> {
  const body = JSON.stringify(usernamePairIds);
  return fetchAsResult(ServiceProxy.dedup(`/pairs/feedback/assignment/remove/${unifiedDatasetName}`),
    { headers: jsonHeaders, method: 'POST', body })
    .then(toFetchResult());
}

export function getUserDefinedSignalsByDatasetName(
  unifiedDatasetName: string,
): Promise<FetchResult<List<UserDefinedSignal>>> {
  return fetchAsResult(ServiceProxy.dedup(`/userDefinedSignals/for-dataset/${unifiedDatasetName}`),
    { headers: jsonHeaders, method: 'GET' })
    .then(toFetchResult((data: $TSFixMe) => List(data).map(UserDefinedSignal.fromJSON)));
}

export function fetchDedupModel(
  unifiedDatasetName: string,
): Promise<FetchResult<void>> {
  return fetchAsResult(ServiceProxy.dedup(`/dedup-model/${unifiedDatasetName}/export`),
    { headers: jsonHeaders, method: 'GET' })
    .then(toFetchResult());
}

export function putRecordComment(
  comments: List<RecordComment>,
  unifiedDatasetName: string,
): Promise<Response> {
  return fetch(ServiceProxy.dedup(`/records/comment/${unifiedDatasetName}`),
    { headers: jsonHeaders,
      method: 'PUT',
      body: JSON.stringify(comments),
    }).then(handleErrorsWithMessage('Unable to edit comment'), networkError);
}

export function deleteRecordComment(
  commentIds: List<RecordCommentId>,
  unifiedDatasetName: string,
): Promise<Response> {
  return fetch(
    ServiceProxy.dedup(`/records/comment/${unifiedDatasetName}`),
    { headers: jsonHeaders,
      method: 'DELETE',
      body: JSON.stringify(commentIds),
    },
  ).then(handleErrorsWithMessage('Unable to delete comment'), networkError);
}

export function generateRecordPairs(
  unifiedDatasetName: string,
): Promise<Response> {
  return fetch(
    ServiceProxy.dedup(`/mastering/generate-pairs/${unifiedDatasetName}`),
    { headers: jsonHeaders,
      method: 'POST',
    },
  ).then(handleErrorsWithMessage('Unable to generate record pairs'), networkError);
}

export function predictCluster(
  unifiedDatasetName: string,
): Promise<Response> {
  return fetch(
    ServiceProxy.dedup(`/mastering/predict-cluster/${unifiedDatasetName}`),
    { headers: jsonHeaders,
      method: 'POST',
    },
  ).then(handleErrorsWithMessage('Unable to predict clusters'), networkError);
}

export function trainPredictCluster(
  unifiedDatasetName: string,
): Promise<Response> {
  return fetch(
    ServiceProxy.dedup(`/mastering/train-predict-cluster/${unifiedDatasetName}`),
    { headers: jsonHeaders,
      method: 'POST',
    },
  ).then(handleErrorsWithMessage('Unable to predict clusters'), networkError);
}

export function putDedupInfo(
  dedupInfo: DedupInfo,
): Promise<Response> {
  return fetch(
    uri(ServiceProxy.dedup('/mastering/configs')).toString(),
    { headers: jsonHeaders,
      method: 'PUT',
      body: JSON.stringify(dedupInfo),
    },
  ).then(handleErrorsWithMessage('Unable to put dedupInfo'), networkError);
}

export function getDedupInfo(
  unifiedDatasetName: string,
): Promise<DedupInfo> {
  return fetch(
    ServiceProxy.dedup(`/mastering/configs/${unifiedDatasetName}`),
    { headers: jsonHeaders,
      method: 'GET',
    },
  ).then(handleErrorsWithMessage('Unable to put dedupInfo'), networkError)
    .then(toJSON)
    .then(d => DedupInfo.fromJSON(d));
}

export function getDnfPairEstimates(
  unifiedDatasetName: string,
): Promise<PairEstimates> {
  return fetch(
    ServiceProxy.dedup(`/mastering/extract-estimated-pair-counts/${unifiedDatasetName}`),
    { headers: jsonHeaders,
      method: 'GET',
    },
  ).then(handleErrorsWithMessage('Unable to get pair estimates'), networkError)
    .then(toJSON)
    .then(data => PairEstimates.fromJSON(data));
}
