import React from 'react';
import { Subtract } from 'utility-types';

export interface InjectedKeyStateProps {
  shiftKey: boolean;
}

type KeyStateState = {
  shiftKey: boolean
}

const withKeyState = <P extends InjectedKeyStateProps>(Component: React.ComponentType<P>) =>
  class KeyStateWrapper extends React.Component<
    Subtract<P, InjectedKeyStateProps>,
    KeyStateState
  > {
    state = { shiftKey: false };

    onKeyDown = (e: KeyboardEvent) => {
      if (e.keyCode === 16) {
        this.setState({ shiftKey: true });
      }
    };

    onKeyUp = (e: KeyboardEvent) => {
      if (e.keyCode === 16) {
        this.setState({ shiftKey: false });
      }
    };

    UNSAFE_componentWillMount() {
      window.addEventListener('keydown', this.onKeyDown);
      window.addEventListener('keyup', this.onKeyUp);
    }

    componentWillUnmount() {
      window.removeEventListener('keydown', this.onKeyDown);
      window.removeEventListener('keyup', this.onKeyUp);
    }

    render() {
      return <Component {...this.props as P} shiftKey={this.state.shiftKey} />;
    }
  };

export default withKeyState;
