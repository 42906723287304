// Mapping of intenral page names to user facing page titles

// If values in titleMap are updated, they must also be updated in
// com.tamr.procurify.service.extension.ExtensionService.PageNames

export const pageTitleMap : { [name: string]: string } = {
  index: 'Home',
  datasetCatalog: ' Dataset Catalog ',
  jobs: 'Jobs',
  users: 'Users and Groups',
  projectDatasetCatalog: 'Project Datasets',
  groupBy: ' Dataset Grouping ',
  schemaMapping: ' Schema Mapping ',
  records: 'Unified Dataset',
  dashboard: 'Dashboard',
  taxonomy: ' Taxonomy',
  categoryDetail: ' Category Details',
  spend: ' Categorizations',
  dnfBuilder: ' Binning',
  pairs: ' Pairs',
  suppliers: ' Clusters',
  projectStatus: 'Project Status',
  goldenrecords: 'Golden Records',
  grRules: 'Rules',
  policies: 'Policies',
  enrichment: 'Enrichment',
  pregroup: ' Group Records',
  notfound: ' Tamr-Page Not Found' };
