import { Set } from 'immutable';

import { ORIGIN_ENTITY_ID as ES_ORIGIN_ENTITY_ID, ORIGIN_SOURCE_NAME as ES_ORIGIN_SOURCE_NAME, TAMR_ID as ES_TAMR_ID } from '../../constants/ElasticConstants';
import PipelineConstants from '../../constants/PipelineConstants';


export const RESERVED_ATTRIBUTE_NAMES = Set.of(
  ES_TAMR_ID,
  ES_ORIGIN_SOURCE_NAME,
  ES_ORIGIN_ENTITY_ID,
).concat(PipelineConstants)
  .map(f => f.toLowerCase());

export function isReserved(fieldName: string) {
  const sanitized = fieldName.toLowerCase().replace(' ', '_');
  return RESERVED_ATTRIBUTE_NAMES.has(sanitized);
}
