import classNames from 'classnames';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Checkbox from '../components/Input/Checkbox';
import TamrIcon from '../components/TamrIcon';
import Toolbar from '../components/Toolbar';
import { AppState } from '../stores/MainStore';
import style from './GoldenRecord.module.scss';
import { hideBookmarksOnboardingMessage } from './GoldenRecordsAsync';
import { selectShowBookmarksOnboardingMessage } from './GoldenRecordsSelectors';


const BookmarksOnboardingMessageDialog = connect((state: AppState) => {
  return { show: selectShowBookmarksOnboardingMessage(state) };
}, {
  onHide: (permanently: boolean) => hideBookmarksOnboardingMessage(permanently),
})(({ show, onHide }) => {
  const [checked, setChecked] = useState(false);
  return (
    <Dialog
      {...{ show }}
      onHide={() => onHide(false) /* dismissing dialog by clicking outside dialog body will not ever hide permanently */}
      dialogStyle={DialogStyle.PRIMARY}
      title="Select entities to preview edits to your rules"
      body={(
        <div>
          Add bookmarks to entities on your Golden Records page to view those entities on your Rules page.
          Click on the <TamrIcon iconName={'bookmark'} size={12} />icon next to an entity to add it to your bookmarks, or select multiple rows and use the bookmark buttons above the table.
          <br /><br />
          Once on your Rules page, you can preview how edits to your rules would impact the bookmarked entities.
        </div>
      )}
      footer={(
        <Toolbar
          left={(
            <Checkbox
              onChange={(c) => setChecked(c)}
              titlePosition="right"
              title="Don't show this message again"
              className={classNames({ [style.checkedCheckbox]: checked })}
            />
          )}
          right={(
            <Button onClick={() => onHide(checked)}>
              Okay
            </Button>
          )}
        />
      )}
    />
  );
});

export default BookmarksOnboardingMessageDialog;
