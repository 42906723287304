import Model from '../models/Model';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { AggregationsEnum } from './GroupByAggregations';

class GroupByEntry extends Model({
  fieldName: { type: ArgTypes.string },
  aggregationName: { type: ArgTypes.string },
  aggregationType: { type: AggregationsEnum.argType },
  groupBy: { type: ArgTypes.bool },
  selected: { type: ArgTypes.bool },
}) {
  constructor() { super(...arguments); }

  static fromJSON(obj) {
    checkArg({ obj }, ArgTypes.object);
    return new GroupByEntry(obj);
  }

  getAggregation() {
    const { fieldName, aggregationName, aggregationType } = this;
    return { fieldName, aggregationName, '@class': aggregationType };
  }
}

export default GroupByEntry;
