import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import MinimalAuthUser from './MinimalAuthUser';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

class SSOEnabled extends getModelHelpers({
  enabled: { type: ArgTypes.bool },
  authenticatedUser: { type: ArgTypes.nullable(ArgTypes.instanceOf(MinimalAuthUser)) },
}, 'SSOEnabled')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class SSOEnabledRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  static fromJSON(obj: $TSFixMe) {
    return new SSOEnabled({
      enabled: obj.enabled,
      authenticatedUser: MinimalAuthUser.fromJSON(obj.authenticatedUser),
    });
  }
}

export default SSOEnabled;
