import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { pure } from 'recompose';
import _ from 'underscore';

import Model from '../models/Model';
import { ArgTypes } from '../utils/ArgValidation';
import DashboardCard from './DashboardCard';
import TitleLabel from './TitleLabel';

export const Segment = Model({
  value: { type: ArgTypes.number },
  label: { type: ArgTypes.string },
  onClick: { type: ArgTypes.orUndefined(ArgTypes.func) },
});

const SegmentedBarGraphCard = _.compose(
  pure,
)(class SegmentedBarGraphCard extends React.Component {
  static propTypes = {
    className: PropTypes.string.isRequired,
    mappingGraph: PropTypes.bool,
    onClick: PropTypes.func,
    segments: ImmutablePropTypes.listOf(PropTypes.instanceOf(Segment)).isRequired,
    short: PropTypes.bool,
    showLabelValues: PropTypes.bool,
    totalMappedValue: PropTypes.number,
    totalNounPlural: PropTypes.string.isRequired,
    totalNounSingular: PropTypes.string.isRequired,
    totalValue: PropTypes.number.isRequired,
  };

  static defaultProps = { short: false, showLabelValues: false };

  stopPropagation = (callback, e) => {
    e.stopPropagation();
    callback();
  };

  renderTotal = () => {
    const { mappingGraph, totalMappedValue, totalValue, totalNounSingular, totalNounPlural } = this.props;
    return <TitleLabel value={mappingGraph ? totalMappedValue : totalValue} singular={totalNounSingular} plural={totalNounPlural} />;
  };

  renderGraph = () => {
    const { segments, totalValue } = this.props;
    if (totalValue === 0) {
      return undefined;
    }
    const segmentElements = segments.map((segment, i) => {
      const key = `segment-${i}`;
      const colorKey = `segment-${i}-color`;
      const widthProportion = segment.value / totalValue;
      const className = classNames('segment', key, colorKey, { clickable: !!segment.onClick });
      const onClick = segment.onClick ? _.partial(this.stopPropagation, segment.onClick) : undefined;
      return (
        <div
          key={key}
          className={className}
          style={{ width: `${widthProportion * 100}%` }}
          onClick={onClick}
        />
      );
    });
    return (
      <div className="graph">
        {segmentElements}
      </div>
    );
  };

  renderLabels = () => {
    const { totalValue, segments, showLabelValues } = this.props;
    if (totalValue === 0) {
      return undefined;
    }
    const labelElements = segments.map((segment, i) => {
      const key = `segment-${i}`;
      const colorKey = `segment-${i}-color`;
      const className = classNames('segment-label', key, {
        left: i === 0,
        middle: i > 0 && i < segments.size - 1,
        right: i === segments.size - 1,
      });
      const swatchClassName = classNames('swatch', colorKey);
      let labelMessage = segment.label;
      if (showLabelValues) {
        labelMessage = `${segment.value} ${segment.label}`;
      }
      return (
        <div key={key} className={className}>
          <div className={swatchClassName} />
          <div className="label-message" title={labelMessage}>{labelMessage}</div>
        </div>
      );
    });
    return (
      <div className="labels">
        {labelElements}
      </div>
    );
  };

  render() {
    const className = classNames('dashboard-segmented-bar-graph-card', this.props.className, {
      short: this.props.short,
    });
    return (
      <DashboardCard className={className} onClick={this.props.onClick}>
        {this.renderTotal()}
        {this.renderGraph()}
        {this.renderLabels()}
      </DashboardCard>
    );
  }
});

export default SegmentedBarGraphCard;
