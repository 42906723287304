import _ from 'underscore';

import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';
import ScoreThresholds from './ScoreThresholds';

// com.tamr.procurify.models.categorization.EsCategorization

class EsCategorization extends getModelHelpers({
  categoryId: { type: ArgTypes.number },
  username: { type: ArgTypes.string },
  timestamp: { type: ArgTypes.number },
  score: { type: ArgTypes.nullable(ArgTypes.number) },
  reason: { type: ArgTypes.nullable(ArgTypes.string) },
  highImpact: { type: ArgTypes.any /* TODO */ },
}, 'EsCategorization')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class EsCategorizationRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  get isSuggested() {
    return _.isNumber(this.score);
  }

  get isManual() {
    return !this.isSuggested;
  }

  get isHighImpact() {
    return !!this.highImpact;
  }
  getStrengthSymbol(strengthThresholds: ScoreThresholds) {
    checkArg({ strengthThresholds }, ArgTypes.instanceOf(ScoreThresholds));
    const { score, isSuggested } = this;
    if (isSuggested && _.isNumber(score)) {
      return strengthThresholds.getSymbol(score);
    }
  }
  static fromJSON(obj: any) {
    return new EsCategorization({
      ...obj,
      timestamp: _.isNumber(obj.timestamp) ? obj.timestamp : Date.parse(obj.timestamp), // sometimes it comes in as a date string!
      highImpact: obj.highImpactRank, // unsure why we had this rename, but keeping it
    });
  }
}

export default EsCategorization;
