import 'whatwg-fetch'; // Import to enable "fetch" polyfill

import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
// @ts-expect-error
import AuthenticatedAppPage from '../auth/AuthenticatedAppPage';
// @ts-expect-error
import SSOWrapper from '../sso/SSOWrapper';
import MainStore from '../stores/MainStore';
import PageTracker from './PageTracker';
import ProjectChangeMonitor from './ProjectChangeMonitor';
import Router from './Router';
import UrlManager from './UrlManager';
import UsersContainer from './UsersContainer';

// Create a client
const queryClient = new QueryClient();
const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={MainStore}>
        <DndProvider backend={HTML5Backend}>
          <div>
            <PageTracker />
            <UrlManager />
            <ProjectChangeMonitor />
            <SSOWrapper>
              <AuthenticatedAppPage>
                <UsersContainer>
                  <Router />
                </UsersContainer>
              </AuthenticatedAppPage>
            </SSOWrapper>
          </div>
        </DndProvider>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default (App);
