import { ArgTypes } from '../utils/ArgValidation';
import { checkTypesAndCast } from '../utils/CheckTypesAndCast';

export interface EnricherOutputField {
  name: string
  type: string
  description: string
}

export const argTypes = {
  name: ArgTypes.string,
  type: ArgTypes.string,
  description: ArgTypes.string,
} as const;

export const fromJSON = checkTypesAndCast<EnricherOutputField, typeof argTypes>(argTypes);
export const argType = ArgTypes.object.withShape(argTypes);
