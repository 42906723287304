import './Input.scss';

import classNames from 'classnames';
import React from 'react';

import { HTMLInputProps } from '../../utils/typescript-react';

const PlainInput: React.FC<HTMLInputProps & {
  className?: string
  invalid?: boolean
}> = ({ className, ...props }) => {
  return (
    <input {...props} className={classNames('tamr-plain-input', { invalid: props.invalid }, className)} />
  );
};

export default PlainInput;
