import { HIGH, LOW, MEDIUM } from '../constants/ConfidenceRange';
import enumify from '../constants/enumify';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';
import { UnitRange } from './UnitRange';

export enum SymbolE {
  H = 'H',
  M = 'M',
  L = 'L',
}
const { H, M, L } = SymbolE;
export const Symbol = enumify({ H, M, L });

class ScoreThresholds extends getModelHelpers({
  high: { type: ArgTypes.number.inRange(0, 1) },
  medium: { type: ArgTypes.number.inRange(0, 1) },
}, 'ScoreThresholds')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ScoreThresholdsRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      const { medium, high } = args;
      checkArg({ medium }, ArgTypes.number.lessThanOrEqualTo(high));
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  get lowUnitRange(): UnitRange { return new UnitRange(0, this.medium); }
  get mediumUnitRange(): UnitRange { return new UnitRange(this.medium, this.high); }
  get highUnitRange(): UnitRange { return new UnitRange(this.high, 1); }

  get lowRange() { return this.lowUnitRange.toUrlParam(); }
  get mediumRange() { return this.mediumUnitRange.toUrlParam(); }
  get highRange() { return this.highUnitRange.toUrlParam(); }

  // return 'L', 'M', or 'M' according to which bucket input score falls in
  getSymbol(score: number) {
    checkArg({ score }, ArgTypes.number.inRange(0, 1));
    let symbol = Symbol.L;
    if (score >= this.high) {
      symbol = Symbol.H;
    } else if (score >= this.medium) {
      symbol = Symbol.M;
    }
    return symbol;
  }

  // based on a string in the form "{lowerBound} {upperBound}", return the range the bounds
  // correspond to
  getConfidenceRange(unitRange: string) {
    checkArg({ unitRange }, ArgTypes.string);
    const bounds = unitRange.split(' ');
    const lowerBound = parseFloat(bounds[0]);
    const upperBound = parseFloat(bounds[1]);
    if (lowerBound === 0 && upperBound === this.medium) {
      return LOW;
    }
    if (lowerBound === this.medium && upperBound === this.high) {
      return MEDIUM;
    }
    if (lowerBound === this.high && upperBound === 1) {
      return HIGH;
    }
    return undefined; // does not match this ScoreThresholds
  }
}

export default ScoreThresholds;
