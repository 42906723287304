import { is } from 'immutable';

import createLoader from '../utils/createLoader';
import { fetchAllPolicies } from './AccessControlAsync';
import { getCurrentFilterAndCacheInfo, getLoadedFilterAndCacheInfo } from './AccessControlStore';


const PoliciesLoader = createLoader(state => {
  const { accessControl: { loadingPolicies } } = state;
  return {
    canFetch: true,
    shouldFetch: !is(getCurrentFilterAndCacheInfo(state), getLoadedFilterAndCacheInfo(state)),
    loading: loadingPolicies,
  };
}, {
  onFetch: fetchAllPolicies,
}, 'PoliciesLoader');

export default PoliciesLoader;
