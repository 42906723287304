import { List, Set } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';

import ColumnOrderSelector from '../components/ColumnOrderSelector';
import ColumnWidthProvider from '../components/ColumnWidthProvider';
import LoadingPanel from '../components/LoadingPanel';
import Table from '../components/Table/Table';
import * as DataTables from '../constants/DataTables';
import { AppAction } from '../stores/AppAction';
import { AppState } from '../stores/MainStore';
import { getUrlForPage } from '../utils/Routing';
import { profileInputDataset } from './GoldenRecordsAsync';
import { selectPreviewTableColumns, setColumnPreferences, setColumnWidth } from './GoldenRecordsColumns';
import * as GoldenRecordsModule from './GoldenRecordsModule';
import style from './GoldenRecordsRules.module.scss';
import { UPDATE_DESIRED_SAMPLE_CLUSTER } from './GoldenRecordsRulesActionTypes';
import { getOnboardingLink, getUpdateClusterProfileJobIsRunningOrSubmitting, selectBookmarkedClusterIds } from './GoldenRecordsSelectors';
import { columnKeyToAttributeName, selectIsPreviewKnownToBeUsable } from './GoldenRecordsStore';
import { PreviewTableRuleCell, RuleColumnHeader, ruleColumns } from './GoldenRecordTableComponents';


const getPreviewedModuleSuggestedRuleNames = (previewedModule: GoldenRecordsModule.GoldenRecordsModule | null): Set<string> => {
  if (previewedModule) {
    const { entityRule, rules } = previewedModule;
    const suggestedRuleNames = Set(rules.filter(r => r.suggested).map(r => r.outputAttributeName));
    return entityRule.suggested ? suggestedRuleNames.add(entityRule.outputAttributeName) : suggestedRuleNames;
  }
  return Set();
};

export function pagerWithoutPages(content: JSX.Element) {
  return {
    // these are all fake, only to get the pager bar to show up
    // these controls are hidden by the scss layer
    // TODO update Table.js to allow a Pager without paging controls
    onPageSizeChange: () => {},
    onPageChange: () => {},
    pageSizes: List(),
    pagerState: { pageSize: 0, pageCount: 0, pageNum: 0 },
    // this one's real
    pagerContent: content,
  };
}

const GoldenRecordsRulesTable = connect((state: AppState) => {
  const {
    goldenRecordsRules: { bookmarkedClustersTooLarge, loadingPreviewInputRecords, loadingPreview, previewTable, previewedModule },
    goldenRecords,
    goldenRecords: { goldenRecordDocument },
  } = state;
  const columnSettings = selectPreviewTableColumns(state);
  const clusterIdColumnName = goldenRecordDocument?.data.clusterDataset.clusterColumn;
  const previewIsUsable = selectIsPreviewKnownToBeUsable(goldenRecords);
  const updateClusterProfileJobIsRunning = getUpdateClusterProfileJobIsRunningOrSubmitting(state);
  const noBookmarkedClusters = selectBookmarkedClusterIds(state).isEmpty();
  const linkToAllRecordsPage = getUrlForPage(state, 'goldenrecords');
  const linkToAllRecordsPageWithOnboarding = getOnboardingLink(state);
  const suggestedRuleNames = getPreviewedModuleSuggestedRuleNames(previewedModule);
  return {
    updateClusterProfileJobIsRunning,
    previewIsUsable,
    columnSettings,
    loadingPreviewInputRecords,
    loadingPreview,
    previewTable,
    clusterIdColumnName,
    noBookmarkedClusters,
    bookmarkedClustersTooLarge,
    linkToAllRecordsPage,
    linkToAllRecordsPageWithOnboarding,
    suggestedRuleNames,
  };
}, {
  onSetColumnWidth: setColumnWidth,
  onSetColumnPreferences: setColumnPreferences,
  onProfileInputDataset: profileInputDataset,
  onFetchClusterSample: (rowNumber: number): AppAction => ({ type: UPDATE_DESIRED_SAMPLE_CLUSTER, rowNumber }),
})(({ onFetchClusterSample, onProfileInputDataset, linkToAllRecordsPage, linkToAllRecordsPageWithOnboarding, bookmarkedClustersTooLarge, noBookmarkedClusters, updateClusterProfileJobIsRunning, previewIsUsable, columnSettings, loadingPreviewInputRecords, loadingPreview, previewTable, onSetColumnWidth, onSetColumnPreferences, clusterIdColumnName, suggestedRuleNames }) => {
  if (!columnSettings || !clusterIdColumnName) return null;

  const pagerProps = pagerWithoutPages(
    <ColumnOrderSelector
      columnSettings={columnSettings.slice(1) /* cannot configure entity rule column */}
      onSetColumnPreferences={(page, columnPrefs) => onSetColumnPreferences(columnPrefs.insert(0, columnSettings.get(0))) /* put the entity rule column back in */}
      pageType={DataTables.GOLDEN_RECORDS}
      numPinnedColumns={1}
    />,
  );
  return (
    <div className={style.tableContainer}>
      {/* Loading panels that would show on top of a table */}
      {loadingPreviewInputRecords ? (
        <LoadingPanel message="Fetching input records" />
      ) : loadingPreview ? (
        <LoadingPanel message="Fetching preview table" />
      ) : null}
      {/* Conditions under which we would just show a message instead of the table */}
      {!previewIsUsable && !updateClusterProfileJobIsRunning ? (
        <div className={style.tableWarning}>Please&nbsp;<a onClick={onProfileInputDataset}>profile</a>&nbsp;your input dataset</div>
      ) : !previewIsUsable && updateClusterProfileJobIsRunning ? (
        <LoadingPanel message="Profiling" />
      ) : noBookmarkedClusters ? (
        <div className={style.tableWarning}>You have no bookmarks. Please go to your&nbsp;<a href={linkToAllRecordsPageWithOnboarding}>Golden Records</a>&nbsp;to select them.</div>
      ) : bookmarkedClustersTooLarge ? (
        <div className={style.tableWarning}>Your bookmarked clusters are too large to preview. Please go to your&nbsp;<a href={linkToAllRecordsPage}>Golden Records</a>&nbsp;to change them.</div>
      ) : (
        <AutoSizer>
          {({ width, height }) => (
            <ColumnWidthProvider>
              <Table
                className={style.table}
                tableType="stripes"
                getLength={() => (previewTable ? previewTable.size : 0)}
                {... { height, width }}
                rowHeight={56}
                pagerHeight={48}
                onColumnResizeEndCallback={(newWidth, columnKey) => onSetColumnWidth(newWidth, columnKeyToAttributeName(columnKey))}
                onActiveCellChange={(e, rowNum) => {
                  onFetchClusterSample(rowNum);
                }}
                {...pagerProps}
              >
                {ruleColumns({
                  columnSettings,
                  clusterIdColumnName,
                  getIsSuggested: (attributeName) => suggestedRuleNames.has(attributeName),
                  renderHeader: ({ attributeName, displayName, isSuggested }) => (
                    <RuleColumnHeader
                      {...{ attributeName, displayName, isSuggested }}
                      className={style.headerCell}
                    />
                  ),
                  renderCell: ({ columnKey, rowIndex }) => <PreviewTableRuleCell {...{ columnKey, rowIndex }} />,
                })}
              </Table>
            </ColumnWidthProvider>
          )}
        </AutoSizer>
      )}
    </div>
  );
});

export default GoldenRecordsRulesTable;
