import enumify from '../constants/enumify';
import { checkArg } from '../utils/ArgValidation';

export const AVRO = 'avro/binary';
export const BIGQUERY = 'bigquery';
export const CSV = 'text/csv';
export const DIRECTORY = 'inode/directory';
export const PARQUET = 'parquet';

const MimeTypeValues = {
  AVRO,
  BIGQUERY,
  CSV,
  DIRECTORY,
  PARQUET,
} as const;
export type MimeTypeE = typeof MimeTypeValues[keyof typeof MimeTypeValues];

// TODO remove enumify usage, only export the const object and type
const MimeType = enumify({ AVRO, BIGQUERY, CSV, DIRECTORY, PARQUET });

export const getDisplayName = (mimeType: MimeTypeE) => {
  checkArg({ mimeType }, MimeType.argType);
  switch (mimeType) {
    case AVRO:
      return 'Avro';
    case BIGQUERY:
      return 'BigQuery';
    case CSV:
      return 'CSV';
    case PARQUET:
      return 'Parquet';
    default:
      return mimeType;
  }
};

export default MimeType;
