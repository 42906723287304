import { List } from 'immutable';

import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../../models/Model';
import { RecordType } from '../../transforms/models/Types';
import { ArgTypes, Checker } from '../../utils/ArgValidation';
import { $TSFixMe, JsonObject } from '../../utils/typescript';

// com.tamr.transform.models.functions.Example
class Example extends getModelHelpers({
  statement: { type: ArgTypes.string },
  inputType: { type: RecordType.argType },
  inputTable: { type: ArgTypes.Immutable.list.of(ArgTypes.object) as Checker<List<JsonObject>> },
  outputType: { type: RecordType.argType },
  outputTable: { type: ArgTypes.Immutable.list.of(ArgTypes.object) as Checker<List<JsonObject>> },
  description: { type: ArgTypes.nullable(ArgTypes.string) },
}, 'Example')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ExampleRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(json: $TSFixMe) {
    const { statement, inputType, inputTable, outputType, outputTable, description } = json;
    return new Example({
      statement,
      description,
      inputType: RecordType.fromJSON(inputType),
      inputTable: List(inputTable),
      outputType: RecordType.fromJSON(outputType),
      outputTable: List(outputTable),
    });
  }
}

export default Example;
