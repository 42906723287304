import { List } from 'immutable';

import { ArgTypes, checkArg, Checker } from '../utils/ArgValidation';
import { $TSFixMe, JsonContent, JsonObject } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

class Page<T> extends getModelHelpers({
  offset: { type: ArgTypes.number },
  limit: { type: ArgTypes.number },
  total: { type: ArgTypes.number },
  items: { type: ArgTypes.Immutable.list as Checker<List<any>> },
}, 'Page')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class PageRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  items: List<T>;
  static get argType() {
    return ArgTypes.instanceOf(this);
  }

  static fromJSON<T>(
    obj: JsonObject,
    dataConstructor: (d: JsonContent) => T,
  ) {
    checkArg({ obj }, ArgTypes.object);
    const offset = obj.offset as number;
    const limit = obj.limit as number;
    const total = obj.total as number;
    const items = obj.items as JsonContent[];
    return new Page<T>({
      offset,
      limit,
      total,
      items: List(items).map(item => dataConstructor(item)),
    });
  }

  static argTypeWithNestedClass = function<T extends { new (...args: any): InstanceType<T> }>(clazz: T): Checker<Page<T>> {
    return function (page: $TSFixMe) {
      const docCheckerStatus = Page.argType(page);
      if (docCheckerStatus) {
        return docCheckerStatus;
      }
      const dataCheckerStatus = ArgTypes.Immutable.list.of(ArgTypes.instanceOf(clazz))(page.items);
      if (dataCheckerStatus) {
        dataCheckerStatus.argName = argName => `the data within ${argName}`;
        return dataCheckerStatus;
      }
      return null;
    };
  };
}

export default Page;
