import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import { EnricherRegistration } from '../models/EnricherRegistration';
import { StoreReducers } from '../stores/AppAction';
import {
  CREATE_MODULE,
  CREATE_MODULE_COMPLETED,
  CREATE_MODULE_FAILED,
  FETCH_INPUT_DATASET,
  FETCH_INPUT_DATASET_COMPLETED,
  FETCH_INPUT_DATASET_FAILED,
  FETCH_MODULE,
  FETCH_MODULE_COMPLETED,
  FETCH_MODULE_FAILED,
  FETCH_MODULE_STATUS_COMPLETED,
  FETCH_TYPES,
  FETCH_TYPES_COMPLETED,
  FETCH_TYPES_FAILED,
  OUTPUT_DATASET_NAME_INVALID,
  OUTPUT_DATASET_NAME_VALID,
  SET_DATASET_NAME,
  SET_FIELD_MAPPING,
  SET_OUTPUT_FIELDS,
  SET_TYPE,
  SUBMIT_PUBLISH,
  SUBMIT_PUBLISH_COMPLETED,
  SUBMIT_PUBLISH_FAILED,
  UPDATE_MODULE,
  UPDATE_MODULE_COMPLETED,
  UPDATE_MODULE_FAILED,
} from './EnrichmentActionTypes';
import { EnrichmentModule } from './EnrichmentModule';

interface EnrichmentStore {
  module: Document<EnrichmentModule> | undefined
  moduleHasBeenSubmitted: boolean
  moduleLoading: boolean
  moduleLoadError: string | undefined
  inputDataset: Document<Dataset> | undefined
  inputDatasetLoading: boolean
  inputDatasetLoadError: string | undefined
  types: EnricherRegistration[]
  typesLoading: boolean
  typesLoadError: string | undefined
  unifiedDatasetName: string | undefined
  submitting: boolean
  isOutputNameValid: boolean
}

export const initialState: EnrichmentStore = {
  module: undefined,
  moduleHasBeenSubmitted: false,
  moduleLoading: false,
  moduleLoadError: undefined,
  inputDataset: undefined,
  inputDatasetLoading: false,
  inputDatasetLoadError: undefined,
  types: [],
  typesLoading: false,
  typesLoadError: undefined,
  unifiedDatasetName: undefined,
  submitting: false,
  isOutputNameValid: true,
};

export const reducers: StoreReducers<EnrichmentStore> = {
  [FETCH_MODULE]: (store) => {
    return { ...store, moduleLoading: true, moduleHasBeenSubmitted: false };
  },
  [FETCH_MODULE_COMPLETED]: (store, { module }) => {
    return { ...store, moduleLoading: false, moduleLoadError: undefined, module };
  },
  [FETCH_MODULE_STATUS_COMPLETED]: (store, { moduleHasBeenSubmitted }) => {
    return { ...store, moduleHasBeenSubmitted };
  },
  [FETCH_MODULE_FAILED]: (store, { errorMessage }) => {
    return { ...store, moduleLoading: false, moduleLoadError: errorMessage, inputDataset: undefined };
  },
  [FETCH_INPUT_DATASET]: (store) => {
    return { ...store, inputDatasetLoading: true };
  },
  [FETCH_INPUT_DATASET_COMPLETED]: (store, { inputDataset }) => {
    return { ...store, inputDatasetLoading: false, inputDatasetLoadError: undefined, inputDataset };
  },
  [FETCH_INPUT_DATASET_FAILED]: (store, { errorMessage }) => {
    return { ...store, inputDatasetLoading: false, inputDatasetLoadError: errorMessage };
  },
  [FETCH_TYPES]: (store) => {
    return { ...store, typesLoading: true };
  },
  [FETCH_TYPES_COMPLETED]: (store, { types }) => {
    return { ...store, typesLoading: false, typesLoadError: undefined, types };
  },
  [FETCH_TYPES_FAILED]: (store, { errorMessage }) => {
    return { ...store, typesLoading: false, typesLoadError: errorMessage };
  },
  [OUTPUT_DATASET_NAME_VALID]: (store) => {
    return { ...store, isOutputNameValid: true };
  },
  [OUTPUT_DATASET_NAME_INVALID]: (store) => {
    return { ...store, isOutputNameValid: false };
  },
  [UPDATE_MODULE]: (store) => {
    return { ...store };
  },
  [UPDATE_MODULE_COMPLETED]: (store, { module }) => {
    return { ...store, module, selectedType: undefined };
  },
  [UPDATE_MODULE_FAILED]: (store, { errorMessage }) => {
    return { ...store, moduleLoading: false, moduleLoadError: errorMessage };
  },
  [CREATE_MODULE]: (store) => {
    return { ...store };
  },
  [CREATE_MODULE_COMPLETED]: (store, { module }) => {
    return { ...store, module, selectedType: undefined };
  },
  [CREATE_MODULE_FAILED]: (store, { errorMessage }) => {
    return { ...store, moduleLoading: false, moduleLoadError: errorMessage };
  },
  [SUBMIT_PUBLISH]: (store) => {
    return { ...store, submitting: true, moduleHasBeenSubmitted: true };
  },
  [SUBMIT_PUBLISH_COMPLETED]: (store) => {
    return { ...store, submitting: false, moduleHasBeenSubmitted: true };
  },
  [SUBMIT_PUBLISH_FAILED]: (store) => {
    return { ...store, submitting: false };
  },
  [SET_TYPE]: (store, { enricherType }) => {
    if (store.module) {
      store.module.data.enricherType = enricherType;
    }
    return { ...store };
  },
  [SET_DATASET_NAME]: (store, { datasetName }) => {
    if (store.module) {
      store.module.data.outputDataset = datasetName;
    }
    return { ...store };
  },
  [SET_FIELD_MAPPING]: (store, { inputName, enricherName }) => {
    if (store.module) {
      let alreadyMapped = false;
      store.module.data.inputFields = store.module.data.inputFields.map(f => {
        if (enricherName === f.enricherName) {
          alreadyMapped = true;
          return { inputName, enricherName, type: f.type, required: f.required };
        }
        return f;
      }).filter(f => f.inputName);
      // Add a new mapping if it doesn't already exist
      if (!alreadyMapped) {
        const field = store.module.data.enricherType?.schema.inputs.find(i => i.name === enricherName);
        if (field) {
          store.module.data.inputFields.push({
            inputName,
            enricherName: field.name,
            type: field.type,
            required: field.required,
          });
        }
      }
    }
    return { ...store };
  },
  [SET_OUTPUT_FIELDS]: (store, { outputFields }) => {
    const { module } = store;
    if (module) {
      module.data.outputFields = outputFields;
    }
    return { ...store, module };
  },
  'Location.change': () => {
    return initialState;
  },
};
