import React from 'react';
import { connect } from 'react-redux';

import Chrome from '../chrome/Chrome';
import Jobs from '../job/Jobs';
import { isAdmin } from '../utils/Authorization';
import { getAuthorizedUser } from '../utils/Selectors';
import Unauthorized from './Unauthorized';

const JobsPage = connect((state) => {
  const { projects: { projectsWithStatus } } = state;
  const anyProjectAccess = isAdmin(getAuthorizedUser(state)) || !projectsWithStatus.isEmpty();
  return { anyProjectAccess };
})(({ anyProjectAccess }) => {
  if (!anyProjectAccess) {
    return <Unauthorized />;
  }
  return (
    <Chrome>
      <Jobs />
    </Chrome>
  );
});

export default JobsPage;
