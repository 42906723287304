import { List, Map, Set } from 'immutable';
import React from 'react';

import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Term from '../components/Term';
import TooltipTrigger from '../components/TooltipTrigger';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import ProfilingInfo from '../models/ProfilingInfo';
import { commafy, longFormat } from '../utils/Numbers';

const DatasetNumRecordsColumn: React.FC<{
  width: number
  rows: List<Document<Dataset>>
  currentlyProfilingDatasetNames: Set<string>
  profiling: Map<string, ProfilingInfo>
}> = ({ width, rows, currentlyProfilingDatasetNames, profiling }) => {
  return (
    <Column
      key="numRecords"
      width={width}
      isResizable
      columnKey="numRecords"
      flexGrow={1}
      header={<Cell><Term>Records</Term></Cell>}
      cell={({ rowIndex }) => {
        const dataset = rows.get(rowIndex);
        const isCurrentlyProfiling = dataset && currentlyProfilingDatasetNames.has(dataset.data.name);
        const profileData = dataset && profiling.get(dataset.data.name, undefined)?.schema;
        const hasNotProfiled = !profileData || profileData.isEmpty();
        let rowCount;
        let rowCountExact;
        if (isCurrentlyProfiling || hasNotProfiled) {
          return (
            <Cell>
              <TooltipTrigger
                content={isCurrentlyProfiling ? 'Profiling in progress' : 'Not profiled'}
                placement="left"
              >
                <span>--</span>
              </TooltipTrigger>
            </Cell>
          );
        }
        // Only metrics on dataset have the metrics object at its root
        const datasetMetrics = profileData?.find(m => (m.metrics && !m.attributeName));
        if (datasetMetrics) {
          const rowCountMetric = datasetMetrics.metrics.find(m => m.metricName === 'rowCount');
          if (rowCountMetric) {
            rowCount = longFormat(rowCountMetric.value);
            rowCountExact = commafy(rowCountMetric.value);
          }
        }
        return (
          <Cell>
            <TooltipTrigger
              content={rowCountExact}
              placement="left"
            >
              <span>
                {rowCount}
              </span>
            </TooltipTrigger>
          </Cell>
        );
      }}
    />
  );
};

export default DatasetNumRecordsColumn;
