import { ManualPairLabelType } from '../constants/PairLabelTypes';
import { ArgTypes } from '../utils/ArgValidation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

export class RecordPairFeedbackResponse extends getModelHelpers({
  label: { type: ArgTypes.valueIn([ManualPairLabelType.MATCH, ManualPairLabelType.NON_MATCH]) },
  lastModified: { type: ArgTypes.timestamp },
}, 'RecordPairFeedbackResponse')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class RecordPairFeedbackResponseRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

export default RecordPairFeedbackResponse;
