import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import { SUPPLIER_MASTERING } from '../constants/ProjectTypes';
import { selectActiveProjectInfo } from '../utils/Selectors';
import RequiredAttributeType from './constants/RequiredAttributeType';
import style from './SchemaMappingCommitWarning.module.scss';

const SchemaMappingCommitWarning = _.compose(
  connect(state => {
    const { schemaMapping: { allUnifiedAttributes: unifiedAttributes } } = state;
    const projectInfo = selectActiveProjectInfo(state);
    return {
      show: projectInfo && projectInfo.projectType === SUPPLIER_MASTERING &&
        !unifiedAttributes.some(({ requiredAttributeType }) => requiredAttributeType === RequiredAttributeType.SUPPLIER),
    };
  }),
)(class SchemaMappingCommitWarning extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
  };

  render() {
    const { show } = this.props;
    if (!show) {
      return <div />;
    }
    return (
      <div className={style.warning}>
        <TamrIcon className={style.icon} iconName="tamr-icon-warning" size={12} />
        <span className={style.text}>Please mark a unified attribute to represent your <Term>supplier</Term> name</span>
      </div>
    );
  }
});

export default SchemaMappingCommitWarning;
