import React from 'react';

import SearchBox from '../components/SearchBox';
import Toolbar from '../components/Toolbar';
import { getFilterableTotalCount } from '../components/TotalCount';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { ConnectedFC } from '../utils/typescript-react';
import { UPDATE_SEARCH_STRING } from './GoldenRecordsActionTypes';
import style from './GoldenRecordsHeader.module.scss';
import { selectAreFiltersEnabled } from './GoldenRecordsStore';
import { PublishGoldenRecordsButton } from './UpdateGoldenRecordsButton';


const GoldenRecordsHeader = ConnectedFC(
  (state) => {
    const { goldenRecords, goldenRecords: { totalRecords, draftPage, goldenRecordDocument, searchString } } = state;
    const total = draftPage?.total;
    const projectInfo = selectActiveProjectInfo(state);
    const projectName = goldenRecordDocument?.data?.displayName || projectInfo?.projectName;
    const anyFiltersEnabled = selectAreFiltersEnabled(goldenRecords);
    return {
      pageTotal: total,
      overallTotal: totalRecords,
      searchString,
      projectName,
      anyFiltersEnabled,
    };
  },
  {
    updateSearchString: (searchString: string) => ({ type: UPDATE_SEARCH_STRING, searchString }),
  },
  ({ pageTotal, overallTotal, projectName, searchString, updateSearchString, anyFiltersEnabled }) => {
    const totalString = getFilterableTotalCount({
      filtersActive: anyFiltersEnabled || !!searchString,
      currentTotal: pageTotal,
      overallTotal,
    });
    return (
      <div className={style.filterContainer}>
        <Toolbar
          className="my-8"
          left={(
            <span className={style.title}>
              {projectName}{totalString && <span className={style.count}>{totalString}</span>}
            </span>
          )}
          right={(
            <React.Fragment>
              <SearchBox className={style.search} value={searchString} onSearch={updateSearchString} />
              <PublishGoldenRecordsButton />
            </React.Fragment>
          )}
        />
      </div>
    );
  },
);

export default GoldenRecordsHeader;
