import { ThunkDispatch } from '@reduxjs/toolkit';
import _ from 'underscore';

import { FetchError } from '../api/FetchResult';
import { AppAction, AppThunkAction } from '../stores/AppAction';
import { AppState } from '../stores/MainStore';
import { API_ERROR } from './ErrorDialogActionTypes';
import { formatAdvancedDetails } from './ErrorFormat';


const apiErrorThunk = (
  title: string,
  fetchError: FetchError,
  failureAction?: AppAction,
): AppThunkAction<void> => async (dispatch) => {
  if (failureAction) {
    dispatch(failureAction);
  }
  dispatch({ type: API_ERROR, title, contents: fetchError });
};

/**
 * Dispatches the given failureAction, as well as shows an error dialog.
 * @param dispatch the ThunkDispatch that will be used to actually dispatch the action(s) here.
 * @param title The title for the error dialog. All other contents will be derived from the given error.
 * @param fetchError the FetchError object from the API error
 * @param failureAction the action to dispatch in addition to showing the dialog - usually store ecosystem-specific
 */
export function apiError(
  dispatch: ThunkDispatch<AppState, undefined, AppAction>,
  title: string,
  fetchError: FetchError,
  failureAction?: AppAction,
): void {
  return dispatch(apiErrorThunk(title, fetchError, failureAction));
}

export function getFetchErrorDetail(error: FetchError): string {
  switch (error.type) {
    case 'ApiException':
      return error.apiException.message;
    case 'json':
    case 'text':
      return 'An unexpected error has occurred';
    case 'unknown':
      return 'An unknown error has occurred';
    default:
      // TODO: when this method is no longer called from untyped context, then
      //       insert assertNever(error) to leverage typesafe execution
      return 'An unknown error has occurred';
  }
}

/**
 * Parse out the advanced detail message of the given FetchError object
 */
export function getFetchErrorAdvancedDetail(error: FetchError): string | undefined {
  if (error.type === 'ApiException') {
    return formatAdvancedDetails(error.apiException);
  }
  if (error.type === 'json') {
    return JSON.stringify(error.json, null, '  ');
  }
  if (error.type === 'text') {
    return error.text;
  }
  if (error.type === 'unknown') {
    const { reason } = error;
    return _.isString(reason) || _.isNumber(reason) || _.isBoolean(reason) || reason === undefined || reason === null
      ? String(reason)
      : typeof reason === 'object' && reason && _.isFunction(reason?.toString)
        ? String(reason.toString())
        : JSON.stringify(reason);
  }
  // type-safe execution should never reach this point
  // but that likely means something went wrong anyway
  return undefined;
}
