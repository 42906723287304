import { List, Map, Record } from 'immutable';

import SortState from '../constants/SortState';
import Document from '../models/doc/Document';
import Job from '../models/Job';
import Model from '../models/Model';
import Task from '../models/Task';
import { ArgTypes } from '../utils/ArgValidation';
import SortUtils from '../utils/SortUtils';

export const initialState = new (Model({
  jobs: {
    type: ArgTypes.Immutable.list.of(Document.argTypeWithNestedClass(Job)),
    defaultValue: List(),
  },
  coreconnectAndSparkJobs: { type: ArgTypes.any, defaultValue: [] },
  offset: {
    type: ArgTypes.Immutable.list.of(ArgTypes.orUndefined(ArgTypes.number)),
    defaultValue: List(),
  },
  pageNum: { type: ArgTypes.number, defaultValue: 0 },
  pageSize: { type: ArgTypes.number, defaultValue: 25 },
  loading: { type: ArgTypes.bool, defaultValue: false },
  showSpinner: { type: ArgTypes.bool, defaultValue: false },
  confirmingCancelForJob: { type: ArgTypes.bool, defaultValue: false },
  showCancelError: { type: ArgTypes.bool, defaultValue: false },
  jobsSequence: { type: ArgTypes.number, defaultValue: 0 },
  loadedFilterInfo: { type: ArgTypes.any },
  statusJobId: { type: ArgTypes.nullable(ArgTypes.string) },
  specJobId: { type: ArgTypes.nullable(ArgTypes.number) },
  cancelJobId: { type: ArgTypes.nullable(ArgTypes.number) },
  spec: { type: ArgTypes.nullable(ArgTypes.any) },
  specLoading: { type: ArgTypes.bool, defaultValue: false },
  showSpecDialog: { type: ArgTypes.bool, defaultValue: false },
  specSequence: { type: ArgTypes.number, defaultValue: 0 },
  loadedSpecFilterInfo: { type: ArgTypes.any },
  tasks: {
    type: ArgTypes.Immutable.list.of(Document.argTypeWithNestedClass(Task)),
    defaultValue: List(),
  },
  sparkConfigNames: {
    type: ArgTypes.Immutable.list.of(ArgTypes.string),
    defaultValue: List(),
  },
  loadedSparkConfigNames: { type: ArgTypes.bool, defaultValue: false },
  searchString: { type: ArgTypes.string, defaultValue: '' },
  columnSortStates: {
    type: ArgTypes.Immutable.map.of(ArgTypes.string, ArgTypes.string),
    defaultValue: Map([
      ['Submitted', SortState.UNSORTED],
      ['Ended', SortState.UNSORTED],
    ]),
  },
}))();

const FilterInfo = Record({ jobsSequence: 0, pageNum: 0, pageSize: 25 });

export const getFilterInfo = ({ jobsSequence, pageNum, pageSize }) => {
  return new FilterInfo({ jobsSequence, pageNum, pageSize });
};

const SpecFilterInfo = Record({ specJobId: null, specSequence: 0 });

export const getSpecFilterInfo = ({ specJobId, specSequence }) => {
  return new SpecFilterInfo({ specJobId, specSequence });
};

const reloadJobs = (state) => state.update('jobsSequence', (n) => n + 1);
const reloadSpec = (state) => state.update('specSequence', (n) => n + 1);

export const reducers = {
  'Jobs.fetchJobs': (state) => {
    return state.set('loading', true);
  },

  'Jobs.fetchJobsCompleted': (state, { jobs, filterInfo }) => {
    return state.merge({
      loading: false,
      showSpinner: false,
      jobs,
      loadedFilterInfo: filterInfo,
    });
  },

  'Jobs.fetchMergedJobsCompleted': (
    state,
    { coreconnectAndSparkJobs, filterInfo, offset, jobs },
  ) => {
    return state.merge({
      loading: false,
      showSpinner: false,
      offset,
      coreconnectAndSparkJobs,
      loadedFilterInfo: filterInfo,
      jobs,
    });
  },

  'Jobs.fetchJobsFailed': (state, { filterInfo }) => {
    return state.merge({
      loading: false,
      showSpinner: false,
      loadedFilterInfo: filterInfo,
    });
  },

  'Jobs.setPage': (state, { pageNum }) => {
    return state.set('pageNum', pageNum);
  },

  'Jobs.setPageSize': (state, { pageSize }) => {
    return state.merge({ pageSize, pageNum: 0 });
  },

  'Jobs.showConfirmCancelJobDialog': (state, { jobId }) => {
    return state.merge({ confirmingCancelForJob: true, cancelJobId: jobId });
  },

  'Jobs.cancelJob': (state) => {
    return state.delete('confirmingCancelForJob');
  },

  'Jobs.cancelConfirmCancelJob': (state) => {
    return state.delete('confirmingCancelForJob');
  },

  'Jobs.cancelJobCompleted': (state) => {
    return reloadJobs(state).set('showSpinner', true);
  },

  'Jobs.cancelJobFailed': (state) => {
    return state.set('showCancelError', true);
  },

  'Jobs.closeCancelAndRefresh': (state) => {
    return reloadJobs(state).merge({
      showCancelError: false,
      showSpinner: true,
    });
  },

  'Jobs.showStatusDialog': (state, { jobId }) => {
    return state.set('statusJobId', jobId);
  },

  'Jobs.hideStatusDialog': (state) => {
    return state.set('statusJobId', undefined);
  },

  'Jobs.loadSpec': (state, { jobId }) => {
    return reloadSpec(state).set('specJobId', jobId);
  },

  'Jobs.showSpecDialog': (state, { jobId }) => {
    return reloadSpec(state).merge({ specJobId: jobId, showSpecDialog: true });
  },

  'Jobs.hideSpecDialog': (state) => {
    return state.merge({ specJobId: undefined, showSpecDialog: false });
  },

  'Jobs.fetchSpec': (state) => {
    return state.set('specLoading', true);
  },

  'Jobs.fetchSpecCompleted': (state, { filterInfo, job }) => {
    return state.merge({
      specLoading: false,
      loadedSpecFilterInfo: filterInfo,
      spec: job.data.specification,
    });
  },

  'Jobs.fetchSpecFailed': (state, { filterInfo }) => {
    return state.merge({
      specLoading: false,
      loadedSpecFilterInfo: filterInfo,
    });
  },

  'Jobs.refresh': (state) => {
    return reloadJobs(state);
  },

  'Jobs.refreshWithSpinner': (state) => {
    return reloadJobs(state).set('showSpinner', true);
  },

  'Jobs.refreshSpec': (state) => {
    return reloadSpec(state);
  },

  'Jobs.fetchTasksCompleted': (state, { tasks }) => {
    return state.merge({ tasks });
  },

  'Jobs.fetchTasksFailed': (state) => {
    return state.merge({ loading: false, showSpinner: false });
  },

  'Jobs.fetchSparkConfigNames': (state) => {
    return state.merge({ loading: true });
  },

  'Jobs.fetchSparkConfigNamesCompleted': (state, { configNames }) => {
    return state.merge({
      loading: false,
      loadedSparkConfigNames: true,
      sparkConfigNames: configNames,
    });
  },

  'Jobs.fetchSparkConfigNamesFailed': (state) => {
    return state.merge({ loading: false, loadedSparkConfigNames: false });
  },

  'Jobs.setSearchString': (state, { searchString }) => {
    return state.merge({
      searchString,
      showSpinner: true,
      pageNum: 0,
      offset: List(),
    });
  },
  'Jobs.changeSort': (state, { column }) => {
    return state
      .update('columnSortStates', (current) => {
        return current
          .set('Submitted', SortState.UNSORTED)
          .set('Ended', SortState.UNSORTED)
          .set(
            column,
            SortUtils.getNext(
              state.columnSortStates.get(column) || SortState.UNSORTED,
            ),
          );
      })
      .merge({ pageNum: 0, offset: List() });
  },
};
