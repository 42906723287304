import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Truncate from 'react-truncate';
import _ from 'underscore';

import { getNumOutOfDateSteps, getProjectStepInfos } from '../chrome/UpdatesUtils';
import BulletedTooltipTrigger from '../components/BulletedTooltipTrigger';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import {
  CLASSIFICATION,
  ENRICHMENT,
  GOLDEN_RECORDS,
  SCHEMA_MAPPING_RECOMMENDATIONS,
  SUPPLIER_MASTERING,
} from '../constants/ProjectTypes';
import { isAdmin, isAdminOrCurator, isCuratorByProjectId } from '../utils/Authorization';
import { shortFormat } from '../utils/Numbers';
import { getAuthorizedUser, selectProjectInfoByProjectId } from '../utils/Selectors';
import { pluralize } from '../utils/Strings';
import { getPath } from '../utils/Values';
import style from './ProjectCard.module.scss';
import { BEGIN_CONFIRMING_DELETE_PROJECT, BEGIN_EDITING_PROJECT } from './ProjectsActionTypes';
import { getBaseEnrichmentPageUrl, getBaseGRPageUrl } from './ProjectsStore';
import ProjectStatsLoader from './ProjectStatsLoader';
import ProjectTypeIcon from './ProjectTypeIcon';

const ICON_SIZE = 20;

const ActionIcon = ({ iconName, onClick }) => (
  <div
    className={style.actionIconClickTarget}
    onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
      onClick(e);
    }}
  >
    <TamrIcon {...{ iconName }} size={ICON_SIZE} />
  </div>
);

const ProjectCard = _.compose(
  connect((state, { projectId }) => {
    const { projectStats: { byProjectId: statsByProjectId } } = state;
    const statValue = statsByProjectId.get(projectId);

    const projectInfo = projectId !== undefined ? selectProjectInfoByProjectId(state, projectId) : null;
    const { projectType, project: { displayName, description }, moduleId } = projectInfo;
    const smRecipeId = projectType === GOLDEN_RECORDS ? null : projectInfo.smRecipeId;

    const projectStepInfos = getProjectStepInfos(projectInfo);
    const authorizedUser = getAuthorizedUser(state);
    const projectUpToDate = getNumOutOfDateSteps(projectStepInfos) === 0 || !isCuratorByProjectId(authorizedUser, projectInfo?.projectDoc.id.id);

    const upgradeFailures = getPath(projectInfo, 'projectWithStatus', 'recipes')
      .filter(r => getPath(r, 'upgradeFailures') && !getPath(r, 'upgradeFailures').isEmpty())
      .map(r => ({ type: getPath(r, 'recipe', 'data', 'type'), messages: getPath(r, 'upgradeFailures') }));

    return {
      statValue: statValue || 0,
      smRecipeId,
      moduleId,
      projectType,
      displayName,
      description,
      projectUpToDate,
      loggedInUserIsAdminOrCurator: isAdminOrCurator(authorizedUser, projectId),
      loggedInUserIsAdmin: isAdmin(authorizedUser),
      upgradeFailures,
    };
  }, {
    onBeginConfirmingDeleteProject: (projectId) => ({ type: BEGIN_CONFIRMING_DELETE_PROJECT, projectId }),
    onBeginEditingProject: (projectId) => ({ type: BEGIN_EDITING_PROJECT, projectId }),
  }),
)(({ moduleId, loggedInUserIsAdminOrCurator, projectId, statValue, smRecipeId, projectType, displayName, description, projectUpToDate, upgradeFailures, onBeginEditingProject, onBeginConfirmingDeleteProject }) => {
  const projectUrl = '/#' + (projectType === GOLDEN_RECORDS
    ? getBaseGRPageUrl(moduleId)
    : projectType === ENRICHMENT
      ? getBaseEnrichmentPageUrl(moduleId)
      : `/dashboard/recipe/${smRecipeId}`);

  let statValueMsg;
  let actionVerb;
  if (projectType === CLASSIFICATION) {
    statValueMsg = <Term amount={statValue} {...{ projectId }}>part</Term>;
    actionVerb = 'categorized';
  } else if (projectType === SUPPLIER_MASTERING) {
    statValueMsg = <Term amount={statValue} {...{ projectId }}>supplier</Term>;
    actionVerb = 'matched';
  } else if (projectType === SCHEMA_MAPPING_RECOMMENDATIONS) {
    statValueMsg = pluralize(statValue, 'unified attribute', 'unified attributes');
    actionVerb = 'mapped';
  } else if (projectType === GOLDEN_RECORDS) {
    statValueMsg = pluralize(statValue, 'golden record', 'golden records');
    actionVerb = 'created';
  } else if (projectType === ENRICHMENT) {
    statValueMsg = pluralize(statValue, 'record', 'records');
    actionVerb = 'enriched';
  }
  return (
    <a href={projectUrl} className={style.card} data-test-element="project-card">
      <ProjectStatsLoader projectId={projectId} moduleId={moduleId} />
      <div className={style.warningIcons}>
        {!projectUpToDate ? <TamrIcon className={style.outOfDateIcon} iconName="error" size={ICON_SIZE} /> : null}
        {upgradeFailures.size > 0
          ? (
            <BulletedTooltipTrigger
              className={style.errorTooltip}
              label="There was a problem upgrading this project.  The following errors were logged:"
              items={upgradeFailures.map(i =>
                i.messages.map(j => <span>{j} (in <em>{i.type}</em> recipe)</span>))}
              placement="bottom"
            >
              <span><TamrIcon className={style.upgradeFailureIcon} iconName="tamr-icon-warning" size={ICON_SIZE} /></span>
            </BulletedTooltipTrigger>
          )
          : null}
      </div>
      {loggedInUserIsAdminOrCurator ? (
        <div className={style.actionIconsContainer}>
          <ActionIcon iconName="edit" onClick={() => onBeginEditingProject(projectId)} />
          <ActionIcon iconName="delete" onClick={() => onBeginConfirmingDeleteProject(projectId)} />
        </div>
      ) : null}
      <ProjectTypeIcon projectType={projectType} className={style.typeIcon} size={61} />
      <div className={style.name} title={displayName}>{displayName}</div>
      <div className={style.description} title={description}>
        <Truncate
          lines={2}
          ellipsis="…"
        >
          {description}
        </Truncate>
      </div>
      <div className={style.stat}>
        {statValue ? shortFormat(statValue) : 'No'}&nbsp;
        <span className={style.statValue}>{statValueMsg}</span>&nbsp;
        {actionVerb}&nbsp;
        {!statValue ? 'yet' : ''}
      </div>
    </a>
  );
});

ProjectCard.propTypes = {
  projectId: PropTypes.number.isRequired,
};

export default ProjectCard;
