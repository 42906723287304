import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import { isStepUpToDate } from '../chrome/UpdatesUtils';
import MenuButton from '../components/MenuButton';
import { ProjectStepsKey as ProjectStepKey } from '../constants/ProjectStepsKey';
import { CLASSIFICATION } from '../constants/ProjectTypes';
import PRODUCT_NAME from '../utils/ProductName';
import { isJobRunning, selectActiveProjectInfo } from '../utils/Selectors';

// Should match value in ClassificationPreconditions.java
const MIN_CATEGORIZATIONS = 1;

const TransactionSuggestCategorizationButton = _.compose(
  connect((state) => {
    const { taxonomy: { taxonomy }, transactions: { suggestionLaunching, loadingModel, modelExists } } = state;
    const projectInfo = selectActiveProjectInfo(state);
    const { recipeWithStatus } = projectInfo || {};
    const schemaMappingOutOfDate = !isStepUpToDate(state, ProjectStepKey.RECORDS);
    return {
      schemaMappingOutOfDate,
      recipeWithStatus,
      totalTxnUser: taxonomy ? taxonomy.numUserCategorizations : 0,
      suggestionRunning: suggestionLaunching || isJobRunning(state, { recipeOperation: 'categorizations', forActiveProject: true }) || isJobRunning(state, { recipeOperation: 'predictCategorizations', forActiveProject: true }),
      loadingModel,
      modelExists,
    };
  }, {
    onApplyFeedbackAndUpdateResults: () => ({ type: 'Transactions.setShowUpdateCategorizationsWarningDialog', show: true, doApplyAndUpdate: true }),
    onUpdateResultsOnly: () => ({ type: 'Transactions.setShowUpdateCategorizationsWarningDialog', show: true, doApplyAndUpdate: false }),
  }),
)(({ recipeWithStatus, totalTxnUser, suggestionRunning, iconify, onApplyFeedbackAndUpdateResults, onUpdateResultsOnly, schemaMappingOutOfDate, loadingModel, modelExists }) => {
  const lastRun = recipeWithStatus.getIn(['materializations', 'categorizations', 'lastRun']);
  const c12nMetadata = recipeWithStatus.getIn(['recipe', 'data', 'metadata', CLASSIFICATION]) || {};
  return (
    <MenuButton
      className="transaction-suggest-categorization-button"
      buttonOnClick={onApplyFeedbackAndUpdateResults}
      buttonPreconditions={Map()
        .set('You must have one or more columns included in Machine Learning on the Schema Mapping page', !List(c12nMetadata.includedFields).isEmpty())
        .set(`You must have ${MIN_CATEGORIZATIONS} or more curator-verified categorizations before ${lastRun ? 'updating' : 'generating'}`, totalTxnUser >= MIN_CATEGORIZATIONS)
        .set('You must wait for updating to complete before updating again', !suggestionRunning)
        .set('Please Update Unified Dataset first', !schemaMappingOutOfDate)
      }
      buttonText={iconify ? 'Apply fb. & upd. res.' : 'Apply feedback and update results'}
      dropdownPreconditions={Map()
        .set(`You must have already trained ${PRODUCT_NAME} before updating results${loadingModel ? ' (Loading...)' : ''}`, modelExists)
        .set('You must have one or more columns included in Machine Learning on the Schema Mapping page before updating results', !List(c12nMetadata.includedFields).isEmpty())
        .set('You must wait for updating to complete before updating results', !suggestionRunning)
        .set('Please Update Unified Dataset before updating results', !schemaMappingOutOfDate)
      }
      options={List.of({ text: 'Update results only', onClick: onUpdateResultsOnly })}
    />
  );
});

TransactionSuggestCategorizationButton.propTypes = {
  iconify: PropTypes.bool.isRequired,
};

export default TransactionSuggestCategorizationButton;
