import './Dialog.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal as RBModal } from 'react-bootstrap';
import _ from 'underscore';

import { FCWithPropTypes } from '../../utils/typescript-react';
import DialogHeader from './DialogHeader';

export const DialogStyle = {
  PRIMARY: 'Primary',
  FULL: 'Full',
} as const;
export type DialogStyleE = typeof DialogStyle[keyof typeof DialogStyle];

const Dialog = FCWithPropTypes(
  {
    /**
     * Open and close the Modal with a slide and fade animation.
     */
    animation: PropTypes.bool,
    /**
     * The body prop should be a string, another component, or anything
     * that can be inserted in the DOM. This value shows up in the
     * main body of the dialog.
     */
    body: PropTypes.node,
    /**
     * Additional class name to add to dialog wrapping element
     */
    className: PropTypes.string,
    /**
     * Additional class name to add to the dialog body element
     */
    bodyClassName: PropTypes.string,
    /**
     * ONLY EFFECTIVE IF this.props.header=true (not an element), OR this.props.title is defined.
     * The closeButton prop determines whether to a close button is present in the default header.
     */
    closeButton: PropTypes.bool,
    /**
     * The optional container prop should be a string that corresponds to
     * one of the nodes in the dialog's parent's refs object. If provided
     * the dialog will be rendered inside of that referenced node.
     */
    container: PropTypes.object,
    /**
     * The dialogStyle prop dictates which predefined set of classes will
     * be passed to the wrapped bootstrap modal as a dialogClassName prop
     * which in turn dictates its styling.
     */
    dialogStyle: PropTypes.oneOf<DialogStyleE>(_.values(DialogStyle)).isRequired,
    /**
     * The footer prop should be a string, another component, or anything
     * that can be rendered in the DOM. This would be a good place to pass
     * in any other interactive components, ie "save" or "cancel" buttons.
     */
    footer: PropTypes.node,
    /**
     * The header prop appears at the top of the modal.
     * It is recommended to use an instance of the DialogHeader component published attached
     * to this component, but you are free to pass whatever element you want in here.
     * The onHide prop supplied to this component will get merged into the node supplied for header.
     * If this value is `true`, a default header will be supplied with the `title` around
     * `closeButton` props on this class considered.
     */
    header: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
    /**
     * Whether to size the dialog larger than normal.
     */
    large: PropTypes.bool,
    /**
     * The onHide function gets passed straight through to the wrapped react-
     * bootstrap modal to be called on a click outside of the dialog box. This
     * function should set whatever state value that gets passed into this
     * dialog's show prop to false.
     */
    onHide: PropTypes.func.isRequired,
    /**
     * The show prop dictates whether the dialog shows itself.
     */
    show: PropTypes.bool.isRequired,
    /**
     * The title prop will be displayed into the default header.
     * If this prop is defined, the `header` prop is ignored and the default header is displayed.
     */
    title: PropTypes.node,
  },
  ({ animation, body, className, bodyClassName, closeButton, container, dialogStyle, footer, header, large, onHide, show, title }) => {
    // TODO default-props-in-typescript
    animation = (animation === undefined || animation === null) ? true : animation;

    const containerClassNames = classNames('tamr-dialog', className);
    const dialogClassName = classNames({
      'full-dialog': dialogStyle === 'Full',
      'primary-dialog': dialogStyle === 'Primary',
    });
    // NB: PropTypes allows non-required fields to be null, but null is not allowed as the
    // className for the RBModal.Body component, so here we use `classNames` to cast it to
    // a valid className
    const dialogBodyClassName = classNames(bodyClassName);

    let headerElement: JSX.Element | null = null;
    if (!!header || !!title) {
      if (header === true || !!title) {
        // use default DialogHeader
        headerElement = (
          <DialogHeader closeButton={!!closeButton}>
            <span>{title}</span>
          </DialogHeader>
        );
      } else {
        // header is a React element
        // @ts-expect-error
        headerElement = header;
      }
      const headerWithOnHide = React.cloneElement(headerElement as JSX.Element, {
        onHide,
      });
      headerElement = (
        <RBModal.Header>
          {headerWithOnHide}
        </RBModal.Header>
      );
    }
    return (
      <RBModal
        className={containerClassNames}
        container={container}
        keyboard
        onHide={onHide}
        show={show}
        backdrop="static"
        dialogClassName={dialogClassName}
        animation={animation}
        bsSize={large ? 'lg' : undefined}
      >
        {headerElement}
        <RBModal.Body className={dialogBodyClassName}>{body}</RBModal.Body>
        {footer && (
          <RBModal.Footer>{footer}</RBModal.Footer>
        )}
      </RBModal>
    );
  },
);

// @ts-expect-error
Dialog.Header = DialogHeader;

export default Dialog;
