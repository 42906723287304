import PropTypes from 'prop-types';
import React from 'react';
import { pure } from 'recompose';
import _ from 'underscore';

import Term from '../components/Term';
import { longFormat } from '../utils/Numbers';
import { pluralize } from '../utils/Strings';

const FeedbackAssignDialogTitle = _.compose(
  pure,
)(class FeedbackAssignDialogTitle extends React.Component {
  static propTypes = {
    noun: PropTypes.string.isRequired,
    numToAssign: PropTypes.number.isRequired,
    numToUnassign: PropTypes.number.isRequired,
    recordCount: PropTypes.number.isRequired,
  };

  render() {
    const { recordCount, noun, numToAssign, numToUnassign } = this.props;
    const formattedSelectedUsersCount = longFormat(numToAssign);
    const assignExpertNoun = pluralize(numToAssign, 'Person', 'People');
    let title = `Assign ${formattedSelectedUsersCount} ${assignExpertNoun}`;
    if (numToUnassign > 0) {
      const formattedUnassignCount = longFormat(numToUnassign);
      const unassignExpertNoun = pluralize(numToUnassign, 'Person', 'People');
      title = `Assign ${formattedSelectedUsersCount} ${assignExpertNoun}, Unassign ${formattedUnassignCount} ${unassignExpertNoun}`;
    }
    const formattedRecordsCount = longFormat(recordCount);
    return (
      <div className="feedback-dialog-header">
        <div className="feedback-dialog-title">{title}</div>
        <div className="feedback-dialog-subtitle">
          Choose people to review the {formattedRecordsCount} selected <Term amount={recordCount}>{noun}</Term>
        </div>
      </div>
    );
  }
});

export default FeedbackAssignDialogTitle;
