import classNames from 'classnames';
import $ from 'jquery';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import { Overlay, Popover as RBPopover } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import _ from 'underscore';

const HIDE_POPOVER_EVT = 'mousedown';

const propTypes = {
  /**
   * The element that acts as the popover trigger
   */
  children: PropTypes.element.isRequired,
  /**
   * Classname is passed to the popover.
   */
  className: PropTypes.string,
  /**
   * Content is the content of the popover.
   */
  content: PropTypes.node.isRequired,
  /**
   * Since this component adds a wrapping div to the children, simply not including this in the
   * DOM is a frustrating way to disable the popover, since style can/will be affected.
   * This prop allows disabling the popover while maintaining consistent DOM structure / style
   * of trigger element.
   */
  disablePopover: PropTypes.bool,
  /**
   * Time in milliseconds to delay before hiding popover
   */
  hideDelay: PropTypes.number,
  /**
   * Placement determines on which side of Children the popover will be placed.
   */
  placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  /**
   * Time in milliseconds to delay after trigger before showing popover
   */
  showDelay: PropTypes.number,
  /*
   * Classname is passed to trigger element (child)
   */
  triggerClassName: PropTypes.string,
};

type HoverTriggerProps = InferProps<typeof propTypes>;

type HoverTriggerState = {
  show: boolean
  triggerTop: number | null
  triggerLeft: number | null
  triggerWidth: number | null
  triggerHeight: number | null
}

/**
 * A component that triggers a popover on hover. Allows the user to mouse into,
 * and interact with, the popover without it immediately closing.
 */
class HoverTrigger extends React.Component<HoverTriggerProps, HoverTriggerState> {
  _hideTimeout: number | undefined;
  _showTimeout: number | undefined;

  static propTypes = propTypes;

  static defaultProps = {
    disablePopover: false,
    placement: 'left',
    hideDelay: 100,
    showDelay: 200,
  };

  state: HoverTriggerState = {
    show: false,
    // dimensions of trigger element; this state should be populated when the
    // popover is initially triggered.
    triggerTop: null,
    triggerLeft: null,
    triggerWidth: null,
    triggerHeight: null,
  };

  componentDidUpdate() {
    // request animation frame to ensure that we try to position popover after
    // the DOM has been completely drawn
    window.requestAnimationFrame(
      () => this.positionPopover(),
    );
  }

  componentWillUnmount() {
    window.removeEventListener(HIDE_POPOVER_EVT, this.discernHide);
    this.clearTimeouts();
  }

  onOverlayEnter = () => {
    window.addEventListener(HIDE_POPOVER_EVT, this.discernHide);
    this.positionPopover();
  };

  onOverlayExited = () => {
    window.removeEventListener(HIDE_POPOVER_EVT, this.discernHide);
    this.clearTimeouts();
  };

  getPlacement = () => {
    const left = this.props.placement === 'left';
    const top = this.props.placement === 'top';
    const right = this.props.placement === 'right';
    const bottom = this.props.placement === 'bottom';
    return { left, top, right, bottom };
  };

  getTriggerDims = (): null | Pick<HoverTriggerState, 'triggerTop' | 'triggerLeft' | 'triggerHeight' | 'triggerWidth'> => {
    const triggerNode = this.triggerNode();
    if (!triggerNode) {
      console.error('Could not get trigger dimensions - no DOM node for trigger');
      return null;
    }
    const $trigger = $(triggerNode).children().first();
    const offset = $trigger.offset();
    const triggerTop = offset?.top || null;
    const triggerLeft = offset?.left || null;
    const triggerHeight = $trigger.height() || null;
    const triggerWidth = $trigger.width() || null;
    return { triggerTop, triggerLeft, triggerHeight, triggerWidth };
  };

  clearTimeouts = () => {
    if (this._hideTimeout) {
      clearTimeout(this._hideTimeout);
    }
    if (this._showTimeout) {
      clearTimeout(this._showTimeout);
    }
  };

  /**
   * Checks to see if mousedown event is within the popover node; if not,
   * hide the popover.
   */
  discernHide = (mouseDownEvt: MouseEvent) => {
    const node = this.popoverNode();
    const { target } = mouseDownEvt;
    if (!(node instanceof Element)) {
      return;
    }
    if (!(target instanceof Element) || !$.contains(node, target)) {
      this.hidePopover();
    }
  };

  hideAfterDelay = (delay: number) => {
    this._hideTimeout = window.setTimeout(
      () => this.setState({ show: false },
      ), delay);
  };

  hidePopover = (delay = 0) => {
    this.clearTimeouts();
    if (delay === 0) {
      this.setState({ show: false });
    } else {
      this.hideAfterDelay(delay);
    }
  };

  popoverNode = () => {
    return ReactDOM.findDOMNode(this.refs.popover);
  };

  /**
   * Unfortunately, was unable to find a straightforward and reliable way to
   * position an Overlay instance relative to its trigger element using the
   * 'container' prop.
   *
   * TODO: Use the Overlay API to avoid re-doing gross positioning math
   */
  positionPopover = () => {
    const { triggerTop, triggerLeft, triggerHeight, triggerWidth } = this.state;
    const node = this.popoverNode();
    if (!_.isNumber(triggerTop) || !_.isNumber(triggerHeight) || !_.isNumber(triggerLeft) || !_.isNumber(triggerWidth) || !node) {
      return;
    }
    const popoverNode = this.popoverNode();
    if (!popoverNode) {
      console.error('Could not position HoverTrigger popover - no DOM node for popover');
      return;
    }
    const $popover = $(popoverNode);
    const $arrow = $popover.find('.arrow');
    // @ts-expect-error offsetHeight and offsetWidth do not exist on Element?
    const { offsetHeight, offsetWidth } = $popover[0];
    const placed = this.getPlacement();

    let top = 0;
    let left = 0;

    if (placed.left || placed.right) {
      const centeredToTrigger =
        triggerTop + (triggerHeight / 2) - (offsetHeight / 2);

      // clamp top position so that the popover doesn't overflow the viewport
      top = Math.max(0, Math.min(centeredToTrigger, window.innerHeight - offsetHeight));

      if (centeredToTrigger !== top) {
        // if the popover is no longer centered around the trigger, push the
        // arrow to point at the trigger.
        $arrow.css({
          top: triggerTop + (triggerHeight / 2) - top,
        });
      }

      if (placed.left) {
        left = triggerLeft - offsetWidth;
      } else { // placed right
        left = triggerLeft + triggerWidth;
      }
    }

    if (placed.top || placed.bottom) {
      const centeredToTrigger =
        triggerLeft + (triggerWidth / 2) - (offsetWidth / 2);

      // clamp left position so that the popover doesn't overflow the viewport
      left = Math.max(0, Math.min(centeredToTrigger, window.innerWidth - offsetWidth));

      if (centeredToTrigger !== left) {
        // if the popover is no longer centered around the trigger, push the
        // arrow to point at the trigger.
        $arrow.css({
          left: triggerLeft + (triggerWidth / 2) - left,
        });
      }

      if (placed.top) {
        top = triggerTop - offsetHeight;
      } else { // placed bottom
        top = triggerTop + triggerHeight;
      }
    }

    $popover.css({ top, left });
  };

  showAfterDelay = (delay: number) => {
    this._showTimeout = window.setTimeout(
      () => this.setState({ show: true },
      ), delay);
  };

  showPopover = (delay = 0) => {
    this.clearTimeouts();
    const triggerDims = this.getTriggerDims();
    if (triggerDims) {
      const toSet: HoverTriggerState = { show: false, ...triggerDims };
      if (delay === 0) {
        toSet.show = true;
      } else {
        this.showAfterDelay(delay);
      }
      this.setState(toSet);
    }
  };

  triggerNode = () => {
    return ReactDOM.findDOMNode(this.refs.hoverTrigger);
  };

  render() {
    const popoverClassNames = classNames('hover-popover', this.props.className);
    const { disablePopover, showDelay, hideDelay, triggerClassName } = this.props;
    const popoverElement = (
      <Overlay
        // @ts-expect-error trigger does not exist as prop on Overlay? Can we safely remove this?
        trigger={this.triggerNode}
        show={this.state.show}
        placement={this.props.placement || undefined}
        onEnter={this.onOverlayEnter}
      >
        <RBPopover
          key="popover"
          ref="popover"
          id="popover"
          className={popoverClassNames}
          onMouseEnter={() => this.showPopover()}
          onMouseLeave={_.partial(this.hidePopover, hideDelay)}
          onClick={() => this.hidePopover()}
        >
          {this.props.content}
        </RBPopover>
      </Overlay>
    );

    return (
      <div
        className={classNames('hover-trigger', triggerClassName)}
        ref={'hoverTrigger'}
        onMouseEnter={_.partial(this.showPopover, showDelay)}
        onMouseLeave={_.partial(this.hidePopover, hideDelay)}
      >
        {this.props.children}
        {disablePopover ? null : popoverElement}
      </div>
    );
  }
}

export default HoverTrigger;
