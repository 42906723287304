import _ from 'lodash';
import { Moment } from 'moment';
import numeral from 'numeral';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';

import AppState from '../../stores/AppState';
import { $TSFixMe } from '../../utils/typescript';

interface Style {
  lineColor: string
  areaColor: string
}

const styles = {
  recall: {
    lineColor: '#0F7BB5',
    // #0F7BB5 = rgb(15, 123, 181)
    areaColor: 'rgba(15, 123, 181, 0.2)',
  },
  precision: {
    lineColor: '#FFBC6D',
    // #FFBC6D = rgb(255, 188, 109)
    areaColor: 'rgba(255, 188, 109, 0.2)',
  },
};

interface Metric {
  date: Moment
  score: number
  confidenceInterval: number
  testRecordsCount: number
}

interface Args {
  label: string,
  metrics: Array<Metric>,
  style: Style,
}

const toLineWithConfidenceInterval = ({
  label,
  metrics,
  style,
}: Args) => {
  return [
    {
      label,
      type: 'line',
      data: metrics.map(m => m.score),
      lineTension: 0,
      fill: false,
      borderColor: style.lineColor,
      borderWidth: 2,
      pointBackgroundColor: style.lineColor,
      pointHitRadius: 16,
    },
    {
      label: `${label}-confidence-interval-minus`,
      type: 'line',
      data: metrics.map(m => m.score - m.confidenceInterval),
      lineTension: 0,
      borderColor: style.lineColor,
      borderDash: [5, 5],
      borderWidth: 2,
      pointRadius: 0,
      fill: false,
      pointHitRadius: 0,
    },
    {
      label: `${label}-confidence-interval-plus`,
      type: 'line',
      data: metrics.map(m => m.score + m.confidenceInterval),
      lineTension: 0,
      borderColor: style.lineColor,
      borderDash: [5, 5],
      borderWidth: 2,
      pointRadius: 0,
      fill: false,
      pointHitRadius: 0,
    },
  ];
};

const OPTIONS: $TSFixMe = {
  legend: {
    display: false,
  },
  tooltips: {
    position: 'nearest',
    mode: 'index',
    intersect: true,
    callbacks: {
      title(tooltipItems: $TSFixMe, data: $TSFixMe) {
        const m = data.labels[tooltipItems[0].index];
        return m.format('MMMM D, YYYY (h:mm a)');
      },
      label(tooltipItem: $TSFixMe, data: $TSFixMe) {
        const label = data.datasets[tooltipItem.datasetIndex].label || '';

        let tooltipLabel;
        if (label === 'Precision') {
          tooltipLabel = 'Precision: ';
        } else if (label === 'Recall') {
          // pad recall label to align with precision label
          tooltipLabel = 'Recall:      ';
        } else {
          return null;
        }

        const score = tooltipItem.yLabel;
        // reverse engineer the confidence interval
        const intervalMinus = _.find(
          data.datasets,
          d => d.label === `${label}-confidence-interval-minus`,
        ).data[tooltipItem.index];
        const formattedScore = numeral(score * 100).format('0.0');
        const formattedConfidenceInterval = numeral((score - intervalMinus) * 100).format('0.0');
        tooltipLabel += `${formattedScore}% ±${formattedConfidenceInterval}%`;

        return tooltipLabel;
      },
    },
  },
  hover: {
    mode: null,
  },
  scales: {
    xAxes: [
      {
        type: 'time',
        gridLines: {
          display: false,
        },
        distribution: 'series',
        ticks: {
          source: 'data',
        },
        time: {
          displayFormats: {
            millisecond: 'h:mm:ss a',
          },
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          min: 0,
          max: 1,
          stepSize: 0.25,
          callback: (y: number) => `${y * 100}%`,
        },
      },
    ],
  },
};

const mapState = (state: AppState) => {
  const { metrics } = state.clusters.metrics.historical;
  return {
    historicalMetrics: metrics?.filter(m => m.precisionTestRecordsCount >= 10 && m.recallTestRecordsCount >= 10),
    // historicalMetrics: metrics,
  };
};

type StateProps = ReturnType<typeof mapState>
type Props = StateProps

const Graph: React.FC<Props> = ({
  historicalMetrics,
}) => {
  if (!historicalMetrics) {
    return null;
  }

  const data: $TSFixMe = {
    labels: historicalMetrics.map(x => x.date),
    datasets: [
      ...toLineWithConfidenceInterval({
        label: 'Precision',
        metrics: historicalMetrics.map(m => ({
          date: m.date,
          score: m.precision,
          confidenceInterval: m.precisionConfidenceInterval,
          testRecordsCount: m.precisionTestRecordsCount,
        })),
        style: styles.precision,
      }),
      ...toLineWithConfidenceInterval({
        label: 'Recall',
        metrics: historicalMetrics.map(m => ({
          date: m.date,
          score: m.recall,
          confidenceInterval: m.recallConfidenceInterval,
          testRecordsCount: m.recallTestRecordsCount,
        })),
        style: styles.recall,
      }),
    ],
  };

  return <Line data={data} options={OPTIONS} height={100} />;
};


export default connect(mapState)(Graph);
