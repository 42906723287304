import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../../models/Model';
import { ArgTypes } from '../../utils/ArgValidation';
import { $TSFixMe } from '../../utils/typescript';

// com.tamr.auth.UserPreferences

class UserPreferences extends getModelHelpers({
  username: { type: ArgTypes.string },
  preferences: { type: ArgTypes.object },
}, 'UserPreferences')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class UserPreferencesRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: $TSFixMe) {
    return new UserPreferences({
      username: obj.username,
      preferences: obj.preferences,
    });
  }
}

export default UserPreferences;
