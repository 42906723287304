import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Chrome from '../chrome/Chrome';
import PageHeader from '../chrome/PageHeader';
import SearchBox from '../components/SearchBox';
import ProcurementTaxonomy from '../models/ProcurementTaxonomy';
import TaxonomyLoader from '../taxonomy/TaxonomyLoader';
import CategorizeDialog from '../transactions/CategorizeDialog';
import CategoryFilterDialog from '../transactions/CategoryFilterDialog';
import ModelLoader from '../transactions/ModelLoader';
import SpendTransactionSidebar from '../transactions/SpendTransactionSidebar';
import TransactionFilter from '../transactions/TransactionFilter';
import TransactionLoader from '../transactions/TransactionLoader';
import TransactionSuggestCategorizationButton from '../transactions/TransactionSuggestCategorizationButton';
import TransactionTable from '../transactions/TransactionTable';
import TransactionTaskbarWrapper from '../transactions/TransactionTaskbarWrapper';
import TransactionTitle from '../transactions/TransactionTitle';
import UpdateCategorizationsWarningDialog from '../transactions/UpdateCategorizationsWarningDialog';
import { isCuratorByProjectId, isVerifierByProjectId } from '../utils/Authorization';
import { getAuthorizedUser, selectActiveProjectInfo } from '../utils/Selectors';
import style from './Spend.module.scss';

const Spend = _.compose(
  connect(state => {
    const { location: { recipeId }, transactions: { categoryIds, total, expanded, filterExpanded, queryString }, projects: { initialFetch } } = state;
    return {
      recipe: (selectActiveProjectInfo(state) || {}).recipe,
      recipeId,
      categoryIds,
      total,
      expanded,
      filterExpanded,
      queryString,
      isUserACurator: isCuratorByProjectId(getAuthorizedUser(state), selectActiveProjectInfo(state)?.projectDoc.id.id),
      isUserAVerifier: isVerifierByProjectId(getAuthorizedUser(state), selectActiveProjectInfo(state)?.projectDoc.id.id),
      initialProjectsFetch: initialFetch,
    };
  }, {
    onSetQueryString: queryString => ({ type: 'Transactions.setQueryString', queryString }),
    // Note: will likely need to change this to add/remove categoryId
    onSetCategoryIds: categoryIds => ({ type: 'Transactions.setCategoryIds', categoryIds }),
  }),
)(class Spend extends React.Component {
  static propTypes = {
    categoryIds: ImmutablePropTypes.listOf(PropTypes.number),
    expanded: PropTypes.bool.isRequired,
    filterExpanded: PropTypes.bool.isRequired,
    isUserACurator: PropTypes.bool.isRequired,
    isUserAVerifier: PropTypes.bool.isRequired,
    onSetCategoryIds: PropTypes.func.isRequired,
    onSetQueryString: PropTypes.func.isRequired,
    queryString: PropTypes.string,
    taxonomy: PropTypes.instanceOf(ProcurementTaxonomy),
    total: PropTypes.number.isRequired,
  };

  getHeader = () => {
    const { total, onSetQueryString, queryString, filterExpanded, isUserACurator, initialProjectsFetch } = this.props;
    return (
      <PageHeader
        title={<TransactionTitle transactionTotal={total} />}
        className={classNames(style.transactionHeader, { [style.headerFilterExpanded]: filterExpanded })}
        searchbox={(
          <div className={style.actionSection}>
            <SearchBox value={queryString} onSearch={onSetQueryString} />
            {isUserACurator && initialProjectsFetch ? <TransactionSuggestCategorizationButton key="suggest-categorizations" iconify={false} /> : null}
          </div>
        )}
      />
    );
  };

  renderFilter = (isUserACurator) => {
    const { filterExpanded } = this.props;
    if (filterExpanded) {
      return (
        <div className={classNames(style.filterPanelContainer, { [style.filterExpanded]: filterExpanded })}>
          <TransactionFilter isUserACurator={isUserACurator} />
        </div>
      );
    }
  };

  render() {
    const { isUserACurator, isUserAVerifier, expanded, filterExpanded } = this.props;
    return (
      <Chrome
        rightSidebar={
          <SpendTransactionSidebar {...{ isUserACurator, isUserAVerifier }} recipeId={this.props.recipeId} />
        }
        isExpanded={expanded}
      >
        <ModelLoader />
        <TransactionLoader />
        <TaxonomyLoader />
        <CategorizeDialog />
        {this.getHeader()}
        {this.props.recipe ? this.renderFilter(isUserACurator || isUserAVerifier) : null}
        <div className={classNames(style.tableContainer, { [style.filterExpanded]: filterExpanded })}>
          {this.props.recipe ?
            <TransactionTaskbarWrapper {...{ isUserACurator, isUserAVerifier }} />
            : null}
          <TransactionTable {...{ isUserACurator, isUserAVerifier }} recipeId={this.props.recipeId} recipe={this.props.recipe} />
        </div>
        <UpdateCategorizationsWarningDialog />
        <CategoryFilterDialog />
      </Chrome>
    );
  }
});

export default Spend;
