import { List, Map } from 'immutable';

import {
  CREATE_EXTERNAL_DATASET_COMPLETED,
  CREATE_EXTERNAL_DATASET_FAILED,
  HIDE_DIALOG,
} from '../addDataset/AddDatasetActionTypes';
import { FetchError } from '../api/FetchResult';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import { StoreReducers } from '../stores/AppAction';
import { ArgTypes, Checker } from '../utils/ArgValidation';
import {
  RESET,
  SET_DESCRIPTION,
  SET_ERROR_MESSAGE,
  SET_FILE,
  SET_FILE_UPLOAD_CONFIG,
  SET_PARSE_FILE_RESULT,
  SET_PREVIEW_COLUMN_WIDTH,
  SET_PRIMARY_KEY_COLUMN_NAME,
  SET_PRIMARY_KEY_CUSTOM_NAME,
  SET_SHOULD_PROFILE,
  SET_SHOULD_TRUNCATE,
  SET_VIEW_CURL_COMMAND,
  TOGGLE_CONFIG_VISIBLE,
  UPLOAD_AND_CREATE,
  UPLOAD_AND_CREATE_COMPLETED,
  UPLOAD_AND_CREATE_FAILED,
  UPLOAD_FILE_FAILED,
} from './FileUploadActionTypes';
import { SUBMIT_MEMBERSHIP_CHANGES_COMPLETED } from './ProjectDatasetCatalogActionTypes';

export class FileUploadConfig extends getModelHelpers({
  columnSeparator: { type: ArgTypes.string, defaultValue: ',' },
  recordSeparator: { type: ArgTypes.string, defaultValue: '\\n' },
  quoteCharacter: { type: ArgTypes.string, defaultValue: '"' },
  escapeCharacter: { type: ArgTypes.string, defaultValue: '"' },
  commentCharacter: { type: ArgTypes.string, defaultValue: '#' },
}, 'FileUploadConfig')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class FileUploadConfigRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  // this is a default constructor when no arguments are passed in
  constructor() {
    super({
      columnSeparator: ',',
      recordSeparator: '\\n',
      quoteCharacter: '"',
      escapeCharacter: '"',
      commentCharacter: '#',
    });
  }

  getColumnSeparatorValue() {
    return (this.columnSeparator === '\\t') ? '\t' : this.columnSeparator;
  }
}

export class FileUploadStore extends getModelHelpers({
  file: { type: ArgTypes.nullable(ArgTypes.instanceOf(File)) },
  fields: { type: ArgTypes.Immutable.list.of(ArgTypes.string), defaultValue: List<string>() },
  description: { type: ArgTypes.string, defaultValue: '' },
  shouldProfile: { type: ArgTypes.bool, defaultValue: true },
  shouldTruncate: { type: ArgTypes.bool, defaultValue: false },
  viewCurlCommand: { type: ArgTypes.bool, defaultValue: false },
  errorMessageHeader: { type: ArgTypes.nullable(ArgTypes.string) },
  errorMessage: { type: ArgTypes.nullable(ArgTypes.string) },
  fileUploadError: { type: ArgTypes.orUndefined(ArgTypes.object as Checker<FetchError>) },
  loading: { type: ArgTypes.bool, defaultValue: false },
  configVisible: { type: ArgTypes.bool, defaultValue: false },
  config: { type: ArgTypes.instanceOf(FileUploadConfig), defaultValue: new FileUploadConfig() },
  primaryKeyColumnName: { type: ArgTypes.nullable(ArgTypes.string) },
  primaryKeyCustomName: { type: ArgTypes.nullable(ArgTypes.string), defaultValue: 'primaryKey' },
  previewData: {
    type: ArgTypes.Immutable.list.of(ArgTypes.Immutable.map.of(ArgTypes.string, ArgTypes.string)),
    defaultValue: List<Map<string, string>>(),
  },
  previewColumnWidths: {
    type: ArgTypes.Immutable.map.of(ArgTypes.number, ArgTypes.string),
    defaultValue: Map<string, number>(),
  },
}, 'FileUploadStore')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class FileUploadStoreRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  reduce(reduceFunction: Function, accumulator: any) {
    Array.from(this.toSeq().entries()).forEach(entry => {
      accumulator = reduceFunction(accumulator, entry[1], entry[0]);
    });
    return accumulator;
  }
}

const ensureIdFieldIsUnique = (state: FileUploadStore) => {
  const { primaryKeyColumnName, primaryKeyCustomName, fields, errorMessage } = state;
  const primaryKeyIsDuplicateNameMsg = 'Dataset already has a field named';
  if (!primaryKeyColumnName && primaryKeyCustomName && fields.contains(primaryKeyCustomName)) {
    return state.set('errorMessage', `${primaryKeyIsDuplicateNameMsg} ${primaryKeyCustomName}`);
  }
  if (errorMessage && errorMessage.indexOf(primaryKeyIsDuplicateNameMsg) !== -1) {
    return state.delete('errorMessage');
  }
  return state;
};

export const initialState = new FileUploadStore({});

export const reducers: StoreReducers<FileUploadStore> = {
  [RESET]: (state) => {
    return state.clear();
  },
  [SET_FILE]: (state, { file }) => {
    return state.clear().set('file', file);
  },
  [SET_PARSE_FILE_RESULT]: (state, { fields, previewData }) => {
    let previewColumnWidths = Map<string, number>();
    // column widths will be the size of the header text (at least 100px, at most 150px)
    const minColumnWidth = 100;
    const maxColumnWidth = 150;
    const columnPadding = 20;
    fields.forEach(f => {
      previewColumnWidths = previewColumnWidths.set(f, Math.max(Math.min(f.length * 10 + columnPadding, maxColumnWidth), minColumnWidth));
    });
    return state
      .merge({ fields, previewData, previewColumnWidths })
      .delete('primaryKeyColumnName')
      .delete('primaryKeyCustomName');
  },
  [SET_DESCRIPTION]: (state, { description }) => {
    return state.set('description', description);
  },
  [SET_SHOULD_PROFILE]: (state, { shouldProfile }) => {
    return state.set('shouldProfile', shouldProfile);
  },
  [SET_SHOULD_TRUNCATE]: (state, { shouldTruncate }) => {
    return state.set('shouldTruncate', shouldTruncate);
  },
  [SET_ERROR_MESSAGE]: (state, { errorMessage }) => {
    return state.set('errorMessage', errorMessage);
  },
  [SET_VIEW_CURL_COMMAND]: (state, { viewCurlCommand }) => {
    return state.set('viewCurlCommand', viewCurlCommand);
  },
  [UPLOAD_AND_CREATE]: (state) => {
    return state.set('loading', true);
  },
  [UPLOAD_AND_CREATE_COMPLETED]: (state) => {
    return state.clear();
  },
  [UPLOAD_AND_CREATE_FAILED]: (state, { errorMessageHeader, errorMessage }) => {
    return state.clear().merge({ errorMessageHeader, errorMessage });
  },
  [UPLOAD_FILE_FAILED]: (state, { error }) => {
    return state.clear().merge({ fileUploadError: error });
  },
  [TOGGLE_CONFIG_VISIBLE]: (state) => {
    return state.set('configVisible', !state.configVisible);
  },
  [SET_FILE_UPLOAD_CONFIG]: (state, { config }) => {
    return state.set('config', config).delete('viewCurlCommand');
  },
  [SET_PRIMARY_KEY_COLUMN_NAME]: (state, { columnName }) => {
    return state.set('primaryKeyColumnName', columnName);
  },
  [SET_PRIMARY_KEY_CUSTOM_NAME]: (state, { customName }) => {
    return ensureIdFieldIsUnique(state.set('primaryKeyCustomName', customName));
  },
  [SET_PREVIEW_COLUMN_WIDTH]: (state, { column, width }) => {
    return state.set('previewColumnWidths', state.get('previewColumnWidths').set(column, width));
  },
  [HIDE_DIALOG]: (state) => {
    return state.clear();
  },
  [SUBMIT_MEMBERSHIP_CHANGES_COMPLETED]: (state) => {
    return state.clear();
  },
  [CREATE_EXTERNAL_DATASET_COMPLETED]: (state) => {
    return state.clear();
  },
  [CREATE_EXTERNAL_DATASET_FAILED]: (state) => {
    return state.clear();
  },
};
