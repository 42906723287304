import { List } from 'immutable';

import AuthUser from '../models/AuthUser';
import Document from '../models/doc/Document';
import Group from '../models/Group';
import KeyMods from '../models/KeyMods';
import MinimalAuthUser from '../models/MinimalAuthUser';
import UserRoles from '../models/UserRoles';
import { GroupsFilterInfo, UsersFilterInfo } from '../users/UsersStore';
import AccountConfig from '../users/utils/AccountConfig';
import { AuthUserSortFieldE } from '../users/utils/AuthUserSortField';
import { SortStateValueType } from './SortState';


export const START_CREATE_GROUP = 'Users.startCreateGroup';
export const START_EDIT_GROUP = 'Users.startEditGroup';
export const SET_GROUP_NAME = 'Users.setGroupName';
export const SET_GROUP_DESC = 'Users.setGroupDescription';
export const START_DELETE_GROUP = 'Users.startDeleteGroup';
export const DELETE_GROUP_COMPLETED = 'Users.deleteGroupCompleted';
export const DELETE_GROUP_FAILED = 'Users.deleteGroupFailed';
export const UPSERT_GROUP_COMPLETED = 'Users.upsertGroupCompleted';
export const UPSERT_GROUP_FAILED = 'Users.upsertGroupFailed';
export const STOP_GROUP_CONFIG = 'Users.stopGroupConfig';
export const FETCH_GROUPS = 'Users.fetchGroups';
export const FETCH_GROUPS_COMPLETED = 'Users.fetchGroupsCompleted';
export const FETCH_GROUPS_FAILED = 'Users.fetchGroupsFailed';

export const SET_SELECTED_TAB = 'Users.setSelectedTab';

export const FETCH_USERS_COMPLETED = 'Users.fetchUsersCompleted';
export const FETCH_FULL_USERS = 'Users.fetchFullUsers';
export const FETCH_FULL_USERS_COMPLETED = 'Users.fetchFullUsersCompleted';
export const FETCH_FULL_USERS_FAILED = 'Users.fetchFullUsersFailed';

export const SET_ACCOUNT_CONFIG = 'Users.setAccountConfig';
export const SET_SHOW_ACCOUNT_MODAL = 'Users.setShowAccountModal';

export const CREATE_USER_COMPLETED = 'Users.createUserCompleted';
export const CREATE_USER_FAILED = 'Users.createUserFailed';

export const UPDATE_USER_COMPLETED = 'Users.updateUserCompleted';
export const UPDATE_USER_FAILED = 'Users.updateUserFailed';

export const SET_ACTIVE_ROW_NUMBER = 'Users.setActiveRowNumber';
export const SELECT_ROW = 'Users.selectRow';
export const SELECT_ALL_ROWS = 'Users.selectAllRows';

export const SET_QUERY = 'Users.setQuery';
export const SET_QUERY_COMPLETED = 'Users.setQueryCompleted';
export const SET_QUERY_FAILED = 'Users.setQueryFailed';

export const SET_PAGE_NUMBER = 'Users.setPageNumber';
export const SET_PAGE_NUMBER_COMPLETED = 'Users.setPageNumberCompleted';
export const SET_PAGE_NUMBER_FAILED = 'Users.setPageNumberFailed';
export const SET_PAGE_SIZE = 'Users.setPageSize';
export const SET_PAGE_SIZE_COMPLETED = 'Users.setPageSizeCompleted';
export const SET_PAGE_SIZE_FAILED = 'Users.setPageSizeFailed';
export const SET_SORT = 'Users.setSort';
export const SET_SORT_COMPLETED = 'Users.setSortCompleted';
export const SET_SORT_FAILED = 'Users.setSortFailed';

export const HIDE_CHANGE_ACTIVE_STATUS_DIALOG = 'Users.hideChangeActiveStatusDialog';
export const SET_CHANGE_ACTIVE_STATUS_ACTIVATE = 'Users.setChangeActiveStatusActivate';
export const SUBMIT_CHANGE_ACTIVE_STATUS_COMPLETED = 'Users.submitChangeActiveStatusCompleted';
export const SUBMIT_CHANGE_ACTIVE_STATUS_FAILED = 'Users.submitChangeActiveStatusFailed';

export const FETCH_USER_ROLES = 'Users.fetchUserRoles';
export const FETCH_USER_ROLES_COMPLETED = 'Users.fetchUserRolesCompleted';
export const FETCH_USER_ROLES_FAILED = 'Users.fetchUserRolesFailed';
export const SET_USER_ROLES = 'Users.setUserRoles';

export const START_EDITING_POLICY_MEMBERSHIP = 'Users.startEditingPolicyMembership';
export const ADD_TO_POLICY_MEMBERSHIP = 'Users.addToPolicyMembership';
export const REMOVE_FROM_POLICY_MEMBERSHIP = 'Users.removeFromPolicyMembership';
export const STOP_EDITING_POLICY_MEMBERSHIP = 'Users.stopEditingPolicyMembership';
export const UPDATE_POLICY_MEMBERSHIP_COMPLETED = 'Users.updatePolicyMembershipCompleted';
export const UPDATE_POLICY_MEMBERSHIP_FAILED = 'Users.updatePolicyMembershipFailed';

export const TOGGLE_ADMIN_ROLE = 'Users.toggleAdminRole';

interface UsersStartCreateGroup {
  type: typeof START_CREATE_GROUP;
}

interface UsersStartEditGroup {
  type: typeof START_EDIT_GROUP;
  id: number
}

interface UsersSetGroupName {
  type: typeof SET_GROUP_NAME;
  groupname: string
}

interface UsersSetGroupDescription {
  type: typeof SET_GROUP_DESC;
  description: string
}

interface UsersStartDeleteGroup {
  type: typeof START_DELETE_GROUP;
  id: number
}

interface UsersDeleteGroupCompleted {
  type: typeof DELETE_GROUP_COMPLETED;
}

interface UsersDeleteGroupFailed {
  type: typeof DELETE_GROUP_FAILED;
}

interface UsersToggleAdminRole {
  type: typeof TOGGLE_ADMIN_ROLE;
}

interface UsersUpsertGroupCompleted {
  type: typeof UPSERT_GROUP_COMPLETED;
}

interface UsersUpsertGroupFailed {
  type: typeof UPSERT_GROUP_FAILED;
}

interface UsersStopGroupConfig {
  type: typeof STOP_GROUP_CONFIG;
}

interface UsersFetchGroups {
  type: typeof FETCH_GROUPS;
}

interface UsersFetchGroupsCompleted {
  type: typeof FETCH_GROUPS_COMPLETED;
  data: List<Document<Group>>
  filterInfo: GroupsFilterInfo
}

interface UsersFetchGroupsFailed {
  type: typeof FETCH_GROUPS_FAILED;
  filterInfo: GroupsFilterInfo
}

interface UsersSetSelectedTab {
  type: typeof SET_SELECTED_TAB;
  tab: string
}

interface UsersFetchUsersCompleted {
  type: typeof FETCH_USERS_COMPLETED;
  data: List<MinimalAuthUser>
}

interface UsersFetchFullUsers {
  type: typeof FETCH_FULL_USERS;
}

interface UsersFetchFullUsersCompleted {
  type: typeof FETCH_FULL_USERS_COMPLETED;
  total: number
  fullUsers: List<AuthUser>
  filterInfo: UsersFilterInfo
}

interface UsersFetchFullUsersFailed {
  type: typeof FETCH_FULL_USERS_FAILED;
  filterInfo: UsersFilterInfo
}

interface UsersSetAccountConfig {
  type: typeof SET_ACCOUNT_CONFIG;
  accountConfig: AccountConfig
}

interface UsersSetShowAccountModal {
  type: typeof SET_SHOW_ACCOUNT_MODAL;
  showAccountModal: boolean | undefined
  editAccountMode?: boolean | null | undefined
  rowNumber?: number | null | undefined
}

interface UsersCreateUserCompleted {
  type: typeof CREATE_USER_COMPLETED;
  users: List<MinimalAuthUser>
  fullUsers: List<AuthUser>
  total: number
}

interface UsersCreateUserFailed {
  type: typeof CREATE_USER_FAILED;
}

interface UsersUpdateUserCompleted {
  type: typeof UPDATE_USER_COMPLETED;
  users: List<MinimalAuthUser>
  fullUsers: List<AuthUser>
  total: number
}

interface UsersUpdateUserFailed {
  type: typeof UPDATE_USER_FAILED;
}

interface UsersSetActiveRowNumber {
  type: typeof SET_ACTIVE_ROW_NUMBER;
  rowNumber: number
}

interface UsersSelectRow {
  type: typeof SELECT_ROW;
  keyMods: KeyMods
  rowNumber: number
}

interface UsersSelectAllRows {
  type: typeof SELECT_ALL_ROWS;
}

interface UsersSetQuery {
  type: typeof SET_QUERY;
  query: string
}

interface UsersSetQueryCompleted {
  type: typeof SET_QUERY_COMPLETED;
  users: List<MinimalAuthUser>
  fullUsers: List<AuthUser>
  total: number
}

interface UsersSetQueryFailed {
  type: typeof SET_QUERY_FAILED;
}

interface UsersSetPageNumber {
  type: typeof SET_PAGE_NUMBER;
  pageNumber: number
}

interface UsersSetPageNumberCompleted {
  type: typeof SET_PAGE_NUMBER_COMPLETED;
  users: List<MinimalAuthUser>
  fullUsers: List<AuthUser>
  total: number
}

interface UsersSetPageNumberFailed {
  type: typeof SET_PAGE_NUMBER_FAILED;
}

interface UsersSetPageSize {
  type: typeof SET_PAGE_SIZE;
  pageSize: number
}

interface UsersSetPageSizeCompleted {
  type: typeof SET_PAGE_SIZE_COMPLETED;
  users: List<MinimalAuthUser>
  fullUsers: List<AuthUser>
  total: number
}

interface UsersSetPageSizeFailed {
  type: typeof SET_PAGE_SIZE_FAILED;
}

interface UsersSetSort {
  type: typeof SET_SORT;
  sortFieldName: AuthUserSortFieldE | null
  sortDirection: SortStateValueType | null
}

interface UsersSetSortCompleted {
  type: typeof SET_SORT_COMPLETED;
  users: List<MinimalAuthUser>
  fullUsers: List<AuthUser>
  total: number
}

interface UsersSetSortFailed {
  type: typeof SET_SORT_FAILED;
}

interface UsersHideChangeActiveStatusDialog {
  type: typeof HIDE_CHANGE_ACTIVE_STATUS_DIALOG;
}

interface UsersSetChangeActiveStatusActivate {
  type: typeof SET_CHANGE_ACTIVE_STATUS_ACTIVATE;
  activate: boolean
}

interface UsersSubmitChangeActiveStatusCompleted {
  type: typeof SUBMIT_CHANGE_ACTIVE_STATUS_COMPLETED;
  users: List<MinimalAuthUser>
  fullUsers: List<AuthUser>
  total: number
}

interface UsersSubmitChangeActiveStatusFailed {
  type: typeof SUBMIT_CHANGE_ACTIVE_STATUS_FAILED;
}

interface UsersFetchUserRoles {
  type: typeof FETCH_USER_ROLES;
}

interface UsersFetchUserRolesCompleted {
  type: typeof FETCH_USER_ROLES_COMPLETED;
  userRoles: UserRoles
}

interface UsersFetchUserRolesFailed {
  type: typeof FETCH_USER_ROLES_FAILED;
}

interface UsersSetUserRoles {
  type: typeof SET_USER_ROLES;
  userRoles: UserRoles
}

interface UsersStartEditingPolicyMembership {
  type: typeof START_EDITING_POLICY_MEMBERSHIP;
  user: MinimalAuthUser
}

interface UsersAddToPolicyMembership {
  type: typeof ADD_TO_POLICY_MEMBERSHIP;
  policyId: number
  role: string
}

interface UsersRemoveFromPolicyMembership {
  type: typeof REMOVE_FROM_POLICY_MEMBERSHIP;
  policyId: number
}

interface UsersStopEditingPolicyMembership {
  type: typeof STOP_EDITING_POLICY_MEMBERSHIP;
}

interface UsersUpdatePolicyMembershipCompleted {
  type: typeof UPDATE_POLICY_MEMBERSHIP_COMPLETED;
}

interface UsersUpdatePolicyMembershipFailed {
  type: typeof UPDATE_POLICY_MEMBERSHIP_FAILED;
}

export type UsersActionTypes
  = UsersStartCreateGroup
  | UsersStartCreateGroup
  | UsersStartEditGroup
  | UsersSetGroupName
  | UsersSetGroupDescription
  | UsersStartDeleteGroup
  | UsersDeleteGroupCompleted
  | UsersDeleteGroupFailed
  | UsersUpsertGroupCompleted
  | UsersUpsertGroupFailed
  | UsersStopGroupConfig
  | UsersFetchGroups
  | UsersFetchGroupsCompleted
  | UsersFetchGroupsFailed
  | UsersSetSelectedTab
  | UsersFetchUsersCompleted
  | UsersFetchFullUsers
  | UsersFetchFullUsersCompleted
  | UsersFetchFullUsersFailed
  | UsersSetAccountConfig
  | UsersSetShowAccountModal
  | UsersCreateUserCompleted
  | UsersCreateUserFailed
  | UsersUpdateUserCompleted
  | UsersUpdateUserFailed
  | UsersSetActiveRowNumber
  | UsersSelectRow
  | UsersSelectAllRows
  | UsersSetQuery
  | UsersSetQueryCompleted
  | UsersSetQueryFailed
  | UsersSetPageNumber
  | UsersSetPageNumberCompleted
  | UsersSetPageNumberFailed
  | UsersSetPageSize
  | UsersSetPageSizeCompleted
  | UsersSetPageSizeFailed
  | UsersSetSort
  | UsersSetSortCompleted
  | UsersSetSortFailed
  | UsersHideChangeActiveStatusDialog
  | UsersSetChangeActiveStatusActivate
  | UsersSubmitChangeActiveStatusCompleted
  | UsersSubmitChangeActiveStatusFailed
  | UsersFetchUserRoles
  | UsersFetchUserRolesCompleted
  | UsersFetchUserRolesFailed
  | UsersSetUserRoles
  | UsersStartEditingPolicyMembership
  | UsersAddToPolicyMembership
  | UsersRemoveFromPolicyMembership
  | UsersStopEditingPolicyMembership
  | UsersUpdatePolicyMembershipCompleted
  | UsersUpdatePolicyMembershipFailed
  | UsersToggleAdminRole
