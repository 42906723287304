import { List, Set } from 'immutable';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { AppDispatch } from '../stores/MainStore';
import { assertNever } from '../utils/typescript';
import * as DatasetFilter from './DatasetFilter';
import { GRInput } from './EditableGoldenRecordsRule';
import { FilterClusterRecords, FilterClusterRecordsTypeName, FilterClusterRecordsTypeNameE } from './FilterClusterRecords';
import { FILTER_OPTIONS } from './FilterElement';
import { getFilterExpressionEnvelope } from './GoldenRecordsAsync';
import style from './GoldenRecordsSidebar.module.scss';

const { CHOOSING_AGGREGATION, SINGLE_INPUT, DATASET_FILTER, FILTER_EMPTIES, PREDICATE } = FilterClusterRecordsTypeName;

export const Row: React.FC<{
  left: React.ReactNode
  right: React.ReactNode
}> = ({ left, right }) => (
  <div className={style.readOnlyCondition}>
    <span className={style.left}>{left}</span>
    <span className={style.right}>{right}</span>
  </div>
);

type ReadOnlyFilterElementOwnProps = {
  filter: FilterClusterRecords
  ruleName: string
  index: number
}

const ReadOnlyFilterElement = connect(null, (dispatch: AppDispatch, { filter, ruleName, index }: ReadOnlyFilterElementOwnProps) => ({
  onShowReadonlyDatasetFilter: () => (filter.type === DatasetFilter.TYPE) && dispatch({ type: 'GoldenRecords.showReadonlyDatasetFilter', filter, ruleName, filterIndex: index }),
}))(({ filter, index, onShowReadonlyDatasetFilter }) => {
  const inputAttributeRow = (
    <Row
      left={<div>Input attribute:</div>}
      right={filter && 'inputAttributeName' in filter && filter.inputAttributeName}
    />
  );

  const expressionRow = (
    <div className={style.expression}>
      <GRInput
        name={`expr${index}`}
        contentEnvelope={getFilterExpressionEnvelope(filter)}
        contents={(filter && 'expression' in filter && filter.expression) || ''}
        lintingErrors={List()}
      />
    </div>
  );

  const getElement = () => {
    const type = filter.type as FilterClusterRecordsTypeNameE;
    switch (type) {
      case SINGLE_INPUT:
        return <Fragment>
          {inputAttributeRow}
        </Fragment>;
      case CHOOSING_AGGREGATION:
        return <Fragment>
          {expressionRow}
        </Fragment>;
      case PREDICATE:
        return <Fragment>
          {expressionRow}
        </Fragment>;
      case FILTER_EMPTIES:
        return <Fragment>
          {inputAttributeRow}
        </Fragment>;
      case DATASET_FILTER:
        const datasetFilter = filter as DatasetFilter.DatasetFilter;
        return <a className={style.datasetFilterSummary} onClick={onShowReadonlyDatasetFilter}>{Set.union(datasetFilter.priorities).size} datasets prioritized | {datasetFilter.excluded.length} datasets excluded</a>;
      default:
        assertNever(type);
        break;
    }
  };
  return (
    <div className={style.filterList}>
      <div className={style.filter}>
        <Row
          left={<div>Condition {index + 1}: </div>}
          right={<span>{FILTER_OPTIONS.find(({ value }) => value === filter.type || ('function' in filter && value === filter.function))?.label}</span>}
        />
        {getElement()}
      </div>

    </div>
  );
});

export default ReadOnlyFilterElement;
