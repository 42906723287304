import { pick } from 'lodash';

import * as ProcurifyClient from '../api/ProcurifyClient';
import { pageTitleMap } from '../components/PageTitleMap';
import { SHOW } from '../errorDialog/ErrorDialogActionTypes';
import { PostButtonSpec } from '../models/PostButtonSpec';
import ProjectInfo from '../models/ProjectInfo';
import { AppThunkAction } from '../stores/AppAction';
import {
  EXTENSION_BUTTON_POST_CALL_COMPLETE,
  EXTENSION_BUTTON_POST_CALL_REQUESTED,
  FETCH_EXTENSION_BUTTONS_COMPLETE,
  SHOW_EXTENSION_RESPONSE_DIALOG,
  STORAGE_HEALTH_CHECK_COMPLETE,
  STORAGE_HEALTH_CHECK_FAILED,
} from './ChromeActionTypes';

/**
 * Converts projectInfo.projectType value (derived from recipes) to Extension Project Types
 * (com.tamr.procurify.service.extension.ExtensionService.ProjectType)
 */
const project_type_map: { [name: string]: string } = {
  DEDUP: 'Mastering',
  ENRICHMENT: 'Enrichment',
  GOLDEN_RECORDS: 'Golden Records',
  SCHEMA_MAPPING_RECOMMENDATIONS: 'Schema Mapping',
  CATEGORIZATION: 'Categorization',
};

/**
 * Returns the name of the current page in the format expected by Extension service
 * <projectType>:<pageTitle>
 * (com.tamr.procurify.service.extension.ExtensionService#validPageNames)
 */
export function getPageName(page: string, projectInfo: ProjectInfo | null) {
  const pageTitle = pageTitleMap[page].trim();
  if (projectInfo == null) {
    return pageTitle;
  }
  return project_type_map[projectInfo.projectType] + ':' + pageTitle;
}

/**
 * Updates the extension buttons in state to match the current page
 */
export const fetchExtensionPageButtons = (page: string, projectInfo: ProjectInfo | null): AppThunkAction<void> => (dispatch) => {
  const pageName = getPageName(page, projectInfo);
  return ProcurifyClient.getExtensionPageButtons(pageName)
    .then((data) => {
      dispatch({
        type: FETCH_EXTENSION_BUTTONS_COMPLETE,
        data,
      });
    }, (response) => dispatch({ type: SHOW, detail: 'Error loading extension buttons', response }),
    );
};

/**
 * A function to call a user provided post endpoint, which posts a pre-defined body
 */
export const doPostAction = (buttonSpec: PostButtonSpec, pageName: string, projectId: string)
  : AppThunkAction<void> => (dispatch) => {
  const allBodyValues = {
    buttonId: buttonSpec.buttonId,
    pageName,
    projectId,
    returnUrl: window.location.href,
  };
  const bodyContent = JSON.stringify(pick(allBodyValues, ...buttonSpec.postBodyKeys));
  dispatch({ type: EXTENSION_BUTTON_POST_CALL_REQUESTED, buttonSpec });
  dispatch({ type: SHOW_EXTENSION_RESPONSE_DIALOG, show: true });
  return Promise.resolve(fetch(buttonSpec.postUrl, {
    method: 'POST',
    body: bodyContent,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json,text/plain',
    },
  })
    .then((response) => {
      response.text().then((msg) => {
        if (response.ok) {
          dispatch({
            type: EXTENSION_BUTTON_POST_CALL_COMPLETE,
            successful: true,
            message: msg,
          });
        } else {
          dispatch({
            type: EXTENSION_BUTTON_POST_CALL_COMPLETE,
            successful: false,
            message: `Error ${response.status}: ${response.statusText}\nDetails: ${msg}`,
          });
        }
      });
    },
    ).catch((error) => {
      if (error instanceof Response) {
        dispatch({
          type: EXTENSION_BUTTON_POST_CALL_COMPLETE,
          successful: false,
          message: `ERROR ${error.status}: ${error.statusText}`,
        });
      } else {
        dispatch({
          type: EXTENSION_BUTTON_POST_CALL_COMPLETE,
          successful: false,
          message: error.toString(),
        });
      }
    }),
  );
};

export const startStorageHealthStatusPolling = (): AppThunkAction<void> => (dispatch) => {
  return ProcurifyClient.getStorageStatus()
    .then((data) => {
      dispatch({
        type: STORAGE_HEALTH_CHECK_COMPLETE,
        data,
      });
    }, () => {
      dispatch({ type: STORAGE_HEALTH_CHECK_FAILED });
    },
    ).then(() => {
      setTimeout(() => dispatch(startStorageHealthStatusPolling()), 10000);
    });
};
