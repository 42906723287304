import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React, { Requireable } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import _ from 'underscore';

const propTypes = {
  onPathSegmentClick: PropTypes.func as Requireable<(path: string[]) => void>,
  path: ImmutablePropTypes.listOf(PropTypes.string.isRequired).isRequired,
  showTooltip: PropTypes.bool,
};

type CategoryPathProps = InferProps<typeof propTypes>

class CategoryPath extends React.Component<CategoryPathProps> {
  static propTypes = propTypes;

  static defaultProps = { showTooltip: true };

  renderSegmentElement = (fullPath: string[], index: number) => {
    let content;
    let isChevron = false;
    if (index === this.props.path.size - 1) {
      content = this.props.path.get(index);
    } else {
      content = <span>&raquo;</span>;
      isChevron = true;
    }

    let onClick;
    if (this.props.onPathSegmentClick) {
      onClick = _.partial(this.props.onPathSegmentClick, fullPath);
    }

    const title = this.props.showTooltip ? fullPath[fullPath.length - 1] : undefined;

    const segmentClassName = classNames({
      'path-segment': true,
      clickable: !!onClick,
      chevron: isChevron,
    });

    return (
      <span
        key={index}
        className={segmentClassName}
        onClick={onClick}
        title={title}
      >
        {content}
      </span>
    );
  };

  render() {
    if (!this.props.path) {
      return <div />;
    }

    // convert [seg1, seg2, seg3] into [[seg1], [seg1, seg2], [seg1, seg2, seg3]] for callback
    const fullPathSegments = [] as string[][];
    let currentPath = [] as string[];
    this.props.path.forEach((pathSegment) => {
      currentPath = currentPath.concat([pathSegment]);
      fullPathSegments.push(currentPath);
    });

    // build elements for each path segment
    const segmentElements = fullPathSegments.map(this.renderSegmentElement);

    return (
      <div className="category-path-component">
        {segmentElements}
      </div>
    );
  }
}

export default CategoryPath;
