import { Set } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import CenterContent from '../components/CenterContent';
import Checkbox from '../components/Input/Checkbox';
import AttributeId, { attributeId } from '../models/AttributeId';
import SourceAttribute from '../models/SourceAttribute';
import { getUnifiedDatasetName } from '../utils/Selectors';
import { SAListHeaderRow } from './ListHeaderRow';
import {
  CLEAR_FILTER_RELATED_ID, SOURCE_SEARCH,
  SOURCE_TOGGLE_FILTER_DNM,
  SOURCE_TOGGLE_FILTER_MAPPED,
  SOURCE_TOGGLE_FILTER_UNMAPPED,
} from './SchemaMappingActionTypes';
import { bootstrapUnifiedAttributes } from './SchemaMappingAsync';
import SchemaMappingBulkMap from './SchemaMappingBulkMap';
import { SchemaMappingSADatasetFilter } from './SchemaMappingDatasetFilter';
import SchemaMappingRefinement from './SchemaMappingRefinement';
import { filteredSourceAttributes, getSourceAttributesAllOnFirstPage, selectSourceMappings } from './SchemaMappingStore';

const SourceListStats = _.compose(
  connect(state => {
    const { schemaMapping } = state;
    const { sourceFilterMapped, sourceFilterUnmapped, sourceFilterDNM, sourceAttributes, unifiedFilterRelatedId, sourceSearchTerm } = schemaMapping;
    const datasetName = getUnifiedDatasetName(state);
    return {
      sourceSearchTerm,
      sourceAttributes,
      sourceFilterMapped,
      sourceFilterUnmapped,
      sourceFilterDNM,
      datasetName,
      sourceAttributesAllOnFirstPage: getSourceAttributesAllOnFirstPage(state),
      filterRelatedId: unifiedFilterRelatedId,
      filteredAttributes: filteredSourceAttributes(schemaMapping),
      sourceMappings: selectSourceMappings(schemaMapping),
      sourceSelectedLength: schemaMapping?.sourceSelectedIds?.size,
    };
  }, {
    onToggleFilterMapped: () => ({ type: SOURCE_TOGGLE_FILTER_MAPPED }),
    onToggleFilterUnmapped: () => ({ type: SOURCE_TOGGLE_FILTER_UNMAPPED }),
    onToggleFilterDNM: () => ({ type: SOURCE_TOGGLE_FILTER_DNM }),
    onClearFilterRelated: () => ({ type: CLEAR_FILTER_RELATED_ID }),
    onSearch: text => ({ type: SOURCE_SEARCH, text }),
    onBootstrapUnifiedAttributes: bootstrapUnifiedAttributes,
  }),
)(class SourceListStats extends React.Component {
  static propTypes = {
    canUserEdit: PropTypes.bool.isRequired,
    datasetName: PropTypes.string,
    filterRelatedId: PropTypes.instanceOf(AttributeId),
    filteredAttributes: ImmutablePropTypes.listOf(PropTypes.instanceOf(SourceAttribute)).isRequired,
    onClearFilterRelated: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onToggleFilterDNM: PropTypes.func.isRequired,
    onToggleFilterMapped: PropTypes.func.isRequired,
    onToggleFilterUnmapped: PropTypes.func.isRequired,
    sourceAttributes: ImmutablePropTypes.listOf(PropTypes.instanceOf(SourceAttribute)).isRequired,
    sourceAttributesAllOnFirstPage: PropTypes.bool.isRequired,
    sourceFilterDNM: PropTypes.bool.isRequired,
    sourceFilterMapped: PropTypes.bool.isRequired,
    sourceFilterUnmapped: PropTypes.bool.isRequired,
    sourceMappings: ImmutablePropTypes.mapOf(ImmutablePropTypes.set.isRequired).isRequired,
  };

  getOverallCountSectionMessage = () => {
    const { sourceAttributes, filterRelatedId, sourceAttributesAllOnFirstPage } = this.props;
    const saResultSize = sourceAttributes.size;
    const attrType = 'input';
    const attrPlurality = saResultSize === 1 ? 'attribute' : 'attributes';
    // actually no source attributes in system
    if (saResultSize === 0 && sourceAttributesAllOnFirstPage) {
      return `No ${attrType} ${attrPlurality}`;
    }
    // special case: filtered to related attribute
    if (filterRelatedId) {
      return (
        <span>
          Filtered to <span className="related-attribute">{filterRelatedId.name}</span> mappings and
          suggestions ({saResultSize} {attrType} {attrPlurality})
        </span>
      );
    }
    // TODO: update this when client-side filtering is added back...
    return `Showing ${!sourceAttributesAllOnFirstPage ? '' : 'all'} ${saResultSize} ${attrType} ${attrPlurality}`;
  };

  renderOverallCountSection = () => {
    const message = this.getOverallCountSectionMessage();
    return (
      <CenterContent className="overall-count-section" horizontally={false} title={message}>
        <div className="overall-count">
          {message}
        </div>
      </CenterContent>
    );
  };

  getFilterTitle = (title, count) => {
    const { sourceAttributesAllOnFirstPage } = this.props;
    const countMessage = `(${count || 0})`;
    return `${title} ${sourceAttributesAllOnFirstPage ? countMessage : ''}`;
  };

  render() {
    const {
      canUserEdit,
      datasetName,
      onSearch,
      onToggleFilterMapped,
      onToggleFilterUnmapped,
      onToggleFilterDNM,
      sourceAttributes,
      sourceMappings,
      sourceFilterMapped,
      sourceFilterUnmapped,
      sourceFilterDNM,
      sourceSearchTerm,
      sourceSelectedLength,
      onBootstrapUnifiedAttributes,
    } = this.props;
    const { mapped, unmapped, doNotMap } = sourceAttributes.countBy(attr => {
      switch (true) {
        case attr.doNotMap.has(datasetName): return 'doNotMap';
        case !!(sourceMappings.get(attributeId(attr)) || Set()).size: return 'mapped';
        default: return 'unmapped';
      }
    }).toJS();
    return (
      <div className="schema-mapping-list-stats">
        {this.renderOverallCountSection()}
        <div className="sources-filter">
          <div className="dropdown-toggle-container">
            <SchemaMappingSADatasetFilter />
            <SchemaMappingBulkMap canUserEdit={canUserEdit} />
            {canUserEdit && <Button
              buttonType="Secondary"
              disabled={sourceSelectedLength === 0}
              onClick={onBootstrapUnifiedAttributes}> Bootstrap
            </Button>}
          </div>
          <div className="search-attributes">
            <SchemaMappingRefinement value={sourceSearchTerm} onSearch={onSearch} />
          </div>
        </div>
        <CenterContent className="mappedness-count-section" horizontally={false}>
          <div className="mappedness-counts">
            <Checkbox
              className="mappedness-count"
              title={this.getFilterTitle('Mapped', mapped)}
              titlePosition="right"
              onChange={onToggleFilterMapped}
              value={sourceFilterMapped}
            />
            <Checkbox
              className="mappedness-count"
              title={this.getFilterTitle('Unmapped', unmapped)}
              titlePosition="right"
              onChange={onToggleFilterUnmapped}
              value={sourceFilterUnmapped}
            />
            <Checkbox
              className="mappedness-count"
              title={this.getFilterTitle('Do not Map', doNotMap)}
              titlePosition="right"
              onChange={onToggleFilterDNM}
              value={sourceFilterDNM}
            />
          </div>
        </CenterContent>
        <SAListHeaderRow />
      </div>
    );
  }
});

export default SourceListStats;
