// com.tamr.dedup.models.dnf.FieldSignal

import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import SimilarityFunction from '../schema-mapping/constants/SimilarityFunction';
import TokenizerConfig from '../schema-mapping/constants/TokenizerConfig';
import TokenWeighting from '../schema-mapping/constants/TokenWeighting';
import { ArgTypes } from '../utils/ArgValidation';

export default class FieldSignal extends getModelHelpers({
  fieldName: { type: ArgTypes.string },
  similarityFunction: { type: SimilarityFunction.argType },
  tokenizerConfig: { type: ArgTypes.orUndefined(TokenizerConfig.argType) },
  tokenWeighting: { type: ArgTypes.orUndefined(TokenWeighting.argType) },
}, 'FieldSignal')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class FieldSignalRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {}
