import PropTypes, { InferProps } from 'prop-types';
import { connect, MapDispatchToPropsParam } from 'react-redux';
import { createSink, pure } from 'recompose';
import _ from 'underscore';

import { AppState } from '../stores/MainStore';

const propTypes = {
  // canFetch: are all system preconditions for fetching this resource met?
  //           eg. if fetching a dataset's materialization in ES, has that dataset even been materialized?
  //           note that one precondition to fetching that isn't owned by this prop is "loader is mounted"
  canFetch: PropTypes.bool.isRequired,
  // fetchArgs: arguments to spread to the invocation of onFetch
  //            this is optional, and no arguments will be supplied to onFetch if this is undefined.
  fetchArgs: PropTypes.array,
  // loading: is a fetch currently outstanding?
  //          this guards against duplicate fetches
  //          and queues the fetch action after a fetch comes back if shouldUpdate remains true
  loading: PropTypes.bool.isRequired,
  // onFetch: the function that actually performs the fetch
  //          ie. a thunk action
  onFetch: PropTypes.func.isRequired,
  // shouldFetch: have any conditions changed since the last time a fetch completed
  //              such that our loaded resource is out of date?
  //              eg. last time we fetched, filter X was not active. It is active now, so shouldFetch is true.
  //              eg. last time we fetched the sort was "column X ASC". Now it is "column X DESC", so shouldFetch is true.
  shouldFetch: PropTypes.bool.isRequired,
};
type Props = InferProps<typeof propTypes>;

// unlike normal components that connect() to state, createLoader has specific props that it needs the connect
//   methods to pass through.
type StateProps = Pick<Props, 'canFetch' | 'fetchArgs' | 'loading' | 'shouldFetch'>;
type DispatchProps = Pick<Props, 'onFetch'>;

export default function createLoader<OwnProps = {}>(
  mapStateToProps: (state: AppState, ownProps: OwnProps) => StateProps,
  mapDispatchToProps: MapDispatchToPropsParam<DispatchProps, OwnProps>,
  displayName: string,
) {
  // createSink is simple: https://github.com/acdlite/recompose/blob/master/docs/API.md#createsink
  const considerFetching = createSink(({ canFetch, shouldFetch, loading, onFetch, fetchArgs }: Props) => {
    // when any of these props change, consider fetching via the following formula
    if (canFetch && shouldFetch && !loading) {
      if (fetchArgs) {
        onFetch(...fetchArgs);
      } else {
        onFetch();
      }
    }
  });
  considerFetching.propTypes = propTypes;
  considerFetching.displayName = displayName || 'Loader'; // could consider allowing this to be passed in

  const loader = _.compose(
    connect(mapStateToProps, mapDispatchToProps),
    // importantly, don't reconsider fetching if props haven't changed
    pure,
  )(considerFetching);
  loader.displayName = displayName || 'Loader';
  return loader;
}
