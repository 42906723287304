import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import { updateProjectPolicies } from '../accessControl/AccessControlAsync';
import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Input from '../components/Input/Input';
import Selector from '../components/Input/Selector';
import Link from '../components/Link';
import SearchBox from '../components/SearchBox';
import ProjectTypes, { CLASSIFICATION, GOLDEN_RECORDS, SCHEMA_MAPPING_RECOMMENDATIONS, SUPPLIER_MASTERING, ENRICHMENT } from '../constants/ProjectTypes';
import { saveGoldenRecordSimple } from '../goldenRecords/GoldenRecordsAsync';
import AuthUser from '../models/AuthUser';
import Document from '../models/doc/Document';
import MinimalAuthUser from '../models/MinimalAuthUser';
import Project, { ENABLE_TRANSFORMATIONS_METADATA_KEY } from '../models/Project';
import Recipe from '../models/Recipe';
import { isAdmin, isAuthorByProjectId } from '../utils/Authorization';
import { getAuthorizedUser, selectProjectInfoByProjectId } from '../utils/Selectors';
import { getPath } from '../utils/Values';
import EnableTransformations from './EnableTransformations';
import { CANCEL_EDITING_PROJECT } from './ProjectsActionTypes';
import { updateProject, updateRecipe } from './ProjectsApi';
import { PROJECT_DEFAULTS, PROJECT_TERMS } from './terms/ProjectTerms';
import Term from './terms/Term';
import TermInput from './terms/TermInput';
import ProjectPoliciesSelector from './ProjectPoliciesSelector';
import TamrIcon from '../components/TamrIcon';
import style from '../pregroup/PregroupSummary.module.scss';
import TooltipTrigger from '../components/TooltipTrigger';

export const SETTINGS = 'Settings';
export const POLICIES = 'Policies';

const EditProjectDialog = _.compose(
  connect((state) => {
    const { projects: { editingProjectId }, jobs: { sparkConfigNames }, accessControl: { policyDocs } } = state;
    const authorizedUser = getAuthorizedUser(state);
    const projectInfo = editingProjectId !== undefined ? selectProjectInfoByProjectId(state, editingProjectId) : null;
    const editingProjectDoc = getPath(projectInfo, 'projectDoc');
    const projectType = getPath(projectInfo, 'projectType');
    let dedupRecipeDoc = null;
    if (projectType === SUPPLIER_MASTERING) {
      dedupRecipeDoc = projectInfo.dedupRecipeDoc;
    }

    return { editingProjectDoc, editingProjectId, projectType, sparkConfigNames, moduleId: projectInfo?.moduleId, policyDocs, authorizedUser, dedupRecipeDoc };
  }, {
    onCancelEditingProject: () => ({ type: CANCEL_EDITING_PROJECT }),
    onCancelEditingPolicies: () => ({ type: 'Projects.stopEditingPolicies' }),
    onUpdate: updateProject,
    onUpdateRecipe: updateRecipe,
    onUpdatePolicies: updateProjectPolicies,
    onUpdateGR: saveGoldenRecordSimple,
  }),
)(class EditProjectDialog extends React.Component {
  static propTypes = {
    authorizedUser: PropTypes.instanceOf(AuthUser | MinimalAuthUser),
    dedupRecipeDoc: Document.propType.withDataType(Recipe),
    editingProjectDoc: Document.propType.withDataType(Project),
    editingProjectId: PropTypes.number,
    onCancelEditingPolicies: PropTypes.func.isRequired,
    onCancelEditingProject: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    projectType: ProjectTypes.propType,
    sparkConfigNames: ImmutablePropTypes.listOf(PropTypes.string).isRequired,
  };

  state = {
    description: undefined,
    displayName: undefined,
    maximumLearnedPairs: 0,
    enableTransformations: false,
    terms: undefined,
    currentSparkConfigName: undefined,
    tab: SETTINGS,
    query: '',
  };


  getTermDefaults = () => {
    return PROJECT_TERMS[this.props.projectType] || [];
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { editingProjectDoc, projectType, dedupRecipeDoc } = nextProps;
    if (editingProjectDoc && !this.props.editingProjectDoc) {
      let terms = getPath(editingProjectDoc, 'data', 'metadata', 'terms');
      if (terms) {
        terms = _.mapObject(terms, Term.fromJSON);
      } else {
        terms = Object.assign({}, PROJECT_DEFAULTS[projectType]);
      }
      this.setState({
        description: editingProjectDoc.data.description,
        displayName: editingProjectDoc.data.displayName,
        tab: SETTINGS,
        terms,
        maximumLearnedPairs: getPath(dedupRecipeDoc, 'data', 'metadata', 'DEDUP', 'maxInferredPairsPerCluster') || 0,
      });
    }
  }

  commit = () => {
    const { editingProjectDoc, dedupRecipeDoc, onUpdate, onUpdatePolicies, onUpdateGR, onUpdateRecipe, projectType, moduleId } = this.props;
    const { description, displayName, terms, maximumLearnedPairs, enableTransformations, currentSparkConfigName } = this.state;
    if (projectType === GOLDEN_RECORDS) {
      return onUpdateGR({ displayName, description, moduleId });
    }

    if (projectType === SUPPLIER_MASTERING) {
      const updatedRecipeDoc = dedupRecipeDoc
        .setIn(['data', 'metadata', 'DEDUP', 'maxInferredPairsPerCluster'], parseInt(maximumLearnedPairs, 10));

      onUpdateRecipe({ recipeId: updatedRecipeDoc.id.id, version: updatedRecipeDoc.lastModified.version, recipe: updatedRecipeDoc.data });
    }

    const updatedProjectDoc = editingProjectDoc
      .setIn(['data', 'description'], description)
      .setIn(['data', 'displayName'], displayName)
      .setIn(['data', 'metadata', 'terms'], terms)
      .setIn(['data', 'projectSparkConfigOverrides'], currentSparkConfigName)
      .updateIn(['data', 'metadata'], m => (enableTransformations && !m.get(ENABLE_TRANSFORMATIONS_METADATA_KEY) ? m.set(ENABLE_TRANSFORMATIONS_METADATA_KEY, true) : m));
    onUpdate(updatedProjectDoc);
    onUpdatePolicies();
    this.onCancel();
  };

  onCancel = () => {
    const { onCancelEditingProject, onCancelEditingPolicies } = this.props;
    onCancelEditingProject();
    onCancelEditingPolicies();
  };

  onSearch = (query) => {
    this.setState({ query });
  };

  onDescriptionChange = (description) => {
    this.setState({ description });
  };

  onTabChange = (tab) => {
    this.setState({ tab });
  };

  onTermChange = (key, term) => {
    const { terms } = this.state;
    terms[key] = term;
    this.setState({ terms });
  };

  onSetCurrentSparkConfigName = (currentSparkConfigName) => {
    this.setState({ currentSparkConfigName });
  };

  renderDescriptionInput = () => {
    return (
      <Input
        onChange={this.onDescriptionChange}
        type="text"
        title="Description"
        value={this.state.description}
    />
    );
  };

  renderTerminologyConfig = () => {
    return (
      <div className="terminology-config">
        {this.getTermDefaults().map(this.renderTerm)}
      </div>
    );
  };

  renderTerm = (projectTerm) => {
    const defaultTerm = this.state.terms[projectTerm.key];
    let alternateTitle;
    if (projectTerm.key === 'currencyChar') {
      alternateTitle = 'currency symbol';
    } else if (projectTerm.key === 'spend') {
      alternateTitle = 'analysis column';
    }
    return (
      <div key={projectTerm.key} className="term-input-container">
        <div>{projectTerm.desc}</div>
        <TermInput
          defaultTerm={defaultTerm}
          onChange={_.partial(this.onTermChange, projectTerm.key)}
          autoUpdatePlural={false}
          hasPlural={projectTerm.hasPlural}
          alternateTitle={alternateTitle}
    />
      </div>
    );
  };

  render() {
    const { editingProjectDoc, editingProjectId, projectType, sparkConfigNames, moduleId, authorizedUser, dedupRecipeDoc } = this.props;
    const { displayName, maximumLearnedPairs, enableTransformations, currentSparkConfigName, query, tab, validSave } = this.state;
    const containerCss = classNames('edit-project-dialog', {
      categorization: projectType === CLASSIFICATION,
      dedup: projectType === SUPPLIER_MASTERING,
      'schema-mapping': projectType === SCHEMA_MAPPING_RECOMMENDATIONS,
      'golden-records': projectType === GOLDEN_RECORDS,
      enrichment: projectType === ENRICHMENT,
    });
    const show = !!editingProjectDoc;
    let hasMaxPairs = null;
    if (projectType === SUPPLIER_MASTERING) {
      hasMaxPairs = dedupRecipeDoc.data.metadata.get('DEDUP') === undefined;
    }
    const clustersDoc = <a href={'https://docs.tamr.com/new/docs/curating-and-reviewing-record-clusters#recommended-setting-for-maximum-inferred-pairs'}>Learn more</a>;

    return (
      <Dialog
        className={containerCss}
        show={show}
        onHide={this.onCancel}
        header={show ? (
          <div className="edit-project-dialog-header">
            Edit <span className="name">{editingProjectDoc.data.displayName}</span><span className="project-id">{moduleId !== undefined ? `Module ID: ${moduleId}` : `Project ID: ${editingProjectId}`}</span>
          </div>
        ) : null}
        body={show ? (
          <div>
            <div className="editProjectTabs">
              <div className={classNames('page-header-tab', { active: tab === SETTINGS })} onClick={() => this.onTabChange(SETTINGS)}>
                Settings
              </div>
              { isAuthorByProjectId(authorizedUser, editingProjectId) ?
                (<div className={classNames('page-header-tab', { active: tab === POLICIES })} onClick={() => this.onTabChange(POLICIES)}>
                  Permissions
                </div>
                ) : null}
            </div>
            {
              /* a switch statement of what to render based on the selected tab */
              {
                [SETTINGS]: (
                  <div className="edit-project">
                    <Input
                      title="Project name"
                      type="text"
                      onChange={newName => this.setState({ displayName: newName, validSave: (newName === '') })}
                      value={displayName}
                    />
                    {this.renderDescriptionInput()}
                    {projectType !== GOLDEN_RECORDS && this.renderTerminologyConfig()}
                    <div className="tamr-dropdown-container">
                      What Spark configuration should jobs in this project run with?
                      <div className="tamr-dropdown">
                        <Selector
                          /*
                           * We know for sure that there's always at least one element in this list,
                           * and the first one is the name of the standard Spark config
                           */
                          onChange={this.onSetCurrentSparkConfigName}
                          values={sparkConfigNames.map(value => ({ display: value, value })).toArray()}
                          value={currentSparkConfigName || editingProjectDoc.data.projectSparkConfigOverrides || sparkConfigNames.first()}
                        />
                      </div>
                    </div>
                    {projectType === SUPPLIER_MASTERING ? (
                      <div>
                        <div className="tamr-dropdown-container">
                          What is the maximum amount of Learned Pairs that should be generated per cluster?
                          <TooltipTrigger
                            delayHide={2000}
                            content={<span>This value limits the number of learned pairs Tamr Core can label per cluster. Tamr recommends setting this field to 10.<br />{clustersDoc}</span>}
                            placement="bottom"
                          >
                            <TamrIcon className={style.tooltipIcon} iconName="info-outline" size={14} />
                          </TooltipTrigger>
                          <div className={hasMaxPairs ? 'maxPairsDisabled' : null}>
                            <Input
                              onChange={(newMax) => {
                                if (newMax > 999) { return false; }
                                this.setState({ maximumLearnedPairs: newMax.replace(/^0+/, 0), validSave: (newMax === '') });
                              }}
                              type="number"
                              required
                              title="Maximum Value"
                              value={maximumLearnedPairs}
                              min={0}
                              max={999}
                              onKeyDown={(e) => {
                                if (e.key === '-') {
                                  e.preventDefault();
                                }
                              }}
                              disabled={hasMaxPairs}
                            />
                          </div>
                          {hasMaxPairs ? <span className="warning">The maximum value for Learned Pairs per cluster cannot be set until “Update Unified Dataset” has been run for the first time.</span> : ''}
                        </div>
                      </div>
                    ) : null}
                    {projectType === CLASSIFICATION || projectType === SUPPLIER_MASTERING ? (
                      <EnableTransformations
                        enabled={enableTransformations}
                        alreadyEnabled={editingProjectDoc.data.metadata.get(ENABLE_TRANSFORMATIONS_METADATA_KEY)}
                        onChange={() => this.setState({ enableTransformations: !enableTransformations })}
                      />
                    ) : null}
                  </div>
                ),
                [POLICIES]: (
                  isAuthorByProjectId(authorizedUser, editingProjectId) ?
                    (<div>
                      <SearchBox value={query} onSearch={this.onSearch} className="searchBox" />
                      <ProjectPoliciesSelector projectName={displayName} projectId={editingProjectId} query={query} />
                    </div>) : null
                ),
              }[tab]
            }
          </div>
        ) : null}
        footer={show ? (
          <ButtonToolbar>
            {(tab === POLICIES && isAdmin(authorizedUser)) && <Link className="policyLink" key="policies" to="/access/policies">Manage policies from Permissions page </Link>}
            <Button
              buttonType="Secondary"
              onClick={this.onCancel}>Cancel</Button>
            <Button buttonType="Primary" onClick={this.commit} disabled={validSave}>Save</Button>
          </ButtonToolbar>
        ) : null}
        dialogStyle={DialogStyle.PRIMARY}
      />
    );
  }
});

export default EditProjectDialog;
