import { List, Map, Set } from 'immutable';

import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import { StoreReducers } from '../stores/AppAction';
import { RecordType, RecordTypeArgType } from '../transforms/models/Types';
import { ArgTypes } from '../utils/ArgValidation';
import { CANCEL_CONFIGURING_DATASET, CANCEL_CONFIRM_EXPORT, CANCEL_CONFIRM_PROFILE, CLEAR_DATASET_SAMPLE, COMMIT_CONFIGURE_DATASET, COMMIT_CONFIGURE_DATASETS_COMPLETED, CONFIGURE_DATASET, CONFIRM_EXPORT, CONFIRM_OVERWRITE_EXPORT, CONFIRM_PROFILE, FETCH_DATASET_SAMPLE, FETCH_DATASET_SAMPLE_COMPLETED, HIDE_EXPORT_FAILURE_DIALOG, SET_DATASET_SAMPLE_COLUMN_WIDTH, SHOW_EXPORT_FAILURE_DIALOG, SUBMIT_EXPORT, SUBMIT_EXPORT_COMPLETED, SUBMIT_PROFILE, SUBMIT_PROFILE_COMPLETED } from './SharedDatasetCatalogActionTypes';


export const getDatasetSampleColumns = ({ datasetSampleSchema }: { datasetSampleSchema: RecordType | undefined }): List<string> => {
  if (!datasetSampleSchema) return List();
  return datasetSampleSchema.fields.map(f => f.name);
};

class SharedDatasetCatalogStore extends getModelHelpers({
  confirmingExportForDatasets: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: Set() },
  confirmingOverwriteExportForDatasets: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: Set() },
  submittingExport: { type: ArgTypes.bool, defaultValue: false },
  confirmingProfileForDatasets: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: Set() },
  submittingProfile: { type: ArgTypes.bool, defaultValue: false },
  configuringDataset: { type: ArgTypes.nullable(ArgTypes.string) },
  committingConfigureDataset: { type: ArgTypes.bool, defaultValue: false },
  showExportFailureDialogForMessage: { type: ArgTypes.nullable(ArgTypes.string) },
  datasetSample: { type: ArgTypes.nullable(ArgTypes.string) },
  datasetSampleRows: { type: ArgTypes.Immutable.list.of(ArgTypes.object), defaultValue: List() },
  datasetSampleSchema: { type: ArgTypes.orUndefined(RecordTypeArgType) },
  datasetSampleColumnWidths: { type: ArgTypes.Immutable.map.of(ArgTypes.number, ArgTypes.string), defaultValue: Map<string, number>() },
}, 'SharedDatasetCatalogStore')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class SharedDatasetCatalogStoreRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

export const initialState = new SharedDatasetCatalogStore({});

export const reducers: StoreReducers<SharedDatasetCatalogStore> = {
  [CONFIRM_EXPORT]: (state, { datasetNames }) => {
    return state.set('confirmingExportForDatasets', datasetNames);
  },
  [CONFIRM_OVERWRITE_EXPORT]: (state, { datasetNames }) => {
    return state.set('confirmingOverwriteExportForDatasets', datasetNames);
  },
  [CANCEL_CONFIRM_EXPORT]: (state) => {
    return state
      .delete('confirmingExportForDatasets')
      .delete('confirmingOverwriteExportForDatasets');
  },
  [SUBMIT_EXPORT]: (state) => {
    return state.set('submittingExport', true);
  },
  [SUBMIT_EXPORT_COMPLETED]: (state) => {
    return state
      .delete('confirmingExportForDatasets')
      .delete('confirmingOverwriteExportForDatasets')
      .delete('submittingExport');
  },
  [CONFIRM_PROFILE]: (state, { datasetNames }) => {
    return state.set('confirmingProfileForDatasets', datasetNames);
  },
  [CANCEL_CONFIRM_PROFILE]: (state) => {
    return state.delete('confirmingProfileForDatasets');
  },
  [SUBMIT_PROFILE]: (state) => {
    return state.set('submittingProfile', true)
      .delete('confirmingProfileForDatasets');
  },
  [SUBMIT_PROFILE_COMPLETED]: (state) => {
    return state.delete('submittingProfile');
  },
  [CONFIGURE_DATASET]: (state, { datasetName }) => {
    return state.set('configuringDataset', datasetName);
  },
  [CANCEL_CONFIGURING_DATASET]: (state) => {
    return state.delete('configuringDataset');
  },
  [COMMIT_CONFIGURE_DATASET]: (state) => {
    return state.set('committingConfigureDataset', true);
  },
  [COMMIT_CONFIGURE_DATASETS_COMPLETED]: (state) => {
    return state.delete('configuringDataset').delete('committingConfigureDataset');
  },
  [SHOW_EXPORT_FAILURE_DIALOG]: (state, { errorMessage }) => {
    return state.set('showExportFailureDialogForMessage', errorMessage);
  },
  [HIDE_EXPORT_FAILURE_DIALOG]: (state) => {
    return state.delete('showExportFailureDialogForMessage');
  },
  [FETCH_DATASET_SAMPLE]: (state, { datasetName }) => {
    return state.set('datasetSample', datasetName);
  },
  [FETCH_DATASET_SAMPLE_COMPLETED]: (state, { rows, schema }) => {
    return state
      .set('datasetSampleRows', rows)
      .set('datasetSampleSchema', schema)
      .set('datasetSampleColumnWidths',
        getDatasetSampleColumns({ datasetSampleSchema: schema }).toMap().flip().map(() => 100));
  },
  [CLEAR_DATASET_SAMPLE]: (state) => {
    return state
      .delete('datasetSample')
      .delete('datasetSampleRows')
      .delete('datasetSampleSchema')
      .delete('datasetSampleColumnWidths');
  },
  [SET_DATASET_SAMPLE_COLUMN_WIDTH]: (state, { column, width }) => {
    return state.setIn(['datasetSampleColumnWidths', column], width);
  },
};
