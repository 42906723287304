import { List, Map, Set } from 'immutable';
import $ from 'jquery';
import uri from 'urijs';

import ServiceProxy from '../api/ServiceProxy';
import { doFetchProfiling } from '../api/TransformClient';
import { PROFILE_JOB_REQUEST_COMPLETE, PROFILE_JOB_REQUESTED } from '../chrome/ChromeActionTypes';
import { SHOW } from '../errorDialog/ErrorDialogActionTypes';
import { JOB_UPDATE } from '../job/JobsActionTypes';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import { Query } from '../models/doc/Query';
import Export from '../models/Export';
import Job from '../models/Job';
import ProfilingInfo from '../models/ProfilingInfo';
import { AppThunkAction } from '../stores/AppAction';
import { AppDispatch } from '../stores/MainStore';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { PROFILE_DATASET_COMPLETED } from './DatasetsActionTypes';

/**
 * samgqroberts 2020-03-29
 * This file has been Typescriptified, but there are still TODOs to move all async calls to
 * Client files, and to replace Ajax usage with fetch().
 */

export const doUpdateDataset = (dataset: Document<Dataset>) => {
  checkArg({ dataset }, Document.argTypeWithNestedClass(Dataset));
  const datasetSpec = dataset.data.asSpec;
  return $.ajax({
    url: uri(ServiceProxy.dataset(`/datasets/${datasetSpec.name}`))
      .query(dataset.lastModified.asQueryParams()).toString(),
    method: 'PUT',
    cache: false,
    context: this,
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    data: JSON.stringify(datasetSpec),
  });
};

export const doTruncateDataset = (datasetName: string) => {
  checkArg({ datasetName }, ArgTypes.string);
  return $.ajax({
    url: uri(ServiceProxy.dataset(`/datasets/${datasetName}/truncate`)).toString(),
    method: 'POST',
    cache: false,
    contentType: 'application/json',
    data: JSON.stringify({}),
  });
};

export const doProfileDataset = (datasetName: string, dispatch: AppDispatch) => {
  checkArg({ datasetName }, ArgTypes.string);
  dispatch({ type: PROFILE_JOB_REQUESTED, datasetName });
  return $.ajax({
    url: uri(ServiceProxy.transform(`/profile/${datasetName}`)).toString(),
    method: 'POST',
    data: JSON.stringify({}),
    contentType: 'application/json',
  }).always(() => {
    dispatch({ type: PROFILE_JOB_REQUEST_COMPLETE, datasetName });
  }).then(result => {
    dispatch({ type: JOB_UPDATE, job: Document.fromJSON(result, Job.fromJSON) });
    return result;
  });
};

export const profileDataset = (datasetName: string): AppThunkAction<void> => (dispatch) => {
  return doProfileDataset(datasetName, dispatch).then(() => {
    dispatch({ type: PROFILE_DATASET_COMPLETED });
  }).fail((response) => {
    dispatch({ type: SHOW, detail: 'Error profiling dataset', response });
  });
};

export const doFetchDatasetById = (datasetId: number): JQueryDeferred<Document<Dataset>> => {
  checkArg({ datasetId }, ArgTypes.number);
  const returnPromise = $.Deferred();
  $.ajax({
    url: uri(ServiceProxy.dataset(`/datasets/${datasetId}`)).toString(),
    method: 'GET',
    success: (docJSON) => {
      returnPromise.resolve(Document.fromJSON(docJSON, Dataset.fromJSON));
    },
    error: returnPromise.reject,
  });
  return returnPromise;
};

export const doFetchDatasetsWithQuery = (query: Query): JQueryDeferred<{ datasets: List<Document<Dataset>>, numDatasets: number }> => {
  const returnPromise = $.Deferred();
  $.ajax({
    url: uri(ServiceProxy.dataset('/datasets/query')).toString(),
    dataType: 'json',
    method: 'POST',
    context: this,
    data: JSON.stringify(query),
    contentType: 'application/json',
    success: ({ items, total }) => {
      const datasets = List(items).map(d => Document.fromJSON(d, Dataset.fromJSON));
      returnPromise.resolve({ datasets, numDatasets: total });
    },
    error: returnPromise.reject,
  });
  return returnPromise;
};

export const doFetchExports = (datasetNames: Set<string>): JQueryDeferred<Map<number, Document<Export>>> => {
  checkArg({ datasetNames }, ArgTypes.Immutable.set.of(ArgTypes.string));
  const returnPromise = $.Deferred();
  if (datasetNames.isEmpty()) {
    returnPromise.resolve(Map());
    return returnPromise;
  }
  $.ajax({
    url: uri(ServiceProxy.procure('/export/query')).toString(),
    method: 'POST',
    data: JSON.stringify(datasetNames),
    contentType: 'application/json',
    context: this,
    success: (items) => {
      returnPromise.resolve(Map(items.map((e: $TSFixMe) => [e.data.datasetId, Document.fromJSON(e, Export.fromJSON)])));
    },
    error: returnPromise.reject,
  });
  return returnPromise;
};

export const doFetchProfilingAndExports = (datasetNames: Set<string>): JQueryDeferred<{ profiling: Map<string, ProfilingInfo>, exporting: Map<number, Document<Export>> }> => {
  checkArg({ datasetNames }, ArgTypes.Immutable.set.of(ArgTypes.string));
  const returnPromise = $.Deferred();
  $.when(doFetchProfiling(datasetNames), doFetchExports(datasetNames)).then((profiling, exporting) => {
    returnPromise.resolve({ profiling, exporting });
  }).fail(returnPromise.reject);
  return returnPromise;
};

export const doFetchDatasetsProfilingAndExportsWithQuery = (query: Query): JQueryDeferred<{ datasets: List<Document<Dataset>>, numDatasets: number, profiling: Map<string, ProfilingInfo>, exporting: Map<number, Document<Export>> }> => {
  const returnPromise = $.Deferred();
  doFetchDatasetsWithQuery(query).then(({ datasets, numDatasets }) => {
    const datasetNames = datasets.map(({ data: { name } }) => name).toSet();
    doFetchProfilingAndExports(datasetNames).then(({ profiling, exporting }) => {
      returnPromise.resolve({ datasets, numDatasets, profiling, exporting });
    }).fail(returnPromise.reject);
  }).fail(returnPromise.reject);
  return returnPromise;
};
