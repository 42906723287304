import { is, List, Map, OrderedMap, Set } from 'immutable';
import { createSelector } from 'reselect';

import { ORIGIN_ENTITY_ID, ORIGIN_SOURCE_NAME, TAMR_ID } from '../constants/ElasticConstants';
import { CREATE, DELETE, REORDER, UPDATE } from '../constants/LedgerAction';
import Signature from '../functionDocs/models/Signature';
import BulkTransform from '../models/BulkTransform';
import Model from '../models/Model';
import Transforms, { Script, supportedTransformArgType, supportedTransformClassNameArgType } from '../models/Transforms';
import UnifiedAttribute from '../models/UnifiedAttribute';
import { selectStagedUnifiedDatasetColumns } from '../records/RecordsColumns';
import RequiredAttributeType from '../schema-mapping/constants/RequiredAttributeType';
import { ArgTypes, checkArg, checkReturn } from '../utils/ArgValidation';
import { createAppStateSelector, createTypedSelector } from '../utils/Selectors';
import { getPath } from '../utils/Values';
import Lint from './models/Lint';
import { AnalysisInput, OperationListResult } from './models/StaticAnalysisModels';
import StoredTransformsState from './models/StoredTransformsState';
import TransformDelta from './models/TransformDelta';
import { doubleType, RecordType, stringType } from './models/Types';
import ValidationError from './ValidationError';

export const DISABLE_ALL_TRANSFORMS = 'disable-all-dummy-guid';
export const ENABLE_ALL_TRANSFORMS = 'enable-all-dummy-guid';


/**
 * Selectors
 */

export const selectFunctionNames = createSelector(
  ({ functionDocs }) => functionDocs,
  functionDocs => functionDocs.flatMap(signature => {
    const funcNames = signature.names.isEmpty() ? [signature.name] : signature.names;
    return funcNames.map(name => name.toLowerCase());
  }),
);

export const selectFunctionTooltipMap = createSelector(
  ({ functionDocs }) => functionDocs,
  functionDocs => {
    const tooltipMap = functionDocs.flatMap(signature => {
      const funcNames = signature.names.isEmpty() ? [signature.name] : signature.names.toArray();
      const args = signature.args.map(arg =>
        arg.type.toString() + ' ' + arg.name,
      ).join(', ');
      const varargs = !signature.varargs ? ''
        : signature.varargs.type.toString() + ' ' + signature.varargs.name + '0, ' +
        signature.varargs.type.toString() + ' ' + signature.varargs.name + '1...';
      return funcNames.map(funcName => [funcName, `${funcName}(${args}${varargs}) → ${signature.output.toString()}`]);
    });
    return new Map(tooltipMap);
  },
);

// pure function to put together the inferred unified dataset schema from a list of unified attributes
export const inferUnifiedDatasetSchema = checkReturn(ArgTypes.instanceOf(RecordType), (allUnifiedAttributes) => {
  checkArg({ allUnifiedAttributes }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(UnifiedAttribute)));
  const spendFieldName = getPath(allUnifiedAttributes.find(ua => ua.requiredAttributeType === RequiredAttributeType.SPEND), 'name');
  const fields = Map(allUnifiedAttributes.map(ua =>
    [ua.name, ua.name === spendFieldName ? doubleType() : ua.type],
  )).set(ORIGIN_SOURCE_NAME, stringType())
    .set(TAMR_ID, stringType())
    .set(ORIGIN_ENTITY_ID, stringType());
  return new RecordType({
    fullySpecified: true,
    fields: fields.map((type, name) => type.as(name)).toList(),
  });
});

export const isEditable = operation => {
  checkArg({ operation }, Transforms.argType);
  switch (operation.className) {
    case 'Script': return true;
    case 'MultiFormula': return true;
    case 'Formula': return true;
    default: return false;
  }
};

export const initialState = new (class TransformsStore extends Model({
  transforms: {
    type: ArgTypes.Immutable.orderedMap.of(BulkTransform.argType, ArgTypes.string /* guid */),
    defaultValue: OrderedMap(),
  },
  deltas: {
    type: ArgTypes.Immutable.list.of(TransformDelta.argType),
    defaultValue: List(),
  },
  selected: {
    type: ArgTypes.nullable(ArgTypes.string), // guid
    defaultValue: null,
  },
  dragging: {
    type: ArgTypes.nullable(ArgTypes.string), // guid
    defaultValue: null,
  },
  previewCutoff: {
    type: ArgTypes.nullable(ArgTypes.string), // guid
    defaultValue: null,
  },
  previewCutoffHover: {
    type: ArgTypes.nullable(ArgTypes.string), // guid
    defaultValue: null,
  },
  errors: {
    type: ArgTypes.Immutable.map.of(ArgTypes.Immutable.list.of(Lint.argType), ArgTypes.string),
    defaultValue: Map(),
  },
  loadingStaticAnalysis: { type: ArgTypes.bool, defaultValue: false },
  loadedStaticAnalysisInfo: { type: ArgTypes.any, defaultValue: null },
  staticAnalysisResult: { type: ArgTypes.orUndefined(ArgTypes.instanceOf(OperationListResult)) },
  showingBigEditor: {
    type: ArgTypes.bool,
    defaultValue: false,
  },
  loading: {
    type: ArgTypes.bool,
    defaultValue: false,
  },
  loadedDatasetId: {
    type: ArgTypes.nullable(ArgTypes.number),
    defaultValue: null,
  },
  hasNotBeenClosedYet: { // for tracking transforms that have been created without having been deselected yet.
    type: ArgTypes.orUndefined(ArgTypes.string), // guid
  },
  hasNotBeenTypedIntoYet: { // for tracking transforms with textareas that have not been touched yet (nor has the transform been closed)
    type: ArgTypes.orUndefined(ArgTypes.string), // guid
  },
  confirmingDeleteTransform: {
    type: ArgTypes.orUndefined(ArgTypes.string), // guid
  },
  datasetSelectorDialogVisible: { type: ArgTypes.bool, defaultValue: false },
  unifiedDatasetChecked: { type: ArgTypes.bool, defaultValue: false },
  showingRebaseConflictDialog: { type: ArgTypes.bool, defaultValue: false },
  updateListConflictMessage: { type: ArgTypes.orUndefined(ArgTypes.string) },
  functionDocs: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(Signature)), defaultValue: List() },
  loadingFunctionNames: { type: ArgTypes.bool, defaultValue: false },
  loadedFunctionNames: { type: ArgTypes.bool, defaultValue: false },
  hintNames: { type: ArgTypes.Immutable.list.of(ArgTypes.string), defaultValue: List() },
  loadingHintNames: { type: ArgTypes.bool, defaultValue: false },
  loadedHintNames: { type: ArgTypes.bool, defaultValue: false },
  unifiedTransformsReferencedDatasetTypes: { type: ArgTypes.Immutable.map.of(ArgTypes.instanceOf(RecordType), ArgTypes.string), defaultValue: Map() },
  unifiedTransformsReferencedDatasetKeys: { type: ArgTypes.Immutable.map.of(ArgTypes.Immutable.list.of(ArgTypes.string), ArgTypes.string), defaultValue: Map() },
  loadedUnifiedTransformsReferencedDatasetNames: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: Set() },
  loadingFromStorage: { type: ArgTypes.bool, defaultValue: false },
}) {
  /**
   * Appends a new delta to its internal set of deltas, returning a new version of itself.
   * @param newDelta the new delta to append
   */
  appendDelta(newDelta) {
    let deltas = this.deltas;
    const lastDelta = deltas.last();
    if (lastDelta && lastDelta.guid === newDelta.guid && lastDelta.action === newDelta.action && newDelta.action === UPDATE) {
      // Optimization: if the last delta is an UPDATE on the same guid, just replace it.
      return this.setIn(['deltas', deltas.size - 1], newDelta);
    }

    if (newDelta.action === UPDATE) {
      // Delete the previous UPDATE delta
      deltas = deltas.filter(delta => delta.guid !== newDelta.guid || delta.action !== UPDATE);
    }
    return this.set('deltas', deltas.push(newDelta));
  }
})();
const createTransformsSelector = (...args) => createTypedSelector(ArgTypes.className('TransformsStore'), ...args);

/**
 * This function counts the number of 'drafts', which is roughly the number of user-visible
 * changes that should be displayed as a badge on the 'Save Changes' button.
 *
 * The rules are fairly simple:
 *
 * 1. Transformations are identified by their `guid`
 *
 * 2. The draft counts are tracked separately for each Transformation, and are initially zero
 *    for all Transformations
 *
 * 3. There are four legal actions for a Delta: CREATE, UPDATE, REORDER, DELETE.  The action
 *    of the first Delta for a transformation sets the draft count for that transformation to 1
 *
 * 4. Any subsequent action for the same Transformation leaves the draft-count unchanged,
 *    _unless_ one of the following conditions holds:
 *    A. The action is a DELETE of a transformation previously CREATEd in the same delta list
 *    B. The action is an UPDATE of a transformation, whose payload is identical to either the
 *       'oldPayload' (if the Transformation's first delta was an UPDATE) or the 'payload'
 *       (if the Transformation's first delta was a CREATE or REORDER) of the Transformation's
 *       first delta
 *    C. The action is a REORDER whose 'location' field is identical to the 'oldLocation'
 *       field of the Transformation's first delta (if that delta is itself a REORDER) or to
 *       the 'location' field of the Transformation's first delta (if that delta is anything
 *       other than a REORDER)
 *   Under any of these three conditions, the draft count of the Transformation is set _back_ to
 *   zero.
 *
 * 5. After all deltas have been scanned, the draft count for all Transformations is summed
 *    and returned.
 *
 * @param deltas A collection of Delta models, in order, to be applied
 * @returns {integer} the number of 'drafts' to be displayed as a badge
 */
export const selectDraftCount = createTypedSelector(
  initialState.objectArgTypeWithFields('deltas'),
  ArgTypes.number,
  state => state.deltas,
  (deltas) => {
    // Possible actions: CREATE, UPDATE, REORDER, DELETE
    // This map specifies the conditions under which a 'non-initial' delta,
    // (that is, a delta that is _not_ the first delta for that guid)
    // contributes a draft to the overall draft count.
    const draftConditions = Map({
      CREATE: () => false,
      UPDATE: (first, latest) => !is(first.oldPayload, latest.payload),
      REORDER: (first, latest) => !is(first.oldLocation, latest.location),
      DELETE: (first) => first.action !== 'CREATE',
    });

    // firstModel tracks, by guid, the _first_ Delta model we see in the list.
    // this helps check when we've moved a transformation back to its
    // "original" state
    //
    // guidChangeCount tracks (by guid) how many 'drafts' each guid is
    // contributing to the overall badge count;
    //   GUID -> Integer
    // (where "GUID" is a String)
    function deltaDraftCounter([firstModel, guidChangeCount], delta) {
      const guid = delta.guid;
      const action = delta.action;

      if (!(guid in firstModel)) {
        // The 'initial delta' case -- for deltas that are the _first_ delta (in order)
        // that we've seen for this guid.
        // The initial delta is _always_ a draft, _unless_ it happens
        // to be an UPDATE delta whose oldPayload and payload fields are identical
        const noopUpdate = action === 'UPDATE' && is(delta.oldPayload, delta.payload);

        firstModel[guid] = delta;
        guidChangeCount[guid] = noopUpdate ? 0 : 1;
      } else {
        // The 'non-initial delta' case -- we've already seen a delta for this guid,
        // and now we use the predicates in the draftPredicate Map to figure out whether
        // this delta should _also_ maintain a draft count of 1 for this guid (or reset
        // it back to 0).
        const draftPredicate = draftConditions.get(action);
        guidChangeCount[guid] = draftPredicate(firstModel[guid], delta) ? 1 : 0;
      }

      return [firstModel, guidChangeCount];
    }

    // The result is the pair -- we need the second element of that pair, but
    // can't use the `const [x, y] = ...` destructuring assignment because
    // the linter yells at us.
    const result = deltas.reduce(deltaDraftCounter, [{}, {}]);
    return Map(result[1]).reduce((acc, value) => acc + value, 0);
  },
);

export const selectOrdering = createTypedSelector(
  initialState.objectArgTypeWithFields('transforms', 'deltas'),
  ArgTypes.Immutable.list.of(ArgTypes.string),
  state => state.transforms,
  state => state.deltas,
  (transforms, deltas) => {
    initialState.checkTypes({ transforms, deltas });
    const preDeltaOrdering = transforms.map((v, guid) => guid).toList();
    return preDeltaOrdering.update(ordering => {
      deltas.forEach(({ action, guid, location }) => {
        const removeGuid = () => {
          ordering = ordering.delete(ordering.indexOf(guid));
        };
        const insertGuidAtLocation = () => {
          if (location.before) {
            ordering = ordering.insert(ordering.findIndex(g => g === location.before) + 1, guid);
          } else {
            ordering = ordering.insert(ordering.findIndex(g => g === location.after), guid);
          }
        };
        switch (action) {
          case UPDATE: // ordering can't change
            return;
          case DELETE: // remove the guid from ordering
            removeGuid();
            return;
          case REORDER: // remove guid's existing ordering spot and insert it back in the right spot
            removeGuid();
            insertGuidAtLocation();
            return;
          case CREATE:
            if (location) {
              insertGuidAtLocation();
            } else { // no location means this is the first transform created, so ordering is just this guid
              ordering = List.of(guid);
            }
        }
      });
      return ordering;
    });
  },
);

export const selectLatestForAllBulkTransforms = createTypedSelector(
  initialState.objectArgTypeWithFields('transforms', 'deltas'),
  ArgTypes.Immutable.map.of(BulkTransform.argType, ArgTypes.string),
  state => state.transforms,
  state => state.deltas,
  selectOrdering,
  (transforms, deltas, allGuids) => {
    return Map(allGuids.map(guid => {
      // get latest delta that has a payload for this guid
      const latestDelta = deltas.filter((delta) => delta.guid === guid && !!delta.payload).last();
      const latest = latestDelta ? latestDelta.payload : transforms.get(guid);
      return [guid, latest];
    }));
  },
);

export const getLatestForBulkTransform = checkReturn(BulkTransform.argType, ({ transforms, deltas }, guid) => {
  initialState.checkTypes({ transforms, deltas });
  checkArg({ guid }, ArgTypes.string);
  return selectLatestForAllBulkTransforms({ transforms, deltas }).get(guid);
});

const BothDelineationGuids = Model({
  source: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
  unified: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
});

const selectBothDelineationGuids = createTypedSelector(
  initialState.objectArgTypeWithFields('transforms', 'deltas', 'loadedDatasetId'),
  ArgTypes.instanceOf(BothDelineationGuids),
  state => state.transforms,
  state => state.deltas,
  state => state.loadedDatasetId,
  (transforms, deltas, loadedDatasetId) => {
    initialState.checkTypes({ deltas, transforms, loadedDatasetId });
    const ordering = selectOrdering({ transforms, deltas });
    const latestForAllBulkTransforms = selectLatestForAllBulkTransforms({ transforms, deltas });
    const source = [];
    const unified = [];
    ordering.forEach(guid => {
      const bulkTransform = latestForAllBulkTransforms.get(guid);
      const isUnifiedDatasetTransform = bulkTransform.datasetIds.size === 1 && bulkTransform.datasetIds.has(loadedDatasetId);
      if (isUnifiedDatasetTransform) {
        unified.push(guid);
      } else {
        source.push(guid);
      }
    });
    return new BothDelineationGuids({ source: List(source), unified: List(unified) });
  },
);

// filters by source level vs. unified
const getDelineatedGuids = checkReturn(ArgTypes.Immutable.list.of(ArgTypes.string), ({ deltas, transforms, loadedDatasetId }, sourceLevel) => {
  checkArg({ sourceLevel }, ArgTypes.bool);
  return getPath(selectBothDelineationGuids({ transforms, deltas, loadedDatasetId }), sourceLevel ? 'source' : 'unified');
});
export const getSourceScopedGuids = checkReturn(ArgTypes.Immutable.list.of(ArgTypes.string), (state) => getDelineatedGuids(state, true));
export const getUnifiedScopedGuids = checkReturn(ArgTypes.Immutable.list.of(ArgTypes.string), (state) => getDelineatedGuids(state, false));

export const getUnifiedTransformOperations = checkReturn(ArgTypes.Immutable.list.of(supportedTransformArgType), (state) => {
  const unifiedScopedGuids = getUnifiedScopedGuids(state);
  return unifiedScopedGuids.map(guid => getLatestForBulkTransform(state, guid).operation);
});

export const getUnifiedOperationList = checkReturn(ArgTypes.Immutable.list.of(ArgTypes.string), (state) => {
  return getUnifiedTransformOperations(state).map(op => op.toTransformText());
});

export const selectLints = createTransformsSelector(
  ArgTypes.Immutable.map.of(ArgTypes.Immutable.list.of(ArgTypes.instanceOf(Lint)), ArgTypes.string /* guid */),
  state => state.errors,
  state => state.staticAnalysisResult,
  selectOrdering,
  getUnifiedScopedGuids,
  (errors, staticAnalysisResult, ordering, unifiedGuids) => {
    const unifiedGuidIndexes = unifiedGuids.toMap().flip();
    return Map(ordering.map(guid => {
      const isSourceScopedTransform = !unifiedGuidIndexes.has(guid);
      if (isSourceScopedTransform) {
        return [guid, errors.get(guid) || List()];
      }
      const analysisOperations = getPath(staticAnalysisResult, 'operations') || List();
      const operationIndex = unifiedGuidIndexes.get(guid);
      return [guid, getPath(analysisOperations.get(operationIndex), 'lints') || List()];
    }));
  },
);

export const selectUnifiedOperationsReferencedDatasets = createTransformsSelector(
  ArgTypes.Immutable.set.of(ArgTypes.string),
  getUnifiedTransformOperations,
  (unifiedTransformOperations) => {
    return Script.fromTransformList(unifiedTransformOperations).referencedDatasets.filter(x => x);
  });

export const transformIsAfterCutoff = (state, guid) => {
  const { previewCutoff, previewCutoffHover } = state;
  const cutoff = previewCutoffHover || previewCutoff;
  if (cutoff === DISABLE_ALL_TRANSFORMS) return true;
  if (cutoff === ENABLE_ALL_TRANSFORMS) return false;
  const ordering = getSourceScopedGuids(state).concat(getUnifiedScopedGuids(state));
  return cutoff && ordering.indexOf(guid) > ordering.indexOf(cutoff);
};

export const selectOrderedGuidsBeforePreviewCutoff = createTransformsSelector(
  ArgTypes.Immutable.list.of(ArgTypes.string),
  state => state.transforms,
  state => state.previewCutoff,
  state => state.deltas,
  getSourceScopedGuids,
  getUnifiedScopedGuids,
  (transforms, previewCutoff, deltas, sourceScopedGuids, unifiedScopedGuids) => {
    if (previewCutoff === DISABLE_ALL_TRANSFORMS) return new List();
    let ordering = sourceScopedGuids.concat(unifiedScopedGuids);
    if (previewCutoff && (previewCutoff !== ENABLE_ALL_TRANSFORMS)) {
      const cutoffIndex = ordering.indexOf(previewCutoff);
      if (cutoffIndex !== -1) {
        ordering = ordering.slice(0, cutoffIndex + 1);
      }
    }
    return ordering;
  });

export const getTransformList = state => {
  const { transforms, deltas } = state;
  return selectOrderedGuidsBeforePreviewCutoff(state)
    .map(guid => getLatestForBulkTransform({ transforms, deltas }, guid));
};

export const selectUnifiedOperationsBeforePreviewCutoff = createTransformsSelector(
  ArgTypes.Immutable.list.of(ArgTypes.string),
  selectOrderedGuidsBeforePreviewCutoff,
  getUnifiedScopedGuids,
  selectLatestForAllBulkTransforms,
  (orderedGuidsBeforePreviewCutoff, unifiedScopedGuids, latestForAllBulkTransforms) => {
    return unifiedScopedGuids
      .filter(guid => orderedGuidsBeforePreviewCutoff.includes(guid))
      .map(guid => latestForAllBulkTransforms.get(guid).operation.toTransformText());
  });

export const selectValidationErrors = createAppStateSelector(
  ArgTypes.Immutable.map.of(ArgTypes.Immutable.set.of(ArgTypes.string), ArgTypes.string),
  state => state.transforms.transforms,
  state => state.transforms.deltas,
  selectStagedUnifiedDatasetColumns,
  state => selectOrdering(state.transforms),
  (transforms, deltas, stagedUnifiedDatasetColumns, ordering) => {
    const stagedUnifiedDatasetColumnNames = stagedUnifiedDatasetColumns.map(col => col.name).toSet();
    return ordering.toMap().flip().map((unused, guid) => {
      const bulkTransform = getLatestForBulkTransform({ transforms, deltas }, guid);
      const { operation } = bulkTransform;
      const errors = [];
      if (operation.className === 'Fill') {
        if (!operation.column) {
          errors.push(ValidationError.FILL_MISSING_ATTRIBUTE);
        }
        if (!stagedUnifiedDatasetColumnNames.has(operation.column)) {
          errors.push(ValidationError.FILL_INVALID_ATTRIBUTE);
        }
      }
      if (operation.className === 'Formula') {
        if (operation.expr === '') {
          errors.push(ValidationError.FORMULA_EMPTY_EXPRESSION);
        }
        if (!operation.column) {
          errors.push(ValidationError.FORMULA_MISSING_OUTPUT_ATTRIBUTE);
        }
        if (!stagedUnifiedDatasetColumnNames.has(operation.column)) {
          errors.push(ValidationError.FORMULA_INVALID_OUTPUT_ATTRIBUTE);
        }
      }
      if (operation.className === 'MultiFormula') {
        if (operation.expr === '') {
          errors.push(ValidationError.MULTI_FORMULA_EMPTY_EXPRESSION);
        }
        const targetColumns = Set(operation.targetColumns);
        if (targetColumns.isEmpty()) {
          errors.push(ValidationError.MULTI_FORMULA_EMPTY_ATTRIBUTES);
        }
        if (targetColumns.has('$COL')) {
          errors.push(ValidationError.MULTI_FORMULA_INVALID_ATTRIBUTE_NAME);
        }
      }
      if (operation.className === 'Script') {
        if (operation.op === '') {
          errors.push(ValidationError.SCRIPT_EMPTY_VALUE);
        }
      }
      if (operation.className === 'Unpivot') {
        const unpivotColumns = Set(operation.unpivotColumns);
        if (unpivotColumns.isEmpty()) {
          errors.push(ValidationError.UNPIVOT_MISSING_UNPIVOT_ATTRIBUTES);
        }
        if (stagedUnifiedDatasetColumnNames.intersect(unpivotColumns).size < unpivotColumns.size) {
          errors.push(ValidationError.UNPIVOT_INVALID_UNPIVOT_ATTRIBUTES);
        }
        if (!operation.variableColumn) {
          errors.push(ValidationError.UNPIVOT_MISSING_VARIABLE_ATTRIBUTE);
        }
        if (!stagedUnifiedDatasetColumnNames.has(operation.variableColumn)) {
          errors.push(ValidationError.UNPIVOT_INVALID_VARIABLE_ATTRIBUTE);
        }
        if (!operation.valueColumn) {
          errors.push(ValidationError.UNPIVOT_MISSING_VALUE_ATTRIBUTE);
        }
        if (!stagedUnifiedDatasetColumnNames.has(operation.valueColumn)) {
          errors.push(ValidationError.UNPIVOT_INVALID_VALUE_ATTRIBUTE);
        }
        if (stagedUnifiedDatasetColumnNames.intersect(Set(operation.dependentColumns)).size < operation.dependentColumns.length) {
          errors.push(ValidationError.UNPIVOT_INVALID_DEPENDENT_COLUMNS);
        }
      }
      return Set(errors);
    });
  });

export const selectLintErrorsPresent = createTransformsSelector(
  ArgTypes.bool,
  selectLints,
  (lints) => lints.some(lintList => !lintList.isEmpty()),
);

export const selectValidationErrorsPresent = createAppStateSelector(
  ArgTypes.bool,
  selectValidationErrors,
  state => state.transforms.hasNotBeenClosedYet,
  (validationErrors, hasNotBeenClosedYet) => validationErrors.some((errorSet, guid) => guid !== hasNotBeenClosedYet && !errorSet.isEmpty()),
);

export const selectErrorsPresent = createAppStateSelector(
  ArgTypes.bool,
  state => selectLintErrorsPresent(state.transforms),
  selectValidationErrorsPresent,
  (lintErrorsPresent, validationErrorsPresent) => lintErrorsPresent || validationErrorsPresent,
);

export const selectIsErroringNewTransform = createAppStateSelector(
  ArgTypes.bool,
  selectValidationErrors,
  state => state.transforms.hasNotBeenClosedYet,
  (validationErrors, hasNotBeenClosedYet) => !!hasNotBeenClosedYet && validationErrors.some((errorSet, guid) => guid === hasNotBeenClosedYet && !errorSet.isEmpty()),
);

export const selectSavePreconditions = createAppStateSelector(
  ArgTypes.Immutable.map.of(ArgTypes.bool, ArgTypes.string),
  selectErrorsPresent,
  selectIsErroringNewTransform,
  state => state.transforms.deltas.size,
  state => selectDraftCount(state.transforms),
  state => state.transforms.loading,
  (errorsPresent, isErroringNewTransform, numDeltas, numDrafts, loading) => Map()
    .set('Number of drafts can\'t be negative', numDrafts >= 0)
    .set('Can\'t save until loading finishes', !loading)
    .set('No changes to save', loading || numDeltas !== 0)
    .set('Some transformations have errors', !errorsPresent)
    .set('Please complete the open transformation', !isErroringNewTransform),
);

/**
 * Returns the elements before and after an index in a list, or undefined
 * if the neighboring element is out of bounds
 */
export const findBeforeAfter = (list, index) => {
  const before = index - 1 < 0 ? undefined : list.get(index - 1);
  const after = index + 1 >= list.size ? undefined : list.get(index + 1);
  return [before, after];
};

export const shouldShowBigEditor = state => {
  const { showingBigEditor, selected } = state;
  if (!showingBigEditor || !selected) {
    return false;
  }
  const operation = getPath(getLatestForBulkTransform(state, selected), 'operation');
  return operation && isEditable(operation);
};

const collapseEditorIfNecessary = (state) => {
  const { selected, transforms, deltas } = state;
  const showingBigEditor = state.get('showingBigEditor');
  if (showingBigEditor) {
    if (selected) {
      const operation = getLatestForBulkTransform({ transforms, deltas }, selected).operation;
      if (!isEditable(operation)) {
        return state.set('showingBigEditor', false);
      }
    } else { // we shouldn't get in a state where nothing is selected, but editor expanded
      return state.set('showingBigEditor', false);
    }
  }
  return state;
};

const openCreatedTransform = (state, guid) => state.set('hasNotBeenClosedYet', guid).set('hasNotBeenTypedIntoYet', guid);
const closeCreatedTransform = (state) => state.delete('hasNotBeenClosedYet').delete('hasNotBeenTypedIntoYet');
const unsetUnifiedDatasetSelected = (state) => {
  if (state.datasetSelectorDialogVisible) {
    return state.delete('unifiedDatasetChecked');
  }
  return state;
};

const updateBulkTransform = checkReturn(ArgTypes.object.withShape({
  oldPayload: ArgTypes.orUndefined(ArgTypes.instanceOf(BulkTransform)),
  payload: ArgTypes.instanceOf(BulkTransform),
  latestVersion: ArgTypes.instanceOf(BulkTransform),
}), (state, { guid, field, value }) => {
  checkArg({ guid }, ArgTypes.string);
  checkArg({ field }, ArgTypes.valueIn(['operation', 'datasetIds']));
  // last version committed to server, or CREATE delta value if transform is new
  const oldPayload = state.getIn(['transforms', guid]) || state.deltas.filter(delta => delta.guid === guid && delta.action === CREATE).first().payload;
  // latest version before the edit (but not necessarly the oldPayload, as updates may have happened since then)
  const latestVersion = getLatestForBulkTransform({ transforms: state.transforms, deltas: state.deltas }, guid);
  // version with change
  const payload = latestVersion.set(field, value);
  return { oldPayload, payload, latestVersion };
});

const edit = (state, { guid, oldPayload, payload }) => {
  checkArg({ guid }, ArgTypes.string);
  checkArg({ oldPayload }, ArgTypes.instanceOf(BulkTransform));
  checkArg({ payload }, ArgTypes.instanceOf(BulkTransform));
  return state
    .appendDelta(TransformDelta.update({
      guid,
      payload,
      oldPayload,
    }));
};

const editDatasetIds = (state, { guid, datasetIds }) => {
  checkArg({ guid }, ArgTypes.string);
  checkArg({ datasetIds }, ArgTypes.Immutable.set.of(ArgTypes.number));
  const { oldPayload, payload } = updateBulkTransform(state, { guid, field: 'datasetIds', value: datasetIds });
  return edit(state, { guid, oldPayload, payload });
};

export const reducers = {
  'Location.projectChange': (state) => {
    return state.clear();
  },

  'Transforms.select': (state, { guid }) => {
    return state.set('selected', guid).update(closeCreatedTransform).update(collapseEditorIfNecessary);
  },

  'Transforms.deselectAll': (state) => {
    return state.delete('selected').update(closeCreatedTransform).update(collapseEditorIfNecessary);
  },

  'Transforms.beginDrag': (state, { guid }) => {
    return state.set('dragging', guid);
  },

  'Transforms.endDrag': (state) => {
    return state.set('dragging', null);
  },

  'Transforms.lint': (state, { lintingErrors, guid }) => {
    return state.setIn(['errors', guid], lintingErrors);
  },

  'Transforms.setBigEditor': (state, { enabled }) => {
    return state.set('showingBigEditor', enabled);
  },

  'Transforms.editOperation': (state, { guid, operation }) => {
    checkArg({ guid }, ArgTypes.string);
    checkArg({ operation }, supportedTransformArgType);

    const { oldPayload, payload, latestVersion } = updateBulkTransform(state, { guid, field: 'operation', value: operation });

    const transformTypeChanged = latestVersion.operation.className !== payload.operation.className;

    const userHasTypedIntoScriptBox = operation.className === 'Formula' && operation.expr !== ''
      || operation.className === 'MultiFormula' && operation.expr !== ''
               || operation.className === 'Script' && operation.value !== '';

    return state
      .update(s => edit(s, { guid, oldPayload, payload }))
      .update(s => (userHasTypedIntoScriptBox ? s.delete('hasNotBeenTypedIntoYet') : s))
      .update(s => (transformTypeChanged ? openCreatedTransform(s, guid) : s));
  },

  // add a new transform to end of specified delineation, with default content for specified operation class name
  'Transforms.createNew': (state, { operationClassName, sourceLevel, toIndex }) => {
    checkArg({ operationClassName }, supportedTransformClassNameArgType);
    checkArg({ sourceLevel }, ArgTypes.bool);
    checkArg({ toIndex }, ArgTypes.orUndefined(ArgTypes.number));

    const sourceScopedGuids = getSourceScopedGuids(state);
    const unifiedScopedGuids = getUnifiedScopedGuids(state);
    let before = null;
    let after = null;
    if (toIndex !== undefined) {
      const destScopedGuids = sourceLevel ? sourceScopedGuids : unifiedScopedGuids;
      after = destScopedGuids.get(toIndex);
    } else if (!sourceScopedGuids.isEmpty() || !unifiedScopedGuids.isEmpty()) {
      if (sourceLevel) {
        if (sourceScopedGuids.isEmpty()) { // unified guids is non empty
          after = unifiedScopedGuids.first();
        } else {
          before = sourceScopedGuids.last();
        }
      } else { // unified level
        if (unifiedScopedGuids.isEmpty()) { // source guids is non empty
          before = sourceScopedGuids.last();
        } else {
          before = unifiedScopedGuids.last();
        }
      }
    }

    const operation = new Transforms[operationClassName]();
    const datasetIds = sourceLevel ? Set() : Set.of(state.loadedDatasetId);
    const bulkTransforms = new BulkTransform({ operation, datasetIds });
    const delta = TransformDelta.create({ payload: bulkTransforms, before, after });
    return state.appendDelta(delta)
      .set('selected', delta.guid)
      .update(s => openCreatedTransform(s, delta.guid))
      .update(collapseEditorIfNecessary);
  },

  'Transforms.reset': (state, { guid }) => {
    const newDeltas = state.deltas.filter(delta => delta.guid !== guid || delta.action !== UPDATE);
    return state
      .set('showingBigEditor', false)
      .set('deltas', newDeltas) // Set new deltas
      .delete('selected') // deselect
      .deleteIn(['errors', guid]); // and remove any errors, since this was a saved state and those can't have errors
  },

  'Transforms.delete': (state, { guid }) => {
    const latestVersion = getLatestForBulkTransform(state, guid);
    return state
      .appendDelta(TransformDelta.delete({ guid, oldPayload: latestVersion }))
      .set('showingBigEditor', false)
      .deleteIn(['errors', guid])
      .updateIn(['previewCutoff'], cutoff => (guid === cutoff ? null : cutoff))
      .delete('selected') // and deselect
      .delete('confirmingDeleteTransform');
  },

  'Transforms.setPreviewCutoff': (state, { guid }) => {
    return state.set('previewCutoff', guid);
  },

  'Transforms.setPreviewCutoffHover': (state, { guid }) => {
    return state.set('previewCutoffHover', guid);
  },

  /**
   * Moves a given guid to a new position in the list of transformations. Positions
   * surround indexes, e.g. 'pos 0' is before index 0 and 'pos 1' is after index 0.
   * Will rework scoped datasetIds if this reorder moves between delineated lists.
   */
  'Transforms.reorder': (state, { guid, toIndex, sourceLevel }) => {
    checkArg({ guid }, ArgTypes.string);
    checkArg({ toIndex }, ArgTypes.number);
    checkArg({ sourceLevel }, ArgTypes.bool);
    const sourceScopedGuids = getSourceScopedGuids(state);
    const unifiedScopedGuids = getUnifiedScopedGuids(state);
    const destScopedGuids = sourceLevel ? sourceScopedGuids : unifiedScopedGuids;
    const wasSourceLevel = sourceScopedGuids.includes(guid);
    const ordering = selectOrdering(state);
    const currentIndex = ordering.findIndex(g => g === guid);
    const toEnd = toIndex === destScopedGuids.size;

    const updatedOrdering = (destScopedGuids.get(toIndex) === guid) || (toEnd && destScopedGuids.last() === guid)
      ? ordering
      : ordering.remove(currentIndex)
        .update(l => {
          const index = toEnd
            ? (l.findIndex(g => g === destScopedGuids.last()) || destScopedGuids.size) + 1
            : l.findIndex(g => g === destScopedGuids.get(toIndex));
          return l.insert(index, guid);
        });

    // figure out if anything changed
    const delineationChange = sourceLevel !== wasSourceLevel;
    return state
      .set('dragging', null)
      .update(s => {
        if (delineationChange) {
          if (sourceLevel && !wasSourceLevel) {
            return editDatasetIds(s, { guid, datasetIds: Set() });
          }
          if (!sourceLevel && wasSourceLevel) {
            return editDatasetIds(s, { guid, datasetIds: Set.of(s.loadedDatasetId) });
          }
        }
        return s;
      })
      .update(s => {
        const [oldBefore, oldAfter] = findBeforeAfter(ordering, currentIndex);
        const [before, after] = findBeforeAfter(updatedOrdering, updatedOrdering.indexOf(guid));
        const stagedWithOrderingChanges = s.appendDelta(TransformDelta.reorder({ guid, oldBefore, oldAfter, before, after }));
        const noChangesWithinDelineations = getSourceScopedGuids(stagedWithOrderingChanges).equals(sourceScopedGuids) && getUnifiedScopedGuids(stagedWithOrderingChanges).equals(unifiedScopedGuids);
        const orderingChange = !is(selectOrdering(state), updatedOrdering) && !(!delineationChange && noChangesWithinDelineations);
        if (orderingChange) {
          return stagedWithOrderingChanges;
        }
        return s;
      });
  },

  'Transforms.updateList': (state) => {
    return state.set('loading', true);
  },

  'Transforms.updateListCompleted': (state, { loadedDatasetId, transforms, saved }) => {
    initialState.checkTypes({ loadedDatasetId, transforms });
    checkArg({ saved }, ArgTypes.bool);
    return state.withMutations(mutableState => {
      const loadedNewDataset = loadedDatasetId !== mutableState.loadedDatasetId;

      const selected = mutableState.get('selected');
      if (saved && selected && !(transforms.has(selected))) {
        // ordering doesn't include in-flight drafts, and we don't want to de-select drafts on updating list when previewing
        mutableState.delete('selected');
      }

      mutableState.merge({
        loading: false,
        loadedDatasetId,
      });

      mutableState.update(collapseEditorIfNecessary);

      if (saved || loadedNewDataset) {
        mutableState
          .set('transforms', transforms)
          .delete('deltas')
          .delete('errors');
      }
    });
  },

  'Transforms.updateListFailed': (state, { conflictMessage, loadedDatasetId }) => {
    checkArg({ conflictMessage }, ArgTypes.orUndefined(ArgTypes.string));
    return state.merge({
      loading: false,
      updateListConflictMessage: conflictMessage,
      loadedDatasetId,
    });
  },

  'Transforms.loadFromLocalStorage': (state) => {
    return state.set('loading', true);
  },

  'Transforms.loadFromStorageCompleted': (state, { storedState }) => {
    checkArg({ storedState }, StoredTransformsState.argType);
    return state.merge({
      transforms: storedState.transforms,
      deltas: storedState.deltas,
      loadedDatasetId: storedState.unifiedDatasetId,
      loading: false,
    }).delete('loadingFromStorage');
  },

  'Transforms.hideFailedListUpdateDialog': (state) => {
    return state.delete('updateListConflictMessage');
  },

  'Transforms.promptConfirmDeleteTransform': (state, { guid }) => {
    return state.set('confirmingDeleteTransform', guid);
  },

  'Transforms.cancelDeleteTransform': (state) => {
    return state.delete('confirmingDeleteTransform');
  },

  'Transforms.clearDeltas': (state) => {
    return state.delete('deltas')
      // addressing DEV-12124: if the selected transform was newly created, 'selected' may now
      //   point to a nonexistent transform. so we must reset 'selected' in that case.
      .update(s => (selectLatestForAllBulkTransforms(s).has(s.selected) ? s : s.delete('selected')));
  },

  'Transforms.clearStore': () => {
    return initialState;
  },

  'Transforms.openDatasetSelectorDialog': (state) => {
    return state.set('datasetSelectorDialogVisible', true)
      .set('unifiedDatasetChecked', getLatestForBulkTransform(state, state.selected).datasetIds.toSet().has(state.loadedDatasetId));
  },
  'Transforms.closeDatasetSelectorDialog': (state) => {
    return state.set('datasetSelectorDialogVisible', false).delete('unifiedDatasetChecked');
  },
  'Transforms.editDatasetIds': (state, { guid, datasetsToAdd, datasetsToRemove }) => {
    const datasetIdsToAdd = datasetsToAdd.map(d => d.id.id);
    const datasetIdsToRemove = datasetsToRemove.map(d => d.id.id);
    return state.update(s => {
      const unifiedDatasetSelected = getLatestForBulkTransform(state, state.selected).datasetIds.has(state.loadedDatasetId);
      const newDatasetIds = s.unifiedDatasetChecked ? Set.of(s.loadedDatasetId)
        : unifiedDatasetSelected ? datasetIdsToAdd
          : getLatestForBulkTransform(state, guid).datasetIds.union(datasetIdsToAdd).subtract(datasetIdsToRemove);
      return editDatasetIds(s, { guid, datasetIds: newDatasetIds });
    }).delete('datasetSelectorDialogVisible')
      .delete('unifiedDatasetChecked');
  },
  'Transforms.setUnifiedDatasetSelected': (state) => {
    return state.set('unifiedDatasetChecked', true);
  },
  'DatasetFilter.toggleRow': unsetUnifiedDatasetSelected,
  'DatasetFilter.toggleAll': unsetUnifiedDatasetSelected,
  'DatasetFilter.resetStagedSelections': unsetUnifiedDatasetSelected,

  'Transforms.performStaticAnalysis': (state) => {
    return state.set('loadingStaticAnalysis', true);
  },
  'Transforms.performStaticAnalysisCompleted': (state, { staticAnalysisInfo, operationListResult, unifiedTransformsReferencedDatasetNames, analysisInput }) => {
    checkArg({ analysisInput }, ArgTypes.instanceOf(AnalysisInput));
    return state.merge({
      staticAnalysisResult: operationListResult,
      loadedStaticAnalysisInfo: staticAnalysisInfo,
      loadingStaticAnalysis: false,
      loadedUnifiedTransformsReferencedDatasetNames: unifiedTransformsReferencedDatasetNames,
      unifiedTransformsReferencedDatasetTypes: analysisInput.referenceTypes,
      unifiedTransformsReferencedDatasetKeys: analysisInput.referenceKeys,
    });
  },
  'Transforms.performStaticAnalysisFailed': (state, { staticAnalysisInfo, unifiedTransformsReferencedDatasetNames, analysisInput }) => {
    checkArg({ analysisInput }, ArgTypes.instanceOf(AnalysisInput));
    return state.merge({
      loadedStaticAnalysisInfo: staticAnalysisInfo,
      loadingStaticAnalysis: false,
      loadedUnifiedTransformsReferencedDatasetNames: unifiedTransformsReferencedDatasetNames,
      unifiedTransformsReferencedDatasetTypes: analysisInput.referenceTypes,
      unifiedTransformsReferencedDatasetKeys: analysisInput.referenceKeys,
    });
  },

  'Transforms.rebase': (state) => {
    return state.merge({ loading: true });
  },
  'Transforms.rebaseCompleted': (state, { transforms }) => {
    return state
      .withMutations(mutableState => {
        const { selected } = mutableState;
        if (selected && !transforms.has(selected)) {
          mutableState.delete('selected');
        }

        const { previewCutoff } = mutableState;
        if (previewCutoff && !transforms.has(previewCutoff)) {
          mutableState.delete('previewCutoff');
        }
      })
      .merge({
        loading: false,
        transforms,
      });
  },
  'Transforms.rebaseFailedDueToConflict': (state) => {
    return state.merge({
      loading: false,
      showingRebaseConflictDialog: true,
    });
  },
  'Transforms.rebaseFailed': (state) => {
    return state.merge({ loading: false });
  },
  'Transforms.hideRebaseConflictDialog': (state) => {
    return state.merge({ showingRebaseConflictDialog: false });
  },
  'Transforms.fetchFunctionNames': (state, { }) => {
    return state.merge({ loadingFunctionNames: true });
  },
  'Transforms.fetchFunctionNamesCompleted': (state, { functionDocs }) => {
    return state.merge({
      functionDocs,
      loadingFunctionNames: false,
      loadedFunctionNames: true,
    });
  },
  'Transforms.fetchHintNames': (state, { }) => {
    return state.merge({ loadingHintNames: true });
  },
  'Transforms.fetchHintNamesCompleted': (state, { hintNames }) => {
    return state.merge({
      hintNames,
      loadingHintNames: false,
      loadedHintNames: true,
    });
  },
};
