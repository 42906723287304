import { List, Map, Set } from 'immutable';
import { isFunction } from 'underscore';

import SortState from '../constants/SortState';
import { set } from './Collections';
import { $TSFixMe } from './typescript';


export const INVALID = Symbol('invalid');

export const parseType = (checker: $TSFixMe) => (v: $TSFixMe) => {
  return !checker(v) ? v : INVALID;
};

export const parseString = (v: string | undefined) => {
  return v === undefined ? INVALID : v;
};
export const parseStringOrDefault = (defaultValue: $TSFixMe) => (v: string | undefined) => {
  const parsed = parseString(v);
  if (parsed === INVALID) return defaultValue;
  return parsed;
};

export const parseNumber = (v: string | undefined) => {
  if (v === undefined) {
    return INVALID;
  }
  const result = v ? Number(v) * 1 : NaN;
  return isNaN(result) ? INVALID : result;
};

export const parseBoolean = (v: string | undefined) => {
  switch (v) {
    case 'true': return true;
    case 'false': return false;
    default: return INVALID;
  }
};
export const parseBooleanOrDefault = (defaultValue: $TSFixMe) => (v: string | undefined) => {
  const parsed = parseBoolean(v);
  if (parsed === INVALID) return defaultValue;
  return parsed;
};

export const parseArray = (parseChild: $TSFixMe) => (v: $TSFixMe) => {
  if (v === undefined) {
    return INVALID;
  }
  const result = (v ? v.split(',') : []).map(parseChild);
  return result.includes(INVALID) ? INVALID : result;
};

export const parseSet = (parseChild: $TSFixMe) => (v: $TSFixMe) => {
  const arr = parseArray(parseChild)(v);
  return arr === INVALID ? INVALID : Set(arr);
};

export const parseList = (parseChild: $TSFixMe) => (v: $TSFixMe) => {
  const arr = parseArray(parseChild)(v);
  return arr === INVALID ? INVALID : List(arr);
};

export const parseSort = (v: $TSFixMe) => {
  const arr = parseArray((s: $TSFixMe) => {
    const m = s.match(/^(.+)\.\.(asc|desc)$/);
    return !m ? INVALID : [m[1], m[2]];
  })(v);
  return arr === INVALID ? INVALID : Map(arr);
};

export const setOrDelete = (k: $TSFixMe, v: $TSFixMe) => (state: $TSFixMe) => {
  return v === INVALID ? state.delete(k) : state.set(k, v);
};

export const maybeSet = (k: $TSFixMe, v: $TSFixMe) => (state: $TSFixMe) => {
  if (v === INVALID) {
    return state;
  }
  if (isFunction(state.set)) {
    return state.set(k, v);
  }
  return set(state, k, v);
};

export const stringifyString = (v: string | null | undefined): string | undefined =>
  ((v === null || v === undefined) ? undefined : v);

export const stringifyNumber = (v: number | null | undefined): string | undefined =>
  ((v === null || v === undefined) ? undefined : v.toString());

export const stringifyBoolean = (v: boolean | null | undefined): string | undefined =>
  ((v === null || v === undefined) ? undefined : v.toString());

const stringifyCollection = (stringifyChild: $TSFixMe) => (v: $TSFixMe) => {
  return v.map(stringifyChild).join();
};

export const stringifySet = stringifyCollection;
export const stringifyList = stringifyCollection;

export const stringifySort = (v: $TSFixMe) => {
  return stringifyCollection((dir: $TSFixMe, name: $TSFixMe) => `${name}..${dir}`)(v ? v.filter((dir: $TSFixMe) => dir !== SortState.UNSORTED) : undefined);
};
