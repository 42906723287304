import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';


const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
};

const RecordPairCell: React.FC<InferProps<typeof propTypes>> = ({ children, className, innerClassName }) => {
  return (
    <div className={classNames('record-pair-cell', className)}>
      <div className={classNames('inner', innerClassName)}>
        {children}
      </div>
    </div>
  );
};
RecordPairCell.propTypes = propTypes;

export default RecordPairCell;
