import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import TamrIcon from '../components/TamrIcon';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import { pluralize } from '../utils/Strings';
import style from './ConfirmRemoveDatasetsFromProjectDialog.module.scss';
import { removeDatasetsFromProject } from './ProjectDatasetCatalogAsync';

const ConfirmRemoveDatasetsFromProjectDialog = connect(({ projectDatasetCatalog: { datasets, confirmingRemoveForDatasets } }) => {
  const datasetDocs = datasets.filter((ds) => confirmingRemoveForDatasets.has(ds.data.name));
  return { datasets: datasetDocs, show: !confirmingRemoveForDatasets.isEmpty() };
}, {
  onAccept: removeDatasetsFromProject,
  onHide: () => ({ type: 'ProjectDatasetCatalog.cancelRemovingDatasets' }),
})(class ConfirmRemoveDatasetsFromProjectDialog extends React.Component {
  static propTypes = {
    datasets: ImmutablePropTypes.listOf(Document.propType.withDataType(Dataset)),
    onAccept: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
  };

  accept = () => {
    this.props.onAccept();
    this.props.onHide();
  };

  render() {
    const { show, onHide, datasets } = this.props;
    const warnIconTitle = <TamrIcon className={style.icon} iconName="warning" size={30} />;
    const warnIconBody = <TamrIcon className={style.icon} iconName="warning" size={16} />;
    return (
      <Dialog
        dialogStyle={DialogStyle.PRIMARY}
        show={show}
        onHide={onHide}
        title={
          show ? (
            <div>
              {warnIconTitle} Warning: {pluralize(datasets.size, 'Dataset', 'Datasets')} Removal
            </div>
          ) : null
        }
        body={show ? (
          <div className={style.body}>
            Are you sure you want to remove {pluralize(datasets.size, 'this dataset', 'these datasets')} from this project?
            <ul className={style.list}>{datasets.map(({ data: { name } }) => <li key={name} className={style.datasetFile}>{name}</li>)}</ul>
            {warnIconBody} Removing {pluralize(datasets.size, 'a dataset', 'datasets')} used in transformations can result in downstream errors.
            If {pluralize(datasets.size, 'this dataset is', 'these datasets are')} used in transformations, update the transformations to remove
            the {pluralize(datasets.size, 'dataset', 'datasets')}.
          </div>
        ) : null
        }
        footer={show ? (
          <ButtonToolbar>
            <Button onClick={this.props.onHide} buttonType="Secondary">Cancel</Button>
            <Button onClick={this.accept} title="Remove Dataset">Confirm</Button>
          </ButtonToolbar>
        ) : null}
      />
    );
  }
});

export default ConfirmRemoveDatasetsFromProjectDialog;
