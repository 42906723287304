import classNames from 'classnames';
import { Map } from 'immutable';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';

import Button from './Button';
import ConditionalButton, { preconditionsPropType } from './ConditionalButton';
import style from './FilterButton.module.scss';
import TooltipTrigger, { propTypes as tooltipTriggerPropTypes } from './TooltipTrigger';


const propTypes = {
  className: PropTypes.string,
  disabledTooltipPlacement: tooltipTriggerPropTypes.placement,
  filterExpanded: PropTypes.bool,
  hasFilters: PropTypes.bool,
  noun: PropTypes.node,
  onClear: PropTypes.func,
  onClick: PropTypes.func,
  onRefresh: PropTypes.func,
  preconditions: preconditionsPropType,
  render: PropTypes.func,
};

type FilterButtonProps = InferProps<typeof propTypes>;

const FilterButton: React.FunctionComponent<FilterButtonProps> = ({ className, onClick, onClear, onRefresh, render, noun, preconditions, disabledTooltipPlacement, filterExpanded, hasFilters }) => {
  const buttonStyle = hasFilters
    ? 'Primary'
    : filterExpanded
      ? 'Shaded'
      : 'Secondary';

  const classStyle = buttonStyle === 'Primary'
    ? style.primary
    : buttonStyle === 'Secondary'
      ? style.secondary
      : buttonStyle === 'Shaded'
        ? style.shaded
        : undefined;

  const button = (
    <ConditionalButton
      preconditions={preconditions || Map<string, boolean>()}
      tooltipPlacement={disabledTooltipPlacement}
      buttonType={buttonStyle}
      className={classStyle}
      icon="filter-list"
      iconSize={16}
      onClick={onClick || undefined} />);
  return (
    <div className={classNames(style.container, className)}>
      {render ? render(button) : button}
      {onClear ?
        <TooltipTrigger placement="bottom" content="Clear all filters">
          <Button buttonType="Shaded" className={style.supplement} icon="close" iconSize={14} onClick={onClear} />
        </TooltipTrigger>
        : null}
      {onRefresh ?
        <TooltipTrigger placement="bottom" content={<div>Reload page to remove {noun} no longer matching your filters</div>}>
          <Button buttonType={onClear ? 'Shaded' : 'Secondary'} className={style.supplement} icon="refresh" iconSize={14} onClick={onRefresh} />
        </TooltipTrigger>
        : null}
    </div>
  );
};

FilterButton.propTypes = propTypes;

export default FilterButton;
