import { Set } from 'immutable';
import { cloneDeep, difference, intersection, keys, union } from 'lodash';
import { materializeDatasets } from '../api/DatasetClient';
import { fetchGroupRecords, fetchPregroupSample, fetchPregroupStats } from '../api/DedupClient';
import { updateJobMetadata } from '../api/JobClient';
import { fetchTransactions } from '../api/ProcurifyClient';
import { updateOperationDatasets, updateRecipe, upgradeRecipe } from '../api/RecipeClient';
import { PAIRS } from '../constants/RecipeOperations';
import { DEDUP } from '../constants/RecipeType';
import { SHOW_SIMPLE } from '../errorDialog/ErrorDialogActionTypes';
import { PROJECT_ID_METADATA_KEY } from '../models/Job';
import { DEDUP_INFO_METADATA_KEY } from '../models/Recipe';
import { RELOAD } from '../projects/ProjectsActionTypes';
import { AppThunkAction } from '../stores/AppAction';
import { TxnUrlBuilder } from '../transactions/TransactionUtils';
import ElasticUtils from '../utils/ElasticUtils';
import { getDatasetByName } from '../api/DatasetClient';
import {
  getDedupInfo,
  getUnifiedDatasetName,
  selectActiveProjectInfo,
} from '../utils/Selectors';
import {
  FETCH_DATA,
  FETCH_DATA_COMPLETED,
  FETCH_DATA_FAILED,
  FETCH_PREVIEW_GROUP_RECORDS,
  FETCH_PREVIEW_GROUP_RECORDS_COMPLETED,
  FETCH_PREVIEW_GROUP_RECORDS_FAILED,
  GROUPING_OUT_OF_DATE,
  SAVE_GROUPING,
  SAVE_GROUPING_COMPLETED,
  SAVE_GROUPING_FAILED, SHOW_UP_TO_DATE_DIALOG, SET_RECORD_GROUPING_ENABLED,
  UPDATE_GROUPING,
  UPDATE_GROUPING_COMPLETED,
} from './PregroupActionTypes';
import { defaultAggregationFunction, defaultPregroupSpec } from './PreGroupBy';
import {
  noWarnings,
  selectPreGroupBy,
  selectPreviewGroupPageNum,
  selectPreviewGroupPageSize,
  selectPreviewGroupRecordId,
  selectPreviewGroupRowIndex,
  selectSamplePageNum,
  selectSamplePageSize,
  selectSampleSpec,
  selectDedupUnifiedAttributes,
  selectGroupingKeys,
  UNIFIED_DATASET_INTERNAL_FIELDS,
} from './PregroupStore';
import { RecordGroup } from './RecordGroup';

export const UPDATE_RECORD_GROUPING_JOB_DESCRIPTION = 'Update Record Grouping';

export const fetchPregroupData = (): AppThunkAction<void> => async (dispatch, getState) => {
  try {
    const state = getState();
    const { pregroup: { isInitialFetch } } = state;
    const unifiedDatasetName = getUnifiedDatasetName(state);
    if (!unifiedDatasetName) {
      throw new Error('Unified dataset not found');
    }
    const pageSize = selectSamplePageSize(state);
    const pageNum = selectSamplePageNum(state);

    /*
    There is a small delay between an update unified dataset job beginning and recipe information updating
    Get datasetByName has the updated fields immediately. The fields from the dataset obtained via selectActiveProjectInfo is delayed
    To avoid fetch errors during this period of inconsistency, we filter the unifiedAttributes to the names present in the unifiedDatasetDoc
     */
    const unifiedDatasetDoc = await getDatasetByName(unifiedDatasetName);
    const unifiedAttributes = selectDedupUnifiedAttributes(state).filter(ua => unifiedDatasetDoc.data.fields.contains(ua.name));
    const fields = unifiedAttributes.map(ua => ua.name);

    dispatch({ type: FETCH_DATA });
    const draftPreGroupBy = cloneDeep(selectPreGroupBy(state)) || defaultPregroupSpec(unifiedAttributes);
    const persistedPreGroupBy = getDedupInfo(state)?.preGroupBy;
    if (isInitialFetch) {
      dispatch({ type: SET_RECORD_GROUPING_ENABLED, isGroupingEnabled: persistedPreGroupBy !== null });
    }
    const persistedExcludedDatasets : string[] = getDedupInfo(state)?.noClusteringWithinSources;
    let draftExcludedDatasets = state.pregroup.excludedDatasetNames;
    if (draftExcludedDatasets == null) {
      draftExcludedDatasets = Set(persistedExcludedDatasets);
    }

    if (draftPreGroupBy.groupingFields.length === 0) {
      // if no specified grouping fields => just fetch all the (ungrouped) records
      // as record grouping preview endpoints do not currently work well with record grouping specs that do not have grouping fields
      if (!unifiedDatasetDoc) {
        throw new Error('Unified dataset doc not found');
      }
      const allTransactions = await fetchTransactions(
        new TxnUrlBuilder(DEDUP).unifiedDatasetName(unifiedDatasetName).pageSize(pageSize).pageNum(pageNum),
        unifiedDatasetDoc,
      );
      const sample: RecordGroup[] = allTransactions.items.map(i => ({
        fieldValues: i.fields.reduce((agg, current) => ({ ...agg, [current]: i._data[ElasticUtils.sanitizeField(current)] }), {}),
        groupIds: {},
        groupSize: 0,
      })).toArray();
      return dispatch({ type: FETCH_DATA_COMPLETED, sample, sampleNumInputRecords: allTransactions.total, sampleMetrics: null, sampleSpec: draftPreGroupBy, warnings: noWarnings, excludedDatasetNames: Set(draftExcludedDatasets) });
    }

    // compute warnings with dedup data not potentially stale saved data
    // if preGroupBy is missing ml fields that are present in the UD, they should be added to the list with a default aggregation function
    let warnings = state.pregroup.warnings;
    if (persistedPreGroupBy !== null) {
      const addedFields = difference(fields, persistedPreGroupBy.groupingFields, keys(persistedPreGroupBy.fieldAggregationMap))
        .filter(n => !UNIFIED_DATASET_INTERNAL_FIELDS.contains(n));

      // "addedFields" are all fields that have been added since the last save
      // Here we filter further to those that the user has not yet set as an agg function or grouping key and set default options for them
      const unifiedAttributesToAdd = unifiedAttributes.filter(ua => addedFields.includes(ua.name))
        .filter(ua => !keys(draftPreGroupBy.fieldAggregationMap).includes(ua.name))
        .filter(ua => !draftPreGroupBy.groupingFields.includes(ua.name));
      unifiedAttributesToAdd.filter(ua => !ua.mlEnabled)
        .forEach(ua => {
          draftPreGroupBy.fieldAggregationMap[ua.name] = defaultAggregationFunction(ua);
        });
      draftPreGroupBy.groupingFields = draftPreGroupBy.groupingFields.concat(
        unifiedAttributesToAdd.filter(ua => ua.mlEnabled).map(ua => ua.name),
      );

      // if preGroupBy contains fields that no longer exist, they should be removed
      const removedFieldsAggregations = difference(keys(persistedPreGroupBy.fieldAggregationMap), fields)
        .filter(n => !UNIFIED_DATASET_INTERNAL_FIELDS.contains(n));
      if (removedFieldsAggregations) {
      // throw warning
        removedFieldsAggregations.forEach(field => {
          delete draftPreGroupBy?.fieldAggregationMap[field];
        });
      }
      const removedFields = union(removedFieldsAggregations, difference(persistedPreGroupBy.groupingFields, fields));
      draftPreGroupBy.groupingFields = intersection(draftPreGroupBy.groupingFields, fields);
      draftPreGroupBy.groupNullFields = intersection(draftPreGroupBy.groupNullFields, fields);
      warnings = { removedFields, addedFields };

      if (addedFields.length > 0 || removedFields.length > 0) {
        dispatch({ type: GROUPING_OUT_OF_DATE });
      }
    } else {
      dispatch({ type: GROUPING_OUT_OF_DATE });
    }

    const [sample, sampleMetrics] = await Promise.all([
      fetchPregroupSample({
        unifiedDatasetName,
        preGroupBy: draftPreGroupBy,
        maxGroupSize: state.pregroup.sampleMaxGroupSize,
        limit: pageSize,
        offset: pageNum,
        noClusteringWithinSources: Array.from(draftExcludedDatasets),
      }),
      fetchPregroupStats({ unifiedDatasetName, preGroupBy: draftPreGroupBy, noClusteringWithinSources: Array.from(draftExcludedDatasets) }),
    ]);
    return dispatch({ type: FETCH_DATA_COMPLETED, sample, sampleNumInputRecords: sampleMetrics.numberOfRecordsInTrivialGroups + sampleMetrics.numberOfRecordsInNonTrivialGroups, sampleMetrics, sampleSpec: draftPreGroupBy, warnings, excludedDatasetNames: draftExcludedDatasets });
  } catch (e) {
    dispatch({ type: FETCH_DATA_FAILED, errorMessage: String(e) });
    dispatch({ type: SHOW_SIMPLE, title: 'Unable to fetch sample for grouped records', detail: e.message });
  }
};

export const saveGrouping = (): AppThunkAction<void> => async (dispatch, getState) => {
  try {
    const state = getState();
    const projectInfo = selectActiveProjectInfo(state);
    if (!projectInfo) {
      throw new Error('No active project');
    }
    const recipeId = projectInfo.recipeId;
    const recipe = projectInfo.recipe;
    const preGroupBy = selectSampleSpec(state);
    const unifiedAttributes = selectDedupUnifiedAttributes(state);
    dispatch({ type: SAVE_GROUPING });
    await updateRecipe({ recipeId, recipe: recipe.setIn(['metadata', DEDUP_INFO_METADATA_KEY, 'preGroupBy'], (preGroupBy?.groupingFields.length === 0 || !state.pregroup.isGroupingEnabled) ? null : preGroupBy).setIn(['metadata', DEDUP_INFO_METADATA_KEY, 'noClusteringWithinSources'], state.pregroup.excludedDatasetNames) });
    await upgradeRecipe({ recipeId, runOperations: false });
    // needed for edge case where user does not reload page before toggling back on record grouping
    let sampleSpec = state.pregroup.sampleSpec;
    let outOfDate = false;
    if (!state.pregroup.isGroupingEnabled) {
      sampleSpec = defaultPregroupSpec(unifiedAttributes);
      outOfDate = true;
    }
    dispatch({ type: SAVE_GROUPING_COMPLETED, sampleSpec, outOfDate });
    dispatch({ type: RELOAD });
  } catch (e) {
    dispatch({ type: SAVE_GROUPING_FAILED, message: String(e) });
    dispatch({ type: SHOW_SIMPLE, title: 'Unable to save grouped records', detail: e.message });
  }
};

export const resetGrouping = (): AppThunkAction<void> => async (dispatch, getState) => {
  try {
    const state = getState();
    const dedupInfo = getDedupInfo(state);
    if (!dedupInfo) {
      throw new Error('No dedup info found for active project');
    }
    dispatch({ type: SAVE_GROUPING });
    const preGroupBy = dedupInfo.preGroupBy;
    dispatch({ type: SAVE_GROUPING_COMPLETED, sampleSpec: preGroupBy, outOfDate: false });

    if (preGroupBy == null) {
      dispatch({ type: SET_RECORD_GROUPING_ENABLED, isGroupingEnabled: false });
    }
    dispatch({ type: RELOAD });
  } catch (e) {
    dispatch({ type: SAVE_GROUPING_FAILED, message: String(e) });
    dispatch({ type: SHOW_SIMPLE, title: 'Unable to clear grouped records', detail: e.message });
  }
};

export const fetchPreviewGroupRecords = (): AppThunkAction<void> => async (dispatch, getState) => {
  try {
    const state = getState();
    const unifiedDatasetName = getUnifiedDatasetName(state);
    if (!unifiedDatasetName) {
      throw new Error('Cannot fetch grouped records, unified dataset not found');
    }
    const previewGroupRowIndex = selectPreviewGroupRowIndex(state);
    if (previewGroupRowIndex === null) {
      throw new Error('Cannot fetch grouped records, no group selected');
    }
    const groupId = selectPreviewGroupRecordId(state);
    if (!groupId) {
      throw new Error('Cannot fetch grouped records, no group record id');
    }

    // null values are omitted from the groupId, here we add in explicit nulls for any missing grouping keys
    const allGroupingKeys = selectGroupingKeys(state);
    if (!allGroupingKeys) {
      throw new Error('Cannot fetch grouped records, no grouping keys');
    }
    allGroupingKeys.forEach(key => {
      if (!groupId.hasOwnProperty(key)) {
        groupId[key] = null;
      }
    });

    dispatch({ type: FETCH_PREVIEW_GROUP_RECORDS });
    const records = await fetchGroupRecords({
      unifiedDatasetName,
      limit: selectPreviewGroupPageSize(state),
      offset: selectPreviewGroupPageSize(state) * selectPreviewGroupPageNum(state),
      groupIdByGroupingField: [groupId],
    });
    dispatch({ type: FETCH_PREVIEW_GROUP_RECORDS_COMPLETED, records });
  } catch (e) {
    dispatch({ type: FETCH_PREVIEW_GROUP_RECORDS_FAILED, message: String(e) });
    dispatch({ type: SHOW_SIMPLE, title: 'Unable to fetch sample for grouped records', detail: e.message });
  }
};

export const runUpdatePreGroup = (): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  const projectInfo = selectActiveProjectInfo(state);
  const dedupRecipe = projectInfo?.dedupRecipeDoc;
  if (!dedupRecipe) {
    throw new Error('Cannot update record grouping. No dedup recipe found');
  }
  const datasetNames = ['_dedup_grouped_entities', '_dedup_entity_group_mapping']
    .map(suffix => projectInfo.unifiedDatasetName + suffix);
  try {
    dispatch({ type: UPDATE_GROUPING });
    await updateOperationDatasets(dedupRecipe.id.id, PAIRS)
      .then(() => materializeDatasets(UPDATE_RECORD_GROUPING_JOB_DESCRIPTION, projectInfo.project.projectSparkConfigOverrides, datasetNames))
      .then(job => {
        if (job == null) {
          // If materialize dataset returns a null job, it means the dataset is already up to date
          dispatch({ type: SHOW_UP_TO_DATE_DIALOG });
          dispatch({ type: UPDATE_GROUPING_COMPLETED });
        } else {
          // Add the projectId to the job metadata, this allows the jobs page to associate the job with the project
          return updateJobMetadata(job.id.id, { [PROJECT_ID_METADATA_KEY]: projectInfo?.projectId });
        }
      });
  } catch (e) {
    dispatch({ type: UPDATE_GROUPING_COMPLETED });
    dispatch({ type: SHOW_SIMPLE, title: 'Unable to update group records', detail: e });
  }
};
