import { List, Set } from 'immutable';

import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import { StoreReducers } from '../stores/AppAction';
import { getResetFns, merge, merger, set, setter, update, updater } from '../utils/Collections';

export interface DatasetFilterFilterInfo {
  page: number,
  pageSize: number,
  searchValue: string,
  selectedTags: Set<number>,
  fetchSequence: number,
}

export function getFilterInfo({ page, pageSize, searchValue, selectedTags, fetchSequence }: DatasetFilterStore): DatasetFilterFilterInfo {
  return { page, pageSize, searchValue, selectedTags, fetchSequence };
}

const isSelected = (state: DatasetFilterStore, datasetName: string) => {
  return state.selectedDatasets.has(datasetName);
};
const isChecked = (state: DatasetFilterStore, datasetName: string) => {
  return (isSelected(state, datasetName) || state.datasetsToAdd.some(d => d.data.name === datasetName))
  && !state.datasetsToRemove.some(d => d.data.name === datasetName);
};

// here, checked != selected. selected means has been committed, checked is committed and/or staged for commit
export const getCheckedDatasets = (state: DatasetFilterStore): Set<string> => {
  return state.datasets.map(d => d.data.name).filter(name => isChecked(state, name)).toSet();
};

interface DatasetFilterStore {
  // current page of datasets
  // Note: this is only tracking the datasets on the CURRENT PAGE of the DatasetFilter dialog!
  datasets: List<Document<Dataset>>,
  disabledDatasetIds: List<number>,
  // used to tell which of the current page of datasets have been selected (but not necessarily checked)
  // Note: this is only tracking selection state for the CURRENT PAGE of the DatasetFilter dialog!
  //   This should not be used to determine overall selection state, eg. to apply selections in an API call
  selectedDatasets: Set<string>,
  // datasetsToAdd and datasetsToRemove are staging collections for committing changes
  datasetsToAdd: Set<Document<Dataset>>,
  datasetsToRemove: Set<Document<Dataset>>,
  // fetching / filtering state
  loading: boolean,
  loadedFilterInfo: DatasetFilterFilterInfo | undefined,
  fetchSequence: number,
  page: number,
  pageSize: number,
  searchValue: string,
  numDatasets: number,
  tagSearchValue: string,
  selectedTags: Set<number>,
}

export const initialState: DatasetFilterStore = {
  datasets: List<Document<Dataset>>(),
  disabledDatasetIds: List<number>(),
  selectedDatasets: Set<string>(),
  datasetsToAdd: Set<Document<Dataset>>(),
  datasetsToRemove: Set<Document<Dataset>>(),
  loading: false,
  loadedFilterInfo: undefined,
  fetchSequence: 0,
  page: 0,
  pageSize: 100,
  searchValue: '',
  numDatasets: 0,
  tagSearchValue: '',
  selectedTags: Set<number>(),
} as const;

const { reset } = getResetFns(initialState);

const refetchDatasets = (state: DatasetFilterStore) => update(state, 'fetchSequence', i => i + 1);
const resetStore = (state: DatasetFilterStore) => refetchDatasets(reset(state, ...Object.keys(initialState).filter(k => !['loading', 'fetchSequence'].includes(k)) as (keyof DatasetFilterStore)[]));
const resetPage = (state: DatasetFilterStore) => reset(state, 'page');

export const reducers: StoreReducers<DatasetFilterStore> = {
  'DatasetFilter.reset': (state) => {
    return resetStore(state);
  },
  'DatasetFilter.resetStagedSelections': (state) => {
    return reset(state, 'datasetsToAdd', 'datasetsToRemove');
  },
  'DatasetFilter.fetchDatasets': (state) => {
    return set(state, 'loading', true);
  },
  'DatasetFilter.fetchDatasetsCompleted': (state, { datasets, numDatasets, filterInfo, selectedDatasets, disabledDatasetIds }) => {
    return update(state,
      merger<DatasetFilterStore>({ datasets, selectedDatasets, disabledDatasetIds, numDatasets, loadedFilterInfo: filterInfo }),
      setter('loading', false));
  },
  'DatasetFilter.setTagSearchValue': (state, { searchValue }) => {
    return merge(state, { tagSearchValue: searchValue });
  },
  'DatasetFilter.setSelectedTags': (state, { tagNames }) => {
    return update(state,
      merger<DatasetFilterStore>({ selectedTags: tagNames }),
      resetPage);
  },
  // changes to staging collections need to be conservative
  // eg. unchecking an unselected but checked row must just remove it from toAdd, not add it to toRemove
  'DatasetFilter.toggleRow': (state, { rowNumber }) => {
    const dataset = state.datasets.get(rowNumber);
    if (!dataset) {
      console.error(`Cannot toggleRow for rowNumber ${rowNumber} - no fetched dataset at that index`);
      return state;
    }
    const datasetName = dataset.data.name;
    const selected = isSelected(state, datasetName);
    const checked = isChecked(state, datasetName);
    return checked
      ? update(state,
        updater('datasetsToAdd', s => s.filter(d => d.data.name !== datasetName)),
        updater('datasetsToRemove', s => (selected ? s.add(dataset) : s)))
      : update(state,
        updater('datasetsToAdd', s => (selected ? s : s.add(dataset))),
        updater('datasetsToRemove', s => s.filter(d => d.data.name !== datasetName)));
  },
  'DatasetFilter.toggleRowExclusive': (state, { rowNumber }) => {
    const dataset = state.datasets.get(rowNumber);
    if (!dataset) {
      console.error(`Cannot toggleRow for rowNumber ${rowNumber} - no fetched dataset at that index`);
      return state;
    }
    const currentlySelected = state.datasetsToAdd;
    return update(state,
      merger<DatasetFilterStore>({ datasetsToAdd: Set().add(dataset), datasetsToRemove: currentlySelected }),
    );
  },
  'DatasetFilter.toggleAll': (state) => {
    const { selectedDatasets, datasets } = state;
    const datasetNames = datasets.map(d => d.data.name).toSet();
    const anyUnselected = datasetNames.some(dsName => !isChecked(state, dsName));
    if (anyUnselected) {
      return update(state,
        updater('datasetsToAdd', s => s.filter(d => !datasetNames.has(d.data.name)).union(datasets.filter(d => !selectedDatasets.has(d.data.name)))),
        updater('datasetsToRemove', s => s.filter(d => !datasetNames.has(d.data.name))));
    }
    return update(state,
      updater('datasetsToAdd', s => s.filter(d => !datasetNames.has(d.data.name))),
      updater('datasetsToRemove', s => s.filter(d => !datasetNames.has(d.data.name)).union(datasets.filter(d => selectedDatasets.has(d.data.name)))));
  },
  'DatasetFilter.setSearchValue': (state, { searchValue }) => {
    return update(state,
      merger<DatasetFilterStore>({ searchValue }),
      resetPage);
  },
  'DatasetFilter.setPage': (state, { page }) => {
    return merge(state, { page });
  },
  'DatasetFilter.setPageSize': (state, { pageSize }) => {
    return update(state,
      merger<DatasetFilterStore>({ pageSize }),
      resetPage);
  },
};
