/* eslint react/no-danger:0 */

import PropTypes from 'prop-types';
import React from 'react';
import { DragSource as dragSource } from 'react-dnd';
import { pure } from 'recompose';
import _ from 'underscore';

import TooltipTrigger from '../components/TooltipTrigger';
import style from './UnpivotItem.module.scss';

const UnpivotItem = _.compose(
  dragSource('unifiedAttribute', {
    beginDrag: ({ attributeName }) => {
      return { attributeName };
    },
    canDrag: () => {
      return true;
    },
  }, connector => {
    return { connectDragPreview: connector.dragPreview(), connectDragSource: connector.dragSource() };
  }),
  pure,
)(class UnpivotItem extends React.Component {
  static propTypes = {
    attributeName: PropTypes.string.isRequired,
    canDrop: PropTypes.bool,
    connectDragPreview: PropTypes.func,
    connectDragSource: PropTypes.func,
    connectDropTarget: PropTypes.func,
  };

  componentDidMount() {
    // TODO: Use empty image as a drag preview so browsers don't draw it
    this.props.connectDragPreview();
  }

  render() {
    const { attributeName, connectDragSource } = this.props;
    return connectDragSource(
      <span>
        <TooltipTrigger
          trigger={['hover', 'click']}
          placement="right"
          content={attributeName}
        >
          <span className={style.unpivotItemPills}>
            {attributeName}
          </span>
        </TooltipTrigger>
      </span>,
    );
  }
});

export default UnpivotItem;
