import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import { CLEAR_BOOTSTRAP_NAME_VALIDATION_ERRORS } from './SchemaMappingActionTypes';
import SchemaMappingDialogTable from './SchemaMappingDialogTable';

const BootstrapNameValidationErrorsDialog = _.compose(
  connect(({ schemaMapping }) => {
    const { bootstrapNameValidationErrors } = schemaMapping;
    return { bootstrapNameValidationErrors };
  }, {
    onHide: () => ({ type: CLEAR_BOOTSTRAP_NAME_VALIDATION_ERRORS }),
  }),
)(({ bootstrapNameValidationErrors, onHide }) => {
  const show = !bootstrapNameValidationErrors.isEmpty();
  const orderedErrors = show && bootstrapNameValidationErrors
    .mapEntries(([name, errors], index) => [index, [name, errors]])
    .toList()
    .sortBy(([name]) => name);
  return (
    <Dialog
      {...{ show, onHide }}
      dialogStyle={DialogStyle.PRIMARY}
      closeButton
      title="Some unified attributes could not be created"
      body={show && (
        <div>
          Some of the names of the input attributes are invalid as unified attribute names.
          &nbsp;The rest of the attributes bootstrapped successfully.
          <br />
          <br />
          Here are the attributes that were not bootstrapped, and their validation errors:
          <SchemaMappingDialogTable
            header1="Input Attribute"
            header2="Validation Error"
            numRows={orderedErrors.size}
            rowRenderer={(rowIndex) => {
              const [name, errors] = orderedErrors.get(rowIndex);
              return [
                name,
                errors.join('\n'),
              ];
            }}
          />
        </div>
      )}
      footer={
        <ButtonToolbar>
          <Button buttonType="Primary" onClick={onHide}>Okay</Button>
        </ButtonToolbar>
      }
    />
  );
});

export default BootstrapNameValidationErrorsDialog;
