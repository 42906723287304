import createLoader from '../utils/createLoader';
import { fetchSparkConfigNames } from './JobAsync';

/*
 * Loads all Spark config names exactly once (until a browser refresh happens).
 */
const SparkConfigLoader = createLoader((state) => {
  const { jobs: { loadedSparkConfigNames }, loading } = state;
  return {
    canFetch: true, // no preconditions
    shouldFetch: !loadedSparkConfigNames,
    loading: !!loading,
  };
}, {
  onFetch: fetchSparkConfigNames,
}, 'SparkConfigLoader');

export default SparkConfigLoader;
