import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import { ArgTypes } from '../utils/ArgValidation';
import SampleRecords from './SampleRecords';
import StorageNode from './StorageNode';


export default class PathElement extends getModelHelpers({
  node: { type: StorageNode.argType },
  children: { type: ArgTypes.orNull(ArgTypes.Immutable.map), defaultValue: null },
  sample: { type: ArgTypes.orNull(ArgTypes.instanceOf(SampleRecords)), defaultValue: null },
}, 'PathElement')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class PathElementRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}
