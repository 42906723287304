import { List } from 'immutable';

import createLoader from '../utils/createLoader';
import { fetchGeospatialTransactions } from './SuppliersAsync';

// Fetch when the current bounds of the geospatial changes
const GeospatialTxnLoader = createLoader((state) => {
  const {
    suppliers: {
      geospatial: {
        currentBounds,
        loadingBounds,
        loadedBounds,
        activeGeospatialRenderingAttribute,
      },
    },
  } = state;

  return {
    canFetch: !!activeGeospatialRenderingAttribute,
    shouldFetch: !List(currentBounds).flatten().equals(List(loadedBounds).flatten()),
    loading: loadingBounds,
  };
}, {
  onFetch: fetchGeospatialTransactions,
}, 'GeospatialTxnLoader');

export default GeospatialTxnLoader;
