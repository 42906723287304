import { Map } from 'immutable';
import React from 'react';
import _ from 'underscore';

import ConditionalButton from '../components/ConditionalButton';
import Column from '../components/Table/Column';
import { getPath } from '../utils/Values';
import style from './DatasetPreviewColumn.module.scss';

const DatasetPreviewColumn = ({ width, rows, currentlyProfilingDatasetNames, profiling, status, onFetchDatasetSample }) => {
  return (
    <Column
      key="datasetPreview"
      width={width}
      columnKey="datasetPreview"
      flexGrow={1}
      cell={({ rowIndex }) => {
        const dataset = rows.get(rowIndex);
        const datasetName = dataset.data.name;
        const isSampleDataset = !!getPath(dataset, 'data', 'metadata', 'sampleMetadata');
        const profiledRevision = profiling.getIn([datasetName, 'revision']);
        const profileUpToDate = !(_.isNumber(profiledRevision) && profiledRevision !== status.get(datasetName).currentRevision);
        const isCurrentlyProfiling = currentlyProfilingDatasetNames.has(datasetName);
        const profileData = profiling.getIn([datasetName, 'schema']);
        const hasNotProfiled = !profileData || profileData.isEmpty();
        return (
          <ConditionalButton
            className={style.datasetPreviewLink}
            buttonType="Link"
            onClick={() => onFetchDatasetSample({ datasetName })}
            preconditions={(() => {
              if (isSampleDataset) return Map.of('Sample datasets cannot be previewed', false);
              if (isCurrentlyProfiling) return Map.of('Profiling in progress', false);
              if (hasNotProfiled) return Map.of('Not profiled', false);
              if (!profileUpToDate) return Map.of('Profiling is out of date', false);
              return Map();
            })()}
          >
            Preview
          </ConditionalButton>
        );
      }}
    />
  );
};

export default DatasetPreviewColumn;
