import classNames from 'classnames';
import { Set } from 'immutable';
import React from 'react';
import { DragSource, DragSourceConnector } from 'react-dnd';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import TamrIcon from '../components/TamrIcon';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
// @ts-expect-error
import { fetchTransactions } from '../records/RecordsApi';
import { PREVIEW } from '../records/RowProviders';
import AppState from '../stores/AppState';
import { AppDispatch } from '../stores/MainStore';
import { activeUserIsCuratorForActiveProject } from '../utils/Selectors';
import { pluralize } from '../utils/Strings';
// @ts-expect-error
import DatasetSelector from './DatasetSelector';
import Lint from './models/Lint';
// @ts-expect-error
import Transform from './Transform';
import style from './Transform.module.scss';
import TransformErrorsIcon from './TransformErrorsIcon';
import Section from './TransformSection';
// @ts-expect-error
import { getLatestForBulkTransform, selectLints, transformIsAfterCutoff } from './TransformsStore';
import Summary from './TransformSummary';

/**
 * A transformation in the sidebar e.g. a single 'Fill' or 'Script'
 */
const BulkTransformRenderer: React.FC<ConnectedBulkTransformProps> = ({
  userIsCurator,
  bulkTransform,
  errors,
  onPromptConfirmDeleteTransform,
  onReset,
  onSetCutoff,
  onSetCutoffHover,
  onUsePreview,
  onRefetch,
  onDone,
  loadedDatasetId,
  connectDragSource,
  guid,
  selected,
  onSelect,
  isDisabled,
  resetAdditionalAddTransformSelector,
  sourceDatasetIds,
}) => {
  const { datasetIds } = bulkTransform;
  const validDatasetIds = Set(datasetIds).intersect(sourceDatasetIds);
  const summaryText = datasetIds.contains(loadedDatasetId)
    ? 'for records from unified dataset'
    : validDatasetIds.size > 0 && validDatasetIds.size !== sourceDatasetIds.size
      ? `for records from ${validDatasetIds.size} ${pluralize(validDatasetIds.size, 'dataset', 'datasets')}`
      : 'for records from all input datasets';
  const previewFetch = () => {
    onSetCutoff(guid);
    onUsePreview();
    onRefetch();
  };

  // make the whole transform draggable, the drag handle is just an indicator
  return connectDragSource(
    <div
      onClick={() => {
        resetAdditionalAddTransformSelector();
        if (!selected && userIsCurator) {
          onSelect(guid);
        }
      }}
      className={classNames(style.card, {
        [style.selected]: selected,
        [style.isDisabled]: isDisabled,
        [style.readOnly]: !userIsCurator,
      })}
      key={guid}
    >
      {userIsCurator && (
        <div className={style.reorder}>
          <TamrIcon title="Reorder transformation" iconName="drag-handle" className={style.icon} size={20} />
        </div>
      )}
      <div className={style.cardContainer}>
        <Transform guid={guid} />
        {selected ? (
          <Section className={style.forSelectorSection}>
            <span className={style.forSelector}>for records from&nbsp;</span>
            <DatasetSelector guid={guid} />
          </Section>
        ) : (
          <Summary name={bulkTransform.operation.type}>{summaryText}</Summary>
        )}
        {selected && ['Unpivot', 'Fill'].includes(bulkTransform.operation.className) ? (
          <TransformErrorsIcon
            errorMessages={errors.map((e: Lint) => e.message)}
            className={style.visualTransformError}
          />
        ) : null}
        {selected ? (
          <div className={classNames(style.toolbar, style.section, { [style.isDisabled]: isDisabled })}>
            <div>
              <TamrIcon
                title="Remove transformation"
                className={style.deleteButton}
                iconName="delete"
                size={16}
                onClick={() => onPromptConfirmDeleteTransform(guid)}
              />
            </div>
            <div>
              <Button buttonType="Secondary" onClick={() => onReset(guid)}>
                Cancel
              </Button>
              <Button
                buttonType="Secondary"
                onClick={previewFetch}
                onMouseEnter={() => onSetCutoffHover(guid)}
                onMouseLeave={() => onSetCutoffHover()}
              >
                Preview
              </Button>
              <Button buttonType="Primary" onClick={() => onDone()}>
                Done
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </div>,
  );
};

const mapState = (state: AppState, { guid }: { guid: string }) => {
  const {
    transforms: transformsState,
    transforms: { selected, loadedDatasetId },
    allSourceDatasets: { datasets },
  } = state;
  return {
    bulkTransform: getLatestForBulkTransform(transformsState, guid),
    errors: selectLints(transformsState).get(guid),
    selected: guid === selected,
    isDisabled: transformIsAfterCutoff(state.get('transforms'), guid),
    loadedDatasetId,
    sourceDatasetIds: datasets.map((dataset: Document<Dataset>) => dataset.id.id).toSet(),
    userIsCurator: activeUserIsCuratorForActiveProject(state),
  };
};

const mapDispatch = (dispatch: AppDispatch) => {
  return {
    onBeginDrag: (guid: string) => dispatch({ type: 'Transforms.beginDrag', guid }),
    onEndDrag: () => dispatch({ type: 'Transforms.endDrag' }),
    onReorderDrop: (guid: string, index: number, sourceLevel: boolean) =>
      dispatch({ type: 'Transforms.reorder', guid, toIndex: index, sourceLevel }),
    onSelect: (guid: string) => dispatch({ type: 'Transforms.select', guid }),
    onReset: (guid: string) => dispatch({ type: 'Transforms.reset', guid }),
    onUsePreview: () => dispatch({ type: 'Records.useRowProvider', provider: PREVIEW }),
    onSetCutoff: (guid: string) => dispatch({ type: 'Transforms.setPreviewCutoff', guid }),
    onSetCutoffHover: (guid?: string) => dispatch({ type: 'Transforms.setPreviewCutoffHover', guid }),
    onRefetch: () => dispatch(fetchTransactions()),
    onPromptConfirmDeleteTransform: (guid: string) =>
      dispatch({ type: 'Transforms.promptConfirmDeleteTransform', guid }),
    onDone: () => dispatch({ type: 'Transforms.deselectAll' }),
  };
};

const connectedBulkTransformConnection = (connector: DragSourceConnector) => {
  return { connectDragSource: connector.dragSource() };
};

interface ownProps {
  currentIndex: number,
  guid: string,
  resetAdditionalAddTransformSelector: () => void,
  sourceLeveL: boolean,
}

type ConnectedBulkTransformProps =
  ownProps &
  ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch> &
  ReturnType<typeof connectedBulkTransformConnection>;

const ConnectedBulkTransform = _.compose(
  connect(mapState, mapDispatch),
  DragSource(
    'transform',
    {
      canDrag: ({ userIsCurator }) => {
        return userIsCurator;
      },
      beginDrag: ({ guid, onBeginDrag, currentIndex, sourceLevel }) => {
        // chrome repaint bug, cancels drag event unless you add a timeout
        // https://github.com/react-dnd/react-dnd/issues/477
        setTimeout(() => onBeginDrag(guid), 0);
        return { guid, currentIndex, sourceLevel };
      },
      endDrag: ({ onEndDrag, onReorderDrop }, monitor) => {
        const dropResult = monitor.getDropResult();
        if (dropResult) {
          const { guid, index, sourceLevel } = dropResult;
          return onReorderDrop(guid, index, sourceLevel);
        }
        return onEndDrag();
      },
    },
    connector => ({
      connectDragSource: connector.dragSource(),
    }),
  ),
)(BulkTransformRenderer);

export default ConnectedBulkTransform;
