import { is } from 'immutable';
import { connect } from 'react-redux';
import { createSink, shouldUpdate } from 'recompose';
import _ from 'underscore';

import { selectActiveProjectInfo } from '../utils/Selectors';
import { getPath } from '../utils/Values';
import { fetchTransactions } from './RecordsApi';
import { getFilterInfo } from './RecordsStore';
import { ES } from './RowProviders';

// TODO switch over to using createLoader()
//   this component has more complex logic than basic createLoader() cases,
//   but we may be able to simplify the logic to fit into the canFetch vs. shouldFetch scheme
const RecordsLoader = _.compose(
  connect(state => {
    const { records: { loadedFilterInfo, loading, datasetsToProfile, finishedProfiling }, transforms: { loadedDatasetId } } = state;
    const projectInfo = selectActiveProjectInfo(state);
    const currentFilterInfo = getFilterInfo(state);
    const fetchAfterProfile = !datasetsToProfile.isEmpty() && datasetsToProfile.toSet().isSubset(finishedProfiling);
    const shouldFetch = !is(currentFilterInfo, loadedFilterInfo) || fetchAfterProfile;
    return {
      shouldFetch,
      usingES: currentFilterInfo.get('provider') === ES,
      loading,
      projInfoLoaded: !!projectInfo && projectInfo.unifiedDatasetDoc && loadedDatasetId && projectInfo.unifiedDatasetId === loadedDatasetId,
      datasetIsIndexed: getPath(projectInfo, 'isUnifiedDatasetIndexed'),
    };
  }, {
    onFetchTransactions: fetchTransactions,
  }),
  shouldUpdate((props, nextProps) => !_.every(props, (v, k) => is(v, nextProps[k]))),
)(createSink(({ projInfoLoaded, datasetIsIndexed, usingES, shouldFetch, loading, onFetchTransactions }) => {
  if (projInfoLoaded && (!usingES || datasetIsIndexed) && shouldFetch && !loading) {
    onFetchTransactions();
  }
}));

export default RecordsLoader;
