import React from 'react';

import TamrIcon from '../components/TamrIcon';

const ICON_SIZE = 21;

const SingleSuggestion = ({ suggestedAttrName }) => {
  return (
    <div
      className="suggestion"
      title={suggestedAttrName}
    >
      <TamrIcon iconName="tamr-icon-lightbulb" size={ICON_SIZE} className="recommendations-icon" />
      <span className="suggestion-label">{suggestedAttrName}</span>
    </div>
  );
};

export default SingleSuggestion;
