import { List, Map, OrderedMap } from 'immutable';
import _, { isArray, isBoolean, isNumber, isString } from 'underscore';

import { ArgTypes, checkArg } from './ArgValidation';
import { $TSFixMe, JsonContent } from './typescript';

// to guard against "undefined" being redefined in scope
const undef = () => void (0);

/**
 * Implement the generic hashCode method which generates a numeric hashCode value for the object.
 * Lifted from the java.lang.String.hashCode implementation.
 */
const hashCode = (obj: $TSFixMe) => {
  // TODO: stringification is nondeterministic! use stable stringify.
  const stringifiedObject = JSON.stringify(obj);
  let hash = 0;
  if (stringifiedObject.length === 0) {
    return hash;
  }
  for (let i = 0; i < stringifiedObject.length; i++) {
    const char = stringifiedObject.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash &= hash; // Convert to 32bit integer
  }
  return hash;
};

const pathHelper = (obj: $TSFixMe, path: $TSFixMe, returnValue = false): $TSFixMe => {
  if (_.isEmpty(path)) {
    return returnValue ? obj : true;
  }
  const attr = path.shift();
  // handle immutable maps the same as objects
  if (Map.isMap(obj) && obj.has(attr)) {
    return pathHelper(obj.get(attr), path, returnValue);
  }
  if (_.isObject(obj) && (attr in obj)) {
    return pathHelper(obj[attr], path, returnValue);
  }
  return returnValue ? undefined : false;
};

/**
 * Checks to see whether a given sequence of keys all exist in an object.
 * This is meant to help with cases where we are writing code like:
 *
 * if (obj && obj.key && obj.key.anotherKey) { ... }
 *
 * to avoid hitting NPEs. This type of code is harmful because it is unnecessarily
 * verbose and doesn't correctly handle falsy terminal values.
 *
 * The example above would be expressed with the following:
 *
 * if(pathExists(obj, 'key', 'anotherKey')) { ... }
 *
 * @param  obj Arbitrary object
 * @param  path Vararg parameters describing the path through the object
 * @return whether the path exists or not
 */
const pathExists = (obj: $TSFixMe, ...path: $TSFixMe) => {
  return pathHelper(obj, path, false);
};

/**
 * Gets the result of applying the given sequence of keys to an object, or
 * undefined if one of the keys does not exist.
 *
 * @param  obj Arbitrary object
 * @param  path Vararg parameters describing the path through the object
 * @return the value at the path, if it exists, or undefined
 */
const getPath = (obj: $TSFixMe, ...path: $TSFixMe) => {
  return pathHelper(obj, path, true);
};

const getIntOrUndef = (str: $TSFixMe) => {
  const parsed = parseInt(str, 10);
  return _.isFinite(parsed) ? parsed : undef();
};

const noop: (...args: any[]) => void = () => {};

const equals = (val: $TSFixMe) =>
  (item: $TSFixMe) => item === val;

const objEquals = (a: $TSFixMe, b: $TSFixMe) => {
  // check for nullability first
  if (typeof (a) !== typeof (b)) {
    return false;
  }
  return a.equals(b);
};

const compareStrings = (a: string, b: string) => a.localeCompare(b);

const compareNumbers = (a: number, b: number) => a - b;

/**
 * Converts an immutable List<V> into a Map<K, V> by mapping each value V to a key K using the provided fn.
 */
export function indexBy<V, K>(col: List<V>, fn: (val: V) => K): Map<K, V> {
  return col.reduce((rn, val) => rn.set(fn(val), val), Map<K, V>());
}

export const orderedIndexBy = (col: $TSFixMe, fn: $TSFixMe) => col.reduce((rn: $TSFixMe, val: $TSFixMe) => rn.set(fn(val), val), OrderedMap());

export const moveIndex = (col: $TSFixMe, i1: number, i2: number) => col.delete(i1).insert(i2, col.get(i1));

export const get = (name: string) => {
  return (d: $TSFixMe) => d[name];
};

export const toggleHas = (col: $TSFixMe, v: $TSFixMe) => (col.has(v) ? col.delete(v) : col.add(v));

/**
 * Search for searchValue within str
 */
export const textMatch = (str: string, searchValue: string) => str.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;

export const resetExcept = (collection: { reduce: Function }, fieldsToPreserve: $TSFixMe) => {
  return collection.reduce((memo: $TSFixMe, value: $TSFixMe, key: $TSFixMe) => (fieldsToPreserve.includes(key) ? memo : memo.delete(key)), collection);
};

export const resetFields = (collection: { reduce: Function }, fieldsToReset: string[]) => {
  return collection.reduce((memo: $TSFixMe, value: $TSFixMe, key: $TSFixMe) => (fieldsToReset.includes(key) ? memo.delete(key) : memo), collection);
};

export const sliceBounds = (a: number, b: number): [number, number] => [Math.min(a, b), Math.max(a, b) + 1];

// joinWithUltimate(List(['a', 'b', 'c', 'd']), ', ', ', and ')
// --> 'a, b, c, and d'
export const joinWithUltimate = (input: $TSFixMe, joiner: $TSFixMe, ultimateJoiner: $TSFixMe, ifTwoJoinerInput: $TSFixMe) => {
  checkArg({ input }, ArgTypes.oneOf(ArgTypes.Immutable.list, ArgTypes.Immutable.set));
  const ifTwoJoiner = ifTwoJoinerInput || ultimateJoiner;
  const list = input.toList();
  const { size } = list;
  if (size < 2) {
    return list.join('');
  }
  if (size === 2) {
    return list.join(ifTwoJoiner);
  }
  return list.interpose(joiner).set(-2, ultimateJoiner).join('');
};

export function isDefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}
export function isNotNull<T>(value: T | null): value is T {
  return value !== null;
}
export function isNotEmpty<T>(value: T | null | undefined): value is T {
  return isDefined(value) && isNotNull(value);
}

export function isPrimitive(value: unknown): value is string | boolean | number | undefined | null {
  if (value === undefined) return true;
  if (value === null) return true;
  if (isString(value)) return true;
  if (isBoolean(value)) return true;
  if (isNumber(value)) return true;
  return false;
}

export function isObject(data: unknown): data is { [key: string ]: unknown } {
  return !!data && data instanceof Object;
}

/**
 * cast unknown json data to a string. this may be user-uploaded data. this accounts for all types expressable in JSON.
 * arrays are simply joined with commas, and objects are json stringified
 * therefore this should not be used where we want to be very intentional about how to show those complex types.
 * mainly useful if the user data is assumed to be a string (like b/c it's going to be used as a pk, say),
 *   but the UI shouldn't break if it's not a string.
 */
export function jsonContentToString(value: JsonContent): string {
  if (isPrimitive(value)) return String(value);
  if (isArray(value)) return value.join(', ');
  return JSON.stringify(value);
}

export function isArrayOf<T>(data: unknown, elementCheck: (element: unknown) => element is T): data is T[] {
  return isArray(data) && data.every(elementCheck);
}

/**
 * Given an array of elements of unknown type, filters the elements to only those that conform to the given element check.
 * @param data an array with elements of unknown type
 * @param elementCheck a predicate over the elements of the data array. if true, that element is confirmed to conform to the desired type.
 * @param onFailureToConform called when the predicate fails for any element. could be used to emit a warning.
 */
export function filterToConformToType<T>(
  data: unknown[],
  elementCheck: (element: unknown) => element is T,
  onFailureToConform: (element: unknown) => void,
): T[] {
  return data.filter(element => {
    if (elementCheck(element)) {
      return true;
    }
    onFailureToConform(element);
    return false;
  }) as T[];
}

export {
  compareStrings,
  compareNumbers,
  getIntOrUndef,
  undef,
  equals,
  objEquals,
  noop,
  pathExists,
  getPath,
  hashCode,
};
