import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import style from './TransformationStepsButton.module.scss';

const TransformationStepsButton = _.compose(
  connect(({ records }) => {
    const { expanded } = records;
    return { expanded };
  }, {
    onToggleExpandSidebar: () => ({ type: 'Records.toggleExpandSidebar' }),
  }),
)(class TransformationStepsButton extends React.Component {
  render() {
    const { expanded, onToggleExpandSidebar } = this.props;
    const buttonCss = classNames(style.toggleButton, {
      [style.openButton]: expanded,
    });

    return (
      <Button
        buttonType="Secondary"
        className={buttonCss}
        onClick={onToggleExpandSidebar}
      >
        {expanded ? 'Hide transformations' : 'Show transformations'}
      </Button>
    );
  }
});

export default TransformationStepsButton;
