import Immutable from 'immutable';
import _ from 'underscore';

import { $TSFixMe, Dictionary } from './typescript';

/**
 * ArgValidation tries to bring the type safety of prop type checking to non-component scenarios.
 *
 * This is the second iteration of it, new form factor for checkers and stuff.
 *
 * if a checker returns an object, that's meant to signify it failed.  undefined means passed.
 * keys that the object can contain:
 *   "noun"    : (required, string) helps upstream callers identify what it was trying to match.
 *   "message" : (optional, string) should totally override upstream warning.
 *   "badValue": (optional, string) should override upstream reports of what the passed in arg's value was.
 *   "argName" : (optional ,function) allows for checker functions to modify the argName reporting.
 */

/**
 * SR 11/20/19 Introducing TypeScript leaves this library in a funny state
 *   Some of the checkers here check types, and those are wholly deprecated by TypeScript
 *     (I've annotated those accordingly)
 *   Some of the checkers here check values, which cannot be done at compile time by TypeScript.
 *     These checkers are therefore not deprecated by TypeScript
 *   Some checkers, most of those that allow nested checkers, are only deprecated if the nested
 *     checkers check for values rather than types
 */

const JSON_STRINGIFY_UNDEFINED_AS_NULL = (obj: Object) => JSON.stringify(obj, (_k, v) => (v === undefined ? null : v));

export interface FailedValidationResult {
  noun?: string
  message?: string
  badValue?: any
  value?: any
  argName?: (name: string) => string
  index?: number
  messageSupplier?: (argName: string) => string
  warning?: string
}
export type ValidationResult = FailedValidationResult | null
export type Checker<T> = (arg: T) => ValidationResult;

/**
 * Utility types for converting a map of Checkers to a TS type
 */
export type UntypedCheckerMap = {
  [key: string]: Checker<any>
}
type InferCheckerType<C extends Checker<any>>
  = C extends Checker<infer T> ? T : never;
export type InferCheckerMapType<T extends UntypedCheckerMap>
  = { [K in keyof T]-?: InferCheckerType<T[K]>}

// TODO we may want to attachBadClassName to all bad values automatically
const getClassName = (obj?: { constructor: Function }): string => {
  if (obj === undefined || obj === null) {
    return '';
  }
  return obj.constructor.name;
};
const attachBadClassName = (status: FailedValidationResult, arg: { constructor: Function }): FailedValidationResult => {
  const argClassName = getClassName(arg);
  if (argClassName) {
    status.badValue = `${arg} (${argClassName})`;
  }
  return status;
};

// any
// deprecated by TypeScript
const any: Checker<any> = () => {
  return null;
};

// eq
// checks equality with a value
function eq<T>(val: T): ((arg: T) => ValidationResult) {
  return function (arg: T) {
    if (arg !== val) {
      return { noun: `a value equaling ${val}` };
    }
    return null;
  };
}

// string
// deprecated by TypeScript
const baseStringChecker = (arg: string): ValidationResult => {
  if (!_.isString(arg)) {
    return { noun: 'a string' };
  }
  return null;
};
const stringStartingWith = function (prefix: string): Checker<string> {
  const prefixCheckerStatus = baseStringChecker(prefix);
  return function (arg: string): ValidationResult {
    if (prefixCheckerStatus) {
      return { message: `Input to ArgTypes.string.startingWith must be a string, found ${prefix}` };
    }
    const argIsStringCheckerStatus = baseStringChecker(arg);
    if (argIsStringCheckerStatus) {
      return argIsStringCheckerStatus;
    }
    if (!arg.startsWith(prefix)) {
      return { noun: `a string starting with '${prefix}'` };
    }
    return null;
  };
};
const string = Object.assign(
  baseStringChecker,
  {
    startingWith: stringStartingWith,
  },
);

// deprecated by TypeScript
const bool = function (arg: boolean): ValidationResult {
  if (arg !== false && arg !== true) {
    return { noun: 'a boolean' };
  }
  return null;
};

// number
// deprecated by TypeScript
const baseNumberChecker = function (arg: number): ValidationResult {
  if (!_.isNumber(arg)) {
    return { noun: 'a number' };
  }
  return null;
};
const numberInRange = function (lowerBound: number, upperBound: number): Checker<number> {
  const lowerBoundCheckerStatus = baseNumberChecker(lowerBound);
  const upperBoundCheckerStatus = baseNumberChecker(upperBound);
  return function (arg: number): ValidationResult {
    if (lowerBoundCheckerStatus || upperBoundCheckerStatus) {
      return { message: 'Inputs to ArgTypes.number.inRange must be numbers'
        + `, found ${lowerBound}, ${upperBound}` };
    }
    const argIsANumberCheckerStatus = baseNumberChecker(arg);
    if (argIsANumberCheckerStatus) {
      return argIsANumberCheckerStatus;
    }
    if (arg < lowerBound || arg > upperBound) {
      return { noun: `a number between ${lowerBound} and ${upperBound} inclusively` };
    }
    return null;
  };
};
const numberLessThanOrEqualTo = function (comparisonValue: number): Checker<number> {
  const comparisonValueIsANumberCheckerStatus = baseNumberChecker(comparisonValue);
  return function (arg: number): ValidationResult {
    if (comparisonValueIsANumberCheckerStatus) {
      return { message: 'Input to ArgTypes.number.lessThanOrEqualTo must be a number'
        + `, found ${comparisonValue}` };
    }
    const argIsANumberCheckerStatus = baseNumberChecker(arg);
    if (argIsANumberCheckerStatus) {
      return argIsANumberCheckerStatus;
    }
    if (arg > comparisonValue) {
      return { noun: `a number less than or equal to ${comparisonValue}` };
    }
    return null;
  };
};
const number = Object.assign(
  baseNumberChecker,
  {
    inRange: numberInRange,
    lessThanOrEqualTo: numberLessThanOrEqualTo,
  },
);

// positiveInteger
const positiveInteger = function (arg: number): ValidationResult {
  if (!Number.isInteger(arg) || arg < 1) {
    return attachBadClassName(
      { noun: 'a positive integer' },
      arg,
    );
  }
  return null;
};

const wholeNumber = function (arg: number): ValidationResult {
  if (!Number.isInteger(arg) || arg < 0) {
    return attachBadClassName(
      { noun: 'a whole number (non-negative integer)' },
      arg,
    );
  }
  return null;
};

//
// date
const date = function (arg: Date): ValidationResult {
  if (!_.isDate(arg)) {
    return { noun: 'a date' };
  }
  return null;
};

//
// timestamp
const timestamp = function (arg: number): ValidationResult {
  if (!(_.isNumber(arg) && _.isDate(new Date(arg)))) {
    return { noun: 'a timestamp' };
  }
  return null;
};

// function
// deprecated by TypeScript
const func = function (arg: Function): ValidationResult {
  if (!_.isFunction(arg)) {
    return { noun: 'a function' };
  }
  return null;
};

// object
// deprecated by TypeScript
const baseObjectChecker = function (arg: Object): ValidationResult {
  if (!_.isObject(arg)) {
    return { noun: 'an object' };
  }
  return null;
};
// ensures all values in given object satisfy given checker
// eg. ArgTypes.object.of(ArgTypes.string) ensures that all values in a given object are strings
// deprecated by TypeScript only in cases where nested checker is also deprecated
const objectOf = function<T> (nestedChecker: Checker<T>): Checker<Dictionary<T>> {
  const nestedCheckerCheckerStatus = func(nestedChecker);
  return function (arg: {[key: string]: T}): ValidationResult {
    // check configuration is valid
    if (nestedCheckerCheckerStatus) {
      return { message: `Input to ArgTypes.object.of must be a checker function, found ${nestedChecker}` };
    }
    // check input is an object
    const objectCheckerStatus = baseObjectChecker(arg);
    if (objectCheckerStatus) { return objectCheckerStatus; }
    // check every value
    const badStatuses = {};
    for (const field in arg) {
      if (Object.prototype.hasOwnProperty.call(arg, field)) {
        const val = arg[field];
        const nestedStatus = nestedChecker(val);
        if (nestedStatus) {
          nestedStatus.value = val;
          // @ts-expect-error
          badStatuses[field] = nestedStatus;
        }
      }
    }
    if (!_.isEmpty(badStatuses)) {
      // @ts-expect-error
      const partialFoundValues = _.mapObject(badStatuses, (status) => status.value);
      return {
        // @ts-expect-error
        noun: `an object with each value being ${badStatuses[_.keys(badStatuses)[0]].noun}`,
        badValue: `violations ${JSON_STRINGIFY_UNDEFINED_AS_NULL(partialFoundValues)}`,
      };
    }
    return null;
  };
};
// deprecated by TypeScript only in cases where nested checkers are also deprecated
const objectWithShape = function <T extends UntypedCheckerMap> (obj: T): Checker<InferCheckerMapType<T>> {
  const typeObjectCheckerStatus = baseObjectChecker(obj);
  const allValuesAreFunctionsCheckerStatus = objectOf(func)(obj);
  return function (arg: {[key: string]: any}): ValidationResult {
    if (typeObjectCheckerStatus) {
      return { message: `Input to ArgTypes.object.withShape must be an object, found ${obj}` };
    }
    if (allValuesAreFunctionsCheckerStatus) {
      return { message: `Values of input to ArgTypes.object.withShape must be functions, found ${allValuesAreFunctionsCheckerStatus.badValue}` };
    }
    const argObjectCheckerStatus = baseObjectChecker(arg);
    if (argObjectCheckerStatus) {
      return argObjectCheckerStatus;
    }
    const nestedCheckerStatuses = _.mapObject(obj, (keyArgType, key) => {
      return keyArgType(arg[key]);
    });
    const badStatuses = _.filter(nestedCheckerStatuses, status => status !== null);
    if (!_.isEmpty(badStatuses)) {
      const partialExpectedShape: {[key: string]: any} = {};
      const partialFoundValues: {[key: string]: any} = {};
      _.each(nestedCheckerStatuses, (status, key) => {
        if (status) {
          partialExpectedShape[key] = status.noun;
          partialFoundValues[key] = arg[key];
        }
      });
      return {
        noun: `an object with (partial) shape ${JSON.stringify(partialExpectedShape)}`,
        badValue: `violations ${JSON.stringify(partialFoundValues)}`,
      };
    }
    return null;
  };
};
const object = Object.assign(
  baseObjectChecker,
  {
    of: objectOf,
    withShape: objectWithShape,
  },
);

// array
// deprecated by TypeScript
const baseArrayChecker = function (arg: Array<any>): ValidationResult {
  if (!_.isArray(arg)) {
    return { noun: 'an array' };
  }
  return null;
};
// deprecated by TypeScript only in cases where nested checker is also deprecated
const arrayOf = function<T> (nestedChecker: Checker<T>): Checker<Array<T>> {
  const nestedCheckerCheckerStatus = func(nestedChecker);
  return function (arg: Array<T>): ValidationResult {
    if (nestedCheckerCheckerStatus) {
      return { message: `Input to ArgTypes.array.of must be a checker function, found ${nestedChecker}` };
    }
    const arrayCheckerStatus = baseArrayChecker(arg);
    if (arrayCheckerStatus) { return arrayCheckerStatus; }
    const nestedCheckerStatuses = arg.map((element: $TSFixMe) => {
      const nestedStatus = nestedChecker(element);
      if (nestedStatus) {
        nestedStatus.value = element;
      }
      return nestedStatus;
    });
    const badStatuses = _.filter(nestedCheckerStatuses, status => status !== null && status !== undefined);
    if (!_.isEmpty(badStatuses)) {
      const argName = (argN: string) => `all elements of ${argN}`;
      const badValues = badStatuses.map(badStatus => (badStatus && badStatus.value));
      return {
        noun: (badStatuses[0] || {}).noun,
        argName,
        badValue: badValues,
      };
    }
    return null;
  };
};
// tests elements of array against ordered checkers
const arrayTuple = function (...nestedCheckers: Checker<any>[]): Checker<Array<any>> {
  return function (arg: Array<any>): ValidationResult {
    const arrayCheckerStatus = baseArrayChecker(arg);
    if (arrayCheckerStatus) { return arrayCheckerStatus; }
    const nestedCheckerStatuses = arg.map((element, i) => {
      const nestedStatus = nestedCheckers[i](element);
      if (nestedStatus) {
        nestedStatus.index = i;
        nestedStatus.value = element;
      }
      return nestedStatus;
    });
    const badStatuses = _.filter(nestedCheckerStatuses, status => status !== null && status !== undefined);
    if (!_.isEmpty(badStatuses)) {
      const messageSupplier = (argName: string) => {
        const badStatusMessages = badStatuses.map(badStatus => (
          `Expected element at array index ${badStatus && badStatus.index} to be ${badStatus && badStatus.noun}, found ${badStatus && badStatus.value}`
        ));
        return `${argName}: ${badStatusMessages.join('; ')}`;
      };
      return { messageSupplier };
    }
    return null;
  };
};
const array = Object.assign(
  baseArrayChecker,
  {
    of: arrayOf,
    tuple: arrayTuple,
  },
);

// oneOf
// varArg method for multiple ArgTypes
function oneOf<T1, T2>(checker1: Checker<T1>, checker2: Checker<T2>): Checker<T1 | T2>;
function oneOf<T1, T2, T3>(checker1: Checker<T1>, checker2: Checker<T2>, checker3: Checker<T3>): Checker<T1 | T2 | T3>;
function oneOf<T1, T2, T3, T4>(checker1: Checker<T1>, checker2: Checker<T2>, checker3: Checker<T3>, checker4: Checker<T4>): Checker<T1 | T2 | T3 | T4>;
function oneOf<T1, T2, T3, T4, T5>(checker1: Checker<T1>, checker2: Checker<T2>, checker3: Checker<T3>, checker4: Checker<T4>, checker5: Checker<T5>): Checker<T1 | T2 | T3 | T4 | T5>;
function oneOf<T1, T2, T3, T4, T5, T6>(checker1: Checker<T1>, checker2: Checker<T2>, checker3: Checker<T3>, checker4: Checker<T4>, checker5: Checker<T5>, checker6: Checker<T6>): Checker<T1 | T2 | T3 | T4 | T5 | T6>;
function oneOf<T1, T2, T3, T4, T5, T6, T7>(checker1: Checker<T1>, checker2: Checker<T2>, checker3: Checker<T3>, checker4: Checker<T4>, checker5: Checker<T5>, checker6: Checker<T6>, checker7: Checker<T7>): Checker<T1 | T2 | T3 | T4 | T5 | T6 | T7>;
function oneOf<T1, T2, T3, T4, T5, T6, T7, T8>(checker1: Checker<T1>, checker2: Checker<T2>, checker3: Checker<T3>, checker4: Checker<T4>, checker5: Checker<T5>, checker6: Checker<T6>, checker7: Checker<T7>, checker8: Checker<T8>): Checker<T1 | T2 | T3 | T4 | T5 | T6 | T7 | T8>;
function oneOf<T1, T2, T3, T4, T5, T6, T7, T8, T9>(checker1: Checker<T1>, checker2: Checker<T2>, checker3: Checker<T3>, checker4: Checker<T4>, checker5: Checker<T5>, checker6: Checker<T6>, checker7: Checker<T7>, checker8: Checker<T8>, checker9: Checker<T9>): Checker<T1 | T2 | T3 | T4 | T5 | T6 | T7 | T8 | T9>;
function oneOf<T1, T2, T3, T4, T5, T6, T7, T8, T9, T10>(checker1: Checker<T1>, checker2: Checker<T2>, checker3: Checker<T3>, checker4: Checker<T4>, checker5: Checker<T5>, checker6: Checker<T6>, checker7: Checker<T7>, checker8: Checker<T8>, checker9: Checker<T9>, checker10: Checker<T10>): Checker<T1 | T2 | T3 | T4 | T5 | T6 | T7 | T8 | T9 | T10>;
function oneOf<T1, T2, T3, T4, T5, T6, T7, T8, T9, T10, T11>(checker1: Checker<T1>, checker2: Checker<T2>, checker3: Checker<T3>, checker4: Checker<T4>, checker5: Checker<T5>, checker6: Checker<T6>, checker7: Checker<T7>, checker8: Checker<T8>, checker9: Checker<T9>, checker10: Checker<T10>, checker11: Checker<T11>): Checker<T1 | T2 | T3 | T4 | T5 | T6 | T7 | T8 | T9 | T10 | T11>;
function oneOf<T1, T2, T3, T4, T5, T6, T7, T8, T9, T10, T11, T12>(checker1: Checker<T1>, checker2: Checker<T2>, checker3: Checker<T3>, checker4: Checker<T4>, checker5: Checker<T5>, checker6: Checker<T6>, checker7: Checker<T7>, checker8: Checker<T8>, checker9: Checker<T9>, checker10: Checker<T10>, checker11: Checker<T11>, checker12: Checker<T12>): Checker<T1 | T2 | T3 | T4 | T5 | T6 | T7 | T8 | T9 | T10 | T11 | T12>;
function oneOf<T1, T2, T3, T4, T5, T6, T7, T8, T9, T10, T11, T12>(checker1: Checker<T1>, checker2: Checker<T2>, checker3: Checker<T3>, checker4: Checker<T4>, checker5: Checker<T5>, checker6: Checker<T6>, checker7: Checker<T7>, checker8: Checker<T8>, checker9: Checker<T9>, checker10: Checker<T10>, checker11: Checker<T11>, checker12: Checker<T12>, ...additionalCheckers: Checker<any>[]): Checker<any>;
function oneOf<T1, T2, T3, T4, T5, T6, T7, T8, T9, T10, T11, T12>(
  checker1: Checker<T1>,
  checker2: Checker<T2>,
  checker3?: Checker<T3>,
  checker4?: Checker<T4>,
  checker5?: Checker<T5>,
  checker6?: Checker<T6>,
  checker7?: Checker<T7>,
  checker8?: Checker<T8>,
  checker9?: Checker<T9>,
  checker10?: Checker<T10>,
  checker11?: Checker<T11>,
  checker12?: Checker<T12>,
  ...additionalCheckers: Checker<any>[]
): Checker<T1> | Checker<T1 | T2> | Checker<T1 | T2 | T3> | Checker<T1 | T2 | T3 | T4> | Checker<T1 | T2 | T3 | T4 | T5> | Checker<T1 | T2 | T3 | T4 | T5 | T6> | Checker<T1 | T2 | T3 | T4 | T5 | T6 | T7> | Checker<T1 | T2 | T3 | T4 | T5 | T6 | T7 | T8> | Checker<T1 | T2 | T3 | T4 | T5 | T6 | T7 | T8 | T9> | Checker<T1 | T2 | T3 | T4 | T5 | T6 | T7 | T8 | T9 | T10> | Checker<T1 | T2 | T3 | T4 | T5 | T6 | T7 | T8 | T9 | T10 | T11> | Checker<T1 | T2 | T3 | T4 | T5 | T6 | T7 | T8 | T9 | T10 | T11 | T12> {
  const argTypes: Checker<any>[] = [checker1, checker2, checker3, checker4, checker5, checker6, checker7, checker8, checker9, checker10, checker11, checker12, ...additionalCheckers]
    .filter(x => !!x) as Checker<any>[];
  const badArgTypes = argTypes.filter(argType => !_.isFunction(argType));
  let message: string;
  if (!_.isEmpty(badArgTypes)) {
    message = `All arguments to ArgTypes.oneOf must be checker functions, found ${badArgTypes}`;
  }
  return function (arg: T1 | T2 | T3 | T4 | T5 | T6 | T7 | T8 | T9 | T10 | T11): ValidationResult {
    if (message) {
      return { message };
    }
    const statuses = argTypes.map(argType => argType(arg));
    const allStatusesFailed = !_.contains(statuses, null) && !_.contains(statuses, undefined);
    if (allStatusesFailed) {
      const nouns = statuses.map(status => (status && status.noun));
      let noun;
      if (nouns.length === 1) {
        noun = nouns[0];
      } else if (nouns.length === 2) {
        noun = nouns.join(' or ');
      } else {
        const lastNoun = nouns.pop();
        noun = `${nouns.join(', ')}, or ${lastNoun}`;
      }
      if (noun) {
        return { noun };
      }
      return null;
    }
    return null;
  };
}

// valueIn
// if supplied an object, ensures args are members of value set
// if supplied an array, ensures args are members of that array
// deprecated by TypeScript if the value set is an Enum
const valueIn = function<T> (obj: Array<T> | {[key: string]: T}): Checker<T> {
  const hasValuesCheckerStatus = oneOf(object, array)(obj);
  const valueSet = _.values(obj);

  return function (arg: any): ValidationResult {
    if (hasValuesCheckerStatus) {
      return { message: `ArgTypes.valueIn must be supplied with an object or an array, found ${obj}` };
    }
    if (!_.contains(valueSet, arg)) {
      return { noun: `a member of value set [${valueSet}]` };
    }
    return null;
  };
};

// null
// deprecated by TypeScript
const nullChecker = function (arg: null): ValidationResult {
  if (arg !== null) {
    return { noun: 'null' };
  }
  return null;
};

// deprecated by TypeScript
const undefinedChecker = function (arg: undefined): ValidationResult {
  if (arg !== undefined) {
    return { noun: 'undefined' };
  }
  return null;
};

// missing (null or undefined)
// deprecated by TypeScript
const missing = function (arg: undefined | null): ValidationResult {
  if (arg !== null && arg !== undefined) {
    return { noun: 'missing (null or undefined)' };
  }
  return null;
};

// deprecated by TypeScript
const nullable = <T>(checker: Checker<T>) => oneOf(missing, checker);
// deprecated by TypeScript
const orNull = <T>(checker: Checker<T>) => oneOf(nullChecker, checker);
// deprecated by TypeScript
const orUndefined = <T>(checker: Checker<T>) => oneOf(undefinedChecker, checker);

// instanceOf
// deprecated by TypeScript
// NB: in Javascript usage, sometimes clazz is supplied as a string class name. That is not allowed in TS.
const instanceOf = function<T extends { new (...args: any): InstanceType<T> }>(clazz: T): Checker<InstanceType<T>> {
  let clazzName: string;
  let warning: string | undefined;
  if (clazz instanceof Function && clazz.name) {
    clazzName = clazz.name;
  } else {
    clazzName = `${clazz}`;
    warning = `ArgTypes.instanceOf must be supplied a class with class.name, found ${clazz} (Hint: did you forget to include 'new' when using the Model)`;
  }
  return function (arg): ValidationResult {
    if (warning) {
      return { message: warning };
    }
    if (clazz instanceof Function && !(arg as unknown instanceof clazz)) {
      return attachBadClassName(
        { noun: `an instance of ${clazzName}` },
        arg as any,
      );
    }
    return null;
  };
};

// deprecated by TypeScript
const className = function (clazzName: string): Checker<any> {
  let warning: string | undefined;
  if (!_.isString(clazzName)) {
    warning = `ArgTypes.className must be supplied a string className, found ${clazzName}`;
  }
  return function (arg): ValidationResult {
    if (warning) {
      return { message: warning };
    }
    if (getClassName(arg) !== clazzName) {
      return attachBadClassName(
        { noun: `a class with the constructor's name being '${clazzName}'` },
        arg,
      );
    }
    return null;
  };
};

// deprecated by TypeScript
const basePromiseChecker = function (arg: Promise<any>): ValidationResult {
  if (!(arg instanceof Promise)) {
    return { noun: 'a promise object', badValue: JSON.stringify(arg) };
  }
  return null;
};
// deprecated by TypeScript only in cases where nested checker is also deprecated
const promiseWithResolution = function<T> (resolutionChecker: Checker<T>): Checker<Promise<T>> {
  return function (arg: Promise<T>): ValidationResult {
    if (!_.isFunction(resolutionChecker)) {
      return { message: `ArgTypes.promise.withResolution must be supplied a checker function, found ${resolutionChecker}` };
    }
    const promiseCheckerState = basePromiseChecker(arg);
    if (promiseCheckerState) {
      return promiseCheckerState;
    }
    arg.then((resolutionOfPromise) => {
      checkArg({ resolutionOfPromise }, resolutionChecker); // eslint-disable-line no-use-before-define
    });
    return null;
  };
};
const promise = Object.assign(
  basePromiseChecker,
  {
    withResolution: promiseWithResolution,
  },
);

// SR 11/20/19 Using jQuery Deferreds/Ajax is deprecated by now in our codebase.
//             While introducing TypeScript I'm ignoring these with $TSFixMe
//             when we are TypeScript-ifying files that use these, we may first want to
//             switch them over to using fetch()
const baseDeferredChecker = function (arg: $TSFixMe): ValidationResult {
  if (!arg || !_.isFunction(arg.promise)) {
    return { noun: 'a jQuery Deferred object' };
  }
  return null;
};
const deferredWithResolution = function (resolutionChecker: Checker<$TSFixMe>): Checker<$TSFixMe> {
  return function (arg: $TSFixMe): ValidationResult {
    if (!_.isFunction(resolutionChecker)) {
      return { warning: `ArgTypes.deferred.withResolution must be supplied a checker function, found ${resolutionChecker}` };
    }
    const deferredCheckerState = baseDeferredChecker(arg);
    if (deferredCheckerState) {
      return deferredCheckerState;
    }
    arg.then((resolutionOfDeferred: $TSFixMe) => {
      checkArg({ resolutionOfDeferred }, resolutionChecker); // eslint-disable-line no-use-before-define
    });
    return null;
  };
};
const deferred = Object.assign(
  baseDeferredChecker,
  {
    withResolution: deferredWithResolution,
  },
);

/**
 * Immutable
 */

function immutableElementChecker<T>(arg: Immutable.Collection<any, T>, nestedChecker: Checker<T>, elementNoun?: string): ValidationResult {
  const badStatuses = arg
    .map(element => {
      const nestedStatus = nestedChecker(element);
      if (nestedStatus) {
        nestedStatus.value = element;
      }
      return nestedStatus;
    })
    .filter(status => status !== null && status !== undefined)
    .valueSeq()
    .toArray();
  if (!_.isEmpty(badStatuses)) {
    const argName = (argN: string) => `all ${elementNoun || 'elements'} of ${argN}`;
    const badValues = badStatuses.map(badStatus => (badStatus && badStatus.value));
    const firstBadStatus = badStatuses[0];
    return {
      noun: firstBadStatus && firstBadStatus.noun || undefined,
      argName,
      badValue: badValues,
    };
  }
  return null;
}

// list
// deprecated by TypeScript
const baseImmutableListChecker = function (arg: Immutable.List<any>): ValidationResult {
  if (!Immutable.List.isList(arg)) {
    return { noun: 'an Immutable.List' };
  }
  return null;
};
// deprecated by TypeScript only in cases where nested checker is also deprecated
const immutableListOf = function<T> (nestedChecker: Checker<T>): Checker<Immutable.List<T>> {
  const nestedCheckerCheckerStatus = func(nestedChecker);
  return function (arg: Immutable.List<T>): ValidationResult {
    if (nestedCheckerCheckerStatus) {
      return { message: `Input to ArgTypes.Immutable.list.of must be a checker function, found ${nestedChecker}` };
    }
    const listCheckerStatus = baseImmutableListChecker(arg);
    if (listCheckerStatus) {
      return listCheckerStatus;
    }
    return immutableElementChecker(arg, nestedChecker);
  };
};
const immutableList = Object.assign(
  baseImmutableListChecker,
  {
    of: immutableListOf,
  },
);

// set
// deprecated by TypeScript
const baseImmutableSetChecker = function (arg: Immutable.Set<any>): ValidationResult {
  if (!Immutable.Set.isSet(arg)) {
    return { noun: 'an Immutable.Set' };
  }
  return null;
};
// deprecated by TypeScript only in cases where nested checker is also deprecated
const immutableSetOf = function<T> (nestedChecker: Checker<T>): Checker<Immutable.Set<T>> {
  const nestedCheckerCheckerStatus = func(nestedChecker);
  return function (arg: Immutable.Set<T>): ValidationResult {
    if (nestedCheckerCheckerStatus) {
      return { message: `Input to ArgTypes.Immutable.set.of must be a checker function, found ${nestedChecker}` };
    }
    const setCheckerStatus = baseImmutableSetChecker(arg);
    if (setCheckerStatus) {
      return setCheckerStatus;
    }
    return immutableElementChecker(arg, nestedChecker);
  };
};
const immutableSet = Object.assign(
  baseImmutableSetChecker,
  {
    of: immutableSetOf,
  },
);

// ordered set
// deprecated by TypeScript
const baseImmutableOrderedSetChecker = function (arg: Immutable.OrderedSet<any>): ValidationResult {
  if (!Immutable.OrderedSet.isOrderedSet(arg)) {
    return { noun: 'an Immutable.OrderedSet' };
  }
  return null;
};
// deprecated by TypeScript only in cases where nested checker is also deprecated
const immutableOrderedSetOf = function<T> (nestedChecker: Checker<T>): Checker<Immutable.OrderedSet<T>> {
  const nestedCheckerCheckerStatus = func(nestedChecker);
  return function (arg: Immutable.OrderedSet<T>): ValidationResult {
    if (nestedCheckerCheckerStatus) {
      return { message: `Input to ArgTypes.Immutable.orderedSet.of must be a checker function, found ${nestedChecker}` };
    }
    const setCheckerStatus = baseImmutableOrderedSetChecker(arg);
    if (setCheckerStatus) {
      return setCheckerStatus;
    }
    return immutableElementChecker(arg, nestedChecker);
  };
};
const immutableOrderedSet = Object.assign(
  baseImmutableOrderedSetChecker,
  {
    of: immutableOrderedSetOf,
  },
);

// map
// deprecated by TypeScript
const baseImmutableMapChecker = function (arg: Immutable.Map<any, any>): ValidationResult {
  if (!Immutable.Map.isMap(arg)) {
    return { noun: 'an Immutable.Map' };
  }
  return null;
};
// deprecated by TypeScript only in cases where nested checker is also deprecated
const immutableMapOf = function<K, V> (valueChecker: Checker<V>, keyChecker?: Checker<K>): Checker<Immutable.Map<K, V>> {
  return function (arg: Immutable.Map<K, V>): ValidationResult {
    const mapCheckerStatus = baseImmutableMapChecker(arg);
    if (mapCheckerStatus) {
      return mapCheckerStatus;
    }
    const valueCheckerStatus = immutableElementChecker(Immutable.List(arg.values()), valueChecker, 'values');
    if (valueCheckerStatus) {
      return valueCheckerStatus;
    }
    if (keyChecker) {
      const keyCheckerStatus = immutableElementChecker(Immutable.List(arg.keys()), keyChecker, 'keys');
      if (keyCheckerStatus) {
        return keyCheckerStatus;
      }
    }
    return null;
  };
}; // SR 11/20/19 does this signature need to account for multiple arguments?
const immutableMap = Object.assign(
  baseImmutableMapChecker,
  {
    of: immutableMapOf,
  },
);

// orderedMap
const baseImmutableOrderedMapChecker = function (arg: Immutable.OrderedMap<any, any>): ValidationResult {
  if (!Immutable.OrderedMap.isOrderedMap(arg)) {
    return { noun: 'an Immutable.OrderedMap' };
  }
  return null;
};
// deprecated by TypeScript only in cases where nested checker is also deprecated
const immutableOrderedMapOf = function<K, V> (valueChecker: Checker<V>, keyChecker: Checker<K>): Checker<Immutable.OrderedMap<K, V>> {
  return function (arg: Immutable.OrderedMap<K, V>): ValidationResult {
    const mapCheckerStatus = baseImmutableOrderedMapChecker(arg);
    if (mapCheckerStatus) {
      return mapCheckerStatus;
    }
    const valueCheckerStatus = immutableElementChecker(Immutable.List(arg.values()), valueChecker, 'values');
    if (valueCheckerStatus) {
      return valueCheckerStatus;
    }
    if (keyChecker) {
      const keyCheckerStatus = immutableElementChecker(Immutable.List(arg.keys()), keyChecker, 'keys');
      if (keyCheckerStatus) {
        return keyCheckerStatus;
      }
    }
    return null;
  };
};
const immutableOrderedMap = Object.assign(
  baseImmutableOrderedMapChecker,
  {
    of: immutableOrderedMapOf,
  },
);

const immutableCheckers = {
  list: immutableList,
  set: immutableSet,
  orderedSet: immutableOrderedSet,
  map: immutableMap,
  orderedMap: immutableOrderedMap,
};

function generateWarning(status: FailedValidationResult, initialArgName: string, arg: any): string {
  let warning: string;
  if (status.message) {
    warning = status.message;
  } else if (status.messageSupplier) {
    warning = status.messageSupplier(initialArgName);
  } else {
    let argName = initialArgName;
    argName = status.argName ? (status.argName && status.argName(argName)) : argName;
    const badValue = status.hasOwnProperty('badValue') ? status.badValue : arg;
    warning = `Expected ${argName} to be ${status.noun}, found ${badValue}`;
  }
  return warning;
}

let warningListener: Function = () => {};
let shouldSuppressLogging = false;

export const checkArg = function (argObject: any, checker: Checker<any>, options?: Object): boolean {
  if (process.env.NODE_ENV === 'production') {
    return true;
  }
  let warning;
  if (!_.isObject(argObject) || _.size(argObject) !== 1) {
    warning = `checkArg first parameter must be an object of size 1, found: ${argObject}`;
  } else if (!_.isFunction(checker)) {
    warning = `checkArg second parameter must be a function, found: ${checker}`;
  } else if (!_.isObject(options) && !!options) {
    warning = `checkArg third parameter must be an object or undefined, found ${options}`;
  } else {
    const pair = _.pairs(argObject)[0];
    const arg = pair[1];
    const status = checker(arg);
    if (status === null || status === undefined) {
      return true;
    }
    warning = generateWarning(status, pair[0], arg);
  }
  if (warning) {
    if (!shouldSuppressLogging) {
      console.warn(new Error(warning));
    }
    warningListener(warning);
    // UNCOMMENT the line below to stop at a breakpoint any time a checkArg fails
    // debugger;
    return false;
  }
  return true;
};

export const checkReturn = (checker: Checker<any>, fn: Function) => (...args: any[]) => {
  const ret = fn(...args);
  checkArg({ 'return value': ret }, checker);
  return ret;
};

export const onWarning = (listener: Function) => {
  if (!_.isFunction(listener)) {
    throw new Error('onWarning listener must be a function');
  }
  warningListener = listener;
};

export const ArgTypes = {
  any,
  eq,
  string,
  bool,
  number,
  positiveInteger,
  wholeNumber,
  date,
  timestamp,
  func,
  object,
  array,
  oneOf,
  valueIn,
  null: nullChecker,
  undefined: undefinedChecker,
  missing,
  nullable,
  orNull,
  orUndefined,
  instanceOf,
  className,
  promise,
  deferred,
  Immutable: immutableCheckers,
};

export const suppressLogging = (shouldSuppress: boolean) => (shouldSuppressLogging = shouldSuppress);
