import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Chrome from '../chrome/Chrome';
import CenterContent from '../components/CenterContent';
import DetailSidebar from '../components/DetailSidebar/DetailSidebar';
import LoadingPanel from '../components/LoadingPanel';
import Term from '../components/Term';
import ProcurementCategory from '../models/ProcurementCategory';
import ProcurementTaxonomy from '../models/ProcurementTaxonomy';
import ActionBar from '../taxonomy/ActionBar';
import BlankTaxonomyPage from '../taxonomy/BlankTaxonomyPage';
import CategorySidebarStats from '../taxonomy/CategorySidebarStats';
import defaultTaxonomyCell from '../taxonomy/DefaultTaxonomyCell';
import DetailsPane from '../taxonomy/DetailsPane';
import labelCountTaxonomyCell from '../taxonomy/LabelCountTaxonomyCell';
import spendTaxonomyCell from '../taxonomy/SpendTaxonomyCell';
import { addCategory, deleteCategorizations, deleteCategory, renameCategory } from '../taxonomy/TaxonomyApi';
import TaxonomyLoader from '../taxonomy/TaxonomyLoader';
import TaxonomyTitle from '../taxonomy/TaxonomyTitle';
import TaxonomyViewSelector from '../taxonomy/TaxonomyViewSelector';
import TierBrowser from '../taxonomy/TierBrowser';
import { isCuratorByProjectId } from '../utils/Authorization';
import { history } from '../utils/History';
import { getAuthorizedUser, selectActiveProjectInfo } from '../utils/Selectors';
import { getPath, textMatch } from '../utils/Values';
import { getActiveSpendField } from '../utils/Selectors';

const DISABLED_DELETE_TOOLTIP = () => <span>Only categories with no <Term>records</Term> assigned to them and with no subcategories with <Term>records</Term> assigned to them can be removed</span>;
const DISABLED_DELETE_CATEGORIZATION_TOOLTIP = () => <span>No <Term>parts</Term> have this categorization</span>;
const COMPARATORS = {
  alphabetical: (category) => (category.path.last()),
  spend: (category) => (category.manualCategorizations.spend +
    category.totalAggForSuggestedCategorizations.spend) * -1,
  labels: (category) => (category.manualCategorizations.count +
    category.totalAggForSuggestedCategorizations.count) * -1,
};
let defaultViewSet = true;

const Taxonomy = _.compose(
  connect(state => {
    const { location: { recipeId }, taxonomy: { taxonomy, loading, expanded, activeCategory, searchValue, viewType, tierLabelMaxCounts } } = state;
    return {
      recipeId,
      taxonomy,
      loading,
      expanded,
      activeCategory,
      searchValue,
      viewType,
      tierLabelMaxCounts,
      isUserACurator: isCuratorByProjectId(getAuthorizedUser(state), selectActiveProjectInfo(state)?.projectDoc.id.id),
      spend: getActiveSpendField(state),
    };
  }, {
    onDeleteCategorizations: deleteCategorizations,
    onDeleteCategory: deleteCategory,
    onAddCategory: addCategory,
    onRenameCategory: renameCategory,
    onSetSearchValue: searchValue => ({ type: 'Taxonomy.setSearchValue', searchValue }),
    onSetViewType: viewType => ({ type: 'Taxonomy.setViewType', viewType }),
    onClearSelection: () => ({ type: 'Taxonomy.clearSelection' }),
  }),
)(class Taxonomy extends React.Component {
  static propTypes = {
    activeCategory: PropTypes.instanceOf(ProcurementCategory),
    expanded: PropTypes.bool.isRequired,
    isUserACurator: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    onAddCategory: PropTypes.func.isRequired,
    onClearSelection: PropTypes.func.isRequired,
    onDeleteCategorizations: PropTypes.func.isRequired,
    onDeleteCategory: PropTypes.func.isRequired,
    onRenameCategory: PropTypes.func.isRequired,
    onSetSearchValue: PropTypes.func.isRequired,
    onSetViewType: PropTypes.func.isRequired,
    recipeId: PropTypes.number,
    searchValue: PropTypes.string,
    taxonomy: PropTypes.instanceOf(ProcurementTaxonomy),
    tierLabelMaxCounts: ImmutablePropTypes.mapOf(PropTypes.number, PropTypes.number),
    viewType: PropTypes.string.isRequired,
  };

  onSidebarCategoryClick = (id) => {
    history.push(`/category/recipe/${this.props.recipeId}/${id}`);
  };

  UNSAFE_componentWillMount() {
    const { onClearSelection } = this.props;
    onClearSelection();
  }

  getComparator = () => {
    const { viewType } = this.props;
    return COMPARATORS[viewType];
  };

  getFilter = (category) => {
    const { searchValue } = this.props;
    return !searchValue || textMatch(category.name, searchValue);
  };

  getCategoryCell = () => {
    const { taxonomy, viewType } = this.props;
    if (taxonomy) {
      switch (viewType) {
        case 'alphabetical': return defaultTaxonomyCell;
        case 'spend': return spendTaxonomyCell;
        default: return labelCountTaxonomyCell;
      }
    }
  };

  getSidebarWidget = (activeCategory) => {
    return (
      <CategorySidebarStats
        activeCategory={activeCategory}
      />
    );
  };

  canDeleteCategorizationsChecker = (activeCategory) => {
    return getPath(activeCategory, 'data', 'manualCategorizations', 'count') > 0;
  };

  canDeleteCategory = (activeCategory) => {
    return !getPath(activeCategory, 'data', 'manualCategorizations', 'count');
  };

  renderTaxonomyBody = () => {
    const { isUserACurator, taxonomy, loading, viewType, onDeleteCategorizations, onDeleteCategory, onAddCategory, onSetSearchValue, onSetViewType, onRenameCategory, searchValue, tierLabelMaxCounts, spend } = this.props;
    if (loading) {
      return <LoadingPanel semiTransparent={false} />;
    }

    if (!taxonomy || taxonomy.categories.isEmpty()) {
      if (isUserACurator) {
        return <BlankTaxonomyPage recipeId={this.props.recipeId} />;
      }
      return (
        <CenterContent>
          No Taxonomy loaded.  You do not have sufficient permissions to import a taxonomy.
        </CenterContent>
      );
    }
    if (spend && defaultViewSet) {
      onSetViewType('spend');
      defaultViewSet = false;
    }
    return (
      <div className="taxonomy-body">
        <TaxonomyTitle
          isUserACurator={isUserACurator}
          taxonomy={taxonomy}
          onSearch={onSetSearchValue}
          startingSearchQuery={this.props.searchValue}
          recipeId={this.props.recipeId}
        />
        <ActionBar />
        <TierBrowser
          comparator={this.getComparator()}
          filter={this.getFilter}
          categoryCellRenderer={this.getCategoryCell()}
          onCreateCategory={isUserACurator ? onAddCategory : undefined}
          onDeleteCategorizations={isUserACurator ? onDeleteCategorizations : undefined}
          onDeleteCategory={isUserACurator ? onDeleteCategory : undefined}
          canDeleteCategorizationsChecker={this.canDeleteCategorizationsChecker}
          onRenameCategory={isUserACurator ? onRenameCategory : undefined}
          canDeleteCategoryChecker={this.canDeleteCategory}
          disabledDeleteCategorizationsTooltip={DISABLED_DELETE_CATEGORIZATION_TOOLTIP}
          disabledDeleteTooltip={DISABLED_DELETE_TOOLTIP}
          searchString={searchValue}
          tierLabelMaxCounts={tierLabelMaxCounts}
        />
        <TaxonomyViewSelector
          onSelectionChange={onSetViewType}
          value={viewType}
        />
      </div>
    );
  };

  getSidebar = () => {
    const { expanded } = this.props;
    return (
      <DetailSidebar
        className="transaction-sidebar"
        isExpanded={expanded}
      >
        <DetailsPane
          onSidebarCategoryClick={this.onSidebarCategoryClick}
          sidebarWidgetRenderer={this.getSidebarWidget}
        />
      </DetailSidebar>
    );
  };

  render() {
    const { expanded } = this.props;

    return (
      <Chrome
        rightSidebar={this.getSidebar()}
        isExpanded={expanded}
      >
        {this.renderTaxonomyBody()}
        <TaxonomyLoader />
      </Chrome>
    );
  }
});

export default Taxonomy;
