/* eslint-disable react/no-danger */

import classNames from 'classnames';
import { List, Set } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import CategoryPath from '../components/CategoryPath';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Checkbox from '../components/Input/Checkbox';
import Input from '../components/Input/Input';
import SearchBox from '../components/SearchBox';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import TooltipTrigger from '../components/TooltipTrigger';
import DataTables from '../constants/DataTables';
import DisplayColumn from '../models/DisplayColumn';
import EsRecord from '../models/EsRecord';
import ProcurementCategory from '../models/ProcurementCategory';
import ProcurementTaxonomy from '../models/ProcurementTaxonomy';
import CategoryTree from '../taxonomy/CategoryTree';
import defaultTaxonomyCell from '../taxonomy/DefaultTaxonomyCell';
import labelCountTaxonomyCell from '../taxonomy/LabelCountTaxonomyCell';
import spendTaxonomyCell from '../taxonomy/SpendTaxonomyCell';
import { addCategory, deleteCategory, renameCategory } from '../taxonomy/TaxonomyApi';
import TaxonomyViewSelector from '../taxonomy/TaxonomyViewSelector';
import TierBrowser from '../taxonomy/TierBrowser';
import { isCuratorByProjectId, isVerifierByProjectId } from '../utils/Authorization';
import PRODUCT_NAME from '../utils/ProductName';
import { getActiveRecipe, getAuthorizedUser, getUDColumnSettingsForPage, selectActiveProjectInfo } from '../utils/Selectors';
import { getPath } from '../utils/Values';
import { categorize, deleteCategorizations, suggest } from './TransactionApi';

const DISABLE_DELETE_TOOLTIP = () => <span>Only categories with no <Term>records</Term> assigned to them and with no subcategories with <Term>records</Term> assigned to them can be removed</span>;
const COMPARATORS = {
  alphabetical: (category) => (category.path.last()),
  spend: (category) => (category.manualCategorizations.spend +
    category.totalAggForSuggestedCategorizations.spend) * -1,
  labels: (category) => (category.manualCategorizations.count +
    category.totalAggForSuggestedCategorizations.count) * -1,
};

@connect(
  state => {
    const {
      taxonomy: { taxonomy, activeCategory, searchValue, viewType, initialCategory, tierLabelMaxCounts },
      transactions: { showCategorizeDialog, categorizeDialogRecords },
    } = state;
    const recipe = getActiveRecipe(state);
    const user = getAuthorizedUser(state);
    const includedFields = new Set(getPath(recipe, 'metadata', 'CATEGORIZATION', 'includedFields'));
    const visibleFields = new Set(getPath(recipe, 'metadata', 'CATEGORIZATION', 'visibleFields'));
    return {
      taxonomyQuery: searchValue,
      taxonomySort: viewType,
      taxonomy,
      activeCategory,
      initialCategory,
      columnSettings: getUDColumnSettingsForPage(state, DataTables.RECORDS),
      tierLabelMaxCounts,
      username: user.username,
      isUserACurator: isCuratorByProjectId(user, selectActiveProjectInfo(state)?.projectDoc.id.id),
      isUserAVerifier: isVerifierByProjectId(user, selectActiveProjectInfo(state)?.projectDoc.id.id),
      show: showCategorizeDialog,
      records: categorizeDialogRecords,
      includedFields: includedFields || Set(),
      visibleFields: visibleFields || Set(),
    };
  },
  {
    onCategorize: categorize,
    onSuggest: suggest,
    onDeleteCategory: deleteCategory,
    onDeleteCategorizations: deleteCategorizations,
    onRenameCategory: renameCategory,
    onAddCategory: addCategory,
    onSetTaxonomyQuery: searchValue => ({ type: 'Taxonomy.setSearchValue', searchValue }),
    onSetTaxonomySort: viewType => ({ type: 'Taxonomy.setViewType', viewType }),
    onClearSelection: () => ({ type: 'Taxonomy.clearSelection' }),
    onHide: () => ({ type: 'Transactions.cancelCategorizeDialog' }),
  },
)
export default class CategorizeDialog extends React.Component {
  static propTypes = {
    columnSettings: ImmutablePropTypes.listOf(PropTypes.instanceOf(DisplayColumn)),
    includedFields: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
    initialCategory: PropTypes.instanceOf(ProcurementCategory),
    isUserACurator: PropTypes.bool.isRequired,
    isUserAVerifier: PropTypes.bool.isRequired,
    onAddCategory: PropTypes.func.isRequired,
    onCategorize: PropTypes.func.isRequired,
    onClearSelection: PropTypes.func.isRequired,
    onDeleteCategorizations: PropTypes.func,
    onDeleteCategory: PropTypes.func,
    onHide: PropTypes.func.isRequired,
    onRenameCategory: PropTypes.func,
    onSetTaxonomyQuery: PropTypes.func.isRequired,
    onSetTaxonomySort: PropTypes.func.isRequired,
    onSuggest: PropTypes.func.isRequired,
    records: ImmutablePropTypes.listOf(PropTypes.instanceOf(EsRecord)),
    show: PropTypes.bool.isRequired,
    taxonomy: PropTypes.instanceOf(ProcurementTaxonomy),
    taxonomyQuery: PropTypes.string,
    taxonomySort: PropTypes.string,
    tierLabelMaxCounts: ImmutablePropTypes.mapOf(PropTypes.number, PropTypes.number),
    username: PropTypes.string,
    visibleFields: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
  };

  state = {
    reason: undefined,
    markFinal: true,
  };

  hide = () => {
    const { onClearSelection, onHide } = this.props;
    onHide();
    onClearSelection();
    this.setState({
      reason: undefined,
      markFinal: true,
    });
  };

  handleSaveClick = () => {
    const { onCategorize, onSuggest, isUserACurator, isUserAVerifier, records, activeCategory } = this.props;
    const { reason, markFinal } = this.state;
    if (markFinal && (isUserACurator || isUserAVerifier)) {
      onCategorize({ records, categoryId: activeCategory.categoryId, reason });
    } else {
      onSuggest({ records, categoryId: activeCategory.categoryId, reason });
    }
    this.hide();
  };

  clearCategorization = () => {
    this.props.onDeleteCategorizations(this.props.records);
    this.hide();
  };

  renderHeader = () => {
    const { taxonomyQuery, onSetTaxonomyQuery } = this.props;
    const numTransactions = this.props.records.size;
    return (
      <Dialog.Header closeButton>
        Choose a category for {numTransactions !== 1 ? `these ${numTransactions}` : 'this'} <Term amount={numTransactions}>record</Term>
        <div className="categorize-dialog-search">
          <SearchBox placeholder="Search for categories" value={taxonomyQuery} onSearch={onSetTaxonomyQuery} />
        </div>
      </Dialog.Header>
    );
  };

  getComparator = () => {
    const { taxonomySort } = this.props;
    return COMPARATORS[taxonomySort];
  };

  getFilter = category => {
    if (this.props.taxonomyQuery) {
      const name = category.name;
      return name.toLowerCase().indexOf(this.props.taxonomyQuery.toLowerCase()) >= 0;
    }
    return true;
  };

  getCategoryCell = () => {
    const { taxonomy, initialCategory } = this.props;
    return (
      <TooltipTrigger
        className="category-tooltip"
        placement="bottom"
        content={
          <div className="category-tree-container">
            <CategoryTree {...{ taxonomy, category: initialCategory }} />
          </div>
        }>
        <div>
          <CategoryPath path={initialCategory.path} showTooltip={false} />
        </div>
      </TooltipTrigger>
    );
  };

  getTransactionSummary = () => {
    const record = this.props.records.first();
    if (!record) {
      return <div />;
    }
    const { initialCategory, taxonomy, columnSettings, includedFields, visibleFields } = this.props;
    const generatedAttributes = columnSettings ? columnSettings.filter(att => !visibleFields.contains(att.name)).map(att => (att.displayName)) : new List();
    const initCategoryColumnWidth = initialCategory ? 250 : 0;
    const orderedVisibleFields = (this.props.columnSettings || List()).filter(c => c.visible);
    const totalWidth = initCategoryColumnWidth + orderedVisibleFields.reduce((accumulator, setting) => accumulator + setting.width, 0);
    return (
      <div>
        <div className="transaction-detail-header">
          <Term>RECORD</Term> DETAIL
        </div>
        <div className="transaction-detail-scroll-container">
          <div className="transaction-detail-col-header" style={{ width: totalWidth }}>
            {initialCategory ? (
              <div className="field-header" style={{ width: initCategoryColumnWidth }} title="Current Categorization">
                Current Categorization
              </div>
            ) : (
              undefined
            )}
            {orderedVisibleFields.map(field => (
              <div key={field.name} className="field-header" style={{ width: field.width }} title={field.alias || field.name}>
                {includedFields.contains(field.name) ? (
                  <TooltipTrigger placement="top" content="This attribute is included in machine learning">
                    <TamrIcon size={12} iconName="tamr-icon-ml-on" className="tamrIconMlOn" />
                  </TooltipTrigger>
                ) : null}
                {generatedAttributes.contains(field.displayName) ? (
                  <TooltipTrigger placement="top" content={`This attribute was created by ${PRODUCT_NAME}`}>
                    <TamrIcon size={14} iconName="tamr-icon-logo" className="tamrIconLogo" />
                  </TooltipTrigger>
                ) : null}
                <span className="header-text">{field.alias || field.name}</span>
              </div>
            ))}
          </div>
          <div className="transaction-detail" style={{ width: totalWidth }}>
            {initialCategory ? (
              <span className="field-value" style={{ width: initCategoryColumnWidth }}>
                <TooltipTrigger
                  className="category-tooltip"
                  placement="bottom"
                  content={
                    <div className="category-tree-container">
                      <CategoryTree {...{ taxonomy, category: initialCategory }} />
                    </div>
                  }>
                  <div>
                    <CategoryPath path={initialCategory.path} showTooltip={false} />
                  </div>
                </TooltipTrigger>
              </span>
            ) : (
              undefined
            )}
            {orderedVisibleFields.map(field => {
              const data = record.getValue(field.name);
              if (data.renderRaw) {
                // Using raw value here since elastic html encodes the data for us in this case.
                // This allows us to display the text highlight
                return (
                  <span
                    key={field.name}
                    className="field-value"
                    style={{ width: field.width }}
                    dangerouslySetInnerHTML={{ __html: data.data }}
                    title={_.isArray(data.originalData) ? data.originalData.join(', ') : data.originalData}
                  />
                );
              }
              return (
                <span key={field.name} className="field-value" style={{ width: field.width }} title={_.isArray(data.data) ? data.data.join(', ') : data.data}>
                  {_.isArray(data.data) ? data.data.join(', ') : data.data}
                  &nbsp;
                </span>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  reasonChange = reason => {
    this.setState({ reason });
  };

  markFinalChange = markFinal => {
    this.setState({ markFinal });
  };

  renderReasonInput = () => {
    const classname = classNames('reason-input-section', {
      'has-is-final-checkbox': this.allowChoosingFinalOrNot(),
    });
    return (
      <div className={classname}>
        <div>Reason (optional):</div>
        <Input ref="reasonField" onChange={this.reasonChange} variant="light" title="Provide a reason for this categorization" value={this.state.reason} />
      </div>
    );
  };

  renderMarkFinal = () => {
    if (!this.allowChoosingFinalOrNot()) {
      return <div />;
    }
    return (
      <div className="mark-final-section">
        <Checkbox
          ref="markFinalField"
          onChange={this.markFinalChange}
          title="Verify this categorization" // (as opposed to suggesting to the curator)
          value={this.state.markFinal}
        />
      </div>
    );
  };

  allowChoosingFinalOrNot = () => {
    return (this.props.isUserACurator || this.props.isUserAVerifier);
  };

  getCellRenderer = () => {
    const { taxonomy, taxonomySort } = this.props;
    if (taxonomy) {
      switch (taxonomySort) {
        case 'alphabetical':
          return defaultTaxonomyCell;
        case 'spend':
          return spendTaxonomyCell;
        default:
          return labelCountTaxonomyCell;
      }
    }
  };

  renderBody = () => {
    const { isUserACurator, onAddCategory, onDeleteCategory, onRenameCategory, show, taxonomyQuery, tierLabelMaxCounts } = this.props;
    if (!show) {
      return;
    }
    return (
      <div className="taxonomy-browser-wrapper">
        {this.getTransactionSummary()}
        <TierBrowser
          comparator={this.getComparator()}
          filter={this.getFilter}
          categoryCellRenderer={this.getCellRenderer()}
          onCreateCategory={isUserACurator ? onAddCategory : undefined}
          onDeleteCategory={isUserACurator ? onDeleteCategory : undefined}
          onRenameCategory={isUserACurator ? onRenameCategory : undefined}
          canDeleteCategoryChecker={this.canDeleteCategory}
          disabledDeleteTooltip={DISABLE_DELETE_TOOLTIP}
          searchString={taxonomyQuery}
          tierLabelMaxCounts={tierLabelMaxCounts}
        />
        {this.renderReasonInput()}
        {this.renderMarkFinal()}
      </div>
    );
  };

  renderFooter = () => {
    const { activeCategory } = this.props;
    const anyRecordHasCategorization = !!this.props.records.find(record => {
      return !!record.manualCategorization;
    });
    let clearCategorizationButton;
    if (anyRecordHasCategorization && this.props.onDeleteCategorizations && (this.props.isUserACurator || this.props.isUserAVerifier)) {
      clearCategorizationButton = (
        <Button buttonType="Secondary" onClick={this.clearCategorization} className="clear-categorization-button">
          Clear categorization
        </Button>
      );
    }
    return (
      <div className="taxonomy-dialog-footer-section">
        <TaxonomyViewSelector onSelectionChange={this.props.onSetTaxonomySort} value={this.props.taxonomySort} />
        <div className="taxonomy-dialog-footer-buttons">
          {clearCategorizationButton}
          <ButtonToolbar>
            <Button buttonType="Secondary" onClick={this.hide}>
              Cancel
            </Button>
            <Button buttonType="Primary" onClick={this.handleSaveClick} disabled={!activeCategory}>
              Save
            </Button>
          </ButtonToolbar>
        </div>
      </div>
    );
  };

  render() {
    if (!this.props.show) {
      return <div />;
    }
    return (
      <Dialog
        className="categorize-dialog"
        show={this.props.show}
        onHide={this.hide}
        header={this.renderHeader()}
        body={this.renderBody()}
        footer={this.renderFooter()}
        dialogStyle={DialogStyle.FULL}
      />
    );
  }
}
