import { List, Map } from 'immutable';
import Papa from 'papaparse';
import _ from 'underscore';
import { refreshAuthCache } from '../auth/AuthCacheAsync';

import { FetchError } from '../api/FetchResult';
import { uploadDataset } from '../api/ProcurifyClient';
import * as RecipeClient from '../api/RecipeClient';
import { updateEnrichmentInputDataset } from '../enrichment/EnrichmentAsync';
import { AppThunkAction } from '../stores/AppAction';
import { AppDispatch } from '../stores/MainStore';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import * as Result from '../utils/Result';
import { $TSFixMe } from '../utils/typescript';
import { QueryBuilder } from '../models/doc/Query';
import { STOP_EDITING_POLICY_RESOURCESHIP } from './DatasetsActionTypes';
import { doProfileDataset, doTruncateDataset, doFetchDatasetsWithQuery } from './DatasetsApi';
import {
  SET_ERROR_MESSAGE,
  SET_FILE,
  SET_FILE_UPLOAD_CONFIG,
  SET_PARSE_FILE_RESULT,
  UPLOAD_AND_CREATE,
  UPLOAD_AND_CREATE_COMPLETED,
  UPLOAD_AND_CREATE_FAILED,
  UPLOAD_FILE_FAILED,
} from './FileUploadActionTypes';
import { FileUploadConfig } from './FileUploadStore';


const parseFileForFields = (file: $TSFixMe, config: FileUploadConfig): AppThunkAction<void> => (dispatch) => {
  Papa.parse(file, {
    delimiter: config.getColumnSeparatorValue(),
    quoteChar: config.get('quoteCharacter'),
    escapeChar: config.get('escapeCharacter'),
    skipEmptyLines: true,
    header: true,
    preview: 100,
    chunk: (results, handle) => {
      handle.abort();
      const previewData = List(results.data).map((row: Map<string, string>) => Map(row).mapEntries(([k, v]) => [k.trim(), v.trim()]));
      const fileFields = List(results.meta.fields).map(field => field.trim());
      // check if there are duplicate column names
      if (fileFields.size !== fileFields.toSet().size) {
        dispatch({ type: SET_ERROR_MESSAGE, errorMessage: 'Duplicate column names are not allowed.' });
      } else {
        dispatch({ type: SET_PARSE_FILE_RESULT, fields: fileFields, previewData });
      }
    },
  });
};

export const setFileUploadConfig = (config: FileUploadConfig): AppThunkAction<void> => (dispatch, getState) => {
  dispatch({ type: SET_FILE_UPLOAD_CONFIG, config });
  const file = getState().fileUpload.file;
  if (file) {
    return dispatch(parseFileForFields(file, config));
  }
};

export const setFile = (file: $TSFixMe): AppThunkAction<void> => (dispatch, getState) => {
  dispatch({ type: SET_FILE, file });
  return dispatch(parseFileForFields(file, getState().fileUpload.config));
};

const postUpload = (dispatch: AppDispatch) => {
  dispatch({ type: UPLOAD_AND_CREATE_COMPLETED });
  dispatch({ type: STOP_EDITING_POLICY_RESOURCESHIP });
};

export const failedUpload = (error: FetchError) => {
  if (error.type === 'ApiException') {
    const cause = error.apiException.causedBy ? error.apiException.causedBy.message : undefined;
    const causeMessage = cause ? `Caused by: ${cause.substring(0, 1000)}. \n` : '';
    return `\n${error.apiException.message}.\n${causeMessage}Please check the logs for more information.`;
  }
  return 'Check the dataset service logs.';
};

export const uploadAndCreate = (
  addToProject: boolean | undefined,
): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ addToProject }, ArgTypes.nullable(ArgTypes.bool));
  dispatch({ type: UPLOAD_AND_CREATE });
  const {
    fileUpload: {
      file,
      fields,
      primaryKeyColumnName,
      primaryKeyCustomName,
      description,
      shouldProfile,
      shouldTruncate,
      config,
    },
    enrichment: {
      module,
    },
    location: { recipeId },
    accessControl: { datasetDraftPolicyResourceship },
  } = getState();

  // Is a dataset being uploaded
  const data = {
    fields: fields.toArray(),
    idField: (primaryKeyColumnName || primaryKeyCustomName),
    delimiter: config.getColumnSeparatorValue(),
    quote: config.get('quoteCharacter'),
    escape: config.get('escapeCharacter'),
    description: '',
    generatePrimaryKey: 'false',
  };

  if (description) {
    data.description = description;
  } if (!primaryKeyColumnName) {
    data.generatePrimaryKey = 'true';
  }
  const formData = new FormData();
  // @ts-expect-error file is of object-type
  formData.append('file', file, file.name);
  _.each(data, (value, key) => {
    // If the value is an array, explode out so that each entry is individually appended
    // to the formData object
    if (_.isArray(value)) {
      _.each(value, (arrayValue) => {
        formData.append(key, arrayValue);
      });
    } else if (value) {
      // TODO: Is not appending if value is falsey the same as appending with a falsey value?
      //       At least from TS' perspective, it expects a non-falsey value.
      formData.append(key, value);
    }
  });

  const fileName = file?.name ? file.name : null;
  if (shouldTruncate && fileName) {
    const query = new QueryBuilder().whereData('name').isEqualTo(fileName);

    doFetchDatasetsWithQuery(query.build())
      .then(({ numDatasets }) => {
        if (numDatasets > 0) {
          doTruncateDataset(fileName).catch(() => {
            dispatch({
              type: UPLOAD_AND_CREATE_FAILED,
              errorMessageHeader: 'Error truncating dataset.',
              errorMessage: 'There was an error truncating your dataset before upload',
            });
          });
        }
      },
      );
  }

  // Get the policies to include this dataset in as part of creation
  const policyIds = Array.from(datasetDraftPolicyResourceship?.getPolicyIds() || []).map((v) => v.toString());
  uploadDataset(formData, policyIds)
    // As permissions are updated during dataset creation, we must force-refresh auth
    .then(ds => refreshAuthCache(dispatch).then(() => { return ds; }))
    .then(Result.handler(
      ds => {
        if (shouldProfile) {
          doProfileDataset(ds.data.name, dispatch)
            .then(() => postUpload(dispatch))
            .catch(() => {
              dispatch({
                type: UPLOAD_AND_CREATE_FAILED,
                errorMessageHeader: 'Error profiling dataset.',
                errorMessage: 'There was an error profiling your dataset after upload',
              });
            });
        }
        if (addToProject) {
        // module being present indicates that this is an enrichment module
          if (module) {
            updateEnrichmentInputDataset(dispatch, getState, module, ds.data.name).then(Result.handler(
              () => {}, // success handling has already been done (dispatched) by updateEnrichmentInputDataset
              error => {
                const errorMessage = failedUpload(error);
                dispatch({
                  type: UPLOAD_AND_CREATE_FAILED,
                  errorMessageHeader: 'Error adding dataset to project.',
                  errorMessage,
                });
              },
            ));
          } else {
            RecipeClient.addInputDatasetToRecipe(recipeId, ds.data.name).then(Result.handler(
              () => postUpload(dispatch),
              error => {
                const errorMessage = failedUpload(error);
                dispatch({
                  type: UPLOAD_AND_CREATE_FAILED,
                  errorMessageHeader: 'Error adding dataset to project.',
                  errorMessage,
                });
              },
            ));
          }
        } else {
          postUpload(dispatch);
        }
      },
      error => {
        dispatch({ type: UPLOAD_FILE_FAILED, error });
        dispatch({ type: STOP_EDITING_POLICY_RESOURCESHIP });
      },
    ));
};
