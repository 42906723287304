import { List, Map } from 'immutable';
import { ReactElementLike } from 'prop-types';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ButtonOnClick } from '../components/Button';
import ConditionalButton, { PreconditionsMap } from '../components/ConditionalButton';
import PopoverTrigger from '../components/PopoverTrigger';
import TamrIcon from '../components/TamrIcon';
import Cluster from '../models/Cluster';
import { AppAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { isCuratorByProjectId, isVerifierByProjectId } from '../utils/Authorization';
import { getAuthorizedUser, isJobRunning, selectActiveProjectInfo } from '../utils/Selectors';
import { PaneE } from './Pane';
import style from './SupplierActionMenu.module.scss';
import { resolveClusters, verifyClusters } from './SuppliersAsync';
import { getSelectedClusters } from './SuppliersStore';
import VerificationIcon, { VERIFICATION_ICON_TYPE_LOCKED, VERIFICATION_ICON_TYPE_SUGGESTABLE_HAS_SUGGESTION, VERIFICATION_ICON_TYPE_VERIFIED_HERE, VerificationIconTypeE } from './VerificationIcon';
import { VerificationTypeE } from './VerificationType';


const MenuItem: React.FC<{
  preconditions: PreconditionsMap
  onClick: ButtonOnClick
}> = ({ preconditions, onClick, children }) => (
  <ConditionalButton
    tooltipPlacement="right"
    buttonType="Link"
    className={style.menuItem}
    preconditions={preconditions}
    onClick={onClick}
  >
    {children}
  </ConditionalButton>
);

const VerificationActionIcon: React.FC<{
  type: VerificationIconTypeE
}> = ({ type }) => (
  <VerificationIcon
    style={{ verticalAlign: 'sub' }}
    size={16}
    verificationIconType={type}
  />
);

const SupplierActionMenu = connect((state: AppState, { pane }: { pane: PaneE }) => {
  const { suppliers } = state;
  const authorizedUser = getAuthorizedUser(state);
  return {
    clusters: getSelectedClusters(suppliers, pane),
    username: authorizedUser?.username,
    isUserACurator: isCuratorByProjectId(authorizedUser, selectActiveProjectInfo(state)?.projectDoc.id.id),
    isUserAVerifier: isVerifierByProjectId(authorizedUser, selectActiveProjectInfo(state)?.projectDoc.id.id),
    suggestionRunning: isJobRunning(state, { recipeOperation: 'trainPredictCluster', forActiveProject: true }),
  };
}, (dispatch, { pane }) => bindActionCreators({
  onOpenAssignDialog: (): AppAction => ({ type: 'Suppliers.openAssignDialog', pane }),
  onBeginConfirmingMergeClusters: (clusters: List<Cluster>): AppAction => ({ type: 'Suppliers.beginConfirmingMergeClusters', clusters, pane }),
  onResolveClusters: () => resolveClusters(pane),
  onVerify: (verificationType: VerificationTypeE) => verifyClusters(pane, verificationType),
}, dispatch),
)(({ clusters, username, isUserACurator, isUserAVerifier, suggestionRunning, onOpenAssignDialog,
  onBeginConfirmingMergeClusters, onResolveClusters, onVerify,
}) => {
  const moreActions = useRef<PopoverTrigger>(null);

  const jobRunningPrecondition = Map<ReactElementLike, boolean>().set(<span>Actions cannot be performed while a clustering job is running.</span>, !suggestionRunning);
  const anySelectionPrecondition = Map<ReactElementLike, boolean>().set(<span>You must select one or more clusters.</span>, !clusters.isEmpty());
  const twoOrMoreSelectionPrecondition = Map<ReactElementLike, boolean>().set(<span>You must select two or more clusters.</span>, clusters.size >= 2);
  const someClustersUnresolvedPrecondition = Map<ReactElementLike, boolean>().set(
    <span>You must select one or more clusters assigned to you that have not yet been resolved.</span>,
    clusters.some(({ clusterAssignments }) => clusterAssignments.some(cf => cf.username === username && !cf.resolved)),
  );

  const hidePopover = () => {
    if (moreActions.current) {
      moreActions.current.hide();
    }
  };

  const handleMerge = () => {
    onBeginConfirmingMergeClusters(clusters);
    hidePopover();
  };
  const handleAssign = () => {
    onOpenAssignDialog();
    hidePopover();
  };
  const handleResolve = () => {
    onResolveClusters();
    hidePopover();
  };
  const handleVerify = (verificationType: VerificationTypeE) => {
    onVerify(verificationType);
    hidePopover();
  };

  return (
    !(isUserACurator || isUserAVerifier) ? null : <PopoverTrigger
      className="supplier-selector-menu"
      placement="bottom"
      ref={moreActions}
      content={
        <div>
          <MenuItem preconditions={twoOrMoreSelectionPrecondition} onClick={handleMerge}>
            Merge
          </MenuItem>
          <MenuItem preconditions={anySelectionPrecondition} onClick={handleAssign}>
            Assign
          </MenuItem>
          <MenuItem preconditions={someClustersUnresolvedPrecondition} onClick={handleResolve}>
            Resolve assignment
          </MenuItem>
          <MenuItem preconditions={anySelectionPrecondition} onClick={() => handleVerify(VerificationTypeE.SUGGEST)}>
            <VerificationActionIcon type={VERIFICATION_ICON_TYPE_SUGGESTABLE_HAS_SUGGESTION} />&nbsp;Verify and enable suggestions
          </MenuItem>
          <MenuItem preconditions={anySelectionPrecondition} onClick={() => handleVerify(VerificationTypeE.MOVABLE)}>
            <VerificationActionIcon type={VERIFICATION_ICON_TYPE_VERIFIED_HERE} />&nbsp;Verify and auto-accept suggestions
          </MenuItem>
          <MenuItem preconditions={anySelectionPrecondition} onClick={() => handleVerify(VerificationTypeE.LOCK)}>
            <VerificationActionIcon type={VERIFICATION_ICON_TYPE_LOCKED} />&nbsp;Verify and disable suggestions
          </MenuItem>
          <MenuItem preconditions={anySelectionPrecondition} onClick={() => handleVerify(VerificationTypeE.UNVERIFIED)}>
            Remove verification
          </MenuItem>
        </div>
      }
    >
      <ConditionalButton
        preconditions={jobRunningPrecondition}
        buttonType="Secondary"
      >
        <span className="supplier-action-button-wrapper">
          <span>Actions</span>
          <TamrIcon iconName="arrow-drop-down" size={18} className="supplier-action-arrow" />
        </span>
      </ConditionalButton>
    </PopoverTrigger>
  );
});

export default SupplierActionMenu;
