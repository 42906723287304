import classnames from 'classnames';
import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../../components/Button';
import Dialog, { DialogStyle } from '../../components/Dialog/Dialog';
import TamrIcon from '../../components/TamrIcon';
import AppState from '../../stores/AppState';
import { AppDispatch } from '../../stores/MainStore';
import styles from './Dialog.module.scss';
import EstimateButton from './EstimateButton';
import Graph from './Graph';
import { closeDialog } from './slice';

interface DisplayMetricProps {
  score: number,
  confidenceInterval: number
  name: string
  color: 'precision' | 'recall'
  description: string
}

const DisplayMetric: React.FC<DisplayMetricProps> = ({
  score,
  confidenceInterval,
  name,
  color,
  description,
}) => {
  return (
    <div className={styles.metric}>
      <div className={styles.metricValue}>
        <span className={styles.score}>{numeral(score * 100).format('0')}%</span>
        <span className={styles.confidenceInterval}>+/- {numeral(confidenceInterval * 100).format('0')}%</span>
      </div>
      <div className={styles.metricKey}>
        <span className={classnames(styles.metricName, { [styles.precision]: color === 'precision', [styles.recall]: color === 'recall' })}>
          {name}
        </span>
        <span className={styles.metricDescription}>{description}</span>
      </div>
    </div>
  );
};

const mapState = (state: AppState) => {
  const { isDialogOpen } = state.clusters.metrics;
  const latestMetrics = _.maxBy(state.clusters.metrics.historical.metrics, m => m.date);
  return {
    isOpen: isDialogOpen,
    latestMetrics,
  };
};

const mapDispatch = (dispatch: AppDispatch) => {
  return {
    close: () => dispatch(closeDialog()),
  };
};

type StateProps = ReturnType<typeof mapState>;
type DispatchProps = ReturnType<typeof mapDispatch>;
type Props = StateProps & DispatchProps;

const MetricsDialog: React.FC<Props> = ({
  latestMetrics,
  isOpen,
  close,
}) => {
  if (!latestMetrics) {
    return null;
  }
  return <Dialog
    className={styles.clusterMetricsDialog}
    show={isOpen}
    onHide={close}
    header={<div className={styles.header}>
      <span className={styles.title}>Cluster Metrics</span>
      <span className={styles.lastUpdated}>Last Updated {latestMetrics.date.fromNow()}</span>
    </div>}
    body={<div>
      <Graph />
      <div className={styles.metrics}>
        <DisplayMetric
          score={latestMetrics.precision}
          confidenceInterval={latestMetrics.precisionConfidenceInterval}
          name="Precision"
          color="precision"
          description="How exact the clusters are"
        />
        <DisplayMetric
          score={latestMetrics.recall}
          confidenceInterval={latestMetrics.recallConfidenceInterval}
          name="Recall"
          color="recall"
          description="How complete the clusters are"
        />
      </div>
    </div>}
    footer={<div className={styles.footer}>
      <EstimateButton>
        <TamrIcon className={styles.reestimateIcon} iconName="redo" size={14} />
        <span>Re-estimate</span>
      </EstimateButton>
      <Button buttonType="Primary" onClick={close}>Close</Button>
    </div>}
    dialogStyle={DialogStyle.PRIMARY}
  />;
};

export default connect(mapState, mapDispatch)(MetricsDialog);
