import { List } from 'immutable';

import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import Document from './doc/Document';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';
import Project from './Project';
import RecipeWithStatus from './RecipeWithStatus';

class ProjectWithStatus extends getModelHelpers({
  project: { type: Document.argTypeWithNestedClass(Project) },
  recipes: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(RecipeWithStatus)) },
  current: { type: ArgTypes.bool },
}, 'ProjectWithStatus')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ProjectWithStatusRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: $TSFixMe) {
    const project = Document.fromJSON(obj.project, Project.fromJSON);
    const recipes: List<RecipeWithStatus> = List(obj.recipes.map(RecipeWithStatus.fromJSON));
    return new ProjectWithStatus({ project, recipes, current: obj.current });
  }
}

export default ProjectWithStatus;
