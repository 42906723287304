import { ArgTypes, Checker } from '../utils/ArgValidation';
import { checkTypesAndCast } from '../utils/CheckTypesAndCast';
import { assertNever } from '../utils/typescript';
import * as AggExprRule from './AggExprRule';
import * as CountDistinctRule from './CountDistinctRule';
import * as CountRule from './CountRule';
import * as DistinctRule from './DistinctRule';
import * as FilterClusterRecords from './FilterClusterRecords';
import * as LongestRule from './LongestRule';
import * as MaxRule from './MaxRule';
import * as MeanRule from './MeanRule';
import * as MinRule from './MinRule';
import * as ModeRule from './ModeRule';
import * as ShortestRule from './ShortestRule';
import * as SumRule from './SumRule';

export const RuleTypeName = {
  [AggExprRule.TYPE]: AggExprRule.TYPE,
  [CountDistinctRule.TYPE]: CountDistinctRule.TYPE,
  [CountRule.TYPE]: CountRule.TYPE,
  [DistinctRule.TYPE]: DistinctRule.TYPE,
  [LongestRule.TYPE]: LongestRule.TYPE,
  [MaxRule.TYPE]: MaxRule.TYPE,
  [MeanRule.TYPE]: MeanRule.TYPE,
  [MinRule.TYPE]: MinRule.TYPE,
  [ModeRule.TYPE]: ModeRule.TYPE,
  [ShortestRule.TYPE]: ShortestRule.TYPE,
  [SumRule.TYPE]: SumRule.TYPE,
} as const;
export type RuleTypeNameE = typeof RuleTypeName[keyof typeof RuleTypeName];

// required fields for every Rule subtype
export interface AbstractRule {
  outputAttributeName: string
  filters: FilterClusterRecords.FilterClusterRecords[]
  suggested: boolean
}
export const genericRuleArgTypes = {
  outputAttributeName: ArgTypes.string,
  filters: ArgTypes.array.of(FilterClusterRecords.argType),
  suggested: ArgTypes.bool,
} as const;

export type Rule
  = AggExprRule.AggExprRule
  | CountDistinctRule.CountDistinctRule
  | CountRule.CountRule
  | DistinctRule.DistinctRule
  | LongestRule.LongestRule
  | MaxRule.MaxRule
  | MeanRule.MeanRule
  | MinRule.MinRule
  | ModeRule.ModeRule
  | ShortestRule.ShortestRule
  | SumRule.SumRule

export const argType = ArgTypes.oneOf(
  AggExprRule.argType,
  CountDistinctRule.argType,
  CountRule.argType,
  DistinctRule.argType,
  LongestRule.argType,
  MaxRule.argType,
  MeanRule.argType,
  MinRule.argType,
  ModeRule.argType,
  ShortestRule.argType,
  SumRule.argType,
) as Checker<Rule>;

const hasTypeArgTypes = {
  type: ArgTypes.valueIn(RuleTypeName),
} as const;
export const fromJSON = (json: unknown): Rule => {
  const { type } = checkTypesAndCast<{ type: RuleTypeNameE }, typeof hasTypeArgTypes>(hasTypeArgTypes)(json);
  if (type === AggExprRule.TYPE) return AggExprRule.fromJSON(json);
  if (type === CountDistinctRule.TYPE) return CountDistinctRule.fromJSON(json);
  if (type === CountRule.TYPE) return CountRule.fromJSON(json);
  if (type === DistinctRule.TYPE) return DistinctRule.fromJSON(json);
  if (type === LongestRule.TYPE) return LongestRule.fromJSON(json);
  if (type === MaxRule.TYPE) return MaxRule.fromJSON(json);
  if (type === MeanRule.TYPE) return MeanRule.fromJSON(json);
  if (type === MinRule.TYPE) return MinRule.fromJSON(json);
  if (type === ModeRule.TYPE) return ModeRule.fromJSON(json);
  if (type === ShortestRule.TYPE) return ShortestRule.fromJSON(json);
  if (type === SumRule.TYPE) return SumRule.fromJSON(json);
  assertNever(type);
};

export const SPECIAL_RULE_NAMES = {
  sources: 'Sources',
  clusterSize: 'Cluster Size',
} as const;
