import { Map } from 'immutable';
import $ from 'jquery';

import * as DatasetClient from '../api/DatasetClient';
import { QueryBuilder } from '../models/doc/Query';
import { getUnifiedDatasetIdsByProjectId } from '../projects/ProjectsStore';
import { doFetchDatasetsProfilingAndExportsWithQuery } from './DatasetsApi';
import { getFilterInfo } from './UnifiedDatasetsStore';

export const fetchAll = () => (dispatch, getState) => {
  const state = getState();
  const { projects } = state;
  // can contain invalid UD's IDs because the recipe system doesn't get updated
  // the UD is deleted
  const unifiedDatasetIdsByProjectId = getUnifiedDatasetIdsByProjectId(projects);
  dispatch({ type: 'UnifiedDatasets.fetchAll' });
  const filterInfo = getFilterInfo(state);
  let ajaxCalls;
  if (unifiedDatasetIdsByProjectId.isEmpty()) {
    ajaxCalls = [
      $.Deferred().resolve({ datasets: Map(), profiling: Map(), exporting: Map() }),
      $.Deferred().resolve(Map()),
    ];
  } else {
    const unifiedDatasetIds = unifiedDatasetIdsByProjectId.toList();
    const query = new QueryBuilder()
      .offset(0)
      .limit(Math.max(1, unifiedDatasetIds.size))
      .where('id')
      .isInIntegers(unifiedDatasetIds.toArray())
      .build();
    ajaxCalls = [doFetchDatasetsProfilingAndExportsWithQuery(query), DatasetClient.postStatusQuery(query)];
  }
  $.when(...ajaxCalls).then(({ datasets, profiling, exporting }, status) => {
    // the server APIs will filter out invalid datasets sent in the query, so we need to make sure
    // that we filter out invalid dataset ids here
    const validDatasetIds = datasets.map(doc => doc.id.id).toSet()
      .intersect(status.map(s => s.datasetId).toSet())
      .intersect(unifiedDatasetIdsByProjectId.valueSeq().toSet());

    const validUnifiedDatasetIdsByProjectId =
      unifiedDatasetIdsByProjectId.filter(dsId => validDatasetIds.has(dsId));

    const datasetDocByDatasetId = Map(datasets.map(doc => [doc.id.id, doc]));
    const statusByDatasetId = Map(status.map(s => [s.datasetId, s]));

    const docsByProjectId =
      validUnifiedDatasetIdsByProjectId.map(dsId => datasetDocByDatasetId.get(dsId));
    const statusByProjectId =
      validUnifiedDatasetIdsByProjectId.map(dsId => statusByDatasetId.get(dsId));

    // TODO: with TS-ification of UnifiedDatasetStore, we can enforce that the values
    //       of docsByProjectId and statusByProjectId have to be valid (i.e not undefined).
    dispatch({
      type: 'UnifiedDatasets.fetchAllCompleted',
      docsByProjectId,
      statusByProjectId,
      profiling,
      exporting,
      filterInfo,
    });
  });
};
