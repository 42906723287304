import classNames from 'classnames';
import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

// @ts-expect-error
import loader from '../../images/tamr-loader.gif';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import TooltipTrigger from '../components/TooltipTrigger';
import SidebarTabs from '../constants/SidebarTabs';
import Cluster from '../models/Cluster';
import { AppAction } from '../stores/AppAction';
import { AppDispatch, AppState } from '../stores/MainStore';
import { commafy } from '../utils/Numbers';
import { TwoPanesIconButton } from './ClusterCard';
import styles from './ClusterRecordsSummary.module.scss';
import ClusterSimilarity from './ClusterSimilarity';
import { PaneE } from './Pane';
import { OPEN_CLUSTER_BROWSER, SET_SUPPLIERS_FILTER, SHOW_SIDEBAR_TAB } from './SuppliersActionTypes';
import { getSelectedClusters } from './SuppliersStore';

interface StateProps {
  loadedSimilarClustersId?: string | null
  loading: boolean
  loadingSimilarClusters: boolean
  mapIsOpen: boolean
  pinnedRecordCount: number
  queryString: string
  selectedClusters: List<Cluster>
  similarClusters: List<ClusterSimilarity>
  total: number
  totalClusterRecords: number
}

interface DispatchProps {
  openClusterBrowser: (clusterId: string) => AppAction
  setClustersFilter: (clusterId: string) => AppAction
  showSidebarDetails: () => AppAction
}

interface OwnProps {
  pane: PaneE
  width: number
}

type Props = StateProps & DispatchProps & OwnProps

const ClusterRecordsSummary: React.FC<Props> = ({
  loadedSimilarClustersId,
  loading,
  loadingSimilarClusters,
  mapIsOpen,
  openClusterBrowser,
  pane,
  pinnedRecordCount,
  queryString,
  selectedClusters,
  setClustersFilter,
  showSidebarDetails,
  similarClusters,
  total,
  totalClusterRecords,
  width,
}) => {
  return (
    <div className={styles.clusterRecordsSummary} style={{ width: `${width}px` }}>
      <Title {...{ queryString, selectedClusters }} />
      <Counts {...{ loading, selectedClusters, total, totalClusterRecords }} />
      <span className={styles.pinned}>{pinnedRecordCount ? `, ${pinnedRecordCount} Pinned` : ''}</span>
      <SimilarClusters {...{
        loadedSimilarClustersId,
        loadingSimilarClusters,
        mapIsOpen,
        openClusterBrowser,
        setClustersFilter,
        showSidebarDetails,
        pane,
        similarClusters,
      }} />
    </div>
  );
};

const Title: React.FC<{
  selectedClusters: List<Cluster>
  queryString: string
}> = ({ selectedClusters, queryString }) => {
  const firstCluster: Cluster = selectedClusters.first();
  const clusterName = firstCluster?.name || firstCluster?.publishedName;
  if (queryString) {
    switch (selectedClusters.size) {
      case 0: return <span className={styles.heading}>Search results</span>;
      case 1: return <span className={styles.heading}>Search results in {clusterName}</span>;
      default: return <span className={styles.heading}>Search results in selected clusters</span>;
    }
  } else {
    switch (selectedClusters.size) {
      case 0: return <span className={styles.heading}>All&nbsp;<Term>Records</Term></span>;
      case 1: return clusterName
        ? (
          <span className={styles.heading}>
            {(firstCluster.trainingCluster || firstCluster.testCluster)
              ? <TamrIcon iconName="tamr-icon-lightning-bolt" className={styles.highImpactIcon} size={16} />
              : null
            }
            {clusterName}
          </span>
        )
        : <span className={classNames(styles.heading, styles.italic)}>No cluster name</span>;
      default: return <span className={styles.heading}>Selected clusters</span>;
    }
  }
};

const Counts: React.FC<{
  loading: boolean
  selectedClusters: List<Cluster>
  total: number
  totalClusterRecords: number
}> = ({
  loading,
  selectedClusters,
  total,
  totalClusterRecords,
}) => {
  const totalCount = selectedClusters.isEmpty() ? totalClusterRecords : selectedClusters.reduce((r, c) => r + c.recordCount, 0);
  return (
    <span className={styles.counts}>
      {total !== totalCount && !loading ? `${commafy(total)} of ` : ''}
      {commafy(totalCount)}
      &nbsp;
      <Term>Records</Term>
    </span>
  );
};

const SimilarClusters: React.FC<{
  loadedSimilarClustersId?: string | null
  loadingSimilarClusters: boolean
  mapIsOpen: boolean
  openClusterBrowser: (clusterId: string) => AppAction
  pane: PaneE
  setClustersFilter: (clusterId: string) => AppAction
  showSidebarDetails: () => AppAction
  similarClusters: List<ClusterSimilarity>
}> = ({
  loadedSimilarClustersId,
  loadingSimilarClusters,
  mapIsOpen,
  openClusterBrowser,
  pane,
  setClustersFilter,
  showSidebarDetails,
  similarClusters,
}) => {
  if (!loadedSimilarClustersId && !loadingSimilarClusters) {
    return null;
  }
  if (loadingSimilarClusters) {
    return (
      <span className={styles.similarClusters}>
        Loading similar clusters&nbsp;
        <img src={loader} style={{ width: 12, height: 12 }} />
      </span>
    );
  }
  const mostSimilarCluster = similarClusters.maxBy(s => s.similarity);
  if (!mostSimilarCluster) {
    return <span className={styles.similarClusters}>No similar clusters</span>;
  }

  const name = mostSimilarCluster?.cluster.name;
  const id = mostSimilarCluster?.id;
  const similarityPercentage = mostSimilarCluster ? Math.round(mostSimilarCluster.similarity * 100) : null;
  return (
    <div className={styles.similarClusters}>
      <span key="unique-label" className={styles.similar}>
        Similar:
      </span>
      <span className={styles.similarSupplier} key={id}>
        <TooltipTrigger placement="bottom" content={<span>Filter to {name}</span>}>
          <a className={styles.similarName} onClick={() => setClustersFilter(id)}>{name}</a>
        </TooltipTrigger>
        <TooltipTrigger placement="bottom" content={<span>{similarityPercentage}% similar to {name}</span>}>
          <span className={styles.percentage}>{similarityPercentage}%</span>
        </TooltipTrigger>
        <TwoPanesIconButton
          tooltipPlacement="bottom"
          className={styles.iconAction}
          focusedPane={pane}
          onOpenClusterInOtherPane={() => openClusterBrowser(id)}
          mapIsOpen={mapIsOpen}
          iconSize={12}
        />
      </span>
      {similarClusters.size > 1 ?
        <>
          <span className={styles.separator}>|</span>
          <a className={styles.seeMore} onClick={showSidebarDetails}>
            and {similarClusters.size - 1} more
          </a>
        </>
        :
        null
      }
    </div>
  );
};

export default connect<StateProps, DispatchProps, OwnProps>((state: AppState, ownProps: OwnProps): StateProps => {
  const { pane } = ownProps;
  const {
    suppliers,
    suppliers: {
      geospatial: { openMap },
      [pane]: {
        loadedSimilarSuppliersId,
        loadingSimilarSuppliers,
        numRecords,
        pinnedRecords,
        queryString,
        recordsLoading,
        similarSuppliers,
        totalSupplierRecords,
      },
    },
  } = state;
  return {
    loadedSimilarClustersId: loadedSimilarSuppliersId,
    loading: recordsLoading,
    loadingSimilarClusters: loadingSimilarSuppliers,
    mapIsOpen: openMap,
    pinnedRecordCount: pinnedRecords.size,
    queryString,
    selectedClusters: getSelectedClusters(suppliers, pane),
    similarClusters: similarSuppliers,
    total: numRecords,
    totalClusterRecords: totalSupplierRecords,
  };
}, (dispatch: AppDispatch, { pane }: { pane: PaneE }) => ({
  setClustersFilter: (clusterId: string): AppAction => dispatch({ type: SET_SUPPLIERS_FILTER, supplierId: clusterId, pane }),
  openClusterBrowser: (clusterId: string): AppAction => dispatch({ type: OPEN_CLUSTER_BROWSER, clusterId, pane }),
  showSidebarDetails: (): AppAction => dispatch({ type: SHOW_SIDEBAR_TAB, sidebarTabKey: SidebarTabs.DETAILS }),
}))(ClusterRecordsSummary);
