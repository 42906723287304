// com.tamr.dedup.models.RecordPair.LabelType

const LabelType = {
  DIRECTLY_LABELED: 'DIRECTLY_LABELED',
  INFERRED_FROM_CLUSTER_VERIFICATION: 'INFERRED_FROM_CLUSTER_VERIFICATION',
  INFERRED_FROM_PREGROUPING: 'INFERRED_FROM_PREGROUPING',
} as const;
export type LabelTypeE = typeof LabelType[keyof typeof LabelType];

export default LabelType;
