import { ArgTypes } from '../utils/ArgValidation';
import { checkTypesAndCast } from '../utils/CheckTypesAndCast';
import { Dictionary, Nullable } from '../utils/typescript';

export interface ModuleOperationStatus {
  lastRunModuleVersion: number
}
const moduleOperationStatusArgTypes = {
  lastRunModuleVersion: ArgTypes.number,
} as const;
export const moduleOperationStatusFromJSON = checkTypesAndCast<ModuleOperationStatus, typeof moduleOperationStatusArgTypes>(moduleOperationStatusArgTypes);

export interface ModuleStatus {
  moduleId: number,
  lastPublishedModuleVersion: Nullable<number | null | undefined>,
  operations: Dictionary<ModuleOperationStatus>,
  configValidationErrors: string[],
}
const moduleStatusArgTypes = {
  moduleId: ArgTypes.positiveInteger,
  lastPublishedModuleVersion: ArgTypes.nullable(ArgTypes.positiveInteger),
  operations: ArgTypes.object.of(ArgTypes.object.withShape(moduleOperationStatusArgTypes)),
  configValidationErrors: ArgTypes.array.of(ArgTypes.string),
};
export const moduleStatusFromJSON = checkTypesAndCast<ModuleStatus, typeof moduleStatusArgTypes>(moduleStatusArgTypes);

export function lastRunIndexDraft(moduleStatus: ModuleStatus) {
  return moduleStatus.operations.indexDraft?.lastRunModuleVersion;
}
