import PropTypes from 'prop-types';

import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../../models/Model';
import { ArgTypes } from '../../utils/ArgValidation';
import { $TSFixMe } from '../../utils/typescript';

class Interval extends getModelHelpers({
  startLine: { type: ArgTypes.number },
  endLine: { type: ArgTypes.number },
  startPosition: { type: ArgTypes.number },
  endPosition: { type: ArgTypes.number },
}, 'Interval')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class IntervalRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON({ startLine, endLine, startPosition, endPosition }: $TSFixMe) {
    return new Interval({ startLine, endLine, startPosition, endPosition });
  }
  static propType = PropTypes.instanceOf(Interval);
}

export default Interval;
