import * as GroupsActions from '../accessControl/GroupsActionTypes';
import * as UsersActions from '../constants/UsersActionTypes';
import * as GoldenRecordsActionTypes from '../goldenRecords/GoldenRecordsActionTypes';
import Model from '../models/Model';
import { ArgTypes } from '../utils/ArgValidation';
import { API_ERROR, SHOW, SHOW_SIMPLE } from './ErrorDialogActionTypes';
import { getFetchErrorAdvancedDetail, getFetchErrorDetail } from './ErrorDialogUtils';
import { formatAdvancedDetails } from './ErrorFormat';

// TODO currently clients dispatch ErrorDialog.show, this store should just listen to *Failed actions and respond accordingly
//      or... perhaps it's actually appropriate for clients to dispatch to this


export const initialState = new (Model({
  showDialog: { type: ArgTypes.bool, defaultValue: false },
  showMore: { type: ArgTypes.bool, defaultValue: false },
  title: { type: ArgTypes.orUndefined(ArgTypes.string) },
  detail: { type: ArgTypes.orUndefined(ArgTypes.string) },
  advancedDetail: { type: ArgTypes.orUndefined(ArgTypes.string) },
  copied: { type: ArgTypes.bool, defaultValue: false },
}))();

export const reducers = {
  'ErrorDialog.dismiss': (state) => {
    return state.clear();
  },

  'ErrorDialog.toggleShowMore': (state) => {
    return state.update('showMore', s => !s);
  },
  [SHOW_SIMPLE]: (state, { title, detail }) => {
    return state.merge({
      showDialog: true,
      detail,
      title,
      advancedDetail: '',
    });
  },

  [SHOW]: (state, { title, detail, advancedDetail, response }) => {
    return state.merge({
      showDialog: true,
      detail,
      advancedDetail: advancedDetail || formatAdvancedDetails(response),
      title,
    });
  },

  [API_ERROR]: (state, { title, contents }) => {
    return state.merge({
      showDialog: true,
      title,
      detail: getFetchErrorDetail(contents),
      advancedDetail: getFetchErrorAdvancedDetail(contents),
    });
  },

  'ErrorDialog.copy': (state) => {
    return state.merge({ copied: true });
  },

  [UsersActions.FETCH_FULL_USERS_FAILED]: (state, { response }) => {
    return state.merge({
      showDialog: true,
      detail: 'Error loading users',
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  [UsersActions.CREATE_USER_FAILED]: (state, { response, detail }) => {
    return state.merge({
      showDialog: true,
      title: 'Error creating user',
      detail,
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  [UsersActions.UPDATE_USER_FAILED]: (state, { response, detail }) => {
    return state.merge({
      showDialog: true,
      title: 'Error updating user',
      detail,
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  [UsersActions.FETCH_USER_ROLES_FAILED]: (state, { response, detail }) => {
    return state.merge({
      showDialog: true,
      title: 'Error editing user',
      detail,
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  [UsersActions.SET_QUERY_FAILED]: (state, { response, detail }) => {
    return state.merge({
      showDialog: true,
      title: 'Error querying users',
      detail,
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  [UsersActions.SET_SORT_FAILED]: (state, { response, detail }) => {
    return state.merge({
      showDialog: true,
      title: 'Error querying users',
      detail,
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  [UsersActions.SET_PAGE_NUMBER_FAILED]: (state, { response, detail }) => {
    return state.merge({
      showDialog: true,
      title: 'Error querying users',
      detail,
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  [UsersActions.SET_PAGE_SIZE_FAILED]: (state, { response, detail }) => {
    return state.merge({
      showDialog: true,
      title: 'Error querying users',
      detail,
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  [UsersActions.SUBMIT_CHANGE_ACTIVE_STATUS_FAILED]: (state, { response, detail }) => {
    return state.merge({
      showDialog: true,
      title: 'Error changing user active status',
      detail,
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  'RecordPairs.launchTrainPredictClusterFailed': (state, { response }) => {
    return state.merge({
      showDialog: true,
      title: 'Error submitting clustering job',
      detail: 'Error submitting trainPredictCluster job',
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  'RecordPairs.launchPredictClusterFailed': (state, { response }) => {
    return state.merge({
      showDialog: true,
      title: 'Error submitting clustering job',
      detail: 'Error submitting predictCluster job',
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  'Dnf.fetchDnfFailed': (state, { response }) => {
    return state.merge({
      showDialog: true,
      title: 'Error loading pair generation clauses',
      detail: 'Error loading pair generation clauses. One or more may be invalid.',
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  'Transactions.checkModelFailed': (state, { detail }) => {
    return state.merge({
      showDialog: true,
      title: 'Error checking whether the categorization model exists',
      detail,
    });
  },
  'DnfBuilder.updatePairGenerationModelFailed': (state, { response }) => {
    return state.merge({
      showDialog: true,
      title: 'Error updating Pair Generation Model',
      detail: 'Error updating pair generation clauses. One or more may be invalid.',
      advancedDetail: formatAdvancedDetails(response),
    });
  },
  'DnfBuilder.submitGenerateEstimatesFailed': (state, { response }) => {
    return state.merge({
      showDialog: true,
      title: 'Error estimating counts',
      // `response` may not always exist. This may be because two thunks are chained together
      // and the error message from the first thunk is more descriptive (we don't want to overwrite it).
      detail: response ? 'Error submitting estimate pair counts job' : state.detail,
      advancedDetail: response ? formatAdvancedDetails(response) : state.advancedDetail,
    });
  },
  'DnfBuilder.generatePairsFailed': (state, { response }) => {
    return state.merge({
      showDialog: true,
      title: 'Error generating Pairs',
      // `response` may not always exist. This may be because two thunks are chained together
      // and the error message from the first thunk is more descriptive (we don't want to overwrite it).
      detail: response ? 'Error submitting generate pairs job' : state.detail,
      advancedDetail: response ? formatAdvancedDetails(response) : state.advancedDetail,
    });
  },
  'ManageProjectDatasets.submitFailed': (state, { detail, response }) => {
    return state.merge({
      showDialog: true,
      title: 'Error changing project datasets',
      detail,
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  'ProjectDatasetCatalog.submitMembershipChangesFailed': (state, { detail, response }) => {
    return state.merge({
      showDialog: true,
      title: 'Error changing project datasets',
      detail,
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  'Transactions.commentFailed': (state, { detail, response }) => {
    return state.merge({
      showDialog: true,
      title: 'Error commenting on a transaction',
      detail,
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  'Transactions.editCommentFailed': (state, { detail, response }) => {
    return state.merge({
      showDialog: true,
      title: 'Error editing a comment on a transaction',
      detail,
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  'Transactions.deleteCommentFailed': (state, { detail, response }) => {
    return state.merge({
      showDialog: true,
      title: 'Error deleting a comment on a transaction',
      detail,
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  'RecordPairs.commentFailed': (state, { response }) => {
    return state.merge({
      showDialog: true,
      title: 'Error commenting on a record pair',
      detail: 'Error adding comment',
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  'RecordPairs.editCommentFailed': (state, { response }) => {
    return state.merge({
      showDialog: true,
      title: 'Error editing a comment on a record pair',
      detail: 'Error editing comment',
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  'RecordPairs.deleteCommentFailed': (state, { response }) => {
    return state.merge({
      showDialog: true,
      title: 'Error deleting a comment on a record pair',
      detail: 'Error deleting comment',
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  'Suppliers.commentFailed': (state, { detail, response }) => {
    return state.merge({
      showDialog: true,
      title: 'Error commenting on a cluster record',
      detail,
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  'Suppliers.editCommentFailed': (state, { detail, response }) => {
    return state.merge({
      showDialog: true,
      title: 'Error editing a comment on a cluster record',
      detail,
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  'Suppliers.deleteCommentFailed': (state, { detail, response }) => {
    return state.merge({
      showDialog: true,
      title: 'Error deleting a comment on a cluster record',
      detail,
      advancedDetail: formatAdvancedDetails(response),
    });
  },

  'Datasets.updatePolicyResourceshipFailed': (state, { response }) => {
    return state.merge({
      showDialog: true,
      title: 'Error updating policy management for dataset',
      detail: response,
    });
  },

  [UsersActions.UPDATE_POLICY_MEMBERSHIP_FAILED]: (state, { response }) => {
    return state.merge({
      showDialog: true,
      title: 'Error updating policy membership for user',
      detail: response,
    });
  },

  [UsersActions.DELETE_GROUP_FAILED]: (state, { response }) => {
    return state.merge({
      showDialog: true,
      title: 'Error deleting group',
      detail: response,
    });
  },

  [GroupsActions.UPDATE_POLICY_MEMBERSHIP_FAILED]: (state, { response }) => {
    return state.merge({
      showDialog: true,
      title: 'Error updating policy membership for group',
      detail: response,
    });
  },

  [GoldenRecordsActionTypes.SUBMIT_UPDATE_SOURCE_LIST_JOB_FAILED]: (state, { data, message }) => {
    return state.merge({
      showDialog: true,
      title: 'Unable to submit job to update Golden Records source list',
      detail: message,
      response: { responseText: data?.stackTrace?.join('\n') },
    });
  },

  [GoldenRecordsActionTypes.PUBLISH_FAILED]: (state, { data, message }) => {
    return state.merge({
      showDialog: true,
      title: 'Unable to publish Golden Records',
      detail: message,
      response: { responseText: data?.stackTrace?.join('\n') },
    });
  },

  [GoldenRecordsActionTypes.EXCLUDE_AND_PUBLISH_FAILED]: (state, { data, message }) => {
    return state.merge({
      showDialog: true,
      title: 'Unable to publish Golden Records',
      detail: message,
      response: { responseText: data?.stackTrace?.join('\n') },
    });
  },

  [GoldenRecordsActionTypes.SUBMIT_UPDATE_CLUSTER_PROFILE_JOB_FAILED]: (state, { data, message }) => {
    return state.merge({
      showDialog: true,
      title: 'Unable to submit update Golden Records cluster profile job',
      detail: message,
      response: { responseText: data?.stackTrace?.join('\n') },
    });
  },
};
