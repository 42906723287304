import enumify from './enumify';

// com.tamr.wms.job.JobStatus.JobState

export const UNSUBMITTED = 'UNSUBMITTED';
export const SUBMITTING = 'SUBMITTING';
export const SUBMITTED = 'SUBMITTED';
export const CANCELING = 'CANCELING';
export const CANCELED = 'CANCELED';
export const RUNNING = 'RUNNING';
export const SUCCEEDED = 'SUCCEEDED';
export const FAILED = 'FAILED';

export default enumify({ UNSUBMITTED, SUBMITTING, SUBMITTED, CANCELING, CANCELED, RUNNING, SUCCEEDED, FAILED });
