import { List, Map } from 'immutable';
import PropTypes from 'prop-types';

import Model from '../../models/Model';
import { ArgTypes } from '../../utils/ArgValidation';
import TransformDelta from './TransformDelta';

// Imported in RecordApi
class TransformChangesPreview extends Model({
  transformChanges: { type: ArgTypes.Immutable.list.of(TransformDelta.argType) },
  sampleDatasets: { type: ArgTypes.Immutable.map.of(ArgTypes.string, ArgTypes.string) },
}) {
  static fromJSON({ transformChanges, sampleDatasets }) {
    return new TransformChangesPreview({
      transformChanges: List(transformChanges).map(json => TransformDelta.fromJSON(json)),
      sampleDatasets: Map(sampleDatasets),
    });
  }
  toJSON() {
    return {
      transformChanges: this.transformChanges.map((transformDelta) => transformDelta.toServerJSON()),
      sampleDatasets: this.sampleDatasets,
    };
  }
}
TransformChangesPreview.propType = PropTypes.instanceOf(TransformChangesPreview);

export default TransformChangesPreview;
