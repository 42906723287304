import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import CenterContent from '../components/CenterContent';
import Link from '../components/Link';
import Term from '../components/Term';
import ProjectStepsKey from '../constants/ProjectStepsKey';
import { ENRICHMENT, GOLDEN_RECORDS } from '../constants/RecipeType';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { getPath } from '../utils/Values';
import style from './UpdatesPopoverContent.module.scss';
import { getProjectStepInfos, ProjectStepInfo } from './UpdatesUtils';

const UpdatesPopoverContent = connect((state) => {
  const projectInfo = selectActiveProjectInfo(state);
  const projectType = projectInfo.projectType;
  const projectStepInfos = getProjectStepInfos(projectInfo);
  const nextStepInfo = projectStepInfos.find(projectStepInfo => (
    !projectStepInfo.initialized || !projectStepInfo.upToDate
  ));
  const nextStepKey = getPath(nextStepInfo, 'key');
  return { projectStepInfos, nextStepKey, projectType };
})(class UpdatesPopoverContent extends React.Component {
  static propTypes = {
    nextStepKey: PropTypes.string,
    projectStepInfos: ImmutablePropTypes.listOf(PropTypes.instanceOf(ProjectStepInfo)).isRequired,
  };

  getStepName = (stepKey, initialized, projectType) => {
    switch (stepKey) {
      case ProjectStepsKey.RECORDS:
        return initialized ? 'Update Unified Dataset' : 'Generate Unified Dataset';
      case ProjectStepsKey.PAIRS:
        return <span>{initialized ? 'Update' : 'Generate'} <Term>Pairs</Term></span>;
      case ProjectStepsKey.TRAIN_PREDICT_CLUSTERS:
        return <span>{initialized ? 'Update' : 'Generate'} <Term>Pair</Term> Labels and <Term>Suppliers</Term></span>;
      case ProjectStepsKey.CATEGORIZATIONS:
        return initialized ? 'Update Categorizations' : 'Generate Categorizations';
      case ProjectStepsKey.INDEX_DRAFT:
        if (projectType === GOLDEN_RECORDS) {
          return initialized ? 'Update Golden Records' : 'Generate Golden Records';
        }
        if (projectType === ENRICHMENT) {
          return 'Run Enrichment';
        }
        // fallthrough to same error if we can't tell the projectType
      default:
        console.warn(new Error(`could not determine name for updates step with key: ${stepKey}`));
        return undefined;
    }
  };

  renderTitle = () => {
    return (
      <CenterContent vertically={false} className={style.titleContainer}>
        <span className={style.title}>PROJECT STEPS</span>
      </CenterContent>
    );
  };

  renderDescription = () => {
    return (
      <div className={style.description}>
        Please update the following to reflect your recent changes:
      </div>
    );
  };

  renderStep = (key, name, destination, isComplete, isNextStep) => {
    const nodeClassName = classNames(style.stepNode, {
      [style.stepNodeIsComplete]: isComplete,
      [style.stepNodeIsNextStep]: isNextStep,
    });
    const labelClassName = classNames(style.stepLabel, {
      [style.stepLabelIsComplete]: isComplete,
      [style.stepLabelIsNextStep]: isNextStep,
    });
    return (
      <div key={`step-${key}`} className={style.step}>
        <div className={nodeClassName} />
        <div className={style.stepLabelContainer}>
          {destination
            ? <Link className={labelClassName} to={destination}>{name}</Link>
            : <span className={labelClassName}>{name}</span>}
        </div>
      </div>
    );
  };

  renderStepConnector = (key, isComplete) => {
    const className = classNames(style.stepConnector, { [style.stepConnectorIsComplete]: isComplete });
    return (
      <CenterContent key={`connector-${key}`} className={style.stepConnectorContainer}>
        <div className={className} />
      </CenterContent>
    );
  };

  renderSteps = () => {
    const { projectStepInfos, nextStepKey, projectType } = this.props;
    const steps = projectStepInfos.flatMap((projectStepInfo, index) => {
      const { key, destination, initialized, upToDate } = projectStepInfo;
      const name = this.getStepName(key, initialized, projectType);
      const isComplete = initialized && upToDate;
      const isNextStep = key === nextStepKey;
      const rendered = [this.renderStep(key, name, destination, isComplete, isNextStep)];
      if (index < projectStepInfos.size - 1) {
        rendered.push(this.renderStepConnector(key, isComplete));
      }
      return rendered;
    });
    return (
      <div className={style.steps}>
        {steps}
      </div>
    );
  };

  render() {
    return (
      <div className={style.component}>
        {this.renderTitle()}
        {this.renderDescription()}
        {this.renderSteps()}
      </div>
    );
  }
});

export default UpdatesPopoverContent;
