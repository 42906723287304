import { List, Map, Range, Set } from 'immutable';
import _, { isNumber } from 'underscore';

import SortState from '../constants/SortState';
import * as UsersActions from '../constants/UsersActionTypes';
import AuthUser from '../models/AuthUser';
import Document from '../models/doc/Document';
import Group from '../models/Group';
import KeyMods from '../models/KeyMods';
import MinimalAuthUser from '../models/MinimalAuthUser';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import User from '../models/User';
import UserRoles from '../models/UserRoles';
import { StoreReducers } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { AppSelector } from '../utils/Selectors';
import { isBlank } from '../utils/Strings';
import { keyModSelect } from '../utils/TableSelection';
import { isDefined } from '../utils/Values';
import AccountConfig from './utils/AccountConfig';
import AuthUserSortField from './utils/AuthUserSortField';
import GroupConfig from './utils/GroupConfig';

export class UsersStore extends getModelHelpers({
  users: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(MinimalAuthUser)), defaultValue: List<MinimalAuthUser>() },
  fullUsers: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(AuthUser)), defaultValue: List<AuthUser>() },
  fullUsersLoading: { type: ArgTypes.bool, defaultValue: false },
  fullUsersSequence: { type: ArgTypes.number, defaultValue: 0 },
  groupConfig: { type: ArgTypes.nullable(GroupConfig.argType) },
  groups: { type: ArgTypes.Immutable.list.of(Document.argTypeWithNestedClass(Group)), defaultValue: List<Document<Group>>() },
  groupsLoading: { type: ArgTypes.bool, defaultValue: false },
  groupsSequence: { type: ArgTypes.number, defaultValue: 0 },
  loadedFullUsersFilterInfo: { type: ArgTypes.any },
  loadedGroupsFilterInfo: { type: ArgTypes.any },
  selectedTab: { type: ArgTypes.string, defaultValue: 'users' },
  accountConfig: { type: AccountConfig.argType, defaultValue: new AccountConfig({}) },
  showAccountModal: { type: ArgTypes.bool, defaultValue: false },
  editAccountMode: { type: ArgTypes.bool, defaultValue: false },
  activeRowNumber: { type: ArgTypes.nullable(ArgTypes.number) },
  selectedRowNumbers: { type: ArgTypes.Immutable.set.of(ArgTypes.number), defaultValue: Set<number>() },
  query: { type: ArgTypes.string, defaultValue: '' },
  pageNumber: { type: ArgTypes.number, defaultValue: 0 },
  pageSize: { type: ArgTypes.number, defaultValue: 100 },
  sortFieldName: { type: ArgTypes.nullable(ArgTypes.valueIn(_.values(AuthUserSortField))) },
  sortDirection: { type: ArgTypes.nullable(ArgTypes.valueIn(_.values(SortState))) },
  totalUsersMatchingQuery: { type: ArgTypes.number, defaultValue: 0 },
  showChangeActiveStatusDialog: { type: ArgTypes.bool, defaultValue: false },
  changeActiveStatusActivate: { type: ArgTypes.nullable(ArgTypes.bool) },
  userRoles: { type: ArgTypes.nullable(UserRoles.argType), defaultValue: null },
  userRolesLoading: { type: ArgTypes.bool, defaultValue: false },
}, 'UsersStore')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class UsersStoreRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  reduce(reduceFunction: Function, accumulator: any) {
    Array.from(this.toSeq().entries()).forEach(entry => {
      accumulator = reduceFunction(accumulator, entry[1], entry[0]);
    });
    return accumulator;
  }
}

export const initialState = new UsersStore({});

export interface GroupsFilterInfo {
  groupsSequence: number
}

export const getGroupsFilterInfo: AppSelector<GroupsFilterInfo> = ({ users: { groupsSequence } }) => {
  return { groupsSequence };
};

export interface UsersFilterInfo {
  fullUsersSequence: number
}

export const getFullUsersFilterInfo: AppSelector<UsersFilterInfo> = ({ users: { fullUsersSequence } }) => {
  return { fullUsersSequence };
};

export const isUsernameInvalid: AppSelector<boolean> = state => {
  const { users: { accountConfig: { username } } } = state;
  return isBlank(username) || username.length > 255;
};

export const isPasswordInvalid: AppSelector<boolean> = ({ users: { accountConfig: { password, password2 } } }) => {
  return password !== '' && password2 !== '' && password !== password2;
};

export const isPassword2Invalid: AppSelector<boolean> = ({ users: { accountConfig: { password, password2 } } }) => {
  return password2 !== '' && password !== password2;
};

const calculateEmailValid: AppSelector<boolean> = ({ users: { accountConfig: { email } } }) => {
  return !!email && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
};

export const isEmailValid: AppSelector<boolean> = state => {
  const { users: { accountConfig: { email } } } = state;
  return email === '' || !email || calculateEmailValid(state);
};

export const isEditUserAlwaysAdmin: AppSelector<boolean> = ({ users: { accountConfig: { username } } }) => {
  return username === 'admin' || username === 'system';
};

export const getAccountManagementSubmitPreconditions: AppSelector<Map<string, boolean>> = state => {
  const { users: { accountConfig: { username, password, password2 }, editAccountMode } } = state;
  const preconditions = Map<string, boolean>().merge({
    'Username cannot be blank': !isBlank(username),
    'Username cannot be longer than 255 characters': username.length <= 255,
    'Passwords must match': password === password2,
    'Password must have a minimum of 8 characters': isBlank(password) || password.length >= 8,
    'Password must have a maximum of 64 characters': isBlank(password) || password.length <= 64,
    'Email must be valid': isEmailValid(state),
  });
  return editAccountMode ? preconditions : preconditions.set('Password cannot be blank', password !== '');
};

export const getUser = (state: AppState, username: string): User | undefined => {
  return state.users.users.find(minAuth => minAuth.user.username === username)?.user;
};

export const getSelectedFullUser: AppSelector<AuthUser | undefined> = state => {
  const { users: { fullUsers, activeRowNumber } } = state;
  return isNumber(activeRowNumber) && fullUsers.get(activeRowNumber) || undefined;
};

export const getSelectedFullUsers: AppSelector<Set<AuthUser>> = ({ users: { fullUsers, selectedRowNumbers } }) => {
  return selectedRowNumbers.map(rowNumber => fullUsers.get(rowNumber)).filter(isDefined);
};

export const getUsernamesToChangeActiveStatus: AppSelector<Set<string>> = state => {
  return getSelectedFullUsers(state).filter(u => {
    // only get the users whose status is different
    return state.users.changeActiveStatusActivate ? u.user.deactivated : !u.user.deactivated;
  }).map(authUser => authUser.username).filter(username => username !== 'admin' && username !== 'system');
};

export const reducers: StoreReducers<UsersStore> = {
  [UsersActions.START_CREATE_GROUP]: (state) => {
    return state.set('groupConfig', GroupConfig.Create({ groupname: '', description: '', admin: false }));
  },

  [UsersActions.START_EDIT_GROUP]: (state, { id }) => {
    const group = state.groups.find(g => g.id.id === id);
    if (!group) {
      console.error(`Cannot start editing group: cannot find group with id ${id}`);
      return state;
    }
    return state.set('groupConfig', GroupConfig.Edit({
      description: group.data.description,
      admin: group.data.admin,
      currentGroup: group,
    }));
  },

  [UsersActions.SET_GROUP_NAME]: (state, { groupname }) => {
    return !state.groupConfig ? state : state.update('groupConfig', current => current?.case({
      Create: (currentValues) => GroupConfig.Create({ ...currentValues, groupname }),
      Edit: () => current,
      Delete: () => current,
    }));
  },

  [UsersActions.SET_GROUP_DESC]: (state, { description }) => {
    return !state.groupConfig ? state : state.update('groupConfig', current => current?.case({
      Create: (currentValues) => GroupConfig.Create({ ...currentValues, description }),
      Edit: (currentValues) => GroupConfig.Edit({ ...currentValues, description }),
      Delete: () => current,
    }));
  },

  [UsersActions.START_DELETE_GROUP]: (state, { id }) => {
    const group = state.groups.find(g => g.id.id === id);
    if (!group) {
      console.error(`Cannot start deleting group: cannot find group with id ${id}`);
      return state;
    }
    const groupname = group.data.groupname;
    return state.set('groupConfig', GroupConfig.Delete({
      id,
      name: groupname,
      numberOfUsers: state.fullUsers.filter(au => au.groups.includes(groupname)).size,
    }));
  },

  [UsersActions.DELETE_GROUP_COMPLETED]: (state) => {
    return state.delete('groupConfig').update('groupsSequence', n => n + 1);
  },

  [UsersActions.DELETE_GROUP_FAILED]: (state) => {
    return state.delete('groupConfig');
  },

  [UsersActions.TOGGLE_ADMIN_ROLE]: (state) => {
    return !state.groupConfig ? state : state.update('groupConfig', current => current?.case({
      Create: ({ admin, ...currentValues }) => GroupConfig.Create({ ...currentValues, admin: !admin }),
      Edit: ({ admin, ...currentValues }) => GroupConfig.Edit({ ...currentValues, admin: !admin }),
      Delete: () => current,
    }));
  },

  [UsersActions.UPSERT_GROUP_COMPLETED]: (state) => {
    return state.delete('groupConfig').update('groupsSequence', n => n + 1);
  },

  [UsersActions.UPSERT_GROUP_FAILED]: (state) => {
    return state.delete('groupConfig');
  },

  [UsersActions.STOP_GROUP_CONFIG]: (state) => {
    return state.delete('groupConfig');
  },

  [UsersActions.FETCH_GROUPS]: (state) => {
    return state.set('groupsLoading', true);
  },

  [UsersActions.FETCH_GROUPS_COMPLETED]: (state, { data, filterInfo }) => {
    return state.merge({
      groupsLoading: false,
      loadedGroupsFilterInfo: filterInfo,
      groups: data,
    });
  },

  [UsersActions.FETCH_GROUPS_FAILED]: (state, { filterInfo }) => {
    return state.merge({ groupsLoading: false, loadedGroupsFilterInfo: filterInfo });
  },

  [UsersActions.SET_SELECTED_TAB]: (state, { tab }) => {
    return state.set('selectedTab', tab);
  },

  [UsersActions.FETCH_USERS_COMPLETED]: (state, { data }) => {
    return state.set('users', data);
  },

  [UsersActions.FETCH_FULL_USERS]: (state) => {
    return state.set('fullUsersLoading', true);
  },

  [UsersActions.FETCH_FULL_USERS_COMPLETED]: (state, { total, fullUsers, filterInfo }) => {
    return state.merge({
      fullUsersLoading: false,
      fullUsers,
      loadedFullUsersFilterInfo: filterInfo,
      totalUsersMatchingQuery: total,
    });
  },

  [UsersActions.FETCH_FULL_USERS_FAILED]: (state, { filterInfo }) => {
    return state.merge({
      fullUsersLoading: false,
      fullUsers: List(),
      loadedFullUsersFilterInfo: filterInfo,
    });
  },

  [UsersActions.SET_ACCOUNT_CONFIG]: (state, { accountConfig }) => {
    return state.set('accountConfig', accountConfig);
  },

  [UsersActions.SET_SHOW_ACCOUNT_MODAL]: (state, { showAccountModal, editAccountMode, rowNumber }) => {
    checkArg({ editAccountMode }, ArgTypes.nullable(ArgTypes.bool));
    checkArg({ rowNumber }, ArgTypes.nullable(ArgTypes.number));
    const defaultRoles = new UserRoles({ username: '', groups: List(), admin: false });
    const user = isNumber(rowNumber) && state.fullUsers.get(rowNumber);
    return state.merge({
      userRoles: null,
      showAccountModal,
      editAccountMode: !!editAccountMode,
      accountConfig: isNumber(rowNumber) && user ? AccountConfig.fromAuthUser(user, defaultRoles) : new AccountConfig({}),
    });
  },

  [UsersActions.CREATE_USER_COMPLETED]: (state, { users, fullUsers, total }) => {
    return state.merge({ showAccountModal: false, users, fullUsers, totalUsersMatchingQuery: total });
  },

  [UsersActions.CREATE_USER_FAILED]: (state) => {
    return state.set('showAccountModal', false);
  },

  [UsersActions.UPDATE_USER_COMPLETED]: (state, { users, fullUsers, total }) => {
    return state.merge({ showAccountModal: false, users, fullUsers, totalUsersMatchingQuery: total });
  },

  [UsersActions.UPDATE_USER_FAILED]: (state) => {
    return state.set('showAccountModal', false);
  },

  [UsersActions.SET_ACTIVE_ROW_NUMBER]: (state, { rowNumber }) => {
    return state.set('activeRowNumber', rowNumber);
  },
  [UsersActions.SELECT_ROW]: (state, { keyMods, rowNumber }) => {
    checkArg({ keyMods }, ArgTypes.instanceOf(KeyMods));
    checkArg({ rowNumber }, ArgTypes.number);
    const { selectedRows, lastSelectedRow } = keyModSelect({
      keyMods,
      selectedRows: state.selectedRowNumbers,
      selectedRow: rowNumber,
      lastSelectedRow: state.activeRowNumber,
    });
    return state.merge({ selectedRowNumbers: selectedRows, activeRowNumber: lastSelectedRow });
  },
  [UsersActions.SELECT_ALL_ROWS]: (state) => {
    return state.update('selectedRowNumbers', rowNumbers => {
      return rowNumbers.size === state.fullUsers.size ? rowNumbers.clear() : Range(0, state.fullUsers.size).toSet();
    });
  },
  'Location.change': (state) => {
    return state.delete('activeRowNumber').delete('selectedRowNumbers');
  },

  [UsersActions.SET_QUERY]: (state, { query }) => {
    return state.set('query', query).delete('activeRowNumber').delete('selectedRowNumbers').delete('pageNumber');
  },

  [UsersActions.SET_QUERY_COMPLETED]: (state, { users, fullUsers, total }) => {
    return state.merge({ users, fullUsers, totalUsersMatchingQuery: total });
  },

  [UsersActions.SET_PAGE_NUMBER]: (state, { pageNumber }) => {
    return state.set('pageNumber', pageNumber).delete('activeRowNumber').delete('selectedRowNumbers');
  },

  [UsersActions.SET_PAGE_NUMBER_COMPLETED]: (state, { users, fullUsers, total }) => {
    return state.merge({ users, fullUsers, totalUsersMatchingQuery: total });
  },

  [UsersActions.SET_PAGE_SIZE]: (state, { pageSize }) => {
    return state.set('pageSize', pageSize).delete('activeRowNumber').delete('selectedRowNumbers').delete('pageNumber');
  },

  [UsersActions.SET_PAGE_SIZE_COMPLETED]: (state, { users, fullUsers, total }) => {
    return state.merge({ users, fullUsers, totalUsersMatchingQuery: total });
  },

  [UsersActions.SET_SORT]: (state, { sortFieldName, sortDirection }) => {
    return state.merge({ sortFieldName, sortDirection }).delete('activeRowNumber').delete('selectedRowNumbers').delete('pageNumber');
  },

  [UsersActions.SET_SORT_COMPLETED]: (state, { users, fullUsers, total }) => {
    return state.merge({ users, fullUsers, totalUsersMatchingQuery: total });
  },

  [UsersActions.HIDE_CHANGE_ACTIVE_STATUS_DIALOG]: (state) => {
    return state.set('showChangeActiveStatusDialog', false).delete('changeActiveStatusActivate');
  },

  [UsersActions.SET_CHANGE_ACTIVE_STATUS_ACTIVATE]: (state, { activate }) => {
    return state.merge({ changeActiveStatusActivate: activate, showChangeActiveStatusDialog: true });
  },

  [UsersActions.SUBMIT_CHANGE_ACTIVE_STATUS_COMPLETED]: (state, { users, fullUsers, total }) => {
    return state.merge({ users, fullUsers, totalUsersMatchingQuery: total }).delete('showChangeActiveStatusDialog').delete('changeActiveStatusActivate').delete('selectedRowNumbers');
  },

  [UsersActions.SUBMIT_CHANGE_ACTIVE_STATUS_FAILED]: (state) => {
    return state.delete('showChangeActiveStatusDialog').delete('changeActiveStatusActivate').delete('selectedRowNumbers');
  },

  [UsersActions.FETCH_USER_ROLES]: (state) => {
    return state.set('userRolesLoading', true);
  },

  [UsersActions.FETCH_USER_ROLES_COMPLETED]: (state, { userRoles }) => {
    return state.merge({
      accountConfig: state.accountConfig.set('admin', userRoles.admin),
      userRoles,
      userRolesLoading: false,
    });
  },

  [UsersActions.FETCH_USER_ROLES_FAILED]: (state) => {
    return state.set('userRolesLoading', false);
  },

  [UsersActions.SET_USER_ROLES]: (state, { userRoles }) => {
    return state.set('userRoles', userRoles);
  },
};
