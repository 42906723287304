// com.tamr.dataset.models.Dataset
import { List, Map, Set } from 'immutable';

import { ArgTypes } from '../utils/ArgValidation';
import DocumentId from './doc/DocumentId';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

class Dataset extends getModelHelpers({
  name: { type: ArgTypes.string },
  fragments: { type: ArgTypes.Immutable.map.of(ArgTypes.instanceOf(DocumentId), ArgTypes.string) },
  upstreamDatasets: { type: ArgTypes.Immutable.set.of(ArgTypes.instanceOf(DocumentId)) },
  fields: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
  idFields: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
  description: { type: ArgTypes.nullable(ArgTypes.string) },
  metadata: { type: ArgTypes.Immutable.map },
  pinnedUpstreamDatasetVersions: { type: ArgTypes.Immutable.map.of(ArgTypes.positiveInteger, ArgTypes.string) },
  pinnedVersion: { type: ArgTypes.nullable(ArgTypes.positiveInteger) },
}, 'Dataset')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class DatasetRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: any) {
    const { name, description, pinnedVersion, pinnedUpstreamDatasetVersions } = obj;
    return new Dataset({
      name,
      description,
      pinnedVersion,
      pinnedUpstreamDatasetVersions: Map(pinnedUpstreamDatasetVersions),
      // @ts-expect-error
      fragments: Map(obj.fragments).map(docId => new DocumentId(docId)),
      // @ts-expect-error
      upstreamDatasets: Set(obj.upstreamDatasets).map(ud => new DocumentId(ud)),
      fields: List(obj.fields),
      idFields: List(obj.idFields),
      metadata: Map(obj.metadata),
    });
  }
  get asSpec() {
    const { name, fields, idFields, description, metadata } = this;
    return { name, fields, idFields, description, metadata };
  }
}

export default Dataset;
