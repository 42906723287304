import classNames from 'classnames';
import React from 'react';

import style from './Transform.module.scss';

const TransformSection: React.FC<{
  children?: React.ReactNode
  className?: string
}> = ({ children, className }) => {
  return (<div className={classNames(style.section, className)}>{children}</div>);
};

export default TransformSection;
