/* eslint-disable newline-per-chained-call */
import { List, Set } from 'immutable';

import { getBulkPrivileges } from '../api/AuthClient';
import { UNTAGGED_ID } from '../constants/UntaggedId';
import { doFetchDatasetsWithQuery } from '../datasets/DatasetsApi';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import { QueryBuilder } from '../models/doc/Query';
import { RECIPE_INPUTS_KEY } from '../projects/RecipeConstants';
import { AppThunkAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { getSMRecipeWithStatus, selectActiveProjectInfo } from '../utils/Selectors';
import { getFilterInfo } from './DatasetFilterStore';


export const DATASET_READ = 'dataset.read';

// produced a modifyQuery compatible function that only allows input datasets to the active recipe
export const onlyInputDatasets = (state: AppState) => {
  const smRecipeWithStatus = getSMRecipeWithStatus(state);
  const smRecipeId = smRecipeWithStatus ? smRecipeWithStatus.recipe.id.id : -1;
  return (query: QueryBuilder) => query.whereData('metadata', RECIPE_INPUTS_KEY).containsInteger(smRecipeId);
};

export const tagJsonBuilder = (selectedTags: Set<number>): { id: number, namespace: string }[] => {
  // This builds an array of arrays of Json Obj because that is how psql determines intersection
  // TODO<Rori> add additional array in the backend, not here
  const selectedTagsObjArraysList: { id: number, namespace: string }[] = [];
  selectedTags.map(tagId => {
    selectedTagsObjArraysList.push({ id: tagId, namespace: 'internal_tags' });
  });
  return selectedTagsObjArraysList;
};

export const fetchDatasets = (
  modifyQuery: ((query: QueryBuilder) => void) | undefined,
  getSelectedDatasets: (datasets: List<Document<Dataset>>) => Set<string>,
  loadAllDatasets: boolean,
): AppThunkAction<void> => (dispatch, getState) => {
  dispatch({ type: 'DatasetFilter.fetchDatasets' });
  const state = getState();
  const activeProjectInfo = selectActiveProjectInfo(state);
  const filterInfo = getFilterInfo(state.datasetFilter);
  const { page, pageSize, searchValue, selectedTags } = filterInfo;
  const offset = page * pageSize;
  const query = new QueryBuilder().offset(offset).limit(pageSize);
  if (searchValue) {
    query.whereData('name').isLike(searchValue);
  }
  if (selectedTags && !selectedTags.isEmpty()) { // get only the datasources with the right tag ids
    const selectedTagsObjArraysList = tagJsonBuilder(selectedTags);
    if (!selectedTags.contains(UNTAGGED_ID)) {
      query.whereData('metadata', 'tag').containsAnyObject(selectedTagsObjArraysList); // contains an object where the id is this id
    } else {
      query.disjunct()
        .whereData('metadata', 'tag').isNull()
        .whereData('metadata', 'tag').empty()
        .whereData('metadata', 'tag').containsAnyObject(selectedTagsObjArraysList) // datasets with the selected tag ids
        .buildDisjunct();
    }
  }
  if (modifyQuery) {
    modifyQuery(query);
  }
  doFetchDatasetsWithQuery(query.build())
    .then(({ datasets, numDatasets }) => {
      const selectedDatasets = getSelectedDatasets(datasets);
      if (activeProjectInfo) {
        const projectId = `RESOURCE:projects/${activeProjectInfo.projectId}`;
        const resources = datasets.map(ds => `datasets/${ds.id.id}`);
        getBulkPrivileges(projectId, DATASET_READ, resources)
          .then(validResources => {
            const authorizedIds = validResources.map(resource => parseInt(resource.parts.last(), 10));
            const disabledDatasetIds = datasets.map(ds => ds.id.id).filter(id => !authorizedIds.contains(id));
            dispatch({ type: 'DatasetFilter.fetchDatasetsCompleted', disabledDatasetIds, selectedDatasets, datasets, numDatasets, filterInfo, activeRecipeId: loadAllDatasets ? null : activeProjectInfo?.smRecipeWithStatus?.recipe.id.id });
          });
      } else {
        const disabledDatasetIds = List();
        dispatch({ type: 'DatasetFilter.fetchDatasetsCompleted', disabledDatasetIds, selectedDatasets, datasets, numDatasets, filterInfo, activeRecipeId: null });
      }
    });
};
