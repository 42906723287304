import { is, List, Map, Set } from 'immutable';
import { isNumber } from 'underscore';

import * as DatasetClient from '../api/DatasetClient';
import { FetchError } from '../api/FetchResult';
import * as RecipeClient from '../api/RecipeClient';
import * as TransformClient from '../api/TransformClient';
import {
  INDEX_DRAFT,
  PROFILE,
  UPDATE_PUBLISHED_DATASETS_AND_PROFILE,
  UPDATE_SOURCE_LIST,
} from '../constants/RecipeOperations';
import { SHOW } from '../errorDialog/ErrorDialogActionTypes';
import { apiError } from '../errorDialog/ErrorDialogUtils';
import Dataset from '../models/Dataset';
import DatasetStatus from '../models/DatasetStatus';
import { QueryBuilder } from '../models/doc/Query';
import TypedTable from '../models/TypedTable';
import { CANCEL_EDITING_PROJECT } from '../projects/ProjectsActionTypes';
import { fetch as fetchAllProjects } from '../projects/ProjectsApi';
import { AppAction, AppThunkAction } from '../stores/AppAction';
import { AppDispatch } from '../stores/MainStore';
import ContentEnvelope, { ContentEnvelopeTypes } from '../transforms/models/ContentEnvelope';
import { AnalysisInput, OperationListResult } from '../transforms/models/StaticAnalysisModels';
import { Field, RecordType } from '../transforms/models/Types';
import { setModulePreferences, updateGlobalPreferences } from '../users/UsersAsync';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { merge, push, removeIndex, set, union, update } from '../utils/Collections';
import executeSequentially from '../utils/ExecuteSequentially';
import PRODUCT_NAME from '../utils/ProductName';
import * as Result from '../utils/Result';
import { getAuthorizedUser } from '../utils/Selectors';
import SortUtils from '../utils/SortUtils';
import { isDefined, jsonContentToString } from '../utils/Values';
import * as ClusterSampleState from './ClusterSampleState';
import * as df from './DatasetFilter';
import * as fcr from './FilterClusterRecords';
import { GoldenRecordsActionConfirmationTypeE } from './GoldenRecordsActionConfirmationType';
import {
  CANCEL_RULE,
  CLEAR_SELECTION,
  DELETE_RULE,
  EXCLUDE_UNACCOUNTED_SOURCES_COMPLETED,
  FETCH_ALL_OVERRIDE_STATS_COMPLETED,
  FETCH_CLUSTER_PROFILE_JOB,
  FETCH_CLUSTER_PROFILE_JOB_COMPLETED,
  FETCH_CLUSTER_PROFILE_JOB_FAILED,
  FETCH_CLUSTER_SAMPLE,
  FETCH_CLUSTER_SAMPLE_COMPLETED,
  FETCH_CLUSTER_SAMPLE_FAILED,
  FETCH_DRAFT_RECORD_TOTAL,
  FETCH_DRAFT_RECORD_TOTAL_COMPLETED,
  FETCH_DRAFT_RECORD_TOTAL_FAILED,
  FETCH_MODULE,
  FETCH_MODULE_COMPLETED,
  FETCH_MODULE_FROM_LAST_PUBLISH_COMPLETED,
  FETCH_MODULE_FROM_LAST_UPDATE,
  FETCH_MODULE_FROM_LAST_UPDATE_COMPLETED,
  FETCH_MODULE_FROM_LAST_UPDATE_FAILED,
  FETCH_OVERRIDE_STATS_FOR_ATTRIBUTE,
  FETCH_SOURCE_LIST,
  FETCH_SOURCE_LIST_COMPLETED,
  FETCH_SOURCE_LIST_FAILED,
  PUBLISH,
  PUBLISH_COMPLETED,
  PUBLISH_FAILED,
  QUERY_DRAFT,
  QUERY_DRAFT_COMPLETED,
  QUERY_DRAFT_FAILED,
  SAVE_ALL_RULES,
  SAVE_ALL_RULES_COMPLETED,
  SAVE_ALL_RULES_FAILED,
  SAVE_MODULE_INVALID,
  SAVE_RULE,
  SAVE_RULE_COMPLETED,
  SAVE_RULE_FAILED,
  SHOW_CONFLICT_DIALOG,
  SUBMIT_PROFILE_JOB,
  SUBMIT_PROFILE_JOB_FAILED,
  SUBMIT_UPDATE_CLUSTER_PROFILE_JOB,
  SUBMIT_UPDATE_CLUSTER_PROFILE_JOB_FAILED,
  SUBMIT_UPDATE_GOLDEN_RECORDS_DRAFT,
  SUBMIT_UPDATE_GOLDEN_RECORDS_DRAFT_FAILED,
  SUBMIT_UPDATE_SOURCE_LIST_JOB,
  SUBMIT_UPDATE_SOURCE_LIST_JOB_FAILED,
  UPDATE_OVERRIDE,
  UPDATE_OVERRIDE_COMPLETED,
} from './GoldenRecordsActionTypes';
import * as GoldenRecordsAPI from './GoldenRecordsAPI';
import * as GoldenRecordsDraftQuery from './GoldenRecordsDraftQuery';
import * as GoldenRecordsModule from './GoldenRecordsModule';
import * as rulesPageActions from './GoldenRecordsRulesActionTypes';
import {
  getModuleVersionOfLastRunIndexDraft,
  selectBookmarkedClusterIds,
  selectFilterInfo,
  selectInputAttributesTypes,
  selectInputRecordsFilterInfo,
  selectModuleFilterInfo,
  selectModuleForPreview,
  selectPreviewFilterInfo,
} from './GoldenRecordsSelectors';
import {
  findRuleDeltaByName,
  getRuleFilter,
  selectAggregationContentEnvelope,
  selectAllRuleNames,
  selectClusterProfileJobFilterInfo,
  selectDatasetsNewToRules,
  selectSelectedClusterIds,
  selectSourceListFilterInfo,
} from './GoldenRecordsStore';
import { getClusterNameOfPreviewedRecord } from './GoldenRecordsUtils';
import { lastRunIndexDraft } from './ModuleStatus';
import * as Predicate from './Predicate';
import { Rule } from './Rule';
import { RuleDelta, RuleDeltaTypes } from './RuleDelta';


export const updateClusterProfile = (): AppThunkAction<void> => async (dispatch, getState) => {
  const { moduleId } = getState().location;
  dispatch({ type: SUBMIT_UPDATE_CLUSTER_PROFILE_JOB, moduleId });
  try {
    await GoldenRecordsAPI.updateClusterProfile(moduleId);
  } catch ({ data, message }) {
    dispatch({ type: SUBMIT_UPDATE_CLUSTER_PROFILE_JOB_FAILED, data, message });
  }
};

export const updateSourceList = (): AppThunkAction<void> => async (dispatch, getState) => {
  const { moduleId } = getState().location;
  dispatch({ type: SUBMIT_UPDATE_SOURCE_LIST_JOB, moduleId });
  try {
    await RecipeClient.runModuleJob({
      moduleId,
      jobName: UPDATE_SOURCE_LIST,
      errorMessage: 'Unable to submit job to update Golden Records source list',
    });
  } catch ({ data, message }) {
    dispatch({ type: SUBMIT_UPDATE_SOURCE_LIST_JOB_FAILED, data, message });
  }
};

function excludeDatasetsInRule<T extends Rule>(rule: T, datasetNames: Set<string> | undefined): T {
  return update(rule, 'filters', (filters) => filters
    .map(f => (f.type === df.TYPE
      ? update(f, 'excluded', e => union(e, datasetNames?.toArray() || []))
      : f)),
  );
}

function updateModuleToExcludeUnaccountedSources(module: GoldenRecordsModule.GoldenRecordsModule, datasetsNewToRules: Map<string, Set<string>>): GoldenRecordsModule.GoldenRecordsModule {
  const rulesToNewDatasets = datasetsNewToRules
    .reduce((memo, ruleNames, datasetName) => {
      return memo.mergeDeep(Map(ruleNames.map(rn => [rn, Set.of(datasetName)])));
    }, Map<string, Set<string>>());
  const withUpdatedEntityRule = update(module,
    m => (rulesToNewDatasets.has(m.entityRule.outputAttributeName)
      ? update(m, 'entityRule', er => excludeDatasetsInRule(er, rulesToNewDatasets.get(m.entityRule.outputAttributeName)))
      : m),
  );
  const updatedModule = update(withUpdatedEntityRule,
    m => update(m, 'rules', rules => rules.map(r => (rulesToNewDatasets.has(r.outputAttributeName)
      ? excludeDatasetsInRule(r, rulesToNewDatasets.get(r.outputAttributeName))
      : r))),
  );
  return updatedModule;
}

/**
 * Hits the Publish API to start a job. Always assumed to be publishing from the Staged versions.
 */
export const confirmPublishGoldenRecords = (): AppThunkAction<void> => async (dispatch, getState) => {
  const { goldenRecords: { goldenRecordDocument }, location: { moduleId } } = getState();
  if (!goldenRecordDocument) {
    console.error('publish called without a valid golden records document');
    return;
  }
  try {
    dispatch({ type: PUBLISH });
    await RecipeClient.runModuleJob({
      moduleId,
      jobName: UPDATE_PUBLISHED_DATASETS_AND_PROFILE,
      errorMessage: 'Unable to submit Publish job for Golden Records project',
    });
    dispatch({ type: PUBLISH_COMPLETED });
  } catch ({ data, message }) {
    dispatch({ type: PUBLISH_FAILED, data, message });
  }
};

/**
 * Submits an Update Golden Records (indexDraft) job. May also submit a publish job as well
 *   if that is what's being confirmed.
 * @param excludeUnaccountedSources whether to first update the module to exclude unaccounted sources
 *        in all DatasetFilters
 */
export const confirmUpdateGoldenRecords = (excludeUnaccountedSources: boolean): AppThunkAction<void> => async (dispatch, getState) => {
  const { goldenRecords, goldenRecords: { confirmingAction, goldenRecordDocument }, location: { moduleId } } = getState();
  if (!goldenRecordDocument) {
    console.error('update golden records draft called without a valid golden records document');
    return;
  }
  if (confirmingAction !== GoldenRecordsActionConfirmationTypeE.UPDATE
    && confirmingAction !== GoldenRecordsActionConfirmationTypeE.UPDATE_AND_PUBLISH) {
    console.error('update golden records draft called without having been in confirming UPDATE or UPDATE_AND_PUBLISH state:', { confirmingAction });
  }
  const datasetsNewToRules = selectDatasetsNewToRules(goldenRecords);
  try {
    dispatch({ type: SUBMIT_UPDATE_GOLDEN_RECORDS_DRAFT });

    // first, if we need to, update the GR module to exclude unaccounted sources
    if (excludeUnaccountedSources) {
      const updatedModule = updateModuleToExcludeUnaccountedSources(goldenRecordDocument.data, datasetsNewToRules);
      const updateModuleResult = await RecipeClient.updateModule({
        moduleId,
        version: goldenRecordDocument.version,
        module: updatedModule,
        fromJSON: GoldenRecordsModule.fromJSON,
      });

      Result.handle(updateModuleResult,
        updatedModuleDoc => {
          dispatch({ type: EXCLUDE_UNACCOUNTED_SOURCES_COMPLETED, moduleDoc: updatedModuleDoc });
        },
        error => apiError(dispatch,
          'Unable to update Golden Record module to exclude unaccounted sources before updating',
          error,
        ),
      );
    }

    // always submit the update job
    await RecipeClient.runModuleJob({
      moduleId,
      jobName: INDEX_DRAFT,
      errorMessage: 'Unable to submit update Golden Records draft job',
    });

    // if the job is an update and publish, submit the update and publish job:
    if (confirmingAction === GoldenRecordsActionConfirmationTypeE.UPDATE_AND_PUBLISH) {
      await RecipeClient.runModuleJob({
        moduleId,
        jobName: UPDATE_PUBLISHED_DATASETS_AND_PROFILE,
        errorMessage: 'Unable to submit publish Golden Records job',
      });
    }
  } catch ({ data, message }) {
    dispatch({ type: SUBMIT_UPDATE_GOLDEN_RECORDS_DRAFT_FAILED, data, message });
  }
};

// since both usually need to be run, just profile everything
export const profileInputDataset = (): AppThunkAction<void> => async (dispatch, getState) => {
  const { moduleId } = getState().location;
  dispatch({ type: SUBMIT_PROFILE_JOB, moduleId });
  try {
    await RecipeClient.runModuleJob({
      moduleId,
      jobName: PROFILE,
      errorMessage: 'Unable to submit job to profile everything for Golden Records project',
    });
  } catch ({ data, message }) {
    dispatch({ type: SUBMIT_PROFILE_JOB_FAILED, data, message });
  }
};

export const submitJobsOnModuleCreation = (moduleId: number) => {
  const jobSubmissionLambdas = [
    () => GoldenRecordsAPI.indexDraft(moduleId),
    () => GoldenRecordsAPI.updateSourceList(moduleId),
    () => GoldenRecordsAPI.updateClusterProfile(moduleId),
  ];
  return executeSequentially(jobSubmissionLambdas);
};

function datasetMaterializationIsAvailable(datasetStatus: DatasetStatus): boolean {
  return datasetStatus.currentRevision === datasetStatus.lastMaterializedVersion;
}

// this assumes that the pinned dataset has exactly ONE direct upstream dataset
export function pinnedDatasetIsUpToDate(pinnedDataset: Dataset, upstreamDatasetStatus: DatasetStatus): boolean {
  return is(pinnedDataset.pinnedUpstreamDatasetVersions, Map().set(upstreamDatasetStatus.datasetName, upstreamDatasetStatus.currentRevision));
}

export const fetchClusterProfileJob = (): AppThunkAction<void> => async (dispatch, getState) => {
  const { goldenRecords, goldenRecords: { goldenRecordDocument } } = getState();
  if (!goldenRecordDocument) {
    console.error('Cannot fetch cluster profile job - no loaded golden records module document');
    return;
  }
  const filterInfo = selectClusterProfileJobFilterInfo(goldenRecords);
  const { publishedDatasetName } = filterInfo;
  if (!publishedDatasetName) {
    console.error('Cannot fetch cluster profile job - no published golden records dataset name');
    return;
  }
  const clusterDatasetName = goldenRecordDocument.data.clusterDataset.id;
  const clusterProfileDatasetName = GoldenRecordsModule.getClusterProfileDatasetName(publishedDatasetName);
  const pinnedClusterDatasetName = GoldenRecordsModule.getPinnedClusterDatasetName(goldenRecordDocument.data);
  dispatch({ type: FETCH_CLUSTER_PROFILE_JOB });
  try {
    const [pinnedClustersMetadata, datasetStatuses] = await Promise.all([
      DatasetClient.getDatasetByName(pinnedClusterDatasetName),
      DatasetClient.postStatusQuery(
        new QueryBuilder().whereData('name').isInStrings([pinnedClusterDatasetName, clusterProfileDatasetName, clusterDatasetName]).build(),
      ),
    ]);
    const pinnedClustersDatasetStatus = datasetStatuses.find(s => s.datasetName === pinnedClusterDatasetName);
    const clusterProfileDatasetStatus = datasetStatuses.find(s => s.datasetName === clusterProfileDatasetName);
    const inputDatasetStatus = datasetStatuses.find(s => s.datasetName === clusterDatasetName);
    const clusterProfileMaterializationIsAvailable = clusterProfileDatasetStatus && datasetMaterializationIsAvailable(clusterProfileDatasetStatus);

    const clusterProfileJobOutOfDate = !(
      clusterProfileMaterializationIsAvailable &&
      (inputDatasetStatus && pinnedDatasetIsUpToDate(pinnedClustersMetadata.data, inputDatasetStatus))
    );

    // detect whether the preview feature is usable - are materializations available, even if out of date?
    const pinnedClustersMaterializationIsAvailable = pinnedClustersDatasetStatus && datasetMaterializationIsAvailable(pinnedClustersDatasetStatus);
    const previewIsUsable = !!(pinnedClustersMaterializationIsAvailable && clusterProfileMaterializationIsAvailable);

    dispatch({ type: FETCH_CLUSTER_PROFILE_JOB_COMPLETED,
      clusterProfileJobOutOfDate,
      previewIsUsable,
      filterInfo,
    });
  } catch ({ data, message }) {
    dispatch({ type: FETCH_CLUSTER_PROFILE_JOB_FAILED, data, message, filterInfo });
  }
};

export const queryDraft = (): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  const filterInfo = selectFilterInfo(state);
  const moduleId = state.location.moduleId;
  const search = filterInfo.searchString?.trim();
  const queryParameters: GoldenRecordsDraftQuery.GoldenRecordsDraftQuery = {
    offset: filterInfo.pageNum * filterInfo.pageSize,
    limit: filterInfo.pageSize,
    sortOptions: SortUtils.getUrlSortStates(filterInfo.columnSortStates),
    searchString: search || undefined,
    hasOverrides: (filterInfo.hasOverridesForAttribute || Set()).isEmpty() ? null : filterInfo.hasOverridesForAttribute.toArray(),
    includedClusters: filterInfo.includedClusters.toArray(),
    excludedClusters: filterInfo.excludedClusters.toArray(),
  };
  dispatch({ type: QUERY_DRAFT, moduleId, queryParameters });
  RecipeClient.queryGoldenRecordsDraft(moduleId, queryParameters).then(Result.handler(
    page => dispatch({ type: QUERY_DRAFT_COMPLETED, page, filterInfo }),
    error => apiError(
      dispatch,
      'Unable to query Golden Records draft',
      error,
      { type: QUERY_DRAFT_FAILED, filterInfo },
    ),
  ));
};

const totalRecordsQuery: GoldenRecordsDraftQuery.GoldenRecordsDraftQuery = {
  offset: 0,
  limit: 0,
  sortOptions: [],
  searchString: null,
  hasOverrides: null,
  includedClusters: [],
  excludedClusters: [],
};

export const fetchModuleFromLastUpdate = (): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  const { location: { moduleId } } = state;
  const moduleFromLastUpdateVersion = getModuleVersionOfLastRunIndexDraft(state);
  if (!moduleFromLastUpdateVersion) {
    console.error('Cannot fetch module from last update - unknown module version for last update');
    return;
  }
  dispatch({ type: FETCH_MODULE_FROM_LAST_UPDATE });
  GoldenRecordsAPI.getLoggedModuleVersion(moduleId, moduleFromLastUpdateVersion).then(Result.handler(
    moduleFromLastUpdate => dispatch({
      type: FETCH_MODULE_FROM_LAST_UPDATE_COMPLETED,
      moduleFromLastUpdate,
      moduleFromLastUpdateVersion,
    }),
    error => apiError(
      dispatch,
      'Unable to fetch module at version of last Update',
      error,
      { type: FETCH_MODULE_FROM_LAST_UPDATE_FAILED, moduleFromLastUpdateVersion },
    ),
  ));
};

export const fetchDraftRecordTotal = (): AppThunkAction<void> => async (dispatch, getState) => {
  const { location: { moduleId }, goldenRecords: { moduleStatus } } = getState();
  const moduleFromLastUpdateVersion = moduleStatus && lastRunIndexDraft(moduleStatus);
  if (!moduleFromLastUpdateVersion) {
    console.error('Cannot fetch draft record total - unknown module version for last update');
    return;
  }
  dispatch({ type: FETCH_DRAFT_RECORD_TOTAL });
  RecipeClient.queryGoldenRecordsDraft(moduleId, totalRecordsQuery).then(Result.handler(
    totalRecordsDraftPage => dispatch({
      type: FETCH_DRAFT_RECORD_TOTAL_COMPLETED,
      moduleFromLastUpdateVersion,
      totalRecords: totalRecordsDraftPage.total,
    }),
    error => apiError(
      dispatch,
      'Unable to fetch Golden Records draft dataset page to determine total number of records',
      error,
      { type: FETCH_DRAFT_RECORD_TOTAL_FAILED, moduleFromLastUpdateVersion },
    ),
  ));
};

export const fetchAllOverrideStats = (ruleName?: string): AppThunkAction<void> => async (dispatch, getState) => {
  // if ruleName is not defined, get overrideTotals for all rules, inc. entity rule
  const { goldenRecords, location: { moduleId } } = getState();
  const ruleNamesOrdered = (ruleName === undefined) ? selectAllRuleNames(goldenRecords).toList() : List.of(ruleName);
  try {
    const overrideStats = await RecipeClient.queryGoldenRecordsOverrideStats(moduleId, ruleNamesOrdered);
    if (ruleName) {
      const overrideStatsForAttribute = overrideStats.get(ruleName);
      if (overrideStatsForAttribute) {
        dispatch({ type: FETCH_OVERRIDE_STATS_FOR_ATTRIBUTE, attributeName: ruleName, overrideStats: overrideStatsForAttribute });
      }
    } else {
      dispatch({ type: FETCH_ALL_OVERRIDE_STATS_COMPLETED, overrideStats });
    }
  } catch ({ data, message }) {
    dispatch({ type: SHOW, title: 'Unable to query Golden Records override totals', detail: message, response: { responseText: data?.stackTrace?.join('\n') } });
  }
};

const showConfigError = (moduleName: string, errors: string[]): AppThunkAction<void> => (dispatch) => {
  dispatch({
    type: SHOW,
    title: 'The project has errors',
    detail: `The golden records project '${moduleName}' is not configured correctly. ` +
      'The golden records configuration must include:\n' +
      ' - The name of the input dataset.\n' +
      ' - The name of the attribute from the input dataset that maps a record to a source dataset (sourceColumn).\n' +
      ' - The attribute name with the cluster ID values for the clusters in the input dataset (clusterColumn).\n' +
      ' - The names of the attributes used to construct golden records rules.\n' +
      ' - The name of the overrides dataset.\n\n' +
      `Verify that these datasets and attributes exist in ${PRODUCT_NAME} and are valid.`,
    response: { responseText: errors.join('\n') },
  });
};

// consistent with the projects fetch actions - fetches module status along with module every time
export const fetchModule = (): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  const { location: { moduleId }, goldenRecords: { fetchAllOverrides } } = state;
  if (!isNumber(moduleId)) {
    console.error('Canont fetch module - moduleId is not deifned');
    return;
  }
  const filterInfo = selectModuleFilterInfo(state);
  dispatch({ type: FETCH_MODULE, moduleId });
  return Promise.all([
    GoldenRecordsAPI.fetchGoldenRecordsModuleDocument(moduleId),
    RecipeClient.getModuleStatus(moduleId),
  ]).then(([moduleDoc, moduleStatusResult]) => {
    Result.handle(moduleStatusResult,
      moduleStatus => {
        dispatch({ type: FETCH_MODULE_COMPLETED, moduleDoc, moduleStatus, filterInfo });

        if (moduleStatus.configValidationErrors.length !== 0) {
          dispatch(showConfigError(moduleDoc.data.displayName, moduleStatus.configValidationErrors));
        }

        const { lastPublishedModuleVersion } = moduleStatus;
        if (lastPublishedModuleVersion) {
          RecipeClient.getModuleAtVersion(moduleId, lastPublishedModuleVersion, GoldenRecordsModule.fromJSON).then(Result.handler(
            module => dispatch({ type: FETCH_MODULE_FROM_LAST_PUBLISH_COMPLETED, module }),
            error => apiError(
              dispatch,
              `Unable to fetch module with id ${moduleId} at version of most recent publish ${lastPublishedModuleVersion}`,
              error,
            ),
          ));
        }

        TransformClient.getDatasetType(moduleDoc.data.clusterDataset.id)
          .then(schema => dispatch({ type: 'GoldenRecords.fetchInputDatasetSchemaCompleted', schema }));
        if (fetchAllOverrides && moduleStatus && lastRunIndexDraft(moduleStatus) !== undefined) {
          dispatch(fetchAllOverrideStats());
        }
      },
      error => apiError(dispatch, `Unable to get module status for module ${moduleId}`, error),
    );
  }).catch(({ data, message }) => {
    dispatch({ type: SHOW, title: `Unable to fetch module with id ${moduleId}`, detail: message, response: { responseText: data?.stackTrace?.join('\n') } });
  });
};

export const fetchSourceList = (): AppThunkAction<void> => async (dispatch, getState) => {
  const { goldenRecords, goldenRecords: { goldenRecordDocument } } = getState();
  if (!goldenRecordDocument) {
    console.error('Cannot fetch source list - no loaded golden records module document');
    return;
  }
  const filterInfo = selectSourceListFilterInfo(goldenRecords);
  const { sourceListDatasetName } = filterInfo;
  if (!sourceListDatasetName) {
    console.error('Cannot fetch source list - no source list dataset name');
    return;
  }
  const clusterDatasetName = goldenRecordDocument.data.clusterDataset.id;
  const pinnedClusterDatasetName = GoldenRecordsModule.getPinnedClusterDatasetName(goldenRecordDocument.data);
  const sourceListDiffDatasetName = GoldenRecordsModule.getSourceListDiffDatasetName(sourceListDatasetName);
  dispatch({ type: FETCH_SOURCE_LIST });
  return Promise.all([
    GoldenRecordsAPI.fetchSourceList(sourceListDiffDatasetName),
    DatasetClient.getDatasetByName(sourceListDiffDatasetName),
    DatasetClient.getDatasetByName(pinnedClusterDatasetName),
    DatasetClient.postStatusQuery(
      new QueryBuilder().whereData('name').isInStrings([clusterDatasetName, sourceListDatasetName]).build(),
    ),
  ]).then(([sourceListDiffRecords, sourceListDiffMetadata, pinnedClustersMetadata, inputStatuses]) => {
    const inputDatasetStatus = inputStatuses.find(s => s.datasetName === clusterDatasetName);
    const sourceListDatasetStatus = inputStatuses.find(s => s.datasetName === sourceListDatasetName);

    // this check should also include "is the source list materialization available", but that is implicitly true by the fetchSourceList call returning successfully
    const sourceListOutOfDate = !(
      (sourceListDatasetStatus && pinnedDatasetIsUpToDate(sourceListDiffMetadata.data, sourceListDatasetStatus)) &&
      (inputDatasetStatus && pinnedDatasetIsUpToDate(pinnedClustersMetadata.data, inputDatasetStatus))
    );

    dispatch({ type: FETCH_SOURCE_LIST_COMPLETED,
      sourceListOutOfDate,
      sourceList: sourceListDiffRecords,
      filterInfo,
    });
  }).catch(({ data, message }) => {
    // this also means the source list is "out of date"
    dispatch({ type: FETCH_SOURCE_LIST_FAILED, data, message, filterInfo });
  });
};

export const setBookmarkedClusterIds = (bookmarkedClusterIds: Set<string>): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const user = getAuthorizedUser(state);
  const moduleId = state.location.moduleId;
  // if preferences are not found, create an empty preferences object
  const modulePrefs = user?.preferencesForModuleById(moduleId) || { moduleId };
  modulePrefs.bookmarkedClusterIds = bookmarkedClusterIds.toArray();
  dispatch(setModulePreferences(modulePrefs));
};

export const toggleBookmarkedClusterId = (clusterId: string): AppThunkAction<void> => (dispatch, getState) => {
  const currentBookmarkedClusterIds = selectBookmarkedClusterIds(getState());
  const updatedBookmarkedClusterIds = currentBookmarkedClusterIds.has(clusterId) ? currentBookmarkedClusterIds.remove(clusterId) : currentBookmarkedClusterIds.add(clusterId);
  dispatch(setBookmarkedClusterIds(updatedBookmarkedClusterIds));
};

export const addBookmarksForSelectedRows = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const selectedClusterIds = selectSelectedClusterIds(state.goldenRecords);
  const currentBookmarkedClusterIds = selectBookmarkedClusterIds(state);
  dispatch({ type: CLEAR_SELECTION });
  dispatch(setBookmarkedClusterIds(currentBookmarkedClusterIds.union(selectedClusterIds)));
};

export const removeBookmarksForSelectedRows = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const selectedClusterIds = selectSelectedClusterIds(state.goldenRecords);
  const currentBookmarkedClusterIds = selectBookmarkedClusterIds(state);
  dispatch({ type: CLEAR_SELECTION });
  dispatch(setBookmarkedClusterIds(currentBookmarkedClusterIds.subtract(selectedClusterIds)));
};

const upsertOrDeleteOverride = (overrideSpec: RecipeClient.OverrideSpec): AppThunkAction<void> => async (dispatch, getStore) => {
  const moduleId = getStore().location.moduleId;
  try {
    dispatch({ type: UPDATE_OVERRIDE });
    await RecipeClient.updateGoldenRecordsOverrides(moduleId, List.of(overrideSpec));
    dispatch(fetchAllOverrideStats(overrideSpec.attribute));
    // TODO be smarter about updating manual overrides and don't refetch everything on success
    return dispatch({ type: UPDATE_OVERRIDE_COMPLETED });
  } catch ({ data, message }) {
    dispatch({ type: SHOW, title: 'Unable to save manual override', detail: message, response: { responseText: data?.stackTrace?.join('\n') } });
  }
};

export const upsertOverride = ({ clusterId, attribute, value }: {
  clusterId: string
  attribute: string
  value: string
}): AppThunkAction<void> => async (dispatch) => {
  dispatch(upsertOrDeleteOverride({ action: 'CREATE', clusterId, attribute, value }));
};

export const deleteOverride = ({ clusterId, attribute }: {
  clusterId: string
  attribute: string
}): AppThunkAction<void> => async (dispatch) => {
  dispatch(upsertOrDeleteOverride({ action: 'DELETE', clusterId, attribute }));
};

// allows updating (only) the description or displayName of a golden records module
export const saveGoldenRecordSimple = ({ moduleId, displayName, description = '' }: { moduleId: number, displayName: string | undefined, description: string }): AppThunkAction<void> => async (dispatch) => {
  checkArg({ displayName }, ArgTypes.orUndefined(ArgTypes.string));
  checkArg({ description }, ArgTypes.string);
  try {
    const goldenRecordDocument = await GoldenRecordsAPI.fetchGoldenRecordsModuleDocument(moduleId);
    const goldenRecords = merge(goldenRecordDocument.data, { displayName, description });
    const updateModuleResult = await RecipeClient.updateModule({
      moduleId,
      version: goldenRecordDocument.version,
      module: goldenRecords,
      fromJSON: GoldenRecordsModule.fromJSON,
    });
    Result.handle(updateModuleResult,
      () => {
        dispatch({ type: CANCEL_EDITING_PROJECT });
        dispatch(fetchAllProjects());
      },
      error => apiError(dispatch, 'Unable to update golden record rules', error),
    );
  } catch ({ data, message }) {
    dispatch({ type: SHOW, title: 'Unable to update golden record rules', detail: message, response: { responseText: data?.stackTrace?.join('\n') } });
  }
};

// incorporates all supplied ruleDeltas into the module's rules
const updateModuleWithRuleDeltas = (module: GoldenRecordsModule.GoldenRecordsModule, ruleDeltas: List<RuleDelta>): GoldenRecordsModule.GoldenRecordsModule => {
  return ruleDeltas.reduce((moduleBuffer, ruleDelta) => {
    if (ruleDelta.rule.outputAttributeName === module.entityRule.outputAttributeName && ruleDelta.type === RuleDeltaTypes.CHANGE) {
      return set(moduleBuffer, 'entityRule', ruleDelta.rule);
    }
    const ruleIndex = moduleBuffer.rules.findIndex(r => r.outputAttributeName === ruleDelta.rule.outputAttributeName);
    if (ruleDelta.type === RuleDeltaTypes.CHANGE) {
      return update(moduleBuffer, 'rules', r => set(r, ruleIndex, set(ruleDelta.rule, 'suggested', false)));
    }
    if (ruleDelta.type === RuleDeltaTypes.DELETE) {
      if (ruleIndex < 0) {
        // rule delta refers to a rule that does not currently exist in the module
        // this may occur if the ruleDelta has not yet been saved
        return moduleBuffer;
      }
      return update(moduleBuffer, 'rules', r => removeIndex(r, ruleIndex));
    }
    if (ruleDelta.type === RuleDeltaTypes.CREATE) {
      return update(moduleBuffer, 'rules', rules => push(rules, ruleDelta.rule));
    }
    return moduleBuffer;
  }, module);
};

const handleSaveModuleFailure = (
  dispatch: AppDispatch,
  error: FetchError,
  failureAction: AppAction,
): void => {
  if (error.type === 'ApiException') {
    if (error.apiException.status === 409) {
      // the call to update module fails due to the module being out-of-date at the time of the
      // call
      dispatch({ type: SHOW_CONFLICT_DIALOG, showConflictDialog: true });
    } else if (error.apiException.status === 400) {
      // the call to update module fails due to invalid module configuration. We match this with
      // the current backend behavior where a thrown illegal argument exception will be
      // converted to 400-status response code.
      // TODO: have a better way to sync up backend and frontend error code.
      dispatch({ type: SAVE_MODULE_INVALID, saveModuleError: error });
    }
  } else {
    apiError(dispatch, 'Unable to update golden record rules', error, failureAction);
  }
};

export const fetchPreviewTable = (): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  const filterInfo = selectPreviewFilterInfo(state);
  const { input } = filterInfo;
  if (!input) {
    console.error('Could not fetch preview table - no handle on input cluster table data');
    return;
  }
  const module = selectModuleForPreview(state);
  if (!module) {
    console.error('Could not fetch preview table - no handle on a module to preview');
    return;
  }

  const { moduleId } = selectModuleFilterInfo(state);

  try {
    dispatch({ type: 'GoldenRecords.fetchPreviewTable' });
    const result = await RecipeClient.getModuleStatus(moduleId);
    Result.handle(result,
      async (moduleStatus) => {
        if (moduleStatus.configValidationErrors.length !== 0) {
          dispatch(showConfigError(module.displayName, moduleStatus.configValidationErrors));
          dispatch({ type: 'GoldenRecords.fetchPreviewTableFailed', filterInfo });
        } else {
          const table = (await RecipeClient.fetchGoldenRecordsPreview(input, module))
            .sort((a, b) => ((jsonContentToString(getClusterNameOfPreviewedRecord(module, a)) > jsonContentToString(getClusterNameOfPreviewedRecord(module, b))) ? 1 : -1));
          dispatch({ type: 'GoldenRecords.fetchPreviewTableCompleted', table, filterInfo, module });
        }
      },
      error => apiError(dispatch, 'Unable to fetch module status for preview', error,
        { type: 'GoldenRecords.fetchPreviewTableFailed', filterInfo }),
    );
  } catch ({ data, message }) {
    dispatch({ type: SHOW, title: 'Unable to fetch preview', detail: message, response: { responseText: data?.stackTrace?.join('\n') } });
    dispatch({ type: 'GoldenRecords.fetchPreviewTableFailed', filterInfo });
  }
};

export const saveRule = (
  { ruleName, skipConfigValidation }: { ruleName: string, skipConfigValidation?: boolean },
): AppThunkAction<void> => async (dispatch, getState) => {
  checkArg({ ruleName }, ArgTypes.string);
  const state = getState();
  const { goldenRecords, goldenRecords: { goldenRecordDocument }, location: { moduleId } } = state;
  if (!goldenRecordDocument) {
    console.error('Could not save rule - no Golden Records Module document loaded');
    return;
  }
  const ruleDelta = findRuleDeltaByName(goldenRecords, ruleName);
  if (!ruleDelta) {
    console.error(`Could not save rule - could not find corresponding rule definition by name: ${ruleName}`);
    return;
  }
  const version = goldenRecordDocument.version;
  const updatedModule = update(goldenRecordDocument.data, module => updateModuleWithRuleDeltas(module, List.of(ruleDelta)));
  dispatch({ type: SAVE_RULE, module: updatedModule, ruleName });

  const updateModuleResult = await RecipeClient.updateModule({
    moduleId,
    version,
    module: updatedModule,
    fromJSON: GoldenRecordsModule.fromJSON,
    skipConfigValidation,
  });

  Result.handle(updateModuleResult,
    newModuleDoc => {
      dispatch({ type: SAVE_RULE_COMPLETED, moduleDoc: newModuleDoc, ruleName });
      dispatch(fetchPreviewTable());
    },
    error => handleSaveModuleFailure(dispatch, error, { type: SAVE_RULE_FAILED }),
  );
};

export const saveAllRules = (): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  const { location: { moduleId }, goldenRecords: { nextRules, nextEntityRule, goldenRecordDocument } } = state;
  if (!goldenRecordDocument) {
    console.error('Could not save all rules - no Golden Records Module document loaded');
    return;
  }
  if (!nextEntityRule) {
    console.error('Could not save all rules - entity rule is currently undefined');
    return;
  }
  const version = goldenRecordDocument.version;
  const updatedModule = update(goldenRecordDocument.data, module => updateModuleWithRuleDeltas(module, nextRules.push(nextEntityRule)));
  dispatch({ type: SAVE_ALL_RULES, module: updatedModule });

  const updateModuleResult = await RecipeClient.updateModule({
    moduleId,
    version,
    module: updatedModule,
    fromJSON: GoldenRecordsModule.fromJSON,
  });

  Result.handle(updateModuleResult,
    newModuleDoc => dispatch({ type: SAVE_ALL_RULES_COMPLETED, moduleDoc: newModuleDoc }),
    error => handleSaveModuleFailure(dispatch, error, { type: SAVE_ALL_RULES_FAILED }),
  );
};

export const deleteRule = (
  { ruleName, skipConfigValidation }: { ruleName: string, skipConfigValidation?: boolean },
): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  const { goldenRecords: { goldenRecordDocument } } = state;
  if (goldenRecordDocument && !GoldenRecordsModule.ruleNames(goldenRecordDocument.data).includes(ruleName)) {
    // trying to delete a rule that is newly created, has never been saved
    dispatch({ type: CANCEL_RULE, ruleName });
    return;
  }
  dispatch({ type: DELETE_RULE, ruleName });
  await dispatch(saveRule({ ruleName, skipConfigValidation }));
};

export const forceSaveModule = (): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  const {
    location: { moduleId },
    goldenRecords: { moduleToUpdate, goldenRecordDocument, singleRuleNameUpdate },
  } = state;
  if (!goldenRecordDocument) {
    console.error('Could not save all rules - no Golden Records Module document loaded');
    return;
  }
  if (!moduleToUpdate) {
    console.error('Could not save module - moduleToUpdate is not loaded');
    return;
  }
  const version = goldenRecordDocument.version;

  const updateModuleResult = await RecipeClient.updateModule({
    moduleId,
    version,
    module: moduleToUpdate,
    fromJSON: GoldenRecordsModule.fromJSON,
    skipConfigValidation: true,
  });

  Result.handle(updateModuleResult,
    newModuleDoc => (isDefined(singleRuleNameUpdate)
      ? dispatch({ type: SAVE_RULE_COMPLETED, moduleDoc: newModuleDoc, ruleName: singleRuleNameUpdate })
      : dispatch({ type: SAVE_ALL_RULES_COMPLETED, moduleDoc: newModuleDoc })),
    error => apiError(dispatch,
      'Unable to update golden record rules',
      error,
      isDefined(singleRuleNameUpdate)
        ? { type: SAVE_RULE_FAILED }
        : { type: SAVE_ALL_RULES_FAILED },
    ),
  );
};

const doPerformStaticAnalysis = ({ clusterColumnName, inputAttributeTypes, contentEnvelope, expression }: {
  clusterColumnName: string
  inputAttributeTypes: List<Field>
  contentEnvelope: ContentEnvelope
  expression: string
}): Promise<OperationListResult> => {
  checkArg({ clusterColumnName }, ArgTypes.string);
  checkArg({ inputAttributeTypes }, ArgTypes.Immutable.list.of(Field.argType));
  checkArg({ contentEnvelope }, ContentEnvelope.argType);
  checkArg({ expression }, ArgTypes.string);
  const statement = `${contentEnvelope.pre}${expression || ''} ${contentEnvelope.post}`;
  const analysisInput = new AnalysisInput({
    activeKey: List.of(clusterColumnName),
    activeType: new RecordType({ fullySpecified: true, fields: inputAttributeTypes }),
    operationList: List.of(statement),
    referenceKeys: Map(),
    referenceTypes: Map(),
  });
  return TransformClient.analyzeTypes(analysisInput);
};

const ExpressionEnvelope = new ContentEnvelope({ pre: 'Select *, ', post: ' as "Custom Expression";', type: ContentEnvelopeTypes.expression });
const PredicateEnvelope = new ContentEnvelope({ pre: 'Filter ', post: '', type: ContentEnvelopeTypes.expression });

export const performStaticAnalysisForRule = ({ ruleName, expression }: { ruleName: string, expression: string }): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  const { goldenRecords, goldenRecords: { goldenRecordDocument } } = state;
  if (!goldenRecordDocument) {
    console.error('Could not perform static analysis - no Golden Records Module document loaded');
    return;
  }
  const staticAnalysisResult = await doPerformStaticAnalysis({
    clusterColumnName: goldenRecordDocument.data.clusterDataset.clusterColumn,
    inputAttributeTypes: selectInputAttributesTypes(state),
    contentEnvelope: selectAggregationContentEnvelope(goldenRecords, ruleName),
    expression,
  });
  dispatch({ type: 'GoldenRecords.performStaticAnalysisForRuleCompleted', ruleName, staticAnalysisResult });
};

export const getFilterExpressionEnvelope = (filter: fcr.FilterClusterRecords) => {
  const type = filter.type;
  return type === Predicate.TYPE ? PredicateEnvelope : ExpressionEnvelope;
};

export const performStaticAnalysisForFilter = ({ ruleName, filterIndex, expression }: {
  ruleName: string
  filterIndex: number
  expression: string
}): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  const { goldenRecords, goldenRecords: { goldenRecordDocument } } = state;
  if (!goldenRecordDocument) {
    console.error('Could not perform static analysis - no Golden Records Module document loaded');
    return;
  }
  const filter = getRuleFilter(goldenRecords, { ruleName, filterIndex });
  if (!filter) {
    console.error(`Could not perform static analysis - could not find corresponding filter for rule '${ruleName}' and filter index ${filterIndex}`);
    return;
  }
  const staticAnalysisResult = await doPerformStaticAnalysis({
    clusterColumnName: goldenRecordDocument.data.clusterDataset.clusterColumn,
    inputAttributeTypes: selectInputAttributesTypes(state),
    contentEnvelope: getFilterExpressionEnvelope(filter),
    expression,
  });
  dispatch({ type: 'GoldenRecords.performStaticAnalysisForFilterCompleted', ruleName, filterIndex, staticAnalysisResult });
};

export const fetchPreviewInputRecords = (): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  const { location: { moduleId } } = state;
  const filterInfo = selectInputRecordsFilterInfo(state);
  const { bookmarkedClusterIds } = filterInfo;
  dispatch({ type: 'GoldenRecords.fetchPreviewInputRecords' });
  try {
    const table = await RecipeClient.fetchGoldenRecordsClusterRecords(moduleId, Set(bookmarkedClusterIds));
    dispatch({ type: 'GoldenRecords.fetchPreviewInputRecordsCompleted', table, filterInfo });
  } catch ({ data, message }) {
    const bookmarkedClustersTooLarge = data.status === 413;
    if (bookmarkedClustersTooLarge) {
      dispatch({ type: 'GoldenRecords.showPreviewTooLargeDialog', showPreviewTooLargeDialog: true });
    } else {
      dispatch({ type: SHOW, title: 'Unable to fetch cluster records', detail: message, response: { responseText: data?.stackTrace?.join('\n') } });
    }
    dispatch({ type: 'GoldenRecords.fetchPreviewInputRecordsFailed', filterInfo, bookmarkedClustersTooLarge });
  }
};

export const hideBookmarksOnboardingMessage = (permanently: boolean): AppThunkAction<void> => async (dispatch) => {
  checkArg({ permanently }, ArgTypes.bool);
  dispatch({ type: 'GoldenRecords.hideBookmarksOnboardingMessage' });
  if (permanently) {
    await dispatch(updateGlobalPreferences({ hideBookmarksOnboardingMessage: true }));
  }
};

export const fetchClusterSample = (
  clusterSampleState: ClusterSampleState.ClusterSampleState,
  prefetchAction: (params: { clusterId: string, clusterName: string, clusterSize: number, fetchSequence: number }) => AppAction,
  successAction: (params: { table: TypedTable, clusterId: string }) => AppAction,
  errorAction: (params: { clusterId: string }) => AppAction,
): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  const { location: { moduleId } } = state;
  const desiredResourceDescription = ClusterSampleState.getDesiredResourceDescription(clusterSampleState);
  if (desiredResourceDescription === null) {
    console.error('Could not fetch cluster sample - no desired resource description (may be no handle on valid cluster id');
    return;
  }
  const clusterId = desiredResourceDescription.clusterId;
  const clusterName = clusterSampleState.clusterInformation?.clusterName || ''; // TODO address this cast
  const clusterSize = clusterSampleState.clusterInformation?.clusterSize || 0; // TODO address this cast
  const fetchSequence = desiredResourceDescription.fetchSequence;
  dispatch(prefetchAction({ clusterId, clusterName, clusterSize, fetchSequence }));
  try {
    const table = await RecipeClient.fetchGoldenRecordsClusterRecords(moduleId, Set.of(clusterId));
    dispatch(successAction({ table, clusterId }));
  } catch ({ data, message }) {
    dispatch({ type: SHOW, title: `Unable to fetch cluster sample for cluster id ${clusterId}`, detail: message, response: { responseText: data?.stackTrace?.join('\n') } });
    dispatch(errorAction({ clusterId }));
  }
};

export const rulesPageFetchClusterSample = (): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  dispatch(fetchClusterSample(
    state.goldenRecordsRules.clusterSampleState,
    params => ({ type: rulesPageActions.FETCH_CLUSTER_SAMPLE, ...params }),
    params => ({ type: rulesPageActions.FETCH_CLUSTER_SAMPLE_COMPLETED, ...params }),
    params => ({ type: rulesPageActions.FETCH_CLUSTER_SAMPLE_FAILED, ...params }),
  ));
};

export const grPageFetchClusterSample = (): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  dispatch(fetchClusterSample(
    state.goldenRecords.clusterSampleState,
    params => ({ type: FETCH_CLUSTER_SAMPLE, ...params }),
    params => ({ type: FETCH_CLUSTER_SAMPLE_COMPLETED, ...params }),
    params => ({ type: FETCH_CLUSTER_SAMPLE_FAILED, ...params }),
  ));
};
