/* eslint-disable no-restricted-globals */

import React from 'react';
import { connect } from 'react-redux';

import { ErrorDialog } from '../errorDialog/ErrorDialog';
import { reducers as recordsReducers } from '../records/RecordsStore';
import { getUrlWithHash } from '../utils/Routing';

const ListUpdateConflictDialog = connect((state) => {
  const { transforms: { updateListConflictMessage } } = state;
  return {
    url: getUrlWithHash(state.update('records', recordsState => recordsReducers['Records.useServerStorage'](recordsState))),
    updateListConflictMessage,
  };
}, {
  onHide: () => ({ type: 'Transforms.hideFailedListUpdateDialog' }),
})(({ url, updateListConflictMessage, onHide }) => {
  return (
    <ErrorDialog
      showDialog={!!updateListConflictMessage}
      showMore={false}
      onDismiss={onHide}
      title="Encountered conflict saving changes"
      detail={(
        <span>Error saving transformations. Your local changes have not been affected. To Save changes, view an updated list of transformations in a <a rel="noopener noreferrer" target="_blank" href={url}>new tab</a> and reproduce your local changes.</span>
      )}
      advancedDetail={updateListConflictMessage}
    />
  );
});

export default ListUpdateConflictDialog;
