import { ArgTypes } from '../utils/ArgValidation';
import { checkTypesAndCast } from '../utils/CheckTypesAndCast';

export interface GoldenRecordsOverrideStats {
  totalNumOverrides: number
}
export const argTypes = {
  totalNumOverrides: ArgTypes.wholeNumber,
} as const;
export const fromJSON = checkTypesAndCast<GoldenRecordsOverrideStats, typeof argTypes>(argTypes);
