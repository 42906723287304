import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import style from './ConflictErrorDialog.module.scss';

const ConflictErrorDialog = _.compose(
  connect(state => {
    const { suppliers: { showConflictError } } = state;
    return { showConflictError };
  }, {
    onReload: () => ({ type: 'Suppliers.reloadConflictError' }),
    onHide: () => ({ type: 'Suppliers.hideConflictError' }),
  }),
)(({ showConflictError, onHide, onReload }) => {
  return (
    <Dialog
      show={showConflictError}
      onHide={onHide}
      title="Refresh to make changes"
      dialogStyle={DialogStyle.PRIMARY}
      body={
        <div>
          <div>Another user recently made changes to the clusters on this page.</div>
          <div className={style.text}>Please refresh to make new changes.</div>
        </div>
      }
      footer={
        <div>
          <Button buttonType="Secondary" onClick={onHide}>Cancel</Button>
          <Button buttonType="Primary" onClick={onReload}>Refresh</Button>
        </div>
      }
    />
  );
});

export default ConflictErrorDialog;
