import React from 'react';
import { connect } from 'react-redux';

import { SHOW } from '../errorDialog/ErrorDialogActionTypes';
import { $TSFixMe } from '../utils/typescript';

const mapDispatchToProps = {
  onError: ({ detail, advancedDetail }: { detail: string, advancedDetail: $TSFixMe }) => ({ type: SHOW, detail, advancedDetail }),
};

type ErrorBoundaryOwnProps = {
  children?: React.ReactNode
  errorMessage?: React.ReactNode
}

type ErrorBoundaryProps = ErrorBoundaryOwnProps & typeof mapDispatchToProps;
type ErrorBoundaryState = {
  hasError: boolean
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = {
    hasError: false,
  };

  componentDidCatch(error: Error, info: $TSFixMe) {
    this.setState({ hasError: true });
    this.props.onError({
      detail: error.message,
      advancedDetail: info.componentStack,
    });
  }

  render() {
    if (this.state.hasError) {
      return this.props.errorMessage || <h1>Something went wrong</h1>;
    }
    return this.props.children || null;
  }
}

export default connect(null, mapDispatchToProps)(ErrorBoundary);
