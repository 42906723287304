import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import TamrIcon from '../components/TamrIcon';
import { AppState } from '../stores/MainStore';
import { getAuthorizedUser } from '../utils/Selectors';


const UserAvatar: React.FC<{
  firstName?: string
  lastName?: string
  username: string
  useToolTip?: boolean
  className?: string
}> = ({ firstName, lastName, username, useToolTip, className }) => {
  const content = (_.isEmpty(firstName) || _.isEmpty(lastName))
    ? <TamrIcon iconName="person" size={24} />
    : <span>{firstName?.charAt(0).toUpperCase()}{lastName?.charAt(0).toUpperCase()}</span>;
  const tooltip = useToolTip
    ? (_.isEmpty(firstName) || _.isEmpty(lastName))
      ? username
      : `${firstName} ${lastName} (${username})`
    : undefined;
  return (
    <div className={className} title={tooltip}>
      {content}
    </div>
  );
};

UserAvatar.propTypes = {
  className: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  useToolTip: PropTypes.bool,
  username: PropTypes.string.isRequired,
};

export default UserAvatar;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const LoggedInUser = connect((state: AppState, ownProps: { useToolTip?: boolean, className?: string }) => {
  const authorizedUser = getAuthorizedUser(state);
  return {
    firstName: authorizedUser?.user.given || undefined,
    lastName: authorizedUser?.user.surname || undefined,
    username: authorizedUser?.username || '',
  };
})(UserAvatar);
