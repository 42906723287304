import { List } from 'immutable';

import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import ClauseEntry from './ClauseEntry';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

// com.tamr.dedup.models.dnf.Clause
class Clause extends getModelHelpers({
  clauseEntries: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(ClauseEntry)) },
  active: { type: ArgTypes.bool },
  clauseId: { type: ArgTypes.orUndefined(ArgTypes.string) },
}, 'Clause')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ClauseRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(Clause); }

  static fromJSON(obj: $TSFixMe) {
    return new Clause({
      clauseEntries: List(obj.clauseEntries).map(ClauseEntry.fromJSON),
      active: obj.active,
      clauseId: obj.clauseId,
    });
  }

  /**
   * Checks if two Clauses are equal
   *
   * Ignores whether their IDs are different or their clause entries are in different orders
   */
  equalsIgnoreId(other: Clause) {
    checkArg({ other }, Clause.argType);
    return this.active === other.active
      && this.clauseEntries.size === other.clauseEntries.size
      && this.clauseEntries.every(clauseEntry => !!other.clauseEntries.find(ce => ce.equalsIgnoreId(clauseEntry)));
  }
}

export default Clause;
