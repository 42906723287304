import MinimalAuthUser from '../models/MinimalAuthUser';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import { AUTH_COMPLETED } from '../sso/SSOActionTypes';
import { StoreReducers } from '../stores/AppAction';
import { ArgTypes } from '../utils/ArgValidation';
import {
  LOGIN,
  LOGIN_COMPLETED,
  LOGIN_FAILED,
  LOGOUT_COMPLETED,
  SET_USER_PREFERENCES,
} from './AuthActionTypes';

/**
 * This store provides access to the currently logged in user
 */

export class AuthStore extends getModelHelpers({
  authorizedUser: { type: ArgTypes.orUndefined(ArgTypes.instanceOf(MinimalAuthUser)) },
  authenticationFailed: { type: ArgTypes.bool, defaultValue: false },
  loggingIn: { type: ArgTypes.bool, defaultValue: false },
  sanitizedErrorMessage: { type: ArgTypes.orUndefined(ArgTypes.string) },
  ssoAttempted: { type: ArgTypes.bool, defaultValue: false },
  validatingToken: { type: ArgTypes.bool, defaultValue: false },
}, 'AuthStore')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class AuthStoreRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  reduce(reduceFunction: Function, accumulator: any) {
    Array.from(this.toSeq().entries()).forEach(entry => {
      accumulator = reduceFunction(accumulator, entry[1], entry[0]);
    });
    return accumulator;
  }
}

export const initialState = new AuthStore({});

export const reducers:StoreReducers<AuthStore> = {
  [LOGIN]: (state) => {
    return state.merge({
      authenticationFailed: false,
      sanitizedErrorMessage: undefined,
      loggingIn: true,
    });
  },
  [LOGIN_COMPLETED]: (state, { authorizedUser }) => {
    return state.merge({
      authorizedUser,
      loggingIn: false,
    });
  },
  [LOGIN_FAILED]: (state, { response }) => {
    const sanitizedErrorMessage = response.status === 500 && response.responseJSON
      ? response.responseJSON.message.replace('com.tamr.common.except.ServiceException:', '').trim()
      : 'Could not log in';
    return state.merge({
      authenticationFailed: true,
      sanitizedErrorMessage,
      loggingIn: false,
    });
  },
  [LOGOUT_COMPLETED]: (state) => {
    window.location.reload();
    return state;
  },
  [SET_USER_PREFERENCES]: (state, { preferences }) => {
    return state.setIn(['authorizedUser', 'preferences'], preferences);
  },
  [AUTH_COMPLETED]: (state, { ssoEnabled }) => {
    if (!ssoEnabled) {
      return state;
    }

    const { authenticatedUser } = ssoEnabled;
    if (authenticatedUser) {
      return state.merge({ authorizedUser: ssoEnabled.authenticatedUser || undefined });
    }
    return state;
  },
};
