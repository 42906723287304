import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { AutoSizer, List as VirtualScroll } from 'react-virtualized';

import CenterContent from '../components/CenterContent';
import AttributeId, { attributeId } from '../models/AttributeId';

const UNEXPANDED_ROW_HEIGHT = 30;
const EXPANDED_ROW_HEIGHT = 195;

class AttributeList extends React.Component {
  static propTypes = {
    AttributeItem: PropTypes.func.isRequired,
    attributes: ImmutablePropTypes.listOf(PropTypes.any),
    canUserEdit: PropTypes.bool.isRequired,
    expandedIds: ImmutablePropTypes.setOf(PropTypes.instanceOf(AttributeId)),
    onSetHeaderScrollbarOffset: PropTypes.func,
    outerHeight: PropTypes.string,
  };

  componentDidUpdate(prev) {
    const { attributes, expandedIds } = this.props;
    if (this.refs.autosizer && (attributes !== prev.attributes || expandedIds !== prev.expandedIds)) {
      this.refs.autosizer.refs.vScroll.recomputeRowHeights();
    }
  }

  /**
   * ARP 12/17:
   *
   * This component has a corresponding header row. To ensure that the header row
   * has the same width as the inner content of the virtual list, we need
   * to do some book-keeping about the width of the scrollbar of the virtual list
   * (if present). This offset will be added to the header row to compensate.
   *
   * This problem could be solved by using a virtualized table component,
   * but given that the attribute rows have expandable content that does not fit
   * the form-factor of available table components, it seems simpler near-term to just
   * correct the positioning quirk in the header.
   */
  onRowsRendered = () => {
    if (!this.props.onSetHeaderScrollbarOffset) {
      return;
    }
    const vscroll = ReactDOM.findDOMNode(this.refs.autosizer.refs.vScroll);
    if (vscroll.children.length) {
      this.props.onSetHeaderScrollbarOffset(vscroll.offsetWidth - vscroll.children[0].offsetWidth);
    }
  };

  render() {
    const { attributes, expandedIds, canUserEdit, AttributeItem, outerHeight } = this.props;
    if (attributes.isEmpty()) {
      return <CenterContent>No Attributes to Show</CenterContent>;
    }
    const rowHeight = ({ index }) => {
      return expandedIds.has(attributeId(attributes.get(index))) ? EXPANDED_ROW_HEIGHT : UNEXPANDED_ROW_HEIGHT;
    };
    const rowRenderer = ({ index, style }) => {
      const attr = attributes.get(index);
      return (
        <div className="schema-mapping-attribute-list-item-container" key={`${attr.name} ${attr.datasetName}`} style={style}>
          <AttributeItem attribute={attr} {...{ canUserEdit }} />
        </div>
      );
    };
    return (
      <div style={{ width: '100%', height: outerHeight }}>
        <AutoSizer ref="autosizer">
          {({ width, height }) => (
            <VirtualScroll
              {...{ width, height, rowHeight, rowRenderer }}
              ref="vScroll"
              className="schema-mapping-attribute-list"
              overscanRowCount={2}
              onRowsRendered={this.onRowsRendered}
              noRowsRenderer={() => <div />}
              rowCount={attributes.size}
            />
          )}
        </AutoSizer>
      </div>
    );
  }
}

AttributeList.defaultProps = {
  outerHeight: '100%',
};

export default AttributeList;
