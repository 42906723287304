// com.tamr.transform.models.profiling.ProfiledAttribute

import { List } from 'immutable';

import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

export class ProfilingMetric extends getModelHelpers({
  metricName: { type: ArgTypes.string },
  value: { type: ArgTypes.any },
}, 'ProfilingMetric')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ProfilingMetricRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

class ProfiledAttribute extends getModelHelpers({
  attributeName: { type: ArgTypes.nullable(ArgTypes.string) }, // NB observed to be nullable even though java class makes no such mention
  metrics: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(ProfilingMetric)) },
}, 'ProfiledAttribute')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ProfiledAttributeRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: $TSFixMe) {
    return new ProfiledAttribute({
      attributeName: obj.attributeName,
      metrics: List(obj.metrics).map((metric: ProfilingMetric) => new ProfilingMetric(metric)),
    });
  }
}

export default ProfiledAttribute;
