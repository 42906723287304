import './Jobs.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import PageHeader from '../chrome/PageHeader';
import SearchBox from '../components/SearchBox';
import JobCancelConfirmDialog from './JobCancelConfirmDialog';
import JobCancelFailedDialog from './JobCancelFailedDialog';
import JobsLoader from './JobsLoader';
import JobSpecLoader from './JobSpecLoader';
import JobsTable from './JobsTable';
import SpecDialog from './SpecDialog';
import StatusDialog from './StatusDialog';

const Jobs = connect(state => {
  const { jobs: { searchString } } = state;
  return {
    searchString,
  };
}, {
  onRefresh: () => ({ type: 'Jobs.refresh' }),
  onRefreshWithSpinner: () => ({ type: 'Jobs.refreshWithSpinner' }),
  onSetSearchString: (searchString) => ({ type: 'Jobs.setSearchString', searchString }),
})(class Jobs extends React.Component {
  static propTypes = {
    onRefresh: PropTypes.func.isRequired,
    onRefreshWithSpinner: PropTypes.func.isRequired,
  };

  UNSAFE_componentWillMount() {
    const { onRefresh, onRefreshWithSpinner } = this.props;
    onRefreshWithSpinner();
    this.interval = setInterval(onRefresh, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { searchString, onSetSearchString } = this.props;
    return (
      <div className="jobs-page-container">
        <JobsLoader />
        <JobSpecLoader />
        <PageHeader
          title={<span>Jobs</span>}
          searchbox={<SearchBox value={searchString} onSearch={onSetSearchString} />}
        />
        <div className="jobs-page-table-container">
          <JobsTable />
        </div>
        <JobCancelFailedDialog />
        <StatusDialog />
        <SpecDialog />
        <JobCancelConfirmDialog />
      </div>
    );
  }
});

export default Jobs;
