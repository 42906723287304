import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import SearchBox from '../components/SearchBox';
import ProcurementCategory from '../models/ProcurementCategory';
import ProcurementTaxonomy from '../models/ProcurementTaxonomy';
import defaultTaxonomyCell from '../taxonomy/DefaultTaxonomyCell';
import labelCountTaxonomyCell from '../taxonomy/LabelCountTaxonomyCell';
import spendTaxonomyCell from '../taxonomy/SpendTaxonomyCell';
import TaxonomyViewSelector from '../taxonomy/TaxonomyViewSelector';
import TierBrowser from '../taxonomy/TierBrowser';
import style from './CategoryFilterDialog.module.scss';

const COMPARATORS = {
  alphabetical: (category) => (category.path.last()),
  spend: (category) => (category.manualCategorizations.spend +
    category.totalAggForSuggestedCategorizations.spend) * -1,
  labels: (category) => (category.manualCategorizations.count +
    category.totalAggForSuggestedCategorizations.count) * -1,
};

const CategoryFilterDialog = _.compose(
  connect(state => {
    const { transactions: { showCategoryFilter, categoryIds }, taxonomy: { taxonomy, activeCategory, searchValue, viewType, initialCategory, tierLabelMaxCounts } } = state;
    return {
      taxonomy,
      taxonomyQuery: searchValue,
      taxonomySort: viewType,
      showCategoryFilter,
      canApply: categoryIds.first() !== (activeCategory && activeCategory.categoryId),
      initialCategory,
      tierLabelMaxCounts,
    };
  }, {
    onCancel: () => ({ type: 'Transactions.cancelCategoryFilter' }),
    onApply: () => (dispatch, getState) => {
      const { taxonomy: { activeCategory, initialCategory }, transactions: { categoryIds } } = getState();
      if (activeCategory) {
        if (initialCategory) {
          dispatch({ type: 'Transactions.setCategoryIds', categoryIds: categoryIds.filter(id => id !== initialCategory.categoryId).push(activeCategory.categoryId) });
        } else {
          dispatch({ type: 'Transactions.addCategoryFilter', categoryId: activeCategory.categoryId });
        }
      }
    },
    onSetTaxonomyQuery: searchValue => ({ type: 'Taxonomy.setSearchValue', searchValue }),
    onSetTaxonomySort: viewType => ({ type: 'Taxonomy.setViewType', viewType }),
  }),
)(class CategoryFilterDialog extends React.Component {
  static propTypes = {
    canApply: PropTypes.bool.isRequired,
    initialCategory: PropTypes.instanceOf(ProcurementCategory),
    onApply: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSetTaxonomyQuery: PropTypes.func.isRequired,
    onSetTaxonomySort: PropTypes.func.isRequired,
    showCategoryFilter: PropTypes.bool.isRequired,
    taxonomy: PropTypes.instanceOf(ProcurementTaxonomy),
    taxonomyQuery: PropTypes.string,
    taxonomySort: PropTypes.string,
    tierLabelMaxCounts: ImmutablePropTypes.mapOf(PropTypes.number, PropTypes.number),
  };

  renderHeader = () => {
    const { taxonomyQuery, onSetTaxonomyQuery } = this.props;
    return (
      <Dialog.Header closeButton>
        Filter to category
        <div className="categorize-dialog-search">
          <SearchBox placeholder="Search for categories" value={taxonomyQuery} onSearch={onSetTaxonomyQuery} />
        </div>
      </Dialog.Header>
    );
  };

  getComparator = () => {
    const { taxonomySort } = this.props;
    return COMPARATORS[taxonomySort];
  };

  getFilter = (category) => {
    if (this.props.taxonomyQuery) {
      const name = category.name;
      return name.toLowerCase().indexOf(this.props.taxonomyQuery.toLowerCase()) >= 0;
    }
    return true;
  };

  getCellRenderer = () => {
    const { taxonomy, taxonomySort } = this.props;
    if (taxonomy) {
      switch (taxonomySort) {
        case 'alphabetical': return defaultTaxonomyCell;
        case 'spend': return spendTaxonomyCell;
        default: return labelCountTaxonomyCell;
      }
    }
  };

  renderTaxonomyBrowser = () => {
    const { showCategoryFilter, taxonomyQuery, tierLabelMaxCounts } = this.props;
    if (!showCategoryFilter) {
      return;
    }
    return (
      <TierBrowser
        comparator={this.getComparator()}
        filter={this.getFilter}
        categoryCellRenderer={this.getCellRenderer()}
        searchString={taxonomyQuery}
        tierLabelMaxCounts={tierLabelMaxCounts}
      />
    );
  };

  render() {
    const { showCategoryFilter, canApply, onCancel, onApply } = this.props;
    return (
      <Dialog
        className={style.dialog}
        dialogStyle={DialogStyle.FULL}
        show={showCategoryFilter}
        onHide={onCancel}
        header={this.renderHeader()}
        body={this.renderTaxonomyBrowser()}
        footer={
          <div className={style.buttons}>
            <div className={style.footerLeft}>
              <TaxonomyViewSelector
                onSelectionChange={this.props.onSetTaxonomySort}
                value={this.props.taxonomySort}
              />
            </div>
            <div className={style.footerRight}>
              <Button buttonType="Secondary" onClick={onCancel}>Cancel</Button>
              <Button className={style.selectButton} buttonType="Primary" onClick={onApply} disabled={!canApply}>Select</Button>
            </div>
          </div>
        }
      />
    );
  }
});

export default CategoryFilterDialog;
