import L from 'leaflet';

export default L.TileLayer.extend({

  initialize(url, options) {
    this._url = url;
    this.wmtsParams = L.extend({}, { style: 'default', tilematrixSet: '' });
    this.matrixIds = options.matrixIds || this.getDefaultMatrix();
    L.setOptions(this, options);
  },

  onAdd(map) {
    this._crs = this.options.crs || map.options.crs;
    L.TileLayer.prototype.onAdd.call(this, map);
  },

  getTileUrl(coords) {
    // Map value may be null, if so, fetch the first tile. This will avoid errors and cache a
    // single tile that only needs to be fetched once.
    if (!this._map) {
      return L.Util.template(this._url, {
        s: this._getSubdomain(coords),
        style: this.wmtsParams.style,
        tileMatrixSet: this.options.tilematrixSet,
        tileCol: 1,
        tileRow: 1,
        tileMatrix: this.matrixIds[1].identifier,
      });
    }

    // Tile Size defaults to 256 unless otherwise specified.
    const tileSize = this.options.tileSize;
    const nwPoint = coords.multiplyBy(tileSize);
    nwPoint.x += 1;
    nwPoint.y -= 1;
    const sePoint = nwPoint.add(new L.Point(tileSize, tileSize));

    // Project the long/lat based on the specified tile zoom and coordinate reference system.
    const nw = this._crs.project(this._map.unproject(nwPoint, this._tileZoom));
    const se = this._crs.project(this._map.unproject(sePoint, this._tileZoom));
    const tilematrix = this.matrixIds[this._tileZoom].identifier;
    const X0 = this.matrixIds[this._tileZoom].topLeftCorner.lng;
    const Y0 = this.matrixIds[this._tileZoom].topLeftCorner.lat;

    // Based on projected long/lat, calculate the tile column and row based on the NW corner.
    const tilewidth = se.x - nw.x;
    const tilecol = Math.floor((nw.x - X0) / tilewidth);
    const tilerow = -Math.floor((nw.y - Y0) / tilewidth);

    // Build the RESTful WMTS URL with the appropriate token replacement values.
    return L.Util.template(this._url, {
      s: this._getSubdomain(coords),
      style: this.wmtsParams.style,
      tileMatrixSet: this.options.tilematrixSet,
      tileCol: tilecol,
      tileRow: tilerow,
      tileMatrix: tilematrix,
    });
  },

  setParams(params, noRedraw) {
    L.extend(this.wmtsParams, params);
    return !noRedraw ? this.redraw() : this;
  },

  /*
   * The coordinates 20037508.3428, -20037508.3428 are static for each element
   * of the EPSG:3857 tile matrix set regardless of scale. This can be overridden
   * for other CRS configurations by setting the 'matrixIds' option for a Tile Layer.
   */
  getDefaultMatrix() {
    const matrixIds3857 = new Array(22);
    for (let i = 0; i < 22; i++) {
      matrixIds3857[i] = {
        identifier: '' + i,
        topLeftCorner: new L.LatLng(20037508.3428, -20037508.3428),
      };
    }
    return matrixIds3857;
  },
});
