const ValidationError = {
  FILL_MISSING_ATTRIBUTE: 'Please select an attribute',
  FILL_INVALID_ATTRIBUTE: 'This is not an attribute in your Unified Dataset',
  FORMULA_EMPTY_EXPRESSION: 'Please write a formula',
  FORMULA_MISSING_OUTPUT_ATTRIBUTE: 'Please select an output attribute',
  FORMULA_INVALID_OUTPUT_ATTRIBUTE: 'This is not an attribute in your Unified Dataset',
  MULTI_FORMULA_EMPTY_EXPRESSION: 'Please write a formula',
  MULTI_FORMULA_EMPTY_ATTRIBUTES: 'Please select at least one attribute to modify',
  MULTI_FORMULA_INVALID_ATTRIBUTE_NAME: 'You cannot use a column called "$COL" as an input to a multi-formula. Please remove this attribute',
  SCRIPT_EMPTY_VALUE: 'Please write a script',
  UNPIVOT_MISSING_UNPIVOT_ATTRIBUTES: 'Please select at least one attribute to unpivot',
  UNPIVOT_INVALID_UNPIVOT_ATTRIBUTES: 'All attributes must be in Unified Dataset',
  UNPIVOT_MISSING_VARIABLE_ATTRIBUTE: 'Please select a variable attribute',
  UNPIVOT_INVALID_VARIABLE_ATTRIBUTE: 'This is not an attribute in your Unified Dataset',
  UNPIVOT_MISSING_VALUE_ATTRIBUTE: 'Please select a value attribute',
  UNPIVOT_INVALID_VALUE_ATTRIBUTE: 'This is not an attribute in your Unified Dataset',
  UNPIVOT_INVALID_DEPENDENT_COLUMNS: 'All attributes must be in Unified Dataset',
  AGGREGATION_EMPTY_VALUE: 'Please write an aggregation expression',
} as const;

export type ValidationErrorE = typeof ValidationError[keyof typeof ValidationError];
export default ValidationError;
