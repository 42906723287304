import { removeIndex } from './Collections';
import { assertNever } from './typescript';
import { isDefined } from './Values';

/**
 * Result.ts
 * Even Typescript has the issue that rejected Promises cannot provide typed error objects to catch blocks.
 * The Result type here is a concept borrowed from Elm that represents the result of an operation that can have
 *   strongly typed success AND error cases.
 * We may want to update this utility in the future to:
 *   - be a legitimate Monad
 *   - be imported from some well-supported functional programming npm project
 *   - extend Promise in some way, so it can be `await`ed
 */

export type ResultSuccess<SuccessData> = {
  isSuccess: true
  isFailure: false
  data: SuccessData
}

export type ResultFailure<ErrorData> = {
  isSuccess: false
  isFailure: true
  error: ErrorData
}

export function constructSuccess<SuccessData>(data: SuccessData): ResultSuccess<SuccessData> {
  return { isSuccess: true, isFailure: false, data };
}

export function constructFailure<ErrorData>(error: ErrorData): ResultFailure<ErrorData> {
  return { isSuccess: false, isFailure: true, error };
}

export type Result<SuccessData, ErrorData>
  = ResultSuccess<SuccessData>
  | ResultFailure<ErrorData>

export function handle<SuccessData, ErrorData>(
  result: Result<SuccessData, ErrorData>,
  successHandler: (successData: SuccessData) => void,
  errorHandler: (errorData: ErrorData) => void,
): void {
  if (result.isSuccess) {
    successHandler(result.data);
    return;
  }
  if (result.isFailure) {
    errorHandler(result.error);
    return;
  }
  assertNever(result);
}

/**
 * Defines handling logic in the case where A) both results are successful, or B) either result has errored
 *
 * samgqroberts 2020-10-05 this could potentially be generalized to `handleAll`, but we would need to figure out
 *   how to maintain proper typing for the varargs
 *
 * @param results the {@link Result} objects to test for success / failure
 * @param successHandler callback to be called if both results are successful.
 *                       takes the success data of both results as arguments.
 * @param errorHandler callback to be called if either result is a failure.
 *                     conceptually the type of this callback's argument is a list of error data objects from the
 *                     failing result(s). the type of this callback necessitates / reflects the fact that
 *                     at least one error be found.
 */
export function handleBoth<SuccessData1, ErrorData1, SuccessData2, ErrorData2>(
  results: [Result<SuccessData1, ErrorData1>, Result<SuccessData2, ErrorData2>],
  successHandler: (successData1: SuccessData1, successData2: SuccessData2) => void,
  errorHandler: (error: ErrorData1 | ErrorData2, restErrors: (ErrorData1 | ErrorData2)[]) => void,
): void {
  if (results[0].isFailure) {
    errorHandler(results[0].error, results.splice(1).map(r => (r.isFailure ? r.error : undefined)).filter(isDefined));
    return;
  }
  if (results[1].isFailure) {
    errorHandler(results[1].error, []);
    return;
  }
  successHandler(results[0].data, results[1].data);
}

/**
 * Similar to {@link handleBoth}, but accepts 3 results.
 * TODO - merge these functions, while preserving the type information
 */
export function handleThree<SuccessData1, ErrorData1, SuccessData2, ErrorData2, SuccessData3, ErrorData3>(
  results: [Result<SuccessData1, ErrorData1>, Result<SuccessData2, ErrorData2>, Result<SuccessData3, ErrorData3>],
  successHandler: (successData1: SuccessData1, successData2: SuccessData2, successData3: SuccessData3) => void,
  errorHandler: (error: ErrorData1 | ErrorData2 | ErrorData3, restErrors: (ErrorData1 | ErrorData2 | ErrorData3)[]) => void,
): void {
  if (results[0].isFailure) {
    errorHandler(results[0].error, results.splice(1).map(r => (r.isFailure ? r.error : undefined)).filter(isDefined));
    return;
  }
  if (results[1].isFailure) {
    errorHandler(results[1].error, removeIndex(results, 1).map(r => (r.isFailure ? r.error : undefined)).filter(isDefined));
    return;
  }
  if (results[2].isFailure) {
    errorHandler(results[2].error, []);
    return;
  }
  successHandler(results[0].data, results[1].data, results[2].data);
}

function resultIsFailure<S, E>(result: Result<S, E>): result is ResultFailure<E> {
  return result.isFailure;
}

/**
 * Defines handling logic for an array of Results that all have the same SuccessData and ErrorData types
 */
export function handleAll<SuccessData, ErrorData>(
  results: Result<SuccessData, ErrorData>[],
  successHandler: (successData: SuccessData[]) => void,
  errorHandler: (error: ErrorData, restErrors: ErrorData[]) => void,
): void {
  const errors = results.filter(resultIsFailure).map(r => r.error);
  const firstError = errors[0];
  if (firstError) {
    errorHandler(firstError, errors.splice(1));
    return;
  }
  successHandler(results.map(r => (r as ResultSuccess<SuccessData>).data));
}

export function handler<SuccessData, ErrorData>(
  successHandler: (successData: SuccessData) => void,
  errorHandler: (errorData: ErrorData) => void,
): (result: Result<SuccessData, ErrorData>) => void {
  return result => handle(result, successHandler, errorHandler);
}

export function map<SuccessData, ErrorData, MappedType>(
  result: Result<SuccessData, ErrorData>,
  successMapper: (successData: SuccessData) => MappedType,
  errorMapper: (errorData: ErrorData) => MappedType,
): MappedType {
  if (result.isSuccess) {
    return successMapper(result.data);
  }
  if (result.isFailure) {
    return errorMapper(result.error);
  }
  assertNever(result);
}

export function mapper<SuccessData, ErrorData, MappedType>(
  successMapper: (successData: SuccessData) => MappedType,
  errorMapper: (errorData: ErrorData) => MappedType,
): (result: Result<SuccessData, ErrorData>) => MappedType {
  return result => map(result, successMapper, errorMapper);
}

/**
 * Similar to {@link map}, except the success and error cases don't have to map to the same type.
 */
export function handleMap<SuccessData, ErrorData, MappedSuccess, MappedError>(
  result: Result<SuccessData, ErrorData>,
  successMapper: (successData: SuccessData) => MappedSuccess,
  errorMapper: (errorData: ErrorData) => MappedError,
): MappedSuccess | MappedError {
  if (result.isSuccess) {
    return successMapper(result.data);
  }
  if (result.isFailure) {
    return errorMapper(result.error);
  }
  assertNever(result);
}

/**
 * Maps the success and error cases to mapped values, and preserves the containership of the Result.
 */
export function mapMonad<SuccessData, ErrorData, MappedSuccess = SuccessData, MappedError = ErrorData>(
  result: Result<SuccessData, ErrorData>,
  successMapper: ((successData: SuccessData) => MappedSuccess),
  errorMapper: (errorData: ErrorData) => MappedError,
): Result<MappedSuccess, MappedError> {
  if (result.isSuccess) {
    return constructSuccess(successMapper(result.data));
  }
  if (result.isFailure) {
    return constructFailure(errorMapper(result.error));
  }
  assertNever(result);
}

export function mapperMonad<SuccessData, ErrorData, MappedSuccess = SuccessData, MappedError = ErrorData>(
  successMapper: ((successData: SuccessData) => MappedSuccess),
  errorMapper: (errorData: ErrorData) => MappedError,
): (result: Result<SuccessData, ErrorData>) => Result<MappedSuccess, MappedError> {
  return result => mapMonad(result, successMapper, errorMapper);
}
