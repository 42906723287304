import * as AuthActions from '../auth/AuthActionTypes';
import Model from '../models/Model';
import { ArgTypes } from '../utils/ArgValidation';

export const initialState = new (Model({
  enabled: { type: ArgTypes.bool, defaultValue: false },
}))();

const triggerAppcues = (state) => {
  if (window.hasOwnProperty('Appcues')) {
    window.Appcues.identify(window.location.hostname, { loggedIn: 'Yes' });
  }
  return state.set('enabled', true);
};

export const reducers = {
  'Location.change': triggerAppcues,
  [AuthActions.LOGIN_COMPLETED]: triggerAppcues,
};
