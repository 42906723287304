import { is } from 'immutable';

import createLoader from '../utils/createLoader';
import { fetch } from './DatasetCatalogApi';
import { getFilterInfo } from './DatasetCatalogStore';

const DatasetCatalogLoader = createLoader((state) => {
  const { datasetCatalog: { loadedFilterInfo, loading }, projects: { initialFetch } } = state;
  return {
    canFetch: initialFetch,
    shouldFetch: !is(getFilterInfo(state), loadedFilterInfo),
    loading,
  };
}, {
  onFetch: fetch,
}, 'DatasetCatalogLoader');

export default DatasetCatalogLoader;
