import React, { useState } from 'react';

import { copyToClipboard } from '../utils/copyToClipboard';
import TamrIcon from './TamrIcon';
import TooltipTrigger, { PlacementOption } from './TooltipTrigger';

const CopyIcon: React.FC<{
  className?: string
  getValue: () => string
  initialTooltipContent: React.ReactNode
  size: number
  tooltipPlacement?: PlacementOption
}> = ({ getValue, size, initialTooltipContent, tooltipPlacement = 'top', className }) => {
  const [showCopiedMsg, setShowCopiedMsg] = useState<boolean>(false);
  return (
    <TooltipTrigger
      placement={tooltipPlacement}
      content={showCopiedMsg ? 'Copied!' : initialTooltipContent}
    >
      <TamrIcon
        className={className || undefined}
        iconName="file-copy"
        size={size}
        onClick={(e) => {
          e.stopPropagation();
          copyToClipboard(getValue);
          setShowCopiedMsg(true);
        }}
        onMouseEnter={(e) => {
          e.stopPropagation();
          setShowCopiedMsg(false);
        }}
      />
    </TooltipTrigger>
  );
};

export default CopyIcon;
