import { List } from 'immutable';

import Model from '../../models/Model';
import ScoreThresholds from '../../models/ScoreThresholds';
import { ArgTypes } from '../../utils/ArgValidation';

export const ClusterTotal = Model({
  name: { type: ArgTypes.string },
  value: { type: ArgTypes.number },
});

export const SuggestedLabelCounts = Model({
  total: { type: ArgTypes.number, defaultValue: 0 },
  highConfidence: { type: ArgTypes.number, defaultValue: 0 },
  mediumConfidence: { type: ArgTypes.number, defaultValue: 0 },
  lowConfidence: { type: ArgTypes.number, defaultValue: 0 },
});

export const initialState = new (Model({
  suggestedLabels: { type: ArgTypes.instanceOf(SuggestedLabelCounts), defaultValue: new SuggestedLabelCounts() },
  loadedSuggestedLabelsForThresholds: { type: ArgTypes.nullable(ArgTypes.instanceOf(ScoreThresholds)), defaultValue: undefined },
  loadingSuggestedLabelCounts: { type: ArgTypes.bool, defaultValue: false },
  totalClusters: { type: ArgTypes.number, defaultValue: 0 },
  topClustersByCount: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(ClusterTotal)), defaultValue: new List() },
  topClustersBySpend: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(ClusterTotal)), defaultValue: new List() },
  loadedTopClusters: { type: ArgTypes.bool, defaultValue: false },
  loadingTopClusters: { type: ArgTypes.bool, defaultValue: false },
}))();

export const reducers = {
  'Location.projectChange': (state) => {
    return state.clear(); // TODO make loaders consider project name as filter info and ensure this doesn't reset loading flags
  },

  'DedupDashboard.fetchSuggestedLabelsByConfidence': (state) => {
    return state.set('loadingSuggestedLabelCounts', true);
  },

  'DedupDashboard.fetchSuggestedLabelsByConfidenceCompleted': (state, { suggestedLabels, loadedSuggestedLabelsForThresholds }) => {
    return state.merge({ loadingSuggestedLabelCounts: false, suggestedLabels, loadedSuggestedLabelsForThresholds });
  },

  'DedupDashboard.fetchTopClusters': (state) => {
    return state.set('loadingTopClusters', true);
  },

  'DedupDashboard.fetchTopClustersCompleted': (state, { topClustersByCount, topClustersBySpend, totalClusters }) => {
    return state.merge({ loadingTopClusters: false, loadedTopClusters: true, topClustersByCount, topClustersBySpend, totalClusters });
  },
};
