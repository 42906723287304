import { isNumber } from 'underscore';

import { longFormat } from '../utils/Numbers';

/**
 * Produces a string that describes some count that has a current total and an overall total.
 * If no "filters are active", then this string is just the overallTotal number.
 * If filters are active, this string is "x of y", where x is the currentTotal and y is the overallTotal.
 */
export function getFilterableTotalCount({ filtersActive, currentTotal, overallTotal }: {
  filtersActive: boolean
  currentTotal: number | undefined
  overallTotal: number | undefined
}): string | undefined {
  // if we haven't been able to get the total num records, refuse to display
  if (!isNumber(overallTotal)) return undefined;
  if (filtersActive) {
    // if we have filters active, but haven't fetched a current total, refuse to display
    if (!isNumber(currentTotal)) return undefined;
    return `${longFormat(currentTotal)} of ${overallTotal}`;
  }
  return `${longFormat(overallTotal)}`;
}
