import PropTypes from 'prop-types';
import React from 'react';

import TemplateStrings from './TemplateStrings';

/**
 * Key identifying information about selected object.
 */
class DetailsHeader extends React.Component {
  static propTypes = {
    createdAt: PropTypes.number,
    createdBy: PropTypes.string,
    name: PropTypes.string,
    realName: PropTypes.string.isRequired,
  };

  renderModificationSubheader = () => {
    if (this.props.createdBy && this.props.createdAt) {
      const message = TemplateStrings.Details.creationSubheader(
        this.props.createdAt,
        this.props.createdBy,
      );
      return (
        <div className="creation-subheader">
          {message}
        </div>
      );
    }
  };

  renderName = () => {
    const { name, realName } = this.props;
    if (name && name !== realName) {
      return `${name} (${realName})`;
    }
    return realName;
  };

  render() {
    return (
      <div className="details-header-component">
        <div className="name" title={this.renderName()}>
          {this.renderName()}
        </div>
        {this.renderModificationSubheader()}
      </div>
    );
  }
}

export default DetailsHeader;
