import { $TSFixMe } from '../utils/typescript';

export const TRAIN_PREDICT_CLUSTER_COMPLETED = 'Jobs.trainPredictClusterCompleted';
export const PREDICT_CLUSTER_COMPLETED = 'Jobs.predictClusterCompleted';
export const JOB_UPDATE = 'Jobs.jobUpdate';
export const PAIR_ESTIMATES_COMPLETED_2S_AGO = 'Jobs.pairEstimatesCompleted2sAgo';
export const PAIR_ESTIMATES_COMPLETED_5S_AGO = 'Jobs.pairEstimatesCompleted5sAgo';
export const CLASSIFICATION_COMPLETED = 'Jobs.classificationCompleted';
export const SCHEMA_MAPPING_RECOMMENDATIONS_JOB_COMPLETED
  = 'Jobs.schemaMappingRecommendationsJobCompleted';
export const FINISHED_PROFILING = 'Jobs.finishedProfiling';

interface JobsTrainPredictClusterCompleted {
  type: typeof TRAIN_PREDICT_CLUSTER_COMPLETED
}

interface JobsPredictClusterCompleted {
  type: typeof PREDICT_CLUSTER_COMPLETED
}

interface JobsJobUpdate {
  type: typeof JOB_UPDATE
  job: $TSFixMe
}

interface PairEstimatesCompleted2sAgo {
  type: typeof PAIR_ESTIMATES_COMPLETED_2S_AGO
}

interface PairEstimatesCompleted5sAgo {
  type: typeof PAIR_ESTIMATES_COMPLETED_5S_AGO
}

interface ClassificationCompleted {
  type: typeof CLASSIFICATION_COMPLETED
}

interface SchemaMappingRecommendationsJobCompleted {
  type: typeof SCHEMA_MAPPING_RECOMMENDATIONS_JOB_COMPLETED
}

interface FinishedProfiling {
  type: typeof FINISHED_PROFILING
}

export type JobsActionTypes
  = JobsTrainPredictClusterCompleted
  | JobsPredictClusterCompleted
  | JobsJobUpdate
  | PairEstimatesCompleted2sAgo
  | PairEstimatesCompleted5sAgo
  | ClassificationCompleted
  | SchemaMappingRecommendationsJobCompleted
  | FinishedProfiling
