/**
 * A selector component with an empty default option. Values should be a list
 * of objects contaning 'display' and 'value'
 */

import './Input.scss';

import classNames from 'classnames';
import React, { ReactNode, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import _ from 'underscore';

import { $TSFixMe } from '../../utils/typescript';
import TamrIcon from '../TamrIcon';


export interface SelectorValuesElementType<ValueType> {
  value: ValueType
  display: ReactNode
  selectedDisplay?: ReactNode
}

interface SelectorProps<ValueType> {
  className?: string,
  disabled?: boolean,
  // If true, will by default make the dropdown open above the trigger
  dropup?: boolean,
  onChange: (value: ValueType) => void,
  placeholder?: ReactNode,
  showTitleHover?: boolean,
  value: ValueType,
  values: SelectorValuesElementType<ValueType>[],
}

function Selector<ValueType>({ className, disabled, dropup, onChange, placeholder, showTitleHover, value, values }: SelectorProps<ValueType>) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selected = _.findWhere(values, { value });
  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
      <div
        className={classNames('tamr-selector', className, { open: isOpen, dropup, disabled })}
        // @ts-expect-error not sure how to handle this right now, since title expects string, but
        //            both display and placeholder are node-type.
        title={(showTitleHover ? selected?.display || placeholder : null)}
      >
        <div className="tamr-selector-button" onClick={() => setIsOpen(!isOpen && !disabled)}>
          <div className="tamr-selector-text">{(selected?.selectedDisplay || selected?.display || placeholder)}</div>
          <TamrIcon
            className="tamr-selector-caret"
            size={14}
            iconName={isOpen ? 'tamr-icon-caret-flat' : 'tamr-icon-caret'}
          />
        </div>
        {isOpen && !disabled ?
          <div className="tamr-selector-menu">
            {values.map(({ display, value: v }: { display: $TSFixMe, value: $TSFixMe }, i) => {
              return (
                <div
                  title={showTitleHover ? display : null}
                  key={i}
                  className="tamr-selector-item"
                  onClick={() => {
                    setIsOpen(false);
                    onChange(v);
                  }}
                >
                  {display}
                </div>
              );
            })}
          </div>
          : null}
      </div>
    </OutsideClickHandler>
  );
}

export default Selector;
