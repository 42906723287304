import { List, Map } from 'immutable';
import { createSelector } from 'reselect';
import { isString } from 'underscore';

import AuthorizationPolicy from '../models/AuthorizationPolicy';
import { Roles } from '../models/AuthUser';
import Document from '../models/doc/Document';
import Group from '../models/Group';
import MinimalAuthUser from '../models/MinimalAuthUser';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import { BEGIN_EDITING_PROJECT } from '../projects/ProjectsActionTypes';
import { StoreReducers } from '../stores/AppAction';
import { AppState } from '../stores/MainStore';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { AppSelector, createAppStateSelector } from '../utils/Selectors';
import PolicyConfig from './PolicyConfig';


export class PolicyFilterAndCacheInfo extends getModelHelpers({
  sequenceNumber: { type: ArgTypes.number, defaultValue: 0 },
}, 'PolicyFilterAndCacheInfo')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class PolicyFilterAndCacheInfoRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

export class DatasetPolicyResourceship extends getModelHelpers({
  datasetId: { type: ArgTypes.string },
  datasetName: { type: ArgTypes.string },
  draftPoliciesById: { type: ArgTypes.Immutable.map.of(AuthorizationPolicy.argType, ArgTypes.number), defaultValue: Map<number, AuthorizationPolicy>() },
}, 'DatasetPolicyResourceship')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class DatasetPolicyResourceshipRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  getPolicyIds() {
    return this.draftPoliciesById
      .filter((policy) => policy.datasetIds().includes(this.datasetId))
      .keys();
  }
}

class UserPolicyMembership extends getModelHelpers({
  user: { type: MinimalAuthUser.argType },
  draftPoliciesById: { type: ArgTypes.Immutable.map.of(AuthorizationPolicy.argType, ArgTypes.number), defaultValue: Map<number, AuthorizationPolicy>() },
}, 'UserPolicyMembership')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class UserPolicyMembershipRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  getPolicy({ policyId }: { policyId: number }): AuthorizationPolicy | undefined {
    return this.draftPoliciesById.get(policyId);
  }

  updatePolicy({ policyId, updatedPolicy }: { policyId: number, updatedPolicy: AuthorizationPolicy }): UserPolicyMembership {
    return this.update('draftPoliciesById', currentDraft => currentDraft.set(policyId, updatedPolicy));
  }
}

export class ProjectPolicyManager extends getModelHelpers({
  projectId: { type: ArgTypes.number },
  projectName: { type: ArgTypes.string },
  draftPolicyMembership: { type: ArgTypes.Immutable.set.of(ArgTypes.number) },
  draftPolicyResourceship: { type: ArgTypes.Immutable.set.of(ArgTypes.number) },
}, 'ProjectPolicyManager')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class UserPolicyMembershipRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}


export class UserGroupPolicyMembership extends getModelHelpers({
  group: { type: Group.argType },
  draftPoliciesById: { type: ArgTypes.Immutable.map.of(AuthorizationPolicy.argType, ArgTypes.number), defaultValue: Map<number, AuthorizationPolicy>() },
}, 'UserGroupPolicyMembership')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class UserGroupPolicyMembershipRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  getPolicy({ policyId }: { policyId: number }): AuthorizationPolicy | undefined {
    return this.draftPoliciesById.get(policyId);
  }

  updatePolicy({ policyId, updatedPolicy }: { policyId: number, updatedPolicy: AuthorizationPolicy }): UserGroupPolicyMembership {
    return this.update('draftPoliciesById', currentDraft => currentDraft.set(policyId, updatedPolicy));
  }
}

class PolicyEntityCount extends getModelHelpers({
  count: { type: ArgTypes.number, defaultValue: 0 },
  appliesToAll: { type: ArgTypes.bool, defaultValue: false },
}, 'PolicyEntityCount')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class PolicyEntityCountRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}


class AccessControlStore extends getModelHelpers({
  policyDocs: { type: ArgTypes.Immutable.list.of(Document.argTypeWithNestedClass(AuthorizationPolicy)), defaultValue: List<Document<AuthorizationPolicy>>() },
  projectNamesById: { type: ArgTypes.Immutable.map.of(ArgTypes.string, ArgTypes.number), defaultValue: Map<number, string>() },
  projectDescriptionsById: { type: ArgTypes.Immutable.map.of(ArgTypes.string, ArgTypes.number), defaultValue: Map<number, string>() },
  loadingPolicies: { type: ArgTypes.bool, defaultValue: false },
  // FILTER AND CACHE INFO //
  currentSequenceNumber: { type: ArgTypes.number, defaultValue: 0 },
  loadedSequenceNumber: { type: ArgTypes.number, defaultValue: 0 },
  // ******************* //
  policyConfig: { type: ArgTypes.nullable(PolicyConfig.argType) },
  datasetDraftPolicyResourceship: { type: ArgTypes.nullable(DatasetPolicyResourceship.argType) },
  projectDraftPolicyManager: { type: ArgTypes.nullable(ProjectPolicyManager.argType) },
  userDraftPolicyMembership: { type: ArgTypes.nullable(UserPolicyMembership.argType) },
  userGroupDraftPolicyMembership: { type: ArgTypes.nullable(UserGroupPolicyMembership.argType) },
}, 'AccessControlStore')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class AccessControlStoreRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

export const initialState = new AccessControlStore({});

export const getCurrentFilterAndCacheInfo = (state: AppState): PolicyFilterAndCacheInfo => {
  const { accessControl: { currentSequenceNumber } } = state;
  return new PolicyFilterAndCacheInfo({ sequenceNumber: currentSequenceNumber });
};

export const getLoadedFilterAndCacheInfo = (state: AppState): PolicyFilterAndCacheInfo => {
  const { accessControl: { loadedSequenceNumber } } = state;
  return new PolicyFilterAndCacheInfo({ sequenceNumber: loadedSequenceNumber });
};

export const getPolicyConfig = (state: AppState) => state.accessControl.policyConfig;

/**
 * Get the display name of an user.
 *
 * If the user is null, the display name is also null.
 * Otherwise, if the user's name is set, it is the display name.
 * Otherwise, the user's username is the display name.
 */
const getDisplayName = (user: MinimalAuthUser | null | undefined): string | undefined => {
  return user?.user.name || user?.username;
};

/**
 * Get usernames of users that are actually in the policy
 *
 * Sort: by name (first + last) or username
 * Filter: remove admins; if the query is nonempty, only include users whose display names contain the query (case-insensitive)
 */
export const selectUsernamesInPolicyToManage: AppSelector<List<string>> = createSelector(
  getPolicyConfig,
  policyConfig => (!policyConfig ? List() : policyConfig.case<List<string>>({
    ManageMembers: ({ currentPolicy, authUsers, query }) => {
      return authUsers
        .filter(user => currentPolicy.data.roleForUsername(user.username) && !user.admin)
        .filter(user => !query || getDisplayName(user)?.toLowerCase().includes(query.toLowerCase()))
        .sortBy(getDisplayName)
        .map(user => user.username);
    },
    Create: () => List<string>(),
    Delete: () => List<string>(),
    Duplicate: () => List<string>(),
    Edit: () => List<string>(),
    ManageResources: () => List<string>(),
  })),
);

/**
 * Get usernames of users that are NOT actually in the policy
 *
 * Sort: by name (first + last) or username
 * Filter: remove admins; if the query is nonempty, only include users whose display names contain the query (case-insensitive)
 */
export const selectUsernamesNotInPolicyToManage = createAppStateSelector(
  ArgTypes.Immutable.list.of(ArgTypes.string),
  getPolicyConfig,
  policyConfig => (!policyConfig ? List() : policyConfig.case({
    ManageMembers: ({ currentPolicy, authUsers, query }) => {
      return authUsers
        .filter(user => !currentPolicy.data.roleForUsername(user.username) && !user.admin)
        .filter(user => !query || getDisplayName(user)?.toLowerCase().includes(query.toLowerCase()))
        .sortBy(getDisplayName)
        .map(user => user.username);
    },
    Create: () => List<string>(),
    Delete: () => List<string>(),
    Duplicate: () => List<string>(),
    Edit: () => List<string>(),
    ManageResources: () => List<string>(),
  })),
);

/**
 * Get the name of a user (returns the username if the name isn't defined)
 */
export const getNameByUsername = (state: AppState) => (username: string): string => {
  const { accessControl: { policyConfig } } = state;
  return !policyConfig ? username : policyConfig.case({
    ManageMembers: ({ authUsers }) => getDisplayName(authUsers.find(au => au.username === username)) || username,
    Create: () => '',
    Delete: () => '',
    Duplicate: () => '',
    Edit: () => '',
    ManageResources: () => '',
  });
};

/**
 * Get the email of a user (returns null if the user doesn't have one)
 */
export const getEmailByUsername = (state: AppState) => (username: string): string | null => {
  checkArg({ username }, ArgTypes.string);
  const { accessControl: { policyConfig } } = state;
  return !policyConfig ? null : policyConfig.case({
    ManageMembers: ({ authUsers }) => authUsers.find(au => au.username === username)?.user.email || null,
    Create: () => null,
    Delete: () => null,
    Duplicate: () => null,
    Edit: () => null,
    ManageResources: () => null,
  });
};

/**
 * Get groups that the user is part of
 */
export const getGroupsByUsername = (state: AppState) => (username: string): List<string> => {
  checkArg({ username }, ArgTypes.string);
  const { accessControl: { policyConfig } } = state;
  return !policyConfig ? List() : policyConfig.case({
    ManageMembers: ({ authUsers }) => authUsers.find(au => au.username === username)?.groups.toList() || List(),
    Create: () => List<string>(),
    Delete: () => List<string>(),
    Duplicate: () => List<string>(),
    Edit: () => List<string>(),
    ManageResources: () => List<string>(),
  });
};

/**
 * Get role of the user in the current policy
 */
export const getPolicyRoleByUsername = (state: AppState) => (username: string | null | undefined): string | null | undefined => {
  checkArg({ username }, ArgTypes.nullable(ArgTypes.string));
  const { accessControl: { policyConfig } } = state;
  return !policyConfig ? null : policyConfig.case({
    ManageMembers: ({ draftUsernamesToRole }) => (isString(username) ? draftUsernamesToRole.get(username) : null),
    Create: () => null,
    Delete: () => null,
    Duplicate: () => null,
    Edit: () => null,
    ManageResources: () => null,
  });
};

/**
 * Whether the user is or isn't in the policy per the current draft state
 */
export const getInPolicyByUsername = (state: AppState) => (username: string | null | undefined): boolean => {
  checkArg({ username }, ArgTypes.nullable(ArgTypes.string));
  const { accessControl: { policyConfig } } = state;
  return !policyConfig ? false : policyConfig.case({
    ManageMembers: ({ draftUsernamesToRole }) => isString(username) && draftUsernamesToRole.keySeq().includes(username),
    Create: () => false,
    Delete: () => false,
    Duplicate: () => false,
    Edit: () => false,
    ManageResources: () => false,
  });
};

export const selectGroupnamesInPolicyToManage = createAppStateSelector(
  ArgTypes.Immutable.list.of(ArgTypes.string),
  getPolicyConfig,
  policyConfig => (!policyConfig ? List() : policyConfig.case({
    ManageMembers: ({ currentPolicy, groupDocs, query }) => groupDocs.filter(doc => !doc.data.admin)
      .map(doc => doc.data.groupname)
      .filter(groupname => currentPolicy.data.roleForGroupname(groupname))
      .filter(groupname => !query || groupname.toLowerCase().includes(query.toLowerCase()))
      .sort(),
    Create: () => List<string>(),
    Delete: () => List<string>(),
    Duplicate: () => List<string>(),
    Edit: () => List<string>(),
    ManageResources: () => List<string>(),
  })),
);

export const selectGroupnamesNotInPolicyToManage = createAppStateSelector(
  ArgTypes.Immutable.list.of(ArgTypes.string),
  getPolicyConfig,
  policyConfig => (!policyConfig ? List() : policyConfig.case({
    ManageMembers: ({ currentPolicy, groupDocs, query }) => groupDocs.filter(doc => !doc.data.admin)
      .map(doc => doc.data.groupname)
      .filter(groupname => !currentPolicy.data.roleForGroupname(groupname))
      .filter(groupname => !query || groupname.toLowerCase().includes(query.toLowerCase()))
      .sort(),
    Create: () => List<string>(),
    Delete: () => List<string>(),
    Duplicate: () => List<string>(),
    Edit: () => List<string>(),
    ManageResources: () => List<string>(),
  })),
);

export const getInPolicyByGroupname = (state: AppState) => (groupname: string): boolean => {
  checkArg({ groupname }, ArgTypes.string);
  const { accessControl: { policyConfig } } = state;
  return !policyConfig ? false : policyConfig.case({
    ManageMembers: ({ draftGroupnamesToRole }) => draftGroupnamesToRole.keySeq().includes(groupname),
    Create: () => false,
    Delete: () => false,
    Duplicate: () => false,
    Edit: () => false,
    ManageResources: () => false,
  });
};

export const getDescriptionByGroupname = (state: AppState) => (groupname: string): string | null | undefined => {
  checkArg({ groupname }, ArgTypes.string);
  const { accessControl: { policyConfig } } = state;
  return !policyConfig ? null : policyConfig.case({
    ManageMembers: ({ groupDocs }) => groupDocs.find(doc => doc.data.groupname === groupname)?.data.description,
    Create: () => null,
    Delete: () => null,
    Duplicate: () => null,
    Edit: () => null,
    ManageResources: () => null,
  });
};

export const getPolicyRoleByGroupname = (state: AppState) => (groupname: string): string | null | undefined => {
  checkArg({ groupname }, ArgTypes.string);
  const { accessControl: { policyConfig } } = state;
  return !policyConfig ? null : policyConfig.case({
    ManageMembers: ({ draftGroupnamesToRole }) => draftGroupnamesToRole.get(groupname),
    Create: () => null,
    Delete: () => null,
    Duplicate: () => null,
    Edit: () => null,
    ManageResources: () => null,
  });
};

const getProjectNamesById = createSelector(
  (state: AppState) => state.accessControl?.projectNamesById,
  ids => ids,
);

/**
 * Get ids of projects as members that are actually in the policy, sorted by project names
 */
export const selectProjectAsMemberIdsInPolicyToManage = createAppStateSelector(
  ArgTypes.Immutable.list.of(ArgTypes.string),
  getPolicyConfig,
  getProjectNamesById,
  (policyConfig, projectNamesById) => (!policyConfig ? List() : policyConfig.case({
    ManageMembers: ({ currentPolicy, query }) => currentPolicy.data.projectsAsMemberIds()
      .toList()
      .filter(id => !query || projectNamesById.get(id)?.toLowerCase().includes(query.toLowerCase()))
      .sortBy(id => projectNamesById.get(id))
      .map(String),
    Create: () => List<string>(),
    Delete: () => List<string>(),
    Duplicate: () => List<string>(),
    Edit: () => List<string>(),
    ManageResources: () => List<string>(),
  })),
);

/**
 * Get ids of projects as members that are not in the policy, sorted by project names
 */
export const selectProjectAsMemberIdsNotInPolicyToManage = createAppStateSelector(
  ArgTypes.Immutable.list.of(ArgTypes.string),
  getPolicyConfig,
  getProjectNamesById,
  (policyConfig, projectNamesById) => (!policyConfig ? List() : policyConfig.case({
    ManageMembers: ({ currentPolicy, query }) => projectNamesById.keySeq().toSet()
      .subtract(currentPolicy.data.projectsAsMemberIds())
      .filter(id => !query || projectNamesById.get(id)?.toLowerCase().includes(query.toLowerCase()))
      .sortBy(id => projectNamesById.get(id))
      .toList()
      .map(String),
    Create: () => List<string>(),
    Delete: () => List<string>(),
    Duplicate: () => List<string>(),
    Edit: () => List<string>(),
    ManageResources: () => List<string>(),
  })),
);

export const getInPolicyAsMemberByProjectId = (state: AppState) => (id: string): boolean => {
  const { accessControl: { policyConfig } } = state;
  return !policyConfig ? false : policyConfig.case({
    ManageMembers: ({ draftProjectIdsToRole }) => draftProjectIdsToRole.keySeq().includes(Number(id)),
    Create: () => false,
    Delete: () => false,
    Duplicate: () => false,
    Edit: () => false,
    ManageResources: () => false,
  });
};

/**
 * Get ids of projects that are actually in the policy, sorted by project name
 */
export const selectProjectIdsInPolicyToManage = createAppStateSelector(
  ArgTypes.Immutable.list.of(ArgTypes.string),
  getPolicyConfig,
  getProjectNamesById,
  (policyConfig, projectNamesById) => (!policyConfig ? List() : policyConfig.case({
    ManageResources: ({ currentPolicy }) => currentPolicy.data.projectIds()
      .toList()
      .sortBy(id => projectNamesById.get(id))
      .map(String),
    Create: () => List<string>(),
    Delete: () => List<string>(),
    Duplicate: () => List<string>(),
    Edit: () => List<string>(),
    ManageMembers: () => List<string>(),
  })),
);

export const selectProjectIdsNotInPolicyToManage = createAppStateSelector(
  ArgTypes.Immutable.list.of(ArgTypes.string),
  getPolicyConfig,
  getProjectNamesById,
  (policyConfig, projectNamesById) => (!policyConfig ? List() : policyConfig.case({
    ManageResources: ({ currentPolicy }) => projectNamesById.keySeq().toSet()
      .subtract(currentPolicy.data.projectIds())
      .sortBy(id => projectNamesById.get(id))
      .toList()
      .map(String),
    Create: () => List<string>(),
    Delete: () => List<string>(),
    Duplicate: () => List<string>(),
    Edit: () => List<string>(),
    ManageMembers: () => List<string>(),
  })),
);

export const getInPolicyByProjectId = (state: AppState) => (id: string): boolean => {
  checkArg({ id }, ArgTypes.string);
  const { accessControl: { policyConfig } } = state;
  return !policyConfig ? false : policyConfig.case({
    ManageResources: ({ draftProjectIds }) => draftProjectIds.includes(id),
    Create: () => false,
    Delete: () => false,
    Duplicate: () => false,
    Edit: () => false,
    ManageMembers: () => false,
  });
};

export const getNameByProjectId = (state: AppState) => (id: string) => {
  checkArg({ id }, ArgTypes.string);
  const { accessControl: { projectNamesById } } = state;
  return projectNamesById.get(Number(id));
};

export const getDescriptionByProjectId = (state: AppState) => (id: string) => {
  checkArg({ id }, ArgTypes.string);
  const { accessControl: { projectDescriptionsById } } = state;
  return projectDescriptionsById.get(Number(id));
};

export const getPolicyById = (state: AccessControlStore) => (id: number): AuthorizationPolicy | undefined => {
  checkArg({ id }, ArgTypes.number);
  const { policyDocs } = state;
  return policyDocs?.find(doc => doc.id.id === id)?.data;
};

export const getPolicyByIdFromAppState = (state: AppState) => (id: number): AuthorizationPolicy | undefined => {
  checkArg({ id }, ArgTypes.number);
  const { accessControl: { policyDocs } } = state;
  return policyDocs?.find(doc => doc.id.id === id)?.data;
};

export const getPolicyDocs = (state: AppState) => (): List<Document<AuthorizationPolicy>> => {
  const { accessControl: { policyDocs } } = state;
  return policyDocs;
};

export const getUsersCount = (state: AppState) => (id: number): PolicyEntityCount => {
  checkArg({ id }, ArgTypes.number);
  const { users: { users }, accessControl } = state;
  const policy = getPolicyById(accessControl)(id);
  const adminUsers = (users as List<MinimalAuthUser>).filter(u => u.admin).map(u => u.username).toSet();
  return new PolicyEntityCount({
    count: policy?.users().filter(username => !adminUsers.includes(username)).size,
    appliesToAll: !!policy?.appliesToAllUsers(),
  });
};

export const getUserGroupsCount = (state: AppState) => (id: number): PolicyEntityCount => {
  checkArg({ id }, ArgTypes.number);
  const { users: { groups }, accessControl } = state;
  const policy = getPolicyById(accessControl)(id);
  const adminGroups = (groups as List<Document<Group>>).filter(g => g.data.admin).map(g => g.data.groupname).toSet();
  return new PolicyEntityCount({
    count: policy?.userGroups().filter(groupname => !adminGroups.includes(groupname)).size,
    appliesToAll: !!policy?.appliesToAllUserGroups(),
  });
};

const reloadPolicies = (state: AccessControlStore) => state.update('currentSequenceNumber', n => n + 1);
const reloadPoliciesAndDone = (state: AccessControlStore) => reloadPolicies(state).delete('policyConfig');

export const selectResourcePolicyIdsInPolicyToManage = createAppStateSelector(
  ArgTypes.Immutable.list.of(ArgTypes.string),
  getPolicyConfig,
  getPolicyByIdFromAppState,
  (policyConfig, policyById) => (!policyConfig ? List() : policyConfig.case({
    ManageResources: ({ currentPolicy }) => currentPolicy.data.resourcePolicyIds()
      .toList()
      .sortBy(id => policyById(Number(id))?.name)
      .map(String),
    Create: () => List<string>(),
    Delete: () => List<string>(),
    Duplicate: () => List<string>(),
    Edit: () => List<string>(),
    ManageMembers: () => List<string>(),
  })),
);
export const selectResourcePolicyIdsNotInPolicyToManage = createAppStateSelector(
  ArgTypes.Immutable.list.of(ArgTypes.string),
  getPolicyConfig,
  getPolicyDocs,
  getPolicyByIdFromAppState,
  (policyConfig, policyDocs, policyById) => (!policyConfig ? List() : policyConfig.case({
    ManageResources: ({ currentPolicy }) => policyDocs().map(d => String(d.id.id)).toSet()
      .subtract(currentPolicy.data.resourcePolicyIds())
      .sortBy(id => policyById(Number(id))?.name)
      .toList()
      .map(String),
    Create: () => List<string>(),
    Delete: () => List<string>(),
    Duplicate: () => List<string>(),
    Edit: () => List<string>(),
    ManageMembers: () => List<string>(),
  })),
);

export const getInPolicyByResourcePolicyId = (state: AppState) => (id: string): boolean => {
  checkArg({ id }, ArgTypes.string);
  const { accessControl: { policyConfig } } = state;
  return !policyConfig ? false : policyConfig.case({
    ManageResources: ({ draftResourcePolicyIds }) => draftResourcePolicyIds.includes(id),
    Create: () => false,
    Delete: () => false,
    Duplicate: () => false,
    Edit: () => false,
    ManageMembers: () => false,
  });
};

export const reducers: StoreReducers<AccessControlStore> = {
  'AccessControl.reloadPolicies': reloadPolicies,
  'DatasetCatalog.reload': reloadPolicies,
  'ProjectDatasetCatalog.reload': reloadPolicies,
  [BEGIN_EDITING_PROJECT]: reloadPolicies,
  'AccessControl.fetchAllPolicies': (state) => {
    return state.merge({ loadingPolicies: true });
  },
  'AccessControl.fetchAllPoliciesCompleted': (state, { filterAndCacheInfo, policyDocs, projectNamesById, projectDescriptionsById }) => {
    const { sequenceNumber } = filterAndCacheInfo;
    return state.merge({ policyDocs, projectNamesById, projectDescriptionsById, loadedSequenceNumber: sequenceNumber }).delete('loadingPolicies');
  },
  'AccessControl.fetchAllPoliciesFailed': (state, { filterAndCacheInfo }) => {
    const { sequenceNumber } = filterAndCacheInfo;
    return state.merge({ loadedSequenceNumber: sequenceNumber }).delete('loadingPolicies');
  },
  'AccessControl.stopConfigPolicy': (state) => {
    return state.delete('policyConfig');
  },
  'AccessControl.startCreatePolicy': (state) => {
    return state.set('policyConfig', PolicyConfig.Create({ name: '', description: '', creating: false }));
  },
  'AccessControl.setNewPolicyName': (state, { name }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      Create: (currentValues) => PolicyConfig.Create({ ...currentValues, name }),
      ManageResources: () => current,
      Delete: () => current,
      Duplicate: () => current,
      Edit: () => current,
      ManageMembers: () => current,
    }));
  },
  'AccessControl.setNewPolicyDescription': (state, { description }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      Create: (currentValues) => PolicyConfig.Create({ ...currentValues, description }),
      ManageResources: () => current,
      Delete: () => current,
      Duplicate: () => current,
      Edit: () => current,
      ManageMembers: () => current,
    }));
  },
  'AccessControl.createPolicy': (state) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      Create: (currentValues) => PolicyConfig.Create({ ...currentValues, creating: true }),
      ManageResources: () => current,
      Delete: () => current,
      Duplicate: () => current,
      Edit: () => current,
      ManageMembers: () => current,
    }));
  },
  'AccessControl.createPolicyCompleted': reloadPolicies,
  'AccessControl.createPolicyFailed': (state) => {
    return state.delete('policyConfig');
  },
  'AccessControl.startEditPolicy': (state, { policy }) => {
    return state.set('policyConfig', PolicyConfig.Edit({ name: policy.data.name, description: policy.data.description || '', currentPolicy: policy, updating: false }));
  },
  'AccessControl.editPolicy': (state) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      Edit: (currentValues) => PolicyConfig.Edit({ ...currentValues, updating: true }),
      ManageResources: () => current,
      Delete: () => current,
      Duplicate: () => current,
      Create: () => current,
      ManageMembers: () => current,
    }));
  },
  'AccessControl.editPolicyName': (state, { name }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      Edit: (currentValues) => PolicyConfig.Edit({ ...currentValues, name }),
      ManageResources: () => current,
      Delete: () => current,
      Duplicate: () => current,
      Create: () => current,
      ManageMembers: () => current,
    }));
  },
  'AccessControl.editPolicyDescription': (state, { description }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      Edit: (currentValues) => PolicyConfig.Edit({ ...currentValues, description }),
      ManageResources: () => current,
      Delete: () => current,
      Duplicate: () => current,
      Create: () => current,
      ManageMembers: () => current,
    }));
  },
  'AccessControl.editPolicyCompleted': reloadPoliciesAndDone,
  'AccessControl.editPolicyFailed': (state) => {
    return state.delete('policyConfig');
  },
  'AccessControl.startDuplicatePolicy': (state, { policy }) => {
    return state.set('policyConfig', PolicyConfig.Duplicate({ name: `Copy of ${policy.data.name}`, description: policy.data.description || '', currentPolicy: policy, duplicating: false }));
  },
  'AccessControl.duplicatePolicy': (state) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      Duplicate: (currentValues) => PolicyConfig.Duplicate({ ...currentValues, duplicating: true }),
      ManageResources: () => current,
      Delete: () => current,
      Edit: () => current,
      Create: () => current,
      ManageMembers: () => current,
    }));
  },
  'AccessControl.setDuplicatePolicyName': (state, { name }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      Duplicate: (currentValues) => PolicyConfig.Duplicate({ ...currentValues, name }),
      ManageResources: () => current,
      Delete: () => current,
      Edit: () => current,
      Create: () => current,
      ManageMembers: () => current,
    }));
  },
  'AccessControl.setDuplicatePolicyDescription': (state, { description }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      Duplicate: (currentValues) => PolicyConfig.Duplicate({ ...currentValues, description }),
      ManageResources: () => current,
      Delete: () => current,
      Edit: () => current,
      Create: () => current,
      ManageMembers: () => current,
    }));
  },
  'AccessControl.duplicatePolicyCompleted': reloadPoliciesAndDone,
  'AccessControl.duplicatePolicyFailed': (state) => {
    return state.delete('policyConfig');
  },
  'AccessControl.startDeletePolicy': (state, { policy }) => {
    return state.set('policyConfig', PolicyConfig.Delete({ currentPolicy: policy, deleting: false }));
  },
  'AccessControl.deletePolicy': (state) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      Delete: ({ currentPolicy }) => PolicyConfig.Delete({ currentPolicy, deleting: true }),
      ManageResources: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      ManageMembers: () => current,
    }));
  },
  'AccessControl.deletePolicyCompleted': reloadPoliciesAndDone,
  'AccessControl.deletePolicyFailed': (state) => {
    return state.delete('policyConfig');
  },
  'AccessControl.startManageMembers': (state, { policyDoc, memberType }) => {
    return state.set('policyConfig', PolicyConfig.ManageMembers({
      currentPolicy: policyDoc,
      updating: false,
      tab: memberType,
      authUsers: List(),
      groupDocs: List(),
      draftUsernamesToRole: Map(),
      draftGroupnamesToRole: Map(),
      draftProjectIdsToRole: Map(),
      applyToAllUsers: false,
      allUsersRole: '',
      applyToAllUserGroups: false,
      allUserGroupsRole: '',
      applyToAllProjects: false,
      query: '',
    }));
  },
  'AccessControl.startManageMembersCompleted': (state, { authUsers, groupDocs }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageMembers: ({ currentPolicy, ...currentValues }) => PolicyConfig.ManageMembers({
        ...currentValues,
        currentPolicy,
        authUsers,
        groupDocs,
        draftUsernamesToRole: currentPolicy.data.roleByUsername(),
        draftGroupnamesToRole: currentPolicy.data.roleByGroupname(),
        draftProjectIdsToRole: currentPolicy.data.roleByProjectId(),
        applyToAllUsers: !!currentPolicy.data.appliesToAllUsers(),
        allUsersRole: currentPolicy.data.appliesToAllUsers() || Roles.CURATOR,
        applyToAllUserGroups: !!currentPolicy.data.appliesToAllUserGroups(),
        allUserGroupsRole: currentPolicy.data.appliesToAllUserGroups() || Roles.CURATOR,
        applyToAllProjects: currentPolicy.data.appliesToAllMemberProjects(),
      }),
      ManageResources: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.startManageMembersFailed': (state) => {
    return state.delete('policyConfig');
  },
  'AccessControl.setManageMembers': (state, { memberType }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageMembers: (currentValues) => PolicyConfig.ManageMembers({ ...currentValues, tab: memberType, query: '' }),
      ManageResources: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.toggleAllUsers': (state) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageMembers: ({ applyToAllUsers, ...currentValues }) => PolicyConfig.ManageMembers({ ...currentValues, applyToAllUsers: !applyToAllUsers }),
      ManageResources: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.updateAllUsersRole': (state, { allUsersRole }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageMembers: ({ ...currentValues }) => PolicyConfig.ManageMembers({ ...currentValues, allUsersRole }),
      ManageResources: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.addUserToPolicy': (state, { username }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageMembers: ({ currentPolicy, draftUsernamesToRole, ...currentValues }) => PolicyConfig.ManageMembers({ ...currentValues, currentPolicy, draftUsernamesToRole: draftUsernamesToRole.set(username, Roles.REVIEWER) }),
      ManageResources: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.changeUserRoleInPolicy': (state, { username, newRole }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageMembers: ({ draftUsernamesToRole, ...currentValues }) => PolicyConfig.ManageMembers({ ...currentValues, draftUsernamesToRole: draftUsernamesToRole.set(username, newRole) }),
      ManageResources: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.removeUserFromPolicy': (state, { username }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageMembers: ({ draftUsernamesToRole, ...currentValues }) => PolicyConfig.ManageMembers({ ...currentValues, draftUsernamesToRole: draftUsernamesToRole.delete(username) }),
      ManageResources: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.toggleAllUserGroups': (state) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageMembers: ({ applyToAllUserGroups, ...currentValues }) => PolicyConfig.ManageMembers({ ...currentValues, applyToAllUserGroups: !applyToAllUserGroups }),
      ManageResources: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.updateAllUserGroupsRole': (state, { allUserGroupsRole }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageMembers: ({ ...currentValues }) => PolicyConfig.ManageMembers({ ...currentValues, allUserGroupsRole }),
      ManageResources: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.addGroupToPolicy': (state, { groupname }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageMembers: ({ draftGroupnamesToRole, ...currentValues }) => PolicyConfig.ManageMembers({ ...currentValues, draftGroupnamesToRole: draftGroupnamesToRole.set(groupname, Roles.REVIEWER) }),
      ManageResources: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.changeGroupRoleInPolicy': (state, { groupname, newRole }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageMembers: ({ draftGroupnamesToRole, ...currentValues }) => PolicyConfig.ManageMembers({ ...currentValues, draftGroupnamesToRole: draftGroupnamesToRole.set(groupname, newRole) }),
      ManageResources: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.removeGroupFromPolicy': (state, { groupname }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageMembers: ({ draftGroupnamesToRole, ...currentValues }) => PolicyConfig.ManageMembers({ ...currentValues, draftGroupnamesToRole: draftGroupnamesToRole.delete(groupname) }),
      ManageResources: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.addProjectAsMemberToPolicy': (state, { id }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageMembers: ({ draftProjectIdsToRole, ...currentValues }) => PolicyConfig.ManageMembers({ ...currentValues, draftProjectIdsToRole: draftProjectIdsToRole.set(Number(id), Roles.PROJECT) }),
      ManageResources: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.removeProjectAsMemberFromPolicy': (state, { id }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageMembers: ({ draftProjectIdsToRole, ...currentValues }) => PolicyConfig.ManageMembers({ ...currentValues, draftProjectIdsToRole: draftProjectIdsToRole.delete(Number(id)) }),
      ManageResources: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.toggleAllProjectsAsMembers': (state) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageMembers: ({ applyToAllProjects, ...currentValues }) => PolicyConfig.ManageMembers({ ...currentValues, applyToAllProjects: !applyToAllProjects }),
      ManageResources: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.updatePolicyMembers': (state) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageMembers: (currentValues) => PolicyConfig.ManageMembers({ ...currentValues, updating: true }),
      ManageResources: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.updatePolicyMembersCompleted': reloadPoliciesAndDone,
  'AccessControl.updatePolicyMembersFailed': (state) => {
    return state.delete('policyConfig');
  },
  'AccessControl.startManageResources': (state, { policyDoc, resourceType }) => {
    return state.set('policyConfig', PolicyConfig.ManageResources({ currentPolicy: policyDoc, tab: resourceType, updating: false, draftProjectIds: policyDoc.data.projectIds().map(String), draftResourcePolicyIds: policyDoc.data.resourcePolicyIds().map(String), datasetFilterOpen: false, applyToAllProjects: policyDoc.data.appliesToAllResourceProjects(), applyToAllDatasets: policyDoc.data.appliesToAllDatasets() }));
  },
  'AccessControl.setManageResources': (state, { resourceType }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageResources: (currentValues) => PolicyConfig.ManageResources({ ...currentValues, tab: resourceType }),
      ManageMembers: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.toggleAllProjectsAsResources': (state) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageResources: ({ applyToAllProjects, ...currentValues }) => PolicyConfig.ManageResources({ ...currentValues, applyToAllProjects: !applyToAllProjects }),
      ManageMembers: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.toggleAllDatasets': (state) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageResources: ({ applyToAllDatasets, ...currentValues }) => PolicyConfig.ManageResources({ ...currentValues, applyToAllDatasets: !applyToAllDatasets }),
      ManageMembers: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.addProjectToPolicy': (state, { id }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageResources: ({ draftProjectIds, ...currentValues }) => PolicyConfig.ManageResources({ ...currentValues, draftProjectIds: draftProjectIds.add(id) }),
      ManageMembers: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.removeProjectFromPolicy': (state, { id }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageResources: ({ draftProjectIds, ...currentValues }) => PolicyConfig.ManageResources({ ...currentValues, draftProjectIds: draftProjectIds.delete(id) }),
      ManageMembers: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.addPolicyAsResourceToPolicy': (state, { id }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageResources: ({ draftResourcePolicyIds, ...currentValues }) => PolicyConfig.ManageResources({ ...currentValues, draftResourcePolicyIds: draftResourcePolicyIds.add(id) }),
      ManageMembers: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.removePolicyAsResourceFromPolicy': (state, { id }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageResources: ({ draftResourcePolicyIds, ...currentValues }) => PolicyConfig.ManageResources({ ...currentValues, draftResourcePolicyIds: draftResourcePolicyIds.delete(id) }),
      ManageMembers: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.updatePolicyResources': (state) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageResources: (currentValues) => PolicyConfig.ManageResources({ ...currentValues, updating: true }),
      ManageMembers: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.updatePolicyResourcesCompleted': reloadPoliciesAndDone,
  'AccessControl.updatePolicyResourcesFailed': (state) => {
    return state.delete('policyConfig');
  },
  'AccessControl.startManageDatasets': (state) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageResources: (currentValues) => PolicyConfig.ManageResources({ ...currentValues, datasetFilterOpen: true }),
      ManageMembers: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'AccessControl.setQuery': (state, { query }) => {
    return !state.policyConfig ? state : state.update('policyConfig', current => current?.case({
      ManageMembers: ({ ...currentValues }) => PolicyConfig.ManageMembers({ ...currentValues, query }),
      ManageResources: () => current,
      Duplicate: () => current,
      Edit: () => current,
      Create: () => current,
      Delete: () => current,
    }));
  },
  'Datasets.startEditingPolicyResourceship': (state, { datasetId, datasetName }) => {
    return state.set('datasetDraftPolicyResourceship', new DatasetPolicyResourceship({ datasetId, datasetName }));
  },
  'Datasets.stopEditingPolicyResourceship': (state) => {
    return state.delete('datasetDraftPolicyResourceship');
  },
  'Datasets.addToPolicyResourceship': (state, { policyId }) => {
    const { policyDocs, datasetDraftPolicyResourceship } = state;
    if (!datasetDraftPolicyResourceship) return state; // attempting to update from an invalid state
    return state.updateIn(['datasetDraftPolicyResourceship', 'draftPoliciesById'], currentDraft => {
      const currentPolicy = currentDraft.has(policyId)
        ? currentDraft.get(policyId)
        : policyDocs.find(doc => doc.id.id === policyId)?.data;
      if (!currentPolicy) return currentDraft; // current policy for policy id not found
      return currentDraft.set(policyId, currentPolicy.addDatasetToPolicy({ datasetId: datasetDraftPolicyResourceship.datasetId }));
    });
  },
  'Datasets.removeFromPolicyResourceship': (state, { policyId }) => {
    const { policyDocs, datasetDraftPolicyResourceship } = state;
    if (!datasetDraftPolicyResourceship) return state; // attempt to update from an invalid state
    return state.updateIn(['datasetDraftPolicyResourceship', 'draftPoliciesById'], currentDraft => {
      const currentPolicy = currentDraft.has(policyId)
        ? currentDraft.get(policyId)
        : policyDocs.find(doc => doc.id.id === policyId)?.data;
      if (!currentPolicy) return currentDraft; // current policy for policy id not found
      return currentDraft.set(policyId, currentPolicy.removeDatasetFromPolicy({ datasetId: datasetDraftPolicyResourceship.datasetId }));
    });
  },
  'Datasets.updatePolicyResourceshipCompleted': (state) => {
    return reloadPolicies(state).delete('datasetDraftPolicyResourceship');
  },
  'Datasets.updatePolicyResourceshipFailed': (state) => {
    return state.delete('datasetDraftPolicyResourceship');
  },
  'Users.startEditingPolicyMembership': (state, { user }) => {
    return state.set('userDraftPolicyMembership', new UserPolicyMembership({ user }));
  },
  'Users.addToPolicyMembership': (state, { policyId, role }) => {
    const { userDraftPolicyMembership } = state;
    // ERROR: attempting to update without being in draft state, do nothing
    if (!userDraftPolicyMembership) return state;
    return state.update('userDraftPolicyMembership', currentDraft => {
      const updatedPolicy = currentDraft && (currentDraft.getPolicy({ policyId }) || getPolicyById(state)(policyId))?.addUserToPolicy({ username: currentDraft.user.username, role });
      return updatedPolicy ? currentDraft?.updatePolicy({
        policyId,
        updatedPolicy,
      }) : currentDraft;
    });
  },
  'Users.removeFromPolicyMembership': (state, { policyId }) => {
    const { userDraftPolicyMembership } = state;
    // ERROR: attempting to update without being in draft state, do nothing
    if (!userDraftPolicyMembership) return state;
    return state.update('userDraftPolicyMembership', currentDraft => {
      const updatedPolicy = currentDraft && (currentDraft.getPolicy({ policyId }) || getPolicyById(state)(policyId))?.removeUserFromPolicy({ username: currentDraft.user.username });
      return updatedPolicy ? currentDraft?.updatePolicy({
        policyId,
        updatedPolicy,
      }) : currentDraft;
    });
  },
  'Users.stopEditingPolicyMembership': (state) => {
    return state.delete('userDraftPolicyMembership');
  },
  'Users.updatePolicyMembershipCompleted': (state) => {
    return reloadPolicies(state).delete('userDraftPolicyMembership');
  },
  'Users.updatePolicyMembershipFailed': (state) => {
    return state.delete('userDraftPolicyMembership');
  },
  'Groups.startEditingPolicyMembership': (state, { group }) => {
    return state.set('userGroupDraftPolicyMembership', new UserGroupPolicyMembership({ group }));
  },
  'Groups.addToPolicyMembership': (state, { policyId, role }) => {
    const { userGroupDraftPolicyMembership } = state;
    // ERROR: attempting to update without being in draft state, do nothing
    if (!userGroupDraftPolicyMembership) return state;
    return state.update('userGroupDraftPolicyMembership', currentDraft => {
      const updatedPolicy = currentDraft && (currentDraft.getPolicy({ policyId }) || getPolicyById(state)(policyId))?.addUserGroupToPolicy({ groupname: currentDraft.group.groupname, role });
      return updatedPolicy ? currentDraft?.updatePolicy({
        policyId,
        updatedPolicy,
      }) : currentDraft;
    });
  },
  'Groups.removeFromPolicyMembership': (state, { policyId }) => {
    const { userGroupDraftPolicyMembership } = state;
    // ERROR: attempting to update without being in draft state, do nothing
    if (!userGroupDraftPolicyMembership) return state;
    return state.update('userGroupDraftPolicyMembership', currentDraft => {
      const updatedPolicy = currentDraft && (currentDraft.getPolicy({ policyId }) || getPolicyById(state)(policyId))?.removeUserGroupFromPolicy({ groupname: currentDraft.group.groupname });
      return updatedPolicy ? currentDraft?.updatePolicy({
        policyId,
        updatedPolicy,
      }) : currentDraft;
    });
  },
  'Groups.stopEditingPolicyMembership': (state) => {
    return state.delete('userGroupDraftPolicyMembership');
  },
  'Groups.updatePolicyMembershipCompleted': (state) => {
    return reloadPolicies(state).delete('userGroupDraftPolicyMembership');
  },
  'Groups.updatePolicyMembershipFailed': (state) => {
    return state.delete('userGroupDraftPolicyMembership');
  },
  'Location.change': (state) => {
    return state
      .delete('userDraftPolicyMembership')
      .delete('userGroupDraftPolicyMembership')
      .delete('datasetDraftPolicyResourceship');
  },

  'Projects.startEditingPolicies': (state, { projectId, projectName }) => {
    const { policyDocs } = state;
    return state.set('projectDraftPolicyManager', new ProjectPolicyManager({
      projectId,
      projectName,
      draftPolicyResourceship: policyDocs
        .filter(doc => doc.data.hasProjectAsResource({ projectId }))
        .map(doc => doc.id.id).toSet(),
      draftPolicyMembership: policyDocs
        .filter(doc => doc.data.hasProjectAsMember({ projectId }))
        .map(doc => doc.id.id).toSet(),
    }));
  },
  'Projects.stopEditingPolicies': (state) => {
    return state.delete('projectDraftPolicyManager');
  },
  'Projects.addToPolicyResourceship': (state, { policyId }) => {
    const { projectDraftPolicyManager } = state;
    if (!projectDraftPolicyManager) return state;
    return state.updateIn(['projectDraftPolicyManager', 'draftPolicyResourceship'], currentDraft => {
      return currentDraft.add(policyId);
    });
  },
  'Projects.addToPolicyMembership': (state, { policyId }) => {
    const { projectDraftPolicyManager } = state;
    if (!projectDraftPolicyManager) return state;
    projectDraftPolicyManager.draftPolicyMembership.add(policyId);
    return state.updateIn(['projectDraftPolicyManager', 'draftPolicyMembership'], currentDraft => {
      return currentDraft.add(policyId);
    });
  },
  'Projects.removeFromPolicyResourceship': (state, { policyId }) => {
    const { projectDraftPolicyManager } = state;
    if (!projectDraftPolicyManager) return state;
    return state.updateIn(['projectDraftPolicyManager', 'draftPolicyResourceship'], currentDraft => {
      return currentDraft.remove(policyId);
    });
  },
  'Projects.removeFromPolicyMembership': (state, { policyId }) => {
    const { projectDraftPolicyManager } = state;
    if (!projectDraftPolicyManager) return state;
    return state.updateIn(['projectDraftPolicyManager', 'draftPolicyMembership'], currentDraft => {
      return currentDraft.remove(policyId);
    });
  },
  'Projects.updatePoliciesCompleted': (state) => {
    return reloadPolicies(state).delete('projectDraftPolicyManager');
  },
  'Projects.updatePoliciesFailed': (state) => {
    return state.delete('projectDraftPolicyManager');
  },
};
