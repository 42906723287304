import classNames from 'classnames';
import { List, Map, Set } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import LoadingPanel from '../components/LoadingPanel';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import { SCHEMA_MAPPING } from '../constants/RecipeType';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import DocumentId from '../models/doc/DocumentId';
import ProjectWithStatus from '../models/ProjectWithStatus';
import { getProjectGeneratedIdsByProjectId, getUnifiedDatasetIdsByProjectId } from '../projects/ProjectsStore';
import { RECIPE_INPUTS_KEY } from '../projects/RecipeConstants';
import { AppState } from '../stores/MainStore';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import PRODUCT_NAME from '../utils/ProductName';
import style from './ConfirmDeleteDatasetDialog.module.scss';
// @ts-expect-error this can be fixed when converting DatasetCatalogApi to TS
import { deleteDatasets } from './DatasetCatalogApi';

export const TITLE_PLURAL = 'Delete Datasets?';
export const TITLE_SINGLE = 'Delete Dataset?';

function someDatasetNotDeletableSummary(dsCount : number): string {
  return `${dsCount} of your selected datasets cannot be deleted until you take further action.`;
}

function someDatasetDeletableSummary(dsCount : number): string {
  return `${dsCount} of your selected datasets can be deleted right now.`;
}

const ALL_DATASET_WARNING_MESSAGE =
  'None of your selected datasets can be deleted until you take further action.';
const DELETE_SOME_DATASETS_MESSAGE =
  'The following datasets can be deleted at this time. Are you sure you want to delete them now? ' +
  'You cannot undo this action.';
const DELETE_ALL_DATASETS_MESSAGE =
  'Are you sure you want to delete the following datasets? You cannot undo this action.';
const DATASET_HAS_DEPENDENCIES_MESSAGE =
  'You cannot delete the following datasets until you remove them from their input projects, ' +
  'update the Unified Dataset of those projects, and delete their derived datasets:';
const DATASET_AUTOGENERATED_MESSAGE =
  'You cannot delete datasets that were autogenerated from existing projects:';
const DATASET_NOT_DELETABLE =
  'You cannot delete the following datasets. Contact Tamr support for help deleting the datasets:';
const DERIVED_DATASET_TOOLTIP =
  `Derived datasets are generated by ${PRODUCT_NAME} and may include datasets to be used internally`;
const STALE_UNIFIED_DATASET_TOOLTIP =
  'This dataset has been removed from its project. You must update the project’s unified ' +
  'dataset to put this removal into effect.';

// this is used to indicate that a dataset's upstream datasets have a cyclic dependency
export const CYCLIC_DEPENDENCY_ID = -1;

function computeUpstreamDatasetIdChain(
  datasetsById: Map<number, Document<Dataset>>,
  dataset: Document<Dataset>,
): Set<number> {
  checkArg(
    { datasetsById },
    ArgTypes.Immutable.map.of(Document.argTypeWithNestedClass(Dataset), ArgTypes.number),
  );
  checkArg({ dataset }, Document.argTypeWithNestedClass(Dataset));
  let upstreamDatasetIds = Set();
  let stackToCheck = dataset.data.upstreamDatasets.map((docId: DocumentId) => docId.id).toList();
  while (!stackToCheck.isEmpty()) {
    // pop the last item to perform DFS
    const currId: number = stackToCheck.last();
    stackToCheck = stackToCheck.pop();

    const currDataset = datasetsById.get(currId);
    if (!currDataset) {
      continue;
    }

    if (upstreamDatasetIds.contains(currId)) {
      // this is a cycle, so exit early
      return Set.of(CYCLIC_DEPENDENCY_ID);
    }

    upstreamDatasetIds = upstreamDatasetIds.add(currId);
    stackToCheck =
      stackToCheck.concat(currDataset.data.upstreamDatasets.map((docId: DocumentId) => docId.id));
  }
  return upstreamDatasetIds;
}

// for a given list of datasets, construct a map from each dataset's name to a map of its
// descendant dataset IDs to their upstream dataset IDs.
function getDescendantIdsToUpstreamIdChains(
  datasets: Set<Document<Dataset>>,
  descendants: Map<string, List<Document<Dataset>>>,
  projectDatasets: List<string>,
): Map<string, Map<number, Set<number>>> {
  checkArg({ datasets }, ArgTypes.Immutable.set.of(Document.argTypeWithNestedClass(Dataset)));
  checkArg(
    { descendants },
    ArgTypes.Immutable.map.of(
      ArgTypes.Immutable.list.of(Document.argTypeWithNestedClass(Dataset)),
      ArgTypes.string,
    ),
  );
  checkArg({ projectDatasets }, ArgTypes.Immutable.list.of(ArgTypes.string));

  return Map(datasets.map((ds: Document<Dataset>) => {
    if (projectDatasets.includes(ds.data.name)) {
      return [ds.data.name, Map<number, Set<number>>()];
    }

    const descendantsForDataset = descendants.get(ds.data.name, List<Document<Dataset>>());

    const descendantsById = Map(descendantsForDataset.map((d: Document<Dataset>) => [d.id.id, d]));
    const descendantIdsToUpstreamIdChains = Map(
      descendantsForDataset.map((d: Document<Dataset>) =>
        [d.id.id, computeUpstreamDatasetIdChain(descendantsById, d)],
      ));
    return [ds.data.name, descendantIdsToUpstreamIdChains];
  }));
}

/**
 * Find all the non-project derived datasets associated with any of the given datasets. This
 * would include group by, samples, etc. The "Project Datasets" are passed in avoid evaluating
 * any dataset that is generated by a project.
 * @param datasets A list of datasets to evaluate.
 * @param descendants A map of datasets to their descendants.
 * @param datasetIdsToUpstreamIds A map of datasets' IDs to their upstream datasets' IDs
 * @param withCycles A map of dataset names that have cyclic upstream dependency
 * @param projectDatasets A list of datasets generated by projects: these are ignored.
 * @param unifiedDatasetAssociations A map associating each dataset with a list of unified datasets
 *                                   it is connected to.
 * @returns A map where the key is the name of a dataset and the value is a list of non project
 *          datasets associated with it.
 */
function findDerivedDatasets(
  datasets: Set<Document<Dataset>>,
  descendants: Map<string, List<Document<Dataset>>>,
  datasetIdsToUpstreamIds: Map<string, Map<number, Set<number>>>,
  withCycles: Set<string>,
  projectDatasets: List<string>,
  unifiedDatasetAssociations: Map<string, Map<number, Document<Dataset>>>,
): Map<string, List<Document<Dataset>>> {
  checkArg({ datasets }, ArgTypes.Immutable.set.of(Document.argTypeWithNestedClass(Dataset)));
  checkArg({ descendants }, ArgTypes.Immutable.map.of(ArgTypes.Immutable.list.of(Document.argTypeWithNestedClass(Dataset)), ArgTypes.string));
  checkArg({ projectDatasets }, ArgTypes.Immutable.list.of(ArgTypes.string));
  checkArg({ unifiedDatasetAssociations }, ArgTypes.Immutable.map.of(ArgTypes.Immutable.map.of(Document.argTypeWithNestedClass(Dataset), ArgTypes.number), ArgTypes.string));

  return Map(datasets.map(ds => {
    if (projectDatasets.includes(ds.data.name) || withCycles.has(ds.data.name)) {
      return [ds.data.name, List()];
    }
    const descendantsById = Map(descendants.get(ds.data.name, List<Document<Dataset>>()).map(d => [d.id.id, d]));
    const upstreamIdChains = datasetIdsToUpstreamIds.get(ds.data.name, Map<number, Set<number>>());
    const nonProjectDescendants = upstreamIdChains.filter((idChain, id) => {
      const thisDescendantIsAUnifiedDataset =
        unifiedDatasetAssociations.get(ds.data.name)?.some(ud => ud.id.id === id);
      const upstreamIdsIncludeUnifiedDataset =
        unifiedDatasetAssociations.get(ds.data.name)?.some((d) => idChain.has(d.id.id));
      return !thisDescendantIsAUnifiedDataset && !upstreamIdsIncludeUnifiedDataset;
    }).map((idChain, id) => descendantsById.get(id)).toList();
    return [ds.data.name, nonProjectDescendants];
  }));
}

/**
 * Given a set of datasets, their descendants, and a map of unified datasets keyed by their
 * project IDs, determine which datasets are related to existing unified datasets. The result is a
 * map where the key is the dataset name, and the value is a list of all the unified datasets
 * associated with the dataset.
 * @param datasets The datasets to check for relations to unified datasets.
 * @param datasetDescendants A map of all the dataset descendants, keyed by the dataset.
 * @param unifiedDatasets A map of all the unified datasets, keyed by their associated project ID.
 * @returns A map where the keys are the dataset names and the values are a list of associated
 *          unified datasets.
 */
function findUnifiedDatasetAssociations(
  datasets: Set<Document<Dataset>>,
  datasetDescendants: Map<string, List<Document<Dataset>>>,
  unifiedDatasets: Map<number, Document<Dataset>>,
): Map<string, Map<number, Document<Dataset>>> {
  checkArg({ datasets }, ArgTypes.Immutable.set.of(Document.argTypeWithNestedClass(Dataset)));
  checkArg({ datasetDescendants }, ArgTypes.Immutable.map.of(ArgTypes.Immutable.list.of(Document.argTypeWithNestedClass(Dataset)), ArgTypes.string));
  checkArg({ unifiedDatasets }, ArgTypes.Immutable.map.of(Document.argTypeWithNestedClass(Dataset), ArgTypes.number));

  return Map(datasets.map(ds => {
    const directDescendants = datasetDescendants.get(ds.data.name, List<Document<Dataset>>())
      .filter(d => d.data.upstreamDatasets.find(ud => ud.id === ds.id.id));
    const directDescendantIds = directDescendants.map(d => d.id.id).toSet();
    return [
      ds.data.name,
      unifiedDatasets.filter(ud => ud !== undefined && directDescendantIds.has(ud.id.id)),
    ];
  }));
}

/**
 * Determines if the datasets given are members of a project.
 * @param datasets The datasets to check.
 * @param projectsWithStatus A list of projects with status. Evaluates the internal recipes for
 *                           dataset association.
 * @returns A map of dataset name to a list of projects the dataset is associated with.
 */
function findProjectMembers(
  datasets: Set<Document<Dataset>>,
  projectsWithStatus: List<ProjectWithStatus>,
): Map<string, List<ProjectWithStatus>> {
  checkArg({ datasets }, ArgTypes.Immutable.set.of(Document.argTypeWithNestedClass(Dataset)));
  checkArg({ projectsWithStatus }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(ProjectWithStatus)));

  return Map(datasets.map(ds => {
    const recipeInputs = Set(ds.data.metadata.get(RECIPE_INPUTS_KEY));
    const isProjectMember = projectsWithStatus.filter(pws => {
      const schemaMappingRWS = pws.recipes.find(rws => rws.recipe.data.type === SCHEMA_MAPPING);
      return schemaMappingRWS && recipeInputs.has(schemaMappingRWS.recipe.id.id);
    });
    return [ds.data.name, isProjectMember];
  }));
}

/**
 * In some cases, a dataset may have been removed from a project, but is still associated because
 * the project's unified dataset has not been updated, and still relies on the dataset in it's
 * pipeline. Using a list of unified dataset associations, current projects, and existing
 * project members, we can determine stale project membership.
 * @param datasets A list of datasets to evaluate.
 * @param unifiedDatasetAssociations Known unified dataset associations.
 * @param projectsWithStatus A list of projects with status.
 * @param projectMembers The known members of a project.
 * @returns A map of dataset name to list of projects with stale project membership via unified
 *          datasets.
 */
function findProjectMembersViaUnifiedDataset(
  datasets: Set<Document<Dataset>>,
  unifiedDatasetAssociations: Map<string, Map<number, Document<Dataset>>>,
  projectsWithStatus: List<ProjectWithStatus>,
  projectMembers: Map<string, List<ProjectWithStatus>>,
): Map<string, List<ProjectWithStatus>> {
  checkArg({ datasets }, ArgTypes.Immutable.set.of(Document.argTypeWithNestedClass(Dataset)));
  checkArg({ unifiedDatasetAssociations }, ArgTypes.Immutable.map.of(ArgTypes.Immutable.map.of(Document.argTypeWithNestedClass(Dataset), ArgTypes.number), ArgTypes.string));
  checkArg({ projectsWithStatus }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(ProjectWithStatus)));
  checkArg({ projectMembers }, ArgTypes.Immutable.map.of(ArgTypes.Immutable.list.of(ArgTypes.instanceOf(ProjectWithStatus)), ArgTypes.string));

  const projectIdsToProjectStatus = Map(projectsWithStatus.map(pws => [pws.project.id.id, pws]));

  return Map(datasets.map(ds => {
    return [
      ds.data.name,
      unifiedDatasetAssociations
        .get(ds.data.name, Map<number, Document<Dataset>>())
        .filter((ud, pId) =>
          !projectMembers.get(ds.data.name, List<ProjectWithStatus>())
            .some(pws => pws.project.id.id === pId),
        ).keySeq()
        .reduce((result: List<ProjectWithStatus>, pId) => {
          if (projectIdsToProjectStatus.has(pId)) {
            // @ts-expect-error may need to create a default constructor for ProjectWithStatus?
            result.push(projectIdsToProjectStatus.get(pId));
          }
          return result;
        }, List()),
    ];
  }));
}

/**
 * Find all the datasets that are generated by a project, using the list of datasets marked for
 * deletion, along with the IDs of all known unified datasets and project generated IDs.
 * @param datasets The datasets to be deleted.
 * @param unifiedDatasetIds The IDs of the unified datasets for a set of projects.
 * @param projectGeneratedIds All the IDs of datasets generated by projects.
 * @returns A list of dataset names that are generated by projects (including unified datasets)
 */
function findProjectDatasets(
  datasets: Set<Document<Dataset>>,
  unifiedDatasetIds: Map<number, number>,
  projectGeneratedIds: Map<number, Set<number>>,
): List<string> {
  checkArg({ datasets }, ArgTypes.Immutable.set.of(Document.argTypeWithNestedClass(Dataset)));
  checkArg({ unifiedDatasetIds }, ArgTypes.Immutable.map.of(ArgTypes.number, ArgTypes.number));
  checkArg({ projectGeneratedIds }, ArgTypes.Immutable.map.of(ArgTypes.Immutable.set.of(ArgTypes.number), ArgTypes.number));

  return datasets.reduce((result: List<string>, ds) => {
    const datasetId = ds.id.id;
    if (unifiedDatasetIds.some(id => id === datasetId) ||
      projectGeneratedIds.some(ids => ids.includes(datasetId))) {
      result.push(ds.data.name);
    }
    return result;
  }, List());
}

function isDatasetDeletable(
  dsName: string,
  memberOfProjects: Map<string, List<ProjectWithStatus>>,
  memberOfProjectsDueToUnifiedDataset: Map<string, List<ProjectWithStatus>>,
  datasetNameToNonProjectDescendants: Map<string, List<Document<Dataset>>>,
  projectDatasets: List<string>,
  withCycles: Set<string>,
): boolean {
  return memberOfProjects.get(dsName, List<ProjectWithStatus>()).isEmpty() &&
    memberOfProjectsDueToUnifiedDataset.get(dsName, List<ProjectWithStatus>()).isEmpty() &&
    datasetNameToNonProjectDescendants.get(dsName, List<Document<Dataset>>()).isEmpty() &&
    !projectDatasets.some(name => name === dsName) &&
    !withCycles.has(dsName);
}

const ConfirmDeleteDatasetDialog = connect((state: AppState) => {
  const {
    datasetCatalog,
    unifiedDatasets: { docsByProjectId: unifiedDatasetDocsByProjectId },
    projects: { projectsWithStatus },
  } = state;
  const {
    deleting,
    confirmingDeleteForDatasetNames,
    descendants,
    loadingDescendants,
    datasets,
  } = datasetCatalog;

  const confirmingDeleteForDatasets =
    confirmingDeleteForDatasetNames.map(
      (dsName: string) => datasets.find((doc: Document<Dataset>) => doc.data.name === dsName),
    );

  const projectDatasets = findProjectDatasets(
    confirmingDeleteForDatasets,
    getUnifiedDatasetIdsByProjectId(state.projects),
    getProjectGeneratedIdsByProjectId(state.projects),
  );

  return {
    confirmingDeleteForDatasets,
    deleting,
    descendants,
    loadingDescendants,
    projectDatasets,
    projectsWithStatus,
    unifiedDatasetDocsByProjectId,
  };
}, {
  onDeleteDatasets: deleteDatasets,
  onHide: () => ({ type: 'DatasetCatalog.cancelConfirmingDelete' }),
})(({ deleting, confirmingDeleteForDatasets, descendants, loadingDescendants, onDeleteDatasets, onHide, projectDatasets, unifiedDatasetDocsByProjectId, projectsWithStatus }) => {
  const show = !confirmingDeleteForDatasets.isEmpty();

  const datasetNameToDescendantUnifiedDatasets = findUnifiedDatasetAssociations(
    confirmingDeleteForDatasets,
    descendants,
    unifiedDatasetDocsByProjectId,
  );

  // find descendant ID map to upstream ID for each dataset
  const descendantIdsToUpstreamIdChains =
    getDescendantIdsToUpstreamIdChains(confirmingDeleteForDatasets, descendants, projectDatasets);

  const withCycles = descendantIdsToUpstreamIdChains.filter(
    (value) => List(value.values()).contains(Set.of(CYCLIC_DEPENDENCY_ID)),
  ).keySeq().toSet();

  const datasetNameToNonProjectDescendants = findDerivedDatasets(
    confirmingDeleteForDatasets,
    descendants,
    descendantIdsToUpstreamIdChains,
    withCycles,
    projectDatasets,
    datasetNameToDescendantUnifiedDatasets,
  );

  const memberOfProjects = findProjectMembers(confirmingDeleteForDatasets, projectsWithStatus);

  const memberOfProjectsDueToUnifiedDataset = findProjectMembersViaUnifiedDataset(
    confirmingDeleteForDatasets,
    datasetNameToDescendantUnifiedDatasets,
    projectsWithStatus,
    memberOfProjects,
  );

  /*
   * To generate the UI, we want to know if any of the datasets marked for deletion are project
   * members, associated via unified datasets, or have derived datasets.  Collecting all the
   * values from each map of dataset-to-project, dataset-to-derived, etc, filters a list of
   * datasets that can be deleted, and also which sections of the dialog need to be presented
   * for errors.
   */
  const canBeDeleted = confirmingDeleteForDatasets.map((ds :Document<Dataset>) => ds.data.name)
    .filter((dsName : string) => isDatasetDeletable(
      dsName,
      memberOfProjects,
      memberOfProjectsDueToUnifiedDataset,
      datasetNameToNonProjectDescendants,
      projectDatasets,
      withCycles,
    ));

  const datasetsWithNonCyclicDependencies =
    confirmingDeleteForDatasets.filter((ds : Document<Dataset>) =>
      !(memberOfProjects.get(ds.data.name, List()).isEmpty() &&
        datasetNameToNonProjectDescendants.get(ds.data.name, List()).isEmpty() &&
        memberOfProjectsDueToUnifiedDataset.get(ds.data.name, List()).isEmpty()) &&
      !withCycles.has(ds.data.name),
    );

  const icon = <TamrIcon className={style.icon} iconName="warning" size={16} />;
  return (
    <Dialog
      dialogStyle={DialogStyle.PRIMARY}
      show={show}
      onHide={onHide}
      title={show ? confirmingDeleteForDatasets.size === 1 ? TITLE_SINGLE : TITLE_PLURAL : ''}
      body={show ? (
        <div className={style.body}>
          {loadingDescendants || deleting ? (
            <LoadingPanel className={style.loading} message={loadingDescendants ? 'Checking for dependent datasets...' : 'Deleting Datasets'} />
          ) : (
            <div>
              {canBeDeleted.size === confirmingDeleteForDatasets.size
                ? null
                : canBeDeleted.isEmpty()
                  ? (<div>{ALL_DATASET_WARNING_MESSAGE}</div>)
                  : (<React.Fragment>
                    <div>{someDatasetDeletableSummary(canBeDeleted.size)}</div>
                    <div>{someDatasetNotDeletableSummary(confirmingDeleteForDatasets.size - canBeDeleted.size)}</div>
                  </React.Fragment>)
              }
              {!withCycles.isEmpty() ? (
                <div className={style.canNotDeleteListContainer}>
                  <div className={style.containerText}>{icon} {DATASET_NOT_DELETABLE}</div>
                  <ul className={style.dependencies}>
                    {confirmingDeleteForDatasets.filter((ds : Document<Dataset>) => withCycles.has(ds.data.name))
                      .map((ds : Document<Dataset>) => (<li key={ds.data.name} className={style.datasetName}>{ds.data.name}</li>))
                    }
                  </ul>
                </div>
              ) : null}
              {!datasetsWithNonCyclicDependencies.isEmpty() ? (
                <div className={style.canNotDeleteListContainer}>
                  <div className={style.containerText}>{icon} {DATASET_HAS_DEPENDENCIES_MESSAGE}</div>
                  <ul className={style.dependencies}>
                    {datasetsWithNonCyclicDependencies.map(
                      (ds: Document<Dataset>) => (
                        <li key={ds.data.name}>
                          <span className={style.datasetName}>{ds.data.name}</span>
                          <ul>
                            {/* the dataset is either a project's UD or tied with a project's UD */}
                            {!memberOfProjects.get(ds.data.name, List()).isEmpty() || !memberOfProjectsDueToUnifiedDataset.get(ds.data.name, List()).isEmpty() ?
                              (
                                <li key={ds.data.name} className={style.dependencyType}>
                                  <span>Input To: </span>
                                  <span className={style.list}>
                                    {memberOfProjects.get(ds.data.name)?.map(project => project.project.data.name).join(', ')}
                                    {memberOfProjectsDueToUnifiedDataset.get(ds.data.name)?.map(project => (
                                      <span key={ds.data.name + '_ud_conflict'}>
                                        ,
                                        <TooltipTrigger placement="top" content={STALE_UNIFIED_DATASET_TOOLTIP}>
                                          <span className={classNames(style.unifiedDatasetText, style.datasetName)}>{project.project.data.name}</span>
                                        </TooltipTrigger>
                                      </span>
                                    ))}
                                  </span>
                                </li>
                              )
                              : null}
                            {/* the dataset has derived datasets that need to be removed */}
                            {!datasetNameToNonProjectDescendants.get(ds.data.name, List()).isEmpty() ?
                              (
                                <li key={ds.data.name} className={style.dependencyType}>
                                  Derived Datasets
                                  <TooltipTrigger placement="top" content={DERIVED_DATASET_TOOLTIP}>
                                    <TamrIcon className={style.info} iconName="info-outline" size={14} />
                                  </TooltipTrigger>
                                  :
                                  <ul className={style.derivedDatasetsList}>
                                    {datasetNameToNonProjectDescendants
                                      .get(ds.data.name, List<Document<Dataset>>())
                                      .map(d => (
                                        <li key={d.data.name}>
                                          <span className={classNames(style.dependencyListItem, style.datasetName)}>
                                            {d.data.name}
                                          </span>
                                        </li>
                                      ))
                                    }
                                  </ul>
                                </li>
                              )
                              : null}
                          </ul>
                        </li>
                      ),
                    )}
                  </ul>
                </div>
              ) : null}
              {!projectDatasets.isEmpty() ? (
                <div className={style.canNotDeleteListContainer}>
                  <div className={style.containerText}>{icon} {DATASET_AUTOGENERATED_MESSAGE}</div>
                  <ul className={style.dependencies}>
                    {projectDatasets.map(dsName => (<li key={dsName} className={style.datasetFile}>{dsName}</li>))}
                  </ul>
                </div>
              ) : null}
              {!canBeDeleted.isEmpty() ? (
                <div className={style.canBeDeletedContainer}>
                  <div>{canBeDeleted.size === confirmingDeleteForDatasets.size ? DELETE_ALL_DATASETS_MESSAGE : DELETE_SOME_DATASETS_MESSAGE}</div>
                  <ul className={style.canBeDeletedList}>
                    {canBeDeleted.map((dsName : string) => (<li key={dsName} className={style.datasetFile}>{dsName}</li>))}
                  </ul>
                </div>
              ) : null}
            </div>
          )}
        </div>
      ) : null}
      footer={show ? (
        <ButtonToolbar>
          <Button
            onClick={onHide}
            buttonType="Secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => onDeleteDatasets(canBeDeleted)}
            disabled={loadingDescendants || canBeDeleted.isEmpty()}
          >
            Delete
          </Button>
        </ButtonToolbar>
      ) : null}
    />
  );
});

export default ConfirmDeleteDatasetDialog;
