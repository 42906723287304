export interface DatasetRefinement {
  page: number
  pageSize: number
  search: string
  showSource: boolean
  showDerived: boolean
  numDatasets: number
  numPages: number
}

export const defaults: DatasetRefinement = {
  page: 0,
  pageSize: 100,
  search: '',
  showSource: true,
  showDerived: true,
  numDatasets: 0,
  numPages: 1,
} as const;
