import { fetchTileServers } from '../projects/ProjectsAsync';
import createLoader from '../utils/createLoader';

/*
 * Loads all tile servers exactly once (until a browser refresh happens).
 */
const TileServersLoader = createLoader((state) => {
  const { projects: { loadedTileServers }, loading } = state;
  return {
    canFetch: true, // no preconditions
    shouldFetch: !loadedTileServers,
    loading: !!loading,
  };
}, {
  onFetch: fetchTileServers,
}, 'TileServersLoader');

export default TileServersLoader;
