import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import CenterContent from '../components/CenterContent';
import Checkbox from '../components/Input/Checkbox';
import AttributeId from '../models/AttributeId';
import UnifiedAttribute from '../models/UnifiedAttribute';
import CreateUADialog from '../records/CreateUADialog';
import { UAListHeaderRow } from './ListHeaderRow';
import {
  CLEAR_FILTER_RELATED_ID,
  CONFIRM_BULK_DELETE_ATTRIBUTES,
  UNIFIED_SEARCH,
  UNIFIED_TOGGLE_FILTER_MAPPED,
  UNIFIED_TOGGLE_FILTER_UNMAPPED,
} from './SchemaMappingActionTypes';
import { SchemaMappingUADatasetFilter } from './SchemaMappingDatasetFilter';
import SchemaMappingRefinement from './SchemaMappingRefinement';
import { filteredUnifiedAttributes, unifiedAttributeFilterOn } from './SchemaMappingStore';


const UnifiedListStats = _.compose(
  connect((state) => {
    const { schemaMapping } = state;
    const { unifiedFilterMapped, unifiedFilterUnmapped, unifiedAttributes, unifiedSelectedIds, sourceFilterRelatedId, unifiedSearchTerm } = schemaMapping;
    return {
      unifiedSearchTerm,
      unifiedFilterMapped,
      unifiedFilterUnmapped,
      unifiedAttributes,
      isUnifiedAttributeFilterOn: unifiedAttributeFilterOn(state),
      filterRelatedId: sourceFilterRelatedId,
      filteredAttributes: filteredUnifiedAttributes(schemaMapping),
      anySelected: !!unifiedSelectedIds.size,
    };
  }, {
    onClearFilterRelated: () => ({ type: CLEAR_FILTER_RELATED_ID }),
    onConfirmBulkDeleteAttributes: () => ({ type: CONFIRM_BULK_DELETE_ATTRIBUTES }),
    onSearch: text => ({ type: UNIFIED_SEARCH, text }),
    onToggleFilterMapped: () => ({ type: UNIFIED_TOGGLE_FILTER_MAPPED }),
    onToggleFilterUnmapped: () => ({ type: UNIFIED_TOGGLE_FILTER_UNMAPPED }),
  }),
)(class UnifiedListStats extends React.Component {
  static propTypes = {
    anySelected: PropTypes.bool.isRequired,
    canUserEdit: PropTypes.bool.isRequired,
    filterRelatedId: PropTypes.instanceOf(AttributeId),
    filteredAttributes: ImmutablePropTypes.listOf(PropTypes.instanceOf(UnifiedAttribute)),
    isUnifiedAttributeFilterOn: PropTypes.bool.isRequired,
    onClearFilterRelated: PropTypes.func.isRequired,
    onConfirmBulkDeleteAttributes: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onToggleFilterMapped: PropTypes.func.isRequired,
    onToggleFilterUnmapped: PropTypes.func.isRequired,
    unifiedAttributes: ImmutablePropTypes.listOf(PropTypes.instanceOf(UnifiedAttribute)),
    unifiedFilterMapped: PropTypes.bool.isRequired,
    unifiedFilterUnmapped: PropTypes.bool.isRequired,
  };

  getOverallCountSectionMessage = () => {
    const { unifiedAttributes, filterRelatedId, isUnifiedAttributeFilterOn } = this.props;
    const uaResultSize = unifiedAttributes.size;
    const attrType = 'Unified Dataset';
    const attrPlurality = uaResultSize === 1 ? 'attribute' : 'attributes';
    // actually no UAs in system
    if (uaResultSize === 0 && !isUnifiedAttributeFilterOn) {
      return `No ${attrType} ${attrPlurality}`;
    }
    // special case: filtered to related attribute
    if (filterRelatedId) {
      return (
        <span>
          Filtered to <span className="related-attribute">{filterRelatedId.name}</span> mappings and
          suggestions ({uaResultSize} {attrType} {attrPlurality})
        </span>
      );
    }
    // TODO: update this when client-side filtering is added back
    return `Showing ${isUnifiedAttributeFilterOn ? '' : 'all'} ${uaResultSize} ${attrType} ${attrPlurality}`;
  };

  renderOverallCountSection = () => {
    const message = this.getOverallCountSectionMessage();
    return (
      <CenterContent className="overall-count-section" horizontally={false} title={message}>
        <div className="overall-count">
          {message}
        </div>
      </CenterContent>
    );
  };

  getFilterTitle = (title, count) => {
    const { isUnifiedAttributeFilterOn } = this.props;
    const countMessage = `(${count || 0})`;
    return `${title} ${!isUnifiedAttributeFilterOn ? countMessage : ''}`;
  };

  render() {
    const {
      anySelected,
      canUserEdit,
      onConfirmBulkDeleteAttributes,
      onSearch,
      onToggleFilterMapped,
      onToggleFilterUnmapped,
      unifiedAttributes,
      unifiedFilterMapped,
      unifiedFilterUnmapped,
      unifiedSearchTerm,
    } = this.props;
    const { mapped, unmapped } = unifiedAttributes.countBy(({ mappedAttributes }) => {
      return mappedAttributes.size ? 'mapped' : 'unmapped';
    }).toJS();
    return (
      <div className="schema-mapping-list-stats">
        {this.renderOverallCountSection()}
        <div className="sources-filter">
          <div className="dropdown-toggle-container">
            <SchemaMappingUADatasetFilter />
            {canUserEdit &&
              <CreateUADialog btnText="Create" />}
            {canUserEdit &&
              <Button buttonType="Secondary" onClick={onConfirmBulkDeleteAttributes} disabled={!anySelected}>
                Remove
              </Button>}
          </div>
          <div className="search-attributes">
            <SchemaMappingRefinement value={unifiedSearchTerm} onSearch={onSearch} />
          </div>
        </div>
        <CenterContent className="mappedness-count-section" horizontally={false}>
          <div className="mappedness-counts">
            <Checkbox
              className="mappedness-count"
              title={this.getFilterTitle('Mapped', mapped)}
              titlePosition="right"
              onChange={onToggleFilterMapped}
              value={unifiedFilterMapped}
            />
            <Checkbox
              className="mappedness-count"
              title={this.getFilterTitle('Unmapped', unmapped)}
              titlePosition="right"
              onChange={onToggleFilterUnmapped}
              value={unifiedFilterUnmapped}
            />
          </div>
        </CenterContent>
        <UAListHeaderRow />
      </div>
    );
  }
});

export default UnifiedListStats;
