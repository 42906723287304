// TODO: Move to UI components
import _ from 'underscore';

class Pager {
  constructor(data, total, pageNum, pageSize) {
    this.pageSize = pageSize;
    this.data = data;
    this.dataSize = total;
    this.pageCount = _.isNumber(total) ? Math.ceil(total / pageSize) : undefined;
    this.pageNum = pageNum;
  }
}

export default Pager;
