import classNames from 'classnames';
import { Map } from 'immutable';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { StandardButtonTypePropType } from './Button';
import ConditionalButton from './ConditionalButton';
import DropdownButton, { OptionsPropType } from './DropdownButton';
import style from './MenuButton.module.scss';

const propTypes = {
  buttonOnClick: PropTypes.func.isRequired,
  buttonPreconditions: ImmutablePropTypes.mapOf(PropTypes.bool.isRequired, PropTypes.node.isRequired),
  buttonText: PropTypes.node.isRequired,
  buttonTooltipLabel: PropTypes.node,
  buttonType: StandardButtonTypePropType,
  className: PropTypes.string,
  dropdownPreconditions: ImmutablePropTypes.mapOf(PropTypes.bool.isRequired, PropTypes.node.isRequired),
  options: OptionsPropType.isRequired,
  popoverMenuClassName: PropTypes.string,
};

// composes a main action button with a DropdownButton
class MenuButton extends React.Component<InferProps<typeof propTypes>> {
  static propTypes = propTypes;

  render() {
    const { buttonText, buttonType, buttonTooltipLabel, buttonPreconditions, buttonOnClick, className, dropdownPreconditions, options, popoverMenuClassName } = this.props;
    return (
      <div className={classNames(style.button, className)}>
        <ConditionalButton
          preconditions={buttonPreconditions || Map()}
          tooltipLabel={buttonTooltipLabel}
          className={style.mainButton}
          buttonType={buttonType}
          onClick={buttonOnClick}
        >
          {buttonText}
        </ConditionalButton>
        <DropdownButton
          {...{ buttonType, options, popoverMenuClassName }}
          className={style.dropdownButton}
          preconditions={dropdownPreconditions}
        />
      </div>
    );
  }
}

export default MenuButton;
