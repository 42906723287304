import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

class User extends getModelHelpers({
  username: { type: ArgTypes.string },
  given: { type: ArgTypes.nullable(ArgTypes.string) },
  surname: { type: ArgTypes.nullable(ArgTypes.string) },
  email: { type: ArgTypes.nullable(ArgTypes.string) },
  externallyManaged: { type: ArgTypes.nullable(ArgTypes.bool) },
  deactivated: { type: ArgTypes.nullable(ArgTypes.bool) },
}, 'User')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class UserRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  get name() {
    if (this.given && this.surname) {
      return `${this.given} ${this.surname}`;
    }
    return this.given || this.surname;
  }
  static fromJSON(obj: $TSFixMe) {
    return new User({
      username: obj.username,
      given: obj.given,
      surname: obj.surname,
      email: obj.email,
      externallyManaged: obj.externallyManaged,
      deactivated: obj.deactivated,
    });
  }
}

export default User;
