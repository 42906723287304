import PropTypes from 'prop-types';
import React from 'react';

import Term from '../components/Term';
import RecipeOperations, {
  CATEGORIZATIONS,
  PAIRS,
  PREDICT_CATEGORIZATIONS,
  PREDICT_CLUSTER,
  RECORDS,
  TRAIN_PREDICT_CLUSTER,
} from '../constants/RecipeOperations';

const getNoun = (projectStep, projectId) => {
  switch (projectStep) {
    case RECORDS:
      return 'Unified Dataset';
    case PAIRS:
      return <Term {...{ projectId }}>Pairs</Term>;
    case TRAIN_PREDICT_CLUSTER:
      return <span><Term {...{ projectId }}>Pair</Term> Labels and <Term {...{ projectId }}>Suppliers</Term></span>;
    case CATEGORIZATIONS:
      return 'Categorizations';
    case PREDICT_CLUSTER:
      return <span><Term {...{ projectId }}>Suppliers</Term></span>;
    case PREDICT_CATEGORIZATIONS:
      return <Term {...{ projectId }}>Records</Term>;
  }
};

const ProjectStepName = ({ progressiveTense, projectStep, neverRunBefore, projectId }) => {
  const verb = neverRunBefore
    ? progressiveTense ? 'Generating' : 'Generate'
    : progressiveTense ? 'Updating' : 'Update';
  const noun = getNoun(projectStep, projectId);
  return <span>{verb} {noun}</span>;
};

ProjectStepName.propTypes = {
  neverRunBefore: PropTypes.bool,
  progressiveTense: PropTypes.bool,
  projectId: PropTypes.number,
  projectStep: RecipeOperations.propType.isRequired,
};

export default ProjectStepName;
