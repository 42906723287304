import * as Rule from './Rule';

export const RuleDeltaTypes = {
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  CHANGE: 'CHANGE',
  // TODO don't store "unchanged" rule deltas. only store deltas that represent change
  UNCHANGED: 'UNCHANGED',
} as const;
export type RuleDeltaTypesE = typeof RuleDeltaTypes[keyof typeof RuleDeltaTypes];

export type RuleDelta
  = { type: typeof RuleDeltaTypes.CREATE, rule: Rule.Rule }
  | { type: typeof RuleDeltaTypes.DELETE, rule: Rule.Rule }
  | { type: typeof RuleDeltaTypes.CHANGE, rule: Rule.Rule }
  | { type: typeof RuleDeltaTypes.UNCHANGED, rule: Rule.Rule }

export function unchanged(rule: Rule.Rule): RuleDelta {
  return { type: RuleDeltaTypes.UNCHANGED, rule };
}
