// com.tamr.auth.authz.HierarchicalName

import { List } from 'immutable';

import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

const WILDCARD = '*';

export default class HierarchicalName extends getModelHelpers({
  stepDelimiter: { type: ArgTypes.string },
  parts: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
}, 'HierarchicalName')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class HierarchicalNameRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
    // @ts-expect-error
    // this is here to declare that this toJSON returns a string, so that subtypes can override it and return a string without a TS error
    // the ts-ignore is because this, in fact, conflicts with the Record toJSON method
    toJSON() {
      return '';
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  constructor({ spec, stepDelimiter }: { spec: string, stepDelimiter: string }) {
    checkArg({ spec }, ArgTypes.string);
    super({
      stepDelimiter,
      parts: List(spec ? spec.trim().split(stepDelimiter) : []),
    });
  }

  /**
   * Whether this HierarchicalName specifies the root of a subtree which contains
   * the other subtree.
   */
  matches(other: HierarchicalName) {
    checkArg({ other }, HierarchicalName.argType);

    if (this.equals(other)) return true;

    if (other === null) return false;

    const firstStepNotPermitted = this.parts
      .zip(other.parts)
      .findIndex(steps => !HierarchicalName.stepPermitted(steps[0], steps[1]));

    if (firstStepNotPermitted >= 0) return false;

    if (this.parts.size <= other.parts.size) return true;

    /**
     * This check duplicates the logic in com.tamr.auth.authz.HierarchicalName.
     * We do not expect any cases where there will be wildcards in the name except
     * for if it is the entirety of the name (i.e. the name is exactly *).
     * However, in order to support the legacy Role class, we do not enforce
     * this behavior, and therefore keep this check in the event an instance
     * of this class is created with wildcards within the name.
     */
    const firstNonWildcard = this.parts
      .slice(other.parts.size, this.parts.size)
      .findIndex(s => s !== WILDCARD);

    return firstNonWildcard === -1;
  }

  static stepPermitted(step: string, otherStep: string) {
    checkArg({ step }, ArgTypes.string);
    checkArg({ otherStep }, ArgTypes.string);
    return (step === WILDCARD) || (step === otherStep);
  }
}
