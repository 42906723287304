import { fetchCoreConnectDefaultCloudProvider } from '../coreConnectService/CoreConnectServiceAsync';
import { fetchAllTags } from '../tags/TagApi';
import { loadLicense } from './LicenseAsync';
import { loadVersion } from './VersionApi';
// This is a list of dispatches that should happen exactly once when user logs in
// TODO should load all of these under one redux action.
export default (dispatch) => {
  dispatch(loadVersion());
  dispatch(loadLicense());
  dispatch(fetchAllTags());
  dispatch(fetchCoreConnectDefaultCloudProvider());
};
