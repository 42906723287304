import * as DedupClient from '../api/DedupClient';
import { AppThunkAction } from '../stores/AppAction';
import * as Result from '../utils/Result';
import { getAuthorizedUser, getUnifiedDatasetName } from '../utils/Selectors';


export const fetchClusterFeedback = (): AppThunkAction<void> => (dispatch, getState) => {
  dispatch({ type: 'ClusterFeedback.fetchFeedback' });
  const state = getState();
  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    return Result.constructFailure('Cannot fetch clusters - unified dataset name is undefined');
  }
  const authUser = getAuthorizedUser(state);
  if (!authUser) {
    return Result.constructFailure('Cannot fetch clusters - logged in user is undefined');
  }

  DedupClient.fetchClusters(
    unifiedDatasetName,
    { username: authUser.username },
  ).then(Result.handler(
    page => {
      const clusterAssignments =
        page.items.flatMap(cluster => cluster.clusterAssignments).toArray();

      return dispatch({
        type: 'ClusterFeedback.fetchFeedbackCompleted',
        clusterAssignments,
      });
    },
    () => dispatch({ type: 'ClusterFeedback.fetchFeedbackFailed' }),
  ));
};
