import { ArgTypes, Checker } from '../utils/ArgValidation';
import { checkTypesAndCast } from '../utils/CheckTypesAndCast';
import { $TSFixMe, JsonContent } from '../utils/typescript';

// TODO consolidate with models/EsRecord
export interface EsRecord {
  id: string
  type: string
  source: { [key: string]: JsonContent },
  highlight: { [key: string]: $TSFixMe },
}
export const argTypes = {
  id: ArgTypes.string,
  type: ArgTypes.string,
  source: ArgTypes.object.of(ArgTypes.any as Checker<JsonContent>),
  highlight: ArgTypes.object.of(ArgTypes.any /* TODO tighten typing here */),
} as const;
export const fromJSON = checkTypesAndCast<EsRecord, typeof argTypes>(argTypes);
