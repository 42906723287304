import React from 'react';
import { connect } from 'react-redux';
import { lifecycle } from 'recompose';
import _ from 'underscore';

import PoliciesLoader from '../accessControl/PoliciesLoader';
import DatasetManagementDialog from '../addDataset/DatasetManagementDialog';
import Chrome from '../chrome/Chrome';
import PageHeader from '../chrome/PageHeader';
import SearchBox from '../components/SearchBox';
import ConfirmChangeProjectMembershipsDialog from '../datasets/ConfirmChangeProjectMembershipsDialog';
import ConfirmDeleteDatasetDialog from '../datasets/ConfirmDeleteDatasetDialog';
import ConfirmExportDialog from '../datasets/ConfirmExportDialog';
import ConfirmProfileDialog from '../datasets/ConfirmProfileDialog';
import DatasetCatalogLoader from '../datasets/DatasetCatalogLoader';
import DatasetCatalogTable from '../datasets/DatasetCatalogTable';
import DatasetCatalogTaskbar from '../datasets/DatasetCatalogTaskbar';
import DatasetPoliciesDialog from '../datasets/DatasetPoliciesDialog';
import DatasetPreviewDialog from '../datasets/DatasetPreviewDialog';
import ExportFailureDialog from '../datasets/ExportFailureDialog';
import ExportWithCoreConnectService from '../datasets/ExportWithCoreConnectService';
import ExportWithJDBCDialog from '../datasets/ExportWithJDBCDialog';
import ManageProjectsForDatasetsDialog from '../datasets/ManageProjectsForDatasetsDialog';
import { isAdmin } from '../utils/Authorization';
import { longFormat } from '../utils/Numbers';
import { getAuthorizedUser } from '../utils/Selectors';
import Unauthorized from './Unauthorized';

const DatasetCatalogPage = _.compose(
  connect((state) => {
    const { datasetCatalog: { paging } } = state;
    return {
      paging,
      loggedInUserIsAdmin: isAdmin(getAuthorizedUser(state)),
    };
  }, {
    onReload: () => ({ type: 'DatasetCatalog.reload' }),
    onSetSearchString: (search) => ({ type: 'DatasetCatalog.setSearchString', search }),
  }),
  lifecycle({
    UNSAFE_componentWillMount() {
      const { onReload } = this.props;
      onReload();
    },
  }),
)(({ loggedInUserIsAdmin, paging, onSetSearchString }) => {
  if (!loggedInUserIsAdmin) {
    return <Unauthorized />;
  }
  return (
    <Chrome>
      <PageHeader
        title={<span>Datasets <span className="page-header-metrics">{longFormat(paging.numDatasets)}</span></span>}
        searchbox={<SearchBox value={paging.search} onSearch={onSetSearchString} />}
      />
      <DatasetPoliciesDialog />
      <DatasetCatalogTable />
      <DatasetCatalogTaskbar />
      <DatasetCatalogLoader />
      <ExportFailureDialog />
      <DatasetManagementDialog isCatalogPage />
      <ConfirmDeleteDatasetDialog />
      <ManageProjectsForDatasetsDialog />
      <ConfirmChangeProjectMembershipsDialog />
      <ConfirmExportDialog />
      <ConfirmProfileDialog />
      <DatasetPreviewDialog />
      <PoliciesLoader />
      <ExportWithCoreConnectService />
      <ExportWithJDBCDialog />
    </Chrome>
  );
});

export default DatasetCatalogPage;
