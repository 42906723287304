import { List, Set } from 'immutable';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import _ from 'underscore';

import { DataTablesType } from '../constants/DataTables';
import { ORIGIN_SOURCE_NAME } from '../constants/ElasticConstants';
import RecipeOperations from '../constants/RecipeOperations';
import { selectProfilingSchema } from '../datasets/DatasetUtils';
import DisplayColumn from '../models/DisplayColumn';
import Document from '../models/doc/Document';
import DocumentId from '../models/doc/DocumentId';
import Job from '../models/Job';
import MinimalAuthUser from '../models/MinimalAuthUser';
import ProfiledAttribute from '../models/ProfiledAttribute';
import ProjectInfo from '../models/ProjectInfo';
import ProjectWithStatus from '../models/ProjectWithStatus';
import Recipe, { DEDUP_INFO_METADATA_KEY } from '../models/Recipe';
import RecipeWithStatus from '../models/RecipeWithStatus';
import { AppState } from '../stores/MainStore';
import { ArgTypes, checkArg, Checker, checkReturn } from './ArgValidation';
import { isAdmin, isCuratorByProjectId } from './Authorization';
import { getColumnDefinitions } from './Columns';
import getDocUrl from './getDocUrl';
import { $TSFixMe } from './typescript';
import { getPath } from './Values';


/**
 * Selectors that act on the entire flux state, instead of slices
 */

export const getProjectsWithStatus = (state: AppState) => state.projects?.projectsWithStatus;
export const getDocsByProjectId = (state: AppState) => state.unifiedDatasets?.docsByProjectId;

// NB when I truned on linting for TS files these overloaded methods were flagged by the rule to not
//    allow repeated exports. I am disabling the rule, but we should look for a better solution
// eslint-disable-next-line import/export
export function createTypedSelector<SelectorInput, ReturnValue, Input1>(inputType: Checker<SelectorInput>, outputType: Checker<ReturnValue>, getInput1: (state: SelectorInput) => Input1, computeValue: (input1: Input1) => ReturnValue): (state: SelectorInput) => ReturnValue;
// eslint-disable-next-line import/export
export function createTypedSelector<SelectorInput, ReturnValue, Input1, Input2>(inputType: Checker<SelectorInput>, outputType: Checker<ReturnValue>, getInput1: (state: SelectorInput) => Input1, getInput2: (state: SelectorInput) => Input2, computeValue: (input1: Input1, input2: Input2) => ReturnValue): (state: SelectorInput) => ReturnValue;
// eslint-disable-next-line import/export
export function createTypedSelector<SelectorInput, ReturnValue, Input1, Input2, Input3>(inputType: Checker<SelectorInput>, outputType: Checker<ReturnValue>, getInput1: (state: SelectorInput) => Input1, getInput2: (state: SelectorInput) => Input2, getInput3: (state: SelectorInput) => Input3, computeValue: (input1: Input1, input2: Input2, input3: Input3) => ReturnValue): (state: SelectorInput) => ReturnValue;
// eslint-disable-next-line import/export
export function createTypedSelector<SelectorInput, ReturnValue, Input1, Input2, Input3, Input4>(inputType: Checker<SelectorInput>, outputType: Checker<ReturnValue>, getInput1: (state: SelectorInput) => Input1, getInput2: (state: SelectorInput) => Input2, getInput3: (state: SelectorInput) => Input3, getInput4: (state: SelectorInput) => Input4, computeValue: (input1: Input1, input2: Input2, input3: Input3, input4: Input4) => ReturnValue): (state: SelectorInput) => ReturnValue;
// eslint-disable-next-line import/export
export function createTypedSelector<SelectorInput, ReturnValue, Input1, Input2, Input3, Input4, Input5>(inputType: Checker<SelectorInput>, outputType: Checker<ReturnValue>, getInput1: (state: SelectorInput) => Input1, getInput2: (state: SelectorInput) => Input2, getInput3: (state: SelectorInput) => Input3, getInput4: (state: SelectorInput) => Input4, getInput5: (state: SelectorInput) => Input5, computeValue: (input1: Input1, input2: Input2, input3: Input3, input4: Input4, input5: Input5) => ReturnValue): (state: SelectorInput) => ReturnValue;
// eslint-disable-next-line import/export
export function createTypedSelector<SelectorInput, ReturnValue, Input1, Input2, Input3, Input4, Input5, Input6>(inputType: Checker<SelectorInput>, outputType: Checker<ReturnValue>, getInput1: (state: SelectorInput) => Input1, getInput2: (state: SelectorInput) => Input2, getInput3: (state: SelectorInput) => Input3, getInput4: (state: SelectorInput) => Input4, getInput5: (state: SelectorInput) => Input5, getInput6: (state: SelectorInput) => Input6, computeValue: (input1: Input1, input2: Input2, input3: Input3, input4: Input4, input5: Input5, input6: Input6) => ReturnValue): (state: SelectorInput) => ReturnValue;
// eslint-disable-next-line import/export
export function createTypedSelector<SelectorInput, ReturnValue, Input1, Input2, Input3, Input4, Input5, Input6, Input7>(inputType: Checker<SelectorInput>, outputType: Checker<ReturnValue>, getInput1: (state: SelectorInput) => Input1, getInput2: (state: SelectorInput) => Input2, getInput3: (state: SelectorInput) => Input3, getInput4: (state: SelectorInput) => Input4, getInput5: (state: SelectorInput) => Input5, getInput6: (state: SelectorInput) => Input6, getInput7: (state: SelectorInput) => Input7, computeValue: (input1: Input1, input2: Input2, input3: Input3, input4: Input4, input5: Input5, input6: Input6, input7: Input7) => ReturnValue): (state: SelectorInput) => ReturnValue;
// eslint-disable-next-line import/export
export function createTypedSelector<SelectorInput, ReturnValue, Input1, Input2, Input3, Input4, Input5, Input6, Input7>(
  inputType: Checker<SelectorInput>,
  outputType: Checker<ReturnValue>,
  func1: (state: SelectorInput) => Input1,
  func2: ((state: SelectorInput) => Input2) | ((input1: Input1) => ReturnValue),
  func3?: ((state: SelectorInput) => Input3) | ((input1: Input1, input2: Input2) => ReturnValue),
  func4?: ((state: SelectorInput) => Input4) | ((input1: Input1, input2: Input2, input3: Input3) => ReturnValue),
  func5?: ((state: SelectorInput) => Input5) | ((input1: Input1, input2: Input2, input3: Input3, input4: Input4) => ReturnValue),
  func6?: ((state: SelectorInput) => Input6) | ((input1: Input1, input2: Input2, input3: Input3, input4: Input4, input5: Input5) => ReturnValue),
  func7?: ((state: SelectorInput) => Input7) | ((input1: Input1, input2: Input2, input3: Input3, input4: Input4, input5: Input5, input6: Input6) => ReturnValue),
  func8?: ((input1: Input1, input2: Input2, input3: Input3, input4: Input4, input5: Input5, input6: Input6, input7: Input7) => ReturnValue),
): (state: SelectorInput) => ReturnValue {
  checkArg({ inputType }, ArgTypes.func);
  checkArg({ outputType }, ArgTypes.func);
  const args = [func1, func2, func3, func4, func5, func6, func7, func8].filter(x => x !== undefined) as Function[];
  checkArg({ args }, ArgTypes.array.of(ArgTypes.func));
  const firstArg = args[0];
  const nonFirstNonLastArgs = args.slice(1, args.length - 1);
  const lastArg = args[args.length - 1];
  return createSelector(
    (selectorInput: SelectorInput) => { checkArg({ selectorInput }, inputType); return firstArg(selectorInput); },
    // @ts-expect-error
    ...nonFirstNonLastArgs,
    checkReturn(outputType, lastArg),
  );
}

// can't import AppState directly since that creats a cyclic dependency
const appStateArgType = ArgTypes.className('AppState');

export type AppSelector<ReturnType> = (state: AppState) => ReturnType;

// eslint-disable-next-line import/export
export function createAppStateSelector<ReturnValue, Input1>(outputType: Checker<ReturnValue>, getInput1: (state: AppState) => Input1, computeValue: (input1: Input1) => ReturnValue): AppSelector<ReturnValue>;
// eslint-disable-next-line import/export
export function createAppStateSelector<ReturnValue, Input1, Input2>(outputType: Checker<ReturnValue>, getInput1: (state: AppState) => Input1, getInput2: (state: AppState) => Input2, computeValue: (input1: Input1, input2: Input2) => ReturnValue): AppSelector<ReturnValue>;
// eslint-disable-next-line import/export
export function createAppStateSelector<ReturnValue, Input1, Input2, Input3>(outputType: Checker<ReturnValue>, getInput1: (state: AppState) => Input1, getInput2: (state: AppState) => Input2, getInput3: (state: AppState) => Input3, computeValue: (input1: Input1, input2: Input2, input3: Input3) => ReturnValue): AppSelector<ReturnValue>;
// eslint-disable-next-line import/export
export function createAppStateSelector<ReturnValue, Input1, Input2, Input3, Input4>(outputType: Checker<ReturnValue>, getInput1: (state: AppState) => Input1, getInput2: (state: AppState) => Input2, getInput3: (state: AppState) => Input3, getInput4: (state: AppState) => Input4, computeValue: (input1: Input1, input2: Input2, input3: Input3, input4: Input4) => ReturnValue): AppSelector<ReturnValue>;
// eslint-disable-next-line import/export
export function createAppStateSelector<ReturnValue, Input1, Input2, Input3, Input4, Input5>(outputType: Checker<ReturnValue>, getInput1: (state: AppState) => Input1, getInput2: (state: AppState) => Input2, getInput3: (state: AppState) => Input3, getInput4: (state: AppState) => Input4, getInput5: (state: AppState) => Input5, computeValue: (input1: Input1, input2: Input2, input3: Input3, input4: Input4, input5: Input5) => ReturnValue): AppSelector<ReturnValue>;
// eslint-disable-next-line import/export
export function createAppStateSelector<ReturnValue, Input1, Input2, Input3, Input4, Input5, Input6>(outputType: Checker<ReturnValue>, getInput1: (state: AppState) => Input1, getInput2: (state: AppState) => Input2, getInput3: (state: AppState) => Input3, getInput4: (state: AppState) => Input4, getInput5: (state: AppState) => Input5, getInput6: (state: AppState) => Input6, computeValue: (input1: Input1, input2: Input2, input3: Input3, input4: Input4, input5: Input5, input6: Input6) => ReturnValue): AppSelector<ReturnValue>;
// eslint-disable-next-line import/export
export function createAppStateSelector<ReturnValue, Input1, Input2, Input3, Input4, Input5, Input6, Input7>(outputType: Checker<ReturnValue>, getInput1: (state: AppState) => Input1, getInput2: (state: AppState) => Input2, getInput3: (state: AppState) => Input3, getInput4: (state: AppState) => Input4, getInput5: (state: AppState) => Input5, getInput6: (state: AppState) => Input6, getInput7: (state: AppState) => Input7, computeValue: (input1: Input1, input2: Input2, input3: Input3, input4: Input4, input5: Input5, input6: Input6, input7: Input7) => ReturnValue): AppSelector<ReturnValue>;
// eslint-disable-next-line import/export
export function createAppStateSelector<ReturnValue, Input1, Input2, Input3, Input4, Input5, Input6, Input7>(
  outputType: Checker<ReturnValue>,
  func1: (state: AppState) => Input1,
  func2: ((state: AppState) => Input2) | ((input1: Input1) => ReturnValue),
  func3?: ((state: AppState) => Input3) | ((input1: Input1, input2: Input2) => ReturnValue),
  func4?: ((state: AppState) => Input4) | ((input1: Input1, input2: Input2, input3: Input3) => ReturnValue),
  func5?: ((state: AppState) => Input5) | ((input1: Input1, input2: Input2, input3: Input3, input4: Input4) => ReturnValue),
  func6?: ((state: AppState) => Input6) | ((input1: Input1, input2: Input2, input3: Input3, input4: Input4, input5: Input5) => ReturnValue),
  func7?: ((state: AppState) => Input7) | ((input1: Input1, input2: Input2, input3: Input3, input4: Input4, input5: Input5, input6: Input6) => ReturnValue),
  func8?: ((input1: Input1, input2: Input2, input3: Input3, input4: Input4, input5: Input5, input6: Input6, input7: Input7) => ReturnValue),
): AppSelector<ReturnValue> {
  // @ts-expect-error
  return createTypedSelector(appStateArgType, outputType, func1, func2, func3, func4, func5, func6, func7, func8);
}

export const selectProjectInfoByProjectId = createCachedSelector(
  getProjectsWithStatus,
  getDocsByProjectId,
  (state: AppState, projectId: number) => projectId,
  (projectsWithStatus, docsByProjectId, projectId) => {
    const projectWithStatus = projectsWithStatus.find((pws: ProjectWithStatus) => pws.project.id.id === projectId);
    const unifiedDatasetDoc = projectWithStatus && docsByProjectId.get(projectId);
    if (projectWithStatus) {
      return new ProjectInfo({ projectWithStatus, unifiedDatasetDoc });
    }
    return null;
  })((state, projectId) => projectId);

export const selectAllProjectInfos = createSelector(
  getProjectsWithStatus,
  getDocsByProjectId,
  (projectsWithStatus, docsByProjectId): List<ProjectInfo> => {
    return projectsWithStatus.map((projectWithStatus: ProjectWithStatus) => {
      const unifiedDatasetDoc = docsByProjectId.get(projectWithStatus.project.id.id);
      return new ProjectInfo({ projectWithStatus, unifiedDatasetDoc });
    });
  },
);

export const getAllRecipeDocs = (state: AppState): List<Document<Recipe>> => {
  const { projects: { projectsWithStatus } } = state;
  return projectsWithStatus.flatMap((pws: ProjectWithStatus) => pws.recipes).map((rws: RecipeWithStatus) => rws.recipe);
};

const MODULE_ID_METADATA_KEY = 'resultingFromModule';

export const selectActiveProjectWithStatus = createSelector(
  (state: AppState) => state.location?.moduleId,
  (state: AppState) => state.location?.recipeId,
  (state: AppState) => state.projects?.projectsWithStatus,
  (moduleId, recipeId, projectsWithStatus) => {
    if (_.isNumber(moduleId)) {
      return projectsWithStatus.find((pws: ProjectWithStatus) => !!pws.recipes.find(rws => rws.recipe.data.metadata.get(MODULE_ID_METADATA_KEY) === moduleId));
    }
    if (_.isNumber(recipeId)) {
      return projectsWithStatus.find((pws: ProjectWithStatus) => !!pws.recipes.find(rws => rws.recipe.id.id === recipeId));
    }
    return null;
  },
);

export const getModuleIdForProject = (state: AppState, { projectId }: { projectId: number }): number | undefined => {
  const allProjectInfos = selectAllProjectInfos(state);
  return allProjectInfos.find(pi => pi.projectId === projectId)?.moduleId;
};

export const selectActiveProjectInfo = createSelector(
  (state) => state.unifiedDatasets?.docsByProjectId,
  selectActiveProjectWithStatus,
  (docsByProjectId, activePWS): ProjectInfo | null => {
    const unifiedDatasetDoc = activePWS && docsByProjectId.get(activePWS.project.id.id);
    if (activePWS) {
      return new ProjectInfo({ projectWithStatus: activePWS, unifiedDatasetDoc });
    }
    return null;
  });

export const selectActiveProjectId: AppSelector<number | undefined> = createSelector(
  selectActiveProjectInfo,
  projectInfo => projectInfo?.projectId,
);

export const isActiveUnifiedDatasetProfilingUpToDate = (state: AppState) => {
  const projectInfo = selectActiveProjectInfo(state);
  const isIndexed = projectInfo && projectInfo.isUnifiedDatasetIndexed;
  const profilingSchemaInfo = projectInfo && selectProfilingSchema(state);
  return !!profilingSchemaInfo && (!isIndexed || profilingSchemaInfo.profilingUpToDate);
};

export const isUnifiedDatasetCreated = (state: AppState): boolean => {
  return !!getPath(selectActiveProjectInfo(state), 'unifiedDatasetName');
};

export const selectIsUnifiedDatasetIndexed = createSelector(
  selectActiveProjectInfo,
  projectInfo => projectInfo && projectInfo.isUnifiedDatasetIndexed,
);

export const getUDColumnSettingsForPage = (
  state: AppState,
  page: DataTablesType,
  modifyFields?: (fields: List<string>) => List<string>,
  modifyDefaults?: (defaults: List<DisplayColumn>) => List<DisplayColumn>,
): List<DisplayColumn> | undefined => {
  const { location: { recipeId }, auth: { authorizedUser } } = state;
  const projectInfo = selectActiveProjectInfo(state);
  if (projectInfo && projectInfo.unifiedDataset && authorizedUser) {
    const fields = projectInfo.unifiedDataset.fields;
    const prefs = List(authorizedUser.columnPreferencesForPage(page, recipeId) || []);
    const visibleFields: List<string> = getPath(projectInfo.recipe.metadata, projectInfo.projectType, 'visibleFields');
    const invisibleFields =
      fields.toSet().subtract(visibleFields).toList().map((name: string) => new DisplayColumn({ name, visible: false }));
    const defaults = invisibleFields.push(new DisplayColumn({ name: ORIGIN_SOURCE_NAME, order: -Infinity, alias: 'Dataset' }));

    // The optional modifyFields and modifyDefaults functions allow the caller to manipulate the
    // field and default list prior to the preferences and defaults being applied, e.g.to add an
    // extra field.
    return getColumnDefinitions(
      modifyFields ? modifyFields(fields) : fields,
      prefs,
      modifyDefaults ? modifyDefaults(defaults) : defaults,
    );
  }
};

export const getAuthorizedUser = (state: AppState): MinimalAuthUser | undefined => state.auth?.authorizedUser;

export const selectLoggedInUserIsAdmin = createSelector(getAuthorizedUser, isAdmin);

export const activeUserIsCuratorForActiveProject = (state: AppState): boolean => {
  const { auth: { authorizedUser } } = state;
  const projectId = selectActiveProjectInfo(state)?.projectId;
  return isCuratorByProjectId(authorizedUser, projectId);
};

export const getUnifiedDatasetName = (state: AppState): string | undefined => {
  const projectInfo = selectActiveProjectInfo(state);
  return projectInfo && projectInfo.unifiedDatasetName || undefined;
};

export const getUnifiedDatasetId = (state: AppState): number | undefined => {
  const projectInfo = selectActiveProjectInfo(state);
  return projectInfo && projectInfo.unifiedDatasetId || undefined;
};

export const selectUnifiedAttributeNames = createSelector(
  (state: AppState) => state.schemaMapping?.allUnifiedAttributes,
  unifiedAttributes => unifiedAttributes && Set(unifiedAttributes.map((ua: $TSFixMe) => ua.name)),
);

export const getSMRecipeWithStatus = (state: AppState): RecipeWithStatus | null | undefined => {
  const projectInfo = selectActiveProjectInfo(state);
  return projectInfo && projectInfo.smRecipeWithStatus;
};

export const getActiveRecipeWithStatus = (state: AppState): RecipeWithStatus | null | undefined => {
  const projectInfo = selectActiveProjectInfo(state);
  return projectInfo && projectInfo.recipeWithStatus;
};

export const getActiveRecipeDoc = (state: AppState): Document<Recipe> | undefined => {
  const rws = getActiveRecipeWithStatus(state);
  return rws ? rws.recipe : undefined;
};

export const getActiveRecipe = (state: AppState): Recipe | undefined => {
  const rws = getActiveRecipeWithStatus(state);
  return rws ? rws.recipe.data : undefined;
};

export const getActiveSpendField = (state: AppState): string | undefined => {
  return getPath(selectActiveProjectInfo(state), 'unifiedDataset', 'metadata', 'spendField');
};

export const getUnifiedDatasetProfilingInfo = (state: AppState): ProfiledAttribute | undefined => {
  const unifiedDatasetName = getUnifiedDatasetName(state);
  const { unifiedDatasets: { profiling } } = state;
  if (unifiedDatasetName && !profiling.isEmpty()) {
    const profileData = profiling.get(unifiedDatasetName);
    if (profileData) {
      return profileData.schema.find((m: $TSFixMe) => !!(m.metrics && !m.attributeName));
    }
  }
};

export const getUnifiedDatasetRowCount = (state: AppState) => {
  const profilingInfo = getUnifiedDatasetProfilingInfo(state);
  if (profilingInfo) {
    const rowCount = profilingInfo.metrics.find(p => p.metricName === 'rowCount');
    return rowCount ? rowCount.value : undefined;
  }
};

export const getActiveNumSources = (state: AppState): number | undefined => {
  const smRecipe = getSMRecipeWithStatus(state);
  if (smRecipe) {
    return getPath(smRecipe, 'recipe', 'data', 'numInputDatasets');
  }
};

export const getDedupInfo = (state: AppState): $TSFixMe => {
  return getPath(selectActiveProjectInfo(state), 'recipe', 'metadata', DEDUP_INFO_METADATA_KEY);
};

export const getActiveProjectRecipeIds = (state: AppState): Set<number> => {
  return (getPath(selectActiveProjectWithStatus(state), 'project', 'data', 'steps') || List()).map((docId: DocumentId) => docId.id).toSet();
};

export const isJobRunning = (state: AppState, params: { recipeOperation?: $TSFixMe, forActiveProject?: boolean, description?: string }) => {
  checkArg({ params }, ArgTypes.object);
  const { recipeOperation, forActiveProject, description } = params;
  checkArg({ recipeOperation }, ArgTypes.orUndefined(RecipeOperations.argType));
  checkArg({ forActiveProject }, ArgTypes.orUndefined(ArgTypes.bool));
  checkArg({ description }, ArgTypes.orUndefined(ArgTypes.string));
  const { chrome: { runningJobs } } = state;
  const activeProjectRecipeIds = getActiveProjectRecipeIds(state);
  return runningJobs.some(({ data, data: { metadata } }: Document<Job>) => {
    if (recipeOperation && metadata?.get('recipeOperation') !== recipeOperation) {
      return false;
    }
    if (forActiveProject && !activeProjectRecipeIds.has(metadata?.get('recipeId'))) {
      return false;
    }
    if (description && data.description !== description) {
      return false;
    }
    return true;
  });
};

export const getCurrentlyProfilingDatasetNames = (state: AppState): Set<string> => {
  const { chrome: { runningJobs, requestedProfileJobs } } = state;
  return runningJobs.valueSeq()
    .filter((job: Document<Job>) => job.data.metadata?.get('isProfilingJob'))
    .map((job: Document<Job>) => job.data.metadata?.get('datasetName'))
    .concat(requestedProfileJobs)
    .toSet();
};

export const getCurrentlyExportingDatasetIds = (state: AppState): Set<number> => {
  const { chrome: { runningJobs } } = state;
  return runningJobs
    .filter((job: Document<Job>) => job.data.metadata?.get('isExportJob'))
    .map((job: Document<Job>) => job.data.metadata?.get('datasetId')).toSet();
};

export const isCommitSchemaJobRunningForActiveProject = (state: AppState): boolean => {
  const { chrome: { runningJobs, requestedCommitSchemaJobs } } = state;
  const smRecipeId = getPath(selectActiveProjectInfo(state), 'smRecipeId');
  return requestedCommitSchemaJobs.has(smRecipeId) || runningJobs.some((job: Document<Job>) => job.getIn(['data', 'metadata', 'recipeId']) === smRecipeId);
};
export const isRecommendationsJobRunningForActiveProject = (state: AppState): boolean => {
  const { chrome: { runningJobs, requestedTrainSuggestionsJobs, requestedPredictSuggestionsJobs } } = state;
  const smRecsRecipeId = getPath(selectActiveProjectInfo(state), 'smRecsRecipeId');
  return requestedTrainSuggestionsJobs.union(requestedPredictSuggestionsJobs).has(smRecsRecipeId) || runningJobs.some((job: Document<Job>) => job.getIn(['data', 'metadata', 'recipeId']) === smRecsRecipeId);
};
export const isJobRunningForActiveUnifiedDataset = (state: AppState): boolean => {
  return isCommitSchemaJobRunningForActiveProject(state) || isRecommendationsJobRunningForActiveProject(state);
};

export const selectDocUrl = createAppStateSelector(
  ArgTypes.orUndefined(ArgTypes.string),
  state => state.version.version?.version,
  (version) => (version ? getDocUrl(version) : undefined),
);
