/**
 * Two-panel schema mapping page which runs against a specified output dataset
 * and recipe
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import PageHeader from '../chrome/PageHeader';
import Button from '../components/Button';
import CenterContent from '../components/CenterContent';
import LoadingPanel from '../components/LoadingPanel';
import AttributeId from '../models/AttributeId';
import { getUnifiedDatasetName, selectActiveProjectInfo } from '../utils/Selectors';
import AllUnifiedAttributesLoader from './AllUnifiedAttributesLoader';
import BootstrapNameValidationErrorsDialog from './BootstrapNameValidationErrorsDialog';
import ConfirmBulkAcceptDialog from './ConfirmBulkAcceptDialog';
import ConfirmBulkDoNotMapDialog from './ConfirmBulkDoNotMapDialog';
import ConfirmBulkUnmapDialog from './ConfirmBulkUnmapDialog';
import ConfirmTiedRecsDialog from './ConfirmTiedRecsDialog';
import FilterIndicator from './FilterIndicator';
import HasSmrModelLoader from './HasSmrModelLoader';
import InitializeUnifiedDataset from './InitializeUnifiedDataset';
import NoSourceAttributes from './NoSourceAttributes';
import NoUnifiedAttributes from './NoUnifiedAttributes';
import NoUnifiedDataset from './NoUnifiedDataset';
import {
  SOURCE_RESET_SOURCE_AND_UNIFIED_ATTRIBUTES,
  SOURCE_TOGGLE_FILTER_RELATED_ID,
  UNIFIED_TOGGLE_FILTER_RELATED_ID,
} from './SchemaMappingActionTypes';
import SchemaMappingCommitButton from './SchemaMappingCommitButton';
import commitButtonStyle from './SchemaMappingCommitButton.module.scss';
import SchemaMappingCommitWarning from './SchemaMappingCommitWarning';
import SchemaMappingConfig from './SchemaMappingConfig';
import SchemaMappingDragLayer from './SchemaMappingDragLayer';
import {
  getNoSourceAttributesInSystem,
  getNoUnifiedAttributesInSystem,
} from './SchemaMappingStore';
import SourceAttributeList from './SourceAttributeList';
import SourceAttributePager from './SourceAttributePager';
import SourceAttributesLoader from './SourceAttributesLoader';
import SourceListHeader from './SourceListHeader';
import TrainPredictButtons from './TrainPredictButtons';
import UnifiedAttributeActionWarningDialog from './UnifiedAttributeActionWarningDialog';
import UnifiedAttributeList from './UnifiedAttributeList';
import UnifiedAttributesLoader from './UnifiedAttributesLoader';
import UnifiedListHeader from './UnifiedListHeader';
import UpdateUnifiedDatasetDialog from './UpdateUnifiedDatasetDialog';

const SchemaMapping = _.compose(
  connect(state => {
    const {
      schemaMapping: {
        sourceShowSpinner,
        sourceLoading,
        unifiedShowSpinner,
        unifiedLoading,
        hasValidationErrors,
        unifiedFilterRelatedId,
        sourceFilterRelatedId,
        unifiedAttributesRemoved,
        sourceAttributesRemoved,
      },
      unifiedDatasets: { loading },
    } = state;

    const unifiedDatasetsLoading = loading;
    const unifiedDatasetExisting = !!selectActiveProjectInfo(state)?.unifiedDataset;

    return {
      sourceFilterRelatedId,
      unifiedFilterRelatedId,
      hasValidationErrors,
      noSourceAttributesInSystem: getNoSourceAttributesInSystem(state),
      noUnifiedAttributesInSystem: getNoUnifiedAttributesInSystem(state),
      sourceShowSpinner,
      sourceLoading,
      unifiedShowSpinner,
      unifiedLoading,
      unifiedDatasetsLoading,
      // this is false when the UD has never been created or has been deleted
      unifiedDatasetExisting,
      // note that a non-empty value here does NOT mean that UD exists. It might have been deleted.
      unifiedDatasetName: getUnifiedDatasetName(state),
      hasGreyedOutRows: sourceAttributesRemoved.size + unifiedAttributesRemoved.size > 0,
    };
  }, {
    onToggleUnifiedFilterRelatedId: id => ({ type: UNIFIED_TOGGLE_FILTER_RELATED_ID, id }),
    onToggleSourceFilterRelatedId: id => ({ type: SOURCE_TOGGLE_FILTER_RELATED_ID, id }),
    resetSourceAndUnifiedAttributes: () => ({ type: SOURCE_RESET_SOURCE_AND_UNIFIED_ATTRIBUTES }),
  }),
)(class SchemaMapping extends React.Component {
  static propTypes = {
    canUserEdit: PropTypes.bool.isRequired,
    hasValidationErrors: PropTypes.bool.isRequired,
    noSourceAttributesInSystem: PropTypes.bool.isRequired,
    noUnifiedAttributesInSystem: PropTypes.bool.isRequired,
    onToggleSourceFilterRelatedId: PropTypes.func.isRequired,
    onToggleUnifiedFilterRelatedId: PropTypes.func.isRequired,
    sourceFilterRelatedId: PropTypes.instanceOf(AttributeId),
    sourceLoading: PropTypes.bool.isRequired,
    sourceShowSpinner: PropTypes.bool.isRequired,
    unifiedDatasetExisting: PropTypes.bool.isRequired,
    unifiedDatasetName: PropTypes.string,
    unifiedFilterRelatedId: PropTypes.instanceOf(AttributeId),
    unifiedLoading: PropTypes.bool.isRequired,
    unifiedShowSpinner: PropTypes.bool.isRequired,
  };

  renderContentWithLoading = (loading, renderer) => {
    return loading
      ? <LoadingPanel semiTransparent={false} />
      : renderer;
  };

  // if we see that the unified dataset does not currently exist, but the name is
  // present, then it's been deleted from the system.
  deletedUnifiedDatasetName = () => !this.props.unifiedDatasetExisting && this.props.unifiedDatasetName;

  deletedUnifiedDataset = (datasetName) => <CenterContent>
    <div>{`The unified dataset [${datasetName}] has been deleted.`}</div>
  </CenterContent>;

  renderSourceAttributeList = () => {
    const {
      canUserEdit,
      unifiedDatasetExisting,
      onToggleUnifiedFilterRelatedId,
      noSourceAttributesInSystem,
      sourceLoading,
      sourceShowSpinner,
      unifiedFilterRelatedId,
      resetSourceAndUnifiedAttributes,
      hasGreyedOutRows,
    } = this.props;

    const sourceAttributeListContainerClass = classNames(
      'source-attribute-list-container', {
        'filter-enabled': !!unifiedFilterRelatedId,
      },
    );

    const sourceSpinner = sourceLoading && sourceShowSpinner;
    const showPager = !(sourceSpinner || noSourceAttributesInSystem);
    const sourceAttributeListPager = classNames(
      'source-attribute-subsection-container', {
        hidden: !showPager,
      },
    );

    return (
      <div className="section-container source-attribute-section-container">
        <div className="section source-attribute-section">
          <SourceListHeader canUserEdit={canUserEdit} />
          <div className={sourceAttributeListContainerClass}>
            {(() => {
              switch (true) {
                case sourceSpinner || this.props.unifiedDatasetsLoading: return <LoadingPanel semiTransparent={false} />;
                case !!this.deletedUnifiedDatasetName():
                  return this.renderContentWithLoading(sourceLoading, this.deletedUnifiedDataset(this.deletedUnifiedDatasetName()));
                // TODO: The source attributes API currently requires the UD to be present.
                //       Need to update the API?
                case !unifiedDatasetExisting:
                  return this.renderContentWithLoading(sourceLoading, <NoUnifiedDataset />);
                case noSourceAttributesInSystem:
                  return <NoSourceAttributes canUserEdit={canUserEdit} />;
                default: return <SourceAttributeList canUserEdit={canUserEdit} />;
              }
            })()}
          </div>
          {(unifiedFilterRelatedId) ? (
            <FilterIndicator
              className={'source-attribute-filter-section'}
              filterAttributeId={unifiedFilterRelatedId}
              onToggleFilterRelatedId={() => onToggleUnifiedFilterRelatedId(unifiedFilterRelatedId)}
            />
          ) : null}
          <div className={sourceAttributeListPager}>
            {hasGreyedOutRows && <Button onClick={resetSourceAndUnifiedAttributes} style={{ margin: '0 5px' }}>Reload</Button>}
            {showPager ?
              <SourceAttributePager tooltip={hasGreyedOutRows ? 'Reload page to continue' : null} disabled={hasGreyedOutRows} pageSizeLabel="Attributes per page:" />
              : null}
          </div>
        </div>
      </div>
    );
  };

  renderUnifiedAttributeList = () => {
    const {
      canUserEdit,
      unifiedDatasetExisting,
      onToggleSourceFilterRelatedId,
      noUnifiedAttributesInSystem,
      sourceFilterRelatedId,
      unifiedLoading,
      unifiedShowSpinner,
      hasValidationErrors,
    } = this.props;

    const unifiedAttributeListContainerClass = classNames(
      'unified-attribute-list-container', {
        'filter-enabled': !!sourceFilterRelatedId,
        'has-error-message': hasValidationErrors,
        'allow-actions': canUserEdit,
        'expand-fully': !unifiedDatasetExisting,
      },
    );

    return (
      <div className="section-container unified-attribute-section-container">
        <div className="section unified-attribute-section">
          {unifiedDatasetExisting ?
            <UnifiedListHeader canUserEdit={canUserEdit} />
            : null}
          <div className={unifiedAttributeListContainerClass}>
            {(() => {
              switch (true) {
                case (unifiedLoading && unifiedShowSpinner) || this.props.unifiedDatasetsLoading:
                  return <LoadingPanel semiTransparent={false} />;
                case !!this.deletedUnifiedDatasetName():
                  return this.renderContentWithLoading(unifiedLoading, this.deletedUnifiedDataset(this.deletedUnifiedDatasetName()));
                case !unifiedDatasetExisting:
                  return this.renderContentWithLoading(
                    unifiedLoading,
                    <InitializeUnifiedDataset canUserEdit={canUserEdit} />,
                  );
                case noUnifiedAttributesInSystem: return <NoUnifiedAttributes />;
                default: return <UnifiedAttributeList canUserEdit={canUserEdit} />;
              }
            })()}
          </div>
          {(sourceFilterRelatedId) ? (
            <FilterIndicator
              className={classNames('unified-attribute-filter-section', { 'has-error-message': hasValidationErrors })}
              filterAttributeId={sourceFilterRelatedId}
              onToggleFilterRelatedId={() => onToggleSourceFilterRelatedId(sourceFilterRelatedId)}
            />
          ) : null}
        </div>
      </div>
    );
  };

  render() {
    const { canUserEdit } = this.props;
    return (
      <div className="schema-mapping">
        <PageHeader title="Schema Mapping" />
        <div className="schema-mapping-controls">
          <SchemaMappingConfig />
          {canUserEdit ?
            <TrainPredictButtons />
            : null}
          {canUserEdit ?
            <SchemaMappingCommitButton className={commitButtonStyle.component} />
            : null}
          {canUserEdit ?
            <SchemaMappingCommitWarning />
            : null}
        </div>
        {this.renderSourceAttributeList()}
        {this.renderUnifiedAttributeList()}
        <ConfirmTiedRecsDialog />
        <ConfirmBulkAcceptDialog />
        <ConfirmBulkDoNotMapDialog />
        <ConfirmBulkUnmapDialog />
        <SourceAttributesLoader />
        <AllUnifiedAttributesLoader />
        <HasSmrModelLoader />
        <UnifiedAttributesLoader />
        <SchemaMappingDragLayer />
        <UnifiedAttributeActionWarningDialog />
        <UpdateUnifiedDatasetDialog />
        <BootstrapNameValidationErrorsDialog />
      </div>
    );
  }
});

export default SchemaMapping;
