import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import ConditionalButton from '../components/ConditionalButton';
import Term from '../components/Term';
import EsRecord from '../models/EsRecord';
import { assignFeedback, deleteFeedback } from '../transactions/TransactionApi';
import FeedbackAssignDialog from './FeedbackAssignDialog';

const FeedbackAssignButton = connect(null, {
  onAssignFeedback: assignFeedback,
  onDeleteFeedback: deleteFeedback,
})(class FeedbackAssignButton extends React.Component {
  static propTypes = {
    iconify: PropTypes.bool.isRequired,
    onAssignFeedback: PropTypes.func.isRequired,
    onDeleteFeedback: PropTypes.func.isRequired,
    records: ImmutablePropTypes.listOf(PropTypes.instanceOf(EsRecord)),
  };

  state = { showingFeedbackDialog: false };

  showFeedbackDialog = () => {
    this.setState({ showingFeedbackDialog: true });
  };

  closeFeedbackDialog = () => {
    this.setState({ showingFeedbackDialog: false });
  };

  onAssign = (usersToAssign, usersToUnassign) => {
    const { onAssignFeedback, onDeleteFeedback, records } = this.props;
    if (!usersToAssign.isEmpty()) {
      onAssignFeedback(usersToAssign, records);
    }
    if (!usersToUnassign.isEmpty()) {
      onDeleteFeedback(usersToUnassign, records);
    }
    this.setState({ showingFeedbackDialog: false });
  };

  userAssignmentCount = (user) => {
    return this.props.records.count(r => {
      const feedback = r.feedbackForUser(user);
      return feedback?.isAssigned;
    });
  };

  renderButton = () => {
    const { iconify, records } = this.props;
    const label = 'Assign';
    const preconditions = Map()
      .set(<span>You must select at least one <Term>record</Term></span>, !records.isEmpty());
    return (
      <ConditionalButton
        onClick={this.showFeedbackDialog}
        buttonType="Secondary"
        preconditions={preconditions}
        icon="group-add"
        iconSize={16}
        tooltipLabel={iconify ? label : ''}
      >
        {iconify ? undefined : label}
      </ConditionalButton>
    );
  };

  render() {
    return (
      <div className="feedback-assign-button">
        {this.renderButton()}
        <FeedbackAssignDialog
          noun="part"
          showDialog={this.state.showingFeedbackDialog}
          onAssign={this.onAssign}
          onHide={this.closeFeedbackDialog}
          userAssignmentCount={this.userAssignmentCount}
          recordCount={this.props.records.size}
        />
      </div>
    );
  }
});

export default FeedbackAssignButton;
