import './ProjectStatusContent.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import PageHeader from '../chrome/PageHeader';
import { CATEGORIZATION } from '../constants/RecipeType';
import ProjectInfo from '../models/ProjectInfo';
import { titleCase } from '../projects/terms/Term';
import { selectActiveProjectInfo } from '../utils/Selectors';

const UsersContent = connect(state => {
  return { projectInfo: selectActiveProjectInfo(state) };
})(class UsersContent extends React.Component {
  static propTypes = {
    projectInfo: PropTypes.instanceOf(ProjectInfo),
  };

  renderInfo = (title, value, key) => {
    return (
      <div key={key} className="info-panel">
        <div className="info-title">
          {title}
        </div>
        <div className="info-value">
          {value}
        </div>
      </div>
    );
  };

  getSeparator = () => {
    return <hr />;
  };

  getStats = (projectInfo) => {
    const stats = [];
    stats.push(this.renderInfo('Project Name', projectInfo.projectDoc.data.name, 'projectName'));
    stats.push(this.renderInfo('Project Id', projectInfo.projectId, 'projectId'));
    stats.push(this.renderInfo('Project Type', projectInfo.projectType, 'projectType'));
    stats.push(this.getSeparator());

    if (projectInfo.unifiedDataset) {
      stats.push(this.renderInfo('Unified Dataset Name', projectInfo.unifiedDataset.name, 'unifiedDatasetName'));
      stats.push(this.renderInfo('Unified Dataset Id', projectInfo.unifiedDatasetId, 'unifiedDatasetId'));
      stats.push(this.getSeparator());
    }

    stats.push(this.renderInfo('Schema Mapping Recipe Name', projectInfo.smRecipe.name, 'smRecipeName'));
    stats.push(this.renderInfo('Schema Mapping Recipe Id', projectInfo.smRecipeId, 'smRecipeId'));
    stats.push(this.getSeparator());

    const smSuggestionsRecipe = projectInfo.recipesWithStatus.get(1);
    if (smSuggestionsRecipe && smSuggestionsRecipe.recipe.data.type === 'SCHEMA_MAPPING_RECOMMENDATIONS') {
      const smName = smSuggestionsRecipe.recipe.data.name;
      const smId = smSuggestionsRecipe.recipe.id.id;
      stats.push(this.renderInfo('Schema Mapping Suggestions Recipe Name', smName, 'smrRecipeName'));
      stats.push(this.renderInfo('Schema Mapping Suggestions Recipe Id', smId, 'smrRecipeId'));
      stats.push(this.getSeparator());
    }

    if (projectInfo.recipe) {
      stats.push(this.renderInfo(`${titleCase(projectInfo.projectType.toLowerCase())} Recipe Name`, projectInfo.recipe.name, 'recipeName'));
      stats.push(this.renderInfo(`${titleCase(projectInfo.projectType.toLowerCase())} Recipe Id`, projectInfo.recipeId, 'recipeId'));
      stats.push(this.renderInfo(`${titleCase(projectInfo.projectType.toLowerCase())} Tag name`, `recipe_${projectInfo.recipeId}`, 'recipeTag'));
    }

    if (projectInfo.projectType === CATEGORIZATION) {
      const categorizationInfo = projectInfo.recipe.metadata.get(CATEGORIZATION);
      if (categorizationInfo) {
        stats.push(this.renderInfo('Taxonomy Id', categorizationInfo.taxonomyId.id, 'taxonomyId'));
      }
    }
    return stats;
  };

  render() {
    const { projectInfo } = this.props;
    if (!projectInfo) {
      return <div />;
    }
    return (
      <div className="project-status-content-container">
        <PageHeader title="Project Status" />
        <div className="project-status-details-container">
          {this.getStats(projectInfo)}
        </div>
      </div>
    );
  }
});
export default UsersContent;
