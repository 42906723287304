import { is } from 'immutable';
import { connect } from 'react-redux';
import { createSink, shouldUpdate } from 'recompose';
import _ from 'underscore';

import { fetchStatsForProject } from './ProjectStatsApi';

// TODO switch over to using createLoader()
const ProjectStatsLoader = _.compose(
  connect((state, { projectId }) => {
    const { projectStats: { byProjectId, loading } } = state;
    return { loaded: byProjectId.has(projectId), loading: loading.includes(projectId) };
  }, {
    doFetch: fetchStatsForProject,
  }),
  shouldUpdate((props, nextProps) => !_.every(props, (v, k) => is(v, nextProps[k]))),
)(createSink(({ projectId, loaded, loading, doFetch, moduleId }) => {
  if (!loaded && !loading) {
    doFetch(projectId, moduleId);
  }
}));

export default ProjectStatsLoader;
