import _ from 'underscore';

import { ORIGIN_SOURCE_NAME } from '../constants/ElasticConstants';
import { ArgTypes, checkArg } from '../utils/ArgValidation';

/**
 * Minimal API needed by the records data table to render a row of data
 */
class PreviewRecord {
  constructor(record) {
    checkArg({ record }, ArgTypes.object);
    this._data = record;
  }

  getValue(field) {
    let data = this._data[field];
    // NB in regular usage, ORIGIN_SOURCE_NAME column is a string multivalue in preview mode.
    //    need to get the raw string in order to perform comparisons later.
    //    Coming out of ES normally, this field is just a string, not a multivalue.
    if (field === ORIGIN_SOURCE_NAME && _.isArray(data)) {
      data = data[0];
    }
    return {
      renderRaw: false,
      data,
    };
  }

  get fields() {
    return _.keys(this._data) || [];
  }
}

export default PreviewRecord;
