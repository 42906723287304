import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import { isCuratorByProjectId, isVerifierByProjectId } from '../../utils/Authorization';
import { getAuthorizedUser, selectActiveProjectInfo } from '../../utils/Selectors';
import AllAssignmentsFilterType from '../AllAssignmentsFilterType';
import SelectFilter from './SelectFilter';

const { ALL, ALL_RESPONDED, SOME_PENDING } = AllAssignmentsFilterType;

const NO_FILTER = 'NOFILTER';

const OPTIONS = [
  { value: NO_FILTER, display: 'No Filter' },
  { value: ALL, display: 'All' },
  { value: ALL_RESPONDED, display: 'Completed' },
  { value: SOME_PENDING, display: 'Open' },
];

const AllAssignmentsFilter = _.compose(
  connect(state => {
    const { recordPairs: { allAssignments } } = state;
    return { allAssignments, userIsCurator: isCuratorByProjectId(getAuthorizedUser(state), selectActiveProjectInfo(state)?.projectDoc.id.id), userIsVerifier: isVerifierByProjectId(getAuthorizedUser(state), selectActiveProjectInfo(state)?.projectDoc.id.id) };
  }, {
    onSetAllAssignmentsFilter: (allAssignments) => ({ type: 'RecordPairs.setAllAssignmentsFilter', allAssignments }),
  }),
)(class AllAssignmentsFilter extends React.Component {
  static propTypes = {
    onSetAllAssignmentsFilter: PropTypes.func.isRequired,
    userIsCurator: PropTypes.bool.isRequired,
    userIsVerifier: PropTypes.bool.isRequired,
  };

  render() {
    const { allAssignments, onSetAllAssignmentsFilter, userIsCurator, userIsVerifier } = this.props;
    if (!(userIsCurator || userIsVerifier)) {
      return null;
    }
    return (
      <SelectFilter
        onChange={value => onSetAllAssignmentsFilter(value === NO_FILTER ? undefined : value)}
        label="All expert assignments"
        values={OPTIONS}
        value={allAssignments || NO_FILTER}
      />
    );
  }
});

export default AllAssignmentsFilter;
