import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import { StoreReducers } from '../stores/AppAction';
import { ArgTypes } from '../utils/ArgValidation';
import { AUTH_COMPLETED, AUTH_FAILED } from './SSOActionTypes';

export class SSOStore extends getModelHelpers({
  loaded: { type: ArgTypes.bool, defaultValue: false },
}, 'SSOStore')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class SSOStoreRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  reduce(reduceFunction: Function, accumulator: any) {
    Array.from(this.toSeq().entries()).forEach(entry => {
      accumulator = reduceFunction(accumulator, entry[1], entry[0]);
    });
    return accumulator;
  }
}

export const initialState = new SSOStore({});

export const reducers: StoreReducers<SSOStore> = {
  [AUTH_COMPLETED]: (state) => {
    return state.merge({ loaded: true });
  },

  [AUTH_FAILED]: (state) => {
    return state.merge({ loaded: true });
  },
};
