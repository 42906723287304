import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import ConditionalButton from '../components/ConditionalButton';
import FilterButton from '../components/FilterButton';
import PopoverTrigger from '../components/PopoverTrigger';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import Response from '../constants/Response';
import FeedbackAssignButton from '../feedback/FeedbackAssignButton';
import EsRecord from '../models/EsRecord';
import PRODUCT_NAME from '../utils/ProductName';
import { getAuthorizedUser } from '../utils/Selectors';
import { bulkAccept, bulkAcceptExpert, bulkRespond, bulkSkip } from './TransactionApi';
import TransactionBulkCategorizeButton from './TransactionBulkCategorizeButton';
import { anyFiltersActive, getSelectedRecords, getTopExpertCategorizations } from './TransactionStore';

const TransactionTaskbar = _.compose(
  connect(state => {
    const { transactions } = state;
    return {
      hasFilters: anyFiltersActive(state),
      expanded: transactions.expanded,
      filterExpanded: transactions.filterExpanded,
      selectedRecords: getSelectedRecords(transactions),
      activeRecordExists: !!transactions.activeRecordId,
      username: getAuthorizedUser(state).username,
      topExpertCategorizationsExist: getTopExpertCategorizations(state).size > 0,
    };
  }, {
    onBulkRespond: bulkRespond,
    onBulkSkip: bulkSkip,
    onBulkAccept: bulkAccept,
    onBulkAcceptExpert: bulkAcceptExpert,
    onToggleFilter: () => ({ type: 'Transactions.toggleExpandFilter' }),
    onClearFilters: () => ({ type: 'Transactions.clearFilters' }),
    onToggleSidebar: () => ({ type: 'Transactions.toggleExpandSidebar' }),
  }),
)(class TransactionTaskbar extends React.Component {
  static propTypes = {
    expanded: PropTypes.bool.isRequired,
    height: PropTypes.number.isRequired,
    isUserACurator: PropTypes.bool.isRequired,
    isUserAVerifier: PropTypes.bool.isRequired,
    onBulkAccept: PropTypes.func.isRequired,
    onBulkAcceptExpert: PropTypes.func.isRequired,
    onBulkRespond: PropTypes.func.isRequired,
    onBulkSkip: PropTypes.func.isRequired,
    onToggleFilter: PropTypes.func.isRequired,
    selectedRecords: ImmutablePropTypes.listOf(PropTypes.instanceOf(EsRecord)),
    topExpertCategorizationsExist: PropTypes.bool.isRequired,
    username: PropTypes.string,
    width: PropTypes.number.isRequired,
  };

  renderButton = (
    label,
    onClick,
    className,
    requiresSuggestedC12n,
    requiresSuggestedOrManualC12n,
    requiresAssignment,
    icon,
    iconify,
  ) => {
    const { selectedRecords, username } = this.props;
    const preconditions = Map()
      .set(<span>You must select at least one <Term>record</Term></span>,
        !selectedRecords.isEmpty())
      .set(<span>All selected <Term>records</Term> must have generated suggestions</span>,
        !requiresSuggestedC12n || !selectedRecords.find(record => !record.suggestedCategorization))
      .set(<span>All selected <Term>records</Term> must have a suggestion</span>,
        !requiresSuggestedOrManualC12n || !selectedRecords.find(record => !(record.suggestedCategorization || record.manualCategorization)))
      .set(<span>All selected <Term>records</Term> must have been assigned to you</span>,
        !requiresAssignment || !selectedRecords.find(record => !record.feedback.some(feedback => feedback.username === username)));
    return (
      <ConditionalButton
        className={className}
        onClick={_.partial(onClick, selectedRecords)}
        buttonType="Secondary"
        icon={icon}
        preconditions={preconditions}
        tooltipLabel={iconify ? label : undefined}
      >
        {iconify ? undefined : label}
      </ConditionalButton>
    );
  };

  renderFilterButton = () => {
    const { onToggleFilter, onClearFilters, hasFilters, filterExpanded } = this.props;
    return (
      <FilterButton className="filter-button" onClick={onToggleFilter} filterExpanded={filterExpanded} hasFilters={hasFilters} onClear={hasFilters ? onClearFilters : undefined} />
    );
  };

  handleMenu = (callback) => {
    callback();
    this.refs.popover.hide();
  };

  renderVerifyActions = () => {
    const { onBulkAccept, onBulkAcceptExpert, topExpertCategorizationsExist } = this.props;
    return (
      <div>
        <ConditionalButton
          buttonType="Link"
          className="verify-action"
          onClick={() => this.handleMenu(onBulkAcceptExpert)}
          preconditions={Map()
            .set('There is no top expert suggestion', topExpertCategorizationsExist)
          }
        >
          Top expert suggestion
        </ConditionalButton>
        <ConditionalButton
          buttonType="Link"
          className="verify-action"
          onClick={() => this.handleMenu(onBulkAccept)}
          preconditions={Map()}
        >
          {PRODUCT_NAME} suggestion
        </ConditionalButton>
      </div>
    );
  };

  renderButtons = (width, height) => {
    const { isUserACurator, isUserAVerifier, onBulkRespond, onBulkSkip, onToggleSidebar, expanded, activeRecordExists } = this.props;
    const buttonsToDropdownTriggerWidth = 1030;
    const iconify = width < buttonsToDropdownTriggerWidth;
    return (
      <div className="transaction-button-container" style={{ width, height }}>
        {this.renderFilterButton()}
        <div className="expert-response-button-group">
          {this.renderButton('Upvote', () => onBulkRespond(Response.AGREE), 'button-upvote', false, true, false, 'tamr-icon-rounded-sort-up', iconify)}
          {this.renderButton('Downvote', () => onBulkRespond(Response.DISAGREE), 'button-downvote', false, true, false, 'tamr-icon-rounded-sort-down', iconify)}
          {this.renderButton('Skip', onBulkSkip, 'button-skip', false, false, true, 'tamr-icon-ic_skip_next_black', iconify)}
          <TransactionBulkCategorizeButton iconify={iconify} />
        </div>
        {!(isUserACurator || isUserAVerifier) ? null : (
          <div key="button-group" className="curator-response-button-group">
            <FeedbackAssignButton records={this.props.selectedRecords} iconify={iconify} />
            <PopoverTrigger ref="popover" placement="bottom" className="verify-actions" content={this.renderVerifyActions()}>
              {this.renderButton(
                <span className="verify-action-button-wrapper">
                  <span>Verify</span>
                  <TamrIcon iconName="arrow-drop-down" size={18} className="verify-action-arrow" />
                </span>,
                () => {}, 'button-accept', false, false, false, undefined, false)}
            </PopoverTrigger>
          </div>
        )}
        <ConditionalButton
          className="transaction-toggle-sidebar"
          buttonType="Secondary"
          preconditions={Map().set(<span>No <Term>record</Term> selected</span>, expanded || (activeRecordExists && !expanded))}
          onClick={onToggleSidebar}
        >
          {expanded
            ? iconify ? 'Close' : 'Close details'
            : iconify ? 'Open' : 'Open details'
          }
        </ConditionalButton>
      </div>
    );
  };

  render() {
    const { width, height } = this.props;
    return (
      <div className="transaction-taskbar-component">
        {this.renderButtons(width, height)}
      </div>
    );
  }
});

export default TransactionTaskbar;
