/* eslint-disable no-console */

import createLog from 'localstorage-logger';

const LOG_NAME = 'unify-log';

// determine if these logs were tampered with, if so, reset
// could happen through manual clearing of localStorage.
// if that happens, this would break indefinitely if we didn't reset
const logNode = localStorage.getItem(LOG_NAME);
const { startIndex, nextFreeIndex } = logNode ? JSON.parse(logNode) : {};
if (startIndex || nextFreeIndex) {
  if (!localStorage.getItem(`unify-log-item-${startIndex}`) || !localStorage.getItem(`unify-log-item-${nextFreeIndex - 1}`)) {
    // NB the log entries are all stored separately, and I'm not sure there's a way to only delete
    //    entries starting with a particular namespace
    console.warn(
      'The localStorage logs are inconsistent, probably due to manual tampering.' +
      ' Resetting all of localStorage for this domain now.',
    );
    localStorage.clear();
  }
}

const log = createLog({
  logName: LOG_NAME,
  maxLogSizeInBytes: 3000 * 1024, // 3MB
});

// wrap localstorage-logger log function so we can hijack console.log
// localstorage-logger prints all log messages to console. we don't want that for now
const doLog = (logLevel, ...args) => {
  const consoleLogFn = console.log;
  try {
    console.log = () => {};
    log[logLevel](...args);
  } catch (e) {
    console.error('Found exception while logging', e);
  } finally {
    console.log = consoleLogFn;
  }
};

const Log = {
  debug: (...args) => doLog('debug', ...args),
  info: (...args) => doLog('info', ...args),
  warn: (...args) => doLog('warn', ...args),
  error: (...args) => doLog('error', ...args),
  exportToArray: () => log.exportToArray(),
};

Log.info('Page Refresh', window.location.href);

// wrap console.log, .warn, and .error to tee all output into the log
const consoleLogFn = console.log;
const consoleWarnFn = console.warn;
const consoleErrFn = console.error;
console.log = (...args) => {
  Log.info('CONSOLE.LOG', ...args);
  consoleLogFn(...args);
};
console.warn = (...args) => {
  Log.warn('CONSOLE.WARN', ...args);
  consoleWarnFn(...args);
};
console.error = (...args) => {
  Log.error('CONSOLE.ERROR', ...args);
  consoleErrFn(...args);
};

export default Log;
