import createLoader from '../utils/createLoader';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { checkExportableSmrModel } from './SchemaMappingAsync';

/**
 * Loads the current state of the schema mapping model of the active project exactly once
 */
const HasSmrModelLoader = createLoader((state) => {
  const { schemaMapping: { hasSmrModelLoading, hasSmrModelLoaded } } = state;
  const projectInfo = selectActiveProjectInfo(state);
  return {
    canFetch: !!projectInfo && !!projectInfo.unifiedDataset?.name,
    shouldFetch: !hasSmrModelLoaded,
    loading: !!hasSmrModelLoading,
  };
}, {
  onFetch: checkExportableSmrModel,
}, 'HasSmrModelLoader');

export default HasSmrModelLoader;
