import _ from 'underscore';

import { ApiException } from '../api/ApiException';
import { Maybe } from '../utils/typescript';

export const NO_DETAILS = 'No details are available.';

interface HasStackTrace {
  stackTrace: Array<string>,
}

export interface Details {
  json: ApiException,
  status: Maybe<number>,
  statusText: Maybe<string>,
  url: Maybe<string>,
}

// Finds the com.tamr lines in the trace and prints them all along with any preceding lines.
// If there are no com.tamr lines, prints the first 3 lines only.
// If the input is invalid, returns an empty string.
export const formatStackTrace = (exception: HasStackTrace): string => {
  if (!exception?.stackTrace) {
    return '';
  }
  const stackTrace = exception.stackTrace;
  const tamrIndex = _.findLastIndex(stackTrace, line => line.startsWith('com.tamr'));
  const rowsToPrint = Math.min(stackTrace.length, Math.max(tamrIndex + 1, 3));
  const rowsUnprinted = stackTrace.length - rowsToPrint;
  let message = '';
  stackTrace.slice(0, rowsToPrint).forEach(line => message += '\n | ' + line);
  if (rowsUnprinted > 0) {
    message += `\n - (${rowsUnprinted} more)`;
  }
  return message;
};

export const parseAdvancedDetails = (data: Details): string => {
  if (!data || !data.json || !data.json.message) {
    return NO_DETAILS;
  }

  // Wrong type, just return the message field
  if (!data.json.service) {
    return data.json.message;
  }

  const statusMessage = (data.status && data.statusText) ? `${data.status} ${data.statusText} ` : '';
  const target = data.url ? ` to ${data.url}` : '';
  const exceptions: Array<ApiException> = [];
  let e: Maybe<ApiException> = data.json;
  while (e) {
    exceptions.push(e);
    e = e.causedBy || undefined;
  }
  e = exceptions.pop();

  let message = `Tamr responded with an error ${statusMessage}while handling a request${target}.\n\nHere are the details:\n\n`;
  let lastService = null;
  while (e) {
    if (lastService === null) {
      message += `The ${e.service} service threw:\n`;
      message += ` ${e.message} at:${formatStackTrace(e)}`;
    } else if (e.service !== lastService) {
      message += `\n\nThe above error in ${lastService} caused the ${e.service} service to throw:\n`;
      message += ` ${e.message} at:${formatStackTrace(e)}`;
    } else {
      message += `\n\n Causing ${e.message} at:${formatStackTrace(e)}`;
    }
    lastService = e.service;
    e = exceptions.pop();
  }
  return message;
};

// Takes an API error response and formats a detailed error message.
// Accepts either jqXHR objects from $.ajax() or deserialized exception object so it can be used
// with fetch(), e.g., response.json().then(formatAdvancedDetails).
export const formatAdvancedDetails = (response: JQuery.jqXHR | ApiException) => {
  const xhr = response as JQuery.jqXHR;
  const ex = response as ApiException;
  if (!xhr && !ex) {
    return NO_DETAILS;
  }

  if (xhr.responseJSON) {
    return parseAdvancedDetails({
      json: xhr.responseJSON,
      status: xhr.status,
      statusText: xhr.statusText,
      url: undefined,
    });
  }

  if (ex.message) {
    return parseAdvancedDetails({
      json: ex,
      status: ex.status,
      statusText: undefined,
      url: undefined,
    });
  }

  if (xhr.responseText) {
    return xhr.responseText;
  }

  return NO_DETAILS;
};
