import classNames from 'classnames';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Input from '../components/Input/Input';
import ProjectTypes, {
  CLASSIFICATION,
  ENRICHMENT,
  GOLDEN_RECORDS,
  SCHEMA_MAPPING_RECOMMENDATIONS,
  SUPPLIER_MASTERING,
} from '../constants/ProjectTypes';
import CreateProjectDialogEnrichment from './CreateProjectDialogEnrichment';
import CreateProjectDialogGoldenRecords from './CreateProjectDialogGoldenRecords';
import EnableTransformations from './EnableTransformations';
import { CANCEL_ADDING_PROJECT } from './ProjectsActionTypes';
import { createProject } from './ProjectsApi';
import { PROJECT_DEFAULTS, PROJECT_TERMS } from './terms/ProjectTerms';
import TermInput from './terms/TermInput';
import ProjectPoliciesSelector, {
  FUTURE_PROJECT_ID, FUTURE_PROJECT_NAME,
} from './ProjectPoliciesSelector';
import { fetchAllPolicies } from '../accessControl/AccessControlAsync';
import ConditionalButton from '../components/ConditionalButton';
import { ProjectPolicyManager } from '../accessControl/AccessControlStore';
import { getAuthorizedUser } from '../utils/Selectors';
import MinimalAuthUser from '../models/MinimalAuthUser';
import { projectCreationPoliciesValid } from '../utils/Authorization';
// during project creation, hide these config options (should be available during editing)
const HIDDEN_TERM_KEYS = ['currencyChar', 'record', 'spend'];

export const getProjectTypeMessage = (projectType) => {
  return projectType === SUPPLIER_MASTERING ? 'Mastering' :
    projectType === SCHEMA_MAPPING_RECOMMENDATIONS ? 'Schema Mapping' : 'Categorization';
};

const CreateProjectDialog = _.compose(
  connect((state) => {
    const { projects: { addingProject }, accessControl: { projectDraftPolicyManager } } = state;
    return { addingProject, projectDraftPolicyManager, user: getAuthorizedUser(state) };
  }, {
    onCancelAddingProject: () => ({ type: CANCEL_ADDING_PROJECT }),
    onCancelEditingPolicies: () => ({ type: 'Projects.stopEditingPolicies' }),
    onCreate: createProject,
    onFetchPolicies: fetchAllPolicies,
  }),
)(class CreateProjectDialog extends React.Component {
  static propTypes = {
    addingProject: ProjectTypes.propType,
    onCancelAddingProject: PropTypes.func.isRequired,
    onCancelEditingPolicies: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    projectDraftPolicyManager: PropTypes.instanceOf(ProjectPolicyManager),
    user: PropTypes.instanceOf(MinimalAuthUser),
  };

  state = {
    type: SUPPLIER_MASTERING, // RecipeType
    name: undefined, // string name of new project
    description: undefined, // string description of new project
    terms: undefined, // map of term key -> Term, values are user-editable
    termDefaults: [], // list of default ProjectTerms for the current type
    enableTransformations: false,
  };
  // circumvent style / title flicker when hiding dialog
  UNSAFE_componentWillReceiveProps(newProps) {
    const { addingProject } = newProps;
    // when dialog starts to show, reset state to ensure no carryover values
    if (addingProject && !this.props.addingProject) {
      this.setState({
        type: SUPPLIER_MASTERING, // RecipeType
        name: '', // string name of new project
        description: '', // string description of new project
        terms: undefined, // map of term key -> Term, values are user-editable
        termDefaults: [], // list of default ProjectTerms for the current type
        enableTransformations: false,
      });
    }
    if (addingProject !== undefined && addingProject !== this.props.addingProject) {
      this.onTypeChange(addingProject);
    }
  }

  componentDidMount() {
    const { onFetchPolicies } = this.props;
    onFetchPolicies();
  }

  create = () => {
    const { name, description, terms, enableTransformations, projectDraftPolicyManager } = this.state;
    const { addingProject, onCreate } = this.props;
    onCreate({ type: addingProject, name, description, terms, enableTransformations, projectDraftPolicyManager });
  };

  onTypeChange = (type) => {
    const termDefaults = PROJECT_TERMS[type] || [];
    const terms = PROJECT_DEFAULTS[type] || {};
    this.setState({ terms, termDefaults, type });
  };

  onTermChange = (key, term) => {
    const { terms } = this.state;
    terms[key] = term;
    this.setState({ terms });
  };

  renderTerm = (projectTerm) => {
    return (
      <div key={projectTerm.key} className="term-input-container">
        <div>{projectTerm.desc}</div>
        <TermInput
          defaultTerm={projectTerm.term}
          onChange={_.partial(this.onTermChange, projectTerm.key)}
          hasPlural={projectTerm.hasPlural}
        />
      </div>
    );
  };

  render() {
    const { addingProject, onCancelAddingProject, onCancelEditingPolicies, projectDraftPolicyManager, user } = this.props;
    const { name, type, description, termDefaults, enableTransformations } = this.state;
    const containerCss = classNames('create-project-dialog', {
      categorization: addingProject === CLASSIFICATION,
      dedup: addingProject === SUPPLIER_MASTERING,
      'schema-mapping': addingProject === SCHEMA_MAPPING_RECOMMENDATIONS,
    });
    if (!!addingProject && addingProject === GOLDEN_RECORDS) return <CreateProjectDialogGoldenRecords />;
    if (!!addingProject && addingProject === ENRICHMENT) return <CreateProjectDialogEnrichment />;
    const show = !!addingProject;
    const projectTypeMessage = getProjectTypeMessage(type);
    return (
      <Dialog
        className={containerCss}
        show={!!show}
        onHide={() => { onCancelAddingProject(); onCancelEditingPolicies(); }}
        header={show ? (
          <div className="create-project-dialog-header">
            Start a new {projectTypeMessage} project
          </div>
        ) : null}
        body={show ? (
          <div className="create-project">
            <Input
              autoFocusOnMount
              onChange={(newName) => this.setState({ name: newName })}
              type="text"
              title="Project name"
              value={name}
            />
            <Input
              onChange={(newDescription) => this.setState({ description: newDescription })}
              type="text"
              title="Description"
              value={description}
            />
            <div className="terminology-config">
              {termDefaults.filter(pt => !HIDDEN_TERM_KEYS.includes(pt.key)).map(this.renderTerm)}
            </div>
            {addingProject === CLASSIFICATION || addingProject === SUPPLIER_MASTERING ? (
              <EnableTransformations
                enabled={enableTransformations}
                onChange={() => this.setState({ enableTransformations: !enableTransformations })}
              />
            ) : null}
            <div className="policies-title">Select project policies <div className="explainer-text">{user.admin ? '' : 'Project must be a resource of at least one policy'}</div></div>
            <div style={{ marginLeft: '10px' }}> <ProjectPoliciesSelector projectName={FUTURE_PROJECT_NAME} projectId={FUTURE_PROJECT_ID} rowClassName="policies-table-row" headerClassName="policies-table-header" /> </div>
          </div>) : null}
        footer={show ? (
          <ButtonToolbar>
            <Button buttonType="Secondary" onClick={() => { onCancelAddingProject(); onCancelEditingPolicies(); }}>Cancel</Button>
            <ConditionalButton
              tooltipPlacement="top"
              preconditions={Map({
                'Please enter a name for the project': name && name !== '',
                'Please add the project to a policy as a resource': projectCreationPoliciesValid(user, projectDraftPolicyManager),
              })}
              onClick={this.create}
              buttonType="Primary"
            >Create</ConditionalButton>
          </ButtonToolbar>
        ) : null}
        dialogStyle={DialogStyle.PRIMARY}
      />
    );
  }
});

export default CreateProjectDialog;
