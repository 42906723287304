import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Term from '../../components/Term';
import { history } from '../../utils/History';
import DualValueCard from '../DualValueCard';

const ClustersCard = _.compose(
  connect((state) => {
    const { dedupDashboard: { suggestedLabels: { total }, totalClusters }, clusterFeedbackStore } = state;
    return { numClusters: totalClusters, numPairs: total, numAssignments: clusterFeedbackStore.clusterAssignments.size };
  }, {
    onClearSuppliersFilter: () => ({ type: 'Suppliers.clearFilters' }),
    onClearSuppliersRecordFilter: () => ({ type: 'Suppliers.clearRecordFilters' }),
  }),
)(class ClustersCard extends React.Component {
  static propTypes = {
    dedupRecipeId: PropTypes.number.isRequired,
    numAssignments: PropTypes.number.isRequired,
    numClusters: PropTypes.number.isRequired,
    numPairs: PropTypes.number.isRequired,
    onClearSuppliersFilter: PropTypes.func.isRequired,
    onClearSuppliersRecordFilter: PropTypes.func.isRequired,
  };

  navigateToClusters = () => {
    const { dedupRecipeId, onClearSuppliersFilter, onClearSuppliersRecordFilter } = this.props;
    onClearSuppliersFilter();
    onClearSuppliersRecordFilter();
    history.push(`/suppliers/recipe/${dedupRecipeId}`);
  };

  render() {
    const { numPairs, numClusters } = this.props;
    return (
      <DualValueCard
        onClick={this.navigateToClusters}
        bottomNounPlural={<Term>Pairs</Term>}
        bottomNounSingular={<Term>Pair</Term>}
        bottomValue={numPairs}
        topNounPlural="clusters"
        topNounSingular="cluster"
        topValue={numClusters}
      />
    );
  }
});

export default ClustersCard;
