import { Record } from 'immutable';

import ConfusionMatrix from '../models/ConfusionMatrix';
import Model from '../models/Model';
import { ArgTypes } from '../utils/ArgValidation';
import { getUnifiedDatasetName } from '../utils/Selectors';
import { resetExcept } from '../utils/Values';

const FilterInfo = Record({
  fetchSequence: null,
  unifiedDatasetName: null,
});

export const getFilterInfo = state => {
  const { confusionMatrix: { fetchSequence } } = state;
  return new FilterInfo({ fetchSequence, unifiedDatasetName: getUnifiedDatasetName(state) });
};

export const initialState = new (Model({
  matrix: { type: ArgTypes.instanceOf(ConfusionMatrix), defaultValue: new ConfusionMatrix(0, 0, 0, 0) },
  manualLabels: { type: ArgTypes.number, defaultValue: 0 },
  visible: { type: ArgTypes.bool, defaultValue: false },
  fetchSequence: { type: ArgTypes.number, defaultValue: 0 },
  loadedFilterInfo: { type: ArgTypes.any },
  loading: { type: ArgTypes.bool, defaultValue: false },
}))();

const reload = (state) => state.update('fetchSequence', x => x + 1);

export const reducers = {
  'Location.projectChange': state => resetExcept(state, ['loading']),

  'ConfusionMatrix.reset': (state) => {
    return state.clear();
  },

  'ConfusionMatrix.fetch': (state) => {
    return state.set('loading', true);
  },

  'ConfusionMatrix.fetchCompleted': (state, { data, loadedFilterInfo }) => {
    return state.merge({
      matrix: ConfusionMatrix.fromJSON(data),
      manualLabels: data.manualLabels,
      loadedFilterInfo,
      loading: false,
    });
  },

  'ConfusionMatrix.show': (state) => {
    return state.set('visible', true);
  },

  'ConfusionMatrix.hide': (state) => {
    return state.set('visible', false);
  },

  'RecordPairs.respondAndVerifyCompleted': reload,
  'RecordPairs.removeResponseAndVerifyCompleted': reload,
  'Jobs.trainPredictClusterCompleted': reload,
};
