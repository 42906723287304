import { Map } from 'immutable';

import * as AuthClient from '../api/AuthClient';
import { SHOW } from '../errorDialog/ErrorDialogActionTypes';
import MinimalAuthUser from '../models/MinimalAuthUser';
import { AppThunkAction } from '../stores/AppAction';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import {
  LOGIN,
  LOGIN_COMPLETED,
  LOGIN_FAILED,
  LOGOUT_COMPLETED,
  SET_USER_PREFERENCES,
} from './AuthActionTypes';

export const login = (username: string, password: string): AppThunkAction<void> => async (dispatch) => {
  checkArg({ username }, ArgTypes.string);
  checkArg({ password }, ArgTypes.string);
  dispatch({ type: LOGIN });
  await AuthClient.postAuthLogin(username, password)
    .then((authorizedUser) => dispatch({ type: LOGIN_COMPLETED, authorizedUser }))
    .catch((response) => dispatch({ type: LOGIN_FAILED, response }));
};

export const logout = (): AppThunkAction<void> => async (dispatch) => {
  AuthClient.deleteAuthLogout().finally(() => {
    return dispatch({ type: LOGOUT_COMPLETED });
  });
};

/**
 * @param skipSavingToServer if true, only updates redux, but does not save this preference to the server
 *                           useful if user is currently editing the value, and we want to see the effects of that editing,
 *                           but we don't want to be chatty with the server.
 */
export const updateUserPreferences = (updatedUser: MinimalAuthUser, skipSavingToServer?: boolean): AppThunkAction<void> => (dispatch) => {
  dispatch({ type: SET_USER_PREFERENCES, preferences: updatedUser.preferences });
  if (!skipSavingToServer) {
    AuthClient.postUserPreference(updatedUser)
      .then((preferencesDoc) => {
        dispatch({
          type: SET_USER_PREFERENCES,
          preferences: Map(preferencesDoc.data.preferences as Map<any, any>),
        });
      }, response => {
        dispatch({ type: SHOW, detail: 'Error updating user preferences', response });
      });
  }
};
