import { ArgTypes, checkArg } from './ArgValidation';

const DOC_BASE_URL = 'https://docs.tamr.com';

function reconstructVersion(
  yearVersion: string,
  sprintVersion: string,
): string {
  return `${yearVersion}.${sprintVersion}`;
}

const getDocUrl = (version: string): string => {
  checkArg({ version }, ArgTypes.string);

  // strip out the "v" prefix in the version
  const sanitizedVersion = version.startsWith('v') ? version.substr(1) : version;
  const versionParts = sanitizedVersion.split('.');

  /**
   * The logic of this method changes as part of DEV-13997. Before changing this method, make
   * sure that you have read up on the ticket and understood the context.
   */

  if (versionParts.length === 3) {
    // this conforms to the release version scheme: [year].[sprint].[patch]

    const yearVersion = parseInt(versionParts[0], 10);

    // we are not planning to do anything special with the patch version since it's always 0
    // in doc URL

    const versionPrefix = 'new';

    // for the non-grouped-version URL, the sprintVersion still contains leading 0
    return `${DOC_BASE_URL}/${versionPrefix}/v${reconstructVersion(yearVersion.toString(), versionParts[1])}/`;
  }
  // non-standard version scheme, just returns the latest doc
  return `${DOC_BASE_URL}/new/`;
};

export default getDocUrl;
