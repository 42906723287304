import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import AssignmentStatusFilterType from '../AssignmentStatusFilterType';
import SelectFilter from './SelectFilter';

const { ALL, SKIP, PENDING, ANSWERED } = AssignmentStatusFilterType;

const NO_FILTER = 'NOFILTER';

const OPTIONS = [
  { value: NO_FILTER, display: 'No Filter' },
  { value: ALL, display: 'All' },
  { value: SKIP, display: 'Skipped' },
  { value: PENDING, display: 'Open' },
  { value: ANSWERED, display: 'Completed' },
];

const AssignedToMeFilter = _.compose(
  connect(({ recordPairs: { assignmentStatus } }) => {
    return { assignmentStatus };
  }, {
    onSetAssignmentStatusFilter: (assignmentStatus) => ({ type: 'RecordPairs.setAssignmentStatusFilter', assignmentStatus }),
  }),
)(class AssignedToMeFilter extends React.Component {
  static propTypes = {
    onSetAssignmentStatusFilter: PropTypes.func.isRequired,
  };

  render() {
    const { assignmentStatus, onSetAssignmentStatusFilter } = this.props;
    return (
      <SelectFilter
        onChange={value => onSetAssignmentStatusFilter(value === NO_FILTER ? undefined : value)}
        label="My assignments"
        values={OPTIONS}
        value={assignmentStatus || NO_FILTER}
      />
    );
  }
});

export default AssignedToMeFilter;
