import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import _ from 'underscore';

import CenterContent from '../components/CenterContent';
import ProgressBar from '../components/ProgressBar/ProgressBar';
import Term from '../components/Term';
import { history } from '../utils/History';
import { pluralize } from '../utils/Strings';
import style from './CategorizationDashboardAssignedToMeChart.module.scss';
import CategorizationDashboardCompletedPanel from './CategorizationDashboardCompletedPanel';

const navigate = (url, onResetTransactionsFilterState) => {
  if (_.isFunction(onResetTransactionsFilterState)) {
    onResetTransactionsFilterState();
  }
  history.push(url);
};

const CategorizationDashboardAssignedToMeChart = _.compose(
  connect(state => {
    const { location: { recipeId }, transactions: { feedbackSummaryNumAssigned, feedbackSummaryNumResponded } } = state;
    const feedbackSummaryNumOpen = feedbackSummaryNumAssigned - feedbackSummaryNumResponded;
    return { recipeId, feedbackSummaryNumResponded, feedbackSummaryNumOpen };
  }, {
    onResetTransactionsFilterState: () => ({ type: 'Transactions.resetFilters' }),
  }),
)(({ recipeId, feedbackSummaryNumOpen, feedbackSummaryNumResponded, onResetTransactionsFilterState }) => {
  const title = feedbackSummaryNumOpen > 0
    ? <span>{feedbackSummaryNumOpen} {pluralize(feedbackSummaryNumOpen, 'Needs', 'Need')} Your Categorization</span>
    : <span>No assignments to complete</span>;
  const graph = feedbackSummaryNumOpen > 0
    ? (
      <ReactCSSTransitionGroup
        transitionName={{
          appear: style.barTransitionAppear,
          appearActive: style.barTransitionAppearActive,
        }}
        transitionAppearTimeout={1000}
        transitionAppear
        transitionEnter={false}
        transitionLeave={false}
      >
        <ProgressBar className={style.answerProgress}>
          <ProgressBar
            fillClass={style.completedProgress}
            now={100 * feedbackSummaryNumResponded / (feedbackSummaryNumResponded + feedbackSummaryNumOpen)}
            onClick={() => navigate(`/spend/recipe/${recipeId}?assignedToMeComplete=true`, onResetTransactionsFilterState)}
          />
          <ProgressBar
            fillClass={style.openProgress}
            now={100 * feedbackSummaryNumOpen / (feedbackSummaryNumResponded + feedbackSummaryNumOpen)}
            onClick={() => navigate(`/spend/recipe/${recipeId}?assignedToMeToDo=true`, onResetTransactionsFilterState)}
          />
        </ProgressBar>
      </ReactCSSTransitionGroup>
    )
    : (
      <CategorizationDashboardCompletedPanel message={<span>Verify reviewers' assignments or categorize more <Term>records</Term> to continue.</span>} />
    );

  const legend = feedbackSummaryNumOpen > 0
    ? (
      <div className={style.legend}>
        <div className={style.rowWrapper}>
          <div className={style.legendRow}>
            <div className={classNames(style.legendBox, style.completed)} />
            <div className={style.legendText}>Your Completed Assignments <b>({feedbackSummaryNumResponded})</b></div>
          </div>
        </div>
        <div className={style.rowWrapper}>
          <div className={style.legendRow}>
            <div className={classNames(style.legendBox, style.open)} />
            <div className={style.legendText}>Your Open Assignments <b>({feedbackSummaryNumOpen})</b></div>
          </div>
        </div>
      </div>
    )
    : undefined;

  return (
    <div className={style.container}>
      <div className={style.title}>
        {title}
      </div>
      <div className={style.barContainer}>
        <CenterContent className={style.barCenter} horizontally={false}>
          {graph}
        </CenterContent>
      </div>
      {legend}
    </div>
  );
});

export default CategorizationDashboardAssignedToMeChart;
