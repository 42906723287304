import './ErrorDialog.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import TamrIcon from '../components/TamrIcon';
import ErrorDialogBody from './ErrorDialogBody';


const DEFAULT_TITLE = 'Something Went Wrong';

/**
 * Shows a generic dialog with information about an error
 */
export const ErrorDialog = ({ showDialog, showMore, title, detail, advancedDetail, onDismiss, onToggleShowMore, onCopy, copied }) => {
  return (
    <Dialog
      className="error-dialog"
      show={showDialog}
      onHide={onDismiss}
      header={(
        <div className="error-dialog-title">
          <TamrIcon className="warning-icon" iconName="tamr-icon-warning" size={20} />
          <span className="title-text">
            {title || DEFAULT_TITLE}
          </span>
        </div>
      )}
      dialogStyle={DialogStyle.PRIMARY}
      large
      body={detail ? (
        <ErrorDialogBody
          {...{ showMore, detail, advancedDetail, onToggleShowMore, onCopy, copied }}
        />
      ) : null}
      footer={
        <Button onClick={onDismiss}>Okay</Button>
      }
    />
  );
};
ErrorDialog.propTypes = {
  advancedDetail: PropTypes.string,
  copied: PropTypes.bool,
  detail: PropTypes.node,
  onCopy: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onToggleShowMore: PropTypes.func,
  showDialog: PropTypes.bool.isRequired,
  showMore: PropTypes.bool,
  title: PropTypes.string,
};
ErrorDialog.defaultProps = {
  showMore: false,
};

const ConnectedErrorDialog = connect((state) => {
  const { errorDialog: { showDialog, showMore, title, detail, advancedDetail, copied } } = state;
  return { showDialog, showMore, title, detail, advancedDetail, copied };
}, {
  onDismiss: () => ({ type: 'ErrorDialog.dismiss' }),
  onToggleShowMore: () => ({ type: 'ErrorDialog.toggleShowMore' }),
  onCopy: () => ({ type: 'ErrorDialog.copy' }),
})(ErrorDialog);

export default ConnectedErrorDialog;
