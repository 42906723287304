import _ from 'underscore';

import RecipeType from '../../constants/RecipeType';
import Model from '../../models/Model';
import { ArgTypes } from '../../utils/ArgValidation';
import Term from './Term';

/**
 * Defaults for the various keys to use if the user-specified values haven't loaded yet
 */
const DEFAULT_TERMS = {
  currencyChar: '$',
  record: 'record',
  spend: 'spend',
  part: 'part',
  supplier: 'supplier',
  pair: 'pair',
};

/**
 * Container for the various strings we need to configure a term in the UI
 * Only contains default values for terms
 */
class ProjectTerm extends Model({
  key: { type: ArgTypes.string },
  desc: { type: ArgTypes.string },
  hasPlural: { type: ArgTypes.bool },
  term: { type: ArgTypes.instanceOf(Term) },
}) {
  constructor(key, desc, hasPlural = true) {
    super({ key, desc, hasPlural, term: new Term(DEFAULT_TERMS[key]) });
  }
}

// Shared terms across both dedup & categorization - these are created & saved on the project
// to facilitate easy API access by FEs
const CURRENCY = new ProjectTerm('currencyChar', 'What currency are monetary values in?', false);
const RECORD = new ProjectTerm('record', 'How should we refer to a row in a dataset?');
const SPEND = new ProjectTerm('spend', 'What does the numerical analysis column contain?', false);

const PROJECT_TERMS = {
  [RecipeType.CATEGORIZATION]: [
    new ProjectTerm('part', 'What do the records in your dataset represent?'),
    CURRENCY,
    RECORD,
    SPEND,
  ],
  [RecipeType.DEDUP]: [
    new ProjectTerm('supplier', 'What are you mastering?'),
    new ProjectTerm('pair', 'What should we call potential duplicate records?'),
    CURRENCY,
    RECORD,
    SPEND,
  ],
  [RecipeType.SCHEMA_MAPPING_RECOMMENDATIONS]: [
    RECORD,
  ],
};

// Map of recipe type -> { term name: default term }
const PROJECT_DEFAULTS = _.mapObject(PROJECT_TERMS, terms => {
  return _.object(terms.map(t => [t.key, t.term]));
});

export {
  DEFAULT_TERMS,
  PROJECT_TERMS,
  PROJECT_DEFAULTS,
};
