import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import { fetchTransactions } from '../records/RecordsApi';
import { PREVIEW } from '../records/RowProviders';
import { updateList } from './TransformsApi';
import { ENABLE_ALL_TRANSFORMS } from './TransformsStore';

const ShowAllButton = _.compose(
  connect(({ transforms: { loadedDatasetId, transforms, deltas } }) => {
    let disabled = false;
    let tooltip = 'Preview all transformations';
    if (!loadedDatasetId) {
      tooltip = 'No dataset loaded';
      disabled = true;
    } else if (!transforms.size && !deltas.size) {
      tooltip = 'No transformations to preview';
      disabled = true;
    }
    return { disabled, tooltip };
  }, {
    onUsePreview: () => ({ type: 'Records.useRowProvider', provider: PREVIEW }),
    onSetCutoff: () => ({ type: 'Transforms.setPreviewCutoff' }),
    onSetCutoffHover: guid => ({ type: 'Transforms.setPreviewCutoffHover', guid }),
    onRefetch: fetchTransactions,
    onUpdateList: updateList,
  }),
)(({ onUpdateList, onSetCutoff, onSetCutoffHover, onUsePreview, onRefetch, ...props }) => {
  const refreshFunction = () => {
    onSetCutoff();
    onUpdateList(); // ensure any static analysis errors are displayed
    onUsePreview();
    onRefetch();
  };
  return (
    <Button {...props} buttonType="Secondary" onClick={refreshFunction} onMouseEnter={() => onSetCutoffHover(ENABLE_ALL_TRANSFORMS)} onMouseLeave={() => onSetCutoffHover()}>
      Preview all
    </Button>
  );
});

export default ShowAllButton;
