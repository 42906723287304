import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import ConfusionMatrixQuadrant, { ConfusionMatrixQuadrantE } from '../constants/ConfusionMatrixQuadrant';
import AppState from '../stores/AppState';
import { AppDispatch } from '../stores/MainStore';
import { getFloat } from '../utils/Numbers';
import styles from './ConfusionMatrix.module.scss';
import { getActiveConfusionMatrixFilter } from './RecordPairsStore';

const MAX_METRIC_SIZE = 50;
const MIN_METRIC_SIZE = 20;

const linearScale = (x: number, max: number, min: number, a: number, b: number): number => {
  return getFloat(((b - a) * (x - min)) / (max - min)) + a;
};

function toSize(x: number, min: number, max: number): number {
  return max > min
    ? linearScale(x, max, min, MIN_METRIC_SIZE, MAX_METRIC_SIZE)
    : MIN_METRIC_SIZE;
}

const Circle: React.FC<{
  metric: number,
  size: number
  type: 'light' | 'dark',
}> = ({
  metric,
  type,
  size,
}) => {
  const style = (type === 'light') ? styles.circleLight : styles.circleDark;
  return <div className={style} style={{ height: size, width: size }}>{metric}</div>;
};

const mapState = (state: AppState) => {
  const {
    truePositives,
    trueNegatives,
    falsePositives,
    falseNegatives,
  } = state.confusionMatrix.matrix;
  const activeConfusionMatrixFilter = getActiveConfusionMatrixFilter(state.recordPairs);
  return {
    activeConfusionMatrixFilter,
    truePositives: (truePositives as number),
    trueNegatives: (trueNegatives as number),
    falsePositives: (falsePositives as number),
    falseNegatives: (falseNegatives as number),
  };
};

const mapDispatch = (dispatch: AppDispatch) => {
  return {
    toggleFilter: (quadrant: ConfusionMatrixQuadrantE) => dispatch({ type: 'RecordPairs.toggleConfusionMatrixFilter', quadrant }),
  };
};

type StateProps = ReturnType<typeof mapState>
type DispatchProps = ReturnType<typeof mapDispatch>
type Props = StateProps & DispatchProps

const ConfusionMatrix: React.FC<Props> = ({
  activeConfusionMatrixFilter,
  truePositives,
  trueNegatives,
  falsePositives,
  falseNegatives,
  toggleFilter,
}) => {
  const metrics = [trueNegatives, truePositives, falseNegatives, falsePositives];
  const max = _.max(metrics) ?? metrics[0];
  const min = _.min(metrics) ?? metrics[0];

  const toCircleProps = (metric: number) => {
    return {
      size: toSize(metric, min, max),
      metric,
    };
  };

  return (
    <div>
      <div className={styles.flex}>
        <div className={styles.spacer} />
        <div>
          <div className={styles.cellA}>All verified responses</div>
          <div className={styles.flex}>
            <div className={styles.cellB1}>Match</div>
            <div className={styles.cellB2}>No match</div>
          </div>
        </div>
      </div>
      <div className={styles.flex}>
        <div className={styles.cellC}>Tamr's<br />responses</div>
        <div>
          <div className={styles.flex}>
            <div className={styles.cellD1}>Match</div>
            <div
              className={classNames(styles.cellE1, { [styles.selected]: activeConfusionMatrixFilter === ConfusionMatrixQuadrant.TRUE_POSITIVES })}
              onClick={() => toggleFilter(ConfusionMatrixQuadrant.TRUE_POSITIVES)}
            >
              <Circle type="light" {...toCircleProps(truePositives)} />
            </div>
            <div
              className={classNames(styles.cellE2, { [styles.selected]: activeConfusionMatrixFilter === ConfusionMatrixQuadrant.FALSE_POSITIVES })}
              onClick={() => toggleFilter(ConfusionMatrixQuadrant.FALSE_POSITIVES)}
            >
              <Circle type="dark" {...toCircleProps(falsePositives)} />
            </div>
          </div>
          <div className={styles.flex}>
            <div className={styles.cellD2}>No match</div>
            <div
              className={classNames(styles.cellE3, { [styles.selected]: activeConfusionMatrixFilter === ConfusionMatrixQuadrant.FALSE_NEGATIVES })}
              onClick={() => toggleFilter(ConfusionMatrixQuadrant.FALSE_NEGATIVES)}
            >
              <Circle type="dark" {...toCircleProps(falseNegatives)} />
            </div>
            <div
              className={classNames(styles.cellE4, { [styles.selected]: activeConfusionMatrixFilter === ConfusionMatrixQuadrant.TRUE_NEGATIVES })}
              onClick={() => toggleFilter(ConfusionMatrixQuadrant.TRUE_NEGATIVES)}
            >
              <Circle type="light" {...toCircleProps(trueNegatives)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapState, mapDispatch)(ConfusionMatrix);
