import { ProjectPolicyManager } from '../accessControl/AccessControlStore';
import Privileges from '../constants/Privileges';
import AuthUser from '../models/AuthUser';
import MinimalAuthUser from '../models/MinimalAuthUser';
import PrivilegeSpec from '../models/PrivilegeSpec';
import ResourceSpec from '../models/ResourceSpec';
import { ArgTypes, checkArg } from './ArgValidation';

/**
 * Utility methods to help with authorization on the client tier
 */

const userType = ArgTypes.oneOf(ArgTypes.instanceOf(AuthUser), ArgTypes.instanceOf(MinimalAuthUser));
type UserType = AuthUser | MinimalAuthUser;

export const isAdmin = (authUser: UserType | null | undefined): boolean => {
  checkArg({ authUser }, ArgTypes.nullable(userType));
  if (!authUser) {
    return false;
  }
  return authUser.admin;
};

export const hasPermission = ({
  user,
  privilege,
  resource,
}: {
  user: UserType,
  privilege: PrivilegeSpec,
  resource: ResourceSpec,
}): boolean => {
  checkArg({ user }, userType);
  checkArg({ privilege }, PrivilegeSpec.argType);
  checkArg({ resource }, ResourceSpec.argType);
  return isAdmin(user) || user.privilegesToResources
    .filter((resourceSpecs, privilegeSpec) => privilegeSpec.matches(privilege))
    .valueSeq()
    .flatten(1)
    .some(resourceSpec => resourceSpec.matches(resource));
};

/**
 * Checks if a user has policy update dataset permissions for at least one policy
 */
export const canUpdateDatasetAtLeastOnePolicy = ({
  user,
}: {
  user: UserType | undefined,
}): boolean => {
  if (!user) {
    return false;
  }
  checkArg({ user }, userType);
  const anyUpdatablePolicies = user.privilegesToResources.some(
    (resourceSpecs, privilegeSpec) => privilegeSpec.matches(new PrivilegeSpec(Privileges.POLICY_UPDATE))
      || privilegeSpec.matches(new PrivilegeSpec(Privileges.POLICY_UPDATE_DATASET_RESOURCE)));
  return isAdmin(user) || anyUpdatablePolicies;
};

/**
 * Checks if a user has policy update project permissions for at least one policy
 * This is expected to be true of Authors and admins
 */
export const canUpdateProjectAtLeastOnePolicy = ({
  user,
}: {
  user: UserType | undefined,
}): boolean => {
  if (!user) {
    return false;
  }
  checkArg({ user }, userType);
  const anyUpdatablePolicies = user.privilegesToResources.some(
    (resourceSpecs, privilegeSpec) => {
      return privilegeSpec.matches(new PrivilegeSpec(Privileges.POLICY_UPDATE))
        || privilegeSpec.matches(new PrivilegeSpec(Privileges.POLICY_UPDATE_RESOURCE_PROJECT))
        || privilegeSpec.matches(new PrivilegeSpec(Privileges.POLICY_UPDATE_MEMBER_PROJECT));
    });
  return isAdmin(user) || anyUpdatablePolicies;
};


/**
 * To create a project you must be an Author or Admin, having the project create permission and policy update permissions
 */
export const canCreateProjects = (user: MinimalAuthUser | undefined) => {
  if (!user) {
    return false;
  }
  return user.admin || (
    canUpdateProjectAtLeastOnePolicy({ user }) &&
    user.privilegesToResources.some(
      (resourceSpecs, privilegeSpec) => privilegeSpec.matches(new PrivilegeSpec(Privileges.PROJECT_CREATE)))
  );
};

/**
 * Non-admins must select at least 1 policy for a new project to be a resource within
 * policy membership is not required
 */
export const projectCreationPoliciesValid = (
  user: MinimalAuthUser | undefined,
  projectDraftPolicyManager: ProjectPolicyManager | undefined | null) => {
  if (!user) {
    return false;
  }
  if (user.admin) {
    return true;
  }
  if (!projectDraftPolicyManager) {
    return false;
  }
  return projectDraftPolicyManager.draftPolicyResourceship.size > 0;
};

/**
 * Is the specified user an admin and curator which is required to edit/delete project
 */
export const isAdminOrCurator = (user: UserType, projectId: number | null | undefined): boolean => {
  checkArg({ user }, userType);
  checkArg({ projectId }, ArgTypes.nullable(ArgTypes.number));
  if (!projectId || !user) {
    return false;
  }
  return hasPermission({
    user,
    privilege: new PrivilegeSpec(Privileges.PROJECT_RUN),
    resource: new ResourceSpec(`projects/${projectId}`),
  });
};

/**
 * Is the specified user a reviewer (including admin, curator and verifier) in the specified project id
 */
export const isReviewerByProjectId = (user: UserType, projectId: number | null | undefined): boolean => {
  checkArg({ user }, userType);
  checkArg({ projectId }, ArgTypes.nullable(ArgTypes.number));
  if (!projectId || !user) {
    return false;
  }
  return hasPermission({
    user,
    privilege: new PrivilegeSpec(Privileges.PROJECT_READ),
    resource: new ResourceSpec(`projects/${projectId}`),
  });
};

/**
 * Is the specified user a verifier (including admin and curator) in the specified project id
 */
export const isVerifierByProjectId = (user: UserType | undefined, projectId: number | null | undefined): boolean => {
  checkArg({ user }, userType);
  checkArg({ projectId }, ArgTypes.nullable(ArgTypes.number));
  if (!projectId || !user) {
    return false;
  }
  return hasPermission({
    user,
    privilege: new PrivilegeSpec(Privileges.PROJECT_READ),
    resource: new ResourceSpec(`projects/${projectId}`),
  }) && hasPermission({
    user,
    privilege: new PrivilegeSpec(Privileges.PROJECT_UPDATE_VERIFY),
    resource: new ResourceSpec(`projects/${projectId}`),
  });
};


/**
 * Is the specified user a curator (including admin) in the specified project id
 */
export const isCuratorByProjectId = (user: UserType | undefined, projectId: number | null | undefined): boolean => {
  checkArg({ user }, userType);
  checkArg({ projectId }, ArgTypes.nullable(ArgTypes.number));
  if (!projectId || !user) {
    return false;
  }
  return hasPermission({
    user,
    privilege: new PrivilegeSpec(Privileges.PROJECT_RUN),
    resource: new ResourceSpec(`projects/${projectId}`),
  });
};

/**
 * Is the specified user a curator (including admin) in the specified project id
 */
export const isAuthorByProjectId = (user: UserType | undefined, projectId: number | null | undefined): boolean => {
  checkArg({ user }, userType);
  checkArg({ projectId }, ArgTypes.nullable(ArgTypes.number));
  if (!projectId || !user) {
    return false;
  }
  return hasPermission({
    user,
    privilege: new PrivilegeSpec(Privileges.PROJECT_CREATE),
    resource: new ResourceSpec(`projects/${projectId}`),
  });
};

/**
 * Is the specified user associated with the project (curator or reviewer)
 */
export const inProject = (authorizedUser: UserType, projectId: number | null | undefined) => {
  checkArg({ authorizedUser }, userType);
  checkArg({ projectId }, ArgTypes.nullable(ArgTypes.number));
  return authorizedUser.username !== 'system' && !authorizedUser.user.deactivated &&
    (isCuratorByProjectId(authorizedUser, projectId) || isVerifierByProjectId(authorizedUser, projectId) || isReviewerByProjectId(authorizedUser, projectId));
};
