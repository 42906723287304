// com.tamr.auth.MinimalAuthUser

import { List, Map, Set } from 'immutable';
import { isArray, isNumber } from 'underscore';

import modelJSON from '../../../../../connect/auth/models/src/main/resources/fixtures/models/MinimalAuthUser.json';
import DataTables, { DataTablesType, isDataTablesType } from '../constants/DataTables';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { $TSFixMe, Dictionary } from '../utils/typescript';
import { filterToConformToType, isArrayOf, isObject } from '../utils/Values';
import { DisplayColumnPartialI, isDisplayColumnPartialI } from './DisplayColumn';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';
import PrivilegeSpec from './PrivilegeSpec';
import ResourceSpec from './ResourceSpec';
import User from './User';


/**
 * All "Page Preferences", stored under user.data.columnsPreferences (unfortunate name, but need to keep for back-compat)
 * have at least this form, plus some page-specific
 */
interface AbstractPagePreferences {
  page: DataTablesType
  recipeId: number
  [key: string]: unknown
}

/**
 * For pages that have tables, we store in the user preferences page preference objects that have a
 *   "columnsPreferences" field.
 * That's where we store the table column preferences.
 * These are stored as {@link DisplayColumnPartialI} - not all column settings are required to be stored.
 */
interface PagePreferencesWithColumnPrefs extends AbstractPagePreferences {
  columnsPreferences: DisplayColumnPartialI[]
}

function isAbstractPagePreferences(data: unknown): data is AbstractPagePreferences {
  if (!isObject(data)) return false;
  if (!isDataTablesType(data.page)) return false;
  if (!isNumber(data.recipeId)) return false;
  return true;
}

function isPagePreferencesWithColumnPrefs(data: unknown): data is PagePreferencesWithColumnPrefs {
  if (!isAbstractPagePreferences(data)) return false;
  if (!isArrayOf(data.columnsPreferences, isDisplayColumnPartialI)) return false;
  return true;
}

class MinimalAuthUser extends getModelHelpers({
  username: { type: ArgTypes.string },
  user: { type: ArgTypes.instanceOf(User) },
  groups: { type: ArgTypes.Immutable.set.of(ArgTypes.string) },
  privilegesToResources: { type: ArgTypes.Immutable.map.of(ArgTypes.Immutable.set.of(ResourceSpec.argType), PrivilegeSpec.argType) },
  preferences: { type: ArgTypes.Immutable.map },
  statistics: { type: ArgTypes.Immutable.map },
  volatileStatistics: { type: ArgTypes.Immutable.map },
  admin: { type: ArgTypes.bool },
}, 'MinimalAuthUser')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class MinimalAuthUserRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(data: $TSFixMe) {
    checkArg({ data }, ArgTypes.object);
    return new MinimalAuthUser({
      username: data.username,
      user: new User(data.user),
      groups: Set(data.groups),
      // @ts-expect-error
      privilegesToResources: Map(data.privilegesToResources).mapEntries(([privilegeSpec, resourceSpecSet]) => [PrivilegeSpec.fromJSON(privilegeSpec), Set(resourceSpecSet).map(resourceSpec => ResourceSpec.fromJSON(resourceSpec))]),
      preferences: Map(data.preferences),
      statistics: Map(data.statistics),
      volatileStatistics: Map(data.volatileStatistics),
      admin: data.admin,
    });
  }

  get loginTime() {
    return this.statistics.get('loginTime');
  }

  /**
   * Reach into the untyped preferences object, passing back a validated array of {@link AbstractPagePreferences}.
   * Stored objects in user preferences that do not conform to this type validation will be filtered out.
   */
  get pagePreferences(): AbstractPagePreferences[] {
    let prefs: AbstractPagePreferences[] = [];
    const storedPreferences: unknown = this.preferences.get('columnsPreferences');
    const asArray = List.isList(storedPreferences) ? storedPreferences.toArray() : storedPreferences;
    if (isArray(asArray)) {
      // we validate here that the untyped objects stored in the user preferences conform to our expected type
      prefs = filterToConformToType(
        asArray,
        isAbstractPagePreferences,
        (pref) => console.warn('Page preferences in stored user preferences not in expected format, filtering out. Found:', pref),
      );
    }
    return prefs;
  }

  preferencesForPage(pageName: DataTablesType, recipeId: number): AbstractPagePreferences | undefined {
    checkArg({ pageName }, DataTables.argType); // TODO "DataTables" is used for page level preferences as well
    checkArg({ recipeId }, ArgTypes.number);
    return this.pagePreferences.find(p => p.recipeId === recipeId && p.page === pageName);
  }

  columnPreferencesForPage(pageName: DataTablesType, recipeId: number): DisplayColumnPartialI[] | undefined {
    const pagePreferences = this.preferencesForPage(pageName, recipeId);
    if (isPagePreferencesWithColumnPrefs(pagePreferences)) {
      return pagePreferences.columnsPreferences;
    }
    // this preference either doesn't exist stored in the user metadata, or it does not conform to expected type
    return undefined;
  }

  updatePagePrefs(pagePrefs: { recipeId: number, page: DataTablesType, columnsPreferences: $TSFixMe }) {
    const { recipeId, page } = pagePrefs;
    return this.updateIn(['preferences', 'columnsPreferences'], prefs => {
      return List(prefs) // empty list if undefined, listify if array, noop if already a List
        .filter((p: $TSFixMe) => !(p.recipeId === recipeId && p.page === page))
        .push(pagePrefs);
    });
  }

  updateModulePrefs(modulePrefs: { moduleId: number }) {
    checkArg({ modulePrefs }, ArgTypes.object.withShape({ moduleId: ArgTypes.positiveInteger }));
    const { moduleId } = modulePrefs;
    return this.updateIn(['preferences', 'modulePreferences'], prefs => List(prefs)
      .filter((p: $TSFixMe) => !(p.moduleId === moduleId)) // remove existing prefs for this module
      .push(modulePrefs));
  }

  updateGlobalPrefs(newPrefs: $TSFixMe) {
    checkArg({ newPrefs }, ArgTypes.object);
    return this.updateIn(['preferences', 'globalPreferences'], existingPrefs =>
      Map(existingPrefs) // empty map if undefined
        .merge(newPrefs),
    );
  }

  get globalPrefs() {
    return Map(this.getIn(['preferences', 'globalPreferences']));
  }

  preferencesForModuleById(moduleId: number): Dictionary<unknown> | undefined {
    checkArg({ moduleId }, ArgTypes.positiveInteger);
    return List(this.preferences?.get('modulePreferences') as Dictionary<unknown>[])
      .find((prefs: $TSFixMe) => prefs.moduleId === moduleId);
  }
}

export const sampleMinimalAuthUser = MinimalAuthUser.fromJSON(modelJSON);

export default MinimalAuthUser;
