import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

export default class Taxonomy extends getModelHelpers({
  name: { type: ArgTypes.string },
  createdBy: { type: ArgTypes.string },
  createdAt: { type: ArgTypes.number },
  modifiedBy: { type: ArgTypes.string },
  modifiedAt: { type: ArgTypes.number },

}, 'Taxonomy')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class TaxonomyRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  static fromJSON(obj: $TSFixMe) {
    return new Taxonomy({
      name: obj.name,
      createdBy: obj.createdBy,
      createdAt: obj.createdAt,
      modifiedBy: obj.modifiedBy,
      modifiedAt: obj.modifiedAt,
    });
  }
}
