import { List, Map, OrderedSet, Set } from 'immutable';

import Dataset from '../models/Dataset';
import DatasetStatus from '../models/DatasetStatus';
import Document from '../models/doc/Document';
import Export from '../models/Export';
import KeyMods from '../models/KeyMods';
import ProfilingInfo from '../models/ProfilingInfo';
import { $TSFixMe } from '../utils/typescript';
import { ProjectDatasetCatalogFilterInfo } from './ProjectDatasetCatalogStore';

export const RELOAD = 'ProjectDatasetCatalog.reload';
export const FETCH = 'ProjectDatasetCatalog.fetch';
export const FETCH_COMPLETED = 'ProjectDatasetCatalog.fetchCompleted';
export const SET_PAGE = 'ProjectDatasetCatalog.setPage';
export const SET_PAGE_SIZE = 'ProjectDatasetCatalog.setPageSize';
export const SET_SEARCH_STRING = 'ProjectDatasetCatalog.setSearchString';
export const SET_SHOWING_SOURCE = 'ProjectDatasetCatalog.setShowingSource';
export const SET_SHOWING_DERIVED = 'ProjectDatasetCatalog.setShowingDerived';
export const BEGIN_MANAGING_MEMBERSHIP = 'ProjectDatasetCatalog.beginManagingMembership';
export const RESET_MEMBERSHIP_MANAGEMENT = 'ProjectDatasetCatalog.resetMembershipManagement';
export const TOGGLE_SHOW_UPLOAD = 'ProjectDatasetCatalog.toggleShowUpload';
export const REMOVE_DATASETS_FROM_PROJECT = 'ProjectDatasetCatalog.removeDatasetsFromProject';
export const REMOVE_DATASETS_FROM_PROJECT_COMPLETED =
  'ProjectDatasetCatalog.removeDatasetsFromProjectCompleted';
export const SUBMIT_MEMBERSHIP_CHANGES_COMPLETED =
  'ProjectDatasetCatalog.submitMembershipChangesCompleted';
export const SUBMIT_MEMBERSHIP_CHANGES_FAILED =
  'ProjectDatasetCatalog.submitMembershipChangesFailed';
export const CONFIRM_REMOVE_DATASETS = 'ProjectDatasetCatalog.confirmRemoveDatasets';
export const CANCEL_REMOVING_DATASETS = 'ProjectDatasetCatalog.cancelRemovingDatasets';
export const SELECT_ROWS = 'ProjectDatasetCatalog.selectRows';
export const CLICK_ROWS = 'ProjectDatasetCatalog.clickRows';

interface ProjectDatasetCatalogReload {
  type: typeof RELOAD
}

interface ProjectDatasetCatalogFetch {
  type: typeof FETCH
}

interface ProjectDatasetCatalogFetchCompleted {
  type: typeof FETCH_COMPLETED
  datasets: List<Document<Dataset>>
  noDatasetsInProject: boolean
  numDatasets: number
  numPages: number
  loadedFilterInfo: ProjectDatasetCatalogFilterInfo
  profiling: Map<string, ProfilingInfo>
  exporting: Map<number, Document<Export>>
  status: Map<string, DatasetStatus>
}

interface ProjectDatasetCatalogSetPage {
  type: typeof SET_PAGE
  pageNum: number
}

interface ProjectDatasetCatalogSetPageSize {
  type: typeof SET_PAGE_SIZE
  pageSize: number
}

interface ProjectDatasetCatalogSetSearchString {
  type: typeof SET_SEARCH_STRING
  search: string
}

interface ProjectDatasetCatalogSetShowingSource {
  type: typeof SET_SHOWING_SOURCE
  showing: boolean
}

interface ProjectDatasetCatalogSetShowingDerived {
  type: typeof SET_SHOWING_DERIVED
  showing: boolean
}

interface ProjectDatasetCatalogBeginManagingMembership {
  type: typeof BEGIN_MANAGING_MEMBERSHIP
}

interface ProjectDatasetCatalogResetMembershipManagement {
  type: typeof RESET_MEMBERSHIP_MANAGEMENT
}

interface ProjectDatasetCatalogToggleShowUpload {
  type: typeof TOGGLE_SHOW_UPLOAD
}

interface RemoveDatasetFromProject {
  type: typeof REMOVE_DATASETS_FROM_PROJECT
  datasetNames: Set<string>
}

interface RemoveDatasetFromProjectCompleted {
  type: typeof REMOVE_DATASETS_FROM_PROJECT_COMPLETED
}

interface SubmitMembershipChangesCompleted {
  type: typeof SUBMIT_MEMBERSHIP_CHANGES_COMPLETED
}

interface ProjectDatasetCatalogSubmitMembershipChangesFailed {
  type: typeof SUBMIT_MEMBERSHIP_CHANGES_FAILED
  detail: $TSFixMe
  response: $TSFixMe
}

interface ProjectDatasetCatalogConfirmRemoveDatasets {
  type: typeof CONFIRM_REMOVE_DATASETS
  datasetNames: Set<string>
}

interface ProjectDatasetCatalogCancelRemovingDatasets {
  type: typeof CANCEL_REMOVING_DATASETS
}

interface ProjectDatasetCatalogSelectRows {
  type: typeof SELECT_ROWS
  selectedRows: OrderedSet<number>
}

interface ProjectDatasetCatalogClickRows {
  type: typeof CLICK_ROWS
  keyMods: KeyMods
  rowNum: number
}

export type ProjectDatasetCatalogActionTypes
  = ProjectDatasetCatalogReload
  | ProjectDatasetCatalogFetch
  | ProjectDatasetCatalogFetchCompleted
  | ProjectDatasetCatalogSetPage
  | ProjectDatasetCatalogSetPageSize
  | ProjectDatasetCatalogSetSearchString
  | ProjectDatasetCatalogSetShowingSource
  | ProjectDatasetCatalogSetShowingDerived
  | ProjectDatasetCatalogBeginManagingMembership
  | ProjectDatasetCatalogResetMembershipManagement
  | ProjectDatasetCatalogToggleShowUpload
  | RemoveDatasetFromProject
  | RemoveDatasetFromProjectCompleted
  | SubmitMembershipChangesCompleted
  | ProjectDatasetCatalogConfirmRemoveDatasets
  | ProjectDatasetCatalogCancelRemovingDatasets
  | ProjectDatasetCatalogSelectRows
  | ProjectDatasetCatalogClickRows
  | ProjectDatasetCatalogSubmitMembershipChangesFailed
