import classNames from 'classnames';
import React from 'react';

import style from './FilterSection.module.scss';


const FilterSection: React.FC<{
  children: React.ReactNode
  className?: string
  titleLabel: string
  linkContent?: React.ReactNode
}> = ({ children, className, titleLabel, linkContent }) => {
  return (
    <div className={classNames(style.filterPanelSection, className)}>
      <div className={style.titleRow}>
        <div className={style.title}>{titleLabel}</div>
        {linkContent && (
          <div className={style.link}>{linkContent}</div>
        )}
      </div>
      <div className={style.content}>{children}</div>
      <div className={style.clear} />
    </div>
  );
};

export default FilterSection;
