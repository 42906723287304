import { RecordType } from '../transforms/models/Types';
import { ArgTypes } from '../utils/ArgValidation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';
import * as Table from './Table';

class TypedTable extends getModelHelpers({
  type: { type: RecordType.argType },
  data: { type: Table.argType },
}, 'TypedTable')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class TypedTableRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON(obj: any) {
    const { type, data } = obj;
    return new TypedTable({
      type: RecordType.fromJSON(type),
      data: Table.fromJSON(data),
    });
  }
}

export default TypedTable;
