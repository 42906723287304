import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import ActivitySidebar from '../components/ActivitySidebarTab';
import CenterContent from '../components/CenterContent';
import DetailSidebar from '../components/DetailSidebar/DetailSidebar';
import DetailSidebarTabbedContent from '../components/DetailSidebar/DetailSidebarTabbedContent';
import Term from '../components/Term';
import SidebarTabs from '../constants/SidebarTabs';
import { AppState } from '../stores/MainStore';
import { $TSFixMe } from '../utils/typescript';
import PairsCommentList from './PairsCommentList';
import { comment } from './RecordPairsAsync';
import RecordPairsSidebarDetailsTab from './RecordPairsSidebarDetailsTab';
import { getActivePair } from './RecordPairsStore';


const mapStateToProps = ({ recordPairs, recordPairs: { activeKey, commentFocusSequence, pairs, isSidebarExpanded } }: AppState) => {
  return { activeKey, commentFocusSequence, pair: getActivePair(recordPairs), pairs, isExpanded: isSidebarExpanded };
};

const mapDispatchToProps = {
  doComment: comment,
  setTab: (tabKey: $TSFixMe) => ({ type: 'RecordPairs.showSidebarTab', tabKey }),
};

type RecordPairsSidebarProps
  = ReturnType<typeof mapStateToProps>
  & typeof mapDispatchToProps

const RecordPairsSidebar = connect(
  mapStateToProps,
  mapDispatchToProps,
)(class RecordPairsSidebar extends React.Component<RecordPairsSidebarProps> {
  renderCommentsList = () => {
    const { pair } = this.props;
    if (pair) {
      return <PairsCommentList pair={pair} />;
    }
  };

  renderActivityTab = () => {
    return (
      <ActivitySidebar
        ref="activitySidebar"
        commentsList={this.renderCommentsList()}
        onAddComment={this.props.doComment}
        focusSequence={this.props.commentFocusSequence}
      />
    );
  };

  render() {
    const { activeKey, pair, isExpanded, setTab } = this.props;
    let content;
    if (pair && isExpanded) {
      content = (
        <DetailSidebarTabbedContent
          activeKey={activeKey}
          onSelect={setTab}
          tabs={
            List([
              {
                eventKey: SidebarTabs.DETAILS,
                title: 'Details',
                content: <RecordPairsSidebarDetailsTab />,
              },
              {
                eventKey: SidebarTabs.ACTIVITY,
                title: 'Comments',
                content: this.renderActivityTab(),
              },
            ])
          }
        />
      );
    } else {
      content = <CenterContent className="no-content"><div>No <Term>pair</Term> selected</div></CenterContent>;
    }
    return (
      <DetailSidebar
        className="pairs-sidebar"
        isExpanded={isExpanded}
      >
        {content}
      </DetailSidebar>
    );
  }
});

export default RecordPairsSidebar;
