import createLoader from '../utils/createLoader';
import { fetchDataset } from './GroupByApi';

const GroupByLoader = createLoader(state => {
  const { groupBy: { loadedDatasetId, loading, datasetId } } = state;
  const shouldFetch = loadedDatasetId !== datasetId;
  return {
    canFetch: true, // no preconditions
    shouldFetch,
    loading,
  };
}, {
  onFetch: fetchDataset,
}, 'GroupByLoader');

export default GroupByLoader;
