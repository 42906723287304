// com.tamr.recipe.models.flows.goldenRecords.ChoosingAggregationFunction

const ChoosingAggregationFunction = {
  MAX: 'MAX',
  MIN: 'MIN',
  MODE: 'MODE',
} as const;
export type ChoosingAggregationFunctionE = typeof ChoosingAggregationFunction[keyof typeof ChoosingAggregationFunction];

export default ChoosingAggregationFunction;
