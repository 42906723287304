import React from 'react';
import { connect } from 'react-redux';

import { AppAction } from '../../stores/AppAction';
import AppState from '../../stores/AppState';
import CheckboxFilter from './CheckboxFilter';


const HasResponsesFilter = connect(({ recordPairs: { hasResponses } }: AppState) => {
  return { hasResponses };
}, {
  onSetHasResponsesFilter: (hasResponses: boolean): AppAction => ({ type: 'RecordPairs.setHasResponsesFilter', hasResponses }),
})(({ hasResponses, onSetHasResponsesFilter }) => {
  return (
    <CheckboxFilter
      onChange={onSetHasResponsesFilter}
      title={<span>Has expert responses</span>}
      value={hasResponses}
    />
  );
});

export default HasResponsesFilter;
