import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ConditionalButton from '../components/ConditionalButton';
import Toolbar from '../components/Toolbar';
import { AppState } from '../stores/MainStore';
import { TOGGLE_SIDEBAR } from './GoldenRecordsActionTypes';
import { addBookmarksForSelectedRows, removeBookmarksForSelectedRows } from './GoldenRecordsAsync';
import GoldenRecordsFilterPanelButton from './GoldenRecordsFilterPanelButton';
import style from './GoldenRecordsTaskbar.module.scss';


const GoldenRecordsTaskbar = connect((state: AppState) => {
  const { goldenRecords: { selectedRowIndices, showSidebar } } = state;
  return {
    showSidebar,
    selectedRowIndices,
  };
}, {
  toggleSidebar: () => ({ type: TOGGLE_SIDEBAR }),
  addBookmarks: addBookmarksForSelectedRows,
  removeBookmarks: removeBookmarksForSelectedRows,
})(({ selectedRowIndices, addBookmarks, removeBookmarks, showSidebar, toggleSidebar }) => {
  const bookmarkButtonPreconditions = Map<string, boolean>().set('Please select one or more rows.', !selectedRowIndices.isEmpty());
  return (
    <div className={style.taskbarContainer}>
      <Toolbar
        className="mb-8"
        left={(
          <div className={style.subheader}>
            <GoldenRecordsFilterPanelButton />
            <div className={style.buttonToolbar}>
              <ConditionalButton
                buttonType="Secondary"
                icon="bookmark"
                onClick={addBookmarks}
                preconditions={bookmarkButtonPreconditions}
              >
                Add bookmark
              </ConditionalButton>
              <ConditionalButton
                className="ml-8"
                buttonType="Secondary"
                icon="bookmark-empty"
                onClick={removeBookmarks}
                preconditions={bookmarkButtonPreconditions}
              >
                Remove bookmark
              </ConditionalButton>
            </div>
          </div>
        )}
        right={(
          <Button buttonType="Secondary" onClick={toggleSidebar}>
            {showSidebar ? 'Close rules' : 'Open rules'}
          </Button>
        )}
      />
    </div>
  );
});

export default GoldenRecordsTaskbar;
