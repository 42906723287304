import React from 'react';

import TamrIcon, { TamrIconProps } from '../components/TamrIcon';
import TooltipTrigger, { TooltipTriggerProps } from '../components/TooltipTrigger';

type MlEnabledIconProps = {
  tooltipProps?: TooltipTriggerProps
} & Omit<Partial<TamrIconProps>, 'iconName' | 'ref'>

export const MlEnabledIcon: React.FC<MlEnabledIconProps> = ({ tooltipProps, ...iconProps }) => {
  return (
    <TooltipTrigger placement="top" content="This attribute is included in machine learning" {...tooltipProps}>
      <TamrIcon size={12} iconName="tamr-icon-ml-on" {...iconProps} />
    </TooltipTrigger>
  );
};
