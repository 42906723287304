import { ThunkDispatch } from '@reduxjs/toolkit';
import { List, Map, Set } from 'immutable';
import { isEqual } from 'lodash';

import * as DatasetClient from '../api/DatasetClient';
import * as DedupClient from '../api/DedupClient';
import { FetchError, FetchResult } from '../api/FetchResult';
import * as ProcurifyClient from '../api/ProcurifyClient';
import * as RecipeClient from '../api/RecipeClient';
import { getAllUnifiedAttributes } from '../api/TransformClient';
import { updateUserPreferences } from '../auth/AuthAsync';
import {
  CHANGED_CLUSTERS_SET, ClusterChangesE, NEW_CLUSTERS_SET,
  REMOVED_CLUSTERS_SET,
} from '../constants/ClusterChanges';
import {
  CHANGED_CLUSTER_SET,
  ClusterRecordChangesValueType,
  DELETED_RECORD_SET,
  NEW_RECORD_SET,
  UNCHANGED_CLUSTER_SET,
} from '../constants/ClusterRecordChanges';
import * as DataTables from '../constants/DataTables';
import { GEOTAMR_RECORD_TYPE } from '../constants/GeoTamrRecordType';
import { PUBLISH_CLUSTERS } from '../constants/RecipeOperations';
import { DEDUP } from '../constants/RecipeType';
import { VerificationTypeFilterE } from '../constants/VerificationTypeFilter';
import { SHOW } from '../errorDialog/ErrorDialogActionTypes';
import { apiError } from '../errorDialog/ErrorDialogUtils';
import * as geoTamr from '../geospatial/GeoTamr';
import ChangedClusters from '../models/ChangedClusters';
import ChangedRecords from '../models/ChangedRecords';
import Cluster from '../models/Cluster';
import CountsOverTime from '../models/CountsOverTime';
import Dataset from '../models/Dataset';
import DatasetStatus from '../models/DatasetStatus';
import DisplayColumn from '../models/DisplayColumn';
import Document from '../models/doc/Document';
import { QueryBuilder } from '../models/doc/Query';
import EsRecord from '../models/EsRecord';
import KeyMods from '../models/KeyMods';
import ProjectInfo from '../models/ProjectInfo';
import { DEDUP_INFO_METADATA_KEY } from '../models/Recipe';
import RecordComment from '../models/RecordComment';
import RecordCommentId from '../models/RecordCommentId';
import { NO_SMR_RECIPE_FOR_PROJECT_ERROR_MESSAGE } from '../schema-mapping/SchemaMappingAsync';
import { getRecommendationsRecipeDoc } from '../schema-mapping/SchemaMappingStore';
import { AppAction, AppThunkAction } from '../stores/AppAction';
import { AppDispatch, AppState } from '../stores/MainStore';
// @ts-expect-error
import { sendMessage } from '../stores/MessagingApi';
import { DEFAULT_PAGE_SIZE, TxnUrlBuilder } from '../transactions/TransactionUtils';
import { setColumnPreferences } from '../users/UsersAsync';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import * as Result from '../utils/Result';
import { getActiveSpendField, getAuthorizedUser, getDedupInfo, getUnifiedDatasetName, selectActiveProjectInfo } from '../utils/Selectors';
import SortUtils from '../utils/SortUtils';
import { getTerm } from '../utils/Terms';
import { $TSFixMe } from '../utils/typescript';
import { isDefined, isNotEmpty } from '../utils/Values';
import { geoTamrFromRows } from './ClusterRecordsMap';
import ClusterSimilarity from './ClusterSimilarity';
import Pane, { PaneE } from './Pane';
import {
  COMMENT_COMPLETED,
  COMMENT_FAILED,
  CONFLICT_ERROR,
  DELETE_COMMENT,
  DELETE_COMMENT_COMPLETED,
  DELETE_COMMENT_FAILED,
  EDIT_COMMENT,
  EDIT_COMMENT_COMPLETED,
  EDIT_COMMENT_FAILED,
  FETCH_ACTIVE_RECORD_CLUSTER_MEMBERSHIP,
  FETCH_ACTIVE_RECORD_CLUSTER_MEMBERSHIP_COMPLETED,
  FETCH_ACTIVE_RECORD_CLUSTER_MEMBERSHIP_FAILED,
  FETCH_ADJACENT_GEOSPATIAL_TRANSACTIONS,
  FETCH_ADJACENT_GEOSPATIAL_TRANSACTIONS_COMPLETED,
  FETCH_ADJACENT_GEOSPATIAL_TRANSACTIONS_FAILED,
  FETCH_GEOSPATIAL_TRANSACTIONS,
  FETCH_GEOSPATIAL_TRANSACTIONS_COMPLETED,
  FETCH_GEOSPATIAL_TRANSACTIONS_FAILED,
  FETCH_INITIAL_GEOSPATIAL_TRANSACTIONS,
  FETCH_INITIAL_GEOSPATIAL_TRANSACTIONS_COMPLETED,
  FETCH_INITIAL_GEOSPATIAL_TRANSACTIONS_FAILED,
  FETCH_TRANSACTIONS,
  FETCH_TRANSACTIONS_BY_BOUNDS_COMPLETED,
  FETCH_TRANSACTIONS_COMPLETED,
  FETCH_TRANSACTIONS_FAILED,
  MOVE_RECORDS,
  MOVE_RECORDS_COMPLETED,
  MOVE_RECORDS_FAILED,
  MOVE_RECORDS_TO_NEW_COMPLETED,
  MOVE_RECORDS_TO_NEW_FAILED,
  UPDATE_GEOSPATIAL_BOUNDS,
} from './SuppliersActionTypes';
import {
  ClusterFilterInfo,
  getActiveRecord,
  getActiveRecordClusterMembershipFilterInfo,
  getClustersFilterInfo,
  getClustersSort,
  getRecordsFilterInfo,
  getSelectedClusters,
  getSelectedRecords,
  urlifyConfidenceRangeFilter,
} from './SuppliersStore';
import { toApiFilters } from './test-record/filtersSlice';
import { SuggestionsFilters, urlifyNegativeClusterVerificationFilters, urlifyPositiveClusterVerificationFilters, urlifyVerificationFilters, VerificationFilterTotals, VerifiedFilters } from './VerificationFilters';
import VerificationType, { VerificationTypeE } from './VerificationType';


export const NAME_COL_NAME = 'name';
export const SPEND_COL_NAME = 'totalSpend';
export const COUNT_COL_NAME = 'recordCount';
export const HIGH_IMPACT_COL_NAME = 'highImpact';

export const CLUSTER_TABLE_WIDTH_PREFERENCE_KEY = 'clusterTableWidth';
export const CLUSTER_TABLE_DEFAULT_WIDTH = 400;

const defaultWidths = Map<string, number>()
  .set(NAME_COL_NAME, 140)
  .set(SPEND_COL_NAME, 90)
  .set(COUNT_COL_NAME, 90);

export function getColumnSettings(state: AppState): Map<string, number> {
  const { location: { recipeId } } = state;
  const authorizedUser = getAuthorizedUser(state);
  const pagePrefs = (authorizedUser?.preferences.get('columnsPreferences') || List())
    .find((p: $TSFixMe) => p.recipeId === recipeId && p.page === DataTables.CLUSTERS);
  return (pagePrefs ? pagePrefs.columnsPreferences : []).reduce((rn: $TSFixMe, { name, width }: $TSFixMe) => rn.update(name, (w: $TSFixMe) => width || w), defaultWidths);
}


export function getClusterTableWidth(state: AppState): number {
  const { location: { recipeId } } = state;
  const authorizedUser = getAuthorizedUser(state);
  const pagePrefs = (authorizedUser?.preferences.get('columnsPreferences') || List())
    .find((p: $TSFixMe) => p.recipeId === recipeId && p.page === DataTables.CLUSTERS_PANE);
  return pagePrefs ? pagePrefs[CLUSTER_TABLE_WIDTH_PREFERENCE_KEY] : CLUSTER_TABLE_DEFAULT_WIDTH;
}

export const setColumnWidth = (newWidth: number, col: string): AppThunkAction<void> => (dispatch, getState) => {
  // When resizing quickly multiple times, it's possible for a user refresh to cause a re-render of the
  // table, which interrupts resizing, which results in col being undefined.
  if (!col) {
    return;
  }
  const state = getState();
  const allCols = [NAME_COL_NAME, getActiveSpendField(state) ? SPEND_COL_NAME : null, COUNT_COL_NAME].filter(isNotEmpty);
  const otherCol = allCols[allCols.indexOf(col) + 1];
  // Subtract the width of all non-resizable columns
  const total = getClusterTableWidth(state) - 64;
  const columnSettings = getColumnSettings(state);
  const denom = allCols.map(c => columnSettings.get(c)).reduce((rn, n) => (rn || 0) + (n || 0), 0);
  const columnSizes = columnSettings.map(n => Math.floor(n * total / (denom || 1)));
  // Don't allow the other cell to collapse below the minimum size
  const delta = Math.min(newWidth - (columnSizes.get(col) || 0), (columnSizes.get(otherCol) || 0) - 20);
  const updatedSizes = columnSizes.update(col, n => n + delta).update(otherCol, n => n - delta);
  const columns = updatedSizes.map((width, name) => new DisplayColumn({ name, width })).toList();
  dispatch(setColumnPreferences(DataTables.CLUSTERS, columns));
};

export const saveClusterTableWidth = (newWidth: number): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const { location: { recipeId } } = state;
  const page = DataTables.CLUSTERS_PANE;
  const user = getAuthorizedUser(state);
  if (!user) {
    console.error('Cannot save cluster table width - no handle on logged in user');
    return;
  }
  const newUser = user.updateIn(['preferences', 'columnsPreferences'], ps => {
    return (ps || [])
      .filter((p: $TSFixMe) => !(p.recipeId === recipeId && p.page === page))
      .concat([{ recipeId, page, [CLUSTER_TABLE_WIDTH_PREFERENCE_KEY]: newWidth }]);
  });
  dispatch(updateUserPreferences(newUser));
};

export const comment = (message: string): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const { suppliers: { focusedPane } } = state;
  const activeRecord = getActiveRecord(state, focusedPane);

  if (!activeRecord) {
    console.error('Unable to get active record');
    return;
  }
  const user = getAuthorizedUser(state);
  if (!user) {
    console.error('Cannot comment because there is no authorized user');
    return;
  }
  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    console.error('Cannot comment because there is no handle to a unified dataset name');
    return;
  }
  const timestamp = Math.floor(Date.now() / 1000);
  const recordComment = new RecordComment({
    entityId: activeRecord.recordId,
    username: user.username,
    createdAt: timestamp,
    modifiedAt: timestamp,
    message,
  });
  DedupClient.putRecordComment(List.of(recordComment), unifiedDatasetName)
    .then(() => dispatch({ type: COMMENT_COMPLETED }))
    .catch((response) => dispatch({ type: COMMENT_FAILED, detail: 'Error adding comment', response }));
};

export const editComment = (
  recordComment: RecordComment,
  message: string,
): AppThunkAction<void> => (dispatch, getState) => {
  dispatch({ type: EDIT_COMMENT });
  const state = getState();
  const { suppliers: { focusedPane } } = state;
  const activeRecord = getActiveRecord(state, focusedPane);

  if (!activeRecord) {
    console.error('Unable to get active record');
    return;
  }

  const timestamp = Math.floor(Date.now() / 1000);
  const unifiedDatasetName = getUnifiedDatasetName(getState());
  if (!unifiedDatasetName) {
    console.error('Cannot comment because there is no handle to a unified dataset name');
    return;
  }
  const newComment = new RecordComment(
    {
      entityId: recordComment.entityId,
      username: recordComment.username,
      createdAt: recordComment.createdAt,
      modifiedAt: timestamp,
      message,
    },
  );

  DedupClient.putRecordComment(List.of(newComment), unifiedDatasetName)
    .then(() => dispatch({ type: EDIT_COMMENT_COMPLETED }))
    .catch((response) => dispatch({ type: EDIT_COMMENT_FAILED, detail: 'Error editing comment', response }));
};

export const removeComment = (
  recordComment: RecordComment,
): AppThunkAction<void> => (dispatch, getState) => {
  dispatch({ type: DELETE_COMMENT });
  const state = getState();
  const { suppliers: { focusedPane } } = state;
  const activeRecord = getActiveRecord(state, focusedPane);

  if (!activeRecord) {
    console.error('Unable to get active record');
    return;
  }

  const unifiedDatasetName = getUnifiedDatasetName(getState());
  if (!unifiedDatasetName) {
    console.error('Cannot comment because there is no handle to a unified dataset name');
    return;
  }
  DedupClient.deleteRecordComment(
    List.of(new RecordCommentId({
      entityId: activeRecord.recordId,
      username: recordComment.username,
      createdAt: recordComment.createdAt,
    })),
    unifiedDatasetName,
  )
    .then(() => dispatch({ type: DELETE_COMMENT_COMPLETED }))
    .catch((response) => ({ type: DELETE_COMMENT_FAILED, detail: 'Error deleting comment', response }));
};

export function onlyClusterSelected(selectedCluster: Set<string>, clusterId: string): boolean {
  return selectedCluster.size === 1 && selectedCluster.has(clusterId);
}

export function boundsTransaction(
  unifiedDatasetName: string,
  pageSize: number,
  bounds: number[][],
  geoAttr: string,
): TxnUrlBuilder {
  return new TxnUrlBuilder(DEDUP)
    .unifiedDatasetName(unifiedDatasetName)
    .pageSize(pageSize)
    .geospatialEnvelopFilter(
      bounds[0][0] && bounds[0][1] && bounds[1][0] && bounds[1][1]
        // we flip bounds[1][1] and bounds[0][1] for the format that Es expected
        ? `tamr__${geoAttr}..${bounds[0][0]}..${bounds[1][1]}..${bounds[1][0]}..${bounds[0][1]}`
        : undefined,
    );
}

export const updateGeospatialBounds = (bounds: number[][]): AppThunkAction<void> => (dispatch) => {
  dispatch({ type: UPDATE_GEOSPATIAL_BOUNDS, bounds });
};

export const fetchGeospatialTransactions = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const {
    suppliers: {
      geospatial: {
        activeGeospatialRenderingAttribute: geoAttr,
        activeClusterId: clusterId,
        currentBounds: bounds,
        initialTotalGeoFeatures,
      },
    },
    config: { elasticConfig },
  } = state;

  if (!elasticConfig) {
    console.error('Cannot fetchGeospatialTransactions - have not loaded elastic config');
    return;
  }
  const { maxGeospatialFeaturesDefault } = elasticConfig;

  const projectInfo = selectActiveProjectInfo(state);

  if (!projectInfo || !projectInfo.unifiedDatasetDoc || !projectInfo.unifiedDatasetName || !geoAttr || !clusterId) {
    console.error('Invalid state to perform action. Make sure your project, unified dataset, ' +
      'geospatial attribute or active cluster ID are all defined');
    return;
  }

  const { unifiedDatasetName, unifiedDatasetDoc } = projectInfo;

  if (initialTotalGeoFeatures === 0 || initialTotalGeoFeatures <= maxGeospatialFeaturesDefault) {
    return;
  }

  dispatch({ type: FETCH_GEOSPATIAL_TRANSACTIONS });

  Promise.all([
    ProcurifyClient.fetchTransactions(
      boundsTransaction(
        unifiedDatasetName,
        maxGeospatialFeaturesDefault,
        bounds,
        geoAttr,
      ).clusterIds([clusterId]),
      unifiedDatasetDoc,
    ),
  ]).then(([{ total, items }]) => {
    dispatch({
      type: FETCH_GEOSPATIAL_TRANSACTIONS_COMPLETED,
      geoRows: items,
      totalGeoFeatures: total,
      loadedBounds: bounds,
      loadingBounds: false,
    });
  }, response => {
    dispatch({ type: FETCH_GEOSPATIAL_TRANSACTIONS_FAILED });
    dispatch({ type: SHOW, detail: 'Error loading data', response });
  });
};

export const fetchAdjacentGeospatialTransactions = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const {
    suppliers: {
      geospatial: {
        activeGeospatialRenderingAttribute: geoAttr,
        activeClusterId: clusterId,
        currentBounds: bounds,
        geoRows,
      },
    },
    config: { elasticConfig },
  } = state;
  const projectInfo = selectActiveProjectInfo(state);

  if (!elasticConfig) {
    console.error('Cannot fetchAdjacentGeospatialTransactions - have not loaded elastic config');
    return;
  }
  const { maxGeospatialFeaturesDefault } = elasticConfig;

  if (!projectInfo || !projectInfo.unifiedDatasetDoc || !projectInfo.unifiedDatasetName || !geoAttr || !clusterId) {
    console.error('Invalid state to perform action. Make sure your project, unified dataset, ' +
      'geospatial attribute or active cluster ID are all defined');
    return;
  }

  const { unifiedDatasetName, unifiedDatasetDoc } = projectInfo;

  const remainingLimitForAdjacentRecords = maxGeospatialFeaturesDefault - geoRows.size;

  if (remainingLimitForAdjacentRecords <= 0) {
    return;
  }

  dispatch({ type: FETCH_ADJACENT_GEOSPATIAL_TRANSACTIONS });

  const getAdjacentGeoRowsPromise = ProcurifyClient.fetchTransactions(
    boundsTransaction(unifiedDatasetName, remainingLimitForAdjacentRecords, bounds, geoAttr)
      .notClusterIds([clusterId]),
    unifiedDatasetDoc,
  );

  getAdjacentGeoRowsPromise.then(({ items: adjacentGeoRows, total: totalAdjacentGeoFeatures }) => dispatch({
    type: FETCH_ADJACENT_GEOSPATIAL_TRANSACTIONS_COMPLETED,
    adjacentGeoRows,
    totalAdjacentGeoFeatures,
    loadedAdjacentBounds: bounds,
    loadingAdjacentRows: false,
  }), response => {
    dispatch({ type: FETCH_ADJACENT_GEOSPATIAL_TRANSACTIONS_FAILED });
    dispatch({ type: SHOW, detail: 'Error loading data', response });
  });
};

export const fetchTransactionsByBounds = (bounds: number[][]): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const {
    suppliers: { geospatial: { activeGeospatialRenderingAttribute, showGeospatialOverlay, activeClusterId } },
    config: { elasticConfig },
  } = state;

  const projectInfo = selectActiveProjectInfo(state);

  if (!elasticConfig) {
    console.error('Cannot fetchTransactionsByBounds - have not loaded elastic config');
    return;
  }
  const { maxGeospatialFeaturesDefault } = elasticConfig;

  if (!projectInfo || !projectInfo.unifiedDatasetDoc || !projectInfo.unifiedDatasetName || !activeGeospatialRenderingAttribute || !activeClusterId) {
    console.error('Invalid state to perform action. Make sure your project, unified dataset, ' +
      'geospatial attribute or active cluster ID are all defined');
    return;
  }

  const { unifiedDatasetName, unifiedDatasetDoc } = projectInfo;

  let fetchTransactionsBuilder = boundsTransaction(
    unifiedDatasetName,
    maxGeospatialFeaturesDefault,
    bounds,
    activeGeospatialRenderingAttribute,
  );

  if (!showGeospatialOverlay) {
    fetchTransactionsBuilder = fetchTransactionsBuilder.clusterIds([activeClusterId]);
  }

  ProcurifyClient.fetchTransactions(fetchTransactionsBuilder, unifiedDatasetDoc)
    .then(({ items }) => dispatch({
      type: FETCH_TRANSACTIONS_BY_BOUNDS_COMPLETED,
      selectedGeoRows: items,
    }));
};

export const fetchTransactionsUnderCursor = (
  lng: number,
  lat: number,
  northBound: number,
  southBound: number,
): AppThunkAction<void> => (dispatch) => {
  // When fetching all the features under a point, this is the box margin to expand on.
  // This is used to expand the bounding box under the cursor to find multiple records by
  // expanding the Northeast Corner (subtract the margin) and the Southwest corner
  // (add the margin). Use a min and max cursor margin to avoid zooming in too far and returning
  // nothing, or zooming too far out and retrieving everything.
  const margin = Math.min(0.0005, Math.max(0.000001, (southBound - northBound) / 500));
  // Bounds search expects Northeast and Southwest points.
  dispatch(fetchTransactionsByBounds([
    [lng - margin, lat - margin],
    [lng + margin, lat + margin],
  ]));
};

/**
 * Grab cluster query filter information from redux and attempt to spawn an http request fetching clusters.
 *
 * @returns A {@link Result.Result} that will fail if the fetch request could not be spawned due to preconditions.
 *   If request can be spawned, the result will contain a promise that contains a {@link FetchResult} tracking the
 *   success of the request.
 */
function doFetchClusters(
  state: AppState,
  dispatch: AppDispatch,
  pane: PaneE,
): Result.Result<Promise<FetchResult<{
  filterInfo: ClusterFilterInfo
  clusters: List<Cluster>
  total: number
}>>, string> {
  const filterInfo = getClustersFilterInfo(state, pane);
  const { clusterVerificationFilters, clusterConfidenceThresholds, confidenceFilter, filterResolved,
    filterUnresolved, pageNum, queryString, suppliersFilter, selectedSourceDatasets, clusterChanges,
    hasCustomConfidenceFilter, customConfidenceFilter, hasCustomSimilarityFilter, customSimilarityFilter,
    clusterHighImpactFilter,
  } = filterInfo;

  const authUser = getAuthorizedUser(state);
  if (!authUser) {
    return Result.constructFailure('Cannot fetch clusters - logged in user is undefined');
  }

  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    return Result.constructFailure('Cannot fetch clusters - unified dataset name is undefined');
  }

  const getResolved = () => {
    const username = authUser.username;
    switch (true) {
      case filterResolved && filterUnresolved: return { username };
      case filterResolved: return { username, resolved: true };
      case filterUnresolved: return { username, resolved: false };
      default: return {};
    }
  };
  let averageLinkageRanges = confidenceFilter
    .map(range => urlifyConfidenceRangeFilter(range, clusterConfidenceThresholds || undefined))
    .filter(isDefined);
  if (hasCustomConfidenceFilter && customConfidenceFilter) {
    averageLinkageRanges = averageLinkageRanges.add(`${customConfidenceFilter.lowerBound} ${customConfidenceFilter.upperBound}`);
  }

  const queryParams: DedupClient.FetchClustersQueryParams = {
    offset: pageNum * DEFAULT_PAGE_SIZE,
    limit: DEFAULT_PAGE_SIZE,
    q: queryString,
    sort: SortUtils.getUrlSortStates(getClustersSort(state, pane)),
    // redux only stores a single string for this - UX only uses it as a single-cluster filter
    clusterIds: suppliersFilter ? [suppliersFilter] : undefined,
    sourceDatasets: selectedSourceDatasets.toArray(),
    ...getResolved(),
    positiveVerificationFilters: urlifyPositiveClusterVerificationFilters(clusterVerificationFilters),
    negativeVerificationFilters: urlifyNegativeClusterVerificationFilters(clusterVerificationFilters),
    averageLinkageRanges: averageLinkageRanges.toArray(),
    clusterChanges: clusterChanges.toArray(),
    clusterSimilarityThreshold: hasCustomSimilarityFilter ? customSimilarityFilter : undefined,
    filterHighImpactClusters: clusterHighImpactFilter ? 'ALL' : undefined,
  };

  return Result.constructSuccess(DedupClient.fetchClusters(unifiedDatasetName, queryParams)
    .then(result => Result.mapMonad(result,
      ({ items, total }) => ({ clusters: List(items).map(Cluster.fromJSON), total, filterInfo }),
      error => {
        apiError(dispatch, 'Error loading clusters', error);
        return error;
      },
    )));
}

/**
 * Thin wrapper over {@link DedupClient.fetchClusters} that only accepts a list of cluster ids to filter to,
 *   and maps the returned clusters page into a Set of clusters.
 */
function fetchClustersById(unifiedDatasetName: string, clusterIds: Set<string>): Promise<FetchResult<Set<Cluster>>> {
  return DedupClient.fetchClusters(unifiedDatasetName, { clusterIds: clusterIds.toArray() })
    .then(result => Result.mapMonad(result,
      ({ items }) => items.toSet(),
      error => error,
    ));
}

function fetchClustersByIdFromStorage(unifiedDatasetName: string, clusterIds: Set<string>): Promise<FetchResult<Set<Cluster>>> {
  return DedupClient.fetchClustersByIds(unifiedDatasetName, clusterIds.toArray(), { clusterIds: clusterIds.toArray() })
    .then(result => Result.mapMonad(result,
      clusters => Set.of(...clusters),
      error => error,
    ));
}

export const fetchSuppliersUpdate = (pane: PaneE): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const { suppliers: { [pane]: { data } } } = state;
  const affectedIds = data.map(c => c.clusterId).toSet();
  dispatch({ type: 'Suppliers.fetchSuppliersUpdate', pane });

  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    console.error('Cannot fetchSuppliersUpdate - unifiedDatasetName is undefined');
    return;
  }

  const clusterFetchResult = doFetchClusters(state, dispatch, pane);
  if (clusterFetchResult.isFailure) {
    // some error happened before being able to spawn fetch clusters request. report and bail out
    console.error(clusterFetchResult.error);
    return;
  }

  return Promise.all([
    clusterFetchResult.data,
    DedupClient.fetchTotalClustersCount(unifiedDatasetName),
    fetchClustersById(unifiedDatasetName, affectedIds),
  ]).then((results) => {
    Result.handleThree(results,
      ({ clusters, total, filterInfo }, totalSuppliers, affectedClusters) =>
        dispatch({ type: 'Suppliers.fetchSuppliersUpdateCompleted', currentPage: clusters, total, filterInfo, totalSuppliers, affectedIds, affectedClusters, pane }),
      () => dispatch({ type: 'Suppliers.fetchSuppliersUpdateFailed', pane }));
  });
};

// TODO There is already a loader for Suppliers: SuppliersLoader.
//      This should integrate with that instead of fetching out-of-band
export const selectCluster = (keyMods: KeyMods, rowNum: number, pane: PaneE): AppThunkAction<void> => (dispatch) => {
  dispatch({ type: 'Suppliers.selectSupplierRow', keyMods, selectedSupplierRow: rowNum, pane });
  Object.values(Pane).forEach(p => dispatch(fetchSuppliersUpdate(p)));
};

export const selectAllClusters = (pane: PaneE): AppThunkAction<void> => (dispatch) => {
  dispatch({ type: 'Suppliers.selectAllSupplierRows', pane });
  Object.values(Pane).forEach(p => dispatch(fetchSuppliersUpdate(p)));
};

export const fetchInitialGeospatialTransactions = (
  keyMods: KeyMods,
  rowNum: number,
  clusterId: string,
  pane: PaneE,
): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const {
    suppliers: { [pane]: { selectedSuppliers, data }, geospatial: { openMap } },
    config: { elasticConfig },
  } = state;
  const projectInfo = selectActiveProjectInfo(state);

  if (!elasticConfig) {
    console.error('Cannot fetchInitialGeospatialTransactions - have not loaded elastic config');
    return;
  }
  const { maxGeospatialFeaturesDefault } = elasticConfig;

  if (!projectInfo || !projectInfo.unifiedDatasetDoc || !projectInfo.unifiedDatasetName) {
    console.error('Invalid state to perform action. Make sure your project and unified dataset are defined');
    return;
  }

  const { unifiedDatasetName, unifiedDatasetDoc } = projectInfo;
  const recsRecipeId = getRecommendationsRecipeDoc(projectInfo)?.id.id;

  if (!recsRecipeId) {
    console.error(NO_SMR_RECIPE_FOR_PROJECT_ERROR_MESSAGE(projectInfo.projectId));
    return;
  }

  const clusterName = data.find(c => c.clusterId === clusterId)?.name;

  if (onlyClusterSelected(selectedSuppliers, clusterId) && openMap) {
    // the map is already open for the only selected cluster, so no need to refetch anything
    return;
  }

  dispatch({ type: FETCH_INITIAL_GEOSPATIAL_TRANSACTIONS });

  Promise.all([
    ProcurifyClient.fetchTransactions(
      new TxnUrlBuilder(DEDUP)
        .unifiedDatasetName(unifiedDatasetName)
        .pageSize(maxGeospatialFeaturesDefault)
        .clusterIds([clusterId]),
      unifiedDatasetDoc,
    ),
    getAllUnifiedAttributes(unifiedDatasetName, recsRecipeId),
  ]).then(([{ total, items }, attributes]) => {
    // get the first geospatial-type attribute (if any)
    const sortedAttributes = attributes.sortBy(d => d.name);
    const geoAttr =
      sortedAttributes.find(d => isEqual(d.type.toJSON(), GEOTAMR_RECORD_TYPE.toJSON()))?.name;

    const bounds = geoAttr ? geoTamr.calculateBounds(geoTamrFromRows(items, geoAttr).toArray()) : [];
    const center = geoAttr ? geoTamr.calculateCentroid(bounds) : [];

    dispatch({
      type: FETCH_INITIAL_GEOSPATIAL_TRANSACTIONS_COMPLETED,
      activeClusterId: clusterId,
      activeClusterName: clusterName,
      activeGeospatialRenderingAttribute: geoAttr,
      geoRows: items,
      totalGeoFeatures: total,
      initialTotalGeoFeatures: total,
      initialBounds: bounds,
      initialCenter: center,
      currentBounds: bounds,
      loadedBounds: bounds,
      loadingRows: false,
    });

    if (!onlyClusterSelected(selectedSuppliers, clusterId)) {
      // make sure to deselect other clusters when mapping a cluster
      dispatch(selectCluster(keyMods, rowNum, pane));
    }
  }, response => {
    dispatch({ type: FETCH_INITIAL_GEOSPATIAL_TRANSACTIONS_FAILED });
    dispatch({ type: SHOW, detail: 'Error loading data', response });
  });
};

export const fetchActiveRecordClusterMembership = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const activeProjectInfo = selectActiveProjectInfo(state);
  if (!activeProjectInfo) {
    console.error('Cannot fetch cluster membership for active record - not currently in a project');
    return;
  }
  const filterInfo = getActiveRecordClusterMembershipFilterInfo(state);
  const { focusedPane: pane, activeRecordId } = filterInfo;
  if (!pane) {
    console.error('Cannot fetch cluster membership for active record - not currently focusing a pane');
    return;
  }
  if (!activeRecordId) {
    console.error('Cannot fetch cluster membership for active record - no active record!');
    return;
  }
  const { unifiedDatasetName } = activeProjectInfo;
  if (!unifiedDatasetName) {
    console.error('Cannot fetchActiveRecordClusterMembership - no handle on the unifiedDatasetName');
    return;
  }
  const record = state.suppliers[pane].rows.find(esRecord => esRecord.recordId === activeRecordId);
  dispatch({ type: FETCH_ACTIVE_RECORD_CLUSTER_MEMBERSHIP, pane });
  DedupClient.fetchClusterMembers(unifiedDatasetName, List.of(activeRecordId))
    .then(clusterMembers => {
      const activeRecordClusterMembership = clusterMembers.find(clusterMember => clusterMember.recordId === activeRecordId);
      // at this point, we may need to specifically fetch a related cluster's information to show on the sidebar
      // here, that cluster is the cluster that tamr's suggestion (which differs from the current record placement) points to
      const suggestedClusterFetch = record?.suggestionExists && record.suggestedClusterId
        ? fetchClustersById(unifiedDatasetName, Set.of(record.suggestedClusterId))
        : undefined;
      // here, that cluster is the cluster that the record was previously verified in, before Tamr moved it during clustering
      const previouslyVerifiedBeforeMoveClusterFetch = record?.verifiedInAnotherCluster && record.verifiedClusterId
        ? fetchClustersById(unifiedDatasetName, Set.of(record.verifiedClusterId))
        : undefined;
      // here, that cluster is the cluster that the record was previously verified in, before a user unverified it
      const previouslyVerifiedBeforeUnverifyClusterFetch = activeRecordClusterMembership?.verifiedThenUnverified && activeRecordClusterMembership?.verifiedClusterId
        ? fetchClustersById(unifiedDatasetName, Set.of(activeRecordClusterMembership.verifiedClusterId))
        : undefined;
      const relatedClusterFetch = suggestedClusterFetch || previouslyVerifiedBeforeMoveClusterFetch || previouslyVerifiedBeforeUnverifyClusterFetch || Promise.resolve();
      return Promise.all([
        Promise.resolve(activeRecordClusterMembership),
        relatedClusterFetch,
      ]);
    }).then(([activeRecordClusterMembership, relatedClusterFetchResult]) => {
      if (relatedClusterFetchResult && relatedClusterFetchResult.isFailure) {
        dispatch({ type: FETCH_ACTIVE_RECORD_CLUSTER_MEMBERSHIP_FAILED, filterInfo });
        return;
      }
      const activeRecordRelatedCluster = relatedClusterFetchResult && relatedClusterFetchResult.data.toList().get(0) || undefined;
      dispatch({
        type: FETCH_ACTIVE_RECORD_CLUSTER_MEMBERSHIP_COMPLETED,
        activeRecordClusterMembership,
        // perhaps we should pass back to the store what type of related cluster we've fetched, per logic above
        activeRecordRelatedCluster,
        pane,
        filterInfo,
      });
    }).catch(() => {
      dispatch({ type: FETCH_ACTIVE_RECORD_CLUSTER_MEMBERSHIP_FAILED, filterInfo });
    });
};

export function getClusterNameField(state: AppState): string | undefined {
  const projectInfo = selectActiveProjectInfo(state);
  if (!projectInfo) {
    console.error('Cannot get cluster name field - no active project');
    return;
  }
  const dedupMetadata = projectInfo.recipe.metadata.get(DEDUP_INFO_METADATA_KEY);
  if (!dedupMetadata) {
    console.warn(`no DEDUP metadata for recipe id ${projectInfo.recipeId}, may need to populate`);
  }
  return dedupMetadata.nameField || undefined;
}

export function showError(response: { status: number }, detail: $TSFixMe): AppAction {
  if (response.status === 409 || response.status === 404) {
    return { type: CONFLICT_ERROR };
  }
  return { type: SHOW, detail, response };
}

export const fetchPublishedClustersStatus = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const projectInfo = selectActiveProjectInfo(state);
  const unifiedDatasetName = projectInfo?.unifiedDatasetName;
  const clusterCountDataset = `${unifiedDatasetName}_dedup_published_clusters`;
  dispatch({ type: 'Suppliers.fetchPublishedClustersDatasetStatus' });
  const query = new QueryBuilder()
    .offset(0)
    .limit(1)
    .whereData('name')
    .isEqualTo(clusterCountDataset)
    .build();
  DatasetClient.postStatusQuery(query)
    .then(response => dispatch({
      type: 'Suppliers.fetchPublishedClustersDatasetStatusCompleted',
      publishedClustersDatasetStatus: response.first() as DatasetStatus,
    }))
    .catch(() => dispatch({ type: 'Suppliers.fetchPublishedClustersDatasetStatusFailed' }));
};

export const moveRecordsToNew = (pane: PaneE, verificationType: VerificationTypeE): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ pane }, ArgTypes.valueIn(Pane));
  checkArg({ verificationType }, VerificationType.argType);
  const state = getState();
  const projectInfo = selectActiveProjectInfo(state);
  if (!projectInfo) {
    console.error('Cannot move records to new - not currently in a project');
    return;
  }
  const clusterNameField = getClusterNameField(state);
  if (!clusterNameField) {
    console.error('Cannot move records to new - could not get cluster name field');
    return;
  }
  const selectedRecords = getSelectedRecords(state, pane);
  const { unifiedDatasetName } = projectInfo;
  if (!unifiedDatasetName) {
    console.error('Cannot move records to new - no handle on unified dataset name');
    return;
  }
  dispatch({ type: 'Suppliers.moveRecordsToNew', pane });
  const supplierNoun = getTerm(state, 'supplier');
  const recordNoun = getTerm(state, 'record');
  const clusterMembers = selectedRecords.map(({ recordId, verifiedClusterId, verificationType: currentVerificationType }) => {
    return { recordId, verifiedClusterId, verificationType: currentVerificationType };
  });
  DedupClient.moveClusterRecordsToNew({
    unifiedDatasetName,
    clusterNameField,
    verificationType,
    clusterMembers,
  })
    .then(({ newTargetClusterId }) => {
      sendMessage('CLUSTER_MEMBER', new ChangedRecords({
        recipeId: projectInfo.recipeId,
        recordIds: selectedRecords.map(r => r.recordId).toSet(),
      }));
      dispatch({
        type: MOVE_RECORDS_TO_NEW_COMPLETED,
        numRecords: selectedRecords.size,
        targetClusterId: newTargetClusterId,
        pane,
        verificationType,
      });
    }).catch((response) => {
      dispatch({ type: MOVE_RECORDS_TO_NEW_FAILED, pane });
      // TODO move to ErrorDialog listener
      dispatch(showError(response, `Error moving ${recordNoun} to new ${supplierNoun}`));
    });
};

const moveRecords = (
  records: List<EsRecord>,
  pane: PaneE,
  targetClusterId: string,
  verificationType: VerificationTypeE,
): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const { suppliers: { [pane]: { data } } } = state;
  const projectInfo = selectActiveProjectInfo(state);

  if (!projectInfo) {
    console.error('Cannot move records - not currently in a project');
    return;
  }

  if (!projectInfo.unifiedDatasetName) {
    console.error('Cannot move records - no handle on unified dataset name');
    return;
  }

  const clusterNameField = getClusterNameField(state);
  if (!isDefined(clusterNameField)) {
    console.error('Cannot move records - no cluster name field detected');
    return;
  }

  dispatch({ type: MOVE_RECORDS, pane });
  DedupClient.moveClusterRecords({
    unifiedDatasetName: projectInfo.unifiedDatasetName,
    clusterNameField,
    targetClusterId,
    verificationType,
    clusterMembers: records.map(({ recordId, verifiedClusterId, verificationType: currentVerificationType }) => {
      return { recordId, verifiedClusterId, verificationType: currentVerificationType };
    }),
  }).then(({ newTargetClusterId }) => {
    sendMessage('CLUSTER_MEMBER', new ChangedRecords({
      recipeId: projectInfo.recipeId,
      recordIds: records.map(r => r.recordId).toSet(),
    }));
    dispatch({
      type: MOVE_RECORDS_COMPLETED,
      numRecords: records.size,
      targetClusterId: newTargetClusterId,
      targetClusterName: data.find(c => c.clusterId === targetClusterId)?.name || '',
      pane,
      verificationType,
    });
  }).catch(response => {
    dispatch({ type: MOVE_RECORDS_FAILED, pane });
    dispatch(showError(response, `Error moving ${getTerm(state, 'records')}`));
  });
};

export const moveRecord = (
  record: EsRecord,
  pane: PaneE,
  targetClusterId: string,
  verificationType: VerificationTypeE,
): AppThunkAction<void> => (dispatch) => {
  dispatch(moveRecords(List.of(record), pane, targetClusterId, verificationType));
};

export const moveSelectedRecords = (
  pane: PaneE,
  fromPane: PaneE,
  targetClusterId: string,
  verificationType: VerificationTypeE,
): AppThunkAction<void> => (dispatch, getState) => {
  const selectedRecords = getSelectedRecords(getState(), fromPane);
  dispatch(moveRecords(selectedRecords, pane, targetClusterId, verificationType));
};

export const fetchTransactions = (pane: PaneE): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();

  const projectInfo = selectActiveProjectInfo(state);
  if (!projectInfo) return;
  const { unifiedDatasetName, unifiedDatasetDoc } = projectInfo;
  if (!unifiedDatasetDoc || !unifiedDatasetName) return;
  const recsRecipe = getRecommendationsRecipeDoc(projectInfo);
  if (!recsRecipe) return;

  const filterInfo = getRecordsFilterInfo(state, pane);
  const {
    selectedSuppliers, queryString, recordsSelectedSourceDatasets, pageNum, pageSize,
    pinnedRecords, recordsVerifiedFilters, recordsSuggestionsFilters, hasComments,
    columnSortStates, recordChanges, isClusterDiffVisible, testRecordFilters,
  } = filterInfo;
  dispatch({ type: FETCH_TRANSACTIONS, pane });

  const dedupInfo = getDedupInfo(state);
  const numericFields = Object.entries(dedupInfo.sortTypes).filter(kv => kv[1] === 'NUMERIC').map(kv => kv[0]);

  Promise.all([
    ProcurifyClient.fetchTransactions(
      new TxnUrlBuilder(DEDUP)
        .unifiedDatasetName(unifiedDatasetName)
        .datasetNames(recordsSelectedSourceDatasets?.toArray() ?? [])
        .pageNum(pageNum)
        .pageSize(pageSize)
        .queryString(queryString)
        .clusterIds(selectedSuppliers.toArray())
        .includePublishedClusterIds(isClusterDiffVisible)
        .pinnedRecords(pinnedRecords.toArray())
        .verificationTypeFilters(urlifyVerificationFilters(recordsVerifiedFilters, recordsSuggestionsFilters))
        .testRecordsAccuracyFilter(toApiFilters(testRecordFilters))
        .hasComments(hasComments)
        .changeStatusFilters(recordChanges)
        .sort(SortUtils.getUrlSortStates(columnSortStates))
        .numericFields(numericFields)
        .fetchRecordsWithoutClusters(false),
      unifiedDatasetDoc,
    ),
    ProcurifyClient.fetchTransactions(
      new TxnUrlBuilder(DEDUP).unifiedDatasetName(unifiedDatasetName).pageNum(0).pageSize(1),
      unifiedDatasetDoc,
    ),
    getAllUnifiedAttributes(unifiedDatasetName, recsRecipe.id.id),
  ]).then(([{ items, total }, { total: totalSupplierRecords }, attributes]) => {
    // get the first geospatial-type attribute (if any)
    const sortedAttributes = attributes.sortBy(d => d.name);
    const geoAttr =
      sortedAttributes.find(d => isEqual(d.type.toJSON(), GEOTAMR_RECORD_TYPE.toJSON()))?.name;

    dispatch({
      type: FETCH_TRANSACTIONS_COMPLETED,
      rows: items,
      total,
      totalSupplierRecords,
      filterInfo,
      pane,
      activeGeospatialRenderingAttribute: geoAttr,
    });
  }, response => {
    dispatch({ type: FETCH_TRANSACTIONS_FAILED, filterInfo, pane });
    dispatch({ type: SHOW, detail: 'Error loading data', response });
  });
};

export const fetchSuppliers = (pane: PaneE): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const filterInfo = getClustersFilterInfo(state, pane);
  dispatch({ type: 'Suppliers.fetchSuppliers', pane });

  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    console.error('Cannot fetch suppliers - unifiedDatasetName is undefined');
    return;
  }

  const clusterFetchResult = doFetchClusters(state, dispatch, pane);
  if (clusterFetchResult.isFailure) {
    // some error happened before being able to spawn fetch clusters request. report and bail out
    console.error(clusterFetchResult.error);
    return;
  }

  Promise.all([
    clusterFetchResult.data,
    DedupClient.fetchTotalClustersCount(unifiedDatasetName),
    DedupClient.fetchClusters(unifiedDatasetName, {
      limit: 10,
      negativeVerificationFilters: [VerificationTypeFilterE.LOCK, VerificationTypeFilterE.NEVER_VERIFIED, VerificationTypeFilterE.UNVERIFIED],
      filterHighImpactClusters: 'TEST',
    }),
  ]).then(results => Result.handleThree(results,
    ({ clusters, total }, totalSuppliers, testClusters) => {
      dispatch({ type: 'Suppliers.fetchTotalTestClustersVerifiedCompleted', totalTestClustersVerified: testClusters.total });
      dispatch({ type: 'Suppliers.fetchSuppliersCompleted', clusters, total, totalSuppliers, filterInfo, pane });
    },
    (error) => apiError(dispatch, `Error loading ${getTerm(state, 'suppliers')}`, error, { type: 'Suppliers.fetchSuppliersFailed', filterInfo, pane }),
  ));
};

// includes removing verification
export const verifyRecords = (pane: PaneE, verificationType: VerificationTypeE): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    console.error('Cannot verifyRecords - unifiedDatasetName is undefined');
    return;
  }
  const selectedRecords = getSelectedRecords(state, pane);
  dispatch({ type: 'Suppliers.verifyRecords', pane });
  return DedupClient.verifyRecords({
    unifiedDatasetName,
    clusterMembers: selectedRecords, // EsRecord already fits ClusterMemberSpec
    verificationType,
  }).then(() => dispatch({ type: 'Suppliers.verifyRecordsCompleted', pane, verificationType }))
    .catch(({ data, message }) => {
      dispatch({ type: SHOW, title: `Unable to ${verificationType === VerificationType.UNVERIFIED ? 'remove verification' : 'verify records'}`, detail: message, response: { responseText: data?.stackTrace?.join('\n') } });
    });
};

// includes removing verification
export const verifyClusters = (pane: PaneE, verificationType: VerificationTypeE): AppThunkAction<void> => async (dispatch, getState) => {
  checkArg({ pane }, ArgTypes.valueIn(Pane));
  checkArg({ verificationType }, VerificationType.argType);
  const state = getState();
  const { suppliers: { [pane]: { selectedSuppliers } } } = state;
  const projectInfo = selectActiveProjectInfo(state);
  if (!projectInfo) {
    console.error('Cannot verifyClusters - no handle on active project info');
    return;
  }
  const unifiedDatasetName = projectInfo.unifiedDatasetName;
  if (!unifiedDatasetName) {
    console.error('Cannot verifyClusters - no handle on unified dataset name');
    return;
  }
  dispatch({ type: 'Suppliers.verifyClusters', pane });
  return DedupClient.verifyClusters({
    unifiedDatasetName,
    clusterIds: selectedSuppliers,
    verificationType,
  }).then(() => {
    sendMessage('CLUSTER', new ChangedClusters({
      recipeId: projectInfo.recipeId,
      clusterIds: selectedSuppliers,
    }));
    dispatch({ type: 'Suppliers.verifyClustersCompleted', pane });
  }).catch((response) => {
    dispatch(showError(response, `Unable to ${verificationType === VerificationType.UNVERIFIED ? 'remove verification' : 'verify clusters'}`));
    dispatch({ type: 'Suppliers.verifyClustersFailed', pane });
  });
};

function getVerificationTotal(
  unifiedDatasetDoc: Document<Dataset>,
  recordsVerifiedFilters: VerifiedFilters,
  recordsSuggestionsFilters: SuggestionsFilters,
): Promise<number> {
  const url = new TxnUrlBuilder(DEDUP)
    .unifiedDatasetName(unifiedDatasetDoc.data.name)
    .pageNum(0)
    .pageSize(1)
    .verificationTypeFilters(urlifyVerificationFilters(recordsVerifiedFilters, recordsSuggestionsFilters))
    .fetchRecordsWithoutClusters(false);
  return ProcurifyClient.fetchTransactions(url, unifiedDatasetDoc)
    .then(({ total }) => total);
}

export const fetchVerificationFilterTotals = (): AppThunkAction<void> => async (dispatch, getState) => {
  const state = getState();
  const { suppliers: { recordsVerificationFilterTotals: { fetchTriggers } } } = state;
  dispatch({ type: 'Suppliers.fetchVerificationFilterTotals' });
  const unifiedDatasetDoc = selectActiveProjectInfo(state)?.unifiedDatasetDoc;
  if (!unifiedDatasetDoc) {
    console.error('Cannot fetchVerificationFilterTotals - unifiedDatasetDoc is undefined');
    return;
  }
  const noVerifiedFilters = new VerifiedFilters({});
  const noSuggestionsFilters = new SuggestionsFilters({});
  Promise.all([
    getVerificationTotal(unifiedDatasetDoc, new VerifiedFilters({ verifiedHere: true }), noSuggestionsFilters),
    getVerificationTotal(unifiedDatasetDoc, new VerifiedFilters({ verifiedElsewhere: true }), noSuggestionsFilters),
    getVerificationTotal(unifiedDatasetDoc, new VerifiedFilters({ notVerified: true }), noSuggestionsFilters),
    getVerificationTotal(unifiedDatasetDoc, noVerifiedFilters, new SuggestionsFilters({ moveSuggested: true })),
    getVerificationTotal(unifiedDatasetDoc, noVerifiedFilters, new SuggestionsFilters({ noMoveSuggested: true })),
    getVerificationTotal(unifiedDatasetDoc, noVerifiedFilters, new SuggestionsFilters({ suggestionsDisabled: true })),
    getVerificationTotal(unifiedDatasetDoc, noVerifiedFilters, new SuggestionsFilters({ suggestionsAutoAccepted: true })),
  ]).then(([verifiedHere, verifiedElsewhere, notVerified, moveSuggested, noMoveSuggested, suggestionsDisabled, suggestionsAutoAccepted]) => {
    dispatch({
      type: 'Suppliers.fetchVerificationFilterTotalsCompleted',
      totals: new VerificationFilterTotals({ verifiedHere, verifiedElsewhere, notVerified, moveSuggested, noMoveSuggested, suggestionsDisabled, suggestionsAutoAccepted }),
      fetchTriggers,
    });
  }).catch(() => {
    dispatch({ type: 'Suppliers.fetchVerificationFilterTotalsFailed', fetchTriggers });
  });
};

const mergeCluster = (
  state: AppState,
  pane: PaneE,
  projectInfo: ProjectInfo,
  clusterIds: Set<string>,
  targetClusterId: string,
  verificationType: VerificationTypeE,
): AppThunkAction<void> => (dispatch) => {
  const dedupMetadata = projectInfo.recipe.metadata.get(DEDUP_INFO_METADATA_KEY);
  if (!dedupMetadata) {
    console.warn(`no DEDUP metadata for recipe id ${projectInfo.recipeId}, may need to populate`);
  }
  if (!projectInfo.unifiedDatasetName) {
    console.warn('Cannot mergeCluseter - no handle on unified dataset name');
    return;
  }
  dispatch({ type: 'Suppliers.mergeClusters', pane });

  DedupClient.mergeClusters(
    projectInfo.unifiedDatasetName,
    dedupMetadata.nameField,
    verificationType,
    [{ targetCluster: targetClusterId, clustersToMerge: clusterIds.toArray() }],
  ).then(Result.handler(
    ({ newTargetClusterId }) => {
      sendMessage('CLUSTER', new ChangedClusters({
        recipeId: projectInfo.recipeId,
        clusterIds,
      }));
      dispatch({ type: 'Suppliers.mergeClustersCompleted', mergeClusterId: newTargetClusterId, numMerged: clusterIds.size, pane, verificationType });
    },
    error => {
      apiError(dispatch, `Error merging ${getTerm(state, 'suppliers')}`, error, { type: 'Suppliers.mergeClustersFailed', clusterIds, pane });
    },
  ));
};

export const mergeClusters = (pane: PaneE, verificationType: VerificationTypeE): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const clustersToMerge = state.suppliers[pane].confirmingVerificationAction.case<List<Cluster>>({
    MergeClusters: ({ clusters }) => clusters,
    MoveRecordsToNewCluster: () => List(),
    MoveRecordsToExistingCluster: () => List(),
    MergeClustersToTarget: () => List(),
    NotConfirming: () => List(),
    AcceptActiveRecordSuggestion: () => List(),
  });
  const projectInfo = selectActiveProjectInfo(state);
  if (!projectInfo) {
    console.error('Cannot mergeClusters - no handle on active project info');
    return;
  }
  const clusterIds = clustersToMerge.map(c => c.clusterId).toSet();
  dispatch(mergeCluster(state, pane, projectInfo, clusterIds, clusterIds.first(), verificationType));
};

export const dropMergeClusters = (
  targetClusterId: string,
  pane: PaneE,
  fromPane: PaneE,
  verificationType: VerificationTypeE,
): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const projectInfo = selectActiveProjectInfo(state);
  if (!projectInfo) {
    console.error('Cannot dropMergeClusters - no handle on active project info');
    return;
  }
  const clusterIds = state.suppliers[pane].confirmingVerificationAction.case<Set<string>>({
    MergeClustersToTarget: ({ clusters, targetCluster }) => clusters.toSet().add(targetCluster).map(c => c.clusterId),
    MergeClusters: () => Set(),
    MoveRecordsToNewCluster: () => Set(),
    MoveRecordsToExistingCluster: () => Set(),
    NotConfirming: () => Set(),
    AcceptActiveRecordSuggestion: () => Set(),
  });
  dispatch(mergeCluster(state, pane, projectInfo, clusterIds, targetClusterId, verificationType));
};

export const assignClusters = (
  pane: PaneE,
  usersToAssign: Set<string>,
  usersToUnassign: Set<string>,
): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    console.error('Cannot assignClusters - unifiedDatasetName is undefined');
    return;
  }
  const { suppliers: { [pane]: { selectedSuppliers } } } = state;
  dispatch({ type: 'Suppliers.assignClusters', pane });
  const assignPromise = usersToAssign.size ? DedupClient.assignClusters(
    unifiedDatasetName,
    { usernames: usersToAssign.toJS(), clusterIds: selectedSuppliers.toJS() },
  ) : null;
  const unassignPromise = usersToUnassign.size ? DedupClient.removeAssignments(
    unifiedDatasetName,
    { usernames: usersToUnassign.toJS(), clusterIds: selectedSuppliers.toJS() },
  ) : null;
  Promise.all([assignPromise, unassignPromise])
    .then(results => {
      const handleError = (error: FetchError) =>
        apiError(dispatch, `Error assigning ${getTerm(state, 'suppliers')}`, error, { type: 'Suppliers.assignClustersFailed', pane });
      if (results[0]?.isFailure) return handleError(results[0].error);
      if (results[1]?.isFailure) return handleError(results[1].error);
      dispatch({ type: 'Suppliers.assignClustersCompleted', pane });
    });
};

export const resolveClusters = (pane: PaneE): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    console.error('Cannot resolveClusters - unifiedDatasetName is undefined');
    return;
  }
  const { suppliers } = state;
  const currentUsername = getAuthorizedUser(state)?.username;
  if (!currentUsername) {
    console.error('Cannot resolveClusters - logged in username is undefined');
    return;
  }
  const clusterIds = getSelectedClusters(suppliers, pane)
    .map(s => s.clusterId);

  dispatch({ type: 'Suppliers.resolveClusters', pane });
  DedupClient.resolveAssignments(
    unifiedDatasetName,
    { usernames: [currentUsername], clusterIds: clusterIds.toArray() },
  ).then(Result.handler(
    () => dispatch({ type: 'Suppliers.resolveClustersCompleted', pane }),
    (error) => apiError(dispatch, `Error resolving ${getTerm(state, 'suppliers')}`, error, { type: 'Suppliers.resolveClustersFailed', pane }),
  ));
};

export const fetchSimilarSuppliers = (pane: PaneE): AppThunkAction<void> => (dispatch, getState) => {
  dispatch({ type: 'Suppliers.fetchSimilarSuppliers', pane });
  const state = getState();
  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    console.error('Cannot fetchSimilarSuppliers - unifiedDatasetName is undefined');
    return;
  }
  const { suppliers: { [pane]: { selectedSuppliers } } } = state;
  const supplierId = selectedSuppliers.first(undefined);
  if (!supplierId) {
    console.error('Cannot fetchSimilarSuppliers - no selected supplier(s)');
    return;
  }
  DedupClient.getClusterSimilarities(unifiedDatasetName, supplierId)
    .then(Result.handler(
      (similarities) => {
        const ids = similarities.map(s => s.id).toSet();
        const fetchPromise: Promise<FetchResult<Set<Cluster>> | null> = ids.isEmpty()
          ? Promise.resolve(null)
          : fetchClustersByIdFromStorage(unifiedDatasetName, ids);
        fetchPromise.then(result => {
          if (result?.isFailure) {
            return apiError(dispatch, `Error loading similar ${getTerm(state, 'suppliers')}`, result.error,
              { type: 'Suppliers.fetchSimilarSuppliersFailed', supplierId, pane });
          }
          const items = result?.data || Set<Cluster>();
          const similarSuppliers = List(items).map((cluster) => {
            const similarity = similarities.find((s) => cluster.clusterId === s.id);
            if (similarity) {
              return new ClusterSimilarity({ ...similarity.toJSON(), cluster: Cluster.fromJSON(cluster) });
            }
            return undefined;
          }).filter(isDefined)
            .sortBy(cs => -cs.similarity);
          dispatch({ type: 'Suppliers.fetchSimilarSuppliersCompleted', supplierId, similarSuppliers, pane });
        });
      },
      error => apiError(dispatch, `Error loading similar ${getTerm(state, 'suppliers')}`, error,
        { type: 'Suppliers.fetchSimilarSuppliersFailed', supplierId, pane }),
    ));
};

function doFetchClusterCounts(unifiedDatasetName: string, clusterChanges: ClusterChangesE[]): Promise<FetchResult<number>> {
  return DedupClient.fetchClusters(unifiedDatasetName, { offset: 0, limit: 0, clusterChanges })
    .then(result => Result.mapMonad(result,
      ({ total }) => total,
      error => error,
    ));
}

function doFetchCountHistory({ datasetName, recordIdField, recordIdValue, countField }: {
  datasetName: string,
  recordIdField: string,
  recordIdValue: string | number,
  countField: string,
}): Promise<List<CountsOverTime>> {
  return DatasetClient.fetchRecordVersions(datasetName, [{ [recordIdField]: recordIdValue }])
    .then(({ versions }) => {
      return List(versions).map((v: $TSFixMe) => CountsOverTime.withStringDate({
        time: v.materializationDate,
        count: v.record[countField],
      })).sortBy(v => v.time);
    });
}

const fetchClusterStats = (dispatch: ThunkDispatch<AppState, undefined, AppAction>, state: AppState) => {
  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    console.error('Cannot fetch cluster stats - unifiedDatasetName is undefined');
    return;
  }
  const clusterCountDataset = `${unifiedDatasetName}_dedup_published_cluster_counts`;
  dispatch({ type: 'PublishClusterDialog.fetchClusterCounts' });
  doFetchCountHistory({ datasetName: clusterCountDataset, recordIdField: 'id', recordIdValue: 0, countField: 'clusterCount' })
    .then(counts => {
      dispatch({ type: 'PublishClusterDialog.fetchClusterCountsCompleted', clusterCounts: counts });
    }, response => {
      dispatch({ type: 'PublishClusterDialog.fetchClusterCountsFailed', response });
    });

  dispatch({ type: 'PublishClusterDialog.fetchModifiedClusters' });
  doFetchClusterCounts(unifiedDatasetName, CHANGED_CLUSTERS_SET.toJSON()).then(Result.handler(
    total => dispatch({ type: 'PublishClusterDialog.fetchModifiedClustersCompleted', modifiedClusters: total }),
    error => apiError(dispatch, 'Could not fetch cluster stats', error),
  ));

  dispatch({ type: 'PublishClusterDialog.fetchNewClusters' });
  doFetchClusterCounts(unifiedDatasetName, NEW_CLUSTERS_SET.toJSON()).then(Result.handler(
    total => dispatch({ type: 'PublishClusterDialog.fetchNewClustersCompleted', newClusters: total }),
    error => apiError(dispatch, 'Could not fetch cluster stats', error),
  ));

  dispatch({ type: 'PublishClusterDialog.fetchRemovedClusters' });
  doFetchClusterCounts(unifiedDatasetName, REMOVED_CLUSTERS_SET.toJSON()).then(Result.handler(
    total => dispatch({ type: 'PublishClusterDialog.fetchRemovedClustersCompleted', removedClusters: total }),
    error => apiError(dispatch, 'Could not fetch cluster stats', error),
  ));
};

function doFetchNumClusterRecords(unifiedDatasetDoc: Document<Dataset>, recordChanges: Set<ClusterRecordChangesValueType>): Promise<number> {
  return ProcurifyClient.fetchTransactions(
    new TxnUrlBuilder(DEDUP)
      .unifiedDatasetName(unifiedDatasetDoc.data.name)
      .pageNum(0)
      .pageSize(0)
      .changeStatusFilters(recordChanges),
    unifiedDatasetDoc,
  ).then(({ total }) => total);
}

const fetchRecordClusterStats = (dispatch: ThunkDispatch<AppState, undefined, AppAction>, state: AppState) => {
  const unifiedDatasetDoc = selectActiveProjectInfo(state)?.unifiedDatasetDoc;
  if (!unifiedDatasetDoc) {
    console.error('Cannot fetch record cluster stats - no handle on unified dataset document');
    return;
  }

  dispatch({ type: 'PublishClusterDialog.fetchMovedRecords' });
  doFetchNumClusterRecords(unifiedDatasetDoc, CHANGED_CLUSTER_SET)
    .then(total => {
      dispatch({ type: 'PublishClusterDialog.fetchMovedRecordsCompleted', movedRecords: total });
    });

  dispatch({ type: 'PublishClusterDialog.fetchUnmovedRecords' });
  doFetchNumClusterRecords(unifiedDatasetDoc, UNCHANGED_CLUSTER_SET)
    .then(total => {
      dispatch({ type: 'PublishClusterDialog.fetchUnmovedRecordsCompleted', unmovedRecords: total });
    });

  dispatch({ type: 'PublishClusterDialog.fetchNewRecords' });
  doFetchNumClusterRecords(unifiedDatasetDoc, NEW_RECORD_SET)
    .then(total => {
      dispatch({ type: 'PublishClusterDialog.fetchNewRecordsCompleted', newRecords: total });
    });

  dispatch({ type: 'PublishClusterDialog.fetchDeletedRecords' });
  doFetchNumClusterRecords(unifiedDatasetDoc, DELETED_RECORD_SET)
    .then(total => {
      dispatch({ type: 'PublishClusterDialog.fetchDeletedRecordsCompleted', deletedRecords: total });
    });
};

export const fetchAllPublishDialogStats = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  dispatch({ type: 'PublishClusterDialog.show' });
  fetchRecordClusterStats(dispatch, state);
  fetchClusterStats(dispatch, state);
};

// Publish clusters
export const publishClusters = (): AppThunkAction<void> => (dispatch, getState) => {
  dispatch({ type: 'Suppliers.publishClusters' });
  const state = getState();
  const recipeId = selectActiveProjectInfo(state)?.recipeId;
  if (!recipeId) {
    console.error('Cannot publish clusters - do not know what current recipe id is');
    return;
  }
  RecipeClient.runOperation(recipeId, PUBLISH_CLUSTERS)
    .then(() => {
      dispatch({ type: 'Suppliers.publishClustersCompleted' });
    }, response => {
      dispatch({ type: 'Suppliers.publishClustersFailed' });
      dispatch({ type: SHOW, detail: 'Error submitting publish clusters job', response });
    });
};

export const fetchClusterPublishInfo = (clusterId: string): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    console.error('Cannot fetchClusterPublishInfo - unifiedDatasetName is undefined');
    return;
  }
  dispatch({ type: 'Suppliers.fetchClusterPublishInfo' });
  Promise.all([
    doFetchCountHistory({
      datasetName: `${unifiedDatasetName}_dedup_published_cluster_counts`,
      recordIdField: 'id',
      recordIdValue: 0,
      countField: 'clusterCount',
    }),
    DedupClient.getPublishedClusterVersions(unifiedDatasetName, [clusterId]),
  ]).then(([counts, publishedClusterVersions]) => {
    if (publishedClusterVersions.isFailure) {
      dispatch({ type: 'Suppliers.fetchClusterPublishInfoFailed', loadedPublishedClusterId: clusterId });
      return;
    }
    dispatch({ type: 'Suppliers.fetchClusterPublishInfoCompleted', publishTimes: counts.map(c => c.time), publishedClusterVersions: publishedClusterVersions.data, loadedPublishedClusterId: clusterId });
  }, response => {
    dispatch({ type: 'Suppliers.fetchClusterPublishInfoFailed', response, loadedPublishedClusterId: clusterId });
  });
};

export const toggleClusterDiffVisible = (pane: PaneE): AppThunkAction<void> => (dispatch) => {
  dispatch({ type: 'Suppliers.toggleClusterDiffVisible', pane });
  dispatch(fetchTransactions(pane));
};
