import React from 'react';
import { connect } from 'react-redux';

import AppState from '../stores/AppState';
import { pluralize } from '../utils/Strings';
import { getUserGroupsCount, getUsersCount } from './AccessControlStore';


/**
 * A component that renders the singular/plural form of an entity's string based on
 * the count of that entity, or "All <entity's plural form>" if appliesToAll is true.
 */
const Count: React.FC<{
  /**
   * Indicate whether to render the span with the "All" keyword, or using the value specified by
   * "count". False when not specified.
   */
  appliesToAll?: boolean
  /**
   * The value to display when appliesToAll is false
   */
  count: number
  /**
   * The singular form of the string being rendered
   */
  singular: string
  /**
   * The plural form of the string being rendered
   */
  plural: string
  /**
   * Optional class name for the span
   */
  className?: string
}> = ({ count, appliesToAll, singular, plural, className }) => (
  <span className={className}>{appliesToAll ? `All ${plural}` : `${count} ${pluralize(count, singular, plural)}`}</span>
);

interface UsersCountOwnProps {
  id: number
  className?: string
}

export const UsersCount = connect((state: AppState, { id }: UsersCountOwnProps) => {
  const userCount = getUsersCount(state)(id);
  return {
    count: userCount.count,
    singular: 'user',
    plural: 'users',
    appliesToAll: userCount.appliesToAll,
  };
})(props => <Count {...props} />);

interface UserGroupsCountOwnProps {
  id: number
  className?: string
}

export const UserGroupsCount = connect((state: AppState, { id }: UserGroupsCountOwnProps) => {
  const userGroupsCount = getUserGroupsCount(state)(id);
  return {
    count: userGroupsCount.count,
    singular: 'group',
    plural: 'groups',
    appliesToAll: userGroupsCount.appliesToAll,
  };
})(props => <Count {...props} />);
