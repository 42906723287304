import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import DocLink from '../components/DocLink';
import Checkbox from '../components/Input/Checkbox';
import { ORIGIN_ENTITY_ID, ORIGIN_SOURCE_NAME } from '../constants/ElasticConstants';
import PRODUCT_NAME from '../utils/ProductName';
import style from './EnableTransformations.module.scss';

const EnableTransformations = ({ enabled, alreadyEnabled, onChange }) => {
  const betaWarning = <span>Read more about our transformation capabilities <DocLink path="transformations#section-enabling-transformations-in-categorization-and-mastering-projects">here</DocLink>, and remember to maintain {PRODUCT_NAME} generated columns {ORIGIN_SOURCE_NAME} and {ORIGIN_ENTITY_ID} as strings before continuing with your project.</span>;

  const canNotDisableWarning = 'Once enabled, transformations cannot be disabled.';
  return (
    <div className={classNames(style.component, { [style.alreadyEnabled]: alreadyEnabled })}>
      <Checkbox
        title={(
          <span className={style.checkboxTitle}>
            Enable Transformations&nbsp;
          </span>
        )}
        titlePosition="right"
        value={alreadyEnabled ? true : enabled}
        onChange={alreadyEnabled ? () => {} : onChange}
      />
      {enabled || alreadyEnabled ? (
        <div className={style.warning}>
          {alreadyEnabled ? <span>{canNotDisableWarning} {betaWarning}</span> : <span>{betaWarning} {canNotDisableWarning}</span>}
        </div>
      ) : null}
    </div>
  );
};

EnableTransformations.propTypes = {
  alreadyEnabled: PropTypes.bool,
  enabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default EnableTransformations;
