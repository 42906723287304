import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import TamrIcon from '../components/TamrIcon';
import { AppState } from '../stores/MainStore';
import { toggleBookmarkedClusterId } from './GoldenRecordsAsync';
import { isClusterIdBookmarked } from './GoldenRecordsSelectors';
import style from './GoldenRecordsTable.module.scss';

type GoldenRecordsBookmarkCellOwnProps = {
  rowIndex: number
};

const GoldenRecordsBookmarkCell = connect((state: AppState, { rowIndex }: GoldenRecordsBookmarkCellOwnProps) => {
  const { goldenRecords: { draftPage } } = state;
  const clusterId = draftPage?.items.get(rowIndex)?.id; // uses the elastic document id to get the cluster id
  const isBookmarked = clusterId && isClusterIdBookmarked(state, clusterId);
  return { isBookmarked, clusterId };
}, {
  toggleBookmark: toggleBookmarkedClusterId,
})(({ toggleBookmark, isBookmarked, clusterId }) => {
  return (
    <div className={style.bookmarkCell}>
      <TamrIcon
        iconName={isBookmarked ? 'bookmark' : 'bookmark-empty'}
        size={16}
        className={classNames(style.bookmarkIcon, { [style.isBookmarked]: isBookmarked })}
        onClick={(e) => {
          e.stopPropagation(); // we do not want the row to activate
          clusterId && toggleBookmark(clusterId);
        }}
      />
    </div>
  );
});

export default GoldenRecordsBookmarkCell;
