import { List } from 'immutable';
import _ from 'underscore';

import FeedbackStatus from '../constants/FeedbackStatus';
import Response from '../constants/Response';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

class AssignmentInfo extends getModelHelpers({
  status: { type: ArgTypes.valueIn(_.values(FeedbackStatus)) },
  archived: { type: ArgTypes.bool },
  created: { type: ArgTypes.timestamp },
  lastModified: { type: ArgTypes.timestamp },
}, 'AssignmentInfo')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class AssignmentInfoRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  static fromJSON(obj: $TSFixMe) {
    checkArg({ obj }, ArgTypes.object);
    return new AssignmentInfo({
      status: obj.status,
      archived: obj.archived,
      created: obj.created,
      lastModified: obj.lastModified || obj.created,
    });
  }
}

class CategorizationSuggestion extends getModelHelpers({
  response: { type: ArgTypes.nullable(ArgTypes.valueIn(_.values(Response))) },
  categoryPath: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
  categoryId: { type: ArgTypes.number },
  reason: { type: ArgTypes.nullable(ArgTypes.string) },
  created: { type: ArgTypes.timestamp },
  lastModified: { type: ArgTypes.timestamp },
}, 'CategorizationSuggestion')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class CategorizationSuggestionRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  static fromJSON(obj: $TSFixMe) {
    checkArg({ obj }, ArgTypes.object);
    return new CategorizationSuggestion({
      response: obj.response,
      categoryPath: List(obj.categoryPath),
      categoryId: obj.categoryId,
      reason: obj.reason,
      created: obj.created,
      lastModified: obj.lastModified,
    });
  }

  static fromManualCategorization(obj: $TSFixMe) {
    checkArg({ obj }, ArgTypes.object);
    const { categoryPath, categoryId, reason, timestamp } = obj;
    return new CategorizationSuggestion({
      response: Response.AGREE,
      categoryPath,
      categoryId,
      reason,
      created: new Date(timestamp).getTime(),
      lastModified: new Date(timestamp).getTime(),
    });
  }
}

class EsFeedback extends getModelHelpers({
  username: { type: ArgTypes.string },
  assignmentInfo: { type: ArgTypes.nullable(ArgTypes.instanceOf(AssignmentInfo)) },
  responses: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(CategorizationSuggestion)) },
}, 'EsFeedback')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class EsFeedbackRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  static fromJSON(obj: $TSFixMe) {
    checkArg({ obj }, ArgTypes.object);
    return new EsFeedback({
      username: obj.username,
      assignmentInfo: obj.assignmentInfo ? AssignmentInfo.fromJSON(obj.assignmentInfo) : undefined,
      responses: List(obj.responses).map(CategorizationSuggestion.fromJSON),
    });
  }

  getResponseForCategory(categoryId: number) {
    return this.responses.find(suggestion => suggestion.categoryId === categoryId)?.response;
  }

  get isAssigned() {
    return !!this.assignmentInfo;
  }

  get isResponded() {
    return !!this.assignmentInfo && this.assignmentInfo.status !== FeedbackStatus.PENDING;
  }
}

export {
  AssignmentInfo,
  CategorizationSuggestion,
};

// We want EsFeedback to be the default mode of interacting with this file
export default EsFeedback;
