import { ArgTypes } from '../utils/ArgValidation';
import { checkTypesAndCast } from '../utils/CheckTypesAndCast';
import { genericRuleArgTypes } from './Rule';
import * as SingleInputAttributeRule from './SingleInputAttributeRule';

// com.tamr.recipe.models.flows.goldenRecords.CountRule

export const TYPE = 'Count';

export interface CountRule extends SingleInputAttributeRule.AbstractSingleInputAttributeRule {
  type: typeof TYPE
}
export const argTypes = {
  type: ArgTypes.eq(TYPE as typeof TYPE),
  ...genericRuleArgTypes,
} as const;
export const fromJSON = checkTypesAndCast<CountRule, typeof argTypes>(argTypes);

export const argType = ArgTypes.object.withShape(argTypes);
