import { CommonTokenStream, InputStream } from 'antlr4/index';
import { Set } from 'immutable';
import _ from 'lodash';
/* eslint camelcase: 0 */
import PropTypes from 'prop-types';
import { TransformsLexer, TransformsParser, TransformsVisitor } from 'tamr-transforms';

import { ArgTypes, checkArg, checkReturn } from '../utils/ArgValidation';
import Dataref from './Dataref';
import Model from './Model';

function startParsing(input) {
  const inputStream = new InputStream(input);
  const lexer = new TransformsLexer(inputStream);
  lexer.removeErrorListeners();
  const tokens = new CommonTokenStream(lexer);
  const parser = new TransformsParser(tokens);
  parser.removeErrorListeners();
  return { inputStream, lexer, tokens, parser };
}

function toTransformText(transforms) {
  return transforms
    .filter(transform => !!transform)
    .map(transform => transform.toTransformText())
    .join(';\n');
}

/**
 * Utility functions for Identifiers.
 * Must work with tokens matching the IDENTIFIER rule from Transforms.g4,
 * which at the time of writing was:
 *
 * IDENTIFIER
 *   : [a-zA-Z_] [a-zA-Z_0-9]*
 *   | '"' (~ '"' | '""')+ '"'
 *   ;
 */
export const Identifiers = {
  escape(id) { return id && id.replace(/"/g, '""'); },
  unescape(id) { return id && id.replace(/""/g, '"'); },
};

/**
 * Utility functions for string literals.
 * Works with tokens matching the STRING_LITERAL rule from Transforms.g4,
 * which at the time of writing was:
 *
 * STRING_LITERAL:
 *   : '\'' (ESC | ~ ['\\])* '\''
 *   ;
 *
 * fragment ESC : '\\' (['\\/bfnrt] | 'u' HEX HEX HEX HEX);
 * fragment HEX : [0-9a-fA-F];
 */
export const StringLiterals = {
  escape(text) { return text && text.replace(/[\\']/g, '\\$&'); },
  unescape(text) { return text && text.replace(/\\([\\'])/g, '$1'); },
};

// syntax highlighting token name source of truth
// MUST BE KEEP UP TO DATE WITH CSS CLASS NAMES IN 'Transform.module.scss'
// NOTE: CodeMirror prefixes the class names defined here with 'cm-' when it generates the highlighting
export const UNIFIED_ATTRIBUTE_TOKEN_NAME = 'unified-attribute';
export const NAMED_UNIFIED_ATTRIBUTE_TOKEN_NAME = 'named-unified-attribute';
export const TEMP_UNIFIED_ATTRIBUTE_TOKEN_PREFIX = 'temp-';
export const TEMP_UNIFIED_ATTRIBUTE_TOKEN_NAME = TEMP_UNIFIED_ATTRIBUTE_TOKEN_PREFIX + UNIFIED_ATTRIBUTE_TOKEN_NAME;
export const TEMP_NAMED_UNIFIED_ATTRIBUTE_TOKEN_NAME = TEMP_UNIFIED_ATTRIBUTE_TOKEN_PREFIX + NAMED_UNIFIED_ATTRIBUTE_TOKEN_NAME;
export const KEYWORD_TOKEN_NAME = 'keyword';
export const DATASET_TOKEN_NAME = 'dataset';
export const NUMBER_TOKEN_NAME = 'number';
export const STRING_TOKEN_NAME = 'string';
export const OPERATOR_TOKEN_NAME = 'operator';
export const ATOM_TOKEN_NAME = 'atom';
export const FUNCTION_TOKEN_NAME = 'function';
export const HINT_TOKEN_NAME = 'hint';


class BaseVisitor extends TransformsVisitor {
  visitId(ctx) {
    return ctx.children
      .map(child => child.getText())
      .map(text => (text[0] === '"' ? Identifiers.unescape(text.slice(1, -1)) : text))
      .join('');
  }

  visitFunction_name(ctx) {
    return this.visitId(ctx.id());
  }

  visitDataset(ctx) {
    const name = ctx.dataset_name();
    if (name) {
      return this.visitDataset_name(name);
    }
    const expr = ctx.dataset_expr();
    if (expr) {
      return this.visitDataset_expr(expr);
    }
  }

  visitDataset_name(ctx) {
    return Dataref.id(this.visitId(ctx.id()));
  }

  visitDataset_list(ctx) {
    if (!ctx) return [];
    return Object.values(ctx.dataset()).filter(ds => ds).map(ds => this.visitDataset(ds));
  }

  visitDataset_expr(ctx) {
    const func = this.visitFunction_name(ctx.function_name());
    const args = this.visitDataset_list(ctx.dataset_list());
    return Dataref.fn(func, args);
  }
}

const referencedColumns = new class extends BaseVisitor {
  visit(ctx) {
    try {
      const children = ctx instanceof Array ? ctx : [ctx] || [];
      return _.uniq(_.compact(_.flattenDeep(
        children.map(child => super.visit(child)),
      )));
    } catch (e) {
      return [];
    }
  }

  visitLambda(ctx) {
    return _.difference(this.visit(ctx.expr()), this.visit(ctx.identifier_list()));
  }

  visitFunction_name() {
    return null; // don't include function calls when finding referenced columns
  }

  visitRecord_field(ctx) {
    return this.visit(ctx.expr() || ctx.id());
  }

  visitGetField(ctx) {
    return this.visit(ctx.expr()); // Ignore the record field: ctx.id()
  }
}();

class ReferencedDatasetVisitor extends BaseVisitor {
  constructor(ctx) {
    super();
    this.referencedDatasets = [];
    this.producedDatasets = [];
    this.visit(ctx);
  }

  addName(name) {
    if (!this.producedDatasets.includes(name) && !this.referencedDatasets.includes(name)) {
      this.referencedDatasets.push(name);
    }
  }

  visit(ctx) {
    return ctx && super.visit(ctx);
  }

  visitUseOp(ctx) {
    ctx.dataset() && this.addName(this.visitDataset(ctx.dataset()));
  }

  visitJoinOp(ctx) {
    ctx.dataset() && this.addName(this.visitDataset(ctx.dataset()));
  }

  visitLookupOp(ctx) {
    ctx.dataset() && this.addName(this.visitDataset(ctx.dataset()));
  }

  visitUnionOp(ctx) {
    this.visitDataset_list(ctx.dataset_list()).forEach(name => this.addName(name));
  }

  visitLabelOp(ctx) {
    if (ctx.dataset_name() != null) {
      this.producedDatasets.push(super.visitDataset_name(ctx.dataset_name()));
    }
    ctx.op() && this.visit(ctx.op());
  }
}

export const propType = PropTypes.shape({
  accept: PropTypes.func.isRequired,
});

export const argType = ArgTypes.object.withShape({
  accept: ArgTypes.func,
});

function TransformModel(namesToTypesAndDefaults) {
  return class extends Model(namesToTypesAndDefaults) {
    accept(visitor) {
      return visitor[`visit${this.className}`](this);
    }
  };
}

export class Script extends TransformModel({
  op: { type: ArgTypes.string, defaultValue: '' },
}) {
  static get className() { return 'Script'; }
  get className() { return Script.className; }
  get referencedDatasets() {
    return Set(new ReferencedDatasetVisitor(startParsing(this.op).parser.ops()).referencedDatasets);
  }
  get producedDatasets() {
    return Set(new ReferencedDatasetVisitor(startParsing(this.op).parser.ops()).producedDatasets);
  }
  toString() {
    return `Script({ op: ${this.op} })`;
  }
  toTransformText() {
    return this.op;
  }
  toJSON() {
    return {
      type: this.className,
      op: this.op,
    };
  }

  static fromTransformList(transformList) {
    checkArg({ transformList }, ArgTypes.Immutable.list.of(supportedTransformArgType)); // eslint-disable-line no-use-before-define
    return new Script({ op: transformList.map(transform => transform.toTransformText()).join(';\n') });
  }
}

export class Fill extends TransformModel({
  coalesce: { type: ArgTypes.bool, defaultValue: false },
  column: { type: ArgTypes.orUndefined(ArgTypes.string) },
  value: { type: ArgTypes.string, defaultValue: '' },
}) {
  static get className() { return 'Fill'; }
  get className() { return Fill.className; }
  toString() {
    return `Fill({ column: ${this.column}, value: ${this.value}, coalesce: ${this.coalesce} })`;
  }
  toTransformText() {
    return this.coalesce
      ? `select *, case when "${Identifiers.escape(this.column)}" is not empty then "${Identifiers.escape(this.column)}" `
        + `else array(\'${StringLiterals.escape(this.value)}\') end as "${Identifiers.escape(this.column)}"`
      : `select *, \'${StringLiterals.escape(this.value)}\' as "${Identifiers.escape(this.column)}"`;
  }
  toJSON() {
    return {
      type: this.className,
      coalesce: this.coalesce,
      column: this.column,
      value: this.value,
    };
  }
}

export class Formula extends TransformModel({
  column: { type: ArgTypes.nullable(ArgTypes.string) },
  expr: { type: ArgTypes.string, defaultValue: '' },
}) {
  static get className() { return 'Formula'; }
  get className() { return Formula.className; }
  get inputColumns() {
    return referencedColumns.visit(startParsing(this.expr).parser.expr());
  }
  toString() {
    return `Formula({ column: ${this.column}, expr: ${this.expr} })`;
  }
  toTransformText() {
    const inputColIdentifiers = this.inputColumns
      .filter(col => col !== this.column) // don't need to re-select the output column
      .map(col => `"${Identifiers.escape(col)}"`)
      .join(', ');
    const inputColText = inputColIdentifiers ? ', ' + inputColIdentifiers : '';
    const expr = _.isEmpty(this.expr) ? "''" : this.expr; // a totally empty clause would cause unexpected problems
    return `select *, ${expr} as "${Identifiers.escape(this.column)}"${inputColText}`;
  }
  toJSON() {
    return {
      type: this.className,
      column: this.column,
      expr: this.expr,
    };
  }
}

export class MultiFormula extends TransformModel({
  targetColumns: { type: ArgTypes.array.of(ArgTypes.string), defaultValue: [] },
  expr: { type: ArgTypes.string, defaultValue: '' },
}) {
  static get className() { return 'MultiFormula'; }
  get className() { return MultiFormula.className; }
  toString() {
    return `MultiFormula({ targetColumns: ${this.targetColumns}, exprPattern: ${this.expr} })`;
  }
  toTransformText() {
    if (this.expr === '' || this.targetColumns.length === 0) {
      return '';
    }
    // replace $COL whereever it appears independently (ie not part of another word)
    const independentRegex = new RegExp(/(^|[^\w])([$]col)([^\w]|$)/, 'ig');

    const expressions = this.targetColumns.map(columnName => {
      let statement = this.expr;
      statement = statement.replaceAll(independentRegex, `$1"${Identifiers.escape(columnName)}"$3`);
      return statement + ` as "${Identifiers.escape(columnName)}"`;
    });
    return 'select *, ' + expressions.join(', ');
  }
  toJSON() {
    return {
      type: this.className,
      targetColumns: this.targetColumns,
      expr: this.expr,
    };
  }
  static fromTransformList(transformList) {
    checkArg({ transformList }, ArgTypes.Immutable.list.of(supportedTransformArgType)); // eslint-disable-line no-use-before-define
    return new Script({ op: 'select *;' });
  }
}

// TODO change collection types to Immutable
export class Unpivot extends TransformModel({
  unpivotColumns: { type: ArgTypes.array.of(ArgTypes.string), defaultValue: [] },
  variableColumn: { type: ArgTypes.orUndefined(ArgTypes.string) },
  valueColumn: { type: ArgTypes.orUndefined(ArgTypes.string) },
  dependentColumns: { type: ArgTypes.array.of(ArgTypes.string), defaultValue: [] },
  dependentColumnValues: { type: ArgTypes.object, defaultValue: {} },
}) {
  static get className() { return 'Unpivot'; }
  get className() { return Unpivot.className; }
  toString() {
    return `Unpivot({ unpivotColumns: ${this.unpivotColumns}, variableColumn: ${this.variableColumn}, valueColumn: ${this.valueColumn}, dependentColumns: ${this.dependentColumns}, dependentColumnValues: ${JSON.stringify(this.dependentColumnValues)} })`;
  }
  toTransformText() {
    let unpivotScript;
    if (_.isEmpty(this.dependentColumns)) {
      unpivotScript = this.unpivotColumns.map(col => `('${StringLiterals.escape(col)}' as "${Identifiers.escape(this.variableColumn)}", "${Identifiers.escape(col)}" as "${Identifiers.escape(this.valueColumn)}")`);
    } else {
      unpivotScript = this.unpivotColumns.map(col => {
        const dependentClauses = _.uniq(_.compact(this.dependentColumns)).map((dep, i) => {
          let dependentClause;
          if (this.dependentColumnValues[col] && this.dependentColumnValues[col][i]) {
            dependentClause = `"${Identifiers.escape(this.dependentColumnValues[col][i])}" as "${Identifiers.escape(dep)}"`;
          } else {
            dependentClause = `null as "${Identifiers.escape(dep)}"`;
          }
          return dependentClause;
        });
        return `('${StringLiterals.escape(col)}' as "${Identifiers.escape(this.variableColumn)}", "${Identifiers.escape(col)}" as "${Identifiers.escape(this.valueColumn)}", ${dependentClauses.join(', ')})`;
      });
    }
    return `unpivot ${unpivotScript.join(', ')}`;
  }
  toJSON() {
    return {
      type: this.className,
      unpivotColumns: this.unpivotColumns,
      variableColumn: this.variableColumn,
      valueColumn: this.valueColumn,
      dependentColumns: this.dependentColumns,
      dependentColumnValues: this.dependentColumnValues,
    };
  }
}

export const convertStorageJSONToObject = (transformJSON) => {
  switch (transformJSON?.type) {
    case Script.className:
      return new Script(transformJSON);
    case Fill.className:
      return new Fill(transformJSON);
    case Formula.className:
      return new Formula(transformJSON);
    case Unpivot.className:
      return new Unpivot(transformJSON);
    case MultiFormula.className:
      return new MultiFormula(transformJSON);
    default:
      console.error('Unsupported transformation type', transformJSON?.type);
      return null;
  }
};

class SemanticOpExtractor extends BaseVisitor {
  constructor(tokens) {
    super();
    this.tokens = tokens;
  }

  visitParseSemanticOp(ctx) {
    const text = this.tokens.getText();
    try {
      const op = this.visit(ctx.semantic_op());
      return op.toTransformText() === text ? op : new Script({ op: text });
    } catch (e) {
      return new Script({ op: text });
    }
  }

  visitSemantic_ops(ctx) {
    return ctx.semantic_op().map(opCtx => this.visit(opCtx));
  }

  visitSemanticFill(ctx) {
    const column = this.visitId(ctx.id());
    const value = this.visitLiteralString(ctx.STRING_LITERAL());
    return new Fill({ coalesce: false, column, value });
  }

  visitSemanticFillCoalesce(ctx) {
    const column = this.visitId(ctx.id()[0]);
    const value = this.visitLiteralString(ctx.STRING_LITERAL());
    return new Fill({ coalesce: true, column, value });
  }

  visitSemanticFormula(ctx) {
    const column = this.visitId(ctx.id()[0]);
    const expr = this.tokens.getText(ctx.expr());
    return new Formula({ column, expr });
  }

  visitSemanticMultiFormula(ctx) {
    const columns = ctx.semantic_multi_formula_clause().map(s => this.visitId(s.id()));
    const firstExpr = this.tokens.getText(ctx.semantic_multi_formula_clause(0).expr());
    const exprPattern = firstExpr.replaceAll('"' + this.visitId(ctx.semantic_multi_formula_clause(0).id()) + '"', '$COL');
    return new MultiFormula({ targetColumns: columns, expr: exprPattern });
  }

  visitSemanticUnpivot(ctx) {
    const firstClause = ctx.semantic_unpivot_clause(0);
    const unpivotColumns = ctx.semantic_unpivot_clause().map(s => this.visitLiteralString(s.STRING_LITERAL()));
    const variableColumn = this.visitId(firstClause.id(0));
    const valueColumn = this.visitId(firstClause.id(2));
    const dependentColumns = [];
    const dependentColumnValues = {};
    unpivotColumns.map((col, i) => {
      // for each of the unpivot cols, there will be a new semantic_unpivot_clause.
      // The first three ids will be for non-dependent information ("var name", "val value", "val name")
      // The fourth id will be the first dep col value
      const thisClause = ctx.semantic_unpivot_clause()[i];
      const clauseIds = thisClause.id();
      const numClauseIds = clauseIds.length;
      const numClauseDepIds = (numClauseIds - 3);
      const clauseDepVals = thisClause.dep_value();
      dependentColumnValues[col] = {};
      for (let j = 0; j < numClauseDepIds; j++) {
        dependentColumns[j] = this.visitId(clauseIds[3 + j]);
        const dependentId = clauseDepVals[j].id();
        dependentColumnValues[col][j] = dependentId ? this.visitId(dependentId) : null;
      }
    });
    return new Unpivot({ unpivotColumns, variableColumn, valueColumn, dependentColumns, dependentColumnValues });
  }

  visitSemanticScript(ctx) {
    const op = this.tokens.getText(ctx);
    return new Script({ op });
  }

  visitLiteralInteger(ctx) { return parseInt(ctx.getText(), 10); }

  visitLiteralNumber(ctx) { return parseFloat(ctx.getText()); }

  visitLiteralString(ctx) { return StringLiterals.unescape(ctx.getText().slice(1, -1)); }

  visitLiteralNull() { return null; }

  visitLiteralTrue() { return true; }

  visitLiteralFalse() { return false; }
}

class ContextFinder extends BaseVisitor {
  constructor(context, cursorPos, cursorLine) {
    super();
    this.cursorPos = cursorPos;
    this.cursorLine = cursorLine;
    this.context = context;
  }

  visit(ctx) {
    if (ctx != null) {
      if (ctx.children) {
        return super.visit(ctx.children);
      }
      return super.visit(ctx);
    }
  }

  visitHintOp(ctx) {
    let openContext = null;
    let closeContext = null;
    ctx.children.forEach((child) => {
      if (child.getText() === '(') {
        openContext = child.symbol;
      }
      if (child.getText() === ')') {
        closeContext = child.symbol;
      }
    });
    if (openContext) {
      if (!closeContext) {
        closeContext = { line: Infinity, column: Infinity };
      }
      if ((openContext.line - 1 < this.cursorLine && this.cursorLine < closeContext.line - 1)
        || (openContext.line - 1 === this.cursorLine && closeContext.line - 1 !== this.cursorLine && openContext.column <= this.cursorPos - 1)
        || (closeContext.line - 1 === this.cursorLine && openContext.line - 1 !== this.cursorLine && closeContext.column > this.cursorPos - 1)
        || (closeContext.line - 1 === this.cursorLine && openContext.line - 1 === this.cursorLine
          && closeContext.column > this.cursorPos - 1 && openContext.column <= this.cursorPos - 1)) {
        this.context.push('hint');
        return;
      }
    }
    this.visit(ctx);
  }
}

class SyntaxHighlighter extends BaseVisitor {
  constructor(collector, unifiedAttributes, functions, keywords) {
    super();
    this.collector = collector;
    this.unifiedAttributes = unifiedAttributes || [];
    this.functions = functions || [];
    this.keywords = keywords || [];
  }

  visit(ctx) {
    if (ctx != null) {
      if (ctx.children) {
        return super.visit(ctx.children);
      }
      return super.visit(ctx);
    }
  }

  visitTerminal(ctx) {
    if (this.keywords.includes(ctx.getText().toUpperCase())) {
      this.collector.push({ text: ctx.getText(), start: ctx.symbol.start, end: ctx.symbol.stop, token: KEYWORD_TOKEN_NAME });
    }
  }

  // Unfortunately these operator rules cannot be combined as they would make the grammar 'mutually left-recursive'
  visitEquals(ctx) {
    this.collector.push({ text: ctx.getText(), start: ctx.getChild(1).symbol.start, end: ctx.getChild(1).symbol.stop, token: OPERATOR_TOKEN_NAME });
    this.visit(ctx);
  }
  visitNotEquals(ctx) {
    this.collector.push({ text: ctx.getText(), start: ctx.getChild(1).symbol.start, end: ctx.getChild(1).symbol.stop, token: OPERATOR_TOKEN_NAME });
    this.visit(ctx);
  }
  visitGT(ctx) {
    this.collector.push({ text: ctx.getText(), start: ctx.getChild(1).symbol.start, end: ctx.getChild(1).symbol.stop, token: OPERATOR_TOKEN_NAME });
    this.visit(ctx);
  }
  visitGTE(ctx) {
    this.collector.push({ text: ctx.getText(), start: ctx.getChild(1).symbol.start, end: ctx.getChild(1).symbol.stop, token: OPERATOR_TOKEN_NAME });
    this.visit(ctx);
  }
  visitLT(ctx) {
    this.collector.push({ text: ctx.getText(), start: ctx.getChild(1).symbol.start, end: ctx.getChild(1).symbol.stop, token: OPERATOR_TOKEN_NAME });
    this.visit(ctx);
  }
  visitLTE(ctx) {
    this.collector.push({ text: ctx.getText(), start: ctx.getChild(1).symbol.start, end: ctx.getChild(1).symbol.stop, token: OPERATOR_TOKEN_NAME });
    this.visit(ctx);
  }
  visitMultiplication(ctx) {
    this.collector.push({ text: ctx.getText(), start: ctx.getChild(1).symbol.start, end: ctx.getChild(1).symbol.stop, token: OPERATOR_TOKEN_NAME });
    this.visit(ctx);
  }
  visitDivision(ctx) {
    this.collector.push({ text: ctx.getText(), start: ctx.getChild(1).symbol.start, end: ctx.getChild(1).symbol.stop, token: OPERATOR_TOKEN_NAME });
    this.visit(ctx);
  }
  visitAddition(ctx) {
    this.collector.push({ text: ctx.getText(), start: ctx.getChild(1).symbol.start, end: ctx.getChild(1).symbol.stop, token: OPERATOR_TOKEN_NAME });
    this.visit(ctx);
  }
  visitSubtraction(ctx) {
    this.collector.push({ text: ctx.getText(), start: ctx.getChild(1).symbol.start, end: ctx.getChild(1).symbol.stop, token: OPERATOR_TOKEN_NAME });
    this.visit(ctx);
  }

  visitFunction_name(ctx) {
    if (this.functions.includes(super.visitId(ctx.id()).toLowerCase())) {
      this.collector.push({ text: ctx.getText(), start: ctx.start.start, end: ctx.stop.stop, token: FUNCTION_TOKEN_NAME });
    }
  }

  visitId(ctx, tokenName) {
    if (ctx && ctx.start && ctx.stop) {
      tokenName = tokenName || UNIFIED_ATTRIBUTE_TOKEN_NAME;
      if (!this.unifiedAttributes.includes(super.visitId(ctx))) {
        tokenName = TEMP_UNIFIED_ATTRIBUTE_TOKEN_PREFIX + tokenName;
      }
      this.collector.push({
        text: ctx.getText(),
        start: ctx.start.start,
        end: ctx.stop.stop,
        token: tokenName + ' line=' + ctx.start.line + ' pos=' + (ctx.start.column + 1),
      });
      this.visit(ctx);
    }
  }

  visitNamed_expr(ctx) {
    if (ctx.children) {
      if (ctx.children.length === 1) {
        this.visit(ctx);
      } else if (ctx.children.length === 3) {
        this.visit(ctx.getChild(0));
        this.visit(ctx.getChild(1));
        this.visitId(ctx.getChild(2), NAMED_UNIFIED_ATTRIBUTE_TOKEN_NAME);
      }
    }
  }

  visitHint(ctx) {
    this.collector.push({ text: ctx.getText(), start: ctx.start.start, end: ctx.stop.stop, token: HINT_TOKEN_NAME });
  }

  visitDataset_name(ctx) {
    this.collector.push({ text: ctx.getText(), start: ctx.start.start, end: ctx.stop.stop, token: DATASET_TOKEN_NAME });
  }

  visitDataset_expr(ctx) {
    this.visitFunction_name(ctx.function_name());
    this.visitDataset_list(ctx.dataset_list());
  }

  visitLabelOp(ctx) {
    if ((ctx.getChild(0) && ctx.getChild(0).getText() === ':') || (ctx.getChild(1) && ctx.getChild(1).getText() === ':')) {
      this.visit(ctx);
    }
  }

  visitLiteralLong(ctx) {
    this.collector.push({ text: ctx.getText(), start: ctx.start.start, end: ctx.start.stop, token: NUMBER_TOKEN_NAME });
  }
  visitLiteralInteger(ctx) {
    this.collector.push({ text: ctx.getText(), start: ctx.start.start, end: ctx.start.stop, token: NUMBER_TOKEN_NAME });
  }
  visitLiteralNumber(ctx) {
    this.collector.push({ text: ctx.getText(), start: ctx.start.start, end: ctx.start.stop, token: NUMBER_TOKEN_NAME });
  }
  visitLiteralString(ctx) {
    this.collector.push({ text: ctx.getText(), start: ctx.start.start, end: ctx.start.stop, token: STRING_TOKEN_NAME });
  }
  visitLiteralNull(ctx) {
    this.collector.push({ text: ctx.getText(), start: ctx.start.start, end: ctx.start.stop, token: ATOM_TOKEN_NAME });
  }
  visitLiteralTrue(ctx) {
    this.collector.push({ text: ctx.getText(), start: ctx.start.start, end: ctx.start.stop, token: ATOM_TOKEN_NAME });
  }
  visitLiteralFalse(ctx) {
    this.collector.push({ text: ctx.getText(), start: ctx.stop.start, end: ctx.start.stop, token: ATOM_TOKEN_NAME });
  }
}

export const supportedTransformArgType = ArgTypes.oneOf(
  ArgTypes.instanceOf(Fill),
  ArgTypes.instanceOf(Unpivot),
  ArgTypes.instanceOf(Formula),
  ArgTypes.instanceOf(Script),
  ArgTypes.instanceOf(MultiFormula),
);
export const supportedTransformClassNameArgType = ArgTypes.valueIn([
  'Fill',
  'Unpivot',
  'Formula',
  'Script',
  'MultiFormula',
]);

export const parse = checkReturn(supportedTransformArgType, (transformsAsString) => {
  const { tokens, parser } = startParsing(transformsAsString);
  return new SemanticOpExtractor(tokens).visit(parser.parseSemanticOp());
});

export const getSyntaxHighlightingTokens = (transformsAsString, unifiedAttributes, functions, keywords, contentEnvelope) => {
  // create parser
  const { parser, tokens } = startParsing(transformsAsString);
  // initialize our collector of highlighting tokens
  let collector = [];
  // create highlighter and generate highlighting tokens through the magic of visitors
  const s = new SyntaxHighlighter(collector, unifiedAttributes, functions, keywords);
  if (contentEnvelope) {
    s.visit(parser.expr());
  } else {
    s.visit(parser.ops());
  }
  // get comments from comment channel and add their highlighting tokens to the collector
  const comments = tokens.tokens.filter(k => k.channel === 2 || k.channel === 3);
  collector = s.collector.concat(comments.reduce((acc, comment) => {
    acc.push({ start: comment.start, end: comment.stop, token: 'comment' });
    return acc;
  }, []));

  return collector;
};

export const getContext = (transformsAsString, cursorPos, cursorLine, contentEnvelope) => {
  // create parser and generate tokens
  const { parser } = startParsing(transformsAsString);
  const context = [];
  // find the context we are currently in through the magic of visitors
  const s = new ContextFinder(context, cursorPos, cursorLine);
  if (contentEnvelope) {
    s.visit(parser.expr());
  } else {
    s.visit(parser.ops());
  }
  return context[0];
};

export default {
  parse,
  getSyntaxHighlightingTokens,
  getContext,
  toTransformText,
  argType,
  propType,
  Fill,
  Formula,
  Script,
  Unpivot,
  MultiFormula,
};
