import { ColumnCellProps, ElementOrFunc } from 'fixed-data-table-2';
import PropTypes, { InferProps } from 'prop-types';
import React, { Requireable } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import AttributeProfilingInfo from '../models/AttributeProfilingInfo';
import DisplayColumn from '../models/DisplayColumn';
import { TypePropType } from '../transforms/models/Types';
import ElasticUtils from '../utils/ElasticUtils';
import PRODUCT_NAME from '../utils/ProductName';
import { noop } from '../utils/Values';
import style from './DataColumn.module.scss';
import MultiValue, { recordValueShim } from './MultiValue';
import ProfiledHeaderCell from './ProfiledHeaderCell';
import SortableHeaderCell from './SortableHeaderCell';
import Cell from './Table/Cell';
import Column from './Table/Column';
import TamrIcon from './TamrIcon';
import TooltipTrigger from './TooltipTrigger';


const dataCellPropTypes = {
  name: PropTypes.string.isRequired,
  rowIndex: PropTypes.number.isRequired,
  rows: ImmutablePropTypes.list.isRequired, // NB checking contents is super slow!
};
type DataCellProps = InferProps<typeof dataCellPropTypes>;
const DataCell: React.FC<DataCellProps> = ({ rows, name, rowIndex }) => {
  const { data, originalData, renderRaw } = rows.get(rowIndex).getValue(name);
  return <Cell><MultiValue values={recordValueShim({ value: data, raw: renderRaw, originalData })} /></Cell>;
};
DataCell.propTypes = dataCellPropTypes;

const profilableSortableHeaderPropTypes = {
  columnSortStates: ImmutablePropTypes.map.isRequired,
  currentlyProfiling: PropTypes.bool.isRequired,
  dataType: TypePropType,
  displayName: PropTypes.string,
  isGeneratedAttribute: PropTypes.bool,
  isMlAttribute: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onSort: PropTypes.func as Requireable<(name: string) => void>,
  profilingInfo: PropTypes.instanceOf(AttributeProfilingInfo),
};
type ProfilableSortableHeaderProps = InferProps<typeof profilableSortableHeaderPropTypes>;
export const ProfilableSortableHeader: React.FC<ProfilableSortableHeaderProps> = ({ profilingInfo, name, displayName, columnSortStates, onSort, currentlyProfiling, dataType, isGeneratedAttribute, isMlAttribute }) => {
  const sortFunc = onSort || noop;
  if (profilingInfo) {
    return (
      <ProfiledHeaderCell
        {...{ profilingInfo, dataType }}
        col={name}
        displayName={displayName}
        sortCallback={() => sortFunc(name)}
        sortState={columnSortStates.get(ElasticUtils.sanitizeField(name))}
        currentlyProfiling={!!currentlyProfiling}
        isGeneratedAttribute={isGeneratedAttribute}
        isMlAttribute={isMlAttribute}
      />
    );
  }
  return (
    <SortableHeaderCell
      col={name}
      display={(
        <span className={style.dataColumnHeader}>
          {isMlAttribute ? (
            <TooltipTrigger placement="top" content="This attribute is included in machine learning">
              <TamrIcon size={12} iconName="tamr-icon-ml-on" className={style.tamrIconMlOn} />
            </TooltipTrigger>
          ) : null}
          {isGeneratedAttribute ? (
            <TooltipTrigger placement="top" content={`This attribute was created by ${PRODUCT_NAME}`}>
              <TamrIcon size={14} iconName="tamr-icon-logo" className={style.tamrIconLogo} />
            </TooltipTrigger>
          ) : null }
          {displayName}
        </span>
      )}
      sortCallback={() => sortFunc(name)}
      sortState={columnSortStates.get(ElasticUtils.sanitizeField(name))}
    />
  );
};

const dataColumnPropTypes = {
  cell: PropTypes.any as Requireable<ElementOrFunc<ColumnCellProps>>,
  rows: ImmutablePropTypes.list.isRequired, // NB checking contents is super slow!
  columnSortStates: ImmutablePropTypes.map.isRequired,
  onSort: PropTypes.func as Requireable<(name: string) => void>,
  column: PropTypes.instanceOf(DisplayColumn).isRequired,
  profilingInfo: PropTypes.instanceOf(AttributeProfilingInfo),
  currentlyProfiling: PropTypes.bool,
  isMlAttribute: PropTypes.bool,
  dataType: TypePropType,
  isGeneratedAttribute: PropTypes.bool,
};

type DataColumnProps = InferProps<typeof dataColumnPropTypes>;

const DataColumn: React.FC<DataColumnProps> = ({ cell, rows, columnSortStates, onSort, column: { name, displayName, width }, profilingInfo, currentlyProfiling, isMlAttribute, dataType, isGeneratedAttribute }) => {
  return (
    <Column
      {... { width }}
      key={name}
      columnKey={name}
      cell={cell || (({ rowIndex }) => <DataCell {...{ rows, name, rowIndex, dataType }} />)}
      header={<ProfilableSortableHeader {...{ profilingInfo, name, displayName, columnSortStates, onSort, currentlyProfiling: !!currentlyProfiling, isMlAttribute, dataType, isGeneratedAttribute }} />}
      allowCellsRecycling
      isResizable
    />
  );
};

export default DataColumn;
