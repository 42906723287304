import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import ConditionalButton from '../components/ConditionalButton';
import Labeler from '../components/Labeler';
import PopoverTrigger from '../components/PopoverTrigger';
import TooltipTrigger from '../components/TooltipTrigger';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import Tag from '../models/Tag';
import { createTag, linkTag, removeLink } from './TagApi';
import style from './TagSelector.module.scss';
import { setTagColor } from './TagStore';
/**
 * Link tags to selected datasets
 */

const TagSelector = connect((state) => {
  const { tags: { allTags, searchTagValue, showingManageDialog } } = state;
  return { allTags, searchTagValue, showingManageDialog };
}, {
  onCreateTag: createTag,
  onLinkTag: linkTag,
  onRemoveLink: removeLink,
  onSetCreateNew: (createNew) => ({ type: 'Tags.setCreateNew', createNew }),
  onSetManageDialogVisibility: (showingManageDialog) => ({ type: 'Tags.setManageDialogVisibility', showingManageDialog }),
  onSetTagSearch: (searchTagValue) => ({ type: 'Tags.setTagSearch', searchTagValue }),
})(/**
 * Link tags to selected datasets
 */

  class TagSelector extends React.Component {
    static propTypes = {
      allTags: ImmutablePropTypes.setOf(Document.propType.withDataType(Tag)).isRequired,
      onCreateTag: PropTypes.func.isRequired,
      onLinkTag: PropTypes.func.isRequired,
      onRemoveLink: PropTypes.func.isRequired,
      onSetCreateNew: PropTypes.func.isRequired,
      onSetManageDialogVisibility: PropTypes.func.isRequired,
      onSetTagSearch: PropTypes.func.isRequired,
      searchTagValue: PropTypes.string,
      selectedDatasets: ImmutablePropTypes.setOf(Document.propType.withDataType(Dataset)).isRequired,
    };

    state = { tagPopoverOpened: false };

    getTagPanelHeight = (numTags) => {
      const maxTagsInScroll = 6;
      const tagHeightElement = 27;
      const searchHeight = 35;
      const padding = numTags ? 7 : 0;
      return padding + searchHeight + Math.min(maxTagsInScroll, numTags) * tagHeightElement;
    };

    getSelectionState = (tagId, props) => {
      const selectedDatasetCount = props.selectedDatasets.size;
      const taggedDatasetCount = props.selectedDatasets.filter(d => (d.data.metadata.get('tag') ? _.contains(d.data.metadata.get('tag').map(t => t.id), tagId) : false)).size;
      if (taggedDatasetCount === 0) {
        return 'unselected';
      } if (selectedDatasetCount === taggedDatasetCount) {
        return 'selected';
      } if (selectedDatasetCount > taggedDatasetCount) {
        return 'semi';
      }
    };

    getTagOptions = () => {
      const { allTags, searchTagValue } = this.props;
      const options = new List(allTags)
        .filter(({ data: { name } }) => (searchTagValue ? name.toLowerCase().indexOf(searchTagValue.toLowerCase()) > -1 : true))
        .map(({ id: { id }, data: { name } }) => {
          const color = setTagColor(name, id);
          return {
            key: name,
            display: (
              <TooltipTrigger
                className={style.tagSelectorOverflowTooltip}
                placement="left"
                content={name}
                trigger={['hover']}
            >
                <div className={style.tagOption}>
                  <div className={style.tagPill} style={{ background: color, paddingTop: '3px', paddingBottom: '3px' }} role="button" tabIndex="0">{name}</div>
                </div>
              </TooltipTrigger>
            ),
            selectionState: this.getSelectionState(id, this.props),
          };
        });
      if (this.state.tagOrder) {
        return options.sortBy((option) => {
          return this.state.tagOrder.indexOf(option.key);
        });
      }
      return options.sortBy(tag => tag.selectionState);
    };

    linkTag = (tag) => {
      const { onRemoveLink, onLinkTag } = this.props;
      const selectedDatasetNames = this.props.selectedDatasets.map(d => d.data.name);
      if (tag.selectionState === 'selected') { // If all of the datasets have the tag already, remove
        onRemoveLink(selectedDatasetNames, tag.key);
      } else { // If none / some of the datasets have the tag, add to all
        onLinkTag(selectedDatasetNames, tag.key);
      }
    };

    createSearchedTag = (strippedSearchTagValue) => {
      const { onCreateTag, onLinkTag } = this.props;
      const selectedDatasetNames = this.props.selectedDatasets.map(d => d.data.name);
      onCreateTag(strippedSearchTagValue)
        .then((newTag) => {
          onLinkTag(selectedDatasetNames, newTag.data.name);
        }); // TODO: should this reset TagActions.setTagSearch('') ?
    };

    setManageDialogVisibility = (visible) => {
      const { onSetManageDialogVisibility } = this.props;
      onSetManageDialogVisibility(visible);
      this.refs.target.removeHideListener(); // Keep the tag popover open while the tag manager is open
    };

    setTagOrder = (props) => {
      const { allTags } = props;
      const tagOrder = new List(allTags)
        .map(({ id: { id }, data: { name } }) => {
          return {
            key: name,
            selectionState: this.getSelectionState(id, props),
          };
        })
        .sortBy(tag => tag.selectionState)
        .map((option) => {
          return option.key;
        });
      this.setState({ tagOrder });
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
    // When the manage dialog is closed renable the popover hide listener
      if (this.props.selectedDatasets !== nextProps.selectedDatasets && !this.state.tagPopoverOpened) {
        this.setTagOrder(nextProps);
      }
      if (this.props.showingManageDialog && !nextProps.showingManageDialog) {
        this.refs.target.attachHideListener();
      }
    }

    renderCreateSearched = () => {
      const { searchTagValue, allTags } = this.props;
      const strippedSearchTagValue = searchTagValue.toLowerCase().replace(/[^\w\s\-\$\.\+\!\*\'\(\)\,]/gi, '');
      return (
        <div className={style.tagsNoMatch}>
          { allTags.size ? <div>No tags match that name</div> : null }
          <div>
            <Button
              className="tag-create-search-button"
              buttonType="Link"
              onClick={() => this.createSearchedTag(strippedSearchTagValue)}
              title="Click to create tag"
          >
              + create new tag "{strippedSearchTagValue}"
            </Button>
          </div>
        </div>
      );
    };

    renderTagsLabeler = () => {
      const { searchTagValue, onSetTagSearch } = this.props;
      const tagOptions = this.getTagOptions();
      const tagPanelHeight = tagOptions.isEmpty() ? 'auto' : this.getTagPanelHeight(tagOptions.size);
      const placeholder = tagOptions.isEmpty() ? 'Create a tag' : 'Search or create new tag';
      return (
        <div style={{ height: tagPanelHeight }} className={tagOptions.isEmpty() ? style.noTags : null}>
          <Labeler
            onOptionSelect={this.linkTag}
            onSearchValueChange={onSetTagSearch}
            options={tagOptions}
            searchValue={searchTagValue}
            placeholder={placeholder}
        />
        </div>
      );
    };

    render() {
      const { searchTagValue, selectedDatasets } = this.props;
      return (
        <PopoverTrigger
          placement="bottom"
          ref="target"
          className={style.tagSelectorPopover}
          onOverlayExited={() => {
            this.setTagOrder(this.props);
            this.setState({ tagPopoverOpened: false });
          }}
          content={
            <div>
              <div className={style.tagFilterHeader}>
                <span className={style.tagSelectorTitle}>Tags</span>
                <TooltipTrigger
                  placement="left"
                  content="Configure Tags"
                  trigger={['hover']}
              >
                  <Button
                    className={style.tagSelectorBtn}
                    buttonType="Link"
                    onClick={_.partial(this.setManageDialogVisibility, true)}
                >
                    MANAGE
                  </Button>
                </TooltipTrigger>
              </div>
              {this.renderTagsLabeler()}
              {searchTagValue.length > 0 && this.getTagOptions().isEmpty() ? this.renderCreateSearched() : null}
            </div>
        }
      >
          <ConditionalButton
            preconditions={Map().set('You must select one or more datasets', !selectedDatasets.isEmpty())}
            buttonType="Secondary"
            onClick={() => {
              this.setState({ tagPopoverOpened: true });
            }}
        >
            Tag
          </ConditionalButton>
        </PopoverTrigger>
      );
    }
  });

export default TagSelector;
