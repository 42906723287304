import { List, Map } from 'immutable';
import $ from 'jquery';
import uri from 'urijs';

import * as DatasetClient from '../api/DatasetClient';
import { SHOW } from '../errorDialog/ErrorDialogActionTypes';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import { QueryBuilder } from '../models/doc/Query';
import { ArgTypes, checkReturn } from '../utils/ArgValidation';
import { ALL, NON_PROJECT_DERIVED, PROJECT_GENERATED, SOURCE, SYSTEM_DATASET, UNIFIED_DATASET } from './DatasetCatalogFilterType';
import { getFilterInfo } from './DatasetCatalogStore';
import { doFetchDatasetsProfilingAndExportsWithQuery } from './DatasetsApi';

export const fetch = () => (dispatch, getState) => {
  dispatch({ type: 'DatasetCatalog.fetch' });
  const filterInfo = getFilterInfo(getState());
  const { page, pageSize, search, filterType, unifiedDatasetIds, projectGeneratedIds } = filterInfo;
  const offset = page * pageSize;
  const query = new QueryBuilder().offset(offset).limit(pageSize);
  if (search) {
    query.whereData('name').isLike(search);
  }
  let necessarilyEmpty = false;
  switch (filterType) {
    case ALL:
      break;
    case SOURCE:
      query.whereData('upstreamDatasets').isLike('[]');
      // source datasets don't have upstreamDatasets and are not system datasets

      query.disjunct()
        .whereData('metadata', 'isSystemDataset').isEqualTo('false')
        .whereData('metadata', 'isSystemDataset').isNull()
        .buildDisjunct();

      // Excluding unified dataset from source datasets
      query.where('id').isNotInIntegers(unifiedDatasetIds.toArray());

      break;
    case UNIFIED_DATASET:
      if (unifiedDatasetIds.isEmpty()) {
        necessarilyEmpty = true;
      } else {
        query.where('id').isInIntegers(unifiedDatasetIds.toArray());
      }
      break;
    case PROJECT_GENERATED:
      if (unifiedDatasetIds.isEmpty()) {
        necessarilyEmpty = true;
      } else {
        query.whereData('upstreamDatasets').isLike('dataset');
        query.where('id').isInIntegers(projectGeneratedIds.toArray());
      }
      break;
    case NON_PROJECT_DERIVED:
      query.whereData('upstreamDatasets').isLike('dataset');
      if (!unifiedDatasetIds.isEmpty()) {
        query.where('id').isNotInIntegers(unifiedDatasetIds.toArray());
      }
      if (!projectGeneratedIds.isEmpty()) {
        query.where('id').isNotInIntegers(projectGeneratedIds.toArray());
      }
      break;
    case SYSTEM_DATASET:
      query.whereData('metadata', 'isSystemDataset').isEqualTo('true');
      break;
  }
  const builtQuery = query.build();
  const ajaxCalls = [
    necessarilyEmpty
      ? $.Deferred().resolve({ datasets: List(), numDatasets: 0, profiling: Map(), exporting: Map() })
      : doFetchDatasetsProfilingAndExportsWithQuery(builtQuery),
    necessarilyEmpty ? Promise.resolve(List()) : DatasetClient.postStatusQuery(builtQuery),
  ];
  $.when(...ajaxCalls).then(({ datasets, numDatasets, profiling, exporting }, status) => {
    const numPages = Math.ceil(numDatasets / pageSize);
    dispatch({ type: 'DatasetCatalog.fetchCompleted', datasets, numDatasets, numPages, profiling, exporting, status: Map(status.map(s => [s.datasetName, s])), loadedFilterInfo: filterInfo });
  });
};

export const doFetchDatasetDescendants = checkReturn(
  ArgTypes.deferred.withResolution(ArgTypes.object.withShape({
    datasetName: ArgTypes.string,
    descendants: ArgTypes.Immutable.list.of(Document.argTypeWithNestedClass(Dataset)),
  })), (datasetName) => {
    return $.ajax({
      url: uri(SERVICES.dataset(`/datasets/${datasetName}/descendants`)),
      method: 'GET',
      context: this,
    }).then(items => {
      const descendants = List(items).map(d => Document.fromJSON(d, Dataset.fromJSON));
      return { datasetName, descendants };
    });
  });

export const beginConfirmingDelete = (datasets) => (dispatch) => {
  const datasetNames = datasets.map(dataset => dataset.data.name).toSet();
  dispatch({ type: 'DatasetCatalog.beginConfirmingDeleteAndFetchDescendants', datasetNames });
  // Check to see if any of the datasets have descendants.
  const ajaxCalls = datasetNames.map(datasetName => doFetchDatasetDescendants(datasetName)).toArray();
  $.when(...ajaxCalls).then((...descendants) => {
    const descendantMap = Map(List(descendants).map(tuple => [tuple.datasetName, tuple.descendants]));
    dispatch({ type: 'DatasetCatalog.fetchDescendantsCompleted', descendants: descendantMap });
  });
};

const deleteDataset = (dataset) => {
  return $.ajax({
    url: uri(SERVICES.procure(`/procurement/datasets/${dataset.id.id}`)),
    method: 'DELETE',
    cache: false,
    context: this,
  });
};

export const deleteDatasets = (datasetNames) => (dispatch, getState) => {
  dispatch({ type: 'DatasetCatalog.deleteDataset' });
  const { datasetCatalog: { datasets } } = getState();
  const ajaxCalls = datasetNames.map(dataset => {
    const confirmingDeleteForDataset = datasets.find(doc => doc.data.name === dataset);
    return deleteDataset(confirmingDeleteForDataset);
  }).toArray();

  $.when(...ajaxCalls).then(() => {
    dispatch({ type: 'DatasetCatalog.deleteDatasetCompleted' });
  }).fail(response => {
    dispatch({
      type: SHOW,
      detail: `Error deleting datasets '${datasetNames.toString()}'`,
      response,
    });
  });
};
