import React from 'react';
import { AutoSizer } from 'react-virtualized';

import TransactionTaskbar from './TransactionTaskbar';
import style from './TransactionTaskbarWrapper.module.scss';

class TransactionTaskbarWrapper extends React.PureComponent {
  render() {
    return (
      <div className={style.component}>
        <AutoSizer>
          {({ width, height }) => (
            <TransactionTaskbar
              {...this.props}
              width={width}
              height={height}
            />
          )}
        </AutoSizer>
      </div>
    );
  }
}

export default TransactionTaskbarWrapper;
