import { ArgTypes } from '../utils/ArgValidation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

class ActiveGeospatialAttribute extends getModelHelpers({
  name: { type: ArgTypes.string },
  isOpenDialog: { type: ArgTypes.bool, defaultValue: false },
}, 'ActiveGeospatialAttribute')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ActiveGeospatialAttributeRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

export default ActiveGeospatialAttribute;
