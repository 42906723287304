import React from 'react';
import { connect } from 'react-redux';

import Chrome from '../chrome/Chrome';
import style from '../goldenRecords/GoldenRecord.module.scss';
import GoldenRecordsRules from '../goldenRecords/GoldenRecordsRules';
import GoldenRecordsRulesSidebar from '../goldenRecords/GoldenRecordsRulesSidebar';
import { AppState } from '../stores/MainStore';


const GoldenRecordsRulesPage = connect((state: AppState) => {
  const { goldenRecordsRules: { showSidebar } } = state;
  return { showSidebar };
})(({ showSidebar }) => {
  return (
    <Chrome
      className={style.page}
      rightSidebar={<GoldenRecordsRulesSidebar />}
      isExpanded={showSidebar}
    >
      <GoldenRecordsRules />
    </Chrome>
  );
});

export default GoldenRecordsRulesPage;
