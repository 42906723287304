import classNames from 'classnames';
import { Map } from 'immutable';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { startAddDataset } from '../addDataset/AddDatasetAsync';
import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import ConditionalButton from '../components/ConditionalButton';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import Input from '../components/Input/Input';
import Selector from '../components/Input/Selector';
import TextArea from '../components/Input/TextArea';
import Toolbar from '../components/Toolbar';
import {
  createEnrichmentModule,
  fetchEnricherTypes,
  validateOutputName,
} from '../enrichment/EnrichmentAsync';
import { EnricherRegistration } from '../models/EnricherRegistration';
import { AppState } from '../stores/MainStore';
import { projectCreationPoliciesValid } from '../utils/Authorization';
import { getAuthorizedUser } from '../utils/Selectors';
import style from './CreateProjectDialogEnrichment.module.scss';
import ProjectPoliciesSelector, {
  FUTURE_PROJECT_ID,
  FUTURE_PROJECT_NAME,
} from './ProjectPoliciesSelector';
import { CANCEL_ADDING_PROJECT } from './ProjectsActionTypes';

const CreateProjectDialogEnrichment = connect((state: AppState) => {
  const {
    enrichment: { types, typesLoading, isOutputNameValid },
    accessControl: { projectDraftPolicyManager },
  } = state;
  return {
    enrichers: types,
    typesLoading,
    isOutputNameValid,
    projectDraftPolicyManager,
    user: getAuthorizedUser(state),
  };
}, {
  onCancelAddingProject: () => ({ type: CANCEL_ADDING_PROJECT }),
  onCancelEditingPolicies: () => ({ type: 'Projects.stopEditingPolicies' }),
  onChangeOutputName: (name: string) => validateOutputName(name),
  showFileUploadDialog: () => startAddDataset({ isCatalogPage: true }),
  onFetchEnricherTypes: fetchEnricherTypes,
  onCreateModule: (displayName: string, description: string, enricherType: EnricherRegistration, outputDataset: string) =>
    createEnrichmentModule(displayName, description, enricherType, outputDataset),
})(({ typesLoading, enrichers, isOutputNameValid, onChangeOutputName, onCancelAddingProject, onCancelEditingPolicies, onCreateModule, onFetchEnricherTypes, projectDraftPolicyManager, user }) => {
  React.useEffect(() => {
    onFetchEnricherTypes();
  }, []);
  const [displayName, setDisplayName] = useState('');
  const [description, setDescription] = useState('');
  const [enricherType, setEnricherType] = useState<EnricherRegistration>({
    id: '',
    location: '',
    name: '',
    schema: { inputs: [], outputs: [] },
    type: '',
    version: '',
  });
  const [outputDataset, setOutputDataset] = useState('');

  const values = enrichers.map(e => ({ value: e, display: e.name }));
  const validEnricher = enrichers.find(v => v === enricherType);

  const content = (
    <div className={classNames('create-project', 'enrichment')}>
      <div className={style.instructions}>Start creating an Enrichment module after a few a quick steps.</div>
      <Input
        autoFocusOnMount
        onChange={v => setDisplayName(v)}
        type="text"
        title="Project name"
        value={displayName}
      />
      <div className={style.selectEnricherText}>Select an Enricher</div>
      <Selector
        disabled={typesLoading}
        onChange={(v) => { setEnricherType(v); }}
        values={values}
        value={enricherType}
      />
      <div className={isOutputNameValid ? style.outputNameText : style.outputErrorText}>
        {isOutputNameValid ?
          'Specify a unique output dataset name.' :
          'There is an existing dataset with the name \'' + outputDataset + '\'. Specify a unique name.'}
      </div>
      <Input
        componentClassName={style.outputName}
        onChange={v => { onChangeOutputName(v); setOutputDataset(v); }}
        type="text"
        title="Output dataset"
        value={outputDataset}
        invalid={!isOutputNameValid}
      />
      <TextArea
        className={style.description}
        onChange={(v: string) => setDescription(v)}
        type="Text"
        placeholder="Description (optional)"
        value={description}
      />
      <div className={style.policiesHeader}>Select project policies<div className={style.policiesSubheader}>{user?.admin ? '' : 'Project must be a resource of at least one policy'}</div></div>
      <ProjectPoliciesSelector projectName={FUTURE_PROJECT_NAME} projectId={FUTURE_PROJECT_ID} rowClassName={classNames(style.policiesTableRow)} headerClassName={classNames(style.policiesTableHeader)} />
    </div>
  );
  const footer = (
    <Toolbar
      right={<ButtonToolbar>
        <Button
          buttonType="Secondary"
          onClick={() => { onCancelAddingProject(); onCancelEditingPolicies(); }}>Cancel</Button>
        <ConditionalButton
          tooltipPlacement="top"
          preconditions={Map({
            'Please enter a name for the project': !!displayName,
            'Please enter a valid name for the output dataset': !!outputDataset && isOutputNameValid,
            'Please select a valid enricher': !!validEnricher,
            'Please add the project to a policy as a resource': projectCreationPoliciesValid(user, projectDraftPolicyManager),
          })}
          onClick={() => { onCreateModule(displayName, description, enricherType, outputDataset); }}
          buttonType="Primary"
        >Create Project</ConditionalButton>

      </ButtonToolbar>} />
  );
  return (
    <Dialog
      className={classNames('create-project-dialog', 'enrichment')}
      show
      onHide={() => { onCancelAddingProject(); onCancelEditingPolicies(); }}
      header={<div className="create-project-dialog-header"> Start a new Enrichment project </div>}
      body={content}
      footer={footer}
      dialogStyle={DialogStyle.PRIMARY}
    />
  );
});

export default CreateProjectDialogEnrichment;
