import { ProjectPolicyManager } from '../accessControl/AccessControlStore';
import { refreshAuthCache } from '../auth/AuthCacheAsync';
import * as RecipeClient from '../api/RecipeClient';
import RecipeType, { DEDUP, RecipeTypeTSType } from '../constants/RecipeType';
import { SHOW } from '../errorDialog/ErrorDialogActionTypes';
import Document from '../models/doc/Document';
import Project, { ENABLE_TRANSFORMATIONS_METADATA_KEY } from '../models/Project';
import Recipe from '../models/Recipe';
import { AppThunkAction } from '../stores/AppAction';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { history } from '../utils/History';
import { getModuleIdForProject } from '../utils/Selectors';
import { $TSFixMe } from '../utils/typescript';
import { FUTURE_PROJECT_ID, FUTURE_PROJECT_NAME } from './ProjectPoliciesSelector';
import {
  CREATE_PROJECT,
  CREATE_PROJECT_COMPLETED,
  DELETE_PROJECT,
  DELETE_PROJECT_COMPLETED,
  FETCH,
  FETCH_COMPLETED,
  INIT_PROJECT,
  INIT_PROJECT_COMPLETED,
  INIT_PROJECT_FAILED,
  STOP_EDITING_PROJECT_POLICIES,
  UPDATE_PROJECT_COMPLETED,
} from './ProjectsActionTypes';
import { getFilterInfo } from './ProjectsStore';


const TERM_METADATA_KEY = 'terms';

export const fetch = (): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const filterInfo = getFilterInfo(state);
  dispatch({ type: FETCH });
  return RecipeClient.fetchProjectsWithStatus().then((projectsWithStatus) => {
    dispatch({ type: FETCH_COMPLETED, projectsWithStatus, filterInfo });
  });
};

export const initProject = (projectId: number, parameters: {[key: string]: any}): AppThunkAction<void> => (dispatch) => {
  checkArg({ projectId }, ArgTypes.number);
  checkArg({ parameters }, ArgTypes.object); // passed directly to server as Metadata
  dispatch({ type: INIT_PROJECT, projectId });
  return RecipeClient.initProject(projectId, parameters)
    .then(() => {
      dispatch({ type: INIT_PROJECT_COMPLETED });
    })
    .catch(response => {
      dispatch({ type: INIT_PROJECT_FAILED });
      dispatch({ type: SHOW,
        detail: 'Error initializing project',
        response,
      });
    });
};

/**
 * SR 5/12/2019
 * This is used as the delete path for both modules and projects, as they
 *   both show up on the projects page, and all modules have a connected
 *   project that they control.
 * In both cases the PROJECT id comes into this method, and this method must
 *   determine whether that project is connected to a module.
 */
export const deleteProject = (projectId: number): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ projectId }, ArgTypes.number);
  const state = getState();
  const moduleId = getModuleIdForProject(state, { projectId });
  dispatch({ type: DELETE_PROJECT, projectId, moduleId });
  // if moduleId is defined, this project is controlled by a module
  // in that case, delete the module, otherwise delete the project directly
  const deletePromise = moduleId
    ? RecipeClient.deleteModule(moduleId)
    : RecipeClient.deleteProject(projectId);
  deletePromise
    .then(() => dispatch({ type: DELETE_PROJECT_COMPLETED }))
    .catch((response) => dispatch({ type: SHOW, detail: 'Error deleting project', response }));
};

export const updateProject = (updatedProjectDoc: Document<Project>): AppThunkAction<void> => (dispatch) => {
  RecipeClient.updateProject(updatedProjectDoc).then(() => {
    dispatch({ type: UPDATE_PROJECT_COMPLETED });
  }).catch((response) => {
    dispatch({ type: SHOW, detail: 'Error updating project', response });
  });
};

export const updateRecipe = ({ recipeId, version, recipe }: {
  recipeId: number
  version?: number
  recipe: Recipe

}): AppThunkAction<void> => (dispatch) => {
  checkArg({ recipeId }, ArgTypes.number);
  checkArg({ version }, ArgTypes.oneOf(ArgTypes.number, ArgTypes.undefined));
  checkArg({ recipe }, ArgTypes.instanceOf(Recipe));
  RecipeClient.updateRecipe({ recipeId, version, recipe }).then(() => {
  }).catch((response) => {
    dispatch({ type: SHOW, detail: 'Error updating recipe', response });
  });
};

export const createProject = ({ type, name, description, terms, enableTransformations }: {
  type: RecipeTypeTSType
  name: string
  description?: string
  terms: $TSFixMe
  enableTransformations: boolean
}): AppThunkAction<void> => {
  return async (dispatch, getState) => {
    const { accessControl: { projectDraftPolicyManager } } = getState();
    checkArg({ type }, ArgTypes.valueIn(RecipeType));
    checkArg({ name }, ArgTypes.string);
    checkArg({ description }, ArgTypes.oneOf(ArgTypes.string, ArgTypes.missing));
    checkArg({ terms }, ArgTypes.object);
    checkArg({ enableTransformations }, ArgTypes.bool);
    checkArg({ projectDraftPolicyManager }, ArgTypes.instanceOf(ProjectPolicyManager));
    const query = {
      name,
      description: description || '',
      resourcePolicyIds: projectDraftPolicyManager?.draftPolicyResourceship.toArray(),
      memberPolicyIds: projectDraftPolicyManager?.draftPolicyMembership.toArray(),
    };
    dispatch({ type: CREATE_PROJECT });
    const newProject = await RecipeClient.createProject(type, query).catch((response) => {
      dispatch({
        type: SHOW,
        detail: 'Error creating project',
        response,
      });
    });

    // As permissions are updated during project creation, we must force-refresh auth
    await refreshAuthCache(dispatch);

    const updatedProjectDoc = Document.fromJSON(newProject, Project.fromJSON)
      .updateIn(['data', 'metadata'], metadata => {
        return metadata.set(TERM_METADATA_KEY, terms)
          .set(ENABLE_TRANSFORMATIONS_METADATA_KEY, enableTransformations ? true : undefined);
      });
    const newProjectSMRecipeId = updatedProjectDoc.data.steps.get(0)?.id;
    await RecipeClient.updateProject(updatedProjectDoc).then(() => {
      dispatch({
        type: STOP_EDITING_PROJECT_POLICIES,
        projectName: FUTURE_PROJECT_NAME,
        projectId: FUTURE_PROJECT_ID,
      });
      return RecipeClient.fetchProjectsWithStatus();
    }).then((projectsWithStatus) => {
      dispatch({
        type: CREATE_PROJECT_COMPLETED,
        projectsWithStatus,
      });
      history.push(type === DEDUP ? `/datasets/recipe/${newProjectSMRecipeId}` : `/dashboard/recipe/${newProjectSMRecipeId}`);
    }).catch((response) => {
      dispatch({
        type: SHOW,
        detail: 'Error creating project',
        response,
      });
      dispatch({
        type: STOP_EDITING_PROJECT_POLICIES,
        projectName: FUTURE_PROJECT_NAME,
        projectId: FUTURE_PROJECT_ID,
      });
    });
  };
};
