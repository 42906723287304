import { List } from 'immutable';

import modelJSON from '../../../../../connect/auth/models/src/main/resources/fixtures/models/UserRoles.json';
import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes, toJSON } from './Model';


export class UserRoles extends getModelHelpers({
  username: { type: ArgTypes.string },
  groups: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
  admin: { type: ArgTypes.bool },
}, 'UserRoles')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class UserRolesRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  toggleAdmin() {
    return this.update('admin', s => !s);
  }

  toJSON() {
    return toJSON(super.toJSON());
  }

  static fromJSON(obj: $TSFixMe) {
    return new UserRoles({
      username: obj.username,
      groups: List(obj.groups || []),
      admin: obj.admin,
    });
  }
}

export const userRolesJSON = modelJSON;
export const sampleUserRoles = UserRoles.fromJSON(modelJSON);

export default UserRoles;
