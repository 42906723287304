import { List, Set } from 'immutable';

import TaggedUnion, { InferConstructedKind } from '../models/TaggedUnion';
import { ArgTypes } from '../utils/ArgValidation';
import { checkTypesAndCast } from '../utils/CheckTypesAndCast';
import { update, updater } from '../utils/Collections';
import { FilterClusterRecords } from './FilterClusterRecords';


// com.tamr.recipe.models.flows.goldenRecords.DatasetFilter

export const TYPE = 'Dataset';

const bucketIdKindDefinitions = {
  Priority: {
    index: ArgTypes.wholeNumber,
  },
  Excluded: {},
};
export const BucketId = TaggedUnion(bucketIdKindDefinitions, 'BucketId');
export type BucketIdType = InferConstructedKind<typeof bucketIdKindDefinitions>

export interface DatasetFilter {
  type: typeof TYPE
  priorities: string[][],
  excluded: string[],
}
export const argTypes = {
  type: ArgTypes.eq(TYPE as typeof TYPE),
  priorities: ArgTypes.array.of(ArgTypes.array.of(ArgTypes.string)),
  excluded: ArgTypes.array.of(ArgTypes.string),
} as const;
export const fromJSON = checkTypesAndCast<DatasetFilter, typeof argTypes>(argTypes);

export const argType = ArgTypes.object.withShape(argTypes);

export function isDatasetFilter(filter: FilterClusterRecords | null | undefined): filter is DatasetFilter {
  return filter?.type === TYPE;
}

export function withImmutableCollections(filter: DatasetFilter) {
  return { ...filter, priorities: List(filter.priorities).map(p => Set(p)), excluded: Set(filter.excluded) };
}
export type DatasetFilterWithImmutableCollections = ReturnType<typeof withImmutableCollections>;
export function withoutImmutableCollections(filter: DatasetFilterWithImmutableCollections): DatasetFilter {
  return { ...filter, priorities: filter.priorities.map(p => p.toArray()).toArray(), excluded: filter.excluded.toArray() };
}

export function construct(priorities: string[][], excluded: string[]): DatasetFilter {
  return { type: TYPE, priorities, excluded };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function isValid(filter: DatasetFilter) {
  return true;
}

export function allDatasets(filter: DatasetFilter): Set<string> {
  const { priorities, excluded } = filter;
  return List(priorities).flatMap(prio => prio).concat(excluded).toSet();
}

export function getBucket(filter: DatasetFilter, bucketId: BucketIdType): Set<string> {
  const { priorities, excluded } = filter;
  return bucketId.case({
    Priority: ({ index }) => Set(priorities[index]),
    Excluded: () => Set(excluded),
  });
}

export function incorporateUnaccountedDatasets(filter: DatasetFilter, sourceList: List<string>) {
  const unaccountedDatasets = sourceList.toSet().subtract(allDatasets(filter));
  // we always assume all unaccounted datasets are excluded
  return update(filter, 'excluded', e => e.concat(unaccountedDatasets.toArray()));
}

export function removeDataset(filter: DatasetFilter, datasetName: string) {
  return update(filter,
    updater('priorities', priorities =>
      priorities.map(bucket => Set(bucket).remove(datasetName).toArray()).filter(bucket => bucket.length > 0)),
    updater('excluded', excluded => Set(excluded).remove(datasetName).toArray()));
}
