import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import { attributeId } from '../models/AttributeId';
import SourceAttribute from '../models/SourceAttribute';
import style from './ConfirmDialog.module.scss';
import ConfirmRemoveMappingsTable from './ConfirmRemoveMappingsTable';
import { HIDE_BULK_UNMAP_DIALOG } from './SchemaMappingActionTypes';
import { bulkUnmap } from './SchemaMappingAsync';

const ConfirmBulkUnmapDialog = _.compose(
  connect(({ schemaMapping }) => {
    const { sourceSelectedIds, showUnmapWarning, sourceAttributes } = schemaMapping;
    const selectedSourceAttributes = sourceAttributes.filter(sa => sourceSelectedIds.has(attributeId(sa)));
    const mappedSelectedSourceAttributes = selectedSourceAttributes.filter(sa => !sa.mappedAttributes.isEmpty());
    return {
      mappedSelectedSourceAttributes,
      selectedSourceAttributes,
      showWarning: showUnmapWarning,
    };
  }, {
    onHide: () => ({ type: HIDE_BULK_UNMAP_DIALOG }),
    onAccept: bulkUnmap,
  }),
)(class ConfirmBulkUnmapDialog extends React.Component {
  static propTypes = {
    mappedSelectedSourceAttributes: ImmutablePropTypes.listOf(PropTypes.instanceOf(SourceAttribute)),
    onAccept: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    selectedSourceAttributes: ImmutablePropTypes.listOf(PropTypes.instanceOf(SourceAttribute)),
    showWarning: PropTypes.bool.isRequired,
  };

  render() {
    const { showWarning, onHide, onAccept, selectedSourceAttributes, mappedSelectedSourceAttributes } = this.props;
    const numMapped = mappedSelectedSourceAttributes.size;
    return (
      <Dialog
        className={style.dialog}
        show={showWarning}
        onHide={onHide}
        body={
          <div>
            <div className={style.description1}>
              {numMapped > 0 ? 'The following mappings will be removed:' : 'No mappings were selected to be removed.'}
            </div>
            <div className={style.tableContainer}>
              <ConfirmRemoveMappingsTable sourceAttributes={selectedSourceAttributes} />
            </div>
            <div className={style.description2}>
              {numMapped > 0 ? 'Are you sure you want to continue, removing these mappings?' : ''}
            </div>
          </div>
        }
        footer={
          <ButtonToolbar>
            <Button buttonType="Secondary" onClick={onHide}>Cancel</Button>
            <Button buttonType="Primary" onClick={onAccept}>Unmap</Button>
          </ButtonToolbar>
        }
        title="Unmap?"
        dialogStyle={DialogStyle.PRIMARY}
        closeButton
      />
    );
  }
});

export default ConfirmBulkUnmapDialog;
