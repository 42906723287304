import React from 'react';

import ClusterProfileJobLoader from './ClusterProfileJobLoader';
import { RulesPageClusterSampleLoader } from './ClusterSampleLoader';
import ConfirmRemoveSourceFromDatasetFiltersDialog from './ConfirmRemoveSourceFromDatasetFiltersDialog';
import ConflictDialog from './ConflictDialog';
import EditDatasetFilterDialog from './EditDatasetFilterDialog';
import EditDatasetFilterDialogDragLayer from './EditDatasetFilterDialogDragLayer';
import GoldenRecordsActionConfirmationDialogs from './GoldenRecordsActionConfirmationDialogs';
import { RulesPageClusterSampleTable } from './GoldenRecordsClusterSampleTable';
import GoldenRecordsPreviewLoader from './GoldenRecordsPreviewLoader';
import style from './GoldenRecordsRules.module.scss';
import GoldenRecordsRulesHeader from './GoldenRecordsRulesHeader';
import GoldenRecordsRulesTable from './GoldenRecordsRulesTable';
import GRPreviewInputRecordsLoader from './GRPreviewInputRecordsLoader';
import ModuleLoader from './ModuleLoader';
import PreviewTooLargeDialog from './PreviewTooLargeDialog';
import SourceListLoader from './SourceListLoader';


const GoldenRecordsRules = () => {
  return (
    <div className={style.container}>
      <ModuleLoader />
      <SourceListLoader />
      <ClusterProfileJobLoader />
      <GRPreviewInputRecordsLoader />
      <GoldenRecordsPreviewLoader />
      <EditDatasetFilterDialog />
      <ConfirmRemoveSourceFromDatasetFiltersDialog />
      <ConflictDialog />
      <PreviewTooLargeDialog />
      <GoldenRecordsActionConfirmationDialogs />
      <EditDatasetFilterDialogDragLayer />
      <GoldenRecordsRulesHeader />
      <GoldenRecordsRulesTable />
      <RulesPageClusterSampleLoader />
      <RulesPageClusterSampleTable />
    </div>
  );
};

export default GoldenRecordsRules;
