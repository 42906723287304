import '../components/Highlighter.scss';

import classnames from 'classnames';
import { ColumnHeaderProps, ElementOrFunc } from 'fixed-data-table-2';
import { List } from 'immutable';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { isNumber } from 'underscore';

import Link from '../components/Link';
import MultiValue, { recordValueShim, RecordValueType, Value } from '../components/MultiValue';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import DisplayColumn from '../models/DisplayColumn';
import { AppState } from '../stores/MainStore';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { pluralize } from '../utils/Strings';
import { $TSFixMe } from '../utils/typescript';
import { HTMLAnchorProps } from '../utils/typescript-react';
import GeneratedRuleIcon from './GeneratedRuleIcon';
import overallStyle from './GoldenRecord.module.scss';
import { getCurrentGoldenRecordUI, selectInputDedupRecipeId } from './GoldenRecordsSelectors';
import style from './GoldenRecordsSidebar.module.scss';
import {
  attributeNameToColumnKey,
  attributeNameToDisplayName,
  columnKeyToAttributeName,
  columnKeyToOverrideColumnKey,
  grColumnKeyArgType,
} from './GoldenRecordsStore';
import tableStyle from './GoldenRecordsTable.module.scss';
import { SPECIAL_RULE_NAMES } from './Rule';


export const InputDedupProjectLink: React.FC<{
  inputDedupRecipeId: number
  clusterId: string
  children: React.ReactNode
} & HTMLAnchorProps> = ({ inputDedupRecipeId, clusterId, children, ...props }) => {
  /**
   * To link from the golden records project to the mastering project we read all loaded projects and see which
   * one has an output dataset named the same as the input dataset to the GR project. See selectDedupRecipeId selector
   */
  return (
    <Link
      to={`/suppliers/recipe/${inputDedupRecipeId}?queryString=${encodeURIComponent(`cluster.id:"${clusterId}"`)}`}
      openInNewTab
      {...props}
    >
      {children}
    </Link>
  );
};


type RuleCellContentProps = {
  hasManualOverride: boolean,
  inputDedupRecipeId?: number,
  isClusterIdColumn: boolean,
  isEntityRuleColumn: boolean,
  isSourcesColumn: boolean,
  raw?: $TSFixMe,
  value: $TSFixMe,
};

const RuleCellContent: React.FunctionComponent<RuleCellContentProps> = ({ hasManualOverride, value, raw, isSourcesColumn, isClusterIdColumn, inputDedupRecipeId, isEntityRuleColumn }) => {
  const getSourcesColumnContent = () => `${value} ${pluralize(value, 'source', 'sources')}`;
  return (
    <div className={style.text}>
      <span className={classnames({ [style.green]: hasManualOverride })}>
        <MultiValue
          values={recordValueShim({ value: raw || value, raw: !!raw, originalData: value })}
          title={isSourcesColumn ? getSourcesColumnContent() : String(value)}
          renderValue={(v: RecordValueType, i: number) => {
            switch (true) {
              case isSourcesColumn:
                return <span key={i} className={style.sources}>{getSourcesColumnContent()}</span>;
              case isClusterIdColumn && isNumber(inputDedupRecipeId):
                return (
                  <InputDedupProjectLink
                    key={i}
                    inputDedupRecipeId={inputDedupRecipeId as number}
                    // the value here comes from the prop of RuleCellContent which will be
                    // a string in this case
                    clusterId={value}
                  >
                    <Value value={v} />
                  </InputDedupProjectLink>
                );
              case isEntityRuleColumn:
                return <strong key={i}>{value}</strong>;
              default:
                return <Value key={i} nestedKey={i} value={v} />;
            }
          }}
        />
      </span>
    </div>
  );
};

type RuleColumnHeaderProps = {
  attributeName: string,
  className?: string,
  displayName: string,
  isSuggested: boolean,
}

export const RuleColumnHeader: React.FunctionComponent<RuleColumnHeaderProps> = ({ attributeName, displayName, isSuggested, className }) => {
  return (
    <Cell className={classnames(tableStyle.cellHeader, className)}>
      {isSuggested && <GeneratedRuleIcon className={overallStyle.suggested} />}
      <TooltipTrigger content={attributeName} placement="bottom">
        <span className={tableStyle.cellHeader}>{displayName}</span>
      </TooltipTrigger>
    </Cell>
  );
};

type HeaderRenderer = (renderHeaderArgs: { attributeName: string, displayName: string, isSuggested: boolean }) => ElementOrFunc<ColumnHeaderProps>;
type CellRenderer = (renderCellArgs: { columnKey: string, rowIndex: number }) => string | React.ReactElement

// general note: <Column>s have to be called like a function, not like a component
export const ruleColumn: React.FunctionComponent<{
  attributeName: string
  columnWidth: number
  leftmost: boolean
  clusterIdColumnName: string
  isSuggested: boolean
  renderHeader: HeaderRenderer
  renderCell: CellRenderer
}> = ({ attributeName, columnWidth, leftmost, clusterIdColumnName, isSuggested, renderHeader, renderCell }) => {
  const columnKey = attributeNameToColumnKey(attributeName);
  const displayName = attributeNameToDisplayName(attributeName, clusterIdColumnName);
  attributeName = attributeName === clusterIdColumnName ? displayName : attributeName;
  return (
    <Column
      key={columnKey}
      columnKey={columnKey}
      fixed={leftmost /* first column is assumed to be the entity rule column  */}
      align="left"
      isResizable
      width={columnWidth}
      header={renderHeader({ attributeName, displayName, isSuggested })}
      cell={({ rowIndex }) => renderCell({ columnKey, rowIndex })}
    />
  );
};

export function ruleColumns({ columnSettings, clusterIdColumnName, getIsSuggested, renderHeader, renderCell }: {
  columnSettings: List<DisplayColumn>
  clusterIdColumnName: string
  getIsSuggested: (attributeName: string) => boolean
  renderHeader: HeaderRenderer
  renderCell: CellRenderer
}): List<React.ReactElement | null> {
  checkArg({ columnSettings }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(DisplayColumn)));
  checkArg({ clusterIdColumnName }, ArgTypes.string);
  return columnSettings.filter(x => x.visible).map((column, i) => {
    const attributeName = column.name;
    return ruleColumn({
      attributeName,
      columnWidth: column.width,
      leftmost: i === 0,
      clusterIdColumnName,
      isSuggested: getIsSuggested(attributeName),
      renderHeader,
      renderCell,
    });
  });
}

export type ShowOverridePopoverArgs = {
  columnKey: string
  editOverridePopoverRef: React.RefObject<HTMLDivElement>
  clusterId: string
}
export type ShowOverridePopoverFnType = (args: ShowOverridePopoverArgs) => void;

type AllRecordsTableRuleCellOwnProps = { columnKey: string, rowIndex: number, showOverridePopover: ShowOverridePopoverFnType };

const allRecordsTableRuleCellMapStateToProps = (state: AppState, ownProps: AllRecordsTableRuleCellOwnProps) => {
  const { moduleFromLastUpdate, draftPage } = getCurrentGoldenRecordUI(state);
  const { rowIndex } = ownProps;
  // columnKey comes in with prepending gr__
  const { columnKey } = ownProps;
  checkArg({ columnKey }, grColumnKeyArgType);
  const attributeName = columnKeyToAttributeName(columnKey);
  const clusterIdColumnName = moduleFromLastUpdate?.clusterDataset?.clusterColumn;
  const isClusterIdColumn = attributeName === clusterIdColumnName;
  const isSourcesColumn = attributeName === SPECIAL_RULE_NAMES.sources;
  const isClusterSizeColumn = attributeName === SPECIAL_RULE_NAMES.clusterSize;
  const isEntityRuleColumn = attributeName === moduleFromLastUpdate?.entityRule?.outputAttributeName;
  const record = draftPage?.items.get(rowIndex);
  const value = record?.source?.[columnKey];
  const raw = record?.highlight?.[columnKey];
  const manualOverride = record?.source?.[columnKeyToOverrideColumnKey(columnKey)];
  const clusterId = record?.id;
  // TODO incorporate field type of column in rendering
  return {
    isClusterIdColumn,
    isSourcesColumn,
    isEntityRuleColumn,
    clusterId,
    ...ownProps,
    value,
    raw,
    inputDedupRecipeId: selectInputDedupRecipeId(state),
    manualOverride,
    overridable: !(isClusterIdColumn || isSourcesColumn || isClusterSizeColumn),
  };
};

type AllRecordsTableRuleCellProps = ReturnType<typeof allRecordsTableRuleCellMapStateToProps> & AllRecordsTableRuleCellOwnProps;

class UnconnectedAllRecordsTableRuleCell extends PureComponent<AllRecordsTableRuleCellProps> {
  cellRef = React.createRef<HTMLDivElement>();
  render() {
    const { showOverridePopover, columnKey, clusterId, value, raw, overridable, manualOverride, inputDedupRecipeId,
      isSourcesColumn, isClusterIdColumn, isEntityRuleColumn } = this.props;
    const hasManualOverride = manualOverride !== undefined;
    return (
      <div className={style.cell} ref={this.cellRef}>
        <RuleCellContent {...{ hasManualOverride, value, raw, isSourcesColumn, isClusterIdColumn, inputDedupRecipeId, isEntityRuleColumn }} />
        {overridable && (
          <TamrIcon
            className={classnames(style.pencil, { [style.visible]: hasManualOverride, [style.green]: hasManualOverride })}
            iconName="edit"
            size={20}
            onClick={(e) => {
              e.stopPropagation(); // we do not want the row to activate
              if (!clusterId) {
                console.error('Could not show override popup - no clusterId');
                return;
              }
              showOverridePopover({
                columnKey,
                editOverridePopoverRef: this.cellRef,
                clusterId,
              });
            }}
          />
        )}
      </div>
    );
  }
}

export const AllRecordsTableRuleCell = connect(allRecordsTableRuleCellMapStateToProps)(UnconnectedAllRecordsTableRuleCell);

type PreviewTableRuleCellOwnProps = {
  columnKey: string
  rowIndex: number
}

export const PreviewTableRuleCell = connect((state: AppState, { columnKey, rowIndex }: PreviewTableRuleCellOwnProps) => {
  checkArg({ columnKey }, grColumnKeyArgType);
  checkArg({ rowIndex }, ArgTypes.wholeNumber);
  const { goldenRecordsRules: { previewedModule, previewTable } } = state;
  const attributeName = columnKeyToAttributeName(columnKey);
  return {
    value: previewTable && previewTable.getIn([rowIndex, attributeName]),
    isSourcesColumn: attributeName === SPECIAL_RULE_NAMES.sources,
    isClusterIdColumn: attributeName === previewedModule?.clusterDataset.clusterColumn,
    isEntityRuleColumn: attributeName === previewedModule?.entityRule.outputAttributeName,
    inputDedupRecipeId: selectInputDedupRecipeId(state),
  };
})(({ value, isSourcesColumn, isClusterIdColumn, isEntityRuleColumn, inputDedupRecipeId }) => {
  return (
    <div className={style.cell}>
      <RuleCellContent
        {...{ value, isSourcesColumn, isClusterIdColumn, isEntityRuleColumn, inputDedupRecipeId }}
        hasManualOverride={false}
      />
    </div>
  );
});
