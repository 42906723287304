import { GridLayer, withLeaflet } from 'react-leaflet';

import { $TSFixMe } from '../utils/typescript';
// @ts-expect-error Will be removed once RESTfulWMTS is TS-ified
import RESTfulWMTS from './RESTfulWMTS';

class WMTSRestTileLayer extends GridLayer {
  createLeafletElement(props: $TSFixMe) {
    const { url, ...params } = props;
    return new RESTfulWMTS(url, this.getOptions(params));
  }
}

export default withLeaflet(WMTSRestTileLayer);
