import _ from 'underscore';

import Project from '../models/Project';
import { AppState } from '../stores/MainStore';
import { ArgTypes, checkArg } from './ArgValidation';
import { selectActiveProjectWithStatus } from './Selectors';
import { $TSFixMe } from './typescript';
import { getPath } from './Values';

const titleCase = (s: string) => s.slice(0, 1).toUpperCase() + s.slice(1);
const upperCase = (s: string) => s.toUpperCase();

const makeCases = (term: string, key: string, num: $TSFixMe) => {
  return _.object([_.identity, titleCase, upperCase].map(fn => [fn(term), { key, fn, num }]));
};

const makeAll = (key: string) => {
  return { ...makeCases(key, key, 'singular'), ...makeCases(key + 's', key, 'plural') };
};

const lookup: $TSFixMe = {
  ...makeAll('pair'),
  ...makeAll('record'),
  ...makeAll('supplier'),
  ...makeAll('part'),
  ...makeCases('spend', 'spend', 'singular'),
  currencyChar: { key: 'currencyChar', fn: _.identity, num: 'singular' },
};

export function projectTerm(
  project: Project | null | undefined,
  term: string,
  amount?: number,
): string {
  checkArg({ project }, ArgTypes.nullable(ArgTypes.instanceOf(Project)));
  checkArg({ term }, ArgTypes.string);
  checkArg({ amount }, ArgTypes.nullable(ArgTypes.number));
  const { key, num, fn } = lookup[term];
  const newNum = amount !== undefined ? (amount === 1 ? 'singular' : 'plural') : num;
  return fn(getPath(project, 'metadata', 'terms', key, newNum) || (key + (newNum === 'singular' ? '' : 's')));
}

export function getTerm(state: AppState, term: string, amount?: number): string {
  checkArg({ term }, ArgTypes.string);
  checkArg({ amount }, ArgTypes.nullable(ArgTypes.number));
  return projectTerm(getPath(selectActiveProjectWithStatus(state), 'project', 'data'), term, amount);
}
