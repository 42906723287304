import PropTypes from 'prop-types';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import { selectActiveProjectInfo } from '../utils/Selectors';
import { getPath } from '../utils/Values';
import { SET_SIMILARITY_THRESHOLD } from './SchemaMappingActionTypes';

const SliderWithTooltip = createSliderWithTooltip(Slider);

const SchemaMappingConfig = _.compose(
  connect((state) => {
    const { schemaMapping: { similarityThreshold } } = state;
    const schemaMappingRecommendationsHaveBeenGenerated =
      !!getPath(selectActiveProjectInfo(state), 'smRecsRecipeWithStatus', 'materializations', 'recommendations', 'lastRun');
    return { similarityThreshold, schemaMappingRecommendationsHaveBeenGenerated };
  }, {
    onSetSimilarityThreshold: similarityThreshold => ({ type: SET_SIMILARITY_THRESHOLD, similarityThreshold }),
  }),
)(class SchemaMappingConfig extends React.Component {
  static propTypes = {
    onSetSimilarityThreshold: PropTypes.func.isRequired,
    similarityThreshold: PropTypes.number.isRequired,
  };

  render() {
    const { similarityThreshold, onSetSimilarityThreshold, schemaMappingRecommendationsHaveBeenGenerated } = this.props;
    const threshold = Math.round(similarityThreshold * 100);
    return (
      <div className="schema-mapping-options-container">
        { schemaMappingRecommendationsHaveBeenGenerated
          ? <div className="slider-title">Suggesting attributes at least <span className="ratio-highlight">{threshold}%</span> similar</div>
          : <div className="slider-title-disabled">Can't show suggestions until they are generated</div>
        }
        <SliderWithTooltip
          className="similarity-slider"
          min={0}
          max={100}
          step={1}
          disabled={!schemaMappingRecommendationsHaveBeenGenerated}
          defaultValue={threshold}
          onAfterChange={v => onSetSimilarityThreshold(v / 100.0)}
        />
      </div>
    );
  }
});

export default SchemaMappingConfig;
