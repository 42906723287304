import classNames from 'classnames';
import { Set } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import { AppState } from '../stores/MainStore';
import { isCuratorByProjectId, isVerifierByProjectId } from '../utils/Authorization';
import { getAuthorizedUser, selectActiveProjectInfo } from '../utils/Selectors';
import { pluralize } from '../utils/Strings';
import { provideFeedbackResponses, removeFeedbackResponses } from './RecordPairsAsync';
import style from './ResponseButton.module.scss';
import VerifiedPairIcon from './VerifiedPairIcon';


const RESPONSE_KEY_LABEL = {
  MATCH: 'Match',
  NON_MATCH: 'No match',
  SKIP: 'Skip',
} as const;

const RESPONSE_KEY_ICON = {
  MATCH: 'match',
  NON_MATCH: 'do-not-disturb-alt',
  SKIP: 'skip-next',
} as const;

type ResponseButtonOwnProps = {
  buttonClassName?: string
  containerClassName?: string
  index: number
  responseIconSize: number
  verifiedIconSize?: number
  verifiedTooltipBelow?: boolean
  responseKey: keyof typeof RESPONSE_KEY_LABEL
}

const ResponseButton = connect((state: AppState, { index }: ResponseButtonOwnProps) => {
  const { recordPairs: { pairs } } = state;
  const authUser = getAuthorizedUser(state);
  const loggedInUsername = authUser?.username;
  const userIsCurator = authUser && isCuratorByProjectId(authUser, selectActiveProjectInfo(state)?.projectDoc.id.id);
  const userIsVerifier = authUser && isVerifierByProjectId(authUser, selectActiveProjectInfo(state)?.projectDoc.id.id);
  const pair = pairs.get(index);
  return { loggedInUsername, userIsCurator, userIsVerifier, pair };
}, {
  onProvideFeedbackResponses: provideFeedbackResponses,
  onRemoveFeedbackResponses: removeFeedbackResponses,
})((props) => {
  const { verifiedTooltipBelow, onProvideFeedbackResponses, onRemoveFeedbackResponses, index, buttonClassName, containerClassName, responseIconSize, verifiedIconSize, responseKey, pair, loggedInUsername, userIsCurator, userIsVerifier } = props;

  if (!pair) {
    return null;
  }

  const userResponseKey = loggedInUsername && pair.getUserResponseKey(loggedInUsername);
  const selected = userResponseKey === responseKey;
  const verified = pair.manualLabel === responseKey;
  const allResponses = pair.feedback.filter(f => f.responseKey === responseKey).sortBy(f => (f.responseTimestamp || 0) * -1);
  const onClick = selected ? () => onRemoveFeedbackResponses({ rowNumbers: Set.of(index) }) : () => onProvideFeedbackResponses({ rowNumbers: Set.of(index), responseKey });
  const getActionMessage = () => {
    if (responseKey === 'SKIP') {
      return 'skipped';
    }
    return `said ${RESPONSE_KEY_LABEL[responseKey]}`;
  };
  return (
    <div className={classNames(style.buttonContainer, containerClassName, { [style.selected]: selected })}>
      <Button
        className={classNames(style.button, buttonClassName, {
          [style.match]: responseKey === 'MATCH',
          [style.noMatch]: responseKey === 'NON_MATCH',
          [style.skip]: responseKey === 'SKIP',
        })}
        buttonType={selected ? 'Primary' : 'Secondary'}
        icon={<TamrIcon iconName={RESPONSE_KEY_ICON[responseKey]} size={responseIconSize} />}
        onClick={onClick}
      />
      {verified ? (
        <VerifiedPairIcon
          tooltipPlacement={verifiedTooltipBelow ? 'bottom' : 'top'}
          pair={pair}
          tooltipClassName={verifiedTooltipBelow ? style.verifiedTooltipBelow : ''}
          iconProps={{ className: style.verified, size: verifiedIconSize }}
        />
      ) : null}
      {(userIsCurator || userIsVerifier) && allResponses.size > 0 ? (
        <TooltipTrigger
          placement="bottom"
          content={
            (selected ? 'You' : allResponses.first(undefined)?.username) + ' ' +
            (allResponses.size > 1 ? `and ${allResponses.size - 1} ${pluralize(allResponses.size - 1, 'other', 'others')} ` : '') +
            getActionMessage()
          }
        >
          <span onClick={onClick} className={style.responseCount}>{allResponses.size}</span>
        </TooltipTrigger>
      ) : null}
    </div>
  );
});

export default ResponseButton;
