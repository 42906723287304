import React from 'react';
import { connect } from 'react-redux';

import Checkbox from '../components/Input/Checkbox';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import { AppAction } from '../stores/AppAction';
import { AppDispatch, AppState } from '../stores/MainStore';
import filterStyle from './FilterSection.module.scss';
import styles from './HighImpactClustersFilterSection.module.scss';
import { PaneE } from './Pane';
import { TOGGLE_CLUSTER_HIGH_IMPACT_FILTER } from './SuppliersActionTypes';


interface StateProps {
  clusterHighImpactFilter: boolean
}

interface DispatchProps {
  toggleHighImpactClusters: () => AppAction
}

interface OwnProps {
  pane: PaneE
}

type Props = StateProps & DispatchProps & OwnProps

const HighImpactClustersFilterSection: React.FC<Props> = ({
  clusterHighImpactFilter,
  toggleHighImpactClusters,
}) => {
  return (
    <div className={filterStyle.filterSection}>
      <div className={filterStyle.filterPanelHeader}>High Impact&nbsp;
        <TooltipTrigger
          placement="right"
          content={(
            <span>
              Tamr uses high impact clusters to improve and test model performace.
            </span>
        )}
      >
          <TamrIcon className={filterStyle.help} iconName="info-outline" size={14} />
        </TooltipTrigger>
      </div>
      <div className={filterStyle.option}>
        <Checkbox
          className={filterStyle.checkboxWrapper}
          onChange={() => toggleHighImpactClusters()}
          title={<span className={styles.highImpactOption}><TamrIcon iconName="tamr-icon-lightning-bolt" className={styles.highImpactIcon} size={16} /> High Impact</span>}
          value={clusterHighImpactFilter}
          size={16}
          titlePosition="right"
        />
      </div>
    </div>
  );
};

export default connect(
  (state: AppState, ownProps: OwnProps): StateProps => {
    const { pane } = ownProps;
    const {
      suppliers: {
        [pane]: {
          clusterHighImpactFilter,
        },
      },
    } = state;
    return {
      clusterHighImpactFilter,
    };
  },
  (dispatch: AppDispatch, { pane }: OwnProps) => ({
    toggleHighImpactClusters: (): AppAction => dispatch({ type: TOGGLE_CLUSTER_HIGH_IMPACT_FILTER, pane }),
  }),
)(HighImpactClustersFilterSection);
