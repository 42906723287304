import { List, Set } from 'immutable';
import _ from 'underscore';

import { ORIGIN_SOURCE_NAME } from '../constants/ElasticConstants';
import Response, { ResponseE } from '../constants/Response';
import { VerificationTypeE } from '../suppliers/VerificationType';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import ElasticUtils from '../utils/ElasticUtils';
import Optional from '../utils/Optional';
import { $TSFixMe, JsonContent, Nullable } from '../utils/typescript';
import { getPath } from '../utils/Values';
import ClusterVerificationCounts from './ClusterVerificationCounts';
import Dataset from './Dataset';
import Document from './doc/Document';
import EsCategorization from './EsCategorization';
import EsFeedback, { CategorizationSuggestion } from './EsFeedback';

export function clusterSuggestionExists(
  verificationType: VerificationTypeE,
  currentClusterId: Nullable<string>,
  suggestedClusterId: Nullable<string>,
): boolean {
  return !!(verificationType === VerificationTypeE.SUGGEST
    && suggestedClusterId
    && suggestedClusterId !== currentClusterId);
}

// The only way to be verified in another cluster is to be movable then have been moved by clustering
export function isVerifiedInAnotherCluster(
  verificationType: VerificationTypeE,
  currentClusterId: Nullable<string>,
  verifiedClusterId: Nullable<string>,
): boolean {
  return !!(verificationType === VerificationTypeE.MOVABLE
    && currentClusterId !== verifiedClusterId);
}

type EsHit = {
  id: string
  type: string
  // TODO samgqroberts 2020-10-06 typing these as pointing to `any` is very dangerous
  //   we should try to type these instead as pointing to `unknown` - we need to be very careful assuming anything
  //   is in elasticsearch
  highlight: {[key: string]: any}
  source: {[key: string]: any}
}

class EsRecord {
  _dataset: Document<Dataset>;
  _hit: EsHit;
  _data: {[key: string]: any};

  static get argType() { return ArgTypes.instanceOf(this); }

  constructor(hit: EsHit, dataset: Document<Dataset>) {
    checkArg({ hit }, ArgTypes.object.withShape({
      id: ArgTypes.string,
      type: ArgTypes.string,
      highlight: ArgTypes.object,
      source: ArgTypes.object,
    }));
    checkArg({ dataset }, Document.argTypeWithNestedClass(Dataset));
    this._dataset = dataset;
    this._hit = hit;
    // extract internal fields
    this._data = _.chain(hit.source)
      .pairs()
      .filter(pair => ElasticUtils.hasColumnPrefix(pair[0]))
      .object()
      .value();
  }

  getValue(field: string) {
    // If there is a highlighted field data, return that, instead of the regular data
    if (this._hit.highlight && this._hit.highlight[ElasticUtils.sanitizeField(field)]) {
      return {
        renderRaw: true,
        data: this._hit.highlight[ElasticUtils.sanitizeField(field)],
        originalData: this._data[ElasticUtils.sanitizeField(field)],
      };
    }
    return {
      renderRaw: false,
      data: this._data[ElasticUtils.sanitizeField(field)],
    };
  }

  getHighlightOrValue(field: string): JsonContent {
    // If there is a highlighted field data, return that, instead of the regular data
    return Optional.of(this._hit.highlight && this._hit.highlight[ElasticUtils.sanitizeField(field)])
      .orElse(this._data[ElasticUtils.sanitizeField(field)]);
  }

  get dataset() {
    return getPath(this._dataset, 'data', 'name');
  }

  get originDataset() {
    return this._data[ElasticUtils.sanitizeField(ORIGIN_SOURCE_NAME)];
  }

  get datasetId() {
    const idString = getPath(this._dataset, 'id', 'id');
    return idString && parseInt(idString, 10);
  }

  get recordId() {
    return this._hit.id;
  }

  get fields(): List<string> {
    return getPath(this._dataset, 'data', 'fields') || List();
  }

  get categorizationData() {
    return getPath(this._dataset, 'data', 'metadata', 'categorization') || {};
  }

  get visibleFields() {
    return this.categorizationData.visibleFields;
  }

  get pinned() {
    // @ts-expect-error
    return this._hit.pinned;
  }

  get hiddenFields() {
    const allColumns = Set(this.fields);
    const visibleColumns: Set<string> = Set(this.visibleFields);
    return allColumns.subtract(visibleColumns).toArray();
  }

  get spend() {
    const spendField = getPath(this._dataset, 'data', 'metadata', 'spendField');
    if (spendField) {
      return this._data[ElasticUtils.sanitizeField(spendField)];
    }
  }

  get manualCategorization() {
    return this._hit.source.manualCategorization && EsCategorization.fromJSON(this._hit.source.manualCategorization);
  }

  get suggestedCategorization() {
    return this._hit.source.suggestedCategorization && EsCategorization.fromJSON(this._hit.source.suggestedCategorization);
  }

  get comments() {
    return this._hit.source.comments;
  }

  commentsForUser(username: string) {
    checkArg({ username }, ArgTypes.string);
    const { comments } = this._hit.source;
    if (comments) {
      return _.where(comments, { createdBy: username });
    }
  }

  numResponses(response: ResponseE, categoryId: number | null | undefined) {
    checkArg({ categoryId }, ArgTypes.nullable(ArgTypes.number));
    checkArg({ response }, Response.argType);
    return this.feedback.reduce((total, feedback) => total + feedback.responses.reduce((subtotal: number, userResponse: $TSFixMe) => {
      if (userResponse.response === response && userResponse.categoryId === categoryId) {
        return subtotal + 1;
      }
      return subtotal;
    }, 0), 0);
  }

  /**
   * Return a List of CategorizationSuggestions
   */
  get categorizationSuggestions(): List<CategorizationSuggestion> {
    return this.feedback.map(feedback => feedback.responses).flatten(1) as List<CategorizationSuggestion>;
  }

  feedbackForUser(username: string) {
    checkArg({ username }, ArgTypes.string);
    return this.feedback.find(feedback => feedback.username === username);
  }

  getResponseForUser(username: string, categoryId: number) {
    checkArg({ username }, ArgTypes.string);
    checkArg({ categoryId }, ArgTypes.number);
    const userFeedback = this.feedback.find(feedback => feedback.username === username);
    if (userFeedback) {
      return userFeedback.getResponseForCategory(categoryId);
    }
  }

  get feedback(): List<EsFeedback> {
    return List(this._hit.source.feedback).map(EsFeedback.fromJSON);
  }

  get clusterName() {
    const { cluster } = this._hit.source;
    if (cluster) {
      return cluster.name;
    }
  }

  get clusterId() {
    const { cluster } = this._hit.source;
    if (cluster) {
      return cluster.id;
    }
  }

  get numRecordsInCluster() {
    const { cluster } = this._hit.source;
    return (cluster && cluster.recordCount) || 0;
  }

  get clusterVerificationCounts(): ClusterVerificationCounts | undefined {
    const { cluster } = this._hit.source;
    return cluster && cluster.clusterVerificationCounts && new ClusterVerificationCounts(cluster.clusterVerificationCounts);
  }

  get numLockedRecordsInCluster() {
    const { cluster } = this._hit.source;
    return (cluster && cluster.clusterVerificationCounts && cluster.clusterVerificationCounts.locked) || 0;
  }

  get totalClusterSpend() {
    const { cluster } = this._hit.source;
    return (cluster && cluster.totalSpend) || 0;
  }

  get lockedInCluster() {
    return this._hit.source.verificationType === 'LOCK';
  }

  get verificationType(): VerificationTypeE | null {
    return this._hit.source.verificationType || null;
  }
  get verifiedClusterId(): string | null {
    return this._hit.source.verifiedClusterId || null;
  }
  get currentClusterId(): string | null {
    return this.clusterId || null;
  }
  get suggestedClusterId(): string | null {
    return this._hit.source.suggestedClusterId || null;
  }
  get suggestionExists(): boolean {
    const { verificationType, currentClusterId, suggestedClusterId } = this;
    if (!verificationType) return false;
    return clusterSuggestionExists(verificationType, currentClusterId, suggestedClusterId);
  }
  get verifiedInAnotherCluster(): boolean {
    const { verificationType, currentClusterId, verifiedClusterId } = this;
    if (!verificationType) return false;
    return isVerifiedInAnotherCluster(verificationType, currentClusterId, verifiedClusterId);
  }
  get movableAndVerifiedInCurrentCluster(): boolean {
    return this.verificationType === VerificationTypeE.MOVABLE && !this.verifiedInAnotherCluster;
  }

  get publishedClusterName() {
    const { publishedCluster } = this._hit.source;
    return publishedCluster && publishedCluster.publishedClusterName;
  }

  get publishedClusterId() {
    const { publishedCluster } = this._hit.source;
    return publishedCluster && publishedCluster.id;
  }

  get changeStatus() {
    const { changeStatus } = this._hit.source;
    return changeStatus;
  }

  get numRecordsInPublishedCluster() {
    const { publishedCluster } = this._hit.source;
    return (publishedCluster && publishedCluster.recordCount) || 0;
  }

  get numLockedRecordsInPublishedCluster() {
    const { publishedCluster } = this._hit.source;
    return (publishedCluster && publishedCluster.clusterVerificationCounts && publishedCluster.clusterVerificationCounts.locked) || 0;
  }

  get totalPublishedClusterSpend() {
    const { publishedCluster } = this._hit.source;
    return (publishedCluster && publishedCluster.totalSpend) || 0;
  }
}

export const argType = EsRecord.argType;

export default EsRecord;
