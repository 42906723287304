import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { isReserved } from './constants/ReservedFields';

export const validateName = (name, unifiedAttributeNames) => {
  checkArg({ name }, ArgTypes.nullable(ArgTypes.string));
  checkArg({ unifiedAttributeNames }, ArgTypes.Immutable.set.of(ArgTypes.string));
  if (!name) {
    return undefined;
  }
  if (name.indexOf('.') >= 0) {
    return 'Name cannot contain the \'.\' character';
  }
  if (name.trim().length !== name.length) {
    return 'Name cannot contain leading or trailing whitespace';
  }
  if (unifiedAttributeNames.map(v => v.toLowerCase()).has(name.toLowerCase())) {
    return 'This name is already in use';
  }
  if (isReserved(name)) {
    // TODO: less infuriating, but not TMI, error message
    return 'This name is reserved by the system';
  }
  return undefined;
};
