import { Set } from 'immutable';
import $ from 'jquery';
import uri from 'urijs';

import { SHOW } from '../errorDialog/ErrorDialogActionTypes';
import ProcurementTaxonomy from '../models/ProcurementTaxonomy';
import { DELETE_TAXONOMY_COMPLETED } from '../stores/TaxonomyActionTypes';
import { getFilterInfo } from '../stores/TaxonomyStore';
import {
  getUnifiedDatasetId,
  selectActiveProjectInfo,
} from '../utils/Selectors';

export const fetchTaxonomy = () => (dispatch, getState) => {
  const state = getState();
  const unifiedDatasetId = getUnifiedDatasetId(state);
  const filterInfo = getFilterInfo(state);
  dispatch({ type: 'Taxonomy.fetchTaxonomy' });
  if (!unifiedDatasetId) {
    dispatch({
      type: 'Taxonomy.fetchTaxonomyCompleted',
      taxonomy: new ProcurementTaxonomy({ categories: Set() }),
      filterInfo,
    });
  } else {
    $.ajax({
      url: SERVICES.procure(`/procurement/categories/${unifiedDatasetId}`),
      cache: false,
    }).then(data => {
      dispatch({
        type: 'Taxonomy.fetchTaxonomyCompleted',
        taxonomy: ProcurementTaxonomy.fromJSON(data),
        filterInfo,
      });
    }, (response) => {
      dispatch({ type: 'Taxonomy.fetchTaxonomyFailed', filterInfo });
      dispatch({ type: SHOW, detail: 'Error loading taxonomy', response });
    });
  }
};

export const deleteTaxonomy = () => (dispatch, getState) => {
  const state = getState();
  const { unifiedDatasetId } = selectActiveProjectInfo(state);
  $.ajax({
    url: SERVICES.procure(`/procurement/categories/${unifiedDatasetId}`),
    method: 'DELETE',
  }).then(() => {
    dispatch({ type: DELETE_TAXONOMY_COMPLETED });
  });
};

export const deleteCategory = categoryId => (dispatch, getState) => {
  const { unifiedDatasetId } = selectActiveProjectInfo(getState());
  $.ajax({
    url: SERVICES.taxonomy(`/categories/${unifiedDatasetId}/${categoryId}`),
    method: 'DELETE',
  }).then(() => {
    dispatch({ type: 'Taxonomy.deleteCategoryCompleted', categoryId });
  }, response => {
    dispatch({ type: SHOW, detail: 'Error deleting category', response });
  });
};

export const deleteCategorizations = categoryId => (dispatch, getState) => {
  const state = getState();
  const { tag, unifiedDatasetId } = selectActiveProjectInfo(state);
  return $.ajax({
    url: uri(SERVICES.procure(`/procurement/datasets/${unifiedDatasetId}/categorizations/by-category`))
      .query({
        tag,
        categoryId,
      }),
    method: 'DELETE',
  }).then(() => {
    dispatch({ type: 'Taxonomy.deleteCategorizationsCompleted' });
  }, response => {
    dispatch({ type: SHOW, detail: 'Error clearing categorizations', response });
  });
};

export const addCategory = path => (dispatch, getState) => {
  const state = getState();
  const { unifiedDatasetId } = selectActiveProjectInfo(state);
  return $.ajax({
    url: SERVICES.taxonomy(`/categories/${unifiedDatasetId}`),
    data: JSON.stringify({
      path: path.toArray(),
      metadata: {},
    }),
    contentType: 'application/json',
    method: 'POST',
  }).then(() => {
    dispatch({ type: 'Taxonomy.addCategoryCompleted' });
  }, response => {
    dispatch({ type: SHOW, detail: 'Error adding category', response });
  });
};

export const renameCategory = (categoryId, name) => (dispatch, getState) => {
  const { unifiedDatasetId } = selectActiveProjectInfo(getState());
  return $.ajax({
    url: uri(SERVICES.taxonomy(`/categories/${unifiedDatasetId}/${categoryId}/rename`)).query({ name }),
    contentType: 'application/json',
    method: 'POST',
  }).then(() => {
    dispatch({ type: 'Taxonomy.renameCategoryCompleted' });
  }, response => {
    dispatch({ type: SHOW, detail: 'Error renaming category', response });
  });
};
