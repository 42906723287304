import TaggedUnion from '../models/TaggedUnion';
import { ArgTypes } from '../utils/ArgValidation';

// Always in one of these states.
export const CommentState = TaggedUnion({
  Displaying: {},
  Editing: {
    usernameState: ArgTypes.string,
    createdAt: ArgTypes.number,
  },
  DeleteConfirmation: {
    usernameState: ArgTypes.string,
    createdAt: ArgTypes.number,
  },
}, 'CommentState');

export const initialState = CommentState.Displaying();

export const reducers = {
  'Comments.showEditState': (state, { username, createdAt }) => {
    return CommentState.Editing({ usernameState: username, createdAt });
  },

  'Comments.hideEditState': () => {
    return CommentState.Displaying();
  },

  'Comments.showDeleteDialog': (state, { username, createdAt }) => {
    return CommentState.DeleteConfirmation({ usernameState: username, createdAt });
  },
  'Comments.hideDeleteDialog': () => {
    return CommentState.Displaying();
  },

  'Suppliers.editComment': () => {
    return CommentState.Displaying();
  },

  'Transactions.editComment': () => {
    return CommentState.Displaying();
  },

  'RecordPairs.editComment': () => {
    return CommentState.Displaying();
  },

  'Suppliers.deleteComment': () => {
    return CommentState.Displaying();
  },

  'Transactions.deleteComment': () => {
    return CommentState.Displaying();
  },

  'RecordPairs.deleteComment': () => {
    return CommentState.Displaying();
  },
};
