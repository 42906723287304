import { is } from 'immutable';

import createLoader from '../utils/createLoader';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { fetchTransactions } from './TransactionApi';
import { getFilterInfo } from './TransactionStore';

const TransactionLoader = createLoader((state) => {
  const { transactions: { loadedFilterInfo, loading } } = state;
  const { unifiedDataset } = selectActiveProjectInfo(state) || {};
  return {
    canFetch: !!unifiedDataset,
    shouldFetch: !is(getFilterInfo(state), loadedFilterInfo),
    loading,
  };
}, {
  onFetch: fetchTransactions,
}, 'TransactionLoader');

export default TransactionLoader;
