import createLoader from '../utils/createLoader';
import { loadRunningJobs } from './ChromeApi';

const RunningJobsLoader = createLoader((state) => {
  const { chrome: { loadingRunningJobs, initialRunningJobsFetch } } = state;
  return {
    canFetch: true,
    shouldFetch: !initialRunningJobsFetch,
    loading: loadingRunningJobs,
  };
}, {
  onFetch: loadRunningJobs,
}, 'RunningJobsLoader');

export default RunningJobsLoader;
