import * as Result from './Result';

// Utils
export const jsonHeaders = { Accept: 'application/json', 'Content-Type': 'application/json' };
// 'must-revalidate' header forces the Tamr API Auth cache to refresh, needed if user permissions have recently updated
export const jsonHeadersWithRevalidate = { Accept: 'application/json', 'Content-Type': 'application/json', 'X-Tamr-Auth': 'must-revalidate' };
export const encodedHeaders = { Accept: 'application/json', 'Content-Type': 'application/x-www-form-urlencoded' };
export const textHeaders = { Accept: 'text/plain;charset=utf-8', 'Content-Type': 'text/plain;charset=utf-8' };
export const networkError = (e: string) => {
  throw Error(`Unable to process network request. Check your connection. ${e}`);
};
export const toJSON = (response: Response) => response.json();

interface Data {
  message: string
}
interface ErrorArgs {
  message: string
  response: Response
  data?: Data
}

/**
 * FetchError is a custom error that sets message, response and (json) data to the error
 */
export class FetchError extends Error {
  message: string;
  response: Response;
  data?: Data;
  constructor({ message, response, data }: ErrorArgs) {
    super();
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }
    this.message = (message && `${message} ${data && data.message}`) || (data && data.message) || `API call failed (${response.status} - ${response.statusText})`;
    this.response = response;
    this.data = data;
  }
}

/**
 * This thenable function will check a fetch reponse for errors and if there are any raise them as a FetchError. If the response type was json, it will get the json and set it on object.
 * @param {Response} response the response returned by Fetch
 * @param {string optional} message the message to show in error
 */
export const handleErrors = async (response: Response, message: string) => {
  if (!response.ok) {
    const contentTypeHeaders = response.headers.get('Content-Type');
    if (contentTypeHeaders && contentTypeHeaders.includes('application/json')) {
      const data = await response.json();
      throw new FetchError({ response, data, message });
    }
    throw new FetchError({ response, message });
  }
  return response;
};

/**
 * This is a helper function that sets the message to display when there is an error. It returns handleErrors which should be the first thenable function after a fetch if you want to handle errors.
 * @param {string} message The message to display when there is an error
 */
export const handleErrorsWithMessage = (message: string) => (response: Response) => handleErrors(response, message);


export type RawFetchResult = Result.Result<Response, unknown /* reason */>

/**
 * A wrapper for `fetch` that does not rely on throwing exceptions or rejecting Promises to handle error cases
 */
export async function fetchAsResult(...args: Parameters<typeof fetch>): Promise<RawFetchResult> {
  return fetch(...args)
    .then(response => Result.constructSuccess(response))
    .catch(reason => Result.constructFailure(reason));
}
