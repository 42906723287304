import React from 'react';
import { connect } from 'react-redux';

import DetailSidebar from '../components/DetailSidebar/DetailSidebar';
import { AppState } from '../stores/MainStore';
import EditGoldenRecordsRulesSidebarContent from './EditGoldenRecordsRulesSidebarContent';
import ForceSaveModuleDialog from './ForceSaveModuleDialog';


const GoldenRecordsRulesSidebar = connect((state: AppState) => {
  const { goldenRecordsRules: { showSidebar } } = state;
  return { showSidebar };
})(({ showSidebar }) => {
  return (
    <DetailSidebar
      isExpanded={showSidebar}
    >
      <EditGoldenRecordsRulesSidebarContent />
      <ForceSaveModuleDialog />
    </DetailSidebar>
  );
});

export default GoldenRecordsRulesSidebar;
