import { List, Map } from 'immutable';
import { createSelector } from 'reselect';

import Dataset from '../models/Dataset';
import DatasetStatus from '../models/DatasetStatus';
import Document from '../models/doc/Document';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import ProfiledAttribute from '../models/ProfiledAttribute';
import ProfilingInfo from '../models/ProfilingInfo';
import ProjectInfo from '../models/ProjectInfo';
import { AppState } from '../stores/MainStore';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import PRODUCT_NAME from '../utils/ProductName';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { getPath } from '../utils/Values';

class ProfilingSchemaInfo extends getModelHelpers({
  dataset: { type: ArgTypes.orUndefined(Document.argTypeWithNestedClass(Dataset)) }, // TODO enforce this is defined
  schema: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(ProfiledAttribute)) },
  profilingUpToDate: { type: ArgTypes.bool },
}, 'ProfilingSchemaInfo')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class ProfilingSchemaInfoRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

// A selector that returns the dataset, its profiling and an up-to-dateness check
export const selectProfilingSchema = createSelector(
  (state: AppState) => state.unifiedDatasets?.profiling,
  (state: AppState) => state.unifiedDatasets?.docsByProjectId,
  (state: AppState) => state.unifiedDatasets?.statusByProjectId,
  (state: AppState) => selectActiveProjectInfo(state),
  (profiling: Map<string, ProfilingInfo>,
    docsByProjectId: Map<number, Document<Dataset>>,
    statusByProjectId: Map<number, DatasetStatus>,
    projectInfo: ProjectInfo | null,
  ): ProfilingSchemaInfo => {
    const datasets = docsByProjectId.toList();
    const status = statusByProjectId.toList();
    const datasetName = projectInfo?.unifiedDatasetName;
    if (profiling && datasetName) {
      checkArg({ profiling }, ArgTypes.Immutable.map.of(ArgTypes.instanceOf(ProfilingInfo), ArgTypes.string));
      checkArg({ datasets }, ArgTypes.Immutable.list.of(Document.argTypeWithNestedClass(Dataset)));
      checkArg({ status }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(DatasetStatus)));
      checkArg({ datasetName }, ArgTypes.string);
      const dataset = datasets.find(ds => ds.data.name === datasetName);
      const datasetStatus = status.find(s => s.datasetName === datasetName);
      const profilingInfo = getPath(profiling, datasetName);
      const schema = getPath(profilingInfo, 'schema') || List();
      const profilingUpToDate = getPath(profilingInfo, 'revision') === getPath(datasetStatus, 'currentRevision');
      return new ProfilingSchemaInfo({ dataset, schema, profilingUpToDate });
    }
    return {} as ProfilingSchemaInfo; // TODO
  },
);

export const DatasetTypeText = {
  SOURCE: `Users add these datasets to ${PRODUCT_NAME}`,
  UNIFIED_DATASET: `${PRODUCT_NAME} creates these datasets to unify every project’s input datasets`,
  PROJECT_GENERATED: `${PRODUCT_NAME} creates these datasets to store the results and internal properties of every project`,
  NON_PROJECT_DERIVED: `${PRODUCT_NAME} creates samples as smaller versions of existing datasets. Users create group-by and API-derived datasets as transformed versions of existing datasets`,
  SYSTEM_DATASET: `${PRODUCT_NAME} creates system datasets to support internal functions.`,
};
