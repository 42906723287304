import { ArgTypes, Checker } from '../utils/ArgValidation';
import { checkTypesAndCast } from '../utils/CheckTypesAndCast';
import { assertNever } from '../utils/typescript';
import * as ChoosingAggregation from './ChoosingAggregation';
import * as DatasetFilter from './DatasetFilter';
import * as FilterEmpties from './FilterEmpties';
import * as Predicate from './Predicate';
import * as SingleInputFilter from './SingleInputFilter';

export const FilterClusterRecordsTypeName = {
  SINGLE_INPUT: SingleInputFilter.TYPE,
  CHOOSING_AGGREGATION: ChoosingAggregation.TYPE,
  PREDICATE: Predicate.TYPE,
  FILTER_EMPTIES: FilterEmpties.TYPE,
  DATASET_FILTER: DatasetFilter.TYPE,
} as const;
export type FilterClusterRecordsTypeNameE = typeof FilterClusterRecordsTypeName[keyof typeof FilterClusterRecordsTypeName];

export type FilterClusterRecords
  = SingleInputFilter.SingleInputFilter
  | ChoosingAggregation.ChoosingAggregation
  | Predicate.Predicate
  | FilterEmpties.FilterEmpties
  | DatasetFilter.DatasetFilter

export const argType = ArgTypes.oneOf(
  ChoosingAggregation.argType,
  DatasetFilter.argType,
  FilterEmpties.argType,
  Predicate.argType,
  SingleInputFilter.argType,
) as Checker<FilterClusterRecords>;

const hasTypeArgTypes = {
  type: ArgTypes.valueIn(FilterClusterRecordsTypeName),
} as const;
export const fromJSON = (json: unknown): FilterClusterRecords => {
  const { type } = checkTypesAndCast<{ type: FilterClusterRecordsTypeNameE }, typeof hasTypeArgTypes>(hasTypeArgTypes)(json);
  if (type === SingleInputFilter.TYPE) return SingleInputFilter.fromJSON(json);
  if (type === ChoosingAggregation.TYPE) return ChoosingAggregation.fromJSON(json);
  if (type === Predicate.TYPE) return Predicate.fromJSON(json);
  if (type === FilterEmpties.TYPE) return FilterEmpties.fromJSON(json);
  if (type === DatasetFilter.TYPE) return DatasetFilter.fromJSON(json);
  assertNever(type);
};

export function isValid(filter: FilterClusterRecords): boolean {
  if (filter.type === SingleInputFilter.TYPE) return SingleInputFilter.isValid(filter);
  if (filter.type === ChoosingAggregation.TYPE) return ChoosingAggregation.isValid(filter);
  if (filter.type === Predicate.TYPE) return Predicate.isValid(filter);
  if (filter.type === FilterEmpties.TYPE) return FilterEmpties.isValid(filter);
  if (filter.type === DatasetFilter.TYPE) return DatasetFilter.isValid(filter);
  assertNever(filter);
}
