import React from 'react';
import { connect } from 'react-redux';

import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import { shortFormat } from '../utils/Numbers';
import style from './DnfBuilder.module.scss';

const DnfBuilderFooter = connect((state) => {
  const { dnfBuilder: { dnf, pairEstimates } } = state;
  return { dnf, pairEstimates };
})(({ dnf, pairEstimates }) => {
  const lastRunDnf = pairEstimates?.estimatedDnf;
  const someChanges = dnf && lastRunDnf && !dnf.activeClauses().equalsIgnoreId(lastRunDnf.activeClauses());
  const { totalEstimate } = pairEstimates || {};
  const { candidateCount, dnfPairCount, averageBinsPerEntity } = totalEstimate || {};
  return (
    <div className={style.footer}>
      {totalEstimate ? (
        <div className={style.footerText}>
          {someChanges ? (
            <TooltipTrigger placement="top" content="Some changes were made to your DNF since the last time estimate counts was run.">
              <TamrIcon iconName="tamr-icon-warning" size={16} />
            </TooltipTrigger>
          ) : null}
          <span>{shortFormat(dnfPairCount)} pairs from {shortFormat(candidateCount)} considered.&nbsp;&nbsp;&nbsp;
            {`${shortFormat(Math.ceil(averageBinsPerEntity))} blocks per record.`}
          </span>
        </div>
      ) : null}
    </div>
  );
});

export default DnfBuilderFooter;
