import { List, Record } from 'immutable';

import Dataset from '../models/Dataset';
import DatasetMetadataSpec from '../models/DatasetMetadataSpec';
import Document from '../models/doc/Document';
import Model from '../models/Model';
import { ArgTypes, checkReturn } from '../utils/ArgValidation';

export const initialState = new (Model({
  propertiesSidebarOpen: { type: ArgTypes.bool, defaultValue: false },
  currentMetadata: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(DatasetMetadataSpec)), defaultValue: List() },
  loadedDatasetMetadataFilterInfo: { type: ArgTypes.any },
  loading: { type: ArgTypes.bool, defaultValue: false },
  fetchSequence: { type: ArgTypes.number, defaultValue: 0 },
  currentlyAddingProperty: { type: ArgTypes.bool, defaultValue: false },
  confirmingDeleteMetadata: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(DatasetMetadataSpec)), defaultValue: List() },
}))();

const DatasetMetadataFilterInfo = Record({ selectedDatasetName: '', fetchSequence: 0 });

export const getSingleSelectedDataset = checkReturn(
  ArgTypes.orNull(Document.argTypeWithNestedClass(Dataset)),
  (state) => {
    const { projectDatasetCatalog: { datasets, selectedRows } } = state;

    if (selectedRows.size !== 1) {
      return null;
    }

    return datasets.get(selectedRows.first());
  },
);

export const getActiveDatasetMetadataFilterInfo = (state) => {
  const { datasetMetadata: { fetchSequence } } = state;

  const selectedDatasetName = getSingleSelectedDataset(state);

  return new DatasetMetadataFilterInfo({ selectedDatasetName, fetchSequence });
};

export const reducers = {
  'DatasetMetadata.toggleSidebar': (state) => {
    return state.set('propertiesSidebarOpen', !state.propertiesSidebarOpen);
  },
  'DatasetMetadata.closeSidebar': (state) => {
    return state.set('propertiesSidebarOpen', false);
  },
  'DatasetMetadata.getDatasetMetadata': (state) => {
    return state.set('loading', true);
  },
  'DatasetMetadata.getDatasetMetadataCompleted': (state, { dataset, metadata, currentlySelectedDataset, metadataFilterInfo }) => {
    return (dataset === currentlySelectedDataset) ?
      state.merge({
        currentMetadata: metadata,
        loadedDatasetMetadataFilterInfo: metadataFilterInfo,
        loading: false,
      }) :
      state.merge({
        loading: false,
      });
  },
  'DatasetMetadata.getDatasetMetadataFailed': (state) => {
    return state.clear();
  },
  'DatasetMetadata.writeMetadata': (state) => {
    return state.set('currentlyAddingProperty', false);
  },
  'DatasetMetadata.writeMetadataCompleted': (state) => {
    return state.update('fetchSequence', x => x + 1);
  },
  'DatasetMetadata.writeMetadataFailed': (state) => {
    return state.clear();
  },
  'DatasetMetadata.startAddProperty': (state) => {
    return state.set('currentlyAddingProperty', true);
  },
  'DatasetMetadata.cancelAddProperty': (state) => {
    return state.set('currentlyAddingProperty', false);
  },
  'DatasetMetadata.confirmDeleteMetadata': (state, { metadataToDelete }) => {
    return state.set('confirmingDeleteMetadata', metadataToDelete);
  },
  'DatasetMetadata.cancelDeleteMetadata': (state) => {
    return state.delete('confirmingDeleteMetadata');
  },
  'ProjectDatasetCatalog.selectRows': (state, { selectedRows }) => {
    return selectedRows.size !== 1 ?
      state.set('propertiesSidebarOpen', false) :
      state;
  },
};
