import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Term from '../../components/Term';
import { history } from '../../utils/History';
import { getActiveNumSources, getUnifiedDatasetRowCount } from '../../utils/Selectors';
import DualValueCard from '../DualValueCard';

const DatasetsCard = connect(state => {
  const numUnifiedRecords = getUnifiedDatasetRowCount(state);
  const numSourceDatasets = getActiveNumSources(state);
  return { numUnifiedRecords: numUnifiedRecords || 0, numSourceDatasets };
})(class DatasetsCard extends React.Component {
  static propTypes = {
    numSourceDatasets: PropTypes.number.isRequired,
    numUnifiedRecords: PropTypes.number.isRequired,
    short: PropTypes.bool,
    smRecipeId: PropTypes.number.isRequired,
  };

  static defaultProps = { short: false };

  navigateToDatasets = () => {
    history.push(`/datasets/recipe/${this.props.smRecipeId}`);
  };

  render() {
    const { numSourceDatasets, numUnifiedRecords } = this.props;
    return (
      <DualValueCard
        onClick={this.navigateToDatasets}
        short={this.props.short}
        bottomNounPlural="Datasets"
        bottomNounSingular="Dataset"
        bottomValue={numSourceDatasets}
        topNounPlural={<Term>records</Term>}
        topNounSingular={<Term>record</Term>}
        topValue={numUnifiedRecords}
      />
    );
  }
});

export default DatasetsCard;
