import React from 'react';
import { connect } from 'react-redux';

import Checkbox from '../components/Input/Checkbox';
import SearchBox from '../components/SearchBox';
import { getFilterableTotalCount } from '../components/TotalCount';
import {
  CLASSIFICATION, ENRICHMENT,
  GOLDEN_RECORDS,
  SCHEMA_MAPPING_RECOMMENDATIONS,
  SUPPLIER_MASTERING,
} from '../constants/ProjectTypes';
import { AppAction } from '../stores/AppAction';
import AppState from '../stores/AppState';
import { canCreateProjects } from '../utils/Authorization';
import { getAuthorizedUser, selectAllProjectInfos } from '../utils/Selectors';
import CreateNewProjectButton from './CreateNewProjectButton';
import {
  SET_FILTER_TO_CATEGORIZATION, SET_FILTER_TO_ENRICHMENT,
  SET_FILTER_TO_GOLDEN_RECORDS,
  SET_FILTER_TO_MASTERING,
  SET_FILTER_TO_SCHEMA_MAPPING,
  SET_SEARCH_QUERY,
} from './ProjectsActionTypes';
import { selectFilteredProjectInfos } from './ProjectsSelectors';
import style from './ProjectTaskbar.module.scss';


const TypeFilterCheckbox: React.FC<{
  typeName: string
  numProjects: number
  totalNumProjects: number
  value: boolean
  onChange: (value: boolean) => void
}> = ({ typeName, numProjects, totalNumProjects, value, onChange }) => {
  return numProjects > 0 && totalNumProjects !== numProjects ? (
    <Checkbox
      title={`${typeName} (${numProjects})`}
      titlePosition="right"
      {...{ value, onChange }}
    />
  ) : (
    <noscript />
  );
};

const ProjectTaskbar = connect((state: AppState) => {
  const { projects: { filterToMastering, filterToCategorization, filterToSchemaMapping, searchQuery, filterToGoldenRecords, filterToEnrichment } } = state;
  const projectInfos = selectAllProjectInfos(state);
  const groupedByType = projectInfos.groupBy(projectInfo => projectInfo.projectType);
  return {
    numCategorizationProjects: groupedByType.get(CLASSIFICATION)?.count() || 0,
    numMasteringProjects: groupedByType.get(SUPPLIER_MASTERING)?.count() || 0,
    numSchemaMappingProjects: groupedByType.get(SCHEMA_MAPPING_RECOMMENDATIONS)?.count() || 0,
    numGoldenRecordsProjects: groupedByType.get(GOLDEN_RECORDS)?.count() || 0,
    numEnrichmentProjects: groupedByType.get(ENRICHMENT)?.count() || 0,
    filterToMastering,
    filterToSchemaMapping,
    filterToCategorization,
    filterToGoldenRecords,
    filterToEnrichment,
    searchQuery,
    user: getAuthorizedUser(state),
    filteredProjectInfos: selectFilteredProjectInfos(state),
  };
}, {
  onSetFilterToCategorization: (value: boolean): AppAction => ({ type: SET_FILTER_TO_CATEGORIZATION, value }),
  onSetFilterToMastering: (value: boolean): AppAction => ({ type: SET_FILTER_TO_MASTERING, value }),
  onSetFilterToSchemaMapping: (value: boolean): AppAction => ({ type: SET_FILTER_TO_SCHEMA_MAPPING, value }),
  onSetFilterToGoldenRecords: (value: boolean): AppAction => ({ type: SET_FILTER_TO_GOLDEN_RECORDS, value }),
  onSetFilterToEnrichment: (value: boolean): AppAction => ({ type: SET_FILTER_TO_ENRICHMENT, value }),
  onSetSearchQuery: (searchQuery: string): AppAction => ({ type: SET_SEARCH_QUERY, searchQuery }),
})(({ user, numCategorizationProjects, numMasteringProjects, numSchemaMappingProjects,
  numGoldenRecordsProjects, numEnrichmentProjects, searchQuery, onSetSearchQuery, filterToMastering,
  filterToCategorization, filterToSchemaMapping, filterToGoldenRecords, filterToEnrichment, filteredProjectInfos,
  onSetFilterToMastering, onSetFilterToCategorization, onSetFilterToSchemaMapping, onSetFilterToGoldenRecords,
  onSetFilterToEnrichment,
}) => {
  const filtersActive = filterToMastering || filterToCategorization || filterToSchemaMapping || filterToGoldenRecords
    || filterToEnrichment || !!searchQuery;
  const currentTotal = filteredProjectInfos.size;
  const totalNumProjects = numCategorizationProjects + numMasteringProjects + numSchemaMappingProjects + numGoldenRecordsProjects + numEnrichmentProjects;
  const totalString = getFilterableTotalCount({ filtersActive, currentTotal, overallTotal: totalNumProjects });
  return (
    <div className={style.projectTaskbar}>
      <span className={style.pageTitle}>Projects</span>
      {totalString && <span className={style.numProjects}>&nbsp;({totalString})</span>}
      <TypeFilterCheckbox {...{ totalNumProjects }} typeName="Categorization" numProjects={numCategorizationProjects} value={filterToCategorization} onChange={onSetFilterToCategorization} />
      <TypeFilterCheckbox {...{ totalNumProjects }} typeName="Mastering" numProjects={numMasteringProjects} value={filterToMastering} onChange={onSetFilterToMastering} />
      <TypeFilterCheckbox {...{ totalNumProjects }} typeName="Schema Mapping" numProjects={numSchemaMappingProjects} value={filterToSchemaMapping} onChange={onSetFilterToSchemaMapping} />
      <TypeFilterCheckbox {...{ totalNumProjects }} typeName="Golden Records" numProjects={numGoldenRecordsProjects} value={filterToGoldenRecords} onChange={onSetFilterToGoldenRecords} />
      <TypeFilterCheckbox {...{ totalNumProjects }} typeName="Enrichment" numProjects={numEnrichmentProjects} value={filterToEnrichment} onChange={onSetFilterToEnrichment} />
      <div className={style.searchBox}>
        <SearchBox value={searchQuery} onSearch={onSetSearchQuery} searchOnKeyup />
      </div>
      {canCreateProjects(user) ? <CreateNewProjectButton className={style.createButton} /> : null}
    </div>
  );
});

export default ProjectTaskbar;
