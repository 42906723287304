import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import { Col } from 'react-bootstrap';

import style from './DetailSidebar.module.scss';

export const SidebarSection: React.FC<{
  className?: string
  title: React.ReactNode
  additionalTitleContent?: React.ReactNode
  children: React.ReactNode
}> = ({ className, title, additionalTitleContent, children }) => {
  return (
    <div className={classNames(style.sidebarSection, className)}>
      <div className={style.sectionTitleContainer}>
        <span className={style.sectionTitle}>{title}</span>
        {additionalTitleContent}
      </div>
      {children}
    </div>
  );
};


const propTypes = {
  /**
   * If true, animate the expansion and collapse of the sidebar
   */
  animate: PropTypes.bool,
  /**
   * Additional class name to add to the sidebar
   */
  className: PropTypes.string,
  /**
   * Whether the sidebar is expandable
   */
  isExpandable: PropTypes.bool,
  /**
   * Whether the sidebar is expanded or collapsed.  Required if isExpandable is true.
   */
  isExpanded: PropTypes.bool,
};

type DetailSidebarProps = InferProps<typeof propTypes>;

/**
 * React element that represents the right details sidebar to be used in a typical layout
 */
class DetailSidebar extends React.Component<DetailSidebarProps> {
  static propTypes = propTypes;

  static defaultProps = { animate: false };

  render() {
    const containerClass = classNames(style.sidebarContainer, this.props.className, {
      open: this.props.isExpanded,
      closed: !this.props.isExpanded,
      animate: this.props.animate,
    });
    const gridWidth = this.props.isExpanded ? 12 : 0;
    return (
      <Col md={gridWidth} className={containerClass} style={{ height: '100%', width: '100%' }}>
        <div className={style.rightSidebar} ref="rightSidebar">
          {this.props.children}
        </div>
      </Col>
    );
  }
}

export default DetailSidebar;
