import React from 'react';

import Chrome from '../chrome/Chrome';
import CenterContent from '../components/CenterContent';

class Unauthorized extends React.Component {
  render() {
    return (
      <Chrome>
        <CenterContent className="unauthorized-page">
          You are not authorized to view this page
        </CenterContent>
      </Chrome>
    );
  }
}

export default Unauthorized;
