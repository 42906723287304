import classNames from 'classnames';
import numeral from 'numeral';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import _, { isNumber } from 'underscore';

import { isNotEmpty } from '../../utils/Values';
import Button from '../Button';
import { TamrIconName } from '../Icons/TamrIconClasses';
import Input from '../Input/Input';
import Selector from '../Input/Selector';
import TamrIcon from '../TamrIcon';

const NUM_PAGES_TO_SHOW = 5;

const propTypes = {
  disabled: PropTypes.bool,

  /**
   * Whether to hide the navigation
   */
  hidePageNavigation: PropTypes.bool,

  /**
   * Should you be able to enter a page number?
   */
  inputDisabled: PropTypes.bool,

  /**
   * Method that is called when the page is changed (e.g. first, last or a page number)
   */
  onPageChange: PropTypes.func,

  /**
   * Method that is called when the paging size is changed
   */
  onPageSizeChange: PropTypes.func.isRequired,

  /**
   * If supplied, replaces the label used for page size dropdown
   */
  pageSizeLabel: PropTypes.node,

  /**
   * Immutable.List of strings where -1 represents ALL.  If nothing is passed in, paging will not be enabled
   */
  pageSizes: ImmutablePropTypes.listOf(PropTypes.number.isRequired).isRequired,

  /**
   * Content to appear in the right-hand portion of the pager
   */
  pagerContent: PropTypes.node,

  /**
   * Content to appear in the left-hand portion of the pager
   */
  pagerContentLeft: PropTypes.node,

  /**
   * Content to appear in the right-hand portion of the pager
   */
  pagerContentRight: PropTypes.node,

  /**
   * Content to appear above the pager
   */
  pagerContentTop: PropTypes.node,

  /**
   * The current state of the pager as controlled by the including Table
   */
  pagerState: PropTypes.shape({
    /**
     * If supplied, this value is used to determine whether the "next page" arrow is enabled.
     * This is useful in cases where the total number of pages is not known, but it is possible to
     * tell if more results are available.
     */
    hasNextPage: PropTypes.bool,
    /**
     * If true, do not show the pages dropdown
     */
    hidePageSize: PropTypes.bool,
    /**
     * The current page size
     */
    pageSize: PropTypes.number.isRequired,
    /**
     * The total number of pages.  If it is not supplied, then do not show the total count
     */
    pageCount: PropTypes.number,
    /**
     * The current number of the page
     */
    pageNum: PropTypes.number.isRequired,
    tooltip: PropTypes.string,
  }).isRequired,

  tooltip: PropTypes.string,
};

type PagerProps = InferProps<typeof propTypes>;

type PagerState = {
  isPageNumberValid: boolean;
  uncommittedPageNumber: string | undefined;
};

/**
 * Class to define a paging controller for a Table
 */
class Pager extends React.Component<PagerProps, PagerState> {
  static propTypes = propTypes;

  static defaultProps = { hidePageNavigation: false };

  state: PagerState = {
    isPageNumberValid: true,
    uncommittedPageNumber: undefined,
  };

  onArrowClick = (pageIndex: number) => {
    this.setState({ isPageNumberValid: true });
    if (this.props.onPageChange) {
      this.props.onPageChange.call(this, pageIndex);
    }
  };

  onPageInputChange = (newValue: string) => {
    this.setState({
      uncommittedPageNumber: newValue,
      isPageNumberValid: this.isValidPageInputValue(parseInt(newValue, 10)),
    });
  };

  onPageInputBlur = () => {
    this.setState({
      uncommittedPageNumber: undefined,
      isPageNumberValid: true,
    });
  };

  onPageInputSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { uncommittedPageNumber } = this.state;
    const parsedValue = uncommittedPageNumber ? parseInt(uncommittedPageNumber, 10) : undefined;
    if (isNumber(parsedValue) && this.isValidPageInputValue(parsedValue)) {
      if (this.props.onPageChange) {
        this.props.onPageChange.call(this, parsedValue - 1);
      }
      this.setState({ uncommittedPageNumber: undefined });
    }
  };

  getFirstPageButtonIndex = () => {
    const {
      pagerState: { pageCount },
    } = this.props;
    const max = isNumber(pageCount) ? Math.max(pageCount - NUM_PAGES_TO_SHOW, 0) : 0;
    const min = 0;
    const calculated = this.props.pagerState.pageNum - Math.floor(NUM_PAGES_TO_SHOW / 2);
    return Math.floor(Math.min(max, Math.max(min, calculated)));
  };

  getLastPageIndex = () => {
    const {
      pagerState: { pageCount },
    } = this.props;
    return isNumber(pageCount) ? pageCount - 1 : pageCount;
  };

  getNextPageIndex = () => {
    const lastPageIndex = this.getLastPageIndex();
    if (!this.shouldRenderPageCount() || !isNotEmpty(lastPageIndex)) {
      return this.props.pagerState.pageNum + 1;
    }
    return Math.min(this.props.pagerState.pageNum + 1, lastPageIndex);
  };

  getPreviousPageIndex = () => {
    return Math.max(this.props.pagerState.pageNum - 1, 0);
  };

  formatValues = () => {
    return this.props.pageSizes
      .map((value) => {
        let display;
        if (value > -1) {
          display = String(value);
        } else {
          display = 'Show All';
        }
        return {
          value,
          display,
        };
      })
      .toArray();
  };

  isValidPageInputValue = (parsedValue: number) => {
    const {
      pagerState: { pageCount },
    } = this.props;
    if (isNaN(parsedValue) || parsedValue < 1) return false;
    // if pageCount is not defined (null or undefined), then we don't know the total,
    // so the input value is assumed to be valid.
    return !(isNotEmpty(pageCount) && parsedValue > pageCount);
  };

  longFormat = (num: number, max = 1000000) => {
    return num < max ? numeral(num).format('0,0') : this.shortFormat(num);
  };

  pageCount = () => {
    if (!this.shouldRenderPageCount()) {
      return <span key="page-nav-max-pg-count" />;
    }
    const {
      pagerState: { pageCount },
    } = this.props;
    const pageCountMsg = isNumber(pageCount) && this.longFormat(pageCount);
    return (
      <span className="tamr-table-pager-item page-count" key="page-nav-max-pg-count">
        of {pageCountMsg}
      </span>
    );
  };

  pageInput = () => {
    return (
      <form key="page-nav-num-input-form" onSubmit={this.onPageInputSubmit}>
        <Input
          key="page-nav-num-input"
          componentClassName="tamr-table-pager-item page-num-input"
          className="tamr-table-pager-input-field"
          invalid={!this.state.isPageNumberValid}
          value={
            !_.isUndefined(this.state.uncommittedPageNumber)
              ? this.state.uncommittedPageNumber
              : this.props.pagerState.pageNum + 1
          }
          onChange={this.onPageInputChange}
          onBlur={this.onPageInputBlur}
          disabled={this.props.inputDisabled || undefined}
          title=""
          autoComplete="off"
        />
      </form>
    );
  };

  pagerButton = (options: {
    display: string;
    icon: JSX.Element | TamrIconName;
    handler: () => void;
    isDisabled: boolean;
    className: string;
  }) => {
    const displayAsNumber = parseInt(options.display, 10);
    const buttonClass = classNames({
      'tamr-table-pager-item': true,
      'is-selected': !isNaN(displayAsNumber) && this.props.pagerState.pageNum === displayAsNumber - 1,
      [options.className]: !!options.className,
    });

    return (
      <Button
        key={'page-nav-' + options.display}
        className={buttonClass}
        buttonType="Flat"
        onClick={options.handler}
        disabled={options.isDisabled}
        icon={options.icon}
      />
    );
  };

  // TODO: pull Values / Numbers / etc. from procurify up into ui-components
  shortFormat = (num: number) => {
    return num < 1000 ? num : numeral(num).format('0.0a').toUpperCase();
  };

  shouldRenderPageCount = () => {
    return _.isNumber(this.props.pagerState.pageCount);
  };

  renderButtonToolbar = () => {
    const prevButton = <TamrIcon iconName="chevron-left" size={24} />;
    const nextButton = <TamrIcon iconName="chevron-right" size={24} />;
    const { hasNextPage } = this.props.pagerState;
    return [
      this.pagerButton({
        display: 'Previous',
        icon: prevButton,
        handler: _.partial(this.onArrowClick, this.getPreviousPageIndex()),
        isDisabled: this.props.disabled || this.props.pagerState.pageNum === 0,
        className: 'previous-page-button',
      }),
      this.pageInput(),
      this.pageCount(),
      this.pagerButton({
        display: 'Next',
        icon: nextButton,
        handler: _.partial(this.onArrowClick, this.getNextPageIndex()),
        isDisabled:
          this.props.disabled ||
          (_.isBoolean(hasNextPage) ? !hasNextPage : _.isEqual(this.props.pagerState.pageNum, this.getLastPageIndex())),
        className: 'next-page-button',
      }),
    ];
  };

  renderPageSizeSelector = () => {
    if (this.props.pagerState.hidePageSize) {
      return <span />;
    }
    return (
      <span className="tamr-table-pager-selector-container">
        <span className="tamr-table-pager-item selector-label">{this.props.pageSizeLabel || 'Records per page:'}</span>
        <Selector
          placeholder="Records per page"
          onChange={this.props.onPageSizeChange}
          values={this.formatValues()}
          value={this.props.pagerState.pageSize}
          dropup
        />
      </span>
    );
  };

  render() {
    const { pagerContentLeft, pagerContentRight } = this.props;
    return (
      <div className="tamr-table-pager-container">
        {this.props.pagerContentTop}
        <div className="tamr-table-pager" title={this.props.tooltip || undefined}>
          {pagerContentLeft && <div className="tamr-pager-content-left">{pagerContentLeft}</div>}
          {this.props.hidePageNavigation ? null : this.renderButtonToolbar()}
          {this.renderPageSizeSelector()}
          <div className="tamr-pager-content">{this.props.pagerContent}</div>
          {pagerContentRight}
        </div>
      </div>
    );
  }
}

export default Pager;
