import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import LoadingPanel from '../components/LoadingPanel';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import TooltipTrigger from '../components/TooltipTrigger';
import { history } from '../utils/History';
import PRODUCT_NAME from '../utils/ProductName';
import { getActiveRecipeDoc } from '../utils/Selectors';
import { pluralize } from '../utils/Strings';
import CategorizationDashboardAssignedToMeChart from './CategorizationDashboardAssignedToMeChart';
import CategorizationDashboardAssignmentChart from './CategorizationDashboardAssignmentChart';
import CategorizationDashboardAssignmentsAllDone from './CategorizationDashboardAssignmentsAllDone';
import style from './CategorizationDashboardAssignmentsCard.module.scss';

const navigate = (url, onResetTransactionsFilterState) => {
  if (_.isFunction(onResetTransactionsFilterState)) {
    onResetTransactionsFilterState();
  }
  history.push(url);
};

const CategorizationDashboardAssignmentsCard = _.compose(
  connect(state => {
    const { categorizationDashboard: { numberToVerify, numberToVerifyAgree, numberVerified, loading }, transactions: { feedbackSummaryNumAssigned, feedbackSummaryNumResponded } } = state;
    const categorizationRecipeId = getActiveRecipeDoc(state).id.id;
    const feedbackSummaryNumOpen = feedbackSummaryNumAssigned - feedbackSummaryNumResponded;
    const needsAttention = feedbackSummaryNumOpen + numberToVerify;
    return { recipeId: categorizationRecipeId, numberToVerify, numberToVerifyAgree, numberVerified, needsAttention, feedbackSummaryNumOpen, loading };
  }, {
    onResetTransactionsFilterState: () => ({ type: 'Transactions.resetFilters' }),
  }),
)(({ recipeId, onResetTransactionsFilterState, numberToVerify, numberVerified, needsAttention, feedbackSummaryNumOpen, loading }) => {
  if (loading) {
    return <LoadingPanel />;
  }
  if (needsAttention === 0) {
    return <CategorizationDashboardAssignmentsAllDone />;
  }

  const assignmentButton = numberToVerify > 0
    ? (
      <Button
        buttonType="Secondary"
        onClick={() => navigate(`/spend/recipe/${recipeId}?assignmentStatusAll=true&assignmentStatusUnassigned=true&expertResponsesAgree=true&expertResponsesDisagree=true&unlabeled=true&expertsSome=true&assignmentStatusAssigned=true`, onResetTransactionsFilterState)}
      >
        Review <Term amount={numberVerified}>records</Term> to verify
      </Button>
    )
    : (
      <Button
        buttonType="Secondary"
        onClick={() => navigate(`/spend/recipe/${recipeId}`, onResetTransactionsFilterState)}
      >
        Go to <Term>records</Term>
      </Button>
    );

  const assignedToMeButton = feedbackSummaryNumOpen > 0
    ? (
      <Button
        buttonType="Secondary"
        onClick={() => navigate(`/spend/recipe/${recipeId}?assignedToMeToDo=true`, onResetTransactionsFilterState)}
      >
        Review your open assignments
      </Button>
    )
    : (
      <Button
        buttonType="Secondary"
        onClick={() => navigate(`/spend/recipe/${recipeId}`, onResetTransactionsFilterState)}
      >
        Go to <Term>records</Term>
      </Button>
    );

  return (
    <div>
      <div className={style.title}>
        {needsAttention} <Term amount={needsAttention}>record</Term> {pluralize(needsAttention, 'needs', 'need')} attention
        <TooltipTrigger
          content={<span>{PRODUCT_NAME} only learns from responses that have been verified by curators or admins. These help {PRODUCT_NAME} suggest better categorizations for <Term>records</Term>.</span>}
          placement="bottom"
        >
          <span className={style.infoIcon}>
            <TamrIcon iconName="info-outline" size={14} />
          </span>
        </TooltipTrigger>
      </div>
      <div className={style.charts}>
        <div className={style.userAssignmentContainer}>
          <CategorizationDashboardAssignmentChart />
          <div className={style.buttonContainer}>
            <div className={style.buttonBox}>
              {assignmentButton}
            </div>
          </div>
        </div>
        <div className={style.myAssginmentContainer}>
          <CategorizationDashboardAssignedToMeChart />
          <div className={style.buttonContainer}>
            <div className={style.buttonBox}>
              {assignedToMeButton}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});


export default CategorizationDashboardAssignmentsCard;
