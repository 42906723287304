import { List, Map, Set } from 'immutable';
import _ from 'underscore';

import Document from '../models/doc/Document';
import Job from '../models/Job';
import Model from '../models/Model';
import PostButtonSpec from '../models/PostButtonSpec';
import RedirectButtonSpec from '../models/RedirectButtonSpec';
import StorageHealthCheckResult from '../models/StorageHealthCheckResult';
import {
  COMMIT_UNIFIED_ATTRIBUTES,
  COMMIT_UNIFIED_ATTRIBUTES_COMPLETED,
  COMMIT_UNIFIED_ATTRIBUTES_FAILED,
  PREDICT_SUGGESTIONS,
  PREDICT_SUGGESTIONS_COMPLETED,
  PREDICT_SUGGESTIONS_FAILED,
  TRAIN_SUGGESTIONS,
  TRAIN_SUGGESTIONS_COMPLETED,
  TRAIN_SUGGESTIONS_FAILED,
} from '../schema-mapping/SchemaMappingActionTypes';
import { ArgTypes, checkArg, checkReturn } from '../utils/ArgValidation';
import { getPath } from '../utils/Values';
import {
  EXTENSION_BUTTON_POST_CALL_COMPLETE,
  EXTENSION_BUTTON_POST_CALL_REQUESTED,
  FETCH_EXTENSION_BUTTONS_COMPLETE,
  SHOW_EXTENSION_RESPONSE_DIALOG,
  STORAGE_HEALTH_CHECK_COMPLETE,
  STORAGE_HEALTH_CHECK_FAILED,
} from './ChromeActionTypes';

export const initialState = new (Model({
  extensionButtons: {
    type: ArgTypes.Immutable.list.of(ArgTypes.oneOf(
      ArgTypes.instanceOf(PostButtonSpec),
      ArgTypes.instanceOf(RedirectButtonSpec),
    )),
    defaultValue: List(),
  },
  extensionButtonPostCallInProgress: { type: ArgTypes.bool, defaultValue: false },
  showExtensionButtonPostCallDialog: { type: ArgTypes.bool, defaultValue: false },
  extensionButtonPostCallConfig: { type: ArgTypes.orUndefined(ArgTypes.instanceOf(PostButtonSpec)) },
  extensionButtonPostCallSuccessful: { type: ArgTypes.bool, defaultValue: false },
  extensionButtonPostCallMessage: { type: ArgTypes.string, defaultValue: '' },
  initialRunningJobsFetch: { type: ArgTypes.bool, defaultValue: false },
  loadingRunningJobs: { type: ArgTypes.bool, defaultValue: false },
  userHiddenId: { type: ArgTypes.orUndefined(ArgTypes.number) },
  runningJobs: { type: ArgTypes.Immutable.map.of(Document.argTypeWithNestedClass(Job), ArgTypes.number), defaultValue: Map() },
  showLastJobFailed: { type: ArgTypes.bool, defaultValue: false },
  requestedProfileJobs: { type: ArgTypes.Immutable.list.of(ArgTypes.string), defaultValue: List() },
  requestedCommitSchemaJobs: { type: ArgTypes.Immutable.set.of(ArgTypes.number) /* sm recipe id */, defaultValue: Set() },
  requestedTrainSuggestionsJobs: { type: ArgTypes.Immutable.set.of(ArgTypes.number) /* sm recs recipe id */, defaultValue: Set() },
  requestedPredictSuggestionsJobs: { type: ArgTypes.Immutable.set.of(ArgTypes.number) /* sm recs recipe id */, defaultValue: Set() },
  storageHealthStatus: { type: ArgTypes.orUndefined(ArgTypes.instanceOf(StorageHealthCheckResult)), defaultValue: undefined },
}))();

const jobUpdate = (state, { job }) => {
  checkArg({ job }, Document.argTypeWithNestedClass(Job));
  if (_.contains(['SUCCEEDED', 'FAILED', 'CANCELED'], job.data.status.state)) {
    return state
      .update('runningJobs', rj => rj.remove(job.id.id))
      .set('showLastJobFailed', job.data.status.state === 'FAILED');
  }
  return state.update('runningJobs', rj => rj.set(job.id.id, job));
};

const checkRecipeIdAndPerform = checkReturn(ArgTypes.func, (fn) => {
  checkArg({ fn }, ArgTypes.func);
  return (state, { recipeId }) => {
    checkArg({ recipeId }, ArgTypes.number);
    return fn(state, { recipeId });
  };
});

const completeRecipeJobRequest = checkReturn(ArgTypes.func, (stateField) => {
  return (state, { recipeId, job }) => {
    checkArg({ recipeId }, ArgTypes.number);
    checkArg({ job }, Document.argTypeWithNestedClass(Job));
    checkArg({ 'the recipeId in the job\'s metadata': getPath(job, 'data', 'metadata', 'recipeId') }, ArgTypes.number);
    return state.update(stateField, recipeIds => recipeIds.delete(recipeId))
      .update(s => jobUpdate(s, { job }));
  };
});

export const reducers = {
  // TODO consolidate this with other job notification actions, like Jobs.classificationCompleted
  'Jobs.jobUpdate': jobUpdate,

  'Chrome.closeJobStatus': (state) => {
    return state.set('userHiddenId', state.runningJobs.first().id.id);
  },

  'Chrome.resetFailureNotification': (state) => {
    return state.set('showLastJobFailed', false);
  },

  'Chrome.loadRunningJobs': (state) => {
    return state.merge({
      loadingRunningJobs: true,
    });
  },

  'Chrome.loadRunningJobsCompleted': (state, { jobs }) => {
    return state.update('runningJobs', rj => {
      return jobs.reduce((rn, jobDocJson) => {
        const jobDoc = Document.fromJSON(jobDocJson, Job.fromJSON);
        return rn.set(jobDoc.id.id, jobDoc);
      }, rj);
    }).merge({
      loadingRunningJobs: false,
      initialRunningJobsFetch: true,
    });
  },

  'Chrome.profileJobRequested': (state, { datasetName }) => {
    checkArg({ datasetName }, ArgTypes.string);
    return state.update('requestedProfileJobs', datasets => datasets.push(datasetName));
  },

  'Chrome.profileJobRequestComplete': (state, { datasetName }) => {
    checkArg({ datasetName }, ArgTypes.string);
    const index = state.get('requestedProfileJobs').indexOf(datasetName);
    return index < 0 ? state
      : state.update('requestedProfileJobs', datasets => datasets.delete(index));
  },

  [COMMIT_UNIFIED_ATTRIBUTES]: checkRecipeIdAndPerform((state, { recipeId }) => state.update('requestedCommitSchemaJobs', recipeIds => recipeIds.add(recipeId))),
  [COMMIT_UNIFIED_ATTRIBUTES_FAILED]: checkRecipeIdAndPerform((state, { recipeId }) => state.update('requestedCommitSchemaJobs', recipeIds => recipeIds.delete(recipeId))),
  [COMMIT_UNIFIED_ATTRIBUTES_COMPLETED]: completeRecipeJobRequest('requestedCommitSchemaJobs'),

  [TRAIN_SUGGESTIONS]: checkRecipeIdAndPerform((state, { recipeId }) => state.update('requestedTrainSuggestionsJobs', recipeIds => recipeIds.add(recipeId))),
  [TRAIN_SUGGESTIONS_FAILED]: checkRecipeIdAndPerform((state, { recipeId }) => state.update('requestedTrainSuggestionsJobs', recipeIds => recipeIds.delete(recipeId))),
  [TRAIN_SUGGESTIONS_COMPLETED]: completeRecipeJobRequest('requestedTrainSuggestionsJobs'),

  [PREDICT_SUGGESTIONS]: checkRecipeIdAndPerform((state, { recipeId }) => state.update('requestedPredictSuggestionsJobs', recipeIds => recipeIds.add(recipeId))),
  [PREDICT_SUGGESTIONS_FAILED]: checkRecipeIdAndPerform((state, { recipeId }) => state.update('requestedPredictSuggestionsJobs', recipeIds => recipeIds.delete(recipeId))),
  [PREDICT_SUGGESTIONS_COMPLETED]: completeRecipeJobRequest('requestedPredictSuggestionsJobs'),

  [FETCH_EXTENSION_BUTTONS_COMPLETE]: (state, { data }) => {
    return state.set('extensionButtons', data);
  },

  [SHOW_EXTENSION_RESPONSE_DIALOG]: (state, { show }) => {
    return state.set('showExtensionButtonPostCallDialog', show);
  },

  [EXTENSION_BUTTON_POST_CALL_REQUESTED]: (state, { buttonSpec }) => {
    return state.merge({
      extensionButtonPostCallInProgress: true,
      extensionButtonPostCallConfig: buttonSpec,
    });
  },

  [EXTENSION_BUTTON_POST_CALL_COMPLETE]: (state, { successful, message }) => {
    return state.merge({
      extensionButtonPostCallInProgress: false,
      extensionButtonPostCallSuccessful: successful,
      extensionButtonPostCallMessage: message,
    });
  },

  [STORAGE_HEALTH_CHECK_COMPLETE]: (state, { data }) => {
    return state.set('storageHealthStatus', data);
  },

  [STORAGE_HEALTH_CHECK_FAILED]: (state) => {
    return state.set('storageHealthStatus', undefined);
  },

};
