import numeral from 'numeral';

const TemplateStrings = Object.freeze({
  Details: {
    description: 'Description',
    creationSubheader(date, byWhom) {
      const formattedDate = new Date(date).toLocaleDateString();
      return `Created ${formattedDate} by ${byWhom}`;
    },
    tierCategories(tier, numCategories) {
      const formattedNumCategories = numeral(numCategories).format('0,0');

      switch (numCategories) {
        case 0:
          return `No Tier ${tier} Categories`;
        case 1:
          return `${formattedNumCategories} Tier ${tier} category`;
        default:
          return `${formattedNumCategories} Tier ${tier} categories`;
      }
    },
    totalChildCategories(numCategories) {
      const formattedNumCategories = numeral(numCategories).format('0,0');

      switch (numCategories) {
        case 0:
          return 'No child categories';
        case 1:
          return `${formattedNumCategories} Child category`;
        default:
          return `${formattedNumCategories} Total child categories`;
      }
    },
    totalCategories(numCategories) {
      const formattedNumCategories = numeral(numCategories).format('0,0');

      switch (numCategories) {
        case 0:
          return 'No categories';
        case 1:
          return `${formattedNumCategories} Category`;
        default:
          return `${formattedNumCategories} Total categories`;
      }
    },
  },
  Taxonomy: {
    tierName(tierNum) {
      return `Tier ${tierNum}`;
    },
    numCategories(numCategories) {
      const formattedNumCategories = numeral(numCategories).format('0,0');

      switch (numCategories) {
        case 0:
          return 'No categories';
        case 1:
          return `${formattedNumCategories} category`;
        default:
          return `${formattedNumCategories} categories`;
      }
    },
    searchPlaceholder: 'Search for categories',
  },
});

export default TemplateStrings;
