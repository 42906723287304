import createLoader from '../utils/createLoader';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { fetchRecords } from './CategoryDetailApi';

const CategoryDetailLoader = createLoader((state) => {
  const { categoryDetail: { loading, loadedCategoryId, categoryId } } = state;
  const { unifiedDatasetDoc } = selectActiveProjectInfo(state) || {};
  return {
    canFetch: !!unifiedDatasetDoc,
    shouldFetch: loadedCategoryId !== categoryId,
    loading,
    fetchArgs: [categoryId],
  };
}, {
  onFetch: fetchRecords,
}, 'CategoryDetailLoader');

export default CategoryDetailLoader;
