import { is } from 'immutable';

import createLoader from '../utils/createLoader';
import { fetch } from './ProjectsApi';
import { getFilterInfo } from './ProjectsStore';

const ProjectsLoader = createLoader((state) => {
  const { projects: { loading, loadedFilterInfo, initialFetch } } = state;
  return {
    canFetch: true, // no preconditions
    shouldFetch: !initialFetch || !is(getFilterInfo(state), loadedFilterInfo),
    loading,
  };
}, {
  onFetch: fetch,
}, 'ProjectsLoader');

export default ProjectsLoader;
