import { connect } from 'react-redux';
import { withProps } from 'recompose';
import _ from 'underscore';

import AttributeList from './AttributeList';
import { SET_HEADER_SCROLL_BAR_OFFSET } from './SchemaMappingActionTypes';
import { filteredSourceAttributes } from './SchemaMappingStore';
import SourceAttributeItem from './SourceAttributeItem';

const SourceAttributeList = _.compose(
  connect(state => {
    const { schemaMapping } = state;
    const { sourceExpanded } = schemaMapping;
    return {
      expandedIds: sourceExpanded,
      attributes: filteredSourceAttributes(schemaMapping),
    };
  }, {
    onSetHeaderScrollbarOffset: offset => ({ type: SET_HEADER_SCROLL_BAR_OFFSET, offset }),
  }),
  withProps({
    AttributeItem: SourceAttributeItem,
  }),
)(AttributeList);

export default SourceAttributeList;
