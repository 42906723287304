import { combineReducers } from 'redux';

import * as filtersCountsSlice from './filtersCountsSlice';
import * as filtersSlice from './filtersSlice';

// Pane

export const paneInitialState = {
  filters: filtersSlice.initialState,
};

export const paneReducer = combineReducers({
  filters: filtersSlice.reducer,
});

// Global

export const initialState = {
  filtersCounts: filtersCountsSlice.initialState,
};

export const reducer = combineReducers({
  filtersCounts: filtersCountsSlice.reducer,
});
