import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import Cell from '../components/Table/Cell';
import { State } from '../stores/MainStore';
import { PregroupData } from './PregroupData';
import style from './PregroupRecordCell.module.scss';
import { selectCellData } from './PregroupStore';


interface PregroupRecordCellProps {
  column: string
  rowIndex: number
}

function mapStateToProps(state: State, { column, rowIndex }: PregroupRecordCellProps) {
  const { isGroupedValue, numRecordsInGroup, content } = selectCellData(column, rowIndex)(state);
  const { pregroup: { isPreviewEnabled } } = state;
  return {
    isGroupedValue,
    numRecordsInGroup,
    content,
    isPreviewEnabled,
  };
}

const PregroupRecordCell = connect(mapStateToProps)(({ content, numRecordsInGroup, isPreviewEnabled }) => (
  <Cell className={classNames({ [style.isMulti]: (numRecordsInGroup || 0) > 1 }, { [style.isPreviewDisabled]: !isPreviewEnabled })}>
    {content === null
      ? null
      : <PregroupData typedValue={content} />
    }
  </Cell>
));

export default PregroupRecordCell;
