import { List, Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import { isStepInitialized, isStepUpToDate } from '../chrome/UpdatesUtils';
import MenuButton from '../components/MenuButton';
import Term from '../components/Term';
import { ProjectStepsKey as ProjectStepKey } from '../constants/ProjectStepsKey';
import { DEDUP_INFO_METADATA_KEY } from '../models/Recipe';
import { getRecipeDocById } from '../projects/ProjectsStore';
import { isCuratorByProjectId } from '../utils/Authorization';
import PRODUCT_NAME from '../utils/ProductName';
import { getAuthorizedUser, isJobRunning, selectActiveProjectInfo } from '../utils/Selectors';

const RecordPairsUpdateResultsButton = _.compose(
  connect(state => {
    const {
      confusionMatrix: { manualLabels, loading: loadingConfusionMatrix },
      projects,
      location: { recipeId },
      recordPairs: { suggestionLaunching, dedupModelExists },
    } = state;
    const recipe = getRecipeDocById(projects.projectsWithStatus, recipeId)?.data;
    const projectInfo = selectActiveProjectInfo(state);
    const schemaMappingOutOfDate = !isStepUpToDate(state, ProjectStepKey.RECORDS);
    const generatePairsOutOfDate = !isStepUpToDate(state, ProjectStepKey.PAIRS);
    const generatePairsNotInitialized = !isStepInitialized(state, ProjectStepKey.PAIRS);
    return {
      manualLabels,
      loadingConfusionMatrix,
      recipe,
      recipeId,
      userIsCurator: isCuratorByProjectId(getAuthorizedUser(state), projectInfo?.projectDoc.id.id),
      schemaMappingOutOfDate,
      generatePairsOutOfDate,
      generatePairsNotInitialized,
      suggestionRunning: suggestionLaunching || isJobRunning(state, { recipeOperation: 'trainPredictCluster', forActiveProject: true }) || isJobRunning(state, { recipeOperation: 'predictCluster', forActiveProject: true }),
      dedupModelExists,
    };
  }, {
    onShowPredictWarning: () => ({ type: 'RecordPairs.showPredictWarning' }),
    onShowTrainPredictWarning: () => ({ type: 'RecordPairs.showTrainPredictWarning' }),
  }),
)(({ manualLabels, loadingConfusionMatrix, suggestionRunning, recipe, userIsCurator, onShowPredictWarning, onShowTrainPredictWarning, iconify, schemaMappingOutOfDate, generatePairsOutOfDate, generatePairsNotInitialized, dedupModelExists }) => {
  if (!recipe || !userIsCurator) {
    return <noscript />;
  }

  // assumes only one input dataset
  const dedupMetadata = recipe.metadata.get(DEDUP_INFO_METADATA_KEY);
  const configuredCorrectly = !!dedupMetadata && !_.isEmpty(dedupMetadata.nameField) && !_.isEmpty(dedupMetadata.includedFields);

  const trainAndPredictPreconditions = Map()
    .set(`You must have at least one verified label${loadingConfusionMatrix ? '. (Loading...)' : ''}`, !configuredCorrectly || manualLabels !== 0)
    .set(<span>You must have configured the unified schema to contain ML fields and a <Term>supplier</Term> field</span>, configuredCorrectly)
    .set('You cannot have a currently running update results job', !suggestionRunning)
    .set('You must update the unified dataset', !schemaMappingOutOfDate)
    .set(<span>You must re-generate <Term>pairs</Term> from the <Term>pair</Term> management page</span>, !generatePairsOutOfDate);

  const predictOnlyPreconditions = Map()
    .set(<span>You must have configured the unified schema to contain ML fields and a <Term>supplier</Term> field before updating results</span>, configuredCorrectly)
    .set('You cannot have a currently running update results job', !suggestionRunning)
    .set(<span>You must generate <Term>pairs</Term> from the <Term>pair</Term> management page before updating results</span>, !generatePairsNotInitialized)
    .set(<span>You must re-generate <Term>pairs</Term> from the <Term>pair</Term> management page before updating results</span>, !generatePairsOutOfDate)
    .set(`You must have already trained ${PRODUCT_NAME} before updating results${_.isUndefined(dedupModelExists) ? '. (Loading...)' : ''}`, !!dedupModelExists);

  return (
    <MenuButton
      buttonText={iconify ? 'Apply fb. & upd. res.' : 'Apply feedback and update results'}
      buttonPreconditions={trainAndPredictPreconditions}
      buttonOnClick={onShowTrainPredictWarning}
      dropdownPreconditions={predictOnlyPreconditions}
      options={List.of({ text: 'Update results only', onClick: onShowPredictWarning })}
    />
  );
});

export default RecordPairsUpdateResultsButton;
