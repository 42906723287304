import classNames from 'classnames';
import React from 'react';
import _ from 'underscore';

import Highlighter from '../Highlighter';
import TamrIcon from '../TamrIcon';


type ListSelectorElementProps = {
  /**
   * Indicates that a particlate checkbox was the last selected
   */
  active?: boolean,

  /**
   * The display value of the element.  If it isn't specified, will use the `key`
   */
  label?: React.ReactNode,
  /**
   * Callback to invoke when clicking this element.  The callback has as an input:
   * - key
   * - value
   */
  onSelect: (value: string | number, order: number, selected: boolean, event: React.MouseEvent<HTMLDivElement>) => void,
  /**
   * The order that this element appears
   */
  order: number,
  /**
   * Value currently beign searched on
   */
  searchValue?: string,
  /**
   * Whether this particular element is selected
   */
  selected?: boolean,
  /**
   * The internal value of the element
   */
  value: string | number,
};
type ListSelectorElementState = {
  hover: boolean
}

/**
 * Component that shows a single value in the list selector
 */
class ListSelectorElement extends React.PureComponent<ListSelectorElementProps, ListSelectorElementState> {
  state = {
    hover: false,
  };

  render() {
    const { selected, label, searchValue, onSelect, value, order, active } = this.props;
    const textLabel = _.isString(label) || _.isNumber(label) ? <Highlighter fullText={`${label}`} highlightText={searchValue} /> : label;
    const { hover } = this.state;
    return (
      <div className={classNames('list-selector-element', { active, hover })} onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })}>
        <div className="list-selector-label" onClick={e => onSelect(value, order, !selected, e)}>
          <TamrIcon
            iconName={selected ? 'tamr-icon-checkbox-checked' : 'tamr-icon-checkbox-unchecked'}
            className={classNames('list-selector-checkbox', { checked: selected })}
            size={15}
          />
          {textLabel}
        </div>
      </div>
    );
  }
}

export default ListSelectorElement;
