import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import TamrIcon from '../../components/TamrIcon';
import Term from '../../components/Term';
import CheckboxFilter from './CheckboxFilter';
import styles from './HighImpactFilterSection.module.scss';

const HighImpactFilterSection = _.compose(
  connect(({ recordPairs: { highImpact } }) => {
    return { highImpact };
  }, {
    onSetHighImpactFilter: (highImpact) => ({ type: 'RecordPairs.setHighImpactFilter', highImpact }),
  }),
)(class HighImpactFilterSection extends React.Component {
  static propTypes = {
    highImpact: PropTypes.bool.isRequired,
    onSetHighImpactFilter: PropTypes.func.isRequired,
  };

  render() {
    const { highImpact, onSetHighImpactFilter } = this.props;
    return (
      <CheckboxFilter
        onChange={onSetHighImpactFilter}
        title={<span>High-impact <Term>pairs</Term> <TamrIcon iconName="tamr-icon-lightning-bolt" className={styles.highImpactIcon} size={16} /></span>}
        value={highImpact}
      />
    );
  }
});

export default HighImpactFilterSection;
