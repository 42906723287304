// com.tamr.dedup.models.ResponseFilter.Type

const ResponseFilterType = {
  PRESENT: 'PRESENT',
  MISSING: 'MISSING',
  MATCH: 'MATCH',
  NON_MATCH: 'NON_MATCH',
  SKIP: 'SKIP',
} as const;
export type ResponseFilterTypeE = typeof ResponseFilterType[keyof typeof ResponseFilterType];

export default ResponseFilterType;
