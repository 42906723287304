import { is } from 'immutable';
import localforage from 'localforage';
import { connect } from 'react-redux';
import { createSink, shouldUpdate } from 'recompose';
import _ from 'underscore';

import { selectActiveProjectInfo } from '../utils/Selectors';
import StoredTransformsState, { storedTransformsStateKey } from './models/StoredTransformsState';
import { clearStore, updateList } from './TransformsApi';

/**
 * Component to control the initial fetch of the list of transforms
 */
// TODO switch over to using createLoader(). This may potentially require extending createLoader(), as this has complex logic.
const TransformsLoader = _.compose(
  connect((state) => {
    const { transforms, records: { useLocalStorage } } = state;
    const projectInfo = selectActiveProjectInfo(state);
    const { projectId } = projectInfo;
    const projectTimestamp = projectInfo?.projectWithStatus?.project?.created?.timestamp;
    const canFetch = !!(projectInfo && projectInfo.unifiedDatasetDoc);
    const datasetId = projectInfo && projectInfo.unifiedDatasetId;
    const { loading, loadedDatasetId } = transforms;
    // the available functions data is static and doesn't need to reload between projects
    return { canFetch, datasetId, loading, loadedDatasetId, projectTimestamp, projectId, useLocalStorage };
  }, {
    onUpdateList: updateList,
    onClearStore: clearStore,
    setLoadingToTrue: () => ({ type: 'Transforms.loadFromLocalStorage' }),
    setTransformsState: (storedState) => ({ type: 'Transforms.loadFromStorageCompleted', storedState }),
  }),
  shouldUpdate((props, nextProps) => !_.every(props, (v, k) => is(v, nextProps[k]))),
)(createSink(({ canFetch, datasetId, projectTimestamp, projectId, loading, loadedDatasetId, onUpdateList, onClearStore, useLocalStorage, setTransformsState, setLoadingToTrue }) => {
  const determineServerFetch = () => {
    const shouldFetch = datasetId !== loadedDatasetId;
    if (canFetch && shouldFetch && !loading) {
      onUpdateList();
    } else if (!canFetch && shouldFetch) {
      onClearStore(); // don't show stale data
    }
  };

  if (useLocalStorage && datasetId && !loadedDatasetId) {
    setLoadingToTrue();
    localforage.getItem(storedTransformsStateKey(projectTimestamp, projectId)).then((val) => {
      if (val) {
        setTransformsState(StoredTransformsState.fromJSON(JSON.parse(val)));
      } else {
        determineServerFetch();
      }
    });
  } else if (datasetId) {
    determineServerFetch();
  }
}));


export default TransformsLoader;
