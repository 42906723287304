import { ArgTypes } from '../utils/ArgValidation';
import { $TSFixMe } from '../utils/typescript';
import DataVersion from './DataVersion';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

class MaterializationStatus extends getModelHelpers({
  lastRun: { type: ArgTypes.nullable(ArgTypes.instanceOf(DataVersion)) },
  latestData: { type: ArgTypes.nullable(ArgTypes.instanceOf(DataVersion)) },
  current: { type: ArgTypes.bool },
}, 'MaterializationStatus')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class MaterializationStatusRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
  static fromJSON({ lastRun, latestData, current }: $TSFixMe) {
    return new MaterializationStatus({
      lastRun: lastRun ? new DataVersion(lastRun) : null,
      latestData: latestData ? new DataVersion(latestData) : null,
      current,
    });
  }
}

export default MaterializationStatus;
