import { List } from 'immutable';
import { createSelector } from 'reselect';

import * as ProjectType from '../constants/ProjectTypes';
import ProjectInfo from '../models/ProjectInfo';
import { AppSelector, selectAllProjectInfos } from '../utils/Selectors';
import { textMatch } from '../utils/Values';

export const selectFilteredProjectInfos: AppSelector<List<ProjectInfo>> = createSelector(
  selectAllProjectInfos,
  s => s.projects.filterToCategorization,
  s => s.projects.filterToMastering,
  s => s.projects.filterToSchemaMapping,
  s => s.projects.filterToGoldenRecords,
  s => s.projects.filterToEnrichment,
  s => s.projects.searchQuery,
  (projectInfos, filterToCategorization, filterToMastering, filterToSchemaMapping, filterToGoldenRecords, filterToEnrichment, searchQuery) =>
    projectInfos.filter(({ projectType, project: { displayName } }) => {
      return (!(filterToCategorization || filterToMastering || filterToSchemaMapping || filterToGoldenRecords || filterToEnrichment) ||
        (filterToCategorization && projectType === ProjectType.CLASSIFICATION) ||
        (filterToGoldenRecords && projectType === ProjectType.GOLDEN_RECORDS) ||
        (filterToMastering && projectType === ProjectType.SUPPLIER_MASTERING) ||
        (filterToSchemaMapping && projectType === ProjectType.SCHEMA_MAPPING_RECOMMENDATIONS) ||
        (filterToEnrichment && projectType === ProjectType.ENRICHMENT)) &&
        (textMatch(displayName, searchQuery));
    }));
