// com.tamr.dedup.models.AllResponsesFilterType

const AllResponsesFilterType = {
  MATCH: 'MATCH',
  NON_MATCH: 'NON_MATCH',
  SKIP: 'SKIP',
} as const;
export type AllResponsesFilterTypeE = typeof AllResponsesFilterType[keyof typeof AllResponsesFilterType];

export default AllResponsesFilterType;
