import _ from 'underscore';

const SortState = {
  UNSORTED: 'unsorted',
  SORTED_ASCENDING: 'asc',
  SORTED_DESCENDING: 'desc',
} as const;
export type SortStateValueType = typeof SortState[keyof typeof SortState];
export const SortStateValues: SortStateValueType[] = _.values(SortState);

export default SortState;
