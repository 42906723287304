import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Selector from '../components/Selector';
import { selectStagedUnifiedDatasetColumns } from '../records/RecordsColumns';

const ColumnSelector = _.compose(
  connect((state) => {
    return { options: selectStagedUnifiedDatasetColumns(state).map(col => col.name) };
  }),
)(({ options, value, multi, className, ...rest }) => {
  // ensure options include value(s)
  (_.isArray(value) && multi ? value : [value]).forEach(v => {
    if (!options.includes(v)) {
      options = options.push(v);
    }
  });
  return (
    <Selector
      className={classNames('column-selector', className)}
      {...rest}
      {...{ value, multi }}
      allowCreate={false}
      options={options.map(option => ({ value: option, label: option })).toArray()}
    />
  );
});
ColumnSelector.propTypes = {
  // if value is an array and selector is multi, all values will be present as options
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default ColumnSelector;
