import classnames from 'classnames';
import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import TamrIcon from '../components/TamrIcon';
import { AppState } from '../stores/MainStore';
import { GRInput } from './EditableGoldenRecordsRule';
import { selectRuleFromModuleFromLastUpdate } from './GoldenRecordsSelectors';
import style from './GoldenRecordsSidebar.module.scss';
import { selectAggregationContentEnvelope } from './GoldenRecordsStore';
import ReadOnlyFilterElement, { Row } from './ReadOnlyFilterElement';
import RULES_OPTIONS from './RulesOptions';
import SidebarRuleSummary from './SidebarRuleSummary';

type ReadOnlyGoldenRecordsRuleOwnProps = {
  name: string
}

const ReadOnlyGoldenRecordsRule = connect((state: AppState, { name }: ReadOnlyGoldenRecordsRuleOwnProps) => {
  const { goldenRecords, goldenRecords: { expandedRuleNames } } = state;
  const rule = selectRuleFromModuleFromLastUpdate(state)(name);
  const contentEnvelope = (rule && 'expression' in rule && rule.expression) ? selectAggregationContentEnvelope(goldenRecords, name) : null;

  const isExpanded = expandedRuleNames.has(name);
  return { isExpanded, rule, contentEnvelope };
})(({ isExpanded, name, rule, contentEnvelope }) => {
  if (!rule) { return null; }
  const ruleOption = RULES_OPTIONS.find(option => option.value === rule.type);

  return (
    <div className={classnames(style.rule)}>
      <SidebarRuleSummary
        readonly
        name={name}
        rule={rule}
        validationErrors={List()}
      />
      {isExpanded && (<div className={style.ruleDetails}>
        <div className={style.ruleWord}>Rule</div>
        <Row left={<span>Rule:</span>} right={ruleOption?.label} />
        {('inputAttributeName' in rule && !!rule.inputAttributeName) && <Row left={<span>Input attribute:</span>} right={rule.inputAttributeName} /> }
        {('expression' in rule && !!rule.expression) && (
          <div className={style.readOnlyRuleExpression}>
            <GRInput
              name={`${name}`}
              contentEnvelope={contentEnvelope}
              contents={rule.expression || ''}
              lintingErrors={List()} />
          </div>
        )}
        <div className={style.ruleWord}>Conditions
          <TamrIcon size={14} iconName="info-outline" className={classnames('ml-4', style.verticalMiddle)} />
        </div>

        {rule.filters.length === 0 ? <div className={style.readOnlyRow}>No Conditions</div> : rule.filters.map((filter, index) => <ReadOnlyFilterElement filter={filter} index={index} key={index} ruleName={name} />)}
      </div>)}

    </div>
  );
});

export default ReadOnlyGoldenRecordsRule;
