import { ArgTypes } from '../utils/ArgValidation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';

class AttributeId extends getModelHelpers({
  name: { type: ArgTypes.string },
  datasetName: { type: ArgTypes.string },
}, 'AttributeId')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class AttributeIdRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  static fromJSON(obj: any) {
    return new AttributeId({
      name: obj.name,
      datasetName: obj.datasetName,
    });
  }
}

export function attributeId({ name, datasetName } : { name: string, datasetName: string }): AttributeId {
  return new AttributeId({ name, datasetName });
}

export default AttributeId;
