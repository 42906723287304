import { List, Map, Record } from 'immutable';

import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import Model from '../models/Model';
import ProfilingInfo from '../models/ProfilingInfo';
import { FETCH_COMPLETED } from '../projects/ProjectsActionTypes';
import { ArgTypes } from '../utils/ArgValidation';

// NB this means that usages of this store for >1000 input datasets in a project
//    WILL NOT WORK and SHOULD BE CONSIDERED A BUG
//    (That is to say, any use of this store should be considered a BUG,
//     since we should be targeting >1000 dataset scalability everywhere)
//    you must use a paginated approach in that scenario.
export const LIMIT = 1000;

/**
 * Stores all source datasets (input datasets to the schema mapping recipe) for only the active project
 * Note that this store will attempt to store ALL source datasets, which does not scale.
 * Because datasets need to be paginated in SCHEMA_MAPPING_RECOMMENDATIONS projects, this shouldn't be used there.
 */
export const initialState = new (Model({
  datasets: { type: ArgTypes.Immutable.list.of(Document.argTypeWithNestedClass(Dataset)), defaultValue: List() },
  profiling: { type: ArgTypes.Immutable.map.of(ArgTypes.instanceOf(ProfilingInfo), ArgTypes.string), defaultValue: new Map() },
  loadedFilterInfo: { type: ArgTypes.any },
  fetchSequence: { type: ArgTypes.number, defaultValue: 1 },
  loading: { type: ArgTypes.bool, defaultValue: false },
}))();

const FilterInfo = Record({
  fetchSequence: 1,
  projectId: null,
});

export const getFilterInfo = ({ fetchSequence }, projectId) => new FilterInfo({ fetchSequence, projectId });

const reloadSourceDatasets = (state) => state.update('fetchSequence', x => x + 1);

export const reducers = {
  'AllSourceDatasets.fetchAll': (state) => {
    return state.set('loading', true);
  },
  'AllSourceDatasets.fetchAllCompleted': (state, { datasets, profiling, loadedFilterInfo }) => {
    return state.merge({ datasets, profiling, loadedFilterInfo, loading: false });
  },

  [FETCH_COMPLETED]: reloadSourceDatasets,
  'Tags.linkTagCompleted': reloadSourceDatasets,
  'Tags.removeLinkCompleted': reloadSourceDatasets,
  'Datasets.profileDatasetCompleted': reloadSourceDatasets,
};
