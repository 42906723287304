import { List } from 'immutable';

import { BrowserFile, CoreConnectJob } from '../api/CoreConnectService';
import Dataset from '../models/Dataset';
import { CoreConnectFileTypeE } from './CoreConnectFileType';

export const SET_SOURCE_PROJECT_NAME = 'CoreConnectService.setSourceProjectName';
export const SET_SOURCE_BUCKET_NAME = 'CoreConnectService.setSourceBucketName';
export const SET_SOURCE_PATH_PREFIX = 'CoreConnectService.setSourcePathPrefix';
export const SET_SOURCE_FILE_TYPE = 'CoreConnectService.setSourceFileType';
export const SET_SINK_PROJECT_NAME = 'CoreConnectService.setSinkProjectName';
export const SET_SINK_BUCKET_NAME = 'CoreConnectService.setSinkBucketName';
export const SET_SINK_PATH_PREFIX = 'CoreConnectService.setSinkPathPrefix';
export const SET_SINK_FILE_TYPE = 'CoreConnectService.setSinkFileType';
export const FETCH_DEFAULT_CLOUD_PROVIDER = 'CoreConnectService.fetchDefaultCloudProvider';
export const FETCH_DEFAULT_CLOUD_PROVIDER_COMPLETED = 'CoreConnectService.fetchDefaultCloudProviderCompleted';
export const FETCH_DEFAULT_CLOUD_PROVIDER_FAILED = 'CoreConnectService.fetchDefaultCloudProviderFailed';
export const FETCH_FILES_WITH_CONNECT = 'CoreConnectService.fetchFilesWithCoreConnect';
export const FETCH_FILES_WITH_CONNECT_COMPLETED = 'CoreConnectService.fetchFilesWithCoreConnectCompleted';
export const FETCH_FILES_WITH_CONNECT_FAILED = 'CoreConnectService.fetchFilesWithCoreConnectFailed';
export const TRANSFER_CLOUD_TO_TAMR = 'CoreConnectService.transferCloudToTamr';
export const TRANSFER_CLOUD_TO_TAMR_COMPLETED = 'CoreConnectService.transferCloudToTamrCompleted';
export const TRANSFER_CLOUD_TO_TAMR_FAILED = 'CoreConnectService.transferCloudToTamrFailed';
export const FETCH_SCHEMA_FROM_CLOUD = 'CoreConnectService.fetchSchemaFromCloud';
export const FETCH_SCHEMA_FROM_CLOUD_COMPLETED = 'CoreConnectService.fetchSchemaFromCloudCompleted';
export const FETCH_SCHEMA_FROM_CLOUD_FAILED = 'CoreConnectService.fetchSchemaFromCloudFailed';
export const SET_PRIMARY_KEYS = 'CoreConnectService.setPrimaryKeys';
export const SET_ADVANCED_OPTIONS_OPEN = 'CoreConnectService.setAdvancedOptionsOpen';
export const SET_SINK_DESCRIPTION = 'CoreConnectService.setSinkDescription';
export const SET_NUM_THREADS = 'CoreConnectService.setNumThreads';
export const SET_PROFILE_DATASET = 'CoreConnectService.setProfileDataset';
export const SET_APPEND_TO_DATASET = 'CoreConnectService.appendToDataset';
export const RESET_CONNECT_STORE = 'CoreConnectService.resetStore';
export const OPEN_EXPORT_DIALOG = 'CoreConnectService.openExportDialog';
export const OPEN_EXPORT_JDBC_DIALOG = 'CoreConnectService.openJDBCExportDialog';
export const CLOSE_EXPORT_DIALOG = 'CoreConnectService.closeExportDialog';
export const SET_TAMR_SOURCE_DATASET = 'CoreConnectService.setTamrSourceDataset';
export const TRANSFER_TAMR_TO_CLOUD = 'CoreConnectService.transferTamrToCloud';
export const TRANSFER_TAMR_TO_CLOUD_COMPLETED = 'CoreConnectService.transferTamrToCloudCompleted';
export const TRANSFER_TAMR_TO_CLOUD_FAILED = 'CoreConnectService.transferTamrToCloudFailed';

interface SetSourceProjectName {
  type: typeof SET_SOURCE_PROJECT_NAME,
  sourceProjectName: string,
}

interface SetSourceBucketName {
  type: typeof SET_SOURCE_BUCKET_NAME,
  sourceBucketName: string,
}

interface SetSourcePathPrefix {
  type: typeof SET_SOURCE_PATH_PREFIX,
  sourcePathPrefix: string,
}

interface SetSourceFileType {
  type: typeof SET_SOURCE_FILE_TYPE,
  sourceFileType: CoreConnectFileTypeE,
}

interface SetSinkProjectName {
  type: typeof SET_SINK_PROJECT_NAME,
  sinkProjectName: string,
}

interface SetSinkBucketName {
  type: typeof SET_SINK_BUCKET_NAME,
  sinkBucketName: string,
}

interface SetSinkPathPrefix {
  type: typeof SET_SINK_PATH_PREFIX,
  sinkPathPrefix: string,
}

interface SetSinkFileType {
  type: typeof SET_SINK_FILE_TYPE,
  sinkFileType: CoreConnectFileTypeE,
}

interface FetchDefaultCloudProvider {
  type: typeof FETCH_DEFAULT_CLOUD_PROVIDER,
}

interface FetchDefaultCloudProviderCompleted {
  type: typeof FETCH_DEFAULT_CLOUD_PROVIDER_COMPLETED,
  coreconnectDefaultProvider: string,
  coreconnectEnabled: boolean
}

interface FetchDefaultCloudProviderFailed {
  type: typeof FETCH_DEFAULT_CLOUD_PROVIDER_FAILED,
  errorMessage: string,
}

interface FetchFilesWithCoreConnect {
  type: typeof FETCH_FILES_WITH_CONNECT,
}

interface FetchFilesWithCoreConnectCompleted {
  type: typeof FETCH_FILES_WITH_CONNECT_COMPLETED,
  sourceFiles: List<BrowserFile>,
}

interface FetchFilesWithCoreConnectFailed {
  type: typeof FETCH_FILES_WITH_CONNECT_FAILED,
  errorMessage: string,
}

interface TransferCloudToTamr {
  type: typeof TRANSFER_CLOUD_TO_TAMR,
}

interface TransferCloudToTamrCompleted {
  type: typeof TRANSFER_CLOUD_TO_TAMR_COMPLETED,
  job: CoreConnectJob,
}

interface TransferCloudToTamrFailed {
  type: typeof TRANSFER_CLOUD_TO_TAMR_FAILED,
  errorMessage: string,
}

interface FetchSchemaFromCloud {
  type: typeof FETCH_SCHEMA_FROM_CLOUD,
}

interface FetchSchemaFromCloudCompleted {
  type: typeof FETCH_SCHEMA_FROM_CLOUD_COMPLETED,
  schema: List<string>,
}

interface FetchSchemaFromCloudFailed {
  type: typeof FETCH_SCHEMA_FROM_CLOUD_FAILED,
  errorMessage: string,
}

interface SetPrimaryKeys {
  type: typeof SET_PRIMARY_KEYS,
  primaryKeys: List<string>,
}

interface SetAdvancedOptions {
  type: typeof SET_ADVANCED_OPTIONS_OPEN,
}

interface SetSinkDescription {
  type: typeof SET_SINK_DESCRIPTION,
  sinkDescription: string,
}

interface SetNumThreads {
  type: typeof SET_NUM_THREADS,
  numThreads: number,
}

interface SetProfileDataset {
  type: typeof SET_PROFILE_DATASET,
  profileDataset: boolean,
}

interface SetAppendToDataset {
  type: typeof SET_APPEND_TO_DATASET,
  appendToDataset: boolean,
}

interface ResetCoreConnectStore {
  type: typeof RESET_CONNECT_STORE,
}

interface OpenExportDialog {
  type: typeof OPEN_EXPORT_DIALOG,
}

interface OpenExportJDBCDialog {
  type: typeof OPEN_EXPORT_JDBC_DIALOG,
}


interface CloseExportDialog {
  type: typeof CLOSE_EXPORT_DIALOG,
}

interface SetTamrSourceDataset {
  type: typeof SET_TAMR_SOURCE_DATASET,
  tamrSourceDataset: Dataset,
}

interface TransferTamrToCloud {
  type: typeof TRANSFER_TAMR_TO_CLOUD,
}

interface TransferTamrToCloudCompleted {
  type: typeof TRANSFER_TAMR_TO_CLOUD_COMPLETED,
  job: CoreConnectJob,
}

interface TransferTamrToCloudFailed {
  type: typeof TRANSFER_TAMR_TO_CLOUD_FAILED,
  errorMessage: string,
}

export type CoreConnectServiceActionTypes
  = SetSourceProjectName
  | SetSourceBucketName
  | SetSourcePathPrefix
  | SetSourceFileType
  | SetSinkProjectName
  | SetSinkBucketName
  | SetSinkPathPrefix
  | SetSinkFileType
  | FetchDefaultCloudProvider
  | FetchDefaultCloudProviderCompleted
  | FetchDefaultCloudProviderFailed
  | FetchFilesWithCoreConnect
  | FetchFilesWithCoreConnectCompleted
  | FetchFilesWithCoreConnectFailed
  | TransferCloudToTamr
  | TransferCloudToTamrCompleted
  | TransferCloudToTamrFailed
  | FetchSchemaFromCloud
  | FetchSchemaFromCloudCompleted
  | FetchSchemaFromCloudFailed
  | SetPrimaryKeys
  | SetAdvancedOptions
  | SetSinkDescription
  | SetNumThreads
  | SetProfileDataset
  | SetAppendToDataset
  | ResetCoreConnectStore
  | OpenExportDialog
  | OpenExportJDBCDialog
  | CloseExportDialog
  | SetTamrSourceDataset
  | TransferTamrToCloud
  | TransferTamrToCloudCompleted
  | TransferTamrToCloudFailed
