import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import _ from 'underscore';
import urlRegex from 'url-regex';
import wellknown from 'wellknown';

import * as geotamr from '../geospatial/GeoTamr';
import TaggedUnion, { InferConstructedKind } from '../models/TaggedUnion';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import Optional from '../utils/Optional';
import { pluralize, stringify } from '../utils/Strings';
import { $TSFixMe, JsonContent } from '../utils/typescript';
import NullValue from './NullValue';
import TamrIcon from './TamrIcon';


// ------------
// Record Value
// ------------

/**
 * TODO(pcattori): Design an incremental adoption plan for `EsRecord#getValue`
 * and make `getValue` return a `RecordValue` instead of a
 * `{ value, raw, originalData}`-shaped object
 *
 * Current usage of `EsRecord#getValue`:
 * - InputDatasetColumn.jsx
 * - SimpleTxnTable.jsx : MultiValue
 * - DataSidebar.jsx :
 * - DataColumn.jsx : MultiValue
 * - CategorizeDialog.jsx : Raw vs HighlightedSearchResult
 */

const StringOrNumberOrBooleanType = ArgTypes.oneOf(ArgTypes.string, ArgTypes.number, ArgTypes.bool);
const NullableStringOrNumberOrBooleanType = ArgTypes.nullable(StringOrNumberOrBooleanType);

const recordValueKindDefinitions = {
  HighlightedSearchResult: {
    data: NullableStringOrNumberOrBooleanType,
    highlightedData: ArgTypes.string,
  },
  GeoTamr: {
    data: NullableStringOrNumberOrBooleanType,
    geoTamr: geotamr.GeoTamr.argType,
  },
  Raw: {
    data: NullableStringOrNumberOrBooleanType,
  },
  Null: {},
};
export const RecordValue = TaggedUnion(recordValueKindDefinitions, 'RecordValue');
export type RecordValueType = InferConstructedKind<typeof recordValueKindDefinitions>

// recursively removes properties with null or undefined values from record-typed data
// this function was added to match ES-backed object-typed data output display semantics
export function recursivelyRemoveNulls(value: $TSFixMe): $TSFixMe {
  if (value && (_.isArray(value))) {
    return value.map(elem => recursivelyRemoveNulls(elem));
  }
  if (value && typeof (value) === 'object') {
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        if (value[key] === null || value[key] === undefined) {
          delete value[key];
        } else {
          // going one step down in the object tree!!
          value[key] = recursivelyRemoveNulls(value[key]);
        }
      }
    }
  }
  return value;
}

export function tryParseDataAsGeo(data: unknown): { [key: string]: $TSFixMe } | undefined {
  // wellknown library can only parse string. Passing in any other type will throw a runtime
  // exception
  return _.isString(data) && (wellknown.parse(data) as {[key: string]: $TSFixMe}) || undefined;
}

/**
 * Translate from { value, raw, originalData }-shaped objects
 * to RecordValue.
 *
 * Note: value and originalData are nullable and can be of any type, from basic types like number
 * or string to complex types like array or record.
 *
 */
function fromRaw({ value, raw, originalData }: {
  value: JsonContent
  raw: boolean | undefined
  originalData: $TSFixMe
}): RecordValueType {
  checkArg({ raw }, ArgTypes.orUndefined(ArgTypes.bool));
  const newValue = recursivelyRemoveNulls(cloneDeep(value));
  if (newValue === undefined || newValue === null) {
    return RecordValue.Null({});
  }
  if (raw) {
    return RecordValue.HighlightedSearchResult({
      data: originalData,
      highlightedData: newValue,
    });
  }
  return Optional.of(tryParseDataAsGeo(newValue))
    .map(geoJson => RecordValue.GeoTamr({ data: newValue, geoTamr: geotamr.fromGeoJSON(geoJson) }))
    .orElse(RecordValue.Raw({ data: newValue }));
}

function toString(recordValue: RecordValueType) {
  checkArg({ recordValue }, RecordValue.argType);
  return recordValue.case({
    HighlightedSearchResult: ({ data }) => data,
    GeoTamr: ({ data }) => data,
    Raw: ({ data }) => data,
    Null: () => null,
  });
}

/**
 * Shim for converting { value, raw, originalData }-shaped object(s) to array of
 * RecordValue.
 *
 * TODO(pcattori): after `EsRecord#getValue` is changed to return a
 *`RecordValue`, remove this function.
 */
export function recordValueShim({ value, raw, originalData }: {
  value: JsonContent
  raw?: boolean | undefined
  originalData?: JsonContent
}): RecordValueType[] {
  checkArg({ value }, ArgTypes.oneOf(ArgTypes.array.of(ArgTypes.orNull(StringOrNumberOrBooleanType)), NullableStringOrNumberOrBooleanType));
  checkArg({ raw }, ArgTypes.orUndefined(ArgTypes.bool));
  checkArg({ originalData }, ArgTypes.oneOf(ArgTypes.undefined, ArgTypes.array.of(ArgTypes.orNull(StringOrNumberOrBooleanType)), NullableStringOrNumberOrBooleanType));
  const values = _.isArray(value) ? value : [value];
  const originalDatas = _.isArray(originalData) ? originalData : [originalData];
  return values.map((v, i) => fromRaw({ value: v, raw, originalData: originalDatas[i] }));
}

// -----
// Value
// -----

const urlre = urlRegex({ exact: true });

export const GeoValueSummary: React.FC<{ geoTamr: geotamr.GeoTamrType }> = ({ geoTamr }) => {
  return <span>{geotamr.size(geoTamr)} {pluralize(geotamr.size(geoTamr), geotamr.displayName(geoTamr), geotamr.displayName(geoTamr) + 's')}</span>;
};

/**
 * Default renderer for record values.
 * Use `MultiValue` if you have an array-like value.
 *
 * value: Record value to render.
 * props: Special props used by React (e.g. `key`, `children`, etc...)
 */
export const Value: React.FunctionComponent<{
  value: RecordValueType
  nestedKey?: string | number
}> = ({ value, nestedKey }) => {
  checkArg({ value }, RecordValue.argType);
  return value.case({
    HighlightedSearchResult: ({ highlightedData }) =>
      <span key={nestedKey} dangerouslySetInnerHTML={{ __html: highlightedData }} />, // eslint-disable-line react/no-danger
    GeoTamr: ({ geoTamr }) =>
      <GeoValueSummary key={nestedKey} {...{ geoTamr }} />,
    Raw: ({ data }) => {
      if (_.isString(data) && urlre.test(data)) {
        return (
          <a key={nestedKey} href={data} rel="noopener noreferrer" target="_blank">
            <TamrIcon iconName="open-in-new" size={14} /> {data}
          </a>
        );
      }
      if (_.isBoolean(data)) {
        data = data.toString();
        return <span title={data} key={nestedKey} style={{ fontStyle: 'italic' }}>{data}</span>;
      }
      const stringData = stringify(data);
      return <span title={stringData} key={nestedKey}>{stringData}</span>;
    },
    Null: () =>
      <NullValue />,
  });
};

// ----------
// MultiValue
// ----------

/**
 * values: Singular record value or array of record values to be rendered.
 * title: Tooltip title. Defaults to a ', '-separated list of each
 *   value represented as its original string.
 * renderValue ((value: RecordValue, index: number) => React node): Function for
 *   rendering each record value. Defaults to `(v, i) => <Value key={i} value={v} />`.
 */
const MultiValue: React.FunctionComponent<{
  values: RecordValueType | RecordValueType[]
  title?: string
  renderValue?: Function
}> = ({ values, title: customTitle, renderValue }) => {
  checkArg({ values }, ArgTypes.oneOf(ArgTypes.array.of(RecordValue.argType), RecordValue.argType));
  checkArg({ customTitle }, ArgTypes.orUndefined(ArgTypes.string));
  checkArg({ renderValue }, ArgTypes.orUndefined(ArgTypes.func));
  let vs = _.isArray(values) ? values : [values];
  vs = _.uniq(vs, false, toString);
  const title = customTitle === undefined
    ? vs.map(toString).join(', ')
    : customTitle;
  return (
    <span className="multi-value" title={title}>
      {vs.map((v, i) => (
        renderValue === undefined
          ? <Value nestedKey={i} value={v} />
          : renderValue(v, i)
      ))}
    </span>
  );
};

export default MultiValue;
