import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { pure } from 'recompose';
import _ from 'underscore';

import Model from '../models/Model';
import { ArgTypes } from '../utils/ArgValidation';
import { shortFormat } from '../utils/Numbers';
import { pluralize } from '../utils/Strings';
import DashboardCard from './DashboardCard';

export const Bar = Model({
  value: { type: ArgTypes.number },
  label: { type: ArgTypes.string },
});

const MAX_BARS = 5;

const TopNCard = _.compose(
  pure,
)(class TopNCard extends React.Component {
  static propTypes = {
    bars: ImmutablePropTypes.listOf(PropTypes.instanceOf(Bar)),
    metric: PropTypes.node.isRequired,
    nounPlural: PropTypes.node.isRequired,
    nounSingular: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    superlative: PropTypes.string,
  };

  static defaultProps = { superlative: 'Top' };

  getBars = () => {
    return this.props.bars
      .filter((bar, i) => i < MAX_BARS)
      .filter((bar) => bar.value > 0);
  };

  isExhaustive = () => {
    return this.getBars().size < MAX_BARS;
  };

  renderTitle = () => {
    const { nounSingular, nounPlural, metric, superlative } = this.props;
    const superlativeMaybe = this.isExhaustive() ? undefined : `${superlative} `;
    const bars = this.getBars();
    const count = bars.size;
    const noun = pluralize(count, nounSingular, nounPlural);
    return (
      <div className="title">
        {superlativeMaybe}{noun} by <span className="bold">{metric}</span>
      </div>
    );
  };

  renderBars = () => {
    const bars = this.getBars();
    const largestBar = bars.min(bar => bar.value); // why is this min? they seem to opposite...
    if (!largestBar) {
      return <div className="bars" />;
    }
    const largestValue = largestBar.value;
    const barElements = bars.map((bar, i) => {
      const key = `bar-${i}`;
      const className = classNames('bar', key);
      const widthProportion = bar.value / largestValue;
      const label = bar.label ? bar.label.toUpperCase() : null;
      const value = shortFormat(bar.value);
      return (
        <div key={key} className={className}>
          <div className="top-text">
            <div className="bar-label">{label}</div>
            <div className="bar-value">{value}</div>
          </div>
          <div className="bar-value-background" style={{ width: `${widthProportion * 100}%` }} />
        </div>
      );
    });
    return (
      <div className="bars">
        {barElements}
      </div>
    );
  };

  render() {
    if (this.getBars().size === 0) {
      return <div />;
    }
    return (
      <DashboardCard className="dashboard-top-n-card" onClick={this.props.onClick}>
        {this.renderTitle()}
        {this.renderBars()}
      </DashboardCard>
    );
  }
});

export default TopNCard;
