import { Record } from 'immutable';
import _ from 'underscore';

import { initialState as accessControlInitialState, reducers as accessControlReducers } from '../accessControl/AccessControlStore';
import { initialState as addDatasetInitialState, reducers as addDatasetReducers } from '../addDataset/AddDatasetStore';
import { initialState as externalStorageInitialState, reducers as externalStorageReducers } from '../addDataset/ExternalStorageContentStore';
import { initialState as authInitialState, reducers as authReducers } from '../auth/AuthStore';
// @ts-expect-error
import { initialState as categoryDetailInitialState, reducers as categoryDetailReducers } from '../categoryDetail/CategoryDetailStore';
// @ts-expect-error
import { initialState as appcuesInitialState, reducers as appcuesReducers } from '../chrome/AppcuesStore';
// @ts-expect-error
import { initialState as chromeInitialState, reducers as chromeReducers } from '../chrome/ChromeStore';
import { initialState as configInitialState, reducers as configReducers } from '../config/ConfigStore';
import { initialState as coreConnectServiceInitialState, reducers as coreConnectServiceReducers } from '../coreConnectService/CoreConnectServiceStore';
// @ts-expect-error
import { initialState as categorizationDashboardInitialState, reducers as categorizationDashboardReducers } from '../dashboard/CategorizationDashboardStore';
// @ts-expect-error
import { initialState as dedupDashboardInitialState, reducers as dedupDashboardReducers } from '../dashboard/dedup/DedupDashboardStore';
// @ts-expect-error
import { initialState as schemaMappingRecsInitialState, reducers as schemaMappingRecsReducers } from '../dashboard/schema-mapping-recommendations/SchemaMappingRecsStore';
import { initialState as datasetFilterInitialState, reducers as datasetFilterReducers } from '../datasetFilter/DatasetFilterStore';
// @ts-expect-error
import { initialState as allSourceDatasetsInitialState, reducers as allSourceDatasetsReducers } from '../datasets/AllSourceDatasetsStore';
// @ts-expect-error
import { initialState as datasetCatalogInitialState, reducers as datasetCatalogReducers } from '../datasets/DatasetCatalogStore';
// @ts-expect-error
import { initialState as datasetMetadataInitialState, reducers as datasetMetadataReducers } from '../datasets/DatasetMetadataStore';
import { initialState as fileUploadInitialState, reducers as fileUploadReducers } from '../datasets/FileUploadStore';
// @ts-expect-error
import { initialState as manageProjectDatasetsInitialState, reducers as manageProjectDatasetsReducers } from '../datasets/ManageProjectDatasetsStore';
import { initialState as projectDatasetCatalogInitialState, reducers as projectDatasetCatalogReducers } from '../datasets/ProjectDatasetCatalogStore';
import { initialState as sharedDatasetCatalogInitialState, reducers as sharedDatasetCatalogReducers } from '../datasets/SharedDatasetCatalogStore';
// @ts-expect-error
import { initialState as unifiedDatasetsInitialState, reducers as unifiedDatasetsReducers } from '../datasets/UnifiedDatasetsStore';
// @ts-expect-error
import { initialState as dnfBuilderInitialState, reducers as dnfBuilderReducers } from '../dnfBuilder/DnfBuilderStore';
import { initialState as enrichmentInitialState, reducers as enrichmentReducers } from '../enrichment/EnrichmentStore';
// @ts-expect-error
import { initialState as errorDialogInitialState, reducers as errorDialogReducers } from '../errorDialog/ErrorDialogStore';
import { initialState as goldenRecordsRulesInitialState, reducers as goldenRecordsRulesReducers } from '../goldenRecords/GoldenRecordsRulesStore';
import { initialState as goldenRecordsInitialState, reducers as goldenRecordsReducers } from '../goldenRecords/GoldenRecordsStore';
// @ts-expect-error
import { initialState as groupByInitialState, reducers as groupByReducers } from '../groupby/GroupByStore';
// @ts-expect-error
import { initialState as jobsInitialState, reducers as jobsReducers } from '../job/JobStore';
import { initialState as notificationInitialState, reducers as notificationReducers } from '../notification/NotificationStore';
// @ts-expect-error
import { initialState as confusionMatrixInitialState, reducers as confusionMatrixReducers } from '../pairs/ConfusionMatrixStore';
import { initialState as recordPairsInitialState, reducers as recordPairsReducers } from '../pairs/RecordPairsStore';
import { initialState as pregroupInitialState, reducers as pregroupReducers } from '../pregroup/PregroupStore';
import { initialState as projectsInitialState, reducers as projectsReducers } from '../projects/ProjectsStore';
// @ts-expect-error
import { initialState as projectStatsInitialState, reducers as projectStatsReducers } from '../projects/ProjectStatsStore';
// @ts-expect-error
import { initialState as recordsInitialState, reducers as recordsReducers } from '../records/RecordsStore';
import { initialState as schemaMappingInitialState, reducers as schemaMappingReducers } from '../schema-mapping/SchemaMappingStore';
import { initialState as ssoInitialState, reducers as ssoReducers } from '../sso/SSOStore';
import { initialState as clusterFeedbackInitialState, reducers as clusterFeedbackReducers } from '../suppliers/ClusterFeedbackStore';
import { initialState as publishClustersDialogState, reducers as publishClustersDialogReducers } from '../suppliers/PublishClusterDialogStore';
import * as clusters from '../suppliers/reducer';
import { initialState as suppliersInitialState, reducers as suppliersReducers } from '../suppliers/SuppliersStore';
// @ts-expect-error
import { initialState as tagsInitialState, reducers as tagsReducers } from '../tags/TagStore';
// @ts-expect-error
import { initialState as taxonomyUploadInitialState, reducers as taxonomyUploadReducers } from '../taxonomy/TaxonomyUploadStore';
import { initialState as transactionsInitialState, reducers as transactionsReducers } from '../transactions/TransactionStore';
// @ts-expect-error
import { initialState as transformsInitialState, reducers as transformsReducers } from '../transforms/TransformsStore';
import { initialState as usersInitialState, reducers as usersReducers } from '../users/UsersStore';
// @ts-expect-error
import { initialState as commentsInitialState, reducers as commentsReducers } from './CommentStore';
// @ts-expect-error
import { initialState as licenseInitialState, reducers as licenseReducers } from './LicenseStore';
// @ts-expect-error
import { initialState as locationInitialState, reducers as locationReducers } from './LocationStore';
// @ts-expect-error
import { initialState as messagingInitialState, reducers as messagingReducers } from './MessagingStore';
// @ts-expect-error
import { initialState as taxonomyInitialState, reducers as taxonomyReducers } from './TaxonomyStore';
// @ts-expect-error
import { initialState as versionInitialState, reducers as versionReducers } from './VersionStore';

/**
 * Reducers compatible with Redux Toolkit
 */
export const rtkReducers = {
  clusters,
};

export const legacyReducers = {
  accessControl: { reducers: accessControlReducers, initialState: accessControlInitialState },
  addDataset: { reducers: addDatasetReducers, initialState: addDatasetInitialState },
  allSourceDatasets: { reducers: allSourceDatasetsReducers, initialState: allSourceDatasetsInitialState },
  appcues: { reducers: appcuesReducers, initialState: appcuesInitialState },
  auth: { reducers: authReducers, initialState: authInitialState },
  categorizationDashboard: { reducers: categorizationDashboardReducers, initialState: categorizationDashboardInitialState },
  categoryDetail: { reducers: categoryDetailReducers, initialState: categoryDetailInitialState },
  chrome: { reducers: chromeReducers, initialState: chromeInitialState },
  clusterFeedbackStore: { reducers: clusterFeedbackReducers, initialState: clusterFeedbackInitialState },
  comments: { reducers: commentsReducers, initialState: commentsInitialState },
  config: { reducers: configReducers, initialState: configInitialState },
  confusionMatrix: { reducers: confusionMatrixReducers, initialState: confusionMatrixInitialState },
  datasetCatalog: { reducers: datasetCatalogReducers, initialState: datasetCatalogInitialState },
  datasetFilter: { reducers: datasetFilterReducers, initialState: datasetFilterInitialState },
  datasetMetadata: { reducers: datasetMetadataReducers, initialState: datasetMetadataInitialState },
  dedupDashboard: { reducers: dedupDashboardReducers, initialState: dedupDashboardInitialState },
  coreConnectService: { reducers: coreConnectServiceReducers, initialState: coreConnectServiceInitialState },
  dnfBuilder: { reducers: dnfBuilderReducers, initialState: dnfBuilderInitialState },
  enrichment: { reducers: enrichmentReducers, initialState: enrichmentInitialState },
  errorDialog: { reducers: errorDialogReducers, initialState: errorDialogInitialState },
  externalStorage: { reducers: externalStorageReducers, initialState: externalStorageInitialState },
  fileUpload: { reducers: fileUploadReducers, initialState: fileUploadInitialState },
  goldenRecords: { reducers: goldenRecordsReducers, initialState: goldenRecordsInitialState },
  goldenRecordsRules: { reducers: goldenRecordsRulesReducers, initialState: goldenRecordsRulesInitialState },
  groupBy: { reducers: groupByReducers, initialState: groupByInitialState },
  jobs: { reducers: jobsReducers, initialState: jobsInitialState },
  license: { reducers: licenseReducers, initialState: licenseInitialState },
  location: { reducers: locationReducers, initialState: locationInitialState },
  manageProjectDatasets: { reducers: manageProjectDatasetsReducers, initialState: manageProjectDatasetsInitialState },
  messaging: { reducers: messagingReducers, initialState: messagingInitialState },
  notification: { reducers: notificationReducers, initialState: notificationInitialState },
  pregroup: { reducers: pregroupReducers, initialState: pregroupInitialState },
  projectDatasetCatalog: { reducers: projectDatasetCatalogReducers, initialState: projectDatasetCatalogInitialState },
  projects: { reducers: projectsReducers, initialState: projectsInitialState },
  projectStats: { reducers: projectStatsReducers, initialState: projectStatsInitialState },
  publishClustersDialog: { reducers: publishClustersDialogReducers, initialState: publishClustersDialogState },
  recordPairs: { reducers: recordPairsReducers, initialState: recordPairsInitialState },
  records: { reducers: recordsReducers, initialState: recordsInitialState },
  schemaMapping: { reducers: schemaMappingReducers, initialState: schemaMappingInitialState },
  schemaMappingRecs: { reducers: schemaMappingRecsReducers, initialState: schemaMappingRecsInitialState },
  sharedDatasetCatalog: { reducers: sharedDatasetCatalogReducers, initialState: sharedDatasetCatalogInitialState },
  sso: { reducers: ssoReducers, initialState: ssoInitialState },
  suppliers: { reducers: suppliersReducers, initialState: suppliersInitialState },
  tags: { reducers: tagsReducers, initialState: tagsInitialState },
  taxonomy: { reducers: taxonomyReducers, initialState: taxonomyInitialState },
  taxonomyUpload: { reducers: taxonomyUploadReducers, initialState: taxonomyUploadInitialState },
  transactions: { reducers: transactionsReducers, initialState: transactionsInitialState },
  transforms: { reducers: transformsReducers, initialState: transformsInitialState },
  unifiedDatasets: { reducers: unifiedDatasetsReducers, initialState: unifiedDatasetsInitialState },
  users: { reducers: usersReducers, initialState: usersInitialState },
  version: { reducers: versionReducers, initialState: versionInitialState },
};

export const data = {
  ...rtkReducers,
  ...legacyReducers,
};

type InferStoreType<V> = V extends { initialState: infer T } ? T : never;
export type State = {[K in keyof typeof data]-?: InferStoreType<typeof data[K]>};

const keysToInitialStates: State = _.mapObject(data, d => d.initialState) as State;

// SR 12/17/19 This is sufficiently typed with respect to the contained stores.
//   However, most of the types of the contained stores are "any" due to the ts-ignore comments in their imports.
//   As stores are converted to typescript, removing the ts-ignore comments here ought to be enough to include their types.
export default class AppState extends Record(keysToInitialStates) implements State {}
