import { List } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import { HIGH, LOW, MEDIUM } from '../../constants/ConfidenceRange';
import { history } from '../../utils/History';
import PRODUCT_NAME from '../../utils/ProductName';
import SegmentedBarGraphCard, { Segment } from '../SegmentedBarGraphCard';

const SuggestedPairLabelsCard = _.compose(
  connect((state) => {
    const { dedupDashboard: { suggestedLabels: { total, highConfidence, mediumConfidence, lowConfidence } } } = state;
    return { total, highConfidence, mediumConfidence, lowConfidence };
  }, {
    onResetPairsFilterState: () => ({ type: 'RecordPairs.resetFilters' }),
  }),
)(class SuggestedPairLabelsCard extends React.Component {
  static propTypes = {
    dedupRecipeId: PropTypes.number.isRequired,
    highConfidence: PropTypes.number.isRequired,
    lowConfidence: PropTypes.number.isRequired,
    mediumConfidence: PropTypes.number.isRequired,
    onResetPairsFilterState: PropTypes.func.isRequired,
    total: PropTypes.number.isRequired,
  };

  navigateToPairs = () => {
    const { dedupRecipeId, onResetPairsFilterState } = this.props;
    onResetPairsFilterState();
    history.push(`/pairs/recipe/${dedupRecipeId}`);
  };

  onSegmentClick = (confidenceRange) => {
    const { onResetPairsFilterState, dedupRecipeId } = this.props;
    onResetPairsFilterState();
    history.push(`/pairs/recipe/${dedupRecipeId}?confidenceRange=${confidenceRange}`);
  };

  getSegments = () => {
    const { lowConfidence, mediumConfidence, highConfidence } = this.props;
    return new List([
      new Segment({ value: highConfidence, label: 'High Confidence', onClick: _.partial(this.onSegmentClick, HIGH) }),
      new Segment({ value: mediumConfidence, label: 'Medium Confidence', onClick: _.partial(this.onSegmentClick, MEDIUM) }),
      new Segment({ value: lowConfidence, label: 'Low Confidence', onClick: _.partial(this.onSegmentClick, LOW) }),
    ]);
  };

  render() {
    return (
      <SegmentedBarGraphCard
        className="dashboard-suggested-pair-labels-card"
        onClick={this.navigateToPairs}
        segments={this.getSegments()}
        totalNounPlural={`${PRODUCT_NAME} Labels`}
        totalNounSingular={`${PRODUCT_NAME} Label`}
        totalValue={this.props.total}
      />
    );
  }
});

export default SuggestedPairLabelsCard;
