import React from 'react';

import Highlighter from '../components/Highlighter';

const defaultTaxonomyCell = (category, tier, taxonomy, searchString) => {
  const categoryName = (
    <Highlighter
      fullText={category.name}
      highlightText={searchString}
    />
  );

  return (
    <div className="category-labeled-default">
      <span className="category-name">{categoryName}</span>
    </div>
  );
};

export default defaultTaxonomyCell;
