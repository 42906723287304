import classNames from 'classnames';
import { List, Map } from 'immutable';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import SortState from '../constants/SortState';
import { profileDataset } from '../datasets/DatasetsApi';
import AttributeProfilingInfo from '../models/AttributeProfilingInfo';
import { AppState } from '../stores/MainStore';
import { TypePropType } from '../transforms/models/Types';
import { safeDivide } from '../utils/Numbers';
import PRODUCT_NAME from '../utils/ProductName';
import { $TSFixMe } from '../utils/typescript';
import { getPath } from '../utils/Values';
import CenterContent from './CenterContent';
import ConditionalButton from './ConditionalButton';
import HoverTrigger from './HoverTrigger';
import NullValuesBar from './NullValuesBar';
import style from './ProfiledHeaderCell.module.scss';
import TamrIcon from './TamrIcon';
import TooltipTrigger from './TooltipTrigger';
import WarningDialog from './WarningDialog';


const FREQ_VALUES_LIMIT = 6;

function getPercLabel(ratio: number): string {
  if (ratio < 1) {
    return '< 1%';
  }
  // DEV-5268, never return 101% Rounding up for other value below 100 is legitimate.
  return `${Math.min(Math.round(ratio), 100)}%`;
}

function mostFrequentValues(profilingInfo: AttributeProfilingInfo) {
  const values = profilingInfo.mostFrequentValues.filter(v => !!v.rawValue);
  const maxPercentage = List(values.map(v => v.percentFrequency)).max();
  return values.map(value => ({
    ...value,
    percentage: value.percentFrequency,
    relativePercentage: 100 * safeDivide(value.percentFrequency, maxPercentage),
  }));
}

function getEmptyValues() {
  const values = [];
  for (let i = FREQ_VALUES_LIMIT; i > 0; i--) {
    values.push({ percentage: ((i - 1) / FREQ_VALUES_LIMIT) * 100.0 });
  }
  return values;
}

const propTypes = {
  col: PropTypes.string.isRequired,
  currentlyProfiling: PropTypes.bool.isRequired,
  dataType: TypePropType,
  displayName: PropTypes.string,
  isGeneratedAttribute: PropTypes.bool,
  isMlAttribute: PropTypes.bool,
  profilingInfo: PropTypes.any,
  rightAlign: PropTypes.bool,
  sortCallback: PropTypes.func.isRequired,
  sortState: PropTypes.oneOf(_.values(SortState)),
};

type ProfiledColumnHeaderOwnProps = InferProps<typeof propTypes>;

const mapStateToProps = (state: AppState) => {
  const { schemaMapping: { allUnifiedAttributes: unifiedAttributes } } = state;
  return { unifiedAttributes };
};

const mapDispatchToProps = {
  onProfile: profileDataset,
};

type ProfiledColumnHeaderProps
  = ProfiledColumnHeaderOwnProps
  & ReturnType<typeof mapStateToProps>
  & typeof mapDispatchToProps;

type ProfiledColumnHeaderState = {
  showingUpdateWarningDialog: boolean
}

class UnconnectedProfiledColumnHeader extends React.Component<ProfiledColumnHeaderProps, ProfiledColumnHeaderState> {
  static propTypes = propTypes;

  state = {
    showingUpdateWarningDialog: false,
  };

  renderPopoverContent = () => {
    const { col, profilingInfo, unifiedAttributes, dataType } = this.props;
    const { profiled } = profilingInfo;
    const description = getPath(unifiedAttributes.find((ua: $TSFixMe) => ua.name === col), 'description');
    return (
      <div>
        {!profiled ? this.renderProfileButton() : undefined}
        <div className={style.colName}>
          {col}
        </div>
        {description ? (
          <div className={style.description}>
            {description}
          </div>
        ) : null}
        {dataType ? (
          <pre className={style.dataType}>
            {`Data type: ${dataType.toString()}`}
          </pre>
        ) : null}
        <div className={style.nullBarContainer}>
          <NullValuesBar profileInfo={profilingInfo} className={style.popoverNullBar} height={15} withHovers />
        </div>
        <div className={style.mostFrequentValues}>
          <div>
            {List(profiled ? mostFrequentValues(profilingInfo) : getEmptyValues()).take(FREQ_VALUES_LIMIT).map((v, i) => (
              <div className={style.freqValueRow} key={i}>
                {profiled ?
                  <div className={style.valueLabel} title={v.value}>
                    <span>{v.rawValue}</span>
                  </div> : undefined
                }
                <div className={style.valueBar}>
                  <span
                    className={profiled ? style.valueBarInner : style.emptyCell}
                    title={getPercLabel(v.percentage)}
                    style={{ width: `${Math.min(Math.max(v.percentage, 1), 100)}%` }}
                  />
                </div>
                {profiled ? <div className={style.valuePerc}>{getPercLabel(v.percentage)}</div> : undefined}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  showUpdateWarningDialog = () => {
    this.setState({ showingUpdateWarningDialog: true });
  };

  hideUpdateWarningDialog = () => {
    this.setState({ showingUpdateWarningDialog: false });
  };

  renderProfileButton = () => {
    const { currentlyProfiling } = this.props;
    return (
      <div
        key="profile-button-container"
        className={style.profileButtonContainer}
      >
        <CenterContent>
          <ConditionalButton
            className={style.profileButton}
            onClick={this.showUpdateWarningDialog}
            preconditions={Map<string, boolean>().set('This dataset is currently being profiled.', !currentlyProfiling)}
          >
            {currentlyProfiling ? 'Profiling...' : 'Profile'}
          </ConditionalButton>
        </CenterContent>
      </div>
    );
  };

  render() {
    const { onProfile, col, sortState, sortCallback, rightAlign, profilingInfo, displayName: rawDisplayName, isGeneratedAttribute, isMlAttribute } = this.props;
    const displayName = rawDisplayName || col;
    const containerClass = classNames(style.tableHeaderCell, style.sortableHeader, style.profiledHeaderCell);
    return (
      <div>
        <HoverTrigger content={this.renderPopoverContent()} className={style.profilePopoverContent} placement={'bottom'}>
          <div className={containerClass} onClick={_.partial(sortCallback, col)}>
            <div className={style.columnLabelContainer}>
              <div className={classNames(style.tableHeaderWrap, { 'right-align': rightAlign })} title={displayName}>
                {isMlAttribute ? (
                  <TooltipTrigger placement="top" content="This attribute is included in machine learning">
                    <TamrIcon size={12} iconName="tamr-icon-ml-on" className={style.tamrIconMlOn} />
                  </TooltipTrigger>
                ) : null}
                {isGeneratedAttribute ? (
                  <TooltipTrigger placement="top" content={`This attribute was created by ${PRODUCT_NAME}`}>
                    <TamrIcon size={14} iconName="tamr-icon-logo" className={style.tamrIconLogo} />
                  </TooltipTrigger>
                ) : null}
                {displayName}
              </div>
              <div style={{ flex: 'none' }}>
                {(() => {
                  switch (sortState) {
                    case SortState.SORTED_ASCENDING:
                      return <TamrIcon iconName="tamr-icon-rounded-sort-up" className={style.tableHeaderIcon} size={14} />;
                    case SortState.SORTED_DESCENDING:
                      return <TamrIcon iconName="tamr-icon-rounded-sort-down" className={style.tableHeaderIcon} size={14} />;
                    default:
                      return <TamrIcon iconName="dummy-no-icon-for-spacing" className={style.tableHeaderIcon} size={14} />;
                  }
                })()}
              </div>
            </div>
            <NullValuesBar profileInfo={profilingInfo} width={60} height={6} />
          </div>
        </HoverTrigger>
        <WarningDialog
          actionName="Profile Dataset"
          message={'Profiling a dataset can take a while. Are you sure you want to continue?'}
          onAccept={_.partial(onProfile, profilingInfo.datasetName)}
          onHide={this.hideUpdateWarningDialog}
          show={this.state.showingUpdateWarningDialog}
        />
      </div>

    );
  }
}

const ProfiledColumnHeader = connect(mapStateToProps, mapDispatchToProps)(UnconnectedProfiledColumnHeader);

export default ProfiledColumnHeader;
