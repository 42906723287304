import Immutable, { List, Map } from 'immutable';
import _ from 'underscore';

import { ORIGIN_ENTITY_ID, ORIGIN_SOURCE_NAME } from '../constants/ElasticConstants';
import PairLabelTypes from '../constants/PairLabelTypes';
import { RecordPairFeedbackResponseTypeE } from '../pairs/RecordPairFeedbackResponseType';
import { ArgTypes, checkArg, Checker } from '../utils/ArgValidation';
import ElasticUtils from '../utils/ElasticUtils';
import { $TSFixMe, JsonContent } from '../utils/typescript';
import { getPath } from '../utils/Values';
import MinimalAuthUser from './MinimalAuthUser';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';
import PairComment from './PairComment';
import PairId from './PairId';
import RecordPairId from './RecordPairId';
import RecordPairInnerFeedback from './RecordPairInnerFeedback';
import ScoreThresholds from './ScoreThresholds';


const ORIGIN_SOURCE_NAME_FIELD = ElasticUtils.sanitizeField(ORIGIN_SOURCE_NAME);
const ORIGIN_ENTITY_ID_FIELD = ElasticUtils.sanitizeField(ORIGIN_ENTITY_ID);

export class RecordPairWithData extends getModelHelpers({
  sourceId1: { type: ArgTypes.string },
  entityId1: { type: ArgTypes.string },
  sourceId2: { type: ArgTypes.string },
  entityId2: { type: ArgTypes.string },
  manualLabel: { type: ArgTypes.nullable(ArgTypes.valueIn(PairLabelTypes.manual)) },
  suggestedLabel: { type: ArgTypes.nullable(ArgTypes.valueIn(PairLabelTypes.suggested)) },
  suggestedLabelConfidence: { type: ArgTypes.nullable(ArgTypes.number.inRange(0, 1)) },
  attributeSimilarityScores: { type: ArgTypes.nullable(ArgTypes.Immutable.map.of(ArgTypes.number, ArgTypes.string)) }, // values can be outside range 0->1 if ABSOLUTE DIFF
  userDefinedSignals: { type: ArgTypes.nullable(ArgTypes.Immutable.map.of(ArgTypes.number, ArgTypes.string)) },
  txn1Data: { type: ArgTypes.nullable(ArgTypes.Immutable.map.of(ArgTypes.any, ArgTypes.string)) as Checker<undefined | null | Map<string, JsonContent>> },
  txn2Data: { type: ArgTypes.nullable(ArgTypes.Immutable.map.of(ArgTypes.any, ArgTypes.string)) },
  txn1HighlightFields: { type: ArgTypes.nullable(ArgTypes.Immutable.map.of(ArgTypes.Immutable.list.of(ArgTypes.string), ArgTypes.string)) },
  txn2HighlightFields: { type: ArgTypes.nullable(ArgTypes.Immutable.map.of(ArgTypes.Immutable.list.of(ArgTypes.string), ArgTypes.string)) },
  highImpact: { type: ArgTypes.nullable(ArgTypes.bool) },
  feedback: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(RecordPairInnerFeedback)) },
  comments: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(PairComment)) },
}, 'RecordPairWithData')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class RecordPairWithDataRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  static fromJSON(obj: $TSFixMe) {
    const { sourceId1, entityId1, sourceId2, entityId2, manualLabel, suggestedLabel, suggestedLabelConfidence, highImpact } = obj;
    const attributeSimilarityScores = obj.attributeSimilarityScores ? Map<string, number>(obj.attributeSimilarityScores) : undefined;
    const userDefinedSignals = obj.userDefinedSignals ? Map<string, number>(obj.userDefinedSignals) : undefined;
    const txn1Data = obj.txn1Data ? Map<string, JsonContent>(obj.txn1Data) : undefined;
    const txn2Data = obj.txn2Data ? Map<string, JsonContent>(obj.txn2Data) : undefined;
    const txn1HighlightFields = obj.txn1HighlightFields ? Immutable.fromJS(obj.txn1HighlightFields) : undefined;
    const txn2HighlightFields = obj.txn2HighlightFields ? Immutable.fromJS(obj.txn2HighlightFields) : undefined;
    const feedback = (List(obj.feedback)).map(RecordPairInnerFeedback.fromJSON);
    const comments = (List(obj.comments)).map(PairComment.fromJSON);
    return new RecordPairWithData({
      sourceId1,
      sourceId2,
      entityId1,
      entityId2,
      manualLabel,
      suggestedLabel,
      suggestedLabelConfidence,
      highImpact,
      attributeSimilarityScores,
      userDefinedSignals,
      txn1Data,
      txn2Data,
      txn1HighlightFields,
      txn2HighlightFields,
      feedback,
      comments,
    });
  }

  get originSourceId1() { return this.txn1Data ? this.txn1Data.get(ORIGIN_SOURCE_NAME_FIELD) : undefined; }
  get originEntityId1() { return this.txn1Data ? this.txn1Data.get(ORIGIN_ENTITY_ID_FIELD) : undefined; }
  get originSourceId2() { return this.txn2Data ? this.txn2Data.get(ORIGIN_SOURCE_NAME_FIELD) : undefined; }
  get originEntityId2() { return this.txn2Data ? this.txn2Data.get(ORIGIN_ENTITY_ID_FIELD) : undefined; }
  get hasConfidence() { return _.isFinite(this.suggestedLabelConfidence); }
  get hasManualLabel() {
    return _.contains(_.values(PairLabelTypes.manual), this.manualLabel);
  }

  getConfidenceSymbol(confidenceThresholds: ScoreThresholds) {
    checkArg({ confidenceThresholds }, ArgTypes.instanceOf(ScoreThresholds));
    const score = this.suggestedLabelConfidence;
    if (this.hasConfidence && _.isNumber(score)) {
      return confidenceThresholds.getSymbol(score);
    }
  }

  swapTxns() {
    const { sourceId1, entityId1, sourceId2, entityId2,
      txn1Data, txn2Data, txn1HighlightFields, txn2HighlightFields } = this;
    return this.merge({
      sourceId1: sourceId2,
      entityId1: entityId2,
      sourceId2: sourceId1,
      entityId2: entityId1,
      txn1Data: txn2Data,
      txn2Data: txn1Data,
      txn1HighlightFields: txn2HighlightFields,
      txn2HighlightFields: txn1HighlightFields,
    });
  }

  toRecordPairId() {
    const { sourceId1, entityId1, sourceId2, entityId2 } = this;
    return new RecordPairId({ sourceId1, entityId1, sourceId2, entityId2 });
  }

  toPairId() {
    const { entityId1, entityId2 } = this;
    return new PairId({ entityId1, entityId2 });
  }

  feedbackForUser(username: string) {
    checkArg({ username }, ArgTypes.string);
    const returnVal = this.feedback.find(f => f.username === username);
    checkArg({ returnVal }, ArgTypes.orUndefined(ArgTypes.instanceOf(RecordPairInnerFeedback)));
    return returnVal;
  }

  responseKeyForUser(username: string) {
    checkArg({ username }, ArgTypes.string);
    const returnVal = getPath(this.feedbackForUser(username), 'responseKey');
    checkArg({ returnVal }, ArgTypes.orUndefined(ArgTypes.valueIn(['MATCH', 'NON_MATCH', 'SKIP'])));
    return returnVal;
  }

  assignedToUser(username: string | undefined) {
    return !!this.feedback.find(f => f.username === username && !!f.assignmentInfo);
  }

  getCuratorFeedback(projectId: number, users: List<MinimalAuthUser>) {
    checkArg({ projectId }, ArgTypes.number);
    checkArg({ users }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(MinimalAuthUser)));
    const { feedback } = this;
    const returnVal = feedback.filter(({ username }) => {
      const authUser = users.find(u => u.username === username);
      return authUser;
    });
    checkArg({ returnVal }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(RecordPairInnerFeedback)));
    return returnVal;
  }

  getMostRecentCuratorResponse(projectId: number, users: List<MinimalAuthUser>) {
    checkArg({ projectId }, ArgTypes.number);
    checkArg({ users }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(MinimalAuthUser)));
    const curatorFeedback = this.getCuratorFeedback(projectId, users);
    const returnVal = curatorFeedback.filter(({ responseKey }) => responseKey === 'MATCH' || responseKey === 'NON_MATCH')
      .sortBy(({ responseTimestamp }) => -(responseTimestamp || 0))
      .first();
    checkArg({ returnVal }, ArgTypes.orUndefined(ArgTypes.instanceOf(RecordPairInnerFeedback)));
    return returnVal;
  }

  // does not consider curator "SKIP" responses, only "MATCH" / "NON_MATCH"
  otherCuratorResponses(projectId: number, users: List<MinimalAuthUser>, username: string) {
    checkArg({ projectId }, ArgTypes.number);
    checkArg({ users }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(MinimalAuthUser)));
    checkArg({ username }, ArgTypes.string);
    const curatorFeedback = this.getCuratorFeedback(projectId, users);
    const returnVal = curatorFeedback.filter(f => f.username !== username && !!f.response);
    checkArg({ returnVal }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(RecordPairInnerFeedback)));
    return returnVal;
  }

  getNextCuratorResponse(projectId: number, users: List<MinimalAuthUser>, username: string): RecordPairFeedbackResponseTypeE | null {
    checkArg({ projectId }, ArgTypes.number);
    checkArg({ users }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(MinimalAuthUser)));
    checkArg({ username }, ArgTypes.string);
    const nextCuratorFeedback = this.otherCuratorResponses(projectId, users, username)
      .sortBy(({ responseTimestamp }) => -(responseTimestamp || 0))
      .first() as RecordPairInnerFeedback | undefined;
    const returnVal = nextCuratorFeedback ? nextCuratorFeedback.responseKey : null;
    return returnVal || null;
  }

  getVerifiedResponse(projectId: number, users: List<MinimalAuthUser>): RecordPairInnerFeedback | undefined {
    checkArg({ projectId }, ArgTypes.number);
    checkArg({ users }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(MinimalAuthUser)));
    const { manualLabel, feedback } = this;
    // we don't track who set a manual label. best we can do is find the most recent curator to have
    // put feedback that == the manual label
    const curatorFeedbackEqualingManualLabel = feedback.filter(({ username, responseKey }) => {
      const authUser = users.find(u => u.username === username);
      return authUser && responseKey === manualLabel;
    });
    const returnValue = curatorFeedbackEqualingManualLabel.sortBy(f => f.getIn(['response', 'lastModified'])).last(undefined);
    return returnValue;
  }

  userHasOnlyCuratorResponse(projectId: number, users: List<MinimalAuthUser>, username: string) {
    checkArg({ projectId }, ArgTypes.number);
    checkArg({ users }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(MinimalAuthUser)));
    checkArg({ username }, ArgTypes.string);
    const loggedInUserHasVerifiedResponse = getPath(this.getVerifiedResponse(projectId, users), 'username') === username;
    const noOtherCuratorResponses = this.otherCuratorResponses(projectId, users, username).isEmpty();
    const returnVal = loggedInUserHasVerifiedResponse && noOtherCuratorResponses;
    checkArg({ returnVal }, ArgTypes.bool);
    return returnVal;
  }

  getUserResponseKey(username: string) {
    checkArg({ username }, ArgTypes.string);
    const { feedback } = this;
    const feedbackForUser = feedback.find(f => f.username === username);
    const userResponseLabel = feedbackForUser && feedbackForUser.response && feedbackForUser.response.label;
    const skipped = feedbackForUser && feedbackForUser.assignmentInfo && feedbackForUser.assignmentInfo.status === 'SKIP';
    const returnValue = skipped ? 'SKIP' : userResponseLabel;
    return returnValue;
  }
}

export default RecordPairWithData;
