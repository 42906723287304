import { ArgTypes } from '../utils/ArgValidation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';
import PairId from './PairId';

export class RecordPairId extends getModelHelpers({
  sourceId1: { type: ArgTypes.string },
  entityId1: { type: ArgTypes.string },
  sourceId2: { type: ArgTypes.string },
  entityId2: { type: ArgTypes.string },
}, 'RecordPairId')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class RecordPairIdRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      const { sourceId1, entityId1, sourceId2, entityId2 } = args;
      // the correct order of transactionId's follows: id1 < id2
      const swap = entityId1 > entityId2;
      const argsInCorrectOrder = {
        sourceId1: swap ? sourceId2 : sourceId1,
        entityId1: swap ? entityId2 : entityId1,
        sourceId2: swap ? sourceId1 : sourceId2,
        entityId2: swap ? entityId1 : entityId2,
      };
      super(argsInCorrectOrder);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  /**
   * instance methods
   */
  swapRecords(): RecordPairId {
    const { sourceId1, entityId1, sourceId2, entityId2 } = this;
    return new RecordPairId({ sourceId1, entityId1, sourceId2, entityId2 });
  }

  toPairId() {
    const { entityId1, entityId2 } = this;
    return new PairId({ entityId1, entityId2 });
  }
}

export default RecordPairId;
