import { List } from 'immutable';

import EsRecord from '../models/EsRecord';
import Model from '../models/Model';
import { ArgTypes } from '../utils/ArgValidation';
import { routes } from '../utils/Routing';

export const initialState = new (Model({
  categoryId: { type: ArgTypes.orUndefined(ArgTypes.number) },
  loading: { type: ArgTypes.bool, defaultValue: false },
  loadedCategoryId: { type: ArgTypes.nullable(ArgTypes.number) },
  records: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(EsRecord)), defaultValue: List() },
  totalRecords: { type: ArgTypes.nullable(ArgTypes.number) },
}))();

export const reducers = {
  'Location.change': (state, { location }) => {
    const m = routes.categoryDetail.match(location.pathname);
    if (m) {
      return state.set('categoryId', parseInt(m.id, 10));
    }
    return state;
  },
  'CategoryDetail.fetchRecords': (state) => {
    return state.set('loading', true);
  },
  'CategoryDetail.fetchRecordsCompleted': (state, { loadedCategoryId, records, totalRecords }) => {
    return state.merge({ loadedCategoryId, records, totalRecords, loading: false });
  },
};
