import { List, Map, Record, Set } from 'immutable';
import qs from 'query-string';
import _ from 'underscore';

import SidebarTabs from '../constants/SidebarTabs';
import SortState, { SortStateValueType } from '../constants/SortState';
import ActiveGeospatialAttribute from '../models/ActiveGeospatialAttribute';
import ConfidenceFilter from '../models/ConfidenceFilter';
import EsRecord from '../models/EsRecord';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import { StoreReducers } from '../stores/AppAction';
import { AppState } from '../stores/MainStore';
import { ArgTypes } from '../utils/ArgValidation';
import ElasticUtils from '../utils/ElasticUtils';
import { routes } from '../utils/Routing';
import SortUtils from '../utils/SortUtils';
import { INVALID, maybeSet, parseBoolean, parseList, parseNumber, parseSet, parseSort, parseString } from '../utils/Url';
import { resetExcept } from '../utils/Values';
import { DEFAULT_PAGE_SIZE } from './TransactionUtils';

enum LoadPhase {
  SAVE = 1,
  RELOAD = 2,
}

export class TransactionStore extends getModelHelpers({
  selectedDatasetIds: { type: ArgTypes.Immutable.set.of(ArgTypes.number), defaultValue: Set() },
  columnSortStates: { type: ArgTypes.Immutable.map.of(ArgTypes.string), defaultValue: Map() },
  rows: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(EsRecord)), defaultValue: List() },
  total: { type: ArgTypes.number, defaultValue: 0 },
  pageNum: { type: ArgTypes.number, defaultValue: 0 },
  pageSize: { type: ArgTypes.number, defaultValue: DEFAULT_PAGE_SIZE },
  categoryIds: { type: ArgTypes.Immutable.list.of(ArgTypes.number), defaultValue: List() },
  filterCategoryId: { type: ArgTypes.nullable(ArgTypes.number) },
  categorizedByMe: { type: ArgTypes.bool, defaultValue: false },
  labeledByTamr: { type: ArgTypes.bool, defaultValue: false },
  labeledByUser: { type: ArgTypes.bool, defaultValue: false },
  showExternalData: { type: ArgTypes.bool, defaultValue: false },
  expertResponsesAgree: { type: ArgTypes.bool, defaultValue: false },
  expertResponsesDisagree: { type: ArgTypes.bool, defaultValue: false },
  expertsPending: { type: ArgTypes.bool, defaultValue: false },
  expertsUnsure: { type: ArgTypes.bool, defaultValue: false },
  expertsNone: { type: ArgTypes.bool, defaultValue: false },
  expertsSome: { type: ArgTypes.bool, defaultValue: false },
  filterExpanded: { type: ArgTypes.bool, defaultValue: false },
  queryString: { type: ArgTypes.string, defaultValue: '' },
  activeRecordId: { type: ArgTypes.nullable(ArgTypes.string) },
  selectedRecordIds: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: Set() },
  unlabeled: { type: ArgTypes.bool, defaultValue: false },
  unlabeledByTamr: { type: ArgTypes.bool, defaultValue: false },
  labelAgreesWithTamr: { type: ArgTypes.bool, defaultValue: false },
  labelDisagreesWithTamr: { type: ArgTypes.bool, defaultValue: false },
  hasComments: { type: ArgTypes.bool, defaultValue: false },
  loading: { type: ArgTypes.bool, defaultValue: false },
  expanded: { type: ArgTypes.bool, defaultValue: false },
  sidebarTabKey: { type: ArgTypes.string, defaultValue: SidebarTabs.DETAILS },
  showUpdateCategorizationsWarningDialog: { type: ArgTypes.bool, defaultValue: false },
  applyFeedbackAndUpdateResults: { type: ArgTypes.bool, defaultValue: true },
  assignedToMeComplete: { type: ArgTypes.bool, defaultValue: false },
  assignedToMeToDo: { type: ArgTypes.bool, defaultValue: false },
  loadedFilterInfo: { type: ArgTypes.any },
  commentFocusSequence: { type: ArgTypes.number, defaultValue: 0 },
  transactionsSequence: { type: ArgTypes.number, defaultValue: 0 },
  suggestionLaunching: { type: ArgTypes.bool, defaultValue: false },
  feedbackSummaryNumAssigned: { type: ArgTypes.number, defaultValue: 0 },
  feedbackSummaryNumResponded: { type: ArgTypes.number, defaultValue: 0 },
  showCategoryFilter: { type: ArgTypes.bool, defaultValue: false },
  showCategorizeDialog: { type: ArgTypes.bool, defaultValue: false },
  categorizeDialogRecords: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(EsRecord)), defaultValue: List() },
  showAssignedToUserFilter: { type: ArgTypes.bool, defaultValue: false },
  showCategorizedByUsersFilter: { type: ArgTypes.bool, defaultValue: false },
  assignmentStatusNone: { type: ArgTypes.bool, defaultValue: false },
  assignmentStatusSome: { type: ArgTypes.bool, defaultValue: false },
  assignmentStatusAll: { type: ArgTypes.bool, defaultValue: false },
  assignmentStatusUnassigned: { type: ArgTypes.bool, defaultValue: false },
  assignmentStatusAssigned: { type: ArgTypes.bool, defaultValue: false },
  assignedToUsers: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: Set() },
  categorizedByUsers: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: Set() },
  datasetFilterDialogVisible: { type: ArgTypes.bool, defaultValue: false },
  highImpact: { type: ArgTypes.bool, defaultValue: false },
  confidenceLow: { type: ArgTypes.bool, defaultValue: false },
  confidenceMedium: { type: ArgTypes.bool, defaultValue: false },
  confidenceHigh: { type: ArgTypes.bool, defaultValue: false },
  hasConfidence: { type: ArgTypes.bool, defaultValue: false },
  confidence: { type: ArgTypes.nullable(ArgTypes.instanceOf(ConfidenceFilter)) },
  sortByConfidence: { type: ArgTypes.valueIn(SortState), defaultValue: SortState.UNSORTED },
  // in order to track state-changing user actions that should block further
  // action to that record until request returns
  currentlyProcessing: { type: ArgTypes.Immutable.map.of(ArgTypes.number, ArgTypes.string), defaultValue: Map() },
  loadingModel: { type: ArgTypes.bool, defaultValue: false },
  modelExists: { type: ArgTypes.bool, defaultValue: false },
  modelRecipeId: { type: ArgTypes.nullable(ArgTypes.number) },
  activeGeospatialAttribute: { type: ArgTypes.orNull(ActiveGeospatialAttribute.argType), defaultValue: null },
}, 'TransactionStore')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class GeospatialRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  reduce(reduceFunction: Function, accumulator: any) {
    Array.from(this.toSeq().entries()).forEach(entry => {
      accumulator = reduceFunction(accumulator, entry[1], entry[0]);
    });
    return accumulator;
  }
}

export const initialState = new TransactionStore({});

export const getActiveRecord = ({ rows, activeRecordId }: TransactionStore) => {
  return rows.find(r => r.recordId === activeRecordId);
};

export const getActiveRecordIndex = ({ rows, activeRecordId }: TransactionStore) => {
  return rows.findIndex(r => r.recordId === activeRecordId);
};

export const getSelectedRecords = ({ rows, selectedRecordIds }: TransactionStore) => {
  return rows.filter(r => selectedRecordIds.has(r.recordId));
};

export const getSelectedRecordIndexes = ({ rows, selectedRecordIds }: TransactionStore) => {
  return selectedRecordIds.map(id => rows.findIndex(r => r.recordId === id));
};

export const isAssignmentStatusSemiChecked = ({ transactions: { assignmentStatusAssigned, assignmentStatusNone, assignmentStatusSome, assignmentStatusAll } }: AppState) => {
  return assignmentStatusAssigned ? false : (assignmentStatusNone || assignmentStatusSome || assignmentStatusAll);
};

export const isExpertsSemiChecked = ({ transactions: { expertsSome, expertResponsesAgree, expertResponsesDisagree, expertsUnsure } }: AppState) => {
  return expertsSome ? false : (expertResponsesAgree || expertResponsesDisagree || expertsUnsure);
};

export const isLabeledByUserSemiChecked = ({ transactions: { labeledByUser, unlabeledByTamr, labelAgreesWithTamr, labelDisagreesWithTamr } }: AppState) => {
  return labeledByUser ? false : (unlabeledByTamr || labelAgreesWithTamr || labelDisagreesWithTamr);
};

const FilterInfo = Record({
  transactionsSequence: null,
  selectedDatasetIds: null,
  columnSortStates: null,
  pageNum: null,
  pageSize: null,
  categoryIds: null,
  categorizedByMe: null,
  labeledByTamr: null,
  labeledByUser: null,
  showExternalData: null,
  expertResponsesAgree: null,
  expertResponsesDisagree: null,
  expertsPending: null,
  expertsUnsure: null,
  expertsNone: null,
  expertsSome: null,
  queryString: null,
  unlabeled: null,
  labelAgreesWithTamr: null,
  unlabeledByTamr: null,
  labelDisagreesWithTamr: null,
  hasComments: null,
  assignedToMeComplete: null,
  assignedToMeToDo: null,
  assignmentStatusNone: null,
  assignmentStatusSome: null,
  assignmentStatusAll: null,
  assignmentStatusUnassigned: null,
  assignmentStatusAssigned: null,
  recipeId: null,
  assignedToUsers: null,
  categorizedByUsers: null,
  highImpact: null,
  confidenceLow: null,
  confidenceMedium: null,
  confidenceHigh: null,
  hasConfidence: null,
  confidence: null,
  sortByConfidence: null,
});

export const getFilterInfo = ({ transactions: { transactionsSequence, selectedDatasetIds, columnSortStates, pageNum, pageSize, categoryIds, categorizedByMe, labeledByTamr, labeledByUser, showExternalData, expertResponsesAgree, expertResponsesDisagree, expertsPending, expertsUnsure, expertsNone, expertsSome, queryString, unlabeled, unlabeledByTamr, labelAgreesWithTamr, labelDisagreesWithTamr, hasComments, assignedToMeComplete, assignedToMeToDo, assignmentStatusNone, assignmentStatusSome, assignmentStatusAll, assignmentStatusUnassigned, assignmentStatusAssigned, assignedToUsers, categorizedByUsers, highImpact, confidenceLow, confidenceMedium, confidenceHigh, hasConfidence, confidence, sortByConfidence }, location: { recipeId } }: AppState) => {
  // @ts-expect-error can be fixed when FilterInfo is properly typed
  return new FilterInfo({ transactionsSequence, selectedDatasetIds, columnSortStates, pageNum, pageSize, categoryIds, categorizedByMe, labeledByTamr, labeledByUser, showExternalData, expertResponsesAgree, expertResponsesDisagree, expertsPending, expertsUnsure, expertsNone, expertsSome, queryString, unlabeled, labelAgreesWithTamr, unlabeledByTamr, labelDisagreesWithTamr, hasComments, assignedToMeComplete, assignedToMeToDo, assignmentStatusNone, assignmentStatusSome, assignmentStatusAll, assignmentStatusUnassigned, assignmentStatusAssigned, recipeId, assignedToUsers, categorizedByUsers, highImpact, confidenceLow, confidenceMedium, confidenceHigh, hasConfidence, confidence, sortByConfidence });
};

/**
 * Get a list of the highest-voted categories for the selected records
 *
 * If a there is a tie, or the net votes on categories for a record aren't >0, then no entry is
 * returned for that record
 */
export const getTopExpertCategorizations = (state: AppState) => {
  const records = getSelectedRecords(state.transactions);

  return records.map(r => {
    // if this record doesn't have any feedback, do nothing
    if (r.feedback.length === 0) return;

    // sum up the votes for each categorization feedback given for this record (+1 for agree, -1 for disagree)
    const votes = List(r.feedback)
      // @ts-expect-error can be fixed when store is properly typed and getSelectedRecords returns correct list
      .map(f => f.responses.toList())
      .flatten(1) // only flatten 1 level, not all the nested values inside of the feedback model
      .groupBy(c => c.categoryId)
      .map(l => l.reduce((sum, c) => {
        return c.response === 'DISAGREE' ? sum - 1 : sum + 1;
      }, 0));

    // now that we have all of the votes, need to find the maximum one
    const maxNetVotes = votes.max();
    // if the maximum net votes is not >0, do nothing
    if (!maxNetVotes || maxNetVotes < 1) return;
    // if multiple categories have the maximum vote (i.e. there's a tie), do nothing
    const categoriesWithMaxNetVotes = votes.filter(v => v === maxNetVotes);
    // @ts-expect-error
    if (categoriesWithMaxNetVotes.size > 1) return;
    // otherwise, the category with the maximum net votes is the winner
    return {
      recordId: r.recordId,
      categoryId: categoriesWithMaxNetVotes.keySeq().get(0),
      reason: undefined,
    };
  }).filter(c => c); // remove the "do nothing" cases
};

const reloadRecords = (state: TransactionStore) => state.update('transactionsSequence', x => x + 1);
const clearSelection = (state: TransactionStore) => state.delete('selectedRecordIds');
const resetPage = (state: TransactionStore) => state.delete('pageNum');
const clearReviewerSection = (state: TransactionStore) => {
  return state
    .delete('assignedToMeToDo')
    .delete('assignedToMeComplete');
};
const clearCuratorSection = (state: TransactionStore) => {
  return state
    .delete('assignmentStatusAll')
    .delete('assignmentStatusSome')
    .delete('assignmentStatusNone')
    .delete('assignmentStatusUnassigned')
    .delete('assignmentStatusAssigned')
    .delete('expertsUnsure')
    .delete('expertsNone')
    .delete('expertsSome')
    .delete('expertResponsesAgree')
    .delete('expertResponsesDisagree')
    .delete('labeledByUser')
    .delete('labelAgreesWithTamr')
    .delete('labelDisagreesWithTamr')
    .delete('unlabeled')
    .delete('unlabeledByTamr')
    .delete('hasComments')
    .delete('assignedToUsers')
    .delete('categorizedByUsers')
    .delete('categoryIds')
    .delete('selectedDatasetIds')
    .delete('showExternalData')
    .delete('highImpact')
    .delete('confidenceLow')
    .delete('confidenceMedium')
    .delete('confidenceHigh')
    .delete('hasConfidence')
    .delete('confidence');
};

export const filterMyAssignments = (state: TransactionStore) => {
  return state
    // @ts-expect-error immutableUpdateWithOneArgument
    .update(clearCuratorSection)
    .merge({ assignedToMeToDo: true, assignedToMeComplete: false });
};

export const filterNeedsVerification = (state: TransactionStore) => {
  return state
    // @ts-expect-error immutableUpdateWithOneArgument
    .update(clearReviewerSection)
    .merge({
      assignmentStatusAll: true,
      assignmentStatusSome: false,
      assignmentStatusNone: false,
      assignmentStatusUnassigned: true,
      assignmentStatusAssigned: true,
      expertsUnsure: false,
      expertsNone: false,
      expertsSome: true,
      expertResponsesAgree: true,
      expertResponsesDisagree: true,
      labeledByUser: false,
      labelAgreesWithTamr: false,
      labelDisagreesWithTamr: false,
      unlabeled: true,
      unlabeledByTamr: false,
    });
};

const addCurrentlyProcessingIds = (state: TransactionStore, ids: Set<number>) => {
  return state.update('currentlyProcessing', m => m.merge(ids.reduce((r, id) => r.set(id, LoadPhase.SAVE), Map())));
};

const setCurrentlyProcessingIdsToReload = (state: TransactionStore, ids: Set<number>) => {
  return state.update('currentlyProcessing', m => m.merge(ids.reduce((r, id) => r.set(id, LoadPhase.RELOAD), Map())));
};

const removeCurrentlyProcessingIds = (state: TransactionStore) => {
  return state.update('currentlyProcessing', m => m.filterNot(reloading => reloading === LoadPhase.RELOAD));
};

export const anyFiltersActive = (state: AppState) => {
  const { transactions: { assignedToMeComplete, assignedToMeToDo, assignmentStatusAll, assignmentStatusSome, assignmentStatusNone, assignmentStatusUnassigned, assignmentStatusAssigned, expertsUnsure, expertsNone, expertsSome, expertResponsesAgree, expertResponsesDisagree, labeledByUser, labelAgreesWithTamr, labelDisagreesWithTamr, unlabeled, unlabeledByTamr, assignedToUsers, categorizedByUsers, categoryIds, selectedDatasetIds, showExternalData, hasComments, hasConfidence, confidence, highImpact } } = state;
  return assignedToMeComplete || assignedToMeToDo || assignmentStatusAll || assignmentStatusSome || assignmentStatusNone || assignmentStatusUnassigned || assignmentStatusAssigned || expertsUnsure || expertsNone || expertsSome || expertResponsesAgree || expertResponsesDisagree || labeledByUser || labelAgreesWithTamr || labelDisagreesWithTamr || unlabeled || unlabeledByTamr || assignedToUsers.size || categorizedByUsers.size || categoryIds.size || selectedDatasetIds.size || showExternalData || hasComments || highImpact || (hasConfidence && confidence);
};

export const reducers: StoreReducers<TransactionStore> = {
  'Location.change': (state, { location }) => {
    if (routes.spend.match(location.pathname)) {
      const params = qs.parse(location.search);
      const parseConfidence = (v: string) => {
        return v ? ConfidenceFilter.fromUrlString(v) : INVALID;
      };
      return _.compose(
        maybeSet('pageNum', parseNumber(params.pageNum)),
        maybeSet('pageSize', parseNumber(params.pageSize)),
        maybeSet('selectedDatasetIds', parseSet(parseNumber)(params.selectedDatasetIds)),
        maybeSet('categoryIds', parseList(parseNumber)(params.categoryIds)),
        maybeSet('categorizedByMe', parseBoolean(params.categorizedByMe)),
        maybeSet('labeledByTamr', parseBoolean(params.labeledByTamr)),
        maybeSet('labeledByUser', parseBoolean(params.labeledByUser)),
        maybeSet('unlabeled', parseBoolean(params.unlabeled)),
        maybeSet('unlabeledByTamr', parseBoolean(params.unlabeledByTamr)),
        maybeSet('labelAgreesWithTamr', parseBoolean(params.labelAgreesWithTamr)),
        maybeSet('labelDisagreesWithTamr', parseBoolean(params.labelDisagreesWithTamr)),
        maybeSet('showExternalData', parseBoolean(params.showExternalData)),
        maybeSet('expertResponsesAgree', parseBoolean(params.expertResponsesAgree)),
        maybeSet('expertResponsesDisagree', parseBoolean(params.expertResponsesDisagree)),
        maybeSet('expertsPending', parseBoolean(params.expertsPending)),
        maybeSet('expertsUnsure', parseBoolean(params.expertsUnsure)),
        maybeSet('expertsNone', parseBoolean(params.expertsNone)),
        maybeSet('expertsSome', parseBoolean(params.expertsSome)),
        maybeSet('hasComments', parseBoolean(params.hasComments)),
        maybeSet('queryString', parseString(params.queryString)),
        maybeSet('columnSortStates', parseSort(params.sort)),
        maybeSet('assignedToMeComplete', parseBoolean(params.assignedToMeComplete)),
        maybeSet('assignedToMeToDo', parseBoolean(params.assignedToMeToDo)),
        maybeSet('assignmentStatusNone', parseBoolean(params.assignmentStatusNone)),
        maybeSet('assignmentStatusSome', parseBoolean(params.assignmentStatusSome)),
        maybeSet('assignmentStatusAll', parseBoolean(params.assignmentStatusAll)),
        maybeSet('assignmentStatusUnassigned', parseBoolean(params.assignmentStatusUnassigned)),
        maybeSet('assignmentStatusAssigned', parseBoolean(params.assignmentStatusAssigned)),
        maybeSet('assignedToUsers', parseSet(parseString)(params.assignedToUsers)),
        maybeSet('categorizedByUsers', parseSet(parseString)(params.categorizedByUsers)),
        maybeSet('filterExpanded', parseBoolean(params.filterExpanded)),
        maybeSet('highImpact', parseBoolean(params.highImpact)),
        maybeSet('confidenceLow', parseBoolean(params.confidenceLow)),
        maybeSet('confidenceMedium', parseBoolean(params.confidenceMedium)),
        maybeSet('confidenceHigh', parseBoolean(params.confidenceHigh)),
        maybeSet('hasConfidence', parseBoolean(params.hasConfidence)),
        maybeSet('confidence', parseConfidence(params.confidence)),
        maybeSet('sortByConfidence', parseString(params.sortByConfidence)),
      )(state).update(reloadRecords);
    }
    return state;
  },

  'Location.projectChange': (state) => resetExcept(state, ['isLoading', 'suggestionLaunching']),

  'Transactions.resetFilters': (state) => resetExcept(state, ['isLoading', 'suggestionLaunching']),

  'Transactions.setSelectedDatasets': (state, { datasetsToAdd, datasetsToRemove }) => {
    return state
      .update('selectedDatasetIds', s => s.union(datasetsToAdd.map(d => d.id.id)).subtract(datasetsToRemove.map(d => d.id.id)))
      // @ts-expect-error immutableUpdateWithOneArgument
      .update(resetPage)
      .set('datasetFilterDialogVisible', false);
  },
  'Transactions.resetDatasetFilter': (state) => {
    return state.delete('selectedDatasetIds');
  },

  'Transactions.setCategoryIds': (state, { categoryIds }) => {
    return resetPage(state).merge({ showCategoryFilter: false, categoryIds });
  },

  'Transactions.setFilterExternalData': (state, { showExternalData }) => {
    return resetPage(state).merge({ showExternalData });
  },

  'Transactions.setFilterCategorizedByMe': (state, { categorizedByMe }) => {
    return resetPage(state).merge({ categorizedByMe });
  },

  'Transactions.setFilterTamrLabeled': (state, { labeledByTamr }) => {
    return resetPage(state).merge({ labeledByTamr });
  },

  'Transactions.setFilterUserLabeled': (state, { labeledByUser }) => {
    // Force select / unselect the child filters if the grouping filter is selected / unselected
    const unlabeledByTamr = labeledByUser;
    const labelAgreesWithTamr = labeledByUser;
    const labelDisagreesWithTamr = labeledByUser;
    return resetPage(state).merge({ labeledByUser, unlabeledByTamr, labelAgreesWithTamr, labelDisagreesWithTamr });
  },

  'Transactions.setFilterUnlabeled': (state, { unlabeled }) => {
    return resetPage(state).merge({ unlabeled });
  },

  'Transactions.setFilterUnlabeledByTamr': (state, { unlabeledByTamr }) => {
    // Grouping filter is true if all child filters are true and false if all of them are not
    const labeledByUser = unlabeledByTamr && state.labelAgreesWithTamr && state.labelDisagreesWithTamr;
    return resetPage(state).merge({ labeledByUser, unlabeledByTamr });
  },

  'Transactions.setFilterLabelAgreesWithTamr': (state, { labelAgreesWithTamr }) => {
    // Grouping filter is true if all child filters are true and false if all of them are not
    const labeledByUser = state.unlabeledByTamr && labelAgreesWithTamr && state.labelDisagreesWithTamr;
    return resetPage(state).merge({ labeledByUser, labelAgreesWithTamr });
  },

  'Transactions.setFilterLabelDisagreesWithTamr': (state, { labelDisagreesWithTamr }) => {
    // Grouping filter is true if all child filters are true and false if all of them are not
    const labeledByUser = state.unlabeledByTamr && state.labelAgreesWithTamr && labelDisagreesWithTamr;
    return resetPage(state).merge({ labeledByUser, labelDisagreesWithTamr });
  },

  'Transactions.setFilterExpertResponsesAgree': (state, { expertResponsesAgree }) => {
    // Grouping filter is true if all child filters are true and false if all of them are not
    const expertsSome = expertResponsesAgree && state.expertResponsesDisagree && state.expertsUnsure;
    return resetPage(state).merge({ expertsSome, expertResponsesAgree });
  },

  'Transactions.setFilterExpertResponsesDisagree': (state, { expertResponsesDisagree }) => {
    // Grouping filter is true if all child filters are true and false if all of them are not
    const expertsSome = state.expertResponsesAgree && expertResponsesDisagree && state.expertsUnsure;
    return resetPage(state).merge({ expertsSome, expertResponsesDisagree });
  },

  'Transactions.setFilterExpertsPending': (state, { expertsPending }) => {
    return resetPage(state).merge({ expertsPending });
  },

  'Transactions.setFilterExpertsUnsure': (state, { expertsUnsure }) => {
    // Grouping filter is true if all child filters are true and false if all of them are not
    const expertsSome = state.expertResponsesAgree && state.expertResponsesDisagree && expertsUnsure;
    return resetPage(state).merge({ expertsSome, expertsUnsure });
  },

  'Transactions.setFilterExpertsNone': (state, { expertsNone }) => {
    return resetPage(state).merge({ expertsNone });
  },

  'Transactions.setFilterExpertsSome': (state, { expertsSome }) => {
    // Grouping filter is true if all child filters are true and false if all of them are not
    const expertsUnsure = expertsSome;
    const expertResponsesAgree = expertsSome;
    const expertResponsesDisagree = expertsSome;
    return resetPage(state).merge({ expertsSome, expertsUnsure, expertResponsesAgree, expertResponsesDisagree });
  },

  'Transactions.setFilterHasComments': (state, { hasComments }) => {
    return resetPage(state).merge({ hasComments });
  },

  'Transactions.setAssignmentStatusNone': (state, { assignmentStatusNone }) => {
    // Grouping filter is true if all child filters are true and false if all of them are not
    const assignmentStatusAssigned = assignmentStatusNone && state.assignmentStatusSome && state.assignmentStatusAll;
    return resetPage(state).merge({ assignmentStatusNone, assignmentStatusAssigned });
  },

  'Transactions.setAssignmentStatusSome': (state, { assignmentStatusSome }) => {
    // Grouping filter is true if all child filters are true and false if all of them are not
    const assignmentStatusAssigned = state.assignmentStatusNone && assignmentStatusSome && state.assignmentStatusAll;
    return resetPage(state).merge({ assignmentStatusSome, assignmentStatusAssigned });
  },

  'Transactions.setAssignmentStatusAll': (state, { assignmentStatusAll }) => {
    // Grouping filter is true if all child filters are true and false if all of them are not
    const assignmentStatusAssigned = state.assignmentStatusNone && state.assignmentStatusSome && assignmentStatusAll;
    return resetPage(state).merge({ assignmentStatusAll, assignmentStatusAssigned });
  },

  'Transactions.setAssignmentStatusUnassigned': (state, { assignmentStatusUnassigned }) => {
    return resetPage(state).merge({ assignmentStatusUnassigned });
  },

  'Transactions.setAssignmentStatusAssigned': (state, { assignmentStatusAssigned }) => {
    // Force select / unselect the child filters if the grouping filter is selected / unselected
    const assignmentStatusNone = assignmentStatusAssigned;
    const assignmentStatusSome = assignmentStatusAssigned;
    const assignmentStatusAll = assignmentStatusAssigned;
    return resetPage(state).merge({ assignmentStatusAssigned, assignmentStatusNone, assignmentStatusSome, assignmentStatusAll });
  },

  'Transactions.setFilterAssignedToMeComplete': (state, { assignedToMeComplete }) => {
    return resetPage(state).merge({ assignedToMeComplete });
  },

  'Transactions.setFilterAssignedToMeToDo': (state, { assignedToMeToDo }) => {
    return resetPage(state).merge({ assignedToMeToDo });
  },

  'Transactions.setFilterAssignedToUsers': (state, { assignedToUsers }) => {
    return resetPage(state).merge({ showAssignedToUserFilter: false, assignedToUsers });
  },

  'Transactions.setFilterCategorizedByUsers': (state, { categorizedByUsers }) => {
    return resetPage(state).merge({ showCategorizedByUsersFilter: false, categorizedByUsers });
  },

  'Transactions.setFilterHighImpact': (state, { highImpact }) => {
    return resetPage(state).merge({ highImpact });
  },

  'Transactions.setConfidenceLow': (state, { confidenceLow }) => {
    return resetPage(state).merge({ confidenceLow });
  },

  'Transactions.setConfidenceMedium': (state, { confidenceMedium }) => {
    return resetPage(state).merge({ confidenceMedium });
  },

  'Transactions.setConfidenceHigh': (state, { confidenceHigh }) => {
    return resetPage(state).merge({ confidenceHigh });
  },

  'Transactions.setHasConfidence': (state, { hasConfidence }) => {
    const confidence = hasConfidence
      ? (state.confidence || new ConfidenceFilter({ lowerBound: 0, upperBound: 1 }))
      : state.confidence;
    return resetPage(state).merge({ hasConfidence, confidence });
  },

  'Transactions.setConfidence': (state, { confidence }) => {
    return resetPage(state).merge({ hasConfidence: true, confidence });
  },

  'Transactions.progressConfidenceSort': (state) => {
    return resetPage(state).merge({ sortByConfidence: SortUtils.getNext(state.sortByConfidence) });
  },

  'Transactions.clearReviewerFilters': (state) => {
    // @ts-expect-error immutableUpdateWithOneArgument
    return state.update(clearReviewerSection).update(resetPage);
  },

  'Transactions.setReviewerFilters': (state) => {
    // @ts-expect-error immutableUpdateWithOneArgument
    return state.update(filterMyAssignments).update(resetPage);
  },

  'Transactions.clearCuratorFilters': (state) => {
    // @ts-expect-error immutableUpdateWithOneArgument
    return state.update(clearCuratorSection).update(resetPage);
  },

  'Transactions.setFilterNeedsVerification': (state) => {
    // @ts-expect-error immutableUpdateWithOneArgument
    return state.update(filterNeedsVerification).update(resetPage);
  },

  'Transactions.clearFilters': (state) => {
    // @ts-expect-error immutableUpdateWithOneArgument
    return state.update(clearCuratorSection).update(clearReviewerSection).update(resetPage);
  },

  'Transactions.fetchTransactions': (state) => {
    return state.set('loading', true);
  },

  'Transactions.fetchTransactionsCompleted': (state, { rows, total, filterInfo }) => {
    const ids = rows.map(({ recordId }) => recordId).toSet();
    return removeCurrentlyProcessingIds(state)
      .merge({ rows, total, loading: false, loadedFilterInfo: filterInfo })
      .update('selectedRecordIds', s => s.filter(id => ids.has(id)))
      // @ts-expect-error
      .update('activeRecordId', id => (ids.has(id) ? id : null));
  },

  'Transactions.fetchTransactionsFailed': (state, { filterInfo }) => {
    return state.merge({ loading: false, loadedFilterInfo: filterInfo });
  },

  'Transactions.toggleSort': (state, { columnName }) => {
    const sanitizedColName = ElasticUtils.sanitizeField(columnName);
    const currentState = state.columnSortStates.get(sanitizedColName) as SortStateValueType || SortState.UNSORTED;
    return state.merge({
      columnSortStates: Map({ [sanitizedColName]: SortUtils.getNext(currentState) }),
      pageNum: 0,
    });
  },

  'Transactions.setPage': (state, { pageNum }) => {
    return state.set('pageNum', pageNum);
  },

  'Transactions.setPageSize': (state, { pageSize }) => {
    return resetPage(state).merge({ pageSize });
  },

  'Transactions.setActiveRowNumber': (state, { rowNum }) => {
    // @ts-expect-error can be fixed when store is properly typed s.t. state.rows isn't a List<any>
    return state.set('activeRecordId', state.rows.get(rowNum).recordId);
  },

  'Transactions.setSelectedRowNumbers': (state, { rowNums }) => {
    return state.set('selectedRecordIds', rowNums.map(i => state.rows.get(i).recordId));
  },

  'Transactions.openCommentForm': (state) => {
    return state
      .merge({ sidebarTabKey: SidebarTabs.ACTIVITY, expanded: true })
      .update('commentFocusSequence', n => n + 1);
  },

  'Transactions.toggleExpandSidebar': (state) => {
    return state.update('expanded', b => !b);
  },

  'Transactions.toggleExpandFilter': (state) => {
    return state.update('filterExpanded', b => !b);
  },

  'Transactions.showSidebarTab': (state, { sidebarTabKey }) => {
    return state.merge({ sidebarTabKey, expanded: true });
  },

  'Transactions.setShowUpdateCategorizationsWarningDialog': (state, { show, doApplyAndUpdate }) => {
    return state.merge({ showUpdateCategorizationsWarningDialog: show, applyFeedbackAndUpdateResults: _.isUndefined(doApplyAndUpdate) ? state.applyFeedbackAndUpdateResults : doApplyAndUpdate });
  },

  'Transactions.setQueryString': (state, { queryString }) => {
    return resetPage(state).merge({ queryString });
  },

  'Transactions.comment': (state, { id }) => {
    return addCurrentlyProcessingIds(state, Set.of(id));
  },

  'Transactions.commentCompleted': (state, { id }) => {
    return reloadRecords(setCurrentlyProcessingIdsToReload(state, Set.of(id)));
  },

  'Transactions.commentFailed': (state, { id }) => {
    return reloadRecords(setCurrentlyProcessingIdsToReload(state, Set.of(id)));
  },

  'Transactions.editComment': (state, { id }) => {
    return addCurrentlyProcessingIds(state, Set.of(id));
  },

  'Transactions.editCommentCompleted': (state, { id }) => {
    return reloadRecords(setCurrentlyProcessingIdsToReload(state, Set.of(id)));
  },

  'Transactions.deleteComment': (state, { id }) => {
    return addCurrentlyProcessingIds(state, Set.of(id));
  },

  'Transactions.deleteCommentCompleted': (state, { id }) => {
    return reloadRecords(setCurrentlyProcessingIdsToReload(state, Set.of(id)));
  },

  'Transactions.suggestCategorizations': (state) => {
    return state.merge({ suggestionLaunching: true });
  },

  'Jobs.jobUpdate': (state) => {
    return state.set('suggestionLaunching', false);
  },

  'Transactions.suggestCategorizationsFailed': (state) => {
    return state.merge({ suggestionLaunching: false });
  },

  'Transactions.addResponses': (state, { ids }) => {
    return addCurrentlyProcessingIds(state, ids);
  },

  'Transactions.addResponsesCompleted': (state, { ids }) => {
    return reloadRecords(clearSelection(setCurrentlyProcessingIdsToReload(state, ids)));
  },

  'Transactions.addResponsesFailed': (state, { ids }) => {
    return reloadRecords(clearSelection(setCurrentlyProcessingIdsToReload(state, ids)));
  },

  'Transactions.skipFeedback': (state, { ids }) => {
    return addCurrentlyProcessingIds(state, ids);
  },

  'Transactions.skipFeedbackCompleted': (state, { ids }) => {
    return reloadRecords(clearSelection(setCurrentlyProcessingIdsToReload(state, ids)));
  },

  'Transactions.skipFeedbackFailed': (state, { ids }) => {
    return reloadRecords(clearSelection(setCurrentlyProcessingIdsToReload(state, ids)));
  },

  'Transactions.addCategorizations': (state, { ids }) => {
    return addCurrentlyProcessingIds(state, ids);
  },

  'Transactions.addCategorizationsCompleted': (state, { ids }) => {
    return reloadRecords(clearSelection(setCurrentlyProcessingIdsToReload(state, ids)));
  },

  'Transactions.addCategorizationsFailed': (state, { ids }) => {
    return reloadRecords(clearSelection(setCurrentlyProcessingIdsToReload(state, ids)));
  },

  'Transactions.deleteCategorization': (state, { ids }) => {
    return addCurrentlyProcessingIds(state, ids);
  },

  'Transactions.deleteCategorizationsCompleted': (state, { ids }) => {
    return reloadRecords(clearSelection(setCurrentlyProcessingIdsToReload(state, ids)));
  },

  'Transactions.deleteCategorizationsFailed': (state, { ids }) => {
    return reloadRecords(clearSelection(setCurrentlyProcessingIdsToReload(state, ids)));
  },

  'Transactions.assign': (state, { ids }) => {
    return addCurrentlyProcessingIds(state, ids);
  },

  'Transactions.assignCompleted': (state, { ids }) => {
    return reloadRecords(clearSelection(setCurrentlyProcessingIdsToReload(state, ids)));
  },

  'Transactions.assignFailed': (state, { ids }) => {
    return reloadRecords(clearSelection(setCurrentlyProcessingIdsToReload(state, ids)));
  },

  'Jobs.classificationCompleted': (state) => {
    return reloadRecords(state);
  },

  'Transactions.feedbackSummaryFetchCompleted': (state, { numAssigned, numResponded }) => {
    return state.set('feedbackSummaryNumAssigned', numAssigned).set('feedbackSummaryNumResponded', numResponded);
  },

  'Transactions.openCategoryFilter': (state) => {
    return state.set('showCategoryFilter', true);
  },

  'Transactions.cancelCategoryFilter': (state) => {
    return state.set('showCategoryFilter', false);
  },

  'Transactions.openCategorizeDialog': (state, { categorizeDialogRecords }) => {
    return state.merge({ showCategorizeDialog: true, categorizeDialogRecords });
  },

  'Transactions.cancelCategorizeDialog': (state) => {
    return state.delete('showCategorizeDialog').delete('categorizeDialogRecords');
  },

  'Transactions.openAssignedToUsersFilter': (state) => {
    return state.set('showAssignedToUserFilter', true);
  },

  'Transactions.openCategorizedByUsersFilter': (state) => {
    return state.set('showCategorizedByUsersFilter', true);
  },

  'Transactions.cancelAssignedToUsersFilter': (state) => {
    return state.set('showAssignedToUserFilter', false);
  },

  'Transactions.cancelCategorizedByUsersFilter': (state) => {
    return state.set('showCategorizedByUsersFilter', false);
  },

  'Transactions.addCategoryFilter': (state, { categoryId }) => {
    return state.merge({ showCategoryFilter: false, categoryIds: state.categoryIds.push(categoryId) });
  },

  'Transactions.removeCategoryFilter': (state, { categoryId }) => {
    return state.merge({ showCategoryFilter: false, categoryIds: state.categoryIds.filter(id => id !== categoryId) });
  },

  'Transactions.openDatasetFilterDialog': (state) => {
    return state.set('datasetFilterDialogVisible', true);
  },
  'Transactions.closeDatasetFilterDialog': (state) => {
    return state.set('datasetFilterDialogVisible', false);
  },
  'Transactions.checkModel': (state) => {
    return state.merge({ modelExists: false, loadingModel: true, modelRecipeId: undefined });
  },
  'Transactions.checkModelCompleted': (state, { exists, recipeId }) => {
    return state.merge({ modelExists: exists, loadingModel: false, modelRecipeId: recipeId });
  },
  'Transactions.checkModelFailed': (state, { recipeId }) => {
    return state.merge({ modelExists: false, loadingModel: false, modelRecipeId: recipeId });
  },

  'Transactions.openGeospatialDetailsSidebar': (state, { attributeName }) => {
    return state.merge({
      sidebarTabKey: SidebarTabs.DETAILS,
      expanded: true,
      activeGeospatialAttribute: new ActiveGeospatialAttribute({
        name: attributeName,
        isOpenDialog: false,
      }),
    });
  },
  'Transactions.closeGeospatialDetailsSidebar': (state) => {
    return state.merge({ activeGeospatialAttribute: null });
  },

  'Transactions.openGeospatialDetailsDialog': (state) => {
    const { activeGeospatialAttribute } = state;
    if (activeGeospatialAttribute === null) {
      return state;
    }
    return state.merge({
      activeGeospatialAttribute: activeGeospatialAttribute
        .set('isOpenDialog', true),
    });
  },
  'Transactions.closeGeospatialDetailsDialog': (state) => {
    const { activeGeospatialAttribute } = state;
    if (activeGeospatialAttribute === null) {
      return state;
    }
    return state.merge({
      activeGeospatialAttribute: activeGeospatialAttribute
        .set('isOpenDialog', false),
    });
  },
};
