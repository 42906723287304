import { is } from 'immutable';

import createLoader from '../utils/createLoader';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { fetchAllUnifiedAttributes } from './SchemaMappingAsync';
import { getAllUnifiedFilterInfo } from './SchemaMappingStore';

const AllUnifiedAttributesLoader = createLoader((state) => {
  const { schemaMapping: { allUnifiedLoading, allUnifiedLoadedFilterInfo } } = state;
  const projectInfo = selectActiveProjectInfo(state);
  return {
    canFetch: !!projectInfo && !!projectInfo.unifiedDataset?.name,
    shouldFetch: !is(getAllUnifiedFilterInfo(state), allUnifiedLoadedFilterInfo),
    loading: allUnifiedLoading,
  };
}, {
  onFetch: fetchAllUnifiedAttributes,
}, 'AllUnifiedAttributesLoader');

export default AllUnifiedAttributesLoader;
