import Model from '../models/Model';
import { ArgTypes } from '../utils/ArgValidation';
import { UPLOAD_TAXONOMY_COMPLETED } from './TaxonomyUploadActionTypes';

export const initialState = new (Model({
  errorMessage: { type: ArgTypes.nullable(ArgTypes.string) },
  fileName: { type: ArgTypes.nullable(ArgTypes.string) },
  isLoading: { type: ArgTypes.bool, defaultValue: false },
  selectedFile: { type: ArgTypes.nullable(ArgTypes.object) },
  showDialog: { type: ArgTypes.bool, defaultValue: false },
}))();

export const reducers = {
  'TaxonomyUpload.reset': state => {
    return state.clear();
  },

  'TaxonomyUpload.fileUpdated': (state, { selectedFile }) => {
    return state.merge({
      selectedFile: selectedFile || state.selectedFile,
      fileName: selectedFile ? selectedFile.name : state.fileName,
    });
  },

  'TaxonomyUpload.uploadTaxonomy': state => {
    return state.set('isLoading', true);
  },

  [UPLOAD_TAXONOMY_COMPLETED]: state => {
    return state.clear();
  },

  'TaxonomyUpload.uploadTaxonomyFailed': (state, { errorMessage }) => {
    return state.merge({
      isLoading: false,
      errorMessage,
    });
  },

  'TaxonomyUpload.showDialog': state => {
    return state.set('showDialog', true);
  },

  'TaxonomyUpload.hideDialog': state => {
    return state.set('showDialog', false);
  },
};
