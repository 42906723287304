import { Set } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import Button from '../../components/Button';
import ButtonToolbar from '../../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../../components/Dialog/Dialog';
import Highlighter from '../../components/Highlighter';
import ListSelector from '../../components/ListSelector/ListSelector';
import TamrIcon from '../../components/TamrIcon';
import TooltipTrigger from '../../components/TooltipTrigger';
import {
  ABSOLUTE_COSINE,
  ABSOLUTE_SIMILARITIES,
} from '../../schema-mapping/constants/SimilarityFunction';
import { getDedupInfo } from '../../utils/Selectors';
import { textMatch } from '../../utils/Values';
import AttributeSimilarityFilterModel from '../AttributeSimilarityFilterModel';
import style from './AttributeSelectionDialog.module.scss';

const defaultOpenFilterModel = (attributeName) => {
  return new AttributeSimilarityFilterModel({
    attributeName,
    lowerBound: 0.0,
    upperBound: 10.0,
    isActive: true,
    lowerBoundIsInclusive: true,
    upperBoundIsInclusive: true,
    comparingAbsoluteDifference: true,
    comparingAbsoluteCosine: false,
    includeNulls: false,
    includeRange: true,
  });
};

const defaultClosedFilterModel = (attributeName) => {
  return new AttributeSimilarityFilterModel({
    attributeName,
    lowerBound: 0.0,
    upperBound: 1.0,
    isActive: true,
    lowerBoundIsInclusive: true,
    upperBoundIsInclusive: true,
    comparingAbsoluteDifference: false,
    comparingAbsoluteCosine: false,
    includeNulls: false,
    includeRange: true,
  });
};

const defaultAbsCosine = (attributeName) => {
  return new AttributeSimilarityFilterModel({
    attributeName,
    lowerBound: 0.0,
    upperBound: 10.0,
    isActive: true,
    lowerBoundIsInclusive: true,
    upperBoundIsInclusive: true,
    comparingAbsoluteDifference: false,
    comparingAbsoluteCosine: true,
    includeNulls: false,
    includeRange: true,
  });
};

const AttributeSelectionDialog = connect((state) => {
  const { recordPairs: { showingAddAttributeSimilarityFilterDialog, attributeSimilarityFilterStates } } = state;
  const dedupInfo = getDedupInfo(state);
  return {
    attributes: new Set(dedupInfo ? dedupInfo.includedFields : []),
    show: showingAddAttributeSimilarityFilterDialog,
    attributeSimilarityFilterStates,
    dedupInfo,
  };
}, {
  onHide: () => ({ type: 'RecordPairs.closeAddAttributeSimilarityFilterDialog' }),
  onAddFilters: (filters) => ({ type: 'RecordPairs.addAttributeSimilarityFilters', filters }),
})(class AttributeSelectionDialog extends React.Component {
  static propTypes = {
    attributes: ImmutablePropTypes.setOf(PropTypes.string.isRequired).isRequired,
    dedupInfo: PropTypes.object,
    onAddFilters: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
  };

  state = {
    selectedAttributes: new Set(),
    searchValue: '',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { attributeSimilarityFilterStates, show } = this.props;
    if (!show && nextProps.show) {
      this.setState({ selectedAttributes: attributeSimilarityFilterStates.keySeq().toSet() });
    }
  }

  resetState = () => {
    this.setState({
      selectedAttributes: new Set(),
      searchValue: '',
    });
  };

  hide = () => {
    this.props.onHide();
    this.resetState();
  };

  onAddFilters = () => {
    const { onAddFilters, dedupInfo } = this.props;
    const { selectedAttributes } = this.state;


    const newFilters = selectedAttributes.map(attributeName => {
      const signalType = dedupInfo.signalTypes[attributeName];

      return signalType === ABSOLUTE_COSINE ? defaultAbsCosine(attributeName) : (
        ABSOLUTE_SIMILARITIES.includes(signalType)
          ? defaultOpenFilterModel(attributeName)
          : defaultClosedFilterModel(attributeName)
      );
    });
    onAddFilters(newFilters);
    this.hide();
  };

  onChange = (changedData) => {
    let { selectedAttributes } = this.state;
    changedData.forEach((selected, attribute) => {
      if (selected) {
        selectedAttributes = selectedAttributes.add(attribute);
      } else {
        selectedAttributes = selectedAttributes.remove(attribute);
      }
    });
    this.setState({ selectedAttributes });
  };

  render() {
    const { attributes } = this.props;
    const { searchValue } = this.state;
    const selectorValues = attributes.filter(attribute => !searchValue || textMatch(attribute, searchValue))
      .sortBy(attribute => attribute)
      .map(attribute => {
        return {
          value: attribute,
          selected: this.state.selectedAttributes.contains(attribute),
          label: (
            <span className={style.dataColumn}>
              <Highlighter fullText={attribute} highlightText={searchValue} />
              {
                // All attributes in this list ar ML attributes so show the icon for all attributes
              }
              <TooltipTrigger placement="top" content="This attribute is included in machine learning">
                <TamrIcon size={12} iconName="tamr-icon-ml-on" />
              </TooltipTrigger>
            </span>
          ),
        };
      }).toList();
    return (
      <Dialog
        className="attribute-selection-dialog"
        show={this.props.show}
        onHide={this.hide}
        title="Select Attributes"
        dialogStyle={DialogStyle.PRIMARY}
        body={(
          <ListSelector
            values={selectorValues}
            enableSearch
            height={250}
            onSelect={this.onChange}
            onSearch={(value) => this.setState({ searchValue: value })}
            searchValue={searchValue}
          />
        )}
        footer={(
          <ButtonToolbar>
            <Button
              onClick={this.hide}
              buttonType="Secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={this.onAddFilters}
            >
              Add filters
            </Button>
          </ButtonToolbar>
        )}
      />
    );
  }
});

export default AttributeSelectionDialog;
