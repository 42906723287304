import ServiceProxy from '../api/ServiceProxy';
import MinimalAuthUser from '../models/MinimalAuthUser';
import { AppThunkDispatch } from '../stores/AppAction';
import { jsonHeadersWithRevalidate, toJSON } from '../utils/Api';
import { LOGIN_COMPLETED, LOGIN_FAILED } from './AuthActionTypes';

/**
 * Get the latest data for the logged-in user and store it in the AuthStore
 */
export const reloadAuthUser = async (dispatch: AppThunkDispatch) => {
  return fetch(ServiceProxy.user('/auth/user'),
    { headers: jsonHeadersWithRevalidate, method: 'GET' })
    .then(toJSON)
    .then(data => MinimalAuthUser.fromJSON(data))
    .then((authorizedUser) => {
      dispatch({
        type: LOGIN_COMPLETED,
        authorizedUser,
      });
    },
    )
    .catch((response) => dispatch({
      type: LOGIN_FAILED,
      response,
    }));
};

export function revalidateTransformAuth(): Promise<Response> {
  // To revalidate the user in auth cache we must hit some API endpoint. This endpoint is low-impact
  return fetch(
    ServiceProxy.transform(
      '/get-dataset-pk'),
    { method: 'POST', body: JSON.stringify([]), headers: jsonHeadersWithRevalidate },
  );
}

export function revalidateDatasetAuth(): Promise<Response> {
  // To revalidate the user in auth cache we must hit some API endpoint. This endpoint is low-impact
  return fetch(ServiceProxy.dataset('/jobs?limit=1'),
    { headers: jsonHeadersWithRevalidate, method: 'GET' });
}

export function revalidateRecipeAuth(): Promise<Response> {
  // To revalidate the user in auth cache we must hit some API endpoint. This endpoint is low-impact
  return fetch(ServiceProxy.recipe('/modules?limit=1'),
    { headers: jsonHeadersWithRevalidate, method: 'GET' });
}

export function revalidateProcurifyAuth(): Promise<Response> {
  // To revalidate the user in auth cache we must hit some API endpoint. This endpoint is low-impact
  return fetch(ServiceProxy.procure('/config/sso-enabled'),
    { headers: jsonHeadersWithRevalidate, method: 'GET' });
}

export function revalidateDedupAuth(): Promise<Response> {
  // To revalidate the user in auth cache we must hit some API endpoint. This endpoint is low-impact
  return fetch(ServiceProxy.dedup('/config/cluster-confidence-thresholds'),
    { headers: jsonHeadersWithRevalidate, method: 'GET' });
}

/**
 * Each microservice has its own auth cache. The cache is refreshed when an api for that
 * microservice is called with the header 'X-Tamr-Auth': 'must-revalidate'
 * (or after a configured refresh time). Here we refresh auth for Auth, Dataset, and Recipe
 */
export async function refreshAuthCache(dispatch: AppThunkDispatch): Promise<any> {
  await reloadAuthUser(dispatch);
  return Promise.all([
    revalidateDatasetAuth(),
    revalidateRecipeAuth(),
    revalidateTransformAuth(),
    revalidateDedupAuth(),
    revalidateProcurifyAuth(),
  ]);
}
