import { List, Set } from 'immutable';
import _ from 'underscore';

import { ORIGIN_ENTITY_ID, ORIGIN_SOURCE_NAME, TAMR_ID } from '../constants/ElasticConstants';
import Clause from '../models/Clause';
import ClauseEntry from '../models/ClauseEntry';
import Dnf from '../models/Dnf';
import Model from '../models/Model';
import PairEstimates from '../models/PairEstimates';
import Recipe, { DEDUP_INFO_METADATA_KEY } from '../models/Recipe';
import { ArgTypes, checkArg, checkReturn } from '../utils/ArgValidation';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { getPath } from '../utils/Values';
import FieldSignal from './FieldSignal';

const DEFAULT_SIMILARITY_FUNCTION = 'COSINE';
const DEFAULT_TOKEN_WEIGHTING = 'IDF';
const DEFAULT_FIELD_NAME = '';


const startClauseEntry = new ClauseEntry({ fieldName: DEFAULT_FIELD_NAME, similarityFunction: DEFAULT_SIMILARITY_FUNCTION, tokenizerConfig: 'DEFAULT', tokenWeighting: 'IDF', threshold: 1.0 });
const startClause = new Clause({ clauseEntries: List.of(startClauseEntry), active: true });

export const getFieldSignalsFromRecipeMetadata = checkReturn(ArgTypes.orUndefined(ArgTypes.Immutable.set.of(ArgTypes.instanceOf(FieldSignal))), ({ metadata, unifiedAttributes }) => {
  checkArg({ metadata }, ArgTypes.Immutable.map);
  checkArg({ unifiedAttributes }, ArgTypes.Immutable.list);

  const signalTypes = getPath(metadata, DEDUP_INFO_METADATA_KEY, 'signalTypes') || {};
  const tokenizerConfig = getPath(metadata, DEDUP_INFO_METADATA_KEY, 'tokenizerConfig') || {};
  const tokenWeightings = getPath(metadata, DEDUP_INFO_METADATA_KEY, 'tokenWeightings') || {};
  return Set(unifiedAttributes).filterNot(name => [ORIGIN_SOURCE_NAME, ORIGIN_ENTITY_ID, TAMR_ID].includes(name)).map(fieldName => {
    return new FieldSignal({
      fieldName,
      similarityFunction: signalTypes[fieldName] || DEFAULT_SIMILARITY_FUNCTION,
      tokenWeighting: tokenWeightings[fieldName] || DEFAULT_TOKEN_WEIGHTING,
      tokenizerConfig: tokenizerConfig[fieldName],
    });
  });
});

// returns FieldSignals for active recipe
export const getAvailableFields = checkReturn(ArgTypes.Immutable.set.of(ArgTypes.instanceOf(FieldSignal)), (state) => {
  const metadata = getPath(selectActiveProjectInfo(state), 'recipe', 'metadata');
  const unifiedAttributes = getPath(selectActiveProjectInfo(state), 'unifiedDataset', 'fields');
  return (metadata && unifiedAttributes) ? getFieldSignalsFromRecipeMetadata({ metadata, unifiedAttributes }) : Set();
});

export const getRemovedFieldClauseEntries = checkReturn(ArgTypes.Immutable.set.of(ArgTypes.instanceOf(ClauseEntry)), ({ availableFields, clause }) => {
  checkArg({ availableFields }, ArgTypes.Immutable.set.of(ArgTypes.instanceOf(FieldSignal)));
  checkArg({ clause }, ArgTypes.instanceOf(Clause));
  const availableFieldNames = availableFields.map(f => f.fieldName);
  return clause.clauseEntries.filter(ce => ce.fieldName !== DEFAULT_FIELD_NAME && !availableFieldNames.has(ce.fieldName)).toSet();
});

export const getRemovedFieldNames = checkReturn(ArgTypes.Immutable.set.of(ArgTypes.string), (state) => {
  const { dnfBuilder: { dnf: { clauses } } } = state;
  const availableFields = getAvailableFields(state);
  return clauses.flatMap(clause => getRemovedFieldClauseEntries({ availableFields, clause }).map(ce => ce.fieldName)).toSet();
});

export const getDnfFromRecipeMetadata = checkReturn(ArgTypes.orUndefined(ArgTypes.Immutable.list.of(ArgTypes.instanceOf(Clause))), (metadata) => {
  checkArg({ metadata }, ArgTypes.Immutable.map);
  const clausesJSON = getPath(metadata, DEDUP_INFO_METADATA_KEY, 'dnf', 'clauses');
  let clauses;
  if (clausesJSON) {
    clauses = new List(clausesJSON.map(clause => {
      // for back-compat need to be able to read List<List<ClauseEntry>> and List<Clause> forms
      const clauseIsArray = _.isArray(clause);
      const clauseEntriesJSON = clauseIsArray ? clause : clause.clauseEntries;
      const clauseEntries = new List(clauseEntriesJSON.map(ce => new ClauseEntry(ce)));
      const active = clauseIsArray ? true : !!clause.active;
      return new Clause({ clauseEntries, active });
    }));
  }
  return clauses;
});

export const getFieldNamesUsedByDnf = checkReturn(ArgTypes.Immutable.set.of(ArgTypes.string), (recipe) => {
  checkArg({ recipe }, ArgTypes.instanceOf(Recipe));
  const clauses = getDnfFromRecipeMetadata(recipe.metadata) || List();
  return clauses.flatMap(clause => clause.clauseEntries.map(ce => ce.fieldName)).toSet();
});

export const initialState = new (Model({
  sidebarExpanded: {
    type: ArgTypes.bool,
    defaultValue: false,
  },
  sourcesToBlockSelfMatch: { // list of dataset names to ignore. undefined is allowed and meaningful
    type: ArgTypes.Immutable.list.of(ArgTypes.nullable(ArgTypes.string)),
    defaultValue: new List(),
  },
  sourcesToBlockSelfCluster: { // list of dataset names to ignore. undefined is allowed and meaningful
    type: ArgTypes.Immutable.list.of(ArgTypes.nullable(ArgTypes.string)),
    defaultValue: new List(),
  },
  showUpdateWarningDialog: {
    type: ArgTypes.bool,
    defaultValue: false,
  },
  generateLaunching: { type: ArgTypes.bool, defaultValue: false },
  estimateLaunching: { type: ArgTypes.bool, defaultValue: false },
  estimateJobId: { type: ArgTypes.orUndefined(ArgTypes.number), defaultValue: undefined },
  dnf: { type: ArgTypes.instanceOf(Dnf), defaultValue: new Dnf({ clauses: List.of(startClause) }) },
  dnfLoading: { type: ArgTypes.bool, defaultValue: false },
  dnfLoadedRecipeId: { type: ArgTypes.nullable(ArgTypes.number) },
  pairEstimates: { type: ArgTypes.orUndefined(ArgTypes.instanceOf(PairEstimates)), defaultValue: undefined },
  pairEstimatesLoading: { type: ArgTypes.bool, defaultValue: false },
  shouldFetchPairEstimates: { type: ArgTypes.bool, defaultValue: true },
}))();

export const reducers = {
  'DnfBuilder.updateInitialDedupInfo': (state, { recipe }) => {
    checkArg({ recipe }, ArgTypes.instanceOf(Recipe));
    // noDedupWithinSources
    const noDedupWithinSources = getPath(recipe.metadata.get(DEDUP_INFO_METADATA_KEY), 'noDedupWithinSources');
    let sourcesToBlockSelfMatch = new List();
    if (noDedupWithinSources) {
      sourcesToBlockSelfMatch = new List(noDedupWithinSources);
    }
    // noClusteringWithinSources
    const noClusteringWithinSources = getPath(recipe.metadata.get(DEDUP_INFO_METADATA_KEY), 'noClusteringWithinSources');
    let sourcesToBlockSelfCluster = new List();
    if (noDedupWithinSources) {
      sourcesToBlockSelfCluster = new List(noClusteringWithinSources);
    }
    return state.merge({ sourcesToBlockSelfMatch, sourcesToBlockSelfCluster });
  },

  'DnfBuilder.addNewClause': (state) => { // add default new clause
    return state.updateIn(['dnf', 'clauses'], c => c.push(startClause));
  },

  'DnfBuilder.addNewClauseEntry': (state) => { // add default new clause entry to last clause
    return state.updateIn(['dnf', 'clauses', state.dnf.clauses.size - 1, 'clauseEntries'], ce =>
      ce.push(startClauseEntry),
    );
  },

  // Move an entry from one clause to another
  'DnfBuilder.moveClauseEntry': (state, { sourceClauseIndex, sourceClauseEntryIndex, destClauseIndex, destClauseEntryIndex }) => {
    const { dnf: { clauses } } = state;
    const clauseEntry = clauses.get(sourceClauseIndex).clauseEntries.get(sourceClauseEntryIndex);

    const newClauses = clauses
      // update the source clause by removing the entry to be moved
      .update(sourceClauseIndex, sourceClause => sourceClause.update('clauseEntries', entries => entries.remove(sourceClauseEntryIndex)))
      // update the destination clause (possible the same as the source clause) by inserting the entry to be moved
      .update(destClauseIndex, destClause => destClause.update('clauseEntries', entries => entries.insert(destClauseEntryIndex, clauseEntry)))
      // remove any clauses that are empty after moving the clause entry
      .filter(c => !c.clauseEntries.isEmpty());

    return state.mergeIn(['dnf'], { clauses: newClauses });
  },

  'DnfBuilder.reset': (state) => { // reset dnf builder to initial state
    return state.delete('dnf').delete('dnfLoadedRecipeId');
  },

  // remove a specific clause entry
  'DnfBuilder.removeClauseEntry': (state, { clauseIndex, clauseEntryIndex }) => {
    const { dnf: { clauses } } = state;
    const clause = clauses.get(clauseIndex);
    const updatedClause = clause.set('clauseEntries', clause.clauseEntries.delete(clauseEntryIndex));
    if (updatedClause.clauseEntries.isEmpty()) {
      return state.updateIn(['dnf', 'clauses'], c => c.delete(clauseIndex));
    }
    return state.updateIn(['dnf', 'clauses'], c => c.set(clauseIndex, updatedClause));
  },

  'DnfBuilder.setClause': (state, { clause, clauseIndex }) => {
    return state.updateIn(['dnf', 'clauses'], c => c.set(clauseIndex, clause));
  },

  'DnfBuilder.setClauseEntry': (state, { clauseIndex, clauseEntry, clauseEntryIndex }) => {
    return state.setIn(['dnf', 'clauses', clauseIndex, 'clauseEntries', clauseEntryIndex], clauseEntry);
  },

  // Split a clause after the entry positioned at clauseEntryOrder
  'DnfBuilder.splitClause': (state, { clauseIndex, clauseEntryIndex }) => {
    const { dnf: { clauses } } = state;
    let initClause = clauses.get(clauseIndex);
    let newClause = clauses.get(clauseIndex);
    initClause = new Clause({ clauseEntries: initClause.clauseEntries.slice(0, clauseEntryIndex + 1), active: true });
    newClause = new Clause({ clauseEntries: newClause.clauseEntries.skip(clauseEntryIndex + 1), active: true });
    let newClauses = clauses.set(clauseIndex, initClause);
    newClauses = newClauses.insert(clauseIndex + 1, newClause);
    return state.setIn(['dnf', 'clauses'], newClauses);
  },

  'DnfBuilder.submitGenerateEstimates': (state) => {
    return state.merge({ estimateLaunching: true, pairEstimates: null });
  },

  'DnfBuilder.submitGenerateEstimatesCompleted': (state, { job }) => {
    return state.merge({ estimateLaunching: false, estimateJobId: job.id.id });
  },

  'DnfBuilder.submitGenerateEstimatesFailed': (state) => {
    return state.merge({ estimateLaunching: false });
  },

  'DnfBuilder.generatePairs': (state) => {
    return state.merge({ showUpdateWarningDialog: false, generateLaunching: true });
  },

  'Jobs.jobUpdate': (state, { job }) => {
    const estimateJobDone =
      !!state.estimateJobId && state.estimateJobId === job.id.id && job.data.status.state === 'SUCCEEDED';

    return state.merge({ generateLaunching: false,
      estimateLaunching: false,
      shouldFetchPairEstimates: estimateJobDone,
      estimateJobId: estimateJobDone ? undefined : state.estimateJobId,
    });
  },

  'DnfBuilder.generatePairsFailed': (state) => {
    return state.merge({ generateLaunching: false });
  },

  'DnfBuilder.addSourceToBlockSelfMatch': (state) => {
    return state.update('sourcesToBlockSelfMatch', s => s.push(undefined));
  },

  'DnfBuilder.setSourceToBlockSelfMatch': (state, { index, datasetName }) => {
    if (!state.sourcesToBlockSelfMatch.contains(datasetName)) {
      return state.update('sourcesToBlockSelfMatch', s => s.set(index, datasetName));
    }
    return state;
  },

  'DnfBuilder.removeSourceToBlockSelfMatch': (state, { index }) => {
    return state.update('sourcesToBlockSelfMatch', s => s.delete(index));
  },

  'DnfBuilder.addSourceToBlockSelfCluster': (state) => {
    return state.update('sourcesToBlockSelfCluster', s => s.push(undefined));
  },

  'DnfBuilder.setSourceToBlockSelfCluster': (state, { index, datasetName }) => {
    if (!state.sourcesToBlockSelfCluster.contains(datasetName)) {
      return state.update('sourcesToBlockSelfCluster', s => s.set(index, datasetName));
    }
    return state;
  },

  'DnfBuilder.removeSourceToBlockSelfCluster': (state, { index }) => {
    return state.update('sourcesToBlockSelfCluster', s => s.delete(index));
  },

  'DnfBuilder.toggleExpandSidebar': (state) => {
    return state.update('sidebarExpanded', s => !s);
  },

  'DnfBuilder.showUpdateWarningDialog': (state) => {
    return state.set('showUpdateWarningDialog', true);
  },

  'DnfBuilder.hideUpdateWarningDialog': (state) => {
    return state.set('showUpdateWarningDialog', false);
  },

  'Dnf.fetchDnf': (state) => {
    return state.set('dnfLoading', true);
  },

  'Dnf.fetchDnfCompleted': (state, { dnf, recipeId }) => {
    return state.merge({ dnf, dnfLoading: false, dnfLoadedRecipeId: recipeId });
  },
  'Dnf.fetchDnfFailed': (state, { recipeId }) => {
    return state.merge({ dnfLoading: false, dnfLoadedRecipeId: recipeId });
  },
  'PairEstimates.fetch': (state) => {
    return state.merge({ pairEstimatesLoading: true });
  },

  'PairEstimates.fetchCompleted': (state, { pairEstimates }) => {
    return state.merge({ pairEstimates, pairEstimatesLoading: false, shouldFetchPairEstimates: false });
  },

  'PairEstimates.fetchFailed': (state) => {
    return state.merge({ pairEstimatesLoading: false, pairEstimates: undefined });
  },

  'DnfBuilder.updatePairGenerationModel': (state) => {
    // TODO: Rename once we stop using "DNF". Replace with pairGenerationModelLoading (?)
    return state.set('dnfLoading', true);
  },
  'DnfBuilder.updatePairGenerationModelCompleted': (state, { recipeDoc }) => {
    return state.merge({
      dnf: Dnf.fromJSON(recipeDoc.data.metadata.get(DEDUP_INFO_METADATA_KEY).dnf),
      dnfLoading: false,
    });
  },
  'DnfBuilder.updatePairGenerationModelFailed': (state) => {
    return state.set('dnfLoading', false);
  },
};
