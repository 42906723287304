import React from 'react';

import Selector, { SelectorValuesElementType } from '../../components/Input/Selector';
import styles from './SelectFilter.module.scss';


interface SelectFilterProps<ValueType> {
  label: React.ReactNode
  onChange: (value: ValueType) => void,
  value: ValueType,
  values: SelectorValuesElementType<ValueType>[],
}

function SelectFilter<ValueType>({ label, onChange, value, values }: SelectFilterProps<ValueType>) {
  return (
    <div className={styles.container} data-test-element="pairs-filter">
      <span className={styles.selectorLabel}>{label}</span>
      <Selector<ValueType>
        onChange={onChange}
        values={values}
        value={value}
      />
    </div>
  );
}

export default SelectFilter;
