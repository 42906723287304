import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import ScoreIcon from '../components/ScoreIcon';
import TamrIcon from '../components/TamrIcon';
import RecordPairWithData from '../models/RecordPairWithData';
import ScoreThresholds, { Symbol } from '../models/ScoreThresholds';
import { AppState } from '../stores/MainStore';
import PRODUCT_NAME from '../utils/ProductName';
import RecordPairCell from './RecordPairCell';
import styleConstants from './RecordPairConstants.module.scss';
import style from './RecordPairTamrResponseCell.module.scss';


const ICON_SIZE = parseInt(styleConstants.cellIconSize, 10);

const ICON_NAMES = {
  MATCH: 'match',
  NON_MATCH: 'do-not-disturb-alt',
} as const;

export const calculateLabelConfidenceTitle = (suggestedLabelConfidence: number, confidenceThresholds: ScoreThresholds) => {
  let titlePrefix;
  const symbol = confidenceThresholds.getSymbol(suggestedLabelConfidence);
  if (symbol === Symbol.H) {
    titlePrefix = 'High';
  } else if (symbol === Symbol.M) {
    titlePrefix = 'Medium';
  } else {
    titlePrefix = 'Low';
  }
  return `${titlePrefix} ${PRODUCT_NAME} Label Confidence - Score: ${suggestedLabelConfidence.toPrecision(3)}`;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RecordPairTamrResponseCell = connect(({ config: { pairConfidenceThresholds } }: AppState, ownProps: { pair: RecordPairWithData }) => {
  return { confidenceThresholds: pairConfidenceThresholds };
})(({ confidenceThresholds, pair }) => {
  const { suggestedLabel, suggestedLabelConfidence } = pair;
  const confidenceTitle = (suggestedLabelConfidence && confidenceThresholds)
    ? calculateLabelConfidenceTitle(suggestedLabelConfidence, confidenceThresholds)
    : undefined;

  return (
    <RecordPairCell innerClassName={style.component}>
      <div className={style.iconContainer} title={confidenceTitle}>
        {suggestedLabel && (
          <TamrIcon
            className={classNames(style.label, suggestedLabel === 'MATCH' ? style.match : style.noMatch)}
            size={ICON_SIZE}
            iconName={ICON_NAMES[suggestedLabel]}
          />
        )}
        {confidenceThresholds && (<ScoreIcon
          className={style.confidence}
          score={suggestedLabelConfidence || undefined}
          scoreThresholds={confidenceThresholds}
        />)}
      </div>
      <div className={style.dividerRight} />
    </RecordPairCell>
  );
});

export default RecordPairTamrResponseCell;
