import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Dataset from '../../models/Dataset';
import Document from '../../models/doc/Document';
import { history } from '../../utils/History';
import DashboardValueCard from '../DashboardValueCard';

const SourceDatasetsCard = _.compose(
  connect((state) => {
    const { allSourceDatasets: { datasets } } = state;
    return { datasets };
  }),
)(class SourceDatasetsCard extends React.Component {
  static propTypes = {
    datasets: ImmutablePropTypes.listOf(Document.propType.withDataType(Dataset)),
    short: PropTypes.bool,
    smRecipeId: PropTypes.number.isRequired,
  };

  static defaultProps = { short: false };

  navigateToDatasets = () => {
    history.push(`/datasets/recipe/${this.props.smRecipeId}`);
  };

  render() {
    const { datasets } = this.props;
    const numSourceDatasets = datasets.size;
    const allFields = datasets.map(d => d.data.fields.size);
    const sumAllFields = allFields.isEmpty() ? 0 : allFields.reduce((a, b) => a + b);
    const numSourceAttributes = numSourceDatasets === 0 ? 0 : sumAllFields;
    const valuesList = Immutable.List.of(
      {
        value: numSourceDatasets,
        nounSingular: 'Dataset',
        nounPlural: 'Datasets',
      },
      {
        value: numSourceAttributes,
        nounSingular: 'Attribute',
        nounPlural: 'Attributes',
      },
    );
    return (
      <DashboardValueCard
        onClick={this.navigateToDatasets}
        short={this.props.short}
        title="SOURCE DATASETS"
        valuesList={valuesList}
      />
    );
  }
});

export default SourceDatasetsCard;
