import React from 'react';
import { connect } from 'react-redux';
import wellknown from 'wellknown';

import TamrIcon from '../components/TamrIcon';
import GeospatialDetailsDialog from '../geospatial/GeospatialDetailsDialog';
import * as geotamr from '../geospatial/GeoTamr';
import LongLatCard from '../geospatial/LongLatCard';
import SidebarMap from '../geospatial/SidebarMap';
import { DEDUP_INFO_METADATA_KEY } from '../models/Recipe';
import {
  GEOSPATIAL_DIRECTIONAL_HAUSDORFF,
  GEOSPATIAL_HAUSDORFF,
  GEOSPATIAL_MIN_DISTANCE,
} from '../schema-mapping/constants/SimilarityFunction';
import { meters } from '../utils/Numbers';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { getPath } from '../utils/Values';
import styles from './RecordPairsSidebarDetailsGeospatial.module.scss';
import { getActivePair } from './RecordPairsStore';

function LegendEntry({ konvaProps, children }) {
  return (
    <div className={styles.legendEntry}>
      <div
        className={styles.legendKey}
        style={{
          backgroundColor: konvaProps.fill,
          border: `1px solid ${konvaProps.stroke}`,
        }}
      />
      {children}
    </div>
  );
}

const topKonvaProps = {
  stroke: '#04344D',
  fill: '#0A608E',
};

const bottomKonvaProps = {
  stroke: '#71B0D1',
  fill: '#000000',
};

function RecordPairsSidebarDetailsGeospatial({
  activePair,
  attributeName,
  signalType,
  closeSubSidebar,
  isOpenDialog,
  openDetailsDialog,
  closeDetailsDialog,
  tileServers,
}) {
  let similarity = activePair.attributeSimilarityScores.get(attributeName);
  similarity = similarity === undefined ? 0 : similarity;

  // convert from WKT to GeoJSON
  const transaction1 = activePair.txn1Data.get(`tamr__${attributeName}`);
  const transaction2 = activePair.txn2Data.get(`tamr__${attributeName}`);
  const dataTopGeoJson = transaction1 ? wellknown.parse(transaction1) : null;
  const dataBottomGeoJson = transaction2 ? wellknown.parse(transaction2) : null;

  // and then from GeoJSON to GeoTamr
  const geoTamrTop = dataTopGeoJson ? geotamr.fromGeoJSON(dataTopGeoJson) : null;
  const geoTamrBottom = dataBottomGeoJson ? geotamr.fromGeoJSON(dataBottomGeoJson) : null;

  return (
    <div className={styles.detailsGeospatial}>
      {GeospatialDetailsDialog({
        attributeName,
        geoTamrs: [geoTamrTop, geoTamrBottom],
        isOpen: isOpenDialog,
        close: closeDetailsDialog,
      })}
      <div onClick={closeSubSidebar} className={styles.navigation}>
        <TamrIcon
          size={14}
          iconName="arrow-back"
          className={styles.backArrow}
        />
        <span>Pair details</span>
      </div>
      <div className={styles.attributeHeader}>
        <div className={styles.attributeHeading}>{attributeName}</div>
        { (signalType === GEOSPATIAL_HAUSDORFF || signalType === GEOSPATIAL_DIRECTIONAL_HAUSDORFF || signalType === GEOSPATIAL_MIN_DISTANCE) &&
          <div className={styles.hausdorff}>
            <div>Distance:</div>
            <div
              title={`${similarity * -1} meters`}
              className={styles.hausdorffDistanceMeters}
            >
              {meters(similarity * -1)}
            </div>
          </div>
        }
      </div>
      {SidebarMap({
        geoTamrs: [geoTamrTop, geoTamrBottom],
        tileServers,
      })}
      <div className={styles.legend}>
        <LegendEntry key="top" konvaProps={topKonvaProps}>
          <div>Top record</div>
        </LegendEntry>
        <LegendEntry key="bottom" konvaProps={bottomKonvaProps}>
          <div>Bottom record</div>
        </LegendEntry>
      </div>
      {
        geoTamrTop ? (
          <div>
            <div className={styles.recordLocationText}>TOP RECORD LOCATION</div>
            {LongLatCard({ geoTamr: geoTamrTop, openDetailsDialog })}
          </div>
        ) : null
      }
      {
        geoTamrBottom ? (
          <div>
            <div className={styles.recordLocationText}>BOTTOM RECORD LOCATION</div>
            {LongLatCard({ geoTamr: geoTamrBottom, openDetailsDialog })}
          </div>
        ) : null
      }
    </div>
  );
}

export default connect(
  state => {
    const { recordPairs, projects: { tileServers } } = state;
    const activePair = getActivePair(recordPairs);
    const attribute = recordPairs.activeGeospatialAttribute;
    const attributeName = attribute.name;

    const projectInfo = selectActiveProjectInfo(state);
    const dedupInfo = getPath(
      projectInfo,
      'recipeDoc',
      'data',
      'metadata',
      DEDUP_INFO_METADATA_KEY,
    );
    const signalType = dedupInfo.signalTypes[attributeName];
    return {
      activePair,
      attributeName,
      isOpenDialog: attribute.isOpenDialog,
      signalType,
      tileServers,
    };
  },
  {
    closeSubSidebar: () => ({ type: 'RecordPairs.closeGeospatialDetails' }),
    openDetailsDialog: () => ({ type: 'RecordPairs.openGeospatialDetailsDialog' }),
    closeDetailsDialog: () => ({ type: 'RecordPairs.closeGeospatialDetailsDialog' }),
  },
)(RecordPairsSidebarDetailsGeospatial);
