import { List } from 'immutable';
import React from 'react';
import { policyString } from '../accessControl/PoliciesList';
import Button from '../components/Button';

import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import TextHeaderCell from '../components/TextHeaderCell';
import AuthorizationPolicy from '../models/AuthorizationPolicy';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import style from './DatasetCatalogTable.module.scss';

const DatasetPoliciesColumn: React.FC<{
  policyDocs: List<Document<AuthorizationPolicy>>
  datasetDocs: List<Document<Dataset>>
  onStartEditingPolicyResourceship: ({ datasetId, datasetName }: {datasetId: string, datasetName: string}) => void
  width: number
}> = ({ policyDocs, datasetDocs, onStartEditingPolicyResourceship, width }) => {
  return (
    <Column
      key="policyResourceship"
      width={width}
      isResizable
      columnKey="policyResourceship"
      flexGrow={1}
      header={() => <TextHeaderCell>Resource in Policy</TextHeaderCell>}
      cell={({ rowIndex }) => {
        const dataset = datasetDocs.get(rowIndex);
        if (!dataset) {
          return (<Cell />);
        }
        const numberOfPolicies = policyDocs.filter(doc => doc.data.hasDataset({ datasetId: dataset.id.id.toString() })).size;
        return (
          <Cell className={style.actionCell}>
            <Button className={style.policyForResource} buttonType="Link" onClick={() => onStartEditingPolicyResourceship({ datasetId: dataset.id.id.toString(), datasetName: dataset.data.name })}>
              {policyString(numberOfPolicies)}
            </Button>
          </Cell>
        );
      }}
    />
  );
};

export default DatasetPoliciesColumn;
