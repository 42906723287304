import React from 'react';

import style from './ConfirmRemoveMappingsTable.module.scss';
import SchemaMappingDialogTable from './SchemaMappingDialogTable';

const ConfirmRemoveMappingsTable = ({ sourceAttributes }) => {
  const mappedSourceAttributes = sourceAttributes.filter(sa => !sa.mappedAttributes.isEmpty());

  if (mappedSourceAttributes.isEmpty()) {
    return <div className={style.tableContainer} />;
  }

  return (
    <div className={style.tableContainer}>
      <SchemaMappingDialogTable
        header1="Source Attributes"
        header2="Mapped To"
        numRows={mappedSourceAttributes.size}
        rowRenderer={(rowIndex) => {
          const { name, datasetName, mappedAttributes } = mappedSourceAttributes.get(rowIndex);
          return [
            <span>{name} <span className={style.sourceDataset}>from {datasetName}</span></span>,
            <span>{mappedAttributes.map((m) => m.name).join(', ')}</span>,
          ];
        }}
      />
    </div>
  );
};

export default ConfirmRemoveMappingsTable;
