import { is, List, Map, OrderedMap, Range, Set } from 'immutable';
import qs from 'query-string';
import _, { isEqual } from 'underscore';

import ConfidenceRange, { ConfidenceRangeE } from '../constants/ConfidenceRange';
import ConfusionMatrixQuadrant, { ConfusionMatrixQuadrantE } from '../constants/ConfusionMatrixQuadrant';
import LabelConsensus, { LabelConsensusE } from '../constants/LabelConsensus';
import { ManualPairLabelTypeE } from '../constants/PairLabelTypes';
import PairsDatasetFilter from '../constants/PairsDatasetFilter';
import RecordPairLabelFilter, { RecordPairLabelFilterE } from '../constants/RecordPairLabelFilter';
import SidebarTabs from '../constants/SidebarTabs';
import SortState, { SortStateValueType } from '../constants/SortState';
import ActiveGeospatialAttribute from '../models/ActiveGeospatialAttribute';
import MinimalAuthUser from '../models/MinimalAuthUser';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../models/Model';
import RecordPairFeedbackAssignmentInfo from '../models/RecordPairFeedbackAssignmentInfo';
import RecordPairFeedbackResponse from '../models/RecordPairFeedbackResponse';
import RecordPairId from '../models/RecordPairId';
import RecordPairInnerFeedback from '../models/RecordPairInnerFeedback';
import RecordPairLabel from '../models/RecordPairLabel';
import RecordPairWithData from '../models/RecordPairWithData';
import ScoreThresholds from '../models/ScoreThresholds';
import { UnitRange } from '../models/UnitRange';
import { StoreReducers } from '../stores/AppAction';
import { AppState } from '../stores/MainStore';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { routes } from '../utils/Routing';
import SortUtils from '../utils/SortUtils';
import { keyModSelect } from '../utils/TableSelection';
import { $TSFixMe, assertNever } from '../utils/typescript';
import {
  INVALID,
  maybeSet,
  parseArray,
  parseBoolean,
  parseNumber,
  parseSet,
  parseSort,
  parseString,
  parseType,
} from '../utils/Url';
import { orderedIndexBy, resetExcept, resetFields } from '../utils/Values';
import AllAssignmentsFilterType, { AllAssignmentsFilterTypeE } from './AllAssignmentsFilterType';
import AllResponsesFilterType, { AllResponsesFilterTypeE } from './AllResponsesFilterType';
import AssignmentStatusFilterType, { AssignmentStatusFilterTypeE } from './AssignmentStatusFilterType';
import AttributeSimilarityFilterModel from './AttributeSimilarityFilterModel';
import {
  FETCH_USER_DEFINED_SIGNALS,
  FETCH_USER_DEFINED_SIGNALS_COMPLETED,
  FETCH_USER_DEFINED_SIGNALS_FAILED,
  SET_FILTER_TO_INFERRED_LABELS,
  TOGGLE_USER_DEFINED_SIGNAL_SORT,
} from './RecordPairsActionTypes';
import ResponseFilterType, { ResponseFilterTypeE } from './ResponseFilterType';
import { ResponseKeyE } from './ResponseKey';
import UserDefinedSignal from './UserDefinedSignal';

const DEFAULT_PAGE_SIZE = 50;
const DEFAULT_PAGE_NUM = 0;

const FILTER_NAMES = [
  'attributeSimilarityFilterStates',
  'manualLabelFilters',
  'suggestedLabelFilters',
  'labelConsensus',
  'highImpact', // PAIRS
  'hasComments',
  'selectedDatasetNames', // SOURCES
  'topRowDatasetName',
  'bottomRowDatasetName',
  'confidenceRangeFilter', // RESPONSES
  'hasResponses',
  'response',
  'filterToInferredLabels',
  'allResponses',
  'allAssignments', // ASSIGNMENT
  'assignmentStatus',
] as const;

export const getDatasetNames = (state: RecordPairsStore, sourceDatasetNames: Set<string>): Set<string> => {
  const { topRowDatasetName, selectedDatasetNames } = state;
  if (topRowDatasetName === PairsDatasetFilter.ANY_DATASET) {
    if (selectedDatasetNames.size === sourceDatasetNames.size) {
      return Set();
    }
    return selectedDatasetNames;
  }
  return Set.of(topRowDatasetName);
};

export const getOtherRecordDatasetNames = (state: RecordPairsStore, sourceDatasetNames: Set<string>): Set<string> => {
  const { bottomRowDatasetName, selectedDatasetNames, topRowDatasetName } = state;
  if (bottomRowDatasetName === PairsDatasetFilter.ANY_DATASET) {
    if (selectedDatasetNames.size === sourceDatasetNames.size) {
      return Set();
    }
    return selectedDatasetNames;
  }
  const selectedDatasetIdsExpanded = selectedDatasetNames.isEmpty()
    ? sourceDatasetNames
    : selectedDatasetNames;

  if (bottomRowDatasetName === PairsDatasetFilter.ANY_OTHER_DATASET) {
    return selectedDatasetIdsExpanded
      .subtract(Set.of(topRowDatasetName));
  }
  return Set.of(bottomRowDatasetName);
};

export const noFiltersEnabled = (state: RecordPairsStore, sourceDatasetNames: Set<string>): boolean => {
  const stateWithDefaultFilters = resetFields(state,
    Set(FILTER_NAMES)
      // we do not want to use these fields to test whether the involved filters are enabled - we do that with
      //   special logic later using getDatasetIds()
      .subtract(['selectedDatasetNames', 'topRowDatasetName', 'bottomRowDatasetName'])
      .toArray(),
  );
  const activeAttFilters = state.attributeSimilarityFilterStates.filter(val => val.isActive);
  const stateWithActiveAttFilters = state.set('attributeSimilarityFilterStates', activeAttFilters);
  return is(stateWithActiveAttFilters, stateWithDefaultFilters) && getDatasetNames(state, sourceDatasetNames).isEmpty();
};

export interface RecordPairsFilterInfo {
  pairsSequence: number,
  pageNum: number,
  pageSize: number,
  queryString: string,
  attributeSimilarityFilterStates: OrderedMap<string, AttributeSimilarityFilterModel>,
  manualLabelFilters: Set<RecordPairLabelFilterE>,
  suggestedLabelFilters: Set<RecordPairLabelFilterE>,
  labelConsensus: LabelConsensusE | undefined,
  highImpact: boolean,
  hasComments: boolean,
  confidenceRangeFilter: ConfidenceRangeE | undefined,
  columnSortStates: Map<string, SortStateValueType>,
  userDefinedSignalSorts: Map<string, SortStateValueType>,
  hasResponses: boolean,
  assignmentStatus: AssignmentStatusFilterTypeE | undefined,
  response: ResponseFilterTypeE | undefined,
  allAssignments: AllAssignmentsFilterTypeE | undefined,
  allResponses: AllResponsesFilterTypeE | undefined,
  filterToInferredLabels: boolean,
  datasetNames: Set<string>,
  otherRecordDatasetNames: Set<string>,
}

export const getFilterInfo = (state: AppState): RecordPairsFilterInfo => {
  const {
    recordPairs: { pairsSequence, pageNum, queryString, attributeSimilarityFilterStates, manualLabelFilters,
      suggestedLabelFilters, labelConsensus, highImpact, hasComments, hasResponses, assignmentStatus, response,
      allAssignments, allResponses, confidenceRangeFilter, pageSize, columnSortStates, filterToInferredLabels,
      userDefinedSignalSorts },
    allSourceDatasets: { datasets: sourceDatasetDocs },
  } = state;
  const sourceDatasetNames = sourceDatasetDocs.map((doc: $TSFixMe /* TODO ts-ify AllSourceDatasetsStore */) => doc.data.name).toSet();
  return {
    pairsSequence,
    pageNum,
    queryString,
    attributeSimilarityFilterStates,
    manualLabelFilters,
    suggestedLabelFilters,
    labelConsensus: labelConsensus || undefined,
    highImpact,
    hasComments,
    confidenceRangeFilter: confidenceRangeFilter || undefined,
    pageSize,
    columnSortStates,
    userDefinedSignalSorts,
    hasResponses,
    assignmentStatus: assignmentStatus || undefined,
    response: response || undefined,
    allAssignments: allAssignments || undefined,
    allResponses: allResponses || undefined,
    filterToInferredLabels,
    datasetNames: getDatasetNames(state.recordPairs, sourceDatasetNames),
    otherRecordDatasetNames: getOtherRecordDatasetNames(state.recordPairs, sourceDatasetNames),
  };
};

export const prepAttributeSimilarityFiltersForQuery = (attributeSimilarityFilterStates: RecordPairsStore['attributeSimilarityFilterStates']) => {
  return attributeSimilarityFilterStates
    .filter(model => model.includeRange || model.includeNulls)
    .valueSeq()
    .toSet();
};

export const confidenceFilterToUnitRange = (confidenceRangeFilter: ConfidenceRangeE | undefined, confidenceThresholds: ScoreThresholds | undefined): UnitRange | undefined => {
  if (!confidenceRangeFilter || !confidenceThresholds) {
    return undefined;
  }
  switch (confidenceRangeFilter) {
    case ConfidenceRange.LOW:
      return confidenceThresholds.lowUnitRange;
    case ConfidenceRange.MEDIUM:
      return confidenceThresholds.mediumUnitRange;
    case ConfidenceRange.HIGH:
      return confidenceThresholds.highUnitRange;
    default:
      console.error('unknown confidenceRange value: ', confidenceRangeFilter);
  }
  return undefined;
};

export const getSelectedPairs = ({ pairs, selectedRowNumbers }: Pick<RecordPairsStore, 'pairs' | 'selectedRowNumbers'>) => {
  return selectedRowNumbers.map(rowNumber => pairs.get(rowNumber));
};

export const getActivePair = ({ pairs, activeRowNumber }: Pick<RecordPairsStore, 'pairs' | 'activeRowNumber'>) => {
  return _.isNumber(activeRowNumber) && pairs.get(activeRowNumber);
};

export class RemoveResponseWarningInfo extends getModelHelpers({
  action: { type: ArgTypes.valueIn(['SKIP', 'REMOVE']) },
  rowNumbers: { type: ArgTypes.Immutable.set.of(ArgTypes.number) },
}, 'RemoveResponseWarningInfo')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class RemoveResponseWarningInfoRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }
}

export class RecordPairsStore extends getModelHelpers({
  pairs: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(RecordPairWithData)), defaultValue: List<RecordPairWithData>() },
  selectedDatasetNames: { type: ArgTypes.Immutable.set.of(ArgTypes.string), defaultValue: Set<string>() },
  topRowDatasetName: {
    type: ArgTypes.string, defaultValue: PairsDatasetFilter.ANY_DATASET },
  bottomRowDatasetName: {
    type: ArgTypes.string, defaultValue: PairsDatasetFilter.ANY_DATASET },
  selectedRowNumbers: { type: ArgTypes.Immutable.set.of(ArgTypes.number), defaultValue: Set<number>() },
  total: { type: ArgTypes.number, defaultValue: 0 },
  unfilteredTotal: { type: ArgTypes.number, defaultValue: 0 },
  pageNum: { type: ArgTypes.number, defaultValue: DEFAULT_PAGE_NUM },
  pageSize: { type: ArgTypes.number, defaultValue: DEFAULT_PAGE_SIZE },
  queryString: { type: ArgTypes.string, defaultValue: '' },
  isLoading: { // does not switch to true for label / commenting fetches, only filter / paging
    type: ArgTypes.bool, defaultValue: false },
  columnSortStates: { type: ArgTypes.Immutable.map.of(ArgTypes.valueIn(SortState), ArgTypes.string), defaultValue: Map<string, SortStateValueType>() },
  userDefinedSignalSorts: { type: ArgTypes.Immutable.map.of(ArgTypes.valueIn(SortState), ArgTypes.string), defaultValue: Map<string, SortStateValueType>() },
  attributeSimilarityFilterStates: { // of attributeName string -> RPAttrSimFiltModel}),
    type: ArgTypes.Immutable.orderedMap.of(ArgTypes.instanceOf(AttributeSimilarityFilterModel), ArgTypes.string),
    defaultValue: OrderedMap<string, AttributeSimilarityFilterModel>(),
  },
  manualLabelFilters: { type: ArgTypes.Immutable.set.of(ArgTypes.valueIn(RecordPairLabelFilter)), defaultValue: Set<RecordPairLabelFilterE>() },
  suggestedLabelFilters: { type: ArgTypes.Immutable.set.of(ArgTypes.valueIn(RecordPairLabelFilter)), defaultValue: Set<RecordPairLabelFilterE>() },
  labelConsensus: { type: ArgTypes.nullable(ArgTypes.valueIn(LabelConsensus)), defaultValue: undefined },
  confidenceRangeFilter: { type: ArgTypes.nullable(ConfidenceRange.argType), defaultValue: undefined },
  highImpact: { // if true, filters GETs to high impact pairs (false does nothing)
    type: ArgTypes.bool, defaultValue: false },
  hasComments: { // if true, filters GETs to pairs that have comments (false does nothing)
    type: ArgTypes.bool, defaultValue: false },
  hasResponses: { type: ArgTypes.bool, defaultValue: false },
  assignmentStatus: { type: ArgTypes.nullable(ArgTypes.valueIn(AssignmentStatusFilterType)) },
  response: { type: ArgTypes.nullable(ArgTypes.valueIn(ResponseFilterType)) },
  allAssignments: { type: ArgTypes.nullable(ArgTypes.valueIn(AllAssignmentsFilterType)) },
  allResponses: { type: ArgTypes.nullable(ArgTypes.valueIn(AllResponsesFilterType)) },
  filterToInferredLabels: { type: ArgTypes.bool, defaultValue: false },
  isSidebarExpanded: { type: ArgTypes.bool, defaultValue: false },
  activeGeospatialAttribute: { type: ArgTypes.orNull(ActiveGeospatialAttribute.argType), defaultValue: null },
  // in order to track state-changing user actions that should block further
  // action to that record pair until request returns
  currentlyProcessing: { type: ArgTypes.Immutable.set.of(ArgTypes.instanceOf(RecordPairId)), defaultValue: Set<RecordPairId>() },
  // alerts loaders that these record pairs are ready to check for filter violations
  shouldCheckFilterViolations: { type: ArgTypes.Immutable.set.of(ArgTypes.instanceOf(RecordPairId)), defaultValue: Set<RecordPairId>() },
  // as we check with the server whether the pair belongs on the current page, track which
  // we're checking for here. similar to isLoading
  currentlyCheckingFilterViolations: { type: ArgTypes.Immutable.set.of(ArgTypes.instanceOf(RecordPairId)), defaultValue: Set<RecordPairId>() },
  // of RecordPairIds, in order to track pairs that have been labeled by the user since the last
  // refresh, filter, search, or pagination, and now violate the current filters
  pendingResolution: { type: ArgTypes.Immutable.set.of(ArgTypes.instanceOf(RecordPairId)), defaultValue: Set<RecordPairId>() },
  pendingResolutionRowNumbers: { // accompanying pendingResolution
    type: ArgTypes.Immutable.set.of(ArgTypes.number), defaultValue: Set<number>() },
  noFiltersOn: { type: ArgTypes.bool, defaultValue: true },
  noPairsInProject: { type: ArgTypes.bool, defaultValue: false },
  initialFetch: { type: ArgTypes.bool, defaultValue: false },
  showingPredictWarning: { type: ArgTypes.bool, defaultValue: false },
  showingTrainPredictWarning: { type: ArgTypes.bool, defaultValue: false },
  suggestionLaunching: { type: ArgTypes.bool, defaultValue: false },
  activeRowNumber: { type: ArgTypes.nullable(ArgTypes.number), defaultValue: undefined },
  showFilterPanel: { type: ArgTypes.bool, defaultValue: false },
  showingDetailsDialog: { type: ArgTypes.bool, defaultValue: false },
  showDatasetSelector: { type: ArgTypes.bool, defaultValue: false },
  datasetFilterSelectionDelta: { // for filter panel source filter, dataset name -> selected
    type: ArgTypes.Immutable.map.of(ArgTypes.bool, ArgTypes.string), defaultValue: Map<string, boolean>() },
  datasetFilterSearchValue: { // for filter panel source filter
    type: ArgTypes.string, defaultValue: '' },
  activeKey: { type: ArgTypes.any, defaultValue: undefined },
  commentFocusSequence: { type: ArgTypes.number, defaultValue: 0 },
  showingAddAttributeSimilarityFilterDialog: { type: ArgTypes.bool, defaultValue: false },
  loadedFilterInfo: { type: ArgTypes.any },
  pairsSequence: { type: ArgTypes.number, defaultValue: 0 },
  assigningFeedbackForRows: { type: ArgTypes.Immutable.set.of(ArgTypes.number), defaultValue: Set<number>() },
  warningAboutRemoveResponse: { type: ArgTypes.orUndefined(ArgTypes.instanceOf(RemoveResponseWarningInfo)) },
  dedupModelExists: { type: ArgTypes.nullable(ArgTypes.bool) },
  // user defined signals
  loadingUserDefinedSignals: { type: ArgTypes.bool, defaultValue: false },
  loadedUserDefinedSignals: { type: ArgTypes.bool, defaultValue: false },
  userDefinedSignals: { type: ArgTypes.orUndefined(ArgTypes.Immutable.list.of(UserDefinedSignal.argType)) },
}, 'RecordPairsStore')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class RecordPairsStoreRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  reduce(reduceFunction: Function, accumulator: any) {
    Array.from(this.toSeq().entries()).forEach(entry => {
      accumulator = reduceFunction(accumulator, entry[1], entry[0]);
    });
    return accumulator;
  }
}

export const initialState = new RecordPairsStore({});

const addCurrentlyProcessingIds = (state: RecordPairsStore, rpIds: Set<RecordPairId>) => {
  return state.update('currentlyProcessing', s => s.union(rpIds));
};

const removeCurrentlyProcessingIds = (state: RecordPairsStore, rpIds: Set<RecordPairId>) => {
  return state.update('currentlyProcessing', s => s.subtract(rpIds));
};

const addPendingResolutionIds = (state: RecordPairsStore, rpIds: Set<RecordPairId>) => {
  const newRowNumbers = state.pairs
    .entrySeq()
    .filter(entry => rpIds.includes(entry[1].toRecordPairId()))
    .map(entry => entry[0]);
  return state.update('pendingResolution', s => s.union(rpIds))
    .update('pendingResolutionRowNumbers', s => s.union(newRowNumbers));
};

const removePendingResolutionIds = (state: RecordPairsStore, rpIds: Set<RecordPairId>) => {
  const newRowNumbers = state.pairs
    .entrySeq()
    .filter(entry => rpIds.includes(entry[1].toRecordPairId()))
    .map(entry => entry[0]);
  return state.update('pendingResolution', s => s.subtract(rpIds))
    .update('pendingResolutionRowNumbers', s => s.subtract(newRowNumbers));
};

const clearPageAndSelection = (state: RecordPairsStore) => {
  return state.merge({
    pageNum: 0,
    selectedRowNumbers: Set<number>(),
    isSidebarExpanded: false,
    activeRowNumber: undefined,
  });
};

const clearSort = (state: RecordPairsStore): RecordPairsStore => {
  return state.delete('columnSortStates').delete('userDefinedSignalSorts');
};

const clearSortPageAndSelection = (state: RecordPairsStore): RecordPairsStore => {
  return clearSort(clearPageAndSelection(state));
};

const setLabelFilters = (
  state: RecordPairsStore,
  humanFilters: Set<RecordPairLabelFilterE>,
  suggFilters: Set<RecordPairLabelFilterE>,
  labelConsensus: LabelConsensusE | undefined,
) => {
  return clearPageAndSelection(state).merge({
    manualLabelFilters: humanFilters,
    suggestedLabelFilters: suggFilters,
    labelConsensus,
  });
};

const reloadPairs = (state: RecordPairsStore) => {
  return state.update('pairsSequence', x => x + 1);
};

// update front-end's notion of a pair's manual label (assumes label request will be successful)
const updatePairLabel = (state: RecordPairsStore, rpId: RecordPairId, label: ManualPairLabelTypeE | undefined) => {
  const entry = state.pairs.findEntry(p => p.toRecordPairId().equals(rpId));
  if (!entry) return state;
  const index = entry[0];
  const newPair = entry[1].set('manualLabel', label);
  return state.setIn(['pairs', index], newPair);
};

const updateFeedbackWithResponses = (state: RecordPairsStore, { rowNumbers, responseKey, loggedInUsername }: {
  rowNumbers: Set<number>
  responseKey: ResponseKeyE,
  loggedInUsername: string,
}) => {
  let updatedState = state;
  const now = Math.floor(Date.now() / 1000);
  rowNumbers.forEach(index => {
    updatedState = updatedState.updateIn(['pairs', index, 'feedback'], (feedback: List<RecordPairInnerFeedback>) => {
      const existingFeedbackForUserIndex = feedback.findIndex(f => f.username === loggedInUsername);
      if (existingFeedbackForUserIndex !== -1) {
        if (responseKey === 'MATCH' || responseKey === 'NON_MATCH') {
          return feedback.update(existingFeedbackForUserIndex, f => {
            return f.update('response', r => {
              const newFields = { label: responseKey, lastModified: now };
              if (r) {
                return r.merge(newFields);
              }
              return new RecordPairFeedbackResponse({ ...newFields });
            }).update('assignmentInfo', ai => {
              if (ai) {
                return ai.set('status', 'ANSWERED');
              }
            });
          });
        } // else SKIP
        return feedback.update(existingFeedbackForUserIndex, f => {
          return f.delete('response').setIn(['assignmentInfo', 'status'], 'SKIP');
        });
      } // else no feedback -- create new
      if (responseKey === 'SKIP') {
        console.error('can not SKIP unassigned feedback');
        return feedback;
      } // must be MATCH or NON_MATCH
      return feedback.push(RecordPairInnerFeedback.fromJSON({
        username: loggedInUsername,
        response: { label: responseKey, created: now, lastModified: now },
      }));
    });
  });
  return updatedState;
};

const updateLabels = (state: RecordPairsStore, { manualLabels }: { manualLabels: Set<RecordPairLabel> }) => {
  checkArg({ manualLabels }, ArgTypes.Immutable.set.of(ArgTypes.instanceOf(RecordPairLabel)));
  let updatedState = state;
  manualLabels.forEach(label => {
    updatedState = updatePairLabel(updatedState, label.toRecordPairId(), label.manualLabel);
  });
  return updatedState;
};

const updateFeedbackWithRemovedResponses = (state: RecordPairsStore, { rowNumbers, loggedInUsername }: {
  rowNumbers: Set<number>,
  loggedInUsername: string
}) => {
  let updatedState = state;
  const now = Math.floor(Date.now() / 1000);
  rowNumbers.forEach(index => {
    updatedState = updatedState.updateIn(['pairs', index, 'feedback'], (feedback: List<RecordPairInnerFeedback>) => {
      const existingFeedbackForUserIndex = feedback.findIndex(f => f.username === loggedInUsername);
      if (existingFeedbackForUserIndex === -1) {
        return feedback;
      }
      return feedback.update(existingFeedbackForUserIndex, f => {
        if (f.assignmentInfo) {
          return f.delete('response').mergeDeep({ assignmentInfo: new RecordPairFeedbackAssignmentInfo({ lastModified: now, status: 'PENDING' }) });
        }
        return f.delete('response');
      });
    });
  });
  return updatedState;
};

const rollBackLabels = (state: RecordPairsStore, { rowNumbers, projectId, users }: {
  rowNumbers: Set<number>
  projectId: number,
  users: List<MinimalAuthUser>
}) => {
  checkArg({ rowNumbers }, ArgTypes.Immutable.set.of(ArgTypes.number));
  checkArg({ projectId }, ArgTypes.number);
  checkArg({ users }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(MinimalAuthUser)));
  let updatedState = state;
  rowNumbers.forEach(index => {
    updatedState = updatedState.updateIn(['pairs', index], pair => {
      const newVerifiedResponse = pair.getMostRecentCuratorResponse(projectId, users);
      return pair.set('manualLabel', newVerifiedResponse ? newVerifiedResponse.responseKey : undefined);
    });
  });
  return updatedState;
};

const clearSelection = (state: RecordPairsStore) => state.delete('selectedRowNumbers');

const disableAllAttSimFilters = (state: RecordPairsStore) => state
  .update('attributeSimilarityFilterStates', existingFilters => existingFilters.map(existingFilter => {
    const newFilter = existingFilter.disable();
    return newFilter;
  }));

const noNonMatrixFiltersEnabled = (state: RecordPairsStore) => {
  // This checks to see if non matrix filters are already set to their default
  let defaultState = state;
  const matrixList = ['manualLabelFilters', 'suggestedLabelFilters', 'labelConsensus'];
  FILTER_NAMES
    .filter(name => !_.contains(matrixList, name)) // do not test matrix filters
    .filter(name => name !== 'attributeSimilarityFilterStates') // attributeSimilarityFilterStates just need to be default
    .forEach(filter => {
      defaultState = defaultState.delete(filter);
    });
  return is(state, disableAllAttSimFilters(defaultState)); // TODO add in sourceDatasetIds
};


export const getActiveConfusionMatrixFilter = (state: RecordPairsStore): ConfusionMatrixQuadrantE | undefined => {
  // if any non-relevant filters are active, return undefined
  if (!noNonMatrixFiltersEnabled(state)) { // if there are any other filters not in their default state
    return undefined;
  }
  // if only top-left filters are active, return top left, so forth
  if (state.manualLabelFilters.contains(RecordPairLabelFilter.MATCH) && !state.manualLabelFilters.contains(RecordPairLabelFilter.NON_MATCH)) {
    if (state.suggestedLabelFilters.contains(RecordPairLabelFilter.MATCH) && !state.suggestedLabelFilters.contains(RecordPairLabelFilter.NON_MATCH)) {
      return ConfusionMatrixQuadrant.TRUE_POSITIVES;
    } if (state.suggestedLabelFilters.contains(RecordPairLabelFilter.NON_MATCH) && !state.suggestedLabelFilters.contains(RecordPairLabelFilter.MATCH)) {
      return ConfusionMatrixQuadrant.FALSE_NEGATIVES;
    }
  } else if (state.manualLabelFilters.contains(RecordPairLabelFilter.NON_MATCH) && !state.manualLabelFilters.contains(RecordPairLabelFilter.MATCH)) {
    if (state.suggestedLabelFilters.contains(RecordPairLabelFilter.MATCH) && !state.suggestedLabelFilters.contains(RecordPairLabelFilter.NON_MATCH)) {
      return ConfusionMatrixQuadrant.FALSE_POSITIVES;
    } if (state.suggestedLabelFilters.contains(RecordPairLabelFilter.NON_MATCH) && !state.suggestedLabelFilters.contains(RecordPairLabelFilter.MATCH)) {
      return ConfusionMatrixQuadrant.TRUE_NEGATIVES;
    }
  }
  return undefined;
};

const resetFilter = (state: RecordPairsStore) => {
  let updatedState = state; // can I do this without introducing "updatedState"?
  FILTER_NAMES.forEach(filter => {
    updatedState = updatedState.delete(filter);
  });
  return updatedState.delete('pageNum');
};

export const reducers: StoreReducers<RecordPairsStore> = {
  'RecordPairs.resetFilters': resetFilter,

  'RecordPairs.setHasCommentsFilter': (state, { hasComments }) => {
    return clearPageAndSelection(state).set('hasComments', hasComments);
  },

  'RecordPairs.setHasResponsesFilter': (state, { hasResponses }) => {
    return clearPageAndSelection(state).set('hasResponses', hasResponses);
  },

  [SET_FILTER_TO_INFERRED_LABELS]: (state, { filterToInferredLabels }) => {
    return clearPageAndSelection(state).merge({ filterToInferredLabels });
  },

  'RecordPairs.setAssignmentStatusFilter': (state, { assignmentStatus }) => {
    return clearPageAndSelection(state).merge({ assignmentStatus });
  },

  'RecordPairs.setResponseFilter': (state, { response }) => {
    return clearPageAndSelection(state).merge({ response });
  },

  'RecordPairs.setAllAssignmentsFilter': (state, { allAssignments }) => {
    return clearPageAndSelection(state).merge({ allAssignments });
  },

  'RecordPairs.setAllResponsesFilter': (state, { allResponses }) => {
    return clearPageAndSelection(state).merge({ allResponses });
  },

  'RecordPairs.setHighImpactFilter': (state, { highImpact }) => {
    return clearPageAndSelection(state).set('highImpact', highImpact);
  },

  'RecordPairs.updateAttributeSimilarityFilter': (state, { filter }) => {
    checkArg({ filter }, ArgTypes.instanceOf(AttributeSimilarityFilterModel));
    return state.update('attributeSimilarityFilterStates', existingFilters => existingFilters.map(existingFilter => {
      return existingFilter.attributeName === filter.attributeName ? filter : existingFilter;
    }));
  },

  'RecordPairs.addAttributeSimilarityFilters': (state, { filters }) => {
    return state.update('attributeSimilarityFilterStates', existingFilters => {
      const selectedNames = filters.map((f: $TSFixMe) => f.sanitizedAttributeName);
      const pruned = existingFilters.filter((v, k) => selectedNames.has(k));
      return filters.reduce((rn: $TSFixMe, f: $TSFixMe) => {
        return rn.get(f.sanitizedAttributeName) ? rn : rn.set(f.sanitizedAttributeName, f);
      }, pruned);
    });
  },

  'RecordPairs.deleteAttributeSimilarityFilters': (state, { filterKeys }) => {
    checkArg({ filterKeys }, ArgTypes.Immutable.set.of(ArgTypes.string)); // attribute names
    let currentFilters = state.attributeSimilarityFilterStates;
    filterKeys.forEach((filterKey: $TSFixMe) => {
      currentFilters = currentFilters.delete(filterKey);
    });
    return state.set('attributeSimilarityFilterStates', currentFilters);
  },

  'RecordPairs.setLabelFilters': (state, { humanFilters, suggFilters, labelConsensus }) => {
    return setLabelFilters(state, humanFilters, suggFilters, labelConsensus);
  },

  'RecordPairs.setConfidenceRangeFilter': (state, { confidenceRangeFilter }) => {
    return clearPageAndSelection(state).set('confidenceRangeFilter', confidenceRangeFilter);
  },

  'RecordPairs.toggleAttributeSort': (state, { columnName }) => {
    const sanitizedColName = AttributeSimilarityFilterModel.sanitizeAttribute(columnName);
    const currentState = state.columnSortStates.get(sanitizedColName) || SortState.UNSORTED;
    const nextState = SortUtils.getNext(currentState);
    return clearSortPageAndSelection(state).set('columnSortStates', Map<string, SortStateValueType>().set(sanitizedColName, nextState));
  },

  [TOGGLE_USER_DEFINED_SIGNAL_SORT]: (state, { udsExternalId }) => {
    const currentState = state.userDefinedSignalSorts.get(udsExternalId) || SortState.UNSORTED;
    const nextState = SortUtils.getNext(currentState);
    return clearSortPageAndSelection(state).set('userDefinedSignalSorts', Map<string, SortStateValueType>().set(udsExternalId, nextState));
  },

  'RecordPairs.setPageNum': (state, { pageNum }) => {
    return state.merge({ pageNum });
  },

  'RecordPairs.setPageSize': (state, { pageSize }) => {
    return clearPageAndSelection(state).merge({ pageSize });
  },

  'RecordPairs.setSelectedDatasets': (state, { selectedDatasetNames }) => {
    return clearPageAndSelection(state).merge({ selectedDatasetNames });
  },

  'RecordPairs.setTopRowDatasetName': (state, { datasetName }) => {
    return clearPageAndSelection(state).set('topRowDatasetName', datasetName);
  },

  'RecordPairs.setBottomRowDatasetName': (state, { datasetName }) => {
    return clearPageAndSelection(state).set('bottomRowDatasetName', datasetName);
  },

  'RecordPairs.setTopAndBottomRowDatasetNames': (state, { top, bottom }) => {
    return clearPageAndSelection(state).merge({
      topRowDatasetName: top,
      bottomRowDatasetName: bottom,
    });
  },

  'RecordPairs.setQueryString': (state, { queryString }) => {
    return clearPageAndSelection(state).merge({ queryString });
  },

  'Location.projectChange': (state) => resetExcept(state, ['isLoading', 'currentlyProcessing', 'shouldCheckFilterViolations', 'currentlyCheckingFilterViolations']),

  'Location.change': (state, { location }) => {
    if (routes.pairs.match(location.pathname)) {
      const params = qs.parse(location.search);
      const parseSimilarity = (v: $TSFixMe) => {
        const arr = parseArray(AttributeSimilarityFilterModel.fromUrlString)(v);
        return arr === INVALID ? INVALID : orderedIndexBy(arr, (m: $TSFixMe) => m.sanitizedAttributeName);
      };
      return _.compose(
        maybeSet('pageNum', parseNumber(params.pageNum)),
        maybeSet('pageSize', parseNumber(params.pageSize)),
        maybeSet('queryString', parseString(params.queryString)),
        maybeSet('selectedDatasetNames', parseSet(parseString)(params.selectedDatasetNames)),
        maybeSet('topRowDatasetName', parseString(params.topRowDatasetName)),
        maybeSet('bottomRowDatasetName', parseString(params.bottomRowDatasetName)),
        maybeSet('labelConsensus', parseType(ArgTypes.valueIn(LabelConsensus))(params.labelConsensus)),
        maybeSet('highImpact', parseBoolean(params.highImpact)),
        maybeSet('hasComments', parseBoolean(params.hasComments)),
        maybeSet('filterToInferredLabels', parseBoolean(params.filterToInferredLabels)),
        maybeSet('attributeSimilarityFilterStates', parseSimilarity(params.attributeSimilarityFilters)),
        maybeSet('columnSortStates', parseSort(params.sort)),
        maybeSet('userDefinedSignalSorts', parseSort(params.userDefinedSignalSort)),
        maybeSet('manualLabelFilters', parseSet(parseType(ArgTypes.valueIn(RecordPairLabelFilter)))(params.manualLabel)),
        maybeSet('suggestedLabelFilters', parseSet(parseType(ArgTypes.valueIn(RecordPairLabelFilter)))(params.suggestedLabel)),
        maybeSet('confidenceRangeFilter', parseType(ConfidenceRange.argType)(params.confidenceRange)),
        maybeSet('hasResponses', parseBoolean(params.hasResponses)),
        maybeSet('assignmentStatus', parseType(ArgTypes.valueIn(AssignmentStatusFilterType))(params.assignmentStatus)),
        maybeSet('response', parseType(ArgTypes.valueIn(ResponseFilterType))(params.response)),
        maybeSet('allAssignments', parseType(ArgTypes.valueIn(AllAssignmentsFilterType))(params.allAssignments)),
        maybeSet('allResponses', parseType(ArgTypes.valueIn(AllResponsesFilterType))(params.allResponses)),
        maybeSet('showFilterPanel', parseBoolean(params.showFilterPanel)),
      )(state).update(reloadPairs);
    }
    return state;
  },

  'RecordPairs.fetchPairs': (state) => {
    return state.set('isLoading', true);
  },

  'RecordPairs.fetchPairsCompleted': (state, { items, unfilteredTotal, total, filterInfo, sourceDatasetDocs }) => {
    const pairs = items.map((record) => {
      const topRowDataset = state.get('topRowDatasetName');
      if (topRowDataset !== PairsDatasetFilter.ANY_DATASET) {
        const dataset1Index = sourceDatasetDocs
          .findIndex(dataset => dataset.data.name === record.originSourceId1);
        if (dataset1Index !== -1 && sourceDatasetDocs.get(dataset1Index)?.data.name !== topRowDataset) {
          return record.swapTxns();
        }
      }
      return record;
    });

    const noFiltersOn = noFiltersEnabled(state, sourceDatasetDocs.map(doc => doc.data.name).toSet());
    // NB: this is a slightly hacky method for determining whether there are any pairs
    //     in the project. Since these are the conditions for the initial fetch (even on
    //     active context change), it's reasonable to expect this to work when needed.
    const noPairsInProject = noFiltersOn && items.size === 0;

    return state.merge({
      noPairsInProject,
      noFiltersOn,
      pairs,
      unfilteredTotal,
      total,
      initialFetch: true,
      loadedFilterInfo: filterInfo,
      isLoading: false,
      pendingResolution: Set(),
      pendingResolutionRowNumbers: Set(),
    }).delete('selectedRowNumbers')
      .delete('activeRowNumber');
  },

  'RecordPairs.fetchPairsFailed': (state, { filterInfo }) => {
    return state.merge({
      isLoading: false,
      loadedFilterInfo: filterInfo,
    });
  },

  'RecordPairs.checkFilterViolations': (state, { rpIds }) => {
    return state.update('shouldCheckFilterViolations', s => s.subtract(rpIds))
      .update('currentlyCheckingFilterViolations', s => s.merge(rpIds));
  },

  'RecordPairs.checkFilterViolationsCompleted': (state, { rpIds, currentPageIds }) => {
    const violatingRPIds = rpIds.subtract(currentPageIds);
    const nonViolatingRPIds = rpIds.subtract(violatingRPIds);
    const removedProcessing = removeCurrentlyProcessingIds(state, rpIds);
    const addedPending = addPendingResolutionIds(removedProcessing, violatingRPIds);
    const removedPending = removePendingResolutionIds(addedPending, nonViolatingRPIds);
    return removedPending.update('shouldCheckFilterViolations', s => s.subtract(rpIds))
      .update('currentlyCheckingFilterViolations', s => s.subtract(rpIds));
  },

  'RecordPairs.showPredictWarning': (state) => {
    return state.set('showingPredictWarning', true);
  },

  'RecordPairs.hidePredictWarning': (state) => {
    return state.set('showingPredictWarning', false);
  },

  'RecordPairs.showTrainPredictWarning': (state) => {
    return state.set('showingTrainPredictWarning', true);
  },

  'RecordPairs.hideTrainPredictWarning': (state) => {
    return state.set('showingTrainPredictWarning', false);
  },

  'RecordPairs.launchTrainPredictCluster': (state) => {
    return state.merge({ showingTrainPredictWarning: false, suggestionLaunching: true });
  },

  'RecordPairs.launchPredictCluster': (state) => {
    return state.merge({ showingPredictWarning: false, suggestionLaunching: true });
  },

  'RecordPairs.launchTrainPredictClusterCompleted': (state) => {
    return state.set('suggestionLaunching', false);
  },

  'RecordPairs.launchPredictClusterCompleted': (state) => {
    return state.set('suggestionLaunching', false);
  },

  'RecordPairs.launchTrainPredictClusterFailed': (state) => {
    return state.merge({ suggestionLaunching: false });
  },

  'RecordPairs.launchPredictClusterFailed': (state) => {
    return state.merge({ suggestionLaunching: false });
  },

  'Jobs.trainPredictClusterCompleted': (state) => {
    return reloadPairs(state);
  },

  'RecordPairs.setActivePair': (state, { activeRowNumber }) => {
    return state.merge({ activeRowNumber });
  },

  'RecordPairs.toggleExpandSidebar': (state) => {
    return state.update('isSidebarExpanded', s => !s);
  },

  'RecordPairs.showSidebarTab': (state, { tabKey }) => {
    return state.merge({
      showingDetailsDialog: false,
      isSidebarExpanded: true,
      activeKey: tabKey,
      commentFocusSequence: state.commentFocusSequence + 1,
    });
  },

  'RecordPairs.refreshPairs': (state) => {
    return reloadPairs(state);
  },

  'RecordPairs.comment': (state, { rpComment }) => {
    return state
      // @ts-expect-error immutableUpdateWithOneArgument
      .update(s => addCurrentlyProcessingIds(s, new Set([rpComment.toPairId().toRecordPairId()])));
  },

  'RecordPairs.commentCompleted': (state, { rpComment }) => {
    const rpId = rpComment.toPairId().toRecordPairId();

    const pairs = state.pairs
      .map(pair => (!isEqual(pair.toPairId(), rpComment.toPairId()) ? pair :
        pair.merge({ comments: pair.comments.push(rpComment) })),
      );

    return state
      // @ts-expect-error immutableUpdateWithOneArgument
      .update(s => removeCurrentlyProcessingIds(s, new Set([rpId])))
      .update('shouldCheckFilterViolations', (s: $TSFixMe) => s.merge(Set([rpId])))
      .merge({ pairs });
  },

  'RecordPairs.editComment': (state, { rpComment }) => {
    return state
      // @ts-expect-error immutableUpdateWithOneArgument
      .update(s => addCurrentlyProcessingIds(s, Set([rpComment.toPairId().toRecordPairId()])));
  },

  'RecordPairs.editCommentCompleted': (state, { rpComment }) => {
    const rpId = rpComment.toPairId().toRecordPairId();
    // Replace with persisted comment
    const pairs = state.pairs
      .map(pair => (!isEqual(pair.toPairId(), rpComment.toPairId()) ? pair :
        pair.merge({ comments:
            pair.comments.filter(c => !isEqual(c.toPairCommentId(), rpComment.toPairCommentId()))
              .push(rpComment),
        })),
      );
    return state
      // @ts-expect-error immutableUpdateWithOneArgument
      .update(s => removeCurrentlyProcessingIds(s, Set([rpId])))
      .update('shouldCheckFilterViolations', (s: $TSFixMe) => s.merge(Set([rpId])))
      .merge({ pairs });
  },

  'RecordPairs.deleteComment': (state, { rpCommentId }) => {
    return state
      // @ts-expect-error immutableUpdateWithOneArgument
      .update(s => addCurrentlyProcessingIds(s, new Set([rpCommentId.toPairId().toRecordPairId()])));
  },

  'RecordPairs.deleteCommentCompleted': (state, { rpCommentId }) => {
    const rpId = rpCommentId.toPairId().toRecordPairId();
    const pairs = state.pairs
      .map(pair => (!isEqual(pair.toPairId(), rpCommentId.toPairId()) ? pair :
        pair.merge({ comments:
            pair.comments.filter(c => !isEqual(c.toPairCommentId(), rpCommentId)),
        })),
      );
    return state
      // @ts-expect-error immutableUpdateWithOneArgument
      .update(s => removeCurrentlyProcessingIds(s, Set([rpId])))
      .update('shouldCheckFilterViolations', (s: $TSFixMe) => s.merge(Set([rpId])))
      .merge({ pairs });
  },

  'RecordPairs.toggleConfusionMatrixFilter': (state, { quadrant }) => {
    checkArg({ quadrant }, ArgTypes.valueIn(ConfusionMatrixQuadrant));
    // is the quadrant active or not?
    const quadrantActive = getActiveConfusionMatrixFilter(state) === quadrant;
    if (!quadrantActive) { // set that quadrant to be active
      let labelFilter;
      switch (quadrant) {
        case ConfusionMatrixQuadrant.TRUE_POSITIVES:
          labelFilter = state
            // @ts-expect-error immutableUpdateWithOneArgument
            .update(s => resetFilter(s))
            .update('attributeSimilarityFilterStates', () => disableAllAttSimFilters(state).get('attributeSimilarityFilterStates')) // add back in disabled attr sim filters
            // @ts-expect-error immutableUpdateWithOneArgument
            .update(s => setLabelFilters(s, Set([RecordPairLabelFilter.MATCH]), Set([RecordPairLabelFilter.MATCH]), LabelConsensus.AGREE));
          break;
        case ConfusionMatrixQuadrant.FALSE_POSITIVES:
          labelFilter = state
            // @ts-expect-error immutableUpdateWithOneArgument
            .update(s => resetFilter(s))
            .update('attributeSimilarityFilterStates', () => disableAllAttSimFilters(state).get('attributeSimilarityFilterStates')) // add back in disabled attr sim filters
            // @ts-expect-error immutableUpdateWithOneArgument
            .update(s => setLabelFilters(s, Set([RecordPairLabelFilter.NON_MATCH]), Set([RecordPairLabelFilter.MATCH]), LabelConsensus.DISAGREE));
          break;
        case ConfusionMatrixQuadrant.FALSE_NEGATIVES:
          labelFilter = state
            // @ts-expect-error immutableUpdateWithOneArgument
            .update(s => resetFilter(s))
            .update('attributeSimilarityFilterStates', () => disableAllAttSimFilters(state).get('attributeSimilarityFilterStates')) // add back in disabled attr sim filters
            // @ts-expect-error immutableUpdateWithOneArgument
            .update(s => setLabelFilters(s, Set([RecordPairLabelFilter.MATCH]), Set([RecordPairLabelFilter.NON_MATCH]), LabelConsensus.DISAGREE));
          break;
        case ConfusionMatrixQuadrant.TRUE_NEGATIVES:
          labelFilter = state
            // @ts-expect-error immutableUpdateWithOneArgument
            .update(s => resetFilter(s))
            .update('attributeSimilarityFilterStates', () => disableAllAttSimFilters(state).get('attributeSimilarityFilterStates')) // add back in disabled attr sim filters
            // @ts-expect-error immutableUpdateWithOneArgument
            .update(s => setLabelFilters(s, Set([RecordPairLabelFilter.NON_MATCH]), Set([RecordPairLabelFilter.NON_MATCH]), LabelConsensus.AGREE));
          break;
        default:
          assertNever(quadrant);
      }
      return labelFilter;
    } // else if the quadrant is already active, toggle it off
    return setLabelFilters(
      state,
      Set(),
      Set(),
      undefined,
    );
  },

  'RecordPairs.resetMatrixFilter': (state) => {
    return setLabelFilters(
      state,
      Set(),
      Set(),
      undefined,
    );
  },

  'RecordPairs.toggleFilterPanel': (state) => {
    return state.update('showFilterPanel', s => !s);
  },

  'RecordPairs.showDetailsDialog': (state) => {
    return state.set('showingDetailsDialog', true);
  },

  'RecordPairs.closeDetailsDialog': (state) => {
    return state.set('showingDetailsDialog', false);
  },

  'RecordPairs.toggleDatasetSelector': (state) => {
    return state.update('showDatasetSelector', s => !s);
  },

  'RecordPairs.setDatasetFilterSelectionDelta': (state, { newDeltaMap }) => {
    return state.set('datasetFilterSelectionDelta', newDeltaMap);
  },

  'RecordPairs.setDatasetFilterSearchValue': (state, { searchValue }) => {
    return state.set('datasetFilterSearchValue', searchValue);
  },

  'RecordPairs.showAddAttributeSimilarityFilterDialog': (state) => {
    return state.set('showingAddAttributeSimilarityFilterDialog', true);
  },

  'RecordPairs.closeAddAttributeSimilarityFilterDialog': (state) => {
    return state.set('showingAddAttributeSimilarityFilterDialog', false);
  },

  'RecordPairs.beginAssigningFeedback': (state, { rowNumbers }) => {
    return state.set('assigningFeedbackForRows', rowNumbers);
  },

  'RecordPairs.stopAssigningFeedback': (state) => {
    return state.delete('assigningFeedbackForRows');
  },

  'RecordPairs.updateFeedbackAssignments': (state, { pairIndexes, usersToAssign, usersToUnassign }) => {
    const pairs = state.pairs.filter((pair, i) => pairIndexes.has(i));
    let updatedState = addCurrentlyProcessingIds(state, Set(pairs.map(pair => pair.toRecordPairId())));
    const now = Date.now();
    pairIndexes.forEach((index: $TSFixMe) => {
      updatedState = updatedState.updateIn(['pairs', index, 'feedback'], feedback => {
        let updatedFeedback = feedback;
        usersToAssign.forEach((username: $TSFixMe) => {
          const existingFeedbackForUserIndex = feedback.findIndex((f: $TSFixMe) => f.username === username);
          if (existingFeedbackForUserIndex !== -1) {
            updatedFeedback = updatedFeedback.update(existingFeedbackForUserIndex, (f: $TSFixMe) => {
              if (f.assignmentInfo) {
                return f; // already assigned
              }
              return f.set('assignmentInfo', new RecordPairFeedbackAssignmentInfo({ status: f.response ? 'ANSWERED' : 'PENDING', lastModified: now }));
            });
          } else {
            updatedFeedback = updatedFeedback.push(new RecordPairInnerFeedback({
              username,
              assignmentInfo: new RecordPairFeedbackAssignmentInfo({ status: 'PENDING', lastModified: now }),
            }));
          }
        });
        usersToUnassign.forEach((username: $TSFixMe) => {
          const existingFeedbackForUserIndex = feedback.findIndex((f: $TSFixMe) => f.username === username);
          if (existingFeedbackForUserIndex !== -1) {
            if (feedback.get(existingFeedbackForUserIndex).response) {
              updatedFeedback = updatedFeedback.update(existingFeedbackForUserIndex, (f: $TSFixMe) => f.delete('assignmentInfo'));
            } else {
              updatedFeedback = updatedFeedback.delete(existingFeedbackForUserIndex);
            }
          }
          // otherwise, attempting to unassign an already unassigned pair
        });
        return updatedFeedback;
      });
    });
    return clearSelection(updatedState).delete('assigningFeedbackForRows');
  },

  'RecordPairs.updateFeedbackAssignmentsCompleted': (state, { pairIndexes }) => {
    const pairs = state.pairs.filter((pair, i) => pairIndexes.has(i));
    const rpIds = Set(pairs.map(pair => pair.toRecordPairId()));
    return removeCurrentlyProcessingIds(state, rpIds)
      .update('shouldCheckFilterViolations', s => s.merge(rpIds));
  },

  'RecordPairs.provideFeedbackResponses': (state, { rowNumbers, responseKey, loggedInUsername, projectId, users }) => {
    checkArg({ rowNumbers }, ArgTypes.Immutable.set.of(ArgTypes.number));
    checkArg({ responseKey }, ArgTypes.valueIn(['MATCH', 'NON_MATCH', 'SKIP']));
    checkArg({ loggedInUsername }, ArgTypes.string);
    checkArg({ projectId }, ArgTypes.number);
    checkArg({ users }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(MinimalAuthUser)));
    const pairs = state.pairs.filter((pair, i) => rowNumbers.has(i));
    let updatedState = addCurrentlyProcessingIds(state, Set(pairs.map(pair => pair.toRecordPairId())));
    updatedState = updateFeedbackWithResponses(updatedState, { rowNumbers, responseKey, loggedInUsername });
    updatedState = rollBackLabels(updatedState, { rowNumbers, projectId, users });
    return clearSelection(updatedState)
      .delete('warningAboutRemoveResponse');
  },

  'RecordPairs.provideFeedbackResponsesCompleted': (state, { rowNumbers }) => {
    const pairs = state.pairs.filter((pair, i) => rowNumbers.has(i));
    const rpIds = Set(pairs.map(pair => pair.toRecordPairId()));
    return removeCurrentlyProcessingIds(state, rpIds)
      .update('shouldCheckFilterViolations', s => s.merge(rpIds));
  },

  'RecordPairs.removeFeedbackResponses': (state, { rowNumbers, loggedInUsername, projectId, users }) => {
    checkArg({ rowNumbers }, ArgTypes.Immutable.set.of(ArgTypes.number));
    checkArg({ loggedInUsername }, ArgTypes.string);
    checkArg({ projectId }, ArgTypes.number);
    checkArg({ users }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(MinimalAuthUser)));
    // add to currently processing
    const pairs = state.pairs.filter((pair, i) => rowNumbers.has(i));
    let updatedState = addCurrentlyProcessingIds(state, Set(pairs.map(pair => pair.toRecordPairId())));
    // update feedback
    updatedState = updateFeedbackWithRemovedResponses(updatedState, { rowNumbers, loggedInUsername });
    updatedState = rollBackLabels(updatedState, { rowNumbers, projectId, users });
    return clearSelection(updatedState);
  },

  'RecordPairs.removeFeedbackResponsesCompleted': (state, { rowNumbers }) => {
    const pairs = state.pairs.filter((pair, i) => rowNumbers.has(i));
    const rpIds = Set(pairs.map(pair => pair.toRecordPairId()));
    return removeCurrentlyProcessingIds(state, rpIds)
      .update('shouldCheckFilterViolations', s => s.merge(rpIds));
  },

  'RecordPairs.respondAndVerify': (state, { rowNumbers, responseKey, loggedInUsername }) => {
    checkArg({ rowNumbers }, ArgTypes.Immutable.set.of(ArgTypes.number));
    checkArg({ loggedInUsername }, ArgTypes.string);
    checkArg({ responseKey }, ArgTypes.valueIn(['MATCH', 'NON_MATCH', 'SKIP']));
    // add to currently processing
    const pairs = state.pairs.filter((pair, i) => rowNumbers.has(i));
    let updatedState = addCurrentlyProcessingIds(state, Set(pairs.map(pair => pair.toRecordPairId())));
    // update feedback
    updatedState = updateFeedbackWithResponses(updatedState, { rowNumbers, responseKey, loggedInUsername });
    // update manual labels
    if (responseKey !== 'SKIP') {
      const manualLabels = pairs.map(p => new RecordPairLabel({ ...p.toJSON(), manualLabel: responseKey })).toSet();
      updatedState = updateLabels(updatedState, { manualLabels });
    }
    return clearSelection(updatedState)
      .delete('warningAboutRemoveResponse');
  },

  'RecordPairs.respondAndVerifyCompleted': (state, { rowNumbers }) => {
    const pairs = state.pairs.filter((pair, i) => rowNumbers.has(i));
    const rpIds = Set(pairs.map(pair => pair.toRecordPairId()));
    return removeCurrentlyProcessingIds(state, rpIds)
      .update('shouldCheckFilterViolations', s => s.merge(rpIds));
  },

  'RecordPairs.removeResponseAndVerify': (state, { rowNumbers, loggedInUsername, projectId, users }) => {
    checkArg({ rowNumbers }, ArgTypes.Immutable.set.of(ArgTypes.number));
    checkArg({ loggedInUsername }, ArgTypes.string);
    checkArg({ projectId }, ArgTypes.number);
    checkArg({ users }, ArgTypes.Immutable.list.of(ArgTypes.instanceOf(MinimalAuthUser)));
    // add to currently processing
    const pairs = state.pairs.filter((pair, i) => rowNumbers.has(i));
    let updatedState = addCurrentlyProcessingIds(state, Set(pairs.map(pair => pair.toRecordPairId())));
    // update feedback
    updatedState = updateFeedbackWithRemovedResponses(updatedState, { rowNumbers, loggedInUsername });
    updatedState = rollBackLabels(updatedState, { rowNumbers, projectId, users });
    return clearSelection(updatedState)
      .delete('warningAboutRemoveResponse');
  },

  'RecordPairs.removeResponseAndVerifyCompleted': (state, { rowNumbers }) => {
    const pairs = state.pairs.filter((pair, i) => rowNumbers.has(i));
    const rpIds = Set(pairs.map(pair => pair.toRecordPairId()));
    return removeCurrentlyProcessingIds(state, rpIds)
      .update('shouldCheckFilterViolations', s => s.merge(rpIds));
  },

  'RecordPairs.warnAboutRemoveResponse': (state, { action, rowNumbers }) => {
    return state.set('warningAboutRemoveResponse', new RemoveResponseWarningInfo({ action, rowNumbers }));
  },

  'RecordPairs.cancelRemovingResponse': (state) => {
    return state.delete('warningAboutRemoveResponse');
  },

  'RecordPairs.selectPairRow': (state, { keyMods, rowNum }) => {
    const { selectedRows, lastSelectedRow } = keyModSelect({
      keyMods,
      selectedRows: state.selectedRowNumbers,
      selectedRow: rowNum,
      lastSelectedRow: state.activeRowNumber,
    });
    return state.merge({ selectedRowNumbers: selectedRows, activeRowNumber: lastSelectedRow });
  },

  'RecordPairs.selectAllPairRows': (state) => {
    return state.update('selectedRowNumbers', nums => {
      return nums.size === state.pairs.size ? nums.clear() : Range(0, state.pairs.size).toSet();
    });
  },

  'Dedup.fetchDedupModel': (state) => {
    return state.delete('dedupModelExists');
  },

  'Dedup.fetchDedupModelCompleted': (state) => {
    return state.set('dedupModelExists', true);
  },

  'Dedup.fetchDedupModelFailed': (state) => {
    return state.set('dedupModelExists', false);
  },

  'RecordPairs.clickGeospatialSimilarity': (state, { attributeName }) => {
    return state.merge({
      activeKey: SidebarTabs.DETAILS,
      isSidebarExpanded: true,
      showingDetailsDialog: false,
      activeGeospatialAttribute: new ActiveGeospatialAttribute({
        name: attributeName,
        isOpenDialog: false,
      }),
    });
  },
  'RecordPairs.closeGeospatialDetails': (state) => {
    return state.merge({ activeGeospatialAttribute: null });
  },
  'RecordPairs.openGeospatialDetailsDialog': (state) => {
    const { activeGeospatialAttribute } = state;
    if (activeGeospatialAttribute === null) {
      return state;
    }
    return state.merge({
      activeGeospatialAttribute: activeGeospatialAttribute
        .set('isOpenDialog', true),
    });
  },
  'RecordPairs.closeGeospatialDetailsDialog': (state) => {
    const { activeGeospatialAttribute } = state;
    if (activeGeospatialAttribute === null) {
      return state;
    }
    return state.merge({
      activeGeospatialAttribute: activeGeospatialAttribute
        .set('isOpenDialog', false),
    });
  },
  [FETCH_USER_DEFINED_SIGNALS]: (state) => {
    return state.set('loadingUserDefinedSignals', true);
  },
  [FETCH_USER_DEFINED_SIGNALS_COMPLETED]: (state, { userDefinedSignals }) => {
    return state.merge({
      loadingUserDefinedSignals: false,
      loadedUserDefinedSignals: true,
      userDefinedSignals,
    });
  },
  [FETCH_USER_DEFINED_SIGNALS_FAILED]: (state) => {
    return state.merge({
      loadingUserDefinedSignals: false,
      loadedUserDefinedSignals: true,
    });
  },
};
