import { useEffect, useRef } from 'react';

type Callback = (e: Event) => void

/**
 * Adapted from https://slides.com/tannerlinsley/custom-hooks-in-react#/38
 */
function useClickOutside(callback: Callback) {
  const elRef = useRef<Element>();

  const callbackRef = useRef<Callback>();
  callbackRef.current = callback;

  useEffect(() => {
    function handleClickOutside(e: Event) {
      if (
        e.target instanceof Node &&
        elRef.current &&
        callbackRef.current &&
        !elRef.current.contains(e.target)
      ) {
        callbackRef.current(e);
      }
    }
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [callbackRef, elRef]);

  return elRef;
}

export default useClickOutside;
