import { is } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import { AppState } from '../stores/MainStore';
import { ConnectedProps } from '../utils/typescript-react';
import NotificationBox from './NotificationBox';

const AUTO_DISMISS_WAIT_TIME = 10000; // 10s

const mapState = (state: AppState) => {
  const { notification: { notificationType, parameters, permanent } } = state;
  return { notificationType, parameters, permanent };
};

const mapDispatch = {
  onHide: () => ({ type: 'Notifications.hide' }),
};

const connector = connect(
  mapState,
  mapDispatch,
);

type PropsFromRedux = ConnectedProps<typeof connector>;

class Notifications extends React.Component<PropsFromRedux> {
  UNSAFE_componentWillReceiveProps(nextProps: PropsFromRedux) {
    const newNotification = nextProps.notificationType &&
      (nextProps.notificationType !== this.props.notificationType || !is(nextProps.parameters, this.props.parameters));
    if (newNotification) {
      this.resetDismissTimer(nextProps.permanent);
    }
  }

  clearDismissTimer = () => {
    // @ts-expect-error
    if (this.dismissTimer) {
      // @ts-expect-error
      clearTimeout(this.dismissTimer);
    }
  };

  resetDismissTimer = (permanent: boolean) => {
    this.clearDismissTimer();
    if (!permanent) {
      // @ts-expect-error
      this.dismissTimer = setTimeout(this.onHide, AUTO_DISMISS_WAIT_TIME);
    }
  };

  onHide = () => {
    this.props.onHide();
  };

  render() {
    return (
      <NotificationBox
        onMouseEnter={this.clearDismissTimer}
        onMouseLeave={() => this.resetDismissTimer(this.props.permanent)}
      />
    );
  }
}

export default connector(Notifications);
