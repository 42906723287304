import { List } from 'immutable';
import moment from 'moment';
import React from 'react';

import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import TooltipTrigger from '../components/TooltipTrigger';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';

const DatasetLastUpdatedColumn: React.FC<{
  datasets: List<Document<Dataset>>
}> = ({ datasets }) => {
  return (
    <Column
      key="timestamp"
      width={110}
      isResizable
      columnKey="timestamp"
      flexGrow={1}
      header={<Cell>Updated</Cell>}
      cell={({ rowIndex }) => { // eslint-disable-line react/no-multi-comp
        const datasetDoc = datasets.get(rowIndex);
        if (!datasetDoc) return <Cell />;
        const { username, timestamp } = datasetDoc.lastModified;
        return (
          <Cell>
            <TooltipTrigger content={`By ${username}`}>
              <span>{moment(timestamp * 1000).fromNow()}</span>
            </TooltipTrigger>
          </Cell>
        );
      }}
    />
  );
};

export default DatasetLastUpdatedColumn;
