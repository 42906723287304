import { useState } from 'react';

/**
 * react hook for allowing functional components to force a rerender.
 * pattern from https://stackoverflow.com/questions/46240647/react-how-can-i-force-render-a-function-component
 */
export default function useForceUpdate() {
  const [, setValue] = useState<number>(0);
  return () => setValue(value => ++value); // update the state to force render
}
