import { List, Map } from 'immutable';

import { getExternalSample, getExternalStorageNodeChildren, getExternalStorageProviders, postDataset } from '../api/DatasetClient';
import { FetchResult } from '../api/FetchResult';
import * as RecipeClient from '../api/RecipeClient';
import { UPLOAD_AND_CREATE_FAILED } from '../datasets/FileUploadActionTypes';
import { failedUpload, uploadAndCreate } from '../datasets/FileUploadAsync';
import { submitMembershipChanges } from '../datasets/ProjectDatasetCatalogAsync';
import { updateEnrichmentInputDataset } from '../enrichment/EnrichmentAsync';
import { apiError } from '../errorDialog/ErrorDialogUtils';
import { AppThunkAction } from '../stores/AppAction';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import * as Result from '../utils/Result';
import { isDefined } from '../utils/Values';
import DatasetSource from './DatasetSource';
import DatasetSpecWithTableConfig from './DatasetSpecWithTableConfig';
import DriverSpec from './DriverSpec';
import { getChildStorageNodes, getStorageNodeSample, mapifyCsvConfig } from './ExternalStorageContentStore';
import { AVRO, BIGQUERY, CSV, DIRECTORY, getDisplayName, MimeTypeE, PARQUET } from './MimeType';
import PathElement from './PathElement';
import SampleRecords from './SampleRecords';
import StorageNode from './StorageNode';
import { BIGQUERY_DATASET, BIGQUERY_PROJECT, BIGQUERY_TABLE, FILE_TYPE, PATH } from './TableConfig';


// constants and types to keep track of which mime types we currently support uploading
const mimeTypesSupportingUpload = [AVRO, BIGQUERY, CSV, PARQUET] as const;
type MimeTypeSupportingUpload = typeof mimeTypesSupportingUpload[number];
export function isMimeTypeSupportingUpload(mimeType: MimeTypeE | null | undefined): mimeType is MimeTypeSupportingUpload {
  return !!(mimeType && mimeTypesSupportingUpload.includes(mimeType as MimeTypeSupportingUpload));
}

export const startAddDataset = ({ isCatalogPage }: { isCatalogPage: boolean }): AppThunkAction<void> => (dispatch) => {
  dispatch({ type: 'AddDataset.startAddDataset', isCatalogPage });
  getExternalStorageProviders()
    .then(externalStorageProviders => dispatch({ type: 'AddDataset.fetchStorageProvidersCompleted', externalStorageProviders }))
    .catch(response => dispatch({ type: 'AddDataset.fetchStorageProvidersFailed', response }));
};

function getPathForFileGroup({ selectedNode, selectedNodeType, path, content }: {
  selectedNode: StorageNode | null
  selectedNodeType: MimeTypeE
  path: List<string>
  content: Map<string, PathElement> | undefined
}): List<string> | null {
  if (selectedNode && selectedNode.mimeType !== DIRECTORY) return null;
  if (!isMimeTypeSupportingUpload(selectedNodeType)) return null;

  const children = content && getChildStorageNodes({ path, content });

  const child = children && children.filter(c => c.mimeType === selectedNodeType).first(null);

  // `child` could be undefined as a result of calling `first()`.
  if (!child) return null;

  return path.push(child.name);
}

export const createExternalDataset = (addToProject?: boolean): AppThunkAction<void> => (dispatch, getState) => {
  const {
    externalStorage: { idColumn, datasetName, datasetDescription, selectedNode, selectedNodeType,
      selectedNodeConfig, selectedProvider, storageContent },
    enrichment: { module },
    location: { recipeId },
  } = getState();
  if (!selectedNode && !selectedNodeType) {
    console.error('Cannot createExternalDataset - selectedNode is null and we have not chosen a node group');
    return;
  }
  if (!datasetName) {
    console.error('Cannot createExternalDataset - datasetName is null');
    return;
  }
  if (!selectedProvider) {
    console.error('Cannot createExternalDataset - selectedProvider is null');
    return;
  }
  const content = storageContent.get(selectedProvider);
  if (!content) {
    console.error(`Cannot createExternalDataset - no storage content for provider ${selectedProvider}`);
    return;
  }

  const path = selectedNode
    ? selectedNode.parent.push(selectedNode.name)
    : List(); // tracks case where we're at the top-level of the storage provider bucket

  dispatch({ type: 'AddDataset.createExternalDataset' });

  let sampleFetchPromise: Promise<FetchResult<SampleRecords>> | null = null;

  // If the selected node is a directory, we have to get a list of fields from one of the child file's schema.
  if (!selectedNode || selectedNode.mimeType === DIRECTORY) {
    if (!selectedNodeType) {
      console.error('Cannot createExternalDataset for node group - selectedNodeType is null');
      return;
    }
    const childPath = getPathForFileGroup({ selectedNode, selectedNodeType, path, content });

    const directoryName = selectedNode ? `directory ${selectedNode.name}` : 'top-level directory';

    if (!childPath) {
      console.error(`Cannot createExternalDataset - the ${directoryName} does not contain any ${selectedNodeType} files.`);
      return;
    }

    // Make an API call as we don't have the schema information in the store right now.
    sampleFetchPromise = getExternalSample({
      storageProviderName: selectedProvider,
      path: childPath.join('/'),
      numRecords: 2, // Only need to get the header fields from the schema.
    });
  } else {
    // expect the schema information to already be in the store.
    // this is because the selected node is a file, not a directory, and we load file samples when the user first selects
    //   the file node.
    const sampleFromStorage = getStorageNodeSample({ path, content });
    if (!sampleFromStorage) {
      console.error(`Cannot createExternalDataset - no stored sample (required for its schema) for node ${selectedNode.name}`);
      return;
    }
    sampleFetchPromise = Promise.resolve(Result.constructSuccess(sampleFromStorage));
  }

  sampleFetchPromise
    .then(Result.handler(
      (sample) => {
        const mimeTypeForUpload = selectedNodeType /* in case this is set explicitly, aka for node groups */ || selectedNode?.mimeType;
        if (!mimeTypeForUpload) {
          throw new Error('Cannot createExternalDataset - do not know mimetype of selected node');
        }

        let tableConfig;
        if (mimeTypeForUpload === BIGQUERY) {
          tableConfig = Map({
            [BIGQUERY_PROJECT]: path.get(0),
            [BIGQUERY_DATASET]: path.get(1),
            [BIGQUERY_TABLE]: path.get(2),
          });
        } else {
          tableConfig = Map({
            [PATH]: '/' + path.join('/'),
            [FILE_TYPE]: getDisplayName(mimeTypeForUpload),
          }).merge(mapifyCsvConfig(selectedNodeConfig));
        }

        postDataset({
          datasetSpec: new DatasetSpecWithTableConfig({
            name: datasetName,
            description: datasetDescription || undefined,
            fields: sample.schema.fields.map(f => f.name),
            idFields: List.of(idColumn),
            driverSpecs: List.of(new DriverSpec({ driverName: selectedProvider, tableConfig })),
          }),
        }).then(Result.handler(
          datasetDocument => {
            if (addToProject) {
              // module being present indicates that this is an enrichment module
              if (module) {
                updateEnrichmentInputDataset(dispatch, getState, module, datasetDocument.data.name).then(Result.handler(
                  () => {}, // success handling has already been done (dispatched) by updateEnrichmentInputDataset
                  error => apiError(dispatch,
                    'Could not add registered external dataset to enrichment module',
                    error,
                    // don't want to also dispatch createExternalDatasetFailed here, since it was created...
                  ),
                ));
              } else {
                RecipeClient.addInputDatasetToRecipe(recipeId, datasetDocument.data.name).then(Result.handler(
                  () => dispatch({ type: 'AddDataset.createExternalDatasetCompleted', datasetDocument }),
                  error => {
                    const errorMessage = failedUpload(error);
                    dispatch({
                      type: UPLOAD_AND_CREATE_FAILED,
                      errorMessageHeader: 'Error adding dataset to project.',
                      errorMessage,
                    });
                  },
                ));
              }
            }
            dispatch({ type: 'AddDataset.createExternalDatasetCompleted', datasetDocument });
          },
          error => apiError(dispatch,
            `Could not upload external dataset ${path.join('/')}`,
            error,
            { type: 'AddDataset.createExternalDatasetFailed' },
          ),
        ));
      },
      error => apiError(dispatch,
        `Could not fetch sample for ${path.join('/')} (needed for upload)`,
        error,
        { type: 'AddDataset.createExternalDatasetFailed' },
      ),
    ));
};

export const addDataset = (addToProject: boolean | undefined): AppThunkAction<void> => (dispatch, getState) => {
  checkArg({ addToProject }, ArgTypes.orUndefined(ArgTypes.bool));
  const { addDataset: { selectedTab } } = getState();
  dispatch({ type: 'AddDataset.addDataset' });
  switch (selectedTab) {
    case DatasetSource.UPLOAD:
      return dispatch(uploadAndCreate(addToProject));
    case DatasetSource.CATALOG:
      return dispatch(submitMembershipChanges());
    case DatasetSource.CONNECT:
      return dispatch(createExternalDataset(addToProject));
    default:
      return dispatch({ type: 'AddDataset.addDatasetFailed', response: 'Attempted to add dataset from unsupported tab' });
  }
};

export const selectExternalStorageNode = (node: StorageNode): AppThunkAction<void> => (dispatch, getState) => {
  const { externalStorage: { selectedProvider } } = getState();
  dispatch({ type: 'AddDataset.selectExternalStorageNode', node });

  if (!selectedProvider) {
    console.error('Cannot selectExternalStorageNode - selectedProvider is null');
    return;
  }

  const path = node.parent.push(node.name).join('/');

  // retrieve storage node's contents.
  const childrenPromise = getExternalStorageNodeChildren({
    storageProviderName: selectedProvider,
    path,
  });

  // If the selected node is a CSV or AVRO file, retrieve a sample of the data.
  const samplePromise = isMimeTypeSupportingUpload(node.mimeType)
    ? getExternalSample({
      storageProviderName: selectedProvider,
      path,
      numRecords: 100, // The default number of records to read for a sample.
    })
    : undefined;

  const promises = [
    isMimeTypeSupportingUpload(node.mimeType)
      ? getExternalSample({
        storageProviderName: selectedProvider,
        path,
        numRecords: 100, // The default number of records to read for a sample.
      })
      : undefined,
  ].filter(isDefined);

  // If the selected node is a CSV or AVRO file, retrieve a sample of the data.
  if (isMimeTypeSupportingUpload(node.mimeType)) {
    promises.push(
      getExternalSample({
        storageProviderName: selectedProvider,
        path,
        numRecords: 100, // The default number of records to read for a sample.
      }),
    );
  }

  Promise.all([childrenPromise, samplePromise]).then(([nodesResult, sampleResult]) => {
    Result.handle(nodesResult,
      (nodes) => dispatch({
        type: 'AddDataset.selectExternalStorageNodeCompleted',
        selectedProvider,
        nodes,
        path: node.parent.push(node.name),
        sampleRecords: (sampleResult && sampleResult.isSuccess && sampleResult.data) || undefined,
      }),
      error => apiError(dispatch,
        'Error selecting node',
        error,
      ),
    );
  });
};

export const selectExternalStorageProvider = (name: string): AppThunkAction<void> => (dispatch) => {
  dispatch({ type: 'AddDataset.setExternalStorageProvider', name });
  getExternalStorageNodeChildren({ storageProviderName: name, path: null }).then(Result.handler(
    nodes => dispatch({ type: 'AddDataset.setExternalStorageProviderCompleted', name, nodes }),
    error => apiError(dispatch, 'Error selecting external storage provider', error),
  ));
};
