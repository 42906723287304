import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import { startAddDataset } from '../addDataset/AddDatasetAsync';
import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import ConditionalButton from '../components/ConditionalButton';
import Selector from '../components/Input/Selector';
import TooltipTrigger from '../components/TooltipTrigger';
import TagManager from '../tags/TagManager';
import TagSelector from '../tags/TagSelector';
import { history } from '../utils/History';
import { beginConfirmingDelete } from './DatasetCatalogApi';
import { ALL, NON_PROJECT_DERIVED, PROJECT_GENERATED, SOURCE, SYSTEM_DATASET, UNIFIED_DATASET } from './DatasetCatalogFilterType';
import style from './DatasetCatalogTaskbar.module.scss';
import { DatasetTypeText } from './DatasetUtils';

const filterValues = [
  { value: ALL, display: 'All Datasets' },
  { value: SOURCE, display: <TooltipTrigger placement="right" content={DatasetTypeText.SOURCE}><div>Source</div></TooltipTrigger> },
  { value: UNIFIED_DATASET, display: <TooltipTrigger placement="right" content={DatasetTypeText.UNIFIED_DATASET}><div>Unified Datasets</div></TooltipTrigger> },
  { value: PROJECT_GENERATED, display: <TooltipTrigger placement="right" content={DatasetTypeText.PROJECT_GENERATED}><div>Results and Internals</div></TooltipTrigger> },
  { value: NON_PROJECT_DERIVED, display: <TooltipTrigger placement="right" content={DatasetTypeText.NON_PROJECT_DERIVED}><div>Sample, Group-by and API-derived</div></TooltipTrigger> },
  { value: SYSTEM_DATASET, display: <TooltipTrigger placement="right" content={DatasetTypeText.SYSTEM_DATASET}><div>System</div></TooltipTrigger> },
];

const DatasetCatalogTaskbar = _.compose(
  connect((state) => {
    const { datasetCatalog: { datasets, noDatasets, selectedRows, paging: { filterType } } } = state;
    return {
      datasets,
      noDatasets,
      selectedRows,
      filterType,
    };
  }, {
    onBeginConfirmingDelete: beginConfirmingDelete,
    onConfirmProfile: (datasetNames) => ({ type: 'SharedDatasetCatalog.confirmProfile', datasetNames }),
    onStartManagingForDatasets: (datasets) => ({ type: 'ManageProjectDatasets.startManagingForDatasets', datasets }),
    onShowFileUploadDialog: () => startAddDataset({ isCatalogPage: true }),
    onSetFilterType: (filterType) => ({ type: 'DatasetCatalog.setFilterType', filterType }),
  }),
)(({ noDatasets, onBeginConfirmingDelete, filterType, onSetFilterType, selectedRows, datasets,
  onShowFileUploadDialog, onStartManagingForDatasets, onConfirmProfile }) => {
  if (noDatasets) {
    return <noscript />;
  }
  const mustSelectPreconditions = Map().set('You must select one or more datasets', !selectedRows.isEmpty());
  const mustSelectOnePreconditions = Map().set('You must select exactly one dataset', selectedRows.size === 1);
  const selectedDatasets = selectedRows.map(i => datasets.get(i));
  const selectedDatasetNames = selectedDatasets.map(d => d.data.name);
  return (
    <div className={style.component}>
      <div className={style.filterContainer}>
        <span>Filter:</span>
        <Selector
          placeholder="Dataset Filters"
          className={style.filterSelector}
          values={filterValues}
          value={filterType}
          onChange={onSetFilterType}
        >
          Filter
        </Selector>
      </div>
      <ButtonToolbar className={style.buttons}>
        <ConditionalButton
          buttonType="Secondary"
          onClick={() => onStartManagingForDatasets(selectedDatasetNames)}
          preconditions={mustSelectPreconditions}
        >
          Input to projects
        </ConditionalButton>
        <ConditionalButton
          buttonType="Secondary"
          onClick={() => onConfirmProfile(selectedDatasetNames)}
          preconditions={mustSelectPreconditions}
        >
          Profile
        </ConditionalButton>
        <TagSelector selectedDatasets={selectedDatasets} />
        <ConditionalButton
          buttonType="Secondary"
          onClick={() => onBeginConfirmingDelete(selectedDatasets)}
          preconditions={mustSelectPreconditions}
        >
          Delete
        </ConditionalButton>
        <ConditionalButton
          buttonType="Secondary"
          preconditions={mustSelectOnePreconditions}
          onClick={() => history.push(`/datasets/groupby/${datasets.get(selectedRows.first()).id.id}`)}
        >
          Create Group-by dataset
        </ConditionalButton>
        <Button
          buttonType="Primary"
          onClick={() => {
            onShowFileUploadDialog();
          }}
        >
          Add new dataset
        </Button>
      </ButtonToolbar>
      <TagManager />
    </div>
  );
});

export default DatasetCatalogTaskbar;
