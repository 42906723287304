import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import { EnricherRegistration } from '../models/EnricherRegistration';
import { EnrichmentModule, OutputField } from './EnrichmentModule';

export const FETCH_MODULE = 'Enrichment.fetchModule';
export const FETCH_MODULE_COMPLETED = 'Enrichment.fetchModuleCompleted';
export const FETCH_MODULE_STATUS_COMPLETED = 'Enrichment.fetchModuleStatusCompleted';
export const FETCH_MODULE_FAILED = 'Enrichment.fetchModuleFailed';
export const FETCH_INPUT_DATASET = 'Enrichment.fetchInputDataset';
export const FETCH_INPUT_DATASET_COMPLETED = 'Enrichment.fetchInputDatasetCompleted';
export const FETCH_INPUT_DATASET_FAILED = 'Enrichment.fetchInputDatasetFailed';
export const FETCH_TYPES = 'Enrichment.fetchTypes';
export const FETCH_TYPES_COMPLETED = 'Enrichment.fetchTypesCompleted';
export const FETCH_TYPES_FAILED = 'Enrichment.fetchTypesFailed';
export const CREATE_MODULE = 'Enrichment.createModule';
export const CREATE_MODULE_COMPLETED = 'Enrichment.createModuleCompleted';
export const CREATE_MODULE_FAILED = 'Enrichment.createModuleFailed';
export const UPDATE_MODULE = 'Enrichment.updateModule';
export const UPDATE_MODULE_COMPLETED = 'Enrichment.updateModuleCompleted';
export const UPDATE_MODULE_FAILED = 'Enrichment.updateModuleFailed';
export const SUBMIT_PUBLISH = 'Enrichment.submitPublish';
export const SUBMIT_PUBLISH_COMPLETED = 'Enrichment.submitPublishCompleted';
export const SUBMIT_PUBLISH_FAILED = 'Enrichment.submitPublishFailed';

export const SET_TYPE = 'Enrichment.setType';
export const SET_DATASET_NAME = 'Enrichment.setDatasetName';
export const SET_FIELD_MAPPING = 'Enrichment.setFieldMapping';
export const SET_OUTPUT_FIELDS = 'Enrichment.setOutputFields';

export const OUTPUT_DATASET_NAME_VALID = 'Enrichment.outputNameValid';
export const OUTPUT_DATASET_NAME_INVALID = 'Enrichment.outputNameInvalid';

interface FetchModule {
  type: typeof FETCH_MODULE
}

interface FetchModuleCompleted {
  type: typeof FETCH_MODULE_COMPLETED
  module: Document<EnrichmentModule>
}

interface FetchModuleStatusCompleted {
  type: typeof FETCH_MODULE_STATUS_COMPLETED
  moduleHasBeenSubmitted: boolean
}

interface FetchModuleFailed {
  type: typeof FETCH_MODULE_FAILED
  errorMessage: string
}

interface FetchInputDataset {
  type: typeof FETCH_INPUT_DATASET
}

interface FetchInputDatasetCompleted {
  type: typeof FETCH_INPUT_DATASET_COMPLETED
  inputDataset: Document<Dataset>
}

interface FetchInputDatasetFailed {
  type: typeof FETCH_INPUT_DATASET_FAILED
  errorMessage: string
}

interface FetchTypes {
  type: typeof FETCH_TYPES
}

interface FetchTypesCompleted {
  type: typeof FETCH_TYPES_COMPLETED
  types: EnricherRegistration[]
}

interface FetchTypesFailed {
  type: typeof FETCH_TYPES_FAILED
  errorMessage: string
}

interface CreateModule {
  type: typeof CREATE_MODULE
}

interface CreateModuleCompleted {
  type: typeof CREATE_MODULE_COMPLETED
  module: Document<EnrichmentModule>
}

interface CreateModuleFailed {
  type: typeof CREATE_MODULE_FAILED
  errorMessage: string
}

interface UpdateModule {
  type: typeof UPDATE_MODULE
}

interface UpdateModuleCompleted {
  type: typeof UPDATE_MODULE_COMPLETED
  module: Document<EnrichmentModule>
}

interface UpdateModuleFailed {
  type: typeof UPDATE_MODULE_FAILED
  errorMessage: string
}

interface SubmitPublish {
  type: typeof SUBMIT_PUBLISH
}

interface SubmitPublishCompleted {
  type: typeof SUBMIT_PUBLISH_COMPLETED
}

interface SubmitPublishFailed {
  type: typeof SUBMIT_PUBLISH_FAILED
  errorMessage: string
}

interface SetType {
  type: typeof SET_TYPE
  enricherType: EnricherRegistration
}

interface SetDatasetName {
  type: typeof SET_DATASET_NAME
  datasetName: string
}

interface SetFieldMapping {
  type: typeof SET_FIELD_MAPPING
  inputName: string
  enricherName: string
}

interface SetOutputFields {
  type: typeof SET_OUTPUT_FIELDS
  outputFields: OutputField[]
}

interface OutputDatasetNameInvalid {
  type: typeof OUTPUT_DATASET_NAME_INVALID
}

interface OutputDatasetNameValid {
  type: typeof OUTPUT_DATASET_NAME_VALID
}

export type EnrichmentActionTypes =
  | FetchModule
  | FetchModuleCompleted
  | FetchModuleStatusCompleted
  | FetchModuleFailed
  | FetchInputDataset
  | FetchInputDatasetCompleted
  | FetchInputDatasetFailed
  | FetchTypes
  | FetchTypesCompleted
  | FetchTypesFailed
  | OutputDatasetNameInvalid
  | OutputDatasetNameValid
  | CreateModule
  | CreateModuleCompleted
  | CreateModuleFailed
  | UpdateModule
  | UpdateModuleCompleted
  | UpdateModuleFailed
  | SubmitPublish
  | SubmitPublishCompleted
  | SubmitPublishFailed
  | SetType
  | SetDatasetName
  | SetFieldMapping
  | SetOutputFields;
