import classnames from 'classnames';
import { ReactNodeLike } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import CenterContent from '../components/CenterContent';
import LoadingPanel from '../components/LoadingPanel';
import { AppState } from '../stores/MainStore';
// @ts-expect-error
import { fetchFunctionNames } from '../transforms/TransformsApi';
import BookmarksOnboardingMessageDialog from './BookmarksOnboardingMessageDialog';
import ClusterProfileJobLoader from './ClusterProfileJobLoader';
import { GrPageClusterSampleLoader } from './ClusterSampleLoader';
import ConfirmRemoveSourceFromDatasetFiltersDialog from './ConfirmRemoveSourceFromDatasetFiltersDialog';
import ConflictDialog from './ConflictDialog';
import DraftRecordsLoader from './DraftRecordsLoader';
import EditDatasetFilterDialog from './EditDatasetFilterDialog';
import EditDatasetFilterDialogDragLayer from './EditDatasetFilterDialogDragLayer';
import EditOverridesOverlay, { OverrideTargetInfo } from './EditOverridesOverlay';
import style from './GoldenRecord.module.scss';
import GoldenRecordsActionConfirmationDialogs from './GoldenRecordsActionConfirmationDialogs';
import { SET_PAGE, SET_PAGE_SIZE } from './GoldenRecordsActionTypes';
import { GrPageClusterSampleTable } from './GoldenRecordsClusterSampleTable';
import GoldenRecordsFilterPanel from './GoldenRecordsFilterPanel';
import GoldenRecordsHeader from './GoldenRecordsHeader';
import { getCurrentGoldenRecordUI, getIndexDraftJobIsRunning, selectIndexDraftHasBeenRun } from './GoldenRecordsSelectors';
import { columnKeyToAttributeName } from './GoldenRecordsStore';
import GoldenRecordsTable from './GoldenRecordsTable';
import GoldenRecordsTaskbar from './GoldenRecordsTaskbar';
import ModuleFromLastUpdateLoader from './ModuleFromLastUpdateLoader';
import ModuleLoader from './ModuleLoader';
import SourceListLoader from './SourceListLoader';
import TotalDraftRecordsLoader from './TotalDraftRecordsLoader';

const mapStateToProps = (store: AppState) => {
  const { moduleStatus, loadedFilterInfo, loading, updatingOverride, draftPage, showFilterPanel } = getCurrentGoldenRecordUI(store);
  const rowCount = draftPage?.items?.size;
  return {
    hasFunctionNames: store.transforms?.functionDocs?.size > 0,
    loadingDraftPage: loading,
    updatingOverride,
    initialDraftPageLoad: loadedFilterInfo !== undefined,
    indexDraftHasBeenRun: selectIndexDraftHasBeenRun(store),
    indexDraftJobIsRunning: getIndexDraftJobIsRunning(store),
    moduleStatusHasBeenLoaded: !!moduleStatus,
    rowCount,
    showFilterPanel,
  };
};

const mapDispatchToProps = {
  onFetchFunctionNames: fetchFunctionNames,
  onSetPageSize: (pageSize: number) => ({ type: SET_PAGE_SIZE, pageSize }),
  onSetPage: (pageNum: number) => ({ type: SET_PAGE, pageNum }),
};

type GoldenRecordsProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
type GoldenRecordsState = {
  overrideTargetInfo: OverrideTargetInfo | undefined,
}

class UnconnectedGoldenRecords extends React.PureComponent<GoldenRecordsProps, GoldenRecordsState> {
  componentDidMount() {
    const { hasFunctionNames, onFetchFunctionNames } = this.props;
    !hasFunctionNames && onFetchFunctionNames();
  }

  state: GoldenRecordsState = {
    overrideTargetInfo: undefined,
  };

  closeOverridePopover = () => this.setState({ overrideTargetInfo: undefined });

  showOverridePopover = ({ columnKey, editOverridePopoverRef, clusterId }: {
    columnKey: string
    editOverridePopoverRef: React.RefObject<HTMLDivElement>
    existingOverrideValue: string
    beforeOverrideValue: ReactNodeLike
    clusterId: string
  }) => {
    const overrideTargetInfo: OverrideTargetInfo = {
      editOverridePopoverRef,
      clusterId,
      attributeName: columnKeyToAttributeName(columnKey),
    };
    this.setState({ overrideTargetInfo });
  };

  render() {
    const { moduleStatusHasBeenLoaded, loadingDraftPage, updatingOverride, initialDraftPageLoad,
      indexDraftJobIsRunning, indexDraftHasBeenRun, showFilterPanel } = this.props;
    const { overrideTargetInfo } = this.state;
    return (
      <div className={style.pageContainer}>
        <ModuleLoader />
        <SourceListLoader />
        <ClusterProfileJobLoader />
        <DraftRecordsLoader />
        <ModuleFromLastUpdateLoader />
        <TotalDraftRecordsLoader />
        <EditDatasetFilterDialog />
        <ConfirmRemoveSourceFromDatasetFiltersDialog />
        <BookmarksOnboardingMessageDialog />
        <ConflictDialog />
        <GoldenRecordsActionConfirmationDialogs />
        <EditDatasetFilterDialogDragLayer />
        <div className={style.tableContainerSection}>
          <GoldenRecordsHeader />
          <div className={style.innerContent}>
            {showFilterPanel ? <GoldenRecordsFilterPanel /> : null}
            <div className={classnames(style.tablePanel, { [style.showFilterPanel]: showFilterPanel })}>
              <GoldenRecordsTaskbar />
              <div className={style.tableContainer}>
                {!moduleStatusHasBeenLoaded || (!initialDraftPageLoad && loadingDraftPage) ? (
                  <LoadingPanel message="Loading Golden Records" />
                ) : !indexDraftHasBeenRun && !indexDraftJobIsRunning ? (
                  <CenterContent className="unauthorized-page">
                    Update Golden Records to view your table.
                  </CenterContent>
                ) : !indexDraftHasBeenRun && indexDraftJobIsRunning ? (
                  <LoadingPanel message="Generating Golden Records." />
                ) : (
                  <React.Fragment>
                    {loadingDraftPage || updatingOverride ? <LoadingPanel aboveCenter /> : null}
                    <GoldenRecordsTable showOverridePopover={this.showOverridePopover} />
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <EditOverridesOverlay
            {...{ overrideTargetInfo }}
            onClose={this.closeOverridePopover}
          />
        </div>
        <GrPageClusterSampleLoader />
        <GrPageClusterSampleTable />
      </div>
    );
  }
}

const GoldenRecords = connect(mapStateToProps, mapDispatchToProps)(UnconnectedGoldenRecords);
export default GoldenRecords;
