import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import ConditionalButton from '../components/ConditionalButton';
import WarningDialog from '../components/WarningDialog';
import { isJobRunningForActiveUnifiedDataset } from '../utils/Selectors';
import style from './SuggestionButton.module.scss';

/**
 * A button that launches one of the schema mapping suggestion pipelines
 */
const SuggestionButton = _.compose(
  connect(state => {
    const { schemaMapping: { allUnifiedAttributes } } = state;
    return {
      jobRunning: isJobRunningForActiveUnifiedDataset(state),
      noMappedUnifiedAttributes: !allUnifiedAttributes.some(({ mappedAttributes }) => mappedAttributes.size),
    };
  }),
)(/**
 * A button that launches one of the schema mapping suggestion pipelines
 */
  class SuggestionButton extends React.Component {
    static propTypes = {
      additionalPreconditions: ImmutablePropTypes.mapOf(PropTypes.bool, PropTypes.node),
      jobRunning: PropTypes.bool.isRequired,
      noMappedUnifiedAttributes: PropTypes.bool.isRequired,
      operation: PropTypes.func.isRequired,
      text: PropTypes.string.isRequired,
    };

    static defaultProps = {
      additionalPreconditions: new Map(),
    };

    state = {
      showingWarningDialog: false,
    };

    showWarningDialog = () => {
      this.setState({ showingWarningDialog: true });
    };

    hideWarningDialog = () => {
      this.setState({ showingWarningDialog: false });
    };

    render() {
      const { jobRunning, noMappedUnifiedAttributes, additionalPreconditions } = this.props;
      const showWarningPreconditions = new Map({
        'You have no mapped unified attributes.': !noMappedUnifiedAttributes,
        'There is already a job running for this unified dataset.': !jobRunning,
      }).merge(additionalPreconditions);

      return (
        <div className={style.suggestionButton}>
          <ConditionalButton
            buttonType="Secondary"
            onClick={this.showWarningDialog}
            preconditions={showWarningPreconditions}
        >
            {this.props.text}
          </ConditionalButton>
          <WarningDialog
            actionName={this.props.text}
            message="Updating suggestions might take a while. Are you sure you want to proceed?"
            onAccept={this.props.operation}
            onHide={this.hideWarningDialog}
            show={this.state.showingWarningDialog}
        />
        </div>
      );
    }
  });

export default SuggestionButton;
