import PolicyMemberType, { PolicyMemberTypeE } from '../constants/PolicyMemberType';
import { ArgTypes, checkArg } from '../utils/ArgValidation';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from './Model';
import ResourceSpec from './ResourceSpec';

const TYPE_SEPARATOR = ':';

export default class Member extends getModelHelpers({
  memberType: { type: ArgTypes.valueIn(PolicyMemberType) },
  identifier: { type: ResourceSpec.argType },
}, 'Member')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class MemberRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  toString() {
    return `${this.memberType}${TYPE_SEPARATOR}${this.identifier.toJSON()}`;
  }

  static fromString(str: string) {
    checkArg({ str }, ArgTypes.string);
    const [memberType, identifier] = str.split(TYPE_SEPARATOR);
    return new Member({
      memberType: memberType as PolicyMemberTypeE,
      identifier: ResourceSpec.fromJSON(identifier),
    });
  }
}
