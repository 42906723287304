import { ArgTypes } from '../utils/ArgValidation';
import { checkTypesAndCast } from '../utils/CheckTypesAndCast';
import * as EnricherInputField from './EnricherInputField';
import * as EnricherOutputField from './EnricherOutputField';

export interface EnricherSchema {
  inputs: EnricherInputField.EnricherInputField[]
  outputs: EnricherOutputField.EnricherOutputField[]
}

export const argTypes = {
  inputs: ArgTypes.array.of(EnricherInputField.argType),
  outputs: ArgTypes.array.of(EnricherOutputField.argType),
} as const;

export const fromJSON = checkTypesAndCast<EnricherSchema, typeof argTypes>(argTypes);
export const argType = ArgTypes.object.withShape(argTypes);
