import { List, OrderedMap } from 'immutable';

import BulkTransform from '../../models/BulkTransform';
import { getModelHelpers, InferConstructorArgTypes, InferReadTypes } from '../../models/Model';
import { ArgTypes, checkArg } from '../../utils/ArgValidation';
import { $TSFixMe } from '../../utils/typescript';
import { localForageKeyPrefix } from './LocalForageUtils';
import TransformDelta from './TransformDelta';


export const storedTransformsStateKey = (projectTimestamp: number, projectId: number) =>
  `${localForageKeyPrefix(projectId, projectTimestamp)}TRANSFORMS_STATE`;

export default class StoredTransformsState extends getModelHelpers({
  unifiedDatasetId: { type: ArgTypes.number },
  transforms: { type: ArgTypes.Immutable.orderedMap.of(BulkTransform.argType, ArgTypes.string /* guid */) },
  deltas: { type: ArgTypes.Immutable.list.of(TransformDelta.argType) },
}, 'StoredTransformsState')(({ RecordClass, typesAndDefaults, checkConstructorArgs, checkSetArgs }) => {
  type ConstructorArgTypes = InferConstructorArgTypes<typeof typesAndDefaults>;
  type ReadTypes = InferReadTypes<typeof typesAndDefaults>;
  return class StoredTransformsStateRecord extends RecordClass {
    constructor(args: ConstructorArgTypes) {
      checkConstructorArgs(args);
      super(args);
    }
    set<T extends keyof ReadTypes>(name: T, value: ReadTypes[T]) {
      checkSetArgs(name, value);
      return super.set(name, value);
    }
  };
}) {
  static get argType() { return ArgTypes.instanceOf(this); }

  static fromJSON(obj: $TSFixMe) {
    checkArg({ obj }, ArgTypes.object);
    const { unifiedDatasetId, transforms, deltas } = obj;

    return new StoredTransformsState({
      unifiedDatasetId,
      transforms: OrderedMap<string, BulkTransform>(transforms).map(transform => BulkTransform.fromStorageJSON(transform)),
      deltas: List(deltas).map(delta => TransformDelta.fromJSON(delta, BulkTransform.fromStorageJSON)),
    });
  }

  // @ts-expect-error this method returns a string rather than a json object - should just return a json object
  //                  (and, obviously, will have to rewire usages)
  toJSON() {
    const { unifiedDatasetId, transforms, deltas } = this;

    return JSON.stringify({
      unifiedDatasetId,
      transforms: OrderedMap(transforms).map(transform => transform.toJSON()),
      deltas: List(deltas).map(delta => TransformDelta.toJSON(delta)),
    });
  }
}
