import { Map, Set } from 'immutable';

import Model from '../models/Model';
import { ArgTypes } from '../utils/ArgValidation';

/**
 * stores rollup stats for all projects (not active context aware)
 */

export const initialState = new (Model({
  byProjectId: { type: ArgTypes.Immutable.map.of(ArgTypes.number, ArgTypes.number), defaultValue: Map() },
  loading: { type: ArgTypes.Immutable.set.of(ArgTypes.number), defaultValue: Set() },
}))();

export const reducers = {
  'ProjectStats.reset': (state) => {
    return state.clear();
  },
  'ProjectStats.statsFetchStarting': (state, { projectId }) => {
    return state.update('loading', s => s.add(projectId));
  },
  'ProjectStats.statsFetchCompleted': (state, { projectId, value }) => {
    return state
      .setIn(['byProjectId', projectId], value)
      .set('loading', state.loading.remove(projectId));
  },
};
