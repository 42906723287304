import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import Voter, { Vote } from '../components/Voter';
import Response from '../constants/Response';
import EsRecord from '../models/EsRecord';
import MinimalAuthUser from '../models/MinimalAuthUser';
import ProcurementTaxonomy from '../models/ProcurementTaxonomy';
import { getAuthorizedUser } from '../utils/Selectors';
import { pluralize } from '../utils/Strings';
import { respond } from './TransactionApi';
import style from './VoterCell.module.scss';

const VoterCell = _.compose(
  connect(state => {
    return { authorizedUser: getAuthorizedUser(state) };
  }, {
    onRespond: respond,
  }),
)(class VoterCell extends React.Component {
  static propTypes = {
    authorizedUser: PropTypes.instanceOf(MinimalAuthUser),
    onRespond: PropTypes.func.isRequired,
    recipeId: PropTypes.number.isRequired,
    record: PropTypes.instanceOf(EsRecord),
    taxonomy: PropTypes.instanceOf(ProcurementTaxonomy),
  };

  getFinalCategorization = () => {
    const { record } = this.props;
    if (record.manualCategorization) {
      return record.manualCategorization.categoryId;
    } if (record.suggestedCategorization) {
      return record.suggestedCategorization.categoryId;
    }
  };

  canAnybodyVote = () => {
    const { record, taxonomy } = this.props;
    return taxonomy && record && (record.suggestedCategorization || record.manualCategorization);
  };

  currentVote = (suggestedCategoryId) => {
    const { record, authorizedUser } = this.props;
    const userResponse = record.getResponseForUser(authorizedUser.username, suggestedCategoryId);

    if (userResponse === Response.AGREE) {
      return Vote.UP;
    }
    if (userResponse === Response.DISAGREE) {
      return Vote.DOWN;
    }
  };

  userString = (count) => {
    return `${count} ${pluralize(count, 'user', 'users')}`;
  };

  render() {
    const { record, onRespond } = this.props;
    if (!this.canAnybodyVote()) {
      return <div />;
    }
    const suggestedCategoryId = this.getFinalCategorization();
    const numUpVotes = record.numResponses(Response.AGREE, suggestedCategoryId);
    const numDownVotes = record.numResponses(Response.DISAGREE, suggestedCategoryId);
    const voteDifferential = record.feedback.size ? numUpVotes - numDownVotes : undefined;
    const tooltip = `${this.userString(numUpVotes)} upvoted, ${this.userString(numDownVotes)} downvoted`;
    return (
      <div className={style.cell}>
        <div title={tooltip}>
          <Voter
            numVotes={voteDifferential}
            currentVote={this.currentVote(suggestedCategoryId)}
            canVote
            onVoteCancel={() => onRespond({ record, categoryId: suggestedCategoryId })}
            onVoteUp={() => onRespond({ record, categoryId: suggestedCategoryId, response: Response.AGREE })}
            onVoteDown={() => onRespond({ record, categoryId: suggestedCategoryId, response: Response.DISAGREE })}
          />
        </div>
      </div>
    );
  }
});

export default VoterCell;
