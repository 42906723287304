import { List } from 'immutable';

import RecordComment from '../models/RecordComment';
import RecordCommentId from '../models/RecordCommentId';
import { AppThunkAction } from '../stores/AppAction';
import { getAuthorizedUser, getUnifiedDatasetName } from '../utils/Selectors';
import {
  deleteRecordComment,
  putRecordComment,
} from './TransactionClient';
import {
  COMMENT,
  COMMENT_COMPLETED,
  COMMENT_FAILED,
  DELETE_COMMENT,
  DELETE_COMMENT_COMPLETED,
  DELETE_COMMENT_FAILED,
  EDIT_COMMENT,
  EDIT_COMMENT_COMPLETED,
  EDIT_COMMENT_FAILED,
} from './TransactionsActionTypes';
import { getActiveRecord } from './TransactionStore';

export const comment = (message: string): AppThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const { transactions } = state;
  const activeRecord = getActiveRecord(transactions);

  const user = getAuthorizedUser(state);
  if (!user) {
    console.error('Cannot comment because there is no authorized user');
    return;
  }
  const unifiedDatasetName = getUnifiedDatasetName(state);
  if (!unifiedDatasetName) {
    console.error('Cannot comment because there is no handle to a unified dataset name');
    return;
  }
  const timestamp = Math.floor(Date.now() / 1000);
  const recordComment = new RecordComment({
    entityId: activeRecord.recordId,
    username: user.username,
    createdAt: timestamp,
    modifiedAt: timestamp,
    message,
  });
  dispatch({ type: COMMENT, id: activeRecord.recordId });
  putRecordComment(List.of(recordComment), unifiedDatasetName)
    .then(() => dispatch({ type: COMMENT_COMPLETED, id: activeRecord.recordId }))
    .catch(response => {
      dispatch({ type: COMMENT_FAILED, id: activeRecord.recordId, detail: 'Error adding comment', response });
    });
};

export const editComment = (
  recordComment: RecordComment,
  message: string,
): AppThunkAction<void> => (dispatch, getState) => {
  const { transactions } = getState();
  const activeRecord = getActiveRecord(transactions);
  const timestamp = Math.floor(Date.now() / 1000);
  const unifiedDatasetName = getUnifiedDatasetName(getState());
  if (!unifiedDatasetName) {
    console.error('Cannot comment because there is no handle to a unified dataset name');
    return;
  }
  const newComment = new RecordComment(
    {
      entityId: recordComment.entityId,
      username: recordComment.username,
      createdAt: recordComment.createdAt,
      modifiedAt: timestamp,
      message,
    },
  );
  dispatch({ type: EDIT_COMMENT, id: activeRecord.recordId });
  putRecordComment(List.of(newComment), unifiedDatasetName)
    .then(() => dispatch({ type: EDIT_COMMENT_COMPLETED, id: activeRecord.recordId }))
    .catch((response) => dispatch({ type: EDIT_COMMENT_FAILED, id: activeRecord.recordId, detail: 'Error editing comment', response }));
};

export const removeComment = (
  recordComment: RecordComment,
): AppThunkAction<void> => (dispatch, getState) => {
  const { transactions } = getState();
  const activeRecord = getActiveRecord(transactions);
  const unifiedDatasetName = getUnifiedDatasetName(getState());
  if (!unifiedDatasetName) {
    console.error('Cannot comment because there is no handle to a unified dataset name');
    return;
  }
  dispatch({ type: DELETE_COMMENT, id: activeRecord.recordId });
  deleteRecordComment(
    List.of(new RecordCommentId({
      entityId: activeRecord.recordId,
      username: recordComment.username,
      createdAt: recordComment.createdAt,
    })), unifiedDatasetName,
  )
    .then(() => dispatch({ type: DELETE_COMMENT_COMPLETED, id: activeRecord.recordId }))
    .catch((response) => dispatch({ type: DELETE_COMMENT_FAILED, id: activeRecord.recordId, detail: 'Error deleting comment', response }));
};
