import { Map, Record } from 'immutable';

import Dataset from '../models/Dataset';
import DatasetStatus from '../models/DatasetStatus';
import Document from '../models/doc/Document';
import Export from '../models/Export';
import Model from '../models/Model';
import ProfilingInfo from '../models/ProfilingInfo';
import { FETCH_COMPLETED } from '../projects/ProjectsActionTypes';
import { ArgTypes } from '../utils/ArgValidation';

/**
 * Stores all unified datasets for all projects in the system, regardless of active context.
 */

export const initialState = new (Model({
  docsByProjectId: { type: ArgTypes.Immutable.map.of(Document.argTypeWithNestedClass(Dataset), ArgTypes.number), defaultValue: Map() },
  statusByProjectId: { type: ArgTypes.Immutable.map.of(ArgTypes.instanceOf(DatasetStatus), ArgTypes.number), defaultValue: Map() },
  initialFetch: { type: ArgTypes.bool, defaultValue: false },
  profiling: { type: ArgTypes.Immutable.map.of(ArgTypes.instanceOf(ProfilingInfo), ArgTypes.string), defaultValue: new Map() },
  exporting: { type: ArgTypes.Immutable.map.of(Document.argTypeWithNestedClass(Export), ArgTypes.number), defaultValue: new Map() },
  loadedFilterInfo: { type: ArgTypes.any },
  fetchSequence: { type: ArgTypes.number, defaultValue: 1 },
  loading: { type: ArgTypes.bool, defaultValue: false },
}))();

const FilterInfo = Record({ fetchSequence: 1 });

export const getFilterInfo = (state) => {
  const { unifiedDatasets: { fetchSequence } } = state;
  return new FilterInfo({ fetchSequence });
};

const reloadUnifiedDatasets = (state) => state.update('fetchSequence', x => x + 1);

export const reducers = {
  'UnifiedDatasets.fetchAll': (state) => {
    return state.set('loading', true);
  },
  'UnifiedDatasets.fetchAllCompleted': (state, { docsByProjectId, statusByProjectId, profiling, exporting, filterInfo }) => {
    return state.merge({
      loading: false,
      initialFetch: true,
      docsByProjectId,
      statusByProjectId,
      profiling,
      exporting,
      loadedFilterInfo: filterInfo,
    });
  },

  [FETCH_COMPLETED]: reloadUnifiedDatasets,
  'Tags.linkTagCompleted': reloadUnifiedDatasets,
  'Tags.removeLinkCompleted': reloadUnifiedDatasets,
  'Datasets.profileDatasetCompleted': reloadUnifiedDatasets, // TODO detect whether profiled dataset was unified dataset, only reload then
};
