import enumify from './enumify';
import { RecipeOperations } from './RecipeOperations';

/**
 * This is a subset of steps from RecipeOperations.ts.
 */
export const ProjectStepsKey = {
  RECORDS: RecipeOperations.RECORDS,
  PAIRS: RecipeOperations.PAIRS,
  PREDICT_CLUSTER: RecipeOperations.PREDICT_CLUSTER,
  TRAIN_PREDICT_CLUSTERS: RecipeOperations.TRAIN_PREDICT_CLUSTER,
  CATEGORIZATIONS: RecipeOperations.CATEGORIZATIONS,
  PREDICT_CATEGORIZATIONS: RecipeOperations.PREDICT_CATEGORIZATIONS,
  INDEX_DRAFT: RecipeOperations.INDEX_DRAFT,
  TRAIN_MASTERING_MODEL: RecipeOperations.TRAIN_MASTERING_MODEL,
  PREDICT_PAIRS: RecipeOperations.PREDICT_PAIRS,
  CLUSTER_PAIRS: RecipeOperations.CLUSTER_PAIRS,
};

export type ProjectStepsKeyE = typeof ProjectStepsKey[keyof typeof ProjectStepsKey];

export default enumify(ProjectStepsKey);
