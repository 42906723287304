type Dataref = string;

/**
 * @returns a version of the input string with appropriate characters escaped via '\\'
 */
function id(name: string): Dataref {
  // Special characters defined in Dataref
  return name.replace(/([\\ ()])/g, '\\$1');
}

function fn(func: string, args: Dataref[]): Dataref {
  return `(${[id(func)].concat(args).join(' ')})`;
}

export default { id, fn };
