import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'underscore';

import PageHeader from '../chrome/PageHeader';
import ConditionalButton from '../components/ConditionalButton';
import SearchBox from '../components/SearchBox';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import { isCuratorByProjectId, isVerifierByProjectId } from '../utils/Authorization';
import { getAuthorizedUser, isJobRunning, selectActiveProjectInfo } from '../utils/Selectors';
import ClusterRecordsFilters from './ClusterRecordsFilters';
import style from './ClusterRecordsHeader.module.scss';
import Metrics from './metrics/Metrics';
import PublishClustersDialog from './PublishClustersDialog';
import PublishClustersWarningDialog from './PublishClustersWarningDialog';
import { fetchAllPublishDialogStats, toggleClusterDiffVisible } from './SuppliersAsync';
import { getActiveCluster, getActiveRecordIndex, getLastPublishTime, getSelectedRecords } from './SuppliersStore';
import VerifyButton from './VerifyButton';

/**
 * Header element over the suppliers page table of transactions. If a single
 * supplier is selected show details, otherwise show aggregate # of records and
 * the search query string (if applicable).
 */
const ClusterRecordsHeader = _.compose(
  connect((state, { pane }) => {
    const { suppliers: { expanded, twoPanes, [pane]: { loading, loadingSimilarSuppliers, saving, recordsLoading, queryString, selectedSuppliers, isClusterDiffVisible } }, publishClustersDialog: { submitting } } = state;
    return {
      expanded,
      selectedRecords: getSelectedRecords(state, pane),
      loading,
      queryString,
      isUserACurator: isCuratorByProjectId(getAuthorizedUser(state), selectActiveProjectInfo(state)?.projectDoc.id.id),
      isUserAVerifier: isVerifierByProjectId(getAuthorizedUser(state), selectActiveProjectInfo(state)?.projectDoc.id.id),
      suggestionRunning: isJobRunning(state, { recipeOperation: 'trainPredictCluster', forActiveProject: true }),
      twoPanes,
      canClose: !loading && !loadingSimilarSuppliers && !saving && !recordsLoading,
      submitting,
      activeRecordExists: getActiveRecordIndex(state, pane) !== -1,
      activeClusterExists: !!getActiveCluster(state, pane),
      isClusterDiffVisible,
      selectedSuppliers,
      lastPublishTime: getLastPublishTime(state),
    };
  }, (dispatch, { pane }) => bindActionCreators({
    onPinRecords: () => ({ type: 'Suppliers.pinRecords', pane }),
    onUnpinRecords: () => ({ type: 'Suppliers.unpinRecords', pane }),
    onSetQueryString: queryString => ({ type: 'Suppliers.setQueryString', queryString, pane }),
    onCloseClusterBrowser: () => ({ type: 'Suppliers.closeClusterBrowser', pane }),
    onBeginConfirmingMoveRecordsToNew: () => ({ type: 'Suppliers.beginConfirmingMoveRecordsToNew', pane }),
    onToggleSidebar: () => ({ type: 'Suppliers.toggleExpandSidebar' }),
    onOpenPublishClusters: fetchAllPublishDialogStats,
    onToggleClusterDiffVisible: () => toggleClusterDiffVisible(pane),
  }, dispatch)),
)(({ queryString, onSetQueryString, isUserACurator, isUserAVerifier,
  selectedRecords, loading, onPinRecords, onUnpinRecords, onBeginConfirmingMoveRecordsToNew, onOpenPublishClusters, onToggleClusterDiffVisible, isClusterDiffVisible, selectedSuppliers, lastPublishTime,
  suggestionRunning, pane, twoPanes, onCloseClusterBrowser, canClose, iconify, submitting, expanded, onToggleSidebar, activeRecordExists, activeClusterExists }) => {
  const anyUnpinned = selectedRecords.find(r => !r.pinned);
  // escape hatch for navigating to this page before any data has been loaded
  if (loading) {
    return <PageHeader title="Loading..." />;
  }

  const clusterDiffPreconditions = Map()
    .set(<span>You must have already published clusters.</span>, !isNaN(lastPublishTime))
    .set(<span>You must select exactly 1 cluster.</span>, selectedSuppliers.size === 1);
  const jobRunningPrecondition = Map().set(<span>Actions cannot be performed while a clustering job is running.</span>, !suggestionRunning);
  const selectionPrecondition = Map().set(<span>You must select one or more <Term>records</Term>.</span>, !selectedRecords.isEmpty());
  const jobSubmitting = Map().set(<span>Publish clusters job is currently being submitted</span>, !submitting);
  const pinLabel = (anyUnpinned ? 'Pin' : 'Unpin');
  return (
    <div>
      <PageHeader
        className={style.pageHeader}
        title={<div>{null}</div>}
        searchbox={<SearchBox placeholder="Search" value={queryString} onSearch={onSetQueryString} />}
      >
        {isUserACurator ?
          <div className={style.buttons}>
            <Metrics />
            <ConditionalButton
              preconditions={jobRunningPrecondition.merge(jobSubmitting)}
              buttonType="Primary"
              onClick={onOpenPublishClusters}
              tooltipLabel={iconify ? 'Review and publish clusters' : undefined}
            >
              {iconify ? 'Review' : 'Review and publish clusters'}
            </ConditionalButton>
          </div>
          : null}
        {twoPanes ?
          <button className={style.closePane} onClick={onCloseClusterBrowser} disabled={!canClose}>
            <TamrIcon iconName="highlight-off" size={22} />
          </button>
          : null}
      </PageHeader>
      <div className={style.clustersToolbar}>
        <div className={style.clustersToolbarContainer}>
          <ClusterRecordsFilters pane={pane} />
          {(isUserACurator || isUserAVerifier) && <VerifyButton {...{ pane, iconify }} />}
          <ConditionalButton
            preconditions={selectionPrecondition}
            buttonType="Secondary"
            icon="place"
            iconSize={16}
            onClick={anyUnpinned ? onPinRecords : onUnpinRecords}
            tooltipLabel={iconify ? pinLabel : undefined}
          >
            {iconify ? undefined : pinLabel}
          </ConditionalButton>
          {(isUserACurator || isUserAVerifier) ?
            <ConditionalButton
              preconditions={jobRunningPrecondition.merge(selectionPrecondition)}
              buttonType="Secondary"
              icon="call-made"
              iconSize={16}
              onClick={onBeginConfirmingMoveRecordsToNew}
              tooltipLabel={iconify ? 'Move to new' : undefined}
            >
              {iconify ? undefined : 'Move to new'}
            </ConditionalButton>
            : null}
          <ConditionalButton
            preconditions={clusterDiffPreconditions}
            buttonType={isClusterDiffVisible ? 'Shaded' : 'Secondary'}
            icon={isClusterDiffVisible ? 'visibility-off' : 'visibility'}
            iconSize={16}
            onClick={onToggleClusterDiffVisible}
          >
            {
              iconify ? undefined : isClusterDiffVisible ? 'Hide changes' : 'View changes'
            }
          </ConditionalButton>
          <ConditionalButton
            buttonType="Secondary"
            preconditions={Map().set(<span>No <Term>record</Term> nor cluster selected</span>, expanded || (activeRecordExists && !expanded) || (activeClusterExists && !expanded))}
            onClick={onToggleSidebar}
          >
            {expanded ? 'Close details' : 'Open details'}
          </ConditionalButton>
        </div>
      </div>
      <PublishClustersDialog />
      <PublishClustersWarningDialog />
    </div>
  );
});

export default ClusterRecordsHeader;
