// samgqroberts 2020-06-12 I often get questions about this library.
//   Is this just a reimplementation of underscore? lodash? Immutable.js?
//   Well to that I say

import { Set } from 'immutable';
import flow from 'lodash/flow';
import { clone, isArray } from 'underscore';

import { assertNever } from './typescript';
import { isDefined } from './Values';

function updateField<T extends Object, K extends keyof T>(
  target: T,
  fieldName: K,
  fieldUpdater: FieldReducer<T, K>,
): T {
  return { ...target, [fieldName]: fieldUpdater(target[fieldName]) };
}

type ElementReducer<T extends Array<any>> = (element: T[keyof T]) => T[keyof T];

function updateElement<T extends Array<any>>(
  target: T,
  index: number,
  elementUpdater: ElementReducer<T>,
): T {
  if (index >= target.length) return target;
  const element = target[index];
  const c = clone(target);
  c[index] = elementUpdater(element);
  return c;
}

function updateTarget<T extends Object>(
  target: T,
  ...reducers: TargetReducer<T>[]
): T {
  return flow(...reducers)(target);
}

type TargetReducer<T> = (obj: T) => T;
type FieldType<T extends Object, K extends keyof T> = T[K];
type FieldReducer<T extends Object, K extends keyof T> = (fieldValue: FieldType<T, K>) => FieldType<T, K>;

interface Update {
  <T extends Object> (
    target: T,
    reducer1: TargetReducer<T>,
    ...reducers: TargetReducer<T>[]
  ): T;
  <T extends Object, K extends keyof T> (
    target: T,
    fieldName: K,
    fieldUpdater: FieldReducer<T, K>
  ): T;
  <T extends Array<any>> (
    target: T,
    index: number,
    elementUpdater: (element: T[number]) => T[number],
  ): T;
}

export const update: Update = <T, K extends keyof T>(
  target: T,
  arg1: TargetReducer<T> | K,
  arg2: TargetReducer<T> | FieldReducer<T, K>,
  ...reducers: TargetReducer<T>[]
): T => {
  if (typeof arg1 === 'string') {
    const fieldName = arg1 as K;
    if (typeof arg2 === 'function') {
      const fieldUpdater = arg2 as FieldReducer<T, K>;
      return updateField(target, fieldName, fieldUpdater);
    }
    assertNever(arg2);
  }
  if (isArray(target) && typeof arg1 === 'number') {
    const array = target as any[];
    const index = arg1 as number;
    if (typeof arg2 === 'function') {
      return updateElement(array, index, arg2) as unknown as T;
    }
    assertNever(arg2);
  } else if (typeof arg1 === 'function') {
    const reducer1 = arg1 as TargetReducer<T>;
    if (typeof arg2 === 'function') {
      const reducer2 = arg2 as TargetReducer<T>;
      return updateTarget(target, ...[reducer1, reducer2, ...reducers].filter(isDefined));
    }
    return updateTarget(target, reducer1);
  }
  throw new Error(`Bad arguments passed to update: ${JSON.stringify({ target, arg1, arg2 })}`);
};

interface Updater {
  <T extends Object> (
    reducer1: TargetReducer<T>,
    ...reducers: TargetReducer<T>[]
  ): (target: T) => T;
  <T extends Object, K extends keyof T> (
    fieldName: K,
    fieldUpdater: FieldReducer<T, K>
  ): (target: T) => T;
}

export const updater: Updater = <T extends Object, K extends keyof T>(
  arg1: TargetReducer<T> | K,
  arg2: TargetReducer<T> | FieldReducer<T, K>,
  ...reducers: TargetReducer<T>[]
): ((target: T) => T) => {
  if (typeof arg1 === 'string') {
    const fieldName = arg1 as K;
    if (typeof arg2 === 'function') {
      const fieldUpdater = arg2 as FieldReducer<T, K>;
      return (target) => update(target, fieldName, fieldUpdater);
    }
    assertNever(arg2);
  } else if (typeof arg1 === 'function') {
    const reducer1 = arg1 as TargetReducer<T>;
    if (typeof arg2 === 'function') {
      const reducer2 = arg2 as TargetReducer<T>;
      return (target) => update(target, reducer1, ...[reducer2, ...reducers].filter(isDefined));
    }
    return (target) => update(target, reducer1);
  }
  throw new Error(`Bad arguments passed to updater: ${JSON.stringify({ arg1, arg2 })}`);
};

export function mapObject<T, U>(object: { [key: string]: T }, mapFn: (value: T, key: string) => U): { [key: string]: U } {
  return Object.keys(object).reduce((result, key) => {
    result[key] = mapFn(object[key], key);
    return result;
  }, {} as { [key: string]: U });
}

export function merge<T extends Object>(obj: T, toMerge: Partial<T>, ...fieldNames: (keyof T)[]): T {
  if (fieldNames.length === 0) {
    return { ...obj, ...toMerge };
  }
  if (isDefined(toMerge)) {
    const merged = { ...obj };
    for (const fieldName of fieldNames) {
      const toMergedHasField: boolean
        = isDefined(fieldName)
        && !!toMerge
        && Object.prototype.hasOwnProperty.call(toMerge, fieldName);
      if (toMergedHasField) {
        merged[fieldName] = toMerge[fieldName] as T[typeof fieldName];
      }
    }
    return merged;
  }
  return obj;
}

export function merger<T extends Object>(toMerge: Partial<T>, ...fieldNames: (keyof T)[]): (obj: T) => T {
  return (obj) => merge(obj, toMerge, ...fieldNames);
}

export function mergeExcept<T extends Object>(obj: T, toMerge: Partial<T>, ...fieldNames: (keyof T)[]): T {
  const objKeys = Object.keys(obj) as (keyof T)[];
  return merge(obj, toMerge, ...objKeys.filter(k => !fieldNames.includes(k)));
}

export function set<T extends Object, K extends keyof T>(target: T, field: K, value: T[K]): T {
  if (isArray(target)) {
    const c = clone(target);
    c.splice(field as number, 1, value);
    return c;
  }
  return { ...target, [field]: value };
}

export function setter<T extends Object, K extends keyof T>(field: K, value: T[K]): (obj: T) => T {
  return obj => set(obj, field, value);
}

export function insert<T extends Array<any>>(target: T, index: number, ...values: T[number][]): T {
  const c = clone(target);
  c.splice(index, 0, ...values);
  return c;
}

export function push<T extends Array<any>>(target: T, ...values: T[number][]): T {
  return [...target, ...values] as T;
}

export function union<T extends Array<any>>(target: T, unionBuddy: T): T {
  return Set(target).union(unionBuddy).toArray() as T;
}

export function removeIndex<T extends Array<any>>(target: T, index: number): T {
  if (index === -1) return [...target.slice(0, target.length - 1)] as T;
  return [...target.slice(0, index), ...target.slice(index + 1)] as T;
}

export function getResetFns<T extends Object>(defaultState: T): {
  reset: (target: T, ...fields: (keyof T)[]) => T
  resetter: (...fields: (keyof T)[]) => (target: T) => T
} {
  const reset = (target: T, ...fields: (keyof T)[]) => merge(target, defaultState, ...fields);
  const resetter = (...fields: (keyof T)[]) => (target: T) => reset(target, ...fields);
  return { reset, resetter };
}
