/* eslint react/no-danger:0 */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { DragSource as dragSource, DropTarget as dropTarget } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import _ from 'underscore';

import Button from '../components/Button';
import EditableInlineText from '../components/EditableInlineText';
import Highlighter from '../components/Highlighter';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import WarningDialog from '../components/WarningDialog';
import { profileDataset } from '../datasets/DatasetsApi';
import { attributeId } from '../models/AttributeId';
import AttributeProfilingInfo from '../models/AttributeProfilingInfo';
import Dataset from '../models/Dataset';
import Document from '../models/doc/Document';
import KeyMods from '../models/KeyMods';
import SourceAttribute from '../models/SourceAttribute';
import TagIcon from '../tags/TagIcon';
import { getUnifiedDatasetName } from '../utils/Selectors';
import { ControlledSACellWidth } from './ListHeaderRow';
import ListItemIcon from './ListItemIcon';
import {
  SOURCE_BEGIN_DRAG,
  SOURCE_SELECT_ATTRIBUTE,
  SOURCE_TOGGLE_EXPANDED,
} from './SchemaMappingActionTypes';
import {
  ATTRIBUTE_COL_NAME,
  DATASET_COL_NAME,
  MAPPINGS_COL_NAME,
  mapSource,
  updateSourceDescription,
} from './SchemaMappingAsync';
import style from './SchemaMappingAttributeListItem.module.scss';
import SchemaMappingProfilingInfo from './SchemaMappingProfilingInfo';
import { getSourceProfiling } from './SchemaMappingStore';
import SourceAttributeMappingStatus from './SourceAttributeMappingStatus';

const SourceAttributeItem = _.compose(
  connect((state, { attribute }) => {
    const {
      schemaMapping: { sourceExpanded, sourceSelectedIds, sourceSearchTerm, sourceAttributesRemoved },
      allSourceDatasets: { datasets },
    } = state;
    const { schema, upToDate } = getSourceProfiling(state, attribute.datasetName);
    const id = attributeId(attribute);
    return {
      datasets,
      schema,
      profilingUpToDate: !!upToDate,
      searchTerm: sourceSearchTerm,
      isExpanded: sourceExpanded.has(id),
      isSelected: sourceSelectedIds.has(id),
      isRemoved: sourceAttributesRemoved.has(id),
      datasetName: getUnifiedDatasetName(state),
    };
  }, {
    onBeginDrag: id => ({ type: SOURCE_BEGIN_DRAG, id }),
    onToggleExpanded: id => ({ type: SOURCE_TOGGLE_EXPANDED, id }),
    onClick: (id, keyMods) => ({ type: SOURCE_SELECT_ATTRIBUTE, id, keyMods }),
    onMap: mapSource,
    onUpdateDescription: updateSourceDescription,
    onProfile: profileDataset,
  }),
  dragSource('sourceAttribute', {
    beginDrag: ({ attribute, onBeginDrag }) => {
      onBeginDrag(attributeId(attribute));
      return { attribute };
    },
    canDrag: ({ attribute: { doNotMap }, datasetName, canUserEdit }) => {
      return canUserEdit && datasetName && !doNotMap.has(datasetName);
    },
  }, connector => {
    return { connectDragPreview: connector.dragPreview(), connectDragSource: connector.dragSource() };
  }),
  dropTarget('unifiedAttribute', {
    canDrop: ({ attribute, datasetName }) => {
      return !attribute.doNotMap.has(datasetName);
    },
    drop: ({ attribute, onMap }) => {
      onMap(attributeId(attribute));
    },
  }, (connector, monitor) => {
    return { connectDropTarget: connector.dropTarget(), canDrop: monitor.canDrop(), isOver: monitor.isOver() };
  }),
)(class SourceAttributeItem extends React.Component {
  static propTypes = {
    attribute: PropTypes.instanceOf(SourceAttribute).isRequired,
    canDrop: PropTypes.bool.isRequired,
    canUserEdit: PropTypes.bool.isRequired,
    connectDragPreview: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    datasets: ImmutablePropTypes.listOf(Document.propType.withDataType(Dataset)),
    isExpanded: PropTypes.bool.isRequired,
    isOver: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onBeginDrag: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    onMap: PropTypes.func.isRequired,
    onToggleExpanded: PropTypes.func.isRequired,
    onUpdateDescription: PropTypes.func.isRequired,
    profilingUpToDate: PropTypes.bool.isRequired,
    schema: PropTypes.object,
    searchTerm: PropTypes.string.isRequired,
  };

  state = {
    showingUpdateWarningDialog: false,
  };

  showUpdateWarningDialog = () => {
    this.setState({ showingUpdateWarningDialog: true });
  };

  hideUpdateWarningDialog = () => {
    this.setState({ showingUpdateWarningDialog: false });
  };

  renderDescription = (profileUpToDate) => {
    let profileButton;
    let description;
    const { attribute, onUpdateDescription, onProfile, schema, profilingUpToDate, canUserEdit } = this.props;
    const { datasetName, generated } = attribute;
    if (!canUserEdit || generated) {
      description = (
        <div key="description" title={attribute.description} className={classNames('description', 'disabled', { 'out-of-date': !profileUpToDate })}>
          {attribute.description}&nbsp;
        </div>
      );
    } else {
      description = (
        <EditableInlineText
          key="description"
          className={classNames('description', { 'out-of-date': !profileUpToDate })}
          commitAction={v => onUpdateDescription(attributeId(attribute), v)}
          placeholder="Edit Description"
          value={attribute.description}
        />
      );
    }
    const profileData = AttributeProfilingInfo.fromMetrics(attribute.name, schema.toArray(), profilingUpToDate, datasetName); // If never profilied before, do not flag as out of date
    if (profileData.profiled && !profileUpToDate) {
      profileButton = (
        <span key="profile-button" className="profile-out-of-date">
          <Button buttonType="Link" onClick={this.showUpdateWarningDialog} disabled={!canUserEdit}>
            <TooltipTrigger content="Click to profile dataset" placement="bottom">
              <span>profiling out of date</span>
            </TooltipTrigger>
          </Button>
          <WarningDialog
            actionName="Profile Dataset"
            message={'Profiling a dataset can take a while. Are you sure you want to continue?'}
            onAccept={_.partial(onProfile, datasetName)}
            onHide={this.hideUpdateWarningDialog}
            show={this.state.showingUpdateWarningDialog}
          />
          <TamrIcon className="warning-button" iconName="tamr-icon-warning" size={14} />
        </span>
      );
    }
    return [
      description,
      profileButton,
    ];
  };

  renderExpandedContent = () => {
    const { attribute, canUserEdit, schema, profilingUpToDate } = this.props;
    const profileData = AttributeProfilingInfo.fromMetrics(attribute.name, schema.toArray(), profilingUpToDate, attribute.datasetName);
    return (
      <div className="expanded-content">
        {this.renderDescription(profilingUpToDate)}
        <SchemaMappingProfilingInfo {...profileData.toJS()} canUserEdit={canUserEdit} />
      </div>
    );
  };

  componentDidMount() {
    // Use empty image as a drag preview so browsers don't draw it
    // and we can draw whatever we want on the custom drag layer instead.
    this.props.connectDragPreview(getEmptyImage());
  }

  render() {
    const { searchTerm, datasets, attribute, canDrop, connectDragSource, connectDropTarget, isExpanded, isSelected, isRemoved, isOver, onToggleExpanded, onClick, canUserEdit } = this.props;
    const id = attributeId(attribute);
    const className = classNames('schema-mapping-attribute-list-item', 'source', {
      expanded: isExpanded,
      selected: isSelected,
      [style.isRemoved]: isRemoved,
      'dragging-over': isOver && canDrop,
    });
    const sourceDatasetName = attribute.datasetName;
    return connectDragSource(connectDropTarget(
      <div className={className} onClick={e => onClick(id, KeyMods.fromJSON(e))} title={isRemoved ? 'This attribute does not match the filters on this page' : undefined} data-test-element="sm-source-item">
        <div className="unexpanded-content">
          <ControlledSACellWidth colName={ATTRIBUTE_COL_NAME} className={style.sourceAttributeNameCell} title={attribute.name}>
            <ListItemIcon
              onClick={() => onToggleExpanded(id)}
              iconName={isExpanded ? 'tamr-icon-rounded-arrow-down' : 'tamr-icon-rounded-arrow-right'}
            />
            <Highlighter className={style.attributeName} fullText={attribute.name} highlightText={searchTerm} />
          </ControlledSACellWidth>
          <ControlledSACellWidth colName={DATASET_COL_NAME} className={style.sourceAttributeDatasetCell}>
            <div className={style.datasetName} title={sourceDatasetName}>{sourceDatasetName}</div>
            <div className={style.tagMarker}>
              <TagIcon sourceDatasetName={sourceDatasetName} datasets={datasets} />
            </div>
          </ControlledSACellWidth>
          <ControlledSACellWidth colName={MAPPINGS_COL_NAME} className={style.unifiedAttributeNameCell}>
            {isRemoved ? <i className={style.datasetName}>Mapping changed</i> : <SourceAttributeMappingStatus {...{ canUserEdit, attribute }} advertiseDropTarget={canDrop} />}
          </ControlledSACellWidth>
        </div>
        {isExpanded ? this.renderExpandedContent() : null}
      </div>,
    ));
  }
});

export default SourceAttributeItem;
