/*
 * infrastructure for setting up feature flags
 */

enum FeatureFlag {
  ENRICHMENT = 'ENRICHMENT',
}

const isFlagActive = (itemKey: FeatureFlag) => {
  return String(localStorage.getItem(itemKey)) === 'true';
};

const enableFeatureFlag = (itemKey: FeatureFlag) => {
  localStorage.setItem(itemKey, 'true');
  window.location.reload();
  return 'Enabling feature flag and reloading page';
};

const disableFeatureFlag = (itemKey: FeatureFlag) => {
  localStorage.removeItem(itemKey);
  window.location.reload();
  return 'Disabling feature flag and reloading page';
};

const activeFeatureFlagDisplayNames: string[] = [];
const featureFlagUtils: {[key: string]: Function} = {};

const setupFeatureFlag = (itemKey: FeatureFlag, displayName: string, commandPrefix: string) => {
  const isActive = isFlagActive(itemKey);
  if (isActive) {
    activeFeatureFlagDisplayNames.push(displayName);
    featureFlagUtils[`${commandPrefix}Disable`] = () => disableFeatureFlag(itemKey);
  } else {
    featureFlagUtils[`${commandPrefix}Enable`] = () => enableFeatureFlag(itemKey);
  }
  return isActive;
};

/*
 * definition of existing feature flags
 */

export const FEATURE_FLAG_ENRICHMENT = setupFeatureFlag(
  FeatureFlag.ENRICHMENT,
  'Enrichment',
  'enrichment',
);

/*
 * alert user about active feature flags
 */

if (activeFeatureFlagDisplayNames.length > 0) {
  console.warn(`Active Feature Flags: ${activeFeatureFlagDisplayNames.join(', ')}`);
}

// @ts-expect-error
window.tamrFeatureFlags = featureFlagUtils;
