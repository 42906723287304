import './ButtonToolbar.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { FCWithPropTypes } from '../utils/typescript-react';

const ButtonToolbar = FCWithPropTypes(
  {
    className: PropTypes.string,
    children: PropTypes.node,
  },
  ({ className, children }) => {
    return (
      <div className={classNames('tamr-button-toolbar', className)}>
        {children}
      </div>
    );
  },
);

export default ButtonToolbar;
