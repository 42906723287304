import { ReactNodeLike } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { fetchAllConfigs } from '../config/ConfigAsync';
// @ts-expect-error
import AllSourceDatasetsLoader from '../datasets/AllSourceDatasetsLoader';
// @ts-expect-error
import UnifiedDatasetsLoader from '../datasets/UnifiedDatasetsLoader';
// @ts-expect-error
import ErrorDialog from '../errorDialog/ErrorDialog';
// @ts-expect-error
import TileServersLoader from '../geospatial/TileServersLoader';
// @ts-expect-error
import SparkConfigLoader from '../job/SparkConfigLoader';
import Notifications from '../notification/Notifications';
// @ts-expect-error
import ProjectsLoader from '../projects/ProjectsLoader';
import { AppState } from '../stores/MainStore';
// @ts-expect-error
import { startPollingForMessages } from '../stores/MessagingApi';
import { selectActiveProjectInfo } from '../utils/Selectors';
import { fetchExtensionPageButtons, startStorageHealthStatusPolling } from './ChromeAsync';
// @ts-expect-error
import JobsStatus from './JobsStatus';
// @ts-expect-error
import MenuLayout from './MenuLayout';
// @ts-expect-error
import RunningJobsLoader from './RunningJobsLoader';
import TopNav from './TopNav';


/**
 * Chrome
 * Application chrome (top nav bar, etc.) that is needed on all pages.
 * All page-level components must mount this.
 */

const mapStateToProps = (state: AppState) => {
  const { config: { elasticConfig }, location: { recipeId, page }, chrome: { extensionButtons } } = state;
  const projectInfo = selectActiveProjectInfo(state);
  return { configsLoaded: !!elasticConfig, recipeId, page, projectInfo, extensionButtons };
};

const mapDispatchToProps = {
  onStartPollingForMessages: startPollingForMessages,
  onFetchAllConfigs: fetchAllConfigs,
  onFetchExtensionPageButtons: fetchExtensionPageButtons,
  onStartStorageHealthStatusPolling: startStorageHealthStatusPolling,
};

type ParentProps = {
  className?: string
  children: ReactNodeLike
  rightSidebar?: ReactNodeLike
  isExpanded?: boolean
};

type ChromeProps
  = ReturnType<typeof mapStateToProps>
  & typeof mapDispatchToProps
  & ParentProps;

class UnconnectedChrome extends React.Component<ChromeProps> {
  componentDidMount() {
    const { configsLoaded, onStartPollingForMessages, onFetchAllConfigs, projectInfo, page, onFetchExtensionPageButtons, onStartStorageHealthStatusPolling } = this.props;
    if (!configsLoaded) {
      onFetchAllConfigs();
      onStartPollingForMessages();
    }
    onFetchExtensionPageButtons(page, projectInfo);
    onStartStorageHealthStatusPolling();
  }

  componentDidUpdate(prevProps: ChromeProps) {
    const { projectInfo, page, onFetchExtensionPageButtons } = this.props;
    if (page !== prevProps.page || projectInfo !== prevProps.projectInfo) {
      onFetchExtensionPageButtons(page, projectInfo);
    }
  }

  render() {
    const { recipeId, className, children, rightSidebar, isExpanded, extensionButtons } = this.props;

    return (
      <div>
        <MenuLayout
          className={className}
          navBar={<TopNav recipeId={recipeId} />}
          extensionButtons={extensionButtons}
          main={children}
          sidebar={rightSidebar}
          sidebarIsExpanded={isExpanded}
        />
        <JobsStatus />
        <Notifications />
        <ErrorDialog />
        <AllSourceDatasetsLoader />
        <RunningJobsLoader />
        <ProjectsLoader />
        <UnifiedDatasetsLoader />
        <SparkConfigLoader />
        <TileServersLoader />
      </div>
    );
  }
}

const Chrome = connect(mapStateToProps, mapDispatchToProps)(UnconnectedChrome);

export default Chrome;
