import { ArgTypes } from '../utils/ArgValidation';
import { checkTypesAndCast } from '../utils/CheckTypesAndCast';
import { genericRuleArgTypes } from './Rule';
import * as SingleInputAttributeRule from './SingleInputAttributeRule';

// com.tamr.recipe.models.flows.goldenRecords.MostCommonValueRule

export const TYPE = 'MostCommonValue';

export interface ModeRule extends SingleInputAttributeRule.AbstractSingleInputAttributeRule {
  type: typeof TYPE
}
export const argTypes = {
  type: ArgTypes.eq(TYPE as typeof TYPE),
  ...genericRuleArgTypes,
} as const;
export const fromJSON = checkTypesAndCast<ModeRule, typeof argTypes>(argTypes);

export const argType = ArgTypes.object.withShape(argTypes);
