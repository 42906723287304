import React from 'react';

import TamrIcon from '../components/TamrIcon';

const ICON_SIZE = 21;

const MappingStatusIcons = ({ mappings, topRecommendations, numRecommendations, filterActivated, isHovered, doNotMap, onToggleFilterRelatedId }) => {
  const showFilterIcon = filterActivated || (isHovered && !(mappings.isEmpty() && topRecommendations.isEmpty()) && !doNotMap);
  const showRecommendations = !(topRecommendations.size <= 1) && !doNotMap;
  return (
    <div className="mappings-status-icons">
      {
        showRecommendations ? (
          <div className="recommendations-icon-container">
            <TamrIcon iconName="tamr-icon-lightbulb" size={ICON_SIZE} className="recommendations-icon" />
            <span className="recommendations-label">{numRecommendations}</span>
          </div>
        ) : null
      }
      {
        showFilterIcon ? (
          <div className="filter-icon-container" onClick={onToggleFilterRelatedId}>
            <TamrIcon iconName="filter-list" className="filter-icon" size={ICON_SIZE} />
          </div>
        ) : null
      }
    </div>
  );
};

export default MappingStatusIcons;
