import classNames from 'classnames';
import { is, List, Range, Set } from 'immutable';
import React from 'react';
import { DragSource as dragSource, DragSourceConnector, DropTarget as dropTarget, DropTargetConnector, DropTargetMonitor } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { connect } from 'react-redux';

import Button from '../components/Button';
import ButtonToolbar from '../components/ButtonToolbar';
import Dialog, { DialogStyle } from '../components/Dialog/Dialog';
import DropdownButton from '../components/DropdownButton';
import Highlighter from '../components/Highlighter';
import SearchBox from '../components/SearchBox';
import TamrIcon from '../components/TamrIcon';
import TooltipTrigger from '../components/TooltipTrigger';
import { AppState } from '../stores/MainStore';
import * as DatasetFilter from './DatasetFilter';
import style from './EditDatasetFilterDialog.module.scss';
import { selectPrioritizedSourcesFromLastPublish } from './GoldenRecordsStore';

const { BucketId } = DatasetFilter;
type BucketIdType = DatasetFilter.BucketIdType;

// get constants from scss layer
const subRowHeight = parseInt(style.subRowHeight, 10);
const headerRowHeight = parseInt(style.headerRowHeight, 10);

const Checkbox: React.FC<{
  selected: boolean
  onClick: TamrIcon['props']['onClick']
}> = ({ selected, onClick }) => (
  <TamrIcon
    iconName={selected ? 'tamr-icon-checkbox-checked' : 'tamr-icon-checkbox-unchecked'}
    className={classNames(style.checkbox, { [style.checked]: selected })}
    onClick={onClick}
    size={14}
  />
);

const priorityCellHandleDragConnection = (connector: DragSourceConnector) => {
  return { connectDragPreview: connector.dragPreview(), connectDragSource: connector.dragSource() };
};

type PriorityCellHandleProps = ReturnType<typeof priorityCellHandleDragConnection>;

const PriorityCellHandle = dragSource('datasetPriority', {
  beginDrag: ({ onBeginDrag, bucketId }) => {
    onBeginDrag(bucketId);
    return { bucketId };
  },
}, priorityCellHandleDragConnection,
)(class PriorityCellHandle extends React.Component<PriorityCellHandleProps> {
  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage());
  }
  render() {
    const { connectDragSource } = this.props;
    return connectDragSource((
      <div className={style.priorityCellHandle}>
        <TamrIcon iconName="drag-handle" size={14} />
      </div>
    ));
  }
});

const PriorityCell: React.FC<{
  bucket: Set<string>
  isLowest: boolean
  isGhostTopPriority: boolean
  onToggleSelected: () => void
  allSourcesInPrioritySelected: boolean
  priorityNum: number
  onBeginDrag: (bucketId: BucketIdType) => void
  bucketId: BucketIdType
  readOnly: boolean
}> = ({ bucket, isLowest, isGhostTopPriority, onToggleSelected, allSourcesInPrioritySelected, priorityNum, onBeginDrag, bucketId, readOnly }) => {
  const isExcludedRow = bucketId.case({ Excluded: () => true, Priority: () => false });
  return (
    <div className={classNames(style.priorityCell, { [style.excludedRow]: isExcludedRow, [style.exactlyOneSource]: bucket.size === 1 })}>
      <div className={style.priorityCellInner}>
        <span className={style.topLine}>
          {!readOnly && <React.Fragment>
            <PriorityCellHandle {...{ onBeginDrag, bucketId }} />
            {isGhostTopPriority || <Checkbox selected={allSourcesInPrioritySelected} onClick={onToggleSelected} />}
          </React.Fragment>}
          {isExcludedRow ? 'Excluded' : priorityNum}
        </span>
        {isLowest ? (
          <span className={style.bottomLine}>
            (lowest)
          </span>
        ) : null}
      </div>
    </div>
  );
};

const sourcesSubRowHandleDragConnection = (connector: DragSourceConnector) => {
  return { connectDragPreview: connector.dragPreview(), connectDragSource: connector.dragSource() };
};

type SourcesSubRowHandleOwnProps = {
  onBeginDrag: () => void
  sourceName: string
}
type SourcesSubRowHandleProps = ReturnType<typeof sourcesSubRowHandleDragConnection>;

const SourcesSubRowHandle = dragSource('datasetPriority', {
  beginDrag: ({ onBeginDrag, sourceName }: SourcesSubRowHandleOwnProps) => {
    onBeginDrag();
    return { sourceName };
  },
}, sourcesSubRowHandleDragConnection,
)(class SourcesSubRowHandle extends React.Component<SourcesSubRowHandleProps> {
  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage());
  }
  render() {
    const { connectDragSource } = this.props;
    return connectDragSource((
      <div className={style.sourcesSubRowHandle}>
        <TamrIcon iconName="drag-handle" size={14} />
      </div>
    ));
  }
});

const SourcesSubRow: React.FC<{
  sourceName: string
  noLongerPresentInSourceList: boolean
  onSelectDataset: (sourceName: string) => void
  isNewSinceLastPublish: boolean
  searchString: string
  onConfirmRemoveSourceFromDatasetFilters: (sourceName: string) => void
  selected: boolean
  onBeginDrag: () => void
  readOnly: boolean
}> = (({ sourceName, noLongerPresentInSourceList, onSelectDataset, isNewSinceLastPublish, searchString, onConfirmRemoveSourceFromDatasetFilters, selected, onBeginDrag, readOnly }) => {
  return (
    <div className={classNames(style.sourcesSubRow, { [style.sourceNoLongerPresent]: noLongerPresentInSourceList })}>
      {!readOnly && <React.Fragment>
        <SourcesSubRowHandle {...{ onBeginDrag, sourceName }} />
        <Checkbox {...{ selected }} onClick={() => onSelectDataset(sourceName)} />
        </React.Fragment>}
      {isNewSinceLastPublish && (
        <TooltipTrigger content="This dataset is new since last Publish" placement="top">
          <TamrIcon iconName="new" className={style.newIcon} size={18} />
        </TooltipTrigger>
      )}
      <Highlighter className={style.datasetName} highlightText={searchString} highlightClassName={style.highlighted} fullText={sourceName} />
      {noLongerPresentInSourceList && <span className={style.notCurrentlyPresent}>not currently present in the project</span>}
      {!readOnly && noLongerPresentInSourceList && (
        <TamrIcon iconName="delete" className={style.removeSource} size={16} onClick={() => onConfirmRemoveSourceFromDatasetFilters(sourceName)} />
      )}
    </div>
  );
});

const betweenPrioritiesDropTargetConnection = (connector: DropTargetConnector, monitor: DropTargetMonitor) => {
  return { connectDropTarget: connector.dropTarget(), isOver: monitor.isOver({ shallow: true }) };
};

type BetweenPrioritiesDropTargetOwnProps = {
  onDrop: () => void
}
type BetweenPrioritiesDropTargetProps = BetweenPrioritiesDropTargetOwnProps & ReturnType<typeof betweenPrioritiesDropTargetConnection>;

const BetweenPrioritiesDropTarget = dropTarget('datasetPriority', {
  drop: ({ onDrop }: BetweenPrioritiesDropTargetOwnProps) => {
    onDrop();
  },
}, betweenPrioritiesDropTargetConnection,
)(({ connectDropTarget, isOver }: BetweenPrioritiesDropTargetProps) => {
  return connectDropTarget((
    <div className={classNames(style.betweenPrioritiesDropTarget, { [style.draggingOver]: isOver })}>
      <div className={style.indicator} />
    </div>
  ));
});

const rowConnection = (connector: DropTargetConnector, monitor: DropTargetMonitor) => {
  return { connectDropTarget: connector.dropTarget(), isOver: monitor.isOver({ shallow: true }) };
};

type RowProps = {
  index: number
  onMoveToNewPriorityBelow: (index: number) => void
  numRows: number
  excluded: Set<string>
  numPriorities: number
  priorities: List<Set<string>>
  selectedDatasets: Set<string>
  onToggleExcludedSelected: () => void
  onTogglePrioritySelected: (index: number) => void
  filteredExcluded: Set<string>
  filteredPriorities: List<Set<string>>
  onForceSelectBucket: (bucketId: BucketIdType) => void
  sourceList: List<string>
  newSourcesSinceLastPublish: Set<string>
  sourcesPrioritizedInLastPublish: Set<string>
  onSelectDataset: (sourceName: string) => void
  searchString: string
  onConfirmRemoveSourceFromDatasetFilters: (sourceName: string) => void
  onForceSelectDataset: (sourceName: string) => void
  readOnly: boolean
} & ReturnType<typeof rowConnection>;

const Row = dropTarget('datasetPriority', {
  drop: ({ index, numRows, onExclude, onMoveToPriority }) => {
    const isExcludedRow = index === numRows - 1;
    const onDrop = () => (isExcludedRow ? onExclude() : onMoveToPriority(index));
    onDrop();
  },
  canDrop: (_, monitor) => {
    return monitor.isOver({ shallow: true });
  },
}, rowConnection,
)(({ connectDropTarget, isOver, index, onMoveToNewPriorityBelow, numRows, excluded, numPriorities,
  priorities, selectedDatasets, onToggleExcludedSelected, onTogglePrioritySelected, filteredExcluded,
  filteredPriorities, onForceSelectBucket, sourceList, newSourcesSinceLastPublish, sourcesPrioritizedInLastPublish,
  onSelectDataset, searchString, onConfirmRemoveSourceFromDatasetFilters, onForceSelectDataset, readOnly }: RowProps) => {
  const isExcludedRow = index === numRows - 1;
  const isHighest = !isExcludedRow && index === 0;
  const priorityNum = index + 1;
  const isLowest = !isHighest && priorityNum === numPriorities;
  const bucket = (isExcludedRow ? excluded : priorities.get(index)) || Set();
  const allSourcesInPrioritySelected = !bucket.isEmpty() && bucket.every(datasetName => selectedDatasets.has(datasetName));
  const onToggleSelected = isExcludedRow ? onToggleExcludedSelected : () => onTogglePrioritySelected(index);
  const isGhostTopPriority = isHighest && priorities.isEmpty();
  const sources = isExcludedRow
    ? filteredExcluded.toList()
    : priorities.isEmpty()
      ? List()
      : filteredPriorities.get(index)?.toList();
  return connectDropTarget((
    <div className={classNames(style.row, { [style.evenRow]: index % 2 === 0, [style.draggingOver]: isOver })}>
      <div className={style.priorityCellContainer}>
        <PriorityCell
          {... { readOnly, bucket, isLowest, isGhostTopPriority, onToggleSelected, allSourcesInPrioritySelected, priorityNum }}
          onBeginDrag={onForceSelectBucket}
          bucketId={isExcludedRow ? BucketId.Excluded({}) : BucketId.Priority({ index })}
        />
      </div>
      <div className={style.sourcesCellContainer}>
        <div className={classNames(style.sourcesRow, { [style.excludedRow]: isExcludedRow })}>
          {(!sources || sources.isEmpty()) ? (
            <span className={classNames(style.sourcesSubRow, style.noSources)}>No Sources</span>
          ) : (
            sources?.sort().map(sourceName => {
              const selected = selectedDatasets.has(sourceName);
              const isNewSinceLastPublish = newSourcesSinceLastPublish.has(sourceName) && !sourcesPrioritizedInLastPublish.has(sourceName);
              const noLongerPresentInSourceList = !sourceList.includes(sourceName);
              return (
                <SourcesSubRow
                  key={sourceName}
                  {...{ readOnly, sourceName, selected, noLongerPresentInSourceList, onSelectDataset, isNewSinceLastPublish, searchString, onConfirmRemoveSourceFromDatasetFilters }}
                  onBeginDrag={() => onForceSelectDataset(sourceName)}
                />
              );
            })
          )}
        </div>
      </div>
      {isExcludedRow || <BetweenPrioritiesDropTarget onDrop={() => onMoveToNewPriorityBelow(index)} />}
    </div>
  ));
});

const EditDatasetFilterDialog = connect((state: AppState) => {
  const { goldenRecords, goldenRecords: { datasetFilterState, editDatasetFilterSearchString, newSourcesSinceLastPublish, selectedDatasetFilterSources, sourceList } } = state;
  let readOnly = false;
  let datasetFilter: DatasetFilter.DatasetFilter | undefined;
  let hidden = false;
  datasetFilterState.case({
    ReadOnly: ({ filter }) => {
      datasetFilter = filter;
      readOnly = true;
    },
    Editing: ({ filter }) => {
      datasetFilter = filter;
    },
    Hidden: () => { hidden = true; },
  });
  const sourcesPrioritizedInLastPublish = selectPrioritizedSourcesFromLastPublish(goldenRecords).toSet();
  return {
    hidden,
    // originalFilter,
    datasetFilter,
    searchString: editDatasetFilterSearchString,
    selectedDatasets: selectedDatasetFilterSources,
    sourceList,
    newSourcesSinceLastPublish,
    sourcesPrioritizedInLastPublish,
    readOnly,
  };
}, {
  onHide: () => ({ type: 'GoldenRecords.cancelEditingDatasetFilter' }),
  onUpdateSearchString: (searchString: string) => ({ type: 'GoldenRecords.updateEditDatasetFilterSearchString', searchString }),
  onSave: () => ({ type: 'GoldenRecords.saveDatasetFilterEdits' }),
  onSelectDataset: (sourceName: string) => ({ type: 'GoldenRecords.selectDatasetFilterSource', sourceName }),
  onForceSelectDataset: (sourceName: string) => ({ type: 'GoldenRecords.forceSelectDatasetFilterSource', sourceName }),
  onForceSelectBucket: (bucketId: BucketIdType) => ({ type: 'GoldenRecords.forceSelectDatasetFilterBucket', bucketId }),
  onToggleExcludedSelected: () => ({ type: 'GoldenRecords.toggleDatasetFilterExcludedSelected' }),
  onTogglePrioritySelected: (priorityIndex: number) => ({ type: 'GoldenRecords.toggleDatasetFilterPrioritySelected', priorityIndex }),
  onToggleAllSelected: () => ({ type: 'GoldenRecords.toggleAllDatasetFilterSourcesSelected' }),
  onMoveToNewTopPriority: () => ({ type: 'GoldenRecords.moveDatasetsToNewTopPriority' }),
  onMoveToNewBottomPriority: () => ({ type: 'GoldenRecords.moveDatasetsToNewBottomPriority' }),
  onMoveToNewPriorityAbove: () => ({ type: 'GoldenRecords.moveDatasetsToNewPriorityAbove' }),
  onMoveToNewPriorityBelow: (priorityIndex: number) => ({ type: 'GoldenRecords.moveDatasetsToNewPriorityBelow', priorityIndex }),
  onMoveToPriority: (priorityIndex: number) => ({ type: 'GoldenRecords.moveDatasetsToPriority', priorityIndex }),
  onExclude: () => ({ type: 'GoldenRecords.excludeDatasets' }),
  onConfirmRemoveSourceFromDatasetFilters: (datasetName: string) => ({ type: 'GoldenRecords.beginConfirmingRemoveSourceFromDatasetFilters', datasetName }),
})(({ datasetFilter, searchString, selectedDatasets, sourceList, newSourcesSinceLastPublish, sourcesPrioritizedInLastPublish, onSave,
  onSelectDataset, onForceSelectDataset, onForceSelectBucket, onHide, onMoveToNewTopPriority, onMoveToNewBottomPriority,
  onMoveToNewPriorityAbove, onMoveToNewPriorityBelow, onMoveToPriority, onExclude, onUpdateSearchString, onToggleAllSelected,
  onToggleExcludedSelected, onTogglePrioritySelected, onConfirmRemoveSourceFromDatasetFilters, readOnly, hidden }) => {
  let body = null;
  if (!hidden && datasetFilter && sourceList) {
    const { priorities, excluded } = datasetFilter;
    const matchesSearch = (datasetName: string) => datasetName.toLowerCase().includes(searchString.toLowerCase().trim());
    const filteredPriorities = priorities.map(bucket => bucket.filter(matchesSearch));
    const filteredExcluded = excluded.filter(matchesSearch);
    const specificPriorityMoveOptions = priorities
      .map((prio, index) => ({ text: `Into priority ${index + 1}`, onClick: () => onMoveToPriority(index) }));
    const numPriorities = priorities.length;
    const noPrioritizedSources = priorities.length === 0;
    const numExcluded = excluded.length;
    const numRows = noPrioritizedSources ? 2 : numPriorities + 1; // if no priorities, have empty prio 1 row. also include excluded row
    const numPrioritySubRows = noPrioritizedSources ? 1 : priorities.reduce((memo, prio) => memo + prio.length, 0);
    const numExcludedSubRows = numExcluded === 0 ? 1 : numExcluded;
    const numSubRows = numPrioritySubRows + numExcludedSubRows; // plus excluded row
    const maxVisibleSubRows = 9;
    const borderPadding = 4;
    const tableHeight =
      borderPadding + headerRowHeight
      + (numSubRows > maxVisibleSubRows ? maxVisibleSubRows * subRowHeight : numSubRows * subRowHeight)
      + (subRowHeight * 0.5);
    const allSourcesSelected = is(selectedDatasets, DatasetFilter.allDatasets(datasetFilter));
    body = (
      <div className={style.body}>
        <p>
          {readOnly ? 'Datasets are prioritized so that rule applies to records from the datasets in the highest priority first (1 being the highest priority). If no records are found in the first priority, then it will check for datasets in the next priority.' : 'You can prioritize datasets so that the rule applies to them first (1 being the highest priority). Multiple datasets can be grouped in the same priority number. If no records are found in the first priority, then it will check for datasets in the next priority.'}
        </p>
        <div className={style.toolbar}>
          {!readOnly && <React.Fragment>
            <DropdownButton
              text="Move to"
              options={List([
                { text: 'New top priority', onClick: onMoveToNewTopPriority },
                { text: 'New bottom priority', onClick: onMoveToNewBottomPriority },
                { text: 'New priority above', onClick: onMoveToNewPriorityAbove },
                { text: 'New priority below', onClick: onMoveToNewPriorityBelow },
                ...specificPriorityMoveOptions,
              ])}
          />
            <Button className={style.excludeButton} buttonType="Secondary" onClick={onExclude}>
              Exclude
            </Button>
          </React.Fragment>}
          <SearchBox value={searchString} onSearch={onUpdateSearchString} searchOnKeyup />
        </div>
        <div className={style.divider} />
        <div style={{ height: tableHeight }} className={style.tableContainer}>
          <div className={style.headerRow}>
            <div className={classNames(style.priorityCellContainer, style.priorityHeader)}>
              {!readOnly && <Checkbox selected={allSourcesSelected} onClick={onToggleAllSelected} /> }
              Priority
            </div>
            <div className={classNames(style.sourcesCellContainer, style.sourcesHeaderCell)}>
              Datasets
            </div>
          </div>
          <div className={style.rowsContainer}>
            {Range(0, numRows).map(index => {
              return (
                <Row
                  key={'row' + index}
                  {...{ index, numRows, onMoveToNewPriorityBelow, excluded: Set(excluded), numPriorities, priorities: List(priorities).map(p => Set(p)), selectedDatasets, onToggleExcludedSelected, onTogglePrioritySelected, filteredExcluded: Set(filteredExcluded), filteredPriorities: List(filteredPriorities).map(p => Set(p)), onExclude, onMoveToPriority, onForceSelectBucket, sourceList, newSourcesSinceLastPublish, sourcesPrioritizedInLastPublish, onSelectDataset, searchString, onConfirmRemoveSourceFromDatasetFilters, onForceSelectDataset, readOnly }}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
  return (
    <Dialog
      {...{ onHide, body }}
      show={!hidden}
      title={readOnly ? 'Prioritized datasets' : 'Select datasets'}
      dialogStyle={DialogStyle.PRIMARY}
      className={style.dialog}
      footer={readOnly ? <Button onClick={onHide} buttonType="Primary">Close</Button> : (
        <ButtonToolbar>
          <Button onClick={onHide} buttonType="Secondary">Cancel</Button>
          <Button onClick={onSave} buttonType="Primary">Apply</Button>
        </ButtonToolbar>
      )}
    />
  );
});

export default EditDatasetFilterDialog;
