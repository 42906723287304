import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import Selector from '../components/Input/Selector';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import TooltipTrigger from '../components/TooltipTrigger';
import DashboardTimeSelector from '../constants/DashboardTimeSelector';
import { pluralize } from '../utils/Strings';
import ClusterCard, { ClusterAggregationIcons } from './ClusterCard';
import ClusterRecordsGraph from './ClusterRecordsGraph';
import style from './ClusterSidebarClusterContent.module.scss';
import ClusterSidebarClusterLoader from './ClusterSidebarClusterLoader';
import { fetchAllPublishDialogStats } from './SuppliersAsync';
import { getActiveCluster, getLastPublishTime } from './SuppliersStore';

const RECORDS_ADDED_MESSAGE = 'The total number of records that have been added to this cluster since the last publish';
const RECORDS_REMOVED_MESSAGE = 'The total number of records that have been removed from this cluster since the last publish';

const ActivitySection = ({ count, message, verb }) => (
  <div className={style.activitySection}>
    <div className={style.recordChangeInfo}>
      <span className={style.recordChangeCount}>{count} </span>
      <Term>{pluralize(count, 'Record', 'Records')}</Term> {verb}
    </div>
    <TooltipTrigger placement="right" content={<span>{message}</span>}><TamrIcon className={style.activityInfoIcon} iconName="info-outline" size={14} /></TooltipTrigger>
  </div>
);

@connect(state => {
  const { suppliers: { focusedPane, recordsGraphTimePeriod } } = state;
  const { suppliers: { [focusedPane]: { similarSuppliers } } } = state;
  const { clusterId, name, recordCount, clusterVerificationCounts, deletedRecordsRemovedCount, existingRecordsRemovedCount, newRecordsAddedCount, existingRecordsAddedCount } = getActiveCluster(state, focusedPane);
  return {
    clusterId,
    name,
    recordCount,
    lastPublishTime: getLastPublishTime(state),
    numberRecordsAdded: newRecordsAddedCount + existingRecordsAddedCount,
    numberRecordsRemoved: deletedRecordsRemovedCount + existingRecordsRemovedCount,
    recordsGraphTimePeriod,
    totalNumVerifiedHere: clusterVerificationCounts && clusterVerificationCounts.totalNumVerifiedHere,
    totalNumVerifiedElsewhere: clusterVerificationCounts && clusterVerificationCounts.totalNumVerifiedElsewhere,
    similarSuppliers,
    focusedPane,
  };
}, {
  onPublishClusters: fetchAllPublishDialogStats,
  onSetTimePeriod: recordsGraphTimePeriod => ({ type: 'Suppliers.setRecordsGraphTimePeriod', recordsGraphTimePeriod }),
})
export default class ClusterSidebarClusterContent extends React.Component {
  render() {
    const { clusterId, name, recordCount, lastPublishTime, numberRecordsAdded, numberRecordsRemoved, onPublishClusters, onSetTimePeriod, recordsGraphTimePeriod, totalNumVerifiedHere, totalNumVerifiedElsewhere, similarSuppliers, focusedPane } = this.props;
    return (
      <div className={style.clusterContentContainer}>
        <div className={style.clusterName}>{name}</div>
        <ClusterAggregationIcons
          className={style.recordCountRow}
          numberOfRecords={recordCount}
          totalNumVerifiedHere={totalNumVerifiedHere}
          totalNumVerifiedElsewhere={totalNumVerifiedElsewhere}
        />
        {!lastPublishTime ? (<div className={style.noPublishesMessage}>Change details about {name} will be shown here once you have <Button className={style.publishButton} buttonType="Link" onClick={onPublishClusters}>published clusters</Button></div>) : (
          <div>
            <ClusterSidebarClusterLoader targetClusterId={clusterId} />
            <div className={style.recordsGraphContainer}>
              <div className={style.sectionTitle}>
                Cluster records
                <Selector
                  className={style.timePeriodSelector}
                  onChange={onSetTimePeriod}
                  value={recordsGraphTimePeriod}
                  values={[
                    { display: '1 Year', value: DashboardTimeSelector.YEAR },
                    { display: '1 Month', value: DashboardTimeSelector.MONTH },
                    { display: '1 Week', value: DashboardTimeSelector.WEEK },
                    { display: 'All time', value: DashboardTimeSelector.ALL },
                  ]}
                />
              </div>
              <ClusterRecordsGraph />
            </div>
            {/* TODO use SidebarSection here */}
            <div className={style.sectionTitle}>
              <span><span className={style.leftHalf}>Activity since last publish</span>&middot;<span className={style.rightHalf}>{moment(lastPublishTime).format('MM/DD/YYYY')}</span></span>
            </div>
            <ActivitySection count={numberRecordsAdded} message={RECORDS_ADDED_MESSAGE} verb="added" />
            <ActivitySection count={numberRecordsRemoved} message={RECORDS_REMOVED_MESSAGE} verb="removed" />
          </div>
        )}
        <div className={style.sectionTitle}>Cluster ID</div>
        <div className={style.clusterId}>{clusterId}</div>
        <div>
          <div className={style.sectionTitle}>Similar Clusters</div>
          {similarSuppliers.sortBy(s => (-1 * s.similarity)).map(({ id, similarity, cluster }) => {
            return (
              <ClusterCard
                key={id}
                className={style.lastPublishedCard}
                clusterName={cluster.name}
                clusterId={id}
                numberOfRecords={cluster.recordCount}
                clusterVerificationCounts={cluster.clusterVerificationCounts}
                spend={cluster.totalSpend}
                focusedPane={focusedPane}
                similarity={similarity}
              />
            );
          }).toArray()}
        </div>
      </div>
    );
  }
}
