import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import Button from '../components/Button';
import Input from '../components/Input/Input';
import LoadingPanel from '../components/LoadingPanel';
import Toolbar from '../components/Toolbar';
import { AppState } from '../stores/MainStore';
// @ts-expect-error
import CodemirrorTooltipPositioner from '../transforms/CodemirrorTooltipPositioner';
import PRODUCT_NAME from '../utils/ProductName';
import { isReserved } from './constants/ReservedFields';
import EditableGoldenRecordsRule from './EditableGoldenRecordsRule';
import { selectAllExpanded, selectNextRuleNameValid, selectNumberOfChanges } from './GoldenRecordsSelectors';
import style from './GoldenRecordsSidebar.module.scss';
import { selectAllRuleNames } from './GoldenRecordsStore';
import SaveGoldenRecordsRulesButton from './SaveGoldenRecordsRulesButton';


const mapStateToProps = (state: AppState) => {
  const { goldenRecords, goldenRecords: { savingRules } } = state;
  return {
    allRuleNames: selectAllRuleNames(goldenRecords),
    nextRuleNameIsValid: selectNextRuleNameValid(state),
    numChanges: selectNumberOfChanges(state),
    allExpanded: selectAllExpanded(state),
    savingRules,
  };
};

const mapDispatchToProps = {
  expand: (name: string) => ({ type: 'GoldenRecords.expand', name }),
  toggleAllExpanded: () => ({ type: 'GoldenRecords.toggleAllExpanded' }),
  cancelRules: () => ({ type: 'GoldenRecords.cancelRules' }),
  createAttribute: (outputAttributeName: string) => ({ type: 'GoldenRecords.createAttribute', outputAttributeName }),
};

type EditGoldenRecordsRulesSidebarContentProps
  = ReturnType<typeof mapStateToProps>
  & typeof mapDispatchToProps

type EditGoldenRecordsRulesSidebarContentState = {
  showCreateAttributeModal: boolean
  newCreateAttributeName: string
  newCreateAttributeNameChanged: boolean
}

const EditGoldenRecordsRulesSidebarContent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(class EditGoldenRecordsRulesSidebarContent extends Component<EditGoldenRecordsRulesSidebarContentProps, EditGoldenRecordsRulesSidebarContentState> {
  isValid = () => this.props.nextRuleNameIsValid(this.state.newCreateAttributeName);
  cancelRules = () => {
    this.props.cancelRules();
    // Undo the rules yea?
  };
  toggleModal = () => this.setState({
    showCreateAttributeModal: !this.state.showCreateAttributeModal,
    newCreateAttributeName: '',
    newCreateAttributeNameChanged: false,
  });
  createAttribute = () => {
    this.props.createAttribute(this.state.newCreateAttributeName);
    this.props.expand(this.state.newCreateAttributeName);
    this.setState({
      showCreateAttributeModal: false,
      newCreateAttributeName: '',
      newCreateAttributeNameChanged: false,
    });
    // @ts-expect-error
    setTimeout(() => this.last.current.scrollIntoView({ behavior: 'smooth' }), 500);
  };
  setCreateAttributeName = (newCreateAttributeName: string) => this.setState({
    newCreateAttributeName,
    newCreateAttributeNameChanged: true,
  });

  state = {
    showCreateAttributeModal: false,
    newCreateAttributeName: '',
    newCreateAttributeNameChanged: false,
  };
  last = React.createRef();

  render() {
    const { numChanges, allExpanded, savingRules, allRuleNames } = this.props;
    const { showCreateAttributeModal, newCreateAttributeName, newCreateAttributeNameChanged } = this.state;
    const invalid = !this.isValid();
    const invalidText = <div className={style.editRuleErrorMessage}>
      {invalid
        ? 'Enter a name that is not already in use. Names can contain any uppercase or lowercase ' +
        'characters, numbers, and dashes. Names cannot contain period (.), leading and trailing spaces.'
        : null
      }
    </div>;
    const reserved = isReserved(this.state.newCreateAttributeName);
    const reservedNameText = <div className={style.editRuleErrorMessage}>
      { reserved ? `This name is already used by ${PRODUCT_NAME}. Choose another name.` : null}
    </div>;
    return (
      <div className={style.flex}>
        <CodemirrorTooltipPositioner />
        {savingRules ? <LoadingPanel /> : null}
        <div className={style.row}>
          <Toolbar
            className="mt-16 mb-8"
            left={<div className={style.name}>Golden Records</div>}
            right={<React.Fragment>
              <Button disabled={numChanges === 0} buttonType="Secondary" className="mr-8" onClick={this.props.cancelRules}>Cancel changes</Button>
              <SaveGoldenRecordsRulesButton />
            </React.Fragment>} />
          <Toolbar
            className="mt-16 mb-8"
            left={<Button buttonType="Secondary" className="mr-8" onClick={this.toggleModal}> Create Attribute </Button>}
            right={<React.Fragment>
              <Button buttonType="Link" onClick={this.props.toggleAllExpanded} className={style.updateExpanded}>{allExpanded ? 'Collapse all' : 'Expand all'}</Button>
            </React.Fragment>} />
          <Modal show={showCreateAttributeModal} onHide={this.toggleModal}>
            <Modal.Header closeButton>
              <Modal.Title>Create new attribute</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Input type="text" value={newCreateAttributeName} onChange={this.setCreateAttributeName} title="Attribute Name" invalid={newCreateAttributeNameChanged && (invalid || reserved)} />
              {newCreateAttributeNameChanged && invalidText}
              {newCreateAttributeNameChanged && reservedNameText}
            </Modal.Body>
            <Modal.Footer>
              <Button buttonType="Secondary" onClick={this.toggleModal} className="mr-8">Cancel</Button>
              <Button onClick={this.createAttribute} disabled={invalid || reserved}>Create</Button>
            </Modal.Footer>
          </Modal>

        </div>
        <div className={style.rules}>
          {allRuleNames.map(ruleName => <EditableGoldenRecordsRule key={ruleName} name={ruleName} />)}
          <div
            // @ts-expect-error
            ref={this.last}
          />
        </div>
      </div>
    );
  }
});

export default EditGoldenRecordsRulesSidebarContent;
