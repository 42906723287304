// com.tamr.dataset.models.Task

import { List } from 'immutable';

import JobSubmissionStatus from '../constants/JobSubmissionStatus';
import TaskStorageStatus from '../constants/TaskStorageStatus';
import { ArgTypes } from '../utils/ArgValidation';
import DriverState from './DriverState';
import Model from './Model';

class Task extends Model({
  uuid: { type: ArgTypes.string },
  sparkJobStatus: { type: JobSubmissionStatus.argType },
  message: { type: ArgTypes.nullable(ArgTypes.string) },
  storageStatus: { type: TaskStorageStatus.argType },
  storageDrivers: { type: ArgTypes.Immutable.list.of(ArgTypes.instanceOf(DriverState)) },
  dagId: { type: ArgTypes.nullable(ArgTypes.number) },
  nonIncrementalDatasets: { type: ArgTypes.Immutable.list.of(ArgTypes.string) },
  version: { type: ArgTypes.nullable(ArgTypes.number) },
  previousVersion: { type: ArgTypes.nullable(ArgTypes.number) },
  blockingTransactionUuid: { type: ArgTypes.nullable(ArgTypes.string) },
}, 'Task') {
  static fromJSON(obj) {
    const { uuid, sparkJobStatus, message, storageStatus, storageDrivers, dagId, nonIncrementalDatasets, version, previousVersion, blockingTransactionUuid } = obj;
    return new Task({
      uuid,
      sparkJobStatus,
      message,
      storageStatus,
      storageDrivers: storageDrivers ? List(storageDrivers).map(driver => DriverState.fromJSON(driver)) : storageDrivers,
      dagId,
      nonIncrementalDatasets: List(nonIncrementalDatasets),
      version,
      previousVersion,
      blockingTransactionUuid,
    });
  }
}

export default Task;
