import { TRANSFER_CLOUD_TO_TAMR_COMPLETED } from '../coreConnectService/CoreConnectServiceActionTypes';
import { UPLOAD_AND_CREATE_COMPLETED, UPLOAD_AND_CREATE_FAILED, UPLOAD_FILE_FAILED } from '../datasets/FileUploadActionTypes';
import { SUBMIT_MEMBERSHIP_CHANGES_COMPLETED } from '../datasets/ProjectDatasetCatalogActionTypes';
import { StoreReducers } from '../stores/AppAction';
import { getResetFns, merge, set } from '../utils/Collections';
import DatasetSource, { DatasetSourceE } from './DatasetSource';

interface AddDatasetStore {
  selectedTab: DatasetSourceE | null
  addingDataset: boolean
}

export const initialState: AddDatasetStore = {
  selectedTab: null,
  addingDataset: false,
} as const;

const { reset } = getResetFns(initialState);

export const reducers: StoreReducers<AddDatasetStore> = {
  'AddDataset.startAddDataset': (_, { isCatalogPage }) => {
    return set(initialState, 'selectedTab', isCatalogPage ? DatasetSource.UPLOAD : DatasetSource.CATALOG);
  },
  'AddDataset.hideDialog': () => {
    return initialState;
  },
  'AddDataset.setTab': (state, { selectedTab }) => {
    return merge(state, { selectedTab });
  },
  'AddDataset.addDataset': (state) => {
    return set(state, 'addingDataset', true);
  },
  'AddDataset.addDatasetFailed': () => {
    return initialState;
  },
  [UPLOAD_AND_CREATE_FAILED]: (state) => {
    return reset(state, 'addingDataset');
  },
  [UPLOAD_FILE_FAILED]: (state) => {
    return reset(state, 'addingDataset');
  },
  [UPLOAD_AND_CREATE_COMPLETED]: () => {
    return initialState;
  },
  [SUBMIT_MEMBERSHIP_CHANGES_COMPLETED]: () => {
    return initialState;
  },
  'ProjectDatasetCatalog.submitMembershipChangesFailed': () => {
    return initialState;
  },
  'AddDataset.createExternalDatasetCompleted': () => {
    return initialState;
  },
  'AddDataset.createExternalDatasetFailed': () => {
    return initialState;
  },
  [TRANSFER_CLOUD_TO_TAMR_COMPLETED]: () => {
    return initialState;
  },
};
