import classNames from 'classnames';
import { List, Set } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import _ from 'underscore';

import loader from '../../images/tamr-loader.gif';
import CenterContent from '../components/CenterContent';
import ColumnOrderSelector from '../components/ColumnOrderSelector';
import ColumnWidthProvider from '../components/ColumnWidthProvider';
import CommentButton from '../components/CommentButton';
import DataColumn from '../components/DataColumn';
import ErrorBoundary from '../components/ErrorBoundary';
import LoadingPanel from '../components/LoadingPanel';
import MultiValue, { recordValueShim, Value } from '../components/MultiValue';
import ScoreIcon from '../components/ScoreIcon';
import SortableHeaderCell from '../components/SortableHeaderCell';
import Cell from '../components/Table/Cell';
import Column from '../components/Table/Column';
import Pager from '../components/Table/PagerModel';
import Table from '../components/Table/Table';
import TamrIcon from '../components/TamrIcon';
import Term from '../components/Term';
import TextHeaderCell from '../components/TextHeaderCell';
import TooltipTrigger from '../components/TooltipTrigger';
import DataTables from '../constants/DataTables';
import SortState from '../constants/SortState';
import EsRecord from '../models/EsRecord';
import ProcurementTaxonomy from '../models/ProcurementTaxonomy';
import ScoreThresholds from '../models/ScoreThresholds';
import { setColumnWidth } from '../users/UsersAsync';
import PRODUCT_NAME from '../utils/ProductName';
import { getActiveRecipeWithStatus, getUDColumnSettingsForPage } from '../utils/Selectors';
import { getPath } from '../utils/Values';
import CategorizationCell from './CategorizationCell';
import FeedbackProgress from './FeedbackProgress';
import { getActiveRecordIndex, getSelectedRecordIndexes } from './TransactionStore';
import styles from './TransactionTable.module.scss';
import VoterCell from './VoterCell';

const TransactionTable = _.compose(
  connect(state => {
    const { config: { elasticConfig, categorizationStrengthThresholds }, transactions, taxonomy: { taxonomy, loading: taxonomyLoading } } = state;
    const { columnSortStates, pageNum, pageSize, loading, rows, total, sortByConfidence } = transactions;

    const hasSuggestions = !!(
      getPath(getActiveRecipeWithStatus(state), 'materializations', 'categorizations', 'lastRun') ||
      getPath(getActiveRecipeWithStatus(state), 'materializations', 'predictCategorizations', 'lastRun')
    );
    return {
      elasticConfig,
      strengthThresholds: categorizationStrengthThresholds,
      activeRowNumber: getActiveRecordIndex(transactions),
      selectedRowNumbers: getSelectedRecordIndexes(transactions),
      columnSortStates,
      pageNum,
      pageSize,
      loading: loading || taxonomyLoading,
      rows,
      total,
      sortByConfidence,
      taxonomy,
      currentlyProcessing: transactions.currentlyProcessing,
      columnSettings: getUDColumnSettingsForPage(state, DataTables.RECORDS),
      hasSuggestions,
    };
  }, {
    onSort: columnName => ({ type: 'Transactions.toggleSort', columnName }),
    onSetActiveRowNumber: rowNum => ({ type: 'Transactions.setActiveRowNumber', rowNum }),
    onSetPage: pageNum => ({ type: 'Transactions.setPage', pageNum }),
    onSetPageSize: pageSize => ({ type: 'Transactions.setPageSize', pageSize }),
    onSetSelectedRowNumbers: rowNums => ({ type: 'Transactions.setSelectedRowNumbers', rowNums }),
    onProgressConfidenceSort: () => ({ type: 'Transactions.progressConfidenceSort' }),
    onSetColumnWidth: setColumnWidth,
    onOpenCommentForm: () => ({ type: 'Transactions.openCommentForm' }),
    onClickGeoTamrAttribute: attributeName => ({ type: 'Transactions.openGeospatialDetailsSidebar', attributeName }),
  }),
)(class TransactionTable extends React.Component {
  static propTypes = {
    activeRowNumber: PropTypes.number,
    columnSettings: ImmutablePropTypes.listOf(PropTypes.object),
    columnSortStates: PropTypes.object,
    currentlyProcessing: ImmutablePropTypes.mapOf(PropTypes.number, PropTypes.string),
    elasticConfig: PropTypes.object,
    hasSuggestions: PropTypes.bool,
    isUserACurator: PropTypes.bool,
    isUserAVerifier: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    onClickGeoTamrAttribute: PropTypes.func.isRequired,
    onOpenCommentForm: PropTypes.func.isRequired,
    onProgressConfidenceSort: PropTypes.func.isRequired,
    onSetActiveRowNumber: PropTypes.func.isRequired,
    onSetColumnWidth: PropTypes.func.isRequired,
    onSetPage: PropTypes.func.isRequired,
    onSetPageSize: PropTypes.func.isRequired,
    onSetSelectedRowNumbers: PropTypes.func.isRequired,
    onSort: PropTypes.func.isRequired,
    pageNum: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    recipe: PropTypes.object,
    recipeId: PropTypes.number.isRequired,
    rows: ImmutablePropTypes.listOf(PropTypes.instanceOf(EsRecord)).isRequired,
    selectedRowNumbers: ImmutablePropTypes.setOf(PropTypes.number),
    sortByConfidence: PropTypes.oneOf(_.union('', _.values(SortState))),
    strengthThresholds: PropTypes.instanceOf(ScoreThresholds),
    taxonomy: PropTypes.instanceOf(ProcurementTaxonomy),
    total: PropTypes.number.isRequired,
  };

  renderCommentColumn = () => {
    const { rows, onOpenCommentForm } = this.props;
    const header = <div />;
    const cell = ({ rowIndex }) => {
      return [
        <CommentButton key="comment-button" onClick={onOpenCommentForm} hasComments={!_.isEmpty(rows.get(rowIndex).comments)} className="comment-button" />,
      ];
    };
    return (
      <Column key="comments" columnKey="comments" width={25} {...{ header, cell }} allowCellsRecycling fixed />
    );
  };

  renderHighImpactColumn = () => {
    const { rows } = this.props;
    const header = <div />;
    const cell = ({ rowIndex }) => {
      const record = rows.get(rowIndex);
      return [
        !!record.suggestedCategorization && record.suggestedCategorization.isHighImpact
          ? <span title="High impact"><TamrIcon iconName="tamr-icon-lightning-bolt" className="high-impact-icon" size={16} /></span>
          : null,
      ];
    };
    return (
      <Column key="highImpact" columnKey="highImpact" width={25} {...{ header, cell }} allowCellsRecycling fixed />
    );
  };

  renderVoterColumn = () => {
    const { rows, taxonomy, recipeId, currentlyProcessing } = this.props;
    const header = <div />;
    const cell = ({ rowIndex }) => {
      const record = rows.get(rowIndex);
      return [
        <VoterCell key="voter" {...{ recipeId, taxonomy, record }} />,
        currentlyProcessing.has(record.recordId)
          ? <img
              key="spinner"
              src={loader}
              className="record-spinner"
              style={{
                width: 12,
                height: 12,
              }}
          />
          : null,
      ];
    };
    return (
      <Column key="vote" columnKey="vote" width={35} {...{ header, cell }} allowCellsRecycling fixed />
    );
  };

  renderCategorizationColumn = () => {
    const { rows, recipeId, taxonomy, isUserACurator, isUserAVerifier } = this.props;
    const header =
      <TextHeaderCell>
        <TooltipTrigger placement="top" content={`This attribute was created by ${PRODUCT_NAME}`}>
          <TamrIcon size={14} iconName="tamr-icon-logo" className="tamrIconLogo" />
        </TooltipTrigger>
        Categorization
      </TextHeaderCell>;
    const cell = ({ rowIndex }) => {
      return (
        <CategorizationCell
          record={rows.get(rowIndex)}
          {...{ isUserACurator, isUserAVerifier, taxonomy, recipeId }}
          rowNumber={rowIndex}
        />
      );
    };
    return (
      <Column key="categorization" columnKey="categorization" width={250} {...{ header, cell }} fixed isResizable />
    );
  };

  renderConfidenceColumn = () => {
    const { rows, strengthThresholds, onProgressConfidenceSort, sortByConfidence, hasSuggestions } = this.props;
    if (!hasSuggestions) {
      return;
    }
    const header = (
      <SortableHeaderCell
        col="score"
        className="score-header"
        sortCallback={onProgressConfidenceSort}
        sortState={sortByConfidence}
      />
    );
    const cell = ({ rowIndex }) => {
      const record = rows.get(rowIndex);
      const score = getPath(record, 'suggestedCategorization', 'score');
      if (!_.isNumber(score)) {
        return;
      }
      return (
        <ScoreIcon
          score={score}
          className="table-score"
          scoreThresholds={strengthThresholds}
          titleNoun="Confidence"
          size={12}
        />
      );
    };
    return (
      <Column key="score" columnKey="score" width={20} {...{ header, cell }} allowCellsRecycling fixed />
    );
  };

  getRowClassName = (rowIndex) => {
    const { recipe, rows } = this.props;
    if (rows.get(rowIndex).originDataset === undefined) {
      return '';
    }
    return classNames({
      'external-data': recipe && recipe.metadata && recipe.metadata.get('CATEGORIZATION') &&
        recipe.metadata.get('CATEGORIZATION').datasetExternalStatus[rows.get(rowIndex).originDataset],
    });
  };

  render() {
    const {
      loading,
      recipeId,
      rows,
      pageNum,
      pageSize,
      total,
      elasticConfig,
      columnSettings,
      activeRowNumber,
      selectedRowNumbers,
      columnSortStates,
      onSort,
      onSetPage,
      onSetPageSize,
      onSetActiveRowNumber,
      onSetSelectedRowNumbers,
      onSetColumnWidth,
      currentlyProcessing,
      recipe,
      onClickGeoTamrAttribute,
    } = this.props;
    if (!elasticConfig) {
      return null;
    }
    const includedFields = new Set(getPath(recipe, 'metadata', 'CATEGORIZATION', 'includedFields'));
    const visibleFields = new Set(getPath(recipe, 'metadata', 'CATEGORIZATION', 'visibleFields'));
    const generatedAttributes = columnSettings ? columnSettings.filter(att => !visibleFields.contains(att.name)).map(att => (att.displayName)) : new List();
    const pager = new Pager(rows, _.min([elasticConfig.maxResultWindow, total]), pageNum, pageSize);
    const pagerContent = (
      <div className="transaction-table-pager-content">
        {total > 0 && columnSettings ?
          <ColumnOrderSelector {...{ columnSettings, recipeId }} pageType={DataTables.RECORDS} mlAttributes={includedFields} generatedAttributes={generatedAttributes} />
          : null}
        <FeedbackProgress />
      </div>
    );

    return (
      <ErrorBoundary>
        <div className="transaction-table-container">
          {(loading && currentlyProcessing.isEmpty()) ? <LoadingPanel aboveCenter /> : null}
          {total > 0 && columnSettings ?
            <AutoSizer>
              {({ width, height }) => (
                <ColumnWidthProvider only={['categorization']}>
                  <Table
                    {...{ width, height }}
                    headerHeight={42}
                    tableType="stripes"
                    getLength={() => rows.size}
                    activeRowNumber={activeRowNumber}
                    selectedRowNumbers={selectedRowNumbers}
                    pageSizes={List.of(50, 100, 500, 1000)}
                    onActiveCellChange={(e, rowNum) => onSetActiveRowNumber(rowNum)}
                    onSelectedRowsChange={onSetSelectedRowNumbers}
                    onPageSizeChange={onSetPageSize}
                    onPageChange={onSetPage}
                    pagerState={pager}
                    pagerContent={pagerContent}
                    rowClassNameGetter={this.getRowClassName}
                    enableMultipleRowSelection
                    onColumnResizeEndCallback={(newWidth, col) => onSetColumnWidth(DataTables.RECORDS, col, newWidth)}
                >
                    {[
                      this.renderCommentColumn(),
                      this.renderHighImpactColumn(),
                      this.renderVoterColumn(),
                      this.renderCategorizationColumn(),
                      ...columnSettings.filter(col => col.visible).map(column =>
                        DataColumn({
                          cell: ({ rowIndex }) => {
                            const { data, renderRaw, originalData } = rows.get(rowIndex).getValue(column.name);
                            return (
                              <Cell>
                                <MultiValue
                                  values={recordValueShim({ value: data, raw: renderRaw, originalData })}
                                  renderValue={(v, i) => {
                                    return v.case({
                                      GeoTamr: () =>
                                        <span
                                          key={i}
                                          className={styles.clickableGeoTamr}
                                          onClick={() => onClickGeoTamrAttribute(column.name)}
                                      >
                                          <Value value={v} />
                                        </span>,
                                      _: () =>
                                        <Value key={i} nestedKey={i} value={v} />,
                                    });
                                  }}
                              />
                              </Cell>
                            );
                          },
                          column,
                          columnSortStates,
                          rows,
                          onSort,
                          isMlAttribute: includedFields.contains(column.name),
                          isGeneratedAttribute: generatedAttributes.contains(column.displayName),
                        }),
                      ).toArray(),
                      this.renderConfidenceColumn(),
                    ]}
                  </Table>
                </ColumnWidthProvider>
              )}
            </AutoSizer>
            :
            <CenterContent>No <Term>Records</Term> To Show</CenterContent>
        }
        </div>
      </ErrorBoundary>
    );
  }
});

export default TransactionTable;
