import React from 'react';
import { connect } from 'react-redux';

import DataColumn from '../components/DataColumn';
import Cell from '../components/Table/Cell';
import { ORIGIN_SOURCE_NAME } from '../constants/ElasticConstants';
import DisplayColumn from '../models/DisplayColumn';
import TagIcon from '../tags/TagIcon';
import { ArgTypes, checkArg } from '../utils/ArgValidation';

const InputDatasetCell = connect((state) => {
  const { datasetFilter: { datasets } } = state; // TODO this has always been a bug... DEV-7656
  return { datasets };
})(({ rows, datasets, rowIndex }) => {
  const { data } = rows.get(rowIndex).getValue(ORIGIN_SOURCE_NAME);
  return (
    <Cell>
      {data}
      <span style={{ position: 'absolute', right: '5px' }}>
        <TagIcon
          sourceDatasetName={data}
          datasets={datasets}
        />
      </span>
    </Cell>
  );
});

const InputDatasetColumn = ({ rows, column, ...rest }) => {
  checkArg({ rows }, ArgTypes.Immutable.list);
  checkArg({ column }, ArgTypes.instanceOf(DisplayColumn));
  return DataColumn({
    ...rest,
    column: column.delete('alias'), // might get set by upstream getUDColumnSettingsForPage
    cell: ({ rowIndex }) => <InputDatasetCell {...{ rows, rowIndex }} />,
    isGeneratedAttribute: column.name === ORIGIN_SOURCE_NAME,
  });
};

export default InputDatasetColumn;
