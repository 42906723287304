import './PopoverTrigger.scss';

import classNames from 'classnames';
import $ from 'jquery';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import { OverlayTrigger, Popover as RBPopover } from 'react-bootstrap';
import ReactDOM from 'react-dom';

import { $TSFixMe } from '../utils/typescript';

const isOrContains = (test: $TSFixMe, target: $TSFixMe) => $(target).is(test) || $.contains(test, target);

const propTypes = {
  /**
   * Children is the "button" (doesn't need to be a button) that triggers the popover's show.
   * Children must be a React element.
   * It cannot be a string, number, or function component because react-bootstrap OverlayTrigger
   * needs to do fanciness with it.
   */
  children: PropTypes.element.isRequired,
  /**
   * Classname is passed to the popover.
   */
  className: PropTypes.string,
  /**
   * Content is the content of the popover.
   */
  content: PropTypes.node,
  /**
   * Content is the content of the popover.
   */
  contentFunction: PropTypes.func,
  /**
   * Classname to pass to the direct div container of the content
   */
  contentContainerClassName: PropTypes.string,
  /**
   * Callback when the overlay has been opened
   */
  onOverlayEntered: PropTypes.func,
  /**
   * Callback when the overlay has been closed
   */
  onOverlayExited: PropTypes.func,
  /**
   * Placement determines on which side of Children the popover will be placed.
   */
  placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
};
type PopoverTriggerProps = InferProps<typeof propTypes>;

class PopoverTrigger extends React.Component<PopoverTriggerProps> {
  get placement() {
    // TODO default-props-in-typescript
    return (this.props.placement === undefined || this.props.placement === null) ? 'left' : this.props.placement;
  }

  componentWillUnmount() {
    this.removeHideListener();
  }

  onOverlayEntered = () => {
    if (this.props.onOverlayEntered) {
      this.props.onOverlayEntered();
    }
    this.attachHideListener();
    const leftPlacement = this.placement === 'left';
    const topPlacement = this.placement === 'top';
    const rightPlacement = this.placement === 'right';
    const bottomPlacement = this.placement === 'bottom';
    // @ts-expect-error
    const $popoverNode = $(this.popoverNode()) as $TSFixMe;
    const $arrow = $popoverNode.find('.arrow');
    // handle arrow positioning wrt content size changes
    if (leftPlacement || rightPlacement) {
      const offsetTop = $arrow[0].offsetTop;
      const arrowHeight = $arrow[0].offsetHeight;
      const newTop = offsetTop + arrowHeight / 2;
      $arrow.css({ top: newTop + 'px' });
    }
    if (topPlacement || bottomPlacement) {
      const offsetLeft = $arrow[0].offsetLeft;
      const arrowWidth = $arrow[0].offsetWidth;
      const newLeft = offsetLeft + arrowWidth / 2;
      $arrow.css({ left: newLeft + 'px' });
    }
    // handle container positioning wrt content size changes
    if (leftPlacement) {
      const offsetRight = $popoverNode[0].offsetLeft + $popoverNode[0].offsetWidth;
      const newRight = window.innerWidth - offsetRight;
      $popoverNode.css({ left: 'inherit', right: newRight + 'px' });
    }
    if (topPlacement) {
      const offsetBottom = $popoverNode[0].offsetTop + $popoverNode[0].offsetHeight;
      const newBottom = window.innerHeight - offsetBottom;
      $popoverNode.css({ top: 'inherit', bottom: newBottom + 'px' });
    }
  };

  onOverlayExited = () => {
    if (this.props.onOverlayExited) {
      this.props.onOverlayExited();
    }
    this.removeHideListener();
  };

  attachHideListener = () => {
    window.addEventListener('mousedown', this.discernHide);
  };

  discernHide = (e: MouseEvent) => {
    if (!isOrContains(this.popoverNode(), e.target) && !isOrContains(ReactDOM.findDOMNode(this.refs.labelerTrigger), e.target)) {
      this.hide();
    }
  };

  hide = () => {
    // @ts-expect-error
    this.refs.labelerTrigger.hide();
  };

  popoverNode = () => {
    return ReactDOM.findDOMNode(this.refs.popover);
  };

  removeHideListener = () => {
    window.removeEventListener('mousedown', this.discernHide);
  };

  render() {
    const { children, content, contentFunction, className } = this.props;
    const popoverClassNames = classNames('tamr-popover-component', className);
    const popoverElement = (
      <RBPopover key="popover" ref="popover" id="popover" className={popoverClassNames}>
        {!!contentFunction && contentFunction(this.hide)}
        {!!content && content}
      </RBPopover>
    );
    return (
      <OverlayTrigger
        key="trigger"
        ref="labelerTrigger"
        trigger="click"
        placement={this.placement}
        overlay={popoverElement}
        onEntered={this.onOverlayEntered}
        onExited={this.onOverlayExited}
      >
        {children}
      </OverlayTrigger>
    );
  }
}

export default PopoverTrigger;
