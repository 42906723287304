import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import _ from 'underscore';

import Button from '../components/Button';
import ConditionalButton from '../components/ConditionalButton';
import Term from '../components/Term';
import RecordPairsBulkLabelButtons from './RecordPairsBulkLabelButtons';
// TODO in flight feature development
import RecordPairsFeedbackAssignButton from './RecordPairsFeedbackAssignButton';
import RecordPairsFilterPanelButton from './RecordPairsFilterPanelButton';

const ICONIFY_THRESHOLD = 1005; // px, below which must iconify buttons

const RecordPairsTaskbar = _.compose(
  connect(({ recordPairs: { activeRowNumber, selectedRowNumbers, isSidebarExpanded } }) => {
    return { activePairExists: _.isNumber(activeRowNumber), selectedRowNumbers, isSidebarExpanded };
  }, {
    showDetailsDialog: () => ({ type: 'RecordPairs.showDetailsDialog' }),
    onToggleSidebar: () => ({ type: 'RecordPairs.toggleExpandSidebar' }),
  }),
)(class RecordPairsTaskbar extends React.Component {
  static propTypes = {
    selectedRowNumbers: ImmutablePropTypes.setOf(PropTypes.number).isRequired,
    showDetailsDialog: PropTypes.func.isRequired,
  };

  renderComponent = (width, height) => {
    const { activePairExists, showDetailsDialog, selectedRowNumbers, isSidebarExpanded, onToggleSidebar } = this.props;
    const iconify = width < ICONIFY_THRESHOLD;
    return (
      <div style={{ width, height }} className="record-pairs-taskbar-inner">
        <div className="record-pairs-taskbar-container">
          <RecordPairsFilterPanelButton iconify={iconify} />
          <RecordPairsBulkLabelButtons iconify={iconify} />
          <RecordPairsFeedbackAssignButton rowNumbers={selectedRowNumbers} iconify={iconify} />
        </div>
        <div className="record-pairs-taskbar-container">
          <Button
            buttonType="Secondary"
            onClick={showDetailsDialog}
            disabled={!activePairExists}
          >
            {iconify ? 'Comp. det.' : 'Compare details'}
          </Button>
          <ConditionalButton
            buttonType="Secondary"
            preconditions={Map().set(<span>No <Term>pair</Term> selected</span>, isSidebarExpanded || (activePairExists && !isSidebarExpanded))}
            onClick={onToggleSidebar}
          >
            {isSidebarExpanded
              ? iconify ? 'Close' : 'Close details'
              : iconify ? 'Open' : 'Open details'
            }
          </ConditionalButton>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="record-pairs-taskbar">
        <AutoSizer>
          {({ width, height }) => this.renderComponent(width, height)}
        </AutoSizer>
      </div>
    );
  }
});

export default RecordPairsTaskbar;
